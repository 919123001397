export function cycleCountJobsGridColumns(titleArray, translateCallback) {
    return [{
        field: "Name",
        title: titleArray[0].description,
        width: 120,
        wmsid: 1,
        filterable: true
    }, {
        field: "CreationDate",
        title: titleArray[1].description,
        template: "#= CreationDate != null ? kendo.toString(kendo.parseDate(CreationDate), 'MM/dd/yyyy') : '' #",
        width: 120,
        wmsid: 2,
        filterable: true,
    }, {
        field: "Status",
        title: titleArray[2].description,
        template: "# if(Status == 0){# Open #}else if(Status == 1){# Counting #}else if(Status == 2){# Counted #}else if(Status == 3){# Closed #} # ",
        width: 120,
        wmsid: 5,
        filterable: true,
    }, {
        field: "NumberOfLocations",
        title: titleArray[3].description,
        width: 120,
        wmsid: 3,
        filterable: true,
    }, {
        field: "UseTrackBys",
        title: titleArray[4].description,
        template: "#= UseTrackBys == true ? 'Yes' : 'No' #",
        width: 120,
        wmsid: 4,
        filterable: true,
    }, {
        field: "AssignedTo",
        title: titleArray[5].description,
        template: "#= AssignedTo != null ? AssignedTo.Name : '' #",
        width: 120,
        wmsid: 6,
        filterable: true,
    }]
}

export function cycleCountJobsGridSchema() {
    return {
        model: {
            id: "EditUri",
            fields: {
                Name: {
                    type: "string"
                },
                CreationDate: {
                    type: "string"
                },
                NumberOfLocations: {
                    type: "number"
                },
                UseTrackBys: {
                    type: "boolean"
                },
                Status: {
                    type: "string"
                },
                AssignedTo: {
                    type: "any"
                }
            }
        }
    };
}
export function cycleCountJobLocationGridSchema() {
    return {
        data: "Data",
        total: "Total",
        schema: {
            model: {
                id: "Id",
                fields: {
                    Name: {
                        type: "string"
                    },
                    Type: {
                        type: "string"
                    },
                    LastCounted: {
                        type: "string"
                    }
                }
            }
        }
    };
}
export function cycleCountJobLocationGridColumns(titleArray, translateCallback) {
    return [{
        field: "Name",
        title: titleArray[0].description,
        width: 120,
        wmsid: 1,
        filterable: true
    }, {
        field: "Type",
        title: titleArray[1].description,
        width: 120,
        wmsid: 2,
        filterable: true,
    }, {
        field: "LastCounted",
        title: titleArray[2].description,
        template: "#= LastCounted != null ? kendo.toString(kendo.parseDate(LastCounted), 'MM/dd/yyyy') : '' #",
        width: 120,
        wmsid: 3,
        filterable: true,
    }, {
        field: "Deactivated",
        title: titleArray[3].description,
        template: "#= Deactivated ? 'Inactive' : 'Active' #",
        width: 120,
        wmsid: 4,
        filterable: true,
    }]
}

