var axios = require('axios');
import * as GlobalService from './../global_services';

export const getCustomerData = function (uri) {
    return axios.post("/WebUI/Receiving/ImportReceiptAsn/GetCustomerData", { customerUri: uri }).then(function (response) {
        return response.data;
    });
}

export const parseReceiverImportFile = function (custId, facilId, transId, file) {
    var data = {
        "facilityId": facilId,
        "transformId": transId,
        "content": file
    }

    return axios.post("/WebUI/Receiving/UpdateSession/ParseReceiverImportFile?customerId=" + custId, data).then(function (response) {
        return response;
    });
}

