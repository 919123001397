
import React from 'react';

//component
import WmsDropdown from './../../components/WmsDropdown/WmsDropdown.jsx';
import WmsButton from './../../components/WmsButton/WmsButton.jsx';
import { SmartParcelSettingsForm } from './smart-parcel-settings-form.jsx';

//services
import * as smartParcelServices from './smart-parcel-settings.services.jsx';
import * as GlobalService from './../../global_services';

import style from './smart-parcel-settings-holder.scss';

export class SmartParcelSettingsHolder extends React.Component {

    constructor(props) {
        super(props);

        
        this.translate = window.translate
        this.$rootScope = window;

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            selector: "smartParcel",
            saveLoader: false,
            customerOption: [],
            sharedSettings: {
                carrierFedEx: [],
                carrierUPS: [],
                AutoRateShoppingMapping: '',
                AutoRateShoppingLogic: '',
            },
            showDiscard: false,
            selectedCustomerUri: ''            
        }
    }

    componentDidMount() {
        kendo.ui.progress($('#smart-parcel-settings-holder'), true);
        GlobalService.GetCustomersAndFacility().then(() => {
            this.setState({
                customerOption: GlobalService.getCustomerList()
            });
        }).finally(() => { 
            kendo.ui.progress($('#smart-parcel-settings-holder'), false);
        });
    }

    handleCustomerChange = (event) => {

        let customerUri = event.target.value;

        this.setState({
            selectedCustomerUri: customerUri,
            showDiscard: false
        });
        if (!customerUri || customerUri == '') { return }

        kendo.ui.progress($('#main-content'), true);
        smartParcelServices.getSmartParcelSettingsOptions(customerUri).then((response) => {
            if (response && response.status == 200) {

                let responseData = response.data;

                // Saperate reference value in different property which have in below format
                // "PieceShipRef:RefNum|PieceShipRef1:TransId|PieceShipRef2:TransId|PieceShipRef3:TransId|PieceShipRef4:TransId"

                if (responseData.SmallParcelReferences != null && responseData.SmallParcelReferences != "") {
                    let arr = responseData.SmallParcelReferences.split('|');
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i] != "") {
                            const splitKeyWithColon = arr[i].split(':');
                            responseData[splitKeyWithColon[0]] = splitKeyWithColon[1];
                        }
                    }
                }
                if (responseData.Carrier.length) {
                    for (let index = 0; index < responseData.Carrier.length; index++) {
                        const element = responseData.Carrier[index];
                        if (element.Carrier == "FedEx") {
                            responseData.carrierFedEx = element.ShipmentServices;
                        } else if (element.Carrier == "UPS") {
                            responseData.carrierUPS = element.ShipmentServices;
                        }
                    }
                }
                this.setState({
                    sharedSettings: JSON.parse(JSON.stringify(responseData))                    
                });
            }
        }).finally(() => {
            kendo.ui.progress($('#main-content'), false);
        });

    }

    settingsDataSet = (param_setting_obj) => {
        this.setState({
            sharedSettings: param_setting_obj,
            showDiscard: true
        });
    }

    saveSmartParcelSettings = () => {

        this.setState({ "saveLoader": true });


        let settingsData = JSON.parse(JSON.stringify(this.state.sharedSettings));

        if (settingsData.SmallParcelUsps3rdParty == null || settingsData.SmallParcelUsps3rdParty == "") {

            if (settingsData.SmallParcelRateShoppingCarrierIds != null) {
                let tempArr = settingsData.SmallParcelRateShoppingCarrierIds.split(',');
                let position = tempArr.indexOf('ENDCA');
                (position != -1) ? tempArr.splice(position, 1) : '';
                settingsData.SmallParcelRateShoppingCarrierIds = tempArr.join(',');
            }
        }
        settingsData.SmallParcelReferences = '';

        // set SmallParcelReferences in below format and remove temp reference property
        // "PieceShipRef:RefNum|PieceShipRef1:TransId|PieceShipRef2:TransId|PieceShipRef3:TransId|PieceShipRef4:TransId"

        var settingsArray = [];

        if (settingsData.PieceShipRef && settingsData.PieceShipRef != '') {
            settingsArray.push('PieceShipRef:' + settingsData.PieceShipRef);
        }

        if (settingsData.PieceShipRef1 && settingsData.PieceShipRef1 != '') {
            settingsArray.push('PieceShipRef1:' + settingsData.PieceShipRef1);
        }

        if (settingsData.PieceShipRef2 && settingsData.PieceShipRef2 != '')
            settingsArray.push('PieceShipRef2:' + settingsData.PieceShipRef2);

        if (settingsData.PieceShipRef3 && settingsData.PieceShipRef3 != '')
            settingsArray.push('PieceShipRef3:' + settingsData.PieceShipRef3);

        if (settingsData.PieceShipRef4 && settingsData.PieceShipRef4 != '')
            settingsArray.push('PieceShipRef4:' + settingsData.PieceShipRef4);

        if (settingsArray.length > 0) {
            settingsData.SmallParcelReferences = settingsArray.join('|');
        }

        delete settingsData.PieceShipRef;
        delete settingsData.PieceShipRef1;
        delete settingsData.PieceShipRef2;
        delete settingsData.PieceShipRef3;
        delete settingsData.PieceShipRef4;

        delete settingsData.carrierFedEx;
        delete settingsData.carrierUPS;

        delete settingsData.Carrier;

        let param_settings_data = {
            'custUri': this.state.selectedCustomerUri,
            'spsData': settingsData
        }

        smartParcelServices.saveSmartParcelSettings(param_settings_data).then((response) => {

            if (response && response.status == 200) {
                GlobalService.notification('success', this.translate('Window_Title_Label_SmartParcel_Data_Success'));
                // GlobalService.closeWindow(this.state.windowId);
            }

        }).finally(() => {
            this.setState({ "saveLoader": false,showDiscard:false });
        });
    }


    render() {
        return (
            <div id="smart-parcel-settings-holder" >

                <div className="row">

                    <WmsButton
                        label={this.translate("Label_Save")}
                        wmsSelector={this.state.selector + "ButtonSaveExit"}
                        onClickFunc={this.saveSmartParcelSettings}
                        icon="fa-floppy-o"
                        buttonType="green"
                        showToolTip={this.state.selectedCustomerUri == ''}
                        tooltipText={
                            (this.state.selectedCustomerUri == '') ?
                                this.translate("Label_Smart_Customer_Require_Tooltip") : ""
                        }
                        disabled={this.state.selectedCustomerUri == ''}
                        loading={this.state.saveLoader} />

                    <WmsButton
                        label={this.state.showDiscard == false ? this.translate('Settings_Label_Exit') : this.translate('Label_Discard_Exit')}
                        wmsSelector={this.state.selector + 'ButtonExit'}
                        onClickFunc={() => { GlobalService.closeWindow(this.state.windowId); }}
                        icon={'fa-times-circle'}
                        buttonType="yellow" />

                </div>

                <div className="select-field">
                    <div className="wms-area-content top-fields">
                        <div className="wms-area-content-darker without-bg">

                            <WmsDropdown
                                id="smartParcelCustomer"
                                name="smartParcelCustomer"
                                label={GlobalService.getCustomLabels().Label_Customer}
                                wmsSelector={this.state.selector + 'SelectedCustomer'}
                                value={this.state.selectedCustomerUri}
                                onChangeFunc={this.handleCustomerChange}
                                options={this.state.customerOption}
                                valueField='uri'
                                textField='name'
                                parentClassName='form_group'
                                required={true}
                                blankFirstOption={true} />

                        </div>
                    </div>
                </div>
                <div id="main-content">
                    {this.state.selectedCustomerUri != '' &&
                        <div className="smart-parcel-settings-main-content">
                            <SmartParcelSettingsForm
                                sharedSettings={this.state.sharedSettings}
                                onSettingsDataSet={this.settingsDataSet}
                            />
                        </div>
                    }
                </div >
            </div >
        )

    }
}