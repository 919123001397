import React from 'react';

//component
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsDateTimePicker from './../../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import WmsModel from './../../../components/WmsModel/WmsModel.jsx';
import WmsInput from './../../../components/WmsInput/WmsInput.jsx';
import WmsFormGrid from './../../../components/WmsFormGrid/WmsFormGrid.jsx';
import WmsCollapse from './../../../components/WmsCollapse/WmsCollapse.jsx';

import { StringWms } from '../../../../../assets/ts/StringWms.ts';
import * as GlobalService from './../../../global_services';
import * as manageOrderServices from './../manage-orders.services.jsx';
import './ship-and-close-modal.scss';
import { kendo } from "./../../../global_variable_service.jsx";

export class ShipAndCloseModal extends React.Component {

    constructor(props) {
        super(props);

        
        this.translate = window.translate;
        let confirmChargeGridheader = StringWms.convertSringToOption(this.translate('Orders_ManageOrders_Confirm_Charges_Grid_Headers'));
        this.state = {
            selector: "OrderShipClose",
            shipOrderGif: false,
            staticGridData: [],
            totalSummaryCharges: 0,
            closeOrderShipDate: '',
            Rights: GlobalService.GetRights(),
            columns: [{
                field: "ChargeTypeName",
                title: confirmChargeGridheader[0].description,
                width: 120
            }, {
                field: "ChargeSummary",
                title: confirmChargeGridheader[1].description,
                width: 120
            }],
            schema: {
                model: {
                    fields: {
                        ChargeTypeName: {
                            type: "string"
                        },
                        ChargeSummary: {
                            type: "float"
                        }
                    }
                }
            }
        };
    }
    componentDidMount() {

            manageOrderServices.getOrderChargeSummary(this.props.shareOrderData.OrderId).then((response) => {

                if (response.status == 200 && response.data.SummaryRows) {
                    console.log("SummaryRows", response.data.SummaryRows);
                    this.setState({
                        totalSummaryCharges: response.data.TotalCharges,
                        staticGridData: response.data.SummaryRows,
                        closeOrderShipDate: kendo.toString(kendo.parseDate(new Date()), 'yyyy-MM-ddTHH:mm:ss'),
                    });
                }
            }).finally(() => {
                kendo.ui.progress($('#' + this.props.windowId), false);
            });

    }
    shipOrder = () => {


        this.setState({ shipOrderGif: true });

        manageOrderServices.shipAndCloseOrder({
            'id': this.props.shareOrderData.OrderId,
            'closeDate': this.state.closeOrderShipDate
        }).then((response) => {

            if (response != 'undefined' && (response.status == 204 || response.status == 200)) {
                GlobalService.notification('success', this.translate('Success_Message_ShipOrder'));
                this.props.onCloseShipAndClose();
                this.props.onOrderGridRead({ "action": "update" });
                if(this.props.onExitOrderForm){
                    this.props.onExitOrderForm();
                }else{
                    GlobalService.closeWindow(this.props.windowId);
                }
            } else {
                GlobalService.notification('error', response.Error);
                this.props.onCloseShipAndClose();
            }

        }).finally(() => {
            this.setState({ shipOrderGif: false });
        });
    }
    handleDateChange = (event) => {
        this.setState({ closeOrderShipDate: event.target.value });
    }


    render() {
        return (
            <WmsModel
                id={'shipAndClose' + this.props.windowId}
                title={this.translate('Label_Review_Charges_And_Close_Order')}
                class="shipAndClose"
                width={800}
                height={500}
                onCloseFunc={this.props.onCloseShipAndClose}
                isOpen={this.props.sharedIsOpenManageShipAndCloseModal}
                customClass='wms-model-new-ui-wrapper'>
                <div className="model-content-wrapper">
                <WmsDateTimePicker
                    id={'closeOrderShip'+ this.props.windowId}
                    name='closeOrderShip'
                    label={this.translate('Label_Ship_Date')}
                    onChangeFunc={this.handleDateChange}
                    value={this.state.closeOrderShipDate}
                    required={!this.state.closeOrderShipDate}
                    isDateParseInCustomFormat={true} />

                {this.state.Rights.hasOwnProperty('orders.charges.manage') && (
                    <WmsCollapse
                    id={'ManageOrderShipAndcloseCharge'+ this.props.windowId}
                    headerLabel={this.translate("Label_Are_These_Charges")}
                    showCollapseText={false}
                    showCollapseIcon={false} >
                        <WmsFormGrid
                            id={'OrderItemsGridConfirm' + this.props.windowId}
                            wmsSelector={this.state.selector + 'ItemsGridConfirm'}
                            columns={this.state.columns}
                            staticGridData={this.state.staticGridData}
                            schema={this.state.schema}
                            parentClassName={'showHeader'}
                            showResetMenu={false}
                        />
                        <div className="input-holder">

                            <WmsInput
                                inputName="GridShipChargesTotal"
                                wmsSelector={this.state.selector + 'GridShipChargesTotal'}
                                value={this.state.totalSummaryCharges}
                                disabled={true}
                                onChangeFunc={() => function () { }}
                                label={this.translate('Label_Total_Charges')} />
                        </div>
                    </WmsCollapse>
                )}
                </div>
                <div className="footer-btn-holder">
                <WmsButton
                    label={this.translate("Label_Cancel")}
                    wmsSelector={this.state.selector + "cancel"}
                    onClickFunc={this.props.onCloseShipAndClose}
                    buttonType={'orange'}
                    icon="fa-ban"
                />
                <WmsButton
                    label={this.translate('Label_Ship_And_Close')}
                    wmsSelector={this.state.selector + 'Confirm'}
                    onClickFunc={this.shipOrder}
                    disabled={this.state.customFieldName == '' || !this.state.closeOrderShipDate}
                    loading={this.state.shipOrderGif}
                    icon='fa-check-square-o' />
                </div>
            </WmsModel>
        );
    }
}