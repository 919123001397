import React, { Component } from 'react'
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import { ObjectWms } from '../../../../../assets/ts/ObjectWms.ts';

//services
import * as FindReceiptService from '../../findReceipt_proto/findReceipt.services.jsx';
import * as GlobalService from './../../../global_services';

import './receipt-advice-modal.scss';

export class ReceiptAdviceModel extends Component {
    constructor(props) {
        super(props)


        this.translate = window.translate;

        this.state = {
            OptionsReceiptAdviceFtp: [],
            OptionsReceiptAdviceFormats: [],
            SelectedFormatTransformId: null,
            SelectedFtpId: null,
            selectedFormat: {},
            selectedFtp: {},
            gifDownload: false,
            gifSend: false,
        }
    }

    componentDidMount() {
        var rowItem = this.props.selectedReceiptItems[0];
        kendo.ui.progress($('#windowReceiptAdvice'), true);
        FindReceiptService.sendReceiptAdviceOptions(rowItem.CustomerUri, rowItem.FacilityId).then((response) => {

           var receiptAdviceFtp = response.Channels;
           var receiptAdviceFormats = response.Transforms;

            this.setState({
                OptionsReceiptAdviceFormats: receiptAdviceFormats,
                selectedFormat: (receiptAdviceFormats.length > 0) ? receiptAdviceFormats[0] : {},
                SelectedFormatTransformId: (receiptAdviceFormats.length > 0) ? receiptAdviceFormats[0].TransformId : null,
                OptionsReceiptAdviceFtp: receiptAdviceFtp,
                selectedFtp: (receiptAdviceFtp.length == 1) ? receiptAdviceFtp[0] : {},
                SelectedFtpId: (receiptAdviceFtp.length == 1) ? receiptAdviceFtp[0].Id : null,
                ReceiverId: rowItem.ReceiverId,
            });
            
        }).finally(() => {
            kendo.ui.progress($('#windowReceiptAdvice'), false);

        });
    }

    closeModel = () => {
        this.props.closeReceiptAdviceModel(false);
    }

    updateFormat = (event) => {
        this.setState({
            SelectedFormatTransformId: event.target.value
        });
    }

    updateFtp = (event) => {
        this.setState({
            SelectedFtpId: event.target.value
        });
    }

    onDownload = (event) => {
        this.setState({ gifDownload: true });

        FindReceiptService.downloadReceiptAdvice(this.state.SelectedFormatTransformId, [this.state.ReceiverId]).then((response) => {
            // var date = new Date();
            // var day = date.getDate();
            // var month = date.getMonth();
            // var year = date.getFullYear();

            if (this.state.SelectedFormatTransformId == 15) {
                response.data.ContentType = 'text/csv';
            }
            // if (this.state.SelectedFormatTransformId == 15) {
            //     //ReceiverComma-Delimite
            //     var blob = new Blob(["" + response.Content], {
            //         type: "text/csv;charset=utf-8"
            //     });
            //     saveAs(blob, "ReceiptAdvice" + month + "_" + day + "_" + year + ".csv");
            // } else if (this.state.SelectedFormatTransformId == 16) {
            //     //EDI
            //     var blob = new Blob(["" + response.Content], {
            //         type: "text/plain;charset=utf-8"
            //     });
            //     saveAs(blob, "ReceiptAdvice" + month + "_" + day + "_" + year + ".txt");
            // } else if (this.state.SelectedFormatTransformId == 18) {
            //     //XML - this will change after clear about xml file data retrived.
            //     var blob = new Blob(["" + response.Content], {
            //         type: "text/xml;charset=utf-8"
            //     });
            //     saveAs(blob, "ReceiptAdvice" + month + "_" + day + "_" + year + ".xml");
            // }

            GlobalService.downloadFileBaseOnContentType(response.data.Content, response.data.ContentType, 'ReceiptAdvice');
        }).finally(() => {
            this.setState({ gifDownload: false });
        });
    }

    onSendFtp = () => {
        this.setState({ gifSend: true });
        let transformObj = this.state.OptionsReceiptAdviceFtp[ObjectWms.getIndexBasedUponKeyValuePair(this.state.OptionsReceiptAdviceFtp, "Id", this.state.SelectedFtpId)];

        var sendData = {
            "transformId": this.state.SelectedFormatTransformId,
            "channelIdentifier": transformObj,
            "receiverIds": [this.state.ReceiverId],
        };
        FindReceiptService.sendAsn(sendData).then((response) => {
            if (response && (response.status == 200 || response.status == 204)) {
                GlobalService.notification('success', this.translate('Label_Receipt_Advice_success'));
                this.props.resetGridSelection();
            }
        }).finally(() => {
            this.setState({ gifSend: false });
            this.props.closeReceiptAdviceModel(false);
        });
    }

    render() {
        return (
            <WmsModel
                id='windowReceiptAdvice'
                title={this.translate('Label_Receipt_Advice')}
                width={550}
                height={380}
                customClass='wms-model-new-ui-wrapper'
                onCloseFunc={this.closeModel}
                isOpen={this.props.sharedIsOpenReceiptAdviceModel}
            >
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'receiptAdvice'}
                        headerLabel={this.translate('Label_Receipt_Advice')}
                        showCollapseText={false}
                        showCollapseIcon={false}
                    >
                        <div className="receipt_num_info">
                            <i className="fa fa-exclamation-triangle wms-icon" aria-hidden="true"></i>
                            {this.translate('Label_Receipts_Selected').replace(new RegExp('{' + 'receiptNumb' + '}', 'gi'), this.props.selectedReceipts)}
                        </div>
                        {
                            (this.state.OptionsReceiptAdviceFormats.length != 0 || this.state.OptionsReceiptAdviceFtp.length != 0) ?
                                <div className="wrapper-section">
                                    <div className="center-element">
                                        {this.state.OptionsReceiptAdviceFormats.length > 1 && (
                                            <WmsDropdown
                                                id="formates"
                                                name="formates"
                                                label={this.translate('Label_Formats')}
                                                wmsSelector={this.props.Selector + 'AdviceFormates'}
                                                value={this.state.SelectedFormatTransformId}
                                                onChangeFunc={this.updateFormat}
                                                options={this.state.OptionsReceiptAdviceFormats}
                                                valueField='TransformId'
                                                textField='Description'
                                            />
                                        )}

                                        {this.state.OptionsReceiptAdviceFormats.length == 1 && (
                                            <div className="title_label">
                                                <p className='label_wrapp'>
                                                    {this.translate('Label_Formats')}
                                                    <span className="title">
                                                        {this.state.selectedFormat.Description}
                                                    </span>
                                                </p>
                                            </div>
                                        )}


                                    </div>
                                    <div className="center-element">
                                        {this.state.OptionsReceiptAdviceFtp.length > 1 && (
                                            <WmsDropdown
                                                id="SendToFtp"
                                                name="SendToFtp"
                                                label={this.translate('Label_Send_To_Ftp')}
                                                wmsSelector={this.props.Selector + 'AdviceSendToFtp'}
                                                value={this.state.SelectedFtpId}
                                                onChangeFunc={this.updateFtp}
                                                options={this.state.OptionsReceiptAdviceFtp}
                                                valueField='Id'
                                                textField='Name'
                                            />
                                        )}

                                        {this.state.OptionsReceiptAdviceFtp.length == 1 && (
                                            <div className="title_label">
                                                <p className='label_wrapp'>
                                                    {this.translate('Label_Send_To_Ftp')}
                                                    <span className="title">
                                                        {this.state.selectedFtp.Name}
                                                    </span>
                                                </p>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                :
                                <div className="title_label">
                                    <p className="title">
                                        {this.translate('Label_Ftp_setting_not_configured_for_this_customer')}
                                    </p>
                                </div>
                        }
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.props.Selector + "CancelReceiptModelExitButton"}
                        onClickFunc={this.closeModel}
                        buttonType='orange'
                        icon={'fa-times-circle'}
                    />
                    {this.state.OptionsReceiptAdviceFormats.length != 0 && (
                        <WmsButton
                            label={this.translate("Label_Download")}
                            wmsSelector={this.props.Selector + "AdviceDownload"}
                            onClickFunc={this.onDownload}
                            icon="fa-download"
                            buttonType="green"
                            disabled={this.state.gifDownload}
                            loading={this.state.gifDownload}
                        />
                    )}

                    {this.state.OptionsReceiptAdviceFtp.length != 0 && (
                        <WmsButton
                            label={this.translate("Label_Send")}
                            wmsSelector={this.props.Selector + "AdviceDownload"}
                            onClickFunc={this.onSendFtp}
                            icon="fa-paper-plane"
                            buttonType="green"
                            disabled={this.state.gifSend}
                            loading={this.state.gifSend}
                        />
                    )}
                </div>

            </WmsModel>
        )
    }
}
