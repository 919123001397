
import React from 'react';
import * as GlobalService from '../../global_services';
import style from '../report-iframe.scss';
import { reportIframe } from '../report-iframe.component.jsx';

export class itemsIframeList extends React.Component {
    constructor(props) {

        super(props);
        
        this.$rootScope = window;
        this.translate = window.translate
        this.state = {
            reloadGrid: false,
            sharedUserUri: null,
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            Rights: GlobalService.GetRights(),
        }
    }
    setIframeUrl = (iframeUrl, reportTitle, urlParam) => e => {
        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            id: iframeUrl,
            isIframe: true,
            title: this.translate(reportTitle),
            active: true,
            icon: 'submenu-icon fa fa-plus',
            urlParam: urlParam
        });
    }

    render() {
        return (
            <div className="react_iframebox">
                <ul>
                    <li>
                        <ul>
                            {this.state.Rights.hasOwnProperty('admin.reports.l1') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/ItemActivityReport.aspx", "Label_Item_Activity_Report",'item-activity')}>{this.translate('Label_Item_Activity_Report')}</a>
                                </li>
                            )}
                            {false && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/Default.aspx", "Label_Inventory_Analysis","inventory-analysis")}>{this.translate('Label_Inventory_Analysis')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l2') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/ViewItemReport.aspx", "Label_View_Item_Report","view-item")}>{this.translate('Label_View_Item_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l4') && this.state.Rights.hasOwnProperty('isWarehouseUser') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/ItemMovementHistory.aspx", "Label_Item_Movement_Report","item-movement")}>{this.translate('Label_Item_Movement_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l4') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/InvWeightAndVolumeReport.aspx", "Label_Inventory_Weight_Volume_Report","inventory-weight-and-volume")}>{this.translate('Label_Inventory_Weight_Volume_Report')}</a>
                                </li>
                            )}
                        </ul>
                    </li>
                </ul>
            </div>
        )
    }
}
