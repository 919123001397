import React from "react";
import PropTypes from "prop-types";
import style from "./WmsCustomDateTimePickerProto.scss";

class WmsCustomDateTimePickerProto extends React.PureComponent {
    constructor(props) {
        super(props);
        this.windowId = this.props.windowId == "" ? "" : "." + this.props.windowId;

        this.defaultDateOption = {
            date: '',
            time: ''
        };
        this.defaultTimeFormat = 'h:mma';
        this.defaultDateTime = moment(this.props.defaultTime, 'HH:mm:ss');

        this.state = {
            timeOptions: [],
            filterString: this.props.value,
            selectedDateOptions: this.defaultDateOption,
        };
    }

    componentDidMount() {

        $(`${this.windowId} #${this.props.id}-date-input`).kendoDatePicker({
            change: this.changeDate,
        });
        $(`${this.windowId} #${this.props.id}-date-input`).bind("focus", (e) => {
            let datePicker = $(`${this.windowId} #${this.props.id}-date-input`).data("kendoDatePicker");
            datePicker.open();
        });
        if (!this.props.onlyDate) {
            this.setOptionsForTime(this.props.stepsTime);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value != prevProps.value) {
            this.setDateTimePickerValue(this.props.value);
        }
    }

    setDateTimePickerValue = (filterString) => {
        let momentDate = moment(filterString);
        if (momentDate.isValid()) {
            let propsDate = momentDate.format();
            let propsTime = momentDate.format(this.defaultTimeFormat);

            this.changeSelectedDateOptions({
                date: propsDate,
                time: propsTime
            }, false);
        } else {
            this.changeSelectedDateOptions(this.defaultDateOption, false, true);
        }

    }

    changeDate = () => {
        let datepicker = $(`${this.windowId} #${this.props.id}-date-input`).data("kendoDatePicker");
        this.changeSelectedDateOptions({ date: datepicker.value() });
    }

    changeTime = (event) => {
        let time = event.target.value;
        let selectedOpt = { time: time };
        if (this.state.selectedDateOptions.date == "") {
            selectedOpt.date = moment().format();
        }
        this.changeSelectedDateOptions(selectedOpt);
    }

    changeSelectedDateOptions = (updateObj, emitAfterUpdate = true, clearTime = false) => {
        let selectedOptions = Object.assign({}, this.state.selectedDateOptions, updateObj);
        if(!clearTime) {
            selectedOptions.time = (selectedOptions.time) ? selectedOptions.time : this.defaultDateTime.format(this.defaultTimeFormat);
        }

        let datepicker = $(`${this.windowId}  #${this.props.id}-date-input`).data("kendoDatePicker");
        datepicker.value(selectedOptions.date);

        this.setState({
            selectedDateOptions: selectedOptions,
        }, () => {
            if (emitAfterUpdate) {
                this.changeDateTimeAndEmit();
            }
        });
    }

    changeDateTimeAndEmit = () => {
        let {date, time} = this.state.selectedDateOptions;
        let filterString = '';

        let momentDate = moment(date);

        if (momentDate.isValid()) {
            if (time) {
                let dateTime = moment(time, this.defaultTimeFormat);
                if (dateTime.isValid()) {
                    momentDate.set({
                        hour: dateTime.get('hours'),
                        minute: dateTime.get('minute'),
                        second:1,
                        millisecond:0
                    });
                }
            }
            if(!this.props.isDateParseInCustomFormat){
                momentDate = momentDate.utc();
            }

            filterString = (this.props.isDateParseInCustomFormat) ? momentDate.format('YYYY-MM-DDTHH:mm:ss') : momentDate.toISOString();
        }

        this.setState({
            filterString: filterString
        });

        let eventObj = {
            target:{
                name: this.props.name,
                value: filterString,
            }
        }

        this.props.onChangeFunc(eventObj);
    }

    setOptionsForTime = (step = 60) => {
        let timeArray = [];
        let minute = 0;
        let totMinutes = moment.duration(1, "day").asMinutes();

        while (minute <= totMinutes) {
            let time = moment.utc(moment.duration(minute, "minutes").asMilliseconds()).format(this.defaultTimeFormat);
            timeArray.push(time);
            if (minute == (totMinutes - step)) {
                step--;
            }
            minute += step;
        }
        this.setState({
            timeOptions: timeArray,
        });
    }

    clearDatePicker = () => {
        if (this.state.selectedDateOptions.date != "" || this.state.selectedDateOptions.time != "") {
            let dateOptions = this.defaultDateOption;
            this.changeSelectedDateOptions(dateOptions, true, true);
        }
    }

    render() {
        return (
            <div
                id={this.props.id}
                className="wms-datetimepicker-component-proto-kendo"
                data-wms-selector={this.props.wmsSelector}
            >
                <label>{this.props.label} </label>
                <div
                    className={`border ${(this.props.disabled) ? 'dropdown-disabled' : ''} ${this.props.parentClassName}`}
                >
                    <div className="datePicker_options">
                        <div className={`wms-DatePicker-main`}>
                            <input id={`${this.props.id}-date-input`} name='date' />
                        </div>

                        {!this.props.onlyDate && (
                            <div className={`wms-DatePicker-main`}>
                                <select
                                    name="time"
                                    onChange={this.changeTime}
                                    value={this.state.selectedDateOptions.time != null ? this.state.selectedDateOptions.time : ''}
                                >
                                    {(this.state.selectedDateOptions.time == '') &&
                                        <option value="">--:--</option>
                                    }
                                    {this.state.timeOptions.map((time, key) => (
                                        <option value={time} key={key}>{time}</option>
                                    ))}
                                </select>
                                <span className="caret"></span>
                            </div>
                        )}
                    </div>
                    <div className="datePicker_clearer" onClick={this.clearDatePicker}><i className="fa fa-times-circle-o" aria-hidden="true"></i></div>
                </div>
            </div>
        );
    }
}

WmsCustomDateTimePickerProto.propTypes = {
  id: PropTypes.string.isRequired, // this should write the id to the input
  name: PropTypes.string.isRequired, //this should set the name of datepicker input
  label: PropTypes.string.isRequired, // this should write the label of the menu
  windowId: PropTypes.string, // this should be used to get window id
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), // this should control the value of datepicker
  defaultTime: PropTypes.string, // this is used for set default time of datetimepicker
  stepsTime: PropTypes.number, // this is used for set steps in times dropdown
  onChangeFunc: PropTypes.func.isRequired, // this sould manage datepicker change and clear select value
  parentClassName: PropTypes.string, //    this should parent tag class default parent class is form-group
  onlyDate: PropTypes.bool, // hide time icon for only show date
  wmsSelector: PropTypes.string, // this should set up a selector on the date
  disabled: PropTypes.bool,  // default will be false
  isDateParseInCustomFormat: PropTypes.bool,  // set true to parse date in custom format not in ISO
};

WmsCustomDateTimePickerProto.defaultProps = {
  defaultTime: "00:00:01",
  stepsTime: 30,
  parentClassName: "",
  windowId: "",
  onlyDate: false,
  disabled: false,
  wmsSelector: "",
  isDateParseInCustomFormat:false,
};

export default WmsCustomDateTimePickerProto;
