import React from 'react';

//component
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsDropdownList from './../components/WmsDropdownList/WmsDropdownList.jsx';
import WmsGrid from './../components/WmsGrid/WmsGrid.jsx';
import WmsFormGrid from './../components/WmsFormGrid/WmsFormGrid.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';
import WmsModel from './../components/WmsModel/WmsModel.jsx';

import * as GlobalService from './../global_services';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import style from "./../components/WmsGrid/WmsGrid.scss";
import WmsTextArea from "../components/WmsTextArea/WmsTextArea.jsx";
import { ObjectWms } from './../../../assets/ts/ObjectWms.ts';

var that;

export class ItemsAssembly extends React.Component{

    constructor(props) {

        super(props);

        this.translate = window.translate;
        var ItemsAssembly_GridHeaders = StringWms.convertSringToOption(this.translate('Items_Assembly_GridHeaders'));

        this.state = {
            selector: "Item",
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #GridItemAssemblies' : '#GridItemAssemblies',
            selectedCustomerData: this.props.sharedCurrentCustomerObj,
            selectedSku: "",
            selectedQualifier: "",
            itemId: 0,
            skuDescription:"",
            skuOptions: [],
            qualifierOptions: [],
            customLabels: GlobalService.getCustomLabels(),
            assemblyGridData: [],
            showQualifiers: false,
            disableNonSku: false,
            nonSkuMaterial: "",
            quantity: null,
            disableAdd: true,
            disableAddTooltipText: this.translate('Tooltip_Select_SKU_Or_NonSkuMaterial'),
            duplicateNonSkuMaterial: false,
            isOpenConfirmModel: false,
            disableDelete: true,
            columns: [{
                field: "RowNumber",
                title: "#",
                hidden: true,
                menu: false
            },{
                field: "ItemId",
                title: "ItemId",
                hidden: true
            }, {
                field: "Sku",
                title: GlobalService.getCustomLabels().Label_SKU
            }, {
                field: "Description",
                title: this.translate('Label_SKU_Description'),
            }, {
                field: "Qualifier",
                title: this.translate('Label_Qualifier')
            }, {
                field: "NonSkuMaterial",
                title: this.translate('Label_Non_Sku_Material')
            }, {
                field: "Qty",
                title: this.translate('Label_Quantity'),
            }],
            schema: {
                data: "Data",
                total: "Total",
                model: {
                    //id: "ItemId",
                    fields: {
                        RowNumber: {
                            type: 'number'
                        },
                        ItemId: {
                            type: "number"
                        },
                        Sku: {
                            type: "string"
                        },
                        Description: {
                            type: "string"
                        },
                        Qualifier:{
                            type: "string"
                        } ,
                        NonSkuMaterial: {
                            type: "string"
                        },
                        Qty: {
                            type: "string"
                        }
                    }
                }
            }}

        that = this;
    }

    onSelectSkuDropdown = (e) => {
        var dataItem = e.dataItem;
        if (!dataItem) {
            dataItem = {
                Sku: this.translate('Label_Select_Sku'),
                Description: this.translate('Label_Select_Sku'),
                Id: 0
            }
        }

        if(dataItem.Sku == this.translate('Label_Select_Sku')) {
            dataItem.Sku = '';
        }
        that.setState({
            selectedSku: dataItem.Sku,
            itemId: dataItem.Id,
            skuDescription:dataItem.Description,
            disableNonSku: dataItem.Id > 0,
            nonSkuMaterial: dataItem.Id > 0? '' : dataItem.nonSkuMaterial
        })

        if (typeof dataItem.QualifierSummaries != 'undefined' && dataItem.QualifierSummaries.length > 0 && ObjectWms.doesKeyContainValidValue(dataItem.QualifierSummaries, "Qualifier")) {
            that.setState({
                showQualifiers: true,
                qualifierOptions: dataItem.QualifierSummaries,
                selectedQualifier:dataItem.QualifierSummaries[0].Qualifier
            })
        }else{
            that.setState({
                showQualifiers: false,
                qualifierOptions: [],
                selectedQualifier: ""
            })
        }

        this.checkForDublicate();

    }
    checkForDublicate = () => {


        if(!this.state.selectedSku && !this.state.nonSkuMaterial) {
            that.setState({
                disableAdd: true,
                disableAddTooltipText: this.translate('Tooltip_Select_SKU_Or_NonSkuMaterial')
            });
            return;
        }
        if (!this.state.quantity) {
            that.setState({
                disableAdd: true,
                disableAddTooltipText: this.translate('Label_Enter_Quantity')
            });
            return;
        }

        // disabled={(item.ItemBasics.ItemQualifiers.length > 0 && item.ItemBasics.ItemQualifiers[0].Name !== "") || (this.state.nonSkuMaterial == "" && !this.state.selectedSku) || this.state.duplicateNonSkuMaterial}
        // tooltipText={((item.ItemBasics.ItemQualifiers.length > 0 && item.ItemBasics.ItemQualifiers[0].Name !== "") ? this.translate('ToolTip_Cannot_Be_As_Assembly') : (this.state.disableAdd) ? this.translate('Tooltip_Duplicate_SKU') : (this.state.nonSkuMaterial == "" && !this.state.selectedSku) ? this.translate('Tooltip_Select_SKU_Or_NonSkuMaterial'): this.state.duplicateNonSkuMaterial ? this.translate('Tooltip_Duplicate_NonSkuMaterial') : ""}

        var assembliesGridData = $("#GridItemAssemblies").data("kendoGrid").dataSource.data();

        // console.log("state sku = " + that.state.selectedSku + "   qualifier = " + that.state.selectedQualifier + "   nonSkuMaterial = " + that.state.nonSkuMaterial + "\n");
        var foundMatch = false;
        var disableAddTooltipText = '';
        if(assembliesGridData.length > 0) {
            for(var i = 0; i < assembliesGridData.length; i++){

                if(that.state.selectedSku && that.state.selectedQualifier &&  assembliesGridData[i].Sku == that.state.selectedSku && assembliesGridData[i].Qualifier == that.state.selectedQualifier){
                    foundMatch = true;
                    disableAddTooltipText = this.translate('Tooltip_Duplicate_Sku_Qualifier');
                    break;
                }

                if(that.state.selectedSku && !that.state.selectedQualifier && assembliesGridData[i].Sku == that.state.selectedSku && assembliesGridData[i].Qualifier == that.state.selectedQualifier){
                    foundMatch = true;
                    disableAddTooltipText = this.translate('Tooltip_Duplicate_Sku_Qualifier');
                    break;
                }

                if(!that.state.selectedSku && !that.state.selectedQualifier
                    && !assembliesGridData[i].Sku && !assembliesGridData[i].Qualifier
                    && that.state.nonSkuMaterial && assembliesGridData[i].NonSkuMaterial == that.state.nonSkuMaterial){
                    foundMatch = true;
                    disableAddTooltipText = this.translate('Tooltip_Duplicate_NotSkuMaterial');
                    break;
                }

                // console.log("grid sku = " + assembliesGridData[i].Sku + "   qualifier = " + assembliesGridData[i].Qualifier + "   nonSkuMaterial = " + assembliesGridData[i].NonSkuMaterial);
                /***Old condition with no sku material  */
                // if(that.state.selectedSku && that.state.selectedQualifier &&  assembliesGridData[i].Sku == that.state.selectedSku && assembliesGridData[i].Qualifier == that.state.selectedQualifier){
                //     foundMatch = true;
                //     disableAddTooltipText = this.translate('Tooltip_Duplicate_Sku_Qualifier');
                //     break;
                // }
                // if(that.state.selectedSku && that.state.selectedQualifier && that.state.nonSkuMaterial
                //     && assembliesGridData[i].Sku == that.state.selectedSku
                //     && assembliesGridData[i].Qualifier == that.state.selectedQualifier
                //     && assembliesGridData[i].NonSkuMaterial == that.state.nonSkuMaterial) {
                //     foundMatch = true;
                //     disableAddTooltipText = this.translate('Tooltip_Duplicate_Sku_Qualifier_NonMateria');
                //     break;
                // }
                // if(that.state.selectedSku && !that.state.selectedQualifier && that.state.nonSkuMaterial
                //     && assembliesGridData[i].Sku == that.state.selectedSku && assembliesGridData[i].NonSkuMaterial == that.state.nonSkuMaterial){
                //     foundMatch = true;
                //     disableAddTooltipText = this.translate('Tooltip_Duplicate_Sku_NonMateria');
                //     break;
                // }

                // if(!that.state.selectedSku && !that.state.selectedQualifier
                //     && !assembliesGridData[i].Sku && !assembliesGridData[i].Qualifier
                //     && that.state.nonSkuMaterial && assembliesGridData[i].NonSkuMaterial == that.state.nonSkuMaterial){
                //     foundMatch = true;
                //     disableAddTooltipText = this.translate('Tooltip_Duplicate_NotSkuMaterial');
                //     break;
                // }
                // if(that.state.selectedSku && !that.state.selectedQualifier && !that.state.nonSkuMaterial && assembliesGridData[i].Sku == that.state.selectedSku && assembliesGridData[i].Qualifier == that.state.selectedQualifier && assembliesGridData[i].NonSkuMaterial == that.state.nonSkuMaterial){
                //     foundMatch = true;
                //     disableAddTooltipText = this.translate('Tooltip_Duplicate_SKU');
                //     break;
                // }
            }
        }

        that.setState({
            disableAdd: foundMatch,
            disableAddTooltipText: disableAddTooltipText
        });

    }

    handleChange = (param_parent_property, event) => {
        let propertyName = event.target.name;
        let value = event.target.value;

        that.setState({
            [param_parent_property]: value
        },() => {
            this.checkForDublicate();
        });
    }


    handleMaterialNotesChange = (event) => {
        let itemObj = Object.assign(
            {},
            this.props.sharedItem,
            {
                Assembly: Object.assign(
                    {},
                    this.props.sharedItem.Assembly,
                    { 'MaterialNotes':  event.target.value }
                )
            }
        );

        this.props.onItemDataset(itemObj);
    }


    addAssemblyInfo = (event) => {

        let tmpComponents =  Object.assign([],this.props.sharedItem.Assembly.Components);

        let rowNum = 1;
        if(tmpComponents.length != 0){
            rowNum = tmpComponents.length + 1;
        }

        tmpComponents.push({
            'RowNumber': rowNum,
            'ItemId': that.state.itemId,
            'Sku': that.state.selectedSku != this.translate('Label_Select_Sku') ? that.state.selectedSku : "",
            'Qualifier': that.state.selectedQualifier,
            'Qty': that.state.quantity,
            'NonSkuMaterial': that.state.nonSkuMaterial,
            'Description': that.state.skuDescription != this.translate('Label_Select_Sku') ? that.state.skuDescription : ""
        });
        let itemObj = Object.assign(
            {},
            this.props.sharedItem,
            {
                Assembly: Object.assign(
                    {},
                    this.props.sharedItem.Assembly,
                    { 'Components': tmpComponents }
                )
            }
        );

        this.props.onItemDataset(itemObj);

        that.setState({
            itemId: 0,
            selectedSku: "",
            selectedQualifier: "",
            quantity: null,
            nonSkuMaterial: "",
            assemblyGridData: tmpComponents,
            skuDescription: "",
            disableAdd: true,
            disableAddTooltipText: this.translate('Tooltip_Select_SKU_Or_NonSkuMaterial'),
            disableNonSku: false,
            showQualifiers: false
        });

    }

    selectRow = (param_kendogrid) => {
        var selectedRows = param_kendogrid.getNumberOfSelections();
        if (selectedRows > 0) {
            this.setState({
                disableDelete: false
            });
        }else {
            this.setState({
                disableDelete: true
            });
        }
    }

    openConfirmationModal = () => {
        this.setState({
            isOpenConfirmModel: true
        });
    }

    ClickExit = () => {
        this.setState({
            isOpenConfirmModel: false
        });
    }

    deleteAssemblyInfo = () => {
        var grid = $('.' + this.props.windowId + ' #GridItemAssemblies').data('kendoGrid');

        grid.select().each(function () {
            grid.removeRow($(this));
        });

        var data = grid.dataSource.data().length > 0 ? grid.dataSource.data().toJSON() : [];

        let itemObj = Object.assign(
            {},
            this.props.sharedItem,
            {
                Assembly: Object.assign(
                    {},
                    this.props.sharedItem.Assembly,
                    { 'Components': data }
                )
            }
        );

        this.props.onItemDataset(itemObj);

        this.setState({
            isOpenConfirmModel: false,
            disableDelete: true
        });
    }

    onLoadAssemblyGrid = () => {
        var grid = $(this.state.gridDomSelector()).data('kendoGrid');
        var that = this;
        if (typeof grid != "undefined") {
            grid.dataSource.data(this.props.sharedItem.Assembly.Components);
        }
    }

    render(){
        const item = this.props.sharedItem;
        return(
            <div className={"collapseItemsTabContent_wrapper"} id={'collapseItemsAssembly'}>

                <WmsCollapse
                    id={'ItemAssembly'}
                    headerLabel={this.translate('Label_Assembly')}
                    showCollapseText={false}
                    showCollapseIcon={false} >
                <div className="wms-assembly-block">
                    <WmsDropdownList
                        id="SkuDropDown"
                        name="SkuDropDown"
                        optionsLabel={{
                            Sku: this.translate('Label_Select_Sku'),
                            Description: this.translate('Label_Select_Sku'),
                            Id: 0
                        }}
                        label={this.translate('Label_Sku')}
                        height={290}
                        wmsSelector={this.state.selector + "SkuDropDown"}
                        value={this.state.itemId}
                        onSelect={this.onSelectSkuDropdown}
                        onChangeFunc={()=>{}}
                        textField='Sku'
                        valueField='Id'
                        template={"<span style='white-space: nowrap;text-overflow: ellipsis;width: 210px;display: block;overflow: hidden'>${ Sku } - ${Description}</span>"}
                        isValuePrimitive={true}
                        virtualObj={{
                            itemHeight: 45,
                            valueMapper: (options) => {
                                var valueMapperOptions = options;
                                $.ajax({
                                    url: "/WebUI/items/items/SkuDropdownIndexRead",
                                    type: "POST",
                                    dataType: "json",
                                    data: {
                                        itemId: valueMapperOptions.value || 0,
                                        customerId: this.state.selectedCustomerData.id
                                    },
                                    success: function (data) {
                                        options.success(data);
                                    }
                                });
                            }
                        }}
                        dataSourceObj={{
                            type: "aspnetmvc-ajax",
                            pageSize: 30,
                            serverPaging: true,
                            serverFiltering: true,
                            transport: {
                                read: {
                                    url: "/WebUI/items/items/SkuDropDownRead",
                                    type: "POST",
                                    data: {
                                        customerId: this.state.selectedCustomerData.id,
                                        parentSku: item.Sku,
                                    }
                                }
                            },
                            schema: {
                                data: "Data",
                                total: "Total"
                            }
                        }}
                    />
                    {this.state.showQualifiers == true &&
                    <WmsDropdown
                        id='QualifierDropDown'
                        name='Qualifier'
                        wmsSelector={this.state.selector + 'QualifierDropDown'}
                        options={this.state.qualifierOptions}
                        value={this.state.selectedQualifier}
                        onChangeFunc={(e) => this.handleChange('selectedQualifier', e)}
                        label={this.translate('Label_Qualifier')}
                        valueField='Qualifier'
                        extraWrapperClass="wms-input-material-box"
                        textField='Qualifier' />}
                    <WmsInput
                        id='NonSkuMaterial'
                        inputName='NonSkuMaterial'
                        wmsSelector={this.state.selector + 'NonSkuMaterial'}
                        onChangeFunc={(e) => this.handleChange('nonSkuMaterial', e)}
                        label={this.translate('Label_Non_Sku_Material')}
                        extraWrapperClass="wms-input-material-box"
                        value={this.state.nonSkuMaterial}
                        disabled={this.state.disableNonSku} />
                    <WmsInput
                        id='Quantity'
                        inputName='Quantity'
                        wmsSelector={this.state.selector + 'Quantity'}
                        onChangeFunc={(e) => this.handleChange('quantity', e)}
                        label={this.translate('Label_Quantity')}
                        value={this.state.quantity}
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                        extraWrapperClass="wms-input-quantity-box"
                        required={true} />
                </div>
                    <WmsButton
                        id='AddAssembly'
                        wmsSelector={this.state.selector + 'AddAssembly'}
                        label={this.translate('Label_Add')}
                        onClickFunc={this.addAssemblyInfo}
                        icon='fa-plus'
                        disabled={this.state.disableAdd}
                        tooltipText={this.state.disableAddTooltipText}
                        showToolTip={true}
                        parentClassName='wms-assembly-btn-block'
                    />

                    <WmsGrid
                        id={'GridItemAssemblies'}
                        wmsSelector={this.state.selector + 'GridItemAssemblies'}
                        gridKeySelection={'RowNumber'}
                        gridName={'GridItemAssemblies'}
                        virtual={false}
                        columns={this.state.columns}
                        onLoadGrid={this.onLoadAssemblyGrid}
                        schema={this.state.schema}
                        showGridSummary={true}
                        shawCheckbox={false}
                        showGridResult={true}
                        showSortColumns={false}
                        showResetButton={false}
                        windowId={this.props.windowId}
                        onSelection={this.selectRow}
                        clickEventOfContextMenu={() => null}
                        gridHeight={400}
                        staticGridData={this.props.sharedItem.Assembly.Components}
                        menu={[
                            {
                                "name": this.translate('Label_Delete'),
                                "value": [],
                                "disabled": this.state.disableDelete,
                                "disabledText": "You may not add blahssss",
                                "icon": "fa-trash",
                                "color": "red",
                                "clickFunc": (this.openConfirmationModal),
                                "wmsSelector": this.state.selector + "DeleteAssembly",
                                "isContextMenu": false,
                            }
                        ]}
                    />

                    <WmsTextArea
                        id='MaterialNotes'
                        wmsSelector={this.state.selector + 'MaterialNotes'}
                        label={this.translate('Label_Material_Notes')}
                        onChangeFunc={(e) => this.handleMaterialNotesChange(e)}
                        value={this.props.sharedItem.Assembly.MaterialNotes}
                        rows={5}
                        cols={5} />

                    <WmsModel
                        id={this.props.windowId + 'confirmDeleteAssembly'}
                        title={this.translate('Label_Confirm_Delete_Assembly')}
                        width={500}
                        height={175}
                        class='confirmDeleteManualCharge'
                        onCloseFunc={this.ClickExit}
                        customClass='wms-model-new-ui-wrapper'
                        isOpen={this.state.isOpenConfirmModel}>
                        <div className='model-content-wrapper'>
                            {this.translate('Label_Confirm_Selected_Assemblies')}
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton
                                label={this.translate('Label_Cancel')}
                                wmsSelector={this.state.selector + 'Cancel'}
                                onClickFunc={this.ClickExit}
                                disabled={false}
                                loading={false}
                                buttonType='yellow'
                                icon={'fa-ban'}
                            />
                            <WmsButton
                                label={this.translate('Label_Confirm_Delete')}
                                wmsSelector={this.state.selector + 'ConfirmDelete'}
                                onClickFunc={this.deleteAssemblyInfo}
                                disabled={false}
                                loading={false}
                                buttonType='red'
                                icon={'fa-trash'}
                            />
                        </div>

                    </WmsModel>

                </WmsCollapse>
            </div>

        )
    }

}