import React from 'react';

//component
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';
import WmsGrid from '../../../components/WmsGrid/WmsGrid.jsx';
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import { StringWms } from '../../../../../assets/ts/StringWms.ts';

//services
import * as GlobalService from '../../../global_services';
import * as metaDataService from '../../meta-data.service.js';

import * as GridConfig from '../grid_configuration.jsx';
import './advanced-contact-lookup-modal.scss';
import { kendo } from "../../../global_variable_service.jsx";

export class AdvancedContactLookupModal extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        let advance_Contact_Lookup_GridHeaders = StringWms.convertSringToOption(this.translate('Orders_ContactsAdvancedLookup_GridHeaders'));

        this.state = {
            selector: "OrderContactLookup",
            searchGif: false,
            columns: GridConfig.getContactLookupGridColumns(advance_Contact_Lookup_GridHeaders).columns,
            schema: GridConfig.getContactLookupGridSchema().schema,
            transport: GridConfig.getContactLookupGridTransport(this.props.selectedCustomer.uri, this.props.sharedContactType).transport,
            totalResult: this.translate('Label_Results_ContactLookup'),
            searchValue : '',
            sharedIsAdvanceContactModal:props.sharedIsAdvanceContactModal
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.sharedIsAdvanceContactModal != this.state.sharedIsAdvanceContactModal){
            this.setState({ sharedIsAdvanceContactModal : nextProps.sharedIsAdvanceContactModal });
        }
    }

    selectRow = (KendoGrid) => {
        this.setState({
            sharedIsAdvanceContactModal:false
        });
        setTimeout(() => {
            this.props.onCloseLookUpModal();
            let selectedObj = {
                contactType: this.props.sharedContactType,
                selectedContact: KendoGrid.getItem(KendoGrid.getKeys())
            };
            this.props.onContactSelect(selectedObj);
        }, 0);
    }

    onGridBinding = () => {
        var grid = $("#GridAdvancedContactLookup").data("kendoGrid");
        if (grid) {
            this.setState({ totalResult: grid.dataSource.total() });
        }
    }

    eventClickSearchGrid = () => {

        this.setState({ searchGif: true });

        var dataSource = new kendo.data.DataSource({
            transport: {
                read: {
                    url: "/WebUI/Shipping/Orders/AdvancedContactGridRead",
                    type: "POST",
                    data: {
                        customerUri: this.props.selectedCustomer.uri,
                        usedAs: this.props.sharedContactType,
                        globalSearch: this.state.searchValue
                    },
                    dataType: "JSON"
                }
            },
            error: (xhr, error) => {
                this.setState({ searchGif: false });
            },
            batch: true,
            schema: this.state.schema,
            pageSize: 100,
            serverPaging: true,
            serverSorting: true,
            serverFiltering: true
        });
        dataSource.fetch(() => { this.setState({ searchGif: false }); });
        let grid = $("#GridAdvancedContactLookup").data("kendoGrid");
        grid.setDataSource(dataSource);
    }




    onChangeGridconfig = (param_grid_config) =>{
        metaDataService.setManageOrdersContactLookupGridOptionInMetaData(param_grid_config);
    }

    onResetGrid = () =>{
        // var grid = $("#GridAdvancedContactLookup").data("kendoGrid");
        // grid.setOptions({ columns: this.state.columns });
        // grid.dataSource.read();
        metaDataService.setManageOrdersContactLookupGridOptionInMetaData({ 'optionsData':{} });
    }

    handleSearchChange = (event) => {
        this.setState({ searchValue: event.target.value });
    }

    render() {
        return (
            <WmsModel
                id={'advancedContactLookupModal' + this.props.windowId}
                title={this.translate('Label_Advanced_Contact_Lookup')}
                class={'advancedContactLookupModal'}
                width={1200}
                height={565}
                onCloseFunc={this.props.onCloseLookUpModal}
                isOpen={this.state.sharedIsAdvanceContactModal}
                customClass='wms-model-new-ui-wrapper'>
                <div className="model-content-wrapper">
                <div className="wms-search-wrapper">
                <WmsInput
                    id='searchTerm'
                    inputName='searchTerm'
                    wmsSelector={this.state.selector + 'SearchInput'+ this.props.windowId}
                    value={this.state.searchValue}
                    onChangeFunc={this.handleSearchChange}
                    label={this.translate('Label_Contact_Search')} />

                <WmsButton
                    label={this.translate('Label_Search')}
                    wmsSelector={this.state.selector + 'SearchButton'+ this.props.windowId}
                    onClickFunc={this.eventClickSearchGrid}
                    loading={this.state.searchGif}
                    icon='fa-search' />
                </div>
                {/* <div className="filter-line wms-toolbar">

                    <div className="text pull-left">
                        <span id="TotalAdvancedContactLookup">{this.state.totalResult}</span>
                        <span style={{ 'fontSize': '10px', 'fontStyle': 'italic' }}>
                            {this.translate('Label_Include_Column_Text')}
                        </span>
                    </div>

                    <WmsButton
                        label={this.translate('Label_Restore_Default_Grid_Layout')}
                        wmsSelector={this.state.selector + 'RestoreDefaultGridLayoutButton'}
                        onClickFunc={this.onResetGrid}
                        icon=' fa-repeat' />

                </div> */}

                {this.state.sharedIsAdvanceContactModal == true &&
                    <WmsGrid
                        wmsSelector={this.state.selector + "Grid"}
                        gridKeySelection={'ContactId'}
                        gridName={'GridAdvancedContactLookup'}
                        isAlignIndexesByProperty={true}
                        onGridBinding={this.onGridBinding}
                        virtual={true}
                        virtualGridpageSize={100}
                        shawCheckbox={false}
                        showResetButton={true}
                        showGridResult={true}
                        showGridSummary={true}
                        showColumnFilter={false}
                        showSortColumns={false}
                        onResetGrid={this.onResetGrid}
                        id={'GridAdvancedContactLookup'}
                        columns={this.state.columns}
                        schema={this.state.schema}
                        transport={this.state.transport}
                        onSelection={this.selectRow}
                        getGridSelectionUrl={''}
                        resetButtonLabel={this.translate('Label_Options')}
                        searchUrl={''}
                        menu={[]}
                        layoutData={metaDataService.getManageOrdersContactLookupGridOptionInMetaData()}
                        onSort={this.onChangeGridconfig}
                        onColumnShow={this.onChangeGridconfig}
                        onColumnHide={this.onChangeGridconfig}
                        onColumnReorder={this.onChangeGridconfig}
                        onColumnResize={this.onChangeGridconfig} />
                }
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Close')}
                        wmsSelector={this.state.selector + "Exit"}
                        onClickFunc={this.props.onCloseLookUpModal}
                        buttonType="orange"
                        icon={'fa-times-circle-o'}
                    />
                </div>
            </WmsModel>
        );
    }
}