import React, { useState, useEffect } from "react";
import ManageAssemblySearch from "./manage-assembly-search.component";
import ManageAssemblyGrid from "./manage-assembly-grid.component";
import "./manageassembly.scss";
import * as ManageAssemblyServices from "./manage-assembly.services";
interface Props {}

declare var window: any;
var $rootScope = window;
const wmsSelector = "ManageAssembly";
const defaultFilterValue = {
    CustomerId: 0,
    FacilityId: 0,
    Skus: [],
    ReferenceNum: "",
    StartDueDate: null,
    EndDueDate: null,
    IndexOptionsStatus: 1,
};

const ManageAssembliyHolder: React.FC<Props> = (props: Props): any => {
    const [windowId] = useState($rootScope.ChildBulletin.Data.Window);
    const [sharedSearchOn, setSharedSearchOn] = useState(false);
    const [sharedFilterObject, setSharedFilterObject] = useState(defaultFilterValue);
    const [loadGrid, setLoadGrid] = useState(false);
    const [isLoadMetaServices, setIsLoadMetaServices] = useState(false);
    // const [gridMetaData, setGridMetaData] = useState([]);

    useEffect(() => {
        async function getGridMeta() {
            await ManageAssemblyServices.getManageAssemblyClientMetaData();
            let filterData = ManageAssemblyServices.getManageAssemblyFilterMetaData();
            if (filterData) {
                setSharedFilterObject(filterData);
            }
            setLoadGrid(true);
            setIsLoadMetaServices(true);
        }
        getGridMeta();
    }, []);

    // const setGridMetaDataObj = (gridMetaData) => {
    //     setGridMetaData(gridMetaData);
    // };

    const onClearSearch = () => {
        ManageAssemblyServices.clearManageAssemblyFilterMetaData();
        setSharedSearchOn(false);
        setSharedFilterObject(defaultFilterValue);
    };

    const onFilterDataSet = (filterObject: any) => {
        if (
            filterObject.CustomerID !== 0 ||
            filterObject.FacilityID !== 0 ||
            filterObject.WarehouseTransactionID !== null ||
            filterObject.ReferenceNum !== null ||
            filterObject.StartDate !== null ||
            filterObject.EndDate !== null
        ) {
            setSharedSearchOn(true);
        }
        console.log("filterObject", filterObject);
        setSharedFilterObject(filterObject);
        ManageAssemblyServices.setManageAssemblyFilterMetaData(filterObject);
    };

    const onApplySavedFilter = (filterObject) => {
        setSharedFilterObject(filterObject);
        setSharedSearchOn(true);
        ManageAssemblyServices.setManageAssemblyFilterMetaData(filterObject);

        // if (this.gridComponent) {
        //     var grid = $('.' + this.state.windowId + ' #' + this.manageAttachFileAssemblyGridId).data("kendoGrid");
        //     var dataSource = grid.dataSource;
        //     this.gridComponent.setFilterAppliedText(dataSource.filter());
        //     this.gridComponent.onGridFilterApplied(param_filterObj);
        // }
    };

    return (
        <div id="manage-assembly-holder">
            {isLoadMetaServices && (
                <ManageAssemblySearch
                    wmsSelector={wmsSelector + "Search"}
                    sharedSearchOn={sharedSearchOn}
                    sharedFilterObject={sharedFilterObject}
                    onClearSearch={onClearSearch}
                    onFilterDataSet={onFilterDataSet}
                    windowId={windowId}
                    onApplySavedFilter={onApplySavedFilter}
                />
            )}
            {loadGrid && (
                <ManageAssemblyGrid
                    wmsSelector={wmsSelector}
                    windowId={windowId}
                    sharedFilterObject={sharedFilterObject}
                />
            )}
        </div>
    );
};
ManageAssembliyHolder.propTypes = {};

ManageAssembliyHolder.defaultProps = {};

export default React.memo(ManageAssembliyHolder);
