import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse';
import * as GridConfig from "./customer-packing-slips-grid-configuration"
import * as CustomerPackingSlipsService from './customer-packing-slips.services';
import VirtualGridComponent from '../components_proto/VirtualGridComponent/VirtualGridComponent';
import * as GlobalService from './../global_services';
import './customerPackagingSlips.scss';
import PackingSlipsTemplateModel from './packing-slips-template-model.component';
import CustomerPackingSlipsTemplateImage from './packing-slips-template-image.component';
interface Props {
    class?: string,
    sharedCustomerEditUri?: string,
    sharedCustomer: any,
    originalCustomerObject: any,
    onCustomerDataset: any,
    setValidTemplateImageUrl: any
    windowId: string,
}

declare var window: any;
const { $ } = window;
let localKendoGrid = null; // it's reset every time null when function component  load,
// so in grid filter or after grid refresh on clear selection it getting null value.

const defaultFormData = {
    Description: '',
    Enabled: false,
    Uri: '',
    FooterUrlOverride: '',
    LogoUrlOverride: ''
}

const CustomerPackingSlipsGrid: React.FC<Props> = (props: Props): any => { // eslint-disable-line sonarjs/cognitive-complexity
    const selector = "CustomerPackingSlips";
    const gridName = "CustomerPackingSlipsGrid";
    const windowId = props.windowId;
    const Rights = GlobalService.GetRights();
    const gridDomSelector = windowId ? '.' + windowId + ' #' + gridName : '#' + gridName;
    const defaultColumns = GridConfig.getCustomerPackingSlipsColumns().columns;
    const allColumnList = GridConfig.getCustomerPackingSlipsAllColumnList();
    const [schema] = useState(GridConfig.getCustomerPackingSlipsSchema());

    const [gridData, setGridData] = useState([]);
    const [recordsCount, setRecordsCount] = useState(0);
    const [selectedRow, setSelectedRow] = useState(defaultFormData);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [packagingTypesGridConfig, setGridConfig] = useState('');
    const [filterText, setFilterText] = useState('');
    const [fullFilterText, setFullFilterText] = useState('');
    const [showGrid, setShowGrid] = useState(false);
    const [isOpenPackingSlipsTemplateModal, setPackingSlipsTemplateModal] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    const onGridDataBound = (e, kendoGrid) => {
        if(localKendoGrid == null) {
            localKendoGrid = kendoGrid;
        }
    }
    const onselectGridRow = (KendoGrid: any) => {
        let grid = $(gridDomSelector).data("kendoGrid");
        var data = grid.dataItem(grid.select());

        if (data) {
            setSelectedRow(data);
            setSelectedRowCount(KendoGrid.getNumberOfSelections());
        } else {
            setSelectedRow(defaultFormData);
            setSelectedRowCount(0);
        }
    }
    // on grid column sort save changes in meta data object
    const onSort = (event) => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
        CustomerPackingSlipsService.setCustomerPackingSlipsGridOptionInMetaData(finalSubmission);
    }

    // on click grid reset default grid layout menu
    const onClickResetGrid = () => {

    }

    // on grid filter selection
    const onGridFilterApplied = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            let filter = dataSource.filter()
            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, filter);
            CustomerPackingSlipsService.setCustomerPackingSlipsGridOptionInMetaData(finalSubmission);
            setFilterAppliedText(filter);
        }, 50);
    }
    const setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            setFilterText('');
            setFullFilterText('');
            return;
        }
        var filterConditionTitle = {
            "lte": "Is Less Than Or Equal To",
            "eq": "Is Equal To",
            "lt": "Is Less Than",
            "neq": "Is Not Equal To",
            "gte": "Is Greater Than Or Equal To",
            "gt": "Is Greater Than",
            "startswith": "Starts With",
            "endswith": "Ends With",
            "contains": "Contains",
            "doesnotcontain": "Does Not Contain",
            "isnull": "Is Null",
            "isnotnull": "Is Not Null",
            "isempty": "Is Empty",
            "isnotempty": "Is Not Empty",
            "isnullorempty": "Has No Value",
            "isnotnullorempty": "Has Value",
        }

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;

        filterList.map((item) => {
            var title = allColumnList.find(x => x.field === item.field).title;
            filterText += title + " : " + item.value + ',';
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            return item;
        });
        filterText = filterText.slice(0, -1);
        setFilterText(filterText);
        setFullFilterText(fullFilterText);
    }
    const clickEventOfContextMenu = (obj: any, e: any) => {
        editPackingSlipModal();
    }

    const editPackingSlipModal = () => {
        let grid = $(gridDomSelector).data("kendoGrid");
        var data = grid.dataItem(grid.select());
        setSelectedRow(data);
        setPackingSlipsTemplateModal(true)
    }
    const onClosePackingSlipsTemplateModal = () => {
        setPackingSlipsTemplateModal(false)
    }

    const loadGridData = useCallback(() => {
        kendo.ui.progress($(gridDomSelector), true);
        setRecordsCount(0);
        setGridData([]);
        CustomerPackingSlipsService.getCustomerPackingSlipsGridData(props.sharedCustomerEditUri).then((response: any) => {
            let tmpPackingSlipData = response.data.CustomerPackingListTemplateList
            tmpPackingSlipData.map((data: any) => {
                data.Description = returnIdentifier(data.TemplateIdentifier);
                return false;
            });
            setGridData(tmpPackingSlipData);
            setRecordsCount(tmpPackingSlipData.length);
        }).finally(() => {
            kendo.ui.progress($(gridDomSelector), false);
        })
    }, [gridDomSelector, props.sharedCustomerEditUri]);

    const refreshGrid = () => {
        loadGridData();
        setSelectedRow(defaultFormData);
        if (localKendoGrid != null) {
            localKendoGrid.clearSelections(() => {
                localKendoGrid.repaintSelections();
            });
        }
        setSelectedRowCount(0);
        setIsLoadingSave(false);
    }

    const afterUpdateSuccess = () => {
        refreshGrid();
        setIsLoadingSave(false);
        GlobalService.notification('success', window.translate('Label_Configure_Packing_Slips_save_success'));
    }

    const onEnableDisableBtnClick = () => {
        setIsLoadingSave(true);
        if(selectedRow.Enabled){
            CustomerPackingSlipsService.deleteCustomerPackingSlipsData(selectedRow.Uri).then(() => {
                afterUpdateSuccess();
            }).finally(() => {
                setIsLoadingSave(false);
            });
        }else{
            var data = {
                FooterUrlOverride: selectedRow.FooterUrlOverride,
                LogoUrlOverride: selectedRow.LogoUrlOverride,
                Uri: selectedRow.Uri
            }
            CustomerPackingSlipsService.updateCustomerPackingSlipsData(data).then(() => {
                afterUpdateSuccess();
            }).finally(() => {
                setIsLoadingSave(false);
            });
        }
    }

    const changeDefaultTemeplate = ( imgUrl: string, fieldName: string): void => {
        let parentObj = props.sharedCustomer.Options.Shipping;
        if (fieldName === 'FooterUrlOverride') {
            parentObj.PackingListFooterUrl = imgUrl;
        } else {
            parentObj.PackingListLogoUrl = imgUrl
        }

        let customerObj = Object.assign(
            {},
            props.sharedCustomer,
            {
                Options: Object.assign(
                    {},
                    props.sharedCustomer.Options,
                    {
                        Shipping: parentObj
                    }
                )
            }
        );
        props.onCustomerDataset(customerObj);
    }

    useEffect(() => {
        async function getGridMeta() {
            await CustomerPackingSlipsService.getUserClientMetaData()
            setGridConfig(CustomerPackingSlipsService.getCustomerPackingSlipsGridOptionInMetaData());
            setShowGrid(true);
            loadGridData();
        }
        if(props.sharedCustomerEditUri){
            getGridMeta();
        }
    }, [props.sharedCustomerEditUri, loadGridData]);

    return (
        <div id="customer-paking-slip-component" key={props.sharedCustomerEditUri}>
            <WmsCollapse
                id={selector + '_collapse'}
                headerLabel={window.translate('Label_Packing_Slip')}
                showCollapseIcon={false}
                showCollapseText={false}
                isDisabled={!Rights.hasOwnProperty('customer.packingslip') || props.sharedCustomerEditUri === ''}
                isExpanded={props.sharedCustomerEditUri !== '' && Rights.hasOwnProperty('customer.packingslip')}
                >
                {showGrid && (
                    <VirtualGridComponent
                        shawCheckbox={false}
                        wmsSelector={gridName}
                        gridName={gridName}
                        id={gridName}
                        loading={false}
                        showSortColumns={true}
                        showGridResult={true}
                        showGridSummary={true}
                        staticGridData={gridData}
                        totalResult={recordsCount}
                        onSelection={onselectGridRow}
                        gridKeySelection={'RowNumber'}
                        showResetButton={false}
                        onClickResetGrid={onClickResetGrid}
                        onDataBound={onGridDataBound}
                        onSort={onSort}
                        virtual={true}
                        metaDataFieldsName={'ManageCustomerPackingSlips'}
                        metaGridConfig={packagingTypesGridConfig}
                        defaultColumns={defaultColumns}
                        allColumns={allColumnList}
                        schema={schema}
                        windowId={windowId}
                        filterText={filterText}
                        fullFilterText={fullFilterText}
                        onGridFilterApplied={onGridFilterApplied}
                        clickEventOfContextMenu={clickEventOfContextMenu}
                        menu={
                            [{
                                "name": selectedRow.Enabled ? window.translate("Label_Disable_Config"): window.translate("Label_Enable_Config"),
                                "value": [],
                                "icon": "fa-check",
                                "color": "green",
                                "wmsSelector": selector + "EnableConfig",
                                "disabled": selectedRowCount !== 1 || isLoadingSave,
                                "disabledText": "You may not add blahssss0",
                                'isContextMenu': false,
                                "clickFunc": () => onEnableDisableBtnClick(),
                                'isButtonMenu': true,
                                'loading': isLoadingSave
                            }, {
                                "name": window.translate('Label_Edit'),
                                "value": [],
                                "disabled": selectedRowCount !== 1 || isLoadingSave,
                                "disabledText": "You may not add blahssss0",
                                "icon": "fa-pencil",
                                "color": "green",
                                "wmsSelector": selector + "Edit",
                                "clickFunc": () => editPackingSlipModal(),
                                'isContextMenu': true,
                                'isButtonMenu': true,
                                'wmsid': 'packagingTypesEdit'
                            }]
                        }
                    />
                )}
                { props.sharedCustomer.Options.Shipping.PackingListFooterUrl !== undefined ? (
                <>
                    <hr/>
                    <CustomerPackingSlipsTemplateImage
                        wmsSelector={'Default_Header'}
                        oldValue={props.originalCustomerObject.Options.Shipping.PackingListLogoUrl}
                        fieldName={'LogoUrlOverride'}
                        caption={'Default_Header'}
                        changeImageField={changeDefaultTemeplate}
                        className={'Default_Template'}
                        setValidImageUrl={props.setValidTemplateImageUrl}
                        maxWidth={257}
                        maxHeight={63}
                    />
                    <hr/>
                    <CustomerPackingSlipsTemplateImage
                        wmsSelector={'Default_Footer'}
                        oldValue={props.originalCustomerObject.Options.Shipping.PackingListFooterUrl}
                        fieldName={'FooterUrlOverride'}
                        caption={'Default_Footer'}
                        changeImageField={changeDefaultTemeplate}
                        className={'Default_Template'}
                        setValidImageUrl={props.setValidTemplateImageUrl}
                        maxWidth={547}
                        maxHeight={76}
                    />
                </>
                ) : null}

                {isOpenPackingSlipsTemplateModal &&(
                    <PackingSlipsTemplateModel
                        isOpenPackingSlipsTemplateModal={isOpenPackingSlipsTemplateModal}
                        onClosePackingSlipsTemplateModal={onClosePackingSlipsTemplateModal}
                        selectedRow={selectedRow}
                        refreshGrid={refreshGrid}
                        sharedCustomer={props.sharedCustomer}
                        defaultHeader={props.originalCustomerObject.Options.Shipping.PackingListLogoUrl}
                        defaultFooter={props.originalCustomerObject.Options.Shipping.PackingListFooterUrl}
                    />
                )}
            </WmsCollapse>
        </div>
    )
}
const returnIdentifier = (identifier) => {
    if(identifier && identifier.Name ) {
        return identifier.Name
    } else {
        return ''
    }
}
CustomerPackingSlipsGrid.propTypes = {
    class: PropTypes.string.isRequired, // this should write the id to the button
    sharedCustomerEditUri: PropTypes.string.isRequired, // this should write the id to the button
    sharedCustomer: PropTypes.any.isRequired,
    originalCustomerObject: PropTypes.any.isRequired,
    setValidTemplateImageUrl: PropTypes.any.isRequired,
    windowId: PropTypes.any.isRequired,
};

CustomerPackingSlipsGrid.defaultProps = {
    class: '',
    sharedCustomerEditUri: '',
    sharedCustomer: {},
    originalCustomerObject: {},
    setValidTemplateImageUrl: {},
    windowId: ''
};

export default React.memo(CustomerPackingSlipsGrid);