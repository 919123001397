import React from "react";
import WmsButton from "../react/components/WmsButton/WmsButton.jsx";
import WmsDropdown from "../react/components/WmsDropdown/WmsDropdown.jsx";
import WmsCollapse from "../react/components/WmsCollapse/WmsCollapse.jsx";
import PropTypes from "prop-types";
export class EventNotificationSearch extends React.Component {
    constructor(props) {
        super(props);
        this.translate = window.translate;
        this.$rootScope = window;
    }

    handleFilterChange = (event) => {
        let propFilter = Object.assign({}, this.props.sharedEventNotificationFilter);
        let objFilter = Object.assign(propFilter, { [event.target.name]: event.target.value });
        this.props.onFilterDataSet(objFilter);
    };

    render() {
        return (
            <div className={"new-layout-filter-wrapper wms-event-notification-search"}>
                <div className="event-notification-search-filter">
                    <div className="wms-toolbar">
                        <WmsButton
                            label={this.translate("Label_Clear_Filters")}
                            buttonType="orange"
                            wmsSelector={this.props.selector + "ClearSearchButton"}
                            onClickFunc={this.props.onClearSearch}
                            icon="fa-repeat"
                        />
                    </div>

                    {this.props.sharedSearchOn && (
                        <div className="title">
                            <b>{this.translate("Label_NOTE")}: </b>
                            <label>{this.translate("Label_Search_Applied_Filter")}</label>
                        </div>
                    )}
                    <div className="event-notification-form-boxes-wrapper">
                        <div
                            className={
                                "event-notification-form-boxes " + (this.props.sharedSearchOn ? "width-title" : "")
                            }
                        >
                            <div className="event-notification-area-content">
                                <WmsCollapse
                                    id={"search_collapse1"}
                                    headerLabel={this.translate("Label_Filter_Search_By_General")}
                                    showCollapseIcon={false}
                                    showCollapseText={false}
                                >
                                    <WmsDropdown
                                        id="Customer"
                                        name="customerEditUri"
                                        label={this.translate("Label_Webhook_Scope")}
                                        wmsSelector={this.props.selector + "Customer"}
                                        value={this.props.sharedEventNotificationFilter.customerEditUri}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.props.sharedCustomerOptions}
                                        textField="Name"
                                        valueField="EditUri"
                                    />
                                </WmsCollapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EventNotificationSearch.defaultProps = {
    onFilterDataSet: "",
    selector: "",
    onClearSearch: "",
    sharedSearchOn: false,
    sharedCustomerOptions: "",
    sharedEventNotificationFilter: "",
};

EventNotificationSearch.propTypes = {
    onFilterDataSet: PropTypes.func,
    selector: PropTypes.string,
    onClearSearch: PropTypes.func,
    sharedSearchOn: PropTypes.bool,
    sharedCustomerOptions: PropTypes.object,
    sharedEventNotificationFilter: PropTypes.object,
};
