import * as GlobalService from "./../../../../global_services";
var isFirstTimeOpen = true;
var keyName = "MassAdd";
var metaDataOrderObject = {
    MassAdd: {
    ManageMassAdd: {
      filterData: {},
      gridConfig: {},
      collapseData: {},
      sorableSection: "",
      saveFilter: [],
    }
  }
};

// call api for get metaData of order is it's open first time
export const getUserClientMetaData = function() {
  if (isFirstTimeOpen) {
    return GlobalService.getUserClientMetaData(keyName).then(
      function successCallBack(response) {
        if (response != "") {
          Object.assign(metaDataOrderObject[keyName], JSON.parse(response));
        }
        console.log("response  metaDataOrderObject :", metaDataOrderObject);
        isFirstTimeOpen = false;
        return true;
      },
      function errorCallBack(response) {
        console.log(response);
        return false;
      }
    );
  }
};

export const clearInventoryFilterMetaData = function(objName) {
  metaDataOrderObject[keyName]["ManageMassAdd"]["filterData"] = {};
  GlobalService.setUserClientMetaData(
    keyName,
    JSON.stringify(metaDataOrderObject[keyName])
  );
};

export const setMassAddFilterMetaData = function (filterData) {
    metaDataOrderObject[keyName]['ManageMassAdd']['filterData'] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const getMassAddFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageMassAdd']['filterData'])) {
        return metaDataOrderObject[keyName]['ManageMassAdd']['filterData'];
    } else {
        return "";
    }

}

export const setMassAddGridOptionInMetaData = function(
  gridConfig
) {
  metaDataOrderObject[keyName]["ManageMassAdd"]["gridConfig"] =
    gridConfig.optionsData;
  GlobalService.setUserClientMetaData(
    keyName,
    JSON.stringify(metaDataOrderObject[keyName])
  );
};
export const getMassAddGridOptionInMetaData = function() {
  if (
    !$.isEmptyObject(
      metaDataOrderObject[keyName]["ManageMassAdd"]["gridConfig"]
    )
  ) {
    return metaDataOrderObject[keyName]["ManageMassAdd"]["gridConfig"];
  } else {
    return "";
  }
};

export const setMassAddCollapseMetaData = function (collapseId, value) {
  if (!metaDataOrderObject[keyName]['ManageMassAdd']['collapseData']) {
      metaDataOrderObject[keyName]['ManageMassAdd']['collapseData'] = {}
  }
  metaDataOrderObject[keyName]['ManageMassAdd']['collapseData'][collapseId] = value;
  GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const getMassAddCollapseMetaData = function () {
  return metaDataOrderObject[keyName]['ManageMassAdd']['collapseData'];
}

export const setMassAddSortSectionMetaData = function (sortArray) {
  metaDataOrderObject[keyName]['ManageMassAdd']['sorableSection'] = sortArray;
  GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const getMassAddSortSectionMetaData = function () {
  return metaDataOrderObject[keyName]['ManageMassAdd']['sorableSection'];
}

// save filter of find order in meta data
export const saveMassAddFilterMetaData = function (title, filterObj, columnObj) {
  var setData = {
      title: title,
      filterData: filterObj,
      columnData: columnObj.optionsData
  };

  if (typeof metaDataOrderObject[keyName]['ManageMassAdd']['saveFilter'] == "undefined") {
      metaDataOrderObject[keyName]['ManageMassAdd']['saveFilter'] = [];
  }
  (metaDataOrderObject[keyName]['ManageMassAdd']['saveFilter']).push(setData);
  GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));

}
export const updateMassAddFilterMetaData = function (index, filterObj, columnObj) {

  if (typeof metaDataOrderObject[keyName]['ManageMassAdd']['saveFilter'][index] != "undefined") {
      metaDataOrderObject[keyName]['ManageMassAdd']['saveFilter'][index].filterData = filterObj;
      metaDataOrderObject[keyName]['ManageMassAdd']['saveFilter'][index].columnData = columnObj.optionsData;
      GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
  }

}
export const getSaveMassAddFilterListMetaData = function () {
  if (metaDataOrderObject[keyName]['ManageMassAdd']['saveFilter']) {
    return metaDataOrderObject[keyName]['ManageMassAdd']['saveFilter'];
  }

  return [];
}

export const clearSaveMassAddFilterListMetaData = function (index) {
  metaDataOrderObject[keyName]['ManageMassAdd']['saveFilter'].splice(index, 1);
  GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}