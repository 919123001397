import * as GlobalService from "../global_services";
var isFirstTimeOpen = true;
var keyName = "Item";
var metaDataItemObject = {
    Item: {
        customerId: 0
    }
};

// call api for get metaData of item customer is it's open first time
export const getItemClientMetaData = function() {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response != "") {
                    Object.assign(metaDataItemObject[keyName], JSON.parse(response));
                }
                console.log("response  metaDataItemObject :", metaDataItemObject);
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                console.log(response);
                return false;
            }
        );
    }
};

export const clearItemFilterMetaData = function(objName) {
    metaDataItemObject[keyName]["customerId"] = 0;
    GlobalService.setUserClientMetaData(
        keyName,
        JSON.stringify(metaDataItemObject[keyName])
    );
};

export const setCustomerIdFilterMetaData = function(custId) {
    metaDataItemObject[keyName]['customerId'] = custId;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataItemObject[keyName]));
}

export const getCustomerIdFilterMetaData = function() {
    if (!$.isEmptyObject(metaDataItemObject[keyName]['customerId'])) {
        return metaDataItemObject[keyName]['customerId'];
    } else {
        return "";
    }
}