import * as GlobalService from "../react/global_services";
var isFirstTimeOpen = true;
var keyName = "MobileLabelTemplates";
var metaDataMobileLabelTemplatesObject = {
    "MobileLabelTemplates": {
        "filterData": {},
        "gridConfig": {},
    },
};
var axios = require("axios");
declare var window: any;
const { $ } = window;
const labelUrl = "/WebUI/settings/zpllabel/Label";

export const setGridFilter = function (param_filterObj, cancleToken) {
    console.log("SetGridFilter", param_filterObj);

    let url = "/WebUI/settings/zpllabel/FilterCount";

    return axios.get(url, { params: param_filterObj }, { cancelToken: cancleToken }).then((response: any) => {
        return response;
    });
};

export const saveMobileLabelTemplatesData = function (data) {
    return axios.post(labelUrl, data).then(function (response) {
        return response;
    });
};

export const updateMobileLabelTemplatesData = function (data) {
    return axios.put(labelUrl, data).then(function (response) {
        return response;
    });
};

export const deleteMobileLabelTemplatesData = function (ZplLabelId) {
    return axios.delete(labelUrl + "?ZplLabelId=" + ZplLabelId).then(function (response) {
        return response;
    });
};

// call api for get metaData of MobileLabelTemplates is it's open first time
export const getMobileLabelTemplatesClientMetaData = function () {
    console.log("isFirstTimeOpen", isFirstTimeOpen);
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response !== "") {
                    // eslint-disable-next-line security/detect-object-injection
                    Object.assign(metaDataMobileLabelTemplatesObject[keyName], JSON.parse(response));
                }
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                return false;
            }
        );
    }
};

export const setMobileLabelTemplatesFilterMetaData = function (filterData) {
    // eslint-disable-next-line security/detect-object-injection
    metaDataMobileLabelTemplatesObject[keyName]["filterData"] = filterData;
    // eslint-disable-next-line security/detect-object-injection
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataMobileLabelTemplatesObject[keyName]));
};

export const getMobileLabelTemplatesFilterMetaData = function () {
    // eslint-disable-next-line security/detect-object-injection
    if (!$.isEmptyObject(metaDataMobileLabelTemplatesObject[keyName]["filterData"])) {
        // eslint-disable-next-line security/detect-object-injection
        return metaDataMobileLabelTemplatesObject[keyName]["filterData"];
    } else {
        return "";
    }
};
export const setMobileLabelTemplatesGridOptionInMetaData = function (gridConfig) {
    // eslint-disable-next-line security/detect-object-injection
    metaDataMobileLabelTemplatesObject[keyName]["gridConfig"] = gridConfig.optionsData;
    // eslint-disable-next-line security/detect-object-injection
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataMobileLabelTemplatesObject[keyName]));
};
export const getMobileLabelTemplatesGridOptionInMetaData = function () {
    // eslint-disable-next-line security/detect-object-injection
    if (!$.isEmptyObject(metaDataMobileLabelTemplatesObject[keyName]["gridConfig"])) {
        // eslint-disable-next-line security/detect-object-injection
        return metaDataMobileLabelTemplatesObject[keyName]["gridConfig"];
    } else {
        return "";
    }
};

export const clearMobileLabelTemplatesFilterMetaData = function () {
    // eslint-disable-next-line security/detect-object-injection
    metaDataMobileLabelTemplatesObject[keyName]["filterData"] = {};
    // eslint-disable-next-line security/detect-object-injection
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataMobileLabelTemplatesObject[keyName]));
};

export const saveReverseAdjustment = function (uri: any, data: any) {
    let url = "/WebUI/Inventory/adjustments/adjustmentreverser?uri=" + uri;
    return axios.post(url, data).then(function (response: any) {
        return response;
    });
};

export const getReceiveItemGridData = function (data: any) {
    let url = "/WebUI/Inventory/InventoryUI/InventoryData?sorts=[]&filters=[]&pgnum=1&pgsiz=50";
    return axios
        .put(url, {
            columns: [
                "ReceiveItemId",
                "ReceiptDate",
                "ReceiverId",
                "ExpirationDate",
                "MovableUnitLabel",
                "LocationName",
                "PrimaryAvailable",
            ], // Receved, MovablUnit,
            inventorySearchInfo: data,
        })
        .then(function (response: any) {
            return response;
        });
};

export const getCharges = function () {
    return axios.get("/WebUI/Shipping/Orders/GetChargeCategories").then(function (response: any) {
        return response;
    });
};
