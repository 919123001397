import React from 'react';

//component
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsContainer from '../../../components/WmsContainer/WmsContainer.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';
import { ArrayWms } from '../../../../../assets/ts/ArrayWms.ts';
import './shipping-label-model.scss';
import * as findOrdersService from '../../../orders/findOrders/findOrders.services.jsx';

export class ShippingLabel extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;

        this.state = {
            Selector: "OrderShippingLabel",
            generateGif: false,
            labelsToPrint: 1,
            reportListSelectedIndex: 0
        }
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    generateShippingLabel = () => {
        this.setState({ generateGif: true });
        let tmpUri = this.props.sharedShippingLabelOption.Reports[this.state.reportListSelectedIndex].TemplatedRunner;
        let param_obj = {
            "RunnerUriTemplate": tmpUri,
            "TotalLabelsRequested":parseInt(this.state.labelsToPrint),
            "TransactionIds": this.props.sharedSelectedOrderIds,
            "IgnoreDuplicates": false,
            "UtcOffset": new Date().getTimezoneOffset() / 60 * -1,
            "outputType": "application/pdf"
        }
        findOrdersService.generateShippingLabel(param_obj).then((response) => {
            this.setState({ generateGif: false });
            if(response){
                var blob = ArrayWms.convertBase64ToByteArray(response, 'application/pdf');
                ArrayWms.displayBlobInWindow(blob, 'report.pdf');
                this.props.onCloseShippingLabelModel();
            }
        }).finally(() => {  
            this.setState({ generateGif: false });
        });
    }

    render() {
        return (
            <WmsModel
                id='shippimgLabel'
                title={this.translate('Shipping_Labels')}
                width={450}
                height={295}
                onCloseFunc={this.props.onCloseShippingLabelModel}
                isOpen={this.props.sharedIsOpenShippingLabelModel}
                customClass='wms-model-new-ui-wrapper'>


                <div className="model-content-wrapper">
                <WmsContainer>
                    <WmsInput
                        inputName="labelsToPrint"
                        name="labelsToPrint"
                        wmsSelector={this.state.Selector + 'Description'}
                        value={this.state.labelsToPrint}
                        onChangeFunc={this.handleChange}
                        label={this.translate('Print_Labels')}
                        regularExp={/^[1-9][0-9]*$/} />

                    <WmsDropdown
                        id="reportList"
                        name="reportListSelectedIndex"
                        label={this.translate('Shipping_Template')}
                        textField='Name'
                        wmsSelector={this.state.Selector + 'reportList'}
                        value={this.state.reportListSelectedIndex}
                        onChangeFunc={this.handleChange}
                        options={this.props.sharedShippingLabelOption.Reports ? this.props.sharedShippingLabelOption.Reports : []}
                        applyIndex={true}
                    />
                </WmsContainer>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Cancel')}
                        wmsSelector={this.state.Selector + "Exit"}
                        onClickFunc={this.props.onCloseShippingLabelModel}
                        buttonType="yellow"
                        icon={'fa-ban'}
                    />
                    <WmsButton
                        label={this.translate('Label_Generate')}
                        wmsSelector={this.state.Selector + "Generate"}
                        onClickFunc={this.generateShippingLabel}
                        loading={this.state.generateGif}
                        disabled={
                            !this.state.labelsToPrint ||
                            this.state.labelsToPrint == 0 ||
                            !this.props.sharedShippingLabelOption.Reports ||
                            ( this.props.sharedShippingLabelOption.Reports && !this.props.sharedShippingLabelOption.Reports[this.state.reportListSelectedIndex].TemplatedRunner)}
                        icon={'fa-plus'}
                    />
                </div>
            </WmsModel>
        )
    }
}