
import React from 'react';
import { WarehouseForm } from './warehouse-form.component.jsx';
import { WarehouseGrid } from './warehouse-grid.component.jsx';

import style from './warehouse.scss';

export class warehouseHolder extends React.Component {
    constructor(props) {
        super(props);

        
        this.$rootScope = window;

        this.state = {
            counter: 0,
            showEditForm: false,
            reloadGrid: false,
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            deafaultWarehouseObj: {
                Name: '',
                Code: '',
                Address1: '',
                Address2: '',
                City: '',
                PhoneNumber: '',
                Fax: '',
                EmailAddress: '',
                UpsAccount: '',
                FedExAccount: '',
                FuelSurcharge: '',
                FreightRateLocationCode: '',
                Active: true,
                Country: 'US',
                State: 'California',
                Zip: '',
                TimeZoneName: '',
                LocationFieldCount: 4,
                MeasurementSystemDefault: 0,
                PreventPartialOrderManualAllocation: false,
                UsePredefinedLocations: false
            },
            shareWarehouseOptions: null,
            sharedWarehouse: null
        }
    }

    componentDidMount() {
        // console.log("react-warehouse-holder componentDidMount before counter: "+this.state.counter);
        // this.setState((prevState, props) => {
        //     return {counter: prevState.counter + 1};
        // });
        // console.log("react-warehouse-holder componentDidMount  after counter: "+this.state.counter);
    }

    onCreateWarehouse = () => {
        this.setState({ sharedWarehouse: this.state.deafaultWarehouseObj });
    }

    onEditWarehouse = (param_warehouse) => {
        this.setState({ sharedWarehouse: param_warehouse });
    }

    onExitWarehouseForm = () => {
        this.setState({
            reloadGrid: '',
            sharedWarehouse: null
        });
    }

    onReceiveWarhouseOptions = (param_warehouse_options) => {
        this.setState({
            shareWarehouseOptions: param_warehouse_options
        });
    }

    onReloadGrid = (basecall) => {
        this.setState({ reloadGrid: basecall });
    }

    render() {
        return (
            <div id="warehouses-holder-react">
                    <WarehouseGrid
                        onCreateWarehouse={this.onCreateWarehouse}
                        onEditWarehouse={this.onEditWarehouse}
                        onReceiveWarhouseOptions={this.onReceiveWarhouseOptions}
                        reloadGrid={this.state.reloadGrid}
                        windowId={this.state.windowId}
                    />
                    {this.state.sharedWarehouse != null && (
                        <WarehouseForm
                            onReloadGrid={this.onReloadGrid}
                            sharedWarehouse={this.state.sharedWarehouse}
                            shareWarehouseOptions={this.state.shareWarehouseOptions}
                            onExitWarehouseForm={this.onExitWarehouseForm}
                            windowId={this.state.windowId} />
                    )}
                </div>
        )
    }
}
