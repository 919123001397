import React, { Component } from "react";
import { StringWms } from '../../../assets/ts/StringWms.ts';
//component
import WmsButton from "../components/WmsButton/WmsButton.jsx";
import WmsDropdown from "./../components/WmsDropdown/WmsDropdown.jsx";
import WmsContainer from "./../components/WmsContainer/WmsContainer.jsx";
import WmsRadio from "./../components/WmsRadio/WmsRadio.tsx";
import WmsCheckBox from "./../components/WmsCheckBox/WmsCheckBox.jsx";
import WmsConfirmModel from "../components/WmsConfirmModel/WmsConfirmModel.jsx";

// Services
import * as GlobalService from "./../global_services";
import * as orderService from "./order-preferences.services.jsx";

import style from "./orderImportPreferences.scss";

export class OrderPreferences extends Component {
    constructor(props) {
        super(props);

        
        this.translate = window.translate
        this.$rootScope = window;

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            Selector: "OrderPreferences",
            saveLoader: false,
            customLabels: GlobalService.getCustomLabels(),
            customerList: [],
            customizePreferencesList: StringWms.convertSringToOption(this.translate('Settings_OrderImportPref_CustomizePrefs')),
            isFormEdit: false,
            isOpenWarningModel: false,
            OnError: 0,
            IndexOptionsCustomizeDuringImport: "",
            WhatToImport: "",
            selectedCustomerIndex: "",
            selectedCustomer: "",
            CustomFormats: [],
            DefaultFormats: [],
            SelectedParsers: [],
            SelectedFormats: []
        };
    }
    componentDidMount() {
        kendo.ui.progress($('#' + this.state.windowId), true);
        GlobalService.GetCustomersAndFacility().then((response) => {
            this.setState({
                customerList: GlobalService.getCustomerList()
            });
        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
    }
    ClickExit = (e) => {
        this.onCloseConfirmModel();
        setTimeout(() => {
            GlobalService.closeWindow(this.state.windowId);
        }, 100);
    };

    EventGetOrderPreferences = (event) => {
        if (this.state.isFormEdit) {
            this.setState({ isOpenWarningModel: true });
            return;
        }

        var customerObj = this.getCustomerById(event.target.value);

        this.setState({
            selectedCustomerIndex: event.target.value,
            selectedCustomer: customerObj
        });

        kendo.ui.progress($('#' + this.state.windowId), true);

        orderService.getOrderImportPreferences(customerObj.uri).then((response) => {

            var workflow = response.Workflow;
            this.findDefaultChecksForFormat(response.Defaults);

            var combinedArray = response.Defaults.concat(response.Customs);
            this.findDefaultChecks(combinedArray);

            this.setState({
                CustomFormats: response.Customs,
                DefaultFormats: response.Defaults,
                OnError: workflow.OnError ? workflow.OnError : 0,
                WhatToImport: workflow.Criteria ? workflow.Criteria : 0,
                IndexOptionsCustomizeDuringImport: workflow.Workflow || workflow.Workflow === 0 ? workflow.Workflow : 2,
            });

            kendo.ui.progress($('#' + this.state.windowId), false);
        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
    };

    //-- Function to find out which checkboxes need to be checked initially
    findDefaultChecks(arr) {
        var SelectedParsers = [];
        arr.forEach((elem) => {
            if(elem.hasOwnProperty('IsSelected') && elem.IsSelected) {
                SelectedParsers.push(elem.Id);
            }
        });
        console.log("Oder Import SelectedParsers",SelectedParsers);
        this.setState({
            SelectedParsers: SelectedParsers,
            // DefaultSelectedParsers: JSON.parse(JSON.stringify(SelectedParsers)),
        });
    }

    findDefaultChecksForFormat(arr) {
        var SelectedFormats = [];
        arr.forEach((elem) => {
            if(elem.hasOwnProperty('IsSelected') && elem.IsSelected) {
                SelectedFormats.push(elem.Id);
            }
        });
        console.log("Oder Import SelectedFormats",SelectedFormats);
        this.setState({
            SelectedFormats: SelectedFormats,
        });
    }

    toggleSelectedParsers = (id, parseType) => (event) => {
        id = Number(id);
        var selectedParsers = this.state.SelectedParsers;
        var selectedFormats = this.state.SelectedFormats;

        var idx = this.state.SelectedParsers.indexOf(id);

        (idx === -1) ? selectedParsers.push(id) : selectedParsers.splice(idx, 1);

        if (parseType == 'format') {
            var idx = this.state.SelectedFormats.indexOf(id);

            (idx === -1) ? selectedFormats.push(id) : selectedFormats.splice(idx, 1);
        }

        // var filteredSelectedParsers = this.state.DefaultSelectedParsers.filter(element => selectedParsers.includes(element));
        // var isFormEdit = (this.state.DefaultSelectedParsers.length == selectedParsers.length && selectedParsers.length == filteredSelectedParsers.length)

        this.setState({
            isFormEdit: true,
            SelectedParsers: selectedParsers,
            SelectedFormats: selectedFormats
        });
    }

    ClickSaveAndExit = (event) => {
        var workflow = {
            OnError: Number(this.state.OnError),
            Criteria: Number(this.state.WhatToImport),
            Workflow: this.state.IndexOptionsCustomizeDuringImport
        };

        this.setState({
            saveLoader: true,
            isFormEdit: false,
        });

        orderService.SaveOrderImportPreferences(this.state.selectedCustomer.uri, workflow, this.state.SelectedParsers)
                .then((response) => {
                    console.log("response", response);
                    GlobalService.notification('success', this.translate('Label_OrderImport_Prefs_Success'));
                    this.setState({
                        saveLoader: false
                    });
                    this.ClickExit();
                }).finally(() => {
                    this.setState({ saveLoader: false });
                });
    }

    onCloseConfirmModel = () => {
        this.setState({ isOpenWarningModel: false });
    }

    ChangeImportPreferences = (value) => (event) => {
        this.setState({
            isFormEdit: true,
            [event.target.name] : value
        });
    }

    getCustomerById(id) {
        return this.state.customerList.find((element) => {
            if (element.id == id) {
                return element;
            }
        });
    }

    makeWmsSelector(string) {
        var selector = this.state.Selector;
        selector +=  string.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1);
        }).replace(/\s/g, "");

        return selector;
    }

    render() {
        return (
            <div id="order-preferences-holder-react">
                <div className="row">
                    <div className="wms-toolbar">

                        <WmsButton
                            label={this.translate("Settings_Label_SaveExit")}
                            wmsSelector={this.state.Selector + "ButtonSaveExit"}
                            onClickFunc={this.ClickSaveAndExit}
                            icon="fa-floppy-o"
                            buttonType="green"
                            showToolTip={ (this.state.SelectedFormats.length == 0 || !this.state.isFormEdit) ? true : false }
                            tooltipText={ (this.state.SelectedFormats.length == 0 && this.state.isFormEdit) ? this.translate("Label_Require_Format") : (!this.state.isFormEdit) ? this.translate("Tooltip_No_changes") : "" }
                            disabled={ (this.state.SelectedFormats.length == 0 || !this.state.isFormEdit) ? true : false }
                            loading={this.state.saveLoader}
                        />

                        {this.state.isFormEdit == false && (
                            <WmsButton
                                label={this.translate("Settings_Label_Exit")}
                                wmsSelector={this.state.Selector + "ButtonExit"}
                                onClickFunc={this.ClickExit}
                                icon="fa-sign-out"
                                buttonType="yellow"
                            />
                        )}

                        {this.state.isFormEdit == true && (
                            <WmsButton
                                label={this.translate("Settings_Label_DiscardExit")}
                                wmsSelector={this.state.Selector + "ButtonDiscard"}
                                onClickFunc={this.ClickExit}
                                icon="fa-times-circle"
                                buttonType="yellow"
                            />
                        )}
                    </div>
                </div>

                <WmsContainer>
                    <WmsDropdown
                        id="orderPreferencesHolderIndexOptionsCustomer"
                        name="CustomerSelect"
                        label={this.state.customLabels.Label_Customer}
                        wmsSelector={this.state.Selector + "CustomerDropdown"}
                        value={this.state.selectedCustomerIndex}
                        onChangeFunc={this.EventGetOrderPreferences}
                        options={this.state.customerList}
                        valueField="id"
                        textField="name"
                        parentClassName="form_group"
                        required={true}
                        blankFirstOption={true}
                    />
                </WmsContainer>

                {this.state.selectedCustomerIndex !== "" && (
                    <div className="wms-order-data-wrapper">
                        <WmsContainer title={this.translate("Settings_Label_Import_Pref")}>
                            <div className="">
                                <label className="wms-input-label">
                                    {this.translate("Settings_Label_OnError")}
                                </label>
                            </div>

                            <WmsRadio
                                label={this.translate("Settings_Label_AbortImport")}
                                name="OnError"
                                value={this.state.OnError == 0}
                                wmsSelector={this.state.Selector + "AbortImport"}
                                onChangeFunc={ this.ChangeImportPreferences(0) }
                                checked={this.state.OnError == 0}
                            />

                            <WmsRadio
                                label={this.translate("Settings_Label_ContinueImport")}
                                name="OnError"
                                value={this.state.OnError == 1}
                                wmsSelector={this.state.Selector + "ContinueImport"}
                                onChangeFunc={ this.ChangeImportPreferences(1) }
                                checked={this.state.OnError == 1}
                            />

                            <div className="">
                                <label className="wms-input-label">
                                    {this.translate("Settings_Label_WhatToImport")}
                                </label>
                            </div>

                            <WmsRadio
                                label={this.translate("Settings_Label_ImportAll")}
                                name="WhatToImport"
                                value="0"
                                wmsSelector={this.state.Selector + "ImportAll"}
                                onChangeFunc={ this.ChangeImportPreferences(0) }
                                checked={this.state.WhatToImport == 0}
                            />

                            <WmsRadio
                                label={this.translate("Settings_Label_ImportOnlyFull")}
                                name="WhatToImport"
                                value="1"
                                wmsSelector={this.state.Selector + "ImportOnlyFull"}
                                onChangeFunc={ this.ChangeImportPreferences(1) }
                                checked={this.state.WhatToImport == 1}
                            />

                            {false && (
                                <WmsDropdown
                                    id="CustomizePreferencesImport"
                                    name="CustomizePreferencesImport"
                                    label={this.translate("Settings_Label_CustomizePreferences")}
                                    wmsSelector={this.state.Selector + "CustomizePreferences"}
                                    value={this.state.IndexOptionsCustomizeDuringImport}
                                    onChangeFunc={() => {}}
                                    options={this.state.customizePreferencesList}
                                    valueField="value"
                                    textField="description"
                                    parentClassName="form_group"
                                    required={true}
                                    blankFirstOption={false}
                                />
                            )}
                        </WmsContainer>

                        { this.state.DefaultFormats.length !== 0 && (
                            <WmsContainer title={this.translate("Settings_Label_ImportFormat")}>
                                {
                                    this.state.DefaultFormats.map((format, key) =>
                                    ([42,26].includes(format.Id)) ?
                                        <WmsCheckBox
                                            key = {key}
                                            id={String(format.Id)}
                                            name={ String(format.DisplayName) }
                                            wmsSelector={ this.makeWmsSelector(format.DisplayName) }
                                            // onChangeFunc={ e => function () { } }
                                            onChangeFunc={ this.toggleSelectedParsers(format.Id, 'format') }
                                            label={ format.DisplayName }
                                            checked={ this.state.SelectedParsers.indexOf(format.Id) !== -1 }
                                            value=""
                                        /> : ""
                                    )
                                }
                                <div className="wms-divider-light"></div>
                                {
                                    this.state.DefaultFormats.map((format, key) =>
                                    ([20,28,48,45,53].includes(format.Id)) ?
                                        <WmsCheckBox
                                            key = {key}
                                            id={String(format.Id)}
                                            name={ String(format.DisplayName) }
                                            wmsSelector={ this.makeWmsSelector(format.DisplayName) }
                                            // onChangeFunc={ e => function () { } }
                                            onChangeFunc={ this.toggleSelectedParsers(format.Id, 'format') }
                                            label={ format.DisplayName }
                                            checked={ this.state.SelectedParsers.indexOf(format.Id) !== -1 }
                                            value=""
                                        /> : ""
                                    )
                                }
                            </WmsContainer>
                        )}

                        { this.state.CustomFormats.length !== 0 && (
                            <WmsContainer title={this.translate("Settings_Label_ImportFormatCustom")}>
                                {
                                    this.state.CustomFormats.map((format, key) => (
                                        <WmsCheckBox
                                            key = {key}
                                            id={String(format.Id)}
                                            name={ String(format.DisplayName) }
                                            wmsSelector={ this.makeWmsSelector(format.DisplayName) }
                                            // onChangeFunc={ e => function () { } }
                                            onChangeFunc={ this.toggleSelectedParsers(format.Id, '') }
                                            label={ format.DisplayName }
                                            checked={ this.state.SelectedParsers.indexOf(format.Id) !== -1 }
                                            value=""
                                        />
                                    ))
                                }
                            </WmsContainer>
                        )}
                    </div>
                )}

                <div>
                    <WmsConfirmModel
                        id={this.state.Selector + "CustomerChangeConfirmModal"}
                        isOpen={this.state.isOpenWarningModel}
                        width={500}
                        height={175}
                        firstBtnLabel={this.translate("Settings_Label_SaveExit")}
                        firstBtnIcon={"fa-floppy-o"}
                        firstBtnOnClickFunc={this.ClickSaveAndExit}
                        secondBtnLabel={this.translate('Settings_Label_DiscardExit')}
                        secondBtnIcon={"fa-times-circle"}
                        secondFunctionClick={this.ClickExit}
                        secondButtonType="yellow"
                        isSecondBtnSaw={true}
                        sawCheckBox={false}
                        thirdBtnLabel={this.translate('RoleManager_Label_Back')}
                        thirdBtnIcon={"fa fa-arrow-left"}
                        conFormModelText="Do you want to save changes before choosing a new customer?"
                        thirdButtonType="black"
                        onCloseFunc={this.onCloseConfirmModel}
                    />
                </div>
            </div>
        );
    }
}
