import React from 'react';

//component
import WmsButton from '../WmsButton/WmsButton.jsx';
import WmsModel from '../WmsModel/WmsModel.jsx';
import './LoadingModel.scss';


export class LoadingModel extends React.PureComponent {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        this.state = {
            Selector: "loadingModel",
            progressCompletePercentage: 0,
        }
    }

    componentDidMount(){

        document.addEventListener("responseHandler", this.listenResponseHandler);

        // load progress controller
        var pb = $("#loadProgressBar").kendoProgressBar({
            min: 0,
            max: 100,
            animation: {
                duration: 0
            },
            change: function (e) {
                this.progressStatus.text("");
            }
        }).data("kendoProgressBar");
        this.setState({
            progressCompletePercentage: 0
        })
        pb.progressStatus.text("");
        pb.value(0);
    }


    //  Listen response handler for concurrency requests
    listenResponseHandler = (e) => {

        var queueTotal = e.detail.instance.queue.length;
        var runningTotal = e.detail.instance.running.length;
        var completeTotal = this.props.sharedTotalRequest - queueTotal - runningTotal + 1;
        var newPercentage = Math.ceil((completeTotal * 100) / this.props.sharedTotalRequest);
        console.log("listenResponseHandler ", newPercentage);
        if(this.refs.loadingProgressRefs){
            this.setState({
                progressCompletePercentage: newPercentage
            },()=>{
                $("#loadProgressBar").data("kendoProgressBar").value(newPercentage)
            });
        }
    }
    cancelLoadAllData = () => {
        this.setState({
            progressCompletePercentage: 0,
        },()=>{
            $("#loadProgressBar").data("kendoProgressBar").value(0);
            this.props.onCancelLoadAllData();
        });
    }

    render() {
        return (

            <WmsModel
                    id={"loadingModal"}
                    class="loadingModal"
                    title={this.translate('Label_loading')}
                    width={340}
                    onCloseFunc={(e) => function () { }}
                    height={260}
                    // isOpen={true}
                    isAnotherModelOpen={this.props.isAnotherModelOpen}
                    isOpen={this.props.sharedOpenLoadingmodel}
                    customClass='wms-model-new-ui-wrapper'
                >
                    <div className="model-content-wrapper" ref="loadingProgressRefs">
                        <div className="loading-overlay">
                            <div className="loading-overlay-content ">
                                <div className="message_text">
                                    {this.translate('Label_waiting_message_for_grid')}
                                </div>
                                <div className="message_info">
                                    <div id="loadProgressBar"></div>
                                    <span className="green-color"> {this.state.progressCompletePercentage}% Complete </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={this.translate("Label_Cancel")}
                            wmsSelector={this.state.Selector + "ButtonCancel"}
                            onClickFunc={this.cancelLoadAllData}
                            icon="fa-ban"
                            buttonType="yellow"
                            loading={false}
                        />
                    </div>
                </WmsModel>
        )
    }
}