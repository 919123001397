import React from 'react';

import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsCheckBox from '../../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx';
import style from './carrier-service-levels.scss';
//services
import * as GlobalService from '../../../global_services';
import * as CarrierServiceLevels from '../../small-parcel-settings.services.jsx';

export class CarrierServiceLevelsModel extends React.Component {

    constructor(props) {

        super(props);


        this.translate = window.translate;
        this.state = {
            carrierOptions: [],
            servicesOptions: [],
            smallParcelRateShopFilter: this.props.sharedSmallParcelSettings.SmallParcelRateShopFilter ? JSON.parse(this.props.sharedSmallParcelSettings.SmallParcelRateShopFilter) : {"carriers":{}},
            disableSaveText: this.translate("Label_Markup_Save_Disable_Text"),
            isSaveDisabled: false,
            isEditLevels: false,
            saveLoader: false,
            selectedCarrier: null,
        };
    }

    componentDidMount() {

        kendo.ui.progress($('#carrierServiceLevels'), true);
        // add carrier options
        if(this.props.selectedCarrierServiceLevels.length && this.props.sharedShipEngineCarrier.length) {
            let tempCarrierOptions = [];
            for (let i = 0; i < this.props.selectedCarrierServiceLevels.length; i++) {
                let selectedElement = this.props.selectedCarrierServiceLevels[i];
                if(selectedElement === "DHLGM") {
                    selectedElement = "DHL eCommerce";
                } else if(selectedElement === "FDXE") {
                    selectedElement = "FedEx";
                } else if(selectedElement === "ENDCA") {
                    selectedElement = "USPS";
                } else if(selectedElement === "PBCS") {
                    selectedElement = "PitneyBowesStd";
                } else if(selectedElement === "AMZN") {
                    selectedElement = "Amazon Buy";
                }
                let tempObject =  this.props.sharedShipEngineCarrier.filter(function (el) {
                    return el.Carrier === selectedElement;
                })[0];

                if(tempObject != undefined) {
                    tempCarrierOptions.push(tempObject);
                }
            }
            // Add first carrier service options
            let tempServiceOptions = this.props.carrierShipmentServices[tempCarrierOptions[0].Carrier.toLowerCase()];
            var tempActiveServiceOptions = tempServiceOptions.filter( el => !el.Deactivated);
            var tempIsAnyOneChecked = Object.keys(this.state.smallParcelRateShopFilter.carriers).length > 0;
            this.setState({
                carrierOptions: tempCarrierOptions,
                servicesOptions: tempActiveServiceOptions,
                selectedCarrier: tempCarrierOptions[0].Carrier.toLowerCase(),
                isSaveDisabled: tempIsAnyOneChecked,
                isEditLevels: tempIsAnyOneChecked
            });
        }
        kendo.ui.progress($('#carrierServiceLevels'), false);
    }

    handleChangeEvent = (e) => {
        if(e.target.value) {
            let tempServiceOptions = this.props.carrierShipmentServices[e.target.value.toLowerCase()];
            var tempActiveServiceOptions = tempServiceOptions.filter( el => !el.Deactivated);
            this.setState({
                servicesOptions: tempActiveServiceOptions,
                selectedCarrier: e.target.value.toLowerCase()
            });
        }
    }

    handleChangeCurrentServiceOptions = (e) => {
        var tempSPRSF = this.state.smallParcelRateShopFilter.carriers;
        if(e.target.checked){
            if(tempSPRSF[this.state.selectedCarrier] == undefined) {
                tempSPRSF[this.state.selectedCarrier] = [];
            }
            tempSPRSF[this.state.selectedCarrier].push(e.target.name);
        } else {
            tempSPRSF[this.state.selectedCarrier] = tempSPRSF[this.state.selectedCarrier].filter(el => el != e.target.name);
            if(tempSPRSF[this.state.selectedCarrier].length == 0) {
                delete tempSPRSF[this.state.selectedCarrier];
            }
        }
        var tempIsAnyOneChecked = Object.keys(tempSPRSF).length > 0 || this.state.isEditLevels;
        this.setState({
            smallParcelRateShopFilter: {carriers: tempSPRSF},
            isSaveDisabled: tempIsAnyOneChecked
        });
    }

    onSaveCarrierLevels = () => {
        this.setState({ saveLoader: true });
        let settingsData = Object.assign({}, this.props.sharedSmallParcelSettings);
        settingsData.SmallParcelRateShopFilter = JSON.stringify(this.state.smallParcelRateShopFilter);
        settingsData.SmallParcelRateShoppingLogic = JSON.stringify(settingsData.SmallParcelRateShoppingLogic);
        let param_settings_data = {
            'custUri': this.props.sharedSelectedCustomer.uri,
            'spsData': settingsData
        }

        CarrierServiceLevels.saveSmartParcelSettings(param_settings_data).then((response) => {
            if (response && response.status == 200) {
                GlobalService.notification('success', this.translate('Window_Title_Label_SmartParcel_Data_Success'));
                response.data.SmallParcelRateShoppingLogic = JSON.parse(response.data.SmallParcelRateShoppingLogic);
                this.props.onSaveCarrierServiceLevels(response.data);
                this.props.closeCarrierServiceLevels();
            }

        }).finally(() => {

        });
    }

    render() {
        return (

            <WmsModel
                id={'carrierServiceLevels'}
                title={this.translate('Label_Edit_Service_Levels')}
                width={800}
                height={703}
                class='carrier-markup-model-wrapper'
                onCloseFunc={this.props.closeCarrierServiceLevels}
                isOpen={this.props.isOpenCarrierServiceLevelsModel}
                customClass='wms-model-new-ui-wrapper'>
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'carrierServiceLevels'}
                        headerLabel={this.translate("Label_Header_Service_Levels")}
                        showCollapseText={false}
                        showCollapseIcon={false} >
                        <div className="fullWidth">
                            <WmsDropdown
                                id="selectedCarrierIndex"
                                name="selectedCarrierIndex"
                                label={this.translate('Label_Carrier')}
                                required={true}
                                wmsSelector={this.state.selector + "carrier"}
                                onChangeFunc={this.handleChangeEvent}
                                options={this.state.carrierOptions}
                                valueField="Carrier"
                                textField="DisplayName" />
                        </div>
                        <hr/>
                        <div className="carrier-service-options-checkbox">
                            {this.state.servicesOptions?.map((item, index) => (
                                <WmsCheckBox
                                    id={item.Code}
                                    key={"carrier-service-options-checkbox" + index}
                                    name={item.Code}
                                    onChangeFunc={this.handleChangeCurrentServiceOptions}
                                    label={item.Description}
                                    checked={this.state.smallParcelRateShopFilter.carriers[this.state.selectedCarrier]?.includes(item.Code)}
                                    value={item.Code}
                                    wmsSelector={this.props.selector + item.Code}
                                    parentClassName='column' />
                            ))}
                        </div>
                    </WmsCollapse>
                </div>

                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Cancel')}
                        wmsSelector={this.selector + "cancel"}
                        onClickFunc={this.props.closeCarrierServiceLevels}
                        icon="fa-ban"
                        buttonType="orange" />
                    <WmsButton
                        label={this.translate('Label_Save')}
                        wmsSelector={this.selector + 'Save'}
                        onClickFunc={this.onSaveCarrierLevels}
                        loading={this.state.saveLoader}
                        showToolTip={!this.state.isSaveDisabled}
                        tooltipText={this.state.disableSaveText}
                        disabled={!this.state.isSaveDisabled}
                        icon="fa-floppy-o" />
                </div>
            </WmsModel>
        );
    }
}