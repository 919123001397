import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import WmsModel from "../react/components/WmsModel/WmsModel";

import WmsCollapse from "../react/components/WmsCollapse/WmsCollapse";
import WmsInput from "../react/components/WmsInput/WmsInput";
import WmsTextArea from "../react/components/WmsTextArea/WmsTextArea";
import WmsDateTimePicker from "../react/components/WmsDateTimePicker/WmsDateTimePicker";
import WmsButton from "../react/components/WmsButton/WmsButton";
import * as GlobalService from "../react/global_services";
import WmsDropdownList from "../react/components/WmsDropdownList/WmsDropdownList.jsx";
import WmsComboBox from "../react/components/WmsComboBox/WmsComboBox.jsx";
import WmsCheckBox from "../react/components/WmsCheckBox/WmsCheckBox.jsx";
import * as ManageAssemblyServices from "./manage-assembly.services";
import EditAllocationModal from "./edit-allocation-model.component";
import WmsGrid from "../react/components/WmsGrid/WmsGrid.jsx";
import { CreateManualChargeModel } from "../react/orders/findOrders/model/create-manual-charge-model.component.jsx";
import { StringWms } from "../../assets/ts/StringWms";
import * as GridConfig from "./manage-assembly-grid-configuration";
import { ObjectWms } from "../../assets/ts/ObjectWms";

import "./manageassembly.scss";
import { Big } from "../react/global_variable_service";

declare var window: any;
const { $ } = window;
interface Props {
    wmsSelector: string;
    windowId: string;
    selectedRow: any;
    isOpenEditAssemblyModal: boolean;
    onCloseEditAssemblyModal: any;
    isEditAssemblyLoading: boolean;
    onApplyEditAssembly: any;
}
const defaultItemTrackByDetail = {
    TrackLotNumber: 0,
    TrackSerialNumber: 0,
    TrackExpirationDate: 0,
    TrackCost: 0,
};
const EditAssemblyModal: React.FC<Props> = (props: Props): any => {
    const CustomLabels = GlobalService.getCustomLabels();
    const [assemblyData, setAssemblyData] = useState(props.selectedRow);
    const [allocationRow, setAllocationRow] = useState(null);
    const [chargesType, setChargesType] = useState([]);
    const [isOpenManualChargeModal, setIsOpenManualChargeModal] = useState(false);
    const [isOpenConfirmModel, setIsOpenConfirmModel] = useState(false);
    const [totalSum, setTotalSum] = useState("");
    const [itemTrackByDetail, setItemTrackByDetail] = useState(defaultItemTrackByDetail);
    const [trackLocation, setTrackLocation] = useState(0);
    const [muTrackPallats, setMuTrackPallats] = useState(0);
    const [optionsMovableUnitLabels, setOptionsMovableUnitLabels] = useState([]);
    const [isOpenEditAllocationModal, setSharedIsOpenEditAllocation] = useState(false);
    const [sharedEditAllocationData, setSharedEditAllocationData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isManualCharge, setIsManualCharge] = useState(false);
    const [isManualChargeLoading, setIsManualChargeLoading] = useState(false);
    const EditAssemblyModelId = "EditAssemblyModel";
    const dafaultDateFormat = "MM/dd/yyyy";

    const chargesGridSchema = GridConfig.getCalculateChargesGridSchema().schema;
    const CalculateCharge_GrideHeaders = StringWms.convertSringToOption(
        window.translate("Orders_ManageOrders_Grid_Headers_Applied_Charges")
    );
    const chargesGridColumns = GridConfig.ChargesColumns(CalculateCharge_GrideHeaders, window.translate);

    const handleChangeAssemblyData = (event: any) => {
        let { name, value } = event.target;
        var finalValue = value;
        if (name === "SplitLineByMovableUnit") {
            finalValue = event.target.checked;
        } else if (name === "locationId") {
            finalValue = value.Id;
        }
        setAssemblyData((prevState: any) => ({
            ...prevState,
            [name]: finalValue,
        }));
    };
    const getSubmitFormData = () => {
        let billingCharges = ObjectWms.removeObjFromListOnParameterValue(
            assemblyData.BillingCharges,
            "NumberOfUnits",
            null
        );
        var formData = {
            AssemblyId: assemblyData.AssemblyId,

            // transaction details
            CustomerId: assemblyData.CustomerId,
            FacilityId: assemblyData.FacilityId,
            ItemId: assemblyData.ItemId,
            Qty: assemblyData.Qty,
            ReferenceNum: assemblyData.ReferenceNum,
            // ExpectedDate: assemblyData.ExpectedDate,
            ExpectedDate: assemblyData.ExpectedDate,
            Notes: assemblyData.Notes,

            // Inventory
            LotNumber: assemblyData.LotNumber,
            SerialNumber: assemblyData.SerialNumber,
            ExpirationDate: kendo.toString(kendo.parseDate(assemblyData.ExpirationDate), "yyyy-MM-dd"),
            Cost: assemblyData.Cost,
            LocationInfo: {
                locationId: 0,
            },
            PalletInfo: {
                Label: null,
                SplitOverCapacityOnCreate: null,
            },
            BillingCharges: billingCharges,
        };
        if (assemblyData.locationId && assemblyData.locationId !== null) {
            formData.LocationInfo = {
                locationId: assemblyData.locationId,
            };
        }
        if (assemblyData.MovableUnitLabel) {
            formData.PalletInfo = {
                Label: assemblyData.MovableUnitLabel,
                SplitOverCapacityOnCreate: assemblyData.SplitLineByMovableUnit,
            };
        }
        return formData;
    };
    const onClickSave = () => {
        var formData = getSubmitFormData();
        kendo.ui.progress($("#" + EditAssemblyModelId), true);
        kendo.ui.progress($("#" + EditAssemblyModelId), true);
        ManageAssemblyServices.editAssembly(formData)
            .then(() => {
                GlobalService.notification("success", window.translate("Assembly_edited_Successfully"));
            })
            .finally(() => {
                kendo.ui.progress($("#" + EditAssemblyModelId), false);
            });
        props.onApplyEditAssembly();
    };
    const requireLotNumberTrackby = () => {
        return itemTrackByDetail.TrackLotNumber === 2;
    };
    const requireSerialNumberTrackby = () => {
        return itemTrackByDetail.TrackSerialNumber === 3 || itemTrackByDetail.TrackSerialNumber === 4;
    };
    const requireExpirationDateTrackby = () => {
        return (
            itemTrackByDetail.TrackExpirationDate === 2 &&
            (assemblyData.ExpirationDate == null || assemblyData.ExpirationDate === "")
        );
    };
    const requireCostTrackby = () => {
        return itemTrackByDetail.TrackCost === 2;
    };
    const requireLocationIdTrackBy = () => {
        return trackLocation === 2;
    };
    const requireMovableUnitLabelTrackBy = () => {
        return muTrackPallats === 2;
    };
    const allValidationDone = () => {
        return validateTrackByall();
    };
    const validateTrackByall = () => {
        return (
            (requireLotNumberTrackby() && !assemblyData.LotNumber) ||
            (requireSerialNumberTrackby() && !assemblyData.SerialNumber) ||
            requireExpirationDateTrackby() ||
            (requireCostTrackby() && !assemblyData.Cost) ||
            (requireLocationIdTrackBy() && (!assemblyData.locationId || assemblyData.locationId.toString() === "0")) ||
            (requireMovableUnitLabelTrackBy() &&
                (assemblyData.MovableUnitLabel === "" || assemblyData.MovableUnitLabel === undefined))
        );
    };
    const resetAllocationGrid = () => {
        ManageAssemblyServices.getAssemblyDetailObject(props.selectedRow.AssemblyId)
            .then((response) => {
                var newAssemblyObject = response.data;
                if (newAssemblyObject.AssemblyItems && newAssemblyObject.AssemblyItems.length) {
                    newAssemblyObject.AssemblyItems.map((item) => updateAssemblyAllocationQty(item, newAssemblyObject));
                }
                const newAssemblyDataObject = { ...assemblyData, AssemblyItems: newAssemblyObject.AssemblyItems };
                setAssemblyData(newAssemblyDataObject);
            })
            .finally(() => {
                kendo.ui.progress($("#" + EditAssemblyModelId), false);
                setIsLoading(false);
            });
    };
    const onGridItemChange = () => {
        console.log("need to onGridItemChange after Edit Allocation");
    };
    const onCloseEditAllocationModal = () => {
        setSharedIsOpenEditAllocation(false);
        console.log("allocationRow", allocationRow);
    };
    const eventClickOpenEditAllocations = () => {
        let grid = $("#" + props.wmsSelector + "allocationGrid").data("kendoGrid");
        let selectedItem = grid.dataItem(grid.select());

        if (!selectedItem) {
            return;
        }
        let tempEditAllocationData = {
            "modalTitle": window.translate("Window_Title_Edit_Allocations"),
            "modalType": "GridEditAllocations",
            "CustomerId": assemblyData.CustomerId,
            "FacilityId": assemblyData.FacilityId,
            "ItemId": selectedItem.ItemId,
            "Sku": selectedItem.Sku,
            "ItemDescription": selectedItem.ItemDescription,
            "OrderQty": selectedItem.Qty,
            "EditWithAllocationsDetailsUri": selectedItem.EditWithAllocationDetailsUri,
            "assemblyId": assemblyData.AssemblyId,
            "assemblyItemUri": assemblyData.EditWithAllocationDetailsUri,
            "Qualifier": selectedItem.Qualifier,
            "OrderItemId": selectedItem.OrderItemId,
            "totalOrdered": selectedItem.Qty,

            // "OrderId": this.props.shareOrderData.OrderId,
            // "OrderItemId": selectedItem.OrderItemId,
            // 'totalInputedQuantity': selectedItem.Qtyy
        };
        setSharedEditAllocationData(tempEditAllocationData);
        setTimeout(() => {
            setSharedIsOpenEditAllocation(true);
        }, 200);
    };
    const onAllocationGridSelection = (KendoGrid) => {
        let tempkey = KendoGrid.getKeys();
        var data = {};
        if (tempkey.length === 1) {
            data = KendoGrid.getItem(tempkey);
        }
        setAllocationRow(data);
    };

    const AllocationGridColumns = [
        {
            field: "Sku",
            title: "SKU",
            width: 150,
        },
        {
            field: "Qualifier",
            title: "Qualifier",
            width: 150,
        },
        {
            field: "baseQty",
            title: "Base QTY",
            width: 100,
        },
        {
            field: "totalQty",
            title: "Total QTY",
            width: 100,
        },
        {
            field: "AvailableQty",
            title: "Available",
            width: 100,
        },
        {
            field: "cmdEdit",
            menu: false,
            command: [
                {
                    name: "Edit",
                    text: "",
                    className: "allocation-row-edit-button",
                    iconClass: "fa fa-pencil",
                    click: eventClickOpenEditAllocations,
                },
            ],
            title: "&nbsp;",
            width: 60,
        },
    ];
    const allocationGridSchema = {
        model: {
            id: "ItemId",
            fields: {
                Sku: { type: "string" },
                Qualifier: { type: "string" },
                baseQty: { type: "number" },
                totalQty: { type: "number" },
                AvailableQty: { type: "number" },
            },
        },
    };
    const updateAssemblyAllocationQty = (item, newAssemblyObject) => {
        item.totalQty = item.Qty;
        item.baseQty = item.Qty / newAssemblyObject.Qty;
        return item;
    };

    useEffect(() => {
        // $(document).off("click", ".allocation-row-edit-button");
        // $(document).on("click", ".allocation-row-edit-button", function () {
        //     eventClickOpenEditAllocations();
        //     return false;
        // });
        $(document).off("click", ".remove-billing-item");
        $(document).on("click", ".remove-billing-item", function () {
            var rowIndex = parseInt($(this).data("id"));
            var newBillingCharges = assemblyData.BillingCharges.filter((i) => i.rowIndex !== rowIndex);
            setAssemblyData((prevState: any) => ({
                ...prevState,
                BillingCharges: newBillingCharges,
            }));
            return false;
        });
    }, [assemblyData.BillingCharges]);
    useEffect(() => {
        kendo.ui.progress($("#" + EditAssemblyModelId), true);
        ManageAssemblyServices.getAssemblyDetailObject(props.selectedRow.AssemblyId)
            .then((response) => {
                var newAssemblyObject = response.data;
                if (newAssemblyObject.BillingCharges) {
                    newAssemblyObject.BillingCharges = newAssemblyObject.BillingCharges.map((item, index) => {
                        item.rowId = index;
                        return item;
                    });
                }
                newAssemblyObject.BillingCharges = ObjectWms.removeObjFromListOnParameterValue(
                    newAssemblyObject.BillingCharges ?? [],
                    "SystemGenerated",
                    true
                );
                newAssemblyObject.SplitLineByMovableUnit =
                    newAssemblyObject.PalletInfo !== undefined &&
                    newAssemblyObject.PalletInfo !== null &&
                    newAssemblyObject.PalletInfo.SplitOverCapacityOnCreate !== undefined &&
                    newAssemblyObject.PalletInfo.SplitOverCapacityOnCreate === true;
                newAssemblyObject.MovableUnitLabel = newAssemblyObject?.PalletInfo?.Label;
                newAssemblyObject.locationId = newAssemblyObject?.LocationInfo?.LocationId;
                newAssemblyObject.ExpectedDate = kendo.toString(
                    kendo.parseDate(newAssemblyObject.ExpectedDate),
                    dafaultDateFormat
                );
                if (newAssemblyObject.AssemblyItems && newAssemblyObject.AssemblyItems.length) {
                    newAssemblyObject.AssemblyItems.map((item) => updateAssemblyAllocationQty(item, newAssemblyObject));
                }
                var customers = GlobalService.getCustomerList(false);
                var selectedCustomer = customers.find(
                    (cus: any) => newAssemblyObject.CustomerId !== 0 && cus.id === newAssemblyObject.CustomerId
                );
                var MUOptions = [
                    {
                        Name: "System Assigned",
                        Value: window.translate("Label_System_Assigned"),
                    },
                ];
                if (!StringWms.isEmpty(selectedCustomer.options.trackPallets)) {
                    setMuTrackPallats(selectedCustomer.options.trackPallets);
                    if (selectedCustomer.options.trackPallets !== 2) {
                        MUOptions.push({
                            Name: "None",
                            Value: window.translate("Label_None"),
                        });
                    }
                }
                if (!StringWms.isEmpty(selectedCustomer.options.trackLocation)) {
                    setTrackLocation(selectedCustomer.options.trackLocation);
                }
                setOptionsMovableUnitLabels(MUOptions);
                setAssemblyData(newAssemblyObject);
                setItemTrackByDetail(
                    newAssemblyObject.TrackBys ? newAssemblyObject.TrackBys : defaultItemTrackByDetail
                );
            })
            .finally(() => {
                kendo.ui.progress($("#" + EditAssemblyModelId), false);
                setIsLoading(false);
            });
    }, [props.selectedRow.AssemblyId]);
    useEffect(() => {
        let tmpTotalSum = 0;
        if (assemblyData.BillingCharges) {
            assemblyData.BillingCharges = assemblyData.BillingCharges.map((item, index) => {
                item.rowId = index;
                return item;
            });
            for (let i = 0; i < assemblyData.BillingCharges.length; i++) {
                const billing = assemblyData.BillingCharges[`${i}`];
                tmpTotalSum = tmpTotalSum + parseFloat(billing.TotalCharges);
            }

            let formatTotalSum = tmpTotalSum
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // eslint-disable-line security/detect-unsafe-regex
            setTotalSum(formatTotalSum);
        }
    }, [assemblyData]);

    const boundGridData = (event, kendoGrid) => {
        const grid = $("#" + props.wmsSelector + "allocationGrid").data("kendoGrid");
        const gridData = grid.dataSource.data();
        for (const dataItem of gridData) {
            if (!dataItem.FullyAllocated) {
                grid.table.find(`tr[data-uid='${dataItem.uid}']`).addClass("wms-over-allocated");
            }
        }
    };

    const openCreateManualChargeModel = () => {
        ManageAssemblyServices.getCharges().then((response) => {
            response.data.unshift({
                "id": null,
                "Description": window.translate("Label_Select_Charge_Type"),
            });
            setChargesType(response.data);
            setIsOpenManualChargeModal(true);
        });
    };
    const onCloseCreateManualChargeModal = () => {
        setIsOpenManualChargeModal(false);
    };
    const addOrderChargesGrid = (data) => {
        var ManualCharge = {
            "Action": "a",
            "ChargeOrigin": 2,
            "ChargeLabel": data.ChargeLabel,
            "ChargeTypeId": data.ChargeTypeId,
            "ChargeTypeDescription": data.ChargeType,
            "UnitDescription": data.Description,
            "ChargePerUnit": data.ChargePerUnit,
            "NumberOfUnits": data.NumberOfUnits,
            "TotalCharges": data.TotalCharges,
            "GeneralLedgerAccount": data.GeneralLedgerAccount,
            "ExternalAccountsReceivable": data.ExternalAccountsReceivable,
            "ExternalItemIdentifier": data.ExternalItemIdentifier,
            "ExternalItemDescription": data.ExternalItemDescription,
            "SKU": "",
            "Notes": "",
            "UnsavedManualCharge": true,
            "SystemGenerated": false,
        };
        setAssemblyData((prevState: any) => ({
            ...prevState,
            BillingCharges: ObjectWms.removeObjFromListOnParameterValue(
                [...prevState.BillingCharges, ManualCharge],
                "SystemGenerated",
                true
            ),
        }));
    };
    const openConfirmationModal = () => {
        setIsOpenConfirmModel(true);
    };
    const clickExit = () => {
        setIsOpenConfirmModel(false);
    };
    const deleteManualCharges = () => {
        var grid = $("#" + props.wmsSelector + "chargeGrid").data("kendoGrid");
        grid.select().each(function () {
            grid.removeRow($(this));
        });
        var gridData = grid.dataSource.data().length > 0 ? grid.dataSource.data().toJSON() : [];

        setAssemblyData((previousState) => ({
            ...previousState,
            BillingCharges: gridData,
        }));
        setIsManualCharge(false);
        grid.clearSelection();
        setIsOpenConfirmModel(false);
        setIsOpenManualChargeModal(false);
    };
    const chargeSelectRow = (param_kendogrid) => {
        var selectedRow = param_kendogrid.getNumberOfSelections();
        var manualChargeType =
            selectedRow > 0 ? param_kendogrid.identicalValuesInSelectedSet("ChargeOrigin", 2) : false;

        setIsManualCharge(manualChargeType);
    };
    const onDataBound = (e, kendoGrid) => {
        if (kendoGrid) {
            var totalCharges = kendoGrid.sumByLocalMemoryGridContents(["TotalCharges"])[0];
            let tmpTotalSum = Number(Math.round(parseFloat(totalCharges + "e2")) + "e-2");
            let formatTotalSum = tmpTotalSum
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // eslint-disable-line security/detect-unsafe-regex
            setTotalSum(formatTotalSum);
        }
    };
    const onEdit = (e) => {
        var grid = $("#" + props.wmsSelector + "chargeGrid").data("kendoGrid");
        var columnIndex = grid.cellIndex(e.container);
        var fieldName = grid.thead.find("th").eq(columnIndex).data("field");

        if (fieldName === "NumberOfUnits" && e.model.ChargeOrigin === 1) {
            grid.closeCell();
        }
        if (fieldName === "ChargePerUnit" && e.model.ChargeOrigin === 1) {
            grid.closeCell();
        }
        if (fieldName === "ChargePerUnit" && e.model.ChargeOrigin === 3) {
            grid.closeCell();
        }
    };
    const EventSaveGridAppliedCharges = (e) => {
        var grid = $(" #" + props.wmsSelector + "chargeGrid").data("kendoGrid");

        var numberOfUnits = StringWms.isEmpty(e.model.NumberOfUnits) ? 0 : e.model.NumberOfUnits;
        if (e.values.hasOwnProperty("NumberOfUnits")) {
            numberOfUnits = StringWms.isEmpty(e.values.NumberOfUnits) ? 0 : e.values.NumberOfUnits;
        }

        var chargePerUnit = StringWms.isEmpty(e.model.ChargePerUnit) ? 0 : e.model.ChargePerUnit;
        if (e.values.hasOwnProperty("ChargePerUnit")) {
            chargePerUnit = StringWms.isEmpty(e.values.ChargePerUnit) ? 0 : e.values.ChargePerUnit;
        }

        var total = parseFloat(new Big(numberOfUnits).times(chargePerUnit).round(4));

        e.model.set("TotalCharges", total);
        e.model.TotalCharges = total;
        grid.saveChanges();

        // Setting a timeout so that NumberOfUnits gets updated by gridSaveChanges
        setTimeout(() => {
            let billingChargesData = grid._data;
            billingChargesData = ObjectWms.removeObjFromListOnParameterValue(billingChargesData, "test", null);

            setAssemblyData((prevState: any) => ({
                ...prevState,
                BillingCharges: billingChargesData,
            }));
        }, 100);
    };
    const appliedChargesGridChange = (e) => {
        //   Still nothing to do here.
    };
    const calculateChargesClick = () => {
        var formData = getSubmitFormData();
        kendo.ui.progress($("#" + EditAssemblyModelId), true);
        ManageAssemblyServices.editAssembly(formData)
            .then(() => {
                GlobalService.notification("success", window.translate("Assembly_edited_Successfully"));
                ManageAssemblyServices.AssemblyCalculateCharges(props.selectedRow.AssemblyId)
                    .then((response) => {
                        if (response.status === 200) {
                            GlobalService.notification("success", window.translate("Label_Success_recalculateCharges"));
                        }
                        if (response.data) {
                            response.data = response.data.map((item, index) => {
                                item.rowId = index;
                                return item;
                            });
                            setAssemblyData((prevState: any) => ({
                                ...prevState,
                                BillingCharges: response.data,
                            }));
                        }
                    })
                    .finally(() => {
                        setIsManualChargeLoading(false);
                    });
            })
            .finally(() => {
                kendo.ui.progress($("#" + EditAssemblyModelId), false);
            });
        setIsManualChargeLoading(true);
    };

    return (
        <>
            <WmsModel
                id={EditAssemblyModelId}
                title={window.translate("Label_Edit_Existing_Assembly")}
                width={800}
                height={800}
                onCloseFunc={props.onCloseEditAssemblyModal}
                isOpen={props.isOpenEditAssemblyModal}
                customClass="wms-model-new-ui-wrapper"
            >
                <div className="model-content-wrapper">
                    {!isLoading && (
                        <>
                            <WmsCollapse
                                id={"EditAssemblyTransactionDetailInfo"}
                                headerLabel={window.translate("Label_Transaction")}
                                showCollapseText={false}
                            >
                                <div className="transaction-information-wrapper">
                                    <div className="display-inline">
                                        <div className="left-content-wrapper">
                                            <div className="content">
                                                <label>{CustomLabels.Label_Customer}</label>
                                                <span>{assemblyData.CustomerName}</span>
                                            </div>
                                            <div className="content">
                                                <label>{CustomLabels.Label_Warehouse}</label>
                                                <span>{assemblyData.FacilityName}</span>
                                            </div>
                                            <div className="content">
                                                <label>{window.translate("Label_Quantity")}</label>
                                                <span>{assemblyData.Qty}</span>
                                            </div>
                                        </div>
                                        <div className="right-content-wrapper">
                                            <div className="content">
                                                <label>{window.translate("Assembly_SKU")}</label>
                                                <span>{assemblyData?.Sku}</span>
                                            </div>
                                            <div className="content">
                                                <label>{window.translate("Orders_Lbl_Reference_Num")}</label>
                                                <span>{assemblyData.ReferenceNum}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="display-inline">
                                        <WmsDateTimePicker
                                            id={"ExpectedDate"}
                                            name="ExpectedDate"
                                            label={window.translate("Label_Due_Date")}
                                            onChangeFunc={handleChangeAssemblyData}
                                            value={assemblyData.ExpectedDate}
                                            required={false}
                                            wmsSelector={props.wmsSelector + "ExpectedDate"}
                                            format={"M/d/yyyy"}
                                            isDateParseInCustomFormat={true}
                                        />
                                        <WmsTextArea
                                            id="Notes"
                                            name="Notes"
                                            wmsSelector={props.wmsSelector + "Notes"}
                                            onChangeFunc={handleChangeAssemblyData}
                                            label={window.translate("Label_Notes")}
                                            value={assemblyData.Notes}
                                            extraWrapperClass="width-70"
                                            maxLength={4096}
                                            rows={3}
                                            cols={75}
                                        />
                                    </div>
                                </div>
                            </WmsCollapse>
                            <WmsCollapse
                                id={"EditAssemblyInventoryDetailInfo"}
                                headerLabel={window.translate("Window_Title_Label_Inventory")}
                                showCollapseText={false}
                                isExpanded={
                                    !(
                                        itemTrackByDetail.TrackLotNumber === 0 &&
                                        itemTrackByDetail.TrackSerialNumber === 0 &&
                                        itemTrackByDetail.TrackExpirationDate === 0 &&
                                        muTrackPallats === 0 &&
                                        trackLocation === 0
                                    )
                                }
                                isDisabled={
                                    itemTrackByDetail.TrackLotNumber === 0 &&
                                    itemTrackByDetail.TrackSerialNumber === 0 &&
                                    itemTrackByDetail.TrackExpirationDate === 0 &&
                                    muTrackPallats === 0 &&
                                    trackLocation === 0
                                }
                            >
                                <div className="display-inline">
                                    {itemTrackByDetail.TrackLotNumber !== 0 && (
                                        <WmsInput
                                            id="LotNumber"
                                            inputName="LotNumber"
                                            wmsSelector={props.wmsSelector + "LotNumber"}
                                            onChangeFunc={handleChangeAssemblyData}
                                            label={window.translate("Label_Lot_Number")}
                                            value={assemblyData.LotNumber}
                                            required={requireLotNumberTrackby()}
                                            extraWrapperClass="width-25"
                                        />
                                    )}
                                    {itemTrackByDetail.TrackSerialNumber !== 0 && (
                                        <WmsInput
                                            id="SerialNumber"
                                            inputName="SerialNumber"
                                            wmsSelector={props.wmsSelector + "SerialNumber"}
                                            onChangeFunc={handleChangeAssemblyData}
                                            label={window.translate("Label_Serial_Number")}
                                            value={assemblyData.SerialNumber}
                                            required={requireSerialNumberTrackby()}
                                            extraWrapperClass="width-25"
                                        />
                                    )}

                                    {itemTrackByDetail.TrackExpirationDate !== 0 && (
                                        <WmsDateTimePicker
                                            id={"ExpirationDate"}
                                            name="ExpirationDate"
                                            label={window.translate("Label_Expiration_Date")}
                                            onChangeFunc={handleChangeAssemblyData}
                                            value={assemblyData.ExpirationDate}
                                            required={requireExpirationDateTrackby()}
                                            wmsSelector={props.wmsSelector + "EarliestShipDate"}
                                            defaultTime={"00:00:00"}
                                            format={"M/d/yyyy"}
                                            isDateParseInCustomFormat={true}
                                        />
                                    )}
                                    {itemTrackByDetail.TrackCost !== 0 && (
                                        <WmsInput
                                            id="Cost"
                                            inputName="Cost"
                                            wmsSelector={props.wmsSelector + "Cost"}
                                            onChangeFunc={handleChangeAssemblyData}
                                            label={window.translate("Label_MassAdd_Cost")}
                                            value={assemblyData.Cost}
                                            required={requireCostTrackby()}
                                            extraWrapperClass="width-20"
                                            // eslint-disable-next-line security/detect-unsafe-regex
                                            regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                        />
                                    )}
                                </div>
                                <div className="display-inline">
                                    {trackLocation !== 0 && (
                                        <WmsDropdownList
                                            id="locationId"
                                            name="locationId"
                                            optionsLabel={{
                                                Name: window.translate("Label_Preferred_Location_Text"),
                                                Id: 0,
                                            }}
                                            label={window.translate("Label_Location")}
                                            wmsSelector={props.wmsSelector + "locationId"}
                                            value={assemblyData.locationId}
                                            onChangeFunc={handleChangeAssemblyData}
                                            textField="Name"
                                            valueField="Id"
                                            extraWrapperClass="location-dropdown-list width-30"
                                            required={requireLocationIdTrackBy()}
                                            // template={"<span style='white-space: nowrap;text-overflow: ellipsis;width: 210px;display: block;overflow: hidden'>${ Name } - ${Description}</span>"}
                                            dataSourceObj={{
                                                type: "aspnetmvc-ajax",
                                                pageSize: 50,
                                                serverPaging: true,
                                                serverFiltering: true,
                                                transport: {
                                                    read: {
                                                        url: "/WebUI/items/items/LocationDropDownRead",
                                                        type: "POST",
                                                        data: {
                                                            facilityId: assemblyData.FacilityId,
                                                        },
                                                    },
                                                },
                                                schema: {
                                                    data: "Data",
                                                    total: "Total",
                                                },
                                            }}
                                        />
                                    )}
                                    {muTrackPallats !== 0 && (
                                        <>
                                            <WmsComboBox
                                                id="MovableUnitLabel"
                                                name="MovableUnitLabel"
                                                label={CustomLabels.Label_Movable_Unit}
                                                wmsSelector={props.wmsSelector + "MovableUnitList"}
                                                value={assemblyData.MovableUnitLabel}
                                                onChangeFunc={handleChangeAssemblyData}
                                                textField="Name"
                                                valueField="Value"
                                                placeholder={"Select MU Label"}
                                                options={optionsMovableUnitLabels}
                                                required={
                                                    requireMovableUnitLabelTrackBy() &&
                                                    (assemblyData.MovableUnitLabel === "" ||
                                                        assemblyData.MovableUnitLabel === undefined)
                                                }
                                                parentClassName={"width-25"}
                                            />
                                            <WmsCheckBox
                                                id={"SplitLineByMovableUnit"}
                                                name={"SplitLineByMovableUnit"}
                                                wmsSelector={props.wmsSelector + "SplitLineByMovableUnit"}
                                                onChangeFunc={handleChangeAssemblyData}
                                                label={window.translate("Label_Split_Movable_Unit")}
                                                checked={assemblyData.SplitLineByMovableUnit}
                                                parentClassName="split-line-by-movable-unit"
                                            />
                                        </>
                                    )}
                                </div>
                            </WmsCollapse>
                            <WmsCollapse
                                id={"EditAssemblyAllocationInfo"}
                                headerLabel={window.translate("Label_Allocation")}
                                showCollapseText={false}
                            >
                                <WmsGrid
                                    columns={AllocationGridColumns}
                                    schema={allocationGridSchema}
                                    gridKeySelection={"OrderItemId"}
                                    virtual={false}
                                    showGridSummary={true}
                                    staticGridData={assemblyData.AssemblyItems ? assemblyData.AssemblyItems : []}
                                    id={props.wmsSelector + "allocationGrid"}
                                    gridName={props.wmsSelector + "allocationGrid"}
                                    showGridResult={true}
                                    showSortColumns={true}
                                    showColumnFilter={false}
                                    wmsSelector={props.wmsSelector + "allocationGrid"}
                                    inlineSelectAll={false}
                                    onSelection={onAllocationGridSelection}
                                    clickEventOfContextMenu={() => null}
                                    isColumnsResizable={true}
                                    isColumnsReorderable={true}
                                    isColumnMenuenable={false}
                                    isColumnsSortable={true}
                                    parentClassName={"allocationGridHolder"}
                                    showResetMenu={false}
                                    showResetButton={false}
                                    IsMultipleSelectable={false}
                                    menu={[]}
                                    shawCheckbox={false}
                                    onDataBound={boundGridData}
                                />
                            </WmsCollapse>

                            <WmsCollapse
                                id={"EditAssemblyChargesInfo"}
                                headerLabel={window.translate("Label_Changes")}
                                showCollapseText={false}
                            >
                                <div className="display-inline charge-grid-wrapper">
                                    <WmsGrid
                                        id={props.wmsSelector + "chargeGrid"}
                                        wmsSelector={props.wmsSelector + "chargeGrid"}
                                        gridKeySelection={"rowId"}
                                        gridName={props.wmsSelector + "chargeGrid"}
                                        isAlignIndexesByProperty={true}
                                        virtual={false}
                                        columns={chargesGridColumns}
                                        schema={chargesGridSchema}
                                        showGridSummary={false}
                                        onSelection={chargeSelectRow}
                                        clickEventOfContextMenu={() => null}
                                        shawCheckbox={false}
                                        showGridResult={false}
                                        staticGridData={assemblyData.BillingCharges ? assemblyData.BillingCharges : []}
                                        showSortColumns={false}
                                        onDataBound={onDataBound}
                                        gridEditable={{
                                            confirmation: false,
                                        }}
                                        onEdit={onEdit}
                                        onGridSave={EventSaveGridAppliedCharges}
                                        onChange={appliedChargesGridChange}
                                        menu={[
                                            {
                                                "name": window.translate("Label_Recalculate_AutoCharges"),
                                                "value": [],
                                                "disabled": isManualChargeLoading,
                                                "disabledText": "You may not add blahssss",
                                                "icon": "fa fa-calculator",
                                                "color": "green",
                                                "clickFunc": calculateChargesClick,
                                                "wmsSelector": props.wmsSelector + "RecalculateAutoCharges",
                                                "loading": isManualChargeLoading,
                                                "isContextMenu": false,
                                            },
                                            {
                                                "name": window.translate("Label_Create_Manual_Charge"),
                                                "value": [],
                                                "disabled": false,
                                                "icon": "fa fa-keyboard-o",
                                                "color": "green",
                                                "clickFunc": openCreateManualChargeModel,
                                                "wmsSelector": props.wmsSelector + "CreateManualCharge",
                                                "isContextMenu": false,
                                            },
                                            {
                                                "name": window.translate("Label_Delete"),
                                                "value": [],
                                                "disabledText": "You may not add blahssss",
                                                "disabled": !isManualCharge,
                                                "icon": "fa-trash",
                                                "color": "red",
                                                "clickFunc": openConfirmationModal,
                                                "wmsSelector": props.wmsSelector + "DeleteManualCharge",
                                                "isContextMenu": false,
                                            },
                                        ]}
                                    />
                                    <div className="charge-totalWrapper">
                                        <div className="total-text">TOTAL</div>
                                        <div className="total-input">
                                            <WmsInput
                                                id="totalInput"
                                                inputName="totalInput"
                                                wmsSelector={props.wmsSelector + "totalInput"}
                                                onChangeFunc={() => {}}
                                                label={""}
                                                value={totalSum}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </WmsCollapse>
                        </>
                    )}
                </div>

                <div className="footer-btn-holder">
                    <WmsButton
                        label={window.translate("Label_Cancel")}
                        wmsSelector={props.wmsSelector + "DiscardExit"}
                        onClickFunc={props.onCloseEditAssemblyModal}
                        buttonType="orange"
                        icon={"fa-ban"}
                    />
                    <WmsButton
                        label={window.translate("Label_Save")}
                        wmsSelector={props.wmsSelector + "SaveExit"}
                        onClickFunc={onClickSave}
                        icon={"fa-floppy-o"}
                        disabled={allValidationDone()}
                        loading={props.isEditAssemblyLoading}
                        parentClassName={"apply-assembly-button"}
                    />
                </div>
            </WmsModel>
            {isOpenEditAllocationModal && (
                <EditAllocationModal
                    wmsSelector={props.wmsSelector}
                    isOpenEditAllocationModal={isOpenEditAllocationModal}
                    windowId={props.windowId}
                    AllocationObj={sharedEditAllocationData}
                    onCloseEditAllocationModal={onCloseEditAllocationModal}
                    onResetAllocationGridAndSelection={resetAllocationGrid}
                    onGridItemChange={onGridItemChange}
                    isEditAllocationLoading={false}
                />
            )}
            {isOpenManualChargeModal && (
                <CreateManualChargeModel
                    sharedIsOpenManualChargeModal={isOpenManualChargeModal}
                    sharedManualChargeOptions={chargesType}
                    onCloseAddManualChargeModel={onCloseCreateManualChargeModal}
                    onAddShipAndCloseGridData={addOrderChargesGrid}
                    isAnotherModelOpen={false}
                    windowId={props.windowId}
                />
            )}
            {isOpenConfirmModel && (
                <WmsModel
                    id={props.windowId + "confirmDeleteManualCharge"}
                    title={window.translate("Label_Confirm_Delete_Manual_Charge")}
                    width={500}
                    height={175}
                    class="confirmDeleteManualCharge"
                    onCloseFunc={clickExit}
                    customClass="wms-model-new-ui-wrapper"
                    isOpen={isOpenConfirmModel}
                >
                    <div className="model-content-wrapper">{window.translate("Label_Confirm_Selected_Charges")}</div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={window.translate("Label_Cancel")}
                            wmsSelector={props.wmsSelector + "Cancel"}
                            onClickFunc={clickExit}
                            disabled={false}
                            loading={false}
                            buttonType="yellow"
                            icon={"fa-ban"}
                        />
                        <WmsButton
                            label={window.translate("Label_Confirm_Delete")}
                            wmsSelector={props.wmsSelector + "ConfirmDelete"}
                            onClickFunc={deleteManualCharges}
                            disabled={false}
                            loading={false}
                            buttonType="red"
                            icon={"fa-trash"}
                        />
                    </div>
                </WmsModel>
            )}
        </>
    );
};
EditAssemblyModal.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    windowId: PropTypes.string.isRequired,
    selectedRow: PropTypes.any.isRequired,
    isOpenEditAssemblyModal: PropTypes.bool.isRequired,
    onCloseEditAssemblyModal: PropTypes.any.isRequired,
    isEditAssemblyLoading: PropTypes.bool.isRequired,
    onApplyEditAssembly: PropTypes.any.isRequired,
};

EditAssemblyModal.defaultProps = {
    wmsSelector: "",
    windowId: "",
    selectedRow: {},
    isOpenEditAssemblyModal: false,
    onCloseEditAssemblyModal: (): void => {},
    isEditAssemblyLoading: false,
    onApplyEditAssembly: (): void => {},
};

export default React.memo(EditAssemblyModal);
