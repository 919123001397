import React from 'react';
import * as GlobalService from './../global_services';

export const getGridDemoColumns = (ManageInventory_GridHeaders, selector) => {

    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    return ({

        columns: [{
            field: "RowNumber",
            title: "#",
            width: 60,
            wmsid: 49,
            locked: true,
            lockable: false,
            resizable: false,
            sortable: false,
            reorderable: false,
            filterable: false,
            attributes: { style: "text-align:center;" },
            headerAttributes: { style: "text-align:center;" }
        }, {
            field: "OnHold",
            title: ManageInventory_GridHeaders[0].description,
            width: 120,
            lockable: false,
            wmsid: 1,
            template: "# if(OnHold === true){# " + translate('Label_On_Hold') + " #}else if(OnHold === false){# " + translate('Label_Available') + " #}else{# " + translate('Label_Available') + " #} # ",
            headerAttributes: {
                "data-wms-selector": selector + 'OnHold'
            }
        },
        {
            field: "ReceiverId",
            lockable: false,
            title: ManageInventory_GridHeaders[1].description,
            width: 120,
            wmsid: 2,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiverId'
            }
        },
        {
            field: "ReceiveItemId",
            lockable: false,
            title: ManageInventory_GridHeaders[2].description,
            width: 120,
            wmsid: 25,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiveItemId'
            }
        },
        {
            field: "ReceiptDate",
            lockable: false,
            title: ManageInventory_GridHeaders[3].description,
            width: 120,
            filterable: true,
            template: "#= ReceiptDate != null ? kendo.toString(kendo.parseDate(ReceiptDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 3,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiptDate'
            }
        },
        {
            field: "CustomerName",
            lockable: false,
            title: customLabels.Label_Customer,
            width: 120,
            wmsid: 4,
            headerAttributes: {
                "data-wms-selector": selector + 'CustomerName'
            }
        },
        {
            field: "Sku",
            lockable: false,
            title: customLabels.Label_Sku,
            width: 120,
            wmsid: 5,
            headerAttributes: {
                "data-wms-selector": selector + 'Sku'
            }
        },
        {
            field: "PrimaryAvailable",
            lockable: false,
            title: ManageInventory_GridHeaders[6].description,
            width: 120,
            filterable: false,
            wmsid: 6,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryAvailable'
            }
        },
        {
            field: "Qualifier",
            lockable: false,
            title: ManageInventory_GridHeaders[8].description,
            width: 120,
            wmsid: 7,
            headerAttributes: {
                "data-wms-selector": selector + 'Qualifier'
            }
        },
        {
            field: "ExpirationDate",
            lockable: false,
            title: ManageInventory_GridHeaders[9].description,
            width: 120,
            template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy') : '' #",
            wmsid: 8,
            headerAttributes: {
                "data-wms-selector": selector + 'ExpirationDate'
            }
        },
        {
            field: "LotNumber",
            lockable: false,
            title: customLabels.Label_Lot_Number,
            width: 120,
            wmsid: 9,
            headerAttributes: {
                "data-wms-selector": selector + 'LotNumber'
            }
        },
        {
            field: "SerialNumber",
            lockable: false,
            title: customLabels.Label_Serial_Number,
            width: 120,
            wmsid: 10,
            headerAttributes: {
                "data-wms-selector": selector + 'SerialNumber'
            }
        },
        {
            field: "Cost",
            lockable: false,
            title: ManageInventory_GridHeaders[12].description,
            width: 120,
            wmsid: 11,
            headerAttributes: {
                "data-wms-selector": selector + 'Cost'
            }
        },
        {
            field: "LocationName",
            lockable: false,
            title: ManageInventory_GridHeaders[13].description,
            width: 120,
            filterable: false,
            wmsid: 12,
            headerAttributes: {
                "data-wms-selector": selector + 'LocationName'
            }
        },
        {
            field: "PrimaryUom",
            lockable: false,
            title: ManageInventory_GridHeaders[7].description,
            width: 120,
            wmsid: 24,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryUom'
            }
        },
        {
            field: "FacilityName",
            lockable: false,
            title: ManageInventory_GridHeaders[28].description,
            width: 120,
            wmsid: 27,
            headerAttributes: {
                "data-wms-selector": selector + 'FacilityName'
            }
        }]
    });
}


export const getAllColumnList = (ManageInventory_GridHeaders, selector) => {

    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    return ({

        columns: [{
            field: "RowNumber",
            title: "#",
            width: 60,
            wmsid: 49,
            locked: true,
            lockable: false,
            resizable: false,
            sortable: false,
            reorderable: false,
            filterable: false,
            attributes: { style: "text-align:center;" },
            headerAttributes: { style: "text-align:center;" }
        }, {
            field: "OnHold",
            lockable: false,
            title: ManageInventory_GridHeaders[0].description,
            width: 120,
            wmsid: 1,
            template: "# if(OnHold === true){# " + translate('Label_On_Hold') + " #}else if(OnHold === false){# " + translate('Label_Available') + " #}else{# " + translate('Label_Available') + " #} # ",
            headerAttributes: {
                "data-wms-selector": selector + 'OnHold'
            }
        },
        {
            field: "ReceiverId",
            lockable: false,
            title: ManageInventory_GridHeaders[1].description,
            width: 120,
            wmsid: 2,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiverId'
            }
        },
        {
            field: "ReceiveItemId",
            lockable: false,
            title: ManageInventory_GridHeaders[2].description,
            width: 120,
            wmsid: 25,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiveItemId'
            }
        },
        {
            field: "ReceiptDate",
            lockable: false,
            title: ManageInventory_GridHeaders[3].description,
            width: 120,
            filterable: true,
            template: "#= ReceiptDate != null ? kendo.toString(kendo.parseDate(ReceiptDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 3,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiptDate'
            }
        },
        {
            field: "CustomerName",
            lockable: false,
            title: customLabels.Label_Customer,
            width: 120,
            wmsid: 4,
            headerAttributes: {
                "data-wms-selector": selector + 'CustomerName'
            }
        },
        {
            field: "Sku",
            lockable: false,
            title: customLabels.Label_Sku,
            width: 120,
            wmsid: 5,
            headerAttributes: {
                "data-wms-selector": selector + 'Sku'
            }
        },
        {
            field: "PrimaryAvailable",
            lockable: false,
            title: ManageInventory_GridHeaders[6].description,
            width: 120,
            filterable: false,
            wmsid: 6,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryAvailable'
            }
        },
        {
            field: "Qualifier",
            lockable: false,
            title: ManageInventory_GridHeaders[8].description,
            width: 120,
            wmsid: 7,
            headerAttributes: {
                "data-wms-selector": selector + 'Qualifier'
            }
        },
        {
            field: "ExpirationDate",
            lockable: false,
            title: ManageInventory_GridHeaders[9].description,
            width: 120,
            template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy') : '' #",
            wmsid: 8,
            headerAttributes: {
                "data-wms-selector": selector + 'ExpirationDate'
            }
        },
        {
            field: "LotNumber",
            lockable: false,
            title: customLabels.Label_Lot_Number,
            width: 120,
            wmsid: 9,
            headerAttributes: {
                "data-wms-selector": selector + 'LotNumber'
            }
        },
        {
            field: "SerialNumber",
            lockable: false,
            title: customLabels.Label_Serial_Number,
            width: 120,
            wmsid: 10,
            headerAttributes: {
                "data-wms-selector": selector + 'SerialNumber'
            }
        },
        {
            field: "Cost",
            lockable: false,
            title: ManageInventory_GridHeaders[12].description,
            width: 120,
            wmsid: 11,
            headerAttributes: {
                "data-wms-selector": selector + 'Cost'
            }
        },
        {
            field: "LocationName",
            lockable: false,
            title: ManageInventory_GridHeaders[13].description,
            width: 120,
            filterable: false,
            wmsid: 12,
            headerAttributes: {
                "data-wms-selector": selector + 'LocationName'
            }
        },
        {
            field: "ImperialOnHandCatchWeight",
            title: ManageInventory_GridHeaders[14].description,
            width: 120,
            lockable: false,
            filterable: false,
            wmsid: 13,
            headerAttributes: {
                "data-wms-selector": selector + 'ImperialOnHandCatchWeight'
            }
        },
        {
            field: "MetricOnHandCatchWeight",
            title: ManageInventory_GridHeaders[29].description,
            width: 120,
            lockable: false,
            filterable: false,
            wmsid: 28,
            headerAttributes: {
                "data-wms-selector": selector + 'MetricOnHandCatchWeight'
            }
        },
        {
            field: "SupplierName",
            lockable: false,
            title: ManageInventory_GridHeaders[15].description,
            width: 120,
            wmsid: 14,
            headerAttributes: {
                "data-wms-selector": selector + 'SupplierName'
            }
        },
        {
            field: "PrimaryReceived",
            lockable: false,
            title: ManageInventory_GridHeaders[16].description,
            width: 120,
            filterable: false,
            wmsid: 15,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryReceived'
            }
        },
        {
            field: "PrimaryShipped",
            lockable: false,
            title: ManageInventory_GridHeaders[17].description,
            width: 120,
            filterable: false,
            wmsid: 16,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryShipped'
            }
        },
        {
            field: "PrimaryOnHand",
            lockable: false,
            title: ManageInventory_GridHeaders[18].description,
            width: 120,
            filterable: false,
            wmsid: 17,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryOnHand'
            }
        },
        {
            field: "SecondaryReceived",
            lockable: false,
            title: ManageInventory_GridHeaders[20].description,
            width: 120,
            filterable: false,
            wmsid: 18,
            headerAttributes: {
                "data-wms-selector": selector + 'SecondaryReceived'
            }
        },
        {
            field: "SecondaryAvailable",
            lockable: false,
            title: ManageInventory_GridHeaders[21].description,
            width: 120,
            template: function (dataItem) {
                var secondaryQty = dataItem.SecondaryAvailable;
                if (GlobalService.getIsIntegeralFlagByType(dataItem.SecondaryUom)) {
                    secondaryQty = Math.ceil(dataItem.SecondaryAvailable);
                }
                return (secondaryQty ? secondaryQty : 0) + " " + (dataItem.SecondaryUom ? dataItem.SecondaryUom : "");
            },
            filterable: false,
            wmsid: 19,
            headerAttributes: {
                "data-wms-selector": selector + 'SecondaryAvailable'
            }
        },
        {
            field: "SecondaryShipped",
            lockable: false,
            title: ManageInventory_GridHeaders[22].description,
            width: 120,
            filterable: false,
            wmsid: 20,
            headerAttributes: {
                "data-wms-selector": selector + 'SecondaryShipped'
            }
        },
        {
            field: "SecondaryOnHand",
            lockable: false,
            title: ManageInventory_GridHeaders[23].description,
            width: 120,
            filterable: false,
            wmsid: 21,
            headerAttributes: {
                "data-wms-selector": selector + 'SecondaryOnHand'
            }
        },
        {
            field: "MovableUnitLabel",
            lockable: false,
            title: ManageInventory_GridHeaders[24].description,
            width: 120,
            sortable: true,
            filterable: true,
            wmsid: 22,
            headerAttributes: {
                "data-wms-selector": selector + 'MovableUnitLabel'
            }
        },
        {
            field: "SkuDescription",
            lockable: false,
            title: ManageInventory_GridHeaders[25].description,
            width: 120,
            filterable: false,
            wmsid: 23,
            headerAttributes: {
                "data-wms-selector": selector + 'SkuDescription'
            }
        }, {
            field: "PrimaryUom",
            lockable: false,
            title: ManageInventory_GridHeaders[7].description,
            width: 120,
            wmsid: 24,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryUom'
            }
        },
        {
            field: "Upc",
            lockable: false,
            title: ManageInventory_GridHeaders[26].description,
            width: 120,
            wmsid: 25,
            headerAttributes: {
                "data-wms-selector": selector + 'Upc'
            }
        },
        {
            field: "SecondaryDescription",
            lockable: false,
            title: ManageInventory_GridHeaders[27].description,
            width: 120,
            wmsid: 26,
            headerAttributes: {
                "data-wms-selector": selector + 'SecondaryDescription'
            }
        }, {
            field: "FacilityName",
            lockable: false,
            title: ManageInventory_GridHeaders[28].description,
            width: 120,
            wmsid: 27,
            headerAttributes: {
                "data-wms-selector": selector + 'FacilityName'
            }
        }, {
            field: "CustomFields",
            title: ManageInventory_GridHeaders[30].description,
            width: 160,
            wmsid: 28,
            headerAttributes: {
                "data-wms-selector": selector + 'CustomFields'
            }
        }, {
            field: "OnHoldReason",
            lockable: false,
            title: ManageInventory_GridHeaders[31].description,
            width: 120,
            wmsid: 29,
            headerAttributes: {
                "data-wms-selector": selector + 'OnHoldReason'
            }
        }, {
            field: "ReferenceNum",
            lockable: false,
            title: ManageInventory_GridHeaders[32].description,
            width: 120,
            wmsid: 30,
            headerAttributes: {
                "data-wms-selector": selector + 'ReferenceNum'
            }
        },
        {
            field: "PONum",
            lockable: false,
            title: ManageInventory_GridHeaders[33].description,
            width: 120,
            wmsid: 31,
            headerAttributes: {
                "data-wms-selector": selector + 'PONum'
            }
        },
        {
            field: "TrailerNumber",
            lockable: false,
            title: ManageInventory_GridHeaders[34].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'TrailerNumber'
            }
        },
        {
            field: "PackagingUom",
            lockable: false,
            title: ManageInventory_GridHeaders[35].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'PackagingUom'
            }
        },
        {
            field: "PrimaryUnitsPerPackagingUnit",
            lockable: false,
            title: ManageInventory_GridHeaders[36].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryUnitsPerPackagingUnit'
            }
        },
        {
            field: "AvailablePackaging",
            lockable: false,
            title: ManageInventory_GridHeaders[37].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'AvailablePackaging'
            }
        },
        {
            field: "OnHandPackaging",
            lockable: false,
            title: ManageInventory_GridHeaders[38].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'OnHandPackaging'
            }
        },
        {
            field: "MovableUnitType",
            lockable: false,
            title: ManageInventory_GridHeaders[39].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'MovableUnitType'
            }
        },
        {
            field: "InventoryAgeDays",
            lockable: false,
            title: ManageInventory_GridHeaders[40].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'InventoryAgeDays'
            }
        },
        {
            field: "ImperialWidth",
            lockable: false,
            title: ManageInventory_GridHeaders[42].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'ImperialWidth'
            }
        },
        {
            field: "ImperialHeight",
            lockable: false,
            title: ManageInventory_GridHeaders[43].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'ImperialHeight'
            }
        },
        {
            field: "ImperialLength",
            lockable: false,
            title: ManageInventory_GridHeaders[41].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'ImperialLength'
            }
        },
        {
            field: "ImperialWeight",
            lockable: false,
            title: ManageInventory_GridHeaders[44].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'ImperialWeight'
            }
        }
    ]

    });
}

export const getGridDemoSchema = () => {
    return ({
        schema: {
            model: {
                id: "ReceiveItemId",
                fields: {
                    OnHold: { type: "boolean" },
                    ReceiverId: { type: "number" },
                    ReceiveItemId: { type: "number" },
                    ReceiptDate: { type: "date" },
                    CustomerName: { type: "string" },
                    Sku: { type: "string" },
                    PrimaryAvailable: { type: "number" },
                    Qualifier: { type: "string" },
                    ExpirationDate: { type: "date" },
                    LotNumber: { type: "string" },
                    SerialNumber: { type: "string" },
                    Cost: { type: "number" },
                    LocationName: { type: "string" },
                    ImperialOnHandCatchWeight: { type: "number" },
                    MetricOnHandCatchWeight: { type: "number" },
                    CatchWeight: { type: "string" },
                    SupplierName: { type: "string" },
                    PrimaryReceived: { type: "number" },
                    PrimaryShipped: { type: "number" },
                    PrimaryOnHand: { type: "number" },
                    SecondaryReceived: { type: "number" },
                    SecondaryAvailable: { type: "number" },
                    SecondaryShipped: { type: "number" },
                    SecondaryOnHand: { type: "number" },
                    MovableUnitLabel: { type: "string" },
                    PrimaryUom: { type: "string" },
                    PackagingUom: { type: "string" },
                    Upc: { type: "string" },
                    SecondaryDescription: { type: "string" },
                    OnHoldReason: { type: "string" },
                    ReferenceNum: { type: "string" },
                    PONum: { type: "string" },
                    TrailerNumber: { type: "string" },
                    PackagingUom: { type: "string"},
                    PrimaryUnitsPerPackagingUnit: { type: "number"},
                    AvailablePackaging: { type: "number"},
                    OnHandPackaging: { type: "number"},
                    MovableUnitLabel: { type: "string"},
                    InventoryAgeDays: { type: "number"},
                }
            }
        }
    })
}

export const exportGridBaseArray = () => {
    return [{
        'ReceiveItemId': 'ReceiveItemId'
    },
    {
        'OnHold': 'OnHold'
    },
    {
        'ReceiverId': 'ReceiverId'
    },
    {
        'ReceiptDate': 'ReceiptDate'
    },
    {
        'CustomerName': 'CustomerName'
    },
    {
        'ItemId': 'ItemId'
    },
    {
        'Sku': 'Sku'
    },
    {
        'SkuDescription': 'SkuDescription'
    },
    {
        'Qualifier': 'Qualifier'
    },
    {
        'ExpirationDate': 'ExpirationDate'
    },
    {
        'LotNumber': 'LotNumber'
    },
    {
        'SerialNumber': 'SerialNumber'
    },
    {
        'Cost': 'Cost'
    },
    {
        'PrimaryAvailable': 'PrimaryAvailable'
    },
    {
        'LocationName': 'LocationName'
    },
    {
        'InQuarantineLocation': 'InQuarantineLocation'
    },
    {
        'ImperialOnHandCatchWeight': 'ImperialOnHandCatchWeight'
    },
    {
        'MetricOnHandCatchWeight': 'MetricOnHandCatchWeight'
    },
    {
        'SupplierName': 'SupplierName'
    },
    {
        'PrimaryReceived': 'PrimaryReceived'
    },
    {
        'PrimaryShipped': 'PrimaryShipped'
    },
    {
        'PrimaryOnHand': 'PrimaryOnHand'
    },
    {
        'SecondaryUom': 'SecondaryUom'
    },
    {
        'SecondaryReceived': 'SecondaryReceived'
    },
    {
        'SecondaryAvailable': 'SecondaryAvailable'
    },
    {
        'SecondaryShipped': 'SecondaryShipped'
    },
    {
        'SecondaryOnHand': 'SecondaryOnHand'
    },
    {
        'MovableUnitLabel': 'MovableUnitLabel'
    },
    {
        'PrimaryUom': 'PrimaryUom'
    },
    {
        'Upc': 'Upc'
    },
    {
        'SecondaryDescription': 'SecondaryDescription'
    },
    {
        'OnHoldReason': 'OnHoldReason'
    },
    {
        'ReferenceNum': 'ReferenceNum'
    },
    {
        'PONum': 'PONum'
    },
    {
        'TrailerNumber': 'TrailerNumber'
    }];
}

// Add special configurations for combine row

export const getCombineGridColumns = (ManageInventory_GridHeaders, selector) => {

    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    return ({

        columns: [{
            field: "RowNumber",
            title: "#",
            width: 60,
            wmsid: 49,
            locked: true,
            lockable: false,
            resizable: false,
            sortable: false,
            reorderable: false,
            filterable: false,
            attributes: { style: "text-align:center;" },
            headerAttributes: { style: "text-align:center;" }
        }, {
            field: "OnHold",
            lockable: false,
            title: ManageInventory_GridHeaders[0].description,
            width: 120,
            wmsid: 1,
            template: "# if(Varies){# " + translate('Varies') + " #} else if(OnHold === true){# " + translate('Label_On_Hold') + " #}else if(OnHold === false){# " + translate('Label_Available') + " #}else{# " + translate('Label_Available') + " #} # ",
            headerAttributes: {
                "data-wms-selector": selector + 'OnHold'
            }
        },
        {
            field: "ReceiverId",
            lockable: false,
            title: ManageInventory_GridHeaders[1].description,
            width: 120,
            wmsid: 2,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiverId'
            }
        },
        {
            field: "ReceiveItemId",
            lockable: false,
            title: ManageInventory_GridHeaders[2].description,
            width: 120,
            wmsid: 25,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiveItemId'
            }
        },
        {
            field: "ReceiptDate",
            lockable: false,
            title: ManageInventory_GridHeaders[3].description,
            width: 120,
            filterable: true,
            // template: "#= ReceiptDate != null ? kendo.toString(kendo.parseDate(ReceiptDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 3,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiptDate'
            }
        },
        {
            field: "CustomerName",
            lockable: false,
            title: customLabels.Label_Customer,
            width: 120,
            wmsid: 4,
            headerAttributes: {
                "data-wms-selector": selector + 'CustomerName'
            }
        },
        {
            field: "Sku",
            lockable: false,
            title: customLabels.Label_Sku,
            width: 120,
            wmsid: 5,
            headerAttributes: {
                "data-wms-selector": selector + 'Sku'
            }
        },
        {
            field: "PrimaryAvailable",
            lockable: false,
            title: ManageInventory_GridHeaders[6].description,
            width: 120,
            filterable: false,
            wmsid: 6,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryAvailable'
            }
        },
        {
            field: "Qualifier",
            lockable: false,
            title: ManageInventory_GridHeaders[8].description,
            width: 120,
            wmsid: 7,
            headerAttributes: {
                "data-wms-selector": selector + 'Qualifier'
            }
        },
        {
            field: "ExpirationDate",
            lockable: false,
            title: ManageInventory_GridHeaders[9].description,
            width: 120,
            template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy') : '' #",
            wmsid: 8,
            headerAttributes: {
                "data-wms-selector": selector + 'ExpirationDate'
            }
        },
        {
            field: "LotNumber",
            lockable: false,
            title: customLabels.Label_Lot_Number,
            width: 120,
            wmsid: 9,
            headerAttributes: {
                "data-wms-selector": selector + 'LotNumber'
            }
        },
        {
            field: "SerialNumber",
            lockable: false,
            title: customLabels.Label_Serial_Number,
            width: 120,
            wmsid: 10,
            headerAttributes: {
                "data-wms-selector": selector + 'SerialNumber'
            }
        },
        {
            field: "Cost",
            lockable: false,
            title: ManageInventory_GridHeaders[12].description,
            width: 120,
            wmsid: 11,
            headerAttributes: {
                "data-wms-selector": selector + 'Cost'
            }
        },
        {
            field: "LocationName",
            lockable: false,
            title: ManageInventory_GridHeaders[13].description,
            width: 120,
            filterable: false,
            wmsid: 12,
            headerAttributes: {
                "data-wms-selector": selector + 'LocationName'
            }
        },
        {
            field: "PrimaryUom",
            lockable: false,
            title: ManageInventory_GridHeaders[7].description,
            width: 120,
            wmsid: 24,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryUom'
            }
        },
        {
            field: "Upc",
            lockable: false,
            title: ManageInventory_GridHeaders[26].description,
            width: 120,
            wmsid: 25,
            headerAttributes: {
                "data-wms-selector": selector + 'Upc'
            }
        },
        {
            field: "SecondaryDescription",
            lockable: false,
            title: ManageInventory_GridHeaders[27].description,
            width: 120,
            wmsid: 26,
            headerAttributes: {
                "data-wms-selector": selector + 'SecondaryDescription'
            }
        }, {
            field: "FacilityName",
            lockable: false,
            title: ManageInventory_GridHeaders[28].description,
            width: 120,
            wmsid: 27,
            headerAttributes: {
                "data-wms-selector": selector + 'FacilityName'
            }
        }]
    });
}

export const getCombineAllColumnList = (ManageInventory_GridHeaders, selector) => {

    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    return ({

        columns: [{
            field: "RowNumber",
            title: "#",
            width: 60,
            wmsid: 49,
            locked: true,
            lockable: false,
            resizable: false,
            sortable: false,
            reorderable: false,
            filterable: false,
            attributes: { style: "text-align:center;" },
            headerAttributes: { style: "text-align:center;" }
        }, {
            field: "OnHold",
            lockable: false,
            title: ManageInventory_GridHeaders[0].description,
            width: 120,
            wmsid: 1,
            template: "# if(Varies){# " + translate('Varies') + " #} else if(OnHold === true){# " + translate('Label_On_Hold') + " #}else if(OnHold === false){# " + translate('Label_Available') + " #}else{# " + translate('Label_Available') + " #} # ",
            headerAttributes: {
                "data-wms-selector": selector + 'OnHold'
            }
        },
        {
            field: "ReceiverId",
            lockable: false,
            title: ManageInventory_GridHeaders[1].description,
            width: 120,
            wmsid: 2,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiverId'
            }
        },
        {
            field: "ReceiveItemId",
            lockable: false,
            title: ManageInventory_GridHeaders[2].description,
            width: 120,
            wmsid: 25,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiveItemId'
            }
        },
        {
            field: "ReceiptDate",
            lockable: false,
            title: ManageInventory_GridHeaders[3].description,
            width: 120,
            filterable: true,
            // template: "#= ReceiptDate != null ? kendo.toString(kendo.parseDate(ReceiptDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 3,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiptDate'
            }
        },
        {
            field: "CustomerName",
            lockable: false,
            title: customLabels.Label_Customer,
            width: 120,
            wmsid: 4,
            headerAttributes: {
                "data-wms-selector": selector + 'CustomerName'
            }
        },
        {
            field: "Sku",
            lockable: false,
            title: customLabels.Label_Sku,
            width: 120,
            wmsid: 5,
            headerAttributes: {
                "data-wms-selector": selector + 'Sku'
            }
        },
        {
            field: "PrimaryAvailable",
            lockable: false,
            title: ManageInventory_GridHeaders[6].description,
            width: 120,
            filterable: false,
            wmsid: 6,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryAvailable'
            }
        },
        {
            field: "Qualifier",
            lockable: false,
            title: ManageInventory_GridHeaders[8].description,
            width: 120,
            wmsid: 7,
            headerAttributes: {
                "data-wms-selector": selector + 'Qualifier'
            }
        },
        {
            field: "ExpirationDate",
            lockable: false,
            title: ManageInventory_GridHeaders[9].description,
            width: 120,
            template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy') : '' #",
            wmsid: 8,
            headerAttributes: {
                "data-wms-selector": selector + 'ExpirationDate'
            }
        },
        {
            field: "LotNumber",
            lockable: false,
            title: customLabels.Label_Lot_Number,
            width: 120,
            wmsid: 9,
            headerAttributes: {
                "data-wms-selector": selector + 'LotNumber'
            }
        },
        {
            field: "SerialNumber",
            lockable: false,
            title: customLabels.Label_Serial_Number,
            width: 120,
            wmsid: 10,
            headerAttributes: {
                "data-wms-selector": selector + 'SerialNumber'
            }
        },
        {
            field: "Cost",
            lockable: false,
            title: ManageInventory_GridHeaders[12].description,
            width: 120,
            wmsid: 11,
            headerAttributes: {
                "data-wms-selector": selector + 'Cost'
            }
        },
        {
            field: "LocationName",
            lockable: false,
            title: ManageInventory_GridHeaders[13].description,
            width: 120,
            filterable: false,
            wmsid: 12,
            headerAttributes: {
                "data-wms-selector": selector + 'LocationName'
            }
        },
        {
            field: "ImperialOnHandCatchWeight",
            title: ManageInventory_GridHeaders[14].description,
            width: 120,
            lockable: false,
            filterable: false,
            wmsid: 13,
            headerAttributes: {
                "data-wms-selector": selector + 'ImperialOnHandCatchWeight'
            }
        },
        {
            field: "MetricOnHandCatchWeight",
            title: ManageInventory_GridHeaders[29].description,
            width: 120,
            lockable: false,
            filterable: false,
            wmsid: 28,
            headerAttributes: {
                "data-wms-selector": selector + 'MetricOnHandCatchWeight'
            }
        },
        {
            field: "SupplierName",
            lockable: false,
            title: ManageInventory_GridHeaders[15].description,
            width: 120,
            wmsid: 14,
            headerAttributes: {
                "data-wms-selector": selector + 'SupplierName'
            }
        },
        {
            field: "PrimaryReceived",
            lockable: false,
            title: ManageInventory_GridHeaders[16].description,
            width: 120,
            filterable: false,
            wmsid: 15,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryReceived'
            }
        },
        {
            field: "PrimaryShipped",
            lockable: false,
            title: ManageInventory_GridHeaders[17].description,
            width: 120,
            filterable: false,
            wmsid: 16,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryShipped'
            }
        },
        {
            field: "PrimaryOnHand",
            lockable: false,
            title: ManageInventory_GridHeaders[18].description,
            width: 120,
            filterable: false,
            wmsid: 17,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryOnHand'
            }
        },
        {
            field: "SecondaryReceived",
            lockable: false,
            title: ManageInventory_GridHeaders[20].description,
            width: 120,
            filterable: false,
            wmsid: 18,
            headerAttributes: {
                "data-wms-selector": selector + 'SecondaryReceived'
            }
        },
        {
            field: "SecondaryAvailable",
            lockable: false,
            title: ManageInventory_GridHeaders[21].description,
            width: 120,
            // template: function (dataItem) {
            //     return dataItem.SecondaryAvailable + " " + dataItem.SecondaryUom;
            // },
            filterable: false,
            wmsid: 19,
            headerAttributes: {
                "data-wms-selector": selector + 'SecondaryAvailable'
            }
        },
        {
            field: "SecondaryShipped",
            lockable: false,
            title: ManageInventory_GridHeaders[22].description,
            width: 120,
            filterable: false,
            wmsid: 20,
            headerAttributes: {
                "data-wms-selector": selector + 'SecondaryShipped'
            }
        },
        {
            field: "SecondaryOnHand",
            lockable: false,
            title: ManageInventory_GridHeaders[23].description,
            width: 120,
            filterable: false,
            wmsid: 21,
            headerAttributes: {
                "data-wms-selector": selector + 'SecondaryOnHand'
            }
        },
        {
            field: "MovableUnitLabel",
            lockable: false,
            title: ManageInventory_GridHeaders[24].description,
            width: 120,
            sortable: true,
            filterable: true,
            wmsid: 22,
            headerAttributes: {
                "data-wms-selector": selector + 'MovableUnitLabel'
            }
        },
        {
            field: "SkuDescription",
            lockable: false,
            title: ManageInventory_GridHeaders[25].description,
            width: 120,
            filterable: false,
            wmsid: 23,
            headerAttributes: {
                "data-wms-selector": selector + 'SkuDescription'
            }
        }, {
            field: "PrimaryUom",
            lockable: false,
            title: ManageInventory_GridHeaders[7].description,
            width: 120,
            wmsid: 24,
            headerAttributes: {
                "data-wms-selector": selector + 'PrimaryUom'
            }
        },
        {
            field: "Upc",
            lockable: false,
            title: ManageInventory_GridHeaders[26].description,
            width: 120,
            wmsid: 25,
            headerAttributes: {
                "data-wms-selector": selector + 'Upc'
            }
        },
        {
            field: "SecondaryDescription",
            lockable: false,
            title: ManageInventory_GridHeaders[27].description,
            width: 120,
            wmsid: 26,
            headerAttributes: {
                "data-wms-selector": selector + 'SecondaryDescription'
            }
        }, {
            field: "FacilityName",
            lockable: false,
            title: ManageInventory_GridHeaders[28].description,
            width: 120,
            wmsid: 27,
            headerAttributes: {
                "data-wms-selector": selector + 'FacilityName'
            }
        }, {
            field: "CustomFields",
            title: ManageInventory_GridHeaders[30].description,
            width: 160,
            wmsid: 28,
            headerAttributes: {
                "data-wms-selector": selector + 'CustomFields'
            }
        }, {
            field: "OnHoldReason",
            lockable: false,
            title: ManageInventory_GridHeaders[31].description,
            width: 120,
            wmsid: 29,
            headerAttributes: {
                "data-wms-selector": selector + 'OnHoldReason'
            }
        }, {
            field: "ReferenceNum",
            lockable: false,
            title: ManageInventory_GridHeaders[32].description,
            width: 120,
            wmsid: 30,
            headerAttributes: {
                "data-wms-selector": selector + 'ReferenceNum'
            }
        },
        {
            field: "PONum",
            lockable: false,
            title: ManageInventory_GridHeaders[33].description,
            width: 120,
            wmsid: 31,
            headerAttributes: {
                "data-wms-selector": selector + 'PONum'
            }
        },
        {
            field: "TrailerNumber",
            lockable: false,
            title: ManageInventory_GridHeaders[34].description,
            width: 120,
            wmsid: 32,
            headerAttributes: {
                "data-wms-selector": selector + 'TrailerNumber'
            }
        }]
    });
}

export const getGridCombineSchema = () => {
    return ({
        schema: {
            model: {
                id: "ReceiveItemId",
                fields: {
                    OnHold: { type: "boolean" },
                    Varies: { type: "boolean" },
                    ReceiverId: { type: "string" },
                    ReceiveItemId: { type: "string" },
                    ReceiptDate: { type: "string" },
                    CustomerName: { type: "string" },
                    Sku: { type: "string" },
                    PrimaryAvailable: { type: "string" },
                    Qualifier: { type: "string" },
                    ExpirationDate: { type: "date" },
                    LotNumber: { type: "string" },
                    SerialNumber: { type: "string" },
                    Cost: { type: "string" },
                    LocationName: { type: "string" },
                    ImperialOnHandCatchWeight: { type: "string" },
                    MetricOnHandCatchWeight: { type: "string" },
                    CatchWeight: { type: "string" },
                    SupplierName: { type: "string" },
                    PrimaryReceived: { type: "number" },
                    PrimaryShipped: { type: "number" },
                    PrimaryOnHand: { type: "number" },
                    SecondaryReceived: { type: "number" },
                    SecondaryAvailable: { type: "string" },
                    SecondaryShipped: { type: "number" },
                    SecondaryOnHand: { type: "number" },
                    MovableUnitLabel: { type: "string" },
                    PrimaryUom: { type: "string" },
                    Upc: { type: "string" },
                    SecondaryDescription: { type: "string" },
                    OnHoldReason: { type: "string" },
                    ReferenceNum: { type: "string" },
                    PONum: { type: "string" },
                    TrailerNumber: { type: "string" }
                }
            }
        }
    })
}