import * as GlobalService from "../react/global_services";
var isFirstTimeOpen = true;
var keyName = "AdjustCustomerCharges";
var metaDataAdjustCustomerChargesObject = {
    "AdjustCustomerCharges": {
        "filterData": {},
        "gridConfig": {},
    },
};
var axios = require("axios");
declare var window: any;
const { $ } = window;

export const setGridFilter = function (param_filterObj, cancleToken) {
    console.log("SetGridFilter", param_filterObj);

    let url = "/WebUI/customers/charges/FilteredRowCount";

    return axios.put(url, param_filterObj, { cancelToken: cancleToken }).then((response: any) => {
        return response;
    });
};
export const clearGridFiltere = function (param_filterObj) {
    let url = "/WebUI/customers/UpdateSession/ChargesSearchInfo";

    return axios.delete(url).then((response: any) => {
        return response;
    });
};

export const getGridData = function () {
    let url = "/WebUI/customers/charges/chargeslist?sort=&page=1&pageSize=50&group=&filter="; // ?sort=&page=1&pageSize=50&group=&filter=

    return axios.post(url).then(function (response: any) {
        return response;
    });
};
export const getPredefinedChange = function (customerId, facilityId) {
    return axios
        .get(`/WebUI/inventory/adjustments/CustomerAdjustmentCharges?customerId=${customerId}&facilityId=${facilityId}`)
        .then(function (response) {
            return response;
        });
};

// call api for get metaData of AdjustCustomerCharges is it's open first time
export const getAdjustCustomerChargesClientMetaData = function () {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response !== "") {
                    Object.assign(metaDataAdjustCustomerChargesObject[keyName], JSON.parse(response));
                }
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                return false;
            }
        );
    }
};

export const setAdjustCustomerChargesFilterMetaData = function (filterData) {
    metaDataAdjustCustomerChargesObject[keyName]["filterData"] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataAdjustCustomerChargesObject[keyName]));
};

export const getAdjustCustomerChargesFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataAdjustCustomerChargesObject[keyName]["filterData"])) {
        return metaDataAdjustCustomerChargesObject[keyName]["filterData"];
    } else {
        return "";
    }
};
export const setAdjustCustomerChargesGridOptionInMetaData = function (gridConfig) {
    metaDataAdjustCustomerChargesObject[keyName]["gridConfig"] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataAdjustCustomerChargesObject[keyName]));
};
export const getAdjustCustomerChargesGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataAdjustCustomerChargesObject[keyName]["gridConfig"])) {
        return metaDataAdjustCustomerChargesObject[keyName]["gridConfig"];
    } else {
        return "";
    }
};

export const clearAdjustCustomerChargesFilterMetaData = function () {
    metaDataAdjustCustomerChargesObject[keyName]["filterData"] = {};
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataAdjustCustomerChargesObject[keyName]));
};

export const saveCancelAdjustment = function (uri: any, data: any) {
    let url = "/WebUI/customers/charges/chargesreverser?uri=" + uri;
    return axios.post(url, data).then(function (response: any) {
        return response;
    });
};
export const getCharges = function () {
    return axios.get("/WebUI/Shipping/Orders/GetChargeCategories?idList=1,2,3,5,6").then(function (response: any) {
        return response;
    });
};
export const createCharges = function (payload) {
    return axios.post("/WebUI/customers/charges/charges", { data: payload }).then(function (response: any) {
        return response;
    });
};
