import React from 'react';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsRadio from "../../components/WmsRadio/WmsRadio.tsx";
import WmsCheckBox from "../../components/WmsCheckBox/WmsCheckBox.jsx";
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import WmsContainer from '../../components/WmsContainer/WmsContainer.jsx';
import WmsDropdownList from '../../components/WmsDropdownList/WmsDropdownList.jsx';

//services
import * as manageOrderServices from './manage-orders.services.jsx';
import * as GlobalService from '../../global_services';
import * as manageOrderProtoMetaDataService from './manage-order-proto-meta-data.service';

export class ManageOrdersContactDetails extends React.Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;

        this.state = {
            customLabels: GlobalService.getCustomLabels(),
            optionsCountry: GlobalService.GetCountries(),
            selector: 'OrderProtoTypeContactDetail',
            billToUseCustom: true,
            indexSoldToOption: 0,
            selectShipToFavorite: '',
            selectBillToFavorite: '',
            selectSoldToFavorite: '',
            manageOrdersEditData: manageOrderServices.getManageOrdersData(),
            isBillToFavoritesDropdownRender: false,
            isSoldToFavoritesDropdownRender: false,
            optionsSoldTo: [{
                "description": "Enter custom sold to information",
                "value": 0
            }, {
                "description": "Use shipping information",
                "value": 1
            }, {
                "description": "Use billing information",
                "value": 2
            }],
            isExpanded: props.isPrototypeComponent ? ((!$.isEmptyObject(props.metaDataList) && props.metaDataList.hasOwnProperty('collapse1')) ? !props.metaDataList['collapse1'] : true) : true
        };
    }

    setCollapseMetaData = (value) => {
        if (this.props.isPrototypeComponent) {
            manageOrderProtoMetaDataService.setManageOrderProtoCollapseMetaData("collapse1", value);
        }
    }

    componentDidMount() {
        $("." + this.props.windowId + " #contactTabStrip").kendoTabStrip({
            select: this.onSelectTab
        });
    }

    onSelectTab = (e) => {

        if (e.item.textContent == this.translate('Label_Billing_Information')) {
            this.setState({
                isBillToFavoritesDropdownRender: true
            });
        }
        if (e.item.textContent == this.translate('Label_Sold_To_Information')) {
            this.setState({
                isSoldToFavoritesDropdownRender: true
            });
        }
    }

    handleChangeEvent = (parentObj_name, event) => {

        let propsOrderObj = this.props.shareOrderData;
        if (event.target.type == 'checkbox') {
            if (typeof propsOrderObj[parentObj_name]['IsQuickLookup'] == 'boolean') {
                propsOrderObj[parentObj_name]['IsQuickLookup'] = !propsOrderObj[parentObj_name]['IsQuickLookup'];
            } else {
                propsOrderObj[parentObj_name]['IsQuickLookup'] = propsOrderObj[parentObj_name]['IsQuickLookup'].toLowerCase() == 'true' ? false : true;
            }
        } else {
            propsOrderObj[parentObj_name][event.target.name] = event.target.value;
        }
        this.props.onOrderDataSet(propsOrderObj, true);
    }

    handleFavoriteContactChange = (param_selectOpt) => {

        let uri = param_selectOpt.target.value.ContactUri;
        let name = param_selectOpt.target.name;
        this.setState({ ['select' + name + 'Favorite']: param_selectOpt.target.value.Value });

        if (uri != "" && uri != null) {
            manageOrderServices.getContactFromUri(uri).then((response) => {
                if (response && response.status == 200) {
                    let propsOrderObj = this.props.shareOrderData;
                    propsOrderObj[name] = response.data;
                    propsOrderObj[name].IsQuickLookup = false;
                    this.props.onOrderDataSet(propsOrderObj, true);
                    this.props.onRegisterContactSet(response.data,name);
                }
            }).finally(() => { });
        }
    }

    eventBillToUseChanged = (param_is_billto_use) => {

        let billToChangedObj = [];
        if (param_is_billto_use == false) {
            billToChangedObj = this.props.shareOrderData.ShipTo;
        } else {
            billToChangedObj = [{}];
            billToChangedObj['Country'] = null;
        }

        this.setState({ billToUseCustom: param_is_billto_use });
        let propsOrderObj = this.props.shareOrderData;
        propsOrderObj['BillTo'] = billToChangedObj;
        this.props.onRegisterContactSet(billToChangedObj,'BillTo');
        this.props.onOrderDataSet(propsOrderObj, true);

    }

    eventOptionsSoldToChange = (e) => {

        let soldToChangedObj = [];

        if (e.target.value == 0) {
            soldToChangedObj = [{}];
            soldToChangedObj['Country'] = null;
        } else if (e.target.value == 1) {
            soldToChangedObj = Object.assign({}, this.props.shareOrderData.ShipTo);
        } else if (e.target.value == 2) {
            soldToChangedObj = Object.assign({}, this.props.shareOrderData.BillTo);
        }

        this.setState({ 'indexSoldToOption': e.target.value });
        let propsOrderObj = this.props.shareOrderData;
        propsOrderObj['SoldTo'] = soldToChangedObj;
        this.props.onRegisterContactSet(soldToChangedObj,'SoldTo');
        this.props.onOrderDataSet(propsOrderObj, true);
    }

    render() {
        let renderOrderObj = _.cloneDeep(this.props.shareOrderData);
        return (
            <div className="contact-detail-section collapseOrderTabContent_wrapper" ref={this.props.divRefs}>
                <WmsCollapse id={'collapse1'} isExpanded={this.state.isExpanded} setCollapseMetaData={this.setCollapseMetaData} windowId={this.props.windowId} headerLabel={this.translate('Label_Order_Contact_Details')}  showCollapseText={false}>
                    <div id="contactTabStrip">
                        {/*TAB list */}
                        <ul>
                            <li className="k-state-active">{this.translate('Label_Shipping_Destination')}</li>
                            <li>{this.translate('Label_Billing_Information')}</li>
                            <li>{this.translate('Label_Sold_To_Information')}</li>
                        </ul>

                        {/* TAB 1 Shipping destination */}
                        <div>
                            <div className="title-block">

                                <WmsDropdownList
                                    id={"ShipToFavoritesDropdown"}
                                    name="ShipTo"
                                    windowId={this.props.windowId}
                                    optionsLabel={this.translate('Label_Lookup_Contacts_By_Code')}
                                    label={this.translate('Label_Shipping_Destination_Favorites')}
                                    wmsSelector={this.state.selector + 'ShippingDestinationFavorites'}
                                    value={this.state.selectShipToFavorite}
                                    onChangeFunc={this.handleFavoriteContactChange}
                                    textField="Display"
                                    valueField="Value"
                                    suggest={true}
                                    minLength={2}
                                    height={300}
                                    dataSourceObj={{
                                        pageSize: 40,
                                        serverPaging: true,
                                        serverFiltering: true,
                                        type: "aspnetmvc-ajax",
                                        transport: {
                                            read: {
                                                dataType: "json",
                                                url: '/WebUI/Shipping/Orders/ShipToContactFavorites',
                                                type: "PUT",
                                                data: {
                                                    "orderContactsUri": this.state.manageOrdersEditData.OrderContactsUri
                                                }
                                            },
                                        },
                                        schema: {
                                            data: "Data",
                                            total: "Total"
                                        }
                                    }}
                                    filteringObj={function(ev){
                                        var filterValue = ev.filter != undefined ? ev.filter.value : "";
                                        ev.preventDefault();
                                        if(filterValue != ""){
                                            this.dataSource.filter({
                                                logic: "or",
                                                filters: [
                                                {
                                                    field: "CompanyName",
                                                    operator: "contains",
                                                    value: filterValue
                                                },
                                                {
                                                    field: "Code",
                                                    operator: "contains",
                                                    value: filterValue
                                                }
                                                ]
                                            });
                                        }else{
                                            this.dataSource.filter({
                                                    field: "CompanyName",
                                                    operator: "contains",
                                                    value: ""
                                            });
                                        }
                                    }}
                                />

                                <WmsButton
                                    label={this.translate('Label_Advanced_Lookup')}
                                    wmsSelector={this.state.selector + 'ShipToAdvancedLookupButton'}
                                    onClickFunc={() => this.props.onOpenAdvanceLookupModal('ShipTo')}
                                    icon='fa-plus-circle' />

                            </div>

                            <WmsContainer title={this.translate('Label_Contact_Information')}>
                            <div className='contact-Info-wrapper'>
                                <div>
                                <WmsInput
                                    id='ShipToCode'
                                    inputName='Code'
                                    wmsSelector={this.state.selector + 'ShipToCode'}
                                    value={renderOrderObj.ShipTo.Code}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    label={this.translate('Label_Contact_Code')}
                                    regularExp={/^.{1,255}$/} />

                                <WmsCheckBox
                                    id={'ShipToAddContact' + this.props.windowId}
                                    name={'IsQuickLookup' + this.props.windowId}
                                    wmsSelector={this.state.selector + 'ShipToAddContact'}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    label={this.translate('Label_Add_To_Favorites')}
                                    value={renderOrderObj.ShipTo.IsQuickLookup}
                                    checked={typeof renderOrderObj.ShipTo.IsQuickLookup == 'boolean' ? renderOrderObj.ShipTo.IsQuickLookup : renderOrderObj.ShipTo.IsQuickLookup == "true"} />
                                </div>
                                <div>
                                <WmsInput
                                    id='ShipToCompanyName'
                                    inputName='CompanyName'
                                    wmsSelector={this.state.selector + 'ShipToCompanyName'}
                                    value={renderOrderObj.ShipTo.CompanyName}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    label={this.translate('Label_Company_Name')}
                                    required={true}
                                    regularExp={/^.{1,255}$/} />

                                <WmsInput
                                    id='ShipToName'
                                    inputName='Name'
                                    wmsSelector={this.state.selector + 'ShipToName'}
                                    value={renderOrderObj.ShipTo.Name}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    label={this.translate('Label_Receipient')}
                                    regularExp={/^.{1,255}$/} />

                                <WmsInput
                                    id='ShipToPhoneNumber'
                                    inputName='PhoneNumber'
                                    wmsSelector={this.state.selector + 'ShipToPhoneNumber'}
                                    value={renderOrderObj.ShipTo.PhoneNumber}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    label={this.translate('Label_Phone')}
                                    regularExp={/^.{1,255}$/} />

                                <WmsInput
                                    id='ShipToEmailAddress'
                                    inputName='EmailAddress'
                                    wmsSelector={this.state.selector + 'ShipToEmailAddress'}
                                    value={renderOrderObj.ShipTo.EmailAddress}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    label={this.translate('Label_Email')}
                                    regularExp={/^.{1,255}$/} />
                                </div>

                                <div>
                                <WmsInput
                                    id='ShipToAddress1'
                                    inputName='Address1'
                                    wmsSelector={this.state.selector + 'ShipToAddress1'}
                                    value={renderOrderObj.ShipTo.Address1}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    label={this.translate('Label_Address_1')}
                                    required={true}
                                    regularExp={/^.{1,255}$/} />

                                <WmsInput
                                    id='ShipToAddress2'
                                    inputName='Address2'
                                    wmsSelector={this.state.selector + 'ShipToAddress2'}
                                    value={renderOrderObj.ShipTo.Address2}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    label={this.translate('Label_Address_2')}
                                    regularExp={/^.{1,255}$/} />
                                </div>

                                <div>
                                <WmsDropdown
                                    id="ShipToCountry"
                                    name="Country"
                                    label={this.translate('Label_Country')}
                                    wmsSelector={this.state.selector + 'ShipToCountry'}
                                    value={renderOrderObj.ShipTo.Country}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    options={this.state.optionsCountry}
                                    textField='Name'
                                    valueField='CountryCode'
                                    required={true} />

                                <WmsDropdown
                                    id="ShipToStatesComboBox"
                                    name="State"
                                    label={this.state.customLabels.Label_State}
                                    wmsSelector={this.state.selector + 'ShipToState'}
                                    value={renderOrderObj.ShipTo.State}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    options={GlobalService.GetRegions(renderOrderObj.ShipTo.Country)}
                                    disabled={!renderOrderObj.ShipTo.Country}
                                    showToolTip={true}
                                    tooltipText={this.translate('Label_Country_Select_Validation')}
                                    textField='Name'
                                    valueField='RegionCode' />

                                <WmsInput
                                    id='ShipToCity'
                                    inputName='City'
                                    wmsSelector={this.state.selector + 'ShipToCity'}
                                    value={renderOrderObj.ShipTo.City}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    label={this.state.customLabels.Label_City}
                                    regularExp={/^.{1,255}$/}
                                    required={true} />

                                <WmsInput
                                    id='ShipToZip'
                                    inputName='Zip'
                                    wmsSelector={this.state.selector + 'ShipToZip'}
                                    value={renderOrderObj.ShipTo.Zip}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    label={this.translate('Label_Costomer_Postal_Code')}
                                    regularExp={/^[a-zA-Z0-9-_ ]*$/}
                                    required={true} />


                                {/* <div class="wms-form-validation-text" ng-messages="ManageOrders.validateShipToEmail.$error">
                                    <div ng-message="pattern">{this.translate('Label_Invalid_Email')}</div>
                                </div> */}
                                </div>

                            {/* </WmsContainer> */}
                            {/* <div className="wms-additional-information"> */}
                            {/* <WmsContainer title={this.translate('Label_Additional_Information')}> */}
                                <div>
                                <WmsDropdown
                                    id="ShipToRetailerId"
                                    name="RetailerId"
                                    label={this.translate('Label_Retailer_ID')}
                                    wmsSelector={this.state.selector + 'OptionsShipToRetailers'}
                                    value={renderOrderObj.ShipTo.RetailerId}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    options={this.props.sharedOptions.Retailers}
                                    valueField='RetailerId'
                                    textField='Description' />

                                <WmsInput
                                    id='ShipToDept'
                                    inputName='Dept'
                                    wmsSelector={this.state.selector + 'ShipToDept'}
                                    value={renderOrderObj.ShipTo.Dept}
                                    onChangeFunc={(e) => this.handleChangeEvent('ShipTo', e)}
                                    label={this.translate('Label_Department_Number')}
                                    regularExp={/^.{1,255}$/} />
                                </div>
                            </div>
                            </WmsContainer>
                        {/* </div> */}
                    </div>

                        {/* TAB 2 Billing information */}
                        <div>
                            <WmsRadio
                                label={this.translate("Label_Enter_Custom_Billing_Information")}
                                name={"billToUseCustom" + this.props.windowId}
                                value={this.state.billToUseCustom}
                                wmsSelector={''}
                                onChangeFunc={(e) => this.eventBillToUseChanged(true)}
                                checked={this.state.billToUseCustom == true}
                            />
                            <WmsRadio
                                label={this.translate("Label_Use_Shipping_Information")}
                                name={"billToUseCustom" + this.props.windowId}
                                value={this.state.billToUseCustom}
                                wmsSelector={''}
                                onChangeFunc={(e) => this.eventBillToUseChanged(false)}
                                checked={this.state.billToUseCustom == false}
                            />
                            <div className="title-block">

                                {this.state.isBillToFavoritesDropdownRender &&
                                    <WmsDropdownList
                                        id={"BillToFavoritesDropdown"}
                                        name="BillTo"
                                        windowId={this.props.windowId}
                                        optionsLabel={this.translate('Label_Lookup_Contacts_By_Code')}
                                        label={this.translate('Label_Billing_Information_Favorites')}
                                        wmsSelector={''}
                                        value={this.state.selectBillToFavorite}
                                        onChangeFunc={this.handleFavoriteContactChange}
                                        textField="Display"
                                        valueField="Value"
                                        suggest={true}
                                        minLength={2}
                                        height={300}
                                        dataSourceObj={{
                                            pageSize: 40,
                                            serverPaging: true,
                                            serverFiltering: true,
                                            type: "aspnetmvc-ajax",
                                            transport: {
                                                read: {
                                                    dataType: "json",
                                                    url: '/WebUI/Shipping/Orders/BillToContactFavorites',
                                                    type: "PUT",
                                                    data: {
                                                        "orderContactsUri": this.state.manageOrdersEditData.OrderContactsUri
                                                    }
                                                },
                                            },
                                            schema: {
                                                data: "Data",
                                                total: "Total"
                                            }
                                        }}
                                        filteringObj={function(ev){
                                            var filterValue = ev.filter != undefined ? ev.filter.value : "";
                                            ev.preventDefault();
                                            if(filterValue != ""){
                                                this.dataSource.filter({
                                                    logic: "or",
                                                    filters: [
                                                    {
                                                        field: "CompanyName",
                                                        operator: "contains",
                                                        value: filterValue
                                                    },
                                                    {
                                                        field: "Code",
                                                        operator: "contains",
                                                        value: filterValue
                                                    }
                                                    ]
                                                });
                                            }else{
                                                this.dataSource.filter({
                                                        field: "CompanyName",
                                                        operator: "contains",
                                                        value: ""
                                                });
                                            }
                                        }}
                                    />
                                }
                                <WmsButton
                                    label={this.translate('Label_Advanced_Lookup')}
                                    wmsSelector={this.state.selector + 'BillToAdvancedLookupButton'}
                                    onClickFunc={() => this.props.onOpenAdvanceLookupModal('BillTo')}
                                    disabled={this.state.billToUseCustom == false}
                                    icon='fa-plus-circle' />

                            </div>
                            <WmsContainer title={this.translate('Label_Contact_Information')}>
                            <div className='contact-Info-wrapper'>
                                <div>
                                <WmsInput
                                    id='BillToCode'
                                    inputName='Code'
                                    wmsSelector={this.state.selector + 'BillToCode'}
                                    value={renderOrderObj.BillTo.Code}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    label={this.translate('Label_Contact_Code')}
                                    regularExp={/^.{1,255}$/}
                                    disabled={this.state.billToUseCustom == false}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsCheckBox
                                    id={'BillToAddContact' + this.props.windowId}
                                    name={'IsQuickLookup' + this.props.windowId}
                                    wmsSelector={this.state.selector + 'BillToAddContact'}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    label={this.translate('Label_Add_To_Favorites')}
                                    value={renderOrderObj.BillTo.IsQuickLookup}
                                    checked={typeof renderOrderObj.BillTo.IsQuickLookup == 'boolean' ? renderOrderObj.BillTo.IsQuickLookup : renderOrderObj.BillTo.IsQuickLookup == "true"}
                                    disabled={this.state.billToUseCustom == false}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled ' : ''}
                                />
                                </div>

                                <div>
                                <WmsInput
                                    id='BillToCompanyName'
                                    inputName='CompanyName'
                                    wmsSelector={this.state.selector + 'BillToCompanyName'}
                                    value={renderOrderObj.BillTo.CompanyName}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    label={this.translate('Label_Company_Name')}
                                    disabled={this.state.billToUseCustom == false}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsInput
                                    id='BillToName'
                                    inputName='Name'
                                    wmsSelector={this.state.selector + 'BillToName'}
                                    value={renderOrderObj.BillTo.Name}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    label={this.translate('Label_Receipient')}
                                    disabled={this.state.billToUseCustom == false}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />
                                <WmsInput
                                    id='BillToPhoneNumber'
                                    inputName='PhoneNumber'
                                    wmsSelector={this.state.selector + 'BillToPhoneNumber'}
                                    value={renderOrderObj.BillTo.PhoneNumber}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    label={this.translate('Label_Phone')}
                                    regularExp={/^.{1,255}$/}
                                    disabled={this.state.billToUseCustom == false}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsInput
                                    id='BillToEmailAddress'
                                    inputName='EmailAddress'
                                    wmsSelector={this.state.selector + 'BillToEmailAddress'}
                                    value={renderOrderObj.BillTo.EmailAddress}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    label={this.translate('Label_Email')}
                                    regularExp={/^.{1,255}$/}
                                    disabled={this.state.billToUseCustom == false}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />

                                </div>

                                <div>
                                <WmsInput
                                    id='BillToAddress1'
                                    inputName='Address1'
                                    wmsSelector={this.state.selector + 'BillToAddress1'}
                                    value={renderOrderObj.BillTo.Address1}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    label={this.translate('Label_Address_1')}
                                    disabled={this.state.billToUseCustom == false}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsInput
                                    id='BillToAddress2'
                                    inputName='Address2'
                                    wmsSelector={this.state.selector + 'BillToAddress2'}
                                    value={renderOrderObj.BillTo.Address2}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    label={this.translate('Label_Address_2')}
                                    regularExp={/^.{1,255}$/}
                                    disabled={this.state.billToUseCustom == false}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />


                                 </div>

                                <div>

                                <WmsDropdown
                                    id="BillToCountry"
                                    name="Country"
                                    label={this.translate('Label_Country')}
                                    wmsSelector={this.state.selector + 'BillToCountry'}
                                    value={renderOrderObj.BillTo.Country}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    options={this.state.optionsCountry}
                                    textField='Name'
                                    valueField='CountryCode'
                                    disabled={this.state.billToUseCustom == false}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsDropdown
                                    id="BillToStatesComboBox"
                                    name="State"
                                    label={this.translate('Label_State')}
                                    wmsSelector={this.state.selector + 'BillToState'}
                                    value={renderOrderObj.BillTo.State}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    options={GlobalService.GetRegions(renderOrderObj.BillTo.Country)}
                                    showToolTip={true}
                                    tooltipText={this.translate('Label_Country_Select_Validation')}
                                    textField='Name'
                                    valueField='RegionCode'
                                    disabled={!renderOrderObj.BillTo.Country}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsInput
                                    id='BillToCity'
                                    inputName='City'
                                    wmsSelector={this.state.selector + 'BillToCity'}
                                    value={renderOrderObj.BillTo.City}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    label={this.state.customLabels.Label_City}
                                    regularExp={/^.{1,255}$/}
                                    disabled={this.state.billToUseCustom == false}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsInput
                                    id='BillToZip'
                                    inputName='Zip'
                                    wmsSelector={this.state.selector + 'BillToZip'}
                                    value={renderOrderObj.BillTo.Zip}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    label={this.translate('Label_Zip')}
                                    regularExp={/^[a-zA-Z0-9-_ ]*$/}
                                    disabled={this.state.billToUseCustom == false}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />

                                </div>
                                {/* <div class="wms-form-validation-text" ng-messages="ManageOrders.validateBillToEmail.$error">
                                    <div ng-message="pattern">{this.translate('Label_Invalid_Email')}</div>
                                </div> */}

                            {/* </WmsContainer>
                            <div className="wms-additional-information">
                            <WmsContainer title={this.translate('Label_Additional_Information')}> */}
                                <div>

                                <WmsInput
                                    id='BillToDept'
                                    inputName='Dept'
                                    wmsSelector={this.state.selector + 'BillToDept'}
                                    value={renderOrderObj.BillTo.Dept}
                                    onChangeFunc={(e) => this.handleChangeEvent('BillTo', e)}
                                    label={this.translate('Label_Department_Number')}
                                    regularExp={/^.{1,255}$/}
                                    disabled={this.state.billToUseCustom == false}
                                    parentClassName={(this.state.billToUseCustom == false) ? 'k-state-disabled form_group' : 'form_group'} />
                            </div>
                        </div>
                        </WmsContainer>

                        </div>

                        {/* TAB 3 Sold to information */}
                        <div>
                            <div className="title-block">

                                <WmsDropdown
                                    id="IndexOptionsSoldTo"
                                    name="IndexOptionsSoldTo"
                                    label={''}
                                    wmsSelector={this.state.selector + 'SoldToOptions'}
                                    value={this.state.indexSoldToOption}
                                    onChangeFunc={this.eventOptionsSoldToChange}
                                    options={this.state.optionsSoldTo}
                                    textField='description'
                                    valueField='value' />

                                {this.state.isSoldToFavoritesDropdownRender &&
                                    <WmsDropdownList
                                        id={"SoldToFavoritesDropdown"}
                                        name="SoldTo"
                                        windowId={this.props.windowId}
                                        optionsLabel={this.translate('Label_Lookup_Contacts_By_Code')}
                                        label={this.translate('Label_Sold_To_Information_Favorites')}
                                        wmsSelector={''}
                                        value={this.state.selectSoldToFavorite}
                                        onChangeFunc={this.handleFavoriteContactChange}
                                        textField="Display"
                                        valueField="Value"
                                        suggest={true}
                                        minLength={2}
                                        height={300}
                                        dataSourceObj={{
                                            pageSize: 40,
                                            serverPaging: true,
                                            serverFiltering: true,
                                            type: "aspnetmvc-ajax",
                                            transport: {
                                                read: {
                                                    dataType: "json",
                                                    url: '/WebUI/Shipping/Orders/SoldToContactFavorites',
                                                    type: "PUT",
                                                    data: {
                                                        "orderContactsUri": this.state.manageOrdersEditData.OrderContactsUri
                                                    }
                                                },
                                            },
                                            schema: {
                                                data: "Data",
                                                total: "Total"
                                            }
                                        }}
                                        filteringObj={function(ev){
                                            var filterValue = ev.filter != undefined ? ev.filter.value : "";
                                            ev.preventDefault();
                                            if(filterValue != ""){
                                                this.dataSource.filter({
                                                    logic: "or",
                                                    filters: [
                                                    {
                                                        field: "CompanyName",
                                                        operator: "contains",
                                                        value: filterValue
                                                    },
                                                    {
                                                        field: "Code",
                                                        operator: "contains",
                                                        value: filterValue
                                                    }
                                                    ]
                                                });
                                            }else{
                                                this.dataSource.filter({
                                                        field: "CompanyName",
                                                        operator: "contains",
                                                        value: ""
                                                });
                                            }
                                        }}
                                    />
                                }
                                <WmsButton
                                    label={this.translate('Label_Advanced_Lookup')}
                                    wmsSelector={this.state.selector + 'SoldToAdvancedLookupButton'}
                                    onClickFunc={() => this.props.onOpenAdvanceLookupModal('SoldTo')}
                                    icon='fa-plus-circle'
                                    disabled={this.state.indexSoldToOption != 0} />

                            </div>
                            <WmsContainer title={this.translate('Label_Contact_Information')}>
                            <div className='contact-Info-wrapper'>
                                <div>
                                <WmsInput
                                    id='SoldToCode'
                                    inputName='Code'
                                    wmsSelector={this.state.selector + 'SoldToCode'}
                                    value={renderOrderObj.SoldTo.Code}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    label={this.translate('Label_Contact_Code')}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsCheckBox
                                    id={'SoldToAddContact' + this.props.windowId}
                                    name={'IsQuickLookup' + this.props.windowId}
                                    wmsSelector={this.state.selector + 'SoldToAddContact'}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    label={this.translate('Label_Add_To_Favorites')}
                                    value={renderOrderObj.SoldTo.IsQuickLookup}
                                    checked={typeof renderOrderObj.SoldTo.IsQuickLookup == 'boolean' ? renderOrderObj.SoldTo.IsQuickLookup : renderOrderObj.SoldTo.IsQuickLookup == "true"}
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled ' : ''} />
                                </div>

                                <div>
                                <WmsInput
                                    id='SoldToCompanyName'
                                    inputName='CompanyName'
                                    wmsSelector={this.state.selector + 'SoldToCompanyName'}
                                    value={renderOrderObj.SoldTo.CompanyName}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    label={this.translate('Label_Company_Name')}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsInput
                                    id='SoldToName'
                                    inputName='Name'
                                    wmsSelector={this.state.selector + 'SoldToName'}
                                    value={renderOrderObj.SoldTo.Name}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    label={this.translate('Label_Receipient')}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />
                                <WmsInput
                                    id='SoldToPhoneNumber'
                                    inputName='PhoneNumber'
                                    wmsSelector={this.state.selector + 'SoldToPhoneNumber'}
                                    value={renderOrderObj.SoldTo.PhoneNumber}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    label={this.translate('Label_Phone')}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsInput
                                    id='SoldToEmailAddress'
                                    inputName='EmailAddress'
                                    wmsSelector={this.state.selector + 'SoldToEmailAddress'}
                                    value={renderOrderObj.SoldTo.EmailAddress}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    label={this.translate('Label_Email')}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />

                                </div>
                                <div>
                                <WmsInput
                                    id='SoldToAddress1'
                                    inputName='Address1'
                                    wmsSelector={this.state.selector + 'SoldToAddress1'}
                                    value={renderOrderObj.SoldTo.Address1}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    label={this.translate('Label_Address_1')}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsInput
                                    id='SoldToAddress2'
                                    inputName='Address2'
                                    wmsSelector={this.state.selector + 'SoldToAddress2'}
                                    value={renderOrderObj.SoldTo.Address2}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    label={this.translate('Label_Address_2')}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />

                                </div>
                                <div>

                                <WmsDropdown
                                    id="SoldToCountry"
                                    name="Country"
                                    label={this.translate('Label_Country')}
                                    wmsSelector={this.state.selector + 'SoldToCountry'}
                                    value={renderOrderObj.SoldTo.Country}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    options={this.state.optionsCountry}
                                    textField='Name'
                                    valueField='CountryCode'
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsDropdown
                                    id="SoldToStatesComboBox"
                                    name="State"
                                    label={this.translate('Label_State')}
                                    wmsSelector={this.state.selector + 'SoldToState'}
                                    value={renderOrderObj.SoldTo.State}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    options={GlobalService.GetRegions(renderOrderObj.SoldTo.Country)}
                                    disabled={!renderOrderObj.SoldTo.Country}
                                    showToolTip={true}
                                    tooltipText={this.translate('Label_Country_Select_Validation')}
                                    textField='Name'
                                    valueField='RegionCode'
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsInput
                                    id='SoldToCity'
                                    inputName='City'
                                    wmsSelector={this.state.selector + 'SoldToCity'}
                                    value={renderOrderObj.SoldTo.City}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    label={this.state.customLabels.Label_City}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />

                                <WmsInput
                                    id='SoldToZip'
                                    inputName='Zip'
                                    wmsSelector={this.state.selector + 'SoldToZip'}
                                    value={renderOrderObj.SoldTo.Zip}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    label={this.translate('Label_Zip')}
                                    regularExp={/^[a-zA-Z0-9-_ ]*$/}
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />

                                </div>
                                {/* <div class="wms-form-validation-text" ng-messages="ManageOrders.validateSoldToEmail.$error">
                                    <div ng-message="pattern">{this.translate('Label_Invalid_Email')}</div>
                                </div> */}

                            {/* </WmsContainer>
                            <div className="wms-additional-information">
                            <WmsContainer title={this.translate('Label_Additional_Information')}> */}
                                <div>

                                <WmsInput
                                    id='SoldToDept'
                                    inputName='Dept'
                                    wmsSelector={this.state.selector + 'SoldToDept'}
                                    value={renderOrderObj.SoldTo.Dept}
                                    onChangeFunc={(e) => this.handleChangeEvent('SoldTo', e)}
                                    label={this.translate('Label_Department_Number')}
                                    regularExp={/^.{1,255}$/}
                                    parentClassName={(this.state.indexSoldToOption != 0) ? 'k-state-disabled form_group' : 'form_group'} />
                                </div>
                            </div>
                        </WmsContainer>
                    </div>
                    </div>
                </WmsCollapse>

            </div>
        );
    }
}
