import React, { Component } from "react";
import WmsModel from "../../../components/WmsModel/WmsModel.jsx";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsDropdown from "../../../components/WmsDropdown/WmsDropdown.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";
import WmsButton from "../../../components/WmsButton/WmsButton.jsx";
import './auto-rate-shopping-rule-model.scss';

export class AutoRateShoppingRuleModel extends Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;

        this.rateShoppingLogics = [
            { text: "Select One...", value: "" },
            { text: "Overnight - Cheapest", value: "Overnight" },
            { text: "2nd Day - Cheapest", value: "TwoDay" },
            { text: "3rd Day - Cheapest", value: "ThreeDay" },
            { text: "4th Day - Cheapest", value: "FourDay" },
            { text: "5th Day - Cheapest", value: "FiveDay" },
            { text: "Cheapest", value: "Cheapest" },
            { text: "Cheapest - Independent of Delivery Date", value: "CheapestDateIndependent" }
        ];
    }

    render() {
        return (
            <WmsModel
                id={'autoRateShoppingRuleModel'}
                title={this.translate('Label_Add_Auto_Rate_Shopping_Rule')}
                width={800}
                height={750}
                class='auto-rate-shopping-rule-wrapper'
                onCloseFunc={this.props.closeAddAutoRateShoppingRuleModel}
                isOpen={this.props.isOpenAddAutoRateShoppingRuleModel}
                customClass='wms-model-new-ui-wrapper'>

                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'autoRateShoppingRuleOptions'}
                        headerLabel={this.translate('Label_Options')}
                        showCollapseText={false}
                        showCollapseIcon={false}>
                        <WmsInput
                            id='AutoRateShoppingMapping'
                            inputName='AutoRateShoppingMapping'
                            wmsSelector={this.props.selector + 'AutoRateShoppingMapping'}
                            onChangeFunc={this.props.handleSettingsChange}
                            label={this.translate('Label_Ship_Service_Keyword')}
                            value={this.props.sharedSmallParcelSettings.AutoRateShoppingMapping}
                            extraWrapperClass="ship-service-keyword-wrapper"
                            required={true}
                        />

                        <WmsDropdown
                            id='AutoRateShoppingLogic'
                            name='AutoRateShoppingLogic'
                            wmsSelector={this.props.selector + 'AutoRateShoppingLogic'}
                            onChangeFunc={this.props.handleSettingsChange}
                            label={this.translate("Label_Rate_Type")}
                            options={this.rateShoppingLogics}
                            valueField="value"
                            textField="text"
                            parentClassName="form_group"
                            value={this.props.sharedSmallParcelSettings.AutoRateShoppingLogic}
                            extraWrapperClass="ship-rate-type-wrapper"
                            required={true}
                        />
                    </WmsCollapse>
                </div>

                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Cancel')}
                        wmsSelector={this.props.selector + "cancelLogic"}
                        onClickFunc={this.props.closeAddAutoRateShoppingRuleModel}
                        icon="fa-ban"
                        buttonType="orange"
                    />
                    <WmsButton
                        label={this.translate('Label_Save')}
                        wmsSelector={this.props.selector + "addLogic"}
                        onClickFunc={this.props.eventClickAddLogic}
                        icon="fa-floppy-o"
                        buttonType="green"
                        disabled={!this.props.sharedSmallParcelSettings.AutoRateShoppingMapping || !this.props.sharedSmallParcelSettings.AutoRateShoppingLogic}
                    />


                </div>
            </WmsModel>
        );
    }
}
