import React, { Component } from 'react'
import WmsFormGrid from '../components/WmsFormGrid/WmsFormGrid.jsx';
import WmsButton from '../components/WmsButton/WmsButton.jsx';

import * as GlobalService from './../global_services';
import * as GridConfig from './grid_configuration.jsx';

export class ImportAsnGrid extends Component {
    constructor(props) {
        super(props)

        
        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            columns: GridConfig.getGridDemoColumns().columns,
            schema: GridConfig.getGridDemoSchema().schema,
        }
    }

    exitFromWindow = () => {
        GlobalService.closeWindow(this.props.WindowId);
    }

    importOrder = (param_btnID) => {
        let targetElement = $("#" + param_btnID);
        this.$rootScope.animationMoveIcon(targetElement);

        var data = {
            customer_id: this.props.SharedSelectedCustomerId,
            preferences: this.props.SharedFetchCustomerInfo.Preferences,
            OnErrorRadio: this.props.SharedFetchCustomerInfo.Preferences.OnError,
            uploadFile: this.props.SharedFileUploadData.fileread,
            uploadFileType: this.props.SharedFileUploadData.fileType,
            SelectedFile: this.props.SharedFileUploadData.fileName
        }

        this.$rootScope.taskStarted();
        // this.$rootScope.$broadcast("importParseAsn", data);

        /**Code for react progress component */
        data.type = "importAsn";
        var event = new CustomEvent("showProgressModal", {
            detail: data
        });
        document.dispatchEvent(event);
        setTimeout(() => { this.exitFromWindow(); }, 200);
    }

    render() {
        return (
            <div className="GridSection">
                <div>
                    <WmsButton
                        label={this.translate("Label_Import")}
                        id="firstImportBtn"
                        wmsSelector={this.props.Selector + "ButtonImportFirst"}
                        onClickFunc={() => this.importOrder('firstImportBtn')}
                        icon="fa-download"
                        buttonType="green"
                        disabled={false}
                    />
                    <WmsButton
                        label={this.translate("Label_Select_New_File")}
                        wmsSelector={this.props.Selector + "ButtonSelectNewFile"}
                        onClickFunc={this.props.SelectNewFile}
                        icon="far fa-check-square"
                        buttonType="green"
                        disabled={false}
                    />
                    <WmsButton
                        label={this.translate("Label_Exit")}
                        wmsSelector={this.props.Selector + "ButtonExit"}
                        onClickFunc={this.exitFromWindow}
                        icon="fa-sign-out-alt"
                        buttonType="yellow"
                    />
                </div>

                <div className="FormManageAsnImport">
                    <div className="success-msg">Validation Successful - Review and hit import to continue</div>

                    <div className="grid-with-title">
                        <WmsFormGrid
                            id={'GridManageImportAsn'}
                            wmsSelector={this.props.Selector + 'ManageGrid'}
                            columns={this.state.columns}
                            staticGridData={this.props.GridData}
                            schema={this.state.schema}
                            showResetMenu={true}
                            showResult={true}
                            isColumnMenuenable={true}
                            isColumnsFilterable={true}
                            isColumnsResizable={true}
                            isColumnsReorderable={true}
                            parentClassName={'showHeader'} />
                    </div>
                    <div className="secondImportBtn">
                        <WmsButton
                            label={this.translate("Label_Import")}
                            id="secondImportBtn"
                            wmsSelector={this.props.Selector + "ButtonImportSecond"}
                            onClickFunc={() => this.importOrder('secondImportBtn')}
                            icon="fa-download"
                            buttonType="green"
                            disabled={false}
                        />
                    </div>
                </div>

            </div>
        )
    }
}
