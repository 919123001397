import React, { useState, useEffect } from "react";

import WmsModel from "../react/components/WmsModel/WmsModel";
import WmsCollapse from "../react/components/WmsCollapse/WmsCollapse";
import WmsInput from "../react/components/WmsInput/WmsInput";
import WmsButton from "../react/components/WmsButton/WmsButton";
import WmsFormGrid from "../react/components/WmsFormGrid/WmsFormGrid";
import "./adjustInventory.scss";
import WmsCheckBox from "../react/components/WmsCheckBox/WmsCheckBox";
import * as AdjustInventoryServices from "./adjust-inventory.services";

interface Props {
    onCloseCancelAdjustment?: any;
    sharedIsOpenReverseAdjustmentModal?: boolean;
    wmsSelector?: string;
    selectedRow?: any;
    onApplyReverseAdjustment?: any;
    applyingAdjustment?: boolean;
}

const defaultReverseAdjustmentData = {
    Charges: "",
    Reason: "",
    CreateInvoice: false,
    InvoiceCreationInfo: {},
};

declare var window: any;
const { $ } = window;

const ReverseAdjustmentModal: React.FC<Props> = (props: Props): any => {
    const [gridColumns] = useState([
        {
            field: "SKU",
            title: "SKU",
            width: 119,
        },
        {
            field: "Qty",
            title: "QTY",
            width: 47,
        },
        {
            field: "LotNumber",
            title: "Lot Number",
            width: 147,
        },
        {
            field: "Location",
            title: "Location",
            width: 141,
        },
        {
            field: "MovableUnitLabel",
            title: "Movable Unit",
            width: 182,
        },
    ]);
    const [gridSchema] = useState({
        data: [],
        schema: {
            model: {
                fields: {
                    SKU: { type: "string" },
                    QTY: { type: "string" },
                    LotNumber: { type: "string" },
                    Location: { type: "string" },
                    MovableUnit: { type: "string" },
                },
            },
        },
    });

    const [ReverseAdjustmentData, setReverseAdjustmentData] = useState(defaultReverseAdjustmentData);
    const [AdjustmentItems, setAdjustmentItems] = useState([]);

    const handelChangeEvent = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "CreateInvoice") {
            value = !ReverseAdjustmentData.CreateInvoice;
        }
        setReverseAdjustmentData((previousState) => ({
            ...previousState,
            [name]: value,
        }));
    };

    const onApplyReverseAdjustment = () => {
        kendo.ui.progress($("#ReverseAdjustmentModal"), true);
        ReverseAdjustmentData.InvoiceCreationInfo = {};
        if (ReverseAdjustmentData.CreateInvoice === true) {
            ReverseAdjustmentData.InvoiceCreationInfo = {
                "SetInvoiceDate": true,
                "UtcOffset": (new Date().getTimezoneOffset() / 60) * -1,
            };
        }

        props.onApplyReverseAdjustment(ReverseAdjustmentData);
    };

    useEffect(() => {
        const FilterValues = {
            CustomerID: null,
            FacilityID: null,
            WarehouseTransactionID: props.selectedRow.AdjustmentId,
            ReferenceNum: null,
            StartDate: null,
            EndDate: null,
        };
        AdjustInventoryServices.getAdjustmentData(FilterValues).then((res) => {
            setAdjustmentItems(res.data.Data[0].AdjustmentItems);
        });
    }, []);

    return (
        <WmsModel
            id="ReverseAdjustmentModal"
            title={window.translate("Cancel_Adjustment")}
            width={800}
            height={800}
            onCloseFunc={props.onCloseCancelAdjustment}
            isOpen={props.sharedIsOpenReverseAdjustmentModal}
            customClass="wms-model-new-ui-wrapper"
        >
            <div className="model-content-wrapper">
                <WmsCollapse
                    id={"adjustmentDetails"}
                    headerLabel={window.translate("Adjustment_Details")}
                    showCollapseText={false}
                >
                    <div className="charge-detail-wrapper">
                        <div className="display-inline">
                            <div className="left-content-wrapper">
                                <div className="content">
                                    <label>Customer</label>
                                    <span>{props.selectedRow.CustomerIdentifier.Name}</span>
                                </div>
                                <div className="content">
                                    <label>Warehouse</label>
                                    <span>{props.selectedRow.FacilityIdentifier.Name}</span>
                                </div>
                                <div className="content">
                                    <label>Associated Charges</label>
                                    <span>${props.selectedRow.TotalCharges}</span>
                                </div>
                            </div>
                            <div className="right-content-wrapper">
                                <div className="content">
                                    <label>Transaction ID</label>
                                    <span>{props.selectedRow.AdjustmentId}</span>
                                </div>
                                <div className="content">
                                    <label>Adjustment Date</label>
                                    <span>
                                        {kendo.toString(kendo.parseDate(props.selectedRow.ProcessDate), "MM/dd/yyyy")}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="display-inline">
                            <WmsInput
                                id="Reason"
                                inputName="Reason"
                                wmsSelector={props.wmsSelector + "Reason"}
                                onChangeFunc={handelChangeEvent}
                                label={window.translate("Cancellation_Reason")}
                                value={ReverseAdjustmentData.Reason}
                                extraWrapperClass="cancellation-reason"
                            />
                            <WmsInput
                                id="Charges"
                                inputName="Charges"
                                wmsSelector={props.wmsSelector + "Charges"}
                                onChangeFunc={handelChangeEvent}
                                label={window.translate("Cancellation_Fee")}
                                value={ReverseAdjustmentData.Charges}
                                extraWrapperClass="cancellation-fee"
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                            />
                            <WmsCheckBox
                                id={"CreateInvoice"}
                                name={"CreateInvoice"}
                                onChangeFunc={handelChangeEvent}
                                label={window.translate("Create_Invoice")}
                                checked={ReverseAdjustmentData.CreateInvoice}
                                wmsSelector="CreateInvoice"
                                parentClassName="adjustment-create-invoice"
                            />
                        </div>
                        <div className="display-inline charge-grid-wrapper">
                            <WmsFormGrid
                                id={window.wmsSelector + "chargeGrid"}
                                wmsSelector={"chargeGrid"}
                                gridKeySelection={""}
                                columns={gridColumns}
                                staticGridData={AdjustmentItems}
                                schema={gridSchema}
                                isColumnsResizable={true}
                                isColumnsReorderable={true}
                                isColumnMenuenable={false}
                                isColumnsSortable={true}
                                parentClassName={"showHeader"}
                                showResetMenu={false}
                                onSelection={() => {}}
                                IsMultipleSelectable={true}
                            />
                        </div>
                    </div>
                </WmsCollapse>
            </div>
            <div className="footer-btn-holder">
                <WmsButton
                    label={window.translate("Label_Cancel")}
                    wmsSelector={window.wmsSelector + "DiscardExit"}
                    onClickFunc={props.onCloseCancelAdjustment}
                    buttonType="orange"
                    icon={"fa-ban"}
                />
                <WmsButton
                    label={window.translate("Apply_Adjustment")}
                    wmsSelector={props.wmsSelector + "ApplyAdjustment"}
                    onClickFunc={onApplyReverseAdjustment}
                    icon={"fa-floppy-o"}
                    loading={props.applyingAdjustment}
                />
            </div>
        </WmsModel>
    );
};
ReverseAdjustmentModal.propTypes = {};

ReverseAdjustmentModal.defaultProps = {};

export default React.memo(ReverseAdjustmentModal);
