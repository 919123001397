import * as GlobalService from '../../global_services';
var isFirstTimeOpen = true;
var keyName = "PackagingTypes";
var metaDataPackagingTypeObject = {
    "PackagingTypes": {
        "filterData": {},
        "gridConfig": {
            "packagingTypesGrid": {

            }
        },
        "packageDefModel": {}
    }
};

// call api for get metaData of PackagingTypes is it's open first time
export const getUserClientMetaData = function () {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response != "") {
                    Object.assign(metaDataPackagingTypeObject[keyName], JSON.parse(response));
                }
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                return false;
            }
        );
    }
}

export const setPackagingTypesFilterMetaData = function (filterData) {
    metaDataPackagingTypeObject[keyName]['filterData'] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataPackagingTypeObject[keyName]));
}

export const getPackagingTypesFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataPackagingTypeObject[keyName]['filterData'])) {
        return metaDataPackagingTypeObject[keyName]['filterData'];
    } else {
        return "";
    }
}

export const clearPackagingTypesFilterMetaData = function () {
    metaDataPackagingTypeObject[keyName]['filterData'] = {};
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataPackagingTypeObject[keyName]));
}

export const setPackagingTypesGridOptionInMetaData = function (gridConfig) {
    metaDataPackagingTypeObject[keyName]['gridConfig']['packagingTypesGrid'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataPackagingTypeObject[keyName]));
}

export const getPackagingTypesGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataPackagingTypeObject[keyName]['gridConfig']['packagingTypesGrid'])) {
        return metaDataPackagingTypeObject[keyName]['gridConfig']['packagingTypesGrid'];
    } else {
        return "";
    }
}

export const setPackagingTypesPackageDefModelMetaData = function (packageDefModelData) {
    metaDataPackagingTypeObject[keyName]['packageDefModel'] = packageDefModelData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataPackagingTypeObject[keyName]));
}

export const getPackagingTypesPackageDefModelMetaData = function () {
    if (!$.isEmptyObject(metaDataPackagingTypeObject[keyName]['packageDefModel'])) {
        return metaDataPackagingTypeObject[keyName]['packageDefModel'];
    } else {
        return "";
    }
}