import React from 'react';
var axios = require('axios');
import * as GlobalService from '../global_services';

export const searchInfoGet = function () {
    return axios.get('/WebUI/Users/Users/searchInfo').then(function (response) {
        return response ;
    });
}

export const updateUsersActivation = function (list, mybool) {

    return axios.put('/WebUI/Users/Users/UserActivator', { "ids": list, "active": mybool }).then(function (response) {
        return response;
    });
}

export const getRolesList = function (param_userType) {

    return axios.get('/WebUI/Users/Users/Roles?scope='+param_userType).then(function (response) {
        return response;
    });
}


export const getUserOptions = function (uri) {
    return axios.get('/WebUI/Users/Users/UserOptions?userUri=' + uri).then(function (response) {
        let myreturn = (response != null) ? response.data : {};
        return myreturn;
    });
}


export const createUser = function (param_userdata) {
    delete param_userdata.CustomerNames;
    return axios.post('/WebUI/Users/Users/User', { userView: param_userdata }).then(function success(response) {
        return response;
    });
}
export const updateUser = function (param_userdata) {
    delete param_userdata.CustomerNames;
    delete param_userdata.CustomerId;
    return axios.put('/WebUI/Users/Users/User', { userView: param_userdata }).then(function success(response) {
        return response;
    });
}
export const updateUserRole = function (param_userIds,param_rolename) {
    return axios.put('/WebUI/Users/Users/RoleUpdater', { userIds: param_userIds, role:param_rolename}).then(function success(response) {
        return response;
    });
}