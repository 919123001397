import React from 'react';

//component
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsComboBox from '../../../components/WmsComboBox/WmsComboBox.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';
import WmsCheckBox from '../../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsCustomDateTimePicker from '../../../components/WmsCustomDateTimePicker/WmsCustomDateTimePicker.jsx';
import WmsTextArea from '../../../components/WmsTextArea/WmsTextArea.jsx';
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import * as findOrdersService from '../findOrders.services.jsx';
import * as GlobalService from '../../../global_services';
import { StringWms } from '../../../../../assets/ts/StringWms.ts';
import './carrier-routing-model-proto.scss';
import { kendo } from "../../../global_variable_service.jsx";

export class CarrierRoutingModelProto extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.formInputs = ["Carrier", "Service", "ScacCode", "AccountNumber", "AccountZip", "WarehouseInstructions", "CarrierInstructions", "LoadNumber", "BolNumber", "TrackingNumber", "TrailerNumber", "SealNumber", "DoorNumber", "Carrier", "Service", "InsuranceAmount", "DryIceWeight"];
        this.checkboxInputs = ["IsCod", "IsInsured", "RequiresReturnReceipt", "SaturdayDelivery", "ResidentialDelivery"];
        this.dateInputs = ["PickupDate"];
        this.selectInputs = ["BillingCode", "CapacityType", "DeliveryConfirmationType", "InsuranceType", "IntlContentsType", "IntlNonDelivery"];

        this.Rights = GlobalService.GetRights();
        this.isEnableProvider = this.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && this.Rights['Integrations.SmallParcelPartner'] == 'ParcelApi';

        this.state = {
            selector: "OrderEditCarrierRouting",
            editOrderMultipleValues: this.translate('Label_Various_Values'),
            customLabels: GlobalService.getCustomLabels(),
            Rights: this.Rights,
            isDatepickerSetVariousLabel: false,
            showScac: false,
            optionsCarrier: [],
            optionsCarrierService: [],
            oldServiceName: '',
            defaultCarriersAndRoutingOptions: [],
            optionsCapacityTypes: [],
            optionsBillingType: [],
            optionsDeliveryConfirmationType: [
                { description: 'None', value: 'none' },
                { description: 'Delivery', value: 'delivery' },
                { description: 'Signature', value: 'signature' },
                { description: 'Adult Signature', value: 'adult_signature' },
                { description: 'Direct Signature', value: 'direct_signature' }
            ],
            optionsInsuranceType: [
                { description: 'None', value: 0 },
                { description: 'Carrier', value: 106 },
                { description: 'Third-party', value: 107 }
            ],
            optionsCODType: [
                { description: 'None', value: 0 },
                { description: 'Any', value: 100 },
                { description: 'Cash', value: 103 },
                { description: 'Cash Equivalent', value: 'Cash Equivalent' }
            ],
            optionsInitalContentType: [
                { description: 'None', value: "" },
                { description: 'Documents', value: 'documents' },
                { description: 'Gift', value: 'gift' },
                { description: 'Merchandise', value: 'merchandise' },
                { description: 'Returned Goods', value: 'returned_goods' },
                { description: 'Sample', value: 'sample' }
            ],
            optionsInitalNoneDelivery: [
                { description: 'None', value: "" },
                { description: 'Treat as Abandoned', value: 'treat_as_abandoned' },
                { description: 'Return to Sender', value: 'return_to_sender' }
            ],
            carrierRoutingFormData: null,
            custFacUPSFedExInfo: {},
            origFormData: null,
            gifSaveChangesAndExit: false,
            checkbox: {},
            concatInputs: this.formInputs.concat(this.checkboxInputs, this.dateInputs, this.selectInputs)

        };
    }
    componentDidMount() {

        kendo.ui.progress($('#WindowBulkEditCarrierProto'), true);

        findOrdersService.initialiseBulkEditModelProto(this.props.sharedSelectedOrderIds, this.props.customerId, this.props.facilityId).then((response) => {

            let tempObject = [];
            let tempOptionsCarrier = response.carriersAndCapacityTypesResponse.Carriers.Carriers;

            tempObject = tempOptionsCarrier.filter(function (el) {
                return el.Carrier === response.editRoutingInfoDataResponse.Carrier.Value;
            })[0];

            let shipmentServices = [];
            let optionsShipmentServices = [];
            if (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('ShipmentServices')) {
                optionsShipmentServices = tempObject['ShipmentServices'];
            } else {
                optionsShipmentServices = response.carriersAndCapacityTypesResponse.Carriers.DefaultShipmentServices;
            }

            shipmentServices = GlobalService.hideDeactivatedShipmentServices(response.editRoutingInfoDataResponse.Carrier.Value, optionsShipmentServices,response.editRoutingInfoDataResponse.Service.Value);

            let tmpShipEngineCarrierResponse = response.carriersAndCapacityTypesResponse.Carriers.Carriers;
            tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
                return obj.Carrier != "Buku" && obj.Carrier != "PitneyBowesExp";
            });
            if (!this.isEnableProvider) {
                tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
                    return obj.Carrier != "PitneyBowesStd";
                });
            }

            this.setState({
                defaultCarriersAndRoutingOptions: response.carriersAndCapacityTypesResponse.Carriers,
                optionsCarrier: tmpShipEngineCarrierResponse,
                optionsBillingType: (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('BillingCodes')) ? tempObject['BillingCodes'] : response.carriersAndCapacityTypesResponse.Carriers.DefaultBillingCodes,
                optionsCarrierService: shipmentServices.shipmentServices,
                oldServiceName: shipmentServices.valueCode ? shipmentServices.valueCode.Description : response.editRoutingInfoDataResponse.Service.Value,
                optionsCapacityTypes: response.carriersAndCapacityTypesResponse.CapacityTypes,
                custFacUPSFedExInfo: response.carriersAndCapacityTypesResponse.CustFacCarrierInfo
            }, () => {
                this.translateFormData(response.editRoutingInfoDataResponse)
            });

        }).finally(() => {
            kendo.ui.progress($('#WindowBulkEditCarrierProto'), false);
            setTimeout(() => {
                if (this.state.carrierRoutingFormData == null) {
                    this.props.onEditCarrierRoutingClose();
                }
            }, 300);
        });
    }

    translateFormData = (param_formdata) => {

        let showScac = false;
        let concatInputs = this.state.concatInputs;
        let checkbox = {};
        let optionsBillingType = this.state.optionsBillingType;
        let optionsCapacityTypes = this.state.optionsCapacityTypes;
        let optionsInsuranceType = this.state.optionsInsuranceType;
        let optionsCODType = this.state.optionsCODType;
        let optionsInitalContentType = this.state.optionsInitalContentType;
        let optionsInitalNoneDelivery = this.state.optionsInitalNoneDelivery;
        let isDatepickerSetVariousLabel = false;
        let optionsDeliveryConfirmationType = this.state.optionsDeliveryConfirmationType;

        // SCAC state is dependent on carrier state so if it is Multiples
        if (param_formdata.Carrier.State === 1) {
            showScac = true;
        }
        for (var x = 0; x < concatInputs.length; x++) {
            if (this.formInputs.indexOf(concatInputs[x]) !== -1) { // these are form inputs

                if (param_formdata[concatInputs[x]].State === 1 && (
                    concatInputs[x] != 'WarehouseInstructions' &&
                    concatInputs[x] != 'CarrierInstructions'
                )) {
                    param_formdata[concatInputs[x]].Value = this.state.editOrderMultipleValues;
                } else if (param_formdata[concatInputs[x]].State === 0) {
                    param_formdata[concatInputs[x]].Value = "";
                } else if (this.props.sharedSelectedOrderIds.length > 1 && (
                    concatInputs[x] == 'WarehouseInstructions' ||
                    concatInputs[x] == 'CarrierInstructions')
                ) {
                    param_formdata[concatInputs[x]].Value = "";
                }
            }

            if (this.checkboxInputs.indexOf(concatInputs[x]) !== -1) { // these are checkbox inputs
                if (param_formdata[concatInputs[x]].State === 1) {
                    $("#" + concatInputs[x]).prop("indeterminate", true);
                    checkbox[concatInputs[x]] = true;

                } else {
                    $("#" + concatInputs[x]).prop("indeterminate", false);
                    checkbox[concatInputs[x]] = false;
                }
            }

            if (this.selectInputs.indexOf(concatInputs[x]) !== -1) { // these are checkbox inputs
                if (param_formdata[concatInputs[x]].State === 1) {

                    if (concatInputs[x] == 'BillingCode') {

                        optionsBillingType.push({
                            "CodeTypeId": -1,
                            "Code": this.state.editOrderMultipleValues
                        });
                        param_formdata.BillingCode.Value = this.state.editOrderMultipleValues;

                    } else if (concatInputs[x] == 'CapacityType') {

                        optionsCapacityTypes.push({
                            "CapacityTypeId": -1,
                            "Description": this.state.editOrderMultipleValues
                        });
                        param_formdata.CapacityType.Value = this.state.editOrderMultipleValues;

                    } else if (concatInputs[x] == "InsuranceType") {

                        optionsInsuranceType.push({
                            description: this.state.editOrderMultipleValues,
                            value: -1
                        });
                        param_formdata.InsuranceType.Value = -1;

                    } else if (concatInputs[x] == "CODPayType") {

                        optionsCODType.push({
                            description: this.state.editOrderMultipleValues,
                            value: -1
                        });
                        param_formdata.CODPayType.Value = -1;

                    } else if (concatInputs[x] == "IntlContentsType") {

                        optionsInitalContentType.push({
                            description: this.state.editOrderMultipleValues,
                            value: -1
                        });
                        param_formdata.IntlContentsType.Value = -1;

                    } else if (concatInputs[x] == "IntlNonDelivery") {

                        optionsInitalNoneDelivery.push({
                            description: this.state.editOrderMultipleValues,
                            value: -1
                        });
                        param_formdata.IntlNonDelivery.Value = -1;

                    } else if (concatInputs[x] == "DeliveryConfirmationType") {

                        optionsDeliveryConfirmationType.push({
                            description: this.state.editOrderMultipleValues,
                            value: -1
                        });
                        param_formdata.DeliveryConfirmationType.Value = -1;
                    }

                }
            }

            if (this.dateInputs.indexOf(concatInputs[x]) !== -1) { // these are date inputs
                if (param_formdata[concatInputs[x]].State === 1) {
                    isDatepickerSetVariousLabel = true;
                }
            }
        }

        if (param_formdata.PickupDate.Value) {
            param_formdata.PickupDate.Value = StringWms.convertFilterStringToISODate(param_formdata.PickupDate.Value, '', false, true).isoStringUTC
        }

        var tmpOrigFormData = _.cloneDeep(param_formdata);

        if (param_formdata.Carrier.Value == 'FedEx') {
            if(param_formdata.BillingCode.Value == 'BillThirdParty') {
                param_formdata.BillingCode.Value = 'third party';
            }
        } else {
            if(param_formdata.BillingCode.Value == 'third party') {
                param_formdata.BillingCode.Value = 'BillThirdParty';
            }
        }

        this.setState({
            showScac: showScac,
            checkbox: checkbox,
            carrierRoutingFormData: param_formdata,
            optionsCapacityTypes: optionsCapacityTypes,
            optionsBillingType: optionsBillingType,
            optionsDeliveryConfirmationType: optionsDeliveryConfirmationType,
            optionsInsuranceType: optionsInsuranceType,
            optionsCODType: optionsCODType,
            optionsInitalContentType: optionsInitalContentType,
            optionsInitalNoneDelivery: optionsInitalNoneDelivery,
            isDatepickerSetVariousLabel: isDatepickerSetVariousLabel,
            origFormData: tmpOrigFormData
        });

    }

    handleCarrierChange = (e) => {

        let showScac = false;
        let carrierRoutingFormData = this.state.carrierRoutingFormData;

        if (e.target.value == null || e.target.value == "" || e.target.value == " " || e.target.value == this.state.editOrderMultipleValues) {
            showScac = true;
        }

        carrierRoutingFormData.Service.Value = '';
        carrierRoutingFormData.BillingCode.Value = '';
        carrierRoutingFormData.AccountNumber.Value = '';
        carrierRoutingFormData.AccountZip.Value = '';
        carrierRoutingFormData.Carrier.Value = e.target.value;

        let tempObject = this.state.optionsCarrier.filter(function (el) {
            return el.Carrier === e.target.value;
        })[0];

        carrierRoutingFormData.ScacCode.Value = (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('ScacCode')) ? tempObject['ScacCode'] : '';
        this.setAccountNumberAndZip(e.target.value, null, carrierRoutingFormData);

        let shipmentServices = [];
        let optionsShipmentServices = [];
        if (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('ShipmentServices')) {
            optionsShipmentServices = tempObject['ShipmentServices'];
        } else {
            optionsShipmentServices = this.state.defaultCarriersAndRoutingOptions.DefaultShipmentServices;
        }
        shipmentServices = GlobalService.hideDeactivatedShipmentServices(e.target.value, optionsShipmentServices);

        this.setState({
            optionsCarrierService: shipmentServices.shipmentServices,
            oldServiceName: shipmentServices.valueCode?.Description,
            optionsBillingType: (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('BillingCodes')) ? tempObject['BillingCodes'] : this.state.defaultCarriersAndRoutingOptions.DefaultBillingCodes,
            showScac: showScac,
            carrierRoutingFormData: carrierRoutingFormData
        });
    }


    setAccountNumberAndZip = (param_current_carrier, param_billing_code, param_carrierFormData) => {
        //var selectedCarrierDetails = this.props.sharedOptions.Carriers.Carriers[param_current_carrier_index];
        if (param_current_carrier != null) {
            var accountSelectedText = '';

            if (param_current_carrier == 'UPS' || param_current_carrier == 'FedEx') {
                var Account = '';
                var ShipPointZip = '';


                if (param_current_carrier == 'UPS') {
                    if (param_billing_code == 'Prepaid') {
                        if (this.state.custFacUPSFedExInfo.CustCarrierInfo == 2 || this.state.custFacUPSFedExInfo.CustCarrierInfo == 1) {
                            Account = this.state.custFacUPSFedExInfo.CustUPSAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.CustUPSAccountZip;
                            accountSelectedText = 'UPS Account of ' + this.state.custFacUPSFedExInfo.CustName;
                        } else if(this.state.custFacUPSFedExInfo.CustCarrierInfo == 3 || this.state.custFacUPSFedExInfo.CustCarrierInfo == 0){
                            Account = this.state.custFacUPSFedExInfo.FacUPSAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.FacZip;
                            accountSelectedText = 'UPS Account of ' + this.state.custFacUPSFedExInfo.FacName;
                        }

                    } else if (param_billing_code == 'BillThirdParty') {
                        if (this.state.custFacUPSFedExInfo.CustCarrierInfo == 3 || this.state.custFacUPSFedExInfo.CustCarrierInfo == 1) {
                            Account = this.state.custFacUPSFedExInfo.CustUPSAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.CustUPSAccountZip;
                            accountSelectedText = 'UPS Account of ' + this.state.custFacUPSFedExInfo.CustName;
                        }
                    }
                    else {
                        Account = '';
                        ShipPointZip = '';
                        accountSelectedText = '';
                    }

                } else if (param_current_carrier == 'FedEx') {
                    if (param_billing_code == 'sender') {
                        if (this.state.custFacUPSFedExInfo.CustCarrierInfo == 2 || this.state.custFacUPSFedExInfo.CustCarrierInfo == 1) {
                            Account = this.state.custFacUPSFedExInfo.CustFedexAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.CustUPSAccountZip;
                            accountSelectedText = 'Fedex Account of ' + this.state.custFacUPSFedExInfo.CustName;
                        } else if (this.state.custFacUPSFedExInfo.CustCarrierInfo == 0 || this.state.custFacUPSFedExInfo.CustCarrierInfo == 3) {
                            Account = this.state.custFacUPSFedExInfo.FacFedexAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.FacZip;
                            accountSelectedText = 'Fedex Account of ' + this.state.custFacUPSFedExInfo.FacName;
                        }

                    } else if (param_billing_code == 'third party') {
                        if (this.state.custFacUPSFedExInfo.CustCarrierInfo == 3 || this.state.custFacUPSFedExInfo.CustCarrierInfo == 1) {
                            Account = this.state.custFacUPSFedExInfo.CustFedexAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.CustUPSAccountZip;
                            accountSelectedText = 'Fedex Account of ' + this.state.custFacUPSFedExInfo.CustName;
                        }
                        // else {
                        //     Account = this.state.custFacUPSFedExInfo.FacFedexAccount;
                        //     ShipPointZip = this.state.custFacUPSFedExInfo.FacZip;
                        //     accountSelectedText = 'Fedex Account of ' + this.state.custFacUPSFedExInfo.FacName;
                        // }

                    } else {
                        Account = '';
                        ShipPointZip = '';
                        accountSelectedText = '';
                    }
                }

                param_carrierFormData["AccountNumber"].Value = Account;
                param_carrierFormData["AccountZip"].Value = ShipPointZip;

                this.setState({
                    carrierRoutingFormData: param_carrierFormData,
                    accountSelectedText: accountSelectedText,
                });

            } else {
                param_carrierFormData["Carrier"].Value = param_current_carrier;

                this.setState({
                    carrierRoutingFormData: param_carrierFormData,
                });
            }
        }
    }

    handleChangeEvent = (event) => {
        let routingFormObj = this.state.carrierRoutingFormData;
        let checkbox = this.state.checkbox;

        if (event.target.type == 'checkbox') {
            checkbox[event.target.name] = false;
            routingFormObj[event.target.name].Value = !routingFormObj[event.target.name].Value;

        } else if (event.target.type == 'select-one' && event.target.id == "BillingCode") {
            this.setAccountNumberAndZip(this.state.carrierRoutingFormData.Carrier.Value, event.target.value, routingFormObj);
            routingFormObj[event.target.name].Value = event.target.value;

        } else {
            routingFormObj[event.target.name].Value = event.target.value;
        }

        var oldServiceName = this.state.oldServiceName;
        if (event.target.name == "Service") {
            oldServiceName = event.target.value;
            if (this.state.carrierRoutingFormData.Carrier.Value) {
                var carrier = this.state.carrierRoutingFormData.Carrier.Value;
                let shipmentServices = [];
                let optionsShipmentServices = [];
                let tempObject = this.state.optionsCarrier.filter(function (el) {
                    return el.Carrier === carrier;
                })[0];
                if (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('ShipmentServices')) {
                    optionsShipmentServices = tempObject['ShipmentServices'];
                } else {
                    optionsShipmentServices = this.state.defaultCarriersAndRoutingOptions.DefaultShipmentServices;
                }
                shipmentServices = GlobalService.hideDeactivatedShipmentServices(carrier, optionsShipmentServices, event.target.value);
                oldServiceName = shipmentServices.valueCode?.Description;
            }
        }

        if (
            event.target.name == 'InsuranceType' ||
            event.target.name == 'CODPayType'
        ) {
            routingFormObj[event.target.name].Value = parseInt(event.target.value);
        }

        if (event.target.name == 'IsInsured' && event.target.checked == false) {
            routingFormObj.InsuranceType.Value = 0;
            routingFormObj.InsuranceType.HasChanged = true;
            routingFormObj.InsuranceAmount.Value = 0;
            routingFormObj.InsuranceAmount.HasChanged = true;
        }

        this.setState({
            checkbox: checkbox,
            oldServiceName: oldServiceName,
            carrierRoutingFormData: routingFormObj
        });


    }

    eventClickSaveChangesAndExit = () => {
        this.setState({ gifSaveChangesAndExit: true });
        let routingFormObj = this.detectChangesFromOrig();
        findOrdersService.bulkUpdateCarrierRoutingProto({
            'massRoutingData': routingFormObj,
            'orderIds': this.props.sharedSelectedOrderIds
        }).then((response) => {


            if (response) {
                if (response.status == 200 || response.status == 204) {
                    GlobalService.notification('success', this.translate('Label_Bulk_Edit_Success'));


                    //EventNewOrderData for reload find order grid
                    this.orderGridRead({ "action": "update" });
                    setTimeout(() => {
                        this.props.onEditCarrierRoutingClose();
                    }, 100);
                }
            }
        }).finally(() => {
            this.setState({ gifSaveChangesAndExit: false });
            kendo.ui.progress($('#WindowBulkEditCarrierProto'), false);
        });

    }

    orderGridRead = (param_event_data) => {
        param_event_data.source = 'react';
        var eventItem = new CustomEvent("EventNewOrderData", {
            detail: param_event_data
        });
        document.dispatchEvent(eventItem);
    }

    detectChangesFromOrig = () => {
        let concatInputs = this.state.concatInputs;
        let routingObj = this.state.carrierRoutingFormData;

        for (var x = 0; x < concatInputs.length; x++) {
            if (concatInputs[x] == "PickupDate") {
                if (new Date(routingObj[concatInputs[x]].Value).getTime() !== new Date(this.state.origFormData[concatInputs[x]].Value).getTime()) {
                    routingObj[concatInputs[x]].HasChanged = true;
                    if (routingObj[concatInputs[x]].Value == null) {
                        routingObj[concatInputs[x]].Value = "";
                    } else {
                        routingObj[concatInputs[x]].Value = StringWms.convertFilterStringToISODate(routingObj[concatInputs[x]].Value, '', false, true).isoStringUTC
                    }
                }
            } else {
                if (routingObj[concatInputs[x]].Value !== this.state.origFormData[concatInputs[x]].Value) {
                    routingObj[concatInputs[x]].HasChanged = true;
                    if (routingObj[concatInputs[x]].Value == null) {
                        routingObj[concatInputs[x]].Value = "";
                    }
                    if (this.props.sharedSelectedOrderIds.length > 1 && (routingObj[concatInputs[x]].State == 1 || routingObj[concatInputs[x]].State == 2) &&
                        (
                            concatInputs[x] == 'WarehouseInstructions' ||
                            concatInputs[x] == 'CarrierInstructions'
                        ) && (routingObj[concatInputs[x]].Value != "")
                    ) {
                        var formVal = routingObj[concatInputs[x]].Value;
                        routingObj[concatInputs[x]].Value = kendo.toString(kendo.parseDate(new Date()), 'yyyy/MM/dd HH:mm - ') + formVal;
                    }
                }
            }
        }

        return routingObj;
    }

    render() {
        return (
            <WmsModel
                id='WindowBulkEditCarrierProto'
                title={this.translate('Label_Bulk_Edit_Carrier')}
                width={800}
                height={800}
                onCloseFunc={this.props.onEditCarrierRoutingClose}
                isOpen={this.props.SharedIsOpenCarrierRoutingModelProto}
                customClass='wms-model-new-ui-wrapper'>

                <div className='model-content-wrapper'>
                    <WmsCollapse
                        id={'carrierRountingInfoCarrier'}
                        headerLabel={this.translate('Label_Carrier')}
                        showCollapseText={false} >

                        <div className="display-inline">
                            <WmsComboBox
                                id="ComboCarrier"
                                name="CarrierComboBox"
                                label={this.translate('Label_Carrier')}
                                wmsSelector={this.state.selector + 'ComboCarrier'}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.Carrier.Value}
                                onChangeFunc={this.handleCarrierChange}
                                options={this.state.optionsCarrier}
                                valueField="Carrier"
                                textField="DisplayName"
                                parentClassName="routing-info-carrier"
                            />

                            <WmsComboBox
                                id="carrierRountingInfoService"
                                name="Service"
                                placeholder={'Select or Enter a Service'}
                                label={this.translate('Label_Service')}
                                wmsSelector={this.state.selector + 'ComboService'}
                                value={
                                    this.state.carrierRoutingFormData == null ? '' :
                                    (this.state.carrierRoutingFormData.Service.Value != '' ? ( this.state.oldServiceName != '' ? this.state.oldServiceName : '') : '' )}
                                onChangeFunc={this.handleChangeEvent}
                                noDataTemplate={false}
                                options={this.state.optionsCarrierService}
                                textField='Description'
                                valueField='Code'
                                parentClassName="routing-info-service" />

                            <WmsInput
                                id="carrierRountingInfoScac"
                                inputName='ScacCode'
                                wmsSelector={this.state.selector + 'ScacCode'}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_SCAC')}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.ScacCode.Value}
                                disabled={this.state.showScac}
                                extraWrapperClass="ship-info-SCAC" />
                        </div>

                        <div className="display-inline">

                            <WmsDropdown
                                id="BillingCode"
                                name='BillingCode'
                                label={this.translate('Label_Billing')}
                                wmsSelector={this.state.selector + 'ComboBillingCode'}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.BillingCode.Value}
                                onChangeFunc={this.handleChangeEvent}
                                options={this.state.optionsBillingType}
                                valueField="Code"
                                textField="Code"
                                blankFirstOption={true}
                                blankFirstOptionText={'Unspecified'}
                                extraWrapperClass="routing-info-billingType" />

                            <WmsInput
                                id="AccountNumber"
                                inputName='AccountNumber'
                                wmsSelector={this.state.selector + 'AccountNumber'}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Account_Number')}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.AccountNumber.Value}
                                extraWrapperClass="routing-info-account-number" />

                            <WmsInput
                                id="AccountZip"
                                inputName='AccountZip'
                                wmsSelector={this.state.selector + 'AccountZip'}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Account_Number_And_Postal_Code')}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.AccountZip.Value}
                                extraWrapperClass="routing-info-postal-code" />

                        </div>
                        <div className="display-inline">
                            <WmsInput
                                id="TrackingNumber"
                                inputName='TrackingNumber'
                                wmsSelector={this.state.selector + 'TrackingNumber'}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Tracking_Number')}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.TrackingNumber.Value}
                                extraWrapperClass="routing-info-tracking-number" />
                        </div>
                        <div className="display-inline instruction-section">
                            <WmsTextArea
                                id="WarehouseInstructions"
                                name="WarehouseInstructions"
                                label={this.translate('Label_Shipping_Warehouse_Instructions')}
                                wmsSelector={this.state.selector + 'WarehouseInstructions'}
                                onChangeFunc={this.handleChangeEvent}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.WarehouseInstructions.Value} />

                            <WmsTextArea
                                id="CarrierInstructions"
                                name="CarrierInstructions"
                                label={this.translate('Label_Carrier_Instructions')}
                                wmsSelector={this.state.selector + 'CarrierInstructions'}
                                onChangeFunc={this.handleChangeEvent}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.CarrierInstructions.Value} />
                        </div>

                    </WmsCollapse>
                    <WmsCollapse
                        id={'carrierInfoRouting'}
                        headerLabel={this.translate('Label_Routing')}
                        showCollapseText={false}
                    >

                        <div className="display-inline">

                            <WmsInput
                                id='LoadNumber'
                                inputName='LoadNumber'
                                wmsSelector={this.state.selector + 'LoadNumber'}
                                value={(this.state.carrierRoutingFormData != null) ? this.state.carrierRoutingFormData.LoadNumber.Value : null}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Load_Number')}
                                regularExp={/^.{1,255}$/}
                                extraWrapperClass='routing-info-load-number'
                            />

                            <WmsInput
                                id='BolNumber'
                                inputName='BolNumber'
                                wmsSelector={this.state.selector + 'BolNumber'}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.BolNumber.Value}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.state.customLabels.Label_Bill_Of_Lading}
                                regularExp={/^.{1,255}$/}
                                extraWrapperClass='routing-info-bol-number'
                            />

                            <WmsInput
                                id='TrailerNumber'
                                inputName='TrailerNumber'
                                wmsSelector={this.state.selector + 'TrailerNumber'}
                                value={(this.state.carrierRoutingFormData != null) ? this.state.carrierRoutingFormData.TrailerNumber.Value : null}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Trailer_Number')}
                                regularExp={/^.{1,255}$/}
                                extraWrapperClass='routing-info-trailer-number'
                            />

                            <WmsInput
                                id='SealNumber'
                                inputName='SealNumber'
                                wmsSelector={this.state.selector + 'SealNumber'}
                                value={(this.state.carrierRoutingFormData != null) ? this.state.carrierRoutingFormData.SealNumber.Value : null}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Seal_Number')}
                                regularExp={/^.{1,255}$/}
                                extraWrapperClass='routing-info-seal-number'
                            />
                        </div>

                        <div className="display-inline">

                            <WmsInput
                                id='DoorNumber'
                                inputName='DoorNumber'
                                wmsSelector={this.state.selector + 'DoorNumber'}
                                value={(this.state.carrierRoutingFormData != null) ? this.state.carrierRoutingFormData.DoorNumber.Value : null}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.state.customLabels.Label_Door}
                                regularExp={/^.{1,255}$/}
                                extraWrapperClass='routing-info-door'
                            />

                            <WmsDropdown
                                id="IndexOptionsCapacityType"
                                name="CapacityType"
                                label={this.translate('Label_Capacity_Type')}
                                wmsSelector={this.state.selector + 'CapacityType'}
                                value={(this.state.carrierRoutingFormData != null && this.state.carrierRoutingFormData.CapacityType != null) ? this.state.carrierRoutingFormData.CapacityType.Value : null}
                                blankFirstOption={true}
                                onChangeFunc={this.handleChangeEvent}
                                options={this.state.optionsCapacityTypes}
                                textField='Description'
                                valueField='Description'
                                extraWrapperClass='routing-info-capacity-type'
                            />

                            <WmsCustomDateTimePicker
                                id={'DatePickerRoutingInfo'}
                                name='PickupDate'
                                wmsSelector={this.state.selector + "PickupDate"}
                                label={this.translate('Label_Edit_Carrier_Pickup_Date') + (this.state.isDatepickerSetVariousLabel ? ' (' + this.state.editOrderMultipleValues + ')' : '')}
                                onChangeFunc={this.handleChangeEvent}
                                value={(this.state.carrierRoutingFormData != null && this.state.carrierRoutingFormData.PickupDate.Value) ? this.state.carrierRoutingFormData.PickupDate.Value : null}
                                isDateParseInCustomFormat={true}
                            />
                        </div>

                    </WmsCollapse>

                    <WmsCollapse
                        id={'carrierRountingInfoShipment'}
                        headerLabel={this.translate('Label_Shipment_Options')}
                        showCollapseText={false}
                        showCollapseIcon={this.state.Rights.hasOwnProperty('orders.manage.smartparcel')}
                        isExpanded={this.state.Rights.hasOwnProperty('orders.manage.smartparcel')}
                        isShowTooltip={!this.state.Rights.hasOwnProperty('orders.manage.smartparcel')}
                        tooltipText={this.translate('Note_Disable_Small_Parcel')} >

                        <div className="display-inline shipping-three-checkbox">

                            <WmsCheckBox
                                id={'RequiresReturnReceipt'}
                                name={'RequiresReturnReceipt'}
                                wmsSelector={this.state.selector + 'ReturnReceipt'}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Require_Return_Receipt') + ' ' + (this.state.checkbox.RequiresReturnReceipt ? '(' + this.state.editOrderMultipleValues + ')' : '')}
                                checked={this.state.carrierRoutingFormData == null ? false : this.state.carrierRoutingFormData.RequiresReturnReceipt.Value == true}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.RequiresReturnReceipt.Value}
                                parentClassName="chk-return-receipt" />

                            <WmsCheckBox
                                id={'SaturdayDelivery'}
                                name={'SaturdayDelivery'}
                                wmsSelector={this.state.selector + 'SaturdayDelivery'}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Saturday_Delivery') + ' ' + (this.state.checkbox.SaturdayDelivery ? '(' + this.state.editOrderMultipleValues + ')' : '')}
                                checked={this.state.carrierRoutingFormData == null ? false : this.state.carrierRoutingFormData.SaturdayDelivery.Value == true}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.SaturdayDelivery.Value}
                                parentClassName="chk-saturday-delivery" />

                            <WmsCheckBox
                                id={'ResidentialDelivery'}
                                name={'ResidentialDelivery'}
                                wmsSelector={this.state.selector + 'ResidentialDelivery'}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Reidential_Delivery') + ' ' + (this.state.checkbox.ResidentialDelivery ? '(' + this.state.editOrderMultipleValues + ')' : '')}
                                checked={this.state.carrierRoutingFormData == null ? false : this.state.carrierRoutingFormData.ResidentialDelivery.Value == true}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.ResidentialDelivery.Value}
                                parentClassName="chk-residential-delivery" />

                        </div>
                        <div className="display-inline insurance-section">

                            <WmsCheckBox
                                id={'IsInsured'}
                                name={'IsInsured'}
                                wmsSelector={this.state.selector + 'Insurance'}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Insurance') + ' ' + (this.state.checkbox.IsInsured ? '(' + this.state.editOrderMultipleValues + ')' : '')}
                                checked={this.state.carrierRoutingFormData == null ? false : this.state.carrierRoutingFormData.IsInsured.Value == true}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.IsInsured.Value}
                                parentClassName="chk-insurance" />

                            <WmsDropdown
                                id="InsuranceType"
                                name='InsuranceType'
                                label={this.translate('Label_Insurance_Type')}
                                wmsSelector={this.state.selector + 'InsuranceType'}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.InsuranceType.Value}
                                onChangeFunc={this.handleChangeEvent}
                                options={this.state.optionsInsuranceType}
                                textField='description'
                                valueField='value'
                                disabled={this.state.carrierRoutingFormData != null && this.state.carrierRoutingFormData.IsInsured.Value == false}
                                extraWrapperClass={"ship-info-insurance-type" + (this.state.carrierRoutingFormData != null && this.state.carrierRoutingFormData.IsInsured.Value == false ? ' disable-options' : '')} />

                            <WmsInput
                                id="InsuranceAmount"
                                inputName='InsuranceAmount'
                                wmsSelector={this.state.selector + 'InsuranceAmount'}
                                onChangeFunc={this.handleChangeEvent}
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                extraSignBeforeValue={'$'}
                                label={this.translate('Label_Insurance_Amount')}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.InsuranceAmount.Value}
                                disabled={this.state.carrierRoutingFormData != null && this.state.carrierRoutingFormData.IsInsured.Value == false}
                                extraWrapperClass={"ship-info-insurance-amount" + (this.state.carrierRoutingFormData != null && this.state.carrierRoutingFormData.IsInsured.Value == false ? ' disable-options' : '')} />

                        </div>

                        <div className="display-inline">

                            <WmsInput
                                id="carrierRountingInfoDryIceWeight"
                                inputName='DryIceWeight'
                                wmsSelector={this.state.selector + 'DryIceWeight'}
                                onChangeFunc={this.handleChangeEvent}
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                label={this.translate('Label_Dry_Ice_Weight')}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.DryIceWeight.Value}
                                extraWrapperClass="ship-info-dry-ice-weight" />

                            <WmsDropdown
                                id="carrierRountingInfoInitContentType"
                                name='IntlContentsType'
                                label={this.translate('Label_Initial_Contents_Type')}
                                wmsSelector={this.state.selector + 'IntlContentsType'}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.IntlContentsType.Value}
                                onChangeFunc={this.handleChangeEvent}
                                options={this.state.optionsInitalContentType}
                                textField='description'
                                valueField='value'
                                extraWrapperClass="ship-info-init-content-type" />

                            <WmsDropdown
                                id="carrierRountingInfoInitmNonDelivery"
                                name='IntlNonDelivery'
                                label={this.translate('Label_Initial_None_Delivery')}
                                wmsSelector={this.state.selector + 'InitmNonDelivery'}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.IntlNonDelivery.Value}
                                onChangeFunc={this.handleChangeEvent}
                                options={this.state.optionsInitalNoneDelivery}
                                textField='description'
                                valueField='value'
                                extraWrapperClass="ship-info-init-non-delivery" />

                            <WmsDropdown
                                id="carrierRountingInfoDeliveryConfirmationType"
                                name='DeliveryConfirmationType'
                                label={this.translate('Label_Delivery_Confirmation_Type')}
                                wmsSelector={this.state.selector + 'DeliveryConfirmationType'}
                                value={this.state.carrierRoutingFormData == null ? '' : this.state.carrierRoutingFormData.DeliveryConfirmationType.Value}
                                onChangeFunc={this.handleChangeEvent}
                                options={this.state.optionsDeliveryConfirmationType}
                                textField='description'
                                valueField='value'
                                extraWrapperClass="ship-info-delivery-confirmation-type" />
                        </div>
                    </WmsCollapse>

                </div>
                <div className='footer-btn-holder'>
                    <WmsButton
                        label={this.translate("Label_Close")}
                        wmsSelector={this.state.selector + "DiscardExit"}
                        onClickFunc={this.props.onEditCarrierRoutingClose}
                        buttonType='orange'
                        icon={'fa-times-circle-o'}
                    />
                    <WmsButton
                        label={this.translate('Label_Save')}
                        wmsSelector={this.state.selector + "SaveExit"}
                        onClickFunc={this.eventClickSaveChangesAndExit}
                        disabled={this.state.gifSaveChangesAndExit}
                        loading={this.state.gifSaveChangesAndExit}
                        icon={'fa-floppy-o'}
                    />
                </div>
            </WmsModel>
        );
    }
}