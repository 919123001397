import React from "react";
import PropTypes from "prop-types";
import style from "./WmsButtonDropdown.scss";

const rebrandedTypes = {
    blue: "primary",
    green: "primary",
    yellow: "outlined",
    orange: "outlined",
};

class WmsButtonDropdown extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        var that = this;
        $("." + this.props.windowId + " #" + this.props.id).kendoMenu({
            open: function (e) {
                if ($(e.item).hasClass("mydisabled")) {
                    e.preventDefault();
                }
                that.props.onOpenKendoMenu();
            },
        });
    }
    componentWillReceiveProps(nextProps) {
        if (!nextProps.disabled) {
            var that = this;
            setTimeout(() => {
                $("." + this.props.windowId + " #" + this.props.id).kendoMenu({
                    open: function (e) {
                        if ($(e.item).hasClass("mydisabled")) {
                            e.preventDefault();
                        }
                        that.props.onOpenKendoMenu();
                    },
                });
            }, 0);
        }
    }

    render() {
        const mappedButtonType = rebrandedTypes[this.props.buttonType] ?? this.props.buttonType;

        return (
            <div className="wms-button-dropdown-component">
                <div className={"wms_dropdown_button" + (this.props.isHidden ? " hidden_menu" : "")}>
                    <ul data-wms-selector={this.props.wmsSelector} id={this.props.id} className={this.props.disabled == true ? "k-item k-state-default k-menu disabled" : "k-item k-state-default k-menu"}>
                        <li className={"wms-toolbar-button-text " + mappedButtonType + "_color"}>
                            <span className={"wms_dropdown_sprite fa " + this.props.menuIcon}></span>
                            <span className="wms_dropdown_menu_title">{this.props.label}</span>

                            {/* <ul> */}
                            {this.props.children}
                            {/* </ul> */}
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

WmsButtonDropdown.propTypes = {
    id: PropTypes.string.isRequired, // this should write the id to the input
    buttonType: PropTypes.string, // ['green', 'yellow', 'red', 'black']
    windowId: PropTypes.string.isRequired, // this should be used to get window id
    label: PropTypes.string.isRequired, // this should write the label of the menu
    menuIcon: PropTypes.string.isRequired, // this should write icon of menu
    disabled: PropTypes.bool, // default will be false
    isHidden: PropTypes.bool, // for hiddent button dropdown menu
    wmsSelector: PropTypes.string, // this should set up a selector on the mwnu
    onOpenKendoMenu: PropTypes.func, // This function call on open of button dropdown
};

WmsButtonDropdown.defaultProps = {
    buttonType: "green",
    wmsSelector: "",
    isHidden: false,
    onOpenKendoMenu: (e) => function () {},
};

export default WmsButtonDropdown;
