/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useState, useEffect, useCallback, useContext } from "react";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";
import { default as WmsDropdown } from "../../../components/WmsDropdown/WmsDropdown.jsx";
import { default as WmsDateTimePicker } from "../../../components/WmsDateTimePicker/WmsDateTimePicker.jsx";
import { FormValues } from "./create-hvp-batch-modal.js";
import * as GlobalService from "../../../global_services.js";
import { DropdownOption } from "./create-hvp-batch-modal.js";
import { CreateHVPContext } from "../context/createHVPContext";
import "./create-hvp-batch-modal.scss";
import WmsButton from "../../../components/WmsButton/WmsButton.jsx";
import WmsCheckBox from "../../../components/WmsCheckBox/WmsCheckBox.jsx";
import _ from "lodash";

interface CreateHVPBatchStep1Props {
    selector: string;
    warehouseOptions: DropdownOption[];
    customerOptions: DropdownOption[];
    packageOptions: DropdownOption[];
    formValues: FormValues;
    onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onPresetFormValuesChange: (formValues) => void;
}

export default function CreateHVPBatchStep1({
    selector,
    onInputChange,
    warehouseOptions,
    customerOptions,
    packageOptions,
    formValues,
    onPresetFormValuesChange,
}: CreateHVPBatchStep1Props) {
    /**
     * Constants and Globals
     */
    const translate = (window as any).translate as any;
    const labelFormatOptions = [
        { description: "PDF", value: "Pdf" },
        { description: "ZPL", value: "Zpl" },
        { description: "PNG (Combo Label)", value: "Png" },
    ];
    const returnLabelOptions = [
        { description: "Not Included", value: false },
        { description: "Included", value: true },
    ];
    const presetClassName = "preset-btn-save";
    const keyName = "HighVolumeProcessing";

    /**
     * States and Context
     */
    const [labelTemplateOptions, setLabelTemplateOptions] = useState([{ description: "Default", value: "Default" }]);
    const [presetDropdownOptions, setPresetDropdownOptions] = useState([
        { description: "Create New Preset", value: "CreateNewPreset" },
    ]);
    const { setModalConfig, modalConfig } = useContext(CreateHVPContext);
    const [isPresetDefault, setIsPresetDefault] = useState(false);
    const [isCreateNewPreset, setIsCreateNewPreset] = useState(false);
    const [isAddNewPresetLoading, setIsAddNewPresetLoading] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [isPresetNameExists, setIsPresetNameExists] = useState(false);
    const [presetValues, setPresetValues] = useState("");
    const [presetNameValues, setPresetNameValues] = useState("");
    const [presetArrayData, setPresetArrayData] = useState([]);

    /**
     * Functions
     */
    const setDefaultPreset = (presetResponseData) => {
        setFormValues();
        let defaultPresetObjData = _.find(presetResponseData, { presetDefault: true });
        if (defaultPresetObjData) {
            setPresetValues(defaultPresetObjData.presetData.preset);
            formValues.preset = defaultPresetObjData.presetData.preset;
            formValues.batchName = defaultPresetObjData.presetData.batchName;
            formValues.warehouse = defaultPresetObjData.presetData.warehouse;
            formValues.customer = defaultPresetObjData.presetData.customer;
            formValues.labelFormat = defaultPresetObjData.presetData.labelFormat;
            formValues.labelDate = "";
            formValues.returnLabel = defaultPresetObjData.presetData.returnLabel;
            formValues.labelTemplate = defaultPresetObjData.presetData.labelTemplate;
            formValues.package = defaultPresetObjData.presetData.package;
            onPresetFormValuesChange(defaultPresetObjData.presetData);
        }
    };

    const onPresetInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormValues();
        setPresetValues(event.target.value);
        if (event.target.value === "CreateNewPreset") {
            setIsCreateNewPreset(true);
        } else {
            setIsCreateNewPreset(false);
            let selectedObjData = _.find(presetArrayData, { presetName: event.target.value });
            if (selectedObjData) {
                formValues.preset = selectedObjData.presetData.preset;
                formValues.batchName = selectedObjData.presetData.batchName;
                formValues.warehouse = selectedObjData.presetData.warehouse;
                formValues.customer = selectedObjData.presetData.customer;
                formValues.labelFormat = selectedObjData.presetData.labelFormat;
                formValues.labelDate = "";
                formValues.returnLabel = selectedObjData.presetData.returnLabel;
                formValues.labelTemplate = selectedObjData.presetData.labelTemplate;
                formValues.package = selectedObjData.presetData.package;
                onPresetFormValuesChange(selectedObjData.presetData);
            }
        }
    };

    const setFormValues = () => {
        formValues.preset = "";
        formValues.batchName = "";
        formValues.warehouse = "0";
        formValues.customer = "0";
        formValues.labelFormat = "Pdf";
        formValues.labelDate = "";
        formValues.returnLabel = false;
        formValues.labelTemplate = "Default";
        formValues.package = null;
        $(".fa-times-circle-o").click();
    };

    const onPresetNameInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const isNameExist = _.some(presetArrayData, (preset) => {
            return preset.presetName.toLowerCase() === event.target.value.toLowerCase();
        });
        if (isNameExist) {
            setIsPresetNameExists(true);
            setIsSaveDisabled(true);
        } else {
            setIsPresetNameExists(false);
            if (event.target.value !== "") {
                setIsSaveDisabled(false);
            } else {
                setIsSaveDisabled(true);
            }
        }
        setPresetNameValues(event.target.value);
    };

    const onInputCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setIsPresetDefault(true);
        } else {
            setIsPresetDefault(false);
        }
    };

    const onPresetCancel = () => {
        setPresetValues("");
        setPresetNameValues("");
        setIsPresetDefault(false);
        setIsCreateNewPreset(false);
        setIsSaveDisabled(true);
        setFormValues();
    };

    const addNewPreset = () => {
        setIsAddNewPresetLoading(true);
        // Add New Preset
        const addNewPresetObj = {
            preset: presetValues,
            presetName: presetNameValues,
            presetDefault: isPresetDefault,
            presetData: {
                preset: presetNameValues,
                batchName: formValues.batchName,
                warehouse: formValues.warehouse,
                customer: formValues.customer,
                labelFormat: formValues.labelFormat,
                returnLabel: formValues.returnLabel,
                labelTemplate: formValues.labelTemplate,
                package: formValues.package,
            },
        };

        let tempPresetArrayData = [...presetArrayData];
        if (isPresetDefault) {
            tempPresetArrayData = tempPresetArrayData?.map((data) => ({ ...data, ...{ presetDefault: false } }));
        }
        const latestPresetArrayData = [...tempPresetArrayData, addNewPresetObj];
        setPresetArrayData(latestPresetArrayData);
        GlobalService.setUserClientMetaData(keyName, JSON.stringify(latestPresetArrayData));
        // Update Preset Dropdown Options
        const updatedDropdownOptions = [
            ...presetDropdownOptions,
            {
                description: presetNameValues,
                value: presetNameValues,
            },
        ];
        setPresetDropdownOptions(updatedDropdownOptions);

        setTimeout(() => {
            onPresetCancel();
            setIsAddNewPresetLoading(false);
        }, 100);
    };

    const handleUpdateCustomerLabelTemplates = useCallback(async () => {
        const reports = await GlobalService.initialPrintMenu(formValues.customer);
        const templateOptions = reports?.find((reportObj) => reportObj?.CategoryName === "Reprint Shipping Label");
        setLabelTemplateOptions(
            templateOptions?.Reports?.map((templateObj) => ({
                description: templateObj?.Name,
                value: templateObj?.Name,
            })) ?? []
        );
    }, [formValues.customer]);

    /**
     * UseEffects
     */

    useEffect(() => {
        setModalConfig({
            ...modalConfig,
            width: 800,
            height: 800,
            modalTitle: "Create High Volume Batch",
            modalSubtitle: "Set Batch Details",
        });
    }, []);

    useEffect(() => {
        GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response !== "") {
                    let tempResponseData = JSON.parse(response);
                    setPresetArrayData([...tempResponseData]);
                    const updatedDropdownOptions = [
                        ...presetDropdownOptions,
                        ...tempResponseData.map((responseObj) => ({
                            description: responseObj?.presetName,
                            value: responseObj?.presetName,
                        })),
                    ];
                    setPresetDropdownOptions(updatedDropdownOptions);
                    if (formValues.preset !== "") {
                        setPresetValues(formValues.preset);
                    } else {
                        setDefaultPreset(tempResponseData);
                    }
                }
            },
            function errorCallBack(response) {
                console.log("Error! No HVP User Metadata", response);
            }
        );
    }, []);

    useEffect(() => {
        if (formValues.customer === "0") {
            setLabelTemplateOptions([{ description: "Default", value: "Default" }]);
            onInputChange({ target: { name: "labelTemplate", value: "Default" } } as ChangeEvent<HTMLInputElement>);
        } else {
            handleUpdateCustomerLabelTemplates();
        }
    }, [formValues.customer, handleUpdateCustomerLabelTemplates]);

    useEffect(() => {
        if (formValues.warehouse === "") {
            onInputChange({ target: { name: "customer", value: "0" } } as ChangeEvent<HTMLInputElement>);
        }
    }, [formValues.warehouse]);

    return (
        <>
            <div className="error-message">
                {isPresetNameExists ? <span>{presetNameValues} already been taken</span> : null}
            </div>
            <div className="section-wrapper-preset">
                <WmsDropdown
                    id="NewHVPPreset"
                    name="preset"
                    label={translate("Label_Preset")}
                    wmsSelector={selector + "preset"}
                    value={presetValues}
                    onChangeFunc={onPresetInputChange}
                    options={presetDropdownOptions}
                    textField="description"
                    valueField="value"
                    blankFirstOption={true}
                    blankFirstOptionText={""}
                />
                {isCreateNewPreset && (
                    <>
                        <WmsInput
                            id="NewHVPPresetName"
                            name="presetName"
                            inputName="presetName"
                            wmsSelector={selector + "HVPPresetName"}
                            value={presetNameValues}
                            onChangeFunc={onPresetNameInputChange}
                            label={translate("Label_Preset_Name")}
                        />
                        <div>
                            <WmsButton
                                label={""}
                                wmsSelector={selector + "presetCancel"}
                                onClickFunc={onPresetCancel}
                                icon={"fa-times-circle-o"}
                                buttonType={"red"}
                                parentClassName={presetClassName}
                            />
                            <WmsButton
                                label={""}
                                wmsSelector={selector + "presetSave"}
                                onClickFunc={addNewPreset}
                                icon={"fa-save"}
                                parentClassName={presetClassName}
                                loading={isAddNewPresetLoading}
                                disabled={isSaveDisabled}
                            />
                        </div>
                        <WmsCheckBox
                            id={"presetDefault"}
                            name={"presetDefault"}
                            wmsSelector={selector + "PresetDefault"}
                            onChangeFunc={onInputCheckChange}
                            label={translate("Label_Recommend")}
                            checked={isPresetDefault}
                            parentClassName={presetClassName}
                        />
                    </>
                )}
            </div>
            <div className="section-wrapper">
                <WmsInput
                    id="NewHVPName"
                    name="batchName"
                    inputName="batchName"
                    wmsSelector={selector + "HVPSearchTargetBatchId"}
                    value={formValues.batchName}
                    onChangeFunc={onInputChange}
                    label={translate("Label_Batch_Name")}
                    showIconAfterInput={false}
                    isAutoFocus={true}
                />
                <WmsDropdown
                    id="NewHVPWarehouse"
                    name="warehouse"
                    label={translate("Label_Warehouse_Text")}
                    wmsSelector={selector + "warehouse"}
                    value={formValues.warehouse}
                    onChangeFunc={onInputChange}
                    options={warehouseOptions}
                    textField="name"
                    valueField="id"
                />
                <WmsDropdown
                    id="NewHVPCustomer"
                    name="customer"
                    label={translate("Label_Customer_Text")}
                    wmsSelector={selector + "customer"}
                    value={formValues.customer}
                    onChangeFunc={onInputChange}
                    options={customerOptions}
                    textField="name"
                    valueField="id"
                    disabled={formValues.warehouse === "0"}
                />
            </div>
            <div className="section-wrapper">
                <WmsDropdown
                    id="NewHVPLabelFormat"
                    name="labelFormat"
                    label={translate("Label_Label_Format")}
                    wmsSelector={selector + "HVPLabelFormat"}
                    value={formValues.labelFormat}
                    onChangeFunc={onInputChange}
                    options={labelFormatOptions}
                    textField="description"
                    valueField="value"
                    disabled={formValues.warehouse === "0"}
                />
                <WmsDateTimePicker
                    id="NewHVPDate"
                    name="labelDate"
                    inputName="labelDate"
                    wmsSelector={selector + "NewHVPDate"}
                    value={formValues.labelDate}
                    onChangeFunc={onInputChange}
                    label={translate("Label_Label_Shipment_Date")}
                    format={"yyyy-MM-dd"}
                    isDateParseInCustomFormat={false}
                    showIconAfterInput={false}
                    onlyDate={true}
                    minDateTime={new Date()}
                    disabled={isCreateNewPreset}
                />
            </div>
            <div className="section-wrapper">
                <WmsDropdown
                    id="NewHVPReturnLabel"
                    name="returnLabel"
                    label={translate("Label_Return_Print")}
                    wmsSelector={selector + "HVPReturnLabel"}
                    value={formValues.returnLabel}
                    onChangeFunc={onInputChange}
                    options={returnLabelOptions}
                    textField="description"
                    valueField="value"
                    disabled={formValues.warehouse === "0"}
                />
                <WmsDropdown
                    id="NewHVPLabelTemplate"
                    name="labelTemplate"
                    label={translate("Label_Label_Template")}
                    wmsSelector={selector + "HVPLabelTemplate"}
                    value={formValues.labelTemplate}
                    onChangeFunc={onInputChange}
                    options={labelTemplateOptions}
                    textField="description"
                    valueField="value"
                    disabled={formValues.warehouse === "0"}
                />
                <WmsDropdown
                    id="NewHVPPacakgeType"
                    name="package"
                    label={translate("Label_Package_Type")}
                    wmsSelector={selector + "HVPPackageType"}
                    value={formValues.package}
                    onChangeFunc={onInputChange}
                    options={packageOptions}
                    textField="name"
                    valueField="value"
                    disabled={formValues.warehouse === "0"}
                />
            </div>
        </>
    );
}
