import React, { Component } from 'react'
import { StringWms } from '../../../assets/ts/StringWms.ts';
import WmsContainer from '../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from '../components/WmsDropdown/WmsDropdown.jsx';
import WmsFile from '../components/WmsFile/WmsFile.jsx';
import WmsCheckBox from './../components/WmsCheckBox/WmsCheckBox.jsx';

import * as GlobalService from './../global_services';
import * as ImportAsnServices from './import-asn.services.jsx';

import style from './import-asn.scss';
import { ImportAsnGrid } from './import-asn-grid.component.jsx';

export class ImportAsnHolder extends Component {
    constructor(props) {
        super(props)

        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            selector: "ImportAsn",
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            customLabels: GlobalService.getCustomLabels(),
            showImportAsn: true,
            showManageAsnImport: false,
            showAmbiguousParser: false,
            customerOptions: [],
            facilityOptions: [],
            customerIndex: '',
            selectedFacility: '',
            onError: '',
            selectFileName:'',
            sharedFetchCustomerInfo: null,
            skipAsnImportManagement: false,
            optionsParser: [],
            indexOptionsParser: null,
            sharedFileUploadData: null,
            selectFormat: {
                TransformId: 0,
                Description: this.translate('Label_SelectFormat')
            },
            receiverImportFileData: [],
        }
    }

    componentDidMount() {
        kendo.ui.progress($('#' + this.state.windowId), true);

        GlobalService.GetCustomersAndFacility().then((response) => {

            this.setState({
                facilityOptions: GlobalService.getFacilityList(),
                customerOptions: GlobalService.getCustomerList()
            });

        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        if (event.target.name == 'customerIndex' && event.target.value != '') {

            let customerObj = this.state.customerOptions[event.target.value];
            let facilityOptions = GlobalService.getFacilityList();
            let cusFaciliOptions = [];

            for (let x = 0; x < customerObj.facilities.length; x++) {
                const facility = facilityOptions.find((element) => {
                    if (element.id == customerObj.facilities[x]) {
                        return element;
                    }
                });
                if(facility) {
                    cusFaciliOptions.push(
                        facility
                        );
                    }
            }
            this.setState({
                facilityOptions: cusFaciliOptions,
				selectedFacility: (typeof customerObj.defaultFacility != 'undefined' && cusFaciliOptions.includes(customerObj.defaultFacility)) ? customerObj.defaultFacility : ''
            });

            this.getCustomerInfo(customerObj);
        }
    }

    handleFileUpload = (fileUploadedData) => {
        // check if file is greater than 2MB
        if(fileUploadedData.fileSize > 2000000 ) {
            var _data = {
                "Source": 1,
                "ServerName": "3PL-QA-APP",
                "Descriptions": [
                    {
                    "ErrorClass": "Http",
                    "ErrorCode": "System",
                    "Descriptors": [ "Maximum request length exceeded."],
                    "Hint": null
                    }
                ],
                "ErrorClass": "Http",
                "ErrorCode": "System",
                "type": "error"
                };
            GlobalService.showErrorMessage(_data);
            return false;
        }
        console.log("fileUploadedData.fileread", fileUploadedData.fileread);
        // check if file is 0 size and blank content
        if(fileUploadedData.fileSize == 0 ||  (fileUploadedData.fileread && fileUploadedData.fileread.trim() == '')) {
            var _data = {
                "Source": 1,
                "ServerName": "3PL-QA-APP",
                "Descriptions": [
                    {
                    "ErrorClass": "Http",
                    "ErrorCode": "System",
                    "Descriptors": [ "Minimum request length exceeded."],
                    "Hint": null
                    }
                ],
                "ErrorClass": "Http",
                "ErrorCode": "System",
                "type": "error"
                };
            GlobalService.showErrorMessage(_data);
            return false;
        }
        let fileSize = (fileUploadedData.fileSize / (1000*1000)).toFixed(2);
        if(fileSize == 0.00) {
            fileSize = (fileUploadedData.fileSize / 1000).toFixed(2) + 'kb';
        } else {
            fileSize = fileSize + 'mb';
        }

        this.setState({
            sharedFileUploadData: fileUploadedData,
            selectFileName:fileUploadedData.fileName,
            selectFileSize:fileSize
        });

        if (this.state.skipAsnImportManagement == false) {

            var data = {
                "Preferences": {
                    "OnError": this.state.onError,
                },
                "CustomerId": this.state.customerOptions[this.state.customerIndex].id,
                "FacilityId": this.state.selectedFacility,
                "TransformId": this.state.indexOptionsParser && this.state.indexOptionsParser != 0 ? this.state.indexOptionsParser : null,
                "File": fileUploadedData.fileread
            };


            this.$rootScope.animationMoveIcon($('#file-upload'));
            this.$rootScope.taskStarted();
            // this.$rootScope.$broadcast("receiptImportParseAndImportAsync", data);
            /**Code for react progress component */
            data.type = "importAsnAsync";
            var event = new CustomEvent("showProgressModal", {
                detail: data
            });
            document.dispatchEvent(event);

        } else {

            kendo.ui.progress($('#' + this.state.windowId), true);

            ImportAsnServices.parseReceiverImportFile(
                this.state.customerOptions[this.state.customerIndex].id,
                this.state.selectedFacility,
                this.state.indexOptionsParser,
                fileUploadedData.fileread
            ).then((response) => {
                if (response) {
                    if (response.status === 200) {

                        this.setState({
                            showImportAsn: false,
                            showManageAsnImport: true,
                            receiverImportFileData: response.data
                        });

                    } else {
                        this.setState({
                            sharedFileUploadData: null,
                        });
                    }
                }
            }).finally(() => { kendo.ui.progress($('#' + this.state.windowId), false);});
        }
    }

    getCustomerInfo(param_cusobj) {

        kendo.ui.progress($('#' + this.state.windowId), true);
        this.setState({ showAmbiguousParser: false })

        ImportAsnServices.getCustomerData(param_cusobj.uri).then((response) => {
            var OptionsParser = [];
            var EdiAmbigParsers = response.AmbiguousTransforms.Edi;
            var XmlAmbigParsers = response.AmbiguousTransforms.Xml;
            var OtherAmbigParsers = response.AmbiguousTransforms.Other;
            var ShowAmbiguousParser = false;

            OptionsParser.unshift(this.state.selectFormat);
            if (!StringWms.isEmpty(XmlAmbigParsers)) {
                for (var x = 0; x < XmlAmbigParsers.length; x++) {
                    OptionsParser.push(XmlAmbigParsers[x]);
                }
                ShowAmbiguousParser = true;
            }
            if (!StringWms.isEmpty(EdiAmbigParsers)) {
                for (var x = 0; x < EdiAmbigParsers.length; x++) {
                    OptionsParser.push(EdiAmbigParsers[x]);
                }
                ShowAmbiguousParser = true;
            }
            if (!StringWms.isEmpty(OtherAmbigParsers)) {
                for (var x = 0; x < OtherAmbigParsers.length; x++) {
                    OptionsParser.push(OtherAmbigParsers[x]);
                }
                ShowAmbiguousParser = true;
            }

            this.setState({
                onError: response.Preferences.OnError,
                sharedFetchCustomerInfo: response,
                showAmbiguousParser: ShowAmbiguousParser,
                optionsParser: OptionsParser,
            });
        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
    }

    handleCheckboxChange = (event) => {
        this.setState({ skipAsnImportManagement: !this.state.skipAsnImportManagement });
    }

    changeIndexOptionsParser = (event) => {
        this.setState({
            indexOptionsParser: event.target.value
        });
    }

    selectNewFile = () => {
        this.setState({
            showImportAsn: true,
            showManageAsnImport: false,
            receiverImportFileData: [],
        });
    }

    render() {
        return (
            <div id="import-asn-holder" >
                {this.state.showImportAsn && (
                    <div>
                        <WmsContainer>
                            <div className="wms-warning-wrapper in-import" data-wms-selector="ImportReceiptFileUploadWarning">
                                <i className="wms-sprite fa fa-info-circle"></i>
                                <span>
                                    {this.translate('Label_Import_Max_Upload_Warning')}
                                </span>
                            </div>
                            <WmsDropdown
                                id="customerIndex"
                                name="customerIndex"
                                label={this.state.customLabels.Label_Customer}
                                wmsSelector={this.state.selector + "CustomerList"}
                                value={this.state.customerIndex}
                                onChangeFunc={this.handleChange}
                                options={this.state.customerOptions}
                                required={true}
                                applyIndex={true}
                                blankFirstOption={true}
                                textField='name'
                            />

                            <WmsDropdown
                                id="selectedFacility"
                                name="selectedFacility"
                                label={this.state.customLabels.Label_Warehouse}
                                wmsSelector={this.state.selector + "FacilityList"}
                                value={this.state.selectedFacility}
                                onChangeFunc={this.handleChange}
                                options={this.state.facilityOptions}
                                required={true}
                                blankFirstOption={true}
                                textField='name'
                                valueField='id'
                            />
                        </WmsContainer>

                        <div>
                            {this.state.showAmbiguousParser && (
                                <WmsContainer>
                                    <div className="title-text pd-l">
                                        <label className="wms-input-label">{this.translate('Label_Choose_Format')}</label>
                                    </div>
                                    <WmsDropdown
                                        id="indexOptionsParser"
                                        name="indexOptionsParser"
                                        label={this.translate('Label_File_Format_Text')}
                                        wmsSelector={this.state.selector + "IndexOptionsParser"}
                                        value={this.state.indexOptionsParser}
                                        onChangeFunc={this.changeIndexOptionsParser}
                                        options={this.state.optionsParser}
                                        textField='Description'
                                        valueField='TransformId'
                                    />
                                </WmsContainer>
                            )}
                        </div>

                        <WmsFile
                            id="file-upload"
                            name="file-upload"
                            label={this.translate('Label_SelectFile')}
                            wmsSelector={this.state.selector + "File"}
                            value={this.state.selectFileName ? (this.state.selectFileName + ' ('+this.state.selectFileSize+')') : ''}
                            onChangeFunc={this.handleFileUpload}
                            disabled={((!this.state.customerIndex > 0 || !this.state.selectedFacility > 0) || (this.state.showAmbiguousParser ? (this.state.indexOptionsParser == null || this.state.indexOptionsParser == 0) : false))}
                            clearInputOnClick={true}
                        />

                        <div className="row">
                            <WmsCheckBox
                                id="chk_sel_all"
                                className="option-input"
                                name="skipAsnImportManagement"
                                wmsSelector={this.state.selector + "ChkSelAll"}
                                onChangeFunc={this.handleCheckboxChange}
                                checked={this.state.skipAsnImportManagement == true}
                                value=""
                                label=''
                            />
                            <div className="chk_label_mng_description">
                                {this.translate('Label_Import_ASN_Use_Quick_Import')}
                                <span className="warning-txt">
                                    {this.translate('Label_ASN_ColumnHeaders_Present')}
                                </span>
                            </div>
                        </div>
                        <div className="row wms-area-content">
                            <div className="wms-area-content-darker without-bg">
                                <div className="wms-area-content-darker wms-area-partial-border ">
                                    <div className="row">
                                        <span className="wms-standard-button">
                                            <a href="https://help.3plcentral.com/hc/en-us/articles/360038228512-Import-Templates" target="_blank">
                                                <span className="k-icon k-i-download"></span>
                                                {this.translate('Label_download_standard_template')}
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.state.showManageAsnImport && (
                    <ImportAsnGrid
                        WindowId={this.state.windowId}
                        Selector={this.state.selector}
                        GridData={this.state.receiverImportFileData}
                        SelectNewFile={this.selectNewFile}
                        SharedFetchCustomerInfo={this.state.sharedFetchCustomerInfo}
                        SharedFileUploadData={this.state.sharedFileUploadData}
                        SharedSelectedCustomerId={this.state.customerOptions[this.state.customerIndex].id}
                    />

                )}
            </div>
        )
    }
}
