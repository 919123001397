import React, { Component } from "react";
import WmsCollapse from "../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../components/WmsInput/WmsInput.jsx";

export class SekoComponent extends Component {

    constructor(props) {
        super(props);
        
        this.translate = window.translate;
        this.$rootScope = window;
        this.state = { }
    }


    render() {
        return (
            <WmsCollapse
                id={"SekoInformation"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <WmsInput
                        id="nickname"
                        inputName="Nickname"
                        wmsSelector={this.props.selector + "nickname"}
                        onChangeFunc={this.props.handleConnectionObjChange}
                        label={this.translate('Label_Account_Nick_Name')}
                        required={true}
                        value={this.props.connectionObj.Nickname}
                        extraWrapperClass="seko-nikcname"
                    />
                    <WmsInput
                        id="accessKey"
                        inputType={"accessKey"}
                        inputName="AccessKey"
                        wmsSelector={this.props.selector + "accessKey"}
                        onChangeFunc={this.props.handleConnectionObjChange}
                        label={this.translate("Label_access_key")}
                        required={true}
                        value={this.props.connectionObj.AccessKey}
                        extraWrapperClass="seko-accesskey"
                    />
                </div>
            </WmsCollapse>
        );
    }
}
