import React from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsDropdown from "../../../components/WmsDropdown/WmsDropdown.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";

declare var window: any;

interface Props {
    selector: string;
    handleCarrierObjChange: any;
    currentCarrierObj: any;
    optionsState: any;
    optionsCountry: any;
    optionsHub: any;
}

const FedExSmartPostAccountInfoComponent: React.FC<Props> = (props: Props): any => {
    props.optionsHub = [
        { Location: "Unspecified", HubId: "" },
        { Location: "HQ - Brookfield, WI", HubId: "5531" },
        { Location: "Allentown, PA", HubId: "5185" },
        { Location: "Atlanta, GA", HubId: "5303" },
        { Location: "Avenel, NJ", HubId: "Peak Annex" },
        { Location: "Baltimore, MD", HubId: "5213" },
        { Location: "Charlotte, NC", HubId: "5281" },
        { Location: "Chino, CA", HubId: "5929" },
        { Location: "Dallas, TX", HubId: "5751" },
        { Location: "Denver, CO", HubId: "5802" },
        { Location: "Detroit, MI", HubId: "5481" },
        { Location: "Edison, NJ", HubId: "5087" },
        { Location: "Grove City, OH", HubId: "5431" },
        { Location: "Grove Port, OH", HubId: "5436" },
        { Location: "Houston, TX", HubId: "5771" },
        { Location: "Indianapolis, IN", HubId: "5465" },
        { Location: "Kansas City, KS", HubId: "5648" },
        { Location: "Los Angeles, CA", HubId: "5902" },
        { Location: "Martinsburg, WV", HubId: "5254" },
        { Location: "Memphis, TN", HubId: "5379" },
        { Location: "Minneapolis, MN", HubId: "5552" },
        { Location: "New Berlin, WI", HubId: "5531" },
        { Location: "Northborough, MA", HubId: "5015" },
        { Location: "Orlando, FL", HubId: "5327" },
        { Location: "Phoenix, AZ", HubId: "5854" },
        { Location: "Pittsburgh, PA", HubId: "5150" },
        { Location: "Reno, NV", HubId: "5893" },
        { Location: "Sacramento, CA", HubId: "5958" },
        { Location: "Salt Lake City, UT", HubId: "5843" },
        { Location: "Seattle, WA", HubId: "5983" },
        { Location: "Scranton, PA", HubId: "5186" },
        { Location: "South Brunswick, NJ", HubId: "5097" },
        { Location: "St.Louis, MO", HubId: "5631" },
        { Location: "Wheeling, IL", HubId: "5602" },
        { Location: "Windsor, CT", HubId: "5061" },
    ];

    return (
        <>
            <WmsCollapse
                id={"FedexSmartPostAccountInfoScreen"}
                headerLabel={window.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id="nickname"
                            inputName="Nickname"
                            wmsSelector={props.selector + "nickname"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Account_Nick_Name")}
                            value={props.currentCarrierObj.Nickname}
                            required={true}
                            extraWrapperClass={"width-40"}
                        />
                        <WmsInput
                            id="accountNumber"
                            inputName="AccountNumber"
                            wmsSelector={props.selector + "accountNumber"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Account_Number")}
                            value={props.currentCarrierObj.AccountNumber}
                            required={true}
                            extraWrapperClass={"width-40"}
                        />
                    </div>
                </div>
            </WmsCollapse>
            <WmsCollapse
                id={"FedexSmartPostCorporateInfoScreen"}
                headerLabel={window.translate("Label_Corporate_Information")}
                showCollapseText={false}
                showCollapseIcon={true}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id="firstName"
                            inputName="FirstName"
                            wmsSelector={props.selector + "firstName"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Warehouse_First_Name")}
                            extraWrapperClass={"width-40"}
                            value={props.currentCarrierObj.FirstName}
                            required={true}
                        />

                        <WmsInput
                            id="lastName"
                            inputName="LastName"
                            wmsSelector={props.selector + "lastName"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Warehouse_Last_Name")}
                            extraWrapperClass={"width-40"}
                            required={true}
                            value={props.currentCarrierObj.LastName}
                        />

                        <WmsInput
                            id="company"
                            inputName="Company"
                            wmsSelector={props.selector + "company"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Company_Name")}
                            extraWrapperClass={"width-40"}
                            required={true}
                            value={props.currentCarrierObj.Company}
                        />

                        <WmsInput
                            id="jobTitle"
                            inputName="ContactTitle"
                            wmsSelector={props.selector + "jobTitle"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Job_Title")}
                            extraWrapperClass={"width-40"}
                            required={true}
                            value={props.currentCarrierObj.ContactTitle}
                        />

                        <WmsInput
                            id="email"
                            inputName="Email"
                            wmsSelector={props.selector + "emailAddress"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Email_Address")}
                            extraWrapperClass={"width-40"}
                            required={true}
                            value={props.currentCarrierObj.Email}
                        />

                        <WmsInput
                            id="phone"
                            inputName="Phone"
                            wmsSelector={props.selector + "phoneNumber"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Warehouse_PhoneNumber")}
                            extraWrapperClass={"width-40"}
                            value={props.currentCarrierObj.Phone}
                            required={true}
                            valueType="number"
                        />

                        <WmsInput
                            id="address1"
                            inputName="Address1"
                            wmsSelector={props.selector + "address1"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Address_1")}
                            extraWrapperClass={"width-40"}
                            required={true}
                            value={props.currentCarrierObj.Address1}
                        />
                        <WmsInput
                            id="address2"
                            inputName="Address2"
                            wmsSelector={props.selector + "address2"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Address_2")}
                            extraWrapperClass={"width-40"}
                            value={props.currentCarrierObj.Address2}
                        />

                        <WmsInput
                            id="city"
                            inputName="City"
                            wmsSelector={props.selector + "city"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Warehouse_City")}
                            extraWrapperClass={"width-40"}
                            value={props.currentCarrierObj.City}
                            required={true}
                        />

                        <WmsDropdown
                            id="state"
                            name="State"
                            label={window.translate("Label_Costomer_State_Province")}
                            wmsSelector={props.selector + "state'"}
                            onChangeFunc={props.handleCarrierObjChange}
                            disabled={props.currentCarrierObj.CountryCode ? false : true}
                            value={props.currentCarrierObj.State}
                            options={props.optionsState}
                            valueField="RegionCode"
                            textField="Name"
                            parentClassName="form_group"
                            extraWrapperClass={"width-40"}
                            required={true}
                        />

                        <WmsInput
                            id="postalCode"
                            inputName="PostalCode"
                            wmsSelector={props.selector + "postalCode"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Account_Zip_Postal_code")}
                            extraWrapperClass={"width-40"}
                            value={props.currentCarrierObj.PostalCode}
                            required={true}
                        />

                        <WmsDropdown
                            id="countryCode"
                            name="CountryCode"
                            label={window.translate("Label_Country")}
                            wmsSelector={props.selector + "countryCode"}
                            onChangeFunc={props.handleCarrierObjChange}
                            value={props.currentCarrierObj.CountryCode}
                            options={props.optionsCountry}
                            valueField="CountryCode"
                            textField="Name"
                            parentClassName="form_group"
                            extraWrapperClass={"width-40"}
                            required={true}
                        />
                    </div>
                </div>
            </WmsCollapse>
            <WmsCollapse
                id={"FedexSmartPostHubInfoScreen"}
                headerLabel={window.translate("Label_SmartPost_Hub_Information")}
                showCollapseText={false}
                showCollapseIcon={true}
            >
                <div className="account-info-section">
                    <div>
                        <WmsDropdown
                            id="smartPostHub"
                            name="HubId"
                            label={window.translate("Label_SmartPostHub")}
                            wmsSelector={props.selector + "smartPostHub"}
                            onChangeFunc={props.handleCarrierObjChange}
                            value={props.currentCarrierObj.HubId}
                            options={props.optionsHub}
                            valueField="HubId"
                            textField="Location"
                            parentClassName="form_group"
                            extraWrapperClass={"width-40"}
                            required={true}
                        />
                    </div>
                </div>
            </WmsCollapse>
            <WmsCollapse
                id={"FedexSmartPostShippingInfoScreen"}
                headerLabel={window.translate("Label_Shipping_Address")}
                showCollapseText={false}
                showCollapseIcon={true}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id="shippingAddress1"
                            inputName="ShippingAddress1"
                            wmsSelector={props.selector + "shippingAddress1"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Address_1")}
                            extraWrapperClass={"width-40"}
                            required={true}
                            value={props.currentCarrierObj.ShippingAddress1}
                        />
                        <WmsInput
                            id="shippingAddress2"
                            inputName="ShippingAddress2"
                            wmsSelector={props.selector + "shippingAddress2"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Address_2")}
                            extraWrapperClass={"width-40"}
                            value={props.currentCarrierObj.ShippingAddress2}
                        />

                        <WmsInput
                            id="shippingCity"
                            inputName="ShippingCity"
                            wmsSelector={props.selector + "shippingCity"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Warehouse_City")}
                            extraWrapperClass={"width-40"}
                            value={props.currentCarrierObj.ShippingCity}
                            required={true}
                        />

                        <WmsDropdown
                            id="shippingState"
                            name="ShippingState"
                            label={window.translate("Label_Costomer_State_Province")}
                            wmsSelector={props.selector + "shippingState'"}
                            onChangeFunc={props.handleCarrierObjChange}
                            disabled={props.currentCarrierObj.ShippingCountryCode ? false : true}
                            value={props.currentCarrierObj.ShippingState}
                            options={props.optionsState}
                            valueField="RegionCode"
                            textField="Name"
                            parentClassName="form_group"
                            extraWrapperClass={"width-40"}
                            required={true}
                        />

                        <WmsInput
                            id="shippingPostalCode"
                            inputName="ShippingPostalCode"
                            wmsSelector={props.selector + "shippingPostalCode"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={window.translate("Label_Account_Zip_Postal_code")}
                            extraWrapperClass={"width-40"}
                            value={props.currentCarrierObj.ShippingPostalCode}
                            required={true}
                        />

                        <WmsDropdown
                            id="shippingCountryCode"
                            name="ShippingCountryCode"
                            label={window.translate("Label_Country")}
                            wmsSelector={props.selector + "shippingCountryCode"}
                            onChangeFunc={props.handleCarrierObjChange}
                            value={props.currentCarrierObj.ShippingCountryCode}
                            options={props.optionsCountry}
                            valueField="CountryCode"
                            textField="Name"
                            parentClassName="form_group"
                            extraWrapperClass={"width-40"}
                            required={true}
                        />
                    </div>
                </div>
            </WmsCollapse>
        </>
    );
};

FedExSmartPostAccountInfoComponent.propTypes = {};

FedExSmartPostAccountInfoComponent.defaultProps = {};

export default React.memo(FedExSmartPostAccountInfoComponent);
