import React from 'react';

//component
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsModel from './../../components/WmsModel/WmsModel.jsx';
import WmsInput from './../../components/WmsInput/WmsInput.jsx';
import WmsToolTip from './../../components/WmsToolTip/WmsToolTip.tsx';
import WmsDropdown from './../../components/WmsDropdown/WmsDropdown.jsx';
import WmsDropdownList from './../../components/WmsDropdownList/WmsDropdownList.jsx';
import WmsContainer from './../../components/WmsContainer/WmsContainer.jsx';
import WmsConfirmModel from './../../components/WmsConfirmModel/WmsConfirmModel.jsx';

import { ArrayWms } from '../../../../assets/ts/ArrayWms.ts';

import * as inventoryservices from './../inventory.services.jsx';
import * as GlobalService from './../../global_services';

import style from './customer-transfer.scss';
import { kendo } from "./../../global_variable_service.jsx";

export class InventoryCustomerTransferModel extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            Selector: 'InventoryC2C',
            facilityId: 0,
            transferInventoryLoader: false,
            holdReasonOptions: [],
            selectedHoldReason: '',
            localKendoGrid: null,
            sourceItemData: {
                Options: {}
            },
            destinationItemData: {
                customerId: "" // GlobalService.getCustomerList(false)[0].id // select first customer id for sku dropdown
            },
            selectedItemCount: 0,
            CustomLabels: GlobalService.getCustomLabels(),
            optionsCustomer: [] ,
            optionsSku: [],
            optionsQualifier: [],
            optionsLocation: [],
            destinationCustomerObj: {},
            hideLocationDropdown: false,
            hideMovableUnit: false,
            requiredLocationDropdown: false,
            requiredMovableUnit: false,
            totalTransferedQuantity: 0,
            isQuantityMaxValid: true,
            destinationSerialShow: false,
            destinationSerialRequired: false,
            destinationLotShow: false,
            destinationLotRequired: false,
            destinationQtyDisabled: false,
            showSameDataTooltip: false,
            destinationExpDateShow: false,
            destinationExpDateRequired: false,
            isQtyConfirmation: false
        };
    }

    componentDidMount (){
        console.log("componentDidMount call");
        this.filteredCustomerList(GlobalService.getCustomerList(false), this.props.warehouseObj.id);
        var idArray = this.props.localKendoGrid.getKeys();
        var selectedCount = this.props.localKendoGrid.getNumberOfSelections();
        if(idArray.length == 1){
            var tmpIds = idArray[0].split(',');
            selectedCount = tmpIds.length;
        }
        kendo.ui.progress($('.customer-transfer-model'), true);
        inventoryservices.c2cTransfererOptions(idArray).then((response) => {
            if (response.MaxQuantity == 0) {
                GlobalService.notification('error', "Item Available Primary Need More Than 0");
                this.exitTransferInventoryModal();
                return;
            }
            this.setState({
                sourceItemData: response,
                selectedItemCount: selectedCount,
                ReceiverItemId: idArray
            });

            this.setState({
                destinationItemData: Object.assign(
                    this.state.destinationItemData,
                    {
                        MovableUnitLabel : response.DesiredMovableUnitLabel,
                        LocationId: this.checkIfLocationExistingInList(response.LocationId) ? response.LocationId : ""
                    }
                )
            });
            kendo.ui.progress($('.customer-transfer-model'), false);
            this.setState({ totalTransferedQuantity: parseInt(response.MaxQuantity) });
        }).finally(() => {
            kendo.ui.progress($('.customer-transfer-model'), false);
        });
    }

    filteredCustomerList = (customerList, facilityId) => {
        var filteredSet = [];
        for(var x = 0; x < customerList.length; x++){
            if(customerList[x].facilities.indexOf(facilityId) > -1){
                filteredSet.push(customerList[x]);
            }

        }
        this.setState({
            optionsCustomer: filteredSet
        });
    }

    checkIfLocationExistingInList = (locationId) => {
        var isFound = false;
        this.state.optionsLocation.find((element) => {
            if (element.Id == locationId) {
                isFound = true;
            }
        });
        return isFound;
    }

    holdReasonChange = (event) => {
        this.setState({ selectedHoldReason: event.target.value });
    }

    onCloseQtyConfirmation = () => {
        this.setState({ isQtyConfirmation: false });
    }

    transferCustomer = (event) => {
        if (this.state.totalTransferedQuantity == this.state.destinationItemData.PrimaryAvailable) {
            this.continueToTransferCustomer();
        } else {
            this.setState({ isQtyConfirmation: true });
        }
    }

    continueToTransferCustomer = () => {

        this.setState({
            isQtyConfirmation:false,
            transferInventoryLoader: true
        });
        var finalObject = this.createFinalObjectBeforeSubmit();

        inventoryservices.C2CTransferer(finalObject).then((response) => {
            if (response.status == 200 || response.status == 204) {
                this.props.gridReload();
                GlobalService.notification('success', this.translate('Label_Success_Transfer_Title'));
                this.exitTransferInventoryModal();
            }
        }).finally(() => {
            this.setState({
                transferInventoryLoader: false
            });
        });
    }

    createFinalObjectBeforeSubmit = () => {
        // logic for calculation of toQty on multiple item selection
        var differenceOfQuantity = this.state.totalTransferedQuantity / this.state.destinationItemData.PrimaryAvailable;
        var isSameMULabel = false;
        if (this.state.sourceItemData.MovableUnitLabel == this.state.destinationItemData.MovableUnitLabel) {
            isSameMULabel = true;
        }

        let locationDestinationId = this.state.destinationItemData.LocationId ? this.state.destinationItemData.LocationId : 0;
        if (this.state.hideLocationDropdown) {
            locationDestinationId = 0;
        }

        var transferItemsData = [];
        var remainingQnty = this.state.destinationItemData.PrimaryAvailable;
        for (var i = 0; i < this.state.sourceItemData.ReceiveItemDetails.length; i++) {
            var responseOfCalculation = this.getAvailbleQtyFromItemId(this.state.sourceItemData.ReceiveItemDetails[i], differenceOfQuantity, remainingQnty);
            var toQty = responseOfCalculation.toQty;
            remainingQnty = responseOfCalculation.remainingQnty;
            var itemObj = {
                "ReceiveItemId": this.state.sourceItemData.ReceiveItemDetails[i].ReceiveItemId,
                "FromQty": this.state.selectedItemCount > 1 ? responseOfCalculation.AvailableQty : this.state.totalTransferedQuantity,
                "ToQty": this.state.selectedItemCount > 1 ? toQty : this.state.destinationItemData.PrimaryAvailable,
                "ToLocationId": this.state.selectedItemCount > 1 ? this.state.sourceItemData.ReceiveItemDetails[i].LocationId : locationDestinationId,
                "ItemTraits": {
                    "ItemId": this.state.destinationItemData.sku,
                    "Qualifier": this.state.destinationItemData.Qualifier ? this.state.destinationItemData.Qualifier : "",
                    "LotNumber": this.state.destinationItemData.LotNumber ? this.state.destinationItemData.LotNumber : "",
                    "SerialNumber": this.state.sourceItemData.ReceiveItemDetails.length > 1 ? this.state.sourceItemData.ReceiveItemDetails[i].SerialNumber : this.state.destinationItemData.SerialNumber,
                    "ExpirationDate": this.state.sourceItemData.ReceiveItemDetails.length > 1 ? this.state.sourceItemData.ReceiveItemDetails[i].ExpirationDate : this.state.destinationItemData.ExpirationDate,
                    "Cost": this.state.sourceItemData.ReceiveItemDetails.length > 1 ? this.state.sourceItemData.ReceiveItemDetails[i].Cost : this.state.destinationItemData.Cost,
                    "PalletLabel": this.state.sourceItemData.ReceiveItemDetails.length > 1 ? this.state.sourceItemData.ReceiveItemDetails[i].PalletLabel : this.state.destinationItemData.MovableUnitLabel
                }
            };
            transferItemsData.push(itemObj);
        }
        var finalObject = {
            "facilityId": this.state.sourceItemData.FacilityId,
            "toCustId": parseInt(this.state.destinationItemData.customerId),
            "fromCustId": this.state.sourceItemData.CustomerId,
            "transferItems": transferItemsData
        };
        return finalObject;
    }

    getAvailbleQtyFromItemId = (element, differenceOfQuantity, remainingQnty) => {
        var availableForToQty = (element.AvailableQty / differenceOfQuantity);
        element.toQty = (availableForToQty).toFixed(2);
        if (remainingQnty > availableForToQty) {
            element.remainingQnty = remainingQnty - availableForToQty;
        } else {
            element.remainingQnty = 0;
        }
        return element;
    }

    getCustomerObjectFromId = (id) => {
        return this.state.optionsCustomer.find((element) => {
            if (element.id == id) {
                return element;
            }
        });
    }

    clearTransfer = () => {
        this.setState({
            destinationItemData: {
                customerId: "",
                sku: "",
                Qualifier: "",
                PrimaryAvailable: ""
            },
            destinationSerialShow: false,
            destinationLotShow: false,
            optionsQualifier: []
        });
    }

    handleCustomerChange = (event) => {
        this.setState({
            destinationItemData: Object.assign(
                this.state.destinationItemData,
                {
                    customerId: event.target.value,
                    sku: "",
                    Qualifier: "",
                    PrimaryAvailable: "",
                    LocationId: 0
                }
            ),
            optionsQualifier: []
        }, this.checkForSameCustSkuQualifier('Qualifier'));
        var custObject = this.getCustomerObjectFromId(event.target.value);
        this.setState({
            hideLocationDropdown: false,
            hideMovableUnit: false,
            requiredLocationDropdown: false,
            requiredMovableUnit: false,
            destinationSerialShow: false,
            destinationLotShow: false,
            destinationCustomerObj: custObject ? custObject : {}
        });
        if(!custObject) {
          return;
        }
        if (custObject.options.trackLocation == 1) {
            this.setState({
                hideLocationDropdown: false,
                requiredLocationDropdown: false
            });
        } else if (custObject.options.trackLocation == 2) {
            this.setState({
                hideLocationDropdown: false,
                requiredLocationDropdown: true
            });
        } else {
            this.setState({
                hideLocationDropdown: true,
                requiredLocationDropdown: false
            });
        }
        if (custObject.options.trackPallets == 1) {
            this.setState({
                hideMovableUnit: false,
                requiredMovableUnit: false
            });
        } else if (custObject.options.trackPallets == 2) {
            this.setState({
                hideMovableUnit: false,
                requiredMovableUnit: true
            });
        } else {
            this.setState({
                hideMovableUnit: true,
                requiredMovableUnit: false
            });
        }


        // override condition for hide movable unit label and location for multiple selection

        if (this.state.selectedItemCount > 1) {
            this.setState({
                hideMovableUnit: true,
                requiredMovableUnit: false,
                hideLocationDropdown: true,
                requiredLocationDropdown: false
            });
        }

    }
    checkForSameCustSkuQualifier = (fromWhere) => {
        if (fromWhere == "Qualifier") {
            if (this.state.destinationItemData.customerId == this.state.sourceItemData.CustomerId && this.state.destinationItemData.sku && this.state.sourceItemData.ItemId == this.state.destinationItemData.sku && (this.state.optionsQualifier.length > 0 ? this.state.sourceItemData.Qualifier == this.state.destinationItemData.Qualifier : true)) {
                this.setState({
                    showSameDataTooltip: true
                });
            } else {
                this.setState({
                    showSameDataTooltip: false
                });
            }
        }
    }
    skuDropdownChangeEvent = (event) => {
        if(event.target.value.Sku == 'Unspecified') {
            this.setState({
                destinationItemData: Object.assign(
                    this.state.destinationItemData,
                    {
                        sku: "",
                        Qualifier: "",
                        PrimaryAvailable: "",
                        LotNumber: "",
                        SerialNumber: "",
                        SkuDescription: "",
                        LocationId: 0
                    }
                )
            });
            return;
        }
        this.setState({
            destinationItemData: Object.assign(
                this.state.destinationItemData,
                {
                    sku: event.target.value.ItemId,
                    Qualifier: "",
                    LotNumber: "",
                    SerialNumber: "",
                    SkuDescription: event.target.value.Description,
                    LocationId: 0
                }
            )
        });

        kendo.ui.progress($('.loading-section'), true);
        var optionsQualifier = [];

        if (event.target.value.Qualifiers[0] == "" && event.target.value.Qualifiers.length == 1) {
            this.state.destinationItemData.Qualifier = "";
        }else{
            optionsQualifier = ArrayWms.convertArrayToOption(event.target.value.Qualifiers);
            this.state.destinationItemData.Qualifier = optionsQualifier[0].value;
        }
        this.setState({
            destinationSerialShow: false,
            destinationSerialRequired: false,
            destinationLotShow: false,
            destinationLotRequired: false,
            destinationQtyDisabled: false
        });
        if (event.target.value.ItemId) {
            inventoryservices.getItemOptions(this.state.destinationItemData.customerId, event.target.value.ItemId).then((response) => {
                this.setState({
                    destinationItemData: Object.assign(
                        this.state.destinationItemData,
                        response
                    )
                });
                if (response.SerialOptions == 0) {
                    this.setState({
                        destinationSerialShow: false
                    });
                } else if (response.SerialOptions == 1 || response.SerialOptions == 2) {
                    this.setState({
                        destinationSerialShow: true,
                        destinationItemData: Object.assign(
                            this.state.destinationItemData,
                            {
                                SerialNumber: this.state.sourceItemData.SerialNumber
                            }
                        )
                    });
                } else if (response.SerialOptions == 3) {
                    this.setState({
                        destinationSerialRequired: true,
                        destinationSerialShow: true,
                        destinationItemData: Object.assign(
                            this.state.destinationItemData,
                            {
                                SerialNumber: this.state.sourceItemData.SerialNumber
                            }
                        )
                    });
                } else if (response.SerialOptions == 4) {
                    this.setState({
                        destinationSerialRequired: true,
                        destinationSerialShow: true,
                        destinationQtyDisabled: true,
                        destinationItemData: Object.assign(
                            this.state.destinationItemData,
                            {
                                PrimaryAvailable: 1,
                                SerialNumber: this.state.sourceItemData.SerialNumber
                            }
                        )
                    });
                }
                if (response.LotOptions == 0) {
                    this.setState({
                        destinationLotShow: false
                    });
                } else if (response.LotOptions == 1) {
                    this.setState({
                        destinationLotShow: true,
                        destinationItemData: Object.assign(
                            this.state.destinationItemData,
                            {
                                LotNumber: this.state.sourceItemData.LotNumber
                            }
                        )
                    });
                } else if (response.LotOptions == 2) {
                    this.setState({
                        destinationLotRequired: true,
                        destinationLotShow: true,
                        destinationItemData: Object.assign(
                            this.state.destinationItemData,
                            {
                                LotNumber: this.state.sourceItemData.LotNumber
                            }
                        )
                    });
                }

                if (response.CostOptions == 0) {
                    this.setState({
                        destinationCostShow: false
                    });
                } else if (response.CostOptions == 1) {
                    this.setState({
                        destinationCostShow: true,
                        destinationItemData: Object.assign(
                            this.state.destinationItemData,
                            {
                                Cost: this.state.sourceItemData.Cost
                            }
                        )
                    });
                } else if (response.CostOptions == 2) {
                    this.setState({
                        destinationCostRequired: true,
                        destinationCostShow: true,
                        destinationItemData: Object.assign(
                            this.state.destinationItemData,
                            {
                                Cost: this.state.sourceItemData.Cost
                            }
                        )
                    });
                }

                if(response.ExpirationOptions == 0){
                    this.setState({
                        destinationExpDateShow: false
                    });
                }else if(response.ExpirationOptions == 1){
                    this.setState({
                        destinationExpDateShow: true,
                        destinationItemData: Object.assign(
                            this.state.destinationItemData,
                            {
                                ExpirationDate: this.state.sourceItemData.ExpirationDate
                            }
                        )
                    });
                }else if (response.ExpirationOptions == 2) {
                    this.setState({
                        destinationExpDateRequired: true,
                        destinationExpDateShow: true,
                        destinationItemData: Object.assign(
                            this.state.destinationItemData,
                            {
                                ExpirationDate: this.state.sourceItemData.ExpirationDate
                            }
                        )
                    });
                }

                // override condition for hide serial number for multiple selection
                if (this.state.selectedItemCount > 1) {
                    this.setState({
                        destinationSerialShow: false,
                        destinationSerialRequired: false
                    });
                }
            }).finally(() => {
                kendo.ui.progress($('.loading-section'), false);
                this.checkForSameCustSkuQualifier('Qualifier');
            });
        } else {
            kendo.ui.progress($('.loading-section'), false);
        }
        this.setState({
            optionsQualifier: optionsQualifier
        });


    }

    onChangeSourceInput = (event) => {
        this.setState({
            sourceItemData: Object.assign(
                this.state.sourceItemData,
                {
                    [event.target.name]: event.target.value
                }
            )
        });
    }

    locationDropDownChange = (event) => {
        this.setState({
            destinationItemData: Object.assign(
                this.state.destinationItemData,
                {
                    LocationId: event.target.value.Id
                }
            )
        });
    }

    onChangeDestinationInput = (event) => {
        var Name = event.target.name;
        this.setState({
            destinationItemData: Object.assign(
                this.state.destinationItemData,
                {
                    [event.target.name]: event.target.value
                }
            )
        }, this.checkForSameCustSkuQualifier(Name));

    }
    onNormalInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            isQuantityMaxValid: true
        });
        if (event.target.value > this.state.sourceItemData.MaxQuantity) {
            this.setState({
                isQuantityMaxValid: false
            });
        }
    }

    exitTransferInventoryModal = () => {
        this.clearTransfer();
        // set time out to wait setState above clearTranfer() method to solve warning [ setstate on unmount component]
        setTimeout(() => {
            this.props.onCloseTransferInventoryModal();
        }, 200);
    }
    render() {
        return (
            <WmsModel
                id='transferInventory'
                title={this.translate('Label_Customer_Transfer')}
                width={850}
                height={window.innerHeight - 80}
                onCloseFunc={this.props.onCloseTransferInventoryModal}
                isOpen={this.props.isTransferModelOpen}
                customClass='wms-model-new-ui-wrapper'
                class="customer-transfer-model">
                    <div className="wms-inventory-model-wrapper model-content-wrapper">
                        <div className="inventory-transfer-source">
                            <WmsContainer title={this.translate('Label_Transfer_Source')}>
                                <WmsInput
                                    id='SourceWarehouse'
                                    inputName='Warehouse'
                                    wmsSelector={this.state.Selector + 'SourceWarehouse'}
                                    onChangeFunc={(e) => function () { }}
                                    label={this.translate('Window_Title_Label_Warehouse')}
                                    disabled={true}
                                    value={this.props.warehouseObj.name} />

                                <WmsInput
                                    id='SourceCustomer'
                                    inputName='Customer'
                                    wmsSelector={this.state.Selector + 'SourceCustomer'}
                                    onChangeFunc={(e) => function () { }}
                                    label={this.translate('Label_Customer_Text')}
                                    disabled={true}
                                    value={this.state.sourceItemData.Customer} />

                                <WmsInput
                                    id='SourceSku'
                                    inputName='Sku'
                                    wmsSelector={this.state.Selector + 'SourceSku'}
                                    onChangeFunc={(e) => function () { }}
                                    label={this.translate('Label_Sku')}
                                    disabled={true}
                                    value={this.state.sourceItemData.Sku} />

                                <div className={this.state.sourceItemData.Qualifier ? '' : 'wms-hide'}>
                                    <WmsInput
                                        id='SourceQualifier'
                                        inputName='Qualifier'
                                        wmsSelector={this.state.Selector + 'SourceQualifier'}
                                        onChangeFunc={(e) => function () { }}
                                        label={this.translate('Label_Qualifier')}
                                        disabled={true}
                                        value={this.state.sourceItemData.Qualifier ? this.state.sourceItemData.Qualifier : "-"} />
                                </div>

                                <div className="description_box">
                                    <WmsInput
                                        id='SourceDescription'
                                        inputName='Description'
                                        wmsSelector={this.state.Selector + 'SourceDescription'}
                                        onChangeFunc={(e) => function () { }}
                                        label={this.translate('Label_Description')}
                                        disabled={true}
                                        value={this.state.sourceItemData.Description ? this.state.sourceItemData.Description : "-"} />
                                </div>

                                <div className="wms-mb-20">
                                    <div className="tooltip-div-group">
                                        <WmsInput
                                            id='SourceTotalTransferedQuantity'
                                            valueType={'number'}
                                            parentClassName={'form_group input-required'}
                                            inputName='totalTransferedQuantity'
                                            wmsSelector={this.state.Selector + 'SourceTotalTransferedQuantity'}
                                            onChangeFunc={this.onNormalInputChange}
                                            label={this.translate('Label_Quantity')}
                                            disabled={(this.state.selectedItemCount == 1 && this.state.sourceItemData.Options.SerialOptions !== 4) ? false : true}
                                            value={this.state.totalTransferedQuantity}
                                            regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}/>
                                        {!this.state.isQuantityMaxValid &&
                                            <WmsToolTip tooltipText={"You can't enter Quantity more than " + this.state.sourceItemData.MaxQuantity}>
                                                <i className="fa fa-exclamation-triangle wms-error has-tooltip-custom" ></i>
                                            </WmsToolTip>
                                        }
                                    </div>
                                    <WmsInput
                                        id='SourcePrimaryUOM'
                                        inputName='PrimaryUOM'
                                        wmsSelector={this.state.Selector + 'SourcePrimaryUOM'}
                                        onChangeFunc={(e) => function () { }}
                                        label={this.translate('Label_Primary_Unit_Type')}
                                        disabled={true}
                                        value={this.state.sourceItemData.PrimaryUom} />
                                </div>
                                <div className="wms-mb-20">
                                    <div className={this.state.sourceItemData.LotNumber ? '' : 'wms-hide'}>
                                        <WmsInput
                                            id='SourceLot'
                                            inputName='Lot'
                                            wmsSelector={this.state.Selector + 'SourceLot'}
                                            onChangeFunc={(e) => function () { }}
                                            label={this.translate('Label_Lot')}
                                            disabled={true}
                                            value={this.state.sourceItemData.LotNumber} />
                                    </div>
                                    <div className={this.state.sourceItemData.ExpirationDate && this.state.selectedItemCount == 1 ? '' : 'wms-hide'}>
                                        <WmsInput
                                            id='SourceExpDate'
                                            inputName='ExpDate'
                                            wmsSelector={this.state.Selector + 'SourceExpDate'}
                                            onChangeFunc={(e) => function () { }}
                                            label={this.translate('Label_Expiration_Date')}
                                            disabled={true}
                                            value={this.state.sourceItemData.ExpirationDate} />
                                </div>
                                <div className={this.state.sourceItemData.Cost && this.state.selectedItemCount == 1 ? '' : 'wms-hide'}>
                                        <WmsInput
                                            id='SourceCost'
                                            inputName='Cost'
                                            wmsSelector={this.state.Selector + 'SourceCost'}
                                            onChangeFunc={(e) => function () { }}
                                            label={this.translate('Label_Cost')}
                                            disabled={true}
                                            value={this.state.sourceItemData.Cost} />
                                </div>
                                    <div className={this.state.sourceItemData.SerialNumber && this.state.selectedItemCount == 1 ? '' : 'wms-hide'}>
                                        <WmsInput
                                            id='SourceSerial'
                                            inputName='Serial'
                                            wmsSelector={this.state.Selector + 'SourceSerial'}
                                            onChangeFunc={(e) => function () { }}
                                            label={this.translate('Label_Serial')}
                                            disabled={true}
                                            value={this.state.sourceItemData.SerialNumber} />
                                    </div>
                                </div>

                                <div className="wms-mb-20">
                                    <div className={this.state.sourceItemData.DesiredMovableUnitLabel ? '' : 'wms-hide'}>
                                        <WmsInput
                                            id='SourceMovableUnitLabel'
                                            inputName='MovableUnitLabel'
                                            wmsSelector={this.state.Selector + 'SourceMovableUnit'}
                                            onChangeFunc={(e) => function () { }}
                                            label={this.translate('Label_Pallet')}
                                            disabled={true}
                                            value={this.state.sourceItemData.DesiredMovableUnitLabel ? this.state.sourceItemData.DesiredMovableUnitLabel : "-"} />
                                    </div>
                                    <div className={this.state.sourceItemData.LocationName && this.state.selectedItemCount == 1 ? '' : 'wms-hide'}>
                                        <WmsInput
                                            id='SourceLocation'
                                            inputName='Location'
                                            wmsSelector={this.state.Selector + 'SourceLocation'}
                                            onChangeFunc={(e) => function () { }}
                                            label={this.translate('Label_Location')}
                                            disabled={true}
                                            value={this.state.sourceItemData.LocationName ? this.state.sourceItemData.LocationName : "-"} />
                                    </div>
                                </div>
                            </WmsContainer>
                        </div>
                        <div className="inventory-transfer-destination">
                            <WmsContainer title={this.translate('Label_Transfer_Destination')}>
                                <WmsInput
                                    id='DestinationWarehouse'
                                    inputName='Warehouse'
                                    wmsSelector={this.state.Selector + 'DestinationWarehouse'}
                                    onChangeFunc={(e) => function () { }}
                                    label={this.translate('Window_Title_Label_Warehouse')}
                                    disabled={true}
                                    value={this.props.warehouseObj.name} />
                                <WmsDropdown
                                    id="DestinationCustomer"
                                    name="IndexOptionsCustomerId"
                                    label={this.state.CustomLabels.Label_Customer}
                                    wmsSelector={this.state.Selector + "DestinationCustomer"}
                                    onChangeFunc={this.handleCustomerChange}
                                    options={this.state.optionsCustomer}
                                    blankFirstOption={true}
                                    textField='name'
                                    required={true}
                                    valueField='id'
                                    value={this.state.destinationItemData.customerId}
                                />
                                <div className="tooltip-div-group">
                                    {this.state.destinationItemData.customerId ?
                                        <WmsDropdownList
                                            id="DestinationDestinationSku"
                                            name="destinationSku"
                                            required={this.state.destinationItemData.sku == "" ? true : false}
                                            optionsLabel={'Unspecified'}
                                            value={this.state.destinationItemData.sku}
                                            label={this.state.CustomLabels.Label_Sku}
                                            wmsSelector={this.state.Selector + "destinationSku"}
                                            onChangeFunc={this.skuDropdownChangeEvent}
                                            textField="Sku"
                                            valueField="ItemId"
                                            template="<table><tr><td style='width:200px;display: block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;'>${ Sku }</td></tr></table>"
                                            virtualObj={{
                                                itemHeight: 26
                                            }}
                                            dataSourceObj={{
                                                pageSize: 40,
                                                serverPaging: true,
                                                serverFiltering: true,
                                                type: "aspnetmvc-ajax",
                                                transport: {
                                                    read: {
                                                        url: "/WebUI/Inventory/ManageInventory/SkuDropdownRead",
                                                        type: "POST",
                                                        data: {
                                                            customerId: this.state.destinationItemData.customerId
                                                        }
                                                    },
                                                },
                                                schema: {
                                                    data: "Data",
                                                    total: "Total"
                                                }
                                            }}
                                        />
                                        :
                                        <div className="blank-div-height48"></div>

                                    }
                                    {this.state.showSameDataTooltip &&
                                        <WmsToolTip tooltipText={"Should not be same Customer, Sku and Qualifier"}>
                                            <i className="fa fa-exclamation-triangle wms-error has-tooltip-custom" ></i>
                                        </WmsToolTip>
                                    }
                                </div>
                                <div className={this.state.optionsQualifier.length <= 0 ? 'wms-hide' : ''}>
                                    <WmsDropdown
                                        id='DestinationQualifier'
                                        name='Qualifier'
                                        label={this.translate('Label_Qualifier')}
                                        wmsSelector={this.state.Selector + "DestinationQualifier"}
                                        options={this.state.optionsQualifier}
                                        valueField='value'
                                        textField='description'
                                        onChangeFunc={this.onChangeDestinationInput}
                                        value={this.state.destinationItemData.Qualifier} />
                                </div>
                                <div className={'loading-section wms-hide ' + (this.state.destinationItemData.customerId && this.state.destinationItemData.sku ? 'wms-show' : '')}>
                                    <div className="description_box">
                                        <WmsInput
                                            id='DestinationDescription'
                                            inputName='Description'
                                            wmsSelector={this.state.Selector + 'DestinationDescription'}
                                            onChangeFunc={(e) => function () { }}
                                            label={this.translate('Label_Description')}
                                            disabled={true}
                                            value={this.state.destinationItemData.SkuDescription} />
                                    </div>
                                    <div>
                                        <div className="wms-mb-20">
                                            <WmsInput
                                                id='DestinationPrimaryAvailable'
                                                inputName='PrimaryAvailable'
                                                valueType={'number'}
                                                required={true}
                                                disabled={this.state.destinationQtyDisabled}
                                                wmsSelector={this.state.Selector + 'DestinationQuantity'}
                                                onChangeFunc={this.onChangeDestinationInput}
                                                label={this.translate('Label_Quantity')}
                                                value={this.state.destinationItemData.PrimaryAvailable}
                                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}/>
                                            <WmsInput
                                                id='DestinationPrimaryUOM'
                                                inputName='PrimaryUOM'
                                                onChangeFunc={this.onChangeDestinationInput}
                                                wmsSelector={this.state.Selector + 'DestinationPrimaryUOM'}
                                                label={this.translate('Label_Primary_Unit_Type')}
                                                disabled={true}
                                                value={this.state.destinationItemData.PrimaryUom} />
                                        </div>
                                        <div className="wms-mb-20">
                                            <div className={'wms-hide ' + (this.state.destinationLotShow ? 'wms-show' : '')}>
                                                <WmsInput
                                                    id='DestinationLotNumber'
                                                    inputName='LotNumber'
                                                    required={this.state.destinationLotRequired}
                                                    onChangeFunc={this.onChangeDestinationInput}
                                                    wmsSelector={this.state.Selector + 'DestinationLot'}
                                                    label={this.translate('Label_Lot')}
                                                    value={this.state.destinationItemData.LotNumber} />
                                            </div>
                                            <div className={this.state.destinationItemData.ExpirationDate && this.state.selectedItemCount == 1 ? '' : 'wms-hide'}>
                                                <WmsInput
                                                    id='DestinationExpDate'
                                                    inputName='ExpDate'
                                                    wmsSelector={this.state.Selector + 'DestinationExpDate'}
                                                    onChangeFunc={(e) => function () { }}
                                                    label={this.translate('Label_Expiration_Date')}
                                                    disabled={true}
                                                    value={this.state.destinationItemData.ExpirationDate} />
                                            </div>
                                            <div className={this.state.destinationCostShow && this.state.selectedItemCount == 1 ? '' : 'wms-hide'}>
                                                <WmsInput
                                                        id='DestinationCost'
                                                        inputName='Cost'
                                                        required={this.state.destinationCostRequired}
                                                        wmsSelector={this.state.Selector + 'DestinationCost'}
                                                        onChangeFunc={this.onChangeDestinationInput}
                                                        label={this.translate('Label_Cost')}
                                                        value={this.state.destinationItemData.Cost} />

                                            </div>
                                            <div className={'wms-hide ' + (this.state.destinationSerialShow ? 'wms-show' : '')}>
                                                <WmsInput
                                                    id='DestinationSerialNumber'
                                                    inputName='SerialNumber'
                                                    required={this.state.destinationSerialRequired}
                                                    wmsSelector={this.state.Selector + 'DestinationSerial'}
                                                    onChangeFunc={this.onChangeDestinationInput}
                                                    label={this.translate('Label_Serial')}
                                                    value={this.state.destinationItemData.SerialNumber} />
                                            </div>
                                        </div>
                                        <div className="wms-mb-20">

                                            <div className={this.state.hideMovableUnit ? 'wms-hide' : ''}>
                                                <WmsInput
                                                    id='DestinationMovableUnitLabel'
                                                    inputName='MovableUnitLabel'
                                                    wmsSelector={this.state.Selector + 'DestinationMovableUnitLabel'}
                                                    onChangeFunc={this.onChangeDestinationInput}
                                                    label={this.translate('Label_Pallet')}
                                                    // disabled={this.state.sourceItemData.MovableUnitId == 0 ? true : false}
                                                    value={this.state.destinationItemData.MovableUnitLabel}
                                                    required={this.state.requiredMovableUnit}
                                                />
                                            </div>
                                            <div className={this.state.hideLocationDropdown ? 'wms-hide' : ''}>
                                              { !this.state.hideLocationDropdown ?
                                                    <WmsDropdownList
                                                        id="DestinationLocationId"
                                                        name="LocationId"
                                                        label={this.translate('Label_Location')}
                                                        wmsSelector={this.state.Selector + "DestinationLocation"}
                                                        onChangeFunc={this.locationDropDownChange}
                                                        textField='Name'
                                                        valueField='Id'
                                                        required={this.state.requiredLocationDropdown}
                                                        // value={this.state.destinationItemData.LocationId}
                                                        optionsLabel={{
                                                            Name: this.translate('Label_Select_Location'),
                                                            Id: 0
                                                        }}
                                                        virtualObj={{
                                                            itemHeight: 26
                                                        }}
                                                        dataSourceObj={{
                                                            pageSize: 40,
                                                            serverPaging: true,
                                                            serverFiltering: true,
                                                            type: "aspnetmvc-ajax",
                                                            transport: {
                                                                read: {
                                                                    url: "/WebUI/Inventory/ManageInventory/LocationDropdownRead",
                                                                    type: "POST",
                                                                    data: {
                                                                        facilityId: this.props.warehouseObj.id
                                                                    }
                                                                },
                                                            },
                                                            schema: {
                                                                data: "Data",
                                                                total: "Total"
                                                            }
                                                        }}
                                                    />
                                                  : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </WmsContainer>
                        </div>
                    </div>

                    <div className="save-filter-button-wrapper footer-btn-holder">

                    <WmsButton
                        label={this.translate("Label_Close")}
                        wmsSelector={this.state.Selector + "ExitButton"}
                        onClickFunc={this.exitTransferInventoryModal}
                        icon={'fa-times-circle-o'}
                        buttonType="orange"
                    />
                    <WmsButton
                        label={this.translate('Label_Clear_Filters')}
                        buttonType='orange'
                        wmsSelector={this.state.Selector + 'ClearSearchButton'}
                        onClickFunc={this.clearTransfer}
                        icon='fa-minus' />
                    <WmsButton
                        label={this.translate('Label_Transfer')}
                        wmsSelector={this.state.Selector + "TransferButton"}
                        onClickFunc={this.transferCustomer}
                        icon={'fa-plus'}
                        disabled={(!this.state.isQuantityMaxValid ||
                            /*(this.state.optionsQualifier.length > 0 && !this.state.destinationItemData.Qualifier) ||*/
                            !this.state.destinationItemData.PrimaryAvailable ||
                            (this.state.destinationLotRequired && !this.state.destinationItemData.LotNumber) ||
                            (this.state.destinationSerialRequired && !this.state.destinationItemData.SerialNumber) ||
                            (this.state.requiredLocationDropdown && !this.state.destinationItemData.LocationId)) ? true : false ||
                            (this.state.showSameDataTooltip)
                        }
                        loading={this.state.transferInventoryLoader} />


                    </div>
                <WmsConfirmModel
                    id="qtyConfirmation"
                    isOpen={this.state.isQtyConfirmation}
                    width={500}
                    height={140}
                    firstBtnSelector={"transferCustomerContinueButton"}
                    firstBtnLabel={this.translate('RoleManager_Label_Continue')}
                    firstBtnIcon={"fa fa-check-square-o"}
                    firstBtnOnClickFunc={this.continueToTransferCustomer}
                    thirdBtnLabel={this.translate('Label_Cancel')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText={
                        "You are transferring " + this.state.totalTransferedQuantity + " " + this.state.sourceItemData.PrimaryUom + " of " + this.state.sourceItemData.Customer + " inventory to " + this.state.destinationItemData.PrimaryAvailable + " " + this.state.destinationItemData.PrimaryUom + " of " + this.state.destinationCustomerObj.name + " inventory. Do you want to continue?"
                    }
                    sawCheckBox={false}
                    isCallToFront={false}
                    onCloseFunc={this.onCloseQtyConfirmation}
                />
            </WmsModel>
        );
    }
}