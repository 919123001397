/* eslint-disable no-undef */
import * as GlobalService from "../react/global_services";
var isFirstTimeOpen = true;
var keyName = "WebhookNotification";
var metaDataWebhookNotificationObject = {
    WebhookNotification: {
        filterData: {},
        gridConfig: {},
    },
};

// call api for get metaData of WebhookNotification is it's open first time
export const getWebhookNotificationClientMetaData = function () {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response !== "") {
                    Object.assign(metaDataWebhookNotificationObject[keyName], JSON.parse(response));
                }
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                return false;
            }
        );
    }
};

export const setWebhookNotificationFilterMetaData = function (filterData) {
    metaDataWebhookNotificationObject[keyName]["filterData"] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataWebhookNotificationObject[keyName]));
};

export const getWebhookNotificationFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataWebhookNotificationObject[keyName]["filterData"])) {
        return metaDataWebhookNotificationObject[keyName]["filterData"];
    } else {
        return "";
    }
};
export const setWebhookNotificationGridOptionInMetaData = function (gridConfig) {
    metaDataWebhookNotificationObject[keyName]["gridConfig"] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataWebhookNotificationObject[keyName]));
};
export const getWebhookNotificationGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataWebhookNotificationObject[keyName]["gridConfig"])) {
        return metaDataWebhookNotificationObject[keyName]["gridConfig"];
    } else {
        return "";
    }
};

export const clearWebhookNotificationFilterMetaData = function () {
    metaDataWebhookNotificationObject[keyName]["filterData"] = {};
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataWebhookNotificationObject[keyName]));
};
