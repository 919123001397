var axios = require('axios');

export function getThreePlOptions() {
    return  axios.get('/WebUI/Shared/GlobalServices/ThreePlOptions').then(function (response) {
        return response;
    });
}
export function saveThreePlOptions(paramObj) {
    return  axios.put('/WebUI/Shared/GlobalServices/ThreePlOptions',paramObj).then(function (response) {
        return response;
    });
}