import React from 'react';
import PropTypes from 'prop-types';
import styles from './WmsConfirmModel.scss';

import WmsButton from '../WmsButton/WmsButton.jsx';
import WmsCheckBox from '../WmsCheckBox/WmsCheckBox.jsx';

class WmsConfirmModel extends React.PureComponent {

    constructor(props) {

        super(props);

        
        this.$rootScope = window
        this.isCallToFront = this.props.isCallToFront
        this.state = {
            isOpen: false,
            checkbox: false,
            isLoading: false
        }
    }

    componentDidMount() {
        $("#" + this.props.id).kendoWindow({
            visible: false,
            cache: false,
            modal: true,            
            width: "800",
            height: 'auto',
            position: {
                top: 100,
                left: "calc((100% - 800px)/2)"
            }
        });
    }

    componentWillUnmount() {
        $("#" + this.props.id).parent().remove();
    }

    //for remove old reference of this model from dom
    componentWillMount() {
        if ($("#" + this.props.id).length > 0) {
            $("#" + this.props.id).parent('.k-window').remove();
        }
    }
    componentWillReceiveProps(nextprops) {

        if (nextprops.isOpen == true && this.state.isOpen == false) {

            this.setState({
                isOpen: true,
                checkbox: false
            });

            let GetWindow = $("#" + this.props.id).data("kendoWindow");
            if (GetWindow) {
                GetWindow.setOptions({
                    title: false,
                    width: "800",
                    height: 'auto',
                    position: {
                        top: 100,
                        left: "calc((100% - 800px)/2)"
                    },
                    open: (e) => {
                        let winObject = $("#" + this.props.id).data("kendoWindow");
                        winObject.wrapper.addClass("areYouSureZindex confirmation-wrapper");
                    },
                    close: (e) => {
                        this.setState({ isOpen: false });
                        if (this.isCallToFront == true) {
                            if (typeof this.$rootScope.currentWindow != "undefined") {
                                setTimeout(() => {
                                    // this.$rootScope.currentWindow.toFront();
                                }, 500);
                            }
                            setTimeout(() => {
                                this.$rootScope.adjustZIndexOfSidebar('close');
                                GetWindow.wrapper.removeClass("areYouSureZindex confirmation-wrapper");
                            }, 500);
                        }
                        nextprops.onCloseFunc();
                    }
                });
                GetWindow.open();
                GetWindow.refresh();
                this.$rootScope.adjustZIndexOfSidebar('open');
            }
        } else if (nextprops.isOpen == false && this.state.isOpen == true) {
            this.setState({ isLoading: false });
            $("#" + this.props.id).data('kendoWindow').close();
        }
        if (nextprops.isLoadingStop == true) {
            this.setState({ isLoading: false });
        }
    }

    handleChange = () => {
        this.setState({ checkbox: !this.state.checkbox });
    }

    firstFunctionClick = () => {
        // $("#"+this.props.id).data('kendoWindow').close();
        this.setState({ isLoading: true });
        if (this.props.isCallToFront == false) {
            this.isCallToFront = false;
        }
        this.props.firstBtnOnClickFunc();
    }

    secondFunctionClick = () => {
        // $("#"+this.props.id).data('kendoWindow').close();
        if (this.props.isCallToFront == false) {
            this.isCallToFront = false;
        }
        this.props.secondFunctionClick();
    }

    closeModel = () => {

        if (this.props.isCallToFront == false) {
            this.isCallToFront = false;
            $("#" + this.props.id).data('kendoWindow').close('pass_param');
        } else {
            $("#" + this.props.id).data('kendoWindow').close();
        }
        this.setState({ isLoading: false });
    }

    render() {
        return (
            <div className={"confirmation-model wms-warning-notification " + this.props.extraWrapperClass} id={this.props.id}>
                <div className="wms-notification-warning-header wms-warning-header-color">{this.props.type}</div>
                <div className="wms-area-content">
                    {!this.props.children && (
                    <div className="note">
                        {/* <label className="wms-form-label">{this.props.conFormModelText}</label> */}
                        <div dangerouslySetInnerHTML={{ __html: this.props.conFormModelText }} />
                    </div>
                    )}
                    {this.props.children && (
                        this.props.children
                    )}
                    {this.props.sawCheckBox == true &&
                        <WmsCheckBox
                            id={'confirmationCheckbox'}
                            name={'confirmationCheckbox'}
                            onChangeFunc={this.handleChange}
                            label={this.props.checkboxLabel}
                            checked={this.state.checkbox == true}
                            value={this.state.checkbox}
                            wmsSelector='confimCheckbox' />
                    }

                </div>
                <div className="wms-notification-warning-toolbar wms-toolbar">
                        {this.props.isThirdBtnSaw == true &&
                            <div className={this.props.isSecondBtnSaw == true ? "width20" : 'wms_half_part'}>
                                <WmsButton
                                    label={this.props.thirdBtnLabel}
                                    onClickFunc={this.closeModel}
                                    wmsSelector={this.props.thirdBtnSelector}
                                    icon={(this.props.thirdBtnIcon.indexOf("fa-times-circle") != -1) ? 'fa-ban':this.props.thirdBtnIcon}
                                    buttonType={this.props.thirdButtonType}
                                />
                            </div>
                        }
                        {this.props.isSecondBtnSaw == true &&
                            <div className="width45" >
                                <WmsButton
                                    label={this.props.secondBtnLabel}
                                    onClickFunc={this.secondFunctionClick}
                                    wmsSelector={this.props.secondBtnSelector}
                                    icon={(this.props.secondBtnIcon.indexOf("fa-times-circle") != -1) ? 'fa-ban':this.props.secondBtnIcon}
                                    buttonType={this.props.secondButtonType}
                                />
                            </div>
                        }
                        {this.props.isFirstBtnSaw == true &&
                            <div className={
                                (this.props.isSecondBtnSaw == false && this.props.isThirdBtnSaw == false) ? '' :
                                (this.props.isSecondBtnSaw == true ? "width35" : 'wms_half_part')
                            }>
                                <WmsButton
                                    label={this.props.firstBtnLabel}
                                    wmsSelector={this.props.firstBtnSelector}
                                    onClickFunc={this.firstFunctionClick}
                                    icon={(this.props.firstBtnIcon.indexOf("fa-check-square") != -1) ? 'fa-check-circle':this.props.firstBtnIcon}
                                    disabled={this.props.sawCheckBox == true && this.state.checkbox == false}
                                    buttonType={this.props.firstButtonType}
                                    loading={this.state.isLoading}

                                />
                            </div>
                        }
                    </div>
            </div>
        );
    }
}

WmsConfirmModel.propTypes = {
    id: PropTypes.string.isRequired, // this should write the id to the input
    isOpen: PropTypes.bool.isRequired, // open and close modal
    isFirstBtnSaw: PropTypes.bool.isRequired,//hide & saw first button
    isSecondBtnSaw: PropTypes.bool.isRequired,//hide & saw second button
    isThirdBtnSaw: PropTypes.bool.isRequired,//hide & saw third button
    sawCheckBox: PropTypes.bool.isRequired,// to set true to check box for getting confirmation
    firstButtonType: PropTypes.string.isRequired,//set color of button using its type
    secondButtonType: PropTypes.string,//set color of button using its type
    thirdButtonType: PropTypes.string.isRequired,//set color of button using its type
    onCloseFunc: PropTypes.func.isRequired, // function to specify when close modal
    firstBtnLabel: PropTypes.string.isRequired, // first button label
    firstBtnOnClickFunc: PropTypes.func.isRequired, // first button click function
    secondFunctionClick: PropTypes.func.isRequired, // second button click function
    secondBtnLabel: PropTypes.string, // second button label
    thirdBtnLabel: PropTypes.string.isRequired, // second button label
    conFormModelText: PropTypes.string.isRequired, // first button click function
    checkboxLabel: PropTypes.string, // checkbox label
    firstBtnIcon: PropTypes.string,// first button icon
    secondBtnIcon: PropTypes.string,// second button icon
    thirdBtnIcon: PropTypes.string,// second button icon
    extraWrapperClass: PropTypes.string,// second button icon
    type: PropTypes.string,// second button icon
    isLoadingStop: PropTypes.bool //for hide loading button when open multiple warning modal
}

WmsConfirmModel.defaultProps = {
    id: "WmsConfirmModel",
    type: 'Warning',
    firstButtonType: 'green',
    secondBtnLabel: '',
    secondButtonType: 'yellow',
    thirdButtonType: 'orange',
    firstBtnSelector:'confirmationbutton1',
    secondBtnSelector:'confirmationbutton2',
    thirdBtnSelector:'confirmationbutton3',
    extraWrapperClass:'',
    secondFunctionClick: (e) => function () { },
    isFirstBtnSaw: true,
    isSecondBtnSaw: false,
    isThirdBtnSaw: true,
    sawCheckBox: true,
    isCallToFront: true
}

export default WmsConfirmModel;
