import React, { Component } from 'react';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsGrid from '../../components/WmsGrid/WmsGrid.jsx';

export class ManageReceiptCustomReceiptInfo extends Component {
    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.state = {
            gridName: 'GridCustomReceiptInfo',
            enableDeleteButton: false,
            isRequiredField: false,
            localKendoGrid: null
        }
    }

    onDataBound = () => {
        var grid = $("." + this.props.windowId + ' #' + this.state.gridName).data('kendoGrid');
        var gridData = grid.dataSource.view();
        var nameColumnIndex = grid.wrapper.find(".k-grid-header [data-field=" + "Name" + "]").index();
        var valueColumnIndex = grid.wrapper.find(".k-grid-header [data-field=" + "Value" + "]").index();

        for (var i = 0; i < gridData.length; i++) {
            var row = grid.table.find("[data-uid='" + gridData[i].uid + "']");
            var nameCell = row.children().eq(nameColumnIndex);
            var valueCell = row.children().eq(valueColumnIndex);
            if (gridData[i].Required) {
                row.addClass("wms-required-grid-row");
                nameCell.addClass("wms-required-grid");
                valueCell.addClass("wms-required-grid-cell");
                row.css('font-style', 'italic');
            }
            if (gridData[i].Required && !gridData[i].Value) {
                nameCell.addClass("wms-required-grid-border");
            }
        }
    }
    clickDeleteRow = () => {

        let savedElementObj = Object.assign([], this.props.sharedReceiptData.ReceiptSE);
        savedElementObj = savedElementObj.filter((element) => {
            if (element.Name != this.state.localKendoGrid.getKeys()[0]) { return element; }
        });
        let ReceiptData = Object.assign({}, this.props.sharedReceiptData);
        ReceiptData.ReceiptSE = savedElementObj;
        this.props.onReceiptOptionSet(ReceiptData);
        this.props.onCustomReceiptInfoSave(savedElementObj);
        this.setState({ enableDeleteButton: false });
    }

    onSelectRow = (param_kendoGrid) => {
        this.setState({
            enableDeleteButton: param_kendoGrid.getNumberOfSelections() == 1 ? true : false,
            isRequiredField: (param_kendoGrid.getNumberOfSelections() == 1 && param_kendoGrid.valueExists("Required", true)) ? true : false,
            localKendoGrid: param_kendoGrid
        });
    }

    onSave = (e) => {
        let savedElementObj = this.props.sharedReceiptData.ReceiptSE;
        for (var x = 0; x < savedElementObj.length; x++) {
            if (savedElementObj[x].Name == e.model.Name) {
                savedElementObj[x].Value = e.values.Value;
            }
        }
        let ReceiptData = Object.assign({}, this.props.sharedReceiptData);
        ReceiptData.ReceiptSE = savedElementObj;
        this.props.onReceiptOptionSet(ReceiptData);
        this.props.onCustomReceiptInfoSave(savedElementObj);
    }
    clickEventOfContextMenu = (obj) => {
        if (obj.wmsid == 'delete-order-info') {
            this.clickDeleteRow();
        }
    }

    render() {
        var EnterValueText = "Enter a value";
        return (
            <div className="receipt-custom-receipt-info-section" ref={this.props.divRefs}>
                <WmsCollapse id={'collapseReceiptCustomInfo'} headerLabel={this.translate('Label_Custom_Receipt_Info')} >

                    <WmsGrid
                        wmsSelector={this.state.gridName}
                        staticGridData={this.props.sharedReceiptData.ReceiptSE}
                        gridKeySelection={'Name'}
                        gridName={this.state.gridName}
                        virtual={false}
                        id={this.state.gridName}
                        onSelection={this.onSelectRow}
                        isAlignIndexesByProperty={true}
                        columns={[
                            {
                                field: 'Name',
                                title: "Name",
                                width: 180,
                                lockable: false,
                                sortable: false,
                                filterable: false,
                                template: "#= Required === true ? Name + '*' : Name #"
                            },
                            {
                                field: 'Value',
                                title: "Value",
                                width: 180,
                                lockable: false,
                                sortable: false,
                                filterable: false,
                                template: "#= Value === null && Required === true  ? '<i class=\"wms-required-grid\">" + EnterValueText + "*</i>' : Value === null && Required === false ? '<i>" + EnterValueText + "</i>' : Value === '' && Required === true ? '<i class=\"wms-required-grid\">" + EnterValueText + "*</i>' : Value === '' && Required === false ? '<i>" + EnterValueText + "</i>' : Value #"
                            }
                        ]}
                        schema={{
                            model: {
                                fields: {
                                    Name: {
                                        type: 'string',
                                        editable: false
                                    },
                                    Value: {
                                        type: 'string'
                                    },
                                    Required: {
                                        type: 'boolean'
                                    }
                                }
                            }
                        }}
                        showGridSummary={false}
                        windowId={this.props.windowId}
                        shawCheckbox={false}
                        showGridResult={false}
                        showSortColumns={false}
                        showColumnFilter={false}
                        gridEditable={true}
                        onDataBound={this.onDataBound}
                        onEdit={() => null}
                        onGridSave={this.onSave}
                        clickEventOfContextMenu={this.clickEventOfContextMenu}
                        menu={[{
                            "name": this.translate('Label_Add_New_Custom_Field'),
                            "value": [],
                            "disabled": false,
                            "icon": "fa-plus",
                            "color": "green",
                            "wmsSelector": 'AddNewCustomField',
                            "clickFunc": this.props.onOpenCustomFieldModal,
                            'isContextMenu': false,
                            'isButtonMenu': true,
                            'wmsid': 'create-order-info'
                        }, {
                            "name": this.translate('Label_Delete'),
                            "value": [],
                            "disabled": !this.state.enableDeleteButton || this.state.isRequiredField,
                            "icon": "fa-trash",
                            'color': this.state.isRequiredField ? 'yellow' : 'red',
                            "wmsSelector": "DeleteCustomField",
                            "clickFunc": this.clickDeleteRow,
                            'isContextMenu': true,
                            'isButtonMenu': true,
                            "showToolTip": this.state.isRequiredField,
                            "tooltipText": this.translate('Label_Cannot_Delete_Required'),
                            "disabledText": this.translate('Label_Cannot_Edit_Required'),
                            'wmsid': 'delete-order-info'
                        }]}>
                    </WmsGrid>
                </WmsCollapse>

            </div>
        )
    }
}
