import React from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";
import WmsDropdown from "../../../components/WmsDropdown/WmsDropdown.jsx";

interface FlexportInfoComponentProps {
    selector: string;
    handleCarrierObjChange: () => void;
    currentCarrierObj: {
        Nickname: string;
        ApiToken: string;
        InjectionLocation: string;
    };
}

export function FlexportInfoComponent(props: FlexportInfoComponentProps): JSX.Element {
    const translate = (window as any).translate as any;
    const injectionLocationOptions = [
        { Name: "EWR" },
        { Name: "LAX" },
        { Name: "ORD" },
        { Name: "DFW" },
        { Name: "ATL" },
        { Name: "SFO" },
    ];

    return (
        <WmsCollapse
            id={"flexportInfoScreen"}
            headerLabel={translate("Label_Account_Information")}
            showCollapseText={false}
            showCollapseIcon={false}
        >
            <div className="account-info-section">
                <div>
                    <WmsInput
                        id="nickname"
                        inputName="Nickname"
                        wmsSelector={props.selector + "nickname"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_Account_Nick_Name")}
                        value={props.currentCarrierObj.Nickname}
                        required={true}
                        extraWrapperClass="w-50"
                    />
                    <WmsInput
                        id="api_token"
                        inputName="ApiToken"
                        wmsSelector={props.selector + "apiToken"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_API_Token")}
                        value={props.currentCarrierObj.ApiToken}
                        required={true}
                        extraWrapperClass="w-50"
                    />
                    <WmsDropdown
                        id="InjectionLocation"
                        name="InjectionLocation"
                        label={translate("Label_Location")}
                        wmsSelector={props.selector + "InjectionLocation"}
                        onChangeFunc={props.handleCarrierObjChange}
                        value={props.currentCarrierObj.InjectionLocation}
                        options={injectionLocationOptions}
                        valueField="Name"
                        textField="Name"
                        parentClassName="form_group"
                        blankFirstOption={true}
                        extraWrapperClass="w-50"
                    />
                </div>
            </div>
        </WmsCollapse>
    );
}
