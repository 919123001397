import React from 'react';

//component
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsFormGrid from './../components/WmsFormGrid/WmsFormGrid.jsx';
import WmsCheckBox from './../components/WmsCheckBox/WmsCheckBox.jsx';
import * as GlobalService from './../global_services';

export class LocationsFormSetup extends React.Component {

    constructor(props) {

        super(props);
        this.translate = window.translate;

        this.state = {
            Selector: 'Location',
            locationZoneInput: '',
            locationZoneExist: false,
            tplSettings: GlobalService.getTplSettings(),
            Rights: GlobalService.GetRights(),
            FacilityDict: GlobalService.getFacilityDictList()
        }

    }

    handleLSetupFormChanges = (event) => {
        let propLocation = this.props.sharedLocation;
        let objLocation = Object.assign(
            {},
            propLocation,
            { [event.target.name]: event.target.type == 'checkbox' ? !propLocation[event.target.name] : event.target.value }
        )
        if (event.target.name == "NonPickable" && objLocation.NonPickable) {
            objLocation.ReplenishLocation = false
        } else if(event.target.name == "ReplenishLocation" && objLocation.ReplenishLocation) {
            objLocation.NonPickable = false
        }
        if (event.target.name == "LocationTypeId") {
            this.setReplenishLocationFieldsBaseOnLocationType(objLocation, 'onChange')
        } else {
            this.props.onLocationDataset(objLocation);
        }
    }
    setReplenishLocationFieldsBaseOnLocationType = (locationObj, callFrom) => {
        // 1 - Storage -  Non-pickable (Default: Not selected User can modify) | Replenish this location (Default: Not selected User can modify)
        // 2 - Staging -  Non-pickable (Default: Selected User can modify) | Replenish this location (Default: Not selected User cannot modify)
        // 3 - Putaway Vehicle -  Non-pickable (Default: Selected User cannot modify) | Replenish this location (Default: Not selected User cannot modify)
        // 4 - Quarantine -  Non-pickable (Default: Selected User cannot modify) | Replenish this location (Default: Not selected User cannot modify)
        // 5 - Pickline - Non-pickable (Default: Not selected User cannot modify) | Replenish this location (Default: SelectedUser cannot modify)
        let tmpNonPickable = false;
        let tmpReplenishLocation = false;

        if (locationObj.LocationTypeId == 1) {
            tmpNonPickable = false;
            tmpReplenishLocation = false;
        } else if (locationObj.LocationTypeId == 2) {
            tmpNonPickable = true;
            tmpReplenishLocation = false;
        } else if (locationObj.LocationTypeId == 3) {
            tmpNonPickable = true;
            tmpReplenishLocation = false;
        } else if (locationObj.LocationTypeId == 4) {
            tmpNonPickable = true;
            tmpReplenishLocation = false;
        } else if (locationObj.LocationTypeId == 5) {
            tmpNonPickable = false;
            tmpReplenishLocation = true;
        }
        let objLocation = Object.assign(
            {},
            locationObj,
            {
                NonPickable: tmpNonPickable,
                ReplenishLocation: tmpReplenishLocation,
            }
        )
        this.props.onLocationDataset(objLocation);
    }
    //check if grid item allready exist and disable add button
    handleZoneChange = (event) => {

        this.setState({ 'locationZoneValue': event.target.value });
        let datasourceOfZoneGrid = $('#GridLocationZones').data('kendoGrid').dataSource.view();
        let indexOfLocationRecored = datasourceOfZoneGrid.filter(function (el) {
            return el['Name'].toLowerCase() == event.target.value.toLowerCase();
        });
        this.setState({ 'locationZoneExist': indexOfLocationRecored.length > 0 ? true : false });

    }

    //Add Zone Grid Item
    addLocationZone = () => {

        this.setState({ locationZoneValue: '' });
        let propLocation = this.props.sharedLocation;
        propLocation['Zones'].push({ 'Name': this.state.locationZoneValue });
        this.props.onLocationDataset(propLocation);

    }

    //Add Zone Grid Item
    removeLocationZone = (param_Zone_kendoGrid) => {

        let locationZoneGrid = $('.' + this.props.windowId + ' #GridLocationZones').data('kendoGrid');
        locationZoneGrid.dataSource.remove(param_Zone_kendoGrid.getItem(param_Zone_kendoGrid.getKeys()));
        let propLocation = this.props.sharedLocation;
        propLocation['Zones'] = locationZoneGrid.dataSource.view();
        this.props.onLocationDataset(propLocation);

    }

    //contextmenu click event for locationZoneGrid
    clickEventOfContextMenuForZone = (param_contextmenu_obj, param_Zone_kendoGrid) => {

        if (param_contextmenu_obj.name == 'Delete') {
            this.removeLocationZone(param_Zone_kendoGrid);
        }

    }



    render() {

        const renderLocations = JSON.parse(JSON.stringify(this.props.sharedLocation));

        return (

            <div id="collapseLocationFormSetup">
                <WmsCollapse
                    id={'LocationBasicsInformation'}
                    headerLabel={this.translate("Label_Location_Basic_information")}
                    showCollapseText={false}
                    divRefs={this.props.basicsInformationRef}
                    showCollapseIcon={false} >

                    <div className="wms_small_input">
                        <label className="wms-input-label wms-input-sl">{this.translate('Label_Location_Identifier')}</label>
                        <WmsInput
                            id='Field1'
                            inputName='Field1'
                            wmsSelector={this.state.Selector + 'Field1'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={''}
                            required={true}
                            value={renderLocations.Field1}
                            parentClassName={'dl_input_sl'} />

                        <WmsInput
                            id='Field2'
                            inputName='Field2'
                            wmsSelector={this.state.Selector + 'Field2'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={''}
                            value={renderLocations.Field2}
                            parentClassName={'dl_input_sl'} />

                        <WmsInput
                            id='Field3'
                            inputName='Field3'
                            wmsSelector={this.state.Selector + 'Field3'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={''}
                            value={renderLocations.Field3}
                            parentClassName={'dl_input_sl'} />

                        <WmsInput
                            id='Field4'
                            inputName='Field4'
                            wmsSelector={this.state.Selector + 'Field4'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={''}
                            value={renderLocations.Field4}
                            parentClassName={'dl_input_sl'} />
                    </div>

                    <WmsInput
                        id='Description'
                        inputName='Description'
                        wmsSelector={this.state.Selector + 'Description'}
                        onChangeFunc={this.handleLSetupFormChanges}
                        label={this.translate('Label_Location_Description')}
                        value={renderLocations.Description} />

                    <WmsDropdown
                        id="LocationTypeId"
                        name="LocationTypeId"
                        label={this.translate('Label_Location_Type_Identifier')}
                        wmsSelector={this.state.Selector + "LocationIdentifier"}
                        onChangeFunc={this.handleLSetupFormChanges}
                        value={renderLocations.LocationTypeId == null ? 0 : renderLocations.LocationTypeId}
                        options={this.props.sharedLocationOptions.LocationTypes}
                        valueField='Value'
                        textField='Name'
                        parentClassName='form_group' />

                    <WmsInput
                        id='PickPath'
                        inputName='PickPath'
                        wmsSelector={this.state.Selector + 'PickPath'}
                        onChangeFunc={this.handleLSetupFormChanges}
                        label={'Pick Path'}
                        value={renderLocations.PickPath}
                        maxLength={7}
                        showToolTip={true}
                        tooltipText={this.translate("Label_location_pickpath_zero_error")}
                        valueType="number" />

                    <div>
                        <WmsCheckBox
                            name="NonPickable"
                            id="NonPickable"
                            wmsSelector={this.state.Selector + 'NonPickable'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={this.translate('Label_Non_Pickable')}
                            checked={renderLocations.NonPickable == true}
                            disabled={(renderLocations.LocationTypeId != 1 && renderLocations.LocationTypeId != 2) || renderLocations.ReplenishLocation}
                            showToolTip={renderLocations.ReplenishLocation == true}
                            tooltipText={this.translate('Label_Non_Pickable_Replenish_This_Location')}
                            value={renderLocations.NonPickable}
                            parentClassName={"non-pickable-wrapper"}
                        />
                        {this.state.FacilityDict[this.props.selectedFacilityId] && this.state.FacilityDict[this.props.selectedFacilityId].replenishment && (
                            <WmsCheckBox
                                name="ReplenishLocation"
                                id="ReplenishLocation"
                                wmsSelector={this.state.Selector + 'ReplenishLocation'}
                                onChangeFunc={this.handleLSetupFormChanges}
                                label={this.translate('Label_Replenish_This_Location')}
                                checked={renderLocations.ReplenishLocation == true}
                                disabled={(renderLocations.LocationTypeId != 1 || renderLocations.NonPickable)}
                                showToolTip={renderLocations.NonPickable == true}
                                tooltipText={this.translate('Label_Non_Pickable_Replenish_This_Location')}
                                value={renderLocations.ReplenishLocation}
                            />
                        ) }
                    </div>
                </WmsCollapse>
                <WmsCollapse
                    id={'LocationCapabilities'}
                    headerLabel={this.translate("Label_Location_Capabilities")}
                    showCollapseText={false}
                    divRefs={this.props.capabilitiesRef}
                    showCollapseIcon={false} >

                    <div className='measurement-wrapper'>
                        <WmsInput
                            id='Width'
                            inputName='Width'
                            wmsSelector={this.state.Selector + 'Width'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={this.translate('Label_Width')}
                            value={renderLocations.Width} />

                        <WmsInput
                            id='Length'
                            inputName='Length'
                            wmsSelector={this.state.Selector + 'Length'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={this.translate('Label_Length')}
                            value={renderLocations.Length} />

                        <WmsInput
                            id='Height'
                            inputName='Height'
                            wmsSelector={this.state.Selector + 'Height'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={this.translate('Label_Height')}
                            value={renderLocations.Height} />

                        <WmsInput
                            id='WeightAllowed'
                            inputName='WeightAllowed'
                            wmsSelector={this.state.Selector + 'WeightAllowed'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={this.translate('Label_Max_Weight')}
                            value={renderLocations.WeightAllowed} />

                        <WmsInput
                            id='TemperatureMinimum'
                            inputName='TemperatureMinimum'
                            wmsSelector={this.state.Selector + 'TemperatureMinimum'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={this.translate('Label_Min_Temperature')}
                            value={renderLocations.TemperatureMinimum} />
                    </div>
                    <div className='quantity-wrapper'>
                        <WmsInput
                            id='QuantityMinimum'
                            inputName='QuantityMinimum'
                            wmsSelector={this.state.Selector + 'QuantityMinimum'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={this.translate('Label_Min_Quantity')}
                            value={renderLocations.QuantityMinimum} />
                        {(this.state.FacilityDict[this.props.selectedFacilityId] && this.state.FacilityDict[this.props.selectedFacilityId].replenishment) &&
                            (renderLocations.LocationTypeId == 1 || renderLocations.LocationTypeId == 5) &&(
                            <WmsInput
                                id='QuantityMax'
                                inputName='QuantityMax'
                                wmsSelector={this.state.Selector + 'QuantityMax'}
                                onChangeFunc={this.handleLSetupFormChanges}
                                label={this.translate('Label_Max_Quantity')}
                                valueType={'number'}
                                value={renderLocations.QuantityMax} />
                        )}
                    </div>
                </WmsCollapse>

                <WmsCollapse
                    id={'LocationPriorities'}
                    headerLabel={this.translate("Label_Location_Priorities")}
                    showCollapseText={false}
                    divRefs={this.props.prioritiesRef}
                    showCollapseIcon={false} >

                    <WmsInput
                        id='AllocationPriority'
                        inputName='AllocationPriority'
                        wmsSelector={this.state.Selector + 'AllocationPriority'}
                        onChangeFunc={this.handleLSetupFormChanges}
                        label={this.translate('Label_Allocation_Pariority')}
                        value={renderLocations.AllocationPriority >= 0 && renderLocations.AllocationPriority <= 99999 ? renderLocations.AllocationPriority : 99999}
                        valueType="number"
                        required={true}
                        maxLength="5"
                        tooltipText={"Value must be between 1 and 99999"}
                        showToolTip={renderLocations.AllocationPriority <= 0 || renderLocations.AllocationPriority > 99999} />

                </WmsCollapse>
                <WmsCollapse
                    id={'LocationBilling'}
                    headerLabel={this.translate("Label_Location_Billing")}
                    showCollapseText={false}
                    divRefs={this.props.billingRef}
                    showCollapseIcon={false} >

                    <WmsDropdown
                        id="BillingTypeId"
                        name="BillingTypeId"
                        label={this.translate('Label_Billing_Type')}
                        wmsSelector={this.state.Selector + "BillingType"}
                        onChangeFunc={this.handleLSetupFormChanges}
                        options={this.props.sharedLocationOptions.BillingTypes}
                        textField='Name'
                        valueField='Value'
                        parentClassName='form_group'
                        blankFirstOption={true}
                        blankFirstOptionText={window.translate("Label_None")}
                        value={renderLocations.BillingTypeId == null ? '' : renderLocations.BillingTypeId} />

                </WmsCollapse>

                <WmsCollapse
                    id={'LocationDirectedPutaway'}
                    headerLabel={this.translate("Label_Directed_Putaway")}
                    showCollapseText={false}
                    showCollapseIcon={false}
                    divRefs={this.props.directedPutAwayRef}
                    isExpanded={this.state.tplSettings.Receiving.DirectedPutAwayEnabled == true}
                    isShowTooltip={this.state.tplSettings.Receiving.DirectedPutAwayEnabled != true}
                    tooltipText={this.translate('Label_Directed_Putaway_DisableTooltip')} >
                    <div className="wms_col_4">
                        <WmsInput
                            id='locationZone'
                            inputName='locationZone'
                            wmsSelector={this.state.Selector + 'locationZone'}
                            onChangeFunc={this.handleZoneChange}
                            label={this.translate('Label_Enter_Zone')}
                            value={this.state.locationZoneValue} />

                        <WmsButton
                            label={this.translate('Label_Add')}
                            wmsSelector={this.state.Selector + 'Add'}
                            onClickFunc={this.addLocationZone}
                            disabled={this.state.locationZoneExist || !this.state.locationZoneValue || this.state.locationZoneValue.trim() == ""}
                            icon='fa-plus' />

                        <WmsFormGrid
                            id={'GridLocationZones'}
                            wmsSelector={this.state.Selector + 'GridLocationZones'}
                            columns={[{ field: "Name", title: "Name" }]}
                            staticGridData={renderLocations.Zones}
                            windowId={this.props.windowId}
                            gridKeySelection={'Name'}
                            clickEventOfContextMenu={this.clickEventOfContextMenuForZone}
                            // onSelection={(e) => this.selectRow(e)}
                            menu={
                                [{
                                    "name": "Delete",
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss",
                                    "icon": "fa-line-chart",
                                    "color": "green",
                                    "clickFunc": ((e) => function () { })
                                }]
                            }
                        />

                    </div>

                    <div className="wms_col_6">
                        <WmsInput
                            id='PercentFill'
                            inputName='PercentFill'
                            wmsSelector={this.state.Selector + 'PercentFill'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={this.translate('Label_Percent_Fill')}
                            value={renderLocations.PercentFill}
                            regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                        <WmsInput
                            id='NumberPalletsAllowed'
                            inputName='NumberPalletsAllowed'
                            wmsSelector={this.state.Selector + 'NumberPalletsAllowed'}
                            onChangeFunc={this.handleLSetupFormChanges}
                            label={this.translate('Label_Movable_Units_Allowed')}
                            value={renderLocations.NumberPalletsAllowed}
                            valueType={'number'} />



                        <div className="checkbox">
                            <WmsCheckBox
                                name="StoreOnlyPallets"
                                id="StoreOnlyPallets"
                                wmsSelector={this.state.Selector + 'MovableUnitsOnly'}
                                onChangeFunc={this.handleLSetupFormChanges}
                                label={this.translate('Label_Movable_Units_Only')}
                                checked={renderLocations.StoreOnlyPallets == true}
                                value={renderLocations.StoreOnlyPallets}
                            />
                        </div>

                    </div>

                </WmsCollapse>
            </div>
        )
    }
}