var axios = require('axios');
import * as GlobalService from '../../global_services';

export const cancelReceipts = function (cancelData) {
    return axios.post('/WebUI/Receiving/FindReceivings/CancelReceipts', cancelData)
    .then(function (response) {
        return response;
    });
}
export const cancelOrders = function (ids) {
    return axios.post('/WebUI/Shipping/FindOrders/OrdersCancel', ids)
        .then(function (response) {
            return response;
        });
}