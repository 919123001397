import React from 'react';

//component
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsMultiDropdown from './../components/WmsMultiDropdown/WmsMultiDropdown.jsx';
import WmsCheckBox from './../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';

//services
import * as GlobalService from './../global_services';

export class CustomersFormBasic extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            CustomLabels: GlobalService.getCustomLabels(),
            facilityCheckbox: false,
            Selector: 'Customer',
            OptionsCountry: GlobalService.GetCountries(),
            OptionsState: GlobalService.GetRegions(this.props.sharedCustomer.CompanyInfo.Country),
            OptionsFacility: GlobalService.getFacilityList(),
            allFacilities: null
        };
    }

    componentDidMount() {


        //set all facilityids array - used for multidropdown on selectall checkbox Checked
        let facilityIds = [];
        this.state.OptionsFacility.forEach(value => {
            facilityIds.push(value['id']);
        });
        this.setState({ allFacilities: facilityIds });
        $(".from-email-address").kendoTooltip({
            showAfter: 800,
            width: 240,
            position: "bottom",
            content: (e) => {
                return '<p class="model-tooltip-title-p" data-wms-selector="ReplyToAddressTooltipContent">' + this.translate('Label_Reply_To_Email_Address_Tooltip') + '</p>';
            },
            animation: {
                open: {
                    effects: "zoom",
                    duration: 150
                }
            }
        }).data("kendoTooltip");
    }

    componentDidUpdate(prevProps, prevState) {
        if (typeof this.props.sharedCustomer.CompanyInfo.Country != 'undefined'
            && this.props.sharedCustomer.CompanyInfo.Country != prevProps.sharedCustomer.CompanyInfo.Country) {
            this.setStatesAccordingToCountry(this.props.sharedCustomer.CompanyInfo.Country);
        }
    }

    setStatesAccordingToCountry = (param_country) => {
        this.setState({ OptionsState: GlobalService.GetRegions(param_country) });
    }

    handleChange = (param_parent_property, event) => {

        let customerObj = null;
        if (param_parent_property != '') {
            let patentObj = Object.assign({}, this.props.sharedCustomer[param_parent_property]);
            patentObj[event.target.name] = event.target.value;
            if (event.target.name == "Country") {
                patentObj.State = "";
            }
            customerObj = Object.assign(
                {},
                this.props.sharedCustomer,
                { [param_parent_property]: patentObj }
            );
        } else {
            customerObj = Object.assign(
                {},
                this.props.sharedCustomer,
                { [event.target.name]: event.target.value }
            );
        }
        this.props.onCustomerDataset(customerObj);
    }

    handleOptionsChange = (param_parent_property, event) => {

        const name = event.target.name;
        if (!this.props.sharedCustomer.Options[param_parent_property]) {
            this.props.sharedCustomer.Options[param_parent_property] = {};
        }
        let parentObj = this.props.sharedCustomer.Options[param_parent_property];
        console.log("this.props.sharedCustomer.Options: ", this.props.sharedCustomer.Options);
        if (name == 'OverrideSentFrom') {
            console.log("parentObj: ", parentObj);
            parentObj[name] = event.target.checked;
            if (event.target.checked) {
                if (parentObj.apiSenderName) {
                    parentObj.SenderName = parentObj.apiSenderName;
                } else if (!parentObj.SenderName) {
                    parentObj.SenderName = this.props.sharedCustomer.CompanyInfo.CompanyName;
                }
                if (parentObj.apiReplyToAddress) {
                    parentObj.ReplyToAddress = parentObj.apiReplyToAddress;
                }
            } else {
                parentObj.SenderName = '';
                parentObj.ReplyToAddress = '';
            }
        } else {
            parentObj[name] = event.target.value;
        }
        if (name == 'ReplyToAddress') {
            var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            parentObj.ReplyToAddressValidate = true;
            if (parentObj.ReplyToAddress) {
                parentObj.ReplyToAddressValidate = emailRegex.test(String(parentObj.ReplyToAddress).toLowerCase());
                parentObj.ReplyToAddressValidateToolTip = this.translate('Label_Reply_Email_Validation_Note');
            }
            if (parentObj.ReplyToAddress && (parentObj.ReplyToAddress.indexOf("3plcentral.com") !== -1 || parentObj.ReplyToAddress.indexOf("secure-wms.com") !== -1)) {
                parentObj.ReplyToAddressValidate = false;
                parentObj.ReplyToAddressValidateToolTip = this.translate('Label_Reply_Email_Not_Allow');
            }
        }
        parentObj["attachedDocsEmailEnabled"] = true;

        let customeObj = Object.assign(
            {},
            this.props.sharedCustomer,
            {
                Options: Object.assign(
                    {},
                    this.props.sharedCustomer.Options,
                    {
                        [param_parent_property]: parentObj
                    }
                )
            }
        );
        this.props.onCustomerDataset(customeObj);
    }


    checkboxChangeEvent = () => {

        if (!this.state.facilityCheckbox) {
            this.dropdownChangeEvent(this.state.allFacilities);
        } else {
            this.dropdownChangeEvent(this.props.customerDefaultFacilities);
        }
        this.setState({ facilityCheckbox: !this.state.facilityCheckbox });
    }

    dropdownChangeEvent = (param_facilityIds) => {
        if (param_facilityIds == null) { param_facilityIds = []; }
        let propCustomer = this.props.sharedCustomer;
        let objCustomer = Object.assign(
            {},
            propCustomer,
            { 'Facilities': param_facilityIds }
        );
        this.props.onCustomerDataset(objCustomer);
    }

    render() {
        const renderCustomer = this.props.sharedCustomer;
        renderCustomer.PrimaryContact = (renderCustomer.PrimaryContact == null) ? {} : renderCustomer.PrimaryContact;

        return (
            <div className="collapseCustomerTabContent_wrapper" id={'collapseCustomerbasics'} >
                <WmsCollapse
                    id={'collapseCustomerbasicsContent'}
                    headerLabel={this.translate('Label_Customer_Basics')}
                    showCollapseText={false}
                    showCollapseIcon={true} >

                    <WmsContainer title={this.translate('Label_Customer_Information')}>
                        <WmsInput
                            id='CompanyName'
                            inputName='CompanyName'
                            wmsSelector={this.state.Selector + 'Name'}
                            onChangeFunc={(e) => this.handleChange('CompanyInfo', e)}
                            label={this.translate('Label_Costomer_Company_Name')}
                            required={true}
                            value={renderCustomer.CompanyInfo.CompanyName} />

                        <WmsInput
                            id='ExternalId'
                            inputName='ExternalId'
                            wmsSelector={this.state.Selector + 'externalId'}
                            onChangeFunc={(e) => this.handleChange('', e)}
                            label={this.translate('Label_Costomer_Alias_External_Id')}
                            value={renderCustomer.ExternalId} />

                        <WmsInput
                            id='PhoneNumber'
                            inputName='PhoneNumber'
                            wmsSelector={this.state.Selector + 'phoneNumber'}
                            onChangeFunc={(e) => this.handleChange('CompanyInfo', e)}
                            label={this.translate('Label_Costomer_Phone_Number')}
                            value={renderCustomer.CompanyInfo.PhoneNumber} />

                        <WmsInput
                            id='Fax'
                            inputName='Fax'
                            wmsSelector={this.state.Selector + 'fax'}
                            onChangeFunc={(e) => this.handleChange('CompanyInfo', e)}
                            label={this.translate('Label_Costomer_Fax_Number')}
                            value={renderCustomer.CompanyInfo.Fax} />

                        <WmsInput
                            id='WebSite'
                            inputName='WebSite'
                            wmsSelector={this.state.Selector + 'webSite'}
                            onChangeFunc={(e) => this.handleChange('', e)}
                            label={this.translate('Label_Costomer_Website')}
                            value={renderCustomer.WebSite} />

                        <WmsInput
                            id='EmailAddress'
                            inputName='EmailAddress'
                            wmsSelector={this.state.Selector + 'emailAddress'}
                            onChangeFunc={(e) => this.handleChange('CompanyInfo', e)}
                            label={this.translate('Label_Costomer_Email')}
                            value={renderCustomer.CompanyInfo.EmailAddress} />

                        <WmsInput
                            id='Address1'
                            inputName='Address1'
                            wmsSelector={this.state.Selector + 'address1'}
                            onChangeFunc={(e) => this.handleChange('CompanyInfo', e)}
                            label={this.translate('Label_Costomer_Address1')}
                            value={renderCustomer.CompanyInfo.Address1} />

                        <WmsInput
                            id='Address2'
                            inputName='Address2'
                            wmsSelector={this.state.Selector + 'address2'}
                            onChangeFunc={(e) => this.handleChange('CompanyInfo', e)}
                            label={this.translate('Label_Costomer_Address2')}
                            value={renderCustomer.CompanyInfo.Address2} />

                        <WmsDropdown
                            id="Country"
                            name="Country"
                            label={this.translate('Label_Costomer_Country') + '1'}
                            wmsSelector={this.state.Selector + "country"}
                            onChangeFunc={(e) => this.handleChange('CompanyInfo', e)}
                            value={renderCustomer.CompanyInfo.Country}
                            options={this.state.OptionsCountry}
                            valueField='CountryCode'
                            textField='Name'
                            parentClassName='form_group' />

                        <WmsDropdown
                            id="State"
                            name="State"
                            label={this.state.CustomLabels.Label_State}
                            wmsSelector={this.state.Selector + "State"}
                            onChangeFunc={(e) => this.handleChange('CompanyInfo', e)}
                            value={renderCustomer.CompanyInfo.State}
                            disabled={renderCustomer.CompanyInfo.Country === '' ? true : false}
                            options={this.state.OptionsState}
                            valueField='RegionCode'
                            textField='Name'
                            parentClassName='form_group' />

                        <WmsInput
                            id='City'
                            inputName='City'
                            wmsSelector={this.state.Selector + 'city'}
                            onChangeFunc={(e) => this.handleChange('CompanyInfo', e)}
                            label={this.state.CustomLabels.Label_City}
                            value={renderCustomer.CompanyInfo.City} />

                        <WmsInput
                            id='Zip'
                            inputName='Zip'
                            wmsSelector={this.state.Selector + 'zip'}
                            onChangeFunc={(e) => this.handleChange('CompanyInfo', e)}
                            label={this.state.CustomLabels.Label_Zip}
                            regularExp={/^[a-zA-Z0-9-_ ]*$/}
                            value={renderCustomer.CompanyInfo.Zip} />

                    </WmsContainer>

                    <WmsContainer title={this.translate('Label_Costomer_Primary_Contact')}>
                        <WmsInput
                            id='Name'
                            inputName='Name'
                            wmsSelector={this.state.Selector + 'primaryContactName'}
                            onChangeFunc={(event) => this.handleChange('PrimaryContact', event)}
                            label={this.translate('Label_Costomer_Name')}
                            value={renderCustomer.PrimaryContact.Name} />

                        <WmsInput
                            id='PhoneNumber'
                            inputName='PhoneNumber'
                            wmsSelector={this.state.Selector + 'primaryPhoneNumber'}
                            onChangeFunc={(event) => this.handleChange('PrimaryContact', event)}
                            label={this.translate('Label_Costomer_Phone_Number')}
                            value={renderCustomer.PrimaryContact.PhoneNumber} />

                        <WmsInput
                            id='EmailAddress'
                            inputName='EmailAddress'
                            wmsSelector={this.state.Selector + 'primaryEmail'}
                            onChangeFunc={(event) => this.handleChange('PrimaryContact', event)}
                            label={this.translate('Label_Costomer_Email')}
                            value={renderCustomer.PrimaryContact.EmailAddress} />

                    </WmsContainer>

                    <WmsContainer
                        title={this.translate('Label_Customer_Specific_Event_Notifications')}
                        className="customer-specific-notification-wrapper"
                        isShawHeaderInfoIcon={true}
                        headerInfoTooltipText={this.translate('Label_Customer_Specific_Event_Notifications_ToolTip')}>
                        <WmsCheckBox
                            id={'OverrideSentFrom'}
                            name={'OverrideSentFrom'}
                            wmsSelector={this.state.Selector + 'OverrideSentFrom'}
                            onChangeFunc={(event) => this.handleOptionsChange('Alerts', event)}
                            label={this.translate('Label_Override_Notification_Settings')}
                            checked={renderCustomer.Options.Alerts.OverrideSentFrom}
                            value={renderCustomer.Options.Alerts.OverrideSentFrom}
                        />
                        <div className={"send-from-name-wrapper" + (renderCustomer.Options.Alerts.OverrideSentFrom == false ? ' disabled-notification-section' : '')}>
                            <WmsInput
                                id='SenderName'
                                inputName='SenderName'
                                wmsSelector={this.state.Selector + 'SenderName'}
                                onChangeFunc={(event) => this.handleOptionsChange('Alerts', event)}
                                label={this.translate('Label_Sent_From_Name')}
                                value={renderCustomer.Options.Alerts.SenderName}
                                required={renderCustomer.Options.Alerts.OverrideSentFrom}
                                extraWrapperClass="sender-name-input" />

                            <WmsInput
                                id='senderNameLbl'
                                inputName='senderNameLbl'
                                wmsSelector={this.state.Selector + 'senderNameLbl'}
                                onChangeFunc={() => { }}
                                label={''}
                                value={'Example: ' + renderCustomer.Options.Alerts.SenderName + ' <notifications@secure-wms.com>'}
                                extraWrapperClass="sender-name-label" />
                        </div>
                        <WmsInput
                            id='ReplyToAddress'
                            inputName='ReplyToAddress'
                            wmsSelector={this.state.Selector + 'ReplyToAddress'}
                            onChangeFunc={(event) => this.handleOptionsChange('Alerts', event)}
                            label={this.translate('Label_Reply_To_Email_Address')}
                            value={renderCustomer.Options.Alerts.ReplyToAddress}
                            // disabled={renderCustomer.Options.Alerts.OverrideSentFrom == false}
                            extraWrapperClass={"from-email-address" + (renderCustomer.Options.Alerts.OverrideSentFrom == false ? ' disabled-notification-section' : '')} />

                    </WmsContainer>

                    <WmsContainer title={this.state.CustomLabels.Label_Warehouse + " Access"}>

                        <WmsCheckBox
                            id={'selectAll'}
                            name={'selectAll'}
                            wmsSelector={this.state.Selector + 'selectAll'}
                            onChangeFunc={this.checkboxChangeEvent}
                            label={this.translate('Label_Costomer_Select_All')}
                            value={this.state.facilityCheckbox}
                            checked={this.state.facilityCheckbox} />

                        <WmsMultiDropdown
                            id='MultiSelectWarehouse'
                            name='MultiSelectWarehouse'
                            wmsSelector={this.state.Selector + "WarehouseAccess"}
                            options={this.state.OptionsFacility}
                            valueField='id'
                            value={renderCustomer.Facilities}
                            textField='name'
                            onChangeFunc={this.dropdownChangeEvent}
                            tagTemplate='#:values.length# selected' />
                    </WmsContainer>

                </WmsCollapse>
            </div>
        );
    }
}