import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsScrollTo.scss';

class WmsScrollTo extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onScrollToClick = this.onScrollToClick.bind(this);
    }

    onScrollToClick(scrollTo, scrollBox, windowId,event) {
        var id = '#' + scrollTo;
        var windowClass = '.' + windowId
        var scrollBoxID = '#' + scrollBox;
        if (this.props.isTabSection) {
            $(scrollBoxID + windowClass + ' .'+ this.props.tabCommonClassForHide).hide();
            $(`${scrollBoxID}${windowClass} ${id}`).show();
            this.props.onTabShow(id);
        } else {
            var elementOffset = $(scrollBoxID + windowClass).offset().top + 10;
            $(scrollBoxID + windowClass).animate({
                scrollTop: $(scrollBoxID + windowClass).scrollTop() + ($(windowClass + ' '+ id).offset().top - elementOffset)
            }, 1000);
        }
        // Add and remove active class for tab
        $(windowClass+' label').parent('li').removeClass("active-tab");
        $(event.target).parent().addClass("active-tab");
    }

    componentDidMount() {
        var windowClass = '.' + this.props.windowId;
        var scrollBoxID = '#' + this.props.scrollBoxId;
        if (this.props.isTabSection) {
            $(scrollBoxID + windowClass + '  .'+ this.props.tabCommonClassForHide).not(':eq(0)').hide();
        }
        // Add active class in first tab
        $(windowClass+' label').parent('li:eq(0)').addClass("active-tab");
    }
    render() {
        return (
            <label className={(this.props.disabled ? 'disabled-tab ' : '') + this.props.className} data-wms-selector={this.props.wmsSelector} onClick={(e) => this.onScrollToClick(this.props.scrollToId, this.props.scrollBoxId, this.props.windowId,e)}>
                {this.props.label} </label>
        );
    }
}

WmsScrollTo.propTypes = {
    label: PropTypes.string.isRequired, // this should be the label that display on box
    scrollToId: PropTypes.string.isRequired, // this should be the id of the scroll to div
    scrollBoxId: PropTypes.string.isRequired, // this should be the id of the scroll box div
    windowId: PropTypes.string.isRequired, // this should be the id of the scroll box div
    className: PropTypes.string, // This should be used to define class
    isTabSection: PropTypes.bool,
    disabled:PropTypes.bool, // set true for disable tab
    onTabShow: PropTypes.func // event fire when we click on tab when isTabSection True

}

WmsScrollTo.defaultProps = {
    className: "startScroll",
    isTabSection: false,
    tabCommonClassForHide: '',
    onTabShow: (e) => function () { },
    disabled: false
}

export default WmsScrollTo;