import React from 'react';

//component
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';
import WmsInput from '../components/WmsInput/WmsInput.jsx';
import WmsDropdown from '../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse.jsx';
import WmsModel from '../components/WmsModel/WmsModel.jsx';
import WmsContainer from '../components/WmsContainer/WmsContainer.jsx';
import WmsConfirmModel from '../components/WmsConfirmModel/WmsConfirmModel.jsx';

import style from './attach-file-to-assembly.scss';

//services
import * as GridConfig from './attach-file-to-assembly-config.jsx';
import * as assemblyservices from './attach-file-to-assembly.services.jsx';
import * as GlobalService from '../global_services';
import * as metaDataService from './attach-file-to-assembly-meta-data.service.js';
import { kendo } from "../global_variable_service.jsx";

export class AttachFileToAssemblySearch extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window;
        this.manageAttachFileAssemblyGridId = "GridAttachFileToAssembly";
        this.AttachFileAssembly_GrideHeaders = StringWms.convertSringToOption(this.translate('AttachFileAssembly_GrideHeaders'));

        this.state = {
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.manageAttachFileAssemblyGridId : '#' + this.manageAttachFileAssemblyGridId,
            CustomLabels: GlobalService.getCustomLabels(),
            selector: 'AttachFileToAssembly',
            optionConatins: StringWms.convertSringToOption(this.translate('Options_SearchBy_StringOptions')),
            booleanOption: StringWms.convertSringToOption(this.translate('Options_AttachFileToAssembly_Status')),
            filter: {},
            isExpandedCollapse: [],
            isOpenSaveFilterModel: false,
            newFilterTitle: "",
            filterNameExist: false,
            allColumnList: [],
            savedFilterList: [],
            savedFilterIndex: null,
            isOpenConfirmModel: false,
            optionsCustomers: GlobalService.getCustomerList(true),
            facilityOption: [],
            showTooltip: false
        };

        this.onFilterDataSet = GlobalService.debounce(this.onFilterDataSet, 700);
    }

    componentDidMount() {
        assemblyservices.initialFilterData().then((response) => {
            let sortedFacilityList = response.getCustomerFacilityList.sort(function (first, second) {

                var columnTitle1 = first.name.trim().toUpperCase(); // ignore upper and lowercase
                var columnTitle2 = second.name.trim().toUpperCase(); // ignore upper and lowercase

                if (columnTitle1 < columnTitle2) { return -1; }

                if (columnTitle1 > columnTitle2) { return 1; }
                // names must be equal
                return 0;
            });
            sortedFacilityList.unshift({
                "id": 0,
                "name": "Unspecified",
                "uri": "",
                "FacilitySimpleUri": "",
                "UpsAccountNumber": "",
                "FedExAccountNumber": ""
            });
            this.setState({
                facilityOption: sortedFacilityList
            });

        }).finally(() => {
            kendo.ui.progress($('#' + this.props.windowId), false);
        });
        //
        let facilityObj = { id: this.props.sharedAttachFileAssemblyFilterObj.FacilityId };
        let allColumnList = GridConfig.getGridAllColumnList(this.AttachFileAssembly_GrideHeaders, facilityObj).columns;
        this.$rootScope.setLoaderTimer("Manage Attach File To Assembly");
        this.setState({
            allColumnList: allColumnList,
            savedFilterList: metaDataService.getSaveManageAttachFileAssemblyFilterListMetaData(),
            isExpandedCollapse: (!$.isEmptyObject(metaDataService.getManageAttachFileAssemblyCollapseMetaData()) ? metaDataService.getManageAttachFileAssemblyCollapseMetaData() : []),
            filter: this.props.sharedAttachFileAssemblyFilterObj
        }, () => {
            this.props.onSelectFacility(facilityObj);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sharedAttachFileAssemblyFilterObj != null && (prevProps.sharedAttachFileAssemblyFilterObj != this.props.sharedAttachFileAssemblyFilterObj)) {
            this.setState({
                filter: this.props.sharedAttachFileAssemblyFilterObj
            });
        }

        var sortable = $('#attech-file-assembly-filter-section-sortable');

        if (sortable.length && !sortable.hasClass('ui-sortable')) {
            var sortData = metaDataService.getManageAttachFileAssemblySortSectionMetaData();
            // set saved sort data if any in meta data
            if (sortData) {
                var pos = JSON.parse(sortData);
                if (pos) {
                    $.each(pos, function (i, position) {
                        if (position != "") {
                            var $target = sortable.find('#' + position);
                            $target.appendTo(sortable); // or prependTo
                        }
                    });
                }
            }

            sortable.sortable({
                update: this.onSectionSort,
                cancel: "label.icon_label,.wms-collapsible-content",
                handle: ".wms-sort",
                distance: 5,
                start: function (e, ui) {
                    var height = ui.item.height();
                    if (height > 100) {
                        height = 100;
                    }
                    ui.placeholder.height(height);
                }
            });
        }
    }


    filterHandleChange = (event) => {
        let filterData = Object.assign({}, this.state.filter);
        let filterKey = event.target.name;
        let filterVal = event.target.value;

        if (filterVal) {
            filterData[filterKey] = filterVal;
        } else {
            delete filterData[filterKey];
        }
        if (filterKey == 'CustomerId') {
            filterData['FacilityId'] = 0;
            this.setFacilityOnCustomer(filterVal);
        }

        this.setState({
            filter: filterData,
        }, () => {
            this.onFilterDataSet();
        });
    }
    setFacilityOnCustomer = (customerId) => {
        let customerIndex = ObjectWms.FindIndexByProperty(this.state.optionsCustomers, "id", parseInt(customerId));
        let custFacility = this.state.optionsCustomers[customerIndex].facilities;
        let allFacilities = GlobalService.getFacilityList(false);
        let selFacilityOption = [];
        for (let i = 0; i < custFacility.length; i++) {
            let facilityIndex = ObjectWms.FindIndexByProperty(allFacilities, "id", parseInt(custFacility[i]));
            if (allFacilities[facilityIndex]) {
                selFacilityOption.push(allFacilities[facilityIndex]);
            }
        }
        selFacilityOption.unshift({
            "id": 0,
            "name": "Unspecified",
            "uri": "",
            "FacilitySimpleUri": "",
            "UpsAccountNumber": "",
            "FedExAccountNumber": ""
        });
        this.setState({ facilityOption: selFacilityOption });
    }
    onFilterDataSet = () => {
        let filterObj = Object.assign({}, this.state.filter);
        let isSearchOn = false;

        if (!$.isEmptyObject(filterObj)) {
            isSearchOn = true;
        }
        this.props.onAttachFileAssemblyFilterObjChange(filterObj, isSearchOn);
    }

    clearFilters = () => {
        // set default value of filter form
        let resetFilterObj = {
            CustomerId: 0,
            FacilityId: 0,
            StatusEnum: 0,
            ReferenceNum: '',
            IndexOptionsStatus: 0
        };
        this.setState({
            filter: resetFilterObj
        });
        this.props.onAttachFileAssemblyFilterObjChange(resetFilterObj, false);
    }

    setCollapseMetaData = (isExpanded, collapseId) => {
        metaDataService.setManageAttachFileAssemblyCollapseMetaData(collapseId, isExpanded);
    }

    onSectionSort = () => {
        var sortArray = JSON.stringify($('#attech-file-assembly-filter-section-sortable').sortable("toArray"));
        metaDataService.setManageAttachFileAssemblySortSectionMetaData(sortArray);
    }

    onOpenSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: true,
            newFilterTitle: "",
            filterNameExist: false
        });
    }
    exitSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: false
        });
    }
    onSaveFilter = (indexForUpdate = null) => {
        let filterObj = Object.assign({}, this.state.filter);
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var filterArray = grid.dataSource.filter() ? grid.dataSource.filter() : [];
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = GlobalService.prepareGridOptionsForStorage(gridColumn, sortArray, filterArray);

        if (indexForUpdate != null) {
            metaDataService.updateManageAttachFileAssemblyFilterMetaData(indexForUpdate, filterObj, finalSubmission);
            GlobalService.notification('success', this.translate('Order_Filter_Update_success'));
        } else {
            if (!this.state.filterNameExist) {
                metaDataService.saveManageAttachFileAssemblyFilterMetaData(this.state.newFilterTitle, filterObj, finalSubmission);
                this.exitSaveFilterModel();
            }
        }
    }

    handleFilterTitleChange = (e) => {
        let value = e.target.value;
        if (value.Description == '') {
            value = '';
        }

        var nameExists = false;
        // check dublicate title for case sensitive
        var exists = this.state.savedFilterList.findIndex(function (item) {
            return value.toLowerCase() === item.title.toLowerCase();
        });
        if (exists != -1) {
            nameExists = true;
        }
        this.setState({
            newFilterTitle: value,
            filterNameExist: nameExists
        });
    }

    onClearSaveFilter = (index) => {
        this.setState({
            isOpenConfirmModel: true,
            savedFilterIndex: index
        });
    }
    onYesRemoveSavedFilter = () => {
        metaDataService.clearSaveManageAttachFileAssemblyFilterListMetaData(this.state.savedFilterIndex);
        this.setState({
            isOpenConfirmModel: false,
            savedFilterList: metaDataService.getSaveManageAttachFileAssemblyFilterListMetaData()
        });
    }
    ClickExit = () => {
        this.setState({
            isOpenConfirmModel: false
        });
    }

    onUseSaveFilter = (index) => {
        var objFilter = Object.assign({}, this.state.savedFilterList[index]);
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var gridConfig = JSON.parse(objFilter.columnData);
        var tempObject = "";

        if (gridConfig.columns) {
            var fieldArray = gridConfig.columns.map(el => el.field);
            var updateColumn = [];
            this.state.allColumnList.filter((column) => {
                if (fieldArray.indexOf(column.field) !== -1) {
                    updateColumn.push(column);
                }
            });
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, updateColumn, "field");
        } else {
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, this.props.defaultColumns, "field");
        }
        grid.setOptions({
            columns: tempObject
        });
        grid.dataSource.sort(gridConfig.sorts);
        grid.dataSource.filter(gridConfig.filters);

        this.setState({
            filter: objFilter.filterData
        }, () => {
            this.onFilterDataSet();
        });
        this.props.onApplySavedFilter(objFilter.filterData);
    }



    showFilterTooltip = () => {
        this.setState({ showTooltip: true });
    }
    hideFilterTooltip = () => {
        this.setState({ showTooltip: false });
    }

    render() {
        return (
            <div className={`attach-file-assembly-search-holder`}>
                <div className="attach-file-assembly-filter">
                    <div className="filter-wrapper">
                        <div className="btn-section">

                            <WmsButton
                                label={this.translate('Label_Save_View')}
                                wmsSelector={this.state.selector + 'SaveFilterButton'}
                                onClickFunc={this.onOpenSaveFilterModel}
                                icon='fa-floppy-o'
                            />
                            <WmsButton
                                label={this.translate('RoleManager_Label_Reset')}
                                buttonType='orange'
                                wmsSelector={this.state.selector + 'ClearSearchButton'}
                                onClickFunc={this.clearFilters}
                                icon='fa-repeat' />
                        </div>

                        <div className="title">
                            {this.props.sharedSearchOn == true &&
                                <div><b>{this.translate('Label_NOTE')}: </b><label >{this.translate('Label_Search_Applied_Filter')}</label></div>
                            }
                        </div>

                        <div className={this.props.sharedSearchOn == true ? "warehouse-form-boxes width-title " : "warehouse-form-boxes"}>
                            <div className="warehouse-are-content" id="attech-file-assembly-filter-section-sortable">
                                {this.state.savedFilterList.length !== 0 && (
                                    <WmsCollapse id={'attach_file_assembly_collapse0'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('attach_file_assembly_collapse0') ? !this.state.isExpandedCollapse['attach_file_assembly_collapse0'] : true} showDragIcon={true} headerLabel={this.translate('Label_Saved_Filter_List')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'attach_file_assembly_collapse0')}>
                                        <ul className="filter-list-block">
                                            {this.state.savedFilterList.map((data, index) => (
                                                <li className="filter-list" key={index}>
                                                    <a className="title-text" title={data.title}>{data.title}</a>
                                                    <a className="clear-save-filter" onClick={(e) => this.onClearSaveFilter(index)}><i className="far fa-times-circle" aria-hidden="true"></i></a>
                                                    <div className="wms-apply-btn">
                                                        <WmsButton
                                                            label={this.translate('RoleManager_Label_Update')}
                                                            buttonType='green'
                                                            wmsSelector={this.state.selector + 'UpdateFilterButton'}
                                                            onClickFunc={(e) => this.onSaveFilter(index)}
                                                            icon='' />
                                                        <WmsButton
                                                            label={this.translate('Label_Apply')}
                                                            buttonType='green'
                                                            wmsSelector={this.state.selector + 'ApplyFilterButton'}
                                                            onClickFunc={(e) => this.onUseSaveFilter(index)}
                                                            icon='' />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </WmsCollapse>
                                )}

                                <WmsCollapse
                                    id={'attach_file_assembly_search_collapse1'}
                                    isExpanded={this.state.isExpandedCollapse.hasOwnProperty('attach_file_assembly_search_collapse1') ? !this.state.isExpandedCollapse['attach_file_assembly_search_collapse1'] : true}
                                    showDragIcon={true}
                                    headerLabel={this.translate('Label_General')}
                                    showCollapseText={false}
                                    setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'attach_file_assembly_search_collapse1')}>
                                    <WmsDropdown
                                        id="attach_file_assembly_search_CustomerId"
                                        name="CustomerId"
                                        windowId={this.props.windowId}
                                        label={this.state.CustomLabels.Label_Customer}
                                        wmsSelector={this.state.selector + "SelectCustomer"}
                                        value={this.state.filter.CustomerId}
                                        onChangeFunc={this.filterHandleChange}
                                        options={this.state.optionsCustomers}
                                        valueField={'id'}
                                        textField={'name'}
                                    />
                                    <WmsDropdown
                                        id="attach_file_assembly_search_warehouse"
                                        name="FacilityId"
                                        windowId={this.props.windowId}
                                        label={this.state.CustomLabels.Label_Warehouse}
                                        wmsSelector={this.state.selector + "SelectWarehouse"}
                                        value={this.state.filter.FacilityId}
                                        onChangeFunc={this.filterHandleChange}
                                        options={this.state.facilityOption}
                                        valueField='id'
                                        textField='name'
                                        parentClassName='form_group'
                                    />
                                </WmsCollapse>

                                <WmsCollapse
                                    id={'attach_file_assembly_collapse2'}
                                    isExpanded={this.state.isExpandedCollapse.hasOwnProperty('attach_file_assembly_collapse2') ? !this.state.isExpandedCollapse['attach_file_assembly_collapse2'] : true}
                                    showDragIcon={true}
                                    headerLabel={this.translate('Label_Assembly_Details')}
                                    showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'attach_file_assembly_collapse2')}>

                                    <WmsDropdown
                                        id="IndexOptionsStatus"
                                        name="IndexOptionsStatus"
                                        windowId={this.props.windowId}
                                        label={this.translate('Label_Filter_Status')}
                                        wmsSelector={this.state.selector + "Status"}
                                        value={this.state.filter.IndexOptionsStatus}
                                        onChangeFunc={this.filterHandleChange}
                                        options={this.state.booleanOption}
                                        valueField='value'
                                        textField='description'
                                    />
                                    <WmsInput
                                        id='ReferenceNum'
                                        inputName='ReferenceNum'
                                        wmsSelector={this.state.Selector + 'ReferenceNum'}
                                        value={this.state.filter.ReferenceNum}
                                        onChangeFunc={this.filterHandleChange}
                                        extraWrapperClass="full-width-input"
                                        label={this.translate('Label_ReferenceID')} />
                                </WmsCollapse>
                            </div>
                        </div>

                        <div>
                            {this.state.isOpenSaveFilterModel && (
                                <WmsModel
                                    id='saveCurrentAttachFileToAssemblyFilter'
                                    title={this.translate('Label_Save_Filter')}
                                    width={450}
                                    height={240}
                                    onCloseFunc={this.exitSaveFilterModel}
                                    isOpen={this.state.isOpenSaveFilterModel}
                                    customClass='wms-model-new-ui-wrapper'>
                                    <div className="model-content-wrapper">
                                        <WmsContainer>
                                            <WmsInput
                                                inputName="newFilterTitle"
                                                name="newFilterTitle"
                                                wmsSelector={this.state.selector + 'FilterTitle'}
                                                value={this.state.newFilterTitle}
                                                onChangeFunc={this.handleFilterTitleChange}
                                                extraWrapperClass="full-width-input"
                                                label={this.translate('Label_Filter_Title')} />
                                        </WmsContainer>
                                    </div>
                                    <div className="save-filter-button-wrapper footer-btn-holder">
                                        <WmsButton
                                            label={this.translate('Label_Cancel')}
                                            wmsSelector={this.state.selector + "SaveNewFilterExit"}
                                            onClickFunc={this.exitSaveFilterModel}
                                            buttonType="orange"
                                            icon='fa-ban'
                                        />
                                        <WmsButton
                                            label={this.translate('Label_Save')}
                                            wmsSelector={this.state.selector + "SaveNewFilter"}
                                            onClickFunc={(e) => this.onSaveFilter()}
                                            disabled={this.state.newFilterTitle == '' || this.state.filterNameExist}
                                            icon="fa-floppy-o"
                                            showToolTip={this.state.filterNameExist}
                                            tooltipText={this.translate('Label_Filter_Name_Exist_Message')}
                                        />
                                    </div>
                                </WmsModel>
                            )}
                        </div>

                        <WmsConfirmModel
                            id={"ConfirmModel" + this.props.windowId}
                            isOpen={this.state.isOpenConfirmModel}
                            type={this.translate('Label_Confirmation')}
                            width={500}
                            height={175}
                            firstBtnLabel={this.translate('Label_Yes')}
                            firstBtnIcon={"fa-check-square"}
                            firstBtnOnClickFunc={this.onYesRemoveSavedFilter}
                            sawCheckBox={false}
                            conFormModelText={this.translate('Label_are_you_sure_remove_filter')}
                            onCloseFunc={this.ClickExit}
                            thirdBtnLabel={this.translate('Label_No')}
                            thirdBtnIcon={"fa-times-circle"}
                        />
                    </div>

                </div>
            </div>
        );
    }
}