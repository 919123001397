import React from 'react';

//component
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsModel from './../components/WmsModel/WmsModel.jsx';
import WmsComboBox from './../components/WmsComboBox/WmsComboBox.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsConfirmModel from './../components/WmsConfirmModel/WmsConfirmModel.jsx';

import * as userService from './users.service.jsx';
import * as GlobalService from './../global_services';

export class UserBetaAssignRoleModel extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.state = {
            saveLoader: false,
            isOpenConfirmModel: false,
            sharedUserRoleList: [],
            assignRoleValue: "",
        }
    }

    componentDidMount() {
        kendo.ui.progress($('#assignRole'), true);
        //Get dropdown of Role for assign role model
        userService.getRolesList(this.props.sharedSelectUserType).then((response) => {
            kendo.ui.progress($('#assignRole'), false);
            this.setState({ sharedUserRoleList: response.data.filter( e => e.Name !== "superadmin") });
            this.setState({ assignRoleValue: '' });
        }).finally(() => {
            kendo.ui.progress($('#assignRole'), false);
        });
    }

    handleAssignRoleChange = (e) => {
        this.setState({ assignRoleValue: e.target.value });
    }
    cancelAssignRole = () => {
        $("#assignRole").data('kendoWindow').close();
        this.props.onAssignRoleClose();
    }

    closeAssignRoleModal = () => {
        this.props.onAssignRoleClose();
    }

    updateUserRole = () => {
        this.setState({ saveLoader: true });
        var returnObj = this.checkHasOutOfScopeRight(this.state.sharedUserRoleList, this.state.assignRoleValue)
        var isHasOutOfScopeRights = returnObj.HasOutOfScopeRights;
        if (isHasOutOfScopeRights) {
            this.setState({
                isOpenConfirmModel: true
            });
            return;
        }
        this.saveAssignRoleCall();
    }
    checkHasOutOfScopeRight(list, roleName) {
        return list.find((element) => {
            if (element.Name == roleName) {
                return element;
            }
        });
    }
    saveAssignRoleCall = () => {
        userService.updateUserRole(this.props.sharedSelectedUserIds, this.state.assignRoleValue).then((response) => {
            this.setState({ saveLoader: false });
            if (response.status == 200 || response.status == 204) {
                GlobalService.notification('success', this.translate('Label_User_Updated'));
                this.onCloseConfirmModel();
                this.cancelAssignRole();
                this.props.onResetSelectionAndButton();
                var eventItem = new CustomEvent("onUserRoleUpdate");
                document.dispatchEvent(eventItem);
            } else {
                this.onCloseConfirmModel();
                GlobalService.apiFailer(response);
            }
        }).finally(() => {
            this.onCloseConfirmModel();
            this.setState({ saveLoader: false });
        });
    }
    onCloseConfirmModel = () => {
        this.setState({ isOpenConfirmModel: false, saveLoader: false });
    }

    clickOnContinueButton = () => {
        this.saveAssignRoleCall();
    }

    render() {
        return (
            <WmsModel
                id='assignRole'
                title={this.translate('Label_Assign_Role')}
                width={800}
                height={365}
                onCloseFunc={this.closeAssignRoleModal}
                isOpen={this.props.sharedIsOpenAssignRoleModel}
                customClass='wms-model-new-ui-wrapper user-assing-role-wrapper'>
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'userRoleCollapse'}
                        headerLabel={this.translate('Label_User_Role')}
                        showCollapseIcon={true}
                        showCollapseText={false}
                    >
                        <div className="title">
                            <div className="wms-sprite fa fa-exclamation-triangle"></div>
                            <span>{this.translate('Label_Setting_New_Role')}</span>
                        </div>
                        <WmsComboBox
                            id='ComboAssignRoles'
                            name='ComboAssignRoles'
                            wmsSelector={"ComboAssignRoles"}
                            options={this.state.sharedUserRoleList}
                            valueField='Name'
                            value={this.state.assignRoleValue}
                            textField='Name'
                            onChangeFunc={this.handleAssignRoleChange}
                            label={this.translate('Label_User_Role')}
                            parentClassName='assignrole_combo'
                            placeholder="" />
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate("Label_Cancel")}
                        wmsSelector={'CancelAssignRoles'}
                        onClickFunc={this.cancelAssignRole}
                        buttonType='orange'
                        icon='fa-ban' />
                    <WmsButton
                        label={this.translate('Label_Save')}
                        wmsSelector={'SaveAssignRoles'}
                        onClickFunc={this.updateUserRole}
                        icon='fa-floppy-o'
                        disabled={
                            this.state.assignRoleValue == '' ||
                            this.state.assignRoleValue == null ||
                            this.state.saveLoader
                        }
                        loading={this.state.saveLoader} />
                </div>
                <WmsConfirmModel
                    id="userConfirmModel"
                    isOpen={this.state.isOpenConfirmModel}
                    width={500}
                    height={250}
                    firstBtnLabel={this.translate('RoleManager_Label_Continue')}
                    firstBtnIcon={"fa fa-check-square"}
                    firstBtnOnClickFunc={this.clickOnContinueButton}
                    thirdBtnLabel={this.translate('RoleManager_Label_Back')}
                    thirdBtnIcon={"fa fa-arrow-left"}
                    conFormModelText={this.translate('AreYouSure_customer_warehouse_user')}
                    sawCheckBox={false}
                    onCloseFunc={this.onCloseConfirmModel}
                    isCallToFront={false}
                />
            </WmsModel>
        )
    }
}