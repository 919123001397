import * as GlobalService from '../global_services';
var isFirstTimeOpen = true;
var keyName = "Orders";
var metaDataOrderObject = {
    "Orders": {
        "FindOrders": {
            "filterData": {

            },
            "gridConfig": {
                "findOrderGrid": {

                }
            },
            "saveFilter": [],
            "sorableSection": "",
            "collapseData": {

            },
        },
        "ManageOrders": {
            "collapseData": {

            },
            "filterData": {
                "editAllocation": {

                },
                "massAdd": {

                }
            },
            "saveFilter": {
                "editAllocation": [],
                "massAdd": []
            },
            "gridConfig": {
                "addLineItemGrid": {

                },
                "lineItemCustomFields": {

                },
                "appliedChargesGrid": {

                },
                "advancedContactLookupGrid": {

                },
                "customOrderInfo": {

                },
                "editAllocation": {

                },
                "massAdd": {

                },
                "OrderLineItem":{

                },
                "OrderLineItemBeta":{

                },
                "OrderLineItemBetaFulFillment":{

                }
            }
        }
    }
};


// call api for get metaData of order is it's open first time
export const getUserClientMetaData = function () {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response != "") {
                    Object.assign(metaDataOrderObject[keyName], JSON.parse(response));
                }
                console.log("response  metaDataOrderObject :", metaDataOrderObject);
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                console.log(response);
                return false;
            }
        );
    }
}

export const setFindOrderCollapseMetaData = function (collapseId, value) {
    if (!metaDataOrderObject[keyName]['FindOrders']['collapseData']) {
        metaDataOrderObject[keyName]['FindOrders']['collapseData'] = {}
    }
    metaDataOrderObject[keyName]['FindOrders']['collapseData'][collapseId] = value;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const getFindOrderCollapseMetaData = function () {
    return metaDataOrderObject[keyName]['FindOrders']['collapseData'];
}

export const setManageOrderCollapseMetaData = function (collapseId, value) {
    metaDataOrderObject[keyName]['ManageOrders']['collapseData'][collapseId] = value;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}


export const getManageOrderCollapseMetaData = function () {
    return metaDataOrderObject[keyName]['ManageOrders']['collapseData'];
}

export const setFindOrderSortSectionMetaData = function (sortArray) {
    metaDataOrderObject[keyName]['FindOrders']['sorableSection'] = sortArray;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getFindOrderSortSectionMetaData = function () {
    return metaDataOrderObject[keyName]['FindOrders']['sorableSection'];
}

export const setFindOrderFilterMetaData = function (filterData) {
    metaDataOrderObject[keyName]['FindOrders']['filterData'] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getFindOrderFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['FindOrders']['filterData'])) {
        return metaDataOrderObject[keyName]['FindOrders']['filterData'];
    } else {
        return "";
    }

}

// save filter of find order in meta data
export const saveFindOrderFilterMetaData = function (title, filterObj, columnObj) {
    var setData = {
        title: title,
        filterData: filterObj,
        columnData: columnObj.optionsData
    };
    if (typeof metaDataOrderObject[keyName]['FindOrders']['saveFilter'] == "undefined") {
        metaDataOrderObject[keyName]['FindOrders']['saveFilter'] = [];
    }
    (metaDataOrderObject[keyName]['FindOrders']['saveFilter']).push(setData);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));

}
export const updateFindOrderFilterMetaData = function (index, filterObj, columnObj) {

    if (typeof metaDataOrderObject[keyName]['FindOrders']['saveFilter'][index] != "undefined") {
        metaDataOrderObject[keyName]['FindOrders']['saveFilter'][index].filterData = filterObj;
        metaDataOrderObject[keyName]['FindOrders']['saveFilter'][index].columnData = columnObj.optionsData;
        GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
    }

}
export const getSaveFindOrderFilterListMetaData = function (objName) {
    if (typeof metaDataOrderObject[keyName]['FindOrders']['saveFilter'] == "undefined") {
        metaDataOrderObject[keyName]['FindOrders']['saveFilter'] = [];
    }
    return metaDataOrderObject[keyName]['FindOrders']['saveFilter'];
}

export const clearSaveFindOrderFilterListMetaData = function (index) {
    metaDataOrderObject[keyName]['FindOrders']['saveFilter'].splice(index, 1);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const applySavedFilterDataToFindOrderGrid = function (gridConfig, filterData) {
    metaDataOrderObject[keyName]['FindOrders']['gridConfig']['findOrderGrid'] = gridConfig.optionsData;
    metaDataOrderObject[keyName]['FindOrders']['filterData'] = filterData;

    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const clearFindOrderFilterMetaData = function (objName) {
    metaDataOrderObject[keyName]['FindOrders']['filterData'] = {};
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const setFindOrderGridOptionInMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['FindOrders']['gridConfig']['findOrderGrid'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getFindOrderGridOptionInMetaData = function () {

    if (typeof metaDataOrderObject[keyName]['FindOrders']['gridConfig'] == "undefined") {
        metaDataOrderObject[keyName]['FindOrders']['gridConfig'] = { };
    }
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['FindOrders']['gridConfig']['findOrderGrid'])) {
        return metaDataOrderObject[keyName]['FindOrders']['gridConfig']['findOrderGrid'];
    } else {
        return "";
    }
}

export const setManageOrdersOrderItemsGridOptionInMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['addLineItemGrid'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrdersOrderItemsGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['addLineItemGrid'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['addLineItemGrid'];
    } else {
        return "";
    }
}

export const setManageOrdersOrderLineItemCustomFieldInMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['lineItemCustomFields'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrdersOrderLineItemCustomFieldInMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['lineItemCustomFields'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['lineItemCustomFields'];
    } else {
        return "";
    }
}
// For custom order info set and get
export const setManageOrdersCustomOrderInfoInMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['customOrderInfo'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrdersCustomOrderInfoInMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['customOrderInfo'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['customOrderInfo'];
    } else {
        return "";
    }
}


export const setManageOrdersAppliedChargesGridOptionInMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['appliedChargesGrid'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrdersAppliedChargesGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['appliedChargesGrid'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['appliedChargesGrid'];
    } else {
        return "";
    }
}

export const setManageOrdersContactLookupGridOptionInMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['advancedContactLookupGrid'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrdersContactLookupGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['advancedContactLookupGrid'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['advancedContactLookupGrid'];
    } else {
        return "";
    }
}

export const setManageOrdersMassAddGridOptionInMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['massAdd'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrdersMassAddGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['massAdd'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['massAdd'];
    } else {
        return "";
    }
}

export const setManageOrdersEditAllocationGridOptionInMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['editAllocation'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrdersEditAllocationGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['editAllocation'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['editAllocation'];
    } else {
        return "";
    }
}

export const setManageOrdersMassAddFilterMetaData = function (filterData) {
    metaDataOrderObject[keyName]['ManageOrders']['filterData']['massAdd'] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrdersMassAddFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['filterData']['massAdd'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['filterData']['massAdd'];
    } else {
        return "";
    }
}

export const setManageOrdersEditAllocationFilterMetaData = function (filterData) {
    metaDataOrderObject[keyName]['ManageOrders']['filterData']['editAllocation'] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrdersEditAllocationFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['filterData']['editAllocation'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['filterData']['editAllocation'];
    } else {
        return "";
    }
}

// save filter of massAdd in meta data
export const saveManageOrdersMassAddFilterMetaData = function (title, filterObj, columnObj) {
    var setData = {
        title: title,
        filterData: filterObj,
        columnData: columnObj.optionsData
    };
    if (typeof metaDataOrderObject[keyName]['ManageOrders']['saveFilter'] == "undefined") {
        metaDataOrderObject[keyName]['ManageOrders']['saveFilter'] = {};
    }
    if (typeof metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['massAdd'] == "undefined") {
        metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['massAdd'] = [];
    }
    (metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['massAdd']).push(setData);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const getSavedManageOrdersMassAddFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['massAdd'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['massAdd'];
    } else {
        return [];
    }
}
export const removeSavedManageOrdersMassAddFilterMetaData = function (index) {
    metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['massAdd'].splice(index, 1);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const applySavedFilterDataToMassAddGrid = function (gridConfig, filterData) {
    metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['massAdd'] = gridConfig.optionsData;
    metaDataOrderObject[keyName]['ManageOrders']['filterData']['massAdd'] = filterData;

    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

// save filter of editAllocation in meta data
export const saveManageOrdersEditAllocationFilterMetaData = function (title, filterObj, columnObj) {
    var setData = {
        title: title,
        filterData: filterObj,
        columnData: columnObj.optionsData
    };
    if (typeof metaDataOrderObject[keyName]['ManageOrders']['saveFilter'] == "undefined") {
        metaDataOrderObject[keyName]['ManageOrders']['saveFilter'] = {};
    }
    if (typeof metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['editAllocation'] == "undefined") {
        metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['editAllocation'] = [];
    }

    (metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['editAllocation']).push(setData);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const getSavedManageOrdersEditAllocationFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['editAllocation'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['editAllocation'];
    } else {
        return [];
    }
}
export const removeSavedManageOrdersEditAllocationFilterMetaData = function (index) {
    metaDataOrderObject[keyName]['ManageOrders']['saveFilter']['editAllocation'].splice(index, 1);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const applySavedFilterDataToEditAllocationGrid = function (gridConfig, filterData) {
    metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['editAllocation'] = gridConfig.optionsData;
    metaDataOrderObject[keyName]['ManageOrders']['filterData']['editAllocation'] = filterData;

    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const setManageOrdersLineItemGridOptionInMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['OrderLineItem'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrdersLineItemGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['OrderLineItem'])) {
        return metaDataOrderObject[keyName]['ManageOrders']['gridConfig']['OrderLineItem'];
    } else {
        return "";
    }
}

export const setManageOrdersLineItemBetaGridOptionInMetaData = function (gridConfig,isFulFillmentEnable) {
    var metaTitle = isFulFillmentEnable ?  'OrderLineItemBetaFulFillment' : 'OrderLineItemBeta';
    metaDataOrderObject[keyName]['ManageOrders']['gridConfig'][metaTitle] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrdersLineItemBetaGridOptionInMetaData = function (isFulFillmentEnable) {
    var metaTitle = isFulFillmentEnable ?  'OrderLineItemBetaFulFillment' : 'OrderLineItemBeta';
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageOrders']['gridConfig'][metaTitle])) {
        return metaDataOrderObject[keyName]['ManageOrders']['gridConfig'][metaTitle];
    } else {
        return "";
    }
}