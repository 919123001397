import React from 'react';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import { ObjectWms } from '../../../../assets/ts/ObjectWms.ts';
import { default as WmsButton } from './../../components/WmsButton/WmsButton.jsx';
import { default as WmsDateTimePicker } from './../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import { default as WmsMultiDropdown } from './../../components/WmsMultiDropdown/WmsMultiDropdown.jsx';
import { default as WmsCustomDateTimePicker } from './../../components/WmsCustomDateTimePicker/WmsCustomDateTimePicker.jsx';
import { default as WmsDropdown } from './../../components/WmsDropdown/WmsDropdown.jsx';
import { default as WmsDropdownList } from './../../components/WmsDropdownList/WmsDropdownList.jsx';
import { default as WmsUpdateDropdownList } from './../../components/WmsUpdateDropdownList/WmsUpdateDropdownList.jsx';
import { default as WmsInput } from './../../components/WmsInput/WmsInput.jsx';
import { default as WmsModel } from './../../components/WmsModel/WmsModel.jsx';
import { default as WmsConfirmModel } from './../../components/WmsConfirmModel/WmsConfirmModel.jsx';
import { default as WmsContainer } from './../../components/WmsContainer/WmsContainer.jsx';
import { default as WmsCollapse } from '../../components/WmsCollapse/WmsCollapse.jsx';
import * as metaDataService from '../meta-data.service';
import * as GridConfig from './grid_configuration.virtual.jsx';

//services
import * as GlobalService from './../../global_services';

export class FindOrdersSearchVirtual extends React.Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;
        this.customLabels = GlobalService.getCustomLabels();
        this.findOrderGridId = "grid_with_remote_data";
        let Orders_FindOrders_GridHeaders = StringWms.convertSringToOption(this.translate('Orders_FindOrders_GridHeaders'));

        this.state = {
            Selector: 'Order',
            noBatchesForCustomer: false,
            showTooltip: false,
            showSearchTargetTooltip: false,
            optionsSearchBy: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchOrderFilters')),
            optionsReferenceNumber: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchFilters')),
            optionsShipTo: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchFilters')),
            optionsOrderNote: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchFilters')),
            optionsInventoryAllocated: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchByInventoryAllocated')),
            optionsStatus: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchByStatus').replace(new RegExp('{' + 'customCompleteLabel' + '}', 'gi'), this.customLabels.Label_Complete)),
            optionsASN: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchByASNAndRouteRequest')),
            OptionsRouteRequest: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchByASNAndRouteRequest')),
            optionsCustomer: GlobalService.getCustomerList(true),
            optionsFacility: GlobalService.getFacilityList(true),
            Rights: GlobalService.GetRights(),
            isOpenSaveFilterModel: false,
            isOpenConfirmModel: false,
            isOpenUpdateConfirmModel: false,
            newFilterTitle: "",
            savedFilterList: metaDataService.getSaveFindOrderFilterListMetaData(),
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.findOrderGridId : '#' + this.findOrderGridId,
            searchTxt: "",
            allColumnList: GridConfig.getAllColumnList(Orders_FindOrders_GridHeaders, GlobalService.getCustomLabels()),
            isExpandedCollapse: (!$.isEmptyObject(metaDataService.getFindOrderCollapseMetaData()) ? metaDataService.getFindOrderCollapseMetaData() : []),
            filterNameExist: false,
            isClickSearchFilterToggle: false,
            sharedFindOrderFilter: Object.assign({}, props.defaultFilterValue)
        };
        this.onFilterDataSetdebounce = GlobalService.debounce(this.onFilterDataSetdebounce, 700);
    }
    componentDidMount() {
        var sortable = $('#sortable-section');
        var sortData = metaDataService.getFindOrderSortSectionMetaData();
        // set saved sort data if any in meta data
        if (sortData) {
            var pos = JSON.parse(sortData);
            if (pos) {
                $.each(pos, function (i, position) {
                    if(position !=""){
                        var $target = sortable.find('#' + position);
                        $target.appendTo(sortable); // or prependTo
                    }
                });
            }
        }
        sortable.sortable({
            update: this.onSectionSort,
            cancel: "label.icon_label,.wms-collapsible-content",
            handle: ".wms-sort",
            distance: 5,
            start: function (e, ui) {
                var height = ui.item.height();
                if (height > 100) {
                    height = 100;
                }
                ui.placeholder.height(height);
            }
        });
        var filterData = metaDataService.getFindOrderFilterMetaData();
        if (filterData != "") {
            // temporary remove batch name from metaData,
            // remove code after pass batchId in filter
            delete filterData.BatchName;
            this.setState({
                sharedFindOrderFilter: filterData
            });
        } 
    }
    onSectionSort = () => {
        var sortArray = JSON.stringify($('#sortable-section').sortable("toArray"));
        metaDataService.setFindOrderSortSectionMetaData(sortArray);
    }
    handleFilterChange = (event) => {
        let propFilter = this.state.sharedFindOrderFilter;

        let objFilter = Object.assign(
            propFilter,
            { [event.target.name]: event.target.value }
        );
        if (event.target.name == "Skus") {
            if (event.target.value == "") {
                objFilter[event.target.name] = [];
            }
        }
        this.onFilterDataSet(objFilter);
    }


    handleCustomerChange = (event) => {
        let propFilter = this.state.sharedFindOrderFilter;
        let objFilter = Object.assign(
            {},
            propFilter,
            { [event.target.name]: event.target.value }
        );

        if (event.target.name == 'IndexOptionsCustomerId') {

            let customerIndex = ObjectWms.FindIndexByProperty(this.state.optionsCustomer, "id", parseInt(event.target.value));
            let custFacility = this.state.optionsCustomer[customerIndex].facilities;
            let allFacilities = GlobalService.getFacilityList(false);
            let selFacilityOption = [];
            for (let i = 0; i < custFacility.length; i++) {
                let facilityIndex = ObjectWms.FindIndexByProperty(allFacilities, "id", parseInt(custFacility[i]));
                if(allFacilities[facilityIndex]) {
                    selFacilityOption.push(allFacilities[facilityIndex]);
                }
            }
            selFacilityOption.unshift({
                "id": 0,
                "name": "Unspecified",
                "uri": "",
                "FacilitySimpleUri": "",
                "UpsAccountNumber": "",
                "FedExAccountNumber": ""
            });
            objFilter['IndexOptionsFacilityId'] = selFacilityOption.find(f => f.id === this.state.optionsCustomer[customerIndex].defaultFacility) === undefined ? 0 :this.state.optionsCustomer[customerIndex].defaultFacility;
            objFilter['BatchOrderid'] = '';
            delete objFilter['Skus'];
            this.setState({
                optionsFacility: selFacilityOption,
                noBatchesForCustomer: false
            });
        }

        this.onFilterDataSet(objFilter);
    }

    multiDropdownChangeEvent = (param_value) => {

        if (param_value == null) { param_value = []; }
        let propFilter = this.state.sharedFindOrderFilter;
        let objFilter = Object.assign(
            {},
            propFilter,
            { 'Skus': param_value }
        );
        this.onFilterDataSet(objFilter);

    }
    setFilter = (objFilter) => {
        this.setState({
            sharedFindOrderFilter:objFilter
        });
    }
    onFilterDataSet = (objFilter) => {
        this.setState({
            sharedFindOrderFilter:objFilter
        });
        this.onFilterDataSetdebounce(objFilter);
    }
    onFilterDataSetdebounce = (objFilter) => {
        this.props.onFilterDataSet(objFilter);
    }
    batchOptionBound = (event) => {
        let noBatchesForCustomer = false;
        if (event.sender.dataSource._data.length <= 0) {
            noBatchesForCustomer = true;
        }
        this.setState({ noBatchesForCustomer: noBatchesForCustomer });
    }

    batchDropdownBound = (batchDropDown) => {
        batchDropDown.bind("filtering", this.batchOptionFilter(batchDropDown));
    }

    batchOptionFilter = (batchDropDown) => (ev) => {
        var filterValue = ev.filter != undefined ? ev.filter.value : "";

        ev.preventDefault();

        if (filterValue == "" || filterValue.length >= 2) {
            let filtersObj = [{
                field: "Description",
                operator: "contains",
                value: filterValue
            }];

            if (!isNaN(filterValue) && filterValue != "") {
                filtersObj.push({
                    field: "BatchOrderId",
                    operator: "eq",
                    value: parseInt(filterValue)
                });
            }

            batchDropDown.dataSource.filter({
                logic: "or",
                filters: filtersObj
            });
        }
    }
    showFilterTooltip = () => {
        this.setState({ showTooltip: true });
    }
    hideFilterTooltip = () => {
        this.setState({ showTooltip: false });
    }
    onSaveFilter = () => {
        if (!this.state.filterNameExist) {
            this.props.onManageFilter('saveFilter',this.state.newFilterTitle);
            this.exitSaveFilterModel();
        }
    }
    onUpdateFilter = () =>{
        this.setState({ isOpenUpdateConfirmModel:false });
        this.props.onManageFilter('updateFilter',this.state.currentFilterIndex);
    }
    onOpenUpdateConfirmModel = (param_index) =>{
        this.setState({
            isOpenUpdateConfirmModel:true,
            currentFilterIndex:param_index
        });
    }
    onCloseUpdateConfirmModel = () =>{
        this.setState({isOpenUpdateConfirmModel:false});
    }
    exitSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: false
        });
    }
    onOpenSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: true,
            newFilterTitle: "",
            filterNameExist: false
        });
    }

    handleFilterTitleChange = (e) => {
        let value = e.target.value;
        if (value.Description == '') {
            value = '';
        }

        var nameExists = false;
        // check dublicate title for case sensitive
        var exists = this.state.savedFilterList.findIndex(function (item) {
            return value.toLowerCase() === item.title.toLowerCase();
        });
        if (exists != -1) {
            nameExists = true;
        }
        this.setState({
            newFilterTitle: value,
            filterNameExist: nameExists
        });
    }
    onUseSaveFilter = (index) => {
        var objFilter = JSON.parse(JSON.stringify(this.state.savedFilterList[index]));
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var gridConfig = JSON.parse(objFilter.columnData, JSON.dateParser);
        var tempObject = "";

        if (gridConfig.columns) {
            var fieldArray = gridConfig.columns.map(el => el.field);
            var updateColumn = [];
            this.state.allColumnList.filter((column) => {
                if (fieldArray.indexOf(column.field) !== -1) {
                    updateColumn.push(column);
                }
            });
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, updateColumn, "field");
        } else {
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, this.props.defaultColumns, "field");
        }

        if(grid) {
            grid.setOptions({
                columns: tempObject
            });
            grid.dataSource.sort(gridConfig.sorts);
            grid.dataSource.filter(gridConfig.filters);
            this.setState({
                sharedFindOrderFilter:objFilter.filterData
            });
            this.props.onApplySavedFilter(objFilter.filterData);
        }
    }
    onYesRemoveSavedFilter = () => {
        metaDataService.clearSaveFindOrderFilterListMetaData(this.state.savedFilterIndex);
        this.setState({
            isOpenConfirmModel: false,
            savedFilterList: metaDataService.getSaveFindOrderFilterListMetaData()
        });
    }
    ClickExit = () => {
        this.setState({
            isOpenConfirmModel: false
        });
    }
    onClearSaveFilter = (index) => {
        this.setState({
            isOpenConfirmModel: true,
            savedFilterIndex: index
        });
    }

    updateSearchTxt = (event) => {
        this.setState({
            searchTxt: event.target.value
        });
    }

    setCollapseMetaData = (isExpanded, collapseId) => {
        metaDataService.setFindOrderCollapseMetaData(collapseId, isExpanded);
    }

    clickForFilterToggle = () =>{
        this.setState({ isClickSearchFilterToggle : true });
        this.props.onClickForFilterToggle();
    }
    onClearSearch = () => {
        this.setState({ 
            sharedFindOrderFilter: Object.assign({}, this.props.defaultFilterValue) 
        });
        this.props.onClearSearch();
    }
    
    render() {
        const renderFilter = this.state.sharedFindOrderFilter;
        return (
            <div className={ "find-order-search-holder new-layout-filter-wrapper"+ ( this.state.isClickSearchFilterToggle ? " sectionToggled" : "" )}>
                {((this.props.sharedSelectOrder && this.state.showTooltip) ?
                    <div className="wms-tooltip-content">{this.translate("Order_filter_section_disabled_tooltip")}</div>
                    : ""
                )}
                <div
                    onMouseEnter={this.showFilterTooltip}
                    onMouseLeave={this.hideFilterTooltip}
                    className="filter"
                    onClick={
                        (this.props.sharedSelectOrder == null) ? this.clickForFilterToggle :
                            () => { }
                    }>
                    <i className="fa fa-caret-left" aria-hidden="true"></i>
                    <span>search filters</span>
                </div>
                <div className="find-order-search-filter ">
                    <div className="wms-toolbar">
                        <WmsButton
                            label={this.translate('Label_Clear_Filters')}
                            buttonType='yellow'
                            wmsSelector={this.state.Selector + 'ClearSearchButton'}
                            onClickFunc={this.onClearSearch}
                            icon='fa-minus' />
                        {/* {this.props.sharedSearchOn == true && */}
                        <WmsButton
                            label={this.translate('Label_Save_View')}
                            buttonType='black'
                            wmsSelector={this.state.Selector + 'SaveFilterButton'}
                            onClickFunc={this.onOpenSaveFilterModel}
                            icon='fa-plus' />
                        {/* } */}
                    </div>
                    {this.props.sharedSearchOn == true &&
                        <div className="title">
                            <b>{this.translate('Label_NOTE')}: </b><label >{this.translate('Label_Search_Applied_Filter')}</label>
                        </div>
                    }
                    <div className="warehouse-form-boxes-wrapper">
                        <div className={"warehouse-form-boxes " + (this.props.sharedSearchOn ? "width-title" : "")}>
                            <div className="warehouse-are-content" id="sortable-section">
                                {this.state.savedFilterList.length !== 0 && (
                                    <WmsCollapse id={'search_collapse0'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse0') ? !this.state.isExpandedCollapse['search_collapse0'] : true} showDragIcon={true} headerLabel={this.translate('Label_Saved_Filter_List')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse0')}>
                                        <ul className="filter-list-block">
                                            {this.state.savedFilterList.map((data, index) => (
                                                <li className="filter-list" key={index}>
                                                    <a className="title-text" title={data.title}>{data.title}</a>
                                                    <div className="wms-apply-btn">
                                                        <WmsButton
                                                            label={this.translate('RoleManager_Label_Update')}
                                                            buttonType='green'
                                                            wmsSelector={this.state.Selector + 'UpdateFilterButton'}
                                                            onClickFunc={(e) => this.onOpenUpdateConfirmModel(index)}
                                                            icon='' />
                                                        <WmsButton
                                                            label={this.translate('Label_Apply')}
                                                            buttonType='green'
                                                            wmsSelector={this.state.Selector + 'ApplyFilterButton'}
                                                            onClickFunc={(e) => this.onUseSaveFilter(index)}
                                                            icon='' />
                                                    </div>
                                                    <a className="clear-save-filter" onClick={(e) => this.onClearSaveFilter(index)}><i className="far fa-times-circle" aria-hidden="true"></i></a>
                                                </li>
                                            ))}
                                        </ul>
                                    </WmsCollapse>
                                )}

                                <WmsCollapse id={'search_collapse1'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse1') ? !this.state.isExpandedCollapse['search_collapse1'] : true} showDragIcon={true} headerLabel={this.translate('Label_Filter_Multi_Search')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse1')}>
                                    <WmsInput
                                        id='SearchTarget'
                                        extraWrapperClass="full-width-input"
                                        inputName='SearchTarget'
                                        wmsSelector={this.state.Selector + 'SearchTarget'}
                                        value={renderFilter.SearchTarget}
                                        onChangeFunc={this.handleFilterChange}
                                        onIconClick={this.handleFilterChange}
                                        label={"Search For"}
                                        showIconAfterInput={true}
                                        icon="fa-search"
                                        onMouseEnterFunc={() => {
                                            this.setState({ showSearchTargetTooltip: true });
                                        }}
                                        onMouseLeaveFunc={() => {
                                            this.setState({ showSearchTargetTooltip: false });
                                        }}
                                        isAutoFocus={true}
                                    />
                                    {this.state.showSearchTargetTooltip &&
                                        <span className="search-hover-tooltip">{this.translate('Label_Filter_Multi_Search_Tooltip')}</span>
                                    }
                                </WmsCollapse>

                                <WmsCollapse id={'search_collapse2'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse2') ? !this.state.isExpandedCollapse['search_collapse2'] : true} showDragIcon={true} headerLabel={this.translate('Label_Filter_Search_By_Date')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse2')}>
                                    <WmsDropdown
                                        id="IndexOptionsSearchBy"
                                        name="IndexOptionsSearchBy"
                                        label={this.translate('Label_Filter_Search_By')}
                                        wmsSelector={this.state.Selector + "SearchByOptions"}
                                        value={renderFilter.IndexOptionsSearchBy}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsSearchBy}
                                        textField='description'
                                        valueField='value'
                                        isHalfInput={true}
                                    />

                                    <WmsCustomDateTimePicker
                                        id={'DatePickerStartDate'}
                                        name='StartDate'
                                        wmsSelector={this.state.Selector + "DatePickerStartDate"}
                                        label={this.translate('Label_Begin')}
                                        onChangeFunc={this.handleFilterChange}
                                        value={renderFilter.StartDate}
                                        windowId={this.props.windowId}
                                        isDateParseInCustomFormat={true}
                                    />

                                    {/* <WmsDateTimePicker
                                        id={'DatePickerStartDate'}
                                        name='StartDate'
                                        wmsSelector={this.state.Selector + "DatePickerStartDate"}
                                        label={this.translate('Label_Begin')}
                                        onChangeFunc={this.handleFilterChange}
                                        value={renderFilter.StartDate}
                                        windowId={this.props.windowId}
                                        format="M/d/yyyy h:mm:ss tt"
                                        defaultTime={'00:00:01'} /> */}


                                    <WmsCustomDateTimePicker
                                        id={'DatePickerEndDate'}
                                        name='EndDate'
                                        wmsSelector={this.state.Selector + "DatePickerEndDate"}
                                        label={this.translate('Label_End')}
                                        onChangeFunc={this.handleFilterChange}
                                        value={renderFilter.EndDate}
                                        windowId={this.props.windowId}
                                        defaultTime={'23:59:59'}
                                        isDateParseInCustomFormat={true}
                                    />

                                    {/* <WmsDateTimePicker
                                        id={'DatePickerEndDate'}
                                        name='EndDate'
                                        wmsSelector={this.state.Selector + "DatePickerEndDate"}
                                        label={this.translate('Label_End')}
                                        onChangeFunc={this.handleFilterChange}
                                        value={renderFilter.EndDate}
                                        windowId={this.props.windowId}
                                        format="M/d/yyyy h:mm:ss tt"
                                        defaultTime={'23:59:59'} /> */}
                                </WmsCollapse>

                                <WmsCollapse id={'search_collapse3'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse3') ? !this.state.isExpandedCollapse['search_collapse3'] : true} showDragIcon={true} headerLabel={this.translate('Label_Filter_Search_By_General')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse3')}>
                                    <WmsDropdown
                                        id="Customer"
                                        name="IndexOptionsCustomerId"
                                        label={this.customLabels.Label_Customer}
                                        wmsSelector={this.state.Selector + "Customer"}
                                        value={renderFilter.IndexOptionsCustomerId}
                                        onChangeFunc={this.handleCustomerChange}
                                        options={this.state.optionsCustomer}
                                        textField='name'
                                        valueField='id'
                                    />
                                    <WmsDropdown
                                        id="Facility"
                                        name="IndexOptionsFacilityId"
                                        label={this.customLabels.Label_Warehouse}
                                        wmsSelector={this.state.Selector + "Facility"}
                                        value={renderFilter.IndexOptionsFacilityId}
                                        onChangeFunc={this.handleFilterChange}
                                        options={
                                            (renderFilter.IndexOptionsCustomerId == 0) ?
                                                GlobalService.getFacilityList(true) :
                                                this.state.optionsFacility
                                        }
                                        textField='name'
                                        valueField='id'
                                    />
                                </WmsCollapse>
                                <WmsCollapse id={'search_collapse6'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse6') ? !this.state.isExpandedCollapse['search_collapse6'] : true} showDragIcon={true} headerLabel={this.translate('Label_Filter_Search_By_Status')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse6')}>
                                    <WmsDropdown
                                        id="IndexOptionsStatus"
                                        name="IndexOptionsStatus"
                                        label={this.translate('Label_Filter_Status')}
                                        wmsSelector={this.state.Selector + "IndexOptionsStatus"}
                                        value={renderFilter.IndexOptionsStatus}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsStatus}
                                        valueField='value'
                                        textField='description'
                                    />
                                </WmsCollapse>
                                <WmsCollapse id={'search_collapse4'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse4') ? !this.state.isExpandedCollapse['search_collapse4'] : true} showDragIcon={true} headerLabel={this.translate('Label_Filter_Search_By_Order')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse4')}>
                                    {(this.state.Rights.hasOwnProperty('orders.manage.batch') && renderFilter.IndexOptionsCustomerId) ?
                                        <div className={renderFilter.BatchOrderid ? 'add-clear-icon' : ''}>
                                        <WmsDropdownList
                                            key={"DropdownlistCustomerBatches"+renderFilter.BatchOrderid}
                                            id="DropdownlistCustomerBatches"
                                            name="BatchOrderid"
                                            optionsLabel={this.translate('Label_Choose_Batch')}
                                            wmsSelector={this.state.Selector + "CustomerBatches"}
                                            value={renderFilter.BatchOrderid}
                                            onChangeFunc={this.handleFilterChange}
                                            textField='Description'
                                            valueField='BatchOrderId'
                                            showClearBtn={true}
                                            template={"<span class='templtehere' style='white-space: nowrap;text-overflow: ellipsis;display: block;overflow: hidden'>(${ BatchOrderId }) ${  (Description != null) ? Description : ''  }</span>"}
                                            valueTemplate={"<span class='templtehere' style='white-space: nowrap;text-overflow: ellipsis;display: block;overflow: hidden'>(${ BatchOrderId }) ${  (Description != null) ? Description : '' }</span>"}
                                            onDataBound={this.batchOptionBound}
                                            onDropdownBound={this.batchDropdownBound}
                                            isValuePrimitive={true}
                                            virtualObj={{
                                                itemHeight: 26,
                                                valueMapper: function (options) {
                                                    var that = options;
                                                    $.ajax({
                                                        url: "/WebUI/Shipping/FindOrders/BatchListForCustomerIndexRead",
                                                        type: "POST",
                                                        dataType: "json",
                                                        data: {
                                                            batchOrderId: that.value || 0,
                                                            customerId: parseInt(renderFilter.IndexOptionsCustomerId)
                                                        },
                                                        success: function (data) {
                                                            // data = [0];
                                                            options.success(data);
                                                        }
                                                    });
                                                }
                                            }}
                                            dataSourceObj={{
                                                pageSize: 40,
                                                serverPaging: true,
                                                serverFiltering: true,
                                                type: "aspnetmvc-ajax",
                                                transport: {
                                                    read: {
                                                        url: "/WebUI/Shipping/FindOrders/BatchListForCustomerRead",
                                                        type: "POST",
                                                        data: {
                                                            customerId: parseInt(renderFilter.IndexOptionsCustomerId)
                                                        }
                                                    },
                                                },
                                                schema: {
                                                    data: "Data",
                                                    total: "Total"
                                                }
                                            }}
                                        />
                                        </div>
                                        : ''

                                    }
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="SearchByReferenceNumber"
                                            name="IndexOptionsReferenceNumber"
                                            label={this.translate('Orders_Lbl_Reference_Num')}
                                            wmsSelector={this.state.Selector + "SearchByReferenceNumber"}
                                            value={renderFilter.IndexOptionsReferenceNumber}
                                            onChangeFunc={this.handleFilterChange}
                                            options={this.state.optionsReferenceNumber}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Sku'
                                            inputName='ReferenceNumber'
                                            wmsSelector={this.state.Selector + 'ReferenceNumber'}
                                            value={renderFilter.ReferenceNumber}
                                            onChangeFunc={this.handleFilterChange}
                                            label=''
                                            extraWrapperClass="full-width-input"
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="SearchByPurchaseOrderNumber"
                                            name="IndexOptionsPoNum"
                                            label={this.translate('NavBarV1_Btn_POs')}
                                            wmsSelector={this.state.Selector + "SearchByPurchaseOrderNumber"}
                                            value={renderFilter.IndexOptionsPoNum}
                                            onChangeFunc={this.handleFilterChange}
                                            options={this.state.optionsReferenceNumber}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='PurchaseOrderNumber'
                                            inputName='PurchaseOrderNumber'
                                            wmsSelector={this.state.Selector + 'PurchaseOrderNumber'}
                                            value={renderFilter.PurchaseOrderNumber}
                                            onChangeFunc={this.handleFilterChange}
                                            label=''
                                            extraWrapperClass="full-width-input"
                                            parentClassName='input_box' />

                                    </div>
                                    {renderFilter.ShowSku && (
                                        <div className="wms-multiselect-input-wrapper">
                                            {renderFilter.IndexOptionsCustomerId < 1 ? (
                                                <WmsInput
                                                    id='Skus'
                                                    inputName='Skus'
                                                    wmsSelector={this.state.Selector + 'SelectedSku'}
                                                    value={
                                                        (renderFilter.Skus && typeof renderFilter.Skus == 'object')
                                                            ? renderFilter.Skus[0]
                                                            : renderFilter.Skus
                                                    }
                                                    onChangeFunc={this.handleFilterChange}
                                                    extraWrapperClass="full-width-input"
                                                    label={this.customLabels.Label_Sku} />
                                            ) :
                                                (
                                                    <div className="wms-multiselect-input">
                                                        {/* <WmsMultiDropdown
                                                            id='MultiSelectCustomerSkus'
                                                            showDetailsTooltip={true}
                                                            name='SelectedSkus'
                                                            label={this.translate('Label_Filter_Sku')}
                                                            wmsSelector={this.state.Selector + "MultiSelectCustomerSkus"}
                                                            isVirtualOptions={true}
                                                            dataSourceObj={{
                                                                pageSize: 40,
                                                                serverPaging: true,
                                                                serverFiltering: true,
                                                                type: "aspnetmvc-ajax",
                                                                transport: {
                                                                    read: {
                                                                        url: "/WebUI/Shipping/FindOrders/SkuDropDownReadLite",
                                                                        type: "POST",
                                                                        data: {
                                                                            customerId: renderFilter.IndexOptionsCustomerId //4
                                                                        }
                                                                    },
                                                                },
                                                                schema: {
                                                                    data: "Data",
                                                                    total: "Total"
                                                                }
                                                            }}
                                                            value={renderFilter.Skus}
                                                            valueField='Sku'
                                                            textField='Sku'
                                                            onChangeFunc={this.multiDropdownChangeEvent} /> */}
                                                            <WmsUpdateDropdownList
                                                                id="MultiSelectCustomerSkus"
                                                                name="MultiSelectCustomerSkus"
                                                                label={this.customLabels.Label_Sku}
                                                                optionsLabel={this.translate('Label_Filter_Sku')}
                                                                wmsSelector={this.state.Selector + "MultiSelectCustomerSkus"}
                                                                value={renderFilter.Skus}
                                                                onChangeFunc={this.multiDropdownChangeEvent}
                                                                textField='Sku'
                                                                valueField='Sku'
                                                                virtualObj={{
                                                                    itemHeight: 26
                                                                }}
                                                                isValuePrimitive={true}
                                                                dataSourceObj={{
                                                                    pageSize: 40,
                                                                    serverPaging: true,
                                                                    serverFiltering: true,
                                                                    type: "aspnetmvc-ajax",
                                                                    transport: {
                                                                        read: {
                                                                            url: "/WebUI/Shipping/FindOrders/SkuDropDownReadLite",
                                                                            type: "POST",
                                                                            data: {
                                                                                customerId: renderFilter.IndexOptionsCustomerId //4
                                                                            }
                                                                        },
                                                                    },
                                                                    schema: {
                                                                        data: "Data",
                                                                        total: "Total"
                                                                    }
                                                                }}
                                                            />
                                                    </div>
                                                )}
                                        </div>
                                    )}
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="SearchByShipTo"
                                            name="IndexOptionsShipTo"
                                            label={this.translate('Label_Filter_Ship_To')}
                                            wmsSelector={this.state.Selector + "SearchByShipTo"}
                                            value={renderFilter.IndexOptionsShipTo}
                                            onChangeFunc={this.handleFilterChange}
                                            options={this.state.optionsShipTo}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='ShipTo'
                                            inputName='ShipTo'
                                            wmsSelector={this.state.Selector + 'ShipTo'}
                                            value={renderFilter.ShipTo}
                                            onChangeFunc={this.handleFilterChange}
                                            label=''
                                            extraWrapperClass="full-width-input"
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="SearchByOrderNote"
                                            name="IndexOptionsOrderNote"
                                            label={this.customLabels.Label_Warehouse + ' ' + this.translate('Label_Filter_Order_Note')}
                                            wmsSelector={this.state.Selector + "SearchByOrderNote"}
                                            value={renderFilter.IndexOptionsOrderNote}
                                            onChangeFunc={this.handleFilterChange}
                                            options={this.state.optionsOrderNote}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='OrderNote'
                                            inputName='OrderNote'
                                            wmsSelector={this.state.Selector + 'OrderNote'}
                                            value={renderFilter.OrderNote}
                                            onChangeFunc={this.handleFilterChange}
                                            label=''
                                            extraWrapperClass="full-width-input"
                                            parentClassName='input_box' />
                                    </div>
                                    <WmsDropdown
                                        id="SearchByInventoryAllocated"
                                        name="IndexOptionsInventoryAllocated"
                                        label={this.translate('Label_Filter_Inventory_Allocated')}
                                        wmsSelector={this.state.Selector + "SearchByInventoryAllocated"}
                                        value={renderFilter.IndexOptionsInventoryAllocated}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsInventoryAllocated}
                                        valueField='value'
                                        textField='description'
                                    />
                                    <WmsDropdown
                                        id="SearchByAsn"
                                        name="IndexOptionsAsn"
                                        label={this.translate('Label_Filter_Asn')}
                                        wmsSelector={this.state.Selector + "SearchByAsn"}
                                        value={renderFilter.IndexOptionsAsn}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsASN}
                                        valueField='value'
                                        textField='description'
                                    />
                                    {/* <WmsDropdown
                                        id="SearchByRouteRequest"
                                        name="IndexOptionsRouteRequest"
                                        label={this.translate('Label_Filter_Route_Request')}
                                        wmsSelector={this.state.Selector + "SearchByRouteRequest"}
                                        value={renderFilter.IndexOptionsRouteRequest}
                                        onChangeFunc={() => function () { }}
                                        options={this.state.OptionsRouteRequest}
                                        valueField='value'
                                        textField='description'
                                        parentClassName="select_box"
                                    /> */}
                                </WmsCollapse>

                                <WmsCollapse id={'search_collapse5'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse5') ? !this.state.isExpandedCollapse['search_collapse5'] : true} showDragIcon={true} headerLabel={this.translate('Label_Filter_Search_By_Transaction_Id')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse5')}>
                                    <WmsInput
                                        id='FirstTransaction'
                                        inputName='FirstTransaction'
                                        wmsSelector={this.state.Selector + 'FirstTransaction'}
                                        value={renderFilter.FirstTransaction}
                                        onChangeFunc={this.handleFilterChange}
                                        extraWrapperClass="full-width-input"
                                        label={this.translate('First_Orders_Lbl_Transaction_Id')} />
                                    <WmsInput
                                        id='LastTransaction'
                                        inputName='LastTransaction'
                                        wmsSelector={this.state.Selector + 'LastTransaction'}
                                        value={renderFilter.LastTransaction}
                                        onChangeFunc={this.handleFilterChange}
                                        extraWrapperClass="full-width-input"
                                        label={this.translate('Last_Orders_Lbl_Transaction_Id')} />
                                </WmsCollapse>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {this.state.isOpenSaveFilterModel && (
                        <WmsModel
                            id='saveCurrentFilter'
                            title={this.translate('Label_Save_Filter')}
                            width={450}
                            height={260}
                            onCloseFunc={this.exitSaveFilterModel}
                            isOpen={this.state.isOpenSaveFilterModel}
                            customClass='wms-model-new-ui-wrapper'>
                            <div className="model-content-wrapper">
                            <WmsContainer>
                                <WmsInput
                                    inputName="newFilterTitle"
                                    name="newFilterTitle"
                                    wmsSelector={this.state.Selector + 'FilterTitle'}
                                    value={this.state.newFilterTitle}
                                    onChangeFunc={this.handleFilterTitleChange}
                                    extraWrapperClass="full-width-input"
                                    label={this.translate('Label_Filter_Title')} />
                            </WmsContainer>
                            </div>
                            <div className="save-filter-button-wrapper footer-btn-holder">
                                <WmsButton
                                    label={this.translate('Label_Close')}
                                    wmsSelector={this.state.Selector + "SaveNewFilterExit"}
                                    onClickFunc={this.exitSaveFilterModel}
                                    buttonType="orange"
                                    icon={'fa-times-circle-o'}
                                />
                                <WmsButton
                                    label={this.translate('Label_Add')}
                                    wmsSelector={this.state.Selector + "SaveNewFilter"}
                                    onClickFunc={this.onSaveFilter}
                                    disabled={this.state.newFilterTitle == '' || this.state.filterNameExist}
                                    icon={'fa-plus'}
                                    showToolTip={this.state.filterNameExist}
                                    tooltipText={this.translate('Label_Filter_Name_Exist_Message')}
                                />
                            </div>
                        </WmsModel>
                    )}
                </div>
                <WmsConfirmModel
                    id={"ConfirmModel" + this.props.windowId}
                    isOpen={this.state.isOpenConfirmModel}
                    type={this.translate('Label_Confirmation')}
                    width={500}
                    height={175}
                    firstBtnLabel={this.translate('Label_Yes')}
                    firstBtnIcon={"fa-check-square"}
                    firstBtnOnClickFunc={this.onYesRemoveSavedFilter}
                    sawCheckBox={false}
                    conFormModelText={this.translate('Label_are_you_sure_remove_filter')}
                    onCloseFunc={this.ClickExit}
                    thirdBtnLabel={this.translate('Label_No')}
                    thirdBtnIcon={"fa-times-circle"}
                />
                <WmsConfirmModel
                    id={"ConfirmUpdateModel" + this.props.windowId}
                    isOpen={this.state.isOpenUpdateConfirmModel}
                    type={this.translate('Label_Confirmation')}
                    width={500}
                    height={175}
                    firstBtnLabel={this.translate('Label_Yes')}
                    firstBtnIcon={"fa-check-square"}
                    firstBtnOnClickFunc={this.onUpdateFilter}
                    sawCheckBox={false}
                    conFormModelText={this.translate('Label_are_you_sure_update_filter')}
                    onCloseFunc={this.onCloseUpdateConfirmModel}
                    thirdBtnLabel={this.translate('Label_No')}
                    thirdBtnIcon={"fa-times-circle"}
                    isLoadingStop={false}
                />
            </div >
        );
    }
}