import React from "react";
import { default as WmsModal } from "../../components/WmsModel/WmsModel.jsx";
import WmsButton from "../../components/WmsButton/WmsButton.jsx";
import WmsCollapse from "../../components/WmsCollapse/WmsCollapse.jsx";
import "./view-rate-shopping-details-modal.scss";

interface ViewRateShoppingDetailsModalProps {
    rateShopDetailsData: any;
    open: boolean;
    onClose: () => void;
}

export default function ViewRateShoppingDetailsModal(props: ViewRateShoppingDetailsModalProps) {
    const translate = (window as any).translate as any;

    const wmsSelector = "ViewRateShoppingDetails";
    const ViewRateShoppingDetailsModalId = "ViewRateShoppingDetailsModal";

    return (
        <WmsModal
            isOpen={props.open}
            id={ViewRateShoppingDetailsModalId}
            title={translate("Lable_Rates_Shopping_Details")}
            width={500}
            height={300}
            onCloseFunc={props.onClose}
            class="print-batched-shipping-model-wrapper"
            customClass="wms-model-new-ui-wrapper"
        >
            <div className="model-content-wrapper">
                <WmsCollapse
                    id={"shippingRateAmountInfo"}
                    headerLabel={translate("Label_Rate_Amounts")}
                    showCollapseText={false}
                >
                    <table>
                        <tbody>
                            <tr>
                                <th>Total:</th>
                                <td>
                                    $
                                    {props.rateShopDetailsData.Rate.total
                                        ? parseFloat(props.rateShopDetailsData.Rate.total).toFixed(2)
                                        : "0.00"}
                                </td>
                            </tr>
                            <tr>
                                <th>Confirmation:</th>
                                <td>
                                    $
                                    {props.rateShopDetailsData.Rate.confirmation
                                        ? parseFloat(props.rateShopDetailsData.Rate.confirmation).toFixed(2)
                                        : "0.00"}
                                </td>
                            </tr>
                            <tr>
                                <th>Insurance:</th>
                                <td>
                                    $
                                    {props.rateShopDetailsData.Rate.insurance
                                        ? parseFloat(props.rateShopDetailsData.Rate.insurance).toFixed(2)
                                        : "0.00"}
                                </td>
                            </tr>
                            <tr>
                                <th>Other:</th>
                                <td>
                                    $
                                    {props.rateShopDetailsData.Rate.other
                                        ? parseFloat(props.rateShopDetailsData.Rate.other).toFixed(2)
                                        : "0.00"}
                                </td>
                            </tr>
                            <tr>
                                <th>Shipping:</th>
                                <td>
                                    $
                                    {props.rateShopDetailsData.Rate.shipping
                                        ? parseFloat(props.rateShopDetailsData.Rate.shipping).toFixed(2)
                                        : "0.00"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </WmsCollapse>
                <WmsCollapse
                    id={"shippingRateDetailInfo"}
                    headerLabel={translate("Label_Rate_Details")}
                    showCollapseText={false}
                >
                    <table>
                        <tbody>
                            {props.rateShopDetailsData.RateDetails &&
                                props.rateShopDetailsData.RateDetails.map((rateDetailData, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <th>Description:</th>
                                            <td>{rateDetailData.Description}</td>
                                        </tr>
                                        <tr className="paddingBottom">
                                            <th>Amount:</th>
                                            <td>${parseFloat(rateDetailData.Amount).toFixed(2)}</td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                        </tbody>
                    </table>
                </WmsCollapse>
            </div>
            <div className="footer-btn-holder">
                <WmsButton
                    id={wmsSelector + "close"}
                    name={wmsSelector + "close"}
                    label={translate("Label_Close")}
                    wmsSelector={wmsSelector + "close"}
                    onClickFunc={props.onClose}
                    icon="fa-times-circle-o"
                    buttonType="orange"
                />
            </div>
        </WmsModal>
    );
}
