
import React from 'react';

//components
import VirtualGridComponent from './../components_proto/VirtualGridComponent/VirtualGridComponent.jsx';
import { AddEditColumnModel } from './../components/AddEditColumn/add-edit-columns.component.jsx';
import WmsConfirmModel from './../components/WmsConfirmModel/WmsConfirmModel.jsx';
import { NewCarrierConnectionModel } from './model/new-carrier-connection.component.jsx';

import { ArrayWms } from './../../../assets/ts/ArrayWms.ts';
import { StringWms } from './../../../assets/ts/StringWms.ts';
import * as GridConfig from './grid_configuration.jsx';
import style from './manage-carriers-holder.scss';
//services
import * as GlobalService from './../global_services';
import * as carrierService from './carriers.services.jsx';
import * as metaDataService from './manage-carrier-meta-data.service';

export class ManageCarriersGrid extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window;
        let carrier_GridHeaders = StringWms.convertSringToOption(this.translate('Carrier_Header'));
        this.manageCarrierGridId = 'manageCarrierGrid'
        this.state = {
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.manageCarrierGridId : '#' + this.manageCarrierGridId,
            localKendoGrid: null,
            selector: 'manageCarriers',
            gridColumns: GridConfig.getGridDemoColumns(carrier_GridHeaders, this.props.sharedSelectedFacilityObj).columns,
            gridSchema: GridConfig.getGridDemoSchema().schema,
            manageCarrierGridConfig: metaDataService.getManageCarrierGridGridOptionInMetaData(),
            sharedIsOpennewConnectionModel: false,
            sharedModelFlow: '',
            selectedCarrierAccountData: null,
            isEditCarrierDisable: true,
            isOpenColumnModel: false,
            deleteCarrierLoader: false,
            isOpenCarrierDeleteConfirmModel: false,
            filterText: "",
            fullFilterText: ""
        }
    }

    openNewConnectionModel = () => {
        this.setState({
            sharedIsOpennewConnectionModel: true,
            sharedModelFlow: 'create'
        });
    }

    editConnection = () => {
        this.setState({
            sharedIsOpennewConnectionModel: true,
            sharedModelFlow: 'edit'
        });
    }
    onCloseNewConnectionModel = () => {
        this.setState({ sharedIsOpennewConnectionModel: false });
    }
    onOpenCarrierDeleteConfirmModel = () => {
        this.setState({ isOpenCarrierDeleteConfirmModel: true });
    }
    onCloseCarrierDeleteConfirmModel = () => {
        this.setState({ isOpenCarrierDeleteConfirmModel: false });
    }

    deleteCarrierAccount = () => {
        this.setState({ deleteCarrierLoader: true });

        let carrierAccountUri = this.state.selectedCarrierAccountData.Uri;

        carrierService.deleteCarrierAccount(carrierAccountUri).then((response) => {
            if (response && response.status == 200) {
                GlobalService.notification('success', this.translate('Label_CarrierAccount_Deleted'));
                this.props.onRefreshGridData();
                this.onCloseCarrierDeleteConfirmModel();
            }
        }).finally(() => {
            this.setState({ deleteCarrierLoader: false });
        });
    }

    selectRow = (param_kendogrid) => {

        let currentSelections = param_kendogrid.getNumberOfSelections();
        let selectedCarrierAccountData = null;
        let isEditCarrierDisable = true;

        if (currentSelections === 1) {
            selectedCarrierAccountData = param_kendogrid.getItem(param_kendogrid.getKeys());
            if (selectedCarrierAccountData.Carrier == 'UPS' || selectedCarrierAccountData.Carrier == 'FedEx') {
                isEditCarrierDisable = false;
            }
        }

        this.setState({
            selectedCarrierAccountData: selectedCarrierAccountData,
            isEditCarrierDisable: isEditCarrierDisable
        });
    }
    eventClickColumns = () => {
        this.setState({ isOpenColumnModel: true });
    }
    onCloseColumnModel = (e) => {
        this.setState({ isOpenColumnModel: false });
    }
    onGridDataBound = (e, kendoGrid) => {
        this.setState({
            localKendoGrid: kendoGrid
        })
    }
    // on grid column sort save changes in meta data object
    onSort = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
        metaDataService.setManageCarrierGridGridOptionInMetaData(finalSubmission);
    }

    // on grid column reorder save changes in meta data object
    onColumnReorder = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(switchedArray, sortArray, grid.dataSource.filter());
        metaDataService.setManageCarrierGridGridOptionInMetaData(finalSubmission);

    }

    // on grid column resize save changes in meta data object
    onColumnResize = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        metaDataService.setManageCarrierGridGridOptionInMetaData(finalSubmission);
    }

    // on click grid clear sort menu
    onClickClearSorts = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        metaDataService.setManageCarrierGridGridOptionInMetaData(finalSubmission);
    }

    // on click grid reset default grid layout menu
    onClickResetGrid = () => {
        var finalSubmission = {
            "optionsData": {}
        }
        metaDataService.setManageCarrierGridGridOptionInMetaData(finalSubmission);
    }

    // save column hide/show data in meta data
    saveChangesInMetaData = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sort,
            grid.dataSource.filter()
        );
        metaDataService.setManageCarrierGridGridOptionInMetaData(finalSubmission);
    }
    // on grid filter selection
    onGridFilterApplied = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            this.setFilterAppliedText(dataSource.filter());

            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, dataSource.filter());

            metaDataService.setManageCarrierGridGridOptionInMetaData(finalSubmission);

        }, 100);

    }

    setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            this.setState({
                filterText: "",
                fullFilterText: ""
            });
            return;
        }
        var filterConditionTitle = {
            "lte": "Is Less Than Or Equal To",
            "eq": "Is Equal To",
            "lt": "Is Less Than",
            "neq": "Is Not Equal To",
            "gte": "Is Greater Than Or Equal To",
            "gt": "Is Greater Than",
            "startswith": "Starts With",
            "endswith": "Ends With",
            "contains": "Contains",
            "doesnotcontain": "Does Not Contain",
            "isnull": "Is Null",
            "isnotnull": "Is Not Null",
            "isempty": "Is Empty",
            "isnotempty": "Is Not Empty",
            "isnullorempty": "Has No Value",
            "isnotnullorempty": "Has Value",
        };

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;
        var self = this;
        filterList.map(function (item) {
            var columnObj = self.state.gridColumns.find(x => x.field === item.field);
            if (columnObj) {
                var title = columnObj.title;
                filterText += title + " : " + item.value + ',';
                fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            }
        });
        filterText = filterText.slice(0, -1);
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var dataSource = grid.dataSource;
        var recordsOnCurrentView = dataSource.view().length;

        this.setState({
            filterText: filterText,
            fullFilterText: fullFilterText
        });
    }

    render() {

        return (
            <div className="manage-carrier-right-side" >
                <div className="manage-carrier-grid-holder">
                    <VirtualGridComponent
                        wmsSelector={this.state.selector + "manageCarrierGrid"}
                        gridKeySelection={'RowNumber'}
                        staticGridData={this.props.sharedStaticGridData}
                        totalResult={this.props.sharedStaticGridData ? this.props.sharedStaticGridData.length : 0}
                        gridName={'manageCarrierGrid'}
                        virtual={true}
                        filterText={this.state.filterText}
                        fullFilterText={this.state.fullFilterText}
                        allColumns={this.state.gridColumns}
                        defaultColumns={this.state.gridColumns}
                        showGridResult={true}
                        showSortColumns={true}
                        shawCheckbox={false}
                        id={this.manageCarrierGridId}
                        onSelection={this.selectRow}
                        schema={this.state.gridSchema}
                        clickEventOfContextMenu={() => { }}
                        eventClickColumns={this.eventClickColumns}
                        resetButtonLabel={this.translate('Label_Costomer_Options')}
                        resetButtonIcon={'fa-sliders'}
                        metaDataFieldsName={''}
                        onGridFilterApplied={this.onGridFilterApplied}
                        metaGridConfig={this.state.manageCarrierGridConfig}
                        onDataBound={this.onGridDataBound}
                        onSort={this.onSort}
                        onColumnReorder={this.onColumnReorder}
                        onColumnResize={this.onColumnResize}
                        onClickClearSorts={this.onClickClearSorts}
                        onClickResetGrid={this.onClickResetGrid}
                        menu={[{
                            "name": this.translate('Label_Create'),
                            "value": [],
                            // "disabled": !this.props.sharedSelectedCustomer.id,
                            "icon": "fa-plus",
                            "color": "green",
                            'isContextMenu': false,
                            'isButtonMenu': true,
                            'wmsid': 'create',
                            'wmsSelector': this.state.selector + "CreateCarrier",
                            'clickFunc': this.openNewConnectionModel,
                        }, {
                            "name": this.translate('Label_Edit'),
                            "value": [],
                            "disabled": this.state.isEditCarrierDisable,
                            "showToolTip": (this.state.selectedCarrierAccountData != null),
                            "tooltipText": this.translate('Carrier_EDIT_DISABLE_TEXT'),
                            "icon": "fa-pencil",
                            "color": "green",
                            'isContextMenu': false,
                            'isButtonMenu': true,
                            'wmsid': 'edit',
                            'wmsSelector': this.state.selector + "EditCarrier",
                            'clickFunc': this.editConnection,
                        }, {
                            "name": this.translate('Label_Delete'),
                            "value": [],
                            "icon": "fa-trash",
                            "color": "red",
                            'isContextMenu': false,
                            'isButtonMenu': true,
                            'wmsid': 'expandCollapse',
                            'wmsSelector': this.state.selector + "ExpandCarrier",
                            'clickFunc': this.onOpenCarrierDeleteConfirmModel,
                            "disabled": (this.state.selectedCarrierAccountData == null)
                        }, {
                            "name": '',
                            "wmsSelector": this.state.selector + 'RefreshGrid',
                            "value": [],
                            "clickFunc": this.props.onRefreshGridData,
                            "disabled": false,
                            "icon": "fa-sync-alt",
                            "color": "blue",
                            'isContextMenu': false,
                            'isButtonMenu': true,
                            'parentClassName': 'carrierGridRefreshButton'
                        }]} />


                    {this.state.isOpenColumnModel && (
                        <AddEditColumnModel
                            windowId={this.props.windowId}
                            id={"addEditColumnCarrierGrid"}
                            height={700}
                            onCloseColumnModel={this.onCloseColumnModel}
                            isOpenColumnModel={this.state.isOpenColumnModel}
                            allSortColumnList={this.state.gridColumns}
                            gridId={this.manageCarrierGridId}
                            updateGridData={() => { }}
                            saveChangesInMetaData={this.saveChangesInMetaData}
                        />
                    )}
                </div>
                {this.state.sharedIsOpennewConnectionModel && (
                    <NewCarrierConnectionModel
                        sharedIsOpennewConnectionModel={this.state.sharedIsOpennewConnectionModel}
                        sharedSelectedCarrierAccountData={this.state.selectedCarrierAccountData}
                        sharedModelFlow={this.state.sharedModelFlow}
                        onCloseNewConnectionModel={this.onCloseNewConnectionModel}
                        sharedFacilityOption={this.props.sharedFacilityOption}
                        sharedSelectedCustomer={this.props.sharedSelectedCustomer}
                        sharedSelectedCustomerIndex={this.props.sharedSelectedCustomerIndex}
                        onRefreshGridData={this.props.onRefreshGridData} />
                )}
                <div>
                    <WmsConfirmModel
                        id={"confirmDeleteCarrier" + this.props.windowId}
                        isOpen={this.state.isOpenCarrierDeleteConfirmModel}
                        type={this.translate('Label_Confirmation_carrier')}
                        width={500}
                        height={175}
                        firstBtnLabel={this.translate('Label_Delete_Carrier')}
                        firstBtnIcon={"fa-trash"}
                        firstButtonType={"red"}
                        firstBtnOnClickFunc={this.deleteCarrierAccount}
                        sawCheckBox={false}
                        conFormModelText={this.translate('Label_Delete_Carrier_Confirm')}
                        thirdBtnLabel={this.translate('Label_Cancel')}
                        thirdBtnIcon={"fa-ban"}
                        isLoadingStop={!this.state.deleteCarrierLoader}
                        onCloseFunc={this.onCloseCarrierDeleteConfirmModel}
                    />
                </div>
            </div >
        )

    }
}