import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';

//component
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsCheckBox from './../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';

//services
import * as GlobalService from './../global_services';

export class CustomersFormReceiptOption extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        let palletOptions = GlobalService.getCustomLabels().Label_Movable_Unit + "s" + " disabled," + "Allow " + GlobalService.getCustomLabels().Label_Movable_Unit + "s," + "Require " + GlobalService.getCustomLabels().Label_Movable_Unit + "s";

        this.state = {
            CustomLabels: GlobalService.getCustomLabels(),
            TplSettings: GlobalService.getTplSettings(),
            Selector: 'Customer',
            rights: GlobalService.GetRights(),
            option: {
                TrackLocationEnum: StringWms.convertSringToOption(this.translate('Options_TrackLocations')),
                TrackSupplierEnum: StringWms.convertSringToOption(this.translate('Options_TrackSupplier')),
                ReceiveAgainstAsnsEnum: StringWms.convertSringToOption(this.translate('Options_ASNOptions')),
                TrackPalletsEnum: StringWms.convertSringToOption(this.translate(palletOptions)),
            },
        };
    }

    handleChange = (param_parent_property, event) => {

        let name = event.target.name;
        let parentObj = Object.assign({}, this.props.sharedCustomer.Options[param_parent_property]);

        if(event.target.type == 'checkbox'){
            parentObj[name] = !this.props.sharedCustomer.Options[param_parent_property][name] ;
        }else{
            parentObj[name]= event.target.value;
        }

        if (name == 'AllowRequiredCustomFields' && parentObj.AllowRequiredCustomFields == false) {
            parentObj.AllowOptionalCustomFields = false
        }

        let customerObj = Object.assign(
            {},
            this.props.sharedCustomer,
            {
                Options: Object.assign(
                    {},
                    this.props.sharedCustomer.Options,
                    {
                        [param_parent_property]: parentObj
                    }
                )
            }
        );
        this.props.onCustomerDataset(customerObj);
    }

    handleMobileReceivingOptions = (event) => {
        let receivingOptions = Object.assign({}, this.props.sharedCustomer.Options.Receiving);
        let ReceiveAgainstAsns = receivingOptions.ReceiveAgainstAsns;

        if (event.target.name == 'ReceiveAgainstAsns') {
            ReceiveAgainstAsns = ($(event.target).prop("checked")) ? 1 : 0;
        }
        if (event.target.name == 'UseBlindReceipts') {
            ReceiveAgainstAsns = ($(event.target).prop("checked")) ? 2 : 1;
        }

        let eventObj = {
            target: {
                name: 'ReceiveAgainstAsns',
                value: ReceiveAgainstAsns
            }
        };

        this.handleChange('Receiving', eventObj);
    }


    render() {
        const renderCustomer = this.props.sharedCustomer;
        return (
            <div className="collapseCustomerTabContent_wrapper" id={'collapseReceiptOption'} >
                <WmsCollapse
                    id={'collapseReceiptOptionContent'}
                    headerLabel={this.translate('Label_Receipts')}
                    showCollapseText={false}
                    showCollapseIcon={true} >

                    <WmsContainer title={this.translate('Label_Customer_Proto_General_Settings')}>

                        <WmsDropdown
                            id="TrackLocation"
                            name="TrackLocation"
                            label={this.translate('Label_Costomer_Location_Options')}
                            wmsSelector={this.state.Selector + "LocationOptions"}
                            onChangeFunc={(e) => this.handleChange('Receiving', e)}
                            value={renderCustomer.Options.Receiving.TrackLocation}
                            options={this.state.option.TrackLocationEnum}
                            valueField='value'
                            textField='description'
                            parentClassName='form_group' />

                        <WmsDropdown
                            id="TrackPallets"
                            name="TrackPallets"
                            label={this.state.CustomLabels.Label_Movable_Unit + " " + this.translate('Label_Options')}
                            wmsSelector={this.state.Selector + "MovableUnitsOptions"}
                            onChangeFunc={(e) => this.handleChange('Receiving', e)}
                            value={renderCustomer.Options.Receiving.TrackPallets}
                            options={this.state.option.TrackPalletsEnum}
                            valueField='value'
                            textField='description'
                            parentClassName='form_group' />

                        <WmsInput
                            id='FuelSurcharge'
                            inputName='FuelSurcharge'
                            wmsSelector={this.state.Selector + 'FuelSurcharge'}
                            onChangeFunc={(e) => this.handleChange('Storage', e)}
                            label={this.translate('Label_Costomer_Fuel_surachage_Percentage')}
                            regularExp={/^[1-9][0-9]?$|^100$/}
                            value={renderCustomer.Options.Storage.FuelSurcharge} />

                        {/* Dropdown hide in angular module */}
                        {/* <WmsDropdown
                        id="ReceiveAgainstAsns"
                        name="ReceiveAgainstAsns"
                        label={this.translate('Label_Costomer_ASN_Options')}
                        wmsSelector={this.state.Selector + "ASNOptions "}
                        onChangeFunc={(e) => function () { }}
                        value={''}
                        options={[]}
                        valueField='Value'
                        textField='Name'
                        parentClassName='form_group' /> */}

                        <WmsDropdown
                            id="TrackSupplier"
                            name="TrackSupplier"
                            label={this.translate('Label_Costomer_Supplier_Options')}
                            wmsSelector={this.state.Selector + "SupplierOptions"}
                            onChangeFunc={(e) => this.handleChange('Receiving', e)}
                            value={renderCustomer.Options.Receiving.TrackSupplier}
                            options={this.state.option.TrackSupplierEnum}
                            valueField='value'
                            textField='description'
                            parentClassName='form_group' />

                        <WmsCheckBox
                            id={'AutoCheckAutoReallocateOnReceive'}
                            name={'AutoCheckAutoReallocateOnReceive'}
                            wmsSelector={this.state.Selector + 'AllocateOnReceive'}
                            onChangeFunc={(e) => this.handleChange('UserInterface', e)}
                            label={this.translate('Label_Costomer_chk_Change_default_of_Auto_Allocate_On_Reveive')}
                            checked={renderCustomer.Options.UserInterface.AutoCheckAutoReallocateOnReceive == true}
                            value={renderCustomer.Options.UserInterface.AutoCheckAutoReallocateOnReceive}
                            parentClassName={this.state.rights.hasOwnProperty('Receiving.AutoReallocOnReceiveEnabled') ? '' : 'disabled-chk'}
                            disabled={!this.state.rights.hasOwnProperty('Receiving.AutoReallocOnReceiveEnabled')}
                            showToolTip={!this.state.rights.hasOwnProperty('Receiving.AutoReallocOnReceiveEnabled')}
                            tooltipText={this.translate('Label_Setting_Disable_Tooltip')}
                            tooltipShowAfter={200}
                        />

                        <WmsCheckBox
                            id={'CreateMultipleMus'}
                            name={'CreateMultipleMus'}
                            wmsSelector={this.state.Selector + 'HideLocationSelected'}
                            onChangeFunc={(e) => this.handleChange('Receiving', e)}
                            label={this.translate('Label_Costomer_chk_Hide_the_dropdown_that_lists_all_locations')}
                            checked={renderCustomer.Options.Receiving.CreateMultipleMus == true}
                            value={renderCustomer.Options.Receiving.CreateMultipleMus} />

                        <WmsCheckBox
                            id={'SuggestedPutAwayEnabled'}
                            name={'SuggestedPutAwayEnabled'}
                            wmsSelector={this.state.Selector + 'SuggestedPutAwayEnabled'}
                            onChangeFunc={(e) => this.handleChange('Receiving', e)}
                            label={this.translate('Label_Costomer_chk_allow_the_use_of_suggested_Put_Away')}
                            checked={renderCustomer.Options.Receiving.SuggestedPutAwayEnabled == true}
                            value={renderCustomer.Options.Receiving.SuggestedPutAwayEnabled}
                            parentClassName={this.state.rights.hasOwnProperty('Receiving.SuggestedPutAwayEnabled') ? '' : 'disabled-chk'}
                        disabled={!this.state.rights.hasOwnProperty('Receiving.SuggestedPutAwayEnabled')}
                        showToolTip={!this.state.rights.hasOwnProperty('Receiving.SuggestedPutAwayEnabled')}
                        tooltipText={this.translate('Label_Setting_Disable_Tooltip')}
                        tooltipShowAfter={200}/>

                </WmsContainer>

                    <WmsContainer title={this.translate('Label_Mobile_Receiving')}>

                        {(this.state.TplSettings.Receiving.AllowReceiveAgainstAsn) &&
                            <div>
                                <WmsCheckBox
                                    id={'ReceiveAgainstAsns'}
                                    name={'ReceiveAgainstAsns'}
                                    wmsSelector={this.state.Selector + 'ReceiveAgainstAsns'}
                                    onChangeFunc={this.handleMobileReceivingOptions}
                                    label={this.translate('Label_Costomer_chk_Receive_Against_ASN')}
                                    checked={renderCustomer.Options.Receiving.ReceiveAgainstAsns == 1 || renderCustomer.Options.Receiving.ReceiveAgainstAsns == 2}
                                    value={renderCustomer.Options.Receiving.ReceiveAgainstAsns} />

                                <WmsCheckBox
                                    id={'UseBlindReceipts'}
                                    name={'UseBlindReceipts'}
                                    wmsSelector={this.state.Selector + 'UseBlindReceipts'}
                                    onChangeFunc={this.handleMobileReceivingOptions}
                                    label={this.translate('Label_Costomer_chk_Use_Blind_Receipts')}
                                    disabled={renderCustomer.Options.Receiving.ReceiveAgainstAsns == 0}
                                    checked={renderCustomer.Options.Receiving.ReceiveAgainstAsns == 2}
                                    value={renderCustomer.Options.Receiving.ReceiveAgainstAsns}
                                    parentClassName={renderCustomer.Options.Receiving.ReceiveAgainstAsns == 0 ? 'disabled-chk' : ''} />
                            </div>
                        }

                        <WmsCheckBox
                            id={'MobileReceiptQuantityOneDefault'}
                            name={'MobileReceiptQuantityOneDefault'}
                            wmsSelector={this.state.Selector + 'ScanQuantityTo1'}
                            onChangeFunc={(e) => this.handleChange('UserInterface', e)}
                            label={this.translate('Label_Costomer_chk_Set_the_default_scan_quantity_to_1')}
                            checked={renderCustomer.Options.UserInterface.MobileReceiptQuantityOneDefault == true}
                            value={renderCustomer.Options.UserInterface.MobileReceiptQuantityOneDefault} />

                        <WmsCheckBox
                            id={'DisplayWeightFieldForReceipt'}
                            name={'DisplayWeightFieldForReceipt'}
                            wmsSelector={this.state.Selector + 'WeightFieldForReceipt'}
                            onChangeFunc={(e) => this.handleChange('Receiving', e)}
                            label={this.translate('Label_Display_Weight_Field_Receipts')}
                            checked={renderCustomer.Options.Receiving.DisplayWeightFieldForReceipt == true}
                            value={renderCustomer.Options.Receiving.DisplayWeightFieldForReceipt} />

                        <WmsCheckBox
                            id={'AllowMovingOpenReceipts'}
                            name={'AllowMovingOpenReceipts'}
                            parentClassName={'allow-moving-open-receipts-wrapper'}
                            wmsSelector={this.state.Selector + 'AllowMovingOpenReceipts'}
                            onChangeFunc={(e) => this.handleChange('MobileProcessingBehaviors', e)}
                            label={this.translate('Label_Allow_Move_Of_Unconfirmed_Inventory')}
                            checked={renderCustomer.Options.MobileProcessingBehaviors.AllowMovingOpenReceipts == true}
                            value={renderCustomer.Options.MobileProcessingBehaviors.AllowMovingOpenReceipts} />
                        <div className='custom-fields-wrapper'>
                            <WmsCheckBox
                                id={'AllowRequiredCustomFields'}
                                name={'AllowRequiredCustomFields'}
                                wmsSelector={this.state.Selector + 'AllowRequiredCustomFields'}
                                onChangeFunc={(e) => this.handleChange('MobileProcessingBehaviors', e)}
                                label={this.translate('Label_Display_Required_Custom_Fields')}
                                checked={renderCustomer.Options.MobileProcessingBehaviors.AllowRequiredCustomFields == true}
                                value={renderCustomer.Options.MobileProcessingBehaviors.AllowRequiredCustomFields} />
                            <WmsCheckBox
                                id={'AllowOptionalCustomFields'}
                                name={'AllowOptionalCustomFields'}
                                parentClassName={(renderCustomer.Options.MobileProcessingBehaviors.AllowRequiredCustomFields == false ? 'disabled-all-custom-fields ' :  '' ) + 'allow-required-custom-fields'}
                                wmsSelector={this.state.Selector + 'AllowOptionalCustomFields'}
                                disabled={renderCustomer.Options.MobileProcessingBehaviors.AllowRequiredCustomFields == false}
                                onChangeFunc={(e) => this.handleChange('MobileProcessingBehaviors', e)}
                                label={this.translate('Label_Display_All_Custom_Fields')}
                                checked={renderCustomer.Options.MobileProcessingBehaviors.AllowOptionalCustomFields == true}
                                value={renderCustomer.Options.MobileProcessingBehaviors.AllowOptionalCustomFields} />
                        </div>

                    </WmsContainer>

                </WmsCollapse>
            </div>
        );
    }
}