import React, { Component } from 'react'
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx'
import WmsInput from '../../../components/WmsInput/WmsInput.jsx'
import WmsDropdown from "../../../components/WmsDropdown/WmsDropdown.jsx";

export class UpsmiAccountInfoComponent extends Component {
    constructor(props) {
        super(props)

        this.translate = window.translate;
        this.$rootScope = window;

        this.serviceEndorsementOptions = [
            {
                Name: "Address Service Requested",
                Value: "address_service_requested",
            },
            {
                Name: "Forwarding Service Requested",
                Value: "forwarding_service_requested",
            },
            {
                Name: "Change Service Requested",
                Value: "change_service_requested",
            },
            {
                Name: "Return Service Requested",
                Value: "return_service_requested",
            },
            {
                Name: "Leave if no response",
                Value: "leave_if_no_response",
            },
        ];
    }

    render() {
        return (
            <WmsCollapse
                id={"upsmiAccountInfoScreen"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id="accountNickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Nick_Name")}
                            value={this.props.currentCarrierObj.Nickname}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="accountNumber"
                            inputName="AccountNumber"
                            wmsSelector={this.props.selector + "accountNumber"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Number")}
                            value={this.props.currentCarrierObj.AccountNumber}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="username"
                            inputName="Username"
                            wmsSelector={this.props.selector + "username"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Username")}
                            value={this.props.currentCarrierObj.Username}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="password"
                            inputType={"password"}
                            inputName="Password"
                            wmsSelector={this.props.selector + "password"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Login_Lbl_Password")}
                            value={this.props.currentCarrierObj.Password}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="mailInnovationsCostCenter"
                            inputName="MailInnovationsCostCenter"
                            wmsSelector={this.props.selector + "mailInnovationsCostCenter"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Innovatios_Cost_Center")}
                            value={this.props.currentCarrierObj.MailInnovationsCostCenter}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsDropdown
                            id="mailInnovationsEndorsement"
                            name="MailInnovationsEndorsement"
                            label={this.translate("Label_Innovations_Endorsement")}
                            wmsSelector={this.props.selector + "mailInnovationsEndorsement"}
                            value={this.props.currentCarrierObj.MailInnovationsEndorsement}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            options={this.serviceEndorsementOptions}
                            required={true}
                            textField="Name"
                            valueField="Value"
                            blankFirstOption={true}
                            blankFirstOptionText="Unspecified"
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="carrierCustomerId"
                            inputName="CarrierCustomerId"
                            wmsSelector={this.props.selector + "carrierCustomerId"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Login_Carrier_CCustomerId")}
                            value={this.props.currentCarrierObj.CarrierCustomerId}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="carrierCustomerGuid"
                            inputName="CarrierCustomerGuid"
                            wmsSelector={this.props.selector + "carrierCustomerGuid"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Login_Carrier_CustomerGuid")}
                            value={this.props.currentCarrierObj.CarrierCustomerGuid}
                            extraWrapperClass="w-42"
                        />
                    </div>
                </div>
            </WmsCollapse>
        )
    }
}
