import React from 'react';

//component
import WmsModel from '../../../../components/WmsModel/WmsModel.jsx';
import WmsGrid from '../../../../components/WmsGrid/WmsGrid.jsx';
import WmsButton from '../../../../components/WmsButton/WmsButton.jsx';
import WmsDropdown from '../../../../components/WmsDropdown/WmsDropdown.jsx';

import './reprint-end-of-day-reoprt.scss';
import * as GlobalService from './../../../../global_services';
import * as findOrdersService from './../../findOrders.services.jsx';
import axios from 'axios';

export class ReprintEODReport extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            selector: "reprintEndOfDay",
            faciltyOptions: GlobalService.getFacilityList(),
            endOfDayNickName: "",
            selectedFacilityId: "",
            selectedWarehouse: "",
            carrierData: [],
            nickNameOption: [],
            gridResourceList: [],
            selectedPrintedItem: 0,
            isRefreshLoading: false,
        }
    }

    componentWillMount() {
        kendo.ui.progress($('#reprintEndOfDayReport'), true);
        axios.get('/WebUI/Settings/CarrierAccount/GetCarrierAccounts?customerUri=all')
            .then((response) => {
                kendo.ui.progress($('#reprintEndOfDayReport'), false);
                var removeNullArray = response.data.filter((item) => { return item.NickName });
                var uniqNickName = _.uniqBy(removeNullArray, function (elem) { return [elem.NickName, elem.ShipEngineId].join(); });
                uniqNickName.push({
                    NickName: "All",
                    ShipEngineId: "all"
                });
                var ShipEngineId = "";
                if (uniqNickName.length > 0) {
                    ShipEngineId = uniqNickName[0].ShipEngineId;
                } else {
                    ShipEngineId = "";
                }
                this.setState({
                    nickNameOption: uniqNickName,
                    endOfDayNickName: ShipEngineId
                })
            }).finally(() => {
                kendo.ui.progress($('#reprintEndOfDayReport'), false);
            });

    }

    getIdByName = (name) => {
        return this.state.faciltyOptions.find((element) => {
            if (element.name == name) {
                return element.id;
            }
        });
    }
    getOptionsByName = (name) => {
        var returnResponse = [];
        this.state.carrierData.map((element) => {
            if (element.Owner == name) {
                returnResponse.push(element);
            }
        });
        return returnResponse;
    }
    changeWarehouse = (event) => {
        this.setState({
            selectedWarehouse: event.target.value
        });
        var selectedFacilityObj = this.getIdByName(event.target.value);
        var selectedFacilityId = "";
        var ShipEngineId = "";
        if (selectedFacilityObj) {
            selectedFacilityId = selectedFacilityObj.id;
        }
        this.setState({
            selectedFacilityId: selectedFacilityId,
        }, this.getGridData);
    }
    changeNickName = (e) => {
        this.setState({
            endOfDayNickName: e.target.value
        }, this.getGridData);
    }

    refreshReprintManifest = () => {
        this.setState({ isRefreshLoading: true });
        findOrdersService.manifestList().then((response) => {
            if(response.status == 200) {
                this.getGridData();
            }
            this.setState({ isRefreshLoading: false });
        });
    }

    listGrid_dataBind = (allData) => {
        let count = 0;
        for (let i = 0; i < allData.length; i++) {
            allData[i].RowNumber = (i+1);
        }
        return allData;
    };

    getGridData = () => {
        if (this.state.endOfDayNickName && this.state.selectedFacilityId) {
            kendo.ui.progress($('#endOfDayReportGrid'), true);
            axios.get('/WebUI/Shipping/FindOrders/ManifestReprinter?externalId=' + this.state.endOfDayNickName + '&facilityId=' + this.state.selectedFacilityId)
                .then((response) => {
                    kendo.ui.progress($('#endOfDayReportGrid'), false);
                    let gridResourceList = this.listGrid_dataBind(response.data.ResourceList);
                    this.setState({
                        gridResourceList: gridResourceList,
                        selectedPrintedItem:0
                    });
                })
                .catch((error) => {
                    kendo.ui.progress($('#endOfDayReportGrid'), false);
                    console.log(error);
                    this.setState({
                        gridResourceList: [],
                        selectedPrintedItem:0
                    });
                });
        } else {
            this.setState({
                gridResourceList: [],
                selectedPrintedItem:0
            });
        }
    }
    onPrintSelected = () => {
        var reportGrid = $('#endOfDayReportGrid').data('kendoGrid');
        var rows = reportGrid.select();

        rows.each(function (index, row) {
            var selectedItem = reportGrid.dataItem(row);
            console.log("selectedItem.ManifestDownloadLink :",selectedItem.ManifestDownloadLink.split('/').pop());
            var a = $("<a class='temp-pdf'>")
                        .attr("href", selectedItem.ManifestDownloadLink)
                        .attr("target", "_blank")
                        .attr("download", selectedItem.ManifestDownloadLink.split('/').pop())
                        .appendTo("body");
                    a[0].click();
                    a.remove();
        });
    }

    selectRow = (KendoGrid) => {
        let currentSelections = KendoGrid.getNumberOfSelections();
        this.setState({ selectedPrintedItem:currentSelections });
    }
    render() {
        return (
            <WmsModel
                id={"reprintEndOfDayReport"}
                class="reprintEndOfDayReport"
                title={this.translate('Label_Reprint_End_of_Day_Report_Title')}
                width={800}
                onCloseFunc={this.props.onCloseReprintEndOfDayReport}
                height={400}
                isOpen={this.props.sharedIsOpenReprintEndOfDayReport}
                customClass='wms-model-new-ui-wrapper' >
                <div className="model-content-wrapper">
                    <div className="inline-element">
                        <WmsDropdown
                            id="selectedWarehouse"
                            name="selectedWarehouse"
                            label={this.translate('Label_Warehouse_Text')}
                            wmsSelector={this.state.selector + "selectedWarehouse"}
                            value={this.state.selectedWarehouse}
                            onChangeFunc={this.changeWarehouse}
                            options={this.state.faciltyOptions}
                            valueField='name'
                            textField='name'
                            blankFirstOption={true}
                        />
                        <WmsDropdown
                            id="endOfDayNickName"
                            name="endOfDayNickName"
                            label={this.translate('Label_Account_Nick_Name')}
                            wmsSelector={this.state.selector + "endOfDayNickName"}
                            value={this.state.endOfDayNickName}
                            onChangeFunc={this.changeNickName}
                            options={this.state.nickNameOption}
                            valueField='ShipEngineId'
                            textField='NickName'
                            blankFirstOption={false}
                        />
                        <WmsButton
                            label={''}
                            buttonType='green'
                            id='RefreshEndOfDayReport'
                            parentClassName='refresh-btn-wrapper'
                            wmsSelector={this.state.selector + 'refreshEndOfDayReport'}
                            onClickFunc={this.refreshReprintManifest}
                            icon='fa-refresh'
                            loading={this.state.isRefreshLoading}
                            disabled={!this.state.endOfDayNickName || !this.state.selectedFacilityId}
                        />
                    </div>
                    <WmsGrid
                        columns={[{
                            field: "CreationDate",
                            title: "Created On",
                            width: "100%",
                            wmsid: 1,
                            template: "#= CreationDate != null ? (moment(CreationDate).format('MMMM Do, YYYY hh:mm a')) : '' #"
                        }, {
                            field: "Shipments",
                            title: "Order count",
                            width: 200,
                            wmsid: 2
                        }, {
                            field: "CarrierAccountNickname",
                            title: "Account Nickname",
                            width: 200,
                            wmsid: 3
                        }]
                        }
                        schema={
                            {
                                model: {
                                    fields: {
                                        createdOn: {
                                            type: 'string'
                                        },
                                        orderCount: {
                                            type: "number"
                                        },
                                        accountNickname: {
                                            type: 'string'
                                        }
                                    }
                                }
                            }
                        }
                        gridHeight={160}
                        gridKeySelection={'RowNumber'}
                        staticGridData={this.state.gridResourceList}
                        virtual={false}
                        showGridSummary={false}
                        showResetButton={false}
                        gridName={"endOfDayReportGrid"}
                        id={"endOfDayReportGrid"}
                        showGridResult={true}
                        showSortColumns={true}
                        showColumnFilter={false}
                        shawCheckbox={false}
                        wmsSelector={this.state.selector + "endOfDayReportGrid"}
                        onSelection={this.selectRow}
                        onSelectAllChange={() => null}
                        clickEventOfContextMenu={() => { }}
                        emptyRecoredText={this.translate('Label_Reprint_EOD_Grid_Empty_Text')}
                        menu={[]}
                    />
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        id="transferOrderCancel"
                        name="transferOrderCancel"
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.state.selector + "ButtonCancel"}
                        onClickFunc={this.props.onCloseReprintEndOfDayReport}
                        icon="fa-ban"
                        buttonType="orange"
                        loading={false}
                    />
                    <WmsButton
                        id="printSelected"
                        name="printSelected"
                        label={this.translate('Label_Reprint_EOD_Print_Selected')}
                        wmsSelector={this.state.selector + "printSelected"}
                        onClickFunc={this.onPrintSelected}
                        disabled={this.state.selectedPrintedItem == 0}
                        icon={'fa-print'}
                    />
                </div>
            </WmsModel>
        )
    }
}