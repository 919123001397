
import { BaseRequirements } from './BaseRequirements';
export class ArrayWms implements BaseRequirements {
	/**
	* Simple contains method for Dictionary
	* @params key:string
	* @return void
	*/
    public static testStaticClass(message: string): void {
        console.log(message);
    }

    public test(message: string): void {
        console.log(message);
    }

    /**
     * get length of array
     * @params array
     * @return boolean
     */
    public static isEmpty(array) {
        if (array.length === 0) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Switch element by index
     * @params array, newIndex, OldIndex
     * @return newArray
     */
    public static switchIndexByField(myarray, newIndex, oldIndex) {
        var extractedIndex = myarray.slice(oldIndex, oldIndex + 1);
        myarray.splice(oldIndex, 1);
        myarray.splice(newIndex, 0, extractedIndex[0]);
        return myarray;
    };

    /**
     * Convert base64 to byte array
     * @params base64, contentType
     * @return Byte Array
     */
    public static convertBase64ToByteArray(base64, contentType) {
        let byteCharacters = atob(base64);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        if (contentType) {
            let blob = new Blob([byteArray], { type: contentType });
            return blob;
        }
        else {
            return byteArray;
        }
    }
    /**
     * Display blob in new window
     * @params blob, fileName
     * @return Byte Array
     */
    public static displayBlobInWindow(blob, fileName) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            if (!fileName) {
                throw new TypeError('IE Requires a fileName Parameter');
            }
            else {
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            }
        }
        else {
            let blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
        }
    }

    /**
     * Convert saprated string in to key value array
     * @params commaSaperatedString, key
     * @return Byte Array
     */
    public static convertStringToArray(commaSaperatedString, key) {
        let dataSourceData = (commaSaperatedString == null || typeof commaSaperatedString == 'undefined') ? [] : commaSaperatedString.split(',');
        let arrayOfarray = [];
        if (dataSourceData.length > 0) {
            while (dataSourceData.length) {
                let obj = dataSourceData.splice(0, 1);
                arrayOfarray.push({ [key]: obj[0] });
            };
        }
        return arrayOfarray;
    }
    /**
     * Method to return a valid option object
     * @params array
     * @return object
     * It should accept only 1 parameter
     * It should return a object
     */
    public static convertArrayToOption(array) {
        var returnObject = [];
        array.forEach((val, key) => {
            returnObject.push({
                "value": val,
                "description": val
            });
        });
        return returnObject;
    }

    /**
     * Method to return a valid object in an array based on a key
     * @params array
     * @return object
     * It should accept only 1 parameter
     * It should return a object
     */
    public static findObjectByKey(array, key, value) {
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] === value) {
                return array[i];
            }
        }
        return null;
    }
}