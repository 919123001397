import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./WmsGridCheckBoxSelection.scss";
import _ from "lodash";

interface GridProps {
    id?: string; // this should write the id to the radio
    onRequestEnd?: any; // this should control the onchange event and anything we need to determine before we send it up
    onSelectRow?: any; // this should control the selection event
    transport?: any; // grid read , update and remove transport object
    changeColumnsDynamic?: boolean; // grid read , update and remove transport object
    staticGridData?: any; // grid read , update and remove transport object
    schema?: any; // grid columns type structure
    columns?: any; // grid columns
    bindSelectRow?: boolean; // bind row click event
    keyId?: string; // row click id
    callMethodAfterInitialize?: any; // this method call on grid initiation complete, we not use databound inbuilt kendo event because it's call read call continues on using setOption
    changeRemoteGridColumn?: boolean; // on set true it allow to change remote grid column update.
    combineSelectedRow?: boolean;
}
let checkBoxClass = ".k-checkbox";
let selectedClass = "k-selected";
let stateSelectedClass = "k-state-selected";

const WmsGridCheckBoxSelection: React.FC<GridProps> = (props: GridProps): any => {
    var checkedIds = {};
    var rowCheckedIds = {};

    useEffect(() => {
        var kendoGrid = $(`#${props.id}`).data("kendoGrid");
        if (!kendoGrid) {
            let dataSourceObj = null;
            if (props.transport) {
                dataSourceObj = new kendo.data.DataSource({
                    transport: props.transport,
                    pageSize: 100,
                    serverPaging: true,
                    serverSorting: true,
                    schema: props.schema,
                    type: "aspnetmvc-ajax",
                    requestEnd: (e) => {
                        props.onRequestEnd(e);
                    },
                });
            } else {
                dataSourceObj = {
                    data: props.staticGridData,
                    schema: props.schema,
                };
            }
            let grid = $(`#${props.id}`)
                .kendoGrid({
                    dataSource: dataSourceObj,
                    // height: 430,
                    columns: props.columns,
                    columnMenu: {
                        sortable: false,
                    },
                    sortable: true,
                    filterable: true,
                })
                .data("kendoGrid");
            //bind click event to the checkbox
            if (grid && grid.table) {
                grid.table.off("click", checkBoxClass, selectRow);
                grid.table.on("click", checkBoxClass, selectRow);
                if (props.bindSelectRow) {
                    grid.table.off("click", "tr", selectedTableRow);
                    grid.table.on("click", "tr", selectedTableRow);
                }
            }
            if (props.callMethodAfterInitialize) {
                props.callMethodAfterInitialize();
            }
        }
    });
    useEffect(() => {
        if (props.changeColumnsDynamic) {
            var column = {
                columns: props.columns,
            };
            var grid = $(`#${props.id}`).data("kendoGrid");
            grid.setOptions(column);
        }
    }, [props.changeColumnsDynamic, props.columns, props.id]);
    useEffect(() => {
        if (props.changeRemoteGridColumn) {
            var column = {
                columns: props.columns,
            };
            var grid = $(`#${props.id}`).data("kendoGrid");

            let newDS = new kendo.data.DataSource({
                transport: props.transport,
                pageSize: 100,
                serverPaging: true,
                serverSorting: true,
                schema: props.schema,
                type: "aspnetmvc-ajax",
                requestEnd: (e) => {
                    props.onRequestEnd(e);
                },
            });
            grid.setOptions(column);
            grid.setDataSource(newDS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.changeRemoteGridColumn]);

    useEffect(() => {
        var grid = $(`#${props.id}`).data("kendoGrid");
        if (typeof grid != "undefined") {
            grid.dataSource.data(props.staticGridData);
        }
    }, [props.id, props.staticGridData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function selectRow(e) {
        var checked = this.checked,
            row = $(this).closest("tr"),
            grid = $($(`#${props.id}`)).data("kendoGrid"),
            dataItem = grid.dataItem(row);

        checkedIds[dataItem[props.keyId]] = checked;
        if (props.combineSelectedRow) {
            rowCheckedIds = checkedIds;
        }

        if (checked) {
            //-select the row
            row.addClass(selectedClass);

            var checkHeader = true;

            $.each(grid.items(), function (_index, item) {
                if (!$(item).hasClass(selectedClass)) {
                    checkHeader = false;
                }
            });

            $("th .k-checkbox")[0]["checked"] = checkHeader;
        } else {
            //-remove selection
            row.removeClass(selectedClass);
            $("th .k-checkbox")[0]["checked"] = false;
        }
        props.onSelectRow(checkedIds);
    }
    function selectedTableRow(e) {
        if ($(e.target).hasClass("k-checkbox-label") || $(e.target).hasClass("k-checkbox")) {
            return;
        }
        let row = $(e.target).closest("tr");
        let grid = $($(`#${props.id}`)).data("kendoGrid");
        let dataItem = grid.dataItem(row);

        if (row.hasClass(stateSelectedClass)) {
            row.removeClass(stateSelectedClass);
            row.addClass(selectedClass);
            row.find(checkBoxClass)[0]["checked"] = false;
            rowCheckedIds[dataItem[props.keyId]] = false;
        } else {
            row.addClass(stateSelectedClass);
            row.removeClass(selectedClass);
            row.find(checkBoxClass)[0]["checked"] = true;
            rowCheckedIds[dataItem[props.keyId]] = true;
        }

        if (props.combineSelectedRow) {
            checkedIds = rowCheckedIds;
        }

        props.onSelectRow(rowCheckedIds);
    }
    return (
        <div className="wms-grid-check-box-selection-component">
            <div id={props.id}></div>
        </div>
    );
};

WmsGridCheckBoxSelection.propTypes = {
    id: PropTypes.string, // this should write the id to the radio
    onRequestEnd: PropTypes.func, // this should control the onchange event and anything we need to determine before we send it up
    onSelectRow: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
    transport: PropTypes.object, // grid read , update and remove transport object
    changeColumnsDynamic: PropTypes.bool, // grid read , update and remove transport object
    staticGridData: PropTypes.array, // grid records
    schema: PropTypes.object, // grid column type structure
    columns: PropTypes.array, // grid column type
    bindSelectRow: PropTypes.bool, // bind row click event
    keyId: PropTypes.string, // grid column type
    callMethodAfterInitialize: PropTypes.func,
    changeRemoteGridColumn: PropTypes.bool,
    combineSelectedRow: PropTypes.bool,
};

WmsGridCheckBoxSelection.defaultProps = {
    onSelectRow: () => {},
    bindSelectRow: false,
    keyId: "Id",
    callMethodAfterInitialize: () => {},
    changeRemoteGridColumn: false,
    combineSelectedRow: false,
};

const areEqual: any = (prevProps: any, nextProps: any): any => {
    return _.isEqual(
        _.omit(nextProps, ["onRequestEnd", "onSelectRow", "callMethodAfterInitialize"]),
        _.omit(prevProps, ["onRequestEnd", "onSelectRow", "callMethodAfterInitialize"])
    );
};
export default React.memo(WmsGridCheckBoxSelection, areEqual);
