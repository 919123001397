import React from 'react';


export class FedexEulaAgreementComponent extends React.Component {
    render() {
        return (
            <div>
                <h1>Appendix A-1</h1>
                <h3>End-User License Agreement (ASP Agreement)</h3>
                <p>
                    If Your Developer Agreement is a FedEx ASP Agreement, this EULA is to be included in
                    Your FedEx Compatible Solution, subject to and in accordance with Your Developer
                    Agreement:
                </p>
                <p>
                    To use the Application (as defined below), you must agree to abide by this Agreement. This
                    Agreement gives each of us certain rights and responsibilities. You will be assuming full and
                    sole responsibility for all use of the Application occurring under your Account Number and
                    registration information (e.g., meter number(s), credentials, etc.); you will be limiting your
                    remedies. Please read this Agreement carefully.
                </p>
                <p>
                    You will be required to accept this Agreement in order to complete the registration process. By
                    clicking the "I accept" button, you accept and agree to be bound by all of the provisions of this
                    Agreement, including those incorporated by reference, without modification, limitation or
                    qualification. You will be binding yourself or your company to this Agreement. You represent
                    that you have the authority to bind your company, if done on behalf of a company, to this
                    Agreement. If you do not have such authority, you must click the "I decline" button. You must
                    also click the "I decline" button if you do not agree with this Agreement. If you decline, you will
                    not be authorized to access or use the Application and/or the FedEx services available
                    through such Application.
                </p>
                <h3>FedEx End-User License Agreement</h3>
                <p>
                    This license agreement (“Agreement”) is entered by and between FedEx Corporate Services,
                    Inc. (“FedEx”) and you (“Licensee”).
                </p>
                <p>
                    In consideration of the mutual promises and obligations set out below, the sufficiency of
                    which the Parties acknowledge, FedEx and Licensee acknowledge and agree as
                    follows:
                </p>
                <h4>Section 1. Application, License, Restrictions.</h4>
                <b>(a) Grant. </b>
                <p>
                    Subject to the terms and conditions of this Agreement, FedEx grants Licensee a
                    personal, non-exclusive, royalty-free, non-assignable, non-transferable, limited, revocable
                    license, without the right to grant sublicenses, solely within the United States, to: (i) access
                    and use the FedEx shipping services application (“Application”), (a) only as the Application is
                    integrated into and made accessible to Licensee as a part of Licensee’s licensed remote
                    access to those application hosting services (“Services”) provided
                    by_________________________ (“System Supplier”) to Licensee, and (b) only through the
                    means provided by the System Supplier, all solely for the purposes of accessing and using
                    those shipping and shipping related services offered, from time to time, by FedEx or its
                    Affiliates through the Application (“FedEx Services”); (ii) access and use Documentation (as
                    defined below) solely for the purposes of accessing and using the Application and obtaining
                    FedEx Services; and, (iii) to the extent that Licensee incorporates shipping functionality into its
                    public web page in connection with Licensee’s retail operations in order to facilitate shipments
                    to its retail customers, Licensee may allow retail customers to access and use the Application,
                    solely as part of the Service and through the means provided by the System Suppler, for the
                    sole purpose of conducting e-commerce transactions with Licensee that request FedEx
                    Services by (and through) Licensee; provided, however, in no event does such access by retail
                    customers extend to third party shippers or resellers or consolidators of shipping or shipping
                    related services except as may be expressly permitted by a separate written agreement
                    between FedEx and Licensee. Licensee may not access or use the Application or
                    Documentation for any other purposes.
                </p>
                <br />
                <b>(b) Restrictions. </b>
                <p>
                    <b>(i) </b>
                    The license does not permit, and Licensee will not (and will not permit any employee or
                    other third party to):
                </p>
                <p>
                    <b>1. </b>
                    Reverse engineer, decompile, disassemble, or translate the Application;
                </p>
                <p>
                    <b>2. </b>
                     Apply any procedure or process to the Application in order to ascertain, derive, and/or
                    appropriate for any reason or purpose, the source code or source listings for the
                    Application or any trade secret or confidential information or process contained in the
                    Application;
                </p>
                <p>
                    <b>3. </b>
                    Remove, obscure, modify or otherwise change any product identification, copyright or other
                    notices or legends contained in or on, or available through, the Application or Documentation;
                </p>
                <p>
                    <b>4. </b>
                    Transfer the Application, Documentation or the license, all whether in whole or in
                    part, or grant any rights in the Application, Documentation or the license, all whether
                    in whole or in part by sublicense or otherwise;
                </p>
                <p>
                    <b>5. </b>
                    Except as expressly permitted under Section 1(a) herein, provide, lease, lend, or otherwise
                    use or allow others to use or have access to the Application, the Documentation or any
                    portion thereof;
                </p>
                <p>
                    <b>6. </b>
                    Disseminate or disclose performance information or analysis (including, without limitation,
                    benchmarks) relating to the Application without the prior written consent of FedEx;
                </p>
                <p>
                    <b>7. </b>
                    Use or display (including, doing either on a stand-alone basis (i.e., not as integrated with the
                    Services)) the Application or Documentation, or any portion of either, except as expressly
                    authorized in and in accordance with Section 1(a) above;
                </p>
                <p>
                    <b>8. </b>
                    Reproduce or distribute the Application or Documentation;
                </p>
                <p>
                    <b>9. </b>
                    Modify or create derivative works of the Application or Documentation; or,
                </p>
                <p>
                    <b>10. </b>
                    Use the Application to perform or order FedEx Services by or for the benefit of third
                    parties. For the avoidance of doubt, the functionality available through the Application
                    (e.g., that which enables Licensee to label, rate, route, record, track and invoice) may
                    only be used by Licensee for shipments tendered to FedEx for carriage on behalf of
                    Licensee unless expressly permitted by a separate written agreement between FedEx
                    and Licensee.
                </p>
                <p>
                    <b>(ii) </b>
                    Licensee shall promptly notify FedEx in writing upon its discovery of any unauthorized use
                    of the Application or Documentation. FedEx may prevent access to or use of the Application
                    or FedEx systems if FedEx has reason to believe that (a) Licensee or third party using the
                    Application licensed to Licensee may be involved in potential unauthorized use of or other
                    infringement of FedEx proprietary rights or (b) Licensee or its use of the Application is in
                    violation of this Agreement.
                </p>
                <p>
                    <b>(iii)</b> Licensee acknowledges and agrees that the Application and Documentation are
                    subject to export restrictions and controls imposed by various statutes and regulations,
                    (collectively, "the Acts"), including the Export Administration Act and the Export
                    Administration Regulations. Licensee shall not use, export or re-export the Application or
                    Documentation except as authorized by and in compliance with the Acts and all laws and
                    regulations of the jurisdiction in which FedEx made the Application available to Licensee.
                    Without limiting the foregoing, Licensee shall not export or re-export the Application or
                    Documentation (i) into or to a national or resident of any embargoed countries under the Acts
                    which include as of the date hereof, Cuba, Iran, Iraq, Libya, North Korea, Sudan or Syria or
                    (ii) to a Denied Party listed on U.S. Department of Commerce's list of U.S. Denied Persons or
                    a Special Designated National on the U.S. Treasury Department's list of Specially Designated
                    Nationals. By entering into this Agreement, Licensee represents and warrants that Licensee
                    is not located in, under control of, or a national or resident of any such country or on any such
                    list.
                </p>
                <p>
                    <b>(iv)</b> Licensee acknowledges that the Services and other services provided by System
                    Supplier are not provided by
                    or on behalf of FedEx. LICENSEE HEREBY WAIVES ANY AND ALL CLAIMS AGAINST
                    FEDEX ARISING FROM OR RELATING TO LICENSEE’S USE OF THE SERVICES OR
                    THE OTHER SERVICES PROVIDED BY SYSTEM SUPPLIER.
                </p>
                <p>
                    <b>(v)</b> In addition to the provisions of this Agreement, if Licensee is an agency, department, or
                    other entity of the United States Government (“Government”), pursuant to 48 C.F.R. 12.212 or
                    48 C.F.R. 227.7202, as applicable, the (a)
                    Application and (b) any related materials, specifications, technical data, and documentation
                    (collectively, “Documentation”) are Commercial Items as defined in 48 C.F.R. 2.101 and are
                    licensed to Government end users only as Commercial Items and only with the rights
                    granted to all other end users as provided in this Agreement. Manufacturer is FedEx
                    Corporate Services, Inc., 30 FedEx Parkway, Collierville, TN 38017.
                </p>
                <br />
                <b>(c) Modifications.</b>
                <p>
                    To the extent FedEx or the System Supplier makes available to Licensee
                    any updates, upgrades, enhancements, bug-fixes or other modifications to the Application
                    (collectively, “Modifications”), such Modifications shall be considered a part of the Application
                    and subject to the terms and conditions of this Agreement; provided, however, a Modification
                    may come with its own imbedded license agreement and in the event of a conflict between
                    this Agreement and any imbedded license agreement, the terms of the imbedded license
                    agreement control. For the avoidance of doubt, except as otherwise stated, “Application”
                    includes “Modification.”
                </p>
                <br />
                <b>(d) Ownership and Reservation of Rights.</b>
                <p>
                    All right, title and interest, including all intellectual
                    property rights, in and to the Application, Modifications (if any), and Documentation, are
                    owned exclusively by FedEx and/or its licensors. The Application, Modification and
                    Documentation are licensed, not sold, and are and shall at all times remain the property of
                    FedEx. Except for the limited, revocable license granted in Section 1(a), Licensee shall have
                    no right, title or interest in any the Application, Modification or Documentation, and shall not
                    allow any lien or encumbrance to exist on any of the foregoing. Except for the express license
                    granted in Section 1(a), all rights in and to the Application and Documentation are reserved.
                    (e) FedEx affiliates. Licensee hereby acknowledges that, unless otherwise specified, when
                    used in this Agreement, the name “FedEx” shall include FedEx Corporate Services, Inc., its
                    parent company and any and all of its affiliate companies, including without limitation Federal
                    Express Corporation and FedEx Ground Package System, Inc.; provided, however, Licensee
                    acknowledges that the sole obligor under this Agreement is FedEx Corporate Services, Inc.
                    alone. Licensee further acknowledges that the contract of carriage for shipments made by
                    Licensee using the Application (and not the terms governing use of such Application) is
                    between Licensee and the FedEx company to which Licensee tenders the package within the
                    United States.
                </p>
                <h4>Section 2. Licensee Obligations.</h4>
                <b>(a) Systems and Access.</b>
                <p>
                    This Agreement governs Licensee’s access and use of the
                    Application as provided above and under the FedEx account numbers and/or registration
                    information (e.g., meter numbers, credentials, etc.) provided to Licensee by FedEx. Licensee
                    will follow instructions of FedEx, including those in the Application and/or Documentation,
                    provided from time to time, for access and use of the Application. Licensee is solely
                    responsible for providing and maintaining all hardware necessary to access and use the
                    Application. In addition, Licensee acknowledges that use of certain Applications or Services
                    may require Licensee to modify its internal computer systems from time to time, and Licensee
                    will make such modifications at its expense pursuant to the instructions provided by FedEx or
                    System Supplier. Licensee is solely responsible, for obtaining access to the Internet or World
                    Wide Web. Such access is required to utilize the Application. FedEx may, and may require the
                    System Supplier, to modify the Application at any time for any reason and Licensee hereby
                    consents to all such Modifications. If required, when instructed by FedEx (and on an ongoing
                    basis thereafter), Licensee will complete an End of Day Close Process as required by FedEx
                    to ensure FedEx shipping transactions are uploaded to FedEx at the close of each business
                    day
                </p>
                <br />
                <b>(b) Maintenance.</b>
                <p>
                    Licensee agrees to look solely to the System Supplier for all technical support
                    and maintenance of the Services and Application, unless otherwise instructed by FedEx.
                    Notwithstanding the foregoing, FedEx in its sole discretion may (i) repair, modify, replace,
                    disable, monitor, or remove the Application; and (ii) provide installation and integration
                    assistance or technical support with respect to Licensee’s access to and use of the Application
                    (collectively (i) and (ii) referred to herein “Limited Support Services”). Notwithstanding the
                    foregoing, FedEx is under no obligation to provide Limited Support Services, or any other
                    services, or Modifications hereunder.
                </p>
                <br />
                <b>(c) Information and Data.</b>
                <p>
                    Licensee is advised and encouraged to maintain back-up copies of
                    any data or information (including, names, addresses, reports, etc.) that it uses or stores with,
                    through or in the Services or the Application (collectively, “Data”). FedEx is and will not be
                    responsible for the loss of or damage to any Data. Licensee will not load process or store Data
                    on or through the Application not required or related to the conduct of business with FedEx.
                    FedEx shall have access to and use of the Data used or processed on, though, in or with the
                    Application. FedEx reserves the right to access, use, delete or remove any Data in connection
                    with the provision of any FedEx Services or in connection with this Agreement. In addition,
                    Licensee consents to the collection, use or disclosure by FedEx or its designee of limited
                    personally identifiable information about Licensee or its users for the purposes contemplated
                    by this Agreement and for marketing, sales, testing, development and other FedEx business
                    purposes.
                </p>
                <br />
                <b>(d) Supplies .</b>
                <p>
                    Licensee acknowledges that it is responsible for the purchase of any supplies
                    necessary to use the Application or Service and will contact the System Supplier to determine
                    what, if any, supplies it must purchase for use of any Application or Service.
                </p>
                <h4>Section 3. Termination.</h4>
                <p>
                    This Agreement is effective until terminated by either party. This
                    Agreement will terminate independently without notice if Licensee fails to comply with any
                    provision of this Agreement or any instructions regarding the Application provided by FedEx.
                    Upon termination for any reason, Licensee must cease all use of the Application. FedEx
                    reserves the right to unilaterally terminate this Agreement and the use of the Application at any
                    time, for any reason, and by whatever means.
                </p>
                <h4>Section 4. Payment.</h4>
                <p>
                    With respect to FedEx Services, Licensee will follow all of payment terms
                    and instructions of FedEx. Licensee will remit payment, in accordance with the terms and
                    conditions contained in the applicable FedEx transportation agreement, applicable FedEx
                    Service Guide ("Service Guide"), or as otherwise instructed by FedEx. Licensee is responsible
                    for payment of all charges generated through use of the Application under Licensee’s account
                    number and registration information (e.g., meter numbers, credentials, etc.). Any rates
                    reflected in or published by the Application or Service, if shown, are offered for information
                    purposes only and may be different from the actual charges for FedEx Services incurred by
                    Licensee.
                </p>
                <h4>Section 5. Adjustments and Refunds.</h4>
                <p>
                    With respect to FedEx Services, Licensee may bill
                    shipping charges for transactions conducted through the Application only to valid account
                    numbers (e.g., bill sender, bill recipient or bill third party). Shipments tendered to FedEx with
                    incorrect or incomplete routing, labeling, commitment date, service designation or other errors

                    will not be eligible for refunds under money-back guarantees of FedEx. Refunds requested by
                    Licensee with respect to any shipment must be made in accordance with the applicable
                    Service Guide or Licensee’s transportation agreement with FedEx. Licensee will not be entitled
                    to any refund or credit under money-back guarantees of FedEx if FedEx determines that the
                    claim resulted from improper use of the Application. FedEx, in its sole discretion, may suspend
                    its money-back guarantees in the event Application or Service fails or is inoperable for any
                    reason.
                </p>
                <h4>Section 6. Disclaimer of Warranty.</h4>
                <p>
                    Licensee expressly acknowledges and agrees that access to and use of the Application is provided to Licensee by the System Supplier and
                    that FedEx is neither providing the Services nor charging Licensee a fee for access to or
                    use of the Application or for providing the Limited Support Services, if any.
                    The Application and Limited Support Services are provided “AS-IS” WITHOUT
                    representation or warranty of any kind.
                </p>
                <p>
                    To the extent permitted by applicable law, FedEx and its Representatives (as defined in
                    Section 8 below), disclaim and exclude all warranties, whether, express, statutory, or implied,
                    including the implied warranties of Merchantability and Fitness for a Particular Purpose, and
                    the warranties of Non-Infringement or Quiet Enjoyment. FedEx does not represent or warrant
                    that the Application will meet any or all of Licensee’s requirements or that its operations will be
                    uninterrupted or error free or that any defect within the Application will be corrected. FedEx
                    does not represent or warrant that the Limited Support Services will meet any or all of
                    Licensee’s requirements or that it will correct any defect within the Application. FedEx does not
                    represent or warrant that the Application, Limited Support Services or any other related
                    services or content is free from bugs, viruses, errors or other program limitations. FedEx does
                    not represent or warrant access to the Internet or to any other service or content through the
                    Application. Furthermore, FedEx does not warrant or make any representation regarding the
                    results of Licensee’s installation or use of the Application or the Limited Support Services in
                    terms of capability, correctness, accuracy, reliability or otherwise.
                </p>
                <p>
                    No oral or written information, representation or advice given by FedEx, the System
                    Supplier or an authorized representative of either shall create any warranty.
                </p>
                <p>
                    Some states do not allow the exclusion of implied warranties, so the above exclusions may
                    not apply to Licensee. In that event, any implied warranties are limited in duration to sixty (60)
                    days from the date of purchase of the Services. However, some states do not allow
                    limitations on how long an implied warranty lasts, so the above limitation may not apply to
                    Licensee. This warranty gives Licensee specific legal rights and Licensee may have other
                    rights as well which vary from state to state.
                </p>
                <h4>Section 7. Remedies.</h4>
                <p>
                    YOUR SOLE REMEDY FOR ANY DISSATISFACTION WITH THE APPLICATION OR LIMITED SUPPORT
                </p>
                <p>
                    SERVICES IS TO STOP USING THE APPLICATION. Licensee agrees to look to the System
                    Supplier for all issues relating to Your access, use or inability to access or use the
                    Application and/or Services. Licensee acknowledges and agrees that it is Licensee’s sole
                    responsibility to arrange with the System Supplier for any and all warranties,
                    maintenance/support requirements, and remedies, if any, arising out of Licensee’s access to
                    and use of the Application and/or Services. Licensee agrees that the remedy set forth in this
                    Section is Licensee’s exclusive remedy under this Agreement for any dissatisfaction with its
                    access to or use of (or inability to do either) the Application, the Services or Limited Support
                    Services.
                </p>
                <h4>Section 8. Limitation of Liability.</h4>
                <p>
                    Licensee acknowledges that FedEx is providing the
                    Application and Limited Support Services, if any, to Licensee free of charge.
                    The entire liability of FedEx and its Representatives (as defined below) for any reason shall
                    be limited to $100.00. whether based on breach of contract, breach of warranty, tort
                    (including, negligence), product liability, statutory or strict liability or otherwise even if FedEx
                    or its Representatives have been advised of the possibility of such damages and even if a
                    remedy set forth herein is found to have failed of its essential purpose.
                    To the maximum extent permitted by applicable law, FedEx and its parent company and
                    subsidiaries and affiliates, direct and indirect, of its Parent company, licensors, or any of the
                    foregoing’s respective employees, officers, directors and/or agents (collectively,
                    “Representatives”) are not and shall not be liable for any special, incidental, exemplary,
                    punitive, consequential or other indirect damages (including: damages for loss of business,
                    loss of data, loss of profits, damages to Licensee’s computer systems or the like), whether
                    based on breach of contract, breach of warranty, tort (including, negligence), product liability,
                    statutory or strict liability or otherwise even if FedEx or its Representatives have been advised
                    of the possibility of such damages and even if a remedy set forth herein is found to have failed
                    of its essential purpose.
                </p>
                <p>
                    Some states do not allow the limitation and/or exclusion of liability for incidental or
                    consequential damages, so the above limitation or exclusion may not apply to You.
                </p>
                <p>
                    The limitations of damages set forth above are fundamental elements of the basis of the
                    bargain between FedEx and Licensee. FedEx would not be able to provide the Application
                    without such limitations.
                </p>
                <h4>Section 9. Controlling Law and Severability.</h4>
                <p>
                    This Agreement shall be governed by and construed in accordance with the laws of the United States and the State of Tennessee,
                    excluding its conflicts of law provisions. If for any reason a court of competent jurisdiction finds
                    any provision of this Agreement, or a portion thereof, to be unenforceable, that provision shall
                    be enforced to the maximum extent permissible so as to effect the intent of the parties and the
                    remainder of this Agreement shall remain in full force and effect. Any cause of action with
                    respect to Application must be instituted within one (1) year after the claim or cause of action
                    has arisen in Shelby County, Tennessee or be barred.
                </p>
                <h4>Section 10. Terms and Conditions of Carriage.</h4>
                <p>
                    <b>(a) </b>
                    It is understood and agreed by Licensee that the Application and/or Service may contain
                    functionality which may be used by Licensee to make shipments through carriers other than
                </p>
                <p>
                    FedEx. Licensee should contact the System Supplier to determine if the Service has multicarrier capability. If so, Licensee agrees that it will look solely to such third party carriers for
                    the terms under which Licensee may tender shipments to and have its shipments carried by
                    such third party carriers and that, as between FedEx and Licensee, Licensee is solely
                    responsible for its compliance with such third party carriers' terms of shipping and will look
                    solely to such third party carriers for all remedies attributable to claims arising out of the
                    third party carriers’ carriage of Licensee’s shipments. LICENSEE WILL DEFEND,
                    INDEMNIFY AND HOLD HARMLESS FEDEX FROM ALL CLAIMS, DEMANDS AND
                    JUDGMENTS, INCLUDING THE PAYMENT OF ATTORNEY’S FEES, FROM THIRD
                    PARTY CARRIERS ARISING OUT OF LICENSEE’S USE OF THE SERVICES TO
                    PROCESS SHIPMENTS TENDERED TO SUCH CARRIERS.
                </p>
                <p>
                    <b>(b) </b>
                    With respect to FedEx Services, Licensee agrees that domestic and international
                    carriage by FedEx of any shipments tendered to FedEx pursuant to this Agreement or using
                    the Application shall be in accordance with the terms, conditions and limitations of liability set
                    out on the NONNEGOTIABLE Air Waybill, Label, Manifest, or Pick-Up Record (collectively
                    “Shipping Documentation”) and as appropriate any transportation agreement between
                    Licensee and FedEx covering such shipment and in any applicable tariff, Service Guide or
                    Standard Conditions of Carriage, copies of which are available upon request, and which are
                    incorporated into this Agreement by reference. If there is a conflict between the Shipping
                    Documentation and any such document then in effect or this Agreement, the transportation
                    agreement, tariff, Service Guide, Standard Conditions of Carriage, or this Agreement will
                    control, in that order of priority. If a shipment originates outside the United States, the contract
                    of carriage is with the FedEx subsidiary, branch, or independent contractor who originally
                    accepts the shipment.
                </p>
                <p>
                    <b>(c) </b>
                    In the event Licensee uses the Application and/or Service to process shipments tendered to
                    FedEx for delivery to locations outside the United States or country of shipment origin,
                    Licensee will, at Licensee’s sole expense, assure that the terms and conditions of international
                    carriage supplied by FedEx from time to time (and which may be amended or modified from
                    time to time at the sole discretion of FedEx) are placed on the Shipping Documentation, as
                    instructed by FedEx, for all such international shipments. LICENSEE WILL DEFEND,
                    INDEMNIFY AND HOLD HARMLESS FEDEX AND ITS REPRESENTATIVES FROM AND
                    AGAINST ANY AND ALL LOSSES, DAMAGES, CLAIMS AND OTHER ITEMS OF COST AND
                    EXPENSE ARISING OUT OF LICENSEE’S FAILURE TO APPLY THE INTERNATIONAL
                    CARRIAGE TERMS TO THE SHIPPING DOCUMENTATION FOR SUCH INTERNATIONAL
                    SHIPMENTS, INCLUDING WITHOUT LIMITATION CLAIMS FROM THE RECIPIENT OF ANY
                    SHIPMENT, AND LICENSEE’S FAILURE TO FOLLOW INSTRUCTIONS OF FEDEX IN
                    REGARD TO THE PLACEMENT OF THE TERMS ON THE SHIPPING DOCUMENTATION
                    FOR SUCH INTERNATIONAL SHIPMENTS.
                </p>
                <p>
                    <b>(d) </b>
                    Licensee acknowledges that if the Application and/or Service is used to process shipments
                    to locations outside the United States or country of shipment origin, Licensee must enter the
                    name of the person completing the Shipping Documentation to print in lieu of its manual
                    signature on the Shipping Documentation, as applicable, for all shipments tendered by
                    Licensee to FedEx using the Application or Service. Licensee further acknowledges that such
                    printed name shall be sufficient to constitute the Licensee’s signature, and Licensee’s
                    acceptance of FedEx terms and conditions of carriage contained in the applicable
                    transportation agreement, tariff, Service Guide, Standard Conditions, or Shipping
                    Documentation, under which the shipment is accepted by FedEx, or its independent
                    contractor.
                </p>
                <p>
                    <b>(e) </b> Unless otherwise indicated, the shipper's address indicated on the face of any Shipping
                    Documentation is the place of execution and the place of departure and the recipient's address
                    listed on the face of the Shipping Documentation is the place of destination. Unless otherwise
                    indicated on the face of the Shipping Documentation the first carrier of all shipments is Federal
                    Express Corporation, 3610 Hacks Cross Road, Memphis, TN, 38125. In the event another
                    carrier is listed on any such document, that carrier is the first carrier of the shipment. The
                    address for FedEx Ground is P.O. Box 108, Coraopolis, PA 15230.
                </p>
                <h4>Section 11. Indemnity.</h4>
                <p>
                    Licensee shall, at Licensee’s sole cost and expense, defend, indemnify
                    and hold FedEx and its Representatives harmless from and against all claims, demands, suits,
                    damages, losses, liabilities, costs, expenses, fines and judgments, including reasonable
                    attorney’s fees, (hereinafter, collectively, “Claims”) arising out of or relating to
                </p>
                <p><b>(a) </b> Licensee’s
                    installation, use (or inability to use) and/or removal (or inability to remove) of the Application or
                    any other materials or services provided to Licensee by or on behalf of FedEx or its
                    Representatives;
                </p>
                <p>
                    <b>(b) </b> the intentional acts, willful misconduct or negligence (acts or omissions) of licensee, its
                    employees, officers, directors, suppliers, customer, agents, users, or representatives
                    (collectively, for purposes of sections 11(b) and 11(c), “Licensee”); or,
                </p>
                <p>
                    <b>(c) </b> Licensee’s breach of
                    this Agreement. FedEx may intervene and assume its defense in any such Claims, at its
                    expense and in its sole discretion. Licensee will not settle any Claims involving FedEx or the
                    Application without the prior written consent of FedEx.
                </p>
                <h4>Section 12. Complete Agreement.</h4>
                <p>
                    This Agreement constitutes the entire agreement between Licensee and FedEx with respect to the Application, and supersedes any prior or
                    contemporaneous understandings, representations, statements or agreements, written or oral,
                    regarding the Application. Notwithstanding the foregoing, an Application or Modification may
                    come with its own imbedded license agreement and in the event of a conflict between this
                    Agreement and any imbedded license agreement, the terms of the imbedded license
                    agreement control. As used in this Agreement, “including” means “including, without limitation”
                    and is illustrative rather than exhaustive. No amendment to or modification of this Agreement
                    will be binding on FedEx without the written consent of FedEx. Licensee may not assign or
                    otherwise transfer this Agreement or the licenses granted herein, including by operation of law
                    without the prior written consent of FedEx. Any assignment or transfer in violation of the
                    foregoing is void and of no effect. In addition, except for FedEx Representatives, Licensee and
                    FedEx acknowledge and agree that there are no third party beneficiaries to this Agreement.
                    The provisions of Sections 1(b), 1(c), 1(d), 1(e), 2(c), 3, 4, 5, 6, 7, 8, 9, 10 11, and 12 will
                    survive the termination of this Agreement.
                </p>
            </div>
        )
    }
}