import * as GlobalService from './../global_services';

export const getGridDemoColumns = (param_usersgri_header) => {
    
    var translate = window.translate;
    var customLabels= GlobalService.getCustomLabels();

    return ({

        columns: [{
            field: "UserId",
            title: param_usersgri_header[0].description,
            width: 70,
            wmsid: 1,
            headerAttributes: {
                "data-wms-selector": 'FindUserUserId'
            }
        },
        {
            field: "Name",
            title: param_usersgri_header[1].description,
            width: 120,
            wmsid: 2,
            filterable: false,
            headerAttributes: {
                "data-wms-selector": 'FindUserName'
            }
        },
        {
            field: "Login",
            title: param_usersgri_header[7].description,
            width: 120,
            wmsid: 8,
            filterable: false,
            headerAttributes: {
                "data-wms-selector": 'FindUserLogin'
            }
        },
        {
            field: "CustomerName",
            title: customLabels.Label_Customer + "Access",
            width: 120,
            wmsid: 4,
            filterable: false,
            sortable: false,
            headerAttributes: {
                "data-wms-selector": 'FindUserCustomerName'
            }
        },
        {
            field: "UiRoles",
            title: param_usersgri_header[2].description,
            width: 120,
            wmsid: 3,
            filterable: false,
            sortable: false,
            headerTemplate: '<span class="k-link">' + param_usersgri_header[2].description + '</span>',
            headerAttributes: {
                "data-wms-selector": 'FindUserUiRoles'
            }

        },
        {
            field: "Facilities",
            title: customLabels.Label_Warehouse + "Access",
            width: 120,
            wmsid: 5,
            filterable: false,
            template: "#= Facilities != null ? Facilities : '' #",
            sortable: false,
            hidden: true,
            headerTemplate: '<span class="k-link">' + customLabels.Label_Warehouse + "Access" + '</span>',
            headerAttributes: {
                "data-wms-selector": 'FindUserFacilities'
            }
        },
        {
            field: "Deactivated",
            title: param_usersgri_header[5].description,
            width: 120,
            wmsid: 6,
            hidden: true,
            template: "#= Deactivated == true ? '" + translate('Label_Inactive') + "' : '" + translate('Label_Active') + "' #",
            headerAttributes: {
                "data-wms-selector": 'FindUserDeactivated'
            }
        },
        {
            field: "LastLoginDate",
            title: param_usersgri_header[6].description,
            width: 120,
            wmsid: 7,
            hidden: true,
            template: "#= kendo.toString(kendo.parseDate(LastLoginDate), 'MMM dd yyyy, hh:mm:ss tt') != 'Jan 01 0001, 12:00:00 AM'  ? kendo.toString(kendo.parseDate(LastLoginDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            headerAttributes: {
                "data-wms-selector": 'FindUserLastLoginDate'
            }
        },
        {
            field: "V1Role",
            title: param_usersgri_header[8].description,
            width: 120,
            wmsid: 9,
            hidden: true,
            filterable: false,
            sortable: false,
            headerTemplate: '<span class="k-link">' + param_usersgri_header[8].description + '</span>',
            headerAttributes: {
                "data-wms-selector": 'FindUserV1Role'
            }
        },
        {
            field: "CreationDate",
            title: param_usersgri_header[9].description,
            width: 120,
            wmsid: 10,
            template: "#= CreationDate != null ? kendo.toString(kendo.parseDate(CreationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            hidden: true,
            headerAttributes: {
                "data-wms-selector": 'FindUserCreationDate'
            }
        },
        {
            field: "Email",
            title: param_usersgri_header[10].description,
            width: 120,
            wmsid: 11,
            hidden: true,
            filterable: false,
            template: "#= Email != null ? Email : '' #",
            headerAttributes: {
                "data-wms-selector": 'FindUserEmail'
            }
        },
        {
            field: "CellPhone",
            title: param_usersgri_header[11].description,
            width: 120,
            wmsid: 12,
            hidden: true,
            template: "#= CellPhone != null ? CellPhone : '' #",
            headerAttributes: {
                "data-wms-selector": 'FindUserCellPhone'
            }
        },
        {
            field: "CarrierName",
            title: param_usersgri_header[12].description,
            width: 120,
            wmsid: 13,
            hidden: true,
            filterable: false,
            template: "#= CarrierName != null ? CarrierName : '' #",
            headerAttributes: {
                "data-wms-selector": 'FindUserCarrierName'
            }
        }        ]
    })
}


export const getGridDemoSchema = () => {
    return ({
        schema: {
            data: "Data",
            total: "Total",
            model: {
                id: "UserId",
                fields: {
                    UserId: {
                        type: "number"
                    },
                    Name: {
                        type: "string"
                    },
                    UiRoles: {
                        type: "string"
                    },
                    CustomerName: {
                        type: "string"
                    },
                    Facilities: {
                        type: "string"
                    },
                    Deactivated: {
                        type: "boolean"
                    },
                    LastLoginDate: {
                        type: "date"
                    },
                    Login: {
                        type: "string"
                    },
                    V1Role: {
                        type: "string"
                    },
                    CreationDate: {
                        type: "date"
                    },
                    Email: {
                        type: "string"
                    },
                    CellPhone: {
                        type: "string"
                    },
                    CarrierName: {
                        type: "string"
                    },
                    Uri: {
                        type: "string"
                    }

                }
            }
        }
    })
}

export const getGridDemoTransport = (warehouseIndex) => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Users/Users/UsersGridRead",
                type: "POST"
            },
            destroy: {
                url: "/WebUI/Users/Users/UsersGridRead",
                type: "POST",
                dataType: "JSON"
            },
            parameterMap: function (options, operation) {
                if (operation !== "read" && options.models) {
                    return {
                        models: JSON.stringify(options.models)
                    };
                }
            }
        }
    })
}