
var axios = require('axios');


export const getSmartParcelSettingsAndCarrier = function (param_customer_uri) {
    return axios.all([
        axios.get('/WebUI/Settings/SmartParcel/SmartParcelSettings?customerUri=' + param_customer_uri),
        axios.get('/WebUI/Settings/SmartParcel/CarrierAccountData?customerUri=' + param_customer_uri),
        axios.get('/WebUI/Settings/SmartParcel/ShipEngineCarriers')
    ]).then(axios.spread(function (smartParcelSettingsOptionsResponse, carrierAccountsResponse, shipEngineCarrierResponse) {
        return {
            smartParcelSettingsOptionsResponse: smartParcelSettingsOptionsResponse.data,
            carrierAccountsResponse: carrierAccountsResponse.data,
            shipEngineCarrierResponse: shipEngineCarrierResponse.data
        };
    }));
}

export const getCarrierMarkup = function (customerId) {
    return axios.get('/WebUI/settings/smartparcel/CarrierMarkups?carrierMarkupsUri='+ '/customers/'+customerId+'/carriermarkups').then(function (response) {
        return response
    });
}

export const saveCarrierMarkup = function (param_mark) {
    return axios.post('/WebUI/settings/smartparcel/CarrierMarkups',param_mark).then(function (response) {
        return response
    });
}



export const saveSmartParcelSettings = function (param_settings_data) {
    return axios.put('/WebUI/Settings/SmartParcel/SmartParcelSettings?customerUri=' + param_settings_data.custUri, { "settings": param_settings_data.spsData }).then(function (response) {
        return response
    });
}

export const addCarrier = function (param_payload) {

    return axios.post("/WebUI/Settings/SmartParcel/CarrierAccount", param_payload).then(function (response) {
        return response
    });

}

export const deleteCarrier = function (carrierAccountUri) {

    return axios.delete("/WebUI/Settings/SmartParcel/CarrierAccount?carrierAccountUri=" + carrierAccountUri).then(function (response) {
        return response
    });

}
export const getCarrierDetail = function (shipEngoineId) {

    return axios.get("/WebUI/Settings/SmartParcel/ShipEngineSettings?seID=" + shipEngoineId).then(function (response) {
        return response
    });

}
export const updateCarrier = function (carrierObj) {

    return axios.put("/WebUI/Settings/SmartParcel/ShipEngineSettings", carrierObj).then(function (response) {
        return response
    });

}
export const getCarrierMarkups = function (carrierMarkupUri) {

    return axios.get("/WebUI/settings/smartparcel/CarrierMarkups").then(function (response) {
        return response
    });

}

export const getProviderDetail = function (provider) {
    return axios.get("/WebUI/Settings/SmartParcel/ParcelApiMappings?provider=" + provider).then(function (response) {
        return response
    });
}

export const createProviderAccount = function (providerAccountObj) {
    return axios.post("/WebUI/Settings/SmartParcel/CreateProviderAccount", providerAccountObj).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    });
}

export const getPackingSlipReports = function (param_payload) {
    return axios.post("/WebUI/Shipping/FindOrders/GetPackingSlipReports", param_payload).then(function (response) {
        return response
    });
}

export const getCartonLabelTemplates = function (customerId) {
    var custId = customerId === 0 ? null : customerId;

    return axios.get('/WebUI/Shipping/SmartPack/CartonLabelTemplates?customerId=' + custId)
        .then(function (response) {
            var tempData = response.data;
            return tempData;
        });
}