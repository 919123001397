
import React from 'react';

// component
import WmsButton from './../../components/WmsButton/WmsButton.jsx';
import WmsContainer from './../../components/WmsContainer/WmsContainer.jsx';
import WmsCheckBox from './../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDropdown from './../../components/WmsDropdown/WmsDropdown.jsx';
import WmsRadio from './../../components/WmsRadio/WmsRadio.tsx';
import WmsInput from './../../components/WmsInput/WmsInput.jsx';
import WmsFormGrid from './../../components/WmsFormGrid/WmsFormGrid.jsx';


//services
import * as GlobalService from './../../global_services';

import style from './smart-parcel-settings-holder.scss';

export class SmartParcelSettingsForm extends React.Component {

    constructor(props) {
        super(props);


        this.translate = window.translate
        let countries = [...GlobalService.GetCountries()];
        countries.shift();
        this.state = {
            selector: 'smartParcel',
            Rights: GlobalService.GetRights(),
            optionsCountry: countries,
            optionsDutyBillType: [
                { text: "Not Specified", value: "100" },
                { text: "Bill Sender", value: "102" },
                { text: "Bill Recipient", value: "103" }
            ],
            optionsDutyAndTaxes: [
                { text: "Duties Unpaid (DUP)", value: "101" },
                { text: "Duties Paid (DDP)", value: "102" }
            ],
            optionsReference: [
                { text: "Reference Number", value: "RefNum" },
                { text: "PO Number", value: "PoNum" },
                { text: "SKU (qty)", value: "Sku(Qty)" },
                { text: "TransactionID", value: "TransId" },
                { text: "BOL Number", value: "BolNum" },
                { text: "Dept. Number", value: "DeptNum" },
                { text: "UCC128", value: "UCC128" },
                { text: "Carrier Notes", value: "CarrierNotes" },
                { text: "Customer Name", value: "CustomerName" }
            ],
            optionsUsps3rdParty: [
                { text: "Endicia", value: "Endicia" },
                { text: "Stamps.com", value: "Stamps.com" }
            ],
            rateShoppingLogics: [
                { text: "Select One...", value: "" },
                { text: "Overnight - Cheapest", value: "Overnight" },
                { text: "2nd Day - Cheapest", value: "TwoDay" },
                { text: "3rd Day - Cheapest", value: "ThreeDay" },
                { text: "Cheapest", value: "Cheapest" },
                { text: "Cheapest - Independent of Delivery Date", value: "CheapestDateIndependent" }
            ],
            acticveLogic: false,
            selectedMappings: [],
            gridData: [],
            gridColumns: [
                {
                    field: "Mapping",
                    title: "Mapping",
                },
                {
                    field: "Logic",
                    title: "Logic"
                }
            ],
            gridSchema: {
                data: [],
                schema: {
                    model: {
                        fields: {
                            Mapping: {
                                type: "string"
                            },
                            Logic: {
                                type: "string"
                            }
                        }
                    }
                }
            }
        }
    }

    handleSettingsChange = (event) => {

        let name = event.target.name;
        let parentObj = this.props.sharedSettings;

        if (event.target.type == 'checkbox') {
            parentObj[name] = !parentObj[name];
        } else if (event.target.type == 'radio') {
            parentObj.SmallParcelUspsReturnType = event.target.name;
        } else {
            parentObj[name] = event.target.value;
        }

        let settingObj = Object.assign({}, this.props.sharedSettings, parentObj);
        this.props.onSettingsDataSet(settingObj);
    }

    handleRateShoppingChange = (event) => {

        let arrRateShopping = [];
        let parentObj = this.props.sharedSettings;
        let carrrierIds = this.props.sharedSettings.SmallParcelRateShoppingCarrierIds;

        if (carrrierIds != '' && carrrierIds != null) {
            arrRateShopping = carrrierIds.split(',');
        }

        let position = arrRateShopping.indexOf(event.target.name);

        if (position != -1)
            arrRateShopping.splice(position, 1);
        else
            arrRateShopping.push(event.target.name);

        parentObj.SmallParcelRateShoppingCarrierIds = arrRateShopping.join(',');
        let settingObj = Object.assign({}, this.props.sharedSettings, parentObj);
        this.props.onSettingsDataSet(settingObj);
    }

    eventClickAddLogic = () => {
        let parentObj = this.props.sharedSettings;
        let newMapping = parentObj.AutoRateShoppingMapping;
        let newLogic = parentObj.AutoRateShoppingLogic;

        if (newMapping == "" || newLogic == "") {
            return;
        }

        let sprsLogics = []

        if (parentObj.SmallParcelRateShoppingLogic) {
            sprsLogics = JSON.parse(parentObj.SmallParcelRateShoppingLogic);
        }

        for (let i = 0; i < sprsLogics.length; i++) {
            if (sprsLogics[i].Mapping.toLowerCase() == newMapping.toLowerCase()) {
                alert("Mapping names must be unique");
                return;
            }
        }

        parentObj.AutoRateShoppingMapping = "";
        parentObj.AutoRateShoppingLogic = "";

        sprsLogics.push({ Mapping: newMapping, Logic: newLogic });

        parentObj.SmallParcelRateShoppingLogic = JSON.stringify(sprsLogics);
        let settingObj = Object.assign({}, this.props.sharedSettings, parentObj);
        this.props.onSettingsDataSet(settingObj);
    }

    eventClickRemoveLogic = () => {
        let parentObj = this.props.sharedSettings;
        let sprsLogics = JSON.parse(parentObj.SmallParcelRateShoppingLogic)
        let selectedMappings = this.state.selectedMappings;

        let keepLogics = [];

        for (let i = 0; i < sprsLogics.length; i++) {
            let keep = true;

            for (let sel = 0; sel < selectedMappings.length; sel++) {
                if (sprsLogics[i].Mapping == selectedMappings[sel]) {
                    keep = false;
                    break;
                }
            }
            if (keep)
                keepLogics.push(sprsLogics[i]);
        }

        parentObj.SmallParcelRateShoppingLogic = JSON.stringify(keepLogics);

        let settingObj = Object.assign({}, this.props.sharedSettings, parentObj);
        this.props.onSettingsDataSet(settingObj);
    }

    selectLogicGrid = () => {
        let grid = $("#" + this.state.selector + 'gridRateShoppingLogic').data("kendoGrid");
        let selected = [];
        let acticveLogic = false;
        $.map(grid.select(), function (item) {
            selected.push(grid.dataItem(item).Mapping);
        });

        if (selected.length > 0)
            acticveLogic = true;

        this.setState({
            acticveLogic: acticveLogic,
            selectedMappings: selected
        });
    }

    componentWillReceiveProps(nextProps) {
        let populate = typeof nextProps.sharedSettings.SmallParcelRateShoppingLogic != 'undefined' &&
            nextProps.sharedSettings.SmallParcelRateShoppingLogic != null &&
            nextProps.sharedSettings.SmallParcelRateShoppingLogic != '' &&
            nextProps.sharedSettings.SmallParcelRateShoppingLogic.length > 0
            ? true
            : false;

        if (populate) {
            this.setState({
                gridData: JSON.parse(nextProps.sharedSettings.SmallParcelRateShoppingLogic)
            });
        }
    }

    render() {
        const renderSettings = this.props.sharedSettings;

        const renderArrRateShopping =
            typeof renderSettings.SmallParcelRateShoppingCarrierIds != 'undefined' &&
                renderSettings.SmallParcelRateShoppingCarrierIds != null &&
                renderSettings.SmallParcelRateShoppingCarrierIds != '' ?
                renderSettings.SmallParcelRateShoppingCarrierIds.split(',') : [];


        return (
            <div>
                <WmsContainer title={this.translate("Window_Title_Label_SmartParcel_Data")}>
                    <WmsCheckBox
                        id={'SmallParcelReturnShippingCost'}
                        name={'SmallParcelReturnShippingCost'}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate("Window_Title_Label_SmartParcel_Shipping_Cost")}
                        checked={renderSettings.SmallParcelReturnShippingCost == true}
                        value={renderSettings.SmallParcelReturnShippingCost}
                        wmsSelector={this.state.selector + "ShippingCost"} />
                    <span className="wms-smartparcel-note">
                        {" ( " + this.translate("Window_Title_Label_SmartParcel_Shipping_Cost_Note") + " )"} </span>

                    <WmsDropdown
                        id="SmallParcelUsps3rdParty"
                        name="SmallParcelUsps3rdParty"
                        label={this.translate("Window_Title_Label_SmartParcel_Usps3rdParty")}
                        wmsSelector={this.state.selector + "Usps3rdParty"}
                        value={renderSettings.SmallParcelUsps3rdParty}
                        onChangeFunc={this.handleSettingsChange}
                        options={this.state.optionsUsps3rdParty}
                        valueField="value"
                        textField="text"
                        parentClassName="form_group"
                        blankFirstOption={true}
                        blankFirstOptionText={'Not specified'}
                    />
                </WmsContainer>

                <WmsContainer title={this.translate("Window_Title_Label_SmartParcel_International")}>
                    <div className="wms-dropdown-box-checkbox">
                        <WmsDropdown
                            id="SmallParcelIntlDutyBillType"
                            name="SmallParcelIntlDutyBillType"
                            label={this.translate("Window_Title_Label_SmartParcel_Duty_Bill")}
                            wmsSelector={this.state.selector + "DutyBillType"}
                            value={renderSettings.SmallParcelIntlDutyBillType}
                            onChangeFunc={this.handleSettingsChange}
                            options={this.state.optionsDutyBillType}
                            valueField="value"
                            textField="text"
                            parentClassName="form_group"
                        />
                        {/* <span>( {this.translate("Window_Title_Label_SmartParcel_Specify_Duty_Bill")})</span> */}


                        <WmsCheckBox
                            id={'SmallParcelCustomizeDuties'}
                            name={'SmallParcelCustomizeDuties'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate("Window_Title_Label_SmartParcel_Customize_Duty_Taxes")}
                            checked={renderSettings.SmallParcelCustomizeDuties == true}
                            value={renderSettings.SmallParcelCustomizeDuties}
                            wmsSelector={this.state.selector + "CustomizeDuties"} />


                        <WmsDropdown
                            id="SmallParcelDutiesAndTaxes"
                            name="SmallParcelDutiesAndTaxes"
                            label={this.translate("Window_Title_Label_SmartParcel_Duty_Taxes")}
                            wmsSelector={this.state.selector + "DutiesAndTaxes"}
                            value={renderSettings.SmallParcelDutiesAndTaxes}
                            onChangeFunc={this.handleSettingsChange}
                            options={this.state.optionsDutyAndTaxes}
                            valueField="value"
                            textField="text"
                            parentClassName="form_group"
                            blankFirstOption={true}
                            blankFirstOptionText={'Not specified'}
                        />
                        {/* <span>( {this.translate("Window_Title_Label_SmartParcel_Specify_Duty_Taxes")})</span> */}
                    </div>
                </WmsContainer>

                {this.state.Rights.hasOwnProperty('Shipping.SmallParcelHighVolumeProcessing') &&

                    <WmsContainer title={this.translate("Window_Title_Label_SmartParcel_High_Volume_Processing")}>

                        <WmsCheckBox
                            id={'SmallParcelHighVolumeDefaultChecked'}
                            name={'SmallParcelHighVolumeDefaultChecked'}
                            onChangeFunc={this.handleSettingsChange}
                            label={this.translate("Window_Title_Label_SmartParcel_High_Volume_Processing_ckecked")}
                            checked={renderSettings.SmallParcelHighVolumeDefaultChecked}
                            value={renderSettings.SmallParcelHighVolumeDefaultChecked}
                            wmsSelector={this.state.selector + 'highVolume'} />

                        <span className="wms-smartparcel-note">
                            {" ( " + this.translate("Window_Title_Label_SmartParcel_High_Volume_Processing_ckecked_Note") + " )"}
                        </span>
                    </WmsContainer>
                }

                <WmsContainer title={this.translate("Window_Title_Label_SmartParcel_References")}>

                    <WmsDropdown
                        id="PieceShipRef"
                        name="PieceShipRef"
                        label={this.translate("Label_Reference")}
                        wmsSelector={this.state.selector + "Reference"}
                        value={typeof renderSettings.PieceShipRef == 'undefined' ? '' : renderSettings.PieceShipRef}
                        onChangeFunc={this.handleSettingsChange}
                        options={this.state.optionsReference}
                        valueField="value"
                        textField="text"
                        parentClassName="form_group"
                        blankFirstOption={true}
                        blankFirstOptionText={'Not specified'}
                    />
                    <WmsDropdown
                        id="PieceShipRef1"
                        name="PieceShipRef1"
                        label={this.translate("Label_Reference") + " 1"}
                        wmsSelector={this.state.selector + "Reference1"}
                        value={typeof renderSettings.PieceShipRef1 == 'undefined' ? '' : renderSettings.PieceShipRef1}
                        onChangeFunc={this.handleSettingsChange}
                        options={this.state.optionsReference}
                        valueField="value"
                        textField="text"
                        parentClassName="form_group"
                        blankFirstOption={true}
                        blankFirstOptionText={'Not specified'}
                    />
                    <WmsDropdown
                        id="PieceShipRef2"
                        name="PieceShipRef2"
                        label={this.translate("Label_Reference") + " 2"}
                        wmsSelector={this.state.selector + "Reference2"}
                        value={typeof renderSettings.PieceShipRef2 == 'undefined' ? '' : renderSettings.PieceShipRef2}
                        onChangeFunc={this.handleSettingsChange}
                        options={this.state.optionsReference}
                        valueField="value"
                        textField="text"
                        parentClassName="form_group"
                        blankFirstOption={true}
                        blankFirstOptionText={'Not specified'}
                    />
                    <WmsDropdown
                        id="PieceShipRef3"
                        name="PieceShipRef3"
                        label={this.translate("Label_Reference") + " 3"}
                        wmsSelector={this.state.selector + "Reference3"}
                        value={typeof renderSettings.PieceShipRef3 == 'undefined' ? '' : renderSettings.PieceShipRef3}
                        onChangeFunc={this.handleSettingsChange}
                        options={this.state.optionsReference}
                        valueField="value"
                        textField="text"
                        parentClassName="form_group"
                        blankFirstOption={true}
                        blankFirstOptionText={'Not specified'}
                    />
                    <WmsDropdown
                        id="PieceShipRef4"
                        name="PieceShipRef4"
                        label={this.translate("Label_Reference") + " 4"}
                        wmsSelector={this.state.selector + "Reference4"}
                        value={typeof renderSettings.PieceShipRef4 == 'undefined' ? '' : renderSettings.PieceShipRef4}
                        onChangeFunc={this.handleSettingsChange}
                        options={this.state.optionsReference}
                        valueField="value"
                        textField="text"
                        parentClassName="form_group"
                        blankFirstOption={true}
                        blankFirstOptionText={'Not specified'}
                    />

                </WmsContainer>

                {this.state.Rights.hasOwnProperty('Shipping.SmallParcelReturnLabelEnabled') &&

                    <WmsContainer title={this.translate("Window_Title_Label_SmartParcel_Return_Label")}>
                        <div className="wms-checkbox-wrapper">

                            <WmsCheckBox
                                id={'SmallParcelReturnDefaultChecked'}
                                name={'SmallParcelReturnDefaultChecked'}
                                onChangeFunc={this.handleSettingsChange}
                                label={
                                    this.translate("Window_Title_Label_SmartParcel_Return_Label_Checked")
                                }
                                checked={renderSettings.SmallParcelReturnDefaultChecked == true}
                                value={renderSettings.SmallParcelReturnDefaultChecked}
                                wmsSelector={this.state.selector + "DutyAndTaxes"} />

                            <span className="wms-smartparcel-note">
                                {"(" + this.translate("Window_Title_Label_SmartParcel_Return_Label_Checked_Note") + " )"}
                            </span>
                        </div>



                        <div className="wms-wrapper">

                            <WmsDropdown
                                label={this.translate("Window_Title_Label_FedEx_Return_Service")}
                                id="SmallParcelReturnFedExService"
                                name="SmallParcelReturnFedExService"
                                wmsSelector={this.state.selector + "FedExService"}
                                value={renderSettings.SmallParcelReturnFedExService}
                                onChangeFunc={this.handleSettingsChange}
                                options={this.props.sharedSettings.carrierFedEx}
                                valueField="Code"
                                textField="Description"
                                parentClassName="form_group"
                                blankFirstOption={true}
                                blankFirstOptionText={'Not specified'}
                            />

                            <span className="wms-smartparcel-note">
                                ( {this.translate("Window_Title_Label_FedEx_Return_Service_Note")})
                        </span>
                        </div>


                        <div className="wms-wrapper">

                            <WmsDropdown
                                id="SmallParcelReturnUpsService"
                                name="SmallParcelReturnUpsService"
                                label={this.translate("Window_Title_Label_UPS_Return_Service")}
                                wmsSelector={this.state.selector + "UpsService"}
                                value={renderSettings.SmallParcelReturnUpsService}
                                onChangeFunc={this.handleSettingsChange}
                                options={this.props.sharedSettings.carrierUPS}
                                valueField="Code"
                                textField="Description"
                                parentClassName="form_group"
                                blankFirstOption={true}
                                blankFirstOptionText={'Not specified'}
                            />

                            <span className="wms-smartparcel-note">
                                ( {this.translate("Window_Title_Label_UPS_Return_Service_Note")})
                        </span>
                        </div>

                        <div className="wms-radio-wrapper">

                            <span className="wms-label">
                                {this.translate("Window_Title_Label_UPS_Endicia_Return_Type")}
                            </span>

                            <WmsRadio
                                label={this.translate("Window_Title_Label_Standard_Return")}
                                name="103"
                                value={renderSettings.SmallParcelUspsReturnType == '103'}
                                wmsSelector={this.state.selector + "StandardReturn"}
                                onChangeFunc={this.handleSettingsChange}
                                checked={renderSettings.SmallParcelUspsReturnType == '103'}
                            />
                            <WmsRadio
                                label={this.translate("Window_Title_Label_Pay_on_Use")}
                                name="109"
                                value={renderSettings.SmallParcelUspsReturnType == '109'}
                                wmsSelector={this.state.selector + "PayOnUse"}
                                onChangeFunc={this.handleSettingsChange}
                                checked={renderSettings.SmallParcelUspsReturnType == '109'}
                            />

                            <span className="wms-smartparcel-note">
                                ( {this.translate("Window_Title_Label_UPS_Endicia_Return_Type_Note")})
                        </span>
                        </div>
                    </WmsContainer>
                }

                <WmsContainer title={this.translate("Window_Title_Label_SmartParcel_Return_Address")}>
                    <WmsInput
                        id='Company'
                        inputName='Company'
                        wmsSelector={this.state.selector + 'Company'}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Company')}
                        value={renderSettings.Company} />

                    <WmsInput
                        id='Name'
                        inputName='Name'
                        wmsSelector={this.state.selector + 'Name'}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Name')}
                        value={renderSettings.Name} />

                    <WmsInput
                        id='Address1'
                        inputName='Address1'
                        wmsSelector={this.state.selector + 'Address1'}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Warehouse_Address1')}
                        value={renderSettings.Address1} />

                    <WmsInput
                        id='Address2'
                        inputName='Address2'
                        wmsSelector={this.state.selector + 'Address2'}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Warehouse_Address2')}
                        value={renderSettings.Address2} />

                    <WmsInput
                        id='City'
                        inputName='City'
                        wmsSelector={this.state.selector + 'City'}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Warehouse_City')}
                        value={renderSettings.City} />

                    <WmsInput
                        id='State'
                        inputName='State'
                        wmsSelector={this.state.selector + 'State'}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Warehouse_State')}
                        value={renderSettings.State} />

                    <WmsInput
                        id='Zip'
                        inputName='Zip'
                        wmsSelector={this.state.selector + 'Zip'}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Warehouse_Zip')}
                        value={renderSettings.Zip} />

                    <div className="custom-half-width-Dropdown">
                        <WmsDropdown
                            id="Country"
                            name="Country"
                            label={this.translate("Label_Country")}
                            wmsSelector={this.state.selector + "Country"}
                            value={renderSettings.Country}
                            onChangeFunc={this.handleSettingsChange}
                            options={this.state.optionsCountry}
                            textField='CountryCode'
                            valueField='CountryCode'
                            parentClassName="form_group "
                            blankFirstOption={true}
                        />
                    </div>


                    <WmsInput
                        id='Phone'
                        inputName='Phone'
                        wmsSelector={this.state.selector + 'Phone'}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Label_Phone')}
                        value={renderSettings.Phone} />

                    <WmsInput
                        id='Code'
                        inputName='Code'
                        wmsSelector={this.state.selector + 'Code'}
                        onChangeFunc={this.handleSettingsChange}
                        label={this.translate('Window_Title_Label_SmartParcel_Code')}
                        value={renderSettings.Code} />

                </WmsContainer>

                {this.state.Rights.hasOwnProperty('Shipping.SmallParcelRateShoppingEnabled') &&
                    <WmsContainer title={this.translate("Window_Title_Label_SmartParcel_RateShopping")}>
                        <div className="wms-rate-shopping-wrapper">
                            <WmsCheckBox
                                id={'AMZN'}
                                name={'AMZN'}
                                onChangeFunc={this.handleRateShoppingChange}
                                label={this.translate("Window_Title_Label_Amazon_Buy_Shipping")}
                                checked={renderArrRateShopping.indexOf('AMZN') != -1}
                                value={renderArrRateShopping.indexOf('AMZN') != -1}
                                wmsSelector={this.state.selector + 'AmazonByEnabled'} />

                            <WmsCheckBox
                                id={'DHLGM'}
                                name={'DHLGM'}
                                onChangeFunc={this.handleRateShoppingChange}
                                label={this.translate("Window_Title_Label_SmartParcel_DHL_Enabled")}
                                checked={renderArrRateShopping.indexOf('DHLGM') != -1}
                                value={renderArrRateShopping.indexOf('DHLGM') != -1}
                                wmsSelector={this.state.selector + 'DhlgmEnabled'} />

                            <WmsCheckBox
                                id={'FDXE'}
                                name={'FDXE'}
                                onChangeFunc={this.handleRateShoppingChange}
                                label={this.translate("Window_Title_Label_SmartParcel_Fedex_Enabled")}
                                checked={renderArrRateShopping.indexOf('FDXE') != -1}
                                value={renderArrRateShopping.indexOf('FDXE') != -1}
                                wmsSelector={this.state.selector + 'FedExEnabled'} />

                            <WmsCheckBox
                                id={'UPS'}
                                name={'UPS'}
                                onChangeFunc={this.handleRateShoppingChange}
                                label={this.translate("Window_Title_Label_SmartParcel_UPS_Enabled")}
                                checked={renderArrRateShopping.indexOf('UPS') != -1}
                                value={renderArrRateShopping.indexOf('UPS') != -1}
                                wmsSelector={this.state.selector + 'UpsEnabled'} />



                            <WmsCheckBox
                                id={'ENDCA'}
                                name={'ENDCA'}
                                onChangeFunc={this.handleRateShoppingChange}
                                label={this.translate("Window_Title_Label_SmartParcel_USPS_Enabled")}
                                checked={renderArrRateShopping.indexOf('ENDCA') != -1}
                                value={renderArrRateShopping.indexOf('ENDCA') != -1}
                                wmsSelector={this.state.selector + 'UspsEnabled'} />

                        </div>
                    </WmsContainer>
                }

                {this.state.Rights.hasOwnProperty('Shipping.SmallParcelRateShoppingEnabled') &&
                    <WmsContainer title={this.translate("Window_Title_Label_SmartParcel_Auto_RateShopping")}>
                        <div className="wms-input-dropdown-btn-block">
                            <WmsInput
                                id='AutoRateShoppingMapping'
                                inputName='AutoRateShoppingMapping'
                                wmsSelector={this.state.selector + 'AutoRateShoppingMapping'}
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate('Window_Title_Label_SmartParcel_Auto_RateShopping_Mapping')}
                                value={renderSettings.AutoRateShoppingMapping}
                            />

                            <WmsDropdown
                                id='AutoRateShoppingLogic'
                                name='AutoRateShoppingLogic'
                                wmsSelector={this.state.selector + 'AutoRateShoppingLogic'}
                                onChangeFunc={this.handleSettingsChange}
                                label={this.translate("Window_Title_Label_SmartParcel_Auto_RateShopping_Logic")}
                                options={this.state.rateShoppingLogics}
                                valueField="value"
                                textField="text"
                                parentClassName="form_group"
                                value={renderSettings.AutoRateShoppingLogic}
                            />
                            <div className="wms-add-and-remove-btn-block">
                                <WmsButton
                                    label={this.translate('Label_Add')}
                                    wmsSelector={this.state.selector + "addLogic"}
                                    onClickFunc={this.eventClickAddLogic}
                                    icon={'fa-plus'}
                                    buttonType="green"
                                    disabled={!renderSettings.AutoRateShoppingMapping}
                                />

                                <WmsButton
                                    label={this.translate('Label_Remove')}
                                    wmsSelector={this.state.selector + "removeLogic"}
                                    onClickFunc={this.eventClickRemoveLogic}
                                    disabled={this.state.acticveLogic == false}
                                    icon={'fa-trash'}
                                    buttonType="red"
                                />
                            </div>
                        </div>
                        <WmsFormGrid
                            id={this.state.selector + 'gridRateShoppingLogic'}
                            wmsSelector={this.state.selector + 'gridRateShoppingLogic'}
                            gridKeySelection={'Mapping'}
                            columns={this.state.gridColumns}
                            staticGridData={this.state.gridData}
                            schema={this.state.gridSchema}
                            isColumnsResizable={true}
                            isColumnsReorderable={true}
                            isColumnMenuenable={false}
                            isColumnsSortable={true}
                            parentClassName={'showHeader'}
                            onSelection={this.selectLogicGrid}
                            IsMultipleSelectable={true}
                            selectionString={this.translate('Label_User_Warehouse_Assigned_String')}
                        />
                    </WmsContainer>
                }
            </div>
        )
    }
}

