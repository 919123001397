import React from 'react';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from '../../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsTextArea from '../../components/WmsTextArea/WmsTextArea.jsx';
import WmsCheckBox from '../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsCustomDateTimePicker from '../../components/WmsCustomDateTimePicker/WmsCustomDateTimePicker.jsx';
import WmsComboBox from '../../components/WmsComboBox/WmsComboBox.jsx';
import { ObjectWms } from './../../../../assets/ts/ObjectWms.ts';
import { ArrayWms } from './../../../../assets/ts/ArrayWms.ts';

//services
import * as GlobalService from '../../global_services';
import * as OrderServices from "./manage-orders.services.jsx";
import * as manageOrderProtoMetaDataService from './manage-order-proto-meta-data.service';

export class ManageOrdersCarrierRoutingInfo extends React.Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.state = {
            shipmentServices: [],
            Selector: 'OrderProtoTypeCarrierRouting',
            CustomLabels: GlobalService.getCustomLabels(),
            Rights: GlobalService.GetRights(),
            optionsDeliveryConfirmationType: [
                { description: 'None', value: 'none' },
                { description: 'Delivery', value: 'delivery' },
                { description: 'Signature', value: 'signature' },
                { description: 'Adult Signature', value: 'adult_signature' },
                { description: 'Direct Signature', value: 'direct_signature' }
            ],
            optionsInsuranceType: [
                { description: 'None', value: 0 },
                { description: 'Carrier', value: 106 },
                { description: 'Third-party', value: 107 }
            ],
            optionsCODType: [
                { description: 'None', value: 0 },
                { description: 'Any', value: 100 },
                { description: 'Cash', value: 103 },
                { description: 'Cash Equivalent', value: 'Cash Equivalent' }
            ],
            optionsInitalContentType: [
                { description: 'None', value: "" },
                { description: 'Documents', value: 'documents' },
                { description: 'Gift', value: 'gift' },
                { description: 'Merchandise', value: 'merchandise' },
                { description: 'Returned Goods', value: 'returned_goods' },
                { description: 'Sample', value: 'sample' }
            ],

            optionsInitalNoneDelivery: [
                { description: 'None', value: "" },
                { description: 'Treat as Abandoned', value: 'treat_as_abandoned' },
                { description: 'Return to Sender', value: 'return_to_sender' }
            ],
            isExpandedCollapse: (!$.isEmptyObject(props.metaDataList) ? props.metaDataList : []),
        };
    }

    componentDidMount() {
        var OrderData = this.props.shareOrderData;
        let carrierIndex = ObjectWms.FindIndexByProperty(this.props.sharedOptions.Carriers.Carriers, "Carrier", OrderData.RoutingInfo.Carrier);
        let shipmentServices = [];
        if (carrierIndex == -1) {
            shipmentServices = GlobalService.hideDeactivatedShipmentServices(OrderData.RoutingInfo.Carrier, this.props.sharedOptions.Carriers.DefaultShipmentServices, OrderData.RoutingInfo.Mode);
        } else {
            shipmentServices = GlobalService.hideDeactivatedShipmentServices(OrderData.RoutingInfo.Carrier, this.props.sharedOptions.Carriers.Carriers[carrierIndex].ShipmentServices, OrderData.RoutingInfo.Mode);
        }
        this.setState({
            shipmentServices: shipmentServices.shipmentServices
        });
    }

    EventChangeCarrier = (event) => {

        var orderData;
        let shipmentServices = [];

        if (event.target.value) {
            var tempObject = ArrayWms.findObjectByKey(this.props.sharedOptions.Carriers.Carriers, 'Carrier', event.target.value);
            if (tempObject != null) {
                shipmentServices = GlobalService.hideDeactivatedShipmentServices(event.target.value, tempObject.ShipmentServices);
            }
            orderData = Object.assign(
                {},
                this.props.shareOrderData,
                {
                    BillingCode: '',
                    RoutingInfo: Object.assign(
                        {},
                        this.props.shareOrderData.RoutingInfo,
                        {
                            Carrier: tempObject != null ? tempObject.Carrier : event.target.value,
                            ScacCode: tempObject != null ? tempObject.ScacCode : null,
                            Account: '',
                            Mode: '',
                            ShipPointZip: ''
                        }
                    )
                }
            );
            this.setAccountNumberAndZip(event.target.value, this.props.shareOrderData.BillingCode);
        } else {
            this.setState({
                accountSelectedText: '',
            });
            shipmentServices = GlobalService.hideDeactivatedShipmentServices('', this.props.sharedOptions.Carriers.DefaultShipmentServices);
            orderData = Object.assign(
                {},
                this.props.shareOrderData,
                {
                    BillingCode: '',
                    RoutingInfo: Object.assign(
                        {},
                        this.props.shareOrderData.RoutingInfo,
                        {
                            Carrier: '',
                            ScacCode: '',
                            Account: '',
                            Mode: '',
                            ShipPointZip: null
                        }
                    )
                }
            );
        }
        if (shipmentServices.length == 0) {
            shipmentServices = GlobalService.hideDeactivatedShipmentServices('', this.props.sharedOptions.Carriers.DefaultShipmentServices);
        }

        this.setState({
            shipmentServices: shipmentServices.shipmentServices
        });

        this.props.onOrderDataSet(orderData);
    }
    setCollapseMetaData = (isExpanded, collapseId) => {
        if (this.props.isPrototypeComponent) {
            manageOrderProtoMetaDataService.setManageOrderProtoCollapseMetaData(collapseId, isExpanded);
        }
    }

    setAccountNumberAndZip = (param_current_carrier_index, param_billing_code) => {
        //var selectedCarrierDetails = this.props.sharedOptions.Carriers.Carriers[param_current_carrier_index];
        var selectedCarrierDetails = ArrayWms.findObjectByKey(this.props.sharedOptions.Carriers.Carriers, 'Carrier', param_current_carrier_index);

        // CustCarrierInfo 0 = Off, 1 = On/Both, 2 = PPOnly, 3 = 3rdPartyOnly
        var custSetting = this.props.sharedOptions.CustFacCarrierInfo.CustCarrierInfo;

        // logic updated per WMS-16030.  jps 2021-09-29
        if (selectedCarrierDetails != null) {
            var accountSelectedText = '';
            var Account = '';
            var ShipPointZip = '';

            if (selectedCarrierDetails.Carrier == 'UPS') {
                if (param_billing_code == 'Prepaid') {
                    if (custSetting == 2 || custSetting == 1) {
                        Account = this.props.sharedOptions.CustFacCarrierInfo.CustUPSAccount;
                        ShipPointZip = this.props.sharedOptions.CustFacCarrierInfo.CustUPSAccountZip;
                        accountSelectedText = 'UPS Account of ' + this.props.sharedOptions.CustFacCarrierInfo.CustName;
                    }
                    else if (custSetting == 0 || custSetting == 3) {
                        Account = this.props.sharedOptions.CustFacCarrierInfo.FacUPSAccount;
                        ShipPointZip = this.props.sharedOptions.CustFacCarrierInfo.FacZip;
                        accountSelectedText = 'UPS Account of ' + this.props.sharedOptions.CustFacCarrierInfo.FacName;
                    }
                    else { } // unknown customer setting
                }
                else if (param_billing_code == 'BillThirdParty') {
                    if (custSetting == 3 || custSetting == 1) {
                        Account = this.props.sharedOptions.CustFacCarrierInfo.CustUPSAccount;
                        ShipPointZip = this.props.sharedOptions.CustFacCarrierInfo.CustUPSAccountZip;
                        accountSelectedText = 'UPS Account of ' + this.props.sharedOptions.CustFacCarrierInfo.CustName;
                    }
                }
                else { } // Order NOT Prepaid OR BillThirdParty - no action leave AcctNum and Zip blank.
            }
            else if (selectedCarrierDetails.Carrier == 'FedEx') {
                if (param_billing_code == 'sender') {
                    if (custSetting == 2 || custSetting == 1) {
                        Account = this.props.sharedOptions.CustFacCarrierInfo.CustFedexAccount;
                        ShipPointZip = this.props.sharedOptions.CustFacCarrierInfo.CustUPSAccountZip;
                        accountSelectedText = 'FedEx Account of ' + this.props.sharedOptions.CustFacCarrierInfo.CustName;
                    }
                    else if (custSetting == 0 || custSetting == 3) {
                        Account = this.props.sharedOptions.CustFacCarrierInfo.FacFedexAccount;
                        ShipPointZip = this.props.sharedOptions.CustFacCarrierInfo.FacZip;
                        accountSelectedText = 'FedEx Account of ' + this.props.sharedOptions.CustFacCarrierInfo.FacName;
                    }
                    else { } // unknown customer setting
                }
                else if (param_billing_code == 'third party') {
                    if (custSetting == 3 || custSetting == 1) {
                        Account = this.props.sharedOptions.CustFacCarrierInfo.CustFedexAccount;
                        ShipPointZip = this.props.sharedOptions.CustFacCarrierInfo.CustUPSAccountZip;
                        accountSelectedText = 'FedEx Account of ' + this.props.sharedOptions.CustFacCarrierInfo.CustName;
                    }
                    // else {
                    //     Account = this.props.sharedOptions.CustFacCarrierInfo.FacFedexAccount;
                    //     ShipPointZip = this.props.sharedOptions.CustFacCarrierInfo.FacZip;
                    //     accountSelectedText = 'Fedex Account of ' + this.props.sharedOptions.CustFacCarrierInfo.FacName;
                    // } // for the other two custSettings, leave blank.  For unknown custSetting, leave blank.
                }
            }
            else { }  // Take no action.  This covers any Carrier other than UPS and FedEx.

			if ((this.props.shareOrderData.RoutingInfo.ShipPointZip != undefined && this.props.shareOrderData.RoutingInfo.ShipPointZip != ShipPointZip) ||
				(this.props.shareOrderData.RoutingInfo.Account != undefined && this.props.shareOrderData.RoutingInfo.Account != Account)) {
                // In addition to assigning field values on the screen,
                // this is what clears out the fields when Carrier or BillType changes require it.
                var orderData = Object.assign(
                    {},
                    this.props.shareOrderData,
                    {
                        RoutingInfo: Object.assign(
                            {},
                            this.props.shareOrderData.RoutingInfo,
                            {
                                ShipPointZip: ShipPointZip,
                                Account: Account,
                            }
                        )
                    }
                );

                this.props.onOrderDataSet(orderData);

                this.setState({
                    accountSelectedText: accountSelectedText,
                });
            }
        }
        else{
            var orderData = Object.assign(
                {},
                this.props.shareOrderData,
                {
                    RoutingInfo: Object.assign(
                        {},
                        this.props.shareOrderData.RoutingInfo,
                        {
                            ShipPointZip: '',
                            Account: '',
                            Carrier: param_current_carrier_index
                        }
                    )
                }
            );

            this.props.onOrderDataSet(orderData);
            this.setState({
                accountSelectedText: '',
            });
        }
    }

    handleChangeEvent = (event) => {

        let OrderData = Object.assign({}, this.props.shareOrderData);
        if (event.target.type === "checkbox") {
            OrderData.RoutingInfo[event.target.name] = !this.props.shareOrderData.RoutingInfo[event.target.name];
        }

        else if (event.target.name === "CapacityTypeIdentifier") {
            if (OrderData.RoutingInfo['CapacityTypeIdentifier'] == null) {
                OrderData.RoutingInfo['CapacityTypeIdentifier'] = { 'Id': event.target.value };
            } else {
                if (event.target.value == "") {
                    OrderData.RoutingInfo['CapacityTypeIdentifier'] = null;
                } else {
                    OrderData.RoutingInfo['CapacityTypeIdentifier'].Id = event.target.value;
                }
            }

        } else {
            OrderData.RoutingInfo[event.target.name] = event.target.value;
        }

        this.props.onOrderDataSet(OrderData);
    }
    handleParcelOptionsChangeEvent = (event) => {
        let OrderData = Object.assign({}, this.props.shareOrderData);
        if(!OrderData.ParcelOptions){
            OrderData.ParcelOptions = {};
        }
        if (event.target.type === "checkbox") {
            OrderData.ParcelOptions[event.target.name] = event.target.checked;
        } else {
            OrderData.ParcelOptions[event.target.name] = event.target.value;
        }

        this.props.onOrderDataSet(OrderData);
    }


    handleOrderMainObjChange = (e) => {
        let OrderData = Object.assign({}, this.props.shareOrderData);

        let val = e.target.value;
        OrderData[e.target.name] = val;
        this.props.onOrderDataSet(OrderData);

        if (e.target.name == 'BillingCode') {
            let carrier = ArrayWms.findObjectByKey(this.props.sharedOptions.Carriers.Carriers, "Carrier", OrderData.RoutingInfo.Carrier);
            if (carrier != null) {
                setTimeout(() => {
                    this.setAccountNumberAndZip(carrier.Carrier, val);
                }, 500);
            }
        }
    }


    render() {
        var OrderData = this.props.shareOrderData;
        let carrierIndex = ObjectWms.FindIndexByProperty(this.props.sharedOptions.Carriers.Carriers, "Carrier", OrderData.RoutingInfo.Carrier);

        return (
            <div className="carrier-and-routing-section collapseOrderTabContent_wrapper" ref={this.props.divRefs}>
                <WmsCollapse
                    id={'collapse3'}
                    headerLabel={this.translate('Label_Carrier')}
                    showCollapseText={false}
                    isExpanded={this.props.isPrototypeComponent ? ((this.state.isExpandedCollapse.hasOwnProperty('manageOrderCarrier')) ? !this.state.isExpandedCollapse['manageOrderCarrier'] : true) : true}
                    setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'manageOrderCarrier')}
                    windowId={this.props.windowId}
                >

                    <div className="display-inline">
                        <WmsComboBox
                            id={"CarrierComboBox" + this.props.windowId}
                            name="CarrierComboBox"
                            label={this.translate('Label_Carrier')}
                            wmsSelector={this.state.Selector + 'Carrier'}
                            value={OrderData.RoutingInfo.Carrier}
                            onChangeFunc={this.EventChangeCarrier}
                            textField="DisplayName"
                            valueField="Carrier"
                            placeholder={'Select or Enter a Carrier'}
                            noDataTemplate={false}
                            options={this.props.sharedOptions.Carriers.Carriers}
                            parentClassName="order-info-carrier"
                        />

                        <WmsInput
                            id='ScacCode'
                            inputName='ScacCode'
                            wmsSelector={this.state.Selector + 'ScacCode'}
                            value={(OrderData.RoutingInfo != null) ? OrderData.RoutingInfo.ScacCode : null}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.translate('Label_SCAC')}
                            regularExp={/^.{1,255}$/}
                            extraWrapperClass="order-info-scac"
                        />

                        <WmsComboBox
                            id={"ShipmentServiceComboBox" + this.props.windowId}
                            name="Mode"
                            placeholder={'Select or Enter a Service'}
                            label={this.translate('Label_Service')}
                            wmsSelector={this.state.Selector + 'ComboService'}
                            value={OrderData.RoutingInfo.Mode}
                            onChangeFunc={this.handleChangeEvent}
                            noDataTemplate={false}
                            options={this.state.shipmentServices}
                            textField='Description'
                            valueField='Code'
                            parentClassName="order-info-service"
                        />

                        <WmsDropdown
                            id="BillingCodeComboBox"
                            name="BillingCode"
                            label={this.translate('Label_Billing')}
                            wmsSelector={this.state.Selector + 'ComboBillingCode'}
                            value={OrderData.BillingCode}
                            blankFirstOption={true}
                            onChangeFunc={this.handleOrderMainObjChange}
                            options={carrierIndex == -1 ? this.props.sharedOptions.Carriers.DefaultBillingCodes :
                                this.props.sharedOptions.Carriers.Carriers[carrierIndex].BillingCodes
                            }
                            textField='Code'
                            valueField='Code'
                            extraWrapperClass="order-info-billing-code"
                        />

                        <div className="accountSelectedTextHolder">
                            <WmsInput
                                id='AccountNumber'
                                inputName='Account'
                                wmsSelector={this.state.Selector + 'AccountNumber'}
                                value={(OrderData.RoutingInfo != null) ? OrderData.RoutingInfo.Account : null}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Account_Number')}
                                regularExp={/^.{1,255}$/}
                                extraWrapperClass='order-info-account-number'
                            />
                            {this.state.accountSelectedText != '' &&
                                <span className="accountSelectedText">{this.state.accountSelectedText}</span>
                            }
                        </div>
                        <WmsInput
                            id='AccountZip'
                            inputName='ShipPointZip'
                            wmsSelector={this.state.Selector + 'AccountZip'}
                            value={(OrderData.RoutingInfo != null) ? OrderData.RoutingInfo.ShipPointZip : null}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.translate('Label_Account_Number_And_Postal_Code')}
                            regularExp={/^.{1,255}$/}
                            extraWrapperClass='order-info-account-zip'
                        />

                        <WmsInput
                            id='TrackingNumber'
                            inputName='TrackingNumber'
                            wmsSelector={this.state.Selector + 'TrackingNumber'}
                            value={(OrderData.RoutingInfo != null) ? OrderData.RoutingInfo.TrackingNumber : null}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.translate('Label_Tracking_Number')}
                            required={(this.props.sharedSelectedCustomer.options.requireTrackingNumber) ? this.props.sharedSelectedCustomer.options.requireTrackingNumber : false}
                            regularExp={/^.{1,255}$/}
                            extraWrapperClass='order-info-tracking-number'
                        />
                        {/* </div>

                    <div className="display-inline warehouse-carrier-instructions"> */}
                        <WmsTextArea
                            id="WarehouseInstructions"
                            name="Notes"
                            rows={5}
                            maxLength={4096}
                            label={this.state.CustomLabels.Label_Warehouse + ' ' + this.translate('Label_Warehouse_Instructions')}
                            wmsSelector={this.state.Selector + 'WarehouseInstructions'}
                            onChangeFunc={this.handleOrderMainObjChange}
                            value={OrderData.Notes}
                            parentClassName="order-info-warehouse-instruction"
                        />

                        <WmsTextArea
                            id="CarrierInstructions"
                            name="ShippingNotes"
                            rows={5}
                            maxLength={4096}
                            label={this.translate('Label_Carrier_Instructions')}
                            wmsSelector={this.state.Selector + 'CarrierInstructions'}
                            onChangeFunc={this.handleOrderMainObjChange}
                            value={OrderData.ShippingNotes}
                            parentClassName="order-info-carrier-instruction"
                        />
                    </div>

                </WmsCollapse>

                <WmsCollapse
                    id={'manageOrderRouting'}
                    headerLabel={this.translate('Label_Routing')}
                    showCollapseText={false}
                    isExpanded={this.props.isPrototypeComponent ? ((this.state.isExpandedCollapse.hasOwnProperty('manageOrderRouting')) ? !this.state.isExpandedCollapse['manageOrderRouting'] : true) : true}
                    setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'manageOrderRouting')}
                    windowId={this.props.windowId}
                >

                    <div className="display-inline">
                        <WmsInput
                            id='LoadNumber'
                            inputName='LoadNumber'
                            wmsSelector={this.state.Selector + 'LoadNumber'}
                            value={(OrderData.RoutingInfo != null) ? OrderData.RoutingInfo.LoadNumber : null}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.translate('Label_Load_Number')}
                            regularExp={/^.{1,255}$/}
                            extraWrapperClass='order-routing-load-number'
                        />

                        <WmsInput
                            id='BolNumber'
                            inputName='BillOfLading'
                            wmsSelector={this.state.Selector + 'BolNumber'}
                            value={(OrderData.RoutingInfo != null) ? OrderData.RoutingInfo.BillOfLading : null}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.state.CustomLabels.Label_Bill_Of_Lading}
                            regularExp={/^.{1,255}$/}
                            extraWrapperClass='order-routing-bol-number'
                        />

                        <WmsInput
                            id='TrailerNumber'
                            inputName='TrailerNumber'
                            wmsSelector={this.state.Selector + 'TrailerNumber'}
                            value={(OrderData.RoutingInfo != null) ? OrderData.RoutingInfo.TrailerNumber : null}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.translate('Label_Trailer_Number')}
                            regularExp={/^.{1,255}$/}
                            extraWrapperClass='order-routing-trailer-number'
                        />

                        <WmsInput
                            id='SealNumber'
                            inputName='SealNumber'
                            wmsSelector={this.state.Selector + 'SealNumber'}
                            value={(OrderData.RoutingInfo != null) ? OrderData.RoutingInfo.SealNumber : null}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.translate('Label_Seal_Number')}
                            regularExp={/^.{1,255}$/}
                            extraWrapperClass='order-routing-seal-number'
                        />

                        <WmsInput
                            id='DoorNumber'
                            inputName='DoorNumber'
                            wmsSelector={this.state.Selector + 'DoorNumber'}
                            value={(OrderData.RoutingInfo != null) ? OrderData.RoutingInfo.DoorNumber : null}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.state.CustomLabels.Label_Door}
                            regularExp={/^.{1,255}$/}
                            extraWrapperClass='order-routing-door'
                        />

                        <WmsDropdown
                            id="IndexOptionsCapacityType"
                            name="CapacityTypeIdentifier"
                            label={this.translate('Label_Capacity_Type')}
                            wmsSelector={this.state.Selector + 'CapacityType'}
                            value={(OrderData.RoutingInfo != null && OrderData.RoutingInfo.CapacityTypeIdentifier != null) ? OrderData.RoutingInfo.CapacityTypeIdentifier.Id : null}
                            blankFirstOption={true}
                            onChangeFunc={this.handleChangeEvent}
                            options={this.props.sharedOptions.CapacityTypes}
                            textField='Description'
                            valueField='CapacityTypeId'
                            extraWrapperClass='order-capacity-type'
                        />

                        <WmsCustomDateTimePicker
                            id={'DatePickerRoutingInfo'}
                            name='PickupDate'
                            wmsSelector={this.state.Selector + "DatePickerRoutingInfo"}
                            label={this.translate('Label_Pickup_Date')}
                            onChangeFunc={this.handleChangeEvent}
                            value={(OrderData.RoutingInfo != null) ? OrderData.RoutingInfo.PickupDate : null}
                            windowId={this.props.windowId}
                            isDateParseInCustomFormat={true}
                        />
                    </div>

                </WmsCollapse>
                <div className={this.state.Rights.hasOwnProperty('orders.manage.smartparcel') ? "" : 'disable-section'}>
                    <WmsCollapse
                        id={'manageOrderSmallParcelShipping'}
                        headerLabel={this.translate('Label_Small_Parcel_Shipping')}
                        showCollapseText={false}
                        showCollapseIcon={this.state.Rights.hasOwnProperty('orders.manage.smartparcel')}
                        isExpanded={this.props.isPrototypeComponent ? ((this.state.isExpandedCollapse.hasOwnProperty('manageOrderSmallParcelShipping')) ? !this.state.isExpandedCollapse['manageOrderSmallParcelShipping'] : true) : true}
                        isShowTooltip={!(this.state.Rights.hasOwnProperty('orders.manage.smartparcel'))}
                        tooltipText={this.translate('Note_Disable_Small_Parcel')}
                        setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'manageOrderSmallParcelShipping')}
                        windowId={this.props.windowId}
                    >

                        <div className="display-inline shipping-three-checkbox">

                            <WmsCheckBox
                                id={this.props.windowId + "chk_Order_RoutingInfo_RequiresReturnReceipt"}
                                name="RequiresReturnReceipt"
                                wmsSelector={this.state.Selector + 'RequiresReturnReceipt'}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Require_Return_Receipt')}
                                checked={(OrderData.RoutingInfo) ? OrderData.RoutingInfo.RequiresReturnReceipt : false}
                                value=""
                                parentClassName="chk-require-return-label"
                            />

                            <WmsCheckBox
                                id={this.props.windowId + "chk_Order_RoutingInfo_SaturdayDelivery"}
                                name={'SaturdayDeliveryFlag'}
                                wmsSelector={this.state.Selector + 'SaturdayDeliveryFlag'}
                                onChangeFunc={this.handleParcelOptionsChangeEvent}
                                label={this.translate('Label_Saturday_Delivery')}
                                checked={(OrderData.ParcelOptions) ? OrderData.ParcelOptions.SaturdayDeliveryFlag : false}
                                value=""
                                parentClassName="chk-saturday-delivery-flag"
                            />

                            <WmsCheckBox
                                id={this.props.windowId + "chk_Order_RoutingInfo_ResidentialDelivery"}
                                name={'ResidentialFlag'}
                                wmsSelector={this.state.Selector + 'ResidentialDelivery'}
                                onChangeFunc={this.handleParcelOptionsChangeEvent}
                                label={this.translate('Label_Reidential_Delivery')}
                                checked={(OrderData.ParcelOptions) ? OrderData.ParcelOptions.ResidentialFlag : false}
                                value=""
                                parentClassName="chk-residential-delivery"
                            />
                        </div>

                        <div className="display-inline">

                            <WmsCheckBox
                                id={this.props.windowId + "chk_Order_RoutingInfo_IsInsurance"}
                                name="IsInsurance"
                                wmsSelector={this.state.Selector + 'IsInsured'}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Insurance')}
                                checked={(OrderData.RoutingInfo != null) ? OrderData.RoutingInfo.IsInsurance : false}
                                value=""
                                parentClassName="chk-is-insured"
                            />

                            <WmsDropdown
                                id="ShippingInfoInsuranceType"
                                name='InsuranceType'
                                label={this.translate('Label_Insurance_Type')}
                                wmsSelector={this.state.Selector + 'InsuranceType'}
                                value={(OrderData.ParcelOptions) ? OrderData.ParcelOptions.InsuranceType : ''}
                                onChangeFunc={this.handleParcelOptionsChangeEvent}
                                options={this.state.optionsInsuranceType}
                                textField='description'
                                valueField='value'
                                disabled={false}
                                extraWrapperClass={"order-insurance-type " + (OrderData.RoutingInfo != null && OrderData.RoutingInfo.IsInsurance == false ? ' disable-options' : '')}
                            />

                            <WmsInput
                                id="shippingInfoInsuranceAmount"
                                inputName='InsuranceAmount'
                                wmsSelector={this.state.Selector + 'InsuranceAmount'}
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                onChangeFunc={this.handleParcelOptionsChangeEvent}
                                label={this.translate('Label_Insurance_Amount')}
                                extraSignBeforeValue={'$'}
                                value={(OrderData.ParcelOptions) ? OrderData.ParcelOptions.InsuranceAmount : ''}
                                extraWrapperClass={"order-insurance-amount " + (OrderData.RoutingInfo != null && OrderData.RoutingInfo.IsInsurance == false ? ' disable-options' : '')}
                            />
                        </div>
                        {/* <div className="display-inline">

                                <WmsCheckBox
                                    id={'shippingInfoCOD'}
                                    name={'IsCod'}
                                    wmsSelector={this.state.selector + 'COD'}
                                    onChangeFunc={this.handleParcelOptionsChangeEvent}
                                    label={this.translate('Label_COD')}
                                    checked={false}
                                    value={''}
                                    parentClassName="chk-right-margin chk-cod chk-margin-top"/>

                                <WmsDropdown
                                    id="ShippingInfoCODType"
                                    name='CODPayType'
                                    label={this.translate('Label_COd_Pay_Type')}
                                    wmsSelector={this.state.selector + 'CODType'}
                                    value={''}
                                    onChangeFunc={this.handleParcelOptionsChangeEvent}
                                    options={this.state.optionsCODType}
                                    textField='description'
                                    valueField='value'
                                    extraWrapperClass="order-small-parcel-COD-type"/>
                            </div> */}

                        <div className="display-inline">
                            <WmsDropdown
                                id={this.props.windowId + "chk_Order_RoutingInfo_DeliveryConfirmationType"}
                                name="DeliveryConfirmationType"
                                wmsSelector={this.state.Selector + 'DeliveryConfirmationType'}
                                onChangeFunc={this.handleParcelOptionsChangeEvent}
                                label={this.translate('Label_Delivery_Confirmation_Type')}
                                options={this.state.optionsDeliveryConfirmationType}
                                textField='description'
                                valueField='value'
                                value={(OrderData.ParcelOptions) ? OrderData.ParcelOptions.DeliveryConfirmationType : ''}
                                extraWrapperClass={"order-small-delivery-confirmation-type"}
                            />

                            <WmsInput
                                id="shippingInfoDryIceWeight"
                                inputName='DryIceWeight'
                                wmsSelector={this.state.Selector + 'DryIceWeight'}
                                onChangeFunc={this.handleParcelOptionsChangeEvent}
                                label={this.translate('Label_Dry_Ice_Weight')}
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                value={(OrderData.ParcelOptions) ? OrderData.ParcelOptions.DryIceWeight : ''}
                                extraWrapperClass="order-small-parcel-dry-ice-weight"
                                valueType="number" />

                            <WmsDropdown
                                id="ShippingInfoInitContentType"
                                name='InternationalContentsType'
                                label={this.translate('Label_Initial_Contents_Type')}
                                wmsSelector={this.state.Selector + 'InternationalContentsType'}
                                value={(OrderData.ParcelOptions) ? OrderData.ParcelOptions.InternationalContentsType : ''}
                                onChangeFunc={this.handleParcelOptionsChangeEvent}
                                options={this.state.optionsInitalContentType}
                                textField='description'
                                valueField='value'
                                extraWrapperClass="order-small-parcel-init-content-type" />

                            <WmsDropdown
                                id="ShippingInfoInitmNonDelivery"
                                name='InternationalNonDelivery'
                                label={this.translate('Label_Initial_None_Delivery')}
                                wmsSelector={this.state.Selector + 'InitmNonDelivery'}
                                value={(OrderData.ParcelOptions) ? OrderData.ParcelOptions.InternationalNonDelivery : ''}
                                onChangeFunc={this.handleParcelOptionsChangeEvent}
                                options={this.state.optionsInitalNoneDelivery}
                                textField='description'
                                valueField='value'
                                extraWrapperClass="order-small-parcel-init-non-delivery" />
                        </div>

                    </WmsCollapse>
                </div>
            </div>
        );
    }
}
