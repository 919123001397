import React from 'react';
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsDropdown from '../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse.jsx';
import WmsConfirmModel from '../components/WmsConfirmModel/WmsConfirmModel.jsx';
import { SmallParcelCarrierAccountSettings } from './carrierAccount/small-parcel-carrier-account-settings.component.jsx';
import { SmallParcelSmartSettings } from './smartSettings/small-parcel-smart-settings.component.jsx';
//services
import * as GlobalService from '../global_services';
import './small-parcel-settings.scss';

//services
import * as smallParcelServices from './small-parcel-settings.services.jsx';

export class SmallParcelSettingsHolder extends React.Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;
        this.$rootScope = window;
        this.customLabels = GlobalService.getCustomLabels();
        this.windowId = this.$rootScope.ChildBulletin.Data.Window;
        this.sharedFacilityOption = GlobalService.getFacilityList(false);
        this.optionsCustomer = GlobalService.getCustomerList(false);
        this.selector = 'SmallParcelSettings';
        this.USPSCarriers = ["stamps.com", "endicia", "buku", "pitneybowesexp"];

        this.rights = GlobalService.GetRights();
        this.isEnableProvider = this.rights.hasOwnProperty('Shipping.SmallParcelEnabled') && this.rights['Integrations.SmallParcelPartner'] == 'ParcelApi';
        this.state = {
            settingsSaveLoading: false,
            isOpenConfirmModel: false,
            isFormDataChanged: false,
            sharedSmallParcelSettings: {
                carrierFedEx: [],
                carrierUPS: [],
                AutoRateShoppingMapping: '',
                AutoRateShoppingLogic: '',
            },
            selectedCustomerIndex: '',
            selectedCustomerObj: {},
            carrierAccountsGridData: [],
            sharedShipEngineCarrier: []
        }
    }
    componentDidMount() {

        var eventName = "onDirtyWindowCloseSmallParcelSettings" + this.windowId;
        document.addEventListener(eventName, this.openConfirmModal);
        var closeEventName = "removeDirtyWindowCloseSmallParcelSettings" + this.windowId;
        var that = this;
        document.addEventListener(closeEventName, function handler(e) {
            document.removeEventListener(eventName, that.openConfirmModal);
            e.currentTarget.removeEventListener(e.type, handler);
            /* whatever */
        });
    }
    openConfirmModal = () => {
        this.setState({
            isOpenConfirmModel: true
        });
    }
    onCloseFunc = () => {
        this.setState({
            isOpenConfirmModel: false
        });
    }
    onDiscardAndExit = () => {
        this.setState({
            isOpenConfirmModel: false,
            isFormDataChanged: false
        });
        setTimeout(() => {
            this.$rootScope.dirtyWindow[this.windowId] = false;
            GlobalService.closeWindow(this.windowId);
            // remove event listener on close window
            var eventName = "onDirtyWindowCloseSmallParcelSettings" + this.windowId;
            document.removeEventListener(eventName, this.openConfirmModal);
        }, 100);
    }

    handleCustomerChange = (event) => {

        let customerObj = {};
        if (event.target.value) {
            customerObj = this.optionsCustomer[event.target.value];
            this.getSmartParcelSettingsAndCarrier(customerObj.uri);
        }
        this.setState({
            selectedCustomerIndex: event.target.value,
            selectedCustomerObj: customerObj,
            isInitializeSettings: false
        });

    }
    getSmartParcelSettingsAndCarrier = (customerUri) => {

        kendo.ui.progress($('#' + this.windowId), true);
        smallParcelServices.getSmartParcelSettingsAndCarrier(customerUri).then((response) => {

            let tmpSharedSmallParcelSettings = {
                carrierFedEx: [],
                carrierUPS: [],
                AutoRateShoppingMapping: '',
                AutoRateShoppingLogic: '',
            };
            let tmpCarrierAccountsGridData = GlobalService.addRowNumberField(response.carrierAccountsResponse);

            // Saperate reference value in different property which have in below format
            // "PieceShipRef:RefNum|PieceShipRef1:TransId|PieceShipRef2:TransId|PieceShipRef3:TransId|PieceShipRef4:TransId"
            if (response.smartParcelSettingsOptionsResponse) {
                tmpSharedSmallParcelSettings = response.smartParcelSettingsOptionsResponse;

                if (tmpSharedSmallParcelSettings.SmallParcelReferences != null && tmpSharedSmallParcelSettings.SmallParcelReferences != "") {
                    let arr = tmpSharedSmallParcelSettings.SmallParcelReferences.split('|');
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i] != "") {
                            const splitKeyWithColon = arr[i].split(':');
                            tmpSharedSmallParcelSettings[splitKeyWithColon[0]] = splitKeyWithColon[1];
                        }
                    }
                }

                if (tmpSharedSmallParcelSettings.Carrier.length) {
                    for (let index = 0; index < tmpSharedSmallParcelSettings.Carrier.length; index++) {
                        const element = tmpSharedSmallParcelSettings.Carrier[index];
                        if (element.Carrier == "FedEx") {
                            tmpSharedSmallParcelSettings.carrierFedEx = element.ShipmentServices;
                        } else if (element.Carrier == "UPS") {
                            tmpSharedSmallParcelSettings.carrierUPS = element.ShipmentServices;
                        }
                    }
                }

                if (tmpSharedSmallParcelSettings.SmallParcelRateShoppingLogic) {
                    tmpSharedSmallParcelSettings.SmallParcelRateShoppingLogic = JSON.parse(tmpSharedSmallParcelSettings.SmallParcelRateShoppingLogic);
                }
            }

            if (response.shipEngineCarrierResponse.Carriers) {
                let tmpShipEngineCarrierResponse = response.shipEngineCarrierResponse.Carriers;
                if (!this.isEnableProvider) {
                    tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
                        return obj.Carrier != "USPS" && obj.Carrier != "PitneyBowesStd";
                    });
                }
                tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
                    return obj.Carrier != "Buku" && obj.Carrier != "PitneyBowesExp"
                });
                if (this.isEnableProvider) {
                    tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
                        return obj.Carrier != "Endicia" && obj.Carrier != "Stamps.com";
                    });
                }
                response.shipEngineCarrierResponse.Carriers = tmpShipEngineCarrierResponse;
            }

            this.setState({
                sharedSmallParcelSettings: tmpSharedSmallParcelSettings,
                carrierAccountsGridData: tmpCarrierAccountsGridData,
                sharedShipEngineCarrier: response.shipEngineCarrierResponse.Carriers,
                isInitializeSettings: true
            });

        }).finally(() => {
            kendo.ui.progress($('#' + this.windowId), false);
        });
    }


    settingsDataSet = (param_setting_obj) => {
        this.setState({
            sharedSmallParcelSettings: param_setting_obj,
            isFormDataChanged: true
        });
        this.$rootScope.dirtyWindow[this.windowId] = true;
    }

    saveSmallParcelSettings = (isExitAfterSave) => (e) => {
        this.setState({ "settingsSaveLoading": true });


        let settingsData = Object.assign({}, this.state.sharedSmallParcelSettings);

        settingsData.SmallParcelRateShoppingLogic = JSON.stringify(settingsData.SmallParcelRateShoppingLogic);
        if (!this.isEnableProvider) {
            if (settingsData.SmallParcelUsps3rdParty == null || settingsData.SmallParcelUsps3rdParty == "") {
                if (settingsData.SmallParcelRateShoppingCarrierIds != null) {
                    let tempArr = settingsData.SmallParcelRateShoppingCarrierIds.split(',');
                    let position = tempArr.indexOf('ENDCA');
                    (position != -1) ? tempArr.splice(position, 1) : '';
                    settingsData.SmallParcelRateShoppingCarrierIds = tempArr.join(',');
                }
            }
        }

        settingsData.SmallParcelReferences = '';

        // Save Small Parcel Rate Shopping Carrier Accounts
        if(settingsData.SmallParcelRateShoppingCarrierIds && settingsData.SmallParcelRateShoppingCarrierIds !== '') {
            let carrierIds = settingsData.SmallParcelRateShoppingCarrierIds.split(',');
            let selectedCarrierAccounts = settingsData.SmallParcelRateShoppingCarrierAccounts ? settingsData.SmallParcelRateShoppingCarrierAccounts.split(',') : [];
            let tempSelectedCarrierAccounts = [];
            for (let i = 0; i < carrierIds.length; i++) {
                let carrierName = carrierIds[i];
                if (carrierName === "DHLGM") {
                    carrierName = "DHL eCommerce";
                } else if (carrierName === "FDXE") {
                    carrierName = "FedEx";
                } else if (carrierName === "ENDCA") {
                    carrierName = "USPS";
                } else if (carrierName === "PBCS") {
                    carrierName = "PitneyBowesStd";
                } else if (carrierName === "AMZN") {
                    carrierName = "Amazon Buy";
                }
                // Check Carrier Account Exist
                this.state.carrierAccountsGridData.filter((el) => {
                    if (carrierName === "USPS") {
                        if(this.USPSCarriers.includes(el.Carrier.toLowerCase())) {
                            if(selectedCarrierAccounts.includes(el.AccountNumber)) {
                                tempSelectedCarrierAccounts.push(el.AccountNumber);
                            }
                        }
                    } else {
                        if(el.Carrier.toLowerCase() === carrierName.toLowerCase()) {
                            if(selectedCarrierAccounts.includes(el.AccountNumber)) {
                                tempSelectedCarrierAccounts.push(el.AccountNumber);
                            }
                        }
                    }
                });
            }
            settingsData.SmallParcelRateShoppingCarrierAccounts = tempSelectedCarrierAccounts.join(",");
        } else {
            settingsData.SmallParcelRateShoppingCarrierAccounts = '';
        }

        // set SmallParcelReferences in below format and remove temp reference property
        // "PieceShipRef:RefNum|PieceShipRef1:TransId|PieceShipRef2:TransId|PieceShipRef3:TransId|PieceShipRef4:TransId"

        var settingsArray = [];

        if (settingsData.PieceShipRef && settingsData.PieceShipRef != '') {
            settingsArray.push('PieceShipRef:' + settingsData.PieceShipRef);
        }

        if (settingsData.PieceShipRef1 && settingsData.PieceShipRef1 != '') {
            settingsArray.push('PieceShipRef1:' + settingsData.PieceShipRef1);
        }

        if (settingsData.PieceShipRef2 && settingsData.PieceShipRef2 != '')
            settingsArray.push('PieceShipRef2:' + settingsData.PieceShipRef2);

        if (settingsData.PieceShipRef3 && settingsData.PieceShipRef3 != '')
            settingsArray.push('PieceShipRef3:' + settingsData.PieceShipRef3);

        if (settingsData.PieceShipRef4 && settingsData.PieceShipRef4 != '')
            settingsArray.push('PieceShipRef4:' + settingsData.PieceShipRef4);

        if (settingsArray.length > 0) {
            settingsData.SmallParcelReferences = settingsArray.join('|');
        }

        delete settingsData.PieceShipRef;
        delete settingsData.PieceShipRef1;
        delete settingsData.PieceShipRef2;
        delete settingsData.PieceShipRef3;
        delete settingsData.PieceShipRef4;

        delete settingsData.carrierFedEx;
        delete settingsData.carrierUPS;

        delete settingsData.Carrier;

        let param_settings_data = {
            'custUri': this.state.selectedCustomerObj.uri,
            'spsData': settingsData
        }

        smallParcelServices.saveSmartParcelSettings(param_settings_data).then((response) => {

            if (response && response.status == 200) {
                GlobalService.notification('success', this.translate('Window_Title_Label_SmartParcel_Data_Success'));
                this.$rootScope.dirtyWindow[this.windowId] = false;
                if(isExitAfterSave) {
                    this.onDiscardAndExit();
                }
                this.setState({isFormDataChanged: false});
                this.refreshGridData();
            }

        }).finally(() => {
            this.setState({ "settingsSaveLoading": false });
        });
    }

    refreshGridData = () => {
        this.getSmartParcelSettingsAndCarrier(this.state.selectedCustomerObj.uri);
    }

    render() {
        return (
            <div className="small-parcel-settings-wrapper">

                <WmsButton
                    label={this.translate("Label_Save")}
                    wmsSelector={this.selector + "Save"}
                    onClickFunc={this.saveSmallParcelSettings(false)}
                    icon="fa-floppy-o"
                    showToolTip={this.state.selectedCustomerIndex == '' || this.state.isFormDataChanged == false}
                    tooltipText={
                        (this.state.selectedCustomerIndex == '') ?
                            this.translate("Label_Smart_Customer_Require_Tooltip") : this.translate("Tooltip_Save_Disabled")
                    }
                    loading={this.state.settingsSaveLoading}
                    disabled={this.state.selectedCustomerIndex == '' || this.state.isFormDataChanged == false}
                    parentClassName="small-parcel-settings-save-btn" />


                <WmsCollapse
                    id={'smallParcelSettingsCustomerSelection'}
                    headerLabel={this.translate('Label_Customer_Text')}
                    showCollapseText={false}
                    showCollapseIcon={true}>
                    <WmsDropdown
                        id="Customer"
                        name="smartParcelCustomer"
                        label={this.customLabels.Label_Customer}
                        wmsSelector={this.selector + "Customer"}
                        value={this.state.selectedCustomerIndex}
                        onChangeFunc={this.handleCustomerChange}
                        options={this.optionsCustomer}
                        valueField='uri'
                        textField='name'
                        blankFirstOption={true}
                        applyIndex={true}
                        blankFirstOptionText="Unspecified"
                        extraWrapperClass="customer-selection-wrapper" />
                </WmsCollapse>
                {this.state.isInitializeSettings && (
                    <div>

                        <SmallParcelCarrierAccountSettings
                            key={this.state.sharedSmallParcelSettings.SmallParcelRateShoppingCarrierAccounts}
                            selector={this.selector}
                            windowId={this.windowId}
                            sharedCarrierAccountsGridData={this.state.carrierAccountsGridData}
                            sharedFacilityOption={this.sharedFacilityOption}
                            sharedSmallParcelSettings={this.state.sharedSmallParcelSettings}
                            sharedSelectedCustomer={this.state.selectedCustomerObj}
                            onRefreshGridData={this.refreshGridData}
                            sharedShipEngineCarrier={this.state.sharedShipEngineCarrier} />

                        <SmallParcelSmartSettings
                            selector={this.selector}
                            windowId={this.windowId}
                            sharedSmallParcelSettings={this.state.sharedSmallParcelSettings}
                            sharedCarrierAccountsGridData={this.state.carrierAccountsGridData}
                            sharedShipEngineCarrier={this.state.sharedShipEngineCarrier}
                            sharedSelectedCustomer={this.state.selectedCustomerObj}
                            onRefreshGridData={this.refreshGridData}
                            onSettingsDataSet={this.settingsDataSet} />

                    </div>
                )}
                <div>
                    <WmsConfirmModel
                        id={"ConfirmModel" + this.windowId}
                        isOpen={this.state.isOpenConfirmModel}
                        type={this.translate('Label_Confirmation')}
                        width={550}
                        height={175}
                        firstBtnLabel={this.translate('Label_Save_and_Exit')}
                        firstBtnIcon={"fa-check-square"}
                        firstBtnOnClickFunc={this.saveSmallParcelSettings(true)}
                        sawCheckBox={false}
                        extraWrapperClass={'wms-confirm-text-center'}
                        conFormModelText={this.translate('Label_are_you_sure_close_order')}
                        onCloseFunc={this.onCloseFunc}
                        thirdBtnLabel={this.translate('Label_Cancel')}
                        thirdBtnIcon={"fa-times-circle"}
                        thirdButtonType={"orange"}
                        isSecondBtnSaw={true}
                        secondFunctionClick={this.onDiscardAndExit}
                        secondBtnLabel={this.translate('Label_Discard_Exit')}
                        secondBtnIcon={"fa-times-circle"}
                    />
                </div>
            </div>
        );
    }
}