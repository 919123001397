
import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';
//components
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsRadio from './../components/WmsRadio/WmsRadio.tsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsGrid from './../components/WmsGrid/WmsGrid.jsx';
import WmsCheckBox from "./../components/WmsCheckBox/WmsCheckBox.jsx";
//services
import * as GlobalService from './../global_services';
import * as userService from './users.service.jsx';

export class UserSearchComponent extends React.Component {

    constructor(props) {

        super(props);
        
        this.$rootScope = window;
        this.translate = window.translate;

        this.state = {
            searchOn: false,
            Selector: 'UserSearch',
            optionConatins: [],
            filter: {
                IndexOptionsUserName: 0,
                IndexOptionsWarehouse: 0,
                IndexOptionsWarehouseAccess: 0,
                IndexOptionscustomerAccess: 0,
                IndexOptionsrolename: 0,
                IndexOptionsStatus: "1",
                IndexOptionsEmail: 0,
                IndexOptionsLoginName: 0,
                UserName: '',
                warehouseAccess: '',
                CustomerName: '',
                EMail: '',
                LoginName: '',
                rolename: '',
                Warehouse: ""
            },

        }
        this.gridSearch = GlobalService.debounce(this.gridSearch, 700);
    }

    componentDidMount() {
        kendo.ui.progress($('#' + this.props.windowId), true);
        this.setState({
            optionConatins: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchFilters'))
        });

        userService.searchInfoGet().then((response) => {
            console.log("searchInfoGet : ", JSON.stringify(response.data.Data));
            if (response.data.Data != null) {
                this.setState({
                    searchOn: true,
                    filter: response.data.Data,
                });
            }

        }).finally(() => {
            kendo.ui.progress($('#' + this.props.windowId), false);
        });
    }


    componentWillReceiveProps(nextProps) {

    }


    filterHandleChange = (event) => {
        this.setState({
            filter: Object.assign(
                {},
                this.state.filter,
                { [event.target.name]: event.target.value }
            ),
        }, () => {
            this.gridSearch();
        });
    }

    gridSearch = () => {

            let dataArr = [{
                ShowUserName: true,
                IndexOptionsUserName: this.state.filter.IndexOptionsUserName,
                UserName: this.state.filter.UserName,
                ShowUserStatus: true,
                IndexOptionsStatus: this.state.filter.IndexOptionsStatus,
                ShowUserCustomer: true,
                IndexOptionsUserCustomer: this.state.filter.IndexOptionscustomerAccess,
                CustomerName: this.state.filter.CustomerName,
                IndexOptionsEmail: this.state.filter.IndexOptionsEmail,
                EMail: this.state.filter.EMail,
                IndexOptionsLoginName: this.state.filter.IndexOptionsLoginName,
                LoginName: this.state.filter.LoginName
            }];
            this.setState({
                searchOn: true,
                filterObject: dataArr
            }, this.changeFilterData);
    }

    changeFilterData = () => {
        this.props.changeFilterData(this.state.filterObject);
    }
    clearFilters = () => {
        this.setState({
            filter: {
                "IndexOptionsUserName": 0,
                "IndexOptionsWarehouse": 0,
                "IndexOptionsWarehouseAccess": 0,
                "IndexOptionscustomerAccess": 0,
                "IndexOptionsrolename": 0,
                "IndexOptionsStatus": "1",
                "IndexOptionsEmail": 0,
                "IndexOptionsLoginName": 0,
                "UserName": '',
                "warehouseAccess": '',
                "CustomerName": '',
                "EMail": '',
                "LoginName": '',
                "rolename": '',
                "Warehouse": ""
            },
            searchOn: false,
            filterObject: [{ method: 'DELETE' }]
        }, this.changeFilterData);
    }

    render() {
        return (
            <div className="users-filter-wrapper new-layout-filter-wrapper">
                <WmsButton
                    label={this.translate('RoleManager_Label_Reset')}
                    parentClassName="user-reset-btn"
                    wmsSelector={this.state.Selector + 'ClearSearchButton'}
                    onClickFunc={this.clearFilters}
                    icon='fa-repeat'
                    buttonType='orange' />

                <WmsCollapse
                    id={'collapseUsersFilter'}
                    headerLabel={this.translate("Label_Filter_Options")}
                    showCollapseText={false}
                    showCollapseIcon={false} >
                    <div className="wms-radio-wrapper">
                        <span className="wms-label">
                            {this.translate("Label_Filter_Status")}
                        </span>
                        <div className="wms-radio">
                            <WmsRadio
                                label={this.translate("Label_All")}
                                classes="small-radio-new"
                                name={"IndexOptionsStatus"}
                                id="IndexOptionsStatus"
                                value={"0"}
                                wmsSelector={this.state.Selector + "UserStatusAll"}
                                onChangeFunc={() => this.filterHandleChange({target:{name:'IndexOptionsStatus',value:"0"}})}
                                checked={this.state.filter.IndexOptionsStatus == "0"}
                            />
                            <WmsRadio
                                label={this.translate("Label_Active")}
                                classes="small-radio-new"
                                name={"IndexOptionsStatus"}
                                id="IndexOptionsStatus1"
                                value={"1"}
                                wmsSelector={this.state.Selector + "UserStatusActive"}
                                onChangeFunc={() => this.filterHandleChange({target:{name:'IndexOptionsStatus',value:"1"}})}
                                checked={this.state.filter.IndexOptionsStatus == "1"}
                            />
                            <WmsRadio
                                label={this.translate("Label_Inactive")}
                                classes="small-radio-new"
                                name={"IndexOptionsStatus"}
                                id="IndexOptionsStatus2"
                                value={"2"}
                                wmsSelector={this.state.Selector + "UserStatusDeactive"}
                                onChangeFunc={() => this.filterHandleChange({target:{name:'IndexOptionsStatus',value:"2"}})}
                                checked={this.state.filter.IndexOptionsStatus == "2"}
                            />
                        </div>
                    </div>
                    <div className="wms-input-group-wrapper">
                        <WmsDropdown
                            id="IndexOptionsUserName"
                            name="IndexOptionsUserName"
                            label={this.translate('Label_Name')}
                            wmsSelector={this.state.Selector + 'NameDropdown'}
                            value={this.state.filter.IndexOptionsUserName}
                            onChangeFunc={this.filterHandleChange}
                            options={this.state.optionConatins}
                            valueField='value'
                            textField='description'
                            parentClassName="select_box" />
                        <WmsInput
                            inputName="UserName"
                            wmsSelector={this.state.Selector + 'FilterInputUsername'}
                            value={this.state.filter.UserName}
                            onChangeFunc={this.filterHandleChange}
                            label={this.translate('Label_Name')}
                            parentClassName="input_box" />
                    </div>
                    <div className="wms-input-group-wrapper">
                        <WmsDropdown
                            id="IndexOptionsLoginName"
                            name="IndexOptionsLoginName"
                            label={this.translate('Label_Login')}
                            wmsSelector={this.state.Selector + 'userLoginDropdown'}
                            value={this.state.filter.IndexOptionsLoginName}
                            onChangeFunc={this.filterHandleChange}
                            options={this.state.optionConatins}
                            valueField='value'
                            textField='description'
                            parentClassName="select_box" />
                        <WmsInput
                            inputName="LoginName"
                            wmsSelector={this.state.Selector + 'FilterInputLoginName'}
                            value={this.state.filter.LoginName}
                            onChangeFunc={this.filterHandleChange}
                            label={this.translate('Label_Login')}
                            parentClassName="input_box" />
                    </div>
                    <div className="wms-input-group-wrapper">
                        <WmsDropdown
                            id="IndexOptionsEmail"
                            name="IndexOptionsEmail"
                            label={this.translate('Label_Email')}
                            wmsSelector={this.state.Selector + 'userEmailDropdown'}
                            value={this.state.filter.IndexOptionsEmail}
                            onChangeFunc={this.filterHandleChange}
                            options={this.state.optionConatins}
                            valueField='value'
                            textField='description'
                            parentClassName="select_box" />
                        <WmsInput
                            inputName="EMail"
                            wmsSelector={this.state.Selector + 'FilterInputEmail'}
                            value={this.state.filter.EMail}
                            onChangeFunc={this.filterHandleChange}
                            label={this.translate('Label_Email')}
                            parentClassName="input_box" />
                    </div>
                </WmsCollapse>
            </div>
        )
    }
}