import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { UserBetaAssignRoleModel } from './users-beta-assign-role-model.component.jsx';


import WmsGrid from '../components/WmsGrid/WmsGrid.jsx';

import * as GlobalService from '../global_services';
import * as userBetaService from './users.service.jsx';

import * as GridConfig from './grid_configuration.jsx';

export class UserBetaGrid extends React.Component {

    constructor(props) {

        super(props);

        this.$rootScope = window;
        this.translate = window.translate;
        let usersGridHeader = StringWms.convertSringToOption(this.translate('Users_ManageUsers_GridHeaders'));

        this.state = {

            gridDomSelector: () => $('.' + this.props.windowId + ' #GridFindUsers'),
            columns: GridConfig.getGridDemoColumns(usersGridHeader).columns,
            schema: GridConfig.getGridDemoSchema().schema,
            transport: GridConfig.getGridDemoTransport().transport,
            localKendoGrid: null,
            userUri: null,
            Selector: 'Users',
            enableEditButton: false,
            enableActiveButton: false,
            enableDeactiveButton: false,
            enableAssignRoleButton: false,
            sharedIsOpenAssignRoleModel: false,
            isUpdateGridSummary: false,
            sharedSelectUserType:'',
            sharedSelectedUserIds: [],
            selectedUsers:0
        }
    }
    componentWillReceiveProps(nextprops) {

        if (nextprops.reloadGrid == 'reloadLocationGrid') {
            this.resetSelectionAndButton();
        }
    }

    //when select any row enable / disable button or context menu
    selectRow = (param_kendogrid) => {

        let currentSelections = param_kendogrid.getNumberOfSelections();
        let objCanDeactivate = param_kendogrid.sumKeywordValues("Deactivated");
        let enableEditButton = false;
        let enableActiveButton = false;
        let enableDeactiveButton = false;
        let enableAssignRoleButton = false;
        let userUri = null;

        if (currentSelections === 1) {
            enableEditButton = true;
            userUri = param_kendogrid.getItem(param_kendogrid.getKeys()).Uri;
        }
        if (currentSelections >= 1) {
            enableAssignRoleButton = true;
        }

        if (objCanDeactivate.hasOwnProperty("true")) {
            enableActiveButton = true;
        }

        if (objCanDeactivate.hasOwnProperty("false")) {
            enableDeactiveButton = true;
        }
        this.setState({
            enableEditButton: enableEditButton,
            localKendoGrid: param_kendogrid,
            enableActiveButton: enableActiveButton,
            enableDeactiveButton: enableDeactiveButton,
            enableAssignRoleButton: enableAssignRoleButton,
            userUri: userUri,
            selectedUsers:currentSelections
        });
    }

    clickEventOfContextMenu = (obj) => {

        if (obj.wmsid == 'edit') {
            this.onEditUser();
        } else if (obj.wmsid == 'activate') {
            this.activateDeactivateUser(true);
        } else if (obj.wmsid == 'deactivate') {
            this.activateDeactivateUser(false);
        } else if (obj.wmsid == 'assignRole') {
            this.openAssignRoleModel();
        }
    }
    activateDeactivateUser = (mybool) => {
        kendo.ui.progress(this.state.gridDomSelector(), true);
        let gridSelectedKeys = this.state.localKendoGrid.getKeys();
        let currentLogedUserid = GlobalService.GetCurrentUser().UserId.toString();


        if ((gridSelectedKeys.indexOf(currentLogedUserid)) != -1 && mybool == false) {
            GlobalService.notification('error', this.translate('Label_Cannot_Deactivate'));
        } else {
            userBetaService.updateUsersActivation(gridSelectedKeys, mybool).then((response) => {
                kendo.ui.progress(this.state.gridDomSelector(), false);
                if (typeof response != "undefined" && response.status == 204) {
                    this.resetSelectionAndButton();
                    GlobalService.notification('success', this.translate('Label_Operation_Successful'));
                }

            }, function (response) {
                kendo.ui.progress(this.state.gridDomSelector(), false);
                console.log("Grid w sort ", response);
            }).finally(() => {
                kendo.ui.progress(this.state.gridDomSelector(), false);
            });
        }

    }

    openAssignRoleModel = () => {
        let list = this.state.localKendoGrid.getKeys();
        let isCustomerAccessUser = true;
        let sharedSelectUserType = 'Warehouse';


        for (let x = 0; x < list.length; x++) {
            let temp = this.state.localKendoGrid.getItem(list[x]);
            if (temp.CustomerName == "") {
                isCustomerAccessUser = false;
            }
            if (temp.CustomerName != "" && this.state.localKendoGrid.valueExists("CustomerName", "")) {
                isCustomerAccessUser = true;
                break;
            }
        }
        if (isCustomerAccessUser == true) {
            sharedSelectUserType = 'Customer'
        }

        this.setState({
            sharedIsOpenAssignRoleModel: true,
            sharedSelectUserType:sharedSelectUserType,
            sharedSelectedUserIds: this.state.localKendoGrid.getKeys()
        });
    }

    onAssignRoleClose = () => {
        this.setState({ sharedIsOpenAssignRoleModel: false });
    }

    resetSelectionAndButton = () => {

        $(this.state.gridDomSelector()).data('kendoGrid').dataSource.read();
        if (this.state.localKendoGrid != null) {
            this.state.localKendoGrid.clearSelections(() => {
                this.state.localKendoGrid.repaintSelections();
            });
            this.setState({
                enableEditButton: false,
                enableActiveButton: false,
                enableDeactiveButton: false,
                enableAssignRoleButton: false,
                isUpdateGridSummary: true
            });
        }
    }

    onEditUser = () => {
        console.log("onEditUser")
        this.props.onEditUser(this.state.userUri);
    }

    onDataBound = () =>{
        // logic for set endtime for calculate loaderTimer
        this.$rootScope.setLoaderTimerEndTime("Manage Users");
    }
    onUpdateGridSummary = () =>{
        this.setState({ isUpdateGridSummary: false });
    };

    renderMenu = () => {
        let menuList = [];
        const UseCognitoLogin = GlobalService.CurrentUser.UseCognitoLogin;
        const isHubLogin = GlobalService.GetCurrentUser()?.IsJwtLogin;
        if (!isHubLogin && !UseCognitoLogin) {
            menuList.push({
                name: "Create",
                value: [],
                disabled: false,
                icon: "fa-plus",
                color: "green",
                clickFunc: this.props.onCreateUser,
                isContextMenu: false,
                isButtonMenu: true,
                wmsSelector: this.state.Selector + "CreateButton",
                wmsid: "create",
            });
        }

        let ManageSubMenuList = [
            {
                name: "Edit",
                value: [],
                disabled: !this.state.enableEditButton,
                icon: "fa-pencil",
                color: "yellow",
                isContextMenu: true,
                isButtonMenu: true,
                wmsSelector: this.state.Selector + "EditButton",
                onClickFunc: this.onEditUser,
                wmsid: "edit",
            }
        ];
        if (!isHubLogin && !UseCognitoLogin) {
            ManageSubMenuList.push({
                name: this.translate("Label_Activate"),
                value: [],
                disabled: !this.state.enableActiveButton,
                disabledText: this.translate("note_user_activate"),
                icon: "fa-bandcamp",
                color: "green",
                isContextMenu: true,
                isButtonMenu: true,
                wmsid: "activate",
                wmsSelector: this.state.Selector + "ActivateButton",
                onClickFunc: (e) => this.activateDeactivateUser(true),
            });
            ManageSubMenuList.push({
                name: this.translate("Label_Deactivate"),
                value: [],
                disabled: !this.state.enableDeactiveButton,
                disabledText: this.translate("note_user_deactivae"),
                icon: "fa-wrench",
                color: "green",
                isContextMenu: true,
                isButtonMenu: true,
                wmsid: "deactivate",
                wmsSelector: this.state.Selector + "DeactivateButton",
                onClickFunc: (e) => this.activateDeactivateUser(false),
            });
        }
        ManageSubMenuList.push({
            name: this.translate("Label_User_Beta_Assign"),
            value: [],
            disabled: !this.state.enableAssignRoleButton,
            disabledText: this.translate("note_user_delete"),
            icon: "fa-wrench",
            color: "green",
            isContextMenu: true,
            isButtonMenu: true,
            wmsid: "assignRole",
            wmsSelector: this.state.Selector + "AssignRoleButton",
            onClickFunc: (e) => this.openAssignRoleModel(),
        });
        menuList.push(
            {
                name: "Manage",
                wmsSelector: this.state.Selector + "Manage",
                value: ManageSubMenuList,
                disabled: this.state.selectedUsers == 0,
                disabledText: "You may not add blahssss0",
                icon: "fa-wrench",
                color: "green",
                isContextMenu: true,
                isButtonMenu: true,
            },
            {
                name: "Copy",
                value: [
                    {
                        name: this.translate("Label_Copy_Field"),
                        value: [],
                        disabled: false,
                        disabledText: this.translate("Label_Copy_Field"),
                        icon: "fa-bandcamp",
                        color: "green",
                        isContextMenu: true,
                        isButtonMenu: false,
                        wmsid: "copyField",
                        wmsSelector: "copyToClicpboard",
                    },
                    {
                        name: this.translate("Label_Copy_Row"),
                        value: [],
                        disabled: false,
                        disabledText: this.translate("Label_Copy_Row"),
                        icon: "fa-wrench",
                        color: "green",
                        isContextMenu: true,
                        isButtonMenu: false,
                        wmsid: "copyRow",
                    },
                ],
                disabled: false,
                disabledText: "You may not add blahssss0",
                icon: "fa-wrench",
                color: "green",
                isContextMenu: true,
                isButtonMenu: false,
            }
        );

        return menuList;
    };

    render() {
        return (
            <div className="user-grid-holder">
                <WmsGrid
                    wmsSelector={'FindUsersGridFindUsers'}
                    gridKeySelection={'UserId'}
                    gridName={'GridFindUsers'}
                    virtual={true}
                    id={'GridFindUsers'}
                    columns={this.state.columns}
                    schema={this.state.schema}
                    transport={this.state.transport}
                    showErrorMessageSection={true}
                    onSelection={this.selectRow}
                    clickEventOfContextMenu={this.clickEventOfContextMenu}
                    windowId={this.props.windowId}
                    showGridSummary={true}
                    filterObject={this.props.filterDataObj}
                    onDataBound={this.onDataBound}
                    getGridSelectionUrl={'/WebUI/Users/Users/GetUsersGridSelections'}
                    searchUrl={'/WebUI/Users/UpdateSession/SearchInfo'}
                    resetButtonLabel={this.translate('Label_Costomer_Options')}
                    resetButtonIcon={'fa-sliders'}
                    emptyRecoredText={this.translate("Label_User_No_Results_Display")}
                    isUpdateGridSummary={this.state.isUpdateGridSummary}
                    onUpdateGridSummary={this.onUpdateGridSummary}
                    menu={this.renderMenu()}
                />
                {this.state.sharedIsOpenAssignRoleModel && (
                    <UserBetaAssignRoleModel
                        sharedSelectUserType={this.state.sharedSelectUserType}
                        sharedSelectedUserIds={this.state.sharedSelectedUserIds}
                        sharedIsOpenAssignRoleModel={this.state.sharedIsOpenAssignRoleModel}
                        onAssignRoleClose={this.onAssignRoleClose}
                        onResetSelectionAndButton={this.resetSelectionAndButton} />
                )}
            </div>
        )
    }
}