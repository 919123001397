
import KeyedCollectionWms from './KeyedCollectionWms';
import { DictionaryWms } from './DictionaryWms';
declare var $: any;
declare var kendo: any;
declare var _: any;
export class KendoGridWmsVirtual {
    _quantityCollection: object;
    _quantityArray: any;
    _selections: any;
    _lastSelection: any;
    _lastSelectedUpdated: boolean;
    _maximumDifference: number;
    _currentPageRows: any;
    _currentSelections: any;
    _clickNumber: number;
    _gridId: any;
    _windowId: any;
    _selectAllCheckBox: boolean;
    _this: any;
    _uniqueKey: any;
    _pageSize: number;
    _shiftSelectUrl: any;
    _isGroupGrid: boolean;
    constructor(gridId, window, key, shiftSelect = undefined, isGroupGrid = undefined) {
        this._quantityCollection = {};
        this._quantityArray = [];
        this._selections = [];
        this._lastSelection = {
            "calculatedRow": 1,
            "page": 1,
            "length": 100,
            "row": -1,
            "column": 1,
            "id": -1
        };
        this._lastSelectedUpdated = false;
        this._maximumDifference = 2500;
        this._currentPageRows = [];
        this._currentSelections = new KeyedCollectionWms();
        this._clickNumber = 0;
        this._gridId = gridId;
        this._windowId = window;
        this._selectAllCheckBox = false;
        this._this = this;
        this._uniqueKey = key;
        this._pageSize = 50;
        this._shiftSelectUrl = shiftSelect;
        this._isGroupGrid = isGroupGrid;
    }

    public getGridOptions = function (columnsObj, optionsObj, gridOptionsStr) {
        // For remove old object refence we do this things
        var columnsObjStr = JSON.stringify(columnsObj);
        var columnsObjTemp = JSON.parse(columnsObjStr);
        if (gridOptionsStr == null || gridOptionsStr == "" || gridOptionsStr == " " || !gridOptionsStr || _.isEqual(JSON.parse(gridOptionsStr), {})) {
            // if we did not get any options back from the service we need to create our object with default columns
            // create columns on our make options obj and set it to the value of our columns obj
            optionsObj.columns = columnsObjTemp.columns;
            return optionsObj;
        } else {

            // we should have savedoptions here because we checked above
            var savedOptions = JSON.parse(gridOptionsStr);
            var _tempArr = [];
            // we are going to be assigning a wmsid, to be a unique identifier
            // it is just a madeup term but don't want to use uid in case there is some logic in kendo for this



            if (!(savedOptions.hasOwnProperty("columns"))) {
                // options should have columns but if it doesnt just give the defaults
                optionsObj.columns = columnsObjTemp.columns;
                return optionsObj;
            } else if (savedOptions.columns.length > 0) {
                // ok we got something back correctly, however
                // lets loop through saved elements and if there is no wmsid on ANY column well, they are going to get the default columns
                // just to be safe
                for (var x = 0; x < savedOptions.columns.length; x++) {
                    if (!(savedOptions.columns[x].hasOwnProperty("wmsid"))) {
                        optionsObj.columns = columnsObjTemp.columns;
                        return optionsObj;
                    }
                }

                // ok if we are getting here we know that we have a valid response that has been parsed, has at least 1 element and all elements have a wmsid
                // var tempColumnArray = [];
                var currentColumnDict = this.createDictionaryFromArrayProperty(columnsObjTemp.columns, "wmsid");
                // we have to respect the order of savedOptions so we need to use that as a template and bring everything else in and attach it to that
                // These are different column options
                //    field: The field could change so we don't want to save the field
                //    title: Title can change so we don't want to change this
                //    width: Width is custom to user so we do want to save this
                //    hidden: This is custom to user so we do want to save this
                //    sortable: Hmmm, we want save the sorted column but whether or not something could sort could change
                //    filterable:  This can change so we don't want to save
                //    wmsid: This cannot change so we want to save it
                //    template : This can change so we do not want to save it

                for (var y = 0; y < savedOptions.columns.length; y++) {
                    if (currentColumnDict.hasOwnProperty(savedOptions.columns[y].wmsid)) {
                        // so we copy over all the parameters from the the currentcolumn
                        // now we need to change the ones that could have been from the saved element which is
                        // width and hidden, note position is being set by looping through the saved array
                        var _tempobj = currentColumnDict[savedOptions.columns[y].wmsid];
                        if (savedOptions.columns[y].hasOwnProperty("width")) {
                            _tempobj.width = savedOptions.columns[y].width;
                        }
                        if (savedOptions.columns[y].hasOwnProperty("hidden")) {
                            _tempobj.hidden = savedOptions.columns[y].hidden;
                        }
                        _tempArr.push(_tempobj);

                        // we are going to remove used entries from the currentcolumndict so at the end we can add back in anything that was left over
                        // This solves the problem where savedColumns might be less then codeColumns because we added a new column in code
                        // currentColumnDict.Remove(savedOptions.columns[y].wmsid);
                        delete currentColumnDict[savedOptions.columns[y].wmsid];
                    }
                }

                for (var key in currentColumnDict) {
                    // skip loop if the property is from prototype
                    if (!currentColumnDict.hasOwnProperty(key)) continue;

                    _tempArr.unshift(currentColumnDict[key]);
                }

            } else {
                optionsObj.columns = columnsObjTemp.columns;
                return optionsObj;
            }

            var combineOptions = optionsObj;
            combineOptions.columns = _tempArr;

            if (savedOptions.sorts && savedOptions.sorts.length) {
                combineOptions.dataSource.sort = savedOptions.sorts;
            }
            return combineOptions;
        }
    };

    public createDictionaryFromArrayProperty = function (arr, field) {
        var returnobj = {};
        for (var x = 0; x < arr.length; x++) {
            returnobj[arr[x][field]] = arr[x];
        }
        return returnobj;
    };

    public repaintSelections = function (override = undefined) {
        //console.log("my windowID" + this._windowId);
        var selections = this.getSelections();
        var gridId = (typeof this._windowId != 'undefined') ?
            "." + this._windowId + ' #' + this._gridId :
            '#' + this._gridId;
        var grid = $(gridId).data('kendoGrid');
        if (typeof grid == 'undefined') {
            return;
        }
        //console.log(grid);
        var gridData = grid.dataSource.view();
        var tempDict = new KeyedCollectionWms();
        //console.log(JSON.stringify(gridData));
        if(!this._isGroupGrid){
        for (var i = 0; i < gridData.length; i++) {
            grid.table.find("tr[data-uid='" + gridData[i].uid + "']").removeClass("k-state-selected");
            if(grid.lockedTable) {
                grid.lockedTable.find("tr[data-uid='" + gridData[i].uid + "']").removeClass("k-state-selected");
            }
            grid.table.find("tr[data-uid='" + gridData[i].uid + "']").removeClass("wms-selection-group");
            tempDict.Add(gridData[i][this._uniqueKey], gridData[i]);
        }
        }else {
            for (var i = 0; i < gridData.length; i++) {
                for (var j = 0; j < gridData[i].items.length; j++) {
                    grid.table.find("tr[data-uid='" + gridData[i].items[j].uid + "']").removeClass("k-state-selected");
                    if(grid.lockedTable) {
                        grid.lockedTable.find("tr[data-uid='" + gridData[i].items[j].uid + "']").removeClass("k-state-selected");
                    }
                    grid.table.find("tr[data-uid='" + gridData[i].items[j].uid + "']").removeClass("wms-selection-group");
                    tempDict.Add(gridData[i].items[j][this._uniqueKey], gridData[i].items[j]);
                }
            }
        }
        for (var i = 0; i < selections.length; i++) {
            var tempData = this._currentSelections.Item(selections[i]);
            var id = tempData[this._uniqueKey];
            //console.log(id);
            var exists = tempDict.ContainsKey(id);
            if (exists) {
                var value = tempDict.Item(id);
                var tempUid = value.uid;
                //console.log(id + "exists with UID of " + tempUid);
                grid.table.find("tr[data-uid='" + tempUid + "']").addClass("k-state-selected");
                if(grid.lockedTable) {
                    grid.lockedTable.find("tr[data-uid='" + tempUid + "']").addClass("k-state-selected");
                }
                if (selections.length > 1) {
                    grid.table.find("tr[data-uid='" + tempUid + "']").addClass("wms-selection-group");
                }
            } else { }
        }
        tempDict = null; // to garbage collect this temporary instance
    };

    public getSelections = function () {
        return this._currentSelections.Keys();
    };

    public clearSelections = function (callback) {
        this._selections = [];
        this._currentSelections.Clear();
        this._quantityCollection = {};
        this._quantityArray = [];
        if (callback) {
            callback();
        }
    };

    /**
     * give the number of occurences of all values of a keyword
     * @params key
     * @return obj
     */
    public sumKeywordValues = function (key) {
        var obj = {};
        var myarr = this._currentSelections.Values();
        for (var x = 0; x < myarr.length; x++) {
            if (obj.hasOwnProperty(myarr[x][key])) {
                obj[myarr[x][key]]++;
            } else {
                obj[myarr[x][key]] = 1;
            }
        }
        return obj;
    };

    public clickEventHandleSelection = function (e, page, length, row, col, dataItem, dataPage, uniqueKey, isVirtualGrid, callback) {
            if(!dataItem) {
                return false;
            }
            this._uniqueKey = uniqueKey;
            this._clickNumber++;
            let calculatedRow = (((page - 1) * length) + row); // this gives us the index of the total we clicked
            this.setCurrentPageRows(dataPage);
            let differenceBetweenSelections; // get the absolute value of the difference between selections
            if (this.getLastSelectionUpdated() == true) {
                differenceBetweenSelections = Math.abs(this.getLastSelection().calculatedRow - calculatedRow);
            } else {
                differenceBetweenSelections = -1;
            }
            if (e.shiftKey && (e.ctrlKey || e.metaKey) && (this._currentSelections.Count() == 0 || this._currentSelections.Count() == 1)) {
                this.repaintSelections();
            } else if (e.shiftKey && (e.ctrlKey || e.metaKey)) {
                var isClickInSelection = this._currentSelections.ContainsKey(dataItem[uniqueKey]);
                if (isClickInSelection == true && this._currentSelections.Count() == 1) {

                } else {
                    this.showLoadingAnimation();
                    if (differenceBetweenSelections > this._maximumDifference) {
                        // if the amount of selections saved in local memory plus the amount of new requested local rows is too much we stop
                        this.hideLoadingAnimation();
                    } else {
                        var myPageData = this.getCurrentPageRows();
                        if (this._clickNumber <= 1) {
                            this._currentSelections.Add(myPageData[0][uniqueKey], myPageData[0]);
                        }
                        var lastpage = this.getLastSelection().page;
                        var newpage = page;
                        var pages = [];
                        var ids = [];
                        pages.push(lastpage);
                        pages.push(newpage);
                        var startId;
                        var endId;
                        if (((this.getLastSelection().page * this._pageSize) + this.getLastSelection().row) < ((page * this._pageSize) + row)) {
                            startId = this.getLastSelection().id;
                            endId = dataItem[uniqueKey];
                        } else {
                            startId = dataItem[uniqueKey];
                            endId = this.getLastSelection().id;
                        }
                        ids.push(dataItem[uniqueKey]);
                        pages.sort(function (a, b) {
                            return a - b;
                        });
                        ids.sort(function (a, b) {
                            return a - b;
                        });
                        var thisGrid = $('#' + this._gridId).data('kendoGrid');
                        var filterObject = thisGrid.dataSource.filter();
                        var sortObject = thisGrid.dataSource.sort();
                        var transformedFilter = "[]";
                        var transformedSort = "[]";
                        if (filterObject != undefined) {
                            transformedFilter = JSON.stringify(this.transformFilterForService(filterObject));
                        }
                        if (sortObject != undefined) {
                            transformedSort = JSON.stringify(this.transformSortForService(sortObject));
                        }
                        var postData: any;
                        postData = {
                            "sorts": "",
                            "filters": "",
                            "pageSize": 0,
                            "startId": 0,
                            "endId": 0

                        };
                        postData.sorts = transformedSort;
                        postData.filters = transformedFilter;
                        postData.pageSize = this._pageSize;
                        postData.startId = startId;
                        postData.endId = endId;
                        var that = this;
                        $.ajax({
                            type: "GET",
                            url: that._shiftSelectUrl,
                            data: postData,
                            success: function (response) {
                                if (response.Code == 0) {
                                    that.addMassToSelection(response.Result);
                                    that.hideLoadingAnimation();
                                    callback();
                                } else {
                                    // console.log("ErrorCode: " + response.Code);
                                    // console.log(JSON.stringify(postData));
                                    callback();
                                    that.hideLoadingAnimation();
                                    alert("There was an error selecting these orders, please try again");
                                }
                            },
                            dataType: "JSON"
                        });
                    }
                    this.logSelections();
                }
            } else if (e.shiftKey) {
                // console.log("SHIFT CLICKED");
                // console.log("Selections: " + this.getSelections().length);
                var isClickInSelection = this._currentSelections.ContainsKey(dataItem[uniqueKey]);
                if (isClickInSelection == true && this._currentSelections.Count() == 1) { } else {
                    this.showLoadingAnimation();
                    // if (differenceBetweenSelections > this._maximumDifference) {
                    //     // if the amount of selections saved in local memory plus the amount of new requested local rows is too much we stop
                    //     alert("too many selections try again");
                    //     this.hideLoadingAnimation();
                    // } else {
                        var myPageData = this.getCurrentPageRows();
                        if (this._clickNumber <= 1) {
                            this._currentSelections.Add(myPageData[0][uniqueKey], myPageData[0]);
                            // console.log("click Number is less than or equal to 1");
                        }
                        //kendo.ui.progress($("#findOrdersGrid"), true);
                        var lastpage = this.getLastSelection().page;
                        var newpage = page;
                        var pages = [];
                        var ids = [];
                        pages.push(lastpage);
                        pages.push(newpage);
                        var startId;
                        var endId;
                        if (((this.getLastSelection().page * this._pageSize) + this.getLastSelection().row) < ((page * this._pageSize) + row)) {
                            startId = this.getLastSelection().id;
                            endId = dataItem[uniqueKey];
                        } else {
                            startId = dataItem[uniqueKey];
                            endId = this.getLastSelection().id;
                        }
                        ids.push(dataItem[uniqueKey]);
                        pages.sort(function (a, b) {
                            return a - b;
                        });
                        ids.sort(function (a, b) {
                            return a - b;
                        });
                        var thisGrid = $('#' + this._gridId).data('kendoGrid');
                        var filterObject = thisGrid.dataSource.filter();
                        var sortObject = thisGrid.dataSource.sort();
                        var transformedFilter = "[]";
                        var transformedSort = "[]";
                        if (filterObject != undefined) {
                            transformedFilter = JSON.stringify(this.transformFilterForService(filterObject));
                        }
                        if (sortObject != undefined) {
                            transformedSort = JSON.stringify(this.transformSortForService(sortObject));
                        }
                        var postData: any;
                        postData = {
                            "sorts": "",
                            "filters": "",
                            "pageSize": 0,
                            "startId": 0,
                            "endId": 0
                        };
                        postData.sorts = transformedSort;
                        postData.filters = transformedFilter;
                        postData.pageSize = this._pageSize;
                        postData.startId = startId;
                        postData.endId = endId;
                        var that = this;

                        if (typeof that._shiftSelectUrl !== 'undefined' && that._shiftSelectUrl !== '') {

                            if (that._shiftSelectUrl == "/WebUI/Inventory/EditAudits/AuditLocationsGridSelections") {
                                let indexOptionsWarehouse: any = document.querySelector('#indexOptionsWarehouse');
                                postData.facilityId = indexOptionsWarehouse.value;
                            }
                            var readData = thisGrid.dataSource.transport.options.read.data;
                            if (readData && readData.customParamsInShiftSelection) {
                                for (var key in readData) {
                                    if (key != "customParamsInShiftSelection") {
                                        postData[key] = readData[key];
                                    }
                                }
                            }

                            if (that._shiftSelectUrl == "/WebUI/Items/Items/GetItemsGridSelections") {
                                let indexOptionsWarehouse: any = document.querySelector('#itemHolderIndexOptionsCustomer');
                                postData.customerId = indexOptionsWarehouse.value;
                            }
                            var reqType = "POST";

                            if (that._shiftSelectUrl.indexOf(".json") >= 0) {
                                reqType = "GET";
                            } else if (that._shiftSelectUrl.indexOf("/WebUI/Facilities/ManageLocations/GridSelections") >= 0) {
                                reqType = "GET";
                            } else if (that._shiftSelectUrl == "/WebUI/Customers/ManageCustomers/GridSelections") {
                                reqType = "GET";
                            } else if (that._shiftSelectUrl == "/WebUI/Inventory/EditAudits/AuditLocationsGridSelections") {
                                reqType = "GET";
                            } else if (that._shiftSelectUrl == "/WebUI/inventory/ManageInventory/GridSelections") {
                                reqType = "GET";
                            }

                            $.ajax({
                                type: reqType,
                                url: that._shiftSelectUrl,
                                data: postData,
                                success: function (response) {
                                    if (response.hasOwnProperty("Code")) {
                                        if (response.Code == 0) {
                                            that.clearSelections();
                                            that.addMassToSelection(response.Result);
                                            that.hideLoadingAnimation();
                                            callback();
                                        } else {
                                            callback();
                                            that.hideLoadingAnimation();
                                        }
                                    } else if (typeof response.Code == 'undefined' && response.length != 0) {
                                        that.clearSelections();
                                        that.addMassToSelection(response);
                                        that.hideLoadingAnimation();
                                        callback();
                                    } else {
                                        that.clearSelections();
                                        that.addMassToSelection(response);
                                        that.hideLoadingAnimation();
                                    }

                                },
                                error: function (XMLHttpRequest, textStatus, errorThrown) {
                                    console.log("Status: ", textStatus);
                                    console.log("Error: ", errorThrown);
                                    that.hideLoadingAnimation();
                                },
                                dataType: "JSON"
                            });
                        } else if (isVirtualGrid) {
                            var gridId = (typeof this._windowId != 'undefined') ?
                            "." + this._windowId + ' #' + this._gridId :
                            '#' + this._gridId;
                            var dataSource = $(gridId).data("kendoGrid").dataSource;
                            var filteredDataSource = new kendo.data.DataSource({
                                data: dataSource.data(),
                                filter: dataSource.filter(),
                                sort: dataSource.sort()
                            });
                            filteredDataSource.read();
                            var data = filteredDataSource.view();

                            var selectedData = [];
                            var startSelecting = false
                            for (var i = 0; i < data.length; i++) {
                                if (data[i][uniqueKey] == startId) {
                                    startSelecting = true;
                                }
                                if (startSelecting) {
                                    selectedData.push(data[i]);
                                }
                                if (data[i][uniqueKey] == endId) {
                                    startSelecting = false;
                                    break;
                                }
                            }
                            that.clearSelections();
                            that.addMassToSelection(selectedData);
                            that.hideLoadingAnimation();
                            callback();
                        } else {
                            that.hideLoadingAnimation();
                            callback();
                        }
                    // }
                    this.logSelections();
                }
            } else if (e.ctrlKey || e.metaKey) {
                this.addSelections(dataItem[uniqueKey], dataItem, "remove");
                this.setLastSelection({
                    "calculatedRow": calculatedRow,
                    "page": page,
                    "length": length,
                    "row": row,
                    "column": col,
                    "id": dataItem[uniqueKey]
                }); // set our last clicked element
                this.logSelections();
                // console.log(dataItem);
                this.repaintSelections();
                callback();
            } else {
                // litte bit convoluted here as we need to clear all selections when normal is clicked however we need select normal as well, or we need to deselect normal is its selected and normal clicked
                if (typeof dataItem == 'undefined' || dataItem == null) {
                    return
                }
                if (this._currentSelections.ContainsKey(dataItem[uniqueKey])) {
                    if (this._currentSelections.Count() > 1) {
                        this.clearSelections();
                        this.addSelections(dataItem[uniqueKey], dataItem, "remove");
                        this.logSelections();
                        this.setLastSelection({
                            "calculatedRow": calculatedRow,
                            "page": page,
                            "length": length,
                            "row": row,
                            "column": col,
                            "id": dataItem[uniqueKey]
                        }); // set our last clicked element
                        // console.log(dataItem);
                        this.repaintSelections();
                    } else if (this._currentSelections.Count() <= 1) {
                        this.addSelections(dataItem[uniqueKey], dataItem, "remove");
                        this.logSelections();
                        this.setLastSelection({
                            "calculatedRow": calculatedRow,
                            "page": page,
                            "length": length,
                            "row": row,
                            "column": col,
                            "id": dataItem[uniqueKey]
                        }); // set our last clicked element
                        this.repaintSelections();
                    }
                } else {
                    this.clearSelections();
                    this.addSelections(dataItem[uniqueKey], dataItem, "remove");
                    this.logSelections();
                    this.setLastSelection({
                        "calculatedRow": calculatedRow,
                        "page": page,
                        "length": length,
                        "row": row,
                        "column": col,
                        "id": dataItem[uniqueKey]
                    }); // set our last clicked element
                    // console.log(dataItem);
                    this.repaintSelections();
                }
                callback();
            }
    };

    public setCurrentPageRows = function (array) {
        this._currentPageRows = array;
    };
    public getLastSelectionUpdated = function () {
        return this._lastSelectedUpdated;
    };
    public getLastSelection = function () {
        return this._lastSelection;
    };
    public showLoadingAnimation = function () {
        kendo.ui.progress($("#" + this._gridId), true);
    }
    public hideLoadingAnimation = function () {
        kendo.ui.progress($("#" + this._gridId), false);
    };
    public getCurrentPageRows = function () {
        return this._currentPageRows;
    };
    public transformFilterForService = function (filter) {
        var myreturn = [];
        for (var x = 0; x < filter.filters.length; x++) {
            var tempObj = {
                "member": filter.filters[x].field,
                "operator": this.transformKendoFilterNames(filter.filters[x].operator),
                "value": filter.filters[x].value
            };
            myreturn.push(tempObj);
        }
        return myreturn;
        //IsLessThan,
        //IsLessThanOrEqualTo, lte
        //IsEqualTo, eq
        //IsNotEqualTo, neq
        //IsGreaterThanOrEqualTo, gte
        //IsGreaterThan, gt
        //StartsWith, startswith
        //EndsWith, endswith
        //Contains, contains
        //IsContainedIn,
        //DoesNotContain, doesnotcontain
    };

    public transformKendoFilterNames = function (name) {

        var myreturn = "";
        if (name == "lte") {
            myreturn = "IsLessThanOrEqualTo";
        } else if (name == "eq") {
            myreturn = "IsEqualTo";
        } else if (name == "lt") {
            myreturn = "IsLessThan";
        } else if (name == "neq") {
            myreturn = "IsNotEqualTo";
        } else if (name == "gte") {
            myreturn = "IsGreaterThanOrEqualTo";
        } else if (name == "gt") {
            myreturn = "IsGreaterThan";
        } else if (name == "startswith") {
            myreturn = "StartsWith";
        } else if (name == "endswith") {
            myreturn = "EndsWith";
        } else if (name == "contains") {
            myreturn = "Contains";
        } else if (name == "doesnotcontain") {
            myreturn = "DoesNotContain";
        } else {
            myreturn = name;
        }
        return myreturn;
    }

    public transformSortForService = function (sort) {
        if (sort.length == 0) {
            return ([]);
        } else {
            var myreturn = {
                "member": "",
                "sortdirection": ""
            };
            myreturn.member = sort[0].field;
            var sortdirection;
            if (sort[0].dir == 'asc') {
                sortdirection = "ascending";
            } else {
                sortdirection = "descending";
            }
            myreturn.sortdirection = sortdirection;
            return new Array(myreturn);
        }
    };
    public logSelections = function () {
        // console.log("Number of Selections: " + this._currentSelections.Count());
    };
    public addSelections = function (key, selection, param) {
        this._currentSelections.Add(key, selection, param);
    };
    public setLastSelection = function (selection) {
        this._lastSelection = selection;
        this._lastSelectedUpdated = true;
    };
    public addMassToSelection = function (data) {
        for (var x = 0; x < data.length; x++) {
            this.addSelections(data[x][this._uniqueKey], data[x]);
        }
        this.logSelections();
        this.repaintSelections();
    };

    public getNumberOfSelections = function () {
        return this._currentSelections.Count();
    };

    public setSelectAllCheckBox = function (mybool, clear, paint) {
        this._selectAllCheckBox = mybool;
        if (clear) {
            this.clearSelections();
        }
        if (paint) {
            this.repaintSelections();
        }
    };

    public onRightClick = function (e, page, length, row, col, dataItem, dataPage, uniqueKey, callback) {
        if(!dataItem) {
            return;
        }
        if (this._currentSelections.ContainsKey(dataItem[uniqueKey])) {
            callback();
        } else {
            var calculatedRow = (((page - 1) * length) + row);
            this.clearSelections();
            this._currentSelections.Add(dataItem[uniqueKey], dataItem);
            this.logSelections();
            this.setLastSelection({
                "calculatedRow": calculatedRow,
                "page": page,
                "length": length,
                "row": row,
                "column": col,
                "id": dataItem[uniqueKey]
            });
            this.repaintSelections();
            callback();
        }
    };

    /**
     * Simple Test Method to test correct configuration
     * @params Kendo Get Options Object
     * @return Object  of style {"optionsData": stringified(object)}
     */
    public prepareGridOptionsForStorage = function (passedObj, sortArr, filterArr) {
        var reducedObj;
        reducedObj = {
            "columns": [],
            "sorts": []
        };
        for (var x = 0; x < passedObj.length; x++) {
            if (passedObj[x].hasOwnProperty("field")) {
                reducedObj.columns[x] = {
                    "field": passedObj[x].field
                };
            }
            if (passedObj[x].hasOwnProperty("width")) {
                reducedObj.columns[x].width = passedObj[x].width;
            }
            if (passedObj[x].hasOwnProperty("wmsid")) {
                reducedObj.columns[x].wmsid = passedObj[x].wmsid;
            }
            if (passedObj[x].hasOwnProperty("hidden")) {
                reducedObj.columns[x].hidden = passedObj[x].hidden;
            }
        }

        reducedObj.sorts = sortArr;
        reducedObj.filter = filterArr;

        var finalreturn = {
            "optionsData": JSON.stringify(reducedObj)
        };
        return (finalreturn);
    };
    public getKeys = function () {
        return this._currentSelections.Keys();
    };
    public getItem = function (key) {
        return this._currentSelections.Item(key);
    };

    /*
    * See if a certain value exists in selected keys
    * @params key, value
    * @return boolean
    */
    public valueExists = function (key, value) {
        var myarr = this._currentSelections.Values();
        var returnboolean = false;
        for (var x = 0; x < myarr.length; x++) {
            if (myarr[x][key] == value) {
                returnboolean = true;
            }
        }
        return returnboolean;
    };

    public getAllRecordsFromApi = function (callback) {
        this.showLoadingAnimation();
        var thisGrid = $('#' + this._gridId).data('kendoGrid');
        var filterObject = thisGrid.dataSource.filter();
        var sortObject = thisGrid.dataSource.sort();
        var transformedFilter = "[]";
        var transformedSort = "[]";
        if (filterObject != undefined) {
            transformedFilter = JSON.stringify(this.transformFilterForService(filterObject));
        }
        if (sortObject != undefined) {
            transformedSort = JSON.stringify(this.transformSortForService(sortObject));
        }

        var postData: any = {
            "sorts": "",
            "filters": "",
            "pageSize": 0,
            "startId": 0,
            "endId": 0
        };
        postData.sorts = transformedSort;
        postData.filters = transformedFilter;
        postData.pageSize = 200;
        postData.startId = -1;
        postData.endId = -1;

        var that = this;

        var readData = thisGrid.dataSource.transport.options.read.data;
        if (readData && readData.customParamsInShiftSelection) {
            for (var key in readData) {
                if (key != "customParamsInShiftSelection") {
                    postData[key] = readData[key];
                }
            }
        }
        // Pass extra parameter customerId only for item shift selection
        if (that._shiftSelectUrl == "/WebUI/Items/Items/GetItemsGridSelections") {

            let indexOptionsWarehouse: any = document.querySelector('#itemHolderIndexOptionsCustomer');
            postData.customerId = indexOptionsWarehouse.value;


        }
        var reqType = "POST";
        if (that._shiftSelectUrl.indexOf(".json") >= 0) {
            reqType = "GET";
        }
        // Pass extra parameter facilityId and set type GET only for location
        if (that._shiftSelectUrl.indexOf("/WebUI/Facilities/ManageLocations/GridSelections") >= 0) {
            reqType = "GET";
        }
        if (that._shiftSelectUrl.indexOf("/WebUI/Customers/ManageCustomers/GridSelections") >= 0) {
            reqType = "GET";
        }
        $.ajax({
            type: reqType,
            url: that._shiftSelectUrl,
            data: postData,
            success: function (response) {
                if (response.Code == 0) {
                    that.clearSelections();
                    that.addMassToSelection(response.Result);
                    // set our last clicked element
                    that.hideLoadingAnimation();
                    callback();
                } else if (typeof response.Code == 'undefined' && response.length != 0) {
                    that.clearSelections();
                    that.addMassToSelection(response);
                    // set our last clicked element
                    that.hideLoadingAnimation();
                    callback();
                } else {
                    that.clearSelections();
                    callback();
                }
            },
            dataType: "JSON"
        });
    }

    public prepareGetOptionsForStorage = function (passedObj, sortObj) {
        var reducedObj;
        reducedObj = {
            "columns": [],
            "sorts": []
        };
        for (var x = 0; x < passedObj.length; x++) {
            if (passedObj[x].hasOwnProperty("field")) {
                reducedObj.columns[x] = {
                    "field": passedObj[x].field
                };
            }
            if (passedObj[x].hasOwnProperty("width")) {
                reducedObj.columns[x].width = passedObj[x].width;
            }
            if (passedObj[x].hasOwnProperty("wmsid")) {
                reducedObj.columns[x].wmsid = passedObj[x].wmsid;
            }
            if (passedObj[x].hasOwnProperty("hidden")) {
                reducedObj.columns[x].hidden = passedObj[x].hidden;
            }
        }
        if (sortObj) {
            reducedObj.sorts[0] = sortObj;
        }
        var finalreturn = {
            "optionsData": JSON.stringify(reducedObj)
        };
        return (finalreturn);
    }
    public getDataItemFromSingleRecordDictionary = function () {
        if (this.getNumberOfSelections() != 1) {
            throw Error("too many selections");
        } else {
            var keys = this.getKeys();
            return (this.getItem(keys));
        }
    };
    public updateValue = function (key, value) {
        this._currentSelections.Remove(key);
        this._currentSelections.Add(key, value);
    };

    public getDictionary = function () {
        return this._currentSelections;
    };

    public attachGridOptions = function (columnsObj, optionsObj, gridOptionsStr) {
        var retrievedOptions = null;
        if (gridOptionsStr == null || gridOptionsStr == "" || gridOptionsStr == " " || !gridOptionsStr || _.isEqual(JSON.parse(gridOptionsStr), {})) {
            // if we did not get any options back from the service we need to create our object with default columns
            // create columns on our make options obj and set it to the value of our columns obj
            optionsObj.columns = columnsObj.columns;
            return optionsObj;
        } else {
            //hmm
            // if we DID get something back for options we need to create our object, including the sort on the datasource
            // first thing we need to do is parse the string into an object
            retrievedOptions = JSON.parse(gridOptionsStr);
            var tempObj = {};
            // var myobj = new MyObject();
            var currentColumnDict = this.createDictionaryFromArrayProperty(columnsObj.columns, "field");
            var tempColumns = [];
            for (var x = 0; x < retrievedOptions.columns.length; x++) {
                if (currentColumnDict.hasOwnProperty(retrievedOptions.columns[x].field)) {
                    //Set Title which is defined in grid
                    retrievedOptions.columns[x].title = currentColumnDict[retrievedOptions.columns[x].field].title;
                    tempColumns.push(retrievedOptions.columns[x]);
                    if (retrievedOptions.columns[x].field == "Status") {
                        retrievedOptions.columns[x].template = currentColumnDict['Status'].template;
                        retrievedOptions.columns[x].sortable = currentColumnDict['Status'].sortable;
                        retrievedOptions.columns[x].filterable = currentColumnDict['Status'].filterable;
                    }
                }
            }
            // we need the order of retrievedOptions however we need to remove the any columns that aren't in columnsObj
            //for (var x = 0; x < columnsObj.columns.length; x++) {
            //	if (previousColumnDict.hasOwnProperty(columnsObj.columns[x].field)) {
            //		columnsObj.columns[x] = previousColumnDict[columnsObj.columns[x].field];
            //	}
            //}
            //var currentColumnKeys = columnsObj.columns.map((x) => x.field);
            // creating a dictionary to prevent having to loop through the array multiple times
            //var currentColumnDict = this.createDictionaryFromArrayProperty(columnsObj.columns, "field");
            // cases when we start with least column
            // we need everything that is in currentColumnDict but then we need to still get the options for each of these from the retrieved Options and still make sure extra columns from retrieved are removed, or rather not introduced
            //var modifiedSavedColumnOptions = retrievedOptions.columns.filter((x) => currentColumnDict.hasOwnProperty(x.field) );
            // our saved columns are in the correct order however there might be some elements in our default columns like templates that are not saved into the clientMetaData, so we need to reattach those elements from the default columns to the returned order of columns from save method
            //tempObj = myobj.alignIndexesByProperty(modifiedSavedColumnOptions, columnsObj.columns, "field");
            var combineOptions = optionsObj;
            combineOptions.columns = tempColumns;
            // attach new columns obj to our options
            //combineOptions.columns = modifiedSavedColumnOptions;
            // change the dataSource sort to the sort we get from the clientMetaDAta
            if (retrievedOptions.sorts.length) {
                combineOptions.dataSource.sort = retrievedOptions.sorts;
            }
            return combineOptions;
        }
    };

    /**
     * Only for local memory Grids to sum integers
     * @params value: string[]
     * @return number[]
     */
    public sumByLocalMemoryGridContents = function (value) {
        var grid = $('.' + this._windowId + ' #' + this._gridId).data('kendoGrid');
        var gridData = grid.dataSource.view();
        var resultArray = new Array(value.length);
        for (var z = 0; z < resultArray.length; z++) {
            resultArray[z] = 0;
        }

            for (var x = 0; x < gridData.length; x++) {
                var dataItem = gridData[x];
                for (var i = 0; i < value.length; i++) {
                    resultArray[i] += dataItem[value[i]];
                }
            }

        return resultArray;
    };

    /**
     * prepare my options to submit to backend
     * @params firstObj, secondObj, keysArray
     * @return [{}]
     */
    public prepareGridOptions = function (passedObj, origGrid, sortObj) {
        var reducedObj, origObj;
        reducedObj = {
            "columns": [],
            "sorts": []
        };
        // only add the field, width and hidden attributes of the columns, we must strip off extra kendo attributes that we dont want to save such as template
        for (var x = 0; x < passedObj.length; x++) {
            if (passedObj[x].hasOwnProperty("field")) {
                reducedObj.columns[x] = {
                    "field": passedObj[x].field
                };
            }
            if (passedObj[x].hasOwnProperty("width")) {
                reducedObj.columns[x].width = passedObj[x].width;
            }
            if (passedObj[x].hasOwnProperty("hidden")) {
                reducedObj.columns[x].hidden = passedObj[x].hidden;
            }
            // had to store template because some columns have templates on columns like volume/length/height and we need to remember it
            if (passedObj[x].hasOwnProperty("template")) {
                reducedObj.columns[x].template = passedObj[x].template;
            }
            if (passedObj[x].hasOwnProperty("title")) {
                reducedObj.columns[x].title = passedObj[x].title;
            }
        }
        // if there is a sort add a sort
        if (sortObj) {
            reducedObj.sorts[0] = sortObj;
        }
        if (origGrid) {
            origObj = JSON.parse(origGrid);
            // if the original stored element has more columns then the current display, pass the original elements over to be saved again for when that user needs them
            if (origObj.columns.length > reducedObj.columns.length) {
                // we want to keep the order of the original layout, we just need to attach to that one any of the elements or layout of the current one
                var currentDict = this.createDictionaryFromArrayProperty(reducedObj.columns, "field");
                var finalColumns = origObj.columns.map(function (x) {
                    if (currentDict.hasOwnProperty(x.field)) {
                        return currentDict[x.field];
                    } else {
                        return x;
                    }
                });
                var finalArea = {
                    "columns": finalColumns,
                    "sorts": []
                };
                if (sortObj) {
                    finalArea.sorts[0] = sortObj;
                }
                var finalreturn = {
                    "optionsData": JSON.stringify(finalArea)
                };
                return (finalreturn);
            } else if (origObj.columns.length < reducedObj.columns.length) {
                throw Error("the saved element had less columns than the current, this should not be possible to reach, try shift refreshing, else there is a bug");
            } else {
                var finalreturn = {
                    "optionsData": JSON.stringify(reducedObj)
                };
                return (finalreturn);
            }
        } else {
            var finalreturn = {
                "optionsData": JSON.stringify(reducedObj)
            };
            return (finalreturn);
        }
    };
    public addMassToDictionary = function (data) {
        for (var x = 0; x < data.length; x++) {
            this.addSelections(data[x][this._uniqueKey], data[x]);
        }
        this.logSelections();
    };
    public sumDictionaryParameter = function (parameter) {
        var keys = this.getKeys();
        var dict = this.getDictionary();
        var myreturn = 0;
        for (var x = 0; x < keys.length; x++) {
            myreturn = parseFloat((myreturn + dict.items[keys[x]][parameter]).toFixed(4));
            // myreturn = myreturn + parseFloat(dict.items[keys[x]][parameter]);
        }
        return myreturn;
    };
    public sumByValue = function (value) {
        var keyArrays = this._currentSelections.Keys();
        var resultArray = new Array(value.length);
        for (var z = 0; z < resultArray.length; z++) {
            resultArray[z] = 0;
        }
        
            for (var x = 0; x < keyArrays.length; x++) {
                var dataItem = this._currentSelections.Item(keyArrays[x]);
                for (var i = 0; i < value.length; i++) {
                    resultArray[i] += dataItem[value[i]];
                }
            }

        return resultArray;
    };
    public valuesIdentical = function (key) {
        var myreturn = true;
        var myarr = this._currentSelections.Values();
        var firstValue = "";
        for (var x = 0; x < myarr.length; x++) {
            if (x == 0) {
                firstValue = myarr[x][key];
            }
            if (myarr[x][key] !== firstValue) {
                myreturn = false;
            }
        }
        return myreturn;
    };

    public valueGreaterThanZero = function (key) {
        var returnboolean = true;
        var myarr = this._currentSelections.Values();
        for (var x = 0; x < myarr.length; x++) {
            if (myarr[x][key] <= 0) {
                returnboolean = false
            }
        }
        return returnboolean;
    }

    /**
     * True if at least one dictionary value is not equal to 'value'
     * example: myarr[x][key].Any(x => x != value)
     * @params key, value
     * @return boolean
     */
    public theNotValueDoesExist = function (key, value) {
        var myarr = this._currentSelections.Values();
        var returnboolean = false;
        for (var x = 0; x < myarr.length; x++) {
            if (myarr[x][key] != value) {
                returnboolean = true;
            }
        }
        return returnboolean;
    };

    /**
     * True if all dictionary values are not equal to 'value'
     * example: !myarr[x][key].Any(x => x == value)
     * @params key, value
     * @return boolean
     */
    public valueDoesNotExist = function (key, value) {
        var myarr = this._currentSelections.Values();
        for (var x = 0; x < myarr.length; x++) {
            if (myarr[x][key] == value) {
                return false;
            }
        }
        return true;
    };

    public identicalValuesInSelectedSet = function (key, value){
        var retVal = true;
        var myarr = this._currentSelections.Values();
        for(var x = 0; x < myarr.length; x++){
            if(myarr[x][key] != value){
                retVal = false;
                return retVal;
            }
        }
        return retVal;
    };

    public valuesNullOrEmpty = function (key) {
        var myarr = this._currentSelections.Values();
        for (var x = 0; x < myarr.length; x++) {
            if (myarr[x][key] == null || myarr[x][key] == undefined || myarr[x][key] == "") {
                return true;
            }
        }
        return false;
    }

    public getSelectedCustomerData = function (arr, key) {
        for(var x = 0; x <= arr.length; x++)
        {
            if(arr[x] && key == arr[x].id){
                return arr[x];
            }
        }
    }

    public getSelectedOrderIdsByValue(key, value){
        var retArr = [];
        var myarr = this._currentSelections.Values();
        for (var x = 0; x < myarr.length; x++) {
            if (myarr[x][key] == value) {
                retArr.push(myarr[x].OrderId)
            }
        }
        return retArr;
    }

    public getValues = function () {
        return this._currentSelections.Values();
    };
    public getQuantityCollection = function () {
        return this._quantityCollection;
    };
    public getQuantityArray = function () {
        return this._quantityArray;
    };
    public setQuantityCollection = function (parameter, quantity, obj) {
        this._quantityCollection[parameter] = quantity;
    };
    public setQuantityArray = function (obj) {
        this._quantityArray.push(obj);
    };
    public clear = function () {
        this._quantityCollection = {};
        this._quantityArray = [];
    };

    public getDirtyValues = function () {
        var thisGrid = $('#' + this._gridId).data('kendoGrid');

        var data = thisGrid.dataSource.data();
        var dirtyData = $.grep(data, function(item) {
            return item.dirty
        });

        return dirtyData;
    };
}