import React from 'react';
import { SmallParcelSearch } from './smallParcel-search.component.jsx';
import { SmallParcelGrid } from './smallParcel-grid.component.jsx';
import './smallParcelSuit.scss';
import * as GlobalService from '../global_services';
import * as metaDataService from './meta-data.service';
import { OrderSummaryVirtual } from '../orders/orderSummary/order-summary.virtual.component.jsx';

export class SmallParcelHolder extends React.Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;
        this.$rootScope = window;
        this.defaultFilterValue = {
            SearchTarget: "",
            HvpBatchName: "",
            BatchOrderid: 0,
            IndexOptionsCustomerId: 0,
            IndexOptionsFacilityId: 0,
            IndexOptionsSearchBy: 0,
            StartDate: '',
            EndDate: '',
            IndexOptionBatchName: 0,
            BatchName: '',
            IndexOptionBatchOrderId: 0,
            IndexOptionsReferenceNumber: 0,
            ReferenceNumber: '',
            IndexOptionsPoNum: 0,
            PurchaseOrderNumber: '',
            IndexOptionsShipToCountry: 0,
            ShipToCountry: '',
            IndexOptionsShipToState: 0,
            ShipToState: '',
            IndexOptionsShipToZip: 0,
            ShipToZip: '',
            IndexOptionsOrderNote: 0,
            OrderNote: '',
            Skus: [],
            sharedSelectOrder: null,
            openFormType: "",
            IndexOptionsStatus: 0,
            IsHVPTab: false,
        }
        this.smallParcelGridId = "SmallParcelRemoteGrid";
        this.state = {
            sharedSmallParcelFilter: Object.assign({}, this.defaultFilterValue),
            sharedSearchOn: false,
            filterObject: [],
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            gridDomSelector: () => this.$rootScope.ChildBulletin.Data.Window ? '.' + this.$rootScope.ChildBulletin.Data.Window + ' #' + this.smallParcelGridId : '#' + this.smallParcelGridId,
            isOptionsCallComplete: false,
            isGridLoading: false,
            gridInlineFilter: "",
            optionsCustomer: GlobalService.getCustomerList(true),
            optionsFacility: GlobalService.getFacilityList(true)
        }
    }

    async componentDidMount() {

        // Add Event Listner For Filter HVP Batch Name
        document.addEventListener("SmallParcel-searchHVPBatchName", this.searchHVPBatchName);

        kendo.ui.progress($("." + this.state.windowId), true);

        await metaDataService.getUserClientMetaData();
        await GlobalService.GetCustomersAndFacility();
        var filterData = metaDataService.getSmallParcelFilterMetaData();

        var sharedSmallParcelFilter = Object.assign({}, this.defaultFilterValue);
        var sharedSearchOn = false;

        if (filterData != "") {

            // temporary remove batch name from metaData,
            // remove code after pass batchId in filter
            delete filterData.BatchName;
            sharedSmallParcelFilter = filterData;
            sharedSearchOn = true;
        }

        // When HVP Batch Name Filter Apply and Small Parcel Tab is Inactive
        if(this.$rootScope.ChildBulletin.Data.HvpBatchName && this.$rootScope.ChildBulletin.Data.HvpCustomerName && this.$rootScope.ChildBulletin.Data.HvpFacilityName) {
            sharedSmallParcelFilter = Object.assign({}, this.defaultFilterValue);
            var tmpCustomer = this.state.optionsCustomer.filter(x => x.name === this.$rootScope.ChildBulletin.Data.HvpCustomerName);
            var tmpFacility = this.state.optionsFacility.filter(x => x.name === this.$rootScope.ChildBulletin.Data.HvpFacilityName);
            if(tmpCustomer.length && tmpFacility.length) {
                sharedSmallParcelFilter.IndexOptionsCustomerId = tmpCustomer[0].id;
                sharedSmallParcelFilter.IndexOptionsFacilityId = tmpFacility[0].id;
            }
            sharedSmallParcelFilter.HvpBatchName = this.$rootScope.ChildBulletin.Data.HvpBatchName;
            sharedSmallParcelFilter.IsHVPTab = true;
        }

        this.setState({
            sharedSmallParcelFilter: sharedSmallParcelFilter,
            sharedSearchOn: sharedSearchOn,
            isOptionsCallComplete: true
        });

        setTimeout(() => {
            sharedSmallParcelFilter.IsHVPTab = false;
            this.setState({ sharedSmallParcelFilter: sharedSmallParcelFilter });
        }, 500);

        kendo.ui.progress($("." + this.state.windowId), false);
    }

    componentWillUnmount() {
        document.removeEventListener("SmallParcel-searchHVPBatchName", this.searchHVPBatchName);
    }

    searchHVPBatchName = (e) => {
        if(e.detail.HvpBatchName && e.detail.HvpFacilityName && e.detail.HvpCustomerName) {
            var tmpCustomer = this.state.optionsCustomer.filter(x => x.name === e.detail.HvpCustomerName);
            var tmpFacility = this.state.optionsFacility.filter(x => x.name === e.detail.HvpFacilityName);
            var sharedSmallParcelFilter = Object.assign({}, this.defaultFilterValue);
            if(tmpCustomer.length && tmpFacility.length) {
                sharedSmallParcelFilter.IndexOptionsCustomerId = tmpCustomer[0].id;
                sharedSmallParcelFilter.IndexOptionsFacilityId = tmpFacility[0].id;
            }
            sharedSmallParcelFilter.HvpBatchName = e.detail.HvpBatchName;
            sharedSmallParcelFilter.IsHVPTab = true;
            this.setState({ sharedSmallParcelFilter: sharedSmallParcelFilter });

            setTimeout(() => {
                sharedSmallParcelFilter.IsHVPTab = false;
                this.setState({ sharedSmallParcelFilter: sharedSmallParcelFilter });
            }, 500);
        }
    }

    onFilterDataSet = (param_filterObj, isUpdateSearchOnFlag = true) => {

        metaDataService.setSmallParcelFilterMetaData(param_filterObj);
        let newState = {
            sharedSmallParcelFilter: param_filterObj
        }
        if (isUpdateSearchOnFlag) {
            newState.sharedSearchOn = true
        }
        this.setState(newState);
    }

    onApplySavedFilter = (param_filterObj) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var tmpGridInlineFilter = '';
        if (grid) {
            tmpGridInlineFilter = grid.dataSource.filter();
        }
        this.setState({
            sharedSmallParcelFilter: param_filterObj,
            sharedSearchOn: true,
            gridInlineFilter: tmpGridInlineFilter
        });
        this.refs.SmallParcelGrid.onApplySavedFilterData(param_filterObj);
    }

    // click on save search filter in meta data
    onManageFilter = (param_action, param_data) => {
        var filterData = Object.assign({}, this.state.sharedSmallParcelFilter);
        delete filterData.IndexOptionsFacilityId;

        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid && grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var filterArray = grid && grid.dataSource.filter() ? grid.dataSource.filter() : [];
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = GlobalService.prepareGridOptionsForStorage(gridColumn, sortArray, filterArray);
        if (param_action == 'saveFilter') {
            metaDataService.saveSmallParcelFilterMetaData(param_data, JSON.parse(JSON.stringify(filterData)), finalSubmission);
        } else {
            metaDataService.updateSmallParcelFilterMetaData(param_data, JSON.parse(JSON.stringify(filterData)), finalSubmission);
            GlobalService.notification('success', this.translate('Order_Filter_Update_success'));
        }
    }

    onClearSearch = () => {
        metaDataService.clearSmallParcelFilterMetaData();
        this.setState({
            sharedSmallParcelFilter: Object.assign({}, this.defaultFilterValue, { 'IndexOptionsFacilityId': this.state.sharedSmallParcelFilter.IndexOptionsFacilityId }),
            sharedSearchOn: false,
            gridInlineFilter: ""
        }, () => {
            // reset selection of grid on filter data change
            var grid = $(this.state.gridDomSelector()).data("kendoGrid");
            if (typeof grid != "undefined") {
                grid.dataSource.filter([]);
                var gridColumn = grid.getOptions().columns;
                var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
                let finalSubmission = GlobalService.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());

                setTimeout(() => {
                    metaDataService.setSmallParcelGridOptionInMetaData(finalSubmission);
                }, 500)
            }
        });
    }

    clickForFilterToggle = () => {
        this.setState({
            filterAreaToggle: !this.state.filterAreaToggle,
            isAnimatedClassAdded: !this.state.isAnimatedClassAdded
        });
        setTimeout(() => {
            this.setState({
                isAnimatedClassAdded: !this.state.isAnimatedClassAdded
            });
            $("#" + this.smallParcelGridId).data("kendoGrid").refresh();
            $(window).trigger('resize');
        }, 1000);
    }

    onGridLoading = (isLoading) => {
        this.setState({ isGridLoading: isLoading })
    }

    onViewSummary = (param_order) => {

        if (this.state.openFormType == "viewSummary" && param_order.isGridSelectionUpdate) {

            this.setState({
                sharedSelectOrder: param_order.dataItem,
                filterAreaToggle: true
            }, () => { this.resizeWindow(); });

        } else if (param_order.isOnViewSummaryClick) {

            this.setState({
                sharedSelectOrder: param_order.dataItem,
                filterAreaToggle: true,
                openFormType: "viewSummary"
            }, () => { this.resizeWindow(); });
        }

    }
    resizeWindow = () => {
        $(window).trigger('resize');
    }

    onExitOrderForm = () => {
        this.setState({
            sharedSelectOrder: null,
            filterAreaToggle: false,
            openFormType: ''
        }, () => {
            this.resizeWindow();
        });
    }
    render() {
        return (
            <div id="wms-smallParcel-virtual-wrapper" className={
                (this.state.filterAreaToggle ? " collapsedFilter" : " expandFilter")
                + (this.state.isAnimatedClassAdded ? " animatedCollapse" : "")
                + " small-Parcel-holder new-layout-grid-filter-wrapper"
            }>
                {this.state.isOptionsCallComplete && (

                    <SmallParcelSearch
                        windowId={this.state.windowId}
                        defaultFilterValue={this.defaultFilterValue}
                        sharedSearchOn={this.state.sharedSearchOn}
                        onFilterDataSet={this.onFilterDataSet}
                        sharedSmallParcelFilter={this.state.sharedSmallParcelFilter}
                        onApplySavedFilter={this.onApplySavedFilter}
                        onClearSearch={this.onClearSearch}
                        onManageFilter={this.onManageFilter}
                        onClickForFilterToggle={this.clickForFilterToggle}
                        isGridLoading={this.state.isGridLoading}
                        sharedSelectOrder={this.state.sharedSelectOrder}
                    />
                )}
                {this.state.isOptionsCallComplete && (

                    <SmallParcelGrid
                        ref="SmallParcelGrid"
                        windowId={this.state.windowId}
                        // sharedFilterAreaToggle={this.state.filterAreaToggle}
                        sharedSmallParcelFilter={this.state.sharedSmallParcelFilter}
                        onFilterDataSet={this.onFilterDataSet}
                        onGridLoading={this.onGridLoading}
                        gridInlineFilter={this.state.gridInlineFilter}
                        onViewSummary={this.onViewSummary}
                        sharedSelectOrder={this.state.sharedSelectOrder}
                    />
                )}
                {this.state.sharedSelectOrder != null && this.state.openFormType == "viewSummary" && (
                    <div className="order-form">
                        <OrderSummaryVirtual
                            sharedSelectOrderId={this.state.sharedSelectOrder.OrderId}
                            onExitSummaryForm={this.onExitOrderForm}
                            windowId={this.state.windowId}
                        />
                    </div>
                )}
            </div>
        );
    }
}