
import React from 'react';
import { FindOrdersHolderVirtual } from './findOrders/findOrders-holder.virtual.component.jsx';
import { ManageOrdersFormProto } from '../orders/manageOrders/manage-orders-form-proto.component.jsx';
import { OrderSummaryVirtual } from './orderSummary/order-summary.virtual.component.jsx';
import { CreateManualChargeModel } from './findOrders/model/create-manual-charge-model.component.jsx';
import style from './manageOrders.virtual.scss';
import * as metaDataService from './meta-data.service';

export class manageOrdersHolderVirtual extends React.Component {

    constructor(props) {
        super(props);

        this.$rootScope = window;

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            sharedFilterAreaToggle: false,
            isAnimatedClassAdded: false,
            sharedSelectOrder: null,
            showGrid: false,
            openFormType: ""
        }
    }

    async componentDidMount() {
        kendo.ui.progress($("." + this.state.windowId), true);
        await metaDataService.getUserClientMetaData();
        kendo.ui.progress($("." + this.state.windowId), false);

        this.setState({
            showGrid: true
        });
        $('#manage-orders-holder-virtual-react').closest('.k-window-content').css( "padding", "0" );
    }

    componentWillUnmount(){
        $('#manage-orders-holder-virtual-react').closest('.k-window-content').css( "padding", "26" );
    }
    resizeWindow =() =>{
        $(window).trigger('resize');
    }
    onCreateOrderProto = () => {
        this.setState({
            sharedSelectOrder: {},
            sharedFilterAreaToggle: true,
            openFormType: "manageOrderProto"
        },()=>{
            this.resizeWindow();
        });
    }

    onEditOrderProto = (param_order) => {
        this.setState({
            sharedSelectOrder: param_order,
            sharedFilterAreaToggle: true,
            openFormType: "manageOrderProto"
        },()=>{
            this.resizeWindow();
        });
    }

    onViewSummary = (param_order) => {

        if(this.state.openFormType ==  "viewSummary" && param_order.isGridSelectionUpdate){

            this.setState({
                sharedSelectOrder: param_order.dataItem,
                sharedFilterAreaToggle: true
            },() => { this.resizeWindow(); });

        }else if(param_order.isOnViewSummaryClick){

            this.setState({
                sharedSelectOrder: param_order.dataItem,
                sharedFilterAreaToggle: true,
                openFormType: "viewSummary"
            }, () => { this.resizeWindow(); });
        }

    }

    onExitOrderForm = () => {
        this.setState({
            sharedSelectOrder: null,
            sharedFilterAreaToggle: false,
            openFormType:''
        },()=>{
            this.resizeWindow();
        });
    }

    onClickForFilterToggle = () => {
        this.setState({
            sharedFilterAreaToggle: !this.state.sharedFilterAreaToggle,
            isAnimatedClassAdded: !this.state.isAnimatedClassAdded,
        },()=>{
            this.resizeWindow();
            setTimeout(() => {
                this.setState({
                    isAnimatedClassAdded : !this.state.isAnimatedClassAdded
                })
            }, 1000);
        });
    }


    render() {
        return (
            <div id="manage-orders-holder-virtual-react" >
                    {this.state.showGrid && (
                        <FindOrdersHolderVirtual
                            onEditOrderProto={this.onEditOrderProto}
                            onViewSummary={this.onViewSummary}
                            onCreateOrderProto={this.onCreateOrderProto}
                            sharedSelectOrder={this.state.sharedSelectOrder}
                            sharedFilterAreaToggle={this.state.sharedFilterAreaToggle}
                            isAnimatedClassAdded={this.state.isAnimatedClassAdded}
                            onClickForFilterToggle={this.onClickForFilterToggle}
                            sharedSearchInfo={this.state.sharedSearchInfo}
                            windowId={this.state.windowId} />
                    )}
                {this.state.sharedSelectOrder != null && this.state.openFormType == "manageOrderProto" && (
                    <div id="orderform" className="order-form">
                        <ManageOrdersFormProto
                            sharedSelectOrder={this.state.sharedSelectOrder}
                            onExitOrderForm={this.onExitOrderForm}
                            windowId={this.state.windowId} />
                    </div>
                )}
                {this.state.sharedSelectOrder != null && this.state.openFormType == "viewSummary" && (
                    <div className="order-form">
                        <OrderSummaryVirtual
                            sharedSelectOrderId={this.state.sharedSelectOrder.OrderId}
                            onExitSummaryForm={this.onExitOrderForm}
                            windowId={this.state.windowId}
                        />
                    </div>
                )}
            </div>
        )
    }
}
