export const getCustomerPackingSlipsColumns = () => {
    return {
        columns: [
            {
                field: "RowNumber",
                title: "RowNumber",
                width: 300,
                wmsid: 1,
                hidden: true,
            },
            {
                field: "Description",
                title: "Description",
                width: 300,
                wmsid: 2,
                headerAttributes: {
                    "data-wms-selector": "CustomerPackingSlipsDescription",
                }
            },
            {
                field: "Enabled",
                title: "Enabled",
                width: 70,
                wmsid: 3,
                headerAttributes: {
                    "data-wms-selector": "CustomerPackingSlipsEnabled",
                },
                template: "# if(Enabled){# Yes #}else {# No #} # ",
            },
        ],
    };
};

export const getCustomerPackingSlipsAllColumnList = () => {
    return [{
            field: "RowNumber",
            title: "RowNumber",
            width: 300,
            wmsid: 1,
            hidden: true,
        }, {
            field: "Description",
            title: "Description",
            width: 120,
            wmsid: 2,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "CustomerPackingSlipsDescription",
            }
        }, {
            field: "Enabled",
            title: "Enabled",
            width: 70,
            wmsid: 3,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "CustomerPackingSlipsEnabled",
            },
            template: "# if(Enabled){# Yes #}else {# No #} # ",
        }
    ];
};

export const getCustomerPackingSlipsSchema = () => {
    return {
        model: {
            id: "RowNumber",
            fields: {
                RowNumber: { type: "number" },
                Description: { type: "string" },
                Enabled: { type: "boolean" },
            },
        },
    };
};
