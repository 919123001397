
import React from 'react';
import * as GlobalService from '../../global_services';
import style from '../report-iframe.scss';
import { reportIframe } from '../report-iframe.component.jsx';
import { transferActivity } from '../../reports/transferActivity/transfer-activity.component.jsx';

export class transactionsIframeList extends React.Component {
    constructor(props) {

        super(props);

        this.$rootScope = window;
        this.translate = window.translate
        this.state = {
            reloadGrid: false,
            sharedUserUri: null,
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            Rights: GlobalService.GetRights(),
        }
    }
    setIframeUrl = (iframeUrl, reportTitle, urlParam) => e => {
        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            id: iframeUrl,
            title: this.translate(reportTitle),
            active: true,
            isIframe: true,
            icon: 'submenu-icon fa fa-plus',
            urlParam: urlParam
        });
    }

    openNewWindow = (componentUrl, reportTitle, urlParam) => e => {
        this.$rootScope.OpenWindow({
            url: componentUrl,
            reactComponent: transferActivity,
            singleton: true,
            title: this.translate(reportTitle),
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: this.translate(reportTitle)
            }],
            icon: 'submenu-icon fa fa-tachometer',
            urlParam: urlParam
        });
    }
    render() {
        return (
            <div className="react_iframebox">
                <ul>
                    <li>
                        <ul>
                            {this.state.Rights.hasOwnProperty('admin.reports.l1') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/BillingReport.aspx", "Label_Transaction_Register_Report", 'transaction-register')}>{this.translate('Label_Transaction_Register_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l2') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/TransByActReport.aspx", "Label_Transaction_Activity_Report", "transaction-by-activity")}>{this.translate('Label_Transaction_Activity_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l2') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/ViewTransactionReport.aspx", "Label_View_Transaction_Report", "view-transaction")}>{this.translate('Label_View_Transaction_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l2') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/TransByLineItem.aspx", "Label_Transaction_Lineitem_Report", "transaction-by-line-item")}>{this.translate('Label_Transaction_Lineitem_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l2') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/ShipmentValueReport.aspx", "Label_Shipment_Value_Report", "shipment-value")}>{this.translate('Label_Shipment_Value_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l2') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/OpenOrdersMultiReport.aspx", "Label_Open_Order_Report", "open-orders")}>{this.translate('Label_Open_Order_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l2') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/OpenReceivingsReport.aspx", "Label_Open_Receivings_Report", "open-receipts")}>{this.translate('Label_Open_Receivings_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l2') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/OpenAssembliesReport.aspx", "Label_Open_Assemblies_Report", "open-assemblies")}>{this.translate('Label_Open_Assemblies_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l4') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/OSNDReport.aspx", "Label_Overage_Shortage_Damaged_Report", "overage-shortage-and-damaged")}>{this.translate('Label_Overage_Shortage_Damaged_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l3') && this.state.Rights.hasOwnProperty('isWarehouseUser') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.openNewWindow("<react-report-transfer-activity></react-report-transfer-activity>", "Label_Transfer_Activity_Report", "transfer-activity")}>{this.translate('Label_Transfer_Activity_Report')}</a>
                                </li>
                            )}
                        </ul>
                    </li>
                </ul>
            </div>
        )
    }
}
