import { ArrayWms } from '../../../assets/ts/ArrayWms.ts';
import * as GlobalService from '../global_services';

export const printZplFormatedLabel = function (zplFormatedResponse) {
    GlobalService.printDataInZplPrinter(zplFormatedResponse);
    var eventItem = new CustomEvent("EventSmallParcelDataChanged", { detail: { "action": "update" } });
    document.dispatchEvent(eventItem);
}

export const printPdfFormatedLabel = function (pdfFormatedResponse, pdfFileName) {
    var blob = ArrayWms.convertBase64ToByteArray(pdfFormatedResponse, 'application/pdf');
    GlobalService.downloadFileBaseOnContentType(blob, 'application/pdf', pdfFileName, false, 'pdfViewer');

    var eventItem = new CustomEvent("EventSmallParcelDataChanged", { detail: { "action": "update" } });
    document.dispatchEvent(eventItem);
}