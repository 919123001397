import { StringWms } from '../../../assets/ts/StringWms.ts';

export const getGridDemoColumns = () => {
    let translate = window.translate;
    var Asn_Import_Management_Headers = StringWms.convertSringToOption(translate('Asn_Import_Management_Headers'));
    return ({

        columns: [
            {
                field: 'ReferenceNum',
                title: Asn_Import_Management_Headers[0].description,
                width: 180,
                lockable: false
            },
            {
                field: 'Customer',
                title: Asn_Import_Management_Headers[1].description,
                width: 180,
                lockable: false
            },
            {
                field: 'Facility',
                title: Asn_Import_Management_Headers[2].description,
                width: 180,
                lockable: false
            },
            {
                field: 'ExpectedArrivalDate',
                title: Asn_Import_Management_Headers[3].description,
                width: 180,
                lockable: false
            },
            {
                field: 'Carrier',
                title: Asn_Import_Management_Headers[4].description,
                width: 180,
                lockable: false
            },
            {
                field: 'PrimaryQuantity',
                title: Asn_Import_Management_Headers[5].description,
                width: 180,
                lockable: false
            },
            {
                field: 'Weight',
                title: Asn_Import_Management_Headers[6].description,
                width: 180,
                lockable: false
            },
            {
                field: 'Pallets',
                title: Asn_Import_Management_Headers[7].description,
                width: 180,
                lockable: false
            }
        ]
    })
}


export const getGridDemoSchema = () => {

    return ({
        schema: {
            model: {
                fields: {
                    ReferenceNum: {
                        type: "string"
                    },
                    Customer: {
                        type: "string"
                    },
                    Facility: {
                        type: "string"
                    },
                    ExpectedArrivalDate: {
                        type: "date"
                    },
                    Carrier: {
                        type: "string"
                    },
                    PrimaryQuantity: {
                        type: "number"
                    },
                    Weight: {
                        type: "number"
                    },
                    Pallets: {
                        type: "number"
                    }
                }
            }
        },
    })
}
