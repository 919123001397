import React from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";

declare var window: any;

interface Props {
    selector: string;
    handleCarrierObjChange: any;
    currentCarrierObj: any;
}

const UpsSurePostAccountInfoComponent: React.FC<Props> = (props: Props): any => {
    return (
        <WmsCollapse
            id={"UpsSurePostAccountInfoScreen"}
            headerLabel={window.translate("Label_Account_Information")}
            showCollapseText={false}
            showCollapseIcon={false}
        >
            <div className="account-info-section">
                <div>
                    <WmsInput
                        id="nickname"
                        inputName="Nickname"
                        wmsSelector={props.selector + "nickname"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={window.translate("Label_Account_Nick_Name")}
                        value={props.currentCarrierObj.Nickname}
                        required={true}
                        extraWrapperClass={"width-40"}
                    />
                    <WmsInput
                        id="accountNumber"
                        inputName="AccountNumber"
                        wmsSelector={props.selector + "accountNumber"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={window.translate("Label_Account_Number")}
                        value={props.currentCarrierObj.AccountNumber}
                        required={true}
                        extraWrapperClass={"width-40"}
                    />
                    <WmsInput
                        id="userId"
                        inputType={"userId"}
                        inputName="UserId"
                        wmsSelector={props.selector + "userId"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={window.translate("Label_Username")}
                        value={props.currentCarrierObj.UserId}
                        required={true}
                        extraWrapperClass={"width-40"}
                    />
                    <WmsInput
                        id="password"
                        inputType={"password"}
                        inputName="Password"
                        wmsSelector={props.selector + "password"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={window.translate("Login_Lbl_Password")}
                        value={props.currentCarrierObj.Password}
                        required={true}
                        extraWrapperClass={"width-40"}
                    />
                </div>
            </div>
        </WmsCollapse>
    );
};

UpsSurePostAccountInfoComponent.propTypes = {};

UpsSurePostAccountInfoComponent.defaultProps = {};

export default React.memo(UpsSurePostAccountInfoComponent);
