import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsMenuItem.scss';

class WmsMenuItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.translate = window.translate;
    }

    render() {
        return (
            <ul className="wms_menu_item_wrapper">
                {this.props.items.map((item, index) =>
                    (typeof item.isButtonMenu == "undefined" || item.isButtonMenu == true) ?
                    <li
                        key={index}
                        id={item.name.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, "_")}
                        className={this.props.count + 'level ' +
                            (item.disabled ? "k_state_disabled mydisabled" : 'k-item k-state-default') +
                            (this.props.children ? "has_submenu" : '') +
                            (item.customClass ? " "+item.customClass : '')
                        }
                        disabled={this.props.disabled ? "disabled" : ""}
                        onClick={item.disabled ? (()=> function(){ }) : item.onClickFunc }
                        data-wms-selector={item.wmsSelector}
                        data-wmsid={(item.wmsid) ? item.wmsid : ""}
                        data-run-report={item.runReport}
                        data-rdl-name={item.rdlName}
                        data-runner-key={item.runnerKey}
                        onMouseLeave={
                            (item.disabled == true) ?
                            (() => this.props.eventMouseLeave()) :
                            () => function () { }
                        }
                        onMouseEnter={
                            (item.disabled == true) ?
                            (() => this.props.eventMouseEnter(item.disabledText)) :
                            (() => function () { })
                        }
                    >
                        {item.name}
                        {item.isNewMenu == true && (
                            <span className="wms-badge">{this.translate('Label_Beta')}</span>
                        )}
                        {item.value.length != 0 && (
                            // <ul>
                            <WmsMenuItem
                            items={item.value}
                            count={this.props.count + 1}
                            eventMouseEnter={this.props.eventMouseEnter}
                            eventMouseLeave={this.props.eventMouseLeave}/>
                            // </ul>
                        )}
                    </li> : ""
                )}
            </ul>
        )
    }
}

WmsMenuItem.propTypes = {
    // disabled: PropTypes.bool, // This should be used to define class
    // label: PropTypes.string.isRequired, // this should write the label of the menu item
    // onClickFunc: PropTypes.func, // this should control the click event and anything we need to determine before we send it up
    items: PropTypes.array,
    count: PropTypes.number,
    eventMouseLeave:PropTypes.func,
    onMouseEnter:PropTypes.func,
    isNewMenu: PropTypes.bool, // set new badge show or not
}

WmsMenuItem.defaultProps = {
    disabled: false,
    count: 0,
    eventMouseLeave:(e) => function () { },
    eventMouseEnter:(e) => function () { },
    isNewMenu: false
}

export default WmsMenuItem;