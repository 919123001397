import React from 'react';

//component
import WmsModel from '../../../../components/WmsModel/WmsModel.jsx';
import WmsContainer from '../../../../components/WmsContainer/WmsContainer.jsx';
import WmsButton from '../../../../components/WmsButton/WmsButton.jsx';
import WmsDropdown from '../../../../components/WmsDropdown/WmsDropdown.jsx';
import style from './reprint-shipping-label.scss';
import { ObjectWms } from '../../../../../../assets/ts/ObjectWms.ts';
import * as GlobalService from './../../../../global_services';
var axios = require('axios');

export class ReprintShippingLabel extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            selector: "FindOrderReprintShippingLabel",
            shippingLabelOptions:[],
            shippingLabelGenerateGif:false,
            shippingLabelSelectedIndex:0
        }
    }

    componentDidMount() {
        var shippinglabelindex = ObjectWms.FindIndexByProperty(this.props.SharedReprintLabelsReport, "CategoryName", "Reprint Shipping Label");
        if(shippinglabelindex != -1){
            var labelReports = this.props.SharedReprintLabelsReport[shippinglabelindex].Reports;
            var customerId = this.props.sharedSelectedOrderData.CustomerId;
            var tmpShippingLabelOptions = [];
            labelReports.filter((report) => {
                if(report.TemplatedRunner.indexOf('customerid=') === -1) {
                    tmpShippingLabelOptions.push(report);
                } else if( this.props.sharedIsSameCustomer && report.TemplatedRunner.indexOf('customerid='+customerId) != -1) {
                    tmpShippingLabelOptions.push(report);
                }
            });
            this.setState({ shippingLabelOptions:tmpShippingLabelOptions});
        }
    }

    handleChangeShippingLabel = (e) => {
        this.setState({ "shippingLabelSelectedIndex":e.target.value });
    }

    generatePDF = () => {
        var selectReportLabel = this.state.shippingLabelOptions[this.state.shippingLabelSelectedIndex];
        var pdfDownloadLink = '/WebUI/Shipping/FindOrders/ParcelReprintPdfContent?customerId=' + this.props.sharedSelectedOrderData.CustomerId + '&orderIds=' +
        this.props.SharedSelectedOrderIds.toString() + '&templatedRunnerKey=' + selectReportLabel.TemplatedRunnerKey;
        console.log("pdfDownloadLink :",pdfDownloadLink);
        axios.get(pdfDownloadLink,{ responseType: 'blob' }).then((response) => {
            GlobalService.downloadFileBaseOnContentType(response.data, 'application/pdf',  "ShippingLabel-" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + ".pdf", false, 'pdfViewer');
            this.props.onCloseReprintShippingLabel();
        }).finally(() => {  });
    }

    render() {
        return (
            <WmsModel
                id={"ReprintShippingLabel"}
                class="reprint-shipping-label-wrapper"
                title={this.translate('Label_Packages')}
                width={500}
                onCloseFunc={this.props.onCloseReprintShippingLabel}
                height={230}
                isOpen={this.props.sharedIsReprintShippingLabelOpen}
                customClass='wms-model-new-ui-wrapper' >
                    <div className="model-content-wrapper">
                        <WmsContainer>
                            <WmsDropdown
                                id={'tempaltes'}
                                name='shippingLabelSelectedIndex'
                                wmsSelector={this.state.selector + 'tempaltes'}
                                options={this.state.shippingLabelOptions}
                                value={this.state.shippingLabelSelectedIndex}
                                onChangeFunc={this.handleChangeShippingLabel}
                                label={this.translate('Label_Template')}
                                valueField={'PackageDefId'}
                                textField={'Name'}
                                applyIndex={true}/>
                        </WmsContainer>
                    </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        id={this.state.selector + "cancel"}
                        name={this.state.selector + "cancel"}
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.state.selector + "cancel"}
                        onClickFunc={this.props.onCloseReprintShippingLabel}
                        icon="fa-ban"
                        buttonType="yellow"
                    />
                    <WmsButton
                        label={this.translate("Label_Generate")}
                        wmsSelector={this.state.selector + "generate"}
                        onClickFunc={this.generatePDF}
                        icon="fa-floppy-o"
                        loading={this.state.shippingLabelGenerateGif}
                    />
                </div>
            </WmsModel>
        )
    }
}