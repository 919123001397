import React from 'react';
import ReactDOM from 'react-dom';
import * as GlobalService from './../global_services';
import style from './supernav.scss';

export default class SuperNavComponent extends React.Component {
    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.state = {
            selector: "SuperNav",
            Rights: GlobalService.GetRights(),
            isCollapseSandBox: false
        }

    }
    componentDidMount() {
        $("#customer-central-dropdown").kendoMenu({
            // closeOnClick: false,
            // openOnClick: {
            //     rootMenuItems: true
            // }
        });
    }
    openSalesForce = () => {
        GlobalService.VerifySsoToSalesForceConfig().then((response) => {
            console.log("VerifySsoToSalesForceConfig response", response);
            if (response.status == 204 || response.status == 200) {
                $("body").append('<form id="salesforceSSO" action="/WebUI/Shared/GlobalServices/SsoToSalesForceForums" method="post" target="_blank"></form>');
                $("#salesforceSSO").submit();
                $("#salesforceSSO").remove();
            }
        }).finally(() => {
        });
    }
    expandCollapseSandBox = () => {
        this.setState({
            isCollapseSandBox: !this.state.isCollapseSandBox
        })
    }

    render() {
        return (
            <div id="supernav-component" className={"super-nav-component"}>
                {(window.location.host === "box.secure-wms.com") && (
                    <div className={'sand-box-alert ' + (this.state.isCollapseSandBox ? 'collapse-sandbox-msg' : 'expand-sandbox-msg')}>
                        <label className={"expand-title"}>
                            <span className="fa fa-exclamation-triangle"></span>
                            {this.translate('Label_Sandbox')} |
                        </label>
                        {this.state.isCollapseSandBox == false && (
                            <div className={"expand-msg"}>
                                {this.translate('Label_Sandbox_Content')}

                            </div>
                        )}
                        <img className='collapse-expand-icon' src={(this.state.isCollapseSandBox ? "assets/images/expand-white.png" : "assets/images/collapse-white.png")} onClick={this.expandCollapseSandBox} />
                    </div>
                )}
                <div className={'call-us main-wrapper'}>
                    <a data-wms-selector={this.state.selector + "Phone"} className="k-link cust-central" href="tel:+1 888-309-7114">
                        <i className="fa fa-phone-flip main-icon" aria-hidden="true"></i>
                        <span>888-309-7114</span>
                    </a>
                </div>
                {false && (
                    <div className={'contact-us main-wrapper'}>
                        <a data-wms-selector={this.state.selector + "ContactUs"} className="cust-central">
                            <i className="fa fa-envelope-o main-icon" aria-hidden="true"></i>
                            <span>{this.translate('Label_Contact_Us')}</span>
                        </a>
                    </div>
                )}
                <div className={'customer-central main-wrapper'}>
                    <ul id="customer-central-dropdown">
                        <li>
                            <a data-wms-selector={this.state.selector + "CustomerCentral"} className="cust-central">
                                <i className="fa fa-life-ring main-icon" aria-hidden="true"></i>
                                {this.translate('Label_Customer_Central')}
                            </a>
                            <ul>
                                {this.state.Rights.hasOwnProperty('isWarehouseUser') && (
                                    <li>
                                        <a data-wms-selector={this.state.selector + "SuportPortal"} onClick={this.openSalesForce}>{this.translate('Label_Support_Portal')}</a>
                                    </li>
                                )}
                                <li>
                                    <a data-wms-selector={this.state.selector + "CommunityForum"} target="_blank" href=" https://community.3plcentral.com">{this.translate('Label_Community_Forum')}</a>
                                </li>
                                <li>
                                    <a data-wms-selector={this.state.selector + "SystemStatus"} target="_blank" href="https://status.3plcentral.com/">{this.translate('Label_System_Status')}</a>
                                </li>
                                <li>
                                    <a data-wms-selector={this.state.selector + "ApiDocumentation"} target="_blank" href="https://developer.3plcentral.com">{this.translate('Label_Api_Documentation')}</a>
                                </li>
                                <li>
                                    <a data-wms-selector={this.state.selector + "TrainingResources"} target="_blank" href="https://www.3plcentral.com/3pl-central-university">{this.translate('Label_Training_Resources')}</a>
                                </li>
                                <li>
                                    <a data-wms-selector={this.state.selector + "KnowledgeBase"} target="_blank" href="https://help.3plcentral.com">{this.translate('Label_Knowledge_Base')}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
