
import React from 'react';
import { RolesFormPermission } from './roles-form-permission.component.jsx';

//component
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsScrollTo from '../components/WmsScrollTo/WmsScrollTo.jsx';
import WmsConfirmModel from '../components/WmsConfirmModel/WmsConfirmModel.jsx';


//Services
import * as roleService from './roles.service.jsx';
import * as GlobalService from './../global_services';

export class RolesForm extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        this.$rootScope = window

        this.state = {
            showDiscard: false,
            saveLoader: false,
            Selector: 'Roles',
            roleType: '',
            sharedSelectRoleData: this.props.sharedSelectRoleData,
            isOpenConfirmModel: false,
            isPermissionChanged: false,
            isLoadingStop: false,
            sawCheckBox: true,
            conFormModelText: "",
            confirmModalFor: "update"
        }
    }

    componentDidMount() {
        if (this.props.isRoleForCopy == true) {
            this.setState({ roleType: this.props.sharedSelectRoleData.Scope == 1 ? 'Customer' : 'Warehouse' });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.sharedSelectRoleData != null &&
            (nextProps.isRoleForCopy != this.props.isRoleForCopy || this.props.sharedSelectRoleData.Uri != nextProps.sharedSelectRoleData.Uri)
        ) {
            this.setState({
                sharedSelectRoleData: nextProps.sharedSelectRoleData,
                showDiscard: false,
            });
        }

        if (nextProps.sharedSelectRoleData == '' && nextProps.sharedSelectRoleData != this.props.sharedSelectRoleData) {
            this.setState({ roleType: '' });
        }
        if (nextProps.isRoleForCopy == true) {
            this.setState({ roleType: nextProps.sharedSelectRoleData.Scope == 1 ? 'Customer' : 'Warehouse' });
        }
    }



    eventClickSave = () => {

        let isRoleUserRole;

        if (this.props.sharedInitialRoleDataObject.LoggedInUserRoles.indexOf(this.state.sharedSelectRoleData.Name) !== -1) {
            isRoleUserRole = true;
        } else {
            isRoleUserRole = false;
        }

        if (typeof this.props.sharedSelectRoleData.Uri != 'undefined' && this.props.isRoleForCopy == false) {

            if (isRoleUserRole) {

                GlobalService.notification("errorAutoClose", this.translate('RoleManager_CreateRole_CannotUpdateOwnRole'));

            } else if (this.state.sharedSelectRoleData.InUse) {
                //Open Confimation model if role in use by other customer
                this.setState({
                    confirmModalFor: "checkForWarehouseRole",
                    conFormModelText: this.state.sharedSelectRoleData.Name + " " + this.translate('RoleManager_Label_RoleInUse_Paragraph'),
                    sawCheckBox: true,
                    saveLoader: false,
                    isOpenConfirmModel: true
                });

            } else if (this.state.sharedSelectRoleData.Scope == 1 && this.checkForWarehouseRole("update")) { // check customer user in edit time
                return;
            } else {
                this.updateRole();
            }

        } else {

            if (this.state.roleType == "Customer" && this.checkForWarehouseRole("create")) {
                return;
            }

            this.createRole();
        }
    }
    checkedNodeIds = (nodes, checkedNodes) => {
        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].checked) {
                if (nodes[i].scope == 2) {
                    checkedNodes.push(nodes[i].text);
                }
            }

            if (nodes[i].hasChildren) {
                this.checkedNodeIds(nodes[i].children.view(), checkedNodes);
            }
        }
    }
    onCloseConfirmModel = () => {
        this.setState({ isOpenConfirmModel: false });
    }

    onRoleDataSet = (param_rolename) => {

        if (param_rolename == 'Warehouse' || param_rolename == 'Customer') {
            this.setState({ roleType: param_rolename });
        } else {
            this.setState({
                sharedSelectRoleData: Object.assign(
                    {},
                    this.state.sharedSelectRoleData,
                    { Name: param_rolename }
                ),
                showDiscard: true,
                isPermissionChanged: true
            });
        }
    }

    onPermissionChange = () => {
        this.setState({
            showDiscard: true,
            isPermissionChanged: true
        });
    }
    checkForWarehouseRole = (confirmModalFor) => {

        let treeview = $("#TreeRole").data("kendoTreeView");
        let treeData = treeview.dataSource.view();
        var checkedNodes = [];

        this.checkedNodeIds(treeData, checkedNodes);
        if (checkedNodes.length > 0) {
            var roleList = "<ul class='warehouse_role_list'>";
            checkedNodes.forEach(function (text) {
                roleList += "<li>" + text + "</li>";
            });
            roleList += "</ul>";
            this.setState({
                confirmModalFor: confirmModalFor,
                conFormModelText: this.translate('AreYouSure_customer_warehouse_role') + roleList,
                sawCheckBox: false,
                saveLoader: false,
                isOpenConfirmModel: true
            });
            return true;
        } else {
            return false;
        }
    }
    clickOnContinueButton = () => {
        if (this.state.confirmModalFor == "update") {
            this.setState({
                isLoadingStop: false
            });
            this.updateRole();
        } else if (this.state.confirmModalFor == "checkForWarehouseRole") {
            if (this.state.sharedSelectRoleData.Scope == 1) {

                if(this.checkForWarehouseRole("update") == false) {
                    this.updateRole();
                } else {
                    this.setState({
                        isLoadingStop: true
                    });
                }
            } else {
                this.updateRole();
            }
        } else {
            this.createRole();
        }
    }
    createRole = () => {

        this.setState({ saveLoader: true });
        let treeview = $("#TreeRole").data("kendoTreeView");
        let treeData = treeview.dataSource.view();
        var payLoadObj = {
            'key': " ",
            'text': null,
            'description': null,
            'checked': false,
            'items': treeData,
        };
        roleService.createRole(
            payLoadObj,
            this.state.sharedSelectRoleData.Name,
            this.state.roleType
        ).then((response) => {
            this.setState({ saveLoader: false });
            if (typeof response != 'undefined' && (response.status == 200 || response.status == 204)) {
                GlobalService.notification('success', this.translate('RoleManager_CreateRole_Success'));
                this.props.onReloadGrid('reloadRoleGrid');
                this.onCloseConfirmModel();
                this.props.onExitRoleForm();
            } else {
                GlobalService.apiFailer(response);
            }
        }).finally(() => {
            this.setState({ saveLoader: false });
        });
    }
    updateRole = () => {

        let treeview = $("#TreeRole").data("kendoTreeView");
        let treeData = treeview.dataSource.view();

        this.setState({ saveLoader: true });
        let payLoadObj = {
            'key': " ",
            'text': null,
            'description': null,
            'checked': false,
            'items': treeData,
        };
        roleService.updateRole(payLoadObj, this.state.sharedSelectRoleData).then((response) => {

            this.setState({ saveLoader: false });
            if (typeof response != 'undefined' && (response.status == 200 || response.status == 204)) {

                GlobalService.notification('success', this.translate('RoleManager_UpdateRole_Success'));
                this.props.onReloadGrid('reloadRoleGrid');
                this.onCloseConfirmModel();
                this.props.onExitRoleForm();

            } else {
                this.onCloseConfirmModel();
                GlobalService.apiFailer(response);

            }

        }).finally(() => {
            this.setState({ saveLoader: false });
        });
    }

    render() {
        return (
            <div className="role-form" id="role_form_block" >
                <div>
                                <WmsButton
                                    label={this.translate('Label_Save')}
                                    wmsSelector={this.state.Selector + 'SaveButton'}
                                    onClickFunc={this.eventClickSave}
                                    icon='fa-floppy-o'
                                    showToolTip={true}
                                    tooltipText={
                                        (typeof this.state.sharedSelectRoleData.Name == 'undefined' ||
                                            this.state.sharedSelectRoleData.Name == '') ?
                                            this.translate('Tooltip_Required_Fields') :
                                            (this.state.isPermissionChanged == false) ?
                                                this.translate('Tooltip_Role_Change') :
                                                (this.state.roleType == '' &&
                                                    typeof this.props.sharedSelectRoleData.Uri == 'undefined') ?
                                                    this.translate('Tooltip_Role_Type_Required') : ''
                                    }
                                    disabled={
                                        this.state.saveLoader == true ||
                                        this.state.isPermissionChanged == false ||
                                        typeof this.state.sharedSelectRoleData.Name == 'undefined' || this.state.sharedSelectRoleData.Name == '' ||
                                        (this.state.roleType == '' &&
                                            typeof this.props.sharedSelectRoleData.Uri == 'undefined')
                                    }
                                    loading={this.state.saveLoader} />

                                {this.state.showDiscard == false && (
                                    <WmsButton
                                        label={this.translate('Label_Exit')}
                                        wmsSelector={this.state.Selector + 'ButtonExit'}
                                        onClickFunc={this.props.onExitRoleForm}
                                        icon='fa-sign-out'
                                        buttonType="yellow" />
                                )}

                                {this.state.showDiscard == true && (
                                    <WmsButton
                                        label={this.translate('Label_Discard_Exit')}
                                        wmsSelector={this.state.Selector + 'ButtonDiscard'}
                                        onClickFunc={this.props.onExitRoleForm}
                                        icon='fa-times-circle'
                                        buttonType="yellow" />
                                )}

                            </div>



                    <div id="ManageUserScrollBox" className={this.props.windowId + " tab-content"}>

                        <RolesFormPermission
                            sharedSelectRoleData={this.state.sharedSelectRoleData}
                            sharedRoleType={this.state.roleType}
                            onPermissionChange={this.onPermissionChange}
                            onRoleDataSet={this.onRoleDataSet}
                            isRoleForCopy={this.props.isRoleForCopy} />

                        <WmsConfirmModel
                            id="roleConfirmModel"
                            isOpen={this.state.isOpenConfirmModel}
                            width={500}
                            height={250}
                            firstBtnLabel={this.translate('RoleManager_Label_Continue')}
                            firstBtnIcon={"fa fa-check-square"}
                            firstBtnOnClickFunc={this.clickOnContinueButton}
                            thirdBtnLabel={this.translate('RoleManager_Label_Back')}
                            thirdBtnIcon={"fa fa-arrow-left"}
                            conFormModelText={this.state.conFormModelText}
                            checkboxLabel={this.translate('RoleManager_Label_IUnderstand')}
                            sawCheckBox={this.state.sawCheckBox}
                            onCloseFunc={this.onCloseConfirmModel}
                            isLoadingStop={this.state.isLoadingStop}
                        />
                    </div>

            </div>
        )
    }
}