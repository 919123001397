import React, { Component } from 'react';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsGrid from '../../components/WmsGrid/WmsGrid.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsContainer from '../../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import { KendoWms } from '../../../../assets/ts/KendoWms.ts';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import { ManageReceiptLineItemDetailGrid } from './manageReceipt-line-item-detail-grid.component.jsx';

import * as GridConfig from './grid_configuration.jsx';
import * as GlobalService from './../../global_services';
import * as manageReceiptService from './manageReceipt.services.jsx';

export class ManageReceiptLineItems extends Component {
    constructor(props) {
        super(props);


        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            columns: [],
            schema: GridConfig.ReceiptLineItemsGridSchema().schema,
            tplSettings: GlobalService.getTplSettings(),
            selectedRows: 0,
            putAwaycall: false,
            gridName: 'GridReceiptLineItems',
            gifDeleteLineItem: false,
            sharedSelectedLineItem: '',
            totalWeight: 0,
            totalVolume: 0,
            localKendoGrid: null,
            isLineItemGridInitialise: 0,
            lineItemGridData: []
        }


    }

    componentDidMount() {
        this.getReceiptLineItemData();
    }

    componentWillReceiveProps(nextProps) {

        // for reset line item grid from mass add and edit allocation modal success
        if (nextProps.sharedIsResetLineItemGrid != this.props.sharedIsResetLineItemGrid) {
            this.refreshLineItemGrid();
        }
        this.setState({
            columns: GridConfig.ReceiptLineItemsGridColumns(nextProps.selectedCustomer, nextProps.selectedFacility).columns
        });
    }

    getReceiptLineItemData = () => {
        manageReceiptService.getReceiptLineItems(this.props.sharedReceiptData.Receipt.EditUri).then((response) => {
            let tempTotalWeight = 0;
            let tempTotalVolume = 0;
            for (let x = 0; x < response.length; x++) {
                tempTotalWeight = tempTotalWeight + parseFloat(response[x].Weight);
                tempTotalVolume = tempTotalVolume + parseFloat(response[x].Volume);
            }
            this.props.onLineItemGridChange(response);
            this.setState({
                lineItemGridData: response,
                totalWeight: tempTotalWeight,
                totalVolume: tempTotalVolume
            });
        }).finally(() => {

        });
    }

    handleChangeEvent = (event) => {
        let ReceiptData = Object.assign({}, this.props.sharedReceiptData.Receipt);

        if (event.target.name == 'Unit1Identifier' || event.target.name == 'Unit2Identifier') {
            if (!ReceiptData[event.target.name]) {
                ReceiptData[event.target.name] = {};
            }
            ReceiptData[event.target.name].Name = event.target.value;
        } else {
            ReceiptData[event.target.name] = event.target.value;
            let unitDrpname = (event.target.name == 'NumUnits1') ? 'Unit1Identifier' : 'Unit2Identifier';
            if (!ReceiptData[unitDrpname]) {
                ReceiptData[unitDrpname] = {};
            }
            if (StringWms.isEmpty(event.target.value)) {
                ReceiptData[unitDrpname].Name = this.props.sharedReceiptData.UnitOfMeasureTypes[0].Name;
            }
        }
        this.props.onReceiptDataSet(ReceiptData);
    }

    onSelectRow = (kendoGrid) => {
        var receiptLineItemsGrid = $('.' + this.props.windowId + ' #' + this.state.gridName).data('kendoGrid');
        var rows = receiptLineItemsGrid.select();
        var sharedSelectedLineItem = '';
        if (kendoGrid.getItem(kendoGrid.getKeys()) != null) {
            sharedSelectedLineItem = Object.assign({}, kendoGrid.getItem(kendoGrid.getKeys()));
        }

        this.setState({
            localKendoGrid: kendoGrid,
            selectedRows: rows.length,
            sharedSelectedLineItem: sharedSelectedLineItem,
        });
    }

    changeDirectPutaway = (fnName = "") => {
        var putAwayEnabled = fnName == "include_line" ? false : true;
        this.setState({ putAwaycall: true });

        manageReceiptService.ReceiptLineItem_DirectPutAway(this.props.sharedReceiptData.Receipt.EditUri, putAwayEnabled).then((response) => {
            this.refreshLineItemGrid();
            // $scope.Selected.ReceiptLineItemsGrid = false;
        }).finally(() => {
            this.setState({ putAwaycall: false });
        });
    }


    deleteLineItme = () => {
        this.setState({ gifDeleteLineItem: true });

        let receiptLineItemsGrid = $("." + this.props.windowId + ' #' + this.state.gridName).data('kendoGrid');

        manageReceiptService.ReceiptLineItem_Delete(KendoWms.getListFromSelectedGridRows(receiptLineItemsGrid, "LineItemEditUri")).then((response) => {
            // Indicate success to user with notification
            GlobalService.notification('success', this.translate('Label_Delete_Receipt_Line_Item'));

            // Refresh the grid so it doesn't show deleted elements
            this.refreshLineItemGrid();

        }).finally(() => {
            this.setState({ gifDeleteLineItem: false });
        });
    }



    onGridDataBound = (e) => {
        // receipt line item bound here
        if (typeof this.$rootScope.setLoaderData['Edit Receipts'] != "undefined") {
            this.$rootScope.setLoaderTimerEndTime("Edit Receipts");
        }
        if (typeof this.$rootScope.setLoaderData['Create Receipts'] != "undefined") {
            this.$rootScope.setLoaderTimerEndTime("Create Receipts");
        }
        let gridData = e.sender.dataSource.data();

        // Get line item grid data and set into shared receipt object
        let receiptData = Object.assign({}, this.props.sharedReceiptData.Receipt);
        if (receiptData.ReceiveItems == null || receiptData.ReceiveItems.length != gridData.length) {
            receiptData.ReceiveItems = gridData;
            let resetDiscardBtn = false;
            if (this.state.isLineItemGridInitialise == 0) {
                resetDiscardBtn = true;
                this.state.isLineItemGridInitialise = 1;
            }
            this.props.onReceiptDataSet(receiptData, resetDiscardBtn);
        }
    }

    onClickEventOfContextMenu = (obj) => {

        if (obj.wmsid == 'addLineItem') {
            this.openLineItemModal('create')
        } else if (obj.wmsid == 'editLineItem') {
            this.openLineItemModal('edit')
        } else if (obj.wmsid == 'deleteLineItem') {
            this.deleteLineItme()
        }
    }

    openLineItemModal = (param_flow) => {
        this.props.onOpenLineItemModal(true, param_flow, this.state.sharedSelectedLineItem);
    }

    refreshLineItemGrid = () => {
        var receiveLineItemsGrid = $("." + this.props.windowId + " #" + this.state.gridName).data('kendoGrid');
        GlobalService.refreshGridForChangedNumberOfRows(receiveLineItemsGrid);
        receiveLineItemsGrid.dataSource.read();
        this.getReceiptLineItemData();
        if (this.state.localKendoGrid != null) {
            this.state.localKendoGrid.clearSelections(() => {
                this.state.localKendoGrid.repaintSelections();
            });
            this.setState({ selectedRows: 0 });
        }
    }
    onSelectAllChange = (event) => {

        var grid = $('.' + this.props.windowId + ' #' + this.state.gridName).data('kendoGrid');
        if (grid) {
            this.setState({
                selectedRows: grid.select().length,
            });
        }

    }

    render() {
        const renderReceiptObj = this.props.sharedReceiptData.Receipt;
        return (
            <div className="receipt-line-items-section" ref={this.props.divRefs}>
                <WmsCollapse id={'collapseReceiptLineitem'} headerLabel={this.translate('Label_Receipt_Line_Items')} >
                    {this.state.columns.length > 0 &&
                        <WmsGrid
                            wmsSelector={this.props.selector + 'LineItemGrid'}
                            gridKeySelection={'ReceiveItemId'}
                            gridName={this.state.gridName}
                            virtual={false}
                            staticDataVirtualization={true}
                            isServerSorting={false}
                            isServerFiltering={false}
                            isAlignIndexesByProperty={true}
                            staticGridData={this.state.lineItemGridData}
                            id={this.state.gridName}
                            columns={this.state.columns}
                            schema={this.state.schema}
                            windowId={this.props.windowId}
                            showSortColumns={false}
                            showGridSummary={true}
                            onSelection={this.onSelectRow}
                            inlineSelectAll={true}
                            onSelectAllChange={this.onSelectAllChange}
                            detailTemplate={(this.props.sharedShowCustomReceiptInfo ? ManageReceiptLineItemDetailGrid : '')}
                            clickEventOfContextMenu={this.onClickEventOfContextMenu}
                            onDataBound={(e) => this.onGridDataBound(e)}
                            menu={
                                [
                                    {
                                        "name": this.translate('Label_Add_Line_Item'),
                                        "value": [],
                                        "disabled": (
                                            (GlobalService.GetCurrentUser().CustomerIds.length > 0) && renderReceiptObj.Completed
                                        ),
                                        "showToolTip": (
                                            (GlobalService.GetCurrentUser().CustomerIds.length > 0) && renderReceiptObj.Completed
                                        ),
                                        "tooltipText": this.translate('Label_Line_Item_Cutomer_Access_Note'),
                                        "icon": "fa-plus",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        'wmsid': 'addLineItem',
                                        'wmsSelector': this.props.selector + "AddLineItem",
                                        'clickFunc': (e) => this.openLineItemModal('create'),
                                    }, {
                                        "name": this.translate('Label_Edit_Line_Item'),
                                        "value": [],
                                        "disabled": this.state.selectedRows != 1 || (
                                            (GlobalService.GetCurrentUser().CustomerIds.length > 0) && renderReceiptObj.Completed
                                        ),
                                        "showToolTip": (
                                            (GlobalService.GetCurrentUser().CustomerIds.length > 0) && renderReceiptObj.Completed
                                        ),
                                        "tooltipText": this.translate('Label_Line_Item_Cutomer_Access_Note'),
                                        "icon": "fa-plus",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        'wmsid': 'editLineItem',
                                        'wmsSelector': this.props.selector + "EditLineItem",
                                        'clickFunc': (e) => this.openLineItemModal('edit'),
                                    }, {
                                        "name": this.translate('Label_Directed_Putaway'),
                                        "wmsSelector": this.props.selector + "DirectPutAwayLocation",
                                        "value": [{
                                            "name": this.translate('Label_Directed_Putaway_Include_All_Lines_Without_Location'),
                                            "value": [],
                                            "disabled": false,
                                            'wmsSelector': this.props.selector + "DirectedPutawayIncludeAllLinesWithoutLocation",
                                            'onClickFunc': ((e) => this.changeDirectPutaway('include_line')),
                                        }, {
                                            "name": this.translate('Label_Directed_Putaway_Include_All_Lines'),
                                            "value": [],
                                            "disabled": false,
                                            'wmsSelector': this.props.selector + "DirectedPutawayIncludeAllLines",
                                            'onClickFunc': ((e) => this.changeDirectPutaway('include_line_replace')),
                                        }],
                                        "disabled": (this.state.putAwaycall || !this.props.selectedCustomer.options.trackLocation || this.props.sharedTotalLineItems == 0),
                                        "disabledText": "",
                                        "icon": "fa-location-arrow",
                                        "color": "green",
                                        'isContextMenu': false,
                                        'isButtonMenu': this.state.tplSettings.Receiving.DirectedPutAwayEnabled
                                    }
                                    , {
                                        "name": this.translate('Label_Delete'),
                                        "value": [],
                                        "disabled": this.state.selectedRows == 0 || (
                                            (GlobalService.GetCurrentUser().CustomerIds.length > 0) && renderReceiptObj.Completed
                                        ),
                                        "showToolTip": (
                                            (GlobalService.GetCurrentUser().CustomerIds.length > 0) && renderReceiptObj.Completed
                                        ),
                                        "tooltipText": this.translate('Label_Line_Item_Cutomer_Access_Note'),
                                        "icon": "fa-trash",
                                        "color": "red",
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        'wmsid': 'deleteLineItem',
                                        'wmsSelector': this.props.selector + "DeleteLineItem",
                                        'clickFunc': (e) => this.deleteLineItme(),
                                        'loading': this.state.gifDeleteLineItem
                                    }
                                ]
                            }
                        />
                    }
                    <WmsContainer className="totalWeightVolumeContainer">
                        <WmsInput
                            id='TotalWeight'
                            inputName="TotalWeight"
                            wmsSelector={this.props.selector + 'TotalWeight'}
                            value={this.state.totalWeight}
                            onChangeFunc={() => { }}
                            label={this.translate('Label_Total_Weight')}
                            disabled={true}
                        />
                        <WmsInput
                            id='TotalVolume'
                            inputName="TotalVolume"
                            wmsSelector={this.props.selector + 'TotalVolume'}
                            value={this.state.totalVolume}
                            onChangeFunc={() => { }}
                            label={this.translate('Label_Total_Volume')}
                            disabled={true}
                        />
                    </WmsContainer>

                    <WmsContainer title={this.translate("Label_Override_Receipts")}>
                        <div className="row">
                            <WmsInput
                                id='NumUnits1'
                                inputName="NumUnits1"
                                wmsSelector={this.props.selector + 'NumberOfPackagedUnits'}
                                value={renderReceiptObj.NumUnits1}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Number_Of_Packaged_Units_1')}
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                            />

                            <WmsDropdown
                                id="Unit1Identifier"
                                name="Unit1Identifier"
                                label={this.translate('Label_Unit_Of_Measure')}
                                wmsSelector={this.props.selector + "UnitOfMeasureForPackagedUnits"}
                                value={(
                                    renderReceiptObj.Unit1Identifier != null &&
                                    renderReceiptObj.Unit1Identifier.Name != 'Select a unit of measure'
                                ) ? renderReceiptObj.Unit1Identifier.Name : null}
                                required={
                                    (renderReceiptObj.NumUnits1 && (renderReceiptObj.Unit1Identifier == null || renderReceiptObj.Unit1Identifier.Name == 'Select a unit of measure')) ? true : false
                                }
                                onChangeFunc={this.handleChangeEvent}
                                options={this.props.sharedReceiptData.UnitOfMeasureTypes}
                                valueField="Name"
                                textField="Name"
                            />
                        </div>
                        <div className="row">
                            <WmsInput
                                id='NumUnits2'
                                inputName="NumUnits2"
                                wmsSelector={this.props.selector + 'NumberOfPalletized'}
                                value={renderReceiptObj.NumUnits2}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Number_of_Palletized')}
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                            />

                            <WmsDropdown
                                id="Unit2Identifier"
                                name="Unit2Identifier"
                                label={this.translate('Label_Unit_Of_Measure')}
                                wmsSelector={this.props.selector + "UnitOfMeasureForPalletized"}
                                value={(
                                    renderReceiptObj.Unit2Identifier != null &&
                                    renderReceiptObj.Unit2Identifier.Name != 'Select a unit of measure'
                                ) ? renderReceiptObj.Unit2Identifier.Name : null}
                                required={
                                    (renderReceiptObj.NumUnits2 && (renderReceiptObj.Unit2Identifier == null || renderReceiptObj.Unit2Identifier.Name == 'Select a unit of measure')) ? true : false
                                }
                                onChangeFunc={this.handleChangeEvent}
                                options={this.props.sharedReceiptData.UnitOfMeasureTypes}
                                valueField="Name"
                                textField="Name"
                            />
                        </div>
                    </WmsContainer>
                </WmsCollapse>


            </div>
        )
    }
}
