import React, { Component } from 'react'

//Components
import { FindReceiptSearch } from './findReceipt-search.component.jsx'
import { FindReceiptGrid } from './findReceipt-grid.component.jsx'
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import * as GridConfig from './grid_configuration.jsx';

import './findReceipt.scss';

//services
import * as GlobalService from './../../global_services';
import * as findReceiptService from './findReceipt.services.jsx';
import * as metaDataService from './meta-data.service';
var axios = require('axios');
export class FindReceiptHolder extends React.PureComponent {
    constructor(props) {
        super(props)

        this.$rootScope = window;
        this.translate = window.translate;
        var windowId = this.$rootScope.ChildBulletin.Data.Window;
        this.findReceiptGridId = "GridFindReceiversProto";
        this.defaultFilterValue = {
            IndexOptionsDateColumn: 0,
            IndexOptionsReferenceNum: 0,
            IndexOptionsReceiptAdviseNumber: 0,
            IndexOptionsPoNum: 0,
            IndexOptionsNotes: 0,
            IndexOptionsReceiptAdviseSent: 2,
            IndexOptionsStatus: 1,
            StartDate: null,
            EndDate: null,
            ReferenceNum: null,
            ReceiptAdviseNumber: null,
            PoNum: null,
            Notes: null,
            FirstTransaction: "",
            LastTransaction: "",
            ShowReferenceNumber: true,
            ShowTransactionRangeSection: true,
            CustomerId: 0,
            FacilityId: 0,
            gridInlineFilter:""
        };
        this.state = {
            selector: "FindReceipt",
            windowId: windowId,
            gridDomSelector: () => windowId ? '.' + windowId + ' #' + this.findReceiptGridId : '#' + this.findReceiptGridId,
            sharedReceiptFilter: Object.assign({}, this.defaultFilterValue),
            searchOnReceiving: false,
            filterObject: [],
            showChildComponents: false,
            isCollapsed: false,
            isAnimatedClassAdded:false
        }

    }
    async componentDidMount() {
        kendo.ui.progress($('#wms-findreceipt-wrapper'), true);
        await GlobalService.GetCustomersAndFacility();
        await metaDataService.getUserClientMetaData();

        var filterData = metaDataService.getFindReceiptFilterMetaData();
        var sharedReceiptFilter = Object.assign({}, this.defaultFilterValue);
        var sharedSearchOn = false;
        if (filterData != "") {
            sharedReceiptFilter = filterData;
            sharedSearchOn = true;
        }
        this.setState({
            sharedReceiptFilter: sharedReceiptFilter,
            sharedSearchOn: sharedSearchOn,
            showChildComponents:true
        });
        $('#wms-findreceipt-wrapper').closest('.k-window-content').css("padding", "0");
        kendo.ui.progress($('#wms-findreceipt-wrapper'), false);
    }

    componentWillUnmount() {
        $('#wms-findreceipt-wrapper').closest('.k-window-content').css("padding", "26");
    }

    onFilterDataSet = (param_filterObj) => {
        metaDataService.setFindReceiptFilterMetaData(param_filterObj);
        this.setState({
            sharedReceiptFilter: param_filterObj,
            sharedSearchOn: true
        });
    }
     
       
    onGridSearch = () => {

        let filterObj = this.state.sharedReceiptFilter;
        filterObj.method = "POST";
        filterObj.ShowReferenceNumber = true;

        delete filterObj.ShowNotes;
        delete filterObj.ShowStatus;
        delete filterObj.ShowPoNum;
        delete filterObj.ShowReceiptAdviseNumber;
        delete filterObj.ShowReceiptAdviseSent;
        delete filterObj.ShowReferenceNum;
        delete filterObj.ShowSearchDateSection;

        this.setState({
            sharedSearchOn: true,
            filterObject: [filterObj]
        });
    }
    onClearSearch = () => {
        metaDataService.clearFindReceiptFilterMetaData();
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        // reset selection of grid on filter data change
        if (typeof grid != "undefined") {
            grid.dataSource.filter([]);
            var gridColumn = grid.getOptions().columns;
            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            let finalSubmission = GlobalService.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
            this.setState({
                sharedReceiptFilter: Object.assign({}, this.defaultFilterValue),
                sharedSearchOn: false,
                gridInlineFilter:""
            });
            setTimeout(() => {
                metaDataService.setFindReceiptGridOptionInMetaData(finalSubmission);
            }, 500);
        }
    }

    // click on save search filter in meta data
    onManageFilter = (param_action, param_data) => {
        var filterData = this.state.sharedReceiptFilter;
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var filterArray = grid.dataSource.filter() ? grid.dataSource.filter() : [];
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = GlobalService.prepareGridOptionsForStorage(gridColumn, sortArray, filterArray);
        if (param_action == 'saveFilter') {
            metaDataService.saveFindReceiptFilterMetaData(param_data, JSON.parse(JSON.stringify(filterData)), finalSubmission);
        } else {
            metaDataService.updateFindReceiptFilterMetaData(param_data, JSON.parse(JSON.stringify(filterData)), finalSubmission);
            GlobalService.notification('success', this.translate('Order_Filter_Update_success'));
        }
    }
    onClickForFilterToggle = () => {
        this.setState({
            isCollapsed : !this.state.isCollapsed,
            isAnimatedClassAdded: !this.state.isAnimatedClassAdded,
        });
        // 
        setTimeout(() => {
            this.setState({
                isAnimatedClassAdded : !this.state.isAnimatedClassAdded
            })
        }, 1000);
    }
    onApplySavedFilter = (param_filterObj) => {

        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var dataSource = grid.dataSource;
        this.setState({
            sharedReceiptFilter: param_filterObj,
            sharedSearchOn: true,
            gridInlineFilter:dataSource.filter()
        });
        this.refs.findReceiptsGridVirtual.onApplySavedFilterData(param_filterObj);
    }
    render() {
        return (
            <div id="wms-findreceipt-wrapper" className={"new-layout-grid-filter-wrapper" + (this.state.isCollapsed ? ' collapsedFilter' : ' expandFilter') + (this.state.isAnimatedClassAdded ? " animatedCollapse" : "")}>
                {this.state.showChildComponents && (
                    <FindReceiptSearch
                        windowId={this.state.windowId}
                        sharedSearchOn={this.state.sharedSearchOn}
                        onFilterDataSet={this.onFilterDataSet}
                        onGridSearch={this.onGridSearch}
                        onApplySavedFilter={this.onApplySavedFilter}
                        selector={this.state.selector}
                        defaultFilterValue={this.defaultFilterValue}
                        onClearSearch={this.onClearSearch}
                        onManageFilter={this.onManageFilter}
                        onClickForFilterToggle={this.onClickForFilterToggle}
                    />
                )}
                {this.state.showChildComponents && (
                    <FindReceiptGrid
                        ref="findReceiptsGridVirtual"
                        selector={this.state.selector}
                        windowId={this.state.windowId}
                        sharedReceiptFilter={this.state.sharedReceiptFilter}
                        onFilterDataSet={this.onFilterDataSet}
                        gridInlineFilter={this.state.gridInlineFilter}
                    />
                )}
            </div>
        )
    }
}
