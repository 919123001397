import React, { Component } from 'react';
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsCheckBox from '../../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDateTimePicker from '../../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import * as GlobalService from './../../../global_services';

export class UpsEditComponent extends Component {
    constructor(props) {
        super(props)
        this.translate = window.translate;

        this.state = {
            pickupTypeOptions: []
        }
    }

    componentDidMount() {

        let optionsForSettings = GlobalService.getDropdownOptionsForSettings('UPS');

        this.setState({
            pickupTypeOptions: optionsForSettings.pickupType,
            IsDisabledUseNegotiatedRates: this.props.currentCarrierObj.UseNegotiatedRates,
            IsDisabledUseNegotiatInputs: true
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.currentCarrierObj.UseNegotiatedRates !== prevProps.currentCarrierObj.UseNegotiatedRates) {
            this.setState({
                IsDisabledUseNegotiatInputs: this.props.currentCarrierObj.UseNegotiatedRates == true ? false : true
            });
        }
    }

    render() {
        return (
            <div>

                <WmsCollapse
                    id={"UPSEditComponent"}
                    headerLabel={this.translate("Label_Company_Information")}
                    showCollapseText={false}
                    showCollapseIcon={false}>
                    <div className="account-info-section">

                        <WmsInput
                            id='nickname'
                            inputName='Nickname'
                            wmsSelector={this.props.selector + 'nickname'}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate('Label_Account_Nick_Name')}
                            extraWrapperClass="UPS-edit-nickname"
                            value={this.props.currentCarrierObj.Nickname} />
                        <div>
                            <WmsInput
                                id='accountNumber'
                                inputName='AccountNumber'
                                wmsSelector={this.props.selector + 'accountNumber'}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate('Label_Account_Number')}
                                extraWrapperClass="UPS-edit-accoutnumber"
                                value={this.props.currentCarrierObj.AccountNumber}
                                disabled={true} />

                            <WmsInput
                                id='accountPostalCode'
                                inputName='AccountPostalCode'
                                wmsSelector={this.props.selector + 'accountzip'}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate('Label_Account_Number_And_Postal_Code')}
                                value={this.props.currentCarrierObj.AccountPostalCode}
                                extraWrapperClass="UPS-edit-zip"
                            />

                            <WmsDropdown
                                id="pickupType"
                                name="PickupType"
                                label={this.translate('Label_Pickup_Type')}
                                wmsSelector={this.props.selector + "PickupType"}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                value={this.props.currentCarrierObj.PickupType}
                                options={this.state.pickupTypeOptions}
                                valueField='value'
                                textField='name'
                                extraWrapperClass="UPS-edit-pickup-type"
                                blankFirstOption={true}
                            />
                        </div>
                        <div className="chk-inline">
                            <WmsCheckBox
                                id={'useCarbonNeutralShippingProgram'}
                                name={"UseCarbonNeutralShippingProgram"}
                                wmsSelector={this.props.selector + 'UseCarbonNeutralShippingProgram'}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate('Label_Use_Carbon_Neutral_Shipping_Program')}
                                checked={this.props.currentCarrierObj.UseCarbonNeutralShippingProgram == true}
                                isRounded={true}
                            />

                            <WmsCheckBox
                                id={'useGroundFreightPricing'}
                                name={"UseGroundFreightPricing"}
                                wmsSelector={this.props.selector + 'UseGroundFreightPricing'}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate('Label_Use_Ground_Freight_Pricing')}
                                checked={this.props.currentCarrierObj.UseGroundFreightPricing == true}
                                isRounded={true}
                            />

                            <WmsCheckBox
                                id={'isPrimaryAccount'}
                                name={"IsPrimaryAccount"}
                                wmsSelector={this.props.selector + 'PrimaryAccount'}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate('Label_Primary_UPS_Account')}
                                checked={this.props.currentCarrierObj.IsPrimaryAccount == true}
                                isRounded={true}
                            />
                        </div>
                    </div>
                </WmsCollapse>

                <WmsCollapse
                    id={'carrierEditInvoiceInformation'}
                    headerLabel={this.translate("Label_Small_Parcel_Settings")}
                    showCollapseText={false}
                    showCollapseIcon={false} >
                    <div className="invoice-info-section">
                        <WmsCheckBox
                            id={'useNegotiatedRates'}
                            name={"UseNegotiatedRates"}
                            wmsSelector={this.props.selector + 'UseNegotiatedRates'}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate('Label_Use_Negotiated_Rates')}
                            checked={this.props.currentCarrierObj.UseNegotiatedRates == true}
                            isRounded={true}
                            disabled={this.state.IsDisabledUseNegotiatedRates}
                            parentClassName={this.state.IsDisabledUseNegotiatedRates ? "disabled-checkbox" : ""}
                        />
                    </div>
                </WmsCollapse>
            </div>
        )
    }
}
