
import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';
//components
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsGrid from './../components/WmsGrid/WmsGrid.jsx';
import { CloneExistingCustomerData } from './model/clone-existing-customer-data-model.component.jsx';
import * as GridConfig from './grid_configuration.jsx';

import * as customerService from './customers.services.jsx';
//services
import * as GlobalService from './../global_services';

export class CustomersGrid extends React.Component {

    constructor(props) {

        super(props);

        this.$rootScope = window;
        this.translate = window.translate;
        let FindCustomers_GrideHeaders = StringWms.convertSringToOption(this.translate('FindCustomers_GrideHeaders'));

        this.state = {
            columns: GridConfig.getGridDemoColumns(FindCustomers_GrideHeaders, GlobalService.getCustomLabels()).columns,
            transport: GridConfig.getGridDemoTransport().transport,
            schema: GridConfig.getGridDemoSchema().schema,
            CustomLabels: GlobalService.getCustomLabels(),
            rights: GlobalService.GetRights(),
            customerEditUri: null,
            searchOn: false,
            enableEditButton: false,
            enableActive: false,
            enableDeactive: false,
            localKendoGrid: null,
            Selector: 'Customer',
            optionConatins: StringWms.convertSringToOption(this.translate('Options_SearchBy_StringOptions')),
            booleanOption: StringWms.convertSringToOption(this.translate('Options_SearchBy_Status')),
            filterObject: [],
            isFilterDataSet: false,
            sharedIsExistingCustomerOpen: false,
            filter: {
                IndexOptionsName: 0,
                Name: '',
                IndexOptionsPrimaryWarehouse: 0,
                PrimaryWarehouse: '',
                IndexOptionsStatus: 0
            }
        };
    }

    componentDidMount() {

        kendo.ui.progress($('#' + this.props.windowId), true);
        customerService.searchInfoGet().then((response) => {
            if (response.data.Data != null) {
                this.setState({
                    searchOn: true,
                    filter: response.data.Data,
                    isFilterDataSet: true
                });
            }

        }).finally(() => {
            this.setState({ isFilterDataSet: true });
            kendo.ui.progress($('#' + this.props.windowId), false);
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.reloadGrid == 'reloadCustomerGrid') {

            $('#findCustomerGrid').data('kendoGrid').dataSource.read();
            if (prevState.localKendoGrid != null) {
                prevState.localKendoGrid.clearSelections(() => {
                    prevState.localKendoGrid.repaintSelections();
                });
            }

            return {
                enableEditButton: false,
                enableActive: false,
                enableDeactive: false
            };
        }
        return null;
    }

    selectRow = (KendoGrid) => {

        let enableEditButton = false;
        let enableActive = false;
        let enableDeactive = false;
        let customerEditUri = null;
        let currentSelections = KendoGrid.getNumberOfSelections();
        let isActive = KendoGrid.valueExists("Status", true);
        let isDeactive = KendoGrid.valueExists("Status", false);

        if (currentSelections == 1) {
            enableEditButton = true;
            customerEditUri = KendoGrid.getItem(KendoGrid.getKeys()).EditUri;
        }

        if (isActive == false  && currentSelections != 0) {
            enableActive = true;
        }

        if (isDeactive == false  && currentSelections != 0) {
            enableDeactive = true;
        }

        this.setState({
            enableEditButton: enableEditButton,
            enableActive: enableActive,
            enableDeactive: enableDeactive,
            customerEditUri: customerEditUri,
            localKendoGrid: KendoGrid
        });
    }

    clickEventOfContextMenu = (obj) => {

        if (obj.wmsid == 'edit') {
            this.onEditCustomer();
        } else if (obj.wmsid == 'activate') {
            this.activateDeactivateWarehouses(true);
        } else if (obj.wmsid == 'deactivate') {
            this.activateDeactivateWarehouses(false);
        } else if (obj.wmsid == 'cloneCustomer') {
            this.onOpenCloneExstingCustomer();
        }

    }

    onEditCustomer = () => {
        this.props.onEditCustomer(this.state.customerEditUri);
    }
    onOpenCloneExstingCustomer =  () => {
        this.setState({ sharedIsExistingCustomerOpen: true });
    }
    onCloseCloneExstingCustomer =  () => {
        this.setState({ sharedIsExistingCustomerOpen: false });
    }
    onCloneCustomer = (param_selected_customer_Settings) => {

        this.setState({ sharedIsExistingCustomerOpen: false });
        this.props.onCloneCustomer(this.state.customerEditUri,param_selected_customer_Settings);
    }

    activateDeactivateWarehouses = (mybool) => {

        let selectionDataNumber = this.state.localKendoGrid.getKeys().map(function (item) {
            return parseInt(item, 10);
        });

        let msgType = 'label_Customer_Activate';
        if (mybool == false) {
            msgType = 'label_Customer_Deactivate';
        }
        let msgNotification = this.translate(msgType);

        kendo.ui.progress($('#findCustomerGrid'), true);
        customerService.updateCustomerActivation(selectionDataNumber, mybool).then((response) => {
            if (typeof response != "undefined" && (response.status == 204 || response.status == 200)) {
                kendo.ui.progress($('#findCustomerGrid'), false);
                this.resetSelectionAndButton();
                GlobalService.notification('success', this.translate(msgNotification));
                GlobalService.GetCustomersAndFacility();
            }

        }).finally(() => {
            kendo.ui.progress($('#findCustomerGrid'), false);
        });
    }

    resetSelectionAndButton = () => {
        $('#findCustomerGrid').data('kendoGrid').dataSource.read();
        if (this.state.localKendoGrid != null) {
            this.state.localKendoGrid.clearSelections(() => {
                this.state.localKendoGrid.repaintSelections();
            });
        }
        this.setState({
            enableEditButton: false,
            enableActive: false,
            enableDeactive: false
        });
    }
    filterHandleChange = (event) => {
        this.setState({
            filter: Object.assign(
                {},
                this.state.filter,
                { [event.target.name]: event.target.value }
            ),
        });
    }

    gridSearch = () => {
            let dataArr = [{
                ShowName: true,
                IndexOptionsName: this.state.filter.IndexOptionsName,
                Name: this.state.filter.Name,
                ShowStatus: true,
                IndexOptionsStatus: this.state.filter.IndexOptionsStatus,
                ShowPrimaryWarehouse: true,
                IndexOptionsPrimaryWarehouse: this.state.filter.IndexOptionsPrimaryWarehouse,
                PrimaryWarehouse: this.state.filter.PrimaryWarehouse || null
            }];
            this.setState({
                searchOn: true,
                filterObject: dataArr
            });
    }

    clearFilters = () => {
        // set default value of filter form
        this.setState({
            filter: {
                "IndexOptionsName": 0,
                "Name": "",
                "IndexOptionsStatus": 0,
                "IndexOptionsPrimaryWarehouse": 0,
                "PrimaryWarehouse": ""
            },
            searchOn: false,
            filterObject: [{ method: 'DELETE' }]
        });
    }
    onDataBound = () =>{
        // logic for set endtime for calculate loaderTimer
        this.$rootScope.setLoaderTimerEndTime("Manage Customers");
    }
    render() {
        return (
            <div className="customers-grid">
                <div className="topbtn-holder">
                    <div>
                <WmsButton
                    label={this.translate('Label_Search')}
                    wmsSelector={this.state.Selector + 'GridSearchButton'}
                    onClickFunc={this.gridSearch}
                    icon='fa-search' />
                <WmsButton
                    label={this.translate('Label_Clear_Filters')}
                    buttonType='orange'
                    wmsSelector={this.state.Selector + 'ClearSearchButton'}
                    onClickFunc={this.clearFilters}
                    icon='fa-minus' />
                    </div>
                    {this.state.searchOn == true && (
                        <div className="search-applied" >
                            <label>{this.translate('Label_Search_Applied_Filter')}</label>
                        </div>
                    )}
                </div>

                <WmsCollapse
                    id={'collapseCustomerFilter'}
                    headerLabel={this.translate("Label_Search_Criteria")}
                    showCollapseText={false}
                    showCollapseIcon={false} >

                <div className="wms-input-group-wrapper">
                            <WmsDropdown
                                id="IndexOptionsName"
                                name="IndexOptionsName"
                                label={this.translate('Label_Warehouse_Contact_Name')}
                                wmsSelector={this.state.Selector + 'indexOptionsName'}
                                value={this.state.filter.IndexOptionsName}
                                onChangeFunc={this.filterHandleChange}
                                options={this.state.optionConatins}
                                valueField='value'
                                textField='description'
                                parentClassName="select_box"/>

                            <WmsInput
                                inputName="Name"
                                name="Name"
                                wmsSelector={this.state.Selector + 'FilterCustomerName'}
                                value={this.state.filter.Name}
                                onChangeFunc={this.filterHandleChange}
                                label={''}
                                parentClassName="input_box" />
                        </div>

                        <div className="wms-input-group-wrapper">
                            <WmsDropdown
                                id="IndexOptionsPrimaryWarehouse"
                                name="IndexOptionsPrimaryWarehouse"
                                label={'Primary ' + this.state.CustomLabels.Label_Warehouse}
                                wmsSelector="indexOptionsIsActive"
                                value={this.state.filter.IndexOptionsPrimaryWarehouse}
                                onChangeFunc={this.filterHandleChange}
                                options={this.state.optionConatins}
                                valueField='value'
                                textField='description'
                                parentClassName="select_box"/>

                            <WmsInput
                                inputName="PrimaryWarehouse"
                                Name="PrimaryWarehouse"
                                wmsSelector={'primaryWarehouse'}
                                value={this.state.filter.PrimaryWarehouse}
                                onChangeFunc={this.filterHandleChange}
                                label={''}
                                parentClassName="input_box" />
                        </div>

                        <WmsDropdown
                            id="IndexOptionsStatus"
                            name="IndexOptionsStatus"
                            label={this.translate('Label_Filter_Status')}
                            wmsSelector={'indexOptionsStatus'}
                            value={this.state.filter.IndexOptionsStatus}
                            onChangeFunc={this.filterHandleChange}
                            options={this.state.booleanOption}
                            valueField='value'
                            textField='description'/>

                </WmsCollapse>

                    {!this.state.rights.hasOwnProperty('customer.manage.create') &&
                    <label className="mail-content">
                        Adding a customer to your environment might incur additional monthly fees. For more information, contact your Customer Success Manager (CSM) or 3PL Central Support (support@3plcentral.com).
                    </label>
                    }
                    {/* for manage window loader initialise grid after filter api call */}
                        {this.state.isFilterDataSet == true &&
                        <WmsGrid
                            wmsSelector={'findCustomerGrid'}
                            gridKeySelection={'CustomerId'}
                            gridName={'GridFindCustomers'}
                            virtual={true}
                            id={'findCustomerGrid'}
                            columns={this.state.columns}
                            schema={this.state.schema}
                            transport={this.state.transport}
                            showGridSummary={true}
                            windowId={this.props.windowId}
                            onSelection={this.selectRow}
                            clickEventOfContextMenu={this.clickEventOfContextMenu}
                            filterObject={this.state.filterObject}
                            inlineFilter={false}
                            onDataBound={this.onDataBound}
                            showErrorMessageSection={true}
                            contextMenuFilter='tbody tr:not(.k-grid-edit-row)'
                            getGridSelectionUrl={'/WebUI/Customers/ManageCustomers/GridSelections'}
                            searchUrl={'/WebUI/Customers/UpdateSession/searchInfo'}
                            resetButtonLabel={this.translate('Label_Costomer_Options')}
                            menu={
                                [{
                                    "name": "Create",
                                    "value": [{
                                        "name": this.translate('Label_Create_New_Customer'),
                                        "value": [],
                                        "disabled": false,
                                        "icon": "fa-pencil",
                                        "color": "orange",
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        'wmsid': 'create',
                                        'wmsSelector': this.state.Selector + "CreateButton",
                                        'onClickFunc': this.props.onCreateCustomer
                                    },{
                                        "name": this.translate('Label_Clone_Customer'),
                                        "value": [],
                                        "disabled": !this.state.enableEditButton,
                                        "disabledText": this.translate('note_Clone_Customer'),
                                        "icon": "fa-bandcamp",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        'wmsid': 'cloneCustomer',
                                        'wmsSelector': this.state.Selector + "CloneCustomer",
                                        'onClickFunc': this.onOpenCloneExstingCustomer,
                                    }],
                                    "disabled": false,
                                    "icon": "fa-plus",
                                    "color": "green",
                                    'isContextMenu': false,
                                    'isButtonMenu': this.state.rights.hasOwnProperty('customer.manage.create'),
                                    'wmsSelector': this.state.Selector + "CreateMenu",
                                    'wmsid': 'create',
                                },
                                    {
                                        "name": "Edit",
                                        "value": [],
                                        "disabled": !this.state.enableEditButton,
                                        "icon": "fa-pencil",
                                        "color": "outlined",
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        'wmsid': 'edit',
                                        'wmsSelector': this.state.Selector + "Edit",
                                        'clickFunc': this.onEditCustomer,
                                    },
                                    {
                                        "name": "Manage",
                                        "value": [{
                                            "name": "Activate",
                                            "value": [],
                                            "disabled": !this.state.enableActive,
                                            "disabledText": this.translate('note_Customer_activate'),
                                            "icon": "fa-bandcamp",
                                            "color": "green",
                                            'isContextMenu': true,
                                            'isButtonMenu': true,
                                            'wmsid': 'activate',
                                            'wmsSelector': this.state.Selector + "Activate",
                                            'onClickFunc': ((e) => this.activateDeactivateWarehouses(true)),
                                        }, {
                                            "name": this.translate('Label_Deactivate'),
                                            "value": [],
                                            "disabled": !this.state.enableDeactive,
                                            "disabledText": this.translate('note_Customer_deactivae'),
                                            "icon": "fa-wrench",
                                            "color": "green",
                                            'isContextMenu': true,
                                            'isButtonMenu': true,
                                            'wmsid': 'deactivate',
                                            'wmsSelector': this.state.Selector + "Deactivate",
                                            'onClickFunc': ((e) => this.activateDeactivateWarehouses(false)),
                                        }],
                                        "disabled": false,
                                        "disabledText": "You may not add blahssss0",
                                        "icon": "fa-wrench",
                                        "color": "outlined",
                                        'isContextMenu': true,
                                        'isButtonMenu': true
                                    }, {
                                    "name": "Copy",
                                    "value": [{
                                        "name": this.translate('Label_Copy_Field'),
                                        "value": [],
                                        "disabled": false,
                                        "disabledText": this.translate('Label_Copy_Field'),
                                        "icon": "fa-bandcamp",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': false,
                                        'wmsid': 'copyField'
                                    }, {
                                        "name": this.translate('Label_Copy_Row'),
                                        "value": [],
                                        "disabled": false,
                                        "disabledText": this.translate('Label_Copy_Row'),
                                        "icon": "fa-wrench",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': false,
                                        'wmsid': 'copyRow'
                                    }],
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false
                                }]
                            }>
                        </WmsGrid>
                        }
                        {this.state.sharedIsExistingCustomerOpen && (
                            <CloneExistingCustomerData
                            sharedIsExistingCustomerOpen={this.state.sharedIsExistingCustomerOpen}
                            onCloseCloneExstingCustomer={this.onCloseCloneExstingCustomer}
                            onCloneCustomer={this.onCloneCustomer}/>
                        )}
                    </div>
        );
    }
}