import React, { Component } from 'react';
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsContainer from '../../../components/WmsContainer/WmsContainer.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';

import './add-new-custom-field-modal.scss';

import * as GlobalService from './../../../global_services';

export class AddNewCustomFieldModal extends Component {
    constructor(props) {
        super(props);

        
        this.translate = window.translate;

        this.state = {
            selector:'ReceiptCustomField',
            customField: {
                Name: '',
                Value: '',
                Required: false
            }
        };
    }

    handleChange = (event) => {
        this.setState({
            customField: Object.assign(
                this.state.customField,
                {
                    [event.target.name]: event.target.value
                }
            )
        });
    }

    addCustomField = () => {

        if (this.state.customField.Name == '') {

            GlobalService.notification("error", this.translate('Label_Unique_Value_Required') + ':', this.translate('Label_Name'));

        } else {

            let savedElementsData = Object.assign([], this.props.sharedReceiptData.ReceiptSE);

            // check if name exist in saved element
            let index = savedElementsData.filter((el) => {
                return el['Name'].toLowerCase() == this.state.customField.Name.toLowerCase();
            });
            if (index.length > 0) {
                GlobalService.notification("error", this.translate('Label_Unique_Value_Required') + ': ', this.translate('Label_Name'));
                return;
            }
            savedElementsData.unshift(this.state.customField);

            let ReceiptData = this.props.sharedReceiptData;
            ReceiptData.ReceiptSE = savedElementsData;
            this.props.onReceiptOptionSet(ReceiptData);
            this.props.onCloseCustomFieldModal();
        }
    }



    render() {
        return (
            <div>
                <WmsModel
                    id={'manageReceiptAddNewCustomField'}
                    title={this.translate('Label_custom_field')}
                    width={400}
                    height={288}
                    onCloseFunc={this.props.onCloseCustomFieldModal}
                    isOpen={this.props.sharedIsOpenAddNewCustomFieldModal}
                    windowId={this.props.windowId}
                    customClass='wms-model-new-ui-wrapper'
                >

                    <div className='model-content-wrapper'>
                    <WmsContainer>
                        <WmsInput
                            id='Name'
                            inputName="Name"
                            wmsSelector={this.state.selector + 'Name'}
                            value={this.state.customField.Name}
                            onChangeFunc={this.handleChange}
                            required={true}
                            label={this.translate('Label_Name')}
                        />

                        <WmsInput
                            id='Value'
                            inputName="Value"
                            wmsSelector={this.state.selector + 'Value'}
                            value={this.state.customField.Value}
                            onChangeFunc={this.handleChange}
                            label={this.translate('Label_Value')}
                        />
                    </WmsContainer>
                    </div>
                    <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Cancel')}
                        wmsSelector={this.state.selector + 'CancelButton'}
                        onClickFunc={this.props.onCloseCustomFieldModal}
                        icon='fa-ban'
                        buttonType="orange"
                    />
                    <WmsButton
                        label={this.translate('Label_Add')}
                        wmsSelector={this.state.selector + 'AddButton'}
                        onClickFunc={this.addCustomField}
                        icon={'fa-plus'}
                        buttonType="green"
                        disabled={this.state.customField.Name == ''}
                    />
                    </div>
                </WmsModel>
            </div>
        );
    }
}
