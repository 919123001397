import React from 'react';
import { StringWms } from './../../../../../assets/ts/StringWms.ts';
import { ObjectWms } from './../../../../../assets/ts/ObjectWms.ts';

//component
import WmsButton from './../../../components/WmsButton/WmsButton.jsx';
import WmsGrid from './../../../components/WmsGrid/WmsGrid.jsx';
import WmsCollapse from './../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsDropdown from './../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsInput from './../../../components/WmsInput/WmsInput.jsx';
import WmsDateTimePicker from './../../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import WmsModel from './../../../components/WmsModel/WmsModel.jsx';

import * as GlobalService from './../../../global_services';
import * as findOrdersService from '../findOrders.services.jsx';
import './mass-ship-and-close-model.scss';

export class MassShipAndCloseModel extends React.Component {

    constructor(props) {

        super(props);

        this.$rootScope = window;
        this.translate = window.translate;
        let GridTitlesAggregate = StringWms.convertSringToOption(this.translate('Massship_Andclose_Grid_Headers'));
        this.defauleManualCharge = {
            indexChargeCategory: 0,
            UnitDescription: '',
            ChargePerUnit: 0,
            NumberOfUnits: 0,
            total: '0',
            GeneralLedgerAccount: '',
            ExternalAccountsReceivable: '',
            ExternalItemIdentifier: '',
            ExternalItemDescription: ''
        };
        this.state = {
            Rights: GlobalService.GetRights(),
            selector: "Order",
            indexChargeType: 0,
            shipAndCloseGridData: [],
            chargeTypeOption: [],
            originalChargeTypeOption: [],
            shipAndCloseFormData: null,
            sharedIsOpenManualChargeModal: false,
            chargeOperationMsg: '',
            sharedManualChargeOptions: [],
            closeOrderShipDate: kendo.toString(kendo.parseDate(new Date()), 'yyyy-MM-ddTHH:mm:ss'),
            gridMassShipChargesTotal: 0,
            currentRowIndex: 0,
            applyChargeOpt: [{
                name: this.translate("Label_Order_Apply_Charge"),
                value: '0'
            }, {
                name: this.translate("Label_Order_Divide_charges"),
                value: '1'
            }],
            rowNumberSet: false,
            manualCharges: Object.assign({}, this.defauleManualCharge),
            shipAndCloseGridColumns: [{
                field: "RowNumber",
                title: "#",
                hidden: true,
                menu: false,
                width: "100%"
            }, {
                field: "ChargeOrigin",
                title: GridTitlesAggregate[0].description,
                lockable: false,
                filterable: false,
                width: 100,
                template: "# if( ChargeOrigin== 1) {# " + this.translate('Label_Billing_Script') + "  #} else if( ChargeOrigin== 2) {# " + this.translate('Label_Manual_Charge') + " #} else if( ChargeOrigin== 3 || ChargeOrigin == 0) {# " + this.translate('Label_Predefined') + " #} #"
            }, {
                field: "ChargeType",
                title: GridTitlesAggregate[1].description,
                width: 100,
                lockable: false
            }, {
                field: "ChargeLabel",
                title: GridTitlesAggregate[2].description,
                width: 150,
                lockable: false
            }, {
                field: "UnitDescription",
                title: GridTitlesAggregate[3].description,
                width: 180,
                lockable: false,
                hidden: true
            }, {
                field: "ChargePerUnit",
                title: GridTitlesAggregate[4].description,
                width: 90,
                lockable: false
            }, {
                field: "NumberOfUnits",
                title: GridTitlesAggregate[5].description,
                width: 80,
                attributes: {
                    "class": "editable-cell"
                },
                lockable: false
            }, {
                field: "TotalCharges",
                title: GridTitlesAggregate[6].description,
                width: 100,
                lockable: false
            }, {
                field: "GeneralLedgerAccount",
                title: GridTitlesAggregate[7].description,
                width: 180,
                lockable: false,
                hidden: true
            }, {
                field: "ExternalAccountsReceivable",
                title: GridTitlesAggregate[8].description,
                width: 180,
                lockable: false,
                hidden: true
            }, {
                field: "ExternalItemIdentifier",
                title: GridTitlesAggregate[9].description,
                width: 180,
                lockable: false,
                hidden: true
            }, {
                field: "ExternalItemDescription",
                title: GridTitlesAggregate[10].description,
                width: 180,
                lockable: false,
                hidden: true
            }, {
                field: 'cmdRemove',
                menu: false,
                command: [{
                    name: "destroy",
                    text: " ",
                    className: "charge-delete"
                }],
                title: "&nbsp;",
                width: 32,
            }],
            schema: {
                model: {
                    fields: {
                        RowNumber: {
                            type: 'number'
                        },
                        ChargeOrigin: {
                            type: "number",
                            editable: false
                        },
                        ChargeTypeId: {
                            type: "string",
                            editable: false
                        },
                        ChargeType: {
                            type: "string",
                            editable: false
                        },
                        ChargeLabel: {
                            type: "string",
                            editable: false
                        },
                        UnitDescription: {
                            type: "string",
                            editable: false
                        },
                        ChargePerUnit: {
                            type: "number",
                            editable: false
                        },
                        NumberOfUnits: {
                            type: "number",
                            editable: true
                        },
                        TotalCharges: {
                            type: "number",
                            editable: false
                        },
                        GeneralLedgerAccount: {
                            type: "float",
                            editable: false
                        },
                        ExternalAccountsReceivable: {
                            type: "string",
                            editable: false
                        },
                        ExternalItemIdentifier: {
                            type: "string",
                            editable: false
                        },
                        ExternalItemDescription: {
                            type: "string",
                            editable: false
                        }
                    }
                }
            }
        }
    }
    componentDidMount() {
        kendo.ui.progress($('#massShipAndClose'), true);

        findOrdersService.initialiseMassShipAndCloseModel(
            this.props.sharedSelectedOrderData.CustomerId,
            this.props.sharedSelectedOrderIds,
            this.props.sharedSelectedOrderData.FacilityId
        ).then((response) => {

            let shipAndCloseFormData = [];
            let chargeTypeOption = [];
            let manualChargesOptions = [];

            chargeTypeOption.unshift({ "ChargeLabel": "Custom Manual Charge" });
            var gridCharges = [];
            if (response.massShipChargesGridData != 'undefined' && response.massShipChargesGridData.status == 200) {
                gridCharges = response.massShipChargesGridData.data;
                // var index = 0;
                // for (var i = 0; i < chargeTypeOption.length; i++) {
                //     var firstItem = chargeTypeOption[i];
                //     index++;
                //     firstItem["uniqueId"] = index;
                // }
            }

            if (response.chargesCategoryResponse != 'undefined' && response.chargesCategoryResponse.status == 200) {
                manualChargesOptions = response.chargesCategoryResponse.data;
            }

            if (response.massShipAndCloseResponse != 'undefined' && response.massShipAndCloseResponse.status == 200) {
                shipAndCloseFormData = this.translateFormData(response.massShipAndCloseResponse.data.FormData);
            }

            this.setState({
                shipAndCloseFormData: shipAndCloseFormData,
                chargeTypeOption: chargeTypeOption,
                originalChargeTypeOption: chargeTypeOption,
                sharedManualChargeOptions: manualChargesOptions,
                rowNumberSet: false,
                shipAndCloseGridData: gridCharges,
                gridMassShipChargesTotal: 0,
                manualCharges: this.defauleManualCharge
            }, () => {
                this.rowNumberUpdate();
            });

        }).finally(() => {
            kendo.ui.progress($('#massShipAndClose'), false);
        });
    }

    translateFormData = (param_shipandclose_formdata) => {

        for (let prop in param_shipandclose_formdata) {
            if (param_shipandclose_formdata[prop].State === 1) {
                let multiplesText = this.translate('Label_Multiples_Text');
                param_shipandclose_formdata[prop].Value = multiplesText;
                if (prop == 'PickupDate') {
                    $("#massShipPickUpDate").attr('placeholder', multiplesText);
                }
            } else if (param_shipandclose_formdata[prop].State === 0) {
                param_shipandclose_formdata[prop].Value = '';
            }
        }
        this.setState({ origFormData: JSON.parse(JSON.stringify(param_shipandclose_formdata)) });
        return param_shipandclose_formdata;

    }

    handleChangeEvent = (event) => {

        let shipAndCloseFormObj = this.state.shipAndCloseFormData;

        if (event.target.name == 'ApplyChargesToEachOrder') {
            shipAndCloseFormObj[event.target.name] = (event.target.value == '0') ? true : false;
        } else {
            shipAndCloseFormObj[event.target.name].Value = event.target.value;
        }

        this.setState({ shipAndCloseFormData: shipAndCloseFormObj });

    }

    onChangeChargeType = (event) => {
        let manualChargeObj = Object.assign({}, this.defauleManualCharge);
        if (event.target.value != 0) {
            manualChargeObj = this.state.chargeTypeOption[event.target.value];
            var getIndex = ObjectWms.FindIndexByProperty(this.state.sharedManualChargeOptions, "Id", manualChargeObj.ChargeTypeId);
            manualChargeObj.indexChargeCategory = getIndex;
        }
        this.setState({
            'indexChargeType': event.target.value,
            manualCharges: manualChargeObj
        });
    }
    changeAdditaionalCharge = (event) => {
        let manualCharges = Object.assign({}, this.state.manualCharges);
        manualCharges[event.target.name] = event.target.value

        //calculate total charges
        if (event.target.name == 'ChargePerUnit' || event.target.name == 'NumberOfUnits') {

            let param_chargePerUnit = (event.target.name == 'ChargePerUnit') ?
                event.target.value :
                this.state.manualCharges.ChargePerUnit;

            let param_numberOfUnits = (event.target.name == 'NumberOfUnits') ?
                event.target.value :
                this.state.manualCharges.NumberOfUnits;
            if (
                !isNaN(parseFloat(param_chargePerUnit)) &&
                !isNaN(parseFloat(param_numberOfUnits))
            ) {
                manualCharges['total'] = parseFloat((new Big(param_chargePerUnit || 0)).times(param_numberOfUnits || 0).toFixed(4));
            }
        }

        this.setState({ 'manualCharges': manualCharges });
    }

    changeCloseShipDate = (event) => {
        this.setState({ 'closeOrderShipDate': event.target.value });
    }

    openAddManualChargeModel = () => {
        this.setState({ sharedIsOpenManualChargeModal: true });
    }

    onCloseAddManualChargeModel = () => {
        this.setState({ sharedIsOpenManualChargeModal: false });
    }

    addShipAndCloseGridData = () => {
        var gridObj = {
            'ChargeOrigin': (this.state.indexChargeType == 0) ? 2 : 3,
            'ChargeTypeId': this.state.sharedManualChargeOptions[this.state.manualCharges.indexChargeCategory].Id,
            'ChargeType': this.state.sharedManualChargeOptions[this.state.manualCharges.indexChargeCategory].Description,
            'ChargeLabel': this.state.manualCharges.ChargeLabel,
            'Description': this.state.manualCharges.UnitDescription,
            'ChargePerUnit': this.state.manualCharges.ChargePerUnit,
            'NumberOfUnits': this.state.manualCharges.NumberOfUnits,
            'TotalCharges': this.state.manualCharges.total,
            'GeneralLedgerAccount': this.state.manualCharges.GeneralLedgerAccount,
            'ExternalAccountsReceivable': this.state.manualCharges.ExternalAccountsReceivable,
            'ExternalItemIdentifier': this.state.manualCharges.ExternalItemIdentifier,
            'ExternalItemDescription': this.state.manualCharges.ExternalItemDescription,
            'uniqueId': this.state.chargeTypeOption[this.state.indexChargeType].uniqueId
        };

        let shipAndCloseGridData = _.cloneDeep(this.state.shipAndCloseGridData);
        shipAndCloseGridData.push(gridObj);

        let tmpUniqueId = this.state.chargeTypeOption[this.state.indexChargeType].uniqueId;
        let cahrgeTypeOpt = this.state.chargeTypeOption;
        if (tmpUniqueId != 1) {
            cahrgeTypeOpt = ObjectWms.removeObjFromListOnParameterValue(this.state.chargeTypeOption, "uniqueId", tmpUniqueId);
        }

        this.setState({
            shipAndCloseGridData: Object.assign([], shipAndCloseGridData),
            rowNumberSet: false,
            manualCharges: Object.assign({}, this.defauleManualCharge),
            chargeOperationMsg: this.translate('Oders_FindOrders_MassShip_Charge_Add_Success'),
            chargeTypeOption: cahrgeTypeOpt
        }, () => {

            this.rowNumberUpdate();
            this.onChangeChargeType({ target: { value: 0 } });
            setTimeout(() => {
                this.setState({ chargeOperationMsg: '' });
            }, 5000);

        });

        this.sumOfTotalChargesColumn(shipAndCloseGridData);

    }
    onRemove = (e) => {
        // use time out to reflect he grid datasource changes
        setTimeout(() => {
            var grid = $('#GridMassShipCharges').data("kendoGrid");

            // Passing test here to remove default propery from data
            var data = ObjectWms.removeObjFromListOnParameterValue(grid.dataSource.data(), "test", null);
            let tmpChargeTypeOption = Object.assign([], this.state.chargeTypeOption);

            if (e.model.uniqueId != 1) {
                var getIndex = ObjectWms.FindIndexByProperty(this.state.originalChargeTypeOption, "uniqueId", e.model.uniqueId);
                let tmpRemovedChargeObj = this.state.originalChargeTypeOption[getIndex];
                tmpChargeTypeOption.push(tmpRemovedChargeObj);
            }

            this.sumOfTotalChargesColumn(data);
            this.setState({
                shipAndCloseGridData: data,
                chargeOperationMsg: this.translate('Oders_FindOrders_MassShip_Charge_Remove_Success'),
                chargeTypeOption: tmpChargeTypeOption
            }, () => {
                setTimeout(() => { this.setState({ chargeOperationMsg: '' }); }, 5000);
            });
        }, 100);

    }
    sumOfTotalChargesColumn = (param_gridData) => {

        let toatalOfCharge = 0;
        for (let x = 0; x < param_gridData.length; x++) {
            let dataItem = param_gridData[x];
            toatalOfCharge += dataItem['TotalCharges'];
        }
        this.setState({ gridMassShipChargesTotal: toatalOfCharge });
    }


    clickShipAndClose = () => {

        let shipAndCloseFormObj = {
            'FormData': this.detectChangesFromOrig(),
            'ApplyChargesToEachOrder': this.state.shipAndCloseFormData.ApplyChargesToEachOrder,
            'CloseDate': this.state.closeOrderShipDate,
            'OrigFormData': this.state.origFormData,
            'ids': this.props.sharedSelectedOrderIds,
            'SelectedCustomerId': this.props.sharedSelectedOrderData.CustomerId,
            'SelectedCustomerUri': this.props.sharedSelectedOrderData.CustomerUri,
        }
        this.props.onCloseShipAndClose()
        this.$rootScope.animationMoveIcon($('#shipAndCloseSAve'));
        this.$rootScope.taskStarted();
        // this.$rootScope.$broadcast("EventProgressMassShipAndClose", {
        //     "MassShip": shipAndCloseFormObj,
        //     "callBackFunc": this.props.onResetGridSelection
        // });

        var event = new CustomEvent("showProgressModal", {
            detail: {
                "MassShip": shipAndCloseFormObj,
                "callBackFunc": this.props.onResetGridSelection,
                "type": "massship"
            }
        });
        document.dispatchEvent(event);

    }
    detectChangesFromOrig = () => {

        let shipAndCloseFormObj = this.state.shipAndCloseFormData;
        for (let objProperty in shipAndCloseFormObj) {
            if (shipAndCloseFormObj[objProperty].Value !== this.state.origFormData[objProperty].Value) {
                shipAndCloseFormObj[objProperty].HasChanged = true;
            }
        }
        var currentGridData = Object.assign([], this.state.shipAndCloseGridData);
        // Remove if currentGridData is 0 or null
        _.remove(currentGridData, function (n) {
            return (n.NumberOfUnits == 0 || n.NumberOfUnits == null);
        });
        if (shipAndCloseFormObj) {
            shipAndCloseFormObj['Charges'] = currentGridData;
        }
        return shipAndCloseFormObj;

    }
    saveMassShipCharges = (e) => {
        var grid = $('#GridMassShipCharges').data('kendoGrid');

        var numberOfUnits = StringWms.isEmpty(e.values.NumberOfUnits) ? 0 : e.values.NumberOfUnits;
        var chargePerUnit = StringWms.isEmpty(e.model.ChargePerUnit) ? 0 : e.model.ChargePerUnit;

        var total = parseFloat((new Big(numberOfUnits)).times(chargePerUnit).round(4));

        e.model.set("TotalCharges", total);
        e.model.TotalCharges = total;
        // grid.saveChanges();

        // Setting a timeout so that NumberOfUnits gets updated by gridSaveChanges
        setTimeout(() => {
            let shipChargesData = grid._data;
            shipChargesData = ObjectWms.removeObjFromListOnParameterValue(shipChargesData, "test", null);

            this.setState({ shipAndCloseGridData: shipChargesData });
            this.sumOfTotalChargesColumn(shipChargesData);
        }, 50);
    }
    onGridEdit = (e) => {
        var rowindex = $(e.container).closest("tr").index();
        this.state.currentRowIndex = rowindex;
    }
    onDataBound = (e) => {
        if (this.state.rowNumberSet == false) {
            this.rowNumberUpdate();
        }
        var that = this;
        $(document).off('keyup', '#GridMassShipCharges .k-grid-content table');
        $(document).on('keyup', '#GridMassShipCharges .k-grid-content table', function (e) {
            if (e.keyCode === kendo.keys.TAB || e.keyCode === kendo.keys.ENTER) {
                e.preventDefault();
                e.stopPropagation();
                var grid = $("#GridMassShipCharges").data('kendoGrid');
                setTimeout(() => {
                    var nextCell;
                    var destinationRow;

                    if (e.shiftKey) {
                        destinationRow = that.state.currentRowIndex - 1;
                    } else {
                        destinationRow = that.state.currentRowIndex + 1;
                    }

                    var indexRow = destinationRow;
                    nextCell = $("#GridMassShipCharges .k-grid-content tbody tr:eq(" + indexRow + ")").children(".editable-cell:first");
                    grid.current(nextCell);
                    grid.editCell(nextCell[0]);
                }, 100);
            }
        });

        var grid = $("#GridMassShipCharges").data('kendoGrid');

        var gridData = grid.dataSource.view();
        for (var i = 0; i < gridData.length; i++) {
            if (gridData[i].ChargeOrigin != 2) {
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("hide-close-button");
            }
        }
    }
    rowNumberUpdate = () => {
        var grid = $('#GridMassShipCharges').data("kendoGrid");
        var dataSource = grid.dataSource;
        var data = dataSource.data();
        var sort = dataSource.sort();
        var query = new kendo.data.Query(data);
        var sortedData = query.data;
        var index = 0;
        for (var i = 0; i < sortedData.length; i++) {
            var firstItem = sortedData[i];
            index++;
            firstItem["RowNumber"] = index;
        }
        this.state.rowNumberSet = true;
        $('#GridMassShipCharges').data("kendoGrid").refresh();
    }
    onCollapseIconClick = (param_collapse) => {
        if (!param_collapse) {
            // use setTimeout to wait until tab expand animation complete, then scrooll div to top
            setTimeout(() => {
                $(".model-content-wrapper").animate({
                    scrollTop: $("#additonalChargesRoutingInfo_main").position().top
                }, 'slow');
                $('#GridMassShipCharges').data("kendoGrid").resize(true);
            }, 500);
        }
    }

    render() {
        return (
            <WmsModel
                id='massShipAndClose'
                title={`${this.translate('Label_Mass_Ship_And_Close')} <span class="tooltipfortitle" data-tooltip-text="${this.props.sharedSelectedOrderIds.join(", ")}">(${this.props.sharedSelectedOrderIds.length} ORDERS)</span>`}
                isShowTooltipOnTitle={true}
                width={800}
                height={750}
                onCloseFunc={this.props.onCloseShipAndClose}
                isOpen={this.props.sharedIsOpenMassShipAndCloseModel}
                customClass='wms-model-new-ui-wrapper'>

                {this.props.sharedIsOpenMassShipAndCloseModel &&
                    <div className="model-content-wrapper">
                        {this.props.callFrom != 'SmallParcelSuit' && (
                            <WmsCollapse
                                id={'shipAndCloseRoutingInfo'}
                                headerLabel={this.translate('Label_Routing_Information')}
                                showCollapseText={false} >
                                <div className="title">
                                    <div className="wms-sprite fa fa-exclamation-triangle"></div>
                                    <span>{this.translate('Oders_FindOrders_MassShip_Summary').replace(new RegExp('{' + 'amount' + '}', 'gi'), this.props.sharedSelectedOrderIds.length)}</span>
                                </div>
                                <div className='collapse-wrapper-section'>
                                    <div className='element-parent-block'>
                                        <WmsDateTimePicker
                                            id={'massCloseOrderShip'}
                                            name='closeOrderShipDate'
                                            wmsSelector={this.state.selector + "CloseOrderShipDate"}
                                            label={this.translate('Label_Ship_Date')}
                                            onChangeFunc={this.changeCloseShipDate}
                                            value={this.state.closeOrderShipDate}
                                            required={!this.state.closeOrderShipDate}
                                            isDateParseInCustomFormat={true} />
                                    </div>

                                    <div className='element-parent-block'>
                                        <WmsInput
                                            inputName="LoadNumber"
                                            wmsSelector={this.state.selector + 'LoadNumber'}
                                            value={
                                                this.state.shipAndCloseFormData != null ? this.state.shipAndCloseFormData.LoadNumber.Value : ''
                                            }
                                            onChangeFunc={this.handleChangeEvent}
                                            maxLength={255}
                                            label={this.translate('Label_Load_Number')} />

                                        <WmsInput
                                            inputName="BolNumber"
                                            wmsSelector={this.state.selector + 'BolNumber'}
                                            value={
                                                this.state.shipAndCloseFormData != null ? this.state.shipAndCloseFormData.BolNumber.Value : ''
                                            }
                                            onChangeFunc={this.handleChangeEvent}
                                            maxLength={255}
                                            label={this.translate('Label_Bill_Of_Lading_Number')} />

                                        <WmsInput
                                            inputName="TrackingNumber"
                                            wmsSelector={this.state.selector + 'TrackingNumber'}
                                            value={
                                                this.state.shipAndCloseFormData != null ? this.state.shipAndCloseFormData.TrackingNumber.Value : ''
                                            }
                                            onChangeFunc={this.handleChangeEvent}
                                            maxLength={255}
                                            label={this.translate('Label_Tracking_Number')} />

                                        <WmsInput
                                            inputName="TrailerNumber"
                                            wmsSelector={this.state.selector + 'TrailerNumber'}
                                            value={
                                                this.state.shipAndCloseFormData != null ? this.state.shipAndCloseFormData.TrailerNumber.Value : ''
                                            }
                                            onChangeFunc={this.handleChangeEvent}
                                            maxLength={255}
                                            label={this.translate('Label_Trailer_Number')} />
                                    </div>

                                    <div className='element-parent-block'>
                                        <WmsInput
                                            inputName="SealNumber"
                                            wmsSelector={this.state.selector + 'SealNumber'}
                                            value={
                                                this.state.shipAndCloseFormData != null ? this.state.shipAndCloseFormData.SealNumber.Value : ''
                                            }
                                            onChangeFunc={this.handleChangeEvent}
                                            maxLength={255}
                                            label={this.translate('Label_Seal_Number')} />

                                        <WmsInput
                                            inputName="DoorNumber"
                                            wmsSelector={this.state.selector + 'DoorNumber'}
                                            value={
                                                this.state.shipAndCloseFormData != null ? this.state.shipAndCloseFormData.DoorNumber.Value : ''
                                            }
                                            onChangeFunc={this.handleChangeEvent}
                                            maxLength={255}
                                            label={this.translate('Label_Door_Number')} />

                                        <WmsDateTimePicker
                                            id={'massShipPickUpDate'}
                                            name='PickupDate'
                                            wmsSelector={this.state.selector + "massShipPickUpDate"}
                                            label={this.translate('Label_Pickup_Date')}
                                            onChangeFunc={this.handleChangeEvent}
                                            value={
                                                this.state.shipAndCloseFormData != null ? this.state.shipAndCloseFormData.PickupDate.Value : ''
                                            } />
                                    </div>
                                </div>
                            </WmsCollapse>
                        )}
                        <WmsCollapse
                            id={'additonalChargesRoutingInfo'}
                            headerLabel={this.translate('Label_Additional_Charges')}
                            isExpanded={this.props.callFrom == 'SmallParcelSuit'}
                            showCollapseIcon={this.props.callFrom != 'SmallParcelSuit'}
                            showCollapseText={false}
                            setCollapseMetaData={this.onCollapseIconClick}
                            isDisabled={!this.state.Rights.hasOwnProperty('receipts.charges.manage')}
                            >
                            <div className='collapse-wrapper-section'>
                                <WmsDropdown
                                    id="indexChargeType"
                                    name="indexChargeType"
                                    label={this.translate('Label_Select_Charge')}
                                    wmsSelector={this.state.selector + "ChargeType"}
                                    value={this.state.indexChargeType}
                                    onChangeFunc={this.onChangeChargeType}
                                    options={this.state.chargeTypeOption}
                                    textField='ChargeLabel'
                                    valueField='ChargeLabel'
                                    applyIndex={true}
                                    extraWrapperClass='dropdown-width-40' />

                                <div className="wms-elements-wrapper">
                                    <WmsDropdown
                                        id="indexChargeCategory"
                                        name="indexChargeCategory"
                                        label={this.translate('Label_Charge_Category')}
                                        wmsSelector={this.state.selector + "Category"}
                                        value={this.state.manualCharges.indexChargeCategory}
                                        onChangeFunc={this.changeAdditaionalCharge}
                                        options={this.state.sharedManualChargeOptions}
                                        textField='Description'
                                        valueField='Description'
                                        applyIndex={true}
                                        disabled={this.state.indexChargeType != 0 && this.state.indexChargeType != ''} />

                                    <WmsInput
                                        id="ChargeLabel"
                                        inputName="ChargeLabel"
                                        wmsSelector={this.state.selector + 'Label'}
                                        value={this.state.manualCharges.ChargeLabel}
                                        onChangeFunc={this.changeAdditaionalCharge}
                                        maxLength={255}
                                        regularExp={/^\w+(\s\w*)*$/}
                                        label={this.translate('Label_Charge_Label')}
                                        required={true}
                                        extraWrapperClass='additional-charge-label-inp'
                                        disabled={this.state.indexChargeType != 0 && this.state.indexChargeType != ''} />

                                    <WmsInput
                                        id="UnitDescription"
                                        inputName="UnitDescription"
                                        wmsSelector={this.state.selector + 'UnitType'}
                                        value={this.state.manualCharges.UnitDescription}
                                        onChangeFunc={this.changeAdditaionalCharge}
                                        maxLength={255}
                                        regularExp={/^\w+( \w+)*$/}
                                        label={this.translate('Label_Type_Of_Charge_Unit')}
                                        extraWrapperClass='additional-charge-unit-type-inp'
                                        disabled={this.state.indexChargeType != 0 && this.state.indexChargeType != ''} />

                                    <WmsInput
                                        id="ChargePerUnit"
                                        inputName="ChargePerUnit"
                                        wmsSelector={this.state.selector + 'PerUnit'}
                                        value={this.state.manualCharges.ChargePerUnit}
                                        onChangeFunc={this.changeAdditaionalCharge}
                                        maxLength={255}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                        label={this.translate('Label_Charge_Per_Unit')}
                                        extraWrapperClass='additional-charge-per-unit-inp'
                                        disabled={this.state.indexChargeType != 0 && this.state.indexChargeType != ''} />

                                    <WmsInput
                                        id="NumberOfUnits"
                                        inputName="NumberOfUnits"
                                        wmsSelector={this.state.selector + 'NumberOfUnits'}
                                        value={this.state.manualCharges.NumberOfUnits}
                                        onChangeFunc={this.changeAdditaionalCharge}
                                        maxLength={255}
                                        label={this.translate('Label_Number_Of_Units')}
                                        required={true}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                        extraWrapperClass='additional-charge-numberof-unit' />
                                </div>
                                <div className='element-parent-block'>
                                    <WmsInput
                                        inputName="GeneralLedgerAccount"
                                        wmsSelector={this.state.selector + 'GlAccNumber'}
                                        value={this.state.manualCharges.GeneralLedgerAccount}
                                        onChangeFunc={this.changeAdditaionalCharge}
                                        maxLength={255}
                                        regularExp={/^\w+( \w+)*$/}
                                        label={this.translate('Label_General_Ledger_Account')}
                                        disabled={this.state.indexChargeType != 0 && this.state.indexChargeType != ''} />

                                    <WmsInput
                                        inputName="ExternalAccountsReceivable"
                                        wmsSelector={this.state.selector + 'ExtAccReceivable'}
                                        value={this.state.manualCharges.ExternalAccountsReceivable}
                                        onChangeFunc={this.changeAdditaionalCharge}
                                        maxLength={255}
                                        regularExp={/^\w+( \w+)*$/}
                                        label={this.translate('Label_External_Account_Receivable')}
                                        disabled={this.state.indexChargeType != 0 && this.state.indexChargeType != ''} />

                                    <WmsInput
                                        inputName="ExternalItemIdentifier"
                                        wmsSelector={this.state.selector + 'ExternalItemIdentifier'}
                                        value={this.state.manualCharges.ExternalItemIdentifier}
                                        onChangeFunc={this.changeAdditaionalCharge}
                                        maxLength={255}
                                        regularExp={/^\w+( \w+)*$/}
                                        label={this.translate('Label_External_Item_Identifier')}
                                        disabled={this.state.indexChargeType != 0 && this.state.indexChargeType != ''} />

                                    <WmsInput
                                        inputName="ExternalItemDescription"
                                        wmsSelector={this.state.selector + 'ExternalItemDesc'}
                                        value={this.state.manualCharges.ExternalItemDescription}
                                        onChangeFunc={this.changeAdditaionalCharge}
                                        maxLength={255}
                                        regularExp={/^\w+( \w+)*$/}
                                        label={this.translate('Label_External_Item_Description')}
                                        disabled={this.state.indexChargeType != 0 && this.state.indexChargeType != ''} />
                                </div>

                                <div className='addtional-chrage-btn'>
                                    <WmsButton
                                        label={this.translate('Label_Add_Charge')}
                                        wmsSelector={this.state.selector + "AddCharge"}
                                        onClickFunc={this.addShipAndCloseGridData}
                                        icon={'fa-plus'}
                                        disabled={
                                            this.state.manualCharges.NumberOfUnits == 0 || this.state.manualCharges.NumberOfUnits == null
                                        } />
                                </div>
                                {this.state.chargeOperationMsg != '' && (
                                    <div className="charge-success-msg">
                                        <div className="wms-sprite fa fa-check-circle-o"></div>
                                        <span>{this.state.chargeOperationMsg}</span>
                                    </div>
                                )}
                                <div className="wms-shipclose-grid-holder">
                                    <div className="charge-grid-note">
                                        <span>NOTE: </span>
                                        <label>{this.translate('MassShip_AdnClose_Grid_Note')}</label>
                                    </div>
                                    {this.props.sharedIsOpenMassShipAndCloseModel == true &&
                                        <WmsGrid
                                            wmsSelector={this.props.windowId + 'GridMassShipCharges'}
                                            isAlignIndexesByProperty={true}
                                            gridKeySelection={'RowNumber'}
                                            gridName={'GridMassShipCharges'}
                                            virtual={false}
                                            id={'GridMassShipCharges'}
                                            columns={this.state.shipAndCloseGridColumns}
                                            schema={this.state.schema}
                                            staticGridData={this.state.shipAndCloseGridData}
                                            onDataBound={this.onDataBound}
                                            onSelection={() => function () { }}
                                            onGridSave={this.saveMassShipCharges}
                                            layoutData={''}
                                            getGridSelectionUrl={''}
                                            searchUrl={''}
                                            gridNoteText={this.translate('MassShip_AdnClose_Grid_Note')}
                                            menu={[]}
                                            gridEditable={{ confirmation: false }}
                                            onRemove={this.onRemove}
                                            shawCheckbox={false}
                                            showResetButton={false}
                                            showColumnFilter={false}
                                            showSortColumns={false}
                                            gridNavigatable={true}
                                            onEdit={this.onGridEdit} />
                                    }
                                    <div className="totalcharges-section" >
                                        <WmsInput
                                            id="GridMassShipChargesTotal"
                                            inputName="GridMassShipChargesTotal"
                                            wmsSelector={this.state.selector + 'GridMassShipChargesTotal'}
                                            value={this.state.gridMassShipChargesTotal}
                                            onChangeFunc={(e) => function () { }}
                                            disabled={true}
                                            label={this.translate('Label_Order_Total_Charges')} />

                                        <WmsDropdown
                                            id="ApplyChargesToEachOrder"
                                            name="ApplyChargesToEachOrder"
                                            label={this.translate('Label_Apply_Charge')}
                                            wmsSelector={this.state.selector + "ApplyChargesToEachOrder"}
                                            value={
                                                this.state.shipAndCloseFormData != null ? (this.state.shipAndCloseFormData.ApplyChargesToEachOrder ?
                                                    '0' : '1')
                                                    : ''
                                            }
                                            onChangeFunc={this.handleChangeEvent}
                                            options={this.state.applyChargeOpt}
                                            textField='name'
                                            valueField='value'
                                            applyIndex={true} />
                                    </div>
                                </div>
                            </div>
                        </WmsCollapse>
                    </div>

                }
                {this.props.sharedIsOpenMassShipAndCloseModel &&
                    <div className="footer-btn-holder">
                        <div className="wmsi-button-holder">
                            <WmsButton
                                label={this.translate('Label_Cancel')}
                                buttonType='yellow'
                                wmsSelector={this.state.selector + 'ShipAndClosCannel'}
                                onClickFunc={this.props.onCloseShipAndClose}
                                icon='fa-ban' />
                            <WmsButton
                                id='shipAndCloseSAve'
                                label={this.translate('Label_Ship_And_Close_Save')}
                                wmsSelector={this.state.selector + 'ShipAndCloseSave'}
                                onClickFunc={this.clickShipAndClose}
                                disabled={!this.state.closeOrderShipDate}
                                icon='fa-truck' />
                        </div>
                    </div>
                }

            </WmsModel>
        )
    }
}