import React from 'react';

import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsGrid from '../../components/WmsGrid/WmsGrid.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsModel from '../../components/WmsModel/WmsModel.jsx';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';

import { StringWms } from '../../../../assets/ts/StringWms.ts';
import { ObjectWms } from '../../../../assets/ts/ObjectWms.ts';

import * as GridConfig from './grid_configuration.jsx';
//services
import * as manageOrderServices from './manage-orders.services.jsx';
import * as manageOrderProtoMetaDataService from './manage-order-proto-meta-data.service';
import * as GlobalService from './../../global_services';
export class ManageOrdersCalculateCharges extends React.Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;
        let CalculateCharge_GrideHeaders = StringWms.convertSringToOption(this.translate('Orders_ManageOrders_Grid_Headers_Applied_Charges'));

        this.state = {
            selector: 'OrderProtoTypeCalculateCharges',
            columns: GridConfig.getCalculateChargesGridColumns(CalculateCharge_GrideHeaders, GlobalService.GetRights()).columns,
            schema: GridConfig.getCalculateChargesGridSchema().schema,
            gridAppliedChargesTotalCharges: '0',
            isExpanded: props.isPrototypeComponent ? ((!$.isEmptyObject(props.metaDataList) && props.metaDataList.hasOwnProperty('collapse5')) ? !props.metaDataList['collapse5'] : true) : true,
            isManualCharge: false,
            localKendoGrid: null,
            isOpenConfirmModel: false,
            // componentsInitialized: false
        };
    }

    setCollapseMetaData = (value) => {
        if (this.props.isPrototypeComponent) {
            manageOrderProtoMetaDataService.setManageOrderProtoCollapseMetaData("collapse5", value);
        }
    }
    openCreateManualChargeModel = () => {

        // GetChargeCategories
        manageOrderServices.GetChargeCategories().then((response) => {
            this.props.onOpenCreateManualChargeModal({
                'chargesOptions': response,
                'openModal': true
            });
        });
    }

    calculateChargesClick = () => {
        this.props.onSaveOrderClicked();
    }

    onDataBound = (e, kendoGrid) => {
        var totalCharges = kendoGrid.sumByLocalMemoryGridContents(["TotalCharges"])[0];
        this.setState({
            gridAppliedChargesTotalCharges: Number(Math.round(totalCharges + 'e2') + 'e-2'),
        });

        // if(this.state.componentsInitialized == false) {
        //   var grid = $('.' + this.props.windowId + ' #GridAppliedCharges').data('kendoGrid');
        //   grid.bind("save", this.EventSaveGridAppliedCharges);
        //   this.setState({
        //     componentsInitialized: true
        //   })
        // }
    }

    onEdit = (e) => {
        var grid = $('.' + this.props.windowId + ' #GridAppliedCharges').data('kendoGrid');
        var columnIndex = grid.cellIndex(e.container);
        var fieldName = grid.thead.find("th").eq(columnIndex).data("field");

        if (fieldName == "NumberOfUnits" && e.model.ChargeOrigin == 1) {
            grid.closeCell();
        }
        if (fieldName == "ChargePerUnit" && e.model.ChargeOrigin == 1) {
            grid.closeCell();
        }
        if (fieldName == "ChargePerUnit" && e.model.ChargeOrigin == 3) {
            grid.closeCell();
        }
    }

    selectRow = (param_kendogrid) => {
        var selectedRow = param_kendogrid.getNumberOfSelections();
        var manualChargeType = selectedRow > 0 ? param_kendogrid.identicalValuesInSelectedSet("ChargeOrigin", 2) : false;

        this.setState({
            isManualCharge: manualChargeType,
            localKendoGrid: param_kendogrid
        });

    }

    openConfirmationModal = () => {
        this.setState({
            isOpenConfirmModel: true
        });
    }

    ClickExit = () => {
        this.setState({
            isOpenConfirmModel: false
        });
    }

    deleteManualCharges = () => {

        var grid = $('.' + this.props.windowId + ' #GridAppliedCharges').data('kendoGrid');

        grid.select().each(function () {
            grid.removeRow($(this));
        });

        var data = grid.dataSource.data().length > 0 ? grid.dataSource.data().toJSON() : [];

        let sharedOptionObj = Object.assign(
            {},
            this.props.sharedOptions,
            {
                'BillingCharges': data
            }
        );
        this.props.onSharedOptionDataSet(sharedOptionObj);
        this.setState({
            isOpenConfirmModel: false,
            isManualCharge: false
        });
    }

    appliedChargesGridChange = (e) => {
        //   var selected = e.sender.select();
        //   $scope.selectedChargesCount = selected.length;
    };

    EventSaveGridAppliedCharges = (e) => {
        var grid = $('.' + this.props.windowId + ' #GridAppliedCharges').data('kendoGrid');
        var numberOfUnits = StringWms.isEmpty(e.model.NumberOfUnits) ? 0 : e.model.NumberOfUnits;
        if (e.values.hasOwnProperty("NumberOfUnits")) {
            numberOfUnits = StringWms.isEmpty(e.values.NumberOfUnits) ? 0 : e.values.NumberOfUnits;
        }

        var chargePerUnit = StringWms.isEmpty(e.model.ChargePerUnit) ? 0 : e.model.ChargePerUnit;
        if (e.values.hasOwnProperty("ChargePerUnit")) {
            chargePerUnit = StringWms.isEmpty(e.values.ChargePerUnit) ? 0 : e.values.ChargePerUnit;
        }

        var total = parseFloat((new Big(numberOfUnits)).times(chargePerUnit).round(4));

        e.model.set("TotalCharges", total);
        e.model.TotalCharges = total;
        grid.saveChanges();

        // Setting a timeout so that NumberOfUnits gets updated by gridSaveChanges
        setTimeout(() => {
            let billingChargesData = grid._data;
            // console.log("billingChargesData before ", billingChargesData);
            // Passing test here as it should not exclude any records from here for now
            // prepareForSave will exclude records with NumberOfUnits null
            billingChargesData = ObjectWms.removeObjFromListOnParameterValue(billingChargesData, "test", null);

            let propsOrderObj = this.props.sharedOptions;
            propsOrderObj["BillingCharges"] = billingChargesData;

            this.props.onSharedOptionDataSet(propsOrderObj);
        }, 100);

    }

    onChangeGridconfig = (param_grid_config) => {
        manageOrderProtoMetaDataService.setManageOrderProtoAppliedChargesGridOptionMetaData(param_grid_config);
    }

    onResetGrid = () => {
        manageOrderProtoMetaDataService.setManageOrderProtoAppliedChargesGridOptionMetaData({ 'optionsData': {} });
    }

    render() {
        // var OrderData = this.props.sharedOptions.Order;
        return (
            <div className="calculate-charge-section collapseOrderTabContent_wrapper" ref={this.props.divRefs}>
                <WmsCollapse id={'collapse5'} isExpanded={this.state.isExpanded} setCollapseMetaData={this.setCollapseMetaData} windowId={this.props.windowId} headerLabel={this.translate('Label_Applied_Charges')} showCollapseText={false}>
                    <WmsGrid
                        wmsSelector={this.state.selector + 'Grid'}
                        gridKeySelection={'ChargeLabel'}
                        gridName={'GridAppliedCharges'}
                        isAlignIndexesByProperty={true}
                        virtual={false}
                        id={'GridAppliedCharges'}
                        columns={this.state.columns}
                        schema={this.state.schema}
                        showGridSummary={false}
                        windowId={this.props.windowId}
                        onSelection={this.selectRow}
                        clickEventOfContextMenu={() => null}
                        contextMenuFilter='tbody tr:not(.k-grid-edit-row)'
                        shawCheckbox={false}
                        showGridResult={false}
                        staticGridData={this.props.sharedOptions.BillingCharges}
                        showSortColumns={false}
                        onDataBound={this.onDataBound}
                        gridEditable={{
                            confirmation: false
                        }}
                        onEdit={this.onEdit}
                        onGridSave={this.EventSaveGridAppliedCharges}
                        onChange={this.appliedChargesGridChange}
                        layoutData={manageOrderProtoMetaDataService.getManageOrderProtoAppliedChargesGridOptionMetaData()}
                        onSort={this.onChangeGridconfig}
                        onColumnShow={this.onChangeGridconfig}
                        onColumnHide={this.onChangeGridconfig}
                        onColumnReorder={this.onChangeGridconfig}
                        onColumnResize={this.onChangeGridconfig}
                        onResetGrid={this.onResetGrid}
                        menu={[
                            {
                                "name": this.translate('Label_Recalculate_AutoCharges'),
                                "value": [],
                                "disabled": (this.props.orderItemsCount == 0 || this.props.sharedRecalculateGif || this.props.isCustomerUserAndOrderComplete),
                                "disabledText": "You may not add blahssss",
                                "icon": "fa fa-calculator",
                                "color": "green",
                                "clickFunc": (this.calculateChargesClick),
                                "wmsSelector": this.state.selector + "RecalculateAutoCharges",
                                "loading": this.props.sharedRecalculateGif,
                                "showToolTip": (this.props.orderItemsCount == 0 || this.props.isCustomerUserAndOrderComplete),
                                "tooltipText": (this.props.isCustomerUserAndOrderComplete ? this.translate('note_cant_calculate_customer_user') : this.translate('Label_Add_Line_Item')),
                                "isContextMenu": false,
                            },
                            {
                                "name": this.translate('Label_Create_Manual_Charge'),
                                "value": [],
                                "disabled": false,
                                "disabledText": "You may not add blahssss",
                                "icon": "fa fa-keyboard-o",
                                "color": "green",
                                "clickFunc": (this.openCreateManualChargeModel),
                                "wmsSelector": this.state.selector + "CreateManualCharge",
                                "disabled": this.props.isCustomerUserAndOrderComplete,
                                "showToolTip": this.props.isCustomerUserAndOrderComplete,
                                "tooltipText": (this.props.isCustomerUserAndOrderComplete ? this.translate('note_cant_add_charges_customer_user') : ''),
                                "isContextMenu": false,
                            },
                            {
                                "name": this.translate('Label_Delete'),
                                "value": [],
                                "disabled": !this.state.isManualCharge,
                                "disabledText": "You may not add blahssss",
                                "icon": "fa-trash",
                                "color": "red",
                                "clickFunc": (this.openConfirmationModal),
                                "wmsSelector": this.state.selector + "DeleteManualCharge",
                                "isContextMenu": false,
                            }
                        ]}
                    />

                    <div className="row wms-area-content-darker widthout-bg total-price">
                        <WmsInput
                            id='TotalCharges'
                            inputName='TotalCharges'
                            wmsSelector={this.state.selector + 'TotalCharges'}
                            value={this.state.gridAppliedChargesTotalCharges}
                            onChangeFunc={() => null}
                            label={this.translate('Label_Total_Charges')}
                        />
                    </div>
                    {this.state.isOpenConfirmModel && (
                        <WmsModel
                            id={this.props.windowId + 'confirmDeleteManualCharge'}
                            title={this.translate('Label_Confirm_Delete_Manual_Charge')}
                            width={500}
                            height={175}
                            class='confirmDeleteManualCharge'
                            onCloseFunc={this.ClickExit}
                            customClass='wms-model-new-ui-wrapper'
                            isOpen={this.state.isOpenConfirmModel}>
                            <div className='model-content-wrapper'>
                                {this.translate('Label_Confirm_Selected_Charges')}
                            </div>
                            <div className="footer-btn-holder">
                                <WmsButton
                                    label={this.translate('Label_Cancel')}
                                    wmsSelector={this.state.selector + 'Cancel'}
                                    onClickFunc={this.ClickExit}
                                    disabled={false}
                                    loading={false}
                                    buttonType='yellow'
                                    icon={'fa-ban'}
                                />
                                <WmsButton
                                    label={this.translate('Label_Confirm_Delete')}
                                    wmsSelector={this.state.selector + 'ConfirmDelete'}
                                    onClickFunc={this.deleteManualCharges}
                                    disabled={false}
                                    loading={false}
                                    buttonType='red'
                                    icon={'fa-trash'}
                                />
                            </div>

                        </WmsModel>
                    )}

                </WmsCollapse>
            </div>
        );
    }
}
