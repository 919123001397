/* eslint-disable security/detect-object-injection */
import * as GlobalService from "../react/global_services";
var isFirstTimeOpen = true;
var keyName = "ManageAssembly";
var metaDataManageAssemblyObject = {
    ManageAssembly: {
        filterData: {},
        gridConfig: {},
        collapseData: {},
        sorableSection: "",
        saveFilter: [],
    },
};
var axios = require("axios");
declare var window: any;
const { $ } = window;

export const initialFilterData = function () {
    return axios.all([GlobalService.GetCustomersAndFacility()]).then(
        axios.spread(function (GetCustomerFacilityList) {
            return {
                getCustomerFacilityList: GlobalService.getFacilityList(),
            };
        })
    );
};

export const setGridFiltere = function (param_filterObj, cancleToken) {
    let url = "/WebUI/assemblies/FindAssemblies/FilteredRowCount";

    return axios.put(url, param_filterObj, { cancelToken: cancleToken }).then((response: any) => {
        return response;
    });
};
export const createAssembly = function (formData) {
    let url = "/WebUI/assemblies/FindAssemblies/Assembly";

    return axios.post(url, formData).then(function (response) {
        return response;
    });
};
export const editAssembly = function (formData) {
    let url = "/WebUI/assemblies/FindAssemblies/Assembly";
    return axios.put(url, formData).then(function (response) {
        return response;
    });
};

export const getReport = function (url, param_object) {
    return axios.post(url, param_object).then(function (response) {
        return response;
    });
};
export const getCharges = function () {
    return axios.get("/WebUI/Shipping/Orders/GetChargeCategories").then(function (response) {
        return response;
    });
};
export const confirmSingleAssembly = function (assemblyId) {
    return axios.post("/WebUI/assemblies/FindAssemblies/Confirm", { assemblyId: assemblyId }).then(function (response) {
        return response;
    });
};
export const confirmAssemblies = function (assemblyIds) {
    return axios
        .post("/WebUI/assemblies/FindAssemblies/ConfirmAssemblies", { ids: assemblyIds })
        .then(function (response) {
            return response;
        });
};
export const CancelAssembly = function (formData) {
    return axios.post("/WebUI/assemblies/FindAssemblies/CancelAssemblies", formData).then(function (response) {
        return response;
    });
};
export const getAssemblyDetailObject = function (assemblyId) {
    return axios.get(`/WebUI/assemblies/FindAssemblies/GetAssemblyById/${assemblyId}`).then(function (response) {
        return response;
    });
};
export const AssemblyCalculateCharges = function (assemblyId) {
    return axios
        .get(`/WebUI/assemblies/FindAssemblies/AssemblyCharges?assemblyId=${assemblyId}`)
        .then(function (response) {
            return response;
        });
};

export const initialEditAllocations = function (param_obj) {
    return axios
        .post("/WebUI/assemblies/FindAssemblies/GetLineItemAllocations", {
            "assemblyId": param_obj.assemblyId,
            "assemblyItemUri": param_obj.assemblyItemUri,
        })
        .then(function (response) {
            return response.data;
        });
};

export const editOrderLineItemAllocations = function (obj) {
    return axios
        .post("/WebUI/assemblies/FindAssemblies/EditOrderLineItemAllocations", obj, { dataType: "JSON" })
        .then(function (response) {
            return response;
        });
};
export const initialMassAdd = function () {
    return axios.post("/WebUI/Shipping/UpdateSession/MassAddSearchInfoClear").then(function () {});
};
export const massOrderLineItemsAdd = function (obj) {
    return axios
        .post("/WebUI/Shipping/Orders/MassOrderLineItemsAdd", obj, { dataType: "JSON" })
        .then(function (response) {
            return response;
        });
};

// call api for get metaData of AdjustInventory is it's open first time
export const getManageAssemblyClientMetaData = function () {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response !== "") {
                    Object.assign(metaDataManageAssemblyObject[keyName], JSON.parse(response));
                }
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                return false;
            }
        );
    }
};

export const setManageAssemblyFilterMetaData = function (filterData) {
    metaDataManageAssemblyObject[keyName]["filterData"] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataManageAssemblyObject[keyName]));
};

export const getManageAssemblyFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataManageAssemblyObject[keyName]["filterData"])) {
        return metaDataManageAssemblyObject[keyName]["filterData"];
    } else {
        return "";
    }
};
export const setManageAssemblyGridOptionInMetaData = function (gridConfig) {
    metaDataManageAssemblyObject[keyName]["gridConfig"] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataManageAssemblyObject[keyName]));
};
export const getManageAssemblyGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataManageAssemblyObject[keyName]["gridConfig"])) {
        return metaDataManageAssemblyObject[keyName]["gridConfig"];
    } else {
        return "";
    }
};

export const clearManageAssemblyFilterMetaData = function () {
    metaDataManageAssemblyObject[keyName]["filterData"] = {};
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataManageAssemblyObject[keyName]));
};

export const setManageAssemblyCollapseMetaData = function (collapseId, value) {
    if (!metaDataManageAssemblyObject[keyName]["collapseData"]) {
        metaDataManageAssemblyObject[keyName]["collapseData"] = {};
    }
    metaDataManageAssemblyObject[keyName]["collapseData"][collapseId] = value;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataManageAssemblyObject[keyName]));
};
export const getManageAssemblyCollapseMetaData = function () {
    return metaDataManageAssemblyObject[keyName]["collapseData"];
};

export const setManageAssemblySortSectionMetaData = function (sortArray) {
    metaDataManageAssemblyObject[keyName]["sorableSection"] = sortArray;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataManageAssemblyObject[keyName]));
};
export const getManageAssemblySortSectionMetaData = function () {
    return metaDataManageAssemblyObject[keyName]["sorableSection"];
};

// save filter of find order in meta data
export const saveManageAssemblyFilterMetaData = function (title, filterObj, columnObj) {
    var setData = {
        title: title,
        filterData: filterObj,
        columnData: columnObj.optionsData,
    };

    if (typeof metaDataManageAssemblyObject[keyName]["saveFilter"] == "undefined") {
        metaDataManageAssemblyObject[keyName]["saveFilter"] = [];
    }
    metaDataManageAssemblyObject[keyName]["saveFilter"].push(setData);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataManageAssemblyObject[keyName]));
};
export const updateManageAssemblyFilterMetaData = function (index, filterObj, columnObj) {
    if (typeof metaDataManageAssemblyObject[keyName]["saveFilter"][index] != "undefined") {
        metaDataManageAssemblyObject[keyName]["saveFilter"][index].filterData = filterObj;
        metaDataManageAssemblyObject[keyName]["saveFilter"][index].columnData = columnObj.optionsData;
        GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataManageAssemblyObject[keyName]));
    }
};
export const getSaveManageAssemblyFilterListMetaData = function () {
    if (metaDataManageAssemblyObject[keyName]["saveFilter"]) {
        return metaDataManageAssemblyObject[keyName]["saveFilter"];
    }

    return [];
};

export const clearSaveManageAssemblyFilterListMetaData = function (index) {
    metaDataManageAssemblyObject[keyName]["saveFilter"].splice(index, 1);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataManageAssemblyObject[keyName]));
};

export const getReports = function (customerId) {
    return axios.get(`/WebUI/assemblies/FindAssemblies/GetReports?customerid=${customerId}`).then(function (response) {
        return response.data;
    });
};
