import React, { Component } from 'react';

// Services
import * as findOrdersService from './../findOrders.services.jsx';

import './pick-ticket-model.scss';
import { ArrayWms } from '../../../../../assets/ts/ArrayWms.ts';

// Component
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsContainer from '../../../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsCheckBox from '../../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsRadio from '../../../components/WmsRadio/WmsRadio.tsx';

export class PickTicketModel extends Component {

    constructor(props) {
        super(props);


        this.$rootScope = window;
        this.translate = window.translate;
        this.modalId = this.props.windowId + this.props.id;

        this.defaultPickTicket = {
            options: [],
            Template: "",
            SelectedTemplateIndex: 0,
            SortLocation: false,
            IsDisable: false,
            TicketOption: "",
        }
        this.radioBtnLabels = {
            'PickTicketExt': this.translate("Label_Pick_Ticket_Default"),
            'PickTicketLocation': this.translate("Label_Pick_Ticket_Location"),
            'PickTicketExt_OverAllocOk': this.translate("Label_Pick_Ticket_Overallocated"),
            'PickTicketExt_ShortShip': this.translate("Label_Short_Ship_Ticket")
        };

        this.state = {
            Selector: "order",
            gifGenerate: false,
            disableGenerateBtn: false,
            tooltipTextForGenerateBtn: "",
            pickTicket: this.defaultPickTicket,
            fullyAllocated: false,
            disableTemplatePickTicket: false,
            optionsTemplate: []
        }
    }
    componentDidMount() {
        kendo.ui.progress($('.WindowPickTicket'), true);

        this.setState({
            fullyAllocated: this.props.fullyAllocated
        });
        var payload = { customerId: this.props.customerId };
        if (this.props.flow === "regular") {
            if (this.props.sharedSelectedCustomerIds.length == 1) {
                payload = { customerId: this.props.sharedSelectedCustomerIds[0] }
                this.setState({
                    disableTemplatePickTicket: false
                });
            } else {
                payload = "";
                this.setState({
                    disableTemplatePickTicket: true
                });
            }
        }

        findOrdersService.GetPickTicketReports(payload).then((response) => {
            if (typeof response != 'undefined' && response.status == 200) {
                this.setState({ optionsTemplate: response.data });
                if (this.props.flow === "regular") {
                    // We will have the dropdown load the first index's options for regular flow
                    if (this.state.fullyAllocated) {
                        if (response.data.length == 0) {
                            this.setState({
                                disableGenerateBtn: true,
                                tooltipTextForGenerateBtn: this.translate("Label_Template_Options_Not_available")
                            });
                            return;
                        }
                        this.eventChangeRegularPickTicket(0);
                    } else {
                        var overAllocatedIndex = response.data.map(function (o) { return o.RdlName; }).indexOf("PickTicketExt_OverAllocOk");
                        if (overAllocatedIndex == -1) {
                            this.setState({
                                disableGenerateBtn: true,
                                tooltipTextForGenerateBtn: this.translate("Label_Template_Options_OverAlloc_Not_available")
                            });
                            return;
                        }
                        this.eventChangeRegularPickTicket(overAllocatedIndex);
                    }
                } else if (this.props.flow === "master") {
                    this.eventChangeMasterPickTicket();
                }
            }

        }).finally(() => {
            kendo.ui.progress($('.WindowPickTicket'), false);
        });



    }

    eventChangeMasterPickTicket = () => {

        var masterPickTicketIndex = this.state.optionsTemplate.map(function (o) { return o.RdlName; }).indexOf("MasterPickTicket");
        if (masterPickTicketIndex == -1) {
            this.setState({
                disableGenerateBtn: true,
                tooltipTextForGenerateBtn: this.translate("Label_Template_Options_MasterPick_Not_available")
            });
            return;
        }
        var options = this.state.optionsTemplate[masterPickTicketIndex].Reports;
        this.setState({
            pickTicket: Object.assign({}, this.state.pickTicket, {
                options: options,
                Template: (options.length > 0) ? options[0] : "",
            }),
        });

    }

    eventChangeRegularPickTicket = (index = 0) => {
        var options = this.state.optionsTemplate[index].Reports;
        var defaultIndex = 0;

        if (this.state.disableTemplatePickTicket) {
            defaultIndex = options.map(function (o) { return o.Name; }).indexOf("Default");
        }
        this.setState({
            pickTicket: Object.assign({}, this.state.pickTicket, {
                options: options,
                TicketOption: 'Location' + index,
                Template: (options.length > 0) ? options[defaultIndex] : "",
                SelectedTemplateIndex: defaultIndex,
            }),
        });
    }

    generateTicket = () => {

        var numSelectedOrders = [];
        this.props.sharedSelectedOrderIds.map(function (item) {
            numSelectedOrders.push(parseInt(item));
        });

        this.setState({ gifGenerate: true });

        var ticketUri = "";
        var data = {
            TransactionIds: numSelectedOrders,
            RunnerUriTemplate: typeof this.state.pickTicket.Template.TemplatedRunner != 'undefined' ? this.state.pickTicket.Template.TemplatedRunner : '',
            IgnoreDuplicates: this.state.pickTicket.IsDisable,
            UtcOffset: new Date().getTimezoneOffset() / 60 * -1,
            OutputType: 'application/pdf'
        };

        if (this.props.flow === "regular") {
            ticketUri = '/WebUI/Shipping/FindOrders/PickTicketReportStarter';
        } else if (this.props.flow === "master") {
            ticketUri = '/WebUI/Shipping/FindOrders/MasterPickTicketReportStarter';
            data = Object.assign({}, data, {
                BatchId: this.props.batchOrderId,
                SortByLocation: this.state.pickTicket.SortLocation
            });
        }

        findOrdersService.GeneratePickTicket(ticketUri, data).then((response) => {

            if (typeof response != 'undefined' && response.status === 200) {
                console.log("PickTicketReportStarter response", response.data.StatusRelativeUri);
                // if (this.props.flow === "regular") {
                // showing moving icon animation start
                var targetElement = $('#PickTicketGenerate' + this.props.windowId);
                this.$rootScope.animationMoveIcon(targetElement);
                this.$rootScope.taskStarted();
                // this.$rootScope.$broadcast("EventProgressPickTicket", {
                //     "pickticket": { uri: response.data.StatusRelativeUri },
                //     "callBackFunc": this.generatePickTicket,
                //     'numSelectedOrders': numSelectedOrders.length
                // });
                var transferOrderObj = {
                    "type": "generatePickTicket",
                    "pickticket": { uri: response.data.StatusRelativeUri, ticketType: this.props.flow },
                    "callBackFunc": this.generatePickTicket,
                    'numSelectedOrders': numSelectedOrders.length
                }
                var event = new CustomEvent("showProgressModal", {
                    detail: transferOrderObj
                });
                document.dispatchEvent(event);
                // }
                // else {
                //     var blob = ArrayWms.convertBase64ToByteArray(response.data, 'application/pdf');
                //     ArrayWms.displayBlobInWindow(blob, 'GeneratePickTicket.pdf');
                // }
            }

        }).finally(() => {
            this.setState({ gifGenerate: false });
            this.closePickTicketModel();
        });
    }
    generatePickTicket = (data) => {
        console.log("generatePickTicket data", data);
    }

    closePickTicketModel = (e) => {
        this.setState({
            pickTicket: this.defaultPickTicket,
            optionsTemplate: []
        }, () => {
            this.props.onClose();
        });
    }

    handleChangeEvent = (event) => {
        var stateKey = event.target.name;
        var type = event.target.type;

        if (type === 'checkbox') {
            this.setState({
                pickTicket: Object.assign({}, this.state.pickTicket, {
                    [stateKey]: event.target.checked
                }),
            });
        } else if (type === "select-one") {
            this.setState({
                pickTicket: Object.assign({}, this.state.pickTicket, {
                    [stateKey]: this.state.pickTicket.options[event.target.value],
                    SelectedTemplateIndex: event.target.value,
                }),
            });
        }
    }

    render() {
        return (
            <WmsModel
                id={this.modalId}
                class="WindowPickTicket"
                title={this.props.title}
                width={this.props.width}
                height={this.props.height}
                onCloseFunc={this.closePickTicketModel}
                isOpen={this.props.sharedIsOpenPickTicketModel}
                customClass='wms-model-new-ui-wrapper'
            >

                <div className="wms-area-content-darker model-content-wrapper wms-area-partial-border">
                    {this.props.flow === 'regular' &&

                        <WmsContainer>
                            <div className="radio-btnlist">
                                {this.state.optionsTemplate.map((option, index) =>
                                    (option.RdlName != 'MasterPickTicket') && (
                                        <div key={option.RdlName}>
                                            <WmsRadio
                                                label={this.radioBtnLabels[option.RdlName]}
                                                classes="radionew"
                                                name={this.props.windowId + "TicketOption"}
                                                value={"Location" + index}
                                                wmsSelector={this.state.Selector + "Location" + index}
                                                onChangeFunc={() => this.eventChangeRegularPickTicket(index)}
                                                checked={this.state.pickTicket.TicketOption === ("Location" + index)}
                                                disabled={option.RdlName == 'PickTicketExt_OverAllocOk' ? false : !this.state.fullyAllocated}
                                            />
                                        </div>
                                    )
                                )}
                            </div>

                            <div className="row">
                                <WmsDropdown
                                    id="Template"
                                    name="Template"
                                    label={this.translate('Label_Template')}
                                    wmsSelector={this.state.Selector + "Template"}
                                    value={this.state.pickTicket.SelectedTemplateIndex}
                                    onChangeFunc={this.handleChangeEvent}
                                    options={this.state.pickTicket.options}
                                    applyIndex={true}
                                    textField="Name"
                                    parentClassName="form_group"
                                    blankFirstOption={false}
                                    tooltipText={this.translate('Label_tooltip_pick_ticket_customer')}
                                    showToolTip={this.state.disableTemplatePickTicket}
                                    disabled={this.state.disableTemplatePickTicket}
                                />
                            </div>

                            <div className="checkbox-listing pick-ticket">
                                <WmsCheckBox
                                    name="IsDisable"
                                    wmsSelector={this.state.Selector + 'IsDisable'}
                                    value={null}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={this.translate('Label_Disable_Duplicate_Watermark')}
                                    checked={this.state.pickTicket.IsDisable}
                                />
                            </div>

                        </WmsContainer>
                    }
                    {this.props.flow === 'master' &&
                        <WmsContainer>
                            <div className="row">
                                <WmsDropdown
                                    id="Template"
                                    name="Template"
                                    label={this.translate('Label_Template')}
                                    wmsSelector={this.state.Selector + "Template"}
                                    value={this.state.pickTicket.SelectedTemplateIndex}
                                    onChangeFunc={this.handleChangeEvent}
                                    options={this.state.pickTicket.options}
                                    applyIndex={true}
                                    textField="Name"
                                    parentClassName="form_group"
                                    blankFirstOption={false}
                                />
                            </div>

                            <div className="checkbox-listing">
                                <WmsCheckBox
                                    name="SortLocation"
                                    wmsSelector={this.state.Selector + 'SortLocation'}
                                    value={null}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={this.translate('Label_Sort_By_Location')}
                                    checked={this.state.pickTicket.SortLocation}
                                />
                                <WmsCheckBox
                                    name="IsDisable"
                                    wmsSelector={this.state.Selector + 'IsDisable'}
                                    value={null}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={this.translate('Label_Disable_Duplicate_Watermark')}
                                    checked={this.state.pickTicket.IsDisable}
                                />
                            </div>
                        </WmsContainer>
                    }
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Cancel')}
                        wmsSelector={this.state.Selector + "PickTicketExit"}
                        onClickFunc={this.closePickTicketModel}
                        disabled={false}
                        loading={false}
                        buttonType='yellow'
                        icon={'fa-ban'}
                    />
                    <WmsButton
                        id={'PickTicketGenerate' + this.props.windowId}
                        label={this.translate('Label_Generate')}
                        wmsSelector={this.state.Selector + "PickTicketGenerate"}
                        onClickFunc={this.generateTicket}
                        showToolTip={this.state.disableGenerateBtn ? true : false}
                        tooltipText={this.state.tooltipTextForGenerateBtn}
                        disabled={this.state.disableGenerateBtn}
                        loading={this.state.gifGenerate}
                        icon={'fa-floppy-o'}
                    />
                </div>
            </WmsModel >
        );
    }
}