import * as GlobalService from './global_services';
const UNIT_MODES = { 2: 'g', 3: 'kg', 11: 'oz', 12: 'lbs' };
const SCALE_STATES = { 2: '±=', 4: '+', 5: '-' };
let _deviceScale;
export let previousWeight = 0;
export let isDeviceConnect = false;

export function connect() {
    previousWeight = 0;
    // check browser support hid or not
    if (!navigator.hid) {
        return;
    }

    // check if any device is attach or not.
    navigator.hid.getDevices().then(_devices => {
        console.log("getDevices > ", _devices)
        if (_devices.length) {
            let isScaleDeviceFound = false;
            for (let index = 0; index < _devices.length; index++) {
                const objDevice = _devices[index];
                // if (objDevice.vendorId == 2338) {
                _deviceScale = objDevice;
                isScaleDeviceFound = true;
                if (_deviceScale && !_deviceScale.opened) {
                    openScaleDevice();
                }
                // }
            }
            if (!isScaleDeviceFound) {
                requestDevice();
            }
        } else {
            requestDevice();
        }
    });
}

export function requestDevice() {
    navigator.hid.requestDevice({
        filters: [{
            // vendorId: 0x0922
            usagePage: 0x8D
        }]
    }).then(device => {
        console.log("connected device", device);
        _deviceScale = device[0];
        if (_deviceScale && !_deviceScale.opened) {
            openScaleDevice();
        }
    });
}

export function closeScaleDevice() {
    _deviceScale.close()
}

export function openScaleDevice() {
    console.log('opening')
    _deviceScale
        .open()
        .then(() => {
            console.log('opened', _deviceScale)
            if (_deviceScale.configuration === null) {
                console.log('selecting configuration')
                return _deviceScale.selectConfiguration(1)
            }
        })
        .then(() => {
            console.log('attaching oninputreport listener: ', _deviceScale);
            isDeviceConnect = true;
            var event = new CustomEvent("scaleConnectionChange", {
                detail: {
                    connected: true
                }
            });
            document.dispatchEvent(event);
            // eslint-disable-next-line no-undef
            toastr.success("Scale device is ready to record", '', {
                timeOut: 4000,
                positionClass: "toast-bottom-right",
                escapeHtml: false // for set html in message
            });
            _deviceScale.oninputreport = function (result) {
                let change = false;
                let data = new Uint8Array(result.data.buffer);
                // console.log("data: ", data);
                // var pow = data[2];
                // var powUse = pow == 255 ? -1 : pow;
                // let weight = (data[3] + data[4] << 8) * Math.pow(10, powUse);
                // console.log ("pow: " + pow + "  powUse: " + powUse + "  weight: " + weight);
                // let weight = data[3] + data[2] * data[4];

                // let weight = data[3] + data[4] * (data[2] == 0 ? 256 : data[2]);

                let weight = data[3] + (data[4] * 256);


                const unit = UNIT_MODES[data[1]];

                let scaleFactor = 1;
                if (data[2] === 255) {
                    scaleFactor = 10;

                } else if (data[2] === 0) {
                    scaleFactor = 1;

                } else if (data[2] <= 255) {
                    scaleFactor = 100;
                }

                weight = weight / scaleFactor;
                weight = weight.toFixed(3);

                // if (unit === 'oz') {
                //     // Use Math.pow to avoid floating point math.
                //     weight /= Math.pow(10, 1);
                // }

                // if (unit === 'g') {
                //     // Use Math.pow to avoid floating point math.
                //     // weight /= Math.pow(10, 1);
                // }

                // if (unit == 'kg' || unit == 'lbs') {
                //     // Use to add the decimal
                //     weight = (weight * .1).toFixed(1);
                // }

                const scaleState = SCALE_STATES[data[0]];

                let scaleWeight = {
                    weight: weight,
                    unit: unit,
                    scaleState: scaleState
                };

                //this.setState(scaleWeight) - in react?
                if (previousWeight !== weight) {
                    previousWeight = weight;
                    change = true;
                }
                if (change == true) {
                    console.log("data: ", data);
                    console.log('weight', scaleWeight);
                    var event = new CustomEvent("scaleDeviceWeightChange", {
                        detail: scaleWeight
                    });
                    document.dispatchEvent(event);
                }
            }
        })
        .then(() => console.log(_deviceScale))
        .catch(err => console.error('hid error', err))
}
if (navigator.hid) {
    navigator.hid.addEventListener('connect', function (e) {
        connect();
        console.log('connected', e)
        // GlobalService.notification('error', window.translate('Label_Reopen_Pack_And_Ship_Scale_Initialize'));
    })

    navigator.hid.addEventListener('disconnect', function (e) {
        _deviceScale = null;
        GlobalService.notification('error', "Scale Device Disconnected");
        isDeviceConnect = false;
        var event = new CustomEvent("scaleConnectionChange", {
            detail: {
                connected: false
            }
        });
        document.dispatchEvent(event);
        console.log('disconnected', e);
    })

}