import React from 'react';

import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsModel from './../components/WmsModel/WmsModel.jsx';
import WmsGrid from './../components/WmsGrid/WmsGrid.jsx';
import style from './item-copy-rate-model.scss';
import * as GridConfig from './grid_configuration.jsx';
import * as GlobalService from './../global_services';
import { StringWms } from './../../../assets/ts/StringWms.ts';


export class ItemCopyRateModel extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        this.state = {
            selector: "ItemCopyRate",
            columns:GridConfig.getCopyItemGridColumns(GlobalService.getCustomLabels()).columns ,
            schema: GridConfig.getCopyItemGridSchema().schema,
            transport: GridConfig.getCopyItemGridTransport(this.props.sharedCurrentCustomerObj.uri).transport,
            CustomLabels: GlobalService.getCustomLabels(),
            optionsSearchBy: [
                { name: 'SKU', value: 'sku' },
                { name: 'Description', value: 'description' }
            ],
            selectItem: null,
            inputSearchFor: '',
            selectedSearchBy: 'sku'
        };

        this.filterCopyGrid = GlobalService.debounce(this.filterCopyGrid, 700);
    }

    selectRow = (KendoGrid) => {
        if (KendoGrid.getKeys().length == 1) {
            this.setState({ selectItem: KendoGrid.getItem(KendoGrid.getKeys()) });
        } else {
            this.setState({ selectItem: null });
        }
    }

    handleChangeEvent = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.filterCopyGrid();
        });
    }

    filterCopyGrid = () => {
        var grid = $("#copyRateItemGrid").data('kendoGrid');
        let data = { customerUri: this.props.sharedCurrentCustomerObj.uri };

        if (this.state.selectedSearchBy == 'sku' ) {
            data.sku = this.state.inputSearchFor;
        } else if (this.state.selectedSearchBy == 'description') {
            data.description = this.state.inputSearchFor;
        }

        grid.dataSource.transport.options.read.data = data;
        grid.dataSource.read();
    }



    render() {
        return (

            <WmsModel
                id={this.props.windowId + 'itemCopyRate'}
                title={this.translate('Model_Title_Copy_Rate')}
                width={800}
                height={650}
                onCloseFunc={this.props.onClose}
                isOpen={this.props.isOpenCopyRateModal}
                class="item-copy-rate-wrapper"
                customClass='wms-model-new-ui-wrapper'>
                <div className="model-content-wrapper">
                <div className="copy-rate-input-holder">
                    <WmsInput
                        id='inputSearchFor'
                        inputName='inputSearchFor'
                        wmsSelector={this.state.selector + 'SearchFor'}
                        onChangeFunc={this.handleChangeEvent}
                        label={this.translate('Label_Title_Search_For')}
                        value={this.state.inputSearchFor} />

                    <WmsDropdown
                        id="selectedSearchBy"
                        name="selectedSearchBy"
                        label={this.translate('Label_Filter_Search_By')}
                        wmsSelector={this.state.selector + "selectedSearchBy"}
                        onChangeFunc={this.handleChangeEvent}
                        value={this.state.selectedSearchBy}
                        options={this.state.optionsSearchBy}
                        valueField='value'
                        textField='name'
                        parentClassName='form_group'
                        blankFirstOption={true}
                        blankFirstOptionText="Unspecified" />
                </div>

                <WmsGrid
                    wmsSelector={this.state.selector + "Grid"}
                    gridKeySelection={'ItemId'}
                    gridName={'copyRateItemGrid'}
                    virtual={true}
                    id={'copyRateItemGrid'}
                    columns={this.state.columns}
                    schema={this.state.schema}
                    transport={this.state.transport}
                    showGridSummary={true}
                    onSelection={this.selectRow}
                    clickEventOfContextMenu={this.clickEventOfContextMenu}
                    shawCheckbox={false}
                    showResetButton={false}
                    menu={[]}>
                </WmsGrid>
                </div>

                <div className="wms-model-footer footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Close')}
                        wmsSelector={this.state.selector + 'Exit'}
                        onClickFunc={this.props.onClose}
                        buttonType="orange"
                        icon={'fa-times-circle-o'} />

                    <WmsButton
                        label={this.translate('Label_Add_MovableUnitLocationZones')}
                        wmsSelector={this.state.selector + 'Add'}
                        onClickFunc={(e) => this.props.onItemSelectFromCopy(this.state.selectItem.ItemRatesUri, [this.state.selectItem.ItemId])}
                        disabled={this.state.selectItem == null}
                        icon='fa-mouse-pointer' />
                </div>
            </WmsModel>
        );
    }
}