import React from 'react';
import VirtualGridComponent from '../components_proto/VirtualGridComponent/VirtualGridComponent.jsx';
import { LoadingModel } from '../components/LoadingModel/LoadingModel.jsx';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { ArrayWms } from '../../../assets/ts/ArrayWms.ts';
import { KendoWms } from '../../../assets/ts/KendoWms.ts';
import { ConcurrencyManager } from '../../../assets/vendor/concurrencyManager/concurrencyManager.js';
import * as GridConfig from './grid_configuration.virtual.jsx';
import * as GlobalService from '../global_services';
import * as metaDataService from './meta-data.service';
import * as DynamicMenu from './grid_dynamic_menu.virtual.jsx';
import { AddEditColumnModel } from '../components/AddEditColumn/add-edit-columns.component.jsx';
import { ConsolidatePackages } from '../orders/findOrders/model/consolidate-packages/consolidate-packages.component.jsx';
import { ReprintEODReport } from '../orders/findOrders/model/reprint-end-of-day-reoprt/reprint-end-of-day-reoprt.component.jsx';
import { ProcessedBatchedShippingLabels } from '../orders/findOrders/model/processed-batched-shipping-labels/processed-batched-shipping-labels.component.jsx';
import { ReprintShippingLabel } from '../orders/findOrders/model/reprint-shipping-label/reprint-shipping-label.component.jsx';
import { BatchingModel } from '../orders/findOrders/model/batching-model.component.jsx';
import { ConfirmationModal } from './confirmationModal/confirmationModal.component.jsx';
import { PackingSlipsModel } from '../orders/findOrders/model/packing-slips-model.component.jsx';
import { MassShipAndCloseModel } from '../orders/findOrders/model/mass-ship-and-close-model.component.jsx';
import { PickTicketModel } from '../orders/findOrders/model/pick-ticket-model.component.jsx';
import { PackAndShipTransactionModel } from './model/pack-and-ship-transaction-model.component.jsx';
import { EditShippingInfoModel } from './model/edit-shipping-info-model.component.jsx';
import { PackageDefModal } from '../orders/packagingMaterials/package-def-modal.component.jsx';
import { ScanOrderCustomerSelectionModal } from './model/scan-order-customer-selection-modal.component.jsx';
import { _, kendo } from "../global_variable_service.jsx";
import { KendoGridWmsVirtual } from '../../../assets/ts/KendoGridWmsVirtual.ts';
import { reportIframe } from '../reportIframe/report-iframe.component.jsx';
import WmsConfirmModel from "../components/WmsConfirmModel/WmsConfirmModel.jsx";

import CreateContainerManifestRequest from './model/create-container-manifest-request.jsx';
var axios = require('axios');

import * as smallParcelService from './smallParcel.service.jsx';
import * as smallParcelPdfGenerator from './small-parcel-pdf-generator.jsx';
import ReprintContainerManifestModal from './model/reprint-container-manifest-modal.jsx';


export class SmallParcelGrid extends React.Component {

    constructor(props) {
        super(props);

        this.$rootScope = window;
        this.translate = window.translate;
        this.firstFiftyAxiosSource = null;
        this.axiosSource = null;
        this.filterCountAxiosSource = null;
        this.InitialPrintMenuResponse = [];
        this.isSamePrintedFormat = false;
        this.parcelLabelType = 0;
        this.isHasSmallParcelShipDate = false;

        this.isEditCareerInfoBaseCall = '';

        let SmartParcel_GridHeaders = StringWms.convertSringToOption(this.translate('SmallParcel_GridHeaders'));
        this.smallParcelGridId = "SmallParcelRemoteGrid";
        this.isApiCanceled = false;
        this.barcodeDataAll = [{
            "Name": "Custom Dimensions",
            "Description": "Custom Dimensions Package",
            "Barcode": "Custom Dimensions"
        }];
        this.barcodeDataCustomer = {};
        this.barcodeDataStatic = [{
            "Name": "Get Rates",
            "Description": "Get Rates.",
            "Barcode": "Get Rates"
        },{
            "Name": "Pack",
            "Description": "Complete Pack of current order.",
            "Barcode": "Pack"
        }, {
            "Name": "Pack and Ship",
            "Description": "Complete Pack of current order and generate shipping labels.",
            "Barcode": "Pack and Ship"
        }, {
            "Name": "Print Carton Label",
            "Description": "Print carton label for current package.",
            "Barcode": "Print Carton Label"
        }, {
            "Name": "Print All Carton Labels",
            "Description": "Print carton labels for all packages on order.",
            "Barcode": "Print All Carton Labels"
        }, {
            "Name": "Print Transaction Labels",
            "Description": "Print transaction labels for all packages on order.",
            "Barcode": "Print Transaction Labels"
        }, {
            "Name": "New Package",
            "Description": "Scan to jump to the Package field to add a new package to the order.",
            "Barcode": "New Package"
        }, {
            "Name": "Select All Unpacked Items",
            "Description": "Select All Unpacked Items.",
            "Barcode": "Select All Unpacked Items"
        }, {
            "Name": "Pack Selected",
            "Description": "Pack Selected items.",
            "Barcode": "Pack Selected"
        }, {
            "Name": "Clear All",
            "Description": "Clear the packing process.",
            "Barcode": "Clear All"
        }, {
            "Name": "Next Label Template",
            "Description": "Selects the next available Label Template.",
            "Barcode": "NxtLabTemp"
        }, {
            "Name": "Next Carton Label Template",
            "Description": "Selects the next available Carton Label Template.",
            "Barcode": "CrtLabTemp"
        }, {
            "Name": "Next Transaction Label Template",
            "Description": "Selects the next available Transaction Label Template.",
            "Barcode": "TranLabTemp"
        }];
        this.defaultSelectionObj = {
            addToNewBatch: { disabled: false },
            addToExistingBatch: { disabled: false },
            removeFromBatch: { disabled: false }
        };
        this.isDisableCancelVoid = true;
        this.state = {
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.smallParcelGridId : '#' + this.smallParcelGridId,
            selection: this.defaultSelectionObj,
            Selector: "SmallParcel",
            GridName: "SmallParcelRemoteGrid",
            isFilterRowCountLoading: false,
            isSmallParcelGridDataLoading: false,
            scanInputLoading: false,
            SelectedOrders: 0,
            staticGridData: [],
            Rights: GlobalService.GetRights(),
            TplSettings: GlobalService.getTplSettings(),
            defaultColumns: GridConfig.getGridDemoColumns(SmartParcel_GridHeaders, GlobalService.getCustomLabels()).columns,
            schema: GridConfig.getGridDemoSchema(),
            allColumnList: GridConfig.getAllColumnList(SmartParcel_GridHeaders, GlobalService.getCustomLabels()),
            allSortColumnList: [],
            LocalKendoGrid: new KendoGridWmsVirtual(this.smallParcelGridId, this.props.windowId, 'OrderId'),
            isOpenColumnModel: false,
            gifLoaderText: '',
            orderSummary: {
                "TotVolumeMetric": 0,
                "TotVolumeImperial": 0,
                "TotWeightMetric": 0,
                "TotWeightImperial": 0,
                "TotPackages": 0,
                "TotPrimaryUnit": 0,
            },
            gridExportLimit: GlobalService.constants.gridExportLimit,
            totalInLineColumnFilters: 0,
            filterText: "",
            fullFilterText: "",
            smallParcelGridConfig: metaDataService.getSmallParcelGridOptionInMetaData(),
            gridPageSize: GlobalService.constants.virtualSmallParcelGridPageSize,
            overlayScreenShow: false,
            isAllDataLoad: false,
            totalRequest: 0,
            isNewColumnAdded: false,
            selectOrderIds: '',
            recordsCount: 0,
            sharedIsOpenConsolidatePackages: false,
            isOpenPrintBatchedShippingModel: false,
            sharedIsOpenReprintEndOfDayReport: false,
            sharedIsPackAndShipModel: false,
            sharedIsShipInfoModelOpen: false,
            sharedIsReprintShippingLabelOpen: false,
            isOpenPackingSlipsModel: false,
            sharedIsOpenMassShipAndCloseModel: false,
            isOpenPackageDefModal: false,
            sharedSelectedOrderData: null,
            SameCustomer: false,
            SameWarehouse: false,
            SameBatchId: false,
            disabledPackAndShipBtn: false,
            isSelectedOrderShipped:false,
            sharedSelectedGridRecored: '',
            SharedSelectedOrderIds: [],
            manageMenuData: [],
            isScanKeyNotAvailable: false,
            documentMenuData: [],
            EnablePickOption: false,
            uidSelectionString: '',
            isOpenPickTicketModel: false,
            ticketModelConfig: {
                title: '',
                height: 0,
                batchOrderId: null,
                flow: '',
                pickTicketId: "modalPickticket"
            },
            sharedSelectedCustomerIds: [],
            FullyAllocated: false,
            customers: {},
            scannedOrderData: [],
            sharedIsScanOrderCustomerSelectionOpen: false,
            showLoadingError: false,
            barcodeDownloadLoading: false,
            isOrderIsClosed: false,
            currentMeasurementStandardIsImperial: GlobalService.getMeasurementStandardFlag(this.props.sharedSmallParcelFilter.IndexOptionsFacilityId),
            sharedBatchProcess: null,
            isOpenBatchConfirmModal: false,
            sharedIsOpenBatchingModel: false,
            isOpenRemoveBatchConfirmModal: false,
            canRegenPkgs: false,
            isOpenContainerManifestModal: false,
            isOpenReprintContainerManifestModal: false,
            isOpenVoidLabelConfirmModel: false,
            isOpenCancelAndVoidLabelConfirmModel: false,
        }

    }

    componentDidMount() {
        this.getInitialPrintMenu();
        let customerData = GlobalService.getCustomerList(false);
        if(this.$rootScope.ChildBulletin.Data.sharedSelectOrder){
            this.onOpenPackAndShipModel([this.$rootScope.ChildBulletin.Data.sharedSelectOrder]);
        }

        // add event for master picket reload grid data after pick ticket success
        document.addEventListener("EventOrderChanged", this.eventOrderChanged);
        document.addEventListener("EventSmallParcelDataChanged", this.eventOrderChanged);
        document.addEventListener("SmallParcel-openPackAndShipModal", this.eventOpenPackAndShipModel);

        // sort all column list title
        let allSortColumnList = this.state.allColumnList.sort(function (first, second) {

            var columnTitle1 = first.title.toUpperCase(); // ignore upper and lowercase
            var columnTitle2 = second.title.toUpperCase(); // ignore upper and lowercase

            if (columnTitle1 < columnTitle2) { return -1; }

            if (columnTitle1 > columnTitle2) { return 1; }
            // names must be equal
            return 0;
        });

        this.setState({ allSortColumnList: allSortColumnList, customers: customerData });

        if (this.props.sharedSmallParcelFilter && this.props.sharedSmallParcelFilter.IndexOptionsFacilityId > 0) {
            this.callForGetTotalCount();
        }
        kendo.ui.progress($("." + this.props.windowId), true);
        // Get all package list
        smallParcelService.getAllPackage().then((response) => {
            var responseData = response.data;
            // remove extra fields from package
            var responseData = _.map(responseData, function (object) {
                return _.pick(object, [
                    'Name',
                    'Description',
                    'Barcode',
                    'AssignedCustomers',
                    'LengthImperial',
                    'WidthImperial',
                    'HeightImperial',
                    'LengthMetric',
                    'WidthMetric',
                    'HeightMetric'
                ]);
            });

            for (let index = 0; index < responseData.length; index++) {

                const objPackage = responseData[index];
                var tmpDimensions = (
                    this.state.currentMeasurementStandardIsImperial ?
                        (objPackage.LengthImperial ? objPackage.LengthImperial + 'x' : '0x') +
                        (objPackage.WidthImperial ? objPackage.WidthImperial + 'x' : '0x') +
                        (objPackage.HeightImperial ? objPackage.HeightImperial : '0x') :
                        (objPackage.LengthMetric ? objPackage.LengthMetric + 'x' : '0x') +
                        (objPackage.WidthMetric ? objPackage.WidthMetric + 'x' : '0x') +
                        (objPackage.HeightMetric ? objPackage.HeightMetric : '0x')
                );
                objPackage.Description = objPackage.Description + " \n\nDimensions : " + tmpDimensions;
                if (objPackage.AssignedCustomers.length) {
                    for (let indexCust = 0; indexCust < objPackage.AssignedCustomers.length; indexCust++) {
                        const custId = objPackage.AssignedCustomers[indexCust];
                        let customerName = GlobalService.getCustomerById(custId);
                        if (!customerName) {
                            continue;
                        }
                        if (!this.barcodeDataCustomer.hasOwnProperty(customerName.name)) {
                            this.barcodeDataCustomer[customerName.name] = [];
                        }
                        this.barcodeDataCustomer[customerName.name].push(objPackage);
                    }
                } else {
                    // delete objPackage.AssignedCustomers;
                    this.barcodeDataAll.push(objPackage);
                }
            }

            this.setState({
                barcodeDataAll: this.barcodeDataAll,
                barcodeDataCustomer: this.barcodeDataCustomer
            })
        }).finally(() => {
            kendo.ui.progress($("." + this.props.windowId), false);
        });
    }

    componentWillUnmount() {
        document.removeEventListener("EventOrderChanged", this.eventOrderChanged);
        document.removeEventListener("EventSmallParcelDataChanged", this.eventOrderChanged);
        document.removeEventListener("SmallParcel-openPackAndShipModal", this.eventOpenPackAndShipModel);
    }

    eventOpenPackAndShipModel = (e) => {
        this.onOpenPackAndShipModel([e.detail.orderId]);
    }

    eventOrderChanged = (e) => {
        if (e && e.detail && e.detail.source && e.detail.source == "pickTicketApiSuccess") {
            this.resetGridSelectionAfterGridRefresh();
        } else {
            this.resetGridSelectionAndButton();
        }
    }
    resetGridSelectionAfterGridRefresh = () => {
        if (this.state.LocalKendoGrid != null) {
            let orderIds = this.state.LocalKendoGrid.getKeys();
            let uidSelectionString = [];
            orderIds.forEach(key => {
                var selectedItem = this.state.LocalKendoGrid.getItem(key);
                uidSelectionString.push("[data-uid='" + selectedItem.uid + "']");
            });
            this.setState({ uidSelectionString: uidSelectionString });
            this.callForGetTotalCount();
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.sharedSmallParcelFilter.IndexOptionsFacilityId > 0) {
            if (prevProps.sharedSmallParcelFilter && prevProps.sharedSmallParcelFilter !== this.props.sharedSmallParcelFilter) {
                this.clearSelectionOfGrid();
                this.callForGetTotalCount(true, true);
            }
        } else {
            if (prevProps.sharedSmallParcelFilter && prevProps.sharedSmallParcelFilter !== this.props.sharedSmallParcelFilter) {
                this.refs.smallParcelGrid.cleanScanInput();
                this.setState({
                    staticGridData: [],
                    recordsCount: 0
                });
            }
        }
        if (prevProps.gridInlineFilter !== this.props.gridInlineFilter) {
            this.setFilterAppliedText(this.props.gridInlineFilter);
        }
    }
    getInitialPrintMenu() {
        //setting to the first customer in the list for now until we talk to Jeff and come to a resolution
        var customerId = GlobalService.GetCurrentUser().CustomerIds[0];

        GlobalService.initialPrintMenu(customerId).then((response) => {
            if (response) {
                this.InitialPrintMenuResponse = response;
                this.updateInitialPrintMenu();
            }
        }).finally(() => { });
    }

    updateInitialPrintMenu = () => {
        var params = {
            Selector: this.state.Selector,
            Rights: this.state.Rights,
            SelectedOrders: this.state.SelectedOrders,
            isSamePrintedFormat: this.isSamePrintedFormat,
            sameCustomer: this.state.SameCustomer,
            SameWarehouse: this.state.SameWarehouse,
            SameBatchId: this.state.SameBatchId,
            ParcelLabelType: this.parcelLabelType,
            EnablePickOption: this.state.EnablePickOption,
            barcodeDownloadLoading: this.state.barcodeDownloadLoading,
            isOrderIsClosed: this.state.isOrderIsClosed,
            disabledShipAndClose: this.state.disabledShipAndClose,
            canRegenPkgs: this.state.canRegenPkgs,
            isDisableCancelVoid: this.isDisableCancelVoid,
            isHasSmallParcelShipDate: this.isHasSmallParcelShipDate,
        }
        const updatedMenus = DynamicMenu.getInitialPrintMenu(this.InitialPrintMenuResponse, params, this.dynamicMenuClick);

        this.setState({
            manageMenuData: updatedMenus.ManageMenuData,
            documentMenuData: updatedMenus.DocumentMenuData,
        });
    }

    dynamicMenuClick = (event) => {
        var wmsid = $(event.target).parent().attr('data-wmsid');

        if (typeof this[wmsid] !== "undefined") {
            this[wmsid](event);
        } else {
            console.error("Uncaught TypeError: " + wmsid + " is not a function");
        }
    }


    callForGetTotalCount = (clearData = true, clearGridSelection = false) => {
        if (clearGridSelection) {
            this.setState({
                SelectedOrders: 0,
                isAllDataLoad: false,
                isFilterRowCountLoading: true,
                recordsCount: 0,
                showLoadingError: false
            });
        } else {
            this.setState({
                isFilterRowCountLoading: true,
                showLoadingError: false,
                recordsCount: 0
            });
        }
        var param_filterObj = this.getSharedSmallParcelFilter();
        param_filterObj.StartDate = StringWms.convertFilterStringToISODate(param_filterObj.StartDate, '', false, true).isoStringUTC;
        param_filterObj.EndDate = StringWms.convertFilterStringToISODate(param_filterObj.EndDate, '23:59:59', false, true).isoStringUTC;

        if (this.filterCountAxiosSource) {
            this.filterCountAxiosSource.cancel();
            this.filterCountAxiosSource = null;
        }
        // on filter update cancel SmartParcelDataDataAxios call if any running
        this.cancelLoadAllData();
        this.filterCountAxiosSource = axios.CancelToken.source();
        let filterRowCount = 0;
        this.props.onGridLoading(true);
        axios.put('/WebUI/Shipping/SmartParcel/FilteredRowCount',
            param_filterObj,
            {
                cancelToken: this.filterCountAxiosSource.token
            }
        ).then((response) => {
            filterRowCount = response.data;
            var newStateData = {
                recordsCount: response.data
            };
            if (clearData == true) {
                newStateData.staticGridData = [];
            }
            this.filterCountAxiosSource = null;
            this.setState(
                newStateData
                , () => {
                    if (response.data > 0) {
                        this.callForGetNewData();
                    }
                });

        }).finally((e) => {
            // false loading state on filter row count error
            this.setState({ isFilterRowCountLoading: false });
            if (!filterRowCount) {
                this.props.onGridLoading(false);
            }

        });
    }
    callForGetNewData = () => {
        if (this.state.recordsCount > 0 && this.state.recordsCount <= GlobalService.constants.virtualSmallParcelGridDataLimit) {
            this.loadAllRecords(false);
        } else {
            // reset selection of grid on filter data change
            // this.setState({ staticGridData: [] });
            console.log("resetGridAllMessage : call");
            this.props.onGridLoading(false);
            this.resetGridAllMessage();
        }

    }
    setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            this.setState({
                filterText: "",
                fullFilterText: ""
            });
            return;
        }
        var filterConditionTitle = {
            "lte": "Is Less Than Or Equal To",
            "eq": "Is Equal To",
            "lt": "Is Less Than",
            "neq": "Is Not Equal To",
            "gte": "Is Greater Than Or Equal To",
            "gt": "Is Greater Than",
            "startswith": "Starts With",
            "endswith": "Ends With",
            "contains": "Contains",
            "doesnotcontain": "Does Not Contain",
            "isnull": "Is Null",
            "isnotnull": "Is Not Null",
            "isempty": "Is Empty",
            "isnotempty": "Is Not Empty",
            "isnullorempty": "Has No Value",
            "isnotnullorempty": "Has Value",
        };

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;
        var self = this;
        filterList.map(function (item) {
            var columnObj = self.state.allColumnList.find(x => x.field === item.field);
            if (columnObj) {
                var title = columnObj.title;
                filterText += title + " : " + item.value + ',';
                fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            }
        });
        filterText = filterText.slice(0, -1);

        this.setState({
            filterText: filterText,
            fullFilterText: fullFilterText,
        });
    }
    UpdateGridSummary = () => {
        if (this.state.LocalKendoGrid != null) {
            let KendoGrid = this.state.LocalKendoGrid;

            if (this.state.LocalKendoGrid.getNumberOfSelections() >= 1) {
                let isDisabledShipAndClose = false;

                let selectedCustomerData = KendoGrid.getSelectedCustomerData(this.state.customers, this.state.sharedSelectedOrderData.CustomerId);
                let TrackingNumDoesNotExists = KendoGrid.valuesNullOrEmpty("TrackingNumber");
                let TrackingNumRequired = selectedCustomerData.options.requireTrackingNumber;

                // Count only change if selected order is 1 or more
                var TotVolumeMetric = KendoGrid.sumDictionaryParameter("TotVolumeMetric");
                var TotVolumeImperial = KendoGrid.sumDictionaryParameter("TotVolumeImperial");
                var TotWeightMetric = KendoGrid.sumDictionaryParameter("TotWeightMetric");
                var TotWeightImperial = KendoGrid.sumDictionaryParameter("TotWeightImperial");
                var TotPackages = KendoGrid.sumDictionaryParameter("TotPackages");
                var TotPrimaryUnit = KendoGrid.sumDictionaryParameter("TotalItemQty");
                var notFullyAllocated = KendoGrid.valueExists("FullyAllocated", false);
                var IdenticalSetOfOrders = KendoGrid.valuesIdentical("FullyAllocated");
                var SelectedOrders = KendoGrid.getNumberOfSelections();

                // conditional enabling of the PickTicket option for overallocated and multiselected set of orders
                var enablePickTicketOption = true;
                if (SelectedOrders == 1 && notFullyAllocated && !this.state.TplSettings.Shipping.PrintOverAllocPickTickets) {
                    enablePickTicketOption = false;
                } else if (SelectedOrders > 1 && !IdenticalSetOfOrders) {
                    enablePickTicketOption = false;
                } else if (SelectedOrders > 1 && (notFullyAllocated && IdenticalSetOfOrders) && !this.state.TplSettings.Shipping.PrintOverAllocPickTickets) {
                    enablePickTicketOption = false;
                }

                if (TrackingNumRequired && TrackingNumDoesNotExists) {
                    isDisabledShipAndClose = true;
                }

                this.setState({
                    orderSummary: {
                        "TotVolumeMetric": TotVolumeMetric,
                        "TotVolumeImperial": TotVolumeImperial,
                        "TotWeightMetric": TotWeightMetric,
                        "TotWeightImperial": TotWeightImperial,
                        "TotPackages": TotPackages,
                        "TotPrimaryUnit": TotPrimaryUnit,
                    },
                    EnablePickOption: enablePickTicketOption,
                    FullyAllocated: !notFullyAllocated,
                    disabledShipAndClose: isDisabledShipAndClose,
                });
            } else {
                this.totalOrderSummary();
            }
            this.updateInitialPrintMenu();
        }
    }

    selectRow = (KendoGrid, isCallFrom) => {
        // Condition for parcel type start
        const canRegenPkg = KendoGrid.valueDoesNotExist("CanRegenPkgs", false);
        var ParcelLabelTypeArr = [];
        var selection = _.cloneDeep(this.defaultSelectionObj);
        var isShipDateHaveValue = KendoGrid.theNotValueDoesExist("SmallParcelShipDate", null);
        var isParcelLabelTypeHaveValue = KendoGrid.theNotValueDoesExist("ParcelLabelType", 0);
        var isClosed = KendoGrid.valueExists("Status", 'Closed');
        var SameCustomer = KendoGrid.valuesIdentical("CustomerId");
        var SameFacility = KendoGrid.valuesIdentical("FacilityId");
        var orderPartOfBatch = KendoGrid.theNotValueDoesExist("BatchOrderId", null);

        this.isDisableCancelVoid = KendoGrid.valueDoesNotExist("SmallParcelShipDate", null);
        this.isHasSmallParcelShipDate = KendoGrid.valueDoesNotExist("SmallParcelShipDate", null);
        if (SameCustomer !== true) {
            selection.addToNewBatch.disabled = true;
            selection.addToNewBatch.message = this.translate('note_addToNewBatch_samecustomer');
            selection.addToExistingBatch.disabled = true;
            selection.addToExistingBatch.message = this.translate('note_addToExistingBatch_samecustomer');
        }
        if (SameFacility !== true) {
            selection.addToNewBatch.disabled = true;
            selection.addToNewBatch.message = this.translate('note_addToNewBatch_samefacility');
            selection.addToExistingBatch.disabled = true;
            selection.addToExistingBatch.message = this.translate('note_addToExistingBatch_samefacility');
        }
        if (orderPartOfBatch == false) {
            selection.removeFromBatch.disabled = true;
            selection.removeFromBatch.message = this.translate('note_removeFromBatch_orderPartOfBatch');
        }
        // This appears to be looping through our selections.
        // Use this below if you need to get specific data out of the current selections
        // SELECTION LOOP
        var customerIds = [];
        KendoGrid.getKeys().forEach(key => {
            var selectedItem = KendoGrid.getItem(key);
            ParcelLabelTypeArr.push(selectedItem.ParcelLabelType);
            // Push customer ids in array
            if (!customerIds.includes(selectedItem.CustomerId)) {
                customerIds.push(selectedItem.CustomerId);
            }
        });

        this.parcelLabelType = 0;
        if (ParcelLabelTypeArr.includes(1)) {
            this.parcelLabelType = 1;
        } else if (ParcelLabelTypeArr.includes(2)) {
            this.parcelLabelType = 2;
        }
        this.isSamePrintedFormat = KendoGrid.valuesIdentical("ParcelLabelType")

        this.setState({
            LocalKendoGrid: KendoGrid,
            SharedSelectedOrderIds: KendoGrid.getKeys(),
            SelectedOrders: KendoGrid.getNumberOfSelections(),
            sharedSelectedOrderData: KendoGrid.getItem(KendoGrid.getKeys()[0]),
            SameCustomer: KendoGrid.valuesIdentical("CustomerId"),
            SameWarehouse: KendoGrid.valuesIdentical("FacilityId"),
            SameBatchId: KendoGrid.valuesIdentical("BatchOrderId"),
            disabledPackAndShipBtn: isShipDateHaveValue,
            isParcelLabelTypeHaveValue: isParcelLabelTypeHaveValue,
            isSelectedOrderShipped: isShipDateHaveValue,
            isOrderIsClosed: isClosed,
            selection: selection,
            canRegenPkgs: canRegenPkg,
            sharedSelectedCustomerIds: customerIds
        });
        let tempkey = KendoGrid.getKeys();
        if (isCallFrom != 'contextMenuClick' && tempkey.length == 1) {
            let dataItem = {
                'isGridSelectionUpdate': true,
                'dataItem': KendoGrid.getItem(tempkey)
            };
            this.props.onViewSummary(dataItem);
        }
        this.UpdateGridSummary();
    }


    resetGridSelectionAndButton = () => {
        if (this.isEditCareerInfoBaseCall == "packNShipEditCareer") {
            return;
        }
        if (this.state.LocalKendoGrid != null) {
            this.state.LocalKendoGrid.clearSelections(() => {
                this.state.LocalKendoGrid.repaintSelections();
            });
        }
        this.setState({
            SelectedOrders: 0
        }, () => {
            this.updateInitialPrintMenu();
        });
        // call for get new update data
        this.callForGetTotalCount(false);
    }

    onGridDataBound = (e, kendoGrid) => {
        var grid = $(this.state.gridDomSelector()).data('kendoGrid');
        var TotalInLineColumnFilters = KendoWms.getLengthOffFilters(grid.dataSource.filter());
        this.setState({
            totalInLineColumnFilters: TotalInLineColumnFilters,
            LocalKendoGrid: kendoGrid
        });
    }

    // on grid column sort save changes in meta data object
    onSort = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
        metaDataService.setSmallParcelGridOptionInMetaData(finalSubmission);
    }

    // on apply saved filter data save changes in meta data object
    onApplySavedFilterData = (filterObj) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());

        metaDataService.applySavedFilterDataToSmallParcelGrid(finalSubmission, filterObj);
    }
    refreshSmallParcelGrid = () => {
        this.clearSelectionOfGrid();
        this.callForGetTotalCount();
        console.log("callForGetTotalCount : in refreshSmallParcelGrid");
    }
    // on new filter data clear selection of grid
    clearSelectionOfGrid = () => {
        if (this.state.LocalKendoGrid != null) {
            this.state.LocalKendoGrid.clearSelections(() => {
                this.state.LocalKendoGrid.repaintSelections();
            });
        }
    }

    resetGridAllMessage = () => {
        if (this.state.isAllDataLoad) {
            this.setState({
                isNewColumnAdded: true
            });
        }
    }

    // on grid column reorder save changes in meta data object
    onColumnReorder = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(switchedArray, sortArray, grid.dataSource.filter());
        metaDataService.setSmallParcelGridOptionInMetaData(finalSubmission);

    }

    // on grid column resize save changes in meta data object
    onColumnResize = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        metaDataService.setSmallParcelGridOptionInMetaData(finalSubmission);
    }

    // on click grid clear sort menu
    onClickClearSorts = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        metaDataService.setSmallParcelGridOptionInMetaData(finalSubmission);
    }

    // on click grid reset default grid layout menu
    onClickResetGrid = () => {
        var finalSubmission = {
            "optionsData": {}
        };
        metaDataService.setSmallParcelGridOptionInMetaData(finalSubmission);
    }

    // on grid filter selection
    onGridFilterApplied = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            this.setFilterAppliedText(dataSource.filter());

            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, dataSource.filter());

            metaDataService.setSmallParcelGridOptionInMetaData(finalSubmission);

        }, 100);

    }
    eventClickExportGrid = () => {
        $(this.state.gridDomSelector()).getKendoGrid().saveAsExcel();
    }
    eventClickColumns = () => {
        this.setState({
            isOpenColumnModel: true
        });
    }
    onCloseColumnModel = () => {
        this.setState({
            isOpenColumnModel: false
        });
    }
    // save column hide/show data in meta data
    saveChangesInMetaData = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.LocalKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sort,
            grid.dataSource.filter()
        );
        metaDataService.setSmallParcelGridOptionInMetaData(finalSubmission);
    }

    // Load all records of order
    loadAllRecords = (isLoaderPopupShow) => {
        this.isApiCanceled = false;
        if (isLoaderPopupShow == false) {
            this.setState({
                isSmallParcelGridDataLoading: true,
                staticGridData: []
            });
        }
        const sequence = n => {
            let seq = [];
            for (let i = 0; i < n; i++) {
                seq.push(i);
            }
            return seq;
        };
        this.axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/Shipping/SmartParcel",
            headers: { 'Access-Control-Allow-Origin': '*' },
            cancelToken: this.axiosSource.token
        });

        const MAX_CONCURRENT_REQUESTS = 3;

        let enableColumns = this.getEnableColumns();
        let orderSearchInfo = _.cloneDeep(this.getSharedSmallParcelFilter());
        orderSearchInfo.StartDate = StringWms.convertFilterStringToISODate(orderSearchInfo.StartDate, '', false, true).isoStringUTC;
        orderSearchInfo.EndDate = StringWms.convertFilterStringToISODate(orderSearchInfo.EndDate, '23:59:59', false, true).isoStringUTC;
        if (orderSearchInfo.OrderNote) {
            orderSearchInfo.OrderNote = orderSearchInfo.OrderNote.trim();
        }

        let totalRequest = Math.ceil(this.state.recordsCount / this.state.gridPageSize);
        this.setState({
            totalRequest: totalRequest
        });
        this.props.onGridLoading(true);
        // init your manager.
        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS, GlobalService);
        Promise.all(sequence(totalRequest).map(i => api.put("/SmartParcelData?sorts=[]&filters=[]&pgnum=" + (i + 1) + '&pgsiz=' + this.state.gridPageSize, { "columns": enableColumns, "searchInfo": orderSearchInfo })))
            .then(responses => {
                let smallParcelData = responses.map(r => r.data);
                this.getAllResponseData(smallParcelData);
            }).catch((error) => {
                console.log("Promise.all catch", error);
                this.setState({
                    showLoadingError: true
                });
                this.cancelLoadAllData();
            }).then(() => {
                if (manager) {
                    manager.detach();
                }
            });
    }

    onClickLoadAllRecords = () => {
        let enableColumns = this.getEnableColumns();
        let orderSearchInfo = _.cloneDeep(this.getSharedSmallParcelFilter());
        orderSearchInfo.StartDate = StringWms.convertFilterStringToISODate(orderSearchInfo.StartDate, '', false, true).isoStringUTC;
        orderSearchInfo.EndDate = StringWms.convertFilterStringToISODate(orderSearchInfo.EndDate, '23:59:59', false, true).isoStringUTC;
        if (orderSearchInfo.OrderNote) {
            orderSearchInfo.OrderNote = orderSearchInfo.OrderNote.trim();
        }

        this.setState({
            overlayScreenShow: true
        });
        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
            this.firstFiftyAxiosSource = null;
        }
        this.firstFiftyAxiosSource = axios.CancelToken.source();

        axios.put('/WebUI/Shipping/SmartParcel/SmartParcelData?filters=[]&pgnum=1&pgsiz=50', { "columns": enableColumns, "searchInfo": orderSearchInfo }, { cancelToken: this.firstFiftyAxiosSource.token })
            .then((response) => {
                this.firstFiftyAxiosSource = null;
                this.setState({
                    staticGridData: response.data.Data
                }, this.loadAllRecords);
            });
    }
    getEnableColumns = () => {
        var defaultParam = ["OrderId", "FullyAllocated", "FacilityId", "CustomerId", "BatchOrderId", "BatchUri", "CustomerUri", "TotVolumeMetric", "TotVolumeImperial", "TotWeightMetric", "TotWeightImperial", "TotPackages", "TotalItemQty", "ParcelLabelType", "ShipToName", "ShipToCompany", "ShipToAddress", "ShipToAddress2", "ShipToCity", "ShipToState", "ShipToZip", "SmallParcelShipDate", "Status"]
        let columnList = [];

        let grid = $('#SmallParcelRemoteGrid').data('kendoGrid');
        // if grid already defined than we get columns from grid
        if (typeof grid != "undefined") {
            columnList = grid.columns;
        } else { // else get column list from metadataObject or default columns
            let SmartParcel_GridHeaders = StringWms.convertSringToOption(this.translate('SmallParcel_GridHeaders'));
            columnList = GridConfig.getGridDemoColumns(SmartParcel_GridHeaders, GlobalService.getCustomLabels()).columns;
            var smallParcelGridConfig = metaDataService.getSmallParcelGridOptionInMetaData();
            if (smallParcelGridConfig != "") {
                var gridOptions = JSON.parse(smallParcelGridConfig);
                if (gridOptions.columns) {
                    columnList = gridOptions.columns;
                }
            }
        }

        var enableColumns = columnList.map(el => el.field);
        enableColumns.push("TrackingUrl");
        // merge defaultParam and enableColumns without dublicate value
        return [...new Set([...defaultParam, ...enableColumns])];
    }
    totalOrderSummary = () => {
        var staticGridData = this.state.staticGridData;
        // _.sumBy(objects, 'n');
        var TotVolumeMetric = _.sumBy(staticGridData, "TotVolumeMetric");
        var TotVolumeImperial = _.sumBy(staticGridData, "TotVolumeImperial");
        var TotWeightMetric = _.sumBy(staticGridData, "TotWeightMetric");
        var TotWeightImperial = _.sumBy(staticGridData, "TotWeightImperial");
        var TotPackages = _.sumBy(staticGridData, "TotPackages");
        var TotPrimaryUnit = _.sumBy(staticGridData, "TotalItemQty");


        this.setState({
            orderSummary: {
                "TotVolumeMetric": TotVolumeMetric,
                "TotVolumeImperial": TotVolumeImperial,
                "TotWeightMetric": TotWeightMetric,
                "TotWeightImperial": TotWeightImperial,
                "TotPackages": TotPackages,
                "TotPrimaryUnit": TotPrimaryUnit,
            }
        });
    }
    getAllResponseData = (data) => {
        if (!this.isApiCanceled) {
            let allData = [];
            data.forEach(obj => {
                allData = [...obj.Data, ...allData];
            });
            if (this.state.uidSelectionString) {
                let grid = $(this.state.gridDomSelector()).data("kendoGrid");
                grid.select(this.state.uidSelectionString.join(", "));
                this.setState({ uidSelectionString: '' });
            }
            this.setState({
                staticGridData: allData,
                overlayScreenShow: false,
                isAllDataLoad: true,
                isNewColumnAdded: false,
                isSmallParcelGridDataLoading: false
            }, () => {
                // reset selection only for diplay recored in grid for pick ticket
                if (this.state.selectOrderIds) {
                    let grid = $(this.state.gridDomSelector()).data("kendoGrid");
                    var dataSource = $(this.state.gridDomSelector()).data("kendoGrid").dataSource;
                    var filters = dataSource.filter();
                    var query = new kendo.data.Query(dataSource.data());
                    var data = query.filter(filters).data;
                    var assignArr = [];

                    this.state.selectOrderIds.forEach(key => {
                        var selectedItem = this.state.LocalKendoGrid.getItem(key);

                        if (_.find(data, { OrderId: parseInt(key) })) {
                            assignArr.push("[data-uid='" + selectedItem.uid + "']");
                        }
                    });
                    if (assignArr.length) {
                        grid.select(assignArr.join(", "));
                    } else {
                        this.resetGridSelectionAndButton();
                    }
                    this.setState({ selectOrderIds: '' });
                }
                if (this.refs.smallParcelGrid) {
                    this.refs.smallParcelGrid.cleanScanInput();
                    this.refs.smallParcelGrid.setFocusOnScanInput();
                }
                this.totalOrderSummary()
            });
        } else {
            this.setState({
                isSmallParcelGridDataLoading: false
            });
        }
        this.props.onGridLoading(false);
    }
    // Cancel axios call if new filter applied
    cancelLoadAllData = () => {
        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
            this.firstFiftyAxiosSource = null;
        }
        if (this.axiosSource) {
            this.axiosSource.cancel();
            this.axiosSource = null;
        }
        this.isApiCanceled = true;
        this.setState({
            overlayScreenShow: false,
            staticGridData: [],
            isAllDataLoad: false,
            isSmallParcelGridDataLoading: false
        });
        this.props.onGridLoading(false);
    }

    clickEventOfContextMenu = (obj) => {
        if (obj.wmsid == 'consolidatePackages') {
            this.consolidatePackages();
        } else if (obj.wmsid == 'autoRateShop') {
            this.autoRateShop();
        } else if (obj.wmsid == 'recalcPackages') {
            this.recalcPackages();
        } else if (obj.wmsid == 'newPackageType') {
            this.newPackageType();
        } else if (obj.wmsid == 'printBatchedShippingLabel') {
            this.printBatchedShippingLabel();
        } else if (obj.wmsid == 'createIntlDocuments') {
            this.createIntlDocuments();
        } else if (obj.wmsid == 'createPackingSlips') {
            this.createPackingSlips();
        } else if (obj.wmsid == 'createContinerManifestRequest') {
            this.createContinerManifestRequest();
        } else if (obj.wmsid == 'reprintContinerManifestRequest') {
            this.reprintContinerManifestRequest();
        } else if (obj.wmsid == 'createEndofDayRequest') {
            this.createEndofDayRequest();
        } else if (obj.wmsid == 'reprintEndofDayRequest') {
            this.reprintEndofDayRequest();
        } else if (obj.wmsid == 'voidLabelsConfirm') {
            this.voidLabelsConfirm();
        } else if (obj.wmsid == 'resetFailedHvp') {
            this.resetFailedHvp();
        } else if (obj.wmsid == 'packAndSlipModel') {
            this.onOpenPackAndShipModel();
        } else if (obj.wmsid == 'BarcodeSheet') {
            this.BarcodeSheet();
        } else if (obj.wmsid == 'editShippingInfo') {
            this.editShippingInfo();
        } else if (obj.wmsid == 'closeOrder') {
            this.closeOrder();
        } else if (obj.wmsid == 'smallParcelViewSummary') {
            this.smallParcelViewSummary();
        } else if (obj.wmsid == 'pickTicket') {
            this.pickTicket();
        } else if (obj.wmsid == 'masterPickTicket') {
            this.masterPickTicket();
        } else if (obj.wmsid == 'addToNew') {
            this.onOpenBatch('addToNew');
        } else if (obj.wmsid == 'addToExistingBatch') {
            this.onOpenBatch('addToExisting');
        } else if (obj.wmsid == 'removeFromBatch') {
            this.onRemoveFromBatch();
        }
    }

    smallParcelViewSummary = () => {
        let tempkey = this.state.LocalKendoGrid.getKeys();
        let dataItem = {
            'isOnViewSummaryClick': true,
            'dataItem': this.state.LocalKendoGrid.getItem(tempkey)  // technically the key is the orderId as well so this step is redundant but just in case we run into a wierd scenario
        };
        this.props.onViewSummary(dataItem);
    }
    pickTicket = (e) => {
        var customerIds = [];
        this.state.LocalKendoGrid.getKeys().forEach(key => {
            var selectedItem = this.state.LocalKendoGrid.getItem(key);
            if (!customerIds.includes(selectedItem.CustomerId)) {
                customerIds.push(selectedItem.CustomerId);
            }
        });
        console.log("customerIds :", customerIds);
        this.setState({
            isOpenPickTicketModel: true,
            sharedSelectedCustomerIds: customerIds,
            ticketModelConfig: {
                title: this.translate('Label_Pick_Ticket'),
                height: 470,
                batchOrderId: null,
                flow: 'regular',
                pickTicketId: "pickTicketModel"
            },
        });
    }
    masterPickTicket = (e) => {
        this.setState({
            isOpenPickTicketModel: true,
            ticketModelConfig: {
                title: this.translate('Label_Master_Pick_Ticket'),
                height: 325,
                batchOrderId: (this.state.sharedSelectedOrderData != null) ? this.state.sharedSelectedOrderData.BatchOrderId : null,
                flow: 'master',
                pickTicketId: "masterPickTicketModel"
            },
        });
    }
    closePickTicketModel = () => {
        this.setState({
            isOpenPickTicketModel: false,
            ticketModelConfig: {
                title: '',
                height: 0,
                batchOrderId: null,
                flow: '',
                pickTicketId: "modalPickticket"
            },
        });
    }

    consolidatePackages = () => {
        this.setState({ sharedIsOpenConsolidatePackages: true });
    }
    onCloseConsolidatePackages = () => {
        this.setState({ sharedIsOpenConsolidatePackages: false });
    }
    printBatchedShippingLabel = () => {
        this.setState({ isOpenPrintBatchedShippingModel: true });
    }
    closePrintBatchedShippingLabel = () => {
        this.setState({ isOpenPrintBatchedShippingModel: false });
        // Refresh Small Parcel Grid When Modal Close.
        this.refreshSmallParcelGrid();
    }
    recalcPackages = () => {
        var orderIds = this.state.LocalKendoGrid.getKeys().map(function (value) {
            return parseInt(value);
        });

        this.setState({ gifLoaderText: this.translate('Label_Recalc_Packages') });
        smallParcelService.recalcPackages(orderIds).then((response) => {
            GlobalService.notification('success', this.translate('Label_Recalc_Packages_Success'));
            this.resetGridSelectionAndButton();
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }
    newPackageType = () => {
        this.setState({
            isOpenPackageDefModal: true,
            newPkgType: true
        });
    }

    closePackageDefModal = () => {
        this.setState({
            isOpenPackageDefModal: false
        });
    }
    createEndofDayRequest = () => {
        if(this.state.Rights['Integrations.SmallParcelPartner'] == 'ParcelApi') {
            smallParcelService.manifestStarter(this.state.SharedSelectedOrderIds, this.state.sharedSelectedOrderData.CustomerId).then((response) => {
                var targetElement = $('[data-wms-selector="SmallParcelDocuments"]');
                this.$rootScope.animationMoveIcon(targetElement);
                this.$rootScope.taskStarted();
                var transferOrderObj = {
                    "type": "createEndOfDay",
                    "relativeUri": response.data.StatusRelativeUri
                }
                var event = new CustomEvent("showProgressModal", {
                    detail: transferOrderObj,
                });
                document.dispatchEvent(event);
            }).finally(() => { });
        } else {
            this.setState({ gifLoaderText: this.translate('Label_End_of_Day_Request') });

            smallParcelService.parcelFinalizer(this.state.SharedSelectedOrderIds).then((response) => {
                if (typeof response != "undefined" && response.status == 204 || response.status == 200) {
                    GlobalService.notification('success', this.translate('Label_Smart_Parcel_End_of_Day_Request'));

                    if (response != null && response.data != null && response.data.length > 0)
                    {
                        var blob = ArrayWms.convertBase64ToByteArray(response.data, 'application/pdf');
                        ArrayWms.displayBlobInWindow(blob, 'Manifest.pdf');
                    }

                    this.resetGridSelectionAndButton();
                }
            }).finally(() => {
                this.setState({ gifLoaderText: '' });
            });
        }
    }
    reprintEndofDayRequest = () => {
        this.setState({ sharedIsOpenReprintEndOfDayReport: true });
    }
    onCloseReprintEndOfDayReport = () => {
        this.setState({ sharedIsOpenReprintEndOfDayReport: false });
    }

    onOpenPackAndShipModel = (selectedOrderId = null) => {
        let newState = {
            sharedIsPackAndShipModel: true,
        }

        if (selectedOrderId) {
            newState.SharedSelectedOrderIds = selectedOrderId;
        }

        this.setState(newState);
    }
    BarcodeSheet = (selectedOrderId = null) => {
        this.setState({
            barcodeDownloadLoading: true,
            gifLoaderText: this.translate('Label_Barcode_Cheat_Sheet')
        }, () => {
            this.updateInitialPrintMenu();
        });

        smallParcelPdfGenerator.generatePdf(this.barcodeDataStatic, this.state.barcodeDataAll, this.state.barcodeDataCustomer)
            .then((response) => {
                GlobalService.notification('success', "Pdf download completed");
            }).finally(() => {
                this.setState({
                    barcodeDownloadLoading: false,
                    gifLoaderText: ''
                }, () => {
                    this.updateInitialPrintMenu();
                });
            });

    }

    onClosePackAndShipModel = () => {
        this.setState({ sharedIsPackAndShipModel: false });
        if (this.refs.smallParcelGrid) {
            this.refs.smallParcelGrid.cleanScanInput();
            this.refs.smallParcelGrid.setFocusOnScanInput();
        }
    }
    editShippingInfo = (isEditCareerInfoBaseCall = '') => {
        this.isEditCareerInfoBaseCall = isEditCareerInfoBaseCall;
        var sharedSelectedGridRecored = this.state.LocalKendoGrid.getItem(this.state.SharedSelectedOrderIds[0]);
        if (!sharedSelectedGridRecored) {
            var grid = $(this.state.gridDomSelector()).data("kendoGrid");
            var models = grid.dataSource.data();
            sharedSelectedGridRecored = models.find(element => element.OrderId == this.state.SharedSelectedOrderIds[0]);
        }
        this.setState({
            sharedIsShipInfoModelOpen: true,
            sharedSelectedGridRecored: sharedSelectedGridRecored
        });
    }
    onCloseShipInfoModel = (baseCall = '') => {
        if (baseCall == "callFromSave" && this.isEditCareerInfoBaseCall == "packNShipEditCareer") {
            this.refs.smallParcelPackNShipTransaction.updateCarrierAndServiceInfo();
        }
        this.isEditCareerInfoBaseCall = '';
        this.setState({ sharedIsShipInfoModelOpen: false });
    }
    autoRateShop = () => {
        this.setState({ gifLoaderText: this.translate('Label_Shop_Cheapest_Rate') });

        // Call Auto Rate Starter API
        let dataObj = {
            "OrderIds": this.state.SharedSelectedOrderIds,
            "ReturnAllRates": false,
            "MultiOrderRateShopOnShip": false
        }
        smallParcelService.parcelAutoRateShopperStarter(dataObj).then((response) => {
            if(response && response.data) {
                this.getParcelAutoRateShopperStatus(response.data);
            }
        });
    }

    getParcelAutoRateShopperStatus = (data) => {
        smallParcelService.parcelAutoRateShopperStatus(data.StatusRelativeUri).then((response) => {
            if(response.data.Done) {
                GlobalService.notification('success', this.translate('Label_Smart_Parcel_Auto_Rate_Shopping_Success'));
                this.resetGridSelectionAndButton();
                this.setState({ gifLoaderText: '' });
            } else {
                setTimeout(() => {
                    this.getParcelAutoRateShopperStatus(response.data);
                }, 3000);
            }
        }, (err) => {
            this.resetGridSelectionAndButton();
            this.setState({ gifLoaderText: '' });
        });
    };

    createIntlDocuments = () => {
        let orderids = JSON.stringify(this.state.SharedSelectedOrderIds);
        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            iframeMethod: 'PUT',
            orderids: orderids,
            id: '/WebUI/V1/SmartParcelRecreateIntlDocs',
            isIframe: true,
            title: 'Label_Recreate_IntlDocs',
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: 'Label_Recreate_IntlDocs'
            }],
            icon: 'submenu-icon fa fa-pencil-square-o',
            urlParam: 'recreate-international-documents'
        });
    }

    createPackingSlips = (e) => {
        let isSameCustomerID = this.state.LocalKendoGrid.valuesIdentical("CustomerId");

        if (isSameCustomerID == false) {
            GlobalService.notification('error', this.translate('Label_Different_Customer'));
        } else {

            this.setState({ isOpenPackingSlipsModel: true });
        }
    }
    onClosePackingSlipModel = (e) => {
        this.setState({ isOpenPackingSlipsModel: false });
    }
    closeOrder = (e) => {
        this.setState({ sharedIsOpenMassShipAndCloseModel: true });
    }
    onCloseOrderCloseModel = (e) => {
        this.setState({ sharedIsOpenMassShipAndCloseModel: false });
    }

    closeVoidLabelConfirmModal = (e) => {
        // Close Void Label Confirm Modal
        this.setState({ isOpenVoidLabelConfirmModel: false });
    }

    voidLabelsConfirm = () => {
        // Open Void Label Confirm Modal
        this.setState({ isOpenVoidLabelConfirmModel: true });
    }

    voidLabels = () => {

        // Close Void Label Confirm Modal
        this.closeVoidLabelConfirmModal();

        this.setState({ gifLoaderText: this.translate('Label_Void_Labels') });

        smallParcelService.parcelCanceler(this.state.SharedSelectedOrderIds).then((response) => {
            var targetElement = $('[data-wms-selector="SmallParcelManage"]');
            this.$rootScope.animationMoveIcon(targetElement);
            this.$rootScope.taskStarted();
            var transferOrderObj = {
                "type": "voidShippingLabel",
                "relativeUri": response.data.StatusRelativeUri,
                "selectedOrderId": this.state.SharedSelectedOrderIds,
                "callBackFunc": this.resetGridSelectionAndButton
            }
            var event = new CustomEvent("showProgressModal", {
                detail: transferOrderObj
            });
            document.dispatchEvent(event);
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }
    resetFailedHvp = () => {
        this.setState({ gifLoaderText: this.translate('Label_Cancel_Void_Labels') });

        smallParcelService.parcelReseter(this.state.SharedSelectedOrderIds).then((response) => {
            if (typeof response != "undefined" && response.status == 204 || response.status == 200) {
                GlobalService.notification('success', this.translate('Label_Smart_Parcel_Void'));
                this.resetGridSelectionAndButton();
            }
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }

    closeCancelAndVoidLabelConfirmModal = (e) => {
        // Close Cancel And Void Label Confirm Modal
        this.setState({ isOpenCancelAndVoidLabelConfirmModel: false });
    }

    cancelAndVoidShippingLabelConfirm = () => {
        // Open Cancel And Void Label Confirm Modal
        this.setState({ isOpenCancelAndVoidLabelConfirmModel: true });
    }

    cancelandVoidShippingLabel = () => {
        // Close Cancel And Void Label Confirm Modal
        this.closeCancelAndVoidLabelConfirmModal();

        this.setState({ gifLoaderText: this.translate('Label_Cancel_Void_Labels') });

        smallParcelService.parcelVoidCanceler(this.state.SharedSelectedOrderIds).then((response) => {
            if (typeof response != "undefined" && response.status == 204 || response.status == 200) {
                GlobalService.notification('success', this.translate('Label_Smart_Parcel_Cancel_and_Void_Shipping_Labels'));
                this.resetGridSelectionAndButton();
            }
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }

    reprintShippingLabel = () => {
        if (this.parcelLabelType == 2) {
            this.reprintZpl();
        } else {
            this.reprintPdf();
        }
    }

    reprintZpl = () => {
        var reportUri = "/WebUI/Shipping/FindOrders/ParcelReprintZplContents";
        this.setState({ gifLoaderText: this.translate('Label_ReprintZpl') });
        smallParcelService.reprintOrReturnZpl(reportUri, this.state.SharedSelectedOrderIds)
            .then((response) => {
                if (typeof response != "undefined" && response.status == 204 || response.status == 200) {
                    GlobalService.printDataInZplPrinter(response.data);
                }
            }).finally(() => {
                this.setState({ gifLoaderText: '' });
            });
    }

    reprintPdf = () => {
        this.setState({ sharedIsReprintShippingLabelOpen: true });
    }
    onCloseReprintShippingLabel = () => {
        this.setState({ sharedIsReprintShippingLabelOpen: false });
    }

    returnZpl = (e) => {
        var reportUri = $(e.target).parent().attr('data-run-report');
        this.setState({ gifLoaderText: this.translate('Label_Return_Print') });
        smallParcelService.reprintOrReturnZpl(reportUri, this.state.SharedSelectedOrderIds)
            .then((response) => {
                if (typeof response != "undefined" && response.status == 204 || response.status == 200) {
                    GlobalService.printDataInZplPrinter(response.data);
                }
            }).finally(() => {
                this.setState({ gifLoaderText: '' });
            });
    }
    returnPdf = (e) => {
        var reportUri = $(e.target).parent().attr('data-run-report');
        var TemplatedRunnerKey = $(e.target).parent().attr('data-runner-key');
        this.downloadPdf(reportUri, this.state.sharedSelectedOrderData.CustomerId, this.state.SharedSelectedOrderIds, TemplatedRunnerKey, "ReturnLabel");
    }
    downloadPdf = (reportUri, CustomerId, selectedDataIds, TemplatedRunnerKey, pdfFileName) => {
        var pdfDownloadLink = reportUri + '?customerId=' + CustomerId + '&orderIds=' +
            selectedDataIds.toString() + '&templatedRunnerKey=' + TemplatedRunnerKey;

        this.setState({ gifLoaderText: this.translate('Label_Return_Print') });
        smallParcelService.reprintOrReturnPdf(pdfDownloadLink)
            .then((response) => {
                GlobalService.downloadFileBaseOnContentType(response.data, 'application/pdf', pdfFileName, false, 'pdfViewer');
            }).finally(() => {
                this.setState({ gifLoaderText: '' });
            });
    }

    onScanValueEnter = (scanValue) => {
        let facilityId = this.props.sharedSmallParcelFilter.IndexOptionsFacilityId;
        let sValue = scanValue ? scanValue.trim() : "";
        if (facilityId > 0 && sValue && sValue.indexOf(".") === -1 && this.state.scanInputLoading === false) {
            this.setState({ scanInputLoading: true });
            smallParcelService.orderScanResolver(facilityId, scanValue).then((data) => {
                let tmpIsScanKeyNotAvailable = true;

                if (data.length > 0) {
                    tmpIsScanKeyNotAvailable = false;
                    if (data.length == 1) {
                        this.selectOrderAndOpenPackAndShipModel(data[0])
                    } else {
                        this.setState({
                            scannedOrderData: data,
                            sharedIsScanOrderCustomerSelectionOpen: true
                        });
                    }
                } else {
                    if (this.refs.smallParcelGrid) {
                        this.refs.smallParcelGrid.cleanScanInput();
                        this.refs.smallParcelGrid.setFocusOnScanInput();
                    }
                    setTimeout(() => {
                        tmpIsScanKeyNotAvailable = false;
                        this.setState({ isScanKeyNotAvailable: tmpIsScanKeyNotAvailable });
                    }, 3000);
                }

                this.setState({
                    isScanKeyNotAvailable: tmpIsScanKeyNotAvailable
                });
            }).finally(() => {
                this.setState({ scanInputLoading: false });
            });
        }
    }

    selectOrderAndOpenPackAndShipModel = (scanData) => {
        // if(scanData && scanData.OrderId) {
        //     var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        //     var models = grid.dataSource.data();
        //     var model = models.find(element => element.OrderId == scanData.OrderId);
        //     var row = grid.table.find("[data-uid=" + model.uid + "]");
        //     grid.select(row);
        // }
        // Should not use because it's not same as physically select record.
        this.onOpenPackAndShipModel([scanData.OrderId]);
    }

    closeScanOrderCustomerSelection = () => {
        this.setState({ sharedIsScanOrderCustomerSelectionOpen: false });
    }

    onSelectCustomerForScan = (scanObj) => {
        this.closeScanOrderCustomerSelection();
        this.selectOrderAndOpenPackAndShipModel(scanObj)
    }

    onOpenBatch = (param_batch_process) => {
        let ordersFromIndenticalCustomer = this.state.LocalKendoGrid.valuesIdentical("CustomerId");
        let orderPartOfBatch = this.state.LocalKendoGrid.valueDoesNotExist("BatchOrderId", null);

        if (ordersFromIndenticalCustomer == false) {
            GlobalService.notification('error', this.translate('Label_Different_Customer'));
        } else if (orderPartOfBatch == true) {
            this.setState({
                isOpenBatchConfirmModal: true,
                sharedBatchProcess: param_batch_process
            });
        } else {
            this.setState({
                sharedIsOpenBatchingModel: true,
                sharedBatchProcess: param_batch_process
            });
        }
    }
    continueBatchModal = () => {
        this.setState({ isOpenBatchConfirmModal: false }, () => {
            setTimeout(() => { this.setState({ sharedIsOpenBatchingModel: true }); }, 600);
        });
    }
    closeBatchingModel = (e) => {
        this.setState({
            sharedIsOpenBatchingModel: false,
            sharedBatchProcess: null
        });
    }
    autoFilterWithBatch = (batchData) => {
        let smallParcelSearchInfo = Object.assign(
            {},
            this.getSharedSmallParcelFilter(),
            {
                IndexOptionsCustomerId: batchData.CustomerId,
                BatchOrderid: batchData.BatchOrderId
            }
        );

        // //call read for get new option
        var dropdownlist = $("#DropdownlistCustomerBatches").data("kendoDropDownList");
        if (dropdownlist) {
            dropdownlist.dataSource.read().then(() => {
                console.log("smallParcelSearchInfo", smallParcelSearchInfo)
                this.props.onFilterDataSet(smallParcelSearchInfo, true);
            });
        } else {
            this.props.onFilterDataSet(smallParcelSearchInfo, true);
        }

    }
    // ---- If customer is deactivated and still that customer is set in filter, we are removing it before grid loading ----
    getSharedSmallParcelFilter = () => {
        let smallParcelFilterData = Object.assign({}, this.props.sharedSmallParcelFilter);
        if (smallParcelFilterData.IndexOptionsCustomerId != 0) {
            let selectedCustomer = GlobalService.getCustomerById(smallParcelFilterData.IndexOptionsCustomerId);
            if (selectedCustomer == null) {
                smallParcelFilterData.IndexOptionsCustomerId = 0;
            }
        }

        return smallParcelFilterData
    }
    onRemoveFromBatch = (e) => {
        let orderPartOfBatch = this.state.LocalKendoGrid.valueDoesNotExist("BatchOrderId", null);
        if (orderPartOfBatch == false) {
            GlobalService.notification('error', this.translate('Label_Different_Customer'));
        } else {
            this.setState({ isOpenRemoveBatchConfirmModal: true });
        }
    }
    continueRemoveBatchModal = () => {
        let batchSelections = [];
        for (var i = 0; i < this.state.SharedSelectedOrderIds.length; i++) {
            var smallParcelDataRow = this.state.LocalKendoGrid.getItem(this.state.SharedSelectedOrderIds[i]);
            batchSelections.push({
                BatchUri: smallParcelDataRow.BatchUri,
                OrderId: smallParcelDataRow.OrderId
            });
        }

        smallParcelService.removeFromBatch(batchSelections).then((response) => {

            if (response != 'undefined' && (response.status == 200 || response.status == 204)) {

                GlobalService.notification('success', this.translate('Label_BatchRemove_Success'));
                this.setState({ isOpenRemoveBatchConfirmModal: false });
                this.resetGridSelectionAndButton();

            }
        }).finally(() => { });
    }
    setModelState = (param_model_state, param_bool) => {
        this.setState({ [param_model_state]: param_bool });
    }

    reprintContinerManifestRequest = () => {
        this.setState({
            isOpenReprintContainerManifestModal: true
        });
    }

    closeReprintContainerManifestModal = () => {
        this.setState({
            isOpenReprintContainerManifestModal: false
        });
    }

    createContinerManifestRequest = () => {
        this.setState({
            isOpenContainerManifestModal: true
        })
    }
    closeContainerManifestModal = () => {
        this.setState({
            isOpenContainerManifestModal: false,
        });
    }

    render() {
        return (
            <div className={"small-parcel-grid new-layout-grid-wrapper" + (this.props.sharedSelectOrder ? ' order-summary-opened' : '')}>
                {this.state.overlayScreenShow && (
                    <LoadingModel
                        sharedOpenLoadingmodel={this.state.overlayScreenShow}
                        onCancelLoadAllData={this.cancelLoadAllData}
                        sharedTotalRequest={this.state.totalRequest}
                    />
                )}
                <div className="wms-search-grid-data">
                    {this.state.manageMenuData.length > 0 && (
                        <VirtualGridComponent
                            ref="smallParcelGrid"
                            loading={this.state.isFilterRowCountLoading || this.state.isSmallParcelGridDataLoading}
                            virtualGridDataLimit={GlobalService.constants.virtualSmallParcelGridDataLimit}
                            isNewColumnAdded={this.state.isNewColumnAdded}
                            gifLoaderText={this.state.gifLoaderText}
                            staticGridData={this.state.staticGridData}
                            wmsSelector={this.state.Selector + "Grid"}
                            showSortColumns={true}
                            showGridSummary={true}
                            callForGetNewData={this.callForGetNewData}
                            gridKeySelection={'OrderId'}
                            showGridResult={true}
                            totalResult={this.state.recordsCount}
                            gridName={this.state.GridName}
                            isBindFilterColumnHoverEvent={true}
                            filterText={this.state.filterText}
                            fullFilterText={this.state.fullFilterText}
                            virtual={true}
                            metaDataFieldsName={'Orders'}
                            emptyRecoredText={this.translate('Window_Title_Label_Parcel_Ship_No_Recoreds')}
                            exportExcelObj={{
                                fileName: "orderGridExport-" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + ".xlsx",
                                allPages: true,
                                filterable: true
                            }}
                            exportable={true}
                            // exportDisable={(this.state.totalOrders > this.state.gridExportLimit || this.state.totalInLineColumnFilters > 1) ? true : false}
                            // exportGridDisabledText={this.translate('note_Export_GridLimit').replace(new RegExp('{' + 'dynamicAmount' + '}', 'gi'), this.state.gridExportLimit)}
                            id={this.smallParcelGridId}
                            defaultColumns={this.state.defaultColumns}
                            allColumns={this.state.allColumnList}
                            schema={this.state.schema}
                            onSelection={this.selectRow}
                            onDataBound={this.onGridDataBound}
                            onSort={this.onSort}
                            onColumnReorder={this.onColumnReorder}
                            onColumnResize={this.onColumnResize}
                            onClickClearSorts={this.onClickClearSorts}
                            onClickResetGrid={this.onClickResetGrid}
                            windowId={this.props.windowId}
                            clickEventOfContextMenu={this.clickEventOfContextMenu}
                            eventClickExportGrid={this.eventClickExportGrid}
                            eventClickColumns={this.eventClickColumns}
                            onGridFilterApplied={this.onGridFilterApplied}
                            metaGridConfig={this.state.smallParcelGridConfig}
                            loadAllRecords={this.onClickLoadAllRecords}
                            isAllDataLoad={this.state.isAllDataLoad}
                            isFindOrderSummary={true}
                            orderSummary={this.state.orderSummary}
                            loadAllMessageFirst={this.translate('Label_Order_Grid_Filter_Message')}
                            loadAllMessageSecond={this.translate('Label_Order_Grid_Filter_Message_2')}
                            OnScanValueEnter={this.onScanValueEnter}
                            shawScanKeyInput={true}
                            shawScanKeyTooltip={true}
                            scanKeyTooltipText={this.translate('Label_Scan_Key_Tooltip')}
                            disableScanKeyInput={(this.props.sharedSmallParcelFilter.IndexOptionsFacilityId <= 0)}
                            scanInputLoading={this.state.scanInputLoading}
                            isScanKeyNotAvailable={this.state.isScanKeyNotAvailable}
                            showLoadingError={this.state.showLoadingError}
                            menu={
                                [{
                                    "name": this.translate("Label_Pack_And_Ship"),
                                    "value": [],
                                    "disabled": this.state.SelectedOrders == 0 || this.state.isOrderIsClosed || (this.state.disabledPackAndShipBtn && !this.state.Rights.hasOwnProperty('Shipping.WarehousePackEnabled')) ||
                                        (this.state.disabledPackAndShipBtn && this.state.SelectedOrders > 1),
                                    "showToolTip": (this.state.disabledPackAndShipBtn && !this.state.Rights.hasOwnProperty('Shipping.WarehousePackEnabled')) ||
                                        (this.state.disabledPackAndShipBtn && this.state.SelectedOrders > 1) || this.state.isOrderIsClosed,
                                    "tooltipText": this.state.isOrderIsClosed ? translate('Note_Disable_Status_Is_close') : this.translate('Label_Pack_and_Ship_Disable_Text'),
                                    "icon": "fa-box",
                                    "color": "green",
                                    "wmsSelector": this.state.Selector + "PackAndSlip",
                                    'isContextMenu': true,
                                    "clickFunc": (e) => this.onOpenPackAndShipModel(),
                                    'isButtonMenu': true,
                                    'wmsid': 'packAndSlipModel'
                                }, {
                                    "name": this.translate('Label_Manage'),
                                    "value": this.state.manageMenuData,
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    "wmsSelector": this.state.Selector + "Manage",
                                    "clickFunc": () => { },
                                    'isContextMenu': true,
                                    'isButtonMenu': true
                                }, {
                                    "name": this.translate('Label_Batching'),
                                    "wmsSelector": this.state.Selector + "Batching",
                                    "value": [{
                                        "name": this.translate('Label_Add_To_New'),
                                        "value": [],
                                        "disabled": (this.state.SelectedOrders < 1 || this.state.selection.addToNewBatch.disabled),
                                        "disabledText": this.state.selection.addToNewBatch.message,
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        "wmsSelector": this.state.Selector + "AddToNewBatch",
                                        "onClickFunc": () => this.onOpenBatch('addToNew'),
                                        'wmsid': 'addToNew'
                                    },
                                    {
                                        "name": this.translate('Label_Add_Orders_To_Existing_Batch'),
                                        "value": [],
                                        "disabled": (this.state.SelectedOrders < 1 || this.state.selection.addToExistingBatch.disabled),
                                        "disabledText": this.state.selection.addToExistingBatch.message,
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        "wmsSelector": this.state.Selector + "AddToExistingBatch",
                                        "onClickFunc": () => this.onOpenBatch('addToExisting'),
                                        'wmsid': 'addToExistingBatch'
                                    },
                                    {
                                        "name": this.translate('Label_Remove_From_Batch'),
                                        "value": [],
                                        "disabled": (this.state.SelectedOrders < 1 || this.state.selection.removeFromBatch.disabled),
                                        "disabledText": this.state.selection.removeFromBatch.message,
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        "wmsSelector": this.state.Selector + "RemoveFromBatch",
                                        "onClickFunc": this.onRemoveFromBatch,
                                        'wmsid': 'removeFromBatch'
                                    }],
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-layer-group",
                                    "color": "green",
                                    'isContextMenu': this.state.Rights.hasOwnProperty('orders.manage.batch'),
                                    'isButtonMenu': this.state.Rights.hasOwnProperty('orders.manage.batch'),
                                }, {
                                    "name": this.translate('NavBarV1_Btn_Documents'),
                                    "wmsSelector": this.state.Selector + this.translate('NavBarV1_Btn_Documents'),
                                    "value": this.state.documentMenuData,
                                    "clickFunc": (e) => null,
                                    "disabled": (this.state.SelectedOrders < 1),
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-print",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': true
                                }, {
                                    "name": '',
                                    "wmsSelector": 'RefreshGrid',
                                    "value": [],
                                    "clickFunc": this.refreshSmallParcelGrid,
                                    "disabled": false,
                                    "icon": "fa-sync-alt",
                                    "color": "blue",
                                    'isContextMenu': false,
                                    'isButtonMenu': true,
                                    'parentClassName': 'refreshSmalParcelGrid'
                                }, {
                                    "name": "Copy",
                                    "value": [{
                                        "name": this.translate('Label_Copy_Field'),
                                        "value": [],
                                        "disabled": false,
                                        "disabledText": this.translate('Label_Copy_Field'),
                                        "icon": "fa-bandcamp",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': false,
                                        'wmsid': 'copyField',
                                        "wmsSelector": "copyToClicpboard",
                                    }, {
                                        "name": this.translate('Label_Copy_Row'),
                                        "value": [],
                                        "disabled": false,
                                        "disabledText": this.translate('Label_Copy_Row'),
                                        "icon": "fa-wrench",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': false,
                                        'wmsid': 'copyRow'
                                    }],
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false
                                }]
                            }
                        />
                    )}
                </div>
                {this.state.isOpenColumnModel && (
                    <AddEditColumnModel
                        id={"addEditColumnSmallParcel"}
                        height={700}
                        windowId={this.props.windowId}
                        onCloseColumnModel={this.onCloseColumnModel}
                        isOpenColumnModel={this.state.isOpenColumnModel}
                        allSortColumnList={this.state.allSortColumnList}
                        gridId={this.smallParcelGridId}
                        updateGridData={this.callForGetNewData}
                        saveChangesInMetaData={this.saveChangesInMetaData}
                        isShawRowNumberColumnInList={true}
                    />
                )}
                {this.state.sharedIsOpenConsolidatePackages && (
                    <ConsolidatePackages
                        sharedIsOpenConsolidatePackages={this.state.sharedIsOpenConsolidatePackages}
                        onCloseConsolidatePackages={this.onCloseConsolidatePackages}
                        SharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        onResetGridSelection={this.resetGridSelectionAndButton}
                        facilityId={this.props.sharedSmallParcelFilter.IndexOptionsFacilityId}
                    />
                )}
                {this.state.isOpenPrintBatchedShippingModel && (
                    <ProcessedBatchedShippingLabels
                        closePrintBatchedShippingLabel={this.closePrintBatchedShippingLabel}
                        isOpenPrintBatchedShippingModel={this.state.isOpenPrintBatchedShippingModel} />
                )}
                {this.state.sharedIsOpenReprintEndOfDayReport && (
                    <ReprintEODReport
                        sharedIsOpenReprintEndOfDayReport={this.state.sharedIsOpenReprintEndOfDayReport}
                        onCloseReprintEndOfDayReport={this.onCloseReprintEndOfDayReport}
                        windowId={this.props.windowId}
                    />
                )}
                {this.state.sharedIsPackAndShipModel && (
                    <PackAndShipTransactionModel
                        isSameCustomerCallOrderApi={this.state.SharedSelectedOrderIds.length > 1 && this.state.SameCustomer}
                        ref="smallParcelPackNShipTransaction"
                        sharedIsPackAndShipModel={this.state.sharedIsPackAndShipModel}
                        windowId={this.props.windowId}
                        onClosePackAndShipModel={this.onClosePackAndShipModel}
                        editShippingInfo={this.editShippingInfo}
                        sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        sharedSelectedCustomerIds={this.state.sharedSelectedCustomerIds}
                        onResetGridSelection={this.resetGridSelectionAndButton}
                        facilityId={this.props.sharedSmallParcelFilter.IndexOptionsFacilityId}
                    />
                )}
                {this.state.sharedIsShipInfoModelOpen && (
                    <EditShippingInfoModel
                        sharedIsShipInfoModelOpen={this.state.sharedIsShipInfoModelOpen}
                        windowId={this.props.windowId}
                        onCloseShipInfoModel={this.onCloseShipInfoModel}
                        sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        sharedSelectedGridRecored={this.state.sharedSelectedGridRecored}
                        onResetGridSelection={this.resetGridSelectionAndButton}
                        isSelectedOrderShipped={this.state.isSelectedOrderShipped}
                    />
                )}
                {this.state.sharedIsReprintShippingLabelOpen && (
                    <ReprintShippingLabel
                        sharedIsReprintShippingLabelOpen={this.state.sharedIsReprintShippingLabelOpen}
                        onCloseReprintShippingLabel={this.onCloseReprintShippingLabel}
                        SharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        SharedReprintLabelsReport={this.InitialPrintMenuResponse}
                        sharedSelectedOrderData={this.state.sharedSelectedOrderData}
                        sharedIsSameCustomer={this.state.SameCustomer}
                    />
                )}
                {this.state.sharedIsScanOrderCustomerSelectionOpen && (
                    <ScanOrderCustomerSelectionModal
                        sharedIsScanOrderCustomerSelectionOpen={this.state.sharedIsScanOrderCustomerSelectionOpen}
                        onCloseScanOrderCustomerSelection={this.closeScanOrderCustomerSelection}
                        sharedScannedOrderData={this.state.scannedOrderData}
                        onClickContinue={this.onSelectCustomerForScan}
                    />
                )}
                {this.state.isOpenPackingSlipsModel && (
                    <PackingSlipsModel
                        sharedIsOpenPackingSlipsModel={this.state.isOpenPackingSlipsModel}
                        windowId={this.props.windowId}
                        sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        sharedSelectedOrderData={this.state.sharedSelectedOrderData}
                        onClose={this.onClosePackingSlipModel}
                    />
                )}
                {this.state.sharedIsOpenMassShipAndCloseModel && (

                    <MassShipAndCloseModel
                        sharedIsOpenMassShipAndCloseModel={this.state.sharedIsOpenMassShipAndCloseModel}
                        sharedSelectedOrderData={this.state.sharedSelectedOrderData}
                        windowId={this.props.windowId}
                        sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        onCloseShipAndClose={this.onCloseOrderCloseModel}
                        onResetGridSelection={this.resetGridSelectionAndButton}
                        callFrom="SmallParcelSuit"
                    />
                )}
                {this.state.isOpenPackageDefModal && (
                    <PackageDefModal
                        id={"packageDefModal"}
                        isNewPackage={this.state.newPkgType}
                        sharedIsOpenPackageDefModal={this.state.isOpenPackageDefModal}
                        onClosePackageDefModal={this.closePackageDefModal}
                        onSavePackageDef={() => { }}
                        sharedSelectedPkgType={''}
                    />
                )}
                {this.state.isOpenPickTicketModel && (
                    <PickTicketModel
                        id={this.state.ticketModelConfig.pickTicketId}
                        title={this.state.ticketModelConfig.title}
                        width={600}
                        height={this.state.ticketModelConfig.height}
                        sharedIsOpenPickTicketModel={this.state.isOpenPickTicketModel}
                        customerId={(this.state.sharedSelectedOrderData != null) ? this.state.sharedSelectedOrderData.CustomerId : 0}
                        sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        batchOrderId={this.state.ticketModelConfig.batchOrderId}
                        flow={this.state.ticketModelConfig.flow}
                        onClose={this.closePickTicketModel}
                        windowId={this.props.windowId}
                        fullyAllocated={this.state.FullyAllocated}
                        sharedSelectedCustomerIds={this.state.sharedSelectedCustomerIds}
                    />
                )}
                <ConfirmationModal
                    SharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                    isOpenBatchConfirmModal={this.state.isOpenBatchConfirmModal}
                    onCloseBatchModal={(e) => this.setModelState('isOpenBatchConfirmModal', false)}
                    onContinueBatchModal={this.continueBatchModal}
                    isOpenRemoveBatchConfirmModal={this.state.isOpenRemoveBatchConfirmModal}
                    onCloseRemoveBatchModal={(e) => this.setModelState('isOpenRemoveBatchConfirmModal', false)}
                    onContinueRemoveBatchModal={this.continueRemoveBatchModal}
                />
                {this.state.sharedIsOpenBatchingModel && (
                    <BatchingModel
                        sharedIsOpenBatchingModel={this.state.sharedIsOpenBatchingModel}
                        sharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                        sharedSelectedOrderData={this.state.sharedSelectedOrderData}
                        sharedBatchProcess={this.state.sharedBatchProcess}
                        onCloseBatchingModel={this.closeBatchingModel}
                        onResetGridSelection={this.resetGridSelectionAndButton}
                        windowId={this.props.windowId}
                        autoFilterWithBatch={this.autoFilterWithBatch}
                    />
                )}
                {this.state.isOpenContainerManifestModal && (
                    <CreateContainerManifestRequest
                        windowId={this.props.windowId}
                        id={"create-container-manifest-request-modal"}
                        title={this.translate("Label_Print_Container_Manifest")}
                        width={400}
                        height={800}
                        closeContainerManifestModal={this.closeContainerManifestModal}
                        customer={(this.props.sharedSmallParcelFilter != null && this.props.sharedSmallParcelFilter.IndexOptionsCustomerId != 0) ? this.props.sharedSmallParcelFilter.IndexOptionsCustomerId : 0}
                        isOpenContainerManifestModal={this.state.isOpenContainerManifestModal}
                        SharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                    />
                )}
                {this.state.isOpenReprintContainerManifestModal && (
                    <ReprintContainerManifestModal
                        windowId={this.props.windowId}
                        closeReprintContainerManifestModal={this.closeReprintContainerManifestModal}
                        customer={(this.props.sharedSmallParcelFilter != null && this.props.sharedSmallParcelFilter.IndexOptionsCustomerId != 0) ? this.props.sharedSmallParcelFilter.IndexOptionsCustomerId : 0}
                        isOpenContainerManifestModal={this.state.isOpenReprintContainerManifestModal}
                        SharedSelectedOrderIds={this.state.SharedSelectedOrderIds}
                    />
                )}
                <div>
                    <WmsConfirmModel
                        id={"VoidLabelConfirmModel" + this.props.windowId}
                        isOpen={this.state.isOpenVoidLabelConfirmModel}
                        type={this.translate("Label_Confirmation")}
                        width={500}
                        height={175}
                        firstBtnLabel={this.translate("Label_Yes")}
                        firstBtnIcon={"fa-check-square"}
                        firstBtnOnClickFunc={this.voidLabels}
                        sawCheckBox={false}
                        conFormModelText={this.translate("Label_Are_You_Sure_Void_Label")}
                        onCloseFunc={this.closeVoidLabelConfirmModal}
                        thirdBtnLabel={this.translate("Label_No")}
                        thirdBtnIcon={"fa-times-circle"}
                    />
                    <WmsConfirmModel
                        id={"CancelAndVoidLabelConfirmModel" + this.props.windowId}
                        isOpen={this.state.isOpenCancelAndVoidLabelConfirmModel}
                        type={this.translate("Label_Confirmation")}
                        width={500}
                        height={175}
                        firstBtnLabel={this.translate("Label_Yes")}
                        firstBtnIcon={"fa-check-square"}
                        firstBtnOnClickFunc={this.cancelandVoidShippingLabel}
                        sawCheckBox={false}
                        conFormModelText={this.translate("Label_Are_You_Sure_Cancel_Void_Label")}
                        onCloseFunc={this.closeCancelAndVoidLabelConfirmModal}
                        thirdBtnLabel={this.translate("Label_No")}
                        thirdBtnIcon={"fa-times-circle"}
                    />
                </div>
            </div>
        );
    }
}