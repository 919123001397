
export const getGridDemoColumns = () => {

    var translate = window.translate;
    return ({

        columns: [{
            field: "FacilityId",
            title: "Warehouse Id",
            width: 120,
            wmsid: 1,
            filterable: true,
            hidden: true
        }, {
            field: "Name",
            title: "Warehouse Name",
            width: 120,
            wmsid: 2,
            filterable: true,
        },
        {
            field: "Active",
            title: "Status",
            width: 120,
            wmsid: 3,
            filterable: true,
            template: "#= Active == true ? '" +translate('Label_Active') + "' : '" +translate('Label_Inactive') + "' #",
        },
        {
            field: "Code",
            title: "Warehouse Code",
            width: 120,
            wmsid: 4,
            filterable: true,
            hidden: true
        }, {
            field: "UpsAccount",
            title: "UPS Account Number",
            width: 120,
            wmsid: 5,
            filterable: true,
            hidden: true
        }, {
            field: "FedExAccount",
            title: "Fed-Ex Account Number",
            width: 120,
            wmsid: 6,
            filterable: true,
            hidden: true
        },
        {
            field: "FuelSurcharge",
            title: "Fuel Surcharge",
            width: 120,
            wmsid: 7,
            filterable: true,
            hidden: true
        },
        {
            field: "LocationFieldCount",
            title: "Number of Fields Per Location",
            width: 120,
            wmsid: 8,
            filterable: true,
            hidden: true
        }, {
            field: "MeasurementSystemDefault",
            title: "Measurement Standard",
            width: 120,
            wmsid: 9,
            filterable: true,
            hidden: true
        }, {
            field: "FreightRateLocationCode",
            title: "External Warehouse Code",
            width: 120,
            wmsid: 10,
            filterable: true,
            hidden: true
        }, {
            field: "TimeZoneName",
            title: "TimeZone",
            width: 120,
            wmsid: 11,
            filterable: true,
            hidden: true
        }, {
            field: "ContactId",
            title: "Contact Id",
            width: 120,
            wmsid: 12,
            filterable: true,
            hidden: true
        },
        {
            field: "Address1",
            title: "Address1",
            width: 120,
            wmsid: 13,
            filterable: true
        },
        {
            field: "Address2",
            title: "Address2",
            width: 120,
            wmsid: 14,
            filterable: true,
            hidden: true
        },
        {
            field: "City",
            title: "City",
            width: 120,
            wmsid: 15,
            filterable: true
        },
        {
            field: "State",
            title: "State",
            width: 120,
            wmsid: 16,
            filterable: true
        },
        {
            field: "Zip",
            title: "Zip",
            width: 120,
            wmsid: 17,
            filterable: true,
            hidden: true
        },
        {
            field: "Country",
            title: "Country",
            width: 120,
            wmsid: 18,
            filterable: true,
            hidden: true
        },
        {
            field: "PhoneNumber",
            title: "Phone Number",
            width: 120,
            wmsid: 19,
            filterable: true,
            hidden: true
        },
        {
            field: "Fax",
            title: "Fax Number",
            width: 120,
            wmsid: 20,
            filterable: true,
            hidden: true
        },
        {
            field: "EmailAddress",
            title: "Email",
            width: 120,
            wmsid: 21,
            filterable: true,
            hidden: true
        },
        {
            field: "CanDeactivate",
            title: "Can Deactivate",
            width: 120,
            wmsid: 22,
            filterable: true,
            hidden: true
        },
        {
            field: "EditUri",
            title: "EditUri",
            width: 120,
            wmsid: 23,
            filterable: true,
            hidden: true
        }]
    })
}


export const getGridDemoSchema = () =>{
    return({
        schema: {
            data: "Data",
            total: "Total",

            model: {
                id: "FacilityId",
                fields: {
                    FacilityId: {
                        type: "number"
                    },
                    Name: {
                        type: "string"
                    },
                    Active: {
                        type: "boolean"
                    },
                    Code: {
                        type: "string"
                    },
                    UpsAccount: {
                        type: "string"
                    },
                    FedExAccount: {
                        type: "string"
                    },
                    FuelSurcharge: {
                        type: "number"
                    },
                    LocationFieldCount: {
                        type: "number"
                    },
                    MeasurementSystemDefault: {
                        type: "number"
                    },
                    FreightRateLocationCode: {
                        type: "string"
                    },
                    TimeZoneName: {
                        type: "string"
                    },
                    ContactId: {
                        type: "number"
                    },
                    Address1: {
                        type: "string"
                    },
                    Address2: {
                        type: "string"
                    },
                    City: {
                        type: "string"
                    },
                    State: {
                        type: "string"
                    },
                    Zip: {
                        type: "number"
                    },
                    Country: {
                        type: "string"
                    },
                    PhoneNumber: {
                        type: "string"
                    },
                    Fax: {
                        type: "string"
                    },
                    EmailAddress: {
                        type: "string"
                    },
                    CanDeactivate: {
                        type: "boolean"
                    },
                    EditUri: {
                        type: "string"
                    }
                }
            }
        }
    })
}

export const getGridDemoTransport = () =>{
    return ({})
}