import React from 'react';
import * as GlobalService from '../global_services';

export const getGridDemoColumns = (attachFileAssembly_GridHeaders, selector) => {

    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    return ({

        columns: [{
            field: "AssemblyId",
            title: attachFileAssembly_GridHeaders[0].description,
            width: 120,
            wmsid: 1
        },
        {
            field: "Customer",
            title: attachFileAssembly_GridHeaders[1].description,
            width: 120,
            lockable: false,
            wmsid: 2,
            headerAttributes: {
                "data-wms-selector": selector + 'Customer'
            }
        },
        {
            field: "Warehouse",
            lockable: false,
            title: attachFileAssembly_GridHeaders[2].description,
            width: 120,
            wmsid: 3,
            headerAttributes: {
                "data-wms-selector": selector + 'Warehouse'
            }
        },
        {
            field: "ReferenceNum",
            lockable: false,
            title: attachFileAssembly_GridHeaders[3].description,
            width: 120,
            wmsid: 4,
            headerAttributes: {
                "data-wms-selector": selector + 'ReferenceNum'
            }
        },
        {
            field: "Status",
            lockable: false,
            title: attachFileAssembly_GridHeaders[4].description,
            width: 120,
            wmsid: 5,
            headerAttributes: {
                "data-wms-selector": selector + 'Status'
            }
        },
        {
            field: "Notes",
            lockable: false,
            title: attachFileAssembly_GridHeaders[5].description,
            width: 120,
            wmsid: 6,
            headerAttributes: {
                "data-wms-selector": selector + 'Notes'
            }
        }]
    });
}

export const getGridAllColumnList = (attachFileAssembly_GridHeaders, selector) => {

    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    return ({

        columns: [{
            field: "AssemblyId",
            title: attachFileAssembly_GridHeaders[0].description,
            width: 120,
            hidden: false,
            wmsid: 1
        },
        {
            field: "Customer",
            title: attachFileAssembly_GridHeaders[1].description,
            width: 120,
            lockable: false,
            wmsid: 2,
            headerAttributes: {
                "data-wms-selector": selector + 'Customer'
            }
        },
        {
            field: "Warehouse",
            lockable: false,
            title: attachFileAssembly_GridHeaders[2].description,
            width: 120,
            wmsid: 3,
            headerAttributes: {
                "data-wms-selector": selector + 'Warehouse'
            }
        },
        {
            field: "ReferenceNum",
            lockable: false,
            title: attachFileAssembly_GridHeaders[3].description,
            width: 120,
            wmsid: 4,
            headerAttributes: {
                "data-wms-selector": selector + 'ReferenceNum'
            }
        },
        {
            field: "Status",
            lockable: false,
            title: attachFileAssembly_GridHeaders[4].description,
            width: 120,
            wmsid: 5,
            headerAttributes: {
                "data-wms-selector": selector + 'Status'
            }
        },
        {
            field: "Notes",
            lockable: false,
            title: attachFileAssembly_GridHeaders[5].description,
            width: 120,
            wmsid: 6,
            headerAttributes: {
                "data-wms-selector": selector + 'Notes'
            }
        },
        {
            field: "ProcessDate",
            lockable: false,
            title: attachFileAssembly_GridHeaders[6].description,
            width: 120,
            filterable: true,
            template: "#= ProcessDate != null ? kendo.toString(kendo.parseDate(ProcessDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 7,
            headerAttributes: {
                "data-wms-selector": selector + 'ProcessDate'
            }
        },
        {
            field: "CreationDate",
            lockable: false,
            title: attachFileAssembly_GridHeaders[7].description,
            width: 120,
            filterable: true,
            template: "#= CreationDate != null ? kendo.toString(kendo.parseDate(CreationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 8,
            headerAttributes: {
                "data-wms-selector": selector + 'CreationDate'
            }
        }]

    });
}

export const getGridDemoSchema = () => {
    return ({

            model: {
                id: "AssemblyId",
                fields: {
                    AssemblyId: {type: "number"},
                    Customer: { type: "string" },
                    Warehouse: { type: "string" },
                    ReferenceNum: { type: "string" },
                    Status: { type: "string" },
                    Notes: { type: "string" },
                    ProcessDate: { type: "date" },
                    CreationDate: { type: "date" },
                }
            }
    })
}

export const getEditAllocationColumns = (gridHeader, customLabels) => {
    return ({
        columns: [{
            field: "PrimaryQuantity",
            "menu": false,
            title: gridHeader[0],
            width: 160,
            wmsid: 1,
            lockable: false,
            sortable: false
        }, {
            field: "PrimaryUnitType",
            title: gridHeader[1],
            width: 180,
            wmsid: 2,
            sortable: false,
            lockable: false
        }, {
            field: "Available",
            title: gridHeader[2],
            width: 180,
            wmsid: 3,
            lockable: false
        }, {
            field: "PalletLabel",
            title: gridHeader[3],
            width: 180,
            wmsid: 4,
            lockable: false
        }, {
            field: "ReceiverId",
            title: gridHeader[4],
            width: 180,
            wmsid: 5,
            lockable: false
        }, {
            field: "ReceiverReferenceNum",
            title: gridHeader[5],
            width: 180,
            wmsid: 6,
            lockable: false
        }, {
            field: "ReceivedDate",
            title: gridHeader[15],
            width: 160,
            wmsid: 7,
            hidden: false,
            lockable: false,
            editable: false,
            template: "#= ReceivedDate != null ? kendo.toString(kendo.parseDate(ReceivedDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
        }, {
            field: "LotNumber",
            title: customLabels.Label_Lot_Number,
            width: 180,
            wmsid: 8,
            lockable: false
        }, {
            field: "SerialNumber",
            title: customLabels.Label_Serial_Number,
            width: 180,
            wmsid: 9,
            lockable: false
        }, {
            field: "ExpirationDate",
            title: gridHeader[8],
            width: 180,
            wmsid: 10,
            lockable: false,
            template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
        }, {
            field: "Cost",
            title: gridHeader[9],
            width: 180,
            wmsid: 11,
            lockable: false
        }, {
            field: "PurchaseOrderNumber",
            title: gridHeader[10],
            width: 180,
            wmsid: 12,
            lockable: false
        }, {
            field: "TrailerNumber",
            title: gridHeader[11],
            width: 180,
            wmsid: 13,
            lockable: false
        }, {
            field: "LocationName",
            title: gridHeader[12],
            width: 180,
            wmsid: 14,
            lockable: false
        }, {
            field: "SupplierName",
            title: gridHeader[13],
            width: 180,
            wmsid: 15,
            lockable: false
        }, {
            field: "CustomFields",
            title: gridHeader[14],
            width: 180,
            wmsid: 16,
            lockable: false,
            sortable: false
        }]
    });
};
export const getEditAllocationSchema = () => {
    return ({
        schema: {
            data: "Data",
            total: "Total",
            model: {
                fields: { //I'm not sure about these types
                    PrimaryQuantity: {
                        type: "number",
                        editable: true,
                        validation: {
                            min: 0
                        }
                    },
                    PrimaryUnitType: {
                        type: "string",
                        editable: false
                    },
                    Available: {
                        type: "number",
                        editable: false
                    },
                    PalletLabel: {
                        type: "string",
                        editable: false
                    },
                    ReceiverId: {
                        type: "string",
                        editable: false
                    },
                    ReceiverReferenceNum: {
                        type: "string",
                        editable: false
                    },
                    ReceivedDate: {
                        type: 'date',
                        editable: false
                    },
                    LotNumber: {
                        type: "string",
                        editable: false
                    },
                    SerialNumber: {
                        type: "string",
                        editable: false
                    },
                    ExpirationDate: {
                        type: "DateTime",
                        editable: false
                    },
                    Cost: {
                        type: "number",
                        editable: false
                    },
                    PurchaseOrderNumber: {
                        type: "string",
                        editable: false
                    },
                    TrailerNumber: {
                        type: "string",
                        editable: false
                    },
                    LocationName: {
                        type: "string",
                        editable: false
                    },
                    SupplierName: {
                        type: "string",
                        editable: false
                    },
                    CustomFields: {
                        type: "string",
                        editable: false
                    }
                }
            }
        }
    });
};
export const getEditAllocationTransport = (customerId, facilityId, itemId, qualifier,orderItemId) => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Shipping/Orders/EditLineItemAllocationsGridRead",
                type: "POST",
                data: {
                    customerId: customerId,
                    facilityId: facilityId,
                    itemId: itemId,
                    qualifier: qualifier,
                    orderItemId: orderItemId
                }
            },
        }
    });
};

export const getMassAddColumns = (gridHeader, customLabels) => {
    return ({
        columns: [{
            field: "PrimaryUnitsToAdd",
            sortable: false,
            menu: false,
            title: gridHeader[0],
            width: 180,
            wmsid: 1,
            lockable: false,
            headerAttributes: {
                "ng-non-bindable": true
            },
            attributes: {
                "class": "wms-fake-look-input"
            }
        }, {
            field: "ItemImage",
            title: customLabels.Label_Item + " Image",
            width: 160,
            wmsid: 2,
            lockable: false,
            hidden: true,
            editable: false,
            sortable: false
        }, {
            field: "SKU",
            sortable: true,
            title: customLabels.Label_Sku,
            width: 180,
            wmsid: 3,
            lockable: false,
            editable: false
        }, {
            field: "Qualifier",
            title: gridHeader[3],
            width: 160,
            wmsid: 4,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "Description",
            title: gridHeader[4],
            width: 160,
            wmsid: 5,
            lockable: false,
            editable: false
        }, {
            field: "Available",
            title: gridHeader[6],
            width: 160,
            wmsid: 6,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "PrimaryUnitType",
            sortable: false,
            title: gridHeader[5],
            width: 160,
            wmsid: 7,
            lockable: false,
            editable: false
        }, {
            field: "PalletLabel",
            title: gridHeader[7],
            width: 160,
            wmsid: 8,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "ReceiverId",
            title: gridHeader[8],
            width: 160,
            wmsid: 9,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "ReceiverReferenceNum",
            title: gridHeader[9],
            width: 160,
            wmsid: 10,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "ReceivedDate",
            title: gridHeader[10],
            width: 160,
            wmsid: 11,
            hidden: false,
            lockable: false,
            editable: false,
            template: "#= ReceivedDate != null ? kendo.toString(kendo.parseDate(ReceivedDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
        }, {
            field: "LotNumber",
            title: customLabels.Label_Lot_Number,
            width: 160,
            wmsid: 12,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "SerialNumber",
            title: customLabels.Label_Serial_Number,
            width: 160,
            wmsid: 13,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "ExpirationDate",
            title: gridHeader[13],
            width: 160,
            wmsid: 14,
            hidden: false,
            lockable: false,
            editable: false,
            template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
        }, {
            field: "Cost",
            title: gridHeader[14],
            width: 160,
            wmsid: 15,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "PurchaseOrderNumber",
            title: gridHeader[15],
            width: 160,
            wmsid: 16,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "TrailerNumber",
            title: gridHeader[16],
            width: 160,
            wmsid: 17,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "LocationName",
            title: gridHeader[17],
            width: 160,
            wmsid: 18,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "SupplierName",
            title: gridHeader[18],
            width: 160,
            wmsid: 19,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "CustomFields",
            title: gridHeader[19],
            width: 160,
            wmsid: 20,
            hidden: false,
            lockable: false,
            editable: false,
            sortable: false
        }]
    });
};

export const getMassAddSchema = (validNumberText) => {
    return({
        schema:{
            data: "Data",
            total: "Total",
            model: {
                fields: {
                    PrimaryUnitsToAdd: {
                        type: "float",
                        editable: true,
                        validation: {
                            primaryunitstoaddvalidation: function (input) {
                                if (input.is("[name='PrimaryUnitsToAdd']") && input.val() != "") {
                                    input.attr("data-primaryunitstoaddvalidation-msg", validNumberText);
                                    return /^[0-9]*(?:\.[0-9]{0,4})?$/.test(input.val());
                                }
                                return true;
                            }
                        }
                    },
                    ItemImage: {
                        type: "string",
                        editable: false
                    },
                    SKU: {
                        type: "string",
                        editable: false
                    },
                    Qualifier: {
                        type: "string",
                        editable: false
                    },
                    Description: {
                        type: "string",
                        editable: false
                    },
                    Available: {
                        type: "number",
                        editable: false
                    },
                    PrimaryUnitType: {
                        type: "string",
                        editable: false
                    },
                    PrimaryQuantity: {
                        type: "number",
                        editable: false
                    },
                    PalletLabel: {
                        type: "string",
                        editable: false
                    },
                    ReceiverId: {
                        type: "number",
                        editable: false
                    },
                    ReceiverReferenceNum: {
                        type: "string",
                        editable: false
                    },
                    ReceivedDate: {
                        type: 'date',
                        editable: false
                    },
                    LotNumber: {
                        type: "string",
                        editable: false
                    },
                    SerialNumber: {
                        type: "string",
                        editable: false
                    },
                    ExpirationDate: {
                        type: "string",
                        editable: false
                    },
                    Cost: {
                        type: "number",
                        editable: false
                    },
                    PurchaseOrderNumber: {
                        type: "string",
                        editable: false
                    },
                    TrailerNumber: {
                        type: "string",
                        editable: false
                    },
                    LocationName: {
                        type: "string",
                        editable: false
                    },
                    SupplierName: {
                        type: "string",
                        editable: false
                    },
                    CustomFields: {
                        type: "string",
                        editable: false
                    }
                }
            }
        }
    });
};

export const getMassAddTransport = (customerId, facilityId) => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Shipping/Orders/MassAddGridRead",
                type: "POST",
                data: {
                    customerId: customerId,
                    facilityId: facilityId
                }
            },
        }
    });
};
