
import React from 'react';
import { KendoGridWms } from '../../../assets/ts/KendoGridWms.ts';
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';
import { StringWms } from "../../../assets/ts/StringWms.ts";

import style from './cyclecount.scss';
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsButtonDropdown from './../components/WmsButtonDropdown/WmsButtonDropdown.jsx';
import WmsMenuItem from './../components/WmsMenuItem/WmsMenuItem.jsx';
import WmsModel from './../components/WmsModel/WmsModel.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsToolTip from './../components/WmsToolTip/WmsToolTip.tsx';

import * as cyclecountService from './cyclecount.services.jsx';
import * as GlobalService from './../global_services';
import * as emitter from "../emitter";
import { kendo } from "./../global_variable_service.jsx";

var that, kendoGridaddLocationJob, selectedJobs = '', countsUri;
export class ReviewCountSection extends React.Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.state = {
            otherLocationModelOption: {
                title: '',
                width: 0,
                height: 0,
                isOpen: false
            },
            selectedJobsForReview: '',
            currentSku: "",
            countsUri: [],
            EditData: this.props.sharedCycleCount.EditData,
            locationsBeingAudited: [],
            facilityId: this.props.sharedCycleCount.warehouseSelection.id,
            facilityUri: this.props.sharedCycleCount.warehouseSelection.uri,
            optionJobs: [],
            indexOptionsJob: '',
            disableReconcileJobs: true,
            activeSelectionsLength: 0,
            selector: 'AuditInventory',
            exportLoading: false
        };

        kendoGridaddLocationJob = new KendoGridWms();
        this.handleChange = this.handleChange.bind(this);
        that = this;
    }

    componentDidMount() {
        this.initialiseReviewCountGrid();
        this.setCurrentSelectJobData(this.state.EditData);

    }
    initialiseReviewCountGrid() {
        var dataSource = new kendo.data.TreeListDataSource({
            data: [],
            schema: {
                model: {
                    id: "id",
                    expanded: true
                }
            }
        });
        var reviewJobHeaders = StringWms.convertSringToOption(this.translate('Review_Reconcile_Job_Header'));
        $("#treelist").kendoTreeList({
            dataSource: dataSource,
            // height: 'auto',
            selectable: 'multiple, row',
            resizable: true,
            reorderable: true,
            columns: [
                { field: "title", template: $("#link-template").html(), title: reviewJobHeaders[0].description },
                // { field: "title" },
                { field: "systemCount", title: reviewJobHeaders[1].description },
                { field: "allocatedCount", title: reviewJobHeaders[2].description },
                { field: "physicalCount", title: reviewJobHeaders[3].description },
                { field: "variance", title: reviewJobHeaders[4].description }
            ],
            dataBound: function (e) {
                that.setState({ disableReconcileJobs: true });
                var treeview = $("#treelist").data("kendoTreeList").dataSource._data;
                console.log('treeview', treeview);
                var grid = $('#treelist').data('kendoTreeList');
                var gridData = grid.dataSource.view();
                for (var i = 0; i < gridData.length; i++) {
                    if (gridData[i].variance != 0 && gridData[i].variance != null) {
                        that.setState({ disableReconcileJobs: false });
                        // grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-variance-allocated");
                    }
                    if(gridData[i].Link) {
                        grid.table.find("tr[data-uid='" + gridData[i].uid + "'] td").first().addClass("sku-click");
                    }
                }
            }
        });
        $(document).on('click', '#treelist tr', function (event) {
            console.log(event);
            // if( event.button != 1 ) {
            //     return;
            // }
            var tr = $(this);
            console.log("hasClass : ", tr.hasClass("k-state-selected"));
            var treelist = $("#treelist").data("kendoTreeList");
            // if (tr.hasClass("k-state-selected") && typeof event.which != "undefined") {
            //     setTimeout(function () {
            //         treelist.clearSelection();
            //     }, 100);
            //     return;
            // }
            treelist.select(tr);
            setTimeout(function () {
                var selected_row = treelist.select();
                var data = treelist.dataItem(selected_row);
                var rootNode = getRootNode(data);
                selectChildNodes(rootNode);
            }, 100);
        });


        function getRootNode(tlModel) {
            if (typeof tlModel != 'undefined') {
                var rootNode = tlModel;
                var level = dataSource.level(tlModel);

                while (level !== 0) {
                    rootNode = dataSource.parentNode(rootNode);
                    level = dataSource.level(rootNode);
                }
                return rootNode;
            }
        }

        function selectChildNodes(tlModel) {
            if (typeof tlModel != 'undefined') {
                // console.log("treelist getRootNode ", dataSource.level(tlModel));
                var childNodes = dataSource.childNodes(tlModel);
                var treelist = $("#treelist").data("kendoTreeList");
                treelist.select($("tr[data-uid='" + tlModel.uid + "']"));
                // console.log("treelist childNodes ", childNodes);
                for (var i = 0; i < childNodes.length; i++) {
                    // treelist.select($("tr[data-uid='" + childNodes[i].uid + "']"));
                    selectChildNodes(childNodes[i]);
                }
            }
        }

        // event for open modal template define in body component html
        $(document).on('click', '.sku-click', function (e) {

            e.preventDefault();
            if(e.target.parentNode.tagName == 'TD'){ // prevent click event on expand/collapse icon
                return;
            }
            var row = $(this).closest('tr');
            var dataItem = $("#treelist").data("kendoTreeList").dataItem(row);
            that.setState({
                currentSku: $(this).data('id'),
                selectedDataItem: dataItem
            });
            console.log('currentSku', that.state.currentSku);
            that.openOtheLocationModal();
        });
        $("#recountContextMenu").kendoContextMenu({
            // listen to right-clicks on the treelist container
            target: "#treelist",

            // show when a row is clicked
            filter: "tbody > tr",

            // handle item clicks
            select: function (e) {


                var button = $(e.item);
                var row = $(e.target);
                var dataItem = $("#treelist").data("kendoTreeList").dataItem(row);
                var rowItemClicked = $(e.item).children(".k-link").parent().attr("data-wmsid");
                var allowTrackBys = false;

                if (rowItemClicked == "everything") {
                    var auditCountsUris = [];
                    for (var i = 0; i < that.state.EditData.length; i++) {
                        auditCountsUris.push(that.state.EditData[i].CountsUri);
                    }
                    cyclecountService.recountAll(auditCountsUris).then(function (response) {
                        if (response.status == 204) {
                            GlobalService.notification('success', that.translate('Recount_Audit_Success'));
                            that.props.closeReviewCountSection();
                            emitter.eventBus.sendEvent('reloadJobGrid');
                        }
                    }, function (response) {
                        // GlobalService.notification('error', response);
                    });
                } else {
                    var locCountUris = [];
                    var auditUris = [];
                    if (rowItemClicked == "trackbys") {
                        allowTrackBys = true;
                    }

                    for (var index = 0; index < that.state.EditData.length; index++) {
                        auditUris.push(that.state.EditData[index].EditUri);
                    }

                    var treelist = $("#treelist").data("kendoTreeList");
                    var data = treelist.select();
                    for (var j = 0; j < data.length; j++) {
                        var selectedDataItem = treelist.dataItem(data[j]);
                        if (selectedDataItem.resetUri != null) {
                            locCountUris.push(selectedDataItem.resetUri);
                        }
                    }

                    cyclecountService.recountLocationsAndTrackBys(locCountUris, auditUris, allowTrackBys).then(function (response) {
                        if (response.status == 204) {
                            GlobalService.notification('success', that.translate('Recount_Audit_Success'));
                            that.props.closeReviewCountSection();
                            emitter.eventBus.sendEvent('reloadJobGrid');
                        }
                    }, function (response) {
                        // GlobalService.notification('error', response);
                    });
                }


            },
            open: function (e) {
                var treelist = $("#treelist").data("kendoTreeList");
                var data = treelist.select();
                that.setState({
                    activeSelectionsLength: data.length
                });
            }
            // open: function (e) {
            //     var tr = $(e.target);
            //     tr.click();
            // }
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.sharedCycleCount) {
            this.setCurrentSelectJobData(nextProps.sharedCycleCount.EditData);
        }
    }
    addLinkProperty(auditReviewListData) {
        for (var index = 0; index < auditReviewListData.length; index++) {
            if (typeof auditReviewListData[index].parentId != 'undefined') {
                if (auditReviewListData[index].parentId == null) {
                    auditReviewListData[index].Link = false;
                } else {
                    var getIndex = ObjectWms.FindIndexByProperty(auditReviewListData, "id", auditReviewListData[index].parentId);
                    if (getIndex != -1) {
                        auditReviewListData[index].Link = auditReviewListData[getIndex].parentId == null ? true : false;
                    }
                }
            } else {
                auditReviewListData[index].Link = false;
            }
        }
        return auditReviewListData;
    }

    setCurrentSelectJobData(jobsData) {
        var jobOptions = [];
        countsUri = [];
        selectedJobs = '';
        jobsData.filter(function (jobDetail) {
            selectedJobs = selectedJobs + jobDetail.Name + ', ';
            countsUri.push(jobDetail.CountsUri);
        });

        for (var i = 0; i < jobsData.length; i++) {
            jobOptions.push({
                "value": jobsData[i].EditUri,
                "description": jobsData[i].Name
            });
        }

        that.setState({
            selectedJobsForReview: selectedJobs.replace(/,\s*$/, ""),
            optionJobs: jobOptions
        });
        kendo.ui.progress($('#treelist'), true);
        cyclecountService.GetAuditReviewList(countsUri).then(function (response) {
            console.log('GetAuditReviewList Response', response);
            if (typeof response != 'undefined' && response.status == 200) {
                if (typeof $('#treelist').data('kendoTreeList') != 'undefined') {

                    var data = that.addLinkProperty(response.data.AuditReviews);
                    that.setState({
                        locationsBeingAudited: response.data.LocationsBeingAudited
                    });
                    $('#treelist').data('kendoTreeList').dataSource.data(data);
                    $('#treelist').data('kendoTreeList').refresh();
                }
            }
            kendo.ui.progress($('#treelist'), false);
        }, function (response) {
            // GlobalService.notification('error', response);
            kendo.ui.progress($('#treelist'), false);
        });
    }

    getAuditReviewCSV()
    {
        this.setState({
            exportLoading: true
        })
        var auditCountsUris = [];
        for (var i = 0; i < that.state.EditData.length; i++)
            auditCountsUris.push(that.state.EditData[i].CountsUri);

        cyclecountService.GetAuditReviewCSV(auditCountsUris).then(function (response)
        {
            var now = new Date();
            var headers = response.headers;
            GlobalService.downloadFileBaseOnContentType(response.data, headers['content-type'], that.state.selectedJobsForReview + '_'
            + (now.getMonth() + 1) + '-'
            + now.getDate() + '-'
            + now.getFullYear() + '_'
            + now.getHours() + '_'
            + now.getMinutes()
                + '.csv', true, "download");
                that.setState({
                    exportLoading: false
                })
        }).finally(function () {
            that.setState({
                exportLoading: false
            })
        });
    }

    openOtheLocationModal() {
        var modelOptions = that.state.otherLocationModelOption;
        modelOptions.title = 'Other Location with SKU';
        modelOptions.width = 600;
        modelOptions.height = 530;
        modelOptions.isOpen = true;
        that.setState({
            otherLocationModelOption: modelOptions
        });

        if (that.state.optionJobs.length == 1) {
            var selectedJobUri = that.state.optionJobs[0].value;
            that.setState({
                indexOptionsJob: selectedJobUri
            });
        }

        cyclecountService.GetOtherLocationsForSku(that.state.selectedDataItem.skuble.Sku, that.state.selectedDataItem.skuble.Qualifier, that.state.facilityUri).then(function (response) {
            if (typeof response != 'undefined' && response.status == 200) {
                $('#cycleCountAddlocationGrid').data('kendoGrid').dataSource.data(response.data);
            }
            kendo.ui.progress($('#treelist'), false);
        }, function (response) {
            // GlobalService.notification('error', response);
            kendo.ui.progress($('#treelist'), false);
        });

        var addLocationJobGrid = {
            dataSource: {
                data: [],
                schema: {
                    model: {
                        id: "locationId",
                        fields:
                        {
                            location: { type: "string" },
                            systemCount: { type: "number" }
                        }
                    }
                },
            },
            selectable: "multiple, row",
            scrollable: {
                height: 280
            },
        };
        var addLocationJobGridColumns = {
            columns: [{
                field: "location",
                title: 'Location',
                width: 120,
                wmsid: 1,
                filterable: false
            }, {
                field: "systemCount",
                title: "System Quantity",
                width: 120,
                wmsid: 2,
                filterable: true,
            }]
        };
        var finalOptionsObj = kendoGridaddLocationJob.getGridOptions(addLocationJobGridColumns, addLocationJobGrid, '[{}]');
        $("#cycleCountAddlocationGrid").kendoGrid(finalOptionsObj);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    closeOtherLocationModal() {
        var modelOptions = that.state.otherLocationModelOption;
        modelOptions.isOpen = false;
        that.setState({
            otherLocationModelOption: modelOptions
        });
    }

    cancelOtherLocationModal() {
        $("#otherLocationModel").data('kendoWindow').close();
    }

    addLocsToJob() {
        var gridData = $('#cycleCountAddlocationGrid').data('kendoGrid').dataSource.data();
        var locIds = [];
        for (var i = 0; i < gridData.length; i++) {
            locIds.push(gridData[i].locationId);
        }

        console.log(that.state.indexOptionsJob);

        cyclecountService.AddLocationsToJob(that.state.indexOptionsJob, locIds).then(function (response) {
            if (typeof response != 'undefined' && ((response.status == 200) || (response.status == 204))) {
                //$('#cycleCountAddlocationGrid').data('kendoGrid').dataSource.data(response.data);
                GlobalService.notification('success', that.translate('Label_Add_Locations_Job_Success'));
                $("#otherLocationModel").data('kendoWindow').close();
                that.props.closeReviewCountSection();
                emitter.eventBus.sendEvent('reloadJobGrid');
            }
        });
    }


    render() {
        return (
            <div className="cyclecount-reveiew-reconcile-wrapper">


                    <ul id="recountContextMenu">
                        <li data-wmsid="everything">Recount Everything</li>
                        <li data-wmsid="trackbys" className={this.state.activeSelectionsLength == 0 ? 'wms-disabled-look' : 'k-item k-state-default'}>Recount with Track-Bys</li>
                        <li data-wmsid="locations" className={this.state.activeSelectionsLength == 0 ? 'wms-disabled-look' : 'k-item k-state-default'}>Recount Location(s)</li>
                    </ul>
                        <div className="title">
                            <label>Review counts below before proceeding to reconcile. Make sure you have included all desired locations. You can recount locations by right clicking on them. You can see other locations containing the sku/qualifier by clicking on them.</label>
                        </div>
                        <div className="wms-btn-wrapper">
                            <WmsButton
                                label={'Reconcile Jobs'}
                                wmsSelector={this.state.selector+'reconcileJob'}
                                onClickFunc={this.props.toggleReconcileSection}
                                buttonType={'green'}
                                disabled={this.state.disableReconcileJobs}
                                icon='fa-tasks'
                            />
                            <div className="export-btn-wrapper">
                                <WmsToolTip
                                    showToolTip={true}
                                    tooltipText="Export audit results in CSV file"
                                >
                                    <WmsButton
                                        label={'Export CSV'}
                                        wmsSelector={this.state.selector+'Export CSV'}
                                        onClickFunc={(e) => this.getAuditReviewCSV()}
                                        buttonType={'green'}
                                        loading={this.state.exportLoading }
                                        icon='fa-file-export' />
                                </WmsToolTip>
                            </div>
                            <WmsButton
                                label={"Exit"}
                                wmsSelector={this.state.selector+'exitReviewCount'}
                                onClickFunc={this.props.closeReviewCountSection}
                                buttonType={'yellow'}
                                icon='fa-times' />
                        </div>
                        <div className="wms-grid-wrapper">
                            <div className="title">
                                <label className="wms-input-label">Jobs under review:<span>{this.state.selectedJobsForReview}</span></label>
                            </div>

                            <div className="wms-grid-holder">
                                        <div className="no-josh" id="treelist" data-wms-selector={this.state.selector+"treelist"}></div>
                                    </div>
                                </div>

                    <WmsModel id={'otherLocationModel'} title={this.state.otherLocationModelOption.title} width={this.state.otherLocationModelOption.width} height={this.state.otherLocationModelOption.height} onCloseFunc={this.closeOtherLocationModal} isOpen={this.state.otherLocationModelOption.isOpen} customClass='wms-model-new-ui-wrapper'>
                         <div className="model-content-wrapper wms-grid-wrapper">
                            {this.state.optionJobs.length > 1 && <WmsDropdown
                                id="indexOptionsJob"
                                name="indexOptionsJob"
                                label={this.translate('Label_Select_Job')}
                                wmsSelector={this.state.selector+"cycleCountJob"}
                                value={this.state.indexOptionsJob}
                                onChangeFunc={this.handleChange}
                                options={this.state.optionJobs}
                                valueField='value'
                                textField='description' />}

                            <div className="title">
                                <label className="wms-input-label">SKU/Qualifier:{this.state.currentSku}</label>
                            </div>
                            <div className="wms-grid-holder">
                                    <div className="no-josh" id="cycleCountAddlocationGrid" data-wms-selector={this.state.selector+"AddlocationGrid"}></div>
                                </div>
                        </div>
                        <div className="footer-btn-holder">
                        <WmsButton
                            label={this.translate("Label_Cancel")}
                            wmsSelector={this.state.selector+'Cancel'}
                            onClickFunc={this.cancelOtherLocationModal}
                            buttonType={'orange'}
                            icon='fa-ban'
                        />

                        <WmsButton
                            label={'Add Location To Job'}
                            wmsSelector={this.state.selector+'addLocationToJob'}
                            onClickFunc={this.addLocsToJob}
                            buttonType={'green'}
                            icon={'fa-plus'} />

                        </div>
                    </WmsModel>
                </div>

        );
    }
}