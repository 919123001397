
import React from 'react';

import { StringWms } from '../../../assets/ts/StringWms.ts';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsFile from './../components/WmsFile/WmsFile.jsx';
import WmsCheckBox from './../components/WmsCheckBox/WmsCheckBox.jsx';
import { ImportOrdersGrid } from './import-orders-grid.componennt.jsx';

import * as GlobalService from './../global_services';
import * as importOrdersServices from './import-orders.services.jsx';

import style from './import-orders.scss';

export class importOrdersHolder extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            customLabels: GlobalService.getCustomLabels(),
            multipleParseErrorText: this.translate('Label_Multiple_Parsers'),
            showManageOrderSection: false,
            chkManageImportOrder: false,
            showAmbigousParser: false,
            sharedFetchCustomerInfo: null,
            sharedFileUploadData: null,
            manageOrderGridData: [],
            sharedGridSummary: {},
            otherAmbigParser: [],
            facilityOptions: [],
            customerOptions: [],
            ediAmbigParsers: [],
            xmlAmbigParsers: [],
            optionsParser: [],
            indexOptionsParser: '',
            customerIndex: '',
            selectedFacility: '',
            selectFileName: '',
            selectFileSize: '',
            onError: '',
            criteria: '',
            selector:'ImportOrder'
        }

    }

    componentDidMount() {

        kendo.ui.progress($('#' + this.state.windowId), true);

        GlobalService.GetCustomersAndFacility().then((response) => {

            this.setState({
                facilityOptions: GlobalService.getFacilityList(),
                customerOptions: GlobalService.getCustomerList()
            });

        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });

    }

    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value });

        if (event.target.name == 'customerIndex' && event.target.value != '') {

            let customerObj = this.state.customerOptions[event.target.value];
            let facilityOptions = GlobalService.getFacilityList();
            let cusFaciliOptions = [];


            for (let x = 0; x < customerObj.facilities.length; x++) {
                const facility = facilityOptions.find((element) => {
                    if (element.id == customerObj.facilities[x]) {
                        return element;
                    }
                });
                if(facility) {
                    cusFaciliOptions.push(
                        facility
                        );
                    }
            }

            this.setState({
                facilityOptions: cusFaciliOptions,
				selectedFacility: (typeof customerObj.defaultFacility != 'undefined' && cusFaciliOptions.includes(customerObj.defaultFacility)) ? customerObj.defaultFacility : ''
            });

            this.getCustomerInfo(customerObj);
        }
    }

    changeIndexOptionsParser = (event) => {
        if (event.target.value !== '') {
            this.continueFileUploading(this.state.sharedFileUploadData);
            this.setState({
                showAmbigousParser: false,
                indexOptionsParser: event.target.value
            });
        }
    }

    getCustomerInfo(param_cusobj) {

        kendo.ui.progress($('#' + this.state.windowId), true);

        importOrdersServices.getCustomerData(param_cusobj.uri).then((response) => {

            if (response != 'undefined' && (response.status == 200 || response.status == 204)) {
                this.setState({
                    onError: response.data.Preferences.OnError,
                    criteria: response.data.Preferences.Criteria,
                    ediAmbigParsers: response.data.AmbiguousTransforms.Edi,
                    xmlAmbigParsers: response.data.AmbiguousTransforms.Xml,
                    otherAmbigParser: response.data.AmbiguousTransforms.Other,
                    sharedFetchCustomerInfo: response.data,
                });
            }

        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
    }

    handleCheckboxChange = (event) => {
        this.setState({ chkManageImportOrder: !this.state.chkManageImportOrder });
    }

    handleFileUpload = (fileUploadedData) => {
        // check if file is greater than 2MB
        if(fileUploadedData.fileSize > 2000000 ) {
            var _data = {
                "Source": 1,
                "ServerName": "3PL-QA-APP",
                "Descriptions": [
                    {
                    "ErrorClass": "Http",
                    "ErrorCode": "System",
                    "Descriptors": [ "Maximum request length exceeded."],
                    "Hint": null
                    }
                ],
                "ErrorClass": "Http",
                "ErrorCode": "System",
                "type": "error"
                };
            GlobalService.showErrorMessage(_data);
            return false;
        }
        // check if file is 0 size and blank content
        if(fileUploadedData.fileSize == 0 ||  (fileUploadedData.fileread && fileUploadedData.fileread.trim() == '')) {
            var _data = {
                "Source": 1,
                "ServerName": "3PL-QA-APP",
                "Descriptions": [
                    {
                    "ErrorClass": "Http",
                    "ErrorCode": "System",
                    "Descriptors": [ "Minimum request length exceeded."],
                    "Hint": null
                    }
                ],
                "ErrorClass": "Http",
                "ErrorCode": "System",
                "type": "error"
                };
            GlobalService.showErrorMessage(_data);
            return false;
        }

        // set default other abigouse detail
        let multipleParseErrorText = this.state.multipleParseErrorText.replace('%parsertype%', 'other').replace('%filetype%', fileUploadedData.fileName.slice(-4));
        let optionsParser = this.state.otherAmbigParser;
        let indexOptionsParser = '';
        let fileSize = (fileUploadedData.fileSize / (1000*1000)).toFixed(2);
        if(fileSize == 0.00) {
            fileSize = (fileUploadedData.fileSize / 1000).toFixed(2) + 'kb';
        } else {
            fileSize = fileSize + 'mb';
        }
        let showAmbigousParser = false;

        if (fileUploadedData.fileType === 'text/xml') {

            if (!StringWms.isEmpty(this.state.xmlAmbigParsers)) {
                multipleParseErrorText = this.state.multipleParseErrorText.replace('%parsertype%', 'xml').replace('%filetype%', fileUploadedData.fileName.slice(-4));
                optionsParser = this.state.xmlAmbigParsers;
                showAmbigousParser = true;

            } else { this.continueFileUploading(fileUploadedData); }

        } else if (fileUploadedData.fileType === 'edi') {

            if (!StringWms.isEmpty(this.state.ediAmbigParsers)) {

                multipleParseErrorText = this.state.multipleParseErrorText.replace('%parsertype%', 'edi').replace('%filetype%', fileUploadedData.fileName.slice(-4));
                optionsParser = this.state.ediAmbigParsers;
                showAmbigousParser = true;

            } else { this.continueFileUploading(fileUploadedData); }

        } else {

            if (!StringWms.isEmpty(this.state.otherAmbigParser)) {
                showAmbigousParser = true;
                indexOptionsParser = this.state.otherAmbigParser[0].TransformId;
            } else { this.continueFileUploading(fileUploadedData); }
        }

        this.setState({
            multipleParseErrorText: multipleParseErrorText,
            optionsParser: optionsParser,
            indexOptionsParser: indexOptionsParser,
            showAmbigousParser: showAmbigousParser,
            sharedFileUploadData: fileUploadedData,
            selectFileName:fileUploadedData.fileName,
            selectFileSize:fileSize
        });
    }

    continueFileUploading = (fileUploadedData) => {
        if (this.state.chkManageImportOrder == false) {

            let data = {
                "Preferences": {
                    "OnError": this.state.onError,
                    "Criteria": this.state.criteria
                },
                "CustomerId": this.state.customerOptions[this.state.customerIndex].id,
                "FacilityId": this.state.selectedFacility,
                "TransformId": null,
                "File": fileUploadedData.fileread,
                "FileName": fileUploadedData.fileName
            };
            this.$rootScope.animationMoveIcon($('#file-upload'));
            this.$rootScope.taskStarted();
            // this.$rootScope.$broadcast("importParseAndImportAsync", data);
            /** Code for react progress component*/
            data.type = "importOrderAsync";
            var event = new CustomEvent("showProgressModal", {
                detail: data
            });
            document.dispatchEvent(event);
            this.setState({ selectFileName: fileUploadedData.fileName, });

        } else {
            kendo.ui.progress($('#' + this.state.windowId), true);
            importOrdersServices.parseOrderImportFile(
                this.state.customerOptions[this.state.customerIndex].id,
                this.state.selectedFacility,
                this.state.indexOptionsParser,
                fileUploadedData.fileread,
                fileUploadedData.fileName
            ).then((response) => {
                var summaryObj = [{
                    label: 'Total Orders',
                    value: kendo.toString(response.Data.length,'n0')
                },{
                    label:'Over allocated orders',
                    value:kendo.toString(_.filter(response.Data, function(o) { if (o.FullyAllocated == false) return o }).length,'n0')
                },{
                    label:'Total items ordered',
                    value:kendo.toString(_.sumBy(response.Data, function (day) { return day.TotalItems;}),'n0')
                }]
                GlobalService.notification('success', this.translate('Settings_Label_Import_Validation_Success'));
                this.setState({
                    manageOrderGridData: response.Data,
                    sharedGridSummary:summaryObj,
                    showManageOrderSection: true
                });

            }).finally(() => { kendo.ui.progress($('#' + this.state.windowId), false); });
        }
    }

    onSelectNewFile = () => {
        this.setState({
            showManageOrderSection: false,
            selectFileName: ''
        });
        importOrdersServices.removeParsedOrdersFromSession();
    }

    render() {
        return (
            <div id="import-order-holder-react" >
                {this.state.showManageOrderSection == false && (
                    <div>
                        <WmsContainer>
                            <div className="wms-warning-wrapper in-import" data-wms-selector="ImportOrderFileUploadWarning">
                                <i className="wms-sprite fa fa-info-circle"></i>
                                <span>
                                    {this.translate('Label_Import_Max_Upload_Warning')}
                                </span>
                            </div>
                            <WmsDropdown
                                id="customerIndex"
                                name="customerIndex"
                                label={this.state.customLabels.Label_Customer}
                                wmsSelector={this.state.selector + "CustomerDropdown"}
                                value={this.state.customerIndex}
                                onChangeFunc={this.handleChange}
                                options={this.state.customerOptions}
                                required={true}
                                applyIndex={true}
                                blankFirstOption={true}
                                textField='name' />

                            <WmsDropdown
                                id="selectedFacility"
                                name="selectedFacility"
                                label={this.state.customLabels.Label_Warehouse}
                                wmsSelector={this.state.selector + "FacilityDropdown"}
                                value={this.state.selectedFacility}
                                onChangeFunc={this.handleChange}
                                options={this.state.facilityOptions}
                                required={true}
                                blankFirstOption={true}
                                textField='name'
                                valueField='id' />

                        </WmsContainer>

                        <WmsFile
                            id="file-upload"
                            name="file-upload"
                            label={this.translate('Label_SelectFile')}
                            wmsSelector={this.state.selector + "File"}
                            value={this.state.selectFileName ? (this.state.selectFileName + ' ('+this.state.selectFileSize+')') : ''}
                            onChangeFunc={this.handleFileUpload}
                            disabled={this.state.customerIndex == '' || this.state.selectedFacility == ''}
                            clearInputOnClick={true}
                        />

                        {this.state.showAmbigousParser &&
                            <div>

                                <div className="row">
                                    <div className="title">
                                        <label className="wms-error-text">{this.state.multipleParseErrorText}</label>
                                    </div>
                                </div>

                                <WmsContainer>
                                    <div className="title-text pd-l">
                                        <label className="wms-input-label">{this.translate('Label_Choose_Format')}</label>
                                    </div>
                                    <WmsDropdown
                                        id="indexOptionsParser"
                                        name="indexOptionsParser"
                                        label={this.translate('Label_File_Format_Text')}
                                        wmsSelector={this.state.selector + "IndexOptionsParser"}
                                        value={this.state.indexOptionsParser}
                                        onChangeFunc={this.changeIndexOptionsParser}
                                        options={this.state.optionsParser}
                                        textField='Description'
                                        valueField='TransformId'
                                        blankFirstOption={true}
                                        blankFirstOptionText={'Select format...'}
                                    />
                                </WmsContainer>

                            </div>
                        }

                        <div className="row">
                            <WmsCheckBox
                                id="chk_sel_all"
                                className="option-input"
                                name="chk_sel_all"
                                wmsSelector={this.state.selector + "ChkSelAll"}
                                onChangeFunc={this.handleCheckboxChange}
                                checked={this.state.chkManageImportOrder == true}
                                value=""
                                label=''
                            />
                            <div className="chk_label_mng_description">
                                {this.translate('Label_Import_Use_Quick_Import')}
                                <span className="warning-txt">
                                    {this.translate('Label_ASN_ColumnHeaders_Present')}
                                </span>
                            </div>
                        </div>

                        <div className="row wms-area-content">
                            <div className="wms-area-content-darker without-bg">
                                <div className="wms-area-content-darker wms-area-partial-border ">
                                    <div className="row">
                                        <span className="wms-standard-button">
                                            <a href="https://help.3plcentral.com/hc/en-us/articles/360038228512-Import-Templates" target="_blank">
                                                <span className="k-icon k-i-download"></span>
                                                {this.translate('Label_download_standard_template')}
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.state.showManageOrderSection == true && (
                    <ImportOrdersGrid
                        sharedGridData={this.state.manageOrderGridData}
                        sharedGridSummary={this.state.sharedGridSummary}
                        sharedFetchCustomerInfo={this.state.sharedFetchCustomerInfo}
                        windowId={this.state.windowId}
                        onSelectNewFile={this.onSelectNewFile}
                        sharedFileUploadData={this.state.sharedFileUploadData}
                        sharedSelectedCustomerId={this.state.customerOptions[this.state.customerIndex].id}
                    />
                )}

            </div >
        )

    }
}