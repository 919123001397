import React, { Component } from 'react';

// Components
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsContainer from '../components/WmsContainer/WmsContainer.jsx';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse.jsx';
import WmsDropdown from '../components/WmsDropdown/WmsDropdown.jsx';
import WmsGrid from '../components/WmsGrid/WmsGrid.jsx';
import { BarcodeDetailGrid } from './barcode-details-grid.component.jsx';

// Services
import * as barcodeServices from './barcode.services.jsx';
import * as GlobalService from './../global_services';

export class BarcodeGrid extends Component {
    constructor(props) {
        super(props)
        
        this.$rootScope = window;
        this.translate = window.translate;

        this.state = {
            Selector: 'Barcode',
            CustomLabels: GlobalService.getCustomLabels(),
            searchInfo: {
                Name: '',
                Group: '',
                Length: ''
            },
            selectedSearchInfo: {
                Name: '',
                Group: '',
                Length: ''
            },
            searchOn: false,
            gridExpanded: false,
            numberOfSelectedRows: 0,
            gifDeleteBarcode: false,
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if ( this.props.selectedCustomer.id != nextProps.selectedCustomer.id) {
            this.setState({
                selectedSearchInfo: {
                    Name: '',
                    Group: '',
                    Length: ''
                },
            })
        }
    }


    gridSearch = () => {

            var grid = $('#GridFindBarcodeDefs').data('kendoGrid');
            var dataSource = grid.dataSource;
            var filterArray = [];

            this.setState({ searchOn: true });

            if (typeof this.state.searchInfo.Group !== 'undefined' && this.state.searchInfo.Group !== '') {
                filterArray.push({
                    field: "Group",
                    operator: "eq",
                    value: this.state.searchInfo.Group
                });
            }
            if (typeof this.state.searchInfo.Length !== 'undefined' && this.state.searchInfo.Length !== '') {
                filterArray.push({
                    field: "TotalLength",
                    operator: "eq",
                    value: this.state.searchInfo.Length
                });
            }
            if (typeof this.state.searchInfo.Name !== 'undefined' && this.state.searchInfo.Name !== '') {
                filterArray.push({
                    field: "Name",
                    operator: "eq",
                    value: this.state.searchInfo.Name
                });
            }

            if (filterArray.length > 0) {
                dataSource.filter(filterArray);
            } else {
                this.clearFilters();
            }
    }

    clearFilters = () => {
        this.setState({
            searchOn: false,
            gridExpanded: false,
            selectedSearchInfo: {
                Name: '',
                Group: '',
                Length: ''
            },
            searchInfo: {
                Name: '',
                Group: '',
                Length: ''
            },
        });

        var grid = $('#GridFindBarcodeDefs').data('kendoGrid');
        var dataSource = grid.dataSource;
        dataSource.filter([]);
    }

    toggleExpandCollapse = (event) => {
        var grid = $('.' + this.props.windowId + ' #GridFindBarcodeDefs').data('kendoGrid');

        if (this.state.gridExpanded === false) {
            grid.expandRow(grid.tbody.find("tr.k-master-row"));
            this.setState({
                gridExpanded: true,
            });
        } else {
            grid.collapseRow(grid.tbody.find("tr.k-master-row"));
            this.setState({
                gridExpanded: false,
            });
        }
        grid.resize(true);
    }

    deleteBarcode = (event) => {
            var barcodeGrid = $('.' + this.props.windowId + ' #GridFindBarcodeDefs').data('kendoGrid');
            var listuris = [];
            var rows = barcodeGrid.select();

            this.setState({
                gifDeleteBarcode: true,
            });

            rows.each(function (index, row) {
                var selectedItem = barcodeGrid.dataItem(row);
                listuris.push(selectedItem.BarcodeUri);
                // selectedItem has EntityVersionId and the rest of your model
            });

            barcodeServices.DeleteBarcodeDefs(listuris).then((response) => {
                barcodeGrid.select().each(function () {
                    barcodeGrid.removeRow($(this));
                });

                this.props.indexOptionsCustomerChange();
            }).finally(() => {
                this.setState({
                    gifDeleteBarcode: false,
                    numberOfSelectedRows: 0,
                });
            });
    }

    onSelectRow = () => {
        var barcodeGrid = $('.' + this.props.windowId + ' #GridFindBarcodeDefs').data('kendoGrid');
        var rows = barcodeGrid.select();
        this.setState({
            numberOfSelectedRows: rows.length,
        });
    }

    updateFilter = (event, key) => {
        var index = event.target.value;
        var value = '';

        if (typeof index != 'undefined' && index != null && index != '') {
            if (key === 'Name') {
                value = this.props.sharedOptions.Names[index];
            } else if (key === 'Group') {
                value = this.props.sharedOptions.Groups[index];
            } else if (key === 'Length') {
                value = this.props.sharedOptions.Lengths[index];
            }
        }

        this.setState({
            selectedSearchInfo: Object.assign(
                this.state.selectedSearchInfo,
                {
                    [key]: index
                }
            ),
            searchInfo: Object.assign(
                this.state.searchInfo,
                {
                    [key]: value
                }
            ),
        });
    }

    clickEventOfContextMenu = (obj) => {
        if (obj.wmsid == 'delete') {
            this.deleteBarcode();
        }
    }

    render() {
        return (
            <div className="barcode-grid">
                <div>
                    <WmsButton
                        label={this.translate('Label_Search')}
                        wmsSelector={this.state.Selector + 'GridSearchButton'}
                        onClickFunc={this.gridSearch}
                        icon='fa-search' />
                    <WmsButton
                        label={this.translate('Label_Clear_Filters')}
                        buttonType='yellow'
                        wmsSelector={this.state.Selector + 'ClearSearchButton'}
                        onClickFunc={this.clearFilters}
                        icon='fa-minus' />

                    {this.state.searchOn == true && (
                        <div className="search-applied" >
                            <label>{this.translate('Label_Search_Applied_Filter')}</label>
                        </div>
                    )}
                </div>
                <div className={"barcode-form-boxes" + this.state.searchOn == true ? " width-title" : ""}>
                    <WmsContainer title={this.translate("Label_Barcode_Details")}>
                        <WmsDropdown
                            id="BarcodeName"
                            name="BarcodeName"
                            label={this.translate('Label_Barcode_Name')}
                            wmsSelector={this.state.Selector + 'SearchByBarcodeName'}
                            value={this.state.selectedSearchInfo.Name}
                            onChangeFunc={(e) => this.updateFilter(e, 'Name')}
                            options={this.props.sharedOptions.Names}
                            applyIndex={true}
                            blankFirstOption={true}
                            blankFirstOptionText={this.translate('Label_Select_Option')}
                        />
                        <WmsDropdown
                            id="BarcodeGroup"
                            name="BarcodeGroup"
                            label={this.translate('Label_Barcode_Group')}
                            wmsSelector={this.state.Selector + 'SearchByBarcodeGroup'}
                            value={this.state.selectedSearchInfo.Group}
                            onChangeFunc={(e) => this.updateFilter(e, 'Group')}
                            options={this.props.sharedOptions.Groups}
                            applyIndex={true}
                            blankFirstOption={true}
                            blankFirstOptionText={this.translate('Label_Select_Option')}
                        />
                        <WmsDropdown
                            id="BarcodeLength"
                            name="BarcodeLength"
                            label={this.translate('Label_Barcode_Length')}
                            wmsSelector={this.state.Selector + 'SearchByBarcodeLength'}
                            value={this.state.selectedSearchInfo.Length}
                            onChangeFunc={(e) => this.updateFilter(e, 'Length')}
                            options={this.props.sharedOptions.Lengths}
                            applyIndex={true}
                            blankFirstOption={true}
                            blankFirstOptionText={this.translate('Label_Select_Option')}
                        />
                    </WmsContainer>

                </div>
                <div className="wms-barcode-wrapper">

                    <WmsGrid
                        columns={[
                            {
                                field: "Name",
                                title: this.translate('Label_Barcode_ID'),
                                width: 120,
                                wmsid: 1
                            },
                            {
                                field: "TotalLength",
                                title: this.translate('Label_Barcode_Length'),
                                width: 120,
                                wmsid: 2
                            },
                            {
                                field: "Group",
                                title: this.translate('Label_Barcode_Group'),
                                width: 120,
                                wmsid: 3
                            }
                        ]}
                        schema={{
                            model: {
                                id: "BarcodeUri",
                                fields: {
                                    Name: {
                                        type: 'string'
                                    },
                                    TotalLength: {
                                        type: 'number'
                                    },
                                    Group: {
                                        type: 'string'
                                    },
                                    BarcodeID: {
                                        type: 'number'
                                    }
                                }
                            }
                        }}
                        gridKeySelection={'Name'}
                        virtual={false}
                        showGridSummary={true}
                        staticGridData={this.props.sharedOptions.BarcodeDefs}
                        gridName={"GridFindBarcodeDefs"}
                        id={"GridFindBarcodeDefs"}
                        windowId={this.props.windowId}
                        showGridResult={true}
                        showSortColumns={true}
                        showColumnFilter={false}
                        detailTemplate={BarcodeDetailGrid}
                        wmsSelector={this.state.Selector + "FindBarcodeGrid"}
                        inlineSelectAll={true}
                        onSelection={this.onSelectRow}
                        onSelectAllChange={() => null}
                        clickEventOfContextMenu={this.clickEventOfContextMenu}
                        menu={
                            [{
                                "name": (this.state.gridExpanded) ? this.translate('Label_Barcode_CollapseRows') : this.translate('Label_Barcode_ExpandRows'),
                                "value": [],
                                "disabled": false,
                                "icon": (this.state.gridExpanded) ? 'fa-minus' : 'fa-plus',
                                "color": "green",
                                'isContextMenu': false,
                                'isButtonMenu': true,
                                'wmsid': 'expandCollapse',
                                'wmsSelector': this.state.Selector + "ExpandCollapse",
                                'clickFunc': this.toggleExpandCollapse,
                            },
                            {
                                "name": this.translate('Label_Create'),
                                "value": [],
                                "disabled": false,
                                "icon": "fa-plus",
                                "color": "green",
                                'isContextMenu': false,
                                'isButtonMenu': true,
                                'wmsid': 'create',
                                'wmsSelector': this.state.Selector + "CreateButton",
                                'clickFunc': (e) => this.props.onOpenCreateBarcode(true),
                            },
                            {
                                "name": this.translate('Label_Delete'),
                                "value": [],
                                "disabled": (this.state.numberOfSelectedRows == 0) || this.state.gifDeleteBarcode,
                                "icon": "fa-trash",
                                "color": "red",
                                'isContextMenu': true,
                                'isButtonMenu': true,
                                'wmsid': 'delete',
                                'wmsSelector': this.state.Selector + "DeleteButton",
                                'clickFunc': this.deleteBarcode,
                                'loading': this.state.gifDeleteBarcode,
                            },
                            {
                                "name": "Copy",
                                "value": [{
                                    "name": this.translate('Label_Copy_Field'),
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": this.translate('Label_Copy_Field'),
                                    "icon": "fa-bandcamp",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyField'
                                }, {
                                    "name": this.translate('Label_Copy_Row'),
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": this.translate('Label_Copy_Row'),
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyRow'
                                }],
                                "disabled": false,
                                "disabledText": "You may not add blahssss0",
                                "icon": "fa-wrench",
                                "color": "green",
                                'isContextMenu': true,
                                'isButtonMenu': false
                            }]
                        }
                    />
                </div>
            </div>
        )
    }
}
