
import React from 'react';
import * as GlobalService from '../global_services';
import style from './report-iframe.scss';
var axios = require('axios');

export class reportIframe extends React.Component {
    constructor(props) {

        super(props);

        this.$rootScope = window;
        this.translate = window.translate;
        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            Rights: GlobalService.GetRights(),
            iframeSrc: "",
            iframeId: "",
            iframeUrl: "",
            iframeMethod:"",
            ids:""
        };
    }
    componentDidMount() {
        var iframeData = GlobalService.getIframeUrl();
        this.setState({
            iframeSrc: iframeData.url,
            iframeId: iframeData.name,
            iframeUrl: iframeData.url.split('?')[0],
            iframeMethod:iframeData.iframeMethod,
            ids:iframeData.paramOrderids

        });
        if(iframeData.iframeMethod !== "GET"){
            axios.put(iframeData.url,iframeData.paramOrderids,{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                this.setState({
                    iframeDoc: response.data
                });
            }).catch((error) => {
                console.log(error);
            });
        }
        window.onBolGeneratePDF =  () => {
            console.log("onBolGeneratePDF called to close Window");
            window.closeWindowFromTitlebar(this.state.windowId);
        };

        document.addEventListener("onBolGeneratePDFRemove", function handler(e) {
            console.log("onBolGeneratePDFRemove called");
            document.removeEventListener("onBolGeneratePDF", window.onBolGeneratePDF);
            e.currentTarget.removeEventListener(e.type, handler);
        });

        document.addEventListener("onBolGeneratePDF", window.onBolGeneratePDF);
    }
    iframeLoad = () => {
        var iframeId = this.state.iframeId;
        $('#' + iframeId + 'Loader').hide();
        if (this.state.iframeSrc == '/WebUI/V1/V1Link/BillingWizardMenu.aspx') {
            $('#' + iframeId).contents().find('#RateGrid_div-view').addClass('cls-billing-wizard');
            $('#' + iframeId).contents().find('#RateGrid_div').addClass('cls-billing-area');
        }
        if (this.state.iframeSrc == '/WebUI/V1/V1Link/WarehousePack.aspx') {
            $('#' + iframeId).contents().find('#form1').addClass('cls-smart-pack');
            $('#' + iframeId).contents().find('#ToBePackedGrid_div').addClass('cls-smart-area');
            $('#' + iframeId).contents().find('#PackageContentsGrid_div').addClass('cls-smart-area');
        }
        if (this.state.iframeUrl == "/WebUI/V1/SmartParcelRecreateIntlDocs") {
            $('#' + iframeId).contents().find('#DocGrid_div').addClass('recreate-document-grid');
        }
        if (this.state.iframeSrc == "/WebUI/V1/V1Link/ManageInvoices.aspx") {
            $('#' + iframeId).contents().find('#TransGrid_div').addClass('recreate-document-grid');
        }
        if (this.state.iframeSrc == "/WebUI/V1/V1Link/ImportLocation.aspx") {
            $('#' + iframeId).contents().find('#StreamData').addClass('import-location-no-margin');
        }
        if (this.state.iframeSrc == "/WebUI/V1/V1Link/MobileLabelTemplate.aspx") {
            $('#' + iframeId).contents().find('#content').addClass('mobile-label-template-content');
        }
        if (this.state.iframeSrc == "/WebUI/V1/V1Link/MobileLabel.aspx") {
            $('#' + iframeId).contents().find('#content').addClass('mobile-label-content');
        }
        if (this.state.iframeSrc == "/WebUI/V1/V1Link/MobilePrinter.aspx") {
            $('#' + iframeId).contents().find('#content').addClass('mobile-printer-content');
        }
        if (this.state.iframeSrc == "/WebUI/V1/V1Link/TrackBysEdit.aspx") {
            $('#' + iframeId).contents().find('#content').addClass('edit-trackbys-content');
        }
        if (this.state.iframeSrc == "/WebUI/V1/V1Link/ClientMessagingView.aspx") {
            $('#' + iframeId).contents().find('#content').addClass('client-message-content');
        }
    }
    render() {
        console.log("this source :",this.state.iframeSrc);
        return (
            <div className="iframe-controller">
                <div className="k-loading-mask">
                    <div id={this.state.iframeId + 'Loader'} className="k-loading-image iframe-mask">
                        <div className="k-loading-color"></div>
                    </div>
                    {this.state.iframeMethod && this.state.iframeMethod === "GET" && (
                        <iframe id={this.state.iframeId} onLoad={this.iframeLoad} src={this.state.iframeSrc} data-wms-id="smartview" name="smartviewiframe" className="smartviewiframe"></iframe>
                    )}
                    {this.state.iframeDoc && this.state.iframeMethod && this.state.iframeMethod !== "GET" && (
                        <iframe id={this.state.iframeId} onLoad={this.iframeLoad} srcDoc={this.state.iframeDoc} data-wms-id="smartview" name={this.state.iframeId} className="smartviewiframe"></iframe>
                    )}
                </div>
            </div>
        );
    }
}
