import React from 'react';
import PropTypes from 'prop-types';
import styles from './WmsButton.scss';

const rebrandedTypes = {
    blue: "primary",
    green: "primary",
    yellow: "orange",
    black: "outlined"
}

class WmsButton extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isHovering: false
        }
        this.handleMouseHover = this.handleMouseHover.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseHover() {
        this.setState({ isHovering: true });
    }

    handleMouseLeave() {
        this.setState({ isHovering: false });
    }

    render() {
        const mappedButtonType = rebrandedTypes[this.props.buttonType] ?? this.props.buttonType

        return (
            <div className={"wms-button-component " + this.props.parentClassName}>
                <div className={"button_holder " + (this.props.loading ? "disabled_button" : "")}>
                    <button
                        id={this.props.id}
                        className={
                            (this.props.disabled == true ?
                                (this.props.showToolTip == true ?
                                    "wms_disabled_look" :
                                    "wms_button_disabled"
                                ) :
                                " ") + ' ' +
                            (this.props.icon == '' ? 'textSpace ' : ' ') +
                            'wms_toolbar_button ' + mappedButtonType + '_button_color'
                        }
                        data-wms-selector={this.props.wmsSelector}
                        onClick={this.props.onClickFunc}
                        disabled={this.props.disabled}>
                        {this.props.loading ?
                            <div className="wms_sprite btnloader fa fa-spinner fa-spin"></div> :
                            this.props.icon != '' && (<div className={"wms_sprite fa " + this.props.icon}></div>)}
                        <div className="wms_toolbar_button_text">{this.props.label}</div>
                    </button>

                    {
                        this.props.disabled == true &&
                        this.props.showToolTip == true &&
                        this.props.loading == false &&
                        (<div>
                            <div className="div_disable" onMouseEnter={this.handleMouseHover}
                                onMouseLeave={this.handleMouseLeave}>&nbsp;</div>
                            {this.state.isHovering == true && (<div onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseLeave} className="wms-button-tooltip">{this.props.tooltipText}</div>)}
                        </div>)
                    }
                </div>
            </div>
        );
    }

}
WmsButton.propTypes = {
    id: PropTypes.string, // this should write the id to the button
    buttonType: PropTypes.string, // ['green', 'yellow', 'red', 'black', 'blue']
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    label: PropTypes.string.isRequired,// thi should set label on button
    icon: PropTypes.string,// set icon in button
    disabled: PropTypes.bool, // disable button
    showToolTip: PropTypes.bool, // set tooltip show or not
    tooltipText: PropTypes.string, // show text on hover as tooltip
    loading: PropTypes.bool, // this pass loading true/false base on process runing or complete
    onClickFunc: PropTypes.func.isRequired, // this should control the onClick event and anything we need to determine before we send it up
}
WmsButton.defaultProps = {
    buttonType: 'primary',
    loading: false,
    showToolTip: false,
    disabled: false,
    tooltipText: 'set tooltip text',
    icon: '',
    parentClassName: ''
}
export default WmsButton;