import * as GlobalService from "../global_services";
var isFirstTimeOpen = true;
var keyName = "CarrierMetaData";
var metaDataManageCarrierObject = {
  CarrierMetaData: {
    'CarrierGrid': {
      "gridConfig": {
      }
    }
  }
};

// call api for get metaData of order is it's open first time
export const getManageCarrierMetaData = function () {
  if (isFirstTimeOpen) {
    return GlobalService.getUserClientMetaData(keyName).then(
      function successCallBack(response) {
        if (response != "") {
          Object.assign(metaDataManageCarrierObject[keyName], JSON.parse(response));
        }
        // console.log("response  metaDataManageCarrierObject :", metaDataManageCarrierObject);
        isFirstTimeOpen = false;
        return true;
      },
      function errorCallBack(response) {
        console.log(response);
        return false;
      }
    );
  }
};


export const setManageCarrierGridGridOptionInMetaData = function (gridConfig) {
  console.log("setManageCarrierGridGridOptionInMetaData gridConfig", gridConfig);
  if (!metaDataManageCarrierObject[keyName]) {
    metaDataManageCarrierObject[keyName] = {};
  }

  metaDataManageCarrierObject[keyName]['CarrierGrid']['gridConfig'] = gridConfig.optionsData;
  GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataManageCarrierObject[keyName]));
};
export const getManageCarrierGridGridOptionInMetaData = function () {
  if (metaDataManageCarrierObject[keyName] && metaDataManageCarrierObject[keyName]['CarrierGrid'] && !$.isEmptyObject(metaDataManageCarrierObject[keyName]['CarrierGrid']['gridConfig'])) {
    return metaDataManageCarrierObject[keyName]['CarrierGrid']['gridConfig'];
  } else {
    return "";
  }
};
