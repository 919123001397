import React, { Component } from 'react';
// Component
import WmsModel from '../../components/WmsModel/WmsModel.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsCheckBox from '../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import * as GlobalService from '../../global_services';
import * as smallParcelService from './../smallParcel.service.jsx';
import './edit-address-detail.scss';

export class EditAddressDetail extends Component {

    constructor(props) {
        super(props);


        this.$rootScope = window;
        this.translate = window.translate;
        this.state = {
            selector: "smallParcelAddressDetail",
            oldOrderData: null,
            updatedOrderData: { ShipTo: {} },
            customLabels: GlobalService.getCustomLabels(),
            optionsCountry: GlobalService.GetCountries(),
            isCheckedStreetAddress2: false,
            isLoadingSaveAddressDetail: false
        }
    }

    componentDidMount() {
        kendo.ui.progress($('#EditAddressDetails'), true);
        smallParcelService.getOrderOptionsData(this.props.sharedSelectedOrderIds[0], this.props.sharedSelectedGridRecored).then((response) => {
            this.setState({
                oldOrderData: _.cloneDeep(response.Order),
                updatedOrderData: _.cloneDeep(response.Order),
                isCheckedStreetAddress2: response.Order.ShipTo.Address2 ? true : false
            });
        }).finally(() => {
            kendo.ui.progress($('#EditAddressDetails'), false);
        });
    }
    saveEditAddressDetails = () => {
        this.setState({ isLoadingSaveAddressDetail: true });
        var tmpUpdatedOrderData = this.state.updatedOrderData;

        if (!this.state.isCheckedStreetAddress2) {
            tmpUpdatedOrderData.ShipTo.Address2 = '';
        }
        if (this.hasContactChanged('ShipTo')) {
            tmpUpdatedOrderData.ShipTo['ContactId'] = null;
        }

        smallParcelService.updateAddressDetail(this.state.oldOrderData, this.state.updatedOrderData).then((response) => {
            console.log("getOrderOptionsData response", response)
            GlobalService.notification('success', this.translate('Label_Success_Address_Details'));
            this.props.updateAddressInfo();
            this.props.onCloseEditAddressDetailModal();
        }).finally(() => {
            this.setState({ isLoadingSaveAddressDetail: false });
        });
    }
    hasContactChanged = () => {

        var originalContactProps = Object.getOwnPropertyNames(this.state.oldOrderData.ShipTo);
        var originalContact = this.state.oldOrderData.ShipTo;
        var contactOrderData = this.state.updatedOrderData.ShipTo;


        for (var i = 0; i < originalContactProps.length; i++) {
            var propName = originalContactProps[i];
            if (originalContact.hasOwnProperty(propName)
                && contactOrderData.hasOwnProperty(propName)
                && originalContact[propName] !== contactOrderData[propName]) {

                return true;
            }
        }
        return false;
    }

    onChangeStreet2Address = (event) => {
        this.setState({
            isCheckedStreetAddress2: event.target.checked
        })
    }

    handleChangeEvent = (event) => {

        let orderObj = _.cloneDeep(this.state.updatedOrderData);
        orderObj.ShipTo[event.target.name] = event.target.value;
        if (event.target.name == 'Country') {
            orderObj.ShipTo.State = null;
        }
        this.setState({
            updatedOrderData: orderObj
        });
    }


    render() {
        return (
            <WmsModel
                id='EditAddressDetails'
                class="edit-address-detail-model-wrapper"
                title={this.translate('Label_Edit_Address_Details')}
                width={800}
                height={800}
                onCloseFunc={this.props.onCloseEditAddressDetailModal}
                isOpen={this.props.isOpenEditAddressDetailModal}
                customClass='wms-model-new-ui-wrapper'
            >

                <div className="wms-area-content-darker model-content-wrapper wms-area-partial-border">
                    <WmsCollapse
                        id={'AddressInformation'}
                        headerLabel={this.translate('Label_Address_Information')}
                        showCollapseText={false}
                        showCollapseIcon={false}>
                        <div className="display-inline">
                            <WmsInput
                                id='ShipToCompanyName'
                                inputName='CompanyName'
                                wmsSelector={this.state.selector + 'ShipToCompanyName'}
                                value={this.state.updatedOrderData.ShipTo.CompanyName}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Company_Name')}
                                maxLength={30}
                                extraWrapperClass={'street-address-one-wrapper-class'} />
                        </div>
                        <div className="display-inline">
                            <WmsInput
                                id='ShipToName'
                                inputName='Name'
                                wmsSelector={this.state.selector + 'ShipToName'}
                                value={this.state.updatedOrderData.ShipTo.Name}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_Recipient')}
                                regularExp={/^.{1,255}$/}
                                extraWrapperClass={'recipient-wrapper-class'} />

                            <WmsDropdown
                                id="ShipToCountry"
                                name="Country"
                                label={this.translate('Label_Country')}
                                wmsSelector={this.state.selector + 'ShipToCountry'}
                                value={this.state.updatedOrderData.ShipTo.Country}
                                onChangeFunc={this.handleChangeEvent}
                                options={this.state.optionsCountry}
                                textField='Name'
                                valueField='CountryCode'
                                required={true}
                                extraWrapperClass={'country-wrapper-class'} />
                        </div>

                        <div>
                            <div className="display-inline">
                                <WmsInput
                                    id='ShipToAddress1'
                                    inputName='Address1'
                                    wmsSelector={this.state.selector + 'ShipToAddress1'}
                                    value={this.state.updatedOrderData.ShipTo.Address1}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={this.translate('Label_Street_Address')}
                                    required={true}
                                    regularExp={/^.{1,255}$/}
                                    extraWrapperClass={'street-address-one-wrapper-class'} />

                                <WmsCheckBox
                                    id={'isCheckedStreetAddress2'}
                                    name={'isCheckedStreetAddress2'}
                                    wmsSelector={this.state.selector + 'isCheckedStreetAddress2'}
                                    onChangeFunc={this.onChangeStreet2Address}
                                    label={this.translate('Label_Street_Address_2_Optional')}
                                    checked={this.state.isCheckedStreetAddress2}
                                    value={this.state.isCheckedStreetAddress2}
                                    parentClassName="chk-street-address" />
                            </div>
                            {this.state.isCheckedStreetAddress2 && (
                                <WmsInput
                                    id='ShipToAddress2'
                                    inputName='Address2'
                                    wmsSelector={this.state.selector + 'ShipToAddress2'}
                                    value={this.state.updatedOrderData.ShipTo.Address2}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={this.translate('Label_Street_Address_2')}
                                    regularExp={/^.{1,255}$/}
                                    extraWrapperClass={'street-address-two-wrapper-class'} />
                            )}
                        </div>

                        <div className="display-inline">
                            <WmsInput
                                id='ShipToCity'
                                inputName='City'
                                wmsSelector={this.state.selector + 'ShipToCity'}
                                value={this.state.updatedOrderData.ShipTo.City}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_City_District')}
                                regularExp={/^.{1,255}$/}
                                required={true}
                                extraWrapperClass={'city-wrapper-class'} />

                            <WmsDropdown
                                id="ShipToStatesComboBox"
                                name="State"
                                label={this.translate('Label_State_Province')}
                                wmsSelector={this.state.selector + 'ShipToState'}
                                value={this.state.updatedOrderData.ShipTo.State}
                                onChangeFunc={this.handleChangeEvent}
                                options={GlobalService.GetRegions(this.state.updatedOrderData.ShipTo.Country)}
                                disabled={!this.state.updatedOrderData.ShipTo.Country}
                                showToolTip={true}
                                tooltipText={this.translate('Label_Country_Select_Validation')}
                                textField='Name'
                                valueField='RegionCode'
                                extraWrapperClass="state-wrapper-class" />

                            <WmsInput
                                id='ShipToZip'
                                inputName='Zip'
                                wmsSelector={this.state.selector + 'ShipToZip'}
                                value={this.state.updatedOrderData.ShipTo.Zip}
                                onChangeFunc={this.handleChangeEvent}
                                label={this.translate('Label_ZIP_Postal_Code')}
                                regularExp={/^[a-zA-Z0-9-_ ]*$/}
                                required={true}
                                extraWrapperClass="zip-wrapper-class" />
                        </div>
                    </WmsCollapse>
                </div>

                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Cancel')}
                        wmsSelector={this.state.selector + "Cancel"}
                        onClickFunc={this.props.onCloseEditAddressDetailModal}
                        disabled={false}
                        loading={false}
                        buttonType='orange'
                        icon={'fa-ban'}
                    />
                    <WmsButton
                        id={this.state.selector + "Save"}
                        label={this.translate('Label_Save')}
                        wmsSelector={this.state.selector + "Save"}
                        onClickFunc={this.saveEditAddressDetails}
                        loading={this.state.isLoadingSaveAddressDetail}
                        disabled={
                            !this.state.updatedOrderData.ShipTo.Country ||
                            !this.state.updatedOrderData.ShipTo.Address1 ||
                            !this.state.updatedOrderData.ShipTo.City ||
                            !this.state.updatedOrderData.ShipTo.Zip
                        }
                        icon="fa-floppy-o"
                    />
                </div>
            </WmsModel >
        );
    }
}