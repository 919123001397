import React, { useState, useEffect } from "react";
import AdjustInventorySearch from "./adjust-inventory-search.component";
import AdjustInventoryGrid from "./adjust-inventory-grid.component";
import "./adjustInventory.scss";
import * as AdjustInventoryServices from "./adjust-inventory.services";
interface Props {}

declare var window: any;
var $rootScope = window;
const wmsSelector = "AdustInventory";
const defaultFilterValue = {
    CustomerID: null,
    FacilityID: null,
    WarehouseTransactionID: null,
    ReferenceNum: null,
    StartDate: null,
    EndDate: null,
};

const AdjustInventoryHolder: React.FC<Props> = (props: Props): any => {
    const [windowId] = useState($rootScope.ChildBulletin.Data.Window);
    const [sharedSearchOn, setSharedSearchOn] = useState(false);
    const [sharedFilterObject, setSharedFilterObject] = useState(defaultFilterValue);
    const [loadGrid, setLoadGrid] = useState(false);

    useEffect(() => {
        async function getGridMeta() {
            await AdjustInventoryServices.getAdjustInventoryClientMetaData();
            let filterData = AdjustInventoryServices.getAdjustInventoryFilterMetaData();
            if (filterData) {
                setSharedFilterObject(filterData);
            }
            setLoadGrid(true);
        }
        getGridMeta();
    }, []);

    const onClearSearch = () => {
        AdjustInventoryServices.clearAdjustInventoryFilterMetaData();
        setSharedSearchOn(false);
        setSharedFilterObject(defaultFilterValue);
    };

    const onFilterDataSet = (filterObject: any) => {
        if (
            filterObject.CustomerID !== 0 ||
            filterObject.FacilityID !== 0 ||
            filterObject.WarehouseTransactionID !== null ||
            filterObject.ReferenceNum !== null ||
            filterObject.StartDate !== null ||
            filterObject.EndDate !== null
        ) {
            setSharedSearchOn(true);
        }
        setSharedFilterObject(filterObject);
        AdjustInventoryServices.setAdjustInventoryFilterMetaData(filterObject);
    };

    return (
        <div className="adjust-customer-charges-wrapper">
            <AdjustInventorySearch
                wmsSelector={wmsSelector}
                sharedSearchOn={sharedSearchOn}
                sharedFilterObject={sharedFilterObject}
                onClearSearch={onClearSearch}
                onFilterDataSet={onFilterDataSet}
                windowId={windowId}
            />
            {loadGrid && (
                <AdjustInventoryGrid
                    // ref={adjustInventoryGridRef}
                    wmsSelector={wmsSelector}
                    windowId={windowId}
                    sharedFilterObject={sharedFilterObject}
                />
            )}
        </div>
    );
};
AdjustInventoryHolder.propTypes = {};

AdjustInventoryHolder.defaultProps = {};

export default React.memo(AdjustInventoryHolder);
