import React from 'react';

//component
import { default as WmsInput } from './../components/WmsInput/WmsInput.jsx';
import { default as WmsGrid } from './../components/WmsGrid/WmsGrid.jsx';
import { default as WmsDropdown } from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import { ObjectWms } from './../../../assets/ts/ObjectWms.ts';

//services
import * as itemService from './items.services.jsx';
import * as GlobalService from './../global_services';

export class ItemsFormRecurringStorage extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            selector: 'Item',
            rateForAll: '',
            rateForSelected: '',
            selectedStorageUOM: '',
            recurringGridData: [],
            isChargeGridFirstBound: false,
            rowNumberSet: false,
            currentSelections: 0,
            lastSelectedStorageUOM: ''
        }
    }

    componentDidMount() {
        if (this.props.sharedCoutinngMechanismIndex != null) {
            this.setRateChargesGridData(this.props.sharedCoutinngMechanismIndex, this.props.sharedStorageRateResponse.CountUnitId);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            (nextProps.sharedCoutinngMechanismIndex != '' &&
                this.props.sharedCoutinngMechanismIndex != nextProps.sharedCoutinngMechanismIndex) ||
            (this.props.sharedStorageRateResponse.CountUnitId != nextProps.sharedStorageRateResponse.CountUnitId)
        ) {
            this.setRateChargesGridData(nextProps.sharedCoutinngMechanismIndex, nextProps.sharedStorageRateResponse.CountUnitId);
        }
        if(nextProps.eachesPerCuft != this.props.eachesPerCuft){
            let recurringObj = Object.assign({}, this.props.sharedStorageRateResponse);
            let storageOpt = this.props.sharedRecurringOptions.StorageCountScriptsArr[this.props.sharedCoutinngMechanismIndex];
            if(storageOpt){
                if(this.getNameToCheckCuft(storageOpt.CountUnitIdentifiers) == "CuFt" && !nextProps.isSetEachesPerCuft) {
                    recurringObj["InventoryUnitsPerUnit"] = nextProps.eachesPerCuft ? (nextProps.eachesPerCuft) : "";
                    this.props.onRecurringStorageDataset(recurringObj);
                }
            }

        }
    }
    getNameToCheckCuft = (data) =>{
        var id = this.props.sharedStorageRateResponse.CountUnitId;
        var findObj = data.find(x => x.Id == id);
        if(findObj){
            return findObj.Name;
        }
        return "";
    }
    setRateChargesGridData = (param_index, param_countId) => {
        kendo.ui.progress($('#RecurringStorageGrid'), true);
        let tmpScriptTemplateObj = this.props.sharedRecurringOptions.StorageCountScriptsArr[param_index];

        if (!tmpScriptTemplateObj) { return; }

        let tmpQueryString = 'customerId=' + this.props.sharedCurrentCustomerObj.id + '&scriptIdentifier=' + tmpScriptTemplateObj.StorageCountScriptTemplateIdentifier.Id;

        let uomUnitIdentifier = (param_countId) ? param_countId : 1;
        if (tmpScriptTemplateObj.IsLegacyStorageUomScript) {
            tmpQueryString += '&uomUnitIdentifier=' + uomUnitIdentifier;
        }

        var payloadData = (this.props.sharedMultiSkuEnableRecurring) ? null : { "facilityRates": this.props.sharedStorageRateResponse.Rates };

        itemService.storageRateCharges(tmpQueryString, payloadData).then((response) => {
            if (response.status == 200) {
                this.setState({
                    recurringGridData: response.data,
                    isChargeGridFirstBound: false,
                    rowNumberSet:false,
                    rateForAll: ''
                });
            }
        }).finally(() => {
            kendo.ui.progress($('#RecurringStorageGrid'), false);
        });
    }

    onApplyToSelectedClick = (param_value) => () => {
        var grid = $("#RecurringStorageGrid").data("kendoGrid");
        var selected = grid.select();
        var header = grid.thead;
        for (var i = 0, max = selected.length; i < max; i++) {
            var index = grid.cellIndex(selected[i]);
            var th = $(header).find("th").eq(index);
            var item = grid.dataItem($(selected[i]).closest("tr"));
            item['Charge'] = param_value;
            this.setState({
                rateForSelected: '',
                rateForAll: ''
            });
        }
        grid.refresh();

        //call wmsGrid selectAll checkbox change event for unchecked
        let event = { target: { checked: false } };
        this.refs.wmsGrid.onChangeFunc(event);

        let recurringObj = Object.assign({}, this.props.sharedStorageRateResponse);
        recurringObj['StorageChargeGridRows'] = Object.assign([], grid._data);
        this.props.onRecurringStorageDataset(recurringObj);
    }

    onApplyToAllClick = (param_field, param_value) => {

        let recurringObj = Object.assign({}, this.props.sharedStorageRateResponse);
        var tempGridData = Object.assign([], this.state.recurringGridData);

        for (let i = 0; i < tempGridData.length; i++) {
            tempGridData[i][param_field] = param_value;
        }
        this.setState({
            recurringGridData: tempGridData,
            rateForSelected: '',
            rateForAll: ''
        });
        recurringObj['StorageChargeGridRows'] = Object.assign([], tempGridData);
        this.state.rowNumberSet = false;
        this.props.onRecurringStorageDataset(recurringObj);
    }

    onApplyStorageUomInGrid = (param_index) => {
        let storageUomOptions = this.props.sharedRecurringOptions.StorageCountScriptsArr[this.props.sharedCoutinngMechanismIndex].CountUnitIdentifiers;

        var tempGridData = Object.assign([], this.state.recurringGridData);
        for (let i = 0; i < tempGridData.length; i++) {
            tempGridData[i]['CountUnitName'] = storageUomOptions[param_index].Name;
            tempGridData[i]['CountingUnitId'] = storageUomOptions[param_index].Id;
        }
        this.setState({ recurringGridData: tempGridData });
    }

    onDataBound = (e) => {
        let recurringObj = Object.assign({}, this.props.sharedStorageRateResponse);
        recurringObj['StorageChargeGridRows'] = Object.assign([], e.sender.dataSource._data);
        if (this.state.isChargeGridFirstBound == false) {
            this.props.onInitialStorageChargeGridData(recurringObj);
            this.state.isChargeGridFirstBound = true;
        }
        if (this.state.rowNumberSet == false) {
            this.rowNumberUpdate();
        }
    }
    rowNumberUpdate = () => {
        var grid = $('#RecurringStorageGrid').data("kendoGrid");
        var dataSource = grid.dataSource;
        var data = dataSource.data();
        var sort = dataSource.sort();
        var query = new kendo.data.Query(data);
        var sortedData = query.data;
        var index = 0;
        for (var i = 0; i < sortedData.length; i++) {
            //access record using for loop - here i is value from loop
            var firstItem = sortedData[i];
            index++;
            firstItem["RowNumber"] = index;
        }
        this.state.rowNumberSet = true;
        $('#RecurringStorageGrid').data("kendoGrid").refresh();
    }

    onEdit = (e) => {
        let that = this;
        $('input.k-input').unbind('input');
        $('input.k-input').on("input", function () {
            // Print entered value in a div box
            that.setState({
                rateForAll: $(this).val(),
                rateForSelected:$(this).val()
            });
        });
    }

    onChange = (e) => {
        let that = this;
        $(document).off('focus', 'input.k-input');
        $(document).on("focus", 'input.k-input', function () {
            that.setState({
                rateForSelected: $(this).val(),
                rateForAll: $(this).val()
            }, () => {
                that.onSelectRow();
            });
        });
    }
    onGridSave = (e) => {
        var grid = $('#RecurringStorageGrid').data('kendoGrid');
        let recurringObj = Object.assign({}, this.props.sharedStorageRateResponse);
        recurringObj['StorageChargeGridRows'] = Object.assign([], grid._data);
        this.props.onRecurringStorageDataset(recurringObj);
    }


    changeRecurringData = (e) => {

        let recurringObj = Object.assign({}, this.props.sharedStorageRateResponse);

        if (e.target.name == 'CountUnitId') {

            this.onApplyStorageUomInGrid(e.target.value);

            let storageOpt = this.props.sharedRecurringOptions.StorageCountScriptsArr[this.props.sharedCoutinngMechanismIndex];
            recurringObj['CountUnitId'] = storageOpt.CountUnitIdentifiers[e.target.value].Id;
            if(storageOpt.CountUnitIdentifiers[e.target.value].Name == "CuFt" && !this.props.isSetEachesPerCuft) {
                recurringObj["InventoryUnitsPerUnit"] = this.props.eachesPerCuft ? this.props.eachesPerCuft : '';
            } else if(this.state.lastSelectedStorageUOM == "CuFt" && !this.props.isSetEachesPerCuft) {
                recurringObj["InventoryUnitsPerUnit"] = "";
            }

            this.setState({lastSelectedStorageUOM: storageOpt.CountUnitIdentifiers[e.target.value].Name });
        } else {
            recurringObj[e.target.name] = e.target.value;
        }

        this.props.onRecurringStorageDataset(recurringObj);
    }

    onSelectRow = (param_kendogrid) => {
        var grid = $('#RecurringStorageGrid').data('kendoGrid');
        var selectedRecored = grid.select().length;
        this.setState({ currentSelections: selectedRecored });
    }
    render() {

        let renderStorageTemp = {};
        let storageIndex = -1;
        let countScriptArr = this.props.sharedRecurringOptions.StorageCountScriptsArr;
        renderStorageTemp = countScriptArr[this.props.sharedCoutinngMechanismIndex];
        if (renderStorageTemp && this.props.sharedStorageRateResponse.CountUnitId) {
            storageIndex = ObjectWms.FindIndexByProperty(renderStorageTemp.CountUnitIdentifiers, "Id", this.props.sharedStorageRateResponse.CountUnitId.toString());
        }

        return (

            <WmsCollapse
                id={'ItemRecurringStorageDetails'}
                headerLabel={this.translate('Label_Recurring_Storage_Details')}
                showCollapseText={false}
                showCollapseIcon={false} >
                <div className="wms-recurring-input-holder">
                    <WmsDropdown
                        id="coutinngMechanismIndex"
                        name="coutinngMechanismIndex"
                        label={this.translate('Label_Counting_Mechanism')}
                        wmsSelector={this.state.selector + "CountingMechanism"}
                        value={this.props.sharedCoutinngMechanismIndex}
                        onChangeFunc={this.props.onCountingMechnismChange}
                        options={this.props.sharedRecurringOptions.optionCountingMechanism}
                        applyIndex={true}
                        blankFirstOption={true}
                        extraWrapperClass="wms-dropdown-counting-mechanism-box"
                        textField='Name'
                        blankFirstOptionText="Unspecified" />
                    {renderStorageTemp && renderStorageTemp.IsLegacyStorageUomScript && (
                        <WmsDropdown
                            id="CountUnitId"
                            name="CountUnitId"
                            label={this.translate('Label_Storage_UOM')}
                            wmsSelector={this.state.selector + "CountUnitId"}
                            onChangeFunc={this.changeRecurringData}
                            value={storageIndex == -1 ? '' : storageIndex}
                            options={renderStorageTemp.CountUnitIdentifiers}
                            textField='Name'
                            parentClassName='form_group'
                            extraWrapperClass="wms-storage-box-UOM-box"
                            applyIndex={true} />
                    )}
                    {renderStorageTemp && renderStorageTemp.IsLegacyStorageUomScript && (
                        <WmsInput
                            id='InventoryUnitsPerUnit'
                            inputName='InventoryUnitsPerUnit'
                            wmsSelector={this.state.selector + 'InventoryUnitsPerUnit'}
                            onChangeFunc={this.changeRecurringData}
                            label={this.translate('Label_Eaches_Per_Each') +
                                (storageIndex == -1 ? '' : ' ' + renderStorageTemp.CountUnitIdentifiers[storageIndex].Name)}
                            extraWrapperClass="wms-input-eaches-per-each-box"
                            value={this.props.sharedStorageRateResponse.InventoryUnitsPerUnit}
                            regularExp={/^[+-]?([0-9]+([.]?)(\d?\d?\d?\d?\d?\d?\d?\d?\d?\d)?)$/}
                            required={storageIndex != -1 && !this.props.sharedStorageRateResponse.InventoryUnitsPerUnit} />
                    )}
                    {renderStorageTemp && renderStorageTemp.IsMinCharge && (
                        <WmsInput
                            id='MinCharge'
                            inputName='MinCharge'
                            wmsSelector={this.state.selector + 'MinCharge'}
                            onChangeFunc={this.changeRecurringData}
                            label={this.translate('Label_Min_Charge')}
                            extraWrapperClass="wms-input-min-charge-box"
                            value={this.props.sharedStorageRateResponse.MinCharge} />
                    )}
                    {renderStorageTemp && renderStorageTemp.IsMinCharge && (
                        <WmsInput
                            id='MonthsToSkipMinCharge'
                            inputName='MonthsToSkipMinCharge'
                            wmsSelector={this.state.selector + 'MonthsToSkipMinCharge'}
                            onChangeFunc={this.changeRecurringData}
                            label={this.translate('Label_Months_To_Skip_Min_Charge')}
                            extraWrapperClass="wms-input-months-box"
                            value={this.props.sharedStorageRateResponse.MonthsToSkipMinCharge} />
                    )}
                </div>
                {renderStorageTemp && (

                    <WmsGrid
                        ref="wmsGrid"
                        wmsSelector={this.state.selector + 'RecurringStorageGrid'}
                        gridKeySelection={'RowNumber'}
                        staticGridData={this.state.recurringGridData}
                        gridName={'RecurringStorageGrid'}
                        isAlignIndexesByProperty={true}
                        inlineSelectAll={true}
                        virtual={false}
                        showResetButton={false}
                        // gridHeight={225}
                        id={'RecurringStorageGrid'}
                        isRemainSelectionForEditCell={true}
                        onSelection={this.onSelectRow}
                        onEdit={this.onEdit}
                        onChange={this.onChange}
                        onDataBound={this.onDataBound}
                        onGridSave={this.onGridSave}
                        columns={[
                            {
                                field: "RowNumber",
                                title: "#",
                                hidden: true,
                                menu: false,
                                width: "100%"
                            }, {
                                field: "FacilityName",
                                title: "Warehouse",
                                lockable: false,
                                width: "100%"
                            },
                            {
                                field: "CountUnitName",
                                title: "Counting Unit",
                                lockable: false,
                                width: "100%"
                            },
                            {
                                field: "Charge",
                                title: "Rate",
                                lockable: false,
                                width: "100%",
                                editor: function(container, options) {
                                      // create an input element
                                      $("<input name='" + options.field + "'/>")
                                        .appendTo(container)
                                        .kendoNumericTextBox({
                                            format: "n4",
                                            decimals: 4
                                        });
                                },
                                filterable: {
                                    ui: function (element) {
                                        element.kendoNumericTextBox({
                                            format: "n4",
                                            decimals: 4
                                        });
                                    }
                                }
                            }
                        ]}
                        schema={{
                            model: {
                                fields: {
                                    RowNumber: {
                                        type: 'number'
                                    },
                                    FacilityName: {
                                        type: "string",
                                        editable: false
                                    },
                                    CountUnitName: {
                                        type: "string",
                                        editable: false
                                    },
                                    Charge: {
                                        type: "number",
                                        editable: true
                                    }
                                }
                            }
                        }}
                        showGridSummary={true}
                        windowId={this.props.windowId}
                        shawCheckbox={true}
                        showGridResult={true}
                        showSortColumns={false}
                        gridEditable={true}
                        clickEventOfContextMenu={() => { }}
                        menu={[{
                            "name": this.translate('Btn_Label_Copy_From_Item'),
                            "value": [],
                            "disabled": false,
                            "icon": "fa-files-o",
                            "color": "green",
                            'isContextMenu': false,
                            'isButtonMenu': true,
                            'wmsid': 'applyToAll',
                            'wmsSelector': this.state.selector + "CopyFromItem",
                            'clickFunc': this.props.onOpenCopyItemModal
                        },
                        {
                            "name": this.translate('Btn_Label_Apply_to_All'),
                            "value": [],
                            "disabled": this.state.rateForAll == '',
                            "icon": "fa-list-alt",
                            "color": "green",
                            'isContextMenu': false,
                            'isButtonMenu': true,
                            'wmsid': 'applyToAll',
                            'wmsSelector': this.state.selector + "ApplyToAll",
                            'clickFunc': (this.state.rateForAll != '' ? () => this.onApplyToAllClick('Charge', this.state.rateForAll) : () => { })
                        }, {
                            "name": this.translate('Btn_Label_Apply_to_Selected'),
                            "value": [],
                            "disabled": this.state.rateForSelected == '' || this.state.currentSelections == 0,
                            "icon": "fa-align-justify",
                            "color": "green",
                            'isContextMenu': false,
                            'isButtonMenu': true,
                            'wmsid': 'applyToSelected',
                            'wmsSelector': this.state.selector + "ApplyToSelected",
                            'clickFunc': this.onApplyToSelectedClick(this.state.rateForSelected)
                        }, {
                            "name": this.translate('Btn_Label_Clear_Selected_Rates'),
                            "value": [],
                            "disabled": this.state.currentSelections == 0,
                            "icon": "fa-minus",
                            "color": "yellow",
                            'isContextMenu': false,
                            'isButtonMenu': true,
                            'wmsid': 'clearRated',
                            'wmsSelector': this.state.selector + "clearRated",
                            'clickFunc': this.onApplyToSelectedClick(null)
                        }]} />

                )}
            </WmsCollapse>
        )
    }
}