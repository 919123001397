import React from 'react';
import PropTypes from 'prop-types';
import { ObjectWms } from '../../../../assets/ts/ObjectWms.ts';
import style from "./VirtualGroupGrid.scss";

class VirtualGroupGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.props.id : '#' + this.props.id,
            columns: props.columns,
            schema: props.schema,
            sorts: []
        }
    }

    componentDidMount() {
        var self = this;
        $(document).off("click", this.state.gridDomSelector() + " .k-grouping-row");
        $(document).on("click", this.state.gridDomSelector() + " .k-grouping-row", function (e) {

            if (!$(this).hasClass('k-state-active-header')) {
                $('.k-grouping-row').removeClass('k-state-active-header');
                $(this).addClass('k-state-active-header');
            }

            self.props.onClickGroupHeader(this);
        });
        /* $(document).off("click", this.state.gridDomSelector() + ' [role="row"]');
        $(document).on("click", this.state.gridDomSelector() + ' [role="row"]', (e) => {
            // this.props.onSelection(e);
        }); */

        // grid column config set
        if (this.props.metaGridConfig != "") {
            var gridOptions = JSON.parse(this.props.metaGridConfig);
            var tempObject = this.props.columns;
            if (gridOptions.columns) {
                var fieldArray = gridOptions.columns.map(el => el.field);
                var updateColumn = [];
                this.props.columns.filter((column) => {
                    updateColumn.push(column);
                    // if (fieldArray.indexOf(column.field) !== -1) {
                    //     updateColumn.push(column);
                    // } else if (column.locked && column.isShawRowNumberColumnInList != true) {
                    //     updateColumn.push(column);
                    // }
                });
                tempObject = ObjectWms.alignIndexesByPropertyVirtual(gridOptions.columns, updateColumn, "field");
            } else {
                tempObject = ObjectWms.alignIndexesByPropertyVirtual(gridOptions.columns, this.props.defaultColumns, "field")
            }

            this.setState({
                columns: tempObject,
                sorts: gridOptions.sorts
            }, this.loadGrid);
        } else {
            this.loadGrid();
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof this.props.staticGridData != "undefined" && _.isEqual(this.props.staticGridData, prevProps.staticGridData) == false) {
            var scrollPos = $(this.state.gridDomSelector() + " .k-grid-content").scrollTop();
            var grid = $(this.state.gridDomSelector()).data("kendoGrid");
            grid.dataSource.data(this.props.staticGridData);
            if (this.props.sortOptions != null && _.isEqual(this.props.sortOptions, prevProps.sortOptions) == false) {
                let tmpOption = grid.options;
                tmpOption.sortable = this.props.sortOptions;
                grid.setOptions(tmpOption);
            }
            $(this.state.gridDomSelector() + " .k-grid-content").scrollTop(scrollPos);
        }
    }


    loadGrid = () => {
        var that = this;
        $(this.state.gridDomSelector()).kendoGrid({
            dataSource: {
                data: that.props.staticGridData,
                schema: this.state.schema,
                sort: this.state.sorts,
                group: {
                    field: this.props.groupKeyName, dir: this.props.groupSortBy
                }
            },
            height: this.props.gridHeight,
            sortable: {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            },
            selectable: "multiple",
            navigatable: true,
            filterable: false,
            inPlaceSort: true,
            columnMenu: false,
            editable: this.props.gridEditable,
            sort: (e) => {
                this.props.onSort(e);
            },
            dataBound: (e) => {
                // this code for remove group header 'k-group-cell' which blank first td in grid
                if ($(".k-group-col,.k-group-cell") && $(".k-group-col,.k-group-cell").length > 0) {
                    $(".k-group-col,.k-group-cell").remove();
                    var spanCells = $(".k-grouping-row").children("td");
                    spanCells.attr("colspan", spanCells.attr("colspan") - 1);
                }
                $(this.state.gridDomSelector() + " tbody").off("click", "tr:not('[class*=k-grouping-row]')");
                $(this.state.gridDomSelector() + " tbody").on("click", "tr:not('[class*=k-grouping-row]')", (e) => {
                    var row = $(e.currentTarget);
                    var gridRef = $(this.state.gridDomSelector()).data("kendoGrid");
                    if (row.hasClass("k-state-selected") && (!e.shiftKey) && (!e.ctrlKey && !e.metaKey)) {
                        var selectionCounter = (gridRef.select() && gridRef.select().length);
                        gridRef.clearSelection();
                        if (selectionCounter > 1) {
                            gridRef.select(row);
                            setTimeout(() => {
                                this.props.onSelection(e);
                            }, 100);
                        }
                        e.stopPropagation();
                    } else {
                        setTimeout(() => {
                            this.props.onSelection(e);
                        }, 100);
                    }
                });
                this.props.onDataBound(e);
            },
            change: (e) => {
                // console.log("VirtualGroupGrid change e", e)
                // that.props.onSelection(e);
            },
            columnShow: (e) => {
                this.props.callForGetNewData();
            },
            columnReorder: (e) => {
                this.props.onColumnReorder(e);
            },
            columnResize: (e) => {
                this.props.onColumnResize(e);
            },
            resizable: true,
            reorderable: true,
            columns: this.state.columns
        });
    }


    render() {
        return (
            <div className="wms-virtual-group-grid-holder">
                <div className="wms-grid-area-wrapper">
                    <div className="wms_area_border">
                        <div id="gridContainer" className="gridContainer">
                            <div
                                className="no-josh"
                                id={this.props.id}
                                data-wms-selector={this.props.wmsSelector}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

VirtualGroupGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    staticGridData: PropTypes.array,
    id: PropTypes.string.isRequired, // this should write the id to the input
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    windowId: PropTypes.string,
    onSelection: PropTypes.func.isRequired, // this should control the selection of row event
    getGridSelectionUrl: PropTypes.string, // url of get selection list of records
    gridEditable: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]), // set true or multiple edit column object to make grid columns editable
    gridHeight: PropTypes.string, // number for set grid height in px
    // onLoadGrid: PropTypes.func,
    onDataBound: PropTypes.func,
    callForGetNewData: PropTypes.func,
    onSort: PropTypes.func,// this function call when grid column's sort
    onColumnReorder: PropTypes.func,// this function call when grid column's order change
    onColumnResize: PropTypes.func, // this function call when grid column's size change
    groupKeyName: PropTypes.string.isRequired,// group key name
    onClickGroupHeader: PropTypes.func

}

VirtualGroupGrid.defaultProps = {
    gridEditable: false,
    gridHeight: '99%',
    groupSortBy: 'asc',
    onDataBound: (e) => function () { },
    onSort: (e) => function () { },
    onColumnReorder: (e) => function () { },
    onColumnResize: (e) => function () { },
    onClickGroupHeader: (e) => function () { },
    sortOptions: null,
}

export default VirtualGroupGrid;