import React, { useState, useCallback, useEffect } from "react";

import PropTypes from "prop-types";
import { StringWms } from "../../assets/ts/StringWms";
import { AddEditColumnModel } from "../react/components/AddEditColumn/add-edit-columns.component";
import { LoadingModel } from "../react/components/LoadingModel/LoadingModel";
import { ArrayWms } from "../../assets/ts/ArrayWms";
import { ConcurrencyManager } from "../../assets/vendor/concurrencyManager/concurrencyManager";
import VirtualGridComponent from "../react/components_proto/VirtualGridComponent/VirtualGridComponent";
import ManageMULabelTemplateModal from "./manage-mu-label-template.modal.component";
import * as GridConfig from "./mobile-template-label-grid-configuration";
import * as GlobalService from "../react/global_services";
import * as MobileLabelTemplates from "./mobile-label-template.services";

import "./mobileLabelTemplates.scss";
import _ from "lodash";

var axios = require("axios");
interface gridFilterType {
    labelName: string;
    cusId: number;
}

interface Props {
    wmsSelector: string;
    windowId: string;
    sharedFilterObject: gridFilterType;
}

declare var window: any;
const { $ } = window;

const gridId = "MobileLabelTemplatesGrid";

const MobileLabelTemplatesGrid: React.FC<Props> = (props: Props) => {
    const mobileLabelTemplatesGridHeaders = StringWms.convertSringToOption(
        window.translate("Mobile_Label_Template_GridHeaders")
    );
    const defaultColumns = GridConfig.getMobileLabelTemplatesColumns(mobileLabelTemplatesGridHeaders).columns;
    const allColumnList = GridConfig.getMobileLabelTemplatesAllColumnList(mobileLabelTemplatesGridHeaders);
    const [schema] = useState(GridConfig.getMobileLabelTemplatesSchema());
    const gridDomSelector = props.windowId ? "." + props.windowId + " #" + gridId : "#" + gridId;
    const [gridConfig, setGridConfig] = useState("");
    let filterCountAxiosSource = null;
    let firstFiftyAxiosSource = null;
    let axiosSource = null;
    let isApiCanceled = false;

    const [filterText, setFilterText] = useState("");
    const [fullFilterText, setFullFilterText] = useState("");
    const [recordsCount, setRecordsCount] = useState(0);
    const [gridData, setGridData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [isOpenColumnModal, setIsOpenColumnModal] = useState(false);
    const [sharedIsOpenManageMULabelTemplate, setSharedIsOpenManageMULabelTemplate] = useState(false);
    const [mobileTemplatesType, setMobileTemplatesType] = useState("CreateLabel");
    const [isAllDataLoad, setIsAllDataLoad] = useState(false);
    const [isFilterRowCountLoading, setIsFilterRowCountLoading] = useState(false);
    const [showLoadingError, setShowLoadingError] = useState(false);
    const [isNewColumnAdded, setIsNewColumnAdded] = useState(false);
    const [isGridDataLoading, setIsGridDataLoading] = useState(false);
    const [deleteLabelLoading, setDeleteLabelLoading] = useState(false);
    const [overlayScreenShow, setOverlayScreenShow] = useState(false);
    const [showGrid, setShowGrid] = useState(false);
    const [totalRequest, setTotalRequest] = useState(0);
    const [localKendoGrid, setLocalKendoGrid] = useState(null);
    const [gridPageSize] = useState(GlobalService.constants.virtualGridDataLimit);

    const onselectGridRow = (KendoGrid: any) => {
        let tmpKey = KendoGrid.getKeys();
        setSelectedRow(null);
        if (tmpKey.length === 1) {
            setSelectedRow(KendoGrid.getItem(tmpKey));
        }
        setSelectedRowCount(tmpKey.length);
    };

    const clickEventOfContextMenu = (obj: any, e: any) => {
        if (obj.wmsid === "createLabel") {
            openManageMULabelTemplate("CreateLabel")(e);
        } else if (obj.wmsid === "CopyLabel") {
            openManageMULabelTemplate("CopyLabel")(e);
        } else if (obj.wmsid === "EditLabel") {
            openManageMULabelTemplate("EditLabel")(e);
        } else if (obj.wmsid === "DeleteLabel") {
            deleteMULabelTemplate(e);
        }
    };

    const onGridDataBound = (e, kendoGrid) => {
        setLocalKendoGrid(kendoGrid);
    };

    // on grid filter selection
    const onGridFilterApplied = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            let filter = dataSource.filter();
            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            if (localKendoGrid != null) {
                var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, filter);
                MobileLabelTemplates.setMobileLabelTemplatesGridOptionInMetaData(finalSubmission);
            }
            setFilterAppliedText(filter);
        }, 50);
    };

    const setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            setFilterText("");
            setFullFilterText("");
            return;
        }
        var filterConditionTitle = {
            lte: "Is Less Than Or Equal To",
            eq: "Is Equal To",
            lt: "Is Less Than",
            neq: "Is Not Equal To",
            gte: "Is Greater Than Or Equal To",
            gt: "Is Greater Than",
            startswith: "Starts With",
            endswith: "Ends With",
            contains: "Contains",
            doesnotcontain: "Does Not Contain",
            isnull: "Is Null",
            isnotnull: "Is Not Null",
            isempty: "Is Empty",
            isnotempty: "Is Not Empty",
            isnullorempty: "Has No Value",
            isnotnullorempty: "Has Value",
        };

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;

        filterList.map((item) => {
            var title = allColumnList.find((x) => x.field === item.field).title;
            filterText += title + " : " + item.value + ",";
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            return item;
        });
        filterText = filterText.slice(0, -1);
        setFilterText(filterText);
        setFullFilterText(fullFilterText);
    };

    // on grid column sort save changes in meta data object
    const onSort = (event) => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var OrderBySortObj = _.find(sortArray, { field: "OrderBy" });
        if (!OrderBySortObj) {
            sortArray.unshift({ field: "OrderBy", dir: "desc", compare: undefined });
        }
        grid.dataSource.sort(sortArray);
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        if (localKendoGrid != null) {
            var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
                gridColumn,
                sortArray,
                grid.dataSource.filter()
            );
            MobileLabelTemplates.setMobileLabelTemplatesGridOptionInMetaData(finalSubmission);
        }
    };

    // on grid column reorder save changes in meta data object
    const onColumnReorder = (event) => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        if (localKendoGrid != null) {
            let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
                switchedArray,
                sortArray,
                grid.dataSource.filter()
            );
            MobileLabelTemplates.setMobileLabelTemplatesGridOptionInMetaData(finalSubmission);
        }
    };

    // on grid column resize save changes in meta data object
    const onColumnResize = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        if (localKendoGrid != null) {
            let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
                grid.columns,
                sortArray,
                grid.dataSource.filter()
            );
            MobileLabelTemplates.setMobileLabelTemplatesGridOptionInMetaData(finalSubmission);
        }
    };

    // on click grid clear sort menu
    const onClickClearSorts = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        if (localKendoGrid != null) {
            var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
                grid.columns,
                [],
                grid.dataSource.filter()
            );
            MobileLabelTemplates.setMobileLabelTemplatesGridOptionInMetaData(finalSubmission);
        }
    };

    // on click grid reset default grid layout menu
    const onClickResetGrid = () => {
        var finalSubmission = {
            optionsData: {},
        };
        MobileLabelTemplates.setMobileLabelTemplatesGridOptionInMetaData(finalSubmission);
    };

    const eventClickExportGrid = () => {
        $(gridDomSelector).getKendoGrid().saveAsExcel();
    };

    const getGridFilter = useCallback(() => {
        let GridFilterData = props.sharedFilterObject;

        if (GridFilterData.cusId !== 0) {
            let selectedCustomer = GlobalService.getCustomerById(GridFilterData.cusId);
            if (selectedCustomer == null) {
                GridFilterData.cusId = 0;
            }
        }
        return GridFilterData;
    }, [props.sharedFilterObject]);
    // on new filter data clear selection of grid
    const clearSelectionOfGrid = () => {
        if (localKendoGrid != null) {
            localKendoGrid.clearSelections(() => {
                localKendoGrid.repaintSelections();
            });
        }
    };

    const refreshGrid = () => {
        setSelectedRowCount(0);
        clearSelectionOfGrid();
        callForGetTotalCount();
    };
    const setDefaultGridFirst = (data) => {
        return _.sortBy(data, ({ IsDefault }) => (IsDefault === true ? 0 : 1));
    };
    const onToggleColumnModal = (isOpen) => () => {
        setIsOpenColumnModal(isOpen);
    };

    const saveMetaData = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        if (localKendoGrid != null) {
            let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
                grid.columns,
                sort,
                grid.dataSource.filter()
            );
            MobileLabelTemplates.setMobileLabelTemplatesGridOptionInMetaData(finalSubmission);
        }
    };
    useEffect(() => {
        setGridConfig(MobileLabelTemplates.getMobileLabelTemplatesGridOptionInMetaData());
        setShowGrid(true);
        if (getGridFilter()) {
            callForGetTotalCount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sharedFilterObject]);

    useEffect(() => {
        if (recordsCount > 0) {
            callForGetNewData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordsCount]);

    // Cancel axios call if new filter applied
    const cancelLoadAllData = () => {
        if (firstFiftyAxiosSource) {
            firstFiftyAxiosSource.cancel();
            firstFiftyAxiosSource = null;
        }
        if (axiosSource) {
            axiosSource.cancel();
            axiosSource = null;
        }
        isApiCanceled = true;
        setIsGridDataLoading(false);
        setOverlayScreenShow(false);
        setGridData([]);
        setIsAllDataLoad(false);
    };

    const callForGetTotalCount = (clearData = true, clearGridSelection = false) => {
        setRecordsCount(0);
        setIsFilterRowCountLoading(true);
        setShowLoadingError(false);

        if (clearGridSelection) {
            setSelectedRowCount(0);
            setIsAllDataLoad(false);
        }

        var param_filterObj = Object.assign({}, getGridFilter());
        param_filterObj.cusId = param_filterObj.cusId === 0 ? null : param_filterObj.cusId;
        if (filterCountAxiosSource) {
            filterCountAxiosSource.cancel();
            filterCountAxiosSource = null;
        }
        // on filter update cancel axiosSource call if any running
        cancelLoadAllData();
        filterCountAxiosSource = axios.CancelToken.source();

        // Call for FilterRowCount.
        MobileLabelTemplates.setGridFilter(param_filterObj, filterCountAxiosSource.token)
            .then((response: any) => {
                setRecordsCount(response.data);
                if (clearData) {
                    setGridData([]);
                }
                filterCountAxiosSource = null;
            })
            .finally(() => {
                setIsFilterRowCountLoading(false);
            });
    };

    const resetGridAllMessage = () => {
        if (isAllDataLoad) {
            setIsNewColumnAdded(true);
        }
    };

    const callForGetNewData = () => {
        if (recordsCount > 0 && recordsCount <= gridPageSize) {
            loadAllRecords(false);
        } else {
            resetGridAllMessage();
        }
    };

    const sequence = (n) => {
        let seq = [];
        for (let i = 0; i < n; i++) {
            seq.push(i);
        }
        return seq;
    };

    const getAllResponseData = (data) => {
        if (!isApiCanceled) {
            let allData = [];
            data.forEach((obj) => {
                allData = [...obj, ...allData];
            });
            const customerOptions = GlobalService.getCustomerList(false);
            let orderBYDefault = 1;
            for (let i = 0; i < allData.length; i++) {
                // eslint-disable-next-line security/detect-object-injection
                const item = allData[i];
                let customerName = "";
                if (
                    item.AssignedCustomers &&
                    item.AssignedCustomers.length &&
                    item.AssignedCustomers.indexOf(-1) === -1
                ) {
                    for (let i = 0; i < item.AssignedCustomers.length; i++) {
                        // eslint-disable-next-line security/detect-object-injection
                        const customerId = item.AssignedCustomers[i];
                        let customerObj = _.find(customerOptions, { id: customerId });
                        customerName = customerName ? customerName + ", " + customerObj.name : customerObj.name;
                    }
                } else if (
                    item.AssignedCustomers &&
                    item.AssignedCustomers.length &&
                    item.AssignedCustomers.indexOf(-1) !== -1
                ) {
                    customerName = "All Customers";
                }
                if (item.IsDefault) {
                    item.OrderBy = orderBYDefault;
                    orderBYDefault = orderBYDefault + 1;
                } else {
                    item.OrderBy = 0;
                }
                // eslint-disable-next-line security/detect-object-injection
                allData[i].Customer = customerName;
            }

            let tmpData = setDefaultGridFirst(allData);
            setGridData(tmpData);
            setOverlayScreenShow(false);
            setIsGridDataLoading(false);
            setIsAllDataLoad(true);
            setIsNewColumnAdded(false);
        } else {
            setIsGridDataLoading(false);
        }
    };

    const loadAllRecords = (isLoaderPopupShow) => {
        isApiCanceled = false;
        if (isLoaderPopupShow) {
            setIsGridDataLoading(true);
            setGridData([]);
        }

        var param_filterObj = Object.assign({}, getGridFilter());
        param_filterObj.cusId = param_filterObj.cusId === 0 ? null : param_filterObj.cusId;

        axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/settings/zpllabel",
            headers: { "Access-Control-Allow-Origin": "*" },
            cancelToken: axiosSource.token,
        });
        const MAX_CONCURRENT_REQUESTS = 3;
        let totalRequest = Math.ceil(recordsCount / gridPageSize);
        setTotalRequest(totalRequest);
        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS, GlobalService);

        Promise.all(
            sequence(totalRequest).map((i) =>
                api.get("/label?pgnum=" + (i + 1) + "&pgsiz=" + gridPageSize, { params: param_filterObj })
            )
        )
            .then((responses) => {
                let inventoryData = responses.map((r) => r.data.ZplLabelsView);
                getAllResponseData(inventoryData);
            })
            .catch((error) => {
                setShowLoadingError(true);
                cancelLoadAllData();
            })
            .then(() => {
                if (manager) {
                    manager.detach();
                }
            });
    };

    const onClickLoadAllRecords = () => {
        var param_filterObj = Object.assign({}, getGridFilter());
        param_filterObj.cusId = param_filterObj.cusId === 0 ? null : param_filterObj.cusId;
        setOverlayScreenShow(true);

        if (firstFiftyAxiosSource) {
            firstFiftyAxiosSource.cancel();
            firstFiftyAxiosSource = null;
        }
        firstFiftyAxiosSource = axios.CancelToken.source();
        axios
            .get(
                "/WebUI/settings/zpllabel/label?sorts=[]&filters=[]&pgnum=1&pgsiz=50",
                { params: param_filterObj },
                { cancelToken: firstFiftyAxiosSource.token }
            )
            .then((response) => {
                firstFiftyAxiosSource = "";
                let tmpData = setDefaultGridFirst(response.data.ZplLabelsView);
                setGridData(tmpData);
                loadAllRecords(false);
            });
    };

    const deleteMULabelTemplate = (e: any) => {
        setDeleteLabelLoading(true);
        MobileLabelTemplates.deleteMobileLabelTemplatesData(selectedRow.ZplLabelId)
            .then((response: any) => {
                GlobalService.notification("success", window.translate("Label_Template_Deleted_Success"));
                refreshGrid();
            })
            .finally(() => {
                setDeleteLabelLoading(false);
            });
    };

    const openManageMULabelTemplate = (modeType) => (e: any) => {
        setSharedIsOpenManageMULabelTemplate(true);
        setMobileTemplatesType(modeType);
    };

    const closeManageMuLabelTemplateModal = () => {
        setSharedIsOpenManageMULabelTemplate(false);
    };

    return (
        <div className={`mobile-label-templates-grid`}>
            {showGrid && (
                <VirtualGridComponent
                    virtualGridDataLimit={gridPageSize}
                    isNewColumnAdded={isNewColumnAdded}
                    callForGetNewData={callForGetNewData}
                    isAllDataLoad={isAllDataLoad}
                    loadAllRecords={onClickLoadAllRecords}
                    loading={isFilterRowCountLoading || isGridDataLoading}
                    showLoadingError={showLoadingError}
                    shawCheckbox={false}
                    id={gridId}
                    showSortColumns={true}
                    showGridSummary={true}
                    showGridResult={true}
                    gridName={gridId}
                    windowId={props.windowId}
                    wmsSelector={props.wmsSelector + gridId}
                    gridKeySelection={"RowNumber"}
                    virtual={true}
                    totalResult={recordsCount}
                    staticGridData={gridData}
                    exportable={true}
                    isExportSelectedData={true}
                    exportExcelObj={{
                        fileName:
                            "AdjustInventoryExport-" +
                            kendo.toString(kendo.parseDate(new Date().toString()), "yyyyMMddhhmmss") +
                            ".xlsx",
                        allPages: true,
                        filterable: true,
                    }}
                    csvExportFileName={"AdjustInventoryExport"}
                    eventClickExportGrid={eventClickExportGrid}
                    allColumns={allColumnList}
                    defaultColumns={defaultColumns}
                    schema={schema}
                    onSelection={onselectGridRow}
                    clickEventOfContextMenu={clickEventOfContextMenu}
                    metaDataFieldsName={"AdjustInventory"}
                    onGridFilterApplied={onGridFilterApplied}
                    filterText={filterText}
                    fullFilterText={fullFilterText}
                    onDataBound={onGridDataBound}
                    onSort={onSort}
                    onColumnReorder={onColumnReorder}
                    onColumnResize={onColumnResize}
                    onClickClearSorts={onClickClearSorts}
                    onClickResetGrid={onClickResetGrid}
                    metaGridConfig={gridConfig}
                    eventClickColumns={onToggleColumnModal(true)}
                    menu={[
                        {
                            name: window.translate("Label_Create"),
                            wmsSelector: props.wmsSelector + "CreateAdjustment",
                            value: [],
                            clickFunc: openManageMULabelTemplate("CreateLabel"),
                            disabled: false,
                            icon: "fa-plus",
                            color: "green",
                            wmsid: "createLabel",
                            isContextMenu: true,
                            isButtonMenu: true,
                        },
                        {
                            name: window.translate("Label_Copy"),
                            wmsSelector: props.wmsSelector + "ReverseAdjustment",
                            value: [],
                            clickFunc: openManageMULabelTemplate("CopyLabel"),
                            disabled: selectedRowCount !== 1,
                            icon: "fa-files-o",
                            wmsid: "CopyLabel",
                            isContextMenu: true,
                            isButtonMenu: true,
                        },
                        {
                            name: window.translate("Label_Edit"),
                            wmsSelector: props.wmsSelector + "ReverseAdjustment",
                            value: [],
                            clickFunc: openManageMULabelTemplate("EditLabel"),
                            disabled: selectedRowCount !== 1,
                            icon: "fa-pencil",
                            wmsid: "EditLabel",
                            isContextMenu: true,
                            isButtonMenu: true,
                        },
                        {
                            name: window.translate("Label_Delete"),
                            wmsSelector: props.wmsSelector + "ReverseAdjustment",
                            value: [],
                            clickFunc: deleteMULabelTemplate,
                            disabled: selectedRowCount !== 1 || (selectedRow && selectedRow.IsDefault),
                            icon: "fa-trash",
                            wmsid: "DeleteLabel",
                            color: "red",
                            loading: deleteLabelLoading,
                            isContextMenu: true,
                            isButtonMenu: true,
                        },
                        {
                            name: "",
                            wmsSelector: props.wmsSelector + "RefreshGrid",
                            value: [],
                            clickFunc: refreshGrid,
                            disabled: false,
                            icon: "fa-sync-alt",
                            color: "blue",
                            isContextMenu: false,
                            isButtonMenu: true,
                            parentClassName: "mobile-label-templates-btn",
                        },
                    ]}
                />
            )}
            {isOpenColumnModal && (
                <AddEditColumnModel
                    id={"addEditColumnEventNotification"}
                    height={700}
                    windowId={props.windowId}
                    onCloseColumnModel={onToggleColumnModal(false)}
                    isOpenColumnModel={isOpenColumnModal}
                    allSortColumnList={allColumnList}
                    gridId={gridId}
                    updateGridData={() => {}}
                    saveChangesInMetaData={saveMetaData}
                />
            )}
            {sharedIsOpenManageMULabelTemplate && (
                <ManageMULabelTemplateModal
                    sharedIsOpenManageMULabelTemplate={sharedIsOpenManageMULabelTemplate}
                    wmsSelector={props.wmsSelector}
                    closeManageMuLabelTemplateModal={closeManageMuLabelTemplateModal}
                    refreshGrid={refreshGrid}
                    mobileTemplatesType={mobileTemplatesType}
                    sharedSelectedRow={selectedRow}
                    default={mobileTemplatesType === "EditLabel" && selectedRow.IsDefault}
                />
            )}
            {overlayScreenShow && (
                <LoadingModel
                    sharedOpenLoadingmodel={overlayScreenShow}
                    onCancelLoadAllData={cancelLoadAllData}
                    sharedTotalRequest={totalRequest}
                />
            )}
        </div>
    );
};
MobileLabelTemplatesGrid.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    windowId: PropTypes.string.isRequired,
    sharedFilterObject: PropTypes.any.isRequired,
};

MobileLabelTemplatesGrid.defaultProps = {
    wmsSelector: "",
    windowId: "",
    sharedFilterObject: {
        labelName: null,
        cusId: 0,
    },
};

export default React.memo(MobileLabelTemplatesGrid);
