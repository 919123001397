import React, { Component } from 'react'

// Components
import WmsFormGrid from '../components/WmsFormGrid/WmsFormGrid.jsx';

export class BarcodeDetailGrid extends Component {
    constructor(props) {
        super(props);
        
        this.translate = window.translate;
        this.state = {
            Selector: "BarcodeDetails",
        };
    }

    render() {
        return (
            <div>
                <WmsFormGrid
                    columns={[
                        {
                            field: "Name",
                            title: "Element",
                            lockable: false,
                            width: "100%"
                        },
                        {
                            field: "Start",
                            title: "Start",
                            lockable: false,
                            width: "100%"
                        },
                        {
                            field: "Length",
                            title: "Length",
                            lockable: false,
                            width: "100%"
                        }
                    ]}
                    schema={{
                        model: {
                            fields: {
                                Name: {
                                    type: "string",
                                    editable: false
                                },
                                Start: {
                                    type: "string",
                                    editable: false
                                },
                                Length: {
                                    type: "string",
                                    editable: false
                                }
                            }
                        }
                    }}
                    wmsSelector={this.state.Selector + "Grid"}
                    windowId={this.props.windowId}
                    showResetMenu={false}
                    isColumnsReorderable={true}
                    isColumnsFilterable={true}
                    isColumnsSortable={false}
                    isColumnMenuenable={true}
                    gridKeySelection={''}
                    id={this.props.gridId}
                    staticGridData={this.props.dataItem.BarcodeElements}
                    parentClassName=''
                />
            </div>
        )
    }
}
