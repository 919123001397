/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ChangeEvent } from "react";
import { default as WmsModal } from "../../../components/WmsModel/WmsModel.jsx";
import WmsButton from "../../../components/WmsButton/WmsButton.jsx";
import { default as Step1 } from "./create-hvp-batch-modal-step-1";
import { default as Step2 } from "./create-hvp-batch-modal-step-2";
import * as GlobalService from "../../../global_services.js";
import { CreateHVPContext } from "../context/createHVPContext";
import type { ModalConfig } from "../context/createHVPContext";
import { createShippingLabelsHighVolumeBatch, getAllPackages } from "../../small-parcel-hvp.service";
import "./create-hvp-batch-modal.scss";

interface CreateBatchHVPModalProps {
    open: boolean;
    onClose: () => void;
    windowId: string;
    onSuccess: () => void;
}

export interface DropdownOption {
    id: string;
    name: string;
    value: string;
    assignedCustomers?: number[];
}

interface UpdateOrderAndBatchesArrayProps {
    ordersAndBatchesArray: any[];
    order: any;
    displayOption: DisplayOptions;
}

type DisplayOptions = "batchesAndOrders" | "singleOrders" | "batchesOrders";

export interface FormValues {
    batchName: string;
    warehouse: string;
    customer: string;
    labelFormat: string;
    labelDate: string;
    returnLabel: boolean;
    labelTemplate: string;
    package: string | null;
    display: DisplayOptions;
    gridDataToDisplay: any[] | null;
    gridBatchesOnlyData: any[];
    gridOrdersOnlyData: any[];
    gridBatchesAndOrdersData: any[];
    selectedOrders: number[];
    preset: string;
}

export default function CreateHVPBatchModal({
    open = false,
    onClose,
    windowId,
    onSuccess,
}: CreateBatchHVPModalProps): JSX.Element {
    const selector = "CreateBatchHVPModal";
    const translate = (window as any).translate as any; //used to translate texts;
    const [currentStep, setCurrentStep] = useState(0);
    const [warehouseOptions, setWarehouseOptions] = useState<DropdownOption[]>([]);
    const [customerOptions, setCustomerOptions] = useState<DropdownOption[]>([]);
    const [packageOptions, setPackageOptions] = useState<DropdownOption[]>([]);
    const [nextButtonDisabledStatus, setNextButtonDisabledStatus] = useState(true);
    const [availablePackagesList, setAvailablePackagesList] = useState<DropdownOption[]>([]);
    const [formValues, setFormValues] = useState<FormValues>({
        batchName: "",
        warehouse: "0",
        customer: "0",
        labelFormat: "Pdf",
        labelDate: "",
        returnLabel: false,
        labelTemplate: "Default",
        package: null,
        display: "batchesOrders",
        gridDataToDisplay: null,
        gridBatchesAndOrdersData: [],
        gridBatchesOnlyData: [],
        gridOrdersOnlyData: [],
        selectedOrders: [],
        preset: "",
    });
    const [modalConfig, setModalConfig] = useState<ModalConfig>({
        width: 800,
        height: 800,
        modalTitle: "Create High Volume Batch",
        modalSubtitle: "Set Batch Details",
        windowId: windowId,
    });

    const getCurrentStep = () => {
        return steps[currentStep as number];
    };

    function filterSmallParcelShipDateFromGridData(data) {
        if (!data?.length) return data;
        return data.filter((item) => {
            return !item.SmallParcelShipDate;
        });
    }
    const handleFormValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormValues((previousValue) => ({ ...previousValue, [name]: value }));

        if (name === "customer") {
            handleCustomerChange(value);
        }

        if (name === "gridOrdersOnlyData") {
            const batchesAndOrdersArray = buildOrdersAndBatchesArray(value as unknown as any[]);
            const gridBatchesOnlyData = buildBatchesArray(
                (value as unknown as any[])?.filter((order) => order.BatchOrderId)
            );
            setFormValues((previousValue) => ({
                ...previousValue,
                gridDataToDisplay: filterSmallParcelShipDateFromGridData(gridBatchesOnlyData),
                gridBatchesOnlyData,
                gridBatchesAndOrdersData: batchesAndOrdersArray,
            }));
        }

        if (name === "display") {
            handleDisplayValueChange(value as DisplayOptions);
        }
    };

    const handlePresetFormValuesChange = (presetFormValues) => {
        setFormValues((previousValue) => {
            return {
                ...previousValue,
                batchName: presetFormValues.batchName,
                warehouse: presetFormValues.warehouse,
                customer: presetFormValues.customer,
                labelFormat: presetFormValues.labelFormat,
                labelDate: "",
                returnLabel: presetFormValues.returnLabel,
                labelTemplate: presetFormValues.labelTemplate,
                package: presetFormValues.package,
            };
        });
        handleCustomerChange(presetFormValues.customer, presetFormValues.package);
    };

    const handleCustomerChange = (customer, presetPackage = null) => {
        if (customer !== "0" && formValues.warehouse !== "0") {
            setPackageOptions(
                availablePackagesList?.filter(
                    (pkg) => pkg.assignedCustomers.includes(Number(customer)) || !pkg.assignedCustomers.length
                )
            );
            setFormValues({ ...formValues, package: presetPackage, customer: customer });
        }
        if (customer === "0") {
            setPackageOptions(availablePackagesList?.filter((pkg) => !pkg.assignedCustomers.length));
            setFormValues({ ...formValues, package: presetPackage, customer: customer });
        }
    };

    const buildOrdersAndBatchesArray = (batches: any[]) => {
        const ordersAndBatchesArray = [];
        batches.forEach((order) => {
            if (order?.BatchOrderId) {
                updateOrdersAndBatchesArray({
                    ordersAndBatchesArray: ordersAndBatchesArray,
                    order,
                    displayOption: "batchesAndOrders",
                });
            } else {
                ordersAndBatchesArray.push(order);
            }
        });
        return ordersAndBatchesArray;
    };

    const buildBatchesArray = (batches: any[]) => {
        const newBatchesArray = [];
        batches.forEach((batchOrder) => {
            updateOrdersAndBatchesArray({
                ordersAndBatchesArray: newBatchesArray,
                order: batchOrder,
                displayOption: "batchesOrders",
            });
        });

        return newBatchesArray;
    };

    const updateOrdersAndBatchesArray = ({
        ordersAndBatchesArray,
        order,
        displayOption,
    }: UpdateOrderAndBatchesArrayProps) => {
        const existingBatch = ordersAndBatchesArray.find((orderItem) => orderItem.BatchOrderId === order.BatchOrderId);
        if (existingBatch) {
            existingBatch.OrderCount += 1;
            existingBatch.TotPackages += order.TotPackages;
            existingBatch.Orders = [...existingBatch.Orders, order.OrderId];
        } else {
            ordersAndBatchesArray.push({ ...order, Orders: [order.OrderId], OrderCount: 1, displayOption });
        }
    };

    const handleDisplayValueChange = (newDisplayValue: DisplayOptions) => {
        if (newDisplayValue === "batchesAndOrders") {
            return setFormValues((previousFormValue) => ({
                ...previousFormValue,
                gridDataToDisplay: filterSmallParcelShipDateFromGridData(previousFormValue.gridBatchesAndOrdersData),
            }));
        }
        if (newDisplayValue === "batchesOrders") {
            return setFormValues((previousFormValue) => ({
                ...previousFormValue,
                gridDataToDisplay: filterSmallParcelShipDateFromGridData(previousFormValue.gridBatchesOnlyData),
            }));
        }
        if (newDisplayValue === "singleOrders") {
            return setFormValues((previousFormValue) => ({
                ...previousFormValue,
                gridDataToDisplay: filterSmallParcelShipDateFromGridData(previousFormValue.gridOrdersOnlyData),
            }));
        }
    };

    const steps: JSX.Element[] = [
        <Step1
            onInputChange={handleFormValueChange}
            formValues={formValues}
            selector={`${selector}-step1`}
            key={"step1"}
            warehouseOptions={warehouseOptions}
            customerOptions={customerOptions}
            packageOptions={packageOptions}
            onPresetFormValuesChange={handlePresetFormValuesChange}
        />,
        <Step2
            onInputChange={handleFormValueChange}
            formValues={formValues}
            selector={`${selector}-step2`}
            key={"step2"}
            warehouseOptions={warehouseOptions}
            customerOptions={customerOptions}
            packageOptions={packageOptions}
        />,
    ];

    const getWarehouseList = () => {
        const warehouses = GlobalService.getFacilityList(false);
        warehouses.unshift({
            "id": "0",
            "name": "Unspecified",
            "value": "",
        });
        setWarehouseOptions(warehouses);
    };

    const getCustomersList = () => {
        const customers = GlobalService.getCustomerList(false);
        customers.unshift({
            "id": "0",
            "name": "All Customers",
            "value": "",
        });
        setCustomerOptions(customers);
    };

    const updateNextButtonDisabledStatus = () => {
        const { batchName, warehouse } = formValues;
        let disabledStatus = true;
        if (currentStep === 0) {
            disabledStatus = batchName === "" || warehouse === "0";
        }
        if (currentStep === 1) {
            disabledStatus = formValues.selectedOrders.length === 0;
        }
        setNextButtonDisabledStatus(disabledStatus);
    };

    const setLoading = (active: boolean) => {
        kendo.ui.progress($("#createHVPBatchModal"), active);
    };

    const getPackages = async () => {
        setLoading(true);
        const { data } = await getAllPackages();
        const packagesList = (data || []).map((packageDef) => ({
            id: packageDef?.PackageDefId,
            name: packageDef?.Description,
            value: packageDef?.PackageDefId,
            assignedCustomers: packageDef?.AssignedCustomers,
        }));
        packagesList.unshift(
            {
                id: "-1",
                name: "No Package",
                value: null,
                assignedCustomers: [],
            },
            {
                id: "0",
                name: "Recalculate packages",
                value: 0,
                assignedCustomers: [],
            }
        );

        setAvailablePackagesList(packagesList);
        setPackageOptions((packagesList || []).filter((pkg) => !pkg?.assignedCustomers?.length));
        setLoading(false);
    };

    const createHvpBatch = async () => {
        try {
            await createShippingLabelsHighVolumeBatch({
                batchName: formValues.batchName,
                orderIds: formValues.selectedOrders,
                labelFormat: formValues.labelFormat,
                includeReturnLabels: formValues.returnLabel,
                packageDefId: parseInt(formValues.package),
                recalcPaks: parseInt(formValues.package) === 0,
                shipDate: formValues.labelDate || null,
            });
            onSuccess();
        } catch (e) {
            console.error(e);
        }
    };

    const handleNext = async () => {
        if (currentStep === 0) {
            return setCurrentStep(currentStep + 1);
        }
        if (currentStep === 1) {
            setLoading(true);
            await createHvpBatch();
            setLoading(false);
        }
    };

    useEffect(() => {
        getCustomersList();
        getWarehouseList();
        getPackages();
        updateNextButtonDisabledStatus();
    }, []);

    useEffect(() => {
        updateNextButtonDisabledStatus();
    }, [formValues, currentStep]);

    return (
        <WmsModal
            isOpen={open}
            id={"createHVPBatchModal"}
            title={`${modalConfig.modalTitle} - ${modalConfig.modalSubtitle}`}
            height={modalConfig.height}
            onCloseFunc={onClose}
            width={modalConfig.width}
            customClass="wms-model-new-ui-wrapper"
        >
            <div className={"model-content-wrapper"}>
                <CreateHVPContext.Provider value={{ setModalConfig, modalConfig }}>
                    {getCurrentStep()}
                </CreateHVPContext.Provider>
            </div>
            <div className="footer-btn-holder">
                <WmsButton
                    label={translate("Label_Cancel")}
                    wmsSelector={selector + "DiscardExit"}
                    onClickFunc={onClose}
                    buttonType="orange"
                    icon={"fa-ban"}
                />
                {currentStep > 0 && (
                    <WmsButton
                        label={"Back"}
                        wmsSelector={selector + "BackButton"}
                        onClickFunc={() => setCurrentStep(currentStep - 1)}
                        buttonType="orange"
                        icon={"fa-arrow-left"}
                    />
                )}
                <WmsButton
                    label={currentStep === 0 ? "Select Transactions" : "Create High Volume Batch"}
                    wmsSelector={selector + (currentStep === 0 ? "SelectTransactions" : "CreateHVP")}
                    onClickFunc={handleNext}
                    loading={false}
                    disabled={nextButtonDisabledStatus}
                />
            </div>
        </WmsModal>
    );
}
