import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import WmsButton from "../react/components/WmsButton/WmsButton";
import WmsCollapse from "../react/components/WmsCollapse/WmsCollapse";
import WmsDropdown from "../react/components/WmsDropdown/WmsDropdown";
import WmsInput from "../react/components/WmsInput/WmsInput";
import { default as WmsCustomDateTimePicker } from "../react/components/WmsCustomDateTimePicker/WmsCustomDateTimePicker.jsx";

import * as GlobalService from "../react/global_services";
import "./adjustInventory.scss";
interface Props {
    wmsSelector: string;
    sharedSearchOn: boolean;
    sharedFilterObject: any;
    windowId: any;
    onClearSearch: () => void;
    onFilterDataSet: (objFilter: any) => void;
}

declare var window: any;

const AdjustInventorySearch: React.FC<Props> = (props: Props): any => {
    const CustomLabels = GlobalService.getCustomLabels();
    const CustomerOptions = GlobalService.getCustomerList(true);
    const [facilityOPtions, setFacilityOptions] = useState(GlobalService.getFacilityList(true));
    const [filterObject, setFilterObject] = useState(props.sharedFilterObject);

    const handleFilterChange = (event: any) => {
        let name = event.target.name;
        let value = event.target.value;
        if (event.target.name === "CustomerID") {
            handleCustomerChange(parseInt(event.target.value));
        } else if (event.target.name === "FacilityID") {
            value = value === "0" ? null : value;
        }

        setFilterObject((previousState) => ({
            ...previousState,
            [name]: value,
        }));

        finalCallFilterChange2();
    };
    const handleCustomerChange = (CustomerID: any) => {
        var selectedCustomer = CustomerOptions.find((cus: any) => cus.id === CustomerID);
        if (selectedCustomer) {
            var customerFacilityIds = selectedCustomer.facilities;
            let allFacilities = GlobalService.getFacilityList(true);
            allFacilities = allFacilities.filter((f: any) => customerFacilityIds.includes(f.id) || f.id === 0);
            setFacilityOptions(allFacilities);
        } else {
            setFacilityOptions([]);
        }
    };

    let finalCallFilterChange = () => {
        props.onFilterDataSet(filterObject);
    };

    let finalCallFilterChange2 = GlobalService.useFCDebounce(finalCallFilterChange, 1000);
    useEffect(() => {
        setFilterObject(props.sharedFilterObject);
    }, [props.sharedFilterObject]);

    return (
        <div className={`adjust-customer-charges-search`}>
            <div className="btn-section">
                <WmsButton
                    label={window.translate("RoleManager_Label_Reset")}
                    buttonType="orange"
                    wmsSelector={props.wmsSelector + "resetFilter"}
                    onClickFunc={props.onClearSearch}
                    icon="fa-repeat"
                />
            </div>
            {props.sharedSearchOn && (
                <div className="title">
                    <b>{window.translate("Label_NOTE")}: </b>
                    <label>{window.translate("Label_Search_Applied_Filter")}</label>
                </div>
            )}

            <div className={"warehouse-form-boxes"}>
                <WmsCollapse
                    id={"Label_General"}
                    showDragIcon={false}
                    headerLabel={window.translate("Label_General")}
                    showCollapseText={false}
                >
                    <WmsDropdown
                        id="search_CustomerId"
                        name="CustomerID"
                        label={CustomLabels.Label_Customer}
                        wmsSelector={props.wmsSelector + "SelectCustomer"}
                        value={filterObject.CustomerID}
                        onChangeFunc={handleFilterChange}
                        options={CustomerOptions}
                        textField={"name"}
                        valueField={"id"}
                    />
                    <WmsDropdown
                        id="search_warehouse"
                        name="FacilityID"
                        label={CustomLabels.Label_Warehouse}
                        wmsSelector={props.wmsSelector + "SelectWarehouse"}
                        value={filterObject.FacilityID}
                        onChangeFunc={handleFilterChange}
                        options={facilityOPtions}
                        valueField="id"
                        textField="name"
                        parentClassName="form_group"
                    />
                </WmsCollapse>

                <WmsCollapse
                    id={"Charges_Detail"}
                    showDragIcon={false}
                    headerLabel={window.translate("Charges_Detail")}
                    showCollapseText={false}
                >
                    <WmsInput
                        id="WarehouseTransactionID"
                        inputName="WarehouseTransactionID"
                        wmsSelector={props.wmsSelector + "WarehouseTransactionID"}
                        value={filterObject.WarehouseTransactionID}
                        onChangeFunc={handleFilterChange}
                        extraWrapperClass="full-width-input"
                        label={window.translate("Orders_Lbl_Transaction_Id")}
                        valueType={"number"}
                    />
                    <WmsInput
                        id="ReferenceNum"
                        inputName="ReferenceNum"
                        wmsSelector={props.wmsSelector + "ReferenceNum"}
                        value={filterObject.ReferenceNum}
                        onChangeFunc={handleFilterChange}
                        extraWrapperClass="full-width-input"
                        label={window.translate("Orders_Lbl_Reference_Num")}
                    />
                </WmsCollapse>
                <WmsCollapse
                    id={"search_collapse2"}
                    showDragIcon={false}
                    headerLabel={window.translate("Label_Filter_Search_By_Date")}
                    showCollapseText={false}
                >
                    <WmsCustomDateTimePicker
                        id={"StartDate"}
                        name="StartDate"
                        wmsSelector={props.wmsSelector + "DatePickerStartDate"}
                        label={window.translate("Label_Begin")}
                        onChangeFunc={handleFilterChange}
                        value={filterObject.StartDate}
                        windowId={props.windowId}
                        isDateParseInCustomFormat={true}
                    />
                    <WmsCustomDateTimePicker
                        id={"EndDate"}
                        name="EndDate"
                        wmsSelector={props.wmsSelector + "DatePickerEndDate"}
                        label={window.translate("Label_End")}
                        onChangeFunc={handleFilterChange}
                        value={filterObject.EndDate}
                        windowId={props.windowId}
                        defaultTime={"23:59:59"}
                        isDateParseInCustomFormat={true}
                    />
                </WmsCollapse>
            </div>
        </div>
    );
};
AdjustInventorySearch.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    sharedSearchOn: PropTypes.bool.isRequired,
    sharedFilterObject: PropTypes.any.isRequired,
    windowId: PropTypes.any.isRequired,
    onClearSearch: PropTypes.func.isRequired,
    onFilterDataSet: PropTypes.func.isRequired,
};

AdjustInventorySearch.defaultProps = {
    wmsSelector: "",
    sharedSearchOn: false,
    sharedFilterObject: {},
    windowId: "",
    onClearSearch: (): void => {},
    onFilterDataSet: (filterObject: any): void => {},
};

export default React.memo(AdjustInventorySearch);
