import React, { Component } from 'react';


import './edit-shipping-info-model.scss';

// Component
import WmsModel from '../../components/WmsModel/WmsModel.jsx';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsCheckBox from '../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsTextArea from '../../components/WmsTextArea/WmsTextArea.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import WmsComboBox from '../../components/WmsComboBox/WmsComboBox.jsx';
import WmsRadio from '../../components/WmsRadio/WmsRadio.tsx'
import WmsDateTimePicker from "../../components/WmsDateTimePicker/WmsDateTimePicker";
import * as smallParcelService from './../smallParcel.service.jsx';
import * as GlobalService from '../../global_services';

export class EditShippingInfoModel extends Component {

    constructor(props) {
        super(props);


        this.$rootScope = window;
        this.translate = window.translate;
        this.formInputs = ["ScacCode", "AccountNumber", "AccountZip", "WarehouseInstructions", "CarrierInstructions", "TrackingNumber", "Carrier", "Service", "InsuranceAmount", "DryIceWeight", "TaxIdentifiers", "ShipDate"];
        this.checkboxInputs = ["IsCod", "IsInsured", "RequiresReturnReceipt", "SaturdayDelivery", "ResidentialDelivery"];
        this.selectInputs = ["BillingCode", "CapacityType", "DeliveryConfirmationType", "InsuranceType", "CODPayType", "IntlContentsType", "IntlNonDelivery", "DeliveredDutyPaid"];
        this.USPSCarriers = ["Buku", "Endicia", "PitneyBowesExp", "Stamps.com"];

        this.Rights = GlobalService.GetRights();
        this.isEnableProvider = this.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && this.Rights['Integrations.SmallParcelPartner'] == 'ParcelApi';

        this.state = {
            selector: "editShippingInfo",
            editOrderMultipleValues: this.translate('Label_Various_Values'),
            optionsDeliveryConfirmationType: [
                { description: 'None', value: 'none' },
                { description: 'Delivery', value: 'delivery' },
                { description: 'Signature', value: 'signature' },
                { description: 'Adult Signature', value: 'adult_signature' },
                { description: 'Direct Signature', value: 'direct_signature' }
            ],
            optionsInsuranceType: [
                { description: 'None', value: 0 },
                { description: 'Carrier', value: 106 },
                { description: 'Third-party', value: 107 }
            ],
            optionsCODType: [
                { description: 'None', value: 0 },
                { description: 'Any', value: 100 },
                { description: 'Cash', value: 103 },
                { description: 'Cash Equivalent', value: 'Cash Equivalent' }
            ],
            optionsInitalContentType: [
                { description: 'None', value: "" },
                { description: 'Documents', value: 'documents' },
                { description: 'Gift', value: 'gift' },
                { description: 'Merchandise', value: 'merchandise' },
                { description: 'Returned Goods', value: 'returned_goods' },
                { description: 'Sample', value: 'sample' }
            ],
            optionsInitalNoneDelivery: [
                { description: 'None', value: "" },
                { description: 'Treat as Abandoned', value: 'treat_as_abandoned' },
                { description: 'Return to Sender', value: 'return_to_sender' }
            ],
            optionsDutyBillType: [
                { text: "Not Specified", value: "100" },
                { text: "Bill Sender", value: "102" },
                { text: "Bill Recipient", value: "103" }
            ],
            optionsCarrier: [],
            defaultCarriersAndRoutingOptions: [],
            optionsBillingType: [],
            optionsService: [],
            oldServiceName: '',
            optionsCapacityTypes: [],
            shippingInfo: null,
            origShippingInfo: null,
            showScac: false,
            updateShippingInfoLoading: false,
            availableCarrierAccounts: [],
            accountNumberArr : [],
            nickNameArr : [],
            tempAccountNickname: "",
            custFacUPSFedExInfo: {},
            checkbox: {},
            concatInputs: this.formInputs.concat(this.checkboxInputs, this.selectInputs),
            optionTaxIdentifierType: [
                { description: 'None', value: "" },
                { description: 'EORI', value: "eori" },
                { description: 'VAT', value: 'vat' },
                { description: 'IOSS', value: 'ioss' },
                { description: 'VOEC', value: 'voec' },
                { description: 'TIN', value: 'tin' }
            ],
            taxIdentifierList: [],
            isChosenRateChange: false,
        }
    }

    componentDidMount() {
        kendo.ui.progress($('#EditShippingInfoModel'), true);
        smallParcelService.initialGetShippingInfoData(this.props.sharedSelectedOrderIds, this.props.sharedSelectedGridRecored).then((response) => {

            let tmpOptionsCarrier = response.carriersAndCapacityTypesResponse.Carriers.Carriers;
            let tempObject = [];

            tempObject = tmpOptionsCarrier.filter(function (el) {
                return el.Carrier === response.shippingInfoDataResponse.Carrier.Value;
            })[0];

            let shipmentServices = [];
            let optionsShipmentServices = [];
            if (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('ShipmentServices')) {
                optionsShipmentServices = tempObject['ShipmentServices'];
            } else {
                optionsShipmentServices = response.carriersAndCapacityTypesResponse.Carriers.DefaultShipmentServices;
            }
            shipmentServices = GlobalService.hideDeactivatedShipmentServices(response.shippingInfoDataResponse.Carrier.Value, optionsShipmentServices,response.shippingInfoDataResponse.Service.Value);

            let tmpShipEngineCarrierResponse = response.carriersAndCapacityTypesResponse.Carriers.Carriers;
            tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
                return obj.Carrier != "Buku" && obj.Carrier != "PitneyBowesExp";
            });
            if (!this.isEnableProvider) {
                tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
                    return obj.Carrier != "PitneyBowesStd";
                });
            }

            this.setState({
                optionsCarrier: tmpShipEngineCarrierResponse,
                availableCarrierAccounts: response.carrierAccountsResponse,
                custFacUPSFedExInfo: response.carriersAndCapacityTypesResponse.CustFacCarrierInfo,
                optionsCapacityTypes: response.carriersAndCapacityTypesResponse.CapacityTypes,
                defaultCarriersAndRoutingOptions: response.carriersAndCapacityTypesResponse.Carriers,
                optionsBillingType: (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('BillingCodes')) ? tempObject['BillingCodes'] : response.carriersAndCapacityTypesResponse.Carriers.DefaultBillingCodes,
                optionsService: shipmentServices.shipmentServices,
                oldServiceName: shipmentServices.valueCode ? shipmentServices.valueCode.Description : response.shippingInfoDataResponse.Service.Value,
            }, () => {
                this.translateFormData(response.shippingInfoDataResponse)
            });
        }).finally(() => {
            kendo.ui.progress($('#EditShippingInfoModel'), false);
        });
    }
    translateFormData = (param_formdata) => {

        let showScac = false;
        let concatInputs = this.state.concatInputs;
        let checkbox = {};
        let optionsBillingType = this.state.optionsBillingType;
        let optionsCapacityTypes = this.state.optionsCapacityTypes;
        let optionsInsuranceType = this.state.optionsInsuranceType;
        let optionsCODType = this.state.optionsCODType;
        let optionsInitalContentType = this.state.optionsInitalContentType;
        let optionsInitalNoneDelivery = this.state.optionsInitalNoneDelivery;
        let optionsDeliveryConfirmationType = this.state.optionsDeliveryConfirmationType;
        let optionTaxIdentifierType = this.state.optionTaxIdentifierType;
        let tmpTaxIdentifierList = [];
        let optionsDutyBillType = this.state.optionsDutyBillType;

        // SCAC state is dependent on carrier state so if it is Multiples
        if (param_formdata.Carrier.State === 1) {
            showScac = true;
        }
        for (var x = 0; x < concatInputs.length; x++) {

            if (this.formInputs.indexOf(concatInputs[x]) !== -1) { // these are form inputs
                // TaxIdentifiers
                if (concatInputs[x] == 'TaxIdentifiers') {
                    tmpTaxIdentifierList = [
                        {
                          taxableEntityType: "",
                          identifierType: "",
                          value: "",
                          issuingAuthority : ""
                        }
                    ];
                    if(param_formdata[concatInputs[x]].Value) {
                        tmpTaxIdentifierList = JSON.parse(param_formdata[concatInputs[x]].Value);
                    }
                    // If Multiple Order Selected Then Set Various Value
                    if(this.props.sharedSelectedOrderIds.length > 1 && tmpTaxIdentifierList.length > 0) {
                        optionTaxIdentifierType.push({
                            "description": this.state.editOrderMultipleValues,
                            "value": -1
                        });
                        if(!param_formdata[concatInputs[x]].Value && param_formdata[concatInputs[x]].State === 1) {
                            Object.keys(tmpTaxIdentifierList).map((key, index) => {
                                tmpTaxIdentifierList[key].identifierType = "-1";
                            });
                        }

                    }
                }

                if (param_formdata[concatInputs[x]].State === 1 && (
                    concatInputs[x] != 'WarehouseInstructions' &&
                    concatInputs[x] != 'CarrierInstructions'
                )) {
                    param_formdata[concatInputs[x]].Value = this.state.editOrderMultipleValues;

                } else if (param_formdata[concatInputs[x]].State === 0) {
                    param_formdata[concatInputs[x]].Value = "";

                } else if (this.props.sharedSelectedOrderIds.length > 1 && (
                    concatInputs[x] == 'WarehouseInstructions' ||
                    concatInputs[x] == 'CarrierInstructions')
                ) {
                    param_formdata[concatInputs[x]].Value = "";
                }
            }

            if (this.checkboxInputs.indexOf(concatInputs[x]) !== -1) { // these are checkbox inputs
                if (param_formdata[concatInputs[x]].State === 1) {
                    $("#" + concatInputs[x]).prop("indeterminate", true);
                    checkbox[concatInputs[x]] = true;

                } else {
                    $("#" + concatInputs[x]).prop("indeterminate", false);
                    checkbox[concatInputs[x]] = false;
                }
            }

            if (this.selectInputs.indexOf(concatInputs[x]) !== -1) { // these are select inputs
                if (param_formdata[concatInputs[x]].State === 1) {

                    if (concatInputs[x] == 'BillingCode') {

                        optionsBillingType.push({
                            "CodeTypeId": -1,
                            "Code": this.state.editOrderMultipleValues
                        });
                        param_formdata.BillingCode.Value = this.state.editOrderMultipleValues;

                    } else if (concatInputs[x] == 'CapacityType') {

                        optionsCapacityTypes.push({
                            "CapacityTypeId": -1,
                            "Description": this.state.editOrderMultipleValues
                        });
                        param_formdata.CapacityType.Value = this.state.editOrderMultipleValues;

                    } else if (concatInputs[x] == "InsuranceType") {

                        optionsInsuranceType.push({
                            description: this.state.editOrderMultipleValues,
                            value: -1
                        });
                        param_formdata.InsuranceType.Value = -1;

                    } else if (concatInputs[x] == "CODPayType") {

                        optionsCODType.push({
                            description: this.state.editOrderMultipleValues,
                            value: -1
                        });
                        param_formdata.CODPayType.Value = -1;

                    } else if (concatInputs[x] == "IntlContentsType") {

                        optionsInitalContentType.push({
                            description: this.state.editOrderMultipleValues,
                            value: -1
                        });
                        param_formdata.IntlContentsType.Value = -1;

                    } else if (concatInputs[x] == "IntlNonDelivery") {

                        optionsInitalNoneDelivery.push({
                            description: this.state.editOrderMultipleValues,
                            value: -1
                        });
                        param_formdata.IntlNonDelivery.Value = -1;

                    } else if (concatInputs[x] == "DeliveryConfirmationType") {

                        optionsDeliveryConfirmationType.push({
                            description: this.state.editOrderMultipleValues,
                            value: -1
                        });
                        param_formdata.DeliveryConfirmationType.Value = -1;

                    } else if (concatInputs[x] == "DeliveredDutyPaid") {

                        optionsDutyBillType.push({
                            text: this.state.editOrderMultipleValues,
                            value: -1
                        });

                        param_formdata.DeliveredDutyPaid.Value = -1;

                    }

                }
            }

        }

        var tmpOrigShippingInfo = _.cloneDeep(param_formdata);

        if (param_formdata.Carrier.Value == 'FedEx') {
            if(param_formdata.BillingCode.Value == 'BillThirdParty') {
                param_formdata.BillingCode.Value = 'third party';
            }
        } else {
            if(param_formdata.BillingCode.Value == 'third party') {
                param_formdata.BillingCode.Value = 'BillThirdParty';
            }
        }

        // Parse ShipDate
        param_formdata.ShipDate.Value = (param_formdata.ShipDate.Value) ? kendo.toString(kendo.parseDate(param_formdata.ShipDate.Value), "yyyy-MM-dd") : '';

        this.setState({
            showScac: showScac,
            checkbox: checkbox,
            shippingInfo: param_formdata,
            optionsBillingType: optionsBillingType,
            optionsCapacityTypes: optionsCapacityTypes,
            optionsDeliveryConfirmationType: optionsDeliveryConfirmationType,
            optionsInsuranceType: optionsInsuranceType,
            optionsCODType: optionsCODType,
            optionsInitalContentType: optionsInitalContentType,
            optionsInitalNoneDelivery: optionsInitalNoneDelivery,
            origShippingInfo: tmpOrigShippingInfo,
            taxIdentifierList: tmpTaxIdentifierList,
            optionTaxIdentifierType: optionTaxIdentifierType
        }, () => {
            // Checked Enabled Multi-Carrier Selection
            if(param_formdata.CarrierSelectionV2.Value) {
                this.setAccountNumberAndZip(param_formdata.Carrier.Value, param_formdata.BillingCode.Value, param_formdata);
                this.setUpAccountNumberAndNickName(param_formdata.Carrier.Value, "");
            }
        });

    }

    handleCarrierChange = (e) => {

        let showScac = false;
        let shippingInfo = this.state.shippingInfo;

        if (e.target.value == null || e.target.value == "" || e.target.value == " " || e.target.value == this.state.editOrderMultipleValues) {
            showScac = true;
        }

        shippingInfo.Service.Value = '';
        shippingInfo.BillingCode.Value = '';
        shippingInfo.AccountNumber.Value = '';
        shippingInfo.AccountZip.Value = '';
        shippingInfo.Carrier.Value = e.target.value;

        let tempObject = this.state.optionsCarrier.filter(function (el) {
            return el.Carrier === e.target.value;
        })[0];

        shippingInfo.ScacCode.Value = (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('ScacCode')) ? tempObject['ScacCode'] : '';

        this.setAccountNumberAndZip(e.target.value, null, shippingInfo);

        // Checked Enabled Multi-Carrier Selection
        if(shippingInfo && shippingInfo.CarrierSelectionV2.Value) {
            this.setUpAccountNumberAndNickName(e.target.value, "handleCarrierChange");
        }

        let shipmentServices = [];
        let optionsShipmentServices = [];
        if (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('ShipmentServices')) {
            optionsShipmentServices = tempObject['ShipmentServices'];
        } else {
            optionsShipmentServices = this.state.defaultCarriersAndRoutingOptions.DefaultShipmentServices;
        }
        shipmentServices = GlobalService.hideDeactivatedShipmentServices(e.target.value, optionsShipmentServices);

        this.setState({
            optionsService: shipmentServices.shipmentServices,
            oldServiceName: shipmentServices.valueCode?.Description,
            optionsBillingType: (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('BillingCodes')) ? tempObject['BillingCodes'] : this.state.defaultCarriersAndRoutingOptions.DefaultBillingCodes,
            showScac: showScac,
            isChosenRateChange: true
        });
    }

    onChangeFunc = (event) => {
        let tmpShippingInfo = this.state.shippingInfo;

        // When Multiple Carrier Account Nickname Value Change
        var that = this;
        if(event.target.name == 'AccountNickname') {
            if(event.target.value) {
                this.state.nickNameArr.filter(function (el, index) {
                    if(el.value === event.target.value) {
                        tmpShippingInfo.AccountNumber.Value = that.state.accountNumberArr[index].value;
                    }
                });
            } else {
                tmpShippingInfo.AccountNumber.Value = '';
            }
        }

        // When Multiple Carrier Account Number Value Change
        if(event.target.name == 'AccountNumber') {
            that.setState({ isChosenRateChange: true });
            if(event.target.value) {
                this.state.accountNumberArr.filter(function (el, index) {
                    if(el.value === event.target.value) {
                        if(tmpShippingInfo.AccountNickname) {
                            tmpShippingInfo.AccountNickname.Value = that.state.nickNameArr[index].value;
                        } else {
                            that.setState({ tempAccountNickname: that.state.nickNameArr[index].value });
                        }
                    }
                });
            } else {
                if(tmpShippingInfo.AccountNickname) {
                    tmpShippingInfo.AccountNickname.Value = '';
                } else {
                    that.setState({ tempAccountNickname: '' });
                }
            }
        }

        if (event.target.type == 'checkbox') {

            // let checkbox = this.state.checkbox;
            // checkbox[event.target.name] = false;
            // this.setState({ checkbox: checkbox });
            tmpShippingInfo[event.target.name].Value = event.target.checked;

        } else if (event.target.name == "BillingCode") {

            this.setAccountNumberAndZip(this.state.shippingInfo.Carrier.Value, event.target.value, tmpShippingInfo);
            tmpShippingInfo[event.target.name].Value = event.target.value;
            // Checked Enabled Multi-Carrier Selection
            if(this.state.shippingInfo && this.state.shippingInfo.CarrierSelectionV2.Value) {
                setTimeout(() => {
                    this.setUpAccountNumberAndNickName(this.state.shippingInfo.Carrier.Value, "");
                }, 100);
            }

        } else {
            if (typeof tmpShippingInfo[event.target.name] == 'undefined') {
                tmpShippingInfo[event.target.name] = {
                    HasChanged: true,
                    State: 0
                }
            }

            tmpShippingInfo[event.target.name].Value = event.target.value;
        }

        var oldServiceName = this.state.oldServiceName;
        if (event.target.name == "Service") {
            oldServiceName = event.target.value;
            this.setState({ isChosenRateChange: true });
            if (this.state.shippingInfo.Carrier.Value) {
                var carrier = this.state.shippingInfo.Carrier.Value;
                let shipmentServices = [];
                let optionsShipmentServices = [];
                let tempObject = this.state.optionsCarrier.filter(function (el) {
                    return el.Carrier === carrier;
                })[0];
                if (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('ShipmentServices')) {
                    optionsShipmentServices = tempObject['ShipmentServices'];
                } else {
                    optionsShipmentServices = this.state.defaultCarriersAndRoutingOptions.DefaultShipmentServices;
                }
                shipmentServices = GlobalService.hideDeactivatedShipmentServices(carrier, optionsShipmentServices, event.target.value);
                oldServiceName = shipmentServices.valueCode?.Description;
            }
        }

        if (
            event.target.name == 'InsuranceType' ||
            event.target.name == 'CODPayType' ||
            event.target.name == 'DeliveredDutyPaid'
        ) {
            tmpShippingInfo[event.target.name].Value = parseInt(event.target.value);
        }

        if (event.target.name == 'IsInsured' && event.target.checked == false) {
            tmpShippingInfo.InsuranceType.Value = 0;
            tmpShippingInfo.InsuranceType.HasChanged = true;
            tmpShippingInfo.InsuranceAmount.Value = 0;
            tmpShippingInfo.InsuranceAmount.HasChanged = true;
        }

        this.setState({
            shippingInfo: tmpShippingInfo,
            oldServiceName: oldServiceName
        });
    }

    setAccountNumberAndZip = (param_current_carrier, param_billing_code, param_carrierFormData) => {
        //var selectedCarrierDetails = this.props.sharedOptions.Carriers.Carriers[param_current_carrier_index];
        if (param_current_carrier != null) {
            var accountSelectedText = '';

            if (param_current_carrier == 'UPS' || param_current_carrier == 'FedEx') {
                var Account = '';
                var ShipPointZip = '';

                if (param_current_carrier == 'UPS') {
                    if (param_billing_code == 'Prepaid') {
                        if (this.state.custFacUPSFedExInfo.CustCarrierInfo != 0 && this.state.custFacUPSFedExInfo.CustCarrierInfo != 3) {
                            Account = this.state.custFacUPSFedExInfo.CustUPSAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.CustUPSAccountZip;
                            accountSelectedText = 'UPS Account of ' + this.state.custFacUPSFedExInfo.CustName;
                        } else {
                            Account = this.state.custFacUPSFedExInfo.FacUPSAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.FacZip;
                            accountSelectedText = 'UPS Account of ' + this.state.custFacUPSFedExInfo.FacName;
                        }

                    } else if (param_billing_code == 'BillThirdParty') {
                        if (this.state.custFacUPSFedExInfo.CustCarrierInfo != 0 && this.state.custFacUPSFedExInfo.CustCarrierInfo != 2) {
                            Account = this.state.custFacUPSFedExInfo.CustUPSAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.CustUPSAccountZip;
                            accountSelectedText = 'UPS Account of ' + this.state.custFacUPSFedExInfo.CustName;
                        } else {
                            Account = this.state.custFacUPSFedExInfo.FacUPSAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.FacZip;
                            accountSelectedText = 'UPS Account of ' + this.state.custFacUPSFedExInfo.FacName;
                        }
                    } else {
                        Account = '';
                        ShipPointZip = '';
                        accountSelectedText = '';
                    }

                } else if (param_current_carrier == 'FedEx') {
                    if (param_billing_code == 'sender') {
                        if (this.state.custFacUPSFedExInfo.CustCarrierInfo != 0 && this.state.custFacUPSFedExInfo.CustCarrierInfo != 3) {
                            Account = this.state.custFacUPSFedExInfo.CustFedexAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.CustUPSAccountZip;
                            accountSelectedText = 'Fedex Account of ' + this.state.custFacUPSFedExInfo.CustName;
                        } else {
                            Account = this.state.custFacUPSFedExInfo.FacFedexAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.FacZip;
                            accountSelectedText = 'Fedex Account of ' + this.state.custFacUPSFedExInfo.FacName;
                        }

                    } else if (param_billing_code == 'third party') {
                        if (this.state.custFacUPSFedExInfo.CustCarrierInfo != 0 && this.state.custFacUPSFedExInfo.CustCarrierInfo != 2) {
                            Account = this.state.custFacUPSFedExInfo.CustFedexAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.CustUPSAccountZip;
                            accountSelectedText = 'Fedex Account of ' + this.state.custFacUPSFedExInfo.CustName;
                        } else {
                            Account = this.state.custFacUPSFedExInfo.FacFedexAccount;
                            ShipPointZip = this.state.custFacUPSFedExInfo.FacZip;
                            accountSelectedText = 'Fedex Account of ' + this.state.custFacUPSFedExInfo.FacName;
                        }

                    } else {
                        Account = '';
                        ShipPointZip = '';
                        accountSelectedText = '';
                    }
                }

                // Checked Enabled Multi-Carrier Selection
                if(this.state.shippingInfo && this.state.shippingInfo.CarrierSelectionV2.Value) {
                    // Set Account Nickname
                    if(Account == '' || Account === null) {
                        if(this.state.origShippingInfo.AccountNumber.Value) {
                            Account = this.state.origShippingInfo.AccountNumber.Value;
                        } else {
                            if(param_carrierFormData["AccountNickname"]) {
                                param_carrierFormData["AccountNickname"].Value = '';
                            } else {
                                param_carrierFormData["AccountNickname"] = {};
                            }
                            this.setState({ tempAccountNickname: '' });
                        }
                    }
                }

                param_carrierFormData["AccountNumber"].Value = Account;
                param_carrierFormData["AccountZip"].Value = ShipPointZip;

                this.setState({
                    shippingInfo: param_carrierFormData,
                    accountSelectedText: accountSelectedText,
                });

            } else {
                param_carrierFormData["Carrier"].Value = param_current_carrier;

                this.setState({
                    shippingInfo: param_carrierFormData,
                });
            }
        }
    }
    setUpAccountNumberAndNickName = (param_current_carrier, calledFrom) => {
        let tmpAccountNumberArr = [];
        let tmpNickNameArr = [];
        let tmpShippingInfo = this.state.shippingInfo;
        let isThirdPartyBillingCode = false;
        let isSenderBillingCode = false;
        let isPrepaidBillingCode = false;
        // Checked is billing type is third party
        if(tmpShippingInfo.BillingCode.Value === 'third party' || tmpShippingInfo.BillingCode.Value === 'BillThirdParty') {
            isThirdPartyBillingCode = true;
        }
        // Checked is billing type is sender
        if(tmpShippingInfo.BillingCode.Value === 'sender') {
            isSenderBillingCode = true;
        }
        // Checked is billing type is prepaid
        if(tmpShippingInfo.BillingCode.Value === 'Prepaid') {
            isPrepaidBillingCode = true;
        }
        if (param_current_carrier && !isThirdPartyBillingCode && param_current_carrier.toLowerCase() === 'usps') {
            for(var i = 0; i < this.state.availableCarrierAccounts.length; i++) {
                const carrier = this.state.availableCarrierAccounts[i];
                if (this.USPSCarriers.some(element => element.toLowerCase() === carrier.Carrier.toLowerCase())) {
                    tmpAccountNumberArr.push({
                        text: carrier.AccountNumber,
                        value: carrier.AccountNumber,
                    });
                    tmpNickNameArr.push({
                        text: carrier.NickName,
                        value: carrier.NickName,
                    });
                }
            }
        } else if(param_current_carrier && !isThirdPartyBillingCode) {
            for(var i = 0; i < this.state.availableCarrierAccounts.length; i++) {
                const carrier = this.state.availableCarrierAccounts[i];
                if (param_current_carrier.toLowerCase() === carrier.Carrier.toLowerCase()) {
                    tmpAccountNumberArr.push({
                        text: carrier.AccountNumber,
                        value: carrier.AccountNumber,
                    });
                    tmpNickNameArr.push({
                        text: carrier.NickName,
                        value: carrier.NickName,
                    });
                }
            }
        }

        // Set Account Nickname Value
        if (tmpNickNameArr.length <= 1) {
            if (typeof tmpShippingInfo.AccountNickname == 'undefined') {
                tmpShippingInfo.AccountNickname = {};
            }
            if(tmpNickNameArr[0] != undefined && tmpNickNameArr[0].text) {
                if(!isSenderBillingCode && !isThirdPartyBillingCode && !isPrepaidBillingCode) {
                    if(this.state.origShippingInfo.AccountNumber.Value) {
                        tmpShippingInfo.AccountNickname.Value = '';
                    } else {
                        tmpShippingInfo.AccountNickname.Value = tmpNickNameArr[0].text;
                    }
                } else {
                    tmpShippingInfo.AccountNickname.Value = '';
                }
            } else {
                tmpShippingInfo.AccountNickname.Value = '';
            }
        } else {
            var that = this;
            if(tmpShippingInfo.AccountNickname) {
                tmpShippingInfo.AccountNickname.Value = '';
            } else {
                that.setState({ tempAccountNickname: '' });
            }
            tmpAccountNumberArr.map(function (el, index) {
                if(el.value === tmpShippingInfo.AccountNumber.Value) {
                    if(tmpShippingInfo.AccountNickname) {
                        tmpShippingInfo.AccountNickname.Value = tmpNickNameArr[index].value;
                    } else {
                        that.setState({ tempAccountNickname: tmpNickNameArr[index].value });
                    }
                }
            });
        }
        // Set Account Number Value
        if (tmpAccountNumberArr.length <= 1) {
            if(tmpAccountNumberArr[0] != undefined && tmpAccountNumberArr[0].text && !isSenderBillingCode && !isThirdPartyBillingCode && !isPrepaidBillingCode) {
                if(this.state.origShippingInfo.AccountNumber.Value) {
                    if(calledFrom) {
                        tmpShippingInfo.AccountNumber.Value = "";
                    } else {
                        tmpShippingInfo.AccountNumber.Value = this.state.origShippingInfo.AccountNumber.Value;
                    }
                } else {
                    tmpShippingInfo.AccountNumber.Value = tmpAccountNumberArr[0].text;
                }
            }
        } else {
            tmpAccountNumberArr.map(function (el, index) {
                if(el.value === tmpShippingInfo.AccountNumber.Value) {
                    tmpShippingInfo.AccountNumber.Value = el.value;
                }
            });
        }
        this.setState({
            accountNumberArr: tmpAccountNumberArr,
            nickNameArr: tmpNickNameArr,
            shippingInfo: tmpShippingInfo
         })

    }

    updateEditInfo = () => {
        this.setState({ updateShippingInfoLoading: true });
        let tmpShippingInfo = this.detectChangesFromOrig();
        // Check If Chosen Rate Change
        if(this.state.isChosenRateChange) {
            tmpShippingInfo.ChosenRate.HasChanged = true;
            tmpShippingInfo.ChosenRate.Value = "";
        }
        smallParcelService.shippingInfoUpdater(this.props.sharedSelectedOrderIds, tmpShippingInfo).then((response) => {

            GlobalService.notification('success', this.translate('Shipping_Info_Edit_Success'));
            this.props.onResetGridSelection();
            setTimeout(() => {
                this.props.onCloseShipInfoModel('callFromSave');
            }, 100);
        }).finally(() => {
            this.setState({
                updateShippingInfoLoading: false,
                isChosenRateChange: false
            });
        });
    }

    detectChangesFromOrig = () => {
        let concatInputs = this.state.concatInputs;
        let routingObj = this.state.shippingInfo;
        for (var x = 0; x < concatInputs.length; x++) {


            if (routingObj[concatInputs[x]].Value !== this.state.origShippingInfo[concatInputs[x]].Value) {
                routingObj[concatInputs[x]].HasChanged = true;
                if (routingObj[concatInputs[x]].Value == null) {
                    routingObj[concatInputs[x]].Value = "";
                }
                if (this.props.sharedSelectedOrderIds.length > 1 && (routingObj[concatInputs[x]].State == 1 || routingObj[concatInputs[x]].State == 2) &&
                    (
                        concatInputs[x] == 'WarehouseInstructions' ||
                        concatInputs[x] == 'CarrierInstructions'
                    ) && (routingObj[concatInputs[x]].Value != "")
                ) {
                    var formVal = routingObj[concatInputs[x]].Value;
                    routingObj[concatInputs[x]].Value = kendo.toString(kendo.parseDate(new Date()), 'yyyy/MM/dd HH:mm - ') + formVal;
                }
            }
            // Update Tax Identifier
            if(this.state.taxIdentifierList.length > 0) {
                if(concatInputs[x] == 'TaxIdentifiers') {
                    routingObj[concatInputs[x]].HasChanged = true;
                    routingObj[concatInputs[x]].Value = JSON.stringify(this.state.taxIdentifierList);
                }
            }
        }
        return routingObj;
    }
    isAllValid = () => {
        var tempArr = this.state.taxIdentifierList.filter(el => {
            return el.taxableEntityType != "" && (el.identifierType.trim() === "" || el.value.trim() === "" || el.issuingAuthority.trim() === "");
        });
        return tempArr.length > 0;
    }
    isAllValidForAdd = () => {
        var tempArr = this.state.taxIdentifierList.filter(el => {
            return el.identifierType.trim() === "" || el.value.trim() === "" || el.issuingAuthority.trim() === "";
        });
        console.log("tempArr.length ", tempArr.length );
        return tempArr.length > 0;
    }

    handleChangeTaxableEntityType = (index, param_taxable_entity_type) => {
        let taxIdentifier = this.state.taxIdentifierList[index];
        taxIdentifier.taxableEntityType = param_taxable_entity_type;
        var tmpTaxIdentifierList = Object.assign(this.state.taxIdentifierList, { [index]: taxIdentifier });
        this.setState({ taxIdentifierList: tmpTaxIdentifierList });
    }

    handleChangeIdentifierType = (index) => (e) => {
        let taxIdentifier = this.state.taxIdentifierList[index];
        taxIdentifier.identifierType = e.target.value;
        var tmpTaxIdentifierList = Object.assign(this.state.taxIdentifierList, { [index]: taxIdentifier });
        this.setState({ taxIdentifierList: tmpTaxIdentifierList });
    }

    handleChangeTaxNumberValue = (index, input) => (e) => {
        let taxIdentifier = this.state.taxIdentifierList[index];
        if(input == 'taxNumber') {
            taxIdentifier.value = e.target.value;
        } else {
            taxIdentifier.issuingAuthority = e.target.value;
        }
        var tmpTaxIdentifierList = Object.assign(this.state.taxIdentifierList, { [index]: taxIdentifier });
        this.setState({ taxIdentifierList: tmpTaxIdentifierList });
    }

    manageTaxIdentifier = (action = 'add', index = 0) => (e) => {
        console.log("Call add");
        let tempTaxIdentifierList = _.cloneDeep(this.state.taxIdentifierList);

        if (action === 'remove') {
            tempTaxIdentifierList.splice(index, 1);
        } else if (action === 'refresh') {
            tempTaxIdentifierList.splice(index, 1);
            tempTaxIdentifierList.push({
                taxableEntityType: "",
                identifierType: "",
                value: "",
                issuingAuthority : ""
            });
        } else {
            tempTaxIdentifierList.push({
                taxableEntityType: "",
                identifierType: "",
                value: "",
                issuingAuthority : ""
            });
        }

        this.setState({ taxIdentifierList: tempTaxIdentifierList });
    }


    render() {
        return (
            <WmsModel
                id='EditShippingInfoModel'
                class="edit-shippinh-info-model-wrapper"
                title={this.translate('Label_Edit_Shipping_Information')}
                width={800}
                height={800}
                onCloseFunc={this.props.onCloseShipInfoModel}
                isOpen={this.props.sharedIsShipInfoModelOpen}
                customClass='wms-model-new-ui-wrapper'
            >

                <div className="wms-area-content-darker model-content-wrapper wms-area-partial-border">
                    <WmsCollapse
                        id={'shippingInfoCarrier'}
                        headerLabel={this.translate('Label_Carrier_Information')}
                        showCollapseText={false} >

                        <div className="display-inline">
                            <WmsComboBox
                                id="shippingInfoCarrierCombo"
                                name="Carrier"
                                label={this.translate('Label_Carrier')}
                                wmsSelector={this.state.selector + 'ComboCarrier'}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.Carrier.Value}
                                onChangeFunc={this.handleCarrierChange}
                                options={this.state.optionsCarrier}
                                valueField="Carrier"
                                textField="DisplayName"
                                parentClassName="ship-info-carrier"
                                disabled={this.props.isSelectedOrderShipped}
                            />


                            <WmsComboBox
                                id="shippingInfoService"
                                name="Service"
                                placeholder={'Select or Enter a Service'}
                                label={this.translate('Label_Service')}
                                wmsSelector={this.state.selector + 'Service'}
                                value={
                                    this.state.shippingInfo == null ? '' :
                                    (this.state.shippingInfo.Service.Value != '' ? ( this.state.oldServiceName != '' ? this.state.oldServiceName: '') : '' )}
                                onChangeFunc={this.onChangeFunc}
                                noDataTemplate={false}
                                options={this.state.optionsService}
                                textField='Description'
                                valueField='Code'
                                parentClassName="ship-info-service"
                                disabled={this.props.isSelectedOrderShipped} />

                            <WmsInput
                                id="shippingInfoScac"
                                inputName='ScacCode'
                                wmsSelector={this.state.selector + 'SCAC'}
                                onChangeFunc={this.onChangeFunc}
                                label={this.translate('Label_SCAC')}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.ScacCode.Value}
                                disabled={this.state.showScac || this.props.isSelectedOrderShipped}
                                extraWrapperClass="ship-info-SCAC" />
                        </div>

                        <div className="display-inline">
                            <WmsDropdown
                                id="BillingCode"
                                name='BillingCode'
                                label={this.translate('Label_Billing')}
                                wmsSelector={this.state.selector + 'BillingType'}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.BillingCode.Value}
                                onChangeFunc={this.onChangeFunc}
                                options={this.state.optionsBillingType}
                                valueField="Code"
                                textField="Code"
                                blankFirstOption={true}
                                blankFirstOptionText={'Unspecified'}
                                extraWrapperClass="ship-info-billingType"
                                disabled={this.props.isSelectedOrderShipped} />

                            {/* Checked Multi-Carrier Selection Enabled */}
                            {this.state.shippingInfo && this.state.shippingInfo.CarrierSelectionV2.Value ? (
                                <>
                                    {this.state.nickNameArr.length > 1 && (this.state.shippingInfo && this.state.shippingInfo.BillingCode.Value !== 'BillThirdParty') ? (
                                        <WmsComboBox
                                            id="shippingInfoAccountNickname"
                                            name="AccountNickname"
                                            placeholder={'Select or Enter a Nickname'}
                                            label={this.translate('Label_Nickname')}
                                            wmsSelector={this.state.selector + 'AccountNickname'}
                                            value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.AccountNickname ? this.state.shippingInfo.AccountNickname.Value : this.state.tempAccountNickname}
                                            onChangeFunc={this.onChangeFunc}
                                            noDataTemplate={false}
                                            options={this.state.nickNameArr}
                                            textField='text'
                                            valueField='value'
                                            parentClassName="ship-info-account-nickname"
                                            disabled={this.props.isSelectedOrderShipped} />
                                    ): (
                                        <WmsInput
                                            id="shippingInfoAccountNickname"
                                            inputName='AccountNickname'
                                            wmsSelector={this.state.selector + 'AccountNickname'}
                                            onChangeFunc={this.onChangeFunc}
                                            label={this.translate('Label_Nickname')}
                                            value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.AccountNickname ? this.state.shippingInfo.AccountNickname.Value : this.state.tempAccountNickname}
                                            extraWrapperClass="ship-info-account-nickname"
                                            disabled={this.props.isSelectedOrderShipped} />
                                    )}

                                    {this.state.accountNumberArr.length > 1 && (this.state.shippingInfo && this.state.shippingInfo.BillingCode.Value !== 'BillThirdParty') ? (
                                        <WmsComboBox
                                            id="shippingInfoAccountNumber"
                                            name="AccountNumber"
                                            placeholder={'Select or Enter Account Number'}
                                            label={this.translate('Label_Account_Number')}
                                            wmsSelector={this.state.selector + 'AccountNumber'}
                                            value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.AccountNumber.Value}
                                            onChangeFunc={this.onChangeFunc}
                                            noDataTemplate={false}
                                            options={this.state.accountNumberArr}
                                            textField='text'
                                            valueField='value'
                                            parentClassName="ship-info-account-nickname"
                                            disabled={this.props.isSelectedOrderShipped} />
                                    ): (
                                        <WmsInput
                                            id="shippingInfoAccountNumber"
                                            inputName='AccountNumber'
                                            wmsSelector={this.state.selector + 'AccountNumber'}
                                            onChangeFunc={this.onChangeFunc}
                                            label={this.translate('Label_Account_Number')}
                                            value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.AccountNumber.Value}
                                            extraWrapperClass="ship-info-account-number"
                                            disabled={this.props.isSelectedOrderShipped} />
                                    )}
                                </>
                            ): (
                                <WmsInput
                                    id="shippingInfoAccountNumber"
                                    inputName='AccountNumber'
                                    wmsSelector={this.state.selector + 'AccountNumber'}
                                    onChangeFunc={this.onChangeFunc}
                                    label={this.translate('Label_Account_Number')}
                                    value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.AccountNumber.Value}
                                    extraWrapperClass="ship-info-account-number"
                                    disabled={this.props.isSelectedOrderShipped} />
                            )}

                            <WmsInput
                                id="shippingInfoPostalCode"
                                inputName='AccountZip'
                                wmsSelector={this.state.selector + 'PostalCode'}
                                onChangeFunc={this.onChangeFunc}
                                label={this.translate('Label_Account_Number_And_Postal_Code')}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.AccountZip.Value}
                                extraWrapperClass="ship-info-postal-code"
                                disabled={this.props.isSelectedOrderShipped} />

                            <WmsDropdown
                                id="shippingInfoCapacityType"
                                name='CapacityType'
                                label={this.translate('Label_Capacity_Type')}
                                wmsSelector={this.state.selector + 'CapacityType'}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.CapacityType.Value}
                                onChangeFunc={this.onChangeFunc}
                                options={this.state.optionsCapacityTypes}
                                valueField="Description"
                                textField="Description"
                                blankFirstOption={true}
                                blankFirstOptionText={'Unspecified'}
                                extraWrapperClass="ship-info-CapacityType"
                                disabled={this.props.isSelectedOrderShipped} />
                        </div>
                        <div className={(this.props.isSelectedOrderShipped ? 'disabled-tracking-number ' : '') + "display-inline"}>
                            <WmsInput
                                id="shippingInfoTrackingNumber"
                                inputName='TrackingNumber'
                                wmsSelector={this.state.selector + 'TrackingNumber'}
                                onChangeFunc={this.props.isSelectedOrderShipped ? () => {} : this.onChangeFunc}
                                label={this.translate('Label_Tracking_Number')}
                                showToolTip={this.props.isSelectedOrderShipped}
                                tooltipText={this.props.isSelectedOrderShipped ? this.translate('Label_Tracking_Number_Disable_Text') : ''}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.TrackingNumber.Value}
                                extraWrapperClass="ship-info-tracking-number" />

                            <WmsDateTimePicker
                                id={"ShippingInfoShipDate"}
                                name="ShipDate"
                                inputName='ShipDate'
                                label={this.translate("Label_Ship_Date")}
                                onChangeFunc={this.onChangeFunc}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.ShipDate.Value}
                                wmsSelector={this.state.selector + "ShipDate"}
                                showIconAfterInput={false}
                                onlyDate={true}
                                format={"yyyy-MM-dd"}
                                minDateTime={kendo.toString(kendo.parseDate(new Date()), "yyyy-MM-dd") }
                                extraWrapperClass="ship-info-ship-date"
                            />
                        </div>
                        <div className="display-inline">
                            <WmsTextArea
                                id="shippingInfoWarehouseInstructions"
                                name="WarehouseInstructions"
                                label={this.translate('Label_Shipping_Warehouse_Instructions')}
                                wmsSelector={this.state.selector + 'WarehouseInstructions'}
                                onChangeFunc={this.onChangeFunc}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.WarehouseInstructions.Value} />

                            <WmsTextArea
                                id="shippingInfoCarrierInstructions"
                                name="CarrierInstructions"
                                label={this.translate('Label_Carrier_Instructions')}
                                wmsSelector={this.state.selector + 'CarrierInstructions'}
                                onChangeFunc={this.onChangeFunc}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.CarrierInstructions.Value} />
                        </div>

                    </WmsCollapse>
                    <WmsCollapse
                        id={'shippingInfoShipment'}
                        headerLabel={this.translate('Label_Shipment_Options')}
                        showCollapseText={false} >

                        <div className="display-inline shipping-three-checkbox">

                            <WmsCheckBox
                                id={'shippinfoResidentialDelivery'}
                                name={'ResidentialDelivery'}
                                wmsSelector={this.state.selector + 'ResidentialDelivery'}
                                onChangeFunc={this.onChangeFunc}
                                label={this.translate('Label_Reidential_Delivery') + ' ' + (this.state.checkbox.ResidentialDelivery ? '(' + this.state.editOrderMultipleValues + ')' : '')}
                                checked={this.state.shippingInfo == null ? false : this.state.shippingInfo.ResidentialDelivery.Value == true}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.ResidentialDelivery.Value}
                                parentClassName="chk-residential-delivery"
                                disabled={this.props.isSelectedOrderShipped} />

                            <WmsCheckBox
                                id={'shippinfoReturnReceipt'}
                                name={'RequiresReturnReceipt'}
                                wmsSelector={this.state.selector + 'ReturnReceipt'}
                                onChangeFunc={this.onChangeFunc}
                                label={this.translate('Label_Require_Return_Receipt') + ' ' + (this.state.checkbox.RequiresReturnReceipt ? '(' + this.state.editOrderMultipleValues + ')' : '')}
                                checked={this.state.shippingInfo == null ? false : this.state.shippingInfo.RequiresReturnReceipt.Value == true}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.RequiresReturnReceipt.Value}
                                parentClassName="chk-return-receipt"
                                disabled={this.props.isSelectedOrderShipped} />

                            <WmsCheckBox
                                id={'shippinfoSaturdayDelivery'}
                                name={'SaturdayDelivery'}
                                wmsSelector={this.state.selector + 'SaturdayDelivery'}
                                onChangeFunc={this.onChangeFunc}
                                label={this.translate('Label_Saturday_Delivery') + ' ' + (this.state.checkbox.SaturdayDelivery ? '(' + this.state.editOrderMultipleValues + ')' : '')}
                                checked={this.state.shippingInfo == null ? false : this.state.shippingInfo.SaturdayDelivery.Value == true}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.SaturdayDelivery.Value}
                                parentClassName="chk-saturday-delivery"
                                disabled={this.props.isSelectedOrderShipped} />

                        </div>

                        <div className="display-inline">

                            <WmsCheckBox
                                id={'shippinfoInsurance'}
                                name={'IsInsured'}
                                wmsSelector={this.state.selector + 'Insurance'}
                                onChangeFunc={this.onChangeFunc}
                                label={this.translate('Label_Insurance') + ' ' + (this.state.checkbox.IsInsured ? '(' + this.state.editOrderMultipleValues + ')' : '')}
                                checked={this.state.shippingInfo == null ? false : this.state.shippingInfo.IsInsured.Value == true}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.IsInsured.Value}
                                parentClassName="chk-insurance chk-margin-top"
                                disabled={this.props.isSelectedOrderShipped} />

                            <WmsDropdown
                                id="ShippingInfoInsuranceType"
                                name='InsuranceType'
                                label={this.translate('Label_Insurance_Type')}
                                wmsSelector={this.state.selector + 'InsuranceType'}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.InsuranceType.Value}
                                onChangeFunc={this.onChangeFunc}
                                options={this.state.optionsInsuranceType}
                                textField='description'
                                valueField='value'
                                disabled={(this.state.shippingInfo != null && this.state.shippingInfo.IsInsured.Value == false) || this.props.isSelectedOrderShipped}
                                extraWrapperClass={"ship-info-insurance-type" + (this.state.shippingInfo != null && this.state.shippingInfo.IsInsured.Value == false ? ' disable-options' : '')} />

                            <WmsInput
                                id="shippingInfoInsuranceAmount"
                                inputName='InsuranceAmount'
                                wmsSelector={this.state.selector + 'InsuranceAmount'}
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                extraSignBeforeValue={'$'}
                                onChangeFunc={this.onChangeFunc}
                                label={this.translate('Label_Insurance_Amount')}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.InsuranceAmount.Value}
                                disabled={(this.state.shippingInfo != null && this.state.shippingInfo.IsInsured.Value == false) || this.props.isSelectedOrderShipped}
                                extraWrapperClass={"ship-info-insurance-amount" + (this.state.shippingInfo != null && this.state.shippingInfo.IsInsured.Value == false ? ' disable-options' : '')} />

                        </div>
                        {/* <div className="display-inline">

                            <WmsCheckBox
                                id={'shippinfoCOD'}
                                name={'IsCod'}
                                wmsSelector={this.state.selector + 'COD'}
                                onChangeFunc={this.onChangeFunc}
                                label={this.translate('Label_COD')  + ' ' + (this.state.checkbox.IsCod ? '(' + this.state.editOrderMultipleValues + ')' : '')}
                                checked={this.state.shippingInfo == null ? false :this.state.shippingInfo.IsCod.Value == true}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.IsCod.Value}
                                parentClassName="chk-right-margin chk-cod chk-margin-top"/>

                            <WmsDropdown
                                id="ShippingInfoCODType"
                                name='CODPayType'
                                label={this.translate('Label_COd_Pay_Type')}
                                wmsSelector={this.state.selector + 'CODType'}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.CODPayType.Value}
                                onChangeFunc={this.onChangeFunc}
                                options={this.state.optionsCODType}
                                textField='description'
                                valueField='value'
                                extraWrapperClass="ship-info-COD-type"/>
                        </div> */}
                        <div className="display-inline">

                            <WmsInput
                                id="shippingInfoDryIceWeight"
                                inputName='DryIceWeight'
                                wmsSelector={this.state.selector + 'DryIceWeight'}
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                onChangeFunc={this.onChangeFunc}
                                label={this.translate('Label_Dry_Ice_Weight')}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.DryIceWeight.Value}
                                extraWrapperClass="ship-info-dry-ice-weight"
                                disabled={this.props.isSelectedOrderShipped} />

                            <WmsDropdown
                                id="ShippingInfoInitContentType"
                                name='IntlContentsType'
                                label={this.translate('Label_Initial_Contents_Type')}
                                wmsSelector={this.state.selector + 'IntlContentsType'}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.IntlContentsType.Value}
                                onChangeFunc={this.onChangeFunc}
                                options={this.state.optionsInitalContentType}
                                textField='description'
                                valueField='value'
                                extraWrapperClass="ship-info-init-content-type"
                                disabled={this.props.isSelectedOrderShipped} />

                            <WmsDropdown
                                id="ShippingInfoInitmNonDelivery"
                                name='IntlNonDelivery'
                                label={this.translate('Label_Initial_None_Delivery')}
                                wmsSelector={this.state.selector + 'InitmNonDelivery'}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.IntlNonDelivery.Value}
                                onChangeFunc={this.onChangeFunc}
                                options={this.state.optionsInitalNoneDelivery}
                                textField='description'
                                valueField='value'
                                extraWrapperClass="ship-info-init-non-delivery"
                                disabled={this.props.isSelectedOrderShipped} />
                            {/* </div>
                        <div className="display-inline"> */}
                            <WmsDropdown
                                id="ShippingInfoDeliveryConfirmationType"
                                name='DeliveryConfirmationType'
                                label={this.translate('Label_Delivery_Confirmation_Type')}
                                wmsSelector={this.state.selector + 'DeliveryConfirmationType'}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.DeliveryConfirmationType.Value}
                                onChangeFunc={this.onChangeFunc}
                                options={this.state.optionsDeliveryConfirmationType}
                                textField='description'
                                valueField='value'
                                extraWrapperClass="ship-info-delivery-confirmation-type"
                                disabled={this.props.isSelectedOrderShipped}/>

                            <WmsDropdown
                                id="ShippingInfoIntlDutyBillType"
                                name="DeliveredDutyPaid"
                                label={this.translate("Window_Title_Label_SmartParcel_Duty_Bill")}
                                wmsSelector={this.props.selector + "DutyBillType"}
                                value={this.state.shippingInfo == null ? '' : this.state.shippingInfo.DeliveredDutyPaid.Value}
                                onChangeFunc={this.onChangeFunc}
                                options={this.state.optionsDutyBillType}
                                valueField="value"
                                textField="text"
                                parentClassName="form_group"
                                extraWrapperClass={'international-bill-type-wrapper'}
                                disabled={this.props.isSelectedOrderShipped}
                            />
                        </div>
                    </WmsCollapse>
                    <WmsCollapse
                        id={'internationalShippingTaxInformation'}
                        headerLabel={this.translate('Label_International_Shipping_Tax_Info')}
                        showCollapseText={false} >

                        {Object.keys(this.state.taxIdentifierList).map((key, index) => (
                            <div className="service-rate-options" key={"taxIdentifier_" + index + "_key"}>
                                <div className="wms-radio-wrapper">
                                    <span className="wms-label">
                                        {this.translate("Label_Taxable_Entity_Type")}
                                    </span>

                                    <WmsRadio
                                        id={"taxableEntityType-shipper" + index}
                                        label={this.translate("Label_Taxable_Entity_Type_Shipper")}
                                        name={"taxableEntityType"+index}
                                        wmsSelector={this.state.selector + 'TaxableEntityType'}
                                        onChangeFunc={() => this.handleChangeTaxableEntityType(index, 'shipper')}
                                        checked={this.state.taxIdentifierList[index].taxableEntityType == 'shipper'}
                                        value={'shipper'}
                                        disabled={this.props.isSelectedOrderShipped}
                                    />

                                    <WmsRadio
                                        id={"taxableEntityType-recipient" + index}
                                        label={this.translate("Label_Taxable_Entity_Type_Recipient")}
                                        name={"taxableEntityType"+index}
                                        wmsSelector={this.state.selector + 'TaxableEntityType'}
                                        onChangeFunc={() => this.handleChangeTaxableEntityType(index, 'recipient')}
                                        checked={this.state.taxIdentifierList[index].taxableEntityType == 'recipient'}
                                        value={'recipient'}
                                        disabled={this.props.isSelectedOrderShipped}
                                    />
                                </div>

                                <WmsDropdown
                                    id="IdentifierType"
                                    name='IdentifierType'
                                    extraWrapperClass='identifier-type-wrapper'
                                    label={this.translate('Label_Identifier_Type')}
                                    wmsSelector={this.state.selector + 'IdentifierType'}
                                    value={this.state.taxIdentifierList[index].identifierType !== '' ? this.state.taxIdentifierList[index].identifierType : ''}
                                    onChangeFunc={this.handleChangeIdentifierType(index)}
                                    options={this.state.optionTaxIdentifierType}
                                    textField='description'
                                    valueField='value'
                                    disabled={this.props.isSelectedOrderShipped}
                                />

                                <WmsInput
                                    id="taxNumberValue"
                                    extraWrapperClass='tax-number-value-wrapper'
                                    inputName='value'
                                    wmsSelector={this.state.selector + 'value'}
                                    label={this.translate('Label_Tax_Number')}
                                    value={this.state.taxIdentifierList[index].value !== '' ? this.state.taxIdentifierList[index].value : ''}
                                    onChangeFunc={this.handleChangeTaxNumberValue(index, 'taxNumber')}
                                    disabled={this.props.isSelectedOrderShipped}
                                />

                                <WmsInput
                                    id="issuingAuthority"
                                    inputName='issuingAuthority'
                                    extraWrapperClass='issuing-authority-wrapper'
                                    wmsSelector={this.state.selector + 'issuingAuthority'}
                                    label={this.translate('Label_Issuing_Authority')}
                                    value={this.state.taxIdentifierList[index].issuingAuthority !== '' ? this.state.taxIdentifierList[index].issuingAuthority : ''}
                                    maxLength={2}
                                    regularExp={/^[a-zA-Z]+$/}
                                    onChangeFunc={this.handleChangeTaxNumberValue(index, 'issuingAuthority')}
                                    disabled={this.props.isSelectedOrderShipped}
                                />

                                {this.state.taxIdentifierList.length > 1 && (
                                    <div>
                                        <span className="wms-label"></span>
                                        <WmsButton
                                            label={''}
                                            buttonType='red'
                                            id='RemoveTaxIdentifiersBtn'
                                            parentClassName='remove-tax-identifiers-btn-wrapper'
                                            wmsSelector={this.props.selector + 'removeTaxIdentifiers'}
                                            onClickFunc={this.manageTaxIdentifier('remove', index)}
                                            disabled={this.state.taxIdentifierList.length == 1 || this.props.isSelectedOrderShipped}
                                            icon='fa-minus'
                                        />
                                    </div>
                                )}
                                {this.state.taxIdentifierList.length == 1 && (
                                    <div>
                                        <span className="wms-label"></span>
                                        <WmsButton
                                            label={''}
                                            buttonType='red'
                                            id='RefreshTaxIdentifiersBtn'
                                            parentClassName='remove-tax-identifiers-btn-wrapper'
                                            wmsSelector={this.props.selector + 'refreshTaxIdentifiers'}
                                            onClickFunc={this.manageTaxIdentifier('refresh', index)}
                                            disabled={this.state.taxIdentifierList.length != 1 || this.props.isSelectedOrderShipped}
                                            icon='fa-refresh'
                                        />
                                    </div>
                                )}
                            </div>
                        ))}

                        <WmsButton
                            label={''}
                            wmsSelector={this.props.selector + 'addTaxIdentifier'}
                            onClickFunc={this.manageTaxIdentifier()}
                            icon='fa-plus'
                            disabled={this.props.isSelectedOrderShipped || this.isAllValidForAdd()}
                        />
                    </WmsCollapse>
                </div>

                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Cancel')}
                        wmsSelector={this.state.selector + "Cancel"}
                        onClickFunc={this.props.onCloseShipInfoModel}
                        disabled={false}
                        loading={false}
                        buttonType='orange'
                        icon={'fa-ban'}
                    />
                    <WmsButton
                        id={'PackAndSshipTransaction' + this.props.windowId}
                        label={this.translate('Label_Save')}
                        wmsSelector={this.state.selector + "Save"}
                        onClickFunc={this.updateEditInfo}
                        disabled={this.state.updateShippingInfoLoading || this.isAllValid()}
                        loading={this.state.updateShippingInfoLoading}
                        icon="fa-floppy-o"
                    />
                </div>
            </WmsModel >
        );
    }
}