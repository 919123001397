
import React from 'react';
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsRadio from './../components/WmsRadio/WmsRadio.tsx';
import WmsFormGrid from './../components/WmsFormGrid/WmsFormGrid.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';

import * as GlobalService from './../global_services';
import * as GridConfig from './grid_configuration.jsx';

import style from './import-orders.scss';

export class ImportOrdersGrid extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            columns: GridConfig.getGridDemoColumns().columns,
            schema: GridConfig.getGridDemoSchema().schema,
            onErrorRadio: 0,
            whatToImportRadio: 0,
            useDefaultPreferences: false,
            showPreferencesSection: false,
            selector:'ImportOrder'
        }

    }

    componentDidMount() {
        this.setDefaultsPreferences(this.props.sharedFetchCustomerInfo);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.sharedFetchCustomerInfo != nextProps.sharedFetchCustomerInfo) {
            this.setDefaultsPreferences(nextProps.sharedFetchCustomerInfo);
        }
    }

    // handleChkChange = (event) => {
    //     this.setState({ [event.target.name]: this.state[event.target.name] == 0 ? 1 : 0 });
    // }

    setDefaultsPreferences = (param_customerInfo) => {
        if (param_customerInfo == null) { return }
        let preferences = param_customerInfo.Preferences;
        //Setting the defaults for the radios and the default preferences at once
        this.setState({
            onErrorRadio: preferences.OnError.toString(),
            whatToImportRadio: preferences.Criteria.toString(),
            useDefaultPreferences: false,
            showPreferencesSection: preferences.Workflow === 1 ? true : false
        });
    }

    importOrder = (param_btnID) => {

        // showing moving icon animation
        let targetElement = $("#" + param_btnID);
        this.$rootScope.animationMoveIcon(targetElement);

        var data = {
            customer_id: this.props.sharedSelectedCustomerId,
            preferences: this.props.sharedFetchCustomerInfo.Preferences,
            ShowPreferencesSection: this.state.showPreferencesSection,
            WhatToImportRadio: this.state.whatToImportRadio,
            OnErrorRadio: this.state.onErrorRadio,
            uploadFile: this.props.sharedFileUploadData.fileread,
            uploadFileType: this.props.sharedFileUploadData.fileType,
            SelectedFile: this.props.sharedFileUploadData.fileName,
            facilityId: this.props.sharedSelectedFacilityId
        }
        this.$rootScope.taskStarted();
        // this.$rootScope.$broadcast("importParseOrder", data);
        /** Code for react progress component*/
        data.type = "importOrder";
        var event = new CustomEvent("showProgressModal", {
            detail: data
        });
        document.dispatchEvent(event);
        // this.$rootScope.$broadcast("importParseOrder", data);
        setTimeout(() => { this.clickExit(); }, 200);
    }

    clickExit = (e) => {
        GlobalService.closeWindow(this.props.windowId);
    };

    render() {

        return (
            <div id="import-order-grid" >
                <div className="button-wrapper">
                <WmsButton
                    label={this.translate("Label_Import")}
                    id="firstImportBtn"
                    wmsSelector={this.state.selector + "ImportButton"}
                    onClickFunc={() => this.importOrder('firstImportBtn')}
                    icon=" fa-file-text-o" />

                <WmsButton
                    label={this.translate("Label_Select_New_File")}
                    wmsSelector={this.state.selector + "SelectNewFile"}
                    onClickFunc={this.props.onSelectNewFile}
                    icon="fa-check" />

                <WmsButton
                    label={this.translate("Label_Exit")}
                    wmsSelector={this.state.selector + "exitBtn"}
                    onClickFunc={this.clickExit}
                    icon="fa-sign-out-alt"
                    buttonType="orange" />
                </div>
                {/* <div className="success-msg">Validation Successful - Review and hit import to continue</div>

                {this.state.showPreferencesSection &&

                    <WmsContainer title={this.translate('Label_Import_Preferences')}>
                        <div name="radios" className="radio-btnlist">

                            <div className="width35">
                                <div className="radiobtn">
                                    <label className="wms-input-label">{this.translate('Label_On_Error')}</label>
                                </div>
                                <WmsRadio
                                    label={this.translate("Label_Abort_Import")}
                                    name="onErrorRadio"
                                    value={''}
                                    wmsSelector={this.state.selector + "AbortImport"}
                                    onChangeFunc={this.handleChkChange}
                                    checked={this.state.onErrorRadio == 0} />

                                <WmsRadio
                                    label={this.translate("Label_Continue_Import")}
                                    name="onErrorRadio"
                                    value={''}
                                    wmsSelector={this.state.selector + "ContinueImport"}
                                    onChangeFunc={this.handleChkChange}
                                    checked={this.state.onErrorRadio == 1} />
                            </div>

                            <div className="width35">
                                <div className="radiobtn">
                                    <label className="wms-input-label">{this.translate('Label_What_To_Import')}</label>
                                </div>
                                <WmsRadio
                                    label={this.translate("Label_Import_All")}
                                    name="whatToImportRadio"
                                    value={''}
                                    wmsSelector={this.state.selector + "ImportAll"}
                                    onChangeFunc={this.handleChkChange}
                                    checked={this.state.whatToImportRadio == 0} />

                                <WmsRadio
                                    label={this.translate("Label_Import_Only")}
                                    name="whatToImportRadio"
                                    value={''}
                                    wmsSelector={this.state.selector + "ImportOnly"}
                                    onChangeFunc={this.handleChkChange}
                                    checked={this.state.whatToImportRadio == 1} />
                            </div>

                        </div>

                        {this.state.useDefaultPreferences &&
                            <div>

                                {this.props.sharedFetchCustomerInfo.preferences.OnError === 0 &&
                                    <div className="label-list" >
                                        <label className="wms-input-label">{this.translate('Label_Onerror')} </label>
                                        <label className="wms-info-text">{this.translate('Label_Abort_Import')}</label>
                                    </div>
                                }

                                {this.props.sharedFetchCustomerInfo.preferences.OnError === 1 &&
                                    <div className="label-list" >
                                        <label className="wms-input-label">{this.translate('Label_Onerror')} </label>
                                        <label className="wms-info-text">{this.translate('Label_Continue_Import')}</label>
                                    </div>
                                }

                                {this.props.sharedFetchCustomerInfo.preferences.whatToImport === 0 &&
                                    <div className="label-list" >
                                        <label className="wms-input-label">{this.translate('Label_What_To_Import')}: </label>
                                        <label className="wms-info-text">{this.translate('Label_Import_All')}</label>
                                    </div>
                                }

                                {this.props.sharedFetchCustomerInfo.preferences.whatToImport === 1 &&
                                    <div className="label-list" >
                                        <label className="wms-input-label">{this.translate('Label_What_To_Import')}: </label>
                                        <label className="wms-info-text">{this.translate('Label_Import_Only')}</label>
                                    </div>
                                }
                            </div>
                        }
                    </WmsContainer>
                } */}

                <WmsFormGrid
                    id={'GridManageOrderImport'}
                    wmsSelector={'GridManageOrderImport'}
                    columns={this.state.columns}
                    staticGridData={this.props.sharedGridData}
                    windowId={this.props.windowId}
                    schema={this.state.schema}
                    showResult={true}
                    isColumnMenuenable={true}
                    isColumnsFilterable={true}
                    isColumnsResizable={true}
                    isColumnsReorderable={true}
                    isColumnsSortable={true}
                    parentClassName={'showHeader'}
                    isShowGridSummary={true}
                    sharedGridSummary={this.props.sharedGridSummary} />
            </div>
        )

    }
}