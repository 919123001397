import React from 'react';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsComboBox from '../../components/WmsComboBox/WmsComboBox.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsCustomDateTimePickerProto from '../../components/WmsCustomDateTimePickerProto/WmsCustomDateTimePickerProto.jsx';
import WmsButtonDropdown from '../../components/WmsButtonDropdown/WmsButtonDropdown.jsx';
import WmsTab from '../../components/WmsTab/WmsTab.jsx';
import { default as WmsMenuItem } from '../../components/WmsMenuItem/WmsMenuItem.jsx';
import * as DynamicMenu from  '../../orders/findOrders/grid_dynamic_menu.virtual.jsx';

import { ObjectWms } from './../../../../assets/ts/ObjectWms.ts';
import { StringWms } from './../../../../assets/ts/StringWms.ts';
import { ArrayWms } from './../../../../assets/ts/ArrayWms.ts';

import { AttachDocuments } from '../../components/AttachDocuments/AttachDocuments.jsx';
import WmsConfirmModel from '../../components/WmsConfirmModel/WmsConfirmModel.jsx';

import { SendAsnModel } from '../../orders/findOrders/model/send-asn-model.component.jsx';
import { PickTicketModel } from '../../orders/findOrders/model/pick-ticket-model.component.jsx';
import { BillOfLadingModel } from '../../orders/findOrders/model/bill-of-lading.component.jsx';
import { ProtoMassAddModel } from '../../orders/findOrders/model/proto-mass-add/proto-mass-add.component.jsx';
import { AddEditLineItemModal } from '../../orders/findOrders/model/add-edit-line-item/add-edit-line-item.component.jsx';
import { PackingSlipsModel } from '../../orders/findOrders/model/packing-slips-model.component.jsx';
import { ShippingLabel } from '../../orders/findOrders/model/shipping-label-model.component.jsx';
import { ManageOrdersContactDetails } from './manage-orders-contact-details.component.jsx';
import { ManageOrdersLineItem } from './manage-orders-line-item.component.jsx';
import { ManageOrdersCarrierRoutingInfo } from './manage-orders-carrier-routing-info.component.jsx';
import { ManageOrdersCustomOrderInfo } from './manage-orders-custom-order-info.component.jsx';
import { ManageOrdersCalculateCharges } from './manage-orders-calculate-charges.component.jsx';
import { ShipAndCloseModal } from '../../orders/manageOrders/model/ship-and-close-modal.component.jsx';
import { ManageAllocationModal } from '../../orders/manageOrders/model/manage-allocation-modal.component.jsx';
import { CustomOrderInfoModal } from  '../../orders/manageOrders/model/custom-order-info-modal.component.jsx';
import { CreateManualChargeModel } from '../../orders/findOrders/model/create-manual-charge-model.component.jsx';
import { AdvancedContactLookupModal } from '../../orders/manageOrders/model/advanced-contact-lookup-modal.component.jsx';
import { EditBolOptionModel } from '../../orders/findOrders/model/edit-bol-option-model.component.jsx';
import { reportIframe } from '../../reportIframe/report-iframe.component.jsx';

import './manage-orders.virtual.scss';

//services

import * as manageOrderServices from './manage-orders.services.jsx';
import * as findOrdersService from '../../orders/findOrders/findOrders.services.jsx';

import * as GlobalService from '../../global_services';
import * as manageOrderProtoMetaDataService from './manage-order-proto-meta-data.service';
import { kendo } from "../../global_variable_service.jsx";
import _ from 'lodash';

var isCustomerUser = false;
export class ManageOrdersFormProto extends React.Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.$rootScope = window;

        this.Selector = "OrderProtoType";
        this.scrollAreaWrapperId = "ManageOrdersScrollBox";
        this.windowId = props.windowId;
        this.scrollDomSelector = props.windowId ? '.' + props.windowId + '#' + this.scrollAreaWrapperId : '#' + this.scrollAreaWrapperId;

        this.Rights = GlobalService.GetRights();

        this.isEnableProvider = this.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && this.Rights['Integrations.SmallParcelPartner'] == 'ParcelApi';

        this.tabHeaderRef = React.createRef();
        this.contactDetailsRef = React.createRef();
        this.lineItemRef = React.createRef();
        this.carrierRoutingRef = React.createRef();
        this.customOrderInfoRef = React.createRef();
        this.appliedChargesRef = React.createRef();
        this.sectionRefs = [
            {
                section: "collapse1",
                ref: this.contactDetailsRef,
                label: this.translate('Label_Order_Contact_Details'),
                wmsSelector: this.Selector + "ContactDetails",
                isTabShow: true
            },
            {
                section: "collapse2",
                ref: this.lineItemRef,
                label: this.translate('Label_Order_Line_Items'),
                wmsSelector: this.Selector + "LineItemTab",
                isTabShow: true
            },
            {
                section: "collapse3",
                ref: this.carrierRoutingRef,
                label: this.translate('Label_Carrier_And_Routing'),
                wmsSelector: this.Selector + "CarrierRoutingTab",
                isTabShow: true
            },
            {
                section: "collapse4",
                ref: this.customOrderInfoRef,
                label: this.translate('Label_Custom_Order_Info'),
                wmsSelector: this.Selector + "CustomOrderInfo",
                isTabShow: true
            },
            {
                section: "collapse5",
                ref: this.appliedChargesRef,
                label: this.translate('Label_Applied_Charges'),
                wmsSelector: this.Selector + "AppliedCharges",
                isTabShow: this.Rights.hasOwnProperty('orders.charges.manage') ? true : false
            }
        ];


        this.originalShipToContact = {};
        this.originalBillToContact = {};
        this.originalSoldToContact = {};
        this.NumberOfUnitsText = this.translate('Label_Notification_NumberOfUnits');

        this.state = {
            customLabels: GlobalService.getCustomLabels(),
            optionsCustomer: [],
            optionsFacility: [],
            selectedCustomerId: "",
            selectedFacility: "",
            selectedCustomer: "",
            selector: this.Selector,
            readyToShipGif: false,
            showCustomerSavedElements: false,
            showDiscard: false,
            saveLoader: false,
            isSaved: false,
            oldOrderData: {},
            shareOrderData: {},
            sharedOptions: {
                OrderSE: []
            },
            sharedEditAllocationData: {
                totalOrdered: 0,// This holds the total amounts of rows returned from gridread
                modalTitle: ''
            },
            showRemainForm: false,
            isEmptycustomerOrderInfo: false,
            customerOrderRequired: "",
            defaultValue: "",
            flow: "create",
            tplSettings: GlobalService.getTplSettings(),
            isEditCallComplete: false,
            Rights: this.Rights,
            orderReadyToShipLabel: this.translate('Label_Markas_Complete').replace(new RegExp('{' + 'customCompleteLabel' + '}', 'gi'), GlobalService.getCustomLabels().Label_Complete),
            InitialPrintMenuResponse: [],
            MenuPrintData: [],
            sharedAttachObj: null,
            isOpenAttachDoc: false,
            isOpenShippingLabelModel: false,
            sharedShippingLabelOption: {},
            pickticketHeight: 0,
            pickticketBatchId: null,
            EnablePickOption: false,
            pickticketFlow: "regular",
            isOpenPickTicketModel: false,
            isOpenPackingSlipsModel: false,
            isOpenSendAsnModel: false,
            scacCodeAvailable: false,
            sendAsnData: {},
            packingSlipsData: {},
            sharedSelectedFacilityObj: {},
            isFullyAllocated: true,
            allocatedSkuList: '',
            createOrderClicked: false,
            shipAndCloseGif: false,
            sharedContactType: 'ShipTo',
            sharedIsAdvanceContactModal: false,
            sharedIsOpenManageAllocation: false,
            sharedIsOpenAddLineItem: false,
            sharedSelectedItemObj: null,
            sharedIsOpenProtoMassAdd: false,
            sharedIsResetLineItemGrid: false,
            sharedIsOpenAddCustomFieldModal: false,
            isOpenManageShipAndCloseModal: false,
            sharedRecalculateGif: false,
            isShipAndCloseDisable: false,
            isMarkAsCompleteDisable: false,
            isOpenManualChargeModal: false,
            manualChargeOptions: [],
            referenceIDText: this.translate('Label_Notification_ReferenceID'),
            showInitialLoaderOn: 'order-form',
            metaDataList: {},
            disabledText: "",
            isOpenBillOfLadingModel: false,
            isOpenConfirmModel: false,
            isOpenEditBolTemplateUI:false,
            bolTemplateOptions:[],
            sharedCustomerId: [],
            isCustomerUserAndOrderComplete: false,
            isLineItemChanges: false,
            sectionRefs: this.sectionRefs
        };
    }
    async componentDidMount() {
        // if (!this.$rootScope.ChildBulletin.Data.sharedSelectedOrder) {
        if (typeof this.props.windowId != 'undefined') {
            this.initManageOrders(this.props.sharedSelectOrder);
        } else if (!this.$rootScope.ChildBulletin.Data.sharedSelectedOrder) {
            this.state.showInitialLoaderOn = this.$rootScope.ChildBulletin.Data.Window;
            this.windowId = this.$rootScope.ChildBulletin.Data.Window;
            this.scrollDomSelector = this.$rootScope.ChildBulletin.Data.Window ? '.' + this.$rootScope.ChildBulletin.Data.Window + '#' + this.scrollAreaWrapperId : '#' + this.scrollAreaWrapperId;
            this.initManageOrders({});
        } else {
            let sharedSelectOrder = this.$rootScope.ChildBulletin.Data.sharedSelectedOrder;
            this.state.showInitialLoaderOn = this.$rootScope.ChildBulletin.Data.Window;
            this.windowId = this.$rootScope.ChildBulletin.Data.Window;
            this.scrollDomSelector = this.$rootScope.ChildBulletin.Data.Window ? '.' + this.$rootScope.ChildBulletin.Data.Window + '#' + this.scrollAreaWrapperId : '#' + this.scrollAreaWrapperId;
            this.initManageOrders(sharedSelectOrder);
        }
        kendo.ui.progress($('.' + this.state.showInitialLoaderOn), true);
        await manageOrderProtoMetaDataService.getManageOrderProtoUserClientMetaData();
        this.setState({ metaDataList: manageOrderProtoMetaDataService.getManageOrderProtoCollapseMetaData() })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.sharedSelectOrder != this.props.sharedSelectOrder) {
            kendo.ui.progress($('.' + this.state.showInitialLoaderOn), true);
            this.initManageOrders(this.props.sharedSelectOrder);
        }
    }

    componentWillUnmount() {
        if (this.$rootScope && this.$rootScope.ChildBulletin && this.$rootScope.ChildBulletin.Data && this.$rootScope.ChildBulletin.Data.isCloneOrder) {
            this.$rootScope.ChildBulletin.Data.isCloneOrder = false;
        }
    }

    openConfirmModal = () => {

        this.setState({
            isOpenConfirmModel:true
        });
    }
    initManageOrders = (param_order) => {
        let orderId;
        let isSaved = false;
        let createOrderClicked = false;

        if (!param_order.OrderId) {
            orderId = 0;
            createOrderClicked = true;
        } else {
            isSaved = true;
            orderId = param_order.OrderId;
            // $scope.Shipping.ShowOrderDataEntry = true; // if this is an edit order we want to show the order info
        }
        if (this.$rootScope.ChildBulletin.Data.isCloneOrder) {
            isSaved = false
        }
        this.setState({
            isSaved: isSaved,
            createOrderClicked: createOrderClicked
        });
        var eventName = "onDirtyWindowCloseOrderPrototype" + this.windowId;
        document.addEventListener(eventName, this.openConfirmModal);
        var closeEventName = "removeDirtyWindowCloseOrderPrototype" + this.windowId;
        var that = this;
        document.addEventListener(closeEventName, function handler(e) {
            document.removeEventListener(eventName, that.openConfirmModal);
            e.currentTarget.removeEventListener(e.type, handler);
            /* whatever */
        });

        // this is a create
        if (orderId == 0) {
            manageOrderServices.startManageOrders().then((response) => {
                // set false to not add unspecified option for customer & facility dropdown
                this.setState({
                    optionsCustomer: GlobalService.getCustomerList(false),
                    optionsFacility: Object.assign([], GlobalService.getFacilityList(false)),
                    showRemainForm: false,
                    selectedCustomerId: "",
                    selectedFacility: "",
                    shareOrderData: {
                        ReferenceNum:''
                    },
                    sharedOptions: { OrderSE: [] }
                });
            }).finally(() => {
                kendo.ui.progress($('.' + this.state.showInitialLoaderOn), false);
            });
        }

        // this is an edit
        if (orderId > 0) {
            manageOrderServices.ManageOrdersEditData(orderId, 'GridCustomOrderInfo').then((response) => {

                let optionsCustomer = GlobalService.getCustomerList(false);
                let selectedCustomer = optionsCustomer[ObjectWms.getIndexBasedUponKeyValuePair(optionsCustomer, "id", response.Order.CustomerId)];

                let tempFacilityOption = [];
                let facilityList = GlobalService.getFacilityDictList();

                if (selectedCustomer) {
                    for (var x = 0; x < selectedCustomer.facilities.length; x++) {
                        tempFacilityOption.push(facilityList[selectedCustomer.facilities[x]]);
                    }
                }
                var selectedFacilityIndex = ObjectWms.getIndexBasedUponKeyValuePair(tempFacilityOption, "id", response.Order.FacilityId);

                var selectedFacility = (selectedFacilityIndex >= 0) ? response.Order.FacilityId : 0;

                // SV-5494
                // Since We need to update FedEx BillingType only for Edit
                // this code is added only for Edit
                // Replacing BillingType for FedEx as below Mapping
                if (response.Order.RoutingInfo.Carrier == 'FedEx') {
                    if(response.Order.BillingCode == 'BillThirdParty') {
                        response.Order.BillingCode = 'third party';

                    } else if(response.Order.BillingCode == 'ConsigneeBilled') {
                        response.Order.BillingCode = 'recipient';

                    } else if(response.Order.BillingCode == 'FreightCollect') {
                        response.Order.BillingCode = 'collect';

                    } else if(response.Order.BillingCode == 'Prepaid') {
                        response.Order.BillingCode = 'sender';
                    }
                } else {
                    if(response.Order.BillingCode == 'third party') {
                        response.Order.BillingCode = 'BillThirdParty';
                    }
                }

                manageOrderServices.setManageOrdersData(response);
                // $scope.ManageOrders.$setPristine();
                this.setState({
                    flow: "edit",
                    optionsCustomer: optionsCustomer,
                    selectedCustomer: selectedCustomer,
                    selectedCustomerId: response.Order.CustomerId,
                    optionsFacility: tempFacilityOption,
                    selectedFacility: selectedFacility,
                    packingSlipsData: {
                        CustomerId: selectedCustomer.id,
                        Customer: selectedCustomer.name
                    },
                    sendAsnData: {
                        CustomerId: selectedCustomer.id,
                        CustomerUri: selectedCustomer.uri
                    }
                }, () => {
                    this.initManageOrdersAllDataAvailable(response);
                    this.getPrintData(response.Order.CustomerId);
                });
                this.setFacilityObject(selectedFacility);


            }).finally(() => {
                kendo.ui.progress($('.' + this.state.showInitialLoaderOn), false);
            });

        }
    }
    indexOptionsCustomerChange = (event) => {
        var tempOptionsFacility = Object.assign([], GlobalService.getFacilityList(false));
        var warehouseId = "";
        var packingSlipsData = null;
        var customerObj = "";
        if(event.target.value > 0) {
            customerObj = this.getCustomerById(event.target.value);
            tempOptionsFacility = [];
            var facilityList = GlobalService.getFacilityDictList();
            for (var x = 0; x < customerObj.facilities.length; x++) {
                if(facilityList[customerObj.facilities[x]] !== undefined){
                    tempOptionsFacility.push(facilityList[customerObj.facilities[x]]);
                }
            }
            console.log("customerFacility2", tempOptionsFacility);
            warehouseId = tempOptionsFacility[ObjectWms.getIndexBasedUponKeyValuePair(tempOptionsFacility, "id", customerObj.defaultFacility)] ?
            tempOptionsFacility[ObjectWms.getIndexBasedUponKeyValuePair(tempOptionsFacility, "id", customerObj.defaultFacility)].id : "";
            packingSlipsData = {
                CustomerId: event.target.value,
                Customer: customerObj.name
            };
        }
        this.setState({
            optionsFacility: tempOptionsFacility,
            selectedFacility: warehouseId,
            selectedCustomerId: event.target.value != 0 ? event.target.value : "",
            selectedCustomer: customerObj,
            showDiscard: true,
            packingSlipsData: packingSlipsData
        });
        this.setFacilityObject(warehouseId)
    }
    setFacilityObject = (warehouseId) => {
        setTimeout(() => {
            this.setState({
                sharedSelectedFacilityObj: warehouseId ? this.getFacilityById(warehouseId) : ""
            });
        }, 0);
    }
    getCustomerById(id) {
        return this.state.optionsCustomer.find((element) => {
            if (element.id == id) {
                return element;
            }
        });
    }
    handleFaciltiySelectChange = (event) => {
        let facilityObj = this.getFacilityById(event.target.value);
        this.setState({
            selectedFacility: event.target.value,
            sharedSelectedFacilityObj: facilityObj,
            showDiscard: true
        });

    }

    getFacilityById(id) {
        return this.state.optionsFacility.find((element) => {
            if (element.id == id) {
                return element;
            }
        });
    }

    handleChangeForObject = (param_parent_property, event) => {
        let value = event.target.value;

        this.setState({
            shareOrderData: Object.assign(
                {},
                this.state.shareOrderData,
                {
                    [param_parent_property]: value
                }
            ),
            showDiscard: true
        });

    }

    shipAndCloseClick = () => {
        if (this.masterValidation('After', 'shipClose')) {
            let newOrderData = this.prepareFieldsOnSave();
            isCustomerUser = GlobalService.GetCurrentUser().CustomerIds.length > 0 ? true : false;
            this.setState({ shipAndCloseGif: true });
            manageOrderServices.updateOrder(this.state.oldOrderData, newOrderData).then((response) => {

                this.setState({ shipAndCloseGif: false });
                if (response != 'undefined' && response.status == 200) {

                    GlobalService.notification('success', this.translate('Label_Notification_Order_Success'));

                    // Response sends us the wrong orderitemscount, so we are assing  state counter
                    response.data.OrderItemsCount = this.state.shareOrderData.OrderItemsCount;

                    response.data.RoutingInfo.Mode = this.getCarrierServices(this.state.sharedOptions.Carriers, response.data.RoutingInfo);
                    this.setState({
                        shareOrderData: Object.assign(
                            {},
                            this.state.shareOrderData,
                            response.data
                        ),
                        oldOrderData:response.data
                    });
                    // The method sends us the wrong orderitemscount, so we are manually determining it here and updating the model to have orders.orderitemcounts =  to what the UI knows, this affects whether or not the ship and close button should show
                    // this.setLineItemsGridDataCount();
                    // Clear manually added flag from saved charges in the grid
                    this.clearManuallyAddedFlag();

                    this.originalShipToContact = Object.assign({},response.data.ShipTo);
                    this.originalBillToContact = Object.assign({},response.data.BillTo);
                    this.originalSoldToContact = Object.assign({},response.data.SoldTo);

                    //EventNewOrderData for reload find order grid
                    this.orderGridRead();

                    // change title of open tab
                    $("#" + this.windowId +'_wnd_title').text(this.translate('Window_Title_Label_EditOrder'));

                    this.setState({ isOpenManageShipAndCloseModal: true });

                    this.setState({
                        showDiscard: false
                    });
                    // $scope.Global.ModalOpen($scope.ShipWindowOptions);
                }
            }).finally(() => {
                this.setState({ shipAndCloseGif: false });
             });

        }
    }
    onCloseShipAndClose = () => {
        this.setState({ isOpenManageShipAndCloseModal: false });
    }
    saveAndFinalizeOrderClicked = () => {

        if (this.masterValidation('After', 'readyToShip')) {
            isCustomerUser = GlobalService.GetCurrentUser().CustomerIds.length > 0 ? true : false;
            manageOrderServices.finalizeOrder({
                "orderId": this.state.shareOrderData.OrderId
            }).then((response) => {
                if (typeof response != 'undefined' && (response.status == 200 || response.status == 204)) {
                    // $scope.ManageOrders.$setPristine();
                    // Clear manually added flag from saved charges in the grid

                    this.clearManuallyAddedFlag();

                    let successMessage = this.translate('Label_Success_SaveAndFinalize').replace(new RegExp('{' + 'customCompleteLabel' + '}', 'gi'), GlobalService.getCustomLabels().Label_Complete);
                    GlobalService.notification('success', successMessage);
                    // IF we successfully convert it to Ready to Ship, we need to hide the button as they can not reattempt this.
                    this.setState({
                        shareOrderData: Object.assign(
                            {},
                            this.state.shareOrderData,
                            { CanFinalize: false }
                        )
                    });
                    // Disable save,add,massadd and calculate charges button for customer user after mark as complete finish
                    if (isCustomerUser) {
                        this.setState({
                            isCustomerUserAndOrderComplete: true
                        });
                    }

                    //EventNewOrderData for reload find order grid
                    this.orderGridRead();


                }
            }).finally(() => {
                this.setState({ readyToShipGif: false });
            });

        } else {
            this.setState({ readyToShipGif: false });
        }
    }
    saveOrderClicked = (saveFrom = null) => {


        isCustomerUser = GlobalService.GetCurrentUser().CustomerIds.length > 0 ? true : false;

        if (saveFrom == 'CalculateCharges') {
            this.setState({ sharedRecalculateGif: true });
        }
        if (saveFrom == 'CompleteOrder') {
            this.setState({ readyToShipGif: true });
        }
        if (this.$rootScope.ChildBulletin.Data.isCloneOrder) {
            this.setState({ saveLoader: true });
            let newOrderData = this.prepareFieldsOnSaveInitial();
            this.$rootScope.setLoaderTimer('Create Order');
            manageOrderServices.cloneOrder(newOrderData, this.state.shareOrderData.OrderId, isCustomerUser).then((response) => {

                // manageOrderServices.setFacility(this.state.sharedSelectedFacilityObj);
                // manageOrderServices.setCustomer(this.state.selectedCustomer);
                if (response != 'undefined' && response.status == 200) {
                    this.$rootScope.ChildBulletin.Data.isCloneOrder = false;
                    var eventItem = new CustomEvent("EventNewOrderData", {
                        detail: {
                            "action": "update",
                            "ref": response.data.ReferenceNum,
                            "trans": response.data.OrderId,
                            fromWindow: this.windowId,
                            "thumbnail": "update"
                        }
                    });
                    document.dispatchEvent(eventItem);

                    GlobalService.notification('success', this.translate('Label_Notification_Order_Cloned_Success'));
                    kendo.ui.progress($('.' + this.state.showInitialLoaderOn), true);
                    this.setState({ isSaved: true });

                    $("#" + this.windowId +'_wnd_title').text(this.translate('Window_Title_Label_EditOrder'));

                    manageOrderServices.ManageOrdersEditData(response.data.OrderId, 'GridCustomOrderInfo')
                        .then((response) => {

                            this.setState({ flow: "edit" });
                            manageOrderServices.setManageOrdersData(response);
                            this.initManageOrdersAllDataAvailable(response);
                            this.getPrintData(response.Order.CustomerId);
                            // this.setLineItemsGridDataCount();

                        }).finally(() => {
                            kendo.ui.progress($('.' + this.state.showInitialLoaderOn), false);
                        });
                    if (saveFrom === 'CalculateCharges') {
                        this.saveAndAddOrderCharges();
                    }

                }
            }).finally(() => {
                this.setState({ saveLoader: false });
            });
        } else if (this.state.isSaved) {
            if (this.masterValidation('After', 'save')) {
                this.setState({ saveLoader: true });
                let newOrderData = this.prepareFieldsOnSave();
                manageOrderServices.updateOrder(this.state.oldOrderData, newOrderData).then((response) => {

                    this.setState({ saveLoader: false });
                    if (response != 'undefined' && response.status == 200) {

                        GlobalService.notification('success', this.translate('Label_Notification_Order_Success'));
                        let Unit1Identifier = response.data.Unit1Identifier;
                        let Unit2Identifier = response.data.Unit2Identifier;
                        let orderRoutingInfo;

                        if (response.data.Unit1Identifier != null) {
                            Unit1Identifier = { 'Name': response.data.Unit1Identifier.Name };
                        }
                        if (response.data.Unit2Identifier != null) {
                            Unit2Identifier = { 'Name': response.data.Unit2Identifier.Name };
                        }
                        if(response.data.RoutingInfo != null){
                            orderRoutingInfo = response.data.RoutingInfo;
                            orderRoutingInfo.Mode = this.getCarrierServices(this.state.sharedOptions.Carriers, response.data.RoutingInfo);
                        }

                        this.setState({
                            shareOrderData: Object.assign(
                                {},
                                this.state.shareOrderData,
                                {
                                    TotalWeight: response.data.TotalWeight,
                                    TotalVolume: response.data.TotalVolume,
                                    NumUnits1: response.data.NumUnits1,
                                    NumUnits2: response.data.NumUnits2,
                                    Unit1Identifier: Unit1Identifier,
                                    Unit2Identifier: Unit2Identifier,
                                    RoutingInfo: orderRoutingInfo,
                                    ShipTo:response.data.ShipTo,
                                    BillTo:response.data.BillTo,
                                    SoldTo:response.data.SoldTo
                                }
                            ),
                            showDiscard: false,
                            oldOrderData: response.data
                        });
                        // this.setLineItemsGridDataCount();
                        this.clearManuallyAddedFlag();

                        this.originalShipToContact = Object.assign({},response.data.ShipTo);
                        this.originalBillToContact = Object.assign({},response.data.BillTo);
                        this.originalSoldToContact = Object.assign({},response.data.SoldTo);

                        //EventNewOrderData for reload find order grid
                        this.orderGridRead();

                        this.setState({
                            showRemainForm: true
                        });  // if are save is successful we will show the rest of the order information

                        // change title of open tab
                        $("#" + this.windowId +'_wnd_title').text(this.translate('Window_Title_Label_EditOrder'));

                        // we want to recalculate charge after save order when click on calculate charge
                        if (saveFrom === null) {
                            let billingChargesData = [];
                            response.data.BillingCharges?.forEach((element, key) => {
                                billingChargesData.push({
                                    "Action": "a",
                                    "ChargeOrigin": element.ChargeOrigin,
                                    "ChargeLabel": element.ChargeLabel,
                                    "ChargeTypeId": element.ChargeTypeId,
                                    "ChargeTypeDescription": element.ChargeTypeDescription,
                                    "UnitDescription": element.UnitDescription,
                                    "ChargePerUnit": element.ChargePerUnit,
                                    "NumberOfUnits": element.NumberOfUnits,
                                    "TotalCharges": element.TotalCharges,
                                    "GeneralLedgerAccount": element.GeneralLedgerAccount,
                                    "ExternalAccountsReceivable": element.ExternalAccountsReceivable,
                                    "ExternalItemIdentifier": element.ExternalItemIdentifier,
                                    "ExternalItemDescription": element.ExternalItemDescription,
                                    "SKU": element.SKU,
                                    "Notes": element.Notes,
                                    "UnsavedManualCharge": false,
                                    "SystemGenerated": element.SystemGenerated
                                });

                            });
                            this.setState({
                                sharedOptions: Object.assign(
                                    {},
                                    this.state.sharedOptions,
                                    {
                                        BillingCharges: billingChargesData
                                    }
                                )
                            });
                        }
                        else {
                            if (saveFrom === 'CalculateCharges') {
                                this.saveAndAddOrderCharges();
                            }
                            if (saveFrom === 'FromAreYouSureModal') {
                                this.onDiscardAndExit();
                            }
                            if (saveFrom === 'CompleteOrder') {
                                this.saveAndFinalizeOrderClicked();
                            }
                        }
                    }
                }).finally(() => {
                    this.setState({ saveLoader: false });
                });

            }
        } else {

            if (this.masterValidation('Initial', 'save')) {
                this.setState({ saveLoader: true });
                let newOrderData = this.prepareFieldsOnSaveInitial();
                this.$rootScope.setLoaderTimer('Create Order');
                manageOrderServices.saveOrder(newOrderData, isCustomerUser).then((response) => {

                    // manageOrderServices.setFacility(this.state.sharedSelectedFacilityObj);
                    // manageOrderServices.setCustomer(this.state.selectedCustomer);

                    if (response != 'undefined' && response.status == 200) {

                        var eventItem = new CustomEvent("EventNewOrderData", {
                            detail: {
                                "action": "update",
                                "ref": response.data.ReferenceNum,
                                "trans": response.data.OrderId,
                                fromWindow: this.windowId,
                                "thumbnail": "update"
                            }
                        });
                        document.dispatchEvent(eventItem);
                        // this.$rootScope.$emit('ReactEventNewOrderData', {
                        //     "action": "update",
                        //     "ref": response.data.ReferenceNum,
                        //     "trans": response.data.OrderId,
                        //     fromWindow: this.windowId,
                        //     "thumbnail": "update"
                        // }); // Some order data has been saved so we need to emit, we pass other data in case it is needed, in this case we need to update the thumbnail

                        GlobalService.notification('success', this.translate('Label_Notification_Order_Create_Success'));
                        kendo.ui.progress($('.' + this.state.showInitialLoaderOn), true);
                        this.setState({ isSaved: true });

                        $("#" + this.windowId +'_wnd_title').text(this.translate('Window_Title_Label_EditOrder'));

                        manageOrderServices.ManageOrdersEditData(response.data.OrderId, 'GridCustomOrderInfo')
                            .then((response) => {

                                this.setState({ flow: "edit" });
                                manageOrderServices.setManageOrdersData(response);
                                this.initManageOrdersAllDataAvailable(response);
                                this.getPrintData(response.Order.CustomerId);
                                // this.setLineItemsGridDataCount();

                            }).finally(() => {
                                kendo.ui.progress($('.' + this.state.showInitialLoaderOn), false);
                            });
                        if (saveFrom === 'CalculateCharges') {
                            this.saveAndAddOrderCharges();
                        }

                    }
                }).finally(() => {
                    this.setState({ saveLoader: false });
                });
            }
        }
    }

    initManageOrdersAllDataAvailable = (data) => {

        // var arr = ['ShipTo', 'BillTo', 'SoldTo'];
        // arr.forEach(element => {
        //     if (data.Order[element] !== undefined) {
        //         if (data.Order[element].Country == null || data.Order[element].Country == '') {
        //             data.Order[element] = Object.assign(
        //                 data.Order[element],
        //                 {
        //                     Country: 'US'
        //                 }
        //             );
        //         }
        //     }
        // });

        var flexFields = this.state.selectedCustomer.options.flexFields;
        this.originalShipToContact =Object.assign({},data.Order.ShipTo);
        this.originalBillToContact =Object.assign({},data.Order.BillTo);
        this.originalSoldToContact =Object.assign({},data.Order.SoldTo);

        var customerOrderRequired, defaultValue;
        for (var i = 0; i < data.OrderSE.length; i++) {
            if (data.OrderSE[i].Required) {
                customerOrderRequired = data.OrderSE[i].Required;
                defaultValue = data.OrderSE[i].Value;
            }
        }

        let billingCharges = ObjectWms.removeObjFromListOnParameterValue(data.BillingCharges == null ? [] : data.BillingCharges, "SystemGenerated", true);
        data.BillingCharges = billingCharges;
        data.ThreePlSettings = GlobalService.getTplSettings();
        if(data.Order.FulfillInv){
            data.Order.FulfillInv.FulfillInvTax = data.Order.FulfillInv.FulfillInvTax ? data.Order.FulfillInv.FulfillInvTax.toFixed(2) : '0.00';
            data.Order.FulfillInv.FulfillInvShippingAndHandling = data.Order.FulfillInv.FulfillInvShippingAndHandling ? data.Order.FulfillInv.FulfillInvShippingAndHandling.toFixed(2) : '0.00';
            data.Order.FulfillInv.FulfillInvDiscountAmount = data.Order.FulfillInv.FulfillInvDiscountAmount ? data.Order.FulfillInv.FulfillInvDiscountAmount.toFixed(2) : '0.00';
        }

        let tmpShipEngineCarrierResponse = data.Carriers.Carriers;
        tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
            return obj.Carrier != "Buku" && obj.Carrier != "PitneyBowesExp";
        });
        if (!this.isEnableProvider) {
            tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
                return obj.Carrier != "PitneyBowesStd";
            });
        }
        data.Carriers.Carriers = tmpShipEngineCarrierResponse;
        var shipAndCloseAndCompleteBtnDisableFlag = this.checkShipAndCloseDisabled(data.Order);

        data.Order.RoutingInfo.Mode = this.getCarrierServices(data.Carriers, data.Order.RoutingInfo);
        let tmpShowRemainForm = true;
        if (this.$rootScope.ChildBulletin.Data.isCloneOrder) {
            // Exclude Reference number
            data.Order.ReferenceNum = "";

            // Exclude Carrier > Tracking number
            data.Order.RoutingInfo.TrackingNumber = ""

            // Exclude From Routing tab
            data.Order.RoutingInfo.LoadNumber = "" // Load Number

            data.Order.RoutingInfo.BillOfLading = "" // Bill of Lading number

            data.Order.RoutingInfo.TrailerNumber = "" // Trailer Number

            data.Order.RoutingInfo.SealNumber = "" // Seal number

            data.Order.RoutingInfo.DoorNumber = "" // Door

            data.Order.RoutingInfo.CapacityTypeIdentifier = "" // Capacity Type

            data.Order.RoutingInfo.PickupDate = "" // Pickup date

            data.BillingCharges = [];

            data.OrderSE = [];
            tmpShowRemainForm = false;
        }
        this.setState({
            showCustomerSavedElements: flexFields,
            oldOrderData: data.Order,
            shareOrderData: data.Order,
            sharedOptions: data,
            isEmptycustomerOrderInfo: (customerOrderRequired && defaultValue == null) ? true : false,
            customerOrderRequired: customerOrderRequired,
            defaultValue: defaultValue,
            showRemainForm: tmpShowRemainForm,
            showDiscard: false,
            isShipAndCloseDisable: shipAndCloseAndCompleteBtnDisableFlag.isShipAndCloseDisable,
            isMarkAsCompleteDisable: shipAndCloseAndCompleteBtnDisableFlag.isMarkAsCompleteDisable
        });
        // this.orderGridRead();
        // update tab refs state
        var sectionRefs = this.state.sectionRefs;
        sectionRefs.forEach((elem) => {
            if(elem.section == "collapse4") {
                elem.isTabShow = (flexFields == true) ? true : false;
            }
        });
        this.setState({
            sectionRefs: sectionRefs
        });
    };

    getCarrierServices = (carriers, routingInfo) => {
        let shipmentServices = [];
        let optionsShipmentServices = [];
        let carrier = routingInfo.Carrier;
        let tempObject = carriers.Carriers.filter(function (el) {
            return el.Carrier === carrier;
        })[0];
        if (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('ShipmentServices')) {
            optionsShipmentServices = tempObject['ShipmentServices'];
        } else {
            optionsShipmentServices = carriers.DefaultShipmentServices;
        }
        shipmentServices = GlobalService.hideDeactivatedShipmentServices(carrier, optionsShipmentServices, routingInfo.Mode);
        routingInfo.Mode = shipmentServices.valueCode ? shipmentServices.valueCode.Description  : routingInfo.Mode;

        return routingInfo.Mode;
    }

    orderGridRead = () => {
        var eventItem = new CustomEvent("EventNewOrderData", {
            detail: { "action": "update" }
        });
        document.dispatchEvent(eventItem);
    }

    getPrintData = (param_customerId) => {
        GlobalService.initialPrintMenu(param_customerId).then((response) => {
            if (response) {
                this.setState({
                    InitialPrintMenuResponse: response
                }, () => {
                    this.updateInitialPrintMenu();
                });
            }
        }).finally(() => { });
    }
    updateInitialPrintMenu = () => {
        var params = {
            Selector: this.state.selector,
            Rights: this.state.Rights,
            FullyAllocated: this.state.isFullyAllocated,
            EnablePickOption: this.state.isFullyAllocated ? true : this.state.tplSettings.Shipping.PrintOverAllocPickTickets,
            TplSettings: this.state.tplSettings,
            SameFacility: true,
            SameCustomer: true,
            SelectedOrders: 1,
            fnNames: ['RunBOLReport', 'RunMasterBOLReport', 'RunReportShippingLabels', 'RunReportViewTransaction'],
        };
        const updatedMenus = DynamicMenu.getInitialPrintMenu(this.state.InitialPrintMenuResponse, params, this.dynamicMenuClick, "manageOrder");
        this.setState({
            MenuPrintData: updatedMenus.PrintData,
        });
    }
    clickOnattachFiles = () => {
        var sharedAttachObj = {
            'isOrder': true,
            'uri': this.state.shareOrderData.EditUri,
            'fileSummariesUri': this.state.shareOrderData.FileSummariesUri,
            'getAttachedDocsInfoUrl': '/WebUI/Shipping/Orders/GetAttachedDocsInfo',
            'getAttachedDocsInfoPayload': {
                'orderUri': this.state.shareOrderData.EditUri
            },
            'attachdocumentUrl': '/WebUI/Shipping/Orders/AttachDocsToOrder?orderFileSummariesUri=' + this.state.shareOrderData.FileSummariesUri,
            'removeAttachDocUrl': '/WebUI/Shipping/Orders/DeleteAttachedDoc',
            'selectedOrderData': this.state.shareOrderData,
        };
        this.setState({
            sharedAttachObj: sharedAttachObj,
            isOpenAttachDoc: true
        });
    }
    clickOnBOL = (e) =>{
        this.setState({
            isOpenBillOfLadingModel: true
        });
    }
    clickOnsendASN = (e) => {
        let scacCodeAvailable = false;

        scacCodeAvailable = (typeof this.state.shareOrderData.RoutingInfo != 'undefined' && this.state.shareOrderData.RoutingInfo.ScacCode != null && this.state.shareOrderData.RoutingInfo.ScacCode != '') ? true : false;

        this.setState({
            scacCodeAvailable: scacCodeAvailable,
            isOpenSendAsnModel: true
        });
    }
    clickOnpickTicket = () => {
        // let selectedCustomer = this.state.sharedCustomerId.concat(this.state.selectedCustomerId);

        this.setState({
            isOpenPickTicketModel: true,
            pickticketHeight: 475,
            pickticketBatchId: null,
            sharedCustomerId : [this.state.selectedCustomerId]
        });
    }

    closePickTicketModel = () => {
        this.setState({
            isOpenPickTicketModel: false
        });
    }

    closeAttachDocuments = () => {
        this.setState({ isOpenAttachDoc: false });
    }
    clickOnShippingLabel = () =>{
        var shippingLblObj = this.state.InitialPrintMenuResponse.filter((el) => {
            if(el['RdlName'] == 'ShippingLabel'){
                return el;
            }
        })[0];
        this.setState({
            isOpenShippingLabelModel:true,
            sharedShippingLabelOption:shippingLblObj
        });
    }
    onCloseShippingLabelModel = () => {
        this.setState({ isOpenShippingLabelModel: false });
    }

    clickOnRunBOLReport = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunBOLReport');
    }
    clickOnRunMasterBOLReport = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunMasterBOLReport');
    }
    clickOnRunReportViewTransaction = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunReportViewTransaction');
    }
    clickOnEditBol = (e) =>{

        findOrdersService.getBOLTemplates(this.state.selectedCustomerId).then((response) => {
            if (response && response.status == 200) {
                // response.data = [
                // {
                //     "Name": "Template1",
                //     "BolTemplateId": 1
                // },
                // {
                //     "Name": "Template2",
                //     "BolTemplateId": 2
                // }];
                if(response.data.length >= 1){
                    let bolTemplates = response.data;
                    bolTemplates.push({
                        "Name": "Default",
                        "BolTemplateId": 0
                    });
                    this.setState({
                        isOpenEditBolTemplateUI:true,
                        bolTemplateOptions:bolTemplates
                    });
                } else {
                    let templateId = 0;
                    if(response.data.length == 1){
                        templateId = response.data[0].BolTemplateId;
                    }
                    this.openEditBolClassicPage(templateId);
                }
            }
        }).finally(() => {
        });
    }
    onCloseEditBolOptionsModel = () =>{
        this.setState({
            isOpenEditBolTemplateUI:false,
            bolTemplateOptions:[]
        });
    }
    openEditBolClassicPage =(param_template_id)=>{
        let orderid = this.state.shareOrderData.OrderId;
        let customerId = this.state.selectedCustomerId;
        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            id: '/WebUI/V1/EditBol?orderid='+orderid+'&templateid='+param_template_id+'&customerid='+customerId,
            isIframe: true,
            title: 'Label_Bill_of_Lading_Editor',
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: 'Label_Bill_of_Lading_Editor'
            }],
            icon: 'submenu-icon fa fa-pencil-square-o',
            urlParam: 'bol-editor'
        });
    }

    dynamicServiceForDynamicMenu = (event, fnName) => {
        const isBOL = fnName === "RunBOLReport";
        var runReport = $(event.target).parent().attr('data-run-report');
        if (isBOL) {
            this.handleLoading(true);
        }
        findOrdersService.dynamicServiceForDynamicMenu(fnName, runReport, [this.state.shareOrderData.OrderId], null, 'Orders').then((response) => {
            if (isBOL) {
                this.refreshOrderData();
                this.handleLoading(false);
                GlobalService.notification('success', this.translate('Label_Notification_Order_Success'));
            }
            this.handleResponseMenuPrint(response);
        }).finally(() => { });
    }

    refreshOrderData = () =>{
        //EventNewOrderData for reload find order grid
        this.orderGridRead();
        this.initManageOrders(this.state.shareOrderData);
        var eventItem = new CustomEvent("onOrderItemsRefresh");
        document.dispatchEvent(eventItem);
    }

    handleLoading = (toggle) => {
        var orderWindowId = undefined;
        if (typeof this.props.windowId != 'undefined') {
            orderWindowId = "#orderform";
        }
        else if (this.$rootScope.ChildBulletin.Data.sharedSelectedOrder) {
            orderWindowId = "#wms-manageorder-proto";
        }
        if(orderWindowId) {
            kendo.ui.progress($(orderWindowId), toggle);
        }
    }

    handleResponseMenuPrint = (response) => {
        var blob = ArrayWms.convertBase64ToByteArray(response.Data, 'application/pdf');
        ArrayWms.displayBlobInWindow(blob, 'report.pdf');
    }
    clickOnrunReportPackingSlips = (e) => {
        this.setState({ isOpenPackingSlipsModel: true });
    }
    closePackingslipsModel = () => {
        this.setState({ isOpenPackingSlipsModel: false });
    }
    closeSendAsnModel = () => {
        this.setState({ isOpenSendAsnModel: false });
    }
    dynamicMenuClick = (event) => {
        var wmsid = $(event.target).parent().attr('data-wmsid');
        wmsid = "clickOn" + wmsid;
        if (typeof this[wmsid] !== "undefined") {

            this[wmsid](event);
        } else {
            console.error("Uncaught TypeError: " + wmsid + " is not a function");
        }
    }
    // setLineItemsGridDataCount = () => {
    //     if ($("." + this.windowId + ' #OrderItemsGrid').data('kendoGrid')) {
    //         var orderItems = $("." + this.windowId + ' #OrderItemsGrid').data('kendoGrid')._data;
    //         console.log("orderItems",orderItems);
    //         this.setState({
    //             shareOrderData: Object.assign(
    //                 {},
    //                 this.state.shareOrderData,
    //                 {
    //                     OrderItems: orderItems,
    //                     OrderItemsCount: orderItems.length
    //                 }
    //             ),
    //         });
    //     }
    // }
    clearManuallyAddedFlag = () => {
        let billingChargesData = this.state.sharedOptions.BillingCharges == null ? [] : this.state.sharedOptions.BillingCharges;
        for (var x = 0; x < billingChargesData.length; x++) {
            billingChargesData[x].Action = "a";
            billingChargesData[x].UnsavedManualCharge = false;
        }
        this.setState({
            sharedOptions: Object.assign(
                {},
                this.state.sharedOptions,
                {
                    BillingCharges: billingChargesData
                }
            )
        });

    }
    prepareFieldsOnSaveInitial() {
        let savedElements = this.state.sharedOptions.OrderSE;
        // if ($("." + this.windowId + ' #OrderItemsGrid').data('kendoGrid')) {
        //     this.setLineItemsGridDataCount();
        // }

        for (var i = 0; i < savedElements.length; i++) {
            if (savedElements[i].Value === '') {
                savedElements[i].Value = null;
            }
        }

        // set customer and facility IDs

        return Object.assign(
            {},
            this.state.shareOrderData,
            {
                "CustomerId": this.state.selectedCustomerId,
                "FacilityId": this.state.selectedFacility,
                "SavedElements": ObjectWms.removeObjFromListOnParameterValue(savedElements, "Value", null)
            }
        );
    }
    prepareFieldsOnSave = () => {
        let billingChargesData = this.state.sharedOptions.BillingCharges == null ? [] : this.state.sharedOptions.BillingCharges;
        let savedElements = this.state.sharedOptions.OrderSE;
        let shipToObject = Object.assign({},this.state.shareOrderData.ShipTo);
        let billToObject = Object.assign({},this.state.shareOrderData.BillTo);
        let soldToObject = Object.assign({},this.state.shareOrderData.SoldTo);


        for (let i = 0; i < savedElements.length; i++) {
            if (savedElements[i].Value === '') {
                savedElements[i].Value = null;
            }
        }

        // ShipToSelectedState scope not in use
        // if (typeof $scope.ShipToSelectedState == 'undefined' || $scope.ShipToSelectedState == null) {
        // } else {
        //     $scope.Order.ShipTo.State = typeof $scope.ShipToSelectedState == 'undefined' ? null : $scope.ShipToSelectedState.RegionCode;
        // }


        if (shipToObject.State != null) {
            if (shipToObject.State.length > 4) {
                shipToObject.State = shipToObject.State.slice(0, 4);
            }
        }

        shipToObject.IsAddressResidential = this.state.shareOrderData.ParcelOptions.ResidentialFlag;
        if (this.state.oldOrderData.ParcelOptions.ResidentialFlag != this.state.shareOrderData.ParcelOptions.ResidentialFlag) {
            shipToObject.AddressStatusTypeId = 2;
        }

        if (billToObject.State != null) {
            if (billToObject.State.length > 4) {
                billToObject.State = billToObject.State.slice(0, 4);
            }
        }

        if (soldToObject.State != null) {
            if (soldToObject.State.length > 4) {
                soldToObject.State = soldToObject.State.slice(0, 4);
            }
        }

        let tmpUnit1Identifier = this.state.shareOrderData.Unit1Identifier;
        if(tmpUnit1Identifier != null && tmpUnit1Identifier.Name == ''){
            tmpUnit1Identifier = null;
        }

        let tmpUnit2Identifier = this.state.shareOrderData.Unit2Identifier;
        if(tmpUnit2Identifier != null && tmpUnit2Identifier.Name == ''){
            tmpUnit2Identifier = null;
        }
        let tmpAppendProperty = Object.assign({},this.state.shareOrderData);
        tmpAppendProperty = Object.assign(
            {},
            tmpAppendProperty,
            {
                "CustomerId": this.state.selectedCustomerId,
                "FacilityId": this.state.selectedFacility,
                "SavedElements": ObjectWms.removeObjFromListOnParameterValue(savedElements, "Value", null),
                "BillingCharges": ObjectWms.removeObjFromListOnParameterValue(billingChargesData, "NumberOfUnits", null),
                'Unit1Identifier': tmpUnit1Identifier,
                'Unit2Identifier': tmpUnit2Identifier
            }
        );
        if(this.isContactInfoValid(shipToObject,1)){
            tmpAppendProperty.ShipTo = shipToObject;
        }else{
                delete tmpAppendProperty.ShipTo;
        }
        if(this.isContactInfoValid(billToObject,1)){
            tmpAppendProperty.BillTo = billToObject;
        }else{
                delete tmpAppendProperty.BillTo;
        }
        if(this.isContactInfoValid(soldToObject,1)){
            tmpAppendProperty.SoldTo = soldToObject;
        }else{
                delete tmpAppendProperty.SoldTo;
        }
        if (tmpAppendProperty.ShipTo && this.hasContactChanged('ShipTo', tmpAppendProperty.ShipTo)) {
            tmpAppendProperty.ShipTo['ContactId'] = null;
        }
        if (tmpAppendProperty.BillTo && this.hasContactChanged('BillTo', tmpAppendProperty.BillTo) ) {
            tmpAppendProperty.BillTo['ContactId'] = null;
        }
        if (tmpAppendProperty.SoldTo && this.hasContactChanged('SoldTo', tmpAppendProperty.SoldTo)  ) {
            tmpAppendProperty.SoldTo['ContactId'] = null;
        }
        if (tmpAppendProperty.RoutingInfo && tmpAppendProperty.RoutingInfo.Mode) {
            let shipmentServices = [];
            let optionsShipmentServices = [];
            let carrier = tmpAppendProperty.RoutingInfo.Carrier;
            let tempObject = this.state.sharedOptions.Carriers.Carriers.filter(function (el) {
                return el.Carrier === carrier;
            })[0];
            if (typeof tempObject != 'undefined' && tempObject.hasOwnProperty('ShipmentServices')) {
                optionsShipmentServices = tempObject['ShipmentServices'];
            } else {
                optionsShipmentServices = this.state.sharedOptions.Carriers.DefaultShipmentServices;
            }
            // shipmentServices = GlobalService.hideDeactivatedShipmentServices(carrier, optionsShipmentServices, data.Order.RoutingInfo.Mode);

            let valueCode = _.find(optionsShipmentServices, function(objShipmentServices) {
                return objShipmentServices.Description === tmpAppendProperty.RoutingInfo.Mode || objShipmentServices.Code === tmpAppendProperty.RoutingInfo.Mode;
            });
            if (valueCode) {
                tmpAppendProperty.RoutingInfo.Mode = valueCode.Code;
            }
        }
        if(tmpAppendProperty.RoutingInfo && tmpAppendProperty.RoutingInfo.PickupDate) {
            tmpAppendProperty.RoutingInfo.PickupDate = StringWms.convertFilterStringToISODate(tmpAppendProperty.RoutingInfo.PickupDate,'',false,true).isoStringUTC
        }
        return tmpAppendProperty;
    }
    isContactInfoValid = (contactObj,numOfFieldsAllow) => {
        var proprtyArr = Object.getOwnPropertyNames(contactObj);
        var numOfValueExist = 0;
        for (var i = 0; i < proprtyArr.length; i++) {
            var proprtyName = proprtyArr[i];
            if (contactObj.hasOwnProperty(proprtyName)
            && contactObj[proprtyName]
            && proprtyName != 'ContactId'
            && proprtyName != 'IsQuickLookup'
            && proprtyName != 'AddressStatusTypeId') {
                // console.log("proprtyName",proprtyName);
                numOfValueExist = numOfValueExist + 1;
            }
            if(numOfValueExist == numOfFieldsAllow){
                return true;
            }
        }
        // console.log("isContactInfoValid numOfValueExist",numOfValueExist);
        return false;
    }
    hasContactChanged = (param_contact_name, param_contact_value) => {

        var originalContactProps = {};
        var originalContact = {};
        var contactOrderData = {};

        if (param_contact_name == 'ShipTo') {

            originalContactProps = Object.getOwnPropertyNames(this.originalShipToContact);
            originalContact = this.originalShipToContact;
            contactOrderData = param_contact_value;

        } else if(param_contact_name == 'BillTo') {

            originalContactProps = Object.getOwnPropertyNames(this.originalBillToContact);
            originalContact = this.originalBillToContact;
            contactOrderData = param_contact_value;

        } else {

            originalContactProps = Object.getOwnPropertyNames(this.originalSoldToContact);
            originalContact = this.originalSoldToContact;
            contactOrderData = param_contact_value;

        }


        for (var i = 0; i < originalContactProps.length; i++) {
            var propName = originalContactProps[i];

            if (originalContact.hasOwnProperty(propName)
                && contactOrderData.hasOwnProperty(propName)
                && (
                    typeof originalContact[propName] != 'object' ||
                    originalContact[propName] == null
                )
                && typeof originalContact[propName] != 'function'
                && originalContact[propName] != contactOrderData[propName]) {

                return true;
            }
        }
        return false;
    }
    onOrderDataSet = (param_orderObj,isResetDiscard) => {
        if(!isResetDiscard){
            isResetDiscard = false;
        }
        var shipAndCloseAndCompleteBtnDisableFlag = this.checkShipAndCloseDisabled(param_orderObj);
        this.setState({
            showDiscard: isResetDiscard ? true : JSON.stringify(param_orderObj) != JSON.stringify(this.state.shareOrderData),
            shareOrderData: Object.assign(
                {},
                this.state.shareOrderData,
                param_orderObj
            ),
            isShipAndCloseDisable: shipAndCloseAndCompleteBtnDisableFlag.isShipAndCloseDisable,
            isMarkAsCompleteDisable: shipAndCloseAndCompleteBtnDisableFlag.isMarkAsCompleteDisable
        });
    }
    // this saperate method use for changes in line item grid and to manage showDiscard state
    onSetOrderItemChange = (param_order_item_arrobj,param_weight,param_volume) => {
        let tempShareOrderData = {
            'OrderItems': param_order_item_arrobj,
            'OrderItemsCount': param_order_item_arrobj.length
        };

        if(this.state.isLineItemChanges){
            tempShareOrderData = Object.assign(
                {},
                tempShareOrderData,
                {
                    'TotalWeight':param_weight.toFixed(4),
                    'TotalVolume':param_volume.toFixed(4)
                }
            );
        }

        this.setState({
            shareOrderData: Object.assign(
                {},
                this.state.shareOrderData,
                tempShareOrderData
            )
        });
    }

    onSharedOptionDataSet = (param_shared_option_obj) => {
        this.setState({
            showDiscard: true,
            sharedOptions: Object.assign(
                {},
                this.state.sharedOptions,
                param_shared_option_obj
            )
        });
    }
    onCustomOrderInfoSave = (param_data) => {
        var customerOrderRequired = false;
        var defaultValue = null;
        for (var i = 0; i < param_data.length; i++) {
            if (param_data[i].Required) {
                customerOrderRequired = param_data[i].Required;
                defaultValue = param_data[i].Value;
            }
        }
        this.setState({
            isEmptycustomerOrderInfo: (customerOrderRequired &&
                (defaultValue == null || defaultValue == '')) ? true : false,
        });
    }
    checkShipAndCloseDisabled = (param_changed_orderObj) => {
        let isShipAndCloseDisable = false;
        let isMarkAsCompleteDisable = false;
        if (
            param_changed_orderObj.ShipTo.CompanyName == '' || param_changed_orderObj.ShipTo.CompanyName == null
            || param_changed_orderObj.ShipTo.Address1 == '' || param_changed_orderObj.ShipTo.Address1 == null
            || param_changed_orderObj.ShipTo.City == '' || param_changed_orderObj.ShipTo.City == null
            || param_changed_orderObj.ShipTo.Zip == '' || param_changed_orderObj.ShipTo.Zip == null
            || param_changed_orderObj.ShipTo.Country == '' || param_changed_orderObj.ShipTo.Country == null
        ) {
            isShipAndCloseDisable = true;
            isMarkAsCompleteDisable = true;
        }

        if (this.state.selectedCustomer.options.requireTrackingNumber && !param_changed_orderObj.RoutingInfo.TrackingNumber) {
            isShipAndCloseDisable = true;
        }

        return {
            isShipAndCloseDisable: isShipAndCloseDisable,
            isMarkAsCompleteDisable: isMarkAsCompleteDisable
         };
    }
    onItemAllocationChanged = (param_allocationobj) => {
        var tmpIsShowDiscardBtn =  this.state.showDiscard;
        if(param_allocationobj.sawDiscardbutton){
            tmpIsShowDiscardBtn = param_allocationobj.sawDiscardbutton;
        }
        this.setState({
            isFullyAllocated: param_allocationobj.isFullyAllocated,
            allocatedSkuList: param_allocationobj.allocatedSkuList,
            showDiscard:tmpIsShowDiscardBtn
        }, this.updateInitialPrintMenu);
    }
    masterValidation = (saveStep, saveMethod) => {
        var returnObject = {
            "save": {
                "valid": true,
                error: {
                    "required": [],
                    "invalid": []
                }
            },
            "shipClose": {
                "valid": true,
                error: {
                    "required": [],
                    "invalid": []
                }
            },
            "saveRecalculate": {
                "valid": true,
                error: {
                    "required": [],
                    "invalid": []
                }
            },
            "readyToShip": {
                "valid": true,
                error: {
                    "required": [],
                    "invalid": []
                }
            }
        };
        if (saveStep === "Initial") {
            if (!this.state.shareOrderData.ReferenceNum) {
                returnObject.save.valid = false;
                returnObject.save.error.required.push({
                    Field: this.state.referenceIDText
                });
            }
        } else if (saveStep === 'After') {
            let billingChargesData = this.state.sharedOptions.BillingCharges == null ? [] : this.state.sharedOptions.BillingCharges;
            // appliedCharge Items Validation
            for (var x = 0; x < billingChargesData.length; x++) {
                if (billingChargesData[x].ChargeOrigin == 0 && StringWms.isEmpty(billingChargesData[x].NumberOfUnits)) {
                    returnObject.shipClose.valid = false;
                    returnObject.save.valid = false;
                    returnObject.saveRecalculate.valid = false;
                    returnObject.readyToShip.valid = false;

                    returnObject.shipClose.error.required.push({
                        Field: this.NumberOfUnitsText
                    });
                    returnObject.save.error.required.push({
                        Field: this.NumberOfUnitsText
                    });
                    returnObject.saveRecalculate.error.required.push({
                        Field: this.NumberOfUnitsText
                    });
                    returnObject.readyToShip.error.required.push({
                        Field: this.NumberOfUnitsText
                    });
                }
            }

            // EmailAddress validation
            /*if (typeof $scope.ManageOrders != 'undefined' && !$scope.ManageOrders.validateShipToEmail.$valid) {
                returnObject.readyToShip.valid = false;
                returnObject.shipClose.valid = false;
                returnObject.save.valid = false;
                returnObject.saveRecalculate.valid = false;

                returnObject.readyToShip.error.invalid.push({
                    Field: EmailValidationText
                });
                returnObject.shipClose.error.invalid.push({
                    Field: EmailValidationText
                });
                returnObject.save.error.invalid.push({
                    Field: EmailValidationText
                });
                returnObject.saveRecalculate.error.invalid.push({
                    Field: EmailValidationText
                });
            }*/

            if (!this.state.shareOrderData.ReferenceNum) {
                returnObject.readyToShip.valid = false;
                returnObject.shipClose.valid = false;
                returnObject.save.valid = false;
                returnObject.saveRecalculate.valid = false;

                returnObject.readyToShip.error.required.push({
                    Field: this.state.referenceIDText
                });
                returnObject.shipClose.error.required.push({
                    Field: this.state.referenceIDText
                });
                returnObject.save.error.required.push({
                    Field: this.state.referenceIDText
                });
                returnObject.saveRecalculate.error.required.push({
                    Field: this.state.referenceIDText
                });
            }
        }
        if (returnObject[saveMethod].valid) {
            return true;
        } else {
            this.masterHandleValidationErrors(saveMethod, returnObject);
        }
    }
    masterHandleValidationErrors = (saveMethod, returnObject) => {
        var finalString = '';
        var requiredErrorArray = returnObject[saveMethod].error.required;
        var invalidErrorArray = returnObject[saveMethod].error.invalid;
        var RequiredValidationText = this.translate('Label_Required_Notifiaction');
        var InvalidValidationText = this.translate('Label_Notification_Invalid');

        if (requiredErrorArray.length > 0) {
            finalString += '<strong>' + RequiredValidationText + ':</strong>' + '<br />';
            for (var i = 0; i < requiredErrorArray.length; i++) {
                finalString += requiredErrorArray[i].Field + '<br />';
            }
        }

        if (invalidErrorArray.length > 0) {
            finalString += '<strong>' + InvalidValidationText + ':</strong>' + '<br />';
            for (var index = 0; index < invalidErrorArray.length; index++) {
                finalString += invalidErrorArray[index].Field + '<br />';
            }
        }
        GlobalService.notification('validation', finalString);

    }
    // start code for advance contact look up modal which used in conatct detail section
    onOpenAdvanceLookupModal = (param_contact_type) => {
        this.setState({
            sharedContactType: param_contact_type,
            sharedIsAdvanceContactModal: true
        });
    }
    onCloseLookUpModal = () => {
        this.setState({ sharedIsAdvanceContactModal: false });
    }

    onContactSelect = (param_contobj) => {
        this.onCloseLookUpModal();
        let propsOrderObj = Object.assign({},this.state.shareOrderData);
        propsOrderObj[param_contobj.contactType] = param_contobj.selectedContact;
        this.onRegisterContactSet(param_contobj.selectedContact,param_contobj.contactType);
        this.onOrderDataSet(propsOrderObj);
    }
    // End code for advance contact look up modal which used in conatct detail section

    // Start Code  For create manual charge  modal which used in ManageOrdersCalculateCharges section

    onOpenCreateManualChargeModal = (paran_modal_data) => {
        this.setState({
            manualChargeOptions: paran_modal_data.chargesOptions,
            isOpenManualChargeModal: true
        });
    }

    onCloseCreateManualChargeModal = () => {
        this.setState({
            isOpenManualChargeModal: false
        });
    }


    addOrderChargesGrid = (data) => {
        let billingChargesData = this.state.sharedOptions.BillingCharges == null ? [] : this.state.sharedOptions.BillingCharges;

        var ManualCharge = {
            "Action": "a",
            "ChargeOrigin": 2,
            "ChargeLabel": data.ChargeLabel,
            "ChargeTypeId": data.ChargeTypeId,
            "ChargeTypeDescription": data.ChargeType,
            "UnitDescription": data.Description,
            "ChargePerUnit": data.ChargePerUnit,
            "NumberOfUnits": data.NumberOfUnits,
            "TotalCharges": data.TotalCharges,
            "GeneralLedgerAccount": data.GeneralLedgerAccount,
            "ExternalAccountsReceivable": data.ExternalAccountsReceivable,
            "ExternalItemIdentifier": data.ExternalItemIdentifier,
            "ExternalItemDescription": data.ExternalItemDescription,
            "SKU": "",
            "Notes": "",
            "UnsavedManualCharge": true,
            "SystemGenerated": false
        };

        billingChargesData.push(ManualCharge);
        billingChargesData = ObjectWms.removeObjFromListOnParameterValue(billingChargesData, "SystemGenerated", true);

        this.setState({
            sharedOptions: Object.assign(
                {},
                this.state.sharedOptions,
                {
                    BillingCharges: billingChargesData
                }
            ),
            showDiscard: true
        });

    }
    saveAndAddOrderCharges = () => {

        manageOrderServices.recalculateOrderCharges(this.state.shareOrderData.OrderId).then((response) => {
            if (response) {
                if (response.status == 200) {
                    GlobalService.notification('success', this.translate('Label_Success_recalculateCharges'));

                    // if ($scope.CreateOrderClicked == true) {
                    //     $scope.Nav.Notifications("success", {
                    //         title: 'Success',
                    //         message: $scope.Label_Success_AddAutoCharges,
                    //         detail: ''
                    //     });
                    //     $scope.CreateOrderClicked = false;
                    // } else {
                    //     $scope.Nav.Notifications("success", {
                    //         title: 'Success',
                    //         message: $filter('translate')('Label_Success_recalculateCharges'),
                    //         detail: ''
                    //     });
                    // }
                    // $scope.ManageOrders.$setPristine();
                    let billingChargesData = [];
                    response.data.forEach((element, key) => {
                        billingChargesData.push({
                            "Action": "a",
                            "ChargeOrigin": element.ChargeOrigin,
                            "ChargeLabel": element.ChargeLabel,
                            "ChargeTypeId": element.ChargeTypeId,
                            "ChargeTypeDescription": element.ChargeTypeDescription,
                            "UnitDescription": element.UnitDescription,
                            "ChargePerUnit": element.ChargePerUnit,
                            "NumberOfUnits": element.NumberOfUnits,
                            "TotalCharges": element.TotalCharges,
                            "GeneralLedgerAccount": element.GeneralLedgerAccount,
                            "ExternalAccountsReceivable": element.ExternalAccountsReceivable,
                            "ExternalItemIdentifier": element.ExternalItemIdentifier,
                            "ExternalItemDescription": element.ExternalItemDescription,
                            "SKU": element.SKU,
                            "Notes": element.Notes,
                            "UnsavedManualCharge": false,
                            "SystemGenerated": element.SystemGenerated
                        });

                    });
                    this.setState({
                        sharedOptions: Object.assign(
                            {},
                            this.state.sharedOptions,
                            {
                                BillingCharges: billingChargesData
                            }
                        )
                    });
                }
            }
            this.setState({ sharedRecalculateGif: false });
        }).finally(() => {
            this.setState({ sharedRecalculateGif: false });
        });
    }
    // End Code  For create manual charge  modal which used in ManageOrdersCalculateCharges section

    // Start code for custom order info modal which is used in ManageOrdersCustomOrderInfo
    onOpenModalAddCustomField = () => {
        this.setState({ sharedIsOpenAddCustomFieldModal: true });
    }

    onCloseAddCustomField = () => {
        this.setState({ sharedIsOpenAddCustomFieldModal: false });
    }
    // End code for custom order info modal which is used in ManageOrdersCustomOrderInfo

    // Start code for manage allocation modal which is used in ManageOrdersLineItem
    onOpenManageAllocationModal = (param_obj) => {
        this.setState({
            sharedIsOpenManageAllocation: param_obj.isModalOpen,
            sharedEditAllocationData: param_obj.modalSharedData
        });
    }
    onOpenProtoMassAddModal = (param_obj) => {
        this.setState({
            sharedIsOpenProtoMassAdd: param_obj.isModalOpen,
            sharedEditAllocationData: param_obj.modalSharedData
        });
    }
    onCloseProtoMassAdd = () => {
        this.setState({
            sharedIsOpenProtoMassAdd: false
        });
    }
    onCloseManageAllocationModal = () => {
        this.setState({ sharedIsOpenManageAllocation: false });
    }
    resetGridAndSelection = () => {
        this.setState({
            sharedIsResetLineItemGrid: !this.state.sharedIsResetLineItemGrid,
        });
    }
    // End code for manage allocation modal which is used in ManageOrdersLineItem

    //Start code for add line item model which is used in ManageOrdersLineItem

    onOpenAddEditLineItemModal = (param_obj) => (e) => {
        this.setState({
            sharedSelectedItemObj:param_obj,
            sharedIsOpenAddLineItem: true
         });
    }

    onCloseAddEditLineItemModal = () => {
        this.setState({ sharedIsOpenAddLineItem: false });
    }
    //End code for add line item model which is used in ManageOrdersLineItem
    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.saveOrderClicked();
        }
    }

    onClickExitForm = () => {
        GlobalService.closeWindow(this.windowId);
    }

    onDocumentMouseEnter = (text) => {
        this.setState({
            disabledText: text
        });
    }

    onDocumentMouseLeave = () => {
        this.setState({
            disabledText: ""
        });
    }
    // click on cancel button of bill of landing model
    onCloseBillOfLadingModel = () => {
        this.setState({
            isOpenBillOfLadingModel: false
        });
    }
    onRegisterContactSet = (contact_obj,contact_type) => {
        if(contact_type == 'ShipTo'){
            this.originalShipToContact = contact_obj;
        }else if(contact_type == 'BillTo'){
            this.originalBillToContact = contact_obj;
        }else if(contact_type == 'SoldTo'){
            this.originalSoldToContact = contact_obj;
        }
    }
    onSaveAndExit = () => {
        this.saveOrderClicked("FromAreYouSureModal");
    }
    onDiscardAndExit = () => {
        this.setState({
            isOpenConfirmModel:false
        });
        setTimeout(() => {
        this.$rootScope.dirtyWindow[this.windowId] = false;
        this.onClickExitForm();
        // remove event listner on close window
        var eventName = "onDirtyWindowCloseOrderPrototype" + this.windowId;
        document.removeEventListener(eventName, this.openConfirmModal);
        }, 100);

    }
    onCloseFunc = () => {
        this.setState({
            isOpenConfirmModel:false
        });
    }
    onGridItemChange = () => {
        this.setState({
            isLineItemChanges : true
        });
    }
    trimRefNo = (e) => {
        let val = e.target.value;
        this.setState({
            shareOrderData: Object.assign(
                {}, this.state.shareOrderData,
                { ReferenceNum: val.trim() }
            )
        });
    }
    render() {
        if(this.windowId){
            this.$rootScope.dirtyWindow[this.windowId] = this.state.showDiscard;
        }
        var currentCustomerId = this.state.selectedCustomerId;
        let sharedDataObjCopy = _.cloneDeep(this.state.shareOrderData);

        return (
            <div id="wms-manageorder-proto" className={
                ((typeof this.props.windowId == 'undefined') ?
                    "manage-order-proto-holder-newtab" :
                    '') + " manage-order-proto-holder "
            }>

                <div className="wms-top-toolbar-wrapper">
                    <div className="wms-tab-header-wrapper" ref={this.tabHeaderRef}>
                        <div className="message-sections">
                            {this.state.disabledText != "" && (
                                <label className="message-txt">{this.state.disabledText}</label>
                            )}
                        </div>
                        <div className="form_clause">
                            {this.state.showRemainForm == true && (
                                <WmsTab sectionRefs={this.state.sectionRefs} scrollAreaSelector={this.scrollDomSelector} tabHeaderRef={this.tabHeaderRef} windowId={this.windowId} extraTopPadding={26}/>
                            )}

                            <div className="wms-top-toolbar-btn-wrapper">
                                <WmsButton
                                    label={this.translate('Label_Cancel')}
                                    wmsSelector={this.state.selector + 'ButtonExit'}
                                    onClickFunc={
                                        (typeof this.props.onExitOrderForm == 'undefined') ?
                                            this.onClickExitForm :
                                            this.props.onExitOrderForm
                                    }
                                    icon={'fa-times-circle-o'}
                                    // icon={this.state.showDiscard == false ? 'fa-sign-out-alt' : 'fa-times-circle'}
                                    buttonType="orange"
                                    parentClassName="manage-order-exit-btn" />
                                {this.state.shareOrderData.OrderItemsCount > 0 && (this.state.shareOrderData.OrderId == 0 || this.state.shareOrderData.CanFinalize == true) &&
                                    <WmsButton
                                        label={this.state.orderReadyToShipLabel}
                                        wmsSelector={this.state.selector + 'ShipAndCloseButton'}
                                        onClickFunc={(e) => this.saveOrderClicked('CompleteOrder')}
                                        icon={'far fa-check-square'}
                                        buttonType="yellow"
                                        disabled={(
                                            GlobalService.GetCurrentUser().CustomerIds.length > 0 && this.state.isMarkAsCompleteDisable
                                        )}
                                        showToolTip={true}
                                        tooltipText={ (
                                            GlobalService.GetCurrentUser().CustomerIds.length > 0 && this.state.isMarkAsCompleteDisable
                                        ) ? this.translate('Label_shipping_required_Mark_As_Complete') : ''}
                                        loading={this.state.readyToShipGif} />
                                }
                                {this.state.showRemainForm == true && (this.state.Rights.hasOwnProperty('orders.confirm')) &&
                                    <WmsButton
                                        label={this.translate('Label_Ship_And_Close_Order')}
                                        wmsSelector={this.state.selector + 'ShipAndClose'}
                                        onClickFunc={this.shipAndCloseClick}
                                        icon={'fa-truck'}
                                        showToolTip={true}
                                        disabled={(
                                            this.state.shareOrderData.OrderItemsCount == 0 ||
                                            !this.state.isFullyAllocated ||
                                            this.state.isShipAndCloseDisable ||
                                            this.state.isEmptycustomerOrderInfo ||
                                            this.state.shipAndCloseGif) &&
                                            (
                                                this.state.Rights.hasOwnProperty('orders.confirm') ? true : false
                                            )}
                                        tooltipText={
                                            (this.state.shareOrderData.OrderItemsCount == 0 ?
                                                this.translate('Label_Order_LineItem_Required') :
                                                (this.state.isEmptycustomerOrderInfo ?
                                                    this.translate('Label_Order_CustomOrder_Required') :
                                                    (!this.state.isFullyAllocated ?
                                                        this.translate('Label_Fullyallocated_Required') :
                                                        (this.state.isShipAndCloseDisable ?
                                                            this.translate('Label_shipping_required_Ship_And_Close') + ((this.state.selectedCustomer.options.requireTrackingNumber) ? " and Tracking Number" : "")
                                                            : ""
                                                        )
                                                    )
                                                )
                                            )
                                        }
                                        buttonType="green"
                                        loading={this.state.shipAndCloseGif} />
                                }
                                <WmsButton
                                    label={this.translate('Label_Save')}
                                    wmsSelector={this.state.selector + 'SaveButton'}
                                    onClickFunc={(e) => this.saveOrderClicked()}
                                    showToolTip={true}
                                    tooltipText={
                                        this.state.isCustomerUserAndOrderComplete ?
                                            this.translate('note_cant_save_customer_user') :
                                            (
                                                !this.state.showDiscard ?
                                                this.translate('Tooltip_Save_Disabled') :
                                                this.translate('Tooltip_Required_Fields')
                                            )
                                    }
                                    disabled={this.state.selectedCustomerId == "" || this.state.selectedFacility == '' || this.state.shareOrderData.ReferenceNum == '' || this.state.saveLoader || this.state.isCustomerUserAndOrderComplete || !this.state.showDiscard}
                                    loading={this.state.saveLoader}
                                    icon='fa-solid fa-floppy-disk' />
                                {(  this.state.Rights.hasOwnProperty('orders.docs') ||
                                ( this.state.Rights.hasOwnProperty('orders.attachdocs') && this.state.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled') )
                                ) && this.state.MenuPrintData.length > 0 && this.state.showRemainForm == true && (
                                    <WmsButtonDropdown
                                        id={'MenuPrintTicket'}
                                        windowId={this.windowId}
                                        label={this.translate('NavBarV1_Btn_Documents')}
                                        wmsSelector={this.state.selector + 'DocumentOptions'}
                                        menuIcon={'fa-print'}
                                        disabled={this.state.showDiscard}
                                        buttonType={'green'}>
                                        <WmsMenuItem items={this.state.MenuPrintData} eventMouseLeave={this.onDocumentMouseLeave} eventMouseEnter={this.onDocumentMouseEnter} />
                                    </WmsButtonDropdown>

                                )}
                            </div>

                            <WmsCollapse
                                id={'manageOrderInformation'}
                                headerLabel={this.translate('Label_Order_Information')}
                                showCollapseText={false}
                            >
                                <div className="order-info-main-wrapper">
                                    {this.$rootScope.ChildBulletin.Data.isCloneOrder ? (
                                        <WmsInput
                                            id='cloneOrderCustomer'
                                            inputName="cloneOrderCustomer"
                                            wmsSelector={this.state.selector + "SelectCustomer"}
                                            value={this.state.selectedCustomer.name}
                                            onChangeFunc={(e) => { }}
                                            label={this.state.customLabels.Label_Customer}
                                            disabled={true}
                                            extraWrapperClass="customer-options"/>
                                    ) : (
                                        <WmsDropdown
                                            id="selectedCustomer"
                                            name="selectedCustomer"
                                            label={this.state.customLabels.Label_Customer}
                                            wmsSelector={this.state.selector + "SelectCustomer"}
                                            value={this.state.selectedCustomerId}
                                            onChangeFunc={this.indexOptionsCustomerChange}
                                            required={true}
                                            options={this.state.optionsCustomer}
                                            valueField="id"
                                            textField="name"
                                            disabled={this.state.showRemainForm}
                                            blankFirstOption={true}
                                            extraWrapperClass="customer-options"
                                        />
                                    )}
                                    {this.$rootScope.ChildBulletin.Data.isCloneOrder ? (
                                        <WmsInput
                                            id='cloneOrderFacility'
                                            inputName="cloneOrderFacility"
                                            wmsSelector={this.state.selector + "SelectedFacility"}
                                            value={this.state.sharedSelectedFacilityObj.name}
                                            onChangeFunc={(e) => { }}
                                            label={this.state.customLabels.Label_Warehouse}
                                            disabled={true}
                                            extraWrapperClass="facility-options" />
                                    ) : (
                                        <WmsDropdown
                                            id="selectedFacility"
                                            name="selectedFacility"
                                            label={this.state.customLabels.Label_Warehouse}
                                            wmsSelector={this.state.selector + "SelectedFacility"}
                                            value={this.state.selectedFacility}
                                            onChangeFunc={this.handleFaciltiySelectChange}
                                            required={true}
                                            options={this.state.optionsFacility}
                                            textField='name'
                                            valueField='id'
                                            disabled={this.state.showRemainForm}
                                            blankFirstOption={true}
                                            extraWrapperClass="facility-options"
                                        />
                                    )}
                                    <WmsInput
                                        id='ReferenceId'
                                        inputName="ReferenceNum"
                                        wmsSelector={this.state.selector + 'ReferenceId'}
                                        value={this.state.shareOrderData.ReferenceNum}
                                        // required={true}
                                        onKeyPressFunc={this.handleKeyPress}
                                        onChangeFunc={(e) => this.handleChangeForObject('ReferenceNum', e)}
                                        label={this.translate('Orders_Lbl_Reference_Num')}
                                        regularExp={/^[^.\s]/}
                                        extraWrapperClass="reference-number-input"
                                        showToolTip={true}
                                        onBlurFunc={ this.trimRefNo}
                                        tooltipText={this.translate('Orders_Lbl_Reference_Num_tooltip')} />

                                    {this.state.isSaved &&
                                            <WmsInput
                                                id='transactionId'
                                                inputName="transactionId"
                                                wmsSelector={this.state.selector + "TransactionId"}
                                                disabled={true}
                                                value={this.state.shareOrderData.OrderId}
                                                onChangeFunc={(e) => function () { }}
                                                label={this.translate('Orders_Lbl_Transaction_Id')}
                                                extraWrapperClass="transaction-input"/>
                                    }
                                    {this.state.isSaved && currentCustomerId > 0 && (
                                        <WmsComboBox
                                            id={"purchaseOrderNumCombobox" + this.windowId}
                                            name="purchaseOrderNum"
                                            label={this.translate('Label_Purchase_Order')}
                                            isVirtualOptions={true}
                                            optionsLabel={this.translate('Label_Purchase_Order')}
                                            wmsSelector={this.state.selector + "PurchaseOrderNum"}
                                            value={this.state.shareOrderData.PoNum}
                                            onChangeFunc={(e) => this.handleChangeForObject('PoNum', e)}
                                            textField='Name'
                                            valueField='Name'
                                            virtualObj={{
                                                itemHeight: 26,
                                                valueMapper: function (options) {
                                                    var that = options;
                                                    $.ajax({
                                                        url: "/WebUI/Shipping/Orders/PurchaseOrderDropdownIndexRead",
                                                        type: "POST",
                                                        dataType: "json",
                                                        data: {
                                                            poNum: that.value || "",
                                                            customerId: parseInt(currentCustomerId)
                                                        },
                                                        success: function (data) {
                                                            options.success(data);
                                                        }
                                                    });
                                                }
                                            }}
                                            dataSourceObj={{
                                                pageSize: 40,
                                                serverPaging: true,
                                                serverFiltering: true,
                                                type: "aspnetmvc-ajax",
                                                transport: {
                                                    read: {
                                                        url: "/WebUI/Shipping/Orders/PurchaseOrderDropdownRead",
                                                        type: "POST",
                                                        data: {
                                                            customerId: parseInt(currentCustomerId)
                                                        }
                                                    },
                                                },
                                                schema: {
                                                    data: "Data",
                                                    total: "Total"
                                                }
                                            }}
                                            parentClassName="purchase-order-option" />
                                    )}
                                    {this.state.isSaved &&
                                        <WmsCustomDateTimePickerProto
                                            id={'DatePickerEarliestShipDate'}
                                            name='DatePickerEarliestShipDate'
                                            wmsSelector={this.state.selector + 'DatePickerEarliestShipDate'}
                                            label={this.translate('Label_Earliest_Ship_Date')}
                                            onChangeFunc={(e) => this.handleChangeForObject('EarliestShipDate', e)}
                                            value={this.state.shareOrderData.EarliestShipDate}
                                            isDateParseInCustomFormat={true}
                                            windowId={this.windowId}
                                            defaultTime={'12:00:00'}
                                        />
                                    }
                                    {this.state.isSaved &&
                                        <WmsCustomDateTimePickerProto
                                            id={'ShipCancelDate_id'}
                                            name='ShipCancelDate'
                                            wmsSelector={this.state.selector + 'DatePickerShipCancelDate'}
                                            label={this.translate('Label_Ship_Cancel_Date')}
                                            onChangeFunc={(e) => this.handleChangeForObject('ShipCancelDate', e)}
                                            value={this.state.shareOrderData.ShipCancelDate}
                                            isDateParseInCustomFormat={true}
                                            windowId={this.windowId}
                                            defaultTime={'12:00:00'}
                                        />
                                    }
                                </div>
                            </WmsCollapse>

                        </div>
                    </div>

                    {this.state.showRemainForm == true && (
                        <div id="ManageOrdersScrollBox" className={this.windowId + " tab-content"}>
                            <ManageOrdersContactDetails
                                divRefs={this.contactDetailsRef}
                                windowId={this.windowId}
                                selectedCustomer={this.state.selectedCustomer}
                                shareOrderData={sharedDataObjCopy}
                                sharedOptions={this.state.sharedOptions}
                                onOrderDataSet={this.onOrderDataSet}
                                onOpenAdvanceLookupModal={this.onOpenAdvanceLookupModal}
                                metaDataList={this.state.metaDataList}
                                isPrototypeComponent={true}
                                onRegisterContactSet={this.onRegisterContactSet}
                            />

                            <ManageOrdersLineItem
                                windowId={this.windowId}
                                divRefs={this.lineItemRef}
                                sharedSelectedCustomer={this.state.selectedCustomer}
                                sharedSelectedFacility={this.state.sharedSelectedFacilityObj}
                                shareOrderData={sharedDataObjCopy}
                                sharedOptions={this.state.sharedOptions}
                                onItemAllocationChanged={this.onItemAllocationChanged}
                                onOrderDataSet={this.onOrderDataSet}
                                onSetOrderItemChange={this.onSetOrderItemChange}
                                onOpenManageAllocationModal={this.onOpenManageAllocationModal}
                                onOpenProtoMassAddModal={this.onOpenProtoMassAddModal}
                                onOpenAddEditLineItemModal={this.onOpenAddEditLineItemModal}
                                sharedIsResetLineItemGrid={this.state.sharedIsResetLineItemGrid}
                                metaDataList={this.state.metaDataList}
                                isPrototypeComponent={true}
                                isCustomerUserAndOrderComplete={this.state.isCustomerUserAndOrderComplete}
                                onGridItemChange={this.onGridItemChange}
                            />

                            <ManageOrdersCarrierRoutingInfo
                                windowId={this.windowId}
                                divRefs={this.carrierRoutingRef}
                                shareOrderData={sharedDataObjCopy}
                                sharedOptions={this.state.sharedOptions}
                                onOrderDataSet={this.onOrderDataSet}
                                metaDataList={this.state.metaDataList}
                                isPrototypeComponent={true}
                                sharedSelectedCustomer={this.state.selectedCustomer}
                            />

                            {/* REMAINING
                                ----------------
                                [ Custom order info grid currently Name & Value fields are editable
                                , Expect - only value field as editable ]

                                */}
                            {this.state.showCustomerSavedElements == true && (
                                <ManageOrdersCustomOrderInfo
                                    windowId={this.windowId}
                                    divRefs={this.customOrderInfoRef}
                                    shareOrderData={sharedDataObjCopy}
                                    sharedOptions={this.state.sharedOptions}
                                    onOpenModalAddCustomField={this.onOpenModalAddCustomField}
                                    onSharedOptionDataSet={this.onSharedOptionDataSet}
                                    metaDataList={this.state.metaDataList}
                                    isPrototypeComponent={true}
                                    onCustomOrderInfoSave={this.onCustomOrderInfoSave}
                                />
                            )}

                            {this.state.Rights.hasOwnProperty('orders.charges.manage') && (
                                <ManageOrdersCalculateCharges
                                    windowId={this.windowId}
                                    divRefs={this.appliedChargesRef}
                                    sharedOptions={this.state.sharedOptions}
                                    orderItemsCount={sharedDataObjCopy.OrderItemsCount}
                                    onSharedOptionDataSet={this.onSharedOptionDataSet}
                                    onSaveOrderClicked={(e) => this.saveOrderClicked('CalculateCharges')}
                                    sharedRecalculateGif={this.state.sharedRecalculateGif}
                                    onOpenCreateManualChargeModal={this.onOpenCreateManualChargeModal}
                                    onCreateChargeModalOpenToggle={this.onCreateChargeModalOpenToggle}
                                    metaDataList={this.state.metaDataList}
                                    isPrototypeComponent={true}
                                    isCustomerUserAndOrderComplete={this.state.isCustomerUserAndOrderComplete}
                                />
                            )}
                        </div>
                    )}

                        {this.state.isOpenAttachDoc &&
                            <AttachDocuments
                                isOpenAttachDoc={this.state.isOpenAttachDoc}
                                sharedAttachObj={this.state.sharedAttachObj}
                                windowId={this.windowId}
                                onClose={this.closeAttachDocuments}
                            />
                        }
                        {this.state.isOpenPackingSlipsModel && (
                            <PackingSlipsModel
                                sharedIsOpenPackingSlipsModel={this.state.isOpenPackingSlipsModel}
                                windowId={this.windowId}
                                sharedSelectedOrderIds={[this.state.shareOrderData.OrderId]}
                                sharedSelectedOrderData={this.state.packingSlipsData}
                                onClose={this.closePackingslipsModel}
                            />
                        )}
                        {this.state.isOpenSendAsnModel && (
                            <SendAsnModel
                                sharedIsOpenSendAsnModel={this.state.isOpenSendAsnModel}
                                windowId={this.windowId}
                                sharedSelectedOrderIds={[this.state.shareOrderData.OrderId]}
                                sharedSelectedOrderData={this.state.sendAsnData}
                                sharedScacCodeAvailable={this.state.scacCodeAvailable}
                                onResetGridSelection={() => this.orderGridRead({ "action": "update" })}
                                onClose={this.closeSendAsnModel}
                            />
                        )}
                        {this.state.isOpenPickTicketModel && (
                            <PickTicketModel
                                id="pickTicketModel"
                                title={this.translate('Label_Pick_Ticket')}
                                width={600}
                                height={this.state.pickticketHeight}
                                sharedIsOpenPickTicketModel={this.state.isOpenPickTicketModel}
                                customerId={(this.state.shareOrderData != null) ? this.state.shareOrderData.CustomerId : 0}
                                sharedSelectedOrderIds={[this.state.shareOrderData.OrderId]}
                                batchOrderId={this.state.pickticketBatchId}
                                flow={this.state.pickticketFlow}
                                onClose={this.closePickTicketModel}
                                windowId={this.windowId}
                                fullyAllocated={this.state.isFullyAllocated}
                                sharedSelectedCustomerIds={this.state.sharedCustomerId}
                            />
                        )}
                        {this.state.isOpenEditBolTemplateUI && (
                            <EditBolOptionModel
                                sharedTemplateOption = {this.state.bolTemplateOptions}
                                onOpenEditBolClassicPage={this.openEditBolClassicPage}
                                onCloseEditBolOptionsModel={this.onCloseEditBolOptionsModel}/>
                        )}
                        {this.state.isOpenBillOfLadingModel && (
                            <BillOfLadingModel
                                windowId={this.props.windowId}
                                isOpenLadingModel={this.state.isOpenBillOfLadingModel}
                                onCloseFunc={this.onCloseBillOfLadingModel}
                                orderId={this.state.shareOrderData.OrderId}
                                customerId={(this.state.shareOrderData != null) ? this.state.shareOrderData.CustomerId : 0}
                            />
                        )}
                        {this.state.isOpenManageShipAndCloseModal && (
                            <ShipAndCloseModal
                                sharedIsOpenManageShipAndCloseModal={this.state.isOpenManageShipAndCloseModal}
                                windowId={this.windowId}
                                onCloseShipAndClose={this.onCloseShipAndClose}
                                shareOrderData={this.state.shareOrderData}
                                onOrderGridRead={this.orderGridRead}
                                onExitOrderForm={this.props.onExitOrderForm}
                            />
                        )}
                        {/* Advance lookup modal for order contact details section */}
                        {this.state.sharedIsAdvanceContactModal && (
                            <AdvancedContactLookupModal
                                sharedIsAdvanceContactModal={this.state.sharedIsAdvanceContactModal}
                                selectedCustomer={this.state.selectedCustomer}
                                sharedContactType={this.state.sharedContactType}
                                onContactSelect={this.onContactSelect}
                                onCloseLookUpModal={this.onCloseLookUpModal}
                                windowId={this.windowId} />
                        )}
                        <div>
                            <WmsConfirmModel
                                id={"ConfirmModel" + this.windowId}
                                isOpen={this.state.isOpenConfirmModel}
                                type={this.translate('Label_Confirmation')}
                                width={550}
                                height={175}
                                firstBtnLabel={this.translate('Label_Save_and_Exit')}
                                firstBtnIcon={"fa-check-square"}
                                firstBtnOnClickFunc={this.onSaveAndExit}
                                sawCheckBox={false}
                                extraWrapperClass={'wms-confirm-text-center'}
                                conFormModelText={this.translate('Label_are_you_sure_close_order')}
                                onCloseFunc={this.onCloseFunc}
                                thirdBtnLabel={this.translate('Label_Cancel')}
                                thirdBtnIcon={"fa-times-circle"}
                                thirdButtonType={"orange"}
                                isSecondBtnSaw={true}
                                secondFunctionClick={this.onDiscardAndExit}
                                secondBtnLabel={this.translate('Label_Discard_Exit')}
                                secondBtnIcon={"fa-times-circle"}
                            />
                        </div>
                        {/* Manage alloaction modal for order line item section */}
                        {this.state.sharedIsOpenManageAllocation &&
                        <ManageAllocationModal
                            sharedIsOpenManageAllocation={this.state.sharedIsOpenManageAllocation}
                            onCloseEditAllocaion={this.onCloseManageAllocationModal}
                            sharedEditAllocationData={this.state.sharedEditAllocationData}
                            onResetGridAndSelection={this.resetGridAndSelection}
                            windowId={this.windowId}
                            onGridItemChange={this.onGridItemChange}
                        />
                        }
                        {this.state.sharedIsOpenProtoMassAdd &&
                            <ProtoMassAddModel
                                isOpenLadingModel={this.state.sharedIsOpenProtoMassAdd}
                                onCloseFunc={this.onCloseProtoMassAdd}
                                sharedEditAllocationData={this.state.sharedEditAllocationData}
                                onResetGridAndSelection={this.resetGridAndSelection}
                                onGridItemChange={this.onGridItemChange}
                            />
                        }
                        {this.state.sharedIsOpenAddLineItem &&
                            <AddEditLineItemModal
                                sharedIsOpenAddLineItem={this.state.sharedIsOpenAddLineItem}
                                onCloseAddEditLineItemModal={this.onCloseAddEditLineItemModal}
                                onResetGridAndSelection={this.resetGridAndSelection}
                                windowId={this.windowId}
                                sharedSelectedCustomer={this.state.selectedCustomer}
                                sharedSelectedFacility={this.state.selectedFacility}
                                sharedOptions={this.state.sharedOptions}
                                sharedSelectedItemObj={this.state.sharedSelectedItemObj}
                                shareOrderData={this.state.shareOrderData}
                                onItemAllocationChanged={this.onItemAllocationChanged}
                                onGridItemChange={this.onGridItemChange}
                            />
                        }
                        {/* custom order info modal for custom order info section  */}
                        {this.state.sharedIsOpenAddCustomFieldModal && (
                        <CustomOrderInfoModal
                            sharedIsOpenAddCustomFieldModal={this.state.sharedIsOpenAddCustomFieldModal}
                            sharedOptions={this.state.sharedOptions}
                            onSharedOptionDataSet={this.onSharedOptionDataSet}
                            onCloseAddCustomField={this.onCloseAddCustomField}
                            windowId={this.windowId}
                        />
                        )}
                        {/* create manual charge modal for orderCharges */}
                        {this.state.isOpenManualChargeModal && (
                        <CreateManualChargeModel
                            sharedIsOpenManualChargeModal={this.state.isOpenManualChargeModal}
                            sharedManualChargeOptions={this.state.manualChargeOptions}
                            onCloseAddManualChargeModel={this.onCloseCreateManualChargeModal}
                            onAddShipAndCloseGridData={this.addOrderChargesGrid}
                            isAnotherModelOpen={false}
                            windowId={this.windowId}
                        />
                        )}

                    {this.state.isOpenShippingLabelModel && (
                        <ShippingLabel
                            sharedIsOpenShippingLabelModel={this.state.isOpenShippingLabelModel}
                            sharedSelectedOrderIds={[this.state.shareOrderData.OrderId]}
                            onCloseShippingLabelModel={this.onCloseShippingLabelModel}
                            sharedShippingLabelOption={this.state.sharedShippingLabelOption}
                            windowId={this.windowId}
                        />
                    )}

                </div>
            </div>
        );
    }
}
