import React from 'react';

//component
import WmsButton from '../WmsButton/WmsButton.jsx';
import WmsCheckBox from '../WmsCheckBox/WmsCheckBox.jsx';

import WmsContainer from '../WmsContainer/WmsContainer.jsx';
import WmsModel from '../WmsModel/WmsModel.jsx';
import './add-edit-columns.scss';

export class AddEditColumnModel extends React.Component {

    constructor(props) {
        super(props);
        
        this.translate = window.translate;

        this.state = {
            Selector: "AddEditColumn",
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.props.id : '#' + this.props.id,
            enableColumns: [],
            isOpenColumnModel: true,
            onOpenColumnList: [],
        };
    }

    componentDidMount(){
        this.getShowColumnList();
    }
    getShowColumnList = () => {
        let enableColumns = [];
        let grid = $('.' + this.props.windowId + ' #' + this.props.gridId).data('kendoGrid');
        let columnList = grid.columns;

        columnList.filter((item) => {
            if (item.hidden !== true) {
                enableColumns.push(item.field);
            }
        });
        this.setState({
            enableColumns: enableColumns,
            onOpenColumnList: JSON.parse(JSON.stringify(enableColumns))
        });
    }
    exitBatchModel = () => {
        this.onCloseColumnModel();
    }
    onCloseColumnModel = () => {
        this.setState({
            isOpenColumnModel: false
        });
        this.props.onCloseColumnModel();
    }
    eventSave = () => {
        // set isReload true if any new column add for show
        var isReload = false;
        var onOpenColumnList = this.state.onOpenColumnList;
        var substractionArr = this.state.enableColumns.filter(n => !onOpenColumnList.includes(n));
        if (substractionArr.length > 0) {
            isReload = true;
        }
        let grid = $('.' + this.props.windowId + ' #' + this.props.gridId).data('kendoGrid');

        var updateColumn = [];
        var enableColumns = this.state.enableColumns;
        var gridActionObject = null;
        grid.columns.filter((column) => {
            if(column.field == 'grid-action'){
                gridActionObject = column;
                var index = enableColumns.indexOf(column.field);
                delete enableColumns[index];
            }
            var enableColumnindex = enableColumns.indexOf(column.field);
            if (enableColumnindex !== -1) {
                updateColumn.push(column);
                delete enableColumns[enableColumnindex];
            }
        });
        this.props.allSortColumnList.filter((column) => {
            if (enableColumns.indexOf(column.field) !== -1) {
                updateColumn.push(column);
            }
        });
        if(gridActionObject){
            updateColumn.push(gridActionObject);
        }
        grid.setOptions({
            columns: updateColumn
        });
        this.onCloseColumnModel();

        if (isReload) {
            this.props.updateGridData();
        }
        this.props.saveChangesInMetaData();

    };
    toggleColumnChange = (id) => (event) => {
        var enableColumns = this.state.enableColumns;

        var idx = this.state.enableColumns.indexOf(id);

        (idx === -1) ? enableColumns.push(id) : enableColumns.splice(idx, 1);

        this.setState({
            enableColumns: enableColumns
        });

    }

    render() {
        let hideColumnsList = '';
        if(this.props.hideColumns){ // get comma saperated column list for hide in list
            hideColumnsList = this.props.hideColumns;
        }
        return (
            <WmsModel
                id={this.props.id}
                title={this.translate('Label_manage_columns')}
                width={1000}
                height={(this.props.height) ? this.props.height : 700}
                class={"addEditColumn"}
                onCloseFunc={this.onCloseColumnModel}
                isAnotherModelOpen={this.props.isAnotherModelOpen}
                isOpen={this.state.isOpenColumnModel}
                customClass={'wms-model-new-ui-wrapper'}>
                
                <div className="model-content-wrapper">
                <WmsContainer>
                    {this.props.allSortColumnList.map((obj, index) => (
                        (obj.field != (this.props.isShawRowNumberColumnInList ? '' : 'RowNumber') && hideColumnsList.indexOf(obj.field) == -1) &&
                        <div key={index} className={"checkbox-container"}>
                            <WmsCheckBox
                                key={index}
                                id={obj.field+"_"+this.props.windowId}
                                name={String(obj.title)}
                                wmsSelector={this.state.Selector + '_' + obj.field}
                                onChangeFunc={this.toggleColumnChange(obj.field)}
                                label={obj.field == "RowNumber" ? "# Row Number" : obj.title}
                                checked={this.state.enableColumns.indexOf(obj.field) !== -1}
                                value=""
                                disabled={obj.disabledColumn}
                                showToolTip={obj.disabledColumn}
                                tooltipText={obj.disabledNote}
                            />
                        </div>
                    ))}
                </WmsContainer>

                </div>
                <div className="footer-btn-holder">
                <WmsButton
                    label={this.translate('Label_Close')}
                    wmsSelector={this.state.Selector + "Exit"}
                    onClickFunc={this.exitBatchModel}
                    disabled={this.state.savingGifBatch}
                    buttonType="orange"
                    icon={'fa-times-circle-o'}
                />
                <WmsButton
                    label={this.translate("Label_Save")}
                    wmsSelector={this.state.Selector + "Save"}
                    onClickFunc={this.eventSave}
                    loading={this.state.savingGifBatch}
                    icon={'fa-solid fa-floppy-disk'}
                    disabled={this.state.enableColumns.length == 0 ||
                            (this.state.enableColumns.length == 1 && ( this.state.enableColumns[0] == "RowNumber" || hideColumnsList.indexOf(this.state.enableColumns[0]) != -1)) ||
                            JSON.stringify(window._.sortBy(this.state.onOpenColumnList)) == JSON.stringify(window._.sortBy(this.state.enableColumns))
                        }
                    showToolTip={this.state.enableColumns.length == 0 || (this.state.enableColumns.length == 1 && (
                            this.state.enableColumns[0] == "RowNumber" ||
                            hideColumnsList.indexOf(this.state.enableColumns[0]) != -1)
                    )}
                    tooltipText={'You need to select atleast one column'}
                />
                </div>
            </WmsModel>
        );
    }
}