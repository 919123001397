import React, { Fragment } from 'react';
import './orderSummary.virtual.scss';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsContainer from '../../components/WmsContainer/WmsContainer.jsx';
import * as manageOrderServices from '../manageOrders/manage-orders.services.jsx';

import * as GlobalService from "../../global_services";
import axios from "axios";

export class OrderSummaryVirtual extends React.Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.$rootScope = window;
        this.optionsDeliveryConfirmationType = {
            "none": "None",
            "delivery": "Delivery",
            "signature": "Signature",
            "adult_signature": "Adult Signature",
            "direct_signature": "Direct Signature"
        };

        this.state = {
            selector: "OrderSummary",
            customerName: "",
            facilityName: "",
            selectedCustomer: {},
            orderDetails: "",
            customLabels: GlobalService.getCustomLabels(),
            OrderId: props.sharedSelectOrderId,
            orderLineItemData: [],
            lineItemGridHeader: StringWms.convertSringToOption(this.translate('Orders_ManageOrders_Line_ItemsGrid_Headers')),
            totalCharges: 0,
            Rights: GlobalService.GetRights()
        }
    }
    componentDidMount() {

        this.initManageOrders();

    }
    componentWillReceiveProps(nextProps) {
        if (typeof nextProps.sharedSelectOrderId != "undefined" && nextProps.sharedSelectOrderId != this.state.OrderId) {
            this.setState({
                OrderId: nextProps.sharedSelectOrderId
            }, this.initManageOrders)
        }
    }

    initManageOrders = () => {
        kendo.ui.progress($('.order-form'), true);
        var orderId = this.state.OrderId;
        manageOrderServices.GetOrderSummary(orderId).then((response) => {
            this.setState({
                orderDetails: response,
                orderLineItemData: response.orderItems,
                customerName: this.getCustomerById(response.customerId).name,
                facilityName: this.getFacilityById(response.facilityId).name,
                selectedCustomer: this.getCustomerById(response.customerId)
            }, () => {
                this.calculateTotalCharge();
                this.loadTabLayout();
            });
        }).finally(() => {
            kendo.ui.progress($('.order-form'), false);
        });
    }
    loadTabLayout = () => {
        $("." + this.props.windowId + " #summaryContactTabStrip").kendoTabStrip(
            {
                animation: {
                    open: {
                        effects: "fadeIn"
                    }
                }
            }
        );
    }
    // sub grid
    detailInit = (e) => {
        $("<div/>").appendTo(e.detailCell).kendoGrid({
            dataSource: {
                data: e.data.SavedElements != null ? e.data.SavedElements : []
            },
            scrollable: false,
            sortable: false,
            pageable: false,
            columns: [
                { field: "Name", title: "Name" },
                { field: "Value", title: "Value" }
            ]
        });
    }
    getCustomerById = (customerId) => {
        return GlobalService.getCustomerList().find((element) => {
            if (element.id == customerId) {
                return element;
            }
        });
    }
    getFacilityById = (warehouseId) => {
        return GlobalService.getFacilityList().find((element) => {
            if (element.id == warehouseId) {
                return element;
            }
        });
    }
    calculateTotalCharge = () => {
        var billingChargesData = this.state.orderDetails.billingCharges ? this.state.orderDetails.billingCharges : [];
        // var total = 0;
        var total = 0;
        billingChargesData.forEach(item => {
            total += item.totalCharges;
        });

        this.setState({ totalCharges: total })
    }
    render() {
        var orderDetails = this.state.orderDetails ? this.state.orderDetails : "";
        var lineItemGridHeader = this.state.lineItemGridHeader;
        var billingChargesData = this.state.orderDetails.billingCharges ? this.state.orderDetails.billingCharges : [];
        var orderSEData = this.state.orderDetails.savedElements ? this.state.orderDetails.savedElements : [];
        var customOrderInfo_GridHeaders = StringWms.convertSringToOption(this.translate('Orders_ManageOrders_CustomOrderHeaders'));
        var CalculateCharges_GridHeaders = StringWms.convertSringToOption(this.translate('Orders_ManageOrders_Grid_Headers_Applied_Charges'));
        var selectedCustomer = this.state.selectedCustomer;

        return (
            <div className="wms-top-toolbar-wrapper order-summary-component">
                <div className="wms-top-toolbar-btn-wrapper">
                    <WmsButton
                        label={this.translate('Label_Exit')}
                        wmsSelector={this.state.selector + 'ButtonExit'}
                        onClickFunc={this.props.onExitSummaryForm}
                        icon={'fa-sign-out-alt'}
                        buttonType="orange"
                        parentClassName="manage-order-summary-exit-btn" />

                    {orderDetails != "" && (
                        <div className="wms-content-wrapper">
                            <WmsContainer>
                                <div className="wms-static-content-wrapper">
                                    <div className="wms-content">
                                        <div className="wms-data-items">
                                            <label>{this.state.customLabels.Label_Customer} :</label> <span>{this.state.customerName}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.state.customLabels.Label_Warehouse} :</label> <span>{this.state.facilityName}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Orders_Lbl_Reference_Num')} :</label> <span>{orderDetails.referenceNum}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Orders_Lbl_Transaction_Id')} :</label> <span>{orderDetails.orderId}</span>
                                        </div>
                                    </div>
                                    <div className="wms-content">
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_PO_Number')} :</label> <span>{orderDetails.poNum}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Earliest_Ship_Date')} :</label> <span>{orderDetails.earliestShipDate}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Ship_Cancel_Date')} :</label> <span>{orderDetails.shipCancelDate}</span>
                                        </div>
                                    </div>
                                </div>

                            </WmsContainer>
                            <div id="summaryContactTabStrip">
                                {/*TAB list */}
                                <ul>
                                    <li className="k-state-active">{this.translate('Label_Shipping_Destination')}</li>
                                    <li>{this.translate('Label_Billing_Information')}</li>
                                    <li>{this.translate('Label_Sold_To_Information')}</li>
                                </ul>
                                {/* TAB 1 Shipping destination */}
                                <div>
                                    <WmsContainer title={this.translate('Label_Contact_Information')}>


                                        <div className="wms-static-content-wrapper">
                                            <div className="wms-content">
                                                <div className="wms-data-items">
                                                    <div className="wms-data-items">
                                                        <label>{this.translate('Label_Contact_Code')} :</label> <span>{orderDetails.shipTo.code}</span>
                                                    </div>
                                                    <div className="wms-data-items">
                                                        <label>{this.translate('Label_Company_Name')} :</label> <span>{orderDetails.shipTo.companyName}</span>
                                                    </div>
                                                    <div className="wms-data-items">
                                                        <label>{this.translate('Label_Receipient')} :</label> <span>{orderDetails.shipTo.name}</span>
                                                    </div>
                                                    <div className="wms-data-items">
                                                        <label>{this.translate('Label_Address_1')} :</label> <span>{orderDetails.shipTo.address1}</span>
                                                    </div>
                                                    <div className="wms-data-items">
                                                        <label>{this.translate('Label_Address_2')} :</label> <span>{orderDetails.shipTo.address2}</span>
                                                    </div>
                                                    <div className="wms-data-items">
                                                        <label>{this.state.customLabels.Label_State} :</label> <span>{orderDetails.shipTo.state}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wms-content">
                                                <div className="wms-data-items">
                                                    <div className="wms-data-items">
                                                        <label>{this.state.customLabels.Label_City} :</label> <span>{orderDetails.shipTo.city}</span>
                                                    </div>
                                                    <div className="wms-data-items">
                                                        <label>{this.translate('Label_Costomer_Postal_Code')} :</label> <span>{orderDetails.shipTo.zip}</span>
                                                    </div>
                                                    <div className="wms-data-items">
                                                        <label>{this.translate('Label_Country')} :</label> <span>{orderDetails.shipTo.country}</span>
                                                    </div>
                                                    <div className="wms-data-items">
                                                        <label>{this.translate('Label_Phone')} :</label> <span>{orderDetails.shipTo.phoneNumber}</span>
                                                    </div>
                                                    <div className="wms-data-items">
                                                        <label>{this.translate('Label_Email')} :</label> <span>{orderDetails.shipTo.emailAddress}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </WmsContainer>
                                    <WmsContainer title={this.translate('Label_Additional_Information')}>
                                        <div className="wms-static-content-wrapper">
                                            <div className="wms-content">
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Retailer_ID')} :</label> <span>{orderDetails.shipTo.retailerDescription}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Department_Number')} :</label> <span>{orderDetails.shipTo.dept}</span>
                                                </div>
                                            </div>
                                            <div className="wms-content">
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Add_To_Favorites')} :</label> <span>{orderDetails.shipTo.isQuickLookup}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </WmsContainer>
                                </div>
                                {/* TAB 2 Billing destination */}
                                <div>
                                    <WmsContainer title={this.translate('Label_Contact_Information')}>
                                        <div className="wms-static-content-wrapper">
                                            <div className="wms-content">
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Contact_Code')} :</label> <span>{orderDetails.billTo.code}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Company_Name')} :</label> <span>{orderDetails.billTo.companyName}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Receipient')} :</label> <span>{orderDetails.billTo.name}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Address_1')} :</label> <span>{orderDetails.billTo.address1}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Address_2')} :</label> <span>{orderDetails.billTo.address2}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.state.customLabels.Label_State} :</label> <span>{orderDetails.billTo.state}</span>
                                                </div>
                                            </div>
                                            <div className="wms-content">
                                                <div className="wms-data-items">
                                                    <label>{this.state.customLabels.Label_City} :</label> <span>{orderDetails.billTo.city}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Costomer_Postal_Code')} :</label> <span>{orderDetails.billTo.zip}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Country')} :</label> <span>{orderDetails.billTo.country}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Phone')} :</label> <span>{orderDetails.billTo.phoneNumber}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Email')} :</label> <span>{orderDetails.billTo.emailAddress}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </WmsContainer>
                                    <WmsContainer title={this.translate('Label_Additional_Information')}>
                                        <div className="wms-static-content-wrapper">
                                            <div className="wms-content">
                                                {/* <div className="wms-data-items">
                                                    <label>{this.translate('Label_Retailer_ID')} :</label> <span>{orderDetails.billTo.retailerId}</span>
                                                </div> */}
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Department_Number')} :</label> <span>{orderDetails.billTo.dept}</span>
                                                </div>
                                            </div>
                                            <div className="wms-content">
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Add_To_Favorites')} :</label> <span>{orderDetails.billTo.isQuickLookup}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </WmsContainer>
                                </div>
                                {/* TAB 3 SoldTo destination */}
                                <div>
                                    <WmsContainer title={this.translate('Label_Contact_Information')}>
                                        <div className="wms-static-content-wrapper">
                                            <div className="wms-content">
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Contact_Code')} :</label> <span>{orderDetails.soldTo.code}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Company_Name')} :</label> <span>{orderDetails.soldTo.companyName}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Receipient')} :</label> <span>{orderDetails.soldTo.name}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Address_1')} :</label> <span>{orderDetails.soldTo.address1}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Address_2')} :</label> <span>{orderDetails.soldTo.address2}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.state.customLabels.Label_State} :</label> <span>{orderDetails.soldTo.state}</span>
                                                </div>
                                            </div>
                                            <div className="wms-content">
                                                <div className="wms-data-items">
                                                    <label>{this.state.customLabels.Label_City} :</label> <span>{orderDetails.soldTo.city}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Costomer_Postal_Code')} :</label> <span>{orderDetails.soldTo.zip}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Country')} :</label> <span>{orderDetails.soldTo.country}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Phone')} :</label> <span>{orderDetails.soldTo.phoneNumber}</span>
                                                </div>
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Email')} :</label> <span>{orderDetails.soldTo.emailAddress}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </WmsContainer>
                                    <WmsContainer title={this.translate('Label_Additional_Information')}>
                                        <div className="wms-static-content-wrapper">
                                            <div className="wms-content">
                                                {/* <div className="wms-data-items">
                                                    <label>{this.translate('Label_Retailer_ID')} : </label> <span>{orderDetails.soldTo.retailerId}</span>
                                                </div> */}
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Department_Number')} :</label> <span>{orderDetails.soldTo.dept}</span>
                                                </div>
                                            </div>
                                            <div className="wms-content">
                                                <div className="wms-data-items">
                                                    <label>{this.translate('Label_Add_To_Favorites')} :</label> <span>{orderDetails.soldTo.isQuickLookup}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </WmsContainer>
                                </div>
                            </div>
                            <WmsContainer wmsSelector={this.state.selector + 'OrderLineItems'} title={this.translate('Label_Adding_Line_Items')}>
                                <div className="wms-table-wrapper">
                                    <div className="wms-table-responsive">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>{this.state.customLabels.Label_Sku}</th>
                                                    <th>{lineItemGridHeader[2].description}</th>
                                                    <th>{lineItemGridHeader[3].description}</th>
                                                    <th>{lineItemGridHeader[4].description}</th>
                                                    <th>{lineItemGridHeader[14].description}</th>
                                                    <th>{lineItemGridHeader[15].description}</th>
                                                    <th>{lineItemGridHeader[16].description}</th>
                                                    <th>{lineItemGridHeader[17].description}</th>
                                                    <th>{lineItemGridHeader[18].description}</th>
                                                    <th>{lineItemGridHeader[5].description}</th>
                                                    <th>{lineItemGridHeader[6].description}</th>
                                                    {/* <th>{lineItemGridHeader[7].description}</th> */}
                                                    <th>{lineItemGridHeader[8].description}</th>
                                                    <th>{lineItemGridHeader[9].description}</th>
                                                    <th>{lineItemGridHeader[10].description}</th>
                                                    <th>{lineItemGridHeader[11].description}</th>
                                                    <th>{lineItemGridHeader[12].description}</th>
                                                    <th>{lineItemGridHeader[13].description}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.orderLineItemData.map((data, index) => {
                                                    // <span>
                                                    return (
                                                        <Fragment key={'frag_' + index}>
                                                            <tr key={index}>
                                                                <td>{data.sku}</td>
                                                                <td>{data.itemDescription}</td>
                                                                <td>{data.qualifier}</td>
                                                                <td>{data.qty}</td>
                                                                <td>{data.primaryUnits}</td>
                                                                <td>{data.secondaryQty}</td>
                                                                <td>{data.secondaryUnits}</td>
                                                                <td>{data.catchWeight}</td>
                                                                <td>{data.volume}</td>
                                                                <td>{data.orderUnitName}</td>
                                                                <td>{data.orderQty}</td>
                                                                {/* <td>{data.availableQty}</td> */}
                                                                <td>{data.fulfillInvSalePrice}</td>
                                                                <td>{data.fullfillInvTotalPrice}</td>
                                                                <td>{data.fulfillInvDiscountPct}</td>
                                                                <td>{data.fulfillInvDiscountAmt}</td>
                                                                <td>{data.fullfillInvTotalAfterDiscount}</td>
                                                                <td>{data.notes}</td>
                                                            </tr>
                                                            {data.savedElements != null && data.savedElements.length > 0 && (
                                                                <tr key={'sub_' + index}>
                                                                    <td colSpan="4">
                                                                        <div className="wms-table-wrapper">
                                                                            <div className="wms-table-responsive">
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Name</th>
                                                                                            <th>Value</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {data.savedElements.map((data, index) => (
                                                                                            <tr key={data.name}>
                                                                                                <td>{data.name}</td>
                                                                                                <td>{data.value}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Fragment>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <WmsContainer wmsSelector={this.state.selector + 'CustomOrderTotals'} title={this.translate('Label_Custom_Order_Totals')} >
                                    <div className="wms-static-content-wrapper">
                                        <div className="wms-content">
                                            <div className="wms-data-items">
                                                <label>{this.translate('Label_Total_Packages')} : </label> <span>{orderDetails.numUnits1}</span>
                                            </div>
                                            <div className="wms-data-items">
                                                <label>{this.translate('Label_Packaging_UOM')} :</label> <span>{orderDetails.unit1Identifier != null ? orderDetails.unit1Identifier.name : ''}</span>
                                            </div>
                                            <div className="wms-data-items">
                                                <label>{this.translate('Label_Total_Weight')} :</label> <span>{orderDetails.totalWeight}</span>
                                            </div>
                                        </div>
                                        <div className="wms-content">
                                            <div className="wms-data-items">
                                                <label>{this.translate('Label_Total_Movable_Units')} :</label> <span>{orderDetails.numUnits2}</span>
                                            </div>
                                            <div className="wms-data-items">
                                                <label>{this.translate('Label_Movable_UOM')} : </label> <span>{orderDetails.unit2Identifier != null ? orderDetails.unit2Identifier.name : ''}</span>
                                            </div>
                                            <div className="wms-data-items">
                                                <label>{this.translate('Label_Total_Volume')} :</label> <span>{orderDetails.totalVolume}</span>
                                            </div>
                                        </div>
                                    </div>
                                </WmsContainer>
                            </WmsContainer>
                            <WmsContainer wmsSelector={this.state.selector + 'CarrierInfo'} title={this.translate('Label_Carrier_Information')}>
                                <div className="wms-static-content-wrapper">
                                    <div className="wms-content">
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Carrier')} :</label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.carrier : null}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_SCAC')} :</label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.scacCode : null}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Service')} :</label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.mode : null}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Billing')} :</label> <span>{orderDetails.billingCode}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Account_Number')} :</label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.account : null}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.state.customLabels.Label_Zip} :</label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.shipPointZip : null}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Capacity_Type')} :</label> <span>{(orderDetails.routingInfo != null && orderDetails.routingInfo.capacityTypeIdentifier != null) ? orderDetails.routingInfo.capacityTypeIdentifier.name : null}</span>
                                        </div>
                                    </div>
                                    <div className="wms-content">
                                        <div className="wms-data-items">
                                            <label>{this.state.customLabels.Label_Warehouse + ' ' + this.translate('Label_Warehouse_Instructions')} :</label> <span>{orderDetails.notes}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Carrier_Instructions')} :</label> <span>{orderDetails.shippingNotes}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_COD')} :</label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.isCod : 'false'}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Insurance')} :</label> <span> {(orderDetails.routingInfo != null) ? orderDetails.routingInfo.isInsurance + '' : 'false'}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Require_Delivery_Confirmation')} :</label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.requiresDeliveryConf + '' : 'false'}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Delivery_Confirmation_Type')} :</label> <span>{(orderDetails.routingInfo != null) ? this.optionsDeliveryConfirmationType[orderDetails.routingInfo.deliveryConfirmationType] : ''}</span>
                                        </div>
                                        <div className="wms-data-items">
                                            <label>{this.translate('Label_Require_Return_Receipt')} :</label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.requiresReturnReceipt + '' : 'false'}</span>
                                        </div>
                                    </div>
                                </div>
                                <WmsContainer wmsSelector={this.state.selector + 'RoutingInfo'} title={this.translate('Label_Routing_Information')}>
                                    <div className="wms-static-content-wrapper">
                                        <div className="wms-content">
                                            <div className="wms-data-items">
                                                <label>{this.translate('Label_Load_Number')} : </label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.loadNumber : null}</span>
                                            </div>
                                            <div className="wms-data-items">
                                                <label>{this.state.customLabels.Label_Bill_Of_Lading} :</label> <span> {(orderDetails.routingInfo != null) ? orderDetails.routingInfo.billOfLading : null}</span>
                                            </div>
                                            <div className="wms-data-items">
                                                <label>{this.translate('Label_Tracking_Number')} :</label> <span> {(orderDetails.routingInfo != null) ? orderDetails.routingInfo.trackingNumber : null}</span>
                                            </div>
                                            <div className="wms-data-items">
                                                <label>{this.translate('Label_Trailer_Number')} :</label> {(orderDetails.routingInfo != null) ? orderDetails.routingInfo.trailerNumber : null}<span></span>
                                            </div>
                                        </div>
                                        <div className="wms-content">
                                            <div className="wms-data-items">
                                                <label>{this.translate('Label_Seal_Number')} :</label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.sealNumber : null}</span>
                                            </div>
                                            <div className="wms-data-items">
                                                <label>{this.state.customLabels.Label_Door} :</label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.doorNumber : null}</span>
                                            </div>
                                            <div className="wms-data-items">
                                                <label>{this.translate('Label_Pickup_Date')} :</label> <span>{(orderDetails.routingInfo != null) ? orderDetails.routingInfo.pickupDate : null}</span>
                                            </div>
                                        </div>
                                    </div>
                                </WmsContainer>
                                {this.state.selectedCustomer.options.flexFields && (
                                    <WmsContainer wmsSelector={this.state.selector + 'CustomOrderInfo'} title={this.translate('Label_Custom_Order_Info')}>
                                        {orderSEData.length > 0 ? (
                                            <div className="wms-table-wrapper">
                                                <div className="wms-table-responsive">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>{customOrderInfo_GridHeaders[0].description}</th>
                                                                <th>{customOrderInfo_GridHeaders[1].description}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {orderSEData.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td>{data.name}</td>
                                                                    <td>{data.value}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (<div className="wms-no-data-found">No Data Found</div>)}
                                    </WmsContainer>
                                )}
                                {this.state.Rights.hasOwnProperty('admin.viewbilling') && (
                                    <WmsContainer wmsSelector={this.state.selector + 'AppliedCharges'} title={this.translate('Label_Applied_Charges')}>
                                        {billingChargesData.length > 0 ? (
                                            <div className="wms-table-wrapper">
                                                <div className="wms-table-responsive">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>{CalculateCharges_GridHeaders[0].description}</th>
                                                                <th>{CalculateCharges_GridHeaders[1].description}</th>
                                                                <th>{CalculateCharges_GridHeaders[2].description}</th>
                                                                <th>{CalculateCharges_GridHeaders[3].description}</th>
                                                                <th>{CalculateCharges_GridHeaders[4].description}</th>
                                                                <th>{CalculateCharges_GridHeaders[5].description}</th>
                                                                <th>{CalculateCharges_GridHeaders[6].description}</th>
                                                                <th>{CalculateCharges_GridHeaders[7].description}</th>
                                                                <th>{CalculateCharges_GridHeaders[8].description}</th>
                                                                <th>{CalculateCharges_GridHeaders[9].description}</th>
                                                                <th>{CalculateCharges_GridHeaders[10].description}</th>
                                                                <th>{CalculateCharges_GridHeaders[11].description}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {billingChargesData.map((data, index) => (

                                                                <tr key={index}>
                                                                    <td>{data.chargeOrigin}</td>
                                                                    <td>{data.chargeTypeDescription}</td>
                                                                    <td>{data.chargeLabel}</td>
                                                                    <td>{data.unitDescription}</td>
                                                                    <td>{data.chargePerUnit}</td>
                                                                    <td>{data.numberOfUnits}</td>
                                                                    <td>{data.totalCharges}</td>
                                                                    <td>{data.generalLedgerAccount}</td>
                                                                    <td>{data.externalAccountsReceivable}</td>
                                                                    <td>{data.externalItemIdentifier}</td>
                                                                    <td>{data.externalItemDescription}</td>
                                                                    <td>{data.sKU}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (<div className="wms-no-data-found">No Data Found</div>)}
                                        <div className="wms-total-changes">
                                            <span>{this.translate('Label_Total_Charges')} :</span>  {this.state.totalCharges}
                                        </div>
                                    </WmsContainer>
                                )}
                            </WmsContainer>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}