import React, { useState } from "react";
import PropTypes from "prop-types";

import WmsModel from "./../react/components/WmsModel/WmsModel";
import WmsCollapse from "./../react/components/WmsCollapse/WmsCollapse";
import WmsDropdown from "./../react/components/WmsDropdown/WmsDropdown";
import WmsInput from "./../react/components/WmsInput/WmsInput";
import WmsTextArea from "./../react/components/WmsTextArea/WmsTextArea";
import WmsDateTimePicker from "./../react/components/WmsDateTimePicker/WmsDateTimePicker";
import WmsButton from "./../react/components/WmsButton/WmsButton";
import * as GlobalService from "./../react/global_services";
import WmsDropdownList from "./../react/components/WmsDropdownList/WmsDropdownList.jsx";

import "./manageassembly.scss";

declare var window: any;
const { $ } = window;

let selectedCustomerId = 0;

interface Props {
    onCloseCreateNewAssemblyModal: any;
    isOpenCreateNewAssemblyModal: boolean;
    wmsSelector: string;
    selectedRow: any;
    windowId: string;
    onApplyCreateAssembly: any;
    isCreateAssemblyLoading: boolean;
}

const CreateNewassemblyModal: React.FC<Props> = (props: Props): any => {
    const CustomLabels = GlobalService.getCustomLabels();
    const CustomerOptions = GlobalService.getCustomerList(true);
    const [facilityOPtions, setFacilityOptions] = useState(GlobalService.getFacilityList(true));
    const [assemblyData, setAssemblyData] = useState(props.selectedRow);
    // const [selectedCustomer, setSelectedCustomer] = useState(null);

    const handleChangeAssemblyData = (event: any) => {
        let { name, value } = event.target;
        var finalValue = value;
        if (name === "CustomerId") {
            finalValue = parseInt(value);
            selectedCustomerId = event.target.value;
            handleCustomerChange(parseInt(finalValue));
        } else if (name === "FacilityId") {
            finalValue = parseInt(value);
        } else if (name === "LocationInfo") {
            finalValue = {
                locationId: parseInt(value.Id),
            };
        }

        setAssemblyData((prevState: any) => ({
            ...prevState,
            [name]: finalValue,
        }));
    };
    const handleCustomerChange = (CustomerID: any) => {
        var selectedCustomer = CustomerOptions.find((cus: any) => CustomerID !== 0 && cus.id === CustomerID);
        if (selectedCustomer) {
            var customerFacilityIds = selectedCustomer.facilities;
            let allFacilities = GlobalService.getFacilityList(true);
            allFacilities = allFacilities.filter((f: any) => customerFacilityIds.includes(f.id) || f.id === 0);
            setFacilityOptions(allFacilities);
            // setSelectedCustomer(selectedCustomer);
        } else {
            setFacilityOptions([]);
        }
    };
    const changecurrentAssemblyItemSku = (event) => {
        let value = 0;
        let materialNotes = "";
        if (event.target.value.Id !== undefined && event.target.value.Id !== "") {
            value = event.target.value.Id;
            materialNotes = event.target.value.MaterialNotes;
        }

        setAssemblyData((prevState: any) => ({
            ...prevState,
            ItemId: value,
            Notes: prevState.ItemId !== value ? materialNotes : prevState.Notes,
        }));
    };
    const allValidationDone = () => {
        return (
            assemblyData.CustomerId === 0 ||
            assemblyData.FacilityId === 0 ||
            assemblyData.ItemId === 0 ||
            assemblyData.Qty === 0 ||
            assemblyData.Qty === "" ||
            assemblyData.ReferenceNum === "" ||
            assemblyData.ReferenceNum === null
        );
    };
    const createAdjustment = () => {
        var formData = {
            assemblyData: {
                CustomerId: assemblyData.CustomerId,
                FacilityId: assemblyData.FacilityId,
                ItemId: assemblyData.ItemId,
                Qty: assemblyData.Qty,
                ReferenceNum: assemblyData.ReferenceNum,
                ExpectedDate: assemblyData.ExpectedDate,
                Notes: assemblyData.Notes,
                PalletInfo: null,
                LocationInfo: null,
                // PalletInfo: { Label: assemblyData.MovableUnitLabel },
                // LocationInfo: assemblyData.LocationInfo,

                LotNumber: null,
                SerialNumber: null,
                ExpirationDate: null,
                Cost: null,
                EditUri: null,
                FileSummariesUri: null,
                AssemblyId: 0,
                BillingCharges: null,
                Description: null,
                CanFinalize: false,
                InvoiceNumber: null,
                ExternalId: null,
                ProcessDate: null,
                AssemblyItemsCount: 0,
                SavedElements: null,
            },
            isCustomerUser: false,
        };

        kendo.ui.progress($("#CreateNewAssemblyModel"), true);

        props.onApplyCreateAssembly(formData);
    };

    return (
        <>
            <WmsModel
                id="CreateNewAssemblyModel"
                title={window.translate("Create_New_Assembly")}
                width={800}
                height={800}
                onCloseFunc={props.onCloseCreateNewAssemblyModal}
                isOpen={props.isOpenCreateNewAssemblyModal}
                customClass="wms-model-new-ui-wrapper"
            >
                <div className={"model-content-wrapper"}>
                    <WmsCollapse
                        id={"CreateNewAssemblyTransactionInfo"}
                        headerLabel={window.translate("Label_Transaction")}
                        showCollapseText={false}
                    >
                        <div className="transaction-information-wrapper">
                            <div className="display-inline">
                                <WmsDropdown
                                    id="customer"
                                    name="CustomerId"
                                    label={CustomLabels.Label_Customer}
                                    wmsSelector={props.wmsSelector + "SelectCustomer"}
                                    value={assemblyData.CustomerId}
                                    onChangeFunc={handleChangeAssemblyData}
                                    options={CustomerOptions}
                                    valueField={"id"}
                                    textField={"name"}
                                    required={true}
                                />
                                <WmsDropdown
                                    id="warehouseFacilityID"
                                    name="FacilityId"
                                    label={CustomLabels.Label_Warehouse}
                                    wmsSelector={props.wmsSelector + "SelectWarehouse"}
                                    value={assemblyData.FacilityId}
                                    onChangeFunc={handleChangeAssemblyData}
                                    options={facilityOPtions}
                                    valueField={"id"}
                                    textField={"name"}
                                    required={true}
                                />
                            </div>
                            <div className="display-inline">
                                <WmsDropdownList
                                    id="ItemId"
                                    name="ItemId"
                                    label={CustomLabels.Label_Sku}
                                    optionsLabel={window.translate("Label_Select_Sku")}
                                    wmsSelector={props.wmsSelector + "assembly-ItemId"}
                                    value={assemblyData.ItemId}
                                    onChangeFunc={changecurrentAssemblyItemSku}
                                    textField="Sku"
                                    valueField="Id"
                                    showDetailsTooltip={true}
                                    windowId={props.windowId}
                                    parentclassName="form-group"
                                    template="<span style='white-space: nowrap;text-overflow: ellipsis;width: 210px;display: block;overflow: hidden'>#if (Description == null) {# #: Sku# #}else{# #:Sku# - #:Description#  #}#</span>"
                                    extraWrapperClass="width-40 sku-dropdown-wrapper"
                                    required={true}
                                    virtualObj={{
                                        itemHeight: 45,
                                        valueMapper: (options) => {
                                            var valueMapperOptions = options;
                                            $.ajax({
                                                url: "/WebUI/items/items/SkuDropdownAssemblyIndexRead",
                                                type: "POST",
                                                dataType: "json",
                                                data: {
                                                    itemId: valueMapperOptions.value || 0,
                                                    customerId: selectedCustomerId || 0,
                                                },
                                                success: function (data) {
                                                    options.success(data);
                                                },
                                            });
                                        },
                                    }}
                                    dataSourceObj={{
                                        type: "aspnetmvc-ajax",
                                        pageSize: 30,
                                        serverPaging: true,
                                        serverFiltering: true,
                                        transport: {
                                            read: {
                                                url: "/WebUI/items/items/SkuDropDownRead",
                                                type: "POST",
                                                data: {
                                                    customerId: selectedCustomerId || 0,
                                                    isAssembly: true,
                                                },
                                            },
                                        },
                                        schema: {
                                            data: "Data",
                                            total: "Total",
                                        },
                                    }}
                                />
                                <WmsInput
                                    id="Qty"
                                    inputName="Qty"
                                    wmsSelector={props.wmsSelector + "Qty"}
                                    onChangeFunc={handleChangeAssemblyData}
                                    label={window.translate("Label_Quantity")}
                                    value={assemblyData.Qty}
                                    extraWrapperClass="width-20"
                                    required={true}
                                    regularExp={/^[1-9][0-9]*$/}
                                />
                                <WmsInput
                                    id="ReferenceNum"
                                    inputName="ReferenceNum"
                                    wmsSelector={props.wmsSelector + "ReferenceNum"}
                                    onChangeFunc={handleChangeAssemblyData}
                                    label={window.translate("Orders_Lbl_Reference_Num")}
                                    value={assemblyData.ReferenceNum}
                                    required={true}
                                    extraWrapperClass="width-40"
                                />
                                <WmsDateTimePicker
                                    id={"ExpectedDate"}
                                    name="ExpectedDate"
                                    label={window.translate("Label_Due_Date")}
                                    onChangeFunc={handleChangeAssemblyData}
                                    value={assemblyData.ExpectedDate}
                                    required={false}
                                    wmsSelector={props.wmsSelector + "ExpectedDate"}
                                    format={"M/d/yyyy"}
                                    isDateParseInCustomFormat={true}
                                />
                            </div>
                            <div className="display-inline">
                                <WmsTextArea
                                    id="Notes"
                                    name="Notes"
                                    wmsSelector={props.wmsSelector + "Notes"}
                                    onChangeFunc={handleChangeAssemblyData}
                                    label={window.translate("Label_Notes")}
                                    value={assemblyData.Notes}
                                    extraWrapperClass="width-70"
                                    maxLength={4096}
                                    rows={1}
                                    cols={75}
                                />
                            </div>
                        </div>
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={window.translate("Label_Cancel")}
                        wmsSelector={props.wmsSelector + "DiscardExit"}
                        onClickFunc={props.onCloseCreateNewAssemblyModal}
                        buttonType="orange"
                        icon={"fa-ban"}
                    />
                    <WmsButton
                        label={window.translate("Label_Save")}
                        wmsSelector={props.wmsSelector + "Save"}
                        onClickFunc={createAdjustment}
                        icon={"fa-floppy-o"}
                        disabled={allValidationDone()}
                        loading={props.isCreateAssemblyLoading}
                        parentClassName={"apply-adjustment-button"}
                    />
                </div>
            </WmsModel>
        </>
    );
};
CreateNewassemblyModal.propTypes = {
    onCloseCreateNewAssemblyModal: PropTypes.any.isRequired,
    isOpenCreateNewAssemblyModal: PropTypes.bool.isRequired,
    wmsSelector: PropTypes.string.isRequired,
    selectedRow: PropTypes.any.isRequired,
    windowId: PropTypes.string.isRequired,
    onApplyCreateAssembly: PropTypes.any.isRequired,
    isCreateAssemblyLoading: PropTypes.bool.isRequired,
};

CreateNewassemblyModal.defaultProps = {
    onCloseCreateNewAssemblyModal: (): void => {},
    isOpenCreateNewAssemblyModal: false,
    wmsSelector: "",
    selectedRow: {},
    windowId: "",
    onApplyCreateAssembly: (): void => {},
    isCreateAssemblyLoading: false,
};

export default React.memo(CreateNewassemblyModal);
