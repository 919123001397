import React, { Component } from 'react';
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsCheckBox from '../../../components/WmsCheckBox/WmsCheckBox.jsx';
import * as GlobalService from './../../../global_services';

export class FedexEditComponent extends Component {
    constructor(props) {
        super(props);
        this.translate = window.translate;

        this.state = {
            smartPostHubOptions: [],
            smartPostEndorsementOptions: []
        }
    }

    componentDidMount() {
        let optionsForSettings = GlobalService.getDropdownOptionsForSettings('FedEx');
        this.setState({
            smartPostHubOptions: optionsForSettings.smartPostHub,
            smartPostEndorsementOptions: optionsForSettings.smartPostEndorsement
        });
    }

    render() {
        return (
            <WmsCollapse
                id={"FedexEditComponent"}
                headerLabel={this.translate("Label_Company_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id='nickname'
                            inputName='Nickname'
                            wmsSelector={this.props.selector + 'nickname'}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate('Label_Account_Nick_Name')}
                            value={this.props.currentCarrierObj.Nickname}
                            extraWrapperClass="fedex-edit-nickname" />
                    </div>
                    <div>

                        <WmsInput
                            id='accountNumber'
                            inputName='AccountNumber'
                            disabled={true}
                            wmsSelector={this.props.selector + 'accountNumber'}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate('Label_Account_Number')}
                            value={this.props.currentCarrierObj.AccountNumber}
                            extraWrapperClass="fedex-edit-accoutnumber" />

                        <WmsInput
                            id='postalCode'
                            inputName='AccountPostalCode'
                            wmsSelector={this.props.selector + 'zip'}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate('Label_Account_Number_And_Postal_Code')}
                            extraWrapperClass="wfedex-edit-zip"
                            value={this.props.currentCarrierObj.AccountPostalCode} />

                        <WmsDropdown
                            id="smartPostHub"
                            name="SmartPostHub"
                            label={this.translate('Label_Smart_Post_Hub')}
                            wmsSelector={this.props.selector + "SmartPostHub"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            value={this.props.currentCarrierObj.SmartPostHub}
                            options={this.state.smartPostHubOptions}
                            valueField='value'
                            textField='name'
                            extraWrapperClass="fedex-edit-smart-post-hub"
                            blankFirstOption={true} />
                    </div>
                    <WmsDropdown
                        id="smartPostEndorsement"
                        name="SmartPostEndorsement"
                        label={this.translate('Label_Smart_Post_Endorsement')}
                        wmsSelector={this.props.selector + "SmartPostEndorsement"}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        value={this.props.currentCarrierObj.SmartPostEndorsement}
                        options={this.state.smartPostEndorsementOptions}
                        valueField='value'
                        textField='name'
                        extraWrapperClass="fedex-edit-smart-post-endorsment"
                        blankFirstOption={true}
                    />

                    <WmsCheckBox
                        id={'isPrimaryAccount'}
                        name={"IsPrimaryAccount"}
                        wmsSelector={this.props.selector + 'PrimaryAccount'}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        label={this.translate('Label_Primary_FedEx_Account')}
                        checked={this.props.currentCarrierObj.IsPrimaryAccount == true}
                        isRounded={true}
                    />
                </div>
            </WmsCollapse>
        )
    }
}
