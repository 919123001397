var axios = require('axios');
import * as GlobalService from '../global_services';

export const initialFilterData = function () {

    return axios.all([
        GlobalService.GetCustomersAndFacility()
    ]).then(axios.spread(function (GetCustomerFacilityList) {
        return {
            getCustomerFacilityList: GlobalService.getFacilityList()
        };
    }));
}
