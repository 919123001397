import React, { Component } from 'react'

// Components
import { BarcodeGrid } from "./barcode-grid.component.jsx";
import { BarcodeForm } from './barcode-form.component.jsx';
import WmsDropdown from '../components/WmsDropdown/WmsDropdown.jsx';

// Styles
import style from './barcode.scss';

// Services
import * as barcodeServices from './barcode.services.jsx';
import * as GlobalService from './../global_services';

export class BarcodeHolder extends Component {
    constructor(props) {
        super(props)
        
        this.$rootScope = window;

        this.state = {
            Selector: 'Barcode',
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            customerOption: [],
            selectedCustomerIndex: "",
            sharedCurrentCustomerObj: "",
            openCreateBarcode: false,
            sharedOptions:{
                Names:[],
                Lengths: [],
                Groups: [],
                BarcodeDefs:[]
            },
            SearchFilters: {
                BarcodeGroup: null,
                BarcodeLength: null,
                BarcodeName: null,
            }
        }
    }

    componentDidMount = () => {
        kendo.ui.progress($('#' + this.state.windowId), true);

        barcodeServices.getBarcodeInitial().then((response) => {
            this.setState({
                customerOption: response.customerList
            });
        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
    }


    handleCustomerSelectChange = (event) => {
        var custId = event.target.value;

        if (custId != '') {

            var showInitialLoaderOn = (this.state.selectedCustomerIndex) ? '#GridFindBarcodeDefs' : '.barcode-main-content' ;
            kendo.ui.progress($(showInitialLoaderOn), true);


            barcodeServices.getFindBarcodeDefsPageLoad(custId).then((response) => {
                var customerObj = this.getCustomerById(custId);
                response.Names = response.Names.filter(val => val);
                response.Lengths = response.Lengths.filter(val => val);
                response.Groups = response.Groups.filter(val => val);

                this.setState({
                    sharedCurrentCustomerObj: customerObj,
                    selectedCustomerIndex: custId,
                    sharedOptions: response,
                    SearchFilters: {
                        BarcodeGroup: null,
                        BarcodeLength: null,
                        BarcodeName: null,
                    }
                });

            }).finally(() => {
                kendo.ui.progress($(showInitialLoaderOn), false);
            });
        }
    }

    getCustomerById(id) {
        return this.state.customerOption.find((element) => {
            if (element.id == id) {
                return element;
            }
        });
    }

    IndexOptionsCustomerChange = () => {
        var event = {
            target: {
                value: this.state.selectedCustomerIndex,
            }
        }
        this.handleCustomerSelectChange(event);
    }

    onOpenCreateBarcode = (flag) => {
        this.setState({
            openCreateBarcode: flag,
        });
    }

    render() {
        return (
            <div id="barcode-holder-react" >
                <div className="select-field">
                    <WmsDropdown
                        id="itemHolderIndexOptionsCustomer"
                        name="selectedCustomerIndex"
                        label={GlobalService.getCustomLabels().Label_Customer}
                        wmsSelector={this.state.Selector + 'SelectCustomer'}
                        value={this.state.selectedCustomerIndex}
                        onChangeFunc={this.handleCustomerSelectChange}
                        options={this.state.customerOption}
                        valueField='id'
                        textField='name'
                        parentClassName='form_group'
                        required={true}
                        blankFirstOption={!this.state.selectedCustomerIndex}
                    />
                </div>
                <div className="barcode-main-content">
                    {this.state.selectedCustomerIndex != "" && (
                        <BarcodeGrid
                            sharedOptions={this.state.sharedOptions}
                            windowId={this.state.windowId}
                            selectedCustomer={this.state.sharedCurrentCustomerObj}
                            indexOptionsCustomerChange={this.IndexOptionsCustomerChange}
                            onOpenCreateBarcode={this.onOpenCreateBarcode}
                        />
                    )}
                    {this.state.openCreateBarcode == true && (
                        <BarcodeForm
                            windowId={this.state.windowId}
                            selectedCustomer={this.state.sharedCurrentCustomerObj}
                            onOpenCreateBarcode={this.onOpenCreateBarcode}
                            barcodeAdded={this.IndexOptionsCustomerChange}
                        />
                    )}
                </div >
            </div>
        )
    }
}
