import React from 'react';
import PropTypes from 'prop-types';
import styles from './WmsModel.scss';
import * as GlobalService from "../../global_services";

var $rootScope;

class WmsModel extends React.PureComponent {

  constructor(props) {
    super(props);

    $rootScope = window;
    this.state = {
      jQueryModel: null,
      isModalOpenFirstTime:true,
      modelLeftPos: 0,
      isModelMinimised: false,
    };
  }

  componentDidMount() {
    // console.log("WmsModel componentDidMount called : " + this.props.id);
    this.getJqueryModel();
    // NOTE: Code For unmount model
    if (this.props.isOpen == true) {
      this.initModelAndOpen();
    }
    window.addEventListener("resize", this.reCenterOpenModel);
  }

  //for remove old reference of this model from dom
  UNSAFE_componentWillMount() {
    // console.log("WmsModel componentWillMount called : " + this.props.id);
    if ($("#" + this.props.id).length > 0) {
      $("#" + this.props.id).parent('.k-window').remove();
    }
  }
  // NOTE: Code For unmount model
    componentWillUnmount() {
        console.log("WmsModel componentWillUnmount called : " + this.props.id);
        if (typeof $("#" + this.props.id).data("kendoWindow") != "undefined") {
          $("#" + this.props.id).data("kendoWindow").close();
          $("#" + this.props.id).data("kendoWindow").destroy();
        }
        window.removeEventListener("resize", this.reCenterOpenModel);
   }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if ((nextprops.isOpen == true && this.props.isOpen == false) || nextprops.title != this.props.title) {
      this.initModelAndOpen();
    } else if (nextprops.isOpen == false && this.props.isOpen == true) {
      this.closeModelIfFound();
    }
  }

  reCenterOpenModel = () => {
    console.log("On resize call => this.state.isModelMinimised", this.state.isModelMinimised );
    var modelObj = $("#" + this.props.id).data("kendoWindow");
    if(modelObj && !this.state.isModelMinimised){

      modelObj.setOptions({
        position: {
          top: this.props.appendTo != '' ? 10 : 50,
          left: this.leftCalculateBasedOnModelWidth()
        },
        maxHeight: this.heightResizeAsWindowScreen()
      });
      modelObj.refresh();

    }
  }

  getJqueryModel = () => {
    if (typeof $("#" + this.props.id).data("kendoWindow") == "undefined") {
      var maxHeight = this.heightResizeAsWindowScreen();
      var draggableVal = this.props.appendTo != '' ? { containment: this.props.appendTo } : true;
      this.state.jQueryModel = $("#" + this.props.id).kendoWindow({
        appendTo: this.props.appendTo != '' ? this.props.appendTo : undefined,
        title: {
          text: this.props.title,
          encoded: false
        },
        visible: false,
        cache: false,
        modal: true,
        width: this.props.width.toString() + "px",
        draggable:draggableVal,
        position: {
                    top: this.props.appendTo != '' ? 10 : 50,
        left: this.leftCalculateBasedOnModelWidth(),
        },
        resizable: false,
        actions: this.props.actions,
        minHeight: this.props.minHeight + "px", // Minimum content area height is 150px. 150 + 80 header + 95 footer = 325
        maxHeight: maxHeight + "px",
        maximize: this.onModelMaximize,
        minimize: this.onModelMinimize,
        dragend: this.onModelDragend,
        restore: () => {
            console.log(" restore Direct function 2222");
        },
        resize: this.onModelResize
        // refresh: function(){this.center();},
      });
      if(this.props.removeCloseAnimation){
        var GetWindow = $("#" + this.props.id).data("kendoWindow");
        GetWindow.setOptions({
            animation: {
                close: false
            }
                });
              }
              if(this.props.removeOpenAnimation){
                var GetWindow = $("#" + this.props.id).data("kendoWindow");
                GetWindow.setOptions({
                    animation: {
                        open: false
                    }
                });
              }
            this.state.jQueryModel.css({ transition: "top 2s" });
    }
    };
    onModelMaximize = () => {
        console.log("onMaximize");
        var modelObj = $("#" + this.props.id).data("kendoWindow");
        if (modelObj) {
            modelObj.wrapper.css({ top: this.props.appendTo != '' ? 10 : 50, right: 5});
        }
    };
    onModelRestore = () => {
        console.log("onModelRestore");
        var modelObj = $("#" + this.props.id).data("kendoWindow");
        if (modelObj) {
            modelObj.wrapper.css({ top: this.props.appendTo != '' ? 10 : 50});
  }
    };
    onModelResize = () => {
        console.log("onModelResize");
    };
    onRestore = (e) => {
        var modelObj = $("#" + this.props.id).data("kendoWindow");
        if (modelObj) {
            if( this.props.appendTo != ''){
                $(this.props.appendTo + " .k-overlay").show();
            } else {
                $(".k-overlay").show();
            }
            modelObj.wrapper.css({
                top: this.props.appendTo != '' ? 10 : 50,
                left: this.leftCalculateBasedOnModelWidth(),
                width: this.props.width.toString() + "px"
            });
            GlobalService.updateModelMinimisePlace(this.props.id, this.props.windowId, false);
            this.setState({isModelMinimised: false})
            setTimeout(() => {
                modelObj.wrapper.css({ transition: 'none'});
            }, 500);
        }
    };
    onModelMinimize = () => {
        console.log("onModelMinimize");
        var ModelLeftPos = GlobalService.minimiseModelAsCurrentWindow(this.props.id, this.props.windowId);
        $('.'+this.props.id + "_model_wrapper"+' [aria-label="window-restore"]').on("click", this.onRestore);
        $(".k-overlay").hide();
        this.setState({
            modelLeftPos: ModelLeftPos,
            isModelMinimised: true
        })

        // var curMinimizePlace = GlobalService.getModelMinimiseEmptyPlace(this.props.windowId);
        // this.setState({minimizePlace: curMinimizePlace})
        // if (modelObj) {
        //     modelObj.wrapper.css({
        //         transition: 'top 0.2s',
        //         left: this.leftCalculateBasedOnModelWidth(curMinimizePlace),
        //         top: $("#"+this.props.windowId).innerHeight() - 80,
        //         width: 350,
        //     });
        //     $(".k-overlay").hide();
        //     $('.'+this.props.id + "_model_wrapper"+' [aria-label="window-restore"]').on("click", this.onRestore);
        //     GlobalService.updateModelMinimisePlace(this.props.windowId, curMinimizePlace, true);
        // }
    };
    onModelDragend = () => {
        console.log("onModelDragend");
        var modelObj = $("#" + this.props.id).data("kendoWindow");
        if (modelObj && modelObj.isMinimized()) {
            modelObj.wrapper.css({
                left: this.state.modelLeftPos,
                top: $("#"+this.props.windowId).innerHeight() - 80
            });
        }
    };


  heightResizeAsWindowScreen = () => {
    // var properModalHeight = window.innerHeight - 100; // we need to remove header footer height and extra padding for proper modal showing
    // if(this.props.height > properModalHeight){
    //   return properModalHeight;
    // }else{
    //   return this.props.height;
    // }
    // calculation for maximum height of modal
    return window.innerHeight - 200; // 100px top padding + 100px bottom padding
  }

  leftCalculateBasedOnModelWidth = () => {
      var mainscreenInnerWith = this.props.appendTo != '' ? $(this.props.appendTo).innerWidth() : window.innerWidth;
      console.log("leftCalculateBasedOnModelWidth", mainscreenInnerWith);
        return (mainscreenInnerWith - this.props.width)/2;
  }

  initModelAndOpen = () => {
    this.getJqueryModel();
    setTimeout(() => {
      var GetWindow = $("#" + this.props.id).data("kendoWindow");
      var maxHeight = this.heightResizeAsWindowScreen();
      var draggableVal = this.props.appendTo != '' ? { containment: this.props.appendTo } : true;
      if (GetWindow) {
        GetWindow.setOptions({
            appendTo: this.props.appendTo != '' ? this.props.appendTo : undefined,
          title: {
            text: this.props.title,
            encoded: false
          },
                    modal: true,
          width: this.props.width.toString() + "px",
          draggable: draggableVal,
          resizable: false,
          actions: this.props.actions,
          minHeight: this.props.minHeight + "px",
          maxHeight: maxHeight + "px",
          position: {
            top: this.props.appendTo != '' ? 10 : 50,
            left: this.leftCalculateBasedOnModelWidth(),
          },
          open: (e) => {
            var winObject = $("#" + this.props.id).data("kendoWindow");
            if (this.props.overlayHide) {
              winObject.wrapper.addClass("Modal1CustomWindow border-modal ");
              setTimeout(() => {
                winObject.wrapper.prev().css('opacity', '0');
              }, 100);
            } else if (this.props.areyousure) {
              winObject.wrapper.addClass("k-window-titleless areYouSureZindex");
            } else if (this.props.customClass) {
              winObject.wrapper.addClass("Modal1CustomWindow " + this.props.customClass);
            } else if (this.props.confirmationModal) {
              winObject.wrapper.addClass("areYouSureZindex");
            } else if (this.props.warningModal) {
              winObject.wrapper.addClass("areYouSureZindex");
            } else {
              winObject.wrapper.addClass("Modal1CustomWindow");
            }
            if (this.state.isModalOpenFirstTime == true && this.props.customClass == 'wms-model-new-ui-wrapper') {
              let height = winObject.wrapper.height() + 80;
              winObject.wrapper.css('height', height);
              this.state.isModalOpenFirstTime = false;
            }

            if (!this.props.actions.includes("Minimize")) {
                winObject.wrapper.addClass("change-maximize-icon");
            }
            winObject.wrapper.addClass(this.props.id + "_model_wrapper");
          },
          close: (e) => {
            if (this.props.isAnotherModelOpen == false) {
              if (typeof $rootScope.currentWindow != "undefined") {
                setTimeout(() => {
                  // $rootScope.currentWindow.toFront();
                }, 500);
              }
              setTimeout(() => {
                $rootScope.adjustZIndexOfSidebar('close');
                GetWindow.wrapper.removeClass("areYouSureZindex");
              }, 500);
            }
          },
          deactivate: () => {
            // the closing animation is about to finish
            this.props.onCloseFunc();
        },
        maximize: this.onModelMaximize,
        minimize: this.onModelMinimize,
        dragend: this.onModelDragend,
        restore: () => {
            console.log(" restore Direct function");
        },
        resize: this.onModelResize,
          // refresh: function(){this.center();}
        });

        if (this.props.removeCloseAnimation) {
          GetWindow.setOptions({
            animation: {
              close: false
            }
          });
        }
        if (this.props.removeOpenAnimation) {
          GetWindow.setOptions({
            animation: {
              open: false
            }
          });
        }
        GetWindow.open();
      }
      // GetWindow.refresh();
    }, 0);
    $rootScope.adjustZIndexOfSidebar('open');


    if(this.props.isShowTooltipOnTitle){
        setTimeout(() => {
            $(".tooltipfortitle").kendoTooltip({
              // width: 300,
              position: "bottom",
              content: function(e) {
                var target = e.target; // the element for which the tooltip is shown
                return '<p class="model-tooltip-title-p">' + target.data("tooltip-text") + '</p>';
              },
              animation: {
                open: {
                  effects: "zoom",
                  duration: 150
                }
              }
          }).data("kendoTooltip");

        }, 0);

    }
  }

  closeModelIfFound = () => {
    if (typeof $("#" + this.props.id).data("kendoWindow") != "undefined") {
      $("#" + this.props.id).data("kendoWindow").close();
    }
  }

  render() {
    return (
      <div>
        <div id={this.props.id} className={"Modal1 " + this.props.class} window-id={this.props.windowId}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

WmsModel.propTypes = {
  isOpen: PropTypes.bool.isRequired, // open and close modal
  id: PropTypes.string.isRequired, // this should write the id to the button
  title: PropTypes.string.isRequired, // title of the modal
  width: PropTypes.number,// width of the modal
  height: PropTypes.number.isRequired,// height of the modal
  onCloseFunc: PropTypes.func.isRequired, // function to specify when close modal
  overlayHide: PropTypes.bool, // Specify true if overlay hide
  areyousure: PropTypes.bool, // Specify true if areyousure modal and class based on condition
  customClass: PropTypes.string, // Specify classes if you want to add
  actions: PropTypes.array, // Specify actions if you want to add
  confirmationModal: PropTypes.bool, // Specify true if confirmation modal and class based on condition
  warningModal: PropTypes.bool, // Specify true if warning modal and class based on condition
  isShowTooltipOnTitle: PropTypes.bool, // Specify true if you want to show tooltip on title
  removeCloseAnimation: PropTypes.bool, // Specify true if you want remove modal close animation
  removeOpenAnimation: PropTypes.bool, // Specify true if you want remove modal open animation
  currentWindow: PropTypes.object,
  minHeight: PropTypes.number,
  appendTo: PropTypes.string
};

WmsModel.defaultProps = {
  overlayHide: false,
  areyousure: false,
  customClass: '',
  confirmationModal: false,
  warningModal: false,
  isAnotherModelOpen: false,
  isShowTooltipOnTitle: false,
  class: '',
  windowId: '',
  actions: ["Maximize"],
  removeCloseAnimation:false,
  removeOpenAnimation:false,
  minHeight: 325,
  appendTo: ''
};

export default WmsModel;
