import React from 'react';
import * as GlobalService from '../../../react/global_services';

export const getContactLookupGridColumns = (LookupUp_GridHeaders, CustomLabels) => {
    return ({

        columns: [{
            field: "ContactId",
            title: LookupUp_GridHeaders[0].description,
            width: 80,
            wmsid:1
        }, {
            field: "ContactType",
            title: LookupUp_GridHeaders[1].description,
            filterable: false,
            sortable: false,
            width: 160,
            template: "# if( ContactType== 0) {# Ship To #} else if( ContactType== 1) {# Sold To  #} else if(ContactType== 2) {# Bill To #} else if( ContactType== 3) {# Supplier #} #",
            wmsid:2
        }, {
            field: "Code",
            title: LookupUp_GridHeaders[2].description,
            width: 160,
            wmsid:3
        }, {
            field: "CompanyName",
            title: LookupUp_GridHeaders[3].description,
            width: 160,
            wmsid:4
        }, {
            field: "Name",
            title: LookupUp_GridHeaders[4].description,
            width: 160,
            wmsid:5
        }, {
            field: "Address1",
            title: LookupUp_GridHeaders[5].description,
            width: 160,
            wmsid:6
        }, {
            field: "Address2",
            title: LookupUp_GridHeaders[6].description,
            width: 160,
            wmsid:7
        }, {
            field: "State",
            title: LookupUp_GridHeaders[7].description,
            width: 160,
            wmsid:8
        }, {
            field: "City",
            title: LookupUp_GridHeaders[8].description,
            width: 160,
            wmsid:9
        }, {
            field: "Zip",
            title: LookupUp_GridHeaders[9].description,
            width: 160,
            wmsid:10
        }, {
            field: "Country",
            title: LookupUp_GridHeaders[10].description,
            width: 160,
            wmsid:11
        }, {
            field: "PhoneNumber",
            title: LookupUp_GridHeaders[11].description,
            width: 160,
            wmsid:12
        }, {
            field: "EmailAddress",
            title: LookupUp_GridHeaders[12].description,
            width: 160,
            wmsid:13
        }, {
            field: "RetailerId",
            title: LookupUp_GridHeaders[13].description,
            width: 120,
            filterable: false,
            sortable: false,
            wmsid:14
        }, {
            field: "Dept",
            title: LookupUp_GridHeaders[14].description,
            width: 120,
            wmsid:15
        }, {
            field: "IsQuickLookup",
            title: LookupUp_GridHeaders[15].description,
            width: 120,
            wmsid:16
        }]
    });
};

export const getContactLookupGridSchema = () => {
    return ({
        schema: {
            data: "Data",
            total: "Total",
            model: {
                id: "ContactId",
                fields: {
                    ContactId: {
                        type: "number",
                        editable: false
                    },
                    ContactType: {
                        type: "number",
                        editable: false
                    },
                    Code: {
                        type: "string",
                        editable: false
                    },
                    CompanyName: {
                        type: "string",
                        editable: false
                    },
                    Name: {
                        type: "string",
                        editable: false
                    },
                    Address1: {
                        type: "string",
                        editable: false
                    },
                    Address2: {
                        type: "string",
                        editable: true
                    },
                    State: {
                        type: "string",
                        editable: false
                    },
                    City: {
                        type: "string",
                        editable: false
                    },
                    Zip: {
                        type: "string",
                        editable: false
                    },
                    Country: {
                        type: "string",
                        editable: false
                    },
                    PhoneNumber: {
                        type: "string",
                        editable: true
                    },
                    EmailAddress: {
                        type: "string",
                        editable: false
                    },
                    RetailerId: {
                        type: "number",
                        editable: false
                    },
                    Dept: {
                        type: "string",
                        editable: false
                    },
                    IsQuickLookup: {
                        type: "string",
                        editable: false
                    }
                }
            }
        }
    });
};

export const getContactLookupGridTransport = (param_customer_uri, param_contact_type) => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Shipping/Orders/AdvancedContactGridRead",
                type: "POST",
                data: {
                    customerUri: param_customer_uri,
                    usedAs: param_contact_type
                },
                dataType: "JSON"
            }
        }
    });
};

export const getCustomOrderInfoGridColumns = (customOrderInfo_GridHeaders, refnum_hint_text) => {
    return ({
        columns: [{
            field: 'Name',
            title: customOrderInfo_GridHeaders[0].description,
            width: 180,
            lockable: false,
            template: "#= Required === true ? Name + '*' : Name #",
            wmsid:1
        }, {
            field: 'Value',
            title: customOrderInfo_GridHeaders[1].description,
            width: 180,
            lockable: false,
            template: "#= Value === null && Required === true  ? '<i class=\"wms-required-grid\">" + refnum_hint_text + "*</i>' : Value === null && Required === false ? '<i>" + refnum_hint_text + "</i>' : Value === '' && Required === true ? '<i class=\"wms-required-grid\">" + refnum_hint_text + "*</i>' : Value === '' && Required === false ? '<i>" + refnum_hint_text + "</i>' : Value #",
            wmsid:2
        }]
    });
};

export const getCustomOrderInfoGridSchema = () => {
    return ({
        schema: {
            model: {
                fields: {
                    Name: {
                        type: 'string',
                        editable: false
                    },
                    Value: {
                        type: 'string'
                    },
                    Required: {
                        type: 'boolean'
                    }
                }
            }
        }
    });
};

export const getCalculateChargesGridColumns = (CalculateCharges_GridHeaders, rights) => {

    var translate = window.translate;
    var columns = [{
            field: "ChargeOrigin",
            // title: CalculateCharges_GridHeaders[0].description,
            title: "",
            lockable: false,
            filterable: false,
            width: 160,
            template: "# if( ChargeOrigin== 0) {# " + translate('Label_Predefined_Required') + " #} else if( ChargeOrigin== 1) {# " + translate('Label_Billing_Script') + "  #} else if( ChargeOrigin== 2) {# " + translate('Label_Manual_Charge') + " #} else if( ChargeOrigin== 3) {# " + translate('Label_Predefined') + " #} #",
            wmsid:1
        }, {
            field: "ChargeTypeDescription",
            title: CalculateCharges_GridHeaders[1].description,
            lockable: false,
            width: 160,
            template: "#= ChargeTypeDescription != null ? ChargeTypeDescription : '' #",
            wmsid:2
        }, {
            field: "ChargeLabel",
            title: CalculateCharges_GridHeaders[2].description,
            lockable: false,
            width: 160,
            template: "#= ChargeLabel != null ? ChargeLabel : '' #",
            wmsid:3
        }, {
            field: "UnitDescription",
            title: CalculateCharges_GridHeaders[3].description,
            hidden: true,
            lockable: false,
            width: 160,
            template: "#= UnitDescription != null ? UnitDescription : '' #",
            wmsid:4
        }, {
            field: "ChargePerUnit",
            title: CalculateCharges_GridHeaders[4].description,
            lockable: false,
            width: 160,
            template: "#= ChargePerUnit != null ? ChargePerUnit : '' #",
            wmsid:5
        }, {
            field: "NumberOfUnits",
            title: CalculateCharges_GridHeaders[5].description,
            lockable: false,
            width: 160,
            template: "#= NumberOfUnits != null ? NumberOfUnits : '' #",
            wmsid:6
        }, {
            field: "TotalCharges",
            title: CalculateCharges_GridHeaders[6].description,
            lockable: false,
            width: 160,
            template: "#= TotalCharges != null ? TotalCharges : '' #",
            wmsid:7
        }, {
            field: "GeneralLedgerAccount",
            title: CalculateCharges_GridHeaders[7].description,
            hidden: true,
            lockable: false,
            width: 160,
            template: "#= GeneralLedgerAccount != null ? GeneralLedgerAccount : '' #",
            wmsid:8
        }, {
            field: "SKU",
            title: CalculateCharges_GridHeaders[11].description,
            hidden: true,
            lockable: false,
            width: 160,
            template: "#= SKU != null ? SKU : '' #",
            wmsid:12
        }, {
            field: "BSId",
            hidden: true
        },{
            field: "BSChargeType",
            hidden: true
        },{
            field: "BSPredefinedRateId",
            hidden: true
        }];
        if(rights.hasOwnProperty("Integrations.isNotActiveBillingManagerApp")){
            columns.push({
                field: "ExternalAccountsReceivable",
                title: CalculateCharges_GridHeaders[8].description,
                hidden: true,
                lockable: false,
                width: 160,
                template: "#= ExternalAccountsReceivable != null ? ExternalAccountsReceivable : '' #",
                wmsid:9
            });
            columns.push({
                field: "ExternalItemIdentifier",
                title: CalculateCharges_GridHeaders[9].description,
                hidden: true,
                lockable: false,
                width: 160,
                template: "#= ExternalItemIdentifier != null ? ExternalItemIdentifier : '' #",
                wmsid:10
            });
            columns.push({
                field: "ExternalItemDescription",
                title: CalculateCharges_GridHeaders[10].description,
                hidden: true,
                lockable: false,
                width: 160,
                template: "#= ExternalItemDescription != null ? ExternalItemDescription : '' #",
                wmsid:11
            });
        }
    return ({
        columns: columns
    });
};

export const getCalculateChargesGridSchema = () => {
    return ({
        schema: {
            model: {
                fields: {
                    ChargeOrigin: {
                        type: "number",
                        editable: false
                    },
                    ChargeTypeDescription: {
                        type: "string",
                        editable: false
                    },
                    ChargeLabel: {
                        type: "string",
                        editable: false
                    },
                    UnitDescription: {
                        type: "string",
                        editable: false
                    },
                    ChargePerUnit: {
                        type: "number",
                        editable: true
                    },
                    NumberOfUnits: {
                        type: "number",
                        editable: true
                    },
                    TotalCharges: {
                        type: "number",
                        editable: false
                    },
                    GeneralLedgerAccount: {
                        type: "string",
                        editable: false
                    },
                    ExternalAccountsReceivable: {
                        type: "string",
                        editable: false
                    },
                    ExternalItemIdentifier: {
                        type: "string",
                        editable: false
                    },
                    ExternalItemDescription: {
                        type: "string",
                        editable: false
                    },
                    SKU: {
                        type: "string",
                        editable: false
                    },
                    UnsavedManualCharge: {
                        type: "boolean",
                        editable: false
                    },
                    SystemGenerated: {
                        type: "boolean",
                        editable: false
                    },
                    BSId: {
                        type: "string",
                        editable: false,
                    },
                    BSChargeType: {
                        type: "string",
                        editable: false,
                    },
                    BSPredefinedRateId: {
                        type: "string",
                        editable: false,
                    }
                }
            }
        },
    });
};
export const getOrderItemsGridColumns = (ManageOrdersLineItemsGridHeaders, CustomLabels,actionClickFunc,isFulfillmentEnable,rights) => {
    var translate =  window.translate;
    return ({
        columns: [{
            field: "Sku",
            title: CustomLabels.Label_Sku,
            width: 160,
            format: ("{0}"),
            wmsid:1
        }, {
            field: "ItemDescription",
            title: ManageOrdersLineItemsGridHeaders[2].description,
            width: 160,
            wmsid:2
        }, {
            field: "Qualifier",
            title: ManageOrdersLineItemsGridHeaders[3].description,
            width: 138,
            wmsid:3
        }, {
            field: "Qty",
            title: ManageOrdersLineItemsGridHeaders[4].description,
            width: 84,
            wmsid:4
        }, {
            field: "PrimaryUnits",
            title: ManageOrdersLineItemsGridHeaders[14].description,
            width: 160,
            wmsid:5
        }, {
            field: "SecondaryQty",
            title: ManageOrdersLineItemsGridHeaders[15].description,
            width: 160,
            wmsid:6
        }, {
            field: "SecondaryUnits",
            title: ManageOrdersLineItemsGridHeaders[16].description,
            width: 160,
            wmsid:7
        }, {
            field: "CatchWeight",
            title: ManageOrdersLineItemsGridHeaders[17].description,
            width: 160,
            wmsid:8
        },
        {
            field: "Volume",
            title: ManageOrdersLineItemsGridHeaders[18].description,
            width: 160,
            wmsid:9
        },
        {
            field: "OrderUnitName",
            title: ManageOrdersLineItemsGridHeaders[5].description,
            width: 160,
            wmsid:10
        }, {
            field: "OriginalQty",
            title: ManageOrdersLineItemsGridHeaders[6].description,
            width: 100,
            disabledColumn:rights.hasOwnProperty('Shipping.ShortShipEnabled') != true,
            disabledNote:translate('Order_Line_Item_Disable_Original_Qty_Note'),
            wmsid:11
        },{
                field: "AvailableQty",
                title: ManageOrdersLineItemsGridHeaders[7].description,
                width: 160,
                template: "#= AvailableQty != null ? AvailableQty : 0 #",
                wmsid:12
        },{
            field: "FulfillInvSalePrice",
            title: ManageOrdersLineItemsGridHeaders[8].description,
            width: 90,
            hidden :isFulfillmentEnable == false,
            wmsid:13,
            template: "#= FulfillInvSalePrice ? '$' + FulfillInvSalePrice.toFixed(2) : '$0.00' #",
        }, {
            field: "FulfillInvLineTotal",
            title: ManageOrdersLineItemsGridHeaders[9].description,
            width: 160,
            wmsid:14,
            template: "#= FulfillInvLineTotal ? '$' + FulfillInvLineTotal.toFixed(2) : '$0.00' #",
        },{
            field: "FulfillInvDiscountAmt",
            title: ManageOrdersLineItemsGridHeaders[10].description,
            width: 160,
            hidden :isFulfillmentEnable == false,
            wmsid:15,
            template: "#= FulfillInvDiscountAmt ? '$' + FulfillInvDiscountAmt.toFixed(2) : '-' #",
        }, {
            field: "FulfillInvDiscountPct",
            title: ManageOrdersLineItemsGridHeaders[11].description,
            width: 114,
            hidden :isFulfillmentEnable == false,
            wmsid:16,
            template: "#= FulfillInvDiscountPct ? FulfillInvDiscountPct.toFixed(2) + '%' : '-' #",
        }, {
            field: "FulfillInvLineDiscountTotal",
            title: ManageOrdersLineItemsGridHeaders[12].description,
            width: 97,
            hidden :isFulfillmentEnable == false,
            wmsid:17,
            template: "#= FulfillInvLineDiscountTotal ? '$' + FulfillInvLineDiscountTotal.toFixed(2) : '$0.00' #",
        }, {
            field: "Notes",
            title: ManageOrdersLineItemsGridHeaders[13].description,
            width: 120,
            wmsid:18
        }, {
            field:'grid-action',
            menu: false,
            command: [{
                name:'grid-action',
                text:"",
                className: "charge-delete",
                iconClass: "fa fa-times-circle-o",
                click:actionClickFunc('fromInline')
            }],
            title: "&nbsp;",
            width: 20,
            wmsid:19
        }]
    });
};

export const getOrderItemsGridSchema = () => {
    return ({
        schema: {
            model: {
                id: "OrderItemId",
                fields: {
                    OrderItemId: {
                        type: "number"
                    },
                    ItemId: {
                        type: "number"
                    },
                    Sku: {
                        type: "string",
                        editable: false
                    },
                    Qualifier: {
                        type: "string",
                        editable: true
                    },
                    ItemDescription: {
                        type: "string",
                        editable: false
                    },
                    Qty: {
                        type: "string",
                        editable: true
                    },
                    PrimaryUnits: {
                        type: "string",
                        editable: false
                    },
                    SecondaryQty: {
                        type: "number",
                        editable: true
                    },
                    SecondaryUnits: {
                        type: "string",
                        editable: false
                    },
                    CatchWeight: {
                        type: "number",
                        editable: true
                    },
                    Volume: {
                        type: "number",
                        editable: false
                    },
                    OrderUnitName: {
                        type: "string",
                        editable: false
                    },
                    OriginalQty: {
                        type: "number",
                        editable: false
                    },
                    AvailableQty: {
                        type: "string",
                        editable: false
                    },
                    FullyAllocated: {
                        type: "boolean",
                        editable: false
                    },
                    FulfillInvSalePrice: {
                        type: "float",
                        editable: true
                    },
                    FulfillInvLineTotal: {
                        type: "float",
                        editable: false
                    },
                    FulfillInvDiscountAmt: {
                        type: "float",
                        editable: true
                    },
                    FulfillInvDiscountPct: {
                        type: "float",
                        editable: true
                    },
                    FulfillInvLineDiscountTotal: {
                        type: "float",
                        editable: false
                    },
                    Notes: {
                        type: "string",
                        editable: true
                    },
                    EditUri: {
                        type: "string",
                        editable: false
                    }
                }
            }
        }
    });
};

export const getOrderItemsGridTransport = (orderId, orderItemsUri, inventorySummariesUri) => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Shipping/Orders/GetOrderLineItemData",
                type: "POST",
                data: {
                    orderId: orderId,
                    itemsUri: orderItemsUri,
                    inventorySummariesUri: inventorySummariesUri
                },
                dataType: "JSON"
            },
            update: {
                url: "/WebUI/Shipping/Orders/EditOrderLineItem",
                type: "POST",
                data: {
                    orderId: orderId,
                    inventorySummaryUri: inventorySummariesUri
                },
                dataType: "JSON"
            }
        }
    });
};

export const getLineItemSavedElementColumns = (gridHeader,enterValueText) =>{
    return ({
        columns:[{
            field: 'Name',
            title: gridHeader[0].description,
            width: 180,
            lockable: false,
            template: "#= Required === true ? Name + '*' : Name #"
        }, {
            field: 'Value',
            title: gridHeader[1].description,
            width: 180,
            lockable: false,
            template: "#= Value === null && Required === true  ? '<i class=\"wms-required-grid\">" + enterValueText + "*</i>' : Value === null && Required === false ? '<i>" + enterValueText + "</i>' : Value === '' && Required === true ? '<i class=\"wms-required-grid\">" + enterValueText + "*</i>' : Value === '' && Required === false ? '<i>" + enterValueText + "</i>' : Value #"
        }]
    });
};

export const getLineItemSavedElementSchema = () =>{
    return ({
        schema: {
            model: {
                fields: {
                    Name: {
                        type: 'String',
                        editable: false
                    },
                    Value: {
                        type: 'String'
                    },
                    Required: {
                        type: 'Boolean'
                    }
                }
            }
        }
    });
};

export const getEditAllocationColumns = (gridHeader, customLabels) => {
    return ({
        columns: [{
            field: "PrimaryQuantity",
            "menu": false,
            title: gridHeader[0],
            width: 160,
            wmsid: 1,
            lockable: false,
            sortable: false,
            editor: function (container, options) {
                // create an input element
                $("<input name='" + options.field + "'/>")
                    .appendTo(container)
                    .kendoNumericTextBox({
                        format: "n4",
                        decimals: 4,
                    });
            },
        }, {
            field: "PrimaryUnitType",
            title: gridHeader[1],
            width: 180,
            wmsid: 2,
            sortable: false,
            lockable: false
        }, {
            field: "Available",
            title: gridHeader[2],
            width: 180,
            wmsid: 3,
            lockable: false
        }, {
            field: "Qualifier",
            title: gridHeader[16],
            width: 180,
            wmsid: 17,
            lockable: false,
        }, {
            field: "PalletLabel",
            title: gridHeader[3],
            width: 180,
            wmsid: 4,
            lockable: false
        }, {
            field: "ReceiverId",
            title: gridHeader[4],
            width: 180,
            wmsid: 5,
            lockable: false
        }, {
            field: "ReceiverReferenceNum",
            title: gridHeader[5],
            width: 180,
            wmsid: 6,
            lockable: false
        }, {
            field: "ReceivedDate",
            title: gridHeader[15],
            width: 160,
            wmsid: 7,
            hidden: false,
            lockable: false,
            editable: false,
            template: "#= ReceivedDate != null ? kendo.toString(kendo.parseDate(ReceivedDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
        }, {
            field: "LotNumber",
            title: customLabels.Label_Lot_Number,
            width: 180,
            wmsid: 8,
            lockable: false
        }, {
            field: "SerialNumber",
            title: customLabels.Label_Serial_Number,
            width: 180,
            wmsid: 9,
            lockable: false
        }, {
            field: "ExpirationDate",
            title: gridHeader[8],
            width: 180,
            wmsid: 10,
            lockable: false,
            template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
        }, {
            field: "Cost",
            title: gridHeader[9],
            width: 180,
            wmsid: 11,
            lockable: false
        }, {
            field: "PurchaseOrderNumber",
            title: gridHeader[10],
            width: 180,
            wmsid: 12,
            lockable: false
        }, {
            field: "TrailerNumber",
            title: gridHeader[11],
            width: 180,
            wmsid: 13,
            lockable: false
        }, {
            field: "LocationName",
            title: gridHeader[12],
            width: 180,
            wmsid: 14,
            lockable: false
        }, {
            field: "SupplierName",
            title: gridHeader[13],
            width: 180,
            wmsid: 15,
            lockable: false
        }, {
            field: "CustomFields",
            title: gridHeader[14],
            width: 180,
            wmsid: 16,
            lockable: false,
            sortable: false
        }]
    });
};
export const getEditAllocationSchema = (selector, $) => {
    return ({
        schema: {
            data: "Data",
            total: "Total",
            model: {
                fields: { //I'm not sure about these types
                    PrimaryQuantity: {
                        type: "number",
                        editable: true,
                        validation: {
                            productnamevalidation: function (input) {
                                const qtyFloat = parseFloat(input.val());
                                if (
                                    input.is(`[name=${"'PrimaryQuantity'"}]`) &&
                                    input.val() !== "" &&
                                    !Number.isNaN(qtyFloat) &&
                                    qtyFloat !== 0
                                ) {
                                    var grid = $(selector).data("kendoGrid");
                                    var dataItem = grid.dataItem($(input).closest("tr"));
                                    const inpAttr = "data-productnamevalidation-msg";
                                    var isIntegral = GlobalService.getIsIntegeralFlagByType(dataItem.PrimaryUnitType);
                                    if (isIntegral) {
                                        input.attr(inpAttr, "This UOM only supports whole-number quantities");
                                        return Math.floor(qtyFloat) === qtyFloat;
                                    }
                                }

                                return true;
                            },
                            min: 0,
                        }
                    },
                    PrimaryUnitType: {
                        type: "string",
                        editable: false
                    },
                    Available: {
                        type: "number",
                        editable: false
                    },
                    PalletLabel: {
                        type: "string",
                        editable: false
                    },
                    ReceiverId: {
                        type: "string",
                        editable: false
                    },
                    ReceiverReferenceNum: {
                        type: "string",
                        editable: false
                    },
                    ReceivedDate: {
                        type: 'date',
                        editable: false
                    },
                    LotNumber: {
                        type: "string",
                        editable: false
                    },
                    SerialNumber: {
                        type: "string",
                        editable: false
                    },
                    ExpirationDate: {
                        type: "DateTime",
                        editable: false
                    },
                    Cost: {
                        type: "number",
                        editable: false
                    },
                    PurchaseOrderNumber: {
                        type: "string",
                        editable: false
                    },
                    TrailerNumber: {
                        type: "string",
                        editable: false
                    },
                    LocationName: {
                        type: "string",
                        editable: false
                    },
                    SupplierName: {
                        type: "string",
                        editable: false
                    },
                    CustomFields: {
                        type: "string",
                        editable: false
                    }, Qualifier: {
                        type: "string",
                        editable: false,
                    },
                }
            }
        }
    });
};

export const getEditAllocationTransport = (customerId, facilityId, itemId, qualifier,orderItemId) => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Shipping/Orders/EditLineItemAllocationsGridRead",
                type: "POST",
                data: {
                    customerId: customerId,
                    facilityId: facilityId,
                    itemId: itemId,
                    qualifier: qualifier,
                    orderItemId: orderItemId
                }
            },
        }
    });
};

export const getMassAddColumns = (gridHeader, customLabels) => {
    return ({
        columns: [{
            field: "PrimaryUnitsToAdd",
            sortable: false,
            menu: false,
            title: gridHeader[0],
            width: 180,
            wmsid: 1,
            lockable: false,
            headerAttributes: {
                "ng-non-bindable": true
            },
            attributes: {
                "class": "wms-fake-look-input"
            }
        }, {
            field: "ItemImage",
            title: customLabels.Label_Item + " Image",
            width: 160,
            wmsid: 2,
            lockable: false,
            hidden: true,
            editable: false,
            sortable: false
        }, {
            field: "SKU",
            sortable: true,
            title: customLabels.Label_Sku,
            width: 180,
            wmsid: 3,
            lockable: false,
            editable: false
        }, {
            field: "Qualifier",
            title: gridHeader[3],
            width: 160,
            wmsid: 4,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "Description",
            title: gridHeader[4],
            width: 160,
            wmsid: 5,
            lockable: false,
            editable: false
        }, {
            field: "Available",
            title: gridHeader[6],
            width: 160,
            wmsid: 6,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "PrimaryUnitType",
            sortable: false,
            title: gridHeader[5],
            width: 160,
            wmsid: 7,
            lockable: false,
            editable: false
        }, {
            field: "PalletLabel",
            title: gridHeader[7],
            width: 160,
            wmsid: 8,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "ReceiverId",
            title: gridHeader[8],
            width: 160,
            wmsid: 9,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "ReceiverReferenceNum",
            title: gridHeader[9],
            width: 160,
            wmsid: 10,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "ReceivedDate",
            title: gridHeader[10],
            width: 160,
            wmsid: 11,
            hidden: false,
            lockable: false,
            editable: false,
            template: "#= ReceivedDate != null ? kendo.toString(kendo.parseDate(ReceivedDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
        }, {
            field: "LotNumber",
            title: customLabels.Label_Lot_Number,
            width: 160,
            wmsid: 12,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "SerialNumber",
            title: customLabels.Label_Serial_Number,
            width: 160,
            wmsid: 13,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "ExpirationDate",
            title: gridHeader[13],
            width: 160,
            wmsid: 14,
            hidden: false,
            lockable: false,
            editable: false,
            template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
        }, {
            field: "Cost",
            title: gridHeader[14],
            width: 160,
            wmsid: 15,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "PurchaseOrderNumber",
            title: gridHeader[15],
            width: 160,
            wmsid: 16,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "TrailerNumber",
            title: gridHeader[16],
            width: 160,
            wmsid: 17,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "LocationName",
            title: gridHeader[17],
            width: 160,
            wmsid: 18,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "SupplierName",
            title: gridHeader[18],
            width: 160,
            wmsid: 19,
            hidden: false,
            lockable: false,
            editable: false
        }, {
            field: "CustomFields",
            title: gridHeader[19],
            width: 160,
            wmsid: 20,
            hidden: false,
            lockable: false,
            editable: false,
            sortable: false
        }]
    });
};

export const getMassAddSchema = (validNumberText) => {
    return({
        schema:{
            data: "Data",
            total: "Total",
            model: {
                fields: {
                    PrimaryUnitsToAdd: {
                        type: "float",
                        editable: true,
                        validation: {
                            primaryunitstoaddvalidation: function (input) {
                                if (input.is("[name='PrimaryUnitsToAdd']") && input.val() != "") {
                                    input.attr("data-primaryunitstoaddvalidation-msg", validNumberText);
                                    return /^[0-9]*(?:\.[0-9]{0,4})?$/.test(input.val());
                                }
                                return true;
                            }
                        }
                    },
                    ItemImage: {
                        type: "string",
                        editable: false
                    },
                    SKU: {
                        type: "string",
                        editable: false
                    },
                    Qualifier: {
                        type: "string",
                        editable: false
                    },
                    Description: {
                        type: "string",
                        editable: false
                    },
                    Available: {
                        type: "number",
                        editable: false
                    },
                    PrimaryUnitType: {
                        type: "string",
                        editable: false
                    },
                    PrimaryQuantity: {
                        type: "number",
                        editable: false
                    },
                    PalletLabel: {
                        type: "string",
                        editable: false
                    },
                    ReceiverId: {
                        type: "number",
                        editable: false
                    },
                    ReceiverReferenceNum: {
                        type: "string",
                        editable: false
                    },
                    ReceivedDate: {
                        type: 'date',
                        editable: false
                    },
                    LotNumber: {
                        type: "string",
                        editable: false
                    },
                    SerialNumber: {
                        type: "string",
                        editable: false
                    },
                    ExpirationDate: {
                        type: "string",
                        editable: false
                    },
                    Cost: {
                        type: "number",
                        editable: false
                    },
                    PurchaseOrderNumber: {
                        type: "string",
                        editable: false
                    },
                    TrailerNumber: {
                        type: "string",
                        editable: false
                    },
                    LocationName: {
                        type: "string",
                        editable: false
                    },
                    SupplierName: {
                        type: "string",
                        editable: false
                    },
                    CustomFields: {
                        type: "string",
                        editable: false
                    }
                }
            }
        }
    });
};

export const getMassAddTransport = (customerId, facilityId) => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Shipping/Orders/MassAddGridRead",
                type: "POST",
                data: {
                    customerId: customerId,
                    facilityId: facilityId
                }
            },
        }
    });
};

export const getGridDefaultColumnsForFullfillment = (ManageOrdersLineItemsGridHeaders, CustomLabels,actionClickFunc,isFulfillmentEnable) => {
    return ({
        columns: [{
            field: "Sku",
            title: CustomLabels.Label_Sku,
            width: 160,
            format: ("{0}"),
            wmsid:1
        }, {
            field: "Qualifier",
            title: ManageOrdersLineItemsGridHeaders[3].description,
            width: 138,
            wmsid:3,
        }, {
            field: "Qty",
            title: ManageOrdersLineItemsGridHeaders[4].description,
            width: 84,
            wmsid:4
        }, {
            field: "FulfillInvSalePrice",
            title: ManageOrdersLineItemsGridHeaders[8].description,
            width: 90,
            wmsid:13,
            template: "#= FulfillInvSalePrice ? '$' + FulfillInvSalePrice.toFixed(2) : '$0.00' #",
        },{
            field: "FulfillInvDiscountAmt",
            title: ManageOrdersLineItemsGridHeaders[10].description,
            width: 160,
            wmsid:15,
            template: "#= FulfillInvDiscountAmt ? '$' + FulfillInvDiscountAmt.toFixed(2) : '-' #",
        }, {
            field: "FulfillInvDiscountPct",
            title: ManageOrdersLineItemsGridHeaders[11].description,
            width: 114,
            wmsid:16,
            template: "#= FulfillInvDiscountPct ? FulfillInvDiscountPct.toFixed(2) + '%' : '-' #",
        }, {
            field: "FulfillInvLineDiscountTotal",
            title: ManageOrdersLineItemsGridHeaders[12].description,
            width: 97,
            wmsid:17,
            template: "#= FulfillInvLineDiscountTotal ? '$' + FulfillInvLineDiscountTotal.toFixed(2) : '$0.00' #",
        }, {
            field:'grid-action',
            menu: false,
            command: [{
                name:'grid-action',
                text:"",
                className: "charge-delete",
                iconClass: "fa fa-times-circle-o",
                click:actionClickFunc('fromInline')
            }],
            title: "&nbsp;",
            width: 20,
            wmsid:19
        }]
    });
};

export const getGridDefaultColumns = (ManageOrdersLineItemsGridHeaders, CustomLabels,actionClickFunc,isFulfillmentEnable) => {
    return ({
        columns: [{
            field: "Sku",
            title: CustomLabels.Label_Sku,
            width: 160,
            format: ("{0}"),
            wmsid:1
        }, {
            field: "Qty",
            title: ManageOrdersLineItemsGridHeaders[4].description,
            width: 84,
            wmsid:4
        }, {
            field: "PrimaryUnits",
            title: ManageOrdersLineItemsGridHeaders[14].description,
            width: 160,
            wmsid:5
        },{
            field: "SecondaryQty",
            title: ManageOrdersLineItemsGridHeaders[15].description,
            width: 160,
            wmsid:6
        }, {
            field: "SecondaryUnits",
            title: ManageOrdersLineItemsGridHeaders[16].description,
            width: 160,
            wmsid:7
        }, {
            field: "CatchWeight",
            title: ManageOrdersLineItemsGridHeaders[17].description,
            width: 160,
            wmsid:8
        }, {
            field: "Volume",
            title: ManageOrdersLineItemsGridHeaders[18].description,
            width: 160,
            wmsid:9
        }, {
            field: "OrderUnitName",
            title: ManageOrdersLineItemsGridHeaders[5].description,
            width: 160,
            wmsid:10
        }, {
            field:'grid-action',
            menu: false,
            command: [{
                name:'grid-action',
                text:"",
                className: "charge-delete",
                iconClass: "fa fa-times-circle-o",
                click:actionClickFunc('fromInline')
            }],
            title: "&nbsp;",
            width: 20,
            wmsid:19
        }]
    });
};