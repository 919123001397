import * as GlobalService from '../../global_services';
var isFirstTimeOpen = true;
var keyName = "ReceiptProto";
var metaDataReceiptObject = {
    "ReceiptProto": {
        "FindReceipt": {
            "filterData": {

            },
            "gridConfig": {
                "findReceiptGrid": {

                }
            },
            "saveFilter": [],
            "sorableSection": "",
            "collapseData": {

            },
            "confirmReceipt": {
                "additionalChargeGrid": {

                }
            }
        }
    }
};


// call api for get metaData of order is it's open first time
export const getUserClientMetaData = function () {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response != "") {
                    Object.assign(metaDataReceiptObject[keyName], JSON.parse(response));
                }
                console.log("response  metaDataReceiptObject :", metaDataReceiptObject);
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                console.log(response);
                return false;
            }
        );
    }
}

export const setFindReceiptCollapseMetaData = function (collapseId, value) {
    if (!metaDataReceiptObject[keyName]['FindReceipt']['collapseData']) {
        metaDataReceiptObject[keyName]['FindReceipt']['collapseData'] = {}
    }
    metaDataReceiptObject[keyName]['FindReceipt']['collapseData'][collapseId] = value;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataReceiptObject[keyName]));
}
export const getFindReceiptCollapseMetaData = function () {
    return metaDataReceiptObject[keyName]['FindReceipt']['collapseData'];
}


export const setFindReceiptSortSectionMetaData = function (sortArray) {
    metaDataReceiptObject[keyName]['FindReceipt']['sorableSection'] = sortArray;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataReceiptObject[keyName]));
}

export const getFindReceiptSortSectionMetaData = function () {
    return metaDataReceiptObject[keyName]['FindReceipt']['sorableSection'];
}

export const setFindReceiptFilterMetaData = function (filterData) {
    metaDataReceiptObject[keyName]['FindReceipt']['filterData'] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataReceiptObject[keyName]));
}

export const getFindReceiptFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataReceiptObject[keyName]['FindReceipt']['filterData'])) {
        return metaDataReceiptObject[keyName]['FindReceipt']['filterData'];
    } else {
        return "";
    }

}

// save filter of find order in meta data
export const saveFindReceiptFilterMetaData = function (title, filterObj, columnObj) {
    var setData = {
        title: title,
        filterData: filterObj,
        columnData: columnObj.optionsData
    };
    if (typeof metaDataReceiptObject[keyName]['FindReceipt']['saveFilter'] == "undefined") {
        metaDataReceiptObject[keyName]['FindReceipt']['saveFilter'] = [];
    }
    (metaDataReceiptObject[keyName]['FindReceipt']['saveFilter']).push(setData);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataReceiptObject[keyName]));

}
export const updateFindReceiptFilterMetaData = function (index, filterObj, columnObj) {

    if (typeof metaDataReceiptObject[keyName]['FindReceipt']['saveFilter'][index] != "undefined") {
        metaDataReceiptObject[keyName]['FindReceipt']['saveFilter'][index].filterData = filterObj;
        metaDataReceiptObject[keyName]['FindReceipt']['saveFilter'][index].columnData = columnObj.optionsData;
        GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataReceiptObject[keyName]));
    }

}
export const getSaveFindReceiptFilterListMetaData = function (objName) {
    return metaDataReceiptObject[keyName]['FindReceipt']['saveFilter'];
}

export const clearSaveFindReceiptFilterListMetaData = function (index) {
    metaDataReceiptObject[keyName]['FindReceipt']['saveFilter'].splice(index, 1);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataReceiptObject[keyName]));
}

export const applySavedFilterDataToFindReceiptGrid = function (gridConfig, filterData) {
    metaDataReceiptObject[keyName]['FindReceipt']['gridConfig']['findReceiptGrid'] = gridConfig.optionsData;
    metaDataReceiptObject[keyName]['FindReceipt']['filterData'] = filterData;

    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataReceiptObject[keyName]));
}

export const clearFindReceiptFilterMetaData = function (objName) {
    metaDataReceiptObject[keyName]['FindReceipt']['filterData'] = {};
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataReceiptObject[keyName]));
}

export const setFindReceiptGridOptionInMetaData = function (gridConfig) {
    metaDataReceiptObject[keyName]['FindReceipt']['gridConfig']['findReceiptGrid'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataReceiptObject[keyName]));
}

export const getFindReceiptGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataReceiptObject[keyName]['FindReceipt']['gridConfig']['findReceiptGrid'])) {
        return metaDataReceiptObject[keyName]['FindReceipt']['gridConfig']['findReceiptGrid'];
    } else {
        return "";
    }
}
export const setAdditionalChargeGridOptionInMetaData = function (gridConfig) {
    if(!metaDataReceiptObject[keyName]['FindReceipt']['confirmReceipt']) {
        metaDataReceiptObject[keyName]['FindReceipt']['confirmReceipt'] = {};
    }
    metaDataReceiptObject[keyName]['FindReceipt']['confirmReceipt']['additionalChargeGrid'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataReceiptObject[keyName]));
}

export const getAdditionalChargeGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataReceiptObject[keyName]['FindReceipt']['confirmReceipt']) && !$.isEmptyObject(metaDataReceiptObject[keyName]['FindReceipt']['confirmReceipt']['additionalChargeGrid'])) {
        return metaDataReceiptObject[keyName]['FindReceipt']['confirmReceipt']['additionalChargeGrid'];
    } else {
        return "";
    }
}