import React from 'react';

//component
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';

import WmsCollapse from './../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsModel from './../../../components/WmsModel/WmsModel.jsx';
import WmsTextArea from "./../../../components/WmsTextArea/WmsTextArea.jsx";

import { ObjectWms } from '../../../../../assets/ts/ObjectWms.ts';
import { ArrayWms } from '../../../../../assets/ts/ArrayWms.ts';
import * as GlobalService from '../../../global_services';

var UtcOffset = new Date().getTimezoneOffset() / 60 * -1;

import './bill-of-lading.scss';
var axios = require('axios');
export class BillOfLadingModel extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        this.defaultFormData = {
            "OrderId": props.OrderId,
            "ReferenceNum": "picktest025",
            "Carrier": {
                "Value": "",
                "State": 2,
                "HasChanged": false
            },
            "ScacCode": {
                "Value": "",
                "State": 2,
                "HasChanged": false
            },
            "Service": {
                "Value": "",
                "State": 2,
                "HasChanged": false
            },
            "BillingCode": {
                "Value": "",
                "State": 2,
                "HasChanged": false
            },
            "BolNumber": {
                "Value": "",
                "State": 2,
                "HasChanged": false
            },
            "TrackingNumber": {
                "Value": "",
                "State": 2,
                "HasChanged": false
            },
            "TrailerNumber": {
                "Value": "",
                "State": 2,
                "HasChanged": false
            },
            "SealNumber": {
                "Value": "",
                "State": 2,
                "HasChanged": false
            },
            "ShippingNotes": {
                "Value": "",
                "State": 2,
                "HasChanged": false
            },
            "PackageCount": {
                "Value": 0,
                "State": 2,
                "HasChanged": false
            },
            "PackagingUOM": {
                "Value": "",
                "State": 2,
                "HasChanged": false
            },
            "HandlingUnitQuantity": {
                "Value": 0,
                "State": 2,
                "HasChanged": false
            },
            "HandlingUnitType": {
                "Value": "",
                "State": 2,
                "HasChanged": false
            },
            "TotalWeight": {
                "Value": 0,
                "State": 0,
                "HasChanged": false
            }
        };
        this.state = {
            Selector: "BillOfLading",
            generateGif: false,
            isOpenLadingModel: props.isOpenLadingModel,
            carrierOptions: [],
            bolOptions: [],
            billingTypeOptions: [],
            serviceLevelOptions: [],
            UnitofMeasureTypesOptions: [],
            formData: this.defaultFormData,
            carrierIndex: ""
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isOpenLadingModel && !this.state.isOpenLadingModel) {
            this.setState({
                isOpenLadingModel: true
            });
        }
    }
    componentDidMount() {
        let uri = "orderId=" + this.props.orderId + "&customerId=" + this.props.customerId + "&bolGeneratorUri=/orders/" + this.props.orderId + "/bolidgenerator?bolnumastransnum=false"
        console.log("uri :", uri);
        kendo.ui.progress($('#billoflading'), true);
        axios.get('/WebUI/Shipping/FindOrders/GetBOLData?' + uri)
            .then((response) => {
                var bolOptions = this.sortBolOptions(response.data.ReportTemplates[0].Reports);
                let columnIndex = ObjectWms.FindIndexByProperty(
                    response.data.Carriers.Carriers,
                    "Carrier",
                    response.data.FormData.Carrier.Value
                );

                var billingTypeOptions = response.data.Carriers.DefaultBillingCodes;
                var serviceLevelOptions = response.data.Carriers.DefaultShipmentServices;

                if (columnIndex !== -1) {
                    var carrierObj = response.data.Carriers.Carriers[columnIndex];
                    billingTypeOptions = carrierObj.BillingCodes;
                    serviceLevelOptions = carrierObj.ShipmentServices;
                }

                this.setState({
                    carrierOptions: response.data.Carriers.Carriers,
                    billingTypeOptions: billingTypeOptions,
                    serviceLevelOptions: serviceLevelOptions,
                    UnitofMeasureTypesOptions: response.data.UnitofMeasureTypes,
                    bolOptions: bolOptions,

                    billOfLadingTemplate: bolOptions[0].TemplatedRunner,
                    formData: response.data.FormData,
                    carrierIndex: columnIndex
                });

                // if bill of landing only one option than select first and move focus on bill of landing number
                if (bolOptions.length == 1) {
                    setTimeout(() => {
                        $("#billOfLadingNumber").focus();
                    }, 300);
                }
                kendo.ui.progress($('#billoflading'), false);
            }).finally(() => {                
                kendo.ui.progress($('#billoflading'), false);
            });
    }

    sortBolOptions = (param_response) => {
        var sortOptions = [];
        sortOptions = param_response.sort(function (first, second) {
            if (first.Name == 'Default' || second.Name == 'Default') { return -1; }
            var firstReport = first.Name.toUpperCase(); // ignore upper and lowercase
            var secondReport = second.Name.toUpperCase(); // ignore upper and lowercase

            if (firstReport < secondReport) { return -1; }

            if (firstReport > secondReport) { return 1; }
            // names must be equal
            return 0;
        });
        return sortOptions;
    }
    templateHandleChange = (e) => {
        var name = e.target.name;
        this.setState({ [name]: e.target.value });
    }
    handleChange = (e) => {
        var name = e.target.name;

        if (name == "HandlingUnitType" || name == "PackagingUOM") {
            this.setState({
                formData: Object.assign(
                    this.state.formData,
                    {
                        [e.target.name]: {
                            "Name": e.target.value,
                            "Id": 0 // find id from option using name and attach here if it's present in option
                        }
                    }
                )
            });
        } else {
            this.setState({
                formData: Object.assign(
                    this.state.formData,
                    {
                        [e.target.name]: {
                            "Value": e.target.value,
                            "State": 2,
                            "HasChanged": true,
                        }
                    }
                )
            });
        }

    }
    onCarrierChange = (e) => {
        var valueObj = this.state.carrierOptions[e.target.value]
        this.setState({
            formData: Object.assign(
                this.state.formData,
                {
                    "Carrier": {
                        "Value": valueObj.Carrier,
                        "State": 2,
                        "HasChanged": true,
                    },
                    "ScacCode": {
                        "Value": valueObj.ScacCode,
                        "State": 2,
                        "HasChanged": true,
                    },
                }
            ),
            billingTypeOptions: valueObj.BillingCodes,
            serviceLevelOptions: valueObj.ShipmentServices,
            [e.target.name]: e.target.value,
        });
    }

    generateBillOfLading = (e) => {
        this.setState({
            generateGif: true
        })
        var finalObject = {
            "formData": this.state.formData,
            "BOLRequestBodyModel": {
                "RunnerUriTemplate": this.state.billOfLadingTemplate,
                "TransactionIds": [this.props.orderId],
                "BolNumAsTransNum": false,
                "IgnoreDuplicates": true,
                "UtcOffset": UtcOffset,
                "OutputType": "application/pdf"
            }
        }
        console.log("generateBillOfLading call finalObject:", finalObject);
        axios.put('/WebUI/Shipping/FindOrders/SaveOrderAndBOLReport', finalObject)
            .then((response) => {
                this.handleResponseMenuPrint(response.data);
            })
            .finally(() => {
                this.setState({
                    generateGif: false,
                    isOpenLadingModel: false
                })
            });

    }
    handleResponseMenuPrint = (data) => {
        var blob = ArrayWms.convertBase64ToByteArray(data, 'application/pdf');
        ArrayWms.displayBlobInWindow(blob, 'report.pdf');
    }
    render() {
        return (
            <WmsModel
                id={"billoflading"}
                class="billoflading"
                title={this.translate('Label_Bill_of_Lading') + ' (' + this.translate('Orders_Lbl_Transaction_Id') + ' #' + this.props.orderId + ')'}
                width={800}
                onCloseFunc={this.props.onCloseFunc}
                height={780}
                isOpen={this.state.isOpenLadingModel}
                customClass='wms-model-new-ui-wrapper'
            >
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'templateSection'}
                        headerLabel={this.translate("Label_Template")}
                        showCollapseText={false}
                        showCollapseIcon={true} >
                        <WmsDropdown
                            id="billOfLadingTemplate"
                            name="billOfLadingTemplate"
                            label={this.translate("Label_Bill_of_Lading_Template")}
                            textField='Name'
                            valueField='TemplatedRunner'
                            wmsSelector={this.state.Selector + 'billOfLadingTemplate'}
                            value={this.state.billOfLadingTemplate}
                            onChangeFunc={this.templateHandleChange}
                            options={this.state.bolOptions}
                            extraWrapperClass={'bol-dropdown-wrapper'}
                        // applyIndex={true}
                        />
                    </WmsCollapse>
                    <WmsCollapse
                        id={'generalSection'}
                        headerLabel={this.translate("Label_General")}
                        showCollapseText={false}
                        showCollapseIcon={true} >
                        <div className='general-section-wrapper'>
                            <div className="content-wrapper">
                                <WmsInput
                                    id="billOfLadingNumber"
                                    inputName="BolNumber"
                                    name="BolNumber"
                                    wmsSelector={this.state.Selector + 'billOfLadingNumber'}
                                    value={this.state.formData.BolNumber.Value}
                                    onChangeFunc={this.handleChange}
                                    label={this.translate('Label_Bill_Of_Lading_Number')}
                                />

                            </div>
                            <div className="content-wrapper">
                                <WmsDropdown
                                    id="carrier"
                                    name="carrierIndex"
                                    label={this.translate("Label_Carrier")}
                                    textField='Carrier'
                                    valueField='DisplayName'
                                    wmsSelector={this.state.Selector + 'carrier'}
                                    value={this.state.carrierIndex}
                                    onChangeFunc={this.onCarrierChange}
                                    options={this.state.carrierOptions}
                                    blankFirstOption={true}
                                    applyIndex={true}
                                    extraWrapperClass={'carrier-dropdown-wrapper'}
                                />
                                <WmsInput
                                    inputName="ScacCode"
                                    name="ScacCode"
                                    wmsSelector={this.state.Selector + 'scac'}
                                    value={this.state.formData.ScacCode.Value}
                                    onChangeFunc={this.handleChange}
                                    label={this.translate('Label_SCAC')}
                                    extraWrapperClass={'scacode-input-wrapper'}
                                />
                                <WmsDropdown
                                    id="Service"
                                    name="Service"
                                    label={this.translate("Label_Service_Level")}
                                    textField='Description'
                                    valueField='Code'
                                    wmsSelector={this.state.Selector + 'serviceLevel'}
                                    value={this.state.formData.Service.Value}
                                    onChangeFunc={this.handleChange}
                                    options={this.state.serviceLevelOptions}
                                    blankFirstOption={true}
                                    extraWrapperClass={'service-level-dropdown-wrapper'}
                                />
                                <WmsDropdown
                                    id="billingType"
                                    name="BillingCode"
                                    label={this.translate("Label_Billing")}
                                    textField='Code'
                                    valueField='CodeTypeId'
                                    wmsSelector={this.state.Selector + 'billingType'}
                                    value={this.state.formData.BillingCode.Value}
                                    onChangeFunc={this.handleChange}
                                    options={this.state.billingTypeOptions}
                                    blankFirstOption={true}
                                    extraWrapperClass={'billing-type-dropdown-wrapper'}
                                // applyIndex={true}
                                />
                            </div>
                            <div className="content-wrapper">
                                <WmsInput
                                    inputName="TrailerNumber"
                                    name="TrailerNumber"
                                    wmsSelector={this.state.Selector + 'trailerNumber'}
                                    value={this.state.formData.TrailerNumber.Value}
                                    onChangeFunc={this.handleChange}
                                    label={this.translate('Label_Trailer_Number')}
                                    extraWrapperClass={'trailer-input-wrapper'}
                                />
                                <WmsInput
                                    inputName="SealNumber"
                                    name="SealNumber"
                                    wmsSelector={this.state.Selector + 'sealNumber'}
                                    value={this.state.formData.SealNumber.Value}
                                    onChangeFunc={this.handleChange}
                                    label={this.translate('Label_Seal_Number')}
                                    extraWrapperClass={'seal-input-wrapper'}
                                />
                                <WmsInput
                                    inputName="TrackingNumber"
                                    name="TrackingNumber"
                                    wmsSelector={this.state.Selector + 'trackingNumber'}
                                    value={this.state.formData.TrackingNumber.Value}
                                    onChangeFunc={this.handleChange}
                                    label={this.translate('Label_Tracking_Pro_Number')}
                                    extraWrapperClass={'tracking-input-wrapper'}
                                />
                            </div>
                            <div className="content-wrapper">
                                <WmsTextArea
                                    id='ShippingNotes'
                                    name="ShippingNotes"
                                    wmsSelector={this.state.selector + 'ShippingNotes'}
                                    label={this.translate('Label_Shipping_Notes')}
                                    parentClassName={'shipping-textarea-wrapper'}
                                    onChangeFunc={this.handleChange}
                                    value={this.state.formData.ShippingNotes.Value}
                                    rows={3}
                                    cols={29} />
                            </div>
                        </div>
                    </WmsCollapse>
                    <WmsCollapse
                        id={'orderInformationSection'}
                        headerLabel={this.translate("Label_Customer_Order_Information")}
                        showCollapseText={false}
                        showCollapseIcon={true} >
                        <div className={"order-info-section-wrapper"}>
                            <WmsInput
                                inputName="PackageCount"
                                name="PackageCount"
                                wmsSelector={this.state.Selector + 'packageCount'}
                                value={this.state.formData.PackageCount.Value}
                                onChangeFunc={this.handleChange}
                                label={this.translate('Label_Package_Count')}
                            />
                            <WmsDropdown
                                id="PackagingUOM"
                                name="PackagingUOM"
                                label={this.translate("Label_Packaging_UOM")}
                                textField='Name'
                                valueField='Name'
                                wmsSelector={this.state.Selector + 'packagingUom'}
                                value={this.state.formData.PackagingUOM ? this.state.formData.PackagingUOM.Name : ""}
                                onChangeFunc={this.handleChange}
                                options={this.state.UnitofMeasureTypesOptions}
                            // applyIndex={true}
                            />
                            <WmsInput
                                inputName="HandlingUnitQuantity"
                                name="HandlingUnitQuantity"
                                wmsSelector={this.state.Selector + 'totalMvUntis'}
                                value={this.state.formData.HandlingUnitQuantity.Value}
                                onChangeFunc={this.handleChange}
                                label={this.translate('Label_Total_Movable_Units')}
                            />
                            <WmsDropdown
                                id="HandlingUnitType"
                                name="HandlingUnitType"
                                label={this.translate("Label_Movable_UOM")}
                                textField='Name'
                                valueField='Name'
                                wmsSelector={this.state.Selector + 'movableUnitUom'}
                                value={this.state.formData.HandlingUnitType ? this.state.formData.HandlingUnitType.Name : ""}
                                onChangeFunc={this.handleChange}
                                options={this.state.UnitofMeasureTypesOptions}
                            // applyIndex={true}
                            />

                            <WmsInput
                                inputName="TotalWeight"
                                name="TotalWeight"
                                wmsSelector={this.state.Selector + 'totalWeight'}
                                value={this.state.formData.TotalWeight.Value}
                                onChangeFunc={this.handleChange}
                                label={this.translate('Label_Total_Weight')}
                            />
                        </div>
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.state.Selector + "ButtonCancel"}
                        onClickFunc={this.props.onCloseFunc}
                        icon="fa-ban"
                        buttonType="yellow"
                        loading={false}
                    />
                    <WmsButton
                        label={this.translate('Label_Save_And_Generate')}
                        wmsSelector={this.state.Selector + "Generate"}
                        onClickFunc={this.generateBillOfLading}
                        loading={this.state.generateGif}
                        icon={'fa-print'}
                    />
                </div>
            </WmsModel>
        )
    }
}