import React, { useEffect, useState, useCallback } from "react";
import VirtualGridComponent from "../../../components_proto/VirtualGridComponent/VirtualGridComponent";
import {
    getSmallParcelBatches,
    getSmallParcelBatchZplContents,
    putSmallParcelBatchLabels,
    removeBatchErrors,
    updateBatchStatus,
} from "../../small-parcel-hvp.service";
import * as GridConfig from "./small-parcel-hvp-grid_configuration.virtual";
import * as GlobalService from "../../../global_services";
import { StringWms } from "../../../../../assets/ts/StringWms";
import type { GridFilter } from "./small-parcel-hvp-grid_configuration.virtual";
import CreateHVPBatchModal from "../modals/create-hvp-batch-modal";
import { KendoGridWmsVirtual } from "../../../../../assets/ts/KendoGridWmsVirtual";
import { default as WmsModal } from "../../../components/WmsModel/WmsModel.jsx";
import WmsButton from "../../../components/WmsButton/WmsButton.jsx";
import HVPBatchErrorModal from "../modals/hvp-batch-error-modal";
import { SmallParcelHolder } from "../../../smallParcel/smallParcel-holder.component";
import axios from "axios";

interface SmallParcelHVPGridProps {
    windowId: string;
    filters: GridFilter[];
}

let filterCountAxiosSource = null;

export default function SmallParcelHVPGrid(props: SmallParcelHVPGridProps) {
    /**
     * Global variables
     */
    const translate = (window as any).translate as any; //used to translate texts;

    /**
     * Constants
     */
    const wmsSelector = "SmallParcelHVP";
    const smallParcelHVPGridId = "SmallParcelHVPRemoteGrid";
    const smallParcelHVPGridHeaders = StringWms.convertSringToOption(translate("SmallParcelHVP_GridHeaders"));
    const defaultColumns = GridConfig.getGridDefaultColumns({
        customLabels: GlobalService.getCustomLabels(),
        gridHeaders: smallParcelHVPGridHeaders,
    });

    /**
     * useStates
     */
    const [gridData, setGridData] = useState([]);
    const [openCreateHVPBatchModal, setOpenCreateHVPBatchModal] = useState(false);
    const [openPrintHvpBatchModal, setOpenPrintHvpBatchModal] = useState(false);
    const [gridRowCount, setGridRowCount] = useState(0);
    const [selectedBatches, setSelectedBatches] = useState([]);
    const [manageOptionsDisableStatus, setManageOptionsDisableStatus] = useState({
        printHvp: {
            documents: true,
            labels: true,
        },
        updateBatch: true,
        resetFailedHvp: true,
    });
    const [openHVPBatchErrorModal, setOpenHVPBatchErrorModal] = useState(false);
    const [HVPBatchErrorModalTitle, setHVPBatchErrorModalTitle] = useState(translate("Label_High_Volume_Batches"));
    const [batchErrorsUrl, setBatchErrorsUrl] = useState("");
    const [batchShipmentsUrl, setBatchShipmentsUrl] = useState("");
    const [isGridLoading, setIsGridLoading] = useState(false);

    /**
     * Functions
     */
    const getBatches = () => {
        setIsGridLoading(true);
        const startDateVal = props.filters.find((filter) => filter.field === "startDate")?.value ?? "today~00:00:00";
        const endDateVal = props.filters.find((filter) => filter.field === "endDate")?.value ?? "today~23:59:59";
        const externalBatchId = props.filters.find((filter) => filter.field === "ExternalBatchId")?.value;
        const batchId = props.filters.find((filter) => filter.field === "BatchId")?.value;
        const customer = props.filters.find((filter) => filter.field === "Customer")?.value;
        const facility = props.filters.find((filter) => filter.field === "Facility")?.value;
        const status = props.filters.find((filter) => filter.field === "Status")?.value;
        const startDate = StringWms.convertFilterStringToISODate(startDateVal, "", false, true).isoStringUTC;
        const endDate = StringWms.convertFilterStringToISODate(endDateVal, "", false, true).isoStringUTC;

        if (filterCountAxiosSource) {
            filterCountAxiosSource.cancel();
            filterCountAxiosSource = null;
        }
        // on filter update cancel axiosSource call if any running
        filterCountAxiosSource = axios.CancelToken.source();
        getSmallParcelBatches(startDate, endDate, filterCountAxiosSource.token).then((response) => {
            const data = response.data;
            if (data && data.ParcelBatches) {
                data.ParcelBatches = data.ParcelBatches.filter((element) => {
                    // Set Filters Locally
                    if (externalBatchId && element.ExternalBatchId !== externalBatchId) {
                        return false;
                    }

                    if (batchId && element.BatchId !== batchId) {
                        return false;
                    }

                    if (customer && element.Customer !== customer) {
                        return false;
                    }

                    if (facility && element.Facility !== facility) {
                        return false;
                    }

                    if (
                        (status && element.Status !== status) ||
                        (status === "processing" && element.Status !== "queued")
                    ) {
                        return false;
                    }
                    return true;
                });

                data.ParcelBatches = data.ParcelBatches.map((element, index) => {
                    element.completedPackageCount = 0;
                    element.erroredPackageCount = 0;
                    element.packagesCount = 0;
                    element.ordersCount = 0;
                    element.RowIndex = index + 1;
                    if (element?.OrderData?.length) {
                        let orderCounts = 0;
                        let packageCounts = 0;
                        element.OrderData.forEach((order) => {
                            if (order.OrderId) orderCounts += 1;
                            if (order.PackageCount) packageCounts += order.PackageCount;
                            if (order.IsFailed) {
                                element.erroredPackageCount += order.PackageCount;
                            } else {
                                element.completedPackageCount += order.PackageCount;
                            }
                        });
                        element.packagesCount = packageCounts;
                        element.ordersCount = orderCounts;
                    }

                    // update Pass Fail
                    element.PassFail = `${element.completedPackageCount}/${element.erroredPackageCount}`;
                    if (element.Status === "processing") {
                        try {
                            const percent = Math.round(((element.Completed + element.Errors) / element.Count) * 100);
                            element.Status = `Processing (${percent}%)`;
                        } catch (e) {
                            console.log(`couldn't create percent`, e.message, element);
                        }
                    }
                    return element;
                });
                setGridData(data.ParcelBatches);
                setGridRowCount(data?.ParcelBatches?.length ?? 0);
                setIsGridLoading(false);
            }
        });
    };

    const setLoading = (active: boolean) => {
        kendo.ui.progress($("." + props.windowId), active);
    };

    const handleRowSelection = (kendoGrid: KendoGridWmsVirtual, isContextMenuSelection: string) => {
        if (isContextMenuSelection) return;
        const selectedBatches = kendoGrid.getDictionary().Values();
        setSelectedBatches(selectedBatches);
    };

    const updateManageOptionsStatus = useCallback(() => {
        const newStatus = { ...manageOptionsDisableStatus };

        if (selectedBatches.length === 1) {
            const [currentBatch] = selectedBatches;
            const batchStatus = currentBatch?.Status?.toLowerCase();
            const hasCompletedOrPrintedStatus = ["printed", "completed", "completed_with_errors"].includes(batchStatus);
            const hasCompletedStatus = ["completed", "completed_with_errors"].includes(batchStatus);
            const hasSomeLabelToDownload = !!currentBatch.LabelDownloadZpl || !!currentBatch.LabelDownloadPdf;
            const hasForms = currentBatch.Forms > 0;

            newStatus.printHvp.labels = !(hasCompletedOrPrintedStatus && hasSomeLabelToDownload);
            newStatus.printHvp.documents = !(hasForms && hasCompletedStatus);
            newStatus.updateBatch = false;
            newStatus.resetFailedHvp = false;
        } else {
            newStatus.printHvp.labels = true;
            newStatus.printHvp.documents = true;
            newStatus.updateBatch = true;
        }

        setManageOptionsDisableStatus(newStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBatches]);

    const handlePrintBatchLabel = async () => {
        const [currentBatch] = selectedBatches;

        const labelFormat = currentBatch?.LabelFormat?.toLowerCase();
        const isPdfOrPngFormat = ["png", "pdf"].includes(labelFormat);
        const isZplFormat = labelFormat === "zpl";

        if (isPdfOrPngFormat) {
            // eslint-disable-next-line security/detect-non-literal-fs-filename
            window.open(currentBatch.LabelDownloadPdf as string, "_blank");
        }

        if (isZplFormat) {
            try {
                setLoading(true);
                const { data } = await getSmallParcelBatchZplContents(currentBatch.LabelDownloadZpl);
                GlobalService.printDataInZplPrinter(data, true);
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        }
        if (currentBatch.Status.toLowerCase() !== "printed") {
            setLoading(true);
            await updateBatchStatus(currentBatch.BatchId, "printed");
            await getBatches();
            setLoading(false);
        }
        setOpenPrintHvpBatchModal(false);
        setLoading(false);
    };

    const handlePrintDocuments = () => {
        const [currentBatch] = selectedBatches;
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        return window.open(currentBatch.FormDownload as string, "_blank");
    };

    const updateBatchLabel = async () => {
        setLoading(true);
        const [currentBatch] = selectedBatches;
        const { BatchLabelsUrl, Errors, BatchErrorsUrl, BatchShipmentsUrl } = currentBatch;

        const params = `?batchlabelsuri=${BatchLabelsUrl}${
            Errors ? `&batcherrorsuri=${BatchErrorsUrl}` : `&batchshipmentsuri=${BatchShipmentsUrl}`
        }`;

        try {
            await putSmallParcelBatchLabels(params);
            GlobalService.notification("success", translate("High_Volume_Processing_Update"));
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
        setLoading(false);
    };

    const handleEventOfContextMenu = async (contextMenu: any) => {
        let { wmsid } = contextMenu;
        const [currentBatch] = selectedBatches;

        if (wmsid === "updateBatchLabel") {
            return updateBatchLabel();
        }
        if (wmsid === "printBatchLabels") {
            if (currentBatch.Status.toLowerCase() === "printed") {
                setOpenPrintHvpBatchModal(true);
                return;
            }
            return handlePrintBatchLabel();
        }
        if (wmsid === "printBatchDocuments") {
            return handlePrintDocuments();
        }
        if (wmsid === "resetFailedHvp") {
            return undefined;
        }
    };

    const handleResetFailedHvp = async () => {
        setLoading(true);
        // send array of papi batches ["https://...", "https://..."]
        const papiPaths = selectedBatches.map((batch) => batch.BatchLabelsUrl);
        await removeBatchErrors(papiPaths);
        await getBatches();
        setLoading(false);
    };

    /**
     * useEffects
     */

    useEffect(() => {
        getBatches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filters]);

    useEffect(() => {
        /**
         * When the griData changes, we have to ensure that the selectedBatches array has the most recent data;
         */
        if (selectedBatches?.length) {
            const updatedBatches = [];
            selectedBatches.forEach((selectedBatch) => {
                const filteredBatch = gridData.find((batch) => batch.BatchId === selectedBatch.BatchId);
                if (filteredBatch) updatedBatches.push(filteredBatch);
            });
            setSelectedBatches(updatedBatches);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridData]);

    useEffect(() => {
        updateManageOptionsStatus();
    }, [updateManageOptionsStatus]);

    useEffect(() => {
        $(document).off("click", ".error-icon-status-hvp");
        $(document).on("click", ".error-icon-status-hvp", function () {
            setBatchErrorsUrl($(this).attr("batch-errors-url"));
            setBatchShipmentsUrl($(this).attr("batch-shipments-url"));
            const batchName = $(this).attr("batch-name");
            if (batchName) {
                setHVPBatchErrorModalTitle("Batch Error(s) " + batchName);
                setOpenHVPBatchErrorModal(true);
            }
        });
    }, []);

    useEffect(() => {
        $(document).off("click", ".hvp-batch-id-link");
        $(document).on("click", ".hvp-batch-id-link", function () {
            const batchName = $(this).attr("batch-name");
            const facilityName = $(this).attr("facility-name");
            const customerName = $(this).attr("customer-name");
            if (batchName && facilityName && customerName) {
                // For Active Small Parcel Tab and Open Pack And Ship Modal
                (window as any).OpenWindow({
                    url: "<SmallParcelHolder></SmallParcelHolder>",
                    reactComponent: SmallParcelHolder,
                    singleton: true,
                    title: "Window_Title_Label_Small_Parcel",
                    active: true,
                    useLineItem: false,
                    icon: "fas fa-box-open",
                    urlParam: "small-parcel",
                    HvpBatchName: batchName,
                    HvpFacilityName: facilityName,
                    HvpCustomerName: customerName,
                    sendToTab: "SmallParcel-searchHVPBatchName",
                });
            }
        });
    }, []);

    return (
        <div className={"small-parcel-hvp-grid new-layout-grid-wrapper"}>
            <div className="wms-search-grid-data">
                <VirtualGridComponent
                    id={smallParcelHVPGridId}
                    windowId={props.windowId}
                    loading={isGridLoading}
                    staticGridData={gridData}
                    gridKeySelection={"BatchId"}
                    gridName={"processedHVPBatchesGrid"}
                    metaGridConfig={""}
                    showResetButton={false}
                    showGridResult={true}
                    showGridSummary={true}
                    totalResult={gridRowCount}
                    shawCheckbox={false}
                    defaultColumns={defaultColumns}
                    allColumns={defaultColumns}
                    filterText={""}
                    virtual={true}
                    onSelection={handleRowSelection}
                    wmsSelector={wmsSelector + "HVPGrid"}
                    metaDataFieldsName={"Batches"}
                    clickEventOfContextMenu={handleEventOfContextMenu}
                    menu={[
                        {
                            "name": translate("Label_Manage"),
                            "value": [
                                {
                                    "disabled": false,
                                    "isButtonMenu": true,
                                    "isContextMenu": true,
                                    "name": "Print HVP Batch",
                                    "onClickFunc": () => {},
                                    "value": [
                                        {
                                            "disabled": manageOptionsDisableStatus.printHvp.labels,
                                            "name": "Print Labels",
                                            "onClickFunc": async () =>
                                                await handleEventOfContextMenu({ wmsid: "printBatchLabels" }),
                                            "value": [],
                                            "wmsSelector": wmsSelector + "printBatchLabels",
                                            "wmsid": "printBatchLabels",
                                            "isButtonMenu": true,
                                            "isContextMenu": true,
                                        },
                                        {
                                            "disabled": manageOptionsDisableStatus.printHvp.documents,
                                            "name": "Print Documents",
                                            "onClickFunc": handlePrintDocuments,
                                            "value": [],
                                            "wmsSelector": wmsSelector + "printBatchDocuments",
                                            "wmsid": "printBatchDocuments",
                                            "isButtonMenu": true,
                                            "isContextMenu": true,
                                        },
                                    ],
                                    "wmsSelector": wmsSelector + "SmallParcelPrintBatchedShippingLabel",
                                    "wmsid": "printHvpBatch",
                                },
                                {
                                    "disabled": manageOptionsDisableStatus.updateBatch,
                                    "isButtonMenu": true,
                                    "isContextMenu": true,
                                    "name": "Update Batch",
                                    "onClickFunc": updateBatchLabel,
                                    "value": [],
                                    "wmsSelector": wmsSelector + "UpdateBatchLabel",
                                    "wmsid": "updateBatchLabel",
                                },
                                {
                                    "disabled": manageOptionsDisableStatus.resetFailedHvp,
                                    "name": "Reset Failed HVP",
                                    "onClickFunc": handleResetFailedHvp,
                                    "value": [],
                                    "wmsSelector": wmsSelector + "resetFailedHvp",
                                    "wmsid": "resetFailedHvp",
                                    "isButtonMenu": true,
                                    "isContextMenu": true,
                                },
                            ],
                            "disabled": false,
                            "icon": "fa-wrench",
                            "color": "green",
                            "wmsSelector": wmsSelector + "Manage",
                            "clickFunc": () => {},
                            "isContextMenu": true,
                            "isButtonMenu": true,
                        },
                        {
                            "name": translate("Label_Batch_Create_HVP"),
                            "value": [],
                            "disabled": false,
                            "color": "green",
                            "wmsSelector": wmsSelector + "CreateHVPBatch",
                            "isContextMenu": false,
                            "clickFunc": () => setOpenCreateHVPBatchModal(true),
                            "isButtonMenu": true,
                        },
                        {
                            "name": "",
                            "wmsSelector": "RefreshGrid",
                            "value": [],
                            "clickFunc": async () => {
                                await getBatches();
                            },
                            "disabled": false,
                            "icon": "fa-sync-alt",
                            "color": "blue",
                            "isContextMenu": false,
                            "isButtonMenu": true,
                            "parentClassName": "refreshSmalParcelHVPGrid",
                        },
                        {
                            "name": "Copy",
                            "value": [
                                {
                                    "name": translate("Label_Copy_Field"),
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": translate("Label_Copy_Field"),
                                    "icon": "fa-bandcamp",
                                    "color": "green",
                                    "isContextMenu": true,
                                    "isButtonMenu": false,
                                    "wmsid": "copyField",
                                    "wmsSelector": "copyToClicpboard",
                                },
                                {
                                    "name": translate("Label_Copy_Row"),
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": translate("Label_Copy_Row"),
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    "isContextMenu": true,
                                    "isButtonMenu": false,
                                    "wmsid": "copyRow",
                                },
                            ],
                            "disabled": false,
                            "disabledText": "",
                            "icon": "fa-wrench",
                            "color": "green",
                            "isContextMenu": true,
                            "isButtonMenu": false,
                        },
                    ]}
                />
            </div>
            {openCreateHVPBatchModal && (
                <CreateHVPBatchModal
                    open={openCreateHVPBatchModal}
                    onClose={() => setOpenCreateHVPBatchModal(false)}
                    windowId={props.windowId}
                    onSuccess={async () => {
                        setOpenCreateHVPBatchModal(false);
                        GlobalService.notification("success", "HVP Batch created");
                        await getBatches();
                    }}
                />
            )}
            {openPrintHvpBatchModal && (
                <WmsModal
                    isOpen={openPrintHvpBatchModal}
                    id={"printHvpLabelConfirm"}
                    title={translate("Label_Confirmation")}
                    height={175}
                    onCloseFunc={() => setOpenPrintHvpBatchModal(false)}
                    width={600}
                    customClass="wms-model-new-ui-wrapper"
                >
                    <div className={"model-content-wrapper"}>{translate("Label_Are_Sure_Reprint_Label")}</div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={translate("Label_No")}
                            wmsSelector={wmsSelector + "CancelPrint"}
                            onClickFunc={() => setOpenPrintHvpBatchModal(false)}
                            buttonType="orange"
                            icon={"fa-ban"}
                        />
                        <WmsButton
                            label={translate("Label_Yes")}
                            wmsSelector={wmsSelector + "PrintHvpLabel"}
                            onClickFunc={handlePrintBatchLabel}
                            loading={false}
                            disabled={false}
                            icon={"fa-check-circle"}
                        />
                    </div>
                </WmsModal>
            )}

            {openHVPBatchErrorModal && (
                <HVPBatchErrorModal
                    open={openHVPBatchErrorModal}
                    onClose={() => setOpenHVPBatchErrorModal(false)}
                    windowId={props.windowId}
                    modalTitle={HVPBatchErrorModalTitle}
                    batchErrorsUrl={batchErrorsUrl}
                    batchShipmentsUrl={batchShipmentsUrl}
                />
            )}
        </div>
    );
}
