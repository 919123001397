import React, { Component } from "react";
import WmsButton from "./../../components/WmsButton/WmsButton.jsx";
import WmsDropdown from "./../../components/WmsDropdown/WmsDropdown.jsx";
import WmsCollapse from './../../components/WmsCollapse/WmsCollapse.jsx';

// Services
import * as GlobalService from '../../global_services';

export class PackagingTypesSearch extends Component {
    constructor(props) {
        super(props);

        this.translate = window.translate
        this.customLabels = GlobalService.getCustomLabels();

        this.state = {
            Rights: GlobalService.GetRights(),
            optionsCustomer: [],
            optionsMaterialType: [
                {
                    name: "All",
                    value: "All"
                },
                {
                    name: "Package",
                    value: 0
                },
                {
                    name: "Insert",
                    value: 1
                },
            ]
        };
    }

    componentDidMount() {
        var tmpCustOpt = GlobalService.getCustomerList(false);
        tmpCustOpt.unshift({
            "id": 0,
            "name": "All Customers",
        });
        this.setState({ optionsCustomer: tmpCustOpt });
    }

    handleFilterChange = (event) => {
        let propFilter = Object.assign({}, this.props.sharedPackagingTypesFilter);
        let objFilter = Object.assign(propFilter, { [event.target.name]: event.target.value });

        this.props.onFilterDataSet(objFilter);
    }

    render() {
        return (
            <div className={"new-layout-filter-wrapper wms-packaging-types-search"}>
                <div className="packaging-types-search-filter ">
                    <div className="wms-toolbar">
                        <WmsButton
                            label={this.translate('Label_Clear_Filters')}
                            buttonType='orange'
                            wmsSelector={this.props.selector + 'ClearSearchButton'}
                            onClickFunc={this.props.onClearSearch}
                            icon='fa-minus'
                        />
                    </div>

                    {this.props.sharedSearchOn == true &&
                        <div className="title">
                            <b>{this.translate('Label_NOTE')}: </b><label >{this.translate('Label_Search_Applied_Filter')}</label>
                        </div>
                    }
                    <div className="packaging-types-form-boxes-wrapper">
                        <div className={"packaging-types-form-boxes " + (this.props.sharedSearchOn ? "width-title" : "")}>
                            <div className="packaging-types-area-content">
                                <WmsCollapse id={'search_collapse1'} headerLabel={this.translate('Label_Filter_Options')} showCollapseIcon={false} showCollapseText={false}>
                                    <WmsDropdown
                                        id="Customer"
                                        name="IndexOptionsCustomerId"
                                        label={this.customLabels.Label_Customer}
                                        wmsSelector={this.props.selector + "Customer"}
                                        value={this.props.sharedPackagingTypesFilter.IndexOptionsCustomerId}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsCustomer}
                                        textField='name'
                                        valueField='id'
                                    />
                                    <WmsDropdown
                                        id="MaterialType"
                                        name="IndexOptionsMaterialType"
                                        label={this.translate('Label_Material_type')}
                                        wmsSelector={this.props.selector + "MaterialType"}
                                        value={this.props.sharedPackagingTypesFilter.IndexOptionsMaterialType}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsMaterialType}
                                        textField='name'
                                        valueField='value'
                                    />

                                </WmsCollapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
