import React, { Component } from 'react'
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';
import WmsDateTimePicker from '../../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import WmsCheckBox from '../../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsGrid from '../../../components/WmsGrid/WmsGrid.jsx';
import { StringWms } from './../../../../../assets/ts/StringWms.ts';
import { ObjectWms } from './../../../../../assets/ts/ObjectWms.ts';

//services
import * as GlobalService from './../../../global_services';
import * as FindReceiptService from '../../findReceipt_proto/findReceipt.services.jsx';
import * as metaDataService from '../../findReceipt_proto/meta-data.service.js';

import "./confirm-receipt-model.scss";

export class ConfirmReceiptModel extends Component {
    constructor(props) {
        super(props)

        this.translate = window.translate;
        this.$rootScope = window;
        let GridTitlesAggregate = StringWms.convertSringToOption(this.translate('Massship_Andclose_Grid_Headers'));
        this.defaultConfirmReceipt = {
            DoorNumber: null,
            ChargeType: null,
            ChargeCategory: 0,

        };
        this.state = {
            Rights: GlobalService.GetRights(),
            autoAllocateToOpenOrders: false,
            optionsChargeType: [],
            chargeOperationMsg: '',
            receiptLabel: (this.props.receiptIds.length > 1) ? 'receipts' : 'receipt',
            sharedManualChargeOptions: [],
            applyChargeOpt: [{
                name: this.translate("Label_Receipt_Apply_Charge"),
                value: 0
            }, {
                name: this.translate("Label_Apply_only_first"),
                value: 1
            }, {
                name: this.translate("Label_Receipt_Divide_charges"),
                value: 2
            }],
            confirmFormData: null,
            confirmReceipt: this.defaultConfirmReceipt,
            GridConfirmReceiptChargesTotal: 0,
            shipAndCloseGridData: [],
            prefixForElementId:'ConfirmReceipt',
            warehouseMaxDateTime: '',
            shipAndCloseGridColumns: [{
                field: "RowNumber",
                title: "#",
                hidden: true,
                menu: false,
                width: "100%"
            }, {
                field: "ChargeOrigin",
                title: GridTitlesAggregate[0].description,
                lockable: false,
                filterable: false,
                width: 100,
                template: "# if( ChargeOrigin== 1) {# " + this.translate('Label_Billing_Script') + "  #} else if( ChargeOrigin== 2) {# " + this.translate('Label_Manual_Charge') + " #} else if( ChargeOrigin== 3 || ChargeOrigin == 0) {# " + this.translate('Label_Predefined') + " #} #"
            }, {
                field: "ChargeType",
                title: GridTitlesAggregate[1].description,
                width: 100,
                lockable: false
            }, {
                field: "ChargeLabel",
                title: GridTitlesAggregate[2].description,
                width: 150,
                lockable: false
            }, {
                field: "Description",
                title: GridTitlesAggregate[3].description,
                width: 180,
                lockable: false,
                hidden: true
            }, {
                field: "ChargePerUnit",
                title: GridTitlesAggregate[4].description,
                width: 90,
                lockable: false
            }, {
                field: "NumberOfUnits",
                title: GridTitlesAggregate[5].description,
                width: 80,
                lockable: false
            }, {
                field: "TotalCharges",
                title: GridTitlesAggregate[6].description,
                width: 100,
                lockable: false
            }, {
                field: "GeneralLedgerAccount",
                title: GridTitlesAggregate[7].description,
                width: 180,
                lockable: false,
                hidden: true
            }, {
                field: "ExternalAccountsReceivable",
                title: GridTitlesAggregate[8].description,
                width: 180,
                lockable: false,
                hidden: true
            }, {
                field: "ExternalItemIdentifier",
                title: GridTitlesAggregate[9].description,
                width: 180,
                lockable: false,
                hidden: true
            }, {
                field: "ExternalItemDescription",
                title: GridTitlesAggregate[10].description,
                width: 180,
                lockable: false,
                hidden: true
            }, {
                field: 'cmdRemove',
                menu: false,
                command: [{
                    name: "destroy",
                    text: " ",
                    className: "charge-delete"
                }],
                title: "&nbsp;",
                width: 32,
            }],
            schema: {
                model: {
                    fields: {
                        RowNumber: {
                            type: 'number'
                        },
                        ChargeOrigin: {
                            type: "number",
                            editable: false
                        },
                        ChargeTypeId: {
                            type: "string",
                            editable: false
                        },
                        ChargeType: {
                            type: "string",
                            editable: false
                        },
                        ChargeLabel: {
                            type: "string",
                            editable: false
                        },
                        Description: {
                            type: "string",
                            editable: false
                        },
                        ChargePerUnit: {
                            type: "number",
                            editable: false
                        },
                        NumberOfUnits: {
                            type: "number",
                            editable: true
                        },
                        TotalCharges: {
                            type: "number",
                            editable: false
                        },
                        GeneralLedgerAccount: {
                            type: "float",
                            editable: false
                        },
                        ExternalAccountsReceivable: {
                            type: "string",
                            editable: false
                        },
                        ExternalItemIdentifier: {
                            type: "string",
                            editable: false
                        },
                        ExternalItemDescription: {
                            type: "string",
                            editable: false
                        }
                    }
                }
            }
        }
    }
    componentDidMount = () => {
        FindReceiptService.intialConfirmReceipt(this.props.receiptIds)
            .then((response) => {
                var manualChargesOptions = [];
                var chargeTypeOption = [];
                if (response.chargesCategoryResponse != 'undefined' && response.chargesCategoryResponse.status == 200) {
                    manualChargesOptions = response.chargesCategoryResponse.data;
                }

                chargeTypeOption.unshift({ "ChargeLabel": "Custom Manual Charge" });
                var gridCharges = [];
                if(response.massShipChargesGridData.data.Charges){
                    gridCharges = response.massShipChargesGridData.data.Charges;
                    // response.massShipChargesGridData.data.Charges.map(function (item) {
                    //     console.log("item :",item);
                    //     item.ChargeOrigin = 3;
                    //     gridCharges.push(item);
                    // });
                }
                var confirmFormData = this.translateFormData(response.massShipChargesGridData.data);
                this.setState({
                    sharedManualChargeOptions: manualChargesOptions,
                    optionsChargeType: chargeTypeOption,
                    confirmFormData: confirmFormData,
                    shipAndCloseGridData: gridCharges
                },()=>{
                    this.rowNumberUpdate();
                });
            });
        if(this.props.facilityId > 0){
            GlobalService.getFacilityMaxDateTime(this.props.facilityId).then((response) => {
                this.setState({
                    warehouseMaxDateTime: response
                })
            });
        }
    }

    translateFormData = (param_shipandclose_formdata) => {

        for (let prop in param_shipandclose_formdata) {
            if (param_shipandclose_formdata[prop]) {
                if (param_shipandclose_formdata[prop].State === 1) {
                    let multiplesText = this.translate('Label_Multiples_Text');
                    param_shipandclose_formdata[prop].Value = multiplesText;
                } else if (param_shipandclose_formdata[prop].State === 0) {
                    param_shipandclose_formdata[prop].Value = '';
                }
            }
        }
        this.setState({ origFormData: JSON.parse(JSON.stringify(param_shipandclose_formdata)) });
        return param_shipandclose_formdata;

    }
    confirmReceiptHandleChange = (event) => {

        let confirmReceipt = Object.assign({}, this.state.confirmReceipt);
        confirmReceipt[event.target.name] = event.target.value

        //calculate total charges
        if (event.target.name == 'ChargePerUnit' || event.target.name == 'NumberOfUnits') {

            let param_chargePerUnit = (event.target.name == 'ChargePerUnit') ?
                event.target.value :
                this.state.confirmReceipt.ChargePerUnit;

            let param_numberOfUnits = (event.target.name == 'NumberOfUnits') ?
                event.target.value :
                this.state.confirmReceipt.NumberOfUnits;
            if(
                !isNaN(parseFloat(param_chargePerUnit)) &&
                !isNaN(parseFloat(param_numberOfUnits))
            ) {
            confirmReceipt['total'] = parseFloat((new Big(param_chargePerUnit || 0)).times(param_numberOfUnits || 0).toFixed(4));
            }
        }

        this.setState({ 'confirmReceipt': confirmReceipt });
    }
    formDataChangesHandle = (event) => {

        let confirmFormData = this.state.confirmFormData;

        if (event.target.name == 'ArrivalDate' || event.target.name == 'ApplyMethod') {
            confirmFormData[event.target.name] = event.target.value;
        } else if (event.target.name == "RecalcAutoCharges") {
            confirmFormData[event.target.name] = !confirmFormData[event.target.name];
        } else {
            confirmFormData[event.target.name].Value = event.target.value;
        }

        this.setState({ shipAndCloseFormData: confirmFormData });
    }

    onClickAddCharges = () => {
        var gridObj = {
            'ChargeOrigin': 2,
            'ChargeTypeId': this.state.sharedManualChargeOptions[this.state.confirmReceipt.ChargeCategory].Id,
            'ChargeType': this.state.sharedManualChargeOptions[this.state.confirmReceipt.ChargeCategory].Description,
            'ChargeLabel': this.state.confirmReceipt.ChargeLabel,
            'Description': this.state.confirmReceipt.Description,
            'ChargePerUnit': this.state.confirmReceipt.ChargePerUnit,
            'NumberOfUnits': this.state.confirmReceipt.NumberOfUnits,
            'TotalCharges': this.state.confirmReceipt.total,
            'GeneralLedgerAccount': this.state.confirmReceipt.GeneralLedgerAccount,
            'ExternalAccountsReceivable': this.state.confirmReceipt.ExternalAccountsReceivable,
            'ExternalItemIdentifier': this.state.confirmReceipt.ExternalItemIdentifier,
            'ExternalItemDescription': this.state.confirmReceipt.ExternalItemDescription,
            // 'uniqueId': this.state.chargeTypeOption[this.state.indexChargeType].uniqueId
        };
        let shipAndCloseGridData = $('#GridConfirmReceiptCharges').data().kendoGrid.dataSource.view().toJSON();
        shipAndCloseGridData.push(gridObj);

        // let tmpUniqueId = this.state.chargeTypeOption[this.state.indexChargeType].uniqueId;
        // let cahrgeTypeOpt = this.state.chargeTypeOption;
        // if (tmpUniqueId != 1) {
        //     cahrgeTypeOpt = ObjectWms.removeObjFromListOnParameterValue(this.state.chargeTypeOption, "uniqueId", tmpUniqueId);
        // }

        this.setState({
            shipAndCloseGridData: Object.assign([], shipAndCloseGridData),
            rowNumberSet: false,
            confirmReceipt: Object.assign({}, this.defaultConfirmReceipt),
            chargeOperationMsg: this.translate('Oders_FindOrders_MassShip_Charge_Add_Success'),
            // chargeTypeOption: cahrgeTypeOpt
        }, () => {

            this.rowNumberUpdate();
            // this.onChangeChargeType({ target: { value: 0 } });
            setTimeout(() => {
                this.setState({ chargeOperationMsg: '' });
            }, 5000);

        });

        this.sumOfTotalChargesColumn(shipAndCloseGridData);

    }

    rowNumberUpdate = () => {
        var grid = $('#GridConfirmReceiptCharges').data("kendoGrid");
        var dataSource = grid.dataSource;
        var data = dataSource.data();
        var sort = dataSource.sort();
        var query = new kendo.data.Query(data);
        var sortedData = query.data;
        var index = 0;
        for (var i = 0; i < sortedData.length; i++) {
            var firstItem = sortedData[i];
            index++;
            firstItem["RowNumber"] = index;
        }
        this.state.rowNumberSet = true;
        $('#GridConfirmReceiptCharges').data("kendoGrid").refresh();
    }

    sumOfTotalChargesColumn = (param_gridData) => {
        let toatalOfCharge = 0;
        for (let x = 0; x < param_gridData.length; x++) {
            let dataItem = param_gridData[x];
            toatalOfCharge += dataItem['TotalCharges'];
        }
        this.setState({ GridConfirmReceiptChargesTotal: toatalOfCharge });
    }
    onRemove = (e) => {
        // use time out to reflect he grid datasource changes
        setTimeout(() => {
            var grid = $('#GridConfirmReceiptCharges').data("kendoGrid");

            // Passing test here to remove default propery from data
            var data = ObjectWms.removeObjFromListOnParameterValue(grid.dataSource.data(), "test", null);
            // let tmpChargeTypeOption = Object.assign([], this.state.chargeTypeOption);

            // if (e.model.uniqueId != 1) {
            //     var getIndex = ObjectWms.FindIndexByProperty(this.state.originalChargeTypeOption, "uniqueId", e.model.uniqueId);
            //     let tmpRemovedChargeObj = this.state.originalChargeTypeOption[getIndex];
            //     tmpChargeTypeOption.push(tmpRemovedChargeObj);
            // }

            this.sumOfTotalChargesColumn(data);
            this.setState({
                shipAndCloseGridData: data,
                chargeOperationMsg: this.translate('Oders_FindOrders_MassShip_Charge_Remove_Success'),
                // chargeTypeOption: tmpChargeTypeOption
            }, () => {
                setTimeout(() => { this.setState({ chargeOperationMsg: '' }); }, 5000);
            });
        }, 100);

    }
    confirmReceipt = () => {
        let shipAndCloseFormObj = {
            'FormData': this.detectChangesFromOrig(),
            'ids': this.props.receiptIds
        }
        this.$rootScope.animationMoveIcon($('#receiptConfirmSave'));
        this.$rootScope.taskStarted();
        // this.$rootScope.$broadcast("EventProgressMassConfirmReceipt", {
        //     "confirmReciptData": shipAndCloseFormObj,
        //     "callBackFunc": this.props.onResetGridSelection
        // });
        var event = new CustomEvent("showProgressModal", {
            detail : {
                "type": "confirmReceipt",
                "confirmReciptData": shipAndCloseFormObj,
                "callBackFunc": this.props.resetGridSelection
            }
        });
        document.dispatchEvent(event);
        this.props.onCloseConfirmReceipt();
    }
    saveMassShipCharges = (e) => {
        var grid = $('#GridConfirmReceiptCharges').data("kendoGrid")
        var numberOfUnitsModel = StringWms.isEmpty(e.model.NumberOfUnits) ? 0 : e.model.NumberOfUnits;
        var numberOfUnits = StringWms.isEmpty(e.values.NumberOfUnits) ? 0 : e.values.NumberOfUnits;

        var chargePerUnitModel = StringWms.isEmpty(e.model.ChargePerUnit) ? 0 : e.model.ChargePerUnit;
        var chargePerUnit = StringWms.isEmpty(e.values.ChargePerUnit) ? chargePerUnitModel : e.values.ChargePerUnit;

        var total = parseFloat((new Big(numberOfUnits)).times(chargePerUnit).round(4));

        e.model.TotalCharges = total;
        grid.saveChanges();
        this.sumOfTotalChargesColumn($('#GridConfirmReceiptCharges').data().kendoGrid.dataSource.view().toJSON())
    }
    detectChangesFromOrig = () => {

        let confirmFormObj = this.state.confirmFormData;
        for (let objProperty in confirmFormObj) {
            if (objProperty == "TrackingNumber" || objProperty == "TrailerNumber" || objProperty == "SealNumber" || objProperty == "BolNumber") {
                if (confirmFormObj[objProperty].Value !== this.state.origFormData[objProperty].Value) {
                    confirmFormObj[objProperty].HasChanged = true;
                }
            }
        }
        // confirmFormObj['Charges'] = Object.assign([], this.state.shipAndCloseGridData);
        var currentGridData = $('#GridConfirmReceiptCharges').data().kendoGrid.dataSource.view().toJSON();
        // Remove if currentGridData is 0 or null
        _.remove(currentGridData, function (n) {
            return (n.NumberOfUnits == 0 || n.NumberOfUnits == null);
        });
        if (confirmFormObj) {
            confirmFormObj['Charges'] = currentGridData;
        }
        return confirmFormObj;

    }
    onDataBound = (e, kendoGrid) => {
        var grid = $("#GridConfirmReceiptCharges").data('kendoGrid');

        var gridData = grid.dataSource.view();
        for (var i = 0; i < gridData.length; i++) {
            if (gridData[i].ChargeOrigin != 2) {
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("hide-close-button");
            }
        }
    }
    onCollapseIconClick = (param_collapse) => {
        if(!param_collapse){
            // use setTimeout to wait until tab expand animation complete, then scrooll div to top
            setTimeout(() => {
                $(".model-content-wrapper").animate({
                    scrollTop: $("#confirmReceiptAdditonalCharges").position().top
                }, 'slow');
                $('#GridConfirmReceiptCharges').data("kendoGrid").resize(true)
            }, 500);
        }
    }
    onChangeGridConfig = (param_grid_config) =>{
        metaDataService.setAdditionalChargeGridOptionInMetaData(param_grid_config);
    }
    render() {
        return (
            <WmsModel
                id='ConfirmReceiptModel'
                title={this.translate('Label_Confirm_Receipt_Model').replace(new RegExp('{' + 'receiptLabelOnSelection' + '}', 'gi'), this.state.receiptLabel)}
                width={800}
                height={750}
                customClass='wms-model-new-ui-wrapper'
                onCloseFunc={this.props.onCloseConfirmReceipt}
                isOpen={this.props.sharedIsOpenConfirmReceiptModel}
            >
                <div className="model-content-wrapper">
                    <WmsCollapse
                        showCollapseText={false}
                        id={'confirmReceiptModalCollapse1'}
                        headerLabel={this.translate('Label_Transport_Information')} >
                        <div className="order-count-note">
                            <div className="wms-sprite fa fa-exclamation-triangle"></div>
                            <span>{this.translate('Label_Count_Of_Selected_Receipts').replace(new RegExp('{' + 'TotalOrders' + '}', 'gi'), this.props.receiptIds.length).replace(new RegExp('{' + 'receiptLabelOnSelection' + '}', 'gi'), this.state.receiptLabel)}</span>
                        </div>
                        <div className="element-parent-block">
                            <WmsDateTimePicker
                                id={this.state.prefixForElementId+'ArrivalDate'}
                                name={'ArrivalDate'}
                                wmsSelector={this.props.Selector + 'ArrivalDate'}
                                label={this.translate('Label_Arrival_Date')}
                                onChangeFunc={this.formDataChangesHandle}
                                value={this.state.confirmFormData != null ? this.state.confirmFormData.ArrivalDate : ''}
                                maxDateTime={this.state.warehouseMaxDateTime}
                                isDateParseInCustomFormat={true} />
                            <WmsInput
                                id={'TrailerNumber'}
                                inputName={'TrailerNumber'}
                                wmsSelector={this.props.Selector + 'TrailerNumber'}
                                onChangeFunc={this.formDataChangesHandle}
                                label={this.translate('Label_Trailer_Number')}
                                value={this.state.confirmFormData != null ? this.state.confirmFormData.TrailerNumber.Value : ''} />
                            <WmsInput
                                id={'SealNumber'}
                                inputName={'SealNumber'}
                                wmsSelector={this.props.Selector + 'SealNumber'}
                                onChangeFunc={this.formDataChangesHandle}
                                label={this.translate('Label_Seal_Number')}
                                value={this.state.confirmFormData != null ? this.state.confirmFormData.SealNumber.Value : ''} />
                        </div>
                        <WmsInput
                            id={'TrackingNumber'}
                            inputName={'TrackingNumber'}
                            wmsSelector={this.props.Selector + 'TrackingNumber'}
                            onChangeFunc={this.formDataChangesHandle}
                            label={this.translate('Label_Tracking_Number')}
                            value={this.state.confirmFormData != null ? this.state.confirmFormData.TrackingNumber.Value : ''} />
                        <WmsInput
                            id={'BolNumber'}
                            inputName={'BolNumber'}
                            wmsSelector={this.props.Selector + 'BolNumber'}
                            onChangeFunc={this.formDataChangesHandle}
                            label={this.translate('Label_Bill_Of_Lading')}
                            value={this.state.confirmFormData != null ? this.state.confirmFormData.BolNumber.Value : ''} />
                        {/* <WmsInput
                            id={'DoorNumber'}
                            inputName={'DoorNumber'}
                            wmsSelector={this.props.Selector + 'DoorNumber'}
                            onChangeFunc={this.formDataChangesHandle}
                            label={this.translate('Label_Door_Number')}
                            value={this.state.confirmFormData != null ? this.state.confirmFormData.LoadNumber : ''}
                        /> */}
                        {/* <WmsCheckBox
                            id={'RecalcAutoCharges'}
                            name={'RecalcAutoCharges'}
                            wmsSelector={this.props.Selector + 'RecalcAutoCharges'}
                            onChangeFunc={this.formDataChangesHandle}
                            label={this.translate('Label_Auto_Allocate_to_OpenOrders')}
                            checked={this.state.confirmFormData != null ? this.state.confirmFormData.RecalcAutoCharges : false}
                            value={this.state.confirmFormData != null ? this.state.confirmFormData.RecalcAutoCharges : false}
                            isRounded={true}
                            parentClassName={
                                this.state.Rights.hasOwnProperty('Receiving.AutoReallocOnReceiveEnabled') ?
                                    '' :
                                    'wms-check-box-disable'
                            }
                        /> */}
                    </WmsCollapse>
                    <WmsCollapse
                        id={'confirmReceiptAdditonalCharges'}
                        headerLabel={this.translate('Label_Additional_Charges')}
                        isExpanded={false}
                        showCollapseText={false}
                        setCollapseMetaData={this.onCollapseIconClick}
                        isDisabled={!this.state.Rights.hasOwnProperty('receipts.charges.manage')}
                        >
                        <div className='collapse-wrapper-section'>

                            <WmsDropdown
                                id={this.state.prefixForElementId+"ChargeType"}
                                name="ChargeType"
                                label={this.translate('Label_Select_Charge_Type')}
                                wmsSelector={this.props.Selector + "ChargeType"}
                                value={this.state.confirmReceipt.ChargeType}
                                onChangeFunc={this.confirmReceiptHandleChange}
                                options={this.state.optionsChargeType}
                                textField='ChargeLabel'
                                valueField='ChargeLabel'
                                applyIndex={true}
                                extraWrapperClass='dropdown-width-40'
                            />
                            <div className="wms-elements-wrapper">

                                <WmsDropdown
                                    id={this.state.prefixForElementId+"ChargeCategory"}
                                    name="ChargeCategory"
                                    label={this.translate('Label_Charge_Category')}
                                    wmsSelector={this.props.Selector + "ChargeCategory"}
                                    value={this.state.confirmReceipt.ChargeCategory}
                                    onChangeFunc={this.confirmReceiptHandleChange}
                                    options={this.state.sharedManualChargeOptions}
                                    applyIndex={true}
                                    textField='Description'
                                    valueField='Description' />

                                <WmsInput
                                    inputName="ChargeLabel"
                                    wmsSelector={this.props.Selector + 'ChargeLabel'}
                                    value={this.state.confirmReceipt.ChargeLabel}
                                    onChangeFunc={this.confirmReceiptHandleChange}
                                    label={this.translate('Label_Charge_Label')}
                                    extraWrapperClass='additional-charge-label-inp'
                                    maxLength={255}
                                    regularExp={/^\w+(\s\w*)*$/}
                                    required={true} />

                                <WmsInput
                                    inputName="Description"
                                    wmsSelector={this.props.Selector + 'UnitType'}
                                    value={this.state.confirmReceipt.Description}
                                    onChangeFunc={this.confirmReceiptHandleChange}
                                    maxLength={255}
                                    regularExp={/^\w+( \w+)*$/}
                                    label={this.translate('Label_Type_Of_Charge_Unit')}
                                    extraWrapperClass='additional-charge-unit-type-inp' />

                                <WmsInput
                                    inputName="ChargePerUnit"
                                    wmsSelector={this.props.Selector + 'PerUnit'}
                                    value={this.state.confirmReceipt.ChargePerUnit}
                                    onChangeFunc={this.confirmReceiptHandleChange}
                                    maxLength={255}
                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                    label={this.translate('Label_Charge_Per_Unit')}
                                    required={true}
                                    extraWrapperClass='additional-charge-per-unit-inp' />

                                <WmsInput
                                    inputName="NumberOfUnits"
                                    wmsSelector={this.props.Selector + 'NumberOfUnits'}
                                    value={this.state.confirmReceipt.NumberOfUnits}
                                    onChangeFunc={this.confirmReceiptHandleChange}
                                    maxLength={255}
                                    label={this.translate('Label_Number_Of_Units')}
                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                    required={true}
                                    extraWrapperClass='additional-charge-numberof-unit' />
                            </div>
                            <div className='element-parent-block'>
                                <WmsInput
                                    inputName="GeneralLedgerAccount"
                                    wmsSelector={this.props.Selector + 'GlAccNumber'}
                                    value={this.state.confirmReceipt.GeneralLedgerAccount}
                                    onChangeFunc={this.confirmReceiptHandleChange}
                                    maxLength={255}
                                    regularExp={/^\w+( \w+)*$/}
                                    label={this.translate('Label_General_Ledger_Account')} />

                                <WmsInput
                                    inputName="ExternalAccountsReceivable"
                                    wmsSelector={this.props.Selector + 'ExtAccReceivable'}
                                    value={this.state.confirmReceipt.ExternalAccountsReceivable}
                                    onChangeFunc={this.confirmReceiptHandleChange}
                                    maxLength={255}
                                    regularExp={/^\w+( \w+)*$/}
                                    label={this.translate('Label_External_Account_Receivable')} />

                                <WmsInput
                                    inputName="ExternalItemIdentifier"
                                    wmsSelector={this.props.Selector + 'ExternalItemIdentifier'}
                                    value={this.state.confirmReceipt.ExternalItemIdentifier}
                                    onChangeFunc={this.confirmReceiptHandleChange}
                                    maxLength={255}
                                    regularExp={/^\w+( \w+)*$/}
                                    label={this.translate('Label_External_Item_Identifier')} />

                                <WmsInput
                                    inputName="ExternalItemDescription"
                                    wmsSelector={this.props.Selector + 'ExternalItemDesc'}
                                    value={this.state.confirmReceipt.ExternalItemDescription}
                                    onChangeFunc={this.confirmReceiptHandleChange}
                                    maxLength={255}
                                    regularExp={/^\w+( \w+)*$/}
                                    label={this.translate('Label_External_Item_Description')} />
                            </div>

                            <div className='addtional-chrage-btn'>
                                <WmsButton
                                    label={this.translate('Label_Add_Charge')}
                                    wmsSelector={this.props.Selector + "AddCharge"}
                                    onClickFunc={this.onClickAddCharges}
                                    icon={'fa-plus'}
                                    disabled={
                                        this.state.confirmReceipt.NumberOfUnits == 0 || this.state.confirmReceipt.NumberOfUnits == null
                                    } />
                            </div>
                            {this.state.chargeOperationMsg != '' && (
                                <div className="charge-success-msg">
                                    <div className="wms-sprite fa fa-check-circle-o"></div>
                                    <span>{this.state.chargeOperationMsg}</span>
                                </div>
                            )}
                            <div className="wms-shipclose-grid-holder">
                                <div className="charge-grid-note">
                                    <span>NOTE: </span>
                                    <label>{this.translate('Confirm_Receipt_Grid_Note').replace(new RegExp('{' + 'receiptLabelOnSelection' + '}', 'gi'), this.state.receiptLabel)}</label>
                                </div>
                                {this.props.sharedIsOpenConfirmReceiptModel == true &&
                                    <WmsGrid
                                        wmsSelector={this.props.windowId + 'GridConfirmReceiptCharges'}
                                        isAlignIndexesByProperty={true}
                                        gridKeySelection={'RowNumber'}
                                        gridName={'GridConfirmReceiptCharges'}
                                        virtual={false}
                                        id={'GridConfirmReceiptCharges'}
                                        columns={this.state.shipAndCloseGridColumns}
                                        schema={this.state.schema}
                                        staticGridData={this.state.shipAndCloseGridData}
                                        onDataBound={this.onDataBound}
                                        onSelection={() => function () { }}
                                        getGridSelectionUrl={''}
                                        searchUrl={''}
                                        gridNoteText={this.translate('MassShip_AdnClose_Grid_Note')}
                                        menu={[]}
                                        onGridSave={this.saveMassShipCharges}
                                        gridEditable={{ confirmation: false, mode: "incell" }}
                                        onRemove={this.onRemove}
                                        shawCheckbox={false}
                                        showResetButton={false}
                                        showColumnFilter={false}
                                        showSortColumns={false}
                                        layoutData={metaDataService.getAdditionalChargeGridOptionInMetaData()}
                                        onColumnShow={this.onChangeGridConfig}
                                        onColumnHide={this.onChangeGridConfig}
                                        onColumnReorder={this.onChangeGridConfig}
                                        onColumnResize={this.onChangeGridConfig} />
                                }
                                <div className="totalcharges-section" >
                                    <WmsInput
                                        inputName="GridConfirmReceiptChargesTotal"
                                        wmsSelector={this.props.Selector + 'GridConfirmReceiptChargesTotal'}
                                        value={this.state.GridConfirmReceiptChargesTotal}
                                        onChangeFunc={(e) => function () { }}
                                        disabled={true}
                                        label={this.translate('Label_Receipt_Total_Charges').replace(new RegExp('{' + 'thisOrthese' + '}', 'gi'),((this.props.receiptIds.length > 1) ? 'these' : 'this')).replace(new RegExp('{' + 'receiptLabelOnSelection' + '}', 'gi'), this.state.receiptLabel)} />

                                    <WmsDropdown
                                        id={this.state.prefixForElementId+"ApplyMethod"}
                                        name="ApplyMethod"
                                        label={this.translate('Label_Apply_Charge')}
                                        wmsSelector={this.props.Selector + "ApplyMethod"}
                                        value={this.state.shipAndCloseFormData != null ? this.state.shipAndCloseFormData.ApplyMethod : 0}
                                        onChangeFunc={this.formDataChangesHandle}
                                        options={this.state.applyChargeOpt}
                                        textField='name'
                                        valueField='value' />
                                </div>
                            </div>
                        </div>
                    </WmsCollapse>

                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.props.Selector + "ButtonCancel"}
                        onClickFunc={this.props.onCloseConfirmReceipt}
                        icon="fa-ban"
                        buttonType="orange"
                        loading={false}
                    />

                    <WmsButton
                        label={this.translate('Label_Select_Confirm')}
                        wmsSelector={this.props.Selector + "Confirm"}
                        onClickFunc={this.confirmReceipt}
                        loading={this.state.generateGif}
                        id={"receiptConfirmSave"}
                        icon={'fa-check-circle-o'}
                        disabled={this.state.confirmFormData == null}
                    />
                </div>

            </WmsModel>
        )
    }
}
