import React from 'react';
import PropTypes from 'prop-types';
import WmsContextMenu from '../WmsContextMenu/WmsContextMenu.jsx';
import WmsCheckBox from '../WmsCheckBox/WmsCheckBox.jsx';
import { KendoGridWms } from '../../../../assets/ts/KendoGridWms.ts';
import { ObjectWms } from '../../../../assets/ts/ObjectWms.ts';
import style from './WmsFormGrid.scss';

class WmsFormGrid extends React.PureComponent {


    constructor(props) {
        super(props);

        this.isFireChangeEvent = true;
        this.isFireChangeEventEveryTime = props.isFireChangeEventEveryTime;

        
        this.translate = window.translate;
        this.windowId = this.props.windowId == "" ? "" : "." + this.props.windowId;
        this.state = {
            KendoGrid: new KendoGridWms(this.props.id, this.props.windowId, this.props.gridKeySelection),
            staticGridData: this.props.staticGridData,
            dataBindingInitial: 0,
            selectionCounter: 0,
            facilityCheckbox: false,
            isExpandGridSummary: true
        }
        this.beforeContextMenuOpen = this.beforeContextMenuOpen.bind(this);
    }

    componentDidMount() {
        $(this.windowId + " #" + this.props.id).kendoGrid({
            columns: this.props.columns,
            schema: this.props.schema,
            resizable: this.props.isColumnsResizable,
            reorderable: this.props.isColumnsReorderable,
            filterable: this.props.isColumnsFilterable,
            sortable: this.props.isColumnsSortable,
            editable: this.props.isEditable,
            columnMenu: (this.props.isColumnMenuenable) ? { sortable: false } : false,
            selectable: this.props.IsMultipleSelectable == true ? "multiple" : this.props.IsSelectable,
            edit: (e) => {
                this.props.onEdit(e);
            },
            save: (e) => {
                this.props.onGridSave(e);
            },
            remove: (e) => {
                this.props.onRemove(e);
            },
            columnMenuInit: (e) => {
                if (this.props.isColumnMenuenable) {
                    var filterMenu = e.container.find(".k-filter-menu");
                    // Set class to filter form of grit to reset specific grid column
                    if (!filterMenu.hasClass(this.props.id + 'FilterForm')) {
                        filterMenu.addClass(this.props.id + 'FilterForm');
                    }
                }
            },
            dataSource: {
                data: this.props.staticGridData
            },
            change: this.onSelection,
            dataBound: (e) => {
                this.props.onDataBound(e);
            }
        });

        if (this.props.gridDefaultSelections != null) {
            this.setGridDefaultSelction(this.props.gridDefaultSelections);
        }

        $(this.windowId + " #" + this.props.id + "_resetMenu").kendoMenu();

    }

    componentWillReceiveProps(nextProps) {

        if (typeof nextProps.staticGridData != "undefined" &&
            nextProps.staticGridData != this.props.staticGridData) {
            var dataSource = new kendo.data.DataSource({ data: nextProps.staticGridData });
            if (typeof $(this.windowId + ' #' + this.props.id).data('kendoGrid') != 'undefined') {
                $(this.windowId + ' #' + this.props.id).data('kendoGrid').setDataSource(dataSource);
            }
            this.setState({
                staticGridData: nextProps.staticGridData,
                selectionCounter: 0,
                facilityCheckbox: false
            },()=>{
                if (nextProps.gridDefaultSelections != null) {
                    setTimeout(() => {
                        this.isFireChangeEvent = false;
                        this.setGridDefaultSelction(nextProps.gridDefaultSelections);
                    }, 100);
                }
            });
        }


    }

    setGridDefaultSelction = (param_selectionID_arr) => {
        let grid = $(this.windowId + ' #' + this.props.id).data("kendoGrid");
        let models = grid.dataSource.data();
        let uidSelectionString = [];
        for (let i = 0; i < param_selectionID_arr.length; i++) {
            let columnIndex = ObjectWms.FindIndexByProperty(
                models,
                "Id",
                param_selectionID_arr[i]
            );
            if (columnIndex != -1) {
                uidSelectionString.push("[data-uid='" + models[columnIndex].uid + "']")
            }

        }
        grid.select(uidSelectionString.join(", "));

        this.setState({
            selectionCounter: uidSelectionString.length,
            facilityCheckbox: (grid.dataSource.data().length != 0 && grid.dataSource.data().length === grid.select().length) ? true : false
        });
    }

    onSelection = () => {
        let grid = $(this.windowId + ' #' + this.props.id).data("kendoGrid");
        setTimeout(() => {
            this.setState({ selectionCounter: grid.select().length });
        }, 100);
        if(this.isFireChangeEvent || this.isFireChangeEventEveryTime) {
            this.props.onSelection();
            this.isFireChangeEventEveryTime = false;
        } else {
            this.isFireChangeEvent = true;
        }
    }

    beforeContextMenuOpen(e) {
        var that = this;
        var grid = $(this.windowId + ' #' + this.props.id).data('kendoGrid');
        var dataItem = grid.dataItem($(e.target).closest('tr'));

        var page = grid.dataSource.page();
        var length = grid.dataSource.view().length;
        var col = grid.cellIndex($(e.target).closest('td'));

        var row2 = $(e.target).closest('tr').index();
        this.state.KendoGrid.onRightClick(e, page, length, row2, col, dataItem, grid.dataSource.data(), this.props.gridKeySelection, function () {
            // that.props.onSelection(that.state.KendoGrid);
        });

    }

    eventClickContextMenu = (obj) => {
        this.props.clickEventOfContextMenu(obj, this.state.KendoGrid);
    }

    checkboxChangeEvent = () => {

        this.setState({ facilityCheckbox: !this.state.facilityCheckbox });
        let grid = $(this.windowId + ' #' + this.props.id).data("kendoGrid");
        if (grid.dataSource.data().length === grid.select().length) {
            grid.clearSelection();
        } else {
            grid.select("tr");
        };
        this.onSelection();
    }

    resetGrid = () => {
        var grid = $(this.windowId + " #" + this.props.id).data("kendoGrid");
        grid.dataSource.sort({});
        grid.setOptions({ columns: this.props.columns });
    }
    clearColumnFilters = () => {
        var grid = $(this.windowId + " #" + this.props.id).data("kendoGrid");
        grid.dataSource.filter({});
    }

    orderSummaryExpand = (param_flag)=> (event) =>{

        var content = $(".wms-grid-summary");
        content.slideToggle(500, () => {
            this.setState({ isExpandGridSummary: param_flag });
        });
    }

    render() {
        return (
            <div className={'wms-form-grid-holder ' + this.props.parentClassName}>
                {this.props.menu != null && (
                    <WmsContextMenu
                        eventClickContextMenu={this.eventClickContextMenu}
                        attachTo={this.windowId + ' #' + this.props.id}
                        menu={this.props.menu}
                        onBeforeOpen={(e) => this.beforeContextMenuOpen(e)}
                        eventMouseEnter={(e) => function () { }}
                        eventMouseLeave={(e) => function () { }}
                        contextMenuFilter='tbody tr:not(.k-grid-edit-row):not(.wms-selection-group)' />
                )}

                {this.props.showCheckbox == true && (
                    <div className="wms_user_access_checkbox">
                        <WmsCheckBox
                            id={'wmsFormGridSelectAll'}
                            name={'wmsFormGridSelectAll'}
                            wmsSelector={'wmsFormGridSelectAll'}
                            onChangeFunc={this.checkboxChangeEvent}
                            label={this.translate('Label_Costomer_Select_All')}
                            value={this.state.facilityCheckbox}
                            checked={this.state.facilityCheckbox} />

                        <label className="wms_counter_label"><span>{this.state.selectionCounter + ' '}</span>{this.props.selectionString}</label>
                    </div>
                )}
                {this.props.showResult && this.props.isShowGridSummary == false &&
                    <div className="title">
                        <span>{this.props.staticGridData.length}</span>Results
                    </div>
                }
                {this.props.showResetMenu &&
                    <div className="wms-form-grid-reset-btn">
                        <ul id={this.props.id + "_resetMenu"}>
                            <li className="GridMenuTopLink">
                                <span></span>
                                <ul>
                                    <li onClick={this.resetGrid}>
                                        <span>{this.translate('Label_Reset_Grid')}</span>
                                    </li>
                                    {this.props.isColumnsFilterable &&
                                        <li onClick={this.clearColumnFilters}>
                                            <span>{this.translate('Label_Clear_Column_Filters')}</span>
                                        </li>
                                    }
                                </ul>
                            </li>
                        </ul>
                    </div>
                }
                {this.props.isShowGridSummary && (
                <div>
                    <div className="wms-grid-details">
                        {this.props.showResult == true &&
                            <label className="wms_grid_results">
                                <span>{this.props.staticGridData.length}</span>{this.translate("Label_Results")}
                            </label>
                        }
                        <div className="grid-right-content">
                            <div className="wms-filter-tooltip">
                                {this.state.isExpandGridSummary == false ? (
                                    <i className="fa fa-chevron-down"
                                    onClick={this.orderSummaryExpand(true)}
                                    ></i>
                                ):(
                                    <i className="fa fa-chevron-up"
                                    onClick={this.orderSummaryExpand(false)}
                                    ></i>
                                )}
                            </div>
                        </div>
                    </div>
                        <div className="wms-grid-summary" style={{ display: this.state.isExpandGridSummary ? 'flex' : 'none' }}>
                        {this.props.sharedGridSummary.map((Option, index) => ( 
                            <div className="wms-details-data-section" key={"summaryOptions_"+index}>
                                <p className="details-count">{Option.value}</p>
                                <p className="details-name">{Option.label}</p>
                            </div>
                        ))}
                            
                        </div>
                    </div>
                )}
                <div
                    id={this.props.id}
                    className="form-grid"
                    data-wms-selector={this.props.wmsSelector}>
                </div>
            </div>
        );
    }

}
WmsFormGrid.propTypes = {
    id: PropTypes.string, // this should write the id to the grid
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the grid
    columns: PropTypes.array.isRequired,
    staticGridData: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    windowId: PropTypes.string.isRequired,
    parentClassName: PropTypes.string,
    IsSelectable: PropTypes.bool,
    IsMultipleSelectable: PropTypes.bool,
    gridDefaultSelections: PropTypes.array,
    onSelection: PropTypes.func, // this function call on grid change
    selectionString: PropTypes.string,
    showCheckbox: PropTypes.bool,
    onEdit: PropTypes.func, // this function call after edit column
    onGridSave: PropTypes.func, // this function call after save column
    onRemove: PropTypes.func,// this function call when grid column's remove
    onDataBound: PropTypes.func,
    isFireChangeEventEveryTime:PropTypes.bool
}

WmsFormGrid.defaultProps = {

    menu: null,
    gridKeySelection: '',
    parentClassName: 'hidden-header',
    isColumnsResizable: false,
    isColumnsReorderable: false,
    isColumnMenuenable: false,
    IsSelectable: false,
    IsMultipleSelectable: false,
    isColumnsSortable: false,
    showResetMenu: false,
    gridDefaultSelections: null,
    onSelection: (e) => function () { },
    showCheckbox: false,
    showResult: false,
    isColumnsFilterable: false,
    windowId: '',
    isEditable: false,
    onEdit: (e) => function () { },
    onGridSave: (e) => function () { },
    onRemove: (e) => function () { },
    onDataBound: (e) => function () { },
    isFireChangeEventEveryTime:false,
    isShowGridSummary:false
}


export default WmsFormGrid;