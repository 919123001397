import React, { Component } from 'react';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import { ObjectWms } from '../../../../assets/ts/ObjectWms.ts';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsContainer from '../../components/WmsContainer/WmsContainer.jsx';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import WmsDateTimePicker from '../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsModel from '../../components/WmsModel/WmsModel.jsx';
import WmsConfirmModel from '../../components/WmsConfirmModel/WmsConfirmModel.jsx';
import WmsCustomDateTimePicker from '../../components/WmsCustomDateTimePicker/WmsCustomDateTimePicker.jsx';
import WmsRadio from '../../components/WmsRadio/WmsRadio.tsx';
import * as GridConfig from './grid_configuration.jsx';

//services
import * as metaDataService from './meta-data.service';
import * as GlobalService from './../../global_services';

export class FindReceiptSearch extends React.PureComponent {
    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.findReceiptGridId = "GridFindReceiversProto";
        let FindReceivers_GridHeaders = StringWms.convertSringToOption(this.translate('FindReceivers_GridHeaders'));

        this.state = {
            CustomLabels: GlobalService.getCustomLabels(),
            allColumnList: GridConfig.getAllColumnList(FindReceivers_GridHeaders, props.selector),
            optionsCustomer: GlobalService.getCustomerList(true),
            optionsFacility: GlobalService.getFacilityList(true),
            optionsSearchBy: StringWms.convertSringToOption(this.translate('Option_Receipts_SearchBy')),
            optionsReferenceNumber: StringWms.convertSringToOption(this.translate('Options_SearchBy_StringOptions')),
            optionsPurchaseOrder: StringWms.convertSringToOption(this.translate('Options_SearchBy_StringOptions')),
            optionsReceiptAdviseNumber: StringWms.convertSringToOption(this.translate('Options_SearchBy_StringOptions')),
            optionsTitleNotes: StringWms.convertSringToOption(this.translate('Options_SearchBy_StringOptions')),
            optionsStatus: StringWms.convertSringToOption(this.translate('Options_Receipt_SearchBy_StatusOptions').replace(new RegExp('{' + 'customCompleteLabel' + '}', 'gi'), GlobalService.getCustomLabels().Label_Complete)),
            optionsReceiptAdviceStatus: StringWms.convertSringToOption(this.translate('Options_Receipt_SearchBy_SentOptions')),
            isOpenSaveFilterModel: false,
            newFilterTitle: "",
            filterNameExist: false,
            savedFilterList: metaDataService.getSaveFindReceiptFilterListMetaData(),
            isExpandedCollapse: (!$.isEmptyObject(metaDataService.getFindReceiptCollapseMetaData()) ? metaDataService.getFindReceiptCollapseMetaData() : []),
            isOpenConfirmModel: false,
            isOpenUpdateConfirmModel: false,
            isClickSearchFilterToggle: false,
            gridDomSelector: () => props.windowId ? '.' + props.windowId + ' #' + this.findReceiptGridId : '#' + this.findReceiptGridId,
            sharedReceiptFilter: Object.assign({}, props.defaultFilterValue)
        };
        this.onFilterDataSet = GlobalService.debounce(this.onFilterDataSet, 700);
    }

    componentDidMount() {
        var sortable = $('#sortable-section');
        var sortData = metaDataService.getFindReceiptSortSectionMetaData();
        // set saved sort data if any in meta data
        if (sortData) {
            var pos = JSON.parse(sortData);
            if (pos) {
                $.each(pos, function (i, position) {
                    if (position != "") {
                        var $target = sortable.find('#' + position);
                        $target.appendTo(sortable); // or prependTo
                    }
                });
            }
        }
        sortable.sortable({
            update: this.onSectionSort,
            cancel: "label.icon_label,.wms-collapsible-content",
            handle: ".wms-sort",
            distance: 5,
            start: function (e, ui) {
                var height = ui.item.height();
                if (height > 100) {
                    height = 100;
                }
                ui.placeholder.height(height);
            }
        });
        var filterData = metaDataService.getFindReceiptFilterMetaData();
        if (filterData != "") {
            this.setState({
                sharedReceiptFilter: filterData,
            });
        }
    }
    onSectionSort = () => {
        var sortArray = JSON.stringify($('#sortable-section').sortable("toArray"));
        metaDataService.setFindReceiptSortSectionMetaData(sortArray);
    }

    handleFilterChange = (event) => {
        let propFilter = Object.assign({}, this.state.sharedReceiptFilter);
        let objFilter = Object.assign(
            propFilter,
            { [event.target.name]: event.target.value }
        );
        var optionsFacility = GlobalService.getFacilityList(true);
        if (event.target.name == 'CustomerId') {
            let customerIndex = ObjectWms.FindIndexByProperty(this.state.optionsCustomer, "id", parseInt(event.target.value));
            let custFacility = this.state.optionsCustomer[customerIndex].facilities;
            let allFacilities = GlobalService.getFacilityList(false);
            let selFacilityOption = [];
            for (let i = 0; i < custFacility.length; i++) {
                let facilityIndex = ObjectWms.FindIndexByProperty(allFacilities, "id", parseInt(custFacility[i]));
                if(allFacilities[facilityIndex]) {
                    selFacilityOption.push(allFacilities[facilityIndex]);
                }
            }
            selFacilityOption.unshift({
                "id": 0,
                "name": "Unspecified",
                "uri": "",
                "FacilitySimpleUri": "",
                "UpsAccountNumber": "",
                "FedExAccountNumber": ""
            });
            objFilter['FacilityId'] = selFacilityOption.find(f => f.id === this.state.optionsCustomer[customerIndex].defaultFacility) === undefined? 0 : this.state.optionsCustomer[customerIndex].defaultFacility;
            optionsFacility = selFacilityOption;
        }
        this.setState({
            sharedReceiptFilter:objFilter,
            optionsFacility:optionsFacility
        });
        this.onFilterDataSet(objFilter);
    }

    onFilterDataSet = (objFilter) => {        
        this.props.onFilterDataSet(objFilter);
    }

    onSaveFilter = () => {
        if (!this.state.filterNameExist) {
            this.props.onManageFilter('saveFilter', this.state.newFilterTitle);
            this.exitSaveFilterModel();
        }
    }

    onUpdateFilter = () => {
        this.setState({ isOpenUpdateConfirmModel: false });
        this.props.onManageFilter('updateFilter', this.state.currentFilterIndex);
    }

    onOpenUpdateConfirmModel = (param_index) => {
        this.setState({
            isOpenUpdateConfirmModel: true,
            currentFilterIndex: param_index
        });
    }

    onCloseUpdateConfirmModel = () => {
        this.setState({ isOpenUpdateConfirmModel: false });
    }

    exitSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: false
        });
    }

    onOpenSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: true,
            newFilterTitle: "",
            filterNameExist: false
        });
    }

    handleFilterTitleChange = (e) => {
        let value = e.target.value;
        if (value.Description == '') {
            value = '';
        }

        var nameExists = false;
        // check dublicate title for case sensitive
        var exists = this.state.savedFilterList.findIndex(function (item) {
            return value.toLowerCase() === item.title.toLowerCase();
        });
        if (exists != -1) {
            nameExists = true;
        }
        this.setState({
            newFilterTitle: value,
            filterNameExist: nameExists
        });
    }

    onUseSaveFilter = (index) => {
        var objFilter = JSON.parse(JSON.stringify(this.state.savedFilterList[index]));
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var gridConfig = JSON.parse(objFilter.columnData, JSON.dateParser);
        var tempObject = "";

        if (gridConfig.columns) {
            var fieldArray = gridConfig.columns.map(el => el.field);
            var updateColumn = [];
            this.state.allColumnList.filter((column) => {
                if (fieldArray.indexOf(column.field) !== -1) {
                    updateColumn.push(column);
                }
            });
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, updateColumn, "field");
        } else {
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, this.props.defaultColumns, "field");
        }

        if(grid) {
            grid.setOptions({
                columns: tempObject
            });
            grid.dataSource.sort(gridConfig.sorts);
            grid.dataSource.filter(gridConfig.filters);
            this.setState({
                sharedReceiptFilter:objFilter.filterData
            });
            this.props.onApplySavedFilter(objFilter.filterData);
        }
    }

    onYesRemoveSavedFilter = () => {
        metaDataService.clearSaveFindReceiptFilterListMetaData(this.state.savedFilterIndex);
        this.setState({
            isOpenConfirmModel: false,
            savedFilterList: metaDataService.getSaveFindReceiptFilterListMetaData()
        });
    }

    ClickExit = () => {
        this.setState({
            isOpenConfirmModel: false
        });
    }

    onClearSaveFilter = (index) => {
        this.setState({
            isOpenConfirmModel: true,
            savedFilterIndex: index
        });
    }

    setCollapseMetaData = (isExpanded, collapseId) => {
        metaDataService.setFindReceiptCollapseMetaData(collapseId, isExpanded);
    }

    onClickForFilterToggle = () => {
        this.setState({
            isClickSearchFilterToggle : true
        });
        this.props.onClickForFilterToggle();
    }
    onClearSearch = () => {
        this.setState({
            sharedReceiptFilter: Object.assign({}, this.props.defaultFilterValue),
        });
        this.props.onClearSearch();
    }
    render() {
        const renderFilter = this.state.sharedReceiptFilter;
        return (
            <div className={"new-layout-filter-wrapper" + ( this.state.isClickSearchFilterToggle ? " sectionToggled" : "" )} >
                <div className={"filter"}
                    onClick={this.onClickForFilterToggle}>
                    <i className="fa fa-caret-left" aria-hidden="true"></i>
                    <span>search filters</span>
                </div>
                <div className="find-receipt-search-filter">
                    <div className="wms-toolbar">
                        <WmsButton
                            label={this.translate('RoleManager_Label_Reset')}
                            buttonType='orange'
                            wmsSelector={this.props.selector + 'ClearSearchButton'}
                            onClickFunc={this.onClearSearch}
                            icon='fa-repeat' />
                        <WmsButton
                            label={this.translate('Label_Save_View')}
                            buttonType='green'
                            wmsSelector={this.props.selector + 'SaveFilterButton'}
                            onClickFunc={this.onOpenSaveFilterModel}
                            icon='fa-floppy-o' />
                    </div>
                    {this.props.sharedSearchOn == true &&
                        <div className="title">
                            <b>{this.translate('Label_NOTE')}: </b><label >{this.translate('Label_Search_Applied_Filter')}</label>
                        </div>
                    }
                    <div className="warehouse-form-boxes-wrapper">
                        <div className={"warehouse-form-boxes " + (this.props.sharedSearchOn ? "width-title" : "")}>
                            <div className="warehouse-are-content" id="sortable-section">
                                {this.state.savedFilterList.length !== 0 && (
                                    <WmsCollapse id={'search_collapse0'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse0') ? !this.state.isExpandedCollapse['search_collapse0'] : true} showDragIcon={true} headerLabel={this.translate('Label_Saved_Views')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse0')}>
                                        <ul className="filter-list-block">
                                            {this.state.savedFilterList.map((data, index) => (
                                                <li className="filter-list" key={index}>
                                                    <a className="title-text" title={data.title}>{data.title}</a>
                                                    <div className="wms-apply-btn">
                                                        <a className="update-save-filter filter-icon" onClick={(e) => this.onOpenUpdateConfirmModel(index)}><i className="fa fa-floppy-o" aria-hidden="true"></i></a>
                                                        <a className="apply-save-filter filter-icon" onClick={(e) => this.onUseSaveFilter(index)}><i className="fa fa-check" aria-hidden="true"></i></a>
                                                        <a className="clear-save-filter filter-icon" onClick={(e) => this.onClearSaveFilter(index)}><i className="fa fa-trash-o" aria-hidden="true"></i></a>
                                                    </div>
                                                    {/* 
                                                        <a className="clear-save-filter" onClick={(e) => this.onClearSaveFilter(index)}><i className="far fa-times-circle" aria-hidden="true"></i></a>
                                                        <a className="clear-save-filter" onClick={(e) => this.onClearSaveFilter(index)}><i className="far fa-times-circle" aria-hidden="true"></i></a>

                                                        <WmsButton
                                                            label={this.translate('RoleManager_Label_Update')}
                                                            buttonType='green'
                                                            wmsSelector={this.props.selector + 'UpdateFilterButton'}
                                                            onClickFunc={(e) => this.onOpenUpdateConfirmModel(index)}
                                                            icon='' />
                                                        <WmsButton
                                                            label={this.translate('Label_Apply')}
                                                            buttonType='green'
                                                            wmsSelector={this.props.selector + 'ApplyFilterButton'}
                                                            onClickFunc={(e) => this.onUseSaveFilter(index)}
                                                            icon='' />
                                                    </div>
                                                    <a className="clear-save-filter" onClick={(e) => this.onClearSaveFilter(index)}><i className="far fa-times-circle" aria-hidden="true"></i></a> */}
                                                </li>
                                            ))}
                                        </ul>
                                    </WmsCollapse>
                                )}

                                <WmsCollapse id={'search_collapse1'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse1') ? !this.state.isExpandedCollapse['search_collapse1'] : true} showDragIcon={true} headerLabel={this.translate('Label_Customer_Text')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse1')}>
                                    <WmsDropdown
                                        id="Customer"
                                        name="CustomerId"
                                        label={this.state.CustomLabels.Label_Customer}
                                        wmsSelector={this.props.selector + "Customer"}
                                        value={renderFilter.CustomerId}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsCustomer}
                                        textField='name'
                                        valueField='id'
                                        windowId={this.props.windowId}
                                    />

                                    <WmsDropdown
                                        id="Facility"
                                        name="FacilityId"
                                        label={this.state.CustomLabels.Label_Warehouse}
                                        wmsSelector={this.props.selector + "Facility"}
                                        value={renderFilter.FacilityId}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsFacility}
                                        textField='name'
                                        valueField='id'
                                        windowId={this.props.windowId}
                                    />
                                </WmsCollapse>

                                <WmsCollapse id={'search_collapse2'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse2') ? !this.state.isExpandedCollapse['search_collapse2'] : true} showDragIcon={true} headerLabel={this.translate('Label_Date_Search')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse2')}>
                                    <WmsDropdown
                                        id="IndexOptionsSearchBy"
                                        name="IndexOptionsDateColumn"
                                        label={this.translate('Label_Filter_By')}
                                        wmsSelector={this.props.selector + "SearchByOptions"}
                                        value={renderFilter.IndexOptionsDateColumn}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsSearchBy}
                                        textField='description'
                                        windowId={this.props.windowId}
                                        valueField='value'
                                        extraWrapperClass={'dropdown-width-75'}
                                    />

                                    <WmsCustomDateTimePicker
                                        id={'DatePickerStartDate'}
                                        name='StartDate'
                                        wmsSelector={this.props.selector + "DatePickerStartDate"}
                                        label={this.translate('Label_Start')}
                                        onChangeFunc={this.handleFilterChange}
                                        value={renderFilter.StartDate}
                                        windowId={this.props.windowId}
                                        isDateParseInCustomFormat={true}
                                        defaultTime={'00:00:00'}

                                    />
                                    <WmsCustomDateTimePicker
                                        id={'DatePickerEndDate'}
                                        name='EndDate'
                                        wmsSelector={this.props.selector + "DatePickerEndDate"}
                                        label={this.translate('Label_End_A')}
                                        onChangeFunc={this.handleFilterChange}
                                        value={renderFilter.EndDate}
                                        windowId={this.props.windowId}
                                        defaultTime={'23:59:59'}
                                        isDateParseInCustomFormat={true}
                                    />
                                </WmsCollapse>

                                <WmsCollapse id={'search_collapse3'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse3') ? !this.state.isExpandedCollapse['search_collapse3'] : true} showDragIcon={true} headerLabel={this.translate('Label_Receipt_Detail')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse3')}>
                                    <WmsDropdown
                                        id="Status"
                                        name="IndexOptionsStatus"
                                        label={this.translate('Label_Receipt_Status')}
                                        wmsSelector={this.props.selector + "Status"}
                                        value={renderFilter.IndexOptionsStatus}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsStatus}
                                        textField='description'
                                        windowId={this.props.windowId}
                                        valueField='value'
                                        extraWrapperClass={'dropdown-width-75'}
                                    />
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="SearchByReferenceNumber"
                                            name="IndexOptionsReferenceNum"
                                            label={this.translate('Label_ReferenceID')}
                                            wmsSelector={this.props.selector + "SearchByReferenceNumber"}
                                            value={renderFilter.IndexOptionsReferenceNum}
                                            onChangeFunc={this.handleFilterChange}
                                            options={this.state.optionsReferenceNumber}
                                            windowId={this.props.windowId}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='ReferenceNum'
                                            inputName='ReferenceNum'
                                            wmsSelector={this.props.selector + 'ReferenceNumber'}
                                            value={renderFilter.ReferenceNum}
                                            onChangeFunc={this.handleFilterChange}
                                            label=''
                                            parentClassName='input_box'
                                        />
                                    </div>

                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsPoNum"
                                            name="IndexOptionsPoNum"
                                            label={this.translate('Label_PO_Number')}
                                            wmsSelector={this.props.selector + "SearchByPurchaseOrder"}
                                            value={renderFilter.IndexOptionsPoNum}
                                            onChangeFunc={this.handleFilterChange}
                                            options={this.state.optionsPurchaseOrder}
                                            windowId={this.props.windowId}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='PoNum'
                                            inputName='PoNum'
                                            wmsSelector={this.props.selector + 'PurchaseOrder'}
                                            value={renderFilter.PoNum}
                                            onChangeFunc={this.handleFilterChange}
                                            label=''
                                            parentClassName='input_box'
                                        />
                                    </div>


                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsReceiptAdviseNumber"
                                            name="IndexOptionsReceiptAdviseNumber"
                                            label={this.translate('Label_Receipt_Advice_Number')}
                                            wmsSelector={this.props.selector + "SearchByReceiptAdviseNumber"}
                                            value={renderFilter.IndexOptionsReceiptAdviseNumber}
                                            onChangeFunc={this.handleFilterChange}
                                            options={this.state.optionsReceiptAdviseNumber}
                                            windowId={this.props.windowId}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='ReceiptAdviseNumber'
                                            inputName='ReceiptAdviseNumber'
                                            wmsSelector={this.props.selector + 'ReceiptAdviseNumber'}
                                            value={renderFilter.ReceiptAdviseNumber}
                                            onChangeFunc={this.handleFilterChange}
                                            label=''
                                            parentClassName='input_box'
                                        />
                                    </div>
                                    <div className="wms-radio-wrapper">
                                        <span className="wms-label">
                                            {this.translate("Label_Receipt_Advice_Status")}
                                        </span>
                                        <div className="wms-radio">
                                            <WmsRadio
                                                label={this.translate("Label_All")}
                                                classes="small-radio-new"
                                                name={"IndexOptionsReceiptAdviseSent"}
                                                id="IndexOptionsReceiptAdviseSent"
                                                value={"2"}
                                                wmsSelector={this.props.selector + "ReceiptAdviceAll"}
                                                onChangeFunc={() => this.handleFilterChange({ target: { name: 'IndexOptionsReceiptAdviseSent', value: "2" } })}
                                                checked={renderFilter.IndexOptionsReceiptAdviseSent == "2"}
                                            />
                                            <WmsRadio
                                                label={this.translate("Label_Sent")}
                                                classes="small-radio-new"
                                                name={"IndexOptionsReceiptAdviseSent"}
                                                id="IndexOptionsReceiptAdviseSent1"
                                                value={"1"}
                                                wmsSelector={this.props.selector + "ReceiptAdviceSent"}
                                                onChangeFunc={() => this.handleFilterChange({ target: { name: 'IndexOptionsReceiptAdviseSent', value: "1" } })}
                                                checked={renderFilter.IndexOptionsReceiptAdviseSent == "1"}
                                            />
                                            <WmsRadio
                                                label={this.translate("Label_Not_Sent")}
                                                classes="small-radio-new"
                                                name={"IndexOptionsReceiptAdviseSent"}
                                                id="IndexOptionsReceiptAdviseSent2"
                                                value={"0"}
                                                wmsSelector={this.props.selector + "ReceiptAdviceNotSent"}
                                                onChangeFunc={() => this.handleFilterChange({ target: { name: 'IndexOptionsReceiptAdviseSent', value: "0" } })}
                                                checked={renderFilter.IndexOptionsReceiptAdviseSent == "0"}
                                            />
                                        </div>
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsNotes"
                                            name="IndexOptionsNotes"
                                            label={this.translate('Label_SectionTitle_Notes')}
                                            wmsSelector={this.props.selector + "SearchByTitleNotes"}
                                            value={renderFilter.IndexOptionsNotes}
                                            onChangeFunc={this.handleFilterChange}
                                            options={this.state.optionsTitleNotes}
                                            valueField='value'
                                            textField='description'
                                            windowId={this.props.windowId}
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Notes'
                                            inputName='Notes'
                                            wmsSelector={this.props.selector + 'TitleNotes'}
                                            value={renderFilter.Notes}
                                            onChangeFunc={this.handleFilterChange}
                                            label=''
                                            parentClassName='input_box'
                                        />
                                    </div>
                                </WmsCollapse>
                                <WmsCollapse id={'search_collapse4'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse4') ? !this.state.isExpandedCollapse['search_collapse4'] : true} showDragIcon={true} headerLabel={this.translate('Label_Transaction_Number')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse4')}>
                                    <WmsInput
                                        id='FirstTransaction'
                                        inputName='FirstTransaction'
                                        wmsSelector={this.props.selector + 'FirstTransaction'}
                                        value={renderFilter.FirstTransaction}
                                        onChangeFunc={this.handleFilterChange}
                                        label={this.translate('Label_First_Trans_Number')}
                                        parentClassName='form_group'
                                        extraWrapperClass={'half-width'}
                                    />
                                    <WmsInput
                                        id='LastTransaction'
                                        inputName='LastTransaction'
                                        wmsSelector={this.props.selector + 'LastTransaction'}
                                        value={renderFilter.LastTransaction}
                                        onChangeFunc={this.handleFilterChange}
                                        label={this.translate('Label_Last_Trans_Number')}
                                        parentClassName='form_group'
                                        extraWrapperClass={'half-width'}
                                    />
                                </WmsCollapse>

                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {this.state.isOpenSaveFilterModel && (
                        <WmsModel
                            id='saveCurrentFilter'
                            title={this.translate('Label_Save_Filter')}
                            width={450}
                            height={240}
                            onCloseFunc={this.exitSaveFilterModel}
                            isOpen={this.state.isOpenSaveFilterModel}
                            customClass='wms-model-new-ui-wrapper'>
                            <div className="model-content-wrapper">
                            <WmsContainer>
                                <WmsInput
                                    inputName="newFilterTitle"
                                    name="newFilterTitle"
                                    wmsSelector={this.props.selector + 'FilterTitle'}
                                    value={this.state.newFilterTitle}
                                    onChangeFunc={this.handleFilterTitleChange}
                                    extraWrapperClass="full-width-input"
                                    label={this.translate('Label_Filter_Title')} />
                            </WmsContainer>
                            </div>
                            <div className="footer-btn-holder save-filter-button-wrapper">
                                <WmsButton
                                    label={this.translate('Label_Cancel')}
                                    wmsSelector={this.props.selector + "SaveNewFilterExit"}
                                    onClickFunc={this.exitSaveFilterModel}
                                    buttonType="orange"
                                    icon={'fa-ban'}
                                />
                                <WmsButton
                                    label={this.translate('Label_Add')}
                                    wmsSelector={this.props.selector + "SaveNewFilter"}
                                    onClickFunc={this.onSaveFilter}
                                    disabled={this.state.newFilterTitle == '' || this.state.filterNameExist}
                                    icon={'fa-plus'}
                                    showToolTip={this.state.filterNameExist}
                                    tooltipText={this.translate('Label_Filter_Name_Exist_Message')}
                                />
                            </div>
                        </WmsModel>
                    )}
                </div>

                <WmsConfirmModel
                    id={"ConfirmModel" + this.props.windowId}
                    isOpen={this.state.isOpenConfirmModel}
                    type={this.translate('Label_Confirmation')}
                    width={500}
                    height={175}
                    firstBtnLabel={this.translate('Label_Yes')}
                    firstBtnIcon={"fa-check-square"}
                    firstBtnOnClickFunc={this.onYesRemoveSavedFilter}
                    sawCheckBox={false}
                    conFormModelText={this.translate('Label_are_you_sure_remove_filter')}
                    onCloseFunc={this.ClickExit}
                    thirdBtnLabel={this.translate('Label_No')}
                    thirdBtnIcon={"fa-times-circle"}
                />
                <WmsConfirmModel
                    id={"ConfirmUpdateModel" + this.props.windowId}
                    isOpen={this.state.isOpenUpdateConfirmModel}
                    type={this.translate('Label_Confirmation')}
                    width={500}
                    height={175}
                    firstBtnLabel={this.translate('Label_Yes')}
                    firstBtnIcon={"fa-check-square"}
                    firstBtnOnClickFunc={this.onUpdateFilter}
                    sawCheckBox={false}
                    conFormModelText={this.translate('Label_are_you_sure_update_filter')}
                    onCloseFunc={this.onCloseUpdateConfirmModel}
                    thirdBtnLabel={this.translate('Label_No')}
                    thirdBtnIcon={"fa-times-circle"}
                    isLoadingStop={false}
                />
            </div>
        );
    }
}
