import React from 'react';
import * as manageReceiptService from '../manageReceipt.services.jsx';
//component
import WmsButton from './../../../components/WmsButton/WmsButton.jsx';
import WmsCheckBox from './../../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDateTimePicker from './../../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import WmsFormGrid from './../../../components/WmsFormGrid/WmsFormGrid.jsx';
import WmsInput from './../../../components/WmsInput/WmsInput.jsx';
import WmsModel from './../../../components/WmsModel/WmsModel.jsx';
import WmsCollapse from './../../../components/WmsCollapse/WmsCollapse.jsx';
// import { StringWms } from '../../../../assets/ts/StringWms.ts';
//services
import * as GlobalService from './../../../global_services';
import './confirm-receipt-modal.scss';



export class ConfirmReceiptModal extends React.Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;

        let autoAllocateToOpenOrders = false;
        if (this.props.sharedSelectedCustomer.options.autoCheckAutoReallocateOnReceive) {
            autoAllocateToOpenOrders = true;
        }

        this.state = {
            selector: "Receipt",
            gifConfirmReceipt: false,
            isChargesLoaded: false,
            autoAllocateToOpenOrders: autoAllocateToOpenOrders,
            canInvoiceOnConfirm: false,
            createInvoice: false,
            staticGridData: [],
            totalCharges: 0,
            arrivalDate: '',
            Rights: GlobalService.GetRights(),
            TplSettings: GlobalService.getTplSettings(),
            warehouseMaxDateTime: "",
            columns: [
            {
                field: "ChargeType",
                title: this.translate('Label_Charge_Type')
            },
            {
                field: "ChargeAmount",
                title: this.translate('Label_Charge_Amount')
            }],
            schema: {
                model: {
                    fields: {
                        Class: {
                            type: "string"
                        },
                        ChargeType: {
                            type: "string"
                        },
                        ChargeAmount: {
                            type: "number"
                        }
                    }
                }
            }
        }
    }
    componentDidMount(){
        manageReceiptService.getConfirmOptions(this.props.shareReceipt.EditUri).then((response) => {

            let combinedArray = [];
            let totalCharges = 0;
            let summaryRows = response.SummaryRows || [];

            for (let x = 0; x < summaryRows.length; x++) {
                combinedArray.push(summaryRows[x]);
                totalCharges = totalCharges + summaryRows[x].ChargeAmount;
            }
            this.setState({
                canInvoiceOnConfirm:response.CanInvoiceOnConfirm,
                createInvoice:response.CreateInvoice,
                totalCharges: totalCharges,
                staticGridData: combinedArray,
                isChargesLoaded: true,
            });

        }).finally(() => {
        });
        GlobalService.getFacilityMaxDateTime(this.props.shareReceipt.FacilityId).then((facilityCurrentTime) => {
            this.setState({
                warehouseMaxDateTime: facilityCurrentTime,
                arrivalDate: this.state.TplSettings.Receiving.AutoSetArrivalDate ? kendo.toString(kendo.parseDate(facilityCurrentTime), 'yyyy-MM-ddTHH:mm:ss') : this.props.shareReceipt.ArrivalDate
            });
        });

    }

    confirmReceipt = () => {

        this.setState({ gifConfirmReceipt: true });
        manageReceiptService.confirmReceipt(this.props.shareReceipt.EditUri, this.state.autoAllocateToOpenOrders, this.state.arrivalDate,this.state.createInvoice).then((response) => {

            this.setState({ gifConfirmReceipt: false });
            GlobalService.notification("success",this.translate("Label_Receipt_Confirm"));
            let shipAndCloseWindow = $('#shipAndClose' + this.props.windowId).data('kendoWindow');
            if (shipAndCloseWindow) {
                shipAndCloseWindow.close();
            }

            GlobalService.closeWindow(this.props.windowId);

            var eventItem = new CustomEvent("EventNewReceiptData", {
                detail: { "action": "update" }
            });

            document.dispatchEvent(eventItem);
        }).finally(() => {
            this.setState({ gifConfirmReceipt: false });
        });

    }

    handleDateChange = (event) => {
        this.setState({ arrivalDate: event.target.value });
    }
    handleCheckBoxChange = (event) => {
        var key = event.target.name;
        // this.setState({ autoAllocateToOpenOrders: !this.state.autoAllocateToOpenOrders });
        if (key == 'allocateOpenOrder') {
            this.setState({ autoAllocateToOpenOrders: !this.state.autoAllocateToOpenOrders });
        } else if (key == 'createInvoice') {
            this.setState({ createInvoice: !this.state.createInvoice });
        }
    }


    render() {
        return (
            <WmsModel
                id={'shipAndClose' + this.props.windowId}
                title={this.translate('Label_Confirm_Receipt')}
                class="confirm-receipt"
                width={800}
                height={483}
                onCloseFunc={this.props.onClose}
                isOpen={this.props.sharedIsOpenConfirmReceiptModal}
                customClass='wms-model-new-ui-wrapper'>
                    <div className="model-content-wrapper">
                        <div>
                        {this.state.warehouseMaxDateTime &&
                        <WmsDateTimePicker
                            id={'DatePickerArrivalDate'}
                            name='DatePickerArrivalDate'
                            wmsSelector={this.state.selector + 'DatePickerArrivalDate'}
                            label={this.translate('Label_Arrival_Date')}
                            onChangeFunc={this.handleDateChange}
                            maxDateTime={this.state.warehouseMaxDateTime}
                            value={this.state.arrivalDate}
                            isDateParseInCustomFormat={true} />
                        }
                        </div>
                        {this.state.Rights.hasOwnProperty('receipts.charges.manage') ? (
                        <div className="charges-grid-holder">
                            <WmsCollapse
                                id={'ManageReceiptConfirmReceiptCharge'+ this.props.windowId}
                                headerLabel={this.translate('Label_Are_These_Charges')}
                                showCollapseText={false}
                                showCollapseIcon={false} >

                                <WmsFormGrid
                                    id={'GridCombinedCharges' + this.props.windowId}
                                    wmsSelector={this.state.selector + 'GridCombinedCharges'}
                                    columns={this.state.columns}
                                    staticGridData={this.state.staticGridData}
                                    schema={this.state.schema}
                                    parentClassName={'showHeader'}
                                    showResetMenu={false}
                                    />
                            </WmsCollapse>
                        </div>
                        ):null}
                        <div className="chk-and-input-holder">
                        <div className="chk-wrapper">
                        {/* {this.state.Rights.hasOwnProperty('Receiving.AutoReallocOnReceiveEnabled') && ( */}
                            <WmsCheckBox
                                id={'allocateOpenOrder'}
                                name={'allocateOpenOrder'}
                                wmsSelector={this.state.selector + 'allocateOpenOrder'}
                                onChangeFunc={this.handleCheckBoxChange}
                                label={this.translate('Label_Auto_Allocate_to_CompleteOrders')}
                                checked={this.state.autoAllocateToOpenOrders}
                                value={this.state.autoAllocateToOpenOrders}
                                isRounded={true}
                                parentClassName={
                                    this.state.Rights.hasOwnProperty('Receiving.AutoReallocOnReceiveEnabled') ?
                                    '' :
                                    'wms-check-box-disable'
                                }
                            />
                        {/* )} */}
                        {this.state.Rights.hasOwnProperty('admin.invoices') && this.state.canInvoiceOnConfirm && (
                            <WmsCheckBox
                                id={'createInvoice'}
                                name={'createInvoice'}
                                wmsSelector={this.state.selector + 'createInvoice'}
                                onChangeFunc={this.handleCheckBoxChange}
                                label={this.translate('Label_Create_Invoice')}
                                checked={this.state.createInvoice}
                                value={this.state.createInvoice}
                                isRounded={true}/>
                        )}
                        </div>
                            {this.state.Rights.hasOwnProperty('receipts.charges.manage') ? (
                                <WmsInput
                                    inputName="GridShipChargesTotal"
                                    wmsSelector={this.state.selector + 'GridShipChargesTotal'}
                                    value={this.state.totalCharges}
                                    disabled={true}
                                    onChangeFunc={() => function () { }}
                                    label={this.translate('Label_Total_Charges')} />
                            ):null}
                        </div>
                    </div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={this.translate('Label_Select_Confirm')}
                            wmsSelector={this.state.selector + 'ConfirmReceiptModel'}
                            onClickFunc={this.confirmReceipt}
                            disabled={this.state.customFieldName == '' || !this.state.isChargesLoaded}
                            loading={this.state.gifConfirmReceipt || !this.state.isChargesLoaded}
                            icon='fa-check-circle' />

                        <WmsButton
                            label={this.translate('Label_Cancel')}
                            wmsSelector={this.state.selector + 'ConfirmReceiptCancel'}
                            onClickFunc={this.props.onClose}
                            icon='fa-times-circle'
                            buttonType='yellow' />
                    </div>
            </WmsModel>
        )
    }
}