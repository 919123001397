import _ from "lodash";
import React from "react";
import WmsCheckBox from "../components/WmsCheckBox/WmsCheckBox";
import WmsCollapse from "../components/WmsCollapse/WmsCollapse";
import WmsContainer from "../components/WmsContainer/WmsContainer";
import WmsCustomTimePickerProto from "../components/WmsCustomTimePickerProto/WmsCustomTimePickerProto";
import WmsDropdown from "../components/WmsDropdown/WmsDropdown";
import WmsInput from "../components/WmsInput/WmsInput";

export class CustomerFormAutobatchOption extends React.PureComponent {
    constructor(props) {
        super(props);

        this.translate = window.translate;

        this.state = {
            Selector: "Customer",
            carrierOptions: [
                {
                    key: "same_carrier",
                    value: "Same Carrier",
                },
                {
                    key: "any_carrier",
                    value: "Any Carrier",
                },
            ],
            ruleOptions: [
                {
                    title: "Quantity 1",
                    name: "Singles",
                    description: "Quantity 1",
                    isEnabled: false,
                    subDescription: "Same Item, Single Item, and quantity = 1",
                    GroupOnCarrier: true,
                    carrierName: "same_carrier",
                    showCarrierOptions: true
                },
                {
                    title: "Identical orders",
                    name: "SameSkuSameQty",
                    description: "Identical Orders",
                    subDescription: "Same Items and quantity",
                    GroupOnCarrier: true,
                    isEnabled: false,
                    carrierName: "same_carrier",
                    showCarrierOptions: true
                },
                {
                    title: "Identical Items",
                    name: "SameSku",
                    description: "Identical Items",
                    subDescription: "Same Items and any quantity",
                    GroupOnCarrier: true,
                    isEnabled: false,
                    carrierName: "same_carrier",
                    showCarrierOptions: true
                },
                {
                    title: "Carrier",
                    name: "Carrier",
                    description: "Carrier",
                    subDescription: "Same carrier only",
                    GroupOnCarrier: true,
                    isEnabled: false,
                    showCarrierOptions: false
                },
                {
                    title: "Customer and Warehouse",
                    name: "SameCustomerSameWarehouse",
                    description: "Customer and Warehouse",
                    subDescription: "Same Customer and Warehouse",
                    GroupOnCarrier: true,
                    isEnabled: false,
                    carrierName: "same_carrier",
                    showCarrierOptions: true
                },
            ],
            rules: [],
        };
    }

    componentDidUpdate = (prevProps) => {
        if (_.isEqual(prevProps.sharedCustomer.Options.Shipping.AutoBatchOptions.Rules, this.props.sharedCustomer.Options.Shipping.AutoBatchOptions.Rules) !== true) {
            if (this.props.sharedCustomer.Options.Shipping.AutoBatchOptions.Rules && this.props.sharedCustomer.Options.Shipping.AutoBatchOptions.Rules.length > 0) {
                let tmpRuleOptions = _.cloneDeep(this.state.ruleOptions);
                let tmpRules = _.map(tmpRuleOptions, (options) => {
                    var find = _.find(this.props.sharedCustomer.Options.Shipping.AutoBatchOptions.Rules, (rule) => {
                        return options.name == rule.Name;
                    });
                    if (find) {
                        options.isEnabled = true;
                        options.carrierName = find.GroupOnCarrier ? "same_carrier" : "any_carrier";
                    }
                    return options;
                });
                this.setState({
                    ruleOptions: tmpRules,
                });
            }
        }
    };

    handleChangeEvent = (e) => {
        let name = e.target.name;
        let parentObj = this.props.sharedCustomer.Options.Shipping.AutoBatchOptions;

        if(name === "CutOffTime" && e.target.value != null){
            var time = e.target.value.split(':');
            parentObj["CutOffHour"] = time[0];
            parentObj["CutOffMinute"] = time[1];
        }else if (e.target.type == "checkbox") {
            parentObj[name] = !this.props.sharedCustomer.Options.Shipping.AutoBatchOptions[name];

            if (!parentObj[name] && (parentObj["BatchSize"] < 1 || parentObj["BatchSize"] > 500)) {
                parentObj["BatchSize"] = 25; //DEFAULT_BATCH_SIZE
            }
        } else {
            parentObj[name] = e.target.value;
        }

        let customeObj = Object.assign({}, this.props.sharedCustomer, {
            Options: Object.assign({}, this.props.sharedCustomer.Options, {
                Shipping: Object.assign({}, this.props.sharedCustomer.Options.Shipping, {
                    AutoBatchOptions: parentObj,
                }),
            }),
        });

        this.props.onCustomerDataset(customeObj);
    };

    handleRulesChangeEvent = (rules) => (e) => {
        let name = e.target.name;
        let value = e.target.value;

        let tmpRuleOptions = _.cloneDeep(this.state.ruleOptions);

        if (name == "isEnabled") {
            value = !rules.isEnabled;
        }

        let tmpRules = [];
        tmpRuleOptions = _.map(tmpRuleOptions, (options) => {
            if (options.name == rules.name) {
                if (name == "carrierName") {
                    options["GroupOnCarrier"] = value == "same_carrier";
                    options[name] = value;
                }else {
                    options[name] = value;
                }
            }
            if (options.isEnabled) {
                tmpRules.push({
                    Name: options.name,
                    GroupOnCarrier: options.GroupOnCarrier,
                });
            }
            return options;
        });

        this.setState({
            ruleOptions: tmpRuleOptions,
        });
        let customeObj = Object.assign({}, this.props.sharedCustomer, {
            Options: Object.assign({}, this.props.sharedCustomer.Options, {
                Shipping: Object.assign({}, this.props.sharedCustomer.Options.Shipping, {
                    AutoBatchOptions: Object.assign({}, this.props.sharedCustomer.Options.Shipping.AutoBatchOptions, {
                        Rules: tmpRules,
                    }),
                }),
            }),
        });

        this.props.onCustomerDataset(customeObj);
    };

    render() {
        const renderCustomerOptions = this.props.sharedCustomer.Options;
        const cutOffTime = renderCustomerOptions.Shipping.AutoBatchOptions.CutOffHour +":"+ renderCustomerOptions.Shipping.AutoBatchOptions.CutOffMinute;
        return (
            <div className="collapseCustomerTabContent_wrapper autobatch-tab-wrapper" id={"collapseAutobathOption"}>
                <WmsCollapse id={"collapseAutobatchOptionContent"} headerLabel={this.translate("Label_General")} showCollapseText={false} showCollapseIcon={true}>
                    <div className="display-flex">
                        <WmsCheckBox id={"Enabled"} name={"Enabled"} wmsSelector={this.state.Selector + "Enabled"} onChangeFunc={this.handleChangeEvent} label={this.translate("Label_AutoBatch_Enable")} checked={renderCustomerOptions.Shipping.AutoBatchOptions.Enabled} parentClassName={"enable-autobatch"} />

                        <WmsCustomTimePickerProto
                            id={"CutOffTime"}
                            name="CutOffTime"
                            label={this.translate("Label_Batch_Cut_Off_Time")}
                            windowId={this.windowId}
                            value={cutOffTime}
                            defaultTime="15:30"
                            onChangeFunc={this.handleChangeEvent}
                            wmsSelector={this.state.Selector + "CutOffTime"}
                            extraWrapperClass={"cut-off-time-wrapper"}
                            disabled={!renderCustomerOptions.Shipping.AutoBatchOptions.Enabled}
                            defaultFormat={"HH:mm"}
                            isDateParseInCustomFormat={"HH:mm"}
                            interval={15}
                        />

                        <WmsInput id="BatchSize" inputName="BatchSize" wmsSelector={this.props.Selector + "BatchSize"} onChangeFunc={this.handleChangeEvent} label={window.translate("Label_Batch_Size")} disabled={!renderCustomerOptions.Shipping.AutoBatchOptions.Enabled} value={renderCustomerOptions.Shipping.AutoBatchOptions.BatchSize} extraWrapperClass={"batchSize-wrapper"} />
                    </div>

                    {/* </WmsContainer> */}
                </WmsCollapse>

                <WmsCollapse id={"collapseAutobatchRulesOptionContent"} headerLabel={this.translate("Label_Batch_Rules")} showCollapseText={false} showCollapseIcon={true}>
                    <div className="title">
                        <div className="wms-sprite fa fa-exclamation-triangle"></div>
                        <span>{this.translate("AutoBatch_Rules_Message")}</span>
                    </div>
                    <div className="auto-batch-setting-options-wrapper">
                        {this.state.ruleOptions.map((rule, index) => (
                            <div key={index} className={"auto-batch-setting-wrapper " + (!renderCustomerOptions.Shipping.AutoBatchOptions.Enabled ? "disabled-wrapper" : "")}>
                                <WmsContainer className="autobatch-setting-container" id={rule.title} title={rule.title}>
                                    <div className="inline-flex">
                                        <div className="autobatch-checkbox">
                                            <WmsCheckBox
                                                id={`isEnabled_${index}`}
                                                name={"isEnabled"}
                                                wmsSelector={this.state.Selector + "isEnabled"}
                                                onChangeFunc={this.handleRulesChangeEvent(rule)}
                                                label={this.translate("Label_Enabled")}
                                                checked={rule.isEnabled}
                                                parentClassName={"enable-autobatch"}
                                                disabled={!renderCustomerOptions.Shipping.AutoBatchOptions.Enabled || (!rule.isEnabled && this.state.ruleOptions.filter(el => el.isEnabled).length)}
                                             />
                                        </div>
                                        <div className={`autobatch-quantity ${!renderCustomerOptions.Shipping.AutoBatchOptions.Enabled || !rule.isEnabled ? "disabled-wrapper" : ""}`}>
                                            <div>
                                                <p className="autobatch-qty">{rule.description}</p>
                                                <span>{rule.subDescription}</span>
                                            </div>
                                            {rule.showCarrierOptions ? (
                                                <WmsDropdown
                                                    id={`carrierName_${index}`}
                                                    name={"carrierName"} label={""}
                                                    wmsSelector={this.props.Selector + "carrierName"}
                                                    value={rule.carrierName}
                                                    onChangeFunc={this.handleRulesChangeEvent(rule)}
                                                    options={this.state.carrierOptions}
                                                    textField="value" valueField="key"
                                                    disabled={!renderCustomerOptions.Shipping.AutoBatchOptions.Enabled || !rule.isEnabled}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </WmsContainer>
                            </div>
                        ))}
                    </div>
                </WmsCollapse>
            </div>
        );
    }
}
