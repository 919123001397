import React, { useState, useEffect } from "react";
import AdjustCustomerChargesSearch from "./adjust-customer-charges-search.component";
import AdjustCustomerChargesGrid from "./adjust-customer-charges-grid.component";
import * as AdjustCustomerCharges from "./adjust-customer-charges.services";
import "./adjustCustomerCharges.scss";

interface Props {}

declare var window: any;
var $rootScope = window;
const wmsSelector = "AdustCustomerCharges";
const defaultFilterValue = {
    "CustomerID": null,
    "FacilityID": null,
    "WarehouseTransactionID": null,
    "ReferenceNum": null,
    "StartDate": null,
    "EndDate": null,
};

const AdjustCustomerChargesHolder: React.FC<Props> = (props: Props): any => {
    const [windowId] = useState($rootScope.ChildBulletin.Data.Window);
    const [sharedSearchOn, setSharedSearchOn] = useState(false);
    const [sharedFilterObject, setSharedFilterObject] = useState(defaultFilterValue);
    const [loadGrid, setLoadGrid] = useState(false);

    useEffect(() => {
        console.log("useEffect called");
        async function getGridMeta() {
            await AdjustCustomerCharges.getAdjustCustomerChargesClientMetaData();
            let filterData = AdjustCustomerCharges.getAdjustCustomerChargesFilterMetaData();
            if (filterData) {
                setSharedFilterObject(filterData);
            }
            setLoadGrid(true);
        }
        getGridMeta();
    }, []);

    const onClearSearch = () => {
        AdjustCustomerCharges.clearAdjustCustomerChargesFilterMetaData();
        setSharedSearchOn(false);
        setSharedFilterObject(defaultFilterValue);
    };

    const onFilterDataSet = (filterObject: any) => {
        if (
            filterObject.CustomerID !== 0 ||
            filterObject.FacilityID !== 0 ||
            filterObject.WarehouseTransactionID !== null ||
            filterObject.ReferenceNum !== null ||
            filterObject.StartDate !== null ||
            filterObject.EndDate !== null
        ) {
            setSharedSearchOn(true);
        }
        setSharedFilterObject(filterObject);
        AdjustCustomerCharges.setAdjustCustomerChargesFilterMetaData(filterObject);
    };

    return (
        <div className="adjust-customer-charges-wrapper">
            <AdjustCustomerChargesSearch
                wmsSelector={wmsSelector}
                sharedFilterObject={sharedFilterObject}
                sharedSearchOn={sharedSearchOn}
                onClearSearch={onClearSearch}
                onFilterDataSet={onFilterDataSet}
                windowId={windowId}
            />
            {loadGrid && (
                <AdjustCustomerChargesGrid
                    wmsSelector={wmsSelector}
                    windowId={windowId}
                    sharedFilterObject={sharedFilterObject}
                />
            )}
        </div>
    );
};
AdjustCustomerChargesHolder.propTypes = {};

AdjustCustomerChargesHolder.defaultProps = {};

export default React.memo(AdjustCustomerChargesHolder);
