import React, { useState } from "react";
import PropTypes from "prop-types";

import WmsModel from "./../react/components/WmsModel/WmsModel";
import WmsCollapse from "./../react/components/WmsCollapse/WmsCollapse";
import WmsDropdown from "./../react/components/WmsDropdown/WmsDropdown";
import WmsButton from "./../react/components/WmsButton/WmsButton";
import WmsCheckBox from "./../react/components/WmsCheckBox/WmsCheckBox.jsx";
import WmsFile from "./../react/components/WmsFile/WmsFile";

import * as GlobalService from "./../react/global_services";
import "./manageassembly.scss";

declare var window: any;
const { $ } = window;
const defaultImportAssemblyData = {
    CustomerId: 0,
    FacilityId: 0,
    fileName: "",
    fileSize: "",
    fileObject: null,
    onErrorCancleImport: false,
};

interface Props {
    onCloseImportAssemblyModal: any;
    isOpenImportAssemblyModal: boolean;
    wmsSelector: string;
    windowId: string;
    onApplyImportAssembly: any;
}

const ImportassemblyModal: React.FC<Props> = (props: Props): any => {
    const CustomLabels = GlobalService.getCustomLabels();
    const CustomerOptions = GlobalService.getCustomerList(true);
    const [facilityOPtions, setFacilityOptions] = useState(GlobalService.getFacilityList(true));
    const [importAssemblyData, setImportAssemblyData] = useState(defaultImportAssemblyData);
    const [sharedFileUploadData, setSharedFileUploadData] = useState(null);
    const [isFileDrage, setFileDrage] = useState(false);

    const handleChangeAssemblyData = (event: any) => {
        let { name, value } = event.target;
        var finalValue = value;
        if (name === "CustomerId") {
            finalValue = parseInt(value);
            handleCustomerChange(parseInt(finalValue));
        } else if (name === "FacilityId") {
            finalValue = parseInt(value);
        } else if (name === "onErrorCancleImport") {
            finalValue = event.target.checked;
        }

        setImportAssemblyData((prevState: any) => ({
            ...prevState,
            [name]: finalValue,
        }));
    };
    const handleCustomerChange = (CustomerID: any) => {
        var selectedCustomer = CustomerOptions.find((cus: any) => CustomerID !== 0 && cus.id === CustomerID);
        if (selectedCustomer) {
            var customerFacilityIds = selectedCustomer.facilities;
            let allFacilities = GlobalService.getFacilityList(true);
            allFacilities = allFacilities.filter((f: any) => customerFacilityIds.includes(f.id) || f.id === 0);
            setFacilityOptions(allFacilities);
        } else {
            setFacilityOptions([]);
        }
    };
    const allValidationDone = () => {
        return !(
            importAssemblyData.CustomerId !== 0 &&
            importAssemblyData.FacilityId !== 0 &&
            importAssemblyData.fileName !== "" &&
            importAssemblyData.fileSize !== ""
        );
    };
    const handleFileUpload = (fileUploadedData) => {
        let fileSize = GlobalService.getFileSizeString(fileUploadedData.fileSize);
        setImportAssemblyData((prevState: any) => ({
            ...prevState,
            fileName: fileUploadedData.fileName,
            fileSize: fileSize,
        }));
        setSharedFileUploadData(fileUploadedData);
        setFileDrage(false);
    };
    const onClickSave = () => {
        // check if file is greater than 2MB
        if (sharedFileUploadData !== null && sharedFileUploadData.fileSize > 2000000) {
            let _data = {
                "Source": 1,
                "ServerName": "3PL-QA-APP",
                "Descriptions": [
                    {
                        "ErrorClass": "Http",
                        "ErrorCode": "System",
                        "Descriptors": ["Maximum request length exceeded."],
                        "Hint": null,
                    },
                ],
                "ErrorClass": "Http",
                "ErrorCode": "System",
                "type": "error",
            };
            GlobalService.showErrorMessage(_data);
            return false;
        }
        // check if file is 0 size and blank content
        if (
            (sharedFileUploadData !== null && sharedFileUploadData.fileSize === 0) ||
            (sharedFileUploadData.fileread && sharedFileUploadData.fileread.trim() === "")
        ) {
            let _data = {
                "Source": 1,
                "ServerName": "3PL-QA-APP",
                "Descriptions": [
                    {
                        "ErrorClass": "Http",
                        "ErrorCode": "System",
                        "Descriptors": ["Minimum request length exceeded."],
                        "Hint": null,
                    },
                ],
                "ErrorClass": "Http",
                "ErrorCode": "System",
                "type": "error",
            };
            GlobalService.showErrorMessage(_data);
            return false;
        }
        var targetElement = $(`[data-wms-selector="${props.wmsSelector}Save"]`);
        window.animationMoveIcon(targetElement);
        window.taskStarted();
        let data = {
            customerId: importAssemblyData.CustomerId,
            facilityId: importAssemblyData.FacilityId,
            skipInvalidAssemblies: !importAssemblyData.onErrorCancleImport,
            File: sharedFileUploadData.fileread,
            type: "importAssemblyAsync",
            callBackFunc: props.onApplyImportAssembly,
        };

        /**Code for react progress component */
        var event = new CustomEvent("showProgressModal", {
            detail: data,
        });
        document.dispatchEvent(event);
        props.onCloseImportAssemblyModal();
    };
    const onClickDownloadTemplateFile = () => {
        window.open("https://help.3plcentral.com/hc/en-us/articles/360038228512-Import-Templates", "_blank");
    };
    const onDragOver = () => {
        setFileDrage(true);
    };
    const onDragStop = () => {
        setFileDrage(false);
    };

    return (
        <>
            <WmsModel
                id="ImportAssembliesModel"
                title={window.translate("Label_Import_Assemblies")}
                width={800}
                height={800}
                onCloseFunc={props.onCloseImportAssemblyModal}
                isOpen={props.isOpenImportAssemblyModal}
                customClass="wms-model-new-ui-wrapper"
            >
                <div className={"model-content-wrapper"}>
                    <WmsCollapse
                        id={"ImportAssemblyTransactionInfo"}
                        headerLabel={window.translate("Label_Template_Import")}
                        showCollapseText={false}
                    >
                        <div className="template-upload-wrapper">
                            <div className="">
                                <WmsDropdown
                                    id="customer"
                                    name="CustomerId"
                                    label={CustomLabels.Label_Customer}
                                    wmsSelector={props.wmsSelector + "SelectCustomer"}
                                    value={importAssemblyData.CustomerId}
                                    onChangeFunc={handleChangeAssemblyData}
                                    options={CustomerOptions}
                                    valueField={"id"}
                                    textField={"name"}
                                    required={true}
                                />
                                <WmsDropdown
                                    id="warehouseFacilityID"
                                    name="FacilityId"
                                    label={CustomLabels.Label_Warehouse}
                                    wmsSelector={props.wmsSelector + "SelectWarehouse"}
                                    value={importAssemblyData.FacilityId}
                                    onChangeFunc={handleChangeAssemblyData}
                                    options={facilityOPtions}
                                    valueField={"id"}
                                    textField={"name"}
                                    required={true}
                                />
                            </div>
                            <div className="">
                                <WmsCheckBox
                                    id={"onErrorCancleImport"}
                                    name={"onErrorCancleImport"}
                                    wmsSelector={props.wmsSelector + "onErrorCancleImport"}
                                    onChangeFunc={handleChangeAssemblyData}
                                    label={window.translate("Label_On_Error_Abort_Import")}
                                    checked={importAssemblyData.onErrorCancleImport}
                                />
                            </div>
                            <div className=" import-assembly-area">
                                <label>{window.translate("Label_Template_file_Upload")}</label>
                                <div className="import-area">
                                    <div className={"import-drag-area" + (isFileDrage ? " is-dragged" : "")}>
                                        <div className="file-icon-and-name">
                                            <i className="fa fa-file-alt"></i>
                                            {importAssemblyData.fileName !== "" && importAssemblyData.fileSize !== "" && (
                                                <label className="import-file-name-and-size">
                                                    {importAssemblyData.fileName}
                                                    <span>({importAssemblyData.fileSize})</span>
                                                </label>
                                            )}
                                        </div>
                                        <label>
                                            {window
                                                .translate("Label_Drag_file_here")
                                                .replace("%file.max.size%", "2 MB")}
                                        </label>
                                        <hr />
                                        <label>{window.translate("Label_Or")}</label>
                                        <WmsFile
                                            id={props.wmsSelector + "ImportFileDrag"}
                                            name={props.wmsSelector + "ImportFileDrag"}
                                            label={window.translate("Label_Browse_Files")}
                                            wmsSelector={props.wmsSelector + "ImportFileDrag"}
                                            onChangeFunc={handleFileUpload}
                                            disabled={
                                                importAssemblyData.CustomerId === 0 ||
                                                importAssemblyData.FacilityId === 0
                                            }
                                            clearInputOnClick={true}
                                            value={""}
                                            icon={"fa-file-image"}
                                            onDragOver={onDragOver}
                                            onDragStop={onDragStop}
                                        />
                                    </div>
                                    <div className="button-area">
                                        <WmsButton
                                            label={window.translate("Label_Download_Template_File")}
                                            wmsSelector={props.wmsSelector + "DownloadTemplate"}
                                            onClickFunc={onClickDownloadTemplateFile}
                                            icon={"fa-file-download"}
                                        />
                                        <WmsFile
                                            id={props.wmsSelector + "ImportFile"}
                                            name={props.wmsSelector + "ImportFile"}
                                            label={window.translate("Label_Browse_Files")}
                                            wmsSelector={props.wmsSelector + "ImportFiles"}
                                            onChangeFunc={handleFileUpload}
                                            disabled={
                                                importAssemblyData.CustomerId === 0 ||
                                                importAssemblyData.FacilityId === 0
                                            }
                                            clearInputOnClick={true}
                                            value={""}
                                            icon={"fa-file-image"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={window.translate("Label_Cancel")}
                        wmsSelector={props.wmsSelector + "DiscardExit"}
                        onClickFunc={props.onCloseImportAssemblyModal}
                        buttonType="orange"
                        icon={"fa-ban"}
                    />
                    <WmsButton
                        label={window.translate("Label_Import")}
                        wmsSelector={props.wmsSelector + "Save"}
                        onClickFunc={onClickSave}
                        icon={"fa-floppy-o"}
                        disabled={allValidationDone()}
                        parentClassName={"apply-adjustment-button"}
                    />
                </div>
            </WmsModel>
        </>
    );
};
ImportassemblyModal.propTypes = {
    onCloseImportAssemblyModal: PropTypes.any.isRequired,
    isOpenImportAssemblyModal: PropTypes.bool.isRequired,
    wmsSelector: PropTypes.string.isRequired,
    windowId: PropTypes.string.isRequired,
    onApplyImportAssembly: PropTypes.any.isRequired,
};

ImportassemblyModal.defaultProps = {
    onCloseImportAssemblyModal: (): void => {},
    isOpenImportAssemblyModal: false,
    wmsSelector: "",
    windowId: "",
    onApplyImportAssembly: (): void => {},
};

export default React.memo(ImportassemblyModal);
