import React from 'react';

//component
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse.jsx';
import WmsCheckBox from '../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsModel from './../components/WmsModel/WmsModel.jsx';

import * as GlobalService from './../global_services';
import * as itemService from './items.services.jsx';
import style from './items-export-modal.scss'

export default class ItemsExportModal extends React.Component {

    constructor(props) {

        super(props);
        this.translate = window.translate;
        this.isImperial = GlobalService.getMeasurementStandardFlag(this.props.sharedSelectedCustomerObj.defaultFacility ? this.props.sharedSelectedCustomerObj.defaultFacility : 0);

        this.state = {
            selector: "ExportItems",
            isIncludeInactiveItem: false,
            exportingGif: false,
            supportedFormats: [{ id: "csv", name: "CSV" }]
        };
    }

    componentDidMount() {
        //
    }

    onChangeIncludeInactiveItems = (e) => {
        this.setState({
            isIncludeInactiveItem: e.target.checked
        })
    }

    exportData = () => {
        let params = {
            "custId": this.props.sharedSelectedCustomerObj.id,
            "inactiveItems": this.state.isIncludeInactiveItem,
            "isimperial": this.isImperial
        }
        let fileName = "itemsGridExport";

        this.setState({ exportingGif: true });
        if (this.props.isExportSelectedItems) {
            params.skus = this.props.sharedSelectedItemSkus;

            itemService.exportSelectedBatchItems(params).then((response) => {
                if (typeof response.data != 'undefined' && response.status == 200) {
                    GlobalService.downloadFileBaseOnContentType(response.data, 'application/txt', fileName);
                }
            }).finally(() => {
                this.setState({ exportingGif: false });
            });

        } else {
            itemService.exportAllBatchItems(params).then((response) => {
                if (typeof response.data != 'undefined' && response.status == 200) {
                    GlobalService.downloadFileBaseOnContentType(response.data, 'application/txt', fileName);
                }
            }).finally(() => {
                this.setState({ exportingGif: false });
            });
        }
    }

    render() {
        return (
            <WmsModel
                id={this.props.id}
                title={this.translate('Label_Export_Item_Information')}
                width={800}
                height={414}
                onCloseFunc={this.props.onCloseItemExportModal}
                isOpen={this.props.isOpenItemExportModal}
                customClass={'wms-model-new-ui-wrapper'}
            >
                <div className='model-content-wrapper'>
                    <WmsCollapse id="itemsExportOptions" headerLabel={this.translate('Label_Export_Options')} showCollapseText={false} showCollapseIcon={false}>
                        <div className="message-section">
                            {this.props.isExportSelectedItems ?
                                <span dangerouslySetInnerHTML={{ __html: this.translate('Label_Export_Selected_Message').replace('{itemcount}' , this.props.sharedSelectedItemSkus.length).replace('{item}' , (this.props.sharedSelectedItemSkus.length > 1 ? 'items' : 'item') ) }} />
                                :
                                <span dangerouslySetInnerHTML={{ __html: this.translate('Label_Export_All_Message').replace('{customername}', this.props.sharedSelectedCustomerObj.name) }} />
                            }
                        </div>
                        <hr />
                        <div className="file-format-section">
                            {/* <WmsDropdown
                                id="selectFormat"
                                name="selectFormat"
                                label={this.translate('Label_Select_Format')}
                                wmsSelector={this.state.selector + "SelectFormat"}
                                value={""}
                                onChangeFunc={() => null}
                                options={this.state.supportedFormats}
                                valueField='id'
                                textField='name'
                            /> */}

                            <WmsCheckBox
                                id="includeInactiveItems"
                                name="includeInactiveItems"
                                wmsSelector={this.state.selector + "IncludeInactiveItems"}
                                onChangeFunc={this.onChangeIncludeInactiveItems}
                                label={this.translate('Label_Include_Inactive_Items')}
                                checked={this.state.isIncludeInactiveItem}
                                value=""
                            />
                        </div>
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Cancel')}
                        wmsSelector={this.state.selector + 'Cancel'}
                        onClickFunc={this.props.onCloseItemExportModal}
                        buttonType='orange'
                        icon="fa-ban"
                    />

                    <WmsButton
                        label={this.translate('Label_Export')}
                        wmsSelector={this.state.selector + 'Export'}
                        onClickFunc={this.exportData}
                        icon='fa-file-export'
                        loading={this.state.exportingGif}
                    />
                </div>
            </WmsModel>
        );
    }
}