import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsComboBox.scss';

class WmsComboBox extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            options: props.options,
            value:props.value
        }
    }
    componentDidMount() {
        this.bindKendoComboBox();
    }
    static getDerivedStateFromProps (nextProps, prevState) {        
        if (nextProps.options !== prevState.options) {
            if (typeof $("#" + nextProps.id).data("kendoComboBox") != "undefined") {
                var multiselect = $("#" + nextProps.id).data("kendoComboBox");
                var newDataSource = new kendo.data.DataSource({
                    data: nextProps.options
                });
                multiselect.setDataSource(newDataSource);
                return { options: nextProps.options };
            }
        }
        if (nextProps.value !== prevState.value) {
            if (typeof $("#" + nextProps.id).data("kendoComboBox") != "undefined") {
                $("#" + nextProps.id).data("kendoComboBox").value(nextProps.value);
            }
            return { value: nextProps.value };
        }

        var addClasses = (((nextProps.required) ? "wms_required_input" : ''));
        var removeClasses = (((!nextProps.required) ? "wms_required_input" : ''));

        $(" #" + nextProps.id).parents('.k-combobox').addClass(addClasses).removeClass(removeClasses);
        return null;
    }
    componentDidUpdate() {
        if (typeof $("#" + this.props.id).data('kendoComboBox') == "undefined") {
            this.bindKendoComboBox();
        }
    }
    componentWillUnmount(){
        $("#" + this.props.id).val();
    }
    bindKendoComboBox() {
        var that = this;
        $("#" + this.props.id).kendoComboBox({
            dataTextField: this.props.textField,
            dataValueField: this.props.valueField,
            filter: "contains",
            value: this.props.value != null ? this.props.value : '',
            noDataTemplate:this.props.noDataTemplate,
            placeholder: this.props.placeholder,
            virtual: (this.props.isVirtualOptions ? this.props.virtualObj : null),
            dataSource: (this.props.isVirtualOptions ? this.props.dataSourceObj : this.props.options),
            change: (e) => {
                that.props.onChangeFunc({
                    target: {
                        value: $("#" + that.props.id).val(),
                        name: this.props.name,
                        selectedIndex: e.sender.selectedIndex
                    }
                });
            }
        });

        var addClasses = (((this.props.required) ? "wms_required_input" : ''));
        $(" #" + this.props.id).parents('.k-combobox').addClass(addClasses);
    }
    render() {
        return (
            <div className={'wms-combobox-component ' + this.props.parentClassName}>
                <label className="label" htmlFor={this.props.id}>{this.props.label}{this.props.required ? ' *' : ''}</label>
                {/* <select id={this.props.id}
                    name={this.props.name}
                    data-wms-selector={this.props.wmsSelector}
                    onChange={() => null}
                    disabled={this.props.disabled}
                    required={this.props.required}>
                    <optgroup>
                        {this.props.options.map((Option, index) => (
                            <option key={index} value={Option[this.props.valueField]}>{Option[this.props.textField]}</option>
                        ))}
                    </optgroup>
                </select> */}
                 <input id={this.props.id}
                    name={this.props.name}
                    data-wms-selector={this.props.wmsSelector}
                    onChange={() => null}
                    disabled={this.props.disabled}
                    required={this.props.required} />
            </div>
        );
    }

}
WmsComboBox.propTypes = {
    id: PropTypes.string.isRequired, // this should write the id to the input
    name: PropTypes.string.isRequired, // this should write the name to the input
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]), // this should control the value of multiSelect Dropdown
    parentClassName: PropTypes.string, //    this should parent tag class
    options: PropTypes.array,// this should a option of dropdown
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
    disabled: PropTypes.bool,//this should set dropdown disable
    placeholder: PropTypes.string,//this should set combobox placeholder
    required: PropTypes.bool, // this determines if the input is required and shows in red
    isVirtualOptions:PropTypes.bool,
    noDataTemplate:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]) // set false to disapear filter options box when no recored found
}

WmsComboBox.defaultProps = {
    disabled: false,
    required: false,
    parentClassName: "form-group",
    placeholder:'Please Select',
    label:'',
    autoBind:true,
    isVirtualOptions:false,
    noDataTemplate:'NO DATA FOUND'
}
export default WmsComboBox;