import React, { createContext } from "react";

export interface ModalConfig {
    width: number;
    height: number;
    modalTitle: string;
    modalSubtitle: string;
    windowId: string;
}

type CreateHVPContextType = {
    setModalConfig: React.Dispatch<React.SetStateAction<ModalConfig>>;
    modalConfig: ModalConfig;
};

export const CreateHVPContext = createContext<CreateHVPContextType | undefined>(undefined);
