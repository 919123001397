import React from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";
import WmsDropdown from "../../../components/WmsDropdown/WmsDropdown.jsx";
import WmsCheckBox from "../../../components/WmsCheckBox/WmsCheckBox.jsx";
import "./lasership-info.scss";
import * as GlobalService from "./../../../global_services";
import WmsCustomTimePickerProto from "../../../components/WmsCustomTimePickerProto/WmsCustomTimePickerProto.jsx";

interface LasershipInfoComponentProps {
    selector: string;
    handleCarrierObjChange: () => void;
    currentCarrierObj: {
        Nickname: string;
        WebServicesId: string;
        WebServicesKey: string;
        CustomerBranch: string;
        Address1: string;
        Address2: string;
        City: string;
        State: string;
        PostalCode: string;
        Phone: string;
        Email: string;
        CriticalPullTime: string;
        CriticalEntryTime: string;
        AttrAlcohol: boolean;
        AttrDryIce: boolean;
        AttrHazMat: boolean;
        AttrTwoPersons: boolean;
        AttrExplosive: boolean;
        AttrControlledSubstance: boolean;
        AttrRefrigerated: boolean;
        AttrPerishable: boolean;
        AttrNorts: boolean;
    };
}

export function LasershipInfoComponent(props: LasershipInfoComponentProps): JSX.Element {
    const translate = (window as any).translate as any;
    return (
        <>
            <WmsCollapse
                id={"lasershipInfoScreen_account_information"}
                headerLabel={translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id="nickname"
                            inputName="Nickname"
                            wmsSelector={props.selector + "nickname"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Nickname")}
                            value={props.currentCarrierObj.Nickname}
                            required={true}
                            extraWrapperClass="w-50"
                        />
                        <WmsInput
                            id="webservicesid"
                            inputName="WebServicesId"
                            wmsSelector={props.selector + "webservicesid"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Lasership_Web_Services_Id")}
                            value={props.currentCarrierObj.WebServicesId}
                            required={true}
                            extraWrapperClass="w-50"
                        />
                        <WmsInput
                            id="webserviceskey"
                            inputName="WebServicesKey"
                            wmsSelector={props.selector + "webserviceskey"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Lasership_Web_Services_Key")}
                            value={props.currentCarrierObj.WebServicesKey}
                            required={true}
                            extraWrapperClass="w-50"
                        />
                        <WmsInput
                            id="customerbranch"
                            inputName="CustomerBranch"
                            wmsSelector={props.selector + "customerbranch"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Lasership_Customer_Branch")}
                            value={props.currentCarrierObj.CustomerBranch}
                            required={true}
                            extraWrapperClass="w-50"
                        />

                        <WmsInput
                            id="phone"
                            inputName="Phone"
                            wmsSelector={props.selector + "phoneNumber"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Warehouse_PhoneNumber")}
                            extraWrapperClass="w-50"
                            value={props.currentCarrierObj.Phone}
                            required={true}
                            valueType="number"
                        />

                        <WmsInput
                            id="email"
                            inputName="Email"
                            wmsSelector={props.selector + "emailAddress"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Email_Address")}
                            extraWrapperClass="w-50"
                            required={true}
                            value={props.currentCarrierObj.Email}
                        />
                    </div>
                </div>
            </WmsCollapse>
            <WmsCollapse
                id={"lasershipInfoScreen_lasership_company_information"}
                headerLabel={translate("Label_Company_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id="lasership_address"
                            inputName="Address1"
                            wmsSelector={props.selector + "address"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Address_1")}
                            value={props.currentCarrierObj.Address1}
                            required={true}
                            extraWrapperClass="w-50"
                        />
                        <WmsInput
                            id="lasership_address_2"
                            inputName="Address2"
                            wmsSelector={props.selector + "address2"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Address_2")}
                            value={props.currentCarrierObj.Address2}
                            required={false}
                            extraWrapperClass="w-50"
                        />

                        <WmsInput
                            id="city"
                            inputName="City"
                            wmsSelector={props.selector + "city"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Warehouse_City")}
                            extraWrapperClass="w-50"
                            value={props.currentCarrierObj.City}
                            required={true}
                        />

                        <WmsDropdown
                            id="state"
                            name="State"
                            label={translate("Label_Costomer_State_Province")}
                            wmsSelector={props.selector + "state"}
                            onChangeFunc={props.handleCarrierObjChange}
                            value={props.currentCarrierObj.State}
                            options={GlobalService.GetRegions("US")}
                            valueField="RegionCode"
                            textField="Name"
                            parentClassName="form_group"
                            extraWrapperClass="w-50"
                            required={true}
                        />
                        <WmsInput
                            id="postalCode"
                            inputName="PostalCode"
                            wmsSelector={props.selector + "postalCode"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Account_Zip_Postal_code")}
                            extraWrapperClass="w-50"
                            value={props.currentCarrierObj.PostalCode}
                            required={true}
                        />
                        <WmsCustomTimePickerProto
                            id={"criticalPullTime"}
                            name="CriticalPullTime"
                            label={translate("Label_Lasership_CriticalPullTime")}
                            value={props.currentCarrierObj.CriticalPullTime}
                            defaultTime=""
                            onChangeFunc={props.handleCarrierObjChange}
                            wmsSelector={props.selector + "criticalPullTime"}
                            defaultFormat={"HH:mm"}
                            isDateParseInCustomFormat={"HH:mm"}
                            interval={15}
                            required={true}
                        />
                        <WmsCustomTimePickerProto
                            id={"criticalEntryTime"}
                            name="CriticalEntryTime"
                            label={translate("Label_Lasership_CriticalEntryTime")}
                            value={props.currentCarrierObj.CriticalEntryTime}
                            defaultTime=""
                            onChangeFunc={props.handleCarrierObjChange}
                            wmsSelector={props.selector + "criticalEntryTime"}
                            defaultFormat={"HH:mm"}
                            isDateParseInCustomFormat={"HH:mm"}
                            interval={15}
                            required={true}
                        />
                    </div>
                </div>
            </WmsCollapse>
            <WmsCollapse
                id={"lasershipInfoScreen_lasership_attributes_information"}
                headerLabel={translate("Label_Settings")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <WmsCheckBox
                    id={"AttrAlcohol"}
                    name={"AttrAlcohol"}
                    wmsSelector={props.selector + "AttrAlcohol"}
                    onChangeFunc={props.handleCarrierObjChange}
                    label={translate("Label_Lasership_AttrAlcohol")}
                    checked={!!props.currentCarrierObj.AttrAlcohol}
                    isRounded={true}
                    parentClassName="check-box"
                />
                <WmsCheckBox
                    id={"AttrDryIce"}
                    name={"AttrDryIce"}
                    wmsSelector={props.selector + "AttrDryIce"}
                    onChangeFunc={props.handleCarrierObjChange}
                    label={translate("Label_Lasership_AttrDryIce")}
                    checked={!!props.currentCarrierObj.AttrDryIce}
                    isRounded={true}
                    parentClassName="check-box"
                />
                <WmsCheckBox
                    id={"AttrHazMat"}
                    name={"AttrHazMat"}
                    wmsSelector={props.selector + "AttrHazMat"}
                    onChangeFunc={props.handleCarrierObjChange}
                    label={translate("Label_Lasership_AttrHazMat")}
                    checked={!!props.currentCarrierObj.AttrHazMat}
                    isRounded={true}
                    parentClassName="check-box"
                />
                <WmsCheckBox
                    id={"AttrTwoPersons"}
                    name={"AttrTwoPersons"}
                    wmsSelector={props.selector + "AttrTwoPersons"}
                    onChangeFunc={props.handleCarrierObjChange}
                    label={translate("Label_Lasership_AttrTwoPersons")}
                    checked={!!props.currentCarrierObj.AttrTwoPersons}
                    isRounded={true}
                    parentClassName="check-box"
                />
                <WmsCheckBox
                    id={"AttrExplosive"}
                    name={"AttrExplosive"}
                    wmsSelector={props.selector + "AttrExplosive"}
                    onChangeFunc={props.handleCarrierObjChange}
                    label={translate("Label_Lasership_AttrExplosive")}
                    checked={!!props.currentCarrierObj.AttrExplosive}
                    isRounded={true}
                    parentClassName="check-box"
                />
                <WmsCheckBox
                    id={"AttrControlledSubstance"}
                    name={"AttrControlledSubstance"}
                    wmsSelector={props.selector + "AttrControlledSubstance"}
                    onChangeFunc={props.handleCarrierObjChange}
                    label={translate("Label_Lasership_AttrControlledSubstance")}
                    checked={!!props.currentCarrierObj.AttrControlledSubstance}
                    isRounded={true}
                    parentClassName="check-box"
                />
                <WmsCheckBox
                    id={"AttrRefrigerated"}
                    name={"AttrRefrigerated"}
                    wmsSelector={props.selector + "AttrRefrigerated"}
                    onChangeFunc={props.handleCarrierObjChange}
                    label={translate("Label_Lasership_AttrRefrigerated")}
                    checked={!!props.currentCarrierObj.AttrRefrigerated}
                    isRounded={true}
                    parentClassName="check-box"
                />
                <WmsCheckBox
                    id={"AttrPerishable"}
                    name={"AttrPerishable"}
                    wmsSelector={props.selector + "AttrPerishable"}
                    onChangeFunc={props.handleCarrierObjChange}
                    label={translate("Label_Lasership_AttrPerishable")}
                    checked={!!props.currentCarrierObj.AttrPerishable}
                    isRounded={true}
                    parentClassName="check-box"
                />
                <WmsCheckBox
                    id={"AttrNorts"}
                    name={"AttrNorts"}
                    wmsSelector={props.selector + "AttrNorts"}
                    onChangeFunc={props.handleCarrierObjChange}
                    label={translate("Label_Lasership_AttrNorts")}
                    checked={!!props.currentCarrierObj.AttrNorts}
                    isRounded={true}
                    parentClassName="check-box"
                />
            </WmsCollapse>
        </>
    );
}
