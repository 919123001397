
import React from 'react';
import * as GlobalService from '../../global_services';
import style from '../report-iframe.scss';
import { reportIframe } from '../report-iframe.component.jsx';

export class locationsIframeList extends React.Component {
    constructor(props) {

        super(props);
        
        this.$rootScope = window;
        this.translate = window.translate
        this.state = {
            reloadGrid: false,
            sharedUserUri: null,
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            Rights: GlobalService.GetRights(),
        }
    }
    setIframeUrl = (iframeUrl, reportTitle, urlParam) => e => {
        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            id: iframeUrl,
            title: this.translate(reportTitle),
            active: true,
            isIframe: true,
            icon: 'submenu-icon fa fa-plus',
            urlParam: urlParam
        });
    }

    render() {
        return (
            <div className="react_iframebox">
                <ul>
                    <li>
                        <ul>
                            {this.state.Rights.hasOwnProperty('admin.reports.l1') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/LocationIdentifier.aspx", "Label_Location_Identifier_Report","location-identifier")}>{this.translate('Label_Location_Identifier_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l4') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/FindItemReport.aspx","Label_Find_Item_Report","find-item")}>{this.translate('Label_Find_Item_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l4') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/LocationList.aspx","Label_Location_List_Report","location-list")}>{this.translate('Label_Location_List_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l4') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/QuarantineLocation.aspx","Label_Quarantine_Location_Report","quarantine-location")}>{this.translate('Label_Quarantine_Location_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l4') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/ReplenishReport.aspx","Label_Replenishment_Report","replenishment")}>{this.translate('Label_Replenishment_Report')}</a>
                                </li>
                            )}
                        </ul>
                    </li>
                </ul>
            </div>
        )
    }
}
