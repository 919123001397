import React, { useState, useEffect } from "react";
import { default as WmsButton } from "../../../components/WmsButton/WmsButton.jsx";
import { default as WmsCollapse } from "../../../components/WmsCollapse/WmsCollapse.jsx";
import { default as WmsInput } from "../../../components/WmsInput/WmsInput.jsx";
import { default as WmsDropdown } from "../../../components/WmsDropdown/WmsDropdown.jsx";
import { default as WmsCustomDateTimePicker } from "../../../components/WmsCustomDateTimePicker/WmsCustomDateTimePicker.jsx";

import type { GridFilter } from "../grid/small-parcel-hvp-grid_configuration.virtual";
import * as GlobalService from "../../../global_services.js";
import { STATUS_OPTIONS } from "../grid/small-parcel-hvp-grid_configuration.virtual";

interface SmallParcelHVPSearchProps {
    onClickForFilterToggle: () => void;
    defaultFilter?: any;
    onFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    filters: GridFilter[];
    onClearFilters: () => void;
}

export default function SmallParcelHVPSearch({ filters = [], ...props }: SmallParcelHVPSearchProps) {
    /**
     * Globals & Constants
     */
    const translate = (window as any).translate as any;
    const selector = "SmallParcelHVPSearch";

    /**
     * States
     */

    const [isClickSearchFilterToggle, setIsClickSearchFilterToggle] = useState(false);
    const [isMenuExpanded] = useState(true);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);

    /**
     * Functions
     */

    const clickForFilterToggle = () => {
        setIsClickSearchFilterToggle(true);
        props.onClickForFilterToggle();
    };

    const handleFilterChange = (event) => {
        return props.onFilterChange(event);
    };

    const handleGetCustomersList = async () => {
        const customers = await GlobalService.getCustomerList(false);
        customers.unshift({
            "id": 0,
            "name": "All Customers",
            "value": "",
        });
        setCustomerOptions(customers);
        handleFilterChange({
            target: {
                name: "Status",
                value: "",
            },
        } as React.ChangeEvent<HTMLInputElement>);
    };

    const handleGetWarehousesList = async () => {
        const warehouses = await GlobalService.getFacilityList(false);
        warehouses.unshift({
            "id": 0,
            "name": "All Warehouses",
            "value": "",
        });
        setWarehouseOptions(warehouses);
    };

    useEffect(() => {
        handleGetCustomersList();
        handleGetWarehousesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className={
                "small-parcel-search-holder new-layout-filter-wrapper" +
                (isClickSearchFilterToggle ? " sectionToggled" : "")
            }
        >
            <div className="filter" onClick={clickForFilterToggle}>
                <i className="fa fa-caret-left" aria-hidden="true"></i>
                <span>search filters</span>
            </div>
            <div className="small-parcel-search-filter ">
                <div className="wms-toolbar">
                    <WmsButton
                        label={translate("Label_Clear_Filters")}
                        buttonType="orange"
                        wmsSelector={selector + "ClearSearchButton"}
                        onClickFunc={props.onClearFilters}
                        icon="fa-minus"
                    />
                </div>
                <div className="smallparcel-form-boxes-wrapper">
                    <div className={"smallparcel-form-boxes "}>
                        <div className="smallparcel-area-content">
                            <WmsCollapse
                                id={"search_hvp_default_collapse"}
                                headerLabel={translate("Label_Batch_Filter")}
                                showCollapseText={false}
                                isExpanded={isMenuExpanded}
                                showDragIcon={true}
                            >
                                <WmsInput
                                    id="HVPSearchTargetBatchId"
                                    extraWrapperClass="full-width-input"
                                    inputName="BatchId"
                                    wmsSelector={selector + "HVPSearchTargetBatchId"}
                                    value={filters.find((filter) => filter.field === "BatchId")?.value ?? ""}
                                    onChangeFunc={handleFilterChange}
                                    onIconClick={handleFilterChange}
                                    label={"Batch ID"}
                                    showIconAfterInput={false}
                                    icon="fa-search"
                                    isAutoFocus={true}
                                />
                                <WmsInput
                                    id="HVPSearchTargetExternalBatchId"
                                    extraWrapperClass="full-width-input"
                                    inputName="ExternalBatchId"
                                    wmsSelector={selector + "HVPSearchTargetExternalBatchId"}
                                    value={filters.find((filter) => filter.field === "ExternalBatchId")?.value ?? ""}
                                    onChangeFunc={handleFilterChange}
                                    onIconClick={handleFilterChange}
                                    label={"Batch Name"}
                                    showIconAfterInput={false}
                                    icon="fa-search"
                                    isAutoFocus={true}
                                />
                                <WmsDropdown
                                    id="HVPCustomer"
                                    name="Customer"
                                    label={translate("Label_Customer_Text")}
                                    wmsSelector={selector + "customer"}
                                    value={filters.find((filter) => filter.field === "Customer")?.value ?? ""}
                                    onChangeFunc={handleFilterChange}
                                    options={customerOptions}
                                    textField="name"
                                    valueField="value"
                                />
                                <WmsDropdown
                                    id="HVPWarehouse"
                                    name="Facility"
                                    label={translate("Label_Warehouse_Text")}
                                    wmsSelector={selector + "warehouse"}
                                    value={filters.find((filter) => filter.field === "Facility")?.value ?? ""}
                                    onChangeFunc={handleFilterChange}
                                    options={warehouseOptions}
                                    textField="name"
                                    valueField="value"
                                />
                                <WmsDropdown
                                    id="HVPstatus"
                                    name="Status"
                                    label={translate("Label_Filter_Status")}
                                    wmsSelector={selector + "status"}
                                    onChangeFunc={handleFilterChange}
                                    value={filters.find((filter) => filter.field === "Status")?.inputValue ?? "Open"}
                                    options={STATUS_OPTIONS}
                                    textField="name"
                                    valueField="value"
                                />
                                <WmsCustomDateTimePicker
                                    id={"HVPDateRangeStartDate"}
                                    name="startDate"
                                    wmsSelector={selector + "DateRangeStartDate"}
                                    label={translate("Label_Date_Range_Start")}
                                    onChangeFunc={handleFilterChange}
                                    value={filters.find((filter) => filter.field === "startDate")?.value ?? ""}
                                    defaultOptionMonth={"All Time"}
                                    isDateParseInCustomFormat={true}
                                />
                                <WmsCustomDateTimePicker
                                    id={"HVPDateRangeEndDate"}
                                    name="endDate"
                                    wmsSelector={selector + "DateRangeEndDate"}
                                    label={translate("Label_Date_Range_end")}
                                    onChangeFunc={handleFilterChange}
                                    value={filters.find((filter) => filter.field === "endDate")?.value ?? ""}
                                    defaultOptionMonth={"All Time"}
                                    isDateParseInCustomFormat={true}
                                />
                            </WmsCollapse>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
