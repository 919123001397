import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsInput.scss';
class WmsInput extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isHovering: false,
            isSetRequiredBorder: false,
            value: props.value || props.value === 0 ? props.value : ""
        }
        this.regExpArray = {
            'number': /(^[0-9]+$|^$)/,
            'negativeNumber': /(^[-]?[0-9]*[.]?[0-9]*$|^$)/,
            // 'letters': /^[A-z]+$/,
            'letters': /^(?!\s*$).+/,
            'alphanumeric': /^[ A-Za-z0-9_@.]*$/,
            // 'float': /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/
            'regularExp': this.props.regularExp
        };
        this.handleMouseHover = this.handleMouseHover.bind(this);
    }

    componentDidMount() {
        if (this.props.isAutoFocus) {
            setTimeout(() => {
                if(this.refs.myinput){
                this.refs.myinput.focus();
                }
            }, 1000);
        }
    }
    static getDerivedStateFromProps (nextProps, prevState) {
        if(nextProps.zeroBasedCondition === 2 || nextProps.zeroBasedCondition === 3) {
            return {
                value: (nextProps.value !== '' && nextProps.value !== null && nextProps.value !== undefined) ? nextProps.value : ""
            };
        } else {
            return {
                value: nextProps.value || nextProps.value === 0 ? nextProps.value : ""
            };
        }
    }
    onChangeFunc(e) {

        var testValueType = this.props.regularExp == '' ? this.props.valueType : 'regularExp';
        var valueTypeMatch = testValueType != '' ? this.regExpArray[testValueType].test(e.target.value) : true;

        if (e.target.value === '' || valueTypeMatch) {
            this.setState({
                value: e.target.value
            })
            this.props.onChangeFunc(e);
        }
    }
    handleMouseHover = () => {
        this.setState({ isHovering: true });
        this.props.onMouseEnterFunc();
    }
    handleMouseLeave = () => {
        this.setState({ isHovering: false });
        this.props.onMouseLeaveFunc();
    }
    onBlurFunc = (e) => {
        if(this.props.required) {
            var isSetRequiredBorder = false;
            if(!e.target.value) {
                isSetRequiredBorder = true;
            }
            this.setState({ isSetRequiredBorder: isSetRequiredBorder });
        }
        this.props.onBlurFunc(e);
    }
    render() {
        var isRequiredClass = '';
        if(this.props.zeroBasedCondition === 1) {
            isRequiredClass = (!this.props.value && this.props.required && this.state.isSetRequiredBorder) ? "wms_required_input" : '';
        } else if(this.props.zeroBasedCondition === 2) {
            isRequiredClass = (!(this.props.value !== '' && this.props.value !== null && this.props.value !== undefined)  && this.props.required && this.state.isSetRequiredBorder) ? "wms_required_input" : ''
        } else if(this.props.zeroBasedCondition === 3) {
            isRequiredClass = (!(this.props.value !== "0" &&this.props.value !== 0 && this.props.value !== '' && this.props.value !== null && this.props.value !== undefined)  && this.props.required && this.state.isSetRequiredBorder) ? "wms_required_input" : ''
        }
        return (
            <div className={'wms-input-component ' + this.props.extraWrapperClass + (this.props.extraSignBeforeValue != "" ? ' input-extra-sign-component':'')} >
                <div className={this.state.value != "" ? "has_value" + ' ' + this.props.parentClassName : this.props.parentClassName}>
                    <label className="inputlabel" dangerouslySetInnerHTML={{ __html: this.props.label + (this.props.required ? ' *' : '') }} htmlFor={this.props.id}></label>
                    {this.props.extraSignBeforeValue != "" && (
                        <span className="extra-sign">
                            {this.props.extraSignBeforeValue}
                        </span>
                    )}
                    <input
                        type={this.props.inputType}
                        id={this.props.id}
                        name={this.props.inputName}
                        className={isRequiredClass}
                        data-wms-selector={this.props.wmsSelector}
                        placeholder={this.props.placeholder}
                        value={this.state.value}
                        onChange={this.onChangeFunc.bind(this)}
                        onKeyPress={this.props.onKeyPressFunc}
                        onBlur={this.onBlurFunc}
                        maxLength={this.props.maxLength}
                        required={this.props.required}
                        disabled={this.props.disabled}
                        readOnly={this.props.readOnly}
                        ref="myinput"
                        autoComplete={this.props.autoComplete != ""? this.props.autoComplete : "off"}
                        onMouseEnter={this.handleMouseHover}
                        onMouseLeave={this.handleMouseLeave}
                    />
                    {
                        this.props.showToolTip == true &&
                        (<div>
                            {this.state.isHovering == true && <div className="div_tooltip">{this.props.tooltipText}</div>}
                        </div>)
                    }
                    </div>

                {this.props.showIconAfterInput && (
                    <span className="wms-search-input-icon" onClick={this.props.onIconClick}>
                        <i className={"fa " + this.props.icon} aria-hidden="true"></i>
                    </span>
                )}
            </div>
        );
    }

}
WmsInput.propTypes = {
    id: PropTypes.string, // this should write the id to the input
    label: PropTypes.string.isRequired,// this should be the label that default shows and floats up to the top
    readOnly: PropTypes.bool, // default will be false
    disabled: PropTypes.bool, // default will be false
    maxLength: PropTypes.number, // this is the maxLength of the input
    required: PropTypes.bool, // this determines if the input is required and shows in red
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    parentClassName: PropTypes.string, //    this should parent tag class
    extraWrapperClass:PropTypes.string, // extra wrapper class if needed
    valueType: PropTypes.string, // alphanumeric, number, letters
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]), // this should control the value of
    zeroBasedCondition: PropTypes.number, // Allowed values 1,2,3
    // 1 ==> Normal Input Field
    // 2 ==> Allowed Zero
    // 3 ==> Allowed Zero and Required Zero Validation
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
    //theme??: PropTypes.string, // this should control the theme of the input,
    showToolTip: PropTypes.bool, // set tooltip show or not
    tooltipText: PropTypes.string, // show text on hover as tooltip
    extraSignBeforeValue: PropTypes.string, // show extra sign before value like $
    icon: PropTypes.string,
    isAutoFocus: PropTypes.bool,
    autoComplete: PropTypes.string,
    onIconClick: PropTypes.func,
    onBlurFunc: PropTypes.func,
    onKeyPressFunc: PropTypes.func,
    onMouseEnterFunc: PropTypes.func,
    onMouseLeaveFunc: PropTypes.func

}

WmsInput.defaultProps = {
    readOnly: false,
    disabled: false,
    maxLength: 255,
    required: false,
    showToolTip: false,
    showIconAfterInput: false,
    tooltipText: 'add tooltip text',
    extraSignBeforeValue: '',
    valueType: "",
    zeroBasedCondition: 1,
    parentClassName: "form_group",
    extraWrapperClass: "",
    inputType: 'text',
    regularExp: '',
    placeholder: '',
    isAutoFocus: false,
    autoComplete: '',
    onBlurFunc: () => void (0),
    onKeyPressFunc: () => void (0),
    onMouseEnterFunc: () => void (0),
    onMouseLeaveFunc: () => void (0)
}


export default WmsInput;