import React from 'react';

import WmsInput from '../components/WmsInput/WmsInput.jsx';
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsDropdown from '../components/WmsDropdown/WmsDropdown.jsx';
import WmsCheckBox from '../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsModel from '../components/WmsModel/WmsModel.jsx';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse.jsx';
import * as GlobalService from './../global_services';
import style from './item-edit-storage-charge-model.scss';
//services
import * as itemService from './items.services.jsx';


export class ItemEditStorageChargeModel extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.state = {
            selector: "ItemEditStorageCharge",
            storageObj: {},
            savingGif: false,
            defaultUnitOptions: [],
        }
    }
    componentDidMount() {
        this.setState({
            storageObj: Object.assign({}, this.props.sharedSlectedStorageCharge)
        }, () => {
            var defaultCharges = this.props.sharedDefaultStorageHandlingCharges;
            var tmpDefaultUnitOptions = [];
            defaultCharges.filter((elem) => {
                if (elem.FacilityId == this.state.storageObj.FacilityId) {
                    tmpDefaultUnitOptions.push({ "Name": elem.unitOfMeasureName });
                }
            });
            this.setState({
                defaultUnitOptions: tmpDefaultUnitOptions
            })
            if (this.props.sharedSlectedStorageCharge.ratePolicy == 1) {
                this.handleChkChangeFunc(this.props.sharedSlectedStorageCharge.ratePolicy)();
            }
        });
    }
    chekcedIsDefaultStorage = (param_storage_obj) => {

        var defaultCharges = this.props.sharedDefaultStorageHandlingCharges;
        param_storage_obj.fullRate = '';
        param_storage_obj.halfRate = '';
        param_storage_obj.freeDays = '';
        param_storage_obj.receiverHandlingRate = '';
        param_storage_obj.orderHandlingRate = '';

        defaultCharges.filter((elem) => {
            if (
                elem.unitOfMeasureName == param_storage_obj.unitOfMeasureName &&
                elem.FacilityId == param_storage_obj.FacilityId
            ) {
                param_storage_obj.fullRate = elem.fullRate;
                param_storage_obj.halfRate = elem.halfRate;
                param_storage_obj.freeDays = elem.freeDays;
                param_storage_obj.receiverHandlingRate = elem.receiverHandlingRate;
                param_storage_obj.orderHandlingRate = elem.orderHandlingRate;
            }
        });
        return param_storage_obj;

    }

    handleChangeFunc = (e) => {
        var tmpStorageObj = Object.assign({}, this.state.storageObj);

        tmpStorageObj[e.target.name] = e.target.value;
        if (e.target.name == 'unitOfMeasureName' && this.state.storageObj.ratePolicy == 1) {
            tmpStorageObj = this.chekcedIsDefaultStorage(tmpStorageObj)
        }
        this.setState({
            storageObj: tmpStorageObj
        });
    }

    handleChkChangeFunc = (param_rate_policy) => (e) => {
        //param_rate_policy 0 - no charge
        //param_rate_policy 1 - default charge
        //param_rate_policy 2 - custom charge
        let tmpStorageObj = this.state.storageObj;
        if (param_rate_policy == 0) {
            tmpStorageObj.inventoryUnitsPerStorageUnit = 0;

            tmpStorageObj.fullRate = '';
            tmpStorageObj.halfRate = '';
            tmpStorageObj.freeDays = '';
            tmpStorageObj.receiverHandlingRate = '';
            tmpStorageObj.orderHandlingRate = '';

        } else if (param_rate_policy == 1) {
            tmpStorageObj = this.chekcedIsDefaultStorage(tmpStorageObj);
            if (!this.state.storageObj.inventoryUnitsPerStorageUnit) {
                tmpStorageObj.inventoryUnitsPerStorageUnit = this.props.sharedSlectedStorageCharge.inventoryUnitsPerStorageUnit;
            }

        } else if (param_rate_policy == 2) {
            if (!this.state.storageObj.inventoryUnitsPerStorageUnit) {
                tmpStorageObj.inventoryUnitsPerStorageUnit = this.props.sharedSlectedStorageCharge.inventoryUnitsPerStorageUnit;
            }

            // tmpStorageObj.fullRate= 0.00;
            // tmpStorageObj.halfRate= 0.00;
            // tmpStorageObj.freeDays= 0.00;
            // tmpStorageObj.receiverHandlingRate= 0.00;
            // tmpStorageObj.orderHandlingRate= 0.00;
        }
        tmpStorageObj.ratePolicy = param_rate_policy;
        // tmpStorageObj.inventoryUnitsPerStorageUnit = (param_rate_policy == 0) ? 0 : this.props.sharedSlectedStorageCharge.inventoryUnitsPerStorageUnit;

        this.setState({
            storageObj: Object.assign({}, tmpStorageObj)
        });
    }
    onSaveStorageCharge = () => {
        let payLoad = this.state.storageObj;
        delete payLoad.RowNumber;
        delete payLoad.dirty;
        delete payLoad.dirtyFields;
        delete payLoad.parent;
        delete payLoad.uid;
        delete payLoad._events;
        delete payLoad._handlers;

        this.setState({ savingGif: true });
        itemService.storageHandlingCharges(this.props.sharedItemRateUri, payLoad).then((response) => {
            if (response && response.status == 200) {
                GlobalService.notification('success', this.translate('Label_Storage_Handle_Charge_success'));
                this.props.onStorageChargeGridDataChange(response.data.StorageHandlingCharges);
            }
        }).finally(() => {
            this.setState({ savingGif: false });
        });
    }
    clearRates = () => {
        var tmpStorageObj = Object.assign({}, this.state.storageObj);
        tmpStorageObj.fullRate = 0.00;
        tmpStorageObj.halfRate = 0.00;
        tmpStorageObj.freeDays = 0.00;
        tmpStorageObj.receiverHandlingRate = 0.00;
        tmpStorageObj.orderHandlingRate = 0.00;
        this.setState({
            storageObj: tmpStorageObj
        });
    }
    
    checkIsDisabledStorageUnit = () => {
        if (this.state.storageObj.ratePolicy == 1 && this.state.defaultUnitOptions.length >= 1) {
            return false;
        } else if (this.state.storageObj.ratePolicy == 2) {
            return false;
        } else {
            return true;
        }
    }
    render() {
        return (

            <WmsModel
                id={this.props.windowId + 'itemStorageCharge'}
                title={this.translate('Label_Edit_Storage_Model_Label')}
                width={800}
                height={650}
                onCloseFunc={this.props.onClose}
                isOpen={this.props.isOpenStorageChargeModal}
                class="item-storage-charge-wrapper"
                customClass='wms-model-new-ui-wrapper'>
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'storageChargeGeneralinfo'}
                        headerLabel={this.translate('Label_General_information')}
                        showCollapseText={false} >
                        <div className="general-info-wrapper">
                            <WmsInput
                                inputName="warehouse"
                                wmsSelector={this.state.selector + 'SealNumber'}
                                value={this.props.sharedSlectedStorageCharge.FacilityName}
                                disabled={true}
                                onChangeFunc={() => { }}
                                maxLength={255}
                                label={this.translate('Label_Warehouse_Text')} />
                            <div className="chk-wrapper">
                                <WmsCheckBox
                                    id='noChanges'
                                    name='ratesChk'
                                    wmsSelector={this.state.Selector + 'noChanges'}
                                    value={this.state.storageObj.ratePolicy}
                                    onChangeFunc={this.handleChkChangeFunc(0)}
                                    checked={this.state.storageObj.ratePolicy == 0}
                                    label={this.translate('Label_No_Changes')}
                                    isRounded={true} />
                                <WmsCheckBox
                                    id='overrideDefault'
                                    name='ratesChk'
                                    wmsSelector={this.state.Selector + 'overrideDefault'}
                                    value={this.state.storageObj.ratePolicy}
                                    onChangeFunc={this.handleChkChangeFunc(1)}
                                    checked={this.state.storageObj.ratePolicy == 1}
                                    label={this.translate('Label_Default_Rates')}
                                    isRounded={true}
                                    showToolTip={true}
                                    tooltipText={this.translate('Label_Warehouse_Default_Rates_Note')}
                                />
                                <WmsCheckBox
                                    id='customRates'
                                    name='ratesChk'
                                    wmsSelector={this.state.Selector + 'customRates'}
                                    value={this.state.storageObj.ratePolicy}
                                    onChangeFunc={this.handleChkChangeFunc(2)}
                                    checked={this.state.storageObj.ratePolicy == 2}
                                    label={this.translate('Label_Custom_Rates')}
                                    isRounded={true} />
                            </div>
                        </div>
                        <WmsDropdown
                            id="unitOfMeasureName"
                            name="unitOfMeasureName"
                            label={this.translate('Label_Storage_handling_Unit')}
                            wmsSelector={this.state.Selector + "CustomerDropdown"}
                            value={this.state.storageObj.unitOfMeasureName}
                            onChangeFunc={this.handleChangeFunc}
                            options={
                                (this.state.storageObj.ratePolicy == 1) ?
                                    this.state.defaultUnitOptions :
                                    this.props.sharedEditItemOptions.TypeOfUnitOptions
                            }
                            valueField="Name"
                            textField="Name"
                            blankFirstOption={true}
                            blankFirstOptionText={this.translate('Label_Select_Option')}
                            extraWrapperClass={this.checkIsDisabledStorageUnit() == true ? 'disable-section' : ""}
                            required={this.checkIsDisabledStorageUnit() == false}
                        />
                        <WmsInput
                            inputName="inventoryUnitsPerStorageUnit"
                            wmsSelector={this.state.selector + 'SealNumber'}
                            value={this.state.storageObj.inventoryUnitsPerStorageUnit}
                            onChangeFunc={this.handleChangeFunc}
                            maxLength={255}
                            label={this.translate('Label_Notification_NumberOfUnits')}
                            extraWrapperClass={(this.checkIsDisabledStorageUnit() == true ? 'disable-section' : "") + " number-input-wrapper"}
                            required={this.checkIsDisabledStorageUnit() == false}
                            regularExp={/^[+-]?([0-9]+([.]?)(\d?\d?\d?\d)?)$/}
                            zeroBasedCondition={3} />
                    </WmsCollapse>
                    <div className={this.checkIsDisabledStorageUnit() == true ? 'disable-section' : ""}>
                        <WmsCollapse
                            id={'storageReceiveTime'}
                            headerLabel={this.translate('Label_Storage_receive_Time')}
                            showCollapseText={false} >
                            <WmsInput
                                inputName="fullRate"
                                wmsSelector={this.state.selector + 'fullRate'}
                                value={
                                    (this.state.storageObj.fullRate != null) ?
                                        (
                                            (this.state.storageObj.ratePolicy == 1) ?
                                                this.state.storageObj.fullRate + ' (default)' :
                                                this.state.storageObj.fullRate
                                        ) : ''
                                }
                                onChangeFunc={this.handleChangeFunc}
                                maxLength={255}
                                label={this.translate('Label_Split_Period_Full_Rate')}
                                disabled={this.state.storageObj.ratePolicy == 1}
                                regularExp={/^[+-]?([0-9]+([.]?)(\d?\d?\d?\d)?)$/}
                                required={this.state.storageObj.ratePolicy == 2}
                                zeroBasedCondition={2} />
                            <WmsInput
                                inputName="halfRate"
                                wmsSelector={this.state.selector + 'halfRate'}
                                value={
                                    (this.state.storageObj.halfRate != null) ?
                                        (
                                            (this.state.storageObj.ratePolicy == 1) ?
                                                this.state.storageObj.halfRate + ' (default)' :
                                                this.state.storageObj.halfRate
                                        ) : ''
                                }
                                onChangeFunc={this.handleChangeFunc}
                                maxLength={255}
                                label={this.translate('Label_Split_Period_Half_Rate')}
                                disabled={this.state.storageObj.ratePolicy == 1}
                                regularExp={/^[+-]?([0-9]+([.]?)(\d?\d?\d?\d)?)$/}
                                required={this.state.storageObj.ratePolicy == 2}
                                zeroBasedCondition={2} />
                            <WmsInput
                                inputName="freeDays"
                                wmsSelector={this.state.selector + 'freeDays'}
                                value={
                                    (this.state.storageObj.freeDays != null) ?
                                        (
                                            (this.state.storageObj.ratePolicy == 1) ?
                                                this.state.storageObj.freeDays + ' (default)' :
                                                this.state.storageObj.freeDays
                                        ) : ''
                                }
                                onChangeFunc={this.handleChangeFunc}
                                maxLength={255}
                                label={this.translate('Label_Free_Days')}
                                disabled={this.state.storageObj.ratePolicy == 1}
                                valueType="number"
                                required={this.state.storageObj.ratePolicy == 2}
                                zeroBasedCondition={2} />
                        </WmsCollapse>
                        <WmsCollapse
                            id={'storageConfirmTime'}
                            headerLabel={this.translate('Label_Handling_Confirm_Time')}
                            showCollapseText={false} >

                            <WmsInput
                                inputName="receiverHandlingRate"
                                wmsSelector={this.state.selector + 'receiverHandlingRate'}
                                value={
                                    (this.state.storageObj.receiverHandlingRate != null) ?
                                        (
                                            (this.state.storageObj.ratePolicy == 1) ?
                                                this.state.storageObj.receiverHandlingRate + ' (default)' :
                                                this.state.storageObj.receiverHandlingRate
                                        ) : ''
                                }
                                onChangeFunc={this.handleChangeFunc}
                                maxLength={255}
                                label={this.translate('Label_Receiver_Handling_Rate')}
                                disabled={this.state.storageObj.ratePolicy == 1}
                                regularExp={/^[+-]?([0-9]+([.]?)(\d?\d?\d?\d)?)$/}
                                required={this.state.storageObj.ratePolicy == 2}
                                zeroBasedCondition={2} />
                            <WmsInput
                                inputName="orderHandlingRate"
                                wmsSelector={this.state.selector + 'orderHandlingRate'}
                                value={
                                    (this.state.storageObj.orderHandlingRate != null) ?
                                        (
                                            (this.state.storageObj.ratePolicy == 1) ?
                                                this.state.storageObj.orderHandlingRate + ' (default)' :
                                                this.state.storageObj.orderHandlingRate
                                        ) : ''
                                }
                                onChangeFunc={this.handleChangeFunc}
                                maxLength={255}
                                label={this.translate('Label_Shipping_Handling_Rate')}
                                disabled={this.state.storageObj.ratePolicy == 1}
                                regularExp={/^[+-]?([0-9]+([.]?)(\d?\d?\d?\d)?)$/}
                                required={this.state.storageObj.ratePolicy == 2}
                                zeroBasedCondition={2} />
                        </WmsCollapse>
                    </div>
                </div>
                <div className="footer-btn-holder">
                    <div className="wmsi-button-holder">
                        <WmsButton
                            id='storageClearRates'
                            label={this.translate('Label_Clear_Rates')}
                            buttonType='red'
                            wmsSelector={this.state.selector + 'clearRates'}
                            onClickFunc={this.clearRates}
                            icon='fa-minus'
                            disabled={this.state.storageObj.ratePolicy != 2} />
                        <WmsButton
                            id='storageCancel'
                            label={this.translate('Label_Cancel')}
                            buttonType='yellow'
                            wmsSelector={this.state.selector + 'cancel'}
                            onClickFunc={this.props.onClose}
                            icon="fa-ban" />
                        <WmsButton
                            id='storageSave'
                            label={this.translate('Label_Save')}
                            wmsSelector={this.state.selector + 'save'}
                            onClickFunc={this.onSaveStorageCharge}
                            loading={this.state.savingGif}
                            disabled={
                                (
                                    this.state.storageObj.ratePolicy != 0 &&
                                    (
                                        !this.state.storageObj.unitOfMeasureName ||
                                        !this.state.storageObj.inventoryUnitsPerStorageUnit ||
                                        this.state.storageObj.inventoryUnitsPerStorageUnit === 0 ||
                                        this.state.storageObj.inventoryUnitsPerStorageUnit === "0" ||
                                        (this.state.storageObj.fullRate === null) ||
                                        (this.state.storageObj.fullRate === '') ||
                                        (this.state.storageObj.halfRate === null) ||
                                        (this.state.storageObj.halfRate === '') ||
                                        (this.state.storageObj.freeDays === null) ||
                                        (this.state.storageObj.freeDays === '') ||
                                        (this.state.storageObj.receiverHandlingRate === null) ||
                                        (this.state.storageObj.receiverHandlingRate === '') ||
                                        (this.state.storageObj.orderHandlingRate === null) ||
                                        (this.state.storageObj.orderHandlingRate === '')
                                    )
                                )
                            }
                            icon='fa-floppy-o' />
                    </div>
                </div>
            </WmsModel>
        )
    }
}