
import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { ArrayWms } from '../../../assets/ts/ArrayWms.ts';

//component
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsTab from '../components/WmsTab/WmsTab.jsx';
import { LocationsFormSetup } from './locations-form-setup.component.jsx';
import { ManageReceiptHolder } from "../receipt/manageReceipt/manageReceipt-holder.component.jsx";

import * as locationService from './locations.services.jsx';
import * as GlobalService from './../global_services';

export class LocationsForm extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window;
        this.Selector = "Location";
        this.scrollAreaWrapperId = "ManageLocationScrollBox";

        this.scrollDomSelector = props.windowId ? '.' + props.windowId + '#' + this.scrollAreaWrapperId : '#' + this.scrollAreaWrapperId;

        this.tabHeaderRef = React.createRef();
        this.basicsInformationRef = React.createRef();
        this.capabilitiesRef = React.createRef();
        this.prioritiesRef = React.createRef();
        this.billingRef = React.createRef();
        this.directedPutAwayRef = React.createRef();
        this.sectionRefs = [
            {
                section: "LocationBasicsInformation",
                ref: this.basicsInformationRef,
                label: this.translate('Label_Location_Basic_information'),
                wmsSelector: this.Selector + "LocationBasicsInformation",
                isTabShow: true
            },
            {
                section: "LocationCapabilities",
                ref: this.capabilitiesRef,
                label: this.translate('Label_Location_Capabilities'),
                wmsSelector: this.Selector + "LocationCapabilities",
                isTabShow: true
            },
            {
                section: "LocationPriorities",
                ref: this.prioritiesRef,
                label: this.translate('Label_Location_Priorities'),
                wmsSelector: this.Selector + "LocationPriorities",
                isTabShow: true
            },
            {
                section: "LocationBilling",
                ref: this.billingRef,
                label: this.translate('Label_Location_Billing'),
                wmsSelector: this.Selector + "LocationBilling",
                isTabShow: true
            },
            {
                section: "LocationDirectedPutaway",
                ref: this.directedPutAwayRef,
                label: this.translate('Label_Directed_Putaway'),
                wmsSelector: this.Selector + "LocationDirectedPutaway",
                isTabShow: true
            }
        ];

        this.state = {
            showDiscard: false,
            saveLoader: false,
            Selector: this.Selector,
            sharedLocation: {},
            defaultLocationObj: {
                Active: true,
                Field1: '',
                Field2: '',
                Field3: '',
                Field4: '',
                Description: null,
                LocationTypeId: 1,
                Width: '',
                Length: '',
                Height: '',
                QuantityMax: '',
                WeightAllowed: '',
                TemperatureMinimum: '',
                QuantityMinimum: '',
                BillingTypeId: null,
                Length: '',
                PercentFill: '',
                NumberPalletsAllowed: null,
                StoreOnlyPallets: false,
                Zones: []
            }
        }
    }

    componentDidMount() {
        this.getLocationData(this.props.sharedLocationEditUri);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.sharedLocationEditUri != this.props.sharedLocationEditUri) {
            this.getLocationData(nextProps.sharedLocationEditUri);
        }
    }

    getLocationData(param_edituri) {

        this.setState({
            sharedLocation: this.state.defaultLocationObj,
            showDiscard: false
        });

        if (param_edituri != '') {

            kendo.ui.progress($('#form_block'), true);
            locationService.getLocationEditData(param_edituri).then((response) => {
                response.data.Zones = ArrayWms.convertStringToArray(response.data.Zones, 'Name');
                if (response.data.ReplenishmentEnabled == false) {
                    response.data.QuantityMax = null
                }
                this.setState({
                    sharedLocation: response.data,
                    showDiscard: false
                });

            }).finally(() => {
                kendo.ui.progress($('#form_block'), false);
            });
        }
    }

    onLocationDataset = (param_locationData) => {
        this.setState({
            sharedLocation: param_locationData,
            showDiscard: true
        });
    }

    onClickExitLocationForm = () => {
        this.props.onExitLocationForm();
    }

    onClickSaveLocation = () => {
        if(this.state.sharedLocation && parseInt(this.state.sharedLocation.PickPath) == 0){
            GlobalService.notification('error', this.translate('Label_location_pickpath_zero_error'));
            return false;
        }

        this.setState({ saveLoader: true });

        let locationObj = Object.assign({}, this.state.sharedLocation);
        locationObj['Zones'] = StringWms.convertArrayOptionToString(locationObj['Zones']);
        if (this.props.sharedLocationEditUri == '') {

            locationService.saveNewLocation({
                "facilityId": this.props.selectedFacilityId,
                "location": locationObj
            }).then((response) => {

                this.setState({ saveLoader: false });
                if (typeof response != 'undefined' && response.status == 200) {
                    GlobalService.notification('success', this.translate('Label_location_create_success'));
                    this.props.onReloadGrid('reloadLocationGrid');
                    this.onClickExitLocationForm();

                    if (this.props.receiptObj) {
                        this.openManageReceiptWindow();
                    }
                }

            }).finally(() => {
                this.setState({ saveLoader: false });
            });

        } else {
            if (!locationObj.AllocationPriority) {
                delete locationObj.AllocationPriority;
            }
            locationObj['Name'] = locationObj['Field1'] + '-' + locationObj['Field2'] + '-' + locationObj['Field3'] + '-' + locationObj['Field4'];
            locationObj['Name'] = locationObj['Name'].replace(/^-+|-+$/gm, '');
            locationService.updateLocation(locationObj).then((response) => {
                this.setState({ saveLoader: false });
                if (typeof response != 'undefined' && response.status == 200) {
                    GlobalService.notification('success', this.translate('Label_location_update_success'));
                    this.props.onReloadGrid('reloadLocationGrid');
                    this.onClickExitLocationForm();

                    if (this.props.receiptObj) {
                        this.openManageReceiptWindow();
                    }
                }

            }).finally(() => {
                this.setState({ saveLoader: false });
            });
        }

    }

    openManageReceiptWindow = () => {
        this.$rootScope.OpenWindow({
            url: '<react-manage-receipt-holder></react-manage-receipt-holder>',
            reactComponent: ManageReceiptHolder,
            singleton: false,
            id: this.props.receiptObj.ReceiverId,
            title: this.translate('Label_Edit_Receipts'),
            active: true,
            useLineItem: true,
            lineItem: [{
                name: 'Ref: ',
                value: this.props.receiptObj.ReferenceNum,
            }, {
                name: 'Trans: ',
                value: this.props.receiptObj.receiverIds,
            }],
            icon: 'submenu-icon fa fa-edit',
            close: {
                'Title': this.translate('Label_Warning'),
                'Button1Title': this.translate('Label_Save_And_Exit'),
                'Button1Icon': 'save',
                'Button1Show': true,
                'Button2Title': this.translate('Label_Discard_And_Exit'),
                'Button2Icon': 'newCancel',
                'Button2Show': true,
                'Button3Title': this.translate('Label_Return'),
                'Button3Icon': 'return',
                'Button3Show': true,
                'Paragraph': 'Changes have been made, click \'Back\' to save your changes.'
            },
            receiptObj: this.props.receiptObj,
            flow: 'edit',
        });
    }


    render() {
        return (
            <div className="location-form" id="form_block" >
                <div className="loc-form-header" ref={this.tabHeaderRef}>
                    <WmsTab sectionRefs={this.sectionRefs} scrollAreaSelector={this.scrollDomSelector} tabHeaderRef={this.tabHeaderRef} windowId={this.props.windowId} />
                    <div className="form-button-group">
                        <WmsButton
                            label={this.translate('Label_Save')}
                            wmsSelector={this.state.Selector + 'SaveLocationButton'}
                            onClickFunc={this.onClickSaveLocation}
                            icon='fa-floppy-o'
                            showToolTip={true}
                            tooltipText={this.translate('Tooltip_Required_Fields')}
                            disabled={this.state.sharedLocation.Field1 == ''
                                || this.state.sharedLocation.AllocationPriority == 0
                                || this.state.saveLoader}
                            loading={this.state.saveLoader} />

                        {this.state.showDiscard == false && (
                            <WmsButton
                                label={this.translate('Label_Exit')}
                                wmsSelector={this.state.Selector + 'ButtonExit'}
                                onClickFunc={this.onClickExitLocationForm}
                                icon='fa-sign-out'
                                buttonType="yellow" />
                        )}

                        {this.state.showDiscard == true && (
                            <WmsButton
                                label={this.translate('Label_Discard_Exit')}
                                wmsSelector={this.state.Selector + 'ButtonDiscard'}
                                onClickFunc={this.onClickExitLocationForm}
                                icon='fa-times-circle'
                                buttonType="yellow" />
                        )}
                    </div>
                </div>
                <div id="ManageLocationScrollBox" className={this.props.windowId + " tab-content"}>
                    <LocationsFormSetup
                        sharedLocation={this.state.sharedLocation}
                        onLocationDataset={this.onLocationDataset}
                        sharedLocationOptions={this.props.sharedLocationOptions}
                        windowId={this.props.windowId}
                        basicsInformationRef={this.basicsInformationRef}
                        capabilitiesRef={this.capabilitiesRef}
                        prioritiesRef={this.prioritiesRef}
                        billingRef={this.billingRef}
                        directedPutAwayRef={this.directedPutAwayRef}
                        selectedFacilityId={this.props.selectedFacilityId}
                    />
                </div>
            </div>
        )
    }
}