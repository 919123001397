import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsContainer.scss';

class WmsContainer extends React.PureComponent {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if(this.props.isShawHeaderInfoIcon){
            setTimeout(() => {
                $("#"+this.props.id+"_headerinfo").kendoTooltip({
                    width: 240,
                    showAfter: 500,
                    position: "bottom",
                    content: (e) => {
                        return '<p class="model-tooltip-title-p">'+this.props.headerInfoTooltipText+'</p>';
                    },
                    animation: {
                        open: {
                            effects: "zoom",
                            duration: 150
                        }
                    }
                }).data("kendoTooltip");
            }, 100);
        }
    }

    render() {

        return (
            <div className={"wms-container-wrapper " + this.props.className} id={this.props.id}>
                <div className="wms-area-content">
                    {this.props.title != '' &&
                        <div className="title">
                            <label data-wms-selector={this.props.wmsSelector}>{this.props.title}</label>
                        </div>
                    }
                    {this.props.isShawHeaderInfoIcon && (
                        <span className="fa fa-info-circle" id={this.props.id + '_headerinfo'} aria-hidden="true"></span>
                    )}
                    {this.props.showWarningText != '' && (
                        <label className="wms-warning-label">
                            <span>{this.props.areYouSureTitle}: </span>
                            {this.props.showWarningText}
                        </label>
                    )}
                    <div className="wms-area-content-darker">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

WmsContainer.propTypes = {
    title: PropTypes.string, // this should write the title on conatiner default blank
    showWarningText: PropTypes.string, // this should set warning label after title
    areYouSureTitle: PropTypes.string, // this should set warning label after title
    className: PropTypes.string, // this should set warning label after title
    id: PropTypes.string, // this should set warning label after title
    wmsSelector: PropTypes.string, // this should set up a selector on the input
    isShawHeaderInfoIcon: PropTypes.bool
}

WmsContainer.defaultProps = {
    title: '',
    showWarningText: '',
    areYouSureTitle: '',
    className: '',
    id: '',
    isShawHeaderInfoIcon: false,
    headerInfoTooltipText: '',
}

export default WmsContainer;