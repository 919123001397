import React, { useState} from 'react';
import * as ConfigurePackingSlipsService from './configure-packing-slips.services';
import * as GlobalService from '../../global_services';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsModel from '../../components/WmsModel/WmsModel.jsx';
import WmsRadio from '../../components/WmsRadio/WmsRadio';
import WmsCheckBox from '../../components/WmsCheckBox/WmsCheckBox.jsx';
import PropTypes from 'prop-types';

import './configurePackagingSlips.scss';
declare var window: any;
const { $ } = window;
interface PackingSlipsConfigurationProps {
    sharedIsOpenPackingSlipsModal: boolean,
    onClosePackingSlipsModal: () => {},
    refreshGrid: () => {},
    afterDelete: () => {},
    selectedRow: any
}


const PackingSlipsConfigurationModal: React.FC<PackingSlipsConfigurationProps> = (props: PackingSlipsConfigurationProps): any => {
    const [packingSlipsFormData, setPackingSlipsFormData] = useState(props.selectedRow);
    const selector = "PackingSlipsConfiguration";
    const handleChangeEvent = (event: any) => {

        const { name, value } = event.target;
        var finalValue = value
        if(name === "ShowShippingNotes"){
            finalValue = event.target.checked;
        }
        setPackingSlipsFormData((prevState: any) => ({ ...prevState, [name]: finalValue }));
    }
    const handleChangeRadio = (name: any, value: any) => {
        setPackingSlipsFormData((prevState: any) => ({ ...prevState, [name]: value }));
    }
    const showShaveActive = () => {
        return packingSlipsFormData.Description.trim() === "" 
                || packingSlipsFormData.Address1Source === -1
                || packingSlipsFormData.Address2Source === -1
                || (packingSlipsFormData.Address1Source === 3 && (
                    !(packingSlipsFormData.Address1SECompanyName) || packingSlipsFormData.Address1SECompanyName.trim() === ''
                    || !(packingSlipsFormData.Address1SEAddress1) || packingSlipsFormData.Address1SEAddress1.trim() === ''
                    || !(packingSlipsFormData.Address1SECity) || packingSlipsFormData.Address1SECity.trim() === ''
                    || !(packingSlipsFormData.Address1SEZip) || packingSlipsFormData.Address1SEZip.trim() === ''
                    || !(packingSlipsFormData.Address1SECountry) || packingSlipsFormData.Address1SECountry.trim() === ''
                    ))
                || (packingSlipsFormData.Address2Source === 3 &&
                    ( !(packingSlipsFormData.Address2SECompanyName) || packingSlipsFormData.Address2SECompanyName.trim() === '' 
                    || !(packingSlipsFormData.Address2SEAddress1) || packingSlipsFormData.Address2SEAddress1.trim() === '' 
                    || !(packingSlipsFormData.Address2SECity) || packingSlipsFormData.Address2SECity.trim() === '' 
                    || !(packingSlipsFormData.Address2SEZip) || packingSlipsFormData.Address2SEZip.trim() === '' 
                    || !(packingSlipsFormData.Address2SECountry) || packingSlipsFormData.Address2SECountry.trim() === ''));
    }
    const deletePackingSlipConfiguration = () => {
        kendo.ui.progress($('#PackingSlipsConfiguration'), true);
        ConfigurePackingSlipsService.deleteConfigurePackingSlipsData(packingSlipsFormData.PackingListTemplateID).then((response: any) => {
            props.onClosePackingSlipsModal();
            props.afterDelete();
            GlobalService.notification('success', window.translate('Label_Configure_Packing_Slips_delete_success'));
          }).finally(() => {
            kendo.ui.progress($('#PackingSlipsConfiguration'), false);
          });

    }
    const afterSave = () => {
        props.onClosePackingSlipsModal();
        props.refreshGrid();
        GlobalService.notification('success', window.translate('Label_Configure_Packing_Slips_save_success'));
    }
    const savePackingSlipConfiguration = () => {
        kendo.ui.progress($('#PackingSlipsConfiguration'), true);
        var formData = {
            data: {
                ShowShippingNotes: packingSlipsFormData.ShowShippingNotes,
                Description: packingSlipsFormData.Description,
                Address1Label: packingSlipsFormData.Address1Label,
                Address1Source: packingSlipsFormData.Address1Source,
                Address1CustomerContactType: 0,
                Address1SEName: packingSlipsFormData.Address1SEName,
                Address1SECompanyName: packingSlipsFormData.Address1SECompanyName,
                Address1SEAddress1: packingSlipsFormData.Address1SEAddress1,
                Address1SEAddress2: packingSlipsFormData.Address1SEAddress2,
                Address1SECity: packingSlipsFormData.Address1SECity,
                Address1SEState: packingSlipsFormData.Address1SEState,
                Address1SEZip: packingSlipsFormData.Address1SEZip,
                Address1SECountry: packingSlipsFormData.Address1SECountry,
                Address1SEDept: packingSlipsFormData.Address1SEDept,

                Address2Label: packingSlipsFormData.Address2Label,
                Address2Source: packingSlipsFormData.Address2Source,
                Address2CustomerContactType: 0,
                Address2SEName: packingSlipsFormData.Address2SEName,
                Address2SECompanyName: packingSlipsFormData.Address2SECompanyName,
                Address2SEAddress1: packingSlipsFormData.Address2SEAddress1,
                Address2SEAddress2: packingSlipsFormData.Address2SEAddress2,
                Address2SECity: packingSlipsFormData.Address2SECity,
                Address2SEState: packingSlipsFormData.Address2SEState,
                Address2SEZip: packingSlipsFormData.Address2SEZip,
                Address2SECountry: packingSlipsFormData.Address2SECountry,
                Address2SEDept: packingSlipsFormData.Address2SEDept,
            }
        }

        if(packingSlipsFormData.PackingListTemplateID !== undefined){
            formData.data['PackingListTemplateID'] = packingSlipsFormData.PackingListTemplateID;
            formData.data['Uri'] = packingSlipsFormData.Uri;

            ConfigurePackingSlipsService.updateConfigurePackingSlipsData(formData).then((res: any) => {
                afterSave();
              }).finally(() => {
                kendo.ui.progress($('#PackingSlipsConfiguration'), false);
              });

        }else{
            ConfigurePackingSlipsService.saveConfigurePackingSlipsData(formData).then((response: any) => {
                afterSave();
              }).finally(() => {
                kendo.ui.progress($('#PackingSlipsConfiguration'), false);
              });
        }
    }

    return (
        <WmsModel
                id='PackingSlipsConfiguration'
                title={packingSlipsFormData.PackingListTemplateID !== undefined ?  window.translate('Label_Edit_Packing_Slip_Configuration') : window.translate('Label_Create_Packing_Slip_Configuration')}
                width={800}
                height={800}
                onCloseFunc={props.onClosePackingSlipsModal}
                isOpen={props.sharedIsOpenPackingSlipsModal}
                customClass='wms-model-new-ui-wrapper' >
            <div className='model-content-wrapper'>
                <WmsCollapse id={'packingSlipsDetail'} headerLabel={window.translate('Label_Packing_Slip_Details')} showCollapseText={false}>
                    <div className="display-inline">
                        <WmsInput
                            id="packingSlipsDescription"
                            inputName='Description'
                            wmsSelector={selector + 'Description'}
                            onChangeFunc={handleChangeEvent}
                            label={window.translate('Label_Description')}
                            value={packingSlipsFormData.Description}
                            extraWrapperClass="packing-slip-Description"
                            required={true}
                        />

                        <WmsCheckBox
                            id={'packingSlipsShowShippingNotes'}
                            name={'ShowShippingNotes'}
                            onChangeFunc={handleChangeEvent}
                            label={window.translate('Label_Include_Carrier_Instructions')}
                            checked={packingSlipsFormData.ShowShippingNotes}
                            isRounded={true}
                            wmsSelector={selector + 'ShowShippingNotes'} />
                    </div>
                </WmsCollapse>
                <WmsCollapse id={'packingSlipsAddress1Config'} headerLabel={window.translate('Label_ADDRESS_1_CONFIGURATION')} showCollapseText={false}>
                    <WmsInput
                        id="Address1Label"
                        inputName='Address1Label'
                        wmsSelector={selector + 'Label'}
                        onChangeFunc={handleChangeEvent}
                        label={window.translate('Label')}
                        value={packingSlipsFormData.Address1Label}
                        extraWrapperClass="packing-slip-label"
                    />
                    <label className='data-source-lbl'>{window.translate('Label_Data_Source')} *</label>
                    <div className="display-inline weight-uom-section">

                        <div className="wms-details-uom-section">
                            <WmsRadio
                                id="Address1SourceShipTo"
                                name="Address1Source"
                                label={window.translate('Label_Filter_Ship_To')}
                                wmsSelector={selector + 'ShipTo'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 0)}
                                checked={packingSlipsFormData.Address1Source === 0}
                            />

                            <WmsRadio
                                id="Address1SourceBillTo"
                                name="Address1Source"
                                label={window.translate('Label_Bill_To')}
                                wmsSelector={selector + 'BillTo'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 4)}
                                checked={packingSlipsFormData.Address1Source === 4}
                            />
                            <WmsRadio
                                id="Address1SourceSoldTo"
                                name="Address1Source"
                                label={window.translate('Label_Sold_To')}
                                wmsSelector={selector + 'SoldTo'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 5)}
                                checked={packingSlipsFormData.Address1Source === 5}
                            />

                            <WmsRadio
                                id="Address1SourceCustomerContact"
                                name="Address1Source"
                                label={window.translate('Label_Customer_Contact')}
                                wmsSelector={selector + 'CustomerContact'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 2)}
                                checked={packingSlipsFormData.Address1Source === 2}
                            />
                        </div>
                        <div className="wms-details-uom-section">
                            <WmsRadio
                                id="Address1SourceCustomerFields"
                                name="Address1Source"
                                label={window.translate('Label_custom_fields')}
                                wmsSelector={selector + 'CustomFields'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 3)}
                                checked={packingSlipsFormData.Address1Source === 3}
                            />

                            <WmsRadio
                                id="Address1SourceWarehouse"
                                name="Address1Source"
                                label={window.translate('Label_Warehouse_Text')}
                                wmsSelector={selector + 'Warehouse'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 1)}
                                checked={packingSlipsFormData.Address1Source === 1}
                            />
                        </div>

                    </div>
                    {packingSlipsFormData.Address1Source === 3 ? (
                    <div className="custom-field-extra-fields-area">
                        <div className="custom-fields-info">
                            <i className="fa fa-info-circle wms-icon" aria-hidden="true"></i>
                            {window.translate('Label_Configure_Packing_Slips_custom_field_info')}
                        </div>
                        <div>
                            <WmsInput
                                id="Address1SECompanyName"
                                inputName='Address1SECompanyName'
                                wmsSelector={selector + 'Address1SECompanyName'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Company_Name_Data_Source')}
                                value={packingSlipsFormData.Address1SECompanyName}
                                required={true}
                            />
                            <WmsInput
                                id="Address1SEName"
                                inputName='Address1SEName'
                                wmsSelector={selector + 'Address1SEName'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Name_Data_Source')}
                                value={packingSlipsFormData.Address1SEName}
                            />
                            <WmsInput
                                id="Address1SEDept"
                                inputName='Address1SEDept'
                                wmsSelector={selector + 'Address1SEDept'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Department_Data_Source')}
                                value={packingSlipsFormData.Address1SEDept}
                            />
                        </div>
                        <div>
                           
                        <WmsInput
                                id="Address1SEAddress1"
                                inputName='Address1SEAddress1'
                                wmsSelector={selector + 'Address1SEAddress1'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Street1_Data_Source')}
                                value={packingSlipsFormData.Address1SEAddress1}
                                required={true}
                            />
                            <WmsInput
                                id="Address1SEAddress2"
                                inputName='Address1SEAddress2'
                                wmsSelector={selector + 'Address1SEAddress2'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Street2_Data_Source')}
                                value={packingSlipsFormData.Address1SEAddress2}
                            />
                            <WmsInput
                                id="Address1SECity"
                                inputName='Address1SECity'
                                wmsSelector={selector + 'Address1SECity'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_City_Data_Source')}
                                value={packingSlipsFormData.Address1SECity}
                                required={true}
                            />                            
                        </div>
                        <div>
                            <WmsInput
                                id="Address1SEState"
                                inputName='Address1SEState'
                                wmsSelector={selector + 'Address1SEState'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_State_Data_Source')}
                                value={packingSlipsFormData.Address1SEState}
                            />
                            
                            <WmsInput
                                id="Address1SEZip"
                                inputName='Address1SEZip'
                                wmsSelector={selector + 'Address1SEZip'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Zip_Data_Source')}
                                value={packingSlipsFormData.Address1SEZip}
                                required={true}
                            />

                            <WmsInput
                                id="Address1SECountry"
                                inputName='Address1SECountry'
                                wmsSelector={selector + 'Address1SECountry'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Country_Data_Source')}
                                value={packingSlipsFormData.Address1SECountry}
                                required={true}
                            />
                        </div>
                    </div>
                    ) : null}
                </WmsCollapse>

                <WmsCollapse id={'packingSlipsAddress2Config'} headerLabel={window.translate('Label_PACKAGING_SLIP_ADDRESS_2_CONFIGURATION')} showCollapseText={false}>
                    <WmsInput
                        id="Address2Label"
                        inputName='Address2Label'
                        wmsSelector={selector + 'Address2Label'}
                        onChangeFunc={handleChangeEvent}
                        label={window.translate('Label')}
                        value={packingSlipsFormData.Address2Label}
                        extraWrapperClass="packing-slip-label"
                    />
                    <label className='data-source-lbl'>{window.translate('Label_Data_Source')} *</label>
                    <div className="display-inline weight-uom-section">

                        <div className="wms-details-uom-section">
                            <WmsRadio
                                id="Address2SourceShipTo"
                                name="Address2Source"
                                label={window.translate('Label_Filter_Ship_To')}
                                wmsSelector={selector + 'ShipTo'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 0)}
                                checked={packingSlipsFormData.Address2Source === 0}
                            />

                            <WmsRadio
                                id="Address2SourceBillTo"
                                name="Address2Source"
                                label={window.translate('Label_Bill_To')}
                                wmsSelector={selector + 'BillTo'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 4)}
                                checked={packingSlipsFormData.Address2Source === 4}
                            />
                            <WmsRadio
                                id="Address2SourceSoldTo"
                                name="Address2Source"
                                label={window.translate('Label_Sold_To')}
                                wmsSelector={selector + 'SoldTo'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 5)}
                                checked={packingSlipsFormData.Address2Source === 5}
                            />

                            <WmsRadio
                                id="Address2SourceCustomerContact"
                                name="Address2Source"
                                label={window.translate('Label_Customer_Contact')}
                                wmsSelector={selector + 'CustomerContact'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 2)}
                                checked={packingSlipsFormData.Address2Source === 2}
                            />
                        </div>
                        <div className="wms-details-uom-section">
                            <WmsRadio
                                id="Address2SourceCustomerFields"
                                name="Address2Source"
                                label={window.translate('Label_custom_fields')}
                                wmsSelector={selector + 'CustomerFields'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 3)}
                                checked={packingSlipsFormData.Address2Source === 3}
                            />

                            <WmsRadio
                                id="Address2SourceWarehouse"
                                name="Address2Source"
                                label={window.translate('Label_Warehouse_Text')}
                                wmsSelector={selector + 'Warehouse'}
                                onChangeFunc={ (e) => handleChangeRadio(e.target.name, 1)}
                                checked={packingSlipsFormData.Address2Source === 1}
                            />
                        </div>

                    </div>
                    {packingSlipsFormData.Address2Source === 3 ? (
                    <div className="custom-field-extra-fields-area">
                        <div className="custom-fields-info">
                            <i className="fa fa-info-circle wms-icon" aria-hidden="true"></i>
                            {window.translate('Label_Configure_Packing_Slips_custom_field_info')}
                        </div>
                        <div>
                            
                            <WmsInput
                                id="Address2SECompanyName"
                                inputName='Address2SECompanyName'
                                wmsSelector={selector + 'Address2SECompanyName'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Company_Name_Data_Source')}
                                value={packingSlipsFormData.Address2SECompanyName}
                                required={true}
                            />
                            <WmsInput
                                id="Address2SEName"
                                inputName='Address2SEName'
                                wmsSelector={selector + 'Address2SEName'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Name_Data_Source')}
                                value={packingSlipsFormData.Address2SEName}
                            />
                            <WmsInput
                                id="Address2SEDept"
                                inputName='Address2SEDept'
                                wmsSelector={selector + 'Address2SEDept'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Department_Data_Source')}
                                value={packingSlipsFormData.Address2SEDept}
                            />
                        </div>
                        <div>
                            <WmsInput
                                id="Address2SEAddress1"
                                inputName='Address2SEAddress1'
                                wmsSelector={selector + 'Address2SEAddress1'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Street1_Data_Source')}
                                value={packingSlipsFormData.Address2SEAddress1}
                                required={true}
                            />
                            <WmsInput
                                id="Address2SEAddress2"
                                inputName='Address2SEAddress2'
                                wmsSelector={selector + 'Address2SEAddress2'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Street2_Data_Source')}
                                value={packingSlipsFormData.Address2SEAddress2}
                            />
                            <WmsInput
                                id="Address2SECity"
                                inputName='Address2SECity'
                                wmsSelector={selector + 'Address2SECity'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_City_Data_Source')}
                                value={packingSlipsFormData.Address2SECity}
                                required={true}
                            />
                        </div>
                        <div>
                            <WmsInput
                                id="Address2SEState"
                                inputName='Address2SEState'
                                wmsSelector={selector + 'Address2SEState'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_State_Data_Source')}
                                value={packingSlipsFormData.Address2SEState}
                            />
                            <WmsInput
                                id="Address2SEZip"
                                inputName='Address2SEZip'
                                wmsSelector={selector + 'Address2SEZip'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Zip_Data_Source')}
                                value={packingSlipsFormData.Address2SEZip}
                                required={true}
                            />
                            <WmsInput
                                id="Address2SECountry"
                                inputName='Address2SECountry'
                                wmsSelector={selector + 'Address2SECountry'}
                                onChangeFunc={handleChangeEvent}
                                label={window.translate('Label_Country_Data_Source')}
                                value={packingSlipsFormData.Address2SECountry}
                                required={true}
                            />
                        </div>
                    </div>
                    ) : null}
                </WmsCollapse>
            </div>
            <div className='footer-btn-holder'>
                    <WmsButton
                        label={window.translate("Label_Cancel")}
                        wmsSelector={selector + "DiscardExit"}
                        onClickFunc={props.onClosePackingSlipsModal}
                        buttonType='orange'
                        icon={'fa-ban'}
                    />
                    {packingSlipsFormData.PackingListTemplateID !== undefined ? (
                    <WmsButton
                        label={window.translate("Label_Delete")}
                        wmsSelector={selector + "DeleteRow"}
                        onClickFunc={deletePackingSlipConfiguration}
                        buttonType='red'
                        icon={'fa-trash'}
                    />
                    ) : null}
                    <WmsButton
                        label={window.translate('Label_Save')}
                        wmsSelector={selector + "SaveExit"}
                        onClickFunc={savePackingSlipConfiguration}
                        icon={'fa-floppy-o'}
                        disabled={showShaveActive()}
                    />
                </div>
        </WmsModel>
    )
}
PackingSlipsConfigurationModal.propTypes = {
    sharedIsOpenPackingSlipsModal: PropTypes.bool.isRequired,
    onClosePackingSlipsModal: PropTypes.func,
    refreshGrid: PropTypes.func,
    afterDelete: PropTypes.func,
    selectedRow: PropTypes.any
};

PackingSlipsConfigurationModal.defaultProps = {
    sharedIsOpenPackingSlipsModal: false,
    onClosePackingSlipsModal: () => void {},
    refreshGrid: () => void {},
    afterDelete: () => void {},
    selectedRow: {}
};
export default React.memo(PackingSlipsConfigurationModal);