export const getGridDemoColumns = (carrier_GridHeaders, selectedFacilityObj) => {
    return ({
        columns: [{
            field: "RowNumber",
            title: "#",
            hidden: true,
            menu: false,
            width: 120
        }, {
            field: "Owner",
            title: carrier_GridHeaders[0].description,
            width: 120,
            wmsid: 1
        },
        {
            field: "Carrier",
            title: carrier_GridHeaders[1].description,
            width: 120,
            wmsid: 2
        },
        {
            field: "NickName",
            title: carrier_GridHeaders[2].description,
            width: 120,
            filterable: true,
            wmsid: 3
        },
        {
            field: "Type",
            title: carrier_GridHeaders[3].description,
            width: 120,
            wmsid: 4,
            template: "#= Type == 'Facility' ? 'Warehouse' : 'Customer' #"
        },
        {
            field: "AccountNumber",
            title: carrier_GridHeaders[4].description,
            width: 120,
            wmsid: 5
        }]
    });
}

export const getGridDemoSchema = () => {
    return ({
        schema: {
            model: {
                id: "Owner",
                fields: {
                    RowNumber: {
                        type: 'number'
                    },
                    facility: {
                        type: "string"
                    },
                    carrier: {
                        type: "string"
                    },
                    accnickname: {
                        type: "string"
                    },
                    acctype: {
                        type: "string"
                    }
                }
            }
        }
    })
}

