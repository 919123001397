interface titleInterface {
    value: number;
    description: string;
}

export const reviewAuditJobGridColumns: Function = (titleArray: Array<titleInterface>): Array<Object> => {
    return [
        {
            field: "title",
            title: titleArray[0].description,
            width: "100px",
            wmsid: 1,
            filterable: true,
        },
        {
            field: "systemCount",
            title: titleArray[1].description,
            width: "100px",
            wmsid: 2,
            filterable: true,
        },
        {
            field: "allocatedCount",
            title: titleArray[2].description,
            width: "100px",
            wmsid: 3,
            filterable: true,
        },
        {
            field: "physicalCount",
            title: titleArray[3].description,
            width: "100px",
            wmsid: 4,
            filterable: true,
        },
        {
            field: "variance",
            template: function (dataItem: any) {
                const color = dataItem.variance === 0 ? "black" : "red";
                return `<span style="color: ${color};">${dataItem.variance}</span>`;
            },
            title: titleArray[4].description,
            width: "100px",
            wmsid: 5,
            filterable: true,
        },
    ];
};

export const reviewAuditJobGridSchema: Function = (): Object => {
    return {
        model: {
            id: "id",
            fields: {
                title: {
                    type: "string",
                },
                systemCount: {
                    type: "number",
                },
                allocatedcount: {
                    type: "number",
                },
                physicalCount: {
                    type: "number",
                },
                variance: {
                    type: "number",
                },
            },
        },
    };
};
