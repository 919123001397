import React from "react";
import PropTypes from "prop-types";

import "./WmsCustomTimePickerProto.scss";

class WmsCustomTimePickerProto extends React.PureComponent {
    constructor(props) {
        super(props);
        this.windowId = this.props.windowId === "" ? "" : "." + this.props.windowId;
    }

    componentDidMount() {
        $(`#${this.props.id}-time-input`).kendoTimePicker({
            change: this.changeTime,
            format: this.props.defaultFormat,
            dateInput: true,
            interval: this.props.interval,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.value && this.props.value !== prevProps.value) {
            this.setTimePickerValue(this.props.value);
        }
    }

    setTimePickerValue = (filterString) => {
        let value = kendo.toString(kendo.parseDate(filterString), this.props.defaultFormat);
        let timepicker = $(`#${this.props.id}-time-input`).data("kendoTimePicker");
        timepicker.value(value);
        timepicker.trigger("change");
    };

    changeTime = () => {
        let timepicker = $(`#${this.props.id}-time-input`).data("kendoTimePicker");
        let eventObj = {
            target: {
                name: this.props.name,
                value: kendo.toString(timepicker.value(), this.props.isDateParseInCustomFormat),
            },
        };
        this.props.onChangeFunc(eventObj);
    };

    render() {
        return (
            <div
                id={this.props.id}
                className={"wms-timepicker-component-proto-kendo " + this.props.extraWrapperClass}
                data-wms-selector={this.props.wmsSelector}
            >
                <label>
                    {this.props.label} {this.props.required ? " *" : ""}{" "}
                </label>
                <div className={this.props.disabled ? "wms-timepicker-disable" : ""}>
                    <div className="timePicker_options">
                        <div className={`wms-TimePicker-main`}>
                            <input
                                id={`${this.props.id}-time-input`}
                                defaultValue={this.props.defaultTime}
                                name="time"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

WmsCustomTimePickerProto.propTypes = {
    id: PropTypes.string.isRequired, // this should write the id to the input
    name: PropTypes.string.isRequired, //this should set the name of datepicker input
    label: PropTypes.string.isRequired, // this should write the label of the menu
    windowId: PropTypes.string, // this should be used to get window id
    value: PropTypes.string, // this should control the value of datepicker
    onChangeFunc: PropTypes.func.isRequired, // this sould manage datepicker change and clear select value
    wmsSelector: PropTypes.string, // this should set up a selector on the date
    disabled: PropTypes.bool, // default will be false
    extraWrapperClass: PropTypes.string, // default will be false
    isDateParseInCustomFormat: PropTypes.string, // default will be false
    defaultFormat: PropTypes.string, // default will be false
    defaultTime: PropTypes.string,
    interval: PropTypes.number,
    required: PropTypes.bool, // default will be false
};

WmsCustomTimePickerProto.defaultProps = {
    value: "00:00:01",
    windowId: "",
    disabled: false,
    wmsSelector: "",
    extraWrapperClass: "",
    defaultFormat: "hh:mm tt",
    isDateParseInCustomFormat: "yyyy-MM-ddTH:mm:ss",
    defaultTime: "12:00 AM",
    interval: 30,
    required: false,
};

export default WmsCustomTimePickerProto;
