import React from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";

interface PassportInfoComponentProps {
    selector: string;
    handleCarrierObjChange: () => void;
    currentCarrierObj: {
        ApiKey: string;
        Nickname: string;
        Company: string;
    };
}

export function PassportInfoComponent(props: PassportInfoComponentProps): JSX.Element {
    const translate = (window as any).translate as any;

    return (
        <WmsCollapse
            id={"passportInfoScreen"}
            headerLabel={translate("Label_Account_Information")}
            showCollapseText={false}
            showCollapseIcon={false}
        >
            <div className="account-info-section">
                <div>
                    <WmsInput
                        id="nickname"
                        inputName="Nickname"
                        wmsSelector={props.selector + "nickname"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_Account_Nick_Name")}
                        value={props.currentCarrierObj.Nickname}
                        required={true}
                        extraWrapperClass="w-50"
                    />
                    <WmsInput
                        id="company"
                        inputName="Company"
                        wmsSelector={props.selector + "Company"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_Company_Name")}
                        value={props.currentCarrierObj.Company}
                        required={true}
                        extraWrapperClass="w-50"
                    />
                    <WmsInput
                        id="apiKey"
                        inputName="ApiKey"
                        wmsSelector={props.selector + "apiKey"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_API_Key")}
                        value={props.currentCarrierObj.ApiKey}
                        required={true}
                        extraWrapperClass="w-50"
                    />
                </div>
            </div>
        </WmsCollapse>
    );
}
