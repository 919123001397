import React from 'react';

//component
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsTextArea from '../../../components/WmsTextArea/WmsTextArea.jsx';
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import * as findOrdersService from '../findOrders.services.jsx';
import * as GlobalService from '../../../global_services';
import './create-pick-job-model.scss';

export class CreatePickJobModel extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        this.state = {
            selector: "order",
            optionsPriority: [
                {Name: '1', Value: 1},
                {Name: '2', Value: 2},
                {Name: '3', Value: 3},
                {Name: '4', Value: 4},
                {Name: '5', Value: 5},
                {Name: '6', Value: 6},
                {Name: '7', Value: 7},
                {Name: '8', Value: 8},
                {Name: '9', Value: 9},
                {Name: '10', Value: 10}],
            optionsOwner: [],
            createPickJobFormData: {},
            gifSaveChangesAndExit: false,
        }
    }
    componentDidMount() {
            kendo.ui.progress($('#WindowCreatePickJob'), true);

            findOrdersService.initialiseCreatePickJobModel(this.props.sharedSelectedOrderIds).then((response) => {

                let optionsOwner = [];
                let createPickJobFormData = {
                    Ids: null,
                    Instructions: null,
                    Priority: 8,
                    UserId: null,
                }

                optionsOwner = response.pickJobCandidatUsersResponse;

                this.setState({
                    optionsOwner: optionsOwner,
                    createPickJobFormData: createPickJobFormData
                }, null);

            }).finally(() => {
                kendo.ui.progress($('#WindowCreatePickJob'), false);
            });

    }



    handleChangeEvent = (event) => {
        let formObj = this.state.createPickJobFormData;

        let propertyName = event.target.name;
        if (event.target.name == "User")
            propertyName = "UserId";
        formObj[propertyName] = event.target.value;
        this.setState({createPickJobFormData: formObj});
    }

    eventClickSaveChangesAndExit = () => {

        this.setState({ gifSaveChangesAndExit: true });
        let formObj = this.state.createPickJobFormData;
        findOrdersService.createPickJob({
            'Instructions': formObj.Instructions,
            'Priority': formObj.Priority,
            'UserId': formObj.UserId,
            'Ids': this.props.sharedSelectedOrderIds
        }).then((response) => {
            GlobalService.notification('success', this.translate('Label_Create_Pick_Job_Success'));
            this.props.onResetGridSelection();
            this.setState({ gifSaveChangesAndExit: false });
            this.props.onCreatePickJobClose();
        }).catch((error) => {
            var errorMessage = error.response.data.Descriptions[0].ErrorCode;
            if (errorMessage.includes('OrderInDifferentPickJob'))
                errorMessage = this.translate('Label_Create_Pick_Job_OrderInDifferentPickJob_Error');
            else if (errorMessage.includes('OrderShipped'))
                errorMessage = this.translate('Label_Create_Pick_Job_OrderShipped_Error');
            else if (errorMessage.includes('OrderAlreadyPicked'))
                errorMessage = this.translate('Label_Create_Pick_Job_OrderAlreadyPicked_Error');
            else if (errorMessage.includes('MultipleCustomers'))
                errorMessage = this.translate('Label_Create_Pick_Job_MultipleCustomers_Error');
            else if (errorMessage.includes('MultipleFacilities'))
                errorMessage = this.translate('Label_Create_Pick_Job_MultipleFacilities_Error');

            GlobalService.notification('error', errorMessage);
        }).finally(() => {
            this.setState({ gifSaveChangesAndExit: false });
            kendo.ui.progress($('#WindowCreatePickJob'), false);
        });

    }

    render() {
        return (
            <WmsModel
                id='WindowCreatePickJob'
                title={this.translate('Label_Create_Pick_Job')}
                width={800}
                height={520}
                onCloseFunc={this.props.onCreatePickJobClose}
                isOpen={this.props.sharedIsOpenCreatePickJobModel}
                customClass='wms-model-new-ui-wrapper'>               
                <div className="model-content-wrapper">
                
                <WmsCollapse
                id={'collapsePickJob'}
                headerLabel={this.translate('Label_Pick_Job')}
                showCollapseText={false}
                showCollapseIcon={false} >
                            <div className="title">
                                <div className="wms-sprite fa fa-exclamation-triangle"></div>
                                {this.props.sharedSelectedOrderIds.length <= 250 &&
                                    <div className="warning-text" >
                                        {this.translate('Orders_FindOrders_CreatePickJob_Summary').replace(new RegExp('{' + 'amount' + '}', 'gi'), this.props.sharedSelectedOrderIds.length)}
                                    </div>
                                }
                                {this.props.sharedSelectedOrderIds.length > 250 &&
                                    <div className="warning-text" ng-show="CountSelectedOrders > 250">
                                        {this.translate('Orders_FindOrders_EditOrder_Summary_Large').replace(new RegExp('{' + 'amount' + '}', 'gi'), this.props.sharedSelectedOrderIds.length)}
                                    </div>
                                }
                            </div>
                            <div className="model-inside-content-wrapper"> 
                            <WmsDropdown
                                id="Priority"
                                name="Priority"
                                label={this.translate('Label_Pick_Job_Priority')}
                                wmsSelector={this.state.selector + "Priority"}
                                value={this.state.createPickJobFormData.Priority}
                                onChangeFunc={this.handleChangeEvent}
                                options={this.state.optionsPriority}
                                valueField="Value"
                                textField="Name"
                                parentClassName="form_group"
                                blankFirstOption={false}
                                required={true}
                            />

                            <WmsDropdown
                                id="User"
                                name="User"
                                label={this.translate('Label_Pick_Job_Owner')}
                                wmsSelector={this.state.selector + "User"}
                                value={this.state.createPickJobFormData.UserId}
                                onChangeFunc={this.handleChangeEvent}
                                options={this.state.optionsOwner}
                                valueField="UserId"
                                textField="Login"
                                parentClassName="form_group"
                                blankFirstOption={true}
                                blankFirstOptionText={'Unspecified'}
                            />

                            <WmsTextArea
                                id="Instructions"
                                name="Instructions"
                                label={this.translate('Label_Pick_Job_Instructions')}
                                wmsSelector={this.state.selector + 'Instructions'}
                                value={this.state.createPickJobFormData.Instructions}
                                onChangeFunc={this.handleChangeEvent}
                                maxLength={512}
                            />                    
                            </div>
                </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate("Label_Discard_Exit")}
                        wmsSelector={this.state.selector + "PickDiscardExit"}
                        onClickFunc={this.props.onCreatePickJobClose}
                        buttonType='orange'
                        icon={'fa-times-circle'}
                    />
                    <WmsButton
                        label={this.translate('Label_Save_Exit')}
                        wmsSelector={this.state.selector + "PickSaveExit"}
                        onClickFunc={this.eventClickSaveChangesAndExit}
                        disabled={this.state.gifSaveChangesAndExit}
                        loading={this.state.gifSaveChangesAndExit}
                        icon={'fa-floppy-o'}
                    />
                </div>
            </WmsModel>
        )
    }
}