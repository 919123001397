import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';
import { default as WmsButton } from '../components/WmsButton/WmsButton.jsx';
import { default as WmsCustomDateTimePicker } from '../components/WmsCustomDateTimePicker/WmsCustomDateTimePicker.jsx';
import { default as WmsDropdown } from '../components/WmsDropdown/WmsDropdown.jsx';
import { default as WmsDropdownList } from '../components/WmsDropdownList/WmsDropdownList.jsx';
import { default as WmsUpdateDropdownList } from '../components/WmsUpdateDropdownList/WmsUpdateDropdownList.jsx';
import { default as WmsInput } from '../components/WmsInput/WmsInput.jsx';
import { default as WmsModel } from '../components/WmsModel/WmsModel.jsx';
import { default as WmsConfirmModel } from '../components/WmsConfirmModel/WmsConfirmModel.jsx';
import { default as WmsContainer } from '../components/WmsContainer/WmsContainer.jsx';
import { default as WmsCollapse } from '../components/WmsCollapse/WmsCollapse.jsx';
import * as metaDataService from './meta-data.service';
import * as GridConfig from './grid_configuration.virtual.jsx';

//services
import * as GlobalService from '../global_services';
export class SmallParcelSearch extends React.Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;
        this.customLabels = GlobalService.getCustomLabels();
        this.smallParcelGridId = "SmallParcelRemoteGrid";
        let SmartParcel_GridHeaders = StringWms.convertSringToOption(this.translate('SmallParcel_GridHeaders'));

        this.state = {
            Selector: 'SmallParcel',
            showTooltip: false,
            showSearchTargetTooltip: false,
            optionsSearchBy: StringWms.convertSringToOption(this.translate('Small_Parcel_Search_Filters')),
            containsOptions: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchFilters')),
            optionsCustomer: GlobalService.getCustomerList(true),
            optionsFacility: GlobalService.getFacilityList(true),
            Rights: GlobalService.GetRights(),
            isOpenSaveFilterModel: false,
            isOpenConfirmModel: false,
            isOpenUpdateConfirmModel: false,
            newFilterTitle: "",
            savedFilterList: metaDataService.getSaveSmallParcelFilterListMetaData(),
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.smallParcelGridId : '#' + this.smallParcelGridId,
            allColumnList: GridConfig.getAllColumnList(SmartParcel_GridHeaders, GlobalService.getCustomLabels()),
            isExpandedCollapse: (!$.isEmptyObject(metaDataService.getSmallParcelCollapseMetaData()) ? metaDataService.getSmallParcelCollapseMetaData() : []),
            filterNameExist: false,
            isClickSearchFilterToggle: false,
            sharedSmallParcelFilter: Object.assign({}, props.defaultFilterValue),
            optionsStatus: [{ value: 0, description: "Complete" }, { value: 3, description: "Closed" }]
        };
        this.onFilterDataSet = GlobalService.debounce(this.onFilterDataSet, 700);
    }
    componentDidMount() {
        var sortable = $('#sortable-section');
        var sortData = metaDataService.getSmallParcelSortSectionMetaData();
        // set saved sort data if any in meta data
        if (sortData) {
            var pos = JSON.parse(sortData);
            if (pos) {
                $.each(pos, function (i, position) {
                    if (position != "") {
                        var $target = sortable.find('#' + position);
                        $target.appendTo(sortable); // or prependTo
                    }
                });
            }
        }
        sortable.sortable({
            update: this.onSectionSort,
            cancel: "label.icon_label,.wms-collapsible-content",
            handle: ".wms-sort",
            distance: 5,
            start: function (e, ui) {
                var height = ui.item.height();
                if (height > 100) {
                    height = 100;
                }
                ui.placeholder.height(height);
            }
        });
        var filterData = metaDataService.getSmallParcelFilterMetaData();
        if (filterData != "") {
            // temporary remove batch name from metaData,
            // remove code after pass batchId in filter
            delete filterData.BatchName;
            this.setState({
                sharedSmallParcelFilter: filterData
            });
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.sharedSmallParcelFilter &&
            prevState.sharedSmallParcelFilter &&
            (
                nextProps.sharedSmallParcelFilter.BatchOrderid != prevState.sharedSmallParcelFilter.BatchOrderid ||
                nextProps.sharedSmallParcelFilter.IsHVPTab != prevState.sharedSmallParcelFilter.IsHVPTab
            )
        ) {
            var nextSharedSmallParcelFilter = Object.assign({}, nextProps.sharedSmallParcelFilter);
            nextSharedSmallParcelFilter.IsHVPTab = false;
            return { sharedSmallParcelFilter: nextSharedSmallParcelFilter };
        }
        return null;
    }
    onSectionSort = () => {
        var sortArray = JSON.stringify($('#sortable-section').sortable("toArray"));
        metaDataService.setSmallParcelSortSectionMetaData(sortArray);
    }
    handleFilterChange = (event) => {
        let propFilter = Object.assign({}, this.state.sharedSmallParcelFilter);
        let updateSearchOnFlag = true;
        let objFilter = Object.assign(
            propFilter,
            { [event.target.name]: event.target.value }
        );

        if (event.target.name == "Skus" && event.target.value == "") {
            objFilter[event.target.name] = [];
        } else if (event.target.name == "BatchOrderid" && event.target.value == "") {
            objFilter[event.target.name] = 0;
        } else if (event.target.name == 'IndexOptionsFacilityId') {
            updateSearchOnFlag = false;
        } else if (event.target.name == 'IndexOptionsCustomerId') {
            // WMS-19452 - Setting batchOrderId 0 when customer is changing
            objFilter['BatchOrderid'] = 0;
        }

        this.setState({
            sharedSmallParcelFilter: objFilter
        });
        this.onFilterDataSet(objFilter, updateSearchOnFlag);
    }

    multiDropdownChangeEvent = (param_value) => {

        if (param_value == null) { param_value = []; }
        let propFilter = this.state.sharedSmallParcelFilter;
        let objFilter = Object.assign(
            {},
            propFilter,
            { 'Skus': param_value }
        );

        this.setState({ sharedSmallParcelFilter: objFilter });
        this.onFilterDataSet(objFilter);

    }

    onFilterDataSet = (objFilter, isUpdateSearchOnFlag) => {
        this.props.onFilterDataSet(objFilter, isUpdateSearchOnFlag);
    }

    batchDropdownBound = (batchDropDown) => {
        batchDropDown.bind("filtering", this.batchOptionFilter(batchDropDown));
    }

    batchOptionFilter = (batchDropDown) => (ev) => {
        var filterValue = ev.filter != undefined ? ev.filter.value : "";

        ev.preventDefault();

        if (filterValue == "" || filterValue.length >= 2) {
            let filtersObj = [{
                field: "Description",
                operator: "contains",
                value: filterValue
            }];

            if (!isNaN(filterValue) && filterValue != "") {
                filtersObj.push({
                    field: "BatchOrderId",
                    operator: "eq",
                    value: parseInt(filterValue)
                });
            }

            batchDropDown.dataSource.filter({
                logic: "or",
                filters: filtersObj
            });
        }
    }

    gridRefresh = () => {
        this.props.onFilterDataSet(this.state.sharedSmallParcelFilter);
    }

    onSaveFilter = () => {
        if (!this.state.filterNameExist) {
            this.props.onManageFilter('saveFilter', this.state.newFilterTitle);
            this.exitSaveFilterModel();
        }
    }

    onUpdateFilter = () => {
        this.setState({ isOpenUpdateConfirmModel: false });
        this.props.onManageFilter('updateFilter', this.state.currentFilterIndex);
    }

    onOpenUpdateConfirmModel = (param_index) => {
        this.setState({
            isOpenUpdateConfirmModel: true,
            currentFilterIndex: param_index
        });
    }

    onCloseUpdateConfirmModel = () => {
        this.setState({ isOpenUpdateConfirmModel: false });
    }

    exitSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: false
        });
    }

    onOpenSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: true,
            newFilterTitle: "",
            filterNameExist: false
        });
    }

    handleFilterTitleChange = (e) => {
        let value = e.target.value;
        if (value.Description == '') {
            value = '';
        }

        var nameExists = false;
        // check dublicate title for case sensitive
        var exists = this.state.savedFilterList.findIndex(function (item) {
            return value.toLowerCase() === item.title.toLowerCase();
        });
        if (exists != -1) {
            nameExists = true;
        }
        this.setState({
            newFilterTitle: value,
            filterNameExist: nameExists
        });
    }

    onUseSaveFilter = (index) => {
        var objFilter = _.cloneDeep(this.state.savedFilterList[index]);
        objFilter.filterData.IndexOptionsFacilityId = this.state.sharedSmallParcelFilter.IndexOptionsFacilityId;

        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var gridConfig = JSON.parse(objFilter.columnData, JSON.dateParser);
        var tempObject = "";

        if (gridConfig.columns) {
            var fieldArray = gridConfig.columns.map(el => el.field);
            var updateColumn = [];
            this.state.allColumnList.filter((column) => {
                if (fieldArray.indexOf(column.field) !== -1) {
                    updateColumn.push(column);
                }
            });
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, updateColumn, "field");
        } else {
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, this.props.defaultColumns, "field");
        }

        if (grid) {
            grid.setOptions({
                columns: tempObject
            });
            grid.dataSource.sort(gridConfig.sorts);
            grid.dataSource.filter(gridConfig.filters);
            this.setState({
                sharedSmallParcelFilter: objFilter.filterData
            });
            this.props.onApplySavedFilter(objFilter.filterData);
        }
    }

    onYesRemoveSavedFilter = () => {
        metaDataService.clearSaveSmallParcelFilterListMetaData(this.state.savedFilterIndex);
        this.setState({
            isOpenConfirmModel: false,
            savedFilterList: metaDataService.getSaveSmallParcelFilterListMetaData()
        });
    }

    ClickExit = () => {
        this.setState({
            isOpenConfirmModel: false
        });
    }

    onClearSaveFilter = (index) => {
        this.setState({
            isOpenConfirmModel: true,
            savedFilterIndex: index
        });
    }

    setCollapseMetaData = (isExpanded, collapseId) => {
        metaDataService.setSmallParcelCollapseMetaData(collapseId, isExpanded);
    }

    clickForFilterToggle = () => {
        this.setState({ isClickSearchFilterToggle: true });
        this.props.onClickForFilterToggle();
    }

    onClearSearch = () => {
        this.setState({
            sharedSmallParcelFilter: Object.assign({}, this.props.defaultFilterValue, { 'IndexOptionsFacilityId': this.state.sharedSmallParcelFilter.IndexOptionsFacilityId })
        });
        this.props.onClearSearch();
    }
    showFilterTooltip = () => {
        this.setState({ showTooltip: true });
    }
    hideFilterTooltip = () => {
        this.setState({ showTooltip: false });
    }
    render() {
        const renderFilter = this.state.sharedSmallParcelFilter;
        return (
            <div className={"small-parcel-search-holder new-layout-filter-wrapper" + (this.state.isClickSearchFilterToggle ? " sectionToggled" : "")}>
                {((this.props.sharedSelectOrder && this.state.showTooltip) ?
                    <div className="wms-tooltip-content">{this.translate("Order_filter_section_disabled_tooltip")}</div>
                    : ""
                )}
                <div
                    onMouseEnter={this.showFilterTooltip}
                    onMouseLeave={this.hideFilterTooltip}
                    className="filter"
                    onClick={
                        (this.props.sharedSelectOrder == null) ? this.clickForFilterToggle :
                            () => { }
                    }>
                    <i className="fa fa-caret-left" aria-hidden="true"></i>
                    <span>search filters</span>
                </div>
                <div className="small-parcel-search-filter ">
                    <div className="wms-toolbar">
                        <WmsButton
                            label={this.translate('Label_Save_View')}
                            wmsSelector={this.state.Selector + 'SaveFilterButton'}
                            onClickFunc={this.onOpenSaveFilterModel}
                            disabled={(renderFilter.IndexOptionsFacilityId == 0)}
                            icon="fa-floppy-o" />
                        <WmsButton
                            label={this.translate('Label_Clear_Filters')}
                            buttonType='orange'
                            wmsSelector={this.state.Selector + 'ClearSearchButton'}
                            onClickFunc={this.onClearSearch}
                            disabled={(renderFilter.IndexOptionsFacilityId == 0)}
                            icon='fa-minus' />
                    </div>

                    {this.props.sharedSearchOn == true &&
                        <div className={(renderFilter.IndexOptionsFacilityId == 0) ? 'title disable-section' : 'title'}>
                            <b>{this.translate('Label_NOTE')}: </b><label >{this.translate('Label_Search_Applied_Filter')}</label>
                        </div>
                    }
                    <div className="smallparcel-form-boxes-wrapper">
                        <div className={"smallparcel-form-boxes " + (this.props.sharedSearchOn ? "width-title" : "")}>
                            <div className="smallparcel-area-content">

                                <WmsCollapse id={'search_default_collapse'} headerLabel={this.translate('Label_General')} showCollapseIcon={false} showCollapseText={false}>
                                    <WmsDropdown
                                        id="Facility"
                                        name="IndexOptionsFacilityId"
                                        label={this.customLabels.Label_Warehouse}
                                        wmsSelector={this.state.Selector + "Facility"}
                                        value={renderFilter.IndexOptionsFacilityId}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsFacility}
                                        textField='name'
                                        valueField='id'
                                        disabled={this.props.isGridLoading}
                                    />
                                    <WmsDropdown
                                        id="Customer"
                                        name="IndexOptionsCustomerId"
                                        label={this.customLabels.Label_Customer}
                                        wmsSelector={this.state.Selector + "Customer"}
                                        value={renderFilter.IndexOptionsCustomerId}
                                        onChangeFunc={this.handleFilterChange}
                                        options={this.state.optionsCustomer}
                                        extraWrapperClass={(renderFilter.IndexOptionsFacilityId == 0) ? 'disable-section' : ''}
                                        textField='name'
                                        valueField='id'
                                    />
                                </WmsCollapse>
                                <div className={(renderFilter.IndexOptionsFacilityId == 0) ? 'disable-section' : ''} id="sortable-section">
                                    {this.state.savedFilterList.length !== 0 && (
                                        <WmsCollapse id={'search_collapse0'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse0') ? !this.state.isExpandedCollapse['search_collapse0'] : true} showDragIcon={true} headerLabel={this.translate('Label_Saved_Filter_List')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse0')}>
                                            <ul className="filter-list-block">
                                                {this.state.savedFilterList.map((data, index) => (
                                                    <li className="filter-list" key={index}>
                                                        <a className="title-text" title={data.title}>{data.title}</a>
                                                        <div className="wms-apply-btn">
                                                            <WmsButton
                                                                label={this.translate('RoleManager_Label_Update')}
                                                                buttonType='green'
                                                                wmsSelector={this.state.Selector + 'UpdateFilterButton'}
                                                                onClickFunc={() => this.onOpenUpdateConfirmModel(index)}
                                                                disabled={(renderFilter.IndexOptionsFacilityId == 0)}
                                                                icon='' />
                                                            <WmsButton
                                                                label={this.translate('Label_Apply')}
                                                                buttonType='green'
                                                                wmsSelector={this.state.Selector + 'ApplyFilterButton'}
                                                                onClickFunc={() => this.onUseSaveFilter(index)}
                                                                disabled={(renderFilter.IndexOptionsFacilityId == 0)}
                                                                icon='' />
                                                        </div>
                                                        <a className="clear-save-filter" onClick={() => this.onClearSaveFilter(index)}><i className="far fa-times-circle" aria-hidden="true"></i></a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </WmsCollapse>
                                    )}

                                    <WmsCollapse id={'search_collapse1'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse1') ? !this.state.isExpandedCollapse['search_collapse1'] : true} showDragIcon={true} headerLabel={this.translate('Label_Filter_Multi_Search')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse1')}>
                                        <WmsInput
                                            id='SearchTarget'
                                            extraWrapperClass="full-width-input"
                                            inputName='SearchTarget'
                                            wmsSelector={this.state.Selector + 'SearchTarget'}
                                            value={renderFilter.SearchTarget}
                                            onChangeFunc={this.handleFilterChange}
                                            onIconClick={this.handleFilterChange}
                                            label={"Search For"}
                                            showIconAfterInput={true}
                                            icon="fa-search"
                                            onMouseEnterFunc={() => {
                                                this.setState({ showSearchTargetTooltip: true });
                                            }}
                                            onMouseLeaveFunc={() => {
                                                this.setState({ showSearchTargetTooltip: false });
                                            }}
                                            isAutoFocus={true}
                                        />
                                        {this.state.showSearchTargetTooltip &&
                                            <span className="search-hover-tooltip">{this.translate('Label_Filter_Multi_Search_Tooltip')}</span>
                                        }
                                    </WmsCollapse>

                                    <WmsCollapse id={'search_collapse2'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse2') ? !this.state.isExpandedCollapse['search_collapse2'] : true} showDragIcon={true} headerLabel={this.translate('Label_Inventory_Date_Search')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse2')}>
                                        <WmsDropdown
                                            id="IndexOptionsSearchBy"
                                            name="IndexOptionsSearchBy"
                                            label={this.translate('Label_Filter_Search_By')}
                                            wmsSelector={this.state.Selector + "SearchByOptions"}
                                            value={renderFilter.IndexOptionsSearchBy}
                                            onChangeFunc={this.handleFilterChange}
                                            options={this.state.optionsSearchBy}
                                            textField='description'
                                            valueField='value'
                                            isHalfInput={true}
                                        />

                                        <WmsCustomDateTimePicker
                                            id={'DatePickerStartDate'}
                                            name='StartDate'
                                            wmsSelector={this.state.Selector + "DatePickerStartDate"}
                                            label={this.translate('Label_Begin')}
                                            onChangeFunc={this.handleFilterChange}
                                            value={renderFilter.StartDate}
                                            windowId={this.props.windowId}
                                            isDateParseInCustomFormat={true}
                                        />



                                        <WmsCustomDateTimePicker
                                            id={'DatePickerEndDate'}
                                            name='EndDate'
                                            wmsSelector={this.state.Selector + "DatePickerEndDate"}
                                            label={this.translate('Label_End')}
                                            onChangeFunc={this.handleFilterChange}
                                            value={renderFilter.EndDate}
                                            windowId={this.props.windowId}
                                            defaultTime={'23:59:59'}
                                            isDateParseInCustomFormat={true}
                                        />
                                    </WmsCollapse>

                                    <WmsCollapse id={'search_collapse6'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse6') ? !this.state.isExpandedCollapse['search_collapse6'] : true} showDragIcon={true} headerLabel={this.translate('Label_Filter_Search_By_Status')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse6')}>
                                        <WmsDropdown
                                            id="IndexOptionsStatus"
                                            name="IndexOptionsStatus"
                                            label={this.translate('Label_Filter_Status')}
                                            wmsSelector={this.state.Selector + "IndexOptionsStatus"}
                                            value={renderFilter.IndexOptionsStatus}
                                            onChangeFunc={this.handleFilterChange}
                                            options={this.state.optionsStatus}
                                            valueField='value'
                                            textField='description'
                                        />
                                    </WmsCollapse>

                                    <WmsCollapse id={'search_collapse4'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse4') ? !this.state.isExpandedCollapse['search_collapse4'] : true} showDragIcon={true} headerLabel={this.translate('Label_Filter_Order_Detail')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse4')}>
                                        {renderFilter.IndexOptionsCustomerId == 0 && (
                                            <div className="wms-input-group-wrapper">
                                                <WmsDropdown
                                                    id="SearchByBatchName"
                                                    name="IndexOptionBatchName"
                                                    label={this.translate('Label_Batch_Name')}
                                                    wmsSelector={this.state.Selector + "SearchByBatchName"}
                                                    value={renderFilter.IndexOptionBatchName}
                                                    onChangeFunc={this.handleFilterChange}
                                                    options={this.state.containsOptions}
                                                    valueField='value'
                                                    textField='description'
                                                    parentClassName="select_box"
                                                />
                                                <WmsInput
                                                    id='BatchName'
                                                    inputName='BatchName'
                                                    wmsSelector={this.state.Selector + 'BatchName'}
                                                    value={renderFilter.BatchName}
                                                    onChangeFunc={this.handleFilterChange}
                                                    label=''
                                                    extraWrapperClass="full-width-input"
                                                    parentClassName='input_box' />
                                            </div>
                                        )}
                                        {(this.state.Rights.hasOwnProperty('orders.manage.batch') && renderFilter.IndexOptionsCustomerId != 0) ?
                                            <div className={renderFilter.BatchOrderid ? 'add-clear-icon' : ''}>
                                                <WmsDropdownList
                                                    id="DropdownlistCustomerBatches"
                                                    name="BatchOrderid"
                                                    optionsLabel={this.translate('Label_Choose_Batch')}
                                                    wmsSelector={this.state.Selector + "CustomerBatches"}
                                                    value={renderFilter.BatchOrderid}
                                                    onChangeFunc={this.handleFilterChange}
                                                    textField='Description'
                                                    valueField='BatchOrderId'
                                                    showClearBtn={true}
                                                    template={"<span class='templtehere' style='white-space: nowrap;text-overflow: ellipsis;display: block;overflow: hidden'>(${ BatchOrderId }) ${  (Description != null) ? Description : ''  }</span>"}
                                                    valueTemplate={"<span class='templtehere' style='white-space: nowrap;text-overflow: ellipsis;display: block;overflow: hidden'>(${ BatchOrderId }) ${  (Description != null) ? Description : '' }</span>"}
                                                    onDropdownBound={this.batchDropdownBound}
                                                    isValuePrimitive={true}
                                                    virtualObj={{
                                                        itemHeight: 26,
                                                        valueMapper: function (options) {
                                                            var that = options;
                                                            $.ajax({
                                                                url: "/WebUI/Shipping/FindOrders/BatchListForCustomerIndexRead",
                                                                type: "POST",
                                                                dataType: "json",
                                                                data: {
                                                                    batchOrderId: that.value || 0,
                                                                    customerId: parseInt(renderFilter.IndexOptionsCustomerId)
                                                                },
                                                                success: function (data) {
                                                                    // data = [0];
                                                                    options.success(data);
                                                                }
                                                            });
                                                        }
                                                    }}
                                                    dataSourceObj={{
                                                        pageSize: 40,
                                                        serverPaging: true,
                                                        serverFiltering: true,
                                                        type: "aspnetmvc-ajax",
                                                        transport: {
                                                            read: {
                                                                url: "/WebUI/Shipping/FindOrders/BatchListForCustomerRead",
                                                                type: "POST",
                                                                data: {
                                                                    customerId: parseInt(renderFilter.IndexOptionsCustomerId)
                                                                }
                                                            },
                                                        },
                                                        schema: {
                                                            data: "Data",
                                                            total: "Total"
                                                        }
                                                    }}
                                                />
                                            </div>
                                            : ''
                                        }
                                        <div className="wms-multiselect-input-wrapper">
                                            <WmsInput
                                                id='HvpBatchName'
                                                inputName='HvpBatchName'
                                                wmsSelector={this.state.Selector + 'HvpBatchName'}
                                                value={renderFilter.HvpBatchName}
                                                onChangeFunc={this.handleFilterChange}
                                                extraWrapperClass="full-width-input"
                                                label={this.translate('Label_HVP_Batch_Name')} />
                                        </div>
                                        <div className="wms-multiselect-input-wrapper">
                                            {renderFilter.IndexOptionsCustomerId < 1 ? (
                                                <WmsInput
                                                    id='Skus'
                                                    inputName='Skus'
                                                    wmsSelector={this.state.Selector + 'SelectedSku'}
                                                    value={
                                                        (renderFilter.Skus && typeof renderFilter.Skus == 'object')
                                                            ? renderFilter.Skus[0]
                                                            : renderFilter.Skus
                                                    }
                                                    onChangeFunc={this.handleFilterChange}
                                                    extraWrapperClass="full-width-input"
                                                    label={this.customLabels.Label_Sku} />
                                            ) :
                                                (
                                                    <div className="wms-multiselect-input">

                                                        <WmsUpdateDropdownList
                                                            id="MultiSelectCustomerSkus"
                                                            name="MultiSelectCustomerSkus"
                                                            label={this.customLabels.Label_Sku}
                                                            optionsLabel={this.translate('Label_Filter_Sku')}
                                                            wmsSelector={this.state.Selector + "MultiSelectCustomerSkus"}
                                                            value={renderFilter.Skus}
                                                            onChangeFunc={this.multiDropdownChangeEvent}
                                                            textField='Sku'
                                                            valueField='Sku'
                                                            virtualObj={{
                                                                itemHeight: 26
                                                            }}
                                                            isValuePrimitive={true}
                                                            dataSourceObj={{
                                                                pageSize: 40,
                                                                serverPaging: true,
                                                                serverFiltering: true,
                                                                type: "aspnetmvc-ajax",
                                                                transport: {
                                                                    read: {
                                                                        url: "/WebUI/Shipping/FindOrders/SkuDropDownReadLite",
                                                                        type: "POST",
                                                                        data: {
                                                                            customerId: renderFilter.IndexOptionsCustomerId //4
                                                                        }
                                                                    },
                                                                },
                                                                schema: {
                                                                    data: "Data",
                                                                    total: "Total"
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                        <div className="wms-input-group-wrapper">
                                            <WmsDropdown
                                                id="SearchByReferenceNumber"
                                                name="IndexOptionsReferenceNumber"
                                                label={this.translate('Orders_Lbl_Reference_Num')}
                                                wmsSelector={this.state.Selector + "SearchByReferenceNumber"}
                                                value={renderFilter.IndexOptionsReferenceNumber}
                                                onChangeFunc={this.handleFilterChange}
                                                options={this.state.containsOptions}
                                                valueField='value'
                                                textField='description'
                                                parentClassName="select_box"
                                            />
                                            <WmsInput
                                                id='Sku'
                                                inputName='ReferenceNumber'
                                                wmsSelector={this.state.Selector + 'ReferenceNumber'}
                                                value={renderFilter.ReferenceNumber}
                                                onChangeFunc={this.handleFilterChange}
                                                label=''
                                                extraWrapperClass="full-width-input"
                                                parentClassName='input_box' />
                                        </div>
                                        <div className="wms-input-group-wrapper">
                                            <WmsDropdown
                                                id="SearchByPurchaseOrderNumber"
                                                name="IndexOptionsPoNum"
                                                label={this.translate('NavBarV1_Btn_POs')}
                                                wmsSelector={this.state.Selector + "SearchByPurchaseOrderNumber"}
                                                value={renderFilter.IndexOptionsPoNum}
                                                onChangeFunc={this.handleFilterChange}
                                                options={this.state.containsOptions}
                                                valueField='value'
                                                textField='description'
                                                parentClassName="select_box"
                                            />
                                            <WmsInput
                                                id='PurchaseOrderNumber'
                                                inputName='PurchaseOrderNumber'
                                                wmsSelector={this.state.Selector + 'PurchaseOrderNumber'}
                                                value={renderFilter.PurchaseOrderNumber}
                                                onChangeFunc={this.handleFilterChange}
                                                label=''
                                                extraWrapperClass="full-width-input"
                                                parentClassName='input_box' />

                                        </div>
                                        <div className="wms-input-group-wrapper">
                                            <WmsDropdown
                                                id="SearchByShipToCountry"
                                                name="IndexOptionsShipToCountry"
                                                label={this.translate('Label_Ship_To_Country')}
                                                wmsSelector={this.state.Selector + "SearchByShipToCountry"}
                                                value={renderFilter.IndexOptionsShipToCountry}
                                                onChangeFunc={this.handleFilterChange}
                                                options={this.state.containsOptions}
                                                valueField='value'
                                                textField='description'
                                                parentClassName="select_box"
                                            />
                                            <WmsInput
                                                id='ShipToCountry'
                                                inputName='ShipToCountry'
                                                wmsSelector={this.state.Selector + 'ShipToCountry'}
                                                value={renderFilter.ShipToCountry}
                                                onChangeFunc={this.handleFilterChange}
                                                label=''
                                                extraWrapperClass="full-width-input"
                                                parentClassName='input_box' />
                                        </div>
                                        <div className="wms-input-group-wrapper">
                                            <WmsDropdown
                                                id="SearchByShipToState"
                                                name="IndexOptionsShipToState"
                                                label={this.translate('Label_Ship_To_State')}
                                                wmsSelector={this.state.Selector + "SearchByShipToState"}
                                                value={renderFilter.IndexOptionsShipToState}
                                                onChangeFunc={this.handleFilterChange}
                                                options={this.state.containsOptions}
                                                valueField='value'
                                                textField='description'
                                                parentClassName="select_box"
                                            />
                                            <WmsInput
                                                id='ShipToState'
                                                inputName='ShipToState'
                                                wmsSelector={this.state.Selector + 'ShipToState'}
                                                value={renderFilter.ShipToState}
                                                onChangeFunc={this.handleFilterChange}
                                                label=''
                                                extraWrapperClass="full-width-input"
                                                parentClassName='input_box' />
                                        </div>
                                        <div className="wms-input-group-wrapper">
                                            <WmsDropdown
                                                id="SearchByShipToZip"
                                                name="IndexOptionsShipToZip"
                                                label={this.translate('Label_Ship_To_Zip')}
                                                wmsSelector={this.state.Selector + "SearchByShipToZip"}
                                                value={renderFilter.IndexOptionsShipToZip}
                                                onChangeFunc={this.handleFilterChange}
                                                options={this.state.containsOptions}
                                                valueField='value'
                                                textField='description'
                                                parentClassName="select_box"
                                            />
                                            <WmsInput
                                                id='ShipToZip'
                                                inputName='ShipToZip'
                                                wmsSelector={this.state.Selector + 'ShipToZip'}
                                                value={renderFilter.ShipToZip}
                                                onChangeFunc={this.handleFilterChange}
                                                label=''
                                                extraWrapperClass="full-width-input"
                                                parentClassName='input_box' />
                                        </div>
                                        <div className="wms-input-group-wrapper">
                                            <WmsDropdown
                                                id="SearchByOrderNote"
                                                name="IndexOptionsOrderNote"
                                                label={this.customLabels.Label_Warehouse + ' ' + this.translate('Label_Filter_Order_Note')}
                                                wmsSelector={this.state.Selector + "SearchByOrderNote"}
                                                value={renderFilter.IndexOptionsOrderNote}
                                                onChangeFunc={this.handleFilterChange}
                                                options={this.state.containsOptions}
                                                valueField='value'
                                                textField='description'
                                                parentClassName="select_box"
                                            />
                                            <WmsInput
                                                id='OrderNote'
                                                inputName='OrderNote'
                                                wmsSelector={this.state.Selector + 'OrderNote'}
                                                value={renderFilter.OrderNote}
                                                onChangeFunc={this.handleFilterChange}
                                                label=''
                                                extraWrapperClass="full-width-input"
                                                parentClassName='input_box' />
                                        </div>
                                    </WmsCollapse>

                                    <WmsCollapse id={'search_collapse5'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('search_collapse5') ? !this.state.isExpandedCollapse['search_collapse5'] : true} showDragIcon={true} headerLabel={this.translate('Orders_Lbl_Transaction_Id')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'search_collapse5')}>
                                        <WmsInput
                                            id='FirstTransaction'
                                            inputName='FirstTransaction'
                                            wmsSelector={this.state.Selector + 'FirstTransaction'}
                                            value={renderFilter.FirstTransaction}
                                            onChangeFunc={this.handleFilterChange}
                                            extraWrapperClass="full-width-input"
                                            label={this.translate('First_Orders_Lbl_Transaction_Id')} />
                                        <WmsInput
                                            id='LastTransaction'
                                            inputName='LastTransaction'
                                            wmsSelector={this.state.Selector + 'LastTransaction'}
                                            value={renderFilter.LastTransaction}
                                            onChangeFunc={this.handleFilterChange}
                                            extraWrapperClass="full-width-input"
                                            label={this.translate('Last_Orders_Lbl_Transaction_Id')} />
                                    </WmsCollapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {this.state.isOpenSaveFilterModel && (
                        <WmsModel
                            id='saveCurrentFilter'
                            title={this.translate('Label_Save_Filter')}
                            width={450}
                            height={260}
                            onCloseFunc={this.exitSaveFilterModel}
                            isOpen={this.state.isOpenSaveFilterModel}
                            customClass='wms-model-new-ui-wrapper'>
                            <div className="model-content-wrapper">
                                <WmsContainer>
                                    <WmsInput
                                        inputName="newFilterTitle"
                                        name="newFilterTitle"
                                        wmsSelector={this.state.Selector + 'FilterTitle'}
                                        value={this.state.newFilterTitle}
                                        onChangeFunc={this.handleFilterTitleChange}
                                        extraWrapperClass="full-width-input"
                                        label={this.translate('Label_Filter_Title')} />
                                </WmsContainer>
                            </div>
                            <div className="save-filter-button-wrapper footer-btn-holder">
                                <WmsButton
                                    label={this.translate('Label_Close')}
                                    wmsSelector={this.state.Selector + "SaveNewFilterExit"}
                                    onClickFunc={this.exitSaveFilterModel}
                                    buttonType="orange"
                                    icon={'fa-times-circle-o'}
                                />
                                <WmsButton
                                    label={this.translate('Label_Add')}
                                    wmsSelector={this.state.Selector + "SaveNewFilter"}
                                    onClickFunc={this.onSaveFilter}
                                    disabled={this.state.newFilterTitle == '' || this.state.filterNameExist}
                                    icon={'fa-plus'}
                                    showToolTip={this.state.filterNameExist}
                                    tooltipText={this.translate('Label_Filter_Name_Exist_Message')}
                                />
                            </div>
                        </WmsModel>
                    )}
                </div>
                <WmsConfirmModel
                    id={"ConfirmModel" + this.props.windowId}
                    isOpen={this.state.isOpenConfirmModel}
                    type={this.translate('Label_Confirmation')}
                    width={500}
                    height={175}
                    firstBtnLabel={this.translate('Label_Yes')}
                    firstBtnIcon={"fa-check-square"}
                    firstBtnOnClickFunc={this.onYesRemoveSavedFilter}
                    sawCheckBox={false}
                    conFormModelText={this.translate('Label_are_you_sure_remove_filter')}
                    onCloseFunc={this.ClickExit}
                    thirdBtnLabel={this.translate('Label_No')}
                    thirdBtnIcon={"fa-times-circle"}
                />
                <WmsConfirmModel
                    id={"ConfirmUpdateModel" + this.props.windowId}
                    isOpen={this.state.isOpenUpdateConfirmModel}
                    type={this.translate('Label_Confirmation')}
                    width={500}
                    height={175}
                    firstBtnLabel={this.translate('Label_Yes')}
                    firstBtnIcon={"fa-check-square"}
                    firstBtnOnClickFunc={this.onUpdateFilter}
                    sawCheckBox={false}
                    conFormModelText={this.translate('Label_are_you_sure_update_filter')}
                    onCloseFunc={this.onCloseUpdateConfirmModel}
                    thirdBtnLabel={this.translate('Label_No')}
                    thirdBtnIcon={"fa-times-circle"}
                    isLoadingStop={false}
                />
            </div >
        );
    }
}