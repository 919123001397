import React, { Component } from 'react';
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';

export class DHLExpressAUAccountInfoComponent extends Component {
    constructor(props) {
        super(props)

        this.translate = window.translate;
        this.servicesOptions = [
            { description: 'Overnight', value: 'Overnight' },
            { description: '2nd Day Air', value: '2nd Day Air' },
            { description: 'Ground', value: 'Ground' },
            { description: 'Like, Crazy Slow', value: 'Like, Crazy Slow' }
        ];
        this.state = {
            defaultRatePercentage: 0,
            defaultRateAmount: 0
        };
    }

    render() {
        return (
            <div>
                <WmsCollapse
                    id={"dhl-express-au"}
                    headerLabel={this.translate("Label_Account_Information")}
                    showCollapseText={false}
                    showCollapseIcon={false}
                >
                    <div className="account-info-section">
                        <WmsInput
                            id="accountNickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Nick_Name")}
                            value={this.props.currentCarrierObj.Nickname}
                            required={true}
                            extraWrapperClass="dhlexp-au-nikcname"
                        />
                        <WmsInput
                            id="account_number"
                            inputName="AccountNumber"
                            wmsSelector={this.props.selector + "accountNumber"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Number")}
                            value={this.props.currentCarrierObj.AccountNumber}
                            required={true}
                            extraWrapperClass="dhlexp-au-accountnumber"
                        />
                    </div>
                </WmsCollapse>
            </div>
        )
    }
}
