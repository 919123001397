
import React from 'react';

//components
import WmsGrid from './../../components/WmsGrid/WmsGrid.jsx';
import WmsCollapse from './../../components/WmsCollapse/WmsCollapse.jsx';
import { NewCarrierConnectionModel } from './model/new-carrier-connection.component.jsx';

import { StringWms } from './../../../../assets/ts/StringWms.ts';
import * as GridConfig from './small-parcel-carrier-account-grid-config.jsx';
//services
import * as GlobalService from './../../global_services';
import * as smallParcelServices from './../small-parcel-settings.services.jsx';

export class SmallParcelCarrierAccountSettings extends React.Component {

    constructor(props) {

        super(props);

        this.rights = GlobalService.GetRights();
        this.isEnableProvider = this.rights.hasOwnProperty('Shipping.SmallParcelEnabled') && this.rights['Integrations.SmallParcelPartner'] == 'ParcelApi';
        this.translate = window.translate;
        this.$rootScope = window;
        let carrier_GridHeaders = StringWms.convertSringToOption(this.translate('Small_Parcel_Carrier_Header'));
        this.carrierGridColumns = GridConfig.getCarrierGridColumnsColumns(carrier_GridHeaders, this.isEnableProvider, this.props.sharedSmallParcelSettings).columns;
        this.carrierGridSchema = GridConfig.getCarrierGridSchema().schema;
        this.localKendoGrid = null;

        this.state = {
            isOpenCarrierConnectionModel: false,
            sharedCarrierConnectionFlow: '',
            selectedCarrierAccountData: null,
            deleteCarrierLoader: false,
            isUpdateGridSummary: false,
            isEditCarrierDisable: true
        };
    }

    addCarrierConnection = () => {
        this.setState({
            isOpenCarrierConnectionModel: true,
            sharedCarrierConnectionFlow: 'create'
        });
    }

    editCarrierConnection = () => {
        this.setState({
            isOpenCarrierConnectionModel: true,
            sharedCarrierConnectionFlow: 'edit'
        });
    }
    closeCarrierConnection = () => {
        this.setState({ isOpenCarrierConnectionModel: false });
    }

    deleteCarrier = () => {
        this.setState({ deleteCarrierLoader: true });

        let carrierAccountUri = this.state.selectedCarrierAccountData.Uri;

        smallParcelServices.deleteCarrier(carrierAccountUri).then((response) => {
            GlobalService.notification('success', this.translate('Label_CarrierAccount_Deleted'));
            this.onResetSelectionAndGridRefresh();
        }).finally(() => {
            this.setState({ deleteCarrierLoader: false });
        });
    }
    onResetSelectionAndGridRefresh = () => {

        if (this.localKendoGrid != null) {
            this.localKendoGrid.clearSelections(() => {
                this.localKendoGrid.repaintSelections();
            });
            this.setState({
                selectedCarrierAccountData: null,
                isEditCarrierDisable: true,
                isUpdateGridSummary: true
            });
        }
        this.props.onRefreshGridData();
    }

    selectRow = (param_kendo_grid) => {

        let currentSelections = param_kendo_grid.getNumberOfSelections();
        let selectedCarrierAccountData = null;
        let isEditCarrierDisable = true;

        if (currentSelections === 1) {
            selectedCarrierAccountData = param_kendo_grid.getItem(param_kendo_grid.getKeys());
            if (selectedCarrierAccountData.Carrier == 'UPS' || selectedCarrierAccountData.Carrier == 'FedEx') {
                isEditCarrierDisable = false;
            }
        }
        this.localKendoGrid = param_kendo_grid;
        this.setState({
            selectedCarrierAccountData: selectedCarrierAccountData,
            isEditCarrierDisable: isEditCarrierDisable
        });
    }
    onUpdateGridSummary = () => {
        this.setState({ isUpdateGridSummary: false });
    }
    onGridSave = (e) => {
        var model = e.model;
        if (e.values.MarkupAmmount) {
            model.set("MarkupPercentage", 0);
        } else if (e.values.MarkupPercentage) {
            model.set("MarkupAmmount", 0);
        }
    }
    render() {

        return (

            <div className="carrier-account-settings-wrapper">
                <WmsCollapse
                    id={'smallParcelSettingsCarrierAccounts'}
                    headerLabel={this.translate('Label_Carrier_Accounts')}
                    showCollapseText={false}
                    showCollapseIcon={true}>


                    <div className="manage-carrier-grid-holder">
                        <WmsGrid
                            columns={this.carrierGridColumns}
                            schema={this.state.carrierGridSchema}
                            gridKeySelection={'RowNumber'}
                            staticGridData={this.props.sharedCarrierAccountsGridData}
                            virtual={false}
                            showGridSummary={true}
                            gridName={"CarrierGrid"}
                            id={"CarrierGrid"}
                            windowId={this.props.windowId}
                            showGridResult={true}
                            showSortColumns={true}
                            showColumnFilter={false}
                            shawCheckbox={false}
                            wmsSelector={this.props.selector + "CarrierGrid"}
                            onSelection={this.selectRow}
                            onSelectAllChange={() => null}
                            clickEventOfContextMenu={() => { }}
                            resetButtonLabel={this.translate('Label_Options')}
                            resetButtonIcon={'fa-sliders'}
                            isUpdateGridSummary={this.state.isUpdateGridSummary}
                            onUpdateGridSummary={this.onUpdateGridSummary}
                            onGridSave={this.onGridSave}
                            menu={
                                [{
                                    "name": this.translate('Label_Create'),
                                    "value": [],
                                    // "disabled": !this.props.sharedSelectedCustomer.id,
                                    "icon": "fa-plus",
                                    "color": "green",
                                    'isContextMenu': false,
                                    'isButtonMenu': true,
                                    'wmsid': 'create',
                                    'wmsSelector': this.props.selector + "CreateCarrier",
                                    'clickFunc': this.addCarrierConnection,
                                }, {
                                    "name": this.translate('Label_Edit'),
                                    "value": [],
                                    "disabled": this.state.isEditCarrierDisable,
                                    "showToolTip": (this.state.selectedCarrierAccountData != null),
                                    "tooltipText": this.translate('Carrier_EDIT_DISABLE_TEXT'),
                                    "icon": "fa-pencil",
                                    "color": "green",
                                    'isContextMenu': false,
                                    'isButtonMenu': true,
                                    'wmsid': 'edit',
                                    'wmsSelector': this.props.selector + "EditCarrier",
                                    'clickFunc': this.editCarrierConnection,
                                },{
                                    "name": this.translate('Label_Delete'),
                                    "value": [],
                                    "icon": "fa-trash",
                                    "color": "red",
                                    'isContextMenu': false,
                                    'isButtonMenu': true,
                                    'wmsid': 'expandCollapse',
                                    'wmsSelector': this.props.selector + "ExpandCarrier",
                                    'clickFunc': this.deleteCarrier,
                                    'loading': this.state.deleteCarrierLoader,
                                    "disabled": (this.state.selectedCarrierAccountData == null)
                                }]
                            }
                        />
                    </div>
                    {this.state.isOpenCarrierConnectionModel && (
                        <NewCarrierConnectionModel
                            isOpenCarrierConnectionModel={this.state.isOpenCarrierConnectionModel}
                            sharedSelectedCarrierAccountData={this.state.selectedCarrierAccountData}
                            sharedCarrierConnectionFlow={this.state.sharedCarrierConnectionFlow}
                            closeCarrierConnection={this.closeCarrierConnection}
                            sharedFacilityOption={this.props.sharedFacilityOption}
                            onResetSelectionAndGridRefresh={this.onResetSelectionAndGridRefresh}
                            sharedShipEngineCarrier={this.props.sharedShipEngineCarrier}
                            selector={this.props.selector} />
                    )}


                </WmsCollapse>
            </div >
        );
    }
}