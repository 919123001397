var axios = require('axios');
var UtcOffset = new Date().getTimezoneOffset() / 60 * -1;

import * as GlobalService from './../../global_services';

export const ManageReceiptEditData = function (editUri) {
    var data = {
        receiverUri: editUri,
        gridNames: ["GridReceiptLineItems", "GridReceiptCharges"]
    }
    return axios.post('/WebUI/Receiving/ManageReceivings/GetEditReceivingsOptions', data)
        .then(function (response) {
            // var tempData = response.data;
            return response;
        });
}

export const createReceipt = function (receiptData, isCustomerUser) {
    var data = {
        viewModel: receiptData,
        isCustomerUser: isCustomerUser
    }

    var response = {
        "ReceiverId": 225995,
        "ReferenceNum": "125345_1812",
        "PoNum": null,
        "ReceiptAdviseNumber": null,
        "ArrivalDate": "2018-12-18T07:24:07.8257601Z",
        "ExpectedDate": null,
        "Carrier": null,
        "TrackingNumber": null,
        "TrailerNumber": null,
        "SealNumber": null,
        "BillOfLading": null,
        "CapacityType": null,
        "DoorNumber": null,
        "Notes": null,
        "CustomerId": 3360,
        "FacilityId": 4514,
        "EditUri": "/inventory/receivers/225995{?detail,itemdetail}",
        "StatusEnum": 0,
        "Completed": false,
        "NumUnits1": null,
        "Unit1Identifier": null,
        "NumUnits2": null,
        "Unit2Identifier": null,
        "TotalWeight": null,
        "TotalVolume": null,
        "ReceiveItems": null,
        "SavedElements": [

        ],
        "BillingCharges": [

        ],
        "FileSummariesUri": "/inventory/receivers/225995/filesummaries"
    }

    return response;
    // return axios.post('/WebUI/Receiving/ManageReceivings/CreateReceipt', data)
    //     .then(function (response) {
    //         return response;
    //     });
}
export const SaveReceipts = function (receiptDataModel, isCustomerUser) {
    var param_data = {
        viewModel: receiptDataModel,
        isCustomerUser: isCustomerUser
    }
    return axios.post('/WebUI/Receiving/ManageReceivings/CreateReceipt', param_data)
        .then(function (response) {
            return response;
        });
}

export const UpdateReceipt = function (receiptData) {
    var data = {
        viewModel: receiptData,
    }
    return axios.post('/WebUI/Receiving/ManageReceivings/UpdateReceipt', data)
        .then(function (response) {
            return response;
        });
}
export const CompleteReceipt = function (receipt) {
    return axios.post('/WebUI/Receiving/ManageReceivings/Completer', {
        viewModel: receipt
    }).then(function (response) {
        return response;
    });
}

export const getReceiptLineItems = function (editUri) {
    return axios.get('/WebUI/Receiving/ManageReceivings/ReceiptLineItems?editUri=' + editUri)
        .then(function (response) {
            return response.data;
        });
}
export const ReceiptLineItem_DirectPutAway = function (editUri, putawayEnabled) {
    var data = {
        "receiverUri": editUri,
        "overrideLocations": putawayEnabled
    }

    return axios.put('/WebUI/Receiving/ManageReceivings/Locator', data)
        .then(function (response) {
            return response.data;
        });
}

export const ReceiptLineItem_Delete = function (lineItemUris) {
    var data = {
        "lineItemUris": lineItemUris
    }

    return axios.post('/WebUI/Receiving/ManageReceivings/ReceiptLineItemsDeleter', data)
        .then(function (response) {
            return response.data;
        });
}



export const dynamicServiceForDynamicMenu = function (urlName, uri, ids, outputType, flowType) {
    var fileType = outputType ? outputType : 'application/pdf';
    var myReturn = {};
    myReturn.FileType = fileType;

    var _url = "";
    var data = {
        requestBody: {
            RunnerUriTemplate: uri,
            outputType: fileType
        }
    }

    if (urlName === "RunViewTransactionReport") {
        _url = '/WebUI/Receiving/' + flowType + '/RunViewTransactionReport';
        data.requestBody.UtcOffset = UtcOffset;
        data.requestBody.ShowCharges = true;
        data.requestBody.ShowPackages = false;
        data.requestBody.TransactionIds = ids;
    } else if (urlName === "RunMULabelReport") {
        _url = '/WebUI/Receiving/' + flowType + '/RunMULabelReport';
        data.requestBody.TransactionId = ids;
    } else if (urlName === "RunReceiptTicketReport") {
        _url = '/WebUI/Receiving/' + flowType + '/RunReceiptTicketReport';
        data.requestBody.TransactionId = ids;
    }

    return axios.post(_url, data)
        .then(function (response) {
            myReturn.Data = response.data;
            return myReturn;
        });
}

export const getConfirmOptions = function (receiverUriTemplate) {

    return axios.post('/WebUI/Receiving/ManageReceivings/GetConfirmOptions', { "receiverUriTemplate": receiverUriTemplate })
        .then(function (response) {
            return response.data;
        });
}

export const confirmReceipt = function (editUriTemplate, autoAllocateToOpenOrders, arrivalDate, createInvoice) {

    return axios.post('/WebUI/Receiving/ManageReceivings/ConfirmReceipt', {
        "editUriTemplate": editUriTemplate,
        "autoAllocateToOpenOrders": autoAllocateToOpenOrders,
        "arrivalDate": arrivalDate,
        "createInvoice": createInvoice,
        "utcOffset": new Date().getTimezoneOffset() / 60 * -1
    }).then(function (response) {
        return response;
    });
}


export const getProposedBillingCharges = function (receiverId) {
    return axios.post('/WebUI/Receiving/ManageReceivings/GetProposedBillingCharges', {
        "receiverUriTemplate": receiverId
    }).then(function (response) {
        return response;
    });
}

export const lineItemEditOptions = function (obj) {
    var url = '/WebUI/Receiving/ManageReceivings/LineItemEditOptions?receiverId=' + obj.receiverId + '&customerId=' + obj.customerId + '&sku=' + encodeURIComponent(obj.sku);
    return axios.get(url).then(function (response) {
        return response.data;
    });
}

export const getSuggestedPutawayLocations = function (facilityId, itemId, qualifier) {
    var params = {
        facilityId: facilityId,
        itemId: itemId,
        qualifier: qualifier
    }
    return axios.get('/WebUI/Receiving/ManageReceivings/GetInventoryLocations', { params: params }).then(function (response) {
        return response.data;
    });
}

export const getPalletInfoByLabel = function (palletLabel) {
    var params = {
        label: palletLabel
    }
    return axios.post('/WebUI/Receiving/ManageReceivings/GetPalletInfoByLabel', params).then(function (response) {
        return response.data;
    });
}

export const checkifMUExistsMultipleLocs = function (palletLabel, facId, loc, paramReceiveItemId) {
    var params = {
        label: palletLabel,
        facilityId: facId,
        location: loc
    }
    if(paramReceiveItemId) {
        params.receiveItemId = paramReceiveItemId
    }
    return axios.post('/WebUI/Receiving/ManageReceivings/CheckIfMUExistsInMultipleLocations', params).then(function (response) {
        return response.data;
    });
}

export const saveReceiptLineItem = function (method, params) {
    return axios[method]('/WebUI/Receiving/ManageReceivings/ReceiptLineItem', params).then(function (response) {
        return response;
    });
}