import React from 'react';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';



import * as GlobalService from './../global_services';
import WmsCheckBox from "./../components/WmsCheckBox/WmsCheckBox.jsx";
import PropTypes from "prop-types";



export class WarehouseFormCustomizations extends React.Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;

        this.state = {
            CustomLabels: GlobalService.getCustomLabels(),
            Rights: GlobalService.GetRights(),
            Selector: 'Warehouse',
            PartialAllocationRadioSelectionState: false,
            measurementStandardOption: [{
                "name": "Imperial",
                "id": 0
            }, {
                "name": "Metric",
                "id": 1
            }],
            locationFieldOption: [{
                "name": "1",
                "value": 1
            }, {
                "name": "2",
                "value": 2
            }, {
                "name": "3",
                "value": 3
            }, {
                "name": "4",
                "value": 4
            }]
        }
    }

    componentWillReceiveProps(nextprops) { }

    handleWCustFormChanges = (event) => {
        if (typeof event.target == 'undefined') {
            return false;
        }

        let propFacility = this.props.sharedWarehouse;
        let objFacility = Object.assign({}, propFacility)

        const name = event.target.name;
        const value = event.target.value;

        if (event.target.type == 'checkbox') {
            objFacility[name] = event.target.checked
        } else {
            objFacility[name] = value
        }

        if (name == 'Replenishment' && objFacility.Replenishment == false) {
            objFacility.ReplenishFewestLocations = false
        }

        this.props.onWarehouseDataset(objFacility);
    }


    render() {
        const renderFacility = this.props.sharedWarehouse;
        return (
            <div id={'CollapseWarehouseCustDetail'} className={"collapseWarehouseTabContent_wrapper"}>
                <WmsCollapse id={'ManageWarehouseCustDetail'} className={"collapseWarehouseTabContent_wrapper"} headerLabel={this.translate('Label_Customizations')} showCollapseText={false} showCollapseIcon={false}  >
                    <div className="inline-elements">
                        <WmsInput
                            id='FuelSurcharge'
                            inputName='FuelSurcharge'
                            wmsSelector={this.state.Selector + 'FuelSurcharge'}
                            onChangeFunc={this.handleWCustFormChanges}
                            label={this.translate('Label_Warehouse_FuelSurcharge')}
                            value={renderFacility.FuelSurcharge} />

                        <WmsInput
                            id='FreightRateLocationCode'
                            inputName='FreightRateLocationCode'
                            wmsSelector={this.state.Selector + 'LocationCode'}
                            onChangeFunc={this.handleWCustFormChanges}
                            label={this.translate('Label_External_Warehouse_Code')}
                            value={renderFacility.FreightRateLocationCode} />


                        <WmsDropdown
                            id="LocationFieldCount"
                            name="LocationFieldCount"
                            label={this.translate('Label_Warehouse_LocationFieldCount')}
                            wmsSelector={this.state.Selector + 'FieldCount'}
                            onChangeFunc={this.handleWCustFormChanges}
                            options={this.state.locationFieldOption}
                            valueField='value'
                            textField='name'
                            value={renderFacility.LocationFieldCount} />

                        <WmsDropdown
                            id="MeasurementSystemDefault"
                            name="MeasurementSystemDefault"
                            label={this.translate('Label_Warehouse_MeasurementSystemDefault')}
                            wmsSelector={this.state.Selector + 'MeasurementSystemDefault'}
                            onChangeFunc={this.handleWCustFormChanges}
                            options={this.state.measurementStandardOption}
                            valueField='id'
                            textField='name'
                            value={renderFacility.MeasurementSystemDefault} />
                    </div>
                    <div className="inline-elements">
                        <WmsCheckBox
                            id={'UsePredefinedLocations'}
                            name={'UsePredefinedLocations'}
                            wmsSelector={this.state.Selector + 'UsePredefinedLocations'}
                            onChangeFunc={this.handleWCustFormChanges}
                            label={this.translate("Label_Warehouse_Use_PredefinedLocations")}
                            checked={renderFacility.UsePredefinedLocations === true}
                            value={renderFacility.UsePredefinedLocations}
                            parentClassName="user-predefined-chk-wrapper"
                        />

                        <WmsCheckBox
                            id={'PreventPartialOrderManualAllocation'}
                            name={'PreventPartialOrderManualAllocation'}
                            wmsSelector={this.state.Selector + 'PreventPartialOrderManualAllocation'}
                            onChangeFunc={this.handleWCustFormChanges}
                            label={this.translate("Label_Warehouse_Partial_Allocations")}
                            checked={renderFacility.PreventPartialOrderManualAllocation === true}
                            value={renderFacility.PreventPartialOrderManualAllocation}
                        />
                    </div>
                    <div className="inline-elements replenishment-chk-wrapper">
                        <WmsCheckBox
                            id={'Replenishment'}
                            name={'Replenishment'}
                            wmsSelector={this.state.Selector + 'Replenishment'}
                            onChangeFunc={this.handleWCustFormChanges}
                            label={this.translate("Label_Enable_Replenishment")}
                            checked={renderFacility.Replenishment === true}
                            value={renderFacility.Replenishment}
                        />
                        <div className='replenishment-wrapper'>
                            <WmsCheckBox
                                id={'ReplenishFewestLocations'}
                                name={'ReplenishFewestLocations'}
                                wmsSelector={this.state.Selector + 'ReplenishFewestLocations'}
                                onChangeFunc={this.handleWCustFormChanges}
                                label={this.translate("Label_Replenish_From_Fewest_Location")}
                                checked={renderFacility.ReplenishFewestLocations === true}
                                value={renderFacility.ReplenishFewestLocations}
                                disabled={renderFacility.Replenishment == false}
                                parentClassName="replenishment-fewest-location-chk"
                            />
                        </div>
                    </div>

                </WmsCollapse>
            </div>
        )
    }
}