export const getPackAndShipGridColumns = () => {
    return ({
        columns: [{
            field: "Sku",
            title: "Sku",
            width: 154,
            wmsid: 1,
            // template: "#= (typeof Location != 'undefined' && Location != null) ? Location +' > ' : '' # #= (typeof MovableUnit != 'undefined' && MovableUnit != null) ? MovableUnit +' > ' : '' # #= (typeof Sku != 'undefined' && Sku != null) ? Sku : '' #",
            // template: "#= (typeof Sku != 'undefined' && Sku != null) ? Sku : '' #",
            template: '#if (typeof Sku != "undefined" && Sku == "INSERTS" ) {# INSR <i class="fa fa-gift pink-color"></i> (Optional) #} else if (typeof Sku != "undefined" && typeof IsInsert != "undefined" && IsInsert) {# #=Sku# <i class="fa fa-gift pink-color"></i> (Optional) #} else if (typeof Sku != "undefined") {# #=Sku# #}#',
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridSku'
            }
        }, {
            field: "Description ",
            title: "Description ",
            width: 275,
            wmsid: 2,
            template: function (dataItem) {
                return dataItem.IsHazMat ? `<div class="hazmat-wrapper"><div class="pill-wrapper">Hazmat</div>  <div class="description-wrapper">${dataItem.Description}</div></div>` : dataItem.Description;
            },
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridDescription'
            }
        }, {
            field: "Qualifier",
            title: "Qualifier",
            width: 100,
            wmsid: 5,
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridQualifier'
            }
        }, {
            field: "Lot",
            title: "Lot",
            width: 90,
            wmsid: 3,
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridLot'
            }
        }, {
            field: "Serial",
            title: "Serial",
            width: 100,
            wmsid: 4,
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridSerial'
            }
        }, {
            field: "OutBoundSerial",
            title: "Ob Serial",
            width: 100,
            wmsid: 12,
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridOutBoundSerial'
            }
        }, {
            field: "Expires",
            title: "Expires",
            width: 105,
            wmsid: 6,
            template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy') : '' #",
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridExpires'
            }
        }, {
            field: "DisplayQty",
            title: "Qty",
            width: 66,
            wmsid: 7,
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridQty'
            }
        }, {
            field: "CheckIcon",
            title: " ",
            width: 50,
            wmsid: 11,
            template: '#if(CheckIcon > 0 ) {# <span class="qty-icon-wrapper checked-package"> <i class="fa fa-check"></i> </span> #} else{# <span class="qty-icon-wrapper "> <i class="fa fa-check"></i> </span> #}#',
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridCheckIcon'
            }
        }, {
            field: "PackageName",
            title: "PackageName",
            hidden: true,
            width: 120,
            wmsid: 8,
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridPackageName'
            }
        },
        {
            field: "val",
            title: "val",
            width: 120,
            wmsid: 9,
            hidden: true,
            groupHeaderTemplate: "<span #if(data.items[0].isHideRow){# class='hide-package-items package-icon-wrapper' #}else if(data.items[0].PackageId || data.items[0].isShawZeroPackageId == true){#class='package-icon-wrapper'#}else {# class='hide-package-icon-wrapper package-icon-wrapper' #}# > <label>#=window.packageArr[data.value]#</label> | Dimensions: #=data.items[0].Dimensions # | Weight: #=data.items[0].Weight # | Package ID: #=data.items[0].PackageId # | UCC128: #=data.items[0].Ucc128 #  <span class='group-icon-wrapper' package-id='#=data.items[0].PackageId#' unique-package-name='#=data.items[0].uniquePackageName#'> #if(data.items[0].isDeleteIconShawForUnAssingPkg ){# <a href='javascript:void(0);' class='remove-unassing-package' data-wms-selector='packAndShipTransactionRemoveUnassingPackage' unique-package-name='#=data.items[0].uniquePackageName#''><span class='fa fa-trash-o'></span></a> #}# #if(data.items[0].PackageId > 0 ){# <a href='javascript:void(0);' #if(data.items[0].isOrderAlreadyShipped == true){# class='edit-package disabled-icon' #}else {#  class='edit-package' #}# data-wms-selector='packAndShipTransactionEditPackage' package-id='#=data.items[0].PackageId#''><span class='fa fa-pencil'></span></a> #}# #if(data.items[0].isPrintIconShaw == false){# <a href='javascript:void(0);' #if(data.items[0].isPrintIconDisable == true){# class='print-package disabled-icon' #}else {#  class='print-package' #}# package-id='#=data.items[0].PackageId#''><span class='fa fa-print'></span></a> #}# #if(data.items[0].PackageUri ) {# <a href='javascript:void(0);' #if(data.items[0].isOrderAlreadyShipped == true){# class='remove-package disabled-icon' #}else {#  class='remove-package' #}# package-id='#=data.items[0].PackageId#'' package-uri='#=data.items[0].PackageUri#' package-name='#=window.packageArr[data.value]#' data-wms-selector='packAndShipTransactionDeletePackage'><span class='fa fa-trash-o'></span></a> #} # </span> </span>",
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridVal'
            }
        },
        {
            field: "PackageContentId",
            title: "PackageContentId",
            width: 120,
            hidden: true,
            wmsid: 10,
            headerAttributes: {
                "data-wms-selector": 'PackSndShipGridPackageContentId'
            }
        }]
    })
}

export const getPackAndShipGridSchema = () => {
    return ({
        schema: {
            model: {
                id: "PackageContentId",
                fields: {
                    PackageName: { type: "string" },
                    Qty: { type: "number" },
                    Expires: { type: "date" },
                    Serial: { type: "string" },
                    Qualifier: { type: "string" },
                    Lot: { type: "string" },
                    Sku: { type: "string" },
                    CheckIcon: { type: "number", width: 30 }
                }
            }
        }
    })
}
