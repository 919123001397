

import React from 'react';

//component
import { ArrayWms } from '../../../../../../assets/ts/ArrayWms.ts'
import { StringWms } from '../../../../../../assets/ts/StringWms.ts';
import VirtualGridComponent from '../../../../components_proto/VirtualGridComponent/VirtualGridComponent.jsx';
import { LoadingModel } from '../../../../components/LoadingModel/LoadingModel.jsx';
import { AddEditColumnModel } from '../../../../components/AddEditColumn/add-edit-columns.component.jsx';
import { ConcurrencyManager } from '../../../../../../assets/vendor/concurrencyManager/concurrencyManager.js';

import axios from 'axios';
import * as GridConfig from './grid_configuration.jsx';
import * as metaDataService from './meta-data.service';
import style from './proto-mass-add.scss';

//services
import * as GlobalService from './../../../../global_services';
import * as manageOrderServices from '../../../manageOrders/manage-orders.services.jsx';


export class ProtoMassAddGrid extends React.Component {

    constructor(props) {

        super(props);

        this.$rootScope = window;
        this.translate = window.translate;
        this.firstFiftyAxiosSource = null;
        this.axiosSource = null;
        this.inventoryGridData = null;
        this.inventory_GridHeaders = StringWms.convertSringToOption(this.translate('Mass_Add_GridHeaders'));
        this.manageInventoryGridId = "GridManageInventory";
        this.receiveItemIdsGroup = [];
        this.isApiCanceled = false;
        this.state = {
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.manageInventoryGridId : '#' + this.manageInventoryGridId,
            selector: 'Inventory',
            localKendoGrid: null,
            allColumnList: GridConfig.getAllColumnList(this.inventory_GridHeaders).columns,
            allSortColumnList: [],
            defaultColumns: GridConfig.getGridDemoColumns(this.inventory_GridHeaders).columns,
            schema: GridConfig.getGridDemoSchema(GlobalService).schema,
            totalInventory: 0,
            gridData: [],
            gridExportLimit: GlobalService.constants.gridExportLimit,
            manageInventoryGridConfig: metaDataService.getMassAddGridOptionInMetaData(),
            Rights: GlobalService.GetRights(),
            isFilterRowCountLoading: false,
            isMassGridDataLoading: false,
            isOpenColumnModel: false,
            filterText: "",
            fullFilterText: "",
            totalRequest: 0,
            selectedOrders: 0,
            disabledAddAllSelected: true,
            overlayScreenShow: false,
            gridPageSize: 500,
            isNewColumnAdded: false,
            isCombineRow: false,
            massAddLoading: false,
            massAddAllLoading: false,
            invalidQty: true,
            addBtnValidationText : this.translate('Label_You_May_Add_Atleast_One'),
            addAllSelectedText : this.translate('Label_Add_All_Selected_Validation'),
            firstLoadLimit: 4000,
            massAddSearchInfo: null,
            showLoadingError: false
        }

    }

    componentDidMount() {
        let allSortColumnList = this.state.allColumnList.sort(function (first, second) {

            var columnTitle1 = first.title.trim().toUpperCase(); // ignore upper and lowercase
            var columnTitle2 = second.title.trim().toUpperCase(); // ignore upper and lowercase

            if (columnTitle1 < columnTitle2) { return -1; }

            if (columnTitle1 > columnTitle2) { return 1; }
            // names must be equal
            return 0;
        });

        this.setState({ allSortColumnList: allSortColumnList });
        this.loadGridData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps) != JSON.stringify(this.props)) {
            if (JSON.stringify(this.props.sharedInventoryFilterObj) != JSON.stringify(prevProps.sharedInventoryFilterObj)) {
                let massAddSearchInfo = Object.assign({}, this.props.sharedInventoryFilterObj);
                massAddSearchInfo.ReceiptBeginDate = StringWms.convertFilterStringToISODate(massAddSearchInfo.ReceiptBeginDate).isoStringUTC;
                massAddSearchInfo.ReceiptEndDate = StringWms.convertFilterStringToISODate(massAddSearchInfo.ReceiptEndDate).isoStringUTC;
                massAddSearchInfo.ExpDateBegin = StringWms.convertFilterStringToISODate(massAddSearchInfo.ExpDateBegin).isoStringUTC;
                massAddSearchInfo.ExpDateEnd = StringWms.convertFilterStringToISODate(massAddSearchInfo.ExpDateEnd).isoStringUTC;

                this.setState({
                    massAddSearchInfo: massAddSearchInfo
                });
            }
        }
    }

    loadGridData = (clearData = true) => {

        kendo.ui.progress($('#proto-mass-add'), false);
        this.setState({
            isFilterRowCountLoading: true,
            showLoadingError: false
        });
        let tmpGridData = this.state.gridData;
        if (clearData == true) {
            tmpGridData = []
        }

        let massAddSearchInfo = Object.assign({}, this.props.sharedInventoryFilterObj);
        massAddSearchInfo.ReceiptBeginDate = StringWms.convertFilterStringToISODate(massAddSearchInfo.ReceiptBeginDate).isoStringUTC;
        massAddSearchInfo.ReceiptEndDate = StringWms.convertFilterStringToISODate(massAddSearchInfo.ReceiptEndDate).isoStringUTC;
        massAddSearchInfo.ExpDateBegin = StringWms.convertFilterStringToISODate(massAddSearchInfo.ExpDateBegin).isoStringUTC;
        massAddSearchInfo.ExpDateEnd = StringWms.convertFilterStringToISODate(massAddSearchInfo.ExpDateEnd).isoStringUTC;
        // on filter update cancel orderDataAxios call if any running
        this.cancelLoadAllData();
        axios.put('/WebUI/Shipping/Orders/MassAddRowCount', {
            customerId:this.props.sharedEditAllocationData.CustomerId,
            facilityId: this.props.sharedEditAllocationData.FacilityId,
            massAddSearchInfo: massAddSearchInfo,
        }).then((response) => {
            this.setState({
                totalInventory: response.data,
                isAllDataLoad: false,
                gridData: tmpGridData,
                invalidQty:true,
                massAddSearchInfo: massAddSearchInfo
            }, () => {
                if(response.data > 0){
                    this.callForGetNewData();
                }
            });
        }).finally(() => {
            this.setState({ isFilterRowCountLoading: false });
        });
    }

    callForGetNewData = () => {
        if (this.state.totalInventory > 0 && this.state.totalInventory <= this.state.firstLoadLimit) {
            this.loadAllRecords(false);
        } else {
            // reset selection of grid on filter data change
            // this.setState({ staticGridData: [] });
            // console.log("resetGridAllMessage : call");
            this.resetGridAllMessage();
        }

    }
    resetGridAllMessage = () => {
        if (this.state.isAllDataLoad) {
            this.setState({
                isNewColumnAdded: true
            });
        }
    }

    onClickLoadAllRecords = () => {
        this.setState({
            overlayScreenShow: true,
        });
        let enableColumns = this.getEnableColumns();
        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
            this.firstFiftyAxiosSource = null;
        }
        this.firstFiftyAxiosSource = axios.CancelToken.source();
        axios.post('/WebUI/Shipping/Orders/MassAddData?sorts=[]&filters=[]&pgnum=1&pgsiz=50', {
            massAddSearchInfo: this.state.massAddSearchInfo,
            customerId:this.props.sharedEditAllocationData.CustomerId,
            facilityId: this.props.sharedEditAllocationData.FacilityId,
            columns: enableColumns
        }, { cancelToken: this.firstFiftyAxiosSource.token }).then((response) => {
            this.firstFiftyAxiosSource = "";
            this.setState({
                gridData: response.data.Data
            },this.loadAllRecords);
        });
    }

    getEnableColumns = () => {
        var defaultParam = ['Key', 'OnHold', 'Available', 'ItemId', 'Qualifier', 'LotNumber','SKU','ExpirationDate','PrimaryUnitType','Received','OnHand','ReceiverId','IsOnHold'];
        let columnList = [];

        let grid = $(this.state.gridDomSelector()).data('kendoGrid');
        // if grid already defined than we get columns from grid
        if (typeof grid != "undefined") {
            columnList = grid.columns;
        } else { // else get column list from metadataObject or default columns
            columnList = GridConfig.getGridDemoColumns(this.inventory_GridHeaders).columns;
        }

        var enableColumns = columnList.map(el => el.field);
        // merge defaultParam and enableColumns without dublicate value
        return [...new Set([...defaultParam, ...enableColumns])];
    }

    setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            this.setState({
                filterText: "",
                fullFilterText: ""
            })
            return;
        }
        var filterConditionTitle = {
            "lte": "Is Less Than Or Equal To",
            "eq": "Is Equal To",
            "lt": "Is Less Than",
            "neq": "Is Not Equal To",
            "gte": "Is Greater Than Or Equal To",
            "gt": "Is Greater Than",
            "startswith": "Starts With",
            "endswith": "Ends With",
            "contains": "Contains",
            "doesnotcontain": "Does Not Contain",
            "isnull": "Is Null",
            "isnotnull": "Is Not Null",
            "isempty": "Is Empty",
            "isnotempty": "Is Not Empty",
            "isnullorempty": "Has No Value",
            "isnotnullorempty": "Has Value",
        }

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;
        var self = this;
        filterList.map((item) => {
            var columnObj = self.state.allColumnList.find(x => x.field === item.field);
            if(columnObj) {
            var title = columnObj.title;
            filterText += title + " : " + item.value + ',';
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            }
        });
        filterText = filterText.slice(0, -1);

        this.setState({
            filterText: filterText,
            fullFilterText: fullFilterText,
        })
    }

    // Load all records of inventory
    loadAllRecords = (isLoaderPopupShow) => {
        this.isApiCanceled = false;
        const sequence = n => {
            let seq = [];
            for (let i = 0; i < n; i++) {
                seq.push(i);
            }
            return seq;
        };
        if (isLoaderPopupShow == false) {
            this.setState({
                isMassGridDataLoading: true,
                gridData: []
            });
        }
        this.axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/Shipping",
            headers: { 'Access-Control-Allow-Origin': '*' },
            cancelToken: this.axiosSource.token
        });

        const MAX_CONCURRENT_REQUESTS = 3;

        let totalRequest = Math.ceil(this.state.totalInventory / this.state.gridPageSize);

        this.setState({
            totalRequest: totalRequest,
        });
        // init your manager.
        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS,GlobalService);
        let enableColumns = this.getEnableColumns();

        Promise.all(sequence(totalRequest).map(i => api.post("/Orders/MassAddData?sorts=[]&filters=[]&pgnum=" + (i + 1) + '&pgsiz=' + this.state.gridPageSize, {
            massAddSearchInfo: this.state.massAddSearchInfo,
            customerId: this.props.sharedEditAllocationData.CustomerId,
            facilityId: this.props.sharedEditAllocationData.FacilityId,
            columns: enableColumns
        }))).then(responses => {
            let protoMassData = responses.map(r => r.data);
            this.getAllResponseData(protoMassData);
        }).catch((error) => {
            console.log("Promise.all catch",  error);
            this.setState({
                showLoadingError: true
            });
            this.cancelLoadAllData();
        }).then(() => {
            if(manager) {
                manager.detach();
            }
        });
    }

    getAllResponseData = (data) => {
        if (!this.isApiCanceled) {
            let allData = [];
            data.forEach(obj => {
                allData = [...obj.Data, ...allData];
            });
            this.inventoryGridData = allData;
            this.setState({
                gridData: allData,
                overlayScreenShow: false,
                isAllDataLoad: true,
                isNewColumnAdded: false,
                isMassGridDataLoading:false
            })
        } else {
            this.setState({
                isMassGridDataLoading: false
            });
        }
    }

    cancelLoadAllData = () => {
        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
            this.firstFiftyAxiosSource = null;
        }
        if(this.axiosSource){
            this.axiosSource.cancel();
            this.axiosSource = null;
        }
        this.isApiCanceled = true;
        this.setState({
            overlayScreenShow: false,
            gridData: [],
            isAllDataLoad: false,
            isMassGridDataLoading: false
        });
    }

    //On grid row selection
    selectRow = (param_kendoGrid) => {
        var SelectedOrders = param_kendoGrid.getNumberOfSelections();
        this.setState({
            disabledAddAllSelected:false
        });
        var checkAvailableZero = param_kendoGrid.valueExists("Available", 0);
        var IsOnHold = param_kendoGrid.valueExists("IsOnHold", true);
        if(SelectedOrders >= 1 && checkAvailableZero){
            this.setState({
                disabledAddAllSelected:true,
                addAllSelectedText : this.translate('Label_Add_All_Selected_Validation'),
            });
        }
        if(IsOnHold){
            this.setState({
                disabledAddAllSelected:true,
                addAllSelectedText : this.translate('Label_Add_All_Selected_Hold_Status_Validataion'),
            });
        }
        if(SelectedOrders == 0){
            this.setState({
                disabledAddAllSelected:true
            });
        }
        this.setState({
            localKendoGrid: param_kendoGrid,
            selectedOrders:SelectedOrders
        });

    }
    onGridDataBound = (e, kendoGrid) => {
        this.setState({
            localKendoGrid: kendoGrid
        })
    }

    // on grid column sort save changes in meta data object
    onSort = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
        metaDataService.setMassAddGridOptionInMetaData(finalSubmission);
    }

    // on grid column reorder save changes in meta data object
    onColumnReorder = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(switchedArray, sortArray, grid.dataSource.filter());
        metaDataService.setMassAddGridOptionInMetaData(finalSubmission);

    }

    // on grid column resize save changes in meta data object
    onColumnResize = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        metaDataService.setMassAddGridOptionInMetaData(finalSubmission);
    }

    // on click grid clear sort menu
    onClickClearSorts = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        metaDataService.setMassAddGridOptionInMetaData(finalSubmission);
    }

    // on click grid reset default grid layout menu
    onClickResetGrid = () => {
        var finalSubmission = {
            "optionsData": {}
        }
        metaDataService.setMassAddGridOptionInMetaData(finalSubmission);
    }

    // on grid filter selection
    onGridFilterApplied = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            this.setFilterAppliedText(dataSource.filter());

            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, dataSource.filter());

            metaDataService.setMassAddGridOptionInMetaData(finalSubmission);

        }, 100);

    }

    // save column hide/show data in meta data
    saveChangesInMetaData = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sort,
            grid.dataSource.filter()
        );
        metaDataService.setMassAddGridOptionInMetaData(finalSubmission);
    }


    eventClickExportGrid = () => {
        $(this.state.gridDomSelector()).getKendoGrid().saveAsExcel();
    }

    eventClickColumns = () => {
        this.setState({
            isOpenColumnModel: true
        })
    }
    onCloseColumnModel = (e) => {
        this.setState({
            isOpenColumnModel: false
        })
    }
    onCheckCombine = (isChecked) => {
        this.setState({
            isCombineRow : isChecked
        })
        if(isChecked){

            this.setState({
                defaultColumns:GridConfig.getCombineGridColumns(this.inventory_GridHeaders).columns,
                schema: GridConfig.getGridCombineSchema().schema,
                allColumnList: GridConfig.getCombineAllColumnList(this.inventory_GridHeaders).columns,
                manageInventoryGridConfig: metaDataService.getMassAddGridOptionInMetaData(),
                invalidQty:true,
            },this.combineRowUsingGridData);
        }else{
            this.setState({
                gridData:this.inventoryGridData,
                totalInventory:this.inventoryGridData.length,
                defaultColumns:GridConfig.getGridDemoColumns(this.inventory_GridHeaders).columns,
                schema: GridConfig.getGridDemoSchema(GlobalService).schema,
                allColumnList: GridConfig.getAllColumnList(this.inventory_GridHeaders).columns,
                invalidQty:true,
                manageInventoryGridConfig: metaDataService.getMassAddGridOptionInMetaData(),
            });
        }
        this.state.localKendoGrid.clearSelections(() => {
            this.state.localKendoGrid.repaintSelections();
        });
    }

    onGridEdit = (event) => {
        var validator = event.container.data('kendoValidator');
        validator.options.messages.custom = "<p>"+this.translate('Label_Greater_Allocation_Warning')+"</p>";
        let inputName = 'PrimaryUnitsToAdd';
        var that = this;
        var input = event.container.find("[name=PrimaryUnitsToAdd]");

        input.keyup(function(){
            setTimeout(() => {
                if($(input).hasClass('k-invalid')){
                    that.setState({
                        invalidQty:true
                    })
                }
            }, 0);
            input.trigger("change");
        });
        validator.options.rules.custom = function (input) {
            if (input.is('[name="' + inputName + '"]')) {
                if (parseFloat(input.val()) > parseFloat(event.model.Available)) {
                    that.setState({
                        invalidQty:true
                    });
                    validator.options.messages.custom = "<p>"+that.translate('Label_Greater_Allocation_Warning')+"</p>";
                    return false;
                }else if(parseFloat(input.val()) === 0){
                    that.setState({
                        invalidQty:true
                    })
                    validator.options.messages.custom = "<p>"+that.translate('Label_Greater_Allocation_Warning_Not_Zero')+"</p>";
                    return false;
                }else if(input.val() && event.model.IsOnHold){
                    that.setState({
                        invalidQty:true
                    })
                    validator.options.messages.custom = "<p>"+that.translate('Label_validation_for_on_hold')+"</p>";
                    return false;
                } else{
                    that.setState({
                        invalidQty:false
                    });
                }
                return true;
            }
            return true;
        }
    }
    enableDisableAddButton = (e) => {
        this.setState({
            addBtnValidationText : this.translate('Label_You_May_Not_Add')
        });
        var field = Object.keys(e.values)[0];
        var newVal = e.values[field];
        if(!newVal){
            delete e.model.dirtyFields[field];
            if (Object.keys(e.model.dirtyFields).length === 0) {
                e.model.dirty = false;
            }
        }
        var invalidQty = true;
        var passing = this.state.localKendoGrid.getDirtyValues();
        setTimeout(() => {
            passing.forEach(value => {
                var PrimaryUnitsToAdd = parseFloat(value.PrimaryUnitsToAdd);
                if(PrimaryUnitsToAdd > value.Available){
                    invalidQty = true;
                    return true;
                }else{
                    if(PrimaryUnitsToAdd){
                        invalidQty = false;
                    }
                }
            });
            this.setState({
                invalidQty:invalidQty
            });
        }, 0);
    }
    combineRowUsingGridData = () => {

        let list = _.cloneDeep(this.inventoryGridData);
        var groups = _.groupBy(list, function (value) {
            return value.SKU + '#' + value.Qualifier + '#' + value.LotNumber + '#' + value.ExpirationDate ;
        });

        var data = _.map(groups, function (group) {
            var Varies = false;
            var IsOnHoldMerge = _.uniqBy(Array.prototype.map.call(group, s => s.IsOnHold));
            var IsOnHold = false;
            if(IsOnHoldMerge.length > 1){
                Varies = true;
                IsOnHold = true;
            }else{
                IsOnHold = IsOnHoldMerge[0];
            }
            _.each(group, function (item) {
                item.Available = parseInt(item.Available);
                if(item.SecondaryAvailable){
                    item.SecondaryAvailable = parseInt(item.SecondaryAvailable)
                } else{
                    item.SecondaryAvailable = 0;
                }
            });
            return {
                Key: Array.prototype.map.call(group, s => s.Key).toString(),
                ItemId: group[0].ItemId,
                SKU: group[0].SKU,
                Qualifier: group[0].Qualifier,
                Upc: group[0].Upc,
                Description: group[0].Description,
                Description2: group[0].Description2,
                Received: _.sumBy(group, 'Received'),
                Available: _.sumBy(group, 'Available'),
                OnHand: _.sumBy(group, 'OnHand'),
                OnHold: group[0].OnHold,
                SecondaryReceived: _.sumBy(group, 'SecondaryReceived') ? _.sumBy(group, 'SecondaryReceived').toFixed(4) : 0,
                SecondaryAvailable: _.sumBy(group, 'SecondaryAvailable') ? _.sumBy(group, 'SecondaryAvailable') : 0 + ' ' + (group[0].SecondaryUom ? group[0].SecondaryUom : ""),
                PrimaryUnitType: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.PrimaryUnitType))).toString(),
                SecondaryUnitType: group[0].SecondaryUnitType,
                InventoryUnitsPerSecondaryUnit: group[0].InventoryUnitsPerSecondaryUnit,
                PalletLabel: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.PalletLabel))).toString(),
                ReceiverId: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.ReceiverId))).toString(),
                ReceiverReferenceNum: group[0].ReceiverReferenceNum,
                ReceivedDate: _.uniqBy(_.compact(Array.prototype.map.call(group, s => kendo.toString(kendo.parseDate(s.ReceivedDate), 'MMM dd yyyy, hh:mm:ss tt')))).toString(),
                LotNumber: group[0].LotNumber,
                SerialNumber: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.SerialNumber))).toString(),
                ExpirationDate: group[0].ExpirationDate,
                Cost: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.Cost))).toString(),
                PurchaseOrderNumber: group[0].PurchaseOrderNumber,
                TrailerNumber: group[0].TrailerNumber,
                LocationName: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.LocationName))).toString(),
                SupplierName: _.uniqBy(_.compact(Array.prototype.map.call(group, s => s.SupplierName))).toString(),
                CustomFields: group[0].CustomFields,
                IsOnHold: IsOnHold,
                Varies:Varies,
                WeightImperial: _.sumBy(group, 'WeightImperial'),
                WeightMetric: _.sumBy(group, 'WeightMetric')
            }

        });
        this.setState({
            gridData: data,
            totalInventory:data.length
        });
    }
    saveProtoAddSelectedAllMass = (event) => {
        var selectedItemIds = this.state.localKendoGrid.getSelections();

        var findItemList = [];
        selectedItemIds.forEach(itemId => {
            var itemIds = itemId.split(',');
            if(itemIds.length > 1){
                itemIds.forEach(value => {
                    var item = this.inventoryGridData.find(function(el){
                        return el.Key == value;
                    });
                    if(item && item.Available > 0){
                        item.PrimaryUnitsToAdd = item.Available;
                        item.PrimaryQuantity = item.PrimaryUnitsToAdd;
                        item.ReceiveItemDetail=[{
                            "ReceiveItemId": item.Key,
                            "Available": item.Available
                        }]
                        findItemList.push(item);
                    }
                })
            }else{

                var item = this.inventoryGridData.find(function(el){
                    return el.Key == itemIds;
                });
                if(item && item.Available > 0){
                    item.PrimaryUnitsToAdd = item.Available;
                    item.PrimaryQuantity = item.PrimaryUnitsToAdd;
                    item.ReceiveItemDetail=[{
                        "ReceiveItemId": item.Key,
                        "Available": item.Available
                    }]
                    findItemList.push(item);
                }
            }
        });
        this.setState({
            massAddAllLoading:true
        });
        this.saveMassAddData(findItemList,true);
    }
    saveProtoAddMass = (event) => {
        var passing = this.state.localKendoGrid.getDirtyValues();
        var findItemList = [];
        if(this.state.isCombineRow){
            passing.forEach(value => {
                var tmpIds = value.Key.split(',');
                var PrimaryUnitsToAdd = parseInt(value.PrimaryUnitsToAdd);
                // if(tmpIds.length > 1){
                    tmpIds.forEach(id => {
                        var item = this.inventoryGridData.find(function(el){
                            return el.Key == id;
                        });
                        if(item.Available > 0 && PrimaryUnitsToAdd > 0){
                            if(item.Available > PrimaryUnitsToAdd){
                                item.PrimaryUnitsToAdd = PrimaryUnitsToAdd;
                                PrimaryUnitsToAdd = 0;
                            }else{
                                item.PrimaryUnitsToAdd = item.Available;
                                PrimaryUnitsToAdd = PrimaryUnitsToAdd - item.Available;
                            }
                            item.PrimaryQuantity = item.PrimaryUnitsToAdd;
                            item.ReceiveItemDetail=[{
                                "ReceiveItemId": item.Key,
                                "Available": item.Available
                            }]
                            if(item.PrimaryQuantity){
                                findItemList.push(item);
                            }
                        }
                    });
                // }
            });
        }else{
            passing.forEach(value => {
                value.PrimaryQuantity = value.PrimaryUnitsToAdd;
                value.ReceiveItemDetail=[{
                    "ReceiveItemId": value.Key,
                    "Available": value.Available
                }];
                if(value.PrimaryQuantity){
                    findItemList.push(value);
                }
            });
        }
        this.setState({
            massAddLoading:true
        });
        this.saveMassAddData(findItemList,false);
    }
    saveMassAddData = (findItemList,isAll) =>{
        manageOrderServices.massOrderLineItems({
            orderId: this.props.sharedEditAllocationData.OrderId,
            itemsUri: this.props.sharedEditAllocationData.OrderItemsUri,
            details: findItemList
        }).then((response) => {
            if (response && (response.status == 200 || response.status == 204)) {
                GlobalService.notification('success', this.translate('Label_Line_Item_Save_Success'));
                this.loadGridData();
                // method call to set discard button for react old order version
                if(this.props.onGridItemChange){
                    this.props.onGridItemChange(true);
                }
                this.props.onResetGridAndSelection();
            }

        }).finally(() => {
            if(isAll){
                this.setState({
                    massAddAllLoading:false
                });
            }else{
                this.setState({
                    massAddLoading:false
                });
            }
        });
    }

    onLockedTableBindKeyEvent = (e) =>{
        var that = this;
        $(document).off('keyup', this.state.gridDomSelector()+' .k-grid-content-locked table, '+this.state.gridDomSelector()+' .k-grid-content table');
        $(document).on('keyup', this.state.gridDomSelector()+' .k-grid-content-locked table, '+this.state.gridDomSelector()+' .k-grid-content table', function (e) {
            if (e.keyCode === kendo.keys.TAB || e.keyCode === kendo.keys.ENTER) {
                e.preventDefault();
                e.stopPropagation();
                var grid =  $(that.state.gridDomSelector()).data('kendoGrid');
                var current = grid.current();
                if (!current.hasClass("editable-cell")) {
                    var nextCell;
                    if (e.shiftKey) {
                        nextCell = current.prevAll(".editable-cell");
                        if (!nextCell[0]) {
                            //search the next row
                            var prevRow = current.parent();
                            var indexRow = $("tr", $(that.state.gridDomSelector()).find('.k-grid-content tbody')).index(prevRow);
                            var nextCell = $("#GridManageInventory .k-grid-content-locked tbody tr:eq(" + indexRow + ")").children(".editable-cell:first");
                        }
                    } else {
                        nextCell = current.nextAll(".editable-cell");
                        if (!nextCell[0]) {
                            //search the next row
                            var nextRow = current.parent().next();

                            var indexRow = $("tr", $(that.state.gridDomSelector()).find('.k-grid-content tbody')).index(nextRow);
                            // var nextCell = nextRow.children(".editable-cell:first");
                            var nextCell = $("#GridManageInventory .k-grid-content-locked tbody tr:eq(" + indexRow + ")").children(".editable-cell:first");
                        }
                    }
                    grid.current(nextCell);
                    grid.editCell(nextCell[0]);
                } else if (e.keyCode === kendo.keys.ENTER){
                    var nextCell = current.nextAll(".editable-cell");
                    if(!$(e.target).hasClass('k-invalid')){
                        if (!nextCell[0]) {
                            var nextRow = current.parent().next();
                            var indexRow = $("tr", $(that.state.gridDomSelector()).find('.k-grid-content-locked tbody')).index(nextRow);
                            var nextCell = $("#GridManageInventory .k-grid-content-locked tbody tr:eq(" + indexRow + ")").children(".editable-cell:first");
                        }
                        grid.current(nextCell);
                        grid.editCell(nextCell[0]);
                    }
                }
            }
        });
    }
    render() {
        return (
            <div className="inventory-grid">
                <div className="wms-inventory-grid-holder">
                    <div className="wms-warning-wrapper in-grid">
                        <div className="wms-sprite fa fa-exclamation-triangle"></div>
                        <span>
                            {this.translate('Label_warning_for_total_weight_and_volume_add_edit')}
                        </span>
                    </div>
                    <VirtualGridComponent
                        loading={this.state.isFilterRowCountLoading || this.state.isMassGridDataLoading}
                        id={this.manageInventoryGridId}
                        isNewColumnAdded={this.state.isNewColumnAdded}
                        gridName={'GridManageInventory'}
                        windowId={this.props.windowId}
                        wmsSelector={'GridManageInventory'}
                        gridKeySelection={'Key'}
                        virtual={true}
                        totalResult={this.state.totalInventory}
                        staticGridData={this.state.gridData}
                        showCombineCheckbox={true}
                        exportable={true}
                        isExportSelectedData={true}
                        onLockedTableBindKeyEvent={this.onLockedTableBindKeyEvent}
                        exportExcelObj={{
                            fileName: "inventoryGridExport-" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + ".xlsx",
                            allPages: true,
                            filterable: true
                        }}
                        csvExportFileName={'inventoryGridExport'}
                        eventClickExportGrid={this.eventClickExportGrid}
                        onCheckCombine={this.onCheckCombine}
                        allColumns={this.state.allColumnList}
                        defaultColumns={this.state.defaultColumns}
                        schema={this.state.schema}
                        showGridSummary={true}
                        onSelection={this.selectRow}
                        callForGetNewData={this.callForGetNewData}
                        metaDataFieldsName={''}
                        metaGridConfig={this.state.manageInventoryGridConfig}
                        eventClickColumns={this.eventClickColumns}
                        onGridFilterApplied={this.onGridFilterApplied}
                        filterText={this.state.filterText}
                        fullFilterText={this.state.fullFilterText}
                        loadAllRecords={this.onClickLoadAllRecords}
                        isAllDataLoad={this.state.isAllDataLoad}
                        onDataBound={this.onGridDataBound}
                        onSort={this.onSort}
                        onColumnReorder={this.onColumnReorder}
                        onColumnResize={this.onColumnResize}
                        onClickClearSorts={this.onClickClearSorts}
                        onClickResetGrid={this.onClickResetGrid}
                        onGridSave={this.enableDisableAddButton}
                        onEdit={this.onGridEdit}
                        virtualGridDataLimit={this.state.firstLoadLimit}
                        showLoadingError={this.state.showLoadingError}
                        menu={[
                                {
                                "name": this.translate('Label_Add'),
                                "wmsSelector": this.state.selector + "MassAdded",
                                "value": [],
                                "disabled": this.state.invalidQty,
                                "tooltipText": this.state.addBtnValidationText,
                                "icon": "fa-plus-circle",
                                "color": "green",
                                "showToolTip":true,
                                'isContextMenu': false,
                                'isButtonMenu': true,
                                'loading':this.state.massAddLoading,
                                'clickFunc': this.saveProtoAddMass
                            },
                            {
                                "name": this.translate('Label_Add_Selected_All'),
                                "wmsSelector": this.state.selector + "MassAddSelectAll",
                                "value": [],
                                "disabled": this.state.disabledAddAllSelected,
                                "icon": "fa-plus-circle",
                                "color": "green",
                                'isContextMenu': false,
                                "showToolTip":true,
                                "tooltipText": this.state.addAllSelectedText,
                                'isButtonMenu': true,
                                'loading':this.state.massAddAllLoading,
                                'clickFunc': this.saveProtoAddSelectedAllMass
                            }
                        ]}
                        gridEditable={true}
                    />
                </div>




                {this.state.isOpenColumnModel && (
                <AddEditColumnModel
                    windowId={this.props.windowId}
                    id={"addEditColumnFindInventory"}
                    height={420}
                    onCloseColumnModel={this.onCloseColumnModel}
                    isOpenColumnModel={this.state.isOpenColumnModel}
                    allSortColumnList={this.state.allSortColumnList}
                    gridId={this.manageInventoryGridId}
                    updateGridData={this.callForGetNewData}
                    saveChangesInMetaData={this.saveChangesInMetaData}
                    isAnotherModelOpen={true}
                    hideColumns={'PrimaryUnitsToAdd'} // pass comma saperated string to hide columns in Add/Edit model list
                />
                )}
                {this.state.overlayScreenShow && (
                    <LoadingModel
                        sharedOpenLoadingmodel={this.state.overlayScreenShow}
                        onCancelLoadAllData={this.cancelLoadAllData}
                        sharedTotalRequest={this.state.totalRequest}
                        isAnotherModelOpen={true}
                    />
                )}
            </div>
        )
    }
}