import React, { useState, useEffect } from "react";
import WmsModel from "./../react/components/WmsModel/WmsModel";
import WmsCollapse from "./../react/components/WmsCollapse/WmsCollapse";
import WmsInput from "./../react/components/WmsInput/WmsInput";
import WmsButton from "./../react/components/WmsButton/WmsButton";
import WmsFormGrid from "./../react/components/WmsFormGrid/WmsFormGrid";
import * as GridConfig from "./adjust-inventory-grid-configuration";
import "./adjustInventory.scss";
import _ from "lodash";

declare var window: any;
const { $ } = window;
interface Props {
    onCloseSelectReceiveItemModel: any;
    isOpenSelectReceiveItemModel: boolean;
    currentAdjustmentItem: any;
    windowId: string;
    wmsSelector: string;
    applyingAdjustment: boolean;
    addReduceInventoryAdjustmentItems: any;
    availableReceiveItemData: any;
    itemIsIntegral: boolean;
}
const defaultSelectedReceivedItem = {
    ReceiptDate: "",
    ReceiverId: "",
    ExpirationDate: "",
    MovableUnitLabel: "",
    LocationName: "",
    PrimaryAvailable: 0,
    ReceiveItemId: "",
    LotNumber: "",
    SerialNumber: "",
};
const SelectReceiveItemModel: React.FC<Props> = (props: Props): any => {
    const availableReceiveItemGridColumns = GridConfig.getAvailableReceiveItemGridColumns();
    const availableReceiveItemGridSchema = useState(GridConfig.getAvailableReceiveItemGridSchema());
    const availableReceiveItemGridId = props.wmsSelector + "AvailableReceiveItemGrid";

    const addedReceivedItemGridColumns = GridConfig.getselectedReceivedItemGridColumns();
    const addedReceivedItemGridSchema = useState(GridConfig.getselectedReceivedItemridSchema());

    const [availableReceiveItemData, setAvailableReceiveItemData] = useState(props.availableReceiveItemData.Candidates);
    const [selectedAvailableReceiveRow, setSelectedAvailableReceiveRow] = useState(defaultSelectedReceivedItem);
    const [selectedReceivedItemData, setSelectedReceivedItemData] = useState(
        props.availableReceiveItemData.ProposedAllocations
    );

    const [totalAdjustment, setTotalAdjustment] = useState(props.currentAdjustmentItem.Qty);
    const [qtyToRemove, setQtyToRemove] = useState(0);
    const [rowIndex, setRowIndex] = useState(0);

    const changeQtyToRemove = (event) => {
        setQtyToRemove(
            event.target.value !== ""
                ? props.itemIsIntegral
                    ? parseInt(event.target.value)
                    : event.target.value
                : null
        );
    };
    const onselectGridRow = (KendoGrid: any) => {
        let grid = $("#" + availableReceiveItemGridId).data("kendoGrid");
        var data = grid.dataItem(grid.select());
        if (data) {
            setSelectedAvailableReceiveRow(data);
        } else {
            setSelectedAvailableReceiveRow(defaultSelectedReceivedItem);
        }
    };
    const addReceiveItemTORemove = () => {
        var rowToAdd = {
            rowIndex: rowIndex,
            LotNumber: selectedAvailableReceiveRow.LotNumber,
            LocationName: selectedAvailableReceiveRow.LocationName,
            SerialNumber: selectedAvailableReceiveRow.SerialNumber,
            ExpirationDate: selectedAvailableReceiveRow.ExpirationDate,
            ReceiverId: selectedAvailableReceiveRow.ReceiverId,
            MovableUnitLabel: selectedAvailableReceiveRow.MovableUnitLabel,
            Allocated: qtyToRemove,
            ReceiveItemId: selectedAvailableReceiveRow.ReceiveItemId,
        };
        setSelectedReceivedItemData((prevState: any) => [...prevState, rowToAdd]);

        setSelectedAvailableReceiveRow(defaultSelectedReceivedItem);
        setQtyToRemove(0);
        var selectedAvailableReceiveRowdata = _.cloneDeep(availableReceiveItemData);
        var index = availableReceiveItemData.findIndex(
            (el) => el.ReceiveItemId === selectedAvailableReceiveRow.ReceiveItemId
        );
        if (props.itemIsIntegral) {
            setTotalAdjustment((ps: any) => ps - qtyToRemove);
            selectedAvailableReceiveRowdata[index].PrimaryAvailable -= qtyToRemove;
        } else {
            setTotalAdjustment((ps: any) => (parseFloat(ps) - parseFloat(qtyToRemove as any)).toFixed(2));
            selectedAvailableReceiveRowdata[index].PrimaryAvailable = (
                parseFloat(selectedAvailableReceiveRowdata[index].PrimaryAvailable) - parseFloat(qtyToRemove as any)
            ).toFixed(2);
        }

        setAvailableReceiveItemData(selectedAvailableReceiveRowdata);
        setRowIndex(rowToAdd.rowIndex + 1);
    };

    const saveSelectReceiveItem = () => {
        props.addReduceInventoryAdjustmentItems(selectedReceivedItemData);
    };

    useEffect(() => {
        $(document).off("click", ".remove-selected-available-item");
        $(document).on("click", ".remove-selected-available-item", function () {
            var rowIndex = parseInt($(this).data("id"));
            var newItems = _.cloneDeep(selectedReceivedItemData);
            var selectedItem = newItems.find((i) => i.rowIndex === rowIndex);
            newItems = newItems.filter((i) => i.rowIndex !== rowIndex);
            setSelectedReceivedItemData(newItems);

            var selectedAvailableReceiveRowdata = _.cloneDeep(availableReceiveItemData);
            var index = availableReceiveItemData.findIndex((el) => el.ReceiveItemId === selectedItem.ReceiveItemId);
            if (props.itemIsIntegral) {
                selectedAvailableReceiveRowdata[index].PrimaryAvailable =
                    parseInt(selectedAvailableReceiveRowdata[index].PrimaryAvailable) +
                    parseInt(selectedItem.Allocated);
                setAvailableReceiveItemData(selectedAvailableReceiveRowdata);
                setTotalAdjustment((ps: number) => ps + parseInt(selectedItem.Allocated));
            } else {
                selectedAvailableReceiveRowdata[index].PrimaryAvailable = (
                    parseFloat(selectedAvailableReceiveRowdata[index].PrimaryAvailable) +
                    parseFloat(selectedItem.Allocated)
                ).toFixed(2);
                setAvailableReceiveItemData(selectedAvailableReceiveRowdata);
                setTotalAdjustment((ps: number) =>
                    (parseFloat(ps as any) + parseFloat(selectedItem.Allocated)).toFixed(2)
                );
            }
            return false;
        });
        if (props.availableReceiveItemData.ProposedAllocations.length > 0) {
            setTotalAdjustment(0);
        }
    }, [selectedReceivedItemData, availableReceiveItemData]);

    return (
        <WmsModel
            id="SelectReceiveItemModel"
            title={window.translate("select_receive_item")}
            width={800}
            height={800}
            onCloseFunc={props.onCloseSelectReceiveItemModel}
            isOpen={props.isOpenSelectReceiveItemModel}
            customClass="wms-model-new-ui-wrapper"
        >
            <div className="model-content-wrapper">
                <div className="charge-detail-wrapper">
                    <div className="display-inline">
                        <div className="left-content-wrapper width-30">
                            <div className="content">
                                <label>SKU</label>
                                <span>{props.currentAdjustmentItem.SKU}</span>
                            </div>
                            <div className="content">
                                <label>Qualifier</label>
                                <span>{props.currentAdjustmentItem.Qualifier}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <WmsCollapse
                    id={"SelectReceiveItemModelCollapse"}
                    headerLabel={window.translate("available_receive_item")}
                    showCollapseText={false}
                >
                    <WmsFormGrid
                        id={availableReceiveItemGridId}
                        wmsSelector={availableReceiveItemGridId}
                        gridKeySelection={""}
                        columns={availableReceiveItemGridColumns}
                        staticGridData={availableReceiveItemData}
                        schema={availableReceiveItemGridSchema}
                        isColumnsResizable={true}
                        isColumnsReorderable={true}
                        isColumnMenuenable={false}
                        isColumnsSortable={true}
                        parentClassName={"adjust-inventory-available-receive-item-grid"}
                        showResetMenu={false}
                        onSelection={onselectGridRow}
                        IsMultipleSelectable={true}
                    />
                    <div className="display-inline">
                        <div className="left-content-wrapper width-30">
                            <WmsInput
                                id="TotalAdjustment"
                                inputName="TotalAdjustment"
                                wmsSelector={props.wmsSelector + "TotalAdjustment"}
                                onChangeFunc={() => {}}
                                label={window.translate("Total_Adjustment")}
                                value={totalAdjustment}
                                extraWrapperClass="width-60"
                                disabled={true}
                            />

                            <div className="display-inline">
                                <WmsInput
                                    id="qtyToremove"
                                    inputName="qtyToremove"
                                    wmsSelector={props.wmsSelector + "qtyToremove"}
                                    onChangeFunc={changeQtyToRemove}
                                    label={window.translate("QTY_to_Remove")}
                                    value={qtyToRemove}
                                    extraWrapperClass="width-60"
                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                    required={true}
                                />

                                <WmsButton
                                    label={window.translate("Label_Add")}
                                    wmsSelector={props.wmsSelector + "addReceiveItemTORemove"}
                                    onClickFunc={addReceiveItemTORemove}
                                    icon={"fa-plus"}
                                    disabled={
                                        qtyToRemove <= 0 ||
                                        totalAdjustment < qtyToRemove ||
                                        selectedAvailableReceiveRow.ReceiveItemId === "" ||
                                        selectedAvailableReceiveRow.PrimaryAvailable < qtyToRemove
                                    }
                                    loading={false}
                                    parentClassName={"add-receive-item-button"}
                                />
                            </div>
                        </div>
                        <div className="right-content-wrapper width-70">
                            <WmsFormGrid
                                id={"addedReceivedItemGrid"}
                                wmsSelector={"addedReceivedItemGrid"}
                                gridKeySelection={""}
                                columns={addedReceivedItemGridColumns}
                                staticGridData={selectedReceivedItemData}
                                schema={addedReceivedItemGridSchema}
                                isColumnsResizable={true}
                                isColumnsReorderable={true}
                                isColumnMenuenable={false}
                                isColumnsSortable={true}
                                parentClassName={"adjust-inventory-selected-receive-item-grid"}
                                showResetMenu={false}
                                onSelection={() => {}}
                                IsMultipleSelectable={true}
                            />
                        </div>
                    </div>
                </WmsCollapse>
            </div>
            <div className="footer-btn-holder">
                <WmsButton
                    label={window.translate("RoleManager_Label_Back")}
                    wmsSelector={props.wmsSelector + "SelectReceiveItemModelDiscardExit"}
                    onClickFunc={props.onCloseSelectReceiveItemModel}
                    buttonType="orange"
                    icon={"fa-angle-left"}
                />
                <WmsButton
                    label={window.translate("Label_Save")}
                    wmsSelector={props.wmsSelector + "SelectReceiveItemModelSaveExit"}
                    onClickFunc={saveSelectReceiveItem}
                    icon={"fa-floppy-o"}
                    disabled={totalAdjustment > 0}
                    loading={props.applyingAdjustment}
                />
            </div>
        </WmsModel>
    );
};

SelectReceiveItemModel.propTypes = {};

SelectReceiveItemModel.defaultProps = {};

export default React.memo(SelectReceiveItemModel);
