export const getInitialPrintMenu = (initialPrintMenuResponse, params, menuClickEvent, callFrom) => {

    var translate = window.translate;

    var updatedPrintMenu = {
        PrintData: [],
        SmartParcelData: [],
    }

    if (initialPrintMenuResponse.length > 0 || initialPrintMenuResponse != null || typeof initialPrintMenuResponse != undefined) {
        var TempParcelData = [];

        initialPrintMenuResponse.forEach((item, i) => {
            var fnName = params.fnNames[i];
            var tempObj = {};

            if (item.RdlName === "MasterBOL" && callFrom == "manageOrder") {
                return; // skipping MasterBOL menu for manageOrder
            }

            tempObj = {
                "name": item.CategoryName,
                "value": [],
                "disabled": (params.SelectedOrders < 1) || !params.Rights.hasOwnProperty('orders.docs'),
                "disabledText": translate("order_Document_Permission_note"),
                'isContextMenu': true,
                'isButtonMenu': true,
                "wmsSelector": params.Selector + item.RdlName,
                "onClickFunc": menuClickEvent,
                'wmsid': item.RdlName
            };

            if (item.RdlName === "BOL") {
                tempObj.name = translate("Label_Generate_BOL");
                tempObj.disabled = ((params.SelectedOrders < 1) || !params.SameFacility || !params.SameCustomer || !params.Rights.hasOwnProperty('orders.docs'));
                tempObj.disabledText = !params.Rights.hasOwnProperty('orders.docs') ? translate("order_Document_Permission_note") : translate("note_bill_of_lading");
                // if (params.SelectedOrders != 1) {
                //     tempObj.disabled = true;
                //     tempObj.disabledText = translate("note_bill_of_lading_multipleOrder");
                // }
                // else if(!params.SameFacility || !params.SameCustomer) {
                //     tempObj.disabled = true;
                //     tempObj.disabledText = translate("note_bill_of_lading");
                // }

            } else if (item.RdlName === "MasterBOL") {
                tempObj.name = translate("Label_Generate_MasterBOL");
                if (callFrom == "findOrder") {
                    tempObj.disabled = ((params.SelectedOrders <= 1) || !params.SameFacility || !params.SameCustomer || !params.FullyAllocated || !params.Rights.hasOwnProperty('orders.docs'));
                    tempObj.disabledText = params.SelectedOrders <= 1 ? translate("note_master_bill_of_lading") : translate("Label_Tooltip_OverAllocatedOrders_EditMasterBOL") + params.SelectedOrderIds;
                    tempObj.disabledText = !params.Rights.hasOwnProperty('orders.docs') ? translate("order_Document_Permission_note") : tempObj.disabledText;
                } else {
                    tempObj.disabled = (params.SelectedOrders < 1 || !params.Rights.hasOwnProperty('orders.docs'));
                }
            }
            if (item.RdlName !== "SmallParcel") {
                // && item.RdlName !== "BOL"
                if (item.hasOwnProperty('Reports') && item.RdlName !== "ShippingLabel") {
                    item.Reports.forEach((report, i) => {
                        tempObj.onClickFunc = () => null;
                        tempObj.value.push({
                            "name": report.Name,
                            "value": [],
                            "disabled": (tempObj.disabled == true || !params.Rights.hasOwnProperty('orders.docs')) ? true : false,
                            "disabledText": translate("order_Document_Permission_note"),
                            'isContextMenu': true,
                            'isButtonMenu': true,
                            "wmsSelector": params.Selector + fnName + (i + 1),
                            "onClickFunc": menuClickEvent,
                            'wmsid': fnName,
                            'runReport': report.TemplatedRunner,
                            'rdlName': item.RdlName,
                        });
                    });
                }

                updatedPrintMenu.PrintData.push(tempObj);

                // Add new option for edit bill of lading
                if (item.RdlName == "BOL") {

                    let appnedEditBolOpt = {
                        "name": translate('Label_Edit_Bill_of_Lading'),
                        "value": [],
                        "disabled": (params.SelectedOrders != 1 || !params.Rights.hasOwnProperty('orders.docs')),
                        "disabledText": !params.Rights.hasOwnProperty('orders.docs') ? translate("order_Document_Permission_note") : translate("note_edit_bill_of_lading"),
                        'isContextMenu': true,
                        'isButtonMenu': true,
                        "wmsSelector": params.Selector + item.RdlName,
                        "onClickFunc": menuClickEvent,
                        'wmsid': 'EditBol'
                    }
                    updatedPrintMenu.PrintData.push(appnedEditBolOpt);

                }
            } else {

                if (item.CategoryName == "Reprint Shipping Label") {
                    tempObj.value = [],
                    tempObj.disabled = (params.ParcelLabelType == 0 || !params.isSamePrintedFormat),
                    tempObj.disabledText = translate("note_reprint_shipping_label");
                    tempObj.isContextMenu =true,
                    tempObj.isButtonMenu =true,
                    tempObj.wmsSelector = params.Selector + "reprintShippingLabel",
                    tempObj.onClickFunc = menuClickEvent,
                    tempObj.wmsid = "reprintShippingLabel"
                    // tempObj.isContextMenu = (params.ParcelLabelType != 0);
                    // tempObj.isButtonMenu = (params.ParcelLabelType != 0);
                    // tempObj.disabled = (params.ParcelLabelType == 0 || !params.isSamePrintedFormat);
                    // tempObj.disabledText = translate("note_reprint_shipping_label");
                    // if (params.ParcelLabelType == 2) {
                    //     tempObj.value.push({
                    //         "name": "Reprint (Zpl)",
                    //         "value": [],
                    //         "disabled": (params.ParcelLabelType == 2) ? false : true,
                    //         // 'isContextMenu': (params.ParcelLabelType != 1),
                    //         // 'isButtonMenu': (params.ParcelLabelType != 1),
                    //         "wmsSelector": params.Selector + "ReprintZpl",
                    //         "onClickFunc": menuClickEvent,
                    //         'wmsid': "reprintZpl",
                    //         'runReport': "/WebUI/Shipping/FindOrders/ParcelReprintZplContents",
                    //         'runnerKey': item.hasOwnProperty('Reports') ? item.Reports[0].TemplatedRunnerKey : ''
                    //     });

                    // } else {
                    //         tempObj.onClickFunc = () => null;
                    //         tempObj.value.push({
                    //             "name": "Reprint (Pdf)",
                    //             "value": [],
                    //             "disabled": (tempObj.disabled == true) ? true : false,
                    //             "disabledText": translate("order_Document_Permission_note"),
                    //             'isContextMenu': true,
                    //             'isButtonMenu': true,
                    //             "wmsSelector": params.Selector + fnName + (i + 1),
                    //             "onClickFunc": menuClickEvent,
                    //             'wmsid': "reprintPdf",
                    //     });
                    // }

                } else if (item.CategoryName == "Return Label") {

                    tempObj.onClickFunc = () => null;
                    tempObj.isButtonMenu = params.Rights.hasOwnProperty('Shipping.SmallParcelReturnLabelEnabled')
                    // tempObj.isContextMenu = (params.ParcelLabelType != 0);
                    // tempObj.isButtonMenu = (params.ParcelLabelType != 0);
                    tempObj.disabled = (params.ParcelLabelType == 0);
                    tempObj.disabledText = translate("note_return_label");

                    tempObj.value.push({
                        "name": "Return (Zpl)",
                        "value": [],
                        "disabled": tempObj.disabled ? true : (params.ParcelLabelType != 1 && params.ParcelLabelType != 2),
                        // 'isContextMenu': (params.ParcelLabelType != 1),
                        // 'isButtonMenu': (params.ParcelLabelType != 1),
                        "wmsSelector": params.Selector + "ReturnZpl",
                        "onClickFunc": menuClickEvent,
                        'wmsid': "returnZpl",
                        'runReport': "/WebUI/Shipping/FindOrders/ParcelReturnZplContents",
                        'runnerKey': item.hasOwnProperty('Reports') ? item.Reports[0].TemplatedRunnerKey : ''
                    });

                    tempObj.value.push({
                        "name": "Return (Pdf)",
                        "value": [],
                        "disabled": tempObj.disabled ? true : (params.ParcelLabelType != 1 && params.ParcelLabelType != 2),
                        // 'isContextMenu': (params.ParcelLabelType != 2),
                        // 'isButtonMenu': (params.ParcelLabelType != 2),
                        "wmsSelector": params.Selector + "ReturnPdf",
                        "onClickFunc": menuClickEvent,
                        'wmsid': "returnPdf",
                        'runReport': "/WebUI/Shipping/FindOrders/ParcelReturnPdfContent",
                        'runnerKey': item.hasOwnProperty('Reports') ? item.Reports[0].TemplatedRunnerKey : ''
                    });
                }

                TempParcelData.push(tempObj);
            }
        });

        updatedPrintMenu.PrintData.unshift({
            "name": translate('Label_Attach_Files'),
            "value": [],
            "disabled": (params.SelectedOrders != 1),
            "disabledText": translate("note_attach_files"),
            'isContextMenu': params.Rights.hasOwnProperty('orders.attachdocs') && params.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled'),
            'isButtonMenu': params.Rights.hasOwnProperty('orders.attachdocs') && params.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled'),
            "wmsSelector": params.Selector + "AttachFiles",
            "onClickFunc": menuClickEvent,
            'wmsid': "attachFiles"
        });
        updatedPrintMenu.PrintData.splice(1, 0, {
            "name": translate('Label_Pick_Ticket'),
            "value": [],
            "disabled": ((params.SelectedOrders < 1) || !params.EnablePickOption || !params.Rights.hasOwnProperty('orders.docs')),
            "disabledText": !params.Rights.hasOwnProperty('orders.docs') ? translate("order_Document_Permission_note") : translate("Tooltip_FullyAllocated_TplSettingPrintOverAllocOrders"),
            'isContextMenu': true,
            'isButtonMenu': true,
            "wmsSelector": params.Selector + "PickTicket",
            "onClickFunc": menuClickEvent,
            'wmsid': "pickTicket"
        });
        if (callFrom == "findOrder") {
            updatedPrintMenu.PrintData.splice(1, 0, {
                "name": translate('Label_Master_Pick_Ticket'),
                "value": [],
                "disabled": ((params.SelectedOrders <= 1) || !params.SameFacility || !params.SameCustomer || !params.SameBatchId || !params.Rights.hasOwnProperty('orders.docs')),
                "disabledText": !params.Rights.hasOwnProperty('orders.docs') ? translate("order_Document_Permission_note") : translate("note_Master_Pick_Ticket"),
                'isContextMenu': true,
                'isButtonMenu': true,
                "wmsSelector": params.Selector + "MasterPickTicket",
                "onClickFunc": menuClickEvent,
                'wmsid': "masterPickTicket"
            });
            updatedPrintMenu.PrintData.splice(6, 0, {
                "name": translate('Label_Edit_Master_BOL'),
                "value": [],
                "disabled": ((params.SelectedOrders <= 1) || !params.SameFacility || !params.SameCustomer || !params.FullyAllocated || !params.Rights.hasOwnProperty('orders.docs')),
                "disabledText": !params.Rights.hasOwnProperty('orders.docs') ?
                    translate("order_Document_Permission_note") :
                    (
                        params.SelectedOrders <= 1 ? translate("note_Edit_Master_BOL") : translate("Label_Tooltip_OverAllocatedOrders_EditMasterBOL") + params.SelectedOrderIds
                    ),
                'isContextMenu': true,
                'isButtonMenu': true,
                "wmsSelector": params.Selector + "EditMasterBOL",
                "onClickFunc": menuClickEvent,
                'wmsid': "editMasterBol"
            });
        }
        updatedPrintMenu.PrintData.splice(1, 0, {
            "name": translate('Label_Attach_Files_To_Batch'),
            "value": [],
            "disabled": !params.Rights.hasOwnProperty('admin.attachfiles'),
            'isContextMenu': params.Rights.hasOwnProperty('orders.attachdocs') && params.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled'),
            'isButtonMenu': params.Rights.hasOwnProperty('orders.attachdocs') && params.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled'),
            "wmsSelector": params.Selector + "AttachFilesToBatch",
            "onClickFunc": menuClickEvent,
            'wmsid': "attachFilesToBatch"
        });
        updatedPrintMenu.PrintData.push({
            "name": "Packing Slips",
            "value": [],
            "disabled": (params.SelectedOrders < 1 || !params.Rights.hasOwnProperty('orders.docs')),
            "disabledText": translate("order_Document_Permission_note"),
            'isContextMenu': true,
            'isButtonMenu': true,
            "wmsSelector": params.Selector + "RunReportPackingSlips",
            "onClickFunc": menuClickEvent,
            'wmsid': "runReportPackingSlips"
        });
        if (callFrom == "findOrder") {
            updatedPrintMenu.PrintData.push({
                "name": translate('Label_Shipping_Manifest'),
                "value": [],
                "disabled": ((params.SelectedOrders < 1) || !params.FullyAllocated || !params.Rights.hasOwnProperty('orders.docs')),
                "disabledText": !params.Rights.hasOwnProperty('orders.docs') ? translate("order_Document_Permission_note") : translate("note_Shipping_Manifest") + params.SelectedOrderIds,
                'isContextMenu': true,
                'isButtonMenu': true,
                "wmsSelector": params.Selector + "ShippingManifest",
                "onClickFunc": menuClickEvent,
                'wmsid': "shippingManifest"
            });
        }
        updatedPrintMenu.PrintData.push({
            "name": "Send ASN",
            "value": [],
            "disabled": ((params.SelectedOrders < 1) || !params.SameFacility || !params.SameCustomer || !params.FullyAllocated || !params.TplSettings.Integrations.EdiEnabled || !params.Rights.hasOwnProperty('orders.docs')),
            "disabledText": !params.Rights.hasOwnProperty('orders.docs') ? translate("order_Document_Permission_note") : translate("note_Send_Asn"),
            'isContextMenu': true,
            'isButtonMenu': params.Rights.hasOwnProperty('orders.manage.sendasn'),
            "wmsSelector": params.Selector + "SendASN",
            "onClickFunc": menuClickEvent,
            'wmsid': "sendASN"
        });


        updatedPrintMenu.SmartParcelData.push({
            "name": translate('Label_Smart_Parcel'),
            "value": [],
            "disabled": params.SelectedOrders < 1 || params.isSmartParcelDisable,
            "disabledText": translate('note_smart_parcel'),
            'isContextMenu': params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            'isButtonMenu': params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            "wmsSelector": params.Selector + "SmartParcelSubMenu",
            "onClickFunc": menuClickEvent,
            'wmsid': "smartParcel"
        });
        updatedPrintMenu.SmartParcelData.push({
            "name": translate('Label_Recreate_IntlDocs'),
            "value": [],
            "disabled": params.SelectedOrders < 1,
            'isContextMenu': params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            'isButtonMenu': params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            "wmsSelector": params.Selector + "RecreateIntlDocs",
            "onClickFunc": menuClickEvent,
            'wmsid': "recreateIntlDocs"
        });
        updatedPrintMenu.SmartParcelData.push({
            "name": translate('Label_Void_Shipping_Label'),
            "value": [],
            "disabled": params.SelectedOrders < 1,
            'isContextMenu': params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            'isButtonMenu': params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            "wmsSelector": params.Selector + "VoidShippingLabel",
            "onClickFunc": menuClickEvent,
            'wmsid': "voidShippingLabel"
        });
        updatedPrintMenu.SmartParcelData.push({
            "name": translate('Label_End_of_Day_Request'),
            "value": [{
                "name": translate('Label_Create_End_of_Day_Request'),
                "value": [],
                "disabled": params.SelectedOrders < 1,
                'isContextMenu': true,
                'isButtonMenu': true,
                "wmsSelector": params.Selector + "createEndofDayRequest",
                "onClickFunc": menuClickEvent,
                'wmsid': "createEndofDayRequest"
            }, {
                "name": translate('Label_Reprint_End_of_Day_Request'),
                "value": [],
                "disabled": false,
                'isContextMenu': true,
                'isButtonMenu': true,
                "wmsSelector": params.Selector + "reprintEndofDayRequest",
                "onClickFunc": menuClickEvent,
                'wmsid': "reprintEndofDayRequest"
            }],
            "disabled": false,
            'isContextMenu': params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            'isButtonMenu': params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            "wmsSelector": params.Selector + "EndofDayRequest",
            "onClickFunc": () => { },
            'wmsid': "endofDayRequest"
        });
        updatedPrintMenu.SmartParcelData.push({
            "name": translate('Label_Cancel_and_Void_Shipping_Labels'),
            "value": [],
            "disabled": params.SelectedOrders < 1,
            'isContextMenu': (params.Rights.hasOwnProperty('orders.manage.smartparcel') && params.Rights.hasOwnProperty('orders.manage.cancel')),
            'isButtonMenu': (params.Rights.hasOwnProperty('orders.manage.smartparcel') && params.Rights.hasOwnProperty('orders.manage.cancel')),
            "wmsSelector": params.Selector + "CancelandVoidShippingLabel",
            "onClickFunc": menuClickEvent,
            'wmsid': "cancelandVoidShippingLabel"
        });
        updatedPrintMenu.SmartParcelData.push({
            "name": translate('Label_Print_Hvp_Batches'),
            "value": [],
            "disabled": false,
            'isContextMenu': params.Rights.hasOwnProperty('Shipping.SmallParcelShipEngineHighVolumeBatches'),
            'isButtonMenu': params.Rights.hasOwnProperty('Shipping.SmallParcelShipEngineHighVolumeBatches'),
            "wmsSelector": params.Selector + "PrintBatchedShippingLabel",
            "onClickFunc": menuClickEvent,
            'wmsid': "printBatchedShippingLabel"
        });
        updatedPrintMenu.SmartParcelData.push({
            "name": translate('Reset_Failed_Hvp'),
            "value": [],
            "disabled": params.SelectedOrders < 1,
            'isContextMenu': params.Rights.hasOwnProperty('Shipping.SmallParcelShipEngineHighVolumeBatches'),
            'isButtonMenu': params.Rights.hasOwnProperty('Shipping.SmallParcelShipEngineHighVolumeBatches'),
            "wmsSelector": params.Selector + "ResetFailedHvp",
            "onClickFunc": menuClickEvent,
            'wmsid': "resetFailedHvp"
        });
        updatedPrintMenu.SmartParcelData.push({
            "name": translate('Label_Auto_Rate_Shop'),
            "value": [],
            "disabled": params.SelectedOrders < 1,
            'isContextMenu': params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            'isButtonMenu': (params.Rights.hasOwnProperty('orders.manage.smartparcel') && params.Rights.hasOwnProperty('Shipping.SmallParcelRateShoppingEnabled')),
            "wmsSelector": params.Selector + "AutoRateShop",
            "onClickFunc": menuClickEvent,
            'wmsid': "autoRateShop"
        });

        updatedPrintMenu.SmartParcelData = updatedPrintMenu.SmartParcelData.concat(TempParcelData);
    }

    return updatedPrintMenu;
}