
import React from 'react';
import { KendoGridWms } from '../../../assets/ts/KendoGridWms.ts';
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';
import { StringWms } from "../../../assets/ts/StringWms.ts";

import style from './cyclecount.scss';
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsModel from './../components/WmsModel/WmsModel.jsx';

import * as cyclecountService from './cyclecount.services.jsx';
import * as GlobalService from './../global_services';
import { kendo } from "./../global_variable_service.jsx";
var that, locationQtyGrid, dataSource, selectLocationParentId;

export class ReconciletSection extends React.Component {

    constructor(props) {
        super(props);
        
        this.translate = window.translate;
        this.state = {
            sharedCycleCount: this.props.sharedCycleCount,
            systemQuantitiesModelOption: {
                title: '',
                width: 0,
                height: 0,
                isOpen: false
            },
            token: "",
            selectedSkuble: null,
            skubleTitle: "",
            sysQntGif: false,
            makeAdjustmentsGif: false,
            guidVarianceObject: {}, // This object is created once at databound to be able to check quickly on variances within our selection, this allows us to disable or enable the adjust system quantities
            activeSelectionVariance: false,
            selector:"AuditInventory"
        };
        locationQtyGrid = new KendoGridWms();
        that = this;
    }

    componentDidMount() {
        dataSource = new kendo.data.TreeListDataSource({
            data: [],
            schema: {
                model: {
                    id: "id",
                    expanded: true
                }
            }
        });
        var reconcileJobHeaders = StringWms.convertSringToOption(this.translate('Review_Reconcile_Job_Header'));
        $("#reconcileTreelist").kendoTreeList({
            dataSource: dataSource,
            // height: 'auto',
            // height: '350',
            selectable: 'multiple, row',
            resizable: true,
            columns: [
                { field: "title", title: reconcileJobHeaders[0].description },
                /*
                { field: "itemDetail", title: "Item Details" },
                { field: "location", title: "Loactions" },
                */
                { field: "systemCount", title: reconcileJobHeaders[1].description  },
                { field: "allocatedCount", title: reconcileJobHeaders[2].description  },
                { field: "physicalCount", title: reconcileJobHeaders[3].description  },
                { field: "variance", title: reconcileJobHeaders[4].description  }
            ],
            dataBound: function (e) {

                that.applyGridRowColorForVariance();
                var treelist = $("#reconcileTreelist").data("kendoTreeList");

                //var hmm = treelist.select();
                var data = treelist.dataSource.view();
                var guidVarianceObject = {};
                for (var z = 0; z < data.length; z++) {
                    guidVarianceObject[data[z].uid] = data[z].variance;
                }
                that.setState({
                    guidVarianceObject: guidVarianceObject
                });
                /*
                var treeview = $("#reconcileTreelist").data("kendoTreeList").dataSource._data;
                console.log('treeview', treeview);
                var grid = $('#reconcileTreelist').data('kendoTreeList');
                    var gridData = grid.dataSource.view();

                    for (var i = 0; i < gridData.length; i++) {
                        if (gridData[i].variance != 0 && gridData[i].variance != null ) {
                            that.setState({ disableReconcileJobs:false });
                            grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-variance-allocated");
                        }
                    }
                    */
            },
            collapse: function (e) {
                that.applyGridRowColorForVariance();

            },
            expand: function (e) {
                that.applyGridRowColorForVariance();

            }
        });

        $(document).on('click', '#reconcileTreelist tr', function (event) {
            var grid = $("#reconcileTreelist").data("kendoTreeList");
            var selectedItem = grid.dataItem(grid.select());
            // var ok = 5;
            // If they click on a row that is not auditNodeType then it is a row that is not a skuble
            // For example it might be a sku or location
            // We are currently only allowing skuble selection so we need to clear the selction
            if (selectedItem != null && typeof selectedItem != 'undefined' && selectedItem.auditNodeType == 1) {
                grid.clearSelection();
                that.updateSkuble(null);
            } else {
                if (selectedItem != null && typeof selectedItem != 'undefined') {
                    that.updateSkuble(selectedItem.skuble);
                    var tr = $(this);
                    grid.select(tr);
                    setTimeout(function () {
                        // set selected item parent id to manage multiple skuble and select only parent id child locations
                        if (selectedItem.auditNodeType == 2) {
                            selectLocationParentId = selectedItem.id;
                        } else if (selectedItem.auditNodeType == 0) {
                            selectLocationParentId = selectedItem.parentId;
                        }
                        var rootNode = that.getRootNode(selectedItem);
                        that.selectChildNodes(rootNode);

                        var treelist = $("#reconcileTreelist").data("kendoTreeList");

                        var selection = treelist.select();
                        var activeSelectionVariance = false;
                        for (var x = 0; x < selection.length; x++) {
                            if (that.state.guidVarianceObject[selection[x].dataset.uid] != 0 && that.state.guidVarianceObject[selection[x].dataset.uid] != null) {
                                activeSelectionVariance = true;
                            }
                        }
                        that.setState({
                            activeSelectionVariance: activeSelectionVariance
                        });

                    }, 100);
                }
            }

        });
        that.setCurrentSelectJobData(that.state.sharedCycleCount.EditData);
    }

    applyGridRowColorForVariance() {
        // HACK, currently the kendo events of collapse and expand fire before the event and when trying to update the dom during the event
        // it does not seem tow ork, so we need an event that fires after dom rendering, we are investigating this but this will work for now
        setTimeout(function () {
            var treeview = $("#reconcileTreelist").data("kendoTreeList").dataSource._data;
            console.log('treeview', treeview);
            var grid = $('#reconcileTreelist').data('kendoTreeList');
            var gridData = grid.dataSource.view();

            for (var i = 0; i < gridData.length; i++) {
                //if (gridData[i].variance != 0 && gridData[i].variance != null ) {
                if (gridData[i].variance != 0 && gridData[i].variance != null) {
                    that.setState({ disableReconcileJobs: false });
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-variance-allocated");
                }
                else {
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").removeClass("wms-variance-allocated");
                }
            }
        }, 200);



    }

    getRootNode(tlModel) {
        var rootNode = tlModel;
        var level = dataSource.level(tlModel);
        while (level !== 0) {
            rootNode = dataSource.parentNode(rootNode);
            level = dataSource.level(rootNode);
        }
        return rootNode;
    }

    selectChildNodes(tlModel) {

        var childNodes = dataSource.childNodes(tlModel);
        var treelist = $("#reconcileTreelist").data("kendoTreeList");
        (tlModel.auditNodeType != 1 &&
            (tlModel.id == selectLocationParentId ||
                tlModel.parentId == selectLocationParentId)
        ) ? treelist.select($("tr[data-uid='" + tlModel.uid + "']")) : '';
        for (var i = 0; i < childNodes.length; i++) {
            that.selectChildNodes(childNodes[i]);
        }

    }
    //set Grid response in grid columns format
    setReconcilegridFormat(auditReconcileData) {
        for (var index = 0; index < auditReconcileData.length; index++) {
            if (typeof auditReconcileData[index].parentId != 'undefined') {
                if (auditReconcileData[index].parentId != null) {
                    var getIndex = ObjectWms.FindIndexByProperty(auditReconcileData, "id", auditReconcileData[index].parentId);
                    if (getIndex != -1 &&
                        auditReconcileData[getIndex].parentId == null ||
                        auditReconcileData[getIndex].parentId == 0) {
                        //   auditReconcileData[index].itemDetail = auditReconcileData[index].title;
                        //   delete auditReconcileData[index].title;
                    } else {
                        //   auditReconcileData[index].location = auditReconcileData[index].title;
                        //   delete auditReconcileData[index].title;
                    }
                }
            }
        }
        return auditReconcileData;
    }
    setCurrentSelectJobData(jobsData) {
        var countsUri = [];
        jobsData.filter(function (jobDetail) {
            countsUri.push(jobDetail.CountsUri);
        });
        kendo.ui.progress($('#reconcileTreelist'), true);
        cyclecountService.GetAuditReconciles(countsUri).then(function (response) {
            if (response.status == 200) {
                if (typeof $('#reconcileTreelist').data('kendoTreeList') != 'undefined') {
                    var data = that.setReconcilegridFormat(response.data);
                    $('#reconcileTreelist').data('kendoTreeList').dataSource.data(data);
                    $('#reconcileTreelist').data('kendoTreeList').refresh();
                    that.setState({ selectedSkuble: null });
                    that.applyGridRowColorForVariance();

                }
            }
            kendo.ui.progress($('#reconcileTreelist'), false);
        }, function (response) {
            // GlobalService.notification('error', response);
            kendo.ui.progress($('#reconcileTreelist'), false);
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.sharedCycleCount != this.state.sharedCycleCount) {

            that.setState({
                sharedCycleCount: Object.assign(
                    {},
                    that.state.sharedCycleCount,
                    nextProps.sharedCycleCount
                ),
                initialCycleCountJobsGrid: 0,
            });
        }
    }
    /*
    We have a skuble that we keep track of when someone selects a row
    We keep track of the
    selectedSkuble
    skubleTitle
    Rather than setting both of these everytime we have a function that does both of these so we don't have to repeat that logic everywhere
     */
    updateSkuble(skubleObject) {
        let date;
        let skubleTitle = "";
        if (skubleObject !== null) {
            skubleObject.Sku ? skubleTitle = skubleTitle + skubleObject.Sku + ":" : null;
            skubleObject.LotNumber ? skubleTitle = skubleTitle + ", " + skubleObject.LotNumber : null;
            skubleObject.Qualifier ? skubleTitle = skubleTitle + ", " + skubleObject.Qualifier + ":" : null;
            skubleObject.SerialNumber ? skubleTitle = skubleTitle + ", " + skubleObject.SerialNumber + ":" : null;
            if (skubleObject.ExpirationDate) {
                if (skubleObject.ExpirationDate.indexOf('Date') != -1) {
                    let date = new Date(parseInt(skubleObject.ExpirationDate.substr(6)));
                    skubleObject.ExpirationDate = date.toISOString();
                    skubleTitle = skubleTitle + ", " + date.toDateString();
                }
            }
        }

        that.setState({
            selectedSkuble: skubleObject,
            skubleTitle: skubleTitle
        });
    }

    openAdjustQtyModal() {
        that.setState({
            sysQntGif: true
        });
        var selectedJobData = that.state.sharedCycleCount.EditData;
        var auditCountsUris = [];
        for (var i = 0; i < selectedJobData.length; i++) {
            auditCountsUris.push(selectedJobData[i].CountsUri);
        }
        kendo.ui.progress($('#adjustQuanity'), true);
        console.log($('#adjustQuanity'));
        // $('#adjustQuanity').remove();
        cyclecountService.getReconcilePreview(auditCountsUris, that.state.selectedSkuble).then(function (response) {

            // lets set the token in our state to use in other sections here
            that.setState({
                token: response.data.Token
            });
            var modelOptions = that.state.systemQuantitiesModelOption;
            modelOptions.title = 'Adjust System Quantities';
            modelOptions.width = 700;
            modelOptions.height = 315;
            modelOptions.isOpen = true;
            that.setState({
                systemQuantitiesModelOption: modelOptions
            });
            var adjustQtyGrid = {
                resizable: true,
                dataSource: {
                    data: response.data.Operations,
                    schema: {
                        model: {
                            id: "Id",
                            fields: {
                                SrcLocation: { type: "string" },
                                TgtLocation: { type: "string" },
                                Operation: { type: "number" },
                                Qty: { type: "number" }
                            }
                        }
                    },
                },
                selectable: "multiple, row",
            };
            var adjustQtyGridColumns = {
                columns: [{
                    field: "SrcLocation",
                    title: 'Source Location',
                    wmsid: 1,
                    filterable: false
                }, {
                    field: "TgtLocation",
                    title: "Target Location",
                    wmsid: 2,
                    filterable: true,
                }, {
                    field: "Operation",
                    title: "Operation",
                    wmsid: 3,
                    filterable: true,
                    template: "#= Operation != 1 ? 'Adjust' : 'Swap' #"
                },
                {
                    field: "Qty",
                    title: "Qty",
                    wmsid: 4,
                    filterable: true,
                }]
            };
            var finalOptionsObj = locationQtyGrid.getGridOptions(adjustQtyGridColumns, adjustQtyGrid, '[{}]');
            console.log('finalOptionsObj', finalOptionsObj);
            $("#cycleCountAdjustQtyGrid").kendoGrid(finalOptionsObj);
            that.setState({
                sysQntGif: false
            });
            kendo.ui.progress($('#adjustQuanity'), false);


        }, function (response) {
            that.setState({ sysQntGif: false });
            kendo.ui.progress($('#adjustQuanity'), false);

        });


    }
    closeJob() {

        var selectedJobData = that.state.sharedCycleCount.EditData;
        var auditCountsUris = [];
        for (var i = 0; i < selectedJobData.length; i++) {
            auditCountsUris.push(selectedJobData[i].EditUri);
        }
        that.props.closeJob(auditCountsUris);
    }
    closeAdjustQtyModal() {

        var modelOptions = that.state.systemQuantitiesModelOption;
        modelOptions.isOpen = false;
        that.setState({
            systemQuantitiesModelOption: modelOptions
        });
        cyclecountService.makeItSoReconcile(that.state.token, false).then(function (response) {

        }, function (response) {

        });
    }

    makeAdjustmentsClick() {

        that.setState({ makeAdjustmentsGif: true });
        cyclecountService.makeItSoReconcile(that.state.token, true).then(function (response) {
            GlobalService.notification('success', that.translate('Label_Make_Adjust_Success'));
            $("#adjustQuanity").data('kendoWindow').close();
            that.setCurrentSelectJobData(that.state.sharedCycleCount.EditData);

            that.setState({ makeAdjustmentsGif: false });
        }, function (response) {
            // GlobalService.notification('error', response);
            that.setState({ makeAdjustmentsGif: false });
        });


    }

    cancelAdjustQtyModal() {
        $("#adjustQuanity").data('kendoWindow').close();
    }

    /*/
    If we call a preview of the reconcile we get a token that we keep in session
    Proceeding with reconciling will remove the token from session but if the user decides they want to cancel the reconcile process
    we still want to remove the token, that is what this function does
     */
    cancelReconcile() {
        cyclecountService.makeItSoReconcile("", false).then(function (response) {

        }, function (response) {

        });
    }

    render() {
        return (
            <div className="cyclecount-reveiew-reconcile-wrapper">

                        <div className="wms-btn-wrapper">
                        <WmsButton
                            label={'Adjust System Quantities'}
                            wmsSelector={this.state.selector+'systemQuantities'}
                            onClickFunc={this.openAdjustQtyModal}
                            buttonType={'yellow'}
                            disabled={this.state.selectedSkuble === null || this.state.sysQntGi || this.state.activeSelectionVariance === false}
                            icon='fa-adjust'
                            loading={this.state.sysQntGif}
                        />
                        <WmsButton
                            label={"Exit"}
                            wmsSelector={this.state.selector+'exitReconcile'}
                            onClickFunc={this.props.toggleReconcileSection}
                            buttonType={'red'}
                            icon='fa-times' />
                        </div>
                        <div className="wms-grid-wrapper">
                            <div className="title">
                                <label className="wms-input-label">Location under review:<span>L1,L2</span></label>
                            </div>

                            <div className="wms-grid-holder">
                                        <div className="no-josh" id="reconcileTreelist" data-wms-selector={this.state.selector+"reconcileTreelist"}></div>
                                    </div>
                                </div>

                    <WmsModel id={'adjustQuanity'} title={this.state.systemQuantitiesModelOption.title} width={this.state.systemQuantitiesModelOption.width} height={this.state.systemQuantitiesModelOption.height} onCloseFunc={this.closeAdjustQtyModal} isOpen={this.state.systemQuantitiesModelOption.isOpen} customClass='wms-model-new-ui-wrapper'>
                    <div className="model-content-wrapper">
                        
                        <div className="wms-grid-wrapper">
                            <div className="title">
                                <label className="wms-input-label">{this.state.skubleTitle}</label>
                            </div>
                            <div className="wms-grid-holder">
                                    <div className="no-josh" id="cycleCountAdjustQtyGrid" data-wms-selector={this.state.selector+"AdjustQtyGrid"}></div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-btn-holder">
                        <WmsButton
                            label={this.translate("Label_Cancel")}
                            wmsSelector={this.state.selector+'adjustmentCancel'}
                            onClickFunc={this.cancelAdjustQtyModal}
                            buttonType={'orange'}
                            icon='fa-ban'
                        />
                        <WmsButton
                            label={'Make Adjustments'}
                            wmsSelector={this.state.selector+'makeadjustments'}
                            onClickFunc={this.makeAdjustmentsClick}
                            loading={this.state.makeAdjustmentsGif}
                            icon='fa-adjust' />

                       
                        </div>
                    </WmsModel>
                </div>

        );
    }
}