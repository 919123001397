import React from 'react';
import PropTypes from 'prop-types';
import WmsToolTip from '../WmsToolTip/WmsToolTip';
import './WmsRadio.scss';
import _ from "lodash";

interface RadioProps {
    id?: string, // this should write the id to the radio
    label?: string,// this should be the label that shows after radio button
    disabled?: boolean, // default will be false
    name?: string,// this should be the name of the radio
    required?: boolean, // this determines if the radio is required and shows in red
    wmsSelector?: string, // this should set up a selector on the input
    onChangeFunc?: any, // this should control the onchange event and anything we need to determine before we send it up
    showToolTip?: boolean, // set tooltip show or not
    tooltipText?: string, // show text on hover as tooltip
    checked?: boolean, // this should checked radio button,
    parentClassName?: string,
    classes?: string
}

const WmsRadio: React.FC<RadioProps> = (props: RadioProps): any => {
    return (
        <div className="wms-radiobtn-component">
            <div className={"wms-radiobtn " + props.classes}>
                <label className={props.disabled === true ? "disabled-checkbox" : ''} htmlFor={props.id}>
                    <input
                        type="radio"
                        id={props.id}
                        name={props.name}
                        className="wms-option-radiobtn radio"
                        data-wms-selector={props.wmsSelector}
                        onChange={props.onChangeFunc}
                        required={props.required}
                        disabled={props.disabled}
                        checked={props.checked} />
                    {props.label}
                </label>
                {
                    props.disabled === true &&
                    props.showToolTip === true && (
                        <WmsToolTip tooltipText={props.tooltipText} />
                    )
                }
            </div>
        </div>
    );
};

WmsRadio.propTypes = {
    id: PropTypes.string, // this should write the id to the radio
    label: PropTypes.string.isRequired,// this should be the label that shows after radio button
    disabled: PropTypes.bool, // default will be false
    name: PropTypes.string.isRequired,// this should be the name of the radio
    required: PropTypes.bool, // this determines if the radio is required and shows in red
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
    showToolTip: PropTypes.bool, // set tooltip show or not
    tooltipText: PropTypes.string, // show text on hover as tooltip
    checked: PropTypes.bool // this should checked radio button
}

WmsRadio.defaultProps = {
    disabled: false,
    checked: false,
    required: false,
    showToolTip: false,
    tooltipText: 'add tooltip text',
    parentClassName: "",
    classes: "",
}

const areEqual: any = (prevProps: any, nextProps: any): any => {
    return _.isEqual(_.omit(nextProps, ['onChangeFunc']), _.omit(prevProps, ['onChangeFunc']));
};
export default React.memo(WmsRadio, areEqual);