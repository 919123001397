import React, { Component } from 'react'
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx'
import WmsInput from '../../components/WmsInput/WmsInput.jsx'

export class CanadaPostComponent extends Component {
    constructor(props) {
        super(props)
        
        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {

        }
    }

    render() {
        return (
            <WmsCollapse
                id={"CanadaPost"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                        <WmsInput
                            id="accountNickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_Account_Nick_Name")}
                            value={this.props.connectionObj.Nickname}
                            required={true}
                            extraWrapperClass="cp-nickname"
                            />
                    <div>
                        <WmsInput
                            id="account_number"
                            inputName="AccountNumber"
                            wmsSelector={this.props.selector + "accountNumber"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_Account_Number")}
                            value={this.props.connectionObj.AccountNumber}
                            required={true}
                            extraWrapperClass="cp-account-number"
                        />
                         <WmsInput
                            id="contractId"
                            inputName="ContractId"
                            wmsSelector={this.props.selector + "contract_id"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_Contract_Id")}
                            value={this.props.connectionObj.ContractId}
                            extraWrapperClass="cp-contract-id"
                        />
                    </div>

                    <WmsInput
                        id="api_key"
                        inputName="ApiKey"
                        wmsSelector={this.props.selector + "apiKey"}
                        onChangeFunc={this.props.handleConnectionObjChange}
                        label={this.translate("Label_API_Key")}
                        value={this.props.connectionObj.ApiKey}
                        extraWrapperClass="w-75"
                        required={true}
                    />
                    <WmsInput
                        id="api_secret"
                        inputName="ApiSecret"
                        wmsSelector={this.props.selector + "apiSecret"}
                        onChangeFunc={this.props.handleConnectionObjChange}
                        label={this.translate("Label_API_Secret")}
                        value={this.props.connectionObj.ApiSecret}
                        extraWrapperClass="w-75"
                        required={true}
                    />
                </div>
            </WmsCollapse>
        )
    }
}
