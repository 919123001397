
import React from 'react';
import { UserBetaGrid } from './users-beta-grid.component.jsx';
import { ManageUsersAccountModel } from './manage-users-account-model.component.jsx';
import { UserSearchComponent } from './users-search.component.jsx';

import style from './users-beta.scss';

export class userBetaHolder extends React.Component {
    constructor(props) {

        super(props);
        
        this.$rootScope = window;

        this.state = {
            reloadGrid: false,
            sharedUserUri: null,
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            filterDataObj: [],
            sharedIsOpenManageUserFrom: false
        }
    }

    componentDidMount() {
        $('#user-beta-holder-react').closest('.k-window-content').css("padding", "0");
    }

    componentWillUnmount() {
        $('#user-beta-holder-react').closest('.k-window-content').css("padding", "26");
    }

    onCreateUser = () => {
        this.setState({ sharedUserUri: '', sharedIsOpenManageUserFrom: true });
    }

    onEditUser = (param_user_uri) => {
        this.setState({ sharedUserUri: param_user_uri, sharedIsOpenManageUserFrom: true });
    }

    onExitUserForm = () => {
        this.setState({
            sharedUserUri: null,
            reloadGrid: '',
            sharedIsOpenManageUserFrom: false
        });
    }

    onReloadGrid = (basecall) => {
        this.setState({ reloadGrid: basecall });
    }
    changeFilterData = (filterDataObj) => {
        this.setState({
            filterDataObj: filterDataObj
        })
    }
    render() {
        return (
            <div id="user-beta-holder-react" className="new-layout-grid-filter-wrapper">
                <UserSearchComponent
                    windowId={this.state.windowId}
                    changeFilterData={this.changeFilterData}
                />
                <div className="user-grid-wrapper new-layout-grid-wrapper">
                    <UserBetaGrid
                        onCreateUser={this.onCreateUser}
                        onEditUser={this.onEditUser}
                        windowId={this.state.windowId}
                        reloadGrid={this.state.reloadGrid}
                        filterDataObj={this.state.filterDataObj}
                    />
                </div>
                {this.state.sharedIsOpenManageUserFrom && (
                    <ManageUsersAccountModel
                        onReloadGrid={this.onReloadGrid}
                        onExitUserForm={this.onExitUserForm}
                        sharedUserUri={this.state.sharedUserUri}
                        windowId={this.state.windowId}
                        sharedIsOpenManageUserFrom={this.state.sharedIsOpenManageUserFrom}
                    />
                )}
            </div>
        )
    }
}
