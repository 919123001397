import React from "react";
import WmsButton from "../../components/WmsButton/WmsButton";
import WmsCollapse from "../../components/WmsCollapse/WmsCollapse";
import WmsDropdown from "../../components/WmsDropdown/WmsDropdown";
import WmsInput from "../../components/WmsInput/WmsInput";
import WmsModel from "../../components/WmsModel/WmsModel";
import WmsTextArea from "../../components/WmsTextArea/WmsTextArea";
import * as GlobalService from './../../global_services';

import * as smallParcelService from './../smallParcel.service.jsx';
import './create-container-manifest-request.scss';

class CreateContainerManifestRequest extends React.PureComponent {
    constructor(props) {
        super(props);
        this.translate = window.translate;
        this.$rootScope = window;
        this.modalId = this.props.windowId + this.props.id;
        this.customer = this.props.customer != 0 ? GlobalService.getCustomerById(this.props.customer).name : '';

        this.containerOptions = [
            {
                "Name": "Carton",
                "Value": "carton",
            },
            {
                "Name": "Pallet",
                "Value": "pallet",
            },
            {
                "Name": "Gaylord",
                "Value": "gaylord",
            },
            {
                "Name": "LicensePlate",
                "Value": "licensePlate",
            },
        ]
        this.containerFormatOptions = [
            {
                "Name": "PDF",
                "Value": "PDF",
            },
            {
                "Name": "ZPL",
                "Value": "ZPL",
            },
            {
                "Name": "PNG",
                "Value": "PNG",
            },
            {
                "Name": "JPEG",
                "Value": "JPEG",
            },
        ]
        this.containerLayoutOptions = [
            {
                "Name": "4x4",
                "Value": "4x4",
            },
            {
                "Name": "6x4",
                "Value": "6x4",
            },
        ]

        this.state = {
            containerManifestData: {
                containerType: '',
                shipmentNumber: '',
                containerId: '',
                containerFormat: '',
                containerLayout: '',
                notes: '',
            },
            containerManifestBtnLoader: false
        }
    }

    onHandelChange = (e) => {
        let tmpCurrentManifestRequest = Object.assign({}, this.state.containerManifestData);
        let {name, value} = e.target;

        tmpCurrentManifestRequest[name] = value;
        this.setState({
            containerManifestData: tmpCurrentManifestRequest
        });
    }

    generateContainerManifest = () => {
        this.setState({ containerManifestBtnLoader: true });
        var payload = Object.assign({}, this.state.containerManifestData);
        payload.ids = this.props.SharedSelectedOrderIds;
        smallParcelService.setParcelContainerFinalizer(payload).then((response) => {
            GlobalService.notification('success', this.translate('Label_Smart_Parcel_Container_Manifest_Request'));
            this.props.closeContainerManifestModal();
        }).finally(() => {
            this.setState({ containerManifestBtnLoader: false });
        });
    }

    render() {
        return (
            <WmsModel
                id={this.modalId}
                class="CreateContainerManifestRequest"
                title={this.props.title}
                width={this.props.width}
                height={this.props.height}
                onCloseFunc={this.props.closeContainerManifestModal}
                isOpen={this.props.isOpenContainerManifestModal}
                customClass='wms-model-new-ui-wrapper'
            >
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'createContainerManifestRequestModal'}
                        headerLabel={`Customer : ${this.customer}`}
                        showCollapseText={false}
                        showCollapseIcon={false}
                        isExpanded={true}
                    >
                        <div className="inline-elements">
                            <div className="" >
                                <WmsDropdown
                                    id="containerType"
                                    name="containerType"
                                    label={this.translate("Label_Container_Type")}
                                    wmsSelector={"containerType"}
                                    value={this.state.containerManifestData.containerType}
                                    onChangeFunc={this.onHandelChange}
                                    options={this.containerOptions}
                                    required={true}
                                    textField="Name"
                                    valueField="Value"
                                    blankFirstOption={true}
                                    blankFirstOptionText="Unspecified"
                                />
                                <WmsInput
                                    id="shipmentNumber"
                                    inputName="shipmentNumber"
                                    wmsSelector={"shipmentNumber"}
                                    onChangeFunc={this.onHandelChange}
                                    label={this.translate("Label_Shipment_Number")}
                                    value={this.state.containerManifestData.shipmentNumber}
                                    required={true}
                                />
                                <WmsInput
                                    id="containerId"
                                    inputName="containerId"
                                    wmsSelector={"containerId"}
                                    onChangeFunc={this.onHandelChange}
                                    label={this.translate("Label_Container_Id")}
                                    value={this.state.containerManifestData.containerId}
                                    required={true}
                                />
                                <WmsDropdown
                                    id="containerFormat"
                                    name="containerFormat"
                                    label={this.translate("Label_Container_Label_Format")}
                                    wmsSelector={"containerFormat"}
                                    value={this.state.containerManifestData.containerFormat}
                                    onChangeFunc={this.onHandelChange}
                                    options={this.containerFormatOptions}
                                    required={true}
                                    textField="Name"
                                    valueField="Value"
                                    blankFirstOption={true}
                                    blankFirstOptionText="Unspecified"
                                />
                                <WmsDropdown
                                    id="containerLayout"
                                    name="containerLayout"
                                    label={this.translate("Label_Container_Label_Size")}
                                    wmsSelector={"containerLayout"}
                                    value={this.state.containerManifestData.containerLayout}
                                    onChangeFunc={this.onHandelChange}
                                    options={this.containerLayoutOptions}
                                    required={true}
                                    textField="Name"
                                    valueField="Value"
                                    blankFirstOption={true}
                                    blankFirstOptionText="Unspecified"
                                />
                                <WmsTextArea
                                    id='notes'
                                    name='notes'
                                    wmsSelector={"notes"}
                                    label={this.translate('Label_Message')}
                                    onChangeFunc={this.onHandelChange}
                                    value={this.state.containerManifestData.notes}
                                    rows={4}
                                    cols={30}
                                />
                            </div>
                        </div>
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Close')}
                        wmsSelector={"cancelContainerManifest"}
                        onClickFunc={this.props.closeContainerManifestModal}
                        disabled={false}
                        loading={false}
                        buttonType='orange'
                        icon={'fa-ban'}
                    />
                    <WmsButton
                        label={this.translate('Label_Generate')}
                        wmsSelector={"generateContainerManifest"}
                        onClickFunc={this.generateContainerManifest}
                        disabled={
                            !this.state.containerManifestData?.containerType ||
                            !this.state.containerManifestData?.containerFormat ||
                            !this.state.containerManifestData?.containerLayout ||
                            !this.state.containerManifestData?.shipmentNumber ||
                            !this.state.containerManifestData?.containerId
                        }
                        loading={this.state.containerManifestBtnLoader}
                        icon={'fa-check-square-o'}
                    />
                </div>
            </WmsModel>
        );
    }
}

export default CreateContainerManifestRequest;
