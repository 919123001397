
import React from 'react';
import { RolesGrid } from './roles-grid.component.jsx';
import { RolesForm } from './roles-form.component.jsx';

import style from './roles.scss';

export class rolesHolder extends React.Component {
    constructor(props) {
        super(props);

        
        this.$rootScope = window;
        this.state = {
            reloadGrid: false,
            isRoleForCopy: false,
            sharedSelectRoleData: null,
            sharedInitialRoleDataObject: {},
            windowId: this.$rootScope.ChildBulletin.Data.Window,
        }
    }


    onCreateRole = (param_initialrole_data) => {
        this.setState({
            sharedSelectRoleData: '',
            sharedInitialRoleDataObject: param_initialrole_data,
            isRoleForCopy: false
        });
    }

    onEditRole = (param_role_obj) => {
        this.setState({
            sharedSelectRoleData: param_role_obj.selectRoleData,
            sharedInitialRoleDataObject: param_role_obj.initialRoleData,
            isRoleForCopy: false
        });
    }

    onCopyRole = (param_role_obj) => {

        let param_copy = JSON.parse(JSON.stringify(param_role_obj));
        param_copy.selectRoleData.Name = '';
        this.setState({
            sharedSelectRoleData: param_copy.selectRoleData,
            sharedInitialRoleDataObject: param_copy.initialRoleData,
            isRoleForCopy: true
        });
    }

    onExitRoleForm = () => {
        this.setState({
            sharedSelectRoleData: null,
            isRoleForCopy: false,
            reloadGrid: ''
        });
    }

    onReloadGrid = (basecall) => {
        this.setState({ reloadGrid: basecall });
    }

    render() {
        return (
            <div id="role-holder-react">

                    <RolesGrid
                        onCreateRole={this.onCreateRole}
                        onEditRole={this.onEditRole}
                        onCopyRole={this.onCopyRole}
                        windowId={this.state.windowId}
                        reloadGrid={this.state.reloadGrid}
                    />



                    {this.state.sharedSelectRoleData != null &&
                        <RolesForm
                            windowId={this.state.windowId}
                            onExitRoleForm={this.onExitRoleForm}
                            onReloadGrid={this.onReloadGrid}
                            sharedSelectRoleData={this.state.sharedSelectRoleData}
                            sharedInitialRoleDataObject={this.state.sharedInitialRoleDataObject}
                            isRoleForCopy={this.state.isRoleForCopy}
                        />
                    }

            </div>
        )
    }
}
