import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsTab.scss';

class WmsTab extends React.PureComponent {


	constructor(props) {
		super(props);
		this.disableHandleScroll = false;
		this.state = {
			visibleSection: props.sectionRefs[0].section
		}
	}

	componentDidMount() {
		$(this.props.scrollAreaSelector).scroll(this.handleScroll);
	}

	componentWillUnmount() {
		$(this.props.scrollAreaSelector).off("scroll", this.handleScroll);
	}

	getDimensions = (ele) => {
		if (!ele) {
			return;
		}
		const { height } = ele.getBoundingClientRect();
		const offsetTop = ele.offsetTop;
		const offsetBottom = offsetTop + height;

		return {
			height,
			offsetTop,
			offsetBottom
		};
	}

	handleScroll = () => {
		if (this.disableHandleScroll) {
			return;
		}
		var windowId = this.props.windowId;
		const { height: headerHeight } = this.getDimensions(this.props.tabHeaderRef.current);
		const scrollPosition = $(this.props.scrollAreaSelector).scrollTop() + (headerHeight + this.props.extraTopPadding);
		const selected = this.props.sectionRefs.find(({ section, ref }) => {
			const ele = $('.' + windowId).find(ref.current).get(0);
			if (ele) {
				const { offsetBottom, offsetTop } = this.getDimensions(ele);
				return (scrollPosition >= offsetTop && scrollPosition < offsetBottom);
			}
		});

		if (selected && selected.section !== this.state.visibleSection) {
			this.setState({
				visibleSection: selected.section
			});
		}
	}

	scrollTo = (ele) => (event) => {
		var windowId = this.props.windowId;

		if (
			!$('.' + windowId).find(ele.ref.current) ||
			!$('.' + windowId).find(ele.ref.current).offset() ||
			!$(this.props.scrollAreaSelector)
		) {
			return;
		}

		var maxScrollTop = $(this.props.scrollAreaSelector)[0].scrollHeight - $(this.props.scrollAreaSelector).outerHeight();
		var scrollSelectorTop = $(this.props.scrollAreaSelector).scrollTop();
		var elementOffset = $(this.props.scrollAreaSelector).offset().top - 1;
		let scrollTop = scrollSelectorTop + ($('.' + windowId).find(ele.ref.current).offset().top - elementOffset);

		this.setState({
			visibleSection: ele.section
		});

		// console.log("scrollTop : " + scrollTop + "  maxScrollTop: " + maxScrollTop + "  elementOffset: "+ elementOffset + "  scrollSelectorTop: " + scrollSelectorTop);
		if (scrollTop > maxScrollTop && scrollSelectorTop >= maxScrollTop) {
			console.log("returning")
			return;
		}

		this.disableHandleScroll = true;

		$(this.props.scrollAreaSelector).animate({
			scrollTop: scrollTop
		}, 1000, () => {
			this.disableHandleScroll = false;
		});
	}

	render() {
		return (
			<div className="tab-listing">
				<ul>
					{this.props.sectionRefs.map((obj, index) => (
						obj.isTabShow && (
							<li key={"key_" + index} className={this.state.visibleSection === obj.section ? "active-tab" : ""} onClick={this.scrollTo(obj)}>
								<label className="startScroll" data-wms-selector={obj.wmsSelector}>{obj.label} </label>
							</li>
						)
					))}
				</ul>
			</div>
		);
	}
}

WmsTab.propTypes = {
	windowId: PropTypes.string.isRequired, // this should be the id of the scroll box div
	extraTopPadding: PropTypes.number
}

WmsTab.defaultProps = {
	extraTopPadding: 0
}

export default WmsTab;