let text_center = "text-align:center;";
export const getEventNotificationGridDemoColumns = (EventNotifications_GridHeaders) => {
    return {
        columns: [
            {
                field: "RowNumber",
                title: "#",
                width: 60,
                wmsid: 1,
                locked: true,
                lockable: false,
                resizable: false,
                sortable: false,
                reorderable: false,
                filterable: false,
                attributes: { style: text_center },
                headerAttributes: { style: text_center },
            },
            {
                field: "Name",
                title: EventNotifications_GridHeaders[0].description,
                width: 165,
                wmsid: 2,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "EventNotificationName",
                },
            },
            {
                field: "Url",
                title: EventNotifications_GridHeaders[1].description,
                width: 300,
                wmsid: 3,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "EventNotificationUrl",
                },
            },
            {
                field: "Resource",
                title: EventNotifications_GridHeaders[2].description,
                width: 190,
                wmsid: 4,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "EventNotificationResource",
                },
            },
            {
                field: "EventTypes",
                title: EventNotifications_GridHeaders[3].description,
                width: 275,
                wmsid: 5,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "EventNotificationEvents",
                },
            },
        ],
    };
};

export const getEventNotificationGridDemoSchema = () => {
    return {
        model: {
            id: "RowNumber",
            fields: {
                Name: { type: "string" },
                Url: { type: "string" },
                Resource: { type: "string" },
                EventTypes: { type: "string" },
                // ResourceOptions: { type: "string" }
            },
        },
    };
};

export const getWebhookEventTypes = () => {
    return [
        {
            name: "Order",
            Id: "Order",
            options: [
                { key: "OrderCreate", title: "Created" },
                { key: "OrderComplete", title: "Completed" },
                { key: "OrderUpdate", title: "Updated" },
                { key: "OrderConfirm", title: "Confirmed" },
                { key: "OrderUnconfirm", title: "Unconfirmed" },
                { key: "OrderCancel", title: "Cancelled" },
                { key: "OrderDelete", title: "Deleted" },
                { key: "OrderTransfer", title: "Transferred" },
                { key: "OrderFullyAllocated", title: "Fully allocated" },
                { key: "OrderExcludedFromPickJob", title: "Excluded from pickjob" },
                { key: "OrderPickJobUserAssigned", title: "Pick job - user assigned" },
                { key: "OrderPickJobUserUnassigned", title: "Pick job - user unassigned" },
                { key: "OrderPickJobDone", title: "Pick job - done" },
                { key: "OrderPackStarted", title: "Pack - started" },
                { key: "OrderPacked", title: "Pack - complete" },
            ],
        },
        {
            name: "Receipt",
            Id: "Receiver",
            options: [
                { key: "ReceiverCreate", title: "Create" },
                { key: "ReceiverComplete", title: "Complete" },
                { key: "ReceiverUpdate", title: "Update" },
                { key: "ReceiverConfirm", title: "Confirm" },
                { key: "ReceiverUnconfirm", title: "Unconfirm" },
                { key: "ReceiverCancel", title: "Cancel" },
                { key: "ReceiverDelete", title: "Delete" },
            ],
        },
        {
            name: "Adjustment",
            Id: "Adjustment",
            options: [
                { key: "AdjustmentCreate", title: "Create" },
                { key: "AdjustmentConfirm", title: "Confirm" },
                { key: "AdjustmentCancel", title: "Cancel" },
                { key: "AdjustmentUpdate", title: "Update" },
                { key: "AdjustmentDelete", title: "Delete" },
            ],
        },
        {
            name: "Assembly",
            Id: "Assembly",
            options: [
                { key: "AssemblyCreate", title: "Create" },
                { key: "AssemblyCancel", title: "Cancel" },
                { key: "AssemblyDelete", title: "Delete" },
                { key: "AssemblyUpdate", title: "Update" },
                { key: "AssemblyConfirm", title: "Confirm" },
            ],
        },
        {
            name: "Order Item",
            Id: "OrderItem",
            options: [
                { key: "OrderItemPack", title: "Pack" },
                { key: "OrderItemUnpack", title: "Unpack" },
                { key: "OrderItemPick", title: "Pick" },
                { key: "OrderItemUnpick", title: "Unpick" },
            ],
        },
        {
            name: "Item",
            Id: "Item",
            options: [
                { key: "ItemCreate", title: "Create" },
                { key: "ItemUpdate", title: "Update" },
                { key: "ItemDelete", title: "Delete" },
            ],
        },
        {
            name: "Inventory Summary",
            Id: "InventorySummary",
            options: [{ key: "InventorySummaryUpdate", title: "Inventory Summary Update" }],
        },
    ];
};
