import React from 'react';
var axios = require('axios');
import * as GlobalService from './../global_services';

export const initialFindLocations = function () {

    return axios.all([
        axios.get('/WebUI/Facilities/ManageLocations/Options'),
        axios.get('/WebUI/Facilities/ManageLocations/SearchInfo'),
        GlobalService.GetCustomersAndFacility()
    ]).then(axios.spread(function (ManageLocationsOptions, ManageLocationsSearchInfo, GetCustomerFacilityList) {

        return {
            ManageLocationsOptions: ManageLocationsOptions.data,
            ManageLocationsSearchInfo: ManageLocationsSearchInfo.data.Data,
            GetCustomerFacilityList: GlobalService.getFacilityList()
        };
    }));
}

export const getLocationEditData = function (editUri) {

    return axios.get('/WebUI/Facilities/ManageLocations/Location?EditUri=' + editUri).then(function (response) {
        return response
    });

}

export const saveNewLocation = function (locationData) {

    return axios.post('/WebUI/Facilities/ManageLocations/Location', locationData).then(function (response) {
        return response
    });

}

export const updateLocation = function (locationData) {

    return axios.put('/WebUI/Facilities/ManageLocations/Location', locationData).then(function (response) {
        return response
    });

}

export const updateLocationActivation = function (locationIds, mybool, facilityId) {

    return axios.put("/WebUI/Facilities/ManageLocations/Activate", {
        locationIds: locationIds,
        activate: mybool,
        facilityId: facilityId
    }).then(function (response) {
        return response
    });

}

export const deleteLocation = function (locationIds) {

    return axios.delete("/WebUI/Facilities/ManageLocations/Location", {
           data: {locationIds: locationIds}
        }).then(function (response) {
        return response
    });

}
