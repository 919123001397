
import React from 'react';
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';

//component
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsModel from '../components/WmsModel/WmsModel.jsx';
import WmsConfirmModel from '../components/WmsConfirmModel/WmsConfirmModel.jsx';
import { ManageUsersAccountDetails } from './manage-users-account-details.jsx';

//Services
import * as userService from './users.service.jsx';
import * as GlobalService from '../global_services';

export class ManageUsersAccountModel extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.defaultUserObj = {
            'Name': '',
            'Login': '',
            'NewPassword': '',
            'confirmPassword': '',
            'Deactivated': false,
            'roleName': '',
            "CellCarrierId": 0,
            "V1Role": "",
            "FacilityIds": [],
            "CustomerIds":[],
            "Email": "",
            "CellPhone": "",
            "Login": "",
            "userType": ""
        };
        this.state = {
            saveLoader: false,
            isOpenConfirmModel: false,
            sharedLoggedInUser: null,
            sharedLoggedInUserId: null,
            Selector: 'User',
            sharedUser: this.defaultUserObj,
            sharedUserOptions: {
                warehouseOptions: [],
                customerOptions: [],
                cellCarriersOption: [],
                customerRoleOption: [],
                warehouseRoleOption: [],
                customerAccessV1Option: [
                    { 'name': 'cust' },
                    { 'name': 'cust2' },
                    { 'name': 'cust3' },
                    { 'name': 'cust3c' },
                    { 'name': 'cust3d' },
                    { 'name': 'cust4' },
                    { 'name': 'cust5' },
                    { 'name': 'cust6' },
                    { 'name': 'cust7' },
                    { 'name': 'mobileonly' },
                    { 'name': 'StockStatusOnly' }],
                facilityAccessV1Option: [
                    { 'name': '4pl1' },
                    { 'name': '4pl2' },
                    { 'name': '4pl3' },
                    { 'name': 'level0' },
                    { 'name': 'level1' },
                    { 'name': 'level2' },
                    { 'name': 'level3' },
                    { 'name': 'level4' },
                    { 'name': 'level5' },
                    { 'name': 'level8' },
                    { 'name': 'level9' },
                    { 'name': 'mobileonly' },
                    { 'name': 'ordonly' },
                    { 'name': 'rcvonly' },
                    { 'name': 'smart' }]
            }

        }
    }

    componentDidMount() {

        this.getUserData(this.props.sharedUserUri);

        this.setState({
            sharedLoggedInUser: GlobalService.GetCurrentUser().CustomerIds.length > 0 ? 'customer' : 'warehouse',
            sharedLoggedInUserId: GlobalService.GetCurrentUser().UserId,
        });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.sharedUserUri != this.props.sharedUserUri) {
            this.getUserData(nextProps.sharedUserUri);
        }
    }

    getUserData(param_user_uri) {
        if (param_user_uri == '') {

            kendo.ui.progress($('#form_block'), true);
            userService.getUserOptions(param_user_uri).then((response) => {

                if (typeof response != 'undefined') {

                    if (this.defaultUserObj.CustomerIds.length > 0) {
                        this.defaultUserObj.userType = 'customerAccess';
                    }
                    this.setState({
                        sharedUserOptions: Object.assign(
                            {},
                            this.state.sharedUserOptions,
                            {
                                allFacilities: response.Facilities,
                                warehouseOptions: response.Customers[0].Facilities,
                                customerOptions: response.Customers,
                                cellCarriersOption: response.CellCarriers,
                                customerRoleOption: response.CustomerRoles,
                                warehouseRoleOption: response.WarehouseRoles.filter( e => e.Name !== "superadmin")
                            }),
                        sharedUser: this.defaultUserObj
                    });
                }
                kendo.ui.progress($('#form_block'), false);

            }).finally(() => {
                kendo.ui.progress($('#form_block'), false);
            });

        } else {

            kendo.ui.progress($('#form_block'), true);
            userService.getUserOptions(param_user_uri).then((response) => {


                if (typeof response != 'undefined') {
                    response.User.userType = 'warehouseAccess';
                    if (response.User.CustomerIds.length > 0) {
                        response.User.userType = 'customerAccess';
                    }
                    if (response.User.CellPhone) {
                        response.User.CellPhone = $('#CellPhone').masked(response.User.CellPhone);
                    }

                    response.User.roleName = (response.User.UiRoleNames.length > 0) ? response.User.UiRoleNames[0] : '';

                    if (!response.User.Provisioned) {
                        response.User.userType = '';
                        response.User.roleName = '';
                        response.User.V1Role = '';
                        response.User.FacilityIds = [];
                        response.User.CustomerIds = [];
                    }

                    this.setState({
                        sharedUserOptions: Object.assign(
                            {},
                            this.state.sharedUserOptions,
                            {
                                allFacilities: response.Facilities,
                                customerOptions: response.Customers,
                                cellCarriersOption: response.CellCarriers,
                                customerRoleOption: response.CustomerRoles,
                                warehouseRoleOption: response.WarehouseRoles.filter( e => e.Name !== "superadmin")
                            }),
                        sharedUser: response.User
                    });
                }

                kendo.ui.progress($('#form_block'), false);

            }).finally(() => {
                kendo.ui.progress($('#form_block'), false);
            });
        }


    }

    onUserDataset = (param_userObj) => {
        this.setState({ sharedUser: param_userObj });
    }

    onClickSaveUser = () => {
        //var today = new Date();


        let userObj = JSON.parse(JSON.stringify(this.state.sharedUser));

        //Logic for show confirm model
        if (userObj.roleName) {
            var isHasOutOfScopeRights = false;
            if (userObj.userType == "customerAccess") {
                var returnObj = this.checkHasOutOfScopeRight(this.state.sharedUserOptions.customerRoleOption, userObj.roleName)
                isHasOutOfScopeRights = returnObj.HasOutOfScopeRights;
            } else {
                var returnObj = this.checkHasOutOfScopeRight(this.state.sharedUserOptions.warehouseRoleOption, userObj.roleName)
                if(returnObj){
                    isHasOutOfScopeRights = returnObj.HasOutOfScopeRights;
                }
            }
            if (isHasOutOfScopeRights) {
                this.setState({
                    isOpenConfirmModel: true
                });
                return;
            }
        }

        this.setState({ saveLoader: true });

        this.saveUserCall();
    }
    saveUserCall = () => {
        let userObj = JSON.parse(JSON.stringify(this.state.sharedUser));
        if (typeof userObj.roleName == 'undefined' || userObj.roleName == '' || userObj.roleName == null) {
            userObj.UiRoleNames = null;
        } else {
            userObj.UiRoleNames = [userObj.roleName];
        }

        if (userObj.CellPhone) {
            userObj.CellPhone = $('#CellPhone').cleanVal();
        }

        //userObj.CreationDate = today.getMonth() + "/" + today.getDate() + "/" + today.getFullYear()
        // HACK I HATE MVC, it can't return us dates right so we have to hack stuff in to get it to be a valid date
        if (userObj.CreationDate) {
            if (userObj.CreationDate.indexOf('Date') != -1) {
                let date = new Date(parseInt(userObj.CreationDate.substr(6)));
                userObj.CreationDate = date.toISOString();

            }
        }
        // get selected facilityIds from dropdown options
        let facilityOpt =(userObj.userType == 'warehouseAccess') ? this.state.sharedUserOptions.allFacilities : this.refs.manageUsersAccountDetails.state.facilityGridData
        let selected = [];
        _.filter(facilityOpt, function(objCust){
            if(userObj.FacilityIds.includes(objCust.Id)){
                selected.push(objCust.Id);
            }
        });
        console.log("facilityOpt selected",selected);
        userObj.FacilityIds = selected;

        if(userObj.userType == 'warehouseAccess'){
            delete userObj.CustomerIds;
        }
        delete userObj.userType;
        delete userObj.roleName;
        if (this.props.sharedUserUri == '') {

            userService.createUser(userObj).then((response) => {
                this.setState({ saveLoader: false });
                this.onCloseConfirmModel();
                if (response.status == 200 || response.status == 204) {
                    GlobalService.notification('success', this.translate('Label_CreateUser_Success'));
                    this.props.onReloadGrid('reloadLocationGrid');
                    this.props.onExitUserForm();
                    var eventItem = new CustomEvent("onUserRoleUpdate");
                    document.dispatchEvent(eventItem);
                } else {
                    GlobalService.apiFailer(response);
                }

            }).finally(() => {
                this.onCloseConfirmModel();
                this.setState({ saveLoader: false });
            });

        } else {
            // HACK I HATE THOMAS AND MVC
            if (userObj.LastLoginDate) {
                if (userObj.LastLoginDate.indexOf('Date') != -1) {
                    let date = new Date(parseInt(userObj.LastLoginDate.substr(6)));
                    userObj.LastLoginDate = date.toISOString();

                }
            }
            userService.updateUser(userObj).then((response) => {
                this.onCloseConfirmModel();
                if (response.status == 200 || response.status == 204) {

                    GlobalService.notification('success', this.translate('Label_User_Updated'));
                    this.props.onReloadGrid('reloadLocationGrid');
                    this.setState({ saveLoader: false });
                    this.props.onExitUserForm();
                    var eventItem = new CustomEvent("onUserRoleUpdate");
                    document.dispatchEvent(eventItem);
                } else {
                    GlobalService.apiFailer(response);
                }

            }).finally(() => {
                this.onCloseConfirmModel();
                this.setState({ saveLoader: false });
            });
        }
    }
    checkHasOutOfScopeRight(list, roleName) {
        return list.find((element) => {
            if (element.Name == roleName) {
                return element;
            }
        });
    }

    onCloseConfirmModel = () => {
        this.setState({ isOpenConfirmModel: false });
    }

    clickOnContinueButton = () => {
        this.setState({ saveLoader: true });
        this.saveUserCall();
    }
    isAllValid(){
        const UseCognitoLogin = GlobalService.CurrentUser.UseCognitoLogin;
        const isHubLogin = GlobalService.GetCurrentUser()?.IsJwtLogin;
        if(UseCognitoLogin || isHubLogin){
            return this.state.sharedUser.roleName == '' || this.state.sharedUser.roleName == 'Unspecified' ||
                (this.state.sharedUser.userType == 'customerAccess' && this.state.sharedUser.CustomerIds.length == 0) ||
                this.state.saveLoader;
        }else{
            return this.state.sharedUser.Login == '' ||
                this.state.sharedUser.NewPassword == '' ||
                this.state.sharedUser.confirmPassword == '' ||
                (this.state.sharedUser.NewPassword != this.state.sharedUser.confirmPassword) ||
                this.state.sharedUser.Name == '' ||
                this.state.sharedUser.Email == '' ||
                this.state.sharedUser.roleName == '' || this.state.sharedUser.roleName == 'Unspecified'||
                this.state.sharedUser.Email == null ||
                this.state.sharedUser.userType == '' ||
                (this.state.sharedUser.userType == 'customerAccess' && this.state.sharedUser.CustomerIds.length == 0) ||
                this.state.saveLoader;
        }
    }
    render() {
        return (
            <WmsModel
                id='ManageUserAccountModel'
                title={this.props.sharedUserUri ? this.translate('Label_Manage_Users_Beta_Edit') : this.translate('Label_Manage_Users_Beta_Create')}
                width={800}
                height={800}
                onCloseFunc={this.props.onExitUserForm}
                isOpen={this.props.sharedIsOpenManageUserFrom}
                customClass='wms-model-new-ui-wrapper'>
                    <div className="model-content-wrapper">
                        <div className="user-form" id="form_block" >
                            <div id="ManageUserScrollBox" className={this.props.windowId + " tab-content"}>
                                <ManageUsersAccountDetails
                                    ref="manageUsersAccountDetails"
                                    sharedUserOptions={this.state.sharedUserOptions}
                                    sharedUser={this.state.sharedUser}
                                    sharedLoggedInUser={this.state.sharedLoggedInUser}
                                    sharedLoggedInUserId={this.state.sharedLoggedInUserId}
                                    windowId={this.props.windowId}
                                    sharedUserUri={this.props.sharedUserUri}
                                    onUserDataset={this.onUserDataset} />
                            </div>
                            <WmsConfirmModel
                                id="userFormConfirmModel"
                                isOpen={this.state.isOpenConfirmModel}
                                width={500}
                                height={250}
                                firstBtnLabel={this.translate('RoleManager_Label_Continue')}
                                firstBtnIcon={"fa fa-check-square"}
                                firstBtnOnClickFunc={this.clickOnContinueButton}
                                thirdBtnLabel={this.translate('RoleManager_Label_Back')}
                                thirdBtnIcon={"fa fa-arrow-left"}
                                conFormModelText={this.translate('AreYouSure_customer_warehouse_user')}
                                sawCheckBox={false}
                                isCallToFront={false}
                                onCloseFunc={this.onCloseConfirmModel}
                            />
                        </div>
                    </div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={this.translate('Label_Canceling')}
                            wmsSelector={this.state.Selector + 'ButtonExit'}
                            onClickFunc={this.props.onExitUserForm}
                            icon='fa-ban'
                            buttonType="orange" />
                        <WmsButton
                            label={this.translate('Label_Save')}
                            wmsSelector={this.state.Selector + 'SaveButton'}
                            onClickFunc={this.onClickSaveUser}
                            icon='fa-floppy-o'
                            // showToolTip={true}
                            // tooltipText={
                            //     (this.state.sharedUser.Name == '' ||
                            //         this.state.sharedUser.Email == '' ||
                            //         this.state.sharedUser.Email == null ||
                            //         this.state.sharedUser.Login == '' ||
                            //         this.state.sharedUser.NewPassword == '' ||
                            //         this.state.sharedUser.confirmPassword == '' ||
                            //         (this.state.sharedUser.NewPassword != this.state.sharedUser.confirmPassword)) ?
                            //         this.translate('Tooltip_Required_Fields') :
                            //         ((this.state.sharedUser.userType == '') ?
                            //             this.translate('Users_Tooltip_Select_User_TyPe') :
                            //             (this.state.sharedUser.roleName == '' || this.state.sharedUser.roleName == 'Unspecified') ?
                            //             this.translate('Tooltip_Required_Fields') : (
                            //                 (this.state.sharedUser.userType == 'customerAccess') && this.state.sharedUser.CustomerIds.length == 0
                            //                 ) ? this.translate('Tooltip_Required_Customer')
                            //                 : ''
                            //         )
                            // }
                            disabled={ this.isAllValid()}
                            loading={this.state.saveLoader} />



                    </div>
            </WmsModel>
        )
    }
}