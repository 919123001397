
import React from 'react';
import WmsConfirmModel from '../../components/WmsConfirmModel/WmsConfirmModel.jsx';
export class ConfirmationModal extends React.Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;
        this.$rootScope = window;
    }

    render() {
        return (
            <div className="content-wrapper" >
                {/*Add or exist batch model */}
                <WmsConfirmModel
                    id="BatchCofirmModel"
                    isOpen={this.props.isOpenBatchConfirmModal}
                    width={500}
                    height={180}
                    firstBtnLabel={this.translate('Label_Continue')}
                    firstBtnIcon={"fa fa-check-square-o"}
                    firstBtnOnClickFunc={this.props.onContinueBatchModal}
                    thirdBtnLabel={this.translate('Label_Discard_Exit')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText={this.translate('Label_Orders_PartOfBatch')}
                    sawCheckBox={false}
                    onCloseFunc={this.props.onCloseBatchModal}
                />
                {/*Remove batch model */}
                <WmsConfirmModel
                    id="RemoveBatchCofirmModel"
                    isOpen={this.props.isOpenRemoveBatchConfirmModal}
                    width={500}
                    height={180}
                    firstBtnLabel={this.translate('Label_Continue')}
                    firstBtnIcon={"fa fa-check-square-o"}
                    firstBtnSelector={"RemoveBatchContinueButton"}
                    firstBtnOnClickFunc={this.props.onContinueRemoveBatchModal}
                    thirdBtnLabel={this.translate('Label_Exit')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText={this.translate('Label_Orders_RemoveFromBatch')}
                    sawCheckBox={false}
                    onCloseFunc={this.props.onCloseRemoveBatchModal}
                />
            </div>
        )
    }
}