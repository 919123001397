import * as GlobalService from '../../global_services';
var isFirstTimeOpen = true;
var keyName = "ManageOrderProto";
var metaDataOrderObject = {
    "ManageOrderProto": {
        "collapseData": {

        },
        "gridConfig": {
            "appliedChargesGrid": {

            },
            "customOrderInfo": {

            },
            "OrderLineItemBeta":{

            },
            "OrderLineItemBetaFulFillment":{

            }
        }
    }
};


// call api for get metaData of order is it's open first time
export const getManageOrderProtoUserClientMetaData = function () {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response != "") {
                    Object.assign(metaDataOrderObject[keyName], JSON.parse(response));
                }
                console.log("response  metaDataOrderObject :", metaDataOrderObject);
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                console.log(response);
                return false;
            }
        );
    }
}

export const setManageOrderProtoCollapseMetaData = function (collapseId, value) {
    metaDataOrderObject[keyName]['collapseData'][collapseId] = value;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrderProtoCollapseMetaData = function () {
    return metaDataOrderObject[keyName]['collapseData'];
}

// For custom order info set and get
export const setManageOrderProtoCustomOrderInfoMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['gridConfig']['customOrderInfo'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrderProtoCustomOrderInfoMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['gridConfig']['customOrderInfo'])) {
        return metaDataOrderObject[keyName]['gridConfig']['customOrderInfo'];
    } else {
        return "";
    }
}

export const setManageOrderProtoAppliedChargesGridOptionMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['gridConfig']['appliedChargesGrid'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrderProtoAppliedChargesGridOptionMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['gridConfig']['appliedChargesGrid'])) {
        return metaDataOrderObject[keyName]['gridConfig']['appliedChargesGrid'];
    } else {
        return "";
    }
}

export const setManageOrderProtoLineItemGridOptionMetaData = function (gridConfig,isFulFillmentEnable) {
    var metaTitle = isFulFillmentEnable ?  'OrderLineItemBetaFulFillment' : 'OrderLineItemBeta';
    metaDataOrderObject[keyName]['gridConfig'][metaTitle] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getManageOrderProtoLineItemGridOptionMetaData = function (isFulFillmentEnable) {
    var metaTitle = isFulFillmentEnable ?  'OrderLineItemBetaFulFillment' : 'OrderLineItemBeta';
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['gridConfig'][metaTitle])) {
        return metaDataOrderObject[keyName]['gridConfig'][metaTitle];
    } else {
        return "";
    }
}
