import * as GlobalService from "./../global_services";
declare var window: any;
var isFirstTimeOpen = true;
const { $ } = window;
var axios = require("axios");
var keyName = "CustomerPackingSlips";
var metaDataOrderObject = {
    CustomerPackingSlips: {
        ManageCustomerPackingSlips: {
            filterData: {},
            gridConfig: {},
            collapseData: {},
            sorableSection: "",
            saveFilter: [],
        },
    },
};

export const getCustomerPackingSlipsGridData = function (editUri:string) {
    let url = "/WebUI/Customers/CustomerPackingListTemplates/PackingListTemplates?uri="+editUri+"/packinglisttemplates";
    return axios.get(url).then(function (response: any) {
        return response;
    });
};
// export const saveCustomerPackingSlipsData = function (data: any) {
//     let url = "/WebUI/Settings/PackingListTemplates/PackingListTemplate";

//     return axios.post(url, data).then(function (response: any) {
//         return response;
//     });
// };
export const getCustomerPackingSlipsData = function (templateId: number) {
    let url =
        "/WebUI/Settings/PackingListTemplates/PackingListTemplate?editUri=properties/packinglisttemplates/" +
        templateId;

    return axios.get(url).then(function (response: any) {
        return response;
    });
};
export const updateCustomerPackingSlipsData = function (data: any) {
    let url = "/WebUI/Customers/CustomerPackingListTemplates/PackingListTemplate";

    return axios.put(url, data).then(function (response: any) {
        return response;
    });
};
export const deleteCustomerPackingSlipsData = function (uri: string) {
    let url =
        "/WebUI/Customers/CustomerPackingListTemplates/PackingListTemplate?editUri=" + uri;

    return axios.delete(url).then(function (response: any) {
        return response;
    });
};

export const getUserClientMetaData = function () {
    console.log("isFirstTimeOpen", isFirstTimeOpen);
    if (isFirstTimeOpen) {
        console.log("isFirstTimeOpen call", isFirstTimeOpen);
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response !== "") {
                    Object.assign(
                        metaDataOrderObject[keyName],
                        JSON.parse(response)
                    );
                }
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                console.log(response);
                return false;
            }
        );
    }
};

export const saveEventNotificationGridData = function (
    cstEditUri: string,
    data: any
) {
    let url = "/WebUI/Customers/WebHooksConfig/GridSave";
    if (cstEditUri) {
        url = url + "?editUri=" + cstEditUri;
    }
    return axios.put(url, data).then(function (response: any) {
        return response;
    });
};
export const setCustomerPackingSlipsGridOptionInMetaData = function (
    gridConfig
) {
    metaDataOrderObject[keyName]["ManageCustomerPackingSlips"]["gridConfig"] =
        gridConfig.optionsData;
    GlobalService.setUserClientMetaData(
        keyName,
        JSON.stringify(metaDataOrderObject[keyName])
    );
};

export const getCustomerPackingSlipsGridOptionInMetaData = function () {
    if (
        !$.isEmptyObject(
            metaDataOrderObject[keyName]["ManageCustomerPackingSlips"][
                "gridConfig"
            ]
        )
    ) {
        return metaDataOrderObject[keyName]["ManageCustomerPackingSlips"][
            "gridConfig"
        ];
    } else {
        return "";
    }
};

export const base64File = async function (url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise(resolve => {
        resolve(blob);
        // const reader = new FileReader();
        // reader.readAsDataURL(blob);
        // reader.onloadend = () => {
        //     const base64data = reader.result;
        //     resolve(base64data);
        // };
    });
}
