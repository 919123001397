
import React from 'react';

// component
import { ItemsGrid } from './items-grid.component.jsx';
import { ItemsForm } from './items-form.component.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';

// services
import * as itemService from './items.services.jsx';
import * as itemMeta from './meta-data.service.js';

import style from './items.scss';
import * as GlobalService from './../global_services';

export class ItemsHolder extends React.Component {

    constructor(props) {

        super(props);

        this.$rootScope = window;

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            customerOption: [],
            sharedSelectedItemIdsArr: [],
            selectedCustomerIndex: "",
            sharedCurrentCustomerObj: {},
            sharedItemOptions: {
                EnumContains: [],
                BooleanOperatorOptions: []
            },
            sharedItemsFilterData: {},
            sharedRecurringOptions: {},
            sharedItemUri: null,
            sharedItemRateUri: null,
            sharedMultiSkuEnableRecurring: false,
            reloadGrid: '',
            receiptObj: '',
            lockedSection: 'item-form-tab-section',
            sharedDefaultStorageHandlingCharges: []

        }
    }

    componentDidMount() {
        var that = this;
        // open create location form from edit receipt > add line item
        document.addEventListener("showItem", this.onOpenItemFromReceipt);
        kendo.ui.progress($('#' + this.state.windowId), true);
        itemService.getitemsInitial().then((response) => {

            this.setState({
                sharedItemOptions: response.data.Options,
                sharedItemsFilterData: response.searchInfo.data.Data,
                customerOption: response.customerList,
                sharedRecurringOptions: response.recurringStorageResponse
            });

            if (this.$rootScope.ChildBulletin.Data.receiptObj) {
                var customerObj = this.getCustomerById(this.$rootScope.ChildBulletin.Data.receiptObj.CustomerId);
                this.setState({
                    sharedCurrentCustomerObj: customerObj,
                    selectedCustomerIndex: this.$rootScope.ChildBulletin.Data.receiptObj.CustomerId,
                    receiptObj: this.$rootScope.ChildBulletin.Data.receiptObj
                });
                this.onCreatItem();
            } else {
                var customerId = itemMeta.getCustomerIdFilterMetaData();
                if (customerId) {
                    var customerObj = this.getCustomerById(customerId);
                    if (customerObj) {
                        this.setState({
                            sharedCurrentCustomerObj: customerObj,
                            selectedCustomerIndex: customerId
                        });
                        itemService.getDefaultStorageHandlingCharges(customerId).then((response) => {
                            if (response && response.status == 200) {
                                this.setState({
                                    sharedDefaultStorageHandlingCharges: response.data
                                })
                            }
                        }).finally(() => {
                        });
                    }
                }
            }

        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
        $('#items-holder-react').closest('.k-window-content').css("padding", "0");
        document.addEventListener("customerFacilityOptionsUpdate", this.updateCustomerOptions);
    }
    updateCustomerOptions = () => {
        if (this.state.selectedCustomerIndex) {
            var customerOptions = GlobalService.getCustomerList(false);
            var customerObj = GlobalService.getCustomerById(this.state.selectedCustomerIndex);
            if (customerObj) {
                this.setState({
                    customerOption: customerOptions,
                    sharedCurrentCustomerObj: customerObj
                });
            } else {
                this.resetFilter();
            }
        }
    }

    onOpenItemFromReceipt = (e) => {
        var customerObj = this.getCustomerById(e.detail.receiptObj.CustomerId);
        this.setState({
            sharedCurrentCustomerObj: customerObj,
            selectedCustomerIndex: e.detail.receiptObj.CustomerId,
            receiptObj: e.detail.receiptObj
        });
        this.onCreatItem();
    }
    componentWillUnmount() {
        document.removeEventListener("showItem", this.onOpenItemFromReceipt);
        $('#items-holder-react').closest('.k-window-content').css("padding", "26");
        document.removeEventListener("customerFacilityOptionsUpdate", this.updateCustomerOptions);
    }
    handleCustomerSelectChange = (event) => {
        this.$rootScope.setLoaderTimer('Manage Items');
        var customerObj = this.getCustomerById(event.target.value);
        this.setState({
            sharedCurrentCustomerObj: customerObj,
            selectedCustomerIndex: event.target.value,
            sharedItemUri: null
        });
        itemMeta.setCustomerIdFilterMetaData(event.target.value);
        if (event.target.value) {
            itemService.getDefaultStorageHandlingCharges(event.target.value).then((response) => {
                if (response && response.status == 200) {
                    console.log("DefaultStorageHandlingCharges response", response);
                    this.setState({
                        sharedDefaultStorageHandlingCharges: response.data
                    })
                }
            }).finally(() => {
            });
        }
    }

    getCustomerById(id) {
        return this.state.customerOption.find((element) => {
            if (element.id == id) {
                return element;
            }
        });
    }

    onCreatItem = () => {
        this.setState({
            sharedItemUri: '',
            sharedItemRateUri: '',
            lockedSection: '',
            sharedMultiSkuEnableRecurring: false
        }, () => {
            //Hack click on item basics tab to activate tab and shaw tab content
            $('#ItemBasicsTab').find('label').trigger('click');
        });
    }

    onEditItem = (param_itemuri, param_itemrateuri, param_itemarr) => {
        this.setState({
            sharedItemUri: param_itemuri,
            sharedItemRateUri: param_itemrateuri,
            lockedSection: '',
            sharedMultiSkuEnableRecurring: false,
            sharedSelectedItemIdsArr: param_itemarr
        }, () => {
            //Hack click on item basics tab to activate tab and shaw tab content
            $('#ItemBasicsTab').find('label').trigger('click');
        });
    }
    // enable recurring storage on multiple item select
    onEnableReccurringStorageOnly = (param_itemarr) => {
        this.setState({
            sharedMultiSkuEnableRecurring: true,
            sharedItemUri: null,
            sharedItemRateUri: null,
            lockedSection: '',
            sharedSelectedItemIdsArr: param_itemarr
        }, () => {
            //Hack click on recurring storage tab to activate tab and shaw tab content
            $('#recurringStorageTab').find('label').trigger('click');
        });
    }


    onExitItemForm = () => {
        this.setState({
            sharedItemUri: null,
            sharedItemRateUri: null,
            sharedMultiSkuEnableRecurring: false,
            reloadGrid: '',
            lockedSection: 'item-form-tab-section'
        });
    }

    onReloadGrid = (basecall) => {
        this.setState({ reloadGrid: basecall });
    }

    onLockSection = (param_section) => {
        this.setState({ lockedSection: param_section })
    }

    resetFilter = () => {
        this.setState({
            selectedCustomerIndex: '',
            sharedCurrentCustomerObj: {},
        });
        itemMeta.clearItemFilterMetaData();
    }

    render() {
        return (
            <div id="items-holder-react" >
                <ItemsGrid
                    sharedCurrentCustomerObj={this.state.sharedCurrentCustomerObj}
                    sharedItemsFilterData={this.state.sharedItemsFilterData}
                    sharedItemOptions={this.state.sharedItemOptions}
                    onCreatItem={this.onCreatItem}
                    onEditItem={this.onEditItem}
                    reloadGrid={this.state.reloadGrid}
                    resetFilter={this.resetFilter}
                    windowId={this.state.windowId}
                    handleCustomerSelectChange={this.handleCustomerSelectChange}
                    sharedCustomerOption={this.state.customerOption}
                    sharedSelectedCustomerIndex={this.state.selectedCustomerIndex}
                    sharedLockedSection={this.state.lockedSection}
                    onEnableReccurringStorageOnly={this.onEnableReccurringStorageOnly}
                />
                {/* (this.state.sharedItemUri != null || this.state.sharedItemRateUri != null) &&  */}
                {this.state.selectedCustomerIndex != '' && (
                    <ItemsForm
                        sharedCurrentCustomerObj={this.state.sharedCurrentCustomerObj}
                        sharedItemOptions={this.state.sharedItemOptions}
                        onExitItemForm={this.onExitItemForm}
                        onReloadGrid={this.onReloadGrid}
                        sharedItemUri={this.state.sharedItemUri}
                        sharedItemRateUri={this.state.sharedItemRateUri}
                        windowId={this.state.windowId}
                        receiptObj={this.state.receiptObj}
                        onLockSection={this.onLockSection}
                        sharedLockedSection={this.state.lockedSection}
                        sharedRecurringOptions={this.state.sharedRecurringOptions}
                        sharedMultiSkuEnableRecurring={this.state.sharedMultiSkuEnableRecurring}
                        sharedSelectedItemIdsArr={this.state.sharedSelectedItemIdsArr}
                        sharedDefaultStorageHandlingCharges={this.state.sharedDefaultStorageHandlingCharges}
                    />
                )}
            </div >
        )
    }
}