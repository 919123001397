import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

//component
import WmsModel from "./../react/components/WmsModel/WmsModel";
import WmsDropdown from "./../react/components/WmsDropdown/WmsDropdown";
import WmsGrid from "./../react/components/WmsGrid/WmsGrid";
import WmsInput from "./../react/components/WmsInput/WmsInput";
import WmsDateTimePicker from "./../react/components/WmsDateTimePicker/WmsDateTimePicker";
import WmsButton from "./../react/components/WmsButton/WmsButton";
import * as GlobalService from "./../react/global_services";
import { StringWms } from "./../../assets/ts/StringWms";
import { KendoGridWms } from "./../../assets/ts/KendoGridWms";
import WmsContainer from "./../react/components/WmsContainer/WmsContainer";
import "./manageassembly.scss";

import { kendo } from "./../react/global_variable_service";
import * as ManageAssemblyServices from "./manage-assembly.services";
import * as GridConfig from "./manage-assembly-grid-configuration";

declare var window: any;
const { $ } = window;
interface Props {
    wmsSelector: string;
    windowId: string;
    isOpenEditAllocationModal: boolean;
    onCloseEditAllocationModal: any;
    isEditAllocationLoading: boolean;
    AllocationObj: any;
    onResetAllocationGridAndSelection: any;
    onGridItemChange: any;
}
const defaultFilter = {
    IndexOptionsSku: 0,
    Sku: "",
    IndexOptionsDescription: 0,
    Description: "",
    IndexOptionsQualifier: 0,
    Qualifier: "",
    IndexOptionsAvailableQty: 0,
    CustomField: "",
    IndexOptionsLotNumber: 0,
    LotNumber: "",
    IndexOptionsSerialNumber: 0,
    SerialNumber: "",
    IndexOptionsSupplier: 0,
    Supplier: "",
    IndexOptionsCost: 0,
    Cost: "",
    ExpDateBegin: null,
    ExpDateEnd: null,
    IndexOptionsReceiptRefId: 0,
    ReceiptRefId: "",
    IndexOptionsPoNumber: 0,
    PoNumber: "",
    IndexOptionsLocation: 0,
    Location: "",
    IndexOptionsPallet: 0,
    Pallet: "",
    IndexOptionsTrailerContainerNumber: 0,
    TrailerContainerNumber: "",
    ReceiptFirstTransaction: null,
    ReceiptLastTransaction: null,
    ReceiptBeginDate: null,
    ReceiptEndDate: null,
};

const EditAllocationModal: React.FC<Props> = (props: Props): any => {
    const wmsSelector = props.wmsSelector + "_OrderAllocation";
    const gridEditAllocationHeader = window.translate("Orders_ManageOrders_EditAllocations_Grid").split(",");
    const gridMassAddHeader = window.translate("Grid_Mass_Add_Column_Specs").split(",");
    const optionsSearchContains = StringWms.convertSringToOption(window.translate("Orders_FindOrders_SearchFilters"));
    const optionsSearchByCost = StringWms.convertSringToOption(window.translate("Orders_ManageOrders_SearchByCost"));
    const editAllocationColumns = GridConfig.getEditAllocationColumns(
        gridEditAllocationHeader,
        GlobalService.getCustomLabels(),
        $
    ).columns;
    const customLabels = GlobalService.getCustomLabels();
    const editAllocationSchema = GridConfig.getEditAllocationSchema(
        "#" + props.AllocationObj.modalType + props.windowId,
        $
    ).schema;
    const editAllocationTransport = GridConfig.getEditAllocationTransport(
        props.AllocationObj.CustomerId,
        props.AllocationObj.FacilityId,
        props.AllocationObj.ItemId,
        props.AllocationObj.Qualifier,
        props.AllocationObj.OrderItemId
    ).transport;
    const massAddcolumns = GridConfig.getMassAddColumns(gridMassAddHeader, GlobalService.getCustomLabels()).columns;
    const massAddSchema = GridConfig.getMassAddSchema(window.translate("Label_Enter_Valid_Number")).schema;
    const massAddTransport = GridConfig.getMassAddTransport(
        props.AllocationObj.CustomerId,
        props.AllocationObj.FacilityId
    ).transport;

    const [KendoGrid] = useState(
        new KendoGridWms(props.AllocationObj.modalType + props.windowId, props.windowId, "Key", "")
    );
    const [makeOrderedQuantityMatchAllocatedQuantity, setMakeOrderedQuantityMatchAllocatedQuantity] = useState(false);
    const [valueGreater, setValueGreater] = useState(false);
    const [gifEditAllocation, setGifEditAllocation] = useState(false);
    const [totalQuantity, setTotalQuantity] = useState(0); // this holds the total amount the user has inputted in their column
    const [disableSave, setDisableSave] = useState(true);
    const [filter, setFilter] = useState(defaultFilter);
    const [filterObject, setFilterObject] = useState([]);
    const [savingGif, setSavingGif] = useState({ savingGifAddNew: false, savingGifAddExit: false });
    const isGridEditAllocations = props.AllocationObj.modalType === "GridEditAllocations";

    useEffect(() => {
        setIntialEditAllocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEditAllocationGridBinding = () => {
        if (isGridEditAllocations) {
            var grid = $("#" + props.AllocationObj.modalType + props.windowId).data("kendoGrid");
            var gridData = grid.dataSource.view();
            var myCollection = KendoGrid.getDictionary().items;
            for (var i = 0; i < gridData.length; i++) {
                var key = gridData[`${i}`].Key;
                if (myCollection.hasOwnProperty(key)) {
                    gridData[`${i}`].PrimaryQuantity = myCollection[`${key}`].Qty;
                }
            }
        }
    };

    const setIntialEditAllocation = () => {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        kendo.ui.progress($("#manageAllocation" + props.windowId), true);
        if (isGridEditAllocations) {
            ManageAssemblyServices.initialEditAllocations({
                "assemblyId": props.AllocationObj.assemblyId,
                "assemblyItemUri": props.AllocationObj.EditWithAllocationsDetailsUri,
            })
                .then((response) => {
                    // we keep track of everything using a Key, so we need to add this key to our data return to use in our dictionary
                    for (var x = 0; x < response.length; x++) {
                        response[`${x}`].Key = response[`${x}`].ReceiveItemId;
                    }
                    KendoGrid.addMassToDictionary(response);
                    setDefaultSearchFilter();
                    let badBrowser = isIEorEDGE();
                    if (badBrowser) {
                        $("#IEdead").height(8);
                    }
                    setTotalQuantity(props.AllocationObj.OrderQty);
                })
                .finally(() => {
                    kendo.ui.progress($("#manageAllocation" + props.windowId), false);
                });
        } else {
            ManageAssemblyServices.initialMassAdd()
                .then((response) => {
                    setDefaultSearchFilter();
                })
                .finally(() => {
                    kendo.ui.progress($("#manageAllocation" + props.windowId), false);
                });
        }
    };

    const gridSearch = () => {
        let filterObj = JSON.parse(JSON.stringify(filter));
        filterObj.method = "POST";
        setFilterObject([filterObj]);
    };

    const clearFilters = () => {
        let filterObj = defaultFilter as any;
        setDefaultSearchFilter();
        filterObj.method = "POST";
        setFilterObject([filterObj]);
    };

    const setDefaultSearchFilter = () => {
        setFilter(defaultFilter);
    };

    const onGridChange = (event) => {
        let inputName = isGridEditAllocations ? "PrimaryQuantity" : "PrimaryUnitsToAdd";
        if (event.field === inputName) {
            let tempvalueGreater = false;
            if (parseFloat($("input[name='" + inputName + "']").val()) > event.items[0].Available) {
                tempvalueGreater = true;
            }

            setValueGreater(tempvalueGreater);
        }
    };

    const onGridSave = (event) => {
        var validator = event.container.data("kendoValidator");
        validator.options.messages.custom = window.translate("Label_Greater_Allocation_Warning");
        let inputName = isGridEditAllocations ? "PrimaryQuantity" : "PrimaryUnitsToAdd";
        validator.options.rules.custom = function (input) {
            if (input.is('[name="' + inputName + '"]')) {
                if (parseFloat(input.val()) > parseFloat(event.model.Available)) {
                    return false;
                }
                return true;
            }
            return true;
        };
        if (isGridEditAllocations) {
            if (validator.validate()) {
                let finalModel = event.model;
                finalModel.PrimaryQuantity = event.values.PrimaryQuantity;
                // We add to our dictionary with the parameter of override, so if our dictionary finds this key it will override it with the new amount
                KendoGrid.addSelections(
                    event.model.Key,
                    {
                        "OrderId": props.AllocationObj.orderID,
                        "OrderItemId": props.AllocationObj.orderItemId,
                        "ReceiveItemId": parseInt(event.model.Key),
                        "Qty": event.model.PrimaryQuantity,
                    },
                    "override"
                );
                setDisableSave(false);
                setTotalQuantity(KendoGrid.sumDictionaryParameter("Qty"));
            }
        } else {
            let finalModel = event.model;
            finalModel.PrimaryUnitsToAdd = event.values.PrimaryUnitsToAdd;
            KendoGrid.addSelections(event.model.Key, finalModel, "override");
            KendoGrid.setQuantityCollection(event.model.Key, event.values.PrimaryUnitsToAdd, {});

            var temp = event.model;
            temp.PrimaryQuantity = event.values.PrimaryUnitsToAdd;
            KendoGrid.setQuantityArray(temp);
        }
    };

    const filterHandelChange = (event) => {
        let filterObj = { ...filter };
        filterObj[event.target.name] = event.target.value;
        setFilter(filterObj);
    };

    const eventClickSaveAllocation = () => {
        kendo.ui.progress($("#manageAllocation" + props.windowId), true);
        var list = KendoGrid.getDictionary().items;
        var keys = KendoGrid.getKeys();

        var newlist = [];
        var sumOfNewQuantity = 0;
        for (var x = 0; x < keys.length; x++) {
            var keysX = keys[`${x}`];
            if (list[`${keysX}`].hasOwnProperty("Key")) {
                delete list[`${keysX}`].Key;
            }
            if (list[`${keysX}`].Qty === null) {
                list[`${keysX}`].Qty = 0;
            }
            sumOfNewQuantity = sumOfNewQuantity + list[`${keysX}`].Qty;
            let listItem = list[`${keysX}`];
            newlist.push({ Qty: listItem.Qty, ReceiveItemId: listItem.ReceiveItemId });
        }
        if (sumOfNewQuantity > props.AllocationObj.totalOrdered) {
            setMakeOrderedQuantityMatchAllocatedQuantity(true);
        }
        if (props.AllocationObj.totalOrdered <= totalQuantity) {
            editOrderLineItemAllocations(newlist);
        }
    };

    const editOrderLineItemAllocations = (newlist) => {
        setGifEditAllocation(true);
        ManageAssemblyServices.editOrderLineItemAllocations({
            assemblyLineItemEditUri: props.AllocationObj.EditWithAllocationsDetailsUri,
            allocations: newlist,
            makeOrderedQuantityMatchAllocatedQuantity: makeOrderedQuantityMatchAllocatedQuantity,
        })
            .then((response) => {
                if (response && (response.status === 200 || response.status === 204)) {
                    GlobalService.notification("success", window.translate("Label_Success_Items_Updated"));
                    eventClickDiscardButton();
                    // method call to set discard button for react old order version
                    if (props.onGridItemChange) {
                        props.onGridItemChange(true);
                    }
                    props.onResetAllocationGridAndSelection();
                    props.onGridItemChange();
                }
            })
            .finally(() => {
                setGifEditAllocation(false);
                kendo.ui.progress($("#manageAllocation" + props.windowId), false);
            });
    };

    const isIEorEDGE = () => {
        if (navigator.appName === "Microsoft Internet Explorer") {
            return true; // IE
        } else if (navigator.appName === "Netscape") {
            return navigator.appVersion.indexOf("Edge") > -1; // EDGE
        }
        return false;
    };
    const onLoadGrid = () => {
        setTotalQuantity(KendoGrid.sumDictionaryParameter("Qty"));
    };

    const eventClickDiscardButton = () => {
        $("#manageAllocation" + props.windowId)
            .data("kendoWindow")
            .close();
        props.onCloseEditAllocationModal();
    };

    const addLineItem = (param_baseCall) => {
        setSavingGif((previousState) => ({ ...previousState, ["savingGif" + param_baseCall]: true }));
        var passing = KendoGrid.getValues();
        kendo.ui.progress($("#manageAllocation" + props.windowId), true);

        ManageAssemblyServices.massOrderLineItemsAdd({
            orderId: props.AllocationObj.OrderId,
            itemsUri: props.AllocationObj.OrderItemsUri,
            details: passing,
        })
            .then((response) => {
                setSavingGif((previousState) => ({ ...previousState, ["savingGif" + param_baseCall]: false }));

                if (param_baseCall === "AddExit") {
                    eventClickDiscardButton();
                }

                if (response && (response.status === 200 || response.status === 204)) {
                    kendo.ui.progress($("#manageAllocation" + props.windowId), false);

                    GlobalService.notification("success", window.translate("Label_Line_Item_Save_Success"));
                    if (param_baseCall === "AddNew") {
                        KendoGrid.clearSelections(() => {
                            // eslint-disable-next-line sonarjs/no-duplicate-string
                            if (typeof $("#GridMassAdd" + props.windowId).data("kendoGrid") != "undefined") {
                                $("#GridMassAdd" + props.windowId)
                                    .data("kendoGrid")
                                    .dataSource.read();
                            }
                            $("#GridMassAdd" + props.windowId)
                                .data("kendoGrid")
                                .refresh();
                        });
                    }

                    // method call to set discard button for react old order version
                    if (props.onGridItemChange) {
                        props.onGridItemChange(true);
                    }
                    props.onResetAllocationGridAndSelection();
                }
            })
            .finally(() => {
                setSavingGif((previousState) => ({ ...previousState, ["savingGif" + param_baseCall]: false }));
                kendo.ui.progress($("#manageAllocation" + props.windowId), false);
            });
    };
    return (
        <WmsModel
            id={"manageAllocation" + props.windowId}
            class={"manageAllocation"}
            title={props.AllocationObj.modalTitle}
            width={window.innerWidth - 470 + 155}
            height={window.innerHeight - 100}
            onCloseFunc={props.onCloseEditAllocationModal}
            isOpen={props.isOpenEditAllocationModal}
            customClass="wms-model-new-ui-wrapper"
        >
            <div className="model-content-wrapper">
                <div className="grid-filter-wrapper">
                    <div className="side-content">
                        <div>
                            <WmsButton
                                label={window.translate("Label_Search")}
                                wmsSelector={wmsSelector + "SearchButton"}
                                onClickFunc={gridSearch}
                                icon="fa-search"
                            />
                            <WmsButton
                                label={
                                    isGridEditAllocations
                                        ? window.translate("Label_Clear_Filters")
                                        : window.translate("Label_Clear_Entries")
                                }
                                wmsSelector={wmsSelector + "ClearFiltersButton"}
                                onClickFunc={clearFilters}
                                icon="fa-times-circle"
                                buttonType={"yellow"}
                                showToolTip={true}
                                tooltipText={"Clear search entries and reset grid results."}
                            />
                        </div>
                        <div id="LineItemInfoSection">
                            {isGridEditAllocations && (
                                <WmsContainer title={window.translate("Label_Line_Item_Information")}>
                                    <div className="without-bg" data-wms-selector={wmsSelector + "LineItemInformation"}>
                                        <ul>
                                            <li>
                                                <h3 className="pull-left">{customLabels.Label_Sku}:</h3>
                                                <p className="pull-left">{props.AllocationObj.Sku}</p>
                                            </li>
                                            <li>
                                                <h3 className="pull-left">{window.translate("Label_Description")}:</h3>
                                                <p className="pull-left">{props.AllocationObj.ItemDescription}</p>
                                            </li>
                                            <li>
                                                <h3 className="pull-left">{window.translate("Label_Qualifier")}:</h3>
                                                <p className="pull-left">
                                                    {StringWms.isEmpty(props.AllocationObj.Qualifier)
                                                        ? "-"
                                                        : props.AllocationObj.Qualifier}
                                                </p>
                                            </li>
                                            <li>
                                                <h3 className="pull-left">
                                                    {window.translate("Label_Total_Ordered")}:
                                                </h3>
                                                <p className="pull-left">{props.AllocationObj.totalOrdered}</p>
                                            </li>
                                            <li>
                                                <h3 className="pull-left">
                                                    {window.translate("Label_Selected_Quantity")}:
                                                </h3>
                                                <p className="pull-left">{totalQuantity}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </WmsContainer>
                            )}
                        </div>

                        <div
                            className={
                                props.AllocationObj.modalType === "GridMassAdd"
                                    ? "mass-add-Modal wms-area-content-scroll-y"
                                    : "wms-area-content-scroll-y"
                            }
                        >
                            {props.AllocationObj.modalType === "GridMassAdd" && (
                                <WmsContainer title={window.translate("Label_Item_Info")}>
                                    <div className="form-group wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSku"
                                            name="IndexOptionsSku"
                                            label={customLabels.Label_Sku}
                                            wmsSelector={wmsSelector + "IndexOptionsSku"}
                                            value={filter.IndexOptionsSku}
                                            onChangeFunc={filterHandelChange}
                                            options={optionsSearchContains}
                                            valueField="value"
                                            textField="description"
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id="Sku"
                                            inputName="Sku"
                                            wmsSelector={wmsSelector + "InputSku"}
                                            value={filter.Sku}
                                            onChangeFunc={filterHandelChange}
                                            label=""
                                            parentClassName="input_box"
                                        />
                                    </div>
                                    <div className="form-group wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsDescription"
                                            name="IndexOptionsDescription"
                                            label={window.translate("Label_Description")}
                                            wmsSelector={wmsSelector + "IndexOptionsDescription"}
                                            value={filter.IndexOptionsDescription}
                                            onChangeFunc={filterHandelChange}
                                            options={optionsSearchContains}
                                            valueField="value"
                                            textField="description"
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id="Description"
                                            inputName="Description"
                                            wmsSelector={wmsSelector + "InputDescription"}
                                            value={filter.Description}
                                            onChangeFunc={filterHandelChange}
                                            label=""
                                            parentClassName="input_box"
                                        />
                                    </div>
                                    <div className="form-group wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsQualifier"
                                            name="IndexOptionsQualifier"
                                            label={window.translate("Label_Qualifier")}
                                            wmsSelector={wmsSelector + "IndexOptionsQualifier"}
                                            value={filter.IndexOptionsQualifier}
                                            onChangeFunc={filterHandelChange}
                                            options={optionsSearchContains}
                                            valueField="value"
                                            textField="description"
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id="Qualifier"
                                            inputName="Qualifier"
                                            wmsSelector={wmsSelector + "InputQualifier"}
                                            value={filter.Qualifier}
                                            onChangeFunc={filterHandelChange}
                                            label=""
                                            parentClassName="input_box"
                                        />
                                    </div>
                                    <WmsInput
                                        id="CustomField"
                                        inputName="CustomField"
                                        wmsSelector={wmsSelector + "CustomField"}
                                        value={filter.CustomField}
                                        onChangeFunc={filterHandelChange}
                                        label={window.translate("Label_Custom_Field_Value")}
                                    />
                                </WmsContainer>
                            )}
                            <WmsContainer title={window.translate("Label_Search_Track_By")}>
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsLotNumber"
                                        name="IndexOptionsLotNumber"
                                        label={customLabels.Label_Lot_Number}
                                        wmsSelector={wmsSelector + "IndexOptionsLotNumber"}
                                        value={filter.IndexOptionsLotNumber}
                                        onChangeFunc={filterHandelChange}
                                        options={optionsSearchContains}
                                        valueField="value"
                                        textField="description"
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id="LotNumber"
                                        inputName="LotNumber"
                                        wmsSelector={wmsSelector + "InputLotNumber"}
                                        value={filter.LotNumber}
                                        onChangeFunc={filterHandelChange}
                                        label=""
                                        parentClassName="input_box"
                                    />
                                </div>
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsSerialNumber"
                                        name="IndexOptionsSerialNumber"
                                        label={customLabels.Label_Serial_Number}
                                        wmsSelector={wmsSelector + "IndexOptionsSerialNumber"}
                                        value={filter.IndexOptionsSerialNumber}
                                        onChangeFunc={filterHandelChange}
                                        options={optionsSearchContains}
                                        valueField="value"
                                        textField="description"
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id="SerialNumber"
                                        inputName="SerialNumber"
                                        wmsSelector={wmsSelector + "InputSerialNumber"}
                                        value={filter.SerialNumber}
                                        onChangeFunc={filterHandelChange}
                                        label=""
                                        parentClassName="input_box"
                                    />
                                </div>
                                {props.AllocationObj.modalType === "GridMassAdd" && (
                                    <div className="form-group wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSupplier"
                                            name="IndexOptionsSupplier"
                                            label={window.translate("Label_Supplier_Company_Name")}
                                            wmsSelector={wmsSelector + "IndexOptionsSupplier"}
                                            value={filter.IndexOptionsSupplier}
                                            onChangeFunc={filterHandelChange}
                                            options={optionsSearchContains}
                                            valueField="value"
                                            textField="description"
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id="Supplier"
                                            inputName="Supplier"
                                            wmsSelector={wmsSelector + "InputSupplier"}
                                            value={filter.Supplier}
                                            onChangeFunc={filterHandelChange}
                                            label=""
                                            parentClassName="input_box"
                                        />
                                    </div>
                                )}
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsCost"
                                        name="IndexOptionsCost"
                                        label={window.translate("Label_Cost")}
                                        wmsSelector={wmsSelector + "IndexOptionsCost"}
                                        value={filter.IndexOptionsCost}
                                        onChangeFunc={filterHandelChange}
                                        options={optionsSearchByCost}
                                        valueField="value"
                                        textField="description"
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id="Cost"
                                        inputName="Cost"
                                        wmsSelector={wmsSelector + "InputCost"}
                                        value={filter.Cost}
                                        onChangeFunc={filterHandelChange}
                                        label=""
                                        parentClassName="input_box"
                                    />
                                </div>
                            </WmsContainer>
                            <WmsContainer title={window.translate("Label_Expiration_Date")}>
                                <WmsDateTimePicker
                                    id={"ExpDateBegin" + props.windowId}
                                    name="ExpDateBegin"
                                    label={window.translate("Label_Begin")}
                                    onChangeFunc={filterHandelChange}
                                    value={filter.ExpDateBegin}
                                    wmsSelector={wmsSelector + "ExpDateBegin"}
                                />
                                <WmsDateTimePicker
                                    id={"ExpDateEnd" + props.windowId}
                                    name="ExpDateEnd"
                                    label={window.translate("Label_End")}
                                    onChangeFunc={filterHandelChange}
                                    value={filter.ExpDateEnd}
                                    wmsSelector={wmsSelector + "ExpDateEnd"}
                                />
                            </WmsContainer>
                            <WmsContainer title={window.translate("Label_Custom_Receipt_Info")}>
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsReceiptRefId"
                                        name="IndexOptionsReceiptRefId"
                                        label={window.translate("Label_Custom_Receipt_Reference_Number")}
                                        wmsSelector={wmsSelector + "IndexOptionsReceiptRefId"}
                                        value={filter.IndexOptionsReceiptRefId}
                                        onChangeFunc={filterHandelChange}
                                        options={optionsSearchContains}
                                        valueField="value"
                                        textField="description"
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id="ReceiptRefId"
                                        inputName="ReceiptRefId"
                                        wmsSelector={wmsSelector + "InputReceiptRefId"}
                                        value={filter.ReceiptRefId}
                                        onChangeFunc={filterHandelChange}
                                        label=""
                                        parentClassName="input_box"
                                    />
                                </div>
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsPoNumber"
                                        name="IndexOptionsPoNumber"
                                        label={window.translate("Label_PO_Number")}
                                        wmsSelector={wmsSelector + "IndexOptionsPoNumber"}
                                        value={filter.IndexOptionsPoNumber}
                                        onChangeFunc={filterHandelChange}
                                        options={optionsSearchContains}
                                        valueField="value"
                                        textField="description"
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id="PoNumber"
                                        inputName="PoNumber"
                                        wmsSelector={wmsSelector + "InputPoNumber"}
                                        value={filter.PoNumber}
                                        onChangeFunc={filterHandelChange}
                                        label=""
                                        parentClassName="input_box"
                                    />
                                </div>
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsLocation"
                                        name="IndexOptionsLocation"
                                        label={window.translate("Label_Location")}
                                        wmsSelector={wmsSelector + "IndexOptionsLocation"}
                                        value={filter.IndexOptionsLocation}
                                        onChangeFunc={filterHandelChange}
                                        options={optionsSearchContains}
                                        valueField="value"
                                        textField="description"
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id="Location"
                                        inputName="Location"
                                        wmsSelector={wmsSelector + "InputLocation"}
                                        value={filter.Location}
                                        onChangeFunc={filterHandelChange}
                                        label=""
                                        parentClassName="input_box"
                                    />
                                </div>
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsPallet"
                                        name="IndexOptionsPallet"
                                        label={window.translate("Label_Pallet")}
                                        wmsSelector={wmsSelector + "IndexOptionsPallet"}
                                        value={filter.IndexOptionsPallet}
                                        onChangeFunc={filterHandelChange}
                                        options={optionsSearchContains}
                                        valueField="value"
                                        textField="description"
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id="Pallet"
                                        inputName="Pallet"
                                        wmsSelector={wmsSelector + "InputPallet"}
                                        value={filter.Pallet}
                                        onChangeFunc={filterHandelChange}
                                        label=""
                                        parentClassName="input_box"
                                    />
                                </div>
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsTrailerContainerNumber"
                                        name="IndexOptionsTrailerContainerNumber"
                                        label={window.translate("Label_Trailer_Container_Number")}
                                        wmsSelector={wmsSelector + "IndexOptionsTrailerContainerNumber"}
                                        value={filter.IndexOptionsTrailerContainerNumber}
                                        onChangeFunc={filterHandelChange}
                                        options={optionsSearchContains}
                                        valueField="value"
                                        textField="description"
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id="TrailerContainerNumber"
                                        inputName="TrailerContainerNumber"
                                        wmsSelector={wmsSelector + "InputTrailerContainerNumber"}
                                        value={filter.TrailerContainerNumber}
                                        onChangeFunc={filterHandelChange}
                                        label=""
                                        parentClassName="input_box"
                                    />
                                </div>
                            </WmsContainer>
                            <WmsContainer title={window.translate("Label_Custom_Receipt_Date_Range")}>
                                <WmsDateTimePicker
                                    id={"ReceiptBeginDate" + props.windowId}
                                    name="ReceiptBeginDate"
                                    label={window.translate("Label_Begin")}
                                    onChangeFunc={filterHandelChange}
                                    value={filter.ReceiptBeginDate}
                                    wmsSelector={wmsSelector + "ReceiptBeginDate"}
                                />
                                <WmsDateTimePicker
                                    id={"ReceiptEndDate" + props.windowId}
                                    name="ReceiptEndDate"
                                    label={window.translate("Label_End")}
                                    onChangeFunc={filterHandelChange}
                                    value={filter.ReceiptEndDate}
                                    wmsSelector={wmsSelector + "ReceiptEndDate"}
                                />
                            </WmsContainer>
                            <WmsContainer title={window.translate("Label_Custom_Receipt_Transaction_Range")}>
                                <WmsInput
                                    id="ReceiptFirstTransaction"
                                    inputName="ReceiptFirstTransaction"
                                    wmsSelector={wmsSelector + "ReceiptFirstTransaction"}
                                    value={filter.ReceiptFirstTransaction}
                                    onChangeFunc={filterHandelChange}
                                    label={window.translate("First_Orders_Lbl_Transaction_Id")}
                                />
                                <WmsInput
                                    id="ReceiptLastTransaction"
                                    inputName="ReceiptLastTransaction"
                                    wmsSelector={wmsSelector + "ReceiptLastTransaction"}
                                    value={filter.ReceiptLastTransaction}
                                    onChangeFunc={filterHandelChange}
                                    label={window.translate("Label_Last_Transaction")}
                                />
                            </WmsContainer>
                        </div>
                    </div>
                    <div className="grid-section">
                        <div className="wms-warning-wrapper">
                            <div className="wms-sprite fa fa-exclamation-triangle"></div>
                            <span>{window.translate("Label_warning_for_total_weight_and_volume_add_edit")}</span>
                        </div>
                        {(totalQuantity > props.AllocationObj.totalOrdered || valueGreater) && isGridEditAllocations ? (
                            <div className="alert-not">
                                {window.translate(
                                    "Label_You_cannot_allocate_more_units_than_specified_in_the_line_item"
                                )}
                            </div>
                        ) : null}
                        {isGridEditAllocations && totalQuantity < props.AllocationObj.totalOrdered ? (
                            <div className="alert-not">{window.translate("Label_You_Cannot_Remove_Quantity")}</div>
                        ) : null}
                        {props.AllocationObj.modalType === "GridMassAdd" && valueGreater && (
                            <span className="mass-add alert-not">{window.translate("Label_You_May_Not_Add")}</span>
                        )}
                        <WmsGrid
                            wmsSelector={wmsSelector + "Grid"}
                            gridKeySelection={"Key"}
                            id={props.AllocationObj.modalType + props.windowId}
                            gridName={props.AllocationObj.modalType}
                            virtual={true}
                            onGridBinding={onEditAllocationGridBinding}
                            shawCheckbox={false}
                            showGridSummary={props.AllocationObj.modalType === "GridEditAllocations" ? true : false}
                            columns={
                                props.AllocationObj.modalType === "GridEditAllocations"
                                    ? editAllocationColumns
                                    : massAddcolumns
                            }
                            schema={
                                props.AllocationObj.modalType === "GridEditAllocations"
                                    ? editAllocationSchema
                                    : massAddSchema
                            }
                            transport={
                                props.AllocationObj.modalType === "GridEditAllocations"
                                    ? editAllocationTransport
                                    : massAddTransport
                            }
                            virtualGridpageSize={props.AllocationObj.modalType === "GridEditAllocations" ? 50 : 80}
                            showGridResult={props.AllocationObj.modalType === "GridEditAllocations" ? true : false}
                            showSortColumns={props.AllocationObj.modalType === "GridEditAllocations" ? true : false}
                            showColumnFilter={false}
                            onSelection={() => {}}
                            getGridSelectionUrl={""}
                            filterObject={filterObject}
                            searchUrl={"/WebUI/Shipping/UpdateSession/FilterSearchInfoSend"}
                            gridEditable={true}
                            onGridChange={onGridChange}
                            onGridSave={onGridSave}
                            onLoadGrid={onLoadGrid}
                            resetButtonLabel={window.translate("Label_Options")}
                            menu={[]}
                        />
                    </div>
                </div>
            </div>
            <div className="footer-btn-holder">
                <WmsButton
                    label={window.translate("Label_Cancel")}
                    wmsSelector={wmsSelector + "DiscardExit"}
                    onClickFunc={eventClickDiscardButton}
                    buttonType={"orange"}
                    icon={props.AllocationObj.modalType === "GridEditAllocations" ? "fa-ban" : "fa-times-circle"}
                />
                {props.AllocationObj.modalType === "GridEditAllocations" && (
                    <WmsButton
                        label={window.translate("Label_Save")}
                        wmsSelector={wmsSelector + "Save"}
                        onClickFunc={eventClickSaveAllocation}
                        disabled={
                            valueGreater ||
                            totalQuantity !== props.AllocationObj.totalOrdered ||
                            gifEditAllocation ||
                            disableSave
                        }
                        icon="fa-floppy-o"
                        showToolTip={valueGreater || totalQuantity !== props.AllocationObj.totalOrdered}
                        tooltipText={
                            valueGreater || totalQuantity !== props.AllocationObj.totalOrdered
                                ? window.translate("Label_Enable_Edit_Allocations_Save")
                                : ""
                        }
                        loading={gifEditAllocation}
                    />
                )}
                {props.AllocationObj.modalType === "GridMassAdd" && (
                    <div>
                        <WmsButton
                            label={window.translate("Label_Add_and_New")}
                            wmsSelector={wmsSelector + "AddAndNewButton"}
                            onClickFunc={() => addLineItem("AddNew")}
                            disabled={valueGreater || savingGif.savingGifAddNew}
                            icon="fa-plus"
                            loading={savingGif.savingGifAddNew}
                        />

                        <WmsButton
                            label={window.translate("Label_Add_and_Exit")}
                            wmsSelector={wmsSelector + "AddExitButton"}
                            onClickFunc={() => addLineItem("AddExit")}
                            disabled={valueGreater}
                            icon="fa-plus"
                            loading={savingGif.savingGifAddExit}
                        />
                    </div>
                )}
            </div>
        </WmsModel>
    );
};
EditAllocationModal.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    windowId: PropTypes.string.isRequired,
    isOpenEditAllocationModal: PropTypes.bool.isRequired,
    onCloseEditAllocationModal: PropTypes.any.isRequired,
    isEditAllocationLoading: PropTypes.bool.isRequired,
    AllocationObj: PropTypes.any.isRequired,
    onResetAllocationGridAndSelection: PropTypes.any.isRequired,
    onGridItemChange: PropTypes.any.isRequired,
};

EditAllocationModal.defaultProps = {
    wmsSelector: "",
    windowId: "",
    isOpenEditAllocationModal: false,
    onCloseEditAllocationModal: (): void => {},
    isEditAllocationLoading: false,
    AllocationObj: {},
    onResetAllocationGridAndSelection: (): void => {},
    onGridItemChange: (): void => {},
};

export default React.memo(EditAllocationModal);
