import React from 'react';

import WmsButton from '../../../../components/WmsButton/WmsButton.jsx';
import WmsModel from '../../../../components/WmsModel/WmsModel.jsx';
import { ProtoMassAddHolder } from './proto-mass-add-holder.component.jsx';
import './proto-mass-add.component.scss';
var axios = require('axios');
export class ProtoMassAddModel extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;

        this.state = {
            isOpenLadingModel: props.isOpenLadingModel,
            Selector:"protoMassAdd"
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isOpenLadingModel && !this.state.isOpenLadingModel) {
            this.setState({
                isOpenLadingModel: true
            });
        }
    }
    componentDidMount() {

    }
    render() {
        return (
            <WmsModel
                id={"proto-mass-add"}
                class="proto-mass-add"
                title={this.translate('Label_Prototype_Mass_Add')}
                width={1300}
                onCloseFunc={this.props.onCloseFunc}
                height={780}
                isOpen={this.state.isOpenLadingModel}
                customClass='wms-model-new-ui-wrapper'
            >
                <div className="model-content-wrapper">
                    <ProtoMassAddHolder
                    isProtoMass={true}
                    sharedEditAllocationData={this.props.sharedEditAllocationData}
                    onResetGridAndSelection={this.props.onResetGridAndSelection}
                    onGridItemChange={this.props.onGridItemChange}/>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate("Label_Close")}
                        wmsSelector={this.state.Selector + "ButtonCancel"}
                        onClickFunc={this.props.onCloseFunc}
                        icon="fa-times-circle-o"
                        buttonType="yellow"
                        loading={false}
                    />
                </div>
            </WmsModel>
        )
    }
}