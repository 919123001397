import React, { Component } from 'react';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import WmsCheckBox from '../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDateTimePicker from '../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import * as GlobalService from './../../global_services';

export class UpsEditComponent extends Component {
    constructor(props) {
        super(props)
        
        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            pickupTypeOptions: []
        }
    }

    componentDidMount() {

        let optionsForSettings = GlobalService.getDropdownOptionsForSettings('UPS');

        this.setState({
            pickupTypeOptions: optionsForSettings.pickupType,
            IsDisabledUseNegotiatedRates: this.props.connectionObj.UseNegotiatedRates,
            IsDisabledUseNegotiatInputs: true
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.connectionObj.UseNegotiatedRates !== prevProps.connectionObj.UseNegotiatedRates) {
            this.setState({
                IsDisabledUseNegotiatInputs: this.props.connectionObj.UseNegotiatedRates == true ? false : true
            });
        }
    }

    render() {
        return (
            <div>

                <WmsCollapse
                    id={"UPSEditComponent"}
                    headerLabel={this.translate("Label_Company_Information")}
                    showCollapseText={false}
                    showCollapseIcon={false}>
                    <div className="account-info-section">

                        <WmsInput
                            id='nickname'
                            inputName='Nickname'
                            wmsSelector={this.props.selector + 'nickname'}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate('Label_Account_Nick_Name')}
                            extraWrapperClass="UPS-edit-nickname"
                            value={this.props.connectionObj.Nickname} />
                        <div>
                            <WmsInput
                                id='accountNumber'
                                inputName='AccountNumber'
                                wmsSelector={this.props.selector + 'accountNumber'}
                                onChangeFunc={this.props.handleConnectionObjChange}
                                label={this.translate('Label_Account_Number')}
                                extraWrapperClass="UPS-edit-accoutnumber"
                                value={this.props.connectionObj.AccountNumber}
                                disabled={true} />

                            <WmsInput
                                id='accountPostalCode'
                                inputName='AccountPostalCode'
                                wmsSelector={this.props.selector + 'accountzip'}
                                onChangeFunc={this.props.handleConnectionObjChange}
                                label={this.translate('Label_Account_Number_And_Postal_Code')}
                                value={this.props.connectionObj.AccountPostalCode}
                                extraWrapperClass="UPS-edit-zip"
                            />  

                        <WmsDropdown
                                id="pickupType"
                                name="PickupType"
                                label={this.translate('Label_Pickup_Type')}
                                wmsSelector={this.props.selector + "PickupType"}
                                onChangeFunc={this.props.handleConnectionObjChange}
                                value={this.props.connectionObj.PickupType}
                                options={this.state.pickupTypeOptions}
                                valueField='value'
                                textField='name'
                                extraWrapperClass="UPS-edit-pickup-type"
                                blankFirstOption={true}
                            />
                        </div>
                        <div className="chk-inline">
                                <WmsCheckBox
                                    id={'useCarbonNeutralShippingProgram'}
                                    name={"UseCarbonNeutralShippingProgram"}
                                    wmsSelector={this.props.selector + 'UseCarbonNeutralShippingProgram'}
                                    onChangeFunc={this.props.handleConnectionObjChange}
                                    label={this.translate('Label_Use_Carbon_Neutral_Shipping_Program')}
                                    checked={this.props.connectionObj.UseCarbonNeutralShippingProgram == true}
                                    isRounded={true}
                                />

                                <WmsCheckBox
                                    id={'useGroundFreightPricing'}
                                    name={"UseGroundFreightPricing"}
                                    wmsSelector={this.props.selector + 'UseGroundFreightPricing'}
                                    onChangeFunc={this.props.handleConnectionObjChange}
                                    label={this.translate('Label_Use_Ground_Freight_Pricing')}
                                    checked={this.props.connectionObj.UseGroundFreightPricing == true}
                                    isRounded={true}
                                />

                                <WmsCheckBox
                                    id={'isPrimaryAccount'}
                                    name={"IsPrimaryAccount"}
                                    wmsSelector={this.props.selector + 'PrimaryAccount'}
                                    onChangeFunc={this.props.handleConnectionObjChange}
                                    label={this.translate('Label_Primary_UPS_Account')}
                                    checked={this.props.connectionObj.IsPrimaryAccount == true}
                                    isRounded={true}
                                />
                        </div>
                    </div>
                </WmsCollapse>

                <WmsCollapse
                    id={'carrierEditInvoiceInformation'}
                    headerLabel={this.translate("Label_Invoice_Information")}
                    showCollapseText={false}
                    showCollapseIcon={false} >
                        <div className="invoice-info-section">
                            <WmsCheckBox
                                id={'useNegotiatedRates'}
                                name={"UseNegotiatedRates"}
                                wmsSelector={this.props.selector + 'UseNegotiatedRates'}
                                onChangeFunc={this.props.handleConnectionObjChange}
                                label={this.translate('Label_Use_Negotiated_Rates')}
                                checked={this.props.connectionObj.UseNegotiatedRates == true}
                                isRounded={true}
                                disabled={this.state.IsDisabledUseNegotiatedRates}
                                parentClassName={this.state.IsDisabledUseNegotiatedRates ? "disabled-checkbox" : "" }
                            />
                        
                            <div className="wms-invoice-elements">

                                <WmsDateTimePicker
                                    id='invoiceDate'
                                    name='InvoiceDate'
                                    label={this.translate('Label_Invoice_Date')}
                                    onChangeFunc={this.props.handleConnectionObjChange}
                                    required={this.state.IsDisabledUseNegotiatInputs == false && !this.props.connectionObj.InvoiceDate ? true : false}
                                    disabled={this.state.IsDisabledUseNegotiatInputs}
                                    parentClassName={this.state.IsDisabledUseNegotiatInputs ? "disabled-checkbox" : "" }
                                    value={this.props.connectionObj.InvoiceDate} />

                                <WmsInput
                                    id='invoiceNumber'
                                    inputName='InvoiceNumber'
                                    wmsSelector={this.props.selector + 'invoiceNumber'}
                                    onChangeFunc={this.props.handleConnectionObjChange}
                                    label={this.translate('Label_Invoice_Number')}
                                    value={this.props.connectionObj.InvoiceNumber}
                                    required={this.state.IsDisabledUseNegotiatInputs == false && !this.props.connectionObj.InvoiceNumber ? true : false}
                                    disabled={this.state.IsDisabledUseNegotiatInputs}
                                    // extraWrapperClass={this.state.IsDisabledUseNegotiatInputs ? "disabled-checkbox" : "" }
                                    valueType="alphanumeric" />

                                <WmsInput
                                    id='controlId'
                                    inputName='ControlId'
                                    wmsSelector={this.props.selector + 'controlId'}
                                    onChangeFunc={this.props.handleConnectionObjChange}
                                    label={this.translate('Label_Control_Id')}
                                    required={this.state.IsDisabledUseNegotiatInputs == false && !this.props.connectionObj.ControlId ? true : false}
                                    disabled={this.state.IsDisabledUseNegotiatInputs}
                                    // extraWrapperClass={this.state.IsDisabledUseNegotiatInputs ? "disabled-checkbox" : "" }
                                    value={this.props.connectionObj.ControlId} />

                                <WmsInput
                                    id='invoiceAmount'
                                    inputName='InvoiceAmount'
                                    wmsSelector={this.props.selector + 'invoiceAmount'}
                                    onChangeFunc={this.props.handleConnectionObjChange}
                                    label={this.translate('Label_Invoice_Total')}
                                    value={this.props.connectionObj.InvoiceAmount}
                                    valueType="number"
                                    required={this.state.IsDisabledUseNegotiatInputs == false && !this.props.connectionObj.InvoiceAmount ? true : false}
                                    disabled={this.state.IsDisabledUseNegotiatInputs}
                                    // extraWrapperClass={this.state.IsDisabledUseNegotiatInputs ? "disabled-checkbox" : "" }
                                    regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/}/>
                            </div>
                        
                        </div>
            </WmsCollapse>
        </div>
        )
    }
}
