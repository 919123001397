import React from 'react';
import { ObjectWms } from '../../../../../assets/ts/ObjectWms.ts';

//component
import WmsCheckBox from './../../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsGrid from './../../../components/WmsGrid/WmsGrid.jsx';
import WmsButton from './../../../components/WmsButton/WmsButton.jsx';
import WmsDropdown from './../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsContainer from './../../../components/WmsContainer/WmsContainer.jsx';
import WmsModel from './../../../components/WmsModel/WmsModel.jsx';

import * as findOrdersService from '../findOrders.services.jsx';
import * as GlobalService from './../../../global_services';
import './send-asn-model.scss';
import { kendo } from "../../../global_variable_service.jsx";
var _ = window._;

export class SendAsnModel extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            selector: "order",
            chkConsolidate: false,
            gifDownload: false,
            gifSend: false,
            dataLoaded: false,
            transformId: '',
            ftpId: '',
            transformsOptions: [],
            modalTitle:this.translate('Label_send_ASN_Title'),
            channelsOptions: [],
            tplSettings: GlobalService.getTplSettings(),
            modelId: 'sendAsnModel' + this.props.windowId,
            isShowAsnErrorDetailGrid:false,  // flag for show violates of settings message in grid
            errorStaticGridData:[],
            isShowStandardMessage:false, // flag for show standard message
            isShowSendAsnSections:false, // show send asn buttons 
            sendAsnOrderIds:props.sharedSelectedOrderIds
        };
    }
    componentDidMount () {
        this.setState({ dataLoaded: false });        
        kendo.ui.progress($('#' + this.state.modelId), true);
        this.intialSendAsn(this.props.sharedSelectedOrderData.CustomerUri);
    }

    intialSendAsn = (param_uri) => {

        findOrdersService.intialSendAsn(param_uri).then((response) => {

            if (response) {
                console.log("intialSendAsn ", response);

                let channels = response.Channels;
                let transforms = response.Transforms;
                var modalTitle = this.translate('Label_send_ASN_Title');

                if(transforms.length == 0 && channels.length == 0) {
                    modalTitle = this.translate('Label_cannot_send_ASN_Title');
                    this.setState({
                        isShowStandardMessage:true,
                        isShowAsnErrorDetailGrid:false,
                        isShowSendAsnSections:false,
                        dataLoaded: true,
                    });
                    // Set dynamic title from here
                    if($('#' + this.state.modelId).data("kendoWindow")){
                        $('#' + this.state.modelId).data("kendoWindow").title(modalTitle);
                    }
                    kendo.ui.progress($('#' + this.state.modelId), false);
                } else {
                    this.callAsnPreChecker(channels,transforms);
                }
                
            }

        }).finally(() => {

        });
    }

    callAsnPreChecker = (channels,transforms) => {
        findOrdersService.checkAsnPreChecker(this.props.sharedSelectedOrderIds).then((response) => {
            var responsData = response.data;
            this.checkForNextProcess(response.data);
            // let tmpAsnPreCheckOptions = GlobalService.setAsnPrechekOptionsData(param_val);
        }).finally(() => {
            this.setState({
                dataLoaded: true,
                transformsOptions: transforms,
                channelsOptions: channels,
                transformId: transforms.length == 1 ? transforms[0].TransformId : '',
                ftpId: channels.length == 1 ? channels[0].Id : '', 
                chkConsolidate: false,
            });
        });
    }
    checkForNextProcess = (data) => {
        let asnErrorDetailGridData = [];
        for (var i = 0; i < data.length; i++) {
            if(data[i].HasMissingPackageContent){
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_missing_package_content')
                });
            }
            if(data[i].AsnPreCheck.RequireScacForAsn){
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_SCAC')
                });
            }
            if(data[i].AsnPreCheck.RequireAttachedDocuments){
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_Attach_File')
                });
            }
            if(data[i].AsnPreCheck.OrderLevelFlexFields !== null){
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_order_level_custom_fields') + ' ( ' + data[i].AsnPreCheck.OrderLevelFlexFields + ' )'
                });
            }
            if(data[i].AsnPreCheck.OrderItemLevelFlexFields !== null){
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_line_item_level_custom_fields')  + ' ( ' + data[i].AsnPreCheck.OrderItemLevelFlexFields + ' )'
                });
            }
            let tmpAsnPreCheckOptions = GlobalService.setAsnPrechekOptionsData(data[i].AsnPreCheck.AsnPreCheckOptions);
            if(tmpAsnPreCheckOptions.HasWeight) {
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_HasWeight')
                });
            } if(tmpAsnPreCheckOptions.HasVolume) {
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_HasVolume')
                });
            } if(tmpAsnPreCheckOptions.HasPackages) {
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_HasPackages')
                });
            } if(tmpAsnPreCheckOptions.HasCarrier) {
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_HasCarrier')
                });
            } if(tmpAsnPreCheckOptions.HasBillOfLading) {
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_HasBillOfLading')
                });
            } if(tmpAsnPreCheckOptions.HasLoadNumber) {
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_HasLoadNumber')
                });
            } if(tmpAsnPreCheckOptions.HasPoNumber) {
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_HasPoNumber')
                });
            } if(tmpAsnPreCheckOptions.IsClosed) {
                asnErrorDetailGridData.push({
                    "OrderId": data[i].OrderIdentifier.Id,
                    "Error":this.translate('Label_Asn_Details_Require_IsClosed')
                });
            }
        }
        if(asnErrorDetailGridData.length > 0){
            this.setState({
                isShowStandardMessage:false,
                isShowAsnErrorDetailGrid:true,
                isShowSendAsnSections:false,
                errorStaticGridData : asnErrorDetailGridData
            });
            var orderIdsWithoutErrors = _.xor($.map(this.props.sharedSelectedOrderIds,Number),_.uniq(_.map(asnErrorDetailGridData,"OrderId")));
            this.setState({
                sendAsnOrderIds : orderIdsWithoutErrors
            });
            // Set dynamic title from here
            if($('#' + this.state.modelId).data("kendoWindow")){
                $('#' + this.state.modelId).data("kendoWindow").title(this.translate('Label_send_ASN_Error_Title'));
            }
            
        } else {
            this.setState({
                isShowStandardMessage:false,
                isShowAsnErrorDetailGrid:false,
                isShowSendAsnSections:true
            });
            // Set dynamic title from here
            if($('#' + this.state.modelId).data("kendoWindow")){
                $('#' + this.state.modelId).data("kendoWindow").title(this.translate('Label_send_ASN_Title'));
            }
            kendo.ui.progress($('#' + this.state.modelId), false);
        }        
    }
    onDetailsGridDataBound = () => {
        // Intialize tooltip for error details grid error message
        $("#asnErrorDetailGrid").kendoTooltip({
              filter: "td:nth-child(2)", //this filter selects the second column's cells
              content: function(e){
                var dataItem = $("#asnErrorDetailGrid").data("kendoGrid").dataItem(e.target.closest("tr"));
                var content = dataItem.Error;
                return content;
              }
        }).data("kendoTooltip");
        kendo.ui.progress($('#' + this.state.modelId), false);
    }
    handleChangeEvent = (e) => {
        if (e.target.type == 'checkbox') {
            this.setState({ 'chkConsolidate': !this.state.chkConsolidate });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    eventClickDownload = () => {
        // check for Packages
        var commaSeparatedOrderIds = (this.state.sendAsnOrderIds.length > 1) ? this.state.sendAsnOrderIds.join(',') : this.state.sendAsnOrderIds[0];

        this.setState({ gifDownload: true });
        let downloadPayload = {
            "transformId": this.state.transformId,
            "orderIdsCommaSeparated": commaSeparatedOrderIds,
            "consolidate": this.state.chkConsolidate
        };

        findOrdersService.ordersWithoutPackages(commaSeparatedOrderIds).then((response1) => {
            // if no Packages : call method Generate (if we don't need to stop, this is fine.
            if (response1.length > 0) {
                findOrdersService.recalcPackages(response1).then((response2) => {

                    findOrdersService.downloadSendAsn(downloadPayload).then((response) => {

                        this.setState({ gifDownload: false });
                        if (typeof response.data != 'undefined' && response.status == 200) {

                            console.log("response.headers", response.headers);
                            GlobalService.downloadFileBaseOnContentType(response.data, response.headers['content-type'], 'sendAsn');

                            if (this.state.channelsOptions.length == 0) {
                                this.props.onResetGridSelection();
                                this.props.onClose();
                            }
                        }

                    }).finally(() => {
                        this.setState({ gifDownload: false });
                    });
                }).finally(() => {
                    this.setState({ gifDownload: false });
                });
            }
            else {
                // Then do the rest of this on the whole list.
                findOrdersService.downloadSendAsn(downloadPayload).then((response) => {

                    this.setState({ gifDownload: false });
                    if (typeof response.data != 'undefined' && response.status == 200) {

                        console.log("response.headers", response.headers);
                        GlobalService.downloadFileBaseOnContentType(response.data, response.headers['content-type'], 'sendAsn');

                        if (this.state.channelsOptions.length == 0) {
                            this.props.onResetGridSelection();
                            this.props.onClose();
                        }
                    }

                }).finally(() => {
                    this.setState({ gifDownload: false });
                });
            }
        }).finally(() => {
            this.setState({ gifDownload: false });
        });
    }

    eventClickSend = () => {
        this.setState({ gifSend: true });
        let ftpObj = this.state.channelsOptions[ObjectWms.getIndexBasedUponKeyValuePair(this.state.channelsOptions, "Id", this.state.ftpId)];
        var commaSeparatedOrderIds = (this.state.sendAsnOrderIds.length > 1) ? this.state.sendAsnOrderIds.join(',') : this.state.sendAsnOrderIds[0];

        var sendObj = {
            "transformId": this.state.transformId,
            "channelIdentifier": ftpObj,
            "orderIds": this.state.sendAsnOrderIds,
            "consolidate": this.state.chkConsolidate
        };

        findOrdersService.ordersWithoutPackages(commaSeparatedOrderIds).then((response1) => {
            // if no Packages on any order, call method Generate on the Orders without Packages
            if (response1.length > 0) {
                findOrdersService.recalcPackages(response1).then((response2) => {
                    findOrdersService.sendAsn(sendObj).then((response) => {
                        this.setState({ gifSend: false });
                        if (response) {
                            if (typeof response.data != 'undefined' && response.status == 200 || response.status == 204) {
                                GlobalService.notification('success', this.translate('Label_SendASN_Success'));
                                this.props.onResetGridSelection();
                                this.props.onClose();
                            }
                        }
                    }).finally(() => {
                        this.setState({ gifSend: false });
                    });
                }).finally(() => {
                    this.setState({ gifSend: false });
                });
            }
            else {
                // If we don't generate packages, still send ASN.
                findOrdersService.sendAsn(sendObj).then((response) => {
                    this.setState({ gifSend: false });
                    if (response) {
                        if (typeof response.data != 'undefined' && response.status == 200 || response.status == 204) {
                            GlobalService.notification('success', this.translate('Label_SendASN_Success'));
                            this.props.onResetGridSelection();
                            this.props.onClose();
                        }
                    }
                }).finally(() => {
                    this.setState({ gifSend: false });
                });
            }
        }).finally(() => {
            this.setState({ gifSend: false });
        });

    }
    sendAsnForOrderWithoutErrors = () => {
        this.setState({
            isShowStandardMessage:false,
            isShowAsnErrorDetailGrid:false,
            isShowSendAsnSections:true
        });
        // Set dynamic title from here
        if($('#' + this.state.modelId).data("kendoWindow")){
            $('#' + this.state.modelId).data("kendoWindow").title(this.translate('Label_send_ASN_Title'));
        }
    }
    render() {
        return (
            <WmsModel
                id={this.state.modelId}
                title={this.state.modalTitle}
                class="send-asn-model"
                width={800}
                height={270}
                onCloseFunc={this.props.onClose}
                isOpen={this.props.sharedIsOpenSendAsnModel}
                customClass='wms-model-new-ui-wrapper'>

                {this.state.dataLoaded == true &&
                    <div className="model-content-wrapper">
                        { this.state.isShowSendAsnSections &&
                            <WmsContainer >
                                <p className="wms-selected-order-count">{this.state.sendAsnOrderIds.length} {this.translate('Label_Selected_Order')}</p>
                                <div className="wms-format-wrapper">
                                    {this.state.transformsOptions.length > 1 &&
                                        <WmsDropdown
                                            id="transformId"
                                            name="transformId"
                                            label={this.translate('Label_Formats')}
                                            wmsSelector={this.state.Selector + "formats"}
                                            value={this.state.transformId}
                                            onChangeFunc={this.handleChangeEvent}
                                            options={this.state.transformsOptions}
                                            textField='Description'
                                            valueField='TransformId'
                                            blankFirstOption={true}
                                            blankFirstOptionText={''}
                                        />
                                    }

                                    {this.state.transformsOptions.length == 1 &&
                                        <div className="title_label" >
                                            <p className="title">Formats <span>{this.state.transformsOptions[0].Description}</span>
                                            </p>
                                        </div>
                                    }

                                    {(this.state.transformsOptions.length != 0) &&
                                        <WmsButton
                                            label={this.translate('Label_Download')}
                                            wmsSelector={this.state.selector + "DownloadAsn"}
                                            id="downloadAsn"
                                            disabled={this.state.transformId == ''}
                                            loading={this.state.gifDownload}
                                            onClickFunc={this.eventClickDownload}
                                            icon={'fa-download'}
                                        />
                                    }
                                </div>
                                <div className="wms-format-wrapper">
                                    {this.state.channelsOptions.length > 1 &&
                                        <WmsDropdown
                                            id="ftpId"
                                            name="ftpId"
                                            label={this.translate('Label_Send_To_Ftp')}
                                            wmsSelector={this.state.Selector + "sendTOFtp"}
                                            value={this.state.ftpId}
                                            onChangeFunc={this.handleChangeEvent}
                                            options={this.state.channelsOptions}
                                            textField='Name'
                                            valueField='Id'
                                            blankFirstOption={true}
                                            blankFirstOptionText={''}
                                        />

                                    }

                                    {this.state.channelsOptions.length == 1 &&
                                        <div className="title_label">
                                            <p className="title">
                                                {this.translate('Label_Send_To_Ftp')}
                                                <span>{this.state.channelsOptions[0].Name}</span>
                                            </p>
                                        </div>
                                    }

                                    {(this.state.channelsOptions.length != 0) &&
                                        <WmsButton
                                            label={this.translate('Label_Send')}
                                            wmsSelector={this.state.selector + "Send"}
                                            id="ftpSend"
                                            onClickFunc={this.eventClickSend}
                                            disabled={this.state.gifSend || this.state.transformId == '' || this.state.ftpId == ''}
                                            loading={this.state.gifSend}
                                            icon={'fa-paper-plane'}
                                        />
                                    }
                                </div>

                                {(this.state.sendAsnOrderIds.length >= 2 && this.state.tplSettings.Receiving.ConsolidateAsnEnabled) &&
                                    <WmsCheckBox
                                        id="chkConsolidate"
                                        name="chkConsolidate"
                                        wmsSelector={this.state.Selector + "consolidateFtp"}
                                        onChangeFunc={this.handleChangeEvent}
                                        label={this.translate("Label_Send_ASN_consolidate_FTP")}
                                        checked={this.state.chkConsolidate == true}
                                        value={this.state.chkConsolidate}
                                    />
                                }

                            </WmsContainer>
                        }

                        { this.state.isShowStandardMessage &&
                            <div className="title_label a">
                                <p className="title">{this.translate('Label_Send_ASN_Data_Not_Available')}</p>
                            </div>
                        }
                        {
                            this.state.isShowAsnErrorDetailGrid && (
                                <WmsGrid
                                    wmsSelector={this.state.selector + 'asnErrorDetailGrid'}
                                    gridKeySelection={'transactionid'}
                                    staticGridData={this.state.errorStaticGridData }
                                    gridName={'asnErrorDetailGrid'}
                                    virtual={false}
                                    showResetButton={false}
                                    shawCheckbox={false}
                                    onDataBound= {this.onDetailsGridDataBound}
                                    id={'asnErrorDetailGrid'}
                                    onSelection={() => { }}
                                    gridHeight={260}
                                    columns={[{
                                        field: "OrderId",
                                        title: "Order ID",
                                        width: "170px"
                                    }, {
                                        field: "Error",
                                        title: "Error Description",
                                        width: "100%"
                                    }]}
                                    schema={{
                                        model: {
                                            fields: {
                                                OrderId: { type: "number" },
                                                Error: { type: "string" }
                                            }
                                        }
                                    }}
                                    clickEventOfContextMenu={() => { }}
                                    menu={[]} />
                            )
                        }
                    </div>
                }
                {this.state.dataLoaded == true &&
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={this.translate('Label_Cancel')}
                            wmsSelector={"sendAsnCancel"}
                            onClickFunc={this.props.onClose}
                            disabled={false}
                            loading={false}
                            buttonType='yellow'
                            icon={'fa-ban'}
                        />
                        {this.state.isShowAsnErrorDetailGrid && this.state.sendAsnOrderIds.length > 0 && (
                            <WmsButton
                                label={this.translate('Label_Send_ASN_for_Orders_Without_Errors')}
                                wmsSelector={"sendAsnForOrderWithoutErrors"}
                                onClickFunc={this.sendAsnForOrderWithoutErrors}
                                disabled={false}
                                loading={false}
                                buttonType='green'
                                icon={'fa-paper-plane'}
                            />
                        )}  
                    </div>
                }
            </WmsModel>
        );
    }
}