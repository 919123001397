
import React from 'react';
import './themes.scss'

export class ColorPickerComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount = () => {
        $("#picker_" + this.props.id).kendoColorPicker({
            value: this.props.value,
            buttons: false,
            select: (e) => {
                document.documentElement.style.setProperty('--' + this.props.name, e.value);
            },
            change: (e) => {
                this.props.onChange(e, this.props.name);
            }
        });
    }
    resetColorPicker = (colorObj) => {
        console.log("resetColorPicker : ", colorObj);
        for (var key in colorObj) {
            if (colorObj.hasOwnProperty(key)) {
                var val = colorObj[key];
                var colorpicker = $("#picker_" + key).data("kendoColorPicker");
                if (typeof colorpicker != "undefined") {
                    colorpicker.value(val);
                }

            }
        }

    }
    render() {
        return (
            <div className="column">

            </div>
        )
    }
}