import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './WmsCheckBox.scss';
function WmsCheckBox(props) {
	const [isHovering, setIsHovering] = useState(false);

	const handleMouseHover = () => {
		const chkElement = document.querySelector(`#${props.id}_chk`).parentElement.getBoundingClientRect();
		const tooltipElement = document.querySelector(`#${props.id}_chk .tooltip-wrapper`);
		var bottom = chkElement.bottom;
		var left = chkElement.left;
		if (tooltipElement.closest('.wms-model-new-ui-wrapper')) {
			var modalPosition = tooltipElement.closest('.wms-model-new-ui-wrapper').getBoundingClientRect();
			bottom = bottom - (modalPosition && modalPosition.top ? modalPosition.top : 100);
			left = left - (modalPosition && modalPosition.left ? (modalPosition.left + 50)  : 192.5);
		}
		tooltipElement.style.top = `${bottom}px`;
		tooltipElement.style.left = `${left}px`;

		tooltipElement.style.transitionDelay = `${props.tooltipShowAfter}ms`;
		setIsHovering(true);
	}

	const handleMouseLeave = () => {
		setIsHovering(false);
	}
	return (
		<div className={props.parentClassName + " wms-checkbox-component " + (props.isRounded ? 'wms-rounded-checkbox' : 'wms-rounded-checkbox')
			+ (props.isSwitchCheckbox ? ' wms-toggle-checkbox' : '') +  (props.disabled ? ' disabled-chk' : '')} >
			<label id={props.id + '_chk'} htmlFor={props.id}>
				<input
					type="checkbox"
					id={props.id}
					name={props.name}
					data-wms-selector={props.wmsSelector}
					onChange={props.onChangeFunc}
					checked={props.checked}
					className={(props.isSwitchCheckbox ? "switch" : "option-input")}
					disabled={props.disabled}
				/> <span
					onMouseEnter={props.showToolTip == true ? handleMouseHover : undefined}
					onMouseLeave={props.showToolTip == true ? handleMouseLeave : undefined}>{props.label}</span>
				<div className="tooltip-wrapper">
					{props.showToolTip == true && isHovering == true && (
						<div dangerouslySetInnerHTML={{ __html: props.tooltipText }}></div>
					)}
				</div>
			</label>
		</div>
	);
}

WmsCheckBox.propTypes = {
	id: PropTypes.string, // this should write the id to the checkbox
	label: PropTypes.string.isRequired,// this should be the label that shows after checkbox button
	name: PropTypes.string.isRequired,// this should be the name of the checkbox
	wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
	onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
	parentClassName: PropTypes.string, //    this should parent tag class
	checked: PropTypes.bool, // this should disable checkbox
	isRounded: PropTypes.bool, // this is use for rounded and square checkbox shown
	showToolTip: PropTypes.bool, // set tooltip show or not
	isSwitchCheckbox: PropTypes.bool, // set tooltip show or not
	tooltipText: PropTypes.string // show text on hover as tooltip
}

WmsCheckBox.defaultProps = {
	checked: false,
	disabled: false,
	parentClassName: "",
	isRounded: false,
	showToolTip: false,
	isSwitchCheckbox: false,
	tooltipShowAfter: 800,
	tooltipText: 'add tooltip text'
}
const areEqual = (prevProps, nextProps) => {
	let isChangeFuncEqual = _.isEqual(_.omit(nextProps, ['onChangeFunc']), _.omit(prevProps, ['onChangeFunc']));
	return isChangeFuncEqual;
};


export default React.memo(WmsCheckBox, areEqual);
