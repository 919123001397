import React, { useState, useEffect, useCallback } from "react";

import VirtualGridComponent from "../react/components_proto/VirtualGridComponent/VirtualGridComponent";
import { StringWms } from "../../assets/ts/StringWms";
import { ArrayWms } from "../../assets/ts/ArrayWms";
import { AddEditColumnModel } from "../react/components/AddEditColumn/add-edit-columns.component";
import * as GlobalService from "../react/global_services";
import * as GridConfig from "./adjust-customer-charges-grid-configuration";
import CreateNewChargeModal from "./create-new-charge.modal.component";
import CancelAdjustmentModal from "./cancel-adjustment.modal.component";
import * as AdjustCustomerCharges from "./adjust-customer-charges.services";
import "./adjustCustomerCharges.scss";
import { ConcurrencyManager } from "../../assets/vendor/concurrencyManager/concurrencyManager";
import { LoadingModel } from "../react/components/LoadingModel/LoadingModel";
import * as DynamicMenu from "../adjustCustomerCharges/grid_dynamic_menu.virtual.jsx";
import * as findOrdersService from "../../app/react/orders/findOrders/findOrders.services.jsx";

var axios = require("axios");
declare var window: any;
const { $ } = window;
interface gridFilterType {
    CustomerID: number;
    FacilityID: number;
    WarehouseTransactionID: number;
    ReferenceNum: string;
    StartDate: string;
    EndDate: string;
}
interface Props {
    wmsSelector: string;
    windowId: string;
    sharedFilterObject: gridFilterType;
}

const gridId = "AdjustCustomerCharges";

const defaultCustomerAdjustmentChargesData = {
    CustomerIdentifier: { Id: 0 },
    FacilityIdentifier: { Id: 0 },
    ReferenceNum: "",
    PONumber: "",
    Notes: "",
    Uri: "",
    CreateInvoice: false,
    InvoiceCreationInfo: {
        SetInvoiceDate: true,
        UtcOffset: 0,
    },
    Billing: [],
};
// eslint-disable-next-line sonarjs/cognitive-complexity
const AdjustCustomerChargesGrid: React.FC<Props> = (props: Props): any => {
    let localKendoGrid = null;
    let filterCountAxiosSource = null;
    let firstFiftyAxiosSource = null;
    let axiosSource = null;
    let isApiCanceled = false;
    const defaultColumns = GridConfig.getAdjustCustomerChargeColumns().columns;
    const gridDomSelector = props.windowId ? "." + props.windowId + " #" + gridId : "#" + gridId;
    const [filterText, setFilterText] = useState("");
    const [fullFilterText, setFullFilterText] = useState("");
    const [recordsCount, setRecordsCount] = useState(0);
    const [gridData, setGridData] = useState([]);
    const allColumnList = GridConfig.getAdjustCustomerChargeAllColumnList();
    const [gridConfig, setGridConfig] = useState("");
    const [schema] = useState(GridConfig.getAdjustCustomerChargeSchema());
    const [sharedIsOpenNewChargeModal, setStateSharedIsOpenNewChargeModal] = useState(false);
    const [sharedIsOpenCancelAdjustmentModal, setStateSharedIsOpenCancelAdjustmentModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(defaultCustomerAdjustmentChargesData);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [applyCancelAdjustment, setApplyCancelAdjustment] = useState(false);
    const [isCancelAdjustment, setIsCancelAdjustment] = useState(false);
    const [isOpenColumnModal, setIsOpenColumnModal] = useState(false);
    const [isCustomerRequired, setIsCustomerRequired] = useState(false);
    const [isAllDataLoad, setIsAllDataLoad] = useState(false);
    const [isFilterRowCountLoading, setIsFilterRowCountLoading] = useState(false);
    const [showLoadingError, setShowLoadingError] = useState(false);
    const [isNewColumnAdded, setIsNewColumnAdded] = useState(false);
    const [isGridDataLoading, setIsGridDataLoading] = useState(false);
    const [gridPageSize] = useState(GlobalService.constants.virtualGridDataLimit);
    const [overlayScreenShow, setOverlayScreenShow] = useState(false);
    const [totalRequest, setTotalRequest] = useState(0);
    const [menuPrintData, setMenuPrintData] = useState([]);
    const [initialPrintMenuResponse, setInitialPrintMenuResponse] = useState([]);
    const rights = GlobalService.GetRights();
    const tplSettings = GlobalService.getTplSettings();

    //componentDidMount
    useEffect(() => {
        getInitialPrintMenu();
    }, []);

    const onApplyCancelAdjustment = (data) => {
        setApplyCancelAdjustment(true);
        AdjustCustomerCharges.saveCancelAdjustment(selectedRow.Uri, data)
            .then((response) => {
                setSelectedRowCount(0);
                setSelectedRow(defaultCustomerAdjustmentChargesData);
                setStateSharedIsOpenCancelAdjustmentModal(false);
                GlobalService.notification("success", window.translate("Reverse_Adjustment_Completed_Successfully"));
                refreshGrid();
            })
            .finally(() => {
                setApplyCancelAdjustment(false);
                kendo.ui.progress($("#CancelAdjustmentModal"), false);
            });
    };

    const getGridFilter = useCallback(() => {
        let GridFilterData = props.sharedFilterObject;

        if (GridFilterData.CustomerID !== 0) {
            let selectedCustomer = GlobalService.getCustomerById(GridFilterData.CustomerID);
            if (selectedCustomer == null) {
                GridFilterData.CustomerID = 0;
            }
        }
        return GridFilterData;
    }, [props.sharedFilterObject]);

    const onselectGridRow = (KendoGrid: any) => {
        let grid = $(gridDomSelector).data("kendoGrid");
        var data = grid.dataItem(grid.select());
        var isClosed = KendoGrid.valueExists("Status", "2");
        if (data) {
            setIsCancelAdjustment(isClosed);
            setSelectedRow(data);
            setSelectedRowCount(KendoGrid.getNumberOfSelections());
        } else {
            setSelectedRow(defaultCustomerAdjustmentChargesData);
            setSelectedRowCount(0);
        }
    };
    const clickEventOfContextMenu = (obj: any, e: any) => {
        if (obj.wmsid == "ViewTransaction") {
            dynamicServiceForDynamicMenu(e, "RunReportViewTransaction");
        } else {
            onOpenCancelAdjustment();
        }
    };
    // on new filter data clear selection of grid
    const clearSelectionOfGrid = () => {
        if (localKendoGrid != null) {
            localKendoGrid.clearSelections(() => {
                localKendoGrid.repaintSelections();
            });
        }
    };

    const listGrid_dataBind = (allData) => {
        let count = 1;
        for (let i = 0; i < allData.length; i++) {
            allData[i].RowNumber = count++;
            allData[i].BillTotalCharges = 0;
            if (allData[i].Billing) {
                for (let j = 0; j < allData[i].Billing.length; j++) {
                    const ChargeBill = allData[i].Billing[j];
                    allData[i].BillTotalCharges = allData[i].BillTotalCharges + ChargeBill.TotalCharges;
                }
            }
        }
        setGridData(allData);
    };

    useEffect(() => {
        console.log("useEffect called");
        async function getGridMeta() {
            setGridConfig(AdjustCustomerCharges.getAdjustCustomerChargesGridOptionInMetaData());
            if (getGridFilter()) {
                callForGetTotalCount();
            }
        }
        getGridMeta();
    }, [props.sharedFilterObject]);

    const onOpenCreateNewCharge = () => {
        setStateSharedIsOpenNewChargeModal(true);
    };
    const onCloseCreateNewCharge = () => {
        setStateSharedIsOpenNewChargeModal(false);
    };
    const onOpenCancelAdjustment = () => {
        setStateSharedIsOpenCancelAdjustmentModal(true);
    };
    const onCloseCancelAdjustment = () => {
        setStateSharedIsOpenCancelAdjustmentModal(false);
    };

    const refreshGrid = () => {
        clearSelectionOfGrid();
        callForGetTotalCount();
    };
    useEffect(() => {
        if (recordsCount > 0) {
            callForGetNewData();
        }
    }, [recordsCount]);

    // Cancel axios call if new filter applied
    const cancelLoadAllData = () => {
        if (firstFiftyAxiosSource) {
            firstFiftyAxiosSource.cancel();
            firstFiftyAxiosSource = null;
        }
        if (axiosSource) {
            axiosSource.cancel();
            axiosSource = null;
        }
        isApiCanceled = true;
        setIsGridDataLoading(false);
        setOverlayScreenShow(false);
        setGridData([]);
        setIsAllDataLoad(false);
    };
    const callForGetTotalCount = (clearData = true, clearGridSelection = false) => {
        setRecordsCount(0);
        setIsFilterRowCountLoading(true);
        setShowLoadingError(false);

        if (clearGridSelection) {
            setSelectedRowCount(0);
            setIsAllDataLoad(false);
        }
        var param_filterObj = Object.assign({}, getGridFilter());
        if (!param_filterObj.CustomerID) {
            setIsCustomerRequired(true);
            setIsFilterRowCountLoading(false);
            setGridData([]);
            return;
        } else {
            setIsCustomerRequired(false);
        }

        param_filterObj.CustomerID = param_filterObj.CustomerID === 0 ? null : param_filterObj.CustomerID;
        param_filterObj.FacilityID = param_filterObj.FacilityID === 0 ? null : param_filterObj.FacilityID;
        param_filterObj.StartDate = param_filterObj.StartDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.StartDate, "", false, true).isoStringUTC
            : null;
        param_filterObj.EndDate = param_filterObj.EndDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.EndDate, "23:59:59", false, true).isoStringUTC
            : null;
        if (filterCountAxiosSource) {
            filterCountAxiosSource.cancel();
            filterCountAxiosSource = null;
        }
        if (param_filterObj.CustomerID === null) {
            setGridData([]);
            setRecordsCount(0);
            setIsFilterRowCountLoading(false);
            return false;
        }
        // on filter update cancel axiosSource call if any running
        cancelLoadAllData();
        filterCountAxiosSource = axios.CancelToken.source();

        // Call for FilterRowCount.
        AdjustCustomerCharges.setGridFilter(param_filterObj, filterCountAxiosSource.token)
            .then((response: any) => {
                setRecordsCount(response.data);
                if (clearData) {
                    setGridData([]);
                }
                filterCountAxiosSource = null;
            })
            .finally(() => {
                setIsFilterRowCountLoading(false);
            });
    };
    const resetGridAllMessage = () => {
        if (isAllDataLoad) {
            setIsNewColumnAdded(true);
        }
    };

    const callForGetNewData = () => {
        if (recordsCount > 0 && recordsCount <= gridPageSize) {
            loadAllRecords(false);
        } else {
            resetGridAllMessage();
        }
    };
    const sequence = (n) => {
        let seq = [];
        for (let i = 0; i < n; i++) {
            seq.push(i);
        }
        return seq;
    };
    const getAllResponseData = (data) => {
        if (!isApiCanceled) {
            let allData = [];
            data.forEach((obj) => {
                allData = [...obj.Data, ...allData];
            });
            listGrid_dataBind(allData);
            setIsGridDataLoading(false);
            setOverlayScreenShow(false);
            setIsAllDataLoad(true);
            setIsNewColumnAdded(false);
        } else {
            setIsGridDataLoading(false);
        }
    };
    const loadAllRecords = (isLoaderPopupShow) => {
        isApiCanceled = false;
        if (isLoaderPopupShow) {
            setIsGridDataLoading(true);
            setGridData([]);
        }

        var param_filterObj = Object.assign({}, getGridFilter());
        param_filterObj.CustomerID = param_filterObj.CustomerID === 0 ? null : param_filterObj.CustomerID;
        param_filterObj.FacilityID = param_filterObj.FacilityID === 0 ? null : param_filterObj.FacilityID;
        param_filterObj.StartDate = param_filterObj.StartDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.StartDate, "", false, true).isoStringUTC
            : null;
        param_filterObj.EndDate = param_filterObj.EndDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.EndDate, "23:59:59", false, true).isoStringUTC
            : null;

        axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/customers/charges/",
            headers: { "Access-Control-Allow-Origin": "*" },
            cancelToken: axiosSource.token,
        });
        const MAX_CONCURRENT_REQUESTS = 3;
        let totalRequest = Math.ceil(recordsCount / gridPageSize);
        setTotalRequest(totalRequest);

        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS, GlobalService);

        Promise.all(
            sequence(totalRequest).map((i) =>
                api.put("/ChargesData?sorts=[]&filters=[]&pgnum=" + (i + 1) + "&pgsiz=" + gridPageSize, {
                    chargesSearchInfo: param_filterObj,
                })
            )
        )
            .then((responses) => {
                let inventoryData = responses.map((r) => r.data);
                getAllResponseData(inventoryData);
            })
            .catch((error) => {
                console.log("Promise.all catch", error);
                setShowLoadingError(true);
                cancelLoadAllData();
            })
            .then(() => {
                if (manager) {
                    manager.detach();
                }
            });
    };
    const onGridDataBound = (e, kendoGrid) => {
        localKendoGrid = kendoGrid;
    };
    // on grid filter selection
    const onGridFilterApplied = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            let filter = dataSource.filter();
            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, filter);
            AdjustCustomerCharges.setAdjustCustomerChargesGridOptionInMetaData(finalSubmission);
            setFilterAppliedText(filter);
        }, 50);
    };
    const setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            setFilterText("");
            setFullFilterText("");
            return;
        }
        var filterConditionTitle = {
            lte: "Is Less Than Or Equal To",
            eq: "Is Equal To",
            lt: "Is Less Than",
            neq: "Is Not Equal To",
            gte: "Is Greater Than Or Equal To",
            gt: "Is Greater Than",
            startswith: "Starts With",
            endswith: "Ends With",
            contains: "Contains",
            doesnotcontain: "Does Not Contain",
            isnull: "Is Null",
            isnotnull: "Is Not Null",
            isempty: "Is Empty",
            isnotempty: "Is Not Empty",
            isnullorempty: "Has No Value",
            isnotnullorempty: "Has Value",
        };

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;

        filterList.map((item) => {
            var title = allColumnList.find((x) => x.field === item.field).title;
            filterText += title + " : " + item.value + ",";
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            return item;
        });
        filterText = filterText.slice(0, -1);
        setFilterText(filterText);
        setFullFilterText(fullFilterText);
    };
    // on grid column sort save changes in meta data object
    const onSort = (event) => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            gridColumn,
            sortArray,
            grid.dataSource.filter()
        );
        AdjustCustomerCharges.setAdjustCustomerChargesGridOptionInMetaData(finalSubmission);
    };
    // on grid column reorder save changes in meta data object
    const onColumnReorder = (event) => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            switchedArray,
            sortArray,
            grid.dataSource.filter()
        );
        AdjustCustomerCharges.setAdjustCustomerChargesGridOptionInMetaData(finalSubmission);
    };

    // on grid column resize save changes in meta data object
    const onColumnResize = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        AdjustCustomerCharges.setAdjustCustomerChargesGridOptionInMetaData(finalSubmission);
    };

    // on click grid clear sort menu
    const onClickClearSorts = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        AdjustCustomerCharges.setAdjustCustomerChargesGridOptionInMetaData(finalSubmission);
    };

    // on click grid reset default grid layout menu
    const onClickResetGrid = () => {
        var finalSubmission = {
            optionsData: {},
        };
        AdjustCustomerCharges.setAdjustCustomerChargesGridOptionInMetaData(finalSubmission);
    };

    const eventClickExportGrid = () => {
        $(gridDomSelector).getKendoGrid().saveAsExcel();
    };
    const onToggleColumnModal = (isOpen) => () => {
        setIsOpenColumnModal(isOpen);
    };
    const saveMetaData = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, sort, grid.dataSource.filter());
        AdjustCustomerCharges.setAdjustCustomerChargesGridOptionInMetaData(finalSubmission);
    };
    const onClickLoadAllRecords = () => {
        var param_filterObj = Object.assign({}, getGridFilter());
        param_filterObj.CustomerID = param_filterObj.CustomerID === 0 ? null : param_filterObj.CustomerID;
        param_filterObj.FacilityID = param_filterObj.FacilityID === 0 ? null : param_filterObj.FacilityID;
        param_filterObj.StartDate = param_filterObj.StartDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.StartDate, "", false, true).isoStringUTC
            : null;
        param_filterObj.EndDate = param_filterObj.EndDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.EndDate, "23:59:59", false, true).isoStringUTC
            : null;
        setOverlayScreenShow(true);

        if (firstFiftyAxiosSource) {
            firstFiftyAxiosSource.cancel();
            firstFiftyAxiosSource = null;
        }
        firstFiftyAxiosSource = axios.CancelToken.source();
        axios
            .put(
                "/WebUI/customers/charges/ChargesData?sorts=[]&filters=[]&pgnum=1&pgsiz=50",
                {
                    chargesSearchInfo: param_filterObj,
                },
                { cancelToken: firstFiftyAxiosSource.token }
            )
            .then((response) => {
                firstFiftyAxiosSource = "";
                console.log("response", response);
                setGridData(response.data.Data);
                loadAllRecords(false);
            });
    };

    const updateInitialPrintMenu = () => {
        var params = {
            Selector: props.wmsSelector,
            Rights: rights,
            TplSettings: tplSettings,
            FullyAllocated: false,
            EnablePickOption: false,
            SameFacility: true,
            SameCustomer: true,
            SelectedOrders: 1,
            fnNames: ["RunReportViewTransaction"],
        };
        const updatedMenus = DynamicMenu.getInitialPrintMenu(
            initialPrintMenuResponse,
            params,
            dynamicMenuClick,
            "AdjustCharges"
        );

        setMenuPrintData(updatedMenus.PrintData);
    };

    const dynamicMenuClick = (event) => {
        var wmsid = $(event.target).parent().attr("data-wmsid");
        wmsid = "clickOn" + wmsid;
        if (typeof $[wmsid] !== "undefined") {
            $[wmsid](event);
        } else {
            console.error("Uncaught TypeError: " + wmsid + " is not a function");
        }
    };

    const getInitialPrintMenu = () => {
        //setting to the first customer in the list for now until we talk to Jeff and come to a resolution
        var customerId = GlobalService.GetCurrentUser().CustomerIds[0];

        GlobalService.initialPrintMenu(customerId)
            .then((response) => {
                if (response) {
                    setInitialPrintMenuResponse(response);
                }
            })
            .finally(() => {});
    };

    const dynamicServiceForDynamicMenu = (event, fnName) => {
        var runReport = $(event.target).parent().attr("data-run-report");

        findOrdersService
            .dynamicServiceForDynamicMenu(fnName, runReport, [], null, "AdjustCharges")
            .then((response) => {
                handleResponseMenuPrint(response);
            })
            .finally(() => {});
    };

    const handleResponseMenuPrint = (response) => {
        var blob = ArrayWms.convertBase64ToByteArray(response.Data, "application/pdf");
        ArrayWms.displayBlobInWindow(blob, "report.pdf");
    };

    useEffect(() => {
        updateInitialPrintMenu();
    }, [initialPrintMenuResponse]);

    return (
        <div className={`adjust-customer-charges-grid`}>
            {isCustomerRequired && (
                <div className="customer-required">{window.translate("Customer_Require_Filter")}</div>
            )}
            <VirtualGridComponent
                virtualGridDataLimit={gridPageSize}
                isNewColumnAdded={isNewColumnAdded}
                callForGetNewData={callForGetNewData}
                isAllDataLoad={isAllDataLoad}
                loadAllRecords={onClickLoadAllRecords}
                loading={isFilterRowCountLoading || isGridDataLoading}
                loadAllMessageFirst={window.translate("Label_Grid_Filter_Message")}
                loadAllMessageSecond={window.translate("Label_Grid_Filter_Message_2")}
                showLoadingError={showLoadingError}
                id={gridId}
                showSortColumns={true}
                showGridSummary={true}
                gridName={"AttachFileToAssembly"}
                windowId={props.windowId}
                wmsSelector={"AttachFileToAssembly"}
                gridKeySelection={"RowNumber"}
                virtual={true}
                totalResult={recordsCount}
                staticGridData={gridData}
                exportable={true}
                allColumns={allColumnList}
                defaultColumns={defaultColumns}
                schema={schema}
                metaGridConfig={gridConfig}
                onSelection={onselectGridRow}
                clickEventOfContextMenu={clickEventOfContextMenu}
                metaDataFieldsName={"ManageAttachFileAssembly"}
                eventClickColumns={onToggleColumnModal(true)}
                onGridFilterApplied={onGridFilterApplied}
                filterText={filterText}
                fullFilterText={fullFilterText}
                onDataBound={onGridDataBound}
                onSort={onSort}
                onColumnReorder={onColumnReorder}
                onColumnResize={onColumnResize}
                onClickClearSorts={onClickClearSorts}
                onClickResetGrid={onClickResetGrid}
                isExportSelectedData={true}
                exportExcelObj={{
                    fileName:
                        "AdjustCustomerChargesExport-" +
                        kendo.toString(kendo.parseDate(new Date().toString()), "yyyyMMddhhmmss") +
                        ".xlsx",
                    allPages: true,
                    filterable: true,
                }}
                csvExportFileName={"AdjustCustomerChargesExport"}
                eventClickExportGrid={eventClickExportGrid}
                menu={[
                    {
                        name: window.translate("Create_Charge"),
                        wmsSelector: props.wmsSelector + "CreateCharge",
                        value: [],
                        clickFunc: onOpenCreateNewCharge,
                        disabled: false,
                        icon: "fa-plus",
                        color: "green",
                        isContextMenu: false,
                        isButtonMenu: true,
                    },
                    {
                        name: window.translate("Reverse_Charge"),
                        wmsSelector: props.wmsSelector + "ReverseCharge",
                        value: [],
                        clickFunc: onOpenCancelAdjustment,
                        disabled: selectedRowCount !== 1 || isCancelAdjustment,
                        icon: "fa-trash",
                        color: "red",
                        isContextMenu: true,
                        isButtonMenu: true,
                    },
                    {
                        name: window.translate("NavBarV1_Btn_Documents"),
                        wmsSelector: props.wmsSelector + "Documents",
                        value: menuPrintData,
                        clickFunc: (e) => null,
                        disabled: true,
                        icon: "fa-print",
                        color: "green",
                        isContextMenu: false,
                        isButtonMenu: false,
                    },
                    {
                        name: "",
                        wmsSelector: props.wmsSelector + "RefreshGrid",
                        value: [],
                        clickFunc: refreshGrid,
                        disabled: false,
                        icon: "fa-sync-alt",
                        color: "blue",
                        isContextMenu: false,
                        isButtonMenu: true,
                        parentClassName: "customer-charge-refresh-btn",
                    },
                ]}
            />
            {isOpenColumnModal && (
                <AddEditColumnModel
                    id={"addEditColumnAdjustCustomerCharges"}
                    height={700}
                    windowId={props.windowId}
                    onCloseColumnModel={onToggleColumnModal(false)}
                    isOpenColumnModel={isOpenColumnModal}
                    allSortColumnList={allColumnList}
                    gridId={gridId}
                    updateGridData={() => {}}
                    saveChangesInMetaData={saveMetaData}
                />
            )}
            {sharedIsOpenNewChargeModal && (
                <CreateNewChargeModal
                    onCloseCreateNewCharge={onCloseCreateNewCharge}
                    sharedIsOpenNewChargeModal={sharedIsOpenNewChargeModal}
                    wmsSelector={props.wmsSelector}
                    reloadGrid={refreshGrid}
                    windowId={props.windowId}
                />
            )}
            {sharedIsOpenCancelAdjustmentModal && (
                <CancelAdjustmentModal
                    wmsSelector={props.wmsSelector}
                    onCloseCancelAdjustment={onCloseCancelAdjustment}
                    sharedIsOpenCancelAdjustmentModal={true}
                    selectedRow={selectedRow}
                    onApplyReverseAdjustment={onApplyCancelAdjustment}
                    applyReverseAdjustment={applyCancelAdjustment}
                />
            )}
            {overlayScreenShow && (
                <LoadingModel
                    sharedOpenLoadingmodel={overlayScreenShow}
                    onCancelLoadAllData={cancelLoadAllData}
                    sharedTotalRequest={totalRequest}
                />
            )}
        </div>
    );
};
AdjustCustomerChargesGrid.propTypes = {};

AdjustCustomerChargesGrid.defaultProps = {};

export default React.memo(AdjustCustomerChargesGrid);
