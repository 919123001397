
import React from 'react';
import WmsContainer from './../../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from './../../components/WmsDropdown/WmsDropdown.jsx';
import WmsButton from './../../components/WmsButton/WmsButton.jsx';
import WmsDateTimePicker from './../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import { ArrayWms } from '../../../../assets/ts/ArrayWms.ts';

import style from './transfer-activity.scss';

import * as GlobalService from './../../global_services';
import * as TransferActivityService from './transfer-activity.services.jsx';

export class transferActivity extends React.Component {

    constructor(props) {
        super(props);
        
        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            customLabels: GlobalService.getCustomLabels(),
            selector: "transferActivity",
            customerIndex: 0,
            customerOptions: [],
            reportDefinationIndex: '',
            startDate: '',
            endDate: '',
            reportDefinationOptions: [],
            runReportGif:false
        }
    }

    componentDidMount() {
        var currentDate = new Date();
        var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        this.setState({
            startDate: firstDay,
            endDate: currentDate
        });
        kendo.ui.progress($('#' + this.state.windowId), true);
        GlobalService.GetCustomersAndFacility().then((response) => {
            var customerList = GlobalService.getCustomerList(true);
            customerList[0].name = "All";
            this.setState({
                customerOptions: customerList
            });

        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
        this.getTransferInventoryReports(0);
    }

    handleChange = (event) => {
        if (event.target.name == 'customerIndex') {
            this.getTransferInventoryReports(this.state.customerOptions[event.target.value].id);
        }
        this.setState({[event.target.name]: event.target.value});
    }

    ClickRunReport = (event) => {
        this.setState({runReportGif:true});
        let custoemrid =  this.state.customerOptions[this.state.customerIndex].id;
        let startDate = (this.state.startDate == "") ? new Date(new Date().getFullYear(), new Date().getMonth(), 1) : this.state.startDate;
        let endDate = (this.state.endDate == "") ? new Date() : this.state.endDate;

        let param_request = {
            "RunnerUriTemplate": this.state.reportDefinationOptions[this.state.reportDefinationIndex].TemplatedRunner,
            "CustomerId": custoemrid,
            "StartDate": kendo.toString(kendo.parseDate(startDate), 'yyyy/MM/dd'),
            "EndDate": kendo.toString(kendo.parseDate(endDate), 'yyyy/MM/dd'),
            "UtcOffset": new Date().getTimezoneOffset() / 60 * -1,
            "outputType": "application/pdf"
        };

        TransferActivityService.runTransferInventoryReports(param_request).then((response) => {
            var blob = ArrayWms.convertBase64ToByteArray(response.data, 'application/pdf');
            ArrayWms.displayBlobInWindow(blob, 'report.pdf');
        }).finally(() => {
            this.setState({runReportGif:false});
        });
    }

    getTransferInventoryReports = (param_customerId) => {

        param_customerId = param_customerId == 0 ? null : param_customerId;

        TransferActivityService.getTransferInventoryReports(param_customerId).then((response) => {

            // response.data = [{
            //     'Reports': [{
            //         'RunnerUriTemplate': '/test/templateUri',
            //         'name': 'ForTestonly'
            //     }]
            // }];

            if (response.status == 200) {

                // // For Testing
                // response.data[0].Reports.push({
                //     Name: "Default1",
                //     TemplatedRunner: "Default1",
                //     TemplatedRunnerKey: "85F197CD"
                // });

                this.setState({
                    reportDefinationOptions: response.data[0].Reports,
                    reportDefinationIndex: '0'
                });
            }
            console.log("getTransferInventoryReports response", response);
        }).finally(() => {
        });
    }

    render() {
        return (
            <div className="transfer-activity-wrapper">
                <WmsContainer>
                    <WmsDropdown
                        id="customerIndex"
                        name="customerIndex"
                        label={this.state.customLabels.Label_Customer}
                        wmsSelector={this.state.selector + "CustomerDropdown"}
                        value={this.state.customerIndex}
                        onChangeFunc={this.handleChange}
                        options={this.state.customerOptions}
                        applyIndex={true}
                        textField='name' />

                    <WmsDateTimePicker
                        id={'startDate'}
                        name='startDate'
                        label={this.translate('Label_Begin')}
                        onChangeFunc={this.handleChange}
                        value={this.state.startDate}
                        format="yyyy/MM/dd"
                        onlyDate={true} />

                    <WmsDateTimePicker
                        id={'endDate'}
                        name='endDate'
                        label={this.translate('Label_End')}
                        onChangeFunc={this.handleChange}
                        value={this.state.endDate}
                        format="yyyy/MM/dd"
                        onlyDate={true} />

                    <WmsDropdown
                        id="reportDefinationIndex"
                        name="reportDefinationIndex"
                        label={this.translate('Label_Report_Defination')}
                        wmsSelector={this.state.selector + "ReportDefinationDropdown"}
                        value={this.state.reportDefinationIndex}
                        onChangeFunc={this.handleChange}
                        options={this.state.reportDefinationOptions}
                        required={true}
                        applyIndex={true}
                        textField='Name'
                        valueField='TemplatedRunner' />


                    <WmsButton
                        label={this.translate("Label_Run_Report")}
                        wmsSelector={this.state.selector + "ButtonSaveExit"}
                        disabled={this.state.reportDefinationIndex == '' || this.state.runReportGif}
                        loading={this.state.runReportGif}
                        onClickFunc={this.ClickRunReport}
                        icon="fa-solid fa-floppy-disk"
                        buttonType="green"
                    />
                </WmsContainer>
            </div>
        )
    }
}