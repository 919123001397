import React from 'react';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';


import * as GlobalService from './../global_services';

export class WarehouseFormDetails extends React.Component {

    constructor(props) {
        super(props);
        
        this.translate = window.translate;

        this.state = {
            CustomLabels: GlobalService.getCustomLabels(),
            Selector: 'Warehouse',
            measurementStandardOption : [{
                "name": "Imperial",
                "id": 0
            }, {
                "name": "Metric",
                "id": 1
            }],
            locationFieldOption : [{
                "name": "1",
                "value": 1
            }, {
                "name": "2",
                "value": 2
            }, {
                "name": "3",
                "value": 3
            }, {
                "name": "4",
                "value": 4
            }]
        }
    }

    componentWillReceiveProps(nextprops) { }

    handleWDetailFormChanges = (event) => {
        if (typeof event.target == 'undefined') {
            return false;
        }
        const name = event.target.name;
        const value = event.target.value;
        let propFacility = this.props.sharedWarehouse;
        let objFacility = Object.assign(
            {},
            propFacility,
            { [name]: name == 'Active' ? !propFacility.Active : value }
        )
        this.props.onWarehouseDataset(objFacility);
    }

    render() {
        const renderFacility = this.props.sharedWarehouse;
        return (
            <div id={'CollapseWarehouseAccount'} className={"collapseWarehouseTabContent_wrapper"}>
            <WmsCollapse id={'ManageWarehouseFormDetail'} className={"collapseWarehouseTabContent_wrapper"} headerLabel={this.translate('Label_Accounts')} showCollapseText={false} showCollapseIcon={false}  >


                    <WmsInput
                        id='UpsAccount'
                        inputName='UpsAccount'
                        wmsSelector={this.state.Selector + 'UpsAccount'}
                        onChangeFunc={this.handleWDetailFormChanges}
                        label={this.translate('Label_Warehouse_UpsAccount')}
                        value={renderFacility.UpsAccount} />

                    <WmsInput
                        id='FedExAccount'
                        inputName='FedExAccount'
                        wmsSelector={this.state.Selector + 'FedExAccount'}
                        onChangeFunc={this.handleWDetailFormChanges}
                        label={this.state.CustomLabels.Label_Warehouse + ' FedExAccount'}
                        value={renderFacility.FedExAccount} />




            </WmsCollapse>
            </div>
        )
    }
}