import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsContextMenuItem.scss';

class WmsContextMenuItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.translate = window.translate;
    }
    onClickContextMenu = (item,e) => {
        this.props.onClickContextMenu(item,e)
    }

    render() {
        return (
            <ul className="wms_context_sub_menu_item_wrapper">
                {this.props.items.map((item, index) =>
                    (typeof item.isContextMenu == "undefined" || item.isContextMenu == true) ?
                        <li
                            key={index}
                            data-wms-selector={item.wmsSelector + '_C'}
                            has-child-menu={item.value.length != 0 ? 'true' : 'false'}
                            className={item.disabled == true ? "mydisabled k-item k-state-default" : 'k-item k-state-default'}
                            onMouseLeave={
                                (item.disabled == true) ?
                                    (() => this.props.eventMouseLeave()) :
                                    () => function () { }
                            }
                            onMouseEnter={
                                (item.disabled == true) ?
                                    (() => this.props.eventMouseEnter(item)) :
                                    (() => function () { })
                            }
                            data-wmsid={(item.wmsid) ? item.wmsid : ""}
                            onClick={item.disabled ? ((e) => function () { }) : ((e) => this.onClickContextMenu(item,e))}
                            data-run-report={item.runReport}
                            data-rdl-name={item.rdlName}
                        >
                            {item.name}
                            {item.isNewMenu == true && (
                                <span className="wms-badge">{this.translate('Label_Beta')}</span>
                            )}
                            {item.value.length != 0 && (
                                // <ul>
                                <WmsContextMenuItem items={item.value} count={index + 1} onClickContextMenu={this.onClickContextMenu} eventMouseEnter={this.props.eventMouseEnter}  eventMouseLeave={this.props.eventMouseLeave}/>
                                // </ul>
                            )}
                        </li> : ""
                )}
            </ul>
        )
    }
}

WmsContextMenuItem.propTypes = {
    // disabled: PropTypes.bool, // This should be used to define class
    // label: PropTypes.string.isRequired, // this should write the label of the menu item
    // onClickFunc: PropTypes.func, // this should control the click event and anything we need to determine before we send it up
    items: PropTypes.array,
    count: PropTypes.number,
    eventMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func
}

WmsContextMenuItem.defaultProps = {
    disabled: false,
    count: 0,
    eventMouseLeave: (e) => function () { },
    eventMouseEnter: (e) => function () { }
}

export default WmsContextMenuItem;