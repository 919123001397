var axios = require('axios');
import * as GlobalService from './../global_services';


export const getCustomerData = function (uri) {
    return axios.post("/WebUI/ImportOrders/GetCustomerData", { customerUri: uri }).then(function (response) {
        return response;
    });
}

export const parseOrderImportFile = function (custId, facilId, transId, file, fileName) {

    transId = transId || transId === 0 ? '&transformId=' + transId : '&transformId=';
    let myUrl = '/WebUI/Shipping/UpdateSession/ParseOrderImportFile?customerId=' + custId + '&facilityId=' + facilId + transId;
    return axios.post(myUrl, { content: file, fileName: fileName }).then(function success(response) {
        return response.data;
    });
}

export const removeParsedOrdersFromSession = function () {
    axios.post('/WebUI/Shipping/UpdateSession/RemoveParsedOrdersFromSession').then(function success(response) {
    });
}

