import * as GlobalService from './../../global_services';

export const ReceiptLineItemsGridColumns = (selectedCustomer, selectedFacility) => {

    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    var volumeColumn = {
        field: "Volume",
        title: translate('Label_Volume'),
        width: 160,
        template: "#= Volume != 0 ? Volume + ' cm cu ' : '' #",
        filterable: false,
        sortable: false
    };

    var muColumns = [
        {
            field: "MovableUnitLabel",
            title: customLabels.Label_Movable_Unit + "Label",
            width: 120,
            wmsid: 14
        },
        {
            field: "MovableUnitType",
            title: customLabels.Label_Movable_Unit + "Type",
            width: 120,
            wmsid: 15,
            filterable: false
        },
        {
            field: "MovableUnitLength",
            title: customLabels.Label_Movable_Unit + "Length",
            width: 120,
            wmsid: 16,
            template: "#=  MovableUnitLength != null ? MovableUnitLength + ' cm ' : '' #"
        },
        {
            field: "MovableUnitWidth",
            title: customLabels.Label_Movable_Unit + "Width",
            width: 120,
            wmsid: 17,
            template: "#= MovableUnitWidth != null ? MovableUnitWidth + ' cm ' : '' #"
        },
        {
            field: "MovableUnitHeight",
            title: customLabels.Label_Movable_Unit + "Height",
            width: 120,
            wmsid: 18,
            template: "#= MovableUnitHeight != null ? MovableUnitHeight + ' cm ' : '' #"
        },
        {
            field: "MovableUnitWeight",
            title: customLabels.Label_Movable_Unit + "Weight",
            width: 120,
            wmsid: 19,
            template: "#= MovableUnitWeight != null ? MovableUnitWeight + ' kg ' : '' #"
        }
    ]

    if (selectedFacility.measurementStandard === 0) {
        volumeColumn = {
            field: "Volume",
            title: translate('Label_Volume'),
            width: 160,
            wmsid: 20,
            template: "#= Volume != 0 ? Volume + ' cu ft ' : '' #",
            filterable: false,
            sortable: false
        }

        muColumns = [
            {
                field: "MovableUnitLabel",
                title: customLabels.Label_Movable_Unit + "Label",
                width: 120,
                wmsid: 14
            },
            {
                field: "MovableUnitType",
                title: customLabels.Label_Movable_Unit + "Type",
                width: 120,
                wmsid: 15,
                filterable: false
            },
            {
                field: "MovableUnitLength",
                title: customLabels.Label_Movable_Unit + "Length",
                width: 120,
                wmsid: 16,
                template: "#= MovableUnitLength != null ? MovableUnitLength + ' in ' : '' #"
            },
            {
                field: "MovableUnitWidth",
                title: customLabels.Label_Movable_Unit + "Width",
                width: 120,
                wmsid: 17,
                template: "#= MovableUnitWidth != null ? MovableUnitWidth + ' in ' : '' #"
            },
            {
                field: "MovableUnitHeight",
                title: customLabels.Label_Movable_Unit + "Height",
                width: 120,
                wmsid: 18,
                template: "#= MovableUnitHeight ? MovableUnitHeight + ' in ' : '' #"
            },
            {
                field: "MovableUnitWeight",
                title: customLabels.Label_Movable_Unit + "Weight",
                width: 120,
                wmsid: 19,
                template: "#= MovableUnitWeight ? MovableUnitWeight + ' lbs ' : '' #"
            }
        ]
    }

    var tempColumns = {
        columns: [
            {
                field: "HoldStatus",
                title: translate('Label_Hold_Status'),
                width: 160,
                wmsid: 1,
                hidden: true,
                filterable: false,
                sortable: false,
                template: "# if(HoldStatus == false){# " + translate('Label_Available') + " #}else{# " + translate('Label_On_Hold') + " #} # "
            },
            {
                field: "HoldReason",
                title: "Hold Reason",
                width: 160,
                wmsid: 13,
                hidden: true
            },
            {
                field: "SKU",
                title: customLabels.Label_Sku,
                width: 320,
                wmsid: 2,
                format: ("{0} ")
            },
            {
                field: "Qualifier",
                title: translate('Label_Qualifier'),
                width: 160,
                wmsid: 3,
            },
            {
                field: "Qty",
                title: translate('Label_Quantity'),
                width: 160,
                wmsid: 4
            },
            {
                field: "PrimaryUnits",
                title: translate('Label_Primary_Units'),
                width: 160,
                wmsid: 5
            },
            {
                field: "SecondaryQty",
                title: translate('Label_Secondary_Quantity'),
                width: 160,
                wmsid: 6,
                template: function (dataItem) {
                    var secondaryQty = dataItem.SecondaryQty;
                    if (GlobalService.getIsIntegeralFlagByType(dataItem.SecondaryUnits)) {
                        secondaryQty = Math.ceil(dataItem.SecondaryQty);
                    }
                    return secondaryQty ? secondaryQty : '';
                }
            },
            {
                field: "SecondaryUnits",
                title: translate('Label_Secondary_Units'),
                width: 160,
                wmsid: 7,
                template: "#= SecondaryUnits ? SecondaryUnits : '' #"
            },
            {
                field: "Weight",
                title: translate('Label_Weight'),
                width: 160,
                wmsid: 8,
                hidden: true,
                filterable: false,
                sortable: false
            },
            volumeColumn,
            {
                field: "LotNumber",
                title: customLabels.Label_Lot_Number,
                width: 160,
                wmsid: 9,
                template: "#= LotNumber ? LotNumber : '' #"
            },
            {
                field: "SerialNumber",
                title: customLabels.Label_Serial_Number,
                width: 160,
                wmsid: 10,
                template: "#= SerialNumber ? SerialNumber : '' #"
            },
            {
                field: "ExpirationDate",
                title: translate('Label_Expiration_Date'),
                width: 160,
                wmsid: 11,
                template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy'): '' #"
            },
            {
                field: "LandedCost",
                title: translate('Label_Landed_Cost'),
                width: 160,
                wmsid: 12,
                template: "#= LandedCost ? LandedCost : '' #"
            }
        ]
    };

    if (selectedCustomer.options.trackLocation) {
        tempColumns.columns = tempColumns.columns.concat({
            field: "Location",
            title: translate('Label_Location'),
            width: 160
        });
    }
    if (selectedCustomer.options.trackSupplier) {
        tempColumns.columns = tempColumns.columns.concat({
            field: "Supplier",
            title: translate('Label_Supplier'),
            width: 160
        });
    }
    if (selectedCustomer.options.trackPallets) {
        tempColumns.columns = tempColumns.columns.concat(muColumns);
    }

    return tempColumns;
}


export const ReceiptLineItemsGridSchema = () => {
    return ({
        schema: {
            model: {
                fields: {
                    HoldStatus: {
                        type: "boolean"
                    },
                    HoldReason: {
                        type: "string"
                    },
                    SKU: {
                        type: "string"
                    },
                    Qualifier: {
                        type: "string",
                        editable: false
                    },
                    Qty: {
                        type: "number",
                        editable: false
                    },
                    PrimaryUnits: {
                        type: "string",
                        editable: false
                    },
                    SecondaryQty: {
                        type: "number",
                        editable: false
                    },
                    SecondaryUnits: {
                        type: "string",
                        editable: false
                    },
                    Weight: {
                        type: "number",
                        editable: false
                    },
                    Volume: {
                        type: "number",
                        editable: false
                    },
                    LotNumber: {
                        type: "string",
                        editable: false
                    },
                    SerialNumber: {
                        type: "string",
                        editable: false
                    },
                    ExpirationDate: {
                        type: "date",
                        editable: false
                    },
                    LandedCost: {
                        type: "number",
                        editable: false
                    },
                    Location: {
                        type: "string",
                        editable: false
                    },
                    Supplier: {
                        type: "string",
                        editable: false
                    },
                    MovableUnitLabel: {
                        type: "string",
                        editable: false
                    },
                    MovableUnitType: {
                        type: "string",
                        editable: false
                    },
                    MovableUnitLength: {
                        type: "number",
                        editable: false
                    },
                    MovableUnitWidth: {
                        type: "number",
                        editable: false
                    },
                    MovableUnitHeight: {
                        type: "number",
                        editable: false
                    },
                    MovableUnitWeight: {
                        type: "number",
                        editable: false
                    },
                    SplitLineByMovableUnit: {
                        type: "boolean",
                        editable: false
                    }
                }
            }
        }
    })
}

export const ReceiptChargesGridColumns = (rights) => {

    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    var text = {
        predefined: "Predefined",
        predefinedRequired: "Predefined (Required)",
        manual: "Manual Charge",
        billing: "Billing Charge",
    };

    var columns = [
            {
                field: "ChargeOrigin",
                title: translate('Label_Charge_Classification'),
                lockable: false,
                filterable: false,
                width: 160,
                wmsid: 1,
                template: "# if( ChargeOrigin== 0) {# " + text.predefinedRequired + " #} else if( ChargeOrigin== 1) {# " + text.billing + "  #} else if( ChargeOrigin== 2) {# " + text.manual + " #} else if( ChargeOrigin== 3) {# " + text.predefined + " #} #"
            },
            {
                field: "ChargeTypeDescription",
                title: translate('Label_Charge_Category'),
                lockable: false,
                width: 160,
                wmsid: 2,
                template: "#= ChargeTypeDescription != null ? ChargeTypeDescription : '' #"
            },
            {
                field: "ChargeLabel",
                title: translate('Label_Charge_Label'),
                lockable: false,
                width: 160,
                wmsid: 3,
                template: "#= ChargeLabel != null ? ChargeLabel : '' #"
            },
            {
                field: "UnitDescription",
                title: translate('Label_Type_Of_Charge_Unit'),
                hidden: true,
                lockable: false,
                width: 160,
                wmsid: 4,
                template: "#= UnitDescription != null ? UnitDescription : '' #"
            },
            {
                field: "ChargePerUnit",
                title: translate('Label_Charge_Per_Unit'),
                lockable: false,
                width: 160,
                wmsid: 5,
                template: "#= ChargePerUnit != null ? ChargePerUnit : '' #"
            },
            {
                field: "NumberOfUnits",
                title: translate('Label_Number_Of_Units'),
                lockable: false,
                width: 160,
                wmsid: 6,
                template: "#= NumberOfUnits != null ? NumberOfUnits : '' #"
            },
            {
                field: "TotalCharges",
                title: translate('Label_Total_Charges'),
                lockable: false,
                width: 160,
                wmsid: 7,
                template: "#= TotalCharges != null ? TotalCharges : '' #"
            },
            {
                field: "GeneralLedgerAccount",
                title: translate('Label_General_Ledger_Account'),
                hidden: true,
                lockable: false,
                width: 160,
                wmsid: 8,
                template: "#= GeneralLedgerAccount != null ? GeneralLedgerAccount : '' #"
            },
            {
                field: "SKU",
                title: translate('Label_Sku_UPC'),
                hidden: true,
                lockable: false,
                width: 160,
                wmsid: 12,
                template: "#= SKU != null ? SKU : '' #"
            }
        ];
        if(rights.hasOwnProperty("Integrations.isNotActiveBillingManagerApp")){
            columns.push({
                field: "ExternalAccountsReceivable",
                title: translate('Label_Accounts_Receive'),
                hidden: true,
                lockable: false,
                width: 160,
                wmsid: 9,
                template: "#= ExternalAccountsReceivable != null ? ExternalAccountsReceivable : '' #"
            });
            columns.push({
                field: "ExternalItemIdentifier",
                title: translate('Label_External_Item_Identifier'),
                hidden: true,
                lockable: false,
                width: 160,
                wmsid: 10,
                template: "#= ExternalItemIdentifier != null ? ExternalItemIdentifier : '' #"
            });
            columns.push({
                field: "ExternalItemDescription",
                title: translate('Label_External_Item_Description'),
                hidden: true,
                lockable: false,
                width: 160,
                wmsid: 11,
                template: "#= ExternalItemDescription != null ? ExternalItemDescription : '' #"
            });
        }
    return ({
        columns: columns
    });
}

export const ReceiptChargesGridSchema = () => {
    return ({
        schema: {
            model: {
                fields: {
                    ChargeTypeId: {
                        type: "number",
                        editable: false
                    },
                    ChargeOrigin: {
                        type: "number",
                        editable: false
                    },
                    ChargeTypeDescription: {
                        type: "string",
                        editable: false
                    },
                    ChargeLabel: {
                        type: "string",
                        editable: false
                    },
                    UnitDescription: {
                        type: "string",
                        editable: false
                    },
                    ChargePerUnit: {
                        type: "number",
                        editable: true
                    },
                    NumberOfUnits: {
                        type: "number",
                        editable: true
                    },
                    TotalCharges: {
                        type: "number",
                        editable: false
                    },
                    GeneralLedgerAccount: {
                        type: "string",
                        editable: false
                    },
                    ExternalAccountsReceivable: {
                        type: "string",
                        editable: false
                    },
                    ExternalItemIdentifier: {
                        type: "string",
                        editable: false
                    },
                    ExternalItemDescription: {
                        type: "string",
                        editable: false
                    },
                    SKU: {
                        type: "string",
                        editable: false
                    },
                    UnsavedManualCharge: {
                        type: "boolean",
                        editable: false
                    },
                    SystemGenerated: {
                        type: "boolean",
                        editable: false
                    },
                    BSId: {
                        type: "string",
                        editable: false,
                    },
                    BSChargeType: {
                        type: "string",
                        editable: false,
                    },
                    BSPredefinedRateId: {
                        type: "string",
                        editable: false,
                    }
                }
            }
        }
    })
}