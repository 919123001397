
import axios from 'axios';
import React from 'react';
//component
import { ArrayWms } from '../../../assets/ts/ArrayWms.ts';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { ConcurrencyManager } from '../../../assets/vendor/concurrencyManager/concurrencyManager.js';
import { AddEditColumnModel } from '../components/AddEditColumn/add-edit-columns.component.jsx';
import { LoadingModel } from '../components/LoadingModel/LoadingModel.jsx';
import VirtualGridComponent from '../components_proto/VirtualGridComponent/VirtualGridComponent.jsx';
import { AttachDocuments } from './../components/AttachDocuments/AttachDocuments.jsx';
//services
import * as GlobalService from '../global_services';
import * as GridConfig from './attach-file-to-assembly-config.jsx';
import * as metaDataService from './attach-file-to-assembly-meta-data.service';

export class AttachFileToAssemblyGrid extends React.Component {

    constructor(props) {

        super(props);
        this.manageAttachFileAssemblyGridId = "GridAttachFileToAssembly";
        this.$rootScope = window;
        this.translate = window.translate;
        this.attachFileAssembly_GridHeaders = StringWms.convertSringToOption(this.translate('AttachFileAssembly_GrideHeaders'));
        this.gridDomSelector = this.props.windowId ? '.' + this.props.windowId + ' #GridAttachFileToAssembly' : '#GridAttachFileToAssembly';
        this.defaultColumns = GridConfig.getGridDemoColumns(this.attachFileAssembly_GridHeaders, 'AttachFileToAssembly').columns;
        this.schema = GridConfig.getGridDemoSchema();
        this.allColumnList = GridConfig.getGridAllColumnList(this.attachFileAssembly_GridHeaders).columns;
        this.firstFiftyAxiosSource = null;
        this.filterCountAxiosSource = null;
        this.axiosSource = null;
        this.isApiCanceled = false;
        this.gridExportLimit= GlobalService.constants.gridExportLimit,
        this.assemblyGridData = null;

        this.state = {
            staticGridData: [],
            selector: 'AttachFileToAssembly',
            localKendoGrid: null,
            allSortColumnList: [],
            manageAttachFilesAssemblyGridConfig: metaDataService.getManageAttachFileAssemblyGridOptionInMetaData(),
            isFilterRowCountLoading: false,
            isAttachFileAssemblyGridDataLoading: false,
            isOpenColumnModel: false,
            filterText: "",
            fullFilterText: "",
            totalRequest: 0,
            overlayScreenShow: false,
            gridPageSize: 1000,
            isNewColumnAdded: false,
            showLoadingError: false,
            totalAttachFilesToAssembly: 0,
            gridData: [],
            isAllDataLoad: false,
            isOpenAttachDoc: false,
            sharedAttachObj: null,
            sharedSelectedAssemblyData: {},
            selectedAssemblyCount: 0
        }
    }

    componentDidMount() {
        let allSortColumnList = this.allColumnList.sort(function (first, second) {

            var columnTitle1 = first.title.trim().toUpperCase(); // ignore upper and lowercase
            var columnTitle2 = second.title.trim().toUpperCase(); // ignore upper and lowercase

            if (columnTitle1 < columnTitle2) { return -1; }

            if (columnTitle1 > columnTitle2) { return 1; }
            // names must be equal
            return 0;
        });
        this.setState({ allSortColumnList: allSortColumnList });
        this.loadGridData();
    }
    refreshGrid = () => {
        this.loadGridData();
    }
    attachFiles = (e) => {
        var sharedAttachObj = {
            'isAssembly': true,
            'uri': this.state.sharedSelectedAssemblyData.EditUri,
            'fileSummariesUri': this.state.sharedSelectedAssemblyData.FileSummariesUri,
            'getAttachedDocsInfoUrl': '/WebUI/assemblies/FindAssemblies/GetAttachedDocsInfo?fileSummariesUri=' + this.state.sharedSelectedAssemblyData.FileSummariesUri,
            'getAttachedDocsInfoMethod': 'GET',
            'getAttachedDocsInfoPayload': {},
            'attachdocumentUrl': '/WebUI/assemblies/FindAssemblies/AttachDocsToAssembly?fileSummariesUri=' + this.state.sharedSelectedAssemblyData.FileSummariesUri,
            'removeAttachDocUrl': '/WebUI/assemblies/FindAssemblies/DeleteAttachedDoc?fileSummariesUri=' + this.state.sharedSelectedAssemblyData.FileSummariesUri,
            'selectedOrderData': this.state.sharedSelectedAssemblyData,
            'customer': this.state.sharedSelectedAssemblyData.Customer,
            'transactionId': this.state.sharedSelectedAssemblyData.AssemblyId,
        };
        this.setState({
            sharedAttachObj: sharedAttachObj,
            isOpenAttachDoc: true
        });
    }


    loadGridData = (clearData = true) => {
        this.setState({
            isFilterRowCountLoading: true,
            totalAttachFilesToAssembly: 0,
            showLoadingError: false
        });
        let tmpGridData = this.state.gridData;
        if (clearData == true) {
            tmpGridData = []
        }

        let assemblySearchInfo = Object.assign({}, this.props.sharedAttachFileAssemblyFilterObj);
        // Clear selection of grid before get new count
        if (this.state.localKendoGrid) {
            this.state.localKendoGrid.clearSelections(() => {
                this.state.localKendoGrid.repaintSelections();
            });
        }

        this.cancelLoadAllData();
        if (this.filterCountAxiosSource) {
            this.filterCountAxiosSource.cancel();
            this.filterCountAxiosSource = null;
        }
        // on filter update cancel orderDataAxios call if any running
        this.filterCountAxiosSource = axios.CancelToken.source();

        axios.put('/WebUI/assemblies/FindAssemblies/FilteredRowCount', {
            assemblySearchInfo: assemblySearchInfo
        }, { cancelToken: this.filterCountAxiosSource.token }).then((response) => {
            this.filterCountAxiosSource = null;
            this.setState({
                totalAttachFilesToAssembly: response.data,
                isAllDataLoad: false,
                gridData: tmpGridData
            }, () => {
                if (response.data > 0) {
                    this.callForGetNewData();
                }
            });
        }).finally(() => {
            this.setState({ isFilterRowCountLoading: false });
        });;
    }

    callForGetNewData = () => {
        if (this.state.totalAttachFilesToAssembly > 0  && this.state.totalAttachFilesToAssembly <= GlobalService.constants.virtualGridDataLimit) {
            this.loadAllRecords(false);
        } else {
            // reset selection of grid on filter data change
            // this.setState({ staticGridData: [] });
            // console.log("resetGridAllMessage : call");
            this.resetGridAllMessage();
        }

    }
    resetGridAllMessage = () => {
        if (this.state.isAllDataLoad) {
            this.setState({
                isNewColumnAdded: true
            });
        }
    }

    onClickLoadAllRecords = () => {
        this.setState({
            gridData: [],
            overlayScreenShow: true,
        });
        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
        }
        this.firstFiftyAxiosSource = axios.CancelToken.source();

        let enableColumns = this.getEnableColumns();
        let assemblySearchInfo = Object.assign({}, this.props.sharedAttachFileAssemblyFilterObj);

        axios.post('/WebUI/assemblies/FindAssemblies/AssembliesData?sorts=[]&filters=[]&pgnum=1&pgsiz=50', {
            columns: enableColumns,
            assemblySearchInfo: assemblySearchInfo
        }, { cancelToken: this.firstFiftyAxiosSource.token }).then((response) => {
            this.firstFiftyAxiosSource = "";
            this.setState({
                gridData: response.data.Data
            }, this.loadAllRecords);
        });
    }

    getEnableColumns = () => {
        var defaultParam = ['FileSummariesUri', 'AssemblyId', 'ReferenceNum', 'CreationDate', 'Customer', 'CustomerId', 'Warehouse', 'FacilityId', 'StatusEnum', 'Status', 'ProcessDate', 'Notes'];
        let columnList = [];

        let grid = $(this.gridDomSelector).data('kendoGrid');
        // if grid already defined than we get columns from grid
        if (typeof grid != "undefined") {
            columnList = grid.columns;
        } else { // else get column list from metadataObject or default columns
            columnList = GridConfig.getGridDemoColumns(this.attachFileAssembly_GridHeaders, 'AttachFileToAssembly').columns;
        }

        var enableColumns = columnList.map(el => el.field);
        // merge defaultParam and enableColumns without dublicate value
        return [...new Set([...defaultParam, ...enableColumns])];
    }

    setFilterAppliedText = (filterObj) => {

        if (!filterObj) {
            this.setState({
                filterText: "",
                fullFilterText: ""
            })
            return;
        }
        var filterConditionTitle = {
            "lte": "Is Less Than Or Equal To",
            "eq": "Is Equal To",
            "lt": "Is Less Than",
            "neq": "Is Not Equal To",
            "gte": "Is Greater Than Or Equal To",
            "gt": "Is Greater Than",
            "startswith": "Starts With",
            "endswith": "Ends With",
            "contains": "Contains",
            "doesnotcontain": "Does Not Contain",
            "isnull": "Is Null",
            "isnotnull": "Is Not Null",
            "isempty": "Is Empty",
            "isnotempty": "Is Not Empty",
            "isnullorempty": "Has No Value",
            "isnotnullorempty": "Has Value",
        }

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;
        var self = this;
        filterList.map((item) => {
            var title = self.allColumnList.find(x => x.field === item.field).title;
            filterText += title + " : " + item.value + ',';
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
        });
        filterText = filterText.slice(0, -1);

        this.setState({
            filterText: filterText,
            fullFilterText: fullFilterText,
        })
    }

    loadAllRecords = (isLoaderPopupShow) => {
        this.isApiCanceled = false;
        const sequence = n => {
            let seq = [];
            for (let i = 0; i < n; i++) {
                seq.push(i);
            }
            return seq;
        };
        if (isLoaderPopupShow == false) {
            this.setState({
                isAttachFileAssemblyGridDataLoading: true,
                gridData: []
            });
        }
        this.axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/assemblies",
            headers: { 'Access-Control-Allow-Origin': '*' },
            cancelToken: this.axiosSource.token
        });
        const MAX_CONCURRENT_REQUESTS = 3;

        let totalRequest = Math.ceil(this.state.totalAttachFilesToAssembly / this.state.gridPageSize);

        this.setState({
            totalRequest: totalRequest,
        });
        // init your manager.
        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS, GlobalService);
        let enableColumns = this.getEnableColumns();
        let assemblySearchInfo = Object.assign({}, this.props.sharedAttachFileAssemblyFilterObj);

        Promise.all(sequence(totalRequest).map(i => api.post("/FindAssemblies/AssembliesData?sorts=[]&filters=[]&pgnum=" + (i + 1) + '&pgsiz=' + this.state.gridPageSize, {
            columns: enableColumns,
            assemblySearchInfo: assemblySearchInfo
        })))
        .then(responses => {
            let AssembliesData = responses.map(r => r.data);
            this.getAllResponseData(AssembliesData);
        }).catch((error) => {
            console.log("Promise.all catch", error);
            this.setState({
                showLoadingError: true
            });
            this.cancelLoadAllData();
        }).then(() => {
            if (manager) {
                manager.detach();
            }
        });
    }

    getAllResponseData = (data) => {
        if (!this.isApiCanceled) {
            let allData = [];
            data.forEach(obj => {
                allData = [...obj.Data, ...allData];
            });
            this.assemblyGridData = allData;
            this.setState({
                gridData: allData,
                overlayScreenShow: false,
                isAllDataLoad: true,
                isNewColumnAdded: false,
                isAttachFileAssemblyGridDataLoading: false
            });
        } else {
            this.setState({
                isAttachFileAssemblyGridDataLoading: false
            });
        }
    }

    cancelLoadAllData = () => {
        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
        }
        if (this.axiosSource) {
            this.axiosSource.cancel();
            this.axiosSource = null;
        }
        this.isApiCanceled = true;
        this.setState({
            isAttachFileAssemblyGridDataLoading: false,
            overlayScreenShow: false,
            gridData: [],
            isAllDataLoad: false
        });
    }

    //On grid row selection
    selectRow = (KendoGrid) => {
        let tempkey = KendoGrid.getKeys();
        var data = {};
        if (tempkey.length == 1) {
            data = KendoGrid.getItem(tempkey);
        }
        console.log("sharedSelectedAssemblyData", data);
        this.setState({
            localKendoGrid: KendoGrid,
            selectedAssemblyCount: KendoGrid.getNumberOfSelections(),
            sharedSelectedAssemblyData: data,
        });
    }
    onGridDataBound = (e, kendoGrid) => {
        this.setState({
            localKendoGrid: kendoGrid
        })
    }
    // on grid column sort save changes in meta data object
    onSort = (event) => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
        metaDataService.setManageAttachFileAssemblyGridOptionInMetaData(finalSubmission);
    }

    // on grid column reorder save changes in meta data object
    onColumnReorder = (event) => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(switchedArray, sortArray, grid.dataSource.filter());
        metaDataService.setManageAttachFileAssemblyGridOptionInMetaData(finalSubmission);

    }

    // on grid column resize save changes in meta data object
    onColumnResize = (event) => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        metaDataService.setManageAttachFileAssemblyGridOptionInMetaData(finalSubmission);
    }

    // on click grid clear sort menu
    onClickClearSorts = () => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        metaDataService.setManageAttachFileAssemblyGridOptionInMetaData(finalSubmission);
    }

    // on click grid reset default grid layout menu
    onClickResetGrid = () => {
        var finalSubmission = {
            "optionsData": {}
        }
        metaDataService.setManageAttachFileAssemblyGridOptionInMetaData(finalSubmission);
    }

    // on grid filter selection
    onGridFilterApplied = () => {

        var grid = $(this.gridDomSelector).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            this.setFilterAppliedText(dataSource.filter());

            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, dataSource.filter());

            metaDataService.setManageAttachFileAssemblyGridOptionInMetaData(finalSubmission);

        }, 100);

    }

    // save column hide/show data in meta data
    saveChangesInMetaData = () => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sort,
            grid.dataSource.filter()
        );
        metaDataService.setManageAttachFileAssemblyGridOptionInMetaData(finalSubmission);
    }

    clickEventOfContextMenu = (obj) => {

    }

    resetGridSelectionAndButton = () => {
        this.loadGridData(false);
    }
    eventClickExportGrid = () => {
        $(this.gridDomSelector).getKendoGrid().saveAsExcel();
    }

    eventClickColumns = () => {
        this.setState({
            isOpenColumnModel: true
        })
    }

    onCloseColumnModel = (e) => {
        this.setState({
            isOpenColumnModel: false
        })
    }
    setModelState = (param_model_state, param_bool) => {
        this.setState({ [param_model_state]: param_bool });
    }

    render() {
        return (
            <div className="attach-file-assembly-grid-holder">
                <div className="wms-attach-file-assembly-grid-holder">
                    <VirtualGridComponent
                        loading={this.state.isFilterRowCountLoading || this.state.isAttachFileAssemblyGridDataLoading}
                        virtualGridDataLimit={GlobalService.constants.virtualGridDataLimit}
                        id={this.manageAttachFileAssemblyGridId}
                        isNewColumnAdded={this.state.isNewColumnAdded}
                        showSortColumns={true}
                        showGridSummary={true}
                        gridName={'AttachFileToAssembly'}
                        windowId={this.props.windowId}
                        wmsSelector={'AttachFileToAssembly'}
                        gridKeySelection={'AssemblyId'}
                        virtual={true}
                        totalResult={this.state.totalAttachFilesToAssembly}
                        staticGridData={this.state.gridData}
                        exportable={true}
                        shawCheckbox={false}
                        isExportSelectedData={true}
                        exportExcelObj={{
                            fileName: "AssemblyGridExport-" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + ".xlsx",
                            allPages: true,
                            filterable: true
                        }}
                        csvExportFileName={'AssemblyGridExport'}
                        eventClickExportGrid={this.eventClickExportGrid}
                        allColumns={this.allColumnList}
                        defaultColumns={this.defaultColumns}
                        schema={this.schema}
                        onSelection={this.selectRow}
                        callForGetNewData={this.callForGetNewData}
                        clickEventOfContextMenu={this.clickEventOfContextMenu}
                        metaDataFieldsName={'ManageAttachFileAssembly'}
                        metaGridConfig={this.state.manageAttachFilesAssemblyGridConfig}
                        eventClickColumns={this.eventClickColumns}
                        onGridFilterApplied={this.onGridFilterApplied}
                        filterText={this.state.filterText}
                        fullFilterText={this.state.fullFilterText}
                        loadAllRecords={this.onClickLoadAllRecords}
                        isAllDataLoad={this.state.isAllDataLoad}
                        onDataBound={this.onGridDataBound}
                        onSort={this.onSort}
                        onColumnReorder={this.onColumnReorder}
                        onColumnResize={this.onColumnResize}
                        onClickClearSorts={this.onClickClearSorts}
                        onClickResetGrid={this.onClickResetGrid}
                        showLoadingError={this.state.showLoadingError}
                        menu={[
                            {
                                "name": this.translate('Label_Attach_Files'),
                                "wmsSelector": this.state.selector + "AttachFiles",
                                "value": [],
                                "clickFunc": this.attachFiles,
                                "disabled": this.state.selectedAssemblyCount !== 1,
                                "icon": "fa-paperclip",
                                "color": "green",
                                'isContextMenu': false,
                                'isButtonMenu': true
                            }, {
                                "name": '',
                                "wmsSelector": this.state.selector + 'RefreshGrid',
                                "value": [],
                                "clickFunc": this.refreshGrid,
                                "disabled": false,
                                "icon": "fa-sync-alt",
                                "color": "blue",
                                'isContextMenu': false,
                                'isButtonMenu': true,
                                'parentClassName': 'attach-file-assembly-refresh-btn'
                            }]}
                    />
                </div>
                {this.state.isOpenColumnModel && (
                    <AddEditColumnModel
                        windowId={this.props.windowId}
                        id={"addEditColumnAssembly" + this.props.windowId}
                        height={420}
                        onCloseColumnModel={this.onCloseColumnModel}
                        isOpenColumnModel={this.state.isOpenColumnModel}
                        allSortColumnList={this.state.allSortColumnList}
                        gridId={this.manageAttachFileAssemblyGridId}
                        updateGridData={this.callForGetNewData}
                        saveChangesInMetaData={this.saveChangesInMetaData}
                    />
                )}
                {this.state.overlayScreenShow && (
                    <LoadingModel
                        sharedOpenLoadingmodel={this.state.overlayScreenShow}
                        onCancelLoadAllData={this.cancelLoadAllData}
                        sharedTotalRequest={this.state.totalRequest}
                    />
                )}
                {this.state.isOpenAttachDoc &&
                <AttachDocuments
                    isOpenAttachDoc={this.state.isOpenAttachDoc}
                    sharedAttachObj={this.state.sharedAttachObj}
                    windowId={this.props.windowId}
                    onClose={(e) => this.setModelState('isOpenAttachDoc', false)}
                />
                }
            </div>
        )
    }
}