import React from 'react';

//component
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsContainer from './../../../components/WmsContainer/WmsContainer.jsx';
import WmsDropdownList from './../../../components/WmsDropdownList/WmsDropdownList.jsx';
import WmsInput from './../../../components/WmsInput/WmsInput.jsx';
import WmsModel from './../../../components/WmsModel/WmsModel.jsx';
import * as findOrdersService from '../findOrders.services.jsx';
import * as GlobalService from './../../../global_services';
import './batching-model.scss';

export class BatchingModel extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;

        this.state = {
            Selector: "OrderBatch",
            batchDescription: "",
            savingGifBatch: false,
            batchOptions: []
        };
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({ batchDescription: '' });
    // }

    exitBatchModel = () => {
        // $("#addToNewBatch").data('kendoWindow').close();
        this.props.onCloseBatchingModel();
    }

    handleBatchChange = (e) => {
        let value = e.target.value;
        if(typeof value == "object" && !value.BatchOrderId){
            value = '';
        }
        this.setState({ batchDescription: value });
    }

    handleOnEnterPress = (e) => {
        this.eventClickCreateAndAdd();
    }

    handleKeyPress = (e) => {
        // && this.state.batchDescription !== ''
        if (e.key === 'Enter') {
            this.eventClickCreateAndAdd();
        }
    }
    eventClickCreateAndAdd = () => {
        this.setState({ savingGifBatch: true });

        if (this.props.sharedBatchProcess == 'addToNew') {
            let batchData = {
                'Ids': this.props.sharedSelectedOrderIds,
                "CustomerId": this.props.sharedSelectedOrderData.CustomerId,
                "Description": this.state.batchDescription
            };
            findOrdersService.createBatch(batchData).then((response) => {

                if (response != 'undefined' && (response.status == 200 || response.status == 204)) {
                    console.log("eventClickCreateAndAdd ", response.data);
                    GlobalService.notification('success', this.translate('Label_BatchCreate_Success'));
                    this.props.autoFilterWithBatch(response.data);
                    this.exitBatchModel();

                } /*else {
                    let errorMessage = this.translate('Label_SystemError');
                    if (response.Error) {
                        errorMessage = response.Error;
                    }
                    GlobalService.apiSystemError(this.injector.get('$rootScope'), { response: {} }, errorMessage);

                }*/
                // this.setState({ savingGifBatch: false });
            }).finally(() => {
                this.setState({ savingGifBatch: false });
            });

        } else {
            let batchData = {};
            if (typeof this.state.batchDescription == "object") {
                batchData = this.state.batchDescription;
            }  
            batchData['Ids'] = this.props.sharedSelectedOrderIds;
            findOrdersService.addToExistingBatch(batchData).then((response) => {

                if (response != 'undefined' && (response.status == 200 || response.status == 204)) {

                    GlobalService.notification('success', this.translate('Label_BatchExisting_Success'));
                    this.props.onResetGridSelection();
                    this.exitBatchModel();

                } /*else {

                    let errorMessage = this.translate('Label_SystemError');
                    if (response.Error) {
                        errorMessage = response.Error;
                    }
                    GlobalService.apiSystemError(this.injector.get('$rootScope'), { response: {} }, errorMessage);

                }*/
                // this.setState({ savingGifBatch: false });
            }).finally(() => { 
                this.setState({ savingGifBatch: false });
            });

        }
    };
    render() {
        return (
            <WmsModel
                id='addToNewBatch'
                title={this.translate((this.props.sharedBatchProcess == 'addToNew') ? 'Label_Add_To_New_Batch' : 'Label_Add_Orders_To_Existing_Batch')}
                width={450}
                height={(this.props.sharedBatchProcess == 'addToNew') ? 226 : 215}
                onCloseFunc={this.props.onCloseBatchingModel}
                isOpen={this.props.sharedIsOpenBatchingModel}
                customClass='wms-model-new-ui-wrapper'>

                <div className='model-content-wrapper'>
                <WmsContainer>
                    {(this.props.sharedBatchProcess == 'addToNew') && (
                        <WmsInput
                            inputName="batchDescription"
                            name="batchDescription"
                            wmsSelector={this.state.Selector + 'Description'}
                            value={this.state.batchDescription}
                            onChangeFunc={this.handleBatchChange}
                            onKeyPressFunc={this.handleKeyPress}
                            isAutoFocus={true}
                            label={this.translate('Label_Batch_Description')} />
                    )}
                    {(this.props.sharedBatchProcess == 'addToExisting') && (
                        <WmsDropdownList
                            id="batchDescription"
                            name="batchDescription"
                            optionsLabel={this.translate('Label_Choose_Batch')}
                            wmsSelector={this.state.Selector + "DropdownlistCustomerBatches"}
                            // value={this.state.batchDescription}
                            onChangeFunc={this.handleBatchChange}
                            textField="Description"
                            valueField="BatchOrderId"
                            onEnterPress={this.handleOnEnterPress}
                            template="<table><tr><td style='width:200px;display: block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;'>(${BatchOrderId}) ${ Description }</td><td style='width:150px;'>${ CustomerName }</td></tr></table>"
                            virtualObj={{
                                itemHeight: 26
                            }}
                            dataSourceObj={{
                                pageSize: 40,
                                serverPaging: true,
                                serverFiltering: true,
                                type: "aspnetmvc-ajax",
                                transport: {
                                    read: {
                                        url: "/WebUI/Shipping/FindOrders/BatchListForCustomerRead",
                                        type: "POST",
                                        data: {
                                            customerId: this.props.sharedSelectedOrderData.CustomerId
                                        }
                                    },
                                },
                                schema: {
                                    data: "Data",
                                    total: "Total"
                                }
                            }} />
                    )}
                </WmsContainer>
                </div>
                <div className="footer-btn-holder">
                <WmsButton
                    label={this.translate('Label_Close')}
                    wmsSelector={this.state.Selector + "Exit"}
                    onClickFunc={this.exitBatchModel}
                    disabled={this.state.savingGifBatch}
                    buttonType="orange"
                    icon={'fa-times-circle-o'}
                />
                <WmsButton
                    label={this.translate((this.props.sharedBatchProcess == 'addToNew') ? 'Label_Create_And_Add' : 'Label_Add')}
                    wmsSelector={this.state.Selector + "SaveExit"}
                    onClickFunc={this.eventClickCreateAndAdd}
                    disabled={(this.props.sharedBatchProcess == 'addToExisting' && this.state.batchDescription == '')}
                    loading={this.state.savingGifBatch}
                    icon={'fa-plus'}
                />
                </div>
            </WmsModel>
        );
    }
}