import React, { Component } from "react";
import WmsCollapse from "../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../components/WmsInput/WmsInput.jsx";

export class RrDonnelleyInfoComponent extends Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.$rootScope = window;
        this.state = { }
    }


    render() {
        return (
            <WmsCollapse
                id={"RrDonnelleyInformation"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <WmsInput
                        id="nickname"
                        inputName="Nickname"
                        wmsSelector={this.props.selector + "nickname"}
                        onChangeFunc={this.props.handleConnectionObjChange}
                        label={this.translate('Label_Account_Nick_Name')}
                        required={true}
                        value={this.props.connectionObj.Nickname}
                    />
                    <WmsInput
                        id="username"
                        inputType={"username"}
                        inputName="Username"
                        wmsSelector={this.props.selector + "username"}
                        onChangeFunc={this.props.handleConnectionObjChange}
                        label={this.translate("Label_Username")}
                        required={true}
                        value={this.props.connectionObj.Username}
                    />
                    <WmsInput
                        id="password"
                        inputType={"password"}
                        inputName="Password"
                        wmsSelector={this.props.selector + "password"}
                        onChangeFunc={this.props.handleConnectionObjChange}
                        label={this.translate("Login_Lbl_Password")}
                        required={true}
                        value={this.props.connectionObj.Password}
                    />
                </div>
            </WmsCollapse>
        );
    }
}
