import React, { Component } from 'react'

// Components
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from '../components/WmsContainer/WmsContainer.jsx';
import WmsInput from '../components/WmsInput/WmsInput.jsx';
import WmsComboBox from '../components/WmsComboBox/WmsComboBox.jsx';
import WmsFormGrid from '../components/WmsFormGrid/WmsFormGrid.jsx';

// Services
import * as barcodeServices from './barcode.services.jsx';
import WmsDropdownList from '../components/WmsDropdownList/WmsDropdownList.jsx';

import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';

export class BarcodeForm extends Component {
    constructor(props) {
        super(props);

        
        this.translate = window.translate
        this.$rootScope = window;

        this.state = {
            Selector: "Barcode",
            savingGif: false,
            PositionUsed: false,
            ListBarCodeCharUsedPositions: [],
            OptionsBarcodeElements: [],
            OptionsBarcodeGroup: [],
            barcodeElement: {
                Name: '',
                Start: '',
                Length: '',
            },
            barcodeData: {
                Name: '',
                Group: '',
                TotalLength: 0,
                BarcodeElements: []
            },
            barcodeTotalLength: 0,
            BoolTotalCharOk: true,
        }
    }

    componentDidMount = () => {
        this.createBarcodeDefsPageLoad(this.props.selectedCustomer);
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.selectedCustomer.id !== nextProps.selectedCustomer.id) {
            this.createBarcodeDefsPageLoad(nextProps.selectedCustomer);
        }
    }

    createBarcodeDefsPageLoad = (seleCustomer) => {

        var custId = seleCustomer.id
        kendo.ui.progress($('.barcode-form'), true);

        barcodeServices.createBarcodeDefsPageLoad(custId).then((response) => {
            var barcodeGroups = [];
            response.Groups.forEach(value => {
                if (typeof value != 'undefined' && value != null) {
                    barcodeGroups.push({
                        key: value,
                        value: value,
                    })
                }
            });

            this.resetBarcodeDetails();

            this.setState({
                OptionsBarcodeElements: response.Elements,
                OptionsBarcodeGroup: barcodeGroups,
            });

        }).finally(() => {
            kendo.ui.progress($('.barcode-form'), false);
        });
    }

    updateBarcodeData = (e) => {
        this.setState({
            barcodeData: Object.assign(
                this.state.barcodeData,
                {
                    [e.target.name]: e.target.value
                }
            )
        });
    }

    updateBarcodeElement = (event, key) => {
        var value = event.target.value;
        var elmObj = {
            [key]: value,
        }

        var positionUsed = false;
        var dict = {};
        var proposedSlots = [];
        var barCodeCharUsedPositionsList = Object.assign([],this.state.ListBarCodeCharUsedPositions);

        if (key === "Name") {
            elmObj = {
                [key]: value.Name,
                IsSavedElement: value.IsSavedElement
            }
        } else {
            barCodeCharUsedPositionsList.forEach((pos, key) => {
                dict[pos] = true;
            });

            if (key === "Start") {
                if (this.state.barcodeElement.Length) {
                    // lets get a list of all the proposed slots they are using
                    for (var y = 0; y < parseInt(this.state.barcodeElement.Length); y++) {
                        proposedSlots.push(parseInt(value) + y);
                    }
                } else {
                    proposedSlots.push(parseInt(value));
                }
            } else if (key === "Length") {
                if (this.state.barcodeElement.Start) {
                    // lets get a list of all the proposed slots they are using
                    for (var y = 0; y < parseInt(value); y++) {
                        proposedSlots.push(parseInt(this.state.barcodeElement.Start) + y);
                    }
                }
            }

            // lets loop through our proposed slots and make sure non of them are on our dictionary, if they are that slot is already in use
            for (var z = 0; z < proposedSlots.length; z++) {
                if (dict.hasOwnProperty(proposedSlots[z])) {
                    positionUsed = true;
                    break;
                }
            }
        }

        this.setState({
            barcodeElement: Object.assign(
                this.state.barcodeElement,
                elmObj,
            ),
            PositionUsed: positionUsed,
        });
    }

    addBarcodeElement = (event) => {
        var elementLists = Object.assign([],this.state.barcodeData.BarcodeElements);
        var elements = Object.assign({},this.state.barcodeElement);
        elementLists.push(elements);

        this.setState({
            barcodeElement: Object.assign(
                this.state.barcodeElement,
                {
                    Start: '',
                    Length: '',
                }
            ),
            barcodeData: Object.assign(
                this.state.barcodeData,
                {
                    BarcodeElements: elementLists
                }
            )
        }, this.barcodeCharCalculation);


    }

    barcodeCharCalculation = () => {
        var temp = [];
        var barcodeElements = Object.assign([],this.state.barcodeData.BarcodeElements);
        var highestStart = 0;
        var lengthOnHighestStart = 0;
        var BoolTotalCharOk = false;
        var barcodeTotalLength = this.state.barcodeTotalLength;

        barcodeElements.forEach((element, key) => {
            for (var x = 0; x < element.Length; x++) {
                temp.push(parseInt(element.Start) + parseInt(x));
            }

            if (parseInt(element.Start) > parseInt(highestStart)) {
                highestStart = parseInt(element.Start);
                lengthOnHighestStart = parseInt(element.Length);
            }
        });
        temp.sort(function (a, b) { //Array now becomes [7, 8, 25, 41]
            return a - b;
        });

        if ((parseInt(highestStart) + parseInt(lengthOnHighestStart)) > parseInt(barcodeTotalLength)) {
            barcodeTotalLength = (parseInt(highestStart) + parseInt(lengthOnHighestStart) - 1);

            var gridData = $("#GridFindBarcodeDefs").data().kendoGrid.dataSource.view();
            var exists = ObjectWms.FindIndexByProperty(gridData, "TotalLength", parseInt(barcodeTotalLength));
            if (exists == -1) {
                BoolTotalCharOk = true;
            }
        }

        this.setState({
            BoolTotalCharOk: BoolTotalCharOk,
            barcodeTotalLength: barcodeTotalLength,
            ListBarCodeCharUsedPositions: temp,
        });
    }

    resetBarcodeDetails = () => {
        this.setState({
            barcodeElement: {
                Name: '',
                Start: '',
                Length: '',
            },
            barcodeData: {
                Name: '',
                Group: '',
                TotalLength: 0,
                BarcodeElements: []
            },
            barcodeTotalLength: 0,
            ListBarCodeCharUsedPositions: [],
            PositionUsed: false,
            BoolTotalCharOk: true
        }, () => {
            $("#barcodeElement").data("kendoDropDownList").refresh();
        });
    }

    eventChangeTotalLength = (event) => {
        var totalLength = event.target.value;
        var boolTotalCharOk = true;
        if (Math.max.apply(null, Object.assign([],this.state.ListBarCodeCharUsedPositions)) > parseInt(totalLength)) {
            boolTotalCharOk = false;
        }

        var gridData = $("#GridFindBarcodeDefs").data().kendoGrid.dataSource.view();
        var exists = ObjectWms.FindIndexByProperty(gridData, "TotalLength", parseInt(totalLength));
        if (exists == -1) {
            boolTotalCharOk = true;
        } else {
            boolTotalCharOk = false;
        }

        this.setState({
            barcodeTotalLength: totalLength,
            BoolTotalCharOk: boolTotalCharOk,
        });
    }

    saveBarcode = () => {

        var barcodeData =  Object.assign({},this.state.barcodeData);
        barcodeData.TotalLength = this.state.barcodeTotalLength;
        this.setState({ savingGif: true });

        barcodeServices.saveBarcodeDef(this.props.selectedCustomer.uri, barcodeData).then((response) => {
            this.setState({ savingGif: false });
            this.props.barcodeAdded();
            this.props.onOpenCreateBarcode(false);
        }).finally(() => { 
            this.setState({ savingGif: false });
        });
    }

    render() {
        return (
            <div className="barcode-form">
                <div>
                    <WmsButton
                        label={this.translate("Label_Save")}
                        wmsSelector={this.state.Selector + "SaveButton"}
                        onClickFunc={this.saveBarcode}
                        icon="fa-floppy-o"
                        buttonType="green"
                        tooltipText={
                            (!this.state.barcodeData.Name) ?
                                this.translate("Tooltip_Required_Fields")
                            :(!this.state.BoolTotalCharOk) ?
                                this.translate("Label_Length_already_used")
                            :(this.state.barcodeData.BarcodeElements.length == 0) ?
                                this.translate("Label_PLese_add_element")
                            :''
                        }
                        showToolTip={
                            !this.state.BoolTotalCharOk ||
                            !this.state.barcodeData.Name ||
                            this.state.ListBarCodeCharUsedPositions.length == 0 ||
                            this.state.barcodeTotalLength == 0 ||
                            this.state.barcodeData.BarcodeElements.length == 0
                        }
                        disabled={(
                            this.state.savingGif ||
                            !this.state.barcodeData.Name ||
                            !this.state.BoolTotalCharOk ||
                            this.state.ListBarCodeCharUsedPositions.length == 0 ||
                            this.state.barcodeTotalLength == 0 ||
                            this.state.barcodeData.BarcodeElements.length == 0
                        )}
                        loading={this.state.savingGif}
                    />

                    <WmsButton
                        label={this.translate("Label_ResetBarcode")}
                        wmsSelector={this.state.Selector + "ResetButton"}
                        onClickFunc={this.resetBarcodeDetails}
                        icon="fa-sign-out-alt"
                        buttonType="yellow"
                    />

                    <WmsButton
                        label={this.translate("Label_Exit")}
                        wmsSelector={this.state.Selector + "ExitButton"}
                        onClickFunc={(e) => this.props.onOpenCreateBarcode(false)}
                        icon="fa-sign-out-alt"
                        buttonType="yellow"
                    />
                </div>

                <WmsCollapse
                id={'manageBarcdeCreateCollapse'}
                headerLabel={this.translate('Window_Title_Label_CreateBarcode')} >

                    <div className='conatiner-title'>
                        <label>
                            {this.translate("Label_Customer_Text")+ ": "}
                            <span>{this.props.selectedCustomer.name}</span>
                        </label>
                    </div>

                    <WmsContainer
                    id={'barcodeCustomerTextSection'}>
                        <WmsInput
                            id='Name'
                            inputName='Name'
                            wmsSelector={this.state.Selector + 'Id'}
                            onChangeFunc={this.updateBarcodeData}
                            label={this.translate('Label_Barcode_ID')}
                            regularExp={/^.{1,255}$/}
                            value={this.state.barcodeData.Name}
                            required={true} />

                        <WmsComboBox
                            id={'Group'}
                            name='Group'
                            wmsSelector={this.state.Selector + 'Group'}
                            options={this.state.OptionsBarcodeGroup}
                            value={this.state.barcodeData.Group}
                            valueField='key'
                            textField='value'
                            onChangeFunc={this.updateBarcodeData}
                            placeholder={this.translate('Create_Barcode_Combobox_Place_Holder')}
                            label={this.translate('Label_Barcode_Group')}
                        />
                    </WmsContainer>

                    <WmsContainer
                    id={'barcodeElementDefination'}
                    title={this.translate("Label_Barcode_Element_Definition")}>
                        <div className="barcode-element-form">
                            <WmsDropdownList
                                id={"barcodeElement"}
                                name="selectBarcodeElement"
                                windowId={this.props.windowId}
                                optionsLabel={this.translate('Create_Barcode_Select_Element_Place_Holder')}
                                label={this.translate('Label_Element')}
                                wmsSelector={this.state.Selector + "Element"}
                                value={this.state.barcodeElement.Name}
                                onChangeFunc={(e) => this.updateBarcodeElement(e, 'Name')}
                                textField="Name"
                                valueField="Name"
                                isVirtualOptions={false}
                                staticOptions={this.state.OptionsBarcodeElements}
                            />

                            <WmsInput
                                inputName="start"
                                name="start"
                                wmsSelector={this.state.Selector + 'Start'}
                                value={this.state.barcodeElement.Start}
                                onChangeFunc={(e) => this.updateBarcodeElement(e, 'Start')}
                                label={this.translate('Label_Start')}
                                regularExp={/^[1-9][0-9]*$/} />

                            <WmsInput
                                inputName="length"
                                name="length"
                                wmsSelector={this.state.Selector + 'Length'}
                                value={this.state.barcodeElement.Length}
                                onChangeFunc={(e) => this.updateBarcodeElement(e, 'Length')}
                                label={this.translate('Label_Length')}
                                regularExp={/^[1-9][0-9]*$/} />
                        </div>


                        <WmsButton
                            label={this.translate("Label_Add_Element")}
                            wmsSelector={this.state.Selector + "AddElement"}
                            onClickFunc={this.addBarcodeElement}
                            icon="fa-plus"
                            buttonType="green"
                            disabled={(
                                this.state.barcodeElement.Name == "" ||
                                this.state.barcodeElement.Start == "" ||
                                this.state.barcodeElement.Length == "" ||
                                this.state.PositionUsed
                            )}
                        />

                        {this.state.PositionUsed && (
                            <div className="note">
                                {this.translate('Label_Position_Already_inUse')}
                            </div>
                        )}

                        {this.state.ListBarCodeCharUsedPositions.length == 0 && (
                            <div className="text">
                                {this.translate('Label_Barcode_Definitions')}
                            </div>
                        )}

                        {this.state.ListBarCodeCharUsedPositions.length > 0 && (
                            <div className="text">
                                {this.translate('Label_Positions_Specified')}:
                                [{this.state.ListBarCodeCharUsedPositions.join(',')}]
                            </div>
                        )}

                        <WmsFormGrid
                            id={'barcodeElementsGrid'}
                            wmsSelector={this.state.Selector + 'ElementsGrid'}
                            gridKeySelection={''}
                            parentClassName=''
                            columns={[
                                {
                                    field: "Name",
                                    title: "Element",
                                    width: 100
                                },
                                {
                                    field: "Start",
                                    title: "Start",
                                    width: 100
                                },
                                {
                                    field: "Length",
                                    title: "Length",
                                    width: 100
                                },{
                                    field: "IsSavedElement",
                                    title: "IsSavedElement",
                                    lockable: false,
                                    width: 140
                                }
                            ]}
                            staticGridData={this.state.barcodeData.BarcodeElements}
                            windowId={this.props.windowId}
                            schema={{
                                model: {
                                    fields: {
                                        Name: {
                                            type: 'String'
                                        },
                                        Start: {
                                            type: 'Number'
                                        },
                                        Length: {
                                            type: 'Number'
                                        },
                                        IsSavedElement: {
                                            type: 'Boolean'
                                        }
                                    }
                                }
                            }}
                            isColumnsSortable={true}
                        />



                        <div className="total-character">
                            {this.state.BoolTotalCharOk == false && (
                                <div className="alert-message">{this.translate('Label_Length_already_used')}</div>
                            )}

                            <WmsInput
                                inputName="TotalCharacter"
                                name="TotalCharacter"
                                wmsSelector={this.state.Selector + 'TotalCharacter'}
                                value={this.state.barcodeTotalLength}
                                onChangeFunc={this.eventChangeTotalLength}
                                label={this.translate('Label_Total_Character')}
                                regularExp={/^[1-9][0-9]*$/}
                                required={(this.state.BoolTotalCharOk == false)}
                            />
                        </div>

                    </WmsContainer>
                </WmsCollapse>
            </div>
        )
    }
}
