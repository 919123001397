import * as GlobalService from "../react/global_services";
var isFirstTimeOpen = true;
var keyName = "AdjustInventory";
var metaDataAdjustInventoryObject = {
    "AdjustInventory": {
        "filterData": {},
        "gridConfig": {},
    },
};
var axios = require("axios");
declare var window: any;
const { $ } = window;

export const setGridFiltere = function (param_filterObj, cancleToken) {
    console.log("SetGridFilter", param_filterObj);

    let url = "/WebUI/Inventory/adjustments/FilteredRowCount";

    return axios.put(url, param_filterObj, { cancelToken: cancleToken }).then((response: any) => {
        return response;
    });
};
export const clearGridFiltere = function (param_filterObj) {
    let url = "/WebUI/Inventory/UpdateSession/AdjustmentSearchInfo";

    return axios.delete(url).then((response: any) => {
        return response;
    });
};

export const getGridData = function () {
    let url = "/WebUI/Inventory/adjustments/adjustments?sort=&page=1&pageSize=50&group=&filter="; // ?sort=&page=1&pageSize=50&group=&filter=

    return axios.post(url).then(function (response: any) {
        return response;
    });
};

export const saveAdjustInventoryData = function (data) {
    let url = "/WebUI/Inventory/adjustments/adjustment";

    return axios.post(url, data).then(function (response) {
        return response;
    });
};
export const InventoryCalculateCharges = function (data, getAutoGenerated) {
    let url = "/WebUI/inventory/adjustments/InventoryAdjustmentCharges";
    if (getAutoGenerated) {
        data.GetAutoGenerated = true;
    } else {
        data.GetAutoGenerated = false;
    }
    return axios.post(url, data).then(function (response) {
        return response;
    });
};

// call api for get metaData of AdjustInventory is it's open first time
export const getAdjustInventoryClientMetaData = function () {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response !== "") {
                    Object.assign(metaDataAdjustInventoryObject[keyName], JSON.parse(response));
                }
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                return false;
            }
        );
    }
};

export const setAdjustInventoryFilterMetaData = function (filterData) {
    metaDataAdjustInventoryObject[keyName]["filterData"] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataAdjustInventoryObject[keyName]));
};

export const getAdjustInventoryFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataAdjustInventoryObject[keyName]["filterData"])) {
        return metaDataAdjustInventoryObject[keyName]["filterData"];
    } else {
        return "";
    }
};
export const setAdjustInventoryGridOptionInMetaData = function (gridConfig) {
    metaDataAdjustInventoryObject[keyName]["gridConfig"] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataAdjustInventoryObject[keyName]));
};
export const getAdjustInventoryGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataAdjustInventoryObject[keyName]["gridConfig"])) {
        return metaDataAdjustInventoryObject[keyName]["gridConfig"];
    } else {
        return "";
    }
};

export const clearAdjustInventoryFilterMetaData = function () {
    metaDataAdjustInventoryObject[keyName]["filterData"] = {};
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataAdjustInventoryObject[keyName]));
};

export const saveReverseAdjustment = function (uri: any, data: any) {
    let url = "/WebUI/Inventory/adjustments/adjustmentreverser?uri=" + uri;
    return axios.post(url, data).then(function (response: any) {
        return response;
    });
};

export const getAdjustmentData = function (filterData: any) {
    let url = "/WebUI/Inventory/adjustments/AdjustmentData?sorts=[]&filters=[]&pgnum=1&pgsiz=1";
    return axios.put(url, { adjustmentSearchInfo: filterData }).then(function (response: any) {
        return response;
    });
};

export const getReceiveItemGridData = function (data: any) {
    let url = "/WebUI/Inventory/InventoryUI/InventoryData?sorts=[]&filters=[]&pgnum=1&pgsiz=50";
    return axios
        .put(url, {
            columns: [
                "ReceiveItemId",
                "ReceiptDate",
                "ReceiverId",
                "ExpirationDate",
                "MovableUnitLabel",
                "LocationName",
                "PrimaryAvailable",
                "LotNumber",
                "SerialNumber",
            ], // Receved, MovablUnit,
            inventorySearchInfo: data,
        })
        .then(function (response: any) {
            return response;
        });
};

export const ProposedAdjustmentAllocations = function (data: any) {
    return axios.put("/WebUI/Inventory/Adjustments/ProposedAdjustmentAllocations", data).then(function (response: any) {
        return response;
    });
};

export const getCharges = function () {
    return axios.get("/WebUI/Shipping/Orders/GetChargeCategories?idList=1,2,3,5,6").then(function (response: any) {
        return response;
    });
};

export const checkifMUExistsMultipleLocs = function (palletLabel, facId, loc, paramReceiveItemId) {
    var params = {
        label: palletLabel,
        facilityId: facId,
        location: loc,
        receiveItemId: null,
    };
    if (paramReceiveItemId) {
        params.receiveItemId = paramReceiveItemId;
    }
    return axios
        .post("/WebUI/Receiving/ManageReceivings/CheckIfMUExistsInMultipleLocations", params)
        .then(function (response) {
            return response.data;
        });
};
