import React, { Component } from 'react';

import WmsModel from '../../components/WmsModel/WmsModel.jsx';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsFormGrid from '../../components/WmsFormGrid/WmsFormGrid.jsx';
import { ArrayWms } from '../../../../assets/ts/ArrayWms.ts';

import './scan-order-customer-selection-modal.scss';

export class ScanOrderCustomerSelectionModal extends Component {
    constructor(props) {
        super(props);

        this.$rootScope = window;
        this.translate = window.translate;

        this.state = {
            selector: "scanOrderCustomerSelection",
            selectedCustomer: null
        }
    }

    selectCustomer = () => {
        let grid = $("#" + this.state.selector + 'Grid').data("kendoGrid");
        let selectedCustomer = null;
        $.map(grid.select(), (item) => {
            selectedCustomer = ArrayWms.findObjectByKey(this.props.sharedScannedOrderData, 'OrderId', grid.dataItem(item).OrderId);
        });

        this.setState({
            selectedCustomer: selectedCustomer
        });
    }

    onClickContinue = () => {
        if(this.state.selectedCustomer) {
            this.props.onClickContinue(this.state.selectedCustomer)
        }
    }

    render() {
        return (
            <div>
                <WmsModel
                    id='scanOrderCustomerSelectionModel'
                    title={this.translate('Label_Select_Customer_For_Scan')}
                    width={450}
                    height={240}
                    isOpen={this.props.sharedIsScanOrderCustomerSelectionOpen}
                    onCloseFunc={this.props.onCloseScanOrderCustomerSelection}
                    customClass='wms-model-new-ui-wrapper'
                >
                    <div className="model-content-wrapper">
                            <WmsFormGrid
                                id={this.state.selector+'Grid'}
                                wmsSelector={this.state.selector + 'Grid'}
                                gridKeySelection={'OrderId'}
                                columns={[
                                    {
                                        field: "OrderId",
                                        title: "Order Id",
                                        lockable: false,
                                        width: "100%"
                                    },
                                    {
                                        field: "Customer",
                                        title: "Customer Name",
                                        lockable: false,
                                        width: "100%"
                                    }
                                ]}
                                schema={{
                                    model: {
                                        fields: {
                                            OrderId: {
                                                type: "string",
                                                editable: false
                                            },
                                            Customer: {
                                                type: "string",
                                                editable: false
                                            }
                                        }
                                    }
                                }}
                                staticGridData={this.props.sharedScannedOrderData}
                                parentClassName={'showHeader'}
                                onSelection={this.selectCustomer}
                                IsSelectable={true}
                            />
                    </div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={this.translate("Label_Cancel")}
                            wmsSelector={this.state.selector + "ButtonCancel"}
                            onClickFunc={this.props.onCloseScanOrderCustomerSelection}
                            icon="fa-ban"
                            buttonType="orange"
                        />
                        <WmsButton
                            label={this.translate('Label_Continue')}
                            wmsSelector={this.state.selector + "ButtonContinue"}
                            onClickFunc={this.onClickContinue}
                            icon={'fa-check-circle'}
                            disabled={(this.state.selectedCustomer == null)}
                        />
                    </div>
                </WmsModel>
            </div>
        )
    }
}
