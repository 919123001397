import type { GridColumnsConfig } from "../../../../../types/gridTypes";

export interface GridFilter {
    field: string;
    operator: string;
    value: string;
}

export const getGridDefaultColumns = ({ gridHeaders, customLabels }: GridColumnsConfig) => {
    const centrilizedStyling = { style: "text-align:center;" };
    return [
        {
            field: "RowNumber",
            title: "#",
            width: 60,
            wmsid: 1,
            locked: true,
            lockable: false,
            resizable: false,
            sortable: false,
            reorderable: false,
            filterable: false,
            attributes: centrilizedStyling,
            headerAttributes: centrilizedStyling,
        },
        {
            field: "Customer",
            title: customLabels.Label_Customer,
            width: 120,
            wmsid: 7,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelGridGridCustomer",
            },
        },
        {
            field: "Warehouse",
            title: customLabels.Label_Warehouse,
            width: 120,
            wmsid: 26,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelGridWarehouse",
            },
        },
        {
            field: "OrderId",
            title: "Order ID",
            width: 120,
            wmsid: 4,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelGridOrderOrBatchId",
            },
            template: function (dataItem) {
                if (["batchesAndOrders", "batchesOrders"].includes(dataItem?.displayOption)) {
                    return dataItem?.Orders?.length > 1 ? "Multiple" : "-";
                }
                return dataItem?.OrderId;
            },
        },
        {
            field: "BatchName",
            title: "Batch Name",
            width: 120,
            wmsid: 4,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelGridOrderOrBatchId",
            },
        },
        {
            title: "Is Batch",
            width: 120,
            wmsid: 4,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelGridIsBatch",
            },
            // eslint-disable-next-line sonarjs/no-identical-functions
            template: function (dataItem) {
                return !!dataItem?.BatchOrderId ? "Yes" : "No";
            },
        },
        {
            field: "TotPackages",
            title: gridHeaders[8].description,
            width: 120,
            wmsid: 9,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelGridTotPackages",
            },
        },
        {
            field: "Status",
            title: "Status",
            width: 120,
            wmsid: 7,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelCreateHVPGridStatus",
            },
            // eslint-disable-next-line prettier/prettier
            template: '#if(Status == "open") {# #= "Open" # #} else if(Status == "queued" || Status == "processing") {#  #= "Processing" # #} else if(Status == "completed") {#  #= "Complete" # #} else if(Status == "completed_with_errors" && Errors > 0) {# <div class="error-icon-status" Batch-Name="#=BatchId#" batch-errors-url="#=BatchErrorsUrl#" batch-shipments-url="#=BatchShipmentsUrl#" > #= "Complete" #  <i class="facility-exclamator fa fa-exclamation-triangle " ></i> </div> #} else if(Status == "completed_with_errors") {#  #= "Complete" # #} else{# #=Status # #}#',
        },
        {
            field: "CreationDate",
            title: "Created Date",
            width: 120,
            wmsid: 16,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelGridShipService",
            },
            template: function (dataItem) {
                const columnVariable = dataItem?.Orders?.length ? "BatchCreationDate" : "CreationDate";

                if (
                    typeof dataItem[columnVariable as string] != undefined &&
                    dataItem[columnVariable as string] != null
                ) {
                    return kendo.toString(
                        kendo.parseDate(dataItem[columnVariable as string]),
                        "MMM dd yyyy, hh:mm:ss tt"
                    );
                }
                return "";
            },
        },
    ];
};
