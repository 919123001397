import React, { Component } from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsDropdown from "../../../components/WmsDropdown/WmsDropdown.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";

export class USPSBukuShipAccountInfoComponent extends Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;
    }


    render() {
        return (
            <WmsCollapse
                id={"carrierAccountInformation"}
                headerLabel={this.translate("Label_Company_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className='display-inline'>
                    <WmsInput
                        id="nickname"
                        inputName="Nickname"
                        wmsSelector={this.props.selector + "nickname"}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        label={this.translate('Label_Account_Nick_Name')}
                        value={this.props.currentCarrierObj.Nickname}
                        required={true}
                    />
                    <WmsInput
                        id='firstName'
                        inputName='FirstName'
                        wmsSelector={this.props.selector + 'firstName'}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        label={this.translate('Label_Warehouse_First_Name')}
                        extraWrapperClass={'wms-input-names-box'}
                        value={this.props.currentCarrierObj.FirstName}
                        required={true}
                         />

                    <WmsInput
                        id='lastName'
                        inputName='LastName'
                        wmsSelector={this.props.selector + 'lastName'}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        label={this.translate('Label_Warehouse_Last_Name')}
                        extraWrapperClass="wms-input-title-box"
                        required={true}
                        value={this.props.currentCarrierObj.LastName} />
                </div>

                <WmsInput
                    id='company'
                    inputName='Company'
                    wmsSelector={this.props.selector + 'company'}
                    onChangeFunc={this.props.handleCarrierObjChange}
                    label={this.translate('Label_Company_Name')}
                    extraWrapperClass={'wms-input-names-box'}
                    required={true}
                    value={this.props.currentCarrierObj.Company}
                />

                <WmsInput
                    id='phone'
                    inputName='Phone'
                    wmsSelector={this.props.selector + 'phoneNumber'}
                    onChangeFunc={this.props.handleCarrierObjChange}
                    label={this.translate('Label_Warehouse_PhoneNumber')}
                    extraWrapperClass="wms-input-title-box"
                    value={this.props.currentCarrierObj.Phone}
                    required={true}
                    valueType="number"
                />

                <WmsInput
                    id='email'
                    inputName='Email'
                    wmsSelector={this.props.selector + 'emailAddress'}
                    onChangeFunc={this.props.handleCarrierObjChange}
                    label={this.translate('Label_Email_Address')}
                    extraWrapperClass="wms-input-address-box"
                    required={true}
                    value={this.props.currentCarrierObj.Email}
                />

                <WmsInput
                    id='address1'
                    inputName='Address1'
                    wmsSelector={this.props.selector + 'address1'}
                    onChangeFunc={this.props.handleCarrierObjChange}
                    label={this.translate('Label_Address_1')}
                    extraWrapperClass="wms-input-address-half-box"
                    required={true}
                    value={this.props.currentCarrierObj.Address1}
                />
                <WmsInput
                    id='address2'
                    inputName='Address2'
                    wmsSelector={this.props.selector + 'address2'}
                    onChangeFunc={this.props.handleCarrierObjChange}
                    label={this.translate('Label_Address_2')}
                    extraWrapperClass="wms-input-address-half-box"
                    value={this.props.currentCarrierObj.Address2}
                />

                <WmsInput
                    id='city'
                    inputName='City'
                    wmsSelector={this.props.selector + 'city'}
                    onChangeFunc={this.props.handleCarrierObjChange}
                    label={this.translate('Label_Warehouse_City')}
                    extraWrapperClass="wms-input-city-box"
                    value={this.props.currentCarrierObj.City}
                    required={true}
                />

                <WmsDropdown
                    id="state"
                    name="State"
                    label={this.translate('Label_Costomer_State_Province')}
                    wmsSelector={this.props.selector + "state'"}
                    onChangeFunc={this.props.handleCarrierObjChange}
                    disabled={this.props.currentCarrierObj.CountryCode ? false : true}
                    value={this.props.currentCarrierObj.State}
                    options={this.props.optionsState}
                    valueField='RegionCode'
                    textField='Name'
                    parentClassName='form_group'
                    extraWrapperClass="wms-dropdown-state-box"
                    required={true}
                />

                <WmsInput
                    id='postalCode'
                    inputName='PostalCode'
                    wmsSelector={this.props.selector + 'zip'}
                    onChangeFunc={this.props.handleCarrierObjChange}
                    label={this.translate('Label_Account_Zip_Postal_code')}
                    extraWrapperClass="wms-input-zip-postal-code-box"
                    value={this.props.currentCarrierObj.PostalCode}
                    required={true}
                />

                <WmsDropdown
                    id="countryCode"
                    name="CountryCode"
                    label={this.translate('Label_Country')}
                    wmsSelector={this.props.selector + "country"}
                    onChangeFunc={this.props.handleCarrierObjChange}
                    value={this.props.currentCarrierObj.CountryCode}
                    options={this.props.optionsCountry}
                    valueField='CountryCode'
                    textField='Name'
                    parentClassName='form_group'
                    extraWrapperClass="wms-dropdown-country-box"
                    required={true}
                    />
            </WmsCollapse>
        );
    }
}
