import React, { Component } from 'react'
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx'
import WmsInput from '../../../components/WmsInput/WmsInput.jsx'

export class EpostGlobalAccountInfoComponent extends Component {
    constructor(props) {
        super(props)

        this.translate = window.translate;
        this.$rootScope = window;
    }

    render() {
        return (
            <WmsCollapse
                id={"ePostGlobalAccountInfoScreen"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    {this.props.selectedProvider == 'shipEngine' ? (
                        <>
                            <WmsInput
                                id="nickname"
                                inputName="Nickname"
                                wmsSelector={this.props.selector + "nickname"}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate('Label_Nick_Name')}
                                value={this.props.currentCarrierObj.Nickname}
                                required={true}
                            />
                            <WmsInput
                                id="username"
                                inputName="Username"
                                wmsSelector={this.props.selector + "username"}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate("Label_Username")}
                                value={this.props.currentCarrierObj.Username}
                                required={true}
                            />
                            <WmsInput
                                id="password"
                                inputType={"password"}
                                inputName="Password"
                                wmsSelector={this.props.selector + "password"}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate("Login_Lbl_Password")}
                                value={this.props.currentCarrierObj.Password}
                                required={true}
                            />
                        </>
                    ) : (
                        <>
                            <WmsInput
                                id="accountNickname"
                                inputName="Nickname"
                                wmsSelector={this.props.selector + "nickname"}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate("Label_Account_Nick_Name")}
                                value={this.props.currentCarrierObj.Nickname}
                                required={true}
                                extraWrapperClass="w-42"
                            />
                            <WmsInput
                                id="api_token"
                                inputName="ApiToken"
                                wmsSelector={this.props.selector + "apiToken"}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate("Label_API_Token")}
                                value={this.props.currentCarrierObj.ApiToken}
                                extraWrapperClass="w-42"
                                required={true}
                                maxLength={300}
                            />
                        </>
                    )}
                </div>
            </WmsCollapse>
        )
    }
}
