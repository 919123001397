/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { ChangeEvent, useState, useEffect } from "react";
import { default as WmsDropdown } from "../../../components/WmsDropdown/WmsDropdown.jsx";
import { FormValues } from "./create-hvp-batch-modal.js";
import * as GlobalService from "../../../global_services.js";
import { DropdownOption } from "./create-hvp-batch-modal.js";
import { useContext } from "react";
import { CreateHVPContext } from "../context/createHVPContext";
import VirtualGridComponent from "../../../components_proto/VirtualGridComponent/VirtualGridComponent.jsx";
import * as GridConfig from "./create-hvp-batch-modal-step-2-grid-config";
import { StringWms } from "../../../../../assets/ts/StringWms";
import { KendoGridWmsVirtual } from "../../../../../assets/ts/KendoGridWmsVirtual";
import "./create-hvp-batch-modal.scss";

interface CreateHVPBatchStep1Props {
    selector: string;
    warehouseOptions: DropdownOption[];
    customerOptions: DropdownOption[];
    packageOptions: DropdownOption[];
    formValues: FormValues;
    onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface EventObjectProperties {
    name: string;
    value: any;
}

export default function CreateHVPBatchStep2({
    selector,
    onInputChange,
    warehouseOptions,
    customerOptions,
    packageOptions,
    formValues,
}: CreateHVPBatchStep1Props) {
    /**
     * Constants and Globals
     */
    const translate = (window as any).translate as any; //used to translate texts;
    const gridId = "SmallParcelCreateHVPRemoteGrid";
    const displayBatchesOptions = [
        { description: "Batches and Single Orders", value: "batchesAndOrders" },
        { description: "Single Orders Only", value: "singleOrders" },
        { description: "Batches Only", value: "batchesOrders" },
    ];

    const smallParcelHVPGridHeaders = StringWms.convertSringToOption(translate("SmallParcel_GridHeaders"));

    const defaultColumns = GridConfig.getGridDefaultColumns({
        customLabels: GlobalService.getCustomLabels(),
        gridHeaders: smallParcelHVPGridHeaders,
    });

    const defaultFilters = {
        SearchTarget: "",
        IndexOptionsCustomerId: formValues.customer,
        IndexOptionsFacilityId: formValues.warehouse,
        IndexOptionsSearchBy: 0,
        StartDate: "",
        EndDate: "",
        IndexOptionBatchName: 0,
        BatchName: "",
        IndexOptionBatchOrderId: 0,
        IndexOptionsReferenceNumber: 0,
        ReferenceNumber: "",
        IndexOptionsPoNum: 0,
        PurchaseOrderNumber: "",
        IndexOptionsShipToCountry: 0,
        ShipToCountry: "",
        IndexOptionsShipToState: 0,
        ShipToState: "",
        IndexOptionsShipToZip: 0,
        ShipToZip: "",
        IndexOptionsOrderNote: 0,
        OrderNote: "",
        Skus: [],
        sharedSelectOrder: null,
        openFormType: "",
        IndexOptionsStatus: 0,
    };

    /**
     * States and Contexts
     */
    const { setModalConfig, modalConfig } = useContext(CreateHVPContext);
    const [localKendoGrid, setLocalKendoGrid] = useState(
        new KendoGridWmsVirtual(gridId, modalConfig.windowId, "OrderId")
    );

    const fixedColumnsToRequest = [
        "OrderId",
        "BatchOrderId",
        "BatchName",
        "Status",
        "CreatedDate",
        "SmallParcelShipDate",
        "BatchCreationDate",
    ];

    /**
     * Functions
     */

    const createEventObject = ({ value, name }: EventObjectProperties) => {
        return { target: { name, value } };
    };

    const setLoading = (active: boolean) => {
        kendo.ui.progress($("#createHVPBatchModal"), active);
    };

    const getSmallParcelData = async () => {
        setLoading(true);
        const { data } = await axios.put("/WebUI/Shipping/SmartParcel/SmartParcelData?filters=[]", {
            "columns": [...defaultColumns.map((col) => col.field), ...fixedColumnsToRequest],
            "searchInfo": defaultFilters,
        });

        const gridDataChangeEvent = createEventObject({
            name: "gridOrdersOnlyData",
            value: data?.Data ?? [],
        });

        onInputChange(gridDataChangeEvent as ChangeEvent<HTMLInputElement>);
        setLoading(false);
    };

    const clearGridSelection = () => {
        if (localKendoGrid !== null) {
            localKendoGrid.clearSelections(() => {
                localKendoGrid.repaintSelections();
            });
        }
    };

    const handleRowSelection = (kendoGrid: KendoGridWmsVirtual, isCallFrom?: boolean) => {
        const selectedValues = kendoGrid.getDictionary().Values();
        const selectedOrders = Array.from(
            new Set(
                selectedValues.map((orderObject) => {
                    if (orderObject?.Orders?.length) {
                        return [...orderObject.Orders];
                    }
                    return orderObject?.OrderId;
                })
            )
        ).flat();

        const gridDataChangeEvent = createEventObject({
            name: "selectedOrders",
            value: selectedOrders ?? [],
        });

        onInputChange(gridDataChangeEvent as ChangeEvent<HTMLInputElement>);
        setLocalKendoGrid(kendoGrid);
    };

    /**
     * UseEffects
     */

    useEffect(() => {
        setModalConfig({ ...modalConfig, modalSubtitle: `Select Orders (${formValues.batchName})`, width: 1200 });
        getSmallParcelData();
        const ordersSelectionEvent = createEventObject({
            name: "selectedOrders",
            value: [],
        });
        onInputChange(ordersSelectionEvent as ChangeEvent<HTMLInputElement>);
    }, []);

    useEffect(() => {
        clearGridSelection();
    }, [formValues.display]);

    return (
        <>
            <div className="section-wrapper">
                <WmsDropdown
                    id="NewHVPDisplay"
                    name="display"
                    label={"Display"}
                    wmsSelector={selector + "display"}
                    value={formValues.display}
                    onChangeFunc={onInputChange}
                    options={displayBatchesOptions}
                    textField="description"
                    valueField="value"
                    disabled={formValues.warehouse === "0"}
                />
                <WmsDropdown
                    id="NewHVPCustomer"
                    name="status"
                    label={"Status"}
                    wmsSelector={selector + "status"}
                    value={"All"}
                    onChangeFunc={onInputChange}
                    options={[{ name: "All", id: "All", value: "All" }]}
                    textField="name"
                    valueField="id"
                    disabled={true}
                />
                <WmsDropdown
                    id="NewHVPWarehouse"
                    name="warehouse"
                    label={translate("Label_Warehouse_Text")}
                    wmsSelector={selector + "warehouse"}
                    value={formValues.warehouse}
                    onChangeFunc={onInputChange}
                    options={warehouseOptions}
                    textField="name"
                    valueField="id"
                    disabled={true}
                />
                <WmsDropdown
                    id="NewHVPCustomer"
                    name="customer"
                    label={translate("Label_Customer_Text")}
                    wmsSelector={selector + "customer"}
                    value={formValues.customer}
                    onChangeFunc={onInputChange}
                    options={customerOptions}
                    textField="name"
                    valueField="id"
                    disabled={true}
                />
            </div>

            <div className="new-layout-grid-filter-wrapper">
                <div className="small-parcel-hvp-grid new-layout-grid-wrapper">
                    <div className="wms-search-grid-data">
                        <VirtualGridComponent
                            id={"SmallParcelCreateHVPRemoteGrid"}
                            staticGridData={formValues.gridDataToDisplay ?? []}
                            gridKeySelection={"OrderId"}
                            gridName={"createHVPBatchesGrid"}
                            showResetButton={false}
                            showGridResult={true}
                            showGridSummary={true}
                            emptyRecoredText={translate("Window_Title_Label_Parcel_Ship_No_Recoreds")}
                            defaultColumns={defaultColumns}
                            allColumns={defaultColumns}
                            filterText={""}
                            virtual={true}
                            wmsSelector={selector + "HVPGrid"}
                            onSelection={handleRowSelection}
                            metaDataFieldsName={"Batches"}
                            menu={[]}
                            shawCheckbox={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
