export const getInitialPrintMenu = (initialPrintMenuResponse, params, menuClickEvent, callFrom) => {
    var updatedMenus = {
        PrintData: [],
    };

    if (
        initialPrintMenuResponse.length > 0 ||
        initialPrintMenuResponse != null ||
        typeof initialPrintMenuResponse != undefined
    ) {
        initialPrintMenuResponse.forEach((item, i) => {
            if (item.RdlName === "ViewTransaction") {
                // eslint-disable-next-line
                var fnName = params.fnNames[i];
                var tempObj = {};

                tempObj = {
                    "name": item.CategoryName,
                    "value": [],
                    "disabled": params.SelectedOrders < 1 || !params.Rights.hasOwnProperty("orders.docs"),
                    "disabledText": translate("order_Document_Permission_note"),
                    "isContextMenu": true,
                    "isButtonMenu": true,
                    "wmsSelector": params.Selector + item.RdlName,
                    "onClickFunc": menuClickEvent,
                    "wmsid": fnName,
                    "rdlName": item.RdlName,
                };

                if (item.hasOwnProperty("Reports") && item.RdlName !== "ShippingLabel") {
                    item.Reports.forEach((report, i) => {
                        tempObj.onClickFunc = () => null;
                        tempObj.value.push({
                            "name": report.Name,
                            "value": [],
                            "disabled": tempObj.disabled || !params.Rights.hasOwnProperty("orders.docs"),
                            "disabledText": translate("order_Document_Permission_note"),
                            "isContextMenu": true,
                            "isButtonMenu": true,
                            "wmsSelector": params.Selector + fnName + (i + 1),
                            "onClickFunc": menuClickEvent,
                            "wmsid": fnName,
                            "runReport": report.TemplatedRunner,
                            "rdlName": item.RdlName,
                        });
                    });
                }

                updatedMenus.PrintData.push(tempObj);
            }
        });
    }

    return updatedMenus;
};
