import React from 'react';

//component
import WmsModel from '../../../../components/WmsModel/WmsModel.jsx';
import WmsCustomDateTimePicker from '../../../../components/WmsCustomDateTimePicker/WmsCustomDateTimePicker.jsx';
import WmsDropdown from '../../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from '../../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsButton from '../../../../components/WmsButton/WmsButton.jsx';
import WmsRadio from '../../../../components/WmsRadio/WmsRadio.tsx';
import WmsGrid from '../../../../components/WmsGrid/WmsGrid.jsx';
import { AddEditColumnModel } from '../../../../components/AddEditColumn/add-edit-columns.component.jsx';
import VirtualGridComponent from '../../../../components_proto/VirtualGridComponent/VirtualGridComponent.jsx';
import { StringWms } from '../../../../../../assets/ts/StringWms.ts';
import * as findOrdersService from '../../findOrders.services.jsx';
import * as GlobalService from '../../../../global_services';
import style from './processed-batched-shipping-labels.scss';
import { ArrayWms } from '../../../../../../assets/ts/ArrayWms.ts';
export class ProcessedBatchedShippingLabels extends React.Component {

    constructor(props) {

        super(props);
        this.translate = window.translate;
        this.rights = GlobalService.GetRights();

        this.state = {
            selector: "processedBatched",
            printDocumentsSaveGif:false,
            printLabelsSaveGif:false,
            isPrintLabelsDisable:true,
            isPrintDocumetnsDisable:true,
            currentGridObj:{},
            appliedFilter: null,
            isSawErrorSection:false,
            isFilterAppliedInGrid:false,
            loadingPutSmalParcelBatch: false,
            isOpenColumnModel: false,
            numberOfSelection:0,
            selectedPrintLabel:'pdf',
            isExpand: 'expanded',
            staticGridData:[],
            errorStaticGridData:[],
            filter:{
                selectedCustomer:'All Customers',
                selectedFacility:'All Warehouses',
                status:'',
                startDate:'',
                endDate:'',
            },
            optionsCustomer: [],
            optionsFacility: [],
            optionsStatus:[
                { name:'Any', value:'' },
                { name:'Complete', value:'Complete' },
                { name:'Processing', value:'Processing' },
                { name:'Open', value:'Open' }
            ],
            columns:[{
                    field: "BatchId",
                    title: "ShipEngine ID",
                    width: "140px"
                },{
                    field: "ExternalBatchId",
                    title: "Batch Name",
                    width: "125px"
                },{
                    field: "Customer",
                    title: "Customer",
                    width: "120px"
                }, {
                    field: "Facility",
                    title: "Warehouse",
                    width: "130px"
                },
                {
                    field: "CreatedAt",
                    title: "Date",
                    width: "135px",
                    template: "#= (typeof CreatedAt != 'undefined' && CreatedAt != null) ?  kendo.toString(kendo.parseDate(CreatedAt), 'M/dd/yyyy hh:mm tt') : '' #"
                },
                {
                    field: "Count",
                    title: "Totals",
                    headerTemplate: 'Totals ( <i class="columnd-header-icon fa fa-check-circle " ></i> / <i class="columnd-header-icon fa fa-exclamation-triangle"></i> )',
                    width: "135px",
                    template: "#= kendo.toString(Count,'n0') # (#= kendo.toString(Completed,'n0') #/#= Errors #)"
                },
                {
                    field: "Status",
                    title: "Status",
                    width: "110px",
                    template: '#if(Status == "open") {# #= "Open" # #} else if(Status == "queued" || Status == "processing") {#  #= "Processing" # #} else if(Status == "completed") {#  #= "Complete" # #} else if(Status == "completed_with_errors" && Errors > 0) {# <div class="error-icon-status" Batch-Name="#=BatchId#" batch-errors-url="#=BatchErrorsUrl#" batch-shipments-url="#=BatchShipmentsUrl#" > #= "Complete" #  <i class="facility-exclamator fa fa-exclamation-triangle " ></i> </div> #} else if(Status == "completed_with_errors") {#  #= "Complete" # #} else{# #=Status # #}#'
            }],
            schema:{
                model: {
                    fields: {
                        BatchId: { type: 'string' },
                        Customer: { type: "string" },
                        Facility: { type: "string" },
                        ExternalBatchId: { type: "string" },
                        BatchNotes: { type: "string" },
                        CreatedAt: { type: "datetime" },
                        Count: { type: "number" },
                        Status: { type: "string" }
                    }
                }
            },
            tmpKendogrid:null
        }
    }
    componentDidMount() {
        var tmpCustOpt = GlobalService.getCustomerList(false);
        tmpCustOpt.unshift({
            "id": 0,
            "name": "All Customers",
        });
        var tmpFacOpt = GlobalService.getFacilityList(false);
        tmpFacOpt.unshift({
            "id": 0,
            "name": "All Warehouses",
        });
        this.setState({
            optionsCustomer: tmpCustOpt,
            optionsFacility: tmpFacOpt,
        })
        var that = this;
        $(document).off("click", ".error-icon-status");
        $(document).on("click", ".error-icon-status", errorDetailShaw);
        function errorDetailShaw(){
            if( $(this).attr('batch-name')){
                var dialog = $("#PrintBatchedShippingModel").data("kendoWindow");
                dialog.title('Errors for "' + $(this).attr('batch-name') + '"');
                var grid = $("#processedBatchedShippingGrid").data("kendoGrid");
                var tmpAppliedFilter = null
                if (typeof grid != "undefined") {
                    tmpAppliedFilter = grid.dataSource.filter();
                }
                if(that.state.tmpKendogrid) {
                    that.state.tmpKendogrid.clearSelections(function () {
                        that.state.tmpKendogrid.repaintSelections();
                    });
                }
                that.setState({
                    isSawErrorSection: true,
                    appliedFilter:tmpAppliedFilter
                });
                that.getSmallParcelBatchErrors($(this).attr('batch-errors-url'), $(this).attr('batch-shipments-url'));
            }
        }
        this.getSmallParcelBatchesData();
        setTimeout(() => {
            var eventStartDate = {
                target : {
                    name:'startDate',
                    value:'today~00:00:01'
                }
            }
            this.handleFilterChange(eventStartDate);
            var eventEndDate = {
                target : {
                    name:'endDate',
                    value:'today~23:59:01'
                }
            }
            this.handleFilterChange(eventEndDate);
            let grid = $('#processedBatchedShippingGrid').data('kendoGrid');
            if(grid){
                grid.dataSource.sort({field: "CreatedAt", dir: "desc"});
            }
        }, 2000);
    }
    getSmallParcelBatchesData = () => {
        kendo.ui.progress($('#PrintBatchedShippingModel'), true);
        findOrdersService.getSmallParcelBatches().then((response) => {
            if(response && response.status == 200){
                var data = response.data.ParcelBatches;
                _.remove(data, function(element) {
                    return (element.Status == 'archived' || element.Status == 'invalid');
                });
                this.setState({ staticGridData:data });
            }
        }).finally(() => {
            kendo.ui.progress($('#PrintBatchedShippingModel'), false);
        });
    }
    onSelectRow = (kendogrid) =>{
        var tmpPrintLabelsDisable = true;
        var tmpPrintDocumetnsDisable = true;
        var tmpCurrentGridObj = {};
        if (kendogrid.getKeys().length == 1) {
            tmpCurrentGridObj = kendogrid.getItem(kendogrid.getKeys());
            if(tmpCurrentGridObj.Completed > 0 ){
                tmpPrintLabelsDisable = false;
            }
            if(tmpCurrentGridObj.Forms > 0 && (tmpCurrentGridObj.Status == "completed_with_errors" || tmpCurrentGridObj.Status == "completed") ){
                tmpPrintDocumetnsDisable = false;
            }
        }

        this.setState({
            numberOfSelection: kendogrid.getNumberOfSelections(),
            isPrintLabelsDisable:tmpPrintLabelsDisable,
            isPrintDocumetnsDisable:tmpPrintDocumetnsDisable,
            currentGridObj:tmpCurrentGridObj,
            tmpKendogrid:kendogrid
        });

    }
    radioBtnChange = (param_type) => (e) => {
        this.setState({ selectedPrintLabel : param_type });
    }
    hideErrorSection = () => {
        kendo.ui.progress($('#PrintBatchedShippingModel'), true);
        this.setState({  isSawErrorSection: false,isFilterAppliedInGrid: false,isErrorDetailOpened:false });
        var dialog = $("#PrintBatchedShippingModel").data("kendoWindow");
        dialog.title(this.translate('Label_High_Volume_Batches'));
        setTimeout(() => {
            kendo.ui.progress($('#PrintBatchedShippingModel'), false);
            var grid = $('#processedBatchedShippingGrid').data('kendoGrid');
            if (typeof grid != 'undefined') {
                grid.dataSource.data(this.state.staticGridData);
                if (this.state.appliedFilter) {
                    grid.dataSource.filter(this.state.appliedFilter);
                }
                grid.refresh();
            }
        }, 900);
    }
    clickPrintLabel = () => {
        if (this.rights['Integrations.SmallParcelPartner'] == 'ParcelApi') {
            if (this.state.currentGridObj.LabelFormat == "Pdf" || this.state.currentGridObj.LabelFormat == "Png") {
                var a = $("<a>")
                .attr("href", this.state.currentGridObj.LabelDownloadPdf)
                .attr("target", "_blank")
                .appendTo("body");
                a[0].click();
                a.remove();
                /* this.setState({ printLabelsSaveGif: true });
                findOrdersService.getSmallParcelBatchPdfContents(this.state.currentGridObj.BatchLabelsUrl).then((response) => {
                    if(response && response.status == 200) {
                        GlobalService.downloadFileBaseOnContentType(response.data, 'application/pdf', this.state.currentGridObj.BatchId + this.state.currentGridObj.ExternalBatchId, false, 'pdfViewer');
                    }
                }).finally(() => {
                    this.setState({ printLabelsSaveGif:false });
                }); */
            } else {
                this.setState({ printLabelsSaveGif: true });
                findOrdersService.getSmallParcelBatchZplContents(this.state.currentGridObj.LabelDownloadZpl).then((response) => {
                    if(response && response.status == 200){
                        GlobalService.printDataInZplPrinter(response.data);
                    }
                }).finally(() => {
                    this.setState({ printLabelsSaveGif:false });
                });
            }
        } else {
            if(this.state.selectedPrintLabel == 'pdf') {
                var a = $("<a>")
                .attr("href", this.state.currentGridObj.LabelDownloadPdf)
                .attr("target", "_blank")
                .appendTo("body");
                a[0].click();
                a.remove();
            } else {
                this.setState({ printLabelsSaveGif:true });
                findOrdersService.getSmallParcelBatchZplContents(this.state.currentGridObj.LabelDownloadZpl).then((response) => {
                    if(response && response.status == 200) {
                        GlobalService.printDataInZplPrinter(response.data);
                    }
                }).finally(() => {
                    this.setState({ printLabelsSaveGif:false });
                });
            }
        }
    }
    clickPrintDocument = () => {
          var a = $("<a>")
          .attr("href", this.state.currentGridObj.FormDownload)
          .attr("target", "_blank")
          .appendTo("body");
          a[0].click();
          a.remove();
    }
    putSmallParcelBatchLabels = () => {
        this.setState({ loadingPutSmalParcelBatch : true });
        var param = '?batchlabelsuri=' + this.state.currentGridObj.BatchLabelsUrl +
        ( this.state.currentGridObj.Errors ? '&batcherrorsuri='+ this.state.currentGridObj.BatchErrorsUrl + '&batchshipmentsuri=' + this.state.currentGridObj.BatchShipmentsUrl: '' );

        findOrdersService.putSmallParcelBatchLabels(param).then((response) => {
            GlobalService.notification('success', this.translate('High_Volume_Processing_Update'));
        }).finally(() => {
            this.setState({ loadingPutSmalParcelBatch : false });
        });
    }
    getSmallParcelBatchErrors = (batchErrorsUrl, batchShipmentsUrl) => {
        kendo.ui.progress($('#PrintBatchedShippingModel'), true);
        findOrdersService.getSmallParcelBatchErrors(batchErrorsUrl, batchShipmentsUrl).then((response) => {
            if(response && response.status == 200){
                var data = response.data.ParcelBatchErrors;
                this.setState({ errorStaticGridData:data });
            }
        }).finally(() => {
            kendo.ui.progress($('#PrintBatchedShippingModel'), false);
        });
    }
    handleFilterChange = (event) => {

        let propFilter = this.state.filter;
        let objFilter = Object.assign(
            {},
            propFilter,
            { [event.target.name]: event.target.value }
        )
        var dataArr = [];
        if(objFilter.selectedCustomer != "All Customers") {
            dataArr.push({
                field: "Customer",
                operator: "contains",
                value: objFilter.selectedCustomer
            });
        }
        if (objFilter.selectedFacility != "All Warehouses") {
            dataArr.push({
                field: "Facility",
                operator: "contains",
                value: objFilter.selectedFacility
            });
        }
        if(objFilter.status) {
            dataArr.push({
                field: "Status",
                operator: "contains",
                value: objFilter.status
            });
        }
        if(objFilter.startDate || objFilter.endDate) {
            if(objFilter.startDate){
                var tmpStartDate = StringWms.convertFilterStringToISODate(objFilter.startDate,'',false, true).isoStringUTC;
                dataArr.push({ field: "CreatedAt", operator: "gte", value: tmpStartDate });
            }
            if(objFilter.endDate){
                var tmpEndDate = StringWms.convertFilterStringToISODate(objFilter.endDate,'',false, true).isoStringUTC;
                dataArr.push({ field: "CreatedAt", operator: "lte", value: tmpEndDate });
            }
        }
        this.setState({ filter: objFilter, isFilterAppliedInGrid:true });
        var grid = $("#processedBatchedShippingGrid").data("kendoGrid");
        if(grid) {
            grid.dataSource.filter(dataArr);
        }
    }
    collapseExpand = (iscollapse) => {
        this.setState({
            isExpand : iscollapse ? 'callapse' : 'expanded'
        })
        setTimeout(() => {

            $("#processedBatchedShippingGrid").data("kendoGrid").refresh();
        }, 500);
    }
    eventClickExportGrid = () => {
        $("#processedBatchedShippingGrid").getKendoGrid().saveAsExcel();
    }
    exportErrorGrid = () => {
        $("#batchedErrorDetailGrid").getKendoGrid().saveAsExcel();
    }
    eventClickColumns = () => {
        this.setState({ isOpenColumnModel: true });
    }
    onCloseColumnModel = (e) => {
        this.setState({ isOpenColumnModel: false });
    }
    gridFilterApplied = (e) => {
        if (e == undefined || !e) {
            this.setState({
                filter: {
                    selectedCustomer: 'All Customers',
                    selectedFacility: 'All Warehouses',
                    status: '',
                    startDate: '',
                    endDate: '',
                }
            });
        }
    }
    render() {
        return (
            <WmsModel
                id={'PrintBatchedShippingModel'}
                title={this.translate('Label_High_Volume_Batches')}
                width={1000}
                height={400}
                class='print-batched-shipping-model-wrapper'
                onCloseFunc={this.props.closePrintBatchedShippingLabel}
                customClass='wms-model-new-ui-wrapper model-height-fixed'
                isOpen={this.props.isOpenPrintBatchedShippingModel}>
                { this.state.isSawErrorSection == false ? (
                    <div className="model-content-wrapper">
                         <WmsCollapse
                                id={'filterResults'}
                                headerLabel={this.translate('Label_Filter_Results')}
                                isExpanded={true}
                                showCollapseText={false}
                                setCollapseMetaData={(e) => this.collapseExpand(e)}>
                                    <div className="display-inline">
                                        <WmsDropdown
                                            id="customer"
                                            name="selectedCustomer"
                                            label={this.translate('FindOrders_Lbl_Customer')}
                                            wmsSelector={this.state.selector + "customer"}
                                            value={this.state.filter.selectedCustomer}
                                            onChangeFunc={this.handleFilterChange}
                                            options={this.state.optionsCustomer}
                                            textField='name'
                                            valueField='name'/>
                                        <WmsDropdown
                                            id="facility"
                                            name="selectedFacility"
                                            label={this.translate('Label_Warehouse_Text')}
                                            wmsSelector={this.state.selector + "warehouse"}
                                            onChangeFunc={this.handleFilterChange}
                                            value={this.state.filter.selectedFacility}
                                            options={this.state.optionsFacility}
                                            textField='name'
                                            valueField='name'/>
                                        <WmsDropdown
                                            id="status"
                                            name="status"
                                            label={this.translate('Label_Filter_Status')}
                                            wmsSelector={this.state.selector + "status"}
                                            onChangeFunc={this.handleFilterChange}
                                            value={this.state.filter.status}
                                            options={this.state.optionsStatus}
                                            valueField='value'
                                            textField='name'/>
                                    </div>
                                    <div className="display-inline">
                                        <WmsCustomDateTimePicker
                                            id={'DateRangeStartDate'}
                                            name='startDate'
                                            wmsSelector={this.state.selector + "DateRangeStartDate"}
                                            label={this.translate('Label_Date_Range_Start')}
                                            onChangeFunc={this.handleFilterChange}
                                            value={this.state.filter.startDate}
                                            defaultOptionMonth={'All Time'}
                                            isDateParseInCustomFormat={true}
                                        />
                                        <WmsCustomDateTimePicker
                                            id={'DateRangeEndDate'}
                                            name='endDate'
                                            wmsSelector={this.state.selector + "DateRangeEndDate"}
                                            label={this.translate('Label_Date_Range_end')}
                                            onChangeFunc={this.handleFilterChange}
                                            value={this.state.filter.endDate}
                                            defaultOptionMonth={'All Time'}
                                            isDateParseInCustomFormat={true}
                                        />
                                    </div>
                        </WmsCollapse>
                        <div className={"custom-grid-wrapper " + this.state.isExpand}>
                        { this.rights['Integrations.SmallParcelPartner'] != 'ParcelApi' ?
                            <div className={"radio-btn-wrapper " + (this.state.numberOfSelection == 0 ? 'disable-radio-section' : '')}>
                                <WmsRadio
                                    label={this.translate("Label_parcel_type_pdf")}
                                    name="pdfShippingLabel"
                                    wmsSelector={this.state.selector + "pdfShippingLabel"}
                                    onChangeFunc={this.radioBtnChange('pdf')}
                                    value={true}
                                    checked={this.state.selectedPrintLabel == 'pdf'}
                                />
                                <WmsRadio
                                    label={this.translate("Label_parcel_type_zpl")}
                                    name="zplShippingLabel"
                                    wmsSelector={this.state.selector + "zplShippingLabel"}
                                    onChangeFunc={this.radioBtnChange('zpl')}
                                    value={true}
                                    checked={this.state.selectedPrintLabel == 'zpl'}
                                />
                            </div>
                         : '' }
                        <VirtualGridComponent
                            wmsSelector={this.state.selector + 'processedBatchedShippingGrid'}
                            gridKeySelection={'BatchId'}
                            staticGridData={this.state.staticGridData}
                            gridName={'processedBatchedShippingGrid'}
                            virtual={true}
                            filterText={''}
                            allColumns={this.state.columns}
                            defaultColumns={this.state.columns}
                            showGridResult={false}
                            showSortColumns={true}
                            shawCheckbox={false}
                            id={'processedBatchedShippingGrid'}
                            onSelection={this.onSelectRow}
                            schema={this.state.schema}
                            clickEventOfContextMenu={() => { }}
                            exportable={true}
                            eventClickColumns={this.eventClickColumns}
                            eventClickExportGrid={this.eventClickExportGrid}
                            menu={[{
                                "name": '',
                                "wmsSelector": this.state.selector + 'RefreshGrid',
                                "value": [],
                                "clickFunc": this.getSmallParcelBatchesData,
                                "disabled": false,
                                "icon": "fa-sync-alt",
                                    "color": "blue",
                                    'isContextMenu': false,
                                    'isButtonMenu': true,
                                    'parentClassName': 'processedBatchedRefreshBtn'
                                }]}
                                resetButtonLabel={this.translate('Label_Costomer_Options')}
                                resetButtonIcon={'fa-sliders'}
                                metaDataFieldsName={''}
                                onGridFilterApplied={this.gridFilterApplied}
                            />
                        </div>
                        {this.state.isOpenColumnModel && (
                            <AddEditColumnModel
                                windowId={'print-batched-shipping-model-wrapper'}
                                id={"addEditColumnProcessedBatchShippinh"}
                                height={700}
                                onCloseColumnModel={this.onCloseColumnModel}
                                isOpenColumnModel={this.state.isOpenColumnModel}
                                allSortColumnList={this.state.columns}
                                gridId={"processedBatchedShippingGrid"}
                                updateGridData={() => {}}
                                saveChangesInMetaData={() => {}}
                            />
                        )}
                    </div>
                ) : (
                    <div className="model-content-wrapper">
                        <WmsGrid
                            wmsSelector={this.state.selector + 'batchedErrorDetailGrid'}
                            gridKeySelection={'OrderId'}
                            staticGridData={this.state.errorStaticGridData}
                            gridName={'batchedErrorDetailGrid'}
                            virtual={false}
                            showResetButton={false}
                            shawCheckbox={false}
                            id={'batchedErrorDetailGrid'}
                            onSelection={()=>{ }}
                            columns={[{
                                field: "OrderId",
                                title: "Transaction ID",
                                width: "100%"
                            }, {
                                field: "Error",
                                title: "Error",
                                width: "100%"
                            }]}
                            schema={{
                                model: {
                                    fields: {
                                        OrderId: { type: "number" },
                                        Error: { type: "string" }
                                    }
                                }
                            }}
                            clickEventOfContextMenu={() => { }}
                            menu={[]} />
                    </div>
                )}
                { this.state.isSawErrorSection == false ? (
                    <div className="footer-btn-holder">
                        <WmsButton
                            id={this.state.selector + "cancel"}
                            name={this.state.selector + "cancel"}
                            label={this.translate("Label_Close")}
                            wmsSelector={this.state.selector + "cancel"}
                            onClickFunc={this.props.closePrintBatchedShippingLabel}
                            icon="fa-times-circle-o"
                            buttonType="orange"
                        />
                        <WmsButton
                            label={this.translate("RoleManager_Label_Update")}
                            wmsSelector={this.state.selector + "update"}
                            onClickFunc={this.putSmallParcelBatchLabels}
                            icon="fa-refresh"
                            loading={this.state.loadingPutSmalParcelBatch}
                            disabled={this.state.isPrintLabelsDisable}
                        />
                        <WmsButton
                            label={this.translate("Label_Print_Documents")}
                            wmsSelector={this.state.selector + "printDocuments"}
                            onClickFunc={this.clickPrintDocument}
                            icon="fa-print"
                            loading={this.state.printDocumentsSaveGif}
                            disabled={this.state.isPrintDocumetnsDisable}
                        />
                        <WmsButton
                            label={this.translate("Label_Print_Labels")}
                            wmsSelector={this.state.selector + "printLabels"}
                            onClickFunc={this.clickPrintLabel}
                            icon="fa-print"
                            loading={this.state.printLabelsSaveGif}
                            disabled={this.state.isPrintLabelsDisable}
                        />
                    </div>
                ) : (
                    <div className="footer-btn-holder">
                        <WmsButton
                            id={this.state.selector + "Barck"}
                            name={this.state.selector + "Barck"}
                            label={this.translate("RoleManager_Label_Back")}
                            wmsSelector={this.state.selector + "Barck"}
                            onClickFunc={this.hideErrorSection}
                            icon="fa-angle-left"
                            buttonType="orange"
                        />
                        <WmsButton
                            id={this.state.selector + "exportError"}
                            name={this.state.selector + "exportError"}
                            label={this.translate("Label_Export")}
                            wmsSelector={this.state.selector + "exportError"}
                            onClickFunc={this.exportErrorGrid}
                            icon="fa-download"
                            buttonType="green"
                        />
                        <WmsButton
                            id={this.state.selector + "close"}
                            name={this.state.selector + "close"}
                            label={this.translate("Label_Close")}
                            wmsSelector={this.state.selector + "close"}
                            onClickFunc={this.props.closePrintBatchedShippingLabel}
                            icon="fa-times-circle-o"
                            buttonType="orange"
                        />
                    </div>
                )}
            </WmsModel>
        )
    }
}