import React from 'react';

//component
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse.jsx';
import WmsModel from './../components/WmsModel/WmsModel.jsx';
import WmsFile from './../components/WmsFile/WmsFile.jsx';

import style from './items-update-modal.scss'
import * as GlobalService from './../global_services';
import * as itemService from './items.services.jsx';

export default class ItemsUpdateModal extends React.Component {

    constructor(props) {

        super(props);
        this.translate = window.translate;
        this.isImperial = GlobalService.getMeasurementStandardFlag(this.props.sharedSelectedCustomerObj.defaultFacility ? this.props.sharedSelectedCustomerObj.defaultFacility : 0)
        this.isValidatingCallContinue = false;
        this.isUpdateItemCallContinue = false;
        this.selectedImportFile = '';
        this.state = {
            selector: "UpdateItems",
            selectedFileName: "No File Selected",
            updatingGif: false,
            downloadingSkusList: false,
            validatingFileGif: false,
            updatedBatchItemData: [],
            batchItemsFileDiff: [],
            totalItemsUpload: 0,
            validationIssueType: "",
            isOpenItemUpdateValidationModal: false
        };
    }

    componentDidMount() {
        //
    }

    handleImportFileChangeAsync = (param_fileobj) => {

        this.selectedImportFile = param_fileobj;
        let fileExtension = param_fileobj.fileName.split('.').pop();
        this.setState({
            validatingFileGif: true,
            selectedFileName: param_fileobj.fileName
        });
        var sizeInMB = (param_fileobj.fileSize / (1024 * 1024)).toFixed(4);
        if (sizeInMB > 8.0000) {
            this.setState({
                isOpenItemUpdateValidationModal: true,
                validatingFileGif: false,
                validationIssueType: "FileSize"
            })

        } else if (fileExtension != 'txt') {
            this.setState({
                isOpenItemUpdateValidationModal: true,
                validatingFileGif: false,
                validationIssueType: "FileType"
            })
        } else {
            const formData = new FormData();
            formData.append('custId', this.props.sharedSelectedCustomerObj.id);
            formData.append('content', param_fileobj.fileread);
            formData.append('isimperial', this.isImperial);
            let isApiRes = false;
            //TODO: recall validate service if 'StatusRelativeUri' not found
            itemService.ValidateUpdateBatchItemStarter(formData).then((response) => {
                isApiRes = true;
                this.isValidatingCallContinue = true;
                this.validateUpdateBatchItemAsync(response.data.StatusRelativeUri);
            }).finally(() => {
                if (this.isValidatingCallContinue == false || isApiRes == false) {
                    this.setState({ validatingFileGif: false });
                }
            });
        }
    }

    validateUpdateBatchItemAsync = (uri) => {
        let isApiRes = false;
        itemService.callServiceOnUri(uri).then((response) => {
            // console.log("validateUpdateBatchItemAsync response : ", response.data);
            isApiRes = true;
            if (response.data.Ready == true) {
                let newItems = response.data.Lists[0].ResourceList;
                let totalItemsUpload = response.data.Lists[0].TotalResults;
                let oldItems = response.data.Lists[1].ResourceList;
                let diffOfOldAndNewItems = [];

                _.map(oldItems, (oldItemObj, oldItemIndex) => {
                    let newItemObj = newItems[oldItemIndex];
                    let changesOfItems = this.getUpdatedItemsProperty(oldItemObj, newItemObj, {});

                    if (!_.isEmpty(changesOfItems)) {
                        diffOfOldAndNewItems.push(Object.assign({ 'Sku': oldItemObj.Sku }, changesOfItems));
                    }
                });

                this.setState({
                    updatedBatchItemData: newItems,
                    batchItemsFileDiff: diffOfOldAndNewItems,
                    totalItemsUpload: totalItemsUpload
                });
                this.isValidatingCallContinue = false;
            } else {
                this.isValidatingCallContinue = true;
                setTimeout(() => {
                    this.validateUpdateBatchItemAsync(uri);
                }, 2500);
            }
        }).finally(() => {
            if (this.isValidatingCallContinue == false || isApiRes == false) {
                this.setState({ validatingFileGif: false });
            }
            if (this.isValidatingCallContinue) {
                this.setState({
                    batchItemsFileDiff: [],
                    totalItemsUpload: 0
                });
            }
        });
    }

    getUpdatedItemsProperty = (oData, nData, result) => {
        let that = this;
        Object.keys(oData).forEach(function (k) {
            if (oData[k] && typeof oData[k] == 'object') {
                if (oData[k].length != nData[k].length) {
                    that.addUndefinedKeys(oData[k], nData[k], this);
                } else {
                    that.getUpdatedItemsProperty(oData[k], nData[k], this);
                }
            } else {
                let oldValue = oData[k] || oData[k] === 0 || oData[k] === null ? oData[k] : '';
                let newValue = nData[k] || nData[k] === 0 || nData[k] === null ? nData[k] : '';
                if (k == "InventoryCategory") {
                    oldValue = oldValue.trim();
                    newValue = newValue.trim();
                }
                if (oldValue != newValue && k != 'RenameSku') {
                    this[k] = '"' + oldValue + '"<b>&nbsp;&nbsp;>&nbsp;&nbsp;</b>"' + newValue + '"';
                }
            }
        }, result);

        return result;
    }
    addUndefinedKeys = function (oldData, newData, result) {
        var srcArray = oldData.length ? oldData : newData;
        var updatedObjectIn = oldData.length == 0 ? 'oldData': 'newData';
        for (let i = 0; i < srcArray.length; i++) {
            const element = srcArray[i];
            Object.keys(element).forEach(function (k) {
                const oldValue = (updatedObjectIn == 'oldData') ? '' : srcArray[i][k];
                const newValue = (updatedObjectIn == 'oldData') ? srcArray[i][k] : '';
                result[k] = '"' + oldValue + '"<b>&nbsp;&nbsp;>&nbsp;&nbsp;</b>"' + newValue + '"';
            });
        }
    }
    confirmBatchItemUpdateAsync = () => {
        this.setState({ updatingGif: true });
        // let params = {
        //     "custId": this.props.sharedSelectedCustomerObj.id,
        //     "content": this.selectedImportFile.fileread,
        //     "isimperial": this.isImperial
        // };
        const formData = new FormData();
        formData.append('custId', this.props.sharedSelectedCustomerObj.id);
        formData.append('content', this.selectedImportFile.fileread);
        formData.append('isimperial', this.isImperial);
        //TODO: recall update batch service if 'StatusRelativeUri' not found
        let isApiRes = false;
        itemService.UpdateBatchItemStarter(formData).then((response) => {
            this.isUpdateItemCallContinue = true;
            isApiRes = true;
            this.updateBatchItemAsync(response.data.StatusRelativeUri);
        }).finally(() => {
            if (this.isUpdateItemCallContinue == false || isApiRes == false) {
                this.setState({ updatingGif: false });
            }
        });
    }
    updateBatchItemAsync = (uri) => {
        let isApiRes = false;
        itemService.callServiceOnUri(uri).then((response) => {
            console.log("loading of process response.data : ", response.data);
            isApiRes = true;
            if (response.data.Ready == true) {
                GlobalService.notification('success', this.translate('Label_Export_Item_Update').replace('{item}', (this.state.updatedBatchItemData.length > 1 ? 'Items' : 'Item')));
                this.props.onUpdateBatchItem();
                this.isUpdateItemCallContinue = false;
            } else {
                this.isUpdateItemCallContinue = true;
                setTimeout(() => {
                    this.updateBatchItemAsync(uri);
                }, 2500);
            }
        }).finally(() => {
            if (this.isUpdateItemCallContinue == false || isApiRes == false) {
                this.setState({ updatingGif: false });
            }
        });
    }

    downloadSkusList = () => {
        let params = {
            "custId": this.props.sharedSelectedCustomerObj.id,
            "inactiveItems": true,
            "isimperial": this.isImperial
        }
        let fileName = "itemsGridExport";

        this.setState({ downloadingSkusList: true });

        itemService.exportAllBatchItems(params).then((response) => {
            if (typeof response.data != 'undefined' && response.status == 200) {
                GlobalService.downloadFileBaseOnContentType(response.data, 'application/txt', fileName);
            }
        }).finally(() => {
            this.setState({ downloadingSkusList: false });
        });
    }

    closeUpdateValidationItemsModal = () => {
        this.setState({
            isOpenItemUpdateValidationModal: false
        })
    }

    render() {
        return (
            <div>
                <WmsModel
                    id={this.props.id}
                    title={this.translate('Label_Update_Items')}
                    width={800}
                    height={424}
                    onCloseFunc={this.props.onCloseItemUpdateModal}
                    isOpen={this.props.isOpenItemUpdateModal}
                    customClass={'wms-model-new-ui-wrapper'}
                >
                    <div className='model-content-wrapper'>
                        <WmsCollapse
                            id="itemsUpdateOptions"
                            headerLabel={this.translate('Label_Update_Options')}
                            showCollapseText={false}
                            showCollapseIcon={!this.state.updatedBatchItemData.length}
                        >
                            <div className="file-import-section">
                                <WmsFile
                                    id="import-file-csv"
                                    name="import-file-csv"
                                    label={this.translate('Label_Select_Import_File_Txt')}
                                    wmsSelector={this.state.selector + 'ImportFileCsv'}
                                    value={this.state.selectedFileName}
                                    onChangeFunc={this.handleImportFileChangeAsync}
                                    clearInputOnClick={true}
                                    loading={this.state.validatingFileGif}
                                />

                                <WmsButton
                                    label={this.translate('Label_Download_Current_SKU_List')}
                                    wmsSelector={this.state.selector + 'DownloadCurrentSKUList'}
                                    onClickFunc={this.downloadSkusList}
                                    icon="fa-file-invoice"
                                    loading={this.state.downloadingSkusList}
                                />
                            </div>
                            <hr />
                            <div className="selected-file-section">
                                <label htmlFor="">{this.translate('Label_Selected_File')}:</label>
                                <span>{this.state.selectedFileName}</span>
                            </div>
                        </WmsCollapse>

                        {(this.state.updatedBatchItemData.length > 0) && (
                            <WmsCollapse
                                id="expectedDataChanges"
                                headerLabel={this.translate('Label_Expected_Data_Changes')}
                                showCollapseText={false}
                            >
                                {!this.state.batchItemsFileDiff.length ? (
                                    <div className="no_change_section">
                                        {this.state.totalItemsUpload > 10 ? (
                                            <span>{this.translate('Item_Update_First_Ten_Item_No_Changes')}</span>
                                        ) : (
                                            <span>{this.translate('Tooltip_No_changes')}</span>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                            {this.state.batchItemsFileDiff.map((itemObj, key) => (
                                                <table key={key} className="changed_item">
                                                    <tbody>
                                                        {_.map(itemObj, (changedValue, field) => (
                                                            <tr key={field} className="item_field">
                                                                <td>{field}</td>
                                                                <td><span dangerouslySetInnerHTML={{ __html: changedValue }} /></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ))}
                                        </div>
                                    )}
                            </WmsCollapse>
                        )}
                    </div>

                    <div className="footer-btn-holder">
                        <WmsButton
                            label={this.translate('Label_Cancel')}
                            wmsSelector={this.state.selector + 'Cancel'}
                            onClickFunc={this.props.onCloseItemUpdateModal}
                            buttonType='orange'
                            icon="fa-ban"
                        />

                        <WmsButton
                            label={this.translate('Label_Select_Confirm')}
                            wmsSelector={this.state.selector + 'Confirm'}
                            onClickFunc={this.confirmBatchItemUpdateAsync}
                            icon="fa-check-circle"
                            loading={this.state.updatingGif}
                            disabled={this.state.batchItemsFileDiff.length || this.state.totalItemsUpload > 10 ? false : true}
                        />
                    </div>

                </WmsModel>
                {this.state.isOpenItemUpdateValidationModal && (
                    <WmsModel
                        id={'updateValidationModal'}
                        title={
                            (this.state.validationIssueType == "FileSize") ?
                                this.translate('Label_large_file_import_title') :
                                this.translate('Label_Invalid_FileType')
                        }
                        width={800}
                        height={220}
                        onCloseFunc={this.closeUpdateValidationItemsModal}
                        isOpen={this.state.isOpenItemUpdateValidationModal}
                        customClass={'wms-model-new-ui-wrapper'} >
                        <div className='model-content-wrapper'>
                            <p className="wms-message-center">{
                                (this.state.validationIssueType == "FileSize") ?
                                    this.translate('Label_large_file_item_update_message') :
                                    this.translate('Label_Invalid_FileType_Message')
                            }</p>
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton
                                label={this.translate('Label_Close')}
                                wmsSelector={this.state.selector + 'Close'}
                                onClickFunc={this.closeUpdateValidationItemsModal}
                                disabled={false}
                                loading={false}
                                buttonType='orange'
                                icon={'fa-ban'}
                            />
                        </div>
                    </WmsModel>
                )}
            </div>
        );
    }
}