import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsCollapse.scss';

class WmsCollapse extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isExpanded: props.isExpanded,
            windowId: props.windowId != "" ? "." + props.windowId + " " : ""
        }
    }
    componentDidMount() {
        if (this.props.isShowTooltip) {
            setTimeout(() => {
                $("#" + this.props.id + "_main").kendoTooltip({
                    width: 240,
                    position: "bottom",
                    content: (e) => {
                        return '<p class="model-tooltip-title-p">' + this.props.tooltipText + '</p>';
                    },
                    animation: {
                        open: {
                            effects: "zoom",
                            duration: 150
                        }
                    }
                }).data("kendoTooltip");
            }, 100);
        }
        if (this.props.isShawHeaderInfoIcon) {
            setTimeout(() => {
                $("#" + this.props.id + "_headerinfo").kendoTooltip({
                    width: 240,
                    showAfter: 500,
                    position: "bottom",
                    content: (e) => {
                        return '<p class="model-tooltip-title-p">' + this.props.headerInfoTooltipText + '</p>';
                    },
                    animation: {
                        open: {
                            effects: "zoom",
                            duration: 150
                        }
                    }
                }).data("kendoTooltip");
            }, 100);
        }
    }
    onClickEvent(e) {
        if (this.props.showCollapseText == false && this.props.showCollapseIcon == false) {
            return;
        }
        var id = this.props.id;
        // var subheader = $("#" + id + " ." + style.text_update);
        // Getting the next element
        var content = $(this.state.windowId + "#" + id).next();
        content.slideToggle(500, () => {
            this.setState({
                isExpanded: !this.state.isExpanded
            });
        });
        this.props.setCollapseMetaData(this.state.isExpanded);
    }

    render() {
        return (
            <div className={"wms-collapsible-div " + (this.props.isDisabled ? "wms-collapse-disabled" : "")} id={this.props.id + '_main'} ref={this.props.divRefs}>
                <div className="wms_collapsible_header" id={this.props.id} onClick={this.onClickEvent.bind(this)}>
                    <div className='title-info'>
                        <label className="wms-title">{this.props.headerLabel}</label>
                        {this.props.isShawHeaderInfoIcon && (
                            <span className="fa fa-info-circle" id={this.props.id + '_headerinfo'} aria-hidden="true"></span>
                        )}
                    </div>
                    {this.props.showDragIcon && (
                        <span className="wms-sort"><i className="fas fa-arrows-alt-v"></i></span>
                    )}
                    {!this.props.showCollapseText && this.props.showCollapseIcon &&
                        <label className={'icon_label '}>{this.state.isExpanded ? <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>} </label>
                    }
                    {this.props.showCollapseText &&
                        <label className={'icon_label label-text-padding '}>{this.state.isExpanded ? '- Collapse' : '+ Expand'} </label>
                    }
                </div>
                <div className="wms-collapsible-content" style={{ display: this.state.isExpanded ? 'block' : 'none' }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

WmsCollapse.propTypes = {
    id: PropTypes.string.isRequired, // this should write the id to the input
    windowId: PropTypes.string, // this should window id
    headerLabel: PropTypes.string.isRequired, // this should be the label that default shows whether it is collapse or expand,
    showDragIcon: PropTypes.bool, // set true to saw drag icon on header left side by default is true
    showCollapseIcon: PropTypes.bool,
    isExpanded: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isShawHeaderInfoIcon: PropTypes.bool,
    ref: PropTypes.any
}
WmsCollapse.defaultProps = {
    isExpanded: true,
    isDisabled: false,
    showCollapseText: true,
    showDragIcon: false,
    showCollapseIcon: true,
    isShowTooltip: false,
    tooltipText: '',
    isShawHeaderInfoIcon: false,
    headerInfoTooltipText: '',
    ref: '',
    setCollapseMetaData: (e) => function () { },
    windowId: ""
}
export default WmsCollapse;