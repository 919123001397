import React from 'react';

//component
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsCustomDateTimePicker from './../components/WmsCustomDateTimePicker/WmsCustomDateTimePicker.jsx';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse.jsx';
import WmsModel from '../components/WmsModel/WmsModel.jsx';
import WmsContainer from '../components/WmsContainer/WmsContainer.jsx';
import WmsConfirmModel from '../components/WmsConfirmModel/WmsConfirmModel.jsx';

import style from './inventory.scss';

//services
import * as GridConfig from './grid_configuration.jsx';
import * as inventoryservices from './inventory.services.jsx';
import * as GlobalService from './../global_services';
import * as metaDataService from './meta-data.service.js';
import { kendo } from "./../global_variable_service.jsx";

export class InventorySearch extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        this.$rootScope = window;
        this.manageInventoryGridId = "GridManageInventoryBeta";
        this.inventory_GridHeaders = StringWms.convertSringToOption(this.translate('Inventory_Beta_GrideHeaders'));

        this.state = {
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.manageInventoryGridId : '#' + this.manageInventoryGridId,
            CustomLabels: GlobalService.getCustomLabels(),
            Selector: 'Inventory',
            optionConatins: StringWms.convertSringToOption(this.translate('Options_SearchBy_StringOptions')),
            booleanOption: StringWms.convertSringToOption(this.translate('Options_Inventory_Status')),
            optionsSearchBy: StringWms.convertSringToOption(this.translate('Options_Inventory_Date')),
            filter: {},
            isExpandedCollapse: [],
            isOpenSaveFilterModel: false,
            newFilterTitle: "",
            filterNameExist: false,
            allColumnList: [],
            savedFilterList: [],
            savedFilterIndex: null,
            isOpenConfirmModel: false,
            optionsCustomers: GlobalService.getCustomerList(true),
            facilityOption: [],
            sectionToggled: false,
            showTooltip: false
        };

        this.onFilterDataSet = GlobalService.debounce(this.onFilterDataSet, 700);
    }

    componentDidMount() {
        inventoryservices.initialInventory().then((response) => {
            let sortedFacilityList = response.getCustomerFacilityList.sort(function (first, second) {

                var columnTitle1 = first.name.trim().toUpperCase(); // ignore upper and lowercase
                var columnTitle2 = second.name.trim().toUpperCase(); // ignore upper and lowercase

                if (columnTitle1 < columnTitle2) { return -1; }

                if (columnTitle1 > columnTitle2) { return 1; }
                // names must be equal
                return 0;
            });
            sortedFacilityList.unshift({
                "id": 0,
                "name": "Unspecified",
                "uri": "",
                "FacilitySimpleUri": "",
                "UpsAccountNumber": "",
                "FedExAccountNumber": ""
            });
            this.setState({
                facilityOption: sortedFacilityList
            });

        }).finally(() => {
            kendo.ui.progress($('#' + this.props.windowId), false);
        });
        //
        let facilityObj = {id:this.props.sharedInventoryFilterObj.FacilityId};
        let allColumnList = GridConfig.getAllColumnList(this.inventory_GridHeaders, facilityObj).columns;
        this.$rootScope.setLoaderTimer("Manage Inventory");
        this.setState({
            allColumnList: allColumnList,
            savedFilterList: metaDataService.getSaveManageInventoryFilterListMetaData(),
            isExpandedCollapse: (!$.isEmptyObject(metaDataService.getManageInventoryCollapseMetaData()) ? metaDataService.getManageInventoryCollapseMetaData() : []),
            filter: this.props.sharedInventoryFilterObj
        }, () => {
            this.props.onSelectFacility(facilityObj);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sharedInventoryFilterObj != null && (prevProps.sharedInventoryFilterObj != this.props.sharedInventoryFilterObj)) {
            this.setState({
                filter: this.props.sharedInventoryFilterObj
            });
        }

        var sortable = $('#inventory-filter-section-sortable');

        if(sortable.length && !sortable.hasClass('ui-sortable')){
            var sortData = metaDataService.getManageInventorySortSectionMetaData();
            // set saved sort data if any in meta data
            if (sortData) {
                var pos = JSON.parse(sortData);
                if (pos) {
                    $.each(pos, function (i, position) {
                        if(position !=""){
                            var $target = sortable.find('#' + position);
                            $target.appendTo(sortable); // or prependTo
                        }
                    });
                }
            }

            sortable.sortable({
                update: this.onSectionSort,
                cancel: "label.icon_label,.wms-collapsible-content",
                handle: ".wms-sort",
                distance: 5,
                start: function (e, ui) {
                    var height = ui.item.height();
                    if (height > 100) {
                        height = 100;
                    }
                    ui.placeholder.height(height);
                }
            });
        }
    }

    handleFaciltiySelectChange = (facilityId) => {
        let facilityIndex = ObjectWms.FindIndexByProperty(this.state.facilityOption, "id", parseInt(facilityId));
        let facilityObj = this.state.facilityOption[facilityIndex];
        let allColumnList = GridConfig.getAllColumnList(this.inventory_GridHeaders, facilityObj).columns;
        this.setState({ allColumnList: allColumnList });
    }

    filterHandleChange = (event) => {
        let filterData = Object.assign({}, this.state.filter);
        let filterKey = event.target.name;
        let filterVal = event.target.value;
        // if (filterKey == "StartDate" || filterKey == "EndDate") {
        //     filterVal = kendo.toString(kendo.parseDate(filterVal), "M/d/yyyy h:mm tt");
        // }

        if (filterVal) {
            filterData[filterKey] = filterVal;
        } else {
            delete filterData[filterKey];
        }
        if(filterKey == 'FacilityId'){
            this.handleFaciltiySelectChange(filterVal);
        }else if(filterKey == 'CustomerId'){
            filterData['FacilityId'] = 0;
            this.setFacilityOnCustomer(filterVal);
        }

        this.setState({
            filter: filterData,
        }, () => {
            this.onFilterDataSet();
        });
    }
    setFacilityOnCustomer = (customerId) => {
        let customerIndex = ObjectWms.FindIndexByProperty(this.state.optionsCustomers, "id", parseInt(customerId));
        let custFacility = this.state.optionsCustomers[customerIndex].facilities;
        let allFacilities = GlobalService.getFacilityList(false);
        let selFacilityOption = [];
        for (let i = 0; i < custFacility.length; i++) {
            let facilityIndex = ObjectWms.FindIndexByProperty(allFacilities, "id", parseInt(custFacility[i]));
            if(allFacilities[facilityIndex]) {
                selFacilityOption.push(allFacilities[facilityIndex]);
            }
        }
        selFacilityOption.unshift({
            "id": 0,
            "name": "Unspecified",
            "uri": "",
            "FacilitySimpleUri": "",
            "UpsAccountNumber": "",
            "FedExAccountNumber": ""
        });
        this.setState({ facilityOption: selFacilityOption });
    }
    onFilterDataSet = () => {
        let filterObj = Object.assign({}, this.state.filter);
        let isSearchOn = false;

        if (!$.isEmptyObject(filterObj)) {
            isSearchOn = true;
        }
        this.props.onInventoryFilterObjChange(filterObj,isSearchOn);
    }

    clearFilters = () => {
        // set default value of filter form
        let resetFilterObj = {
            IndexOptionsDate:0,
            CustomerId:0,
            IndexOptionsSku:0,
            IndexOptionsQualifier:0,
            IndexOptionsSerialNumber:0,
            IndexOptionsSupplier:0,
            IndexOptionsLotNumber:0,
            IndexOptionsLocation:0,
            IndexOptionsMuLabel:0,
            IndexOptionsStatus:0,
            CustomField:"",
            FacilityId:0
        };
        this.setState({
            filter: resetFilterObj
        });
        this.props.onInventoryFilterObjChange(resetFilterObj,false);
    }

    setCollapseMetaData = (isExpanded, collapseId) => {
        metaDataService.setManageInventoryCollapseMetaData(collapseId, isExpanded);
    }

    onSectionSort = () => {
        var sortArray = JSON.stringify($('#inventory-filter-section-sortable').sortable("toArray"));
        metaDataService.setManageInventorySortSectionMetaData(sortArray);
    }

    onOpenSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: true,
            newFilterTitle: "",
            filterNameExist: false
        });
    }
    exitSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: false
        });
    }
    onSaveFilter = (indexForUpdate = null) => {
        let filterObj = Object.assign({}, this.state.filter);
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var filterArray = grid.dataSource.filter() ? grid.dataSource.filter() : [];
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = GlobalService.prepareGridOptionsForStorage(gridColumn, sortArray, filterArray);

        if (indexForUpdate != null) {
            metaDataService.updateManageInventoryFilterMetaData(indexForUpdate, filterObj, finalSubmission);
            GlobalService.notification('success',this.translate('Order_Filter_Update_success'));
        } else {
            if (!this.state.filterNameExist) {
                metaDataService.saveManageInventoryFilterMetaData(this.state.newFilterTitle, filterObj, finalSubmission);
                this.exitSaveFilterModel();
            }
        }
    }

    handleFilterTitleChange = (e) => {
        let value = e.target.value;
        if (value.Description == '') {
            value = '';
        }

        var nameExists = false;
        // check dublicate title for case sensitive
        var exists = this.state.savedFilterList.findIndex(function (item) {
            return value.toLowerCase() === item.title.toLowerCase();
        });
        if (exists != -1) {
            nameExists = true;
        }
        this.setState({
            newFilterTitle: value,
            filterNameExist: nameExists
        });
    }

    onClearSaveFilter = (index) => {
        this.setState({
            isOpenConfirmModel: true,
            savedFilterIndex: index
        });
    }
    onYesRemoveSavedFilter = () => {
        metaDataService.clearSaveManageInventoryFilterListMetaData(this.state.savedFilterIndex);
        this.setState({
            isOpenConfirmModel: false,
            savedFilterList: metaDataService.getSaveManageInventoryFilterListMetaData()
        });
    }
    ClickExit = () => {
        this.setState({
            isOpenConfirmModel: false
        });
    }

    onUseSaveFilter = (index) => {
        var objFilter = Object.assign({}, this.state.savedFilterList[index]);
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var gridConfig = JSON.parse(objFilter.columnData, JSON.dateParser);
        var tempObject = "";

        if (gridConfig.columns) {
            var fieldArray = gridConfig.columns.map(el => el.field);
            var updateColumn = [];
            this.state.allColumnList.filter((column) => {
                if (fieldArray.indexOf(column.field) !== -1) {
                    updateColumn.push(column);
                }
            });
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, updateColumn, "field");
        } else {
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, this.props.defaultColumns, "field");
        }
        grid.setOptions({
            columns: tempObject
        });
        grid.dataSource.sort(gridConfig.sorts);
        grid.dataSource.filter(gridConfig.filters);

        this.setState({
            filter: objFilter.filterData
        }, () => {
            this.onFilterDataSet();
        });
        this.props.onApplySavedFilter(objFilter.filterData);
    }

    onClickSectionHandle = () => {
        this.setState({
            sectionToggled: true
        }, this.props.onClickForFilterToggle);
    };

    showFilterTooltip = () => {
        this.setState({ showTooltip: true });
    }
    hideFilterTooltip = () => {
        this.setState({ showTooltip: false });
    }

    render() {
        return (
            <div className={`manage-inventory-search-holder ${ this.state.sectionToggled ? " sectionToggled " : "" }`}>
                <div className={"filter"} onClick={ this.onClickSectionHandle } >
                    <i className="fa fa-caret-left" aria-hidden="true"></i>
                    <span>search filters</span>
                </div>
                <div className="inventory-filter">
                    <div className="filter-wrapper">
                        <div className="btn-section">
                            <WmsButton
                                    label={this.translate('Label_Refresh_Button')}
                                    wmsSelector={this.state.Selector + 'GridRefreshButton'}
                                    onClickFunc={this.props.onRefreshGrid}
                                    icon='fa-sync-alt' />
                            <WmsButton
                                label={this.translate('Label_Clear_Filters')}
                                buttonType='yellow'
                                wmsSelector={this.state.Selector + 'ClearSearchButton'}
                                onClickFunc={this.clearFilters}
                                icon='fa-minus' />
                            <WmsButton
                                label={this.translate('Label_Save_View')}
                                buttonType='black'
                                wmsSelector={this.state.Selector + 'SaveFilterButton'}
                                onClickFunc={this.onOpenSaveFilterModel}
                                icon='fa-plus'
                            />
                        </div>

                        <div className="title">
                            {this.props.sharedSearchOn == true &&
                                <div><b>{this.translate('Label_NOTE')}: </b><label >{this.translate('Label_Search_Applied_Filter')}</label></div>
                            }
                        </div>

                        <div className={this.props.sharedSearchOn == true ? "warehouse-form-boxes width-title " : "warehouse-form-boxes"}>
                            <div className="warehouse-are-content" id="inventory-filter-section-sortable">
                                {this.state.savedFilterList.length !== 0 && (
                                    <WmsCollapse id={'inventory_search_collapse0'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse0') ? !this.state.isExpandedCollapse['inventory_search_collapse0'] : true} showDragIcon={true} headerLabel={this.translate('Label_Saved_Filter_List')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'inventory_search_collapse0')}>
                                        <ul className="filter-list-block">
                                            {this.state.savedFilterList.map((data, index) => (
                                                <li className="filter-list" key={index}>
                                                    <a className="title-text" title={data.title}>{data.title}</a>
                                                    <a className="clear-save-filter" onClick={(e) => this.onClearSaveFilter(index)}><i className="far fa-times-circle" aria-hidden="true"></i></a>
                                                    <div className="wms-apply-btn">
                                                        <WmsButton
                                                            label={this.translate('RoleManager_Label_Update')}
                                                            buttonType='green'
                                                            wmsSelector={this.state.Selector + 'UpdateFilterButton'}
                                                            onClickFunc={(e) => this.onSaveFilter(index)}
                                                            icon='' />
                                                        <WmsButton
                                                            label={this.translate('Label_Apply')}
                                                            buttonType='green'
                                                            wmsSelector={this.state.Selector + 'ApplyFilterButton'}
                                                            onClickFunc={(e) => this.onUseSaveFilter(index)}
                                                            icon='' />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </WmsCollapse>
                                )}

                                <WmsCollapse id={'inventory_search_collapse1'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse1') ? !this.state.isExpandedCollapse['inventory_search_collapse1'] : true} showDragIcon={true} headerLabel={this.translate('Label_Inventory_Transaction_Search')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'inventory_search_collapse1')}>
                                    <WmsDropdown
                                        id="CustomerId"
                                        name="CustomerId"
                                        windowId={this.props.windowId}
                                        label={this.state.CustomLabels.Label_Customer}
                                        wmsSelector={this.state.Selector + "SelectCustomer"}
                                        value={this.state.filter.CustomerId}
                                        onChangeFunc={this.filterHandleChange}
                                        options={this.state.optionsCustomers}
                                        valueField={'id'}
                                        textField={'name'}
                                    />
                                    <WmsDropdown
                                        id="inventory_beta_selectedInvetoryFacilityId"
                                        name="FacilityId"
                                        windowId={this.props.windowId}
                                        label={this.state.CustomLabels.Label_Warehouse}
                                        wmsSelector={this.state.Selector + "SelectWarehouse"}
                                        value={this.state.filter.FacilityId}
                                        onChangeFunc={this.filterHandleChange}
                                        options={this.state.facilityOption}
                                        valueField='id'
                                        textField='name'
                                        parentClassName='form_group'
                                    />
                                    <WmsInput
                                        id='FirstTransaction'
                                        inputName='FirstTransaction'
                                        wmsSelector={this.state.Selector + 'FirstTransaction'}
                                        value={this.state.filter.FirstTransaction}
                                        onChangeFunc={this.filterHandleChange}
                                        label={this.translate('Label_First_Trans')} />
                                    <WmsInput
                                        id='LastTransaction'
                                        inputName='LastTransaction'
                                        wmsSelector={this.state.Selector + 'LastTransaction'}
                                        value={this.state.filter.LastTransaction}
                                        onChangeFunc={this.filterHandleChange}
                                        label={this.translate('Label_Last_Trans')} />
                                </WmsCollapse>

                                <WmsCollapse id={'inventory_search_collapse2'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse2') ? !this.state.isExpandedCollapse['inventory_search_collapse2'] : true} showDragIcon={true} headerLabel={this.translate('Label_Inventory_Item_Search')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'inventory_search_collapse2')}>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSkuOrUpc"
                                            name="IndexOptionsSkuOrUpc"
                                            windowId={this.props.windowId}
                                            label={this.translate('Label_SKU_UPC')}
                                            wmsSelector={this.state.Selector + "IndexOptionsSkuOrUpc"}
                                            value={this.state.filter.IndexOptionsSkuOrUpc}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='SkuOrUpc'
                                            inputName='SkuOrUpc'
                                            wmsSelector={this.state.Selector + 'SkuOrUpcInput'}
                                            value={this.state.filter.SkuOrUpc}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSkuDescOrSecondaryDesc"
                                            windowId={this.props.windowId}
                                            name="IndexOptionsSkuDescOrSecondaryDesc"
                                            label={this.translate('Label_SKU_Description')}
                                            wmsSelector={this.state.Selector + "IndexOptionsSkuDescOrSecondaryDesc"}
                                            value={this.state.filter.IndexOptionsSkuDescOrSecondaryDesc}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='SkuDescOrSecondaryDesc'
                                            inputName='SkuDescOrSecondaryDesc'
                                            wmsSelector={this.state.Selector + 'SkuDescOrSecondaryDesc'}
                                            value={this.state.filter.SkuDescOrSecondaryDesc}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsQualifier"
                                            name="IndexOptionsQualifier"
                                            windowId={this.props.windowId}
                                            label={this.translate('Label_Qualifier')}
                                            wmsSelector={this.state.Selector + "IndexOptionsQualifier"}
                                            value={this.state.filter.IndexOptionsQualifier}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Qualifier'
                                            inputName='Qualifier'
                                            wmsSelector={this.state.Selector + 'QualifierInput'}
                                            value={this.state.filter.Qualifier}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <WmsInput
                                        id='CustomField'
                                        inputName='CustomField'
                                        wmsSelector={this.state.Selector + 'CustomFieldInput'}
                                        value={this.state.filter.CustomField}
                                        onChangeFunc={this.filterHandleChange}
                                        label={this.translate('Label_CustomField')}/>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSupplier"
                                            name="IndexOptionsSupplier"
                                            windowId={this.props.windowId}
                                            label={this.translate('Label_Supplier')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsSupplier'}
                                            value={this.state.filter.IndexOptionsSupplier}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Supplier'
                                            inputName='Supplier'
                                            wmsSelector={this.state.Selector + 'Supplier'}
                                            value={this.state.filter.Supplier}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                </WmsCollapse>

                                <WmsCollapse id={'inventory_search_collapse3'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse3') ? !this.state.isExpandedCollapse['inventory_search_collapse3'] : true} showDragIcon={true} headerLabel={this.translate('Label_Inventory_TrackBys_Search')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'inventory_search_collapse3')}>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSerialNumber"
                                            name="IndexOptionsSerialNumber"
                                            windowId={this.props.windowId}
                                            label={this.translate('Label_Serial_Number')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsSerialNumber'}
                                            value={this.state.filter.IndexOptionsSerialNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='SerialNumber'
                                            inputName='SerialNumber'
                                            wmsSelector={this.state.Selector + 'SerialNumber'}
                                            value={this.state.filter.SerialNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsLotNumber"
                                            name="IndexOptionsLotNumber"
                                            windowId={this.props.windowId}
                                            label={this.state.CustomLabels.Label_Lot_Number}
                                            wmsSelector={this.state.Selector + "IndexOptionsLotNumber"}
                                            value={this.state.filter.IndexOptionsLotNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='LotNumber'
                                            inputName='LotNumber'
                                            wmsSelector={this.state.Selector + 'LotNumber'}
                                            value={this.state.filter.LotNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                </WmsCollapse>

                                <WmsCollapse id={'inventory_search_collapse4'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse4') ? !this.state.isExpandedCollapse['inventory_search_collapse4'] : true} showDragIcon={true} headerLabel={this.translate('Label_Inventory_Date_Search')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'inventory_search_collapse4')}>
                                    <WmsDropdown
                                        id="IndexOptionsDate"
                                        name="IndexOptionsDate"
                                        windowId={this.props.windowId}
                                        label={this.translate('Label_Filter_Status')}
                                        wmsSelector={this.state.Selector + "IndexOptionsDate"}
                                        value={this.state.filter.IndexOptionsDate}
                                        onChangeFunc={this.filterHandleChange}
                                        options={this.state.optionsSearchBy}
                                        textField='description'
                                        valueField='value'
                                    />

                                    <WmsCustomDateTimePicker
                                        id={'StartDate'}
                                        name='StartDate'
                                        wmsSelector={this.state.Selector + "StartDate"}
                                        label={this.translate('Label_Begin')}
                                        onChangeFunc={this.filterHandleChange}
                                        value={this.state.filter.StartDate}
                                        windowId={this.props.windowId}
                                        isDateParseInCustomFormat={true}
                                    />
                                    {/* <WmsDateTimePicker
                                        id={'StartDate'}
                                        name='StartDate'
                                        wmsSelector={this.state.Selector + 'StartDate'}
                                        label={this.translate('Label_Begin')}
                                        onChangeFunc={this.filterHandleChange}
                                        value={this.state.filter.StartDate}
                                        windowId={this.props.windowId} /> */}


                                    <WmsCustomDateTimePicker
                                        id={'EndDate'}
                                        name='EndDate'
                                        wmsSelector={this.state.Selector + "EndDate"}
                                        label={this.translate('Label_End')}
                                        onChangeFunc={this.filterHandleChange}
                                        value={this.state.filter.EndDate}
                                        windowId={this.props.windowId}
                                        isDateParseInCustomFormat={true}
                                        defaultTime={'23:59:59'}
                                    />
                                    {/* <WmsDateTimePicker
                                        id={'EndDate'}
                                        name='EndDate'
                                        wmsSelector={this.state.Selector + 'EndDate'}
                                        label={this.translate('Label_End')}
                                        onChangeFunc={this.filterHandleChange}
                                        value={this.state.filter.EndDate}
                                        windowId={this.props.windowId} /> */}
                                </WmsCollapse>

                                <WmsCollapse id={'inventory_search_collapse5'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse5') ? !this.state.isExpandedCollapse['inventory_search_collapse5'] : true} showDragIcon={true} headerLabel={this.translate('Label_Inventory_Placement_Search')} showCollapseText={false} setCollapseMetaData={(e) => this.setCollapseMetaData(e, 'inventory_search_collapse5')}>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsLocation"
                                            name="IndexOptionsLocation"
                                            windowId={this.props.windowId}
                                            label={this.translate('Label_Location')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsLocation'}
                                            value={this.state.filter.IndexOptionsLocation}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Location'
                                            inputName='Location'
                                            wmsSelector={this.state.Selector + 'Location'}
                                            value={this.state.filter.Location}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsMuLabel"
                                            name="IndexOptionsMuLabel"
                                            windowId={this.props.windowId}
                                            label={this.translate('Label_MU')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsMuLabel'}
                                            value={this.state.filter.IndexOptionsMuLabel}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='MuLabel'
                                            inputName='MuLabel'
                                            wmsSelector={this.state.Selector + 'MuLabel'}
                                            value={this.state.filter.MuLabel}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <WmsDropdown
                                        id="IndexOptionsStatus"
                                        name="IndexOptionsStatus"
                                        windowId={this.props.windowId}
                                        label={this.translate('Label_Hold_Status')}
                                        wmsSelector={this.state.Selector + "HoldStatus"}
                                        value={this.state.filter.IndexOptionsStatus}
                                        onChangeFunc={this.filterHandleChange}
                                        options={this.state.booleanOption}
                                        valueField='value'
                                        textField='description'
                                    />
                                </WmsCollapse>
                            </div>
                        </div>

                        <div>
                            {this.state.isOpenSaveFilterModel && (
                                <WmsModel
                                    id='saveCurrentInventoryFilter'
                                    title={this.translate('Label_Save_Filter')}
                                    width={450}
                                    height={240}
                                    onCloseFunc={this.exitSaveFilterModel}
                                    isOpen={this.state.isOpenSaveFilterModel}
                                    customClass='wms-model-new-ui-wrapper'>
                                    <div className="model-content-wrapper">
                                    <WmsContainer>
                                        <WmsInput
                                            inputName="newFilterTitle"
                                            name="newFilterTitle"
                                            wmsSelector={this.state.Selector + 'FilterTitle'}
                                            value={this.state.newFilterTitle}
                                            onChangeFunc={this.handleFilterTitleChange}
                                            extraWrapperClass="full-width-input"
                                            label={this.translate('Label_Filter_Title')} />
                                    </WmsContainer>
                                    </div>
                                    <div className="save-filter-button-wrapper footer-btn-holder">
                                        <WmsButton
                                            label={this.translate('Label_Cancel')}
                                            wmsSelector={this.state.Selector + "SaveNewFilterExit"}
                                            onClickFunc={this.exitSaveFilterModel}
                                            buttonType="orange"
                                            icon='fa-ban'
                                        />
                                        <WmsButton
                                            label={this.translate('Label_Save')}
                                            wmsSelector={this.state.Selector + "SaveNewFilter"}
                                            onClickFunc={(e) => this.onSaveFilter()}
                                            disabled={this.state.newFilterTitle == '' || this.state.filterNameExist}
                                            icon="fa-floppy-o"
                                            showToolTip={this.state.filterNameExist}
                                            tooltipText={this.translate('Label_Filter_Name_Exist_Message')}
                                        />
                                    </div>
                                </WmsModel>
                            )}
                        </div>

                        <WmsConfirmModel
                            id={"ConfirmModel" + this.props.windowId}
                            isOpen={this.state.isOpenConfirmModel}
                            type={this.translate('Label_Confirmation')}
                            width={500}
                            height={175}
                            firstBtnLabel={this.translate('Label_Yes')}
                            firstBtnIcon={"fa-check-square"}
                            firstBtnOnClickFunc={this.onYesRemoveSavedFilter}
                            sawCheckBox={false}
                            conFormModelText={this.translate('Label_are_you_sure_remove_filter')}
                            onCloseFunc={this.ClickExit}
                            thirdBtnLabel={this.translate('Label_No')}
                            thirdBtnIcon={"fa-times-circle"}
                        />
                    </div>

                </div>
            </div>
        );
    }
}