import * as GlobalService from '../global_services';
var isFirstTimeOpen = true;
var keyName = "NavBar";
var metaDataOrderObject = {
    "NavBar": {
        "CurrentView":"wms"
    }
};

// call api for get metaData of order is it's open first time
export const setActiveViewInMetaData = function (CurrentView) {
    metaDataOrderObject[keyName]['CurrentView'] = CurrentView;
    GlobalService.setCurrentActiveView(CurrentView);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}