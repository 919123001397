
import { BaseRequirements } from './BaseRequirements';
export class NumberWms {
	/**
	* Simple contains method for Dictionary
	* @params key:string
	* @return void
	*/
    public static testing(message: string) {
        console.log(message);
        return message;
    }

    public test(message: string) {
        console.log('4 : ', message);
        return message;
    }

    public jasMineExample(mynum: number): number {
        return mynum;
    }

    public truncateNumber(mynum: number, endnum: number): any {
        // we first check to see if the input number is greater than our maximum number
        if (mynum > endnum) {
            // convert numbers to strings to get their length
            var convertmynumToString = mynum.toString();
            // In the case where the input number is greater than the possible number and it is only 1 character we have to revert it to null
            if (convertmynumToString.length == 1) {
                return null;
            }
            // case where we have more than 1 digit we are just going to strip the last digit off and return it
            else {
                return parseInt(convertmynumToString.slice(0, -1));
            }
        }
        // this would be used if we don't get valid numbers or everything is correct
        else {
            return mynum
        }
    }

    /**
     * Determines if something is in fact a positive number
     * @params any
     * @return boolean
     */
    public static isPositiveNumber(elementInQuestion, allowStringNumbers) {
        if (allowStringNumbers === true) {
            elementInQuestion = parseFloat(elementInQuestion);
        }
        if (this.isNumber(elementInQuestion, allowStringNumbers)) {
            if (elementInQuestion >= 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * Determines if something is in fact a number
     * @params any
     * @return boolean
     */
    public static isNumber(elementInQuestion, allowStringNumbers) {
        if (allowStringNumbers === true) {
            elementInQuestion = parseFloat(elementInQuestion);
        }
        if (typeof elementInQuestion === 'string') {
            return false;
        } else {
            return !isNaN(parseFloat(elementInQuestion)) && isFinite(elementInQuestion);
        }
    }

    /* Use isNaN() instead
    public static isNumber(num: number): Boolean {}
    */
}

