
import React from 'react';

import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { HeaderColorComponent } from './header-color.component.jsx';
import { SidebarColorComponent } from './sidebar-color.component.jsx';
import { ColorPickerComponent } from './color-picker.component.jsx';
import { default as WmsButton } from "../components/WmsButton/WmsButton.jsx";
import { default as WmsCollapseProto } from '../components/WmsCollapse/WmsCollapse.jsx';
import { default as WmsModel } from '../components/WmsModel/WmsModel.jsx';
import { default as WmsContainer } from '../components/WmsContainer/WmsContainer.jsx';
import { default as WmsInput } from '../components/WmsInput/WmsInput.jsx';
import { default as WmsConfirmModel } from '../components/WmsConfirmModel/WmsConfirmModel.jsx';
import {BrandingLogo} from './branding-logo.component.jsx';

var axios = require('axios');
import * as metaDataService from './meta-data.service';
import { kendo } from './../global_variable_service.jsx';

import './themes.scss';

export class themesComponent extends React.Component {
    constructor(props) {
        super(props);

        
        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            displayColorPicker: false,
            color: "#fff",
            themeName: 'blue',
            // anotherObj: colorVariableList,
            mainColorObj: {},
            defaulLoading: false,
            resetLoading: false,
            isOpenConfirmModel: false,
            isOpenSaveThemeModel: false,
            Selector: "Themes",
            savedFilterList: [],
            filterNameExist: false,

        };
    }

    async componentDidMount() {
        kendo.ui.progress($("." + this.state.windowId), true);
        await metaDataService.getUserClientMetaData();
        kendo.ui.progress($("." + this.state.windowId), false);
        var appliedThemeData = metaDataService.getDefaultThemeMetaData();
        var savedTheme = metaDataService.getSaveThemeMetaData();
        console.log("savedTheme :", savedTheme);
        this.setState({
            savedFilterList: savedTheme
        });
        if ($.isEmptyObject(appliedThemeData)) {
            this.colorObjectDataSet();
        } else {
            this.setThemeColorObject(appliedThemeData);
        }
        $('#wms-theme-wrapper').closest('.k-window-content').css("padding", "0");
    }

    componentWillUnmount() {
        $('#wms-theme-wrapper').closest('.k-window-content').removeAttr("style");
    }

    themeChange = (themeName) => {
        this.setState({
            themeName: themeName
        }, this.colorObjectDataSet);
    }
    exitSaveThemeModel = () => {
        this.setState({
            isOpenSaveThemeModel: false
        });
    }
    handleFilterTitleChange = (e) => {
        let value = e.target.value;
        if (value.Description == '') {
            value = '';
        }

        var nameExists = false;
        // check dublicate title for case sensitive
        var exists = this.state.savedFilterList.findIndex(function (item) {
            return value.toLowerCase() === item.title.toLowerCase();
        });
        if (exists != -1) {
            nameExists = true;
        }
        this.setState({
            newFilterTitle: value,
            filterNameExist: nameExists
        });
    }
    onOpenSaveThemeModel = () => {
        this.setState({
            isOpenSaveThemeModel: true,
            newFilterTitle: "",
            filterNameExist: false
        });
    }
    onSaveTheme = () => {
        if (!this.state.filterNameExist) {
            var colorObj = this.state.mainColorObj;
            console.log("colorObj :",colorObj);
            // var metaObject = {};
            // for (var key in colorObj) {
            //     metaObject[key] = colorObj[key]['val'];
            // }
            metaDataService.setSaveThemeMetaData(this.state.newFilterTitle, colorObj);
            this.exitSaveThemeModel();
        }
    }
    onClearSaveFilter = (index) => {
        this.setState({
            isOpenConfirmModel: true,
            savedFilterIndex: index
        });
    }
    onYesRemoveSavedFilter = () => {
        metaDataService.clearSaveThemeListMetaData(this.state.savedFilterIndex);
        this.setState({
            isOpenConfirmModel: false,
            savedFilterList: metaDataService.getSaveThemeMetaData()
        });
    }

    ClickExit = () => {
        this.setState({
            isOpenConfirmModel: false
        });
    }
    onUseSaveFilter = (index) => {
        var objFilter = JSON.parse(JSON.stringify(this.state.savedFilterList[index]));
        this.setThemeColorObject(objFilter.themeData);
    }
    colorObjectDataSet = () => {
        axios.get('assets/themes/' + this.state.themeName + '/variable.json').then((response) => {
            var responseObj = response.data;
            this.setThemeColorObject(responseObj);
        });
    }
    themeReset = (isSave) => {
        this.setState({
            themeName: 'blue'
        }, this.colorObjectDataSet);
        if (isSave) {
            this.setState({
                resetLoading: true
            });
            metaDataService.setDefaultThemeMetaData({}).then((response) => {
                this.setState({
                    resetLoading: false
                });
            });
        }

    }
    resetTheme = (themeName) => {
        this.setState({
            themeName: themeName,
            resetLoading: true
        }, this.colorObjectDataSet);
        metaDataService.setDefaultThemeMetaData({}).then((response) => {
            this.setState({
                resetLoading: false
            });
        });
    }
    setThemeColorObject = (responseObj) => {
        var colorObj = {};
        for (var key in responseObj) {
            if (responseObj.hasOwnProperty(key)) {
                var val = responseObj[key];
                colorObj[key] = val;
                document.documentElement.style.setProperty('--' + key, val);
            }
        }
        this.setState({
            mainColorObj: colorObj
        });
        this.colorPickerComponent.resetColorPicker(colorObj);
    }

    handleChangeMainColor = (e, name) => {
        var steteAssign = this.state.mainColorObj;
        steteAssign[name] = e.value;
        this.setState({ mainColorObj: steteAssign });
    };
    themeSetAsDefault = () => {
        var colorObj = this.state.mainColorObj;
        var metaObject = {};
        for (var key in colorObj) {
            metaObject[key] = colorObj[key];
        }
        this.setState({
            defaulLoading: true
        });
        metaDataService.setDefaultThemeMetaData(metaObject).then((response) => {
            this.setState({
                defaulLoading: false
            });
        });
    }
    render() {
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px'
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        var anotherObj = this.state.anotherObj;
        var mainColorObj = this.state.mainColorObj;
        return (
            <div id="wms-theme-wrapper">
                <div className="theme-holder-react">
                    <div className="theme-search-holder">
                        <div className="theme-search-filter">
                            <div className="wms-toolbar">
                                <WmsButton
                                    label={'Save Themes'}
                                    wmsSelector={'ThemeSaveButton'}
                                    onClickFunc={this.onOpenSaveThemeModel}
                                    icon='fa-solid fa-floppy-disk' />
                                {/* <WmsButton
                                    label={'Reset Theme'}
                                    buttonType={'red'}
                                    wmsSelector={'ThemeResetButton'}
                                    onClickFunc={() => this.resetTheme('blue')}
                                    loading={this.state.resetLoading}
                                    icon='fa-sync-alt' /> */}
                            </div>
                            <div className="theme-form-boxes-wrapper">
                                <div className={"theme-form-boxes"}>
                                    <div className="theme-are-content" id="sortable-section">
                                        <WmsCollapseProto id={'theme_collapse'} headerLabel={'Default Themes'} showCollapseText={false}>
                                            <ul className="filter-list-block">
                                                <li className="filter-list">
                                                    <a className="title-text">Green</a>
                                                    <div className="wms-apply-btn">
                                                        <WmsButton
                                                            label={this.translate('Label_Apply')}
                                                            buttonType='green'
                                                            wmsSelector={'ThemeApplyButton'}
                                                            onClickFunc={(e) => this.themeChange('green')}
                                                            icon='' />
                                                    </div>
                                                </li>
                                                <li className="filter-list">
                                                    <a className="title-text">Blue</a>
                                                    <div className="wms-apply-btn">
                                                        <WmsButton
                                                            label={this.translate('Label_Apply')}
                                                            buttonType='green'
                                                            wmsSelector={'ThemeApplyButton'}
                                                            onClickFunc={(e) => this.themeChange('blue')}
                                                            icon='' />
                                                    </div>
                                                </li>
                                                <li className="filter-list">
                                                    <a className="title-text">Orange</a>
                                                    <div className="wms-apply-btn">
                                                        <WmsButton
                                                            label={this.translate('Label_Apply')}
                                                            buttonType='green'
                                                            wmsSelector={'ThemeApplyButton'}
                                                            onClickFunc={(e) => this.themeChange('orange')}
                                                            icon='' />
                                                    </div>
                                                </li>
                                                <li className="filter-list">
                                                    <a className="title-text">Dark Blue</a>
                                                    <div className="wms-apply-btn">
                                                        <WmsButton
                                                            label={this.translate('Label_Apply')}
                                                            buttonType='green'
                                                            wmsSelector={'ThemeApplyButton'}
                                                            onClickFunc={(e) => this.themeChange('DarkBlue')}
                                                            icon='' />
                                                    </div>
                                                </li>
                                            </ul>
                                        </WmsCollapseProto>
                                        {this.state.savedFilterList.length !== 0 && (
                                            <WmsCollapseProto id={'search_collapse0'} headerLabel={'Saved Theme List'} showCollapseText={false}>
                                                <ul className="filter-list-block">
                                                    {this.state.savedFilterList.map((data, index) => (
                                                        <li className="filter-list" key={index}>
                                                            <a className="title-text" title={data.title}>{data.title}</a>
                                                            <a className="clear-save-filter" onClick={(e) => this.onClearSaveFilter(index)}><i className="far fa-times-circle" aria-hidden="true"></i></a>
                                                            <div className="wms-apply-btn">
                                                                <WmsButton
                                                                    label={this.translate('Label_Apply')}
                                                                    buttonType='green'
                                                                    wmsSelector={this.state.Selector + 'ApplyFilterButton'}
                                                                    onClickFunc={(e) => this.onUseSaveFilter(index)}
                                                                    icon='' />
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </WmsCollapseProto>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="theme-grid ">
                        <div className="wms-search-grid-data">
                        <WmsCollapseProto id={'branding_logo'} headerLabel={this.translate('Branding_Logo')} showCollapseText={false} >
                            <BrandingLogo/>
                        </WmsCollapseProto>
                        <WmsCollapseProto id={'branding_colors'} headerLabel={this.translate('Branding_Colors')} showCollapseText={false} >
                            <div className="right_content">
                                <WmsButton
                                    label={this.translate('Label_Save')}
                                    wmsSelector={'ThemeSaveButton'}
                                    onClickFunc={this.themeSetAsDefault}
                                    loading={this.state.defaulLoading}
                                    icon='fa-solid fa-floppy-disk' />
                                <WmsButton
                                    label={'Reset And Save'}
                                    wmsSelector={'ThemeSaveButton'}
                                    buttonType='yellow'
                                    onClickFunc={() => this.themeReset(true)}
                                    loading={this.state.resetLoading}
                                    icon='fa-sync-alt' />
                                <WmsButton
                                    label={'Reset'}
                                    wmsSelector={'ThemeSaveButton'}
                                    buttonType='red'
                                    onClickFunc={() => this.themeReset(false)}
                                    icon='fa-redo-alt' />
                                <ul>
                                    {Object.keys(mainColorObj).map((format, key) => (
                                        <li className="theme-var-list" key={key}>
                                            <div className="input-label">{format}</div>
                                            <input id={"picker_" + format} />
                                            <ColorPickerComponent id={format} value={mainColorObj[format]} onChange={this.handleChangeMainColor} name={format} ref={(c) => { this.colorPickerComponent = c; }} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            </WmsCollapseProto>
                        </div>
                    </div>
                </div>
                <div>
                    {this.state.isOpenSaveThemeModel && (
                        <WmsModel
                            id='saveCurrentThemes'
                            title={this.translate('Label_Save_Filter')}
                            width={450}
                            height={250}
                            onCloseFunc={this.exitSaveThemeModel}
                            isOpen={this.state.isOpenSaveThemeModel}
                            customClass='wms-model-new-ui-wrapper'>
                            <div className='model-content-wrapper'>
                            <WmsContainer>
                                <WmsInput
                                    inputName="newFilterTitle"
                                    name="newFilterTitle"
                                    wmsSelector={this.state.Selector + 'FilterTitle'}
                                    value={this.state.newFilterTitle}
                                    onChangeFunc={this.handleFilterTitleChange}
                                    label={this.translate('Label_Filter_Title')} />
                            </WmsContainer>
                            </div>
                            <div className="save-filter-button-wrapper footer-btn-holder">
                                <WmsButton
                                    label={this.translate('Label_Add')}
                                    wmsSelector={this.state.Selector + "SaveNewFilter"}
                                    onClickFunc={this.onSaveTheme}
                                    disabled={this.state.newFilterTitle == '' || this.state.filterNameExist}
                                    icon={'fa-plus'}
                                    showToolTip={this.state.filterNameExist}
                                    tooltipText={this.translate('Label_Filter_Name_Exist_Message')}
                                />
                                <WmsButton
                                    label={this.translate('Label_Exit')}
                                    wmsSelector={this.state.Selector + "SaveNewFilterExit"}
                                    onClickFunc={this.exitSaveThemeModel}
                                    buttonType="yellow"
                                    icon={'fa-sign-out-alt'}
                                />
                            </div>
                        </WmsModel>
                    )}
                </div>
                <WmsConfirmModel
                    id={"ConfirmModel" + this.props.windowId}
                    isOpen={this.state.isOpenConfirmModel}
                    type={this.translate('Label_Confirmation')}
                    width={500}
                    height={175}
                    firstBtnLabel={this.translate('Label_Yes')}
                    firstBtnIcon={"fa-check-square"}
                    firstBtnOnClickFunc={this.onYesRemoveSavedFilter}
                    sawCheckBox={false}
                    conFormModelText={this.translate('Label_are_you_sure_remove_filter')}
                    onCloseFunc={this.ClickExit}
                    thirdBtnLabel={this.translate('Label_No')}
                    thirdBtnIcon={"fa-times-circle"}
                />
            </div>

        );
    }
}