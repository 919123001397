import React from 'react';

//component
import WmsInput from '../components/WmsInput/WmsInput.jsx';
import WmsRadio from '../components/WmsRadio/WmsRadio.tsx';
import WmsCheckBox from '../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsFormGrid from '../components/WmsFormGrid/WmsFormGrid.jsx';
import WmsDropdown from '../components/WmsDropdown/WmsDropdown.jsx';
import WmsUpdateDropdownList from '../components/WmsUpdateDropdownList/WmsUpdateDropdownList.jsx';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse.jsx';
import * as GlobalService from '../global_services';

export class ManageUsersAccountDetails extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            Selector: 'Users',
            passInputType: 'password',
            inputIcon: 'fa-eye',
            facilityGridData:[]
        }
    }
    componentDidMount(){
        $('#CellPhone').mask('(000) 000-0000');
    }
    componentWillReceiveProps(nextProps) {
        if(
            nextProps.sharedUser.userType == 'customerAccess' &&
            nextProps.sharedUser.Uri != '' &&
            nextProps.sharedUser.Uri != this.props.sharedUser.Uri
        ){
            setTimeout(() => {
                let tmpCustOptions = Object.assign([], nextProps.sharedUserOptions.customerOptions);
                _.remove(tmpCustOptions, obj => nextProps.sharedUser.CustomerIds.includes(obj.Id));
                var dropdownListData = $("#CustomerIds").data("kendoDropDownList");
                dropdownListData.setDataSource(tmpCustOptions);
            }, 700);
            this.setCustomerFacilityObj(nextProps.sharedUser.CustomerIds,nextProps.sharedUserOptions.customerOptions,'isFromFirstEdit');
        }else if(
            nextProps.sharedUser.userType == 'warehouseAccess' &&
            nextProps.sharedUser.Uri != this.props.sharedUser.Uri
        ){
            this.removeObjectFromDropdownList(nextProps.sharedUser.FacilityIds,nextProps.sharedUserOptions.allFacilities)
        }
     }

    handleChangeCustomer = (event) => {

        let name = event.target.name;
        let value = event.target.value;

        this.props.onUserDataset(Object.assign(
            {},
            this.props.sharedUser,
            { [name]: event.target.type == 'radio' ? !this.props.sharedUser[name] : value }
        ))
    }
    handleChangeUserName = (event) => {
        this.props.onUserDataset(Object.assign(
            {},
            this.props.sharedUser,
            { 'Login': event.target.value }
        ))
    }


    toggleShowPass = () => {
        this.setState({
            passInputType: (this.state.passInputType == 'password') ? 'text' : 'password',
            inputIcon: (this.state.passInputType == 'password') ? 'fa-eye-slash' : 'fa-eye'
        })
    }

    handleUserTypeChange = (param_radioBtn_value) => {

        this.props.onUserDataset(Object.assign(
            {},
            this.props.sharedUser,
            {
                'userType': param_radioBtn_value,
                'roleName': ''
            }
        ));
        this.setState({ facilityGridData:[]});

        var dropdownListData = $("#FacilityIdsDropdownOptions").data("kendoDropDownList");
        if(param_radioBtn_value == 'customerAccess'){
            if(dropdownListData){ setTimeout(() => { dropdownListData.setDataSource([]);  }, 700); }
            this.setCustomerFacilityObj(this.props.sharedUser.CustomerIds,this.props.sharedUserOptions.customerOptions);
        }else{
            if(dropdownListData){ setTimeout(() => { dropdownListData.setDataSource(this.props.sharedUserOptions.allFacilities);  }, 700); }
        }

    }
    handleUserCustomerChange = (valueArr) =>{
        if(!valueArr){
            valueArr = [];
        }


        this.props.onUserDataset(Object.assign(
            {},
            this.props.sharedUser,
            {
                'CustomerIds': valueArr
            }
        ));
        this.setCustomerFacilityObj(valueArr,this.props.sharedUserOptions.customerOptions);
    }
    handleUserWarehouseChange = (valueArr) =>{
        if(!valueArr){
            valueArr = [];
        }
        this.props.onUserDataset(Object.assign(
            {},
            this.props.sharedUser,
            { 'FacilityIds': valueArr }
        ));
    }
    setCustomerFacilityObj = (customerIds,customerOptions,isFromFirstEdit) => {
        var facilityObj = [];
        _.filter(customerOptions, function(objCust){
             if(customerIds.includes(objCust.Id)){
                 var merged = _.merge(_.keyBy(facilityObj, 'Id'), _.keyBy(objCust.Facilities, 'Id'));
                 facilityObj = _.values(merged);
             }
        });
        this.setState({
            facilityGridData:facilityObj
        },() => {
            if(isFromFirstEdit){
                this.removeObjectFromDropdownList(this.props.sharedUser.FacilityIds,facilityObj)
            }else{
                var dropdownListData = $("#FacilityIdsDropdownOptions").data("kendoDropDownList");
                if(dropdownListData){
                    setTimeout(() => {  dropdownListData.setDataSource(facilityObj);  }, 700);
                }
            }
        });
    }

    removeObjectFromDropdownList = (facilityIds,facilityOptions) => {
        setTimeout(() => {
            let tmpFacOptions = Object.assign([], facilityOptions);
            _.remove(tmpFacOptions, obj => facilityIds.includes(obj.Id));
            var dropdownListData = $("#FacilityIdsDropdownOptions").data("kendoDropDownList");
            dropdownListData.setDataSource(tmpFacOptions);
        }, 700);
    }


    render() {
        let renderUser = this.props.sharedUser;
        let roleOption = this.props.sharedUserOptions.warehouseRoleOption;
        if (renderUser.userType == 'customerAccess') {
            roleOption = this.props.sharedUserOptions.customerRoleOption;
        }
        if(!renderUser.CustomerIds) {
            renderUser.CustomerIds = [];
        }
        const UseCognitoLogin = GlobalService.CurrentUser.UseCognitoLogin;
        const isHubLogin = GlobalService.GetCurrentUser()?.IsJwtLogin;

        let renderCustomerOptions = this.props.sharedUserOptions.customerOptions;

        return (
            <div>
                <WmsCollapse
                id={'collapseManageUserInformation'}
                headerLabel={this.translate('Label_User_Information')}
                showCollapseText={false}
                showCollapseIcon={false} >
                    {UseCognitoLogin || isHubLogin ? (
                    <div className="display-block">
                        <div className="width-100 display-flex">
                        <WmsInput
                            id='Login'
                            inputName='formName'
                            wmsSelector={this.state.Selector + 'LoginUsername'}
                            onChangeFunc={this.handleChangeUserName}
                            label={this.translate('Label_Login')}
                            extraWrapperClass="wms-account-details-all-inpun-box"
                            value={renderUser.Login}
                            readOnly={true}
                            showToolTip={true}
                            tooltipText={this.translate('Label_Manage_User_Hub')}/>
                        <WmsInput
                            id='Name'
                            inputName='Name'
                            wmsSelector={this.state.Selector + 'Name'}
                            onChangeFunc={this.handleChangeCustomer}
                            label={this.translate('Label_User_beta_Account_Name')}
                            extraWrapperClass="width-31"
                            value={renderUser.Name}
                            disabled={true} />
                        </div>
                        <div className="width-100 display-flex">
                            <WmsInput
                            id='Email'
                            inputName='Email'
                            wmsSelector={this.state.Selector + 'Email'}
                            onChangeFunc={this.handleChangeCustomer}
                            label={this.translate('Label_Email_Address')}
                            value={renderUser.Email}
                            extraWrapperClass="width-60"
                            disabled={true}/>
                        <WmsInput
                            id='CellPhone'
                            inputName='CellPhone'
                            wmsSelector={this.state.Selector + 'CellPhone'}
                            onChangeFunc={this.handleChangeCustomer}
                            label={this.translate('Label_Warehouse_PhoneNumber')}
                            value={renderUser.CellPhone}
                            extraWrapperClass="width-20"
                            disabled={true} />
                        </div>
                    </div>
                    ):(
                    <>
                    <div className="display-flex">
                        <WmsInput
                            id='Login'
                            inputName='formName'
                            wmsSelector={this.state.Selector + 'LoginUsername'}
                            onChangeFunc={this.handleChangeUserName}
                            label={this.translate('Label_Login')}
                            required={true}
                            extraWrapperClass="wms-account-details-all-inpun-box"
                            value={renderUser.Login}
                            />
                        <WmsInput
                            inputType={this.state.passInputType}
                            id='NewPassword'
                            inputName='NewPassword'
                            wmsSelector={this.state.Selector + 'NewPassword'}
                            onChangeFunc={this.handleChangeCustomer}
                            label={this.translate('Login_Lbl_User_Beta_Password')}
                            required={this.props.sharedUserUri == '' ? true : false}
                            showIconAfterInput={false}
                            icon={this.state.inputIcon}
                            extraWrapperClass="width-35 wms-account-user-information-password"
                            onIconClick={this.toggleShowPass}
                            value={renderUser.NewPassword}
                            autoComplete="new-password"
                             />
                        <div className="wms_confirm_psw">
                            <WmsInput
                                inputType={this.state.passInputType}
                                id='confirmPassword'
                                inputName='confirmPassword'
                                wmsSelector={'ConfirmPassword'}
                                onChangeFunc={this.handleChangeCustomer}
                                label={this.translate('Label_User_beta_Confirm_Password')}
                                required={this.props.sharedUserUri == '' ? true : false}
                                extraWrapperClass="width-100 wms-account-user-information-password"
                                value={renderUser.confirmPassword}
                                autoComplete="new-password" />

                            {(renderUser.NewPassword != renderUser.confirmPassword &&
                                renderUser.confirmPassword != null &&
                                renderUser.confirmPassword != '') &&

                                (<label className="wms_error_msg" >{this.translate('Label_Passwords_Do_Not_Match')}</label>)

                            }
                            {(renderUser.NewPassword == renderUser.confirmPassword &&
                                renderUser.confirmPassword != null &&
                                renderUser.confirmPassword != '') &&

                                (<label className="wms_success_msg" >{this.translate('Label_Passwords_Match') + '!'}</label>)

                            }
                        </div>
                    </div>
                    <div className="display-flex">
                        <WmsInput
                            id='Name'
                            inputName='Name'
                            wmsSelector={this.state.Selector + 'Name'}
                            onChangeFunc={this.handleChangeCustomer}
                            required={true}
                            label={this.translate('Label_User_beta_Account_Name')}
                            extraWrapperClass="width-31"
                            value={renderUser.Name}
                        />
                        <WmsInput
                            id='Email'
                            inputName='Email'
                            wmsSelector={this.state.Selector + 'Email'}
                            onChangeFunc={this.handleChangeCustomer}
                            required={true}
                            label={this.translate('Label_Email_Address')}
                            value={renderUser.Email}
                            extraWrapperClass="width-60"
                            />
                    </div>
                    <div className="display-flex">
                        <WmsInput
                            id='CellPhone'
                            inputName='CellPhone'
                            wmsSelector={this.state.Selector + 'CellPhone'}
                            onChangeFunc={this.handleChangeCustomer}
                            label={this.translate('Label_Warehouse_PhoneNumber')}
                            value={renderUser.CellPhone}
                            extraWrapperClass="width-20"
                            />
                        <WmsDropdown
                            id="CellCarrierId"
                            name="CellCarrierId"
                            label={this.translate('Label_Cell_Carrier')}
                            wmsSelector={this.state.Selector + "CellCarrier"}
                            onChangeFunc={this.handleChangeCustomer}
                            options={this.props.sharedUserOptions.cellCarriersOption}
                            value={renderUser.CellCarrierId}
                            valueField='CellCarrierId'
                            textField='Name'
                            parentClassName='form_group'
                            extraWrapperClass="wms-account-dropdown-box"
                            blankFirstOption={true}
                            blankFirstOptionText={'Unspecified'} />
                    </div>
                    </>
                    )}
                </WmsCollapse >

                <WmsCollapse
                id={'collapseManageUserAccess'}
                headerLabel={this.translate('Label_User_Access')}
                showCollapseText={false}
                showCollapseIcon={false} >
                    <div className="wms-user-access">
                            <div>
                                {this.props.sharedUserUri == '' &&
                                (<div className="wms-user-desc">
                                    <label> {this.translate('Users_Type_First_Note')} < span > {this.translate('Users_Type_Second_Note')}</span></label>
                                </div>)}
                                <div className="wms-radio-wrapper">
                                    {(this.props.sharedUserUri == '' || !this.props.sharedUser.Provisioned) &&
                                    this.props.sharedLoggedInUser == 'warehouse' && (
                                        <WmsRadio
                                            id="warehouseAccess"
                                            name="userType"
                                            label={this.translate('StatusBar_Lbl_Facility')}
                                            wmsSelector={this.state.Selector + 'TypeWarehouse'}
                                            onChangeFunc={(e) => this.handleUserTypeChange('warehouseAccess')}
                                            value={'warehouseAccess'}
                                            checked={renderUser.userType == 'warehouseAccess'}
                                        />
                                    )}
                                    {(this.props.sharedUserUri == '' || !this.props.sharedUser.Provisioned) &&
                                    <WmsRadio
                                        id="customerAccess"
                                        name="userType"
                                        label={this.translate('FindOrders_Lbl_Customer')}
                                        wmsSelector={this.state.Selector + 'TypeCustomer'}
                                        onChangeFunc={(e) => this.handleUserTypeChange('customerAccess')}
                                        value={'customerAccess'}
                                        checked={renderUser.userType == 'customerAccess' || this.props.sharedLoggedInUser == 'customer'}
                                    />
                                    }
                                    {(renderUser.userType != '') &&
                                        <div className="width-100 display-flex">
                                            <WmsDropdown
                                                id="roleName"
                                                name="roleName"
                                                label={this.translate('RoleManager_Label_Role')}
                                                wmsSelector={this.state.Selector + "IndexOptionsRole"}
                                                onChangeFunc={this.handleChangeCustomer}
                                                value={(renderUser.roleName == 'Unspecified') ? '': renderUser.roleName}
                                                options={roleOption}
                                                textField='Name'
                                                valueField='Name'
                                                parentClassName='form_group'
                                                required={(
                                                    renderUser.roleName == 'Unspecified' ||
                                                    renderUser.roleName == ''
                                                )}
                                                disabled={this.props.sharedUserUri != '' && this.props.sharedLoggedInUserId == renderUser.UserId}
                                                extraWrapperClass="width-40" />

                                            <WmsDropdown
                                                id="V1Role"
                                                name="V1Role"
                                                label={this.translate('Label_User_ClassicView_Access')}
                                                wmsSelector={this.state.Selector + "V1Role"}
                                                onChangeFunc={this.handleChangeCustomer}
                                                value={(renderUser.V1Role == null) ? '' : renderUser.V1Role}
                                                options={ renderUser.userType == 'customerAccess' ? this.props.sharedUserOptions.customerAccessV1Option
                                                    : this.props.sharedUserOptions.facilityAccessV1Option}
                                                textField='name'
                                                valueField='name'
                                                parentClassName='form_group'
                                                blankFirstOption={true}
                                                blankFirstOptionText={'Unspecified'}
                                                disabled={this.props.sharedUserUri != '' && this.props.sharedLoggedInUserId == renderUser.UserId}
                                                extraWrapperClass="width-40"/>
                                        </div>
                                    }
                                </div>
                            </div>

                            {(renderUser.userType != '' || this.props.sharedLoggedInUser == 'customer') &&
                                (<div>
                                    {
                                    (renderUser.userType == 'customerAccess')
                                    && (
                                        <div className="wms-hide-show-dropdown">
                                            <WmsUpdateDropdownList
                                                    id="CustomerIds"
                                                    name="CustomerIds"
                                                    label={this.translate('Label_User_Beta_Assigned_To_Customer')}
                                                    optionsLabel={this.translate('Label_User_Beta_Assigned_To_Customer')}
                                                    wmsSelector={this.state.Selector + "CustomerAccess"}
                                                    valueArray={renderUser.CustomerIds}
                                                    onChangeFunc={this.handleUserCustomerChange}
                                                    textField='Name'
                                                    valueField='Id'
                                                    isVirtualOptions={false}
                                                    staticOptions={renderCustomerOptions}
                                                    required={renderUser.CustomerIds.length == 0}
                                                    extraWrapperClass="overwrite-update-dropdown-style"
                                                />
                                        </div>
                                    )
                                }
                                <div className="facility-grid-wrapper wms-hide-show-dropdown">
                                        <WmsUpdateDropdownList
                                            id="FacilityIdsDropdownOptions"
                                            name="FacilityIds"
                                            label={this.translate('Label_User_Beta_Assigned_To_Warehouse')}
                                            optionsLabel={this.translate('Label_User_Beta_Assigned_To_Warehouse')}
                                            wmsSelector={this.state.Selector + "WarehouseAccess"}
                                            valueArray={renderUser.FacilityIds}
                                            onChangeFunc={this.handleUserWarehouseChange}
                                            textField='Name'
                                            valueField='Id'
                                            isVirtualOptions={false}
                                            staticOptions={
                                                (renderUser.userType == 'warehouseAccess') ?
                                                this.props.sharedUserOptions.allFacilities :
                                                this.state.facilityGridData
                                            }
                                            extraWrapperClass="overwrite-update-dropdown-style"
                                        />
                                    </div>
                                </div>)
                            }
                    </div>
                </WmsCollapse >
            </div>
        )
    }
}