import React from "react";
import PropTypes from "prop-types";
import style from "./WmsNotification.scss";
import WmsButton from "../WmsButton/WmsButton.jsx";
import * as ErrorConfig from "./error_configuration.jsx";
import * as GlobalService from "../../global_services";
import { _, kendo } from "../../global_variable_service.jsx";
var errorCodeTitle;
var errorClassTitle;
var errorStatusName;

class WmsNotification extends React.PureComponent {
    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.$rootScope = window;
        this.state = {
            showErrorPopup: false,
            showSuccessPopup: false,
            showAutoCloseWarningPopup: false,
            showAutoCloseErrorPopup: false,
            errorData: {},
            showExtraDetailString: "",
            notificationClass: "",
            isErrorExpand: false,
            ErrorConfig: ErrorConfig.getErrorMessages(),
            modalTitle: "",
            isCustomeTitleSet: false,
        };
        this.errorNotification = "";
        this.isModalKeyNotFound = false;
        this.arrKeysNotFound = [];
    }

    componentDidMount() {
        document.addEventListener("showErrorMessage", (e) => {
            this.setState(
                {
                    errorData: e.detail.data,
                    isErrorExpand: false,
                    isCustomeTitleSet: false,
                    showExtraDetailString: "",
                },
                () => {
                    this.errorClassTitle(this.state.errorData.ErrorClass);
                    this.openErrorWindow();
                }
            );
            this.arrKeysNotFound = [];
        });
        // set errorCodeTitle with translate files
        errorCodeTitle = {
            Required: this.translate("Label_ErrorClass_Required"),
            NotParsable: this.translate("Label_ErrorCode_NotParsable"),
            DoesNotExist: this.translate("Label_ErrorCode_DoesNotExist"),
            AtLeastOneRequired: this.translate("Label_ErrorCode_AtLeastOneRequired"),
            "Order Not Shipped": this.translate("Label_ErrorCode_SmartParcelResults"),
            MultipleResults: this.translate("Label_ErrorCode_MultipleResults"),
            NotSupported: this.translate("Label_ErrorCode_NotSupported"),
            ValueNotSupported: this.translate("Label_ErrorCode_ValueNotSupported"),
            ColumnNotSupported: this.translate("Label_ErrorCode_ColumnNotSupported"),
            Incompatible: this.translate("Label_ErrorCode_Incompatible"),
            Invalid: this.translate("Label_ErrorCode_Invalid"),
            InUse: this.translate("Label_ErrorCode_InUse"),
            AsyncOperationUnavailable: this.translate("Label_ErrorCode_AsyncOperationUnavailable"),
            WrongCustomerInBatch: this.translate("Label_ErrorCode_WrongCustomerInBatch"),
            MixedFacilitiesInBatch: this.translate("Label_ErrorCode_MixedFacilitiesInBatch"),
            CannotEditMessageNum: this.translate("Label_ErrorCode_CannotEditMessageNum"),
            Unallocated: this.translate("Label_Unallocated"),
            SettingDisallowedByTpl: this.translate("Label_ErrorCode_SettingDisallowedByTpl"),
            Duplicate: this.translate("Label_ErrorCode_Duplicate"),
            System: this.translate("Label_ErrorCode_System"),
        };
        // set error class title with translate
        errorClassTitle = {
            ModelValidation: this.translate("Label_ErrorClass_ModelValidation"),
            Operation: this.translate("Label_ErrorClass_Operation"),
            QueryParameter: this.translate("Label_ErrorClass_QueryParameter"),
            NullReference: this.translate("Label_ErrorClass_NullReference"),
            Authorization: this.translate("Label_ErrorClass_Authorization"),
            400: this.translate("Label_ErrorClass_SystemError"),
            401: this.translate("Label_ErrorClass_SystemError"),
            404: this.translate("Label_ErrorClass_SystemError"),
            428: this.translate("Label_ErrorClass_SystemError"),
            403: this.translate("Label_ErrorClass_Unauthorized"),
            412: this.translate("Label_ErrorClass_Unable_to_Save"),
        };
        errorStatusName = {
            400: this.translate("Label_ErrorMsg_For_System_Error"),
            401: this.translate("Label_ErrorMsg_For_System_Error"),
            403: this.translate("Label_ErrorMsg_For_Unauthorized"),
            404: this.translate("Label_ErrorMsg_For_System_Error"),
            412: this.translate("Label_ErrorMsg_For_Unable_To_Save"),
            428: this.translate("Label_ErrorMsg_For_System_Error"),
        };
    }
    openErrorWindow = () => {
        // console.log("errorData :",this.state.errorData);
        if (this.state.errorData.type == "success") {
            this.setState(
                {
                    showSuccessPopup: true,
                },
                this.autoCloseSuccessMessage
            );
        } else if (this.state.errorData.type == "warningAutoClose") {
            this.setState(
                {
                    showAutoCloseWarningPopup: true,
                },
                this.autoCloseWarningMessage
            );
        } else if (this.state.errorData.type == "errorAutoClose") {
            this.setState(
                {
                    showAutoCloseErrorPopup: true,
                },
                this.autoCloseErrorMessage
            );
        } else {
            this.setState({
                showErrorPopup: true,
                notificationClass: this.state.errorData.type,
            });
        }
    };

    autoCloseErrorMessage = () => {
        this.setTopOfAutoCloseError();
        setTimeout(() => {
            this.setState({
                showAutoCloseErrorPopup: false,
            });
        }, 4000);
    };

    autoCloseWarningMessage = () => {
        this.setTopOfAutoCloseWarning();
        setTimeout(() => {
            this.setState({
                showAutoCloseWarningPopup: false,
            });
        }, 4000);
    };

    setTopOfAutoCloseError = () => {
        // set top depends on window
        var modalTop = (window.innerHeight - 400) / 2;
        $("#errorAutoCloseMessageModel").css("top", modalTop + "px");
    };
    setTopOfAutoCloseWarning = () => {
        // set top depends on window
        var modalTop = (window.innerHeight - 200) / 2;
        $("#warningAutoCloseMessageModel").css("top", modalTop + "px");
    };
    autoCloseSuccessMessage = () => {
        setTimeout(() => {
            this.setState({
                showSuccessPopup: false,
            });
        }, 4000);
    };

    renderReason = () => {
        var ErrorCode = this.state.errorData ? this.state.errorData.ErrorCode : "";
        // console.log("ErrorCode :",ErrorCode);
        var errorCodeText = this.translate("Label_ErrorCode_Default_Reason");
        if (ErrorCode == "Required") {
            errorCodeText = this.translate("Label_ErrorClass_Required_Reason");
        } else if (ErrorCode == "NotParsable") {
            errorCodeText = this.translate("Label_ErrorCode_NotParsable_Reason");
        } else if (ErrorCode == "DoesNotExist") {
            errorCodeText = this.translate("Label_ErrorCode_DoesNotExist_Reason");
        } else if (ErrorCode == "AtLeastOneRequired") {
            errorCodeText = this.translate("Label_ErrorCode_AtLeastOneRequired_Reason");
        } else if (ErrorCode == "Order Not Shipped") {
            errorCodeText = this.translate("Label_ErrorCode_SmartParcelResults");
        } else if (ErrorCode == "MultipleResults") {
            errorCodeText = this.translate("Label_ErrorCode_MultipleResults_Reason");
        } else if (ErrorCode == "NotSupported") {
            errorCodeText = this.translate("Label_ErrorCode_NotSupported_Reason");
        } else if (ErrorCode == "ValueNotSupported") {
            errorCodeText = this.translate("Label_ErrorCode_ValueNotSupported_Reason");
        } else if (ErrorCode == "ColumnNotSupported") {
            errorCodeText = this.translate("Label_ErrorCode_ColumnNotSupported_Reason");
        } else if (ErrorCode == "Incompatible") {
            errorCodeText = this.translate("Label_ErrorCode_Incompatible_Reason");
        } else if (ErrorCode == "Invalid") {
            errorCodeText = this.translate("Label_ErrorCode_Invalid_Reason");
        } else if (ErrorCode == "InUse") {
            errorCodeText = this.translate("Label_ErrorCode_InUse_Reason");
        } else if (ErrorCode == "AsyncOperationUnavailable") {
            errorCodeText = this.translate("Label_ErrorCode_AsyncOperationUnavailable_Reason");
        } else if (ErrorCode == "WrongCustomerInBatch") {
            errorCodeText = this.translate("Label_ErrorCode_WrongCustomerInBatch_Reason");
        } else if (ErrorCode == "MixedFacilitiesInBatch") {
            errorCodeText = this.translate("Label_ErrorCode_MixedFacilitiesInBatch_Reason");
        } else if (ErrorCode == "CannotEditMessageNum") {
            errorCodeText = this.translate("Label_ErrorCode_CannotEditMessageNum_Reason");
        } else if (ErrorCode == "Unallocated") {
            errorCodeText = this.translate("Label_Unallocated_Reason");
        } else if (ErrorCode == "MissingData") {
            errorCodeText = this.translate("Label_ErrorCode_MissingData_Reason");
        } else if (ErrorCode == "SettingDisallowedByTpl") {
            errorCodeText = this.translate("Label_ErrorCode_SettingDisallowedByTpl_Reason");
        } else if (ErrorCode == "Duplicate") {
            errorCodeText = this.translate("Label_ErrorCode_Duplicate_Reason");
        } else if (ErrorCode == "System") {
            errorCodeText = this.translate("Label_ErrorCode_System_Reason");
        } else {
            errorCodeText = this.translate("Label_ErrorCode_Default_Reason");
        }
        return errorCodeText;
    };
    renderErrorMessage = () => {
        var errorData = this.state.errorData;
        var ErrorClass = errorData ? errorData.ErrorClass : "";
        var responseText = "";
        var arrErrorDesc;
        // Set extra error message information for clone customer
        if (errorData.config && errorData.config.url.indexOf("/WebUI/Customers/ManageCustomers/CloneRates") != -1 && errorData.config.method.toLowerCase() == "put") {
            responseText += "<p>The customer has been created successfully, but we were unable to clone the transactional billing setup due to misconfigured charges in the Billing Wizard.</p>";
            responseText += "<p>Your options are as follows:</p>";
            responseText += "<ul class='error-ul-list'>";
            responseText += "<li>Set up this customer’s per transaction billing manually.</li>";
            responseText += "<li>Fix or delete the billing scripts that are not fully configured for the original customer. Deactivate the new customer, then reattempt the clone. Note that customer names must be unique, so please modify the deactivated customer’s name accordingly before reattempting the clone.</li>";
            responseText += "</ul>";
        }
        if (ErrorClass == "Operation") {
            // check orderid in Descriptors if available than we ignore Descriptors in groupby
            arrErrorDesc = _.groupBy(errorData.Descriptions, function (objDescription) {
                if (objDescription.Descriptors.join(",").indexOf("orderid") >= 0) {
                    objDescription.isOrderIds = true;
                    return objDescription.ErrorCode + "#" + objDescription.Hint;
                } else {
                    return objDescription.Descriptors + "#" + objDescription.ErrorCode + "#" + objDescription.Hint;
                }
            });
            var counter = 0;
            _.forEach(arrErrorDesc, (arrDescriptor, key) => {
                counter++;
                var index = 0;
                const errDesc = arrDescriptor[index];
                var actionName = "";
                var orderidList = [];
                // check if description have order ids than we get order ids list
                if (errDesc.isOrderIds) {
                    for (let desIndex = 0; desIndex < arrDescriptor.length; desIndex++) {
                        var descriptorList = arrDescriptor[desIndex].Descriptors;
                        for (let descriptorIndex = 0; descriptorIndex < descriptorList.length; descriptorIndex++) {
                            const arrayItem = descriptorList[descriptorIndex];
                            if (arrayItem.indexOf("orderid") >= 0) {
                                orderidList.push(JSON.parse(arrayItem).orderid);
                            }
                        }
                    }
                }
                for (let index = 0; index < errDesc.Descriptors.length; index++) {
                    const arrayItem = errDesc.Descriptors[index];
                    if (arrayItem == "ClassName") {
                        actionName = errDesc.Descriptors[index + 1];
                        break;
                    }
                }

                var string = actionName + "-" + errDesc.ErrorCode + (errDesc.Hint ? "-" + errDesc.Hint : "");
                var stringWithoutHint = actionName + "-" + errDesc.ErrorCode;
                // console.log("string :", string, stringWithoutHint);
                if (Object.prototype.hasOwnProperty.call(this.state.ErrorConfig, string)) {
                    responseText += "<p>" + this.state.ErrorConfig[string] + "</p>";
                    // WMS-16818 - manually set hint null return from api.
                    if (stringWithoutHint == "PackageDefDelete-InUse") {
                        responseText += "<p>More info: " + this.translate("Label_Delete_Package_In_Use_Hint") + "</p>";
                    }
                    if (stringWithoutHint == "ReceiverCancel-ReceiverAllocated") {
                        responseText += "<p>More info: " + this.translate("Label_Cancel_Receipt_Allocate_Hint") + "</p>";
                    }
                    if (stringWithoutHint == "OrderComplete-AlreadyCompleted") {
                        responseText += "<p>More info: " + this.translate("Label_Already_Complete_Order_Hint") + "</p>";
                    }
                } else if (Object.prototype.hasOwnProperty.call(this.state.ErrorConfig, stringWithoutHint)) {
                    responseText += "<p>" + this.state.ErrorConfig[stringWithoutHint] + "</p>";
                    if (errDesc.Hint && errDesc.ErrorCode == "ParseError") {
                        responseText += "<p>More info: " + errDesc.Hint + ".</p>";
                    }
                } else {
                    responseText += "<p>You cannot perform this " + this.convertCamelStringToNormal(actionName, false) + " because " + this.convertCamelStringToNormal(errDesc.ErrorCode, false) + ".</p>";
                    if (errDesc.Hint) {
                        responseText += "<p>More info: " + errDesc.Hint + ".</p>";
                    }
                    if (this.arrKeysNotFound.includes(string) === false) {
                        this.arrKeysNotFound.push(string);
                    }
                    this.isModalKeyNotFound = true;
                }
                if (orderidList.length) {
                    responseText += "<p>" + this.translate("Label_Transaction_Range") + " #: " + orderidList.join(", ") + "</p>";
                }
                if (counter < Object.keys(arrErrorDesc).length) {
                    responseText += "<p>----</p>";
                }
            });
            if (this.isModalKeyNotFound) {
                this.logUnhandleExceptions("missingKeyOperation", errorData);
                this.isModalKeyNotFound = false;
            }
        } else if (ErrorClass == "Authorization") {
            arrErrorDesc = _.groupBy(errorData.Descriptions, function (objDescription) {
                return objDescription.Descriptors + "#" + objDescription.ErrorCode;
            });
            var counter = 0;
            _.forEach(arrErrorDesc, (arrDescriptor, key) => {
                counter++;
                var index = 0;
                const errDesc = arrDescriptor[index];
                var string = errDesc.ErrorCode + (errDesc.Hint ? "-" + errDesc.Hint : "");
                if (Object.prototype.hasOwnProperty.call(this.state.ErrorConfig, string)) {
                    responseText += "<p>" + this.state.ErrorConfig[string] + "</p>";
                } else {
                    responseText += "<p><span style='font-weight:bold'>" + this.convertCamelStringToNormal(arrDescriptor[0].ErrorCode, true) + "</span>" + (arrDescriptor[0].Hint ? " - " + arrDescriptor[0].Hint : "") + "</p>";
                    for (let index = 0; index < arrDescriptor.length; index++) {
                        const element = arrDescriptor[index];
                        responseText += "<div style='margin-bottom: 10px;'>" + element.Descriptors.join(" ") + "</div>";

                        var strDescriptorsJoin = element.Descriptors.join(" ");
                        if (strDescriptorsJoin.indexOf("System.Exception") !== -1) {
                            strDescriptorsJoin = "";
                        }
                        var keyNotFound = element.ErrorClass + "-" + element.ErrorCode + "-" + strDescriptorsJoin;
                        if (this.arrKeysNotFound.includes(keyNotFound) === false) {
                            this.arrKeysNotFound.push(keyNotFound);
                        }
                    }
                    this.isModalKeyNotFound = true;
                }
            });
            if (this.isModalKeyNotFound) {
                this.logUnhandleExceptions("missingKeyOperation", errorData);
                this.isModalKeyNotFound = false;
            }
        } else if (ErrorClass == "ModelValidation") {
            responseText = "<p>We’ve recognized at least one invalid entry, listed below. Please review the associated fields.</p>";
            var buildCustomNotificationYn = false;
            arrErrorDesc = _.groupBy(errorData.Descriptions, function (objDescription) {
                return objDescription.ErrorCode + (objDescription.Hint ? "-" + objDescription.Hint : "");
            });
            _.forEach(arrErrorDesc, (arrDescriptor, key) => {
                // WMS-15610 changes for Hint
                if (arrDescriptor[0].Hint && arrDescriptor[0].Hint == this.translate("Label_Serial_Unique_Error_Hint")) {
                    responseText += "<p><span style='font-weight:bold'>" + this.convertCamelStringToNormal(arrDescriptor[0].ErrorCode, true) + "</span>" + " - " + this.translate("Label_Serial_Unique_Error_Message") + "</p>";
                }
                // WMS-27398 changes for Hint
                else if (arrDescriptor[0].Hint &&
                    arrDescriptor[0].Hint == this.translate("Label_Mu_Duplicate_Location") &&
                    arrDescriptor[0].Descriptors &&
                    arrDescriptor[0].Descriptors.length >= 2 &&
                    arrDescriptor[0].Descriptors[1] &&
                    arrDescriptor[0].Descriptors[1].split("=")[0]) {
                        buildCustomNotificationYn = true;
                        const locationName = arrDescriptor[0].Descriptors[1].split("=")[1] ?? "";
                        responseText += "<p><span style='font-weight:bold'>" + this.convertCamelStringToNormal(arrDescriptor[0].ErrorCode, true) + "</span> - ";
                        responseText += this.translate("Label_Multiple_Locations_ForGivenMU").replace('{loc_name}',"<span style='font-weight:bold'>'" + locationName + "'</span>") + "</p>";
                } else {
                    responseText += "<p><span style='font-weight:bold'>" + this.convertCamelStringToNormal(arrDescriptor[0].ErrorCode, true) + "</span>" + (arrDescriptor[0].Hint ? " - " + arrDescriptor[0].Hint : "") + "</p>";
                }
                if(!buildCustomNotificationYn) {
                    for (let index = 0; index < arrDescriptor.length; index++) {
                        const element = arrDescriptor[index];
                        responseText += this.getLiForModalValidation(element);
                    }
                }
                // WMS-15610 changes for Hint
                if (arrDescriptor[0].Hint && arrDescriptor[0].Hint == this.translate("Label_Serial_Unique_Error_Hint")) {
                    responseText += "<p><span style='font-weight:bold'>Hint: </span>" + arrDescriptor[0].Hint + "</p>";
                }
            });
            if (this.isModalKeyNotFound) {
                this.logUnhandleExceptions("missingKeyModelValidation", errorData);
                this.isModalKeyNotFound = false;
            }
        } else {
            arrErrorDesc = _.groupBy(errorData.Descriptions, function (objDescription) {
                return objDescription.ErrorCode + (objDescription.Hint ? "-" + objDescription.Hint : "");
            });
            _.forEach(arrErrorDesc, (arrDescriptor, key) => {
                responseText += "<p><span style='font-weight:bold'>" + this.convertCamelStringToNormal(arrDescriptor[0].ErrorCode, true) + "</span>" + (arrDescriptor[0].Hint ? " - " + arrDescriptor[0].Hint : "") + "</p>";
                for (let index = 0; index < arrDescriptor.length; index++) {
                    const element = arrDescriptor[index];
                    responseText += "<div style='margin-bottom: 10px;'>" + element.Descriptors.join(" ") + "</div>";

                    var strDescriptorsJoin = element.Descriptors.join(" ");
                    if (strDescriptorsJoin.indexOf("System.Exception") !== -1) {
                        strDescriptorsJoin = "";
                    }
                    var keyNotFound = element.ErrorClass + "-" + element.ErrorCode + "-" + strDescriptorsJoin;
                    if (this.arrKeysNotFound.includes(keyNotFound) === false) {
                        this.arrKeysNotFound.push(keyNotFound);
                    }
                }
            });
            // WMS-12016 add condition for impoert large files
            if (this.arrKeysNotFound.includes("Http-System-Maximum request length exceeded.") === true) {
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("Http-System-Maximum request length exceeded."), 1);
                responseText = this.translate("Label_large_file_import_message");
                this.setState({
                    modalTitle: this.translate("Label_large_file_import_title"),
                    isCustomeTitleSet: true,
                });
            }
            // WMS-12675 add condition for impoert balnk or 0 bytes files
            if (this.arrKeysNotFound.includes("Http-System-Minimum request length exceeded.") === true) {
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("Http-System-Minimum request length exceeded."), 1);
                responseText = this.translate("Label_small_file_import_message");
                this.setState({
                    modalTitle: this.translate("Label_small_file_import_title"),
                    isCustomeTitleSet: true,
                });
            }
            // WMS-13439 add condition for handle 503 http status
            if (this.arrKeysNotFound.includes("TaskCanceled-System-Operation timed out") === true) {
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("TaskCanceled-System-Operation timed out"), 1);
                responseText = this.translate("Label_Timeout_Error_message");
                this.setState({
                    modalTitle: this.translate("Label_Timeout_Error_title"),
                    isCustomeTitleSet: true,
                });
            }
            //WMS-16818
            if (this.arrKeysNotFound.includes("QueryParameter-Required-scannedValue") === true) {
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("QueryParameter-Required-scannedValue"), 1);
                responseText = "<p><span style='font-weight:bold'>" + this.translate("Label_Invalid_Entry") + " - </span></p>";
                responseText += this.translate("Label_Invalid_Pack_Ship_Scan_Value");
            }
            if (this.arrKeysNotFound.includes("QueryParameter-Invalid-skulist.String") === true) {
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("QueryParameter-Invalid-skulist.String"), 1);
                responseText = "<p><span style='font-weight:bold'>" + this.translate("Label_Invalid_Entry") + " - </span></p>";
                responseText += this.translate("Label_Valid_SKU");
            }
            if (this.arrKeysNotFound.includes("QueryParameter-MultipleResults-scannedValue") === true) {
                var hintData = "";
                _.forEach(errorData.Descriptions, (arrDescriptor, key) => {
                    console.log("errorData.Descriptions :", arrDescriptor);
                    hintData += arrDescriptor.Hint;
                });
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("QueryParameter-MultipleResults-scannedValue"), 1);
                responseText = this.translate("Label_Tracking_Number_In_Multiple_Transaction") + hintData;
            }
            //WMS-15354 add condition for scannedvalue not supported
            if (this.arrKeysNotFound.includes("QueryParameter-NotSupported-scannedValue") === true) {
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("QueryParameter-NotSupported-scannedValue"), 1);
                responseText = this.translate("Label_UnSupported_ScannedValue");
            }
            // WMS-16713 add condition for blank content in import file
            if (this.arrKeysNotFound.includes("QueryParameter-Required-content") === true) {
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("QueryParameter-Required-content"), 1);
                responseText = "<p><span style='font-weight:bold'>" + this.translate("Label_Required_Content_Title") + "</span></p>";
                responseText += this.translate("Label_Required_Content");
            }
            // WMS-16819 added condition for not completed order scan
            if (this.arrKeysNotFound.includes("QueryParameter-Invalid-scannedValue") === true) {
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("QueryParameter-Invalid-scannedValue"), 1);
                responseText = "<p><span style='font-weight:bold'>" + "Invalid" + "</span>" + " - " + "The scanned transaction cannot be opened. Only Complete orders can be processed in the Small Parcel space." + "</p>";
            }

            // WMS-15698
            if (this.arrKeysNotFound.some((substring) => substring.includes("Exception-System-ShipEngine Partner Account Id Missing")) === true) {
                this.setState({ showExtraDetailString: this.arrKeysNotFound[0] });
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("Exception-System-ShipEngine Partner Account Id Missing"), 1);
                responseText = this.translate("Label_TPL_Connected_ShipEngine");
            }

            // wMS-19018
            if (this.arrKeysNotFound.includes("QueryParameter-NotSupported-file") === true) {
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("QueryParameter-NotSupported-file"), 1);
                responseText = this.translate("Label_Not_Supported_File");
            }
            if (this.arrKeysNotFound.includes("QueryParameter-NotParsable-file") === true) {
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("QueryParameter-NotParsable-file"), 1);
                responseText = this.translate("Label_Not_Parsable_File");
            }
            // WMS-27356
            if (this.arrKeysNotFound.includes("QueryParameter-Required-parser") === true) {
                this.arrKeysNotFound.splice(this.arrKeysNotFound.indexOf("QueryParameter-Required-parser"), 1);
                responseText = this.translate(
                    errorData.config.url.indexOf("/WebUI/Receiving/ImportReceiptAsn/ParseAndImportFileAsyncStatus") != -1 ?
                    "Label_No_Parsers_For_Customer"
                    : "Label_Not_Supported_File" );
            }

            if (this.arrKeysNotFound.length > 0) {
                this.logUnhandleExceptions("missingKeyOther", errorData);
            }
        }

        if (!responseText && Object.prototype.hasOwnProperty.call(errorStatusName, errorData.status)) {
            responseText = errorStatusName[errorData.status];
        }

        if (!responseText) {
            responseText = this.translate("Label_Default_ErrorMsg");
        }
        return responseText;
    };
    convertCamelStringToNormal = (camel_sting, isFirstCharacterCapital) => {
        if (camel_sting.indexOf(" ") !== -1) {
            return camel_sting;
        }
        var convToNormalString = camel_sting.replace(/([A-Z])/g, " $1").trim();
        if (isFirstCharacterCapital) {
            return convToNormalString.charAt(0).toUpperCase() + convToNormalString.slice(1).toLowerCase();
        } else {
            return convToNormalString.toLowerCase();
        }
    };
    getLiForModalValidation = (errDescription) => {
        var responseText = "";
        var descriptor = errDescription.Descriptors;
        var ormOrApi = "";
        var hashNumber = !isNaN(descriptor[0]) ? "Record #" + descriptor[0] + ", " : "";
        var fieldName = "";
        // console.log ("getLiForModalValidation descriptor: ", errDescription);

        // responseText += "<p>Please review the following field(s):</p>";
        responseText = "<ol class='order-list'>";
        for (var x = 0; x < descriptor.length; x++) {
            if (descriptor[x]) {
                var descriptorName = descriptor[x].split("=")[0];
                var fieldValue = descriptor[x].split("=")[1];
                if (fieldValue) {
                    fieldValue = ": " + fieldValue;
                } else {
                    fieldValue = "";
                }
                if (descriptor[x] === "Orm" || descriptor[x] === "Api" || descriptor[x] === "Other") {
                    ormOrApi = descriptor[x];
                    continue;
                }
                if (!ormOrApi) {
                    continue;
                }

                var string = ormOrApi + ": " + descriptorName;
                if (Object.prototype.hasOwnProperty.call(this.state.ErrorConfig, string)) {
                    fieldName = this.state.ErrorConfig[string];
                } else {
                    fieldName = descriptorName;
                    if (this.arrKeysNotFound.includes(string) === false) {
                        this.arrKeysNotFound.push(string);
                    }
                    this.isModalKeyNotFound = true;
                }
                responseText += "<li>" + hashNumber + fieldName + fieldValue + "</li>";
            }
        }
        responseText += "</ol>";
        return responseText;
    };

    logUnhandleExceptions = (lavel, errorData) => {
        if (errorData && errorData.config && errorData.config.url == "/WebUI/PanelServices/BrowserTraces") {
            return false;
        }
        var errorCloneData = Object.assign({}, errorData);
        // console.log("logUnhandleExceptions : " + lavel, errorData);
        // var data = JSON.stringify(errorData);
        errorCloneData.missingkey = "missingKey";
        errorCloneData.missingkeyLavel = lavel;
        errorCloneData.activeTab = ($(".tab-list div.active h5").text() + "  " + $(".tab-list div.active p:first").text() + "  " + $(".tab-list div.active p:last").text()).trim();
        delete errorCloneData.Descriptions;
        errorCloneData.arrKeysNotFound = this.arrKeysNotFound;
        errorCloneData.currentLoginUser = GlobalService.currentLoginUser;
        GlobalService.LogBrowserTraces(JSON.stringify(errorCloneData)).then(function (response) {
            // console.log(response);
        });
    };
    closeErrorModal = () => {
        this.setState({
            showErrorPopup: false,
        });
    };
    onSendErrorReport = () => {
        console.log("onSendErrorReport call");
    };
    copyToClipboard = () => {
        var copyText = this.state.errorData.RequestorContextId;
        var textField = document.createElement("textarea");
        textField.innerText = copyText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
    };
    onExpandCollapseDetails = () => {
        if (!this.state.isErrorExpand) {
            $(".wms-error-content-details").slideDown();
        } else {
            $(".wms-error-content-details").slideUp();
        }
        this.setState({
            isErrorExpand: !this.state.isErrorExpand,
        });
    };
    errorClassTitle = (errorClass) => {
        if (this.state.isCustomeTitleSet) {
            return;
        }
        var errorData = this.state.errorData;
        var modalTitle = "";
        if (Object.prototype.hasOwnProperty.call(errorClassTitle, errorClass)) {
            modalTitle = errorClassTitle[errorClass];
        } else if (Object.prototype.hasOwnProperty.call(errorClassTitle, errorData.status)) {
            modalTitle = errorClassTitle[errorData.status];
        } else if (errorClass) {
            modalTitle = errorClass;
        } else {
            modalTitle = errorData.status;
        }
        this.setState({
            modalTitle: modalTitle,
        });
    };
    errorCodeTitle = (errorCode) => {
        if (Object.prototype.hasOwnProperty.call(errorCodeTitle, errorCode)) {
            return errorCodeTitle[errorCode];
        } else {
            return errorCode;
        }
    };
    render() {
        return (
            <div className="wms-error-wrapper">
                {this.state.showSuccessPopup && (
                    <div className="wms-notification success">
                        <div className="wms-notification-header">{this.translate("Label_Success")}</div>
                        <div className="wms-notification-content">{this.state.errorData.message}</div>
                    </div>
                )}
                {this.state.showAutoCloseWarningPopup && (
                    <div id="warningAutoCloseMessageModel" className="wms-notification warning">
                        <div className="wms-notification-header">{this.translate("Label_Error")}</div>
                        <div className="wms-notification-content">
                            {this.state.errorData.message}
                            <br /> {this.state.errorData.detail ? <label dangerouslySetInnerHTML={{ __html: this.state.errorData.detail }}></label> : ""}
                        </div>
                    </div>
                )}
                {this.state.showAutoCloseErrorPopup && (
                    <div id="errorAutoCloseMessageModel" className="wms-notification danger">
                        <div className="wms-notification-header">{this.translate("Label_Error")}</div>
                        <div className="wms-notification-content">
                            {this.state.errorData.message}
                            <br /> {this.state.errorData.detail ? <label dangerouslySetInnerHTML={{ __html: this.state.errorData.detail }}></label> : ""}
                        </div>
                    </div>
                )}
                {this.state.showErrorPopup && (
                    <div>
                        <div id="modal-backdrop" className="wms-modal-backdrop-transparent modal-transition"></div>
                        <div id="errorMessageModel" className={"wms-react-notification " + (this.state.isErrorExpand ? "expanded" : "")} data-wms-selector="wms-react-notification">
                            <div className={"wms-notification-header " + (this.state.notificationClass == "warning" ? "error-recover " : "danger")} data-wms-selector="wms-notification-header">
                                <div className="wms-notification-title" data-wms-selector="wms-notification-title">
                                    <div className="error-status" data-wms-selector="wms-notification-error-status">
                                        {this.state.modalTitle}
                                    </div>
                                </div>
                                <WmsButton label={this.state.isErrorExpand ? this.translate("Label_error_collapse_details") : this.translate("Label_error_expand_details")} onClickFunc={this.onExpandCollapseDetails} icon={this.state.isErrorExpand ? "fa-chevron-up" : "fa-chevron-down"} parentClassName={"wms-collapse-button"} wmsSelector="wms-notification-button-expand" />
                            </div>
                            <div className="wms-notification-content">
                                <div className="wms-error-content-main" data-wms-selector="wms-error-content-main" dangerouslySetInnerHTML={{ __html: this.renderErrorMessage() }}></div>
                                {/* { this.state.isErrorExpand && ( */}
                                <div className="wms-error-content-details" data-wms-selector="wms-error-content-details" style={{ display: "none" }}>
                                    <div className="wms-details-wrapper" data-wms-selector="wms-details-wrapper">
                                        <span className="wms-details-title">{this.translate("Label_Server_Name")}</span>
                                        <span className="wms-details-description">{this.state.errorData.ServerName}</span>
                                    </div>
                                    <div className="wms-details-wrapper">
                                        <span className="wms-details-title">{this.translate("Label_Requestor_Context_Id")}</span>
                                        <span className="wms-details-description">{this.state.errorData.RequestorContextId}</span>
                                    </div>
                                    {this.state.errorData.RequestId && (
                                        <div className="wms-details-wrapper">
                                            <span className="wms-details-title">{this.translate("Label_Request_Id")}</span>
                                            <span className="wms-details-description">{this.state.errorData.RequestId}</span>
                                        </div>
                                    )}
                                    {this.state.showExtraDetailString && (
                                        <div className="wms-details-wrapper">
                                            <span className="wms-details-title">{this.translate("Label_Debug_Information")}</span>
                                            <span className="wms-details-description">{this.state.showExtraDetailString}</span>
                                        </div>
                                    )}
                                    {/* <div className="wms-details-wrapper">
                                            <span className="wms-details-title">
                                                {this.translate('Label_Common_Reason')}
                                            </span>
                                            <span className="wms-details-description">
                                                {this.renderReason()}
                                            </span>
                                        </div> */}
                                </div>
                                {/* )} */}
                            </div>
                            <div className="wms-notification-footer" data-wms-selector="wms-notification-footer">
                                <div className="wmsi-button-holder">
                                    <WmsButton id="closeError" label={this.translate("Label_Close")} buttonType="orange" wmsSelector={(this.state.notificationClass == "warning" ? "error4xx" : "error5xx") + "PopupClose"} onClickFunc={this.closeErrorModal} icon="fa-times-circle-o" />
                                    {/* <WmsButton
                                        id='sendErrorReport'
                                        label={this.translate('Label_Send_Error_Report')}
                                        buttonType='green'
                                        wmsSelector={(this.state.notificationClass == 'warning' ? 'error4xx' : 'error5xx') +"ContactSupport"}
                                        onClickFunc={this.onSendErrorReport}
                                        icon='fa-paper-plane-o'/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

WmsNotification.propTypes = {};

WmsNotification.defaultProps = {};

export default WmsNotification;
