
import React from 'react';

import WmsFile from './../components/WmsFile/WmsFile.jsx'
import WmsRadio from './../components/WmsRadio/WmsRadio.tsx'
import WmsInput from './../components/WmsInput/WmsInput.jsx'
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsConfirmModel from './../components/WmsConfirmModel/WmsConfirmModel.jsx';
import * as GlobalService from "./../global_services";

var axios = require('axios');

import './themes.scss';
var file, cropper;
export class BrandingLogo extends React.Component {
    constructor(props) {
        super(props);

        
        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            gifRemoveImage: false,
            gifUploadImage: false,
            isOpenWarningModel: false,
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            selectedFileData: {},
            uploadType: 'file',
            logoUrl: '',
            imageSrc: '/WebUI/Settings/Logos/Logo'
        }
    }

    handleFileChange = (param_fileobj) => {
        console.log("param_fileobj", param_fileobj);
        this.setState({ selectedFileData: param_fileobj });
    }

    handleChangeUploadType = (param_upload_type) => {
        this.setState({ uploadType: param_upload_type });
    }

    handleUrlChange = (e) => {
        this.setState({ logoUrl: e.target.value });
    }

    uploadImage = () => {

        this.setState({ gifUploadImage: true });

        if (this.state.uploadType == 'url') {

            axios.put('/WebUI/Settings/Logos/Downloader', { "url": this.state.logoUrl }).then((response) => {

                this.setState({
                    logoUrl: '',
                    imageSrc: "/WebUI/Settings/Logos/Logo?" + new Date().getTime()
                });
                GlobalService.notification('success', this.translate('Upload_Barnding_Logo_Message'));

            }).finally(() => {
                this.setState({ gifUploadImage: false })
            });

        } else {

            axios.put('/WebUI/Settings/Logos/Logo?contentType=' + this.state.selectedFileData.ContentType, this.state.selectedFileData.FileContents).then((response) => {

                this.setState({
                    selectedFileData: {},
                    imageSrc: "/WebUI/Settings/Logos/Logo?" + new Date().getTime()
                });
                GlobalService.notification('success', this.translate('Upload_Barnding_Logo_Message'));

            }).finally(() => {
                this.setState({ gifUploadImage: false })
            });

        }
    }
    onClickApplyLogo = () => {
        $("#img-logo-upperleft").attr("src", "/WebUI/Shared/GlobalServices/Logo?" + new Date().getTime());
    }

    openConfimationModal = () => {
        this.setState({ isOpenWarningModel: true });
    }

    closeConfimationModal = () => {
        this.setState({ isOpenWarningModel: false });
    }
    removeImage = () => {

        this.setState({
            gifRemoveImage: true,
            isOpenWarningModel: false
        })

        axios.delete('/WebUI/Settings/Logos/Logo').then((response) => {

            this.setState({ imageSrc: "/WebUI/Settings/Logos/Logo?" + new Date().getTime() });
            GlobalService.notification('success', this.translate('Remove_Barnding_Logo_Message'));

        }).finally(() => {
            this.setState({ gifRemoveImage: false })
        });
    }

    render() {

        return (
            <div className="branding-logo-wraper">
                <div className="branding-image-upload">
                    <WmsRadio
                        label={this.translate("Label_Costomer_Upload_File")}
                        name="uploadUsingFile"
                        wmsSelector={"uploadUsingFile"}
                        onChangeFunc={() => this.handleChangeUploadType('file')}
                        checked={this.state.uploadType == 'file'}
                        value={this.state.uploadType}
                    />
                    <WmsRadio
                        label={this.translate("Label_Costomer_Use_URL")}
                        name="uploadUsingUrl"
                        wmsSelector={"uploadUsingUrl"}
                        onChangeFunc={() => this.handleChangeUploadType('url')}
                        checked={this.state.uploadType == 'url'}
                        value={this.state.uploadType}
                    />
                    {this.state.uploadType == 'url' && (
                        <WmsInput
                            inputName="branding-logo-upload"
                            name="branding-logo-upload"
                            wmsSelector={'brandingLogoUpload'}
                            value={this.state.logoUrl}
                            onChangeFunc={this.handleUrlChange}
                            label={this.translate('Label_Costomer_Image_url')} />
                    )}

                    {this.state.uploadType == 'file' && (
                        <WmsFile
                            id="branding-logo-upload"
                            name="attach-file-upload"
                            label={this.translate('Label_Browse')}
                            wmsSelector="attachDocFiles"
                            fileProcessType="FileSelect"
                            value={this.state.selectedFileData.fileName}
                            onChangeFunc={this.handleFileChange}
                            disabled={false}
                            clearInputOnClick={true}
                            accept={"image/png, image/jpeg"}
                        />
                    )}

                    {this.state.uploadType != '' && (
                        <WmsButton
                            label={this.translate('Label_upload')}
                            wmsSelector={'submitFile'}
                            onClickFunc={this.uploadImage}
                            icon={'fa-upload'}
                            buttonType="yellow"
                            loading={this.state.gifUploadImage}
                            disabled={this.state.gifUploadImage || ((this.state.uploadType == 'url') ? this.state.logoUrl == '' : !this.state.selectedFileData.fileName)} />
                    )}
                </div>
                <div className="branding-image-view">
                    <div className="img-logo-holder">
                        <img src={this.state.imageSrc} id="brandingImage" />
                    </div>
                    <div className="btns-holder">
                        <WmsButton
                            label={this.translate('Remove_Barnding_Logo')}
                            wmsSelector={'removeBrandingLogo'}
                            onClickFunc={this.openConfimationModal}
                            icon={'fa-trash'}
                            buttonType="red"
                            disabled={this.state.gifRemoveImage}
                            loading={this.state.gifRemoveImage} />

                        <WmsButton
                            label={this.translate('Btn_Apply_Barnding_Logo')}
                            wmsSelector={'applyBrandingLogo'}
                            onClickFunc={this.onClickApplyLogo}
                            icon={'fa-paper-plane"'}
                            buttonType="yellow" />
                    </div>
                    <div className="apply-tooltip">Apply Logo button will apply logo temporary on the upper left corner.</div>
                </div>
                <WmsConfirmModel
                    isOpen={this.state.isOpenWarningModel}
                    width={450}
                    height={175}
                    firstBtnLabel={this.translate("Label_Continue")}
                    firstBtnIcon={"fa-floppy-o"}
                    firstBtnOnClickFunc={this.removeImage}
                    firstButtonType="green"
                    isSecondBtnSaw={false}
                    sawCheckBox={false}
                    thirdBtnLabel={this.translate('Settings_Label_DiscardExit')}
                    thirdBtnIcon={"fa-times-circle"}
                    conFormModelText={this.translate("Remove_Branding_Confirm_Message")}
                    thirdButtonType="yellow"
                    onCloseFunc={this.closeConfimationModal}
                />
            </div>
        )
    }
}