import React from 'react';

//component
import WmsModel from '../../../../components/WmsModel/WmsModel.jsx';
import WmsCollapse from '../../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsButton from '../../../../components/WmsButton/WmsButton.jsx';
import WmsInput from '../../../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from '../../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsDropdownList from '../../../../components/WmsDropdownList/WmsDropdownList.jsx';
import WmsComboBox from '../../../../components/WmsComboBox/WmsComboBox.jsx';
import { ArrayWms } from '../../../../../../assets/ts/ArrayWms.ts';
import { StringWms } from '../../../../../../assets/ts/StringWms.ts';
import { ObjectWms } from '../../../../../../assets/ts/ObjectWms.ts';

import style from './add-edit-line-item.scss';

import * as addEditLineItemServices from './add-edit-line-item.services.jsx';
import * as GlobalService from '../../../../global_services';
var axios = require('axios');
export class AddEditLineItemModal extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        this.$rootScope = window;
        this.isCalcForWeight = true;
        this.defaultItemSummary = {
            "ItemId": '',
            "Sku": null,
            "Qualifier": null,
            "LotNumber": null,
            "SerialNumber": null,
            "ExpirationDate": null,
            "Notes": null,
            "FulfillInvSalePrice": 0,
            "FulfillInvDiscountPct": 0,
            "FulfillInvDiscountAmt": 0,
            "FulfillInvLineTotal": '',
            "FulfillInvLineDiscountTotal": 0,
            "FulfillInvNote": null,
            "Qty": 0,
            "PrimaryUnits": null,
            "SecondaryQty": 0,
            "SecondaryUnits": null,
            "IsPrimaryEstimate": "False",
            "IsSecondaryEstimate": "False",
            "FullyAllocated": "True",
            "CatchWeight": null,
            "Volume": 0,
            "AvailableQty": 0,
            "ItemDescription": null,
            "SavedElements": [],
            "ProposedAllocations": []
        };

        // Calculating height for modal

        let rightsArray = GlobalService.GetRights()
        let height = 720;
        if (!props.sharedSelectedCustomer.options.flexFields) {
            height = 460;
        }
        if (!(rightsArray.hasOwnProperty('Shipping.FulfillInvEnabled') && props.sharedSelectedCustomer.options.fulfillmentInvoicing == 1)) {
            height = height - 40;
        }

        this.state = {
            selector: "AddlineItem",
            Rights: rightsArray,
            customLabels: GlobalService.getCustomLabels(),
            itemSummary: props.sharedSelectedItemObj ? JSON.parse(JSON.stringify(props.sharedSelectedItemObj)) : this.defaultItemSummary,
            selectedSkuDropDown: { Id: 0 },
            showQualifier: false,
            labelSecondaryRec: "",
            labelPrimaryRec: "",
            modalFlowType: (props.sharedSelectedItemObj) ? "edit" : "create",
            lineItemSavedElementsData: (props.sharedSelectedItemObj) ?
                Object.assign([], props.sharedSelectedItemObj.SavedElements) :
                JSON.parse(JSON.stringify(props.sharedOptions.OrderLineItemPredefinedOnlySE))
            ,
            isSavedElementsDuplicatedValue: false,
            lineItemsRequiredFieldsValid: false,
            saveLineItemGrif: false,
            qualSummariesOptions: [],
            PrimaryUnitsPerSecondaryUnit:0,
            InventoryUnitsPerUnit:0,
            isAliasSku:false,
            currentMeasurementStandardIsImperial:GlobalService.getMeasurementStandardFlag(this.props.sharedSelectedFacility),
            height: height,
            isSkuImageZoome:false,
            imageSrc:"assets/images/no-image.jpg",
            imgLoading:false,
            currentCatchWeight: 0
        }
    }
    componentDidMount() {

        if (this.state.modalFlowType == "edit") {
            this.isCalcForWeight = false;
            this.getSkuInfo(this.state.itemSummary.ItemId);
            // set required validation from OrderLineItemPredefinedOnlySE
            var OrderLineItemPredefinedOnlySE = _.cloneDeep(this.props.sharedOptions.OrderLineItemPredefinedOnlySE);
            var savedElementsData = _.cloneDeep(this.state.lineItemSavedElementsData);
            var savedElementArr = [];
            if(savedElementsData.length == 0){
                savedElementArr = OrderLineItemPredefinedOnlySE;
            }else {
                savedElementArr = _.unionBy(savedElementsData, OrderLineItemPredefinedOnlySE, "Name");
                savedElementArr.filter((element) => {
                    var indexArr = _.keys(_.pickBy(OrderLineItemPredefinedOnlySE, {Name: element.Name}));
                    if(indexArr.length != 0 && OrderLineItemPredefinedOnlySE[indexArr[0]].Required){
                        element.Required = true;
                    }
                });
            }
            this.setState({lineItemSavedElementsData:savedElementArr})
        }

        if (typeof this.props.sharedOptions.OrderLineItemPredefinedOnlySE != 'undefined') {

            this.checkForSavedElementesRequired();
        }

    }

    checkForSavedElementesRequired = () => {
        let lineItemSavedElement = (
            (this.state.modalFlowType == 'create') ? this.props.sharedOptions.OrderLineItemPredefinedOnlySE :
                this.state.itemSummary.SavedElements);

        let lineItemsRequiredFieldsValid = false;

        if (!ArrayWms.isEmpty(lineItemSavedElement)) {
            for (var i = 0; i < lineItemSavedElement.length; i++) {
                if (lineItemSavedElement[i].Required == true && StringWms.isEmpty(lineItemSavedElement[i].Value)) {
                    lineItemsRequiredFieldsValid = true;
                    break;
                }
            }
        }

        this.setState({
            lineItemsRequiredFieldsValid: lineItemsRequiredFieldsValid,
            showTplSavedElements: (
                this.props.sharedOptions.ThreePlSettings != null &&
                this.props.sharedOptions.ThreePlSettings.OtherUserFeatures != null
            ) ?
                this.props.sharedOptions.ThreePlSettings.OtherUserFeatures.SavedElementsEnabled :
                false,
        });
    }

    getSkuInfo = (e) => {
        var tempItemId = e;
        let itemSummaryObj = Object.assign({}, this.state.itemSummary);

        if (e.target) {
            tempItemId = e.target.value.Id;
            if (e.target.value.Id < 0 || e.target.value.Id == "") {
                itemSummaryObj.ItemId = e.target.value.Id;
                itemSummaryObj.Sku = e.target.value.Sku;
                this.setState({
                    itemSummary: Object.assign(
                        {},
                        this.state.itemSummary,
                        itemSummaryObj
                    ),
                    selectedSkuDropDown: e.target.value,
                    isAliasSku: true,
                    showQualifier: false,
                    imageSrc: ""
                },this.nextFocusAfterSkuSelect);
                return;
            }
        }
        kendo.ui.progress($('#addEditLineItemModal'), true);
        addEditLineItemServices.SkuInfo(this.props.sharedSelectedCustomer.id, tempItemId).then((response) => {
            if (response && response.status == 200) {
                response.data.Id = response.data.ItemId;
                this.handleSkuChange(response.data);
                if(response.data._links && response.data._links[0].Href){
                    this.setState({
                        imgLoading:true,
                        imageSrc:"/WebUI/Shipping/Orders/ItemImage?itemUri="+response.data._links[0].Href
                    });
                }
            }
        }).finally(() => {
            kendo.ui.progress($('#addEditLineItemModal'), false);
        });
    }
    handleSkuChange = (skuValue) => {
        this.setState({
            showQualifier:false
        }); // hack for issue of break code when qualifier options changes
        let showQualifier = false;
        let itemSummaryObj = Object.assign({}, this.state.itemSummary);
        let PrimaryUnitsPerSecondaryUnit = 0;
        let InventoryUnitsPerUnit = 0;
        let currentCatchWeight = 0;
        let currentVolume = 0;

        if(skuValue.Options && skuValue.Options.PackageUnit){
            if(this.state.currentMeasurementStandardIsImperial && skuValue.Options.PackageUnit.Imperial){
                let imperial = skuValue.Options.PackageUnit.Imperial;
                currentCatchWeight = imperial.Weight;
                currentVolume = (imperial.Length ? imperial.Length : 0) * (imperial.Height ? imperial.Height : 0) * (imperial.Width ? imperial.Width : 0);
            }
            if(!this.state.currentMeasurementStandardIsImperial && skuValue.Options.PackageUnit.Metric){
                let metric = skuValue.Options.PackageUnit.Metric;
                currentCatchWeight = metric.Weight;
                currentVolume = (metric.Length ? metric.Length : 0) * (metric.Height ? metric.Height : 0) * (metric.Width ? metric.Width : 0);
            }
            InventoryUnitsPerUnit = skuValue.Options.PackageUnit.InventoryUnitsPerUnit;
        }   

        if (this.state.modalFlowType == 'create') {
            // itemSummaryObj = Object.assign({}, skuValue);
            itemSummaryObj.ItemId = skuValue.ItemId;
            itemSummaryObj.Sku = skuValue.Sku;
            itemSummaryObj.ItemDescription = skuValue.Description;
            itemSummaryObj.FulfillInvSalePrice = skuValue.Price;
            itemSummaryObj.Qualifier = "";
            itemSummaryObj.SecondaryQty = null;
            itemSummaryObj.PrimaryUnits = null;
            itemSummaryObj.SecondaryUnits = null;
            itemSummaryObj.CatchWeight = null;
            itemSummaryObj.Volume = null;

            if (skuValue.Options && skuValue.Options.InventoryUnit) {
                itemSummaryObj.PrimaryUnits = skuValue.Options.InventoryUnit.UnitIdentifier.Name;
            }
            if (skuValue.Options && skuValue.Options.SecondaryUnit) {
                itemSummaryObj.SecondaryUnits = skuValue.Options.SecondaryUnit.UnitIdentifier.Name;
            }
            // Pending: line total on sku change also
            itemSummaryObj.FulfillInvLineTotal = this.lineItemTotalMath(itemSummaryObj);
            // Pending: get data of weight and volumne
            // Pending: PrimaryIsIntegral flag set using sku read
            itemSummaryObj.Volume = currentVolume;
        }
        itemSummaryObj.FulfillInvSalePrice = itemSummaryObj.FulfillInvSalePrice ? itemSummaryObj.FulfillInvSalePrice.toFixed(2) : itemSummaryObj.FulfillInvSalePrice;
        currentCatchWeight = currentCatchWeight ? currentCatchWeight.toFixed(4) : currentCatchWeight;
        if (this.isCalcForWeight) {
            //--- When coming for editing we don't need to calculate it, so preventing it for one time by making it false in componentDidMount
            itemSummaryObj.CatchWeight = this.calcForWeight(itemSummaryObj, currentCatchWeight, InventoryUnitsPerUnit);
        }
        this.isCalcForWeight = true;

        itemSummaryObj.Volume = itemSummaryObj.Volume ? itemSummaryObj.Volume.toFixed(4) : itemSummaryObj.Volume;
        itemSummaryObj.Cost = skuValue.Cost;
        if (skuValue.Options && skuValue.Options.SecondaryUnit) {
            PrimaryUnitsPerSecondaryUnit = skuValue.Options.SecondaryUnit.InventoryUnitsPerUnit;
        }

        /**For set hide/show ,options and value set of qualifiers start */

        if (skuValue.Qualifiers != null &&
            skuValue.Qualifiers.length == 1 &&
            skuValue.Qualifiers[0].Qailifier == ""
        ) {
            itemSummaryObj.Qualifier = skuValue.Qualifiers[0].Qailifier;
        }
        
        if (skuValue.Id > 0 &&
            ObjectWms.doesKeyContainValidValue(skuValue.Qualifiers, "Qualifier")
        ) {
            showQualifier = true;
            if (this.state.modalFlowType == 'create')
                itemSummaryObj.Qualifier = skuValue.Qualifiers[0].Qualifier;
        }

        /**For set hide/show ,options and value set of qualifiers end */

        this.setState({
            selectedSkuDropDown: skuValue,
            showQualifier: showQualifier,
            itemSummary: Object.assign(
                {},
                this.state.itemSummary,
                itemSummaryObj
            ),
            qualSummariesOptions: skuValue.Qualifiers ? skuValue.Qualifiers : [],
            PrimaryUnitsPerSecondaryUnit: PrimaryUnitsPerSecondaryUnit,
            InventoryUnitsPerUnit: InventoryUnitsPerUnit,
            isAliasSku: false,
            currentCatchWeight:currentCatchWeight
        },this.nextFocusAfterSkuSelect);
    }
    // Focus next element after sku select
    nextFocusAfterSkuSelect = () => {
        if (this.state.showQualifier) {
            $("#QualifierDropDown").data("kendoDropDownList").focus();
        } else {
            $("#Qty").focus();
        }
    }

    handleChangeQualifiers = (event) => {
        let value = event.target.value;
        this.setState({
            itemSummary: Object.assign(
                {},
                this.state.itemSummary,
                {
                    Qualifier: value,
                }
            ),
        });
    }

    handleQtyChange = (event) => {

        var secondaryRec = "";
        var primaryRec = "";
        let value = event.target.value;
        var qty = (event.target.name == "Qty") ? value : this.state.itemSummary.Qty;
        var SecondaryQty = (event.target.name == "SecondaryQty") ? value : this.state.itemSummary.SecondaryQty;
        var tmpItemSummary = Object.assign({}, this.state.itemSummary);
        if (qty) {
            secondaryRec = qty / this.state.PrimaryUnitsPerSecondaryUnit;
            // if (secondaryRec % 1 != 0 && this.state.itemSummary.SecondaryIsIntegral) {
            if (secondaryRec % 1 != 0 && GlobalService.getIsIntegeralFlagByType(this.state.itemSummary.SecondaryUnits)) {
                secondaryRec = Math.ceil(secondaryRec);
            }
        }

        if (SecondaryQty) {
            primaryRec = SecondaryQty * this.state.PrimaryUnitsPerSecondaryUnit;
            // if (primaryRec % 1 != 0 && this.state.itemSummary.PrimaryIsIntegral) {
            if (primaryRec % 1 != 0 && GlobalService.getIsIntegeralFlagByType(this.state.itemSummary.PrimaryUnits)) {
                primaryRec = Math.ceil(primaryRec);;
            }
        }

        tmpItemSummary[event.target.name] = value;
        var passObj = Object.assign({}, tmpItemSummary);
        if (event.target.name == "Qty") {
            if (!passObj.Qty) {
                passObj.Qty = primaryRec;
            }
            tmpItemSummary.FulfillInvLineTotal = this.lineItemTotalMath(passObj);
        }
        if (event.target.name == "SecondaryQty") {
            if (!this.state.itemSummary.Qty) {
                passObj.Qty = primaryRec;
            }
            tmpItemSummary.FulfillInvLineTotal = this.lineItemTotalMath(passObj);
        }
        // Calculation for Weight
        tmpItemSummary.CatchWeight = this.calcForWeight(passObj, this.state.currentCatchWeight, this.state.InventoryUnitsPerUnit);
        this.setState({
            labelPrimaryRec: primaryRec,
            labelSecondaryRec: secondaryRec,
            itemSummary: tmpItemSummary
        });

    }
    calcForWeight = (param_itemobj, currentCatchWeight, InventoryUnitsPerUnit) => {
        console.log("calcForWeight: ", param_itemobj.Qty, currentCatchWeight, InventoryUnitsPerUnit);
        var weight = param_itemobj.Qty * (currentCatchWeight / InventoryUnitsPerUnit);
        return (weight ? weight.toFixed(4) : 0.0000);
    }
    lineItemTotalMath = (param_itemobj) => {
        var lineTotal = parseFloat(param_itemobj.Qty) * parseFloat(param_itemobj.FulfillInvSalePrice);
        if (param_itemobj.FulfillInvDiscountAmt) {
            lineTotal = lineTotal - parseFloat(param_itemobj.FulfillInvDiscountAmt);
        } else if (param_itemobj.FulfillInvDiscountPct) {
            lineTotal = (lineTotal - ((lineTotal * parseFloat(param_itemobj.FulfillInvDiscountPct)) / 100));
        }
        if(!lineTotal) {
            return "0.00";
        }
        return lineTotal.toFixed(2);
    }

    handleItemObjChange = (event) => {
        let tmpItemSummary = Object.assign({}, this.state.itemSummary);
        tmpItemSummary[event.target.name] = event.target.value;
        if (event.target.name == 'FulfillInvDiscountAmt') {
            let lineItemTotalTemp = parseFloat(tmpItemSummary.Qty) * parseFloat(tmpItemSummary.FulfillInvSalePrice);
            if(
                !tmpItemSummary.FulfillInvDiscountAmt ||
                // parseInt(tmpItemSummary.FulfillInvDiscountAmt) <= parseInt(tmpItemSummary.FulfillInvSalePrice)
                parseFloat(tmpItemSummary.FulfillInvDiscountAmt) <= parseFloat(lineItemTotalTemp)
            ){
                tmpItemSummary.FulfillInvDiscountPct = null;
                tmpItemSummary.FulfillInvLineTotal = this.lineItemTotalMath(tmpItemSummary);
            }else{
                tmpItemSummary.FulfillInvDiscountAmt = this.state.itemSummary.FulfillInvDiscountAmt;
            }
        } else if (event.target.name == 'FulfillInvDiscountPct') {
            if(tmpItemSummary.FulfillInvDiscountPct > 100){
                tmpItemSummary.FulfillInvDiscountPct = this.state.itemSummary.FulfillInvDiscountPct;
            }
            tmpItemSummary.FulfillInvDiscountAmt = null;
            tmpItemSummary.FulfillInvLineTotal = this.lineItemTotalMath(tmpItemSummary);
        } else if (event.target.name == "FulfillInvSalePrice" && event.target.value && tmpItemSummary.Qty) {
            tmpItemSummary.FulfillInvLineTotal = this.lineItemTotalMath(tmpItemSummary);
        }
        this.setState({
            itemSummary: tmpItemSummary
        });
    }

    handleSavedElementChange = (param_index) => (e) => {
        let savedElement = Object.assign([], this.state.lineItemSavedElementsData);
        savedElement[param_index][e.target.name] = e.target.value;
        let tmpIsDuplucatedValue = false;
        let lineItemsRequiredFieldsValid = false;

        _.map(savedElement, function (item) {
            if (_.filter(savedElement, { Name: item.Name }).length > 1 && item.Name) {
                tmpIsDuplucatedValue = true;
            }
            if (item.Required && !item.Value) {
                lineItemsRequiredFieldsValid = true;
            }
        });

        this.setState({
            lineItemSavedElementsData: savedElement,
            isSavedElementsDuplicatedValue: tmpIsDuplucatedValue,
            lineItemsRequiredFieldsValid: lineItemsRequiredFieldsValid
        });
    }
    manageSavedElement = (param_index) => (e) => {
        let savedElement = this.state.lineItemSavedElementsData;
        let tmpIsDuplucatedValue = false;

        if (typeof param_index != 'undefined') {
            savedElement.splice(param_index, 1);
            _.map(savedElement, function (item) {
                if (_.filter(savedElement, { Name: item.Name }).length > 1 && item.Name) {
                    tmpIsDuplucatedValue = true;
                }
            });
        } else {
            savedElement.push({
                Name: "",
                Required: false,
                Value: null
            });
            tmpIsDuplucatedValue = this.state.isSavedElementsDuplicatedValue;
        }
        this.setState({
            lineItemSavedElementsData: savedElement,
            isSavedElementsDuplicatedValue: tmpIsDuplucatedValue
        });
    }
    // Focus next element after sku select
    nextFocusAfterCarrierSelect = () => {
        $("#Qty").focus();
    }

    orderItemsGridInsert = () => {
        this.setState({
            saveLineItemGrif: true,
        });

        var tmpItemSummaryObj = Object.assign({}, this.state.itemSummary);
        var qty = this.state.itemSummary.Qty;
        var SecondaryQty = this.state.itemSummary.SecondaryQty;
        if (this.state.PrimaryUnitsPerSecondaryUnit) {
            if (this.state.itemSummary.Qty) {
                qty = this.state.itemSummary.Qty;
            } else {
                qty = this.state.itemSummary.SecondaryQty * this.state.PrimaryUnitsPerSecondaryUnit;
                if (qty % 1 != 0 && GlobalService.getIsIntegeralFlagByType(this.state.itemSummary.PrimaryUnits)) {
                    qty = Math.ceil(qty);;
                }
            }
            if (this.state.itemSummary.SecondaryQty) {
                SecondaryQty = this.state.itemSummary.SecondaryQty;
            } else {
                SecondaryQty = this.state.itemSummary.Qty / this.state.PrimaryUnitsPerSecondaryUnit;
                if (SecondaryQty % 1 != 0 && GlobalService.getIsIntegeralFlagByType(this.state.itemSummary.SecondaryUnits)) {
                    SecondaryQty = Math.ceil(SecondaryQty);;
                }
            }
        }
        tmpItemSummaryObj.Qty = qty;
        tmpItemSummaryObj.SecondaryQty = SecondaryQty;
        tmpItemSummaryObj.Qualifier = this.state.itemSummary.Qualifier;

        var savedElements = Object.assign([],this.state.lineItemSavedElementsData);
        // Remove if name anf value is blank
        _.remove(savedElements, function(n) {
            return (!n.Name || !n.Value);
        });
        tmpItemSummaryObj.SavedElements = savedElements;
        var params = {
            orderId: this.props.shareOrderData.OrderId,
            inventorySummaryUri: this.props.shareOrderData.InventorySummariesUri,
        }
        kendo.ui.progress($('.' + this.props.windowId + "#OrderItemsGrid"), true);
        if (this.state.modalFlowType == "edit") {
            delete tmpItemSummaryObj.FullfillInvTotalAfterDiscount;
            delete tmpItemSummaryObj.FullfillInvTotalPrice;
            delete tmpItemSummaryObj.OriginalQty;
            params.itemToUpdate = tmpItemSummaryObj;
            addEditLineItemServices.editLineItem(params).then((response) => {
                this.setState({ saveLineItemGrif: false });
                if (response != 'undefined' && (response.status == 200 || response.status == 204)) {
                    this.afterAddEditLineItem();
                    this.props.onGridItemChange();
                }
            }).finally(() => {
                this.setState({ saveLineItemGrif: false });
            });
        } else {
            params.itemToAdd = tmpItemSummaryObj;
            addEditLineItemServices.addLineItem(params).then((response) => {
                this.setState({ saveLineItemGrif: false });
                if (response != 'undefined' && (response.status == 200 || response.status == 204)) {
                    this.afterAddEditLineItem();
                    this.props.onGridItemChange();
                }
            }).finally(() => {
                this.setState({ saveLineItemGrif: false });
            });
        }
    }
    afterAddEditLineItem = () => {
        var grid = $('.' + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');
        var dataSource = grid.dataSource;
        dataSource.read();
        grid.refresh();
        let isFullyAllocated = true;
        this.setState({
            selectedLineItemsCount: 0,
            itemSummary: Object.assign(
                {},
                this.state.itemSummary,
                {
                    Qty: null,
                    SecondaryQty: null,
                    PrimaryUnits: null,
                    SecondaryUnits: null
                }
            ),
            selectedSkuDropDown: {
                Id: 0
            },
        });

        this.props.onResetGridAndSelection();

        var gridData = grid.dataSource.data();
        var allocatedSkuList = "";
        for (var i = 0; i < gridData.length; i++) {
            if (gridData[i].FullyAllocated == false) {
                isFullyAllocated = false;
                allocatedSkuList += "<p>" + gridData[i].Sku + "</p>";
            }
        }
        this.props.onItemAllocationChanged({
            'isFullyAllocated': isFullyAllocated,
            "allocatedSkuList": allocatedSkuList,
            'sawDiscardbutton': true
        });
        kendo.ui.progress($('.' + this.props.windowId + "#OrderItemsGrid"), false);
        this.setState({
            isFullyAllocated: isFullyAllocated
        });
        let msg = (this.state.modalFlowType == "edit") ? this.translate('Label_Update_Line_Item_Success') : this.translate('Label_Add_Line_Item_Success');
        GlobalService.notification('success', msg);
        if (this.state.modalFlowType == "edit") {
            this.props.onCloseAddEditLineItemModal();
        } else {
            // Clear form and focus on sku fields
            this.setState({
                itemSummary: this.defaultItemSummary,
                lineItemSavedElementsData: JSON.parse(JSON.stringify(this.props.sharedOptions.OrderLineItemPredefinedOnlySE))
            });
            var SkuDropDownList = $('#SkuDropDownList').data("kendoDropDownList");
            if (typeof SkuDropDownList != "undefined") {
                SkuDropDownList.select(0);
                SkuDropDownList.trigger("change");
                this.checkForSavedElementesRequired();
                // Focus on first input
                $("#SkuDropDownList").data("kendoDropDownList").focus()
            }

        }
    }
    fixedPointOnBlur = (fixedNumber) => (e) => {
        if(e.target.value){
            let tmpItemSummary = Object.assign({}, this.state.itemSummary);
            tmpItemSummary[e.target.name] = parseFloat(e.target.value).toFixed(fixedNumber);
            this.setState({
                itemSummary: tmpItemSummary
            });
        }
    }
    openImageZommedModal = () =>{
        this.setState({ isSkuImageZoome: true });
    }
    closeImageZommedModal = () =>{
        this.setState({ isSkuImageZoome: false });
    }
    setDefaultImage = () => {
        this.setState({ imageSrc:"assets/images/no-image.jpg", imgLoading:false });
    }
    loadImage = () => {
        this.setState({imgLoading:false});
    }
    render() {
        return (
            <WmsModel
                id={"addEditLineItemModal"}
                class="add-line-item-modal-wrapper"
                title={
                    (this.state.modalFlowType == "create") ?
                        this.translate('Label_Add_Line_Item_Model') :
                        this.translate('Label_Edit_Order_Line_Item_Model')
                }
                width={820}
                onCloseFunc={this.props.onCloseAddEditLineItemModal}
                height={this.state.height}
                isOpen={this.props.sharedIsOpenAddLineItem}
                customClass='wms-model-new-ui-wrapper' >
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'shipAndCloseGeneral'}
                        headerLabel={this.translate('Label_General')}
                        showCollapseText={false} >
                        <div className="wms-warning-wrapper">
                            <div className="wms-sprite fa fa-exclamation-triangle"></div>
                            <span>
                                {this.translate('Label_warning_for_total_weight_and_volume_add_edit')}
                            </span>
                        </div>
                        <div className="content-wrapper">
                            <div className="image-content-wrapper">
                            <div className="form-image-left-side">
                            <div className="inline-elements">
                                {this.state.modalFlowType == "create" ?
                                    <WmsDropdownList
                                        id="SkuDropDownList"
                                        name="SkuDropDownList"
                                        label={this.state.customLabels.Label_Sku}
                                        optionsLabel={this.translate('Label_Select_Sku')}
                                        wmsSelector={this.state.selector + "SkuDropDown"}
                                        onChangeFunc={this.getSkuInfo}
                                        textField="Sku"
                                        valueField="Id"
                                        virtualObj={{ itemHeight: 26 }}
                                        showDetailsTooltip={true}
                                        windowId={this.props.windowId}
                                        parentclassName="form-group"
                                        template="<span style='white-space: nowrap;text-overflow: ellipsis;width: 210px;display: block;overflow: hidden'>#if (Description == null) {# #: Sku# #}else{# #:Sku# - #:Description#  #}#</span>"
                                        extraWrapperClass="width-35 sku-dropdown-wrapper"
                                        dataSourceObj={{
                                            pageSize: 40,
                                            serverPaging: true,
                                            serverFiltering: true,
                                            type: "aspnetmvc-ajax",
                                            transport: {
                                                read: {
                                                    url: "/WebUI/Shipping/Orders/SkuAndAliasDropdownRead",
                                                    type: "POST",
                                                    data: {
                                                        customerUri: this.props.sharedSelectedCustomer.uri
                                                    }
                                                },
                                            },
                                            schema: {
                                                data: "Data",
                                                total: "Total"
                                            }
                                        }} /> :
                                    <WmsInput
                                        id={'disableSku'}
                                        inputName='disableSku'
                                        wmsSelector={this.state.selector + 'disableSku'}
                                        onChangeFunc={() => { }}
                                        disabled={true}
                                        label={this.state.customLabels.Label_Sku}
                                        value={this.state.itemSummary.Sku}
                                        extraWrapperClass="width-40" />
                                }

                                {this.state.showQualifier == true &&
                                    <WmsDropdown
                                        id={'QualifierDropDown'}
                                        name='Qualifier'
                                        wmsSelector={this.state.selector + 'QualifierDropDown'}
                                        options={this.state.qualSummariesOptions}
                                        value={this.state.itemSummary.Qualifier}
                                        onChangeFunc={this.handleChangeQualifiers}
                                        label={this.translate('Label_Qualifier')}
                                        valueField={'Qualifier'}
                                        textField={'Qualifier'}
                                        nextFocus={this.nextFocusAfterCarrierSelect}
                                        setKendoDropdown={true}
                                        disabled={this.state.modalFlowType == 'edit' ? true : false}
                                        extraWrapperClass="width-30 " />
                                }

                                {this.state.selectedSkuDropDown.Id != 0 &&
                                    <WmsInput
                                        id={'Qty'}
                                        inputName='Qty'
                                        wmsSelector={this.state.selector + 'Qty'}
                                        onChangeFunc={this.handleQtyChange}
                                        label={this.translate('Label_Quantity') + (this.state.itemSummary.PrimaryUnits ? " (" + this.state.itemSummary.PrimaryUnits + ") " : "") + (this.state.labelPrimaryRec ? this.translate('Label_Recommend') + ": " + (this.state.labelPrimaryRec) : "")}
                                        value={this.state.itemSummary.Qty}
                                        required={(!this.state.itemSummary.Qty && !this.state.itemSummary.SecondaryQty)}
                                        parentclassName="form-group"
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                        extraWrapperClass="width-30"/>
                                }

                                {this.state.itemSummary.SecondaryUnits != null &&
                                    <WmsInput
                                        id='SecondaryQty'
                                        inputName='SecondaryQty'
                                        wmsSelector={this.state.selector + 'SecondaryQty'}
                                        onChangeFunc={this.handleQtyChange}
                                        label={this.translate('Label_Secondary_Quantity') + (this.state.itemSummary.SecondaryUnits ? " (" + this.state.itemSummary.SecondaryUnits + ") " : "") + (this.state.labelSecondaryRec ? this.translate('Label_Recommend') + ": " + (this.state.labelSecondaryRec) : "")}
                                        value={this.state.itemSummary.SecondaryQty}
                                        parentclassName="form-group"
                                        required={(!this.state.itemSummary.Qty && !this.state.itemSummary.SecondaryQty)}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                        extraWrapperClass="width-30" />
                                }
                            </div>
                            {/* <div className="inline-elements"> */}
                                {/* <WmsInput
                                    id='cost'
                                    inputName='cost'
                                    wmsSelector={this.state.selector + 'cost'}
                                    onChangeFunc={this.handleItemObjChange}
                                    label={this.translate('Label_Cost')}
                                    value={this.state.itemSummary.Cost}
                                    disabled={true}
                                    extraWrapperClass="width-20" /> */}
                            {(
                                this.state.Rights.hasOwnProperty('Shipping.FulfillInvEnabled') && this.props.sharedSelectedCustomer.options.fulfillmentInvoicing == 1
                            ) && (
                                <div>
                                <WmsInput
                                    id='FulfillInvSalePrice'
                                    inputName='FulfillInvSalePrice'
                                    wmsSelector={this.state.selector + 'price'}
                                    onChangeFunc={this.handleItemObjChange}
                                    disabled={this.state.isAliasSku}
                                    label={this.translate('Label_Price')}
                                    value={this.state.itemSummary.FulfillInvSalePrice}
                                    extraWrapperClass="width-20"
                                    extraSignBeforeValue={'$'}
                                    regularExp={/^[+]?([0-9]+([.]?)(\d?\d?)?)$/}
                                    valueType="number"
                                    onBlurFunc={this.fixedPointOnBlur(2)}/>
                                <WmsInput
                                    id='FulfillInvDiscountAmt'
                                    inputName='FulfillInvDiscountAmt'
                                    wmsSelector={this.state.selector + 'discountAmount'}
                                    onChangeFunc={this.handleItemObjChange}
                                    label={this.translate('Label_Discount_Amount')}
                                    value={this.state.itemSummary.FulfillInvDiscountAmt}
                                    disabled={this.state.isAliasSku}
                                    extraWrapperClass="width-20"
                                    extraSignBeforeValue={'$'}
                                    regularExp={/^[+]?([0-9]+([.]?)(\d?\d?)?)$/}
                                    valueType="number"
                                    onBlurFunc={this.fixedPointOnBlur(2)}/>
                                <WmsInput
                                    id='FulfillInvDiscountPct'
                                    inputName='FulfillInvDiscountPct'
                                    wmsSelector={this.state.selector + 'FulfillInvDiscountPct'}
                                    onChangeFunc={this.handleItemObjChange}
                                    // extraSignBeforeValue={'% '}
                                    label={this.translate('Label_Discount_Percent')}
                                    disabled={this.state.isAliasSku}
                                    value={this.state.itemSummary.FulfillInvDiscountPct}
                                    extraWrapperClass="width-20"
                                    regularExp={/^[+]?([0-9]+([.]?)(\d?\d?)?)$/}
                                    onBlurFunc={this.fixedPointOnBlur(2)}
                                />
                                <WmsInput
                                    id='FulfillInvLineTotal'
                                    inputName='FulfillInvLineTotal'
                                    wmsSelector={this.state.selector + 'FulfillInvLineTotal'}
                                    value={this.state.itemSummary.FulfillInvLineTotal}
                                    label={this.translate('Label_Line_Total')}
                                    onChangeFunc={() => { }}
                                    extraSignBeforeValue={'$'}
                                    regularExp={/^[+]?([0-9]+([.]?)(\d?\d?)?)$/}
                                    disabled={true}
                                    extraWrapperClass="width-20"
                                    zeroBasedCondition={2} />
                                </div>
                            )}
                            {/* </div> */}
                            <div>
                                <WmsInput
                                    id='primaryUnits'
                                    inputName='PrimaryUnits'
                                    wmsSelector={this.state.selector + 'primaryUnits'}
                                    onChangeFunc={() => { }}
                                    label={this.translate('Label_Line_Item_Beta_Primary_Unit')}
                                    value={this.state.itemSummary.PrimaryUnits}
                                    disabled={true}
                                    extraWrapperClass="width-20" />
                                <WmsInput
                                    id='CatchWeight'
                                    inputName='CatchWeight'
                                    wmsSelector={this.state.selector + 'weight'}
                                    onChangeFunc={this.handleItemObjChange}
                                    label={this.translate('Label_Weight') + ' (' + (
                                        this.state.currentMeasurementStandardIsImperial ?
                                            this.translate('Label_Imperial_Weight')
                                            :
                                            this.translate('Label_Metric_Weight')
                                        )+')'
                                    }
                                    value={this.state.itemSummary.CatchWeight}
                                    regularExp={/^[+]?([0-9]+([.]?)(\d?\d?\d?\d)?)$/}
                                    extraWrapperClass="width-15"
                                    onBlurFunc={this.fixedPointOnBlur(4)} />
                                <WmsInput
                                    id='volume'
                                    inputName='volume'
                                    wmsSelector={this.state.selector + 'volume'}
                                    onChangeFunc={() => { }}
                                    label={this.translate('Label_Volume')+ ' (' + (
                                        this.state.currentMeasurementStandardIsImperial ?
                                            this.translate('Label_Line_Item_Beta_Imperial_Volume')
                                            :
                                            this.translate('Label_Line_Item_Beta_Metric_volume')
                                        )+')'
                                    }
                                    value={this.state.itemSummary.Volume}
                                    disabled={true}
                                    regularExp={/^[+]?([0-9]+([.]?)(\d?\d?\d?\d)?)$/}
                                    extraWrapperClass="width-15"
                                    onBlurFunc={this.fixedPointOnBlur(4)} />
                                {/* <WmsInput
                                    id='SecondaryUnits'
                                    inputName='SecondaryUnits'
                                    wmsSelector={this.state.selector + 'SecondaryUnits'}
                                    onChangeFunc={() => { }}
                                    label={this.translate('Label_Order_Unit')}
                                    value={this.state.itemSummary.SecondaryUnits}
                                    disabled={true}
                                    extraWrapperClass="width-20" /> */}
                            </div>
                            </div>
                            {this.state.Rights.hasOwnProperty('Items.ItemImagesEnabled') && (
                            <div className="form-image-right-side">
                                <img
                                src={this.state.imageSrc}
                                onClick={
                                    (this.state.imageSrc.indexOf("assets/images/no-image.jpg") !== -1) ?
                                    () => {} :
                                    this.openImageZommedModal
                                }
                                onLoad={this.loadImage}
                                onError={this.setDefaultImage}/>
                                {this.state.imgLoading &&(
                                    <div className="wms_sprite btnloader fa fa-spinner fa-spin"></div>
                                )}
                            </div>
                            )}
                            {this.state.isSkuImageZoome && (
                            <WmsModel
                                id={"ZommedImageModel"}
                                class="zommed-image-model"
                                title={this.state.itemSummary.Sku + " Image"}
                                width={470}
                                height={470}
                                onCloseFunc={this.closeImageZommedModal}
                                isOpen={this.state.isSkuImageZoome}
                                actions={["Maximize", "Close"]}
                                isAnotherModelOpen={true}>
                                    <div className="image-wrapper">
                                        <img id="itemImage" src={this.state.imageSrc} />
                                    </div>
                            </WmsModel>
                            )}
                            </div>
                            <div>
                                <WmsInput
                                    id='ItemDescription'
                                    inputName='ItemDescription'
                                    wmsSelector={this.state.selector + 'ItemDescription'}
                                    onChangeFunc={() => { }}
                                    label={this.translate('Label_Description')}
                                    value={this.state.itemSummary.ItemDescription}
                                    disabled={true}
                                    extraWrapperClass="width-100" />
                            </div>
                        </div>
                    </WmsCollapse>
                    {this.props.sharedSelectedCustomer.options.flexFields && (
                    <WmsCollapse id={'addLineItemCustomFields'}
                        headerLabel={this.translate('Label_custom_fields')}
                        showCollapseText={false}>

                        {this.state.showTplSavedElements == true &&
                            (
                                <div className={"content-wrapper"}>
                                    <div className="custom-field-error-msg">
                                        {this.state.isSavedElementsDuplicatedValue && (
                                            <div>
                                                <div className="wms-sprite fa fa-ban"></div>
                                                <span>
                                                    {this.translate("Label_Saved_Element_Diplicate_Tooltip")}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    {this.state.lineItemSavedElementsData.map((Option, index) => (
                                            <div className="inline-elements" key={"savedelement_" + index + "_key"}>
                                                <WmsComboBox
                                                    id={'CustomFieldName' + (index + 1)}
                                                    name='Name'
                                                    wmsSelector={this.state.selector + 'CustomName'}
                                                    options={this.props.sharedOptions.OrderLineItemPredefinedOnlySE ? this.props.sharedOptions.OrderLineItemPredefinedOnlySE : []}
                                                    value={Option.Name}
                                                    valueField='Name'
                                                    textField='Name'
                                                    onChangeFunc={this.handleSavedElementChange(index)}
                                                    placeholder={this.translate('Label_Combo_Enter_Name')}
                                                    label={this.translate('Label_Name') + ((Option.Required) ? "*" : "")}
                                                    parentClassName="width-35" />
                                                <WmsInput
                                                    id='savedElementValue'
                                                    inputName='Value'
                                                    wmsSelector={this.state.selector + 'savedElementValue'}
                                                    onChangeFunc={this.handleSavedElementChange(index)}
                                                    label={this.translate('Label_Value')}
                                                    value={Option.Value}
                                                    parentclassName="form-group"
                                                    required={Option.Required}
                                                    extraWrapperClass="width-60" />
                                                <div
                                                    className={(Option.Required ? 'opacity-content' : '') + " clear-icon"}
                                                    onClick={this.manageSavedElement(index)}>
                                                    <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <WmsButton
                                        id={this.state.selector + "Add"}
                                        name={this.state.selector + "Add"}
                                        label={this.translate("Label_Add")}
                                        wmsSelector={this.state.selector + "ButtonAdd"}
                                        onClickFunc={this.manageSavedElement()}
                                        icon="fa-plus"
                                        loading={false}
                                        parentClassName='center-content'
                                    />
                                </div>
                            )}
                    </WmsCollapse>
                    )}
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        id={this.state.selector + "close"}
                        name={this.state.selector + "close"}
                        label={this.translate("Label_Close")}
                        wmsSelector={this.state.selector + "ButtonClose"}
                        onClickFunc={this.props.onCloseAddEditLineItemModal}
                        icon="fa-ban"
                        buttonType="orange"
                        loading={false}
                    />
                    <WmsButton
                        label={this.state.modalFlowType == "edit" ? this.translate("Label_Save") : this.translate("Label_Add")}
                        wmsSelector={this.state.selector + "save"}
                        onClickFunc={this.orderItemsGridInsert}
                        icon={this.state.modalFlowType == "edit" ? "fa-floppy-o":"fa-plus"}
                        disabled={
                            (this.state.selectedSkuDropDown.Id == 0) ||
                            !(this.state.itemSummary.Qty || this.state.itemSummary.SecondaryQty) ||
                            (
                                this.props.sharedSelectedCustomer.options.flexFields && this.state.lineItemsRequiredFieldsValid
                            )||
                            this.state.isSavedElementsDuplicatedValue
                        }
                        loading={this.state.saveLineItemGrif}
                    />
                </div>
            </WmsModel>
        )
    }
}