/* global _, kendo, BrowserPrint, toastr, globalsMsg */
/* eslint no-prototype-builtins: "off", no-redeclare: "off" */
import React, {useRef, useCallback, useEffect} from 'react';
import {
    StringWms
} from '../../assets/ts/StringWms.ts';
import {
    ArrayWms
} from '../../assets/ts/ArrayWms.ts';

import
    KeyedCollectionWms
 from '../../assets/ts/KeyedCollectionWms.ts';
import _ from 'lodash';

var axios = require('axios');
var PossibleWindows = 8;
var isPrivateWindow = false;
export var Rights = {};
export var DefaultRights = {};
export var windowData = {};
export var currentActiveView = "wms";
for (var x = 1; x <= PossibleWindows; x++) {
    windowData["Window" + x] = {};
}
export var Modal1 = {};
export var Modal2 = {};
export var WMSModelMinimisePlaceObj = {};

var countries = new KeyedCollectionWms();
var countriesRegions = new KeyedCollectionWms();
export var CurrentUser;
export var userKeysUrlStringForDashboard = '';
export var userKeysUrlStringForDashboardPost = {};
export var currentLoginUser;
export var whoseLineIsItAnywaySessionId;
var unitOfMeasureTypesOptions;
export var Customers = {
    Customer: [],
    Facility: [],
    FacilityDict: {},
    CustomerDict: {},
    LastModified: "",
    SelectedCustomerId: 0,
    SelectedFacilityId: 0
}
var facilityList = [];
var tplSettings = [];
var customLabel;
export const constants = {
    virtualizedGridLimit: 2500,
    gridExportLimit: 5000,
    virtualOrderGridDataLimit: 4000,
    virtualGridDataLimit: 2000,
    virtualReceiptGridDataLimit: 1000,
    virtualSmallParcelGridDataLimit: 4000,
    virtualSmallParcelGridPageSize: 2000
}
export const PreLoad = function () {
    try {
        window.localStorage.setItem("temp",2);
        isPrivateWindow = false;
    } catch(e) {
        isPrivateWindow = true;
    }
    var languageToLoad = isNotIframe() ? window.localStorage.getItem('language') : null;
    if (!languageToLoad) {
        // if local storage not available get language from browser settings
        languageToLoad = navigator.language || navigator.userLanguage ;
    }
    languageToLoad = languageToLoad ? languageToLoad : "en-US";
    return axios.all([
        axios.get('/WebUI/Shared/GlobalServices/GetRights'),
        axios.get('/WebUI/Shared/GlobalServices/GetCountries'),
        axios.get('/WebUI/Shared/GlobalServices/LoggedInUserInfo'),
        axios.get('/WebUI/Shared/GlobalServices/ThreePlOptions'),
        axios.get('/WebUI/Shared/GlobalServices/UnitOfMeasureTypes'),
        axios.post('/WebUI/Shared/GlobalServices/GetCustomLabels', {
            locale: languageToLoad
        }),
        axios.get('/WebUI/Shared/GlobalServices/GetCustomerFacilityList?active=true'),
        axios.get('/WebUI/Shared/GlobalServices/WhoseLineIsItAnyway'),
        axios.get('/WebUI/Shared/GlobalServices/UserClientMetaData?name="NavBar"')
    ]).then(axios.spread(function (rightsResponse, countriesResponse, loginUserInfoResponse, tPlOptionsResponse, UnitOfMeasureTypesResponse, customLabelResponse,customerFacilityListResponse, whoseLineIsItAnywayResponse, navbarMetaResponse) {
        SetRights(rightsResponse.data);
        SetCountries(countriesResponse.data);
        setTplSettings(tPlOptionsResponse.data);
        setUnitOfMeasureTypes(UnitOfMeasureTypesResponse.data);
        setCustomerFacilityListResult(customerFacilityListResponse);
        if(navbarMetaResponse.data){
            var responseJson = JSON.parse(navbarMetaResponse.data);
            currentActiveView = responseJson['CurrentView'];
        }
        customLabel = customLabelResponse.data;
        whoseLineIsItAnywaySessionId = whoseLineIsItAnywayResponse.data;
        SetCurrentUser(loginUserInfoResponse.data);
        let data = {
            messageCount: 5
        };

        /**Code for react progress component */
        var event = new CustomEvent("showNewMessageAlert", {
            detail: data,
        });
        document.dispatchEvent(event);
        return tPlOptionsResponse.data;
    }));
}

export const updateModelMinimisePlace = function (modelId, page, toAdd) {
    var currentMinimisedObj = Object.assign({},WMSModelMinimisePlaceObj);
    console.log("addModelMinimiseCount", page, modelId, currentMinimisedObj);

    if(currentMinimisedObj[page] == undefined && !Array.isArray(currentMinimisedObj[page])){
        currentMinimisedObj[page] = [];
    }
    if(toAdd){
        currentMinimisedObj[page].push(modelId);
    }else{
        currentMinimisedObj[page] = currentMinimisedObj[page].filter(el => el != modelId);
        rePosMinimisedModelsLeft(page, currentMinimisedObj);
    }
    WMSModelMinimisePlaceObj = currentMinimisedObj;
}
const rePosMinimisedModelsLeft = function(page, currentMinimisedObj) {
    currentMinimisedObj[page].map((modelId, index) => {
        console.log("rePosMinimisedModelsLeft => ",modelId, index, index * (350+30) + 30);
        var modelObj = $("#" + modelId).data("kendoWindow");
        if (modelObj) {
            modelObj.wrapper.css({
                left: index * (350+30) + 30,
            });
        }
    });
}
export const minimiseModelAsCurrentWindow = function(modelId, page) {
    var currentMinimisedObj = Object.assign({},WMSModelMinimisePlaceObj);
    var modelObj = $("#" + modelId).data("kendoWindow");
    var ModelLeftPos = leftCalculateForMinimiseModel(page, currentMinimisedObj)
    if (modelObj) {
        modelObj.wrapper.css({
            transition: 'top 0.2s',
            left: ModelLeftPos,
            top: $("#"+page).innerHeight() - 80,
            width: 350,
        });
    }
    updateModelMinimisePlace(modelId, page, true);
    return ModelLeftPos;
}
export const leftCalculateForMinimiseModel = function(page, currentMinimisedObj) {
    // var mainscreenInnerWith = $("#"+placek,).innerWidth();
    if(currentMinimisedObj[page] == undefined && !Array.isArray(currentMinimisedObj[page])){
        return 30;
    } else {
        return currentMinimisedObj[page].length * (350+30) + 30;
    }
}
export const getModelMinimiseEmptyPlace = function (page) {
    var currentObj = Object.assign({},WMSModelMinimisePlaceObj);
    if(currentObj[page] != undefined){
        return getObjectKey(currentObj[page], false);
    }
    return "first";
}
export const getObjectKey = function(obj, value) {
    return Object.keys(obj).find(key => obj[key] === value);
  }

export const SetRights = function (arr) {
    for (var x = 0; x < arr.length; x++) {
        Rights[arr[x]] = true;
    }
    DefaultRights = Object.assign({},Rights);
    // Rights['warehouse.create'] = true;
}

export const GetRights = function () {
    return Rights;
}
export const isNotIframe = function () {
    // return window.self === window.top;
    return (window.self === window.top) && isPrivateWindow;
}

export const setCurrentActiveView = function(cActiveView) {
    currentActiveView = cActiveView;
}

export const addRightsBaseOnTplSettings = function (threeplsettings) {

    Rights = Object.assign({},DefaultRights);

    Rights["default"] = true;
    //set supportLink rights base on customer and warehouse user
    CurrentUser.CustomerIds.length > 0 ? "" : Rights["isWarehouseUser"] = true;
    CurrentUser.CustomerIds.length == 0 && Rights["orders.manage.view"] == true ? Rights["showUPSReprintMenu"] = true : "";

    if (CurrentUser.CustomerIds.length == 0 && Rights.hasOwnProperty("datainsights.operationsdashboards.ordersummary")) {
        Rights["UxPrototypingDirectivesDashboard"] = true;
    }

    if (threeplsettings.Items) {
        if (threeplsettings.Items.SkuAliasEnabled) {
            Rights["Items.SkuAliasEnabled"] = true;
        }
        if (threeplsettings.Items.ItemImagesEnabled) {
            Rights["Items.ItemImagesEnabled"] = true;
        }
        if (threeplsettings.Items.PricingEnabled) {
            Rights["Items.PricingEnabled"] = true;
        }
        if (threeplsettings.Items.ItemClassificationEnabled) {
            Rights["Items.ItemClassificationEnabled"] = true;
        }
    }
    if (threeplsettings.Shipping) {
        if (threeplsettings.Shipping.SmallParcelEnabled) {
            Rights["Shipping.SmallParcelEnabled"] = true;
        }
        if (threeplsettings.Shipping.SmallParcelRateShoppingEnabled) {
            Rights["Shipping.SmallParcelRateShoppingEnabled"] = true;
        }
        if (threeplsettings.Shipping.SmallParcelReturnLabelEnabled) {
            Rights["Shipping.SmallParcelReturnLabelEnabled"] = true;
        }
        if (threeplsettings.Shipping.SmallParcelHighVolumeProcessing) {
            Rights["Shipping.SmallParcelHighVolumeProcessing"] = true;
        }
        if (threeplsettings.Shipping.ZoneBillingEnabled) {
            Rights["Shipping.ZoneBillingEnabled"] = true;
        }
        if (threeplsettings.Shipping.WarehousePackEnabled) {
            Rights["Shipping.WarehousePackEnabled"] = true;
        }
        if (threeplsettings.Shipping.SmartPackScaleEnabled) {
            Rights["Shipping.SmartPackScaleEnabled"] = true;
        }
        if (threeplsettings.Shipping.AttachedDocsEnabled) {
            Rights["Shipping.AttachedDocsEnabled"] = true;
        }
        if (threeplsettings.Shipping.FulfillInvEnabled) {
            Rights["Shipping.FulfillInvEnabled"] = true;
        }
        if (threeplsettings.Shipping.AutoBatchEnabled) {
            Rights["Shipping.AutoBatchEnabled"] = true;
        }
        if (threeplsettings.Shipping.SmallParcelShipEngineHighVolumeBatches) {
            Rights["Shipping.SmallParcelShipEngineHighVolumeBatches"] = true;
        }
        if (threeplsettings.Shipping.ShortShipEnabled) {
            Rights["Shipping.ShortShipEnabled"] = true;
        }
        if (threeplsettings.Shipping.AllowOrderQueue) {
            Rights["Shipping.AllowOrderQueue"] = true;
        }
    }
    if (threeplsettings.Receiving) {
        if (threeplsettings.Receiving.PalletsEnabled) {
            Rights["Receiving.PalletsEnabled"] = true;
        }
        if (threeplsettings.Receiving.AllowPurchaseOrders) {
            Rights["Receiving.AllowPurchaseOrders"] = true;
        }
        if (threeplsettings.Receiving.AutoReallocOnReceiveEnabled) {
            Rights["Receiving.AutoReallocOnReceiveEnabled"] = true;
        }
        if (threeplsettings.Receiving.SuggestedPutAwayEnabled) {
            Rights["Receiving.SuggestedPutAwayEnabled"] = true;
        }
        if (threeplsettings.Receiving.SpsCloseOnUpdate) {
            Rights["Receiving.SpsCloseOnUpdate"] = true;
        }
    }
    if (threeplsettings.OtherUserFeatures) {
        if (threeplsettings.OtherUserFeatures.SavedElementsEnabled) {
            Rights["OtherUserFeatures.SavedElementsEnabled"] = true;
        }
        if (threeplsettings.OtherUserFeatures.SemiAutoMonthlyBillingEnable) {
            Rights["OtherUserFeatures.SemiAutoMonthlyBillingEnable"] = true;
        }
        if (threeplsettings.OtherUserFeatures.MobileEnabled) {
            Rights["OtherUserFeatures.MobileEnabled"] = true;
        }
        if (threeplsettings.OtherUserFeatures.ReallocateAtPickTimeEnabled) {
            Rights["OtherUserFeatures.ReallocateAtPickTimeEnabled"] = true;
        }
        if (threeplsettings.OtherUserFeatures.DashboardEnabled) {
            Rights["OtherUserFeatures.DashboardEnabled"] = true;
        }

    }
    if (threeplsettings.UserInterface) {
        if (threeplsettings.UserInterface.ConnectionsEnabled) {
            Rights["UserInterface.ConnectionsEnabled"] = true;
        }
        if (threeplsettings.UserInterface.PreventClassicAccess == false) {
            Rights["UserInterface.AllowClassicAccess"] = true;
        }
        if (threeplsettings.UserInterface.FindOrdersAutoBatchEnabled) {
            Rights["UserInterface.FindOrdersAutoBatchEnabled"] = true;
        }
    }
    if (threeplsettings.Integrations) {
        if (threeplsettings.Integrations.TmwExportEnabled) {
            Rights["Integrations.TmwExportEnabled"] = true;
        }
        if (!threeplsettings.Integrations.UseBillingManager) {
            // Hide 3W Billing links if billing manager module is enable.
            Rights["Integrations.isNotActiveBillingManagerApp"] = true;
        }
        if (threeplsettings.Integrations.QbEnabled && threeplsettings.Integrations.QbEnabled == 2) {
            Rights["Integrations.QbEnabled"] = true;
        }
        Rights["Integrations.SmallParcelPartner"] = threeplsettings.Integrations.SmallParcelPartner;
        if (threeplsettings.Integrations.SmallParcelPartner == "ParcelApi" && threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("HidePitneyBuku") != -1) {
            Rights["UxPrototypingDirectivesHidePitneyBuku"] = true;
        }
        if (threeplsettings.Integrations.SmallParcelPartner == "ParcelApi" && threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("PitneyUSPSDefault") != -1) {
            Rights["UxPrototypingDirectivesHideUSPSProviders"] = true;
        }
        if (threeplsettings.Integrations.SmallParcelPartner == "ShipEngine") {
            threeplsettings.Integrations.SmallParcelPartnerBoolean = true;
            Rights["Integrations.SmallParcelPartnerBoolean"] = true;
        }
        if (threeplsettings.Integrations.WebhooksEnabled) {
            Rights["Integrations.WebhooksEnabled"] = true;
        }
    }

    if (CurrentUser.CustomerIds.length == 0 && threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("on") != -1) {
        Rights["UxPrototypingDirectivesOn"] = true;
    }
    if (threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("outbound") != -1 && Rights.hasOwnProperty("datainsights.operationsdashboards.outprod") && CurrentUser.CustomerIds.length == 0) {
        Rights["UxPrototypingDirectivesOutBound"] = true;
    }
    if (threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("labor") != -1 && Rights.hasOwnProperty("datainsights.operationsdashboards.laboranalytics") && CurrentUser.CustomerIds.length == 0) {
        Rights["UxPrototypingDirectivesLabor"] = true;
    }
    if (threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("stockstatus") != -1) {
        Rights["UxPrototypingDirectivesStockStatus"] = true;
    }
    if (threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("pallets") != -1) {
        Rights["UxPrototypingDirectivesPallets"] = true;
    }
    if (threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("newadjust") != -1) {
        Rights["UxPrototypingDirectivesnewadjust"] = true;
    }
    if (threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("intercom") != -1) {
        Rights["UxPrototypingDirectivesShowIntercom"] = true;
    }
    if (threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("PBCBDS") != -1) {
        Rights["UxPrototypingDirectivesPBCBDS"] = true;
    }
    if (threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("zplFastPrint") != -1) {
        Rights["print.optimization"] = true;
    }
    if (threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("supportportal") != -1) {
        Rights["UxPrototypingShowSupportPortal"] = true;
    }
    Rights["UxPrototypingDirectivesShowClassicSP"] = true;
    if (threeplsettings.UserInterface.UxPrototypingDirectives.indexOf("noclassicSP") != -1) {
        delete Rights["UxPrototypingDirectivesShowClassicSP"];
    }
    if ((threeplsettings.Shipping.WarehousePackEnabled || threeplsettings.Shipping.SmallParcelEnabled) && (Rights['orders.manage.smartparcel'] || Rights['orders.edit.pack']) && Rights["isWarehouseUser"]) {
        Rights["smallParcelMenuVisible"] = true;
    }
    if (CurrentUser.UserId == -1) {
        Rights["isSysAdminUser"] = true;
    }
}


export const SetCountries = function (arr) {

    countries = arr;
    countries.unshift({
        "CountryCode": '',
        "Name": "Unspecified"
    });
    for (var x = 0; x < arr.length; x++) {
        countriesRegions.Add(arr[x].CountryCode, arr[x].Regions);
    }
}


export const GetCountries = function () {
    return countries;
}

export const GetRegions = function (countryCode) {
    if (!countryCode) {
        var myreturn = []; //countriesRegions.Item('US');
        myreturn.push({
            "RegionCode": "",
            "Name": "Unspecified"
        });
        return myreturn;
    } else {
        var myreturn;

        countriesRegions.Item(countryCode) ? myreturn = countriesRegions.Item(countryCode) : myreturn = [];
        if ((typeof myreturn[0] !== 'undefined' && myreturn[0].Name !== "Unspecified") || (typeof myreturn[0] == 'undefined')) {
            myreturn.unshift({
                "RegionCode": "",
                "Name": "Unspecified"
            })
        }
        return myreturn;
    }
}

export const GetCustomerFacilityListAxios = function () {
    return axios.get('/WebUI/Shared/GlobalServices/GetCustomerFacilityList?active=true', { headers: { 'If-Modified-Since': Customers.LastModified } });
}
export const GetCustomersAndFacility = function () {
    var axiosFacilityList = GetCustomerFacilityListAxios();
    return axiosFacilityList.then(function (data) {
        if (data && data.response && data.response.status === 304) {
            return;
        } else {
            setCustomerFacilityListResult(data);
            var eventCustFacUpdate = new CustomEvent("customerFacilityOptionsUpdate");
            document.dispatchEvent(eventCustFacUpdate);
        }
        return;
    });
}

export const setCustomerFacilityListResult = function (param_response) {
    // var isWarehouseUser = (CurrentUser.CustomerIds.length == 0 ? true : false);
    var data = param_response.data;
    var headers = param_response.headers;
    var customerDict = [];
    // if(isWarehouseUser){
        var customerWithFacility = [];
        // Logic for filter customer show only customer which have active facility in facility list
        var facilityIds = _.map(data.facilities,"id");
        for (var x = 0; x < data.customers.length; x++) {
            var isFacilitAvailable = facilityIds.some(function (v) {
                return data.customers[x]['facilities'].indexOf(v) >= 0;
            });
            if(isFacilitAvailable){
                customerWithFacility.push(data.customers[x]);
            }
            customerDict[data.customers[x].id] = data.customers[x];
        }

        Customers.Customer = customerWithFacility;
        Customers.CustomerDict = customerDict;
    // }else{
    //     Customers.Customer = data.customers;
    // }
    Customers.FacilityDict = data.facilities;
    Customers.LastModified = headers["last-modified"];
    var customerFacility = [];
    var keys = Object.keys(Customers.FacilityDict);
    var tempId = [];
    for (var x = 0; x < keys.length; x++) {
        customerFacility.push(Customers.FacilityDict[keys[x]]);
        tempId.push(keys[x]);
    }
    facilityList = customerFacility;
}
export const ResetCustomerFacilityList = function () {
    Customers.Customer = [];
    Customers.LastModified = "";
}
export const getFacilityList = function (isAddUnspecifiedOption) {
    var temp = _.cloneDeep(facilityList);
    if (isAddUnspecifiedOption) {
        temp.unshift({
            "id": 0,
            "name": "Unspecified",
            "uri": "",
            "FacilitySimpleUri": "",
            "UpsAccountNumber": "",
            "FedExAccountNumber": ""
        });
    }
    return temp;
}
// if use this function for facility options, please take referance for filter inactive facilities from manage-orders-form-proto.component.jsx.
export const getFacilityDictList = function () {
    return _.cloneDeep(Customers.FacilityDict);
}

export const SetCurrentUser = function (obj) {
    CurrentUser = obj;
    currentLoginUser = obj.TplId + '_' + obj.Login;
    if(isNotIframe()){
        window.localStorage.setItem('currentLoginUser',currentLoginUser);
        window.localStorage.setItem('currentLoginUserName',obj.Name);
        window.localStorage.setItem('currentLoginTplName',obj.TplName);
    }
    // set query string from user data for dashboard iframe url
    userKeysUrlStringForDashboard = Object.keys(obj).map(function(key) {
        userKeysUrlStringForDashboardPost[key] = obj[key];
        return key + '=' + obj[key]
    }).join('&');

    userKeysUrlStringForDashboard += '&cookie=' + whoseLineIsItAnywaySessionId;
    userKeysUrlStringForDashboardPost["cookie"] = whoseLineIsItAnywaySessionId;

    userKeysUrlStringForDashboard += '&EnvIndicator='+getEnvironmentIndicator();
    userKeysUrlStringForDashboardPost["EnvIndicator"] = getEnvironmentIndicator();
}
/* istanbul ignore next */
export const GetCurrentUser = function () {
    return CurrentUser;
}



export const getCustomerList = function (isAddUnspecifiedOption) {

    if (isAddUnspecifiedOption) {
        var keys = Object.keys(Customers.FacilityDict);
        var tempId = [];
        for (var x = 0; x < keys.length; x++) {
            Customers.Facility.push(Customers.FacilityDict[keys[x]]);
            tempId.push(keys[x]);
        }
        const temp = JSON.parse(JSON.stringify(Customers.Customer));
        temp.unshift({
            "id": 0,
            "name": "Unspecified",
            "defaultFacility": 0,
            "facilities": tempId
        });
        return temp;
    } else {
        return _.cloneDeep(Customers.Customer);
    }
}
export const getSortedColumnName = function (sortArrayGrid, columnList) {
    var sortedColums = [];
    for (var i = 0; i < sortArrayGrid.length; i++) {
        var title = getTitleFromFields(sortArrayGrid[i].field, columnList);
        if (title) {
            sortedColums.push(title);
        }
    }
    return sortedColums.join(", ");
}

export function getTitleFromFields(fieldName, columnList) {
    var filterObj = columnList.filter(function (e) {
        return e.field == fieldName;
    });
    if(filterObj.length > 0 && filterObj[0].hideOnSortTitle){
        return '';
    }
    if (filterObj.length > 0) {
        return filterObj[0]['title'];
    } else {
        return fieldName;
    }
}


export function apiFailer(res) {
    /*

    if (typeof res == "undefined" || res.status == 304) {
        // if we get not modified we don't want to throw any error here
        return;
    } else if (res.status == 400) {
        var trackData = {};
        trackData.method = res.config.method;
        trackData.url = res.config.url;
        trackData.status = res.status;
        trackData.request = res.config.data;
        trackData.response = res.data;
        errorRecoverable($rootScope, trackData);
        return false;
    } else if (res.status == 401) {

        /*
        var URL = window.location.href;
        window.location.href = globalsMsg.auth_url + URL;
        return;
        * /
        console.log("401 IS FOUND, GLOBAL SERVICES IS PROCESSING IS PROCESSING THIS")
        var tplguid = $.urlParam('tplguid');
        var urlStringNoParams = window.location.origin+window.location.pathname;
        var tplguidQueryParam = '';
        if (tplguid) {
            tplguidQueryParam = '&tplguid=' + tplguid;
        }

        window.location.replace(globalsMsg.auth_url + urlStringNoParams + tplguidQueryParam);
        return;
    } else if (res.status == 500) {
        var trackData = {};
        trackData.method = res.config.method;
        trackData.url = res.config.url;
        trackData.status = res.status;
        trackData.request = res.config.data;
        trackData.response = res.data;
        errorUnRecoverable($rootScope, trackData);
        return false;
    } else {
        var trackData = {};
        trackData.method = res.config.method;
        trackData.url = res.config.url;
        trackData.status = res.status;
        trackData.request = res.config.data;
        trackData.response = res.data;
        apiSystemError($rootScope, trackData);
        return false;
    }
    return false;*/
}
export function toastSuccess(notificationType, message) {
    if (notificationType == "success") {
        var html = '<i class="fa fa-check-circle" aria-hidden="true"></i>' + message;
        toastr.success(html, '', {
            timeOut: 4000,
            positionClass: "toast-bottom-right",
            escapeHtml: false // for set html in message
        });
    }
}
export function notification(notificationType, message, detail) {

    if(notificationType == "warning" && typeof message == "string"){
        notificationType = "warningAutoClose";
    } else if(notificationType == "error" && typeof message == "string"){
        notificationType = "errorAutoClose";
    }else if(message.response && (message.response.status == 400 || message.response.status == 404)){
        notificationType = "warning";
    } else if (notificationType != "success") {
        notificationType = "warningAutoClose";
    }
    var data = {
        "ErrorClass": "",
        "ErrorCode": "",
        "Descriptors": [],
        "Hint": null
    };
    if (notificationType != "success") {
        data.RequestorContextId = message.RequestorContextId ?  message.RequestorContextId : '' ;

        data.RequestorContextId = "SE." + whoseLineIsItAnywaySessionId;
        if (!data.ErrorCode) {
            data.ErrorCode = "System";
        }

    } else {
        toastSuccess(notificationType, message);
        return true;
    }
    data.type = notificationType;
    data.message = message;
    data.detail = detail ? detail : " ";
    data.status = message.response ? message.response.status : "";
    data.ServerName = message.ServerName ?  message.ServerName : '' ;
    var event = new CustomEvent("showErrorMessage", {
        detail: {
            data: data
        }
    });
    document.dispatchEvent(event);
}


export function arrayRemove(array, element) {
    return array.filter(function (e) {
        return e !== element
    });
}

export function sortDataset(sortArrayGrid, sorrCurrColumn) {
    var sortColums = [];
    for (var i = 0; i < sortArrayGrid.length; i++) {
        if (sorrCurrColumn.field == sortArrayGrid[i].field) {
            sortArrayGrid.splice(i, 1);
            break;
        }
    }
    if (sorrCurrColumn.dir != undefined) {
        sortArrayGrid.push(sorrCurrColumn);
    }
}

export function setGridOptions(gridName, finalSubmission) {

    return axios.post('/WebUI/Shared/GridOptions/SetGridOptions?name=' + gridName, finalSubmission).then(function (response) { });
}

export function GridSearchInfoSend(url, filterData) {
    if (typeof filterData.method != "undefined" && filterData.method == "DELETE") {
        return axios.delete(url, filterData).then(function (response) {
            return response.data;
        });
    } else if (typeof filterData.method != "undefined" && filterData.method == "POST") {
        delete filterData.method;
        return axios.post(url, filterData).then(function (response) {
            return response.data;
        });
    } else {
        return axios.put(url, filterData).then(function (response) {
            return response.data;
        });
    }

}
var iframeUrl = '';
var iframeMethod = '';
var paramOrderids = '';
var iframeNameCounter = 0;
/* istanbul ignore next */
export function setIframeUrl(param_iframeUrl,param_iframeMethod,param_orderids) {

    iframeUrl = param_iframeUrl;
    iframeMethod = param_iframeMethod;
    paramOrderids = param_orderids;
    iframeNameCounter = parseInt(iframeNameCounter) + 1;

}
/* istanbul ignore next */
export function getIframeUrl() {
    return {
        'url': iframeUrl,
        'name': 'iframe' + iframeNameCounter,
        'iframeMethod':iframeMethod,
        'paramOrderids':paramOrderids
    };
}

export function refreshGridForChangedNumberOfRows(grid) {
    if (grid) {
        grid.dataSource.page(1);
    }

}
export function resetGrid(grid) {
    return axios.post('/WebUI/Shared/GridOptions/ClearGridOptions', {
        name: grid
    }).then(function (response) {
        return response;
    });
}
export function getCustomLabels() {
    return customLabel;
}

export function closeWindow(windowId) {
    if (windowId !== "" || windowId !== null) {
        window.closeWindowFromTitlebar(windowId);
        return true;
    }
    return false;
}


export const setTplSettings = function (data) {
    tplSettings = data;
}

export const getTplSettings = function () {
    return tplSettings;
}

export const setUnitOfMeasureTypes = function (data) {
    unitOfMeasureTypesOptions = data;
}
export const getIsIntegeralFlagByType = function (unitName) {
    if(!unitName){
        return false;
    }
    var IsIntegeral = false;
    unitOfMeasureTypesOptions.filter(function (unitType) {

        if(unitType.Type == unitName){
            IsIntegeral = unitType.IsIntegral;
        }
    });
    return IsIntegeral;
}

export const printDataInZplPrinter = function (printData, forceOptimizedVersion = false) {
    BrowserPrint.getDefaultDevice('printer', function (printer) {
        if ((printer != null) && (printer.connection !== undefined)) {
            const hasPrintOptimizationUxDirective = Rights?.['print.optimization'];
            // UX directive slow roll out of faster zpl printing in case zpl printers struggling with speed.
            // TODO remove directive after we've moved everyone to faster printing
            if (hasPrintOptimizationUxDirective || forceOptimizedVersion) {
                console.log('optimized printing started');
                checkPrinterStatus(printer, function(pStatus) {
                    if (pStatus === "Ready to Print") {
                        for (let index = 0; index < printData.length; index++) {
                            const elementPrintData = printData[index];
                            printer.send(elementPrintData, printComplete, printerError);
                        }
                    } else {
                        printerError(pStatus);
                    }
                })
            } else {
                for (let index = 0; index < printData.length; index++) {
                    const elementPrintData = printData[index];
                    sendDataToBrowserPrint(elementPrintData, printer);
                }
            }
        } else {
            UseApplet(document, printData);
        }
    }, function (error_response) {
        console.log("zpl printer not found ", error_response);
        UseApplet(document, printData);
    });
}

export function UseApplet(document, printData) {
    if (!isIEorEDGE()) {
        var msg = 'Please install '
            + '<a target="_blank" href="https://www.zebra.com/us/en/products/software/barcode-printers/link-os/browser-print.html">Browser Print</a>'
            + '<br/><br/>Start Browser Print application if already installed'
            + '<br/><br/><strong>Chrome Users:</strong> If you previously installed Browser Print, you might need to add, or re-add, a <a href="https://localhost:9101/ssl_support" target="_blank" >permanent SSL certificate exception</a>.';

        notification("systemError", '', msg);

    } else {
        for (let index = 0; index < printData.length; index++) {
            const elementPrintData = printData[index];
            var app = document.createElement('applet');
            app.id = 'Java';
            app.archive = 'assets/ZPLPrintBase.jar';
            app.code = 'ZPLPrintDefault';
            app.width = '0';
            app.height = '0';
            app.innerHTML = 'Java applet not supported. Please install <a target="_blank" href="https://www.zebra.com/us/en/products/software/barcode-printers/link-os/browser-print.html">Browser Print</a><br /><br /><strong>Chrome Users:</strong> If you previously installed Browser Print, you might need to add, or re-add, a <a href="https://3plcentral.atlassian.net/wiki/spaces/3WM/pages/1474604/Getting+Started+with+Zebra+Browser+Print#GettingStartedwithZebraBrowserPrint-PermanentCertExceptionAddingaPermanentSSLCertificateExceptionforChrome" target="_blank" >permanent SSL certificate exception</a>.';

            var p1 = document.createElement('param');
            p1.name = 'zplcode';
            p1.value = elementPrintData;

            app.appendChild(p1);
            document.getElementsByTagName('body')[0].appendChild(app);
        }

    }
}

function isIEorEDGE() {
    if (navigator.appName === 'Microsoft Internet Explorer') {
        return true; // IE
    } else if (navigator.appName === "Netscape") {
        return navigator.appVersion.indexOf('Edge') > -1; // EDGE
    }
    return false;
}

// TODO remove this after we deprecate the printDataInZplPrinter ux directive/feature flag
export function sendDataToBrowserPrint(zpldata, printer) {
    checkPrinterStatus(printer, function (status) {
        if (status === "Ready to Print") {
            printer.send(zpldata, printComplete, printerError);
        } else {
            printerError(status);
        }
    });
}

export function checkPrinterStatus(printer, finishedFunction) {
    printer.sendThenRead("~HQES",
        function (text) {
            var statuses = new Array();
            var errorsIndex = text.indexOf('ERRORS:');
            if (errorsIndex == -1) {

                //alert("Garbage data returned from Printer, will try to print anyway: " + text);

                //The expected status message wasn't sent in the printer's response,
                //so just try to print anyway since we don't know any better
                statuses.push("Ready to Print");
                finishedFunction(statuses.join());
                return;
            }
            text = text.substring(errorsIndex);
            text = text.replace(/  +/g, ' ');

            var textSplit = text.split(' ');
            if (textSplit.length < 4) {
                statuses.push("Error: Could not parse printer status: " + text);
                finishedFunction(statuses.join());
                return;
            }

            var ok = false;
            var isError = textSplit[1].charAt(0);
            var media = textSplit[3].charAt(7);
            var head = textSplit[3].charAt(6);
            var pause = textSplit[3].charAt(3);

            // check each flag that prevents printing
            if (isError == '0') {
                ok = true;
                statuses.push("Ready to Print");
            }
            if (media == '1' || media == '3' || media == '5' || media == '7' || media == '9' || media == 'B' || media == 'D' || media == 'F')
                statuses.push("Paper out");
            if (media == '2' || media == '3' || media == '6' || media == '7' || media == 'A' || media == 'B' || media == 'E' || media == 'F')
                statuses.push("Ribbon Out");
            if (media == '4' || media == '5' || media == '6' || media == '7' || media == 'C' || media == 'D' || media == 'E' || media == 'F')
                statuses.push("Media Door Open");
            if (media == '8' || media == '9' || media == 'A' || media == 'B' || media == 'C' || media == 'D' || media == 'E' || media == 'F')
                statuses.push("Cutter Fault");
            if (head == '1' || head == '3' || head == '5' || head == '7' || head == '9' || head == 'B' || head == 'D' || head == 'F')
                statuses.push("Printhead Overheating");
            if (head == '2' || head == '3' || head == '6' || head == '7' || head == 'A' || head == 'B' || head == 'E' || head == 'F')
                statuses.push("Motor Overheating");
            if (head == '4' || head == '5' || head == '6' || head == '7' || head == 'C' || head == 'D' || head == 'E' || head == 'F')
                statuses.push("Printhead Fault");
            if (head == '8' || head == '9' || head == 'A' || head == 'B' || head == 'C' || head == 'D' || head == 'E' || head == 'F')
                statuses.push("Incorrect Printhead");
            if (pause == '1')
                statuses.push("Printer Paused");
            if ((!ok) && (statuses.length == 0))
                statuses.push("Error: Unknown Error");
            finishedFunction(statuses.join());
        }, printerError);
}

export function printComplete() {
    notification('success', "Printing complete");
}

export function printerError(text) {
    alert("An error occurred while printing. Please try again. \n" + text);
}

export const initialPrintMenu = function (customerId) {
    var custId = customerId === 0 ? null : customerId;

    return axios.get('/WebUI/Shipping/FindOrders/GetReports?customerid=' + custId)
        .then(function (response) {
            var tempData = response.data;
            return tempData;
        });
}
/* istanbul ignore next */
export const getExportColums = function (baseArray, columnList) {
    var columns = [];
    for (var i = 0; i < columnList.length; i++) {
        if (!columnList[i].hidden) {
            var modelFieldName = this.getTitleandModelField(columnList[i].field, baseArray);
            if (modelFieldName != null) {
                columns.push({
                    'HeaderName': columnList[i].title,
                    'ModelField': modelFieldName
                });
            }
        }
    }
    return columns;
}

/* istanbul ignore next */
export const getTitleandModelField = function (fieldName, baseArray) {
    var filterObj = '';

    filterObj = baseArray.filter(function (e) {
        return e[fieldName];
        // return e[fieldName] == fieldName;
    });
    if (filterObj) {
        return filterObj[0] ? filterObj[0][fieldName] : null;
    } else {
        return null;
    }
}

/* clear api cache service */
export const flushApiCache = function () {
    return axios.get('/WebUI/Shared/GlobalServices/FlushCache').then(function (response) {
        return response;
    });
}

/* clear api cache service */
export const getFooterInfo = function () {
    return axios.get('/WebUI/Shared/GlobalServices/GetFooterInfo').then(function (response) {
        return response;
    });
}

/* Export grid data to csv */

export const exportGridtoCSV = function (data, grid_selector,export_file_name) {

    var csv = '';
    let enableColumns = [];
    let grid = $(grid_selector).data('kendoGrid');
    let columnList = grid.columns;

    columnList.filter(function (item) {
        if (item.hidden !== true) {
            enableColumns.push(item.field);
        }
    });
    //add the header row
    if (data.length > 0) {
        for (var col in enableColumns) {
            csv += '"' + enableColumns[col] + '"';
            if (col != data[0].length - 1) {
                csv += ",";
            }
        }
        csv += "\n";
    }
    //add each row of data
    for (var row in data) {
        for (var col in enableColumns) {

            var value = data[row][enableColumns[col]];
            if (typeof value == 'undefined') {
                value = ""
            } else if (value === null) {
                value = "";
            } else if (value instanceof Date) {
                value = kendo.toString(kendo.parseDate(value), 'yyyy-MM-dd HH:mm:ss');
            } else {
                value = value.toString();
            }
            csv += '"' + value + '"';
            if (col != enableColumns.length - 1) {
                csv += ",";
            }
        }
        csv += "\n";
    }
    //TODO replace with downloadify so we can get proper file naming
    var blob = new Blob([csv], {
        "type": "text/csv;charset=utf8;"
    });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, export_file_name+"-" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + ".csv");
    } else {
        var a = $("<a>")
            .attr("href", window.URL.createObjectURL(blob))
            .attr("download", export_file_name+"-" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + ".csv")
            .appendTo("body");
        a[0].click();
        a.remove();
    }
}

export const getUserClientMetaData = function (name) {
    return axios.get('/WebUI/Shared/GlobalServices/UserClientMetaData?name="' + name + '"')
        .then(function (response) {
            return response.data;
        });
}

export const setUserClientMetaData = function (name, data) {
    var dataObj = {
        "data": data
    }
    return axios.put('/WebUI/Shared/GlobalServices/UserClientMetaData?name="' + name + '"', dataObj).then(function (response) {
        return response;
    });
}


// Download file using api respose content type and method used in to Send asn model(Order) and Receipt advice model
export const downloadFileBaseOnContentType = function (response, param_content_type, param_file_prefix, param_file_prefix_with_extension, param_action = 'download') {
    console.log("extensionListWithType ", param_content_type);
    // NOTE: If no content type found in below list default will be txt
    let contentType = {
      type:'text/plain',
      extension:'.txt'
    };

    let contentTypeList = [
        {type:'application/xml',extension:'.xml'},
        {type:'application/txt',extension:'.txt'},
        {type:'text/plain',extension:'.txt'},
        {type:'application/json',extension:'.json'},
        {type:'application/edi',extension:'.edi'},
        {type:'application/EDI-consent',extension:'.edi'},
        {type:'text/csv',extension:'.csv'},
        {type:'text/csv;charset=utf8;',extension:'.csv'},
        {type:'application/msword',extension:'.doc'},
        {type:'application/pdf',extension:'.pdf'}
    ];

    contentTypeList.filter(function (item) {
        if(item.type == param_content_type){ contentType =  item; }
    });
    var blob = new Blob([response], { "type": param_content_type});
    var fileName = param_file_prefix + "-" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + contentType.extension;
    if(param_file_prefix_with_extension) {
        fileName = param_file_prefix;
    }

    if (param_action == "pdfViewer") {
        var blobUrl = window.URL.createObjectURL(blob);
        var currentUrl = window.location.origin + window.location.pathname + "pdf-viewer/viewer.html?file=" + blobUrl;
        window.open(currentUrl, "_blank");
    } else {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
        else {
            if (param_action == "download") {
                var a = $("<a>")
                    .attr("href", window.URL.createObjectURL(blob))
                    .attr("download", fileName)
                    .appendTo("body");
                a[0].click();
                a.remove();
            } else {
                ArrayWms.displayBlobInWindow(blob, fileName);
            }
        }
    }

}
export const prepareGridOptionsForStorage = function (passedObj, sortArr,filterArr) {
    var reducedObj;
    reducedObj = {
        "columns": [],
        "sorts": [],
        "filters":[]
    };
    for (var x = 0; x < passedObj.length; x++) {
        if (passedObj[x].hasOwnProperty("field")) {
            reducedObj.columns[x] = {
                "field": passedObj[x].field
            };
        }
        if (passedObj[x].hasOwnProperty("width")) {
            reducedObj.columns[x].width = passedObj[x].width;
        }
        if (passedObj[x].hasOwnProperty("wmsid")) {
            reducedObj.columns[x].wmsid = passedObj[x].wmsid;
        }
        if (passedObj[x].hasOwnProperty("hidden")) {
            reducedObj.columns[x].hidden = passedObj[x].hidden;
        }
    }

    reducedObj.sorts = sortArr;
    reducedObj.filters = filterArr;

    var finalreturn = {
        "optionsData": JSON.stringify(reducedObj)
    };
    return (finalreturn);
};
export const debounce = function (a, b, c) { var d, e; return function () { function h() { d = null, c || (e = a.apply(f, g)) } var f = this, g = arguments; return clearTimeout(d), d = setTimeout(h, b), c && !d && (e = a.apply(f, g)), e } }

export const useFCDebounce = function(cb, delay) {
    const inputsRef = useRef({cb, delay});
    useEffect(() => { inputsRef.current = { cb, delay }; });
    return useCallback(
      _.debounce((...args) => {
            inputsRef.current.cb(...args);
        }, delay
      ),
      [delay, _.debounce]
    );
}

export const getDropdownOptionsForSettings = function (selectedCarrier) {
    let pickupType =  [];
    let mailInnovationsEndorsement = [];

    let smartPostHub = [
        { name: "None", value: "none" },
        { name: "Allentown, PA", value: "allentown_pa" },
        { name: "Atlanta, GA", value: "atlanta_ga" },
        { name: "Baltimore, MD", value: "baltimore_md" },
        { name: "Charlotte, NC", value: "charlotte_nc" },
        { name: "Chino, CA", value: "chino_ca" },
        { name: "Dallas, TX", value: "dallas_tx" },
        { name: "Denver, CO", value: "denver_co" },
        { name: "Detroit, MI", value: "detroit_mi" },
        { name: "Edison, NJ", value: "edison_nj" },
        { name: "Grove City, OH", value: "grove_city_oh" },
        { name: "Groveport, OH", value: "groveport_oh" },
        { name: "Houston, TX", value: "houston_tx" },
        { name: "Indianapolis, IN", value: "indianapolis_in" },
        { name: "Kansas City, KS", value: "kansas_city_ks" },
        { name: "Los Angeles, CA", value: "los_angeles_ca" },
        { name: "Martinsburg, WV", value: "martinsburg_wv" },
        { name: "Memphis, TN", value: "memphis_tn" },
        { name: "Minneapolis, MN", value: "minneapolis_mn" },
        { name: "New Berlin, WI", value: "new_berlin_wi" },
        { name: "Northborough, MA", value: "northborough_ma" },
        { name: "Orlando, FL", value: "orlando_fl" },
        { name: "Phoneix, AZ", value: "phoneix_az" },
        { name: "Pittsburgh, PA", value: "pittsburgh_pa" },
        { name: "Reno, NV", value: "reno_nv" },
        { name: "Sacramento, CA", value: "sacramento_ca" },
        { name: "Salt Lake City, UT", value: "salt_lake_city_ut" },
        { name: "Seattle, WA", value: "seattle_wa" },
        { name: "St Louis, MO", value: "st_louis_mo" },
        { name: "Windsor, CT", value: "windsor_ct" },
        { name: "Newark, NY", value: "newark_ny" },
        { name: "South Brunswick, NJ", value: "south_brunswick_nj" },
        { name: "Scranton, PA", value: "scranton_pa" },
        { name: "Wheeling, IL", value: "wheeling_il" }
    ];
    let smartPostEndorsement = [
        { name: "None", value: "none" },
        { name: "Return Service Requested", value: "return_service_requested" },
        { name: "Forwarding Service Requested", value: "forwarding_service_requested" },
        { name: "Address Service Requested", value: "address_service_requested" },
        { name: "Change Service Requested", value: "change_service_requested" },
        { name: "Leave If No Response", value: "leave_if_no_response" }
    ];

    if (selectedCarrier == 'FedEx') {
        pickupType = [
            { name: "None", value: "none" },
            { name: "Regular Pickup", value: "regular_pickup" },
            { name: "Request Courier", value: "request_courier" },
            { name: "Drop Box", value: "drop_box" },
            { name: "Business Service Center", value: "business_service_center" },
            { name: "Station", value: "station" },
        ];
    } else if (selectedCarrier == 'UPS') {

        pickupType = [
            { name: "Daily Pickup", value: "daily_pickup" },
            { name: "Occasional Pickup", value: "occasional_pickup" },
            { name: "Customer Counter", value: "customer_counter" }
        ];

        mailInnovationsEndorsement = [
            { name: "None", value: "none"},
            { name: "Return Service Requested", value: "return_service_requested"},
            { name: "Forwarding Service Requested", value: "forwarding_service_requested"},
            { name: "Address Service Requested", value: "address_service_requested"},
            { name: "Change Service Requested", value: "change_service_requested"},
            { name: "Leave If No Response", value: "leave_if_no_response"}
        ];
    }

    let optionsList = {
        pickupType: pickupType,
        smartPostHub: smartPostHub,
        smartPostEndorsement: smartPostEndorsement,
        mailInnovationsEndorsement: mailInnovationsEndorsement,
    };

    return optionsList;
}
export function getFilterOptions(param_filter_type) {
    // inject angular global service for get customLabel list
    var translate = window.translate;
    var filterOptions = [];
    if (param_filter_type == 'string') {
        filterOptions = StringWms.convertSringToOption(translate('Options_SearchBy_StringOptions'));
    } else if (param_filter_type == 'number') {
        filterOptions = StringWms.convertSringToOption(translate('Options_SearchBy_NumberOptions'));
    }
    return filterOptions;
}
export function getMeasurementStandardFlag(facilityId) {
    var facilityDict = getFacilityDictList();
    var isImperial = true;
    if (facilityDict.hasOwnProperty(facilityId)) {
        if (facilityDict[facilityId].hasOwnProperty("measurementStandard")) {
            isImperial = facilityDict[facilityId].measurementStandard === 0 ? true : false;
        }
    }
    return isImperial;
}
export function getFacilityMaxDateTime(facilityId) {
    var facilityDict = getFacilityDictList();
    if (facilityDict.hasOwnProperty(facilityId)) {
        var timezone = facilityDict[facilityId].timeZone;
        return axios.get('/WebUI/Shared/GlobalServices/GetTimeZoneNow?timezonename='+timezone)
            .then(function (response) {
                return response.data.timeZoneNow;
         });
    }
}
export function showErrorMessage(data) {
    data.RequestorContextId = "SE." + whoseLineIsItAnywaySessionId;
    if (!data.ErrorCode) {
        data.ErrorCode = "System";
    }
    if(!data.type){
        data.type = "error";
    }
    var event = new CustomEvent("showErrorMessage", {
        detail: {
            data: data
        }
    });
    document.dispatchEvent(event);
}
export function MainLogout() {
    return axios.get('/WebUI/Login/Logout')
        .then(function (response) {
            var URL = window.location.href;
            var tplguidQueryParam = "";
            if (response.data.tplguid) {
                tplguidQueryParam = '&tplguid=' + response.data.tplguid;
            }
            window.location.href = globalsMsg.auth_url + URL + tplguidQueryParam;
            return response;
        });
}
export function GetUSerInformation() {
    return axios.get('/WebUI/Shared/GlobalServices/LoggedInUserInfo')
        .then(function (response) {
            SetCurrentUser(response.data);
            return response;
        });
}
export function getEnvironmentIndicator() {
    return getHostIndicator(window.location.host);
}

export function getHostIndicator(host) {
    var currentUrl = host;
    var urlParts = currentUrl.split('.');
    var indicator = "";
    if(urlParts.length >= 3){ // check if subdomain available
        var subdomain = urlParts[0];
        var isMatch = !/^(global)/.test(subdomain)
        if (subdomain !== "3w" && isMatch) {
            indicator = subdomain.replace(/^3w-/, "").substring(0, 1) + "-";
        }
    }
    if(host.indexOf("localhost") !== -1) {
        indicator = "l-";
    }
    return indicator;
}

export function isLocalhost() {
    return ['localhost', "127.0.0.1"].includes(window.location.hostname)
}

export function VerifySsoToSalesForceConfig() {
    return axios.get('/WebUI/Shared/GlobalServices/VerifySsoToSalesForceConfig')
        .then(function (response) {
            return response;
        });
}

export const LogBrowserTraces = function (data) {

    return axios.post('/WebUI/Shared/GlobalServices/BrowserTraces', {
        traceLevel: "error",
        traceMessage: data
    }).then(function (response) {
            return response;
        });
}

export const setAsnPrechekOptionsData = function (param_val) {
    let tmpAsnPreCheckOptions = {
        HasWeight : false, // 1
        HasVolume : false, // 2
        HasPackages : false, // 4
        HasCarrier : false, //  8
        HasBillOfLading : false, // 16
        HasLoadNumber : false, // 32
        HasPoNumber : false, // 64
        IsClosed : false // 128
    }

    if (param_val < 0 || param_val > 255 || param_val % 1 !== 0) {
        return tmpAsnPreCheckOptions;
    }
    // Here generate 8 bit binary value For example - 255 convert in binary 11111111 and 3 convert into 00000011
    let binnaryVal =  ("000000000" + param_val.toString(2)).substr(-8);

    // convert binary string into array, set precheck option using index
    let binaryValArray =  binnaryVal.split("");

    for (let index = 0; index < binaryValArray.length; index++) {

       const bit = binaryValArray[index];
       if(bit == 1) {
            if(index == 0) { // binary first index it's 128 value base on binary sequence value
                tmpAsnPreCheckOptions.IsClosed = true;
            } else if(index == 1) { // binary first index it's 64 value base on binary sequence value
                tmpAsnPreCheckOptions.HasPoNumber = true;
            } else if(index == 2) { // 32
                tmpAsnPreCheckOptions.HasLoadNumber = true;
            } else if(index == 3) { // 19
                tmpAsnPreCheckOptions.HasBillOfLading = true;
            } else if(index == 4) { // 8
                tmpAsnPreCheckOptions.HasCarrier = true;
            } else if(index == 5) {  // 4
                tmpAsnPreCheckOptions.HasPackages = true;
            } else if(index == 6) {  // 2
                tmpAsnPreCheckOptions.HasVolume = true;
            } else if(index == 7) { // 1
                tmpAsnPreCheckOptions.HasWeight = true;
            }
        }
    }
    return tmpAsnPreCheckOptions;
}

export const axiosResponseErrorHandle = async function(error) {
    if ( error.request && error.request.responseType === 'blob' && error.response.data instanceof Blob && error.response.data.type && error.response.data.type.toLowerCase().indexOf('json') != -1 ) {
        error.response.data = await convertBlobToJson(error.response.data);
    }

    if (!error.config) {
        error.isCancel = true;
        console.error("canceled error : ", error);
        return Promise.reject(error);
    }
    if(error.response && error.response.status === 304){
        return error;
    }
    if (error && error.response && error.response.status >= 500) {
        trackEventInPendo(error.config.url, error.config.method, error.response.status,  error.config.data, error.response.data);
    }

    // prevent error modal to open when login credential is wrong in carrier settings for PitneyBowes [WMS-20706]
    if (error.config.url.indexOf("/WebUI/Settings/SmartParcel/CreateProviderAccount") != -1) {
        return Promise.reject(error);
    }

    // prevent void label error modal to open when shipengine throw error [WMS-25085]
    if (error.config.url.indexOf("/WebUI/Shipping/FindOrders/ParcelCancelerStatus") != -1 && error.response.status === 400) {
        return Promise.reject(error);
    }

    if (error.config.url.indexOf("/WebUI/FindOrders/PickJob?pickJobId") != -1 && error.config.method.toLowerCase() === "delete") {
        return Promise.reject(error);
    }

    if (error.config.url.indexOf("WebUI/Items/Items/Item") != -1 && (error.config.method.toLowerCase() === "put" || error.config.method.toLowerCase() === "post")) {
        if(error.response && error.response.data && error.response.data.Descriptions && error.response.data.Descriptions.length > 0) {
            if(error.response.data.Descriptions[0].Descriptors.join(",").indexOf('ItemSummaryList.i=WMS.V2.Customer.Models.ItemSummaryLis') >= 0
                && error.response.data.Descriptions[0]['ErrorCode'] == "Duplicate" && error.response.data.Descriptions[0]['Hint'] == 'Duplicate Upc') {
                return Promise.reject(error);
            }
        }
    }
    if (error && error.response && error.response.status == 401) {
        var tplguid = $.urlParam('tplguid');
        var urlStringNoParams = window.location.origin + window.location.pathname;
        var tplguidQueryParam = '';
        if (tplguid) {
            tplguidQueryParam = '&tplguid=' + tplguid;
        }
        var url = window.location.origin + '/webui/login?callbackUri=';
        if(typeof globalsMsg != 'undefined' && typeof globalsMsg.auth_url != 'undefined'){
            url = globalsMsg.auth_url;
        }
        window.location.replace(url + urlStringNoParams + tplguidQueryParam);
        return;
    }

    var apiLogObj = {
        'url': error.config.method + " " + error.config.url,
        'response': error.message,
        'statusCode': '-',
        'platform': "v2",
        'category': 1,
        'time': 0,
        'type':'error'
    }
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        apiLogObj.response = JSON.stringify(error.response.data),
            apiLogObj.statusCode = error.response.status;
    }

    if (globalsMsg.clientLogs &&
        globalsMsg.clientLogs.request &&
        apiLogObj.statusCode != 401) {
        window.setApiInLocalstorage(apiLogObj);
    }
    error.config.responseTimestamp = new Date().getTime();
    // Do something with response error
    // Display error message for axios call

    /**Generate object for popup start */
    if(error.response) {
        generateObjForPopup(error.response);
    }
    return Promise.reject(error);
}

export const getFileSizeString = function(size) {
    return size ?
        (size > 1048576 ? (size / 1048576).toFixed(2) + ' MB' : (size / 1024).toFixed(2) + ' KB')
         : '';
}
export const getFileSizeOfBase64 = function(base64String) {
    var stringLength = base64String.length - 'data:image/png;base64,'.length;
    return 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
}

export const convertBlobToJson = function(blobData) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            let jsonData = JSON.parse(reader.result);
            resolve(jsonData);
        };

        reader.readAsText(blobData);
    });
}
export const generateObjForPopup = function(param_obj){
    // console.log("_data send start",JSON.stringify(param_obj));

    let config = {};
    let status = param_obj.status;
    if(param_obj.config) {
        config.url = param_obj.config.url;
        config.method = param_obj.config.method;
        // config.data = param_obj.config.data;
    }
    if(param_obj.data){
        param_obj = param_obj.data;
    }
    if (typeof param_obj.Descriptions == "undefined") {
        param_obj = {
            "ErrorClass": "",
            "ErrorCode": "",
            "Descriptors": [],
            "Hint": null,
            "Descriptions":[]
        };
    }
    param_obj.status = status;
    param_obj.config = config;
    param_obj.ErrorClass = (param_obj.Descriptions.length >= 1) ? param_obj.Descriptions[0].ErrorClass : "";
    param_obj.ErrorCode = (param_obj.Descriptions.length >= 1) ? param_obj.Descriptions[0].ErrorCode : "";
    param_obj.type = ((param_obj.status >= 500) ? 'error' : 'warning');

    /**Generate object for popup end */
    showErrorMessage(param_obj);
}
export const addRowNumberField = (param_data) => {
    var index = 0;
    for (var i = 0; i < param_data.length; i++) {
        var firstItem = param_data[i];
        index++;
        firstItem["RowNumber"] = index;
    }
    return param_data;
}

export const getCustomerDictList = function () {
    return Object.assign({}, Customers.CustomerDict);
}

export function getCustomerById(customerId) {
    var customerDict = getCustomerDictList();
    return customerDict.hasOwnProperty(customerId) ? customerDict[customerId] : null;
}
export function getOutboundDashboardUrl(){
    return globalsMsg.outbound_dashboard_url;
}

export function trackEventInPendo(url, methodType, status, requestData, responseData) {
    if (globalsMsg && globalsMsg.pendo && window.pendo) {
        var pendoTrackObj = {
            mvcUrl: url,
            pageUrl: 'page=' + ($.urlParam('page') ? $.urlParam('page') : ''),
            activeTab: ($(".tab-list div.active h5").text() + "  " + $(".tab-list div.active p:first").text() + "  " + $(".tab-list div.active p:last").text()).trim(),
            methodType: methodType,
            status: status,
            requestorContextId: responseData && responseData.RequestorContextId ? responseData.RequestorContextId : "",
        };
        if (globalsMsg.allowPendoRequestResponseTracking) {
            pendoTrackObj.request = requestData;
            pendoTrackObj.response = JSON.stringify(responseData);
        }
        if (window.pendo?.track) {
            window.pendo.track("5xxError", pendoTrackObj);
        }
    }
}

export function hideDeactivatedShipmentServices (carrier, shipments, value = null) {
    let shipmentServices = {};
    shipmentServices = _.filter(shipments, function(objShipmentServices){
        return objShipmentServices.Deactivated === false;
    });

    let valueCode = {};
    if (value != null) {
        valueCode = _.find(shipments, function(objShipmentServices) {
            return objShipmentServices.Code === value;
        });
    }

    let shipmentData = {
        shipmentServices : shipmentServices,
        valueCode : valueCode,
    };

    return shipmentData;
}

export function showFirstNChar(text, count){
    if(text != undefined && text != null) {
        return text.slice(0, count) + (text.length > count ? "..." : "");
    } else {
        return;
    }
}

export function getAllShortedColumnList(allColumnList) {
    let allSortColumnList = allColumnList.sort(function (first, second) {

        var columnTitle1 = first.title.trim().toUpperCase(); // ignore upper and lowercase
        var columnTitle2 = second.title.trim().toUpperCase(); // ignore upper and lowercase

        if (columnTitle1 < columnTitle2) { return -1; }

        if (columnTitle1 > columnTitle2) { return 1; }
        // names must be equal
        return 0;
    });
    return allSortColumnList;
}
