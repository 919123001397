import React, { Component } from 'react'
import { reportIframe } from '../../reportIframe/report-iframe.component.jsx';

export class MobilePrintingListHolder extends Component {
    constructor(props) {
        super(props)
        
        this.translate = window.translate;
        this.$rootScope = window;


    }
    openIframe = (param_ifram_url, param_iframe_title) => () => {
        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            id: param_ifram_url,
            isIframe: true,
            title: param_iframe_title,
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: param_iframe_title
            }],
            icon: 'submenu-icon fa fa-cog'
        });
    }


    render() {
        return (
            <div id="reportListController" className="iframe-controller">
                <div className="iframebox">
                    <ul>
                        <li>
                            <ul>
                                <li >
                                    <a
                                    href="javascript:void(0);"
                                    onClick={this.openIframe("/WebUI/V1/V1Link/MobileLabelTemplate.aspx", "Ifrane_title_Label_Templates")}>
                                        {this.translate('Ifrane_title_Label_Templates')}
                                    </a>
                                </li>
                                <li >
                                    <a
                                    href="javascript:void(0);"
                                    onClick={this.openIframe("/WebUI/V1/V1Link/MobileLabel.aspx", "Ifrane_title_Labels")}>
                                        {this.translate('Ifrane_title_Labels')}
                                    </a>
                                </li>
                                <li >
                                    <a
                                    href="javascript:void(0);"
                                    onClick={this.openIframe("/WebUI/V1/V1Link/MobilePrinter.aspx", "Ifrane_title_Add_Printers")}>
                                        {this.translate('Ifrane_title_Add_Printers')}
                                    </a>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
