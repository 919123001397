
import React from 'react';
import WmsConfirmModel from '../../../components/WmsConfirmModel/WmsConfirmModel.jsx';
import * as findOrdersService from '../findOrders.services.jsx';
import * as GlobalService from './../../../global_services';
export class ConfirmationModal extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window
        var splitOverallocateHtmlText = '<label class="wms-form-label"><i>' + this.translate('Label_Split_Selected_Explanation') + '</i></label>' +
            '<div ng-show="ShowSplitSelectedInfo"><div class="wms-sprite fa fa-exclamation-triangle"></div><div>' +
            '<i><strong>' + this.translate('Label_Split_Selected_Warning_One') + '</strong></i>' +
            '</div><div class="wms-sprite fa fa-exclamation-triangle"></div><div>' +
            '<i><strong>' + this.translate('Label_Split_Selected_Warning_Two') + '</strong></i>'
        '</div></div>';
        var shortSlipOrderText = '<label class="wms-form-label"><i>' + this.translate('Label_Short_Ship_Orders_Cofirm_Msg') + '</i></label>' +
        '<br><br><div ng-show="ShowSplitSelectedInfo"><i><strong>Note: </strong></i> '+ this.translate('Label_Short_Ship_Orders_Cofirm_Msg_Note') + '</div>';
        this.state = {
            splitOverallocateText: splitOverallocateHtmlText,
            shortSlipOrderText: shortSlipOrderText
        }
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    reOpenClickContinue = () => {
        findOrdersService.reopenOrders(this.props.SharedSelectedOrderIds).then((response) => {
            if (response ) {
                GlobalService.notification('success', this.translate('Label_Reopen_Success'));
                this.props.onResetGridSelection();
            }
            this.onCloseReopenModal();
        });
    }
    onCloseReopenModal = () => {
        this.props.onCloseReopenModal();
    }


    unAllocateClickContinue = () => {
        findOrdersService.unallocateOrders(this.props.SharedSelectedOrderIds).then((response) => {
            if (response && (response.status == 200 || response.status == 204)) {
                GlobalService.notification('success', this.translate('Label_Unallocate_Success'));
                this.props.onResetGridSelection();
            }
            this.onCloseUnallocateModal();
        });
    }

    onCloseUnallocateModal = () => {
        this.props.onCloseUnallocateModal();
    }


    reAllocateClickContinue = () => {
        this.props.closeReallocateConfirmationModal(true);
        this.$rootScope.animationMoveIcon('[data-wms-selector="OrderReallocateContinueButton"]');
        this.$rootScope.taskStarted();

        var transferOrderObj =  {
            "type":"reallocation",
            "reallocation": { orderIds: this.props.SharedSelectedOrderIds, facilityId: this.props.SameFacilityId },
            "callBackFunc": this.reallocateOrders,
            'numSelectedOrders': this.props.SharedSelectedOrderIds.length
        }
        var event = new CustomEvent("showProgressModal", {
            detail: transferOrderObj
        });
        document.dispatchEvent(event);
    }

    reallocateOrders = (response) => {
        if (
            response.data.TotalOrdersNotReallocated == 0 &&
            (
                response.data.FaultedOrders == null ||
                (
                    response.data.FaultedOrders &&
                    response.data.FaultedOrders.Faults &&
                    response.data.FaultedOrders.Faults.length == 0
                )
            )
        ) {
            GlobalService.notification('success', this.translate('Label_Reallocate_Success'));
            this.props.onResetGridSelection();
            this.onCloseReallocateModal();
        } else {
            if(response.data.TotalOrdersNotReallocated !=  response.data.TotalOrders){
                this.props.onResetGridSelection();
            }
            this.onCloseReallocateModal(response.data);
        }
    }

    onCloseReallocateModal = (param_result_msg) => {
        this.props.onCloseReallocateModal(param_result_msg);
    }

    splitOverallocateClickContinue = () => {
        findOrdersService.splitOrders(this.props.SharedSelectedOrderIds).then((response) => {
            if (response) {
                GlobalService.notification('success', this.translate('Label_Split_Success'));
                this.props.onResetGridSelection();
            }
            this.onCloseSplitOverallocateModal();
        });
    }

    shortShipOrdersClickContinue = () => {
        findOrdersService.shorterShipOrders(this.props.SharedSelectedOrderIds).then((response) => {
            if (response) {
                GlobalService.notification('success', this.translate('Label_Short_Ship_Success'));
                this.props.onResetGridSelection();
            }
            this.props.onCloseShipOrders();
        });
    }

    onCloseSplitOverallocateModal = () => {
        this.props.onCloseSplitOverallocateModal();
    }

    render() {
        return (
            <div className="content-wrapper" >
                {/* Reopen order model code */}
                <WmsConfirmModel
                    id="orderReopenModal"
                    isOpen={this.props.isOpenReopenModal}
                    width={500}
                    height={240}
                    firstBtnLabel={this.translate('RoleManager_Label_Continue')}
                    firstBtnSelector={"OrderContinueButton"}
                    firstBtnIcon={"fa fa-check-square-o"}
                    firstBtnOnClickFunc={this.reOpenClickContinue}
                    thirdBtnLabel={this.translate('Label_Cancel')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText={this.translate('Label_Reopen_Warning').replace(new RegExp('{' + 'amount' + '}', 'gi'), this.props.SharedSelectedOrderIds.length)}
                    sawCheckBox={false}
                    onCloseFunc={this.onCloseReopenModal}
                />
                {/* order un allcated model code */}
                <WmsConfirmModel
                    id="orderUnallocateModal"
                    isOpen={this.props.isOpenUnallocateModal}
                    width={500}
                    height={240}
                    firstBtnSelector={"OrderUnallocateContinueButton"}
                    firstBtnLabel={this.translate('RoleManager_Label_Continue')}
                    firstBtnIcon={"fa fa-check-square-o"}
                    firstBtnOnClickFunc={this.unAllocateClickContinue}
                    thirdBtnLabel={this.translate('Label_Cancel')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText={this.translate('Label_Unallocate_Warning').replace(new RegExp('{' + 'amount' + '}', 'gi'), this.props.SharedSelectedOrderIds.length)}
                    sawCheckBox={false}
                    onCloseFunc={this.onCloseUnallocateModal}
                />
                {/* Order Re-allocate model code */}
                <WmsConfirmModel
                    id="orderReallocateModal"
                    isOpen={this.props.isOpenReallocateModal}
                    width={500}
                    height={240}
                    firstBtnSelector={"OrderReallocateContinueButton"}
                    firstBtnLabel={this.translate('RoleManager_Label_Continue')}
                    firstBtnIcon={"fa fa-check-square-o"}
                    firstBtnOnClickFunc={this.reAllocateClickContinue}
                    thirdBtnLabel={this.translate('Label_Cancel')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText={this.translate('Label_Reallocate_Warning').replace(new RegExp('{' + 'amount' + '}', 'gi'), this.props.SharedSelectedOrderIds.length)}
                    sawCheckBox={false}
                    onCloseFunc={this.props.closeReallocateConfirmationModal}
                />
                {/*Split overallocate Order model code */}
                <WmsConfirmModel
                    id="orderSplitOverallocateModal"
                    isOpen={this.props.isOpenSplitOverallocateModal}
                    width={900}
                    height={300}
                    firstBtnLabel={this.translate('RoleManager_Label_Continue')}
                    firstBtnIcon={"fa fa-check-square-o"}
                    firstBtnOnClickFunc={this.splitOverallocateClickContinue}
                    thirdBtnLabel={this.translate('Label_Cancel')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText={this.state.splitOverallocateText}
                    sawCheckBox={false}
                    onCloseFunc={this.onCloseSplitOverallocateModal}
                />
                {/*Add or exist batch model */}
                <WmsConfirmModel
                    id="BatchCofirmModel"
                    isOpen={this.props.isOpenBatchConfirmModal}
                    width={500}
                    height={180}
                    firstBtnLabel={this.translate('Label_Continue')}
                    firstBtnIcon={"fa fa-check-square-o"}
                    firstBtnOnClickFunc={this.props.onContinueBatchModal}
                    thirdBtnLabel={this.translate('Label_Discard_Exit')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText={this.translate('Label_Orders_PartOfBatch')}
                    sawCheckBox={false}
                    onCloseFunc={this.props.onCloseBatchModal}
                />
                {/*Remove batch model */}
                <WmsConfirmModel
                    id="RemoveBatchCofirmModel"
                    isOpen={this.props.isOpenRemoveBatchConfirmModal}
                    width={500}
                    height={180}
                    firstBtnLabel={this.translate('Label_Continue')}
                    firstBtnIcon={"fa fa-check-square-o"}
                    firstBtnSelector={"RemoveBatchContinueButton"}
                    firstBtnOnClickFunc={this.props.onContinueRemoveBatchModal}
                    thirdBtnLabel={this.translate('Label_Exit')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText={this.translate('Label_Orders_RemoveFromBatch')}
                    sawCheckBox={false}
                    onCloseFunc={this.props.onCloseRemoveBatchModal}
                />
                {/*short ship orders */}
                <WmsConfirmModel
                    id="ShortShipOrders"
                    isOpen={this.props.isOpenConfirmForShipOrders}
                    width={500}
                    height={295}
                    firstBtnLabel={this.translate('Label_Ok')}
                    firstBtnIcon={"fa fa-check-square-o"}
                    firstBtnSelector={"RemoveBatchContinueButton"}
                    firstBtnOnClickFunc={this.shortShipOrdersClickContinue}
                    thirdBtnLabel={this.translate('Label_Cancel')}
                    thirdBtnIcon={"fa fa-times-circle"}
                    conFormModelText={this.state.shortSlipOrderText}
                    sawCheckBox={false}
                    onCloseFunc={this.props.onCloseShipOrders}
                />
            </div>
        )
    }
}