import React from 'react';
import { StringWms } from './../../../../../assets/ts/StringWms.ts';

//component
import WmsButton from './../../../components/WmsButton/WmsButton.jsx';
import WmsCollapse from './../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from './../../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from './../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsModel from './../../../components/WmsModel/WmsModel.jsx';
import './create-manual-charge-model.scss';
import { Big } from "./../../../global_variable_service.jsx";
import * as GlobalService from './../../../global_services';

export class CreateManualChargeModel extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.defauleManualCharge = {
            indexChargeCategory: 0,
            chargeUnitType: '',
            chargePerUnit: 0,
            numberOfUnits: 0,
            total: '0',
            glAccNumber: '',
            extAccReceivable: '',
            externalItemIdentifier: '',
            externalItemDesc: ''
        };
        this.state = {
            selector: "OrderCharges",
            manualCharges: JSON.parse(JSON.stringify(this.defauleManualCharge)),
            rights: GlobalService.GetRights()
        };
    }

    // componentWillReceiveProps(nextProps) {
    //     if(this.props.sharedIsOpenManualChargeModal == true && this.props.sharedIsOpenManualChargeModal != nextProps.sharedIsOpenManualChargeModal)
    //     this.setState({ manualCharges: JSON.parse(JSON.stringify(this.defauleManualCharge)) });
    // }


    handleEventChange = (event) => {
        let manualCharges = this.state.manualCharges;
        manualCharges[event.target.name] = event.target.value;

        //calculate total charges
        if (event.target.name == 'chargePerUnit' || event.target.name == 'numberOfUnits') {

            let param_chargePerUnit = (event.target.name == 'chargePerUnit') ?
                event.target.value :
                this.state.manualCharges.chargePerUnit;

            let param_numberOfUnits = (event.target.name == 'numberOfUnits') ?
                event.target.value :
                this.state.manualCharges.numberOfUnits;
            if (
                !isNaN(parseFloat(param_chargePerUnit)) &&
                !isNaN(parseFloat(param_numberOfUnits))
            ) {
                manualCharges['total'] = parseFloat((new Big(param_chargePerUnit || 0)).times(param_numberOfUnits || 0).toFixed(4));
            }
        }

        this.setState({ 'manualCharges': manualCharges });
    }

    addManualChargeGrid = () => {
        this.props.onAddShipAndCloseGridData({
            'ChargeOrigin': 2,
            'ChargeTypeId': this.props.sharedManualChargeOptions[this.state.manualCharges.indexChargeCategory].Id,
            'ChargeType': this.props.sharedManualChargeOptions[this.state.manualCharges.indexChargeCategory].Description,
            'ChargeLabel': this.state.manualCharges.chargeLabel,
            'Description': this.state.manualCharges.chargeUnitType,
            'ChargePerUnit': this.state.manualCharges.chargePerUnit,
            'NumberOfUnits': this.state.manualCharges.numberOfUnits,
            'TotalCharges': this.state.manualCharges.total,
            'GeneralLedgerAccount': this.state.manualCharges.glAccNumber,
            'ExternalAccountsReceivable': this.state.manualCharges.extAccReceivable,
            'ExternalItemIdentifier': this.state.manualCharges.externalItemIdentifier,
            'ExternalItemDescription': this.state.manualCharges.externalItemDesc
        });
        this.props.onCloseAddManualChargeModel();
    }

    render() {
        return (
            <WmsModel
                id={'createManualCharge' + this.props.windowId}
                title={this.translate('Label_Add_Manual_Charge')}
                class="createManualCharge"
                width={500}
                height={500}
                windowId={this.props.windowId}
                onCloseFunc={this.props.onCloseAddManualChargeModel}
                isOpen={this.props.sharedIsOpenManualChargeModal}
                isAnotherModelOpen={this.props.isAnotherModelOpen}
                customClass='wms-model-new-ui-wrapper'>
                <div className='model-content-wrapper'>
                    <WmsCollapse
                        id={'manualChargeRoutingInformation'}
                        headerLabel={this.translate('Label_Routing_Information')}
                        showCollapseText={false}
                        showCollapseIcon={false} >

                        <WmsDropdown
                            id="indexChargeCategory"
                            name="indexChargeCategory"
                            label={this.translate('Label_Charge_Category')}
                            wmsSelector={this.state.selector + "Category"}
                            value={this.state.manualCharges.indexChargeCategory}
                            onChangeFunc={this.handleEventChange}
                            options={this.props.sharedManualChargeOptions}
                            textField='Description'
                            valueField='Description'
                            applyIndex={true} />

                        <WmsInput
                            id="chargeLabel"
                            inputName="chargeLabel"
                            wmsSelector={this.state.selector + 'Label'}
                            value={this.state.manualCharges.chargeLabel}
                            onChangeFunc={this.handleEventChange}
                            maxLength={255}
                            regularExp={/^\w+(\s\w+)*(\s\w*)?$/}
                            label={this.translate('Label_Charge_Label')}
                            required={true} />

                        <WmsInput
                            id="chargeUnitType"
                            inputName="chargeUnitType"
                            wmsSelector={this.state.selector + 'UnitType'}
                            value={this.state.manualCharges.chargeUnitType}
                            onChangeFunc={this.handleEventChange}
                            maxLength={255}
                            regularExp={/^\w+( \w+)*$/}
                            label={this.translate('Label_Type_Of_Charge_Unit')}
                            required={true} />

                        <WmsInput
                            inputName="chargePerUnit"
                            id="chargePerUnit"
                            wmsSelector={this.state.selector + 'PerUnit'}
                            value={this.state.manualCharges.chargePerUnit}
                            onChangeFunc={this.handleEventChange}
                            maxLength={255}
                            valueType={"negativeNumber"}
                            label={this.translate('Label_Charge_Per_Unit')}
                            required={true} />

                        <WmsInput
                            id="numberOfUnits"
                            inputName="numberOfUnits"
                            wmsSelector={this.state.selector + 'NumberOfUnits'}
                            value={this.state.manualCharges.numberOfUnits}
                            onChangeFunc={this.handleEventChange}
                            maxLength={255}
                            label={this.translate('Label_Number_Of_Units')}
                            valueType={"negativeNumber"}
                            required={true} />

                        <WmsInput
                            inputName="total"
                            wmsSelector={this.state.selector + 'Total'}
                            value={this.state.manualCharges.total}
                            onChangeFunc={this.handleEventChange}
                            maxLength={255}
                            disabled={true}
                            label={this.translate('Label_Calculated_Totals')} />

                        <WmsInput
                            inputName="glAccNumber"
                            wmsSelector={this.state.selector + 'GlAccNumber'}
                            value={this.state.manualCharges.glAccNumber}
                            onChangeFunc={this.handleEventChange}
                            maxLength={255}
                            regularExp={/^\w+( \w+)*$/}
                            label={this.translate('Label_General_Ledger_Account')} />
                        {this.state.rights.hasOwnProperty("Integrations.isNotActiveBillingManagerApp") && (
                            <>
                            <WmsInput
                                inputName="extAccReceivable"
                                wmsSelector={this.state.selector + 'ExtAccReceivable'}
                                value={this.state.manualCharges.extAccReceivable}
                                onChangeFunc={this.handleEventChange}
                                maxLength={255}
                                regularExp={/^\w+( \w+)*$/}
                                label={this.translate('Label_External_Account_Receivable')} />

                            <WmsInput
                                inputName="externalItemIdentifier"
                                wmsSelector={this.state.selector + 'ExternalItemIdentifier'}
                                value={this.state.manualCharges.externalItemIdentifier}
                                onChangeFunc={this.handleEventChange}
                                maxLength={255}
                                regularExp={/^\w+( \w+)*$/}
                                label={this.translate('Label_External_Item_Identifier')} />

                            <WmsInput
                                inputName="externalItemDesc"
                                wmsSelector={this.state.selector + 'ExternalItemDesc'}
                                value={this.state.manualCharges.externalItemDesc}
                                onChangeFunc={this.handleEventChange}
                                maxLength={255}
                                regularExp={/^\w+( \w+)*$/}
                                label={this.translate('Label_External_Item_Description')} />
                            </>

                        )}

                    </WmsCollapse>
                </div>
                <div className='footer-btn-holder'>
                    <WmsButton
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.state.selector + "ClearChangesAndExit"}
                        onClickFunc={this.props.onCloseAddManualChargeModel}
                        buttonType='orange'
                        icon="fa-ban" />
                    <WmsButton
                        label={this.translate('Label_Add_Charge')}
                        wmsSelector={this.state.selector + "AddCharge"}
                        onClickFunc={this.addManualChargeGrid}
                        icon={'fa-plus-circle'}
                        disabled={
                            this.state.manualCharges.chargeLabel == '' ||
                            this.state.manualCharges.chargeUnitType == '' ||
                            this.state.manualCharges.chargePerUnit == 0 ||
                            this.state.manualCharges.numberOfUnits == 0
                        } />
                </div>
            </WmsModel>
        );
    }
}