import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { KendoWms } from '../../../assets/ts/KendoWms.ts';
import { DictionaryWms } from '../../../assets/ts/DictionaryWms.ts';
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import * as warehouseService from './warehouse.service.jsx';

import WmsGrid from './../components/WmsGrid/WmsGrid.jsx';
import * as GlobalService from './../global_services';
import * as GridConfig from './grid_configuration.jsx';

export class WarehouseGrid extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;

        this.state = {
            columns: GridConfig.getGridDemoColumns().columns,
            schema: GridConfig.getGridDemoSchema().schema,
            transport: GridConfig.getGridDemoTransport().transport,
            rights: GlobalService.GetRights(),
            currentLogedUser: GlobalService.GetCurrentUser(),
            localKendoGrid: null,
            searchOn: false,
            enableEditButton: false,
            enableActiveButton: false,
            enableDeactiveButton: false,
            Selector: 'Warehouse',
            optionConatins: [],
            booleanOption: [],
            warehouseGridData: [],
            filterObject: [],
            warehouseOption: {},
            warehouseEditData: null,
            deActiveMessage:'',
            filter: {
                IndexOptionsWarehouse: 0,
                Warehouse: "",
                IndexOptionsIsActive: 0
            }
        }

        this.gridSearch = GlobalService.debounce(this.gridSearch, 700);
    }

    componentDidMount() {

        this.setState({
            optionConatins: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchFilters')),
            booleanOption: StringWms.convertSringToOption(this.translate('Options_Warehouse_StatusOption'))
        });
        console.log("WarehouseGrid componentDidMount reloadGrid reloadWarehouseGrid");

        let loaderSourceId = 'findWarehouseGrid';
        if (!this.state.warehouseGridData.length) {
            loaderSourceId = this.props.windowId;
        }
        kendo.ui.progress($('#' + loaderSourceId), true);

        // this.initialFindWarehouses();
    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.reloadGrid == 'reloadWarehouseGrid') {
            console.log("WarehouseGrid componentWillReceiveProps reloadGrid reloadWarehouseGrid");
            this.initialFindWarehouses();
        }
    }

    //when select any row enable / disable button or context menu
    selectRow = (param_kendogrid) => {

        let currentSelections = param_kendogrid.getNumberOfSelections();
        let objActive = param_kendogrid.sumKeywordValues("Active");
        let objCanDeactivate = param_kendogrid.sumKeywordValues("CanDeactivate");
        let enableEditButton = false;
        let enableActiveButton = false;
        let enableDeactiveButton = false;
        let warehouseEditData = null;
        let deActiveMessage = this.translate('Label_ManagewWarehouse_DisableDeactiveNote');;

        if (currentSelections === 1) {
            enableEditButton = true;
            warehouseEditData = param_kendogrid.getItem(param_kendogrid.getKeys());
        }

        if (objActive.hasOwnProperty("false") && currentSelections === 1) {
            enableActiveButton = true;
            deActiveMessage = this.translate('note_warehouse_deactivate');
        }

        if (objActive.hasOwnProperty("true") && objCanDeactivate.hasOwnProperty("true") && currentSelections === 1) {
            enableDeactiveButton = true;
        }

        this.setState({
            enableEditButton: enableEditButton,
            localKendoGrid: param_kendogrid,
            enableActiveButton: enableActiveButton,
            enableDeactiveButton: enableDeactiveButton,
            warehouseEditData: warehouseEditData,
            deActiveMessage:deActiveMessage
        });
    }

    gridSearch = () => {
        let searchOn = false

            let dataArr = new Array();
            if (this.state.filter.Warehouse != "") {

                dataArr.push({
                    field: "Name",
                    operator: KendoWms.getFilterOperatorByIndex(this.state.filter.IndexOptionsWarehouse),
                    value: this.state.filter.Warehouse
                });
                searchOn = true;
            }

            if (this.state.filter.IndexOptionsIsActive && this.state.filter.IndexOptionsIsActive !== 'Both') {

                let activeValue = false;
                if (this.state.filter.IndexOptionsIsActive == "Active") {
                    activeValue = true;
                }
                dataArr.push({
                    field: "Active",
                    operator: "eq",
                    value: activeValue
                });
                searchOn = true;
            }

            this.setState({
                filterObject: dataArr,
                searchOn: searchOn
            });

    }

    clearFilters = () => {
        // set default value of filter form
        this.setState({
            filter: Object.assign(
                {},
                this.state.filter,
                {
                    "IndexOptionsWarehouse": 0,
                    "IndexOptionsIsActive": 0,
                    "Warehouse": ""
                }
            ),
            searchOn: false,
            filterObject: []
        });
    }

    filterHandleChange = (event) => {
        this.setState({
            filter: Object.assign(
                {},
                this.state.filter,
                { [event.target.name]: event.target.value }
            ),
        }, () => {
            this.gridSearch();
        });
    }

    clickEventOfContextMenu = (obj) => {

        if (obj.wmsid == 'edit') {
            this.onEditWarehouse();
        } else if (obj.wmsid == 'activate') {
            this.activateDeactivateWarehouse(true);
        } else if (obj.wmsid == 'deactivate') {
            this.activateDeactivateWarehouse(false);
        }

    }

    activateDeactivateWarehouse = (mybool) => {

        let gridSelectedKeys = this.state.localKendoGrid.getKeys();
        let currentLogedUserid = this.state.currentLogedUser.UserId.toString();


        if ((gridSelectedKeys.indexOf(currentLogedUserid)) != -1 && mybool == false) {
            GlobalService.notification('error', this.translate('Label_Cannot_Deactivate'));
        } else {
            let dataItem = this.state.localKendoGrid.getItem(gridSelectedKeys);
            warehouseService.updateWarehouseActivation(dataItem['EditUri'], mybool).then((response) => {

                if (typeof response != "undefined" && response.status == 200) {
                    this.initialFindWarehouses();
                    GlobalService.notification('success', this.translate('Label_Operation_Successful'));
                }

            }).finally(() => { });
        }

    }

    onLoadWarehouseGrid = () => {
        this.initialFindWarehouses();
    }

    initialFindWarehouses = () => {

        let loaderSourceId = 'findWarehouseGrid';
        if (!this.state.warehouseGridData.length) {
            loaderSourceId = this.props.windowId;
        }
        kendo.ui.progress($('#' + loaderSourceId), true);

        warehouseService.initialFindWarehouses().then((response) => {

            if (typeof response != "undefined" && response.status == 200) {

                this.setState({
                    warehouseGridData: response.data.Facilities,
                    warehouseOption: response.data.Options,
                    enableEditButton: false,
                    enableActiveButton: false,
                    enableDeactiveButton: false,
                });

                this.gridSearch();
                this.props.onReceiveWarhouseOptions(response.data.Options);

            }

        }).finally(() => {
            kendo.ui.progress($('#' + loaderSourceId), false);
        });
    }

    onEditWarehouse = () => {
        this.props.onEditWarehouse(this.state.warehouseEditData);
    }

    render() {
        return (
            <div className="warehouse-grid">
                <div className="wh-topbtn-holder">
                <WmsButton
                    label={this.translate('Label_Clear_Filters')}
                    wmsSelector={this.state.Selector + 'ButtonFilterClear'}
                    onClickFunc={this.clearFilters}
                    icon='fa-minus'
                    buttonType="orange" />

                    {this.state.searchOn == true && (
                        <div className="search-applied" >
                            <label>{this.translate('Label_Search_Applied_Filter')}</label>
                        </div>
                    )}
                </div>
                <div className={"warehouse-form-boxes"}>
                    <WmsCollapse
                    id={'collapseWarehouseFilter'}
                    headerLabel={this.translate("Filter_Criteria_Label")}
                    showCollapseText={false}
                    showCollapseIcon={false} >
                        <div className=" wms-input-group-wrapper">
                            <WmsDropdown
                                id="IndexOptionsWarehouse"
                                name="IndexOptionsWarehouse"
                                label={this.translate('Dropdown_Label_Warehouse')}
                                wmsSelector={this.state.Selector + 'FilterDropdownWarehouse'}

                                value={this.state.filter.IndexOptionsWarehouse}
                                onChangeFunc={this.filterHandleChange}
                                options={this.state.optionConatins}
                                valueField='value'
                                textField='description'
                                parentClassName="select_box"
                            />
                            <WmsInput
                                inputName="Warehouse"
                                wmsSelector={this.state.Selector + 'FilterInputWarehouse'}
                                value={this.state.filter.Warehouse}
                                onChangeFunc={this.filterHandleChange}
                                label={this.translate('Label_Warehouse_Name')}
                                parentClassName="input_box" />
                        </div>
                        <WmsDropdown
                            id="IndexOptionsIsActive"
                            name="IndexOptionsIsActive"
                            label={this.translate('Label_Filter_Status')}
                            wmsSelector="indexOptionsIsActive"
                            value={this.state.filter.IndexOptionsIsActive}
                            onChangeFunc={this.filterHandleChange}
                            options={this.state.booleanOption}
                            valueField='description'
                            textField='description'
                        />
                    </WmsCollapse>
                </div>
                    {!this.state.rights.hasOwnProperty('warehouse.create') &&
                        <label className="mail-content">
                            To add, deactivate, or reactivate warehouses, have an authorized agent contact your Customer Success Manager (CSM) or Support@3plcentral.com.
                        </label>
                    }

                        <WmsGrid
                            wmsSelector={'findWarehouseGrid'}
                            gridKeySelection={'FacilityId'}
                            staticGridData={this.state.warehouseGridData}
                            gridName={'GridFindWarehouses'}
                            virtual={false}
                            id={'findWarehouseGrid'}
                            onLoadGrid={this.onLoadWarehouseGrid}
                            columns={this.state.columns}
                            schema={this.state.schema}
                            transport={this.state.transport}
                            showGridSummary={true}
                            showErrorMessageSection={true}
                            windowId={this.props.windowId}
                            shawCheckbox={false}
                            onSelection={this.selectRow}
                            clickEventOfContextMenu={this.clickEventOfContextMenu}
                            filterObject={this.state.filterObject}
                            inlineFilter={true}
                            contextMenuFilter='tbody tr:not(.k-grid-edit-row):not(.wms-selection-group)'
                            menu={
                                [{
                                    "name": "Create",
                                    "value": [],
                                    "disabled": false,
                                    "icon": "fa-plus",
                                    "color": "green",
                                    "wmsSelector": this.state.Selector + "ButtonCreate",
                                    clickFunc: ((e) => this.props.onCreateWarehouse()),
                                    'isContextMenu': false,
                                    'isButtonMenu': true,
                                    'isButtonShaw': this.state.rights.hasOwnProperty('warehouse.create'),
                                    'wmsid': 'create'
                                },
                                {
                                    "name": "Edit",
                                    "value": [],
                                    "disabled": !this.state.enableEditButton,
                                    "icon": "fa-pencil",
                                    'isContextMenu': true,
                                    'isButtonMenu': true,
                                    "wmsSelector": this.state.Selector + "EditButton",
                                    clickFunc: ((e) => this.onEditWarehouse()),
                                    'wmsid': 'edit'
                                },
                                {
                                    "name": "Manage",
                                    "wmsSelector": this.state.Selector + "Manage",
                                    "value": [{
                                        "name": "Activate",
                                        "value": [],
                                        "disabled": !this.state.enableActiveButton,
                                        "disabledText": this.translate('note_warehouse_activate'),
                                        "icon": "fa-bandcamp",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        'wmsid': 'activate',
                                        "wmsSelector": this.state.Selector + "ActivateButton",
                                        onClickFunc: ((e) => this.activateDeactivateWarehouse(true))
                                    }, {
                                        "name": "Deactivate",
                                        "value": [],
                                        "disabled": !this.state.enableDeactiveButton,
                                        "disabledText": this.state.deActiveMessage,
                                        "icon": "fa-wrench",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': true,
                                        'wmsid': 'deactivate',
                                        "wmsSelector": this.state.Selector + "DeactivateButton",
                                        onClickFunc: ((e) => this.activateDeactivateWarehouse(false))
                                    }],
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu':this.state.rights.hasOwnProperty('warehouse.create'),
                                    'isButtonMenu': this.state.rights.hasOwnProperty('warehouse.create')
                                }, {
                                    "name": '',
                                    "wmsSelector": this.props.Selector + 'RefreshGrid',
                                    "value": [],
                                    "clickFunc": this.initialFindWarehouses,
                                    "disabled": false,
                                    "icon": "fa-sync-alt",
                                    "color": "blue",
                                    'isContextMenu': false,
                                    'isButtonMenu': true,
                                    'parentClassName':'WarehouseRefreshBtn'
                                }, {
                                    "name": "Copy",
                                    "value": [{
                                        "name": this.translate('Label_Copy_Field'),
                                        "value": [],
                                        "disabled": false,
                                        "disabledText": this.translate('Label_Copy_Field'),
                                        "icon": "fa-bandcamp",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': false,
                                        'wmsid': 'copyField',
                                        "wmsSelector": "copyToClicpboard",
                                    }, {
                                        "name": this.translate('Label_Copy_Row'),
                                        "value": [],
                                        "disabled": false,
                                        "disabledText": this.translate('Label_Copy_Row'),
                                        "icon": "fa-wrench",
                                        "color": "green",
                                        'isContextMenu': true,
                                        'isButtonMenu': false,
                                        'wmsid': 'copyRow'
                                    }],
                                    "disabled": false,
                                    "disabledText": "You may not add blahssss0",
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false
                                }]
                            }>
                        </WmsGrid>
                    </div>
        )
    }
}