import * as GlobalService from '../global_services';
var isFirstTimeOpen = true;
var keyName = "Themes";
var metaDataOrderObject = {
    "Themes": {
        "defaultTheme": {},
        "savedTheme":[]
    }
};


// call api for get metaData of theme is it's open first time
export const getUserClientMetaData = function () {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response != "") {
                    Object.assign(metaDataOrderObject[keyName], JSON.parse(response));
                }
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                console.log(response);
                return false;
            }
        );
    }
}

export const setDefaultThemeMetaData = function (themeJson) {
    metaDataOrderObject[keyName]['defaultTheme'] = themeJson;
    return GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const getDefaultThemeMetaData = function () {
    return metaDataOrderObject[keyName]['defaultTheme'];
}

export const setSaveThemeMetaData = function (title, themeObj) {
    var setData = {
        title: title,
        themeData: themeObj
    };
    if (typeof metaDataOrderObject[keyName]['savedTheme'] == "undefined") {
        metaDataOrderObject[keyName]['savedTheme'] = [];
    }
    (metaDataOrderObject[keyName]['savedTheme']).push(setData);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getSaveThemeMetaData = function () {
    return metaDataOrderObject[keyName]['savedTheme'];
}

export const clearSaveThemeListMetaData = function (index) {
    metaDataOrderObject[keyName]['savedTheme'].splice(index, 1);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
