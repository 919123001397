
import React from 'react';
//component
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsGrid from './../components/WmsGrid/WmsGrid.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsModel from './../components/WmsModel/WmsModel.jsx';

import * as locationService from './locations.services.jsx';
import * as GlobalService from './../global_services';

import style from './locations.scss';

import * as GridConfig from './grid_configuration.jsx';

export class LocationsGrid extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        let LocationJob_GridHeaders = StringWms.convertSringToOption(this.translate('FindLocations_GrideHeaders'));

        this.state = {
            customLabels: GlobalService.getCustomLabels(),
            columns: GridConfig.getGridDemoColumns(LocationJob_GridHeaders).columns,
            schema: GridConfig.getGridDemoSchema().schema,
            transport: GridConfig.getGridDemoTransport(this.props.selectedFacilityId).transport,
            locationsEditUri: null,
            localKendoGrid: null,
            searchOn: false,
            enableActive: false,
            enableDeactive: false,
            enableDelete: false,
            enableEditButton: false,
            Selector: 'Location',
            optionConatins: StringWms.convertSringToOption(this.translate('Options_SearchBy_StringOptions')),
            booleanOption: StringWms.convertSringToOption(this.translate('Options_SearchBy_Status')),
            filterObject: [],
            deActiveMessage:'',
            filter: {
                IndexOptionLocationName: 0,
                LocationName: "",
                IndexOptionsIsActive: 0
            },
            isOpenDeleteConfirmModel:false
        }

        this.gridSearch = GlobalService.debounce(this.gridSearch, 700);
    }

    componentDidMount() {

        if (this.props.sharedLocationFilterData != null) {
            this.setState({
                filter: this.props.sharedLocationFilterData,
                searchOn: true
            });
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.selectedFacilityId != this.props.selectedFacilityId) {
            let obj = this.state.transport;
            obj.read.data.facilityId = nextProps.selectedFacilityId;
            this.setState({ transport: obj });
        }

        if (nextProps.reloadGrid == 'reloadLocationGrid') {
            $('#findLocationGrid').data('kendoGrid').dataSource.read();
            if (this.state.localKendoGrid != null) {
                this.state.localKendoGrid.clearSelections(() => {
                    this.state.localKendoGrid.repaintSelections();
                });
                this.setState({ enableEditButton: false });
            }
        }
    }

    //On grid row selection
    selectRow = (KendoGrid) => {

        let enableActive = false;
        let enableDeactive = false;
        let enableDelete = false;
        let enableEditButton = false;
        let currentSelections = KendoGrid.getNumberOfSelections();
        let isActive = KendoGrid.valueExists("Active", true);
        let isDeactive = KendoGrid.valueExists("Active", false);
        let isCandeactive = KendoGrid.valueExists("CanDeactivate", true);
        // select one or more locations and check all locations are canDeactivate or not
        let identicalCanDeactive = KendoGrid.valuesIdentical("CanDeactivate");
        let deActiveMessage = this.translate("note_location_can_deactivate");

        let cannotDelete = KendoGrid.valueExists("CanDelete", false);
        let locationsEditUri = null;

        if (currentSelections == 1) {
            enableEditButton = true;
            locationsEditUri = KendoGrid.getItem(KendoGrid.getKeys()).EditUri;
        }

        if (isActive == false && currentSelections != 0) {
            enableActive = true;
            deActiveMessage = this.translate("note_location_deactivate");
        }

        if (isDeactive == false && isCandeactive == true && identicalCanDeactive == true && currentSelections != 0) {
            enableDeactive = true;
        }

        if (cannotDelete == false && currentSelections != 0) {
            enableDelete = true;
        }


        this.setState({
            localKendoGrid: KendoGrid,
            locationsEditUri: locationsEditUri,
            enableActive: enableActive,
            enableDeactive: enableDeactive,
            enableDelete: enableDelete,
            enableEditButton: enableEditButton,
            deActiveMessage:deActiveMessage
        });
    }
    handleFaciltiySelectChange = (event) => {
        let obj = this.state.transport;
        obj.read.data.facilityId = event.target.value;
        this.setState({ transport: obj });
        this.props.onFaciltiySelectChange(event.target.value);
    }
    filterHandleChange = (event) => {
        this.setState({
            filter: Object.assign(
                {},
                this.state.filter,
                { [event.target.name]: event.target.value }
            ),
        }, () => {
            this.gridSearch();
        });
    }

    clickEventOfContextMenu = (obj) => {

        if (obj.wmsid == 'edit') {
            this.onEditLocation();
        } else if (obj.wmsid == 'activate') {
            this.activateDeactivateLocation(true);
        } else if (obj.wmsid == 'deactivate') {
            this.activateDeactivateLocation(false);
        } else if (obj.wmsid == 'delete') {
            this.onClickDeleteLocation();
        }

    }

    activateDeactivateLocation = (mybool) => {

        let gridSelectedKeys = this.state.localKendoGrid.getKeys();
        let currentLogedUserid = GlobalService.GetCurrentUser().UserId.toString();
        let msgType = 'Label_Location_Activate';

        if(mybool == false){
            msgType = 'Label_Location_Deactivate';
        }
        let msgNotification = this.translate(msgType);

        if ((gridSelectedKeys.indexOf(currentLogedUserid)) != -1 && mybool == false) {
            GlobalService.notification('error', this.translate('Label_Cannot_Deactivate'));
        } else {

            locationService.updateLocationActivation(gridSelectedKeys, mybool, this.props.selectedFacilityId).then((response) => {

                if (typeof response != "undefined" && response.status == 204) {
                    $('#findLocationGrid').data('kendoGrid').dataSource.read();
                    GlobalService.notification('success', this.translate(msgNotification));
                    this.state.localKendoGrid.clearSelections(() => {
                        this.state.localKendoGrid.repaintSelections();
                    });
                    this.setState({
                        enableEditButton: false,
                        enableActive: false,
                        enableDeactive: false,
                        enableDelete: false
                    });
                }

            }, (response) => {
                console.log("Grid w sort ", response);
            });
        }

    }

    deleteLocation = () => {
        let locationIds = this.state.localKendoGrid.getKeys();
        locationService.deleteLocation(locationIds).then((response) => {

            if (typeof response != "undefined" && response.status == 204) {
                $('#findLocationGrid').data('kendoGrid').dataSource.read();
                GlobalService.notification('success', this.translate('Label_Location_Deleted'));
            }

        }, (response) => {
            console.log("Grid w sort ", response);
        });
    }

    onClickDeleteLocation = () => {
        let currentSelections = this.state.localKendoGrid.getNumberOfSelections();
        if(currentSelections == 1){
            this.deleteLocation();
        }else{
            this.setState({
                isOpenDeleteConfirmModel: true
            });
        }
    }


    closeDeleteconfirm = () => {
        this.setState({ isOpenDeleteConfirmModel:false });
    }

    onEditLocation = () => {
        this.props.onEditLocation(this.state.locationsEditUri);
    }

    gridSearch = () => {

            let dataArr = [{
                ShowLocationName: true,
                IndexOptionLocationName: this.state.filter.IndexOptionLocationName,
                LocationName: this.state.filter.LocationName || "",
                ShowIsActive: true,
                IndexOptionsIsActive: this.state.filter.IndexOptionsIsActive,
                ShowLocationType: true,
                IndexOptionsLocationType: 0
            }];

            this.setState({
                searchOn: true,
                filterObject: dataArr
            });
    }

    clearFilters = () => {
        // set default value of filter form
        this.setState({
            filter: {
                "IndexOptionLocationName": 0,
                "LocationName": "",
                "IndexOptionsIsActive": 0
            },
            searchOn: false,
            filterObject: [{ method: 'DELETE' }]
        });
    }

    refreshLocationGrid = () => {
        var grid = $('#findLocationGrid').data('kendoGrid');
        if(grid && grid.dataSource) {
            grid.dataSource.read();
        }
    }

    render() {
        return (
            <div className="location-grid">
            <div className="location-topbtn-holder">
                <WmsButton
                    label={this.translate('RoleManager_Label_Reset')}
                    buttonType='yellow'
                    wmsSelector={this.state.Selector + 'ClearSearchButton'}
                    onClickFunc={this.clearFilters}
                    icon='fa-repeat' />
                    {this.state.searchOn == true && (
                        <div className="search-applied" >
                            <label >{this.translate('Label_Search_Applied_Filter')}</label>
                        </div>
                    )}
                </div>

                    <WmsCollapse
                        id={'collapseLocationFilter'}
                        headerLabel={this.translate("Label_Filter_Search_By_Name")}
                        showCollapseText={false}
                        showCollapseIcon={false} >
                            <WmsDropdown
                                id="selectedFacilityId"
                                name="selectedFacilityId"
                                label={this.state.customLabels.Label_Warehouse}
                                wmsSelector="warehouseDropdown"
                                value={this.props.selectedFacilityId}
                                onChangeFunc={this.handleFaciltiySelectChange}
                                options={this.props.sharedFacilityOption}
                                valueField='id'
                                textField='name'
                                parentClassName='form_group'
                                required={true}
                                blankFirstOption={true}
                            />
                        <div className="wms-input-group-wrapper">
                            <WmsDropdown
                                id="indexOptionslocation"
                                name="IndexOptionLocationName"
                                label={this.translate('Label_Location_Identifier')}
                                wmsSelector="locationIndexDropdown"
                                value={this.state.filter.IndexOptionLocationName}
                                onChangeFunc={this.filterHandleChange}
                                options={this.state.optionConatins}
                                valueField='value'
                                textField='description'
                                parentClassName="select_box"
                            />
                            <WmsInput
                                id='LocationName'
                                inputName='LocationName'
                                wmsSelector={this.state.Selector + 'LocationName'}
                                value={this.state.filter.LocationName}
                                onChangeFunc={this.filterHandleChange}
                                label=''
                                parentClassName='input_box' />
                        </div>
                        <WmsDropdown
                            id="IndexOptionsIsActive"
                            name="IndexOptionsIsActive"
                            label={this.translate('Label_Filter_Status')}
                            wmsSelector="locationStatusDropdown"
                            value={this.state.filter.IndexOptionsIsActive}
                            onChangeFunc={this.filterHandleChange}
                            options={this.state.booleanOption}
                            textField='description'
                            valueField='value'
                        />
                    </WmsCollapse>
                {this.props.selectedFacilityId != '' && (
                <div className="wms-location-wrapper">
                    <WmsGrid
                        wmsSelector={'findLocationGrid'}
                        gridKeySelection={'LocationId'}
                        gridName={'GridLocations'}
                        virtual={true}
                        id={'findLocationGrid'}
                        columns={this.state.columns}
                        schema={this.state.schema}
                        transport={this.state.transport}
                        showGridSummary={true}
                        showErrorMessageSection={true}
                        onSelection={this.selectRow}
                        clickEventOfContextMenu={this.clickEventOfContextMenu}
                        windowId={this.props.windowId}
                        filterObject={this.state.filterObject}
                        getGridSelectionUrl={'/WebUI/Facilities/ManageLocations/GridSelections'}
                        searchUrl={'/WebUI/Facilities/UpdateSession/SearchInfo'}
                        menu={
                            [{
                                "name": "Create",
                                "value": [],
                                "disabled": false,
                                "icon": "fa-plus",
                                "color": "green",
                                clickFunc: this.props.onCreateLocation,
                                'isContextMenu': false,
                                'isButtonMenu': true,
                                "wmsSelector": this.state.Selector + "CreateLocationButton",
                                'wmsid': 'create'
                            },
                            {
                                "name": "Edit",
                                "value": [],
                                "disabled": !this.state.enableEditButton,
                                "icon": "fa-pencil",
                                "color": "outlined",
                                'isContextMenu': true,
                                'isButtonMenu': true,
                                "wmsSelector": this.state.Selector + "EditButton",
                                clickFunc: this.onEditLocation,
                                'wmsid': 'edit'
                            },
                            {
                                "name": "Manage",
                                "wmsSelector": this.state.Selector + "Manage",
                                "value": [{
                                    "name": "Activate",
                                    "value": [],
                                    "disabled": !this.state.enableActive,
                                    "disabledText": this.translate('note_location_activate'),
                                    "icon": "fa-bandcamp",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': true,
                                    'wmsid': 'activate',
                                    "wmsSelector": this.state.Selector + "ActivateLocationButton",
                                    onClickFunc: ((e) => this.activateDeactivateLocation(true))
                                }, {
                                    "name": this.translate('Label_Deactivate'),
                                    "value": [],
                                    "disabled": !this.state.enableDeactive,
                                    "disabledText": this.state.deActiveMessage,
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': true,
                                    'wmsid': 'deactivate',
                                    "wmsSelector": this.state.Selector + "DeactivateLocationButton",
                                    onClickFunc: ((e) => this.activateDeactivateLocation(false))
                                }, {
                                    "name": "Delete",
                                    "value": [],
                                    "disabled": !this.state.enableDelete,
                                    "disabledText": this.translate('note_location_delete'),
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': true,
                                    'wmsid': 'delete',
                                    "wmsSelector": this.state.Selector + "DeleteLocationButton",
                                    onClickFunc: this.onClickDeleteLocation
                                }],
                                "disabled": false,
                                "disabledText": "You may not add blahssss0",
                                "icon": "fa-wrench",
                                "color": "outlined",
                                'isContextMenu': true,
                                'isButtonMenu': true
                            },  {
                                "name": '',
                                "wmsSelector": this.props.Selector + 'RefreshGrid',
                                "value": [],
                                "clickFunc": this.refreshLocationGrid,
                                "disabled": false,
                                "icon": "fa-sync-alt",
                                "color": "blue",
                                'isContextMenu': false,
                                'isButtonMenu': true,
                                'parentClassName':'locationRefreshBtn'
                            }, {
                                "name": "Copy",
                                "value": [{
                                    "name": this.translate('Label_Copy_Field'),
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": this.translate('Label_Copy_Field'),
                                    "icon": "fa-bandcamp",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyField',
                                    "wmsSelector": "copyToClicpboard",
                                }, {
                                    "name": this.translate('Label_Copy_Row'),
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": this.translate('Label_Copy_Row'),
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyRow'
                                }],
                                "disabled": false,
                                "disabledText": "You may not add blahssss0",
                                "icon": "fa-wrench",
                                "color": "green",
                                'isContextMenu': true,
                                'isButtonMenu': false
                            }]
                        }
                    />
                </div>
                )}
                {this.state.isOpenDeleteConfirmModel &&(
                <WmsModel
                    id={this.props.windowId + 'confirmDeleteLocation'}
                    title={this.translate('Label_Confirm_Delete_Location')}
                    width={500}
                    height={220}
                    class='confirmDeleteLocation'
                    onCloseFunc={this.closeDeleteconfirm}
                    customClass='wms-model-new-ui-wrapper'
                    isOpen={this.state.isOpenDeleteConfirmModel}>
                    <div className='model-content-wrapper'>
                        {this.translate('Label_Confirm_Selected_Locations')}
                    </div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={this.translate('Label_Cancel')}
                            wmsSelector={this.state.Selector + 'Cancel'}
                            onClickFunc={this.closeDeleteconfirm}
                            disabled={false}
                            loading={false}
                            buttonType='orange'
                            icon={'fa-ban'}
                        />
                        <WmsButton
                            label={this.translate('Label_Select_Confirm')}
                            wmsSelector={this.state.Selector + 'ConfirmDelete'}
                            onClickFunc={this.deleteLocation}
                            disabled={false}
                            loading={false}
                            icon={'fa-check-circle'}
                        />
                    </div>

                </WmsModel>
                )}
            </div>
        )
    }
}