import React from 'react';
var axios = require('axios');
import * as GlobalService from './../global_services';

export const initialFindWarehouses = function(){
    // var url = globalsMsg.json_url + globalsMsg.warehouses_get_options;
    var url = "/WebUI/Facilities/ManageFacilities/GetFacilityOptions";
    return axios.post(url).then(function(response){
        return response;
    });
}

export const manageWarehouse = function (warehouseData,flowType) {
    var url = (flowType == 'create') ?
    '/WebUI/Facilities/ManageFacilities/CreateFacility' :
    '/WebUI/Facilities/ManageFacilities/UpdateFacility';

    return axios.post(url,{ facility: warehouseData }).then(function (response){
        return response;
    });
}

export const updateWarehouseActivation = function (editUri,Activate) {

    return axios.post('/WebUI/Facilities/ManageFacilities/SetFacilityStatus',{ "editUri": editUri, "activate": Activate }).then(function (response){
        return response;
    });
}