import * as GlobalService from './../global_services';

export const getGridDemoColumns = (FindRole_GridHeaders) => {
    
    var customLabels= GlobalService.getCustomLabels();
    var translate = window.translate;

    return ({

        columns: [{
            field: "Name",
            title: FindRole_GridHeaders[1].description,
            width: 120,
            wmsid: 2,
            filterable: true
        },{
            field: "Scope",
            title: FindRole_GridHeaders[2].description,
            width: 120,
            wmsid: 3,
            template:'# if(Scope == 1) {# '+customLabels.Label_Customer +' #} else if (Scope == 2) {# ' + customLabels.Label_Warehouse + ' #} else {#  #} #'
        },
        {
            field: "CreationDate",
            title: FindRole_GridHeaders[3].description,
            width: 120,
            wmsid: 4,
            template: "#= kendo.toString(kendo.parseDate(CreationDate), 'MMM dd yyyy, hh:mm:ss tt') #"
        },
        {
            field: "InUse",
            title: FindRole_GridHeaders[4].description,
            width: 120,
            wmsid: 5,
        },
        {
            field: "IsTemplate",
            title: FindRole_GridHeaders[5].description,
            width: 120,
            wmsid: 6,
            template: "#= IsTemplate == true ? '" + translate('Label_Yes') + "' : '" + translate('Label_No') + "' #"
        }]
    })
}


export const getGridDemoSchema = () => {
    return ({
        schema: {
            data: "Data",
            total: "Total",
            model: {
                id: "Uri",
                fields: {
                    Uri: {
                        type: "string"
                    },
                    Name: {
                        type: "string"
                    },
                    Scope: {
                        type: "number"
                    },
                    CreationDate: {
                        type: "date"
                    },
                    InUse: {
                        type: "boolean"
                    },
                    IsTemplate: {
                        type: "boolean"
                    }
                }
            }
        }
    })
}

export const getGridDemoTransport = (warehouseIndex) => {
    return ({})
}