import React from 'react';
import PropTypes from 'prop-types';
import WmsButton from '../WmsButton/WmsButton.jsx';
import style from './WmsUpdateDropdownList.scss';

class WmsUpdateDropdownList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.windowId = this.props.windowId == "" ? "" : "." + this.props.windowId;
        this.dropdownListId = this.windowId + " #" + this.props.id;
        this.dropdownListData = "";
        this.state = {
            value: props.value || "",
            selectItemObj:[],
            isListValueInitialise: false
        }
    }
    componentDidMount() {
        this._ismounted = true;
        var componentProps = this.props;
        $(this.dropdownListId).kendoDropDownList({
            dataTextField: this.props.textField,
            dataValueField: this.props.valueField,
            optionLabel: this.props.optionsLabel,
            filter: "contains",
            valueTemplate: this.props.valueTemplate,
            template: this.props.template,
            height: this.props.height,
            virtual: (this.props.isVirtualOptions ? this.props.virtualObj : null),
            dataSource: (this.props.isVirtualOptions ? this.props.dataSourceObj : this.props.staticOptions),
            suggest: this.props.suggest,
            minLength: this.props.minLength,
            autoBind: this.props.autoBind,
            enable: (this.props.disabled == false),
            change: (e) => {
                that.setSelectItemObj($("#" + that.props.id).val());
                $("#" + this.props.id).data('kendoDropDownList').value(null);
            },
            dataBound: (e) => {
                this.props.onDataBound(e);
                var that = this;
                if(this.state.isListValueInitialise == false) {
                    var itemObj = _.filter(this.props.staticOptions, function(objCust){
                        return that.props.valueArray.includes(objCust[that.props.valueField]);
                    });
                    if (this._ismounted) {
                        this.setState({
                            selectItemObj: itemObj,
                            isListValueInitialise: true
                        });
                    }
                }
            },
            select: (e) => {
                this.props.onSelect(e);
            },
            open: function(e) {
                e.sender.popup.element.addClass(componentProps.popupClassName);
            }
        });

        this.dropdownListData = $(this.dropdownListId).data("kendoDropDownList");


        this.props.onDropdownBound(this.dropdownListData);

        var addClasses = (((this.props.required) ? "wms_required_input" : ''));
        $(this.dropdownListId).parents('.k-dropdown').addClass(addClasses);

        var that = this;
        this.dropdownListData.wrapper.keydown(function(e) {
            if(that.dropdownListData.value() != "") {
                if(e.keyCode == 13){
                    that.props.onEnterPress();
                }
            }
        });

        var txtContainsId = this.props.id + "_listbox";
        $("input[aria-owns*='"+txtContainsId+"']").on("keydown", function (el, e) {
            if (e.keyCode == 9 || e.keyCode == 13) {
                that.props.nextFocus();
            }
        }.bind(null, this.dropdownListData));
        // bind tooltip for show more description
        if(this.props.showDetailsTooltip){
            $('body').kendoTooltip({
                filter: "#" + txtContainsId + '>li.k-item',
                position: 'right',
                content: function (e) {
                    var item = that.dropdownListData.dataItem($(e.target));
                    if(item){
                        var result = '<p> SKU : ' + item.Sku + '</p>' +
                            '<p> Description : ' + item.Description + '</p>';
                        return result;
                    }
                },
                // height:100,
                // width:100
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isVirtualOptions &&
            typeof nextProps.dataSourceObj.transport != "undefined" &&
            JSON.stringify(nextProps.dataSourceObj.transport.read.data) != JSON.stringify(this.props.dataSourceObj.transport.read.data)
        ) {
            var objKendoDropDownList = this.dropdownListData;
            if (typeof objKendoDropDownList != "undefined" && objKendoDropDownList.dataSource.transport.options) {
                objKendoDropDownList.setDataSource(nextProps.dataSourceObj);
                // grid.value('');
                // grid.dataSource.transport.options.read.data = nextProps.dataSourceObj.transport.read.data;
                // grid.dataSource.read();
            }
        }

        if (!nextProps.isVirtualOptions && nextProps.valueArray != this.props.valueArray) {

            this.setState({
                isListValueInitialise: false
            });
            let tmpCustomerOptions = Object.assign([], this.props.staticOptions);
            _.remove(tmpCustomerOptions, obj => nextProps.valueArray.includes(obj[this.props.valueField]));
            setTimeout(() => {
                if($(this.dropdownListId).data("kendoDropDownList")){
                    this.dropdownListData.setDataSource(tmpCustomerOptions);
                }
            }, 100);
        }

        this.dropdownListData.enable((nextProps.disabled == false));
        if (!nextProps.disabled) {
            var wrapper = this.dropdownListData.wrapper;
            wrapper.attr({ tabindex: 0 });
        }
        var addClasses = (((nextProps.required) ? "wms_required_input" : ''));
        var removeClasses = (((!nextProps.required) ? "wms_required_input" : ''));

        $(this.dropdownListId).parents('.k-dropdown').addClass(addClasses).removeClass(removeClasses);
    }

    componentWillUnmount() {
        this._ismounted = false;
     }

    componentDidUpdate() {

    }

    onClickClearBtn = (e) => {
        this.setState({
            value: ""
        });

        this.dropdownListData.value(null);
        this.dropdownListData.trigger("change");

    }
    setSelectItemObj = (param_value) => {
        var data = $("#" + this.props.id).data('kendoDropDownList').dataSource._data;
        var that=this;
        var selectItemObj = this.state.selectItemObj;
        var itemObj = _.filter(data, function(p){
            return (param_value == p[that.props.valueField]);
        });
        selectItemObj.push(itemObj);
        selectItemObj = _.flatten(selectItemObj);

        this.setState({
            selectItemObj: selectItemObj
        });

        that.props.onChangeFunc(_.map(selectItemObj, this.props.valueField));

    }

    removeSelectedItem = (param_id) => (e) => {

        var selectItemObj = this.state.selectItemObj;
        var that=this;
        _.remove(selectItemObj, function (e) {
            return e[that.props.valueField] == param_id;
        });
        this.setState({
            selectItemObj: selectItemObj
         });
        that.props.onChangeFunc(_.map(selectItemObj, this.props.valueField));
    }
    render() {
        return (
            <div className={"wms-kendo-dropdown-update-list-component " + (this.props.required && this.state.selectItemObj.length == 0 ? "no-value-selected " : "") + this.props.extraWrapperClass}>
                {this.props.label != '' &&
                    <label className="wms-form-label" data-wms-identifier="Shipping_Label_Contacts" htmlFor={this.props.id}>{this.props.label + (this.props.required ? ' *' : '')}</label>
                }
                <input id={this.props.id}
                    name={this.props.name}
                    data-wms-selector={this.props.wmsSelector}
                    required={this.props.required}
                />
                {this.props.showClearBtn && this.state.value &&
                    <div className="wms-dropdown-clear" onClick={this.onClickClearBtn}>
                        <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                    </div>
                }
                <div className="selected-values">
                    {this.state.selectItemObj.map((items, index) => (
                        <WmsButton
                        key={index}
                        label={items[this.props.textField]}
                        onClickFunc={this.removeSelectedItem(items[this.props.valueField])}
                        wmsSelector={index+'_selectedSku'}
                        icon='fa-times-circle-o'
                        buttonType='yellow' />
                    ))}
                </div>
            </div>
        );
    }

}
WmsUpdateDropdownList.propTypes = {
    id: PropTypes.string, // this should write the id to the input,
    name: PropTypes.string, // this should write the id to the input,
    required: PropTypes.bool, // this determines if the input is required and shows in red
    disabled: PropTypes.bool,  // default will be false
    windowId: PropTypes.string, // this should be used to get window id
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    extraWrapperClass: PropTypes.string,
    parentClassName: PropTypes.string, //    this should parent tag class default parent class is form-group
    valueArray: PropTypes.array, // this should control the value ,it`s string or number
    valueField: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),// dropdown option value and it mus be require
    textField: PropTypes.string.isRequired,// dropdown option text and it mus be require
    label: PropTypes.string.isRequired,// dropdown title
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
    isVirtualOptions: PropTypes.bool,// this should be true when option from virtual api
    isValuePrimitive: PropTypes.bool,// this should be true when need to return object property on change otherwise return object
    staticOptions: PropTypes.array, // dropdown option and it's not containe virtual api
    onSelect: PropTypes.func, // function call when select in dropdown option
    onEnterPress: PropTypes.func,
    nextFocus: PropTypes.func,
    showClearBtn: PropTypes.bool,
    showDetailsTooltip: PropTypes.bool, // this is used for show tooltip for more details on hover over sku
    valueTemplate: PropTypes.string,
    popupClassName: PropTypes.string

}
WmsUpdateDropdownList.defaultProps = {
    height: 260,
    required: false,
    disabled: false,
    virtualObj: null,
    parentClassName: "form_group",
    extraWrapperClass: '',
    nestedValueField: '',
    label: '',
    isVirtualOptions: true,
    isValuePrimitive: false,
    windowId: '',
    suggest: false,
    minLength: 1,
    autoBind: true,
    showClearBtn: false,
    showDetailsTooltip: false,
    onDataBound: (e) => () => { },
    nextFocus: (e) => () => { },
    onSelect: (e) => () => { },
    onEnterPress: (e) => () => { },
    onDropdownBound: (e) => () => { },
    valueTemplate: '',
    popupClassName: ''
}
export default WmsUpdateDropdownList;