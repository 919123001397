import React from 'react';
import * as GlobalService from './../../global_services';

export const getGridDemoColumns = (FindReceivers_GridHeaders, selector) => {
    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();

    selector = selector + "Header";
    return ({
        columns: [{
            field: "StatusEnum",
            title: FindReceivers_GridHeaders[0].description,
            width: 120,
            wmsid: 1,
            headerAttributes: {
                "data-wms-selector": selector + 'StatusEnum'
            },
            filterable:{
                ui: function(element){
                element.kendoDropDownList({
                    dataSource: [
                        { value: 0, text: translate('Receiving_Status_Open') },
                        { value: 1, text: translate('Receiving_Status_Closed') },
                        { value: 2, text: translate('Receiving_Status_Cancel') },
                        { value: 3, text: customLabels.Label_Complete }],
                    optionLabel: "--Select--",
                    dataTextField: "text",
                    dataValueField: "value"
                });
                }
            },
            sortable: true,
            template: "# if(StatusEnum == 0){# " + translate('Receiving_Status_Open') + " #}else if(StatusEnum == 3){# " + customLabels.Label_Complete + " #} else if(StatusEnum == 1){# " + translate('Receiving_Status_Closed') + " #}else{# " + translate('Receiving_Status_Cancel') + " #} # "
        },
        {
            field: "ReceiverId",
            title: FindReceivers_GridHeaders[1].description,
            width: 120,
            wmsid: 2,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiverId'
            },
            filterable: {
                ui: function (element) {
                    element.kendoNumericTextBox({
                        format: '{0:0}'
                    })
                }
            }
        },
        {
            field: "ReferenceNum",
            title: FindReceivers_GridHeaders[2].description,
            width: 120,
            wmsid: 3,
            headerAttributes: {
                "data-wms-selector": selector + 'ReferenceNum'
            }
        },
        {
            field: "CreationDate",
            title: FindReceivers_GridHeaders[3].description,
            width: 120,
            wmsid: 4,
            headerAttributes: {
                "data-wms-selector": selector + 'CreationDate'
            },
            template: "#= CreationDate != null ? kendo.toString(kendo.parseDate(CreationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
        },
        {
            field: "Customer",
            title: customLabels.Label_Customer,
            width: 120,
            wmsid: 5,
            headerAttributes: {
                "data-wms-selector": selector + 'Customer'
            }
        },
        {
            field: "Warehouse",
            title: customLabels.Label_Warehouse,
            width: 120,
            wmsid: 6,
            headerAttributes: {
                "data-wms-selector": selector + 'Warehouse'
            }
        },
        {
            field: "ReceiptAdviseNumber",
            title: FindReceivers_GridHeaders[6].description,
            width: 120,
            wmsid: 7,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiptAdviseNumber'
            }
        },
        {
            field: "PoNum",
            title: FindReceivers_GridHeaders[7].description,
            width: 120,
            wmsid: 8,
            headerAttributes: {
                "data-wms-selector": selector + 'PoNum'
            }
        },
        {
            field: "TrailerNumber",
            title: FindReceivers_GridHeaders[8].description,
            width: 120,
            wmsid: 9,
            headerAttributes: {
                "data-wms-selector": selector + 'TrailerNumber'
            }
        },
        {
            field: "Skus",
            title: customLabels.Label_Sku,
            width: 120,
            wmsid: 10,
            headerAttributes: {
                "data-wms-selector": selector + 'Skus'
            },
            filterable: true,
            sortable: true
        },
        {
            field: "ArrivalDate",
            title: FindReceivers_GridHeaders[10].description,
            width: 120,
            wmsid: 11,
            headerAttributes: {
                "data-wms-selector": selector + 'ArrivalDate'
            },
            template: "#= ArrivalDate != null ? kendo.toString(kendo.parseDate(ArrivalDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
        },
        {
            field: "Notes",
            title: FindReceivers_GridHeaders[11].description,
            width: 120,
            wmsid: 12,
            headerAttributes: {
                "data-wms-selector": selector + 'Notes'
            }
        }]
    });
}
export const getAllColumnList = (FindReceivers_GridHeaders, selector) => {

    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();

    selector = selector + "Header";
    return ([
        {
            field: "StatusEnum",
            title: FindReceivers_GridHeaders[0].description,
            width: 120,
            wmsid: 1,
            headerAttributes: {
                "data-wms-selector": selector + 'StatusEnum'
            },
            filterable:{
                ui: function(element){
                element.kendoDropDownList({
                    dataSource: [
                        { value: 0, text: translate('Receiving_Status_Open') },
                        { value: 1, text: translate('Receiving_Status_Closed') },
                        { value: 2, text: translate('Receiving_Status_Cancel') },
                        { value: 3, text: customLabels.Label_Complete }],
                    optionLabel: "--Select--",
                    dataTextField: "text",
                    dataValueField: "value"
                });
                }
            },
            sortable: true,
            template: "# if(StatusEnum == 0){# " + translate('Receiving_Status_Open') + " #}else if(StatusEnum == 3){# " + customLabels.Label_Complete + " #} else if(StatusEnum == 1){# " + translate('Receiving_Status_Closed') + " #}else{# " + translate('Receiving_Status_Cancel') + " #} # "
        },
        {
            field: "ReceiverId",
            title: FindReceivers_GridHeaders[1].description,
            width: 120,
            wmsid: 2,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiverId'
            },
            filterable: {
                ui: function (element) {
                    element.kendoNumericTextBox({
                        format: '{0:0}'
                    })
                }
            }
        },
        {
            field: "ReferenceNum",
            title: FindReceivers_GridHeaders[2].description,
            width: 120,
            wmsid: 3,
            headerAttributes: {
                "data-wms-selector": selector + 'ReferenceNum'
            }
        },
        {
            field: "CreationDate",
            title: FindReceivers_GridHeaders[3].description,
            width: 120,
            wmsid: 4,
            headerAttributes: {
                "data-wms-selector": selector + 'CreationDate'
            },
            template: "#= CreationDate != null ? kendo.toString(kendo.parseDate(CreationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
        },
        {
            field: "Customer",
            title: customLabels.Label_Customer,
            width: 120,
            wmsid: 5,
            headerAttributes: {
                "data-wms-selector": selector + 'Customer'
            }
        },
        {
            field: "Warehouse",
            title: customLabels.Label_Warehouse,
            width: 120,
            wmsid: 6,
            headerAttributes: {
                "data-wms-selector": selector + 'Warehouse'
            }
        },
        {
            field: "ReceiptAdviseNumber",
            title: FindReceivers_GridHeaders[6].description,
            width: 120,
            wmsid: 7,
            headerAttributes: {
                "data-wms-selector": selector + 'ReceiptAdviseNumber'
            }
        },
        {
            field: "PoNum",
            title: FindReceivers_GridHeaders[7].description,
            width: 120,
            wmsid: 8,
            headerAttributes: {
                "data-wms-selector": selector + 'PoNum'
            }
        },
        {
            field: "TrailerNumber",
            title: FindReceivers_GridHeaders[8].description,
            width: 120,
            wmsid: 9,
            headerAttributes: {
                "data-wms-selector": selector + 'TrailerNumber'
            }
        },
        {
            field: "Skus",
            title: customLabels.Label_Sku,
            width: 120,
            wmsid: 10,
            headerAttributes: {
                "data-wms-selector": selector + 'Skus'
            },
            filterable: true,
            sortable: true
        },
        {
            field: "ArrivalDate",
            title: FindReceivers_GridHeaders[10].description,
            width: 120,
            wmsid: 11,
            headerAttributes: {
                "data-wms-selector": selector + 'ArrivalDate'
            },
            template: "#= ArrivalDate != null ? kendo.toString(kendo.parseDate(ArrivalDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
        },
        {
            field: "Notes",
            title: FindReceivers_GridHeaders[11].description,
            width: 120,
            wmsid: 12,
            headerAttributes: {
                "data-wms-selector": selector + 'Notes'
            }
        },
        {
            field: "ExpectedDate",
            title: FindReceivers_GridHeaders[12].description,
            width: 120,
            wmsid: 4,
            headerAttributes: {
                "data-wms-selector": selector + 'ExpectedDate'
            },
            template: "#= ExpectedDate != null ? kendo.toString(kendo.parseDate(ExpectedDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
        },
    ]);
}


export const getGridDemoSchema = () => {
    return ({
        model: {
            id: "ReceiverId",
            fields: {
                StatusEnum: {
                    type: 'number'
                },
                ReceiverId: {
                    type: 'number'
                },
                ReferenceNum: {
                    type: 'string'
                },
                CreationDate: {
                    type: 'date'
                },
                Customer: {
                    type: 'string'
                },
                Warehouse: {
                    type: 'string'
                },
                ReceiptAdviseNumber: {
                    type: 'string'
                },
                PoNum: {
                    type: 'string'
                },
                TrailerNumber: {
                    type: 'string'
                },
                Skus: {
                    type: 'string'
                },
                ArrivalDate: {
                    type: 'date'
                },
                Notes: {
                    type: 'string'
                },
                ExpectedDate: {
                    type: 'date'
                }
            }
        }
    })
}

export const getGridDemoTransport = () => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Receiving/FindReceivings/ReceivingsGridRead",
                type: "POST"
            },
            destroy: {
                url: "/WebUI/Receiving/FindReceivings/ReceivingsGridRead",
                type: "POST",
                dataType: "JSON"
            },
            parameterMap: function (options, operation) {
                if (operation !== "read" && options.models) {
                    return {
                        models: JSON.stringify(options.models)
                    };
                }
            }
        }
    })
}

export const exportGridBaseArray = () => {
    return [
        { 'ReceiverId': 'ReceiverId' },
        { 'ReferenceNum': 'ReferenceNum' },
        { 'CreationDate': 'CreationDate' },
        { 'AsnConfirmDate': 'AsnConfirmDate' },
        { 'Notes': 'Notes' },
        { 'PoNum': 'PoNum' },
        { 'ArrivalDate': 'ArrivalDate' },
        { 'TrailerNumber': 'TrailerNumber' },
        { 'ReceiptAdviseNumber': 'ReceiptAdviseNumber' },
        { 'Customer': 'Customer' },
        { 'CustomerId': 'CustomerId' },
        { 'Warehouse': 'Warehouse' },
        { 'FacilityId': 'FacilityId' },
        { 'StatusEnum': 'StatusEnum' },
        { 'Skus': 'Skus' }
    ];
}