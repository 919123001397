var axios = require('axios');
import * as GlobalService from '../global_services';

export const initialInventory = function () {

    return axios.all([
        GlobalService.GetCustomersAndFacility()
    ]).then(axios.spread(function (GetCustomerFacilityList) {
        return {
            getCustomerFacilityList: GlobalService.getFacilityList()
        };
    }));
}

export const holdOrReleaseInventory = function (param_holdObj) {
    return axios.put("/WebUI/Inventory/ManageInventory/HoldStatus?putOnHold="+param_holdObj.putOnHold+"&reason="+param_holdObj.Reason, {
        "receiveItemIds": param_holdObj.receiveItemIds
    }).then(function (response) {
        return response;
    });
}

export const locationDropdownData = function (param_facilityId) {
    let data = {
        sort: '',
        page: 1,
        pageSize: 400,
        group: '',
        filter: '',
        facilityId: parseInt(param_facilityId),
    }
    return axios.post('/WebUI/Inventory/ManageInventory/LocationDropdownRead', data)
        .then(function (response) {
            return response.data;
        });
}

export const C2CTransferer = function (finalObject) {

    return axios.post('/WebUI/Inventory/ManageInventory/C2CTransferer', finalObject)
        .then(function (response) {
            return response;
        });
}

export const c2cTransfererOptions = function (idsArray) {

    return axios.get('/WebUI/inventory/ManageInventory/C2CTransferOptions', {
        params: {
            "receiveItemIds": idsArray.toString()
        }
    }).then(function (response) {
        return response.data;
    });
}

export const getItemOptions = function (customerId, itemId) {

    return axios.get('/WebUI/inventory/ManageInventory/ItemOptions', {
        params: {
            "customerId": customerId,
            "itemId": itemId
        }
    }).then(function (response) {
        return response.data;
    });
}
