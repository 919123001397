import React from 'react';
import { ArrayWms } from '../../../../../assets/ts/ArrayWms.ts';

//component
import WmsButton from './../../../components/WmsButton/WmsButton.jsx';
import WmsDropdown from './../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsContainer from './../../../components/WmsContainer/WmsContainer.jsx';
import WmsModel from './../../../components/WmsModel/WmsModel.jsx';

import * as findOrdersService from '../findOrders.services.jsx';
import * as GlobalService from './../../../global_services';
import './packing-slips-model.scss';

export class PackingSlipsModel extends React.Component {

    constructor(props) {

        super(props);

        this.$rootScope = window;
        this.translate = window.translate;
        this.modalId = "#" + this.props.windowId + "shipSlipsModel";
        this.state = {
            selector: "order",
            gifPackageSlips: false,
            optionsPackingReports: [],
            optionsTemplateReports: [],
            optionsOutputTypes: [],
            indexOptionsPackingReports: 0,
            indexOptionsTemplateReports: 0,
            indexOptionsOutputTypes: 0
        }
    }
    componentDidMount() {

            kendo.ui.progress($(this.modalId), true);

            findOrdersService.initialPackingSlips(
                this.props.sharedSelectedOrderData.CustomerId,
                this.props.sharedSelectedOrderIds
            ).then((response) => {

                if (response) {
                    let _combinedPackingShortShipArray = [];
                    let _templateReports = [];
                    let _outputTypes = [];

                    // DETECT IF THE PACKING SLIPS ARRAY IS PRESENT AND ADD IT IF IT IS
                    if (response.length >= 1) {
                        let packingShip = response[0];
                        for (let x = 0; x < packingShip.Reports.length; x++) {
                            _combinedPackingShortShipArray.push(packingShip.Reports[x]);
                        }
                        _templateReports = packingShip.Templates;
                        _outputTypes = packingShip.FileTypes;
                    }
                    // DETECT IF THE SHORT SHIP ARRAY IS PRESENT AND ADD IT IF IT IS
                    if (response.length >= 2) {
                        let shortShip = response[1];
                        for (let y = 0; y < shortShip.Reports.length; y++) {
                            shortShip.Reports[y].Name = shortShip.Reports[y].Name + this.translate('Label_Append_Short_Ship');
                            _combinedPackingShortShipArray.push(shortShip.Reports[y]);
                        }
                    }

                    let indexOptionsTemplateReports = _templateReports.length >= 1 ? _templateReports[0].Id : 0;

                    let indexOptionsOutputTypes = _outputTypes.length >= 1 ? _outputTypes[0].Value : 0;

                    // sort report in alphabatic order and
                    // move default template in last position
                    let tmpOpt = _combinedPackingShortShipArray.sort(function(first, second) {
                        // set default value at last in sorting
                        if(first.Name == 'Default' || second.Name == 'Default'){ return -1; }
                        var firstReport = first.Name.toUpperCase(); // ignore upper and lowercase
                        var secondReport = second.Name.toUpperCase(); // ignore upper and lowercase

                        if (firstReport < secondReport) { return -1; }

                        if (firstReport > secondReport) { return 1; }
                        // names must be equal
                        return 0;
                    });
                    let indexOptionsPackingReports = tmpOpt.length >= 1 ? tmpOpt[0].TemplatedRunner : 0;
                    this.setState({
                        optionsPackingReports: tmpOpt,
                        optionsTemplateReports: _templateReports,
                        optionsOutputTypes: _outputTypes,
                        indexOptionsPackingReports: indexOptionsPackingReports,
                        indexOptionsTemplateReports: indexOptionsTemplateReports,
                        indexOptionsOutputTypes: indexOptionsOutputTypes
                    });
                }
            }).finally(() => {
                kendo.ui.progress($(this.modalId), false);
            });
    }

    handleChangeEvent = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    clickExportReport = () => {

        this.setState({ gifPackageSlips: true });

        let payload_runReportObj = {
            "TemplatedRunner": this.state.indexOptionsPackingReports,
            "TransactionIds": this.props.sharedSelectedOrderIds,
            "CustomerId": this.props.sharedSelectedOrderData.CustomerId,
            "TemplateId": this.state.indexOptionsTemplateReports,
            "OutputType": this.state.indexOptionsOutputTypes
        }
        findOrdersService.PackingSlipReportStarter(payload_runReportObj).then((response) => {

            this.setState({ gifPackageSlips: false });
            console.log("PackingSlipReportStarter response",response);
            if(response && response.status == 200){
                let outputType = 'pdf';
                if (this.state.indexOptionsOutputTypes.indexOf('csv') != -1) {
                    outputType = 'csv';
                } else if (this.state.indexOptionsOutputTypes.indexOf('excel') != -1) {
                    outputType = 'xls';
                } else if (this.state.indexOptionsOutputTypes.indexOf('xml') != -1) {
                    outputType = 'xml';
                } else if (this.state.indexOptionsOutputTypes.indexOf('msword') != -1) {
                    outputType = 'doc';
                }
                response.data.outputType = outputType;
                // showing moving icon animation start
                var targetElement = $('#generateSlip'+this.props.windowId);
                this.$rootScope.animationMoveIcon(targetElement);
                this.$rootScope.taskStarted();
                // this.$rootScope.$broadcast("EventProgressPackingSlips", response.data);
                response.data.type = "packingslips";
                var event = new CustomEvent("showProgressModal", {
                    detail: response.data
                });
                document.dispatchEvent(event);

            }

            // let date = new Date();
            // let day = date.getDate();
            // let month = date.getMonth();
            // let year = date.getFullYear();

            // if (this.state.indexOptionsOutputTypes.indexOf('pdf') != -1) {

            //     let blob = ArrayWms.convertBase64ToByteArray(response, this.state.indexOptionsOutputTypes);
            //     ArrayWms.displayBlobInWindow(blob, 'report.pdf');

            // } else if (this.state.indexOptionsOutputTypes.indexOf('csv') != -1) {

            //     let blob = ArrayWms.convertBase64ToByteArray(response, this.state.indexOptionsOutputTypes);
            //     saveAs(blob, "PackingSlips" + month + "_" + day + "_" + year + ".csv");

            // } else if (this.state.indexOptionsOutputTypes.indexOf('excel') != -1) {

            //     let blob = ArrayWms.convertBase64ToByteArray(response, this.state.indexOptionsOutputTypes);
            //     saveAs(blob, "PackingSlips" + month + "_" + day + "_" + year + ".xls");

            // } else if (this.state.indexOptionsOutputTypes.indexOf('xml') != -1) {

            //     let blob = ArrayWms.convertBase64ToByteArray(response, this.state.indexOptionsOutputTypes);
            //     saveAs(blob, "PackingSlips" + month + "_" + day + "_" + year + ".xml");

            // } else if (this.state.indexOptionsOutputTypes.indexOf('msword') != -1) {

            //     let blob = ArrayWms.convertBase64ToByteArray(response, this.state.indexOptionsOutputTypes);
            //     saveAs(blob, "PackingSlips" + month + "_" + day + "_" + year + ".doc");

            // }
        }).finally(() => {
            this.setState({ gifPackageSlips: false });
            kendo.ui.progress($(this.modalId), false);
            this.props.onClose();
        });
    }

    render() {
        return (
            <WmsModel
                id={this.props.windowId + 'shipSlipsModel'}
                class='shipSlipsModel'
                title={this.translate('Label_Packing_Slip')}
                width={400}
                height={390}
                onCloseFunc={this.props.onClose}
                isOpen={this.props.sharedIsOpenPackingSlipsModel}
                customClass='wms-model-new-ui-wrapper'>
                <div className="model-content-wrapper">
                <WmsContainer title={
                    this.translate('FindOrders_Lbl_Customer') + ': ' +
                    (this.props.sharedSelectedOrderData != null ? this.props.sharedSelectedOrderData.Customer : '')
                }>

                    <WmsDropdown
                        id="indexOptionsPackingReports"
                        name="indexOptionsPackingReports"
                        label={this.translate('Label_Packing_Slip_Type')}
                        wmsSelector={this.state.selector + "PackingSlipReport"}
                        value={this.state.indexOptionsPackingReports}
                        onChangeFunc={this.handleChangeEvent}
                        options={this.state.optionsPackingReports}
                        textField='Name'
                        valueField='TemplatedRunner'
                    />
                    <WmsDropdown
                        id="indexOptionsTemplateReports"
                        name="indexOptionsTemplateReports"
                        label={this.translate('Label_Packing_Slip_Address_Configuration')}
                        wmsSelector={this.state.selector + "indexOptionsTemplateReports"}
                        value={this.state.indexOptionsTemplateReports}
                        onChangeFunc={this.handleChangeEvent}
                        options={this.state.optionsTemplateReports}
                        textField='Name'
                        valueField='Id'
                    />
                    <WmsDropdown
                        id="indexOptionsOutputTypes"
                        name="indexOptionsOutputTypes"
                        label={this.translate('Label_Packing_Slip_Format')}
                        wmsSelector={this.state.selector + "OutputType"}
                        value={this.state.indexOptionsOutputTypes}
                        onChangeFunc={this.handleChangeEvent}
                        options={this.state.optionsOutputTypes}
                        textField='Name'
                        valueField='Value'
                    />
                </WmsContainer>

                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.state.selector + "SlipExit"}
                        onClickFunc={this.props.onClose}
                        buttonType='yellow'
                        icon={'fa-ban'}
                    />
                    <WmsButton
                        label={this.translate('Label_Generate')}
                        wmsSelector={this.state.selector + "GenerateSlip"}
                        id={"generateSlip"+this.props.windowId}
                        onClickFunc={this.clickExportReport}
                        disabled={this.state.gifPackageSlips}
                        loading={this.state.gifPackageSlips}
                        icon={'fa-check-square-o'}
                    />

                </div>


            </WmsModel>
        )
    }
}