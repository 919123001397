var axios = require('axios');
import * as GlobalService from '../../../../global_services';

export const SkuInfo = function (param_customerid, param_item_id) {
    return axios.get('/WebUI/Shipping/Orders/SkuInfo?customerId='+param_customerid+"&itemId="+param_item_id).then(function (response) {
        return response;
    });
}
export const addLineItem = function (params) {
    return axios.post('/WebUI/Shipping/Orders/OrderItem',params).then(function (response) {
        return response;
    });
}
export const editLineItem = function (params) {
    return axios.put('/WebUI/Shipping/Orders/OrderItem',params).then(function (response) {
        return response;
    });
}