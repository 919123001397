import React from 'react';
import style from './outbound-productivity.scss';
import * as GlobalService from '../global_services';
import * as outboundService from './outbound-productivity.services.jsx';
export class OutboundProductivity extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            iframeUrl: ''
        }
        
    }

    componentDidMount() {
        let postParams = _.cloneDeep(GlobalService.userKeysUrlStringForDashboardPost);
        postParams["dashboard_name"] = "outbound_productivity";
        postParams["parameter_list"] = {
            "param1": "value1",
            "param2": "value2",
        };
        outboundService.getOutBoundDashboard(postParams).then((response) => {
            console.log("openOutBoundDashboard: ", response.EmbedUrl);
            if(response.EmbedUrl) {
                this.setState({
                    iframeUrl: response.EmbedUrl
                });                
            }
        });
    }
    iframeLoad = () => {
        var iframeId = this.state.iframeId;
        $('#' + iframeId + 'Loader').hide();        
    }

    render() {        
        return (
            <div className="iframe-controller">
                <div className="k-loading-mask">
                    <div id={this.state.iframeId + 'Loader'} className="k-loading-image iframe-mask">
                        <div className="k-loading-color"></div>
                    </div>
                    {this.state.iframeUrl != ''? (
                        <iframe id="outbound-productivity-iframe" onLoad={this.iframeLoad} src={this.state.iframeUrl} data-wms-id="smartview" name="smartviewiframe" className="smartviewiframe"></iframe>
                    ): null}
                    
                </div>
            </div>
        );
    }
}