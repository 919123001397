import React, { Component } from 'react';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';

export class DHLExpressAUAccountInfoComponent extends Component {
    constructor(props) {
        super(props)
        
        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {

        }
    }

    render() {
        return (
            <WmsCollapse
                id={"dhl-express-au"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                        <WmsInput
                            id="accountNickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_Account_Nick_Name")}
                            value={this.props.connectionObj.Nickname}
                            required={true}
                            extraWrapperClass="dhlexp-au-nikcname"
                        />
                        <WmsInput
                            id="account_number"
                            inputName="AccountNumber"
                            wmsSelector={this.props.selector + "accountNumber"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_DHL_Express_Account_Number")}
                            value={this.props.connectionObj.AccountNumber}
                            required={true}
                            extraWrapperClass="dhlexp-au-accountnumber"
                        />
                </div>
            </WmsCollapse>
        )
    }
}
