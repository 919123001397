import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse';
import WmsModel from '../components/WmsModel/WmsModel';
import WmsButton from '../components/WmsButton/WmsButton';
import CustomerPackingSlipsTemplateImage from './packing-slips-template-image.component';
import './customerPackagingSlips.scss';
import * as GlobalService from '../global_services';

import * as CustomerPackingSlipsService from './customer-packing-slips.services';

interface Props {
    isOpenPackingSlipsTemplateModal?: boolean,
    onClosePackingSlipsTemplateModal?: any,
    selectedRow: any,
    refreshGrid: any,
    sharedCustomer: any,
    defaultHeader: string,
    defaultFooter: string
}

declare var window: any;

const CustomerPackingSlipsTemplateModel: React.FC<Props> = (props: Props): any => { // eslint-disable-line sonarjs/cognitive-complexity
    const selector = "PackingSlipsTemplate";
    const [packingSlipsTemplateData, setPackingSlipsTemplateData] = useState(Object.assign({}, props.selectedRow));
    const [saveLoader, setSaveLoader] = useState(false);
    const [saveDeactive, setSaveDeactive] = useState(true);

    useEffect(()=> {

    },[])
    const onSavePackingSlipsModel = () => {
        setSaveLoader(true);
        var formData = {
            data: {
                LogoUrlOverride: packingSlipsTemplateData.LogoUrlOverride,
                FooterUrlOverride: packingSlipsTemplateData.FooterUrlOverride,
                Uri: packingSlipsTemplateData.Uri
            }
        }

        CustomerPackingSlipsService.updateCustomerPackingSlipsData(formData).then(() => {
            props.refreshGrid();
            props.onClosePackingSlipsTemplateModal();
            setSaveLoader(false);
            GlobalService.notification('success', window.translate('Label_Configure_Packing_Slips_save_success'));
        }).finally(() => {
            setSaveLoader(false);
        });
    }
    const changeImageField = ( imgUrl: string, fieldName: string): void => {
        var tempState = packingSlipsTemplateData;
        tempState[fieldName] = imgUrl;
        setPackingSlipsTemplateData(tempState);
        setSaveDeactive(isSaveDesabled())
    }
    const isSaveDesabled = (): boolean => {
        return  packingSlipsTemplateData.LogoUrlOverride === props.selectedRow.LogoUrlOverride 
                    && packingSlipsTemplateData.FooterUrlOverride === props.selectedRow.FooterUrlOverride;
    }
    return (
        <div>
            <WmsModel
                    id='PackingSlipsTemplate'
                    title={window.translate('PACKING_SLIP_TEMPLATE_SETTINGS')}
                    width={800}
                    height={800}
                    onCloseFunc={props.onClosePackingSlipsTemplateModal}
                    isOpen={props.isOpenPackingSlipsTemplateModal}
                    customClass='wms-model-new-ui-wrapper' >
                    <div className='model-content-wrapper'>
                        <div className='slip-template-info'>
                            <span className='info-text'>{window.translate('Label_Customer_Text')}</span>
                            <span className='info-value'>{props.sharedCustomer.CompanyInfo.CompanyName}</span >
                        </div>
                        <div className='slip-template-info'>
                            <span className='info-text'>{window.translate('Label_Configuration')}</span>
                            <span className='info-value'>{props.selectedRow.Description}</span >
                        </div>

                        <WmsCollapse
                            id={'headerPackingSlipsHeaderFooterImage'}
                            headerLabel={window.translate('Label_Header_And_Footer_Images')}
                            showCollapseText={false}>

                            <CustomerPackingSlipsTemplateImage
                                wmsSelector={'Header'}
                                oldValue={props.selectedRow.LogoUrlOverride}
                                fieldName={'LogoUrlOverride'}
                                caption={'Header'}
                                changeImageField={changeImageField}
                                maxWidth={257}
                                maxHeight={63}
                                onDeleteDefault={props.defaultHeader}
                            />
                            <hr/>
                            <CustomerPackingSlipsTemplateImage
                                wmsSelector={'Footer'}
                                oldValue={props.selectedRow.FooterUrlOverride}
                                fieldName={'FooterUrlOverride'}
                                caption={'Footer'}
                                changeImageField={changeImageField}
                                maxWidth={547}
                                maxHeight={76}
                                onDeleteDefault={props.defaultFooter}
                            />
                        </WmsCollapse>
                    </div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={window.translate('Label_Cancel')}
                            wmsSelector={selector + 'Cancel'}
                            onClickFunc={props.onClosePackingSlipsTemplateModal}
                            disabled={saveLoader}
                            loading={false}
                            buttonType='orange'
                            icon={'fa-ban'}
                        />
                        <WmsButton
                            label={window.translate('Label_Save')}
                            wmsSelector={selector + 'save'}
                            onClickFunc={onSavePackingSlipsModel}
                            icon={'fa-floppy-o'}
                            disabled={saveDeactive || saveLoader}
                            loading={saveLoader}
                        />
                    </div>
                </WmsModel>
        </div>
    )
}
CustomerPackingSlipsTemplateModel.propTypes = {
    isOpenPackingSlipsTemplateModal: PropTypes.bool.isRequired, // this should write the id to the button
    onClosePackingSlipsTemplateModal: PropTypes.func.isRequired, // this should write the id to the button
    selectedRow: PropTypes.any.isRequired,
    refreshGrid: PropTypes.any.isRequired,
    sharedCustomer: PropTypes.any.isRequired,
    defaultHeader: PropTypes.string,
    defaultFooter: PropTypes.string
};

CustomerPackingSlipsTemplateModel.defaultProps = {
    isOpenPackingSlipsTemplateModal: false,
    onClosePackingSlipsTemplateModal: () => {},
    selectedRow: {},
    refreshGrid: {},
    sharedCustomer: {},
    defaultHeader: '',
    defaultFooter: ''
};

export default React.memo(CustomerPackingSlipsTemplateModel);