import React from 'react';
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsScrollTo from './../components/WmsScrollTo/WmsScrollTo.jsx';
import { WarehouseFormBasic } from './warehouse-form-basic.component.jsx';
import { WarehouseFormDetails } from './warehouse-form-details.component.jsx';
import { WarehouseFormCustomizations } from './warehouse-form-customizations.component.jsx';
import * as warehouseService from './warehouse.service.jsx';
import * as GlobalService from './../global_services';
import WmsConfirmModel from '../components/WmsConfirmModel/WmsConfirmModel.jsx';

export class WarehouseForm extends React.Component {

    constructor(props) {
        super(props);


        this.translate = window.translate;

        this.state = {
            Selector: 'Warehouse',
            CustomLabels: GlobalService.getCustomLabels(),
            showDiscard: false,
            manageWarehouseLoading: false,
            sharedWarehouse: this.props.sharedWarehouse,
            isOpenConfirmModel: false,
            isLoadingStop: false,
            sawCheckBox: true,
            conFormModelText: "",
            confirmModalFor: "create",
            stateRequired: true
        }

    }

    componentDidMount() {
        this.checkStateRequired(this.props.sharedWarehouse.Country);
    }

    clickOnContinueButton = () => {
        if (this.state.confirmModalFor == "create") {
            this.setState({
                isLoadingStop: false
            });
            this.updateWarehouse();
            this.setState({ isOpenConfirmModel: false });
        }
    }

    onClickSaveWarehouse = () => {

        if (!this.state.sharedWarehouse.EditUri) {
            this.setState({
                confirmModalFor: "create",
                conFormModelText: this.translate('Warehouse_Label_Warning_Create'),
                sawCheckBox: false,
                saveLoader: false,
                isOpenConfirmModel: true
            });
        }
        else {
            this.updateWarehouse();
        }
    }
    updateWarehouse = () => {

        this.setState({ manageWarehouseLoading: true });

        // remove property add in facility object after object value change
        delete this.state.sharedWarehouse._events;
        delete this.state.sharedWarehouse._handlers;
        delete this.state.sharedWarehouse.uid;
        delete this.state.sharedWarehouse.ContactId;

        // let that = this;
        let flowType = 'create';
        let msgType = 'Label_Warehouses_Create'
        if (this.state.sharedWarehouse.EditUri) {
            flowType = 'edit';
            msgType = 'Label_Warehouses_Update';
        }
        let msgNotification = this.translate(msgType);

        warehouseService.manageWarehouse(this.state.sharedWarehouse, flowType)
            .then((response) => {
                this.setState({ manageWarehouseLoading: false });
                if (response.status == 200) {
                    GlobalService.notification('success', msgNotification);
                    this.props.onReloadGrid('reloadWarehouseGrid');
                    this.updateGlobalFacilityData();
                    this.onClickExitWarehouseForm();
                }

            }).finally(() => {
                this.setState({ manageWarehouseLoading: false });
            });
    }

    onCloseConfirmModel = () => {
        this.setState({ isOpenConfirmModel: false });
    }

    onClickExitWarehouseForm = () => {
        this.props.onExitWarehouseForm();
    }

    updateGlobalFacilityData = () => {
        GlobalService.flushApiCache().then((response) => {
            GlobalService.GetCustomersAndFacility();
        }).finally(() => { });
    }

    onWarehouseDataset = (modifiedData) => {
        this.setState({
            sharedWarehouse: modifiedData,
            showDiscard: true
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            sharedWarehouse: Object.assign(
                {},
                nextProps.sharedWarehouse
            ),
            showDiscard: false
        });
        this.checkStateRequired(nextProps.sharedWarehouse.Country);
    }

    checkStateRequired = (Country) => {
        var newStateData = GlobalService.GetRegions(Country);
        var stateRequired = false;
        if (newStateData.length > 1) { // check length greater than 1 because first options is unspecified
            stateRequired = true;
        }
        this.setState({
            stateRequired: stateRequired
        });
    }
    render() {
        return (
            <div className="warehouse-form" id="form_block" data-wms-selector={"FormCreateEdit" + this.state.Selector}>

                <div className="warehouse-form-header">
                    <div className="tab-listing">
                        <ul>
                            <li>
                                <WmsScrollTo
                                    label={this.translate('Label_Contact_Information')}
                                    windowId={this.props.windowId}
                                    scrollToId='CollapseWarehouseFormBasic'
                                    scrollBoxId='ManageWarehouseScrollBox'
                                    wmsSelector={this.state.Selector + "ContactInformation"}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseWarehouseTabContent_wrapper"}
                                />
                            </li>

                            <li >
                                <WmsScrollTo
                                    label={this.translate('Label_Accounts')}
                                    windowId={this.props.windowId}
                                    scrollToId='CollapseWarehouseAccount'
                                    scrollBoxId='ManageWarehouseScrollBox'
                                    wmsSelector={this.state.Selector + "AccountInformation"}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseWarehouseTabContent_wrapper"}
                                />
                            </li>
                            <li >
                                <WmsScrollTo
                                    label={this.translate('Label_Customizations')}
                                    windowId={this.props.windowId}
                                    scrollToId='CollapseWarehouseCustDetail'
                                    scrollBoxId='ManageWarehouseScrollBox'
                                    wmsSelector={this.state.Selector + "Customization"}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseWarehouseTabContent_wrapper"}
                                />
                            </li>
                        </ul>
                    </div>
                    <div className="form-button-group">
                        <WmsButton
                            label={this.translate('Label_Save')}
                            wmsSelector={this.state.Selector + 'ButtonSave'}
                            onClickFunc={this.onClickSaveWarehouse}
                            icon='fa-floppy-o'
                            showToolTip={true}
                            tooltipText={this.translate('Tooltip_Required_Fields')}
                            disabled={!this.state.sharedWarehouse.Name
                                || !this.state.sharedWarehouse.Address1
                                || !this.state.sharedWarehouse.Country
                                || (this.state.stateRequired ? !this.state.sharedWarehouse.State : false)
                                || !this.state.sharedWarehouse.City
                                || !this.state.sharedWarehouse.Zip
                                || !this.state.sharedWarehouse.TimeZoneName
                                || this.state.manageWarehouseLoading}
                            loading={this.state.manageWarehouseLoading} />

                        {this.state.showDiscard == false && (
                            <WmsButton
                                label={this.translate('Label_Exit')}
                                wmsSelector={this.state.Selector + 'ButtonExit'}
                                onClickFunc={(e) => this.onClickExitWarehouseForm()}
                                icon='fa-sign-out'
                                buttonType="orange" />
                        )}

                        {this.state.showDiscard == true && (
                            <WmsButton
                                label={this.translate('Label_Discard_Exit')}
                                wmsSelector={this.state.Selector + 'ButtonDiscard'}
                                onClickFunc={(e) => this.onClickExitWarehouseForm()}
                                icon='fa-times-circle'
                                buttonType="orange" />
                        )}

                    </div>


                </div>
                <div id="ManageWarehouseScrollBox" className={this.props.windowId + " tab-content"}>
                    <WarehouseFormBasic
                        sharedWarehouse={this.state.sharedWarehouse}
                        onWarehouseDataset={this.onWarehouseDataset}
                        checkStateRequired={this.checkStateRequired}
                        shareWarehouseOptions={this.props.shareWarehouseOptions}
                    />

                    <WarehouseFormDetails
                        sharedWarehouse={this.state.sharedWarehouse}
                        onWarehouseDataset={this.onWarehouseDataset} />

                    <WarehouseFormCustomizations
                        sharedWarehouse={this.state.sharedWarehouse}
                        onWarehouseDataset={this.onWarehouseDataset} />

                    <WmsConfirmModel
                        id="roleConfirmModel"
                        isOpen={this.state.isOpenConfirmModel}
                        width={500}
                        height={250}
                        firstBtnLabel={this.translate('RoleManager_Label_Continue')}
                        firstBtnIcon={"fa fa-check-square"}
                        firstBtnOnClickFunc={this.clickOnContinueButton}
                        thirdBtnLabel={this.translate('RoleManager_Label_Back')}
                        thirdBtnIcon={"fa fa-arrow-left"}
                        conFormModelText={this.state.conFormModelText}
                        checkboxLabel={this.translate('RoleManager_Label_IUnderstand')}
                        sawCheckBox={this.state.sawCheckBox}
                        onCloseFunc={this.onCloseConfirmModel}
                        isLoadingStop={this.state.isLoadingStop}
                    />
                </div>
            </div>
        )
    }
}