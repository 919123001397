import React from 'react';
export const getErrorMessages = () => {
    var translate = window.translate;
    return ({
        "Orm: WarehouseTransaction.ReferenceNum": translate('Orders_Lbl_Reference_Num'),
        "Api: OrderCreate.ReferenceNum": translate('Orders_Lbl_Reference_Num'),
        "Orm: Item.SKU": translate('Label_Sku'),
        "Api: Move.MoveItems": translate('Window_Title_Label_Move_Items'),
        "Orm: WarehouseTransaction.ProcessDate": translate('Label_Close_Date'),
        "Orm: ReceiveItem.LocationID": translate('Label_Location'),
        "Orm: ReceiveItem.MovableUnitID": translate('Label_Pallet'),
        "Api: PackageUnit.InventoryUnitsPerUnit": translate('Label_Primary_Units_Per_Packaging_Unit'),
        "Orm: AttachedDoc.DocName": translate('Label_File_Name'),
        "Orm: AttachedDoc.PathIdentifier": translate('Label_File_Path'),
        "Orm: Location.Display": translate('Label_Location_Identifier'),
        "Orm: WarehouseTransactionPriceCalc.WarehouseTransactionID": translate('Orders_Lbl_Transaction_Id'),
        "Orm: WarehouseTransactionPriceCalc.WarehouseTransactionPriceCalcID": translate('Label_Charge_Category'),
        "Orm: WarehouseTransactionPriceCalc.ChargeLabel": translate('Label_Charge_Label'),
        "Orm: OrderItemSE.Value": translate('Label_Custom_Field_Value'),
        "Orm: ReceiveItem.OrigQtySecondary": translate('Label_Quantity_Secondary_Unit'),
        "Orm: Customer.Description": translate('Label_Customer_Name'),
        "Orm: Contact.Code": translate('Label_Contact_ID'),
        "Orm: OrderItem.PrimaryInvQty": translate('Label_Quantity'),
        "Api: Move.PalletIdentifier.NameKey.Name": translate('Label_Pallet'),
        "Api: Customer.Options.UserInterface.AutoCheckAutoReallocateOnReceive": translate('Label_Costomer_chk_Change_default_of_Auto_Allocate_On_Reveive'),
        "Api: SecondaryUnit.InventoryUnitsPerUnit": translate('Label_Primary_Units_Per_Secondary_Unit'),
        "Orm: ReceiveItem.SupplierContactID": translate('Label_Supplier_Contact_ID'),
        "Api: Packaging.SpsCloseOnUpdate": translate('Label_Costomer_chk_Automatically_close_orders_when_a'),
        "Api: Customer.Options.Shipping.OrderQueue": translate('Label_Costomer_chk_Prevent_orders_from_auto_allocating'),
        "Api: Customer.Options.UserInterface.TransactionConfirmInvoiceCreateDefault": translate('Label_Costomer_Create_Invoice_Defaults'),
        "Orm: ReceiveItem.SerialNumber": translate('Label_Serial_Number'),
        "Api: Receiving.PurchaseOrders": translate('Window_Title_Label_Purchase_Orders'),
        "Orm: PackageContent.Qty": translate('Label_Quantity'),
        "Api: Customer.Options.UserInterface.ReallocateAtPickTime": translate('Label_Costomer_chk_Allow_the_ability_to_reallocation'),
        "Orm: Item.InventoryCategory": translate('Label_Cycle_Count_Type'),
        "Orm: Item.InventoryUnitsPerStorageUnit": translate('Label_Primary_Units_Per_Storage_UOM'),
        "Orm: UiRole.Name": translate('Role_Label_Name'),
        "Api: MispickInfo.Qty": translate('Label_Quantity'),
        "Orm: ItemFacilityStorageRate.Rate": translate('Label_Storage_Rate'),
        "Orm: OrderItem.Qualifier": translate('Label_Qualifier'),
        "Api: ItemRates.LegacyStorageCountScriptInfo": translate('Label_Number_of_Eaches_Per_Storage_UOM'),
        "Api: Customer.Options.Shipping.FulfillmentInvoicing": translate('Label_Costomer_Fulfillment_Invoicing'),
        "Orm: ReceiveItem.ExpirationDate": translate('Label_Expiration_Date'),
        "Orm: ReceiveItem.LotNumber": translate('Label_Lot_Number'),
        "Orm: ReceiveItem.OnHoldReason": translate('Label_Reason_for_Hold'),
        "Api: C2CTransferItem.ReceiverItemId": translate('Label_ReceiveItem_ID'),
        "Api: C2CTransferItem.ToItemTraits.ItemIdentifier.Id": translate('Label_ItemID'),
        "Api: C2CTransferItem.ToItemTraits.PalletIdentifier.NameKey.Name": translate('Label_Pallet'),
        "Api: Move.Destination.NameKey.Name": translate('Label_Transfer_Destination'),
        "Api: ReceiverCreate.CustomerIdentifier": translate('Label_Customer_Name'),
        "Api: Storage.SetInvoiceDateToXactionConfirmDate": translate('Label_Costomer_chk_Set_the_default_invoice_confirm_date'),
        "Api: Customer.Options.UserInterface.MobileReceiptQuantityOneDefault": translate('Label_Costomer_chk_Set_the_default_scan_quantity_to_1'),
        "Orm: MovableUnit.MovableUnitTypeID": translate('Label_Movable_Unit_Type'),
        "Orm: ReceiveItem.Cost": translate('Label_Cost'),
        "Orm: WarehouseTransactionSE.Value": translate('Label_Custom_Field_Value'),
        "Orm: ReceiveItem.ItemID": translate('Label_ReceiveItem_Item_ID'),
        "Orm: AutoBillingScript.PerPalletFeeForOrders.charge": translate('Label_Per_Pallet_Fee'),
        "Other: SKU": translate('Label_Sku'),
        "Other: LocationID": translate('Label_Location_ID'),
        "Other: FieldName": translate('Label_FieldName'),
        "Orm: WarehouseTransaction.ShipToContactID": translate('Label_Ship_to_Contact_ID'),
        "Orm: ReceiveItem.Qualifier": translate('Label_Qualifier'),
        "Api: CountUnitIdentifiers": translate('Label_Counting_Unit'),
        "Orm: AutoBillingScript.ChargeTypeMin.charge": translate('Label_Charge_Type_Min'),
        "Orm: OrderItem.FulfillInvDiscountPct": translate('Label_Discount_Percent'),
        "Orm: MovableUnit.Label": translate('Label_Pallet'),
        "Api: allocations[0].Qty": translate('Label_Quantity'),
        "Orm: WarehouseTransaction.FulfillInvDiscountAmount": translate('Label_Discount_Amount'),
        "Orm: AutoBillingScript.PerTransactionFee.charge": translate('Label_Per_Transaction_Fee'),
        "Orm: AutoBillingScript.PerSkuMinimumReceivingCharge.percharge": translate('Label_Per_SKU_Minimum_Receiving_Charge'),
        "Api: Receiving.SuggestedPutAwayEnabled": translate('Label_Enable_Suggested_Putaway'),
        "Api: ReceiveItem.ItemIdentifier.Sku": translate('Label_Sku'),
        "Api: Shipping.FulfillmentInvoicing": translate('Label_Costomer_Fulfillment_Invoicing'),
        "Api: Rels.InventorySvc.ReceiverConfirm": translate('Label_Confirm_Receipt'),
        "Orm: AutoBillingScript.TransactionMin.charge": translate('Label_Transaction_Min'),
        "Api: ReceiveItem.ItemIdentifier.Id": translate('Label_ReceiveItem_ID'),
        "Api: Shipping.OrderQueue": translate('Label_Costomer_chk_Prevent_orders_from_auto_allocating'),
        "Api: UserInterface.ReallocateAtPickTime": translate('Label_Costomer_chk_Allow_the_ability_to_reallocation'),
        "Api: ReceiverCreate.ReferenceNum": translate('Orders_Lbl_Reference_Num'),
        "Api: Receiving.TrackPallets": translate('Label_Allow_Movable_Units'),
        "Orm: UserLogin.Login": translate('Label_User_Name_already_exists'),
        "Orm: AutoBillingScript.PrimaryQuantityShippingFee.charge": translate('Label_Primary_Quantity_Shipping_Fee'),
        "Other: Country": translate('Label_Country'),
        "Other: Location.Display": translate('Label_Location_Fields'),
        "Api: HttpRequestBase.ContentLength=0": translate('Label_Empty_file'),
        "Orm: OrderItemSE.Name": translate('Label_order_item_level_custom_field'),
        "Api: OrderItem.ItemIdentifier": translate('Label_Sku'),
        "Orm: AutoBillingScript.OutboundPalletFee.charge": translate('Label_Outbound_Pallet_Fee'),
        "Orm: AutoBillingScript.HandlingAndStorageMinOverride.charge": translate('Label_Trans_Handling_and_Storage_Min'),
        "Orm: BarcodeDef.TotalLength": translate('Label_Barcode_Length'),
        "Orm: InventoryAuditLocation.LocationId": translate('Label_Job_Location'),
        "Api: orderViewModel.CustomerId": translate('Label_Customer_Text'),
        "Api: inventorySearchInfo.EndDate": translate('Label_End'),
        "Orm: AutoBillingScript.RoundingPackagingUnitHandling.charge": translate('Label_Rounding_Packaging_Unit_Handling_Script'),
        "Orm: WarehouseTransaction.BatchOrderID": translate('Label_Batch_Order_ID'),
        "Api: C2CTransferItem.ToQty": translate('Label_Quantity'),
        "Orm: AutoBillingScript.UniqueSkuShippingFee.charge": translate('Label_Unique_SKU_Shipping_Fee'),
        "Api: update.StorageRateSetup.InventoryUnitsPerUnit": translate('Label_Notification_NumberOfUnits'),
        "Orm: WarehouseTransactionSE.Name": translate('Label_Order_level_Custom_Field_Name'),
        "Orm: AutoBillingScript.BillingByMUSquareFoot.charge": translate('Label_Movable_Unit_Sq_Ft_Billing'),
        "Api: Shipping.PackingListLogoUrl": translate('Label_Packing_Slip_Logo_URL'),
        "Api: ReceiversMarkRaInfo.ChannelIdentifier": translate('Label_Order_FTP_Name_ID'),
        "Orm: ItemMaterial.QualifierFrom": translate('Label_Qualifier'),
        "Orm: AutoBillingScript.PackagingUnitCubicFootBilling.charge": translate('Label_Rate_Per_Cubic_Feet'),
        "Orm: WarehouseTransaction.PONum": translate('Label_PO_Number'),
        "Orm: Item.StorageUnitOfMeasureTypeID": translate('Label_Storage_handling_Unit'),
        "Orm: Item.ItemQualifiers": translate('Label_Qualifier'),
        "ItemQualifierDelete-InUse":translate('Label_used_qualifier_delete'),
        "LocationUpdate-InUse-cannot deactivate location, has inventory":translate('Label_deactive_inventory_contain_location'),
        "LocationUpdate-LocationHasUnshippedAllocations-Cannot make Location Quarantine type while it has Receivers with Unshipped Allocations in it.":translate('Label_change_inventory_allocated_location'),
        "OrderConfirm-OrderConfirmed":translate('Label_attamp_close_order_close'),
        "OrderConsolidatePackages-HasSmallParcelShipped":translate('Label_OrderConsolidatePackages_HasSmallParcelShipped'),
        "OrderConsolidatePackages-OrderConfirmed":translate('Label_OrderConsolidatePackages_OrderConfirmed'),
        "OrderGeneratePackages-HasSmallParcelShipped":translate('Label_OrderGeneratePackages_HasSmallParcelShipped'),
        "PickJobCreate-OrderAlreadyPicked":translate('Label_PickJobCreate_OrderAlreadyPicked'),
        "PickJobCreate-OrderInDifferentPickJob":translate('Label_PickJobCreate_OrderInDifferentPickJob'),
        "PickJobMispick-NotMispick-mispick data matches allocated inventory":translate('Label_PickJobMispick_NotMispick_mispick'),
        "ReceiverUpdate-ReceiverConfirmed":translate('Label_ReceiverUpdate_ReceiverConfirmed'),
        "UpdateOrder-Canceled": translate('Label_UpdateOrder_Canceled'),
        "LineItemEditOptions-No_Matching_Sku_For_Receiver_And_Customer": translate('Label_Line_Item_Edit_Options'),
        "OrderSetPickTicketDate-OrderConfirmed": translate('Label_Order_confirmed'),
        "-ParseError": translate('Label_Parse_Error'),
        "-Order Already Shipped": translate('Label_Order_Allready_Shipped'),
        "-Void Package request failed. Message: ": translate('Label_Package_Request_Fail_Message'),
        "BatchOrderUpdate-MixedFacilitiesInBatch": translate('Label_Mixed_Facilities_In_Batch'),
        "ReceiverCancel-ReceiverCanceled": translate('Label_Receiver_Canceled'),
        "-CannotReopenReceiver": translate('Label_Cannot_Reopen_Receiver'),
        "-FullyShippedReceiveItem": translate('Label_Fully_Shipped_Receive_Item'),
        "ReceiverConfirm-ReceiverConfirmed": translate('Label_Receiver_Confirmed'),
        "OrderConfirm-NotFullyAllocated": translate('Label_FullyAllocated'),
        "OrderGeneratePackages-OrderAsnSent": translate('Label_Order_Asn_Sent'),
        "LocationDelete-InUse": translate('Label_Location_Delete'),
        "UserNotAuthorizedForActionOnResource-Completed Orders/Receivers can be modified only by warehouse personnel": translate('Label_UserNot_Authorized_For_ActionOnResource'),
        "UserNotAuthorizedForCustomerData": translate('Label_UserNot_Authorized_For_CustomerData'),
        "PickJobCreate-OrderShipped": translate('Label_Order_Shipped'),
        "OrderConfirm-OrderCanceled": translate('Label_Order_Canceled'),
        "-One or more errors occurred.": translate('Label_Oneormore_Errors_Occurred'),
        "-One Or More Selected Orders Have Not Been Shipped": translate('Label_Oneormore_Selected_Orders_Have_Not_Been_Shipped'),
        "-One Or More Selected Orders Are Already Shipped": translate('Label_Oneormore_Selected_Orders_Are_Already_Shipped'),
        "-OrderConfirmed": translate("Label_Operation_Not_Perform_Confirm_Order"),
        "OrderCancel-OrderConfirmed": translate("Label_Operation_Not_Cancel_Confirm_Order"),
        "BatchOrderCreate-MixedFacilitiesInBatch": translate("Label_Mixed_Facilities"),
        "BatchOrderCreate-WrongCustomerInBatch": translate("Label_Mixed_Customers"),
        "Api: UserInterface.TransactionConfirmInvoiceCreateDefault": translate("Label_Invoice_Settings_Disable"),
        "Orm: PackageDef.PackageDef_": translate('Label_Package_Name'),
        "Orm: PackageDef.Barcode": translate('Label_Barcode'),
        "Orm: PackageDef.IsDefault": translate('Label_Default_Package'),
        "Api: FirstTransaction": translate('First_Orders_Lbl_Transaction_Id'),
        "Api: LastTransaction": translate('Last_Orders_Lbl_Transaction_Id'),
        "Api: inventorySearchInfo.FirstTransaction": translate('Label_First_Trans'),
        "Api: inventorySearchInfo.LastTransaction": translate('Label_Last_Trans'),
        "Api: Receiving.AutoFillTrackBys": translate('Label_Costomer_chk_Automatically_popilate_track_by_info'),
        "Api: massAddSearchInfo.ReceiptFirstTransaction": translate('Label_First_Trans'),
        "Api: massAddSearchInfo.ReceiptLastTransaction": translate('Label_Last_Trans'),
        "Api: UserInterface.AutoCheckAutoReallocateOnReceive": translate('Label_Costomer_chk_Change_default_of_Auto_Allocate_On_Reveive'),
        "Api: ItemExchange.Sku": translate('Label_Sku'),
        "Api: massRoutingData.InsuranceAmount.Value": translate('Label_Insurance_Amount'),
        "Orm: PackageContent.ReceiveItemID": translate('Label_ReceiveItem_ID'),
        "Api: inventorySearchInfo.StartDate": translate('Label_Begin'),
        "Api: userView.CellCarrierId": translate('Label_Cell_Carrier'),
        "Api: updatedOrderData.RoutingInfo.PickupDate": translate('Label_Pickup_Date_Sanitized'),
        "Api: OtherUserFeatures.ReallocateAtPickTimeEnabled": translate('Label_Reallocation_During_Pick'),
        "Api: packageData.PackageContents[0].ExpirationDate": translate('Label_Expiration_Date'),
        "Api: details[0].ReceivedDate": translate('Label_Receipt_Date'),
        "Orm: ReceiveItem.OrigQtyPrimary": translate('Label_Received_Primary'),
        "Api: packageData.Weight": translate('Label_Weight'),
        "Api: Shipping.SmartPackScaleEnabled": translate('Label_Threepl_Settings_Smart_Pack_Scale'),
        "Api: CustomerCarrierMarkup.Carrier": translate('Label_Carrier_Markup'),
        "PackageDefDelete-InUse": translate('Label_Delete_Package_In_Use'),
        "ReceiverCancel-ReceiverAllocated": translate('Label_Cancel_Receipt_Allocate'),
        "OrderComplete-AlreadyCompleted": translate('Label_Already_Complete_Order'),
        "Orm: Item.UPC": translate('Label_Primary_UPC'),
        "Orm: Item.SecondaryUnitUPC": translate('Label_Secondary_UPC'),
        "Api: update.StorageRateSetup.MinCharge": translate('Label_Minimum_Charge'),
        "Api: update.StorageRateSetup.MonthsToSkipMinCharge": translate('Label_Months_Skip_Min_Charge'),
        "OrderConsolidatePackages-OrderAsnSent": translate('Label_Consolidate_Order_Asn_Sent'),
        "ItemDelete-InUse": translate('Label_Item_InUse'),
        "ReceiverComplete-ReceiverConfirmed": translate('Label_Receiver_Complete'),
        "ReceiverConfirm-NoReceiveItems": translate('Label_NoReceive_Items_Confirm'),
        "PackingSlipTemplateDelete-InUse-cannot delete packingsliptemplate": translate('Label_PackingSlipTemplate_In_Use'),
        "AssemblyOrderCompleteXaction-NotFullyAllocated": translate('Label_Assembly_Unallocated_Complete'),
        "ThreePLSetting-InvalidRecipient": translate('Label_GlobalRecipient_Invalid')
    });
};

