import React, { Component } from "react";
import { PackagingTypesGrid } from './packaging-types-grid.component.jsx'
import { PackagingTypesSearch } from "./packaging-types-search.component.jsx";

// Services
import * as GlobalService from "../../global_services";
import * as packagingTypeMetaDataService from './packaging-type-meta-data.service';

import style from "./packagingTypes.scss";

export class PackagingTypesHolder extends Component {
    constructor(props) {
        super(props);

        this.translate = window.translate
        this.$rootScope = window;
        this.windowId = this.$rootScope.ChildBulletin.Data.Window;
        this.selector = "PackagingTypes";
        this.defaultFilterValue = {
            IndexOptionsCustomerId: "0",
            IndexOptionsMaterialType: "All",
        }

        this.state = {
            sharedPackagingTypesFilter: Object.assign({}, this.defaultFilterValue),
            sharedSearchOn: false,
            isMetaCallComplete: false,
        };
    }
    async componentDidMount() {
        var sharedPackagingTypesFilter = Object.assign({}, this.defaultFilterValue);
        var sharedSearchOn = false;

        kendo.ui.progress($('#' + this.windowId), true);
        await packagingTypeMetaDataService.getUserClientMetaData();
        GlobalService.GetCustomersAndFacility();

        var filterData = packagingTypeMetaDataService.getPackagingTypesFilterMetaData();
        if (filterData != "" && !(_.isEqual(this.defaultFilterValue, filterData))) {
            sharedPackagingTypesFilter = filterData;
            sharedSearchOn = true;
        }
        if(sharedPackagingTypesFilter.IndexOptionsMaterialType == undefined){
            sharedPackagingTypesFilter.IndexOptionsMaterialType = "All";
        }
        this.setState({
            sharedPackagingTypesFilter: sharedPackagingTypesFilter,
            sharedSearchOn: sharedSearchOn,
            isMetaCallComplete: true
        });
        kendo.ui.progress($('#' + this.windowId), false);
    }

    onClearSearch = () => {
        packagingTypeMetaDataService.clearPackagingTypesFilterMetaData();
        this.setState({
            sharedPackagingTypesFilter: Object.assign({}, this.defaultFilterValue),
            sharedSearchOn: false
        });
    }

    onFilterDataSet = (param_filterObj) => {
        packagingTypeMetaDataService.setPackagingTypesFilterMetaData(param_filterObj);
        this.setState({
            sharedPackagingTypesFilter: param_filterObj,
            sharedSearchOn: !(_.isEqual(this.defaultFilterValue, param_filterObj))
        });
    }

    render() {
        return (
            <div id="wms-packaging-types-wrapper" className="new-layout-grid-filter-wrapper">
                {this.state.isMetaCallComplete && (
                    <PackagingTypesSearch
                        windowId={this.windowId}
                        selector={this.selector}
                        sharedSearchOn={this.state.sharedSearchOn}
                        onFilterDataSet={this.onFilterDataSet}
                        sharedPackagingTypesFilter={this.state.sharedPackagingTypesFilter}
                        onClearSearch={this.onClearSearch}
                    />
                )}

                {this.state.isMetaCallComplete && (
                    <PackagingTypesGrid
                        windowId={this.windowId}
                        selector={this.selector}
                        sharedPackagingTypesFilter={this.state.sharedPackagingTypesFilter}
                    />
                )}
            </div>
        );
    }
}
