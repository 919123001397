
import React from 'react';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import style from './cyclecount.scss';
import { CyclecountGrid } from './cyclecount-grid.component.jsx';
import { CyclecountForm } from './cyclecount-form.component.jsx';
import { ReviewCountSection } from './reviewcount-section.component.jsx';
import { ReconciletSection } from './reconcile-section.component.jsx';

import * as GlobalService from './../global_services';
import * as cyclecountService from './cyclecount.services.jsx';
import * as emitter from '../emitter';
import { kendo } from "./../global_variable_service.jsx";
var that;

export class cyclecountHolder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            warehouseOption: [],
            indexOptionsWarehouse: '',
            sharedCycleCount: {
                userOption:[]
            },
            selector:'AuditInventory'

        };

        this.translate = window.translate;
        this.handleChange = this.handleChange.bind(this);
        this.setCreateEditData = this.setCreateEditData.bind(this);
        that = this;

        // reload grid after create/edit job
        emitter.eventBus.on('reloadJobGrid', function (data) {
            that.initialOption(that.state.sharedCycleCount.warehouseSelection.uri);
            that.setState({
                sharedCycleCount: Object.assign(
                    {},
                    that.state.sharedCycleCount,
                    {
                        flowType: 'create',
                        createEditFormShow:false,
                        reviewCountSectionShow:false,
                        reconcileSectionShow:false,
                        EditData: [],
                    }
                ),
            });
        });
    }

    componentDidMount() {
        kendo.ui.progress($(".cyclecount-holder-react"), true);
        GlobalService.GetCustomersAndFacility().then(() => {
            this.setState({
                warehouseOption: GlobalService.getFacilityList(false)
            });
        }).finally(() => {
            kendo.ui.progress($('.cyclecount-holder-react'), false);
        });
    }

    handleChange(event) {

        this.setState({
            [event.target.name]: event.target.value
        });

        this.state.warehouseOption.filter(function (item) {
            if (item.id == event.target.value) {
                setTimeout(function () {
                    that.setState({
                        sharedCycleCount: Object.assign(
                            {},
                            that.state.sharedCycleCount,
                            {
                                flowType: 'create',
                                EditData: [],
                                warehouseSelection: item,
                                reviewCountSectionShow: false,
                                reconcileSectionShow: false,
                            }
                        ),
                    });
                    that.initialOption(that.state.sharedCycleCount.warehouseSelection.uri);
                }, 0);
            }
        });

    }

    closeJob(auditCountsUris) {

        that.setState({ sharedCycleCount: Object.assign({}, that.state.sharedCycleCount, { closejobGif: true, }) });
        cyclecountService.closeJob(auditCountsUris).then(function (response) {
            console.log('Closejob Response', response);
            // response = { status: 204 };
            if (typeof response != 'undefined' && response.status == 204) {
                GlobalService.notification('success', that.translate('Label_Close_job_success'));

                that.setState({
                    sharedCycleCount: Object.assign(
                        {},
                        that.state.sharedCycleCount,
                        {
                            createEditFormShow: false,
                            reviewCountSectionShow: false,
                            reconcileSectionShow: false,
                            closejobGif: false,
                        }
                    ),
                });
                that.initialOption(that.state.sharedCycleCount.warehouseSelection.uri);
            }
            that.setState({ sharedCycleCount: Object.assign({}, that.state.sharedCycleCount, { closejobGif: false, }) });
        }, function (response) {
            // GlobalService.notification('error', response);
            that.setState({ sharedCycleCount: Object.assign({}, that.state.sharedCycleCount, { closejobGif: false, }) });
        });
    }

    // Function called when setCreateEditData props call by child
    setCreateEditData(data){
        console.log('setCreateEditData : ',data);
        this.setState({
            sharedCycleCount: Object.assign(
                {},
                that.state.sharedCycleCount,
                {
                    flowType: data.flowType,
                    EditData: data.EditData,
                    createEditFormShow: data.createEditFormShow,
                    reviewCountSectionShow: data.reviewCountSectionShow,
                    reconcileSectionShow:false
                }
            ),
        });
    }
    closeCyclecountForm(){
        that.setState({
            sharedCycleCount: Object.assign(
                {},
                that.state.sharedCycleCount,
                {
                    createEditFormShow:false,
                    reviewCountSectionShow:false,
                    reconcileSectionShow:false,
                }
            ),
        });
    }

    closeReviewCountSection(){
        that.setState({
            sharedCycleCount: Object.assign(
                {},
                that.state.sharedCycleCount,
                {
                    reviewCountSectionShow: false,
                }
            ),
        });
    }
    toggleReconcileSection(){
        that.setState({
            sharedCycleCount: Object.assign(
                {},
                that.state.sharedCycleCount,
                {
                    reconcileSectionShow: !that.state.sharedCycleCount.reconcileSectionShow,
                    reviewCountSectionShow: false,
                }
            ),
        });
    }

    initialOption(facilityUri) {
        kendo.ui.progress($('#cycleCountJobsGrid .k-grid-content'), true);
        cyclecountService.cyclecountOption(facilityUri).then(function (response) {
            if (response.status == 200) {
                response.data.LocationTypes.unshift({ "Name": "All", "Value": 0 });
                that.setState({
                    sharedCycleCount: Object.assign(
                        {},
                        that.state.sharedCycleCount,
                        {
                            searchInfo: response.data.SearchInfo,
                            locationTypes: response.data.LocationTypes,
                            userOption: response.data.Users,
                            cycleCountGridData: response.data.Audits,
                        }
                    ),
                });
            }
            kendo.ui.progress($('#cycleCountJobsGrid .k-grid-content'), false);
        }, function (response) {
            console.log("Grid w sort ", response);
            kendo.ui.progress($('#cycleCountJobsGrid .k-grid-content'), false);
        });
    }


    render() {
        return (
            <div className="cyclecount-holder-react">
                <div className="select-field">
                            <WmsDropdown
                                id="indexOptionsWarehouse"
                                name="indexOptionsWarehouse"
                                label={GlobalService.getCustomLabels().Label_Warehouse}
                                wmsSelector={this.state.selector+"warehouseDropdown"}
                                value={this.state.indexOptionsWarehouse}
                                onChangeFunc={this.handleChange}
                                options={this.state.warehouseOption}
                                valueField='id'
                                textField='name'
                                parentClassName='form_group'
                                required={true}
                                blankFirstOption={true}
                                blankFirstOptionText={''}
                            />
                </div>
                {this.state.indexOptionsWarehouse && (<div className="main_content" id="cycleCountMainContent" >
                    <CyclecountGrid
                        sharedCycleCount={this.state.sharedCycleCount}
                        setCreateEditData={this.setCreateEditData}
                        reloadJobGrid={(e) => this.initialOption(that.state.sharedCycleCount.warehouseSelection.uri)}
                        closeJob={this.closeJob}
                        setCloseData={this.setCloseData}
                    />
                    {this.state.sharedCycleCount.createEditFormShow && (<CyclecountForm
                        sharedCycleCount={this.state.sharedCycleCount}
                        closeCyclecountForm={this.closeCyclecountForm}
                    />)}
                    {this.state.sharedCycleCount.reviewCountSectionShow && (<ReviewCountSection
                        sharedCycleCount={this.state.sharedCycleCount}
                        closeReviewCountSection={this.closeReviewCountSection}
                        toggleReconcileSection={this.toggleReconcileSection}
                    />)}
                    {this.state.sharedCycleCount.reconcileSectionShow && (<ReconciletSection
                        sharedCycleCount={this.state.sharedCycleCount}
                        toggleReconcileSection={this.toggleReconcileSection}
                        closeJob={this.closeJob}
                    />)}
                </div>)}
            </div>
        );

    }
}