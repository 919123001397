export const getCarrierGridColumnsColumns = (carrier_GridHeaders, isEnableProvider, sharedSmallParcelSettings) => {
    return ({
        columns: [{
            field: "RowNumber",
            title: "#",
            hidden: true,
            menu: false,
            width: "100%"
        }, {
            field: "Owner",
            title: carrier_GridHeaders[0].description,
            width: 340,
            wmsid: 1
        }, {
            field: "RateShop",
            title: carrier_GridHeaders[6].description,
            width: 120,
            wmsid: 6,
            hidden: sharedSmallParcelSettings.SmallParcelCarrierSelectionV2 ? false : true,
            template: function (dataItem) {
                let rateshop = "";
                if(sharedSmallParcelSettings.SmallParcelRateShoppingCarrierAccounts) {
                    let selectedCarrierAccounts = sharedSmallParcelSettings.SmallParcelRateShoppingCarrierAccounts.split(",");
                    if(selectedCarrierAccounts.indexOf(dataItem.AccountNumber) !== -1) {
                        rateshop = "Yes";
                    }
                }
                return rateshop;
            }
        }, {
            field: "Carrier",
            title: carrier_GridHeaders[1].description,
            width: 160,
            wmsid: 2,
            template: function (dataItem) {
                let carrier = dataItem.Carrier;
                if (isEnableProvider) {
                    if (carrier == "Stamps.com" || carrier == "Endicia") {
                        carrier = "USPS - " + carrier;
                    } else if (carrier == "Buku" && dataItem.Provider == "bukuShip") {
                        carrier = "USPS - BUKU Ship";
                    } else if (carrier == "PitneyBowesExp") {
                        carrier = "USPS - Pitney Bowes Expedited";
                    } else if (carrier == "PitneyBowesStd") {
                        carrier = "Pitney Bowes Standard";
                    }
                }
                return carrier;
            }
        }, {
            field: "Provider",
            title: carrier_GridHeaders[2].description,
            width: 160,
            wmsid: 3,
            hidden : isEnableProvider ? false : true,
            template: function (dataItem) {
                let provider = "";
                if (dataItem.Provider == "shipEngine") {
                    provider = "ShipEngine";
                } else if (dataItem.Provider == "bukuShip") {
                    provider = "BUKU Ship";
                } else if (dataItem.Provider == "pitneyBowes") {
                    provider = "Pitney Bowes";
                } else if (dataItem.Provider == "flexport") {
                    provider = "Flexport";
                }
                return provider;
            }
        }, {
            field: "NickName",
            title: carrier_GridHeaders[3].description,
            width: 255,
            filterable: true,
            wmsid: 4
        }, {
            field: "Type",
            title: carrier_GridHeaders[4].description,
            width: 155,
            wmsid: 5
        }, {
            field: "AccountNumber",
            title: carrier_GridHeaders[5].description,
            width: 185,
            wmsid: 6
        }]
    });
};

export const getCarrierGridSchema = () => {
    return ({
        schema: {
            data: "Data",
            total: "Total",
            model: {
                id: "Owner",
                fields: {
                    RowNumber: {
                        type: 'number'
                    },
                    facility: {
                        type: "string",
                        editable: false
                    },
                    rateshop: {
                        type: "string",
                        editable: false
                    },
                    carrier: {
                        type: "string",
                        editable: false
                    },
                    provider: {
                        type: "string",
                        editable: false
                    },
                    accnickname: {
                        type: "string",
                        editable: false
                    },
                    acctype: {
                        type: "string",
                        editable: false
                    }
                }
            }
        }
    });
};

