import React from 'react';

//component
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsDropdownList from './../components/WmsDropdownList/WmsDropdownList.jsx';
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';
import WmsModel from './../components/WmsModel/WmsModel.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';

import * as GlobalService from './../global_services';
import * as itemService from './items.services.jsx';
import style from './items-preferred-location-model.scss';

export class ItemsPreferredLocationModel extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;

        this.state = {
            selectfacilityID: 0,
            preferedLocationObj: {
                Id:0
            },
            // facilityLocationOptions: []
        };
    }

    componentDidMount() {
        if (this.props.sharedItem.ItemBasics.DirectedPutawayOptions['PreferredLocation'] != null &&
            this.props.sharedItem.ItemBasics.DirectedPutawayOptions.PreferredLocation.FacilityId != this.state.selectfacilityID) {
            // this.getLocationDropdownRead(this.props.sharedItem.ItemBasics.DirectedPutawayOptions.PreferredLocation.FacilityId);
            let sharedLocationObj = this.props.sharedItem.ItemBasics.DirectedPutawayOptions.PreferredLocation;
            this.setState({
                selectfacilityID: sharedLocationObj.FacilityId,
                preferedLocationObj: { Id:sharedLocationObj.LocationId }
            });
        }
    }
    /*componentWillReceiveProps(nextProps) {

        if (nextProps.sharedIsOpenPreferredLocationModel == true &&nextProps.sharedItem.ItemBasics.DirectedPutawayOptions['PreferredLocation'] != null &&
            nextProps.sharedItem.ItemBasics.DirectedPutawayOptions.PreferredLocation.FacilityId != this.state.selectfacilityID) {

            this.getLocationDropdownRead(nextProps.sharedItem.ItemBasics.DirectedPutawayOptions.PreferredLocation.FacilityId);
            this.setState({
                selectfacilityID: nextProps.sharedItem.ItemBasics.DirectedPutawayOptions.PreferredLocation.FacilityId,
            });

        }else{
            this.setState({
                selectfacilityID: 0,
                preferedLocationId: 0,
            });
        }
    }*/

    handlePrefereLocationModalchange = (event) => {
        /*if (event.target.name == 'selectfacilityID') {
            this.getLocationDropdownRead(event.target.value);
        }*/
        this.setState({ [event.target.name]: event.target.value });
    }

    /*getLocationDropdownRead(param_facilityID) {
        itemService.getLocationDropdownRead(param_facilityID).then((response) => {
            if (response.status == 200) {
                let preferedLocationId = 0;

                if (this.props.sharedItem.ItemBasics.DirectedPutawayOptions['PreferredLocation'] != null) {
                    preferedLocationId = this.props.sharedItem.ItemBasics.DirectedPutawayOptions.PreferredLocation.LocationId;
                }

                this.setState({
                    facilityLocationOptions: response.data.Data,
                    preferedLocationId: preferedLocationId
                });

            }
        }, (response) => { });
    }*/


    clickAddPreferredLocationId = () => {

        let allFacilities = GlobalService.getFacilityList(false);
        let facilityindex = ObjectWms.FindIndexByProperty(allFacilities, "id", parseInt(this.state.selectfacilityID));
        let selectFacilityObj = allFacilities[facilityindex];
        /*let locationIndex = ObjectWms.FindIndexByProperty(this.state.facilityLocationOptions, "Id", parseInt(this.state.preferedLocationId));

        let selectLocationObj = this.state.facilityLocationOptions[locationIndex];*/
        let propItemObj = this.props.sharedItem;
        propItemObj.ItemBasics.DirectedPutawayOptions.PreferredLocationId = this.state.preferedLocationObj.Id;
        propItemObj.ItemBasics.DirectedPutawayOptions['PreferredLocation'] = {
            "LocationId": this.state.preferedLocationObj.Id,
            "LocationName": this.state.preferedLocationObj.Name,
            "FacilityId": selectFacilityObj.id,
            "FacilityName": selectFacilityObj.name
        };

        this.props.onItemDataset(propItemObj);
        $("#itemsPreferredLocation").data('kendoWindow').close();
        this.props.onPreferredLocationClose();

    }

    exitAddPreferredLocation = () => {
        $("#itemsPreferredLocation").data('kendoWindow').close();
        this.props.onPreferredLocationClose();
    }

    closePreferredLocationModal = () => {
        this.props.onPreferredLocationClose();
    }

    render() {
        return (
            <WmsModel
                id='itemsPreferredLocation'
                title={this.translate('Label_Add_Preferred_Location_Id')}
                width={500}
                height={266}
                onCloseFunc={this.closePreferredLocationModal}
                isOpen={this.props.sharedIsOpenPreferredLocationModel}
                class="item-preffered-location-wrapper"
                customClass='wms-model-new-ui-wrapper'>

                <div className='model-content-wrapper'>
                <WmsContainer>
                    <div className="wms_add_location_model">
                        <WmsDropdown
                            id="selectfacilityID"
                            name="selectfacilityID"
                            label={this.translate('Label_Warehouse_Text')}
                            wmsSelector={this.state.selector + "SelectedFacility"}
                            value={this.state.selectfacilityID}
                            onChangeFunc={this.handlePrefereLocationModalchange}
                            options={this.props.sharedCustomerFacilities}
                            valueField='id'
                            textField='name'
                            blankFirstOption={true}
                            required={true} />

                        {/*<WmsDropdown
                            id="preferedLocationId"
                            name="preferedLocationId"
                            label={this.translate('Label_Preferred_Location_Text')}
                            wmsSelector={"PreferredLocationDropdownSelection"}
                            value={this.state.preferedLocationId}
                            disabled={this.state.selectfacilityID == 0}
                            onChangeFunc={this.handlePrefereLocationModalchange}
                            options={this.state.facilityLocationOptions}
                            valueField='Id'
                            textField='Name'
                            blankFirstOption={true}
                            blankFirstOptionText={'Unspecified'}/>*/}

                            <WmsDropdownList
                                id="preferedLocation"
                                name="preferedLocationObj"
                                optionsLabel={{
                                    Name: this.translate('Label_Preferred_Location_Text'),
                                    Id: 0
                                }}
                                wmsSelector={"PreferredLocationDropdownSelection"}
                                value={this.state.preferedLocationObj.Id }
                                onChangeFunc={this.handlePrefereLocationModalchange}
                                textField='Name'
                                valueField='Id'
                                template={"<span style='white-space: nowrap;text-overflow: ellipsis;width: 210px;display: block;overflow: hidden'>${ Name } - ${Description}</span>"}
                                virtualObj={{
                                    itemHeight: 45,
                                    valueMapper:  (options) => {
                                        var that = options;
                                        console.log("valumapper that",that);
                                        $.ajax({
                                            url: "/WebUI/Items/Items/LocationDropdownIndexRead",
                                            type: "POST",
                                            dataType: "json",
                                            data: {
                                                locationId: this.state.preferedLocationObj.Id || 0,
                                                facilityId: this.state.selectfacilityID
                                            },
                                            success: function (data) {
                                                console.log("value mapper success",data);
                                                options.success(data);
                                            }
                                        });
                                    }
                                }}
                                dataSourceObj={{
                                    type: "aspnetmvc-ajax",
                                    pageSize: 50,
                                    serverPaging: true,
                                    serverFiltering: true,
                                    transport: {
                                        read: {
                                            url: "/WebUI/items/items/LocationDropDownRead",
                                            type: "POST",
                                            data: { facilityId: this.state.selectfacilityID }
                                        }
                                    },
                                    schema: {
                                        data: "Data",
                                        total: "Total"
                                    }
                                }}
                            />
                    </div>
                </WmsContainer>
                </div>
                <div className="footer-btn-holder">
                <WmsButton
                    label={this.translate('Label_Cancel')}
                    wmsSelector={'cycleCountCancel'}
                    onClickFunc={this.exitAddPreferredLocation}
                    buttonType='orange'
                    icon="fa-ban"
                />

                <WmsButton
                    label={this.translate('Label_Save')}
                    wmsSelector={'PreferredLocationSave'}
                    onClickFunc={this.clickAddPreferredLocationId}
                    icon='fa-floppy-o'
                    disabled={this.state.selectfacilityID == 0 || this.state.preferedLocationObj.Id == 0 } />

                
                </div>
            </WmsModel>
        );
    }
}