import React from 'react';
var axios = require('axios');
var UtcOffset = new Date().getTimezoneOffset() / 60 * -1;

import * as GlobalService from '../../global_services';

var _manageOrdersEditData = {};

export const startManageOrders = function () {

    return axios.all([
        GlobalService.GetCustomersAndFacility()
    ]).then(axios.spread(function (GetCustomerFacilityList) {
        return GetCustomerFacilityList;
    }));
};

export const getManageOrdersData = function () {
    return _manageOrdersEditData;
};
export const setManageOrdersData = function (obj) {
    _manageOrdersEditData = obj;
};
export const saveOrder = function (orderData, isCustomerUser) {
    let params = {
        "orderData": orderData,
        "isCustomerUser": isCustomerUser
    };
    return axios.post('/WebUI/Shipping/Orders/Order', params)
        .then(function (response) {
            return response;
        });
};
export const cloneOrder = function (orderData, sourceOrderId, isCustomerUser) {
    let params = {
        "orderData": orderData,
        "sourceOrderId": sourceOrderId,
        "isCustomerUser": isCustomerUser
    };
    return axios.post('/WebUI/Shipping/Orders/CloneOrder', params)
        .then(function (response) {
            return response;
        });
};

export const updateOrder = function (oldOrderData, updatedOrderData) {
    let params = {
        "oldOrderData": oldOrderData,
        "updatedOrderData": updatedOrderData
    };
    // if(GlobalService.GetRights().hasOwnProperty("Shipping.SmallParcelEnabled")) {
        params.updateParcelOptions = true;
    // }
    return axios.put('/WebUI/Shipping/Orders/Order', params)
        .then(function (response) {
            response.data.TotalWeight = response.data.TotalWeight ? response.data.TotalWeight.toFixed(4) : "0.0000";
            response.data.TotalVolume = response.data.TotalVolume ? response.data.TotalVolume.toFixed(4) : "0.0000";

            return response;
        });
};

export const setFacility = function (facility) {
    let params = {
        "facility": facility
    };
    return axios.post('/WebUI/Shared/GlobalServices/SetFacility', params)
        .then(function (response) {
            return response.data;
        });
};
export const setCustomer = function (customer) {
    let params = {
        "customer": customer
    };
    return axios.post('/WebUI/Shared/GlobalServices/SetCustomer', params)
        .then(function (response) {
            return response.data;
        });
};
export const ManageOrdersEditData = function (orderId, gridName) {
    let params = {
        "orderId": orderId,
        "getProposedBilling": true
    };
    // if(GlobalService.GetRights().hasOwnProperty("Shipping.SmallParcelEnabled")) {
        params.getParcelOptions = true;
    // }
    return axios.post('/WebUI/Shipping/Orders/OrderOptions', params)
        .then(function (response) {
            response.data.Order.TotalWeight = response.data.Order.TotalWeight ? response.data.Order.TotalWeight.toFixed(4) : "0.0000";
            response.data.Order.TotalVolume = response.data.Order.TotalVolume ? response.data.Order.TotalVolume.toFixed(4) : "0.0000";

            return response.data;
        });
};

export const GetOrderSummary = function (orderId) {
    return axios.get('/WebUI/Shipping/Orders/GetOrderSummary?orderId='+orderId)
        .then(function (response) {
            return response.data;
        });
};

// contact detail section services
export const getContactFromUri = function (uri) {

    return axios.post('/WebUI/Shipping/Orders/GetContactFromUri', { uri: uri })
        .then(function (response) {
            return response;
        });
};

// contact detail section services
export const GetChargeCategories = function () {
    return axios.get('/WebUI/Shipping/Orders/GetChargeCategories').then(function (response) {
        return response.data;
    });
};

// line item section service
export const deleteMultipleOrderLineItems = function (param_obj) {

    return axios.post('/WebUI/Shipping/Orders/DeleteMultipleOrderLineItems', param_obj, { dataType: "JSON" }
    ).then(function (response) { });
};
export const deleteSingleOrderLineItems = function (param_uri,param_orderid) {

    return axios.delete('/WebUI/Shipping/Orders/OrderItem?editUri='+param_uri+"&orderId="+param_orderid).then(function (response) { });
};
export const initialEditAllocations = function (param_obj) {
    return axios.post('/WebUI/Shipping/Orders/GetLineItemAllocations', {
        "orderId": param_obj.orderId,
        "orderItemUri": param_obj.EditWithAllocationDetailsUri
    }).then(function (response) {
        return response.data;
    });
};

export const editOrderLineItemAllocations = function (obj) {
    return axios.post('/WebUI/Shipping/Orders/EditOrderLineItemAllocations', obj, {
        dataType: "JSON"
    }).then(function (response) {
        return response;
    });
};

export const recalculateOrderCharges = function (orderId) {
    return axios.get('/WebUI/Shipping/Orders/OrderCharges?orderId=' + orderId).then(function (response) {
        return response;
    });
};

export const initialMassAdd = function () {
    return axios.post('/WebUI/Shipping/UpdateSession/MassAddSearchInfoClear').then(function (response) {

    });
};
export const massOrderLineItemsAdd = function (obj) {
    return axios.post('/WebUI/Shipping/Orders/MassOrderLineItemsAdd', obj, { dataType: "JSON" }).then(function (response) {
        return response;
    });
};
export const massOrderLineItems = function (obj) {
    return axios.post('/WebUI/Shipping/Orders/MassOrderLineItems', obj, { dataType: "JSON" }).then(function (response) {
        return response;
    });
};

export const editOrderLineItemData = function (orderId, inventorySummaryUri, lineItemData) {
    return axios.post('/WebUI/Shipping/Orders/EditOrderLineItem', {
        orderId: orderId,
        inventorySummaryUri: inventorySummaryUri,
        models: lineItemData
    }).then(function (response) {
        return response;
    });
};
export const getOrderChargeSummary = function (param_orderid) {
    return axios.post('/WebUI/Shipping/Orders/GetOrderChargeSummary?id=' + param_orderid).then(function (response) {
        return response;
    });
};
export const shipAndCloseOrder = function (param_payload_obj) {
    return axios.post('/WebUI/Shipping/Orders/ShipAndCloseOrder',param_payload_obj).then(function (response) {
        return response;
    });
};
export const finalizeOrder = function (param_payload_obj) {
    return axios.post('/WebUI/Shipping/Orders/Complete',param_payload_obj).then(function (response) {
        return response;
    });
};

