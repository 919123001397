import React from 'react';
var axios = require('axios');
import * as GlobalService from '../global_services';


export const registerShipEngine = function (param_payload) {

    return axios.post("/WebUI/Settings/CarrierAccount/RegisterShipEngine", param_payload).then(function (response) {
        return response
    });

}


export const deleteCarrierAccount = function (carrierAccountUri) {

    return axios.delete("/WebUI/Settings/CarrierAccount/DeleteCarrierAccount?carrieraccounturi=" + carrierAccountUri).then(function (response) {
        return response
    });

}
export const getCarrierDetail = function (shipEngoineId) {

    return axios.get("/WebUI/Settings/CarrierAccount/ShipEngineSettings?seId="+shipEngoineId).then(function (response) {
        return response
    });

}
export const updateCarrierSetting = function (carrierObj) {

    return axios.put("/WebUI/Settings/CarrierAccount/ShipEngineSettings",carrierObj).then(function (response) {
        return response
    });

}
