var axios = require('axios');
import * as GlobalService from '../global_services';


export const getOutBoundDashboard = function (postParams) {

    return axios.post(globalsMsg.outbound_dashboard_url, postParams)
        .then(function (response) {
            return response.data;
        });
}