import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { DictionaryWms } from '../../../assets/ts/DictionaryWms.ts';
import { KendoWms } from '../../../assets/ts/KendoWms.ts';

import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsGrid from './../components/WmsGrid/WmsGrid.jsx';

import style from './roles.scss';
import * as GlobalService from './../global_services';

import * as roleService from './roles.service.jsx';
import * as GridConfig from './grid_configuration.jsx';

export class RolesGrid extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        let rolesGridHeader = StringWms.convertSringToOption(this.translate('Rolemanager_Grid_Header'));

        this.state = {

            gridDomSelector: () => $('.' + this.props.windowId + ' #GridFindRoles'),
            columns: GridConfig.getGridDemoColumns(rolesGridHeader, GlobalService.getCustomLabels()).columns,
            schema: GridConfig.getGridDemoSchema().schema,
            transport: GridConfig.getGridDemoTransport().transport,
            localKendoGrid: null,
            selectRoleData: null,
            Selector: 'Roles',
            searchOn: false,
            roleTemplateDisableNote: '',
            enableEditButton: false,
            enableRoleButton: false,
            enableDeleteButton: false,
            initialRoleDataObj: {
                Roles: [],
                loggedInUserRoles: {},
                //resources: {}
            },
            optionConatins: [],
            filterObject: [],
            filter: {
                IndexOptionsRole: 0,
                role: '',
            }
        }
    }

    componentDidMount() {
        this.setState({
            optionConatins: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchFilters'))
        });
        let loaderSourceId = 'GridFindRoles';
        if (!this.state.initialRoleDataObj.Roles.length) {
            loaderSourceId = this.props.windowId;
        }

        kendo.ui.progress($('#' + loaderSourceId), true);

        document.addEventListener("onUserRoleUpdate", this.onUserRoleUpdate);
        var that = this;
        document.addEventListener("onUserRoleUpdateRemove", function handler(e) {
            document.removeEventListener("onUserRoleUpdate", that.onUserRoleUpdate);
            e.currentTarget.removeEventListener(e.type, handler);
            /* whatever */
        });
    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.reloadGrid == 'reloadRoleGrid') {
            this.initialFindRole();
        }
    }

    onUserRoleUpdate = () => {
        if (typeof $('#GridFindRoles').data('kendoGrid') != 'undefined') {
            this.initialFindRole();
        }
    }

    onLoadRoleGrid = () => {
        this.initialFindRole();
    }

    initialFindRole = () => {

        let loaderSourceId = 'GridFindRoles';
        if (!this.state.initialRoleDataObj.Roles.length) {
            loaderSourceId = this.props.windowId;
        }

        kendo.ui.progress($('#' + loaderSourceId), true);

        roleService.initialFindRole('').then((response) => {

            if (typeof response != "undefined" && response.status == 200) {
                var finalRoleData = response.data.Roles.filter(el => el.Name !== "superadmin");
                this.setState({
                    initialRoleDataObj: { ...response.data, Roles: finalRoleData},
                    enableEditButton: false,
                    enableRoleButton: false,
                    enableDeleteButton: false,
                });

                this.gridSearch();
            }

        }).finally(() => {
            kendo.ui.progress($('#' + loaderSourceId), false);
        });
    }

    //when select any row enable / disable button or context menu
    selectRow = (param_kendogrid) => {

        let currentSelections = param_kendogrid.getNumberOfSelections();
        let enableEditButton = false;
        let enableRoleButton = false;
        let enableDeleteButton = false;
        let roleTemplateDisableNote = '';
        let selectRoleData = null;

        if (currentSelections === 1) {
            selectRoleData = param_kendogrid.getItem(param_kendogrid.getKeys());
            enableEditButton = true;
            enableRoleButton = selectRoleData.Name !== "superadmin";
        }
        if (currentSelections == 1 && param_kendogrid.getItem(param_kendogrid.getKeys()).InUse == false) {
            enableDeleteButton = true;
        }
        if (currentSelections == 1 && param_kendogrid.getItem(param_kendogrid.getKeys()).IsTemplate == true) {
            roleTemplateDisableNote = this.translate('Template_Role_Note');
            enableEditButton = false;
            enableDeleteButton = false;
        }


        this.setState({
            roleTemplateDisableNote: roleTemplateDisableNote,
            enableEditButton: enableEditButton,
            enableRoleButton: enableRoleButton,
            enableDeleteButton: enableDeleteButton,
            selectRoleData: selectRoleData
        });
    }

    gridSearch = () => {
        let searchOn = false

            let dataArr = new Array();
            if (this.state.filter.role != "") {

                dataArr.push({
                    field: "Name",
                    operator: KendoWms.getFilterOperatorByIndex(this.state.filter.IndexOptionsRole),
                    value: this.state.filter.role
                });
                searchOn = true;
            }

            this.setState({
                filterObject: dataArr,
                searchOn: searchOn
            });
    }

    clearFilters = () => {
        // set default value of filter form
        this.setState({
            filter: Object.assign(
                {},
                this.state.filter,
                {
                    "IndexOptionsRole": 0,
                    "role": ""
                }
            ),
            searchOn: false,
            filterObject: []
        });
    }

    filterHandleChange = (event) => {
        this.setState({
            filter: Object.assign(
                {},
                this.state.filter,
                { [event.target.name]: event.target.value }
            ),
        });
    }

    clickEventOfContextMenu = (obj) => {

        if (obj.wmsid == 'edit') {
            this.onEditRole();
        } else if (obj.wmsid == 'delete') {
            this.onDeleteRole();
        } else if (obj.wmsid == 'copyRole') {
            this.onCopyRole();
        }

    }

    onEditRole = () => {
        this.props.onEditRole({
            'selectRoleData': this.state.selectRoleData,
            'initialRoleData': this.state.initialRoleDataObj
        });
    }

    onCopyRole = () => {
        this.props.onCopyRole({
            'selectRoleData': this.state.selectRoleData,
            'initialRoleData': this.state.initialRoleDataObj
        });
    }

    onDeleteRole = () => {
        roleService.deleteRole(this.state.selectRoleData.Uri).then((response) => {
            if (typeof response != 'undefined' && (response.status == 200 || response.status == 204)) {
                GlobalService.notification('success', this.translate('RoleManager_DeleteRole_Success'));
                this.initialFindRole();
            } else {
                GlobalService.apiFailer(response);
            }
        }).finally(() => { });
    }

    render() {
        return (
            <div className="role-grid">
                <div className="location-topbtn-holder">
                <WmsButton
                    label={this.translate('Label_Search')}
                    wmsSelector={this.state.Selector + 'ButtonFilterSearch'}
                    onClickFunc={this.gridSearch}
                    icon='fa-search' />
                <WmsButton
                    label={this.translate('Label_Clear_Filters')}
                    wmsSelector={this.state.Selector + 'ButtonFilterClear'}
                    onClickFunc={this.clearFilters}
                    icon='fa-minus'
                    buttonType="yellow" />
                    {this.state.searchOn == true && (
                        <div className="search-applied" >
                            <label>{this.translate('Label_Search_Applied_Filter')}</label>
                        </div>
                    )}
                </div>
                <div className={this.state.searchOn == true ? "role-form-boxes width-title " : "role-form-boxes"}>
                    <WmsContainer >
                        <div className="wms-input-group-wrapper">
                            <WmsDropdown
                                id="IndexOptionsRole"
                                name="IndexOptionsRole"
                                label={this.translate('Label_Name')}
                                wmsSelector={this.state.Selector + 'Dropdown'}
                                value={this.state.filter.IndexOptionsRole}
                                onChangeFunc={this.filterHandleChange}
                                options={this.state.optionConatins}
                                valueField='value'
                                textField='description'
                                parentClassName="select_box"
                                blankFirstOption={true}
                            />
                            <WmsInput
                                inputName="role"
                                wmsSelector={this.state.Selector + 'FilterInputrole'}
                                value={this.state.filter.role}
                                onChangeFunc={this.filterHandleChange}
                                label={this.translate('Label_Name')}
                                parentClassName="input_box" />
                        </div>
                    </WmsContainer>
                </div>
                    <WmsGrid
                        wmsSelector={'GridFindRoles'}
                        gridKeySelection={'Name'}
                        staticGridData={this.state.initialRoleDataObj.Roles}
                        gridName={'GridFindRoles'}
                        virtual={false}
                        id={'GridFindRoles'}
                        shawCheckbox={false}
                        onLoadGrid={this.onLoadRoleGrid}
                        columns={this.state.columns}
                        schema={this.state.schema}
                        transport={this.state.transport}
                        onSelection={this.selectRow}
                        clickEventOfContextMenu={this.clickEventOfContextMenu}
                        windowId={this.props.windowId}
                        showGridSummary={true}
                        filterObject={this.state.filterObject}
                        inlineFilter={true}
                        searchUrl={''}
                        menu={
                            [{
                                "name": "Create",
                                "value": [],
                                "disabled": false,
                                "icon": "fa-plus",
                                "color": "green",
                                "clickFunc": (e) => this.props.onCreateRole(this.state.initialRoleDataObj),
                                'isContextMenu': false,
                                'isButtonMenu': true,
                                "wmsSelector": this.state.Selector + "CreateButton",
                                'wmsid': 'create'
                            },
                            {
                                "name": "Edit",
                                "value": [],
                                "disabled": !this.state.enableEditButton,
                                "showToolTip": this.state.roleTemplateDisableNote != '',
                                "tooltipText": this.state.roleTemplateDisableNote,
                                "icon": "fa-pencil",
                                "color": "outlined",
                                'isContextMenu': true,
                                'isButtonMenu': true,
                                "wmsSelector": this.state.Selector + "EditButton",
                                "clickFunc": this.onEditRole,
                                'wmsid': 'edit'
                            }, {
                                "name": "Manage",
                                "wmsSelector": this.state.Selector + "Manage",
                                "value": [{
                                    "name": "Delete",
                                    "value": [],
                                    "disabled": !this.state.enableDeleteButton,
                                    "icon": "fa-trash",
                                    'isContextMenu': true,
                                    'isButtonMenu': true,
                                    "wmsSelector": this.state.Selector + "DeleteButton",
                                    "onClickFunc": this.onDeleteRole,
                                    'wmsid': 'delete'
                                }, {
                                    "name": "Copy Role",
                                    "value": [],
                                    "disabled": !this.state.enableRoleButton,
                                    "icon": "fa-trash",
                                    'isContextMenu': true,
                                    'isButtonMenu': true,
                                    "wmsSelector": this.state.Selector + "CopyRole",
                                    "onClickFunc": this.onCopyRole,
                                    'wmsid': 'copyRole'
                                }],
                                "disabled": false,
                                "disabledText": "You may not add blahssss0",
                                "icon": "fa-wrench",
                                "color": "outlined",
                                'isContextMenu': true,
                                'isButtonMenu': true
                            }, {
                                "name": "Copy",
                                "value": [{
                                    "name": this.translate('Label_Copy_Field'),
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": this.translate('Label_Copy_Field'),
                                    "icon": "fa-bandcamp",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyField',
                                    "wmsSelector": "copyToClicpboard",
                                }, {
                                    "name": this.translate('Label_Copy_Row'),
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": this.translate('Label_Copy_Row'),
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyRow'
                                }],
                                "disabled": false,
                                "disabledText": "You may not add blahssss0",
                                "icon": "fa-wrench",
                                "color": "green",
                                'isContextMenu': true,
                                'isButtonMenu': false
                            }]
                        }
                    />

            </div>
        )
    }
}