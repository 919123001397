import React, { Component } from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";
import WmsCheckBox from '../../../components/WmsCheckBox/WmsCheckBox.jsx';

export class PitneyBowesExpeditedInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.translate = window.translate;
    }
    render() {
        return (
            <div>
                <WmsCollapse
                    id={"carrierAccountInformation"}
                    headerLabel={this.translate("Label_Account_Information")}
                    showCollapseText={false}
                    showCollapseIcon={false}
                >
                    <div className="account-info-section pitney-account-info-section">
                        <WmsInput
                            id="nickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Nick_Name")}
                            value={this.props.currentCarrierObj.Nickname}
                            required={true}
                        />
                        <WmsInput
                            id="shipperRatePlan"
                            inputName="ShipperRatePlan"
                            wmsSelector={this.props.selector + "shipperRatePlan"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Shipper_Rate_Plan")}
                            value={this.props.currentCarrierObj.ShipperRatePlan}
                            required={false}
                        />
                        <WmsInput
                            id="integratorCarrierId"
                            inputName="IntegratorCarrierId"
                            wmsSelector={this.props.selector + "integratorCarrierId"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Integrator_Carrier_Id")}
                            value={this.props.currentCarrierObj.IntegratorCarrierId}
                            required={false}
                        />
                        <div>
                            <WmsCheckBox
                                id={'minimalAddressValidation'}
                                name={"MinimalAddressValidation"}
                                wmsSelector={this.props.selector + 'MinimalAddressValidation'}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate('Label_Use_Minimal_Address_Validation')}
                                checked={this.props.currentCarrierObj.MinimalAddressValidation == true}
                                isRounded={true}
                            />
                        </div>
                    </div>
                </WmsCollapse>
            </div>
        );
    }
}
