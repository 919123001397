import React from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";
import WmsDropdown from "../../../components/WmsDropdown/WmsDropdown.jsx";
import WmsCheckBox from "../../../components/WmsCheckBox/WmsCheckBox.jsx";

interface PitneyBowesCrossBorderInfoComponentProps {
    selector: string;
    handleCarrierObjChange: () => void;
    currentCarrierObj: {
        cbdsDeliverySolution: string;
        clientFacilityId: string;
        cbdsHub: string;
        nickname: string;
        shipmentType: string;
        MinimalAddressValidation: boolean;
        clientId: string;
    };
}

export function PitneyBowesCrossBorderInfoComponent(props: PitneyBowesCrossBorderInfoComponentProps): JSX.Element {
    const translate = (window as any).translate as any;

    const cbdsHubOptions = [
        {
            name: "California",
            code: "US_GEODIS_USZ",
        },
        {
            name: "Chicago",
            code: "US_GLOBAL_ILA",
        },
        {
            name: "Kentucky",
            code: "US_ELOVATIONS_KY",
        },
        {
            name: "New Jersey",
            code: "US_GLOBAL_NJA",
        },
        {
            name: "Washington",
            code: "US_GLOBAL_WAA",
        },
        {
            name: "BCA",
            code: "CA_GLOBAL_BCA",
        },
        {
            name: "ONA",
            code: "CA_GLOBAL_ONA",
        },
        {
            name: "GBA",
            code: "GB_GEODIS_GBA",
        },
    ];

    const pbStandardFacilitiesOptions = [
        {
            name: "ATL",
            code: "2035",
        },
        {
            name: "BOS",
            code: "2398",
        },
        {
            name: "BWI",
            code: "2109",
        },
        {
            name: "CMH",
            code: "2418",
        },
        {
            name: "CVG",
            code: "0921",
        },
        {
            name: "DFW",
            code: "2433",
        },
        {
            name: "EWR",
            code: "1779",
        },
        {
            name: "IND",
            code: "1585",
        },
        {
            name: "MCO",
            code: "2121",
        },
        {
            name: "ONT",
            code: "1787",
        },
        {
            name: "ORD",
            code: "2692",
        },
        {
            name: "SEA",
            code: "1571",
        },
        {
            name: "SLC",
            code: "1712",
        },
        {
            name: "SCK",
            code: "2124",
        },
    ];

    const cbdsDeliverySolutionOptions = [
        {
            name: "CBDS Returns Delivery Solution",
            code: "FIRSTMILE",
            supportedShipmentTypes: ["RETURN"],
        },
        {
            name: "Drop-Off to Local Depot",
            code: "FIRSTMILE",
            supportedShipmentTypes: ["DOMESTIC"],
        },
        {
            name: "Drop-Off to CBDS with Final-Mile Label",
            code: "DROPOFF_FINALMILELABEL",
            supportedShipmentTypes: ["DOMESTIC", "OUTBOUND"],
        },
        {
            name: "Bulk Pickup with Final-Mile Label",
            code: "PICKUP_FINALMILELABEL",
            supportedShipmentTypes: ["DOMESTIC"],
        },
        {
            name: "Piece-Level Shipment (U.S. outbound)",
            code: "FIRSTMILE",
            supportedShipmentTypes: ["OUTBOUND"],
        },
        {
            name: "Bulk Pickup (U.S. outbound)",
            code: "PICKUP_STANDARDLABEL",
            supportedShipmentTypes: ["OUTBOUND"],
        },
        {
            name: "Drop-Off to PB Standard (U.S. outbound)",
            code: "PICKUP_STANDARDLABEL",
            supportedShipmentTypes: ["OUTBOUND"],
        },
        {
            name: "Drop-Off to CBDS with Final-Mile Label (CA Outbound) - Drop-off",
            code: "DROPOFF_FINALMILELABEL",
            supportedShipmentTypes: ["OUTBOUND"],
        },
        {
            name: "Drop-Off to CBDS with Final-Mile Label (CA Outbound) - Pick up",
            code: "PICKUP_FINALMILELABEL",
            supportedShipmentTypes: ["OUTBOUND"],
        },
    ];

    const cbdsShipmentTypes = [
        {
            name: "Domestic",
            code: "DOMESTIC",
        },
        {
            name: "Return",
            code: "RETURN",
        },
        {
            name: "Outbound",
            code: "OUTBOUND",
        },
    ];

    return (
        <WmsCollapse
            id={"pitneyBowesCrossBorderAccountInfoScreen"}
            headerLabel={translate("Label_Account_Information")}
            showCollapseText={false}
            showCollapseIcon={false}
        >
            <div className="account-info-section">
                <div>
                    <div
                        className="note-section"
                        dangerouslySetInnerHTML={{ __html: translate("CBDS_Note_Section") }}
                    ></div>
                    <WmsDropdown
                        id="CBDSShipmentType"
                        name="shipmentType"
                        label={translate("Label_CBDS_Shipment_Type")}
                        wmsSelector={props.selector + "shipmentType"}
                        value={props.currentCarrierObj.shipmentType}
                        onChangeFunc={props.handleCarrierObjChange}
                        options={cbdsShipmentTypes}
                        required={true}
                        textField="name"
                        valueField="code"
                        blankFirstOption={true}
                        blankFirstOptionText="Select"
                    />
                    <WmsInput
                        id="nickname"
                        inputName="nickname"
                        wmsSelector={props.selector + "nickname"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_Account_Nick_Name")}
                        value={props.currentCarrierObj.nickname}
                        required={true}
                        extraWrapperClass="w-50"
                    />

                    {props.currentCarrierObj.shipmentType && (
                        <WmsDropdown
                            id="cbdsDeliverySolution"
                            name="cbdsDeliverySolution"
                            label={translate("Label_CBDS_Delivery_Solution")}
                            wmsSelector={props.selector + "cbdsDeliverySolution"}
                            value={props.currentCarrierObj.cbdsDeliverySolution}
                            onChangeFunc={props.handleCarrierObjChange}
                            options={cbdsDeliverySolutionOptions.filter((deliveryOption) =>
                                deliveryOption.supportedShipmentTypes.includes(props.currentCarrierObj.shipmentType)
                            )}
                            required={true}
                            textField="name"
                            valueField="code"
                            blankFirstOption={true}
                            blankFirstOptionText="Unspecified"
                        />
                    )}

                    {props.currentCarrierObj.shipmentType === "OUTBOUND" && (
                        <>
                            <WmsInput
                                id="clientFacilityId"
                                inputName="clientFacilityId"
                                wmsSelector={props.selector + "clientFacilityId"}
                                onChangeFunc={props.handleCarrierObjChange}
                                label={translate("Lable_Client_Facility_Id")}
                                value={props.currentCarrierObj.clientFacilityId}
                                required={props.currentCarrierObj.cbdsDeliverySolution === "PICKUP_STANDARDLABEL"}
                                extraWrapperClass="w-50"
                            />

                            <WmsDropdown
                                id="cbdsHub"
                                name="cbdsHub"
                                label={
                                    props.currentCarrierObj.cbdsDeliverySolution === "PICKUP_STANDARDLABEL"
                                        ? "Carrier Facility Id"
                                        : translate("Label_CBDS_Hub")
                                }
                                wmsSelector={props.selector + "cbdsHub"}
                                value={props.currentCarrierObj.cbdsHub}
                                onChangeFunc={props.handleCarrierObjChange}
                                options={
                                    props.currentCarrierObj.cbdsDeliverySolution === "PICKUP_STANDARDLABEL"
                                        ? pbStandardFacilitiesOptions
                                        : cbdsHubOptions
                                }
                                required={true}
                                textField="name"
                                valueField="code"
                                blankFirstOption={true}
                                blankFirstOptionText="Unspecified"
                            />
                        </>
                    )}

                    {["OUTBOUND", "RETURN"].includes(props.currentCarrierObj.shipmentType) && (
                        <WmsInput
                            id="clientId"
                            inputName="clientId"
                            wmsSelector={props.selector + "clientId"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Client_Id")}
                            value={props.currentCarrierObj.clientId}
                            required={props.currentCarrierObj.cbdsDeliverySolution === "PICKUP_STANDARDLABEL"}
                            extraWrapperClass="w-50"
                        />
                    )}
                    <div>
                        <WmsCheckBox
                            id={"MinimalAddressValidation"}
                            name={"MinimalAddressValidation"}
                            wmsSelector={props.selector + "MinimalAddressValidation"}
                            onChangeFunc={props.handleCarrierObjChange}
                            label={translate("Label_Minimal_Address_Validation")}
                            checked={props.currentCarrierObj.MinimalAddressValidation === true}
                            isRounded={true}
                        />
                    </div>
                </div>
            </div>
        </WmsCollapse>
    );
}

export default PitneyBowesCrossBorderInfoComponent;
