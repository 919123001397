import React, { useState, useEffect } from "react";

import WmsModel from "../react/components/WmsModel/WmsModel";
import WmsCollapse from "../react/components/WmsCollapse/WmsCollapse";
import WmsInput from "../react/components/WmsInput/WmsInput";
import WmsButton from "../react/components/WmsButton/WmsButton";
import WmsCheckBox from "../react/components/WmsCheckBox/WmsCheckBox";
import WmsDropdown from "../react/components/WmsDropdown/WmsDropdown";
import WmsTextArea from "../react/components/WmsTextArea/WmsTextArea";
import * as MobileLabelTemplates from "./mobile-label-template.services";
import * as GlobalService from "../react/global_services";
import "./mobileLabelTemplates.scss";

import _ from "lodash";
interface sharedFilterObjectType {
    LabelName: string;
    TemplateType: string;
    LabelCommand: string;
    AssignedCustomers: any;
}
interface Props {
    sharedIsOpenManageMULabelTemplate: boolean;
    wmsSelector: string;
    closeManageMuLabelTemplateModal: any;
    refreshGrid: any;
    mobileTemplatesType: string;
    sharedSelectedRow: sharedFilterObjectType;
    default: boolean;
}

const defaultMuLabelTemplatesObj = {
    LabelName: "",
    TemplateType: "Receipt",
    LabelCommand: "",
    AssignedCustomers: [-1],
};

declare var window: any;

const ManageMULabelTemplateModal: React.FC<Props> = (props: Props): any => {
    const CustomLabels = GlobalService.getCustomLabels();
    const CustomerOptions = GlobalService.getCustomerList(false);
    const templateTypes = [
        {
            text: "Receipt",
            value: "Receipt",
        },
        {
            text: "Move",
            value: "Move",
        },
        {
            text: "Loadout",
            value: "Loadout",
        },
    ];
    const defaultCustomerOptions = GlobalService.getCustomerList(false);
    CustomerOptions.unshift({
        id: -1,
        uri: "",
        name: "All Customer",
    });

    const [muLabelTemplatesObj, setMuLabelTemplatesObj] = useState(defaultMuLabelTemplatesObj);
    const [saveLoading, setSaveLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState(window.translate("Label_Create_Movable_Unit_Label_Template"));
    const [customerId, setCustomerId] = useState(-1);

    useEffect(() => {
        console.log("props.mobileTemplatesType", props.mobileTemplatesType);
        if (props.mobileTemplatesType === "CopyLabel") {
            let tmpObj = Object.assign({}, props.sharedSelectedRow);
            tmpObj.LabelName = "";
            if (tmpObj.AssignedCustomers != null) {
                setCustomerId(tmpObj.AssignedCustomers[0]);
            }
            setMuLabelTemplatesObj(tmpObj);
            setModalTitle(window.translate("Label_Copy_Movable_Unit_Label_Template"));
        } else if (props.default) {
            let tmpObj = Object.assign({}, props.sharedSelectedRow);
            if (
                tmpObj.AssignedCustomers &&
                tmpObj.AssignedCustomers.length === 1 &&
                tmpObj.AssignedCustomers.indexOf(-1) !== 1
            ) {
                tmpObj.AssignedCustomers = _.map(defaultCustomerOptions, "id");
                tmpObj.AssignedCustomers.push(-1);
            }

            setMuLabelTemplatesObj(tmpObj);
            setModalTitle(window.translate("Label_Default_Movable_Unit_Label_Template"));
        } else if (props.mobileTemplatesType === "EditLabel") {
            let tmpObj = Object.assign({}, props.sharedSelectedRow);
            if (tmpObj.AssignedCustomers != null && tmpObj.AssignedCustomers.length === 1) {
                setCustomerId(tmpObj.AssignedCustomers[0]);
            }
            setMuLabelTemplatesObj(tmpObj);
            setModalTitle(window.translate("Label_Edit_Movable_Unit_Label_Template"));
        } else {
            setMuLabelTemplatesObj(defaultMuLabelTemplatesObj);
            setModalTitle(window.translate("Label_Create_Movable_Unit_Label_Template"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handelChangeEvent = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "AssignedCustomers") {
            value = [value];
            setCustomerId(value);
        }
        setMuLabelTemplatesObj((previousState) => ({
            ...previousState,
            [name]: value,
        }));
    };
    // eslint-disable-next-line sonarjs/no-identical-functions
    const onChangeDefaultCustomer = (customerId: any) => {
        let tmpCustomerIds = muLabelTemplatesObj.AssignedCustomers;
        if (tmpCustomerIds.indexOf(-1) === -1 && customerId === -1) {
            tmpCustomerIds = _.map(defaultCustomerOptions, "id");
            tmpCustomerIds.push(-1);
        } else if (tmpCustomerIds.indexOf(-1) !== -1 && customerId === -1) {
            tmpCustomerIds = [];
        } else if (tmpCustomerIds.indexOf(customerId) === -1) {
            if (tmpCustomerIds.indexOf(-1) !== -1) {
                tmpCustomerIds.splice(tmpCustomerIds.indexOf(-1), 1);
            }
            tmpCustomerIds.push(customerId);
        } else if (tmpCustomerIds.indexOf(customerId) !== -1) {
            if (tmpCustomerIds.indexOf(-1) !== -1) {
                tmpCustomerIds.splice(tmpCustomerIds.indexOf(-1), 1);
            }
            tmpCustomerIds.splice(tmpCustomerIds.indexOf(customerId), 1);
        }
        setMuLabelTemplatesObj((previousState) => ({
            ...previousState,
            AssignedCustomers: tmpCustomerIds,
        }));
    };
    const saveMuLabelTemplate = () => {
        setSaveLoading(true);
        if (props.mobileTemplatesType !== "EditLabel" && !props.default) {
            MobileLabelTemplates.saveMobileLabelTemplatesData(muLabelTemplatesObj)
                .then((response: any) => {
                    GlobalService.notification("success", window.translate("Label_Created_Success"));
                    props.closeManageMuLabelTemplateModal();
                    props.refreshGrid();
                })
                .finally(() => {
                    setSaveLoading(false);
                });
        } else {
            if (props.default && muLabelTemplatesObj.AssignedCustomers.length >= defaultCustomerOptions.length) {
                muLabelTemplatesObj.AssignedCustomers = [-1];
            }
            MobileLabelTemplates.updateMobileLabelTemplatesData(muLabelTemplatesObj)
                .then((response: any) => {
                    GlobalService.notification("success", window.translate("Label_Updated_Success"));
                    props.closeManageMuLabelTemplateModal();
                    props.refreshGrid();
                })
                .finally(() => {
                    setSaveLoading(false);
                });
        }
    };

    return (
        <WmsModel
            id="ManageMuLabelTemplate"
            title={modalTitle}
            width={800}
            height={800}
            onCloseFunc={props.closeManageMuLabelTemplateModal}
            isOpen={props.sharedIsOpenManageMULabelTemplate}
            customClass="wms-model-new-ui-wrapper"
        >
            <div className="model-content-wrapper">
                {props.default && (
                    <div>
                        <WmsCollapse
                            id={"LabelInformation"}
                            headerLabel={window.translate("Label_Information")}
                            showCollapseText={false}
                            showCollapseIcon={false}
                        >
                            <div className="information-wrapper">
                                <span>{window.translate("Label_Template_Name")}</span>
                                <label>Default Label Template</label>
                            </div>
                        </WmsCollapse>
                        <WmsCollapse
                            id={"LabelAssignedTo"}
                            headerLabel={window.translate("Label_Assigned_To")}
                            showCollapseText={false}
                            showCollapseIcon={false}
                        >
                            <div className="assign-customer-wrapper">
                                <WmsCheckBox
                                    id="AssignToAllCustomers"
                                    name="AssignToAllCustomers"
                                    onChangeFunc={() => onChangeDefaultCustomer(-1)}
                                    label={window.translate("Label_Assign_To_All_Customers")}
                                    checked={muLabelTemplatesObj.AssignedCustomers?.indexOf(-1) !== -1}
                                    wmsSelector={"AssignToAllCustomers"}
                                />
                                <hr />
                                {defaultCustomerOptions.map((customer, index) => (
                                    <div key={index} className={"checkbox-container"}>
                                        <WmsCheckBox
                                            key={index}
                                            id={customer.id + "_" + customer.name}
                                            name={customer.name}
                                            wmsSelector={props.wmsSelector + "_" + customer.name}
                                            onChangeFunc={() => onChangeDefaultCustomer(customer.id)}
                                            label={customer.name}
                                            checked={
                                                muLabelTemplatesObj.AssignedCustomers?.indexOf(customer.id) !== -1 ||
                                                muLabelTemplatesObj.AssignedCustomers?.indexOf(-1) !== -1
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                        </WmsCollapse>
                    </div>
                )}
                {!props.default && (
                    <WmsCollapse
                        id={"labelSettings"}
                        headerLabel={window.translate("Label_Label_Settings")}
                        showCollapseText={false}
                    >
                        <div className="customer-template-wrapper">
                            <WmsInput
                                id="LabelName"
                                inputName="LabelName"
                                wmsSelector={props.wmsSelector + "LabelName"}
                                onChangeFunc={handelChangeEvent}
                                label={window.translate("Label_Label_Template_Name")}
                                required={true}
                                value={muLabelTemplatesObj.LabelName}
                                extraWrapperClass="template-name"
                            />
                            <WmsDropdown
                                id="AssignedCustomers"
                                name="AssignedCustomers"
                                label={CustomLabels.Label_Customer}
                                wmsSelector={props.wmsSelector + "AssignedCustomers"}
                                onChangeFunc={handelChangeEvent}
                                value={customerId}
                                options={CustomerOptions}
                                textField={"name"}
                                valueField={"id"}
                                extraWrapperClass={"customer-options"}
                            />
                            <WmsDropdown
                                id="TemplateType"
                                name="TemplateType"
                                label={window.translate("Label_Label_Template_Type")}
                                wmsSelector={props.wmsSelector + "TemplateType"}
                                onChangeFunc={handelChangeEvent}
                                value={muLabelTemplatesObj.TemplateType}
                                options={templateTypes}
                                textField={"text"}
                                valueField={"value"}
                                extraWrapperClass={"label-type-options"}
                            />
                        </div>
                        <div className="command-wrapper">
                            <WmsTextArea
                                id="LabelCommand"
                                name="LabelCommand"
                                wmsSelector={props.wmsSelector + "LabelCommand"}
                                label={window.translate("Label_Label_Command")}
                                onChangeFunc={handelChangeEvent}
                                value={muLabelTemplatesObj.LabelCommand}
                                required={true}
                                maxLength={4096}
                                rows={5}
                                cols={5}
                            />
                        </div>
                    </WmsCollapse>
                )}
            </div>
            <div className="footer-btn-holder">
                <WmsButton
                    label={window.translate("Label_Cancel")}
                    wmsSelector={window.wmsSelector + "DiscardExit"}
                    onClickFunc={props.closeManageMuLabelTemplateModal}
                    buttonType="orange"
                    icon={"fa-ban"}
                />
                <WmsButton
                    label={window.translate("Label_Save")}
                    wmsSelector={props.wmsSelector + "Save"}
                    onClickFunc={saveMuLabelTemplate}
                    icon={"fa-floppy-o"}
                    disabled={!muLabelTemplatesObj.LabelName || !muLabelTemplatesObj.LabelCommand}
                    loading={saveLoading}
                />
            </div>
        </WmsModel>
    );
};
ManageMULabelTemplateModal.propTypes = {};

ManageMULabelTemplateModal.defaultProps = {};

export default React.memo(ManageMULabelTemplateModal);
