import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';

//component
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsCheckBox from './../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';

//services
import * as GlobalService from './../global_services';
export class CustomersFormSystemOption extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            Selector: 'Customer',
            rights: GlobalService.GetRights(),
            option: {
                PrepopulateCarrierInfoEnum: StringWms.convertSringToOption(this.translate('Options_CarrierInfo')),
                TrackConfirmInvoiceCreateDefault: StringWms.convertSringToOption(this.translate('Options_CreateInvoiceDefaults')),
                QuickBooksPaymentTerms: StringWms.convertSringToOption(this.translate('Options_QuickBooksPaymentTerms')),
            }
        };
    }

    handleChange = (param_parent_property, event) => {

        let name = event.target.name;
        let parentObj = Object.assign({}, this.props.sharedCustomer.Options[param_parent_property]);

        if (event.target.type == 'checkbox') {
            parentObj[name] = !this.props.sharedCustomer.Options[param_parent_property][name];
        } else {
            parentObj[name] = event.target.value;
        }

        let customerObj = Object.assign(
            {},
            this.props.sharedCustomer,
            {
                Options: Object.assign(
                    {},
                    this.props.sharedCustomer.Options,
                    {
                        [param_parent_property]: parentObj
                    }
                )
            }
        );
        this.props.onCustomerDataset(customerObj);
    }

    render() {
        const renderCustomerOptions = this.props.sharedCustomer.Options;
        return (
            <div className="collapseCustomerTabContent_wrapper" id={'collapseSystemOption'} >
                <WmsCollapse
                    id={'collapseSystemOptionContent'}
                    headerLabel={this.translate('Label_Customer_Proto_System')}
                    showCollapseText={false}
                    showCollapseIcon={true}  >

                    <WmsContainer title={this.translate('Label_Customer_Proto_General_Settings')} className="system-general-settings-wrapper">

                        <WmsCheckBox
                            id={'AllowDuplicateUPCs'}
                            name={'AllowDuplicateUPCs'}
                            wmsSelector={this.state.Selector + 'EnableAllowDuplicateUPCsToAllow'}
                            onChangeFunc={(e) => this.handleChange('OtherSystemBehaviors', e)}
                            label={this.translate('Label_Costomer_chk_Allow_Duplicated_UPCs')}
                            checked={renderCustomerOptions.OtherSystemBehaviors.AllowDuplicateUPCs == true}
                            value={renderCustomerOptions.OtherSystemBehaviors.AllowDuplicateUPCs}
                            showToolTip={true}
                            tooltipText={this.translate('Label_Costomer_chk_Allow_Duplicated_UPCs_Note')}
                            tooltipShowAfter={200}
                        />

                        <WmsCheckBox
                            id={'FlexFields'}
                        name={'FlexFields'}
                        wmsSelector={this.state.Selector + 'EnableFlexFieldsToAllow'}
                        onChangeFunc={(e) => this.handleChange('OtherSystemBehaviors', e)}
                        label={this.translate('Label_Costomer_chk_Enable_Flex_Fields_to_allow')}
                        checked={renderCustomerOptions.OtherSystemBehaviors.FlexFields == true}
                        value={renderCustomerOptions.OtherSystemBehaviors.FlexFields}
                        disabled={!this.state.rights.hasOwnProperty('OtherUserFeatures.SavedElementsEnabled')}
                        parentClassName={this.state.rights.hasOwnProperty('OtherUserFeatures.SavedElementsEnabled') ? '' : 'disabled-option'}
                        showToolTip={!this.state.rights.hasOwnProperty('OtherUserFeatures.SavedElementsEnabled')}
                        tooltipText={this.translate('Label_Setting_Disable_Tooltip')}
                        tooltipShowAfter={200}
                    />

                    <WmsCheckBox
                        id={'PurchaseOrders'}
                        name={'PurchaseOrders'}
                        wmsSelector={this.state.Selector + 'AllowUseOfPurchaseOrder'}
                        onChangeFunc={(e) => this.handleChange('Receiving', e)}
                        label={this.translate('Label_Costomer_chk_Allow_the_use_of_Purchase_Order')}
                        checked={renderCustomerOptions.Receiving.PurchaseOrders == true}
                        value={renderCustomerOptions.Receiving.PurchaseOrders}
                        disabled={!this.state.rights.hasOwnProperty('Receiving.AllowPurchaseOrders')}
                            parentClassName={this.state.rights.hasOwnProperty('Receiving.AllowPurchaseOrders') ? '' : 'disabled-option'}
                            showToolTip={!this.state.rights.hasOwnProperty('Receiving.AllowPurchaseOrders')}
                            tooltipText={this.translate('Label_Setting_Disable_Tooltip')}
                            tooltipShowAfter={200} />

                        <WmsCheckBox
                            id={'ExcludeLocationsWithZeroInventory'}
                            name={'ExcludeLocationsWithZeroInventory'}
                            wmsSelector={this.state.Selector + 'ExcludeLocations'}
                            onChangeFunc={(e) => this.handleChange('UserInterface', e)}
                            label={this.translate('Label_Costomer_chk_Make_the_Exclude_locations')}
                            checked={renderCustomerOptions.UserInterface.ExcludeLocationsWithZeroInventory == true}
                            value={renderCustomerOptions.UserInterface.ExcludeLocationsWithZeroInventory} />

                    </WmsContainer >
                    {this.state.rights.hasOwnProperty('Integrations.QbEnabled') && (
                    <WmsContainer title={this.translate('Label_Customer_Proto_Quickbooks_Invoice_Terms')}>
                        <WmsDropdown
                                id="QuickBooksPaymentTerms"
                                name="QuickBooksPaymentTerms"
                                label={this.translate('Label_Customer_Proto_Payment_Terms')}
                                wmsSelector={this.state.Selector + "QuickBooksPaymentTerms"}
                                onChangeFunc={(e) => this.handleChange('Shipping', e)}
                                value={renderCustomerOptions.Shipping.QuickBooksPaymentTerms}
                                options={this.state.option.QuickBooksPaymentTerms}
                                valueField='description'
                                textField='description'
                                parentClassName='form_group' />

                        </WmsContainer >
                    )}
                <WmsContainer title={this.translate('Label_Costomer_Carrier_Account')}>

                    <WmsInput
                        id='UpsAccountNumber'
                        inputName='UpsAccountNumber'
                        wmsSelector={this.state.Selector + 'UpsAccountNumber'}
                        onChangeFunc={(e) => this.handleChange('Shipping', e)}
                        label={this.translate('Label_Costomer_UPS_Account_Number')}
                        value={renderCustomerOptions.Shipping.UpsAccountNumber} />

                    <WmsInput
                        id='FedExAccountNumber'
                        inputName='FedExAccountNumber'
                        wmsSelector={this.state.Selector + 'FedExAccountNumber'}
                        onChangeFunc={(e) => this.handleChange('Shipping', e)}
                        label={this.translate('Label_Costomer_Fed_Ex_Account_Number')}
                        value={renderCustomerOptions.Shipping.FedExAccountNumber} />

                    <WmsInput
                        id='UpsAccountZip'
                        inputName='UpsAccountZip'
                        wmsSelector={this.state.Selector + 'UpsAccountZip'}
                        onChangeFunc={(e) => this.handleChange('Shipping', e)}
                        label={this.translate('Label_Costomer_Carrier_Postal_Code')}
                        value={renderCustomerOptions.Shipping.UpsAccountZip} />

                    <WmsDropdown
                        id="PrepopulateCarrierInfo"
                        name="PrepopulateCarrierInfo"
                        label={this.translate('Label_Costomer_Auto_populate_the_above_Carriers')}
                        wmsSelector={this.state.Selector + "PrepopulateCarrierInfo"}
                        onChangeFunc={(e) => this.handleChange('Shipping', e)}
                        value={renderCustomerOptions.Shipping.PrepopulateCarrierInfo}
                            options={this.state.option.PrepopulateCarrierInfoEnum}
                            valueField='value'
                            textField='description'
                            parentClassName='form_group' />

                    </WmsContainer >

                <WmsContainer title={this.translate('Label_Costomer_Per_Transaction_Options')}>

                    <WmsInput
                        id='AccountingCustomerName'
                        inputName='AccountingCustomerName'
                        wmsSelector={this.state.Selector + 'AccountingCustomerName'}
                        onChangeFunc={(e) => this.handleChange('Storage', e)}
                        label={this.translate('Label_Warehouse_AccountName')}
                        value={renderCustomerOptions.Storage.AccountingCustomerName} />

                    <WmsDropdown
                        id="TransactionConfirmInvoiceCreateDefault"
                        name="TransactionConfirmInvoiceCreateDefault"
                        label={this.translate('Label_Costomer_Create_Invoice_Defaults')}
                        wmsSelector={this.state.Selector + "TransactionConfirmInvoiceCreateDefault"}
                        onChangeFunc={(e) => this.handleChange('UserInterface', e)}
                        value={renderCustomerOptions.UserInterface.TransactionConfirmInvoiceCreateDefault}
                        options={this.state.option.TrackConfirmInvoiceCreateDefault}
                        valueField='value'
                        textField='description'
                        parentClassName='form_group' />

                    <WmsCheckBox
                        id={'SetInvoiceDateToXactionConfirmDate'}
                        name={'SetInvoiceDateToXactionConfirmDate'}
                        wmsSelector={this.state.Selector + 'InvoiceConfirmDate'}
                        onChangeFunc={(e) => this.handleChange('Storage', e)}
                        label={this.translate('Label_Costomer_chk_Set_the_default_invoice_confirm_date')}
                        checked={renderCustomerOptions.Storage.SetInvoiceDateToXactionConfirmDate == true}
                        value={renderCustomerOptions.Storage.SetInvoiceDateToXactionConfirmDate} />

                </WmsContainer >

                <WmsContainer title={this.translate('Label_Costomer_EDI_X12_940_945')}>

                    <WmsInput
                        id='CustInterchangeId'
                        inputName='CustInterchangeId'
                        wmsSelector={this.state.Selector + 'CustInterchangeId'}
                        onChangeFunc={(e) => this.handleChange('Edi', e)}
                        label={this.translate('Label_Costomer_Interchange_ID')}
                        value={renderCustomerOptions.Edi.CustInterchangeId} />

                    <WmsInput
                        id='CustInterchangeIdQual'
                        inputName='CustInterchangeIdQual'
                        wmsSelector={this.state.Selector + 'CustInterchangeIdQual'}
                        onChangeFunc={(e) => this.handleChange('Edi', e)}
                        label={this.translate('Label_Costomer_ID_Qualifier')}
                        value={renderCustomerOptions.Edi.CustInterchangeIdQual} />

                    <WmsInput
                        id='ThreeplInterchangeId'
                        inputName='ThreeplInterchangeId'
                        wmsSelector={this.state.Selector + 'ThreeplInterchangeId'}
                        onChangeFunc={(e) => this.handleChange('Edi', e)}
                        label={this.translate('Label_Costomer_3PL_Interchange_ID')}
                        value={renderCustomerOptions.Edi.ThreeplInterchangeId} />

                    <WmsInput
                        id='ThreeplInterchangeIdQual'
                        inputName='ThreeplInterchangeIdQual'
                        wmsSelector={this.state.Selector + 'ThreeplInterchangeIdQual'}
                        onChangeFunc={(e) => this.handleChange('Edi', e)}
                        label={this.translate('Label_Costomer_3PL_ID_Qualifier')}
                        value={renderCustomerOptions.Edi.ThreeplInterchangeIdQual} />

                </WmsContainer >

                <WmsContainer title={this.translate('Label_Costomer_Pre_wired_Connection_Settings')}>

                    <WmsInput
                        id='TradingPartnerId'
                        inputName='TradingPartnerId'
                        wmsSelector={this.state.Selector + 'TradingPartnerId'}
                        onChangeFunc={(e) => this.handleChange('Edi', e)}
                        label={this.translate('Label_Costomer_SPS_Trading_Portner_ID')}
                        value={renderCustomerOptions.Edi.TradingPartnerId} />

                </WmsContainer >

                <WmsContainer title={this.translate('Label_Costomer_Document_Settings')}>

                    <WmsInput
                        id='NextMasterBolId'
                        inputName='NextMasterBolId'
                        wmsSelector={this.state.Selector + 'NextMasterBolId'}
                        onChangeFunc={(e) => this.handleChange('Shipping', e)}
                        label={this.translate('Label_Costomer_Next_Increment_of_Master_ID')}
                        value={renderCustomerOptions.Shipping.NextMasterBolId} />

                    <WmsInput
                        id='Gs1CompanyPrefix'
                        inputName='Gs1CompanyPrefix'
                        wmsSelector={this.state.Selector + 'Gs1CompanyPrefix'}
                        onChangeFunc={(e) => this.handleChange('Packaging', e)}
                        label={this.translate('Label_GS1_Company_Prefix')}
                        value={renderCustomerOptions.Packaging.Gs1CompanyPrefix}
                        maxLength={11} />

                    <WmsCheckBox
                        id={'BolNumAsTransNum'}
                        name={'BolNumAsTransNum'}
                        wmsSelector={this.state.Selector + 'TransactionbasedBill'}
                        onChangeFunc={(e) => this.handleChange('Shipping', e)}
                        label={this.translate('Label_Costomer_chk_Transaction_basedBill')}
                        checked={renderCustomerOptions.Shipping.BolNumAsTransNum == true}
                        value={renderCustomerOptions.Shipping.BolNumAsTransNum} />

                    <WmsCheckBox
                        id={'UseVendorIdOnBol'}
                        name={'UseVendorIdOnBol'}
                        wmsSelector={this.state.Selector + 'VendorId'}
                        onChangeFunc={(e) => this.handleChange('Packaging', e)}
                        label={this.translate('Label_Costomer_chk_Display_the_Vendor_ID_instead')}
                        checked={renderCustomerOptions.Packaging.UseVendorIdOnBol == true}
                        value={renderCustomerOptions.Packaging.UseVendorIdOnBol} />

                    {/* image section hide in angular module only content set  */}

                    {/* <div className="clearfix">
                        <div className="title">
                            <label className="wms-input-label wms-top-level-row">{this.translate('Label_Costomer_Current_Packing_Slip_Header_Image')} </label>
                        </div>
                        <div className="row wms-top-level-row">
                            <div className="img">
                                <img src="" height="40px" width="40px" />
                            </div>
                            <div className="upload-btns">
                                <div className="radiobtn">
                                    <label className="wms-input-label" style={{ margin: '0 6px 0 4px' }}>
                                        <input className="option-input radio" type="radio" /> {this.translate('Label_Costomer_Use_URL')}
                                    </label>
                                </div>
                                <div className="radiobtn">
                                    <label className="wms-input-label">
                                        <input className="option-input radio" type="radio" value="file" /> {this.translate('Label_Costomer_Upload_File')}
                                    </label>
                                </div>
                                <div className="file-uploader">
                                    <div >
                                        <input type="file" id="file_header" />
                                        <WmsButton
                                            label={'Choose File'}
                                            wmsSelector={'choose_file'}
                                            onClickFunc={(e) => function () { }} />
                                        <label className="wms-filename-text" ng-show="selectHeaderFile">{' selectHeaderFile'}</label>
                                    </div>
                                    <WmsInput
                                        id='customerImageUrl'
                                        inputName='customerImageUrl'
                                        wmsSelector={this.state.Selector + 'customerImageUrl'}
                                        onChangeFunc={(e) => this.handleChange('Shipping',e)}
                                        label={this.translate('Label_Costomer_Image_url')}
                                        value={renderCustomerOptions.Shipping.FedExAccountNumber} />
                                </div>
                                <div className="apply-btn">
                                    <WmsButton
                                        label={this.translate('Label_Costomer_Apply')}
                                        wmsSelector={this.state.Selector + 'customerApply'}
                                        onClickFunc={(e) => function () { }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix" >
                        <div className="title">
                            <label className="wms-input-label wms-top-level-row">{this.translate('Label_Costomer_Current_Packing_Slip_Footer_Image')} </label>
                        </div>
                        <div className="row wms-top-level-row" style={{ padding: '0 7px' }}>
                            <div className="img">
                                <img src="" height="40px" width="40px" />
                            </div>
                            <div className="upload-btns">
                                <div className="radiobtn">
                                    <label className="wms-input-label">
                                        <input className="option-input radio" type="radio" value="text" /> {this.translate('Label_Costomer_Use_URL')}
                                    </label>
                                </div>
                                <div className="radiobtn">
                                    <label className="wms-input-label">
                                        <input className="option-input radio" type="radio" value="file" /> {this.translate('Label_Costomer_Upload_File')}
                                    </label>
                                </div>
                                <div className="row">
                                    <div className="file-uploader">
                                        <div >
                                            <input type="file" style={{ display: 'block' }} id="file_footer" />
                                            <WmsButton
                                                label={'Choose File'}
                                                wmsSelector={'choose_file'}
                                                onClickFunc={(e) => function () { }} />
                                            <label className="wms-filename-text" ng-show="selectHeaderFile">{'selectFooterFile'}</label>
                                        </div>
                                        <WmsInput
                                            id='footerImageUrl'
                                            inputName='footerImageUrl'
                                            wmsSelector={this.state.Selector + 'footerImageUrl'}
                                            onChangeFunc={(e) => this.handleChange('Shipping',e)}
                                            label={this.translate('Label_Costomer_Image_url')}
                                            value={renderCustomerOptions.Shipping.FedExAccountNumber} />
                                    </div>
                                    <div className="apply-btn">
                                        <WmsButton
                                            label={this.translate('Label_Costomer_Apply')}
                                            wmsSelector={this.state.Selector + 'customerApply'}
                                            onClickFunc={(e) => function () { }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ clear: 'both', 'marginLeft': '10px', position: 'relative', 'marginBottom': '20px' }}>
                        <div className="title">
                            <label className="wms-input-label wms-top-level-row">{this.translate('Label_Costomer_Branding_Image_ID')} </label>
                        </div>
                        <div className="img">
                            <img src="" height="40px" width="40px" />
                        </div>
                        <div className="upload-btns set-align">
                            <div className="file-uploader">
                                <div>
                                    <input type="file" style={{ display: 'block' }} id="file_Branding" />
                                    <WmsButton
                                        label={'Choose File'}
                                        wmsSelector={'choose_file'}
                                        onClickFunc={(e) => function () { }} />
                                    <label className="wms-filename-text" ng-show="selectBrandingFile">{'selectBrandingFile'}</label>
                                </div>
                            </div>
                            <div className="apply-btn">
                                <WmsButton
                                    label={this.translate('Label_Costomer_Apply')}
                                    wmsSelector={this.state.Selector + 'customerApply'}
                                    onClickFunc={(e) => function () { }} />
                            </div>
                        </div>
                    </div> */}

                </WmsContainer >

            </WmsCollapse >
            </div>
        );
    }
}