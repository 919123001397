import React from 'react';
var axios = require('axios');
import * as GlobalService from './../global_services';

export const initialFindRole = function (param_scope) {

    return axios.post('/WebUI/Roles/RoleManager/GetRoleManagementOptions',{
        "scope": param_scope
    }).then(function (response) {
        return response;
    });
}
export const getRoleData = function (param_roleuri, param_isTemplate, param_roleType) {

    if(param_roleType == "Customer") {
        param_roleType = "CustUsersOnly";
    } else if(param_roleType == "Warehouse") {
        param_roleType = "WarehouseAndCustUsers";
    }

    return axios.put('/WebUI/Roles/RoleManager/GetTreeData', {
        roleUri: param_roleuri,
        isTemplate: param_isTemplate,
        scope: param_roleType
    }).then(function (response) {
        return response;
    });
}
export const createRole = function (tree, roleName,scope) {

    return axios.post('/WebUI/Roles/RoleManager/Role', {
        name: roleName,
        treeNode: tree,
        scope:scope
    }).then(function (response) {
        return response;
    });

}

export const updateRole = function (tree, role) {

    return axios.put('/WebUI/Roles/RoleManager/Role', {
        roleUri: role.Uri,
        treeNode: tree
    }).then(function (response) {
        return response;
    });

}

export const deleteRole = function (uri) {

    return axios.delete('/WebUI/Roles/RoleManager/Role?roleUri=' + uri).then(function (response) {
        return response;
    })

}


