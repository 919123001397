import React from 'react';
import { AttachFileToAssemblyGrid } from './attach-file-to-assembly-grid.component.jsx';
import { AttachFileToAssemblySearch } from './attach-file-to-assembly-search.component.jsx';
import * as metaDataService from './attach-file-to-assembly-meta-data.service.js';
import style from './attach-file-to-assembly.scss';

export class AttachFileToAssembly extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window;
        this.manageAttachFileAssemblyGridId = "GridAttachFileToAssembly";

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            sharedSelectedFacilityObj: null,
            sharedAttachFileAssemblyFilterObj: {
                "CustomerId": 0,
                "FacilityId": 0,
                "StatusEnum": 0,
                "ReferenceNum": '',
                "IndexOptionsStatus": 0
            },
            sharedSearchOn: false,
            isLoadMetaServices: false
        }
    }

    async componentDidMount() {
        $('#attach-file-assembly-holder').closest('.k-window-content').css("padding", "0");

        kendo.ui.progress($('#' + this.state.windowId), true);
        await metaDataService.getUserClientMetaData();

        var filterData = metaDataService.getAttachFileAssemblyFilterMetaData();
        if (filterData != "") {
            this.setState({
                sharedAttachFileAssemblyFilterObj: filterData,
                sharedSearchOn: JSON.stringify(filterData) != JSON.stringify(this.state.sharedAttachFileAssemblyFilterObj)
            });
        }
        this.setState({
            isLoadMetaServices: true
        })
    }

    componentWillUnmount() {
        $('#attach-file-assembly-holder').closest('.k-window-content').css("padding", "26");
    }

    selectedFacility = (facilityObj) => {
        this.setState({
            sharedSelectedFacilityObj: facilityObj
        });
    }

    onAttachFileAssemblyFilterObjChange = (param_inventoryFilterObj, searchOn) => {
        this.setState({
            sharedAttachFileAssemblyFilterObj: Object.assign({}, param_inventoryFilterObj),
            sharedSearchOn: searchOn
        }, () => {
            metaDataService.setAttachFileAssemblyFilterMetaData(param_inventoryFilterObj);
            this.gridComponent.loadGridData();
        });
    }

    onRefreshGrid = () => {
        this.gridComponent.loadGridData();
    }

    resizeWindow = () => {
        $(window).trigger('resize');
    }

    onApplySavedFilter = (param_filterObj) => {
        this.setState({
            sharedAttachFileAssemblyFilterObj: param_filterObj,
            sharedSearchOn: true
        }, () => {
            if (this.gridComponent) {
                var grid = $('.' + this.state.windowId + ' #' + this.manageAttachFileAssemblyGridId).data("kendoGrid");
                var dataSource = grid.dataSource;
                this.gridComponent.setFilterAppliedText(dataSource.filter());
                this.gridComponent.onGridFilterApplied(param_filterObj);
            }
        });
    }

    render() {
        return (
            <div id="attach-file-assembly-holder" >
                {this.state.isLoadMetaServices && (
                    <AttachFileToAssemblySearch
                        onApplySavedFilter={this.onApplySavedFilter}
                        onAttachFileAssemblyFilterObjChange={this.onAttachFileAssemblyFilterObjChange}
                        sharedAttachFileAssemblyFilterObj={this.state.sharedAttachFileAssemblyFilterObj}
                        onRefreshGrid={this.onRefreshGrid}
                        windowId={this.state.windowId}
                        onSelectFacility={this.selectedFacility}
                        sharedSearchOn={this.state.sharedSearchOn}
                    />
                )}

                {this.state.sharedSelectedFacilityObj != null && this.state.isLoadMetaServices &&
                    <AttachFileToAssemblyGrid
                        ref={instance => { this.gridComponent = instance; }}
                        sharedSelectedFacilityObj={this.state.sharedSelectedFacilityObj}
                        windowId={this.state.windowId}
                        sharedAttachFileAssemblyFilterObj={this.state.sharedAttachFileAssemblyFilterObj}
                    />
                }

            </div >
        )

    }
}