import React, { Component } from 'react'
import WmsModel from '../WmsModel/WmsModel.jsx';
import WmsButton from '../WmsButton/WmsButton.jsx';
import WmsCollapse from '../WmsCollapse/WmsCollapse.jsx';
import WmsTextArea from '../WmsTextArea/WmsTextArea.jsx';
import WmsInput from '../WmsInput/WmsInput.jsx';

//services
import * as CancelModelService from './cancel-model.service.jsx';
import * as GlobalService from '../../global_services';

import "./cancel-model.scss";

export class CancelModel extends Component {
    constructor(props) {
        super(props)

        
        this.translate = window.translate;

        this.state = {
            cancelProcessing: false,
            cancelModelData: {
                Reason: '',
                Charge: 0,
            }
        }
    }

    updateCancelModelData = (event, key) => {
        var value = event.target.value;

        this.setState({
            cancelModelData: Object.assign(
                this.state.cancelModelData,
                {
                    [key]: value
                }
            ),
        });
    }

    closeCancelModel = () => {
        this.props.onCloseCancelModel();
    }

    exitCancelModal = () => {
        this.props.onCloseCancelModel();
    }

    onCancelReceipt = () => {
        this.setState({ cancelProcessing: true });
        if(this.props.modalType == 'Receipt'){

            var receiptData = this.state.cancelModelData;
            receiptData.ReceiverIds= this.props.sharedSelectedIds;
            
            CancelModelService.cancelReceipts(receiptData)
            .then((response) => {
                
                this.setState({ cancelProcessing: false });
                
                if(response && response.status == 204){
                    GlobalService.notification('success',this.translate('Label_Receipt_Cancelled'));
                    this.props.resetGridSelection();
                }
                this.exitCancelModal();
            }).finally(() =>{
                this.setState({ cancelProcessing: false });
            });
        } else { 
            var orderData = this.state.cancelModelData;
            orderData.ids= this.props.sharedSelectedIds;
            CancelModelService.cancelOrders(orderData).then((response) => {
                if (response) {
                    GlobalService.notification('success', this.translate('Label_Cancel_Success'));
                    this.props.resetGridSelection();
                }
                this.exitCancelModal();
            }).finally(() =>{
                this.setState({ cancelProcessing: false });
            });
        }
    }

    render() {
        return (
            <WmsModel
                id='windowCancelModel'
                title={this.props.modalType == 'Receipt' ? this.translate('Label_Cancel_Receipt') : this.translate('Label_Cancel_Order')}
                width={540}
                height={415}
                customClass='wms-model-new-ui-wrapper'
                onCloseFunc={this.closeCancelModel}
                isOpen={this.props.sharedIsOpenCancelModel}
            >
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'cancelReceipt'}
                        headerLabel={this.translate('Label_Cancel_Information')}
                        showCollapseText={false}
                        showCollapseIcon={false}
                        >
                        <WmsTextArea
                            id='CancelReason'
                            wmsSelector={"Cancel" + this.props.modalType + "ModelReason"}
                            label={this.translate('Label_Reason_for_Cancel')}
                            onChangeFunc={(e) => this.updateCancelModelData(e, 'Reason')}
                            value={this.state.cancelModelData.Reason}
                            rows={4}
                            cols={30}
                            required={true}
                        />

                        <WmsInput
                            inputName="CancelReceiptCharge"
                            name="CancelReceiptCharge"
                            wmsSelector={"Cancel"+ this.props.modalType +"ModelReceiptCharge"}
                            value={this.state.cancelModelData.Charge}
                            onChangeFunc={(e) => this.updateCancelModelData(e, 'Charge')}
                            label={this.props.modalType == 'Receipt' ? this.translate('Label_Cancel_Receipt_Charge') : this.translate('Label_Cancel_Order_Charge')}
                            regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                        />
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate("Label_Exit")}
                        wmsSelector={"Cancel"+ this.props.modalType +"ModelExitButton"}
                        onClickFunc={this.exitCancelModal}                        
                        buttonType='orange'
                        icon={'fa-times-circle'}
                    />
                    <WmsButton
                        label={this.props.modalType == 'Receipt' ? this.translate("Label_Cancel_Receipt") : this.translate('Label_Cancel_Order')}
                        wmsSelector={"Cancel"+ this.props.modalType +"ModelCancelButton"}
                        onClickFunc={this.onCancelReceipt}
                        icon="fa-check-circle"
                        buttonType="green"
                        disabled={(this.state.cancelProcessing || this.state.cancelModelData.Reason == '')}
                        loading={this.state.cancelProcessing}
                    />

                </div>


            </WmsModel>
        )
    }
}
