import React from 'react';
import WmsGrid from '../../components/WmsGrid/WmsGrid.jsx';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import { StringWms } from '../../../../assets/ts/StringWms.ts';

import * as GridConfig from './grid_configuration.jsx';

//services
import * as manageOrderProtoMetaDataService from './manage-order-proto-meta-data.service';

export class ManageOrdersCustomOrderInfo extends React.Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;

        this.state = {
            selector: 'OrderProtoTypeCustomInfo',
            enableDeleteButton: false,
            isRequiredField: false,
            columns: GridConfig.getCustomOrderInfoGridColumns(StringWms.convertSringToOption(this.translate('Orders_ManageOrders_CustomOrderHeaders')), this.translate('Label_RefNum_HintText')).columns,
            schema: GridConfig.getCustomOrderInfoGridSchema().schema,
            localKendoGrid: null,
            isExpanded: props.isPrototypeComponent ? ((!$.isEmptyObject(props.metaDataList) && props.metaDataList.hasOwnProperty('collapse4')) ? !props.metaDataList['collapse4'] : true) : true
        };
    }

    setCollapseMetaData = (value) => {
        if (this.props.isPrototypeComponent) {
            manageOrderProtoMetaDataService.setManageOrderProtoCollapseMetaData("collapse4", value);
        }
    }
    clickDeleteRow = () => {

        let savedElementObj = Object.assign([], this.props.sharedOptions.OrderSE);
        savedElementObj = savedElementObj.filter((element) => {
            if (element.Name != this.state.localKendoGrid.getKeys()[0]) { return element; }
        });

        let sharedOptionObj = Object.assign(
            {},
            this.props.sharedOptions,
            {
                'OrderSE': savedElementObj
            }
        );
        this.props.onSharedOptionDataSet(sharedOptionObj);
        this.setState({ enableDeleteButton: false });
    }

    onSelectRow = (param_kendoGrid) => {
        this.setState({
            enableDeleteButton: param_kendoGrid.getNumberOfSelections() == 1 ? true : false,
            isRequiredField: (param_kendoGrid.getNumberOfSelections() == 1 && param_kendoGrid.valueExists("Required", true)) ? true : false,
            localKendoGrid: param_kendoGrid
        });
    }

    clickEventOfContextMenu = (obj) => {
        if (obj.wmsid == 'delete-order-info') {
            this.clickDeleteRow();
        }
    }


    onEdit = (e) => {
        e.container.find("input[name=Value]").attr("maxlength", 255);
        e.container.find("input[name=Value]").attr("ng-blur", "ChangeEvent()");
        $('input.k-input.k-textbox').blur(() => {
            let data = $('.' + this.props.windowId + ' #GridCustomOrderInfo').data('kendoGrid').dataSource.view();
        });
    }

    onSave = (e) => {
        let savedElementObj = Object.assign([], this.props.sharedOptions.OrderSE);

        for (var x = 0; x < savedElementObj.length; x++) {
            if (savedElementObj[x].Name == e.model.Name) {
                savedElementObj[x].Value = e.values.Value;
            }
        }

        let sharedOptionObj = Object.assign(
            {},
            this.props.sharedOptions,
            {
                'OrderSE': savedElementObj
            }
        );
        this.props.onSharedOptionDataSet(sharedOptionObj);
        this.props.onCustomOrderInfoSave(savedElementObj);

    }

    onChangeGridconfig = (param_grid_config) => {
        manageOrderProtoMetaDataService.setManageOrderProtoCustomOrderInfoMetaData(param_grid_config);
    }

    onResetGrid = () => {
        manageOrderProtoMetaDataService.setManageOrderProtoCustomOrderInfoMetaData({ 'optionsData': {} });
    }

    render() {
        return (
            <div className="collapse-grid-section collapseOrderTabContent_wrapper" ref={this.props.divRefs}>
                <WmsCollapse id={'collapse4'} isExpanded={this.state.isExpanded} setCollapseMetaData={this.setCollapseMetaData} windowId={this.props.windowId} headerLabel={this.translate('Label_Custom_Order_Info')} showCollapseText={false}>

                    <WmsGrid
                        wmsSelector={this.state.selector + 'Grid'}
                        gridKeySelection={'Name'}
                        staticGridData={this.props.sharedOptions != null ? this.props.sharedOptions.OrderSE : []}
                        gridName={'GridCustomOrderInfo'}
                        isAlignIndexesByProperty={true}
                        virtual={false}
                        gridHeight={225}
                        id={'GridCustomOrderInfo'}
                        onSelection={this.onSelectRow}
                        columns={this.state.columns}
                        schema={this.state.schema}
                        showGridSummary={false}
                        windowId={this.props.windowId}
                        shawCheckbox={false}
                        showGridResult={false}
                        showSortColumns={false}
                        gridEditable={true}
                        onEdit={this.onEdit}
                        onGridSave={this.onSave}
                        clickEventOfContextMenu={this.clickEventOfContextMenu}
                        layoutData={manageOrderProtoMetaDataService.getManageOrderProtoCustomOrderInfoMetaData()}
                        onSort={this.onChangeGridconfig}
                        onColumnShow={this.onChangeGridconfig}
                        onColumnHide={this.onChangeGridconfig}
                        onColumnReorder={this.onChangeGridconfig}
                        onColumnResize={this.onChangeGridconfig}
                        onResetGrid={this.onResetGrid}
                        menu={[{
                            "name": this.translate('Label_New_Custom_Field'),
                            "value": [],
                            "disabled": false,
                            "icon": "fa-plus",
                            "color": "green",
                            "wmsSelector": this.state.selector + "AddCustomField",
                            "clickFunc": this.props.onOpenModalAddCustomField,
                            'isContextMenu': false,
                            'isButtonMenu': true,
                            'wmsid': 'create-order-info'
                        }, {
                            "name": this.translate('Label_Delete'),
                            "value": [],
                            "disabled": !this.state.enableDeleteButton || this.state.isRequiredField,
                            "icon": "fa-trash",
                            'color': this.state.isRequiredField ? 'yellow' : 'red',
                            "wmsSelector": this.state.selector + "DeleteCustomField",
                            "clickFunc": this.clickDeleteRow,
                            'isContextMenu': true,
                            'isButtonMenu': true,
                            "showToolTip": this.state.isRequiredField,
                            "tooltipText": this.translate('Label_Cannot_Delete_Required'),
                            "disabledText": this.translate('Label_Cannot_Edit_Required'),
                            'wmsid': 'delete-order-info'
                        }]}>
                    </WmsGrid>


                </WmsCollapse>
            </div>
        );
    }
}
