import React from 'react';
var axios = require('axios');
import * as GlobalService from './../global_services';


export const cyclecountOption = function (facilityUri) {
    return axios.get('/WebUI/Inventory/ManageAudits/AuditOptions?facilityUri=' +facilityUri).then(function (response) {
        return response;
    });
}

export const searchInfoSend = function (searchData) {
    return axios.put('/WebUI/Inventory/UpdateSession/AuditSearchInfo', { "searchInfo": searchData }).then(function (response) {
        return response;
    });
}

export const locationGridSearchInfoGet = function () {
    return axios.get('/WebUI/Inventory/EditAudits/AuditLocationSearchInfo').then(function (response) {
        return response;
    });
}
export const locationGridSearchInfoSend = function (searchData) {
    return axios.put('/WebUI/Inventory/UpdateSession/AuditLocationSearchInfo', searchData).then(function (response) {
        return response;
    });
}
export const locationGridSearchInfoClear = function () {
    return axios.delete('/WebUI/Inventory/UpdateSession/AuditLocationSearchInfo').then(function (response) {
        return response;
    });
}

export const createJob = function (jobData,flowType) {
    if(flowType == 'create'){
        return axios.post('/WebUI/inventory/editaudits/audit', jobData).then(function (response) {
            return response;
        });
    }else{
        return axios.put('/WebUI/inventory/editaudits/audit', jobData).then(function (response) {
            return response;
        });
    }

}

export const getEditJobLocationInitial = function () {
    return axios.get('../../../assets/json/cyclecount/getJobLocationInitial.json').then(function (response) {
        return response;
    });
}

export const recountAll = function (auditCountsUris) {
    return axios.post('/WebUI/Inventory/ManageAudits/RecountAudits', auditCountsUris).then(function (response) {
        return response;
    });
}

export const GetAuditReviewList = function (auditCountsUris) {
    return axios.put('/WebUI/Inventory/ManageAudits/AuditReviews', { "auditCountsUris": auditCountsUris }).then(function (response) {
        return response;
    });
}

export const GetAuditReviewCSV = function (auditCountsUris) {
    return axios.put('/WebUI/Inventory/ManageAudits/AuditResultToCSV', {"auditCountsUris": auditCountsUris }).then(function (response) {
        return response;
    });
}

export const GetAuditReconciles = function (auditCountsUris) {
    return axios.put('/WebUI/Inventory/ManageAudits/AuditReconciles', { "auditCountsUris": auditCountsUris }).then(function (response) {
        return response;
    });
}

export const closeJob = function (auditCountsUris) {
    return axios.put('/WebUI/Inventory/ManageAudits/Close', { "auditUris": auditCountsUris }).then(function (response) {
        return response;
    });
}

export const recountLocationsAndTrackBys = function(locCountUris, auditUris, allowTrackBys){
    return axios.post('/WebUI/Inventory/ManageAudits/RecountAuditLocationTrackBys', { "locCountUris": locCountUris, "auditUris": auditUris, allowTrackBys }).then(function (response) {
        return response;
    });
}

export const getReconcilePreview = function(auditCountsUris, itemToReconcile){
    return axios.put('/WebUI/Inventory/UpdateSession/AuditReconciler', { "auditCountsUris": auditCountsUris, "itemToReconcile": itemToReconcile}).then(function(response){
        return response;
    });
}

export const makeItSoReconcile = function(token, proceed){
    return axios.post('/WebUI/Inventory/UpdateSession/AuditReconciler', { "token": token, "proceed": proceed}).then(function(response){
        return response;
    });
}

export const GetOtherLocationsForSku = function(sku, qualifier, facilityUri){
    return axios.put('/WebUI/Inventory/ManageAudits/OtherLocationsForSku', {
            'sku': sku,
            'qualifier': qualifier,
            'facilityUri': facilityUri
    }).then(function (response) {
        return response;
    });
}

export const AddLocationsToJob = function(auditUri, locIds){
    return axios.put('/WebUI/Inventory/ManageAudits/LocationAdder', { "auditUri": auditUri, "locationIds": locIds}).then(function(response){
        return response;
    });
}