import React from 'react';
var axios = require('axios');
var UtcOffset = new Date().getTimezoneOffset() / 60 * -1;

import * as GlobalService from './../../global_services';

export const getReceivingOptions = function () {
    return axios.post('/WebUI/Receiving/FindReceivings/GetReceivingOptions', { name: 'GridFindReceivers' })
        .then(function (response) {
            var tempData = response.data;
            return tempData;
        });
}


export const clearSearchInfo = function () {
    return axios.post('/WebUI/Receiving/UpdateSession/SearchInfoClear')
        .then(function (response) {
            return response.data.Result;
        });
}


export const dynamicServiceForDynamicMenu = function (urlName, uri, ids, outputType, flowType) {
    var fileType = outputType ? outputType : 'application/pdf';
    var myReturn = {};
    myReturn.FileType = fileType;

    var _url = "";
    var data = {
        requestBody: {
            RunnerUriTemplate: uri,
            outputType: fileType
        }
    }

    if (urlName === "RunViewTransactionReport") {
        _url = '/WebUI/Receiving/' + flowType + '/RunViewTransactionReport';
        data.requestBody.UtcOffset = UtcOffset;
        data.requestBody.ShowCharges = true;
        data.requestBody.ShowPackages = false;
        data.requestBody.TransactionIds = ids;
    } else if (urlName === "RunMULabelReport") {
        _url = '/WebUI/Receiving/' + flowType + '/RunMULabelReport';
        data.requestBody.TransactionId = ids[0]; //.join(",");
    } else if (urlName === "RunReceiptTicketReport") {
        _url = '/WebUI/Receiving/' + flowType + '/RunReceiptTicketReport';
        data.requestBody.TransactionId = ids[0]; //.join(",");
    }

    return axios.post(_url, data)
        .then(function (response) {
            myReturn.Data = response.data;
            return myReturn;
        });
}

export const sendReceiptAdviceOptions = function (custUri, facilityId) {
    var _url = "customerUri=" + custUri + "&facilityId=" + facilityId;
    return axios.get('/WebUI/Receiving/FindReceivings/SendReceiptAdviceOptions?' + _url)
        .then(function (response) {
            return response.data;
        });
}

export const downloadReceiptAdvice = function (transformId, receiverIds) {
    var data = {
        transformId: transformId,
        receiverIds: receiverIds
    };
    return axios.post('/WebUI/Receiving/FindReceivings/DownloadReceiptAdvice', data)
        .then(function (response) {
            return response;
        });
}

export const sendAsn = function (param_data) {
    return axios.post('/WebUI/Receiving/FindReceivings/SendReceiptAdvice', param_data)
        .then(function (response) {
            return response;
        });
}

export const reopenReceiver = function (editUri) {
    var data = {
        "editUri": editUri
    };
    return axios.post('/WebUI/Receiving/FindReceivings/ReopenReceiver', data)
        .then(function (response) {
            return response;
        });
}

export const intialConfirmReceipt = function (receiptIds) {
    var data = {
        "ids":receiptIds
    }
    return axios.all([

        axios.put('/WebUI/Receiving/FindReceivings/MassConfirmReceivingsData', data),
        axios.get('/WebUI/Shipping/Orders/GetChargeCategories')

    // ]).then(axios.spread(function (getChargeCategoriesResponse) {
    ]).then(axios.spread(function (getMassShipOrderChargesResponse, getChargeCategoriesResponse) {

        return {
            massShipChargesGridData: getMassShipOrderChargesResponse,
            chargesCategoryResponse: getChargeCategoriesResponse
        };

    }));
}