
import React from 'react';
import { InventoryGrid } from './inventory-grid.component.jsx';
import { InventorySearch } from './inventory-search.component.jsx';

import style from './inventory.scss';

import * as GlobalService from './../global_services';
import * as metaDataService from './meta-data.service.js';

export class InventoryHolder extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window;
        this.manageInventoryGridId = "GridManageInventoryBeta";
        this.windowId = this.$rootScope.ChildBulletin.Data.Window;
        this.state = {
            Selector: "Inventory",
            customLabels: GlobalService.getCustomLabels(),
            sharedSelectedFacilityObj: null,
            sharedInventoryFilterObj: {
                "IndexOptionsDate": 0,
                "CustomerId": 0,
                "FacilityId": 0,
                "IndexOptionsSku": 0,
                "IndexOptionsQualifier": 0,
                "IndexOptionsSerialNumber": 0,
                "IndexOptionsSupplier": 0,
                "IndexOptionsLotNumber": 0,
                "IndexOptionsLocation": 0,
                "IndexOptionsMuLabel": 0,
                "IndexOptionsStatus": 0
              },
            sharedFilterAreaToggle: false,
            sharedSearchOn: false,
            isLoadMetaServices: false,
            isAnimatedClassAdded: false
        }
    }

    async componentDidMount() {
        $('#inventory-beta-holder-react').closest('.k-window-content').css( "padding", "0" );

        kendo.ui.progress($('#' + this.windowId), true);
        await metaDataService.getUserClientMetaData();

        var filterData = metaDataService.getInventoryFilterMetaData();
        if (filterData != "") {
            this.setState({
                sharedInventoryFilterObj: filterData,
                sharedSearchOn: JSON.stringify(filterData) != JSON.stringify(this.state.sharedInventoryFilterObj)
            });
        }
        this.setState({
            isLoadMetaServices:true
        })
    }

    componentWillUnmount(){
        $('#inventory-beta-holder-react').closest('.k-window-content').css( "padding", "26" );
    }

    selectedFacility = (facilityObj) => {
        this.setState({
            sharedSelectedFacilityObj: facilityObj
        });
    }

    onApplySavedFilter = (param_filterObj) => {
        this.setState({
            sharedInventoryFilterObj: param_filterObj,
            sharedSearchOn: true
        }, () => {
            if (this.gridComponent) {
                var grid = $('.' + this.windowId + ' #' + this.manageInventoryGridId).data("kendoGrid");
                var dataSource = grid.dataSource;
                this.gridComponent.setFilterAppliedText(dataSource.filter());
                this.gridComponent.onApplySavedFilterData(param_filterObj);
            }
        });
    }

    onInventoryFilterObjChange = (param_inventoryFilterObj,searchOn) => {
        this.setState({
            sharedInventoryFilterObj: Object.assign({}, param_inventoryFilterObj),
            sharedSearchOn:searchOn
        }, () => {
            metaDataService.setInventoryFilterMetaData(param_inventoryFilterObj);
            this.gridComponent.loadGridData();
        });
    }

    onRefreshGrid = () => {
        this.gridComponent.loadGridData();
    }

    clickForFilterToggle = () => {
        this.setState({
            filterAreaToggle: !this.state.filterAreaToggle,
            isAnimatedClassAdded: !this.state.isAnimatedClassAdded
        },()=>{
            setTimeout(() => {
                this.setState({
                    isAnimatedClassAdded : !this.state.isAnimatedClassAdded
                })
            }, 1000);
        });
        this.resizeWindow();
    }

    resizeWindow =() =>{
        $(window).trigger('resize');
    }

    render() {
        return (
            <div id="inventory-beta-holder-react" className={(this.state.filterAreaToggle ? " collapsedFilter " : " expandFilter ") + (this.state.isAnimatedClassAdded ? " animatedCollapse" : "")} >
                {this.state.isLoadMetaServices &&(
                <InventorySearch
                    onInventoryFilterObjChange={this.onInventoryFilterObjChange}
                    sharedInventoryFilterObj={this.state.sharedInventoryFilterObj}
                    onRefreshGrid={this.onRefreshGrid}
                    windowId={this.windowId}
                    onSelectFacility={this.selectedFacility}
                    onClickForFilterToggle={this.clickForFilterToggle}
                    sharedSearchOn={this.state.sharedSearchOn}
                    onApplySavedFilter={this.onApplySavedFilter}
                />
                )}

                {this.state.sharedSelectedFacilityObj != null && this.state.isLoadMetaServices &&
                    <InventoryGrid
                        ref={instance => { this.gridComponent = instance; }}
                        sharedSelectedFacilityObj={this.state.sharedSelectedFacilityObj}
                        windowId={this.windowId}
                        sharedInventoryFilterObj={this.state.sharedInventoryFilterObj}
                    />
                }

            </div >
        )

    }
}