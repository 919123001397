import React from "react";
import { StringWms } from "../../assets/ts/StringWms";
import { AddEditColumnModel } from "../react/components/AddEditColumn/add-edit-columns.component";
// import WmsScrollTo from "../react/components/WmsScrollTo/WmsScrollTo";
import VirtualGridComponent from "../react/components_proto/VirtualGridComponent/VirtualGridComponent";
import * as GridConfig from "./event-notification-grid-configuration.virtual.jsx";
import * as GlobalService from "../react/global_services";
import * as EventNotificationMetaServices from "./event-notification-meta-data.service.js";
import * as eventNotificationsService from "./event-notifications.services.js";
import WebHookModel from "./webhook-modal.component.jsx";
import { ArrayWms } from "../../assets/ts/ArrayWms";
import PropTypes from "prop-types";

export class EventNotificationGrid extends React.Component {
    constructor(props) {
        super(props);

        this.EventNotificationGridId = "EventNotificationGrid";
        this.translate = window.translate;
        this.EventNotification_GridHeaders = StringWms.convertSringToOption(
            this.translate("EventNotification_GridHeaders")
        );
        this.gridDomSelector = this.props.windowId
            ? "." + this.props.windowId + " #" + this.EventNotificationGridId
            : "#" + this.EventNotificationGridId;

        this.defaultColumns = GridConfig.getEventNotificationGridDemoColumns(
            this.EventNotification_GridHeaders
        ).columns;
        this.schema = GridConfig.getEventNotificationGridDemoSchema();
        this.allColumnList = GridConfig.getEventNotificationGridDemoColumns(this.EventNotification_GridHeaders).columns;

        this.state = {
            eventNotificationGridConfig: EventNotificationMetaServices.getWebhookNotificationGridOptionInMetaData(),
            sharedWebhookModalData: [],
            localKendoGrid: null,
            staticGridData: [],
            recordsCount: 0,
            filterText: "",
            fullFilterText: "",
            isOpenColumnModal: false,
            isNewWebhook: false,
            isOpenWebhookModal: false,
            sharedWebhookModalDataIndex: 0,
            webhookSaveChangesAndExit: false,
        };
    }

    componentDidMount() {
        this.loadGrid();
    }

    loadGrid = () => {
        kendo.ui.progress($(this.gridDomSelector), true);
        let cstEditUri = "";
        if (this.props.sharedEventNotificationFilter.customerEditUri) {
            cstEditUri = this.props.sharedEventNotificationFilter.customerEditUri;
        }
        eventNotificationsService
            .getEventNotificationGridData(cstEditUri)
            .then((response) => {
                let i = 1;
                response.data.Params.map((obj) => (obj.RowId = i++));
                this.setState({
                    staticGridData: response.data.Params,
                    recordsCount: response.data.Params.length,
                });
            })
            .finally(() => {
                kendo.ui.progress($(this.gridDomSelector), false);
            });
    };

    onToggleColumnModal = (isOpen) => () => {
        this.setState({
            isOpenColumnModal: isOpen,
        });
    };

    openWebhookModel = (isNewWebhook = false) => {
        this.setState({
            isOpenWebhookModal: true,
            isNewWebhook: isNewWebhook,
        });
    };

    onCloseWebhookModal = () => {
        this.setState({
            isOpenWebhookModal: false,
            isNewWebhook: false,
        });
    };

    onSaveWebhookData = (data) => {
        this.setState({ webhookSaveChangesAndExit: true });
        let tempAllData = Object.assign([], this.state.staticGridData);
        let msaageLable = "Lable_Webhook_Success";
        if (data.RowId == 0) {
            tempAllData.push(data);
        } else {
            tempAllData = tempAllData.map((obj) => {
                return data.RowId == obj.RowId ? data : obj;
            });
            msaageLable = "Lable_Webhook_Updated";
        }

        let cstEditUri = "";
        if (this.props.sharedEventNotificationFilter.customerEditUri) {
            cstEditUri = this.props.sharedEventNotificationFilter.customerEditUri;
        }
        eventNotificationsService
            .saveEventNotificationGridData(cstEditUri, { Params: tempAllData })
            .then((response) => {
                this.setState({ webhookSaveChangesAndExit: false, isOpenWebhookModal: false });
                GlobalService.notification("success", this.translate(msaageLable));
                this.loadGrid();
            })
            .finally(() => {
                this.setState({ webhookSaveChangesAndExit: false });
                kendo.ui.progress($("#WebHookModal"), false);
            });
    };
    onDeleteWebhookData = (RowId) => {
        this.setState({ webhookSaveChangesAndExit: true });
        let tempAllData = Object.assign([], this.state.staticGridData);
        tempAllData = tempAllData.filter((obj) => {
            return RowId != obj.RowId;
        });
        let cstEditUri = "";
        if (this.props.sharedEventNotificationFilter.customerEditUri) {
            cstEditUri = this.props.sharedEventNotificationFilter.customerEditUri;
        }
        eventNotificationsService
            .saveEventNotificationGridData(cstEditUri, { Params: tempAllData })
            .then((response) => {
                this.setState({ webhookSaveChangesAndExit: false, isOpenWebhookModal: false });
                GlobalService.notification("success", this.translate("Lable_Webhook_Deleted"));
                this.loadGrid();
            })
            .finally(() => {
                this.setState({ webhookSaveChangesAndExit: false });
            });
    };

    onselectGridRow = () => {
        let grid = $("#" + this.props.windowId + " #EventNotificationGrid").data("kendoGrid");
        var data = grid.dataItem(grid.select());
        if (data) {
            this.setState({
                isOpenWebhookModal: true,
                isNewWebhook: false,
                sharedWebhookModalData: data,
                sharedWebhookModalDataIndex: data.RowId,
            });
        }
    };

    onGridDataBound = (e, kendoGrid) => {
        this.setState({
            localKendoGrid: kendoGrid,
        });
    };

    onSort = (event) => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            gridColumn,
            sortArray,
            grid.dataSource.filter()
        );
        EventNotificationMetaServices.setWebhookNotificationGridOptionInMetaData(finalSubmission);
    };

    // on grid column reorder save changes in meta data object
    onColumnReorder = (event) => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            switchedArray,
            sortArray,
            grid.dataSource.filter()
        );
        EventNotificationMetaServices.setWebhookNotificationGridOptionInMetaData(finalSubmission);
    };

    // on grid column resize save changes in meta data object
    onColumnResize = (event) => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        EventNotificationMetaServices.setWebhookNotificationGridOptionInMetaData(finalSubmission);
    };

    // on click grid clear sort menu
    onClickClearSorts = () => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            [],
            grid.dataSource.filter()
        );
        EventNotificationMetaServices.setWebhookNotificationGridOptionInMetaData(finalSubmission);
    };

    // on click grid reset default grid layout menu
    onClickResetGrid = () => {
        var finalSubmission = {
            optionsData: {},
        };
        EventNotificationMetaServices.setWebhookNotificationGridOptionInMetaData(finalSubmission);
    };

    setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            this.setState({
                filterText: "",
                fullFilterText: "",
            });
            return;
        }
        var filterConditionTitle = {
            lte: "Is Less Than Or Equal To",
            eq: "Is Equal To",
            lt: "Is Less Than",
            neq: "Is Not Equal To",
            gte: "Is Greater Than Or Equal To",
            gt: "Is Greater Than",
            startswith: "Starts With",
            endswith: "Ends With",
            contains: "Contains",
            doesnotcontain: "Does Not Contain",
            isnull: "Is Null",
            isnotnull: "Is Not Null",
            isempty: "Is Empty",
            isnotempty: "Is Not Empty",
            isnullorempty: "Has No Value",
            isnotnullorempty: "Has Value",
        };
        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;
        for (let i = 0; i < filterList.length; i++) {
            const item = filterList[i];
            var title = this.allColumnList.find((x) => x.field === item.field).title;
            filterText += title + " : " + item.value + ",";
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
        }
        filterText = filterText.slice(0, -1);
        this.setState({
            filterText: filterText,
            fullFilterText: fullFilterText,
        });
    };

    // on grid filter selection
    onGridFilterApplied = (filter) => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            this.setFilterAppliedText(dataSource.filter());
            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
                grid.columns,
                sortArray,
                dataSource.filter()
            );
            EventNotificationMetaServices.setWebhookNotificationGridOptionInMetaData(finalSubmission);
        }, 100);
    };

    refreshEventNotificationGrid = () => {
        this.loadGrid();
    };

    saveMetaData = () => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sort,
            grid.dataSource.filter()
        );
        EventNotificationMetaServices.setWebhookNotificationGridOptionInMetaData(finalSubmission);
    };

    render() {
        return (
            <div className="new-layout-grid-wrapper">
                {/* <div className="event-notification-header-group">
                    <div className="tab-listing">
                        <ul>
                            <li>
                                <WmsScrollTo
                                    label="Email"
                                    windowId={this.props.windowId}
                                    scrollToId='collapseItemsEmail'
                                    scrollBoxId='ItemsScrollBox'
                                    wmsSelector={this.props.selector + "LabelEmail"}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseItemsTabContent_wrapper"}
                                    disabled={this.props.sharedMultiSkuEnableRecurring}
                                />
                            </li>
                            <li>
                                <WmsScrollTo
                                    label="FTP"
                                    windowId={this.props.windowId}
                                    scrollToId='collapseItemsFTP'
                                    scrollBoxId='ItemsScrollBox'
                                    wmsSelector={this.props.selector + "LabelFTP"}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseItemsTabContent_wrapper"}
                                    disabled={this.props.sharedMultiSkuEnableRecurring}
                                />
                            </li>
                            <li>
                                <WmsScrollTo
                                    label="Webhooks"
                                    windowId={this.props.windowId}
                                    scrollToId='collapseItemsWebhooks'
                                    scrollBoxId='ItemsScrollBox'
                                    wmsSelector={this.props.selector + "LabelWebhook"}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseItemsTabContent_wrapper"}
                                    disabled={this.props.sharedMultiSkuEnableRecurring}
                                />
                            </li>
                        </ul>
                    </div>
                </div> */}
                <VirtualGridComponent
                    shawCheckbox={false}
                    // ref="EventNotificationGrid"
                    loading={false}
                    staticGridData={this.state.staticGridData}
                    onSelection={this.onselectGridRow}
                    wmsSelector={this.props.selector + "Grid"}
                    showSortColumns={true}
                    gridKeySelection={"RowNumber"}
                    showGridResult={true}
                    showGridSummary={true}
                    totalResult={this.state.recordsCount}
                    gridName={this.EventNotificationGridId}
                    eventClickColumns={this.onToggleColumnModal(true)}
                    filterText={this.state.filterText}
                    fullFilterText={this.state.fullFilterText}
                    virtual={true}
                    metaDataFieldsName={"EventNotification"}
                    id={this.EventNotificationGridId}
                    defaultColumns={this.defaultColumns}
                    allColumns={this.allColumnList}
                    schema={this.schema}
                    metaGridConfig={this.state.eventNotificationGridConfig}
                    onDataBound={this.onGridDataBound}
                    onSort={this.onSort}
                    onColumnReorder={this.onColumnReorder}
                    onColumnResize={this.onColumnResize}
                    onClickClearSorts={this.onClickClearSorts}
                    onClickResetGrid={this.onClickResetGrid}
                    onGridFilterApplied={this.onGridFilterApplied}
                    menu={[
                        {
                            name: this.translate("Label_New_Webhook"),
                            value: [],
                            icon: "fa-plus",
                            color: "green",
                            wmsSelector: this.props.selector + "Create",
                            isContextMenu: false,
                            clickFunc: () => this.openWebhookModel(true),
                            isButtonMenu: true,
                        },
                        {
                            name: "",
                            wmsSelector: this.props.selector + "RefreshGrid",
                            value: [],
                            clickFunc: this.refreshEventNotificationGrid,
                            disabled: false,
                            icon: "fa-sync-alt",
                            color: "blue",
                            isContextMenu: false,
                            isButtonMenu: true,
                            parentClassName: "event-notification-refresh-btn",
                        },
                    ]}
                />
                {this.state.isOpenColumnModal && (
                    <AddEditColumnModel
                        id={"addEditColumnEventNotification"}
                        height={700}
                        windowId={this.props.windowId}
                        onCloseColumnModel={this.onToggleColumnModal(false)}
                        isOpenColumnModel={this.state.isOpenColumnModal}
                        allSortColumnList={this.allColumnList}
                        gridId={this.EventNotificationGridId}
                        updateGridData={() => {}}
                        saveChangesInMetaData={this.saveMetaData}
                    />
                )}

                {this.state.isOpenWebhookModal && (
                    <WebHookModel
                        isNewWebhook={this.state.isNewWebhook}
                        isOpen={this.state.isOpenWebhookModal}
                        onCloseWebHookModal={this.onCloseWebhookModal}
                        onSaveWebhookData={this.onSaveWebhookData}
                        DeleteWebhookData={this.onDeleteWebhookData}
                        selector={this.props.selector}
                        sharedEventNotificationFilter={this.props.sharedEventNotificationFilter}
                        windowId={this.props.windowId}
                        sharedWebhookModalData={this.state.sharedWebhookModalData}
                        sharedCustomerOptions={this.props.sharedCustomerOptions}
                        webhookSaveChangesAndExit={this.state.webhookSaveChangesAndExit}
                    />
                )}
            </div>
        );
    }
}

EventNotificationGrid.defaultProps = {
    windowId: "",
    sharedEventNotificationFilter: "",
    sharedCustomerOptions: "",
    selector: "",
};

EventNotificationGrid.propTypes = {
    windowId: PropTypes.string,
    selector: PropTypes.string,
    sharedCustomerOptions: PropTypes.object,
    sharedEventNotificationFilter: PropTypes.object,
};
