export const getInitialPrintMenu = (initialPrintMenuResponse, params, menuClickEvent) => {

    var translate = window.translate;

    var updatedPrintMenu = {
        ManageMenuData: [],
        DocumentMenuData: []
    }

    if (initialPrintMenuResponse.length > 0 || initialPrintMenuResponse != null || typeof initialPrintMenuResponse != undefined) {
        updatedPrintMenu.ManageMenuData.push({
            "name": translate('Label_Edit_Shipping_Info'),
            "value": [],
            "disabled": params.SelectedOrders == 0 || params.sameCustomer == false || params.SameWarehouse == false || !params.Rights.hasOwnProperty('orders.edit.view') || params.isOrderIsClosed,
            "disabledText": !params.Rights.hasOwnProperty('orders.edit.view') ? translate('Note_Disable_Permission_Edit_Shipping_Info') : params.isOrderIsClosed ? translate('Note_Disable_Status_Is_close') : translate('Note_Disable_Edit_Shipping_Info'),
            "isContextMenu": true,
            "isButtonMenu": true,
            "wmsSelector": params.Selector + "EditShippingInfo",
            "onClickFunc": menuClickEvent,
            "wmsid": "editShippingInfo"
        });
        updatedPrintMenu.ManageMenuData.push({
            "name": translate('Label_Shop_Cheapest_Rate'),
            "value": [],
            "disabled": params.SelectedOrders == 0 || params.isOrderIsClosed,
            "disabledText": params.isOrderIsClosed ? translate('Note_Disable_Status_Is_close') : "",
            'isContextMenu': params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('Shipping.SmallParcelRateShoppingEnabled'),
            'isButtonMenu': params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('Shipping.SmallParcelRateShoppingEnabled'),
            "wmsSelector": params.Selector + "AutoRateShop",
            "onClickFunc": menuClickEvent,
            "wmsid": "autoRateShop"
        });
        updatedPrintMenu.ManageMenuData.push({
            "name": translate('Label_Consolidate_Packages'),
            "value": [],
            "disabled": params.SelectedOrders == 0 || params.isOrderIsClosed || !params.canRegenPkgs,
            "disabledText": params.isOrderIsClosed ? translate('Note_Disable_Status_Is_close') : ((!params.canRegenPkgs) ? translate('Label_Already_Shipped_Tooltip') : ''),
            'isContextMenu': params.Rights.hasOwnProperty('orders.edit.pack'),
            'isButtonMenu': params.Rights.hasOwnProperty('orders.edit.pack'),
            "wmsSelector": params.Selector + "ConsolidatePackages",
            "onClickFunc": menuClickEvent,
            "wmsid": "consolidatePackages"
        });
        updatedPrintMenu.ManageMenuData.push({
            "name": translate('Label_Recalc_Packages'),
            "value": [],
            "disabled": params.SelectedOrders == 0 || params.isOrderIsClosed || !params.canRegenPkgs,
            "disabledText": params.isOrderIsClosed ? translate('Note_Disable_Status_Is_close') : ((!params.canRegenPkgs) ? translate('Label_Already_Shipped_Tooltip') : ''),
            'isContextMenu': params.Rights.hasOwnProperty('orders.edit.pack'),
            'isButtonMenu': params.Rights.hasOwnProperty('orders.edit.pack'),
            "wmsSelector": params.Selector + "RecalcPackages",
            "onClickFunc": menuClickEvent,
            "wmsid": "recalcPackages"
        });
        updatedPrintMenu.ManageMenuData.push({
            "name": translate('Label_New_Package_Type'),
            "value": [],
            "disabled": false,
            'isContextMenu': true,
            'isButtonMenu': true,
            "wmsSelector": params.Selector + "NewPackageType",
            "onClickFunc": menuClickEvent,
            "wmsid": "newPackageType",
            "customClass": params.Rights.hasOwnProperty('orders.confirm') ? '' : "option-saperator"
        });
        updatedPrintMenu.ManageMenuData.push({
            "name": translate('Label_Close_Orders'),
            "value": [],
            "disabled": (params.SelectedOrders == 0 || params.disabledShipAndClose || params.sameCustomer == false || params.isOrderIsClosed),
            "disabledText": (params.disabledShipAndClose) ? translate("Label_Disabled_ShipAndClose") : (!params.sameCustomer) ? translate("note_ship_and_close_samecustomer") : params.isOrderIsClosed ? translate('Note_Disable_Status_Is_close') : "",
            'isContextMenu': params.Rights.hasOwnProperty('orders.confirm'),
            'isButtonMenu': params.Rights.hasOwnProperty('orders.confirm'),
            "wmsSelector": params.Selector + "CloseOrder",
            "onClickFunc": menuClickEvent,
            "wmsid": "closeOrder",
            "customClass": "option-saperator"
        });
        updatedPrintMenu.ManageMenuData.push({
            "name": translate('Label_Print_Hvp_Batches'),
            "value": [],
            "disabled": false,
            "isContextMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('Shipping.SmallParcelHighVolumeProcessing') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            "isButtonMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('Shipping.SmallParcelHighVolumeProcessing') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            "wmsSelector": params.Selector + "PrintBatchedShippingLabel",
            "onClickFunc": menuClickEvent,
            "wmsid": "printBatchedShippingLabel"
        });
        updatedPrintMenu.ManageMenuData.push({
            "name": translate('Reset_Failed_Hvp'),
            "value": [],
            "disabled": params.SelectedOrders < 1,
            "isContextMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            "isButtonMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            "wmsSelector": params.Selector + "ResetFailedHvp",
            "onClickFunc": menuClickEvent,
            'wmsid': "resetFailedHvp"
        });
        initialPrintMenuResponse.forEach((item) => {
            var tempObj = {};

            tempObj = {
                "name": item.CategoryName,
                "value": [],
                "disabled": !params.Rights.hasOwnProperty('orders.docs'),
                "disabledText": translate("order_Document_Permission_note"),
                'isContextMenu': true,
                'isButtonMenu': true,
                "wmsSelector": params.Selector + item.RdlName,
                "onClickFunc": menuClickEvent,
                'wmsid': item.RdlName
            };


            if (item.CategoryName == "Reprint Shipping Label") {
                tempObj.name = translate('Label_Reprint_Labels'),
                    tempObj.value = [],
                    tempObj.disabled = (params.ParcelLabelType == 0 || !params.isSamePrintedFormat),
                    tempObj.disabledText = translate("note_reprint_shipping_label");
                tempObj.isContextMenu = false,
                    tempObj.isButtonMenu = params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
                    tempObj.wmsSelector = params.Selector + "ReprintLabels",
                    tempObj.onClickFunc = menuClickEvent,
                    tempObj.wmsid = "reprintShippingLabel"

                updatedPrintMenu.ManageMenuData.push(tempObj);
            } else if (item.CategoryName == "Return Label") {

                tempObj.name = translate('Label_Print_Return_Label'),
                    tempObj.onClickFunc = () => null;
                tempObj.isButtonMenu = params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
                    tempObj.isContextMenu = false,
                    tempObj.disabled = (params.ParcelLabelType == 0);
                tempObj.disabledText = translate("note_return_label");
                tempObj.customClass = "option-saperator";

                tempObj.value.push({
                    "name": "Return (Zpl)",
                    "value": [],
                    "disabled": tempObj.disabled ? true : (params.ParcelLabelType != 1 && params.ParcelLabelType != 2),
                    "wmsSelector": params.Selector + "ReturnZpl",
                    "onClickFunc": menuClickEvent,
                    'wmsid': "returnZpl",
                    'runReport': "/WebUI/Shipping/FindOrders/ParcelReturnZplContents",
                    'runnerKey': item.hasOwnProperty('Reports') ? item.Reports[0].TemplatedRunnerKey : ''
                });

                tempObj.value.push({
                    "name": "Return (Pdf)",
                    "value": [],
                    "disabled": tempObj.disabled ? true : (params.ParcelLabelType != 1 && params.ParcelLabelType != 2),
                    "wmsSelector": params.Selector + "ReturnPdf",
                    "onClickFunc": menuClickEvent,
                    'wmsid': "returnPdf",
                    'runReport': "/WebUI/Shipping/FindOrders/ParcelReturnPdfContent",
                    'runnerKey': item.hasOwnProperty('Reports') ? item.Reports[0].TemplatedRunnerKey : ''
                });

                updatedPrintMenu.ManageMenuData.push(tempObj);
            }

        });
        updatedPrintMenu.ManageMenuData.push({
            "name": translate('Label_Void_Labels'),
            "value": [],
            "disabled": params.SelectedOrders == 0 || !params.isDisableCancelVoid,
            "disabledText": !params.isDisableCancelVoid ? translate("note_void_label") : "",
            "isContextMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            "isButtonMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
            "wmsSelector": params.Selector + "VoidLabels",
            "onClickFunc": menuClickEvent,
            "wmsid": "voidLabelsConfirm"
        });
        updatedPrintMenu.ManageMenuData.push({
            "name": translate('Label_Cancel_Void_Labels'),
            "value": [],
            "disabled": params.SelectedOrders < 1 || params.isOrderIsClosed || !params.isDisableCancelVoid,
            "disabledText": (params.isOrderIsClosed ? translate('Note_Disable_Status_Is_close') : !params.isDisableCancelVoid ? translate("note_void_label") : ""),
            "isContextMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel') && params.Rights.hasOwnProperty('orders.manage.cancel'),
            "isButtonMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel') && params.Rights.hasOwnProperty('orders.manage.cancel'),
            "wmsSelector": params.Selector + "cancelAndVoidShippingLabelConfirm",
            "onClickFunc": menuClickEvent,
            'wmsid': "cancelAndVoidShippingLabelConfirm"
        });
    }
    // start document option
    updatedPrintMenu.DocumentMenuData.push({
        "name": translate('Label_Order_Summary'),
        "value": [],
        "disabled": (params.SelectedOrders != 1),
        "disabledText": '',
        "icon": "fa-pencil",
        "color": "yellow",
        'isContextMenu': params.Rights.hasOwnProperty('orders.manage.view'),
        'isButtonMenu': params.Rights.hasOwnProperty('orders.manage.view'),
        "wmsSelector": params.Selector + "ViewSummary",
        "onClickFunc": menuClickEvent,
        'wmsid': 'smallParcelViewSummary'
    });
    updatedPrintMenu.DocumentMenuData.push({
        "name": translate('Label_Pick_Ticket'),
        "value": [],
        "disabled": ((params.SelectedOrders < 1) || !params.EnablePickOption || !params.Rights.hasOwnProperty('orders.docs')),
        "disabledText": !params.Rights.hasOwnProperty('orders.docs') ? translate("order_Document_Permission_note") : translate("Tooltip_FullyAllocated_TplSettingPrintOverAllocOrders"),
        'isContextMenu': true,
        'isButtonMenu': true,
        "wmsSelector": params.Selector + "PickTicket",
        "onClickFunc": menuClickEvent,
        'wmsid': "pickTicket"
    });
    updatedPrintMenu.DocumentMenuData.push({
        "name": translate('Label_Master_Pick_Ticket'),
        "value": [],
        "disabled": ((params.SelectedOrders <= 1) || !params.SameWarehouse || !params.sameCustomer || !params.SameBatchId || !params.Rights.hasOwnProperty('orders.docs')),
        "disabledText": !params.Rights.hasOwnProperty('orders.docs') ? translate("order_Document_Permission_note") : translate("note_Master_Pick_Ticket"),
        'isContextMenu': true,
        'isButtonMenu': true,
        "wmsSelector": params.Selector + "MasterPickTicket",
        "onClickFunc": menuClickEvent,
        'wmsid': "masterPickTicket"
    });
    updatedPrintMenu.DocumentMenuData.push({
        "name": translate('Label_Packing_Slip'),
        "value": [],
        "disabled": params.SelectedOrders == 0 || !params.Rights.hasOwnProperty('orders.docs'),
        "disabledText": !params.Rights.hasOwnProperty('orders.docs') ? translate("order_Document_Permission_note") : '',
        "isContextMenu": true,
        "isButtonMenu": true,
        "wmsSelector": params.Selector + "CreatePackingSlips",
        "onClickFunc": menuClickEvent,
        "wmsid": "createPackingSlips"
    });
    updatedPrintMenu.DocumentMenuData.push({
        "name": translate('Label_Intl_Documents'),
        "value": [],
        "disabled": params.SelectedOrders == 0,
        "isContextMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
        "isButtonMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
        "wmsSelector": params.Selector + "CreateIntlDocuments",
        "onClickFunc": menuClickEvent,
        "wmsid": "createIntlDocuments"
    });
    updatedPrintMenu.DocumentMenuData.push({
        "name": translate('Label_End_of_Day_Request'),
        "value": [{
            "name": translate('Label_Create_End_of_Day_Request'),
            "value": [],
            "disabled": params.SelectedOrders < 1,
            'isContextMenu': true,
            'isButtonMenu': true,
            "wmsSelector": params.Selector + "createEndofDayRequest",
            "onClickFunc": menuClickEvent,
            'wmsid': "createEndofDayRequest"
        }, {
            "name": translate('Label_Reprint_End_of_Day_Request'),
            "value": [],
            "disabled": false,
            'isContextMenu': true,
            'isButtonMenu': true,
            "wmsSelector": params.Selector + "reprintEndofDayRequest",
            "onClickFunc": menuClickEvent,
            'wmsid': "reprintEndofDayRequest"
        }],
        "disabled": false,
        "isContextMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
        "isButtonMenu": params.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && params.Rights.hasOwnProperty('orders.manage.smartparcel'),
        "wmsSelector": params.Selector + "EndofDayRequest",
        "onClickFunc": () => { },
        'wmsid': "endofDayRequest"
    });
    if (params.Rights.hasOwnProperty("Integrations.SmallParcelPartner") && params.Rights['Integrations.SmallParcelPartner'] == "ParcelApi") {
        let findEndOfDayRequestMenu = _.find(updatedPrintMenu.DocumentMenuData, (menuItem) => {
            return menuItem.wmsid == "endofDayRequest"
        });
        if (findEndOfDayRequestMenu) {
            /* console.log("params", params);
            console.log("params.SelectedOrders", params.SelectedOrders);
            console.log("!params.SmallParcelShipDate", !params.SmallParcelShipDate); */
            findEndOfDayRequestMenu.value.push(
                {
                    name: translate("Label_Create_Container_Manifest_Request"),
                    value: [],
                    disabled: params.SelectedOrders == 0 || !params.isHasSmallParcelShipDate,
                    disabledText: translate("Label_Create_Container_Manifest_Disable"),
                    isContextMenu: true,
                    isButtonMenu: true,
                    wmsSelector: params.Selector + "createContinerManifestRequest",
                    onClickFunc: menuClickEvent,
                    wmsid: "createContinerManifestRequest",
                },
                {
                    name: translate("Label_Reprint_Container_Manifest_Request"),
                    value: [],
                    disabled: params.SelectedOrders == 0 || !params.isHasSmallParcelShipDate,
                    isContextMenu: true,
                    disabledText: translate("Label_Create_Container_Manifest_Disable"),
                    isButtonMenu: true,
                    wmsSelector: params.Selector + "reprintContinerManifestRequest",
                    onClickFunc: menuClickEvent,
                    wmsid: "reprintContinerManifestRequest",
                }
            );
        }
    }
    updatedPrintMenu.DocumentMenuData.push({
        "name": translate("Label_Barcode_Cheat_Sheet"),
        "value": [],
        "disabled": params.barcodeDownloadLoading,
        "loading": params.barcodeDownloadLoading,
        "disabledText": "",
        "icon": "fa-print",
        "color": "green",
        'isContextMenu': true,
        "wmsSelector": params.Selector + "BarcodeSheet",
        "onClickFunc": menuClickEvent,
        'isButtonMenu': true,
        'wmsid': 'BarcodeSheet'
    });
    return updatedPrintMenu;
}