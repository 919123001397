import React from "react";
import WmsModel from "../react/components/WmsModel/WmsModel";
import PropTypes from "prop-types";
import WmsCollapse from "../react/components/WmsCollapse/WmsCollapse";
import WmsButton from "../react/components/WmsButton/WmsButton";
import * as GridConfig from "./event-notification-grid-configuration.virtual.jsx";
import WmsInput from "../react/components/WmsInput/WmsInput";
import WmsDropdown from "../react/components/WmsDropdown/WmsDropdown";
import WmsCheckBox from "../react/components/WmsCheckBox/WmsCheckBox";

let WebHookModalId = "#WebHookModal";
export default class WebHookModel extends React.Component {
    constructor(props) {
        super(props);

        this.translate = window.translate;

        this.initialWebhookObj = {
            RowId: 0,
            Name: "",
            Url: "https://",
            Resource: "",
            EventTypesArr: [],
            IncludeResource: false,
            IncludeResourceOptions: [],
        };

        this.eventTypesOptions = GridConfig.getWebhookEventTypes();

        this.state = {
            webhookFormData: this.initialWebhookObj,
            isOpenDeleteConfirmModel: false,
            isValidURL: false,
        };
        this.eventTypesOptions.unshift({
            Id: "",
            name: "Select an Event Type",
            options: [],
        });
    }

    componentDidMount() {
        if (!this.props.isNewWebhook) {
            kendo.ui.progress($(WebHookModalId), true);
            let tmpWebhookData = this.props.sharedWebhookModalData;
            tmpWebhookData.EventTypesArr =
                this.props.sharedWebhookModalData.EventTypes !== undefined
                    ? this.props.sharedWebhookModalData.EventTypes.split(",")
                    : [];
            tmpWebhookData.IncludeResourceOptions = [];
            if (tmpWebhookData.Allocations) tmpWebhookData.IncludeResourceOptions.push("Allocations");
            if (tmpWebhookData.AllocationsWithDetail)
                tmpWebhookData.IncludeResourceOptions.push("AllocationsWithDetail");
            if (tmpWebhookData.BillingDetails) tmpWebhookData.IncludeResourceOptions.push("BillingDetails");
            if (tmpWebhookData.Contacts) tmpWebhookData.IncludeResourceOptions.push("Contacts");
            if (tmpWebhookData.ItemSavedElements) tmpWebhookData.IncludeResourceOptions.push("ItemSavedElements");
            if (tmpWebhookData.OrderItems) tmpWebhookData.IncludeResourceOptions.push("OrderItems");
            if (tmpWebhookData.OutboundSerialNumbers)
                tmpWebhookData.IncludeResourceOptions.push("OutboundSerialNumbers");
            if (tmpWebhookData.Packages) tmpWebhookData.IncludeResourceOptions.push("Packages");
            if (tmpWebhookData.ParcelOptions) tmpWebhookData.IncludeResourceOptions.push("ParcelOptions");
            if (tmpWebhookData.ReceiveItems) tmpWebhookData.IncludeResourceOptions.push("ReceiveItems");
            if (tmpWebhookData.SavedElements) tmpWebhookData.IncludeResourceOptions.push("SavedElements");
            if (tmpWebhookData.SmallParcel) tmpWebhookData.IncludeResourceOptions.push("SmallParcel");
            this.setState({
                webhookFormData: tmpWebhookData,
                isValidURL: this.urlPatternValidation(tmpWebhookData.Url),
            });
            kendo.ui.progress($(WebHookModalId), false);
        }
    }

    handleChangeEvent = (event) => {
        const { name, value } = event.target;
        let tempEventTypesArr = this.state.webhookFormData.EventTypesArr;
        let tempIncludeResource = this.state.webhookFormData.IncludeResource;
        let tempIncludeResourceOptions = this.state.webhookFormData.IncludeResourceOptions;
        if (name == "Url") {
            this.setState({
                isValidURL: this.urlPatternValidation(value),
            });
        }
        if (name === "Resource") {
            tempEventTypesArr = [];
            tempIncludeResource = false;
            tempIncludeResourceOptions = [];
            if (value == "InventorySummary") {
                tempEventTypesArr.push("InventorySummaryUpdate");
                tempIncludeResource = true;
            }
        }
        this.setState({
            webhookFormData: {
                ...this.state.webhookFormData,
                [name]: value,
                EventTypesArr: tempEventTypesArr,
                IncludeResource: tempIncludeResource,
                IncludeResourceOptions: tempIncludeResourceOptions,
            },
        });
    };

    handelEventTypeOption = (event) => {
        const { name, checked } = event.target;
        let tempWebhookData = this.state.webhookFormData;
        let tempEventTypes = tempWebhookData.EventTypesArr;
        if (checked) {
            tempEventTypes.push(name);
        } else {
            let position = tempEventTypes.indexOf(name);
            tempEventTypes.splice(position, 1);
        }
        this.setState({ webhookFormData: { ...this.state.webhookFormData, EventTypesArr: tempEventTypes } });
    };

    handleIncludeResourceChangeEvent = (event) => {
        var checked = event.target.checked;
        this.setState({
            webhookFormData: { ...this.state.webhookFormData, IncludeResource: checked, IncludeResourceOptions: [] },
        });
    };

    handleIncludeResourceOptionChangeEvent = (event) => {
        const { name, checked } = event.target;
        let tempWebhookData = this.state.webhookFormData;
        let tempResourceOptionSelect = tempWebhookData.IncludeResourceOptions;
        if (checked) {
            tempResourceOptionSelect.push(name);
        } else {
            let position = tempResourceOptionSelect.indexOf(name);
            tempResourceOptionSelect.splice(position, 1);
        }
        this.setState({
            webhookFormData: { ...this.state.webhookFormData, IncludeResourceOptions: tempResourceOptionSelect },
        });
    };

    urlPatternValidation = (URL) => {
        var regex =
            /^https:\/\/((www.[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)+)|((?!www).)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)+)/gm;
        return regex.test(URL);
    };

    onDeleteWebhookData = () => {
        this.setState({
            isOpenDeleteConfirmModel: true,
        });
    };

    onCloseDeleteAllConfirmationModel = () => {
        this.setState({
            isOpenDeleteConfirmModel: false,
        });
    };

    onConfirmDeleteWebhookData = () => {
        kendo.ui.progress($("#" + this.props.windowId + "confirmDeletePackage"), true);
        this.props.DeleteWebhookData(this.state.webhookFormData.RowId);
    };

    saveWebhookData = () => {
        kendo.ui.progress($(WebHookModalId), true);
        let data = {
            RowId: this.state.webhookFormData.RowId,
            Name: this.state.webhookFormData.Name,
            Url: this.state.webhookFormData.Url,
            Resource: this.state.webhookFormData.Resource,
            EventTypes: this.state.webhookFormData.EventTypesArr.join(","),
            IncludeResource: this.state.webhookFormData.IncludeResource,

            Allocations: this.state.webhookFormData.IncludeResourceOptions.includes("Allocations"),
            AllocationsWithDetail: this.state.webhookFormData.IncludeResourceOptions.includes("AllocationsWithDetail"),
            BillingDetails: this.state.webhookFormData.IncludeResourceOptions.includes("BillingDetails"),
            Contacts: this.state.webhookFormData.IncludeResourceOptions.includes("Contacts"),
            ItemSavedElements: this.state.webhookFormData.IncludeResourceOptions.includes("ItemSavedElements"),
            OrderItems: this.state.webhookFormData.IncludeResourceOptions.includes("OrderItems"),
            OutboundSerialNumbers: this.state.webhookFormData.IncludeResourceOptions.includes("OutboundSerialNumbers"),
            Packages: this.state.webhookFormData.IncludeResourceOptions.includes("Packages"),
            ParcelOptions: this.state.webhookFormData.IncludeResourceOptions.includes("ParcelOptions"),
            ReceiveItems: this.state.webhookFormData.IncludeResourceOptions.includes("ReceiveItems"),
            SavedElements: this.state.webhookFormData.IncludeResourceOptions.includes("SavedElements"),
            SmallParcel: this.state.webhookFormData.IncludeResourceOptions.includes("SmallParcel"),
        };
        this.props.onSaveWebhookData(data);
    };

    render() {
        const isDisplayEventOptions =
            this.eventTypesOptions.find((o) => o.Id === this.state.webhookFormData.Resource) != undefined &&
            this.eventTypesOptions.find((o) => o.Id === this.state.webhookFormData.Resource).options.length > 0;

        const isEnableCommonResources =
            this.state.webhookFormData.Resource === "Order" ||
            this.state.webhookFormData.Resource === "Receiver" ||
            this.state.webhookFormData.Resource === "OrderItem";

        return (
            <div>
                <WmsModel
                    id="WebHookModal"
                    title={window.translate("Label_Create_New_Webhook")}
                    width={800}
                    height={800}
                    onCloseFunc={this.props.onCloseWebHookModal}
                    isOpen={this.props.isOpen}
                    customClass="wms-model-new-ui-wrapper"
                >
                    <div className="model-content-wrapper">
                        <WmsCollapse
                            id={"webhookGeneral"}
                            headerLabel={window.translate("Label_Configuration_Options")}
                            showCollapseText={false}
                        >
                            <div className="display-inline">
                                <WmsDropdown
                                    id="webhookCustomer"
                                    name="customer"
                                    label={window.translate("Label_Webhook_Scope")}
                                    wmsSelector={this.props.selector + "webhookCustomer"}
                                    value={this.props.sharedEventNotificationFilter.customerEditUri}
                                    disabled={true}
                                    onChangeFunc={() => {}}
                                    options={this.props.sharedCustomerOptions}
                                    isVirtualOptions={false}
                                    valueField="EditUri"
                                    textField="Name"
                                    parentClassName="webhook-customer"
                                />
                                <WmsInput
                                    id="WebhookName"
                                    inputName="Name"
                                    wmsSelector={this.props.selector + "Name"}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={window.translate("Label_Description")}
                                    value={this.state.webhookFormData.Name}
                                    required={true}
                                    extraWrapperClass={"webhook-description"}
                                />
                            </div>
                            <div className="display-inline">
                                <WmsInput
                                    id="Webhookurl"
                                    inputName="Url"
                                    wmsSelector={this.props.selector + "Url"}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={window.translate("Label_Destination_Url")}
                                    value={this.state.webhookFormData.Url}
                                    required={true}
                                    extraWrapperClass={
                                        this.state.webhookFormData.Url != "https://" && !this.state.isValidURL
                                            ? "webhook-destination-url wms_invalid_input"
                                            : "webhook-destination-url"
                                    }
                                />
                            </div>
                        </WmsCollapse>
                        <div className="scroll-horizontal">
                            <div className="model-content-wrapper">
                                <div className="display-flex">
                                    <WmsDropdown
                                        id="webhookResource"
                                        name="Resource"
                                        label={window.translate("Label_Event_Type")}
                                        wmsSelector={this.props.selector + "Resource"}
                                        value={this.state.webhookFormData.Resource}
                                        required={true}
                                        onChangeFunc={this.handleChangeEvent}
                                        options={this.eventTypesOptions}
                                        valueField="Id"
                                        textField="name"
                                        parentClassName="webhook-event-type"
                                    />
                                </div>
                                {isDisplayEventOptions && (
                                    <div className="wms-checkbox-group">
                                        <label className="label">Events</label>
                                        <div className="wms-checkbox-group-container">
                                            {this.eventTypesOptions
                                                .find((o) => o.Id === this.state.webhookFormData.Resource)
                                                .options.map((obj, i) => {
                                                    return (
                                                        <div
                                                            key={obj.key + "checkboxArea"}
                                                            className={"checkbox-container"}
                                                        >
                                                            <WmsCheckBox
                                                                key={`EventType_${i}`}
                                                                id={`EventType_${i}`}
                                                                name={`${obj.key}`}
                                                                onChangeFunc={this.handelEventTypeOption}
                                                                label={obj.title}
                                                                checked={
                                                                    this.state.webhookFormData?.EventTypesArr.indexOf(
                                                                        obj.key
                                                                    ) !== -1
                                                                }
                                                                isRounded={true}
                                                                disabled={
                                                                    this.state.webhookFormData.Resource ==
                                                                    "InventorySummary"
                                                                }
                                                                wmsSelector={this.props.selector + "IsDefault"}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="model-content-wrapper">
                                {this.state.webhookFormData.Resource && (
                                    <>
                                        <div className="wms-checkbox-group">
                                            <label className="label">Include Resources</label>
                                            <div className="wms-checkbox-group-container">
                                                <div
                                                    key={"includeResourceCheckboxArea"}
                                                    className={
                                                        "checkbox-container wms-checkbox-include-resouce-container"
                                                    }
                                                >
                                                    <WmsCheckBox
                                                        id="IncludeResource"
                                                        name="IncludeResource"
                                                        onChangeFunc={this.handleIncludeResourceChangeEvent}
                                                        label="Include resource in payload"
                                                        checked={this.state.webhookFormData.IncludeResource}
                                                        isRounded={true}
                                                        disabled={
                                                            this.state.webhookFormData.Resource == "InventorySummary"
                                                        }
                                                        wmsSelector={this.props.selector + "IncludeResource"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {isEnableCommonResources ? (
                                            <div
                                                className={`wms-checkbox-group ${
                                                    !this.state.webhookFormData.IncludeResource ? "disabled" : ""
                                                }`}
                                            >
                                                <label className="label">Resource Options</label>
                                                {this.state.webhookFormData.Resource === "Order" ? (
                                                    <div className="resource-group-container">
                                                        <div className="check-div-60">
                                                            <div className="checkbox-container">
                                                                <WmsCheckBox
                                                                    id="SavedElements"
                                                                    name="SavedElements"
                                                                    onChangeFunc={
                                                                        this.handleIncludeResourceOptionChangeEvent
                                                                    }
                                                                    label="Order custom fields"
                                                                    checked={
                                                                        this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                            "SavedElements"
                                                                        ) !== -1
                                                                    }
                                                                    isRounded={true}
                                                                    disabled={
                                                                        !this.state.webhookFormData.IncludeResource
                                                                    }
                                                                    wmsSelector={
                                                                        this.props.selector +
                                                                        "IncludeResourcesOptionsSavedElements"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="checkbox-container">
                                                                <WmsCheckBox
                                                                    id="Packages"
                                                                    name="Packages"
                                                                    onChangeFunc={
                                                                        this.handleIncludeResourceOptionChangeEvent
                                                                    }
                                                                    label="Packages"
                                                                    checked={
                                                                        this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                            "Packages"
                                                                        ) !== -1
                                                                    }
                                                                    isRounded={true}
                                                                    disabled={
                                                                        !this.state.webhookFormData.IncludeResource
                                                                    }
                                                                    wmsSelector={
                                                                        this.props.selector +
                                                                        "IncludeResourcesOptionsPackages"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="checkbox-container">
                                                                <WmsCheckBox
                                                                    id="Contacts"
                                                                    name="Contacts"
                                                                    onChangeFunc={
                                                                        this.handleIncludeResourceOptionChangeEvent
                                                                    }
                                                                    label="Contacts"
                                                                    checked={
                                                                        this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                            "Contacts"
                                                                        ) !== -1
                                                                    }
                                                                    isRounded={true}
                                                                    disabled={
                                                                        !this.state.webhookFormData.IncludeResource
                                                                    }
                                                                    wmsSelector={
                                                                        this.props.selector +
                                                                        "IncludeResourcesOptionsContacts"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="checkbox-container">
                                                                <WmsCheckBox
                                                                    id="BillingDetails"
                                                                    name="BillingDetails"
                                                                    onChangeFunc={
                                                                        this.handleIncludeResourceOptionChangeEvent
                                                                    }
                                                                    label="Billing details"
                                                                    checked={
                                                                        this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                            "BillingDetails"
                                                                        ) !== -1
                                                                    }
                                                                    isRounded={true}
                                                                    disabled={
                                                                        !this.state.webhookFormData.IncludeResource
                                                                    }
                                                                    wmsSelector={
                                                                        this.props.selector +
                                                                        "IncludeResourcesOptionsBillingDetails"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="checkbox-container">
                                                                <WmsCheckBox
                                                                    id="OutboundSerialNumbers"
                                                                    name="OutboundSerialNumbers"
                                                                    onChangeFunc={
                                                                        this.handleIncludeResourceOptionChangeEvent
                                                                    }
                                                                    label="Outbound serial numbers"
                                                                    checked={
                                                                        this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                            "OutboundSerialNumbers"
                                                                        ) !== -1
                                                                    }
                                                                    isRounded={true}
                                                                    disabled={
                                                                        !this.state.webhookFormData.IncludeResource
                                                                    }
                                                                    wmsSelector={
                                                                        this.props.selector +
                                                                        "IncludeResourcesOptionsOutboundSerialNumbers"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="checkbox-container">
                                                                <WmsCheckBox
                                                                    id="SmallParcel"
                                                                    name="SmallParcel"
                                                                    onChangeFunc={
                                                                        this.handleIncludeResourceOptionChangeEvent
                                                                    }
                                                                    label="Small parcel"
                                                                    checked={
                                                                        this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                            "SmallParcel"
                                                                        ) !== -1
                                                                    }
                                                                    isRounded={true}
                                                                    disabled={
                                                                        !this.state.webhookFormData.IncludeResource
                                                                    }
                                                                    wmsSelector={
                                                                        this.props.selector +
                                                                        "IncludeResourcesOptionsSmallParcel"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="checkbox-container">
                                                                <WmsCheckBox
                                                                    id="ParcelOptions"
                                                                    name="ParcelOptions"
                                                                    onChangeFunc={
                                                                        this.handleIncludeResourceOptionChangeEvent
                                                                    }
                                                                    label="Parcel options"
                                                                    checked={
                                                                        this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                            "ParcelOptions"
                                                                        ) !== -1
                                                                    }
                                                                    isRounded={true}
                                                                    disabled={
                                                                        !this.state.webhookFormData.IncludeResource
                                                                    }
                                                                    wmsSelector={
                                                                        this.props.selector +
                                                                        "IncludeResourcesOptionsParcelOptions"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="check-div-40">
                                                            <div className="checkbox-container">
                                                                <WmsCheckBox
                                                                    id="OrderItems"
                                                                    name="OrderItems"
                                                                    onChangeFunc={
                                                                        this.handleIncludeResourceOptionChangeEvent
                                                                    }
                                                                    label="Order items"
                                                                    checked={
                                                                        this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                            "OrderItems"
                                                                        ) !== -1
                                                                    }
                                                                    isRounded={true}
                                                                    disabled={
                                                                        !this.state.webhookFormData.IncludeResource
                                                                    }
                                                                    wmsSelector={
                                                                        this.props.selector +
                                                                        "IncludeResourcesOptionsorderItems"
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                className={`list-view ${
                                                                    this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                        "OrderItems"
                                                                    ) !== -1
                                                                        ? ""
                                                                        : "disabled"
                                                                }`}
                                                            >
                                                                <div className="tree-checkbox-container">
                                                                    <div className="checkbox-container">
                                                                        <WmsCheckBox
                                                                            id="ItemSavedElements"
                                                                            name="ItemSavedElements"
                                                                            onChangeFunc={
                                                                                this
                                                                                    .handleIncludeResourceOptionChangeEvent
                                                                            }
                                                                            label="Item custom fields"
                                                                            checked={
                                                                                this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                                    "ItemSavedElements"
                                                                                ) !== -1
                                                                            }
                                                                            isRounded={true}
                                                                            disabled={
                                                                                !this.state.webhookFormData
                                                                                    .IncludeResource ||
                                                                                this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                                    "OrderItems"
                                                                                ) === -1
                                                                            }
                                                                            wmsSelector={
                                                                                this.props.selector +
                                                                                "IncludeResourcesOptionsItemSavedElements"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="tree-checkbox-container">
                                                                    <div className="checkbox-container">
                                                                        <WmsCheckBox
                                                                            id="Allocations"
                                                                            name="Allocations"
                                                                            onChangeFunc={
                                                                                this
                                                                                    .handleIncludeResourceOptionChangeEvent
                                                                            }
                                                                            label="Allocations"
                                                                            checked={
                                                                                this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                                    "Allocations"
                                                                                ) !== -1
                                                                            }
                                                                            isRounded={true}
                                                                            disabled={
                                                                                !this.state.webhookFormData
                                                                                    .IncludeResource ||
                                                                                this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                                    "OrderItems"
                                                                                ) === -1
                                                                            }
                                                                            wmsSelector={
                                                                                this.props.selector +
                                                                                "IncludeResourcesOptionsAllocations"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="tree-checkbox-container">
                                                                    <div className="checkbox-container">
                                                                        <WmsCheckBox
                                                                            id="AllocationsWithDetail"
                                                                            name="AllocationsWithDetail"
                                                                            onChangeFunc={
                                                                                this
                                                                                    .handleIncludeResourceOptionChangeEvent
                                                                            }
                                                                            label="Allocations with detail"
                                                                            checked={
                                                                                this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                                    "AllocationsWithDetail"
                                                                                ) !== -1
                                                                            }
                                                                            isRounded={true}
                                                                            disabled={
                                                                                !this.state.webhookFormData
                                                                                    .IncludeResource ||
                                                                                this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                                    "OrderItems"
                                                                                ) === -1
                                                                            }
                                                                            wmsSelector={
                                                                                this.props.selector +
                                                                                "IncludeResourcesOptionsAllocationsWithDetail"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {this.state.webhookFormData.Resource === "Receiver" ? (
                                                    <div className="resource-group-container">
                                                        <div className="check-div-60">
                                                            <div className="checkbox-container">
                                                                <WmsCheckBox
                                                                    id="SavedElements"
                                                                    name="SavedElements"
                                                                    onChangeFunc={
                                                                        this.handleIncludeResourceOptionChangeEvent
                                                                    }
                                                                    label="Receipt custom fields"
                                                                    checked={
                                                                        this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                            "SavedElements"
                                                                        ) !== -1
                                                                    }
                                                                    isRounded={true}
                                                                    disabled={
                                                                        !this.state.webhookFormData.IncludeResource
                                                                    }
                                                                    wmsSelector={
                                                                        this.props.selector +
                                                                        "IncludeResourcesOptionsSavedElements"
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="checkbox-container">
                                                                <WmsCheckBox
                                                                    id="BillingDetails"
                                                                    name="BillingDetails"
                                                                    onChangeFunc={
                                                                        this.handleIncludeResourceOptionChangeEvent
                                                                    }
                                                                    label="Billing details"
                                                                    checked={
                                                                        this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                            "BillingDetails"
                                                                        ) !== -1
                                                                    }
                                                                    isRounded={true}
                                                                    disabled={
                                                                        !this.state.webhookFormData.IncludeResource
                                                                    }
                                                                    wmsSelector={
                                                                        this.props.selector +
                                                                        "IncludeResourcesOptionsBillingDetails"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="check-div-40">
                                                            <div className="checkbox-container">
                                                                <WmsCheckBox
                                                                    id="ReceiveItems"
                                                                    name="ReceiveItems"
                                                                    onChangeFunc={
                                                                        this.handleIncludeResourceOptionChangeEvent
                                                                    }
                                                                    label="Receive items"
                                                                    checked={
                                                                        this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                            "ReceiveItems"
                                                                        ) !== -1
                                                                    }
                                                                    isRounded={true}
                                                                    disabled={
                                                                        !this.state.webhookFormData.IncludeResource
                                                                    }
                                                                    wmsSelector={
                                                                        this.props.selector +
                                                                        "IncludeResourcesOptionsReceiveItems"
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                className={`list-view ${
                                                                    this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                        "ReceiveItems"
                                                                    ) !== -1
                                                                        ? ""
                                                                        : "disabled"
                                                                }`}
                                                            >
                                                                <div className="tree-checkbox-container">
                                                                    <div className="checkbox-container">
                                                                        <WmsCheckBox
                                                                            id="ItemSavedElements"
                                                                            name="ItemSavedElements"
                                                                            onChangeFunc={
                                                                                this
                                                                                    .handleIncludeResourceOptionChangeEvent
                                                                            }
                                                                            label="Item custom fields"
                                                                            checked={
                                                                                this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                                    "ItemSavedElements"
                                                                                ) !== -1
                                                                            }
                                                                            isRounded={true}
                                                                            disabled={
                                                                                !this.state.webhookFormData
                                                                                    .IncludeResource ||
                                                                                this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                                    "ReceiveItems"
                                                                                ) === -1
                                                                            }
                                                                            wmsSelector={
                                                                                this.props.selector +
                                                                                "IncludeResourcesOptionsItemSavedElements"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                {this.state.webhookFormData.Resource === "OrderItem" ? (
                                                    <div className="wms-checkbox-group-container">
                                                        <div className="checkbox-container">
                                                            <WmsCheckBox
                                                                id="SavedElements"
                                                                name="SavedElements"
                                                                onChangeFunc={
                                                                    this.handleIncludeResourceOptionChangeEvent
                                                                }
                                                                label="Item custom fields"
                                                                checked={
                                                                    this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                        "SavedElements"
                                                                    ) !== -1
                                                                }
                                                                isRounded={true}
                                                                disabled={!this.state.webhookFormData.IncludeResource}
                                                                wmsSelector={
                                                                    this.props.selector +
                                                                    "IncludeResourcesOptionsSavedElements"
                                                                }
                                                            />
                                                        </div>
                                                        <div className="checkbox-container">
                                                            <WmsCheckBox
                                                                id="Allocations"
                                                                name="Allocations"
                                                                onChangeFunc={
                                                                    this.handleIncludeResourceOptionChangeEvent
                                                                }
                                                                label="Allocations"
                                                                checked={
                                                                    this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                        "Allocations"
                                                                    ) !== -1
                                                                }
                                                                isRounded={true}
                                                                disabled={!this.state.webhookFormData.IncludeResource}
                                                                wmsSelector={
                                                                    this.props.selector +
                                                                    "IncludeResourcesOptionsAllocations"
                                                                }
                                                            />
                                                        </div>
                                                        <div className="checkbox-container">
                                                            <WmsCheckBox
                                                                id="AllocationsWithDetail"
                                                                name="AllocationsWithDetail"
                                                                onChangeFunc={
                                                                    this.handleIncludeResourceOptionChangeEvent
                                                                }
                                                                label="Allocations with detail"
                                                                checked={
                                                                    this.state.webhookFormData?.IncludeResourceOptions.indexOf(
                                                                        "AllocationsWithDetail"
                                                                    ) !== -1
                                                                }
                                                                isRounded={true}
                                                                disabled={!this.state.webhookFormData.IncludeResource}
                                                                wmsSelector={
                                                                    this.props.selector +
                                                                    "IncludeResourcesOptionsAllocationsWithDetail"
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={window.translate("Label_Cancel")}
                            wmsSelector={this.props.selector + "DiscardExit"}
                            onClickFunc={this.props.onCloseWebHookModal}
                            buttonType="orange"
                            icon={"fa-ban"}
                        />
                        {!this.props.isNewWebhook && (
                            <WmsButton
                                label={window.translate("Label_Delete")}
                                wmsSelector={this.props.selector + "Delete"}
                                onClickFunc={this.onDeleteWebhookData}
                                disabled={this.props.webhookSaveChangesAndExit}
                                loading={this.props.webhookSaveChangesAndExit}
                                buttonType="red"
                                icon={"fa-trash"}
                            />
                        )}
                        <WmsButton
                            label={window.translate("Label_Save")}
                            wmsSelector={this.props.selector + "SaveExit"}
                            onClickFunc={this.saveWebhookData}
                            disabled={
                                this.props.webhookSaveChangesAndExit ||
                                this.state.webhookFormData.Name == "" ||
                                this.state.webhookFormData.Url == "" ||
                                this.state.webhookFormData.Resource == "" ||
                                !this.state.isValidURL ||
                                this.state.webhookFormData?.EventTypesArr.length == 0
                            }
                            loading={this.props.webhookSaveChangesAndExit}
                            icon={"fa-floppy-o"}
                        />
                    </div>
                </WmsModel>

                {this.state.isOpenDeleteConfirmModel && (
                    <WmsModel
                        id={this.props.windowId + "confirmDeletePackage"}
                        title={this.translate("Label_Confirm_Delete_Webhook")}
                        width={600}
                        height={200}
                        removeCloseAnimation={true}
                        class="confirm-remove-webhook-wrapper"
                        onCloseFunc={this.onCloseDeleteAllConfirmationModel}
                        customClass="wms-model-new-ui-wrapper"
                        isOpen={this.state.isOpenDeleteConfirmModel}
                    >
                        <div className="model-content-wrapper">
                            <label className="confirm-remove-msg">
                                {this.translate("Label_Confirm_Delete_Webhook_Confirm")}
                            </label>
                        </div>
                        <div className="footer-btn-holder">
                            <WmsButton
                                label={this.translate("Label_Cancel")}
                                wmsSelector={this.selector + "DeleteAllCancel"}
                                onClickFunc={this.onCloseDeleteAllConfirmationModel}
                                disabled={false}
                                loading={false}
                                buttonType="orange"
                                icon={"fa-ban"}
                            />
                            <WmsButton
                                label={this.translate("Label_Delete")}
                                wmsSelector={this.selector + "DeleteAllDelete"}
                                onClickFunc={this.onConfirmDeleteWebhookData}
                                buttonType="red"
                                disabled={false}
                                loading={this.state.deleteAkk}
                                icon={"fa-trash"}
                            />
                        </div>
                    </WmsModel>
                )}
            </div>
        );
    }
}

WebHookModel.defaultProps = {
    isNewWebhook: false,
    windowId: "",
    isOpen: false,
    selector: "",
    sharedWebhookModalData: {},
    sharedEventNotificationFilter: {},
    sharedCustomerOptions: {},
};

WebHookModel.propTypes = {
    isNewWebhook: PropTypes.bool,
    windowId: PropTypes.string,
    DeleteWebhookData: PropTypes.func.isRequired,
    onSaveWebhookData: PropTypes.func.isRequired,
    onCloseWebHookModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    selector: PropTypes.string,
    sharedWebhookModalData: PropTypes.object,
    sharedEventNotificationFilter: PropTypes.object,
    sharedCustomerOptions: PropTypes.object,
    webhookSaveChangesAndExit: PropTypes.func.isRequired,
};
