export const getInitialDynamicPrintMenu = (initialPrintMenuResponse, params, menuClickEvent) => {

        var translate = window.translate;
        var updatedPrintMenu = {
            PrintData: [],
        }

        if (initialPrintMenuResponse != null && typeof initialPrintMenuResponse != undefined && initialPrintMenuResponse.length > 0) {
            initialPrintMenuResponse.forEach((item, i) => {

                var fnName = params.fnNames[i];
                var isDisable = false;

                if (item.CategoryName != "Transaction record") {
                    isDisable = (params.SelectedReceipts != 1);
                }

                var tempObj = {
                    "name": item.CategoryName,
                    "value": [],
                    "disabled": isDisable || !params.Rights.hasOwnProperty('receipts.docs'),
                    "disabledText": params.Rights.hasOwnProperty('receipts.docs') ? translate('Label_Disable_Edit_Receipt_On_Multiple_Select') : translate('Label_Receipt_GenerateDocs_Access_Note'),
                    'isContextMenu': true,
                    'isButtonMenu': true,
                    "wmsSelector": params.Selector + item.RdlName,
                    "onClickFunc": menuClickEvent,
                    'wmsid': item.RdlName
                };

                item.Reports.forEach((report, i) => {
                    tempObj.onClickFunc = () => null;
                    tempObj.value.push({
                        "name": report.Name,
                        "value": [],
                        "disableText": translate('Label_Receipt_GenerateDocs_Access_Note'),
                        "disabled": !params.Rights.hasOwnProperty('receipts.docs'),
                        'isContextMenu': true,
                        'isButtonMenu': true,
                        "wmsSelector": params.Selector + fnName + (i + 1),
                        "onClickFunc": menuClickEvent,
                        'wmsid': fnName,
                        'runReport': report.TemplatedRunner,
                        'rdlName': item.RdlName,
                    });
                });

                updatedPrintMenu.PrintData.push(tempObj);
            });

            updatedPrintMenu.PrintData.unshift({
                "name": "Attach Files",
                "value": [],
                "disabled": (params.SelectedReceipts != 1 || params.isCanceled),
                "disabledText": (params.SelectedReceipts != 1) ? translate('Label_Disable_Edit_Receipt_On_Multiple_Select') : translate('Label_Disable_Attach_File_Receipt'),
                'isContextMenu': params.Rights.hasOwnProperty('receipts.attachdocs') && params.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled'),
                'isButtonMenu': params.Rights.hasOwnProperty('receipts.attachdocs') && params.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled'),
                "wmsSelector": params.Selector + "AttachFiles",
                "onClickFunc": menuClickEvent,
                'wmsid': "attachFiles"
            });
        }

        return updatedPrintMenu;

}