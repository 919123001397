import React, { useState, useCallback, useEffect } from "react";

import VirtualGridComponent from "../react/components_proto/VirtualGridComponent/VirtualGridComponent";
import { StringWms } from "../../assets/ts/StringWms";
import * as GlobalService from "../react/global_services";
import * as GridConfig from "./adjust-inventory-grid-configuration";
import PropTypes from "prop-types";
import * as AdjustInventoryServices from "./adjust-inventory.services";
import { AddEditColumnModel } from "../react/components/AddEditColumn/add-edit-columns.component";
import { ArrayWms } from "../../assets/ts/ArrayWms";
import { ConcurrencyManager } from "../../assets/vendor/concurrencyManager/concurrencyManager";
import { LoadingModel } from "../react/components/LoadingModel/LoadingModel";
import * as DynamicMenu from "../adjust-inventory/grid_dynamic_menu.virtual.jsx";
import * as findOrdersService from "../../app/react/orders/findOrders/findOrders.services.jsx";

import CreateNewInventoryAdjustmentModal from "./create-new-inventory-adjustment.modal.component";
import ReverseAdjustmentModalComponent from "./reverse-adjustment.modal.component";
import "./adjustInventory.scss";
import { AttachDocuments } from "../react/components/AttachDocuments/AttachDocuments";
import _ from "lodash";
var axios = require("axios");

interface gridFilterType {
    CustomerID: number;
    FacilityID: number;
    WarehouseTransactionID: number;
    ReferenceNum: string;
    StartDate: string;
    EndDate: string;
}

interface Props {
    wmsSelector: string;
    windowId: string;
    sharedFilterObject: gridFilterType;
}

declare var window: any;
const { $ } = window;

const gridId = "AdjustInventoryGrid";

const defaultInventoryAdjustmentData = {
    AdjustmentId: 0,
    CustomerIdentifier: { Id: 0 },
    FacilityIdentifier: { Id: 0 },
    ReferenceNum: "",
    PONumber: "",
    Notes: "",
    Uri: "",
    CreateInvoice: false,
    InvoiceCreationInfo: {
        SetInvoiceDate: true,
        UtcOffset: (new Date().getTimezoneOffset() / 60) * -1,
    },
    Billing: [],
    AdjustmentItems: [],
    IncludeCharges: false,
    FileSummariesUri: null,
};
let localKendoGrid = null;

const AdjustInventoryGrid: React.FC<Props> = (props: Props) => {
    const adjustInventoryGridHeaders = StringWms.convertSringToOption(window.translate("AdjustInventory_GridHeaders"));
    const defaultColumns = GridConfig.getAdjustInventoryColumns(adjustInventoryGridHeaders).columns;
    const allColumnList = GridConfig.getAdjustInventoryAllColumnList(adjustInventoryGridHeaders);
    const [schema] = useState(GridConfig.getAdjustInventorySchema());
    const gridDomSelector = props.windowId ? "." + props.windowId + " #" + gridId : "#" + gridId;
    const [gridConfig, setGridConfig] = useState("");
    let filterCountAxiosSource = null;
    let firstFiftyAxiosSource = null;
    let axiosSource = null;
    let isApiCanceled = false;
    let transactionIds = [];

    const [filterText, setFilterText] = useState("");
    const [fullFilterText, setFullFilterText] = useState("");
    const [recordsCount, setRecordsCount] = useState(0);
    const [gridData, setGridData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(defaultInventoryAdjustmentData);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [isOpenColumnModal, setIsOpenColumnModal] = useState(false);
    const [isAllDataLoad, setIsAllDataLoad] = useState(false);
    const [isFilterRowCountLoading, setIsFilterRowCountLoading] = useState(false);
    const [showLoadingError, setShowLoadingError] = useState(false);
    const [isNewColumnAdded, setIsNewColumnAdded] = useState(false);
    const [isGridDataLoading, setIsGridDataLoading] = useState(false);
    const [gridPageSize] = useState(GlobalService.constants.virtualGridDataLimit);
    const [overlayScreenShow, setOverlayScreenShow] = useState(false);
    const [totalRequest, setTotalRequest] = useState(0);
    const [isOpenAttachDoc, setIsOpenAttachDoc] = useState(false);
    const [sharedAttachObj, setSharedAttachObj] = useState(null);

    const [isOpenCreateNewInventoryAdjustmentModal, setIsOpenCreateNewInventoryAdjustmentModal] = useState(false);
    const [sharedIsOpenReverseAdjustmentModal, setSharedIsOpenReverseAdjustmentModal] = useState(false);

    const [applyingAdjustment, setApplyingAdjustment] = useState(false);
    const [isReversedAdjustment, setIsReversedAdjustment] = useState(false);

    const [menuPrintData, setMenuPrintData] = useState([]);
    const [initialPrintMenuResponse, setInitialPrintMenuResponse] = useState([]);
    const [isReportsLoading, setIsReportsLoading] = useState(false);
    const rights = GlobalService.GetRights();
    const tplSettings = GlobalService.getTplSettings();

    //componentDidMount
    useEffect(() => {
        getInitialPrintMenu();
    }, []);

    const onselectGridRow = (KendoGrid: any) => {
        localKendoGrid = KendoGrid;
        let grid = $(gridDomSelector).data("kendoGrid");
        var data = grid.dataItem(grid.select());
        var isClosed = KendoGrid.valueExists("Status", "2");

        if (data) {
            setIsReversedAdjustment(isClosed);
            setSelectedRow(data);
            setSelectedRowCount(KendoGrid.getNumberOfSelections());
            updateInitialPrintMenu();
        } else {
            setSelectedRow(defaultInventoryAdjustmentData);
            setSelectedRowCount(0);
        }
    };
    const clickEventOfContextMenu = (obj: any, e: any) => {
        if (obj.wmsid == "ViewTransaction") {
            dynamicServiceForDynamicMenu(e, "RunReportViewTransaction");
        } else if (obj.wmsid === "AttachFiles") {
            attachFiles(null);
        } else {
            onOpenReverselAdjustmentModal();
        }
    };

    const onGridDataBound = (e, kendoGrid) => {
        localKendoGrid = kendoGrid;
    };
    // on grid filter selection
    const onGridFilterApplied = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            let filter = dataSource.filter();
            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, filter);
            AdjustInventoryServices.setAdjustInventoryGridOptionInMetaData(finalSubmission);
            setFilterAppliedText(filter);
        }, 50);
    };
    const setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            setFilterText("");
            setFullFilterText("");
            return;
        }
        var filterConditionTitle = {
            lte: "Is Less Than Or Equal To",
            eq: "Is Equal To",
            lt: "Is Less Than",
            neq: "Is Not Equal To",
            gte: "Is Greater Than Or Equal To",
            gt: "Is Greater Than",
            startswith: "Starts With",
            endswith: "Ends With",
            contains: "Contains",
            doesnotcontain: "Does Not Contain",
            isnull: "Is Null",
            isnotnull: "Is Not Null",
            isempty: "Is Empty",
            isnotempty: "Is Not Empty",
            isnullorempty: "Has No Value",
            isnotnullorempty: "Has Value",
        };

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;

        filterList.map((item) => {
            var title = allColumnList.find((x) => x.field === item.field).title;
            filterText += title + " : " + item.value + ",";
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            return item;
        });
        filterText = filterText.slice(0, -1);
        setFilterText(filterText);
        setFullFilterText(fullFilterText);
    };
    // on grid column sort save changes in meta data object
    const onSort = (event) => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            gridColumn,
            sortArray,
            grid.dataSource.filter()
        );
        AdjustInventoryServices.setAdjustInventoryGridOptionInMetaData(finalSubmission);
    };
    // on grid column reorder save changes in meta data object
    const onColumnReorder = (event) => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            switchedArray,
            sortArray,
            grid.dataSource.filter()
        );
        AdjustInventoryServices.setAdjustInventoryGridOptionInMetaData(finalSubmission);
    };

    // on grid column resize save changes in meta data object
    const onColumnResize = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        AdjustInventoryServices.setAdjustInventoryGridOptionInMetaData(finalSubmission);
    };

    // on click grid clear sort menu
    const onClickClearSorts = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        AdjustInventoryServices.setAdjustInventoryGridOptionInMetaData(finalSubmission);
    };

    // on click grid reset default grid layout menu
    const onClickResetGrid = () => {
        var finalSubmission = {
            optionsData: {},
        };
        AdjustInventoryServices.setAdjustInventoryGridOptionInMetaData(finalSubmission);
    };

    const eventClickExportGrid = () => {
        $(gridDomSelector).getKendoGrid().saveAsExcel();
    };

    const getGridFilter = useCallback(() => {
        let GridFilterData = props.sharedFilterObject;

        if (GridFilterData.CustomerID !== 0) {
            let selectedCustomer = GlobalService.getCustomerById(GridFilterData.CustomerID);
            if (selectedCustomer == null) {
                GridFilterData.CustomerID = 0;
            }
        }
        return GridFilterData;
    }, [props.sharedFilterObject]);

    const listGrid_dataBind = (data) => {
        _.map(data, function (value) {
            value.TotalCharges = 0;
            _.map(value.Billing, function (subValue) {
                value.TotalCharges += subValue.TotalCharges;
                return value;
            });
            return data;
        });
        setGridData(data);
    };
    // on new filter data clear selection of grid
    const clearSelectionOfGrid = () => {
        if (localKendoGrid != null) {
            localKendoGrid.clearSelections(() => {
                localKendoGrid.repaintSelections();
            });
        }
    };

    const refreshGrid = () => {
        clearSelectionOfGrid();
        callForGetTotalCount();
    };

    const onToggleColumnModal = (isOpen) => () => {
        setIsOpenColumnModal(isOpen);
    };

    const saveMetaData = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, sort, grid.dataSource.filter());
        AdjustInventoryServices.setAdjustInventoryGridOptionInMetaData(finalSubmission);
    };

    useEffect(() => {
        console.log(AdjustInventoryServices.getAdjustInventoryGridOptionInMetaData());
        setGridConfig(AdjustInventoryServices.getAdjustInventoryGridOptionInMetaData());
        if (getGridFilter()) {
            callForGetTotalCount();
        }
    }, [props.sharedFilterObject]);

    useEffect(() => {
        if (recordsCount > 0) {
            callForGetNewData();
        }
    }, [recordsCount]);

    // Cancel axios call if new filter applied
    const cancelLoadAllData = () => {
        if (firstFiftyAxiosSource) {
            firstFiftyAxiosSource.cancel();
            firstFiftyAxiosSource = null;
        }
        if (axiosSource) {
            axiosSource.cancel();
            axiosSource = null;
        }
        isApiCanceled = true;
        setIsGridDataLoading(false);
        setOverlayScreenShow(false);
        setGridData([]);
        setIsAllDataLoad(false);
    };
    const callForGetTotalCount = (clearData = true, clearGridSelection = false) => {
        setRecordsCount(0);
        setIsFilterRowCountLoading(true);
        setShowLoadingError(false);

        if (clearGridSelection) {
            setSelectedRowCount(0);
            setIsAllDataLoad(false);
        }

        var param_filterObj = Object.assign({}, getGridFilter());
        param_filterObj.CustomerID = param_filterObj.CustomerID === 0 ? null : param_filterObj.CustomerID;
        param_filterObj.FacilityID = param_filterObj.FacilityID === 0 ? null : param_filterObj.FacilityID;
        param_filterObj.StartDate = param_filterObj.StartDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.StartDate, "", false, true).isoStringUTC
            : null;
        param_filterObj.EndDate = param_filterObj.EndDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.EndDate, "23:59:59", false, true).isoStringUTC
            : null;
        if (filterCountAxiosSource) {
            filterCountAxiosSource.cancel();
            filterCountAxiosSource = null;
        }
        // on filter update cancel axiosSource call if any running
        cancelLoadAllData();
        filterCountAxiosSource = axios.CancelToken.source();

        // Call for FilterRowCount.
        AdjustInventoryServices.setGridFiltere(param_filterObj, filterCountAxiosSource.token)
            .then((response: any) => {
                setRecordsCount(response.data);
                if (clearData) {
                    setGridData([]);
                }
                filterCountAxiosSource = null;
            })
            .finally(() => {
                setIsFilterRowCountLoading(false);
            });
    };
    const resetGridAllMessage = () => {
        if (isAllDataLoad) {
            setIsNewColumnAdded(true);
        }
    };

    const callForGetNewData = () => {
        if (recordsCount > 0 && recordsCount <= gridPageSize) {
            loadAllRecords(false);
        } else {
            resetGridAllMessage();
        }
    };
    const sequence = (n) => {
        let seq = [];
        for (let i = 0; i < n; i++) {
            seq.push(i);
        }
        return seq;
    };
    const getAllResponseData = (data) => {
        if (!isApiCanceled) {
            let allData = [];
            data.forEach((obj) => {
                allData = [...obj.Data, ...allData];
            });
            listGrid_dataBind(allData);
            setOverlayScreenShow(false);
            setIsGridDataLoading(false);
            setIsAllDataLoad(true);
            setIsNewColumnAdded(false);
        } else {
            setIsGridDataLoading(false);
        }
    };
    const loadAllRecords = (isLoaderPopupShow) => {
        isApiCanceled = false;
        if (isLoaderPopupShow) {
            setIsGridDataLoading(true);
            setGridData([]);
        }

        var param_filterObj = Object.assign({}, getGridFilter());
        param_filterObj.CustomerID = param_filterObj.CustomerID === 0 ? null : param_filterObj.CustomerID;
        param_filterObj.FacilityID = param_filterObj.FacilityID === 0 ? null : param_filterObj.FacilityID;
        param_filterObj.StartDate = param_filterObj.StartDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.StartDate, "", false, true).isoStringUTC
            : null;
        param_filterObj.EndDate = param_filterObj.EndDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.EndDate, "23:59:59", false, true).isoStringUTC
            : null;

        axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/Inventory/adjustments",
            headers: { "Access-Control-Allow-Origin": "*" },
            cancelToken: axiosSource.token,
        });
        const MAX_CONCURRENT_REQUESTS = 3;
        let totalRequest = Math.ceil(recordsCount / gridPageSize);
        setTotalRequest(totalRequest);
        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS, GlobalService);

        Promise.all(
            sequence(totalRequest).map((i) =>
                api.put("/AdjustmentDataLite?sorts=[]&filters=[]&pgnum=" + (i + 1) + "&pgsiz=" + gridPageSize, {
                    adjustmentSearchInfo: param_filterObj,
                })
            )
        )
            .then((responses) => {
                let inventoryData = responses.map((r) => r.data);
                getAllResponseData(inventoryData);
            })
            .catch((error) => {
                console.log("Promise.all catch", error);
                setShowLoadingError(true);
                cancelLoadAllData();
            })
            .then(() => {
                if (manager) {
                    manager.detach();
                }
            });
    };

    const onOpenCreateNewInventoryAdjustmentModal = () => {
        clearSelectionOfGrid();
        setSelectedRow(defaultInventoryAdjustmentData);
        setIsOpenCreateNewInventoryAdjustmentModal(true);
    };
    const onCloseCreateNewInventoryAdjustmentModal = () => {
        setIsOpenCreateNewInventoryAdjustmentModal(false);
    };
    const onOpenReverselAdjustmentModal = () => {
        setSharedIsOpenReverseAdjustmentModal(true);
    };
    const onCloseReverselAdjustmentModal = () => {
        setSharedIsOpenReverseAdjustmentModal(false);
    };

    const onApplyReverseAdjustment = (data) => {
        setApplyingAdjustment(true);
        AdjustInventoryServices.saveReverseAdjustment(selectedRow.Uri, data)
            .then((response) => {
                setSelectedRowCount(0);
                setSelectedRow(defaultInventoryAdjustmentData);
                setSharedIsOpenReverseAdjustmentModal(false);
                GlobalService.notification("success", window.translate("Reverse_Adjustment_Completed_Successfully"));
                refreshGrid();
            })
            .finally(() => {
                setApplyingAdjustment(false);
                kendo.ui.progress($("#ReverseAdjustmentModal"), false);
            });
    };
    const onApplyCreateAdjustment = (data: any) => {
        setApplyingAdjustment(true);
        AdjustInventoryServices.saveAdjustInventoryData(data)
            .then((response) => {
                setSelectedRowCount(0);
                setSelectedRow(defaultInventoryAdjustmentData);
                setIsOpenCreateNewInventoryAdjustmentModal(false);
                GlobalService.notification("success", window.translate("Create_Adjustment_Completed_Successfully"));
                refreshGrid();
            })
            .finally(() => {
                setApplyingAdjustment(false);
                kendo.ui.progress($("#CreateNewInventoryAdjustmentModel"), false);
            });
    };
    const onClickLoadAllRecords = () => {
        var param_filterObj = Object.assign({}, getGridFilter());
        param_filterObj.CustomerID = param_filterObj.CustomerID === 0 ? null : param_filterObj.CustomerID;
        param_filterObj.FacilityID = param_filterObj.FacilityID === 0 ? null : param_filterObj.FacilityID;
        param_filterObj.StartDate = param_filterObj.StartDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.StartDate, "", false, true).isoStringUTC
            : null;
        param_filterObj.EndDate = param_filterObj.EndDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.EndDate, "23:59:59", false, true).isoStringUTC
            : null;
        setOverlayScreenShow(true);

        if (firstFiftyAxiosSource) {
            firstFiftyAxiosSource.cancel();
            firstFiftyAxiosSource = null;
        }
        firstFiftyAxiosSource = axios.CancelToken.source();
        axios
            .put(
                "/WebUI/Inventory/adjustments/AdjustmentDataLite?sorts=[]&filters=[]&pgnum=1&pgsiz=50",
                { params: param_filterObj },
                { cancelToken: firstFiftyAxiosSource.token }
            )
            .then((response) => {
                firstFiftyAxiosSource = "";
                console.log("response", response);
                setGridData(response.data.Data);
                loadAllRecords(false);
            });
    };

    const updateInitialPrintMenu = () => {
        var params = {
            Selector: props.wmsSelector,
            Rights: rights,
            TplSettings: tplSettings,
            FullyAllocated: false,
            EnablePickOption: false,
            SameFacility: true,
            SameCustomer: true,
            SelectedOrders: 1,
            fnNames: ["", "", "", "RunReportViewTransaction"],
        };

        if (localKendoGrid) {
            transactionIds = [];
            let adjustmentIds = localKendoGrid.getKeys();

            adjustmentIds.forEach((key) => {
                var selectedItem = localKendoGrid.getItem(key);
                transactionIds.push(selectedItem.AdjustmentId);
            });
        }

        const updatedMenus = DynamicMenu.getInitialPrintMenu(
            initialPrintMenuResponse,
            params,
            dynamicMenuClick,
            "FindOrders"
        );

        setMenuPrintData(updatedMenus.PrintData);
    };

    const dynamicMenuClick = (event) => {
        var wmsid = $(event.target).parent().attr("data-wmsid");
        if (wmsid === "RunReportViewTransaction") {
            RunReportViewTransaction(event);
        } else {
            console.error("Uncaught TypeError: " + wmsid + " is not a function");
        }
    };

    const getInitialPrintMenu = () => {
        //setting to the first customer in the list for now until we talk to Jeff and come to a resolution
        var customerId = GlobalService.GetCurrentUser().CustomerIds[0];

        GlobalService.initialPrintMenu(customerId)
            .then((response) => {
                if (response) {
                    setInitialPrintMenuResponse(response);
                }
            })
            .finally(() => {});
    };

    const dynamicServiceForDynamicMenu = (event, fnName) => {
        setIsReportsLoading(true);
        var runReport = $(event.target).parent().attr("data-run-report");
        if (transactionIds.length > 0) {
            findOrdersService
                .dynamicServiceForDynamicMenu(fnName, runReport, transactionIds, null, "FindOrders")
                .then((response) => {
                    handleResponseMenuPrint(response);
                })
                .finally(() => setIsReportsLoading(false));
        } else {
            setIsReportsLoading(false);
        }
    };

    const handleResponseMenuPrint = (response) => {
        var blob = ArrayWms.convertBase64ToByteArray(response.Data, "application/pdf");
        ArrayWms.displayBlobInWindow(blob, "report.pdf");
    };

    const RunReportViewTransaction = (e) => {
        dynamicServiceForDynamicMenu(e, "RunReportViewTransaction");
    };

    const attachFiles = (e) => {
        var sharedAttachObj = {
            "uri": selectedRow.FileSummariesUri,
            "fileSummariesUri": selectedRow.FileSummariesUri,
            "getAttachedDocsInfoUrl":
                "/WebUI/Inventory/Adjustments/GetAttachedDocsInfo?fileSummariesUri=" + selectedRow.FileSummariesUri,
            "getAttachedDocsInfoPayload": {},
            "attachdocumentUrl":
                "/WebUI/Inventory/Adjustments/AttachDocsToAdjustment?fileSummariesUri=" + selectedRow.FileSummariesUri,
            "getAttachedDocsInfoMethod": "GET",
            "removeAttachDocUrl":
                "/WebUI/Inventory/Adjustments/DeleteAttachedDoc?fileSummariesUri=" + selectedRow.FileSummariesUri,
            "selectedOrderData": selectedRow,
            "customer": selectedRow.CustomerIdentifier?.Id,
            "transactionId": selectedRow.AdjustmentId,
        };
        setSharedAttachObj(sharedAttachObj);
        setIsOpenAttachDoc(true);
    };

    useEffect(() => {
        updateInitialPrintMenu();
    }, [initialPrintMenuResponse]);

    return (
        <div className={`adjust-customer-charges-grid`}>
            <VirtualGridComponent
                virtualGridDataLimit={gridPageSize}
                isNewColumnAdded={isNewColumnAdded}
                callForGetNewData={callForGetNewData}
                isAllDataLoad={isAllDataLoad}
                loadAllRecords={onClickLoadAllRecords}
                loading={isFilterRowCountLoading || isGridDataLoading || isReportsLoading}
                loadAllMessageFirst={window.translate("Label_Grid_Filter_Message")}
                loadAllMessageSecond={window.translate("Label_Grid_Filter_Message_2")}
                showLoadingError={showLoadingError}
                shawCheckbox={false}
                id={gridId}
                showSortColumns={true}
                showGridSummary={true}
                showGridResult={true}
                gridName={gridId}
                windowId={props.windowId}
                wmsSelector={props.wmsSelector + gridId}
                gridKeySelection={"RowNumber"}
                virtual={true}
                totalResult={recordsCount}
                staticGridData={gridData}
                exportable={true}
                isExportSelectedData={true}
                exportExcelObj={{
                    fileName:
                        "AdjustInventoryExport-" +
                        kendo.toString(kendo.parseDate(new Date().toString()), "yyyyMMddhhmmss") +
                        ".xlsx",
                    allPages: true,
                    filterable: true,
                }}
                csvExportFileName={"AdjustInventoryExport"}
                eventClickExportGrid={eventClickExportGrid}
                allColumns={allColumnList}
                defaultColumns={defaultColumns}
                schema={schema}
                onSelection={onselectGridRow}
                clickEventOfContextMenu={clickEventOfContextMenu}
                metaDataFieldsName={"AdjustInventory"}
                onGridFilterApplied={onGridFilterApplied}
                filterText={filterText}
                fullFilterText={fullFilterText}
                onDataBound={onGridDataBound}
                onSort={onSort}
                onColumnReorder={onColumnReorder}
                onColumnResize={onColumnResize}
                onClickClearSorts={onClickClearSorts}
                onClickResetGrid={onClickResetGrid}
                metaGridConfig={gridConfig}
                eventClickColumns={onToggleColumnModal(true)}
                menu={[
                    {
                        name: window.translate("Create_Adjustment"),
                        wmsSelector: props.wmsSelector + "CreateAdjustment",
                        value: [],
                        clickFunc: onOpenCreateNewInventoryAdjustmentModal,
                        disabled: false,
                        icon: "fa-plus",
                        color: "green",
                        isContextMenu: false,
                        isButtonMenu: true,
                    },
                    {
                        name: window.translate("Label_Attach_Files"),
                        wmsSelector: props.wmsSelector + "AttachFiles",
                        value: [],
                        clickFunc: attachFiles,
                        disabled: selectedRowCount !== 1,
                        icon: "fa-paperclip",
                        disabledText: "",
                        isContextMenu:
                            rights.hasOwnProperty("admin.attachfiles") &&
                            rights.hasOwnProperty("Shipping.AttachedDocsEnabled"),
                        isButtonMenu:
                            rights.hasOwnProperty("admin.attachfiles") &&
                            rights.hasOwnProperty("Shipping.AttachedDocsEnabled"),
                        wmsid: "AttachFiles",
                    },
                    {
                        name: window.translate("NavBarV1_Btn_Documents"),
                        wmsSelector: props.wmsSelector + "Documents",
                        value: menuPrintData,
                        clickFunc: (e) => null,
                        disabled: selectedRowCount !== 1,
                        icon: "fa-print",
                        color: "green",
                        isContextMenu: false,
                        isButtonMenu: true,
                    },
                    {
                        name: window.translate("Reverse_Adjustment"),
                        wmsSelector: props.wmsSelector + "ReverseAdjustment",
                        value: [],
                        clickFunc: onOpenReverselAdjustmentModal,
                        disabled: selectedRowCount !== 1 || isReversedAdjustment,
                        icon: "fa-trash",
                        color: "red",
                        isContextMenu: true,
                        isButtonMenu: true,
                    },
                    {
                        name: "",
                        wmsSelector: props.wmsSelector + "RefreshGrid",
                        value: [],
                        clickFunc: refreshGrid,
                        disabled: false,
                        icon: "fa-sync-alt",
                        color: "blue",
                        isContextMenu: false,
                        isButtonMenu: true,
                        parentClassName: "adjust-inventory-refresh-btn",
                    },
                ]}
            />
            {isOpenColumnModal && (
                <AddEditColumnModel
                    id={"addEditColumnEventNotification"}
                    height={700}
                    windowId={props.windowId}
                    onCloseColumnModel={onToggleColumnModal(false)}
                    isOpenColumnModel={isOpenColumnModal}
                    allSortColumnList={allColumnList}
                    gridId={gridId}
                    updateGridData={() => {}}
                    saveChangesInMetaData={saveMetaData}
                />
            )}
            {isOpenCreateNewInventoryAdjustmentModal && (
                <CreateNewInventoryAdjustmentModal
                    onCloseCreateNewInventoryAdjustmentModal={onCloseCreateNewInventoryAdjustmentModal}
                    isOpenCreateNewInventoryAdjustmentModal={isOpenCreateNewInventoryAdjustmentModal}
                    wmsSelector={props.wmsSelector}
                    selectedRow={selectedRow}
                    windowId={props.windowId}
                    onApplyCreateAdjustment={onApplyCreateAdjustment}
                    applyingAdjustment={applyingAdjustment}
                />
            )}
            {sharedIsOpenReverseAdjustmentModal && (
                <ReverseAdjustmentModalComponent
                    wmsSelector={props.wmsSelector}
                    onCloseCancelAdjustment={onCloseReverselAdjustmentModal}
                    sharedIsOpenReverseAdjustmentModal={true}
                    selectedRow={selectedRow}
                    onApplyReverseAdjustment={onApplyReverseAdjustment}
                    applyingAdjustment={applyingAdjustment}
                />
            )}
            {overlayScreenShow && (
                <LoadingModel
                    sharedOpenLoadingmodel={overlayScreenShow}
                    onCancelLoadAllData={cancelLoadAllData}
                    sharedTotalRequest={totalRequest}
                />
            )}
            {isOpenAttachDoc && (
                <AttachDocuments
                    isOpenAttachDoc={isOpenAttachDoc}
                    sharedAttachObj={sharedAttachObj}
                    windowId={props.windowId}
                    onClose={(e) => setIsOpenAttachDoc(false)}
                />
            )}
        </div>
    );
};
AdjustInventoryGrid.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    windowId: PropTypes.string.isRequired,
    sharedFilterObject: PropTypes.any.isRequired,
};

AdjustInventoryGrid.defaultProps = {
    wmsSelector: "",
    windowId: "",
    sharedFilterObject: {
        CustomerID: null,
        FacilityID: null,
        WarehouseTransactionID: null,
        ReferenceNum: null,
        StartDate: null,
        EndDate: null,
    },
};

export default React.memo(AdjustInventoryGrid);
