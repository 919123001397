import React, { useState, useEffect, useCallback} from 'react';
import * as ConfigurePackingSlipsService from './configure-packing-slips.services';
import * as GlobalService from '../../global_services';
import * as GridConfig from "./configure-packing-slips-grid-configuration"
import PackingSlipsConfigurationModal from './packing-slip-configuration-modal.component'
import  VirtualGridComponent  from '../../components_proto/VirtualGridComponent/VirtualGridComponent.jsx'

import './configurePackagingSlips.scss';
declare var window: any;
const { $ } = window;
var $rootScope = window;
const defaultFormData = {
  Description: '',
  ShowShippingNotes: false,
  Address1Label: "",
  Address1Source: -1,
  Address2Label: "",
  Address2Source: -1
}

const ConfigurePackingSlipsHolder: React.FC<any> = (props: any): any => {

    // const windowId = $rootScope.ChildBulletin.Data.Window;
    const selector = "ConfigurePackingSlips";
    const gridName = "ConfigurePackingSlipsGrid";

    const defaultColumns = GridConfig.getConfigurePackingSlipsColumns().columns;
    const allColumnList = GridConfig.getConfigurePackingSlipsAllColumnList();
    const [packagingTypesGridConfig, setGridConfig] = useState('');
    const [showGrid, setShowGrid] = useState(false);
    let localKendoGrid = null

    const [sharedIsOpenPackingSlipsModal, setSharedIsOpenPackingSlipsModal] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [recordsCount, setRecordsCount] = useState(0);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [selectedRow, setSelectedRow] = useState(defaultFormData);
    const [filterText, setFilterText] = useState('');
    const [fullFilterText, setFullFilterText] = useState('');
    const [windowId] = useState($rootScope.ChildBulletin.Data.Window);
    const [schema] = useState(GridConfig.getConfigurePackingSlipsSchema());
    const gridDomSelector = windowId ? '.' + windowId + ' #' + gridName : '#' + gridName;

    const loadGridData = useCallback(() => {
      kendo.ui.progress($(gridDomSelector), true);
      setRecordsCount(0);
      setGridData([]);

      ConfigurePackingSlipsService.getConfigurePackingSlipsGridData().then((response: any) => {
        setGridData(response.data.PackingListTemplateList);
        setRecordsCount(response.data.PackingListTemplateList.length);

      }).finally(() => {
        kendo.ui.progress($(gridDomSelector), false);
      })
    }, [gridDomSelector]);

    const afterDelete = () => {
      setSelectedRowCount(0);
      setSelectedRow(defaultFormData);
      loadGridData();
    }

    const refreshGrid = () => {
        loadGridData();
    }
    const onGridDataBound = (e, kendoGrid) => {
      localKendoGrid = kendoGrid
    }
    const onselectGridRow = (KendoGrid: any) => {
      let grid = $(gridDomSelector).data("kendoGrid");
      var data = grid.dataItem(grid.select());

      if (data) {
        setSelectedRow(data);
        setSelectedRowCount(KendoGrid.getNumberOfSelections());
      } else {
        setSelectedRow(defaultFormData);
        setSelectedRowCount(0);
      }
    }
    // on grid column sort save changes in meta data object
    const onSort = (event) => {
      var grid = $(gridDomSelector).data("kendoGrid");
      var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
      GlobalService.sortDataset(sortArray, event.sort);
      var gridColumn = grid.getOptions().columns;
      var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
      ConfigurePackingSlipsService.setConfigurePackingSlipsGridOptionInMetaData(finalSubmission);
    }

    // on grid column reorder save changes in meta data object
    const onColumnReorder = (event) => {
      }

    // on grid column resize save changes in meta data object
    const onColumnResize = () => {

    }

    // on click grid clear sort menu
    const onClickClearSorts = () => {

    }

    // on click grid reset default grid layout menu
    const onClickResetGrid = () => {

    }

    // on grid filter selection
    const onGridFilterApplied = () => {
      var grid = $(gridDomSelector).data("kendoGrid");
      var dataSource = grid.dataSource;
      setTimeout(() => {
        let filter = dataSource.filter()
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, filter);
        ConfigurePackingSlipsService.setConfigurePackingSlipsGridOptionInMetaData(finalSubmission);
        setFilterAppliedText(filter);
      }, 50);
    }
    const setFilterAppliedText = (filterObj) => {
      if (!filterObj) {
          setFilterText('');
          setFullFilterText('');
            return;
        }
        var filterConditionTitle = {
            "lte": "Is Less Than Or Equal To",
            "eq": "Is Equal To",
            "lt": "Is Less Than",
            "neq": "Is Not Equal To",
            "gte": "Is Greater Than Or Equal To",
            "gt": "Is Greater Than",
            "startswith": "Starts With",
            "endswith": "Ends With",
            "contains": "Contains",
            "doesnotcontain": "Does Not Contain",
            "isnull": "Is Null",
            "isnotnull": "Is Not Null",
            "isempty": "Is Empty",
            "isnotempty": "Is Not Empty",
            "isnullorempty": "Has No Value",
            "isnotnullorempty": "Has Value",
        }

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;

        filterList.map((item) => {
            var title = allColumnList.find(x => x.field === item.field).title;
            filterText += title + " : " + item.value + ',';
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            return item;
        });
        filterText = filterText.slice(0, -1);
        setFilterText(filterText);
        setFullFilterText(fullFilterText);
    }
    const clickEventOfContextMenu = (obj: any, e:any) => {
      editPackingSlipModal();
    }

    const createPackingSlipModal = () => {
      setSelectedRow(defaultFormData);
      setSharedIsOpenPackingSlipsModal(true);
    }
  const editPackingSlipModal = () => {
      let grid = $(gridDomSelector).data("kendoGrid");
      var data = grid.dataItem(grid.select());
      setSelectedRow(data);
      setSharedIsOpenPackingSlipsModal(true);
    }
    const onClosePackingSlipsModal = () => {
      setSharedIsOpenPackingSlipsModal(false);
    }

    // useEffect(() => {
    //   loadGridData();
    // },[loadGridData]);
    useEffect(()=> {
    },[])

    useEffect(() => {
      async function getGridMeta() {
         await ConfigurePackingSlipsService.getUserClientMetaData()
        setGridConfig(ConfigurePackingSlipsService.getConfigurePackingSlipsGridOptionInMetaData());
        setShowGrid(true)
        loadGridData();
      }
      getGridMeta();
    }, [loadGridData]);
    return (
      <div id="wms-configure-packing-slips" className="new-layout-grid-filter-wrapper">

        <div className='test'></div>
        {showGrid && (

          <VirtualGridComponent
          shawCheckbox={false}
          wmsSelector={selector + "Grid"}
          gridName={gridName}
          id={gridName}
          loading={false}
          showSortColumns={true}
          showGridResult={true}
          showGridSummary={true}
          staticGridData={gridData}
          totalResult={recordsCount}
          onSelection={onselectGridRow}
          gridKeySelection={'PackingListTemplateID'}
          showResetButton={false}
          onClickResetGrid={onClickResetGrid}
          onDataBound={onGridDataBound}
          onSort={onSort}
          virtual={true}
          metaDataFieldsName={'ManageConfigurePackingSlips'}
          metaGridConfig={packagingTypesGridConfig}
          defaultColumns={defaultColumns}
          allColumns={allColumnList}
          schema={schema}
          windowId={windowId}
          filterText={filterText}
          fullFilterText={fullFilterText}
          onColumnReorder={onColumnReorder}
          onColumnResize={onColumnResize}
          onClickClearSorts={onClickClearSorts}
          onGridFilterApplied={onGridFilterApplied}
          clickEventOfContextMenu={clickEventOfContextMenu}
          menu={
            [{
              "name": window.translate("Label_Create"),
              "value": [],
              "icon": "fa-plus",
              "color": "green",
              "wmsSelector": selector + "Create",
              'isContextMenu': false,
              "clickFunc": () => createPackingSlipModal(),
              'isButtonMenu': true,
            }, {
              "name": window.translate('Label_Edit'),
              "value": [],
              "disabled": selectedRowCount !== 1,
              "disabledText": "You may not add blahssss0",
              "icon": "fa-pencil",
              "color": "outlined",
              "wmsSelector": selector + "Edit",
              "clickFunc": () => editPackingSlipModal(),
              'isContextMenu': true,
              'isButtonMenu': true,
              'wmsid': 'packagingTypesEdit'
            }, {
              "name": '',
              "wmsSelector": selector + 'RefreshGrid',
              "value": [],
              "clickFunc": refreshGrid,
              "disabled": false,
              "icon": "fa-sync-alt",
              "color": "outlined",
              'isContextMenu': false,
              'isButtonMenu': true,
              'parentClassName': 'packing-slips-refresh-btn'
            }]
          }
          />
        )}
        {sharedIsOpenPackingSlipsModal &&
          <PackingSlipsConfigurationModal
            sharedIsOpenPackingSlipsModal={sharedIsOpenPackingSlipsModal}
            onClosePackingSlipsModal={() => void onClosePackingSlipsModal()}
            refreshGrid={() => void refreshGrid()}
            afterDelete={() => void afterDelete()}
            selectedRow={selectedRow}
          />}
      </div>
    )
}

export default React.memo(ConfigurePackingSlipsHolder);