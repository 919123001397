
var axios = require('axios');
import * as GlobalService from './../global_services';


export const searchInfoGet = function () {
    return axios.get('/WebUI/Customers/ManageCustomers/searchInfo').then(function (response) {
        return response;
    });
};

export const getEditCustomerData = function (editUri) {
    return axios.get('/WebUI/Customers/ManageCustomers/customer?editUri=' + editUri).then(function (response) {
        return response;
    });
};

export const updateCustomerActivation = function (list, mybool) {
    return axios.put('/WebUI/Customers/ManageCustomers/activate?activate=' + mybool, list).then(function (response) {
        return response;
    });
};

export const saveNewCustomer = function (CustomerData) {
    return axios.post('/WebUI/Customers/ManageCustomers/customer', CustomerData).then(function (response) {
        return response;
    });
};

export const cloneRates = function (sourceCustomerId, targetCustomerID) {
    return axios.put('/WebUI/Customers/ManageCustomers/CloneRates?srcCustId=' + sourceCustomerId + '&trgCustId=' + targetCustomerID).then(function (response) {
        return response;
    });
};

export const updateExistingCustomer = function (CustomerData) {
    return axios.put('/WebUI/Customers/ManageCustomers/customer', CustomerData).then(function (response) {
        return response;
    });
};