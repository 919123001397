import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsMultiDropdown.scss';

class WmsMultiDropdown extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            isHovering: false,
            options: props.options,
            dataSourceObj: props.dataSourceObj
        }
    }
    componentDidMount() {
        if (typeof $("#" + this.props.id).data('kendoMultiSelect') == "undefined" && (this.props.options.length !== 0 || this.props.isVirtualOptions)) {
            this.bindKendoMultiSelect();
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value != prevState.value) {
            if (typeof $("#" + nextProps.id).data("kendoMultiSelect") != "undefined") {
                $("#" + nextProps.id).data("kendoMultiSelect").value(nextProps.value);
                return { value: nextProps.value };
            }
        }

        if (nextProps.isVirtualOptions &&
            typeof nextProps.dataSourceObj.transport != "undefined" &&
            JSON.stringify(nextProps.dataSourceObj.transport.read.data) != JSON.stringify(prevState.dataSourceObj.transport.read.data)
        ) {
            var multiselect = $("#" + nextProps.id).data("kendoMultiSelect");
            if (typeof multiselect != "undefined" && multiselect.dataSource.transport.options) {
                multiselect.value('');
                multiselect.dataSource.transport.options.read.data = nextProps.dataSourceObj.transport.read.data;
                multiselect.dataSource.read();
            }
        }
        if (!nextProps.isVirtualOptions && nextProps.options != prevState.options) {
            if (typeof $("#" + nextProps.id).data("kendoMultiSelect") != "undefined") {
                var multiselect = $("#" + nextProps.id).data("kendoMultiSelect");
                var newDataSource = new kendo.data.DataSource({
                    data: nextProps.options
                });
                multiselect.setDataSource(newDataSource);
                $("#" + nextProps.id).data("kendoMultiSelect").setDataSource(nextProps.options);
                return { options: nextProps.options };
            }
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        var that = this;
        if (typeof $("#" + this.props.id).data('kendoMultiSelect') == "undefined") {
            this.bindKendoMultiSelect();
        }
    }
    bindKendoMultiSelect() {
        var that = this;
        let obj = {
            dataSource: this.props.options,
            dataTextField: this.props.textField,
            dataValueField: this.props.valueField,
            value: this.props.value,
            tagTemplate: this.props.tagTemplate,
            tagMode: 'single',
            change: function (e) {
                that.props.onChangeFunc($("#" + that.props.id).val());
            },
            dataBound: function (event) {
                that.props.onDataBound(event);
            }
        }
        if (this.props.isVirtualOptions) {
            obj.dataSource = this.props.dataSourceObj;
            obj.virtual = this.props.virtualObj;
        }
        $("#" + this.props.id).kendoMultiSelect(obj);
    }
    handleMouseHover = () => {
        this.setState({ isHovering: true });
    }
    handleMouseLeave = () => {
        this.setState({ isHovering: false });
    }
    render() {
        return (
            <div className={"wms-multi-dorpdown-list " + this.props.parentClassName}
                onMouseEnter={this.handleMouseHover}
                onMouseLeave={this.handleMouseLeave}>
                {this.props.label != "" && (
                    <label htmlFor={this.props.id}>
                        {this.props.label}
                    </label>
                )}
                <select id={this.props.id}
                    name={this.props.name}
                    data-wms-selector={this.props.wmsSelector}
                    onChange={this.props.onChangeFunc}
                    // value={this.props.value}
                    multiple={true}
                    disabled={this.props.disabled}>
                    {/* {this.props.options.map((Option, index) => (
                        <option key={index} value={Option[this.props.valueField]}>{Option[this.props.textField]}</option>
                    ))} */}
                </select>
                {
                    this.props.showToolTip == true &&
                    (<div>
                        {this.state.isHovering == true && <div className="div_tooltip">{this.props.tooltipText}</div>}
                    </div>)
                }
            </div>
        );
    }

}
WmsMultiDropdown.propTypes = {
    id: PropTypes.string.isRequired, // this should write the id to the input
    name: PropTypes.string.isRequired, // this should write the name to the input
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array
    ]), // this should control the value of multiSelect Dropdown
    parentClassName: PropTypes.string, //    this should parent tag class
    options: PropTypes.array,// this should a option of dropdown
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
    disabled: PropTypes.bool,//this should set dropdown disable
    isVirtualOptions: PropTypes.bool,// this should be true when option from virtual api
    dataSourceObj: PropTypes.object,// this contain transport object
    virtualObj: PropTypes.object,// this contain value mapper object
    template: PropTypes.string,// this should conatin customer display structure in dropdown
    onDataBound: PropTypes.func,// fire event on data bound,
    label: PropTypes.string,
    showToolTip: PropTypes.bool,
    tooltipText: PropTypes.string,
    tagTemplate: PropTypes.string // set the custom template for selected value, it's default template is '1 item(s) selected'
}

WmsMultiDropdown.defaultProps = {
    disabled: false,
    options: [],
    parentClassName: "form-group",
    isVirtualOptions: false,
    showToolTip: false,
    tooltipText: '',
    template: '',
    label: '',
    onDataBound: (e) => function () { },
    tagTemplate: ''
}
export default WmsMultiDropdown;