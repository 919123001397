import * as GlobalService from "../global_services";
var isFirstTimeOpen = true;
var keyName = "AttachFileAssembly";
var metaDataOrderObject = {
  AttachFileAssembly: {
    ManageAttachFileAssembly: {
      filterData: {},
      gridConfig: {},
      collapseData: {},
      sorableSection: "",
      saveFilter: [],
    }
  }
};

// call api for get metaData of order is it's open first time
export const getUserClientMetaData = function () {
  if (isFirstTimeOpen) {
    return GlobalService.getUserClientMetaData(keyName).then(
      function successCallBack(response) {
        if (response != "") {
          Object.assign(metaDataOrderObject[keyName], JSON.parse(response));
        }
        console.log("response  metaDataOrderObject :", metaDataOrderObject);
        isFirstTimeOpen = false;
        return true;
      },
      function errorCallBack(response) {
        console.log(response);
        return false;
      }
    );
  }
};

export const clearAttachFileAssemblyFilterMetaData = function (objName) {
  metaDataOrderObject[keyName]["ManageAttachFileAssembly"]["filterData"] = {};
  GlobalService.setUserClientMetaData(
    keyName,
    JSON.stringify(metaDataOrderObject[keyName])
  );
};

export const setAttachFileAssemblyFilterMetaData = function (filterData) {
  metaDataOrderObject[keyName]['ManageAttachFileAssembly']['filterData'] = filterData;
  GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const getAttachFileAssemblyFilterMetaData = function () {
  if (!$.isEmptyObject(metaDataOrderObject[keyName]['ManageAttachFileAssembly']['filterData'])) {
    return metaDataOrderObject[keyName]['ManageAttachFileAssembly']['filterData'];
  } else {
    return "";
  }

}

export const setManageAttachFileAssemblyGridOptionInMetaData = function (
  gridConfig
) {
  metaDataOrderObject[keyName]["ManageAttachFileAssembly"]["gridConfig"] =
    gridConfig.optionsData;
  GlobalService.setUserClientMetaData(
    keyName,
    JSON.stringify(metaDataOrderObject[keyName])
  );
};
export const getManageAttachFileAssemblyGridOptionInMetaData = function () {
  if (
    !$.isEmptyObject(
      metaDataOrderObject[keyName]["ManageAttachFileAssembly"]["gridConfig"]
    )
  ) {
    return metaDataOrderObject[keyName]["ManageAttachFileAssembly"]["gridConfig"];
  } else {
    return "";
  }
};

export const setManageAttachFileAssemblyCollapseMetaData = function (collapseId, value) {
  if (!metaDataOrderObject[keyName]['ManageAttachFileAssembly']['collapseData']) {
    metaDataOrderObject[keyName]['ManageAttachFileAssembly']['collapseData'] = {}
  }
  metaDataOrderObject[keyName]['ManageAttachFileAssembly']['collapseData'][collapseId] = value;
  GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const getManageAttachFileAssemblyCollapseMetaData = function () {
  return metaDataOrderObject[keyName]['ManageAttachFileAssembly']['collapseData'];
}

export const setManageAttachFileAssemblySortSectionMetaData = function (sortArray) {
  metaDataOrderObject[keyName]['ManageAttachFileAssembly']['sorableSection'] = sortArray;
  GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const getManageAttachFileAssemblySortSectionMetaData = function () {
  return metaDataOrderObject[keyName]['ManageAttachFileAssembly']['sorableSection'];
}

// save filter of find order in meta data
export const saveManageAttachFileAssemblyFilterMetaData = function (title, filterObj, columnObj) {
  var setData = {
    title: title,
    filterData: filterObj,
    columnData: columnObj.optionsData
  };

  if (typeof metaDataOrderObject[keyName]['ManageAttachFileAssembly']['saveFilter'] == "undefined") {
    metaDataOrderObject[keyName]['ManageAttachFileAssembly']['saveFilter'] = [];
  }
  (metaDataOrderObject[keyName]['ManageAttachFileAssembly']['saveFilter']).push(setData);
  GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));

}
export const updateManageAttachFileAssemblyFilterMetaData = function (index, filterObj, columnObj) {

  if (typeof metaDataOrderObject[keyName]['ManageAttachFileAssembly']['saveFilter'][index] != "undefined") {
    metaDataOrderObject[keyName]['ManageAttachFileAssembly']['saveFilter'][index].filterData = filterObj;
    metaDataOrderObject[keyName]['ManageAttachFileAssembly']['saveFilter'][index].columnData = columnObj.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
  }

}
export const getSaveManageAttachFileAssemblyFilterListMetaData = function () {
  if (metaDataOrderObject[keyName]['ManageAttachFileAssembly']['saveFilter']) {
    return metaDataOrderObject[keyName]['ManageAttachFileAssembly']['saveFilter'];
  }

  return [];
}

export const clearSaveManageAttachFileAssemblyFilterListMetaData = function (index) {
  metaDataOrderObject[keyName]['ManageAttachFileAssembly']['saveFilter'].splice(index, 1);
  GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}