import React,
{ Component } from 'react'
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';

export class DHLeCommerceAccountInfoComponent extends Component {
    constructor(props) {
        super(props);
        
        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            selectedDistributionCenterIndex: null,
            distributionCenterOption : [
                {name:"USATL1", value:"USATL1"},
                {name:"USBOS1", value:"USBOS1"},
                {name:"USBWI1", value:"USBWI1"},
                {name:"USCVG1", value:"USCVG1"},
                {name:"USDEN1", value:"USDEN1"},
                {name:"USDFW1", value:"USDFW1"},
                {name:"USEWR1", value:"USEWR1"},
                {name:"USLAX1", value:"USLAX1"},
                {name:"USMCO1", value:"USMCO1"},
                {name:"USMEM1", value:"USMEM1"},
                {name:"USORD1", value:"USORD1"},
                {name:"USPHX1", value:"USPHX1"},
                {name:"USRDU1", value:"USRDU1"},
                {name:"USSEA1", value:"USSEA1"},
                {name:"USSFO1", value:"USSFO1"},
                {name:"USSLC1", value:"USSLC1"},
                {name:"USSTL1", value:"USSTL1"},
                {name:"CNYYZ1", value:"CNYYZ1"}
            ]
        }
    }

    handleSelectChange = (e) => {
        this.setState({
            selectedDistributionCenterIndex: e.target.value
        })
        this.props.handleConnectionObjChange(e);
    }

    render() {
        return (
            <WmsCollapse
                id={"carrierAccountInformation"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id="accountNickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_Account_Nick_Name")}
                            value={this.props.connectionObj.Nickname}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="client_id"
                            inputName="ClientId"
                            wmsSelector={this.props.selector + "clientId"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_DHL_Client_Id")}
                            value={this.props.connectionObj.ClientId}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                    </div>

                    <div>
                        <WmsInput
                            id="username"
                            inputName="Username"
                            wmsSelector={this.props.selector + "username"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_Username")}
                            value={this.props.connectionObj.Username}
                            required={true}
                        />
                        <WmsInput
                            id="passphrase"
                            inputType={"password"}
                            inputName="Passphrase"
                            wmsSelector={this.props.selector + "passphrase"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Login_Lbl_Password")}
                            value={this.props.connectionObj.Passphrase}
                            required={true}
                        />
                    </div>

                    <div>
                        <WmsInput
                            id="pickup_number"
                            inputName="PickupNumber"
                            wmsSelector={this.props.selector + "pickupNumber"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_Pickup_Number")}
                            value={this.props.connectionObj.PickupNumber}
                            required={true}
                        />

                        <WmsDropdown
                            id="distribution_center"
                            name="DistributionCenter"
                            label={this.translate('Label_Distribution_Center')}
                            wmsSelector={this.state.selector + "distributionCenter"}
                            onChangeFunc={this.handleSelectChange}
                            value={this.state.selectedDistributionCenterIndex}
                            options={this.state.distributionCenterOption}
                            valueField='value'
                            textField='name'
                            blankFirstOption={true}
                            required={true}
                        />
                    </div>

                    <WmsInput
                        id="api_key"
                        inputName="ApiKey"
                        wmsSelector={this.props.selector + "apiKey"}
                        onChangeFunc={this.props.handleConnectionObjChange}
                        label={this.translate("Label_API_Key")}
                        value={this.props.connectionObj.ApiKey}
                        extraWrapperClass="w-75"
                    />
                    <WmsInput
                        id="api_secret"
                        inputName="ApiSecret"
                        wmsSelector={this.props.selector + "apiSecret"}
                        onChangeFunc={this.props.handleConnectionObjChange}
                        label={this.translate("Label_API_Secret")}
                        value={this.props.connectionObj.ApiSecret}
                        extraWrapperClass="w-75"
                    />
                </div>
            </WmsCollapse>
        )
    }
}
