import React from 'react';

//component
import WmsButton from '../../../../components/WmsButton/WmsButton.jsx';
import { StringWms } from '../../../../../../assets/ts/StringWms.ts';
import { ObjectWms } from '../../../../../../assets/ts/ObjectWms.ts';
import WmsInput from '../../../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from '../../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsDateTimePicker from '../../../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import WmsCustomDateTimePicker from '../../../../components/WmsCustomDateTimePicker/WmsCustomDateTimePicker.jsx';
import WmsCollapse from '../../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsModel from '../../../../components/WmsModel/WmsModel.jsx';
import WmsContainer from '../../../../components/WmsContainer/WmsContainer.jsx';
import WmsConfirmModel from '../../../../components/WmsConfirmModel/WmsConfirmModel.jsx';

import style from './proto-mass-add.scss';

//services
import * as GridConfig from './grid_configuration.jsx';
import * as GlobalService from '../../../../global_services';
import * as metaDataService from './meta-data.service.js';

export class ProtoMassAddSearch extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        this.$rootScope = window;
        this.manageInventoryGridId = "GridManageInventory";
        this.inventory_GridHeaders = StringWms.convertSringToOption(this.translate('Mass_Add_GridHeaders'));

        this.defaultFilter = {
            IndexOptionsSkuOrUpc:0,
            IndexOptionsQualifier:0,
            IndexOptionsCustomField:0,
            IndexOptionsSerialNumber:0,
            IndexOptionsSupplier:0,
            IndexOptionsLotNumber:0,
            IndexOptionsLocation:0,
            IndexOptionsSkuDescOrSecondaryDesc:0,
            IndexOptionsReceived:0,
            IndexOptionsAvailable:0,
            IndexOptionsOnHand:0,
            IndexOptionsSecondaryReceived:0,
            IndexOptionsSecondaryAvailable:0,
            IndexOptionsPrimaryUnitType:0,
            IndexOptionsSecondaryUnitType:0,
            IndexOptionsPallet:0,
            IndexOptionsTrailerContainerNumber:0,
            IndexOptionsCost:0,
            IndexOptionsReceiptRefId:0,
            IndexOptionsPoNumber:0
        };

        this.state = {
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.manageInventoryGridId : '#' + this.manageInventoryGridId,
            CustomLabels: GlobalService.getCustomLabels(),
            searchOn: false,
            Selector: 'ProtoMassAdd',
            optionConatins: GlobalService.getFilterOptions('string'),
            numericOptionConatins: GlobalService.getFilterOptions('number'),
            booleanOption: StringWms.convertSringToOption(this.translate('Options_Bool')),
            optionsSearchBy: StringWms.convertSringToOption(this.translate('Options_Inventory_Date')),
            filter: this.defaultFilter,
            isExpandedCollapse: (!$.isEmptyObject(metaDataService.getMassAddCollapseMetaData()) ? metaDataService.getMassAddCollapseMetaData() : []),
            isOpenSaveFilterModel: false,
            newFilterTitle: "",
            filterNameExist: false,
            allColumnList: GridConfig.getAllColumnList(this.inventory_GridHeaders).columns,
            savedFilterList: metaDataService.getSaveMassAddFilterListMetaData(),
            savedFilterIndex: null,
            isOpenConfirmModel: false,
            sectionToggled: false,
            showTooltip: false
        };
        this.onFilterDataSet = GlobalService.debounce(this.onFilterDataSet, 700);
    }

    componentDidMount() {
        if (this.props.sharedInventoryFilterObj && (JSON.stringify(this.defaultFilter) != JSON.stringify(this.props.sharedInventoryFilterObj))) {
            this.setState({
                filter: this.props.sharedInventoryFilterObj,
                searchOn: true
            });
        }

        var sortable = $('#mass-add-filter-section-sortable');

        if(sortable.length && !sortable.hasClass('ui-sortable')){
            var sortData = metaDataService.getMassAddSortSectionMetaData();
            // set saved sort data if any in meta data
            if (sortData) {
                var pos = JSON.parse(sortData);
                if (pos) {
                    $.each(pos, function (i, position) {
                        if(position !=""){
                            var $target = sortable.find('#' + position);
                            $target.appendTo(sortable); // or prependTo
                        }
                    });
                }
            }

            sortable.sortable({
                update: this.onSectionSort,
                cancel: "label.icon_label,.wms-collapsible-content",
                handle: ".wms-sort",
                distance: 5,
                start: function (e, ui) {
                    var height = ui.item.height();
                    if (height > 100) {
                        height = 100;
                    }
                    ui.placeholder.height(height);
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    filterHandleChange = (event,isConvertToNum) => {
        let filterData = Object.assign({}, this.state.filter);
        let filterKey = event.target.name;
        let filterVal = event.target.value;
        if(isConvertToNum){
            filterVal = parseInt(filterVal);
        }
        // if (filterKey == "StartDate" || filterKey == "EndDate") {
        //     filterVal = kendo.toString(kendo.parseDate(filterVal), "M/d/yyyy h:mm tt");
        // }

        if (filterVal) {
            filterData[filterKey] = filterVal;
        } else {
            delete filterData[filterKey];
        }

        this.setState({
            filter: filterData,
        }, () => {
            this.onFilterDataSet();
        });
    }

    onFilterDataSet = () => {
        let filterObj = Object.assign({}, this.state.filter);
        let isSearchOn = false;

        if ((JSON.stringify(this.defaultFilter) != JSON.stringify(filterObj))) {
            isSearchOn = true;
        }

        this.setState({
            searchOn: isSearchOn
        });
        this.props.onInventoryFilterObjChange(filterObj);
    }

    clearFilters = () => {
        this.setState({
            filter: this.defaultFilter,
            searchOn: false
        });
        this.props.onInventoryFilterObjChange(this.defaultFilter);
    }

    setCollapseMetaData = (collapseId) => (isExpanded) => {
        metaDataService.setMassAddCollapseMetaData(collapseId, isExpanded);
    }

    onSectionSort = () => {
        var sortArray = JSON.stringify($('#mass-add-filter-section-sortable').sortable("toArray"));
        metaDataService.setMassAddSortSectionMetaData(sortArray);
    }

    onOpenSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: true,
            newFilterTitle: "",
            filterNameExist: false
        });
    }
    exitSaveFilterModel = () => {
        this.setState({
            isOpenSaveFilterModel: false
        });
    }
    onSaveFilter = (indexForUpdate = null) => {
        let filterObj = Object.assign({}, this.state.filter);
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var filterArray = grid.dataSource.filter() ? grid.dataSource.filter() : [];
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = GlobalService.prepareGridOptionsForStorage(gridColumn, sortArray, filterArray);

        if (indexForUpdate != null) {
            metaDataService.updateMassAddFilterMetaData(indexForUpdate, filterObj, finalSubmission);
            GlobalService.notification('success',this.translate('Order_Filter_Update_success'));
        } else {
            if (!this.state.filterNameExist) {
                metaDataService.saveMassAddFilterMetaData(this.state.newFilterTitle, filterObj, finalSubmission);
                this.exitSaveFilterModel();
            }
        }
    }

    handleFilterTitleChange = (e) => {
        let value = e.target.value;
        if (value.Description == '') {
            value = '';
        }

        var nameExists = false;
        // check dublicate title for case sensitive
        var exists = this.state.savedFilterList.findIndex(function (item) {
            return value.toLowerCase() === item.title.toLowerCase();
        });
        if (exists != -1) {
            nameExists = true;
        }
        this.setState({
            newFilterTitle: value,
            filterNameExist: nameExists
        });
    }

    onClearSaveFilter = (index) => {
        this.setState({
            isOpenConfirmModel: true,
            savedFilterIndex: index
        });
    }
    onYesRemoveSavedFilter = () => {
        metaDataService.clearSaveMassAddFilterListMetaData(this.state.savedFilterIndex);
        this.setState({
            isOpenConfirmModel: false,
            savedFilterList: metaDataService.getSaveMassAddFilterListMetaData()
        });
    }
    ClickExit = () => {
        this.setState({
            isOpenConfirmModel: false
        });
    }

    onUseSaveFilter = (index) => {
        var objFilter = Object.assign({}, this.state.savedFilterList[index]);
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var gridConfig = JSON.parse(objFilter.columnData, JSON.dateParser);
        var tempObject = "";

        if (gridConfig.columns) {
            var fieldArray = gridConfig.columns.map(el => el.field);
            var updateColumn = [];
            this.state.allColumnList.filter((column) => {
                if (fieldArray.indexOf(column.field) !== -1) {
                    updateColumn.push(column);
                }
            });
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, updateColumn, "field");
        } else {
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, this.props.defaultColumns, "field");
        }
        grid.setOptions({
            columns: tempObject
        });
        grid.dataSource.sort(gridConfig.sorts);
        grid.dataSource.filter(gridConfig.filters);

        this.setState({
            filter: objFilter.filterData
        }, () => {
            this.onFilterDataSet();
        });
    }

    onClickSectionHandle = () => {
        this.setState(
            {
            sectionToggled: true
            },
            this.props.onClickForFilterToggle
        );
    };

    showFilterTooltip = () => {
        this.setState({ showTooltip: true });
    }
    hideFilterTooltip = () => {
        this.setState({ showTooltip: false });
    }

    render() {
        return (
            <div className={`manage-inventory-search-holder ${ this.state.sectionToggled ? " sectionToggled " : "" }`}>
                <div className={"filter"} onClick={ this.onClickSectionHandle } >
                    <i className="fa fa-caret-left" aria-hidden="true"></i>
                    <span>search filters</span>
                </div>
                <div className="inventory-filter">
                    <div className="filter-wrapper">
                        <div className="btn-section">
                            <WmsButton
                                label={this.translate('Label_Refresh_Button')}
                                wmsSelector={this.state.Selector + 'GridRefreshButton'}
                                onClickFunc={this.props.onRefreshGrid}
                                icon='fa-sync-alt' />
                            <WmsButton
                                label={this.translate('Label_Clear_Filters')}
                                buttonType='yellow'
                                wmsSelector={this.state.Selector + 'ClearSearchButton'}
                                onClickFunc={this.clearFilters}
                                disabled={!this.state.searchOn}
                                icon='fa-minus' />
                            <WmsButton
                                label={this.translate('Label_Save_View')}
                                buttonType='black'
                                wmsSelector={this.state.Selector + 'SaveFilterButton'}
                                onClickFunc={this.onOpenSaveFilterModel}
                                icon='fa-plus'
                            />
                        </div>

                        <div className="title">
                            {this.state.searchOn == true &&
                                <div><b>{this.translate('Label_NOTE')}: </b><label >{this.translate('Label_Search_Applied_Filter')}</label></div>
                            }
                        </div>

                        <div className={this.state.searchOn == true ? "warehouse-form-boxes width-title " : "warehouse-form-boxes"}>
                            <div className="warehouse-are-content" id="mass-add-filter-section-sortable">
                                {this.state.savedFilterList.length !== 0 && (
                                    <WmsCollapse id={'inventory_search_collapse0'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse0') ? !this.state.isExpandedCollapse['inventory_search_collapse0'] : true} showDragIcon={true} headerLabel={this.translate('Label_Saved_Filter_List')} showCollapseText={false} setCollapseMetaData={this.setCollapseMetaData('inventory_search_collapse0')}>
                                        <ul className="filter-list-block">
                                            {this.state.savedFilterList.map((data, index) => (
                                                <li className="filter-list" key={index}>
                                                    <a className="title-text" title={data.title}>{data.title}</a>
                                                    <a className="clear-save-filter" onClick={(e) => this.onClearSaveFilter(index)}><i className="far fa-times-circle" aria-hidden="true"></i></a>

                                                    <div className="wms-apply-btn">
                                                        <WmsButton
                                                            label={this.translate('RoleManager_Label_Update')}
                                                            buttonType='green'
                                                            wmsSelector={this.state.Selector + 'UpdateFilterButton'}
                                                            onClickFunc={(e) => this.onSaveFilter(index)}
                                                            icon='' />
                                                        <WmsButton
                                                            label={this.translate('Label_Apply')}
                                                            buttonType='green'
                                                            wmsSelector={this.state.Selector + 'ApplyFilterButton'}
                                                            onClickFunc={(e) => this.onUseSaveFilter(index)}
                                                            icon='' />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </WmsCollapse>
                                )}

                                <WmsCollapse id={'inventory_search_collapse1'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse1') ? !this.state.isExpandedCollapse['inventory_search_collapse1'] : true} showDragIcon={true} headerLabel={this.translate('Label_Inventory_Search_By_Data')} showCollapseText={false} setCollapseMetaData={this.setCollapseMetaData('inventory_search_collapse1')}>

                                    <WmsCustomDateTimePicker
                                        id={'StartDate'}
                                        name='ReceiptBeginDate'
                                        wmsSelector={this.state.Selector + 'ReceiptBeginDate'}
                                        label={this.translate('Label_Begin')}
                                        onChangeFunc={this.filterHandleChange}
                                        value={this.state.filter.ReceiptBeginDate}
                                        windowId={this.props.windowId}
                                    />

                                    <WmsCustomDateTimePicker
                                        id={'ReceiptEndDate'}
                                        name='ReceiptEndDate'
                                        wmsSelector={this.state.Selector + 'ReceiptEndDate'}
                                        label={this.translate('Label_End')}
                                        onChangeFunc={this.filterHandleChange}
                                        value={this.state.filter.ReceiptEndDate}
                                        windowId={this.props.windowId}
                                        defaultTime={'23:59:59'}
                                    />

                                </WmsCollapse>

                                <WmsCollapse id={'inventory_search_collapse4'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse4') ? !this.state.isExpandedCollapse['inventory_search_collapse4'] : true} showDragIcon={true} headerLabel={this.translate('Label_Inventory_Search_By_Item')} showCollapseText={false} setCollapseMetaData={this.setCollapseMetaData('inventory_search_collapse4')}>

                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSkuOrUpc"
                                            name="IndexOptionsSkuOrUpc"
                                            label={this.translate('Label_SKU_UPC')}
                                            wmsSelector={this.state.Selector + "IndexOptionsSkuOrUpc"}
                                            value={this.state.filter.IndexOptionsSkuOrUpc}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='SkuOrUpc'
                                            inputName='SkuOrUpc'
                                            wmsSelector={this.state.Selector + 'SkuOrUpc'}
                                            value={this.state.filter.SkuOrUpc}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSkuDescOrSecondaryDesc"
                                            name="IndexOptionsSkuDescOrSecondaryDesc"
                                            label={this.translate('Label_Description')}
                                            wmsSelector={this.state.Selector + "IndexOptionsSkuDescOrSecondaryDesc"}
                                            value={this.state.filter.IndexOptionsSkuDescOrSecondaryDesc}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='SkuDescOrSecondaryDesc'
                                            inputName='SkuDescOrSecondaryDesc'
                                            wmsSelector={this.state.Selector + 'SkuDescOrSecondaryDesc'}
                                            value={this.state.filter.SkuDescOrSecondaryDesc}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsQualifier"
                                            name="IndexOptionsQualifier"
                                            label={this.translate('Label_Qualifier')}
                                            wmsSelector={this.state.Selector + "IndexOptionsQualifier"}
                                            value={this.state.filter.IndexOptionsQualifier}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Qualifier'
                                            inputName='Qualifier'
                                            wmsSelector={this.state.Selector + 'QualifierInput'}
                                            value={this.state.filter.Qualifier}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <WmsInput
                                        id='CustomField'
                                        inputName='CustomField'
                                        wmsSelector={this.state.Selector + 'CustomFieldInput'}
                                        value={this.state.filter.CustomField}
                                        onChangeFunc={this.filterHandleChange}
                                        label={this.translate('Label_CustomField')}/>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSupplier"
                                            name="IndexOptionsSupplier"
                                            label={this.translate('Label_Supplier')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsSupplier'}
                                            value={this.state.filter.IndexOptionsSupplier}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Supplier'
                                            inputName='Supplier'
                                            wmsSelector={this.state.Selector + 'Supplier'}
                                            value={this.state.filter.Supplier}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>

                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsTrailerContainerNumber"
                                            name="IndexOptionsTrailerContainerNumber"
                                            label={this.translate('Label_Trailer_Container_Number')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsTrailerContainerNumber'}
                                            value={this.state.filter.IndexOptionsTrailerContainerNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='TrailerContainerNumber'
                                            inputName='TrailerContainerNumber'
                                            wmsSelector={this.state.Selector + 'TrailerContainerNumber'}
                                            value={this.state.filter.TrailerContainerNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>


                                </WmsCollapse>
                                <WmsCollapse id={'inventory_search_collapse2'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse2') ? !this.state.isExpandedCollapse['inventory_search_collapse2'] : true} showDragIcon={true} headerLabel={this.translate('Label_Inventory_TrackBys_Search')} showCollapseText={false} setCollapseMetaData={this.setCollapseMetaData('inventory_search_collapse2')}>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsLotNumber"
                                            name="IndexOptionsLotNumber"
                                            label={this.translate('Label_Inventory_Lot_Number')}
                                            wmsSelector={this.state.Selector + "IndexOptionsLotNumber"}
                                            value={this.state.filter.IndexOptionsLotNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='LotNumber'
                                            inputName='LotNumber'
                                            wmsSelector={this.state.Selector + 'DescriptionInput'}
                                            value={this.state.filter.LotNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <WmsCustomDateTimePicker
                                        id={'ExpDateBegin'}
                                        name='ExpDateBegin'
                                        wmsSelector={this.state.Selector + 'ExpDateBegin'}
                                        label={this.translate('Label_Exp_Begin')}
                                        onChangeFunc={this.filterHandleChange}
                                        value={this.state.filter.ExpDateBegin}
                                        windowId={this.props.windowId}
                                    />
                                    <WmsCustomDateTimePicker
                                        id={'ExpDateEnd'}
                                        name='ExpDateEnd'
                                        wmsSelector={this.state.Selector + 'ExpDateEnd'}
                                        label={this.translate('Label_Exp_End')}
                                        onChangeFunc={this.filterHandleChange}
                                        value={this.state.filter.ExpDateEnd}
                                        windowId={this.props.windowId}
                                        defaultTime={'23:59:59'}
                                    />
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSerialNumber"
                                            name="IndexOptionsSerialNumber"
                                            label={this.translate('Label_Serial_Number')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsSerialNumber'}
                                            value={this.state.filter.IndexOptionsSerialNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='SerialNumber'
                                            inputName='SerialNumber'
                                            wmsSelector={this.state.Selector + 'SerialNumber'}
                                            value={this.state.filter.SerialNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsCost"
                                            name="IndexOptionsCost"
                                            label={this.translate('Label_MassAdd_Cost')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsCost'}
                                            value={this.state.filter.IndexOptionsCost}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Cost'
                                            inputName='Cost'
                                            wmsSelector={this.state.Selector + 'Cost'}
                                            value={this.state.filter.Cost}
                                            onChangeFunc={this.filterHandleChange}
                                            regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>


                                </WmsCollapse>

                                <WmsCollapse id={'inventory_search_collapse3'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse3') ? !this.state.isExpandedCollapse['inventory_search_collapse3'] : true} showDragIcon={true} headerLabel={this.translate('Label_Inventory_Transaction_Search')} showCollapseText={false} setCollapseMetaData={this.setCollapseMetaData('inventory_search_collapse3')}>

                                    <WmsInput
                                        id='ReceiptFirstTransaction'
                                        inputName='ReceiptFirstTransaction'
                                        wmsSelector={this.state.Selector + 'ReceiptFirstTransaction'}
                                        value={this.state.filter.ReceiptFirstTransaction}
                                        onChangeFunc={(e) => this.filterHandleChange(e,true)}
                                        label={this.translate('Label_First_Trans')} />
                                    <WmsInput
                                        id='ReceiptLastTransaction'
                                        inputName='ReceiptLastTransaction'
                                        wmsSelector={this.state.Selector + 'ReceiptLastTransaction'}
                                        value={this.state.filter.ReceiptLastTransaction}
                                        onChangeFunc={(e) => this.filterHandleChange(e,true)}
                                        label={this.translate('Label_Last_Trans')} />
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsReceiptRefId"
                                            name="IndexOptionsReceiptRefId"
                                            label={this.translate('Label_Receipt_Ref_Id')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsReceiptRefId'}
                                            value={this.state.filter.IndexOptionsReceiptRefId}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='ReceiptRefId'
                                            inputName='ReceiptRefId'
                                            wmsSelector={this.state.Selector + 'ReceiptRefId'}
                                            value={this.state.filter.ReceiptRefId}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsPoNumber"
                                            name="IndexOptionsPoNumber"
                                            label={this.translate('Label_PO_Number')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsPoNumber'}
                                            value={this.state.filter.IndexOptionsPoNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='PoNumber'
                                            inputName='PoNumber'
                                            wmsSelector={this.state.Selector + 'PoNumber'}
                                            value={this.state.filter.PoNumber}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                </WmsCollapse>

                                <WmsCollapse id={'inventory_search_collapse5'} isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse5') ? !this.state.isExpandedCollapse['inventory_search_collapse5'] : true} showDragIcon={true} headerLabel={this.translate('Label_Inventory_Placement_Search')} showCollapseText={false} setCollapseMetaData={this.setCollapseMetaData('inventory_search_collapse5')}>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsLocation"
                                            name="IndexOptionsLocation"
                                            label={this.translate('Label_Location')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsLocation'}
                                            value={this.state.filter.IndexOptionsLocation}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Location'
                                            inputName='Location'
                                            wmsSelector={this.state.Selector + 'Location'}
                                            value={this.state.filter.Location}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsPallet"
                                            name="IndexOptionsPallet"
                                            label={this.translate('Label_MU')}
                                            wmsSelector={this.state.Selector + 'IndexOptionsPallet'}
                                            value={this.state.filter.IndexOptionsPallet}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Pallet'
                                            inputName='Pallet'
                                            wmsSelector={this.state.Selector + 'Pallet'}
                                            value={this.state.filter.Pallet}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                </WmsCollapse>

                                <WmsCollapse id={'inventory_search_collapse6'}
                                    isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse6')
                                        ? !this.state.isExpandedCollapse['inventory_search_collapse6'] : true}
                                    showDragIcon={true}
                                    headerLabel={this.translate('Label_Inventory_Search_by_UOM')}
                                    showCollapseText={false}
                                    setCollapseMetaData={this.setCollapseMetaData('inventory_search_collapse6')}>

                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsPrimaryUnitType"
                                            name="IndexOptionsPrimaryUnitType"
                                            label={this.translate('Label_Primary_Unit')}
                                            wmsSelector={this.state.Selector + "IndexOptionsPrimaryUnitType"}
                                            value={this.state.filter.IndexOptionsPrimaryUnitType}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='PrimaryUnitType'
                                            inputName='PrimaryUnitType'
                                            wmsSelector={this.state.Selector + 'PrimaryUnitTypeInput'}
                                            value={this.state.filter.PrimaryUnitType}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSecondaryUnitType"
                                            name="IndexOptionsSecondaryUnitType"
                                            label={this.translate('Label_Secondary_Unit')}
                                            wmsSelector={this.state.Selector + "IndexOptionsSecondaryUnitType"}
                                            value={this.state.filter.IndexOptionsSecondaryUnitType}
                                            onChangeFunc={this.filterHandleChange}
                                            options={this.state.optionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='SecondaryUnitType'
                                            inputName='SecondaryUnitType'
                                            wmsSelector={this.state.Selector + 'SecondaryUnitTypeInput'}
                                            value={this.state.filter.SecondaryUnitType}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            parentClassName='input_box' />
                                    </div>
                                </WmsCollapse>

                                <WmsCollapse id={'inventory_search_collapse7'}
                                    isExpanded={this.state.isExpandedCollapse.hasOwnProperty('inventory_search_collapse7')
                                        ? !this.state.isExpandedCollapse['inventory_search_collapse7'] : true}
                                    showDragIcon={true}
                                    headerLabel={this.translate('Label_Inventory_Search_by_Quantity')}
                                    showCollapseText={false}
                                    setCollapseMetaData={this.setCollapseMetaData('inventory_search_collapse7')}>

                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsReceived"
                                            name="IndexOptionsReceived"
                                            label={this.translate('Label_Received')}
                                            wmsSelector={this.state.Selector + "IndexOptionsReceived"}
                                            value={this.state.filter.IndexOptionsReceived}
                                            onChangeFunc={(e) => this.filterHandleChange(e,true)}
                                            options={this.state.numericOptionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Received'
                                            inputName='Received'
                                            wmsSelector={this.state.Selector + 'ReceivedInput'}
                                            value={this.state.filter.Received}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            valueType={'number'}
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsAvailable"
                                            name="IndexOptionsAvailable"
                                            label={this.translate('Label_Available')}
                                            wmsSelector={this.state.Selector + "IndexOptionsAvailable"}
                                            value={this.state.filter.IndexOptionsAvailable}
                                            onChangeFunc={(e) => this.filterHandleChange(e,true)}
                                            options={this.state.numericOptionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='Available'
                                            inputName='Available'
                                            wmsSelector={this.state.Selector + 'AvailableInput'}
                                            value={this.state.filter.Available}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            valueType={'number'}
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsOnHand"
                                            name="IndexOptionsOnHand"
                                            label={this.translate('Label_OnHand')}
                                            wmsSelector={this.state.Selector + "IndexOptionsOnHand"}
                                            value={this.state.filter.IndexOptionsOnHand}
                                            onChangeFunc={(e) => this.filterHandleChange(e,true)}
                                            options={this.state.numericOptionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='OnHand'
                                            inputName='OnHand'
                                            wmsSelector={this.state.Selector + 'OnHandInput'}
                                            value={this.state.filter.OnHand}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            valueType={'number'}
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSecondaryReceived"
                                            name="IndexOptionsSecondaryReceived"
                                            label={this.translate('Label_Secondary_Received')}
                                            wmsSelector={this.state.Selector + "IndexOptionsSecondaryReceived"}
                                            value={this.state.filter.IndexOptionsSecondaryReceived}
                                            onChangeFunc={(e) => this.filterHandleChange(e,true)}
                                            options={this.state.numericOptionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='SecondaryReceived'
                                            inputName='SecondaryReceived'
                                            wmsSelector={this.state.Selector + 'SecondaryReceivedInput'}
                                            value={this.state.filter.SecondaryReceived}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            valueType={'number'}
                                            parentClassName='input_box' />
                                    </div>
                                    <div className="wms-input-group-wrapper">
                                        <WmsDropdown
                                            id="IndexOptionsSecondaryAvailable"
                                            name="IndexOptionsSecondaryAvailable"
                                            label={this.translate('Label_Secondary_Available')}
                                            wmsSelector={this.state.Selector + "IndexOptionsSecondaryAvailable"}
                                            value={this.state.filter.IndexOptionsSecondaryAvailable}
                                            onChangeFunc={(e) => this.filterHandleChange(e,true)}
                                            options={this.state.numericOptionConatins}
                                            valueField='value'
                                            textField='description'
                                            parentClassName="select_box"
                                        />
                                        <WmsInput
                                            id='SecondaryAvailable'
                                            inputName='SecondaryAvailable'
                                            wmsSelector={this.state.Selector + 'SecondaryAvailableInput'}
                                            value={this.state.filter.SecondaryAvailable}
                                            onChangeFunc={this.filterHandleChange}
                                            label=''
                                            valueType={'number'}
                                            parentClassName='input_box' />
                                    </div>
                                </WmsCollapse>

                            </div>
                        </div>

                        <div>
                            {this.state.isOpenSaveFilterModel && (
                                <WmsModel
                                    id='saveCurrentProtoMassAddInventoryFilter'
                                    title={this.translate('Label_Save_Filter')}
                                    width={450}
                                    height={240}
                                    onCloseFunc={this.exitSaveFilterModel}
                                    isOpen={this.state.isOpenSaveFilterModel}
                                    isAnotherModelOpen={true}
                                    customClass='wms-model-new-ui-wrapper'
                                >
                                    <div className="model-content-wrapper">
                                    <WmsContainer>
                                        <WmsInput
                                            inputName="newFilterTitle"
                                            name="newFilterTitle"
                                            wmsSelector={this.state.Selector + 'FilterTitle'}
                                            value={this.state.newFilterTitle}
                                            onChangeFunc={this.handleFilterTitleChange}
                                            extraWrapperClass="full-width-input"
                                            label={this.translate('Label_Filter_Title')} />
                                    </WmsContainer>
                                    </div>
                                    <div className="save-filter-button-wrapper footer-btn-holder">
                                        <WmsButton
                                            label={this.translate('Label_Close')}
                                            wmsSelector={this.state.Selector + "SaveNewFilterExit"}
                                            onClickFunc={this.exitSaveFilterModel}
                                            buttonType="orange"
                                            icon={'fa-times-circle-o'}
                                        />
                                        <WmsButton
                                            label={this.translate('Label_Add')}
                                            wmsSelector={this.state.Selector + "SaveNewFilter"}
                                            onClickFunc={(e) => this.onSaveFilter()}
                                            disabled={this.state.newFilterTitle == '' || this.state.filterNameExist}
                                            icon={'fa-plus'}
                                            showToolTip={this.state.filterNameExist}
                                            tooltipText={this.translate('Label_Filter_Name_Exist_Message')}
                                        />
                                    </div>
                                    
                                </WmsModel>
                            )}
                        </div>

                        <WmsConfirmModel
                            id={"ConfirmModel" + this.props.windowId}
                            isOpen={this.state.isOpenConfirmModel}
                            type={this.translate('Label_Confirmation')}
                            width={500}
                            height={175}
                            firstBtnLabel={this.translate('Label_Yes')}
                            firstBtnIcon={"fa-check-square"}
                            firstBtnOnClickFunc={this.onYesRemoveSavedFilter}
                            sawCheckBox={false}
                            conFormModelText={this.translate('Label_are_you_sure_remove_filter')}
                            onCloseFunc={this.ClickExit}
                            thirdBtnLabel={this.translate('Label_No')}
                            thirdBtnIcon={"fa-times-circle"}
                            isCallToFront={false}
                        />
                    </div>
                </div>
            </div>
        );
    }
}