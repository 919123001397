import React, { Component } from 'react';


import './edit-bol-option-model.scss';

// Component
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsContainer from '../../../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';

export class EditBolOptionModel extends Component {

    constructor(props) {
        super(props);

        
        this.$rootScope = window;
        this.translate = window.translate;

        this.state = {
            selectedTemplateId: this.props.sharedTemplateOption[0].BolTemplateId
        }
    }
    clickEditBol = () => {
        this.props.onCloseEditBolOptionsModel();
        setTimeout(() => {
            this.props.onOpenEditBolClassicPage(this.state.selectedTemplateId);
        }, 550);
    }

    handleChangeEvent = (e) => {
        this.setState({ selectedTemplateId: e.target.value });
    }

    render() {
        return (
            <WmsModel
                id={'editBolTemplateUi'}
                class="editBolTemplateUi"
                title={this.translate('Label_Edit_Bill_of_Lading')}
                width={400}
                height={221}
                onCloseFunc={this.props.onCloseEditBolOptionsModel}
                isOpen={true}
                customClass='wms-model-new-ui-wrapper' >

                <div className="model-content-wrapper">
                    <WmsContainer>
                        <WmsDropdown
                            id="templateOption"
                            name="templateOption"
                            label={this.translate('Label_Edit_BOL_Templates')}
                            wmsSelector={this.state.Selector + "Template"}
                            value={this.state.selectedTemplateId}
                            onChangeFunc={this.handleChangeEvent}
                            options={this.props.sharedTemplateOption}
                            textField="Name"
                            valueField="BolTemplateId"
                            parentClassName="form_group"
                        />
                    </WmsContainer>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Cancel')}
                        wmsSelector={this.state.Selector + "PickTicketExit"}
                        onClickFunc={this.props.onCloseEditBolOptionsModel}
                        disabled={false}
                        loading={false}
                        buttonType='yellow'
                        icon={'fa-ban'}
                    />
                    <WmsButton
                        id={'BolEdit' + this.props.windowId}
                        label={this.translate('Label_Generate')}
                        wmsSelector={this.state.Selector + "BolEdit"}
                        onClickFunc={this.clickEditBol}
                        disabled={this.state.gifGenerate}
                        loading={this.state.gifGenerate}
                        icon={'fa-floppy-o'}
                    />
                </div>
            </WmsModel >
        );
    }
}