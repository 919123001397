
import React from 'react';
import { ObjectWms } from './../../../assets/ts/ObjectWms.ts';

//components
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import { default as WmsCollapseProto } from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import { ManageCarriersGrid } from './manage-carriers-grid.component.jsx';
import * as metaDataService from './manage-carrier-meta-data.service';

import style from './manage-carriers-holder.scss';
//services
import * as GlobalService from './../global_services';
var axios = require('axios');

export class ManageCarriersHolder extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            selector: 'manageCarriers',
            customerOptions: [],
            sharedSelectedCustomer: {},
            selectedCustomerUri: '',
            sharedFacilityOption: [],
            selectedCustomerIndex: '',
            staticGridData: [],
            isLoadMetaServices: false
        }
    }

    async componentDidMount() {
        kendo.ui.progress($("#manage-carrriers-holder-react"), true);
        await metaDataService.getManageCarrierMetaData();

        GlobalService.GetCustomersAndFacility().then(() => {
            this.setState({
                customerOptions: GlobalService.getCustomerList(),
                sharedFacilityOption: GlobalService.getFacilityList(false)
            });
        }).finally(() => {
            kendo.ui.progress($('#manage-carrriers-holder-react'), false);
        });
        $('#manage-carrriers-holder-react').closest('.k-window-content').css("padding", "0");
        this.setState({
            isLoadMetaServices: true
        });
        setTimeout(() => {
            this.getGridData();
        }, 100);
    }

    componentWillUnmount() {
        $('#manage-carrriers-holder-react').closest('.k-window-content').css("padding", "26");
    }

    handleCustomerChange = (event) => {

        let value = event.target.value;
        let selFacilityOption = [];
        let customerObj = {};
        if (event.target.value) {
            let custFacility = this.state.customerOptions[value].facilities;
            let allFacilities = GlobalService.getFacilityList(false);
            for (let i = 0; i < custFacility.length; i++) {
                let facilityIndex = ObjectWms.FindIndexByProperty(allFacilities, "id", parseInt(custFacility[i]));
                if(allFacilities[facilityIndex]) {
                    selFacilityOption.push(allFacilities[facilityIndex]);
                }
            }
            customerObj = this.state.customerOptions[value];
        } else {
            selFacilityOption = GlobalService.getFacilityList(false);
        }

        this.setState({
            selectedCustomerIndex: value,
            sharedSelectedCustomer: customerObj,
            sharedFacilityOption: selFacilityOption
        }, () => {
            this.getGridData();
        });
    };
    getGridData = () => {

        kendo.ui.progress($('#manageCarrierGrid'), true);
        var customerUri = '';
        if (this.state.sharedSelectedCustomer.uri) {
            customerUri = this.state.sharedSelectedCustomer.uri;
        }
        axios.get('/WebUI/Settings/CarrierAccount/GetCarrierAccounts', { params: { "customerUri": customerUri } })
            .then((response) => {
                console.log("response  :", response);
                let addRowNumberData = this.addRowNumber(response.data);
                this.setState({
                    staticGridData: addRowNumberData
                });

            }).finally(() => {
                kendo.ui.progress($('#manageCarrierGrid'), false);
            });
    }
    addRowNumber = (param_data) => {
        var index = 0;
        for (var i = 0; i < param_data.length; i++) {
            var firstItem = param_data[i];
            index++;
            firstItem["RowNumber"] = index;
        }
        return param_data;
    }
    render() {
        return (
            <div id="manage-carrriers-holder-react" className="manage-carrriers-holder-react" >
                <div className="manage-carrier-left-side">

                    {/* <WmsButton
                        label={this.translate('RoleManager_Label_Reset')}
                        buttonType='yellow'
                        wmsSelector={this.state.Selector + 'ClearSearchButton'}
                        onClickFunc={() => { }}
                        icon='fa-repeat' /> */}

                    <WmsCollapseProto id={'collapseCarrierMultiSearchFilter'} headerLabel={this.translate('Label_Manage_Carries_Multi_Search')} showCollapseText={false} showCollapseIcon={false}>

                        <WmsDropdown
                            id="itemHolderIndexOptionsCustomer"
                            name="selectedCustomerIndex"
                            label={this.translate('Label_Account_Owner')}
                            wmsSelector={this.state.selector + "Customer"}
                            value={this.state.selectedCustomerIndex}
                            onChangeFunc={this.handleCustomerChange}
                            options={this.state.customerOptions}
                            valueField='uri'
                            textField='name'
                            parentClassName='form_group'
                            blankFirstOption={true}
                            blankFirstOptionText={this.translate('Label_Warehouse_Accounts')}
                            applyIndex={true}
                        />
                    </WmsCollapseProto>
                </div>
                {this.state.isLoadMetaServices && (

                    <ManageCarriersGrid
                        windowId={this.state.windowId}
                        sharedStaticGridData={this.state.staticGridData}
                        sharedFacilityOption={this.state.sharedFacilityOption}
                        sharedSelectedCustomer={this.state.sharedSelectedCustomer}
                        sharedSelectedCustomerIndex={this.state.selectedCustomerIndex}
                        onRefreshGridData={this.getGridData}
                    />
                )}

            </div>
        )

    }
}