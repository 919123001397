import React, { useState } from "react";

import WmsModel from "../react/components/WmsModel/WmsModel";
import WmsCollapse from "../react/components/WmsCollapse/WmsCollapse";
import WmsInput from "../react/components/WmsInput/WmsInput";
import WmsButton from "../react/components/WmsButton/WmsButton";
import "./adjustCustomerCharges.scss";

interface Props {
    onCloseCancelAdjustment?: any;
    sharedIsOpenCancelAdjustmentModal?: boolean;
    wmsSelector?: string;
    selectedRow?: any;
    onApplyReverseAdjustment?: any;
    applyReverseAdjustment?: boolean;
}

const defaultCancelAdjustmentData = {
    Charges: "",
    Reason: "",
    CreateInvoice: false,
    InvoiceCreationInfo: {},
};

declare var window: any;
const { $ } = window;

const CancelAdjustmentModal: React.FC<Props> = (props: Props): any => {
    const [CancelAdjustmentData, setCancelAdjustmentData] = useState(defaultCancelAdjustmentData);

    const handelChangeEvent = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        setCancelAdjustmentData((previousState) => ({
            ...previousState,
            [name]: value,
        }));
    };

    const onApplyReverseAdjustment = () => {
        kendo.ui.progress($("#CancelAdjustmentModal"), true);
        CancelAdjustmentData.InvoiceCreationInfo = {};
        if (props.selectedRow.CreateInvoice === true) {
            CancelAdjustmentData.InvoiceCreationInfo = {
                "SetInvoiceDate": true,
                "UtcOffset": (new Date().getTimezoneOffset() / 60) * -1,
            };
        }

        props.onApplyReverseAdjustment(CancelAdjustmentData);
    };

    return (
        <WmsModel
            id="CancelAdjustmentModal"
            title={window.translate("Cancel_Adjustment")}
            width={800}
            height={800}
            onCloseFunc={props.onCloseCancelAdjustment}
            isOpen={props.sharedIsOpenCancelAdjustmentModal}
            customClass="wms-model-new-ui-wrapper"
        >
            <div className="model-content-wrapper">
                <WmsCollapse
                    id={"adjustmentDetails"}
                    headerLabel={window.translate("Adjustment_Details")}
                    showCollapseText={false}
                >
                    <div className="charge-detail-wrapper">
                        <div className="display-inline">
                            <div className="left-content-wrapper">
                                <div className="content">
                                    <label>Customer</label>
                                    <span>{props.selectedRow.CustomerIdentifier.Name}</span>
                                </div>
                                <div className="content">
                                    <label>Warehouse</label>
                                    <span>{props.selectedRow.FacilityIdentifier.Name}</span>
                                </div>
                            </div>
                            <div className="right-content-wrapper">
                                <div className="content">
                                    <label>Transaction ID</label>
                                    <span>{props.selectedRow.TransactionId}</span>
                                </div>
                                <div className="content">
                                    <label>Adjustment Date</label>
                                    <span>
                                        {kendo.toString(kendo.parseDate(props.selectedRow.ProcessDate), "MM/dd/yyyy")}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="display-inline">
                            <WmsInput
                                id="Reason"
                                inputName="Reason"
                                wmsSelector={props.wmsSelector + "Reason"}
                                onChangeFunc={handelChangeEvent}
                                label={window.translate("Cancellation_Reason")}
                                value={CancelAdjustmentData.Reason}
                                extraWrapperClass="cancellation-reason"
                            />
                            <WmsInput
                                id="Charges"
                                inputName="Charges"
                                wmsSelector={props.wmsSelector + "Charges"}
                                onChangeFunc={handelChangeEvent}
                                label={window.translate("Cancellation_Fee")}
                                value={CancelAdjustmentData.Charges}
                                extraWrapperClass="cancellation-fee"
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                            />
                        </div>
                    </div>
                </WmsCollapse>
            </div>
            <div className="footer-btn-holder">
                <WmsButton
                    label={window.translate("Label_Cancel")}
                    wmsSelector={window.wmsSelector + "DiscardExit"}
                    onClickFunc={props.onCloseCancelAdjustment}
                    buttonType="orange"
                    icon={"fa-ban"}
                />
                <WmsButton
                    label={window.translate("Label_Save")}
                    wmsSelector={props.wmsSelector + "ApplyAdjustment"}
                    onClickFunc={onApplyReverseAdjustment}
                    icon={"fa-floppy-o"}
                    loading={props.applyReverseAdjustment}
                />
            </div>
        </WmsModel>
    );
};
CancelAdjustmentModal.propTypes = {};

CancelAdjustmentModal.defaultProps = {};

export default React.memo(CancelAdjustmentModal);
