import React,
{ Component } from 'react'
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';

export class DHLeCommerceAccountInfoComponent extends Component {
    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.distributionCenterOption = [
            { name: "USATL1", value: "USATL1" },
            { name: "USBOS1", value: "USBOS1" },
            { name: "USBWI1", value: "USBWI1" },
            { name: "USCAK1", value: "USCAK1" },
            { name: "USCVG1", value: "USCVG1" },
            { name: "USDEN1", value: "USDEN1" },
            { name: "USDFW1", value: "USDFW1" },
            { name: "USEWR1", value: "USEWR1" },
            { name: "USIAH1", vlaue: "USIAH1" },
            { name: "USLAX1", value: "USLAX1" },
            { name: "USMCI1", value: "USMCI1" },
            { name: "USMCO1", value: "USMCO1" },
            { name: "USMEM1", value: "USMEM1" },
            { name: "USORD1", value: "USORD1" },
            { name: "USPHX1", value: "USPHX1" },
            { name: "USRDU1", value: "USRDU1" },
            { name: "USSEA1", value: "USSEA1" },
            { name: "USSFO1", value: "USSFO1" },
            { name: "USSLC1", value: "USSLC1" },
            { name: "USSTL1", value: "USSTL1" },
            { name: "CNYYZ1", value: "CNYYZ1" }
        ];
        this.ancillaryEndorsementOption = [
            { name: "None", value: "none" },
            { name: "Return Service Requested", value: "return_service_requested" },
            { name: "Forwarding Service Requested", value: "forwarding_service_requested" },
            { name: "Address Service Requested", value: "address_service_requested" },
            { name: "Change Service Requested", value: "change_service_requested" },
            { name: "Leave If No Response", value: "leave_if_no_response" }
        ];
        this.state = {
            selectedDistributionCenterIndex: null,
            selectedAncillaryEndorsementIndex: null
        }
    }

    handleSelectChange = (e) => {
        if(e.target.name === "AncillaryEndorsement") {
            this.setState({
                selectedAncillaryEndorsementIndex: e.target.value
            });
        } else {
            this.setState({
                selectedDistributionCenterIndex: e.target.value
            });
        }
        this.props.handleCarrierObjChange(e);
    }

    render() {
        return (
            <WmsCollapse
                id={"carrierAccountInformation"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id="accountNickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Nick_Name")}
                            value={this.props.currentCarrierObj.Nickname}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="client_id"
                            inputName="ClientId"
                            wmsSelector={this.props.selector + "clientId"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_DHL_Client_Id")}
                            value={this.props.currentCarrierObj.ClientId}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                    </div>
                    <WmsInput
                        id="api_secret"
                        inputName="ApiSecret"
                        wmsSelector={this.props.selector + "apiSecret"}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        label={this.translate("Label_Client_Secret")}
                        value={this.props.currentCarrierObj.ApiSecret}
                        required={true}
                        extraWrapperClass="w-75"
                    />

                    <div>
                        <WmsInput
                            id="sold_to_number"
                            inputName="AccountNumber"
                            wmsSelector={this.props.selector + "AccountNumber"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Sold_To_Number")}
                            value={this.props.currentCarrierObj.AccountNumber}
                            required={true}
                        />

                        <WmsInput
                            id="pickup_number"
                            inputName="PickupNumber"
                            wmsSelector={this.props.selector + "pickupNumber"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Pickup_Number")}
                            value={this.props.currentCarrierObj.PickupNumber}
                            required={true}
                        />

                    </div>

                    <WmsDropdown
                        id="distribution_center"
                        name="DistributionCenter"
                        label={this.translate('Label_Distribution_Center')}
                        wmsSelector={this.state.selector + "distributionCenter"}
                        onChangeFunc={this.handleSelectChange}
                        value={this.state.selectedDistributionCenterIndex}
                        options={this.distributionCenterOption}
                        valueField='value'
                        textField='name'
                        blankFirstOption={true}
                        required={true}
                    />

                    <WmsDropdown
                        id="ancillary_endorsement"
                        name="AncillaryEndorsement"
                        label={this.translate('Label_Ancillary_Endorsement')}
                        wmsSelector={this.state.selector + "ancillaryEndorsement"}
                        onChangeFunc={this.handleSelectChange}
                        value={this.state.selectedAncillaryEndorsementIndex}
                        options={this.ancillaryEndorsementOption}
                        valueField='value'
                        textField='name'
                        blankFirstOption={true}
                        required={false}
                    />
                </div>
            </WmsCollapse>
        )
    }
}
