
import { BaseRequirements } from './BaseRequirements';
import { NumberWms } from './NumberWms';
import { StringWms } from './StringWms';
export class KendoWms implements BaseRequirements {
	/**
	* Simple contains method for Dictionary
	* @params key:string
	* @return void
	*/
    public static test(message: string): void {
        console.log(message);
    }

    public test(message: string): void {
        console.log(message);
    }

    public testWithNumberWms(message: string) {
        let numberObj = new NumberWms();
        numberObj.test("NumberWma.ts test method call from KendoWms.ts file");
    }

    public testWithNumberWmsStatic(message: string) {
        return NumberWms.testing(message);
    }

    public testWithStringWmsStatic(message: string) {
        return StringWms.test(message);
    }

    public static transformFilterForService(filter) {

        let myreturn = [];
        console.log('filter', filter);
        if (typeof filter != 'undefined' && typeof filter != null && filter != null) {
            for (let x = 0; x < filter.filters.length; x++) {
                let tempObj = {
                    "member": filter.filters[x].field,
                    "operator": this.transformKendoFilterNames(filter.filters[x].operator),
                    "value": filter.filters[x].value
                };
                myreturn.push(tempObj);
            }
        }
        return JSON.stringify(myreturn);
    }

    public static transformFilterForWebUI(filterobj){


        if(filterobj) {


            Object.keys(filterobj).forEach(function (key) {

                if (key == "logic") {
                    filterobj["LogicalOperator"] = filterobj[key];
                    delete(filterobj[key]);
                    if (typeof filterobj["LogicalOperator"] == "object" && filterobj["LogicalOperator"] !== null && Object.prototype.toString.call(filterobj["LogicalOperator"]) != '[object String]') {
                        this.transformFilterForWebUI(filterobj["LogicalOperator"]);
                    }


                }
                else if (key == "filters") {
                    filterobj["FilterDescriptors"] = filterobj[key];
                    delete(filterobj[key]);
                    if (Array.isArray(filterobj["FilterDescriptors"])) {
                        filterobj["FilterDescriptors"].forEach(function (obj) {
                            this.transformFilterForWebUI(obj);
                        }, this);
                    }

                }
                else if (key == "field") {
                    filterobj["member"] = filterobj[key];
                    delete(filterobj[key]);

                }
                else {
                    if (Array.isArray(filterobj[key])) {
                        filterobj[key].forEach(function (obj) {
                            this.transformFilterForWebUI(obj);
                        }, this);
                    }
                    else if (typeof key == "object" && key !== null) {
                        this.transformFilterForWebUI(key);
                    }
                    else if (key == "operator") {
                        filterobj[key] = this.transformKendoFilterNames(filterobj[key]);
                    }
                }


                /*
                 if(Array.isArray(filterobj[key])){
                 filterobj[key].forEach(function(obj){
                 this.transformFilterForWebUI(obj);
                 },this);
                 }
                 else if (typeof key == "object" && key !== null){
                 this.transformFilterForWebUI(key);
                 }
                 else{
                 if(key == "logic"){
                 filterobj["LogicalOperator"] = filterobj[key];
                 delete(filterobj[key]);

                 }
                 else if(key == "filters"){
                 filterobj["FilterDescriptors"] = filterobj[key];
                 delete(filterobj[key]);

                 }
                 else if(key == "field"){
                 filterobj["member"] = filterobj[key];
                 delete(filterobj[key]);

                 }
                 }
                 */
            }, this);

            return filterobj;
        }
        else{
           return filterobj;
        }

    }

    public static getFilterOperatorByIndex(index:number) : string {
        var myreturn = "";
        if(index == 0) {
            myreturn = "contains";
        } else if(index == 1){
            myreturn = "doesnotcontain";
        } else if(index == 2) {
            myreturn = "eq";
        } else if(index == 3) {
            myreturn = "neq";
        } else if(index == 4) {
            myreturn = "startswith";
        } else if(index == 5) {
            myreturn = "endswith";
        }
        return myreturn;
    }


    public static transformKendoFilterNames = function (name) {

        var myreturn = "";
        if (name == "lte") {
            myreturn = "IsLessThanOrEqualTo";
        } else if (name == "eq") {
            myreturn = "IsEqualTo";
        } else if (name == "lt") {
            myreturn = "IsLessThan";
        } else if (name == "neq") {
            myreturn = "IsNotEqualTo";
        } else if (name == "gte") {
            myreturn = "IsGreaterThanOrEqualTo";
        } else if (name == "gt") {
            myreturn = "IsGreaterThan";
        } else if (name == "startswith") {
            myreturn = "StartsWith";
        } else if (name == "endswith") {
            myreturn = "EndsWith";
        } else if (name == "contains") {
            myreturn = "Contains";
        } else if (name == "doesnotcontain") {
            myreturn = "DoesNotContain";
        } else {
            myreturn = name;
        }
        return myreturn;
    }

    public static transformSortForService(sort) {

        if(sort == undefined || sort == "undefined"){
            return JSON.stringify([]);
        }
        else{
            if (sort.length == 0) {
                return JSON.stringify([]);
            } else {
                var myreturn = [];
                var sortdirection;
                for (let x = 0; x < sort.length; x++) {
                    if (sort[x].dir == 'asc') {
                        sortdirection = "ascending";
                    } else {
                        sortdirection = "descending";
                    }
                    myreturn.push({
                        "member": sort[x].field,
                        "sortdirection": sortdirection
                    });
                }
                return JSON.stringify(myreturn);
            }
        }

    };

    public static getLengthOffFilters(filters){
        if(!filters){
            return 0;
        }
        else{
            return filters.filters.length;
        }
    }

    public static getListFromSelectedGridRows(grid: any, key:string) :any{

        // instantiate array to hold selected row indexes
        let _selectedIndexs = [];
        // get the selected row indexes from grid
        var selectedRows = grid.select();
        // populate selectedIndexs with rowIndex
        for (var x = 0; x < selectedRows.length; x++) {
            _selectedIndexs.push(selectedRows[x].rowIndex);
        }
        // instantiate array to hold the returnable list
        var selectedList = [];
        // pull out the key value in the grids datasource using the selected indexes
        for (var x = 0; x < _selectedIndexs.length; x++) {

            selectedList.push(grid._data[_selectedIndexs[x]][key]);
        }
        // return list
        return selectedList;
    }




}