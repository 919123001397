import axios from "axios";

interface CreateHVPBatch {
    orderIds: number[];
    batchName: string;
    labelFormat: string;
    includeReturnLabels: boolean;
    recalcPaks: boolean;
    packageDefId: number | null;
    shipDate: string | null;
}

export const removeBatchErrors = async (batchUris: string[]) => {
    return axios.put("/WebUI/Shipping/FindOrders/RemoveSmallParcelBatchErrors", {
        batchErrorsUriList: batchUris,
    });
};

export const createShippingLabelsHighVolumeBatch = async (createHvpBatchData: CreateHVPBatch) => {
    return axios.put("/WebUI/Shipping/SmartParcel/ShippingLabelsHighVolumeBatch", createHvpBatchData);
};

export const getSmallParcelBatches = (startDate: string, endDate: string, cancleToken: any) => {
    let url = "";
    if (startDate && endDate) {
        url = `/WebUI/Shipping/FindOrders/GetSmallParcelBatches?includeOrderPackages=true&startDate=${startDate}&endDate=${endDate}`;
    } else {
        url = `/WebUI/Shipping/FindOrders/GetSmallParcelBatches?includeOrderPackages=true`;
    }
    return axios.get(url, { cancelToken: cancleToken });
};

export const getAllPackages = () => {
    return axios.get("/WebUI/Shipping/PackageDefs/PackageDefsData");
};

export const getSmallParcelBatchZplContents = async (zplUri: string) => {
    const url = `/WebUI/Shipping/FindOrders/GetSmallParcelBatchZplContents?batchlabelszpluri=${zplUri}`;
    return axios.get(url);
};

export const putSmallParcelBatchLabels = async (param_args: string) => {
    return axios.put(`/WebUI/Shipping/FindOrders/PutSmallParcelBatchLabels${param_args}`);
};

export const updateBatchStatus = async (batchId: string, status: string) => {
    return axios.put(`/WebUI/Shipping/FindOrders/PutSmallParcelBatch?batchId=${batchId}&status=${status}`);
};
