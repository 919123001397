import React from 'react';
import PropTypes from 'prop-types';

import WmsButton from './../components/WmsButton/WmsButton.jsx';

class OrderImportMessage extends React.Component {

    constructor(props) {

        super(props);

        
        this.$rootScope = window
        this.translate = window.translate;
        this.isCallToFront = this.props.isCallToFront
        this.state = {
            isOpen: false,
            selectedFile: '',
            optionsParser: [],
            indexOptionParser: 0,
            importResults: {
                successArray: [],
                errorArray: [],
            },
            showAmbiguousParser: false,
            showLoadedContent: false,
            title: "",
            status: '',
            contentHeader: '',
        }
    }

    componentDidMount() {
        console.log("1111111 react OrderImportMessage componentDidMount");
        $("#" + this.props.id).kendoWindow({
            visible: false,
            cache: false,
            modal: true,
            width: this.props.width,
            height: this.props.height,
        });
    }

    componentWillUnmount() {
        $("#" + this.props.id).remove();
    }

    //for remove old reference of this model from dom
    componentWillMount() {
        if ($("#" + this.props.id).length > 0) {
            $("#" + this.props.id).parent('.k-window').remove();
        }
    }
    componentWillReceiveProps(nextprops) {

        console.log("1111111 react OrderImportMessage nextprops", nextprops);
        if (nextprops.isOpen == true && this.state.isOpen == false) {
            console.log("1111111 react OrderImportMessage nextprops in condition", nextprops);

            var StatusType = "";
            var TitleText = "";
            var ContentHeader = "";
            var SuccessArray = nextprops.orderImportData.ImportResults.CreatedReceivers;
            var ErrorArray = nextprops.orderImportData.ImportResults.Faults;
            if (nextprops.orderImportData.Status == 0) {
                StatusType = "Success";
                TitleText = this.translate('Label_Success');
                ContentHeader = this.translate('Label_Success_Import_Message').replace('%QuantityOfSuccess%', SuccessArray.length);
            } else if (nextprops.orderImportData.Status == 1) {
                StatusType = "SuccessWithErrors";
                TitleText = this.translate('Label_Success_WithErrors');
                ContentHeader = this.translate('Label_Success_Import_Message').replace('%QuantityOfSuccess%', SuccessArray.length);
            } else if (nextprops.orderImportData.Status == 2) {
                StatusType = "Error";
                TitleText = this.translate('Label_Import_Error');
                ContentHeader = this.translate('Label_Import_Error_Message').replace('%filename.extension%', "testing.php");
            }


            this.setState({
                isOpen: true,
                state: StatusType,
                title: TitleText,
                importResults: nextprops.orderImportData.ImportResults,
                errorArray: ErrorArray,
                successArray: SuccessArray,
                contentHeader: ContentHeader
            });

            let GetWindow = $("#" + this.props.id).data("kendoWindow");
            GetWindow.setOptions({
                title: nextprops.title,
                width: nextprops.width,
                height: nextprops.height,
                open: (e) => {
                    let winObject = $("#" + this.props.id).data("kendoWindow");
                    winObject.wrapper.addClass("areYouSureZindex");
                },
                close: (e) => {
                    this.setState({ isOpen: false });
                    if (this.isCallToFront == true) {
                        if (typeof this.$rootScope.currentWindow != "undefined") {
                            setTimeout(() => {
                                // this.$rootScope.currentWindow.toFront();
                            }, 500);
                        }
                        setTimeout(() => {
                            this.$rootScope.adjustZIndexOfSidebar('close');
                            GetWindow.wrapper.removeClass("areYouSureZindex");
                        }, 500);
                    }
                    nextprops.onCloseFunc();
                }
            });
            GetWindow.center().open();
            GetWindow.refresh();
            this.$rootScope.adjustZIndexOfSidebar('open');
        } else if (nextprops.isOpen == false && this.state.isOpen == true) {
            this.setState({ isLoading: false });
            $("#" + this.props.id).data('kendoWindow').close();
        }
        if (nextprops.isLoadingStop == true) {
            this.setState({ isLoading: false });
        }
    }


    // firstFunctionClick = () => {
    //     // $("#"+this.props.id).data('kendoWindow').close();
    //     this.setState({ isLoading: true });
    //     if (this.props.isCallToFront == false) {
    //         this.isCallToFront = false;
    //     }
    //     this.props.firstBtnOnClickFunc();
    // }

    // secondFunctionClick = () => {
    //     // $("#"+this.props.id).data('kendoWindow').close();
    //     if (this.props.isCallToFront == false) {
    //         this.isCallToFront = false;
    //     }
    //     this.props.secondFunctionClick();
    // }

    // closeModel = () => {

    //     if (this.props.isCallToFront == false) {
    //         this.isCallToFront = false;
    //         $("#" + this.props.id).data('kendoWindow').close('pass_param');
    //     } else {
    //         $("#" + this.props.id).data('kendoWindow').close();
    //     }

    // }

    render() {
        return (
            <div className="confirmation-model" id={this.props.id}>
                <div className="wms-warning-notification">
                    <div className="wms-notification-warning-left"></div>
                    <div className="wms-notification-warning-header wms-warning-header-color">{this.state.title}</div>
                    <div className="wms-area-content">
                        <div className={'wms-area-content-success'}>
                            <div className="wms-notification-header">
                                <div className="note">
                                    <label className="wms-form-label">{this.state.contentHeader}</label>
                                </div>
                            </div>
                        </div>

                        {this.state.status == "SuccessWithErrors" || this.state.status == "Error" &&
                            <div id="ErrorSection" className={'wms-success-error-section'}>
                                {this.state.status == "SuccessWithErrors" &&
                                    <div className="wms-area-content">
                                        <div className="note">
                                            <label className="wms-label-info-grid-header">{this.translate('Label_Error_Import_Header')} </label>
                                        </div>
                                    </div>
                                }

                                <div id="padding-wrapper" className='wms-pad-left'>
                                    <div className="wms-area-content">
                                        <div className="note">
                                            <label className="wms-label-info-grid-header">{this.translate('Label_Line')}</label>
                                        </div>
                                        <div className="note">
                                            <label className="wms-label-info-grid-header">{this.translate('Label_Reference')}</label>
                                        </div>
                                        <div className="note">
                                            <label className="wms-label-info-grid-header">{this.translate('Label_Error')}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className={'padding-wrapper-2 wms-pad-left wms-success-padding-wrapper'}>
                                    {
                                        this.state.errorArray.map((error, key) =>
                                            <div>
                                                <div className="wms-ellipsis-container">
                                                    <label className="wms-label-info-grid-header">{error.Line}</label>
                                                </div>
                                                {error.ReferenceNumber.referencenum.length >= 20 &&
                                                    <div className="wms-ellipsis-container" kendo-tooltip k-content={error.ReferenceNumber.referencenum}
                                                        k-ng-delay="errorArray">
                                                        <label className="wms-label-info-grid-header">{error.ReferenceNumber.referencenum}</label>
                                                    </div>
                                                }

                                                {error.ReferenceNumber.referencenum.length <= 19 &&
                                                    <div className="wms-ellipsis-container" ng-show="error.ReferenceNumber.referencenum.length <= 19" k-ng-delay="errorArray">
                                                        <label className="wms-label-info-grid-header">{error.ReferenceNumber.referencenum}</label>
                                                    </div>
                                                }

                                                {error.Error.length >= 42 &&
                                                    <div className="wms-ellipsis-container" ng-show="error.Error.length >= 42" kendo-tooltip k-content={error.Error} k-ng-delay="errorArray">
                                                        <label className="wms-label-info-grid-header">{error.Error}</label>
                                                    </div>
                                                }

                                                {error.Error.length <= 41 &&
                                                    <div className="wms-ellipsis-container" ng-show="error.Error.length <= 41" k-ng-delay="errorArray">
                                                        <label className="wms-label-info-grid-header">{error.Error}</label>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className="wms-notification-toolbar">
                        {this.state.status == "Success" || this.state.SuccessWithErrors == "SuccessWithErrors" &&
                            <div className="wms-toolbar">
                                <WmsButton
                                    label={this.translate("Label_View_On_FindOrders")}
                                    wmsSelector={this.state.Selector + "ViewOnFindOrders"}
                                    onClickFunc={(e) => function () { }}
                                    icon="fa-search"
                                    buttonType="green"
                                    showToolTip={false}
                                    disabled={false}
                                />

                                <WmsButton
                                    label={this.translate("Label_Exit")}
                                    wmsSelector={this.state.Selector + "ExitButton"}
                                    onClickFunc={(e) => function () { }}
                                    icon="fa-sign-out-alt"
                                    buttonType="yellow"
                                    showToolTip={false}
                                    disabled={false}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

OrderImportMessage.propTypes = {
    id: PropTypes.string.isRequired, // this should write the id to the input
    isOpen: PropTypes.bool.isRequired, // open and close modal
}

OrderImportMessage.defaultProps = {
    id: "OrderImportMessage",
    isCallToFront: true,
    width: 550,
    height: 200,
}

export default OrderImportMessage;
