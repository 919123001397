import React, { Component } from 'react';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from '../../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import WmsComboBox from '../../components/WmsComboBox/WmsComboBox.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsTextArea from '../../components/WmsTextArea/WmsTextArea.jsx';

export class ManageReceiptTransportInformation extends Component {
    constructor(props) {
        super(props);

        
        this.translate = window.translate;

        this.state = {
            SelectedCapacityTypes: 0,
        }
    }

    handleChangeEvent = (event) => {

        let ReceiptData = Object.assign({}, this.props.sharedReceiptData.Receipt);
        let name = event.target.name;
        let value = event.target.value;

        if (name == 'CapacityType') {

            let cType = this.props.sharedReceiptData.CapacityTypes[value];

            ReceiptData[name] = {
                Id: cType.CapacityTypeId,
                Name: cType.Description,
            };

        }else{
            ReceiptData[name] = value;
        }
        this.props.onReceiptDataSet(ReceiptData);
    }

    render() {
        const renderReceiptObj = this.props.sharedReceiptData.Receipt;
        return (
            <div className="transport-information-section" ref={this.props.divRefs}>
                <WmsCollapse id={'collapseReceiptTransportInformation'} headerLabel={this.translate('Label_Transport_Information')} >
                    <WmsContainer>
                        <WmsComboBox
                            id={"Carrier"+this.props.windowId}
                            name="Carrier"
                            label={this.translate('Label_Carrier')}
                            wmsSelector={this.props.selector + 'Carrier'}
                            value={renderReceiptObj.Carrier}
                            onChangeFunc={this.handleChangeEvent}
                            textField="Description"
                            valueField="Name"
                            placeholder={'Select or Enter a Carrier'}
                            options={this.props.sharedReceiptData.Carriers}
                        />

                        <WmsInput
                            id='TrailerNumber'
                            inputName="TrailerNumber"
                            wmsSelector={this.props.selector + 'TrailerNumber'}
                            value={renderReceiptObj.TrailerNumber}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.translate('Label_Trailer_Number')}
                        />
                        <WmsInput
                            id='SealNumber'
                            inputName="SealNumber"
                            wmsSelector={this.props.selector + 'SealNumber'}
                            value={renderReceiptObj.SealNumber}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.translate('Label_Seal_Number')}
                        />
                        <WmsInput
                            id='BillOfLading'
                            inputName="BillOfLading"
                            wmsSelector={this.props.selector + 'BillOfLading'}
                            value={renderReceiptObj.BillOfLading}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.translate('Label_Bill_Of_Lading')}
                        />
                        <WmsInput
                            id='TrackingNumber'
                            inputName="TrackingNumber"
                            wmsSelector={this.props.selector + 'TrackingNumber'}
                            value={renderReceiptObj.TrackingNumber}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.translate('Label_Tracking_Pro_Number')}
                        />
                        <WmsDropdown
                            id="CapacityType"
                            name="CapacityType"
                            label={this.translate('Label_Capacity_Type')}
                            wmsSelector={this.props.selector + "CapacityType"}
                            value={
                                (renderReceiptObj.CapacityType != null) ?
                                    renderReceiptObj.CapacityType.Id :
                                    ''
                            }
                            onChangeFunc={this.handleChangeEvent}
                            options={this.props.sharedReceiptData.CapacityTypes}
                            textField='Description'
                            valueField='Id'
                            applyIndex={true}
                        />
                        <WmsInput
                            id='DoorNumber'
                            inputName="DoorNumber"
                            wmsSelector={this.props.selector + 'DoorNumber'}
                            value={renderReceiptObj.DoorNumber}
                            onChangeFunc={this.handleChangeEvent}
                            label={this.props.customLabels.Label_Door}
                        />
                        <WmsTextArea
                            id="Notes"
                            name="Notes"
                            rows={5}
                            label={this.translate('Label_Notes')}
                            wmsSelector={this.props.selector + 'Notes'}
                            onChangeFunc={this.handleChangeEvent}
                            value={renderReceiptObj.Notes}
                            maxLength={4096}
                        />
                    </WmsContainer>
                </WmsCollapse>
            </div>
        )
    }
}
