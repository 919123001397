import React from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";

interface TuskInfoComponentProps {
    selector: string;
    handleCarrierObjChange: () => void;
    currentCarrierObj: {
        ApiKey: string;
        Nickname: string;
        AccountNumber: string;
    };
}

export function TuskInfoComponent(props: TuskInfoComponentProps): JSX.Element {
    const translate = (window as any).translate as any;

    return (
        <WmsCollapse
            id={"tuskInfoScreen"}
            headerLabel={translate("Label_Account_Information")}
            showCollapseText={false}
            showCollapseIcon={false}
        >
            <div className="account-info-section">
                <div>
                    <WmsInput
                        id="nickname"
                        inputName="Nickname"
                        wmsSelector={props.selector + "nickname"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_Account_Nick_Name")}
                        value={props.currentCarrierObj.Nickname}
                        required={true}
                        extraWrapperClass="w-50"
                    />
                    <WmsInput
                        id="AccountNumber"
                        inputName="AccountNumber"
                        wmsSelector={props.selector + "AccountNumber"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_User_beta_Account_Name")}
                        value={props.currentCarrierObj.AccountNumber}
                        required={true}
                        extraWrapperClass="w-50"
                    />
                    <WmsInput
                        id="apiKey"
                        inputName="ApiKey"
                        wmsSelector={props.selector + "apiKey"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_API_Key")}
                        value={props.currentCarrierObj.ApiKey}
                        required={true}
                        extraWrapperClass="w-50"
                    />
                </div>
            </div>
        </WmsCollapse>
    );
}
