import React, { Component } from 'react';
import { KendoGridWms } from '../../../../assets/ts/KendoGridWms.ts';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import { ObjectWms } from '../../../../assets/ts/ObjectWms.ts';

import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsGrid from '../../components/WmsGrid/WmsGrid.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsModel from './../../components/WmsModel/WmsModel.jsx';
import WmsButton from './../../components/WmsButton/WmsButton.jsx';

import * as GridConfig from './grid_configuration.jsx';
import * as GlobalService from './../../global_services';

export class ManageReceiptCharges extends Component {
    constructor(props) {
        super(props);


        this.translate = window.translate;

        this.state = {
            columns: GridConfig.ReceiptChargesGridColumns(GlobalService.GetRights()).columns,
            schema: GridConfig.ReceiptChargesGridSchema().schema,
            gridAppliedChargesTotalCharges: '0',
            isManualCharge: false,
            localKendoGrid: null,
            isOpenConfirmModel: false,
        }
    }

    onDataBound = (e) => {

        let grid = $("." + this.props.windowId + " #GridmanageReceiptAppliedCharges").data("kendoGrid");
        let gridData = grid.dataSource.view();

        for (let i = 0; i < gridData.length; i++) {
            if (gridData[i].ChargeOrigin == 0) {
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-predefined-required");
            }
        }

        let tempKendo = new KendoGridWms("GridmanageReceiptAppliedCharges", this.props.windowId, '');
        let totalCharges = tempKendo.sumByLocalMemoryGridContents(["TotalCharges"])[0];
        this.setState({ gridAppliedChargesTotalCharges: Number(Math.round(totalCharges + 'e2') + 'e-2') });

    }

    onEdit = (e) => {
        var grid = $('.' + this.props.windowId + ' #GridmanageReceiptAppliedCharges').data('kendoGrid');
        var columnIndex = grid.cellIndex(e.container);
        var fieldName = grid.thead.find("th").eq(columnIndex).data("field");

        if (fieldName == "NumberOfUnits" && e.model.ChargeOrigin == 1) {
            grid.closeCell();
        }
        if (fieldName == "ChargePerUnit" && e.model.ChargeOrigin == 1) {
            grid.closeCell();
        }
        if (fieldName == "ChargePerUnit" && e.model.ChargeOrigin == 3) {
            grid.closeCell();
        }
    }

    onGridSave = (e) => {

        var grid = $('.' + this.props.windowId + ' #GridmanageReceiptAppliedCharges').data('kendoGrid');

        var numberOfUnits = StringWms.isEmpty(e.model.NumberOfUnits) ? 0 : e.model.NumberOfUnits;
        if (e.values.hasOwnProperty("NumberOfUnits")) {
            numberOfUnits = StringWms.isEmpty(e.values.NumberOfUnits) ? 0 : e.values.NumberOfUnits;
        }

        var chargePerUnit = StringWms.isEmpty(e.model.ChargePerUnit) ? 0 : e.model.ChargePerUnit;
        if (e.values.hasOwnProperty("ChargePerUnit")) {
            chargePerUnit = StringWms.isEmpty(e.values.ChargePerUnit) ? 0 : e.values.ChargePerUnit;
        }

        var total = parseFloat((new Big(numberOfUnits)).times(chargePerUnit).round(4));

        e.model.set("TotalCharges", total);
        e.model.TotalCharges = total;
        grid.saveChanges();

        setTimeout(() => {
            let billingChargesData = grid._data;
            billingChargesData = ObjectWms.removeObjFromListOnParameterValue(billingChargesData, "test", null);

            let propsReceiptObj = this.props.sharedReceiptData.Receipt;
            propsReceiptObj["BillingCharges"] = billingChargesData;
            this.props.onReceiptDataSet(propsReceiptObj);
        }, 100);
    }

    selectRow = (param_kendogrid) => {

        var selectedRow = param_kendogrid.getNumberOfSelections();
        var manualChargeType = selectedRow > 0 ? param_kendogrid.identicalValuesInSelectedSet("ChargeOrigin", 2) : false;

        this.setState({
            isManualCharge: manualChargeType,
            localKendoGrid: param_kendogrid
        });

    }

    openConfirmationModal = () => {
        this.setState({
            isOpenConfirmModel: true
        });
    }

    ClickExit = () => {
        this.setState({
            isOpenConfirmModel: false
        });
    }

    deleteManualCharges = () => {

        var grid = $('.' + this.props.windowId + ' #GridmanageReceiptAppliedCharges').data('kendoGrid');

        grid.select().each(function () {
            grid.removeRow($(this));
        });

        var data = grid.dataSource.data().length > 0 ? grid.dataSource.data().toJSON() : [];

        let sharedOptionObj = Object.assign(
            {},
            this.props.sharedReceiptData.Receipt,
            {
                'BillingCharges': data
            }
        );
        this.props.onReceiptDataSet(sharedOptionObj);

        this.setState({
            isOpenConfirmModel: false,
            isManualCharge: false
        });
    }

    render() {
        return (
            <div className="receipt-charges-section" ref={this.props.divRefs}>
                <WmsCollapse id={'collapseReceiptCharges'} headerLabel={this.translate('Label_Receipt_Charges')} >
                    <WmsGrid
                        wmsSelector={this.props.selector + 'AppliedChargesGrid'}
                        gridKeySelection={'ChargeLabel'}
                        gridName={'GridmanageReceiptAppliedCharges'}
                        virtual={false}
                        id={'GridmanageReceiptAppliedCharges'}
                        columns={this.state.columns}
                        schema={this.state.schema}
                        showGridSummary={false}
                        windowId={this.props.windowId}
                        onSelection={this.selectRow}
                        clickEventOfContextMenu={() => null}
                        contextMenuFilter='tbody tr:not(.k-grid-edit-row)'
                        shawCheckbox={false}
                        showGridResult={false}
                        staticGridData={this.props.sharedReceiptData.Receipt.BillingCharges}
                        showSortColumns={false}
                        onDataBound={this.onDataBound}
                        gridEditable={{
                            confirmation: false
                        }}
                        onGridSave={this.onGridSave}
                        onEdit={this.onEdit}
                        menu={[
                            {
                                "name": this.translate('Label_Recalculate_AutoCharges'),
                                "value": [],
                                "disabled": (this.props.sharedTotalLineItems == 0 || this.props.sharedGifRecalculate),
                                "icon": 'fa fa-truck',
                                "color": "green",
                                "clickFunc": () => this.props.onSaveReceipt('recalculate'),
                                "wmsSelector": this.props.selector + "RecalculateAutoCharges",
                                "loading": this.props.sharedGifRecalculate,
                                "showToolTip": (this.props.sharedTotalLineItems == 0),
                                "tooltipText": this.translate('Label_Add_Line_Item'),
                                "isContextMenu": false,
                            },
                            {
                                "name": this.translate('Label_Create_A_Manual_Charge'),
                                "value": [],
                                "disabled": false,
                                "disabledText": "You may not add blahssss",
                                "icon": "fa fa-keyboard-o",
                                "color": "green",
                                "clickFunc": this.props.onOpenCreateManualChargeModal,
                                "wmsSelector": this.props.selector + "CreateManualCharge",
                                "isContextMenu": false,
                            },
                            {
                                "name": this.translate('Label_Delete'),
                                "value": [],
                                "disabled": !this.state.isManualCharge,
                                "disabledText": "You may not add blahssss",
                                "icon": "fa-trash",
                                "color": "red",
                                "clickFunc": (this.openConfirmationModal),
                                "wmsSelector": this.props.selector + "DeleteManualCharge",
                                "isContextMenu": false,
                            }
                        ]}
                    />
                    <div className="row wms-area-content-darker widthout-bg total-price">
                        <WmsInput
                            id='TotalCharges'
                            inputName='TotalCharges'
                            wmsSelector={this.state.selector + 'TotalCharges'}
                            value={this.state.gridAppliedChargesTotalCharges}
                            onChangeFunc={() => null}
                            label={this.translate('Label_Total_Charges')}
                        />
                    </div>
                    {this.state.isOpenConfirmModel && (
                        <WmsModel
                            id={this.props.windowId + 'confirmDeleteManualCharge'}
                            title={this.translate('Label_Confirm_Delete_Manual_Charge')}
                            width={500}
                            height={175}
                            class='confirmDeleteManualCharge'
                            onCloseFunc={this.ClickExit}
                            customClass='wms-model-new-ui-wrapper'
                            isOpen={this.state.isOpenConfirmModel}>
                            <div className='model-content-wrapper'>
                                {this.translate('Label_Confirm_Selected_Charges')}
                            </div>
                            <div className="footer-btn-holder">
                                <WmsButton
                                    label={this.translate('Label_Cancel')}
                                    wmsSelector={this.state.selector + 'Cancel'}
                                    onClickFunc={this.ClickExit}
                                    disabled={false}
                                    loading={false}
                                    buttonType='yellow'
                                    icon={'fa-ban'}
                                />
                                <WmsButton
                                    label={this.translate('Label_Confirm_Delete')}
                                    wmsSelector={this.state.selector + 'ConfirmDelete'}
                                    onClickFunc={this.deleteManualCharges}
                                    disabled={false}
                                    loading={false}
                                    buttonType='red'
                                    icon={'fa-trash'}
                                />
                            </div>
                        </WmsModel>
                    )}
                </WmsCollapse>
            </div>
        )
    }
}
