import React from 'react';


export class UpsEulaAgreementComponent extends React.Component {
    render() {
        return (
            <div className="ups-eula-agreement">
                <h2>UPS TECHNOLOGY AGREEMENT</h2>
                <b>UPS TECHNOLOGY AGREEMENT Version UTA 08072018</b>
                <p>
                    PLEASE CAREFULLY READ THE FOLLOWING TERMS AND CONDITIONS OF THIS UPS TECHNOLOGY AGREEMENT. BY INDICATING BELOW THAT YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU HAVE ENTERED INTO A LEGALLY BINDING AGREEMENT WITH UPS MARKET DRIVER, INC. ("UPS").
                </p>
                <p>
                    This Agreement comprises
                    <b>(1)</b> these General Terms and Conditions (including its attached Exhibit A (Definitions - General Terms and Conditions ) and Exhibit B (Country Specific Amendment to the General Terms and Conditions);
                </p>
                <p>
                    <b>(2)</b> the End User Rights (including its attached Exhibit A (Definitions - End User Rights), Exhibit B (UPS Technology), and Exhibit C (Permitted Territory)) available at<a href='https://www.ups.com/us/en/help-center/legalterms-conditions/technology-agreement.page' target="_blank"> https://www.ups.com/us/en/help-center/legalterms-conditions/technology-agreement.page </a>(as such End User Rights may change from time to time in accordance with its terms); and
                </p>
                <p>
                    <b>(3)</b> the documentation referenced in any of the foregoing, which are all incorporated by reference. You hereby confirm that You have read and fully understand BOTH the General Terms and Conditions AND the End User Rights which are available for Your review by accessing <a href="https://www.ups.com/us/en/helpcenter/legal-terms-conditions/technology-agreement.page" target="_blank">https://www.ups.com/us/en/helpcenter/legal-terms-conditions/technology-agreement.page</a> INCLUDING the documentation referenced therein. The Agreement may be presented to You more than once in conjunction with Your access and use of UPS Technology. Unless the version of the Agreement has changed, each instance serves to confirm the Agreement as mutually entered into and not to create an additional or separate agreement.
                </p>
                <b>For purposes of the Agreement:</b>
                <p>
                    "Customer" means the Person that is your employer, but is not a Service Provider, (1) and that has been assigned the UPS Account you use to register the first UPS Technology you access, if a UPS Account is required for such
                    registration, (2) and that has been assigned the first UPS Account you use with the UPS Technology you access, if a UPS Account is not required for registration but is required for use, or (3) when the first UPS Technology you
                    access does not require a UPS Account for registration or use.
                </p>
                <p>
                    "Service Provider" means a third party engaged by a UPS customer to assist such UPS customer in managing its shipping activity with the UPS Parties, including Billing Data Service Providers, that has been approved by UPS in
                    writing to perform such services for the UPS customer; provided however, UPS Affiliates may serve as Service Provider without a written approval by UPS.
                </p>
                <p>
                    "Service Provider Employee" means an employee of a Service Provider.
                    "You" or the possessive, "Your," means, as applicable: (i) you as an individual, if you are entering into this Agreement as an individual on behalf of no other third party for your own personal use of the UPS Technologies; (ii) you
                    as an individual and Customer, if you are accessing UPS Technology as part of your responsibilities as an employee of Customer; or (iii) you as an individual and your employer, if your employer is a Service Provider to a UPS
                    customer and you are accessing UPS Technology as part of your responsibilities as a Service Provider Employee to perform services for the benefit of a UPS customer. You represent and warrant You have reached the age of legal majority and, if applicable, You can form legally binding contracts under applicable law on behalf of Yourself, the Customer or Service Provider related to the UPS Technology. If at any time You are no longer authorized to form legally binding contracts under applicable law on behalf of Yourself, the Customer or Service Provider, as applicable, You may no longer use UPS Technology on Your own behalf or on behalf of such Customer or Service Provider. General Terms and Conditions
                </p>
                <b>1. Definitions.</b>
                <p>
                    Capitalized terms used in this Agreement have the meanings set forth on General Terms and Conditions Exhibit A attached hereto and End User Rights Exhibit A. In the case of any conflict between the terms of
                    the End User Rights and these General Terms and Conditions, these General Terms and Conditions shall control.
                </p>
                <b>2. License Grant.</b>
                <p>
                    <b>2.1.</b> Scope. UPS hereby grants to You and You accept, subject to the terms and conditions of this Agreement, a limited, revocable, non-sublicenseable, non-exclusive, non-transferable, license to use or access, as applicable, the UPS Technology and associated Technical Documentation in the Permitted Territory for such UPS Technology. The End User Rights contain additional general license rights and restrictions, as well as UPS Technology-specific
                    license rights and restrictions.
                </p>
                <b>2.2. General Restrictions - UPS Materials and Software.</b>
                <p>
                    You shall not, and shall cause Your employees and agents not to, sublicense, disclose or transfer the UPS Materials to any third party without the written consent of UPS. You agree not to modify (including corrections to the Software), reproduce, rent, lease, lend, encumber, distribute, redistribute, remarket or otherwise dispose of the UPS Materials or any part thereof without the consent of UPS, and hereby waive such rights granted under applicable law, except where such waiver is unenforceable. You agree not to duplicate the Software, except as required for its use in accordance with this Agreement, provided that You may make one (1) back-up copy of the Software solely for archival purposes. Such back-up copy shall include UPS's copyright and other proprietary notices, and shall be subject to all the terms and conditions of this Agreement.Notwithstanding anything to the contrary in this Agreement, You may not use the Software on an outsourced time-share or service bureau basis.
                </p>
                <b>3. Export Law Assurances.</b>
                <p>
                    You acknowledge that all UPS Materials provided hereunder are subject to the U.S. Export Administration Regulations ("EAR") administered by the U.S. Department of Commerce's Bureau of Industry and Security, as well as other U.S. laws and regulations. You agree to comply with the EAR and all applicable U.S. laws in your handling and use of all UPS Materials provided hereunder and to not export or reexport the UPS Materials except as authorized by the EAR, U.S. and other applicable laws. Without limiting the generality of the foregoing, you agree, represent and warrant that no UPS Materials will be accessed from, downloaded in,
                    released in, carried to, transferred to, transshipped through or to, exported to, or reexported to (1) the Restricted Territory (or a national or resident thereof) or (2) any person, entity or organization on the U.S. Treasury Department's list of Specially Designated Nationals or the U.S. Department of Commerce's Denied Persons List or Entity List. The countries and territories considered Restricted Territory and the persons, entities or organizations
                    on the aforementioned lists may change from time to time. You agree to stay current with and comply with this provision notwithstanding any such changes. For convenient reference only, information on the Restricted Territory
                    countries and territories and the persons, entities or organizations on the aforementioned lists may be found at:
                    <a href="http://www.treasury.gov/ofac/downloads/sdnlist.txt" target="_blank">http://www.treasury.gov/ofac/downloads/sdnlist.txt</a>,
                    <a href="http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx" target="_blank">http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx</a>, and
                    <a href="http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm" target="_blank">http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm</a>.
                </p>
                <b>4. UPS Materials.</b>
                <p>
                    <b>4.1.</b> Ownership of Intellectual Property Rights. You hereby acknowledge and agree that UPS owns all right, title and interest in and to, or has the right to license to You, the UPS Materials. You acknowledge that You have not
                    acquired any ownership interest in the UPS Materials and will not acquire any ownership interest in the UPS Materials by reason of this Agreement. You will not at any time do or knowingly permit to be done any act or thing
                    that would in any way impair the rights of UPS or its licensors in and to the UPS Materials. UPS and its licensors reserve all rights pertaining to the UPS Materials not specifically granted herein.
                </p>
                <b>4.2. Changes to UPS Materials and UPS Technology.</b>
                <p>
                    UPS may update, alter, modify or supplement any or all of the UPS Materials and/or UPS Technology at any time.
                </p>
                <b>5. Support Services.</b>
                <p>
                    <b>5.1.</b> Support and Maintenance. From time to time UPS, in its sole discretion, may, in response to Your request, choose to provide support or maintenance for the Software ("Support Services"). You hereby authorize UPS and its
                    authorized agents (the "Support Providers"), in order to provide Support Services, to access the Software, other applications which You may be using in conjunction with the Software and Your computer systems either (1)
                    remotely, via the Internet or other means (which may require the installation of additional software on Your computer systems by UPS or the Support Providers) ("Support Software"), or (2) through on-site visits at specific times
                    as mutually agreed upon by the parties. Each support session using Support Software will be separately approved by You. During such sessions UPS may see the Software as operating on the Your computer system and UPS may
                    assist You in making modifications to Your computer systems. You further grant UPS and Support Providers the right to manipulate and modify the Software and Your computer systems, applications, files and data as reasonably
                    necessary to provide You with Support Services. However, You agree that any Support Services will be provided at UPS's discretion and nothing in the Agreement shall be interpreted to obligate UPS to provide any Support
                    Services.
                </p>
                <b>5.2. Access to Proprietary Information.</b>
                <p>
                    You acknowledge and agree that You may disclose, or that UPS or the Support Providers may observe, Your information and data during the provision of Support Services by UPS or the Support Providers; and that such information and data shall be deemed non-confidential and thus, not covered by General Terms and Conditions Article 7, unless UPS has agreed otherwise in a signed confidentiality agreement
                    separate from this Agreement. In addition, You acknowledge that the remote communications sessions utilized by UPS or the Support Providers may be implemented through the Internet, which is inherently insecure, and You
                    agree that UPS or the Support Providers shall not be liable for any security breaches occurring on the Internet. You should take the foregoing into account when requesting Support Services from UPS or Support Providers.
                </p>
                <b>6. Suspension; Term and Termination.</b>
                <p>
                    <b>6.1.</b> Suspension of Rights. UPS may suspend Your rights to access any part of the UPS Systems through the UPS Technology or as necessary in UPS's sole discretion including without limitation, to (1) prevent access to any part
                    of UPS Systems or the UPS Technology that is not in compliance with the terms and conditions of this Agreement; (2) correct a material error in the UPS Systems or the UPS Technology or (3) comply with a law, regulation or
                    rule or any ruling of a court or other body of competent jurisdiction.
                </p>
                <b>6.2. Term.</b>
                <p>
                    This Agreement shall become effective upon Your assent by clickthrough below and shall remain in full force and effect thereafter until terminated as provided herein (the "Term").
                </p>
                <b>6.3. Hosted UPS Technology.</b>
                <p>
                    Certain UPS Technology is hosted by UPS, UPS Affiliates or vendors of UPS or UPS Affiliates. Hosted UPS Technology is hosted on servers in the United States of America and is scheduled to be
                    available twenty-four (24) hours a day, seven (7) days a week (other than when unavailable for maintenance); however, UPS does not guarantee the availability of hosted UPS Technology, or that access will be uninterrupted or
                    error-free. UPS reserves the right to interrupt, limit, or suspend hosted UPS Technology from time to time for purposes of maintenance, upgrades and similar reasons. You agree that neither UPS nor UPS Affiliates shall be held
                    responsible or liable for any Damages arising from any interruption, suspension or termination of hosted UPS Technology, regardless of the cause.
                </p>
                <b>6.4. Termination.</b>
                <p>
                    <b>a.</b> Either party may terminate this Agreement, and UPS may terminate any or all licenses to the UPS Technology granted hereunder, for convenience at any time upon written notice to the other party.
                    <b>b.</b> Notwithstanding the foregoing, this Agreement shall terminate without any further action needing to be taken by UPS (1) upon a breach of General Terms and Conditions Article 3, 7 or 10, or General Terms and Conditions
                    Sections 2.2 and 4.1 (third sentence); (2) in the event of Your bankruptcy, commencement of bankruptcy, corporate reorganization, civil rehabilitation, concordat, special liquidation or any other insolvency proceeding with
                    respect to You, or if You shall have a receiver, administrator, administrative receiver or liquidator appointed or shall pass a resolution for winding up, or a court shall make an order to that effect, (3) if You are a partner in or
                    Customer or Service Provider is a partnership and such partnership is dissolved, or (4) upon deletion of Your UPS profile.
                </p>
                <b>6.5. Effect of Termination.</b>
                <p>
                    <b>a.</b> Upon the termination of this Agreement for any reason whatsoever all licenses granted hereunder shall immediately terminate and You shall immediately cease and desist from all access to and use of the UPS Materials, and
                    destroy all UPS Materials in Your possession or control.
                </p>
                <p>
                    <b>b.</b> Upon termination of any license to a UPS Technology, You shall immediately cease and desist from access to and use of such UPS Technology and associated UPS Materials and destroy all such associated UPS Materials in Your possession or control.
                    6.6. Survival of Terms upon Termination. General Terms and Conditions Articles 1, 5, 7-9 and 12; Sections 4.1, 6.5 and 6.6; and Exhibit A and Exhibit B to these General Terms and Conditions; and the Articles and those Sections of the End User Rights identified in End User Rights Section 3.2 shall survive the termination of this Agreement for any reason.
                </p>
                <b>7. Confidential Information, Trade Secrets, Information.</b>
                <p>
                    <b>7.1.</b> Disclosure. During the Term and thereafter, You shall not use (except as permitted in connection with Your performance hereunder), disclose or permit any Person access to any Trade Secrets (including, without limitation, any Trade Secrets contained in the UPS Materials). During the Term and for a period of five (5) years thereafter, except as otherwise mandated by law, You shall not use, disclose, or permit any Person access to any Confidential Information, except as permitted in connection with Your performance hereunder. You acknowledge that if You breach this General Terms and Conditions Article 7, UPS may have no adequate remedy at law available to it, may suffer irreparable harm, and will be entitled to seek equitable relief. You agree to protect such Confidential Information and Trade Secrets with no less diligence than You protect Your own confidential or proprietary information. If disclosure to Confidential Information is required under provisions of any law or court order, You will notify UPS sufficiently in advance so UPS will have a reasonable opportunity to object.
                </p>
                <p>
                    <b>7.2.</b> Aggregation. You shall not aggregate the Information or derive or develop information, services or products that use the Information, other than as expressly permitted under this Agreement.
                </p>
                <p>
                    <b>7.3.</b> Data Export. You may not export, whether by (i) data export functionality built into the Software; (ii) extraction from the Software interface (e.g. screen scraping); or (iii) otherwise, any data from the UPS Databases and use
                    such data for comparing shipping rates or delivery times with the shipping rates or delivery times of any third party that is not a member of the UPS Parties.
                </p>
                <b>8. Warranties.</b>
                <p>
                    <b>8.1.</b> By Customer. You represent and warrant that (1) Customer is not headquartered in the Restricted Territory; (2) You will not use the UPS Technology in the Restricted Territory; and (3) You are not, nor is Customer under the
                    control of any Person on the U.S. Treasury Department list of Specially Designated Nationals, or the U.S. Department of Commerce Denied Persons List or Entity List (as they may be amended from time to time and which, for convenient reference only, may be found at <br /><br />
                    <a href="http://www.treasury.gov/ofac/downloads/sdnlist.txt" target="_blank">http://www.treasury.gov/ofac/downloads/sdnlist.txt</a>,<br />
                    <a href="http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx" target="_blank">http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx</a>, and <br /><br />
                    <a href="http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm" target="_blank">http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx </a> ), or incorporated in, a national resident of or government of the Restricted Territory.
                </p>
                <b>8.2. Disclaimers.M</b>
                <p>
                    <b>a.</b> UPS PARTIES WARRANT THAT FOR NINETY (90) DAYS FROM THE DELIVERY TO YOU OF SOFTWARE THAT SUCH SOFTWARE SHALL MATERIALLY OPERATE AS DESCRIBED IN THE SOFTWARE'S CORRESPONDING TECHNICAL DOCUMENTATION. UPS'S SOLE LIABILITY FOR A BREACH OF THE FOREGOING WARRANTY SHALL BE TO REPLACE ANY SUCH SOFTWARE. EXCEPT AS STATED IN
                    THE WARRANTY OF THE FOREGOING TWO SENTENCES, THE UPS MATERIALS ARE PROVIDED "AS IS WITH ALL FAULTS" AND IN THEIR PRESENT STATE AND CONDITION. NO WARRANTY, REPRESENTATION, GUARANTEE, CONDITION, UNDERTAKING OR TERM, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, AS TO THE CONDITION, QUALITY, DURABILITY, ACCURACY, COMPLETENESS, PERFORMANCE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY, QUIET ENJOYMENT, OR FITNESS FOR A PARTICULAR PURPOSE OR USE OF THE UPS MATERIALS IS GIVEN OR ASSUMED BY UPS AND ALL SUCH WARRANTIES, REPRESENTATIONS, CONDITIONS, UNDERTAKINGS AND TERMS ARE HEREBY EXCLUDED TO THE FULLEST EXTENT
                    PERMITTED BY APPLICABLE LAW, AS ARE ANY WARRANTIES ARISING FROM COURSE OF DEALING OR USAGE. UPS DOES NOT WARRANT THAT DEFECTS IN THE UPS MATERIALS WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY UPS OR ANY UPS REPRESENTATIVE SHALL CREATE A WARRANTY.
                </p>
                <p>
                    <b>b.</b> You furthermore acknowledge and agree that access by UPS or the Support Providers to Your computer systems, files and associated data pursuant to General Terms and Conditions Article 5 hereof is merely to facilitate Support Services on Your behalf, and You remain solely responsible for backing up Your computer systems, applications, files and data. ANY SUPPORT SERVICES OR SUPPORT SOFTWARE PROVIDED BY UPS OR A SUPPORT PROVIDER PURSUANT TO THIS AGREEMENT ARE PROVIDED "AS IS WITH ALL FAULTS", AND UPS MAKES NO WARRANTIES, EXPRESS OR IMPLIED, REGARDING ANY SUCH SUPPORT SERVICES OR SUPPORT SOFTWARE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, UPS HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTY RIGHTS OR FITNESS FOR A PARTICULAR PURPOSE, RELATED TO SUPPORT SERVICES OR SUPPORT SOFTWARE PROVIDED UNDER THE AGREEMENT AND ALL ASSOCIATED ADVICE, DIAGNOSES AND RESULTS. YOU ACKNOWLEDGE AND AGREE THAT UPS WILL NOT BE LIABLE FOR ANY ERROR, OMISSION, DEFAULT, DEFICIENCY, OR NONCONFORMITY IN THE SUPPORT SERVICES.
                </p>
                <p>
                    <b>c.</b> THE UPS PARTIES DO NOT GUARANTEE CONTINUOUS, UNINTERRUPTED, OR SECURE ACCESS TO THE UPS SYSTEMS AND ACCESS TO SUCH UPS SYSTEMS MAY BE INTERFERED WITH BY NUMEROUS FACTORS OUTSIDE OF UPS'S CONTROL. THE UPS PARTIES ARE NOT LIABLE FOR ANY DAMAGES OF ANY TYPE CAUSED BY SUCH INTERFERENCE.
                </p>
                <p>
                    <b>d.</b> SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTY, SO THE LIMITATIONS AND EXCLUSIONS IN THIS SECTION MAY NOT APPLY TO YOU. THE AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS. YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM JURISDICTION TO JURISDICTION. YOU AGREE AND ACKNOWLEDGE THAT THE LIMITATIONS AND EXCLUSIONS OF LIABILITY AND WARRANTY PROVIDED IN THIS AGREEMENT ARE FAIR AND REASONABLE.
                </p>
                <b>9. Limitation of Liability.</b>
                <p>
                    <b>a.</b> SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON CERTAIN DAMAGES SUCH AS LIMITATIONS ON (I) INCIDENTAL OR CONSEQUENTIAL DAMAGES, (II) DAMAGES RESULTING FROM GROSS NEGLIGENCE OR WILLFUL MISCONDUCT AND (III) DAMAGES RESULTING FROM PERSONAL INJURY OR DEATH. ACCORDINGLY, THE LIMITATIONS AND EXCLUSIONS IN THIS SECTION MAY NOT APPLY TO YOU AND ARE ONLY APPLICABLE TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION. IF YOU ARE DEALING AS A CONSUMER, YOUR STATUTORY RIGHTS THAT CANNOT BE WAIVED, IF ANY, ARE NOT AFFECTED BY THESE PROVISIONS.
                </p>
                <p>
                    <b>b.</b> THE UPS PARTIES SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, PUNITIVE, MULTIPLE, INCIDENTAL, OR SPECIAL DAMAGES, LOST
                    PROFITS, LOSS OF DATA OR DATA USE, LOST SAVINGS, OR COSTS OF PROCURING SUBSTITUTE GOODS ARISING OUT OF THIS AGREEMENT, DUE TO BREACH OF CONTRACT, TORT (INCLUDING
                    NEGLIGENCE), DELICT, USE OF THE UPS MATERIALS OR OTHERWISE, EVEN IF THE UPS PARTIES HAVE BEEN ADVISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING. EXCEPT FOR THE
                    LIMITATIONS OF LIABILITY SET FORTH IN END USER RIGHTS SECTION 1.7 AND GENERAL TERMS AND CONDITIONS SECTION 8.2, IN NO EVENT SHALL THE LIABILITY OF ALL THE UPS PARTIES
                    FOR ANY DAMAGES (DIRECT OR OTHERWISE) OR PENALTIES OR LOSS, REGARDLESS OF THE FORM OF ACTION OR CLAIM, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), DELICT OR OTHERWISE OF ANY TYPE EXCEED, IN THE AGGREGATE, ONE THOUSAND UNITED STATES DOLLARS (USD $1,000), ANY CLAIM FOR DAMAGES IN EXCESS THEREOF BEING HEREBY WAIVED
                    BY YOU.
                </p>
                <p>
                    <b>c.</b> FOR THE AVOIDANCE OF DOUBT AND CONSISTENT WITH THE SECOND PARAGRAPH OF THESE GENERAL TERMS AND CONDITIONS, THE PRESENTMENT OF THIS AGREEMENT (VERSION UTA
                    08072018 (UPS.COM)) MORE THAN ONCE TO YOU DOES NOT CHANGE THE UPS PARTIES' TOTAL AGGREGATE LIABILITY ABOVE ONE THOUSAND UNITED STATES DOLLARS (USD $1,000).
                </p>
                <p>
                    <b>d.</b> CLAIMS NOT MADE WITHIN SIX (6) MONTHS AFTER THE FIRST EVENT GIVING RISE TO A CLAIM SHALL BE DEEMED WAIVED.
                </p>
                <b>10. Use of Name and Publicity.</b>
                <p>
                    Except as expressly provided in this Agreement, You agree that You shall not, without separate prior written consent of UPS in each instance, use in advertising, publicity or otherwise the name of
                    the UPS Parties (including without limitation United Parcel Service of America, Inc.), or any partner or employee of the UPS Parties, nor any trade name, trademark, trade dress or simulation thereof owned by the UPS Parties.
                </p>
                <b>11. Notices.</b>
                <p>
                    Except as specifically provided in this Agreement, all notices, demands or other communications required or permitted hereunder shall
                    be in writing and shall be given as follows: If by You: by personal delivery, UPS Next Day Air delivery (notice deemed effective one business day after dispatch); by facsimile or telecopier transmission if a transmission confirmation is received by the sending party (notice deemed effective on date confirmation is received); or by certified mail, return receipt requested, postage prepaid (notice deemed effective on tenth business day following placement in mail) to UPS, 35 Glenlake Parkway, Atlanta, Georgia 30328, attention: UPS Legal Department, facsimile: (404) 828-6912; and If by UPS: by each method available to You as well as electronic mail (notice deemed effective on date of transmission); to the address, email address or facsimile number, as applicable, (1) of Your registration information for the UPS Technology as provided to UPS, (2) of a UPS Account You use with the UPS Technology or (3) if neither (1) nor (2) above apply, then to the address, email address or facsimile number, as applicable, that You have otherwise provided to UPS. Either party may change its address, email address or facsimile number for notice through thirty (30) days' prior written notice to the other party.
                </p>
                <b>12. Miscellaneous.</b>
                <p>
                    <b>12.1.</b> Independent Parties. The parties are independent parties and nothing herein shall be construed as creating an employment or agency relationship, partnership, and/or joint venture between the parties. Neither party is granted
                    any right or authority to assume or to create any obligation or responsibility, express or implied, on behalf of or in the name of the other party, or to bind such other party in any manner.
                </p>
                <p>
                    <b>12.2.</b> Waiver. No waiver of any provision of this Agreement, or any rights or obligations of either party under this Agreement, shall be effective, except pursuant to a written instrument signed by the party or parties waiving
                    compliance, and any such waiver shall be effective only in the specific instance and for the specific purpose stated in such writing.
                </p>
                <p>
                    <b>12.3.</b> Severability of Provisions. In the event that any provision of this Agreement is found to be invalid or unenforceable pursuant to judicial decree or decision, the remainder of this Agreement shall remain valid and
                    enforceable according to its terms.
                </p>
                <p>
                    <b>12.4.</b> Assignment. This Agreement, including any rights, licenses or obligations under this Agreement, may not be assigned by You to any other Person or entity without the prior written consent of UPS. UPS may assign,
                    delegate or transfer all or any part of this Agreement or any rights hereunder to any member of the UPS Parties without the need for any approval or consent from You. For these purposes, "Assignment" shall include, but is not
                    limited to, any merger or sale of all or substantially all of the assets of the assigning party or any transfer of this Agreement, or any portion hereof, by operation of law or otherwise, or any sale or other transfer of thirty percent
                    (30%) or more of the voting shares/interests of the assigning party or control thereof. In the event of any permitted Assignment of this Agreement, this Agreement shall be binding upon and inure to the benefit of each of the
                    parties and their respective legal successors and permitted assigns.
                </p>
                <p>
                    <b>12.5.</b> Taxes. Any fees payable under this Agreement do not include any taxes and fees (including, but not limited to, any applicable withholding taxes and VAT or any other tax or fee) levied by any duly constituted taxing
                    authority against the fees payable to UPS hereunder. You shall be solely responsible for the calculation of and payment of any such taxes to the relevant taxing authority, and shall not reduce the amount of the fees payable for
                    such tax payment.
                </p>
                <p>
                    <b>12.6.</b> Governing Law; Jurisdiction and Language. To the fullest extent permitted by applicable law, this Agreement and any claim, case, or controversy arising out of or relating to this Agreement (whether for breach of contract,
                    tort or otherwise) shall be governed by and construed in accordance with the laws of the State of New York, excluding (1) its conflict of law principles; (2) the United Nations Convention on Contracts for the International Sale of Goods; (3) the 1974 Convention on the Limitation Period in the International Sale of Goods; and (4) the Protocol amending the 1974 Convention, done at Vienna, April 11, 1980. The parties declare that they have required that this Agreement and all documents related hereto, either present or future, be drawn up in the English language only. Les parties declarent qu'elles exigent que cette entente et tous les documents y afferents, soit pour le present ou
                    l'avenir, soient rediges en langue anglaise seulement. To the fullest extent permitted by applicable law and consistent with valid entry into a binding agreement, the controlling language of this Agreement is English and any translation You have received has been provided solely for Your convenience. To the fullest extent permitted by applicable law, all correspondence and communications between You and UPS under this Agreement must be in the English language. In the event You have entered into this Agreement by means of the Internet display of a translated version of this Agreement in a language other than U.S. English, You may view the U.S. English language
                    version of this Agreement by accessing <a href="https://www.ups.com/us/en/help-center/legal-terms-conditions/technology-agreement.page" target="_blank">https://www.ups.com/us/en/help-center/legal-terms-conditions/technology-agreement.page</a>. THE EXCLUSIVE JURISDICTION FOR ANY CLAIM, CASE, OR CONTROVERSY ARISING OUT OF OR RELATING TO THIS AGREEMENT (WHETHER FOR BREACH OF CONTRACT, TORT OR OTHERWISE) SHALL BE A FEDERAL OR STATE COURT IN ATLANTA, GEORGIA, AND THE PARTIES HEREBY CONSENT TO SUCH EXCLUSIVE JURISDICTION AND IRREVOCABLY WAIVE AND SHALL NOT ASSERT ANY DEFENSES BASED ON LACK OF IN PERSONAM JURISDICTION, IMPROPER VENUE OR INCONVENIENT FORUM. Notwithstanding the foregoing, if and to the extent subsequent separate or ancillary proceedings in another U.S. or foreign court are necessary in order to enforce a
                    judgment of the court in Atlanta, Georgia, or otherwise as is necessary to provide complete relief and full resolution of all issues in dispute, the Parties may institute such subsequent separate or ancillary proceedings in any such U.S. or foreign court, and the Parties hereby consent to the non-exclusive jurisdiction of such court and hereby waive any defenses therein based on lack of in personam jurisdiction, improper venue or inconvenient forum. Notwithstanding anything herein to the contrary, UPS shall be entitled to interim relief or provisional remedies before any court having jurisdiction. You agree to the admissibility of computer records and electronic evidence in
                    any dispute herein. For the avoidance of doubt, dispute resolution provisions contained in any agreement You have entered into with a member of the UPS Parties related to UPS services, including for example, the applicable UPS Terms and Conditions of Carriage/Service, shall govern whenever such provisions are applicable to any claim or controversy. SEE SECTION 12.15 AND EXHIBIT B FOR COUNTRY-SPECIFIC DEVIATIONS TO THIS AGREEMENT IF YOU ARE A RESIDENT OF OR YOUR REGISTERED OFFICE IS LOCATED IN ONE OF THE FOLLOWING COUNTRIES OR TERRITORIES: MIDDLE EASTERN COUNTRIES, BANGLADESH, INDONESIA, ISRAEL, THE UNITED STATES OF AMERICA OR PUERTO RICO.
                </p>
                <p>
                    <b>12.7.</b> Force Majeure. Neither party hereto shall be liable for the failure to perform any of its obligations under this Agreement or for any Damages if such failure is caused by any reason beyond its reasonable control including without limitation, any occurrence of any act of God, labor strike or dispute, industrial disturbance, governmental emergency order, judicial or government action, emergency regulations, sabotage, riots, vandalism, electronic failure, major computer hardware or software failures, equipment delivery delays, acts of third parties, or act of terrorism.
                </p>
                <p>
                    <b>12.8.</b> Remedies. Any remedies provided herein are non-exclusive.
                </p>
                <p>
                    <b>12.9.</b> Compliance with Laws. Each party, in connection with its performance hereunder, shall strictly comply with all applicable laws, rulings, and regulations and shall take no actions which would cause the other party to be in violation of any laws, rulings or regulations applicable to it, including, where required, You as a licensee filing this Agreement with a governmental entity. You specifically acknowledge that the UPS Materials provided hereunder may contain encryption functionality. You acknowledge and agree that by downloading, importing or using the UPS Materials in any country or territory outside the United States you, not UPS, assume full responsibility for compliance with all the laws and regulations of such country or territory, including, without limitation, all laws and regulations governing the import, use, distribution, development, or transfer of encryption software or technology and all requirements of registration or licensing related to same.
                </p>
                <p>
                    <b>12.10.</b> Data Practices. In order to perform pickup and delivery services, and in connection with Your use of the UPS Technology, the UPS package delivery company in Your jurisdiction, whose name and address may be found
                    under "Contact UPS" on the UPS Web Site for Your jurisdiction ("UPS Delivery Co."), collects, processes and uses personal information. UPS Market Driver, Inc., 35 Glenlake Parkway, N.E., Atlanta, Georgia, USA 30328 and
                    the other UPS Parties receive personal information and use it for the Purposes defined below. The UPS Parties process personal information in accordance with applicable data protection laws. The personal information is used for the purposes (the "Purposes") set forth in, and is subject to, the UPS Privacy Notice published on UPS's web site at <a href="https://www.ups.com/us/en/help-center/legal-terms-conditions/privacy-notice.page" target="_blank">https://www.ups.com/us/en/help-center/legal-terms-conditions/privacy-notice.page</a> (hereby incorporated by reference into this Agreement). The personal information may be disclosed to certain
                    recipients ("Recipients") as described in the UPS Privacy Notice. You acknowledge You have read and fully understand the UPS Privacy Notice. You represent and warrant to UPS that when You or Your employees, agents or contractors ("Shipper Parties") provide UPS Delivery Co. with personal information: (1) the Shipper Parties have collected the personal information lawfully, and have the right and authority to provide the personal information to the UPS Parties for any uses permitted under these General Terms and Conditions Section 12.10; (2) You or another Shipper Party have notified each individual identified by the personal information (including all package addressees), as required by applicable law, that UPS will be processing the personal information in accordance with the UPS Privacy Notice published
                    at <a href="https://www.ups.com/us/en/help-center/legal-terms-conditions/privacy-notice.page" target="_blank">https://www.ups.com/us/en/help-center/legal-terms-conditions/privacy-notice.page</a> in effect at the time of shipping, that the personal information may be provided by UPS to the Recipients set forth above, and that the personal information may be transferred to countries or territories other than the country or territory in which the UPS Parties originally collected the information (which countries or territories may not have the same data protection laws as the country or territory in which You originally provided the information); and (3) You have obtained informed and specific consent from any package addressee or recipient, as required by law, that UPS may send e-mail and other notifications related to the agreed shipment services. You further agree to receive non-marketing telephone calls and text messages relating to the UPS pickup and delivery services (including, without limitation, collections calls and text messages) from or on behalf of UPS at any wireless telephone number assigned to Your account. You understand and agree that such calls or text messages may be prerecorded and/or delivered through the use of an automatic telephone dialing system and that Your wireless carrier's message and data rates may apply to Your receipt of such calls and text messages at a cellular telephone number. You understand and agree that any telephone number(s) that You provide to UPS will be true, accurate, current, and complete, and You will promptly update any such number as necessary to keep it true, accurate, current, and complete.
                </p>
                <p>
                    <b>12.11.</b> Non-Exclusivity. Nothing in this Agreement shall be construed to preclude or restrict UPS in any way from entering into similar arrangements with any other Person or from dealing or contracting directly with mutual customers of the parties.
                </p>
                <p>
                    <b>12.12.</b> Entire Agreement; Amendment. This Agreement constitutes the entire understanding and agreement between the parties with respect to the subject matter of this Agreement and supersedes any and all (1) prior or contemporaneous representations, understandings and agreements related thereto and (2) any prior versions of the UPS Technology Agreement between UPS and You, all of which are merged in this Agreement. Such merger will not be effective as to Software. The UPS Technology Agreement current at the time You receive a particular version of the Software will govern Your use of such Software version at all times. Any Corporate Technology
                    Agreement between UPS and Customer, whether entered into before or after the date of this Agreement, shall supersede this Agreement. Any UPS Technology Agreement between UPS and You having a version more recent than Version UTA08072018 shall supersede this Agreement. The superseding of any prior agreement shall not abridge UPS's rights against You as a result of any violation or breach of such prior agreement before the date of this Agreement. This Agreement may not be modified or amended except by a writing signed by authorized representatives of the parties to this Agreement; provided, however, UPS may modify the End User Rights pursuant to End
                    User Rights Section 3.1 and the UPS Materials and UPS Technology pursuant to these General Terms and Conditions Section 4.2. A writing with electronic signatures shall not qualify to modify or amend the Agreement.
                </p>
                <p>
                    <b>12.13.</b> Waiver: European Union Notices. To the fullest extent permitted by applicable law, if You are a resident of or Your registered office is located in a member state of the European Union, You waive all notices,
                    acknowledgements and confirmations relative to contracting by electronic means which may be required under Articles 10(1), 10(2), 11(1) and 11(2) of EU Directive 2000/31/EC as implemented in Your jurisdiction in respect of Your use of the UPS Technology. If You are a resident of any European Union member state, You understand You have a right to withdraw from this Agreement in the first fourteen (14) days after Your acceptance, and to the fullest extent permitted by applicable law, You hereby expressly renounce the 14-day right of withdrawal in exchange of UPS making UPS Technologies available to You immediately upon entering in this Agreement.
                </p>
                <p>
                    <b>12.14.</b> NOTICE: Processing of Personal Information pursuant to UPS Privacy Notice. Except as You indicate to the contrary pursuant to the methods specified in the UPS Privacy Notice (which preferences may be changed by You at any time), You hereby acknowledge that personal information may be processed for the Purposes as set forth in General Terms and Conditions Section 12.10. You also agree that where You are the package addressee or recipient, You have received notice of the processing and use of personal information as described in General Terms and Conditions Section 12.10.
                </p>
                <p>
                    <b>12.15.</b> Country-Specific Terms. If You are a resident of or Your registered office is located in one of the countries or territories listed below, the terms of Exhibit B shall apply to You. In the case of conflict or ambiguity between any provision contained in the body of these General Terms and Conditions and any provision contained in Exhibit B, the provision contained in Exhibit B shall prevail if Exhibit B applies to You. a. Bahrain, Kuwait, Oman, Qatar, Saudi Arabia, UAE, Jordan, Tunisia, Algeria, Djibouti, Iraq, Lebanon, Libya, Morocco, and Mauritania ("Middle Eastern Countries"). b. Bangladesh, Indonesia, Israel, the United States of America and Puerto Rico.
                </p>
                <h4>EXHIBIT A</h4>
                <h5>DEFINITIONS- GENERAL TERMS AND CONDITIONS</h5>
                <p>
                    Affiliates means third parties that control, are controlled by, or under common control with, whether directly or indirectly, a Person. For purposes of this definition, the term "control" (including with correlative meanings, the terms "controlled by" and "under common control with") means the possession directly or indirectly of the power to direct or cause the direction of the management and policies of an entity,whether through the ownership of voting securities, by trust, management agreement, contract or otherwise.
                </p>
                <p>
                    Agreement is defined in the second paragraph of these General Terms and Conditions.
                </p>
                <p>
                    Alternate Billed Shipment(s) means shipments tendered to the UPS Parties on Your behalf by another Person where such shipments are charged against Your UPS Account. Assignment has the definition provided in General Terms and Conditions Section 12.4.
                </p>
                <p>
                    Billing Data Service Provider means a third party services provider (1) hired by You for providing services for You to close the Customer Billing Cycle, and (2) who has been identified to UPS by You for receipt of Billing Data from UPS to You via the UPS Systems, using the approved secure transfer method which may be modified from time to time by UPS in accordance with this Agreement.
                </p>
                <p>
                    Confidential Information means any information or material, other than Trade Secrets, that is of value to UPS and is not generally known to third parties, or that UPS obtains from any third party (including without limitation the UPS Parties) that UPS treats as proprietary whether or not owned by UPS. Confidential Information shall include Information. Confidential Information shall not include information that You can show is: (1) known by You at the time of receipt from UPS and not subject to any other nondisclosure agreement between the parties; (2) now, or which hereafter becomes, generally known to the public through no fault of You; (3) otherwise lawfully and independently developed by You without reference to Confidential Information; or (4) lawfully acquired by You from a third party without any obligation of confidentiality.
                </p>
                <p>
                    Customer is defined in the third paragraph of the General Terms and Conditions.
                    Damages means any claims, losses, damages, rulings, judgments and costs and expenses (including without limitation attorneys fees).
                    End User Rights means the document available at
                    <a href="https://www.ups.com/us/en/help-center/legal-terms-conditions/technology-agreement.page" target="_blank">https://www.ups.com/us/en/help-center/legal-terms-conditions/technology-agreement.page</a> and described in the second paragraph of these General Terms and Conditions.
                    General Terms and Conditions means this document.
                    Inbound Shipments means shipments tendered to the UPS Parties for delivery to You.
                    Information means information provided from the UPS Systems (i) related to services provided by the UPS Parties or (ii) generated in connection with You shipping with the UPS Parties, including without limitation, Tendered Shipments.
                    Middle Eastern Countries has the definition provided in General Terms and Conditions Section 12.15. Outbound Shipments means a shipment tendered to the UPS Parties by You.
                    Permitted Territory means for any UPS Technology those countries and territories associated with such UPS Technology on End User Rights Exhibit C.
                    Person means any individual, corporation, limited liability company, partnership, joint venture, association, joint stock company, trust, unincorporated organization or other legal entity.
                </p>
                <p>
                    Purposes has the definition provided in General Terms and Conditions Section 12.10. Recipients has the definition provided in General Terms and Conditions Section 12.10. Restricted Territory means those countries or territories subject to a comprehensive economic sanctions program administered by the U.S. Department of the Treasury, Office of Foreign Assets Control ("OFAC") or to any other
                    general prohibition on the use, exportation, or reexportation of the UPS Technology under U.S. sanctions or export control laws. Countries or territories subject to OFAC embargo or sanctions can change at any time. For Your
                    convenient reference only, the following links provide information pertaining to such countries or territories:
                    <a href="http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx" target="_blank">http://www.treasury.gov/resource-center/sanctions/Programs/Pages/Programs.aspx</a>, and
                    <a href="http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm" target="_blank">http://www.bis.doc.gov/complianceandenforcement/liststocheck.htm.</a>
                </p>
                <p>
                    Service Provider has the definition provided in the third paragraph of the General Terms and Conditions.
                    Service Provider Employee has the definition provided in the third paragraph of the General Terms and Conditions.
                    Shipper Parties has the definition provided in General Terms and Conditions Section 12.10.
                    Software means those items of UPS Technology that (i) are software and that are provided by UPS to You under this Agreement, (excluding
                    sample computer software code) and any associated Technical Documentation, and (ii)
                    any Updates thereto to the extent provided by UPS to You under this Agreement.
                    Support Services has the definition provided in General Terms and Conditions Section 5.1.
                    Support Software has the definition provided in General Terms and Conditions Section 5.1.
                    Support Providers has the definition provided in General Terms and Conditions Section 5.1.
                    Technical Documentation means collectively any and all documentation and/or sample computer software code regarding the UPS Technology or the UPS Marks provided or made available to You by UPS hereunder. Tendered Shipment means a shipment tendered (i) by or for You to the UPS Parties for delivery or (ii) by a third party to the UPS Parties for delivery to You, which can be an Outbound Shipment, Alternate Billed Shipment or Inbound Shipment.
                    Term has the definition provided in General Terms and Conditions Section 6.2.
                    Trade Secret means any information of UPS or that UPS acquired from a third party (including without limitation the UPS Parties) which is not commonly known by or available to the public, which (1) derives economic value,
                    actual or potential, from not being generally known to and not being readily ascertainable by proper means by other persons who can obtain economic value from its disclosure or use, and (2) is the subject of efforts that are
                    reasonable under the circumstances to maintain its secrecy.
                    Update(s) shall mean maintenance, error corrections, modifications, updates, enhancements or revisions to the UPS Materials. UPS means UPS Market Driver, Inc.
                    UPS Account means any shipping account assigned to You by a member of the UPS Parties, including, without limitation, those accounts assigned to users of UPS.com shipping known as "temporary accounts" and UPS Freight shipping accounts.
                    UPS Databases means databases of proprietary information related to the shipping services of the UPS Parties and distributed with or for use with Software.
                    UPS Delivery Co. has the definition provided in General Terms and Conditions Section 12.10. UPS Marks means the word mark "UPS" as shown in various trademark registrations including but not limited to U.S. Trademark Registration No. 966,724, and the mark "UPS & Stylized Shield Device" as shown below and as shown in various trademark registrations, including but not limited to U.S. Trademark Registration Nos.: 2,867,999, 2,965,392, 2,973,108, 2,978,624, 3,160,056 and European Community Trademark Registration Nos.:
                    3,107,026, 3,107,281, and 3,106,978.
                </p>
                <p>
                    UPS Materials means collectively the UPS Technology, the UPS Databases, the Technical Documentation, the Information, the Software, the UPS Marks, and the UPS Systems.
                    UPS Parties means UPS and existing Affiliates, and their respective shareholders, officers, directors, employees, agents, partners, third party suppliers and third party licensors.
                    UPS Privacy Notice means the privacy notice available at https://www.ups.com/us/en/help-center/legal-terms-conditions/privacy-notice.page.
                    UPS Systems means the UPS computer and network systems accessed by the UPS Technology. UPS Technology means those products identified on End User Rights Exhibit B. You is defined in the third paragraph of this Agreement.
                </p>
                <h4>EXHIBIT B</h4>
                <h5>COUNTRY SPECIFIC AMENDMENT TO THE GENERAL TERMS AND CONDITIONS</h5>
                <p>
                    If You are a resident of or Your registered office is located in any country or territory identified in Section 12.15 of the General Terms and Conditions, the following terms replace or modify the referenced terms of the General Terms and Conditions. All terms of the General Terms and Conditions that are not changed by these amendments remain unchanged and in effect.
                    1. Middle Eastern Countries, Bangladesh, Indonesia, Israel, the U.S. and Puerto Rico.
                    1.1 Geographic Scope and Applicability.
                    a. You warrant and represent that You are a resident of or Your registered office is located in one of the following countries: Middle Eastern Countries, Bangladesh, Indonesia, Israel, the United States of America or Puerto Rico.
                    b. Subject to Section 1.1(a) of this Exhibit B above, You and UPS agree to modify the General Terms and Conditions as set forth in Section 1.2 of this Exhibit B below.
                    c. Except as amended as set forth herein, the General Terms and Conditions (including the Exhibits thereto) shall continue in full force and effect until expired or terminated and reflect the entire agreement between You and UPS regarding the subject matter hereof. To the extent that any provisions of this Exhibit B are inconsistent with the General Terms and Conditions, the terms of this Exhibit B shall govern solely with regard to the subject of the
                    inconsistency.
                    d. In consideration of the promises and mutual covenants of the parties, You and UPS agree that the General Terms and Conditions shall be modified as set forth in Section 1.2 of this Exhibit B below.
                    1.2 Amendments.
                    a. If You are a resident of or Your registered office is located in one of the Middle Eastern Countries, Bangladesh, Indonesia or Israel, Section 9 of the General Terms and Conditions shall be deleted in its entirety and replaced
                    with the following:
                    "9. Limitation of Liability.
                    9.1 Except as expressly stated in Section 9.2:
                    a. UPS Parties shall not in any circumstances have any liability for any Damages which may be suffered by the Customer (or any person claiming under or through the Customer), whether the same are suffered directly or
                    indirectly or are immediate or consequential, and whether the same arise in contract, tort (including negligence) or otherwise howsoever, which fall within any of the following categories:
                    i. special damage even if UPS Parties was aware of the circumstances in which such special damage could arise;
                    ii. loss of profits;
                    iii. loss of anticipated savings;
                    iv. loss of business opportunity;
                    v. loss of goodwill;
                    vi. costs of procuring substitute goods arising out of this Agreement;
                    vii. loss or corruption of data or data use.
                    b. The total liability of UPS Parties, whether in contract, tort (including negligence) or otherwise and whether in connection with this Agreement or any collateral contract, shall in no circumstances exceed, in the aggregate, a sum
                    equal to one thousand United States dollars (USD $1,000); and
                    c. You agree that, in entering into this Agreement, either it did not rely on any representations, whether written or oral, of any kind or of any person other than those expressly set out in this Agreement or (if it did rely on any
                    representations, whether written or oral, not expressly set out in this licence) that it shall have no remedy in respect of such representations and, in either case, UPS Parties shall have no liability in any circumstances otherwise
                    than in accordance with the express terms of this Agreement.
                    9.2 The exclusions in Section 9.1 shall apply to the fullest extent permitted by applicable law, but UPS Parties do not exclude liability for:
                    a. death or personal injury caused by the negligence of UPS Parties, their officers, employees, contractors or agents;
                    b. fraud or fraudulent misrepresentation; or
                    c. any other liability which may not be excluded by law
                </p>
                <p>
                    <b>9.3</b> For the avoidance of doubt, the presentment of this Agreement (version UTA 08072018) more than once to you does not change the UPS Parties' total aggregate liability above one thousand united states dollars (USD $1,000).
                </p>
                <p>
                    <b>9.4</b> Claims not made within six (6) months after the first event giving rise to a claim shall be deemed waived."
                    b. If You are a resident of or Your registered office is located in one of the Middle Eastern Countries, Bangladesh, Indonesia, Israel, the United States of America or Puerto Rico, Section 12.6 of the General Terms and Conditions
                    shall be deleted in its entirety and replaced with the following:
                    "12.6 Governing Law and Arbitration.
                </p>
                <p>
                    <b>a.</b> If You are a resident of or Your registered office is located in one of the Middle Eastern Countries, any dispute arising out of or in connection with this Agreement, including any question regarding its existence, validity or
                    termination, shall be referred to and finally resolved by arbitration under the Arbitration Rules of the DIFC-LCIA Arbitration Centre, which the Arbitration Rules of the DIFC-LCIA Arbitration Centre are deemed to be incorporated by reference into this clause. The number of arbitrators shall be one. The seat, or legal place, of arbitration shall be the Dubai International Financial Centre. The language to be used in the arbitration shall be English. The governing law of the Agreement shall be the substantive laws of England and Wales. You hereby represent and warrant that You have the authority to enter into an agreement to arbitrate in accordance with this
                    clause and any applicable law.
                </p>
                <p>
                    <b>b.</b> If You are a resident of or Your registered office is located in Bangladesh or Indonesia, any dispute arising out of or in connection with this Agreement, including any question regarding its existence, validity or termination,
                    shall be referred to and finally resolved by arbitration under the Arbitration Rules of the Singapore International Arbitration Centre, which the Arbitration Rules of the Singapore International Arbitration Centre are deemed to be
                    incorporated by reference into this clause. The number of arbitrators shall be one. The seat, or legal place, of arbitration shall be the Singapore International Arbitration Centre. The language to be used in the arbitration shall be
                    English. The governing law of the Agreement shall be the substantive laws of England and Wales. You hereby represent and warrant that You have the authority to enter into an agreement to arbitrate in accordance with this
                    clause and any applicable law.
                </p>
                <p>
                    <b>c.</b> If You are a resident of or Your registered office is located in Israel, any dispute arising out of this Agreement, or the breach thereof, shall be settled by arbitration in accordance with the International Arbitration Rules of the
                    Israeli Institute of Commercial Arbitration, which the International Arbitration Rules of the Israeli Institute of Commercial Arbitration are deemed to be incorporated by reference into this clause. The number of arbitrators shall
                    be one. The parties also agree to abide by and to perform the award or judgment of the arbitrator as the final decision respecting such dispute. The language to be used in the arbitration shall be English. The governing law of the
                    Agreement shall be the substantive laws of England and Wales. You hereby represent and warrant that You have the authority to enter into an agreement to arbitrate in accordance with this clause and any applicable law.
                </p>
                <p>
                    <b>d.</b> If You are a resident of or Your registered office is located in the United States of America or Puerto Rico, any dispute arising out of this Agreement, or the breach thereof, shall be governed as set forth in Attachment 1
                    (Dispute Resolution in the U.S. and Puerto Rico) attached hereto.
                </p>
                <p>
                    <b>e.</b> To the fullest extent permitted by applicable law and consistent with valid entry into a binding agreement, the controlling language of this Agreement is English and any translation You have received has been provided solely
                    for Your convenience. To the fullest extent permitted by applicable law, all correspondence and communications between You and UPS under this Agreement must be in the English language. In the event You have entered into
                    this Agreement by means of the Internet display of a translated version of this Agreement in a language other than U.S. English, You may view the U.S. English language version of this Agreement by accessing <a href="https://www.ups.com/us/en/help-center/legal-terms-conditions/technology-agreement.page" target="_blank">https://www.ups.com/us/en/help-center/legal-terms-conditions/technology-agreement.page</a>.
                </p>
                <p>
                    <b>f.</b> Notwithstanding any other term of this Agreement, dispute resolution provisions contained in any agreement You have entered into with a member of the UPS Parties related to UPS Services, including for example, the applicable UPS Terms and Conditions of Carriage/Service, shall govern whenever such provisions are applicable to any claim or controversy.
                </p>
                <p>
                    <b>c.</b> If You are a resident of or Your registered office is located in one of the Middle Eastern Countries, Bangladesh, Indonesia or Israel, Section 12.16 shall be appended to the General Terms and Conditions as follows:
                    "12.16 Interpretation. The following rules of interpretation shall apply to this Agreement:
                </p>
                <p>
                    <b>a.</b> Section and Exhibit headings shall not affect the interpretation of this Agreement.
                    <b>b.</b> A person includes a natural person, corporate or unincorporated body (whether or not having separate legal personality).
                    <b>c.</b> Unless the context otherwise requires, words in the singular shall include the plural and in the plural include the singular.
                    <b>d.</b> Unless the context otherwise requires, a reference to one gender shall include a reference to the other gender.
                </p>
                <p>
                    <b>e.</b> Any reference to an English legal term for any action, remedy, method of judicial proceeding, legal document, legal status, court, official or any legal concept or thing shall, in respect of any jurisdiction other than England, be deemed to include a reference to what most nearly approximates to the English legal term in that jurisdiction.
                </p>
                <p>
                    <b>f.</b> A reference to a statute or statutory provision is a reference to it as amended, extended or re-enacted from time to time, whether before or after the date of this Agreement, and in the case of a reference to a statute is also to all subordinate legislation made under that statute whether before or after the date of this Agreement.
                </p>
                <p>
                    <b>g.</b> Any words following the terms "including," "include," "in particular" or "for example" or any similar phrase shall not limit the generality of the related general words.
                </p>
                <p>
                    <b>h.</b> Any reference to the word "merchantability" shall also be construed as meaning "satisfactory quality.""
                </p>
                <p>
                    <b>d.</b> If You are a resident of or Your registered office is located in one of the Middle Eastern Countries, Bangladesh, Indonesia or Israel, Section 12.17 shall be appended to the General Terms and Conditions as follows:
                    "12.17 Third Party Rights. A person who is not a party to this Agreement shall not have any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this Agreement, but this does not affect any right or remedy of a third party which exists, or is available, apart from that Act."
                </p>
                <p>
                    <b>e.</b> If You are a resident of or Your registered office is located in one of the Middle Eastern Countries, Bangladesh, Indonesia or Israel, Section 12.18 shall be appended to the General Terms and Conditions as follows:
                    "12.18 Anti-Bribery and Anti-Corruption. You shall comply with all applicable laws, statutes, regulations, and codes relating to anti-bribery and anti-corruption including but not limited to the UK Bribery Act 2010 in connection
                    with this Agreement and promptly report to UPS any request or demand for any undue financial or other advantage of any kind received by such party in connection with the performance of this Agreement."
                </p>
                <p>
                    <b>f.</b> If You are a resident of or Your registered office is located in Indonesia, Section 12.13 of the General Terms and Conditions shall be deleted in its entirety and replaced with the following:
                    "12.13 Entire Agreement; Amendment. This Agreement constitutes the entire understanding and agreement between the parties with respect to the subject matter of this Agreement and supersedes any and all (1) prior or contemporaneous representations, understandings and agreements related thereto and (2) any prior versions of the UPS Technology Agreement between UPS and You, all of which are merged in this Agreement. Such merger will not be effective as to Software. The UPS Technology Agreement current at the time You receive a particular version of the Software will govern Your use of such Software version at all times. Any Corporate Technology Agreement between UPS and Customer, whether entered into before or after the date of this Agreement, shall supersede this Agreement. Any UPS Technology Agreement between UPS and You having a version more recent than Version UTA08072018 shall supersede this Agreement. The superseding of any prior agreement shall not abridge UPS's rights against You as a result of any violation or breach of such prior agreement before the date of this Agreement. A writing with electronic signatures shall not qualify to modify or amend the Agreement.
                </p>
                <b>Attachment 1 </b>
                <p>
                    Dispute Resolution in the U.S. and Puerto Rico Binding Arbitration of Disputes Except as to disputes that qualify for state courts of limited jurisdiction (such as small claims, justice of the peace, magistrate court, and similar courts with monetary limits on their jurisdictions over civil disputes), You and UPS agree that any controversy or claim, whether at law or equity, arising out of or related to this Agreement that arises in whole or in part in the United States or Puerto Rico, regardless of the date of accrual of such dispute, shall be resolved in its entirety by individual (not class-wide nor collective) binding arbitration.
                </p>
                <p>
                    Arbitration is the submission of a dispute to a neutral arbitrator, instead of a judge or jury, for a final and binding decision, known as an "award." Arbitration provides for more limited discovery than in court, and is subject to limited review by courts. Each party has an opportunity to present evidence to the arbitrator in writing or through witnesses. An arbitrator can only award the same damages and relief that a court can award under the law and must honor the terms and conditions in this Agreement. You and UPS agree that their sole relationship is a contractual one governed by this Agreement.
                </p>
                <p>
                    Institutional Arbitration The arbitration shall be conducted by the American Arbitration Association ("AAA") in accordance with its Commercial Arbitration Rules and the Supplementary Procedures for Consumer-Related Disputes (the "AAA Rules"),
                    and judgment on the award may be entered in any court of competent jurisdiction. The AAA Rules, including instructions for how to initiate arbitration, are available at https://www.adr.org. The arbitrator shall decide all issues of
                    the case on the basis of the applicable law, not equity. If You initiate arbitration, You must serve UPS's registered agent for service of process, Corporation Service Company, which has locations in every state. Information also
                    can be found on the website of your local Secretary of State.
                </p>
                <p>
                    Any arbitration under this Agreement will take place on an individual basis; class, mass, consolidated or combined actions or arbitrations or proceeding as a private attorney general are not permitted. You and UPS each waives
                    the right to trial by jury. You and UPS further waive the ability to participate in a class, mass, consolidated or combined action or arbitration.
                    Place of Arbitration/Number of Arbitrators/Costs of Arbitration Any arbitration will take place in the county where You reside and will be determined by a single arbitrator. Any filing fee or administrative fee required of You by the AAA Rules shall be paid by You to the extent such fee does
                    not exceed the amount of the fee required to commence a similar action in a court that otherwise would have jurisdiction. For all non frivolous complaints, UPS will pay the amount of such fee in excess of that amount. The
                    arbitrator will allocate the administrative costs and arbitral fees consistent with the applicable rules of the AAA. Reasonable attorney's fees and expenses will be allocated or awarded only to the extent such allocation or award is
                    available under applicable law.
                </p>
                <p>
                    All issues are for the arbitrator to decide, except that issues relating to the scope, application, and enforceability of the arbitration provision are for a court to decide. The Federal Arbitration Act governs the interpretation and
                    enforcement of this provision.
                </p>
                <p>
                    Severability
                    Notwithstanding anything to the contrary in the AAA Rules, if any part of this arbitration provision is deemed invalid or ineffective for any reason, this shall not affect the validity or enforceability of the remainder of this
                    arbitration provision, and the arbitrator shall have the authority to amend any provisions deemed invalid or ineffective to make the same valid and enforceable. Desk Arbitration
                    For all disputes concerning an amount less than fifteen thousand dollars ($15,000.00), the parties shall submit their arguments and evidence to the arbitrator in writing and the arbitrator shall make an award based only on the
                    documents; no hearing will be held unless the arbitrator in his or her discretion, and upon request of a party, decides it is a necessity to require an in-person hearing. For a dispute governed by the AAA Consumer-Related Disputes Supplementary Procedures, and concerning an award between fifteen thousand dollars ($15,000.00) and fifty thousand dollars ($50,000.00), inclusive, UPS shall pay Your filing fee under the AAA Rules, provided that You agree that each party shall submit their arguments and evidence to the arbitrator in writing and that the arbitrator shall make an award based only on the documents, without a hearing being held. Notwithstanding this
                    provision, the parties may agree to proceed with desk arbitration at any time. Access to Small Claims Courts All parties shall retain the right to seek adjudication in a state court of limited jurisdiction, such as small claims, justice of the peace, magistrate court, and similar courts with monetary limits on their jurisdiction over civil
                    disputes, for individual disputes within the scope of such court's jurisdiction.
                    Acknowledgements YOU AND UPS ACKNOWLEDGE AND AGREE THAT EACH PARTY WAIVES THE RIGHT TO:
                    (a) HAVE A TRIAL BY JURY TO RESOLVE ANY DISPUTE ALLEGED AGAINST YOU, UPS OR RELATED THIRD PARTIES;
                    (b) HAVE A COURT, OTHER THAN A STATE COURT OF LIMITED JURISDICTION AS DEFINED ABOVE, RESOLVE ANY DISPUTE ALLEGED AGAINST YOU, UPS OR RELATED THIRD PARTIES;
                    (c) HAVE A COURT REVIEW ANY DECISION OR AWARD OF AN ARBITRATOR, WHETHER INTERIM OR FINAL, EXCEPT FOR APPEALS BASED ON THOSE GROUNDS FOR VACATUR EXPRESSLY SET
                    FORTH IN SECTION 10 OF THE FEDERAL ARBITRATION ACT; AND
                    (d) SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, JOIN AS A CLASS MEMBER, AND/OR TO PARTICIPATE AS A MEMBER OF A
                    CLASS IN ANY CLASS, MASS, CONSOLIDATED OR COMBINED ACTION OR ARBITRATION FILED AGAINST YOU, UPS AND/ OR RELATED THIRD PARTIES.
                    Award The arbitrator may award money or equitable relief in favor of only the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claim. Similarly, an arbitration award
                    and any judgment confirming it apply only to that specific case; it cannot be used in any other case except to enforce the award itself. To reduce the time and expense of the arbitration, the arbitrator will not provide a statement of
                    reasons for his or her award unless a brief explanation of the reasons is requested by one of the parties. Unless both You and UPS agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not
                    otherwise preside over any form of a representative, private attorney general or class proceeding.
                    Confidentiality of Arbitration
                    Notwithstanding anything to the contrary in the AAA Rules, UPS and You agree that the filing of arbitration, the arbitration proceeding, any documents exchanged or produced during the arbitration proceeding, any briefs or
                    other documents prepared for the arbitration, and the arbitral award shall all be kept fully confidential and shall not be disclosed to any other party, except to the extent necessary to enforce this arbitration provision, arbitral award
                    or other rights of the parties, or as required by law or court order. This confidentiality provision does not foreclose the AAA from reporting certain consumer arbitration case information as required by state law.
                </p>
            </div>
        )
    }
}