import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { StringWms } from "../../assets/ts/StringWms";
import { ObjectWms } from "../../assets/ts/ObjectWms";

import WmsButton from "../react/components/WmsButton/WmsButton";
import WmsInput from "../react/components/WmsInput/WmsInput";
import WmsDropdown from "../react/components/WmsDropdown/WmsDropdown";
import WmsCollapse from "../react/components/WmsCollapse/WmsCollapse";
import WmsModel from "../react/components/WmsModel/WmsModel";
import WmsContainer from "../react/components/WmsContainer/WmsContainer";
import WmsConfirmModel from "../react/components/WmsConfirmModel/WmsConfirmModel";
import { default as WmsUpdateDropdownList } from "./../react/components/WmsUpdateDropdownList/WmsUpdateDropdownList";
import { default as WmsCustomDateTimePicker } from "../react/components/WmsCustomDateTimePicker/WmsCustomDateTimePicker.jsx";

import * as ManageAssemblyServices from "./manage-assembly.services";
import * as GridConfig from "./manage-assembly-grid-configuration";
import * as GlobalService from "../react/global_services";

import "./manageassembly.scss";

interface Props {
    wmsSelector: string;
    sharedSearchOn: boolean;
    sharedFilterObject: any;
    onClearSearch: () => void;
    onFilterDataSet: (objFilter: any) => void;
    windowId: any;
    onApplySavedFilter: (objFilter: any) => void;
}
const defaultFilterValue = {
    CustomerId: 0,
    FacilityId: 0,
    Skus: [],
    ReferenceNum: "",
    StartDueDate: null,
    EndDueDate: null,
    IndexOptionsStatus: 1,
};

declare var window: any;
const { $ } = window;

const ManageAssemblySearch: React.FC<Props> = (props: Props): any => {
    const ManageAssemblyGridId = "GridManageAssembly";
    const gridDomSelector = props.windowId
        ? "." + props.windowId + " #" + ManageAssemblyGridId
        : "#" + ManageAssemblyGridId;
    const gridHeaders = StringWms.convertSringToOption(window.translate("ManageAssembly_GrideHeaders"));
    const allColumnList = GridConfig.getGridAllColumnList(gridHeaders, props.wmsSelector);
    const defaultColumns = GridConfig.getGridDefaultColumns(gridHeaders, props.wmsSelector);
    const manageAssemblyFilterCollapse0 = "manage-assembly-filter_collapse0";
    const statusFilterOptions = StringWms.convertSringToOption(window.translate("Options_ManageAssembly_Status"));

    const CustomLabels = GlobalService.getCustomLabels();
    const CustomerOptions = GlobalService.getCustomerList(true);
    const [facilityOPtions, setFacilityOptions] = useState(GlobalService.getFacilityList(true));
    const [isOpenSaveFilterModel, setIsOpenSaveFilterModel] = useState(false);
    const [newFilterTitle, setNwFilterTitle] = useState("");
    const [filterNameExist, setFilterNameExist] = useState(false);
    const [savedFilterList, setSavedFilterList] = useState(
        ManageAssemblyServices.getSaveManageAssemblyFilterListMetaData()
    );
    const [isExpandedCollapse, setIsExpandedCollapse] = useState([]);
    const [savedFilterIndex, setSavedFilterIndex] = useState(null);
    const [filter, setFilter] = useState(props.sharedFilterObject);
    const [isOpenConfirmModel, setIsOpenConfirmModel] = useState(false);

    const initShortable = useCallback(() => {
        var sortable = $("#manage-assembly-filter-section-sortable");

        if (sortable.length && !sortable.hasClass("ui-sortable")) {
            var sortData = ManageAssemblyServices.getManageAssemblySortSectionMetaData();
            // set saved sort data if any in meta data
            if (sortData) {
                var pos = JSON.parse(sortData);
                if (pos) {
                    $.each(pos, function (i, position) {
                        if (position !== "") {
                            var $target = sortable.find("#" + position);
                            $target.appendTo(sortable); // or prependTo
                        }
                    });
                }
            }

            sortable.sortable({
                update: onSectionSort,
                cancel: "label.icon_label,.wms-collapsible-content",
                handle: ".wms-sort",
                distance: 5,
                start: function (e, ui) {
                    var height = ui.item.height();
                    if (height > 100) {
                        height = 100;
                    }
                    ui.placeholder.height(height);
                },
            });
        }
    }, []);

    const onSectionSort = () => {
        var sortArray = JSON.stringify($("#manage-assembly-filter-section-sortable").sortable("toArray"));
        ManageAssemblyServices.setManageAssemblySortSectionMetaData(sortArray);
    };

    const onOpenSaveFilterModel = () => {
        setIsOpenSaveFilterModel(true);
        setNwFilterTitle("");
        setFilterNameExist(false);
    };
    const exitSaveFilterModel = () => {
        setIsOpenSaveFilterModel(false);
    };
    const clearFilters = () => {
        // set default value of filter form
        let resetFilterObj = defaultFilterValue;
        setFilter(resetFilterObj);
        props.onClearSearch();
    };
    const setCollapseMetaData = (isExpanded, collapseId) => {
        ManageAssemblyServices.setManageAssemblyCollapseMetaData(collapseId, isExpanded);
    };

    const onClearSaveFilter = (index) => {
        setIsOpenConfirmModel(true);
        setSavedFilterIndex(index);
    };
    const onSaveFilter = (indexForUpdate = null) => {
        let filterObj = Object.assign({}, filter);
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var filterArray = grid.dataSource.filter() ? grid.dataSource.filter() : [];
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = GlobalService.prepareGridOptionsForStorage(gridColumn, sortArray, filterArray);

        if (indexForUpdate != null) {
            ManageAssemblyServices.updateManageAssemblyFilterMetaData(indexForUpdate, filterObj, finalSubmission);
            GlobalService.notification("success", window.translate("Order_Filter_Update_success"));
        } else {
            if (!filterNameExist) {
                ManageAssemblyServices.saveManageAssemblyFilterMetaData(newFilterTitle, filterObj, finalSubmission);
                exitSaveFilterModel();
            }
        }
    };
    const onUseSaveFilter = (index) => {
        var objFilter = Object.assign({}, savedFilterList[`${index}`]);
        var grid = $(gridDomSelector).data("kendoGrid");
        var gridConfig = JSON.parse(objFilter.columnData);
        var tempObject;

        if (gridConfig.columns) {
            var fieldArray = gridConfig.columns.map((el) => el.field);
            var updateColumn = [];
            allColumnList.filter((column) => {
                if (fieldArray.indexOf(column.field) !== -1) {
                    updateColumn.push(column);
                }
                return 1;
            });
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, updateColumn, "field");
        } else {
            tempObject = ObjectWms.alignIndexesByProperty(gridConfig.columns, defaultColumns, "field");
        }
        grid.setOptions({
            columns: tempObject,
        });
        grid.dataSource.sort(gridConfig.sorts);
        grid.dataSource.filter(gridConfig.filters);

        setFilter(objFilter.filterData);
        onFilterDataSet(objFilter.filterData);
    };

    const onFilterDataSet = (filterParam?: any) => {
        if (filterParam) {
            props.onApplySavedFilter(filterParam);
        } else {
            props.onApplySavedFilter(filter);
        }
    };

    const filterHandleChange = (event) => {
        let filterData = Object.assign({}, filter);
        let filterKey = event.target.name;
        let filterVal = event.target.value;

        if (filterVal) {
            filterData[`${filterKey}`] = filterVal;
        } else {
            delete filterData[`${filterKey}`];
        }
        if (filterKey === "CustomerId") {
            filterData["FacilityId"] = 0;
            setFacilityOnCustomer(filterVal);
        } else if (filterKey === "Skus") {
            if (filterVal === "") {
                filterData["Skus"] = [];
            } else {
                filterData["Skus"] = [filterVal];
                filterData["ShowSku"] = true;
            }
        }
        setFilter(filterData);
        finalCallFilterChange2(filterData);
    };
    let finalCallFilterChange2 = GlobalService.useFCDebounce(onFilterDataSet, 1000);

    const setFacilityOnCustomer = (customerId) => {
        let customerIndex = ObjectWms.FindIndexByProperty(CustomerOptions, "id", parseInt(customerId));
        let custFacility = CustomerOptions[`${customerIndex}`].facilities;
        let allFacilities = GlobalService.getFacilityList(false);
        let selFacilityOption = [];
        for (let i = 0; i < custFacility.length; i++) {
            let facilityIndex = ObjectWms.FindIndexByProperty(allFacilities, "id", parseInt(custFacility[`${i}`]));
            if (allFacilities[`${facilityIndex}`]) {
                selFacilityOption.push(allFacilities[`${facilityIndex}`]);
            }
        }
        selFacilityOption.unshift({
            "id": 0,
            "name": "Unspecified",
            "uri": "",
            "FacilitySimpleUri": "",
            "UpsAccountNumber": "",
            "FedExAccountNumber": "",
        });
        setFacilityOptions(selFacilityOption);
    };

    const handleFilterTitleChange = (e) => {
        let value = e.target.value;
        if (value.Description === "") {
            value = "";
        }

        var nameExists = false;
        // check dublicate title for case sensitive
        var exists = savedFilterList.findIndex(function (item) {
            return value.toLowerCase() === item.title.toLowerCase();
        });
        if (exists !== -1) {
            nameExists = true;
        }
        setNwFilterTitle(value);
        setFilterNameExist(nameExists);
    };
    const onYesRemoveSavedFilter = () => {
        ManageAssemblyServices.clearSaveManageAssemblyFilterListMetaData(savedFilterIndex);
        setIsOpenConfirmModel(false);
        var savedFilterList = ManageAssemblyServices.getSaveManageAssemblyFilterListMetaData();
        setSavedFilterList(savedFilterList);
    };
    const onClickExitConfirmModel = () => {
        setIsOpenConfirmModel(false);
    };
    const multiDropdownChangeEvent = (param_value) => {
        let filterData = Object.assign({}, filter);
        if (param_value == null) {
            param_value = [];
        } else {
            filterData.ShowSku = true;
        }

        filterData.Skus = param_value;
        setFilter(filterData);
        finalCallFilterChange2(filterData);
    };

    useEffect(() => {
        ManageAssemblyServices.initialFilterData()
            .then((response) => {
                let sortedFacilityList = response.getCustomerFacilityList.sort(function (first, second) {
                    var columnTitle1 = first.name.trim().toUpperCase(); // ignore upper and lowercase
                    var columnTitle2 = second.name.trim().toUpperCase(); // ignore upper and lowercase

                    if (columnTitle1 < columnTitle2) {
                        return -1;
                    }

                    if (columnTitle1 > columnTitle2) {
                        return 1;
                    }
                    // names must be equal
                    return 0;
                });
                sortedFacilityList.unshift({
                    "id": 0,
                    "name": "Unspecified",
                    "uri": "",
                    "FacilitySimpleUri": "",
                    "UpsAccountNumber": "",
                    "FedExAccountNumber": "",
                });
                setFacilityOptions(sortedFacilityList);
            })
            .finally(() => {
                kendo.ui.progress($("#" + props.windowId), false);
            });
        window.setLoaderTimer("Manage Assembly");
        // var savedFilterList = ManageAssemblyServices.getSaveManageAssemblyFilterListMetaData();
        // setSavedFilterList(savedFilterList);
        var isExpandedCollapse = !$.isEmptyObject(ManageAssemblyServices.getManageAssemblyCollapseMetaData())
            ? ManageAssemblyServices.getManageAssemblyCollapseMetaData()
            : [];
        setIsExpandedCollapse(isExpandedCollapse);
        initShortable();
    }, [initShortable, props.windowId]);

    return (
        <div className={`manage-assembly-search-holder`}>
            <div className="manage-assembly-filter">
                <div className="filter-wrapper">
                    <div className="btn-section">
                        <WmsButton
                            label={window.translate("Label_Save_View")}
                            wmsSelector={props.wmsSelector + "SaveFilterButton"}
                            onClickFunc={onOpenSaveFilterModel}
                            icon="fa-floppy-o"
                        />
                        <WmsButton
                            label={window.translate("RoleManager_Label_Reset")}
                            buttonType="orange"
                            wmsSelector={props.wmsSelector + "ClearSearchButton"}
                            onClickFunc={clearFilters}
                            icon="fa-repeat"
                        />
                    </div>
                    {props.sharedSearchOn && (
                        <div className="title">
                            <b>{window.translate("Label_NOTE")}: </b>
                            <label>{window.translate("Label_Search_Applied_Filter")}</label>
                        </div>
                    )}

                    <div
                        className={
                            props.sharedSearchOn === true ? "warehouse-form-boxes width-title " : "warehouse-form-boxes"
                        }
                    >
                        <div className="warehouse-are-content" id="manage-assembly-filter-section-sortable">
                            {savedFilterList.length !== 0 && (
                                <WmsCollapse
                                    id={manageAssemblyFilterCollapse0}
                                    isExpanded={
                                        isExpandedCollapse.hasOwnProperty(manageAssemblyFilterCollapse0)
                                            ? !isExpandedCollapse["manage-assembly-filter_collapse0"]
                                            : true
                                    }
                                    showDragIcon={true}
                                    headerLabel={window.translate("Label_Saved_Filter_List")}
                                    showCollapseText={false}
                                    setCollapseMetaData={(e) => setCollapseMetaData(e, manageAssemblyFilterCollapse0)}
                                >
                                    <ul className="filter-list-block">
                                        {savedFilterList.map((data, index) => (
                                            <li className="filter-list" key={index}>
                                                <span className="title-text" title={data.title}>
                                                    {data.title}
                                                </span>
                                                <span
                                                    className="clear-save-filter"
                                                    onClick={() => onClearSaveFilter(index)}
                                                >
                                                    <i className="far fa-times-circle" aria-hidden="true"></i>
                                                </span>
                                                <div className="wms-apply-btn">
                                                    <WmsButton
                                                        label={window.translate("RoleManager_Label_Update")}
                                                        buttonType="green"
                                                        wmsSelector={props.wmsSelector + "UpdateFilterButton"}
                                                        onClickFunc={(e) => onSaveFilter(index)}
                                                        icon=""
                                                    />
                                                    <WmsButton
                                                        label={window.translate("Label_Apply")}
                                                        buttonType="green"
                                                        wmsSelector={props.wmsSelector + "ApplyFilterButton"}
                                                        onClickFunc={(e) => onUseSaveFilter(index)}
                                                        icon=""
                                                    />
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </WmsCollapse>
                            )}
                            <WmsCollapse
                                id={"manage_assembly_search_collapse1"}
                                isExpanded={
                                    isExpandedCollapse.hasOwnProperty("manage_assembly_search_collapse1")
                                        ? !isExpandedCollapse["manage_assembly_search_collapse1"]
                                        : true
                                }
                                showDragIcon={true}
                                headerLabel={window.translate("Label_Filter_Search_By_General")}
                                showCollapseText={false}
                                setCollapseMetaData={(e) => setCollapseMetaData(e, "manage_assembly_search_collapse1")}
                            >
                                <WmsDropdown
                                    id="manage_assembly_search_CustomerId"
                                    name="CustomerId"
                                    windowId={props.windowId}
                                    label={CustomLabels.Label_Customer}
                                    wmsSelector={props.wmsSelector + "SelectCustomer"}
                                    value={filter.CustomerId}
                                    onChangeFunc={filterHandleChange}
                                    options={CustomerOptions}
                                    valueField={"id"}
                                    textField={"name"}
                                />
                                <WmsDropdown
                                    id="manage_assembly_search_warehouse"
                                    name="FacilityId"
                                    windowId={props.windowId}
                                    label={CustomLabels.Label_Warehouse}
                                    wmsSelector={props.wmsSelector + "SelectWarehouse"}
                                    value={filter.FacilityId}
                                    onChangeFunc={filterHandleChange}
                                    options={facilityOPtions}
                                    valueField="id"
                                    textField="name"
                                    parentClassName="form_group"
                                />
                            </WmsCollapse>
                            <WmsCollapse
                                id={"manage_assembly_search_collapse2"}
                                isExpanded={
                                    isExpandedCollapse.hasOwnProperty("manage_assembly_search_collapse2")
                                        ? !isExpandedCollapse["manage_assembly_search_collapse2"]
                                        : true
                                }
                                showDragIcon={true}
                                headerLabel={window.translate("Label_Filter_Search_By_Status")}
                                showCollapseText={false}
                                setCollapseMetaData={(e) => setCollapseMetaData(e, "manage_assembly_search_collapse2")}
                            >
                                <WmsDropdown
                                    id="IndexOptionsStatus"
                                    name="IndexOptionsStatus"
                                    label={window.translate("Label_Filter_Status")}
                                    wmsSelector={props.wmsSelector + "IndexOptionsStatus"}
                                    value={filter.IndexOptionsStatus}
                                    onChangeFunc={filterHandleChange}
                                    options={statusFilterOptions}
                                    valueField="value"
                                    textField="description"
                                />
                            </WmsCollapse>

                            <WmsCollapse
                                id={"manage_assembly_search_collapse3"}
                                isExpanded={
                                    isExpandedCollapse.hasOwnProperty("manage_assembly_search_collapse3")
                                        ? !isExpandedCollapse["manage_assembly_search_collapse3"]
                                        : true
                                }
                                showDragIcon={true}
                                headerLabel={window.translate("Label_Assembly_Details")}
                                showCollapseText={false}
                                setCollapseMetaData={(e) => setCollapseMetaData(e, "manage_assembly_search_collapse3")}
                            >
                                {filter.CustomerId === null || filter.CustomerId === 0 ? (
                                    <WmsInput
                                        inputName="Skus"
                                        name="Skus"
                                        wmsSelector={props.wmsSelector + "FilterItemSKU"}
                                        value={
                                            filter.Skus && typeof filter.Skus == "object" ? filter.Skus[0] : filter.Skus
                                        }
                                        onChangeFunc={filterHandleChange}
                                        label={CustomLabels.Label_Sku}
                                        extraWrapperClass="full-width-input"
                                    />
                                ) : (
                                    <div className="wms-multiselect-input">
                                        <WmsUpdateDropdownList
                                            id="MultiSelectCustomerSkus"
                                            name="MultiSelectCustomerSkus"
                                            label={CustomLabels.Label_Sku}
                                            optionsLabel={window.translate("Label_Filter_Sku")}
                                            wmsSelector={props.wmsSelector + "FilterItemSKU"}
                                            value={filter.Skus}
                                            onChangeFunc={multiDropdownChangeEvent}
                                            textField="Sku"
                                            valueField="Sku"
                                            virtualObj={{
                                                itemHeight: 26,
                                                valueMapper: (options) => {
                                                    var valueMapperOptions = options;
                                                    console.log("valueMapperOptions", valueMapperOptions);
                                                    $.ajax({
                                                        url: "/WebUI/items/items/SkuDropdownIndexRead",
                                                        type: "POST",
                                                        dataType: "json",
                                                        data: {
                                                            itemId: valueMapperOptions.value || 0,
                                                            customerId: filter.CustomerId || 0,
                                                        },
                                                        success: function (data) {
                                                            options.success(data);
                                                        },
                                                    });
                                                },
                                            }}
                                            isValuePrimitive={true}
                                            dataSourceObj={{
                                                pageSize: 40,
                                                serverPaging: true,
                                                serverFiltering: true,
                                                type: "aspnetmvc-ajax",
                                                transport: {
                                                    read: {
                                                        url: "/WebUI/Shipping/FindOrders/SkuDropDownReadLite",
                                                        type: "POST",
                                                        data: {
                                                            customerId: filter.CustomerId,
                                                        },
                                                    },
                                                },
                                                schema: {
                                                    data: "Data",
                                                    total: "Total",
                                                },
                                            }}
                                        />
                                    </div>
                                )}
                                <WmsInput
                                    inputName="ReferenceNum"
                                    id="ReferenceNum"
                                    wmsSelector={props.wmsSelector + "ReferenceNum"}
                                    value={filter.ReferenceNum}
                                    onChangeFunc={filterHandleChange}
                                    extraWrapperClass="full-width-input"
                                    label={window.translate("Orders_Lbl_Reference_Num")}
                                />
                            </WmsCollapse>

                            <WmsCollapse
                                id={"manage_assembly_search_collapse4"}
                                isExpanded={
                                    isExpandedCollapse.hasOwnProperty("manage_assembly_search_collapse4")
                                        ? !isExpandedCollapse["manage_assembly_search_collapse4"]
                                        : true
                                }
                                showDragIcon={true}
                                headerLabel={window.translate("Label_Inventory_Search_By_Due_Data")}
                                showCollapseText={false}
                                setCollapseMetaData={(e) => setCollapseMetaData(e, "manage_assembly_search_collapse4")}
                            >
                                <WmsCustomDateTimePicker
                                    id={"StartDueDate"}
                                    name="StartDueDate"
                                    wmsSelector={props.wmsSelector + "DatePickerStartDueDate"}
                                    label={window.translate("Label_Begin")}
                                    onChangeFunc={filterHandleChange}
                                    value={filter.StartDueDate}
                                    windowId={props.windowId}
                                    isDateParseInCustomFormat={true}
                                    onlyDate={true}
                                />
                                <WmsCustomDateTimePicker
                                    id={"EndDueDate"}
                                    name="EndDueDate"
                                    wmsSelector={props.wmsSelector + "DatePickerEndDueDate"}
                                    label={window.translate("Label_End")}
                                    onChangeFunc={filterHandleChange}
                                    value={filter.EndDueDate}
                                    windowId={props.windowId}
                                    isDateParseInCustomFormat={true}
                                    onlyDate={true}
                                />
                            </WmsCollapse>
                        </div>
                    </div>
                    <div>
                        {isOpenSaveFilterModel && (
                            <WmsModel
                                id="saveCurrentAttachFileToAssemblyFilter"
                                title={window.translate("Label_Save_Filter")}
                                width={450}
                                height={240}
                                onCloseFunc={exitSaveFilterModel}
                                isOpen={isOpenSaveFilterModel}
                                customClass="wms-model-new-ui-wrapper"
                            >
                                <div className="model-content-wrapper">
                                    <WmsContainer>
                                        <WmsInput
                                            inputName="newFilterTitle"
                                            name="newFilterTitle"
                                            wmsSelector={props.wmsSelector + "FilterTitle"}
                                            value={newFilterTitle}
                                            onChangeFunc={handleFilterTitleChange}
                                            extraWrapperClass="full-width-input"
                                            label={window.translate("Label_Filter_Title")}
                                        />
                                    </WmsContainer>
                                </div>
                                <div className="save-filter-button-wrapper footer-btn-holder">
                                    <WmsButton
                                        label={window.translate("Label_Cancel")}
                                        wmsSelector={props.wmsSelector + "SaveNewFilterExit"}
                                        onClickFunc={exitSaveFilterModel}
                                        buttonType="orange"
                                        icon="fa-ban"
                                    />
                                    <WmsButton
                                        label={window.translate("Label_Save")}
                                        wmsSelector={props.wmsSelector + "SaveNewFilter"}
                                        onClickFunc={(e) => onSaveFilter()}
                                        disabled={newFilterTitle === "" || filterNameExist}
                                        icon="fa-floppy-o"
                                        showToolTip={filterNameExist}
                                        tooltipText={window.translate("Label_Filter_Name_Exist_Message")}
                                    />
                                </div>
                            </WmsModel>
                        )}
                    </div>
                    <WmsConfirmModel
                        id={"ConfirmModel" + props.windowId}
                        isOpen={isOpenConfirmModel}
                        type={window.translate("Label_Confirmation")}
                        width={500}
                        height={175}
                        firstBtnLabel={window.translate("Label_Yes")}
                        firstBtnIcon={"fa-check-square"}
                        firstBtnOnClickFunc={onYesRemoveSavedFilter}
                        sawCheckBox={false}
                        conFormModelText={window.translate("Label_are_you_sure_remove_filter")}
                        onCloseFunc={onClickExitConfirmModel}
                        thirdBtnLabel={window.translate("Label_No")}
                        thirdBtnIcon={"fa-times-circle"}
                    />
                </div>
            </div>
        </div>
    );
};

ManageAssemblySearch.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    sharedSearchOn: PropTypes.bool.isRequired,
    sharedFilterObject: PropTypes.any.isRequired,
    windowId: PropTypes.any.isRequired,
    onClearSearch: PropTypes.func.isRequired,
    onFilterDataSet: PropTypes.func.isRequired,
    onApplySavedFilter: PropTypes.func.isRequired,
};

ManageAssemblySearch.defaultProps = {
    wmsSelector: "",
    sharedSearchOn: false,
    sharedFilterObject: {},
    windowId: "",
    onClearSearch: (): void => {},
    onFilterDataSet: (filterObject: any): void => {},
    onApplySavedFilter: (filterObject: any): void => {},
};

export default React.memo(ManageAssemblySearch);
