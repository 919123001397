import { BaseRequirements } from './BaseRequirements';

declare var moment: any;
// import Base = moment.unitOfTime.Base
export class StringWms implements BaseRequirements {
	/**
	* Simple contains method for Dictionary
	* @params key:string
	* @return void
	*/
    public test(message: string): void {
        console.log("Class reached with message of : " + message);
    }
    public static test(message: string) {
        return message;
    }
    /**
     * Simple method to see if a str is empty
     * @params str:any
     * @return boolean
     * It should accept only 1 parameter
     * It should return a boolean
     */
    public static isEmpty(str: any): boolean {
        if (str == undefined || str == null || str == "" || str == " ") {
            return true;
        } else {
            return false;
        }
    }

    public static exists(str) {
        if (str == undefined || str == null || str == "" || str == " " || str == "undefined") {
            return false;
        } else {
            return true;
        }
    };

    /**
     * Simple method to see if it is a valid string or not
     * @params str:any
     * @return boolean
     * It should accept only 1 parameter
     * It should return a boolean
     */
    public static isString(str: any): boolean {
        if (typeof str === 'string') {
            return true;
        } else {
            return false;
        }
    }


    /**
     * Simple method to initial cap every word in string
     * @params str:string
     * @return string
     * It should accept only 1 parameter
     * It should return a string
     * It should return a string where every initial letter after a space is a capital
     * It should change other capital letters to lowercase
     */
    public static initialCaps(str: any): string {
        var final = "";
        str.split(" ").forEach((s, index) => { index != 0 ? final += " " : null; s.split("").forEach((x, index) => { index == 0 ? final += x.toUpperCase() : final += x.toLowerCase() }) });
        return final;
    }

    /**
     * Method to return a valid location name
     * @params str:any
     * @return string
     * It should accept only 1 parameter
     * It should return a string
     */
    public static formValidLocationName(str: any): string {
        var locationName = str.Field1;
        if (str.Field2) {
            locationName = locationName + '-' + str.Field2;
        }
        if (str.Field3) {
            locationName = locationName + '-' + str.Field3;
        }
        if (str.Field4) {
            locationName = locationName + '-' + str.Field4;
        }
        return locationName;
    }

    /**
     * Method to return a valid location name
     * @params str:any
     * @return string
     * It should accept only 1 parameter
     * It should return a string
     */
    public static convertSringToOption(str: string) {
        var array = str.split(',');
        var returnObject = [];
        array.forEach((val, key) => {
            returnObject.push({
                "value": key,
                "description": val
            });
        });
        return returnObject;
    }

    /**
    * Method to return a comma separate string from gridDatadource
    * @params data:array
    * @return string comma separate
    * It should accept only 1 parameter
    * It should return a string
    */
    public static convertOptionToString(data: string) {
        var returnObject = [];
        data['_data'].forEach(function (val, key) {
            returnObject.push(val.Name);
        });
        return returnObject.join(",");
    }

    /**
    * Method to return a comma separate string from array
    * @params data:array
    * @return string comma separate
    * It should accept only 1 parameter
    * It should return a string
    */
    public static convertArrayOptionToString(data) {
        if(data.length == 0 ){
            return null;
        }
        var returnObject = [];
        data.forEach(function (val, key) {
            returnObject.push(val.Name);
        });
        return returnObject.join(",");
    }

    public static getCommonOptionsForDateTimePicker = function () {
        let extraOptions =[
            {title:'Today', value: 'today', number: 0, name:'days' },
            {title:'Yesterday', value: 'yesterday', number: 1, name:'days' },
            {title:'Last 7 Days', value: '1weekago', number: 7, name:'days' },
            {title:'Last 30 Days', value: '1monthago', number: 30, name:'days' },
        ];

        let defaultDateOption = {
            day: '',
            month: '',
            year: '',
            time: ''
        }

        return {
            extraOptions: extraOptions,
            defaultDateOption: defaultDateOption,
            timeFormat: 'h:mma'
        }
    }

    public static convertFilterStringToISODate(filterString:string, timeFormat, onlyDate = false,isCustomFormatParsing) {
        let commonOptions = StringWms.getCommonOptionsForDateTimePicker();

        let fromExtraOptions = false;
        let extraOptions = commonOptions.extraOptions;
        let dateOptions = commonOptions.defaultDateOption;
        if (!timeFormat) {
            timeFormat = commonOptions.timeFormat;
        }

        let isoStringUTC = '';

        if (filterString) {
            if (filterString.indexOf("~") < 0) {
                let momentDate = moment(filterString);
                dateOptions = {
                    day: momentDate.get('date'),
                    month: momentDate.format("M"),
                    year: momentDate.get('year'),
                    time: (onlyDate) ? '' : momentDate.format(timeFormat),
                }

                if(isCustomFormatParsing == true){
                    isoStringUTC = momentDate.format('YYYY-MM-DDTHH:mm:ss');
                }else{
                    isoStringUTC = momentDate.toISOString();
                }
            } else {
                fromExtraOptions = true;
                let filterData = filterString.split('~');
                let dateTimeLocal = null;
                if(isCustomFormatParsing == true){
                    dateTimeLocal = moment(filterData[1], "HH:mm:ss").local();
                }else{
                    dateTimeLocal = moment.utc(filterData[1], "HH:mm:ss").local();
                }



                dateOptions = {
                    day: '',
                    month: filterData[0],
                    year: '',
                    time: (onlyDate) ? '' : dateTimeLocal.format(timeFormat)
                }

                var option = extraOptions.find(obj => {
                    return obj.value == filterData[0];
                });

                let actualLocalDate = moment().subtract(option.number, option.name);

                if (dateTimeLocal.isValid()) {
                    actualLocalDate.set({
                        hour: (onlyDate) ? 0 : dateTimeLocal.get('hours'),
                        minute: (onlyDate) ? 0 : dateTimeLocal.get('minute'),
                        second: 0,
                        millisecond:0
                    });
                }

                if(isCustomFormatParsing == true){
                    isoStringUTC = actualLocalDate.format('YYYY-MM-DDTHH:mm:ss');
                }else{
                    isoStringUTC = actualLocalDate.utc().toISOString();
                }

            }
        }

        return {
            dateOptions: dateOptions,
            filterString: filterString,
            isFromExtraOptions: fromExtraOptions,
            isoStringUTC: isoStringUTC
        }

    }
}
