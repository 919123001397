

export const getRateShoppingGridColumns = () => {

    var translate = window.translate;
    return ({
        columns: [
            {
                field: "Mapping",
                title: translate("Label_Ship_Service_Keyword"),
            },
            {
                field: "Logic",
                title: translate("Label_Rate_Type")
            }, {
                field: 'cmdRemove',
                menu: false,
                command: [{
                    name: "destroy",
                    text: " ",
                    className: "rate-shop-delete",
                    iconClass: "fa fa-trash"
                }],
                title: "&nbsp;",
                width: 32,
            }
        ]
    });
};

export const getRateShoppingGridSchema = () => {
    return ({
        schema: {
            model: {
                fields: {
                    Mapping: {
                        type: "string",
                        editable: false
                    },
                    Logic: {
                        type: "string",
                        editable: false
                    }
                }
            }
        }
    });
};

