import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';

//component
import WmsRadio from './../components/WmsRadio/WmsRadio.tsx';
import WmsCheckBox from './../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';
import WmsComboboxWithTag from './../components/WmsComboboxWithTag/WmsComboboxWithTag.jsx';
import * as GlobalService from "./../global_services";
import CustomersPackingSlipsGrid from './customers-packing-slips-grid';

export class CustomersFormShippingOption extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            Selector: 'Customer',
            rights: GlobalService.GetRights(),
        };
    }

    handleChange = (param_parent_property, event) => {

        const name = event.target.name;
        let parentObj = this.props.sharedCustomer.Options[param_parent_property];

        if (event.target.type == 'checkbox') {
            parentObj[name] = !this.props.sharedCustomer.Options[param_parent_property][name];
        } else {
            parentObj[name] = event.target.value;
        }

        if (param_parent_property == 'MobileProcessingBehaviors' && name == 'AutoAdvanceLineItem' && parentObj[name] == false) {
            parentObj.AutoFillSameSkuAndTrackby = false;
        }

        if (param_parent_property == 'MobileProcessingBehaviors' && name == 'AutoAdvanceLineItem' && parentObj[name] == true) {
            parentObj.AutoFillSameSkuAndTrackby = true;
        }
        if (param_parent_property == 'MobileProcessingBehaviors' && name == 'AllowMultiplePickers' && parentObj[name] == true) {
            parentObj.AutoAdvanceLineItem = false;
            parentObj.AutoFillSameSkuAndTrackby = false;
        }

        let customeObj = Object.assign(
            {},
            this.props.sharedCustomer,
            {
                Options: Object.assign(
                    {},
                    this.props.sharedCustomer.Options,
                    {
                        [param_parent_property]: parentObj
                    }
                )
            }
        );
        this.props.onCustomerDataset(customeObj);
    }

    handleAsnPreCheckOptionsChange = (event) => {

        const name = event.target.name;
        let asnOptionsFlag = _.cloneDeep(this.props.sharedAsnPrecheckedOptionsFlag);
        asnOptionsFlag[name] = !this.props.sharedAsnPrecheckedOptionsFlag[name];
        this.props.onAsnPrecheckOptionsSet(asnOptionsFlag);

    }

    handleAsnPreCheckChange = (event) => {

        const name = event.target.name;
        let customeObj = _.cloneDeep(this.props.sharedCustomer);
        customeObj.Options.Shipping.AsnPreCheck[name] = !this.props.sharedCustomer.Options.Shipping.AsnPreCheck[name];
        this.props.onCustomerDataset(customeObj);
    }

    handleASNFlexFields = (property_name, val_arr) => {
        if (!val_arr) {
            val_arr = [];
        }
        let customeObj = _.cloneDeep(this.props.sharedCustomer);
        if (!customeObj.Options.Shipping.AsnPreCheck) {
            customeObj.Options.Shipping.AsnPreCheck = {};
        }
        customeObj.Options.Shipping.AsnPreCheck[property_name] = val_arr;
        this.props.onCustomerDataset(customeObj);
    }
    render() {
        const renderCustomerOptions = this.props.sharedCustomer.Options;
        return (
            <div className="collapseCustomerTabContent_wrapper shipping-tab-wrapper" id={'collapseShippingOption'} >
                <WmsCollapse
                    id={'collapseShippingOptionContent'}
                    headerLabel={this.translate('Orders_Page_Title')}
                    showCollapseText={false}
                    showCollapseIcon={true}  >

                    <WmsContainer title={this.translate('Label_Customer_Proto_General_Settings')}>

                        <WmsCheckBox
                            id={'AutoConfirmOrderOnTrackingUpdate'}
                            name={'AutoConfirmOrderOnTrackingUpdate'}
                            wmsSelector={this.state.Selector + 'AutomaticConfirmOrders'}
                            onChangeFunc={(e) => this.handleChange('Shipping', e)}
                            label={this.translate('Label_Costomer_chk_Automatically_confirm_orders')}
                            checked={renderCustomerOptions.Shipping.AutoConfirmOrderOnTrackingUpdate == true}
                            value={renderCustomerOptions.Shipping.AutoConfirmOrderOnTrackingUpdate} />

                        <WmsCheckBox
                            id={'OrderQueue'}
                            name={'OrderQueue'}
                            wmsSelector={this.state.Selector + 'PreventOrdersFromAutoAllocating'}
                            onChangeFunc={(e) => this.handleChange('Shipping', e)}
                            label={this.translate('Label_Costomer_chk_Prevent_orders_from_auto_allocating')}
                            checked={renderCustomerOptions.Shipping.OrderQueue == true}
                            value={renderCustomerOptions.Shipping.OrderQueue}
                            parentClassName={this.state.rights.hasOwnProperty('Shipping.AllowOrderQueue') ? '' : 'disabled-chk'}
                            disabled={!this.state.rights.hasOwnProperty('Shipping.AllowOrderQueue')}
                            showToolTip={!this.state.rights.hasOwnProperty('Shipping.AllowOrderQueue')}
                            tooltipText={this.translate('Label_Setting_Disable_Tooltip')}
                            tooltipShowAfter={200} />

                        <WmsCheckBox
                            id={'AutoPrintCartonLabelDefault'}
                            name={'AutoPrintCartonLabelDefault'}
                            wmsSelector={this.state.Selector + 'AutoPrintCartonLabelDefault'}
                            onChangeFunc={(e) => this.handleChange('Shipping', e)}
                            label={this.translate('Label_Costomer_chk_SmartPack_Auto_Print_Labels')}
                            checked={renderCustomerOptions.Shipping.AutoPrintCartonLabelDefault == true}
                            value={renderCustomerOptions.Shipping.AutoPrintCartonLabelDefault}
                            parentClassName={this.state.rights.hasOwnProperty('Shipping.WarehousePackEnabled') ? '' : 'disabled-chk'}
                            disabled={!this.state.rights.hasOwnProperty('Shipping.WarehousePackEnabled')}
                            showToolTip={true}
                            tooltipText={this.state.rights.hasOwnProperty('Shipping.WarehousePackEnabled') ? this.translate('Label_Costomer_chk_SmartPack_Auto_Print_Tooltip') : this.translate('Label_Setting_Disable_Tooltip')}
                            tooltipShowAfter={200} />

                        <WmsCheckBox
                            id={'SmartPackScaleEnabled'}
                            name={'SmartPackScaleEnabled'}
                            wmsSelector={this.state.Selector + 'SmartPackScaleEnabled'}
                            onChangeFunc={!this.state.rights.hasOwnProperty('Shipping.SmartPackScaleEnabled') ? () => { } : (e) => this.handleChange('Shipping', e)}
                            label={this.translate('Label_Costomer_chk_SmartPack_Enable_Scale_Labels')}
                            checked={renderCustomerOptions.Shipping.SmartPackScaleEnabled == true}
                            value={renderCustomerOptions.Shipping.SmartPackScaleEnabled}
                            parentClassName={!this.state.rights.hasOwnProperty('Shipping.SmartPackScaleEnabled') ? 'disabled-chk' : ''}
                            showToolTip={!this.state.rights.hasOwnProperty('Shipping.SmartPackScaleEnabled')}
                            tooltipText={!this.state.rights.hasOwnProperty('Shipping.SmartPackScaleEnabled') ? this.translate('Label_Setting_Disable_Tooltip') : ''}
                            tooltipShowAfter={200} />

                        <WmsCheckBox
                            id={'AutoFillTrackBys'}
                            name={'AutoFillTrackBys'}
                            wmsSelector={this.state.Selector + 'AutomaticPopulateTrackByInfo'}
                            onChangeFunc={(e) => this.handleChange('Receiving', e)}
                            label={this.translate('Label_Costomer_chk_Automatically_popilate_track_by_info')}
                            checked={renderCustomerOptions.Receiving.AutoFillTrackBys == true}
                            value={renderCustomerOptions.Receiving.AutoFillTrackBys}
                            parentClassName={this.state.rights.hasOwnProperty('Shipping.WarehousePackEnabled') ? '' : 'disabled-chk'}
                            disabled={!this.state.rights.hasOwnProperty('Shipping.WarehousePackEnabled')}
                            showToolTip={!this.state.rights.hasOwnProperty('Shipping.WarehousePackEnabled')}
                            tooltipText={this.translate('Label_Setting_Disable_Tooltip')}
                            tooltipShowAfter={200} />

                        <WmsCheckBox
                            id={'SpsCloseOnUpdate'}
                            name={'SpsCloseOnUpdate'}
                            wmsSelector={this.state.Selector + 'AutomaticCloseOrders'}
                            onChangeFunc={(e) => this.handleChange('Packaging', e)}
                            label={this.translate('Label_Costomer_Proto_chk_Automatically_close_orders_when_a')}
                            checked={renderCustomerOptions.Packaging.SpsCloseOnUpdate == true}
                            value={renderCustomerOptions.Packaging.SpsCloseOnUpdate}
                            disabled={!this.state.rights.hasOwnProperty('Receiving.SpsCloseOnUpdate')}
                            parentClassName={this.state.rights.hasOwnProperty('Receiving.SpsCloseOnUpdate') ? '' : 'disabled-option'}
                            showToolTip={!this.state.rights.hasOwnProperty('Receiving.SpsCloseOnUpdate')}
                            tooltipText={this.translate('Label_Setting_Disable_Tooltip')}
                            tooltipShowAfter={200} />

                        <WmsCheckBox
                            id={'SpsDisableAutoClearBinId'}
                            name={'SpsDisableAutoClearBinId'}
                            wmsSelector={this.state.Selector + 'SpsDisableAutoClearBinId'}
                            onChangeFunc={(e) => this.handleChange('Packaging', e)}
                            label={this.translate('Label_Costomer_Proto_chk_SpsDisableAutoClearBinId')}
                            checked={renderCustomerOptions.Packaging.SpsDisableAutoClearBinId == true}
                            value={renderCustomerOptions.Packaging.SpsDisableAutoClearBinId} />

                        <WmsCheckBox
                            id={'SmallParcelDisableClickToPack'}
                            name={'SmallParcelDisableClickToPack'}
                            wmsSelector={this.state.Selector + 'SmallParcelDisableClickToPack'}
                            onChangeFunc={(e) => this.handleChange('Packaging', e)}
                            label={this.translate('Label_Costomer_Proto_chk_Small_Parcel_Disable_Click_To_Pack')}
                            checked={renderCustomerOptions.Packaging.SmallParcelDisableClickToPack == true}
                            value={renderCustomerOptions.Packaging.SmallParcelDisableClickToPack} />


                        <WmsCheckBox
                            id={'RequireTrackingNumber'}
                            name={'RequireTrackingNumber'}
                            wmsSelector={this.state.Selector + 'PreventOrderConfirmationIfOrder'}
                            onChangeFunc={(e) => this.handleChange('Shipping', e)}
                            label={this.translate('Label_Costomer_chk_Prevent_order_confirmation_if_an_order')}
                            checked={renderCustomerOptions.Shipping.RequireTrackingNumber == true}
                            value={renderCustomerOptions.Shipping.RequireTrackingNumber} />


                        <WmsCheckBox
                            id={'RounduptoFullMU'}
                            name={'RounduptoFullMU'}
                            wmsSelector={this.state.Selector + 'RounduptoFullMU'}
                            onChangeFunc={(e) => this.handleChange('Shipping', e)}
                            label={this.translate('Label_Costomer_chk_Roundup_To_Full_Mu')}
                            checked={renderCustomerOptions.Shipping.RounduptoFullMU == true}
                            value={renderCustomerOptions.Shipping.RounduptoFullMU} />

                        <div className={'fulfillment-wrapper-class'}>

                            <WmsRadio
                                label={this.translate("Label_Costomer_Proto_Fullfulment_Invoicing_Disabled")}
                                name={"FullfulmentInvoicingDisabled"}
                                id="FullfulmentInvoicingDisabled"
                                value={renderCustomerOptions.Shipping.FulfillmentInvoicing == 0}
                                wmsSelector={this.state.Selector + "FullfulmentInvoicingDisabled"}
                                onChangeFunc={() => this.handleChange('Shipping', { target: { name: 'FulfillmentInvoicing', value: 0 } })}
                                checked={renderCustomerOptions.Shipping.FulfillmentInvoicing == 0}
                                disabled={!this.state.rights.hasOwnProperty('Shipping.FulfillInvEnabled')}
                                showToolTip={!this.state.rights.hasOwnProperty('Shipping.FulfillInvEnabled')}
                                tooltipText={this.translate('Label_Setting_Disable_Tooltip')}
                            />
                            <WmsRadio
                                label={this.translate("Label_Costomer_Proto_Fullfulment_Invoicing_Enabled")}
                                name={"FullfulmentInvoicingEnabled"}
                                id="FullfulmentInvoicingEnabled"
                                value={renderCustomerOptions.Shipping.FulfillmentInvoicing == 1}
                                wmsSelector={this.state.Selector + "FullfulmentInvoicingEnabled"}
                                onChangeFunc={() => this.handleChange('Shipping', { target: { name: 'FulfillmentInvoicing', value: 1 } })}
                                checked={renderCustomerOptions.Shipping.FulfillmentInvoicing == 1}
                                disabled={!this.state.rights.hasOwnProperty('Shipping.FulfillInvEnabled')}
                                showToolTip={!this.state.rights.hasOwnProperty('Shipping.FulfillInvEnabled')}
                                tooltipText={this.translate('Label_Setting_Disable_Tooltip')}
                            />
                            <WmsRadio
                                label={this.translate("Label_Costomer_Proto_Edit_Fulfillment_Pricing")}
                                name={"EditFulfillmentPricing"}
                                id="EditFulfillmentPricing"
                                value={renderCustomerOptions.Shipping.FulfillmentInvoicing == 2}
                                wmsSelector={this.state.Selector + "EditFulfillmentPricing"}
                                onChangeFunc={() => this.handleChange('Shipping', { target: { name: 'FulfillmentInvoicing', value: 2 } })}
                                checked={renderCustomerOptions.Shipping.FulfillmentInvoicing == 2}
                                disabled={!this.state.rights.hasOwnProperty('Shipping.FulfillInvEnabled')}
                                showToolTip={!this.state.rights.hasOwnProperty('Shipping.FulfillInvEnabled')}
                                tooltipText={this.translate('Label_Setting_Disable_Tooltip')}
                            />
                        </div>
                    </WmsContainer >

                    <WmsContainer title={this.translate('Label_Mobile_Processing')}>
                        <div className='mobile-processing-wrapper'>
                            <div className='reallocate-wrapper'>
                                <WmsCheckBox
                                    id={'ReallocateAtPickTime'}
                                    name={'ReallocateAtPickTime'}
                                    wmsSelector={this.state.Selector + 'AllowAbilityToReallocation'}
                                    onChangeFunc={(e) => this.handleChange('UserInterface', e)}
                                    label={this.translate('Label_Pick_Allow_Reallocate')}
                                    checked={renderCustomerOptions.UserInterface.ReallocateAtPickTime == true}
                                    value={renderCustomerOptions.UserInterface.ReallocateAtPickTime}
                                    disabled={!this.state.rights.hasOwnProperty('OtherUserFeatures.ReallocateAtPickTimeEnabled')}
                                    parentClassName={this.state.rights.hasOwnProperty('OtherUserFeatures.ReallocateAtPickTimeEnabled') ? '' : 'disabled-option'}
                                    showToolTip={!this.state.rights.hasOwnProperty('OtherUserFeatures.ReallocateAtPickTimeEnabled')}
                                    tooltipText={this.translate('Label_Setting_Disable_Tooltip')}
                                    tooltipShowAfter={200} />

                                <WmsCheckBox
                                    id={'AllowMultiplePickers'}
                                    name={'AllowMultiplePickers'}
                                    wmsSelector={this.state.Selector + 'AllowMultiplePickers'}
                                    onChangeFunc={(e) => this.handleChange('MobileProcessingBehaviors', e)}
                                    label={this.translate('Label_Pick_Allow_Multiple_Pickers')}
                                    checked={renderCustomerOptions.MobileProcessingBehaviors.AllowMultiplePickers == true}
                                    value={renderCustomerOptions.MobileProcessingBehaviors.AllowMultiplePickers}/>
                            </div>
                            <div className={'auto-advance-fill-wrapper' + ( renderCustomerOptions.MobileProcessingBehaviors && renderCustomerOptions.MobileProcessingBehaviors.AllowMultiplePickers == true ? ' disabled-auto-advance-section' : '')}>
                                <WmsCheckBox
                                    id={'AutoAdvanceLineItem'}
                                    name={'AutoAdvanceLineItem'}
                                    wmsSelector={this.state.Selector + 'AutoAdvanceLineItem'}
                                    onChangeFunc={(e) => this.handleChange('MobileProcessingBehaviors', e)}
                                    label={this.translate('Label_Pick_Auto_Advance_Next_Line_Item')}
                                    checked={
                                        renderCustomerOptions.MobileProcessingBehaviors ?
                                            renderCustomerOptions.MobileProcessingBehaviors.AutoAdvanceLineItem == true :
                                            false
                                    }
                                    value={
                                        renderCustomerOptions.MobileProcessingBehaviors ?
                                            renderCustomerOptions.MobileProcessingBehaviors.AutoAdvanceLineItem :
                                            false
                                    } />

                                <WmsCheckBox
                                    id={'AutoFillSameSkuAndTrackby'}
                                    name={'AutoFillSameSkuAndTrackby'}
                                    wmsSelector={this.state.Selector + 'AutoFillSameSkuAndTrackby'}
                                    onChangeFunc={(e) => this.handleChange('MobileProcessingBehaviors', e)}
                                    label={this.translate('Label_Auto_Fill_SKU_And_Track_Bys')}
                                    parentClassName={'auto-fill-sku-wrapper' + ((renderCustomerOptions.MobileProcessingBehaviors &&
                                        renderCustomerOptions.MobileProcessingBehaviors.AutoAdvanceLineItem == false) ? ' disabled-auto-fill' :
                                        '')}
                                    checked={
                                        renderCustomerOptions.MobileProcessingBehaviors ?
                                            renderCustomerOptions.MobileProcessingBehaviors.AutoFillSameSkuAndTrackby == true :
                                            false
                                    }
                                    value={
                                        renderCustomerOptions.MobileProcessingBehaviors ?
                                            renderCustomerOptions.MobileProcessingBehaviors.AutoFillSameSkuAndTrackby :
                                            false
                                    } />
                            </div>
                        </div>
                    </WmsContainer >

                    <WmsContainer title={this.translate('Label_Costomer_Proto_Advance_ASN_Settings')}>

                        <WmsCheckBox
                            id={'RequireScacForAsn'}
                            name={'RequireScacForAsn'}
                            wmsSelector={this.state.Selector + 'RequireScacForAsn'}
                            onChangeFunc={this.handleAsnPreCheckChange}
                            label={this.translate('Label_Costomer_Proto_Require_SCAC')}
                            checked={
                                renderCustomerOptions.Shipping.AsnPreCheck ?
                                    renderCustomerOptions.Shipping.AsnPreCheck.RequireScacForAsn == true :
                                    false
                            }
                            value={
                                renderCustomerOptions.Shipping.AsnPreCheck ?
                                    renderCustomerOptions.Shipping.AsnPreCheck.RequireScacForAsn :
                                    false
                            } />

                        <WmsCheckBox
                            id={'RequireCarrierForAsn'}
                            name={'HasCarrier'}
                            wmsSelector={this.state.Selector + 'RequireCarrierForAsn'}
                            onChangeFunc={this.handleAsnPreCheckOptionsChange}
                            label={this.translate('Label_Costomer_Proto_Require_Carrier')}
                            checked={this.props.sharedAsnPrecheckedOptionsFlag.HasCarrier} />

                        <WmsCheckBox
                            id={'RequireLoadNumberForAsn'}
                            name={'HasLoadNumber'}
                            wmsSelector={this.state.Selector + 'RequireLoadNumberForAsn'}
                            onChangeFunc={this.handleAsnPreCheckOptionsChange}
                            label={this.translate('Label_Costomer_Proto_Require_Load_Number')}
                            checked={this.props.sharedAsnPrecheckedOptionsFlag.HasLoadNumber} />

                        <WmsCheckBox
                            id={'BillOfLoadingNumber'}
                            name={'HasBillOfLading'}
                            wmsSelector={this.state.Selector + 'BillOfLoadingNumber'}
                            onChangeFunc={this.handleAsnPreCheckOptionsChange}
                            label={this.translate('Label_Costomer_Proto_Require_Bill_of_Lading')}
                            checked={this.props.sharedAsnPrecheckedOptionsFlag.HasBillOfLading} />

                        <WmsCheckBox
                            id={'PoNumberForASN'}
                            name={'HasPoNumber'}
                            wmsSelector={this.state.Selector + 'PoNumberForASN'}
                            onChangeFunc={this.handleAsnPreCheckOptionsChange}
                            label={this.translate('Label_Costomer_Proto_Require_purchase_Order_Number')}
                            checked={this.props.sharedAsnPrecheckedOptionsFlag.HasPoNumber} />

                        <WmsCheckBox
                            id={'PkgCountNotZero'}
                            name={'HasPackages'}
                            wmsSelector={this.state.Selector + 'PkgCountNotZero'}
                            onChangeFunc={this.handleAsnPreCheckOptionsChange}
                            label={this.translate('Label_Costomer_Proto_Package_Count_Not_Equal_Zero')}
                            checked={this.props.sharedAsnPrecheckedOptionsFlag.HasPackages} />

                        <WmsCheckBox
                            id={'IsClosed'}
                            name={'IsClosed'}
                            wmsSelector={this.state.Selector + 'IsClosed'}
                            onChangeFunc={this.handleAsnPreCheckOptionsChange}
                            label={this.translate('Label_Costomer_Proto_Order_Closed')}
                            checked={this.props.sharedAsnPrecheckedOptionsFlag.IsClosed} />

                        <WmsCheckBox
                            id={'HasAttachFile'}
                            name={'RequireAttachedDocuments'}
                            wmsSelector={this.state.Selector + 'HasAttachFile'}
                            onChangeFunc={this.handleAsnPreCheckChange}
                            label={this.translate('Label_Costomer_Proto_Attach_File')}
                            checked={
                                renderCustomerOptions.Shipping.AsnPreCheck ?
                                    renderCustomerOptions.Shipping.AsnPreCheck.RequireAttachedDocuments == true :
                                    false
                            }
                            value={
                                renderCustomerOptions.Shipping.AsnPreCheck ?
                                    renderCustomerOptions.Shipping.AsnPreCheck.RequireAttachedDocuments :
                                    false
                            } />

                        <WmsCheckBox
                            id={'HasVolumeGeaterZero'}
                            name={'HasVolume'}
                            wmsSelector={this.state.Selector + 'HasVolumeGeaterZero'}
                            onChangeFunc={this.handleAsnPreCheckOptionsChange}
                            label={this.translate('Label_Costomer_Proto_Volume_And_Package_Greater_Zero')}
                            checked={this.props.sharedAsnPrecheckedOptionsFlag.HasVolume} />

                        <WmsCheckBox
                            id={'HasWeightGeaterZero'}
                            name={'HasWeight'}
                            wmsSelector={this.state.Selector + 'HasWeightGeaterZero'}
                            onChangeFunc={this.handleAsnPreCheckOptionsChange}
                            label={this.translate('Label_Costomer__Proto_weight_And_Package_Greater_Zero')}
                            checked={this.props.sharedAsnPrecheckedOptionsFlag.HasWeight} />
                        <WmsComboboxWithTag
                            id="OrderLevelFlexFields"
                            name="OrderLevelFlexFields"
                            label={this.translate('Label_Costomer__Proto_Order_Level_Custom_Fileds')}
                            wmsSelector={this.state.Selector + "OrderLevelFlexFields"}
                            valueArray={
                                (renderCustomerOptions.Shipping.AsnPreCheck && renderCustomerOptions.Shipping.AsnPreCheck.OrderLevelFlexFields) ?
                                    renderCustomerOptions.Shipping.AsnPreCheck.OrderLevelFlexFields :
                                    []
                            }
                            onChangeFunc={(e) => this.handleASNFlexFields('OrderLevelFlexFields', e)}
                            duplicateTagTooltipText={this.translate('Customer_Order_Custom_Fields_Duplicate_Tooltip')}
                            extraWrapperClass='form_group add-top-margin' />

                        <WmsComboboxWithTag
                            id="OrderItemLevelFlexFields"
                            name="OrderItemLevelFlexFields"
                            label={this.translate('Label_Costomer__Proto_Line_Item_Level_Custom_Fields')}
                            wmsSelector={this.state.Selector + "OrderItemLevelFlexFields"}
                            valueArray={
                                (renderCustomerOptions.Shipping.AsnPreCheck && renderCustomerOptions.Shipping.AsnPreCheck.OrderItemLevelFlexFields) ?
                                    renderCustomerOptions.Shipping.AsnPreCheck.OrderItemLevelFlexFields :
                                    []
                            }
                            onChangeFunc={(e) => this.handleASNFlexFields('OrderItemLevelFlexFields', e)}
                            duplicateTagTooltipText={this.translate('Customer_Order_Custom_Fields_Duplicate_Tooltip')}
                            extraWrapperClass='form_group' />

                    </WmsContainer >
                </WmsCollapse >
                <CustomersPackingSlipsGrid class=""
                sharedCustomerEditUri={this.props.sharedCustomerEditUri}
                sharedCustomer={this.props.sharedCustomer}
                originalCustomerObject={this.props.originalCustomerObject}
                onCustomerDataset={this.props.onCustomerDataset}
                setValidTemplateImageUrl={this.props.setValidTemplateImageUrl}
                windowId={this.props.windowId}
                 />
            </div>
        );
    }
}