import React from 'react';
import { FindOrdersSearchVirtual } from './findOrders-search.virtual.component.jsx';
import { FindOrdersGridVirtual } from './findOrders-grid.virtual.component.jsx';
import './findOrdes.virtual.scss';
import * as GlobalService from '../../global_services';
import * as metaDataService from '../meta-data.service';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import * as GridConfig from './grid_configuration.virtual.jsx';

export class FindOrdersHolderVirtual extends React.Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;
        this.$rootScope = window;
        this.defaultFilterValue = {
            IndexOptionsSearchBy: 0,
            IndexOptionsReferenceNumber: 0,
            IndexOptionsCustomerId: 0,
            IndexOptionsFacilityId: 0,
            IndexOptionsPoNum: 0,
            IndexOptionsShipTo: 0,
            IndexOptionsOrderNote: 0,
            IndexOptionsStatus: 2,
            IndexOptionsAsn: 2,
            IndexOptionsInventoryAllocated: 2,
            OrderNote: '',
            StartDate: '',
            EndDate: '',
            Sku: '',
            PurchaseOrderNumber: '',
            SelectedSkus: '',
            SelectedCustomer: 0,
            SelectedFacility: 0,
            ShowSku: true,
            ShowBatchName: true,
            SearchTarget: ""
        }
        this.findOrderGridId = "grid_with_remote_data";
        this.state = {
            sharedFindOrderFilter: Object.assign({}, this.defaultFilterValue),
            sharedSearchOn: false,
            isOptionCallComplete: false,
            filterObject: [],
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.findOrderGridId : '#' + this.findOrderGridId,

        }

    }

    componentDidMount() {
        kendo.ui.progress($('#wms-findorder-virtual-wrapper'), true);

        GlobalService.GetCustomersAndFacility().then(() => {
            this.setState({
                isOptionCallComplete:true
            });
        }).finally(() => {
            kendo.ui.progress($('#wms-findorder-virtual-wrapper'), false);
            this.intializeOrder();
        });
    }
    intializeOrder = () => {
        var filterData = metaDataService.getFindOrderFilterMetaData();
        if (filterData != "") {

            // temporary remove batch name from metaData,
            // remove code after pass batchId in filter
            delete filterData.BatchName;

            this.setState({
                sharedFindOrderFilter: filterData,
                sharedSearchOn: true,
            }, () => { 
                this.refs.findOrdersGridVirtual.callForGetTotalCount() 
            });
        } else {
            this.refs.findOrdersGridVirtual.callForGetTotalCount();
        }
    }
    onFilterDataSet = (param_filterObj, isNeedToSetFilter = false) => {
        metaDataService.setFindOrderFilterMetaData(param_filterObj);
        this.setState({
            sharedFindOrderFilter: param_filterObj,
            sharedSearchOn: true
        }, () => {
            // reset selection of grid on filter data change
            if(isNeedToSetFilter) {
                this.refs.findOrdersSearchVirtual.setFilter(param_filterObj);
            }
            this.refs.findOrdersGridVirtual.clearSelectionOfGrid();
            this.refs.findOrdersGridVirtual.callForGetTotalCount();
        });
    }

    onApplySavedFilter = (param_filterObj) => {
        this.setState({
            sharedFindOrderFilter: param_filterObj,
            sharedSearchOn: true
        }, () => {

            // reset selection of grid on filter data change
            this.refs.findOrdersGridVirtual.clearSelectionOfGrid();
            this.refs.findOrdersGridVirtual.callForGetTotalCount();
            var grid = $(this.state.gridDomSelector()).data("kendoGrid");
            var dataSource = grid.dataSource;
            this.refs.findOrdersGridVirtual.setFilterAppliedText(dataSource.filter());
            this.refs.findOrdersGridVirtual.onApplySavedFilterData(param_filterObj);
        });
    }

    getEnableColumns = () => {
        var defaultParam = ["FullyAllocated", "IsClosed", "CanConfirm", "CanCancel", "CanUnconfirm", "FacilityId", "CustomerId", "BatchOrderId", "BatchUri", "EditUri", "FileSummariesUri", "CustomerUri", "CanSplit", "CanAllocate", "PickJobId", "IsPickDone", "CanDeallocate", "CanExportToTmw", "ParcelLabelType"]
        let columnList = [];

        let grid = $('#grid_with_remote_data').data('kendoGrid');
        // if grid already defined than we get columns from grid
        if (typeof grid != "undefined") {
            columnList = grid.columns;
        } else { // else get column list from metadataObject or default columns
            let Orders_FindOrders_GridHeaders = StringWms.convertSringToOption(this.translate('Orders_FindOrders_GridHeaders'));
            columnList = GridConfig.getGridDemoColumns(Orders_FindOrders_GridHeaders, GlobalService.getCustomLabels()).columns;
            var findOrderGridConfig = metaDataService.getFindOrderGridOptionInMetaData();
            if (findOrderGridConfig != "") {
                var gridOptions = JSON.parse(findOrderGridConfig);
                if (gridOptions.columns) {
                    columnList = gridOptions.columns;
                }
            }
        }

        var enableColumns = columnList.map(el => el.field);
        // merge defaultParam and enableColumns without dublicate value
        return [...new Set([...defaultParam, ...enableColumns])];
    }
    
    
    getUpdatedData = () => {
        this.refs.findOrdersGridVirtual.callForGetTotalCount(false);
    }
    
    
    // click on save search filter in meta data
    onManageFilter = (param_action, param_data) => {
        var filterData = this.state.sharedFindOrderFilter;
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var filterArray = grid.dataSource.filter() ? grid.dataSource.filter() : [];
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = GlobalService.prepareGridOptionsForStorage(gridColumn, sortArray, filterArray);
        if (param_action == 'saveFilter') {
            metaDataService.saveFindOrderFilterMetaData(param_data, JSON.parse(JSON.stringify(filterData)), finalSubmission);
        } else {
            metaDataService.updateFindOrderFilterMetaData(param_data, JSON.parse(JSON.stringify(filterData)), finalSubmission);
            GlobalService.notification('success',this.translate('Order_Filter_Update_success'));
        }
    }

    onClearSearch = () => {
        metaDataService.clearFindOrderFilterMetaData();
        this.setState({
            sharedFindOrderFilter: Object.assign({}, this.defaultFilterValue),
            sharedSearchOn: false
        }, () => {
            // reset selection of grid on filter data change
            var grid = $(this.state.gridDomSelector()).data("kendoGrid");
            if (typeof grid != "undefined") {
                grid.dataSource.filter([]);
                var gridColumn = grid.getOptions().columns;
                var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
                let finalSubmission = GlobalService.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());

                this.refs.findOrdersGridVirtual.clearSelectionOfGrid();
                this.refs.findOrdersGridVirtual.callForGetTotalCount();
                setTimeout(() =>{
                    metaDataService.setFindOrderGridOptionInMetaData(finalSubmission);
                },500)
                this.refs.findOrdersGridVirtual.setFilterAppliedText(grid.dataSource.filter());
            }
        });
    }
    render() {
        return (
            <div id="wms-findorder-virtual-wrapper" className={
                (this.props.sharedSelectOrder ?
                    "order-grid" :
                    ""
                ) + (this.props.sharedFilterAreaToggle ? " collapsedFilter" : " expandFilter")
                + (this.props.isAnimatedClassAdded ? " animatedCollapse" : "")
                + " find-order-holder-react new-layout-grid-filter-wrapper"
            }>
                {this.state.isOptionCallComplete && (
                    <FindOrdersSearchVirtual
                        ref="findOrdersSearchVirtual"
                        windowId={this.props.windowId}
                        defaultFilterValue={this.defaultFilterValue}
                        sharedSearchOn={this.state.sharedSearchOn}
                        saveFilterList={this.state.saveFilterList}
                        onFilterDataSet={this.onFilterDataSet}
                        onApplySavedFilter={this.onApplySavedFilter}
                        onGridSearch={this.onGridSearch}
                        onClearSearch={this.onClearSearch}
                        onManageFilter={this.onManageFilter}
                        onClickForFilterToggle={this.props.onClickForFilterToggle}
                        sharedSelectOrder={this.props.sharedSelectOrder}
                    />
                )}
                {this.state.isOptionCallComplete && (
                    <FindOrdersGridVirtual
                        ref="findOrdersGridVirtual"
                        windowId={this.props.windowId}
                        sharedFilterAreaToggle={this.props.sharedFilterAreaToggle}
                        sharedFindOrderFilter={this.state.sharedFindOrderFilter}
                        onViewSummary={this.props.onViewSummary}
                        onCreateOrderProto={this.props.onCreateOrderProto}
                        onEditOrderProto={this.props.onEditOrderProto}
                        getUpdatedData={this.getUpdatedData}
                        reloadGrid={this.props.reloadGrid}
                        getEnableColumns={this.getEnableColumns}
                        onFilterDataSet={this.onFilterDataSet}
                    />
                )}
            </div>
        );
    }
}