import React from 'react';

//component
import WmsModel from '../../../../components/WmsModel/WmsModel.jsx';
import WmsCollapse from '../../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsButton from '../../../../components/WmsButton/WmsButton.jsx';
import WmsDropdown from '../../../../components/WmsDropdown/WmsDropdown.jsx';


//services
import * as GlobalService from './../../../../global_services';
import * as findOrdersService from '../../findOrders.services.jsx';

import './transfer-order.scss';
export class TransferOrderModel extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            selector: "TransferOrder",
            transferOrdeGif:false,
            facilityOptions:[],
            selectedFacilityId:'',
            selectedFacilityName:'',
        }
    }

    componentWillMount() {
        this.getFacilityCustomerById();
    }

    getFacilityCustomerById() {
        this.setState({
            facilityOptions: this.props.transferFacilityOptions,
            selectedFacilityName: this.props.transferFacilityName
        });
    }
    handleFacilityChange = (e) =>{
        this.setState({selectedFacilityId:e.target.value});
        console.log("handleFacilityChange call:",e.target.value);
    }
    transferOrder =()=>{
        var payload = {
            OrderIds: this.props.sharedSelectedOrderIds,
            FacilityId: this.state.selectedFacilityId
        }

        findOrdersService.OrdersTranfer(payload).then((response) => {
            if (typeof response != 'undefined' && response.status === 200) {
                    // showing moving icon animation start

                this.$rootScope.animationMoveIcon($('#transferOrderAsync'));
                this.$rootScope.taskStarted();
                // this.$rootScope.$broadcast("EventProgressOrdersTransfer", {
                //     "ordersTransfer": { uri: response.data.StatusRelativeUri },
                //     "callBackFunc": this.ordersTransfer,
                //     'numSelectedOrders': this.props.sharedSelectedOrderIds.length
                // });
                var transferOrderObj =  {
                    "type":"ordersTransfer",
                    "ordersTransfer": { uri: response.data.StatusRelativeUri },
                    "callBackFunc": this.ordersTransfer,
                    'numSelectedOrders': this.props.sharedSelectedOrderIds.length
                }
                var event = new CustomEvent("showProgressModal", {
                    detail: transferOrderObj
                });
                document.dispatchEvent(event);
                }
        }).finally(() => {

            this.props.onCloseTransferOrderModel();
        });

    }

    ordersTransfer = (data) => {
        console.log("ordersTransfer data", data);
    }

    render() {
        return (
            <WmsModel
                id={"transferORder"}
                class="transferORder"
                title={this.translate('Label_Transfer_Order' + (this.props.sharedSelectedOrderIds.length > 1 ? "s": ""))}
                width={650}
                onCloseFunc={this.props.onCloseTransferOrderModel}
                height={346}
                isOpen={this.props.sharedIsOpenTransferModel}
                customClass='wms-model-new-ui-wrapper' >
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'shipAndCloseRoutingInfo'}
                        headerLabel={this.translate('note_Transfer_Order_Options')}
                        showCollapseText={false} >
                        <div className="title">
                            <div className="wms-sprite fa fa-exclamation-triangle"></div>
                            <span>
                                {this.translate(this.props.sharedSelectedOrderIds.length > 1 ? "Teansfer_Orders_Summary" : "Teansfer_Order_Summary").replace(new RegExp('{' + 'amount' + '}', 'gi'), this.props.sharedSelectedOrderIds.length).replace(new RegExp('{' + 'facility' + '}', 'gi'), this.state.selectedFacilityName)}
                            </span>
                        </div>
                        <WmsDropdown
                            id="transferOrderWarehouse"
                            name="transferOrderWarehouse"
                            label={this.translate('Label_Transfer_Warehouse_Text')}
                            wmsSelector={this.state.selector + "transferOrderWarehouse"}
                            value={this.state.selectedFacilityId}
                            onChangeFunc={this.handleFacilityChange}
                            options={this.state.facilityOptions}
                            valueField='id'
                            textField='name'
                            disabled={this.state.facilityOptions.length == 0}
                            showToolTip={this.state.facilityOptions.length == 0}
                            tooltipText={this.translate('Label_Cannot_TransferOrders')}
                            blankFirstOption={true}
                        />
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        id="transferOrderCancel"
                        name="transferOrderCancel"
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.state.selector + "ButtonCancel"}
                        onClickFunc={this.props.onCloseTransferOrderModel}
                        icon="fa-ban"
                        buttonType="yellow"
                        loading={false}
                    />
                    <WmsButton
                        id="transferOrderAsync"
                        name="transferOrderAsync"
                        label={this.translate("Label_Button_Transfer_Order" + (this.props.sharedSelectedOrderIds.length > 1 ? "s" : "")) }
                        wmsSelector={this.state.selector + "transferORder"}
                        onClickFunc={this.transferOrder}
                        disabled={!this.state.selectedFacilityId || this.state.facilityOptions.length == 0}
                        showToolTip={this.state.facilityOptions.length == 0}
                        tooltipText={this.translate('Label_Cannot_TransferOrders')}
                        loading={this.state.transferOrdeGif}
                        icon={'fa-exchange'}
                    />
                </div>
            </WmsModel>
        )
    }
}