import React from 'react';

import WmsButton from './../../components/WmsButton/WmsButton.jsx';
import WmsDropdown from './../../components/WmsDropdown/WmsDropdown.jsx';
import WmsModel from './../../components/WmsModel/WmsModel.jsx';
import WmsCollapse from './../../components/WmsCollapse/WmsCollapse.jsx';
import WmsCheckBox from './../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDateTimePicker from './../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import { FedexEulaAgreementComponent } from './fedex-eula-agreement.component.jsx';
import { FedexEditComponent } from './fedex-edit.component.jsx';
import { UpsEditComponent } from './ups-edit.component.jsx';
import { UpsEulaAgreementComponent } from './ups-eula-agreement.component.jsx';
import WmsInput from './../../components/WmsInput/WmsInput.jsx';
import style from './new-carrier-connection.scss';
var axios = require('axios');

//services
import * as GlobalService from './../../global_services';
import * as carrierService from './../carriers.services.jsx';
import { EndiciaAccountInfoComponent } from './endicia-account-info.component.jsx';
import { DHLeCommerceAccountInfoComponent } from './dhl-eCommerce-account-info.component.jsx';
import { DHLExpressAccountInfoComponent } from './dhl-express-account-info.component.jsx';
import { AustraliaPostAccountInfoComponent } from './australia-post-account-info.component.jsx';
import { StampsAccountInfoComponent } from './stamps-account-info.component.jsx';
import { APCAccountInfoComponent } from './apc-account-info.component.jsx';
import { FirstMileInfoComponent } from './first-mile-info.component.jsx';
import { GlobegisticsInfoComponent } from './globegistics-info.component.jsx';
import { RrDonnelleyInfoComponent } from './rrDonnelley-info.component.jsx';
import { CanadaPostComponent } from './canada-post.component.jsx';
import { OnTraceComponent } from './on-trac.component.jsx';
import { SekoComponent } from './seko.component.jsx';
import { AmazonComponent } from './amazon.component.jsx';
import { CanparAccountInfoComponent } from './canpar-account-info.component.jsx';
import { PurolatorAccountInfoComponent } from './purolator-account-info.component.jsx';
import { DHLExpressAUAccountInfoComponent } from './dhl-express-au-account-info.component.jsx';

export class NewCarrierConnectionModel extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            selector: "newConnection",
            rights: GlobalService.GetRights(),
            selectedContentType: '',
            selectedFacilityIndex: '',
            selectedCustomerIndex: '',
            selectedCarrierIndex: '',
            selectedCarrier: '',
            connectionLoader: false,
            chkNegotiatedRates: true,
            currentStep: 1,
            totalSteps: 0,
            currentCarrierImgUrl: '',
            currentLoginLabel: 'Login',
            carrierOptions: [],
            pickupTypeOptions: [],
            smartPostHubOptions: [],
            smartPostEndorsementOptions: [],
            mailInnovationsEndorsementOptions: [],
            optionsCountry: GlobalService.GetCountries(),
            customerOptions: GlobalService.getCustomerList(),
            facilityOption: GlobalService.getFacilityList(false),
            optionsState: [],
            connectionObj: {}
        };
        this.loadDropdownOptions();
    }
    componentDidMount() {
        if (this.props.sharedModelFlow == 'edit') {

            kendo.ui.progress($('#newCarrierConnection'), true);
            carrierService.getCarrierDetail(this.props.sharedSelectedCarrierAccountData.ShipEngineId).then((response) => {
                // response.data.CarrierName = 'UPS';
                this.setState({
                    connectionObj: response.data
                });
                this.setCarrierOptions(response.data.CarrierName);

            }).finally(() => {
                kendo.ui.progress($('#newCarrierConnection'), false);
            });

        } else {
            var selectedContentType = 'Warehouse Account';
            var selectedCustomerIndex = '';
            if (this.props.sharedSelectedCustomerIndex) {
                selectedContentType = 'Customer Account';
                selectedCustomerIndex = this.props.sharedSelectedCustomerIndex;
            }
            this.setState({
                selectedContentType: selectedContentType,
                selectedCustomerIndex: selectedCustomerIndex
            })
        }
    }
    loadDropdownOptions = () => {
        axios.get('/WebUI/Settings/CarrierAccount/GetCarriers')
            .then((response) => {
                var arrrOfRemoveCarrieresList = ["DHL Express UK", "DHL Express Canada", "FedEx UK", "DPD", "Asendia", "Newgistics", "Royal Mail", "Lone Star Overnight", "Less-Than-Load", "Walmart Fedex"];
                _.remove(response.data.Carriers, obj => arrrOfRemoveCarrieresList.includes(obj.Carrier));
                this.setState({
                    carrierOptions: response.data.Carriers
                });
            });
    }
    handleChangeEvent = (e) => {
        let name = e.target.name;
        if (e.target.type == 'checkbox') {
            this.setState({ [name]: !this.state[name] });
        } else {
            this.setState({ [name]: e.target.value });
        }
        // if(name == 'selectedContentType' ){
        //     this.setState({selectedFacilityIndex:''});
        // }

        if (name == 'selectedCarrierIndex') {
            let tmpSelectedCarrier = this.state.carrierOptions[e.target.value].Carrier;

            this.setCarrierOptions(tmpSelectedCarrier);

            this.setState({ connectionObj: {} });
        }
    }
    setCarrierOptions = (tmpSelectedCarrier) => {
        let totalSteps = 2;
        let currentStep = 1;
        let currentCarrierImgUrl = '3PL_Logo_Orange.png';
        let currentLoginLabel = 'Login';

        if (tmpSelectedCarrier == 'Endicia') {
            totalSteps = 2;
            currentCarrierImgUrl = 'endicia-logo.png';
            currentLoginLabel = 'Endicia Account';
        } else if (tmpSelectedCarrier == 'FedEx') {
            totalSteps = 3;
            currentCarrierImgUrl = 'FedEx-logo.png';
        } else if (tmpSelectedCarrier == 'Stamps.com') {
            totalSteps = 2;
            currentCarrierImgUrl = 'Stamps.com-logo.png';
            currentLoginLabel = 'Stamps Account';
        } else if (tmpSelectedCarrier == 'APC') {
            totalSteps = 2;
            currentCarrierImgUrl = 'APC-logo.png';
        } else if (tmpSelectedCarrier == 'UPS') {
            totalSteps = 4;
            currentCarrierImgUrl = 'UPS-logo.png';
        } else if (tmpSelectedCarrier == 'DHL eCommerce') {
            totalSteps = 2;
            currentCarrierImgUrl = 'dhl-ecommerce.png';
        } else if (tmpSelectedCarrier == 'DHL Express') {
            totalSteps = 2;
            currentCarrierImgUrl = 'dhl-express.png';
        } else if (tmpSelectedCarrier == 'Australia Post') {
            totalSteps = 2;
            currentCarrierImgUrl = 'australia-post.png';
        } else if (tmpSelectedCarrier == 'Purolator') {
            totalSteps = 2;
            currentCarrierImgUrl = 'purolator-seeklogo.com.png';
        } else if (tmpSelectedCarrier == 'DHL Express Australia') {
            totalSteps = 2;
            currentCarrierImgUrl = 'DHLExpressAU.png';
        } else if (tmpSelectedCarrier == 'Firstmile') {
            totalSteps = 2;
            currentCarrierImgUrl = 'First Mile.png';
        } else if (tmpSelectedCarrier == 'Globegistics') {
            totalSteps = 2;
            currentCarrierImgUrl = 'Globegistics.png';
        } else if (tmpSelectedCarrier == 'RR Donnelley') {
            totalSteps = 2;
            currentCarrierImgUrl = 'rrDonnelley.png';
        } else if (tmpSelectedCarrier == 'Canada Post') {
            totalSteps = 2;
            currentCarrierImgUrl = 'canada-post-logo.jpg';
        } else if (tmpSelectedCarrier == 'OnTrac') {
            totalSteps = 2;
            currentCarrierImgUrl = 'ontrac.png';
        } else if (tmpSelectedCarrier == 'SEKO') {
            totalSteps = 2;
            currentCarrierImgUrl = 'seko.png';
        } else if (tmpSelectedCarrier == 'Amazon Buy') {
            totalSteps = 2;
            currentCarrierImgUrl = 'amazon.png';
        } else if (tmpSelectedCarrier == 'Canpar') {
            totalSteps = 2;
            currentCarrierImgUrl = 'canpar.png';
        }



        let optionsForSettings = GlobalService.getDropdownOptionsForSettings(tmpSelectedCarrier);

        this.setState({
            currentCarrierImgUrl: currentCarrierImgUrl,
            totalSteps: totalSteps,
            currentLoginLabel: currentLoginLabel,
            selectedCarrier: tmpSelectedCarrier,
            pickupTypeOptions: optionsForSettings.pickupType,
            smartPostHubOptions: optionsForSettings.smartPostHub,
            smartPostEndorsementOptions: optionsForSettings.smartPostEndorsement,
            mailInnovationsEndorsementOptions: optionsForSettings.mailInnovationsEndorsement,
            currentStep: currentStep
        });
    }

    handleConnectionObjChange = (e) => {

        let tmpConnectionObj = Object.assign({}, this.state.connectionObj);
        let tmpName = e.target.name;

        if (e.target.type == 'checkbox') {
            tmpConnectionObj[tmpName] = !tmpConnectionObj[tmpName];
            if (tmpName == "UseNegotiatedRates" && this.props.sharedModelFlow == 'edit' && this.state.selectedCarrier == 'UPS' && tmpConnectionObj[tmpName] == false) {
                tmpConnectionObj["InvoiceDate"] = "";
                tmpConnectionObj["InvoiceNumber"] = "";
                tmpConnectionObj["ControlId"] = "";
                tmpConnectionObj["InvoiceAmount"] = "";
            }

        } else {
            tmpConnectionObj[tmpName] = e.target.value;
        }

        if (tmpName == 'CountryCode') {
            this.setState({ optionsState: GlobalService.GetRegions(e.target.value) });
        }
        this.setState({ connectionObj: tmpConnectionObj });
    }

    movePreviousStep = () => {
        let prvStep = this.state.currentStep - 1;
        this.setState({ currentStep: prvStep });
    }

    moveNextStep = () => {
        let nxtStep = this.state.currentStep + 1;
        this.setState({ currentStep: nxtStep });
    }

    updateCarrierSettings = () => {

        this.setState({ connectionLoader: true });

        carrierService.updateCarrierSetting(this.state.connectionObj).then((response) => {

            GlobalService.notification('success', this.translate('Label_Carrier_Update_Success'));

            this.props.onRefreshGridData();
            this.props.onCloseNewConnectionModel();

        }).finally(() => {
            this.setState({ connectionLoader: false });
        });
    }

    registerConnection = () => {
        let tmpConnectionObj = Object.assign({}, this.state.connectionObj);
        let carrierObj = this.state.carrierOptions[this.state.selectedCarrierIndex];
        let facilityObj = this.state.facilityOption[this.state.selectedFacilityIndex];
        let customerObj = this.state.customerOptions[this.state.selectedCustomerIndex];

        tmpConnectionObj.CarrierName = carrierObj.Carrier;
        tmpConnectionObj.CarrierUri = carrierObj.Uri;

        if (facilityObj && this.state.selectedContentType == 'Warehouse Account') {
            tmpConnectionObj.FacilityId = facilityObj.id;
            tmpConnectionObj.FacilityUri = facilityObj.uri;
        } else {
            tmpConnectionObj.CustomerId = customerObj.id;
            tmpConnectionObj.CustomerUri = customerObj.uri;
        }


        if (tmpConnectionObj.CarrierName === "APC") {
            tmpConnectionObj.AccountNumber = tmpConnectionObj.Username;
        } else if (tmpConnectionObj.CarrierName === "Firstmile") {
            tmpConnectionObj.AccountNumber = tmpConnectionObj.MailerId;
        } else if (tmpConnectionObj.CarrierName === "Globegistics") {
            tmpConnectionObj.AccountNumber = tmpConnectionObj.Username;
        } else if (tmpConnectionObj.CarrierName === "Purolator") {
            tmpConnectionObj.AccountNumber = tmpConnectionObj.ApiKey;
        } else if (tmpConnectionObj.CarrierName === "RR Donnelley") {
            tmpConnectionObj.AccountNumber = tmpConnectionObj.Username;
        } else if (tmpConnectionObj.CarrierName === "SEKO") {
            tmpConnectionObj.AccountNumber = tmpConnectionObj.AccessKey;
        } else if (tmpConnectionObj.CarrierName === "DHL eCommerce") {
            tmpConnectionObj.AccountNumber = tmpConnectionObj.ClientId;
        }

        carrierService.registerShipEngine(tmpConnectionObj).then((response) => {
            this.setState({ connectionLoader: false });
            if (response && response.status == 200) {
                // response.data.message = "Failour: test message";
                var msg = response.data.message;
                // GlobalService.notification('success', this.translate('New_carrier_Connection_Success'));
                //GlobalService.notification('success', response.data.message);
                if (msg.indexOf('Failure:') === -1) {
                    GlobalService.notification('success', msg);
                    this.props.onRefreshGridData();
                    this.props.onCloseNewConnectionModel();
                } else {
                    // GlobalService.notification('error', msg);
                    var failureMsg = msg.replace(/[\u0022\u0027\u003a\u003b]/g, '');
                    var _data = {
                        "ErrorCode": "System",
                        "ErrorMessage": '',
                        "Field": "",
                        "RequestorContextId": failureMsg
                    };
                    GlobalService.notification("errorr", _data);
                }

            }
        }).finally(() => {
            this.setState({ connectionLoader: false });
        });

    }



    render() {

        let carrierAccountInformationSection = () => {
            switch (this.state.selectedCarrier) {
                case 'Endicia':
                    return <EndiciaAccountInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                        currentLoginLabel={this.state.currentLoginLabel}
                    />;
                case 'DHL eCommerce':
                    return <DHLeCommerceAccountInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                        currentLoginLabel={this.state.currentLoginLabel}
                    />;
                case 'DHL Express':
                    return <DHLExpressAccountInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                    />;
                case 'Australia Post':
                    return <AustraliaPostAccountInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                    />;
                case 'Stamps.com':
                    return <StampsAccountInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                        currentLoginLabel={this.state.currentLoginLabel}
                    />;
                case 'APC':
                    return <APCAccountInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                    />;
                case 'Purolator':
                    return <PurolatorAccountInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                        currentLoginLabel={this.state.currentLoginLabel}
                    />;
                case 'DHL Express Australia':
                    return <DHLExpressAUAccountInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                        currentLoginLabel={this.state.currentLoginLabel}
                    />;
                case 'Firstmile':
                    return <FirstMileInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                    />;
                case 'Globegistics':
                    return <GlobegisticsInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                    />;
                case 'RR Donnelley':
                    return <RrDonnelleyInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                    />;
                case 'Canada Post':
                    return <CanadaPostComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                    />;
                case 'OnTrac':
                    return <OnTraceComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                    />;
                case 'SEKO':
                    return <SekoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                    />;
                case 'Amazon Buy':
                    return <AmazonComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                    />;
                case 'Canpar':
                    return <CanparAccountInfoComponent
                        selector={this.state.selector}
                        connectionObj={this.state.connectionObj}
                        handleConnectionObjChange={this.handleConnectionObjChange}
                    />;

                default:
                    return <div className="default-section">
                        We are currently building out the connection user interface for this carrier and expect to complete it soon. If you need this carrier setup immediately please contact your Customer Success Manager to begin the process.
                            </div>;
            }
        };
        let editModelTitle = 'Title_carrier_EDIT_Carrier_ACCOUNT';
        let editCarrierImgUrl = '3PL_Logo_Orange.png';
        if (this.props.sharedModelFlow == 'edit') {
            if (this.state.selectedCarrier == 'FedEx') {
                editModelTitle = 'Title_carrier_EDIT_FEDEX_ACCOUNT';
                editCarrierImgUrl = 'FedEx-logo.png';
            } else if (this.state.selectedCarrier == 'UPS') {
                editModelTitle = 'Title_carrier_EDIT_UPS_ACCOUNT';
                editCarrierImgUrl = 'UPS-logo.png';
            }
        }
        return (

            <WmsModel
                id={'newCarrierConnection'}
                title={
                    this.props.sharedModelFlow == 'create' ? this.translate('Title_New_carrier_Connection_Model') :
                        this.translate(editModelTitle)
                }
                width={800}
                height={750}
                class='new-carrier-connection-wrapper'
                onCloseFunc={this.props.onCloseNewConnectionModel}
                isOpen={this.props.sharedIsOpennewConnectionModel}
                customClass='wms-model-new-ui-wrapper'>
                <div className="model-content-wrapper">
                    {/* First step */}
                    {this.props.sharedModelFlow == 'edit' && (
                        <div className="connection-step-block">
                            <div className="image-section">
                                <img src={'assets/images/' + editCarrierImgUrl} />
                            </div>
                            {this.state.selectedCarrier == 'FedEx' &&
                                <FedexEditComponent
                                    selector={this.state.selector}
                                    connectionObj={this.state.connectionObj}
                                    handleConnectionObjChange={this.handleConnectionObjChange} />
                            }
                            {this.state.selectedCarrier == 'UPS' &&
                                <UpsEditComponent
                                    selector={this.state.selector}
                                    connectionObj={this.state.connectionObj}
                                    handleConnectionObjChange={this.handleConnectionObjChange} />
                            }
                            {(this.state.selectedCarrier !== 'FedEx' && this.state.selectedCarrier !== 'UPS') &&
                                <div className="default-section">
                                    Carrier details not found, If you need any help please contact your Customer Success Manager to begin the process.
                        </div>
                            }
                        </div>
                    )}
                    {this.props.sharedModelFlow == 'create' && this.state.currentStep == 1 && (
                        <div className="wms-carrier-connection">
                            <WmsCollapse
                                id={'carrierSelectConnection'}
                                headerLabel={this.translate("Label_Carrier_Select")}
                                showCollapseText={false}
                                showCollapseIcon={false} >

                                <WmsDropdown
                                    id="selectedContentType"
                                    name="selectedContentType"
                                    label={this.translate('Label_Connection_Type')}
                                    required={true}
                                    wmsSelector={this.state.selector + "contentType"}
                                    onChangeFunc={this.handleChangeEvent}
                                    value={this.state.selectedContentType}
                                    options={[{
                                        'Text': 'Warehouse Account',
                                        'Value': 'Warehouse Account',
                                    }, {
                                        'Text': 'Customer Account',
                                        'Value': 'Customer Account',
                                    }]}
                                    valueField='Value'
                                    textField='Text'
                                    parentClassName='form_group'
                                    blankFirstOption={true}
                                    extraWrapperClass={"new-connection-first-element"} />
                                {/* <div className={this.state.selectedContentType == 'Customer Account' ? 'disabled-facility-opt':''}> */}
                                {this.state.selectedContentType == 'Customer Account' ? (
                                    <WmsDropdown
                                        id="selectedCustomerIndex"
                                        name="selectedCustomerIndex"
                                        label={this.translate('FindOrders_Lbl_Customer')}
                                        required={true}
                                        wmsSelector={this.state.selector + "customer"}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={this.state.selectedCustomerIndex}
                                        options={this.state.customerOptions}
                                        valueField='id'
                                        textField='name'
                                        parentClassName='form_group'
                                        applyIndex={true}
                                        blankFirstOption={true}
                                        extraWrapperClass={"new-connection-second-element"} />
                                ) : (
                                        <WmsDropdown
                                            id="selectedFacilityIndex"
                                            name="selectedFacilityIndex"
                                            label={this.translate('Label_Warehouse_Text')}
                                            required={true}
                                            wmsSelector={this.state.selector + "facility"}
                                            onChangeFunc={this.handleChangeEvent}
                                            value={this.state.selectedFacilityIndex}
                                            options={this.state.facilityOption}
                                            valueField='id'
                                            textField='name'
                                            parentClassName='form_group'
                                            applyIndex={true}
                                            blankFirstOption={true}
                                            extraWrapperClass={"new-connection-second-element"} />
                                    )}
                                {/* </div> */}

                                <WmsDropdown
                                    id="selectedCarrierIndex"
                                    name="selectedCarrierIndex"
                                    label={this.translate('Label_Carrier')}
                                    required={true}
                                    wmsSelector={this.state.selector + "carrier"}
                                    onChangeFunc={this.handleChangeEvent}
                                    value={this.state.selectedCarrierIndex}
                                    options={this.state.carrierOptions}
                                    valueField='Carrier'
                                    textField='DisplayName'
                                    parentClassName='form_group'
                                    applyIndex={true}
                                    blankFirstOption={true}
                                    extraWrapperClass={"new-connection-third-element"} />

                            </WmsCollapse>
                        </div>
                    )}


                    {/* Second step */}
                    {this.state.currentStep == 2 && (
                        <div className="connection-step-block">
                            <div className={"image-section " + (this.state.selectedCarrier == 'Amazon Buy' ? 'img-amazon' : '')}>
                                <img src={'assets/images/' + this.state.currentCarrierImgUrl} />
                            </div>

                            {this.state.selectedCarrier == 'FedEx' || this.state.selectedCarrier == 'UPS' ? (
                                <WmsCollapse
                                    id={'carrierAgreementSection'}
                                    headerLabel={this.translate("Label_Agreement")}
                                    showCollapseText={false}
                                    showCollapseIcon={false} >
                                    {this.state.selectedCarrier == 'FedEx' ?
                                        <FedexEulaAgreementComponent /> :
                                        <UpsEulaAgreementComponent />
                                    }
                                    <WmsCheckBox
                                        id={'agreeToEula'}
                                        name={"AgreeToEula"}
                                        wmsSelector={this.state.selector + 'agreeToEula'}
                                        onChangeFunc={this.handleConnectionObjChange}
                                        label={this.translate('Label_Agree_Terms') + ' *'}
                                        checked={this.state.connectionObj.AgreeToEula == true}
                                        isRounded={true}
                                        value={this.state.connectionObj.AgreeToEula}
                                    />

                                </WmsCollapse>
                            ) : (
                                    <div>
                                        {carrierAccountInformationSection()}
                                    </div>
                                )}
                        </div>
                    )}

                    {/* Third step */}
                    {this.state.currentStep == 3 && (
                        <div className="connection-step-block">
                            <div className="image-section">
                                <img src={'assets/images/' + this.state.currentCarrierImgUrl} />
                            </div>

                            <WmsCollapse
                                id={'carrierCompoanyInformation'}
                                headerLabel={this.translate("Label_Company_Information")}
                                showCollapseText={false}
                                showCollapseIcon={false} >

                                <div className='display-inline'>
                                    <WmsInput
                                        id='firstName'
                                        inputName='FirstName'
                                        wmsSelector={this.state.selector + 'firstName'}
                                        onChangeFunc={this.handleConnectionObjChange}
                                        label={this.translate('Label_Warehouse_First_Name')}
                                        extraWrapperClass={this.state.selectedCarrier == 'UPS' ? 'ups-first-name wms-input-names-box' : 'wms-input-names-box'}
                                        value={this.state.connectionObj.FirstName} />

                                    <WmsInput
                                        id='lastName'
                                        inputName='LastName'
                                        wmsSelector={this.state.selector + 'lastName'}
                                        onChangeFunc={this.handleConnectionObjChange}
                                        label={this.translate('Label_Warehouse_Last_Name')}
                                        extraWrapperClass="wms-input-title-box"
                                        value={this.state.connectionObj.LastName} />
                                </div>

                                {this.state.selectedCarrier == 'UPS' &&
                                    <div className='display-inline ups-section'>
                                        <WmsInput
                                            id='contactTitle'
                                            inputName='ContactTitle'
                                            wmsSelector={this.state.selector + 'contactTitle'}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            label={this.translate('Label_Title')}
                                            extraWrapperClass="wms-input-title-box"
                                            value={this.state.connectionObj.ContactTitle} />
                                    </div>
                                }

                                <WmsInput
                                    id='company'
                                    inputName='Company'
                                    wmsSelector={this.state.selector + 'company'}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    label={this.translate('Label_Company_Name')}
                                    extraWrapperClass={this.state.selectedCarrier == 'UPS' ? 'ups-company-name wms-input-names-box' : 'wms-input-names-box'}
                                    value={this.state.connectionObj.Company}
                                />

                                <WmsInput
                                    id='phone'
                                    inputName='Phone'
                                    wmsSelector={this.state.selector + 'phoneNumber'}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    label={this.translate('Label_Warehouse_PhoneNumber')}
                                    extraWrapperClass="wms-input-title-box"
                                    value={this.state.connectionObj.Phone}
                                    valueType="number"
                                />

                                <WmsInput
                                    id='email'
                                    inputName='Email'
                                    wmsSelector={this.state.selector + 'emailAddress'}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    label={this.translate('Label_Email_Address')}
                                    extraWrapperClass="wms-input-address-box"
                                    value={this.state.connectionObj.Email}
                                />

                                <WmsInput
                                    id='address1'
                                    inputName='Address1'
                                    wmsSelector={this.state.selector + 'address1'}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    label={this.translate('Label_Address_1')}
                                    extraWrapperClass="wms-input-address-half-box"
                                    value={this.state.connectionObj.Address1}
                                />
                                <WmsInput
                                    id='address2'
                                    inputName='Address2'
                                    wmsSelector={this.state.selector + 'address2'}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    label={this.translate('Label_Address_2')}
                                    extraWrapperClass="wms-input-address-half-box"
                                    value={this.state.connectionObj.Address2}
                                />

                                <WmsInput
                                    id='city'
                                    inputName='City'
                                    wmsSelector={this.state.selector + 'city'}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    label={this.translate('Label_Warehouse_City')}
                                    extraWrapperClass="wms-input-city-box"
                                    value={this.state.connectionObj.City}
                                />

                                <WmsDropdown
                                    id="state"
                                    name="State"
                                    label={this.translate('Label_Costomer_State_Province')}
                                    wmsSelector={this.state.selector + "state'"}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    disabled={this.state.connectionObj.CountryCode ? false : true}
                                    value={this.state.connectionObj.State}
                                    options={this.state.optionsState}
                                    valueField='RegionCode'
                                    textField='Name'
                                    parentClassName='form_group'
                                    extraWrapperClass="wms-dropdown-state-box"
                                />

                                <WmsInput
                                    id='postalCode'
                                    inputName='PostalCode'
                                    wmsSelector={this.state.selector + 'zip'}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    label={this.translate('Label_Account_Zip_Postal_code')}
                                    extraWrapperClass="wms-input-zip-postal-code-box"
                                    value={this.state.connectionObj.PostalCode}
                                />

                                <WmsDropdown
                                    id="countryCode"
                                    name="CountryCode"
                                    label={this.translate('Label_Country')}
                                    wmsSelector={this.state.selector + "country"}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    value={this.state.connectionObj.CountryCode}
                                    options={this.state.optionsCountry}
                                    valueField='CountryCode'
                                    textField='Name'
                                    parentClassName='form_group'
                                    extraWrapperClass="wms-dropdown-country-box" />


                            </WmsCollapse>

                            <WmsCollapse
                                id={'carrierAccountInformation'}
                                headerLabel={this.translate("Label_Account_Information")}
                                showCollapseText={false}
                                showCollapseIcon={false} >
                                <WmsInput
                                    id='nickname'
                                    inputName='Nickname'
                                    wmsSelector={this.state.selector + 'nickname'}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    label={this.translate('Label_Account_Nick_Name')}
                                    extraWrapperClass="wms-input-city-box"
                                    value={this.state.connectionObj.Nickname} />
                                <WmsInput
                                    id='accountNumber'
                                    inputName='AccountNumber'
                                    wmsSelector={this.state.selector + 'accountNumber'}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    label={this.translate(
                                        (this.state.selectedCarrier == 'UPS') ?
                                            'Label_Warehouse_UpsAccount' :
                                            'Label_Costomer_FedEx_Account_Number'
                                    )}
                                    extraWrapperClass="wms-input-city-box"
                                    value={this.state.connectionObj.AccountNumber} />
                                {this.state.selectedCarrier == 'UPS' && (
                                    <div>
                                        <WmsDropdown
                                            id="accountCountryCode"
                                            name="AccountCountryCode"
                                            label={this.translate('Label_Account_Country')}
                                            wmsSelector={this.state.selector + "accountCountryCode"}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            value={this.state.connectionObj.AccountCountryCode}
                                            options={this.state.optionsCountry}
                                            valueField='CountryCode'
                                            textField='Name'
                                            parentClassName='form_group'
                                            extraWrapperClass="wms-dropdown-account-country-box"
                                        />

                                        <WmsInput
                                            id='accountPostalCode'
                                            inputName='AccountPostalCode'
                                            wmsSelector={this.state.selector + 'accountzip'}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            label={this.translate('Label_Account_Zip')}
                                            value={this.state.connectionObj.AccountPostalCode}
                                            extraWrapperClass="wms-input-account-zip-box"
                                        />
                                    </div>
                                )}
                                {this.state.selectedCarrier != 'FedEx' && (
                                    <WmsCheckBox
                                        id={'invoiceReceivedInPast90Days'}
                                        name={"InvoiceReceivedInPast90Days"}
                                        wmsSelector={this.state.selector + 'chkInvoice'}
                                        onChangeFunc={this.handleConnectionObjChange}
                                        label={this.translate('Label_Invoice')}
                                        checked={this.state.connectionObj.InvoiceReceivedInPast90Days == true}
                                        isRounded={true}
                                        value={this.state.connectionObj.InvoiceReceivedInPast90Days}
                                    />
                                )}
                            </WmsCollapse>

                            {this.state.selectedCarrier == 'FedEx' && (
                                <WmsCollapse
                                    id={'carrierSettings'}
                                    headerLabel={this.translate("Label_Settings")}
                                    showCollapseText={false}
                                    showCollapseIcon={false} >

                                    <div className="dropdown_section">
                                        <WmsDropdown
                                            id="pickupType"
                                            name="PickupType"
                                            label={this.translate('Label_Pickup_Type')}
                                            wmsSelector={this.state.selector + "PickupType"}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            value={this.state.connectionObj.PickupType}
                                            options={this.state.pickupTypeOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />

                                        <WmsDropdown
                                            id="smartPostHub"
                                            name="SmartPostHub"
                                            label={this.translate('Label_Smart_Post_Hub')}
                                            wmsSelector={this.state.selector + "SmartPostHub"}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            value={this.state.connectionObj.SmartPostHub}
                                            options={this.state.smartPostHubOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />
                                        <WmsDropdown
                                            id="smartPostEndorsement"
                                            name="SmartPostEndorsement"
                                            label={this.translate('Label_Smart_Post_Endorsement')}
                                            wmsSelector={this.state.selector + "SmartPostEndorsement"}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            value={this.state.connectionObj.SmartPostEndorsement}
                                            options={this.state.smartPostEndorsementOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />
                                    </div>

                                    <div className="radio_section">
                                        <WmsCheckBox
                                            id={'isPrimaryAccount'}
                                            name={"IsPrimaryAccount"}
                                            wmsSelector={this.state.selector + 'PrimaryAccount'}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            label={this.translate('Label_Primary_Account')}
                                            checked={this.state.connectionObj.IsPrimaryAccount == true}
                                            isRounded={true}
                                        />
                                    </div>
                                </WmsCollapse>
                            )}
                        </div>
                    )}

                    {/* Fourth step */}
                    {this.state.currentStep == 4 && (
                        <div className="connection-step-block">
                            <div className="image-section">
                                <img src={'assets/images/' + this.state.currentCarrierImgUrl} />
                            </div>
                            <WmsCollapse
                                id={'carrierInvoiceInformation'}
                                headerLabel={this.translate("Label_Invoice_Information")}
                                showCollapseText={false}
                                showCollapseIcon={false} >
                                <WmsDateTimePicker
                                    id='invoiceDate'
                                    name='InvoiceDate'
                                    label={this.translate('Label_Invoice_Date')}
                                    onChangeFunc={this.handleConnectionObjChange}
                                    value={this.state.connectionObj.InvoiceDate} />
                                <div className="wms-invoice-block">
                                    <WmsInput
                                        id='invoiceNumber'
                                        inputName='InvoiceNumber'
                                        wmsSelector={this.state.selector + 'invoiceNumber'}
                                        onChangeFunc={this.handleConnectionObjChange}
                                        label={this.translate('Label_Invoice_Number')}
                                        value={this.state.connectionObj.InvoiceNumber}
                                        valueType="alphanumeric" />

                                    <WmsInput
                                        id='controlId'
                                        inputName='ControlId'
                                        wmsSelector={this.state.selector + 'controlId'}
                                        onChangeFunc={this.handleConnectionObjChange}
                                        label={this.translate('Label_Control_Id')}
                                        value={this.state.connectionObj.ControlId} />
                                    <WmsInput
                                        id='invoiceAmount'
                                        inputName='InvoiceAmount'
                                        wmsSelector={this.state.selector + 'invoiceAmount'}
                                        onChangeFunc={this.handleConnectionObjChange}
                                        label={this.translate('Label_Invoice_Total')}
                                        value={this.state.connectionObj.InvoiceAmount}
                                        valueType="number"
                                        regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/} />
                                </div>
                            </WmsCollapse>

                            {this.state.selectedCarrier == 'UPS' && (
                                <WmsCollapse
                                    id={'carrierSettings'}
                                    headerLabel={this.translate("Label_Settings")}
                                    showCollapseText={false}
                                    showCollapseIcon={false} >

                                    <div className="dropdown_section">
                                        <WmsDropdown
                                            id="pickupType"
                                            name="PickupType"
                                            label={this.translate('Label_Pickup_Type')}
                                            wmsSelector={this.state.selector + "PickupType"}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            value={this.state.connectionObj.PickupType}
                                            options={this.state.pickupTypeOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />
                                    </div>

                                    <div className="mail_section">
                                        <WmsDropdown
                                            id="mailInnovationsEndorsement"
                                            name="MailInnovationsEndorsement"
                                            label={this.translate('Label_Mail_Innovations_Endorsement')}
                                            wmsSelector={this.state.selector + "MailInnovationsEndorsement"}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            value={this.state.connectionObj.MailInnovationsEndorsement}
                                            options={this.state.mailInnovationsEndorsementOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />

                                        <WmsInput
                                            id='mailInnovationsCostCenter'
                                            inputName='MailInnovationsCostCenter'
                                            wmsSelector={this.state.selector + 'MailInnovationsCostCenter'}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            label={this.translate('Label_Mail_Innovations_Cost_Center')}
                                            value={this.state.connectionObj.MailInnovationsCostCenter}
                                        />
                                    </div>

                                    <div className="radio_section">
                                        <WmsCheckBox
                                            id={'useOrderNumberOnMailInnovationsLabels'}
                                            name={"UseOrderNumberOnMailInnovationsLabels"}
                                            wmsSelector={this.state.selector + 'UseOrderNumberOnMailInnovationsLabels'}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            label={this.translate('Label_Use_Order_Number_On_Mail_Innovations_Labels')}
                                            checked={this.state.connectionObj.UseOrderNumberOnMailInnovationsLabels == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'useConsolidationServices'}
                                            name={"UseConsolidationServices"}
                                            wmsSelector={this.state.selector + 'UseConsolidationServices'}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            label={this.translate('Label_Use_Consolidation_Services')}
                                            checked={this.state.connectionObj.UseConsolidationServices == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'useNegotiatedRates'}
                                            name={"UseNegotiatedRates"}
                                            wmsSelector={this.state.selector + 'UseNegotiatedRates'}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            label={this.translate('Label_Use_Negotiated_Rates')}
                                            checked={this.state.connectionObj.UseNegotiatedRates == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'useCarbonNeutralShippingProgram'}
                                            name={"UseCarbonNeutralShippingProgram"}
                                            wmsSelector={this.state.selector + 'UseCarbonNeutralShippingProgram'}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            label={this.translate('Label_Use_Carbon_Neutral_Shipping_Program')}
                                            checked={this.state.connectionObj.UseCarbonNeutralShippingProgram == true}
                                            isRounded={true}
                                        />


                                        <WmsCheckBox
                                            id={'useGroundFreightPricing'}
                                            name={"UseGroundFreightPricing"}
                                            wmsSelector={this.state.selector + 'UseGroundFreightPricing'}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            label={this.translate('Label_Use_Ground_Freight_Pricing')}
                                            checked={this.state.connectionObj.UseGroundFreightPricing == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'isPrimaryAccount'}
                                            name={"IsPrimaryAccount"}
                                            wmsSelector={this.state.selector + 'PrimaryAccount'}
                                            onChangeFunc={this.handleConnectionObjChange}
                                            label={this.translate('Label_Primary_Account')}
                                            checked={this.state.connectionObj.IsPrimaryAccount == true}
                                            isRounded={true}
                                        />
                                    </div>
                                </WmsCollapse>
                            )}
                        </div>
                    )}
                </div>

                <div className="footer-btn-holder">

                    <WmsButton
                        label={this.translate('Label_Close')}
                        buttonType='orange'
                        wmsSelector={this.state.selector + 'exitNewConnection'}
                        onClickFunc={this.props.onCloseNewConnectionModel}
                        icon='fa-times-circle-o' />
                    {this.props.sharedModelFlow == 'create' && (
                        <WmsButton
                            label={this.translate('Label_Previous')}
                            wmsSelector={this.state.selector + 'previous'}
                            onClickFunc={this.movePreviousStep}
                            icon='fa-caret-left'
                            disabled={this.state.currentStep == 1} />
                    )}
                    {this.props.sharedModelFlow == 'create' && (
                        <WmsButton
                            label={
                                (this.state.totalSteps == this.state.currentStep) ?
                                    this.translate('Label_Connect') :
                                    this.translate('Label_Next')
                            }
                            wmsSelector={this.state.selector + 'next'}
                            onClickFunc={
                                (this.state.totalSteps == this.state.currentStep) ?
                                    this.registerConnection :
                                    this.moveNextStep
                            }
                            loading={this.state.connectionLoader}
                            disabled={
                                (
                                    this.state.currentStep == 1 && (
                                        !this.state.selectedContentType ||
                                        (
                                            (
                                                this.state.selectedContentType == 'Warehouse Account' &&
                                                !this.state.selectedFacilityIndex
                                            ) || (
                                                this.state.selectedContentType == 'Customer Account' &&
                                                !this.state.selectedCustomerIndex
                                            )
                                        )
                                        ||
                                        !this.state.selectedCarrierIndex
                                    )
                                ) ||
                                (
                                    this.state.currentStep == 2 && (
                                        (
                                            this.state.selectedCarrier == 'FedEx' ||
                                            this.state.selectedCarrier == 'UPS'
                                        ) &&
                                        (typeof this.state.connectionObj.AgreeToEula == 'undefined' || this.state.connectionObj.AgreeToEula == false)
                                    )
                                ) ||
                                (
                                    this.state.currentStep == 2 && (
                                        this.state.selectedCarrier != 'FedEx' &&
                                        this.state.selectedCarrier != 'UPS' &&
                                        this.state.selectedCarrier != 'DHL eCommerce' &&
                                        this.state.selectedCarrier != 'DHL Express' &&
                                        this.state.selectedCarrier != 'Australia Post' &&
                                        this.state.selectedCarrier != 'APC' &&
                                        this.state.selectedCarrier != 'DHL Express Australia' &&
                                        this.state.selectedCarrier != 'Firstmile' &&
                                        this.state.selectedCarrier != 'Globegistics' &&
                                        this.state.selectedCarrier != 'RR Donnelley' &&
                                        this.state.selectedCarrier != 'Canada Post' &&
                                        this.state.selectedCarrier != 'OnTrac' &&
                                        this.state.selectedCarrier != 'SEKO' &&
                                        this.state.selectedCarrier != 'Amazon Buy' &&
                                        this.state.selectedCarrier != 'Canpar' &&
                                        this.state.selectedCarrier != 'Purolator'
                                    ) &&
                                    (
                                        !this.state.connectionObj.AccountNumber ||
                                        !this.state.connectionObj.Passphrase ||
                                        !this.state.connectionObj.Nickname
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'DHL eCommerce' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.ClientId ||
                                        !this.state.connectionObj.Username ||
                                        !this.state.connectionObj.Passphrase ||
                                        !this.state.connectionObj.PickupNumber ||
                                        !this.state.connectionObj.DistributionCenter
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'DHL Express' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.AccountNumber
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'Australia Post' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.AccountNumber ||
                                        !this.state.connectionObj.ApiKey ||
                                        !this.state.connectionObj.ApiSecret
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'APC' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.Username ||
                                        !this.state.connectionObj.Passphrase
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'Canada Post' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.AccountNumber ||
                                        !this.state.connectionObj.ApiKey ||
                                        !this.state.connectionObj.ApiSecret
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'Purolator' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.Passphrase ||
                                        !this.state.connectionObj.ApiKey
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'Firstmile' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.MailerId ||
                                        !this.state.connectionObj.Password
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'SEKO' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.AccessKey
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'DHL Express Australia' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.AccountNumber
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'RR Donnelley' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.Username ||
                                        !this.state.connectionObj.Password
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'OnTrac' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.AccountNumber ||
                                        !this.state.connectionObj.Password
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'Globegistics' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.Username ||
                                        !this.state.connectionObj.Password
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Amazon Buy' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.AccountNumber ||
                                        !this.state.connectionObj.Password ||
                                        !this.state.connectionObj.Email
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Canpar' &&
                                    (
                                        !this.state.connectionObj.Nickname ||
                                        !this.state.connectionObj.AccountNumber ||
                                        !this.state.connectionObj.Username ||
                                        !this.state.connectionObj.Password
                                    )
                                )

                            }
                            icon='fa-caret-right' />

                    )}
                    {(this.props.sharedModelFlow == 'edit' && (this.state.selectedCarrier == 'UPS' || this.state.selectedCarrier == 'FedEx')) && (
                        <WmsButton
                            label={this.translate('Label_Save')}
                            wmsSelector={this.state.selector + 'EditCarriersave'}
                            onClickFunc={this.updateCarrierSettings}
                            icon="fa-floppy-o"
                            loading={this.state.connectionLoader}
                            disabled={
                                (this.state.selectedCarrier == 'UPS' && this.state.connectionObj.UseNegotiatedRates && (
                                    !this.state.connectionObj.InvoiceDate ||
                                    !this.state.connectionObj.InvoiceNumber ||
                                    !this.state.connectionObj.ControlId ||
                                    !this.state.connectionObj.InvoiceAmount
                                ))
                            }
                        />
                    )}

                </div>
            </WmsModel>
        );
    }
}