import React from "react";
import PropTypes from "prop-types";
import style from "./WmsCustomDateTimePicker.scss";

import { StringWms } from '../../../../assets/ts/StringWms.ts';

class WmsCustomDateTimePicker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.windowId = this.props.windowId == "" ? "" : "." + this.props.windowId;
        this.years = _.range(2011,2041);

        let commonOptions = StringWms.getCommonOptionsForDateTimePicker();
        this.defaultDateOption = commonOptions.defaultDateOption;
        this.defaultTimeFormat = commonOptions.timeFormat;
        this.defaultDateTime = moment(this.props.defaultTime, 'HH:mm:ss');

        this.state = {
            extraOptions: commonOptions.extraOptions,
            monthOptions: moment.months(),
            dayOptions: [],
            yearOptions: this.years,
            timeOptions: [],
            filterString: this.props.value,
            isSelectExtraOption: false,
            selectedDateOptions: this.defaultDateOption,
        };
    }

    componentDidMount() {
        if (!this.props.onlyDate) {
            this.setOptionsForTime(this.props.stepsTime);
        }
        this.setDateTimePickerValue(this.props.value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps) != JSON.stringify(this.props)) {
            if (this.props.value != prevProps.value) {
                this.setDateTimePickerValue(this.props.value);
            }
        }
    }

    setDateTimePickerValue = (filterString) => {
        let convertedData = StringWms.convertFilterStringToISODate(filterString, null, this.props.onlyDate,this.props.isDateParseInCustomFormat);

        let dateOptions = convertedData.dateOptions;
        let dayDropdownOptions = this.getOptionsForDayDropdown(dateOptions);
        dateOptions.day = dayDropdownOptions.selectedDay;

        this.setState({
            dayOptions: dayDropdownOptions.options,
            isSelectExtraOption: convertedData.isFromExtraOptions,
            filterString: convertedData.filterString,
            selectedDateOptions: dateOptions
        });
    }

    changeMonth = (event) => {
        let value = event.target.value;

        let dateOptions = Object.assign({}, this.state.selectedDateOptions);
        dateOptions.month = value;
        dateOptions.time = (dateOptions.time) ? dateOptions.time : this.defaultDateTime.format(this.defaultTimeFormat);

        let isSelectExtraOption = this.state.isSelectExtraOption;
        if (_.has(this.state.monthOptions, (value-1))) {
            dateOptions.day = dateOptions.day ? dateOptions.day : this.defaultDateTime.date();
            dateOptions.year = dateOptions.year ? dateOptions.year : this.defaultDateTime.year();
            isSelectExtraOption = false;
        } else {
            dateOptions.day = '';
            dateOptions.year = '';
            isSelectExtraOption = true;
        }

        let dayDropdownOptions = this.getOptionsForDayDropdown(dateOptions);
        dateOptions.day = dayDropdownOptions.selectedDay;

        this.setState({
            dayOptions: dayDropdownOptions.options,
            isSelectExtraOption: isSelectExtraOption,
            selectedDateOptions: dateOptions
        }, () => {
            this.changeDateTimeAndEmit();
        });
    }

    changeDay = (event) => {
        let day = event.target.value;
        this.changeSelectedDateOptions({ day: day});
    }

    changeYear = (event) => {
        let value = event.target.value;

        let dateOptions = Object.assign({}, this.state.selectedDateOptions);

        dateOptions.year = value;
        dateOptions.day = (dateOptions.day) ? dateOptions.day : this.defaultDateTime.date();
        dateOptions.month = (dateOptions.month) ? dateOptions.month : this.defaultDateTime.format('M');
        dateOptions.time = (dateOptions.time) ? dateOptions.time : this.defaultDateTime.format(this.defaultTimeFormat);

        let dayDropdownOptions = this.getOptionsForDayDropdown(dateOptions);
        dateOptions.day = dayDropdownOptions.selectedDay;

        this.setState({
            dayOptions: dayDropdownOptions.options,
            selectedDateOptions: dateOptions
        }, () => {
            this.changeDateTimeAndEmit();
        });
    }

    changeTime = (event) => {
        let time = event.target.value;
        this.changeSelectedDateOptions({ time: time});
    }

    changeSelectedDateOptions = (updateObj) => {
        let selectedOptions = Object.assign({}, this.state.selectedDateOptions, updateObj);

        selectedOptions.time = (selectedOptions.time) ? selectedOptions.time : this.defaultDateTime.format(this.defaultTimeFormat);

        if (!this.state.isSelectExtraOption) {
            selectedOptions.day = (selectedOptions.day) ? selectedOptions.day : this.defaultDateTime.date();
            selectedOptions.month = (selectedOptions.month) ? selectedOptions.month : this.defaultDateTime.format('M');
            selectedOptions.year = (selectedOptions.year) ? selectedOptions.year : this.defaultDateTime.year();
        }

        this.setState({
            selectedDateOptions: selectedOptions,
        }, () => {
            this.changeDateTimeAndEmit();
        });
    }

    changeDateTimeAndEmit = () => {
        let {year, month, day, time} = this.state.selectedDateOptions;
        let filterString = '';

        if (month) {
            let momentDate = moment();

            if (this.state.isSelectExtraOption) {
                let opt = _.find(this.state.extraOptions, { value: month });
                momentDate = momentDate.subtract(opt.number, opt.name);
            } else {
                momentDate = moment([year, (month - 1), day]);
            }

            if (time) {
                let dateTime = moment(time, this.defaultTimeFormat);
                if (dateTime.isValid()) {
                    momentDate.set({
                        hour: dateTime.get('hours'),
                        minute: dateTime.get('minute'),
                        second:0,
                        millisecond:0
                    });
                }
            }
            if(!this.props.isDateParseInCustomFormat){
                momentDate = momentDate.utc();
            }
            if (this.state.isSelectExtraOption) {
                filterString = `${month}~${momentDate.format('HH:mm:ss')}`;
            } else {
                filterString = (this.props.isDateParseInCustomFormat) ?
                momentDate.format('YYYY-MM-DDTHH:mm:ss') :
                momentDate.toISOString();
            }
        }

        this.setState({
            filterString: filterString
        });

        let eventObj = {
            target:{
                name: this.props.name,
                // value_dup: momentDate.toISOString(),
                value: filterString,
                // filterString: filterString
            }
        }
        this.props.onChangeFunc(eventObj);
    }

    getOptionsForDayDropdown = (selectedOptions) => {
        let {year, month, day} = selectedOptions;
        let daysInMonth = 31;

        if (year && month) {
            let momentDate = moment([year, (month - 1)]);
            daysInMonth = momentDate.daysInMonth();
        }

        let dayOptions = _.range(1, (daysInMonth + 1));

        if(!dayOptions.includes(parseInt(day))) {
            day = '';
        }

        return {
            options: dayOptions,
            selectedDay: day
        }
    }

    setOptionsForTime = (step = 60) => {
        let timeArray = [];
        let minute = 0;
        let totMinutes = moment.duration(1, "day").asMinutes();

        while (minute <= totMinutes) {
            let time = moment.utc(moment.duration(minute, "minutes").asMilliseconds()).format(this.defaultTimeFormat);
            timeArray.push(time);
            if (minute == (totMinutes - step)) {
                step--;
            }
            minute += step;
        }
        this.setState({
            timeOptions: timeArray,
        });
    }

    clearDatePicker = () => {
        if (this.props.value) {
            let dateOptions = this.defaultDateOption

            let dayDropdownOptions = this.getOptionsForDayDropdown(dateOptions);
            dateOptions.day = dayDropdownOptions.selectedDay;

            this.setState({
                dayOptions: dayDropdownOptions.options,
                isSelectExtraOption: false,
                selectedDateOptions: dateOptions
            }, () => {
                this.changeDateTimeAndEmit();
            });
        }
    }

    render() {
        return (
            <div
                id={this.props.id}
                className="wms-datetimepicker-component-proto"
                data-wms-selector={this.props.wmsSelector}
            >
                <label>{this.props.label} </label>
                <div
                    className={`border ${(this.props.disabled) ? 'dropdown-disabled' : ''} ${this.props.parentClassName}`}
                >
                    <div className="datePicker_options">
                        <div className={`wms-DatePicker-main`}>
                            <select
                                name="month"
                                onChange={this.changeMonth}
                                value={this.state.selectedDateOptions.month != null ? this.state.selectedDateOptions.month : ''}
                            >
                                {(this.state.selectedDateOptions.month == '') &&
                                    <option value="">{this.props.defaultOptionMonth}</option>
                                }
                                {this.state.extraOptions.map((opt, key) => (
                                    <option value={opt.value} key={key}>{opt.title}</option>
                                ))}

                                <option disabled="disabled">------</option>
                                {/* <option disabled role="separator"></option> */}

                                {this.state.monthOptions.map((month, key) => (
                                    <option value={key + 1} key={key}>{month}</option>
                                ))}
                            </select>
                            <span className="caret"></span>
                        </div>

                        <div className={`wms-DatePicker-main ${(this.state.isSelectExtraOption) ? 'option-disabled' : ''}`}>
                            <select
                                name="date"
                                onChange={this.changeDay}
                                value={this.state.selectedDateOptions.day != null ? this.state.selectedDateOptions.day : ''}
                                disabled={this.state.isSelectExtraOption}
                            >
                                {(this.state.selectedDateOptions.day == '') &&
                                    <option value="">--</option>
                                }
                                {this.state.dayOptions.map((day, key) => (
                                    <option value={day} key={key}>{day}</option>
                                ))}
                            </select>
                            <span className="caret"></span>
                        </div>

                        <div className={`wms-DatePicker-main ${(this.state.isSelectExtraOption) ? 'option-disabled' : ''}`}>
                            <select
                                name="year"
                                onChange={this.changeYear}
                                value={this.state.selectedDateOptions.year != null ? this.state.selectedDateOptions.year : ''}
                                disabled={this.state.isSelectExtraOption}
                            >
                                {(this.state.selectedDateOptions.year == '') &&
                                    <option value="">----</option>
                                }
                                {this.state.yearOptions.map((year, key) => (
                                    <option value={year} key={key}>{year}</option>
                                ))}
                            </select>
                            <span className="caret"></span>
                        </div>

                        {!this.props.onlyDate && (
                            <div className={`wms-DatePicker-main`}>
                                <select
                                    name="time"
                                    onChange={this.changeTime}
                                    value={this.state.selectedDateOptions.time != null ? this.state.selectedDateOptions.time : ''}
                                >
                                    {(this.state.selectedDateOptions.time == '') &&
                                        <option value="">----</option>
                                    }
                                    {this.state.timeOptions.map((time, key) => (
                                        <option value={time} key={key}>{time}</option>
                                    ))}
                                </select>
                                <span className="caret"></span>
                            </div>
                        )}
                    </div>
                    <div className="datePicker_clearer" onClick={this.clearDatePicker}><i className="fa fa-times-circle-o" aria-hidden="true"></i></div>
                </div>
            </div>
        );
    }
}

WmsCustomDateTimePicker.propTypes = {
  id: PropTypes.string.isRequired, // this should write the id to the input
  name: PropTypes.string.isRequired, //this should set the name of datepicker input
  label: PropTypes.string.isRequired, // this should write the label of the menu
  windowId: PropTypes.string, // this should be used to get window id
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]), // this should control the value of datepicker
  defaultTime: PropTypes.string, // this is used for set default time of datetimepicker
  stepsTime: PropTypes.number, // this is used for set steps in times dropdown
  onChangeFunc: PropTypes.func.isRequired, // this sould manage datepicker change and clear select value
  parentClassName: PropTypes.string, //    this should parent tag class default parent class is form-group
  onlyDate: PropTypes.bool, // hide time icon for only show date
  wmsSelector: PropTypes.string, // this should set up a selector on the date
  disabled: PropTypes.bool,  // default will be false
  isDateParseInCustomFormat: PropTypes.bool,  // set true to parse date in custom format not in ISO
  defaultOptionMonth: PropTypes.string // set default first option by default is '----'
};

WmsCustomDateTimePicker.defaultProps = {
  defaultTime: "00:00:00",
  stepsTime: 30,
  parentClassName: "",
  windowId: "",
  onlyDate: false,
  disabled: false,
  wmsSelector: "",
  isDateParseInCustomFormat:false,
  defaultOptionMonth: '-----'
};

export default WmsCustomDateTimePicker;
