import Axios from "axios";

export const GetAuditReviewList: Function = function (auditCountsUris: Array<string>) {
    return Axios.put("/WebUI/Inventory/ManageAudits/AuditReviews", {
        "auditCountsUris": auditCountsUris,
    }).then(function (response) {
        return response;
    });
};

export const GetOtherLocationsForSku: Function = function (sku: any, qualifier: any, facilityUri: any) {
    return Axios.put("/WebUI/Inventory/ManageAudits/OtherLocationsForSku", {
        "sku": sku,
        "qualifier": qualifier,
        "facilityUri": facilityUri,
    }).then(function (response) {
        return response;
    });
};
export const InventoryAuditReconcile: Function = function (param: any) {
    return Axios.post("/WebUI/Inventory/ManageAudits/InventoryAuditReconcile", param).then(function (response) {
        return response;
    });
};

export const GetAuditReviewCSV = function (auditCountsUris) {
    return Axios.put("/WebUI/Inventory/ManageAudits/AuditResultToCSV", { "auditCountsUris": auditCountsUris }).then(
        function (response) {
            return response;
        }
    );
};
