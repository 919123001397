var axios = require('axios');

export const getPackageList = () => {
    // /WebUI/Shipping/PackageDefs/PackageDefsData
    return axios.get('/WebUI/Shipping/PackageMaterials/PackageMaterialsData')
        .then((response) => {
            return response;
        });
}
export const getPackageListRow = ( url ) => {
    return axios.get(url)
        .then( (response) => {
            return response;
        });
}

export const deletePackage = (url) => {
    return axios.delete(url)
        .then((response) => {
            return response;
        });
}

export const createPackage = (url, packageDeta) => {
    return axios.post(url, packageDeta)
        .then((response) => {
            return response;
        });
}

export const updatePackage = (url, packageDeta) => {
    return axios.put(url, packageDeta)
        .then((response) => {
            return response;
        });
}
export const getThreePlOptions = () => {
    return axios.get('/WebUI/Shared/GlobalServices/ThreePlOptions')
        .then((response) => {
            return response;
        });
}
export const saveThreePlOptions = (data) => {
    return axios.put('/WebUI/Shared/GlobalServices/ThreePlOptions', data)
        .then((response) => {
            return response;
        });
}
export const getSkuOptions = (customerId) => {
    return axios.post('/WebUI/items/items/SkuDropDown', {customerId: customerId})
        .then((response) => {
            return response;
        });
}
export const getOrderSourceOptions = (customerId) => {
    return axios.get('/webui/customers/CustomerOrderSource/GetCustomerOrderSources/' + customerId)
        .then((response) => {
            return response;
        });
}