var axios = require('axios');
import * as GlobalService from '../../global_services';
export const getAttachedDocsInfo = function(method, getAttachedDocsInfoUrl, data) {
    return axios({
        method: method ? method : 'POST',
        url: getAttachedDocsInfoUrl,
        data
    })
    .then(function(response) {
        return response;
    });
}


export const attachDocument = function(attachdocumentUrl, fileContents, contentType) {
    return axios.post(attachdocumentUrl, fileContents, {
        "Content-Type": contentType
    }).then(function(response) {
        return response.data;
    });
}

export const removeAttachedDoc = function(removeAttachDocUrl, payloadData) {
    return axios.post(removeAttachDocUrl, payloadData)
        .then(function(response) {
            return response;
        });
}

export const getAttachedDoc = function (getAttachDocUrl, fileUri) {
    return axios.post(getAttachDocUrl, { fileUri: fileUri},{
        responseType: "arraybuffer"
    }).then(function(response) {
        return response;
    });
}