import React from "react";
import { StringWms } from "../../../../assets/ts/StringWms";
import WmsButton from "../../components/WmsButton/WmsButton";
import WmsGrid from "../../components/WmsGrid/WmsGrid";
import WmsModel from "../../components/WmsModel/WmsModel";

import * as GlobalService from './../../global_services';
import axios from 'axios';
import './create-container-manifest-request.scss';

class ReprintContainerManifestModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.translate = window.translate;
        this.$rootScope = window;

        let gridHeaders = StringWms.convertSringToOption(this.translate('ReprintContainerManifest_GridHeaders'));

        this.state = {
            gridResourceList: [],
            printLabelsSaveGif: false,
            selectedPrintedItem: 0,
            gridColumns: [
                {
                    field: "RowNumber",
                    title: "#",
                    hidden: true,
                    menu: false
                },
                {
                    field: "ManifestDate",
                    title: gridHeaders[0].description,
                    width: "100%",
                    wmsid: 1,
                    template: "#= ManifestDate != null ? (moment(ManifestDate).format('MMMM Do, YYYY hh:mm a')) : '' #"
                }, {
                    field: "ShipmentNumber",
                    title: gridHeaders[2].description,
                    width: 150,
                    wmsid: 3
                }, {
                    field: "ContainerId",
                    title: gridHeaders[3].description,
                    width: 150,
                    wmsid: 4
                }
            ],
            gridSchema: {
                data: [],
                schema: {
                    model: {
                        fields: {
                            RowNumber: {
                                type: 'number'
                            },
                            CreatedOn: {
                                type: "string",
                                editable: false
                            },
                            ShipmentNumber: {
                                type: "string",
                                editable: false
                            },
                            ContainerId: {
                                type: "string",
                                editable: false
                            }
                        }
                    }
                }
            },
            isHasSameFormat: false
        };
    }

    componentDidMount = () => {
        this.getGridData();
    }

    listGrid_dataBind = (allData) => {
        let count = 0;
        for (let i = 0; i < allData.length; i++) {
            allData[i].RowNumber = (i+1);
        }
        return allData;
    };

    getGridData = () => {
        kendo.ui.progress($('#ReprintContainerManifestModal'), true);
        axios.get('/WebUI/Shipping/FindOrders/ContainerManifestReprinter')
        .then((response) => {
            kendo.ui.progress($('#ReprintContainerManifestModal'), false);
            let gridResourceList = this.listGrid_dataBind(response.data.ResourceList);
            this.setState({
                gridResourceList: gridResourceList,
                selectedPrintedItem:0
            });
        })
        .catch((error) => {
            kendo.ui.progress($('#ReprintContainerManifestModal'), false);
            console.log(error);
            this.setState({
                gridResourceList: [],
                selectedPrintedItem:0
            });
        });
    }

    selectRow = (KendoGrid) => {
        let currentSelections = KendoGrid.getNumberOfSelections();
        let isHasSameFormat = KendoGrid.valuesIdentical("ContainerFormat");
        this.setState({ selectedPrintedItem:currentSelections, isHasSameFormat: isHasSameFormat });
    }

    onPrintSelected = () => {
        var reportGrid = $('#reprintContainerManifestGrid').data('kendoGrid');
        var rows = reportGrid.select();
        this.setState({
            printLabelsSaveGif: true
        })
        var printData = [];
        rows.each(async (index, row) => {
            var selectedItem = reportGrid.dataItem(row);
            if (selectedItem.ContainerFormat == "ZPL") {
                var res = await axios.get(selectedItem.Url).then(function (response) {
                    return response;
                });
                if(res && res.status == 200) {
                    printData.push(res.data);
                }
            } else {
                var a = $("<a class='temp-pdf'>")
                            .attr("href", selectedItem.Url)
                            .attr("target", "_blank")
                            .attr("download", selectedItem.Url)
                            .appendTo("body");
                        a[0].click();
                        a.remove();
            }

            if (rows.length == (index+1)) {
                if (printData.length) {
                    GlobalService.printDataInZplPrinter(printData);
                }
                this.setState({
                    printLabelsSaveGif: false
                })
            }
        });
    }

    render() {
        return (
            <WmsModel
                id={'ReprintContainerManifestModal'}
                class="ReprintContainerManifestModal"
                title={this.translate("Label_Reprint_Container_Manifest_Request")}
                width={700}
                height={800}
                onCloseFunc={this.props.closeReprintContainerManifestModal}
                isOpen={this.props.isOpenContainerManifestModal}
                customClass='wms-model-new-ui-wrapper'
            >
                <div className="wms-area-content-darker model-content-wrapper wms-area-partial-border">
                    <WmsGrid
                        columns={this.state.gridColumns}
                        schema={this.state.schema}
                        gridHeight={500}
                        gridKeySelection={'RowNumber'}
                        staticGridData={this.state.gridResourceList}
                        virtual={false}
                        showGridSummary={false}
                        showResetButton={false}
                        gridName={"reprintContainerManifestGrid"}
                        id={"reprintContainerManifestGrid"}
                        showGridResult={true}
                        showSortColumns={true}
                        showColumnFilter={false}
                        shawCheckbox={false}
                        wmsSelector={this.state.selector + "reprintContainerManifestGrid"}
                        onSelection={this.selectRow}
                        onSelectAllChange={() => null}
                        clickEventOfContextMenu={() => { }}
                        emptyRecoredText={this.translate('Window_Title_Label_Parcel_Ship_No_Recoreds')}
                        menu={[]}
                    />
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Close')}
                        wmsSelector={"cancelContainerManifest"}
                        onClickFunc={this.props.closeReprintContainerManifestModal}
                        disabled={false}
                        loading={false}
                        buttonType='orange'
                        icon={'fa-ban'}
                    />
                    <WmsButton
                        label={this.translate('Label_Reprint_EOD_Print_Selected')}
                        wmsSelector={"generateContainerManifest"}
                        onClickFunc={this.onPrintSelected}
                        disabled={!this.state.selectedPrintedItem >= 1 || !this.state.isHasSameFormat}
                        tooltipText={this.state.selectedPrintedItem == 0 ? this.translate("Select_Reprint_Container_Manifest_Tooltip") : "Please choose same format types only"}
                        showToolTip={!this.state.selectedPrintedItem >= 1 || !this.state.isHasSameFormat}
                        loading={this.state.printLabelsSaveGif}
                        icon={'fa-print'}
                    />
                </div>
            </WmsModel>
        );
    }
}

export default ReprintContainerManifestModal;
