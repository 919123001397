import React, { Component } from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsDropdown from "../../../components/WmsDropdown/WmsDropdown.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";
import WmsCheckBox from '../../../components/WmsCheckBox/WmsCheckBox.jsx';

export class PitneyBowesStandardInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.translate = window.translate;

        this.nonDeliveryOptions = [
            {
                Name: "Address Service Requested",
                Value: "AddressServiceRequested",
            },
            {
                Name: "Address Service Requested BPRS",
                Value: "AddressServiceRequestedBPRS",
            },
            {
                Name: "Return Service Requested",
                Value: "ReturnServiceRequested",
            },
            {
                Name: "Return Service Requested BPRS",
                Value: "ReturnServiceRequestedBPRS",
            },
            {
                Name: "Change Service Requested",
                Value: "ChangeServiceRequested",
            },
            {
                Name: "Forwarding Service Requested",
                Value: "ForwardingServiceRequested",
            },
            {
                Name: "Electronic Service Requested",
                Value: "ElectronicServiceRequested",
            },
        ];
    }


    render() {
        return (
            <div>
                <WmsCollapse
                    id={"carrierAccountInformation"}
                    headerLabel={this.translate("Label_Account_Information")}
                    showCollapseText={false}
                    showCollapseIcon={false}
                >
                    <div className="account-info-section pitney-account-info-section">
                        <WmsInput
                            id="nickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Nick_Name")}
                            value={this.props.currentCarrierObj.Nickname}
                            required={true}
                        />
                        <WmsInput
                            id="accountNumber"
                            inputName="accountNumber"
                            wmsSelector={this.props.selector + "accountNumber"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Client_Id")}
                            value={this.props.currentCarrierObj.accountNumber}
                            required={true}
                        />
                        <WmsInput
                            id="carrierFacilityId"
                            inputName="carrierFacilityId"
                            wmsSelector={this.props.selector + "carrierFacilityId"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Lable_Carrier_Facility_Id")}
                            value={this.props.currentCarrierObj.carrierFacilityId}
                            required={true}
                        />
                        <WmsInput
                            id="clientFacilityId"
                            inputName="clientFacilityId"
                            wmsSelector={this.props.selector + "clientFacilityId"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Lable_Client_Facility_Id")}
                            value={this.props.currentCarrierObj.clientFacilityId}
                            required={true}
                        />
                        <div>
                            <WmsCheckBox
                                id={'minimalAddressValidation'}
                                name={"MinimalAddressValidation"}
                                wmsSelector={this.props.selector + 'MinimalAddressValidation'}
                                onChangeFunc={this.props.handleCarrierObjChange}
                                label={this.translate('Label_Use_Minimal_Address_Validation')}
                                checked={this.props.currentCarrierObj.MinimalAddressValidation == true}
                                isRounded={true}
                            />
                        </div>
                    </div>
                </WmsCollapse>

                <WmsCollapse
                    id={"carrierAccountSettingInformation"}
                    headerLabel={this.translate("Label_Settings_Text")}
                    showCollapseText={false}
                    showCollapseIcon={false}
                >
                    <div className="account-info-section pitney-account-info-section">
                        <WmsDropdown
                            id="nonDeliveryDefault"
                            name="nonDeliveryDefault"
                            label={this.translate("Lable_Non_Delivery_Option")}
                            wmsSelector={this.props.selector + "nonDeliveryDefault"}
                            value={this.props.currentCarrierObj.nonDeliveryDefault}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            options={this.nonDeliveryOptions}
                            required={true}
                            textField="Name"
                            valueField="Value"
                            blankFirstOption={true}
                            blankFirstOptionText="Unspecified"
                        />
                        <WmsInput
                            id="returnClientId"
                            inputName="returnClientId"
                            wmsSelector={this.props.selector + "returnClientId"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Pitney_Bowes_Returns_Id")}
                            value={this.props.currentCarrierObj.returnClientId}
                        />
                        <WmsInput
                            id="carrierGatewayFacilityId"
                            inputName="carrierGatewayFacilityId"
                            wmsSelector={this.props.selector + "carrierGatewayFacilityId"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Lable_Carrier_Gateway_Facility_Id")}
                            value={this.props.currentCarrierObj.carrierGatewayFacilityId}
                        />
                        <WmsInput
                            id="dispositionRulesetId"
                            inputName="dispositionRulesetId"
                            wmsSelector={this.props.selector + "dispositionRulesetId"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Lable_Disposition_Request_Id")}
                            value={this.props.currentCarrierObj.dispositionRulesetId}
                        />
                    </div>
                </WmsCollapse>
            </div>
        );
    }
}
