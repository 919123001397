import React, { useEffect, useState } from "react";
import { default as WmsModal } from "../../../components/WmsModel/WmsModel.jsx";
import WmsButton from "../../../components/WmsButton/WmsButton.jsx";
import * as FindOrdersService from "../../../../react/orders/findOrders/findOrders.services";
import CsvDownloader from "react-csv-downloader";
import { SmallParcelHolder } from "../../../smallParcel/smallParcel-holder.component";

interface HVPBatchErrorModalProps {
    windowId: string;
    modalTitle: string;
    batchErrorsUrl: string;
    batchShipmentsUrl: string;
    open: boolean;
    onClose: () => void;
}

export default function HVPBatchErrorModal(props: HVPBatchErrorModalProps) {
    /**
     * Global variables
     */
    const translate = (window as any).translate as any; //used to translate texts;

    /**
     * Constants
     */
    const wmsSelector = "SmallParcelHVPBatchError";
    const HVPBatchErrorModalId = "HVPBatchErrorModal";

    /**
     * Export Columns
     */
    const exportColumns = [
        {
            id: "OrderId",
            displayName: "Transaction ID",
        },
        {
            id: "Error",
            displayName: "Error",
        },
    ];

    const [HVPBatchErrorData, setHVPBatchErrorData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    /**
     * useEffects
     */
    useEffect(() => {
        kendo.ui.progress($("#" + HVPBatchErrorModalId), true);
        FindOrdersService.getSmallParcelBatchErrors(props.batchErrorsUrl, props.batchShipmentsUrl)
            .then((response) => {
                if (response && response.status === 200) {
                    setHVPBatchErrorData(response.data.ParcelBatchErrors);
                }
            })
            .finally(() => {
                kendo.ui.progress($("#" + HVPBatchErrorModalId), false);
                setIsLoading(false);
            });
    }, [props.batchErrorsUrl, props.batchShipmentsUrl]);

    const resolveHVPBatchError = (orderId: any) => {
        props.onClose();
        // For Active Small Parcel Tab and Open Pack And Ship Modal
        (window as any).OpenWindow({
            url: "<SmallParcelHolder></SmallParcelHolder>",
            reactComponent: SmallParcelHolder,
            singleton: true,
            title: "Window_Title_Label_Small_Parcel",
            active: true,
            useLineItem: false,
            icon: "fas fa-box-open",
            urlParam: "small-parcel",
            sharedSelectOrder: orderId,
            sendToTab: "SmallParcel-openPackAndShipModal",
        });
    };

    return (
        <WmsModal
            isOpen={props.open}
            id={HVPBatchErrorModalId}
            title={props.modalTitle}
            width={1000}
            height={400}
            onCloseFunc={props.onClose}
            class="print-batched-shipping-model-wrapper"
            customClass="wms-model-new-ui-wrapper model-height-fixed"
        >
            {!isLoading && (
                <>
                    <div className="model-content-wrapper">
                        {HVPBatchErrorData.map((eData: any, index: number) => (
                            <div className="hvp-batch-row" key={"HVPBatchErrorID_" + index}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>{translate("Orders_Lbl_Transaction_Id")}</th>
                                            <td>{eData.OrderId}</td>
                                        </tr>
                                        <tr>
                                            <th>{translate("Label_Error")}</th>
                                            <td>{eData.Error}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <WmsButton
                                    id={wmsSelector + "resolveHVPBatchError" + index}
                                    name={wmsSelector + "resolveHVPBatchError" + index}
                                    label={translate("Label_Resolve_Error")}
                                    wmsSelector={wmsSelector + "resolveHVPBatchError" + index}
                                    onClickFunc={() => resolveHVPBatchError(eData.OrderId)}
                                    buttonType="green"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="footer-btn-holder">
                        <CsvDownloader datas={HVPBatchErrorData} filename="HVPBatchErrors" columns={exportColumns}>
                            <WmsButton
                                id={wmsSelector + "exportHVPBatchError"}
                                name={wmsSelector + "exportHVPBatchError"}
                                label={translate("Label_Export")}
                                wmsSelector={wmsSelector + "exportHVPBatchError"}
                                onClickFunc={() => false}
                                icon="fa-download"
                                buttonType="green"
                                disabled={!HVPBatchErrorData.length}
                            />
                        </CsvDownloader>
                        <WmsButton
                            id={wmsSelector + "close"}
                            name={wmsSelector + "close"}
                            label={translate("Label_Close")}
                            wmsSelector={wmsSelector + "close"}
                            onClickFunc={props.onClose}
                            icon="fa-times-circle-o"
                            buttonType="orange"
                        />
                    </div>
                </>
            )}
        </WmsModal>
    );
}
