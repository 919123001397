import axios from "axios";
import React from 'react';

import WmsFormGrid from './../../components/WmsFormGrid/WmsFormGrid.jsx';


export class ManageReceiptLineItemDetailGrid extends React.Component {

    constructor(props) {
        super(props);
        
        this.translate = window.translate;
    }



    render() {
        return (
            <div>
                {this.translate('Label_Line_Item_Custom_Fields')}
                <WmsFormGrid
                    windowId={this.props.windowId}
                    parentClassName={'receipt-line-item-detail'}
                    isColumnsReorderable={true}
                    isColumnsFilterable={true}
                    isColumnsSortable={true}
                    isColumnMenuenable={true}
                    columns={[{
                        field: "Name",
                        title: "Name",
                        lockable: false,
                        width: 160
                    },{
                        field: "Value",
                        title: "Value",
                        lockable: false,
                        width: 160
                    }]}
                    gridKeySelection={''}
                    schema={{
                        model: {
                            fields: {
                                Name: {
                                    type: "string",
                                },
                                Value: {
                                    type: "string",
                                }

                            }
                        }
                    }}
                    staticGridData={this.props.dataItem.SavedElements}
                    id={this.props.gridId}
                    wmsSelector={"GridReceiptLineItemsDetail"}
                />
            </div>
        )
    }
}