import React from 'react';

//component
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsUpdateDropdownList from '../../components/WmsUpdateDropdownList/WmsUpdateDropdownList.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsModel from '../../components/WmsModel/WmsModel.jsx';
import WmsRadio from '../../components/WmsRadio/WmsRadio.tsx';
import WmsCheckBox from '../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDropdownList from "../../components/WmsDropdownList/WmsDropdownList.jsx";
import WmsDropdown from "../../components/WmsDropdown/WmsDropdown";
import WmsDateTimePicker from "../../components/WmsDateTimePicker/WmsDateTimePicker";

import * as GlobalService from '../../global_services';
import * as packagingTypeMetaDataService from './packaging-type-meta-data.service';
import * as packagingTypeService from './packaging-type.services.jsx'

export class PackageDefModal extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.customLabels = GlobalService.getCustomLabels();
        this.metaDataPackagingDef = packagingTypeMetaDataService.getPackagingTypesPackageDefModelMetaData(),
            this.selector = "PackageDefModal";
            this.ModelId = "PackageDefModal";
        this.optionsCustomer = GlobalService.getCustomerList(false);
        let countries = [...GlobalService.GetCountries()];
        this.Rights = GlobalService.GetRights();

        countries.shift();
        this.state = {
            packageTypeFormData: null,
            PackingItemType: "Packaging",
            AssignedCustomers: [],
            selectedCustomer: null,
            IsInsertHasInventory: false,
            SelectedFacilities: [{
                FacilityIdentifier: {
                    Id: 0,
                    Name: "",

                },
                CurrentQuantity: "",
                MinQuantity: "",
                UserIdentifier: {
                    Id: this.props.GlobalRecipient,
                    Name: "",
                },
                isNew: true,
            }],
            gifSaveChangesAndExit: false,
            isUnitsOfMeasure: (this.metaDataPackagingDef != "" && typeof this.metaDataPackagingDef.isUnitsOfMeasure) ? this.metaDataPackagingDef.isUnitsOfMeasure : false,
            facilityOPtions: [],
            optionsCountry: countries,
            PackageInsertItemIds: [],
            SkuOptions: [],
            isSkuOptionsUpdating: false,
            OptionsState: [],
            OrderSourceOptions: [
                { name: "option 1"},
                { name: "option 2"},
                { name: "option 3"},
                { name: "option 4"},
            ],
            isLoading: false,

        };
    }

    componentDidMount() {
        if (this.props.isNewPackage == false) {
            kendo.ui.progress($("#" + this.ModelId), true);
            this.setState({isLoading: true});
            var url = "/WebUI/Shipping/PackageDefs/PackageDef/" + this.props.sharedSelectedPkgType.Id;
            if (this.props.sharedSelectedPkgType.MaterialType) {
                url = "/WebUI/Shipping/PackageInserts/PackageInsert/" + this.props.sharedSelectedPkgType.Id;
            }
            packagingTypeService.getPackageListRow(url)
                .then((res) => {
                    if (this.props.sharedSelectedPkgType.MaterialType) {
                        var selectedCustomer = this.optionsCustomer.find( el => el.id == res.data.CustomerIdentifier.Id);
                        res.data.LifespanStartDate  = res.data.LifespanStartDate;
                        res.data.LifespanEndDate  = res.data.LifespanEndDate;
                        this.setState({
                            PackingItemType: "Insert",
                            packageTypeFormData: res.data,
                            selectedCustomer: selectedCustomer,
                            SelectedFacilities: res.data.PackageInsertFacilities && res.data.PackageInsertFacilities.length ? res.data.PackageInsertFacilities: [{
                                FacilityIdentifier: {
                                    Id: 0,
                                    Name: "",

                                },
                                CurrentQuantity: "",
                                MinQuantity: "",
                                UserIdentifier: {
                                    Id: this.props.GlobalRecipient,
                                    Name: "",
                                },
                                isNew: true,

                            }],
                            PackageInsertItemIds: res.data.PackageInsertItems.map(el => el.Id),
                            IsInsertHasInventory:  res.data.ItemIdentifier != null,
                            isLoading: false,
                        });

                        var customerFacilityIds = [];
                        if(selectedCustomer && selectedCustomer.facilities) {
                            customerFacilityIds = selectedCustomer.facilities;
                        }
                        this.changeFacilityOptions(customerFacilityIds);
                        this.getOrderSourceOptions(res.data.CustomerIdentifier.Id);
                        this.updateSkuOption(res.data.CustomerIdentifier.Id);
                        this.setStatesAccordingToCountry(res.data.Country);
                    } else {
                        this.setState({
                            PackingItemType: "Packaging",
                            packageTypeFormData: res.data,
                            // AssignedCustomers: res.data.AssignedCustomers,
                            SelectedFacilities: res.data.PackageDefFacilities && res.data.PackageDefFacilities.length ? res.data.PackageDefFacilities : [{
                                FacilityIdentifier: {
                                    Id: 0,
                                    Name: "",

                                },
                                CurrentQuantity: "",
                                MinQuantity: "",
                                UserIdentifier: {
                                    Id: this.props.GlobalRecipient,
                                    Name: "",
                                },
                                isNew: true,
                            }],
                            isLoading: false,
                        });
                        this.handleUserCustomerChange(res.data.AssignedCustomers);

                    }
                    kendo.ui.progress($("#" + this.ModelId), false);


                });
        }
        else {
           this.changeFacilityOptions([]);
        }
    }
    setStatesAccordingToCountry = (param_country) => {
        this.setState({ OptionsState: GlobalService.GetRegions(param_country) });
    }
    changeTriggerItem = (event) => {
        if (event.dataItem !== undefined && event.dataItem.Id !== "") {
                let updatedEvent = {
                    target: {
                        name: "PackageInsertItems",
                        value: {
                            Id: event.dataItem.Id,
                            Sku: event.dataItem.Sku
                        }
                    }
                }
            this.handleChangeEvent(updatedEvent);
        } else {
            // need to do if got an issue on rerendering.
        }
    }
    onChangeInventoryItem = (e) => {
        this.handleChangeEvent({target: {name: "ItemIdentifier", value: {
            Id: e.target.value.Id,
            Sku: e.target.value.Sku
        }}});
    }

    handleChangeEvent = (event) => {
        let packageTypeFormObj = Object.assign({}, this.state.packageTypeFormData);
        let name = event.target.name;

        packageTypeFormObj[name] = event.target.value;

        this.setState({
            packageTypeFormData: packageTypeFormObj
        });
        if (event.target.name == "Country"){
            this.setStatesAccordingToCountry(event.target.value);
        }
    }
    handleChangeCheckbox = (event) => {
        let updatedEvent = {
            target: {
                name: event.target.name,
                value: event.target.checked
            }
        }

        this.handleChangeEvent(updatedEvent);
    }
    handleChangeIsInsertHasInventory = (event) => {
        let packageTypeFormObj = Object.assign({}, this.state.packageTypeFormData);
        packageTypeFormObj.ItemIdentifier = null;
        this.setState({
            IsInsertHasInventory: event.target.checked,
            SelectedFacilities: [{
                FacilityIdentifier: {
                    Id: 0,
                    Name: "",

                },
                CurrentQuantity: "",
                MinQuantity: "",
                UserIdentifier: {
                    Id: this.props.GlobalRecipient,
                    Name: "",
                },
                isNew: true,

            }],
            packageTypeFormData: packageTypeFormObj
        });
    }
    handleChangePakingType = (value) => {
        this.setState({
            PackingItemType: value,
            packageTypeFormData: null,
            SelectedFacilities: [{
                FacilityIdentifier: {
                    Id: 0,
                    Name: "",

                },
                CurrentQuantity: "",
                MinQuantity: "",
                UserIdentifier: {
                    Id: this.props.GlobalRecipient,
                    Name: "",
                },
                isNew: true,

            }],
            PackageInsertItemIds: [],
            AssignedCustomers: [],
            facilityOPtions: [],
        });

        this.changeFacilityOptions([], value);
    }

    handleUserCustomerChange = (valueArr) => {
        if (!valueArr) {
            valueArr = [];
        }
        this.setState({AssignedCustomers: valueArr});
        var customerFacilityIds = [];
        valueArr.forEach(cu => {
            var selectedCustomer = this.optionsCustomer.find( el => el.id == cu);
            if(selectedCustomer && selectedCustomer.facilities){
                customerFacilityIds = customerFacilityIds.concat(selectedCustomer.facilities);
            }
        });
        this.changeFacilityOptions(customerFacilityIds);
    }
    handlePackageInsertItemChange = (valueArr) => {
        let packageTypeFormObj = Object.assign({}, this.state.packageTypeFormData);
        if (!valueArr || valueArr.length == 0) {
            packageTypeFormObj.InsertQtyMatchesItemQty = false;
            valueArr = [];
        }else if(valueArr.length > 1) {
            packageTypeFormObj.InsertQtyMatchesItemQty = false;
        }
        this.setState({
            PackageInsertItemIds: valueArr,
            packageTypeFormData: packageTypeFormObj
        });
    }
    handleCustomerChange = (event) => {
        var selectedCustomer = this.optionsCustomer.find( el => el.id == event.target.value);
        if (selectedCustomer) {
            this.setState({selectedCustomer: selectedCustomer});
            var customerFacilityIds = selectedCustomer.facilities;
            this.changeFacilityOptions(customerFacilityIds);
            this.updateSkuOption(event.target.value);
            this.getOrderSourceOptions(event.target.value);
        } else {
            this.setState({
                SkuOptions: [],
                facilityOPtions: [],
                OrderSourceOptions: [],
                selectedCustomer: null,
                SelectedFacilities: [{
                    FacilityIdentifier: {
                        Id: 0,
                        Name: "",

                    },
                    CurrentQuantity: "",
                    MinQuantity: "",
                    UserIdentifier: {
                        Id: this.props.GlobalRecipient,
                        Name: "",
                    },
                    isNew: true,

                }],
            });
        }
    }
    updateSkuOption = (customerId) => {
        this.setState({isSkuOptionsUpdating: true});
        packagingTypeService.getSkuOptions(customerId).then((response) => {
            this.setState({SkuOptions: response.data.Data});
        }).finally(() => {
            this.setState({isSkuOptionsUpdating: false});
        });
    }
    getOrderSourceOptions = (customerId) => {
        packagingTypeService.getOrderSourceOptions(customerId).then((response) => {
            this.setState({OrderSourceOptions: response.data.Items});
        });
    }
    changeFacilityOptions = (customerFacilityIds, packingType = null) => {
        let packType = packingType || this.state.PackingItemType;
        let allFacilities = GlobalService.getFacilityList(false);

        if(packType === 'Packaging') {
            if(customerFacilityIds.length === 0)
            {
                this.setState({ facilityOPtions: allFacilities });
                return;
            }
        }

        allFacilities = allFacilities.filter((f) => customerFacilityIds.includes(f.id));
        var tempSelectedFacilities = this.state.SelectedFacilities.filter(
            o => customerFacilityIds.includes(o.FacilityIdentifier.Id)
        );
        if(tempSelectedFacilities.length === 0){
            tempSelectedFacilities = [{
                FacilityIdentifier: {
                    Id: 0,
                    Name: "",

                },
                CurrentQuantity: "",
                MinQuantity: "",
                UserIdentifier: {
                    Id: this.props.GlobalRecipient,
                    Name: "",
                },
                isNew: true,

            }];
        }

        this.setState({
            facilityOPtions: allFacilities,
            SelectedFacilities: tempSelectedFacilities,
        });
    }

    handleChangeUnitsOfMeasure = () => {
        let metaDataPackagingDef = Object.assign({}, this.metaDataPackagingDef, { isUnitsOfMeasure: !this.state.isUnitsOfMeasure })
        packagingTypeMetaDataService.setPackagingTypesPackageDefModelMetaData(metaDataPackagingDef)

        this.setState({
            isUnitsOfMeasure: !this.state.isUnitsOfMeasure,
        })
    }

    savePackageType = () => {
        if (this.props.isNewPackage) {
            this.createNewPackageType();
        } else {
            this.updatePackageType();
        }
    }

    getFormData(isNewCreate) {
        var returnObj = {};
        if (this.state.PackingItemType == "Packaging") {
            returnObj = {
                pkgDefData: {
                    Name: this.state.packageTypeFormData.Name.trim(),
                    Description: this.state.packageTypeFormData.Description?.trim(),
                    Barcode: this.state.packageTypeFormData.Barcode.trim(),
                    AssignedCustomers: this.state.AssignedCustomers,
                    IsDefault: this.state.packageTypeFormData.IsDefault,
                    LengthImperial: this.state.packageTypeFormData.LengthImperial,
                    WidthImperial: this.state.packageTypeFormData.WidthImperial,
                    HeightImperial: this.state.packageTypeFormData.HeightImperial,
                    WeightImperial: this.state.packageTypeFormData.WeightImperial,
                    // if Imperial is selected, what value for following.
                    LengthMetric: this.state.packageTypeFormData.LengthMetric,
                    WidthMetric: this.state.packageTypeFormData.WidthMetric,
                    HeightMetric: this.state.packageTypeFormData.HeightMetric,
                    WeightMetric: this.state.packageTypeFormData.WeightMetric,
                    Cost: this.state.packageTypeFormData.Cost,
                    DefaultMarkupPercent: this.state.packageTypeFormData.DefaultMarkupPercent,
                    DefaultMarkupFlat: this.state.packageTypeFormData.DefaultMarkupFlat,
                    PackageDefFacilities: this.state.SelectedFacilities[0].FacilityIdentifier.Id != 0 ? this.state.SelectedFacilities : undefined,
                }
            };
            if(!isNewCreate){
                returnObj.uri = this.state.packageTypeFormData.PackageDefUri;
                // returnObj.pkgDefData.PackageDefId = this.state.packageTypeFormData.PackageDefId;
            }
        } else {
            var tempPackageInsertItems = [];
            this.state.PackageInsertItemIds.forEach( skuId => {
                var tempSku = this.state.SkuOptions.find(el => el.ItemId == skuId);
                tempPackageInsertItems.push({
                    id: tempSku.ItemId,
                    Sku: tempSku.Sku,
                });
            });
            returnObj = {
                pkgInsertData: {
                    CustomerIdentifier: {
                        Id: this.state.selectedCustomer.id,
                        Name: this.state.selectedCustomer.name,
                    },
                    ItemIdentifier: this.state.packageTypeFormData.ItemIdentifier,
                    Name: this.state.packageTypeFormData.Name.trim(),
                    Description: this.state.packageTypeFormData.Description?.trim(),
                    Barcode: this.state.packageTypeFormData.Barcode.trim(),
                    InsertQtyMatchesItemQty: this.state.packageTypeFormData.InsertQtyMatchesItemQty,
                    LifespanStartDate: this.state.packageTypeFormData.LifespanStartDate? kendo.toString(kendo.parseDate(this.state.packageTypeFormData.LifespanStartDate), "yyyy-MM-dd") : null, //"YYYY-MM-DDTHH:MM:SSZ",
                    LifespanEndDate: this.state.packageTypeFormData.LifespanEndDate? kendo.toString(kendo.parseDate(this.state.packageTypeFormData.LifespanEndDate), "yyyy-MM-dd") : null, //"YYYY-MM-DDTHH:MM:SSZ",
                    Country: this.state.packageTypeFormData.Country,
                    State: this.state.packageTypeFormData.State,
                    OrderSource: this.state.packageTypeFormData.OrderSource,
                    IgnoreForEdi: this.state.packageTypeFormData.IgnoreForEdi,
                    // "DisplayOnPackingList": true,
                    PackageInsertFacilities: this.state.SelectedFacilities[0].FacilityIdentifier.Id != 0 ? this.state.SelectedFacilities : undefined,
                    PackageInsertItems: tempPackageInsertItems,
                }
            };
            if(!isNewCreate){
                returnObj.uri = this.state.packageTypeFormData.PackageInsertUri;
                // returnObj.pkgInsertData.PackageInsertId = this.state.packageTypeFormData.PackageInsertId;
            }
        }
        return returnObj;
    }
    isAllFacilityValid = () => {
        return this.state.SelectedFacilities.length == 0 ||
            (this.state.SelectedFacilities.length > 0 &&
            this.state.SelectedFacilities.find(el =>
                el.FacilityIdentifier.Id != 0
                && (el.UserIdentifier.Id == 0
                    || el.CurrentQuantity == "" || el.CurrentQuantity <= 0
                    || el.MinQuantity == "" || el.MinQuantity <= 0)
            ) == undefined);
    }
    isInvalidFormData = () => {
        if( !this.isAllFacilityValid()){
            return true;
        }
        if (this.state.PackingItemType == "Packaging") {
            return this.state.packageTypeFormData == null ||
                this.state.packageTypeFormData.Name == undefined || this.state.packageTypeFormData.Name.trim() == "" ||
                this.state.packageTypeFormData.Barcode == undefined ||this.state.packageTypeFormData.Barcode.trim() == "";

        } else {
            if (this.state.packageTypeFormData != null) {
            }
            return this.state.packageTypeFormData == null ||
                this.state.packageTypeFormData.Name == undefined || this.state.packageTypeFormData.Name.trim() == "" ||
                this.state.packageTypeFormData.Barcode == undefined || this.state.packageTypeFormData.Barcode.trim() == "" ||
                (this.state.IsInsertHasInventory && !(this.state.packageTypeFormData.ItemIdentifier?.Id));
        }
    }
    createNewPackageType = () => {
        if(this.isInvalidFormData()){
            return false;
        }
        let paramObj = this.getFormData(true);

        this.setState({ gifSaveChangesAndExit: true });
        var url = '/WebUI/Shipping/PackageDefs/PackageDef';
        var successText = "Label_Package_Create_Success";
        if(this.state.PackingItemType == "Insert"){
            url = '/WebUI/Shipping/PackageInserts/PackageInsert';
            successText = "Label_Insert_Create_Success";
        }
        packagingTypeService.createPackage(url, paramObj).then((response) => {
            GlobalService.notification('success', this.translate(successText));
            this.props.onSavePackageDef();
            setTimeout(() => {
                this.props.onClosePackageDefModal();
            }, 100);
        }).finally(() => {
            this.setState({ gifSaveChangesAndExit: false });
        });
    }

    updatePackageType = () => {
        let paramObj = this.getFormData(false);

        // let paramObj = {
        //     uri: this.props.sharedSelectedPkgType.PackageDefUri,
        //     pkgDefData: this.getFormData(false)
        // }
        this.setState({ gifSaveChangesAndExit: true });
        var url = '/WebUI/Shipping/PackageDefs/PackageDef';
        var successText = "Label_Package_Update_Success";
        if(this.state.PackingItemType == "Insert"){
            url = '/WebUI/Shipping/PackageInserts/PackageInsert';
            successText = "Label_Insert_Update_Success";
        }
        packagingTypeService.updatePackage(url, paramObj).then((response) => {
            GlobalService.notification('success', this.translate(successText));
            this.props.onSavePackageDef();
            setTimeout(() => {
                this.props.onClosePackageDefModal();
            }, 100);
        }).finally(() => {
            this.setState({ gifSaveChangesAndExit: false });
        });
    }
    removeSelectedWarehouse= (index) => {
        let tempArr = [...this.state.SelectedFacilities];
        tempArr.splice(index, 1);

        this.setState({SelectedFacilities: tempArr});
    }
    addAnotherWarehouse = () => {
        let tempArr = [...this.state.SelectedFacilities];
        tempArr.push({
            FacilityIdentifier: {
                Id: 0,
                Name: "",

            },
            CurrentQuantity: "",
            MinQuantity: "",
            UserIdentifier: {
                Id: this.props.GlobalRecipient,
                Name: "",
            },
            isNew: true,

        });
        this.setState({SelectedFacilities: tempArr});
    }
    handleWarehouseChange = (event, i) => {
        let tempArr = [...this.state.SelectedFacilities];
        var selectedWarehouse = this.state.facilityOPtions.find(el => el.id == event.target.value);
        if (selectedWarehouse) {
            tempArr[i].FacilityIdentifier = {
                Id: selectedWarehouse.id,
                Name: selectedWarehouse.name
            };
        } else {
            tempArr[i].FacilityIdentifier = {
                Id: 0,
                Name: "",
            };
            tempArr[i].UserIdentifier = {
                Id: this.props.GlobalRecipient,
                Name: "",
            }
        }
        this.setState({SelectedFacilities: tempArr});
    }
    handleEmailChange = (event, i) => {
        let tempArr = [...this.state.SelectedFacilities];
        tempArr[i].UserIdentifier = {
            Id: event.dataItem.UserId,
            Name: event.dataItem.Name,
        };
        this.setState({SelectedFacilities: tempArr});

    }
    handleChangeSelectFacilityInput = (event, i) => {
        let tempArr = [...this.state.SelectedFacilities];
        tempArr[i][event.target.name] = event.target.value;
        this.setState({SelectedFacilities: tempArr});
    }
    getActiveFacilityOptions = (selectedFacilityObj) => {
        if(this.isUserHaveFacilityAccess(selectedFacilityObj.Id)){
            return this.filterSelectedFacilities(selectedFacilityObj.Id);
        } else {
            return [{
                name: selectedFacilityObj.Name,
                id: selectedFacilityObj.Id
            }]
        }
    }
    isUserHaveFacilityAccess = (facilityId) => {
        let allFacilities = GlobalService.getFacilityList(false);
        return facilityId == 0 || allFacilities.find(el => el.id == facilityId) != undefined;
    }
    filterSelectedFacilities = (selectedFacilityId) => {
        const selectedFacility = this.state.SelectedFacilities.map(el => el.FacilityIdentifier.Id);
        return this.state.facilityOPtions.filter( (op) => op.id == selectedFacilityId || !selectedFacility.includes(op.id));
    }

    render() {
        const renderPkgType = (this.state.packageTypeFormData == null) ? {} : Object.assign({}, this.state.packageTypeFormData);
        const imperialMetricText = this.state.isUnitsOfMeasure == true ? 'Imperial' : 'Metric';
        const selectedCustomerId = this.state.selectedCustomer?.id || 0;
        const SelectedItemIds = this.state.PackageInsertItemIds || [];
        return (
            <WmsModel
                id='PackageDefModal'
                title={(this.props.isNewPackage) ? this.translate('Label_Create_New_Package_Type') : this.translate('Label_Edit_Package_Type')}
                width={800}
                height={800}
                onCloseFunc={this.props.onClosePackageDefModal}
                isOpen={this.props.sharedIsOpenPackageDefModal}
                customClass='wms-model-new-ui-wrapper' >
                {!this.state.isLoading && (
                <div className='model-content-wrapper'>
                    <WmsCollapse id={'PakingItemTypeColleps'} headerLabel={this.translate('Label_Packing_Item_Type')} showCollapseText={false}>
                        <div className="display-inline">
                            <WmsRadio
                                id="packageTypePackaging"
                                name="PackingItemType"
                                label={this.translate('Label_Packaging')}
                                wmsSelector={this.selector + 'Packing_type_Packaging'}
                                onChangeFunc={(e) => this.handleChangePakingType("Packaging")}
                                checked={this.state.PackingItemType == "Packaging"}
                                // disabled={!this.props.isNewPackage}
                            />

                            <WmsRadio
                                id="packageTypeInsert"
                                name="PackingItemType"
                                label={this.translate('Label_Insert')}
                                wmsSelector={this.selector + 'Packing_type_Insert'}
                                onChangeFunc={(e) => this.handleChangePakingType("Insert")}
                                checked={this.state.PackingItemType == "Insert"}
                                // disabled={!this.props.isNewPackage}
                            />
                        </div>
                    </WmsCollapse>
                    {this.state.PackingItemType == "Packaging" && (
                        <>
                        <WmsCollapse id={'packageTypeGeneral'} headerLabel={this.translate('Label_General')} showCollapseText={false}>
                            <div className="display-inline">
                                <WmsInput
                                    id="packageTypeName"
                                    inputName='Name'
                                    wmsSelector={this.selector + 'Name'}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={this.translate('Label_Package_Name')}
                                    value={renderPkgType.Name}
                                    required={true}
                                    extraWrapperClass="package-type-name"
                                />
                                <WmsInput
                                    id="packageTypeDescription"
                                    inputName='Description'
                                    wmsSelector={this.selector + 'Description'}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={this.translate('Label_Description')}
                                    value={renderPkgType.Description}
                                    extraWrapperClass="package-type-description"
                                />
                                <WmsInput
                                    id="packageTypeBarcode"
                                    inputName='Barcode'
                                    wmsSelector={this.selector + 'Barcode'}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={this.translate('Label_Barcode')}
                                    value={renderPkgType.Barcode}
                                    required={true}
                                    extraWrapperClass="package-type-barcode"
                                />
                            </div>

                            <div className="display-inline customer-and-isdefault-section">
                                <WmsUpdateDropdownList
                                    id="packageTypeCustomer"
                                    name="packageTypeCustomer"
                                    label={this.customLabels.Label_Customer}
                                    optionsLabel={this.translate('Label_Select_Customer')}
                                    wmsSelector={this.selector + "Customer"}
                                    valueArray={this.state.AssignedCustomers}
                                    onChangeFunc={this.handleUserCustomerChange}
                                    textField='name'
                                    valueField='id'
                                    isVirtualOptions={false}
                                    staticOptions={this.optionsCustomer}
                                    extraWrapperClass="package-type-customer"
                                    popupClassName="drop-down-list-unset-height"
                                />
                                <WmsCheckBox
                                    id={'packageTypeIsDefault'}
                                    name={'IsDefault'}
                                    onChangeFunc={this.handleChangeCheckbox}
                                    label={this.translate('Label_Default_Package')}
                                    checked={renderPkgType.IsDefault == true}
                                    value=''
                                    isRounded={false}
                                    wmsSelector={this.selector + 'IsDefault'} />
                            </div>
                        </WmsCollapse>

                        <WmsCollapse id={'packageTypeWeightsAndMeasurements'} headerLabel={this.translate('Label_WEIGHTS_AND_MEASUREMENTS')} showCollapseText={false}>
                            <div className="display-inline weight-uom-section">
                                <div className="wms-details-uom-section">
                                    <WmsRadio
                                        id="packageTypeImperial"
                                        name="UnitsOfMeasure"
                                        label={this.translate('Label_Imperial_US')}
                                        wmsSelector={this.selector + 'Imperial'}
                                        onChangeFunc={this.handleChangeUnitsOfMeasure}
                                        value=""
                                        checked={this.state.isUnitsOfMeasure == true}
                                    />

                                    <WmsRadio
                                        id="packageTypeMetric"
                                        name="UnitsOfMeasure"
                                        label={this.translate('Label_Metric')}
                                        wmsSelector={this.selector + 'Metric'}
                                        onChangeFunc={this.handleChangeUnitsOfMeasure}
                                        value=""
                                        checked={this.state.isUnitsOfMeasure == false}
                                    />
                                </div>
                            </div>
                            <div className="display-inline ">
                                <div  className="">
                                    <WmsInput
                                        id="packageTypeLength"
                                        inputName={'Length' + imperialMetricText}
                                        label={this.translate('Label_Length') + ' (' + (
                                            (this.state.isUnitsOfMeasure) ?
                                                this.translate('Label_Inch_Abbrev_Wihtout_dot') :
                                                this.translate('Label_Centimeter_Abbrev_Wihtout_dot')
                                        ) + ')'}
                                        wmsSelector={this.selector + "Length"}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={renderPkgType['Length' + imperialMetricText]}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                        // required={true}
                                        extraWrapperClass="package-type-length"
                                    />
                                    <WmsInput
                                        id="packageTypeWidth"
                                        inputName={'Width' + imperialMetricText}
                                        label={this.translate('Label_Width') + ' (' + (
                                            (this.state.isUnitsOfMeasure) ?
                                                this.translate('Label_Inch_Abbrev_Wihtout_dot') :
                                                this.translate('Label_Centimeter_Abbrev_Wihtout_dot')
                                        ) + ')'}
                                        wmsSelector={this.selector + "Width"}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={renderPkgType['Width' + imperialMetricText]}
                                        extraWrapperClass="package-type-width"
                                        // required={true}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                    />
                                    <WmsInput
                                        id="packageTypeHeight"
                                        inputName={'Height' + imperialMetricText}
                                        label={this.translate('Label_Height') + ' (' + (
                                            (this.state.isUnitsOfMeasure) ?
                                                this.translate('Label_Inch_Abbrev_Wihtout_dot') :
                                                this.translate('Label_Centimeter_Abbrev_Wihtout_dot')
                                        ) + ')'}
                                        wmsSelector={this.selector + "Height"}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={renderPkgType['Height' + imperialMetricText]}
                                        extraWrapperClass="package-type-height"
                                        // required={true}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                    />
                                    <WmsInput
                                        id="packageTypeWeight"
                                        inputName={'Weight' + imperialMetricText}
                                        label={this.translate('Label_Weight') + ' (' + (
                                            (imperialMetricText == 'Imperial') ?
                                                this.translate('Label_Imperial_Weight') :
                                                this.translate('Label_Metric_Weight')
                                        ) + ')'}
                                        wmsSelector={this.selector + "Weight"}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={renderPkgType['Weight' + imperialMetricText]}
                                        extraWrapperClass="package-type-weight"
                                        // required={true}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                    />
                                </div>
                            </div>
                        </WmsCollapse>

                        <WmsCollapse id={'packageTypeCostAndMarkUp'} headerLabel={this.translate('Label_COSTS_AND_MARK_UP')} showCollapseText={false}>
                            <div className="display-inline">
                                <WmsInput
                                    id="packageTypeCost"
                                    inputName='Cost'
                                    wmsSelector={this.selector + 'Cost'}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={this.translate('Label_Unit_Cost')}
                                    value={renderPkgType.Cost}
                                    extraWrapperClass="package-type-cost"
                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                />
                                <WmsInput
                                    id="packageTypeDefaultMarkupPercent"
                                    inputName='DefaultMarkupPercent'
                                    wmsSelector={this.selector + 'DefaultMarkupPercent'}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={this.translate('Label_Mark_Up_Percent')}
                                    value={renderPkgType.DefaultMarkupPercent}
                                    regularExp={/^[+]?([0-9]+([.]?)(\d?\d?)?)$/}
                                    extraWrapperClass="package-type-markup-per"
                                />
                                <WmsInput
                                    id="packageTypeDefaultMarkupFlat"
                                    inputName='DefaultMarkupFlat'
                                    wmsSelector={this.selector + 'DefaultMarkupFlat'}
                                    onChangeFunc={this.handleChangeEvent}
                                    label={this.translate('Label_Mark_Up_Flat_Rate')}
                                    value={renderPkgType.DefaultMarkupFlat}
                                    extraWrapperClass="package-type-markup-flat"
                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                />
                            </div>
                        </WmsCollapse>
                        </>
                    )}
                    {this.state.PackingItemType == "Insert" && (
                        <>
                            <WmsCollapse id={'packageTypeGeneral'} headerLabel={this.translate('Label_General')} showCollapseText={false}>
                                <div className="display-inline">
                                    <WmsDropdown
                                        id="customer"
                                        name="CustomerIdentifier"
                                        label={this.customLabels.Label_Customer}
                                        wmsSelector={this.selector + "Customer"}
                                        value={this.state.selectedCustomer?.id}
                                        onChangeFunc={this.handleCustomerChange}
                                        options={this.optionsCustomer}
                                        blankFirstOption={true}
                                        valueField={"id"}
                                        textField={"name"}
                                        required={true}
                                    />
                                    <WmsUpdateDropdownList
                                        key={"PackageInsertItems_"+this.state.SkuOptions.length}
                                        id="PackageInsertItems"
                                        name="PackageInsertItems"
                                        label={this.translate('Label_Trigger_Item')}
                                        optionsLabel={this.translate('Label_Select_Trigger_Item')}
                                        wmsSelector={this.selector + "Trigger_Item"}
                                        valueArray={this.state.PackageInsertItemIds}
                                        onChangeFunc={this.handlePackageInsertItemChange}
                                        disabled={this.state.isSkuOptionsUpdating || selectedCustomerId == 0}
                                        textField={'Sku'}
                                        valueField={'ItemId'}
                                        isVirtualOptions={false}
                                        staticOptions={this.state.SkuOptions}
                                        extraWrapperClass="package-insert-items-dropdown"
                                        popupClassName="drop-down-list-unset-height"
                                    />

                                </div>
                                <div className="display-inline inventory-item-area">
                                    <WmsCheckBox
                                        id={'IsInsertHasInventory'}
                                        name={'IsInsertHasInventory'}
                                        onChangeFunc={this.handleChangeIsInsertHasInventory}
                                        label={this.translate('Label_Insert_Is_Inventory')}
                                        checked={this.state.IsInsertHasInventory}
                                        value=''
                                        isRounded={false}
                                        wmsSelector={this.selector + 'InsertIsInventory'} />

                                    <WmsDropdownList
                                        key={"PackageInventoryItems"+SelectedItemIds.length}
                                        id="PackageInventoryItems"
                                        name="PackageInventoryItems"
                                        label={this.translate('Label_Select_Inventory_Item')}
                                        optionsLabel={this.translate("Label_Select_Inventory_Item")}
                                        wmsSelector={this.selector + "Inventory_Item"}
                                        onSelect={(e) => {
                                            if(e.dataItem.isDeleted){
                                                e.preventDefault();
                                            }
                                        }}
                                        value={renderPkgType?.ItemIdentifier?.Id}
                                        onChangeFunc={this.onChangeInventoryItem}
                                        disabled={this.state.isSkuOptionsUpdating || selectedCustomerId == 0 || !this.state.IsInsertHasInventory}
                                        textField="Sku"
                                        valueField="Id"
                                        showDetailsTooltip={true}
                                        parentclassName="form-group"
                                        template="<span style='white-space: nowrap;text-overflow: ellipsis;width: 210px;display: block;overflow: hidden; #if (isDeleted) {# opacity: 0.2; #}#'>#if (Description == null) {# #: Sku# #}else{# #:Sku# - #:Description#  #}#</span>"
                                        extraWrapperClass="package-Inventory-items-dropdown"
                                        required={this.state.IsInsertHasInventory}
                                        popupClassName="drop-down-list-unset-height"
                                        virtualObj={{
                                            itemHeight: 45,
                                            valueMapper: (options) => {
                                                var valueMapperOptions = options;
                                                $.ajax({
                                                    url: "/WebUI/items/items/SkuDropdownIndexRead",
                                                    type: "POST",
                                                    dataType: "json",
                                                    data: {
                                                        itemId: valueMapperOptions.value || 0,
                                                        customerId: selectedCustomerId || 0,
                                                    },
                                                    success: function (data) {
                                                        options.success(data);
                                                    },
                                                });
                                            },
                                        }}
                                        dataSourceObj={{
                                            type: "aspnetmvc-ajax",
                                            pageSize: 30,
                                            serverPaging: true,
                                            serverFiltering: true,
                                            transport: {
                                                read: {
                                                    url: "/WebUI/items/items/SkuDropDownRead",
                                                    type: "POST",
                                                    data: {
                                                        customerId: selectedCustomerId,
                                                    },
                                                },
                                            },
                                            schema: {
                                                data: "Data",
                                                total: "Total",
                                            },
                                            requestEnd: (e) => {
                                                var temparr = e.response.Data.map(el => {el.isDeleted = SelectedItemIds.includes(el.Id); return el});
                                                e.response.Data = temparr;
                                                return e;
                                            }
                                        }}
                                    />
                                </div>

                                <div className="display-inline">
                                    <WmsInput
                                        id="packageTypeName"
                                        inputName='Name'
                                        wmsSelector={this.selector + 'Name'}
                                        onChangeFunc={this.handleChangeEvent}
                                        label={this.translate('Label_Insert_Name')}
                                        value={renderPkgType.Name}
                                        required={true}
                                        extraWrapperClass="package-type-name"
                                    />
                                    <WmsInput
                                        id="packageTypeDescription"
                                        inputName='Description'
                                        wmsSelector={this.selector + 'Description'}
                                        onChangeFunc={this.handleChangeEvent}
                                        label={this.translate('Label_Description')}
                                        value={renderPkgType.Description}
                                        extraWrapperClass="package-type-description"
                                    />
                                    <WmsInput
                                        id="packageTypeBarcode"
                                        inputName='Barcode'
                                        wmsSelector={this.selector + 'Barcode'}
                                        onChangeFunc={this.handleChangeEvent}
                                        label={this.translate('Label_Barcode')}
                                        value={renderPkgType.Barcode}
                                        required={true}
                                        extraWrapperClass="package-type-barcode"
                                    />
                                </div>

                            </WmsCollapse>
                            <WmsCollapse id={'PackageSystemRules'} headerLabel={this.translate('Label_System_Rules')} showCollapseText={false}>
                                <div className="display-inline">
                                    <WmsCheckBox
                                        id={'InsertQtyMatchesItemQty'}
                                        name={'InsertQtyMatchesItemQty'}
                                        onChangeFunc={this.handleChangeCheckbox}
                                        label={this.translate('Label_Insert_Quantity_Matches_Item_Quantity')}
                                        checked={renderPkgType.InsertQtyMatchesItemQty == true}
                                        value=''
                                        isRounded={false}
                                        disabled={this.state.PackageInsertItemIds.length != 1}
                                        wmsSelector={this.selector + 'InsertQtyMatchesItemQty'} />
                                </div>
                            </WmsCollapse>
                            <WmsCollapse id={'PackageLifespan'} headerLabel={this.translate('Label_Lifespan')} showCollapseText={false}>
                                <div className="display-inline">
                                    <WmsDateTimePicker
                                        id={"LifespanStartDate"}
                                        name="LifespanStartDate"
                                        label={this.translate("Label_Begin")}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={renderPkgType.LifespanStartDate}
                                        wmsSelector={this.selector + "LifespanStartDate"}
                                        defaultTime={"00:00:00"}
                                        format={"M/d/yyyy"}
                                        maxDateTime={renderPkgType.LifespanEndDate instanceof Date ? new Date(renderPkgType.LifespanEndDate?.getTime() - 24*60*60*1000): undefined}
                                        isDateParseInCustomFormat={true}
                                    />
                                    <span>To</span>
                                    <WmsDateTimePicker
                                        id={"LifespanEndDate"}
                                        name="LifespanEndDate"
                                        label={this.translate("Label_End")}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={renderPkgType.LifespanEndDate}
                                        wmsSelector={this.selector + "LifespanEndDate"}
                                        defaultTime={"00:00:00"}
                                        format={"M/d/yyyy"}
                                        minDateTime={renderPkgType.LifespanStartDate instanceof Date ? new Date(renderPkgType.LifespanStartDate?.getTime() + 24*60*60*1000): undefined}
                                        isDateParseInCustomFormat={true}
                                    />
                                </div>
                            </WmsCollapse>
                            <WmsCollapse id={'PackagePakingFilters'} headerLabel={this.translate('Label_Packing_Filters')} showCollapseText={false}>
                                <div className="display-inline">
                                    <WmsDropdown
                                        id="Country"
                                        name="Country"
                                        label={this.translate('Label_Country')}
                                        wmsSelector={this.selector + "Country"}
                                        value={renderPkgType.Country}
                                        onChangeFunc={this.handleChangeEvent}
                                        options={this.state.optionsCountry}
                                        textField='Name'
                                        valueField='CountryCode'
                                        parentClassName="form_group "
                                        blankFirstOption={true}
                                    />
                                    <WmsDropdown
                                        id="State"
                                        name="State"
                                        label={this.customLabels.Label_State}
                                        wmsSelector={this.Selector + "State"}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={renderPkgType.State}
                                        options={this.state.OptionsState}
                                        valueField='RegionCode'
                                        textField='Name'
                                        parentClassName='form_group' />
                                    </div>
                                <div className="display-inline order-source-area">
                                    <WmsDropdown
                                        id="OrderSource"
                                        name="OrderSource"
                                        label={this.translate('Label_im_cart_name')}
                                        wmsSelector={this.Selector + "OrderSource"}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={renderPkgType.OrderSource}
                                        options={this.state.OrderSourceOptions}
                                        valueField='OrderSource'
                                        textField='OrderSource'
                                        blankFirstOption={true}
                                        blankFirstOptionText={this.translate('Label_Select_cart_name')}
                                        parentClassName='form_group' />

                                    <WmsCheckBox
                                        id={'IgnoreForEdi'}
                                        name={'IgnoreForEdi'}
                                        onChangeFunc={this.handleChangeCheckbox}
                                        label={this.translate('Label_Ignore_For_EDI_Orders')}
                                        checked={renderPkgType.IgnoreForEdi == true}
                                        value=''
                                        isRounded={false}
                                        wmsSelector={this.selector + 'IgnoreForEdi'} />
                                </div>
                            </WmsCollapse>
                        </>
                    )}
                    <WmsCollapse id={'packageTypeInventory'} headerLabel={this.translate('Window_Title_Label_Inventory')} showCollapseText={false}>
                        {this.state.SelectedFacilities.map((facilityObj, index) => (
                            <div key={"SelectedFacilitiesArea_"+index}>
                                <div className="display-inline">
                                    <WmsDropdown
                                        id={"packageTypeWarehouse_"+index}
                                        name="packageTypeWarehouse"
                                        label={this.customLabels.Label_Warehouse}
                                        wmsSelector={this.selector + "Warehouse_"+index}
                                        value={facilityObj.FacilityIdentifier.Id}
                                        onChangeFunc={(e) => this.handleWarehouseChange(e, index)}
                                        options={this.getActiveFacilityOptions(facilityObj.FacilityIdentifier)}
                                        textField='name'
                                        valueField='id'
                                        extraWrapperClass="form_group package-type-warehouse"
                                        blankFirstOption={true}
                                        blankFirstOptionText={this.translate('Label_Select_warehouse')}
                                        disabled={this.state.IsInsertHasInventory || !facilityObj.isNew || !this.isUserHaveFacilityAccess(facilityObj.FacilityIdentifier.Id)}
                                    />
                                    <WmsDropdownList
                                        id={"packageTypeEmail_"+index}
                                        name="packageTypeEmail"
                                        label={this.translate('Label_Alert_Recipient')}
                                        wmsSelector={this.selector + "Email_"+index}
                                        optionsLabel={this.translate("Label_Select_Email")}
                                        onSelect={(e) => this.handleEmailChange(e, index)}
                                        value={facilityObj.UserIdentifier.Id}
                                        onChangeFunc={() => {}}
                                        extraWrapperClass={'form_group package-type-email'}
                                        textField="Name"
                                        valueField="UserId"
                                        showDetailsTooltip={false}
                                        disabled={facilityObj.FacilityIdentifier.Id == 0 || this.state.IsInsertHasInventory || !this.isUserHaveFacilityAccess(facilityObj.FacilityIdentifier.Id)}
                                        template="<span style='white-space: nowrap;text-overflow: ellipsis;width: 210px;display: block;overflow: hidden'>#if (Email == null) {# #: Name# #}else{# #:Name# - #:Email#  #}#</span>"
                                        popupClassName="drop-down-list-unset-height"
                                        // required={true}
                                        virtualObj={{
                                            itemHeight: 45,
                                            valueMapper: (options) => {
                                                var valueMapperOptions = options;
                                                $.ajax({
                                                    url: "/WebUI/users/users/UserDropDownIndexRead",
                                                    type: "POST",
                                                    dataType: "json",
                                                    data: {
                                                        id: valueMapperOptions.value || 0,
                                                        userAccess: 1,
                                                    },
                                                    success: function (data) {
                                                        options.success(data);
                                                    },
                                                });
                                            },
                                        }}
                                        dataSourceObj={{
                                            type: "aspnetmvc-ajax",
                                            pageSize: 30,
                                            serverPaging: true,
                                            serverFiltering: true,
                                            transport: {
                                                read: {
                                                    url: "/WebUI/users/users/userDropDownRead",
                                                    type: "POST",
                                                    data: {
                                                        userAccess: 1,
                                                    },
                                                },
                                            },
                                            schema: {
                                                data: "Data",
                                                total: "Total",
                                            },
                                        }}
                                    />
                                </div>
                                <div className="display-inline Warehouse-second-area">
                                    <WmsInput
                                        id="CurrentQuantity"
                                        inputName='CurrentQuantity'
                                        wmsSelector={this.selector + 'CurrentQuantity_'+index}
                                        onChangeFunc={(e) => {this.handleChangeSelectFacilityInput(e, index);}}
                                        label={this.translate('Label_Current_Quantity')}
                                        value={facilityObj.CurrentQuantity}
                                        extraWrapperClass=""
                                        required={facilityObj.FacilityIdentifier.Id != 0}
                                        disabled={facilityObj.FacilityIdentifier.Id == 0 || this.state.IsInsertHasInventory || !this.isUserHaveFacilityAccess(facilityObj.FacilityIdentifier.Id)}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                    />
                                    <WmsInput
                                        id="MinQuantity"
                                        inputName='MinQuantity'
                                        wmsSelector={this.selector + 'MinQuantity_'+index}
                                        onChangeFunc={(e) => {this.handleChangeSelectFacilityInput(e, index);}}
                                        label={this.translate('Label_Min_Quantity')}
                                        value={facilityObj.MinQuantity}
                                        extraWrapperClass=""
                                        required={facilityObj.FacilityIdentifier.Id != 0}
                                        disabled={facilityObj.FacilityIdentifier.Id == 0 || this.state.IsInsertHasInventory || !this.isUserHaveFacilityAccess(facilityObj.FacilityIdentifier.Id)}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                    />
                                    {this.state.SelectedFacilities.length >= 2 && (
                                        <WmsButton
                                                label={""}
                                                wmsSelector={this.selector + "Delete_warehouse_"+index}
                                                onClickFunc={() => {this.removeSelectedWarehouse(index)}}
                                                buttonType='red'
                                                icon={'fa-trash'}
                                                disabled={!this.isUserHaveFacilityAccess(facilityObj.FacilityIdentifier.Id)}
                                            />
                                    )}
                                </div>
                            </div>
                        ))}
                        <div className="display-inline">
                            <WmsButton
                                label={this.translate("Label_Add_Another_warehouse")}
                                wmsSelector={this.selector + "Add_Another_warehouse"}
                                onClickFunc={this.addAnotherWarehouse}
                                buttonType='black'
                                disabled={this.state.SelectedFacilities.find(el => el.FacilityIdentifier.Id == 0) != undefined || this.state.IsInsertHasInventory || this.filterSelectedFacilities(0).length == 0}
                            />
                        </div>
                    </WmsCollapse>
                </div>
                    )}
                <div className='footer-btn-holder'>
                    <WmsButton
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.selector + "DiscardExit"}
                        onClickFunc={this.props.onClosePackageDefModal}
                        buttonType='orange'
                        icon={'fa-ban'}
                    />
                    <WmsButton
                        label={this.translate('Label_Save')}
                        wmsSelector={this.selector + "SaveExit"}
                        onClickFunc={this.savePackageType}
                        disabled={this.state.gifSaveChangesAndExit || this.isInvalidFormData()}
                        loading={this.state.gifSaveChangesAndExit}
                        icon={'fa-floppy-o'}
                    />
                </div>
            </WmsModel>
        );
    }
}