
import React from 'react';

// component
import { CustomersGrid } from './customers-grid.component.jsx';
import { CustomersForm } from './customers-form.component.jsx';

import style from './customers.scss';
import * as customerService from './customers.services.jsx';

export class customersHolder extends React.Component {

    constructor(props) {
        super(props);

        this.$rootScope = window;

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            reloadGrid: '',
            sharedCustomerEditUri: null,
            sharedIsCloneCustomer: false,
            sharedSettingsForCloneCustomer: { }
        };
    }

    onCreateCustomer = () => {
        this.setState({ sharedCustomerEditUri: '', sharedIsCloneCustomer:false });
    }

    onEditCustomer = (param_edituri) => {

        this.setState({ sharedCustomerEditUri: param_edituri,sharedIsCloneCustomer:false });
    }

    onCloneCustomer = (param_edituri,param_selected_customer_Settings) => {
        this.setState({
            sharedCustomerEditUri: param_edituri,
            sharedIsCloneCustomer:true,
            sharedSettingsForCloneCustomer: param_selected_customer_Settings
        });
    }

    onExitCustomerForm = () => {
        this.setState({
            sharedCustomerEditUri: null,
            reloadGrid: '',
            sharedIsCloneCustomer:false
        });
    }

    reloadGrid = (basecall) => {
        this.setState({ reloadGrid: basecall });
    }

    render() {
        return (
            <div id="customers-holder-react" >
                    <CustomersGrid
                        onCreateCustomer={this.onCreateCustomer}
                        onEditCustomer={this.onEditCustomer}
                        onCloneCustomer={this.onCloneCustomer}
                        reloadGrid={this.state.reloadGrid}
                        windowId={this.state.windowId}
                    />
                    {this.state.sharedCustomerEditUri != null && (
                        <CustomersForm
                            reloadGrid={this.reloadGrid}
                            sharedCustomerEditUri={this.state.sharedCustomerEditUri}
                            sharedIsCloneCustomer={this.state.sharedIsCloneCustomer}
                            onExitCustomerForm={this.onExitCustomerForm}
                            windowId={this.state.windowId}
                            sharedSettingsForCloneCustomer={this.state.sharedSettingsForCloneCustomer}
                        />
                    )}
            </div >
        );

    }
}