import React, { useState, useCallback, useEffect } from "react";

import VirtualGridComponent from "../react/components_proto/VirtualGridComponent/VirtualGridComponent";
import { StringWms } from "../../assets/ts/StringWms";
import { ArrayWms } from "../../assets/ts/ArrayWms";

import * as GlobalService from "../react/global_services";
import * as GridConfig from "./manage-assembly-grid-configuration";
import PropTypes from "prop-types";
import "./manageassembly.scss";
import { AddEditColumnModel } from "../react/components/AddEditColumn/add-edit-columns.component";
import { ConcurrencyManager } from "../../assets/vendor/concurrencyManager/concurrencyManager";
import { LoadingModel } from "../react/components/LoadingModel/LoadingModel";
import * as ManageAssemblyServices from "./manage-assembly.services";
import CreateNewassemblyModal from "./create-new-assembly.modal.component";
import ImportassemblyModal from "./import-assembly.modal.component";
import CancelAssemblyModal from "./cancel-assembly.modal.component";
import EditAssemblyModal from "./edit-assembly.modal.component";
import { AttachDocuments } from "../react/components/AttachDocuments/AttachDocuments";
import _ from "lodash";
var axios = require("axios");

interface gridFilterType {
    CustomerId: number;
    FacilityId: number;
    Skus: any;
    ReferenceNum: string;
    StartDueDate: string;
    EndDueDate: string;
    IndexOptionsStatus: number;
}

interface Props {
    wmsSelector: string;
    windowId: string;
    sharedFilterObject: gridFilterType;
}

declare var window: any;
const { $ } = window;

const layerIcon = "fa-layer-group";
const defaultAssemblyItem = {
    // General
    AssemblyId: 0,
    EditUri: null,
    FileSummariesUri: null,
    Status: null,

    // transaction details
    CustomerId: 0,
    FacilityId: 0,
    ItemId: 0,
    Qty: 0,
    ReferenceNum: null,
    ExpectedDate: new Date(),
    Notes: null,
    Customer: null,

    // Inventory
    LotNumber: null,
    SerialNumber: null,
    ExpirationDate: null,
    Cost: null,
    LocationInfo: {
        locationId: null,
    },
    PalletInfo: {
        Label: null,
        SplitOverCapacityOnCreate: null,
    },

    //Billing details
    BillingCharges: [
        {
            chargeTypeId: 0,
            chargePerUnit: 0.0,
            unitDescription: null,
            totalCharges: 0.0,
            chargeLabel: "Manual entry made",
            numberOfUnits: 1,
            sku: null,

            // not required
            // "warehouseTransactionPriceCalcId": 0,
            // "chargeOrigin": 0,
            // "chargeTypeDescription": null,
            // "notes": null,
            // "generalLedgerAccount": null,
            // "externalAccountsReceivable": null,
            // "externalItemIdentifier": null,
            // "externalItemDescription": null,
            // "systemGenerated": false,
            // "unsavedManualCharge": false
        },
    ],

    // not required
    // Description: null,
    // CanFinalize: false,
    // InvoiceNumber: null,
    // ExternalId: null,
    // ProcessDate: null,
    // AssemblyItemsCount: 0,
    // SavedElements: null,
    // PalletInfo: null,
};
const ManageAssembliesGridId = "GridManageAssembly";
let localKendoGrid = null;

const ManageAssemblyGrid: React.FC<Props> = (props: Props) => {
    const gridDomSelector = props.windowId
        ? "." + props.windowId + " #" + ManageAssembliesGridId
        : "#" + ManageAssembliesGridId;
    const gridHeaders = StringWms.convertSringToOption(window.translate("ManageAssembly_GrideHeaders"));
    const allColumnList = GridConfig.getGridAllColumnList(gridHeaders, props.wmsSelector);
    const defaultColumns = GridConfig.getGridDefaultColumns(gridHeaders, props.wmsSelector).columns;
    const [schema] = useState(GridConfig.getGridDemoSchema());
    const [gridConfig] = useState(ManageAssemblyServices.getManageAssemblyGridOptionInMetaData());
    let filterCountAxiosSource = null;
    let firstFiftyAxiosSource = null;
    let axiosSource = null;
    let isApiCanceled = false;
    const outPutTypePDF = "application/pdf";

    const [filterText, setFilterText] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [selectedWarehouse, setSelectedWarehouse] = useState("");
    const [fullFilterText, setFullFilterText] = useState("");
    const [recordsCount, setRecordsCount] = useState(0);
    const [gridData, setGridData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(defaultAssemblyItem);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [isOpenColumnModal, setIsOpenColumnModal] = useState(false);
    const [isAllDataLoad, setIsAllDataLoad] = useState(false);
    const [isFilterRowCountLoading, setIsFilterRowCountLoading] = useState(false);
    const [showLoadingError, setShowLoadingError] = useState(false);
    const [isNewColumnAdded, setIsNewColumnAdded] = useState(false);
    const [isGridDataLoading, setIsGridDataLoading] = useState(false);
    const [gridPageSize] = useState(GlobalService.constants.virtualGridDataLimit);
    const [overlayScreenShow, setOverlayScreenShow] = useState(false);
    const [totalRequest, setTotalRequest] = useState(0);

    const [isOpenCreateModel, setIsOpenCreateModel] = useState(false);
    const [isCreateAssemblyLoading, setIsCreateAssemblyLoading] = useState(false);
    const [isOpenImportModel, setIsOpenImportModel] = useState(false);
    const [isOpenCancelAssemblyModal, setIsOpenCancelAssemblyModal] = useState(false);
    const [applyingCancelAssembly, setApplyingCancelAssembly] = useState(false);
    const [sharedSelectedAssembliesIds, setsharedSelectedAssembliesIds] = useState([]);
    const [isOpenEditModel, setIsOpenEditModel] = useState(false);
    const [isOpenAttachDoc, setIsOpenAttachDoc] = useState(false);
    const [sharedAttachObj, setSharedAttachObj] = useState(null);
    const [initialPrintMenuResponse, setInitialPrintMenuResponse] = useState([]);
    const rights = GlobalService.GetRights();

    const onselectGridRow = (KendoGrid: any) => {
        let tempkey = KendoGrid.getKeys();
        let grid = $(gridDomSelector).data("kendoGrid");
        var data = grid.dataItem(grid.select());
        if (data) {
            let selectedRows = grid.select();
            let tempSelectedCustomer = "";
            let tempSelectedWarehouse = "";
            _.each(selectedRows, function (value, key) {
                const data = grid.dataItem(value);
                if (tempSelectedCustomer === "") {
                    tempSelectedCustomer = data.Customer;
                } else {
                    tempSelectedCustomer = data.Customer !== tempSelectedCustomer ? "Multiple" : tempSelectedCustomer;
                }
                if (tempSelectedWarehouse === "") {
                    tempSelectedWarehouse = data.Warehouse;
                } else {
                    // tempSelectedWarehouse = data.Warehouse !== tempSelectedWarehouse ? "Multiple" : tempSelectedWarehouse;
                    tempSelectedWarehouse =
                        data.Warehouse !== tempSelectedWarehouse ? "Multiple" : tempSelectedWarehouse;
                }
            });
            setSelectedCustomer(tempSelectedCustomer);
            setSelectedWarehouse(tempSelectedWarehouse);
            setSelectedRow(data);
            setSelectedRowCount(KendoGrid.getNumberOfSelections());
            setsharedSelectedAssembliesIds(tempkey);
        } else {
            setSelectedRow(defaultAssemblyItem);
            setsharedSelectedAssembliesIds([]);
            setSelectedRowCount(0);
        }
    };
    const getMenuPrintData = () => {
        let menuPrintDataArr = updateInitialPrintMenu(initialPrintMenuResponse, selectedRowCount).PrintData;
        menuPrintDataArr.unshift({
            "name": window.translate("Label_Attach_Files"),
            "wmsSelector": props.wmsSelector + "AttachFiles",
            "value": [],
            "onClickFunc": () => {
                attachFiles(null);
            },
            "disabled": selectedRowCount !== 1,
            "icon": "fa-paperclip",
            "disabledText": "",
            "isContextMenu":
                rights.hasOwnProperty("admin.attachfiles") && rights.hasOwnProperty("Shipping.AttachedDocsEnabled"),
            "isButtonMenu":
                rights.hasOwnProperty("admin.attachfiles") && rights.hasOwnProperty("Shipping.AttachedDocsEnabled"),
            "wmsid": "AttachFiles",
        });
        return menuPrintDataArr;
    };

    const updateInitialPrintMenu = (printMenuResponse, tempSelectedRowCount) => {
        var params = {
            fnNames: ["TransactionReport", "BilOfMaterial", "MULabel"],
            menuResponse: printMenuResponse,
        };
        return getInitialDynamicPrintMenu(params, dynamicMenuClick, tempSelectedRowCount);
    };

    const getInitialDynamicPrintMenu = (params, menuClickEvent, tempSelectedRowCount) => {
        var updatedPrintMenu = {
            PrintData: [],
        };
        if (params.menuResponse !== null && params.menuResponse !== undefined && params.menuResponse.length > 0) {
            params.menuResponse.forEach((item, i) => {
                var fnName = params.fnNames[`${i}`];
                var isDisable = tempSelectedRowCount > 1 && fnName === "MULabel";
                var tempObj = {
                    "name": item.CategoryName,
                    "value": [],
                    "disabled": isDisable,
                    "disabledText": "",
                    "isContextMenu": false,
                    "isButtonMenu": true,
                    "wmsSelector": props.wmsSelector + fnName,
                    "onClickFunc": menuClickEvent,
                    "wmsid": fnName,
                    "icon": layerIcon,
                    "color": "green",
                };
                item.Reports.forEach((report, i) => {
                    tempObj.onClickFunc = () => null;
                    tempObj.value.push({
                        "name": report.Name,
                        "value": [],
                        "disabled": isDisable,
                        "disabledText": "",
                        "isContextMenu": false,
                        "isButtonMenu": true,
                        "wmsSelector": props.wmsSelector + fnName + (i + 1),
                        "onClickFunc": menuClickEvent,
                        "wmsid": fnName,
                        "runReport": report.TemplatedRunner,
                        "rdlName": item.RdlName,
                    });
                });
                updatedPrintMenu.PrintData.push(tempObj);
            });
        }
        return updatedPrintMenu;
    };

    const dynamicMenuClick = (event) => {
        var wmsid = $(event.target).parent().attr("data-wmsid");
        var runReport = $(event.target).parent().attr("data-run-Report");
        var selectedIds = localKendoGrid.getKeys();
        if (wmsid === "TransactionReport") {
            onClickTransactionReportMenu(runReport, selectedIds);
        } else if (wmsid === "BilOfMaterial") {
            onClickBilOfMaterialMenu(runReport, selectedIds);
        } else if (wmsid === "MULabel") {
            onClickMULabelMenu(runReport, selectedIds);
        }
    };

    const clickEventOfContextMenu = (obj: any, e: any) => {
        if (obj.wmsid === "EditAssembly") {
            openEditModel();
        } else if (obj.wmsid === "ConfirmAssembly") {
            confirmAssembly();
        } else if (obj.wmsid === "TransactionReport") {
            onClickTransactionReportMenu(null, sharedSelectedAssembliesIds);
        } else if (obj.wmsid === "BilOfMaterial") {
            onClickBilOfMaterialMenu(null, sharedSelectedAssembliesIds);
        } else if (obj.wmsid === "MULabel") {
            onClickMULabelMenu(null, sharedSelectedAssembliesIds);
        } else if (obj.wmsid === "AttachFiles") {
            attachFiles(null);
        } else if (obj.wmsid === "CancelAssemblies") {
            openCancelAssemblyModel();
        }
        // onOpenReverselAdjustmentModal();
    };
    const onGridDataBound = (e, kendoGrid) => {
        localKendoGrid = kendoGrid;

        const grid = $(gridDomSelector).data("kendoGrid");
        const trData = "tr[data-uid='";
        const gridData = grid.dataSource.view();

        for (const dataItem of gridData) {
            if (dataItem.FullyAllocated) {
                // Fully Allocated: Order has line items and all line items are fully allocated.
                if (grid.lockedTable) {
                    grid.lockedTable.find(trData + dataItem.uid + "']").addClass("wms-fully-allocated");
                    grid.lockedTable.find(trData + dataItem.uid + "']").addClass("wms-fully-allocated-left-border");
                } else {
                    grid.table.find(trData + dataItem.uid + "']").addClass("wms-fully-allocated-left-border");
                }
                grid.table.find(trData + dataItem.uid + "']").addClass("wms-fully-allocated");
            } else if (!dataItem.FullyAllocated && dataItem.HasAllocations) {
                //Partially Allocated: Order has line items, with some being allocated and others unallocated.
                if (grid.lockedTable) {
                    grid.lockedTable.find(trData + dataItem.uid + "']").addClass("wms-partially-allocated");
                    grid.lockedTable.find(trData + dataItem.uid + "']").addClass("wms-partially-allocated-left-border");
                } else {
                    grid.table.find(trData + dataItem.uid + "']").addClass("wms-partially-allocated-left-border");
                }
                grid.table.find(trData + dataItem.uid + "']").addClass("wms-partially-allocated");
            } else {
                // Unallocated: Order has line items but none of them are allocated.
                if (grid.lockedTable) {
                    grid.lockedTable.find(trData + dataItem.uid + "']").addClass("wms-un-allocated");
                    grid.lockedTable.find(trData + dataItem.uid + "']").addClass("wms-un-allocated-left-border");
                } else {
                    grid.table.find(trData + dataItem.uid + "']").addClass("wms-un-allocated-left-border");
                }
                grid.table.find(trData + dataItem.uid + "']").addClass("wms-un-allocated");
            }
        }

        if (typeof $(gridDomSelector).data("kendoTooltip") == "undefined") {
            $(gridDomSelector)
                .kendoTooltip({
                    filter: "tr:not('.wms-fully-allocated') td",
                    position: "bottom",
                    content: (e) => {
                        var dataItem = $(gridDomSelector).data("kendoGrid").dataItem(e.target.closest("tr"));
                        var content = "";
                        if (typeof dataItem != "undefined" && !dataItem.FullyAllocated && dataItem.HasAllocations) {
                            //Partially Allocated
                            content = "Partially Allocated";
                        } else if (
                            typeof dataItem != "undefined" &&
                            !dataItem.FullyAllocated &&
                            !dataItem.HasAllocations
                        ) {
                            // Unallocated
                            content = "Unallocated";
                        }
                        return content;
                    },
                })
                .data("kendoTooltip");
        }
    };
    // // on grid filter selection
    const onGridFilterApplied = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            let filter = dataSource.filter();
            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, filter);
            ManageAssemblyServices.setManageAssemblyGridOptionInMetaData(finalSubmission);
            setFilterAppliedText(filter);
        }, 50);
    };
    const setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            setFilterText("");
            setFullFilterText("");
            return;
        }
        var filterConditionTitle = {
            lte: "Is Less Than Or Equal To",
            eq: "Is Equal To",
            lt: "Is Less Than",
            neq: "Is Not Equal To",
            gte: "Is Greater Than Or Equal To",
            gt: "Is Greater Than",
            startswith: "Starts With",
            endswith: "Ends With",
            contains: "Contains",
            doesnotcontain: "Does Not Contain",
            isnull: "Is Null",
            isnotnull: "Is Not Null",
            isempty: "Is Empty",
            isnotempty: "Is Not Empty",
            isnullorempty: "Has No Value",
            isnotnullorempty: "Has Value",
        };

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;

        filterList.map((item) => {
            var title = allColumnList.find((x) => x.field === item.field).title;
            filterText += title + " : " + item.value + ",";
            fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            return item;
        });
        filterText = filterText.slice(0, -1);
        setFilterText(filterText);
        setFullFilterText(fullFilterText);
    };
    // on grid column sort save changes in meta data object
    const onSort = (event) => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            gridColumn,
            sortArray,
            grid.dataSource.filter()
        );
        ManageAssemblyServices.setManageAssemblyGridOptionInMetaData(finalSubmission);
    };
    // on grid column reorder save changes in meta data object
    const onColumnReorder = (event) => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            switchedArray,
            sortArray,
            grid.dataSource.filter()
        );
        ManageAssemblyServices.setManageAssemblyGridOptionInMetaData(finalSubmission);
    };
    // on grid column resize save changes in meta data object
    const onColumnResize = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        ManageAssemblyServices.setManageAssemblyGridOptionInMetaData(finalSubmission);
    };
    // on click grid clear sort menu
    const onClickClearSorts = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        ManageAssemblyServices.setManageAssemblyGridOptionInMetaData(finalSubmission);
    };
    // on click grid reset default grid layout menu
    const onClickResetGrid = () => {
        var finalSubmission = {
            optionsData: {},
        };
        ManageAssemblyServices.setManageAssemblyGridOptionInMetaData(finalSubmission);
    };
    const eventClickExportGrid = () => {
        $(gridDomSelector).getKendoGrid().saveAsExcel();
    };
    const getGridFilter = useCallback(() => {
        let GridFilterData = props.sharedFilterObject;

        if (GridFilterData.CustomerId !== 0) {
            let selectedCustomer = GlobalService.getCustomerById(GridFilterData.CustomerId);
            if (selectedCustomer == null) {
                GridFilterData.CustomerId = 0;
            }
        }
        return GridFilterData;
    }, [props.sharedFilterObject]);
    const listGrid_dataBind = (data) => {
        _.map(data, function (value) {
            value.TotalCharges = 0;
            _.map(value.Billing, function (subValue) {
                value.TotalCharges += subValue.TotalCharges;
                return value;
            });
            return data;
        });
        setGridData(data);
    };
    // // on new filter data clear selection of grid
    const clearSelectionOfGrid = () => {
        if (localKendoGrid != null) {
            localKendoGrid.clearSelections(() => {
                localKendoGrid.repaintSelections();
            });
        }
    };
    const refreshGrid = () => {
        clearSelectionOfGrid();
        callForGetTotalCount(true, true);
    };
    const onToggleColumnModal = (isOpen) => () => {
        setIsOpenColumnModal(isOpen);
    };
    const saveMetaData = () => {
        var grid = $(gridDomSelector).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = localKendoGrid.prepareGridOptionsForStorage(grid.columns, sort, grid.dataSource.filter());
        ManageAssemblyServices.setManageAssemblyGridOptionInMetaData(finalSubmission);
    };
    // Cancel axios call if new filter applied
    const cancelLoadAllData = () => {
        if (firstFiftyAxiosSource) {
            firstFiftyAxiosSource.cancel();
            firstFiftyAxiosSource = null;
        }
        if (axiosSource) {
            axiosSource.cancel();
            axiosSource = null;
        }
        isApiCanceled = true;
        setIsGridDataLoading(false);
        setOverlayScreenShow(false);
        setGridData([]);
        setIsAllDataLoad(false);
    };
    const callForGetTotalCount = (clearData = true, clearGridSelection = false) => {
        setRecordsCount(0);
        setIsFilterRowCountLoading(true);
        setShowLoadingError(false);

        if (clearGridSelection) {
            setSelectedRowCount(0);
            setIsAllDataLoad(false);
        }

        var param_filterObj = Object.assign({}, getGridFilter());
        param_filterObj.StartDueDate = param_filterObj.StartDueDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.StartDueDate, "", false, true).isoStringUTC
            : null;
        param_filterObj.EndDueDate = param_filterObj.EndDueDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.EndDueDate, "23:59:59", false, true).isoStringUTC
            : null;
        if (filterCountAxiosSource) {
            filterCountAxiosSource.cancel();
            filterCountAxiosSource = null;
        }
        // on filter update cancel axiosSource call if any running
        cancelLoadAllData();
        filterCountAxiosSource = axios.CancelToken.source();

        // Call for FilterRowCount.
        ManageAssemblyServices.setGridFiltere(param_filterObj, filterCountAxiosSource.token)
            .then((response: any) => {
                setRecordsCount(response.data);
                if (clearData) {
                    setGridData([]);
                }
                filterCountAxiosSource = null;
            })
            .finally(() => {
                setIsFilterRowCountLoading(false);
            });
    };
    const resetGridAllMessage = () => {
        if (isAllDataLoad) {
            setIsNewColumnAdded(true);
        }
    };
    const sequence = (n) => {
        let seq = [];
        for (let i = 0; i < n; i++) {
            seq.push(i);
        }
        return seq;
    };
    const getAllResponseData = (data) => {
        if (!isApiCanceled) {
            let allData = [];
            data.forEach((obj) => {
                allData = [...obj.Data, ...allData];
            });
            listGrid_dataBind(allData);
            setOverlayScreenShow(false);
            setIsGridDataLoading(false);
            setIsAllDataLoad(true);
            setIsNewColumnAdded(false);
        } else {
            setIsGridDataLoading(false);
        }
    };
    const loadAllRecords = (isLoaderPopupShow) => {
        isApiCanceled = false;
        if (isLoaderPopupShow) {
            setIsGridDataLoading(true);
            setGridData([]);
        }

        var param_filterObj = Object.assign({}, getGridFilter());
        param_filterObj.CustomerId = param_filterObj.CustomerId === 0 ? null : param_filterObj.CustomerId;
        param_filterObj.FacilityId = param_filterObj.FacilityId === 0 ? null : param_filterObj.FacilityId;
        param_filterObj.StartDueDate = param_filterObj.StartDueDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.StartDueDate, "", false, true).isoStringUTC
            : null;
        param_filterObj.EndDueDate = param_filterObj.EndDueDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.EndDueDate, "23:59:59", false, true).isoStringUTC
            : null;
        axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/assemblies/FindAssemblies",
            headers: { "Access-Control-Allow-Origin": "*" },
            cancelToken: axiosSource.token,
        });
        const MAX_CONCURRENT_REQUESTS = 3;
        let totalRequest = Math.ceil(recordsCount / gridPageSize);
        setTotalRequest(totalRequest);
        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS, GlobalService);
        let enableColumns = getEnableColumns();
        Promise.all(
            sequence(totalRequest).map((i) =>
                api.post("/AssembliesData?sorts=[]&filters=[]&pgnum=" + (i + 1) + "&pgsiz=" + gridPageSize, {
                    columns: enableColumns,
                    assemblySearchInfo: param_filterObj,
                })
            )
        )
            .then((responses) => {
                let assembliesData = responses.map((r) => r.data);
                getAllResponseData(assembliesData);
            })
            .catch((error) => {
                setShowLoadingError(true);
                cancelLoadAllData();
            })
            .then(() => {
                if (manager) {
                    manager.detach();
                }
            });
    };
    const getEnableColumns = () => {
        var defaultParam = [
            "Sku",
            "Customer",
            "Warehouse",
            "Qty",
            "ReferenceNum",
            "ExpectedDate",
            "Status",
            "FileSummariesUri",
            "FullyAllocated",
            "HasAllocations",
        ];
        let columnList = [];

        let grid = $(gridDomSelector).data("kendoGrid");
        // if grid already defined than we get columns from grid
        if (typeof grid != "undefined") {
            columnList = grid.columns;
        } else {
            // else get column list from metadataObject or default columns
            columnList = GridConfig.getGridDefaultColumns(gridHeaders, props.wmsSelector + "Grid").columns;
        }

        var enableColumns = columnList.map((el) => el.field);
        // merge defaultParam and enableColumns without dublicate value
        return _.uniq(defaultParam.concat(enableColumns));
    };
    const callForGetNewData = () => {
        if (recordsCount > 0 && recordsCount <= gridPageSize) {
            loadAllRecords(false);
        } else {
            resetGridAllMessage();
        }
    };
    const onClickLoadAllRecords = () => {
        var param_filterObj = Object.assign({}, getGridFilter());
        param_filterObj.CustomerId = param_filterObj.CustomerId === 0 ? null : param_filterObj.CustomerId;
        param_filterObj.FacilityId = param_filterObj.FacilityId === 0 ? null : param_filterObj.FacilityId;
        param_filterObj.StartDueDate = param_filterObj.StartDueDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.StartDueDate, "", false, true).isoStringUTC
            : null;
        param_filterObj.EndDueDate = param_filterObj.EndDueDate
            ? StringWms.convertFilterStringToISODate(param_filterObj.EndDueDate, "23:59:59", false, true).isoStringUTC
            : null;

        let enableColumns = getEnableColumns();
        setOverlayScreenShow(true);

        if (firstFiftyAxiosSource) {
            firstFiftyAxiosSource.cancel();
            firstFiftyAxiosSource = null;
        }
        firstFiftyAxiosSource = axios.CancelToken.source();
        axios
            .post(
                "/WebUI/assemblies/FindAssemblies/AssembliesData?sorts=[]&filters=[]&pgnum=1&pgsiz=50",
                {
                    params: param_filterObj,
                    columns: enableColumns,
                },
                { cancelToken: firstFiftyAxiosSource.token }
            )
            .then((response) => {
                firstFiftyAxiosSource = "";
                setGridData(response.data.Data);
                loadAllRecords(false);
            });
    };

    const openCreateModel = () => {
        setSelectedRow(defaultAssemblyItem);
        setIsOpenCreateModel(true);
    };
    const onCloseCreateNewAssemblyModal = () => {
        setIsOpenCreateModel(false);
    };
    const onApplyCreateAssembly = (formData) => {
        setIsCreateAssemblyLoading(true);
        setIsGridDataLoading(true);

        ManageAssemblyServices.createAssembly(formData)
            .then((response) => {
                // setSelectedRowCount(0);
                setSelectedRow(response.data);
                setIsOpenCreateModel(false);

                GlobalService.notification("success", window.translate("Create_Assembly_Created_Successfully"));
                refreshGrid();
                setIsOpenEditModel(true);
            })
            .finally(() => {
                kendo.ui.progress($("#CreateNewAssemblyModel"), false);
                setIsCreateAssemblyLoading(false);
                setIsGridDataLoading(false);
            });
    };
    const openImportModel = () => {
        setIsOpenImportModel(true);
    };
    const onCloseImportModal = () => {
        setIsOpenImportModel(false);
    };
    const onApplyImportAssembly = () => {
        refreshGrid();
    };
    const openEditModel = () => {
        setIsOpenEditModel(true);
    };
    const onCloseEditModal = () => {
        setIsOpenEditModel(false);
    };
    const openCancelAssemblyModel = () => {
        setIsOpenCancelAssemblyModal(true);
    };
    const closeCancelAssemblyModel = () => {
        setIsOpenCancelAssemblyModal(false);
    };
    const onApplyCancelAssembly = (tempCancelAssemblyData) => {
        setApplyingCancelAssembly(true);

        ManageAssemblyServices.CancelAssembly(tempCancelAssemblyData)
            .then(() => {
                GlobalService.notification("success", window.translate("Assembly_cancelled_Successfully"));
                refreshGrid();
            })
            .finally(() => {
                kendo.ui.progress($("#CancelAssemblyModal"), false);
                setApplyingCancelAssembly(false);
                setIsOpenCancelAssemblyModal(false);
            });
    };
    const confirmAssembly = () => {
        setIsGridDataLoading(true);
        if (sharedSelectedAssembliesIds.length === 1) {
            ManageAssemblyServices.confirmAssemblies(sharedSelectedAssembliesIds)
                .then((response) => {
                    GlobalService.notification("success", window.translate("Assembly_Confirmed_Successfully"));
                    refreshGrid();
                })
                .finally(() => {
                    setIsGridDataLoading(false);
                });
        } else {
            ManageAssemblyServices.confirmSingleAssembly(sharedSelectedAssembliesIds[0])
                .then((response) => {
                    GlobalService.notification("success", window.translate("Assembly_Confirmed_Successfully"));
                    refreshGrid();
                })
                .finally(() => {
                    setIsGridDataLoading(false);
                });
        }
    };
    const onClickBilOfMaterialMenu = (reportUrl, assemblyIds) => {
        setIsGridDataLoading(true);
        let param_obj = {
            "requestBody": {
                "RunnerUriTemplate": reportUrl ?? "/reportdefs/ssrs/BillOfMaterials/runner{?parameters}",
                "TransactionIds": assemblyIds,
                "ShowCharges": null,
                "ShowPackages": null,
                "UtcOffset": "-6",
                "OutputType": outPutTypePDF,
            },
        };
        let url = "/WebUI/assemblies/FindAssemblies/RunBillOfMaterialReport";
        ManageAssemblyServices.getReport(url, param_obj)
            .then(generateReport)
            .finally(() => {
                setIsGridDataLoading(false);
            });
    };
    const onClickTransactionReportMenu = (reportUrl, assemblyIds) => {
        setIsGridDataLoading(true);
        let param_obj = {
            "requestBody": {
                "RunnerUriTemplate": reportUrl ?? "/reportdefs/ssrs/ViewTransaction/runner{?parameters}",
                "TransactionIds": assemblyIds,
                "ShowCharges": null,
                "ShowPackages": null,
                "UtcOffset": "-6",
                "OutputType": outPutTypePDF,
            },
        };
        let url = "/WebUI/assemblies/FindAssemblies/RunViewTransactionReport";
        ManageAssemblyServices.getReport(url, param_obj)
            .then(generateReport)
            .finally(() => {
                setIsGridDataLoading(false);
            });
    };
    const onClickMULabelMenu = (reportUrl, assemblyIds) => {
        setIsGridDataLoading(true);
        let param_obj = {
            "requestBody": {
                "RunnerUriTemplate": reportUrl ?? "/reportdefs/ssrs/PalletID/runner{?parameters,alternatepath}",
                "TransactionId": assemblyIds[0],
                "OutputType": outPutTypePDF,
            },
        };
        let url = "/WebUI/assemblies/FindAssemblies/RunMULabelReport";
        ManageAssemblyServices.getReport(url, param_obj)
            .then(generateReport)
            .finally(() => {
                setIsGridDataLoading(false);
            });
    };
    const generateReport = (response) => {
        var blob = ArrayWms.convertBase64ToByteArray(response.data, outPutTypePDF);
        ArrayWms.displayBlobInWindow(blob, "report.pdf");
        refreshGrid();
    };
    const afterApplyEditAssembly = () => {
        refreshGrid();
        setIsOpenEditModel(false);
    };

    const attachFiles = (e) => {
        var sharedAttachObj = {
            "isAssembly": true,
            "uri": selectedRow.FileSummariesUri,
            "fileSummariesUri": selectedRow.FileSummariesUri,
            "getAttachedDocsInfoUrl":
                "/WebUI/assemblies/FindAssemblies/GetAttachedDocsInfo?fileSummariesUri=" + selectedRow.FileSummariesUri,
            "getAttachedDocsInfoMethod": "GET",
            "getAttachedDocsInfoPayload": {},
            "attachdocumentUrl":
                "/WebUI/assemblies/FindAssemblies/AttachDocsToAssembly?fileSummariesUri=" +
                selectedRow.FileSummariesUri,
            "removeAttachDocUrl":
                "/WebUI/assemblies/FindAssemblies/DeleteAttachedDoc?fileSummariesUri=" + selectedRow.FileSummariesUri,
            "selectedOrderData": selectedRow,
            "customer": selectedRow.Customer,
            "transactionId": selectedRow.AssemblyId,
        };
        setSharedAttachObj(sharedAttachObj);
        setIsOpenAttachDoc(true);
    };

    const isAllSelectedStatusOpen = () => {
        // localKendoGrid
        let grid = $(gridDomSelector).data("kendoGrid");
        if (grid && localKendoGrid) {
            let selectedRowIds = grid.select();
            let isAllOpen = true;
            _.each(selectedRowIds, function (value, key) {
                const data = grid.dataItem(value);
                if (data.Status !== "Open") {
                    isAllOpen = false;
                }
            });
            return isAllOpen;
        }
        return false;
    };

    useEffect(() => {
        if (getGridFilter()) {
            callForGetTotalCount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sharedFilterObject]);
    useEffect(() => {
        if (recordsCount > 0) {
            callForGetNewData();
        }
        const currentUserId = GlobalService.GetCurrentUser().CustomerId;
        ManageAssemblyServices.getReports(currentUserId)
            .then((response) => {
                if (response !== undefined) {
                    setInitialPrintMenuResponse(response);
                }
            })
            .finally(() => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordsCount]);

    return (
        <div className={`manage-assembly-grid`}>
            <VirtualGridComponent
                virtualGridDataLimit={gridPageSize}
                isNewColumnAdded={isNewColumnAdded}
                callForGetNewData={callForGetNewData}
                isAllDataLoad={isAllDataLoad}
                loadAllRecords={onClickLoadAllRecords}
                loading={isFilterRowCountLoading || isGridDataLoading}
                loadAllMessageFirst={window.translate("Label_Grid_Filter_Message")}
                loadAllMessageSecond={window.translate("Label_Grid_Filter_Message_2")}
                showLoadingError={showLoadingError}
                shawCheckbox={false}
                id={ManageAssembliesGridId}
                showSortColumns={true}
                showGridSummary={true}
                showGridResult={true}
                gridName={ManageAssembliesGridId}
                windowId={props.windowId}
                wmsSelector={props.wmsSelector + ManageAssembliesGridId}
                gridKeySelection={"AssemblyId"}
                virtual={true}
                totalResult={recordsCount}
                staticGridData={gridData}
                exportable={true}
                isExportSelectedData={true}
                exportExcelObj={{
                    fileName:
                        "ManageAssemblyExport-" +
                        kendo.toString(kendo.parseDate(new Date().toString()), "yyyyMMddhhmmss") +
                        ".xlsx",
                    allPages: true,
                    filterable: true,
                }}
                csvExportFileName={"ManageAssemblyExport"}
                eventClickExportGrid={eventClickExportGrid}
                allColumns={allColumnList}
                defaultColumns={defaultColumns}
                schema={schema}
                onSelection={onselectGridRow}
                clickEventOfContextMenu={clickEventOfContextMenu}
                metaDataFieldsName={"ManageAssembly"}
                onGridFilterApplied={onGridFilterApplied}
                filterText={filterText}
                fullFilterText={fullFilterText}
                onDataBound={onGridDataBound}
                onSort={onSort}
                onColumnReorder={onColumnReorder}
                onColumnResize={onColumnResize}
                onClickClearSorts={onClickClearSorts}
                onClickResetGrid={onClickResetGrid}
                metaGridConfig={gridConfig}
                eventClickColumns={onToggleColumnModal(true)}
                isLegendSet={true}
                menu={[
                    {
                        "name": window.translate("Label_Create"),
                        "wmsSelector": props.wmsSelector + "create",
                        "value": [],
                        "clickFunc": openCreateModel,
                        "icon": "fa-plus",
                        "color": "green",
                        "isContextMenu": false,
                        "isButtonMenu": true,
                        "disabled": false,
                    },
                    {
                        "name": window.translate("Label_Import"),
                        "wmsSelector": props.wmsSelector + "import",
                        "value": [],
                        "clickFunc": openImportModel,
                        "icon": "fa-file-import",
                        "color": "green",
                        "isContextMenu": false,
                        "isButtonMenu": true,
                        "disabled": false,
                    },
                    {
                        "name": window.translate("Label_Manage"),
                        "wmsSelector": props.wmsSelector + "Manage",
                        "value": [
                            {
                                "name": window.translate("Label_Edit"),
                                "wmsSelector": props.wmsSelector + "edit",
                                "value": [],
                                "onClickFunc": openEditModel,
                                "disabled": selectedRowCount !== 1 || selectedRow?.Status !== "Open",
                                "disabledText": window.translate(
                                    selectedRowCount > 1
                                        ? "Label_Disable_Edit_Multiple_Assembly"
                                        : "Label_Disable_Edit_Closed_Cancel_Assembly"
                                ),
                                "isContextMenu": true,
                                "isButtonMenu": true,
                                "wmsid": "EditAssembly",
                            },
                            {
                                "name": window.translate("Label_Select_Confirm"),
                                "value": [],
                                "disabled": selectedRowCount !== 1 || selectedRow?.Status !== "Open",
                                "disabledText": window.translate(
                                    selectedRowCount > 1
                                        ? "Label_Disable_Confirm_Multiple_Assembly"
                                        : "Label_Disable_Confirm_Closed_Cancel_Assembly"
                                ),
                                "isContextMenu": true,
                                "isButtonMenu": true,
                                "wmsSelector": props.wmsSelector + "ConfirmAssembly",
                                "onClickFunc": confirmAssembly,
                                "wmsid": "ConfirmAssembly",
                            },
                            {
                                "name": window.translate("Label_Cancel"),
                                "wmsSelector": props.wmsSelector + "cancel",
                                "value": [],
                                "onClickFunc": openCancelAssemblyModel,
                                "isContextMenu": true,
                                "isButtonMenu": true,
                                "disabled": selectedRowCount > 0 && !isAllSelectedStatusOpen(),
                                "wmsid": "CancelAssemblies",
                                "disabledText": window.translate("Label_Disable_Cancel_Closed_Assembly"),
                            },
                        ],
                        "disabled": false,
                        "disabledText": "You may not add blahssss0",
                        "icon": layerIcon,
                        "color": "green",
                        "isContextMenu": true,
                        "isButtonMenu": true,
                    },
                    {
                        "name": window.translate("NavBarV1_Btn_Documents"),
                        "wmsSelector": props.wmsSelector + "Documents",
                        "value": getMenuPrintData(),
                        "disabled": selectedRowCount === 0,
                        "disabledText": "You may not add blahssss0",
                        "icon": layerIcon,
                        "color": "green",
                        "isContextMenu": false,
                        "isButtonMenu": true,
                    },
                    {
                        "name": "",
                        "wmsSelector": props.wmsSelector + "RefreshGrid",
                        "value": [],
                        "clickFunc": refreshGrid,
                        "disabled": false,
                        "icon": "fa-sync-alt",
                        "color": "blue",
                        "isContextMenu": false,
                        "isButtonMenu": true,
                        "parentClassName": "assemblyRefreshBtn",
                    },
                    {
                        "name": "Copy",
                        "wmsSelector": props.wmsSelector + "copy",
                        "value": [
                            {
                                "name": window.translate("Label_Copy_Field"),
                                "wmsSelector": props.wmsSelector + "Copy_Field",
                                "value": [],
                                "disabled": false,
                                "disabledText": window.translate("Label_Copy_Field"),
                                "icon": "fa-bandcamp",
                                "color": "green",
                                "isContextMenu": true,
                                "isButtonMenu": false,
                                "wmsid": "copyField",
                            },
                            {
                                "name": window.translate("Label_Copy_Row"),
                                "wmsSelector": props.wmsSelector + "Copy_Row",
                                "value": [],
                                "disabled": false,
                                "disabledText": window.translate("Label_Copy_Row"),
                                "icon": "fa-wrench",
                                "color": "green",
                                "isContextMenu": true,
                                "isButtonMenu": false,
                                "wmsid": "copyRow",
                            },
                        ],
                        "disabled": false,
                        "disabledText": "",
                        "icon": "fa-wrench",
                        "color": "green",
                        "isContextMenu": true,
                        "isButtonMenu": false,
                    },
                ]}
            />
            {isOpenColumnModal && (
                <AddEditColumnModel
                    id={"addEditColumnAssembly"}
                    height={700}
                    windowId={props.windowId}
                    onCloseColumnModel={onToggleColumnModal(false)}
                    isOpenColumnModel={isOpenColumnModal}
                    allSortColumnList={allColumnList}
                    gridId={ManageAssembliesGridId}
                    updateGridData={refreshGrid}
                    saveChangesInMetaData={saveMetaData}
                />
            )}
            {overlayScreenShow && (
                <LoadingModel
                    sharedOpenLoadingmodel={overlayScreenShow}
                    onCancelLoadAllData={cancelLoadAllData}
                    sharedTotalRequest={totalRequest}
                />
            )}
            {isOpenCreateModel && (
                <CreateNewassemblyModal
                    onCloseCreateNewAssemblyModal={onCloseCreateNewAssemblyModal}
                    isOpenCreateNewAssemblyModal={isOpenCreateModel}
                    wmsSelector={"CreateNewAssembly"}
                    selectedRow={selectedRow}
                    windowId={props.windowId}
                    onApplyCreateAssembly={onApplyCreateAssembly}
                    isCreateAssemblyLoading={isCreateAssemblyLoading}
                />
            )}
            {isOpenImportModel && (
                <ImportassemblyModal
                    wmsSelector={"ImportAssembly"}
                    windowId={props.windowId}
                    onCloseImportAssemblyModal={onCloseImportModal}
                    isOpenImportAssemblyModal={isOpenImportModel}
                    onApplyImportAssembly={onApplyImportAssembly}
                />
            )}
            {isOpenCancelAssemblyModal && (
                <CancelAssemblyModal
                    selectedRow={selectedRow}
                    selectedAssembliesIds={Object.values(sharedSelectedAssembliesIds)}
                    selectedCustomer={selectedCustomer}
                    selectedWarehouse={selectedWarehouse}
                    wmsSelector={"CancelNewAssembly"}
                    onCloseCancelAssembly={closeCancelAssemblyModel}
                    applyingCancelAssembly={applyingCancelAssembly}
                    isOpenCancelAssemblyModal={isOpenCancelAssemblyModal}
                    onApplyCancelAssembly={onApplyCancelAssembly}
                />
            )}
            {isOpenEditModel && (
                <EditAssemblyModal
                    wmsSelector={"EditAssembly"}
                    windowId={props.windowId}
                    selectedRow={selectedRow}
                    isOpenEditAssemblyModal={isOpenEditModel}
                    onCloseEditAssemblyModal={onCloseEditModal}
                    isEditAssemblyLoading={false}
                    onApplyEditAssembly={afterApplyEditAssembly}
                />
            )}
            {isOpenAttachDoc && (
                <AttachDocuments
                    isOpenAttachDoc={isOpenAttachDoc}
                    sharedAttachObj={sharedAttachObj}
                    windowId={props.windowId}
                    onClose={(e) => setIsOpenAttachDoc(false)}
                />
            )}
        </div>
    );
};
ManageAssemblyGrid.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    windowId: PropTypes.string.isRequired,
    sharedFilterObject: PropTypes.any.isRequired,
};

ManageAssemblyGrid.defaultProps = {
    wmsSelector: "",
    windowId: "",
    sharedFilterObject: {
        CustomerId: 0,
        FacilityId: 0,
        Skus: null,
        ReferenceNum: null,
        StartDueDate: null,
        EndDueDate: null,
        IndexOptionsStatus: 0,
    },
};

export default React.memo(ManageAssemblyGrid);
