
import React from 'react';
import * as GlobalService from './../../global_services';
import style from '../report-iframe.scss';
import { reportIframe } from '../report-iframe.component.jsx';
export class managementIframeList extends React.Component {
    constructor(props) {

        super(props);
        
        this.$rootScope = window;
        this.translate = window.translate
        this.state = {
            reloadGrid: false,
            sharedUserUri: null,
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            Rights: GlobalService.GetRights(),
        }
    }
    setIframeUrl = (iframeUrl, reportTitle, urlParam) => e => {
        this.$rootScope.OpenWindow({
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            id: iframeUrl,
            title: this.translate(reportTitle),
            active: true,
            isIframe: true,
            icon: 'submenu-icon fa fa-plus',
            urlParam: urlParam
        });
    }
    render() {
        return (
            <div className="react_iframebox">
                <ul>
                    <li>
                        <ul>
                            {this.state.Rights.hasOwnProperty('admin.reports.l3') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/TurnsReport.aspx", "Label_Inventory_Turns_Reports","inventory-turns")}>{this.translate('Label_Inventory_Turns_Reports')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l3') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/StockStatusByReceiptReport.aspx", "Label_Stock_Status_Anniversary_Report","stock-status-by-anniversary")}>{this.translate('Label_Stock_Status_Anniversary_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l5') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/PricingReport.aspx", "Label_Customer_Pricing_Report","customer-pricing")}>{this.translate('Label_Customer_Pricing_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l5') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/UserReport.aspx", "Label_User_Report","user-list")}>{this.translate('Label_User_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l5') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/TransactionReview.aspx", "Label_Transaction_Review_Report","transaction-review")}>{this.translate('Label_Transaction_Review_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l5') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/FreightAudit.aspx", "Label_Freight_Audit_Report","freight-audit")}>{this.translate('Label_Freight_Audit_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l5') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/CycleCount.aspx", " Cycle Count Report","cycle-count-report")}>{this.translate('Label_Cycle_Count_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l5') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/UPSDailySummary.aspx", "Label_UPS_Daily_Report","ups-daily-summaries")}>{this.translate('Label_UPS_Daily_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l5') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/PackageUsageReport.aspx", "Label_Package_Usage_Report","package-usage")}>{this.translate('Label_Package_Usage_Report')}</a>
                                </li>
                            )}
                            {this.state.Rights.hasOwnProperty('admin.reports.l5') && (
                                <li>
                                    <a href="javascript:void(0);" onClick={this.setIframeUrl("/WebUI/V1/V1Link/SmallParcelTransReport.aspx", "Label_Small_Parcel_Report","small-parcel-transactions")}>{this.translate('Label_Small_Parcel_Report')}</a>
                                </li>
                            )}
                        </ul>
                    </li>
                </ul>
            </div>
        )
    }
}
