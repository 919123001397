import React from "react";
import * as GlobalService from "./../global_services";
import style from "./settings.scss";
import WmsButton from "./../components/WmsButton/WmsButton.jsx";
import {KendoGridWms} from "../../../assets/ts/KendoGridWms.ts";
import * as commanFunction from "./settinggrid-columns.jsx";
import * as settingServices from './setting_services.jsx';

var KendoGridCC,
    layoutData,
    that;
export class settingsHolder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customLabel: {},
            saveSettingGif: false,
            disableSaveBtn: true
        };
       
        this.$rootScope = window;
        this.translate = window.translate;
        KendoGridCC = new KendoGridWms("settingsGrid", this.$rootScope.ChildBulletin.Data.Window, "Name", "./assets/json/cyclecount/cycleJobs.json");
        that = this;
    }
    componentDidMount() {
        this.setState({
            customLabel: GlobalService.getCustomLabels()
        });

        var customLables = GlobalService.getCustomLabels();
        console.log("settings-holder componentDidMount : ", customLables);
        var gridData = [];
        for (var key in customLables) {
            if (customLables.hasOwnProperty(key)) {
                var disKey = key.replace(/_/g, " ");
                disKey = disKey.replace("Lbl", "");
                disKey = disKey.replace("Label", "");
                var objLbl = {
                    Symbol: key,
                    display_name: disKey,
                    Value: customLables[key]
                }
                gridData.push(objLbl);
                console.log("disKey " + disKey + " key : " + key + " -> " + customLables[key]);
            }
        }
        this.loadGrid(gridData);
    }
    loadGrid(gridData) {
        var dataSource = new kendo
            .data
            .DataSource({
                data: gridData,
                schema: commanFunction.settingGridSchema()
            });
        var tempOptionsCycleCountJobsGrid = {
            dataSource: dataSource,
            columns: commanFunction.settingGridColumns(),
            selectable: true,
            editable: true,
            save: (e) => {
                this.setState({ disableSaveBtn: false });
            }
        };
        var copycycleCountJobsGridColumns = {
            columns: commanFunction.settingGridColumns()
        };

        var finalOptionsObj = KendoGridCC.getGridOptions(copycycleCountJobsGridColumns, tempOptionsCycleCountJobsGrid, '[{}]');
        $("#settingsGrid").kendoGrid(finalOptionsObj);
    }
    saveSettings() {
        that.setState({
            saveSettingGif: true
        });

        /*
        var data = $("#settingsGrid")
            .data()
            .kendoGrid
            .dataSource

            .view()
            .toJSON();

        */

        var mydata = $("#settingsGrid").data()
            .kendoGrid
            .dataSource
        // lets only send over the fields that have been updated
        const myarray = mydata._data.filter( x => x.dirty == true );
        // Dirty array contains those elements modified

        /*
        data.forEach(element => {
            delete element.display_name;
        });
        */
        var userLang = navigator.language || navigator.userLanguage;
        var finalResponse = {
            "Locale": userLang,
            "CustomerId": null,
            "Symbols": myarray
        }
        settingServices
            .saveSettings(finalResponse)
            .then(function (response) {
                console.log(response);
                if (typeof response != 'undefined' && (response.status == 204 || response.status == 200)) {
                    GlobalService.notification('success', that.translate('Label_Take_Effect'));
                }
                that.setState({
                    saveSettingGif: false
                });
            }, function (response) {
                GlobalService.notification('error', response);
            }).finally(() => {
                that.setState({
                    saveSettingGif: false
                });
            });
    }
    render() {
        return (
            <div className={style.wms_settings_wrapper}>
                <WmsButton
                    label={this.translate("Label_Save")}
                    wmsSelector={"settingSaveButton"}
                    onClickFunc={this.saveSettings}
                    loading={this.state.saveSettingGif}
                    showToolTip={this.state.disableSaveBtn}
                    tooltipText={this.translate('Tooltip_Save_Disabled')}
                    disabled={this.state.saveSettingGif || this.state.disableSaveBtn}
                    icon="fa-solid fa-floppy-disk"/>

                <div className={style.wms_settingsGrid}>
                    <div className={style.wms_area_content}>
                        <div id="gridContainer">
                            <div id="gridParent">
                                <div className="no-josh" id="settingsGrid" data-wms-selector="settingsGrid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
