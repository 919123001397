import React from 'react';
// component
import WmsModel from './../components/WmsModel/WmsModel.jsx';
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import { DictionaryWms } from '../../../assets/ts/DictionaryWms.ts';
import OrderImportMessage from './order-import-message.component.jsx';
import * as ProgressHelper from './progress.helper.jsx';
import style from './progress.scss';
var axios = require('axios');

import * as progressService from "./progress.services.jsx";
import * as GlobalService from "./../global_services";
import * as smallParcelService from './../smallParcel/smallParcel.service.jsx';
import _ from "lodash";
import * as findOrdersService from '../orders/findOrders/findOrders.services.jsx';

export class ProgressHolder extends React.PureComponent {

    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            isOpenProgress: false,
            runnigProcesses: [],
            loadingProgress: [],
            errorProgress: [],
            isOpenImportMessage: false,
            printTicketMessage: '',
            isOpenVoidLabelErrorModal: false,
            selectedOrderData: '',
            faultsOrderIds: [],
            isButtonLoading: false,
            showNewMessageModel: false,
            packageRestockAlertsData: [],
            // importData: []
        };
        console.log("ProgressHolder component loaded");
    }

    componentDidMount() {
        var that = this;
        // document.addEventListener("showNewMessageAlert", function(e) {
        //     if (e.detail && e.detail.messageCount != 0) {
        //         that.setState({
        //             newMessageAlertCount: e.detail.messageCount,
        //             showNewMessageModel: true
        //         })
        //     }
        // });
        document.addEventListener("showProgressModal", function (e) {
            if (e.detail) {
                var _data = that.wrapEventDataForArray(e.detail);
                if(!_data) {
                    return false;
                }
                that.$rootScope.setGreenIndicator(true);
                that.setState({ runnigProcesses: [...that.state.runnigProcesses, _data] });
                // that.$rootScope.Global.DisableKeyboard();
                if (e.detail.type == "receiptGridExport") {
                    that.gridExport(_data);
                }
                if (e.detail.type == "confirmReceipt") {
                    that.confirmReceipt(_data);
                }
                if (e.detail.type == "massship") {
                    that.massShip(_data)
                }
                if (e.detail.type == "importAsnAsync") {
                    that.importAsnAsync(_data)
                }
                if (e.detail.type == "importAssemblyAsync") {
                    that.importAssemblyAsync(_data)
                }
                if (e.detail.type == "importAsn") {
                    that.importAsn(_data)
                }
                if (e.detail.type == "importOrder") {
                    that.importOrders(_data)
                }
                if (e.detail.type == "importOrderAsync") {
                    that.importOrderAsync(_data)
                }
                if (e.detail.type == "ordersTransfer") {
                    that.ordersTransfer(_data)
                }
                if (e.detail.type == "generatePickTicket") {
                    that.generatePickTicket(_data);
                }
                if (e.detail.type == "voidShippingLabel") {
                    that.voidShippingLabel(_data);
                }
                if (e.detail.type == "zplFormatedLabel") {
                    that.zplFormatedLabel(_data);
                }
                if (e.detail.type == "pdfFormatedLabel") {
                    that.pdfFormatedLabel(_data);
                }
                if (e.detail.type == "createEndOfDay") {
                    that.createEndOfDay(_data);
                }
                if (e.detail.type == "reallocation") {
                    that.reallocation(_data);
                }
                if (e.detail.type == "packingslips") {
                    that.packingslips(_data);
                }
                that.setState({
                    isOpenProgress: false
                });
                that.loadingProgressSet(_data.progress_id, 0);
            } else {
                that.setState({
                    isOpenProgress: true
                });
                that.$rootScope.setGreenIndicator(false);
            }
        });
        that.getLatestPackageRestockAlerts(true);

    }
    clearAlert = (alert) => {
        progressService.deletePackageRestockAlerts(alert.Uri).then((response) => {
            this.getLatestPackageRestockAlerts();
        });
    }
    getLatestPackageRestockAlerts = (isShowModel = false) => {
        progressService.getPackageRestockAlertsData(GlobalService.CurrentUser.UserId).then((response) => {
            var resData = [];
            if(response.data && response.data.length){
                this.$rootScope.setGreenIndicator(true);
                resData = response.data;
            }else {
                this.$rootScope.setGreenIndicator(false);
                isShowModel = false;
            }
            this.setState({
                packageRestockAlertsData: response.data,
                showNewMessageModel: isShowModel,
            });
        });
    }
    onCloseNewMessageAlertModel = () => {
        this.setState({
            showNewMessageModel: false,
        });
    }
    onOpenNewMessageAlertModel = () => {
        this.setState({
            showNewMessageModel: false,
            isOpenProgress: true
        });
    }
    componentDidUpdate() {
        this.state.runnigProcesses.map((progress, index) => {
            if (typeof $('#' + progress.progress_id).data("kendoProgressBar") == 'undefined') {
                console.log("Progress bar is init now.");
                $("#" + progress.progress_id).kendoProgressBar({
                    min: 0,
                    max: 100,
                    type: "percent",
                    value: 40,
                    animation: {
                        duration: 400
                    }
                }).data("kendoProgressBar");
            }
        })
    }
    static getDerivedStateFromProps (nextProps, prevState) {
        if (nextProps.openProgressModal == true) {
            return { isOpenProgress: true };
        }
        return null;
    }

    wrapEventDataForArray = (data) => {
        if (data.type == "importOrder") {
            var _obj = this.baseStructure();
            _obj.title = "Import Processing";
            _obj.type = "import";
            _obj.button1Show = true;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;

            return _obj;

        } else if (data.type == "massship") {
            var _obj = this.baseStructure();
            _obj.title = "Mass Ship Processing";
            _obj.type = "massship";
            _obj.button1Show = false;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;

            return _obj;
        } else if (data.type == "importOrderAsync") {
            var _obj = this.baseStructure();
            _obj.title = "Parse and Import Processing";
            _obj.type = "importOrderAsync";
            _obj.button1Show = true;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;

            return _obj;
        } else if (data.type == "ordersTransfer") {
            var _obj = this.baseStructure();
            _obj.title = "Orders Transfer Processing";
            _obj.type = "ordersTransfer";
            _obj.button1Show = false;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;

            return _obj;
        } else if (data.type == "importAsn" || data.type == "importAsnAsync") {
            var _obj = this.baseStructure();
            _obj.title = "Import Receipt";
            _obj.type = "import";
            _obj.button1Show = false;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;

            return _obj;
        } else if (data.type == "importAssemblyAsync") {
            var _obj = this.baseStructure();
            _obj.title = "Import Assembly";
            _obj.type = "import";
            _obj.button1Show = false;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;

            return _obj;
        } else if (data.type == "receiptGridExport") {
            var _obj = this.baseStructure();
            _obj.title = "Receipt Grid Export";
            _obj.type = "gridExport";
            _obj.button1Show = false;
            _obj.button2Show = true;
            _obj.button3Show = true;
            _obj.data = data;
            _obj.guide = '';
            _obj.gridExportUrl = '/WebUI/Receiving/UpdateSession/GridExporter';
            _obj.gridExporterUrl = '/WebUI/Receiving/UpdateSession/GridExporter?guid=';
            _obj.gridExporterDownloadUrl = '/WebUI/Receiving/FindReceivings/GridExporterData?guid=';
            _obj.fileName = "ReceiptsGridExport";
            return _obj;
        } else if (data.type == "confirmReceipt") {
            var _obj = this.baseStructure();
            _obj.title = "Confirm Receipt Processing";
            _obj.type = "confirmReceipt";
            _obj.button1Show = false;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;
            return _obj;
        } else if (data.type == "generatePickTicket") {
            var _obj = this.baseStructure();
            _obj.title = (data.pickticket.ticketType == "master") ? "Master Pick Ticket PDF" : "Pick Ticket PDF";
            _obj.type = "generatePickTicket";
            _obj.button1Show = false;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;
            return _obj;
        } else if (data.type == "voidShippingLabel") {
            var _obj = this.baseStructure();
            _obj.title = "Void Shipping Label";
            _obj.type = "voidShippingLabel";
            _obj.button1Show = false;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;

            return _obj;
        } else if (data.type == "zplFormatedLabel") {
            var _obj = this.baseStructure();
            _obj.title = "Zpl Formatted Label";
            _obj.type = "zplFormatedLabel";
            _obj.button1Show = false;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;

            return _obj;
        } else if (data.type == "pdfFormatedLabel") {
            var _obj = this.baseStructure();
            _obj.title = "Pdf Formatted Label";
            _obj.type = "pdfFormatedLabel";
            _obj.button1Show = false;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;

            return _obj;
        } else if (data.type == "createEndOfDay") {
            var _obj = this.baseStructure();
            _obj.title = "Manifest";
            _obj.type = "createEndOfDay";
            _obj.data = data;

            return _obj;
        } else if (data.type == "reallocation") {
            var _obj = this.baseStructure();
            _obj.title = "Reallocation Orders";
            _obj.type = "reallocation";
            _obj.button1Show = false;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;

            return _obj;
        } else if (data.type == "packingslips") {
            var outputType = data.outputType;
            if (outputType == "xls") {
                outputType = "Excel";
            } else if (outputType == "doc") {
                outputType = "msword";
            }
            var _obj = this.baseStructure();
            _obj.title = "packing Slips " + outputType.toUpperCase();
            _obj.type = "packingslips";
            _obj.button1Show = false;
            _obj.button2Show = false;
            _obj.button3Show = true;
            _obj.data = data;

            return _obj;
        } else {
            return false;
        }
    }
    baseStructure = () => {
        return {
            "title": "",
            "titleSummary": "",
            "type": "",
            "button1Show": true,
            "button2Show": true,
            "progress_id": "progress_" + new Date().getTime(),
            "data": {},
            "status": "processing"
        }
    }

    gridExport = (data) => {

        var that = this;
        this.errorProgressSet(data.progress_id, 0);

        progressService.gridExport(data.data, data.gridExportUrl).then((response) => {

            if (typeof response != 'undfined' && response.status == 200 && response.data != '') {
                data.guide = response.data;
                this.gridExporter(response, data);
            }
        }).catch((error) => {
            that.errorProgressSet(data.progress_id, 1);
            that.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);

            //TODO Error handling
        }).finally(() => { });

    }
    gridExporter = (response, data) => {
        var that = this;
        progressService.gridExporter(response.data, data.gridExporterUrl).then(function success(response2) {
            if (response2) {
                if (response2.status == 200 && response2.data == true) {

                    that.loadingProgressSet(data.progress_id, 100);

                    // update specific progress using javascript way
                    let runningProcessCopy = JSON.parse(JSON.stringify(that.state.runnigProcesses))
                    runningProcessCopy.filter(function (progress) {
                        if (progress.progress_id === data.progress_id) {
                            progress.title = "Grid Exported Successfully";
                            progress.status = "success";
                        }
                    });
                    that.setState({
                        runnigProcesses: runningProcessCopy
                    });
                    GlobalService.notification('success', 'Grid Exported Successfully');
                    that.errorProgressSet(data.progress_id, 2);
                    setTimeout(() => {
                        $("#downloadFile_" + data.progress_id).trigger("click");
                    });
                } else {
                    setTimeout(() => {
                        that.gridExporter(response, data);
                    }, 5000);
                }
            }
        }).catch((error) => {
            that.errorProgressSet(data.progress_id, 1);
            that.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);
            // GlobalService.apiFailer(error.response);
        });
    }

    confirmReceipt = (data) => {
        var that = this;
        this.errorProgressSet(data.progress_id, 0);
        progressService.asyncMassReceiptConfirmer(data.data.confirmReciptData).then((response) => {
            if (typeof response != 'undefined' && (response.status == 200 || response.status == 204)) {
                var uri = response.data.Uri;
                this.asyncMassReceiptConfirmerStatus(data, uri);
            }
        }, function error(err) {
            console.error("progress-holder ", err);
            // GlobalService.apiFailer(error.response);
            that.errorProgressSet(data.progress_id, 1);
            that.progressStatusSetError(data.progress_id);
        });
    }
    asyncMassReceiptConfirmerStatus = (data, uri) => {
        progressService.asyncMassReceiptConfirmerStatus(uri).then((response2) => {
            if (typeof response2 != 'undefined' && (response2.status == 200 || response2.status == 204)) {
                var percentComplete = Math.floor(((response2.data.ReceiversConfirmed / response2.data.ReceiversToConfirm) * 100));
                this.loadingProgressSet(data.progress_id, percentComplete);

                if (response2.data.ReceiversConfirmed >= response2.data.ReceiversToConfirm) {

                    var eventItem = new CustomEvent("EventNewReceiptData", {
                        detail: { "action": "update" }
                    });
                    document.dispatchEvent(eventItem);

                    let runningProcessCopy = JSON.parse(JSON.stringify(this.state.runnigProcesses))
                    runningProcessCopy.filter(function (progress) {
                        if (progress.progress_id === data.progress_id) {
                            progress.title = "Receipt Confirm Complete";
                            progress.titleSummary = response2.data.ReceiversConfirmed + "/" + response2.data.ReceiversToConfirm + "  Receipt Confirm. ";
                            progress.status = "success";
                        }
                    });
                    this.setState({
                        runnigProcesses: runningProcessCopy
                    });

                    var succeessMsg = this.translate('Label_Bulk_Confirm_Receipt_Success');
                    if (response2.data.ReceiversToConfirm > 1) {
                        succeessMsg = this.translate('Label_Bulk_Confirm_Receipts_Success');
                    }

                    GlobalService.notification('success', succeessMsg);
                    if (data.data.callBackFunc) {
                        data.data.callBackFunc();
                    }

                    this.errorProgressSet(data.progress_id, 2);
                } else {
                    setTimeout(() => {
                        this.asyncMassReceiptConfirmerStatus(data, uri);
                    }, 3000);
                }

                this.updateIconStatus();
            }
        }, (err) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", err);
            // GlobalService.apiFailer(error.response);
        });
    }

    massShip = (data) => {
        var that = this;
        this.errorProgressSet(data.progress_id, 0);

        progressService.massShipAndCloseOrdersAsync(data.data.MassShip).then((response) => {

            if (typeof response != 'undefined' && (response.status == 200 || response.status == 204)) {
                var uri = response.data.Uri;
                this.massShipAndCloseOrdersAsyncStatus(data, uri);
            }
        }).catch((error) => {
            that.errorProgressSet(data.progress_id, 1);
            that.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);
            // GlobalService.apiFailer(error.response);
        }).finally(() => { });

    }
    massShipAndCloseOrdersAsyncStatus = (data, uri) => {
        progressService.massShipAndCloseOrdersAsyncStatus(uri).then((response2) => {
            if (typeof response2 != 'undefined' && (response2.status == 200 || response2.status == 204)) {
                var percentComplete = Math.floor(((response2.data.OrdersConfirmed / response2.data.OrdersToConfirm) * 100));

                this.loadingProgressSet(data.progress_id, percentComplete);
                if (response2.data.OrdersConfirmed >= response2.data.OrdersToConfirm) {

                    // update specific progress using javascript way
                    let runningProcessCopy = JSON.parse(JSON.stringify(this.state.runnigProcesses))
                    runningProcessCopy.filter(function (progress) {
                        if (progress.progress_id === data.progress_id) {
                            progress.title = "Mass Ship Complete";
                            progress.titleSummary = response2.data.OrdersConfirmed + "/" + response2.data.OrdersToConfirm + "  shipped and closed. ";
                            progress.status = "success";
                        }
                    });
                    this.setState({
                        runnigProcesses: runningProcessCopy
                    });
                    var eventItem = new CustomEvent("EventNewOrderData", {
                        'action': 'new'
                    });
                    document.dispatchEvent(eventItem);
                    GlobalService.notification('success', this.translate('Label_Bulk_ShipAndClose_Success'));
                    if (data.data.callBackFunc) {
                        data.data.callBackFunc();
                    }

                    this.errorProgressSet(data.progress_id, 2);
                } else {
                    setTimeout(() => {
                        this.massShipAndCloseOrdersAsyncStatus(data, uri);
                    }, 5000);
                }
                this.updateIconStatus();
            }
        }).catch((error) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);
            // GlobalService.apiFailer(error.response);
        }).finally(() => { });
    }

    importAssemblyAsync = (data) => {
        var that = this;
        that.errorProgressSet(data.progress_id, 0);
        progressService.assemblyUploadFileWithoutManage(data.data.customerId, data.data.facilityId, data.data.skipInvalidAssemblies, data.data.File).then((response) => {
            if (response.Success) {
                var uri = response.FinalResult.Uri;
                this.assemblyParseAndImportFileAsyncStatus(data, uri);
            } else if (response.Error) {
                this.errorProgressSet(data.progress_id, 1);
                this.progressStatusSetError(data.progress_id);

                alert("Something wrong with code");

                // that.setState({
                //     isOpenImportMessage: true,
                // });

                /***Pending open modal for error is pending */
                // ShippingOrderImportService.OrderImport.Status = 2;
                // ShippingOrderImportService.OrderImport.ImportResults = response.FinalResult;
                // $scope.OpenModal(windowOptions, data.uploadFile, data.uploadFileType, data.SelectedFile);
            } else if (response.SuccessWithErrors) {

                this.errorProgressSet(data.progress_id, 1);
                this.progressStatusSetError(data.progress_id);
                alert("Something wrong with code");

                // that.setState({
                //     isOpenImportMessage: true,
                //     importData: {
                //         Status: 1,
                //         ImportResults: response.FinalResult.Results
                //     }
                // });

                /***Pending open modal for error is pending */
                // ShippingOrderImportService.OrderImport.Status = 1;
                // $scope.OpenModal(windowOptions, data.uploadFile, data.uploadFileType, data.SelectedFile);
            } else if (response.ImportError) {

                this.errorProgressSet(data.progress_id, 1);
                this.progressStatusSetError(data.progress_id);
                GlobalService.notification('error', this.translate('Label_Import_Error_Notif').replace('%file%', data.SelectedFile));

            } else if (response.Timeout) {
                this.errorProgressSet(data.progress_id, 1);
                this.progressStatusSetError(data.progress_id);
                GlobalService.notification('error', this.translate('Label_Timeout_Error_Notif'));
            }
        }).catch((error) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);
            // GlobalService.apiFailer(error.response);
        }).finally(() => { });

    }
    assemblyParseAndImportFileAsyncStatus = (data, uri) => {
        progressService.assemblyParseAndImportFileAsyncStatus(uri).then((response2) => {

            if (response2.status === 200 || response2.status === 204) {

                var percentComplete = Math.floor(((response2.data.AssemblyCreationsAttempted / response2.data.AssembliesToCreate) * 100));
                this.loadingProgressSet(data.progress_id, percentComplete);

                if (response2.data.AssemblyCreationsAttempted >= response2.data.AssembliesToCreate && response2.data.AssemblyCreationsAttempted != 0) {

                    let runningProcessCopy = JSON.parse(JSON.stringify(this.state.runnigProcesses))
                    runningProcessCopy.filter(function (progress) {
                        if (progress.progress_id === data.progress_id) {
                            progress.title = "Import Complete";
                            progress.titleSummary = response2.data.Results.CreatedAssemblies.length + "/" + response2.data.AssembliesToCreate + " imported. ";
                            progress.response = response2.data.Results;
                            progress.status = "success";
                        }
                    });

                    this.setState({ runnigProcesses: runningProcessCopy });
                    GlobalService.notification('success', this.translate('Label_assembly_import_success'));
                    this.errorProgressSet(data.progress_id, 2);

                } else {
                    setTimeout(() => {
                        this.assemblyParseAndImportFileAsyncStatus(data, uri)
                    }, 2000);
                }
                this.updateIconStatus();

            }
        }).catch((error) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);
            // GlobalService.apiFailer(error.response);
        }).finally(() => { });
    }
    importAsnAsync = (data) => {
        var that = this;
        that.errorProgressSet(data.progress_id, 0);
        progressService.receiptUploadFileWithoutManage(data.data.CustomerId, data.data.FacilityId, data.data.TransformId, data.data.File).then((response) => {
            if (response.Success) {
                var uri = response.FinalResult.Uri;
                this.receiptParseAndImportFileAsyncStatus(data, uri);
            } else if (response.Error) {
                this.errorProgressSet(data.progress_id, 1);
                this.progressStatusSetError(data.progress_id);

                alert("Something wrong with code");

                // that.setState({
                //     isOpenImportMessage: true,
                // });

                /***Pending open modal for error is pending */
                // ShippingOrderImportService.OrderImport.Status = 2;
                // ShippingOrderImportService.OrderImport.ImportResults = response.FinalResult;
                // $scope.OpenModal(windowOptions, data.uploadFile, data.uploadFileType, data.SelectedFile);
            } else if (response.SuccessWithErrors) {

                this.errorProgressSet(data.progress_id, 1);
                this.progressStatusSetError(data.progress_id);
                alert("Something wrong with code");

                // that.setState({
                //     isOpenImportMessage: true,
                //     importData: {
                //         Status: 1,
                //         ImportResults: response.FinalResult.Results
                //     }
                // });

                /***Pending open modal for error is pending */
                // ShippingOrderImportService.OrderImport.Status = 1;
                // $scope.OpenModal(windowOptions, data.uploadFile, data.uploadFileType, data.SelectedFile);
            } else if (response.ImportError) {

                this.errorProgressSet(data.progress_id, 1);
                this.progressStatusSetError(data.progress_id);
                GlobalService.notification('error', this.translate('Label_Import_Error_Notif').replace('%file%', data.SelectedFile));

            } else if (response.Timeout) {
                this.errorProgressSet(data.progress_id, 1);
                this.progressStatusSetError(data.progress_id);
                GlobalService.notification('error', this.translate('Label_Timeout_Error_Notif'));
            }
        }).catch((error) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);
            // GlobalService.apiFailer(error.response);
        }).finally(() => { });

    }
    receiptParseAndImportFileAsyncStatus = (data, uri) => {
        progressService.receiptParseAndImportFileAsyncStatus(uri).then((response2) => {

            if (response2.status === 200 || response2.status === 204) {

                var percentComplete = Math.floor(((response2.data.ReceiptCreationsAttempted / response2.data.ReceiptsToCreate) * 100));
                this.loadingProgressSet(data.progress_id, percentComplete);

                if (response2.data.ReceiptCreationsAttempted >= response2.data.ReceiptsToCreate && response2.data.ReceiptCreationsAttempted != 0) {

                    let runningProcessCopy = JSON.parse(JSON.stringify(this.state.runnigProcesses))
                    runningProcessCopy.filter(function (progress) {
                        if (progress.progress_id === data.progress_id) {
                            progress.title = "Import Complete";
                            progress.titleSummary = response2.data.Results.CreatedReceivers.length + "/" + response2.data.ReceiptsToCreate + " imported. ";
                            progress.response = response2.data.Results;
                            progress.status = "success";
                        }
                    });

                    this.setState({ runnigProcesses: runningProcessCopy });
                    GlobalService.notification('success', this.translate('Label_order_import_success'));
                    this.errorProgressSet(data.progress_id, 2);

                } else {
                    setTimeout(() => {
                        this.receiptParseAndImportFileAsyncStatus(data, uri)
                    }, 2000);
                }
                this.updateIconStatus();

            }
        }).catch((error) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);
            // GlobalService.apiFailer(error.response);
        }).finally(() => { });
    }

    importAsn = (data) => {
        this.errorProgressSet(data.progress_id, 0);
        var tempPreferences = {};
        tempPreferences.skipInvalidReceivers = data.data.OnErrorRadio == 1 ? true : false;

        progressService.importParsedAsn(data.data.customer_id, tempPreferences).then((response) => {
            if (response) {
                if (response.Success) {
                    // Open the success window
                    var uri = response.FinalResult.Uri;
                    this.importParsedAsnAsyncStatus(data, uri);
                } else if (response.Error) {
                    this.errorProgressSet(data.progress_id, 1);
                    this.progressStatusSetError(data.progress_id);
                    alert("Something wrong with code");

                    //Pending Open error window
                    // ShippingOrderImportService.OrderImport.Status = 2; // Should be 2 by defualt
                    // ShippingOrderImportService.OrderImport.ImportResults = response.FinalResult; // This is the information we need for the next window
                    // $scope.OpenModal(windowOptions, data.uploadFile, data.uploadFileType, data.SelectedFile);

                } else if (response.SuccessWithErrors) {

                    this.errorProgressSet(data.progress_id, 1);
                    this.progressStatusSetError(data.progress_id);
                    alert("Something wrong with code");

                    // Pending Open success with errors
                    // ShippingOrderImportService.OrderImport.Status = 1; // Use this to determine which message to show from orderImportMessageController
                    // $scope.OpenModal(windowOptions, data.uploadFile, data.uploadFileType, data.SelectedFile);
                } else if (response.ImportError) {
                    this.errorProgressSet(data.progress_id, 1);
                    this.progressStatusSetError(data.progress_id);
                    GlobalService.notification('error', this.translate('Label_Import_Error_Notif').replace('%file%', data.SelectedFile));
                } else if (response.Timeout) {
                    this.errorProgressSet(data.progress_id, 1);
                    this.progressStatusSetError(data.progress_id);
                    GlobalService.notification('error', this.translate('Label_Timeout_Error_Notif'));
                }
            }
        }).catch((error) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            // GlobalService.apiFailer(error.response);
            console.error("progress-holder ", error);
        }).finally(() => { });
    }
    importParsedAsnAsyncStatus = (data, uri) => {
        progressService.importParsedAsnAsyncStatus(uri).then((response2) => {
            if (response2) {
                if (response2.status === 200 || response2.status === 204) {
                    var percentComplete = Math.floor(((response2.data.ReceiverCreationsAttempted / response2.data.ReceiversToCreate) * 100));

                    this.loadingProgressSet(data.progress_id, percentComplete);
                    if (response2.data.ReceiverCreationsAttempted >= response2.data.ReceiversToCreate && response2.data.ReceiverCreationsAttempted != 0) {

                        let runningProcessCopy = JSON.parse(JSON.stringify(this.state.runnigProcesses))
                        runningProcessCopy.filter(function (progress) {
                            if (progress.progress_id === data.progress_id) {
                                progress.title = "Import Complete";
                                progress.titleSummary = response2.data.Results.CreatedReceivers.length + "/" + response2.data.ReceiversToCreate + " imported. ";
                                progress.response = response2.data.Results;
                                progress.status = "success";
                            }
                        });
                        this.setState({
                            runnigProcesses: runningProcessCopy
                        });
                        GlobalService.notification('success', this.translate('Label_order_import_success'));
                        this.errorProgressSet(data.progress_id, 2);
                    } else {
                        setTimeout(() => {
                            this.importParsedAsnAsyncStatus(data, uri);
                        }, 2000);
                    }
                }
            }
        }).catch((error) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            // GlobalService.apiFailer(error.response);
            console.error("progress-holder ", error);
        }).finally(() => { });
    }

    importOrders = (data) => {
        var that = this;
        that.errorProgressSet(data.progress_id, 0);
        var tempPreferences = {};
        if (!data.data.ShowPreferencesSection) {
            tempPreferences.skipShortOrders = data.data.preferences.Criteria === 1 ? true : false;
            tempPreferences.skipInvalidOrders = data.data.preferences.OnError === 1 ? true : false;
        } else if (data.data.ShowPreferencesSection) {
            tempPreferences.skipShortOrders = data.data.WhatToImportRadio === '1' ? true : false;
            tempPreferences.skipInvalidOrders = data.data.OnErrorRadio === '1' ? true : false;
        }

        progressService.importParsedOrders(data.data.customer_id, tempPreferences).then((response) => {
            var uri = response.Uri;
            this.importParsedOrdersAsyncStatus(data, uri);
        }).catch((error) => {
            that.errorProgressSet(data.progress_id, 1);
            that.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);
            // GlobalService.apiFailer(error.response);
        }).finally(() => { });
    }
    importParsedOrdersAsyncStatus = (data, uri) => {
        progressService.importParsedOrdersAsyncStatus(uri).then((response2) => {
            var percentComplete = Math.floor(((response2.data.OrderCreationsAttempted / response2.data.OrdersToCreate) * 100));

            this.loadingProgressSet(data.progress_id, percentComplete);

            if (response2.data.OrderCreationsAttempted >= response2.data.OrdersToCreate && response2.data.OrderCreationsAttempted != 0) {

                let runningProcessCopy = JSON.parse(JSON.stringify(this.state.runnigProcesses))
                runningProcessCopy.filter(function (progress) {
                    if (progress.progress_id === data.progress_id) {
                        progress.title = "Import Complete";
                        progress.titleSummary = response2.data.Results.CreatedOrders.length + "/" + response2.data.OrdersToCreate + " imported. " + "Batch: " + response2.data.Results.BatchName;
                        progress.response = response2.data.Results;
                        progress.status = "success";
                        progress.showViewFindOrder = (response2.data.Results.BatchName) ? true : false;
                    }
                });

                this.setState({
                    runnigProcesses: runningProcessCopy,
                    isOpenImportMessage: true
                });
                GlobalService.notification('success', this.translate('Label_order_import_success'));
                this.errorProgressSet(data.progress_id, 2);
            } else {
                setTimeout(() => {
                    this.importParsedOrdersAsyncStatus(data, uri);
                }, 2500);
            }
            this.updateIconStatus();
        }).catch((error) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);
            // GlobalService.apiFailer(error.response);
        }).finally(() => { });
    }

    importOrderAsync = (data) => {

        var that = this;
        that.errorProgressSet(data.progress_id, 0);

        progressService.uploadFileWithoutManage(data.data.CustomerId, data.data.FacilityId, data.data.TransformId, data.data.File, data.data.FileName).then((response) => {
            var uri = response.data.Uri;
            this.parseAndImportFileAsyncStatus(data, uri);
        }).catch((error) => {
            that.errorProgressSet(data.progress_id, 1);
            that.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);
            // GlobalService.apiFailer(error.response);

            // ShippingOrderImportService.OrderImport.Status = 2; // Should be 2 by defualt
            // ShippingOrderImportService.OrderImport.ImportResults = response.FinalResult; // This is the information we need for the next window
            // $scope.OpenModal(windowOptions, data.uploadFile, data.uploadFileType, data.SelectedFile);
        }).finally(() => { });
    }
    parseAndImportFileAsyncStatus = (data, uri) => {
        progressService.parseAndImportFileAsyncStatus(uri).then((response2) => {
            var percentComplete = Math.floor(((response2.data.OrderCreationsAttempted / response2.data.OrdersToCreate) * 100));
            this.loadingProgressSet(data.progress_id, percentComplete);

            if (response2.data.OrderCreationsAttempted >= response2.data.OrdersToCreate && response2.data.OrderCreationsAttempted != 0) {

                let runningProcessCopy = JSON.parse(JSON.stringify(this.state.runnigProcesses))
                runningProcessCopy.filter(function (progress) {
                    if (progress.progress_id === data.progress_id) {
                        progress.title = "Import Complete";
                        progress.titleSummary = response2.data.Results.CreatedOrders.length + "/" + response2.data.OrdersToCreate + " imported. " + "Batch: " + response2.data.Results.BatchName;
                        progress.response = response2.data.Results;
                        progress.status = "success";
                        progress.showViewFindOrder = (response2.data.Results.BatchName) ? true : false;
                    }
                });
                this.setState({
                    runnigProcesses: runningProcessCopy
                });
                GlobalService.notification('success', this.translate('Label_order_import_success'));
                this.errorProgressSet(data.progress_id, 2);
            } else {
                setTimeout(() => {
                    this.parseAndImportFileAsyncStatus(data, uri);
                }, 2500);
            }
        }).catch((error) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", error);
            // GlobalService.apiFailer(error.response);
        }).finally(() => { });
    }

    ordersTransfer = (data) => {
        var that = this;
        that.errorProgressSet(data.progress_id, 0);
        var uri = data.data.ordersTransfer.uri;

        progressService.callServiceOnUrl(uri).then(function success(response) {
            if (response && (response.status == 200) || (response.status == 204)) {
                var percentComplete = Math.floor(((response.data.OrdersSuccessfullyTransferred / response.data.OrdersToTransfer) * 100));
                that.loadingProgressSet(data.progress_id, percentComplete);

                if (response.data.Done) {
                    if (response.data.Errors != null) {
                        var faults = response.data.Errors.Faults;
                        var orderIds = [];
                        _.each(faults, function (item) {
                            orderIds.push(item.EntryInfo)
                        })
                        GlobalService.notification("error", {
                            "ErrorClass": "System",
                            "ErrorCode": "abcerrircode",
                            "RequestorContextId": "OrderIds: " + orderIds.join(", ") + " failed to transfer",
                            "Field": ""
                        });

                        that.errorProgressSet(data.progress_id, 1);
                        that.progressStatusSetError(data.progress_id);
                        return;
                    }
                    var eventItem = new CustomEvent("EventNewOrderData", {
                        detail: { "action": "update" }
                    });
                    document.dispatchEvent(eventItem);

                    // windowOptions.height = 200;
                    // Use this to determine which message to show from orderImportMessageController
                    // $scope.completeImport = response.data.Done;

                    let runningProcessCopy = JSON.parse(JSON.stringify(that.state.runnigProcesses));
                    runningProcessCopy.filter(function (progress) {
                        if (progress.progress_id === data.progress_id) {
                            progress.title = "Orders Transfer Complete";
                            progress.titleSummary = response.data.OrdersSuccessfullyTransferred + "/" + response.data.OrdersToTransfer + "  transferred. ";
                            progress.status = "success";
                        }
                    });
                    that.setState({
                        runnigProcesses: runningProcessCopy
                    });
                    let successMsg = that.translate('Label_Transfer_Order_Success')
                    if (data.data.numSelectedOrders> 1) {
                        successMsg = that.translate('Label_Transfer_Orders_Success')
                    }
                    GlobalService.notification('success', successMsg);
                    if (data.data.callBackFunc) {
                        data.data.callBackFunc();
                    }
                    that.errorProgressSet(data.progress_id, 2);
                } else {
                    setTimeout(function () {
                        that.ordersTransfer(data)
                    }, 3000);
                }
            }
        }, function error(err) {
            that.errorProgressSet(data.progress_id, 1);
            that.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", err);
            // GlobalService.apiFailer(error.response);
        });
    }

    // Generate pick ticket
    generatePickTicket = (data) => {
        var that = this;
        that.errorProgressSet(data.progress_id, 0);
        var uri = data.data.pickticket.uri;
        var printTicketMessage = 'Printing_Pick_Tickets_Successful';
        if (data.data.numSelectedOrders == 1) {
            printTicketMessage = 'Printing_Pick_Ticket_Successful';
        }
        if(data.data.pickticket.ticketType == 'master'){
            printTicketMessage = 'Printing_Mass_Pick_Tickets_Successful';
        }
        this.setState({
            printTicketMessage: printTicketMessage
        })
        //call api for StatusRelativeUri
        progressService.apiCallMassProgress(uri).then(function success(response) {

            if (response && response.status == 200) {
                if (response.data.Step1PdfUri) {
                    // call api for strp1PdfUri
                    // on success open pdf in new tab or call for Step1PdfUri
                    axios.get('/WebUI/' + response.data.Step1PdfUri, { responseType: 'blob' }).then(function success(response) {

                        GlobalService.downloadFileBaseOnContentType(response.data, "application/pdf", "PickTicketReportPdf_" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + ".pdf", true, "openNewTab");
                        that.loadingProgressSet(data.progress_id, 50);
                        that.callStep2PrintedDatesUri(uri, data, response.data);

                    }, function error(err) {
                        that.errorProgressSet(data.progress_id, 1);
                        that.progressStatusSetError(data.progress_id);
                    });

                } else {
                    setTimeout(function () {
                        that.generatePickTicket(data)
                    }, 3000);
                }
            }
        }, function error(err) {
            that.errorProgressSet(data.progress_id, 1);
            that.progressStatusSetError(data.progress_id);
            // GlobalService.apiFailer(error.response);
            console.error("progress-holder ", err);
        });
    }
    // Void Shipping Label
    voidShippingLabel = (data) => {
        this.setState({selectedOrderData: data});
        this.errorProgressSet(data.progress_id, 0);
        var uri = data.data.relativeUri;
        //call api for StatusRelativeUri
        progressService.apiCallMassProgress(uri).then((response) => {

            if (response && response.status == 200) {

                var percentComplete = Math.floor(((response.data.TotalOrdersSucceeded / response.data.TotalOrders) * 100));
                this.loadingProgressSet(data.progress_id, percentComplete);
                if (response.data.Done) {
                    if(response.data.FaultedOrders && response.data.FaultedOrders.Faults.length) {
                        var tmpFaultsOrderIds = [];
                        for(var x = 0; x < response.data.FaultedOrders.Faults.length; x++) {
                            var entryInfoId = JSON.parse(response.data.FaultedOrders.Faults[x].EntryInfo);
                            if(entryInfoId) {
                                tmpFaultsOrderIds.push(entryInfoId.orderid);
                            }
                        }
                        if(tmpFaultsOrderIds.length) {
                            this.setState({
                                isOpenVoidLabelErrorModal: true,
                                faultsOrderIds: tmpFaultsOrderIds
                            });
                            return false;
                        }
                    }
                    GlobalService.notification('success', this.translate('Label_Smart_Parcel_Void'));
                    this.errorProgressSet(data.progress_id, 2);
                    this.loadingProgressSet(data.progress_id, 100);
                    // on success callback function called for refresh grid
                    data.data.callBackFunc();
                } else {
                    setTimeout(() => {
                        this.voidShippingLabel(data)
                    }, 3000);
                }
            }
        }, (err) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            // GlobalService.apiFailer(error.response);
            console.error("progress-holder ", err);
            this.setState({
                isOpenVoidLabelErrorModal: true
            });
        });
    }

    zplFormatedLabel = (data) => {
        this.errorProgressSet(data.progress_id, 0);
        var uri = data.data.relativeUri;
        let isMultiId = data.data.isMultiId;
        //call api for StatusRelativeUri
        progressService.apiCallMassProgress(uri).then((response) => {

            if (response && response.status == 200) {

                // var percentComplete = Math.floor(((response.data.TotalOrdersSucceeded / response.data.TotalOrders) * 100));
                this.loadingProgressSet(data.progress_id, 0);

                if (response.data.Done) {
                    GlobalService.notification('success', this.translate('Label_Pack_And_Ship_Success'));
                    this.errorProgressSet(data.progress_id, 2);
                    this.loadingProgressSet(data.progress_id, 100);
                    ProgressHelper.printZplFormatedLabel(response.data.Labels)
                    // Print Packing Slips
                    if(data.data.isIntegratedPackingSlips) {
                        findOrdersService.PackingSlipReportStarter(data.data.packingSlipsData).then((response) => {
                            if(response && response.status == 200) {
                                response.data.outputType = 'pdf';
                                response.data.type = "packingslips";
                                this.packingslips(response);
                            }
                        });
                    }
                } else {
                    setTimeout(() => {
                        this.zplFormatedLabel(data)
                    }, 3000);
                }
            }
        }, (err) => {
            if (isMultiId) {
                let eventItem = new CustomEvent("EventSmallParcelDataChanged", { detail: { "action": "update" } });
                document.dispatchEvent(eventItem);
            }
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            // GlobalService.apiFailer(error.response);
            console.error("progress-holder ", err);
        });
    }
    pdfFormatedLabel = (data) => {
        this.errorProgressSet(data.progress_id, 0);
        var uri = data.data.relativeUri;
        let isMultiId = data.data.isMultiId;
        //call api for StatusRelativeUri
        progressService.apiCallMassProgress(uri).then((response) => {

            if (response && response.status == 200) {

                this.loadingProgressSet(data.progress_id, 0);

                if (response.data.Done) {

                    if(response.data.Errors && response.data.Errors.Faults && response.data.Errors.Faults.length > 0) {

                        var _data = {
                            "Source": 1,
                            "ServerName": "3PL-QA-APP",
                            "Descriptions": [],
                            "ErrorClass": "Http",
                            "ErrorCode": response.data.Errors.Faults[0].WmsException.ErrorCode,
                            "type": "error"
                        };

                        _.map(response.data.Errors.Faults, function (x) {
                            _data.Descriptions.push({
                                "ErrorClass": "Http",
                                "ErrorCode": x.WmsException.ErrorCode,
                                "Descriptors": [x.WmsException.Message],
                                "Hint": null
                            });
                        });

                        GlobalService.showErrorMessage(_data);
                    }

					window.smallParcelPackNShipTransactionIds = window.smallParcelPackNShipTransactionIds.filter(function(value, index, arr){
						return value.StatusRelativeUri != response.data.StatusRelativeUri
					});
                    GlobalService.notification('success', this.translate('Label_Pack_And_Ship_Success'));
                    this.errorProgressSet(data.progress_id, 2);
                    this.loadingProgressSet(data.progress_id, 100);
                    // on success callback function called for refresh grid
                    ProgressHelper.printPdfFormatedLabel(response.data.Labels, data.data.pdfFileName)
                    // Print Packing Slips
                    if(data.data.isIntegratedPackingSlips) {
                        findOrdersService.PackingSlipReportStarter(data.data.packingSlipsData).then((response) => {
                            if(response && response.status == 200) {
                                response.data.outputType = 'pdf';
                                response.data.type = "packingslips";
                                this.packingslips(response);
                            }
                        });
                    }
                } else {
                    setTimeout(() => {
                        this.pdfFormatedLabel(data)
                    }, 3000);
                }
            }
        }, (err) => {
            if (isMultiId) {
                let eventItem = new CustomEvent("EventSmallParcelDataChanged", { detail: { "action": "update" } });
                document.dispatchEvent(eventItem);
            }
			window.smallParcelPackNShipTransactionIds = window.smallParcelPackNShipTransactionIds.filter(function(value, index, arr){
				return value.StatusRelativeUri != uri
			});
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            // GlobalService.apiFailer(error.response);
            console.error("progress-holder ", err);
        });
    }
    createEndOfDay = (data) => {
        this.errorProgressSet(data.progress_id, 0);
        var uri = data.data.relativeUri;

        //call api for StatusRelativeUri
        progressService.apiCallMassProgress(uri).then((response) => {

            if (response && response.status == 200) {

                this.loadingProgressSet(data.progress_id, 0);

                if (response.data.Done) {
                    if(response.data.Errors && response.data.Errors.Faults && response.data.Errors.Faults.length > 0) {

                        var _data = {
                            "Source": 1,
                            "ServerName": "3PL-QA-APP",
                            "Descriptions": [],
                            "ErrorClass": "Http",
                            "ErrorCode": response.data.Errors.Faults[0].WmsException.ErrorCode,
                            "type": "error"
                        };

                        _.map(response.data.Errors.Faults, function (x) {
                            _data.Descriptions.push({
                                "ErrorClass": "Http",
                                "ErrorCode": x.WmsException.ErrorCode,
                                "Descriptors": [x.WmsException.Message],
                                "Hint": null
                            });
                        });

                        GlobalService.showErrorMessage(_data);
                    }

					GlobalService.notification('success', this.translate('Label_Smart_Parcel_End_of_Day_Request'));
                    this.errorProgressSet(data.progress_id, 2);
                    this.loadingProgressSet(data.progress_id, 100);
                    // on success callback function called for refresh grid
                    console.log("apiCallMassProgress response",response);
                    ProgressHelper.printPdfFormatedLabel(response.data.Manifests, "'Manifest.pdf'");

                } else {

                    setTimeout(() => {
                        this.createEndOfDay(data)
                    }, 3000);

                }
            }
        }, (err) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            console.error("progress-holder ", err);
        });
    }
    callStep2PrintedDatesUri = (param_uri, data, pickTicketReportStatusResponse) => {
        var that = this;
        //call api for StatusRelativeUri
        if (pickTicketReportStatusResponse.Step2PrintedDatesUri) {
            that.pickTicketStep2PrintedDatesUri(pickTicketReportStatusResponse.Step2PrintedDatesUri, data.progress_id,data);
            return;
        }
        progressService.apiCallMassProgress(param_uri).then(function success(response) {
            if (response && (response.status == 200 || response.status == 204)) {
                if (response.data.Step2PrintedDatesUri) {
                    // call api for Step2PrintedDatesUri and on success shaw success notification
                    that.pickTicketStep2PrintedDatesUri(response.data.Step2PrintedDatesUri, data.progress_id,data);
                } else {
                    setTimeout(function () {
                        that.callStep2PrintedDatesUri(param_uri, data, '')
                    }, 3000);
                }
            }
        }, function error(err) {
            that.errorProgressSet(data.progress_id, 1);
            that.progressStatusSetError(data.progress_id);
            // GlobalService.apiFailer(error.response);
            console.error("progress-holder ", err);
        });
    }
    pickTicketStep2PrintedDatesUri = (Step2PrintedDatesUri, progress_id,data) => {
        var that = this;
        progressService.apiCallMassProgress(Step2PrintedDatesUri)
            .then(function success(response2) {
                if (response2 && response2.status == 204) {

                    // Print ticket success so we need to reload find order grid here
                    var eventItem = new CustomEvent("EventNewOrderData", {
                        detail: { "action": "update","source": data.data.pickticket.ticketType == 'regular' ? "pickTicketApiSuccess" : '' }
                    });
                    document.dispatchEvent(eventItem);
                    let runningProcessCopy = JSON.parse(JSON.stringify(that.state.runnigProcesses))
                    runningProcessCopy.filter(function (progress) {
                        if (progress.progress_id === progress_id) {
                            progress.status = "success";
                        }
                    });
                    that.setState({
                        runnigProcesses: runningProcessCopy
                    });
                    that.errorProgressSet(progress_id, 2);
                    that.loadingProgressSet(progress_id, 100);
                    GlobalService.notification('success', that.translate(that.state.printTicketMessage));
                }

            }, function error(err) {
                that.errorProgressSet(progress_id, 1);
                that.progressStatusSetError(progress_id);
                // GlobalService.apiFailer(error.response);
                console.error("progress-holder ", err);
            });
    }
    packingslips = (data) => {
        var that = this;
        that.errorProgressSet(data.progress_id, 0);
        var uri = data.data.StatusRelativeUri;
        progressService.apiCallMassProgress(uri).then(function success(response) {

            if (response && response.status == 200) {
                if (response.data.Step1AddrsGenUri) {
                    that.packingSlipsStep1AddrsGenUri(data, response.data);
                } else {
                    setTimeout(function () {
                        that.packingslips(data)
                    }, 3000);
                }
            }
        }, function error(err) {
            that.errorProgressSet(data.progress_id, 1);
            that.progressStatusSetError(data.progress_id);
            // GlobalService.apiFailer(error.response);
            console.error("progress-holder ", err);
        });
    }
    packingSlipsStep1AddrsGenUri = (packingSlipsData, packingSlipStep0Response) => {
        var that = this;

        //call api for step1AddrsGenUri
        progressService.apiCallMassProgress(packingSlipStep0Response.Step1AddrsGenUri)
            .then(function success(response2) {

                if (response2 && response2.status == 204) {

                    that.loadingProgressSet(packingSlipsData.progress_id, 50);
                    GlobalService.notification('success', that.translate('Packing_Slip_Uri_Generated_Successful'));

                    that.packingSlipCallStep2OutputUri(packingSlipsData, packingSlipStep0Response);
                }

            }, function error(err) {
                that.errorProgressSet(packingSlipsData.progress_id, 1);
                that.progressStatusSetError(packingSlipsData.progress_id);
                // GlobalService.apiFailer(error.response);
                console.error("progress-holder ", err);
            });
    }
    packingSlipCallStep2OutputUri  = (packingSlipsData, packingSlipStep0Response) => {
        var that = this;

        //check Step2OutputUri available in response then call Step2OutputUri api
        if (packingSlipStep0Response.Step2OutputUri) {
            that.packingSlipsStep2OutputUri(packingSlipsData, packingSlipStep0Response);
            return;
        }
        //call api for StatusRelativeUri
        progressService.apiCallMassProgress(packingSlipStep0Response.StatusRelativeUri).then(function success(response) {

            if (response  && (response.status == 200 || response.status == 204)) {
                if(response.data.Step2OutputUri){
                    that.packingSlipsStep2OutputUri(packingSlipsData, response.data);
                } else {
                    setTimeout(function() {
                        that.packingSlipCallStep2OutputUri(packingSlipsData, packingSlipStep0Response)
                    }, 3000);
                }
            }
        }, function error(err) {
            that.errorProgressSet(packingSlipsData.progress_id, 1);
            that.progressStatusSetError(packingSlipsData.progress_id);
            // GlobalService.apiFailer(error.response);
            console.error("progress-holder ", err);
        });
    }

    packingSlipsStep2OutputUri = (packingSlipsData, packingSlipStep0Response) => {
        // call api for Step2OutputUri
        var that = this;
        // on success open pdf in new tab or call for Step2OutputUri
        if (packingSlipsData.data.outputType != 'pdf') {

            axios.get('/WebUI/' + packingSlipStep0Response.Step2OutputUri, { responseType: 'blob' }).then((response) => {

                console.log("response", response)
                GlobalService.downloadFileBaseOnContentType(response.data, packingSlipsData.data.outputType, "PackingSlips_" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + "." + packingSlipsData.data.outputType, true);
                that.errorProgressSet(packingSlipsData.progress_id, 2);
                that.loadingProgressSet(packingSlipsData.progress_id, 100);
            }, (err) => {
                that.errorProgressSet(packingSlipsData.progress_id, 1);
                that.progressStatusSetError(packingSlipsData.progress_id);
            });
        } else {
            axios.get('/WebUI/' + packingSlipStep0Response.Step2OutputUri, { responseType: 'blob' }).then((response) => {
                GlobalService.downloadFileBaseOnContentType(response.data, "application/pdf", "PackingSlips_" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + ".pdf", true, "openNewTab");
                that.errorProgressSet(packingSlipsData.progress_id, 2);
                that.loadingProgressSet(packingSlipsData.progress_id, 100);
            }, (err) => {
                that.errorProgressSet(packingSlipsData.progress_id, 1);
                that.progressStatusSetError(packingSlipsData.progress_id);
            });
        }

    }
    errorProgressSet = (id, value) => {
        console.log("errorProgressSet", id, value);

        let errorProgress = this.state.errorProgress;
        errorProgress[id] = value;
        this.setState({ errorProgress: errorProgress });
        if (value > 0) {
            this.updateIconStatus();
        }
    }

    loadingProgressSet = (id, value) => {
        console.log("loadingProgressSet", id, value);
        let loadingProgress = this.state.loadingProgress;
        // loadingProgress[id] = value;
        loadingProgress[id] = 45;
        this.setState({ loadingProgress: loadingProgress });
        if($("#" + id).data("kendoProgressBar")){
            $("#" + id).data("kendoProgressBar").value(value);
        }

    }

    progressStatusSetError = (id) => {
        let runningProcessCopy = JSON.parse(JSON.stringify(this.state.runnigProcesses))
        runningProcessCopy.filter(function (progress) {
            if (progress.progress_id === id) {
                progress.status = "error";
            }
        });
        this.setState({ runnigProcesses: runningProcessCopy }, this.updateIconStatus);
    }
    closeModal = () => {
        this.setState({
            isOpenProgress: false
        });
    }
    updateIconStatus = () => {
        var length = this.state.runnigProcesses.length;
        var color = "#fff";
        if (length > 0) {
            this.state.runnigProcesses.filter(function (progress) {
                if (progress.status == "error") {
                    color = "red";
                } else if (progress.status == "processing") {
                    color = "yellow";
                }
            });
        }
        this.$rootScope.changeColorOfProgress(color);
    }

    // Reallocation order
    reallocation = (data) => {
        this.errorProgressSet(data.progress_id, 0);
        var param_data = data.data.reallocation;
        //call api for StatusRelativeUri
        progressService.reallocateOrdersStarter(param_data.orderIds, param_data.facilityId).then((response) => {
            if (response && response.status == 200) {
                if (response.data.StatusRelativeUri) {
                    this.callStep2StatusReallocateOrdersStarter(response.data.StatusRelativeUri, data);
                } else {
                    setTimeout( () => {
                        this.reallocation(data)
                    }, 3000);
                }
            }
        },  (err) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            // GlobalService.apiFailer(error.response);
            console.error("progress-holder ", err);
        });
    }
    callStep2StatusReallocateOrdersStarter = (param_uri, data) => {

        progressService.callServiceOnUrl(param_uri).then((response) => {
            if (response && (response.status == 200 )) {
                var percentComplete = Math.floor(((response.data.TotalOrdersProcessed / response.data.TotalOrders) * 100));
                this.loadingProgressSet(data.progress_id, percentComplete);

                if (response.data.TotalOrders == response.data.TotalOrdersProcessed) {
                    if (data.data.callBackFunc) {
                        data.data.callBackFunc(response);
                    }
                } else {
                    setTimeout( () => {
                        this.callStep2StatusReallocateOrdersStarter(param_uri, data)
                    }, 3000);
                }
            }
        }, (err) => {
            this.errorProgressSet(data.progress_id, 1);
            this.progressStatusSetError(data.progress_id);
            // GlobalService.apiFailer(error.response);
            console.error("progress-holder ", err);
        });
    }
    /* istanbul ignore next */
    eventClickButton1 = (data) => {
        if (data.type == "import" || data.type == "importOrderAsync") {
            var batchName = data.response.BatchName;
            var customerId = data.data.customer_id;
            var facilityId = data.data.facilityId;

            $("#reactProgress").data("kendoWindow").close();
            progressService.viewOrdersOnFindOrders(batchName, customerId, facilityId).then((response) => {
                var eventItem = new CustomEvent("EventOpenFindOrders", {
                    detail: { "action": "open" }
                });
                document.dispatchEvent(eventItem);
            }).finally(() => { });
        }
    }
    eventClickButton2 = (data) => {
        if (data.type == "gridExport" || data.guide == '') {
            $("#reactProgress").data("kendoWindow").close();
            var fileUrl = data.gridExporterDownloadUrl + data.guide;
            progressService.downloadGridExportData(fileUrl).then((response) => {
                if (typeof response.data != 'undefined' && response.status == 200) {
                    var blob = new Blob([response.data], {
                        "type": "text/csv;charset=utf8;"
                    });

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, data.fileName + "-" + kendo.toString(new Date(), 'yyyyMMddhhmmss') + ".csv");
                    } else {
                        GlobalService.downloadFileBaseOnContentType(response.data, "text/csv;charset=utf8;", data.fileName + "-" + kendo.toString(new Date(), 'yyyyMMddhhmmss'));
                    }
                }
            }).finally(() => { });
        }
    }
    clearProcess = (progress) => {
        var array = [...this.state.runnigProcesses]; // make a separate copy of the array
        var index = array.indexOf(progress);
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ runnigProcesses: array }, this.updateIconStatus);
        }

    }
    clearAllProgress = () => {
        this.state.packageRestockAlertsData.map((alert, index) => {
            progressService.deletePackageRestockAlerts(alert.Uri).then((response) => { });
        });
        this.setState({
            runnigProcesses: [],
            packageRestockAlertsData: [],
        }, this.updateIconStatus);
    }
    onCloseVoidLabelErrorModal = () => {
        this.setState({
            isOpenVoidLabelErrorModal: false,
            faultsOrderIds: []
        });
    }
    onRemoveTrackingShipDate = () => {
        this.setState({ isButtonLoading: true });
        var selOrderIds = {"orderIds" : this.state.selectedOrderData.data.selectedOrderId};
        if(this.state.faultsOrderIds.length) {
            selOrderIds = {"orderIds" : this.state.faultsOrderIds};
        }
        smallParcelService.parcelVoidDbClear(selOrderIds).then((response) => {
            GlobalService.notification('success', this.translate('Label_Remove_Ship_Date'));
            this.setState({ isButtonLoading: false });
            // Close Voide Label Modal Dialog
            this.onCloseVoidLabelErrorModal();
            // on success callback function called for refresh grid
            this.state.selectedOrderData.data.callBackFunc();
        });
    }
    render() {
        return (
            <>
                {this.state.isOpenProgress && (
                <WmsModel
                    id={'reactProgress'}
                    title={this.translate('Label_Daily_Alerts') + (this.state.packageRestockAlertsData.length ? `(${this.state.packageRestockAlertsData.length})` : "")}
                    width={800}
                    height={(window.innerHeight - 245)}
                    onCloseFunc={this.closeModal}
                    isOpen={this.state.isOpenProgress}
                    class="react-progress-model-wrapper"
                    customClass='wms-model-new-model-wrapper'
                    actions={["Close"]}
                    >
                    <div className="model-content-wrapper">
                        <div className="wms-progress-running-process-info">
                        {this.state.runnigProcesses.length < 1 && this.state.packageRestockAlertsData.length < 1 &&
                            (
                                <div className="wms-notification">
                                    <div className="alert-msg" > {this.translate('Settings_Label_no_progress')} </div>
                                </div>
                            )
                        }
                        {this.state.runnigProcesses.length >= 1 &&

                            this.state.runnigProcesses.map((progress, index) => (
                                <div className="wms-notification" key={progress.progress_id}>
                                    <div className="progress-title-box">
                                        <div className={"title" + (this.state.errorProgress[progress.progress_id] == 1 ? ' progress-disabled' : '')} >
                                            <span className="progress-title">{progress.title} </span>
                                            {progress.titleSummary && (
                                                <span className="success-msg">({progress.titleSummary})</span>
                                            )}
                                            {this.state.errorProgress[progress.progress_id] == 1 && (
                                                <span className="wms-notification-icon">
                                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                </span>
                                            )}
                                        </div>
                                        <div className="wms-toolbar">
                                            {
                                                progress.button1Show && progress.showViewFindOrder == true && (
                                                    // <WmsButton
                                                    //     onClickFunc={(e) => this.eventClickButton1(progress)}
                                                    //     disabled={this.state.loadingProgress[progress.progress_id] !== 100}
                                                    //     icon="fa-search"
                                                    //     label={this.translate('Label_View_On_FindOrders')}
                                                    //     wmsSelector="progressViewOnFind"
                                                    // />
                                                    <div
                                                        className={"wms-toolbar-button" +
                                                            (this.state.loadingProgress[progress.progress_id] == 100 ? '' : ' k-state-disabled')
                                                        }
                                                        onClick={(e) => this.eventClickButton1(progress)}>
                                                        <div className="wms-sprite fa fa-search"></div>
                                                    </div>
                                                )
                                            }
                                            {
                                                progress.button2Show && (
                                                    // <WmsButton
                                                    //     onClickFunc={(e) => this.eventClickButton2(progress)}
                                                    //     disabled={this.state.loadingProgress[progress.progress_id] !== 100}
                                                    //     icon="fa-search"

                                                    //     label={this.translate('Label_Download_File')}

                                                    // />
                                                    <div
                                                        className={"wms-toolbar-button" +
                                                            (this.state.loadingProgress[progress.progress_id] == 100 ? '' : ' k-state-disabled')
                                                        }
                                                        onClick={(e) => this.eventClickButton2(progress)}
                                                        id={"downloadFile_" + progress.progress_id}>
                                                        <i className="wms-sprite fa fa-arrow-circle-o-down"></i>
                                                    </div>
                                                )
                                            }
                                            {
                                                progress.button3Show && (
                                                    // <WmsButton
                                                    //     onClickFunc={(e) => this.clearProcess(progress)}
                                                    //     disabled={this.state.errorProgress[progress.progress_id] == 0}
                                                    //     icon="fa-minus"
                                                    //     label={this.translate('Label_Clear')}
                                                    //     buttonType="yellow"

                                                    // />
                                                    <div
                                                        className={"wms-toolbar-button" +
                                                            (this.state.errorProgress[progress.progress_id] == 0 ? ' k-state-disabled' : '')
                                                        }
                                                        onClick={(e) => this.clearProcess(progress)}
                                                    >
                                                        <i className="wms-sprite fa fa-trash-o"></i>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="box">
                                        <div className="wms-progess-bar-wrapper-main">
                                            <div className={"progess-bar" + (this.state.errorProgress[progress.progress_id] == 1 ? ' progress-disabled' : '')} id={progress.progress_id}></div>

                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                        <div className="wms-progress-notification-info">
                            {this.state.packageRestockAlertsData.map((alert, index) => (
                                <div className="wms-notification" key={"wms-notification-area"+index}>
                                    <div className="progress-title-box">
                                        <div className={"title" } >
                                            <span className="progress-title">Low Inventory Alert </span>
                                        </div>
                                    </div>
                                    <div className="progress-title-box">
                                        <div className="message">
                                            <span className="success-msg">{alert.Message}</span>
                                        </div>
                                        <div className="wms-toolbar">
                                            <div
                                                className={"wms-toolbar-button"}
                                                onClick={(e) => this.clearAlert(alert)}
                                            >
                                                <i className="wms-sprite fa fa-trash-o"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={this.translate('Label_Clear_all')}
                            icon='fa-minus'
                            buttonType="red"
                            wmsSelector="progressClearAll"
                            onClickFunc={this.clearAllProgress}
                        />
                        <WmsButton
                            label={this.translate('Label_Close')}
                            icon='fa-times-circle'
                            buttonType="yellow"
                            wmsSelector="progressClose"
                            onClickFunc={this.closeModal}
                        />
                    </div>
                </WmsModel>

                )}
                <WmsModel
                    id={'voidLabelErrorModal'}
                    isOpen={this.state.isOpenVoidLabelErrorModal}
                    title={'<i class="fa fa-exclamation-triangle"></i> ' + this.translate("Label_Error_Void_Labels")}
                    width={400}
                    height={175}
                    minHeight={295}
                    onCloseFunc={this.onCloseVoidLabelErrorModal}
                    customClass='wms-model-new-model-wrapper'
                    actions={["Close"]}
                >
                    <div className='model-content-wrapper'>
                        {this.translate("Label_Confirm_Text_Void_Labels")}
                    </div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={window.translate("Label_Close")}
                            wmsSelector={"voidLabelErrorModal"}
                            className="cancel-button"
                            onClickFunc={this.onCloseVoidLabelErrorModal}
                            disabled={false}
                            loading={false}
                            buttonType="orange"
                        />
                        <WmsButton
                            label={window.translate("Label_Tracking_Ship_Date")}
                            wmsSelector={"removeTrackingShipDate"}
                            onClickFunc={this.onRemoveTrackingShipDate}
                            loading={this.state.isButtonLoading}
                        />
                    </div>
                </WmsModel>
                {this.state.showNewMessageModel && (
                <WmsModel
                    id={'newMessageAlertModel'}
                    isOpen={this.state.showNewMessageModel}
                    title={'<i class="fa fa-exclamation-triangle"></i> ' + this.translate("Label_New_Messages")}
                    width={400}
                    height={100}
                    minHeight={200}
                    onCloseFunc={this.onCloseNewMessageAlertModel}
                    customClass='wms-model-new-model-wrapper mini-model'
                    actions={["Close"]}
                >
                    <div className='model-content-wrapper'>
                        You have {this.state.packageRestockAlertsData.length} messages to review.
                    </div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={window.translate("Label_Close")}
                            wmsSelector={"NewMessageModelClose"}
                            className="cancel-button"
                            onClickFunc={this.onCloseNewMessageAlertModel}
                            disabled={false}
                            loading={false}
                            buttonType="orange"
                        />
                        <WmsButton
                            label={window.translate("Label_Open_Alerts")}
                            wmsSelector={"NewMessageModelOpen"}
                            onClickFunc={this.onOpenNewMessageAlertModel}
                            loading={false}
                        />
                    </div>
                </WmsModel>
                )}
            </>
        );
    }
}