export const getConfigurePackingSlipsColumns = () => {

  return ({

    columns: [{
      field: "Description",
      title: "Description",
      width: 120,
      wmsid: 9,
      lockable: false,
      headerAttributes: {
        "data-wms-selector": 'ConfigurePackingSlipsDescription'
      },

    }
    ]
  })
}

export const getConfigurePackingSlipsAllColumnList = () => {
  return ([{
    field: "Description",
    title: "Description",
    width: 120,
    wmsid: 9,
    lockable: false,
    headerAttributes: {
      "data-wms-selector": 'ConfigurePackingSlipsDescription'
    },

  }
  ])
}

export const getConfigurePackingSlipsSchema = () => {
  return ({
    model: {
      id: "rowNumber",
      fields: {
        Description: { type: "string" }
      }
    }
  })
}