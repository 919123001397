import React from 'react';

export const getGridDemoColumns = (Items_GridHeaders, CustomLabels) => {
    
    var translate = window.translate;
    return ({

        columns: [{
            field: "ItemId",
            title: CustomLabels.Label_Item + "Id",
            width: 120,
            hidden: true,
            wmsid: 1
        },
        {
            field: "Sku",
            title: CustomLabels.Label_Sku,
            width: 120,
            wmsid: 2
        },
        {
            field: "Status",
            title: Items_GridHeaders[2].description,
            width: 120,
            sortable: false,
            filterable: false,
            template: "#= IsActive == true ? '" + translate('Label_Active') + "' : '" + translate('Label_Inactive') + "' #",
            wmsid: 3
        },
        {
            field: "HasStorageRates",
            title: Items_GridHeaders[3].description,
            width: 120,
            sortable: false,
            filterable: false,
            wmsid: 4
        },
        {
            field: "Description",
            title: Items_GridHeaders[4].description,
            width: 120,
            filterable: true,
            wmsid: 5
        },
        {
            field: "IsAssembly",
            sortable: false,
            filterable: false,
            title: Items_GridHeaders[5].description,
            width: 120,
            wmsid: 6
        }]
    })
}


export const getGridDemoSchema = () => {
    return ({
        schema: {
            data: "Data",
            total: "Total",
            model: {
                id: "ItemId",
                fields: {
                    Sku: {
                        type: "string"
                    },
                    Status: {
                        type: "boolean"
                    },
                    HasStorageRates: {
                        type: "boolean"
                    },
                    Description: {
                        type: "string"
                    },
                    IsAssembly: {
                        type: "boolean"
                    }
                }
            }
        }
    })
}

export const getGridDemoTransport = (customerUri) => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Items/Items/ItemsGridRead",
                type: "POST",
                data: {
                    customerUri: customerUri,
                },
                dataType: 'json'
            },
            destroy: {
                url: "/WebUI/Items/Items/ItemsGridRead",
                type: "GET",
                dataType: "JSON"
            },
            parameterMap: function (options, operation) {
                if (operation !== "read" && options.models) {
                    return {
                        models: JSON.stringify(options.models)
                    };
                }
            },
        },
    })
}

export const getCopyItemGridColumns = (CustomLabels) => {
    
    var translate = window.translate;
    return ({
        columns: [{
            field: "ItemId",
            title: CustomLabels.Label_Item + "Id",
            width: 120,
            hidden: true,
            wmsid: 1
        },
        {
            field: "Sku",
            title: translate('Label_Sku'),
            width: 120,
            wmsid: 2
        }, {
            field: "Description",
            title: translate('Label_Description'),
            width: 120,
            wmsid: 3
        }, {
            field: "CountingMechanism",
            title: translate('Label_Counting_Mechanism'),
            width: 120,
            wmsid: 4
        }]
    })
}
export const getCopyItemGridSchema = () => {
    return ({
        schema: {
            data: "Data",
            total: "Total",
            model: {
                id: "ItemId",
                fields: {
                    Sku: {
                        type: "string"
                    },
                    Description: {
                        type: "string"
                    },
                    CountingMechanism: {
                        type: "string"
                    }
                }
            }
        }
    })
}
export const getCopyItemGridTransport = (customerUri) => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Items/Items/ItemsThatCanBeCopiedFrom",
                type: "POST",
                data: {
                    customerUri: customerUri,
                },
                dataType: 'json'
            },
            destroy: {
                url: "/WebUI/Items/Items/ItemsThatCanBeCopiedFrom",
                type: "GET",
                dataType: "JSON"
            },
            parameterMap: function (options, operation) {
                if (operation !== "read" && options.models) {
                    return {
                        models: JSON.stringify(options.models)
                    };
                }
            },
        },
    })
}
export const getStorageChargeGridColumns = () => {
    return ({

        columns: [{
                field: "RowNumber",
                title: "#",
                hidden: true,
                menu: false,
                width: "100%"
            },{
                field: "FacilityName",
                title: "Warehouse",
                lockable: false,
                width: "100%",
                template: '#if(!inventoryUnitsPerStorageUnit) {# #=FacilityName# <i class="facility-exclamator fa fa-exclamation-triangle"></i> #} else{# #=FacilityName# #}#'
            },
            {
                field: "unitOfMeasureName",
                title: "UOM",
                lockable: false,
                width: "100%"
            },
            {
                field: "inventoryUnitsPerStorageUnit",
                title: "# OF UNITS",
                lockable: false,
                width: "100%"
            },
            {
                field: "fullRate",
                title: "SPLIT (FULL)",
                lockable: false,
                width: "100%"
            },
            {
                field: "halfRate",
                title: "SPLIT (Half)",
                lockable: false,
                width: "100%"
            },
            {
                field: "freeDays",
                title: "Free Days",
                lockable: false,
                width: "100%"
            },
            {
                field: "receiverHandlingRate",
                title: "RCV Handle",
                lockable: false,
                width: "100%"
            }
        ]
    })
}
export const getStorageChargeGridSchema = () => {
    return ({
        schema: {
            data: "Data",
            total: "Total",
            model: {
                fields: {
                    FacilityName: {
                        type: "string",
                        editable: false
                    },
                    unitOfMeasureName: {
                        type: "string",
                        editable: false
                    },
                    inventoryUnitsPerStorageUnit: {
                        type: "number",
                        editable: false
                    },
                    fullRate: {
                        type: "number",
                        editable: false
                    },
                    halfRate:{
                        type: "number",
                        editable: false
                    },
                    freeDays:{
                        type: "number",
                        editable: false
                    },
                    receiverHandlingRate:{
                        type: "number",
                        editable: false
                    }
                }
            }
        }
    })
}