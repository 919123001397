import React from 'react';
var axios = require('axios');

import * as GlobalService from './../global_services';

export const getReports = function (customerId) {
    var custId = customerId === 0 ? null : customerId;

    return axios.get('/WebUI/Receiving/FindReceivings/GetReports?customerid=' + custId)
        .then(function (response) {
            var tempData = response.data;
            return tempData;
        });
}

