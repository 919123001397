let align_center = "text-align:center;";
let movable_unit = "Movable Unit";
export const getAdjustInventoryColumns = (GridHeaders) => {
    return {
        columns: [
            {
                field: "RowNumber",
                title: "#",
                width: 60,
                wmsid: 1,
                hidden: false,
                locked: true,
                lockable: false,
                resizable: false,
                sortable: false,
                reorderable: false,
                filterable: false,
                attributes: { style: align_center },
                headerAttributes: { style: align_center },
            },
            {
                field: "AdjustmentId",
                title: GridHeaders[0].description,
                wmsid: 2,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "AdjustInventoryTransactionId",
                },
            },
            {
                field: "ReferenceNum",
                title: GridHeaders[1].description,
                wmsid: 3,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "AdjustInventoryReference",
                },
            },
            {
                field: "ProcessDate",
                title: GridHeaders[2].description,
                wmsid: 5,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "AdjustInventoryAdjustmentDate",
                },
                template: "#= ProcessDate != null ? kendo.toString(kendo.parseDate(ProcessDate), 'MM/dd/yyyy')  : '' #",
            },
            {
                field: "CustomerIdentifier.Name",
                title: GridHeaders[3].description,
                wmsid: 6,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "AdjustInventoryCustomer",
                },
            },
            {
                field: "FacilityIdentifier.Name",
                title: GridHeaders[4].description,
                wmsid: 7,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "AdjustInventoryFacility",
                },
            },
        ],
    };
};

export const getAdjustInventoryAllColumnList = (GridHeaders) => {
    return [
        {
            field: "RowNumber",
            title: "RowNumber",
            width: 60,
            wmsid: 1,
            hidden: false,
            locked: true,
            lockable: false,
            resizable: false,
            sortable: false,
            reorderable: false,
            filterable: false,
            attributes: { style: align_center },
            headerAttributes: { style: align_center },
        },
        {
            field: "AdjustmentId",
            title: GridHeaders[0].description,
            wmsid: 2,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "AdjustInventoryTransactionId",
            },
        },
        {
            field: "ReferenceNum",
            title: GridHeaders[1].description,
            wmsid: 3,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "AdjustInventoryReference",
            },
        },
        {
            field: "ProcessDate",
            title: GridHeaders[2].description,
            wmsid: 5,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "AdjustInventoryAdjustmentDate",
            },
            template: "#= ProcessDate != null ? kendo.toString(kendo.parseDate(ProcessDate), 'MM/dd/yyyy')  : '' #",
        },
        {
            field: "CustomerIdentifier.Name",
            title: GridHeaders[3].description,
            wmsid: 6,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "AdjustInventoryCustomer",
            },
        },
        {
            field: "FacilityIdentifier.Name",
            title: GridHeaders[4].description,
            wmsid: 7,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "AdjustInventoryFacility",
            },
        },
    ];
};

export const getAdjustInventorySchema = () => {
    return {
        model: {
            id: "RowNumber",
            fields: {
                RowNumber: { type: "number" },
                TransactionId: { type: "number" },
                AdjustmentId: { type: "number" },
                Reference: { type: "string" },
                AdjustmentDate: { type: "string" },
                Customer: { type: "string" },
                Facility: { type: "string" },
            },
        },
    };
};

export const getAdjustmentItemsGridColumns = () => {
    return [
        {
            field: "SKU",
            title: "SKU",
            width: 100,
        },
        {
            field: "Qty",
            title: "QTY",
            width: 50,
        },
        {
            field: "LotNumber",
            title: "Lot Number",
            width: 120,
        },
        {
            field: "Location",
            title: "Location",
            width: 100,
        },
        {
            field: "SerialNumber",
            title: "Serial #",
            width: 120,
        },
        {
            field: "ExpirationDate",
            title: "Exp. Date",
            width: 160,
        },
        {
            field: "MovableUnitLabel",
            title: movable_unit,
            width: 160,
        },
        {
            field: "",
            title: "",
            width: 50,
            template:
                "<a href='javascript: void(0);' data-id='#=data.rowIndex#' class='remove-adjustment-item' data-wms-selector='packAndShipTransactionDeletePackage'><span class='fa fa-trash-o'></span></a>",
        },
    ];
};
export const getAdjustmentItemsGridSchema = () => {
    return {
        data: [],
        schema: {
            model: {
                fields: {
                    SKU: { type: "string" },
                    QTY: { type: "string" },
                    LotNumber: { type: "string" },
                    Location: { type: "string" },
                    MovableUnitLabel: { type: "string" },
                },
            },
        },
    };
};

export const getAvailableReceiveItemGridColumns = () => {
    return [
        {
            field: "ReceiptDate",
            title: "Received",
            template:
                "#= ReceiptDate != null ? kendo.toString(kendo.parseDate(ReceiptDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            width: 100,
        },
        {
            field: "ReceiverId",
            title: "Transaction #",
            width: 140,
        },
        {
            field: "ExpirationDate",
            template:
                "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MM/dd/yyyy') : '' #",
            title: "Exp. Date",
            width: 100,
        },
        {
            field: "MovableUnitLabel",
            title: movable_unit,
            width: 130,
        },
        {
            field: "LocationName",
            title: "Location",
            width: 100,
        },
        {
            field: "PrimaryAvailable",
            title: "Available",
            width: 100,
        },
        {
            field: "LotNumber",
            title: "Lot",
            width: 120,
        },
        {
            field: "SerialNumber",
            title: "Serial #",
            width: 140,
        },
        {
            field: "ReceiveItemId",
            title: "ReceiveItemId",
            hidden: true,
        },
    ];
};
export const getAvailableReceiveItemGridSchema = () => {
    return {
        data: [],
        schema: {
            model: {
                fields: {
                    ReceiptDate: { type: "date" },
                    ReceiverId: { type: "number" },
                    ExpirationDate: { type: "date" },
                    MovableUnitLabel: { type: "string" },
                    LocationName: { type: "string" },
                    PrimaryAvailable: { type: "number" },
                    LotNumber: { type: "string" },
                    SerialNumber: { type: "string" },
                    ReceiveItemId: { type: "string" },
                },
            },
        },
    };
};
export const getselectedReceivedItemGridColumns = () => {
    return [
        {
            field: "LocationName",
            title: "Location",
            width: 100,
        },
        {
            field: "ReceiverId",
            title: "Receiver Id",
            width: 100,
        },
        {
            field: "MovableUnitLabel",
            title: movable_unit,
            width: 100,
        },
        {
            field: "Allocated",
            title: "Allocated",
            width: 100,
        },
        {
            field: "ReceiveItemId",
            title: "ReceiveItemId",
            hidden: true,
        },
        {
            field: "",
            title: "",
            width: 35,
            template:
                "<a href='javascript: void(0);' data-id='#=data.rowIndex#' class='remove-selected-available-item' data-wms-selector='removeselected-available-item-for-reverse-adjustment'><span class='fa fa-trash-o'></span></a>",
        },
    ];
};
export const getselectedReceivedItemridSchema = () => {
    return {
        data: [],
        schema: {
            model: {
                fields: {
                    LocationName: { type: "string" },
                    ReceiverId: { type: "number" },
                    MovableUnitLabel: { type: "string" },
                    Allocated: { type: "number" },
                    ReceiveItemId: { type: "string" },
                },
            },
        },
    };
};

export const getCalculateChargesGridSchema = () => {
    return {
        schema: {
            model: {
                fields: {
                    ChargeOrigin: {
                        type: "number",
                        editable: false,
                    },
                    ChargeTypeDescription: {
                        type: "string",
                        editable: false,
                    },
                    ChargeLabel: {
                        type: "string",
                        editable: false,
                    },
                    ChargePerUnit: {
                        type: "number",
                        editable: true,
                    },
                    NumberOfUnits: {
                        type: "number",
                        editable: true,
                    },
                    TotalCharges: {
                        type: "number",
                        editable: false,
                    },
                    BSId: {
                        type: "string",
                        editable: false,
                    },
                    BSChargeType: {
                        type: "string",
                        editable: false,
                    },
                    BSPredefinedRateId: {
                        type: "string",
                        editable: false,
                    },
                },
            },
        },
    };
};

export const ChargesColumns = (CalculateCharges_GridHeaders, translate) => {
    // var translate = window.translate;
    return [
        {
            field: "ChargeOrigin",
            // title: CalculateCharges_GridHeaders[0].description,
            title: "",
            lockable: false,
            filterable: false,
            width: 160,
            template:
                "# if( ChargeOrigin== 0) {# " +
                translate("Label_Predefined_Required") +
                " #} else if( ChargeOrigin== 1) {# " +
                translate("Label_Billing_Script") +
                "  #} else if( ChargeOrigin== 2) {# " +
                translate("Label_Manual_Charge") +
                " #} else if( ChargeOrigin== 3) {# " +
                translate("Label_Predefined") +
                " #} #",
            wmsid: 1,
        },
        {
            field: "ChargeTypeDescription",
            title: CalculateCharges_GridHeaders[1].description,
            lockable: false,
            width: 160,
            template: "#= ChargeTypeDescription != null ? ChargeTypeDescription : '' #",
            wmsid: 2,
        },
        {
            field: "ChargeLabel",
            title: CalculateCharges_GridHeaders[2].description,
            lockable: false,
            width: 160,
            template: "#= ChargeLabel != null ? ChargeLabel : '' #",
            wmsid: 3,
        },
        {
            field: "ChargePerUnit",
            title: CalculateCharges_GridHeaders[4].description,
            lockable: false,
            width: 160,
            template: "#= ChargePerUnit != null ? ChargePerUnit : '' #",
            wmsid: 5,
        },
        {
            field: "NumberOfUnits",
            title: CalculateCharges_GridHeaders[5].description,
            lockable: false,
            width: 160,
            template: "#= NumberOfUnits != null ? NumberOfUnits : '' #",
            wmsid: 6,
        },
        {
            field: "TotalCharges",
            title: CalculateCharges_GridHeaders[6].description,
            lockable: false,
            width: 160,
            template: "#= TotalCharges != null ? TotalCharges : '' #",
            wmsid: 7,
        },
    ];
};
