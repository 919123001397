import React from 'react';

//component
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsInput from './../../../components/WmsInput/WmsInput.jsx';
import WmsModel from './../../../components/WmsModel/WmsModel.jsx';
import WmsContainer from './../../../components/WmsContainer/WmsContainer.jsx';

import * as GlobalService from './../../../global_services';
import './custom-order-info-modal.scss';
export class CustomOrderInfoModal extends React.Component {

    constructor(props) {
        super(props);
        
        this.translate = window.translate;
        this.state = {
            selector: "OrderCustomField",
            sawDiscardBtn: false,
            customFieldName: '',
            customFieldValue: '',

        };
    }
    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         customFieldName: '',
    //         customFieldValue: '',
    //         sawDiscardBtn: false
    //     });
    // }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            sawDiscardBtn: true
        });
    }


    addCustomOrderInfo = () => {
        let savedElementObj =JSON.parse(JSON.stringify(this.props.sharedOptions.OrderSE));

        let index = savedElementObj.filter((el) => {
            return el['Name'].toLowerCase() == this.state.customFieldName.toLowerCase();
        });

        if (index.length > 0) {
            GlobalService.notification("error", this.translate('Label_Unique_Value_Required') + ':', this.translate('Label_Name'));
            return;
        }
        savedElementObj.push({
            "Name": this.state.customFieldName,
            "Value": this.state.customFieldValue,
            "Required": false
        });

        let sharedOptionObj = Object.assign(
            {},
            this.props.sharedOptions,
            {
                'OrderSE': savedElementObj
            }
        );
        this.props.onSharedOptionDataSet(sharedOptionObj);
        this.props.onCloseAddCustomField();
    }

    render() {
        return (
            <WmsModel
                id={'customOrderinfo' + this.props.windowId}
                title={this.translate('Label_Custom_Order_Info')}
                class="customOrderinfo"
                width={400}
                height={280}
                windowId={this.props.windowId}
                onCloseFunc={this.props.onCloseAddCustomField}
                isOpen={this.props.sharedIsOpenAddCustomFieldModal}
                customClass='wms-model-new-ui-wrapper'>
                
                <div className="model-content-wrapper">
                <WmsContainer>
                <div className='input-components'>
                    <WmsInput
                        id='Name'
                        inputName='customFieldName'
                        wmsSelector={this.state.selector + 'Name'}
                        value={this.state.customFieldName}
                        onChangeFunc={this.handleChange}
                        required={true}
                        label={this.translate('Label_Name')} />

                    <WmsInput
                        id='Value'
                        inputName='customFieldValue'
                        wmsSelector={this.state.selector + 'Value'}
                        value={this.state.customFieldValue}
                        onChangeFunc={this.handleChange}
                        label={this.translate('Label_Value')} />
                </div>
                </WmsContainer>
                </div>
                <div className="footer-btn-holder">
                <WmsButton
                    label={this.translate('Label_Close')}
                    wmsSelector={this.state.selector + 'Exit'}
                    onClickFunc={this.props.onCloseAddCustomField}
                    buttonType={'orange'}
                    icon={'fa-times-circle-o'} />

                <WmsButton
                    label={this.translate('Label_Add')}
                    wmsSelector={this.state.selector + 'Add'}
                    onClickFunc={this.addCustomOrderInfo}
                    disabled={this.state.customFieldName == ''}
                    icon='fa-plus' />

                </div>
            </WmsModel>
        );
    }
}