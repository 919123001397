import React, { Fragment } from 'react';
import WmsButton from '../WmsButton/WmsButton.jsx';
import './version-checker.scss';
var axios = require('axios');
export default class VersionChecker extends React.Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.mainVersionCheckInterval = null;
        this.secondTimer = 0;
        this.minuteTimer = 0;
        this.releaseVersion = 0;
        this.state = {
            Selector: "VersionChecker",
            counterSeconds: 60,
            counterMinutes: 300,
            showFirstMessage: false,
            confirmationScreen: false,
            fiveMinutesTimerScreen: false,
            isVersionChange: false
        };
    }

    componentDidMount = () => {
        this.checkReleaseVersion();
        let vCheckTime = globalsMsg.versionCheckTime ? globalsMsg.versionCheckTime : 1000 * 60 * 5;
        this.mainVersionCheckInterval = setInterval(() => {
            this.checkReleaseVersion();
        }, vCheckTime);
    }

    checkReleaseVersion = () => {
        axios.get('env.json?v=' + Math.random()).then((response) => {
            if (this.releaseVersion) {
                if (response.data.timeStamp > this.releaseVersion) {
                    clearInterval(this.mainVersionCheckInterval);
                    this.startSecondTimer();
                    this.setState({
                        isVersionChange: true,
                        showFirstMessage: true
                    });
                }
            } else {
                this.releaseVersion = response.data.timeStamp;
            }
        })
    }

    onRefreshWindow = () => {
        window.location.reload();
    }

    onWaitFiveMinutes = () => {
        clearInterval(this.secondTimer); // clear old counter for seconds
        this.secondTimer = 0;
        // check confirmationScreen is set than we need to refresh after 5 minutes else give confirmation message
        if (this.state.confirmationScreen) {

            this.startMinutesTimer();
            this.setState({
                confirmationScreen: false,
                fiveMinutesTimerScreen: true
            })
        } else {
            this.setState({
                confirmationScreen: true,
                showFirstMessage: false,
                counterSeconds: 60
            }, this.startSecondTimer);// start new counter for seconds If no action is taken, automatically refresh in 1 minute.
        }
    }

    startSecondTimer = () => {
        if (this.secondTimer == 0 && this.state.counterSeconds > 0) {
            this.secondTimer = setInterval(this.secondCountDown, 1000);
        }
    }

    startMinutesTimer = () => {
        if (this.minuteTimer == 0 && this.state.counterMinutes > 0) {
            this.minuteTimer = setInterval(this.minutesCountDown, 1000);
        }
    }

    secondCountDown = () => {
        // Remove one second, set state so a re-render happens.
        let counterSeconds = this.state.counterSeconds - 1;
        this.setState({
            counterSeconds: counterSeconds,
        });

        // Check if we're at zero.
        if (counterSeconds <= 0) {
            clearInterval(this.secondTimer);
            this.onRefreshWindow();
        }
    }

    minutesCountDown = () => {
        // Remove one second, set state so a re-render happens.
        let counterMinutes = this.state.counterMinutes - 1;
        this.setState({
            counterMinutes: counterMinutes,
        });

        // Check if we're at zero.
        if (counterMinutes <= 0) {
            clearInterval(this.minuteTimer);
            this.onRefreshWindow();
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.isVersionChange && (
                    <div className="wms-version-checker-wrapper">
                        <div className="wms-version-checker-container">
                            {this.state.showFirstMessage && (
                                <Fragment>
                                    <p
                                        className='wms-version-notification'
                                        dangerouslySetInnerHTML={{ __html: this.translate('Label_version_notification').replace('{secondsVariable}', this.state.counterSeconds) }}>
                                    </p>
                                    <WmsButton
                                        label={this.translate("Label_Wait_5_Minutes")}
                                        wmsSelector={this.state.Selector + "ButtonWait5Minutes"}
                                        onClickFunc={this.onWaitFiveMinutes}
                                        icon="fa-stopwatch"
                                        buttonType="orange"
                                    />
                                    <WmsButton
                                        label={this.translate("Label_Refresh_Now")}
                                        wmsSelector={this.state.Selector + "ButtonRefreshNow"}
                                        onClickFunc={this.onRefreshWindow}
                                        icon="fa-redo-alt"
                                        buttonType="green"
                                    />
                                </Fragment>
                            )}
                            {this.state.confirmationScreen && (
                                <Fragment>
                                    <b>If you don't take any action then this page will automatically refresh after {this.state.counterSeconds} seconds.</b>
                                    <p
                                        className='wms-version-notification'
                                        dangerouslySetInnerHTML={{ __html: this.translate('Label_version_confirmation_message') }}>
                                    </p>
                                    <WmsButton
                                        label={this.translate("Label_No_Refresh_Now")}
                                        wmsSelector={this.state.Selector + "ButtonNoRefreshNow"}
                                        onClickFunc={this.onRefreshWindow}
                                        icon="fa-redo-alt"
                                        buttonType="green"
                                    />
                                    <WmsButton
                                        label={this.translate("Label_Yes_Wait_5_Minutes")}
                                        wmsSelector={this.state.Selector + "ButtonYesWait5Minutes"}
                                        onClickFunc={this.onWaitFiveMinutes}
                                        icon="fa-stopwatch"
                                        buttonType="orange"
                                    />
                                </Fragment>
                            )}
                            {this.state.fiveMinutesTimerScreen && (
                                <b className="wms-final-countdown">This page will automatically refresh in {this.state.counterMinutes} seconds.</b>
                            )}
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}