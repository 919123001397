import React from 'react';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsRadio from './../components/WmsRadio/WmsRadio.tsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';


import * as GlobalService from './../global_services';

export class WarehouseFormBasic extends React.Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;

        this.state = {
            CustomLabels: GlobalService.getCustomLabels(),
            Selector: 'Warehouse',
            options: {
                OptionsCountry: GlobalService.GetCountries(),
                OptionsSatate: [],
                stateRequired: true
            }
        }
    }

    componentDidMount() {
        this.setStatesAccordingToCountry(this.props.sharedWarehouse.Country);
    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.sharedWarehouse) {
            if (this.props.sharedWarehouse.Country !== nextprops.sharedWarehouse.Country) {
                this.setStatesAccordingToCountry(nextprops.sharedWarehouse.Country);
            }
        }
    }

    setStatesAccordingToCountry = (param_country) => {
        var newStateData = GlobalService.GetRegions(param_country);
        var stateRequired = false;
        if (newStateData.length > 1) { // check length greater than 1 because first options is unspecified
            stateRequired = true;
        }
        this.setState({
            options: Object.assign(
                {},
                this.state.options,
                { OptionsSatate: newStateData }
            ),
            stateRequired: stateRequired
        });
    }

    handleWBasicFormChanges = (event) => {
        if (typeof event.target == 'undefined') {
            return false;
        }
        const name = event.target.name;
        const value = event.target.value;
        let propFacility = this.props.sharedWarehouse;
        if(name == "Country") {
            propFacility.State = null;
            this.props.checkStateRequired(value);
        }
        let objFacility = Object.assign(
            {},
            propFacility,
            { [name]: name == 'Active' ? !propFacility.Active : value }
        )
        this.props.onWarehouseDataset(objFacility);
    };

    render() {
        const renderFacility = this.props.sharedWarehouse;
        // console.log("warehouse-form-basic : ", this.props);
        return (
            <div id={'CollapseWarehouseFormBasic'} className={"collapseWarehouseTabContent_wrapper"}>
                <WmsCollapse id={'ManageWarehouseFormBasic'} headerLabel={this.translate('Label_Contact_Information')} showCollapseText={false} showCollapseIcon={false}  >


                    <WmsInput
                        id='Name'
                        inputName='Name'
                        wmsSelector={this.state.Selector + 'Name'}
                        onChangeFunc={this.handleWBasicFormChanges}
                        label={this.state.CustomLabels.Label_Warehouse + ' Name'}
                        value={renderFacility.Name}
                        required={true} />

                    <WmsInput
                        id='Code'
                        inputName='Code'
                        wmsSelector={this.state.Selector + 'Code'}
                        onChangeFunc={this.handleWBasicFormChanges}
                        label={this.state.CustomLabels.Label_Warehouse + ' Code'}
                        value={renderFacility.Code} />

                    <WmsInput
                        id='Address1'
                        inputName='Address1'
                        wmsSelector={this.state.Selector + 'Address1'}
                        onChangeFunc={this.handleWBasicFormChanges}
                        label={this.translate('Label_Warehouse_Address1')}
                        required={true}
                        value={renderFacility.Address1} />

                    <WmsInput
                        id='Address2'
                        inputName='Address2'
                        wmsSelector={this.state.Selector + 'Address2'}
                        onChangeFunc={this.handleWBasicFormChanges}
                        label={this.translate('Label_Warehouse_Address2')}
                        value={renderFacility.Address2} />

                    <WmsDropdown
                        id="Country"
                        name="Country"
                        label={this.translate('Label_Warehouse_Country')}
                        wmsSelector={this.state.Selector + 'Country'}
                        onChangeFunc={this.handleWBasicFormChanges}
                        options={this.state.options.OptionsCountry}
                        valueField='CountryCode'
                        textField='Name'
                        required={true}
                        value={renderFacility.Country == null ? '' : renderFacility.Country} />

                    <WmsDropdown
                        id="State"
                        name="State"
                        label={this.state.CustomLabels.Label_State}
                        wmsSelector={this.state.Selector + 'State'}
                        onChangeFunc={this.handleWBasicFormChanges}
                        disabled={renderFacility.Country === '' ? true : false}
                        options={this.state.options.OptionsSatate}
                        valueField='RegionCode'
                        textField='Name'
                        required={this.state.stateRequired}
                        value={renderFacility.State == null ? '' : renderFacility.State} />

                    <WmsInput
                        id='City'
                        inputName='City'
                        wmsSelector={this.state.Selector + 'City'}
                        onChangeFunc={this.handleWBasicFormChanges}
                        label={this.state.CustomLabels.Label_City}
                        required={true}
                        value={renderFacility.City}
                        blankFirstOption={true} />

                    <WmsInput
                        id='Zip'
                        inputName='Zip'
                        wmsSelector={this.state.Selector + 'Zip'}
                        onChangeFunc={this.handleWBasicFormChanges}
                        label={this.state.CustomLabels.Label_Zip}
                        required={true}
                        value={renderFacility.Zip} />

                    <WmsDropdown
                        id="TimeZoneName"
                        name="TimeZoneName"
                        label={this.translate('Label_Warehouse_TimeZoneId')}
                        wmsSelector={this.state.Selector + 'TimeZoneName'}
                        value={renderFacility.TimeZoneName == null ? '' : renderFacility.TimeZoneName}
                        onChangeFunc={this.handleWBasicFormChanges}
                        options={this.props.shareWarehouseOptions.OptionsTimeZone}
                        valueField='Value'
                        textField='Name'
                        required={true}
                        blankFirstOption={true}
                        blankFirstOptionText={'Unspecified'}
                    />

                    <WmsInput
                        id='PhoneNumber'
                        inputName='PhoneNumber'
                        wmsSelector={this.state.Selector + 'PhoneNumber'}
                        onChangeFunc={this.handleWBasicFormChanges}
                        label={this.translate('Label_Warehouse_PhoneNumber')}
                        value={renderFacility.PhoneNumber} />

                    <WmsInput
                        id='Fax'
                        inputName='Fax'
                        wmsSelector={this.state.Selector + 'Fax'}
                        onChangeFunc={this.handleWBasicFormChanges}
                        label={this.translate('Label_Warehouse_Fax')}
                        value={renderFacility.Fax} />

                    <WmsInput
                        id='EmailAddress'
                        inputName='EmailAddress'
                        wmsSelector={this.state.Selector + 'Email'}
                        onChangeFunc={this.handleWBasicFormChanges}
                        label={this.translate('Label_Email')}
                        value={renderFacility.EmailAddress} />


                </WmsCollapse>
            </div>
        )
    }
}