
var axios = require('axios');
import * as GlobalService from './../global_services';
import * as metaDataService from './meta-data.service.js';

export const getitemsInitial = function () {

    return axios.all([
        axios.get('/WebUI/Items/Items/Options'),
        axios.get('/WebUI/Items/Items/SearchInfo'),
        axios.get('/WebUI/Items/Items/StorageCountScripts'),
        GlobalService.GetCustomersAndFacility(),
        metaDataService.getItemClientMetaData()
    ]).then(axios.spread(function (optionResponse, searchInfoResponse, recurringStorageResponse) {
        optionResponse['searchInfo'] = searchInfoResponse;
        optionResponse['customerList'] = GlobalService.getCustomerList(false);
        optionResponse['recurringStorageResponse'] = { optionCountingMechanism: [] };
        if (recurringStorageResponse.status == 200) {
            var reformatedArray = recurringStorageResponse.data.map(obj => {
                var rObj = {};
                rObj = obj.StorageCountScriptTemplateIdentifier;
                return rObj;
            });

            var tmpRecurringStorageRes = {};
            tmpRecurringStorageRes.StorageCountScriptsArr = recurringStorageResponse.data;
            tmpRecurringStorageRes.optionCountingMechanism = reformatedArray;
            optionResponse['recurringStorageResponse'] = tmpRecurringStorageRes;
        }
        return optionResponse;
    }));
}
export const getCreateEditItemsInitial = function (itemUri) {
    return axios.get('/WebUI/Items/Items/Options?itemUri=' + itemUri).then(function (response) {
        return response;
    });
}
export const updateItemsActivation = function (customerId, list, mybool) {
    var data = {
        customerId: customerId,
        activate: mybool,
        itemIds: list
    };
    return axios.put('/WebUI/Items/Items/Activate', data).then(function (response) {
        return response;
    });
}
export const deleteItem = function (itemUri) {
    var data = {
        itemUri: itemUri
    };
    return axios.delete('/WebUI/Items/Items/Item', { data: data }).then(function (response) {
        return response;
    });
}

/*export const getLocationDropdownRead = function (facilityId) {
    let data = {
        sort: '',
        page: 1,
        pageSize: 50,
        group: '',
        filter: '',
        facilityId: facilityId,
    }
    return axios.post('/WebUI/items/items/LocationDropDownRead', data).then(function (response) {
        return response;
    });
}*/

export const saveNewItems = function (itemsData, customerId, ignoreDuplicateUpc) {
    let payLoad = {
        itemView: itemsData,
        customerId: customerId
    }
    if (ignoreDuplicateUpc) {
        payLoad.ignoreDuplicateUpc = ignoreDuplicateUpc;
    }
    return axios.post('/WebUI/Items/Items/Item', payLoad).then(function (response) {
        return response.data;
    });
}

export const updateExistingItems = function (itemsData, itemUri, ignoreDuplicateUpc) {
    let payLoad = {
        itemView: itemsData,
        itemUri: itemUri
    }
    if (ignoreDuplicateUpc) {
        payLoad.ignoreDuplicateUpc = ignoreDuplicateUpc;
    }
    return axios.put('/WebUI/Items/Items/Item', payLoad).then(function (response) {
        return response.data;
    });
}

export const storageRateSetup = function (itemRateUri) {
    return axios.get('/WebUI/Items/Items/storageratesetup?itemRatesUri=' + itemRateUri).then(function (response) {
        return response;
    });
}

export const storageRateCharges = function (param_query_string, param_payload) {
    return axios.put('/WebUI/Items/Items/StorageRateCharges?' + param_query_string,
        param_payload).then(function (response) {
            return response;
        });
}
export const storageRatesGroup = function (param_payload) {
    return axios.put('/WebUI/items/items/StorageRatesGroup', { "update": param_payload }).then(function (response) {
        return response;
    });
}
export const getDefaultStorageHandlingCharges = function (customerId) {
    return axios.get('/WebUI/Items/Items/DefaultStorageHandlingCharges?customerId=' + customerId).then(function (response) {
        return response;
    });
}
export const storageHandlingCharges = function (itemUri, payload) {
    payload = { 'rateSetup': payload }
    return axios.put('/WebUI/Items/Items/StorageHandlingCharges?itemRatesUri=' + itemUri, payload).then(function (response) {
        return response;
    });
}
export const exportAllBatchItems = function (params) {
    return axios.put('/WebUI/Items/BatchItemUpdate/ExportAllBatchItems', params).then(function (response) {
        return response;
    });
}
export const exportSelectedBatchItems = function (params) {
    return axios.put('/WebUI/Items/BatchItemUpdate/ExportSelectedBatchItems', params).then(function (response) {
        return response;
    });
}
export const ValidateUpdateBatchItemStarter = function (params) {
    return axios.put('/WebUI/Items/BatchItemUpdate/ValidateUpdateBatchItemStarter', params).then(function (response) {
        return response;
    });
}
export const callServiceOnUri = function (param_uri) {
    return axios.get('/WebUI/' + param_uri).then(function (response) {
        return response;
    });
}
export const UpdateBatchItemStarter = function (params) {
    return axios.put('/WebUI/Items/BatchItemUpdate/UpdateBatchItemStarter', params).then(function (response) {
        return response;
    });
}