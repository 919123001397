import React, { useState } from "react";
import SmallParcelHVPGrid from "./components/grid/small-parcel-hvp-grid-component";
import SmallParcelHVPSearch from "./components/search/small-parcel-hvp-search.component";
import type { GridFilter } from "./components/grid/small-parcel-hvp-grid_configuration.virtual";
import "./smallParcelHVPSuit.scss";

export function SmallParcelHVPHolder(props) {
    /**
     * Constants and Globals
     */
    const windowId = (window as any).ChildBulletin.Data.Window; //used to store the tab index;
    const smallParcelHVPGridId = "SmallParcelHVPRemoteGrid";
    const filterStartDate = "today~00:00:00";
    const filterEndDate = "today~23:59:59";
    const defaultFilters: GridFilter[] = [
        {
            field: "startDate",
            operator: "gte",
            value: filterStartDate,
        },
        {
            field: "endDate",
            operator: "lte",
            value: filterEndDate,
        },
    ];

    /**
     * States
     */

    const [filterAreaToggle, setFilterAreaToggle] = useState(false);
    const [isAnimatedClassAdded, setIsAnimatedClassAdded] = useState(false);
    const [filterValues, setFilterValues] = useState<GridFilter[]>(defaultFilters);

    /**
     * Functions
     */

    const handleClickForFilterToggle = () => {
        setFilterAreaToggle(!filterAreaToggle);
        setIsAnimatedClassAdded((prevState) => !prevState);
        setTimeout(() => {
            setIsAnimatedClassAdded((prevState) => !prevState);
            $("#" + smallParcelHVPGridId)
                .data("kendoGrid")
                .refresh();
            $(window).trigger("resize");
        }, 1000);
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFilterData: GridFilter = {
            field: targetName,
            operator: "contains",
            value: targetValue,
            inputValue: targetValue,
        };

        if (targetName === "Status" && targetValue === "Open") {
            newFilterData.operator = "doesnotcontain";
            newFilterData.value = "Closed";
            newFilterData.inputValue = "Open";
        }

        if (targetName === "startDate" && !targetValue) {
            newFilterData.field = "startDate";
            newFilterData.operator = "gte";
            newFilterData.value = filterStartDate;
        }

        if (targetName === "endDate" && !targetValue) {
            newFilterData.field = "endDate";
            newFilterData.operator = "gte";
            newFilterData.value = filterEndDate;
        }

        if (targetName === "ExternalBatchId" && targetValue) {
            newFilterData.field = "ExternalBatchId";
            newFilterData.operator = "contains";
            newFilterData.value = targetValue;
        }

        if (targetName === "BatchId" && targetValue) {
            newFilterData.field = "BatchId";
            newFilterData.operator = "contains";
            newFilterData.value = targetValue;
        }

        if (targetName === "Customer" && targetValue) {
            newFilterData.field = "Customer";
            newFilterData.operator = "contains";
            newFilterData.value = targetValue;
        }

        if (targetName === "Facility" && targetValue) {
            newFilterData.field = "Facility";
            newFilterData.operator = "contains";
            newFilterData.value = targetValue;
        }

        if (targetName === "Status" && targetValue) {
            newFilterData.field = "Status";
            newFilterData.operator = "contains";
            newFilterData.value = targetValue;
        }

        const previousFilters = filterValues.filter((filter) => {
            return filter.field !== newFilterData.field;
        });
        setFilterValues([...previousFilters, newFilterData]);
    };

    const handleClearFilters = () => {
        setFilterValues([
            {
                field: "startDate",
                operator: "gte",
                value: "",
            },
            {
                field: "endDate",
                operator: "lte",
                value: "",
            },
        ]);
    };

    return (
        <div
            id="wms-smallParcel-hvp-virtual-wrapper"
            className={
                (filterAreaToggle ? " collapsedFilter" : " expandFilter") +
                (isAnimatedClassAdded ? " animatedCollapse" : "") +
                " small-Parcel-holder new-layout-grid-filter-wrapper"
            }
        >
            <>
                <SmallParcelHVPSearch
                    onClickForFilterToggle={handleClickForFilterToggle}
                    filters={filterValues}
                    onFilterChange={handleFilterChange}
                    onClearFilters={handleClearFilters}
                />
                <SmallParcelHVPGrid windowId={windowId} filters={filterValues} />
            </>
        </div>
    );
}
