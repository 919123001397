
import React from 'react';

import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

import './themes.scss'
var colorVariableList;
export class HeaderColorComponent extends React.Component {
    constructor(props) {
        super(props);
        colorVariableList = {
            "primary-white": { "val": "#ffffff", "isOpen": false },
            "primary-black": { "val": "#000000", "isOpen": false },
            "green-menu": { "val": "#83de52", "isOpen": false }
        };

        this.state = {
            displayColorPicker: false,
            color: "#fff",
            anotherObj: colorVariableList,
        }
    }
    handleClick = (name) => {
        var steteAssign = this.state.anotherObj;
        steteAssign[name]['isOpen'] = !steteAssign[name]['isOpen'];
        this.setState({ anotherObj: steteAssign })
    };

    handleClose = (name) => {
        var steteAssign = this.state.anotherObj;
        steteAssign[name]['isOpen'] = false;
        this.setState({ anotherObj: steteAssign })
    };

    handleChange = (color, name) => {
        // console.log("handle On change :", color, name);
        document.documentElement.style.setProperty('--' + name, color.hex);
        // this.setState({ color: color.hex })

        var steteAssign = this.state.anotherObj;
        steteAssign[name]['val'] = color.hex;
        this.setState({ anotherObj: steteAssign })
    };

    render() {
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px'
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        var anotherObj = this.state.anotherObj;
        return (
            <div className="headerColor">
                <h3><center>Header Color</center></h3>
                {Object.keys(anotherObj).map((format, key) => (
                    <li className="theme-var-list" key={key}>
                        <div className="input-label">{format}</div>
                        <div style={styles.swatch} onClick={() => this.handleClick(format)}>
                            <div style={{ background: anotherObj[format]['val'], width: "36px", height: "14px", borderRadius: "2px" }} />

                        </div>
                        {
                            anotherObj[format]['isOpen'] ? <div style={styles.popover}>
                                <div style={styles.cover} onClick={() => this.handleClose(format)} />
                                <SketchPicker color={anotherObj[format]['val']} onChange={(e) => this.handleChange(e, format)} />
                            </div> : null
                        }
                    </li>
                ))}
            </div>
        )
    }
}