import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import WmsInput from '../components/WmsInput/WmsInput';
import WmsFile from '../components/WmsFile/WmsFile';
import WmsButton from '../components/WmsButton/WmsButton';
import './customerPackagingSlips.scss';
import * as GlobalService from './../global_services';
import _ from 'lodash';
import './customerPackagingSlips.scss';

interface Props {
    wmsSelector: string,
    oldValue?: any,
    fieldName: string,
    caption: string,
    changeImageField: (imgUrl: string, fieldName: string) => void,
    className?: string,
    setValidImageUrl?: (isValid: boolean) => void,
    maxWidth: number,
    maxHeight: number,
    onDeleteDefault?: string
}
const defaultFileObject = {
    fileName: '',
    fileExtension: '',
    FileSize: 0,
    fileResolution: {width: 0, height: 0}
}

declare var window: any;
const { $ } = window;

const CustomerPackingSlipsTemplateImage: React.FC<Props> = (props: Props): any => { // eslint-disable-line sonarjs/cognitive-complexity

    const [imagePreview, setImagePreview] = useState(props.oldValue);
    const [imageFileObject, setImageFileObject] = useState(defaultFileObject);
    const [isFileDrage, setFileDrage] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [isImageUrlValid, setIsImageUrlValid] = useState(true);
    const onDeleteDefault = props.onDeleteDefault ? props.onDeleteDefault : "assets/images/Default_image.png";

    useEffect(()=> {
        var btn = document.getElementById(props.wmsSelector + 'imageDragFile');
        btn.ondrag = (e) => {
            console.log("drag event called")
        };
    },[props.wmsSelector]);

    const handleFileChange = (param_fileobj) => {
        setImageUrl('');
        setImagePreview(param_fileobj.previewUrl);
        setImageFileObject(param_fileobj);
        var imgUrl = "." + param_fileobj.fileExtension + ':' + param_fileobj.FileContents.split(',').pop();
        props.changeImageField(imgUrl, props.fieldName);
        props.setValidImageUrl(true);
        setIsImageUrlValid(true);
        props.setValidImageUrl(true);
    }
    const onDragOver = (e, fileId) => {
        setFileDrage(true);
    }
    const onDragStop = (e, fileId) => {
        setFileDrage(false);
    }
    const onChangeImageUrl = (e) => {
        let imageUrl = e.target.value;
        setImageUrl(imageUrl);
        setImageFileObject(defaultFileObject);
        $("#" + props.wmsSelector + 'imageDragFile').val('');
        $("#" + props.wmsSelector + 'headerFile').val(''); 
        if(imageUrl !== ''){
            setImagePreview(imageUrl);
            props.changeImageField(imageUrl, props.fieldName);
        }else{
            props.changeImageField(props.oldValue, props.fieldName);
            setImagePreview(props.oldValue);
        }
        setIsImageUrlValid(true);
        props.setValidImageUrl(true);
    }
    const onDeleteUrl = () => {
        props.changeImageField(null, props.fieldName);
        setImagePreview(onDeleteDefault);
        setImageFileObject(defaultFileObject);
        setImageUrl('');
        setIsImageUrlValid(true);
        props.setValidImageUrl(true);
        $("#" + props.wmsSelector + 'imageDragFile').val('');
        $("#" + props.wmsSelector + 'headerFile').val('');
    }
    const onResetUrl = () => {
        props.changeImageField(props.oldValue, props.fieldName);
        setImagePreview(props.oldValue);
        setImageFileObject(defaultFileObject);
        setImageUrl('');
        $("#" + props.wmsSelector + 'imageDragFile').val('');
        $("#" + props.wmsSelector + 'headerFile').val('');
        setIsImageUrlValid(true);
        props.setValidImageUrl(true);
    }
    const onImageSrcInvalid = (currentTarget) => {
        
        // currentTarget.onerror = null; // prevents looping
        currentTarget.src="assets/images/Default_image.png";
        
        if(imageUrl !== ''){
            setIsImageUrlValid(false);
            props.setValidImageUrl(false);

            props.changeImageField(props.oldValue, props.fieldName);
            setImagePreview(props.oldValue);
            setImageFileObject(defaultFileObject);
        }
    }
    return (

        <div className={`image-wrapper ${props.className}`}>
            <div className={'img-section' + (isFileDrage ? ' is-dragged': '')}>
                <label>{window.translate('Label_' + props.caption + '_Image')}</label>
                <img src={imagePreview ? imagePreview : 'assets/images/Default_image.png'}
                    onError={onImageSrcInvalid}
                    alt="header" />

                <WmsFile
                    id={props.wmsSelector + 'imageDragFile'}
                    name={props.wmsSelector + 'imageDragFile'}
                    label={window.translate('Label_Browse_Files')}
                    wmsSelector={props.wmsSelector+"HeaderDragFiles"}
                    fileProcessType="ImageSelect"
                    value={''}
                    icon={"fa-file-o"}
                    onChangeFunc={handleFileChange}
                    accept={"image/png, image/jpeg, image/gif"}
                    maxWidth={props.maxWidth}
                    maxHeight={props.maxHeight}
                    onDragOver={onDragOver}
                    onDragStop={onDragStop}
                    clearInputOnClick={true}
                    />
            </div>
            <div className='image-source-info-section'>
                    <WmsInput
                        id="imageUrlPath"
                        inputName='Name'
                        wmsSelector={props.wmsSelector + 'Name'}
                        onChangeFunc={onChangeImageUrl}
                        label={window.translate('Label_' + props.caption + '_Image_File_URL')}
                        value={imageUrl}
                        extraWrapperClass={!isImageUrlValid ? "image-path-url wms_invalid_input" : "image-path-url"}
                    />
                    <div className='img-size-info'>
                        <b>Max Image Size:</b> {props.maxWidth} x {props.maxHeight} pixels
                    </div>
                    {!_.isEqual(imageFileObject, defaultFileObject)? (
                    <div className='img-info'>
                        <b>File Info</b><br/>
                        File Name: {imageFileObject.fileName} <br/>
                        File Type / SIze: {imageFileObject.fileExtension} - {GlobalService.getFileSizeString(imageFileObject.FileSize)} ({imageFileObject.fileResolution.width} x {imageFileObject.fileResolution.height} pixels)
                    </div>
                    ): null}
            </div>
                <div className='or-section'>
                    or
                </div>
                <div className='browse-reset-section'>
                    <WmsFile
                        id={props.wmsSelector + 'headerFile'}
                        name={props.wmsSelector + 'headerFile'}
                        label={window.translate('Label_Browse_Files')}
                        wmsSelector={props.wmsSelector+"HeaderFiles"}
                        fileProcessType="ImageSelect"
                        value={''}
                        icon={"fa-file-o"}
                        onChangeFunc={handleFileChange}
                        accept={"image/png, image/jpeg, image/gif"}
                        maxWidth={props.maxWidth}
                        maxHeight={props.maxHeight}
                        clearInputOnClick={true}
                    />
                    <div className='btn-wrapper'>
                        <WmsButton
                            label={''}
                            wmsSelector={props.wmsSelector + 'ResetHeaderImage'}
                            onClickFunc={() => {onResetUrl()}}
                            buttonType='blue'
                            icon={' fa-undo'}
                            disabled={imagePreview === props.oldValue}
                        />
                        <WmsButton
                            label={''}
                            wmsSelector={props.wmsSelector + 'DeleteHeaderImage'}
                            onClickFunc={() => {onDeleteUrl()}}
                            buttonType='red'
                            icon={' fa-trash'}
                            disabled={imagePreview === onDeleteDefault || imagePreview === null}
                        />
                    </div>
                </div>
        </div>
    )
}
CustomerPackingSlipsTemplateImage.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    oldValue: PropTypes.any,
    className: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    changeImageField: PropTypes.func.isRequired,
    setValidImageUrl: PropTypes.any,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    onDeleteDefault: PropTypes.string
};

CustomerPackingSlipsTemplateImage.defaultProps = {
    wmsSelector: '',
    oldValue: '',
    fieldName: '',
    caption: '',
    className: '',
    changeImageField: (imgUrl: string, fieldName: string): void => {},
    setValidImageUrl: (isValid: boolean): void => {},
    maxWidth:257,
    maxHeight:63,
    onDeleteDefault: "assets/images/Default_image.png"
};

export default React.memo(CustomerPackingSlipsTemplateImage);