
import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';
//components
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsGrid from './../components/WmsGrid/WmsGrid.jsx';
import * as GridConfig from './grid_configuration.jsx';
import WmsCheckBox from "./../components/WmsCheckBox/WmsCheckBox.jsx";
import ItemsExportModal from './items-export-modal.component.jsx';
import ItemsUpdateModal from './items-update-modal.component.jsx';

import * as itemService from './items.services.jsx';
//services
import * as GlobalService from './../global_services';

export class ItemsGrid extends React.Component {

    constructor(props) {

        super(props);

        this.$rootScope = window;
        this.translate = window.translate;
        var Items_GridHeaders = StringWms.convertSringToOption(this.translate('Items_GridHeaders'));

        this.itemsGridId = "itemsGrid";
        this.state = {
            columns: GridConfig.getGridDemoColumns(Items_GridHeaders, GlobalService.getCustomLabels()).columns,
            schema: GridConfig.getGridDemoSchema().schema,
            transport: GridConfig.getGridDemoTransport(this.props.sharedCurrentCustomerObj.uri).transport,
            CustomLabels: GlobalService.getCustomLabels(),
            Rights: GlobalService.GetRights(),
            searchOn: false,
            enableEdit: false,
            enableActive: false,
            enableDeactive: false,
            enableDelete: false,
            Selector: 'Item',
            filterObject: [],
            localKendoGrid: null,
            isInactive: false,
            filter: {
                Description: "",
                IndexOptionsDescription: 0,
                IndexOptionsIsActive: 1,
                IndexOptionsIsAssembly: 0,
                IndexOptionsSku: 0,
                ShowDescription: true,
                ShowIsActive: true,
                ShowIsAssembly: true,
                ShowSku: true,
                Sku: "",
            },
            isOpenItemExportModal: false,
            isExportSelectedItems: false,
            isOpenItemUpdateModal: false,
            selectedItems: [],
            selectedSkus: []
        }
        this.gridSearch = GlobalService.debounce(this.gridSearch, 700);
    }

    componentDidMount() {

        if (this.props.sharedItemsFilterData != null) {
            this.setState({
                filter: this.props.sharedItemsFilterData,
                searchOn: true,
            });
        }
    }


    componentWillReceiveProps(nextProps) {

        if (
            nextProps.sharedSelectedCustomerIndex != '' &&
            typeof this.props.sharedCurrentCustomerObj != 'undefined' &&
            typeof nextProps.sharedCurrentCustomerObj != 'undefined' &&
            nextProps.sharedCurrentCustomerObj.uri != this.props.sharedCurrentCustomerObj.uri
        ) {
            var transportObj = this.state.transport;
            transportObj.read.data.customerUri = nextProps.sharedCurrentCustomerObj.uri;
            this.setState({ transport: transportObj });
        }

        if (nextProps.reloadGrid == 'reloadItemGrid') {
            $('#itemsGrid').data('kendoGrid').dataSource.read();
            if (this.state.localKendoGrid != null) {
                this.state.localKendoGrid.clearSelections(() => {
                    this.state.localKendoGrid.repaintSelections();
                });
            }
            this.setState({
                enableEdit: false,
                enableActive: false,
                enableDeactive: false,
                enableDelete: false
            });
        }
        if (
            nextProps.sharedItemsFilterData != null &&
            this.props.sharedItemsFilterData != nextProps.sharedItemsFilterData
        ) {
            this.setState({
                filter: nextProps.sharedItemsFilterData,
                isInactive: nextProps.sharedItemsFilterData.IndexOptionsIsActive == 0 ? true : false,
                searchOn: true,
            });
        }
    }

    selectRow = (KendoGrid) => {

        let currentSelections = KendoGrid.getNumberOfSelections();
        let selectedItems = _.map(KendoGrid.getDictionary().items);
        let enableEdit = false;
        let enableActive = false;
        let enableDeactive = false;
        let enableDelete = false;

        if (currentSelections > 0) {
            enableEdit = true;
        }

        if (KendoGrid.valueExists("IsActive", false) == true) {
            enableActive = true;
        }

        if (KendoGrid.valueExists("IsActive", true) == true) {
            enableDeactive = true;
        }

        if (KendoGrid.valueExists("IsDeletable", true) == true && currentSelections == 1) {
            enableDelete = true;
        }


        this.setState({
            enableEdit: enableEdit,
            enableActive: enableActive,
            enableDeactive: enableDeactive,
            enableDelete: enableDelete,
            selectedItems: selectedItems,
            selectedSkus: _.map(selectedItems, 'Sku'),
            localKendoGrid: KendoGrid
        });

    }

    clickEventOfContextMenu = (obj) => {

        if (obj.wmsid == 'edit') {
            this.onEditItem();
        } else if (obj.wmsid == 'activate') {
            this.activateDeactivateItems(true);
        } else if (obj.wmsid == 'deactivate') {
            this.activateDeactivateItems(false);
        } else if (obj.wmsid == 'exportAll') {
            this.openExportItemsModal(false)()
        } else if (obj.wmsid == 'exportSelected') {
            this.openExportItemsModal(true)()
        } else if (obj.wmsid == 'updateItems') {
            this.openUpdateItemsModal()
        } else if (obj.wmsid == 'delete') {
            this.deleteItems();
        }

    }

    onEditItem = () => {

        let currentSelections = this.state.localKendoGrid.getNumberOfSelections();

        if (currentSelections > 1) {
            this.props.onEnableReccurringStorageOnly(this.state.localKendoGrid.getKeys());
        } else if (currentSelections == 1) {
            var itemEditUri = this.state.localKendoGrid.getItem(this.state.localKendoGrid.getKeys()).EditUri;
            var itemRatesUri = this.state.localKendoGrid.getItem(this.state.localKendoGrid.getKeys()).ItemRatesUri;
            this.props.onEditItem(itemEditUri, itemRatesUri, this.state.localKendoGrid.getKeys());
        }
    }

    activateDeactivateItems = (mybool) => {

        var gridSelectedKeys = this.state.localKendoGrid.getKeys();
        var customerId = this.props.sharedCurrentCustomerObj.id;

        kendo.ui.progress($('#itemsGrid'), true);
        itemService.updateItemsActivation(customerId, gridSelectedKeys, mybool).then((response) => {


            if (typeof response != "undefined" && (response.status == 204 || response.status == 200)) {

                kendo.ui.progress($('#itemsGrid'), false);

                $('#itemsGrid').data('kendoGrid').dataSource.read();
                GlobalService.notification('success', this.translate('Label_Item_Update'));
                this.state.localKendoGrid.clearSelections(() => {
                    this.state.localKendoGrid.repaintSelections();
                });
                this.selectRow(this.state.localKendoGrid);
            }

        }).finally(() => {
            kendo.ui.progress($('#itemsGrid'), false);
        });
    }

    deleteItems = () => {

        var gridSelectedKeys = this.state.localKendoGrid.getKeys();
        var selectedDataItem = this.state.localKendoGrid.getItem(gridSelectedKeys);

        kendo.ui.progress($('#itemsGrid'), true);
        itemService.deleteItem(selectedDataItem.EditUri).then((response) => {

            if (typeof response != "undefined" && response.status == 204) {

                kendo.ui.progress($('#itemsGrid'), false);
                var grid = $('#itemsGrid').data('kendoGrid');
                grid.dataSource.read();
                GlobalService.notification('success', this.translate('Label_Item_Delete'));
                this.state.localKendoGrid.clearSelections(() => {
                    this.state.localKendoGrid.repaintSelections();
                });
                this.selectRow(this.state.localKendoGrid);
            }

        }).finally(() => {
            // kendo.ui.progress($('#itemsGrid'), false);
            kendo.ui.progress($('#itemsGrid'), false);
        });
    }

    filterHandleChange = (event) => {
        this.setState({
            filter: Object.assign(
                {},
                this.state.filter,
                { [event.target.name]: event.target.value }
            ),
        }, () => {
            this.gridSearch();
        });
    }

    handleChangeInactive = () => {
        this.setState({
            isInactive: !this.state.isInactive
        }, () => {
            this.gridSearch();
        });
    }

    openExportItemsModal = (exportSelected = false) => (e) => {
        this.setState({
            isOpenItemExportModal: true,
            isExportSelectedItems: exportSelected
        })
    }
    closeExportItemsModal = () => {
        this.setState({
            isOpenItemExportModal: false
        })
    }

    openUpdateItemsModal = () => {
        this.setState({
            isOpenItemUpdateModal: true,
        })
    }

    closeUpdateItemsModal = () => {
        this.setState({
            isOpenItemUpdateModal: false
        })
    }

    gridSearch = () => {
        var dataArr = [{
            "searchInfo": {
                Description: this.state.filter.Description,
                IndexOptionsDescription: this.state.filter.IndexOptionsDescription,
                IndexOptionsIsActive: this.state.isInactive == true ? 0 : 1,
                IndexOptionsIsAssembly: this.state.filter.IndexOptionsIsAssembly,
                IndexOptionsSku: this.state.filter.IndexOptionsSku,
                ShowDescription: true,
                ShowIsActive: true,
                ShowIsAssembly: true,
                ShowSku: true,
                Sku: this.state.filter.Sku
            }
        }];
        this.setState({
            filterObject: dataArr,
            searchOn: true
        });
    }

    clearFilters = () => {
        // set default value of filter form
        this.setState({
            filter:
            {
                "Description": "",
                "IndexOptionsDescription": 0,
                "IndexOptionsIsActive": 1,
                "IndexOptionsIsAssembly": 0,
                "IndexOptionsSku": 0,
                "ShowDescription": true,
                "ShowIsActive": true,
                "ShowIsAssembly": true,
                "ShowSku": true,
                "Sku": "",
            },
            searchOn: false,
            isInactive: false,
            filterObject: [{ "method": "DELETE" }]
        }, () => {
            this.props.resetFilter();
        });
    }

    onDataBound = () => {
        //  this.$rootScope.setLoaderTimerEndTime("Manage Items");
    }

    onUpdateBatchItem = () => {
        this.setState({ isOpenItemUpdateModal: false });

        $('#itemsGrid').data('kendoGrid').dataSource.read();
        if (this.state.localKendoGrid != null) {
            this.state.localKendoGrid.clearSelections(() => {
                this.state.localKendoGrid.repaintSelections();
            });
            this.selectRow(this.state.localKendoGrid);
        }
    }

    render() {
        return (
            <div className={(
                this.props.sharedLockedSection == 'item-grid-section' ?
                    "wms-overlay-opacity" : ""
            ) + " items-grid"}>
                <WmsButton
                    label={this.translate('RoleManager_Label_Reset')}
                    buttonType='yellow'
                    wmsSelector={this.state.Selector + 'ClearSearchButton'}
                    onClickFunc={this.clearFilters}
                    icon='fa-repeat' />

                {/* <div className={this.state.searchOn == true ? "item-form-boxes width-title " : "item-form-boxes"}> */}
                {/* <div className="title">
                        <label className="standard-control-label wms-area-content-title">{this.translate('Label_Filter_Search_By_Name')}</label>
                        {this.state.searchOn == true && (
                            <label className="wms-float-right">{this.translate('Label_Search_Applied_Filter')}</label>
                        )}
                    </div> */}


                <WmsCollapse
                    id={'collapseItemsFilter'}
                    headerLabel={this.translate("Filter_Criteria_Label")}
                    showCollapseText={false}
                    showCollapseIcon={false} >
                    <WmsDropdown
                        id="itemHolderIndexOptionsCustomer"
                        name="selectedCustomerIndex"
                        label={GlobalService.getCustomLabels().Label_Customer}
                        wmsSelector="ItemSelectedCustomer"
                        value={this.props.sharedSelectedCustomerIndex}
                        onChangeFunc={this.props.handleCustomerSelectChange}
                        options={this.props.sharedCustomerOption}
                        valueField='id'
                        textField='name'
                        parentClassName='form_group'
                        required={true}
                        blankFirstOption={true}
                    />
                    <div className="wms-input-group-wrapper">
                        <WmsDropdown
                            id="IndexOptionsSku"
                            name="IndexOptionsSku"
                            label={this.state.CustomLabels.Label_Sku}
                            wmsSelector={this.state.Selector + 'IndexOptionsSku'}
                            value={this.state.filter.IndexOptionsSku}
                            onChangeFunc={this.filterHandleChange}
                            options={this.props.sharedItemOptions.EnumContains}
                            valueField='Value'
                            textField='Name'
                            parentClassName="select_box"
                        />
                        <WmsInput
                            inputName="Sku"
                            name="Sku"
                            wmsSelector={this.state.Selector + 'FilterItemSKU'}
                            value={this.state.filter.Sku}
                            onChangeFunc={this.filterHandleChange}
                            label={''}
                            parentClassName="input_box" />
                    </div>
                    <div className="form-and-detail-middle">
                        <div className="wms-input-group-wrapper">
                            <WmsDropdown
                                id="IndexOptionsDescription"
                                name="IndexOptionsDescription"
                                label={this.translate('Lable_Description')}
                                wmsSelector="indexOptionsDescription"
                                value={this.state.filter.IndexOptionsDescription}
                                onChangeFunc={this.filterHandleChange}
                                options={this.props.sharedItemOptions.EnumContains}
                                valueField='Value'
                                textField='Name'
                                parentClassName="select_box"
                            />
                            <WmsInput
                                inputName="Description"
                                Name="Description"
                                wmsSelector={'description'}
                                value={this.state.filter.Description}
                                onChangeFunc={this.filterHandleChange}
                                label={''}
                                parentClassName="input_box" />
                        </div>

                        {/* <WmsDropdown
                            id="indexOptionsIsActive"
                            name="IndexOptionsIsActive"
                            label={this.translate('Label_Filter_Status')}
                            wmsSelector={'indexOptionsIsActive'}
                            value={this.state.filter.IndexOptionsIsActive}
                            onChangeFunc={this.filterHandleChange}
                            options={this.props.sharedItemOptions.BooleanOperatorOptions}
                            valueField='Value'
                            textField='Name'
                        /> */}
                        <WmsCheckBox
                            id={'showInnactives'}
                            name={'showInnactives'}
                            wmsSelector={this.state.Selector + 'ShowInActives'}
                            onChangeFunc={this.handleChangeInactive}
                            label={this.translate('Label_Chk_Show_Inactives')}
                            value={this.state.isInactive}
                            checked={this.state.isInactive == true}
                            isRounded={true} />
                    </div>
                </WmsCollapse>
                {this.props.sharedSelectedCustomerIndex != '' && (
                    <WmsGrid
                        wmsSelector={'itemsGrid'}
                        gridKeySelection={'ItemId'}
                        gridName={'GridItems'}
                        virtual={true}
                        id={this.itemsGridId}
                        columns={this.state.columns}
                        schema={this.state.schema}
                        transport={this.state.transport}
                        showGridSummary={true}
                        showErrorMessageSection={true}
                        windowId={this.props.windowId}
                        onDataBound={this.onDataBound}
                        onSelection={this.selectRow}
                        clickEventOfContextMenu={this.clickEventOfContextMenu}
                        filterObject={this.state.filterObject}
                        contextMenuFilter='tbody tr:not(.k-grid-edit-row)'
                        getGridSelectionUrl={'/WebUI/Items/Items/GetItemsGridSelections'}
                        searchUrl={'/WebUI/Items/UpdateSession/SearchInfo'}
                        menu={
                            [{
                                "name": "Create",
                                "value": [],
                                "disabled": false,
                                "icon": "fa-plus",
                                "color": "green",
                                'isContextMenu': false,
                                'isButtonMenu': true,
                                'wmsid': 'create',
                                'wmsSelector': this.state.Selector + 'CreateButton',
                                'clickFunc': this.props.onCreatItem
                            },
                            {
                                "name": "Edit",
                                "value": [],
                                "disabled": !this.state.enableEdit,
                                "icon": "fa-pencil",
                                "color": "outlined",
                                'isContextMenu': true,
                                'isButtonMenu': true,
                                'wmsid': 'edit',
                                'wmsSelector': this.state.Selector + 'Edit',
                                'clickFunc': this.onEditItem
                            },
                            {
                                "name": "Manage",
                                "wmsSelector": this.state.Selector + "Manage",
                                "value": [{
                                    "name": "Activate",
                                    "value": [],
                                    "disabled": !this.state.enableActive,
                                    "disabledText": this.translate('note_item_activate'),
                                    "icon": "fa-bandcamp",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': true,
                                    'wmsid': 'activate',
                                    "wmsSelector": this.state.Selector + "Activate",
                                    "onClickFunc": ((e) => this.activateDeactivateItems(true))
                                }, {
                                    "name": this.translate('Label_Deactivate'),
                                    "value": [],
                                    "disabled": !this.state.enableDeactive,
                                    "disabledText": this.translate('note_item_deactivate'),
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': true,
                                    'wmsid': 'deactivate',
                                    "wmsSelector": this.state.Selector + "Deactivate",
                                    "onClickFunc": ((e) => this.activateDeactivateItems(false))
                                }, {
                                    "name": this.translate('Label_Export_All'),
                                    "value": [],
                                    'isContextMenu': this.state.Rights.hasOwnProperty('customer.items.import'),
                                    'isButtonMenu': this.state.Rights.hasOwnProperty('customer.items.import'),
                                    'wmsid': 'exportAll',
                                    "wmsSelector": this.state.Selector + "ExportAll",
                                    "onClickFunc": (this.openExportItemsModal(false))
                                }, {
                                    "name": this.translate('Label_Export_Selected'),
                                    "value": [],
                                    "disabled": !this.state.enableEdit,
                                    'isContextMenu': this.state.Rights.hasOwnProperty('customer.items.import'),
                                    'isButtonMenu': this.state.Rights.hasOwnProperty('customer.items.import'),
                                    'wmsid': 'exportSelected',
                                    "wmsSelector": this.state.Selector + "ExportSelected",
                                    "onClickFunc": (this.openExportItemsModal(true))
                                }, {
                                    "name": this.translate('Label_Update_Items'),
                                    "value": [],
                                    'isContextMenu': this.state.Rights.hasOwnProperty('customer.items.import'),
                                    'isButtonMenu': this.state.Rights.hasOwnProperty('customer.items.import'),
                                    'wmsid': 'updateItems',
                                    "wmsSelector": this.state.Selector + "UpdateItems",
                                    "onClickFunc": (this.openUpdateItemsModal)
                                }],
                                "disabled": false,
                                "disabledText": "You may not add blahssss0",
                                "icon": "fa-wrench",
                                "color": "outlined",
                                'isContextMenu': true,
                                'isButtonMenu': true
                            },
                            {
                                "name": "Delete",
                                "value": [],
                                "disabled": !this.state.enableDelete,
                                "disabledText": this.translate('note_item_delete'),
                                "icon": "fa-pencil",
                                "color": "yellow",
                                'isContextMenu': true,
                                'isButtonMenu': false,
                                'wmsid': 'delete',
                                "wmsSelector": this.state.Selector + "Delete",
                                "clickFunc": this.deleteItems
                            }, {
                                "name": "Copy",
                                "value": [{
                                    "name": this.translate('Label_Copy_Field'),
                                    "value": [],
                                    "disabled": false,
                                    "icon": "fa-bandcamp",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyField',
                                    "disabledText": this.translate('Label_Copy_Field'),
                                    "wmsSelector": "copyToClicpboard",
                                }, {
                                    "name": this.translate('Label_Copy_Row'),
                                    "value": [],
                                    "disabled": false,
                                    "disabledText": this.translate('Label_Copy_Row'),
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyRow'
                                }],
                                "disabled": false,
                                "disabledText": "You may not add blahssss0",
                                "icon": "fa-wrench",
                                "color": "green",
                                'isContextMenu': true,
                                'isButtonMenu': false
                            }]
                        }>
                    </WmsGrid>
                )}
                {/* </div> */}

                {this.state.isOpenItemExportModal && (
                    <ItemsExportModal
                        id="itemsExportModal"
                        isExportSelectedItems={this.state.isExportSelectedItems}
                        isOpenItemExportModal={this.state.isOpenItemExportModal}
                        onCloseItemExportModal={this.closeExportItemsModal}
                        sharedSelectedItemSkus={this.state.selectedSkus}
                        sharedSelectedCustomerObj={this.props.sharedCurrentCustomerObj}
                    />
                )}

                {this.state.isOpenItemUpdateModal && (
                    <ItemsUpdateModal
                        id="itemsUpdateModal"
                        isOpenItemUpdateModal={this.state.isOpenItemUpdateModal}
                        onCloseItemUpdateModal={this.closeUpdateItemsModal}
                        sharedSelectedCustomerObj={this.props.sharedCurrentCustomerObj}
                        onUpdateBatchItem={this.onUpdateBatchItem}
                    />
                )}
            </div>
        )
    }
}