import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import WmsButton from "../react/components/WmsButton/WmsButton";
import WmsCollapse from "../react/components/WmsCollapse/WmsCollapse";
import WmsDropdown from "../react/components/WmsDropdown/WmsDropdown";
import WmsInput from "../react/components/WmsInput/WmsInput";
import * as GlobalService from "../react/global_services";
import "./mobileLabelTemplates.scss";
interface Props {
    wmsSelector: string;
    windowId: any;
    sharedSearchOn: boolean;
    sharedFilterObject: any;
    onClearSearch: () => void;
    onFilterDataSet: (objFilter: any) => void;
}

declare var window: any;

const MobileLabelTemplatesSearch: React.FC<Props> = (props: Props): any => {
    const CustomLabels = GlobalService.getCustomLabels();
    const CustomerOptions = GlobalService.getCustomerList(false);
    CustomerOptions.unshift({
        id: 0,
        uri: "",
        name: "All Customer",
    });
    const [filterObject, setFilterObject] = useState(props.sharedFilterObject);

    const handleFilterChange = (event: any) => {
        let name = event.target.name;
        let value = event.target.value;

        setFilterObject((previousState) => ({
            ...previousState,
            [name]: value,
        }));

        finalCallFilterChange2();
    };

    let finalCallFilterChange = () => {
        props.onFilterDataSet(filterObject);
    };

    let finalCallFilterChange2 = GlobalService.useFCDebounce(finalCallFilterChange, 1000);

    useEffect(() => {
        setFilterObject(props.sharedFilterObject);
    }, [props.sharedFilterObject]);

    return (
        <div className={`mobile-label-templates-search`}>
            <div className="btn-section">
                <WmsButton
                    label={window.translate("RoleManager_Label_Reset")}
                    buttonType="orange"
                    wmsSelector={props.wmsSelector + "resetFilter"}
                    onClickFunc={props.onClearSearch}
                    icon="fa-repeat"
                />
            </div>

            {props.sharedSearchOn && (
                <div className="title">
                    <b>{window.translate("Label_NOTE")}: </b>
                    <label>{window.translate("Label_Search_Applied_Filter")}</label>
                </div>
            )}

            <WmsCollapse
                id={"Label_General"}
                showDragIcon={false}
                headerLabel={window.translate("Label_General")}
                showCollapseText={false}
            >
                <WmsInput
                    id="labelName"
                    inputName="labelName"
                    wmsSelector={props.wmsSelector + "labelName"}
                    value={filterObject.labelName}
                    onChangeFunc={handleFilterChange}
                    extraWrapperClass="full-width-input"
                    label={window.translate("Label_Template_Name_Contains")}
                />
                <WmsDropdown
                    id="cusId"
                    name="cusId"
                    label={CustomLabels.Label_Customer}
                    wmsSelector={props.wmsSelector + "SelectCustomer"}
                    value={filterObject.cusId}
                    onChangeFunc={handleFilterChange}
                    options={CustomerOptions}
                    textField={"name"}
                    valueField={"id"}
                    extraWrapperClass={"customer-options"}
                />
            </WmsCollapse>
        </div>
    );
};
MobileLabelTemplatesSearch.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    sharedSearchOn: PropTypes.bool.isRequired,
    sharedFilterObject: PropTypes.any.isRequired,
    windowId: PropTypes.any.isRequired,
    onClearSearch: PropTypes.func.isRequired,
    onFilterDataSet: PropTypes.func.isRequired,
};

MobileLabelTemplatesSearch.defaultProps = {
    wmsSelector: "",
    sharedSearchOn: false,
    sharedFilterObject: {},
    windowId: "",
    onClearSearch: (): void => {},
    onFilterDataSet: (filterObject: any): void => {},
};

export default React.memo(MobileLabelTemplatesSearch);
