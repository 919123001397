export const getAdjustCustomerChargeColumns = () => {
    return {
        columns: [
            {
                field: "RowNumber",
                title: "#",
                width: 50,
                wmsid: 1,
                locked: true,
                lockable: false,
                resizable: false,
                sortable: false,
                reorderable: false,
                filterable: false,
            },
            {
                field: "TransactionId",
                title: "Transaction ID",
                wmsid: 2,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "AdjustCustomerChargeTransactionId",
                },
            },
            {
                field: "ReferenceNum",
                title: "Reference Number",
                wmsid: 3,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "AdjustCustomerChargeReference",
                },
            },
            {
                field: "ProcessDate",
                title: "Charge Date",
                wmsid: 5,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "AdjustCustomerChargeChargeDate",
                },
                template: function (dataItem) {
                    return kendo.toString(kendo.parseDate(dataItem.ProcessDate), "yyyy-MM-dd");
                },
            },
            {
                field: "Customer",
                title: "Customer",
                wmsid: 6,
                locked: false,
                lockable: false,
                template: function (dataItem) {
                    return dataItem.CustomerIdentifier ? dataItem.CustomerIdentifier.Name : "";
                },
                headerAttributes: {
                    "data-wms-selector": "AdjustCustomerChargeCustomer",
                },
            },
            {
                field: "Facility",
                title: "Facility",
                wmsid: 7,
                locked: false,
                lockable: false,
                template: function (dataItem) {
                    return dataItem.FacilityIdentifier ? dataItem.FacilityIdentifier.Name : "";
                },
                headerAttributes: {
                    "data-wms-selector": "AdjustCustomerChargeFacility",
                },
            },
            {
                field: "BillTotalCharges",
                title: "Total Charges",
                wmsid: 8,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "AdjustCustomerChargeChargeAmount",
                },
            },
        ],
    };
};

export const getAdjustCustomerChargeAllColumnList = () => {
    return [
        {
            field: "RowNumber",
            title: "#",
            width: 50,
            wmsid: 1,
            locked: true,
            lockable: false,
            resizable: false,
            sortable: false,
            reorderable: false,
            filterable: false,
        },
        {
            field: "TransactionId",
            title: "Transaction ID",
            width: 300,
            wmsid: 2,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "AdjustCustomerChargeTransactionId",
            },
        },
        {
            field: "ReferenceNum",
            title: "Reference Number",
            wmsid: 3,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "AdjustCustomerChargeReference",
            },
        },
        {
            field: "ChargeType",
            title: "ChargeType",
            wmsid: 4,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "AdjustCustomerChargeChargeType",
            },
        },
        {
            field: "ProcessDate",
            title: "Charge Date",
            wmsid: 5,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "AdjustCustomerChargeChargeDate",
            },
            template: function (dataItem) {
                return kendo.toString(kendo.parseDate(dataItem.ProcessDate), "yyyy-MM-dd");
            },
        },
        {
            field: "Customer",
            title: "Customer",
            wmsid: 6,
            locked: false,
            lockable: false,
            template: function (dataItem) {
                return dataItem.CustomerIdentifier ? dataItem.CustomerIdentifier.Name : "";
            },
            headerAttributes: {
                "data-wms-selector": "AdjustCustomerChargeCustomer",
            },
        },
        {
            field: "Facility",
            title: "Facility",
            wmsid: 7,
            locked: false,
            lockable: false,
            template: function (dataItem) {
                return dataItem.FacilityIdentifier ? dataItem.FacilityIdentifier.Name : "";
            },
            headerAttributes: {
                "data-wms-selector": "AdjustCustomerChargeFacility",
            },
        },
        {
            field: "BillTotalCharges",
            title: "Total Charges",
            wmsid: 8,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "AdjustCustomerChargeChargeAmount",
            },
        },
    ];
};

export const getAdjustCustomerChargeSchema = () => {
    return {
        model: {
            id: "RowNumber",
            fields: {
                RowNumber: { type: "number" },
                TransactionId: { type: "number" },
                ReferenceNum: { type: "string" },
                ChargeType: { type: "string" },
                ChargeDate: { type: "string" },
                Customer: { type: "string" },
                Facility: { type: "string" },
                ChargeAmount: { type: "number" },
                BillTotalCharges: { type: "number" },
            },
        },
    };
};

export const getCalculateChargesGridSchema = () => {
    return {
        schema: {
            model: {
                fields: {
                    ChargeOrigin: {
                        type: "number",
                        editable: false,
                    },
                    ChargeTypeDescription: {
                        type: "string",
                        editable: false,
                    },
                    ChargeLabel: {
                        type: "string",
                        editable: false,
                    },
                    ChargePerUnit: {
                        type: "number",
                        editable: true,
                    },
                    NumberOfUnits: {
                        type: "number",
                        editable: true,
                    },
                    TotalCharges: {
                        type: "number",
                        editable: false,
                    },
                    BSId: {
                        type: "string",
                        editable: false,
                    },
                    BSChargeType: {
                        type: "string",
                        editable: false,
                    },
                    BSPredefinedRateId: {
                        type: "string",
                        editable: false,
                    },
                },
            },
        },
    };
};

export const ChargesColumns = (CalculateCharges_GridHeaders, translate) => {
    // var translate = window.translate;
    return [
        {
            field: "ChargeOrigin",
            // title: CalculateCharges_GridHeaders[0].description,
            title: "",
            lockable: false,
            filterable: false,
            width: 160,
            template:
                "# if( ChargeOrigin== 0) {# " +
                translate("Label_Predefined_Required") +
                " #} else if( ChargeOrigin== 1) {# " +
                translate("Label_Billing_Script") +
                "  #} else if( ChargeOrigin== 2) {# " +
                translate("Label_Manual_Charge") +
                " #} else if( ChargeOrigin== 3) {# " +
                translate("Label_Predefined") +
                " #} #",
            wmsid: 1,
        },
        {
            field: "ChargeTypeDescription",
            title: CalculateCharges_GridHeaders[1].description,
            lockable: false,
            width: 160,
            template: "#= ChargeTypeDescription != null ? ChargeTypeDescription : '' #",
            wmsid: 2,
        },
        {
            field: "ChargeLabel",
            title: CalculateCharges_GridHeaders[2].description,
            lockable: false,
            width: 160,
            template: "#= ChargeLabel != null ? ChargeLabel : '' #",
            wmsid: 3,
        },
        {
            field: "ChargePerUnit",
            title: CalculateCharges_GridHeaders[4].description,
            lockable: false,
            width: 160,
            template: "#= ChargePerUnit != null ? ChargePerUnit : '' #",
            wmsid: 5,
        },
        {
            field: "NumberOfUnits",
            title: CalculateCharges_GridHeaders[5].description,
            lockable: false,
            width: 160,
            template: "#= NumberOfUnits != null ? NumberOfUnits : '' #",
            wmsid: 6,
        },
        {
            field: "TotalCharges",
            title: CalculateCharges_GridHeaders[6].description,
            lockable: false,
            width: 160,
            template: "#= TotalCharges != null ? TotalCharges : '' #",
            wmsid: 7,
        },
    ];
};
