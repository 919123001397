
var axios = require('axios');
import * as GlobalService from './../../global_services';

export const getTransferInventoryReports = function (paran_customerId) {
    console.log("param_customerId", paran_customerId);
    return axios.get("/WebUI/Inventory/ManageInventory/GetTransferInventoryReports", { "customerId": paran_customerId })
        .then(function (response) {
            return response;
        });
}
export const runTransferInventoryReports = function (param_request) {

    return axios.post("/WebUI/Inventory/ManageInventory/RunTransferInventoryReports", {'requestbody':param_request})
        .then(function (response) {
            return response;
        });
}
