import React, { useEffect, useState } from "react";
import { default as WmsModal } from "../../components/WmsModel/WmsModel.jsx";
import WmsGrid from "../../components/WmsGrid/WmsGrid.jsx";
import WmsButton from "../../components/WmsButton/WmsButton.jsx";
import _ from "lodash";

interface ViewRateShoppingWarningsModalProps {
    rateShoppingWarningData: any;
    open: boolean;
    onClose: () => void;
}

export default function ViewRateShoppingWarningsModal(props: ViewRateShoppingWarningsModalProps) {
    /**
     * Global variables
     */
    const translate = (window as any).translate as any; //used to translate texts;

    /**
     * Constants
     */
    const wmsSelector = "ViewRateShoppingWarnings";
    const ViewRateShoppingWarningsModalId = "ViewRateShoppingWarningsModal";

    const [rateShoppingWarningData, setRateShoppingWarningData] = useState([]);

    /**
     * useEffects
     */
    useEffect(() => {
        kendo.ui.progress($("#" + ViewRateShoppingWarningsModalId), true);
        let tempRateShoppingWarningData = [];
        _.map(props.rateShoppingWarningData, (warningData) => {
            if (warningData.WmsException.ErrorCode) {
                tempRateShoppingWarningData.push({
                    Error: warningData.WmsException.ErrorCode,
                });
            }
        });
        setRateShoppingWarningData(tempRateShoppingWarningData);
        kendo.ui.progress($("#" + ViewRateShoppingWarningsModalId), false);
    }, [props.rateShoppingWarningData]);

    const exportWarningGrid = () => {
        props.onClose();
        ($("#rateShoppingWarningGrid") as any).getKendoGrid().saveAsExcel();
    };

    return (
        <WmsModal
            isOpen={props.open}
            id={ViewRateShoppingWarningsModalId}
            title={translate("Lable_Rates_Shopping_Warnings")}
            width={800}
            height={400}
            onCloseFunc={props.onClose}
            class="print-batched-shipping-model-wrapper"
            customClass="wms-model-new-ui-wrapper model-height-fixed"
        >
            <>
                <div className="model-content-wrapper">
                    <WmsGrid
                        wmsSelector={wmsSelector + "rateShoppingWarningGrid"}
                        gridKeySelection={"OrderId"}
                        staticGridData={rateShoppingWarningData}
                        gridName={"rateShoppingWarningGrid"}
                        virtual={false}
                        showResetButton={false}
                        shawCheckbox={false}
                        id={"rateShoppingWarningGrid"}
                        onSelection={() => {}}
                        columns={[
                            {
                                field: "Error",
                                title: "Error",
                                width: "100%",
                            },
                        ]}
                        schema={{
                            model: {
                                fields: {
                                    Error: { type: "string" },
                                },
                            },
                        }}
                        clickEventOfContextMenu={() => {}}
                        menu={[]}
                    />
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        id={wmsSelector + "exportWarnings"}
                        name={wmsSelector + "exportWarnings"}
                        label={translate("Label_Export")}
                        wmsSelector={wmsSelector + "exportWarnings"}
                        onClickFunc={() => exportWarningGrid()}
                        icon="fa-download"
                        buttonType="green"
                    />
                    <WmsButton
                        id={wmsSelector + "close"}
                        name={wmsSelector + "close"}
                        label={translate("Label_Close")}
                        wmsSelector={wmsSelector + "close"}
                        onClickFunc={props.onClose}
                        icon="fa-times-circle-o"
                        buttonType="orange"
                    />
                </div>
            </>
        </WmsModal>
    );
}
