import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app/react/index';
import ErrorBoundary from './app/react/ErrorBoundary';

ReactDOM.render(
  <ErrorBoundary>
    <App />,
  </ErrorBoundary>,
  document.getElementById('root')
);
