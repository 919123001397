var axios = require('axios');
import * as GlobalService from './../global_services';

export const getBarcodeInitial = function () {
    return axios.all([
        GlobalService.GetCustomersAndFacility()
    ]).then(axios.spread(function () {
        var optionResponse = {
            customerList: GlobalService.getCustomerList(false)
        };
        return optionResponse;
    }));
}

export const getFindBarcodeDefsPageLoad = function (customerId) {
    var custUri = '/customers/' + customerId;

    return axios.post('/WebUI/Customers/BarcodeDefs/FindBarcodeDefsPageLoad', { customerUri: custUri }).then(function (response) {
        var returnObj = [];
        if (typeof response != "undefined" && (response.status == 204 || response.status == 200)) {
            returnObj = response.data;
        }
        return returnObj;
    });
}

export const DeleteBarcodeDefs = function (uriArray) {
    return axios.post('/WebUI/Customers/BarcodeDefs/DeleteBarcodeDefs', { barcodeDefUrIs: uriArray }).then(function (response) {
        return response.data;
    });
}
export const createBarcodeDefsPageLoad = function (customerId) {
    var custUri = '/customers/' + customerId;

    return axios.post('/WebUI/Customers/BarcodeDefs/CreateBarcodeDefsPageLoad', { customerUri: custUri }).then(function (response) {
        var returnObj = [];
        if (typeof response != "undefined" && (response.status == 204 || response.status == 200)) {
            returnObj = response.data;
        }
        return returnObj;
    });
}

export const saveBarcodeDef = function (custUri, def) {

    return axios.post('/WebUI/Customers/BarcodeDefs/SaveBarcodeDef', { custUri: custUri, def: def }).then(function (response) {
        var returnObj = [];
        if (typeof response != "undefined" && (response.status == 204 || response.status == 200)) {
            returnObj = response.data;
        }
        return returnObj;
    });
}