import React, { Component } from "react";
import WmsCollapse from "../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../components/WmsInput/WmsInput.jsx";

export class AmazonComponent extends Component {

    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.$rootScope = window;
        this.state = {}
    }

    render() {
        return (
            <div className="amazon-info">
                <span className="section-label">Amazon Buy Connection</span>
                <WmsCollapse
                    id={"AmazonInformation"}
                    headerLabel={this.translate("Label_Account_Information")}
                    showCollapseText={false}
                    showCollapseIcon={false}
                >
                    <div className="account-info-section">
                        <WmsInput
                            id="nickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "Nickname"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate('Label_Account_Nick_Name')}
                            value={this.props.connectionObj.Nickname}
                            required={true}
                            extraWrapperClass="amazon-nikcname"
                        />
                        <WmsInput
                            id="accountNumber"
                            inputName="AccountNumber"
                            wmsSelector={this.props.selector + "AccountNumber"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_Seller_Id")}
                            value={this.props.connectionObj.AccountNumber}
                            required={true}
                            extraWrapperClass="amazon-seller-id"
                        />
                        <WmsInput
                            id="password"
                            inputName="Password"
                            wmsSelector={this.props.selector + "Password"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_Authentication_Token")}
                            value={this.props.connectionObj.Password}
                            required={true}
                            extraWrapperClass="amazon-auth-token"
                        />
                        <WmsInput
                            id="email"
                            inputName="Email"
                            wmsSelector={this.props.selector + "EmailAddress"}
                            onChangeFunc={this.props.handleConnectionObjChange}
                            label={this.translate("Label_Email_Address")}
                            value={this.props.connectionObj.Email}
                            required={true}
                            extraWrapperClass="amazon-email"
                        />
                    </div>
                </WmsCollapse>
            </div>
        );
    }
}
