
import React from 'react';

//component
import WmsButton from '../components/WmsButton/WmsButton.jsx';
import WmsScrollTo from '../components/WmsScrollTo/WmsScrollTo.jsx';
import { CustomersFormBasic } from './customers-form-basic.component.jsx';
import { CustomersFormReceiptOption } from './customers-form-receipt-option.jsx';
import { CustomersFormShippingOption } from './customers-form-shipping-option.jsx';
import { CustomersFormSystemOption } from './customers-form-system-option.jsx';

//services
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';
import * as customerService from './customers.services.jsx';
import * as GlobalService from './../global_services';
import { CustomerFormAutobatchOption } from './customer-form-autobatch-option.jsx';

export class CustomersForm extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.defaultFacilitiesObjForC2User = [];
        this.defaultCustomerObj = {
            CompanyInfo: {
                CompanyName: '',
                Country: 'US',
                State: 'CA'
            },
            PrimaryContact: {},
            Options: {
                Receiving: {
                    TrackLocation: 0,
                    TrackPallets: 0,
                    ReceiveAgainstAsns: 0,
                    TrackSupplier: 0
                },
                Shipping: {
                    FulfillmentInvoicing: 0,
                    PrepopulateCarrierInfo: 0,
                    QuickBooksPaymentTerms: "",
                    AsnPreCheck: {
                        AsnPreCheckOptions: 0,
                        OrderLevelFlexFields: [],
                        OrderItemLevelFlexFields: []
                    },
                    AutoBatchOptions: {
                        Enabled: false,
                        BatchSize: 0,
                        CutOffHour: 15,
                        CutOffMinute: 30,
                        Rules: []
                    }
                },
                UserInterface: {
                    TransactionConfirmInvoiceCreateDefault: 0
                },
                MobileProcessingBehaviors: {
                    AutoAdvanceLineItem: true, // WMS-21256 - Create customer default enable  AutoAdvance setting
                    AutoFillSameSkuAndTrackby: true, // WMS-21256 - Create customer default enable  Autofill setting
                    AllowOptionalCustomFields: false,
                    AllowRequiredCustomFields: false
                },
                Edi: {},
                Storage: {},
                Packaging: {},
                OtherSystemBehaviors: {},
                Alerts: {
                    OverrideSentFrom: false
                }
            },
            Facilities: [],
            ReadOnly: {
                CreationDate: null
            },
            WebSite: '',
            ExternalId: ''
        };
        this.state = {
            showDiscard: false,
            saveLoader: false,
            Selector: 'Customer',
            customerDefaultFacilities: null,
            OptionsFacility: GlobalService.getFacilityList(),
            customerObjWithFlags: null,
            sharedCustomer: this.defaultCustomerObj,
            originalCustomerObject: this.defaultCustomerObj,
            rights: GlobalService.GetRights(),
            isAllSelectedFacilityAvailable: true,
            asnPrecheckedOptionsFlag: {
                HasWeight: false, // binary sequeance value = 1
                HasVolume: false, // binary sequeance value = 2
                HasPackages: false, // binary sequeance value = 4
                HasCarrier: false, // binary sequeance value = 8
                HasBillOfLading: false, // binary sequeance value = 16
                HasLoadNumber: false, // binary sequeance value = 32
                HasPoNumber: false, // binary sequeance value = 64
                IsClosed: false // binary sequeance value = 128
            },
            isValidTempleteImageURL: true

        };
    }

    componentDidMount() {
        this.getCustomerData(this.props.sharedCustomerEditUri);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.sharedCustomerEditUri != this.props.sharedCustomerEditUri || prevProps.sharedIsCloneCustomer != this.props.sharedIsCloneCustomer ||
            (
                prevProps.sharedSettingsForCloneCustomer.generalSettings != this.props.sharedSettingsForCloneCustomer.generalSettings ||
                prevProps.sharedSettingsForCloneCustomer.transactionSettings != this.props.sharedSettingsForCloneCustomer.transactionSettings
            )) {
            this.getCustomerData(this.props.sharedCustomerEditUri);
        }
    }

    setValidTemplateImageUrl(isValid){
        this.setState({
            isValidTempleteImageURL: isValid
        });
    }

    getCustomerData(param_edituri) {
        this.setState({
            sharedCustomer: this.defaultCustomerObj,
            showDiscard: false,
            isAllSelectedFacilityAvailable: true,
            asnPrecheckedOptionsFlag: {
                HasWeight: false, // binary sequeance value = 1
                HasVolume: false, // binary sequeance value = 2
                HasPackages: false, // binary sequeance value = 4
                HasCarrier: false, // binary sequeance value = 8
                HasBillOfLading: false, // binary sequeance value = 16
                HasLoadNumber: false, // binary sequeance value = 32
                HasPoNumber: false, // binary sequeance value = 64
                IsClosed: false // binary sequeance value = 128
            }
        });

        this.defaultFacilitiesObjForC2User = [];

        if (param_edituri != '') {

            kendo.ui.progress($('#form_block'), true);
            customerService.getEditCustomerData(param_edituri).then((response) => {
                if (typeof response != 'undefined' && response.status == 200) {

                    let facilityIds = [];
                    let isSelectedFacAvailable = true;
                    response.data.Customer.Facilities.forEach(value => {
                        facilityIds.push(value['Id']);
                        let getIndex = ObjectWms.FindIndexByProperty(this.state.OptionsFacility, 'id', value['Id']);
                        if (getIndex == -1) {
                            isSelectedFacAvailable = false;
                        }
                    });


                    this.defaultFacilitiesObjForC2User = response.data.Customer.Facilities;
                    response.data.Customer.Facilities = facilityIds;

                    if (this.props.sharedIsCloneCustomer) {
                        var tmpCustObj = this.setCustomerObjForClone(response.data.Customer);
                        response.data.Customer = tmpCustObj;
                    }


                    if (response.data.Customer.Options.Shipping.AsnPreCheck.AsnPreCheckOptions) {
                        this.setAsnPrechekOptions(response.data.Customer.Options.Shipping.AsnPreCheck.AsnPreCheckOptions);
                    }

                    if (response.data.Customer.Options.Shipping.QuickBooksPaymentTerms == "") {
                        response.data.Customer.Options.Shipping.QuickBooksPaymentTerms = "None";
                    }

                    if (response.data.Customer.Options.Alerts.ReplyToAddress) {
                        response.data.Customer.Options.Alerts.apiReplyToAddress = response.data.Customer.Options.Alerts.ReplyToAddress;
                    }
                    if (response.data.Customer.Options.Alerts.SenderName) {
                        response.data.Customer.Options.Alerts.OverrideSentFrom = true;
                        response.data.Customer.Options.Alerts.apiSenderName = response.data.Customer.Options.Alerts.SenderName;
                    } else {
                        response.data.Customer.Options.Alerts.OverrideSentFrom = false;
                    }
                    if (response.data.Customer.Options.MobileProcessingBehaviors && response.data.Customer.Options.MobileProcessingBehaviors.AllowMultiplePickers) {
                        response.data.Customer.Options.MobileProcessingBehaviors.AutoAdvanceLineItem = false
                        response.data.Customer.Options.MobileProcessingBehaviors.AutoFillSameSkuAndTrackby = false
                    }

                    this.setState({
                        sharedCustomer: response.data.Customer,
                        originalCustomerObject: _.cloneDeep(response.data.Customer),
                        customerDefaultFacilities: facilityIds,
                        customerObjWithFlags: response.data,
                        showDiscard: false,
                        isAllSelectedFacilityAvailable: isSelectedFacAvailable
                    });
                }

            }).finally(() => {
                kendo.ui.progress($('#form_block'), false);
            });
        }
    }
    setAsnPrechekOptions = (param_val) => {
        let tmpAsnPreCheckOptions = GlobalService.setAsnPrechekOptionsData(param_val);
        this.setState({ asnPrecheckedOptionsFlag: tmpAsnPreCheckOptions });
    }
    setCustomerObjForClone = (param_cutomerObj) => {
        var tmpCustObj = param_cutomerObj;

        /* basic section */
        tmpCustObj.CompanyInfo = {
            CompanyName: '',
            Country: 'US',
            State: 'CA'
        };
        tmpCustObj.ExternalId = '';
        tmpCustObj.WebSite = '';
        tmpCustObj.PrimaryContact = {};
        if (this.props.sharedSettingsForCloneCustomer.generalSettings == false) {

            /* Customer Basics section - warehouse access*/
            tmpCustObj.Facilities = [];

            /* Receipts section - GENERAL SETTINGS*/
            tmpCustObj.Options.Receiving.TrackLocation = 0;
            tmpCustObj.Options.Receiving.TrackPallets = 0;
            tmpCustObj.Options.Storage.FuelSurcharge = '';
            tmpCustObj.Options.Receiving.TrackSupplier = 0;
            tmpCustObj.Options.UserInterface.AutoCheckAutoReallocateOnReceive = false;
            tmpCustObj.Options.Receiving.CreateMultipleMus = false;
            tmpCustObj.Options.Receiving.SuggestedPutAwayEnabled = false;

            /* Receipts section - MOBILE RECEIVING (SMARTSCAN)*/
            tmpCustObj.Options.Receiving.ReceiveAgainstAsns = 0;
            tmpCustObj.Options.UserInterface.MobileReceiptQuantityOneDefault = false;
            tmpCustObj.Options.Receiving.DisplayWeightFieldForReceipt = false;

            /* Orders section - Orders */
            tmpCustObj.Options.Shipping.AutoConfirmOrderOnTrackingUpdate = false;
            tmpCustObj.Options.Shipping.OrderQueue = false;
            tmpCustObj.Options.Shipping.SmartPackScaleEnabled = false;
            tmpCustObj.Options.Receiving.AutoFillTrackBys = false;
            tmpCustObj.Options.Packaging.SpsCloseOnUpdate = false;
            tmpCustObj.Options.UserInterface.ReallocateAtPickTime = false;
            tmpCustObj.Options.Shipping.RequireTrackingNumber = false;
            tmpCustObj.Options.Shipping.RounduptoFullMU = false;
            tmpCustObj.Options.Shipping.FulfillmentInvoicing = 0;
            tmpCustObj.Options.Shipping.AsnPreCheck.RequireScacForAsn = false;
            tmpCustObj.Options.Shipping.AsnPreCheck.AsnPreCheckOptions = 0;
            tmpCustObj.Options.Shipping.AsnPreCheck.RequireAttachedDocuments = false;
            tmpCustObj.Options.Shipping.AsnPreCheck.OrderLevelFlexFields = null;
            tmpCustObj.Options.Shipping.AsnPreCheck.OrderItemLevelFlexFields = null;

            /* System section - GENERAL SETTINGS */
            tmpCustObj.Options.UserInterface.ExcludeLocationsWithZeroInventory = false;
            tmpCustObj.Options.OtherSystemBehaviors.FlexFields = false;
            tmpCustObj.Options.OtherSystemBehaviors.AllowDuplicateUPCs = false;
            tmpCustObj.Options.Receiving.PurchaseOrders = false;
            tmpCustObj.Options.Shipping.QuickBooksPaymentTerms = "";

            /* System section - DOCUMENT SETTINGS */
            tmpCustObj.Options.Shipping.NextMasterBolId = "";
            tmpCustObj.Options.Packaging.Gs1CompanyPrefix = "";
            tmpCustObj.Options.Shipping.BolNumAsTransNum = false;
            tmpCustObj.Options.Packaging.UseVendorIdOnBol = false;

        }

        /* System section - CARRIER ACCOUNT */
        delete tmpCustObj.Options.Shipping.UpsAccountNumber;
        delete tmpCustObj.Options.Shipping.FedExAccountNumber;
        delete tmpCustObj.Options.Shipping.UpsAccountZip;
        delete tmpCustObj.Options.Shipping.PrepopulateCarrierInfo;

        /* System section - PER TRANSACTION OPTIONS */
        delete tmpCustObj.Options.Storage.AccountingCustomerName;

        if (this.props.sharedSettingsForCloneCustomer.transactionSettings == false) {
            /* System section - PER TRANSACTION OPTIONS */
            delete tmpCustObj.Options.UserInterface.TransactionConfirmInvoiceCreateDefault;
            delete tmpCustObj.Options.Storage.SetInvoiceDateToXactionConfirmDate;
        }

        /* System section - EDI X12 940-945 & PRE-WIRED CONNECTIONS */
        tmpCustObj.Options.Edi = {};

        return tmpCustObj;
    }
    onCustomerDataset = (param_customerobj) => {
        this.setState({
            sharedCustomer: Object.assign(
                {},
                this.state.sharedCustomer,
                param_customerobj
            ),
            showDiscard: true
        });
    }
    onAsnPrecheckOptionsSet = (options_obj) => {
        this.setState({
            asnPrecheckedOptionsFlag: options_obj,
            showDiscard: true
        });
    }

    onClickExitCustomerForm = () => {
        this.props.onExitCustomerForm();
    }
    calculateAsnPrecheckedOptions = (param_obj) => {
        let tmpAsnPrechekOptionsValue = 0;

        if (param_obj.IsClosed) { tmpAsnPrechekOptionsValue += 128; }

        if (param_obj.HasPoNumber) { tmpAsnPrechekOptionsValue += 64; }

        if (param_obj.HasLoadNumber) { tmpAsnPrechekOptionsValue += 32; }

        if (param_obj.HasBillOfLading) { tmpAsnPrechekOptionsValue += 16; }

        if (param_obj.HasCarrier) { tmpAsnPrechekOptionsValue += 8; }

        if (param_obj.HasPackages) { tmpAsnPrechekOptionsValue += 4; }

        if (param_obj.HasVolume) { tmpAsnPrechekOptionsValue += 2; }

        if (param_obj.HasWeight) { tmpAsnPrechekOptionsValue += 1; }

        return tmpAsnPrechekOptionsValue;
    }
    onClickSaveCustomer = () => {

        this.setState({ saveLoader: true });
        let customer = _.cloneDeep(this.state.sharedCustomer);
        if (this.state.isAllSelectedFacilityAvailable) {
            customer.Facilities = this.prepareFacilityObjOnSave();
        } else {
            customer.Facilities = this.defaultFacilitiesObjForC2User // reset previous facility object
        }
        customer.ReadOnly.CreationDate = null;
        customer.Options.Shipping.AsnPreCheck.AsnPreCheckOptions = this.calculateAsnPrecheckedOptions(this.state.asnPrecheckedOptionsFlag);
        if (customer.Options.Shipping.QuickBooksPaymentTerms == "None") {
            customer.Options.Shipping.QuickBooksPaymentTerms = "";
        }
        // remove FuelSurcharge property if blank value
        if (!customer.Options.Storage.FuelSurcharge) { delete customer.Options.Storage.FuelSurcharge; }
        // remove NextMasterBolId property if blank value
        if (!customer.Options.Shipping.NextMasterBolId) { delete customer.Options.Shipping.NextMasterBolId; }
        // remove Gs1CompanyPrefix property if blank value
        if (!customer.Options.Packaging.Gs1CompanyPrefix) { delete customer.Options.Packaging.Gs1CompanyPrefix; }

        //remove extra property - used in specification notification section
        delete customer.Options.Alerts.OverrideSentFrom;
        delete customer.Options.Alerts.ReplyToAddressValidate;
        delete customer.Options.Alerts.ReplyToAddressValidateToolTip;

        if (this.props.sharedCustomerEditUri != '' && this.props.sharedIsCloneCustomer == false) {

            customer['EditUri'] = this.props.sharedCustomerEditUri;
            let customerData = {
                "Customer": customer,
                "EditUri": this.props.sharedCustomerEditUri,
                "AlertsNull": this.setFlagsIfChangesOccured('AlertsNull'),
                "StorageNull": this.setFlagsIfChangesOccured('StorageNull'),
                "EdiNull": this.setFlagsIfChangesOccured('EdiNull'),
                "ReceivingNull": this.setFlagsIfChangesOccured('ReceivingNull'),
                "ShippingNull": this.setFlagsIfChangesOccured('ShippingNull'),
                "PackagingNull": this.setFlagsIfChangesOccured('PackagingNull'),
                "OtherSystemBehaviorsNull": this.setFlagsIfChangesOccured('OtherSystemBehaviorsNull'),
                "UserInterfaceNull": this.setFlagsIfChangesOccured('UserInterfaceNull'),
                "SavedElementsNull": true,
                "LogoChanged": false,
            };

            customerService.updateExistingCustomer(customerData).then((response) => {
                if (typeof response != 'undefined' && response.status == 200) {
                    GlobalService.notification('success', this.translate('Label_Customer_Update'));
                    GlobalService.GetCustomersAndFacility();
                    this.setState({ saveLoader: false });
                    this.props.reloadGrid('reloadCustomerGrid');
                    this.onClickExitCustomerForm();
                }

            }).finally(() => {
                if (this.state.saveLoader) {
                    this.setState({ saveLoader: false });
                }
            });

        } else {
            delete customer["Groups"]; // wms-9896 delete Groups it's create error when clone customer and save
            // pass extra parameter for clone customer
            let srcCustId = 0;
            let cloneBilling = false;
            if (this.props.sharedIsCloneCustomer == true) {
                srcCustId = customer.ReadOnly.CustomerId;
                cloneBilling = this.props.sharedSettingsForCloneCustomer.transactionSettings;
            }
            customerService.saveNewCustomer({
                customer: customer
            }).then((response) => {
                GlobalService.notification('success', this.translate('Label_Customer_Create'));
                GlobalService.GetCustomersAndFacility();
                if (cloneBilling) {
                    let trgCustId = response.data.Customer.ReadOnly.CustomerId;
                    this.callCloneCustomerRatesAfterCreateCustomer(srcCustId, trgCustId);
                } else {
                    this.props.reloadGrid('reloadCustomerGrid');
                    this.onClickExitCustomerForm();
                }

            }).finally(() => {
                if (this.state.saveLoader && this.props.sharedIsCloneCustomer == false) {
                    this.setState({ saveLoader: false });
                }
            });
        }
    }

    callCloneCustomerRatesAfterCreateCustomer = (source_customer, target_customer) => {
        customerService.cloneRates(source_customer, target_customer)
            .then((response) => {
                GlobalService.notification('success', this.translate('Label_Customer_Per_Tran_Success'));
                this.setState({ saveLoader: false });
                this.props.reloadGrid('reloadCustomerGrid');
                this.onClickExitCustomerForm();
            }).finally(() => {
                if (this.state.saveLoader) {
                    this.setState({ saveLoader: false });
                }
            });
    }


    prepareFacilityObjOnSave = () => {
        let tempFacility = [];
        this.state.sharedCustomer.Facilities.forEach((value, index) => {
            let getIndex = ObjectWms.FindIndexByProperty(this.state.OptionsFacility, 'id', parseInt(value));
            if (getIndex != -1) {
                tempFacility.push({
                    "Id": this.state.OptionsFacility[getIndex].id,
                    "Name": this.state.OptionsFacility[getIndex].name
                });
            }

        });
        return tempFacility;
    }

    setFlagsIfChangesOccured(flag) {
        // The object definitions are pretty far down so just making a little shortcut to not have to type so much
        let _c = this.state.customerObjWithFlags.Customer.Options;
        let _dm = this.state.sharedCustomer.Options;

        // normally we would just compare objects to see if things had changed from the UI data model fromt he original data model from the GET
        // However not everything in the model is being used or is the same so we have to manually look at the pieces this we want
        // Little cusetmbersome and not super clean looking but works nonetheless
        if (flag == "AlertsNull") {
            // Currently there are no alerts mapped into the UI so this should always return the value it got from the GET
            return this.state.customerObjWithFlags[flag];
        }
        else if (flag == "StorageNull") {
            if (_c.Storage.FuelSurcharge !== _dm.Storage.FuelSurcharge ||
                _c.Storage.SetInvoiceDateToXactionConfirmDate !== _dm.Storage.SetInvoiceDateToXactionConfirmDate ||
                _c.Storage.AccountingCustomerName !== _dm.Storage.AccountingCustomerName) {
                return false;
            } else {
                return this.state.customerObjWithFlags[flag];
            }
        }
        else if (flag == "EdiNull") {
            // I was having a hard time keeping straight all my conditions so figured it was easier just to make an array and loop through it
            let _arr = ["CustInterchangeId", "CustInterchangeIDQual", "ThreePLInterchangeID", "ThreePLInterchangeIDQual", "TradingPartnerID"];

            for (let x = 0; x < _arr.length; x++) {
                if (_c.Edi[_arr[x]] != _dm.Edi[_arr[x]]) {
                    return false;
                }
            }
            return this.state.customerObjWithFlags[flag];
        }
        else if (flag == "ReceivingNull") {

            let _arr = ["CreateMultipleMus", "ReceiveAgainstAsns", "SuggestedPutAwayEnabled", "AutoFillTrackBys"];

            for (let x = 0; x < _arr.length; x++) {
                if (_c.Receiving[_arr[x]] != _dm.Receiving[_arr[x]]) {
                    return false;
                }
            }
            return this.state.customerObjWithFlags[flag];
        }
        else if (flag == "ShippingNull") {

            let _arr = ["NextMasterBolId", "AutoConfirmOrderOnTrackingUpdate", "FulfillmentInvoicing", "OrderQueue", "UPSAccountNumber", "UPSAccountZip", "FedExAccountNumber", "PrepopulateCarrierInfo", "PackingListLogoUrl", "PackingListFooterUrl"];
            for (let x = 0; x < _arr.length; x++) {
                if (_c.Shipping[_arr[x]] != _dm.Shipping[_arr[x]]) {
                    return false;
                }
            }

            //todo: what to do with Shipping.AsnPreCheck "RequireScacForAsn", "RequireAttachedDocuments"

            return this.state.customerObjWithFlags[flag];
        }
        else if (flag == "PackagingNull") {

            let _arr = ["UseVendorIdOnBol", "SpsCloseOnUpdate"];
            for (let x = 0; x < _arr.length; x++) {
                if (_c.Packaging[_arr[x]] != _dm.Packaging[_arr[x]]) {
                    return false;
                }
            }
            return this.state.customerObjWithFlags[flag];
        }
        else if (flag == "OtherSystemBehaviorsNull") {

            let _arr = ["FlexFields"];
            for (let x = 0; x < _arr.length; x++) {
                if (_c.OtherSystemBehaviors[_arr[x]] != _dm.OtherSystemBehaviors[_arr[x]]) {
                    return false;
                }
            }
            return this.state.customerObjWithFlags[flag];
        }
        else if (flag == "UserInterfaceNull") {

            let _arr = ["HideLocationsSelectedList", "ExcludeLocationsWithZeroInventory", "AutoCheckAutoReallocateOnReceive", "TransactionConfirmInvoiceCreateDefault", "MobileReceiptQuantityOneDefault", "ReallocateAtPickTime", "BrandingImageId"];
            for (let x = 0; x < _arr.length; x++) {
                if (_c.UserInterface[_arr[x]] != _dm.UserInterface[_arr[x]]) {
                    return false;
                }
            }
            return this.state.customerObjWithFlags[flag];
        }
        else if (flag == "SavedElementsNull") {
            // no data models yet so always just return what we originally got
            return this.state.customerObjWithFlags[flag];
        }
    }


    render() {
        return (
            <div className="customers-form" id="form_block" >
                <div className="cust-form-header">
                    <div className="tab-listing">
                        <ul>
                            <li >
                                <WmsScrollTo
                                    label={this.translate('Label_Customer_Basics')}
                                    windowId={this.props.windowId}
                                    scrollToId='collapseCustomerbasics'
                                    scrollBoxId='ManageCustomerScrollBox'
                                    wmsSelector={this.state.Selector + 'CustomerBasics'}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseCustomerTabContent_wrapper"} />
                            </li>
                            <li >
                                <WmsScrollTo
                                    label={this.translate('Label_Receipts')}
                                    windowId={this.props.windowId}
                                    scrollToId='collapseReceiptOption'
                                    scrollBoxId='ManageCustomerScrollBox'
                                    wmsSelector={this.state.Selector + 'ReceiptOption'}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseCustomerTabContent_wrapper"} />
                            </li>
                            <li >
                                <WmsScrollTo
                                    label={this.translate('Orders_Page_Title')}
                                    windowId={this.props.windowId}
                                    scrollToId='collapseShippingOption'
                                    scrollBoxId='ManageCustomerScrollBox'
                                    wmsSelector={this.state.Selector + 'ShippingOption'}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseCustomerTabContent_wrapper"} />
                            </li>
                            <li >
                                <WmsScrollTo
                                    label={this.translate('Autobath_Page_Title')}
                                    windowId={this.props.windowId}
                                    scrollToId='collapseAutobathOption'
                                    scrollBoxId='ManageCustomerScrollBox'
                                    wmsSelector={this.state.Selector + 'AutobathOption'}
                                    isTabSection={true}
                                    disabled={!this.state.rights.hasOwnProperty("Shipping.AutoBatchEnabled") || !this.state.rights.hasOwnProperty("orders.manage.batch")}
                                    tabCommonClassForHide={"collapseCustomerTabContent_wrapper"} />
                            </li>
                            <li >
                                <WmsScrollTo
                                    label={this.translate('Label_Customer_Proto_System')}
                                    windowId={this.props.windowId}
                                    scrollToId='collapseSystemOption'
                                    scrollBoxId='ManageCustomerScrollBox'
                                    wmsSelector={this.state.Selector + 'SystemOption'}
                                    isTabSection={true}
                                    tabCommonClassForHide={"collapseCustomerTabContent_wrapper"} />
                            </li>
                        </ul>
                    </div>
                    <div className="cust-topbtn-holder">
                        <WmsButton
                            label={this.translate('Label_Save')}
                            wmsSelector={this.state.Selector + 'ButtonSave'}
                            onClickFunc={this.onClickSaveCustomer}
                            icon='fa-floppy-o'
                            showToolTip={true}
                            tooltipText={
                                this.state.sharedCustomer.Options.Alerts.ReplyToAddressValidate == false ?
                                    this.state.sharedCustomer.Options.Alerts.ReplyToAddressValidateToolTip :
                                    (this.state.sharedCustomer.Options.Shipping.AutoBatchOptions.Enabled
                                        && this.state.sharedCustomer.Options.Shipping.AutoBatchOptions.Rules.length === 0) ?
                                        this.translate("Lable_Must_Need_To_Select_Auto_Batch_Rule") : this.translate('Label_Save')
                            }
                            disabled={this.state.sharedCustomer.CompanyInfo.CompanyName == ''
                                    || this.state.saveLoader
                                    || this.state.sharedCustomer.Options.Alerts.ReplyToAddressValidate == false
                                    || !this.state.isValidTempleteImageURL
                                    || (
                                        this.state.sharedCustomer.Options.Alerts.OverrideSentFrom ?
                                        !this.state.sharedCustomer.Options.Alerts.SenderName : false
                                    )
                                    || (this.state.sharedCustomer.Options.Shipping.AutoBatchOptions.Enabled
                                        && this.state.sharedCustomer.Options.Shipping.AutoBatchOptions.Rules.length === 0)
                                }
                            loading={this.state.saveLoader} />

                        <WmsButton
                            label={this.translate('Label_Cancel')}
                            wmsSelector={this.state.Selector + 'ButtonExit'}
                            onClickFunc={this.onClickExitCustomerForm}
                            icon={this.state.showDiscard == false ? 'fa-sign-out' : 'fa-times-circle'}
                            buttonType="orange" />
                    </div>                </div>
                <div id="ManageCustomerScrollBox" className={this.props.windowId + " tab-content"}>

                    <CustomersFormBasic
                        sharedCustomer={this.state.sharedCustomer}
                        customerDefaultFacilities={this.state.customerDefaultFacilities}
                        isAllSelectedFacilityAvailable={this.state.isAllSelectedFacilityAvailable}
                        onCustomerDataset={this.onCustomerDataset} />

                    <CustomersFormReceiptOption
                        sharedCustomer={this.state.sharedCustomer}
                        onCustomerDataset={this.onCustomerDataset} />

                    <CustomersFormShippingOption
                        sharedCustomer={this.state.sharedCustomer}
                        originalCustomerObject={this.state.originalCustomerObject}
                        onCustomerDataset={this.onCustomerDataset}
                        sharedAsnPrecheckedOptionsFlag={this.state.asnPrecheckedOptionsFlag}
                        onAsnPrecheckOptionsSet={this.onAsnPrecheckOptionsSet}
                        sharedCustomerEditUri={this.props.sharedCustomerEditUri}
                        setValidTemplateImageUrl={this.setValidTemplateImageUrl.bind(this)}
                        windowId={this.props.windowId}
                        />

                    <CustomerFormAutobatchOption
                        sharedCustomer={this.state.sharedCustomer}
                        onCustomerDataset={this.onCustomerDataset}
                    />

                    <CustomersFormSystemOption
                        sharedCustomer={this.state.sharedCustomer}
                        onCustomerDataset={this.onCustomerDataset} />

                </div>
            </div>
        );
    }
}