
export var _ = window._ ;

export var pendo = window.pendo;

export var kendo = window.kendo;

export var Big = window.Big ;
// export window.kendo;

export var smallParcelPackNShipTransactionIds = window.smallParcelPackNShipTransactionIds = [];
//WMS-19253 - convert date string to actual date object, for grid inline filter
if (window.JSON && !window.JSON.dateParser) {
  var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
  JSON.dateParser = function (key, value) {
      if (typeof value === 'string') {
          var a = reISO.exec(value);
          if (a)
              return new Date(value);
      }
      return value;
  };

}