import React from 'react';

//component
import WmsModel from './../../components/WmsModel/WmsModel.jsx';
import WmsCollapse from './../../components/WmsCollapse/WmsCollapse.jsx';
import WmsCheckBox from './../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsButton from './../../components/WmsButton/WmsButton.jsx';

import * as GlobalService from '../../global_services';

import style from './clone-existing-customer-data.scss';

export class CloneExistingCustomerData extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            selector: 'CloneExistingCustomer',
            seledCustomerSettings: {
                generalSettings:true,
                transactionSettings:false,
            },
            Rights: GlobalService.GetRights(),
        };
    }

    createCustomerclick = () => {
        this.props.onCloneCustomer(this.state.seledCustomerSettings);
    }
    handleOnchange = (event) => {
        this.setState({
            seledCustomerSettings: Object.assign(
                {},
                this.state.seledCustomerSettings,
                { [event.target.name]: event.target.checked }
            ),
        })
    }

    render() {
        return (
            <WmsModel
                id='cloneExistingCustomer'
                title={this.translate('Label_Clone_Existing_Customer')}
                width={800}
                height={359}
                onCloseFunc={this.props.onCloseCloneExstingCustomer}
                isOpen={this.props.sharedIsExistingCustomerOpen}
                customClass='wms-model-new-ui-wrapper'
                class="clone-existing-customer-wrapper">
                    <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'cloneDataOptions'}
                        headerLabel={this.translate("Label_Data_Options")}
                        showCollapseText={false}
                        showCollapseIcon={false} >
                                 <WmsCheckBox
                                    id="existingCustomerGeneralSettings"
                                    name="generalSettings"
                                    wmsSelector={this.state.selector + 'generalSettings'}
                                    onChangeFunc={this.handleOnchange}
                                    label={this.translate('Label_General_Customer_Settings')}
                                    checked={this.state.seledCustomerSettings.generalSettings}
                                    value=""
                                />

                                <WmsCheckBox
                                    id="existingCustomerTransactionSettings"
                                    name="transactionSettings"
                                    wmsSelector={this.state.selector + "transactionSettings"}
                                    onChangeFunc={this.handleOnchange}
                                    label={this.translate('Label_Pertransaction_Billing_Settings')}
                                    checked={this.state.seledCustomerSettings.transactionSettings}
                                    value=""
                                />

                            </WmsCollapse>
                    </div>

                    <div className="save-filter-button-wrapper footer-btn-holder">

                        <WmsButton
                            label={this.translate("Label_Cancel")}
                            wmsSelector={this.state.selector+'cancel'}
                            onClickFunc={this.props.onCloseCloneExstingCustomer}
                            buttonType={'orange'}
                            icon='fa-ban'
                        />
                        <WmsButton
                            label={this.translate('Window_Title_Label_CreateCustomer')}
                            wmsSelector={this.state.selector+'createCustomer'}
                            onClickFunc={this.createCustomerclick}
                            disabled={this.state.seledCustomerSettings.generalSettings == false && this.state.seledCustomerSettings.transactionSettings == false}
                            icon='fa-plus' />

                    </div>
            </WmsModel>
        );
    }
}