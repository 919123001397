import React, { Component } from 'react'
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx'
import WmsInput from '../../../components/WmsInput/WmsInput.jsx'

export class LandmarkAccountInfoComponent extends Component {
    constructor(props) {
        super(props)

        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {

        }
    }

    render() {
        return (
            <WmsCollapse
                id={"landmarkAccountInfoScreen"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id="accountNickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Nick_Name")}
                            value={this.props.currentCarrierObj.Nickname}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="client_id"
                            inputName="ClientId"
                            wmsSelector={this.props.selector + "clientId"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_DHL_Client_Id")}
                            value={this.props.currentCarrierObj.ClientId}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="username"
                            inputName="Username"
                            wmsSelector={this.props.selector + "username"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Username")}
                            required={true}
                            extraWrapperClass="w-42"
                            value={this.props.currentCarrierObj.Username}
                        />
                        <WmsInput
                            id="password"
                            inputType={"password"}
                            inputName="Password"
                            wmsSelector={this.props.selector + "password"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Login_Lbl_User_Beta_Password")}
                            required={true}
                            extraWrapperClass="w-42"
                            value={this.props.currentCarrierObj.Password}
                        />
                    </div>
                </div>
            </WmsCollapse>
        )
    }
}
