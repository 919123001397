
var axios = require('axios');
import * as GlobalService from './../../global_services';


export const getSmartParcelSettingsOptions = function (param_customer_uri) {
    return axios.get('/WebUI/Customers/SmartParcelSettings/SmartParcelSettingsOptions?customerUri=' + param_customer_uri).then(function (response) {
        return response
    });
}

export const saveSmartParcelSettings = function (param_settings_data) {
    return axios.put('/WebUI/Customers/SmartParcelSettings/SaveSmartParcelSettings',param_settings_data).then(function (response) {
        return response
    });
}