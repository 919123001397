import React from 'react';

//component
import { default as WmsButton } from '../components/WmsButton/WmsButton.jsx';
import { default as WmsGrid } from '../components/WmsGrid/WmsGrid.jsx';
import { default as WmsDropdown } from '../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse.jsx';
import { ObjectWms } from './../../../assets/ts/ObjectWms.ts';
import * as GridConfig from './grid_configuration.jsx';

//services
import * as itemService from './items.services.jsx';
import * as GlobalService from '../global_services';

export class ItemsFormStorageHandlingCharges extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;

        this.state = {
            selector: 'Item',
            columns:GridConfig.getStorageChargeGridColumns().columns,
            schema:GridConfig.getStorageChargeGridSchema().schema,
            numberOfSelection:0
        }
    }

    changeNumberOfSelection = () => {
        this.setState({
            numberOfSelection : 0
        });
    }

    onSelectRow = (kendogrid) =>{
        if (kendogrid.getKeys().length == 1) {
            this.props.onSetSlectedStorageChargeObj(kendogrid.getItem(kendogrid.getKeys()));
        }
        this.setState({
            numberOfSelection: kendogrid.getNumberOfSelections()
        });

    }
    editHandlingCharge = () => {
        this.props.onOpenEditStorageChargeModal();
    }
    clickEventOfContextMenu = (obj) => {
        if (obj.wmsid == 'editHandlingCharge') {
            this.props.onOpenEditStorageChargeModal();
        }
    }

    onGridDataBound = (e, kendoGrid) => {
        var grid = $("#StorageCharges").data('kendoGrid');
        var gridData = grid.dataSource.view();
        for (var i = 0; i < gridData.length; i++) {
            if (!gridData[i].inventoryUnitsPerStorageUnit) {
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-disabled-row");
            }
        }
    }

    render() {

        return (

            <WmsCollapse
                id={'ItemHanlingCharge'}
                headerLabel={this.translate('Label_Storage_Handling_Collapse_Label')}
                showCollapseText={false}
                showCollapseIcon={false} >

                    <WmsGrid
                        ref="storageCharges"
                        wmsSelector={this.state.selector + 'StorageCharges'}
                        gridKeySelection={'RowNumber'}
                        staticGridData={this.props.sharedStorageHandlingCharges}
                        gridName={'StorageCharges'}
                        isAlignIndexesByProperty={true}
                        virtual={false}
                        showResetButton={false}
                        id={'StorageCharges'}
                        onSelection={this.onSelectRow}
                        columns={this.state.columns}
                        schema={this.state.schema}
                        windowId={this.props.windowId}
                        shawCheckbox={false}
                        showSortColumns={false}
                        gridEditable={true}
                        clickEventOfContextMenu={this.clickEventOfContextMenu}
                        onDataBound={this.onGridDataBound}
                        menu={[{
                            "name": this.translate('Label_Edit'),
                            "value": [],
                            "disabled": this.state.numberOfSelection == 0,
                            "icon": "fa-pencil",
                            "color": "green",
                            'isContextMenu': true,
                            'isButtonMenu': true,
                            'wmsid': 'editHandlingCharge',
                            'wmsSelector': this.state.selector + "HandlingCharge",
                            'clickFunc': this.editHandlingCharge
                        }]}
                    />
            </WmsCollapse>
        )
    }
}