import axios from 'axios';
import * as GlobalService from "./../global_services";

export const getOrderImportPreferences = function (customerUri) {
    let data = {
        customerUri: customerUri
    }
    return axios.post('/WebUI/Settings/ImportOrderPreferences/GetPreferences', data)
        .then(function (response) {
            let tempData = response.data;

            let MyReturn = {};
            MyReturn.Customs = [];
            MyReturn.Defaults = [];

            tempData.Transforms.forEach((currentElem) => {
                if (currentElem.Id >= 1000) {
                    MyReturn.Customs.push(currentElem);
                } else {
                    MyReturn.Defaults.push(currentElem)
                }
            });

            //Adding the work flow to the return object
            MyReturn.Workflow = tempData.Workflow;

            return MyReturn;
        });
}

export const SaveOrderImportPreferences = function(custUri, workflow, parserIds) {
    let data = {
        customerUri: custUri,
        workflowViewModel: workflow,
        transformIds: parserIds
    }
    return axios.post('/WebUI/Settings/ImportOrderPreferences/SavePreferences', data)
        .then(function (response) {
            let MyReturn = {};
            MyReturn = response.data;
            return MyReturn;
        });
}
