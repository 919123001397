import axios from 'axios';
import * as GlobalService from "./../global_services";

export const gridExport = function (exportGridData, exportUrl) {
    return axios.post(exportUrl, exportGridData)
        .then(function (response) {
            return response;
        });

}
export const gridExporter = function (guide, exporterUrl) {
    return axios.get(exporterUrl + guide)
        .then(function (response) {
            return response;
        });
}
export const viewOrdersOnFindOrders = function (batchName, customer, facility) {
    return axios.post('/WebUI/Shipping/UpdateSession/SetFindOrdersFilterSessionBatch?batchName=' + batchName + '&customerId=' + customer + '&facilityId=' + facility)
        .then(function (response) {
            return response;
        });
}
export const downloadGridExportData = function (fileUrl) {
    return axios.get(fileUrl)
        .then(function (response) {
            return response;
        });
}
export const massShipAndCloseOrdersAsync = function (bulkShipData) {
    return axios.post('/WebUI/Shipping/FindOrders/MassShipAndCloseOrdersAsync', bulkShipData)
        .then(function (response) {
            return response;
        });
}

export const massShipAndCloseOrdersAsyncStatus = function (passuri) {
    return axios.post('/WebUI/Shipping/FindOrders/MassShipAndCloseOrdersAsyncStatus', { uri: passuri })
        .then(function (response) {
            return response;
        });
}
export const receiptUploadFileWithoutManage = function (custId, facilId, transId, file) {
    var MyReturn = {};
    var myUrl = '/WebUI/Receiving/ImportReceiptAsn/ParseAndImportFileAsync?customerId=' + custId;
    var data = {
        "facilityId": facilId,
        "transformId": transId,
        "content": file
    };
    return axios.post(myUrl, data)
        .then(function (response) {
            var myResult = response.data;
            if (response.status === 200) {
                if (myResult.Results.Faults.length == 0) {
                    MyReturn.Success = true;
                } else if (myResult.Results.Faults.length > 0) {
                    MyReturn.SuccessWithErrors = true;
                }
            } else {
                MyReturn.ParserError = true;
            }
            MyReturn.FinalResult = response.data;
            // that.AsnImport.ImportResults = MyReturn.FinalResult;
            return MyReturn;
        });
}
export const receiptParseAndImportFileAsyncStatus = function (uri) {
    var myUrl = '/WebUI/Receiving/ImportReceiptAsn/ParseAndImportFileAsyncStatus';
    return axios.post(myUrl, { uri: uri });
}

export const assemblyUploadFileWithoutManage = function (custId, facilId, skipInvalidAssemblies, file) {
    var MyReturn = {};
    var myUrl = `/WebUI/assemblies/ImportAssemblies/ParseAndImportFileAsync?customerId=${custId}&facilityId=${facilId}&skipInvalidAssemblies=${skipInvalidAssemblies}`;
    var data = {
        "content": file
    };
    return axios.post(myUrl, data)
        .then(function (response) {
            var myResult = response.data;
            if (response.status === 200) {
                if (myResult.Results.Faults.length == 0) {
                    MyReturn.Success = true;
                } else if (myResult.Results.Faults.length > 0) {
                    MyReturn.SuccessWithErrors = true;
                }
            } else {
                MyReturn.ParserError = true;
            }
            MyReturn.FinalResult = response.data;
            return MyReturn;
        });
}

export const assemblyParseAndImportFileAsyncStatus = function (uri) {
    var myUrl = '/WebUI/assemblies/ImportAssemblies/ParseAndImportFileAsyncStatus';
    return axios.post(myUrl, { uri: uri });
}
export const importParsedAsnAsyncStatus = function (uri) {
    var myUrl = '/WebUI/Receiving/ImportReceiptAsn/ImportParsedReceiversAsyncStatus';
    return axios.post(myUrl, { uri: uri });
}
export const importParsedAsn = function (id, preferences) {
    var myUrl = '/WebUI/Receiving/UpdateSession/ImportParsedReceiverAsync?customerId=' + id;
    var data = {
        "skipInvalidReceivers": preferences.skipInvalidReceivers
    };
    return axios.post(myUrl, data)
        .then(function (response) {
            var MyReturn = {};
            var myResult = response.data;
            //Code 0 is Success OR Success with errors
            //Code 1 is Session timeout or other generic error
            //Code 2 is a list of errors
            //Code 3 is another generic error
            if (response.status === 200) {
                if (myResult.ReceiversToCreate && myResult.Results.Faults.length == 0) { //the import was successful with no errors
                    MyReturn.Success = true;
                } else if (myResult.ReceiversToCreate && myResult.Results.Faults.length > 0) { //the import was successful with some errors
                    MyReturn.SuccessWithErrors = true;
                }
            } else {
                MyReturn.ImportError = true;
            }
            MyReturn.FinalResult = response.data.Results;
            MyReturn.FinalResult.Uri = response.data.Uri;
            // that.AsnImport.ImportResults = MyReturn.FinalResult;
            return MyReturn;
        });

}

export const importParsedOrders = function (id, preferences) {
    var myUrl = '/WebUI/Shipping/UpdateSession/ImportParsedOrdersAsync?customerId=' + id + '&skipShortOrders=' + preferences.skipShortOrders + '&skipInvalidOrders=' + preferences.skipInvalidOrders
    return axios.post(myUrl)
        .then(function (response) {
            // that.OrderImport.ImportResults = response.data.Results;
            return response.data;
        });
}

export const importParsedOrdersAsyncStatus = function (uri) {
    var MyReturn = {};
    var myUrl = '/WebUI/Shipping/ImportOrders/ImportParsedOrdersAsyncStatus?uri=' + uri;
    return axios.post(myUrl);
}
export const uploadFileWithoutManage = function (custId, facilId, transId, file, fileName) {

    transId = transId || transId === 0 ? '&transformId=' + transId : '&transformId=';
    var myUrl = '/WebUI/Shipping/ImportOrders/ParseAndImportFileAsync?customerId=' + custId + '&facilityId=' + facilId + transId;

    return axios.post(myUrl, { content: file, fileName: fileName });
}

export const parseAndImportFileAsyncStatus = function (uri) {
    var myUrl = '/WebUI/Shipping/ImportOrders/ParseAndImportFileAsyncStatus?uri=' + uri;

    return axios.post(myUrl)
        .then(function (response) {
            // that.OrderImport.ImportResults = response.data;
            return response;
        });
}

export const asyncMassReceiptConfirmer = function (bulkShipData) {
   return axios.post('/WebUI/Receiving/FindReceivings/AsyncMassReceiptConfirmer',bulkShipData).then(function (response) {
       return response;
   });
}

export const asyncMassReceiptConfirmerStatus = function (passuri) {
   return axios.post('/WebUI/Receiving/FindReceivings/AsyncMassReceiptConfirmerStatus',{ uri: passuri }).then(function (response) {
       return response;
   });
}

export const callServiceOnUrl = function (passuri) {
    return axios.get('/WebUI/'+passuri).then(function (response) {
        return response;
    });
 }
export const apiCallMassProgress = function (passuri) {
    return axios.get('/WebUI/'+passuri).then(function (response) {
        return response;
    });
}
export const getPackageRestockAlertsData = function(userLoginId) {
    return axios.get('/webui/shipping/packagerestockalerts/PackageRestockAlertsData?userLoginId='+ userLoginId).then( function (response){
        return response;
    });
}
export const deletePackageRestockAlerts = function(uri) {
    return axios.delete('/webui/shipping/packagerestockalerts/PackageRestockAlert?uri='+uri).then( function (response){
        return response;
    });
}

export const reallocateOrdersStarter = function (ids, facilityId) {
    // You can now only reallocate Orders on the same facilityId
    // There is also an option to skipShortOrders, that means to not allocate orders unless they can be fully allocated
    // This is an optional parameter that is a setting, the default is false, so we need to check to see if it is set when
    // We attempt to allocate.  This can be done by looking at the getCustomerFacilityList call but we need a facilityId to look
    // at that

    // Since we should never be able to allocate unless it is from the same facility we should never be able to get to this point
    // Without a facilityId, so lets see if they want to skipShortOrders


    var facilityDict = GlobalService.getFacilityDictList();
    var skipShortOrders = false;
    //
    if(facilityDict.hasOwnProperty(facilityId)){
        if(facilityDict[facilityId].hasOwnProperty("preventPartialOrderManualAllocation")){
            skipShortOrders = facilityDict[facilityId].preventPartialOrderManualAllocation;
        }
    }
    else{
        console.log("WE SHOULD NEVER REACH THIS POINT, THE GRIDS FACILITY LIST AND THE GETCUSTOMERFACILITY AND CACHED VERSION LIST ARE NOT IN ALIGNMENT");
    }

    return axios.post('/WebUI/FindOrders/ReallocateOrdersStarter?skipShortOrders='+skipShortOrders, ids)
        .then(function (response) {
            return response;
        });
}
