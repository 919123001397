
import { BaseRequirements } from './BaseRequirements';
import { DictionaryWms } from './DictionaryWms';

export class ObjectWms implements BaseRequirements {
	/**
	* Simple contains method for Dictionary
	* @params key:string
	* @return void
	*/
    public static test(message: string): void {
        console.log('1 : ', message);
    }

    public test(message: string): void {
        console.log('2 : ', message);
    }

    /**
     * Finds the index of array of objects with a specific parameter and value
     * @params arrayObjects, property, value
     * @return number
     */
    public static FindIndexByProperty(arrayObjects, property, value) {
        let result = arrayObjects.map(function (obj) {
            return obj[property];
        }).indexOf(value);
        if (result) {
            return result;
        } else if (result == 0) {
            return 0;
        } else {
            return false;
        }
    };

    /**
     * check specific key value in object is valid or not
     * @params list, key
     * @return myreturn boolean
     */
    public static doesKeyContainValidValue(list, key) {
        let myreturn = false;
        for (let x = 0; x < list.length; x++) {
            if (list[x][key] != "" && list[x][key] != " " && list[x][key] != null) {
                myreturn = true;
                break;
            }
        }
        return myreturn;
    };

    /**
     * For two objects align indexes off of a property
     * @params firstObject, secondObject
     * @return second Object aligned with indexes of first
     */
    public static alignIndexesByProperty(firstArrayObjects, secondArrayObjects, parameter) {
            let finalArray = [];
            if (firstArrayObjects.length !== secondArrayObjects.length) {
                for (let x = 0; x < secondArrayObjects.length; x++) {
                    let value = secondArrayObjects[x][parameter];
                    let secondIndex = this.FindIndexByProperty(firstArrayObjects, parameter, value);
                    let mergedObject = null;
                    if(secondIndex == -1){
                        mergedObject = this.merge(secondArrayObjects[x], firstArrayObjects[x]);
                    }else{
                        mergedObject = this.merge(secondArrayObjects[x], firstArrayObjects[secondIndex]);
                    }

                    finalArray.push(mergedObject);
                }
                return finalArray;
                // throw "Bs.ts, the amount of indexes that you are trying to compare are unequal";
            } else {
                for (let x = 0; x < firstArrayObjects.length; x++) {
                    let value = firstArrayObjects[x][parameter];
                    let secondIndex = this.FindIndexByProperty(secondArrayObjects, parameter, value);
                    let mergedObject = this.merge(firstArrayObjects[x], secondArrayObjects[secondIndex]);
                    finalArray.push(mergedObject);
                }
            }
            return finalArray;
    };

    /**
     * For two objects align indexes off of a property
     * @params firstObject, secondObject
     * @return second Object aligned with indexes of first
     */
    public static alignIndexesByPropertyVirtual(firstArrayObjects, secondArrayObjects, parameter) {
        let finalArray = [];
        if (firstArrayObjects.length !== secondArrayObjects.length) {
            for (let x = 0; x < secondArrayObjects.length; x++) {
                let value = secondArrayObjects[x][parameter];
                let secondIndex = this.FindIndexByProperty(firstArrayObjects, parameter, value);
                let mergedObject = null;
                if(secondIndex == -1){
                    mergedObject = this.merge(secondArrayObjects[x], firstArrayObjects[x]);
                }else{
                    mergedObject = this.merge(secondArrayObjects[x], firstArrayObjects[secondIndex]);
                }
                finalArray.push(mergedObject);
            }
        } else {
            for (let x = 0; x < firstArrayObjects.length; x++) {
                let value = firstArrayObjects[x][parameter];
                let secondIndex = this.FindIndexByProperty(secondArrayObjects, parameter, value);
                if (secondIndex >= 0) {
                    let mergedObject = this.merge(firstArrayObjects[x], secondArrayObjects[secondIndex]);
                    finalArray.push(mergedObject);
                }
            }
        }
        return finalArray;
    };
    /**
     * Merges Two Objects together, in case of conflicting parameters, the first Object's parameter is used
     * @params firstObject, Second Object
     * @return mergedObject
     */
    public static merge(firstObject, secondObject) {
        for (let key in secondObject) {
            if (secondObject.hasOwnProperty(key) && !firstObject.hasOwnProperty(key)) {
                firstObject[key] = secondObject[key];
            }
        }
        return firstObject;
    };

    /**
     * Simple method to find the index of a key value pair, returns -1 if not found
     * @params list/array, the value to search and the value to replace
     * @return list
     */
    public static getIndexBasedUponKeyValuePair(list, key, value): any {
        let indexReturn = -1;
        for (let x = 0; x < list.length; x++) {
            if (list[x][key] == value) {
                indexReturn = x;
                break;
            }
        }
        return indexReturn;
    };

    /**
     * Simple method to replace all values in a list of objects
     * @params list/array, the value to search and the value to replace
     * @return list
     */
    public static removeObjFromListOnParameterValue(list, keyName, keyValue): any {
        let returnArr = [];
        for (let x = 0; x < list.length; x++) {
            if (list[x].hasOwnProperty(keyName)) {
                if (list[x][keyName] != keyValue) {
                    returnArr.push(list[x]);
                } else { }
            } else {
                returnArr.push(list[x]);
            }
        }
        return returnArr;
    };

    public static removeObjFromListBasedOnParameters(list, keyName, keyValues): any {
        let returnArr = [];
        for (let x = 0; x < list.length; x++) {
            if (list[x].hasOwnProperty(keyName)) {
                if (list[x][keyName] != keyValues[0] && list[x][keyName] != keyValues[1]) {
                    returnArr.push(list[x]);
                } else { }
            } else {
                returnArr.push(list[x]);
            }
        }
        return returnArr;
    };

    /**
     * Can you find a valid value, in other words, does the key have only empty strings or nulls etc or does it actually have a value
     * @params list, key
     * @return array
     */
    public static returnDistinctListBasedOffOfCertainKey = function (list1, list2, key) {
        return list1.filter(function (obj) {
            return list2.map(function (checkobj) {
                return checkobj[key];
            }).indexOf(obj[key]) >= 0 ? false : true;
        });
    };

    /**
    * Finds the object by a parameter value in an array of Objects
    * @params arrayObjects, property, value
    * @return Object
    */
   public static ReturnObjectByProperty = function (arrayObjects, property, value) {
       var result = arrayObjects.filter(function (obj) {
           return obj[property] == value;
       });
       if (result === "") {
           result = false;
       }
       if (result) {
           return result[0];
       } else {
           return false;
       }
   }
}
