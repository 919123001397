import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import WmsModel from "../../../components/WmsModel/WmsModel.jsx";
import WmsButton from "../../../components/WmsButton/WmsButton.jsx";
import WmsDropdown from "../../../components/WmsDropdown/WmsDropdown.jsx";
import WmsCheckBox from "../../../components/WmsCheckBox/WmsCheckBox.jsx";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
//services
import * as GlobalService from "../../../global_services";
import * as SelectCarrierAccounts from "../../small-parcel-settings.services.jsx";

import "./carrier-select-accounts-modal.scss";

declare var window: any;
const { $ } = window;
const USPSCarriers = ["stamps.com", "endicia", "buku", "pitneybowesexp"];

interface Props {
    wmsSelector: string;
    isOpenSelectAccountModal: boolean;
    onCloseSelectAccountModal: any;
    sharedShipEngineCarrier: any;
    smallParcelSettingsData: any;
    sharedSelectedCustomer: any;
    selectedCarrierAccounts: any;
    carrierAccountsData: any;
    onSaveSelectCarrierAccounts: any;
}

const CarrierSelectAccountModal: React.FC<Props> = (props: Props): any => {
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [carrierOptions, setCarrierOptions] = useState([]);
    const [carrierAccounts, setCarrierAccounts] = useState([]);
    const [savedCarrierAccounts, setSavedCarrierAccounts] = useState(
        props.smallParcelSettingsData.SmallParcelRateShoppingCarrierAccounts
            ? props.smallParcelSettingsData.SmallParcelRateShoppingCarrierAccounts.split(",")
            : []
    );

    const onSaveCarrierAccounts = () => {
        setIsSaveLoading(true);
        let settingsData = Object.assign({}, props.smallParcelSettingsData);
        settingsData.SmallParcelRateShoppingCarrierAccounts = savedCarrierAccounts.join(",");
        settingsData.SmallParcelRateShoppingLogic = JSON.stringify(settingsData.SmallParcelRateShoppingLogic);
        let paramSettingsData = {
            "custUri": props.sharedSelectedCustomer.uri,
            "spsData": settingsData,
        };

        SelectCarrierAccounts.saveSmartParcelSettings(paramSettingsData).then((response) => {
            if (response && response.status === 200) {
                GlobalService.notification("success", window.translate("Window_Title_Label_SmartParcel_Data_Success"));
                response.data.SmallParcelRateShoppingLogic = JSON.parse(response.data.SmallParcelRateShoppingLogic);
                props.onSaveSelectCarrierAccounts(response.data);
                setIsSaveLoading(false);
                props.onCloseSelectAccountModal();
            }
        });
    };

    const handleCarrierChangeEvent = (event: any) => {
        if (event.target.value) {
            let tempCarrierAccounts = props.carrierAccountsData.filter(function (el: any) {
                if (event.target.value === "USPS") {
                    return USPSCarriers.includes(el.Carrier.toLowerCase());
                } else {
                    return el.Carrier.toLowerCase() === event.target.value.toLowerCase();
                }
            });
            setCarrierAccounts(tempCarrierAccounts);
        }
    };

    const handleChangeCarrierAccountOptions = (event: any) => {
        const isCarrierChecked = event.target.checked;
        const checkedCarrierName = event.target.name;

        setSavedCarrierAccounts((prevSavedCarrierAccounts: any[]) => {
            if (isCarrierChecked) {
                return [...prevSavedCarrierAccounts, checkedCarrierName];
            } else {
                return prevSavedCarrierAccounts.filter((el: any) => el !== checkedCarrierName);
            }
        });
    };

    useEffect(() => {
        kendo.ui.progress($("#selectCarrierAccountModal"), true);
        if (props.selectedCarrierAccounts.length && props.sharedShipEngineCarrier) {
            let tempCarrierOptions = [];
            for (let i = 0; i < props.selectedCarrierAccounts.length; i++) {
                let selectedElement = props.selectedCarrierAccounts[`${i}`];
                if (selectedElement === "DHLGM") {
                    selectedElement = "DHL eCommerce";
                } else if (selectedElement === "FDXE") {
                    selectedElement = "FedEx";
                } else if (selectedElement === "ENDCA") {
                    selectedElement = "USPS";
                } else if (selectedElement === "PBCS") {
                    selectedElement = "PitneyBowesStd";
                } else if (selectedElement === "AMZN") {
                    selectedElement = "Amazon Buy";
                }
                let tempObject = props.sharedShipEngineCarrier.filter(function (el: any) {
                    return el.Carrier === selectedElement;
                })[0];

                if (tempObject !== undefined) {
                    tempCarrierOptions.push(tempObject);
                }
            }
            // Add First Carrier Account
            let tempCarrierAccounts = props.carrierAccountsData.filter(function (el: any) {
                if (tempCarrierOptions.length > 0 && tempCarrierOptions[0].Carrier === "USPS") {
                    return USPSCarriers.includes(el.Carrier.toLowerCase());
                } else {
                    return el.Carrier.toLowerCase() === tempCarrierOptions[0].Carrier.toLowerCase();
                }
            });
            setCarrierAccounts(tempCarrierAccounts);
            setCarrierOptions(tempCarrierOptions);
        }
        kendo.ui.progress($("#selectCarrierAccountModal"), false);
    }, [props.selectedCarrierAccounts, props.sharedShipEngineCarrier, props.carrierAccountsData]);

    return (
        <WmsModel
            id={"selectCarrierAccountModal"}
            title={window.translate("Label_Select_Carrier_Accounts_For_Rate_Shopping")}
            width={800}
            height={703}
            class="carrier-markup-model-wrapper"
            onCloseFunc={props.onCloseSelectAccountModal}
            isOpen={props.isOpenSelectAccountModal}
            customClass="wms-model-new-ui-wrapper"
        >
            <div className="model-content-wrapper">
                <WmsCollapse
                    id={"selectCarrierAccountCollapse"}
                    headerLabel={window.translate("Label_Header_Select_Carrier_Account")}
                    showCollapseText={false}
                    showCollapseIcon={false}
                >
                    <div className="fullWidth">
                        <WmsDropdown
                            id="carrierSelectedIndex"
                            name="carrierSelectedIndex"
                            label={window.translate("Label_Carrier")}
                            required={true}
                            wmsSelector={props.wmsSelector + "carriers"}
                            onChangeFunc={handleCarrierChangeEvent}
                            options={carrierOptions}
                            valueField="Carrier"
                            textField="DisplayName"
                        />
                    </div>
                    {carrierAccounts.length > 0 && <hr />}
                    <div className="carrier-account-options-checkbox">
                        {carrierAccounts?.map((item, index) => (
                            <WmsCheckBox
                                id={item.AccountNumber}
                                key={"carrier-account-options-checkbox" + index}
                                name={item.AccountNumber}
                                onChangeFunc={handleChangeCarrierAccountOptions}
                                label={item.Carrier + " " + item.NickName}
                                checked={savedCarrierAccounts.indexOf(item.AccountNumber) !== -1}
                                wmsSelector={props.wmsSelector + item.AccountNumber}
                                parentClassName="column"
                            />
                        ))}
                    </div>
                </WmsCollapse>
            </div>
            <div className="footer-btn-holder">
                <WmsButton
                    label={window.translate("Label_Cancel")}
                    wmsSelector={props.wmsSelector + "cancel"}
                    onClickFunc={props.onCloseSelectAccountModal}
                    icon="fa-ban"
                    buttonType="orange"
                />
                <WmsButton
                    label={window.translate("Label_Save")}
                    wmsSelector={props.wmsSelector + "Save"}
                    onClickFunc={onSaveCarrierAccounts}
                    loading={isSaveLoading}
                    icon="fa-floppy-o"
                />
            </div>
        </WmsModel>
    );
};

CarrierSelectAccountModal.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    isOpenSelectAccountModal: PropTypes.bool.isRequired,
    onCloseSelectAccountModal: PropTypes.any.isRequired,
    sharedShipEngineCarrier: PropTypes.any.isRequired,
    smallParcelSettingsData: PropTypes.any.isRequired,
    sharedSelectedCustomer: PropTypes.any.isRequired,
    selectedCarrierAccounts: PropTypes.any.isRequired,
    carrierAccountsData: PropTypes.any.isRequired,
    onSaveSelectCarrierAccounts: PropTypes.any.isRequired,
};

CarrierSelectAccountModal.defaultProps = {
    wmsSelector: "",
    onCloseSelectAccountModal: (): void => {},
};

export default React.memo(CarrierSelectAccountModal);
