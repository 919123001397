import React, { Component } from "react";

import VirtualGridComponent from '../../components_proto/VirtualGridComponent/VirtualGridComponent.jsx';
import WmsConfirmModel from '../../components/WmsConfirmModel/WmsConfirmModel.jsx';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import { KendoGridWmsVirtual } from '../../../../assets/ts/KendoGridWmsVirtual.ts';
import { ArrayWms } from '../../../../assets/ts/ArrayWms.ts';
import { AddEditColumnModel } from '../../components/AddEditColumn/add-edit-columns.component.jsx';
import { PackageDefModal } from './package-def-modal.component.jsx';
import * as packagingTypeService from './packaging-type.services.jsx'
import * as GridConfig from './packaging-type-grid-configuration.virtual.jsx';
import * as GlobalService from '../../global_services';
import * as packagingTypeMetaDataService from './packaging-type-meta-data.service';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsDropdown from "../../components/WmsDropdown/WmsDropdown";
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsDropdownList from "../../components/WmsDropdownList/WmsDropdownList.jsx";

export class PackagingTypesGrid extends Component {
    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.Rights = GlobalService.GetRights();
        let PackagingTypes_GridHeaders = StringWms.convertSringToOption(this.translate('PackagingTypes_GridHeaders'));
        this.packagingTypesGridId = "PackagingTypesGrid";
        this.gridDomSelector = (this.props.windowId) ? '.' + this.props.windowId + ' #' + this.packagingTypesGridId : '#' + this.packagingTypesGridId;
        this.gridName = "PackagingTypesGrid";
        this.defaultColumns = GridConfig.getPackagingTypeGridDemoColumns(PackagingTypes_GridHeaders).columns;
        this.allColumnList = GridConfig.getPackagingTypeGridAllColumnList(PackagingTypes_GridHeaders);
        this.schema = GridConfig.getPackagingTypeGridDemoSchema();
        this.packagingTypesGridConfig = packagingTypeMetaDataService.getPackagingTypesGridOptionInMetaData();
        this.localKendoGrid = new KendoGridWmsVirtual(this.packagingTypesGridId, this.props.windowId, 'Id');

        this.state = {
            loading: false,
            selectedPackageType: null,
            selectedPackageTypeName: null,
            allPackageTypes: [],
            staticGridData: [],
            isOpenColumnModal: false,
            allSortColumnList: [],
            filterText: "",
            fullFilterText: "",
            recordsCount: 0,
            isOpenPackageDefModal: false,
            isOpenConfirmModel: false,
            newPkgType: true,
            threePlOptions: null,
            alertRecipientLoading: false,
            isLoadingUserOptions: false,
        };
    }
    componentDidMount() {
        this.getListOfPackageDefs();

        // sort all column list title
        let allSortColumnList = this.allColumnList.sort(function (first, second) {

            var columnTitle1 = first.title.toUpperCase(); // ignore upper and lowercase
            var columnTitle2 = second.title.toUpperCase(); // ignore upper and lowercase

            if (columnTitle1 < columnTitle2) { return -1; }

            if (columnTitle1 > columnTitle2) { return 1; }
            // names must be equal
            return 0;
        });
        this.getThreePlOptions();
        this.setState({ allSortColumnList: allSortColumnList });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sharedPackagingTypesFilter != prevProps.sharedPackagingTypesFilter) {
            this.clearAndRepaintGridSelection();
            this.getListOfPackageDefsByCustomer(this.state.allPackageTypes);
        }
    }
    refreshGrid = () => {
        this.clearAndRepaintGridSelection();
        this.getListOfPackageDefsByCustomer(this.state.allPackageTypes);
    }

    getListOfPackageDefs = () => {
        this.setState({ loading: true });
        kendo.ui.progress($('#PackagingTypesGrid'), true);

        packagingTypeService.getPackageList().then((response) => {
            this.setState({
                allPackageTypes: response.data
            })
            this.getListOfPackageDefsByCustomer(response.data)
        }).finally(() => {
            this.setState({ loading: false });
            this.clearAndRepaintGridSelection();
            kendo.ui.progress($('#PackagingTypesGrid'), false);
        });
    }

    clearAndRepaintGridSelection = () => {
        if (this.localKendoGrid) {
            this.localKendoGrid.clearSelections(() => {
                this.localKendoGrid.repaintSelections();
            });
        }
    }

    getListOfPackageDefsByCustomer = (allPackageTypes) => {
        let pkgTypesByCustomers = allPackageTypes;
        let selectedCustomerId = this.props.sharedPackagingTypesFilter.IndexOptionsCustomerId;
        let MaterialTypeFilter = this.props.sharedPackagingTypesFilter.IndexOptionsMaterialType;

        pkgTypesByCustomers = _.filter(allPackageTypes, (pkgTyp) => {

            var isSearchMatch = true;
            if(MaterialTypeFilter != "All" && pkgTyp.MaterialType != MaterialTypeFilter){
                isSearchMatch = false
            }
            if (pkgTyp.CustomerIdentifiers.length > 0) {
                // var customerNameArr = [];
                // $.grep(GlobalService.getCustomerList(), (element) => {
                //     if (pkgTyp.CustomerIdentifiers.indexOf(element.id) != -1) {
                //         customerNameArr.push(element.name);
                //     }
                // });
                pkgTyp.customerName = pkgTyp.CustomerIdentifiers.map(el => el.Name);
            } else {
                pkgTyp.customerName = 'All';
            }

            if (selectedCustomerId > 0) {
                if (_.includes(pkgTyp.CustomerIdentifiers.map(el => el.Id), parseInt(selectedCustomerId))) {
                    return isSearchMatch ? pkgTyp : false;
                }
                return false;
            }

            // return pkgTyp;
            return isSearchMatch ? pkgTyp : false;
        });

        this.setState({
            recordsCount: pkgTypesByCustomers.length,
            staticGridData: pkgTypesByCustomers,
            selectedPackageType: null,
            selectedPackageTypeName: null
        })
    }

    selectRow = (KendoGrid) => {
        let selectedPackageType = null;
        this.localKendoGrid = KendoGrid;
        if (KendoGrid.getKeys().length == 1) {
            let allPkgTypes = Object.assign({}, this.state.allPackageTypes);
            let pkgId = KendoGrid.getKeys()[0];
            selectedPackageType = _.find(allPkgTypes, (pkgType) => {
                return (pkgType.Uri == pkgId)
            });
        }

        this.setState({
            selectedPackageType: selectedPackageType,
            selectedPackageTypeName: (selectedPackageType) ? selectedPackageType.Name : '',
        });
    }

    onToggleColumnModal = (isOpen) => {
        this.setState({
            isOpenColumnModal: isOpen
        });
    }

    openPackageDefModal = (isNewPkg = false) => {
        this.setState({
            isOpenPackageDefModal: true,
            newPkgType: isNewPkg
        });
    }

    closePackageDefModal = () => {
        this.setState({
            isOpenPackageDefModal: false
        });
    }

    toggleConfirmDeleteModal = (isOpen) => {
        this.setState({ isOpenConfirmModel: isOpen })
    }

    deletePackageType = () => {
        if (this.state.selectedPackageType) {
            var url = "/WebUI/Shipping/PackageDefs/PackageDef?uri="+this.state.selectedPackageType.Uri;
            var successText = "Label_Package_remove_Success";
            if (this.state.selectedPackageType.MaterialType) {
                url = "/WebUI/Shipping/PackageInserts/PackageInsert?uri="+this.state.selectedPackageType.Uri;
                successText = "Label_Insert_remove_Success";
            }
            packagingTypeService.deletePackage(url).then((response) => {
                this.getListOfPackageDefs();
                GlobalService.notification('success', this.translate(successText));
            }).finally(() => {
                this.setState({
                    loading: false,
                    isOpenConfirmModel: false
                });
            });
        }
    }

    savePackageDef = () => {
        this.getListOfPackageDefs();
    }

    // on grid column sort save changes in meta data object
    onSort = (event) => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.localKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
        packagingTypeMetaDataService.setPackagingTypesGridOptionInMetaData(finalSubmission);
    }

    // on grid column reorder save changes in meta data object
    onColumnReorder = (event) => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = this.localKendoGrid.prepareGridOptionsForStorage(switchedArray, sortArray, grid.dataSource.filter());
        packagingTypeMetaDataService.setPackagingTypesGridOptionInMetaData(finalSubmission);
    }

    // on grid column resize save changes in meta data object
    onColumnResize = () => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        packagingTypeMetaDataService.setPackagingTypesGridOptionInMetaData(finalSubmission);
    }

    // on click grid clear sort menu
    onClickClearSorts = () => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var finalSubmission = this.localKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        packagingTypeMetaDataService.setPackagingTypesGridOptionInMetaData(finalSubmission);
    }

    // on click grid reset default grid layout menu
    onClickResetGrid = () => {
        var finalSubmission = {
            "optionsData": {}
        };
        packagingTypeMetaDataService.setPackagingTypesGridOptionInMetaData(finalSubmission);
    }

    // on grid filter selection
    onGridFilterApplied = () => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            this.setFilterAppliedText(dataSource.filter());

            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = this.localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, dataSource.filter());

            packagingTypeMetaDataService.setPackagingTypesGridOptionInMetaData(finalSubmission);

        }, 100);
    }

    setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            this.setState({
                filterText: "",
                fullFilterText: ""
            });
            return;
        }
        var filterConditionTitle = {
            "lte": "Is Less Than Or Equal To",
            "eq": "Is Equal To",
            "lt": "Is Less Than",
            "neq": "Is Not Equal To",
            "gte": "Is Greater Than Or Equal To",
            "gt": "Is Greater Than",
            "startswith": "Starts With",
            "endswith": "Ends With",
            "contains": "Contains",
            "doesnotcontain": "Does Not Contain",
            "isnull": "Is Null",
            "isnotnull": "Is Not Null",
            "isempty": "Is Empty",
            "isnotempty": "Is Not Empty",
            "isnullorempty": "Has No Value",
            "isnotnullorempty": "Has Value",
        };

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;
        var self = this;
        filterList.map(function (item) {
            var columnObj = self.allColumnList.find(x => x.field === item.field);
            if (columnObj) {
                var title = columnObj.title;
                filterText += title + " : " + item.value + ',';
                fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            }
        });
        filterText = filterText.slice(0, -1);
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var dataSource = grid.dataSource;

        this.setState({
            filterText: filterText,
            fullFilterText: fullFilterText
        });
    }

    // save column hide/show data in meta data
    saveChangesInMetaData = () => {
        var grid = $(this.gridDomSelector).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sort,
            grid.dataSource.filter()
        );
        packagingTypeMetaDataService.setPackagingTypesGridOptionInMetaData(finalSubmission);
    }

    clickEventOfContextMenu = (obj, e) => {
        if (obj.wmsid == 'packagingTypesEdit') {
            this.openPackageDefModal(false)
        } else if (obj.wmsid == 'packagingTypesDelete') {
            this.toggleConfirmDeleteModal(true)
        }
    }

    getThreePlOptions = () => {
        this.setState({isLoadingUserOptions: true});
        packagingTypeService.getThreePlOptions().then((response) => {
            this.setState({threePlOptions: response.data});
        }).finally(() => {
            this.setState({
                isLoadingUserOptions: false,
            });
        });
    }
    handleAlertRecipient = (event) => {
        var tempSettings = Object.assign({}, this.state.threePlOptions);
        tempSettings.Shipping.GlobalRecipient = event.dataItem.UserId;
        this.setState({threePlOptions: tempSettings});
    }
    saveAlertRecipient = () => {
        this.setState({isLoadingUserOptions: true});

        packagingTypeService.saveThreePlOptions(this.state.threePlOptions)
            .then((response) => {
                GlobalService.notification("success", this.translate("Message_Save_Alert_recipient_success"));
            })
            .finally(() => {
                this.setState({
                    isLoadingUserOptions: false,
                });
            });
    }

    render() {
        return (
            <>
            <div className="new-layout-grid-wrapper">
                    <WmsCollapse id={'PakingItemTypeCollepsArea'} headerLabel={this.translate('Label_Packing_Item_Type')} showCollapseText={false}>
                        <div className="aleart-reciplent-setting-area">
                            <div className="w-40">

                                <WmsDropdownList
                                    id="alertReciplent"
                                    name="alertReciplent"
                                    label={this.translate('Label_Alert_Recipient')}
                                    optionsLabel={this.translate("Label_Select_Recipient")}
                                    wmsSelector={this.selector + "Alert_Recipient"}
                                    onSelect={this.handleAlertRecipient}
                                    value={this.state.threePlOptions?.Shipping?.GlobalRecipient}
                                    onChangeFunc={() => {}}
                                    disabled={this.state.isLoadingUserOptions}
                                    textField="Name"
                                    valueField="UserId"
                                    windowId={this.props.windowId}
                                    parentclassName="form-group"
                                    template="<span style='white-space: nowrap;text-overflow: ellipsis;width: 270px;display: block;overflow: hidden'>#if (Email == null) {# #: Name# #}else{# #:Name# (#:Email#)  #}#</span>"
                                    extraWrapperClass=""
                                    popupClassName="drop-down-list-unset-height"
                                    // required={true}
                                    virtualObj={{
                                        itemHeight: 45,
                                        valueMapper: (options) => {
                                            var valueMapperOptions = options;
                                            $.ajax({
                                                url: "/WebUI/users/users/UserDropDownIndexRead",
                                                type: "POST",
                                                dataType: "json",
                                                data: {
                                                    id: valueMapperOptions.value || 0,
                                                    userAccess: 1,
                                                },
                                                success: function (data) {
                                                    options.success(data);
                                                },
                                            });
                                        },
                                    }}
                                    dataSourceObj={{
                                        type: "aspnetmvc-ajax",
                                        pageSize: 30,
                                        serverPaging: true,
                                        serverFiltering: true,
                                        transport: {
                                            read: {
                                                url: "/WebUI/users/users/userDropDownRead",
                                                type: "POST",
                                                data: {
                                                    userAccess: 1,
                                                },
                                            },
                                        },
                                        schema: {
                                            data: "Data",
                                            total: "Total",
                                        },
                                    }}
                                />

                            </div>
                            <WmsButton
                                label={this.translate('Label_Save')}
                                wmsSelector={this.props.selector + "Save"}
                                onClickFunc={this.saveAlertRecipient}
                                loading={this.state.isLoadingUserOptions}
                                icon={'fa-floppy-o'}
                            />
                        </div>
                    </WmsCollapse>
                <VirtualGridComponent
                    ref="packagingTypesGrid"
                    loading={this.state.loading}
                    staticGridData={this.state.staticGridData}
                    wmsSelector={this.props.selector + "Grid"}
                    showSortColumns={true}
                    gridKeySelection={'Uri'}
                    showGridResult={true}
                    showGridSummary={true}
                    totalResult={this.state.recordsCount}
                    gridName={this.gridName}
                    filterText={this.state.filterText}
                    fullFilterText={this.state.fullFilterText}
                    virtual={true}
                    metaDataFieldsName={'PackagingTypes'}
                    id={this.packagingTypesGridId}
                    defaultColumns={this.defaultColumns}
                    allColumns={this.allColumnList}
                    schema={this.schema}
                    onSelection={this.selectRow}
                    onSort={this.onSort}
                    onColumnReorder={this.onColumnReorder}
                    onColumnResize={this.onColumnResize}
                    onClickClearSorts={this.onClickClearSorts}
                    onClickResetGrid={this.onClickResetGrid}
                    windowId={this.props.windowId}
                    eventClickColumns={() => this.onToggleColumnModal(true)}
                    onGridFilterApplied={this.onGridFilterApplied}
                    metaGridConfig={this.packagingTypesGridConfig}
                    clickEventOfContextMenu={this.clickEventOfContextMenu}
                    menu={
                        [{
                            "name": this.translate("Label_Create"),
                            "value": [],
                            "icon": "fa-plus",
                            "color": "green",
                            "wmsSelector": this.props.selector + "Create",
                            'isContextMenu': false,
                            "clickFunc": () => this.openPackageDefModal(true),
                            'isButtonMenu': true,
                        }, {
                            "name": this.translate('Label_Edit'),
                            "value": [],
                            "disabled": (!this.state.selectedPackageType),
                            "disabledText": "You may not add blahssss0",
                            "icon": "fa-pencil",
                            "color": "outlined",
                            "wmsSelector": this.props.selector + "Edit",
                            "clickFunc": () => this.openPackageDefModal(false),
                            'isContextMenu': true,
                            'isButtonMenu': true,
                            'wmsid': 'packagingTypesEdit'
                        }, {
                            "name": this.translate('Label_Delete'),
                            "value": [],
                            "disabled": (!this.state.selectedPackageType),
                            "disabledText": "You may not add blahssss0",
                            "icon": "fa-trash",
                            "color": "red",
                            "wmsSelector": this.props.selector + "Delete",
                            "clickFunc": () => this.toggleConfirmDeleteModal(true),
                            'isContextMenu': true,
                            'isButtonMenu': true,
                            'wmsid': 'packagingTypesDelete'
                        },
                        {
                            "name": "",
                            "wmsSelector": this.props.selector + "RefreshGrid",
                            "value": [],
                            "clickFunc": this.refreshGrid,
                            "disabled": false,
                            "icon": "fa-sync-alt",
                            "color": "blue",
                            "isContextMenu": false,
                            "isButtonMenu": true,
                            "parentClassName": "package-def-grid-refresh-btn",
                        },
                        {
                            "name": "Copy",
                            "disabled": false,
                            "disabledText": "Copy menu should never be disabled",
                            "icon": "fa-wrench",
                            "color": "green",
                            'isContextMenu': true,
                            'isButtonMenu': false,
                            "value": [{
                                "name": this.translate('Label_Copy_Field'),
                                "value": [],
                                "disabled": (!this.state.selectedPackageType),
                                "disabledText": this.translate('Label_Copy_Field'),
                                "icon": "fa-bandcamp",
                                "color": "green",
                                'isContextMenu': true,
                                'isButtonMenu': false,
                                'wmsid': 'copyField',
                                "wmsSelector": "copyToClicpboard",
                            }, {
                                "name": this.translate('Label_Copy_Row'),
                                "value": [],
                                "disabled": (!this.state.selectedPackageType),
                                "disabledText": this.translate('Label_Copy_Row'),
                                "icon": "fa-wrench",
                                "color": "green",
                                'isContextMenu': true,
                                'isButtonMenu': false,
                                'wmsid': 'copyRow'
                            }]
                        }]
                    }
                />

                <WmsConfirmModel
                    id={"ConfirmDeletePackageModel"}
                    isOpen={this.state.isOpenConfirmModel}
                    type={this.translate('Label_Delete_Package_Type_Title')}
                    width={800}
                    height={265}
                    firstBtnLabel={this.translate('Label_Select_Confirm')}
                    firstBtnIcon={"fa-check-circle"}
                    firstBtnOnClickFunc={this.deletePackageType}
                    sawCheckBox={false}
                    conFormModelText={this.translate('Label_Delete_Package_Type_Confirm').replace('{packagename}', this.state.selectedPackageTypeName)}
                    onCloseFunc={() => this.toggleConfirmDeleteModal(false)}
                    thirdBtnLabel={this.translate('Label_Cancel')}
                    thirdBtnIcon={"fa-ban"}
                />

                {this.state.isOpenColumnModal && (
                    <AddEditColumnModel
                        id={"addEditColumnPackagingTypes"}
                        height={700}
                        windowId={this.props.windowId}
                        onCloseColumnModel={() => this.onToggleColumnModal(false)}
                        isOpenColumnModel={this.state.isOpenColumnModal}
                        allSortColumnList={this.state.allSortColumnList}
                        gridId={this.packagingTypesGridId}
                        updateGridData={() => { }}
                        saveChangesInMetaData={this.saveChangesInMetaData}
                    />
                )}
                {this.state.isOpenPackageDefModal && (
                    <PackageDefModal
                        id={"packageDefModal"}
                        isNewPackage={this.state.newPkgType}
                        sharedIsOpenPackageDefModal={this.state.isOpenPackageDefModal}
                        onClosePackageDefModal={this.closePackageDefModal}
                        onSavePackageDef={this.savePackageDef}
                        sharedSelectedPkgType={this.state.selectedPackageType}
                        GlobalRecipient={this.state.threePlOptions?.Shipping?.GlobalRecipient}
                    />
                )}
            </div>
            </>
        );
    }
}
