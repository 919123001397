import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { KendoWms } from '../../../assets/ts/KendoWms.ts';
import style from './cyclecount.scss';
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsCheckBox from './../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsButtonDropdown from './../components/WmsButtonDropdown/WmsButtonDropdown.jsx';
import WmsMenuItem from './../components/WmsMenuItem/WmsMenuItem.jsx';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import * as commanFunction from './cyclecountgrid-columns.jsx';
import * as GlobalService from './../global_services';
import { KendoGridWms } from '../../../assets/ts/KendoGridWms.ts';
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';
import { ArrayWms } from '../../../assets/ts/ArrayWms.ts';

import * as cyclecountService from './cyclecount.services.jsx';
import * as emitter from '../emitter';
import { kendo } from "./../global_variable_service.jsx";
var that, KendoGridCC, layoutData;

export class CyclecountGrid extends React.Component {

    constructor(props) {
        super(props);
        that = this;

        this.$rootScope = window;
        this.defaultFilter = {
            IndexOptionsJobName: 0,
            IndexOptionsStatus: -1,
            IndexOptionsUserName: 0,
            JobName: null,
            Status: 'All',
            UserName: null,
            TrackBys: false,
        };
        this.state = {

            optionConatins: [],
            booleanOption: [],
            sharedCycleCount: this.props.sharedCycleCount,
            sortArrayGrid: [],
            initialCycleCountJobsGrid: 0,
            initial: false,
            disableEditButton: false,
            disableReviewButton: false,
            closejobGif: false,
            currentSelection: 0,
            filter: this.defaultFilter,
            selector:'AuditInventory'
        };

        this.translate = window.translate;
        this.handleChange = this.handleChange.bind(this);
        this.gridSearch = this.gridSearch.bind(this);
        this.searchInfoClear = this.searchInfoClear.bind(this);
        KendoGridCC = new KendoGridWms("cycleCountJobsGrid", this.$rootScope.ChildBulletin.Data.Window, "EditUri", "./assets/json/cyclecount/cycleJobs.json");
        this.CycleCountJobs_GrideHeaders = StringWms.convertSringToOption(this.translate('CycleCountJobs_GrideHeaders'));
        // if (typeof emitter.eventBus._events.reloadJobGrid == 'undefined') {
        emitter.eventBus.on('reloadJobGrid', function (data) {
            KendoGridCC.clearSelections(function () {
                KendoGridCC.repaintSelections();
            });

            that.setState({
                currentSelection: KendoGridCC.getNumberOfSelections()
            });
        });
        // }
    }

    componentDidMount() {
        var containsOption = StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchFilters'));
        var booleanOption = StringWms.convertSringToOption(this.translate('Options_CycleCount_StatusOption'));

        booleanOption.unshift({
            "value": -1,
            "description": "All"
        });
        this.setState({
            optionConatins: containsOption,
            booleanOption: booleanOption
        });
        that.initCycleCountJobs(this.props.sharedCycleCount.cycleCountGridData);

        // restring multiple sorting direct click on header
        $("#cycleCountJobsGrid").on("mouseup", "th>a.k-link", function (e) {
            if (!(e.ctrlKey || e.metaKey)) {
                that.state.sortArrayGrid = [];
            }
        });
        this.contextMenuSet();
        this.groupContextMenuSet();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        var that = this;
        if (nextProps.sharedCycleCount != this.state.sharedCycleCount) {

            that.setState({
                sharedCycleCount: Object.assign(
                    {},
                    that.state.sharedCycleCount,
                    nextProps.sharedCycleCount
                ),
            });

            if (nextProps.sharedCycleCount.cycleCountGridData != this.state.sharedCycleCount.cycleCountGridData) {
                $('#cycleCountJobsGrid').data('kendoGrid').dataSource.data(nextProps.sharedCycleCount.cycleCountGridData);
                $('#cycleCountJobsGrid').data('kendoGrid').refresh();

                KendoGridCC.clearSelections(function () {
                    KendoGridCC.repaintSelections();
                });
                this.setState({
                    currentSelection: 0
                });
            }

            if (nextProps.sharedCycleCount.searchInfo != that.state.sharedCycleCount.searchInfo) {
                if (typeof nextProps.sharedCycleCount.searchInfo != 'undefined' && nextProps.sharedCycleCount.searchInfo.HasData == true) {
                    setTimeout(function () {
                        that.setState({
                            filter: Object.assign(
                                {},
                                that.state.filter,
                                nextProps.sharedCycleCount.searchInfo.Data
                            ),
                        });
                        that.setState({
                            filter: Object.assign(
                                {},
                                that.state.filter,
                                {
                                    IndexOptionsUserName: (that.state.filter.IndexOptionsUserName == 0) ? '' : that.state.filter.IndexOptionsUserName,
                                }
                            ),
                        });
                        that.gridSearch('fromComponentWillReceiveCall');
                    }, 0);
                }
            }
            $("#cycleCountJobGridMenu").kendoMenu();
        }
    }

    initCycleCountJobs(cycleCountGridData) {
        var tempOptionsCycleCountJobsGrid = {
            dataSource: {
                data: cycleCountGridData,
                schema: commanFunction.cycleCountJobsGridSchema()
            },
            scrollable: {
                virtual: true,
                height: 280
            },
            columnMenu: {
                sortable: false
            },
            columnMenuInit: function (e) {
                var menu = e.container.find(".k-menu");
                var filterMenu = e.container.find(".k-filter-menu");
                // Set class to filter form of grit to reset specific grid column
                if (!filterMenu.hasClass('cycleCountJobsGridFilterForm')) {
                    filterMenu.addClass('cycleCountJobsGridFilterForm');
                }
            },
            selectable: true,
            sortable: {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            },
            height: '100%',
            resizable: true,
            reorderable: true,
            filterable: true,
            pageable: false,
            editable: "popup",
            sort: function (e) {
                e.preventDefault();
                var sortedField = e.sort.field;
                GlobalService.sortDataset(that.state.sortArrayGrid, e.sort);
                this.dataSource.sort(that.state.sortArrayGrid);
                var finalSubmission = KendoGridCC.prepareGridOptionsForStorage($("#cycleCountJobsGrid").data("kendoGrid").getOptions().columns, that.state.sortArrayGrid);
                // GlobalService.setGridOptions(gridName, finalSubmission).then(function successCallBack(response) {
                // }, function errorCallBack(response) {
                //     console.log("Grid w sort ", response);
                // });
            }, columnShow: function (e) {
                var columns = $("#cycleCountJobsGrid").data("kendoGrid").getOptions().columns;
                var getIndex = ObjectWms.FindIndexByProperty($("#cycleCountJobsGrid").data("kendoGrid").getOptions().columns, "field", e.column.field);
                columns[getIndex].hidden = false;
                var finalSubmission = KendoGridCC.prepareGridOptionsForStorage(columns, that.state.sortArrayGrid);
                // GlobalService.setGridOptions(gridName, finalSubmission).then(function successCallBack(response) {
                // }, function errorCallBack(response) {
                //     console.log(response);
                // });
            },
            columnHide: function (e) {
                var columns = $("#cycleCountJobsGrid").data("kendoGrid").getOptions().columns;
                var getIndex = ObjectWms.FindIndexByProperty(columns, "field", e.column.field);
                columns[getIndex].hidden = true;
                var finalSubmission = KendoGridCC.prepareGridOptionsForStorage(columns, that.state.sortArrayGrid);

                // GlobalService.setGridOptions(gridName, finalSubmission).then(function successCallBack(response) {
                // }, function errorCallBack(response) {
                //     console.log(response);
                // });
            },
            columnReorder: function (e) {
                // Sorting column remain after column reorder
                var grid = $('#cycleCountJobsGrid').data('kendoGrid');
                var columns = grid.getOptions().columns;
                var sortedfield = [];
                for (var x = 0; x < that.state.sortArrayGrid.length; x++) {
                    sortedfield.push({
                        field: that.state.sortArrayGrid[x].field,
                        dir: that.state.sortArrayGrid[x].dir
                    });
                }
                grid.dataSource.sort(sortedfield);
                var switchedArray = ArrayWms.switchIndexByField(columns, e.newIndex, e.oldIndex);
                var finalSubmission = KendoGridCC.prepareGridOptionsForStorage(switchedArray, that.state.sortArrayGrid);
                // GlobalService.setGridOptions(gridName, finalSubmission).then(function successCallBack(response) {
                // }, function errorCallBack(response) {
                //     console.log("Grid w columnReorder ", response);
                // });
            },
            columnResize: function (e) {
                var columns = $("#cycleCountJobsGrid").data("kendoGrid").getOptions().columns;
                var getIndex = ObjectWms.FindIndexByProperty(columns, "field", e.column.field);
                columns[getIndex].width = e.newWidth;
                var finalSubmission = KendoGridCC.prepareGridOptionsForStorage(columns, that.state.sortArrayGrid);
                // GlobalService.setGridOptions(gridName, finalSubmission).then(function successCallBack(response) {
                // }, function errorCallBack(response) {
                //     console.log("Grid w columnResize ", response);
                // });
            },
            dataBinding: function (e) {
                var grid = $('#cycleCountJobsGrid').data('kendoGrid');
                if (that.state.initial) {
                    that.state.sortArrayGrid = grid.dataSource.sort() ? grid.dataSource.sort() : [];
                    that.setState({
                        initial: false
                    });
                }
                that.state.sortColums = GlobalService.getSortedColumnName(that.state.sortArrayGrid, commanFunction.cycleCountJobsGridColumns(that.CycleCountJobs_GrideHeaders, that.translate));
            },
            dataBound: function (e) {
                KendoGridCC.repaintSelections();
                if (that.state.initialCycleCountJobsGrid == 0) {
                    var grid = $('#cycleCountJobsGrid').data('kendoGrid');
                    grid.table.on('click', function (e) {
                        var gridData = grid.dataSource.view();
                        var dataItem = grid.dataItem($(e.target).closest('tr'));
                        var currentPage = grid.dataSource.page();
                        var currentNumberOfItems = grid.dataSource.view().length;
                        var row = $(e.target).closest('tr').index();
                        var col = grid.cellIndex($(e.target).closest('td'));

                        KendoGridCC.clickEventHandleSelection(e, currentPage, currentNumberOfItems, row, col, dataItem, grid.dataSource.data(), 'EditUri', function () {
                            // once we removed the synchronous call this gets called before the end of the of the element
                            that.setState({
                                currentSelection: KendoGridCC.getNumberOfSelections(),
                                disableEditButton: KendoGridCC.valueExists("Status", 0),
                                disableReviewButton: (KendoGridCC.valueExists("Status", 2) && !KendoGridCC.valueExists("Status", 0) && !KendoGridCC.valueExists("Status", 1) && !KendoGridCC.valueExists("Status", 3))
                            });
                        });
                    });
                    that.setState({
                        initialCycleCountJobsGrid: 1
                    });
                }

                var jobsGridObj = $('#cycleCountJobsGrid').data('kendoGrid');
                var gridData = jobsGridObj.dataSource.view();
                for (var i = 0; i < gridData.length; i++) {
                    if (gridData[i].FullyAllocated == false) {
                        jobsGridObj.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-over-allocated");
                    }
                }
            }
        };

        this.setState({
            initial: true
        });

        var copycycleCountJobsGridColumns = {
            columns: commanFunction.cycleCountJobsGridColumns(this.CycleCountJobs_GrideHeaders, this.translate)
        };

        var finalOptionsObj = KendoGridCC.getGridOptions(copycycleCountJobsGridColumns, tempOptionsCycleCountJobsGrid, layoutData);
        $("#cycleCountJobsGrid").kendoGrid(finalOptionsObj);
    }

    contextMenuSet() {
        var menu = $("#jobLocationGridMenu").kendoContextMenu({
            orientation: 'vertical',
            target: '#cycleCountJobsGrid',
            filter: "tbody tr:not(.k-grid-edit-row):not(.wms-selection-group)",
            animation: {
                open: {
                    effects: "fadeIn"
                },
                duration: 500
            },
            select: function (e) {
                var custom = $(e.item).children(".k-link").parent().attr("data-wmsid");
                if (custom == "addLocation") {
                    that.addLocation();
                }
                var wmsIdOfContextMenuItem = $(e.item).children(".k-link").parent().attr("data-wmsid");
                if($("li[data-wmsid = '"+wmsIdOfContextMenuItem+"']").hasClass('wms-disabled-look')){
                    return;
                }
                console.log('custom', wmsIdOfContextMenuItem);
                if (wmsIdOfContextMenuItem == "edit") {
                    that.createEditFormEvent('edit');
                } else if (wmsIdOfContextMenuItem == "reviewCount") {
                    that.openReveiwcCountModel();
                } else if (wmsIdOfContextMenuItem == "closejob") {
                    that.closeJob();
                }
            },
            open: function (e) {
                var grid = $('#cycleCountJobsGrid').data('kendoGrid');
                var dataItem = grid.dataItem($(e.target).closest('tr'));
                var page = grid.dataSource.page();
                var length = grid.dataSource.view().length;
                var col = grid.cellIndex($(e.target).closest('td'));
                var row2 = $(e.target).closest('tr').index();
                var selectedrow = $("#cycleCountJobsGrid").find("tbody tr.k-state-selected");
                console.log('selectedrow', selectedrow.length);
                if (selectedrow.length == 0 || selectedrow.length >= 1) {
                    KendoGridCC.onRightClick(e, page, length, row2, col, dataItem, grid.dataSource.data(), 'EditUri', function () {
                        that.setState({
                            currentSelection: KendoGridCC.getNumberOfSelections(),
                            disableEditButton: KendoGridCC.valueExists("Status", 0),
                            disableReviewButton: KendoGridCC.valueExists("Status", 2)
                        });
                        if (KendoGridCC.getNumberOfSelections() == 0) {
                            $("li[data-wmsid = 'closejob']").addClass('wms-disabled-look');
                        } else {
                            $("li[data-wmsid = 'closejob']").removeClass('wms-disabled-look');
                        }
                        if (KendoGridCC.getNumberOfSelections() != 1 || !KendoGridCC.valueExists("Status", 0)) {
                            $("li[data-wmsid = 'edit']").addClass('wms-disabled-look');
                        } else {
                            $("li[data-wmsid = 'edit']").removeClass('wms-disabled-look');
                        }
                        if (KendoGridCC.getNumberOfSelections() != 1 || !KendoGridCC.valueExists("Status", 2)) {
                            $("li[data-wmsid = 'reviewCount']").addClass('wms-disabled-look');
                        } else {
                            $("li[data-wmsid = 'reviewCount']").removeClass('wms-disabled-look');
                        }
                    });
                }

            }
        });
    }
    groupContextMenuSet() {
        var that = this;
        var menu = $("#jobLocationGriGroupdMenu").kendoContextMenu({
            orientation: 'vertical',
            target: '#cycleCountJobsGrid',
            filter: "tbody tr.wms-selection-group",
            animation: {
                open: {
                    effects: 'fadein'
                },
                duration: 2500
            },
            select: function (e) {
                var custom = $(e.item).children(".k-link").parent().attr("data-wmsid");
                if (custom == "reviewCount") {
                    that.openReveiwcCountModel();
                } else if (custom == "closejob") {
                    that.closeJob();
                }
            },
            open: function (e) {
                var selectedLocation = KendoGridCC.getSelections();
                that.setState({
                    currentSelection: KendoGridCC.getNumberOfSelections(),
                    disableEditButton: KendoGridCC.valueExists("Status", 0),
                    disableReviewButton: KendoGridCC.valueExists("Status", 2)
                });
                $("li[data-wmsid = 'edit']").addClass('wms-disabled-look');

                if (!KendoGridCC.valueExists("Status", 2)) {
                    $("li[data-wmsid = 'reviewCount']").addClass('wms-disabled-look');
                } else {
                    $("li[data-wmsid = 'reviewCount']").removeClass('wms-disabled-look');
                }
            }
        });
    }

    handleChange(event) {
        this.setState({
            filter: Object.assign(
                {},
                this.state.filter,
                { [event.target.name]: event.target.value }
            ),
        });
    }

    toggleCheckbox(event) {
        that.setState({
            filter: Object.assign(
                {},
                that.state.filter,
                { [event.target.name]: !that.state.filter[event.target.name] }
            ),
        });
    }

    gridSearch(basecall) {
        that.state.booleanOption.filter(function (item) {
            if (item.value == that.state.filter.IndexOptionsStatus) {
                that.state.filter.Status = item['description'];
            }
        });

        var filterData;
        if (that.state.filter.IndexOptionsUserName != '') {
            that.state.sharedCycleCount.userOption.filter(function (item) {
                if (item.Id == that.state.filter.IndexOptionsUserName) {
                    that.state.filter.UserName = item['Name'];
                }
            });
            filterData = Object.assign({}, that.state.filter);
        } else {
            filterData = Object.assign({}, that.state.filter);
            filterData.IndexOptionsUserName = 0;
        }
        var dataArr = new Array();

            if (this.state.filter.JobName) {
                dataArr.push({
                    field: "Name",
                    operator: KendoWms.getFilterOperatorByIndex(this.state.filter.IndexOptionsJobName),
                    value: this.state.filter.JobName
                });
            }
            if (this.state.filter.IndexOptionsStatus != -1) {
                dataArr.push({
                    field: "Status",
                    operator: "IsEqualTo",
                    value: parseInt(this.state.filter.IndexOptionsStatus)
                });
            }
            if (this.state.filter.TrackBys) {
                dataArr.push({
                    field: "UseTrackBys",
                    operator: "IsEqualTo",
                    value: this.state.filter.TrackBys
                });
            }
            if (that.state.filter.IndexOptionsUserName) {
                dataArr.push({
                    field: "AssignedTo",
                    operator: "IsEqualTo",
                    operator: function (items, filterValue) {
                        if (items != null && items.Name == that.state.filter.UserName) {
                            return true;
                        } else {
                            return false;
                        }
                    },
                    value: that.state.filter.UserName
                });
            }

        cyclecountService.searchInfoSend(filterData).then(function (response) {
            if (response.status == 204) {
                var dataSource = $("#cycleCountJobsGrid").data("kendoGrid").dataSource;
                dataSource.filter(dataArr);
                if (basecall == 'fromSearchbtn') { that.props.reloadJobGrid(); }
            }

        }, function (response) {
            console.log("Grid w sort ", response);
        });
    }

    searchInfoClear() {
        this.setState({
            filter:this.defaultFilter
        });
        cyclecountService.searchInfoSend(this.defaultFilter).then(function (response) {
            if (response.status == 204) {
                var dataSource = $("#cycleCountJobsGrid").data("kendoGrid").dataSource;
                dataSource.filter([]);
                $("form.k-filter-menu button[type='reset']").trigger("click");
                that.props.reloadJobGrid();
            }
        }, function (response) {
            console.log("Grid w sort ", response);
        });
    }

    createEditFormEvent(flowType) {
        var tempkey = KendoGridCC.getKeys();
        var dataItem = KendoGridCC.getItem(tempkey);
        var editData;
        if (flowType == 'edit') {
            editData = dataItem.toJSON();
        }
        //send flowType ( crete or edit ) and send grid select data
        this.props.setCreateEditData({
            flowType: flowType,
            EditData: editData,
            createEditFormShow: true
        });
    }

    eventClickResetGrid() {
        //addd reset grid api using gridname
        that.state.initialCycleCountJobsGrid = 0;
        var column = {
            columns: commanFunction.cycleCountJobsGridColumns(that.CycleCountJobs_GrideHeaders, that.translate)
        };
        var grid = $("#cycleCountJobsGrid").data("kendoGrid");
        grid.setOptions(column);
        grid.dataSource.sort({});
        $('#cycleCountJobsGrid').data('kendoGrid').refresh();
        KendoGridCC.clearSelections(function () {
            KendoGridCC.repaintSelections();
        });
        that.setState({
            currentSelection: 0
        });
        that.props.reloadJobGrid();
    }
    eventClickClearFilters() {
        that.searchInfoClear();
    }
    eventClickClearSorts() {
        var grid = $('#cycleCountJobsGrid').data('kendoGrid');
        $('#cycleCountJobsGrid').data('kendoGrid').refresh();
        grid.dataSource.sort({});
        that.props.reloadJobGrid();
    }
    getJobSelectedData() {
        var tempkey = KendoGridCC.getKeys();
        var selectedJobData = [];
        tempkey.filter(function (e) {
            var dataItem = KendoGridCC.getItem(e);
            selectedJobData.push(dataItem);
        });
        return selectedJobData;
    }
    openReveiwcCountModel() {

        var selectedJobData = this.getJobSelectedData();

        this.props.setCreateEditData({
            reviewCountSectionShow: true,
            EditData: selectedJobData
        });
        var dataSource = new kendo.data.TreeListDataSource({
            data: [
                { id: 1, Name: "", Details: "", Location: "L1", Phone: "", parentId: null },
                { id: 2, Name: "Guy Wooten", Location: "Scarf/Blue", Phone: "(438) 738-4935", parentId: 1 },
                { id: 32, Name: "Buffy Weber", Location: "None", sqty: "10", cqty: "5", Phone: "(699) 838-6121", parentId: 2 },
                { id: 32, Name: "Buffy Weber", Location: "Lot:sbc234", sqty: "10", cqty: "5", Phone: "(699) 838-6121", parentId: 2 },
                { id: 40, Name: "", Details: "", Location: "L2", Phone: "", parentId: null },
                { id: 41, Name: "Guy Wooten", Location: "Scarf/Blue", Phone: "(438) 738-4935", parentId: 40 },
            ],

            schema: {
                model: {
                    id: "id",
                    expanded: true
                }
            }
        });
        $("#invAuditReveiwGrid").kendoTreeList({
            dataSource: dataSource,
            height: 'auto',
            columns: [
                { field: "Location" },
                { field: "sqty" },
                { field: "cqty" },
                { field: "variance" }
            ],
            dataBound: function (e) {
                $('.k-alt.k-treelist-group').on('click', function () {
                    that.openOtheLocationModal();
                });
            }
        });
    }
    closeJob() {

        var selectedJobData = that.getJobSelectedData();
        var auditCountsUris = [];
        for (var i = 0; i < selectedJobData.length; i++) {
            auditCountsUris.push(selectedJobData[i].EditUri);
        }
        that.props.closeJob(auditCountsUris);
    }
    // closeModel() {
    //     var modelOptions = that.state.inventoryAuditModalOption;
    //     modelOptions.isOpen = false;
    //     that.setState({
    //         inventoryAuditModalOption: modelOptions
    //     });
    //         $("#invAuditReveiwGrid").empty(); // destroy the TreeList
    //     // $("#invAuditReveiwGrid").remove(); // remove all TreeList HTML
    // }



    render() {
        return (
            <div className="cyclecount-grid-section" >
                <div className="wms_toolbar">
                    <WmsButton
                        label={this.translate('Label_Search')}
                        wmsSelector={this.state.selector+'GridSearchButton'}
                        onClickFunc={(e) => this.gridSearch('fromSearchbtn')}
                        icon='fa-search' />
                    <WmsButton
                        label={this.translate('Label_Clear_Filters')}
                        wmsSelector={this.state.selector+'clearSearchButton'}
                        onClickFunc={this.searchInfoClear}
                        buttonType={'yellow'}
                        icon='fa-minus' />
                </div>
                    <WmsContainer title={this.translate('Label_Cycle_count_Filter_Contain')}>
                        <div className="inner-filter-section">
                        <div className="form-group wms-input-group-wrapper">
                            <WmsDropdown
                                id="IndexOptionsJobName"
                                name="IndexOptionsJobName"
                                label={'contains'}
                                wmsSelector={this.state.selector+"IndexOptionsJobname"}
                                value={this.state.filter.IndexOptionsJobName}
                                onChangeFunc={this.handleChange}
                                options={this.state.optionConatins}
                                valueField='value'
                                textField='description'
                                parentClassName="select_box"
                            />
                            <WmsInput
                                inputName="JobName"
                                wmsSelector={this.state.selector+"SearchJobName"}
                                value={this.state.filter.JobName}
                                onChangeFunc={this.handleChange}
                                label={this.translate('Label_Cycle_count_Job_Name')}
                                parentClassName="input_box" />
                        </div>
                        <WmsDropdown
                            id="IndexOptionsStatus"
                            name="IndexOptionsStatus"
                            label={'status'}
                            wmsSelector={this.state.selector+"IndexOptionsStatus"}
                            value={this.state.filter.IndexOptionsStatus}
                            onChangeFunc={this.handleChange}
                            options={this.state.booleanOption}
                            valueField='value'
                            textField='description'
                        />
                        </div>
                        <div className="inner-filter-section">
                        <WmsDropdown
                            id="IndexOptionsUserName"
                            name="IndexOptionsUserName"
                            label={'Assigned To'}
                            wmsSelector={this.state.selector+"SearchUserName"}
                            value={this.state.filter.IndexOptionsUserName}
                            onChangeFunc={this.handleChange}
                            options={this.props.sharedCycleCount.userOption}
                            valueField='Id'
                            textField='Name'
                            blankFirstOption={true}
                            blankFirstOptionText={this.translate("Label_All")}
                        />
                        <WmsCheckBox
                            id={'TrackBys'}
                            name={'TrackBys'}
                            wmsSelector={this.state.selector+"SearchTrackBys"}
                            onChangeFunc={this.toggleCheckbox}
                            label={this.translate('Label_Cycle_count_Job_includes')}
                            checked={this.state.filter.TrackBys}
                            value={this.state.filter.TrackBys} />
                        </div>
                    </WmsContainer>
                    <ul id="jobLocationGridMenu">
                        <li className={style.wms_context_row} data-wmsid="edit">Edit</li>
                        <li className={style.wms_context_row} data-wmsid="reviewCount">Review Jobs</li>
                        <li className={style.wms_context_row} data-wmsid="closejob">Close Jobs</li>
                    </ul>
                    <ul id="jobLocationGriGroupdMenu">
                        <li className={style.wms_context_row} data-wmsid="edit">Edit</li>
                        <li className={style.wms_context_row} data-wmsid="reviewCount">Review Jobs</li>
                        <li className={style.wms_context_row} data-wmsid="closejob">Close Jobs</li>
                    </ul>
                    <div className="wms-grid-wrapper">
                        <div className="wms-toolbar-holder">
                            <div className="wms_toolbar">
                                <WmsButton
                                    label={this.translate('Button_Label_CreateOrders')}
                                    wmsSelector={this.state.selector+'CreateButton'}
                                    onClickFunc={(e) => this.createEditFormEvent('create')}
                                    icon='fa-plus' />
                                <WmsButton
                                    label={'Review Jobs'}
                                    wmsSelector={this.state.selector+'ReconcileButton'}
                                    disabled={this.state.currentSelection == 0 || !this.state.disableReviewButton}
                                    onClickFunc={(e) => this.openReveiwcCountModel()}
                                    icon="fa-tasks"
                                />
                                <WmsButton
                                    label={this.translate('Label_Edit')}
                                    wmsSelector={this.state.selector+'Edit'}
                                    disabled={this.state.currentSelection != 1 || !this.state.disableEditButton}
                                    onClickFunc={(e) => this.createEditFormEvent('edit')}
                                    icon='fa-pencil' />
                                <WmsButton
                                    label={this.translate('Label_Close_job')}
                                    wmsSelector={this.state.selector+'CloseButton'}
                                    disabled={this.state.currentSelection == 0 || this.props.sharedCycleCount.closejobGif}
                                    onClickFunc={(e) => this.closeJob()}
                                    icon='fa-times-circle'
                                    loading={this.props.sharedCycleCount.closejobGif}
                                />
                            </div>
                                <div className="wms-toolbar-grid reset-btn">
                                    <ul id="cycleCountJobGridMenu"
                                        k-orientation="'horizontal'" className="GridMenu">
                                        <li className="GridMenuTopLink">
                                            <span></span>
                                            <ul>
                                                <li onClick={this.eventClickResetGrid}>
                                                    <span>{this.translate('Label_Reset_Grid')}</span>
                                                </li>
                                                <li onClick={this.eventClickClearFilters}>
                                                    <span>{this.translate('Label_Clear_Column_Filters')}</span>
                                                </li>
                                                <li onClick={this.eventClickClearSorts}>
                                                    <span>{this.translate('Label_Clear_Sort')}</span>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        {/* "wms-area-border no-josh" */}
                        {/* <div className="wms-area-content">
                            <div className="wms-toolbar" >
                                <div className="wms-toolbar-grid reset-btn">
                                    <ul id="cycleCountJobGridMenu"
                                        k-orientation="'horizontal'" className="GridMenu">
                                        <li className="GridMenuTopLink">
                                            <span></span>
                                            <ul>
                                                <li onClick={this.eventClickResetGrid}>
                                                    <span>{this.translate('Label_Reset_Grid')}</span>
                                                </li>
                                                <li onClick={this.eventClickClearFilters}>
                                                    <span>{this.translate('Label_Clear_Column_Filters')}</span>
                                                </li>
                                                <li onClick={this.eventClickClearSorts}>
                                                    <span>{this.translate('Label_Clear_Sort')}</span>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div> */}
                                <div className="wms-grid-holder">
                                        <div className="no-josh" id="cycleCountJobsGrid" data-wms-selector={this.state.selector+"JobsGrid"}></div>
                                    </div>
                                </div>
                            </div>
        );
    }
}