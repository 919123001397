import axios from "axios";
import React from 'react';
import { ArrayWms } from '../../../../assets/ts/ArrayWms.ts';
import { KendoGridWms } from '../../../../assets/ts/KendoGridWms.ts';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import { AddEditColumnModel } from '../../components/AddEditColumn/add-edit-columns.component.jsx';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsCollapse from '../../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from '../../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsModel from '../../components/WmsModel/WmsModel.jsx';
import VirtualGridComponent from '../../components_proto/VirtualGridComponent/VirtualGridComponent.jsx';
import * as GlobalService from "../../global_services";
import * as manageOrderProtoMetaDataService from './manage-order-proto-meta-data.service';
import * as GridConfig from './grid_configuration.jsx';
//services
import * as manageOrderServices from './manage-orders.services.jsx';

var that;
var _ = window._;

export class ManageOrdersLineItem extends React.Component {

    constructor(props) {
        super(props);

        this.$rootScope = window;
        this.translate = window.translate;
        this.defaultItemSummary = {
            ItemId: "",
            Sku: "",
            SkuDescription: "",
            QualSummaries: [],
            Qualifier: "",
            PrimaryIsIntegral: "",
            SecondaryIsIntegral: "",
            PrimaryUnitsPerSecondaryUnit: "",
            PrimaryUom: "",
            SecondaryUom: "",
            PrimaryInvQty: "",
            SecondaryInvQty: "",
            totalResult:0
        };
        var isFulfillmentEnable = (GlobalService.GetRights().hasOwnProperty('Shipping.FulfillInvEnabled') && props.sharedSelectedCustomer.options.fulfillmentInvoicing == 1);
        let defaultColumns;
        if (isFulfillmentEnable) {
            defaultColumns= GridConfig.getGridDefaultColumnsForFullfillment(StringWms.convertSringToOption(this.translate('Orders_ManageOrders_Line_ItemsGrid_Headers_Beta')), GlobalService.getCustomLabels(),this.openDeleteConfirmModel,isFulfillmentEnable).columns;
        } else {
            defaultColumns= GridConfig.getGridDefaultColumns(StringWms.convertSringToOption(this.translate('Orders_ManageOrders_Line_ItemsGrid_Headers_Beta')), GlobalService.getCustomLabels(),this.openDeleteConfirmModel,isFulfillmentEnable).columns;
        }
        this.state = {
            selector: 'OrderProtoTypeLineItem',
            // transport: GridConfig.getOrderItemsGridTransport(
            //     this.props.shareOrderData.OrderId,
            //     this.props.shareOrderData.OrderItemsUri,
            //     this.props.shareOrderData.InventorySummariesUri
            // ).transport,
            schema: GridConfig.getOrderItemsGridSchema().schema,
            isFulfillmentEnable:isFulfillmentEnable,
            defaultColumns: defaultColumns,
            columns: GridConfig.getOrderItemsGridColumns(StringWms.convertSringToOption(this.translate('Orders_ManageOrders_Line_ItemsGrid_Headers_Beta')), GlobalService.getCustomLabels(),this.openDeleteConfirmModel,isFulfillmentEnable,GlobalService.GetRights()).columns,
            localKendoGrid: null,
            gifDeleteLineItem: false,
            tempDisableAddLineItem: false,
            savingGifGridInsert: false,
            isFullyAllocated: true,
            showLineItemSavedElements: true,
            showCustomerSavedElements: false,
            showTplSavedElements: false,
            enableDeleteButton: false,
            enableEditAllocation: false,
            qualifierOptions: [],
            indexOptionsQualifiers: "",
            itemSummary: this.defaultItemSummary,
            selectedSkuDropDown: { Id: 0 },
            selectedLineItemsCount: 0,
            Rights: GlobalService.GetRights(),
            isExpanded: props.isPrototypeComponent ? ((!$.isEmptyObject(props.metaDataList) && props.metaDataList.hasOwnProperty('collapse2')) ? !props.metaDataList['collapse2'] : true) : true,
            isOpenDeleteConfirmModel:false,
            selectedItemNames:[],
            selectedItemIds:[],
            deleteClickFrom:'',
            selectLineItemObj:{},
            lineItemSubTotal:0,
            lineItemTotal:0,
            gridData: [],
            lineItemGridConfig: manageOrderProtoMetaDataService.getManageOrderProtoLineItemGridOptionMetaData(isFulfillmentEnable),
            totalInventory: 0,
            firstLoadLimit: 4000,
            allSortColumnList: [],
            gridLoading: false,
            isOpenColumnModel: false,
            IsLineItemInitialCallSet:false,
            filterText: "",
            fullFilterText: ""
        };
        that = this;
    }
    componentDidMount () {
        document.addEventListener("onOrderItemsRefresh", this.onOrderItemsRefresh);
        let allSortColumnList = this.state.columns.sort(function (first, second) {

            var columnTitle1 = first.title.toUpperCase(); // ignore upper and lowercase
            var columnTitle2 = second.title.toUpperCase(); // ignore upper and lowercase

            if (columnTitle1 < columnTitle2) { return -1; }

            if (columnTitle1 > columnTitle2) { return 1; }
            // names must be equal
            return 0;
        });
        this.setState({ allSortColumnList: allSortColumnList });
        this.getGridData();
    }
    componentDidUpdate(prevProps, prevState) {
        // for reset line item grid from mass add and edit allocation modal success
        if (prevProps.sharedIsResetLineItemGrid != this.props.sharedIsResetLineItemGrid) {
            this.getGridData();
        }
        if (prevProps.shareOrderData.OrderId != this.props.shareOrderData.OrderId) {
            this.getGridData(null,this.props.shareOrderData);
        }
    }
    componentWillUnmount(){
        document.removeEventListener("onOrderItemsRefresh", this.onOrderItemsRefresh);
    }
    setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            this.setState({
                filterText: "",
                fullFilterText: ""
            });
            return;
        }
        var filterConditionTitle = {
            "lte": "Is Less Than Or Equal To",
            "eq": "Is Equal To",
            "lt": "Is Less Than",
            "neq": "Is Not Equal To",
            "gte": "Is Greater Than Or Equal To",
            "gt": "Is Greater Than",
            "startswith": "Starts With",
            "endswith": "Ends With",
            "contains": "Contains",
            "doesnotcontain": "Does Not Contain",
            "isnull": "Is Null",
            "isnotnull": "Is Not Null",
            "isempty": "Is Empty",
            "isnotempty": "Is Not Empty",
            "isnullorempty": "Has No Value",
            "isnotnullorempty": "Has Value",
        };

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;
        filterList.map( (item) => {
            var columnObj = this.state.columns.find(x => x.field === item.field);
            if(columnObj) {
                var title = columnObj.title;
                filterText += title + " : " + item.value + ',';
                fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
            }
        });
        filterText = filterText.slice(0, -1);

        this.setState({
            filterText: filterText,
            fullFilterText: fullFilterText
        });
    }
    // on grid filter selection
    onGridFilterApplied = () => {
        var grid = $("." + this.props.windowId + " #OrderItemsGrid").data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            this.setFilterAppliedText(dataSource.filter());

            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, dataSource.filter());

            manageOrderProtoMetaDataService.setManageOrderProtoLineItemGridOptionMetaData(finalSubmission);

        }, 100);

    }

    onOrderItemsRefresh = () => {
        this.getGridData();
    }

    setCollapseMetaData = (value) => {
        if (this.props.isPrototypeComponent) {
            manageOrderProtoMetaDataService.setManageOrderProtoCollapseMetaData("collapse2", value);
        }
    }
    handleChangeForOrderTotal = (param_parent_property, e) => {
        let orderObj = Object.assign(this.props.shareOrderData);
        if (param_parent_property == '') {
            orderObj[e.target.name] = e.target.value;
        } else {
            if (orderObj[param_parent_property] == null) {
                orderObj[param_parent_property] = { [e.target.name]: e.target.value };
            } else {
                orderObj[param_parent_property][e.target.name] = e.target.value;
            }
        }
        this.props.onOrderDataSet(orderObj);
    }
    fixedPointOnBlur = (fixedNumber) => (e) => {
        if(e.target.value) {
            let newValue = parseFloat(e.target.value).toFixed(fixedNumber);
            if (e.target.value.toString() === newValue.toString()) {
                return;
            }
            let orderObj = Object.assign({}, this.props.shareOrderData);
            orderObj.FulfillInv[e.target.name] = parseFloat(e.target.value).toFixed(fixedNumber);
            this.props.onOrderDataSet(orderObj);
        } else {
            let newValue = "0.00";
            let orderObj = Object.assign({}, this.props.shareOrderData);
            orderObj.FulfillInv[e.target.name] = newValue;
            this.props.onOrderDataSet(orderObj);
        }
    }
    handleChangeForFulFillInv = (e) => {
        let orderObj = Object.assign(this.props.shareOrderData);
        let IsLineItemInitialCallSet = this.state.IsLineItemInitialCallSet ;
        if(e){
            if (
                e.target.name == 'FulfillInvDiscountAmount'
            ) {
                let lineItemTotalTemp = this.state.lineItemSubTotal +
                parseFloat(orderObj.FulfillInv.FulfillInvTax) +
                parseFloat(orderObj.FulfillInv.FulfillInvShippingAndHandling);
                if(parseFloat(e.target.value) > parseFloat(lineItemTotalTemp)){
                    this.props.onOrderDataSet(orderObj,true); // pass true to remain discard button enable
                    return;
                }
            }

            if (orderObj.FulfillInv == null) {
                orderObj.FulfillInv = { [e.target.name]: e.target.value };
            } else {
                orderObj.FulfillInv[e.target.name] = e.target.value;
            }
        }else{
            if(IsLineItemInitialCallSet == false ){
                this.setState({IsLineItemInitialCallSet : true });
            }
        }

        if(this.state.lineItemSubTotal){
            var lineItemTotal =
                this.state.lineItemSubTotal +
                parseFloat(
                    orderObj.FulfillInv.FulfillInvTax ?
                    orderObj.FulfillInv.FulfillInvTax :
                    0
                ) +
                parseFloat(
                    orderObj.FulfillInv.FulfillInvShippingAndHandling ? orderObj.FulfillInv.FulfillInvShippingAndHandling :
                    0
                ) -
                parseFloat(
                    orderObj.FulfillInv.FulfillInvDiscountAmount ?
                    orderObj.FulfillInv.FulfillInvDiscountAmount :
                    0
                );
                this.setState({lineItemTotal:lineItemTotal.toFixed(2)});
        }else{
            orderObj.FulfillInv.FulfillInvTax = '0.00';
            orderObj.FulfillInv.FulfillInvShippingAndHandling = '0.00';
            orderObj.FulfillInv.FulfillInvDiscountAmount = '0.00';
            this.setState({ lineItemTotal:'0.00'});
        }
        this.props.onOrderDataSet(orderObj,IsLineItemInitialCallSet);
    }
    openDeleteConfirmModel = (param_baseCall) => (e) => {
        let orderLineItemsGrid = $("." + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');
        let tmpItemIds = [];
        let tmpItemName = [];
        var tmpDeleteClickFrom = '';
        var selectLineItemObj= {};
        if(param_baseCall == 'fromInline'){
            var dataItem = orderLineItemsGrid.dataItem($(e.currentTarget).closest('tr'));
            tmpItemIds = [dataItem.OrderItemId];
            tmpItemName = [dataItem.Sku];
            tmpDeleteClickFrom = 'fromInlineGrid';
            selectLineItemObj = dataItem;
            if(this.props.isCustomerUserAndOrderComplete){
                GlobalService.notification(
                    "warning",
                    this.translate('note_cant_delete_line_item_customer_user'),
                );
                return;
            }
        }else{
            let selectedIndexs = [];
            let selectedRows = orderLineItemsGrid.select();
            tmpItemIds = [];
            for (let x = 0; x < selectedRows.length; x++) {
                selectedIndexs.push(selectedRows[x].rowIndex);
            }

            for (let x = 0; x < selectedIndexs.length; x++) {
                tmpItemIds.push(orderLineItemsGrid._data[selectedIndexs[x]].OrderItemId);
                tmpItemName.push(orderLineItemsGrid._data[selectedIndexs[x]].Sku);
            }

            tmpItemIds = tmpItemIds;
            tmpItemName = tmpItemName;
        }

        this.setState({
            isOpenDeleteConfirmModel:true,
            selectedItemIds:tmpItemIds,
            selectedItemNames:tmpItemName,
            deleteClickFrom:tmpDeleteClickFrom,
            selectLineItemObj:selectLineItemObj
         });
    }
    closeDeleteconfirm = () => {
        this.setState({ isOpenDeleteConfirmModel:false });
    }
    orderItemsGridDelete = () => {

        this.setState({ gifDeleteLineItem: true });

        let orderLineItemsGrid = $("." + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');

        manageOrderServices.deleteMultipleOrderLineItems({

            orderItemsListUri: this.props.shareOrderData.OrderItemsUri,
            ids: this.state.selectedItemIds

        }).then((response) => {

            GlobalService.notification('success', this.translate('Label_Deleted_Success'));
            this.resetGridAndSelection();

            let gridData = orderLineItemsGrid.dataSource.data();
            let isFullyAllocated = true;
            let allocatedSkuList = "";


            for (let i = 0; i < gridData.length; i++) {
                if (gridData[i].FullyAllocated == false) {
                    isFullyAllocated = false;
                    allocatedSkuList += "<p>" + gridData[i].Sku + "</p>";
                }
            }

            this.props.onItemAllocationChanged({
                'isFullyAllocated': isFullyAllocated,
                "allocatedSkuList": allocatedSkuList,
                'sawDiscardbutton': true
            });
            this.setState({ isOpenDeleteConfirmModel:false });
            this.getGridData();
            this.props.onGridItemChange();
            // reset discountamount after delete

            // let orderObj = Object.assign(this.props.shareOrderData);
            // orderObj.FulfillInv.FulfillInvDiscountAmount = '0.00';
            // this.props.onOrderDataSet(orderObj);

        }).finally(() => {
            this.setState({ gifDeleteLineItem: false });
        });
    }
    orderSingleItemDelete = () => {

        this.setState({ gifDeleteLineItem: true });

        let orderLineItemsGrid = $("." + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');

        manageOrderServices.deleteSingleOrderLineItems(this.state.selectLineItemObj.EditUri,this.props.shareOrderData.OrderId).then((response) => {

            GlobalService.notification('success', this.translate('Label_Deleted_Success'));

            let gridData = orderLineItemsGrid.dataSource.data();
            let isFullyAllocated = true;
            let allocatedSkuList = "";


            for (let i = 0; i < gridData.length; i++) {
                if (gridData[i].FullyAllocated == false) {
                    isFullyAllocated = false;
                    allocatedSkuList += "<p>" + gridData[i].Sku + "</p>";
                }
            }

            this.props.onItemAllocationChanged({
                'isFullyAllocated': isFullyAllocated,
                "allocatedSkuList": allocatedSkuList,
                'sawDiscardbutton': true
            });
            this.setState({ isOpenDeleteConfirmModel:false });
            this.getGridData();
            this.props.onGridItemChange();
            // reset discountamount after delete

            // let orderObj = Object.assign(this.props.shareOrderData);
            // orderObj.FulfillInv.FulfillInvDiscountAmount = '0.00';
            // this.props.onOrderDataSet(orderObj);
        }).finally(() => {
            this.setState({ gifDeleteLineItem: false });
        });
    }

    resetGridAndSelection = () => {
        let orderLineItemsGrid = $("." + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');
        if (this.state.localKendoGrid != null) {
            this.state.localKendoGrid.clearSelections(() => {
                this.state.localKendoGrid.repaintSelections();
            });
        }
        orderLineItemsGrid.dataSource.read();
        orderLineItemsGrid.refresh();
        this.setState({
            enableDeleteButton: false,
            enableEditAllocation: false
        });
    }

    eventClickOpenEditAllocations = () => {
        let grid = $("." + this.props.windowId + " #OrderItemsGrid").data("kendoGrid");
        let selectedItem = grid.dataItem(grid.select());

        if (!selectedItem) {
            return;
        }
        let sharedEditAllocationData = {
            "modalTitle": this.translate('Window_Title_Edit_Allocations'),
            "modalType": 'GridEditAllocations',
            "CustomerId": this.props.sharedSelectedCustomer.id,
            "FacilityId": this.props.sharedSelectedFacility.id,
            "ItemId": selectedItem.ItemId,
            "QualifierId": selectedItem.Qualifier,
            "Sku": selectedItem.Sku,
            "ItemDescription": selectedItem.ItemDescription,
            "OrderQty": selectedItem.Qty,
            "EditWithAllocationsDetailsUri": selectedItem.EditWithAllocationDetailsUri,
            "OrderId": this.props.shareOrderData.OrderId,
            "OrderItemId": selectedItem.OrderItemId,
            'totalOrdered': selectedItem.Qty,
            'totalInputedQuantity': selectedItem.Qtyy
        };
        this.props.onOpenManageAllocationModal({
            'isModalOpen': true,
            'modalSharedData': sharedEditAllocationData
        });
    }

    openAddEditLineItemModal = () => {
        let grid = $("." + this.props.windowId + " #OrderItemsGrid").data("kendoGrid");
        let selectedItem = grid.dataItem(grid.select());
        this.props.onOpenAddEditLineItemModal(selectedItem)();
    }

    openPrototypeMassAdd =() =>{
        let sharedMassAddData = {
            "CustomerId": this.props.sharedSelectedCustomer.id,
            "FacilityId": this.props.sharedSelectedFacility.id,
            "OrderId": this.props.shareOrderData.OrderId,
            'OrderItemsUri': this.props.shareOrderData.OrderItemsUri
        };
        this.props.onOpenProtoMassAddModal({
            'isModalOpen': true,
            'modalSharedData': sharedMassAddData
        });
    }
    eventClickColumns = () => {
        this.setState({ isOpenColumnModel: true });
    }
    onCloseColumnModel = (e) => {
        this.setState({ isOpenColumnModel: false });
    }
    getGridData = (pgSiz = null,orderData) => {
        var tmpOrderData =  this.props.shareOrderData;
        if(orderData){
            tmpOrderData = orderData;
        }
        this.setState({ gridLoading: true });
        let url = "/WebUI/Shipping/Orders/OrderLineItems";
        axios.put(url, {
            orderId: tmpOrderData.OrderId,
            itemsUri: tmpOrderData.OrderItemsUri,
            inventorySummariesUri: tmpOrderData.InventorySummariesUri
        }).then((response) => {
            if(response && response.status == 200 ){
                this.setState({
                    gridLoading: false,
                    gridData: response.data.OrderItems,
                    totalResult:response.data.OrderItems.length,
                    lineItemSubTotal: response.data.OrderItemSubTotal
                },()=>{
                    this.handleChangeForFulFillInv();
                });
            }
        }).finally(() => {
            this.setState({ gridLoading: false });
        });
    }
    clickEventOfContextMenu = (obj) => {
        if (obj.wmsid == 'delete') {
            this.openDeleteConfirmModel('fromContextmenu')();
        } else if (obj.wmsid == 'edit') {
            this.eventClickOpenEditAllocations();
        } else if (obj.wmsid == 'addLineItem') {
            this.props.onOpenAddEditLineItemModal()();
        } else if (obj.wmsid == 'editLineItem') {
            this.openAddEditLineItemModal();
        } else if(obj.wmsid == 'massAddLineItem') {
          this.openPrototypeMassAdd();
        }
    }

    selectRow = (param_kendogrid) => {
        let that = this;
        setTimeout(function(){
            var grid = $('.' + that.props.windowId + ' #OrderItemsGrid').data('kendoGrid');
            var selectedRecored = grid.select().length;
            that.setState({
                enableDeleteButton: selectedRecored >= 1 ? true : false,
                enableEditAllocation: selectedRecored == 1 ? true : false,
                localKendoGrid: param_kendogrid
            });
        }, 200);
    }

    boundGridData = (event, kendoGrid) => {

        if (typeof this.$rootScope.setLoaderData['Edit Order'] != "undefined") {
            this.$rootScope.setLoaderTimerEndTime("Edit Order");
        }
        if (typeof this.$rootScope.setLoaderData['Create Order'] != "undefined") {
            this.$rootScope.setLoaderTimerEndTime("Create Order");
        }


        let grid = $('.' + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');
        let gridData = grid.dataSource.data();
        let isFullyAllocated = true;
        let allocatedSkuList = "";
        let height = 0;
        let tHeadHight = grid.thead.height();
        let trheight = $(grid.table.find('tr')[0]).height() ? $(grid.table.find('tr')[0]).height() : 30;
        if(gridData.length == 0){ // set default tr height when grid have 0 recored
            trheight = 31;
        } else if(trheight == 0) {
            trheight = 30;
        }

        $('.' + this.props.windowId + ' #OrderItemsGrid').find('.k-scrollbar-vertical').hide();
        $('.' + this.props.windowId + ' #OrderItemsGrid').find('.k-grid-content').css("padding-right", "0");
        if(gridData.length <= 3 ){
            height = ((3 * trheight) + tHeadHight);
        } else if(gridData.length > 25 ){
            height = ((25 * trheight) + tHeadHight);
            $('.' + this.props.windowId + ' #OrderItemsGrid').find('.k-scrollbar-vertical').show();
            $('.' + this.props.windowId + ' #OrderItemsGrid').find('.k-grid-content').css("padding-right", "20px");
        } else{
            height = ((gridData.length * trheight) + tHeadHight);
        }
        height = height + 15;
        $('.' + this.props.windowId + ' #OrderItemsGrid').height(height);
        $('.' + this.props.windowId + ' #OrderItemsGrid').parent().height(height+8); // set grid parent height because - grid is resize on column add/edit & grid bottom border not saw propely
        if (gridData.length == 0) { isFullyAllocated = false; }
        var totalWieght =  _.sumBy(gridData, 'CatchWeight');
        var totalValue =  _.sumBy(gridData, 'Volume');


        for (let i = 0; i < gridData.length; i++) {

            if (gridData[i].PickLineEmpty) {
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-insufficient-pickline-qty");
                allocatedSkuList += "<p>" + gridData[i].Sku + "</p>";
            } else if (gridData[i].FullyAllocated == false) {
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-over-allocated");
                isFullyAllocated = false;
                allocatedSkuList += "<p>" + gridData[i].Sku + "</p>";
            } else {
                grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-sufficient-pickline-qty");
                allocatedSkuList += "<p>" + gridData[i].Sku + "</p>";
            }

            //Calculations for the TotalPrice, TotalAfterDiscount columns
            if (gridData[i].FulfillInvSalePrice != null && typeof gridData[i].FulfillInvSalePrice !== "undefined" && gridData[i].FulfillInvSalePrice != 0) {
                gridData[i].FullfillInvTotalPrice = parseFloat((new Big(gridData[i].PrimaryInvQty || 0)).times(gridData[i].FulfillInvSalePrice).round(4));
            }

            if (gridData[i].FullfillInvTotalPrice != null && typeof gridData[i].FullfillInvTotalPrice !== "undefined" && typeof gridData[i].FullfillInvTotalPrice !== 0) {
                if ((typeof gridData[i].FulfillInvDiscountPct !== "undefined") && gridData[i].FulfillInvDiscountPct != null) {
                    if (gridData[i].FulfillInvDiscountPct == 0 && gridData[i].FulfillInvDiscountAmt) {
                        gridData[i].FullfillInvTotalAfterDiscount = parseFloat(new Big(gridData[i].FullfillInvTotalPrice || 0).minus(gridData[i].FulfillInvDiscountAmt).round(4));
                    } else {
                        var discountpctAmt = parseFloat(new Big(gridData[i].FulfillInvDiscountPct || 0).div(100).round(4));
                        var discountAmt = parseFloat(new Big(gridData[i].FullfillInvTotalPrice || 0).times(discountpctAmt).round(4));
                        gridData[i].FullfillInvTotalAfterDiscount = gridData[i].FullfillInvTotalPrice - discountAmt;
                    }
                } else if ((typeof (gridData[i].FulfillInvDiscountAmt) !== "undefined") && gridData[i].FulfillInvDiscountAmt != null && gridData[i].FulfillInvDiscountAmt != 0) {
                    gridData[i].FullfillInvTotalAfterDiscount = parseFloat(new Big(gridData[i].FullfillInvTotalPrice || 0).minus(gridData[i].FulfillInvDiscountAmt).round(4));
                } else {
                    gridData[i].FullfillInvTotalAfterDiscount = parseFloat(new Big(gridData[i].FullfillInvTotalPrice || 0).round(4));
                }
            }

        }

        this.props.onItemAllocationChanged({
            'isFullyAllocated': isFullyAllocated,
            "allocatedSkuList": allocatedSkuList
        });
        this.props.onSetOrderItemChange(gridData,totalWieght,totalValue);
        this.setState({ localKendoGrid: kendoGrid });

        if(typeof $('.' + this.props.windowId + ' #OrderItemsGrid').data("kendoTooltip") == 'undefined'){
            $('.' + this.props.windowId + ' #OrderItemsGrid').kendoTooltip({
                filter: "tr:not('.wms-sufficient-pickline-qty'):not('.wms-over-allocated') td",
                position: "bottom",
                content: (e) => {
                    var dataItem = $('.' + this.props.windowId + ' #OrderItemsGrid').data("kendoGrid").dataItem(e.target.closest("tr"));
                    var content = "";
                    if(typeof dataItem != 'undefined' && dataItem.PickLineEmpty){ //Unable to allocate from pickline
                        content = "Insufficient quantity in pick line";
                    }
                    return content;
                }
            }).data("kendoTooltip");

        }
    }

    beforeContextMenuOpen = (e) => {
        var grid = $('.' + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');
        var dataItem = grid.dataItem($(e.target).closest('tr'));

        var page = grid.dataSource.page();
        var length = grid.dataSource.view().length;
        var col = grid.cellIndex($(e.target).closest('td'));

        var row2 = $(e.target).closest('tr').index();

        let KendoGrid = new KendoGridWms('OrderItemsGrid', this.props.windowId, 'OrderItemId', '');
        KendoGrid.onRightClick(e, page, length, row2, col, dataItem, grid.dataSource.data(), 'OrderItemId', () => {
            this.selectRow(KendoGrid);
        });

    }

    // on grid column sort save changes in meta data object
    onSort = (event) => {
        var grid = $('.' + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
        manageOrderProtoMetaDataService.setManageOrderProtoLineItemGridOptionMetaData(finalSubmission,this.state.isFulfillmentEnable);
    }
    // on grid column reorder save changes in meta data object
    onColumnReorder = (event) => {
        var grid = $('.' + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(switchedArray, sortArray, grid.dataSource.filter());
        manageOrderProtoMetaDataService.setManageOrderProtoLineItemGridOptionMetaData(finalSubmission,this.state.isFulfillmentEnable);

    }
    // on grid column resize save changes in meta data object
    onColumnResize = (event) => {
        var grid = $('.' + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        manageOrderProtoMetaDataService.setManageOrderProtoLineItemGridOptionMetaData(finalSubmission,this.state.isFulfillmentEnable);
    }
    // on click grid clear sort menu
    onClickClearSorts = () => {
        var grid = $('.' + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');
        var finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        manageOrderProtoMetaDataService.setManageOrderProtoLineItemGridOptionMetaData(finalSubmission,this.state.isFulfillmentEnable);
    }
    // on click grid reset default grid layout menu
    onClickResetGrid = () => {
        var finalSubmission = {
            "optionsData": {}
        };
        manageOrderProtoMetaDataService.setManageOrderProtoLineItemGridOptionMetaData(finalSubmission,this.state.isFulfillmentEnable);
    }

    // save column hide/show data in meta data
    saveMetaData = () => {
        var grid = $('.' + this.props.windowId + ' #OrderItemsGrid').data('kendoGrid');
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.state.localKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sort,
            grid.dataSource.filter()
        );
        manageOrderProtoMetaDataService.setManageOrderProtoLineItemGridOptionMetaData(finalSubmission,this.state.isFulfillmentEnable);
    }
    onChangeGridconfig = (param_grid_config) => {
        manageOrderProtoMetaDataService.setManageOrderProtoLineItemGridOptionMetaData(param_grid_config,this.state.isFulfillmentEnable);
    }

    onResetGrid = () => {
        manageOrderProtoMetaDataService.setManageOrderProtoLineItemGridOptionMetaData({ 'optionsData': {} },this.state.isFulfillmentEnable);
    }

    render() {
        return (
            <div className="collapse-grid-section collapseOrderTabContent_wrapper" ref={this.props.divRefs}>
                <div className="wms-order-line-items-wrapper">
                    <WmsCollapse id={'collapse2'} isExpanded={this.state.isExpanded} setCollapseMetaData={this.setCollapseMetaData} windowId={this.props.windowId} headerLabel={this.translate('Label_Order_Line_Items')} showCollapseText={false}>
                    <VirtualGridComponent
                        loading={this.state.gridLoading}
                        id={"OrderItemsGrid"}
                        gridName={'OrderItemsGrid'}
                        windowId={this.props.windowId}
                        wmsSelector={this.state.selector + "Grid"}
                        gridKeySelection={'OrderItemId'}
                        clickEventOfContextMenu={this.clickEventOfContextMenu}
                        staticGridData={this.state.gridData}
                        virtual={true}
                        showGridSummary={true}
                        totalResult={this.state.totalResult}
                        allColumns={this.state.columns}
                        defaultColumns={this.state.defaultColumns}
                        schema={this.state.schema}
                        onSelection={this.selectRow}
                        eventClickColumns={this.eventClickColumns}
                        metaGridConfig={this.state.lineItemGridConfig}
                        onDataBound={this.boundGridData}
                        onSort={this.onSort}
                        onColumnReorder={this.onColumnReorder}
                        onColumnResize={this.onColumnResize}
                        onClickClearSorts={this.onClickClearSorts}
                        onClickResetGrid={this.onClickResetGrid}
                        virtualGridDataLimit={this.state.firstLoadLimit}
                        metaDataFieldsName={''}
                        filterText={this.state.filterText}
                        fullFilterText={this.state.fullFilterText}
                        onGridFilterApplied={this.onGridFilterApplied}
                        menu={[{
                            "name": this.translate('Label_Add'),
                            "value": [],
                            "disabled": this.props.isCustomerUserAndOrderComplete,
                            "showToolTip": this.props.isCustomerUserAndOrderComplete,
                            "tooltipText":this.translate('note_cant_add_line_item_customer_user'),
                            "icon": "fa-plus",
                            'isContextMenu': true,
                            'isButtonMenu': true,
                            'wmsid': 'addLineItem',
                            'wmsSelector': this.state.selector + "Add",
                            'clickFunc': this.props.onOpenAddEditLineItemModal()
                        },{
                            "name": this.translate('Label_Mass_Add'),
                            "value": [],
                            "disabled": this.props.isCustomerUserAndOrderComplete,
                            "showToolTip": this.props.isCustomerUserAndOrderComplete,
                            "tooltipText":this.translate('note_cant_mass_add_line_item_customer_user'),
                            "icon": "fa-plus",
                            'isContextMenu': true,
                            'isButtonMenu': true,
                            'wmsid': 'massAddLineItem',
                            "wmsSelector": this.state.selector + "PrototypeMassAdd",
                            "clickFunc": this.openPrototypeMassAdd
                        },{
                            "name": this.translate('Label_Edit'),
                            "value": [],
                            "disabled": (!this.state.enableEditAllocation || this.props.isCustomerUserAndOrderComplete),
                            "showToolTip": this.props.isCustomerUserAndOrderComplete,
                            "tooltipText":this.translate('note_cant_edit_line_item_customer_user'),
                            "icon": "fa-pencil",
                            'isContextMenu': true,
                            'isButtonMenu': true,
                            'wmsid': 'editLineItem',
                            'wmsSelector': this.state.selector + "Edit",
                            'clickFunc': this.openAddEditLineItemModal
                        },{
                            "name": this.translate('Window_Title_Edit_Allocations'),
                            "value": [],
                            "disabled": (!this.state.enableEditAllocation || this.props.isCustomerUserAndOrderComplete),
                            "showToolTip": this.props.isCustomerUserAndOrderComplete,
                            "tooltipText":this.translate('note_cant_edit_allocataion_customer_user'),
                            "icon": "fa-pencil",
                            'isContextMenu': this.state.Rights.hasOwnProperty('orders.allocate'),
                            'isButtonMenu': this.state.Rights.hasOwnProperty('orders.allocate'),
                            'wmsid': 'edit',
                            'wmsSelector': this.state.selector + "EditAllocations",
                            'clickFunc': this.eventClickOpenEditAllocations,
                        },{
                            "name": this.translate('Label_BatchDelete'),
                            "value": [],
                            "disabled": !this.state.enableDeleteButton || this.state.gifDeleteLineItem || this.props.isCustomerUserAndOrderComplete,
                            "showToolTip": this.props.isCustomerUserAndOrderComplete,
                            "tooltipText":this.translate('note_cant_delete_line_item_customer_user'),
                            "icon": "fa-trash",
                            "color": "red",
                            'isContextMenu': true,
                            'isButtonMenu': true,
                            'wmsid': 'delete',
                            'wmsSelector': this.state.selector + "DeleteSelected",
                            'clickFunc': this.openDeleteConfirmModel('fromButton')
                        }]}
                    />
                    {this.state.isOpenColumnModel && (
                    <AddEditColumnModel
                        id={"addEditColumnLineItemBeta"+this.props.windowId}
                        height={340}
                        windowId={this.props.windowId}
                        onCloseColumnModel={this.onCloseColumnModel}
                        isOpenColumnModel={this.state.isOpenColumnModel}
                        allSortColumnList={this.state.allSortColumnList}
                        gridId={"OrderItemsGrid"}
                        updateGridData={() => {}}
                        hideColumns={this.state.isFulfillmentEnable ? 'grid-action' : 'FulfillInvSalePrice,FulfillInvDiscountAmt,FulfillInvDiscountPct,FulfillInvLineDiscountTotal,grid-action'}
                        saveChangesInMetaData={this.saveMetaData}
                    />
                    )}
                        {(
                            this.state.Rights.hasOwnProperty('Shipping.FulfillInvEnabled') && (
                                this.props.sharedSelectedCustomer.options.fulfillmentInvoicing == 1 ||this.props.sharedSelectedCustomer.options.fulfillmentInvoicing == 2
                            )
                        ) && (
                            <div className="fulfillment-invoicing-wrapper">
                                <div className="fulfillment-left-section">
                                    <WmsInput
                                        id='FulfillInvDiscountCode'
                                        inputName='FulfillInvDiscountCode'
                                        wmsSelector={this.state.selector + 'FulfillInvDiscountCode'}
                                        onChangeFunc={this.handleChangeForFulFillInv}
                                        label={this.translate('Label_Fulfillment_DiscountCode')}
                                        value={this.props.shareOrderData.FulfillInv.FulfillInvDiscountCode}
                                        extraWrapperClass="discount-code-input"
                                    />
                                    <WmsInput
                                        id='FulfillInvGiftMessage'
                                        inputName='FulfillInvGiftMessage'
                                        wmsSelector={this.state.selector + 'FulfillInvGiftMessage'}
                                        onChangeFunc={this.handleChangeForFulFillInv}
                                        label={this.translate('Label_Fulfillment_Gift_Message')}
                                        value={this.props.shareOrderData.FulfillInv.FulfillInvGiftMessage}
                                        extraWrapperClass="gift-message-input"
                                    />
                                </div>
                                <div className="fulfillment-right-section">
                                    <WmsInput
                                        id='lineItemSubTotal'
                                        inputName='lineItemSubTotal'
                                        wmsSelector={this.state.selector + 'lineItemSubTotal'}
                                        onChangeFunc={() => {}}
                                        disabled={true}
                                        extraSignBeforeValue={'$'}
                                        label={this.translate('Label_Fulfillment_Subtotal')}
                                        extraWrapperClass="bold-input-label"
                                        value={
                                            this.state.lineItemSubTotal ?
                                            parseFloat(this.state.lineItemSubTotal).toFixed(2) :
                                            '0.00'
                                        }
                                        regularExp={/^[+]?([0-9]+([.]?)(\d?\d?)?)$/}
                                    />
                                    <WmsInput
                                        id='FulfillInvTax'
                                        inputName='FulfillInvTax'
                                        wmsSelector={this.state.selector + 'FulfillInvTax'}
                                        onChangeFunc={this.handleChangeForFulFillInv}
                                        extraSignBeforeValue={'$'}
                                        label={this.translate('Label_Fulfillment_Tax')}
                                        value={this.props.shareOrderData.FulfillInv.FulfillInvTax}
                                        regularExp={/^[+]?([0-9]+([.]?)(\d?\d?)?)$/}
                                        onBlurFunc={this.fixedPointOnBlur(2)}
                                        zeroBasedCondition={2}
                                    />
                                    <WmsInput
                                        id='FulfillInvShippingAndHandling'
                                        inputName='FulfillInvShippingAndHandling'
                                        wmsSelector={this.state.selector + 'FulfillInvShippingAndHandling'}
                                        onChangeFunc={this.handleChangeForFulFillInv}
                                        extraSignBeforeValue={'$'}
                                        label={this.translate('Label_Fulfillment_ShippingAndHandling')}
                                        value={this.props.shareOrderData.FulfillInv.FulfillInvShippingAndHandling }
                                        regularExp={/^[+]?([0-9]+([.]?)(\d?\d?)?)$/}
                                        onBlurFunc={this.fixedPointOnBlur(2)}
                                        zeroBasedCondition={2}
                                    />
                                    <WmsInput
                                        id='FulfillInvDiscountAmount'
                                        inputName='FulfillInvDiscountAmount'
                                        wmsSelector={this.state.selector + 'FulfillInvDiscountAmount'}
                                        extraSignBeforeValue={'$'}
                                        onChangeFunc={this.handleChangeForFulFillInv}
                                        label={this.translate('Label_Discount_Amount')}
                                        value={this.props.shareOrderData.FulfillInv.FulfillInvDiscountAmount }
                                        regularExp={/^[+]?([0-9]+([.]?)(\d?\d?)?)$/}
                                        onBlurFunc={this.fixedPointOnBlur(2)}
                                        zeroBasedCondition={2}
                                    />
                                    <WmsInput
                                        id='lineItemTotal'
                                        inputName='lineItemTotal'
                                        wmsSelector={this.state.selector + 'lineItemTotal'}
                                        extraSignBeforeValue={'$'}
                                        onChangeFunc={() => {}}
                                        disabled={true}
                                        label={this.translate('Label_Fulfillment_Total')}
                                        value={
                                            this.state.lineItemTotal ?
                                            parseFloat(this.state.lineItemTotal).toFixed(2) :
                                            '0.00'
                                        }
                                        extraWrapperClass="bold-input-label"
                                        regularExp={/^[+]?([0-9]+([.]?)(\d?\d?)?)$/}
                                    />
                                </div>
                            </div>
                        ) }
                        <WmsContainer title={this.translate('Label_Custom_Order_Totals')} >
                            <div className="wms-customer-order-details-wrap">
                                <WmsInput
                                    id='NumUnits1'
                                    inputName='NumUnits1'
                                    wmsSelector={this.state.selector + 'NumUnits1'}
                                    onChangeFunc={(e) => this.handleChangeForOrderTotal('', e)}
                                    label={this.translate('Label_Total_Packages')}
                                    value={this.props.shareOrderData.NumUnits1}
                                    parentclassName="form-group"
                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                />
                                <WmsDropdown
                                    id='unit1IdentifierName'
                                    name='Name'
                                    wmsSelector={this.state.selector + 'Unit1IdentifierName'}
                                    options={this.props.sharedOptions.UnitofMeasureTypes}
                                    value={
                                        (this.props.shareOrderData.Unit1Identifier != null) ? this.props.shareOrderData.Unit1Identifier.Name : ''
                                    }
                                    onChangeFunc={(e) => this.handleChangeForOrderTotal('Unit1Identifier', e)}
                                    label={this.translate('Label_Packaging_UOM')}
                                    valueField='Name'
                                    textField='Name'
                                    blankFirstOption={true}
                                    blankFirstOptionText={this.translate('Label_Option_Select_UOM')}
                                    parentclassName="form-group" />
                                <WmsInput
                                    id='TotalWeight'
                                    inputName='TotalWeight'
                                    wmsSelector={this.state.selector + 'LineItemTotalWeight'}
                                    onChangeFunc={(e) => this.handleChangeForOrderTotal('', e)}
                                    label={
                                        this.translate('Label_Total_Weight') + ' ' + (
                                            (this.props.sharedSelectedFacility.measurementStandard == 0) ?
                                                this.translate('Label_Imperial_Weight')
                                                :
                                                this.translate('Label_Metric_Weight'))
                                    }
                                    value={this.props.shareOrderData.TotalWeight}
                                    parentclassName="form-group"
                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                />

                                <WmsInput
                                    id='NumUnits2'
                                    inputName='NumUnits2'
                                    wmsSelector={this.state.selector + 'NumUnits2'}
                                    onChangeFunc={(e) => this.handleChangeForOrderTotal('', e)}
                                    label={this.translate('Label_Total_Movable_Units')}
                                    value={this.props.shareOrderData.NumUnits2}
                                    parentclassName="form-group"
                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                />
                                <WmsDropdown
                                    id='Unit2Identifier'
                                    name='Name'
                                    wmsSelector={this.state.selector + 'Unit2IdentifierName'}
                                    options={this.props.sharedOptions.UnitofMeasureTypes}
                                    value={
                                        (this.props.shareOrderData.Unit2Identifier != null) ? this.props.shareOrderData.Unit2Identifier.Name : ''
                                    }
                                    onChangeFunc={(e) => this.handleChangeForOrderTotal('Unit2Identifier', e)}
                                    label={this.translate('Label_Movable_UOM')}
                                    valueField='Name'
                                    textField='Name'
                                    blankFirstOption={true}
                                    blankFirstOptionText={this.translate('Label_Option_Select_UOM')}
                                    parentclassName="form-group" />
                                <WmsInput
                                    id='TotalVolume'
                                    inputName='TotalVolume'
                                    wmsSelector={this.state.selector + 'LineItemTotalVolume'}
                                    onChangeFunc={(e) => this.handleChangeForOrderTotal('', e)}
                                    label={
                                        this.translate('Label_Total_Volume') + ' ' +
                                        ((this.props.sharedSelectedFacility.measurementStandard == 0) ?
                                            this.translate('Label_Imperial_Volume')
                                            :
                                            this.translate('Label_Metric_Volume'))
                                    }
                                    value={this.props.shareOrderData.TotalVolume}
                                    parentclassName="form-group"
                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                />
                            </div>
                        </WmsContainer>
                    </WmsCollapse>
                    {this.state.isOpenDeleteConfirmModel &&(
                        <WmsModel
                            id={this.props.windowId + 'confirmDeleteLineItem'}
                            title={this.translate('Label_Delete_Confirm_title')}
                            width={500}
                            height={220}
                            class='confirmDeleteLineItem'
                            onCloseFunc={this.closeDeleteconfirm}
                            customClass='wms-model-new-ui-wrapper'
                            isOpen={this.state.isOpenDeleteConfirmModel}>
                            <div className='model-content-wrapper'>
                                <div className="wms-warning-wrapper in-delete">
                                    <i className="wms-sprite fa fa-exclamation-triangle"></i>
                                    <span>
                                        {this.translate('Label_warning_for_total_weight_and_volume_delete')}
                                    </span>
                                </div>
                                <p className="wms-message-center">{
                                    this.translate('Label_Delete_Confirm_text').replace(new RegExp('{' + 'items' + '}', 'gi'), this.state.selectedItemNames.length > 1 ? 'items' : 'item')
                                }</p>
                            </div>
                            <div className="footer-btn-holder">
                                <WmsButton
                                    label={this.translate('Label_Cancel')}
                                    wmsSelector={this.state.selector + 'Cancel'}
                                    onClickFunc={this.closeDeleteconfirm}
                                    disabled={false}
                                    loading={false}
                                    buttonType='orange'
                                    icon={'fa-ban'}
                                />
                                <WmsButton
                                    label={this.translate('Label_Select_Confirm')}
                                    wmsSelector={this.state.selector + 'ConfirmDelete'}
                                    onClickFunc={this.state.deleteClickFrom == 'fromInlineGrid' ? this.orderSingleItemDelete :this.orderItemsGridDelete}
                                    disabled={false}
                                    loading={this.state.gifDeleteLineItem}
                                    icon={'fa-check-circle'}
                                />
                            </div>

                        </WmsModel>
                    )}
                </div>

            </div>
        );
    }
}
