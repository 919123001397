import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsDropdownList.scss';

class WmsDropdownList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.windowId = this.props.windowId == "" ? "" : "." + this.props.windowId;
        this.dropdownListId = this.windowId + " #" + this.props.id;
        this.dropdownListData = "";
        this.state = {
            value: props.value || ""
        }
    }
    componentDidMount() {
        var componentProps = this.props;
        $(this.dropdownListId).kendoDropDownList({
            dataTextField: this.props.textField,
            dataValueField: this.props.valueField,
            optionLabel: this.props.optionsLabel,
            filter: this.props.filterType,
            valueTemplate: this.props.valueTemplate,
            value: this.state.value != null ? this.state.value : '',
            template: this.props.template,
            height: this.props.height,
            virtual: (this.props.isVirtualOptions ? this.props.virtualObj : null),
            dataSource: (this.props.isVirtualOptions ? this.props.dataSourceObj : this.props.staticOptions),
            filtering: this.props.filteringObj,
            suggest: this.props.suggest,
            minLength: this.props.minLength,
            autoBind: this.props.autoBind,
            enable: (this.props.disabled == false),
            change: (e) => {
                this.setState({
                    value: e.sender.dataItem()[this.props.valueField]
                });
                this.props.onChangeFunc({
                    target: {
                        value: (this.props.isValuePrimitive) ? e.sender.dataItem()[this.props.valueField] : e.sender.dataItem(),
                        name: this.props.name,
                        selectedIndex: e.sender.selectedIndex
                    }
                });
            },
            dataBound: (e) => {
                this.props.onDataBound(e);
            },
            select: (e) => {
                this.props.onSelect(e);
            },
            open: function(e) {
                e.sender.popup.element.addClass(componentProps.popupClassName);
            }
        });

        this.dropdownListData = $(this.dropdownListId).data("kendoDropDownList");

        this.props.onDropdownBound(this.dropdownListData);



        var that = this;
        this.dropdownListData.wrapper.keydown(function (e) {
            if (that.dropdownListData.value() != "") {
                if (e.keyCode == 13) {
                    that.props.onEnterPress();
                }
            }
        });
        this.dropdownListData.wrapper.blur((e) => {
            if (this.props.required && (!this.dropdownListData.text() || !this.state.value)) {
                $(this.dropdownListId).parents('.k-dropdown').addClass("wms_required_input");
            } else {
                $(this.dropdownListId).parents('.k-dropdown').removeClass("wms_required_input");
            }

        });

        var txtContainsId = this.props.id + "_listbox";
        $("input[aria-owns*='" + txtContainsId + "']").on("keydown", function (el, e) {
            if (e.keyCode == 9 || e.keyCode == 13) {
                that.props.nextFocus();
            }
        }.bind(null, this.dropdownListData));
        // bind tooltip for show more description
        if (this.props.showDetailsTooltip) {
            $('body').kendoTooltip({
                filter: "#" + txtContainsId + '>li.k-item',
                position: 'right',
                content: function (e) {
                    var item = that.dropdownListData.dataItem($(e.target));
                    if (item) {
                        var result = '<p> SKU : ' + item.Sku + '</p>' +
                            '<p> Description : ' + item.Description + '</p>';
                        return result;
                    }
                },
                // height:100,
                // width:100
            });
        }
    }
    componentWillUnmount() {
        console.log("WmsDropdownList componentWillUnmount call");
        if ($(this.dropdownListId).data("kendoDropDownList")) {
            $(this.dropdownListId).data("kendoDropDownList").destroy();
        }
        $(this.dropdownListId).remove();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isVirtualOptions &&
            typeof nextProps.dataSourceObj.transport != "undefined" &&
            JSON.stringify(nextProps.dataSourceObj.transport.read.data) != JSON.stringify(this.props.dataSourceObj.transport.read.data)
        ) {
            var objKendoDropDownList = this.dropdownListData;
            if (typeof objKendoDropDownList != "undefined" && objKendoDropDownList.dataSource.transport.options) {
                objKendoDropDownList.setDataSource(nextProps.dataSourceObj);
                // grid.value('');
                // grid.dataSource.transport.options.read.data = nextProps.dataSourceObj.transport.read.data;
                // grid.dataSource.read();
            }
        }

        if (typeof nextProps.value != "undefined" && nextProps.value !== this.state.value) {
            this.setState({ value: nextProps.value });
            if (!nextProps.value) {
                this.dropdownListData.value(null);
            } else {
                this.dropdownListData.value(nextProps.value);
            }
        }

        if (!nextProps.isVirtualOptions &&
            typeof nextProps.staticOptions != "undefined" &&
            nextProps.staticOptions != this.props.staticOptions
        ) {
            this.dropdownListData.setDataSource(nextProps.staticOptions);
        }

        this.dropdownListData.enable((nextProps.disabled == false));
        if (!nextProps.disabled) {
            var wrapper = this.dropdownListData.wrapper;
            wrapper.attr({ tabindex: 0 });
        }
    }

    onClickClearBtn = (e) => {
        this.setState({
            value: ""
        });

        this.dropdownListData.value(null);
        this.dropdownListData.trigger("change");

    }
    render() {
        return (
            <div className={"wms-kendo-dropdown-list-component " + this.props.extraWrapperClass + (this.props.disabled ? " disabled" : "")}>
                {this.props.label != '' &&
                    <label className="wms-form-label" data-wms-identifier="Shipping_Label_Contacts" htmlFor={this.props.id}>{this.props.label + (this.props.required ? ' *' : '')}</label>
                }
                {/* <select
                        id={this.props.id}
                        name={this.props.name}
                        // className={this.props.required && this.props.value ? (this.props.disabled ? 'k_state_disabled ' : ' ') + style.wms_required_input : this.props.disabled ? 'k_state_disabled ' : ' '}
                        data-wms-selector={this.props.wmsSelector}
                        required={this.props.required}
                        disabled={this.props.disabled}> </select> */}
                <input id={this.props.id}
                    name={this.props.name}
                    data-wms-selector={this.props.wmsSelector}
                    required={this.props.required}
                />
                {this.props.showClearBtn && this.state.value ? (
                    <div className="wms-dropdown-clear" onClick={this.onClickClearBtn}>
                        <i className="fa fa-times-circle-o" aria-hidden="true"></i>
                    </div>) : ''
                }
            </div>
        );
    }

}
WmsDropdownList.propTypes = {
    id: PropTypes.string, // this should write the id to the input,
    name: PropTypes.string, // this should write the id to the input,
    required: PropTypes.bool, // this determines if the input is required and shows in red
    disabled: PropTypes.bool,  // default will be false
    windowId: PropTypes.string, // this should be used to get window id
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    extraWrapperClass: PropTypes.string,
    parentClassName: PropTypes.string, //    this should parent tag class default parent class is form-group
    filterType: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.number
    ]), // this should control the value ,it`s string or number
    valueField: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),// dropdown option value and it mus be require
    textField: PropTypes.string.isRequired,// dropdown option text and it mus be require
    label: PropTypes.string.isRequired,// dropdown title
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
    isVirtualOptions: PropTypes.bool,// this should be true when option from virtual api
    isValuePrimitive: PropTypes.bool,// this should be true when need to return object property on change otherwise return object
    staticOptions: PropTypes.array, // dropdown option and it's not containe virtual api
    onSelect: PropTypes.func, // function call when select in dropdown option
    onEnterPress: PropTypes.func,
    nextFocus: PropTypes.func,
    showClearBtn: PropTypes.bool,
    showDetailsTooltip: PropTypes.bool, // this is used for show tooltip for more details on hover over sku
    valueTemplate: PropTypes.string,
    popupClassName: PropTypes.string

}
WmsDropdownList.defaultProps = {
    height: 260,
    required: false,
    disabled: false,
    virtualObj: null,
    parentClassName: "form_group",
    filterType: "contains", // "contains" ,"startswith"
    extraWrapperClass: '',
    nestedValueField: '',
    label: '',
    isVirtualOptions: true,
    isValuePrimitive: false,
    windowId: '',
    suggest: false,
    minLength: 1,
    autoBind: true,
    showClearBtn: false,
    showDetailsTooltip: false,
    onDataBound: (e) => () => { },
    nextFocus: (e) => () => { },
    onSelect: (e) => () => { },
    onEnterPress: (e) => () => { },
    onDropdownBound: (e) => () => { },
    valueTemplate: '',
    popupClassName: ''
}
export default WmsDropdownList;