import React, { Component } from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";

export class OnTraceComponent extends Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;
    }


    render() {
        return (
            <WmsCollapse
                id={"OnTracAccountInfo"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <WmsInput
                        id="nickname"
                        inputName="Nickname"
                        wmsSelector={this.props.selector + "nickname"}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        label={this.translate('Label_Account_Nick_Name')}
                        value={this.props.currentCarrierObj.Nickname}
                        required={true}
                    />
                    <WmsInput
                        id="account_number"
                        inputName="AccountNumber"
                        wmsSelector={this.props.selector + "accountNumber"}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        label={this.translate("Label_Account_Number")}
                        value={this.props.currentCarrierObj.AccountNumber}
                        required={true}
                    />
                    <WmsInput
                        id="password"
                        inputType={"password"}
                        inputName="Password"
                        wmsSelector={this.props.selector + "password"}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        label={this.translate("Login_Lbl_Password")}
                        value={this.props.currentCarrierObj.Password}
                        required={true}
                    />
                </div>
            </WmsCollapse>
        );
    }
}
