import React, { Component } from 'react';
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';

export class DHLExpressAccountInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.translate = window.translate;
    }

    render() {
        return (
            <WmsCollapse
                id={"carrierAccountInformation"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id="accountNickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Nick_Name")}
                            value={this.props.currentCarrierObj.Nickname}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="account_number"
                            inputName="AccountNumber"
                            wmsSelector={this.props.selector + "accountNumber"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Number")}
                            value={this.props.currentCarrierObj.AccountNumber}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                    </div>
                </div>
            </WmsCollapse>
        )
    }
}
