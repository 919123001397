import * as GlobalService from '../global_services';
var isFirstTimeOpen = true;
var keyName = "SmartParcel";
var metaDataOrderObject = {
    "SmartParcel": {
        "filterData": {

        },
        "gridConfig": {
            "findOrderGrid": {

            }
        },
        "saveFilter": [],
        "sorableSection": "",
        "collapseData": {

        },
        "packAndShipModel": {
            'smartPackGrid': {

            },
            'isAutoPrintEnable': false
        }
    }
};

// call api for get metaData of order is it's open first time
export const getUserClientMetaData = function () {
    if (isFirstTimeOpen) {
        return GlobalService.getUserClientMetaData(keyName).then(
            function successCallBack(response) {
                if (response != "") {
                    Object.assign(metaDataOrderObject[keyName], JSON.parse(response));
                }
                isFirstTimeOpen = false;
                return true;
            },
            function errorCallBack(response) {
                return false;
            }
        );
    }
}

export const setSmallParcelCollapseMetaData = function (collapseId, value) {
    if (!metaDataOrderObject[keyName]['collapseData']) {
        metaDataOrderObject[keyName]['collapseData'] = {}
    }
    metaDataOrderObject[keyName]['collapseData'][collapseId] = value;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}
export const getSmallParcelCollapseMetaData = function () {
    return metaDataOrderObject[keyName]['collapseData'];
}




export const setSmallParcelSortSectionMetaData = function (sortArray) {
    metaDataOrderObject[keyName]['sorableSection'] = sortArray;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getSmallParcelSortSectionMetaData = function () {
    return metaDataOrderObject[keyName]['sorableSection'];
}

export const setSmallParcelFilterMetaData = function (filterData) {
    metaDataOrderObject[keyName]['filterData'] = filterData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getSmallParcelFilterMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['filterData'])) {
        return metaDataOrderObject[keyName]['filterData'];
    } else {
        return "";
    }

}

// save filter of find order in meta data
export const saveSmallParcelFilterMetaData = function (title, filterObj, columnObj) {
    var setData = {
        title: title,
        filterData: filterObj,
        columnData: columnObj.optionsData
    };
    if (typeof metaDataOrderObject[keyName]['saveFilter'] == "undefined") {
        metaDataOrderObject[keyName]['saveFilter'] = [];
    }
    (metaDataOrderObject[keyName]['saveFilter']).push(setData);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));

}
export const updateSmallParcelFilterMetaData = function (index, filterObj, columnObj) {

    if (typeof metaDataOrderObject[keyName]['saveFilter'][index] != "undefined") {
        metaDataOrderObject[keyName]['saveFilter'][index].filterData = filterObj;
        metaDataOrderObject[keyName]['saveFilter'][index].columnData = columnObj.optionsData;
        GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
    }

}
export const getSaveSmallParcelFilterListMetaData = function () {
    return metaDataOrderObject[keyName]['saveFilter'];
}

export const clearSaveSmallParcelFilterListMetaData = function (index) {
    metaDataOrderObject[keyName]['saveFilter'].splice(index, 1);
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const applySavedFilterDataToSmallParcelGrid = function (gridConfig, filterData) {
    metaDataOrderObject[keyName]['gridConfig']['findOrderGrid'] = gridConfig.optionsData;
    metaDataOrderObject[keyName]['filterData'] = filterData;

    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const clearSmallParcelFilterMetaData = function () {
    metaDataOrderObject[keyName]['filterData'] = {};
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const setSmallParcelGridOptionInMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['gridConfig']['findOrderGrid'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getSmallParcelGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['gridConfig']['findOrderGrid'])) {
        return metaDataOrderObject[keyName]['gridConfig']['findOrderGrid'];
    } else {
        return "";
    }
}

export const setPackAndShipGridOptionInMetaData = function (gridConfig) {
    metaDataOrderObject[keyName]['packAndShipModel']['smartPackGrid'] = gridConfig.optionsData;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getPackAndShipGridOptionInMetaData = function () {
    if (!$.isEmptyObject(metaDataOrderObject[keyName]['packAndShipModel']['smartPackGrid'])) {
        return metaDataOrderObject[keyName]['packAndShipModel']['smartPackGrid'];
    } else {
        return "";
    }
}
export const setPackAndShipAutoPrintInMetaData = function (autoPrintEnable) {
    metaDataOrderObject[keyName]['packAndShipModel']['isAutoPrintEnable'] = autoPrintEnable;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getPackAndShipAutoPrintInMetaData = function () {
    if (metaDataOrderObject[keyName]['packAndShipModel']['isAutoPrintEnable']) {
        return metaDataOrderObject[keyName]['packAndShipModel']['isAutoPrintEnable'];
    } else {
        return false;
    }
}
export const setPackAndShipOneQtyPackInMetaData = function (oneQtyPackEnable) {
    metaDataOrderObject[keyName]['packAndShipModel']['oneQtyPack'] = oneQtyPackEnable;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getPackAndShipOneQtyPackInMetaData = function () {
    if (metaDataOrderObject[keyName]['packAndShipModel']['oneQtyPack']) {
        return metaDataOrderObject[keyName]['packAndShipModel']['oneQtyPack'];
    } else {
        return false;
    }
}
export const setPackAndShipTransactionLabelInMetaData = function (transactionLabel) {
    metaDataOrderObject[keyName]['packAndShipModel']['transactionLabel'] = transactionLabel;
    GlobalService.setUserClientMetaData(keyName, JSON.stringify(metaDataOrderObject[keyName]));
}

export const getPackAndShipTransactionLabelInMetaData = function () {
    if (metaDataOrderObject[keyName]['packAndShipModel']['transactionLabel']) {
        return parseInt(metaDataOrderObject[keyName]['packAndShipModel']['transactionLabel']);
    } else {
        return 0;
    }
}