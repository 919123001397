import React, { Component } from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";

export class PurolatorAccountInfoComponent extends Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;
    }


    render() {
        return (
            <WmsCollapse
                id={"carrierAccountInformation"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <div className="display-inline">
                        <WmsInput
                            id="nickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate('Label_Account_Nick_Name')}
                            value={this.props.currentCarrierObj.Nickname}
                            extraWrapperClass={"width-40"}
                            required={true}
                        />
                        <WmsInput
                            id="apiKey"
                            inputName="ApiKey"
                            wmsSelector={this.props.selector + "apiKey"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Number")}
                            value={this.props.currentCarrierObj.ApiKey}
                            extraWrapperClass={"width-40"}
                            required={true}
                        />
                    </div>
                    <WmsInput
                        id="passphrase"
                        inputName="Passphrase"
                        wmsSelector={this.props.selector + "passphrase"}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        label={this.translate("Label_Activation_key")}
                        value={this.props.currentCarrierObj.Passphrase}
                        extraWrapperClass={"width-100"}
                        required={true}
                    />
                </div>
            </WmsCollapse>
        );
    }
}
