import React from 'react';
import { StringWms } from '../../../../assets/ts/StringWms.ts';

//component
import WmsButton from '../WmsButton/WmsButton.jsx';
import WmsCollapse from '../WmsCollapse/WmsCollapse.jsx';
import WmsFile from '../WmsFile/WmsFile.jsx';
import WmsFormGrid from '../WmsFormGrid/WmsFormGrid.jsx';
import WmsModel from '../WmsModel/WmsModel.jsx';
import WmsDropdown from '../WmsDropdown/WmsDropdown.jsx';
import WmsDropdownList from '../WmsDropdownList/WmsDropdownList.jsx';
import './AttachDocuments.scss';

import * as GlobalService from '../../global_services';
import * as attachDocumentsServices from './attachDocuments.service.jsx';
import { kendo } from "./../../global_variable_service.jsx";

export class AttachDocuments extends React.PureComponent {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.customLabels = GlobalService.getCustomLabels();
        let gridHeaders = StringWms.convertSringToOption(this.translate('AttachFile_GridHeaders'));
        let customers = GlobalService.getCustomerList(false);
        this.state = {
            selector: "attachDocument",
            optionsCustomer: GlobalService.getCustomerList(false),
            selectedCustomerId: (customers.length ? customers[0].id : 0),
            batchOrderid: 0,
            batchOrderOptions: [],
            atttachGridData: null,
            selectedAttachItem: null,
            selectedFileData: {},
            selectedFile: 0,
            gifAttachFile: false,
            gifRemoveFile: false,
            acticveDoc: false,
            gridColumns: [
                {
                    field: "Class",
                    title: gridHeaders[0].description,
                    groupHeaderTemplate: "#=value#",
                    width: 70
                }, {
                    field: "DocName",
                    title: gridHeaders[1].description,
                    template: '#if(DownloadUri != null) {# <a href="javascript:void(0);" class="download-file" download-uri="#=DownloadUri#" doc-name="#=DocName#"  download>#=DocName#</a> #} else{# <span>#=DocName#</span> #}#',
                    width: 100
                }, {
                    field: "DocLength",
                    title: gridHeaders[2].description,
                    template: "#= DocLength != null ?  (DocLength > 1048576 ? (DocLength / 1048576).toFixed(2) + ' MB' : (DocLength / 1024).toFixed(2) + ' KB' ): ''#",
                    width: 100
                }, {
                    field: "AttachedDate",
                    title: gridHeaders[3].description,
                    template: "#= AttachedDate != null ? kendo.toString(kendo.parseDate(AttachedDate), 'MM/dd/yyyy hh:mm:ss tt')  : '' # #= AttachedBy  ? ' by ' + AttachedBy : ''#",
                    width: 200
                }, {
                    field: "RemovedDate",
                    title: gridHeaders[4].description,
                    template: "#= RemovedDate != null ? kendo.toString(kendo.parseDate(RemovedDate), 'MM/dd/yyyy hh:mm:ss tt') : '' #  #= RemovedBy  ? ' by ' + RemovedBy : ''#",
                    width: 200
                }
            ],
            gridSchema: {
                data: [],
                schema: {
                    model: {
                        fields: {
                            Class: {
                                type: "string"
                            },
                            DocName: {
                                type: "string"
                            },
                            DocLength: {
                                type: "number",
                                editable: false
                            },
                            AttachedDate: {
                                type: "date"
                            },
                            RemovedDate: {
                                type: "date"
                            }
                        }
                    }
                }
            }
        };
    }
    UNSAFE_componentWillMount() {
        let self = this;
        $(document).off("click", ".download-file");
    }
    componentDidMount() {
        let self = this;
        // $(".download-file").off("click");
        $(document).on("click", ".download-file", attachDownloadEvent);
        function attachDownloadEvent() {
            self.downloadFile($(this).attr('download-uri'), $(this).attr('doc-name'));
        }
        if (this.props.isOpenAttachDoc == true && !this.props.sharedAttachObj.isOrderAttachBatch) {
            this.getAttachDocsInfo(this.props.sharedAttachObj.getAttachedDocsInfoMethod, this.props.sharedAttachObj.getAttachedDocsInfoUrl, this.props.sharedAttachObj.getAttachedDocsInfoPayload);
        }
        // document.addEventListener("EventOrderChangedRemove", function handler(e) {
        //     console.log("Remove lister call React AttachDocument");
        //     // alert("remove from attach file");
        //     $(document).off("click", ".download-file", self.attachDownloadEvent);
        //     e.currentTarget.removeEventListener(e.type, handler);
        //     /* whatever */
        // });

    }


    getAttachDocsInfo = (method, param_url, param_payload) => {
        this.setState({
            atttachGridData: [],
            selectedFileData: {}
        });
        kendo.ui.progress($('#' + this.props.windowId + 'attachDocuments'), true);
        attachDocumentsServices.getAttachedDocsInfo(method, param_url, param_payload).then((response) => {
            if (typeof response != 'undefined' && (response.status == 200 || response.status == 204)) {
                this.setState({ atttachGridData: this.addActiveRemoveClass(response.data) }, () => {
                });
            }
        }).finally(() => {
            kendo.ui.progress($('#' + this.props.windowId + 'attachDocuments'), false);
        });
    }

    eventAttachClicked = () => {
        this.setState({ gifAttachFile: true });

        if (this.state.selectedFileData.FileSize >= 8388608) {

            GlobalService.notification('warning', this.translate('Label_LargeFileUpload_Error'));
            this.setState({ gifAttachFile: false });
        } else {

            let attachdocumentUrl =  '';
            if(this.props.sharedAttachObj.isOrderAttachBatch){
                var selectedBatchObj =this.state.batchOrderOptions.find( obj => obj.BatchOrderId == this.state.batchOrderid);
                attachdocumentUrl = this.props.sharedAttachObj.attachdocumentUrl + selectedBatchObj.BatchUri+'/filesummaries' + '&fileName=' + this.state.selectedFileData.fileName;

            }else{
                attachdocumentUrl = this.props.sharedAttachObj.attachdocumentUrl + '&fileName=' + this.state.selectedFileData.fileName;
            }

            attachDocumentsServices.attachDocument(
                attachdocumentUrl,
                this.state.selectedFileData.FileContents,
                this.state.selectedFileData.ContentType
            ).then((response) => {
                if(this.props.sharedAttachObj.isOrderAttachBatch){
                    var selectedBatchObj =this.state.batchOrderOptions.find( obj => obj.BatchOrderId == this.state.batchOrderid);
                    var url = this.props.sharedAttachObj.getAttachedDocsInfoUrl + selectedBatchObj.BatchUri
                    this.getAttachDocsInfo(this.props.sharedAttachObj.getAttachedDocsInfoMethod, url, {});
                }else{
                    this.getAttachDocsInfo(this.props.sharedAttachObj.getAttachedDocsInfoMethod, this.props.sharedAttachObj.getAttachedDocsInfoUrl, this.props.sharedAttachObj.getAttachedDocsInfoPayload);
                }

            }).finally(() => {
                this.setState({ gifAttachFile: false });
            });
        }
    }

    downloadFile = (uri, fileName) => {
        uri = uri.replace(/%27%27/g, "'");
        var getAttachDocUrl = '/WebUI/Shared/GlobalServices/GetAttachedDoc';
        if (this.props.sharedAttachObj.isReceipt == true){
            getAttachDocUrl = '/WebUI/Shared/GlobalServices/GetReceiptAttachedDoc';
        }else if (this.props.sharedAttachObj.isOrder == true){
            getAttachDocUrl = '/WebUI/Shared/GlobalServices/GetOrderAttachedDoc';
        }
        attachDocumentsServices.getAttachedDoc(getAttachDocUrl, uri).then((response) => {
            var headers = response.headers;
            GlobalService.downloadFileBaseOnContentType(response.data, headers['content-type'], fileName, true);
        }).finally(() => {
            // this.setState({ gifAttachFile: false });
        });

    }

    addActiveRemoveClass = (param_attachedDocsData) => {
        let combinedArray = [];
        for (let x = 0; x < param_attachedDocsData.length; x++) {
            if (param_attachedDocsData[x].RemovedBy != "" && param_attachedDocsData[x].RemovedDate != null) {
                param_attachedDocsData[x].Class = "Removed";
            } else {
                param_attachedDocsData[x].Class = "Active";
            }
            combinedArray.push(param_attachedDocsData[x]);
        }
        return combinedArray;
    }

    handleFileChange = (param_fileobj) => {
        if (param_fileobj.fileName) {
            param_fileobj.fileName = param_fileobj.fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, "-");
        }
        this.setState({ selectedFileData: param_fileobj }, () => {
            this.eventAttachClicked();
        });
    }
    handleOptionsChange = (e) => {

        this.setState({ [e.target.name]: e.target.value });
        if(e.target.name == "batchOrderid" && e.target.value){
            var selectedBatchObj =this.state.batchOrderOptions.find( obj => obj.BatchOrderId == e.target.value);
            var url = this.props.sharedAttachObj.getAttachedDocsInfoUrl + selectedBatchObj.BatchUri
            this.getAttachDocsInfo(this.props.sharedAttachObj.getAttachedDocsInfoMethod, url, {});
        }else{
            this.setState({
                atttachGridData: [],
                selectedFileData: {},
                BatchOrderId: 0
            });
        }
    }

    selectAttachGrid = () => {

        let grid = $("#" + this.props.windowId + "gridAttachedDocs").data("kendoGrid");
        let selected = [];
        let acticveDoc = false;
        $.map(grid.select(), (item) => {
            let selectItem = grid.dataItem(item);
            if (selectItem.RemovedDate == null && selectItem.RemovedBy == "") {
                acticveDoc = true;
            } else {
                acticveDoc = false;
            }
            selected.push(selectItem);
        });

        this.setState({
            acticveDoc: acticveDoc,
            selectedAttachItem: selected[0],
            selectedFile: selected.length
        });
    }

    eventClickRemoveDoc = () => {

        this.setState({ gifRemoveFile: true });

        var uri = this.state.selectedAttachItem.DownloadUri;
        var removeAttachDocUrl = this.props.sharedAttachObj.removeAttachDocUrl;
        var payloadData;
        if (this.props.sharedAttachObj.isAssembly){
            payloadData = {
                assemblyFileUri: uri,
                receiverUri: this.props.sharedAttachObj.uri
            };
        }
        else if (this.props.sharedAttachObj.isReceipt == true) {
            payloadData = {
                receiverFileUri: uri,
                receiverUri: this.props.sharedAttachObj.uri
            };
        } else if(this.props.sharedAttachObj.isOrderAttachBatch){
            var selectedBatchObj =this.state.batchOrderOptions.find( obj => obj.BatchOrderId == this.state.batchOrderid);
            // “/WebUI/Shipping/Orders/DeleteBatchAttachedDoc?batchFileUri={batchFileUri}&batchUri={batchUri}”
            removeAttachDocUrl = removeAttachDocUrl + uri + '&batchUri='+selectedBatchObj.BatchUri;
        }else {
            payloadData = {
                orderFileUri: uri,
                orderUri: this.props.sharedAttachObj.uri
            };
        }

        attachDocumentsServices.removeAttachedDoc(
            removeAttachDocUrl,
            payloadData
        ).then((response) => {
            if (typeof response != 'undefined' && response.status == 200) {

                this.setState({
                    atttachGridData: this.addActiveRemoveClass(response.data),
                    selectedFile: 0,
                    acticveDoc: false
                });
                GlobalService.notification('success', this.translate('Label_AttachedDoc_Removed_Success'));

            }

        }).finally(() => {
            this.setState({ gifRemoveFile: false });
        });
    }
    batchOptionBound = (event) => {
        this.setState({ batchOrderOptions: event.sender.dataSource._data });
    }

    render() {
        var selectedCustomerId = this.state.selectedCustomerId;
        return (
            <WmsModel
                id={this.props.windowId + 'attachDocuments'}
                title={this.props.sharedAttachObj.isOrderAttachBatch ? this.translate('Label_Attach_Files_To_Batch') : this.translate('Label_Attach_Files')}
                width={800}
                height={800}
                class='attachDocuments'
                onCloseFunc={this.props.onClose}
                customClass={'wms-model-new-ui-wrapper'}
                isOpen={this.props.isOpenAttachDoc}>
                <div className='model-content-wrapper'>
                    {(this.props.sharedAttachObj.isReceipt || this.props.sharedAttachObj.isOrder || this.props.sharedAttachObj.isAssembly) && (
                        <div className="attach-file-detail">
                            <div className="detail-label-wrapper">
                                <label >
                                    {this.translate('Settings_Label_Custom_Customer')}
                                </label>
                                <label >
                                    {this.translate('Orders_Lbl_Transaction_Id')}
                                </label>
                            </div>
                            <div className="detail-value-wrapper">
                                <span>
                                    {this.props.sharedAttachObj.customer}
                                </span>
                                <span>
                                    {this.props.sharedAttachObj.transactionId}
                                </span>
                            </div>
                        </div>
                    )}
                    <WmsCollapse
                        id={'attachFilesAddDelete'}
                        headerLabel={this.translate('Label_Add_Delete_Header')}
                        showCollapseText={false}
                        showCollapseIcon={false}>
                        {this.props.sharedAttachObj.isOrderAttachBatch && (
                            <div className="customer-batch-wrapper">
                                <WmsDropdown
                                    id="Customer"
                                    name="selectedCustomerId"
                                    label={this.customLabels.Label_Customer}
                                    wmsSelector={"attachDocCustomer"}
                                    value={this.state.selectedCustomerId}
                                    onChangeFunc={this.handleOptionsChange}
                                    options={this.state.optionsCustomer}
                                    textField='name'
                                    valueField='id'
                                />
                                <WmsDropdownList
                                    key={'DropdownlistCustomerBatches'+this.state.selectedCustomerId}
                                    id="DropdownlistCustomerBatches"
                                    name="batchOrderid"
                                    label={this.translate('Label_Batching')}
                                    optionsLabel={this.translate('Label_Choose_Batch')}
                                    wmsSelector={"attachDocCustomerBatches"}
                                    value={this.state.batchOrderid}
                                    onChangeFunc={this.handleOptionsChange}
                                    textField='Description'
                                    valueField='BatchOrderId'
                                    template={"<span class='templtehere' style='white-space: nowrap;text-overflow: ellipsis;display: block;overflow: hidden'>(${ BatchOrderId }) ${  (Description != null) ? Description : ''  }</span>"}
                                    valueTemplate={"<span class='templtehere' style='white-space: nowrap;text-overflow: ellipsis;display: block;overflow: hidden'>(${ BatchOrderId }) ${  (Description != null) ? Description : '' }</span>"}
                                    onDataBound={this.batchOptionBound}
                                    isValuePrimitive={true}
                                    virtualObj={{
                                        itemHeight: 26,
                                        valueMapper: (options) => {
                                            var that = options;

                                            $.ajax({
                                                url: "/WebUI/Shipping/FindOrders/BatchListForCustomerIndexRead",
                                                type: "POST",
                                                dataType: "json",
                                                data: {
                                                    batchOrderId: that.value || 0,
                                                    customerId: parseInt(selectedCustomerId)
                                                },
                                                success: function (data) {
                                                    console.log('dropdownlist', data);
                                                    options.success(data);
                                                }
                                            });
                                        }
                                    }}
                                    dataSourceObj={{
                                        pageSize: 40,
                                        serverPaging: true,
                                        serverFiltering: true,
                                        type: "aspnetmvc-ajax",
                                        transport: {
                                            read: {
                                                url: "/WebUI/Shipping/FindOrders/BatchListForCustomerRead",
                                                type: "POST",
                                                data: {
                                                    customerId: parseInt(selectedCustomerId)
                                                }
                                            },
                                        },
                                        schema: {
                                            data: "Data",
                                            total: "Total"
                                        }
                                    }}
                                />
                            </div>
                        )}
                        <div className="btn-wrapper">
                            <WmsFile
                                id="label-add-file"
                                name="label-add-file"
                                label={this.translate('Label_Add_File')}
                                wmsSelector="attachDocFiles"
                                fileProcessType="FileSelect"
                                value={this.state.selectedFileData.fileName}
                                onChangeFunc={this.handleFileChange}
                                disabled={this.props.sharedAttachObj.isOrderAttachBatch && !(this.state.selectedCustomerId && this.state.batchOrderid)}
                                icon="fa-plus"
                                showSelectedFileName={false}
                                clearInputOnClick={true}
                                loading={this.state.gifAttachFile}
                            />
                            <WmsButton
                                label={this.translate('Label_Remove_Attach_Files')}
                                wmsSelector={"removeAttachDoc"}
                                onClickFunc={this.eventClickRemoveDoc}
                                disabled={this.state.selectedFile == 0 || this.state.selectedFile > 1 || this.state.acticveDoc == false || (this.props.sharedAttachObj.isOrder && this.props.sharedAttachObj.selectedOrderData.IsClosed)}
                                loading={this.state.gifRemoveFile}
                                showToolTip={this.state.selectedFile == 0 || this.state.selectedFile > 1 || this.state.acticveDoc == false || (this.props.sharedAttachObj.isOrder && this.props.sharedAttachObj.selectedOrderData.IsClosed)}
                                tooltipText={
                                    this.state.selectedFile == 0 ?
                                        this.translate('Label_Select_Attach_Files') :
                                        (this.state.selectedFile > 1 ?
                                            this.translate('Label_Multiple_Files_Tooltip') :
                                            (
                                                this.state.acticveDoc == false ?
                                                    this.translate('Label_Select_Removed_Files') :
                                                    this.translate('Label_Remove_Attach_Files_From_Closed_Order')
                                            )
                                        )
                                }
                                icon={'fa-trash'}
                                buttonType="red"
                            />
                        </div>
                        <WmsFormGrid
                            id={this.props.windowId + 'gridAttachedDocs'}
                            wmsSelector={'gridAttachedDocs'}
                            gridKeySelection={'DocName'}
                            columns={this.state.gridColumns}
                            staticGridData={this.state.atttachGridData}
                            schema={this.state.gridSchema}
                            isColumnsResizable={true}
                            isColumnsReorderable={true}
                            isColumnMenuenable={false}
                            isColumnsSortable={true}
                            parentClassName={'showHeader'}
                            showResetMenu={false}
                            onSelection={this.selectAttachGrid}
                            IsMultipleSelectable={true}
                        />
                    </WmsCollapse>
                </div>

                <div className="footer-btn-holder">
                    <WmsButton
                        label={this.translate('Label_Close')}
                        wmsSelector={"shipAndcloseCancel"}
                        onClickFunc={this.props.onClose}
                        disabled={false}
                        loading={false}
                        buttonType='orange'
                        icon={'fa-times-circle-o'}
                    />
                </div>


            </WmsModel>
        );
    }
}