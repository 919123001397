import * as GlobalService from "../../global_services";
declare var window: any;
const { $ } = window;
var isFirstTimeOpen = true;
var axios = require('axios');
var keyName = "ConfigurePackingSlips";
var metaDataOrderObject = {
    ConfigurePackingSlips: {
      ManageConfigurePackingSlips: {
      filterData: {},
      gridConfig: {},
      collapseData: {},
      sorableSection: "",
      saveFilter: [],
      }
    }
};

export const getConfigurePackingSlipsGridData = function () {
  let url = '/WebUI/Settings/PackingListTemplates/PackingListTemplates';

  return axios.get(url).then(function (response: any) {
    return response;
  });
};
export const saveConfigurePackingSlipsData = function (data: any) {
  let url = '/WebUI/Settings/PackingListTemplates/PackingListTemplate';

  return axios.post(url, data).then(function (response:any) {
    return response;
  });
};
export const getConfigurePackingSlipsData = function (templateId: number) {
  let url = '/WebUI/Settings/PackingListTemplates/PackingListTemplate?editUri=properties/packinglisttemplates/'+templateId;

  return axios.get(url).then(function (response: any) {
    return response;
  });
};
export const updateConfigurePackingSlipsData = function (data: any) {
  let url = '/WebUI/Settings/PackingListTemplates/PackingListTemplate';

  return axios.put(url, data).then(function (response:any) {
    return response;
  });
};
export const deleteConfigurePackingSlipsData = function (templateId: number) {
  let url = '/WebUI/Settings/PackingListTemplates/PackingListTemplate?editUri=properties/packinglisttemplates/'+templateId;

  return axios.delete(url).then(function (response: any) {
    return response;
  });
};

export const saveEventNotificationGridData = function (cstEditUri: string, data: any) {
  let url = '/WebUI/Customers/WebHooksConfig/GridSave';
  if (cstEditUri) {
    url = url + "?editUri=" + cstEditUri
  }
  return axios.put(url, data).then(function (response: any) {
    return response;
  });
}

export const getUserClientMetaData = function () {
  console.log('isFirstTimeOpen', isFirstTimeOpen);
  if (isFirstTimeOpen) {
    console.log('isFirstTimeOpen call', isFirstTimeOpen);
    return GlobalService.getUserClientMetaData(keyName).then(
      function successCallBack(response) {
        if (response !== "") {
          Object.assign(metaDataOrderObject[keyName], JSON.parse(response));
        }
        isFirstTimeOpen = false;
        return true;
      },
      function errorCallBack(response) {
        console.log(response);
        return false;
      }
    );
  }
};
export const setConfigurePackingSlipsGridOptionInMetaData = function (
  gridConfig
) {
  metaDataOrderObject[keyName]["ManageConfigurePackingSlips"]["gridConfig"] =
    gridConfig.optionsData;
  GlobalService.setUserClientMetaData(
    keyName,
    JSON.stringify(metaDataOrderObject[keyName])
  );
};

export const getConfigurePackingSlipsGridOptionInMetaData = function () {
  if (
    !$.isEmptyObject(
      metaDataOrderObject[keyName]["ManageConfigurePackingSlips"]["gridConfig"]
    )
  ) {
    return metaDataOrderObject[keyName]["ManageConfigurePackingSlips"]["gridConfig"];
  } else {
    return "";
  }
};