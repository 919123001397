import React, { useState, useEffect } from "react";
import MobileLabelTemplatesSearch from "./mobile-label-templates-search.component";
import MobileLabelTemplatesGrid from "./mobile-label-templates-grid.component";
import "./mobileLabelTemplates.scss";
import * as MobileLabelTemplates from "./mobile-label-template.services";
interface Props {}

declare var window: any;
var $rootScope = window;
const wmsSelector = "MobileLabelTemplates";
const defaultFilterValue = {
    labelName: null,
    cusId: 0,
};

const MobileLabelTemplatesHolder: React.FC<Props> = (props: Props): any => {
    const [windowId] = useState($rootScope.ChildBulletin.Data.Window);
    const [sharedSearchOn, setSharedSearchOn] = useState(false);
    const [sharedFilterObject, setSharedFilterObject] = useState(defaultFilterValue);
    const [loadGrid, setLoadGrid] = useState(false);

    useEffect(() => {
        async function getGridMeta() {
            await MobileLabelTemplates.getMobileLabelTemplatesClientMetaData();
            let filterData = MobileLabelTemplates.getMobileLabelTemplatesFilterMetaData();
            if (filterData) {
                setSharedFilterObject(filterData);
            }
            setLoadGrid(true);
        }
        getGridMeta();
    }, []);

    const onClearSearch = () => {
        MobileLabelTemplates.clearMobileLabelTemplatesFilterMetaData();
        setSharedSearchOn(false);
        setSharedFilterObject(defaultFilterValue);
    };

    const onFilterDataSet = (filterObject: any) => {
        if (
            filterObject.CustomerID !== 0 ||
            filterObject.FacilityID !== 0 ||
            filterObject.WarehouseTransactionID !== null ||
            filterObject.ReferenceNum !== null ||
            filterObject.StartDate !== null ||
            filterObject.EndDate !== null
        ) {
            setSharedSearchOn(true);
        }
        setSharedFilterObject(filterObject);
        MobileLabelTemplates.setMobileLabelTemplatesFilterMetaData(filterObject);
    };

    return (
        <div className="mobile-label-templates-wrapper">
            <MobileLabelTemplatesSearch
                wmsSelector={wmsSelector}
                sharedSearchOn={sharedSearchOn}
                sharedFilterObject={sharedFilterObject}
                onClearSearch={onClearSearch}
                onFilterDataSet={onFilterDataSet}
                windowId={windowId}
            />
            {loadGrid && (
                <MobileLabelTemplatesGrid
                    wmsSelector={wmsSelector}
                    windowId={windowId}
                    sharedFilterObject={sharedFilterObject}
                />
            )}
        </div>
    );
};
MobileLabelTemplatesHolder.propTypes = {};

MobileLabelTemplatesHolder.defaultProps = {};

export default React.memo(MobileLabelTemplatesHolder);
