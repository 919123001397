
export const generatePdf = function (barcodeDataStatic, customerAll, barcodeDataCustomer) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {

            var doc = new jspdf.jsPDF();
            doc.setFontSize(24);
            doc.text("3PL Central Small Parcel Suite", 15, 10);

            doc.setFontSize(16);
            doc.text("SmartPack Barcode Sheet", 15, 20);

            doc.setFontSize(16);
            var text = "SmartPack Actions";
            doc.text(text, 80, 30);
            let commonOptions = {
                head: [{
                    "Name": "Name",
                    "Description": "Description",
                    "Barcode": "Barcode"
                }],
                theme: 'grid',
                rowPageBreak: 'avoid',
                columnStyles: {
                    Barcode: { cellWidth: 80 },
                },
                didDrawCell: function (data) {
                    if (data.column.dataKey == "Barcode" && data.cell.section == 'body') {
                        var td = data.cell.raw;
                        if (td) {
                            var canvas = document.createElement("canvas");
                            JsBarcode(canvas, td);
                            var imgSrc = canvas.toDataURL({
                                format: 'jpeg',
                                quality: 0.9 // compression works now!
                            });
                            var dim = data.cell.height - data.cell.padding('vertical');
                            doc.addImage(imgSrc, data.cell.x + 1, data.cell.y + 1, dim + 40, dim);
                        }
                    }
                },
                didParseCell: function (HookData) {
                    if (HookData.cell.section == 'head') {
                        HookData.cell.styles.halign = "center";
                        HookData.cell.styles.valign = "middle";
                        HookData.cell.styles.fillColor = 255;
                        HookData.cell.styles.textColor = 0;
                        HookData.cell.styles.lineWidth = 0.1;
                        HookData.cell.styles.lineColor = 200;

                    } else if (HookData.cell.section == 'body') {
                        HookData.cell.styles.minCellHeight = 38;
                    }
                }
            };
            let barcodeOptions = _.cloneDeep(commonOptions);
            barcodeOptions.margin = {
                top: 40
            };
            barcodeOptions.body = barcodeDataStatic;
            doc.autoTable(barcodeOptions);

            doc.addPage();
            doc.setFontSize(16);
            doc.text("General Use Packages", 80, 10); //you use the variable and add the number of pixels you want it to move.

            let customerAllOptions = _.cloneDeep(commonOptions);
            customerAllOptions.body = customerAll;
            doc.autoTable(customerAllOptions);

            Object.keys(barcodeDataCustomer).map((keyName, i) => {
                var responseData = _.map(barcodeDataCustomer[keyName], function (object) {
                    return _.omit(object, ['AssignedCustomers']);
                });
                doc.addPage();
                doc.setFontSize(16);
                doc.text(keyName, 80, 10); //you use the variable and add the number of pixels you want it to move.
                let responseDataOptions = _.cloneDeep(commonOptions);
                responseDataOptions.body = responseData;
                doc.autoTable(responseDataOptions);
            });

            doc.setProperties({
                DisplayDocTitle: true,
                title: "Small Parcel Suite - Barcode Sheet"
            });
            // doc.output('dataurlnewwindow');
            // doc.save('barcode-sheet.pdf', { returnPromise: true });
            doc.save('barcode-sheet.pdf');
            resolve(true);
        }, 100);
    });
}