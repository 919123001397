import React from 'react';

//component
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsCheckBox from '../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsCollapse from './../components/WmsCollapse/WmsCollapse.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';
import WmsRadio from './../components/WmsRadio/WmsRadio.tsx';

import * as GlobalService from './../global_services';

export class ItemsUofm extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.state = {
            Selector: 'Item',
            customLabels: GlobalService.getCustomLabels(),
            Rights: GlobalService.GetRights()
        }
    }

    componentDidMount() {
        this.updateTotalAreaVolume(this.props.sharedItem, true);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.sharedItem.ItemId != this.props.sharedItem.ItemId) {
            this.updateTotalAreaVolume(nextProps.sharedItem, true);
        }
    }

    handleChange = (param_parent_property, event) => {

        if (event.target.type == 'radio') {

            //set movableunit option  imperial or matrix radio button change
            this.handleMovableUnitSelectChange(this.props.sharedItem.UnitsOfMeasure.MovableUnit.MovableUnitUomIndex);

            let itemsObj = Object.assign(
                {},
                this.props.sharedItem,
                {
                    UnitsOfMeasure: Object.assign(
                        {},
                        this.props.sharedItem.UnitsOfMeasure,
                        {
                            [event.target.name]: !this.props.sharedItem.UnitsOfMeasure[event.target.name]
                        }
                    )
                }
            );

            this.updateTotalAreaVolume(itemsObj);

        } else {

            let parentObj = Object.assign({}, this.props.sharedItem.UnitsOfMeasure[param_parent_property]);
            if (event.target.type == 'checkbox') {
                parentObj[event.target.name] = event.target.checked;
                if (event.target.name === "UseSecondaryUnit") {
                    parentObj["SecondaryUnitType"] = "";
                }
            } else {
                parentObj[event.target.name] = event.target.value;
            }

            let itemsObj = Object.assign(
                {},
                this.props.sharedItem,
                {
                    UnitsOfMeasure: Object.assign(
                        {},
                        this.props.sharedItem.UnitsOfMeasure,
                        { [param_parent_property]: parentObj }
                    )
                }
            );
            this.props.onItemDataset(itemsObj);
            if (event.target.name == "InventoryUnitsPerUnit") {
                this.props.calculateCuftForRecStorage(itemsObj);
            }
        }
    }
    handleItemBasicChange = (param_parent_property, event) => {
        let parentObj = Object.assign({}, this.props.sharedItem.ItemBasics[param_parent_property]);
        parentObj[event.target.name] = event.target.value;
        let itemsObj = Object.assign(
            {},
            this.props.sharedItem,
            {
                ItemBasics: Object.assign(
                    {},
                    this.props.sharedItem.ItemBasics,
                    { [param_parent_property]: parentObj }
                )
            }
        );
        this.props.onItemDataset(itemsObj);
    }

    handleChangeOfPickline = (event) => {
        let itemsObj = Object.assign({}, this.props.sharedItem);
        itemsObj[event.target.name] = event.target.value;
        if (!itemsObj.PicklineMin) {
            itemsObj.PicklineMax = null
        }
        this.props.onItemDataset(itemsObj);
    }

    handleReplenishmentToggle = (event) => {
        let itemsObj = Object.assign({}, this.props.sharedItem);
        itemsObj.ItemBasics.Replenishment = !itemsObj.ItemBasics.Replenishment;
        itemsObj.Replenishment = !itemsObj.Replenishment;
        this.props.onItemDataset(itemsObj);
    }

    handlechangeForQty = (event) => {

        let movableUnitObj = Object.assign({}, this.props.sharedItem.UnitsOfMeasure.MovableUnit);
        movableUnitObj[event.target.name] = parseInt(event.target.value);
        movableUnitObj.Qty = movableUnitObj.High * movableUnitObj.Tie;

        let itemsObj = Object.assign(
            {},
            this.props.sharedItem,
            {
                UnitsOfMeasure: Object.assign(
                    {},
                    this.props.sharedItem.UnitsOfMeasure,
                    { 'MovableUnit': movableUnitObj }
                )
            }
        );
        this.props.onItemDataset(itemsObj);
    }

    handleMovableUnitSelectChange = (movableUnitIndex) => {

        let movableUnitObj = this.props.sharedItem.UnitsOfMeasure.MovableUnit;
        let movableUnitOptionObj = this.props.sharedItemOptions.MovableUnitOptions[movableUnitIndex];
        if (typeof movableUnitOptionObj != 'undefined') {
            movableUnitObj = Object.assign({}, movableUnitOptionObj.Value);
            movableUnitObj.Qty = this.props.sharedItem.UnitsOfMeasure.MovableUnit.Qty;
            movableUnitObj['MovableUnitUomIndex'] = movableUnitIndex;
        } else {
            movableUnitObj['MovableUnitUomIndex'] = '';
        }
        let itemsObj = Object.assign(
            {},
            this.props.sharedItem,
            {
                UnitsOfMeasure: Object.assign(
                    {},
                    this.props.sharedItem.UnitsOfMeasure,
                    {
                        'MovableUnit': movableUnitObj
                    }
                )
            }
        );
        this.props.onItemDataset(itemsObj);
    }

    handleChangeForTotalAreaVolume = (param_parent_property, event) => {
        let parentObj = Object.assign({}, this.props.sharedItem.UnitsOfMeasure[param_parent_property]);
        parentObj[event.target.name] = event.target.value;

        let itemsObj = Object.assign(
            {},
            this.props.sharedItem,
            {
                UnitsOfMeasure: Object.assign(
                    {},
                    this.props.sharedItem.UnitsOfMeasure,
                    { [param_parent_property]: parentObj }
                )
            }
        );
        this.updateTotalAreaVolume(itemsObj);
    }

    updateTotalAreaVolume = (param_itemsObj, param_initObj) => {
        let length;
        let width;
        let height = 0;
        let imperialMetricText = param_itemsObj.UnitsOfMeasure.IsImperial == true ? 'Imperial' : 'Metric';

        if (param_itemsObj.UnitsOfMeasure != null &&
            param_itemsObj.UnitsOfMeasure.PackagingUnit != null) {

            length = parseFloat(param_itemsObj.UnitsOfMeasure.PackagingUnit['Length' + imperialMetricText] || 0);
            width = parseFloat(param_itemsObj.UnitsOfMeasure.PackagingUnit['Width' + imperialMetricText] || 0);
            height = parseFloat(param_itemsObj.UnitsOfMeasure.PackagingUnit['Height' + imperialMetricText] || 0);

        }

        var divisor = (param_itemsObj.UnitsOfMeasure.IsImperial == true || param_itemsObj.UnitsOfMeasure.IsImperial == "true") ? 12 : 100;

        let itemsObj = Object.assign({}, param_itemsObj);
        itemsObj['totalArea'] = ((width / divisor || 0) * (length / divisor || 0)).toFixed(4);

        let cf = (length * width * height) / (divisor * divisor * divisor);
        itemsObj['totalVolume'] = cf.toFixed(4);
        itemsObj['totalActualVolume'] = cf;

        let isFirstTimeObjSet = false;
        if (param_initObj) {
            isFirstTimeObjSet = true;
        }
        this.props.onItemDataset(itemsObj, isFirstTimeObjSet);
        this.props.calculateCuftForRecStorage(itemsObj);
    }

    render() {
        const renderItems = this.props.sharedItem;
        const imperialMetricText = renderItems.UnitsOfMeasure.IsImperial == true ? 'Imperial' : 'Metric';
        var isAllowDuplicatedUPC = false;
        if (
            this.props.sharedCurrentCustomerObj &&
            this.props.sharedCurrentCustomerObj.options &&
            this.props.sharedCurrentCustomerObj.options.allowDuplicateUPCs
        ) {
            isAllowDuplicatedUPC = true;
        }
        return (
            <div className={"collapseItemsTabContent_wrapper"} id={'collapseItemsUofM'}>

                <WmsCollapse
                    id={'ItemUofM'}
                    headerLabel={this.translate("Label_Units_Of_Measure")}
                    showCollapseText={false}
                    showCollapseIcon={false} >

                    <WmsRadio
                        id="IsImperial"
                        name="IsImperial"
                        label={this.translate('Label_US_Imperial')}
                        wmsSelector={this.state.Selector + 'IsImperial'}
                        onChangeFunc={(e) => this.handleChange('UnitsOfMeasure', e)}
                        value={renderItems.UnitsOfMeasure.IsImperial}
                        checked={renderItems.UnitsOfMeasure.IsImperial == true}
                    />

                    <WmsRadio
                        id="metric"
                        name="IsImperial"
                        label={this.translate('Label_Metric')}
                        wmsSelector={this.state.Selector + ''}
                        onChangeFunc={(e) => this.handleChange('UnitsOfMeasure', e)}
                        value={renderItems.UnitsOfMeasure.IsImperial}
                        checked={renderItems.UnitsOfMeasure.IsImperial != true}
                    />

                </WmsCollapse>

                <WmsCollapse
                    id={'ItemInventoryUofM'}
                    headerLabel={this.translate("Label_Inventory_Units_Of_Measure")}
                    showCollapseText={false}
                    showCollapseIcon={false} >

                    {/* Set sorting options  orderBy:customSort  */}
                    <div className={(renderItems.UnitsOfMeasure.InventoryUnitOfMeasure.UseSecondaryUnit == true ? "" : "disabled-secondary-uom") + " wms_primaryunitType_wrap"}>
                        <div className="uom-wrapper">
                            <WmsDropdown
                                id="PrimaryUnitType"
                                name="PrimaryUnitType"
                                label={this.translate('Label_Primary_UOM')}
                                wmsSelector={this.state.Selector + "TypeOfUnitOptions"}
                                onChangeFunc={(e) => this.handleChange('InventoryUnitOfMeasure', e)}
                                value={renderItems.UnitsOfMeasure.InventoryUnitOfMeasure.PrimaryUnitType == null ? 0 : renderItems.UnitsOfMeasure.InventoryUnitOfMeasure.PrimaryUnitType}
                                options={this.props.sharedItemOptions.TypeOfUnitOptions}
                                valueField='Name'
                                textField='Name'
                                parentClassName='form_group'
                                blankFirstOption={true}
                                blankFirstOptionText="Unspecified"
                                extraWrapperClass="unit-type-option-wrapper" />

                            <WmsInput
                                id='Upc'
                                inputName='Upc'
                                wmsSelector={this.state.Selector + 'PrimaryUnitUpc'}
                                onChangeFunc={(e) => this.handleItemBasicChange('Description', e)}
                                label={this.translate('Label_Primary_UPC')}
                                value={renderItems.ItemBasics.Description.Upc}
                                extraWrapperClass="upc-input-wrapper" />

                        </div>
                        {this.props.hideMaxAllocatePickLine == false && (
                            <div className='pick-line-wrapper'>
                                <WmsInput
                                    id='MaxAllocateFromPickline'
                                    inputName='MaxAllocateFromPickline'
                                    wmsSelector={this.state.Selector + 'MaxAllocateFromPickline'}
                                    onChangeFunc={this.handleChangeOfPickline}
                                    label={this.translate('Label_Max_To_Allocate_From_Pick_Line')}
                                    value={renderItems.MaxAllocateFromPickline}
                                    valueType={'number'}
                                    extraWrapperClass="max-allocate-input-wrapper" />
                                <WmsInput
                                    id='PicklineMin'
                                    inputName='PicklineMin'
                                    wmsSelector={this.state.Selector + 'PicklineMin'}
                                    onChangeFunc={this.handleChangeOfPickline}
                                    label={this.translate('Label_Pick_Line_Minimum')}
                                    value={renderItems.PicklineMin}
                                    valueType={'number'}
                                    extraWrapperClass="max-allocate-input-wrapper" />
                                <WmsInput
                                    id='PicklineMax'
                                    inputName='PicklineMax'
                                    wmsSelector={this.state.Selector + 'PicklineMax'}
                                    onChangeFunc={this.handleChangeOfPickline}
                                    label={this.translate('Label_Pick_Line_Maximum')}
                                    value={renderItems.PicklineMax}
                                    valueType={'number'}
                                    disabled={!renderItems.PicklineMin}
                                    required={renderItems.PicklineMin != null}
                                    extraWrapperClass={!renderItems.PicklineMin? ' disabled-pickline-max max-allocate-input-wrapper' : ' max-allocate-input-wrapper'} />
                                <div className={(renderItems.ItemBasics.Replenishment == true ? "" : " disabled-replenishment ") + " wms_checkbox_wrap"}>
                                    <WmsCheckBox
                                        id="UseReplenishment"
                                        name="UseReplenishment"
                                        label={this.translate('Label_Enable_Replenishment')}
                                        wmsSelector={this.state.Selector + "UseReplenishment"}
                                        onChangeFunc={(e) => this.handleReplenishmentToggle('Replenishment', e)}
                                        value={renderItems.Replenishment}
                                        checked={renderItems.Replenishment == true}
                                        isRounded={true} />
                                </div>
                            </div>
                        )}
                         <div className="uom-wrapper">
                            <div className={(renderItems.UnitsOfMeasure.InventoryUnitOfMeasure.UseSecondaryUnit == true ? "" : " wms_secondary_uom") + " wms_checkbox_wrap"}>
                                <WmsCheckBox
                                    id="UseSecondaryUnit"
                                    name="UseSecondaryUnit"
                                    label={this.translate('Label_Use_Secondary')}
                                    wmsSelector={this.state.Selector + "UseSecondaryUnit"}
                                    onChangeFunc={(e) => this.handleChange('InventoryUnitOfMeasure', e)}
                                    value={renderItems.UnitsOfMeasure.InventoryUnitOfMeasure.UseSecondaryUnit}
                                    checked={renderItems.UnitsOfMeasure.InventoryUnitOfMeasure.UseSecondaryUnit == true}
                                    isRounded={true} />
                            </div>
                        </div>
                    </div>


                    {renderItems.UnitsOfMeasure.InventoryUnitOfMeasure.UseSecondaryUnit == true && (
                        <div className="wms_measure_wrap">
                            <WmsDropdown
                                id="SecondaryUnitType"
                                name="SecondaryUnitType"
                                label={this.translate('Label_Secondary_Unit_Type')}
                                wmsSelector={this.state.Selector + "SelectedSecondaryUnitType"}
                                onChangeFunc={(e) => this.handleChange('InventoryUnitOfMeasure', e)}
                                value={renderItems.UnitsOfMeasure.InventoryUnitOfMeasure.SecondaryUnitType == null ?
                                    0 : renderItems.UnitsOfMeasure.InventoryUnitOfMeasure.SecondaryUnitType}
                                options={this.props.sharedItemOptions.TypeOfUnitOptions}
                                required={!renderItems.UnitsOfMeasure.InventoryUnitOfMeasure.SecondaryUnitType}
                                valueField='Name'
                                textField='Name'
                                parentClassName='form_group'
                                blankFirstOption={true}
                                blankFirstOptionText="Unspecified"
                                extraWrapperClass="unit-type-option-wrapper" />

                            <WmsInput
                                id="PrimaryUnitsPerSecondaryUnit"
                                inputName="PrimaryUnitsPerSecondaryUnit"
                                label={this.translate('Label_Primary_Units_Per_Secondary_UOM')}
                                wmsSelector={this.state.Selector + "PrimaryUnitsPerSecondaryUnit"}
                                onChangeFunc={(e) => this.handleChange('InventoryUnitOfMeasure', e)}
                                value={renderItems.UnitsOfMeasure.InventoryUnitOfMeasure.PrimaryUnitsPerSecondaryUnit}
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                extraWrapperClass={`primary-units-per-secondary-unit ${this.props.validUnitOfMeuser()? 'invalid-input' : ''}`}
                                required={true} />

                            <WmsInput
                                id='SecondaryUnitUpc'
                                inputName='SecondaryUnitUpc'
                                wmsSelector={this.state.Selector + 'SecondaryUnitUpc'}
                                onChangeFunc={isAllowDuplicatedUPC ? () => { } : (e) => this.handleItemBasicChange('Description', e)}
                                label={this.translate('Label_Secondary_UPC')}
                                value={renderItems.ItemBasics.Description.SecondaryUnitUpc}
                                extraWrapperClass={(isAllowDuplicatedUPC ? 'disabled-upc ' : '') + "upc-input-wrapper secondary-upc-input-wrapper"} />

                        </div>
                    )}
                    {/* )} */}



                </WmsCollapse>

                <WmsCollapse
                    id={'ItemUofMPackagingUnit'}
                    headerLabel={this.translate("Label_Packaging_Unit")}
                    showCollapseText={false}
                    showCollapseIcon={false} >

                    {/* set customer sor in options orderBy:customSort */}
                    <div>
                        <WmsDropdown
                            id="PackagingUom"
                            name="PackagingUom"
                            label={this.translate('Label_Packaging_Unit_Measure')}
                            wmsSelector={this.state.Selector + "PackagingUom"}
                            onChangeFunc={(e) => this.handleChange('PackagingUnit', e)}
                            value={renderItems.UnitsOfMeasure.PackagingUnit.PackagingUom == null ? 0 : renderItems.UnitsOfMeasure.PackagingUnit.PackagingUom}
                            options={this.props.sharedItemOptions.TypeOfUnitOptions}
                            valueField='Name'
                            textField='Name'
                            extraWrapperClass="wms-dropdown-packaging-unit-uom-box"
                            parentClassName='form_group'
                            blankFirstOption={true}
                            blankFirstOptionText="Unspecified" />

                        <WmsInput
                            id="InventoryUnitsPerUnit"
                            inputName="InventoryUnitsPerUnit"
                            label={this.translate('Label_Primary_Units_Per_Packaging')}
                            wmsSelector={this.state.Selector + "InventoryUnitsPerUnit"}
                            onChangeFunc={(e) => this.handleChange('PackagingUnit', e)}
                            value={renderItems.UnitsOfMeasure.PackagingUnit.InventoryUnitsPerUnit}
                            extraWrapperClass={`wms-input-primary-box ${this.props.validInventoryUnitsPerUnit()? 'invalid-input' : ''}`}
                            regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                        <WmsInput
                            id='Upc'
                            inputName='Upc'
                            wmsSelector={this.state.Selector + 'PackageUnitUpc'}
                            onChangeFunc={isAllowDuplicatedUPC ? () => { } : (e) => this.handleChange('PackagingUnit', e)}
                            label={this.translate('Label_Packaging_UPC')}
                            value={renderItems.UnitsOfMeasure.PackagingUnit.Upc}
                            extraWrapperClass={(isAllowDuplicatedUPC ? 'disabled-upc ' : '') + "upc-input-wrapper"} />
                    </div>
                    <WmsInput
                        id="PackagingLength"
                        inputName={'Length' + imperialMetricText}
                        label={this.translate('Label_Length') + ' (' + (
                            (imperialMetricText == 'Imperial') ?
                                this.translate('Label_Inch_Abbrev_Wihtout_dot') :
                                this.translate('Label_Centimeter_Abbrev_Wihtout_dot')
                        ) + ')'}
                        wmsSelector={this.state.Selector + "PackagingLength"}
                        onChangeFunc={(e) => this.handleChangeForTotalAreaVolume('PackagingUnit', e)}
                        value={renderItems.UnitsOfMeasure.PackagingUnit['Length' + imperialMetricText]}
                        extraWrapperClass="wms-input-height-width-length-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                    <WmsInput
                        id="PackagingWidth"
                        inputName={'Width' + imperialMetricText}
                        label={this.translate('Label_Width') + ' (' + (
                            (imperialMetricText == 'Imperial') ?
                                this.translate('Label_Inch_Abbrev_Wihtout_dot') :
                                this.translate('Label_Centimeter_Abbrev_Wihtout_dot')
                        ) + ')'}
                        wmsSelector={this.state.Selector + "PackagingWidth"}
                        onChangeFunc={(e) => this.handleChangeForTotalAreaVolume('PackagingUnit', e)}
                        value={renderItems.UnitsOfMeasure.PackagingUnit['Width' + imperialMetricText]}
                        extraWrapperClass="wms-input-height-width-length-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                    <WmsInput
                        id="packagingHeight"
                        inputName={'Height' + imperialMetricText}
                        label={this.translate('Label_Height') + ' (' + (
                            (imperialMetricText == 'Imperial') ?
                                this.translate('Label_Inch_Abbrev_Wihtout_dot') :
                                this.translate('Label_Centimeter_Abbrev_Wihtout_dot')
                        ) + ')'}
                        wmsSelector={this.state.Selector + "PackagingHeight"}
                        onChangeFunc={(e) => this.handleChangeForTotalAreaVolume('PackagingUnit', e)}
                        value={renderItems.UnitsOfMeasure.PackagingUnit['Height' + imperialMetricText]}
                        extraWrapperClass="wms-input-height-width-length-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                    <WmsInput
                        id="totalArea"
                        inputName="totalArea"
                        label={this.translate('Label_Total_Area') + ' (' + (
                            (imperialMetricText == 'Imperial') ?
                                this.translate('Label_Square_Feet_Abbrev') :
                                this.translate('Label_Square_Meter_Abbrev')
                        ) + ')'}
                        wmsSelector={this.state.Selector + "totalArea"}
                        onChangeFunc={(e) => function () { }}
                        extraWrapperClass="wms-input-total-area-box"
                        value={renderItems.totalArea} />

                    <WmsInput
                        id="totalVolume"
                        inputName="totalVolume"
                        label={this.translate('Label_Total_Volume') + ' (' + (
                            (imperialMetricText == 'Imperial') ?
                                this.translate('Label_Cubic_Feet_Abbrev') :
                                this.translate('Label_Cubic_Meter_Abbrev')
                        ) + ')'}
                        wmsSelector={this.state.Selector + "totalVolume"}
                        onChangeFunc={(e) => function () { }}
                        extraWrapperClass="wms-input-total-volume-box"
                        value={renderItems.totalVolume} />

                    <WmsInput
                        id="packagingWeight"
                        inputName={'Weight' + imperialMetricText}
                        label={this.translate('Label_Weight') + ' (' + (
                            (imperialMetricText == 'Imperial') ?
                                this.translate('Label_Pound_Abbrev') :
                                this.translate('Label_Kilogram_Abbrev')
                        ) + ')'}
                        wmsSelector={this.state.Selector + "PackagingWeight"}
                        onChangeFunc={(e) => this.handleChange('PackagingUnit', e)}
                        value={renderItems.UnitsOfMeasure.PackagingUnit['Weight' + imperialMetricText]}
                        extraWrapperClass="wms-input-total-and-weight-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                    <WmsInput
                        id="PackagingNetWeight"
                        inputName={'NetWeight' + imperialMetricText}
                        label={this.translate('Label_Net_Weight') + ' (' + (
                            (imperialMetricText == 'Imperial') ?
                                this.translate('Label_Pound_Abbrev') :
                                this.translate('Label_Kilogram_Abbrev')
                        ) + ')'}
                        wmsSelector={this.state.Selector + "PackagingNetWeight"}
                        onChangeFunc={(e) => this.handleChange('PackagingUnit', e)}
                        value={renderItems.UnitsOfMeasure.PackagingUnit['NetWeight' + imperialMetricText]}
                        extraWrapperClass="wms-input-weight-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                </WmsCollapse>

                <WmsCollapse
                    id={'ItemUofMMovableUnit'}
                    headerLabel={this.state.customLabels.Label_Movable_Unit}
                    showCollapseText={false}
                    showCollapseIcon={false} >

                    <WmsDropdown
                        id="MovableUnitUom"
                        name="MovableUnitUom"
                        label={this.state.customLabels.Label_Movable_Unit}
                        wmsSelector={this.state.Selector + "SelectedMovableUnitUom"}
                        onChangeFunc={(e) => this.handleMovableUnitSelectChange(e.target.value)}
                        value={renderItems.UnitsOfMeasure.MovableUnit.MovableUnitUomIndex}
                        options={this.props.sharedItemOptions.MovableUnitOptions}
                        textField='Name'
                        applyIndex={true}
                        parentClassName='form_group'
                        extraWrapperClass="wms-dropdown-packaging-unit-box"
                        blankFirstOption={true}
                        blankFirstOptionText="Unspecified" />

                    <WmsInput
                        id="MovableLength"
                        inputName={'Length' + imperialMetricText}
                        label={this.translate('Label_Length') + ' (' + ((imperialMetricText == 'Imperial') ? this.translate('Label_Inch_Abbrev_Wihtout_dot') : this.translate('Label_Centimeter_Abbrev_Wihtout_dot')) + ')'}
                        wmsSelector={this.state.Selector + "MovableLength"}
                        onChangeFunc={(e) => this.handleChange('MovableUnit', e)}
                        value={renderItems.UnitsOfMeasure.MovableUnit['Length' + imperialMetricText]}
                        extraWrapperClass="wms-input-height-width-length-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                    <WmsInput
                        id="MovableWidth"
                        inputName={'Width' + imperialMetricText}
                        label={this.translate('Label_Width') + ' (' + ((imperialMetricText == 'Imperial') ? this.translate('Label_Inch_Abbrev_Wihtout_dot') : this.translate('Label_Centimeter_Abbrev_Wihtout_dot')) + ')'}
                        wmsSelector={this.state.Selector + "MovableWidth"}
                        onChangeFunc={(e) => this.handleChange('MovableUnit', e)}
                        value={renderItems.UnitsOfMeasure.MovableUnit['Width' + imperialMetricText]}
                        extraWrapperClass="wms-input-height-width-length-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                    <WmsInput
                        id="MovableHeight"
                        inputName={'Height' + imperialMetricText}
                        label={this.translate('Label_Height') + ' (' + ((imperialMetricText == 'Imperial') ? this.translate('Label_Inch_Abbrev_Wihtout_dot') : this.translate('Label_Centimeter_Abbrev_Wihtout_dot')) + ')'}
                        wmsSelector={this.state.Selector + "MovableHeight"}
                        onChangeFunc={(e) => this.handleChange('MovableUnit', e)}
                        value={renderItems.UnitsOfMeasure.MovableUnit['Height' + imperialMetricText]}
                        extraWrapperClass="wms-input-height-width-length-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                    <WmsInput
                        id="Tie"
                        inputName="Tie"
                        label={this.translate('Label_TI')}
                        wmsSelector={this.state.Selector + "MovableUnitTie"}
                        onChangeFunc={this.handlechangeForQty}
                        value={renderItems.UnitsOfMeasure.MovableUnit.Tie}
                        extraWrapperClass="wms-input-total-and-weight-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                    <WmsInput
                        id="High"
                        inputName="High"
                        label={this.translate('Label_High')}
                        wmsSelector={this.state.Selector + "MovableUnitHigh"}
                        onChangeFunc={this.handlechangeForQty}
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                        extraWrapperClass="wms-input-total-and-weight-box"
                        value={renderItems.UnitsOfMeasure.MovableUnit.High} />

                    <WmsInput
                        id="movableWeight"
                        inputName={'Weight' + imperialMetricText}
                        label={this.translate('Label_Weight') + ' (' + ((imperialMetricText == 'Imperial') ? this.translate('Label_Pound_Abbrev') : this.translate('Label_Kilogram_Abbrev')) + ')'}
                        wmsSelector={this.state.Selector + "MovableWeight"}
                        onChangeFunc={(e) => this.handleChange('MovableUnit', e)}
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                        extraWrapperClass="wms-input-total-and-weight-box"
                        value={renderItems.UnitsOfMeasure.MovableUnit['Weight' + imperialMetricText]}
                    />

                    <WmsInput
                        id="Qty"
                        inputName="Qty"
                        label={this.translate('Label_Quantity')}
                        wmsSelector={this.state.Selector + "MovableUnitQty"}
                        onChangeFunc={(e) => this.handleChange('MovableUnit', e)}
                        value={renderItems.UnitsOfMeasure.MovableUnit.Qty}
                        extraWrapperClass="wms-input-total-and-weight-box"
                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />
                </WmsCollapse>
            </div>
        )
    }
}