import React, { Component } from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";

export class SekoComponent extends Component {

    constructor(props) {
        super(props);
        this.translate = window.translate;
    }


    render() {
        return (
            <WmsCollapse
                id={"SekoInformation"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <WmsInput
                        id="nickname"
                        inputName="Nickname"
                        wmsSelector={this.props.selector + "nickname"}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        label={this.translate('Label_Account_Nick_Name')}
                        value={this.props.currentCarrierObj.Nickname}
                        extraWrapperClass="seko-nikcname"
                        required={true}
                    />
                    <WmsInput
                        id="accessKey"
                        inputType={"accessKey"}
                        inputName="AccessKey"
                        wmsSelector={this.props.selector + "accessKey"}
                        onChangeFunc={this.props.handleCarrierObjChange}
                        label={this.translate("Label_access_key")}
                        value={this.props.currentCarrierObj.AccessKey}
                        extraWrapperClass="seko-accesskey"
                        required={true}
                    />
                </div>
            </WmsCollapse>
        );
    }
}
