let align_center = "text-align:center;";
export const getMobileLabelTemplatesColumns = (GridHeaders) => {
    return {
        columns: [
            {
                field: "RowNumber",
                title: "#",
                width: 60,
                wmsid: 1,
                hidden: false,
                locked: true,
                lockable: false,
                resizable: false,
                sortable: false,
                reorderable: false,
                filterable: false,
                attributes: { style: align_center },
                headerAttributes: { style: align_center },
                template: checkForDefaultRow,
            },
            {
                field: "LabelName",
                title: GridHeaders[0].description,
                wmsid: 2,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "MobileLabelLabelName",
                },
            },
            {
                field: "Customer",
                title: GridHeaders[1].description,
                wmsid: 3,
                locked: false,
                lockable: false,
                headerAttributes: {
                    "data-wms-selector": "MobileLabelTemplateCustomer",
                },
            },
        ],
    };
};

export const getMobileLabelTemplatesAllColumnList = (GridHeaders) => {
    return [
        {
            field: "RowNumber",
            title: "#",
            width: 60,
            wmsid: 1,
            hidden: false,
            locked: true,
            lockable: false,
            resizable: false,
            sortable: false,
            reorderable: false,
            filterable: false,
            attributes: { style: align_center },
            headerAttributes: { style: align_center },
            template: checkForDefaultRow,
        },
        {
            field: "LabelName",
            title: GridHeaders[0].description,
            wmsid: 2,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "MobileLabelLabelName",
            },
        },
        {
            field: "Customer",
            title: GridHeaders[1].description,
            wmsid: 3,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "MobileLabelTemplateCustomer",
            },
        },
    ];
};

export const getMobileLabelTemplatesSchema = () => {
    return {
        model: {
            id: "RowNumber",
            fields: {
                LabelName: { type: "string" },
                customerName: { type: "string" },
            },
        },
    };
};

let checkForDefaultRow = (dataItem) => {
    var returnString = dataItem.RowNumber;
    if (dataItem.IsDefault) {
        returnString = dataItem.RowNumber + "<i class='fa fa-lock default-template-lock' aria-hidden='true' ></i>";
    }
    return returnString;
};
