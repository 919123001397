import React, { Component } from 'react';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import { KendoWms } from '../../../../assets/ts/KendoWms.ts';
import { ArrayWms } from '../../../../assets/ts/ArrayWms.ts';
import VirtualGridComponent from '../../components_proto/VirtualGridComponent/VirtualGridComponent.jsx';
import { AttachDocuments } from '../../components/AttachDocuments/AttachDocuments.jsx';
import { LoadingModel } from '../../components/LoadingModel/LoadingModel.jsx';
import { ConcurrencyManager } from '../../../../assets/vendor/concurrencyManager/concurrencyManager.js';
import { AddEditColumnModel } from '../../components/AddEditColumn/add-edit-columns.component.jsx';

import * as GridConfig from './grid_configuration.jsx';
import * as ReceiptGlobalService from './../findReceipt_global.services.jsx';
import * as DynamicMenu from './../dynamic_menu.jsx';

//services
import * as GlobalService from './../../global_services';
import * as FindReceiptService from './findReceipt.services.jsx';

import { CancelModel } from '../../components/CancelModel/cancel-model.component.jsx';
import { ConfirmReceiptModel } from '../findReceipt/model/confirm-receipt-modal.component.jsx';
import { ReceiptAdviceModel } from '../findReceipt/model/receipt-advice-modal.component.jsx';
import { ManageReceiptHolder } from "../../receipt/manageReceipt/manageReceipt-holder.component.jsx";
import * as metaDataService from './meta-data.service';
import { _, kendo } from "./../../global_variable_service.jsx";

var axios = require('axios');

export class FindReceiptGrid extends React.PureComponent {
    constructor(props) {
        super(props);


        this.$rootScope = window;
        this.translate = window.translate;
        this.firstFiftyAxiosSource = null;
        this.axiosSource = null;
        this.filterCountAxiosSource = null;
        let FindReceivers_GridHeaders = StringWms.convertSringToOption(this.translate('FindReceivers_GridHeaders'));
        this.findReceiptGridId = "GridFindReceiversProto";
        this.InitialPrintMenuResponse = [];
        this.LocalKendoGrid = null;
        this.isApiCanceled = false;
        this.state = {
            GridName: "GridFindReceiversProto",
            Rights: GlobalService.GetRights(),
            CustomLabels: GlobalService.getCustomLabels(),
            defaultColumns: GridConfig.getGridDemoColumns(FindReceivers_GridHeaders, props.selector).columns,
            schema: GridConfig.getGridDemoSchema(),
            allColumnList: GridConfig.getAllColumnList(FindReceivers_GridHeaders, props.selector),
            MenuPrintData: [],
            SelectedReceipts: 0,
            isUpdateGridSummary: false,
            SelectedReceiptIds: [],
            SelectedReceiptItems: [],
            totalReceipts: 0,
            totalInLineColumnFilters: 0,
            gridExportLimit: GlobalService.constants.gridExportLimit,
            IsClosedOrCanceled: false,
            isCanceled: false,
            CanReopen: false,
            isOpenCancelReceiptModel: false,
            isOpenConfirmReceiptModel: false,
            isOpenReceiptAdviceModel: false,
            sharedAttachObj: null,
            isOpenAttachDoc: false,
            NoLineItems: false,
            SameCustomer: true,
            SameFacility: true,
            gifLoaderText: '',
            staticGridData: [],
            gridPageSize: GlobalService.constants.virtualReceiptGridDataLimit,
            gridDomSelector: () => props.windowId ? '.' + props.windowId + ' #' + this.findReceiptGridId : '#' + this.findReceiptGridId,
            isNewColumnAdded: false,
            filterText: "",
            fullFilterText: "",
            isOpenColumnModel: false,
            allSortColumnList: [],
            findReceiptGridConfig: metaDataService.getFindReceiptGridOptionInMetaData(),
            isFilterRowCountLoading: false,
            isReceiptGridDataLoading: false,
            recordsCount: 0,
            confirmReceiptDisablrMsg:'',
            showLoadingError: false,
            facilityId: ""
        };
    }

    componentDidMount = () => {

        document.addEventListener("EventReceiptData", this.resetGridSelectionAndButton);

        let that = this;

        // sort all column list title
        let allSortColumnList = this.state.allColumnList.sort(function (first, second) {

            var columnTitle1 = first.title.toUpperCase(); // ignore upper and lowercase
            var columnTitle2 = second.title.toUpperCase(); // ignore upper and lowercase

            if (columnTitle1 < columnTitle2) { return -1; }

            if (columnTitle1 > columnTitle2) { return 1; }
            // names must be equal
            return 0;
        });

        var customerId = GlobalService.GetCurrentUser().CustomerId;

        ReceiptGlobalService.getReports(customerId).then((response) => {
            if (typeof response != undefined) {
                this.InitialPrintMenuResponse = response;
                var updatedMenus = this.updateInitialPrintMenu(this.state.SelectedReceipts,this.state.IsClosedOrCanceled,this.state.isCanceled);
                this.setState({
                    MenuPrintData: updatedMenus.PrintData,
                    allSortColumnList: allSortColumnList
                });
            }
        }).finally(() => { });
        if(this.getSharedReceiptFilter()){
            this.callForGetTotalCount();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("EventReceiptData", this.resetGridSelectionAndButton);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.sharedReceiptFilter && prevProps.sharedReceiptFilter !== this.props.sharedReceiptFilter){
            this.clearSelectionOfGrid();
            this.callForGetTotalCount(true,true);
        }
        if(prevProps.gridInlineFilter !== this.props.gridInlineFilter){
            this.setFilterAppliedText(this.props.gridInlineFilter);
        }
      }
    /**Shared method for holder and grid start */

    callForGetTotalCount = (clearData = true , clearGridSelection = false) => {
        if(clearGridSelection) {
            this.setState({
                SelectedReceipts: 0,
                isAllDataLoad: false,
                isFilterRowCountLoading: true,
                recordsCount: 0,
                showLoadingError: false
            });
        } else {
            this.setState({
                isFilterRowCountLoading: true,
                recordsCount: 0,
                showLoadingError: false
            });
        }

        var param_filterObj = Object.assign({}, this.getSharedReceiptFilter());
        param_filterObj.StartDate = StringWms.convertFilterStringToISODate(param_filterObj.StartDate, '', false, true).isoStringUTC;
        param_filterObj.EndDate = StringWms.convertFilterStringToISODate(param_filterObj.EndDate, '23:59:59', false, true).isoStringUTC;

        if (this.filterCountAxiosSource) {
            this.filterCountAxiosSource.cancel();
            this.filterCountAxiosSource = null;
        }
        // on filter update cancel axiosSource call if any running
        this.cancelLoadAllData();
        this.filterCountAxiosSource = axios.CancelToken.source();

        axios.put('/WebUI/Receiving/ReceivingsUi/FilteredRowCount', param_filterObj, { cancelToken: this.filterCountAxiosSource.token }).then((response) => {
            var newStateData = {
                recordsCount: response.data
            };
            if (clearData == true) {
                newStateData.staticGridData = [];
            }
            this.filterCountAxiosSource = null;

            this.setState(
                newStateData
                , () => {
                    if (response.data > 0) {
                        this.callForGetNewData();
                    }
                });

        }).finally(() => {
            this.setState({ isFilterRowCountLoading: false });
        });
    }
    callForGetNewData = () => {
        if (this.state.recordsCount > 0 && this.state.recordsCount <= GlobalService.constants.virtualReceiptGridDataLimit) {
            this.loadAllRecords(false);
        } else {
            // reset selection of grid on filter data change
            this.resetGridAllMessage();
        }

    }

    getEnableColumns = () => {
        var defaultParam = ['FacilityId', 'Skus', 'CustomerId', 'CustomerUri', 'ReceiverId', 'StatusEnum', 'CanReopen', 'EditUri', 'FileSummariesUri'];
        let columnList = [];

        let grid = $(this.state.gridDomSelector()).data("kendoGrid");
        // if grid already defined than we get columns from grid
        if (typeof grid != "undefined") {
            columnList = grid.columns;
        } else { // else get column list from metadataObject or default columns
            let FindReceivers_GridHeaders = StringWms.convertSringToOption(this.translate('FindReceivers_GridHeaders'));
            columnList = GridConfig.getGridDemoColumns(FindReceivers_GridHeaders, GlobalService.getCustomLabels()).columns;
            var findReceiptGridConfig = metaDataService.getFindReceiptGridOptionInMetaData();
            if (findReceiptGridConfig != "") {
                var gridOptions = JSON.parse(findReceiptGridConfig);
                if (gridOptions.columns) {
                    columnList = gridOptions.columns;
                }
            }
        }

        var enableColumns = columnList.map(el => el.field);
        // merge defaultParam and enableColumns without dublicate value
        return [...new Set([...defaultParam, ...enableColumns])];
    }
    /**Shared method for holder and grid end */

    setFilterAppliedText = (filterObj) => {
        if (!filterObj) {
            this.setState({
                inlineGridFilter: false,
                inlineGridFilterCount: 0,
                filterText: "",
                fullFilterText: ""
            });
            return;
        }
        var filterConditionTitle = {
            "lte": "Is Less Than Or Equal To",
            "eq": "Is Equal To",
            "lt": "Is Less Than",
            "neq": "Is Not Equal To",
            "gte": "Is Greater Than Or Equal To",
            "gt": "Is Greater Than",
            "startswith": "Starts With",
            "endswith": "Ends With",
            "contains": "Contains",
            "doesnotcontain": "Does Not Contain",
            "isnull": "Is Null",
            "isnotnull": "Is Not Null",
            "isempty": "Is Empty",
            "isnotempty": "Is Not Empty",
            "isnullorempty": "Has No Value",
            "isnotnullorempty": "Has Value",
        };

        var filterText = "";
        var fullFilterText = "";
        var filterList = filterObj.filters;
        var self = this;
        var statusList = [this.translate('Receiving_Status_Open'),this.translate('Receiving_Status_Closed'),this.translate('Receiving_Status_Cancel'),this.state.CustomLabels.Label_Complete];
        filterList.map(function (item) {
            var columnObj = self.state.allColumnList.find(x => x.field === item.field);
            if (columnObj) {
                var title = columnObj.title;
                if (title == "Status") {
                    filterText += title + " : " + statusList[item.value] + ',';
                    fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + statusList[item.value] + "<br>";
                } else {
                    filterText += title + " : " + item.value + ',';
                    fullFilterText += title + " " + filterConditionTitle[item.operator] + " : " + item.value + "<br>";
                }
            }
        });
        filterText = filterText.slice(0, -1);
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var dataSource = grid.dataSource;
        var recordsOnCurrentView = dataSource.view().length;

        this.setState({
            filterText: filterText,
            fullFilterText: fullFilterText,
            inlineGridFilter: true,
            inlineGridFilterCount: recordsOnCurrentView
        });
    }

    updateInitialPrintMenu = (SelectedReceipts,IsClosedOrCanceled,isCanceled) => {
        var params = {
            Selector: this.props.selector,
            SelectedReceipts: SelectedReceipts,
            isClosedOrCanceled: IsClosedOrCanceled,
            isCanceled: isCanceled,
            Rights: this.state.Rights,
            fnNames: ['RunViewTransactionReport', 'RunMULabelReport', 'RunReceiptTicketReport']
        };
        return DynamicMenu.getInitialDynamicPrintMenu(this.InitialPrintMenuResponse, params, this.dynamicMenuClick);
    }

    UpdateGridSummary = () => {
        if (this.LocalKendoGrid != null) {
            var KendoGrid = this.LocalKendoGrid;
            var selectedSet = KendoGrid.getDictionary().items;
            var ClosedOrCanceled = false;
            var isCanceled = false;
            var tempItems = [];
            var NoLineItems = KendoGrid.valueExists("Skus", "");
            var SameFacility = KendoGrid.valuesIdentical("FacilityId");
            var SameCustomer = KendoGrid.valuesIdentical("CustomerId");
            var confirmReceiptDisablrMsg = '';
            for (let [key, receipt] of Object.entries(selectedSet)) {
                tempItems.push(receipt);
                if (receipt.StatusEnum == 1 || receipt.StatusEnum == 2) {
                    ClosedOrCanceled = true;
                    confirmReceiptDisablrMsg = this.translate("Label_Disable_Confirm_Receipt_For_Close_Cancel");
                    if (ClosedOrCanceled && isCanceled) {
                        break;
                    }
                } else {
                    ClosedOrCanceled = false;
                }
                if (receipt.StatusEnum == 2) {
                    isCanceled = true;
                    confirmReceiptDisablrMsg = this.translate("Label_Disable_Confirm_Receipt_For_Cancel");
                }
            }
            var receiptNotConfirmObj = _.find(selectedSet, item => item.CanConfirm === false);
            if (receiptNotConfirmObj) {
                NoLineItems = true;
            }
            if(NoLineItems){
                confirmReceiptDisablrMsg = this.translate("Label_Disable_Confirm_Receipt_For_No_Line_Items") ;
            }
            if((ClosedOrCanceled == false && isCanceled == false && !this.state.Rights.hasOwnProperty('isWarehouseUser'))){
                confirmReceiptDisablrMsg = this.translate("Label_Disable_Confirm_Receipt_For_Custoner_User");
            }
            var updatedMenus = this.updateInitialPrintMenu(KendoGrid.getNumberOfSelections(),ClosedOrCanceled,isCanceled);
            var facilityId;
            if(KendoGrid.getNumberOfSelections() > 0 && SameFacility) {
                let grid = $('#' + this.state.GridName).data('kendoGrid');
                facilityId = grid.dataItem(grid.select()).FacilityId;
            }
            this.setState({
                SelectedReceipts: KendoGrid.getNumberOfSelections(),
                isUpdateGridSummary: true,
                SelectedReceiptIds: KendoGrid.getKeys(),
                SelectedReceiptItems: tempItems,
                IsClosedOrCanceled: ClosedOrCanceled,
                isCanceled: isCanceled,
                CanReopen: KendoGrid.valueExists("CanReopen", true),
                NoLineItems: NoLineItems,
                SameFacility: SameFacility,
                SameCustomer: SameCustomer,
                confirmReceiptDisablrMsg:confirmReceiptDisablrMsg,
                MenuPrintData: updatedMenus.PrintData,
                facilityId: facilityId
            });
        }
    }

    selectRow = (KendoGrid) => {
        this.KendoGrid = KendoGrid;
        this.UpdateGridSummary();
    }

    eventClickCreate = () => {
        this.$rootScope.OpenWindow({
            url: '<react-manage-receipt-holder></react-manage-receipt-holder>',
            reactComponent: ManageReceiptHolder,
            singleton: false,
            title: this.translate('Label_Create_Receipts'),
            active: true,
            useLineItem: true,
            lineItem: [{
                name: '',
                value: 'Create Receipts'
            }],
            icon: 'submenu-icon fa fa-plus',
            close: {
                'Title': this.translate('Label_Warning'),
                'Button1Title': this.translate('Label_Save_And_Exit'),
                'Button1Icon': 'save',
                'Button1Show': true,
                'Button2Title': this.translate('Label_Discard_And_Exit'),
                'Button2Icon': 'newCancel',
                'Button2Show': true,
                'Button3Title': this.translate('Label_Return'),
                'Button3Icon': 'return',
                'Button3Show': true,
                'Paragraph': 'Changes have been made, click \'Back\' to save your changes.'
            },
            flow: 'create',
            urlParam: 'managereceipt'
        });
    }
    refreshReceiptGrid = () => {
        this.clearSelectionOfGrid();
        this.callForGetTotalCount();
    }
    editReceipt = (event) => {
        let tempkey = this.LocalKendoGrid.getKeys();
        let dataItem = this.LocalKendoGrid.getItem(tempkey); // technically the key is the receiptId as well so this step is redundant but just in case we run into a wierd scenario

        this.$rootScope.OpenWindow({
            url: '<react-manage-receipt-holder></react-manage-receipt-holder>',
            reactComponent: ManageReceiptHolder,
            singleton: false,
            id: (typeof dataItem != undefined) ? dataItem.ReceiverId : 0,
            title: this.translate('Label_Edit_Receipts'),
            active: true,
            useLineItem: true,
            lineItem: [{
                name: 'Ref: ',
                value: (typeof dataItem != undefined) ? dataItem.ReferenceNum : '',
            }, {
                name: 'Trans: ',
                value: (typeof dataItem != undefined) ? dataItem.ReceiverId : 0,
            }],
            icon: 'submenu-icon fa fa-edit',
            close: {
                'Title': this.translate('Label_Warning'),
                'Button1Title': this.translate('Label_Save_And_Exit'),
                'Button1Icon': 'save',
                'Button1Show': true,
                'Button2Title': this.translate('Label_Discard_And_Exit'),
                'Button2Icon': 'newCancel',
                'Button2Show': true,
                'Button3Title': this.translate('Label_Return'),
                'Button3Icon': 'return',
                'Button3Show': true,
                'Paragraph': 'Changes have been made, click \'Back\' to save your changes.'
            },
            receipt: dataItem,
            flow: 'edit',
            urlParam: 'managereceipt'
        });
    }

    attachFiles = (e) => {
        var selectedItem = this.state.SelectedReceiptItems[0];
        var sharedAttachObj = {
            'isReceipt': true,
            'uri': selectedItem.EditUri,
            'fileSummariesUri': selectedItem.FileSummariesUri,
            'getAttachedDocsInfoUrl': '/WebUI/Receiving/FindReceivings/GetAttachedDocsInfo',
            'getAttachedDocsInfoPayload': {
                'receiverUri': selectedItem.EditUri
            },
            'attachdocumentUrl': '/WebUI/Receiving/FindReceivings/AttachDocsToReceiver?receiverFileSummariesUri=' + selectedItem.FileSummariesUri,
            'removeAttachDocUrl': '/WebUI/Receiving/FindReceivings/DeleteAttachedDoc',
            'customer': selectedItem.Customer,
            'transactionId': selectedItem.ReceiverId
        };

        this.setState({
            sharedAttachObj: sharedAttachObj,
            isOpenAttachDoc: true
        });
    }

    dynamicMenuClick = (event) => {
        var wmsid = $(event.target).parent().attr('data-wmsid');

        if (typeof this[wmsid] !== "undefined") {
            this[wmsid](event);
        } else {
            console.error("Uncaught TypeError: " + wmsid + " is not a function");
        }
    }

    resetGridSelectionAndButton = () => {
        let grid = $('#' + this.state.GridName).data('kendoGrid');
        if (grid) {
            grid.dataSource.read();
        }
        if (this.LocalKendoGrid != null) {
            this.LocalKendoGrid.clearSelections(() => {
                this.LocalKendoGrid.repaintSelections();
            });
        }
        this.setState({
            SelectedReceipts: 0,
            isUpdateGridSummary: true
        });
        // call for get new update data
        this.callForGetTotalCount(false);
    }

    clickEventOfContextMenu = (obj, e) => {

        if (obj.wmsid == 'editReceipt') {
            this.editReceipt();
        } else if (obj.wmsid == 'adviceReceipt') {
            this.openReceiptAdviceModel(true);
        } else if (obj.wmsid == 'cancelReceipt') {
            this.cancelReceipt();
        } else if (obj.wmsid == 'confirmReceipt') {
            this.confirmReceipt();
        } else if (obj.wmsid == 'attachFiles') {
            this.attachFiles();
        } else if (obj.wmsid == 'reopenReceipt') {
            this.reOpenReceipt();
        } else if (obj.wmsid && typeof this[obj.wmsid] !== "undefined") {
            this[obj.wmsid](e);
        }
    }

    dynamicServiceForDynamicMenu = (event, fnName) => {
        var runReport = $(event.target).parent().attr('data-run-report');
        var rdlName = $(event.target).parent().attr('data-rdl-name');
        var parentLabel = $("[data-wmsid='" + rdlName + "'] span").first().text();
        this.setState({ gifLoaderText: parentLabel });
        FindReceiptService.dynamicServiceForDynamicMenu(fnName, runReport, this.state.SelectedReceiptIds, null, 'FindReceivings').then((response) => {
            this.handleResponseMenuPrint(response);
        }).finally(() => {
            this.setState({ gifLoaderText: '' });
        });
    }

    handleResponseMenuPrint = (response) => {
        var blob = ArrayWms.convertBase64ToByteArray(response.Data, 'application/pdf');
        ArrayWms.displayBlobInWindow(blob, 'report.pdf');
    }

    onGridDataBound = (e, kendoGrid) => {

        let grid = $('#' + this.state.GridName).data('kendoGrid');
        var TotalInLineColumnFilters = KendoWms.getLengthOffFilters(grid.dataSource.filter());
        this.LocalKendoGrid = kendoGrid;
        this.setState({
            totalReceipts: grid.dataSource.total(),
            totalInLineColumnFilters: TotalInLineColumnFilters
        });
        this.$rootScope.setLoaderTimerEndTime("Find Receipts proto");
    }

    // on grid column sort save changes in meta data object
    onSort = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        GlobalService.sortDataset(sortArray, event.sort);
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.LocalKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());
        metaDataService.setFindReceiptGridOptionInMetaData(finalSubmission);
    }

    // on apply saved filter data save changes in meta data object
    onApplySavedFilterData = (filterObj) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        var gridColumn = grid.getOptions().columns;
        var finalSubmission = this.LocalKendoGrid.prepareGridOptionsForStorage(gridColumn, sortArray, grid.dataSource.filter());

        metaDataService.applySavedFilterDataToFindReceiptGrid(finalSubmission, filterObj);
    }

    // on new filter data clear selection of grid
    clearSelectionOfGrid = () => {
        if (this.LocalKendoGrid != null) {
            this.LocalKendoGrid.clearSelections(() => {
                this.LocalKendoGrid.repaintSelections();
            });
        }
    }

    resetGridAllMessage = () => {
        if (this.state.isAllDataLoad) {
            this.setState({
                isNewColumnAdded: true
            });
        }
    }

    // on grid column reorder save changes in meta data object
    onColumnReorder = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let gridColumn = grid.getOptions().columns;
        let switchedArray = ArrayWms.switchIndexByField(gridColumn, event.newIndex, event.oldIndex);
        let finalSubmission = this.LocalKendoGrid.prepareGridOptionsForStorage(switchedArray, sortArray, grid.dataSource.filter());
        metaDataService.setFindReceiptGridOptionInMetaData(finalSubmission);

    }

    // on grid column resize save changes in meta data object
    onColumnResize = (event) => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.LocalKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sortArray,
            grid.dataSource.filter()
        );
        metaDataService.setFindReceiptGridOptionInMetaData(finalSubmission);
    }

    // on click grid clear sort menu
    onClickClearSorts = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var finalSubmission = this.LocalKendoGrid.prepareGridOptionsForStorage(grid.columns, [], grid.dataSource.filter());
        metaDataService.setFindReceiptGridOptionInMetaData(finalSubmission);
    }

    // on click grid reset default grid layout menu
    onClickResetGrid = () => {
        var finalSubmission = {
            "optionsData": {}
        };
        metaDataService.setFindReceiptGridOptionInMetaData(finalSubmission);
    }

    // on grid filter selection
    onGridFilterApplied = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var dataSource = grid.dataSource;
        setTimeout(() => {
            this.setFilterAppliedText(dataSource.filter());
            var sortArray = grid.dataSource.sort() ? grid.dataSource.sort() : [];
            var finalSubmission = this.LocalKendoGrid.prepareGridOptionsForStorage(grid.columns, sortArray, dataSource.filter());

            metaDataService.setFindReceiptGridOptionInMetaData(finalSubmission);

        }, 100);

    }

    eventClickExportGrid = () => {
        $(this.state.gridDomSelector()).getKendoGrid().saveAsExcel();
    }

    eventClickColumns = () => {
        this.setState({
            isOpenColumnModel: true
        });
    }

    onCloseColumnModel = (e) => {
        this.setState({
            isOpenColumnModel: false
        });
    }

    // save column hide/show data in meta data
    saveChangesInMetaData = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var sort = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        let finalSubmission = this.LocalKendoGrid.prepareGridOptionsForStorage(
            grid.columns,
            sort,
            grid.dataSource.filter()
        );
        metaDataService.setFindReceiptGridOptionInMetaData(finalSubmission);
    }

    // Load all records of order
    loadAllRecords = (isLoaderPopupShow) => {
        this.isApiCanceled = false;
        if (isLoaderPopupShow == false) {
            this.setState({
                isReceiptGridDataLoading: true,
                staticGridData: []
            });
        }
        const sequence = n => {
            let seq = [];
            for (let i = 0; i < n; i++) {
                seq.push(i);
            }
            return seq;
        };
        this.axiosSource = axios.CancelToken.source();
        let api = axios.create({
            baseURL: "/WebUI/Receiving/ReceivingsUi",
            headers: { 'Access-Control-Allow-Origin': '*' },
            cancelToken: this.axiosSource.token
        });

        const MAX_CONCURRENT_REQUESTS = 3;

        let enableColumns = this.getEnableColumns();
        let receivingsSearchInfo = _.cloneDeep(this.getSharedReceiptFilter());
        receivingsSearchInfo.StartDate = StringWms.convertFilterStringToISODate(receivingsSearchInfo.StartDate, '', false, true).isoStringUTC;
        receivingsSearchInfo.EndDate = StringWms.convertFilterStringToISODate(receivingsSearchInfo.EndDate, '23:59:59', false, true).isoStringUTC;

        let totalRequest = Math.ceil(this.state.recordsCount / this.state.gridPageSize);
        this.setState({
            totalRequest: totalRequest,
            pendingRequest: totalRequest,
            completeRequest: 0,
        });
        // init your manager.
        var manager = ConcurrencyManager(api, MAX_CONCURRENT_REQUESTS,GlobalService);
        Promise.all(sequence(totalRequest).map(i => api.put("/ReceivingsData?sorts=[]&filters=[]&pgnum=" + (i + 1) + '&pgsiz=' + this.state.gridPageSize, { "columns": enableColumns, "receivingsSearchInfo": receivingsSearchInfo })))
            .then(responses => {
                let receiptData = responses.map(r => r.data);
                this.getAllResponseData(receiptData);
            }).catch((error) => {
                console.log("Promise.all catch",  error);
                this.setState({
                    showLoadingError: true
                });
                this.cancelLoadAllData();
            }).then(() => {
                if(manager) {
                    manager.detach();
                }
            });
    }

    onClickLoadAllRecords = () => {
        let enableColumns = this.getEnableColumns();
        let self = this;
        let receivingsSearchInfo = this.getSharedReceiptFilter();
        receivingsSearchInfo.StartDate = StringWms.convertFilterStringToISODate(receivingsSearchInfo.StartDate, '', false, true).isoStringUTC;
        receivingsSearchInfo.EndDate = StringWms.convertFilterStringToISODate(receivingsSearchInfo.EndDate, '23:59:59', false, true).isoStringUTC;
        this.setState({
            overlayScreenShow: true
        });

        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
            this.firstFiftyAxiosSource = null;
        }
        this.firstFiftyAxiosSource = axios.CancelToken.source();

        axios.put('/WebUI/Receiving/ReceivingsUi/ReceivingsData?sorts=[]&filters=[]&pgnum=1&pgsiz=50', { "columns": enableColumns, "receivingsSearchInfo": receivingsSearchInfo }, { cancelToken: this.firstFiftyAxiosSource.token })
            .then((response) => {

                this.firstFiftyAxiosSource = null;
                this.setState({
                    staticGridData: response.data.Data
                },this.loadAllRecords);
            });
    }

    getAllResponseData = (data) => {
        if (!this.isApiCanceled) {
            let allData = [];
            data.forEach(obj => {
                allData = [...obj.Data, ...allData];
            });
            this.setState({
                staticGridData: allData,
                overlayScreenShow: false,
                isAllDataLoad: true,
                isNewColumnAdded: false,
                isReceiptGridDataLoading:false
            });
        } else {
            this.setState({
                isReceiptGridDataLoading: false
            });
        }
    }
    // Cancel axios call if new filter applied
    cancelLoadAllData = () => {
        if (this.firstFiftyAxiosSource) {
            this.firstFiftyAxiosSource.cancel();
            this.firstFiftyAxiosSource = null;
        }
        if(this.axiosSource){
            this.axiosSource.cancel();
            this.axiosSource = null;
        }
        this.isApiCanceled = true;
        this.setState({
            overlayScreenShow: false,
            staticGridData: [],
            isAllDataLoad: false,
            isReceiptGridDataLoading:false
        });
    }

    RunViewTransactionReport = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunViewTransactionReport');
    }
    RunMULabelReport = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunMULabelReport');
    }
    RunReceiptTicketReport = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunReceiptTicketReport');
    }

    cancelReceipt = (event) => {
        this.setState({
            isOpenCancelReceiptModel: true
        });
    }

    closeCancelReceipt = () => {
        this.setState({
            isOpenCancelReceiptModel: false
        });
    }

    confirmReceipt = () => {
        this.setState({
            isOpenConfirmReceiptModel: true
        });
    }

    closeConfirmReceipt = () => {
        this.setState({
            isOpenConfirmReceiptModel: false
        });
    }

    closeAttachDocuments = () => {
        this.setState({ isOpenAttachDoc: false });
    }

    openReceiptAdviceModel = (flag) => {
        this.setState({
            isOpenReceiptAdviceModel: flag,
        });
    }

    reOpenReceipt = (event) => {
        // Pending
        var selectedItem = this.state.SelectedReceiptItems[0];
        var editUri = selectedItem.EditUri;

        kendo.ui.progress($('#' + this.state.GridName), true);
        FindReceiptService.reopenReceiver(editUri).then((response) => {
            if (response && response.status == 204) {
                GlobalService.notification('success', this.translate('Label_Receipt_Updated'));
                this.resetGridSelectionAndButton();
                var KendoGrid = this.LocalKendoGrid;

                KendoGrid.clearSelections(() => {
                    KendoGrid.repaintSelections();
                    this.setState({
                        SelectedReceipts: KendoGrid.getNumberOfSelections()
                    });
                });
            }
        }).finally(() => {
            kendo.ui.progress($('#' + this.state.GridName), false);
        });
    }

    // ---- If customer is deactivated and still that customer is set in filter, we are removing it before grid loading ----
    getSharedReceiptFilter = () => {
        let receiptFilterData = Object.assign({}, this.props.sharedReceiptFilter);
        if (receiptFilterData.CustomerId != 0 ) {
            let selectedCustomer = GlobalService.getCustomerById(receiptFilterData.CustomerId);
            if (selectedCustomer == null) {
                receiptFilterData.CustomerId = 0;
            }
        }

        return receiptFilterData
    }

    render() {
        return (
            <div className={"find-receipt-grid new-layout-grid-wrapper"}>

                {this.state.MenuPrintData.length > 0 && (
                    <VirtualGridComponent
                        loading={this.state.isFilterRowCountLoading || this.state.isReceiptGridDataLoading}
                        isNewColumnAdded={this.state.isNewColumnAdded}
                        gifLoaderText={this.state.gifLoaderText}
                        staticGridData={this.state.staticGridData}
                        wmsSelector={this.props.selector + "Grid"}
                        showSortColumns={true}
                        showGridSummary={true}
                        callForGetNewData={this.callForGetNewData}
                        gridKeySelection={'ReceiverId'}
                        showGridResult={true}
                        totalResult={this.state.recordsCount}
                        gridName={this.state.GridName}
                        isBindFilterColumnHoverEvent={true}
                        filterText={this.state.filterText}
                        fullFilterText={this.state.fullFilterText}
                        virtual={true}
                        metaDataFieldsName={'ReceiptProto'}
                        exportExcelObj={{
                            fileName: "receiptGridExport-" + kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss") + ".xlsx",
                            allPages: true,
                            filterable: true
                        }}
                        exportable={true}
                        id={this.findReceiptGridId}
                        defaultColumns={this.state.defaultColumns}
                        allColumns={this.state.allColumnList}
                        schema={this.state.schema}
                        onSelection={this.selectRow}
                        onDataBound={this.onGridDataBound}
                        onSort={this.onSort}
                        onColumnReorder={this.onColumnReorder}
                        onColumnResize={this.onColumnResize}
                        onClickClearSorts={this.onClickClearSorts}
                        onClickResetGrid={this.onClickResetGrid}
                        windowId={this.props.windowId}
                        clickEventOfContextMenu={this.clickEventOfContextMenu}
                        eventClickExportGrid={this.eventClickExportGrid}
                        eventClickColumns={this.eventClickColumns}
                        onGridFilterApplied={this.onGridFilterApplied}
                        metaGridConfig={this.state.findReceiptGridConfig}
                        loadAllRecords={this.onClickLoadAllRecords}
                        isAllDataLoad={this.state.isAllDataLoad}
                        virtualGridDataLimit={this.state.gridPageSize}
                        showLoadingError={this.state.showLoadingError}
                        menu={
                            [{
                                "name": this.translate('Label_Manage'),
                                "wmsSelector": this.props.selector + "Manage",
                                "value": [
                                    {
                                        "name": this.translate('Label_Edit_Receipt'),
                                        "value": [],
                                        "disabled": (this.state.SelectedReceipts != 1 || this.state.IsClosedOrCanceled),
                                        "disabledText": (this.state.SelectedReceipts != 1) ? this.translate('Label_Disable_Edit_Receipt_On_Multiple_Select') : this.translate('Label_Disable_Edit_Closed_Or_Cancel_Receipt_Note'),
                                        'isContextMenu': this.state.Rights.hasOwnProperty('receipts.edit.view'),
                                        'isButtonMenu': this.state.Rights.hasOwnProperty('receipts.edit.view'),
                                        'wmsid': 'editReceipt',
                                        "wmsSelector": this.props.selector + "EditButton",
                                        "onClickFunc": this.editReceipt
                                    }, {
                                        "name": this.translate('Label_Confirm_Receipt_Model').replace(
                                            new RegExp('{' + 'receiptLabelOnSelection' + '}', 'gi'), (this.state.SelectedReceiptIds.length > 1 ? 'Receipts' : 'Receipt')
                                        ),
                                        "value": [],
                                        "disabled": (!this.state.SelectedReceipts || this.state.IsClosedOrCanceled || this.state.NoLineItems || !this.state.SameCustomer || !this.state.SameFacility ||
                                            (this.state.IsClosedOrCanceled == false && this.state.isCanceled == false && !this.state.Rights.hasOwnProperty('isWarehouseUser'))),
                                        'isContextMenu': this.state.Rights.hasOwnProperty('receipts.confirm'),
                                        'isButtonMenu': this.state.Rights.hasOwnProperty('receipts.confirm'),
                                        "disabledText":this.state.confirmReceiptDisablrMsg,
                                        //'isContextMenu': true,
                                        //'isButtonMenu': true,
                                        'wmsid': 'confirmReceipt',
                                        "wmsSelector": this.props.selector + "ConfirmButton",
                                        "onClickFunc": this.confirmReceipt
                                    }, {
                                        "name": this.translate('Label_Cancel_Receipt'),
                                        "value": [],
                                        "disabled": (!this.state.SelectedReceipts || this.state.IsClosedOrCanceled),
                                        "disabledText": !this.state.SelectedReceipts  ? this.translate('Label_Disable_Edit_Receipt_On_Multiple_Select') : this.translate('Label_Disable_Cancel_Closed_Or_Cancel_Receipt_Note'),
                                        'isContextMenu': this.state.Rights.hasOwnProperty('receipts.manage.cancel'),
                                        'isButtonMenu': this.state.Rights.hasOwnProperty('receipts.manage.cancel'),
                                        'wmsid': 'cancelReceipt',
                                        "wmsSelector": this.props.selector + "CancelButton",
                                        "onClickFunc": this.cancelReceipt
                                    }, {
                                        "name": this.translate('Label_Reopen_Receipt'),
                                        "value": [],
                                        "disabled": (this.state.SelectedReceipts != 1 || !this.state.CanReopen),
                                        "disabledText":!this.state.SelectedReceipts  ? this.translate('Label_Disable_Edit_Receipt_On_Multiple_Select') : this.translate('Label_Disable_Reopen_Receipt'),
                                        'isContextMenu': this.state.Rights.hasOwnProperty('receipts.manage.reopen'),
                                        'isButtonMenu': this.state.Rights.hasOwnProperty('receipts.manage.reopen'),
                                        'wmsid': 'reopenReceipt',
                                        "wmsSelector": this.props.selector + "ReopenButton",
                                        "onClickFunc": this.reOpenReceipt
                                    }, {
                                        "name": this.translate('Label_Receipt_Advice'),
                                        "value": [],
                                        "disabled": (this.state.SelectedReceipts != 1),
                                        "disabledText":this.translate('Label_Disable_Edit_Receipt_On_Multiple_Select'),
                                        'isContextMenu': this.state.Rights.hasOwnProperty('receipts.manage.sendra'),
                                        'isButtonMenu': this.state.Rights.hasOwnProperty('receipts.manage.sendra'),
                                        'wmsid': 'adviceReceipt',
                                        "wmsSelector": this.props.selector + "ReceiptAdviceButton",
                                        "onClickFunc": (e) => this.openReceiptAdviceModel(true)
                                    }
                                ],
                                "disabled": false,
                                "icon": "fa-wrench",
                                "color": "green",
                                'isContextMenu': true,
                                'isButtonMenu': true,
                                "clickFunc": () => null
                            },
                            {
                                "name": this.translate('Label_Create'),
                                "wmsSelector": this.props.selector + "CreateButton",
                                "value": [],
                                "disabled": false,
                                "icon": "fa-plus",
                                "color": "green",
                                'isContextMenu': false,
                                'isButtonMenu': this.state.Rights.hasOwnProperty('receipts.edit.view'),
                                "clickFunc": this.eventClickCreate
                            },
                            {
                                "name": this.translate('NavBarV1_Btn_Documents'),
                                "wmsSelector": this.props.selector + this.translate('NavBarV1_Btn_Documents'),
                                "value": this.state.MenuPrintData,
                                "clickFunc": () => null,
                                "disabled": (this.state.SelectedReceipts == 0),
                                "icon": "fa-print",
                                "color": "green",
                                'isContextMenu': this.state.Rights.hasOwnProperty('receipts.docs') || (this.state.Rights.hasOwnProperty('receipts.attachdocs') && this.state.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled')),
                                'isButtonMenu': this.state.Rights.hasOwnProperty('receipts.docs') || (this.state.Rights.hasOwnProperty('receipts.attachdocs') && this.state.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled')),
                            },
                            {
                                "name": '',
                                "wmsSelector": this.props.selector + 'RefreshGrid',
                                "value": [],
                                "clickFunc": this.refreshReceiptGrid,
                                "disabled": false,
                                "icon": "fa-sync-alt",
                                "color": "blue",
                                'isContextMenu': false,
                                'isButtonMenu': true,
                                'parentClassName':'receiptRefreshBtn'
                            },
                            {
                                "name": "Copy",
                                "value": [{
                                    "name": this.translate('Label_Copy_Field'),
                                    "value": [],
                                    "disabled": (this.state.SelectedReceipts != 1),
                                    "disabledText": this.translate('Label_Copy_Field'),
                                    "icon": "fa-bandcamp",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyField',
                                    "wmsSelector": "copyToClicpboard",
                                }, {
                                    "name": this.translate('Label_Copy_Row'),
                                    "value": [],
                                    "disabled": (this.state.SelectedReceipts != 1),
                                    "disabledText": this.translate('Label_Copy_Row'),
                                    "icon": "fa-wrench",
                                    "color": "green",
                                    'isContextMenu': true,
                                    'isButtonMenu': false,
                                    'wmsid': 'copyRow'
                                }],
                                "disabled": false,
                                "disabledText": "You may not add blahssss0",
                                "icon": "fa-wrench",
                                "color": "green",
                                'isContextMenu': true,
                                'isButtonMenu': false
                            }
                            ]}
                    />
                )}
                {this.state.isOpenColumnModel && (
                    <AddEditColumnModel
                        id={"addEditColumnFindReceipt"}
                        height={400}
                        windowId={this.props.windowId}
                        onCloseColumnModel={this.onCloseColumnModel}
                        isOpenColumnModel={this.state.isOpenColumnModel}
                        allSortColumnList={this.state.allSortColumnList}
                        gridId={this.findReceiptGridId}
                        updateGridData={this.callForGetNewData}
                        saveChangesInMetaData={this.saveChangesInMetaData}
                    />
                )}
                {this.state.overlayScreenShow && (
                    <LoadingModel
                        sharedOpenLoadingmodel={this.state.overlayScreenShow}
                        onCancelLoadAllData={this.cancelLoadAllData}
                        sharedTotalRequest={this.state.totalRequest}
                    />
                )}
                {this.state.isOpenCancelReceiptModel &&
                    <CancelModel
                        modalType={'Receipt'}
                        sharedIsOpenCancelModel={this.state.isOpenCancelReceiptModel}
                        onCloseCancelModel={this.closeCancelReceipt}
                        sharedSelectedIds={this.state.SelectedReceiptIds}
                        resetGridSelection={this.resetGridSelectionAndButton}
                    />
                }
                {this.state.isOpenConfirmReceiptModel &&
                    <ConfirmReceiptModel
                        Selector={this.props.selector}
                        windowId={this.props.windowId}
                        sharedIsOpenConfirmReceiptModel={this.state.isOpenConfirmReceiptModel}
                        onCloseConfirmReceipt={this.closeConfirmReceipt}
                        receiptIds={this.state.SelectedReceiptIds}
                        resetGridSelection={this.resetGridSelectionAndButton}
                        facilityId={this.state.facilityId}
                    />
                }

                {this.state.isOpenReceiptAdviceModel &&
                    <ReceiptAdviceModel
                        Selector={this.props.selector}
                        windowId={this.props.windowId}
                        sharedIsOpenReceiptAdviceModel={this.state.isOpenReceiptAdviceModel}
                        selectedReceipts={this.state.SelectedReceipts}
                        selectedReceiptItems={this.state.SelectedReceiptItems}
                        closeReceiptAdviceModel={this.openReceiptAdviceModel}
                        resetGridSelection={this.resetGridSelectionAndButton}
                    />
                }

                {this.state.isOpenAttachDoc &&
                    <AttachDocuments
                        isOpenAttachDoc={this.state.isOpenAttachDoc}
                        sharedAttachObj={this.state.sharedAttachObj}
                        windowId={this.props.windowId}
                        onClose={this.closeAttachDocuments}
                    />
                }
            </div>
        );
    }
}
