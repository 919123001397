import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsDateTimePicker.scss';

class WmsDateTimePicker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.windowId = this.props.windowId ? "." + this.props.windowId : "";
        this.jquerySelector = this.windowId + " #" + this.props.id;
        this.kendoDateTimePicker = "";
        this.viewtype = "";
        this.datePickerOptions = "";
    }

    componentDidMount() {
        var that = this;
        this.datePickerOptions = {
            open: function (event) {
                that.viewtype = event.view;
            },
            change: function (event) {
                var value = this.value();
                if (value != null && value.getHours() == 0 && that.viewtype !== "time") {
                    let formatArr = that.props.defaultTime.split(':');
                    value.setHours(formatArr[0]);
                    value.setMinutes(formatArr[1]);
                    value.setSeconds(formatArr[2]);
                    this.value(value);
                }
                that.onChangeFunc();
            },
            close: function (event) {
                that.viewtype = "";
            },
            format: that.props.format,
            parseFormats: [that.props.format]
        }

        if (this.props.maxDateTime !== "") {
            this.datePickerOptions.max = this.props.maxDateTime;
        }
        if (this.props.minDateTime !== "") {
            this.datePickerOptions.min = this.props.minDateTime;
        }

        $(this.jquerySelector).kendoDateTimePicker(this.datePickerOptions);
        this.kendoDateTimePicker = $(this.jquerySelector).data("kendoDateTimePicker")

        $(this.jquerySelector).bind("focus", (e) => {
            setTimeout(() => {
                if (that.viewtype != "time") {
                    this.kendoDateTimePicker.open();
                }
            }, 100);
        });

        var addClasses = (((that.props.required) ? 'wms_required_datepicker' : ''));
        $(this.jquerySelector).parents(".k-datetimepicker").addClass(addClasses);
    }

    componentDidUpdate(prevProps) {
        if (this.props.maxDateTime != prevProps.maxDateTime && this.props.maxDateTime !== "") {
            this.kendoDateTimePicker.setOptions({
                max: this.props.maxDateTime
            });
        }
        if (this.props.minDateTime != prevProps.minDateTime && this.props.minDateTime !== "") {
            this.kendoDateTimePicker.setOptions({
                min: this.props.minDateTime
            });
        }
        if (this.props.required != prevProps.required) {
            var addClasses = this.props.required ? 'wms_required_datepicker' : '';
            var removeClasses = !this.props.required ? 'wms_required_datepicker' : '';

            $(this.jquerySelector).parents(".k-datetimepicker").addClass(addClasses).removeClass(removeClasses);
        }
        this.kendoDateTimePicker.enable(!this.props.disabled);
    }

    onChangeFunc = () => {
        var datePicker = $(this.jquerySelector).data("kendoDateTimePicker");
        var tmpDateVal = datePicker.value();
        if (this.props.isDateParseInCustomFormat) {
            tmpDateVal = kendo.toString(kendo.parseDate(tmpDateVal), 'yyyy-MM-ddTHH:mm:ss');
        }
        let event = {
            target: {
                name: this.props.name,
                value: tmpDateVal
            }
        }
        if (datePicker.element) {
            datePicker.element[0].blur();
        }
        this.props.onChangeFunc(event);
    }

    clearDateTimePicker = () => {
        let event = {
            target: {
                name: this.props.name,
                value: ''
            }
        }
        if (this.props.value) {
            this.props.onChangeFunc(event);
        }
        var datePicker = $(this.jquerySelector).data("kendoDateTimePicker");
        datePicker.value("");
    }
    render() {
        let renderValue = (typeof this.props.value == 'undefined' ||
            this.props.value === '' ||
            this.props.value === null) ?
            '' :
            kendo.toString(kendo.parseDate(this.props.value), this.props.format);
        return (
            <div className={"wms-datetimepicker-component " + this.props.extraWrapperClass}>
                <label htmlFor={this.props.id} >{this.props.label}{this.props.required ? ' *' : ''} </label>
                <div
                    className={'border ' + (renderValue != '' ? 'datepicker_has_value ' + this.props.parentClassName : this.props.parentClassName) + ' ' + (this.props.onlyDate ? 'only_date' : '')}>

                    <input
                        id={this.props.id}
                        name={this.props.name}
                        value={(renderValue === null) ? this.props.value : renderValue}
                        data-wms-selector={this.props.wmsSelector}
                        onChange={() => null}
                        required={this.props.required}
                        disabled={this.props.disabled}
                    />

                    <div
                        className={"datePicker_clearer " + (this.props.required ? ' wms_required_datepicker' : '')}
                        onClick={this.clearDateTimePicker}
                    ><i className="fa fa-times-circle-o" aria-hidden="true"></i></div>
                </div>
            </div>
        )
    }
}


WmsDateTimePicker.propTypes = {
    id: PropTypes.string.isRequired, // this should write the id to the input
    name: PropTypes.string.isRequired, //this should set the name of datepicker input
    label: PropTypes.string.isRequired, // this should write the label of the menu
    windowId: PropTypes.string, // this should be used to get window id
    format: PropTypes.string, // this shiuld set date formate
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),// this should control the value of datepicker
    maxDateTime: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    minDateTime: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    defaultTime: PropTypes.string, // this is used for set default time of datetimepicker
    onChangeFunc: PropTypes.func.isRequired, // this sould manage datepicker change and clear select value
    parentClassName: PropTypes.string, //    this should parent tag class default parent class is form-group
    onlyDate: PropTypes.bool, // hide time icon for only show date
    extraWrapperClass: PropTypes.string, // extra wrapper class if needed
    disabled: PropTypes.bool, // hide time icon for only show date
    wmsSelector: PropTypes.string, // this should set up a selector on the date
    isDateParseInCustomFormat: PropTypes.bool,  // set true to parse date in custom format

}

WmsDateTimePicker.defaultProps = {
    format: "M/d/yyyy h:mm tt",
    defaultTime: '12:00:00',
    parentClassName: "form_group",
    extraWrapperClass: "",
    windowId: "",
    onlyDate: false,
    disabled: false,
    wmsSelector: '',
    isDateParseInCustomFormat: false,
    maxDateTime: "",
    minDateTime: "",
}

export default WmsDateTimePicker;