import { StringWms } from '../../../assets/ts/StringWms.ts';

export const getGridDemoColumns = () => {
    let translate = window.translate;
    let importOrdersHeaders = StringWms.convertSringToOption(translate('Orders_ImportOrders_Management_Headers'));
    return ({

        columns: [{
            field: 'ReferenceNum',
            title: importOrdersHeaders[0].description,
            width: 180,
            lockable: false,            
        },
        {
            field: 'TotalItems',
            title: importOrdersHeaders[1].description,
            width: 180,
            lockable: false,            
        },
        {
            field: 'Destination',
            title: importOrdersHeaders[2].description,
            width: 180,
            lockable: false,            
        },
        {
            field: 'PoNum',
            title: importOrdersHeaders[3].description,
            width: 180,
            lockable: false,            
        },
        {
            field: 'TotalLines',
            title: importOrdersHeaders[4].description,
            width: 180,
            lockable: false,            
        },
        {
            field: 'FullyAllocated',
            title: importOrdersHeaders[5].description,
            width: 180,
            lockable: false,
            sortable: true,
            template: "# if(FullyAllocated === true){#  "+ translate('Label_Yes') +" #} else{#  "+ translate('Label_No') +" #} #",
        }]
    })
}


export const getGridDemoSchema = () => {

    return ({
        schema: {
            model: {
                fields: {
                    ReferenceNum: {
                        type: "string"
                    },
                    TotalItems: {
                        type: "number"
                    },
                    Destination: {
                        type: "string"
                    },
                    PoNum: {
                        type: "string"
                    },
                    TotalLines: {
                        type: "number"
                    }
                }
            }
        },
    })
}
