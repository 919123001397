import React, { Component } from 'react'
import WmsCollapse from '../../../components/WmsCollapse/WmsCollapse.jsx'
import WmsDropdown from "../../../components/WmsDropdown/WmsDropdown.jsx";
import WmsInput from '../../../components/WmsInput/WmsInput.jsx'

export class ParcllAccountInfoComponent extends Component {
    constructor(props) {
        super(props)

        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {

        }

        this.hubLocationOptions = [
            {
                Name: "Vernon CA",
                Value: "LA",
            },
            {
                Name: "Franklin Park IL",
                Value: "ORD",
            },
            {
                Name: "Monroe NJ",
                Value: "NJ",
            },
            {
                Name: "Jamaica NJ",
                Value: "JFK",
            },
            {
                Name: "Austell GA",
                Value: "ATL",
            },
            {
                Name: "Hialeah FL",
                Value: "MIA",
            },
            {
                Name: "Coppell TX",
                Value: "DFW",
            },
        ];
    }

    render() {
        return (
            <WmsCollapse
                id={"parcllAccountInfoScreen"}
                headerLabel={this.translate("Label_Account_Information")}
                showCollapseText={false}
                showCollapseIcon={false}
            >
                <div className="account-info-section">
                    <div>
                        <WmsInput
                            id="accountNickname"
                            inputName="Nickname"
                            wmsSelector={this.props.selector + "nickname"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_Account_Nick_Name")}
                            value={this.props.currentCarrierObj.Nickname}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="accountNumber"
                            inputName="AccountNumber"
                            wmsSelector={this.props.selector + "accountNumber"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate('Label_Account_Number')}
                            value={this.props.currentCarrierObj.AccountNumber}
                            required={true}
                            extraWrapperClass="w-42"
                        />
                        <WmsInput
                            id="apiKey"
                            inputName="ApiKey"
                            wmsSelector={this.props.selector + "apiKey"}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            label={this.translate("Label_API_Key")}
                            value={this.props.currentCarrierObj.ApiKey}
                            extraWrapperClass="w-42"
                            required={true}
                        />
                        <WmsDropdown
                            id="hublocation"
                            name="HubLocation"
                            label={this.translate("Label_Hub_Location")}
                            wmsSelector={this.props.selector + "hublocation"}
                            value={this.props.currentCarrierObj.nonDeliveryDefault}
                            onChangeFunc={this.props.handleCarrierObjChange}
                            options={this.hubLocationOptions}
                            required={true}
                            textField="Name"
                            valueField="Value"
                            blankFirstOption={true}
                            blankFirstOptionText="Unspecified"
                            extraWrapperClass="w-42"
                        />
                    </div>
                </div>
            </WmsCollapse>
        )
    }
}
