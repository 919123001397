import { StringWms } from "../../../../../assets/ts/StringWms";
import type { GridColumnsConfig } from "../../../../../types/gridTypes";

export interface GridFilter {
    field: string;
    operator: string;
    value: string;
    inputValue?: string;
}

export const getGridDefaultColumns = ({ gridHeaders, customLabels }: GridColumnsConfig) => {
    const centrilizedStyling = { style: "text-align:center;" };
    return [
        {
            field: "RowIndex",
            title: "#",
            width: 60,
            wmsid: 1,
            hidden: false,
            locked: true,
            lockable: false,
            resizable: false,
            sortable: false,
            reorderable: false,
            filterable: false,
            attributes: centrilizedStyling,
            headerAttributes: centrilizedStyling,
        },
        {
            field: "ExternalBatchId",
            title: gridHeaders[1].description,
            width: 120,
            wmsid: 2,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelHVPGridBatchName",
            },
        },
        {
            field: "Customer",
            title: customLabels.Label_Customer,
            width: 120,
            wmsid: 3,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelHVPGridCustomer",
            },
        },
        {
            field: "Facility",
            title: customLabels.Label_Warehouse,
            width: 100,
            wmsid: 4,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelHVPGridWarehouse",
            },
        },
        {
            field: "BatchId",
            title: gridHeaders[4].description,
            width: 120,
            wmsid: 5,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelHVPGridBatchId",
            },
            template: function (dataItem) {
                const { BatchId, ExternalBatchId, Facility, Customer } = dataItem;
                return `<a href='javascript:void(0)' Batch-Name='${ExternalBatchId}' Facility-Name='${Facility}' Customer-Name='${Customer}' class='hvp-batch-id-link'>${BatchId}</a>`;
            },
        },
        {
            field: "ordersCount",
            title: gridHeaders[5].description,
            width: 100,
            wmsid: 6,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelHVPGridTotOrders",
                style: centrilizedStyling.style,
            },
            attributes: centrilizedStyling,
        },
        {
            field: "packagesCount",
            title: gridHeaders[6].description,
            width: "120px",
            wmsid: 7,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelHVPGridTotPackages",
                style: centrilizedStyling.style,
            },
            attributes: centrilizedStyling,
        },
        {
            field: "PassFail",
            title: gridHeaders[7].description,
            width: 120,
            wmsid: 8,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelHVPGridTotals",
                style: centrilizedStyling.style,
            },
            attributes: centrilizedStyling,
        },
        {
            field: "Status",
            title: gridHeaders[8].description,
            width: "155px",
            wmsid: 9,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelHVPGridStatus",
            },
            template:
                '#if(Status == "open") {# #= "Open" # #} else if(Status == "queued" || Status == "processing") {#  #= "Processing" # #} else if(Status == "completed") {#  #= "Complete" # #} else if(Status == "printed" && Errors > 0) {# <div class="error-icon-status-hvp" Batch-Name="#=BatchId#" batch-errors-url="#=BatchErrorsUrl#" batch-shipments-url="#=BatchShipmentsUrl#" > #= "Printed" #  <i class="facility-exclamator fa fa-exclamation-triangle " ></i> </div> #} else if(Status == "printed") {#  #= "Printed" # #} else if(Status == "completed_with_errors" && Errors > 0) {# <div class="error-icon-status-hvp" Batch-Name="#=BatchId#" batch-errors-url="#=BatchErrorsUrl#" batch-shipments-url="#=BatchShipmentsUrl#" > #= "Complete" #  <i class="facility-exclamator fa fa-exclamation-triangle " ></i> </div> #} else if(Status == "completed_with_errors") {#  #= "Complete" # #} else{# #=Status # #}#',
        },
        {
            field: "CreatedAt",
            title: gridHeaders[9].description,
            width: 120,
            wmsid: 10,
            locked: false,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": "SmallParcelHVPGridDateCreated",
            },
            template: function (itemData) {
                return new Date(itemData.CreatedAt).toLocaleDateString("en-US");
            },
        },
    ];
};

export const defaultFilters: GridFilter[] = [
    {
        field: "BatchId",
        operator: "contains",
        value: "",
    },
    {
        field: "ExternalBatchId",
        operator: "contains",
        value: "",
    },
    {
        field: "Customer",
        operator: "contains",
        value: "",
    },
    {
        field: "Facility",
        operator: "contains",
        value: "",
    },
    {
        field: "Status",
        operator: "contains",
        value: "",
    },
    {
        field: "CreatedAt",
        operator: "gte",
        value: `${StringWms.convertFilterStringToISODate("today~00:00:00", "", false, true).isoStringUTC}`,
    },
    {
        field: "CreatedAt",
        operator: "lte",
        value: `${StringWms.convertFilterStringToISODate("today~23:59:59", "", false, true).isoStringUTC}`,
    },
];

export const STATUS_OPTIONS = [
    { name: "Any", value: "" },
    { name: "Open", value: "open" },
    { name: "Complete", value: "completed" },
    { name: "Processing", value: "processing" },
    { name: "Printed", value: "printed" },
    { name: "Complete With Error", value: "completed_with_errors" },
    { name: "Closed", value: "closed" },
];
