import React from "react";
import WmsCollapse from "../../../components/WmsCollapse/WmsCollapse.jsx";
import WmsInput from "../../../components/WmsInput/WmsInput.jsx";

interface SendleInfoComponentProps {
    selector: string;
    handleCarrierObjChange: () => void;
    currentCarrierObj: {
        ApiKey: string;
        Nickname: string;
        UserId: string;
    };
}

export function SendleInfoComponent(props: SendleInfoComponentProps): JSX.Element {
    const translate = (window as any).translate as any;

    return (
        <WmsCollapse
            id={"sendleInfoScreen"}
            headerLabel={translate("Label_Account_Information")}
            showCollapseText={false}
            showCollapseIcon={false}
        >
            <div className="account-info-section">
                <div>
                    <WmsInput
                        id="nickname"
                        inputName="Nickname"
                        wmsSelector={props.selector + "nickname"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_Account_Nick_Name")}
                        value={props.currentCarrierObj.Nickname}
                        required={true}
                        extraWrapperClass="w-50"
                    />
                    <WmsInput
                        id="apiKey"
                        inputName="ApiKey"
                        wmsSelector={props.selector + "apiKey"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_API_Key")}
                        value={props.currentCarrierObj.ApiKey}
                        required={true}
                        extraWrapperClass="w-50"
                    />
                    <WmsInput
                        id="userId"
                        inputName="UserId"
                        wmsSelector={props.selector + "userId"}
                        onChangeFunc={props.handleCarrierObjChange}
                        label={translate("Label_Sendle_Id")}
                        value={props.currentCarrierObj.UserId}
                        required={true}
                        extraWrapperClass="w-50"
                    />
                </div>
            </div>
        </WmsCollapse>
    );
}
