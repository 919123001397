import React, { Component } from "react";

// Component
import WmsButton from "../components/WmsButton/WmsButton.jsx";
import WmsDropdown from "../components/WmsDropdown/WmsDropdown.jsx";
import WmsContainer from "../components/WmsContainer/WmsContainer.jsx";
import WmsRadio from "../components/WmsRadio/WmsRadio.tsx";
import WmsCheckBox from "../components/WmsCheckBox/WmsCheckBox.jsx";
import WmsConfirmModel from "../components/WmsConfirmModel/WmsConfirmModel.jsx";


// Services
import * as GlobalService from "./../global_services";
import * as AsnServices from "./asn-import-preferences.services.jsx";


import style from "./asnimportPreferences.scss";

export class AsnImportPreferences extends Component {
    constructor(props) {
        super(props);

        
        this.translate = window.translate
        this.$rootScope = window;

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            Selector: "AsnImportPreferences",
            saveLoader: false,
            customLabels: GlobalService.getCustomLabels(),
            customerList: [],
            isFormEdit: false,
            OnError: 0,
            selectedCustomerIndex: "",
            selectedCustomer: "",
            DefaultFormats: [],
            SelectedParsers: [],
            isOpenWarningModel: false
        }
    }

    componentDidMount() {
        kendo.ui.progress($('#' + this.state.windowId), true);
        GlobalService.GetCustomersAndFacility().then(() => {
            this.setState({
                customerList: GlobalService.getCustomerList()
            });
        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
    }

    ClickExit = (e) => {
        this.onCloseConfirmModel();
        setTimeout(() => {
            GlobalService.closeWindow(this.state.windowId);
        }, 100);
    };

    EventGetReceivingPreferences = (event) => {
        if (this.state.isFormEdit) {
            this.setState({ isOpenWarningModel: true });
            return;
        }
        var customerObj = this.getCustomerById(event.target.value);

        this.setState({
            selectedCustomerIndex: event.target.value,
            selectedCustomer: customerObj
        });

        kendo.ui.progress($('#' + this.state.windowId), true);

        AsnServices.GetReceivingImportPreferences(customerObj.uri).then((response) => {

            var workflow = response.Workflow;

            this.findDefaultChecks(response.Defaults);

            this.setState({
                DefaultFormats: response.Defaults,
                OnError: workflow.OnError ? workflow.OnError : 0,
                // IndexOptionsCustomizeDuringImport: workflow.Workflow || workflow.Workflow === 0 ? workflow.Workflow : 2,
            });

            
        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
    };

    findDefaultChecks(arr) {
        var SelectedParsers = [];
        arr.forEach((elem) => {
            if (elem.hasOwnProperty('IsSelected') && elem.IsSelected) {
                SelectedParsers.push(elem.Id);
            }
        });

        this.setState({
            SelectedParsers: SelectedParsers
        });
    }

    getCustomerById(id) {
        return this.state.customerList.find((element) => {
            if (element.id == id) {
                return element;
            }
        });
    }


    toggleSelectedParsers = (id) => (event) => {
        id = Number(id);
        var selectedParsers = this.state.SelectedParsers;

        var idx = this.state.SelectedParsers.indexOf(id);

        (idx === -1) ? selectedParsers.push(id) : selectedParsers.splice(idx, 1);


        // var filteredSelectedParsers = this.state.DefaultSelectedParsers.filter(element => selectedParsers.includes(element));
        // var isFormEdit = (this.state.DefaultSelectedParsers.length == selectedParsers.length && selectedParsers.length == filteredSelectedParsers.length)

        this.setState({
            isFormEdit: true,
            SelectedParsers: selectedParsers
        });
    }

    ClickSaveAndExit = (event) => {
        var workflow = {
            OnError: Number(this.state.OnError)
        };

        this.setState({
            saveLoader: true,
            isFormEdit: false,
        });

        AsnServices.SaveReceivngImportPreferences(this.state.selectedCustomer.uri, workflow, this.state.SelectedParsers)
            .then((response) => {

                this.setState({ saveLoader: false });                
                if (response.status == '204' || response.status == '200') {
                    GlobalService.notification('success', this.translate('Settings_Label_Import_Pref_Save_Success'));
                    this.ClickExit();
                }
                
            }).finally(() => {
                this.setState({ saveLoader: false });  
            });
    }

    ChangeImportPreferences = (key, value) => (event) => {
        this.setState({
            isFormEdit: true,
            [key]: value
        });
    }

    onCloseConfirmModel = () => {
        this.setState({ isOpenWarningModel: false });
    }

    makeWmsSelector(string) {
        var selector = this.state.Selector;
        selector += string.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1);
        }).replace(/\s/g, "");

        return selector;
    }

    render() {
        return (
            <div id="asn-import-preferences-holder-react">
                <div className="row">
                    <WmsButton
                        label={this.translate("Settings_Label_SaveExit")}
                        wmsSelector={this.state.Selector + "ButtonSaveExit"}
                        onClickFunc={this.ClickSaveAndExit}
                        icon="fa-floppy-o"
                        buttonType="green"
                        showToolTip={(this.state.SelectedParsers.length == 0 || !this.state.isFormEdit) ? true : false}
                        tooltipText={(this.state.SelectedParsers.length == 0 && this.state.isFormEdit) ? this.translate("Label_Require_Format") : (!this.state.isFormEdit) ? this.translate("Tooltip_No_changes") : ""}
                        disabled={(this.state.SelectedParsers.length == 0 || !this.state.isFormEdit) ? true : false}
                        loading={this.state.saveLoader}
                    />

                    <WmsButton
                        label={this.state.isFormEdit == false ? this.translate('Settings_Label_Exit') : this.translate('Settings_Label_DiscardExit')}
                        wmsSelector={this.state.Selector + 'ButtonExit'}
                        onClickFunc={this.ClickExit}
                        icon={this.state.isFormEdit == false ? 'fa-sign-out-alt' : 'fa-times-circle'}
                        buttonType="yellow" />
                </div>

                <WmsContainer>
                    <WmsDropdown
                        id="asnImportPreferencesHolderIndexOptionsCustomer"
                        name="CustomerSelect"
                        label={this.state.customLabels.Label_Customer}
                        wmsSelector={this.state.Selector + "CustomerDropdown"}
                        value={this.state.selectedCustomerIndex}
                        onChangeFunc={this.EventGetReceivingPreferences}
                        options={this.state.customerList}
                        valueField="id"
                        textField="name"
                        parentClassName="form_group"
                        required={true}
                        blankFirstOption={true}
                    />
                </WmsContainer>

                {this.state.selectedCustomerIndex !== "" && (
                    <div className="wms-asn-data-wrapper">
                        <WmsContainer title={this.translate("Settings_Label_Import_Pref")}>
                            <div className="">
                                <label className="wms-input-label">
                                    {this.translate("Settings_Label_OnError")}
                                </label>
                            </div>

                            <WmsRadio
                                label={this.translate("Settings_Label_AbortImport")}
                                name="OnErrorAsnPreferences"
                                value={this.state.OnError == 0}
                                wmsSelector={this.state.Selector + "AbortImport"}
                                onChangeFunc={this.ChangeImportPreferences('OnError', 0)}
                                checked={this.state.OnError == 0}
                            />

                            <WmsRadio
                                label={this.translate("Settings_Label_ContinueImportAsn")}
                                name="OnErrorAsnPreferences"
                                value={this.state.OnError == 1}
                                wmsSelector={this.state.Selector + "ContinueImport"}
                                onChangeFunc={this.ChangeImportPreferences('OnError', 1)}
                                checked={this.state.OnError == 1}
                            />

                        </WmsContainer>

                        {this.state.DefaultFormats.length !== 0 && (
                            <WmsContainer title={this.translate("Settings_Label_ReceiptImportFormat")}>
                                {
                                    this.state.DefaultFormats.map((format, key) =>
                                        ([33, 35, 32, 54].includes(format.Id)) ?
                                            <WmsCheckBox
                                                key={key}
                                                id={String(format.Id)}
                                                name={String(format.DisplayName)}
                                                wmsSelector={this.makeWmsSelector(format.DisplayName)}
                                                onChangeFunc={this.toggleSelectedParsers(format.Id)}
                                                label={format.DisplayName}
                                                checked={this.state.SelectedParsers.indexOf(format.Id) !== -1}
                                                value=""
                                            /> : ""
                                    )
                                }
                            </WmsContainer>
                        )}
                    </div>
                )}

                <div>
                    <WmsConfirmModel
                        isOpen={this.state.isOpenWarningModel}
                        width={500}
                        height={175}
                        firstBtnLabel={this.translate("Settings_Label_SaveExit")}
                        firstBtnIcon={"fa-floppy-o"}
                        firstBtnOnClickFunc={this.ClickSaveAndExit}
                        secondBtnLabel={this.translate('Settings_Label_DiscardExit')}
                        secondBtnIcon={"fa-times-circle"}
                        secondFunctionClick={this.ClickExit}
                        secondButtonType="yellow"
                        isSecondBtnSaw={true}
                        sawCheckBox={false}
                        thirdBtnLabel={this.translate('RoleManager_Label_Back')}
                        thirdBtnIcon={"fa fa-arrow-left"}
                        conFormModelText="Do you want to save changes before choosing a new customer?"
                        thirdButtonType="black"
                        onCloseFunc={this.onCloseConfirmModel}
                    />
                </div>
            </div>
        );
    }
}