
export function settingGridSchema() {
    return {
        model: {
            id: "Symbol",
            fields: {
                Symbol: { editable: false, nullable: true },
                display_name: { editable: false, nullable: true },
                Value: { validation: { required: true } }
            }
        }
    };
}
export function settingGridColumns() {
    return [{
        field: "display_name",
        title: "Name",
        width: 120,
        wmsid: 2,
        editable: false,
        filterable: true
    }, {
        field: "Value",
        title: "Value",
        width: 120,
        wmsid: 3,
        filterable: true
    }]
}