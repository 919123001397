import React from 'react';
import PropTypes from 'prop-types';
import style from './WmsTextArea.scss';

class WmsTextArea extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isSetRequiredBorder: false,
            value: props.value || ""
        }
        this.regExpArray = {
            'number': /^[0-9\b]+$/,
            'letters': /^(?!\s*$).+/,
            'alphanumeric': /^[ A-Za-z0-9_@.]*$/,
            'regularExp': this.props.regularExp
        };
    }
    static getDerivedStateFromProps (nextProps, prevState) {
        if(nextProps.value  != prevState.value){
            return { value: nextProps.value || "" };
        }
        return null;
    }
    onChangeFunc = (e) => {

        var testValueType = this.props.regularExp == '' ? this.props.valueType : 'regularExp';
        var valueTypeMatch = testValueType != '' ? this.regExpArray[testValueType].test(e.target.value) : true;

        if (e.target.value === '' || valueTypeMatch) {
            this.setState({ value: e.target.value });
            this.props.onChangeFunc(e);
        }
    }
    onBlurFunc = (e) => {
        if(this.props.required) {
            var isSetRequiredBorder = false;
            if(!e.target.value) {
                isSetRequiredBorder = true;
            }
            this.setState({ isSetRequiredBorder: isSetRequiredBorder });
        }
    }

    render() {
        return (
            <div className={"wms-text-area-component " + this.props.parentClassName}>
                <label htmlFor={this.props.id}>{this.props.label + (this.props.required ? ' *' : '')}</label>
                    <textarea
                        id={this.props.id}
                        name={this.props.name}
                        className={(this.state.isSetRequiredBorder && this.props.required) ? "wms_required_input" : ''}
                        value={this.state.value}
                        data-wms-selector={this.props.wmsSelector}
                        onChange={this.onChangeFunc}
                        maxLength={this.props.maxLength}
                        required={this.props.required}
                        disabled={this.props.disabled}
                        rows={this.props.rows}
                        onBlur={this.onBlurFunc}
                        cols={this.props.cols} >
                    </textarea>
            </div>
        );
    }
}

WmsTextArea.propTypes = {
    id: PropTypes.string, // this should write the id to the textArea
    name: PropTypes.string, // this should write the name to the textArea
    disabled: PropTypes.bool, // default will be false
    maxLength: PropTypes.number, // this is the maxLength of the textArea
    rows: PropTypes.number, // this is the row of the textarea
    cols: PropTypes.number, // this is the column of the textarea
    required: PropTypes.bool, // this determines if the textArea is required and shows in red
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the textArea
    parentClassName: PropTypes.string, //    this should parent tag class
    value: PropTypes.string, // this should control the value of
    valueType: PropTypes.string, // alphanumeric, number, letters
    label: PropTypes.string.isRequired, // this should be the label that display on box
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
}

WmsTextArea.defaultProps = {
    disabled: false,
    required: false,
    rows: 10,
    cols: 10,
    maxLength: 255,
    parentClassName: 'textarea-listing',
    valueType: "",
    regularExp: ''
}

export default WmsTextArea;