import React, { useState } from "react";
import PropTypes from "prop-types";

import WmsModel from "./../react/components/WmsModel/WmsModel";
import WmsCollapse from "./../react/components/WmsCollapse/WmsCollapse";
import WmsInput from "./../react/components/WmsInput/WmsInput";
import WmsButton from "./../react/components/WmsButton/WmsButton";
import "./manageassembly.scss";

interface Props {
    wmsSelector?: string;
    selectedRow?: any;
    onCloseCancelAssembly?: any;
    isOpenCancelAssemblyModal?: boolean;
    onApplyCancelAssembly?: any;
    applyingCancelAssembly?: boolean;
    selectedAssembliesIds: any;
    selectedCustomer: string;
    selectedWarehouse: string;
}

const defaultCancelAssemblyData = {
    charge: "",
    reason: "",
    ids: [],
};

declare var window: any;
const { $ } = window;

const CancelAssemblyModal: React.FC<Props> = (props: Props): any => {
    const [CancelAssemblyData, setCancelAssemblyData] = useState(defaultCancelAssemblyData);

    const handelChangeEvent = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        setCancelAssemblyData((previousState) => ({
            ...previousState,
            [name]: value,
        }));
    };

    const onApplyCancelAssembly = () => {
        kendo.ui.progress($("#CancelAssemblyModal"), true);
        let tempCancelAssemblyData = CancelAssemblyData;
        tempCancelAssemblyData.ids = props.selectedAssembliesIds;
        props.onApplyCancelAssembly(tempCancelAssemblyData);
    };

    return (
        <WmsModel
            id="CancelAssemblyModal"
            title={window.translate("Cancel_Assembly")}
            width={800}
            height={800}
            onCloseFunc={props.onCloseCancelAssembly}
            isOpen={props.isOpenCancelAssemblyModal}
            customClass="wms-model-new-ui-wrapper"
        >
            <div className="model-content-wrapper">
                <WmsCollapse
                    id={"AssemblyDetails"}
                    headerLabel={window.translate("Label_Assembly_Details")}
                    showCollapseText={false}
                >
                    <div className="charge-detail-wrapper">
                        <div className="display-inline">
                            <div className="left-content-wrapper">
                                <div className="content">
                                    <label>Customer</label>
                                    <span>{props.selectedCustomer}</span>
                                </div>
                                <div className="content">
                                    <label>Warehouse</label>
                                    <span>{props.selectedWarehouse}</span>
                                </div>
                            </div>
                            <div className="right-content-wrapper">
                                <div className="content">
                                    <label>Assembly ID</label>
                                    <span>{props.selectedAssembliesIds.toString()}</span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="display-inline">
                            <WmsInput
                                id="reason"
                                inputName="reason"
                                wmsSelector={props.wmsSelector + "reason"}
                                onChangeFunc={handelChangeEvent}
                                label={window.translate("Cancellation_Reason")}
                                value={CancelAssemblyData.reason}
                                extraWrapperClass="cancellation-reason"
                            />
                            <WmsInput
                                id="charge"
                                inputName="charge"
                                wmsSelector={props.wmsSelector + "charge"}
                                onChangeFunc={handelChangeEvent}
                                label={window.translate("Cancellation_Fee")}
                                value={CancelAssemblyData.charge}
                                extraWrapperClass="cancellation-fee"
                                // eslint-disable-next-line security/detect-unsafe-regex
                                regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                            />
                        </div>
                    </div>
                </WmsCollapse>
            </div>
            <div className="footer-btn-holder">
                <WmsButton
                    label={window.translate("Label_Cancel")}
                    wmsSelector={window.wmsSelector + "DiscardExit"}
                    onClickFunc={props.onCloseCancelAssembly}
                    buttonType="orange"
                    icon={"fa-ban"}
                />
                <WmsButton
                    label={window.translate("Label_Save")}
                    wmsSelector={props.wmsSelector + "ApplyCancelAssembly"}
                    onClickFunc={onApplyCancelAssembly}
                    icon={"fa-floppy-o"}
                    loading={props.applyingCancelAssembly}
                />
            </div>
        </WmsModel>
    );
};
CancelAssemblyModal.propTypes = {
    wmsSelector: PropTypes.string.isRequired,
    selectedRow: PropTypes.any.isRequired,
    onCloseCancelAssembly: PropTypes.any.isRequired,
    isOpenCancelAssemblyModal: PropTypes.bool.isRequired,
    onApplyCancelAssembly: PropTypes.any.isRequired,
    applyingCancelAssembly: PropTypes.bool.isRequired,
    selectedAssembliesIds: PropTypes.any.isRequired,
    selectedCustomer: PropTypes.string.isRequired,
    selectedWarehouse: PropTypes.string.isRequired,
};

CancelAssemblyModal.defaultProps = {
    wmsSelector: "",
    selectedRow: {},
    onCloseCancelAssembly: (): void => {},
    isOpenCancelAssemblyModal: false,
    onApplyCancelAssembly: (): void => {},
    applyingCancelAssembly: false,
    selectedAssembliesIds: [],
    selectedCustomer: "",
    selectedWarehouse: "",
};

export default React.memo(CancelAssemblyModal);
