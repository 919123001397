import React from 'react';
import PropTypes from 'prop-types';
import './WmsToolTip.scss';

interface ToolTipProps {
    showToolTip?: boolean,
    tooltipText?: string,
    children?: any
}

const WmsToolTip: React.FC<ToolTipProps> = (props: ToolTipProps): any => {
    return (
        <div
            className={"wms-tooltip-component" + (props.children ? ' saw-tooltip-on-icon' : '')} >
            {props.children}
            {(<div className="div_tooltip">
                {props.showToolTip === true && (
                    <div>{props.tooltipText}</div>
                )}
            </div>)}
        </div>
    );

};

WmsToolTip.propTypes = {
    showToolTip: PropTypes.bool, // set tooltip show or not
    tooltipText: PropTypes.string, // show text on hover as tooltip
}
WmsToolTip.defaultProps = {
    showToolTip: true,
    tooltipText: 'set tooltip text',
}

export default React.memo(WmsToolTip);