import React from 'react';

export const getGridDemoColumns = (FindLocation_GridHeaders) => {
    
    var translate = window.translate;
    return ({

        columns: [{
            field: "Name",
            title: FindLocation_GridHeaders[0].description,
            width: 120,
            wmsid: 1,
            filterable: true
        },
        {
            field: "Active",
            title: FindLocation_GridHeaders[1].description,
            width: 120,
            wmsid: 2,
            filterable: false,
            template: "#= Active == true ? '" + translate('Label_Active') + "' : '" + translate('Label_Inactive') + "' #",
        },
        {
            field: "TypeDescription",
            title: FindLocation_GridHeaders[2].description,
            width: 120,
            wmsid: 3,
            filterable: true,
            sortable: true,
        },
        {
            field: "Description",
            title: FindLocation_GridHeaders[3].description,
            width: 120,
            wmsid: 4,
            filterable: true,
        },
        {
            field: "Height",
            title: FindLocation_GridHeaders[4].description,
            width: 120,
            wmsid: 5,
            hidden: true,
            filterable: false,
            sortable: false,
        },
        {
            field: "Width",
            title: FindLocation_GridHeaders[5].description,
            width: 120,
            wmsid: 6,
            hidden: true,
            filterable: false,
            sortable: false,
        },
        {
            field: "Length",
            title: FindLocation_GridHeaders[6].description,
            width: 120,
            wmsid: 7,
            hidden: true,
            filterable: false,
            sortable: false,
        },
        {
            field: "WeightAllowed",
            title: FindLocation_GridHeaders[7].description,
            width: 120,
            wmsid: 8,
            hidden: true
        },
        {
            field: "TemperatureMinimum",
            title: FindLocation_GridHeaders[8].description,
            width: 120,
            wmsid: 9,
            hidden: true
        },
        {
            field: "QuantityMinimum",
            title: FindLocation_GridHeaders[9].description,
            width: 120,
            wmsid: 10,
            hidden: true,
            filterable: false,
            sortable: false,
        },
        {
            field: "AllocationPriority",
            title: FindLocation_GridHeaders[10].description,
            width: 120,
            wmsid: 11,
            hidden: true
        },
        {
            field: "BillingTypeId",
            title: FindLocation_GridHeaders[11].description,
            width: 120,
            wmsid: 12,
            hidden: true
        }]
    })
}


export const getGridDemoSchema = () =>{
    return({
        schema: {
            data: "Data",
            total: "Total",
            model: {
                id: "LocationId",
                fields: {
                    LocationId: {
                        type: "number"
                    },
                    Name: {
                        type: "string"
                    },
                    Active: {
                        type: "boolean"
                    },
                    TypeId: {
                        type: "string"
                    },
                    TypeDescription: {
                        type: "string"
                    },
                    Description: {
                        type: "string"
                    },
                    Height: {
                        type: "number"
                    },
                    Width: {
                        type: "number"
                    },
                    Length: {
                        type: "number"
                    },
                    WeightAllowed: {
                        type: "number"
                    },
                    TemperatureMinimum: {
                        type: "number"
                    },
                    QuantityMinimum: {
                        type: "number"
                    },
                    AllocationPriority: {
                        type: "number"
                    },
                    BillingTypeId: {
                        type: "number"
                    }
                }
            }
        }
    })
}

export const getGridDemoTransport = (warehouseIndex) =>{
    return ({
        transport: {
            read: {
                url: "/WebUI/Facilities/ManageLocations/LocationsGridRead",
                type: "POST",
                data: {
                    facilityId: warehouseIndex,
                    customParamsInShiftSelection:true
                },
                dataType: 'json'
            },
            destroy: {
                url: "/WebUI/Facilities/ManageLocations/LocationsGridRead",
                type: "POST",
                data: {
                    facilityId: warehouseIndex
                },
                dataType: "JSON"
            },

            parameterMap: function (options, operation) {
                if (operation !== "read" && options.models) {
                    return {
                        models: JSON.stringify(options.models)
                    };
                }
            },
        }
    })
}