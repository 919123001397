import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
var axios = require('axios');

// Translation Higher Order Component
import { setTranslations, setDefaultLanguage, t } from 'react-multi-lang';
import en_lang from '../../assets/languages/en-US.json';
import en_error_lang from '../../assets/languages/en-error-US.json';
var en = Object.assign({}, en_lang, en_error_lang);
setTranslations({ en });
setDefaultLanguage('en');
window.translate = t;
// import * as commanFunction from './gridColumns';
import * as GlobalService from './global_services';
// for theme metadataservices
import * as metaDataService from '../react/themes/meta-data.service';
import BodyHolder from './bodyComponent/body.component.jsx';
import VersionChecker from './components/VersionChecker/version-checker.component.jsx';
export default class App extends React.Component {
    constructor(props) {
        super(props);
        GlobalService.PreLoad().then((response) => {
            var threeplsettings = response;
            if (threeplsettings) {

                GlobalService.addRightsBaseOnTplSettings(threeplsettings);


            }

            window.setLogInLocalstorage({
                'category': 2,
                'platform': "v2"
            });
            this.setState({
                isPreloadComplete: true
            });
            $(document.body).addClass("loaded");
            setTimeout(function () {
                $("#loader-wrapper").remove();
            }, 500);

            var rights = GlobalService.GetRights();
            var envIndicator = GlobalService.getEnvironmentIndicator();
            /*
             * Commenting out this entire section because now we don't need this Salesforce chat Agent
            if (rights.hasOwnProperty('isWarehouseUser') && rights.hasOwnProperty('UxPrototypingDirectivesShowIntercom')) {
                console.log('Yes I have rights for chat I con');
                var initESW = function(gslbBaseURL) {
                    embedded_svc.settings.displayHelpButton = true; //Or false
                    embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

                    //embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
                    //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

                    //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
                    //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

                    // Settings for Chat
                    //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
                        // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
                        // Returns a valid button ID.
                    //};
                    //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
                    //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
                    //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

                    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
                    embedded_svc.settings.entryFeature = 'LiveAgent';

                    embedded_svc.init(
                        'https://3plcentral.my.salesforce.com',
                        'https://3plcentral.force.com/support',
                        gslbBaseURL,
                        '00D300000006eMr',
                        'X3PL_Central_Chat',
                        {
                            baseLiveAgentContentURL: 'https://c.la2-c1-ia4.salesforceliveagent.com/content',
                            deploymentId: '5723y0000001hS9',
                            buttonId: '5733y0000001iUy',
                            baseLiveAgentURL: 'https://d.la2-c1-ia4.salesforceliveagent.com/chat',
                            eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I3y000000GpzAEAS_17ffec30140',
                            isOfflineSupportEnabled: true
                        }
                    );
                };

                if (!window.embedded_svc) {
                    var s = document.createElement('script');
                    s.setAttribute('src', 'https://3plcentral.my.salesforce.com/embeddedservice/5.0/esw.min.js');
                    s.onload = function() {
                        initESW(null);
                    };
                    document.body.appendChild(s);
                } else {
                    initESW('https://service.force.com');
                }
            }
            */

        });
        this.state = {
            isPreloadComplete: false
        }
        //
        this.$rootScope = window;
        // GlobalService.GetCustomersAndFacility();
        this.setupInterceptors();
    }

    async componentDidMount() {
        await metaDataService.getUserClientMetaData();
        var appliedThemeData = metaDataService.getDefaultThemeMetaData();
        if (!$.isEmptyObject(appliedThemeData)) {
            this.setThemeColorObject(appliedThemeData);
        }
    }
    setThemeColorObject = (responseObj) => {
        for (var key in responseObj) {
            if (responseObj.hasOwnProperty(key)) {
                var val = responseObj[key];
                document.documentElement.style.setProperty('--' + key, val);
            }
        }
    }

    setupInterceptors() {
        var that = this;
        window.onerror = function (message, source, lineno, colno, error) {
            console.log("window.onerror ", error);
            StackTrace.fromError(error).then((stackframes) => {
                var stringifiedStack = stackframes.map(function (sf) {
                    // console.log("sf :",sf);
                    return sf.toString();
                }).join('\n');
                console.log("stringifiedStack :", stringifiedStack);
                var errorData = {
                    "activeTab": ($(".tab-list div.active h5").text() + "  " + $(".tab-list div.active p:first").text() + "  " + $(".tab-list div.active p:last").text()).trim(),
                    "message": message,
                    "source": source,
                    "lineno": lineno,
                    "colno": colno,
                    "error": error ? error.stack : "",
                    "stack": stringifiedStack
                }

                var data = JSON.stringify(errorData);
                GlobalService.LogBrowserTraces(data).then(function (response) {
                    console.log(response);
                });
            }).catch((err) => {
                console.log(err.message);
            });


        }

        window.onunhandledrejection = function (e) {
            if (e && e.reason && e.reason.isCancel) {
                console.log("onunhandledrejection isCancel true : ", e);
                return;
            }
            console.log("onunhandledrejection : ", e);
            if (!e.reason.config) {
                console.log("reason :", e.reason);
                var errorData = {
                    "activeTab": ($(".tab-list div.active h5").text() + "  " + $(".tab-list div.active p:first").text() + "  " + $(".tab-list div.active p:last").text()).trim(),
                    "message": e.reason.message,
                    "stack": e.reason.stack
                }
                console.log(JSON.stringify(errorData));
                GlobalService.LogBrowserTraces(JSON.stringify(errorData)).then(function (response) {
                    console.log(response);
                });

            };
        }

        axios.defaults.headers['Accept-Language'] = 'en-US';
        // Add a request interceptor
        axios.interceptors.request.use(function (config) {
            // console.log("request interceptors config :", config)
            // Do something before request is sent
            var eventItem = new CustomEvent("EventCheckLoginUser", {
                detail: {
                    "action": "check"
                }
            });
            document.dispatchEvent(eventItem);
            config.requestTimestamp = new Date().getTime();
            return config;
        }, function (error) {
            console.error("error : ", error);
            error.config.responseTimestamp = new Date().getTime();
            return Promise.reject(error);
        });

        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            response.config.responseTimestamp = new Date().getTime();
            var trackData = {};
            trackData.method = response.config.method;
            trackData.url = response.config.url;
            trackData.status = response.status;
            trackData.request = response.config.data;
            trackData.response = response.data;
            trackData.object = response;
            // Do something with response data
            that.callApiLog(trackData);
            return response;
        }, GlobalService.axiosResponseErrorHandle);

        let self = this;

        $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
            options.requestTimestamp = new Date().getTime();
            return options;
            // Modify options, control originalOptions, store jqXHR, etc
        });
        $(document).ajaxSuccess(function (event, xhr, settings) {
            let responseTime = (new Date().getTime()) - settings.requestTimestamp;
            if (globalsMsg.clientLogs &&
                globalsMsg.clientLogs.request &&
                responseTime > globalsMsg.clientLogs.request.timing) {
                var apiLogObj = {
                    'url': settings.type + " " + settings.url,
                    'statusCode': xhr.status,
                    'platform': "v2",
                    'category': 1,
                    'time': responseTime,
                    'type': 'slow'
                }
                self.$rootScope.setApiInLocalstorage(apiLogObj);
            }
        });
        $(document).ajaxError(function (event, jqxhr, settings, thrownError) {
            // if ajax request abort than don't show error message
            if (thrownError == "abort") {
                return;
            }
            var apiLogObj = {
                'url': settings.type + " " + settings.url,
                'response': thrownError,
                'statusCode': jqxhr.status,
                'platform': "v2",
                'category': 1,
                'time': 0,
                'type': 'error'
            }

            if (apiLogObj.statusCode == 401) {
                var tplguid = $.urlParam('tplguid');
                var urlStringNoParams = window.location.origin + window.location.pathname;
                var tplguidQueryParam = '';
                if (tplguid) {
                    tplguidQueryParam = '&tplguid=' + tplguid;
                }
                var url = window.location.origin + '/webui/login?callbackUri=';
                if (typeof globalsMsg != 'undefined' && typeof globalsMsg.auth_url != 'undefined') {
                    url = globalsMsg.auth_url;
                }
                //window.location.href = globalsMsg.auth_url + urlStringNoParams + tplguidQueryParam;
                window.location.replace(url + urlStringNoParams + tplguidQueryParam);
                return;
            }


            var responseData = ''
            if (jqxhr.responseText) {
                responseData = jqxhr.responseText;
                apiLogObj.response = jqxhr.responseText;
            }
            if (globalsMsg.clientLogs &&
                globalsMsg.clientLogs.request &&
                apiLogObj.statusCode != 401) {
                that.$rootScope.setApiInLocalstorage(apiLogObj);
            }

            /**Generate object for popup start */
            var _data = {};

            if (jqxhr.responseJSON) {
                responseData = jqxhr.responseJSON;
                _data = jqxhr.responseJSON;
                _data.status = jqxhr.status;
                _data.config = {};
                _data.config.url = settings.url;
                _data.config.method = settings.type;
                // _data.config.data = settings.data;
                GlobalService.generateObjForPopup(_data);
            } else {
                _data = {
                    "ErrorClass": "",
                    "ErrorCode": "",
                    "Descriptors": [],
                    "Hint": null,
                    "type": ((jqxhr.status >= 500) ? 'error' : 'warning'),
                    "status": jqxhr.status

                };
                _data.config = {};
                _data.config.url = settings.url;
                _data.config.method = settings.type;
                // _data.config.data = settings.data;
                GlobalService.showErrorMessage(_data);
            }

            if (jqxhr.status >= 500) {
                GlobalService.trackEventInPendo(_data.config.url, _data.config.method, jqxhr.status, settings.data, responseData);
            }
        });
        $.urlParam = function (name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)')
                .exec(window.location.href);
            if (results == null) {
                return 0;
            }
            return results[1] || 0;
        }
    }
    arrayRemove = (array, element) => {
        return array.filter(function (e) {
            return e !== element
        });

    }

    callApiLog(trackData) {

        var apiResponseTime = (trackData.object.config.responseTimestamp - trackData.object.config.requestTimestamp);

        if (typeof globalsMsg != 'undefined' && globalsMsg.clientLogs &&
            globalsMsg.clientLogs.request &&
            apiResponseTime > globalsMsg.clientLogs.request.timing) {
            var apiLogObj = {
                'url': trackData.object.config.method + " " + trackData.object.config.url,
                'statusCode': trackData.status,
                'platform': "v2",
                'category': 1,
                'time': apiResponseTime,
                'type': 'slow'
            }

            this.$rootScope.setApiInLocalstorage(apiLogObj);
            // this.$rootScope.setLoaderTimerInLocalstorage(apiLogObj);
        }
    }
    render() {
        return (
            <Fragment>
                <BodyHolder isPreloadComplete={this.state.isPreloadComplete}></BodyHolder>
                {this.state.isPreloadComplete && <VersionChecker></VersionChecker>}
            </Fragment>
        );
    }
}
