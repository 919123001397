import React, { Component } from 'react'
import { ObjectWms } from '../../../../assets/ts/ObjectWms.ts';
import { DictionaryWms } from '../../../../assets/ts/DictionaryWms.ts';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import { ArrayWms } from '../../../../assets/ts/ArrayWms.ts';
import { AttachDocuments } from './../../components/AttachDocuments/AttachDocuments.jsx';

import './manageReceipt.scss';

//services
import * as manageReceiptService from './manageReceipt.services.jsx';
import * as GlobalService from './../../global_services';
import * as ReceiptGlobalService from './../findReceipt_global.services.jsx';
import * as DynamicMenu from './../dynamic_menu.jsx';


import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsComboBox from '../../components/WmsComboBox/WmsComboBox.jsx';
import WmsButtonDropdown from '../../components/WmsButtonDropdown/WmsButtonDropdown.jsx';
import WmsMenuItem from '../../components/WmsMenuItem/WmsMenuItem.jsx';
import WmsContainer from '../../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from '../../components/WmsDropdown/WmsDropdown.jsx';
import WmsInput from '../../components/WmsInput/WmsInput.jsx';
import WmsDateTimePicker from '../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import WmsTab from '../../components/WmsTab/WmsTab.jsx';
import { ManageReceiptTransportInformation } from './manageReceipt-transport-information.component.jsx';
import { ManageReceiptCustomReceiptInfo } from './manageReceipt-custom-receipt-info.component.jsx';
import { ManageReceiptLineItems } from './manageReceipt-line-items.component.jsx';
import { ManageReceiptCharges } from './manageReceipt-charges.component.jsx';
import { CreateManualChargeModel } from '../../orders/findOrders/model/create-manual-charge-model.component.jsx';
import { ConfirmReceiptModal } from './model/confirm-receipt-modal.component.jsx';
import { AddNewCustomFieldModal } from './model/add-new-custom-field-modal.component.jsx';
import { ManageLineItemsModal } from './model/manage-line-items-modal.component.jsx';

export class ManageReceiptHolder extends Component {
    constructor(props) {
        super(props);

        this.translate = window.translate;
        this.$rootScope = window;

        this.Selector = "ManageReceipt";
        this.scrollAreaWrapperId = "manageReceiptScrollBox";
        this.scrollDomSelector = this.$rootScope.ChildBulletin.Data.Window ? '.' + this.$rootScope.ChildBulletin.Data.Window + '#' + this.scrollAreaWrapperId : '#' + this.scrollAreaWrapperId;
        this.Rights = GlobalService.GetRights();

        this.isEnableProvider = this.Rights.hasOwnProperty('Shipping.SmallParcelEnabled') && this.Rights['Integrations.SmallParcelPartner'] == 'ParcelApi';

        this.tabHeaderRef = React.createRef();
        this.transportInformationRef = React.createRef();
        this.customReceiptInfoRef = React.createRef();
        this.lineItemsRef = React.createRef();
        this.receiptChargesRef = React.createRef();

        this.sectionRefs = [
            {
                section: "collapseReceiptTransportInformation",
                ref: this.transportInformationRef,
                label: this.translate('Label_Transport_Information'),
                wmsSelector: this.Selector + "TransportInformation",
                isTabShow: true
            },
            {
                section: "collapseReceiptCustomInfo",
                ref: this.customReceiptInfoRef,
                label: this.translate('Label_Custom_Receipt_Info'),
                wmsSelector: this.Selector + "CustomReceiptInfo",
                isTabShow: true
            },
            {
                section: "collapseReceiptLineitem",
                ref: this.lineItemsRef,
                label: this.translate('Label_Receipt_Line_Items'),
                wmsSelector: this.Selector + "ReceiptLineItems",
                isTabShow: true
            },
            {
                section: "collapseReceiptCharges",
                ref: this.receiptChargesRef,
                label: this.translate('Label_Receipt_Charges'),
                wmsSelector: this.Selector + "ReceiptCharges",
                isTabShow: this.Rights.hasOwnProperty('receipts.charges.manage') ? true : false
            }
        ];

        this.isCreating = false;
        this.emptySharedReceiptData = {
            Receipt: {
                ReferenceNum: ''
            },
            ChargeTypeCategories: []
        };

        this.state = {
            selector: this.Selector,
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            Rights: this.Rights,
            customLabels: GlobalService.getCustomLabels(),
            sharedReceiptData: this.emptySharedReceiptData,
            optionsCustomer: GlobalService.getCustomerList(false),
            optionsFacility: [],
            MenuPrintData: [],
            InitialPrintMenuResponse: [],
            selectedCustomerId: "",
            selectedFacilityId: "",
            selectedFacility: {},
            selectedCustomer: { options: {} },
            gifComplete: false,
            savingGif: false,
            enableSave: false,
            confirmedReceipt: false,
            showCustomReceiptInfo: false,
            isSaved: false,
            sharedIsOpenManualChargeModal: false,
            sharedIsOpenAddNewCustomFieldModal: false,
            sharedIsOpenManageLineItemsModal: false,
            sharedLineItemFlow: '',
            sharedSelectedLineItem: '',
            manageReceiptEditData: {},
            lineItemsGridData: [],
            totalLineItems: 0,
            isOpenAttachDoc: false,
            sharedAttachObj: false,
            sharedIsOpenConfirmReceiptModal: false,
            gifConfirm: false,
            sharedIsResetLineItemGrid: false,
            isRequiredCustomReceiptInfo: false,
            warehouseMaxDateTime: "",
            sectionRefs: this.sectionRefs
        }
    }

    componentDidMount() {
        this.initmanageReceipt();
    }
    resetLineItemGrid = () => {
        this.setState({
            sharedIsResetLineItemGrid: !this.state.sharedIsResetLineItemGrid,
            enableSave: true,
        });
    }
    initmanageReceipt = () => {
        // $rootScope.initTooltip();
        kendo.ui.progress($('#' + this.state.windowId), true);//Animation for loading the window
        this.setState({ savingGif: false });

        if (this.$rootScope.ChildBulletin.Data.flow == "create") {
            this.initializeCreate();
            this.isCreating = true;
        } else {
            this.isCreating = false;
            manageReceiptService.ManageReceiptEditData(this.$rootScope.ChildBulletin.Data.receipt.EditUri).then((response) => {

                if (response && (response.status == 204 || response.status == 200)
                ) {
                    // if (response.data.Receipt.ReceiverId != 0) {
                    //     var eventItem = new CustomEvent("EventNewReceiptData", {
                    //         detail: {
                    //             "action": "update",
                    //             "ref": response.data.Receipt.ReferenceNum,
                    //             "trans": response.data.Receipt.ReceiverId,
                    //             fromWindow: this.state.windowId,
                    //             "thumbnail": "update"
                    //         }
                    //     });
                    //     document.dispatchEvent(eventItem);
                    // }

                    this.initializeEdit(response.data);
                } else {
                    kendo.ui.progress($('#' + this.state.windowId), false); //Turning off window loading animation
                }

            }).finally(() => {
                kendo.ui.progress($('#' + this.state.windowId), false);
            });
        }
    }

    initializeCreate = () => {
        kendo.ui.progress($('#' + this.state.windowId), true);
        this.setCustomerAndWarehouse(0, 0, '');
        this.setState({
            enableSave: true,
        });
    }

    initializeEdit = (param_receipt_data) => {

        this.setCustomerAndWarehouse(param_receipt_data.Receipt.CustomerId, param_receipt_data.Receipt.FacilityId, param_receipt_data.GridOptions);

        GlobalService.getFacilityMaxDateTime(param_receipt_data.Receipt.FacilityId)
            .then((facilityCurrentTime) => {
                this.setState({ warehouseMaxDateTime: facilityCurrentTime });
            });

        param_receipt_data.UnitOfMeasureTypes.splice(0, 0, {
            'Name': this.translate('Label_Select_UOM')
        });

        let tmpSharedReceiptObj = param_receipt_data;

        let tmpShipEngineCarrierResponse = tmpSharedReceiptObj.Carriers;
        tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
            return obj.Name != "Buku" && obj.Name != "PitneyBowesExp";
        });
        if (!this.isEnableProvider) {
            tmpShipEngineCarrierResponse = tmpShipEngineCarrierResponse.filter((obj) => {
                return obj.Name != "PitneyBowesStd";
            });
        }
        tmpSharedReceiptObj.Carriers = tmpShipEngineCarrierResponse;

        tmpSharedReceiptObj.ReceiveItemsData = {};

        if (param_receipt_data.Receipt.CapacityType != null) {
            for (var x = 0; x < param_receipt_data.CapacityTypes.length; x++) {
                if (param_receipt_data.CapacityTypes[x]["CapacityTypeId"] == param_receipt_data.Receipt.CapacityType.Id) {
                    tmpSharedReceiptObj.Receipt.CapacityType.Id = param_receipt_data.CapacityTypes[x].CapacityTypeId;
                    break;
                }
            }
        } else {
            tmpSharedReceiptObj.Receipt.CapacityType = {};
            tmpSharedReceiptObj.Receipt.CapacityType.Id = param_receipt_data.CapacityTypes[0].CapacityTypeId;
        }
        if (param_receipt_data.Receipt.Carrier != null) {
            for (var x = 0; x < param_receipt_data.Carriers.length; x++) {
                if (param_receipt_data.Carriers[x]["Name"] == param_receipt_data.Receipt.Carrier) {
                    tmpSharedReceiptObj.Receipt.Carrier = param_receipt_data.Carriers[x].Name;
                    break;
                }
            }
        } else {
            tmpSharedReceiptObj.Receipt.Carrier = param_receipt_data.Carriers[0].Name;
        }

        if (param_receipt_data.Receipt.Unit1Identifier != null) {
            for (var x = 0; x < param_receipt_data.UnitOfMeasureTypes.length; x++) {
                if (param_receipt_data.UnitOfMeasureTypes[x]["Name"] == param_receipt_data.Receipt.Unit1Identifier.Name) {
                    tmpSharedReceiptObj.Receipt.Unit1Identifier.Name = param_receipt_data.UnitOfMeasureTypes[x].Name;
                    break;
                }
            }
        } else {
            tmpSharedReceiptObj.Receipt.Unit1Identifier = {};
            tmpSharedReceiptObj.Receipt.Unit1Identifier.Name = param_receipt_data.UnitOfMeasureTypes[0].Name;
        }

        if (param_receipt_data.Receipt.Unit2Identifier != null) {
            for (var x = 0; x < param_receipt_data.UnitOfMeasureTypes.length; x++) {
                if (param_receipt_data.UnitOfMeasureTypes[x]["Name"] == param_receipt_data.Receipt.Unit2Identifier.Name) {
                    tmpSharedReceiptObj.Receipt.Unit2Identifier.Name = param_receipt_data.UnitOfMeasureTypes[x].Name;
                    break;
                }
            }
        } else {
            tmpSharedReceiptObj.Receipt.Unit2Identifier = {};
            tmpSharedReceiptObj.Receipt.Unit2Identifier.Name = param_receipt_data.UnitOfMeasureTypes[0].Name;
        }

        setTimeout(() => {
            $("." + this.state.windowId + ' #ReceiptsPONum').focus();
        }, 100);



        // // remove grid element which us systemGenerated
        // if it is a systemGenerated = true we do not want to show it on initial load, only after recalculate
        const billingData = ObjectWms.removeObjFromListOnParameterValue(tmpSharedReceiptObj.Receipt.BillingCharges, "SystemGenerated", true);
        tmpSharedReceiptObj.Receipt.BillingCharges = billingData;

        tmpSharedReceiptObj.threePlReceiptSettings = param_receipt_data.ThreePlSettings;
        tmpSharedReceiptObj.ReceiveItemsData.receiveLineItems = param_receipt_data.Receipt.ReceiveItems;

        if (!StringWms.isEmpty(param_receipt_data.GridOptions.GridReceiptLineItems)) {
            tmpSharedReceiptObj.ReceiveItemsData.GridLayout = param_receipt_data.GridOptions.GridReceiptLineItems;
        }

        // Attach this to shared object so that saved elements grid can use it
        tmpSharedReceiptObj.ReceiptSE = param_receipt_data.ReceiptSE;

        var initialData = [];
        if (tmpSharedReceiptObj.Receipt.SavedElements.length > 0) {
            initialData = tmpSharedReceiptObj.Receipt.SavedElements;
        }
        // we should have the Predefined Elements for SavedElements attached to Receipts object before we get a notificaiton letting us know the data is ready.
        var nonDuplicatedPreDefined = [];

        for (var x = 0; x < tmpSharedReceiptObj.ReceiptSE.length; x++) {
            if (ObjectWms.FindIndexByProperty(initialData, "Name", tmpSharedReceiptObj.ReceiptSE[x].Name) == -1) {
                nonDuplicatedPreDefined.push(tmpSharedReceiptObj.ReceiptSE[x]);
            }
        }

        tmpSharedReceiptObj.Receipt.SavedElements = initialData.concat(nonDuplicatedPreDefined);

        kendo.ui.progress($('#' + this.state.windowId), false); //Turning off window loading animation

        this.onCustomReceiptInfoSave(tmpSharedReceiptObj.Receipt.SavedElements);
        this.setState({
            sharedReceiptData: tmpSharedReceiptObj,
            confirmedReceipt: param_receipt_data.Receipt.StatusEnum > 0,
            showCustomReceiptInfo: param_receipt_data.ThreePlSettings.OtherUserFeatures.SavedElementsEnabled,
            isSaved: true,
            enableSave: false,
            isReceiptCreated: true,
            savingGif: false
        });
        // update tab refs state
        var sectionRefs = this.state.sectionRefs;
        sectionRefs.forEach((elem) => {
            if (elem.section == "collapseReceiptCustomInfo") {
                elem.isTabShow = (param_receipt_data.ThreePlSettings.OtherUserFeatures.SavedElementsEnabled == true) ? true : false;
            }
        });
        this.setState({
            sectionRefs: sectionRefs
        });
    }

    onCustomReceiptInfoSave = (param_data) => {
        var receiptInfoRequired = false;
        var defaultValue = null;
        for (var i = 0; i < param_data.length; i++) {
            if (param_data[i].Required) {
                receiptInfoRequired = param_data[i].Required
                defaultValue = param_data[i].Value;
            }
        }
        this.setState({
            isRequiredCustomReceiptInfo: (receiptInfoRequired && !defaultValue) ? true : false,
        });
    }
    setCustomerAndWarehouse = (custId, facilityId, gridOption) => {
        GlobalService.GetCustomersAndFacility().then((response) => {

            let isCustomerAndFacilityLoad = false;
            let showPurchaseOrderSearch = false;
            let selectedCustomer = null;

            if (this.state.isSaved == true) {

                let optionsCustomer = GlobalService.getCustomerList(false);
                selectedCustomer = optionsCustomer[ObjectWms.getIndexBasedUponKeyValuePair(optionsCustomer, "id", custId)];
                if (selectedCustomer) {
                    isCustomerAndFacilityLoad = true;
                    this.getInitialPrintMenu(selectedCustomer.id);
                    if (selectedCustomer.options.purchaseOrders == true) {
                        showPurchaseOrderSearch = true;
                    }
                    this.setState({
                        sharedReceiptData: Object.assign(
                            this.state.sharedReceiptData,
                            { customerSettings: selectedCustomer.options }
                        )
                    });
                }
            }
            if (selectedCustomer && selectedCustomer != null) {

                var temp = Object.assign([], GlobalService.getFacilityList(false));

                if (this.state.isSaved == false) {
                    for (var x = 0; x < selectedCustomer.facilities.length; x++) {
                        temp.push(GlobalService.Customers.FacilityDict[selectedCustomer.facilities[x]]);
                    }
                }
                let selectedFacility = temp[ObjectWms.getIndexBasedUponKeyValuePair(temp, "id", facilityId)];

                this.setState({
                    selectedCustomer: selectedCustomer,
                    selectedCustomerId: selectedCustomer.id,
                    selectedFacility: selectedFacility,
                    selectedFacilityId: selectedFacility.id,
                    optionsFacility: temp,
                    isCustomerAndFacilityLoad: isCustomerAndFacilityLoad,
                    showPurchaseOrderSearch: showPurchaseOrderSearch,
                });

            }
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
    }

    onClickExitForm = () => {
        GlobalService.closeWindow(this.state.windowId);
    }

    indexOptionsCustomerChange = (event) => {
        var customerObj = this.getCustomerById(event.target.value);

        this.setState({
            selectedCustomerId: event.target.value != 0 ? event.target.value : "",
            selectedCustomer: customerObj,
            enableSave: true,
        });
        var temp = [];
        var facilityList = GlobalService.getFacilityDictList();
        for (var x = 0; x < customerObj.facilities.length; x++) {
            if(facilityList[customerObj.facilities[x]] !== undefined){
                temp.push(facilityList[customerObj.facilities[x]]);
            }
        }
        var warehouseId = "";
        var selectedFacility = "";
        if (temp.length > 0) {
            warehouseId = temp[ObjectWms.getIndexBasedUponKeyValuePair(temp, "id", customerObj.defaultFacility)] ?
                temp[ObjectWms.getIndexBasedUponKeyValuePair(temp, "id", customerObj.defaultFacility)].id : temp[0].id;
            selectedFacility = temp[ObjectWms.getIndexBasedUponKeyValuePair(temp, "id", warehouseId)] ?
                temp[ObjectWms.getIndexBasedUponKeyValuePair(temp, "id", warehouseId)] : temp[0];
        }
        this.setState({
            optionsFacility: temp,
            selectedFacility: selectedFacility,
            selectedFacilityId: warehouseId,
        });
    }

    getCustomerById(id) {
        return this.state.optionsCustomer.find((element) => {
            if (element.id == id) {
                return element;
            }
        });
    }

    getFacilityById(id) {
        return this.state.optionsFacility.find((element) => {
            if (element.id == id) {
                return element;
            }
        });
    }

    handleFaciltiySelectChange = (event) => {
        this.setState({
            selectedFacility: this.getFacilityById(event.target.value),
            selectedFacilityId: event.target.value,
            enableSave: true,
        });
    }

    getInitialPrintMenu(param_customer_id) {
        ReceiptGlobalService.getReports(param_customer_id).then((response) => {
            if (response) {
                this.setState({
                    InitialPrintMenuResponse: response
                });
                this.updateInitialPrintMenu();
            }
        }).finally(() => { });
    }

    updateInitialPrintMenu = () => {
        var params = {
            Selector: this.state.selector,
            SelectedReceipts: 1,
            isClosedOrCanceled: false,
            isCanceled: false,
            Rights: this.state.Rights,
            fnNames: ['RunViewTransactionReport', 'RunMULabelReport', 'RunReceiptTicketReport']
        }
        const updatedMenus = DynamicMenu.getInitialDynamicPrintMenu(this.state.InitialPrintMenuResponse, params, this.dynamicMenuClick);
        this.setState({
            MenuPrintData: updatedMenus.PrintData,
        });
    }
    dynamicMenuClick = (event) => {
        var wmsid = $(event.target).parent().attr('data-wmsid');
        wmsid = "clickOn" + wmsid;
        if (this[wmsid]) {
            this[wmsid](event);
        } else {
            console.error("Uncaught TypeError: " + wmsid + " is not a function");
        }
    }
    clickOnattachFiles = () => {
        var sharedAttachObj = {
            'isReceipt': true,
            'uri': this.state.sharedReceiptData.Receipt.EditUri,
            'fileSummariesUri': this.state.sharedReceiptData.Receipt.FileSummariesUri,
            'getAttachedDocsInfoUrl': '/WebUI/Receiving/FindReceivings/GetAttachedDocsInfo',
            'getAttachedDocsInfoPayload': {
                'receiverUri': this.state.sharedReceiptData.Receipt.EditUri
            },
            'attachdocumentUrl': '/WebUI/Receiving/FindReceivings/AttachDocsToReceiver?receiverFileSummariesUri=' + this.state.sharedReceiptData.Receipt.FileSummariesUri,
            'removeAttachDocUrl': '/WebUI/Receiving/FindReceivings/DeleteAttachedDoc',
        };

        this.setState({
            sharedAttachObj: sharedAttachObj,
            isOpenAttachDoc: true
        });
    }

    closeAttachDocuments = () => {
        this.setState({ isOpenAttachDoc: false });
    }
    closeConfirmReceiptModal = () => {
        this.setState({ sharedIsOpenConfirmReceiptModal: false });
    }
    clickOnRunViewTransactionReport = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunViewTransactionReport');
    }
    clickOnRunMULabelReport = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunMULabelReport');
    }
    clickOnRunReceiptTicketReport = (e) => {
        this.dynamicServiceForDynamicMenu(e, 'RunReceiptTicketReport');
    }
    dynamicServiceForDynamicMenu = (event, fnName) => {
        var runReport = $(event.target).parent().attr('data-run-report');

        manageReceiptService.dynamicServiceForDynamicMenu(fnName, runReport, this.state.sharedReceiptData.Receipt.ReceiverId, null, 'FindReceivings').then((response) => {
            this.handleResponseMenuPrint(response);
        }).finally(() => { });
    }

    handleResponseMenuPrint = (response) => {
        var blob = ArrayWms.convertBase64ToByteArray(response.Data, 'application/pdf');
        ArrayWms.displayBlobInWindow(blob, 'report.pdf');
    }
    onReceiptDataSet = (param_receiptObj, param_resetDiscardBtn) => {

        let sharedReceiptObj = this.state.sharedReceiptData;
        sharedReceiptObj.Receipt = param_receiptObj;

        let hasChangedReceipt = JSON.stringify(param_receiptObj) != JSON.stringify(this.state.sharedReceiptData)
                                    && (this.isCreating || JSON.stringify(this.emptySharedReceiptData) != JSON.stringify(this.state.sharedReceiptData));

        if (param_resetDiscardBtn) {
            hasChangedReceipt = false;
        }

        this.setState({
            enableSave: hasChangedReceipt,
            sharedReceiptData: Object.assign(
                this.state.sharedReceiptData,
                sharedReceiptObj
            )
        });
    }

    onReceiptOptionSet = (param_receiptObj, param_resetDiscardBtn) => {

        let sharedReceiptObj = this.state.sharedReceiptData;
        sharedReceiptObj = param_receiptObj;

        let tmpEnableSave = true;

        if (param_resetDiscardBtn) {
            tmpEnableSave = false;
        }

        this.setState({
            enableSave: tmpEnableSave,
            sharedReceiptData: Object.assign(
                this.state.sharedReceiptData,
                sharedReceiptObj
            )
        });
    }

    handleChangeForObject = (event) => {
        let ReceiptData = Object.assign({}, this.state.sharedReceiptData.Receipt);
        ReceiptData[event.target.name] = event.target.value;
        this.onReceiptDataSet(ReceiptData);
    }

    saveReceipt = (param_callerId) => {
        //For Ie11
        if (!param_callerId) {
            param_callerId = "default";
            this.setState({ savingGif: true });
        }

        if (param_callerId == 'create_item' || param_callerId == 'create_location') {
            this.setState({ savingGif: true });
        }

        if (param_callerId == 'confirm_receipt') {
            this.setState({ gifConfirm: true });
        }
        if (param_callerId == 'recalculate') {
            this.setState({ sharedGifRecalculate: true });
        }
        if (this.state.isSaved === true) {
            let param_receipt_payload = this.prepareFieldsOnEdit();
            manageReceiptService.UpdateReceipt(param_receipt_payload).then((response) => {

                if (
                    response &&
                    (response.status == 200 || response.status == 204)
                ) {

                    GlobalService.notification('success', this.translate('Label_Receipt_Updated'));
                    this.setState({ enableSave: false });

                    var eventItem = new CustomEvent("EventNewReceiptData", {
                        detail: { "action": "update" }
                    });

                    document.dispatchEvent(eventItem);

                    if (param_callerId == 'confirm_receipt') {
                        this.setState({ sharedIsOpenConfirmReceiptModal: true });
                    }
                    if (param_callerId == 'recalculate') {
                        this.recalculateCharges();
                    }

                    this.setupReceiptDataAfterSave(response.data);

                }
            }).finally(() => {
                this.setState({
                    gifConfirm: false,
                    savingGif: false,
                    sharedGifRecalculate: false
                });
            });
        } else {
            this.$rootScope.setLoaderTimer('Create Receipts');
            let receiptData = this.state.sharedReceiptData.Receipt;
            receiptData.CustomerId = this.state.selectedCustomerId;
            receiptData.FacilityId = this.state.selectedFacilityId;

            let isCustomerUser = (!this.state.Rights.hasOwnProperty('isWarehouseUser')) ? true : false;

            manageReceiptService.SaveReceipts(receiptData, isCustomerUser).then((response) => {
                //set receipt data it's use to open item module
                if (response && (response.status == 200 || response.status == 204)) {

                    GlobalService.notification('success', this.translate('Label_Receipt_Created'));
                    var eventItem = new CustomEvent("EventNewReceiptData", {
                        detail: {
                            "action": "update",
                            "ref": response.data.ReferenceNum,
                            "trans": response.data.ReceiverId,
                            fromWindow: this.state.windowId,
                            "thumbnail": "update"
                        }
                    });
                    document.dispatchEvent(eventItem);
                    $("#" + this.state.windowId + '_wnd_title').text(this.translate('Label_Edit_Receipts'));

                    manageReceiptService.ManageReceiptEditData(response.data.EditUri).then((response) => {

                        if (response && (response.status == 204 || response.status == 200)
                        ) {
                            this.initializeEdit(response.data);
                        }
                    }).finally(() => {
                        this.setState({ savingGif: false });
                    });

                }
            }).catch((error) => {
                this.setState({ savingGif: false });
                console.error("ManageReceiptHolder", error);
            }).finally(() => {
                this.setState({
                    gifConfirm: false
                });
            });
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (this.state.savingGif == false && this.state.isSaved == false) {
                this.saveReceipt();
            }
        }
    }


    prepareFieldsOnEdit = () => {
        let receiptData = Object.assign({}, this.state.sharedReceiptData.Receipt);

        /*MAPPING FOR SAVED ELEMENTS***********************************************************************************/
        // Saved elements could be updated on change for saved elements, however, since we populate predefined elements with a value of null, we have to remove all elements that have a value of null

        var savedElementsGridContents = this.state.sharedReceiptData.ReceiptSE;
        var passToSavedElements = [];
        for (var i = 0; i < savedElementsGridContents.length; i++) {
            if (savedElementsGridContents[i].Value !== '' && savedElementsGridContents[i].Value !== null) {
                passToSavedElements.push(savedElementsGridContents[i]);
            }
        }
        receiptData.SavedElements = passToSavedElements;


        var chargesGridContents = receiptData.BillingCharges;
        receiptData.BillingCharges = ObjectWms.removeObjFromListOnParameterValue(chargesGridContents, "NumberOfUnits", null);

        if (receiptData.Unit1Identifier != null &&
            receiptData.Unit1Identifier.Name == "Select a unit of measure") {
            receiptData.Unit1Identifier = null;
        }

        if (receiptData.Unit2Identifier != null &&
            receiptData.Unit2Identifier.Name == "Select a unit of measure") {
            receiptData.Unit2Identifier = null;
        }

        if (receiptData.Carrier == "Unspecified") {
            receiptData.Carrier = null;
        }

        if (receiptData.CapacityType != null &&
            receiptData.CapacityType.Id == 0) {
            receiptData.CapacityType = null;
        }
        return receiptData;
    }

    setupReceiptDataAfterSave = (param_receipt_Obj) => {
        if (param_receipt_Obj.Unit1Identifier == null) {
            param_receipt_Obj.Unit1Identifier = {};
            param_receipt_Obj.Unit1Identifier.Name = this.state.sharedReceiptData.UnitOfMeasureTypes[0].Name;
        }

        if (this.state.sharedReceiptData.Receipt.Unit2Identifier == null) {
            param_receipt_Obj.Unit2Identifier = {};
            param_receipt_Obj.Unit2Identifier.Name = this.state.sharedReceiptData.UnitOfMeasureTypes[0].Name;
        }

        if (this.state.sharedReceiptData.Receipt.Carrier == null) {
            param_receipt_Obj.Carrier = this.state.sharedReceiptData.Carriers[0].Name;
        }

        if (this.state.sharedReceiptData.Receipt.CapacityType == null) {
            param_receipt_Obj.CapacityType = {};
            param_receipt_Obj.CapacityType.Id = this.state.sharedReceiptData.CapacityTypes[0].CapacityTypeId;
        }

        let sharedReceiptObj = this.state.sharedReceiptData;
        sharedReceiptObj.Receipt = param_receipt_Obj;

        this.setState({
            sharedReceiptData: Object.assign(
                this.state.sharedReceiptData,
                sharedReceiptObj
            ),
            enableSave: false,
        });
    }

    initializeReceiptEditData = (editReceiptData) => {
        var receiptData = editReceiptData.Receipt;
        var UOMTypes = editReceiptData.UnitOfMeasureTypes;
        UOMTypes.splice(0, 0, {
            'Name': this.translate('Label_Select_UOM')
        });

        if (receiptData.CapacityType == null) {
            receiptData.CapacityType = {
                Id: editReceiptData.CapacityTypes[0].CapacityTypeId
            }
        }
        if (receiptData.Carrier == null) {
            receiptData.Carrier = editReceiptData.Carriers[0].Name
        }
        if (receiptData.Unit1Identifier == null) {
            receiptData.Unit1Identifier = {
                Name: UOMTypes[0].Name
            }
        }
        if (receiptData.Unit2Identifier == null) {
            receiptData.Unit2Identifier = {
                Name: UOMTypes[0].Name
            }
        }

        this.setState({
            manageReceiptEditData: editReceiptData,
            sharedReceiptData: Object.assign(this.state.sharedReceiptData, receiptData),
            selectedCustomerId: editReceiptData.Receipt.CustomerId,
            selectedFacility: this.getFacilityById(editReceiptData.Receipt.FacilityId),
            selectedFacilityId: editReceiptData.Receipt.FacilityId,
            enableSave: false,
        });
    }

    recalculateCharges = () => {
        manageReceiptService.getProposedBillingCharges(this.state.sharedReceiptData.Receipt.EditUri).then((response) => {

            if (response && response.status == 200) {

                let sharedReceiptObj = this.state.sharedReceiptData;
                sharedReceiptObj.Receipt.BillingCharges = (response.data) ? response.data : [];

                let hasChangedReceipt = JSON.stringify(sharedReceiptObj) != JSON.stringify(this.state.sharedReceiptData)
                                            && (this.isCreating || JSON.stringify(this.emptySharedReceiptData) != JSON.stringify(this.state.sharedReceiptData));

                this.setState({
                    enableSave: hasChangedReceipt,
                    sharedReceiptData: Object.assign(
                        {},
                        this.state.sharedReceiptData,
                        sharedReceiptObj
                    )
                });
            }

        }).finally(() => {
            this.setState({ sharedGifRecalculate: false });
        });
    }

    lineItemGridChange = (param_grid_data) => {
        this.setState({
            lineItemsGridData: param_grid_data,
            totalLineItems: param_grid_data.length
        });
    }

    onOpenCreateManualChargeModal = (paran_modal_data) => {
        this.setState({
            // sharedCreateManualChargeOptions:paran_modal_data.chargesOptions,
            sharedIsOpenManualChargeModal: true
        });
    }

    onCloseCreateManualChargeModal = () => {
        this.setState({
            sharedIsOpenManualChargeModal: false
        });
    }

    onOpenCustomFieldModal = () => {
        this.setState({
            sharedIsOpenAddNewCustomFieldModal: true
        });
    }

    onCloseCustomFieldModal = () => {
        this.setState({
            sharedIsOpenAddNewCustomFieldModal: false
        });
    }

    onOpenLineItemModal = (flag, param_flow, param_select_item_data) => {
        this.setState({
            sharedIsOpenManageLineItemsModal: flag,
            sharedLineItemFlow: param_flow,
            sharedSelectedLineItem: param_select_item_data
        });
    }

    onAddOrderChargesGrid = (data) => {

        let billingChargesData = (this.state.sharedReceiptData.Receipt.BillingCharges == null) ? [] :
            Object.assign([], this.state.sharedReceiptData.Receipt.BillingCharges);

        var ManualCharge = {
            "ChargeTypeId": data.ChargeTypeId,
            "ChargeOrigin": 2,
            "ChargeTypeDescription": data.ChargeType,
            "ChargeLabel": data.ChargeLabel,
            "UnitDescription": data.Description,
            "ChargePerUnit": data.ChargePerUnit,
            "NumberOfUnits": data.NumberOfUnits,
            "TotalCharges": data.TotalCharges,
            "GeneralLedgerAccount": data.GeneralLedgerAccount,
            "ExternalAccountsReceivable": data.ExternalAccountsReceivable,
            "ExternalItemIdentifier": data.ExternalItemIdentifier,
            "ExternalItemDescription": data.ExternalItemDescription,
            "SKU": "",
            "UnsavedManualCharge": true,
            "SystemGenerated": false
        }


        billingChargesData.push(ManualCharge);

        let sharedReceiptObj = this.state.sharedReceiptData;
        sharedReceiptObj.Receipt.BillingCharges = billingChargesData;

        this.setState({
            enableSave: true,
            sharedReceiptData: Object.assign(
                this.state.sharedReceiptData,
                sharedReceiptObj
            )
        });
    }

    eventCompleteReceipt = () => {
        this.setState({ gifComplete: true });
        manageReceiptService.CompleteReceipt(this.state.sharedReceiptData.Receipt).then((response) => {

            if (response && (response.status == 204 || response.status == 200)) {

                GlobalService.notification('success', this.translate('Label_Success_Complete_Receipt').replace(new RegExp('{' + 'customCompleteLabel' + '}', 'gi'), this.state.customLabels.Label_Complete));

                var eventItem = new CustomEvent("EventNewReceiptData", {
                    detail: { "action": "update" }
                });

                document.dispatchEvent(eventItem);

                let receiptData = Object.assign({}, this.state.sharedReceiptData.Receipt);
                receiptData.Completed = true;
                this.onReceiptDataSet(receiptData);
            }
        }).finally(() => {
            this.setState({ gifComplete: false });
        });
    }


    render() {
        var currentCustomerId = this.state.selectedCustomerId;
        return (
            <div id="create-receipt-holder-wrapper">
                <div className="wms-top-toolbar-wrapper">
                    <div className="wms-tab-header-container" ref={this.tabHeaderRef}>
                        <div className="wms-top-toolbar-btn-wrapper">
                            <div>
                                <WmsButton
                                    label={this.translate('Label_Save')}
                                    wmsSelector={this.state.selector + 'SaveButton'}
                                    onClickFunc={(e) => this.saveReceipt()}
                                    showToolTip={(
                                        this.state.selectedCustomerId == '' ||
                                        this.state.selectedFacilityId == '' ||
                                        this.state.sharedReceiptData.Receipt.ReferenceNum == '' ||
                                        (
                                            (!this.state.Rights.hasOwnProperty('isWarehouseUser')) && this.state.sharedReceiptData.Receipt.Completed
                                        )
                                    )}
                                    tooltipText={
                                        (
                                            (!this.state.Rights.hasOwnProperty('isWarehouseUser')) && this.state.sharedReceiptData.Receipt.Completed
                                        ) ?
                                            this.translate('Label_Line_Item_Cutomer_Access_Note') :
                                            this.translate('Tooltip_Required_Fields')
                                    }
                                    disabled={(
                                        this.state.savingGif ||
                                        this.state.selectedCustomerId == '' ||
                                        this.state.selectedFacilityId == '' ||
                                        this.state.sharedReceiptData.Receipt.ReferenceNum == '' ||
                                        !this.state.enableSave ||
                                        (
                                            (!this.state.Rights.hasOwnProperty('isWarehouseUser')) && this.state.sharedReceiptData.Receipt.Completed
                                        )
                                    )}
                                    loading={this.state.savingGif}
                                    icon='fa-solid fa-floppy-disk'
                                />
                                {
                                    (this.state.Rights.hasOwnProperty('receipts.docs') ||
                                        (
                                            this.state.Rights.hasOwnProperty('receipts.attachdocs') && this.state.Rights.hasOwnProperty('Shipping.AttachedDocsEnabled')
                                        )) && this.state.MenuPrintData.length > 0 && (
                                        <WmsButtonDropdown
                                            id={'MenuPrintTicket'}
                                            windowId={this.state.windowId}
                                            label={this.translate('NavBarV1_Btn_Documents')}
                                            wmsSelector={this.state.selector + "PrintDocuments"}
                                            menuIcon={'fa-print'}
                                            buttonType={'green'}
                                        >
                                            <WmsMenuItem items={this.state.MenuPrintData} />
                                        </WmsButtonDropdown>
                                    )}

                                {/* pending Complete button */}
                                {(
                                    this.state.totalLineItems > 0 &&
                                    !this.state.sharedReceiptData.Receipt.Completed &&
                                    this.state.isReceiptCreated
                                ) &&
                                    <WmsButton
                                        label={this.translate('Label_Markas_Complete').replace(new RegExp('{' + 'customCompleteLabel' + '}', 'gi'), this.state.customLabels.Label_Complete)}
                                        wmsSelector={this.state.selector + 'CompleteButton'}
                                        onClickFunc={this.eventCompleteReceipt}
                                        icon={'fa-check-square-o'}
                                        loading={this.state.gifComplete}
                                        buttonType={'yellow'}
                                    />
                                }

                                {this.state.isReceiptCreated && this.state.Rights.hasOwnProperty('receipts.confirm') &&
                                    <WmsButton
                                        label={this.translate('Label_Confirm_Receipt')}
                                        wmsSelector={this.state.selector + 'ConfirmReceipt'}
                                        onClickFunc={() => this.saveReceipt('confirm_receipt')}
                                        icon={'fa-truck'}
                                        showToolTip={!this.state.gifConfirm}
                                        disabled={(
                                            !this.state.totalLineItems > 0 ||
                                            this.state.gifConfirm ||
                                            this.state.isRequiredCustomReceiptInfo ||
                                            (
                                                (!this.state.Rights.hasOwnProperty('isWarehouseUser')) && this.state.sharedReceiptData.Receipt.Completed
                                            )
                                        )}
                                        tooltipText={
                                            (!this.state.totalLineItems > 0) ?
                                                this.translate('Label_Order_LineItem_Required') :
                                                this.state.isRequiredCustomReceiptInfo ?
                                                    this.translate('Label_Recipt_Custom_Info_Required') :
                                                    (!this.state.Rights.hasOwnProperty('isWarehouseUser')) && this.state.sharedReceiptData.Receipt.Completed ?
                                                        this.translate('Label_Confirm_Receipt_Cutomer_Access_Note')
                                                        : ''
                                        }
                                        buttonType="green"
                                        loading={this.state.gifConfirm}
                                    />
                                }
                            </div>

                            <WmsButton
                                label={this.translate('Label_Cancel')}
                                wmsSelector={this.state.selector + 'ExitButton'}
                                onClickFunc={this.onClickExitForm}
                                icon={'fa-times-circle'}
                                buttonType="orange"
                            />
                        </div>

                        <WmsContainer>
                            <WmsDropdown
                                id="selectedCustomer"
                                name="selectedCustomer"
                                label={this.state.customLabels.Label_Customer}
                                wmsSelector={this.state.selector + "SelectCustomer"}
                                value={this.state.selectedCustomerId}
                                onChangeFunc={this.indexOptionsCustomerChange}
                                required={true}
                                options={this.state.optionsCustomer}
                                valueField="id"
                                textField="name"
                                disabled={this.state.isSaved}
                                blankFirstOption={this.state.selectedCustomerId == ''}
                                windowId={this.state.windowId}
                            />
                            <WmsDropdown
                                id="selectedFacility"
                                name="selectedFacility"
                                label={this.state.customLabels.Label_Warehouse}
                                wmsSelector={this.state.selector + "SelectedFacility"}
                                value={this.state.selectedFacilityId}
                                onChangeFunc={this.handleFaciltiySelectChange}
                                required={true}
                                options={this.state.optionsFacility}
                                textField='name'
                                valueField='id'
                                disabled={this.state.isSaved}
                                windowId={this.state.windowId}
                            />
                            <WmsInput
                                id='ReferenceId'
                                inputName="ReferenceNum"
                                wmsSelector={this.state.selector + 'ReferenceId'}
                                value={this.state.sharedReceiptData.Receipt.ReferenceNum}
                                onKeyPressFunc={this.handleKeyPress}
                                required={true}
                                regularExp={/^[^.\s]/}
                                onChangeFunc={this.handleChangeForObject}
                                label={this.translate('Label_ReferenceID')}
                            />

                            {this.state.isSaved &&
                                <div>
                                    <WmsInput
                                        id='TransNumber'
                                        inputName="TransNumber"
                                        wmsSelector={this.state.selector + 'TransNumber'}
                                        value={this.state.sharedReceiptData.Receipt.ReceiverId}
                                        disabled={true}
                                        onChangeFunc={this.handleChangeForObject}
                                        label={this.translate('Label_Trans_Number')}
                                    />
                                    {currentCustomerId > 0 && (
                                        <WmsComboBox
                                            id={"PoNum" + this.state.windowId}
                                            name="PoNum"
                                            label={this.translate('Label_Purchase_Order')}
                                            isVirtualOptions={true}
                                            optionsLabel={this.translate('Label_Purchase_Order')}
                                            wmsSelector={this.state.selector + 'PurchaseOrder'}
                                            value={this.state.sharedReceiptData.Receipt.PoNum}
                                            onChangeFunc={this.handleChangeForObject}
                                            textField='Name'
                                            valueField='Name'
                                            virtualObj={{
                                                itemHeight: 26,
                                                valueMapper: function (options) {
                                                    var that = options;
                                                    $.ajax({
                                                        url: "/WebUI/Receiving/ManageReceivings/PurchaseOrderDropdownIndexRead",
                                                        type: "POST",
                                                        dataType: "json",
                                                        data: {
                                                            poNum: that.value || "",
                                                            customerId: parseInt(currentCustomerId)
                                                        },
                                                        success: function (data) {
                                                            // data = [0];
                                                            options.success(data);
                                                        }
                                                    });
                                                }
                                            }}
                                            dataSourceObj={{
                                                pageSize: 40,
                                                serverPaging: true,
                                                serverFiltering: true,
                                                type: "aspnetmvc-ajax",
                                                transport: {
                                                    read: {
                                                        url: "/WebUI/Receiving/ManageReceivings/PurchaseOrderDropdownRead",
                                                        type: "POST",
                                                        data: {
                                                            customerId: parseInt(currentCustomerId)
                                                        }
                                                    },
                                                },
                                                schema: {
                                                    data: "Data",
                                                    total: "Total"
                                                }
                                            }} />
                                    )}
                                    <WmsInput
                                        id='ReceiptAdviseNumber'
                                        inputName="ReceiptAdviseNumber"
                                        wmsSelector={this.state.selector + 'ReceiptNumber'}
                                        value={this.state.sharedReceiptData.Receipt.ReceiptAdviseNumber}
                                        onChangeFunc={this.handleChangeForObject}
                                        label={this.translate('Label_Receipt_Number')}
                                    />
                                    <WmsDateTimePicker
                                        id={'ExpectedDate'}
                                        name='ExpectedDate'
                                        wmsSelector={this.state.selector + 'ExpectedDate'}
                                        label={this.translate('Label_Expected_Date')}
                                        onChangeFunc={this.handleChangeForObject}
                                        value={this.state.sharedReceiptData.Receipt.ExpectedDate}
                                        windowId={this.state.windowId}
                                        isDateParseInCustomFormat={true}
                                    />
                                    {this.state.Rights.hasOwnProperty('receipts.edit.backdate') &&
                                        <WmsDateTimePicker
                                            id={'ArrivalDate'}
                                            name='ArrivalDate'
                                            wmsSelector={this.state.selector + 'ArrivalDate'}
                                            label={this.translate('Label_Arrival_Date')}
                                            onChangeFunc={this.handleChangeForObject}
                                            value={this.state.sharedReceiptData.Receipt.ArrivalDate}
                                            windowId={this.state.windowId}
                                            isDateParseInCustomFormat={true}
                                            maxDateTime={this.state.warehouseMaxDateTime}
                                        />
                                    }
                                </div>
                            }
                        </WmsContainer>
                        {this.state.isSaved == true &&
                            <WmsTab sectionRefs={this.state.sectionRefs} scrollAreaSelector={this.scrollDomSelector} tabHeaderRef={this.tabHeaderRef} windowId={this.state.windowId} extraTopPadding={26 + 12} /> // top padding 26 manageReceiptScrollBox margin 12
                        }
                    </div>
                    {this.state.isSaved == true &&
                        <div className="wms-tablisting-holder">
                            <div id="manageReceiptScrollBox" className={this.state.windowId + " tab-content"}>

                                <ManageReceiptTransportInformation
                                    divRefs={this.transportInformationRef}
                                    windowId={this.state.windowId}
                                    selector={this.state.selector}
                                    customLabels={this.state.customLabels}
                                    onReceiptDataSet={this.onReceiptDataSet}
                                    sharedReceiptData={this.state.sharedReceiptData}
                                />
                                {this.state.showCustomReceiptInfo &&
                                    <ManageReceiptCustomReceiptInfo
                                        divRefs={this.customReceiptInfoRef}
                                        windowId={this.state.windowId}
                                        selector={this.state.selector}
                                        sharedReceiptData={this.state.sharedReceiptData}
                                        onOpenCustomFieldModal={this.onOpenCustomFieldModal}
                                        onReceiptDataSet={this.onReceiptDataSet}
                                        onReceiptOptionSet={this.onReceiptOptionSet}
                                        onCustomReceiptInfoSave={this.onCustomReceiptInfoSave}
                                    />
                                }
                                {this.state.selectedCustomer != null && !$.isEmptyObject(this.state.selectedCustomer.options) &&
                                    <ManageReceiptLineItems
                                        divRefs={this.lineItemsRef}
                                        windowId={this.state.windowId}
                                        selector={this.state.selector}
                                        sharedReceiptData={this.state.sharedReceiptData}
                                        selectedCustomer={this.state.selectedCustomer}
                                        selectedFacility={this.state.selectedFacility}
                                        onReceiptDataSet={this.onReceiptDataSet}
                                        onLineItemGridChange={this.lineItemGridChange}
                                        sharedTotalLineItems={this.state.totalLineItems}
                                        onSaveReceipt={this.saveReceipt}
                                        onOpenLineItemModal={this.onOpenLineItemModal}
                                        sharedShowCustomReceiptInfo={this.state.showCustomReceiptInfo}
                                        sharedIsResetLineItemGrid={this.state.sharedIsResetLineItemGrid}
                                    />
                                }
                                {this.state.Rights.hasOwnProperty('receipts.charges.manage') && (
                                    <ManageReceiptCharges
                                        divRefs={this.receiptChargesRef}
                                        windowId={this.state.windowId}
                                        selector={this.state.selector}
                                        sharedReceiptData={this.state.sharedReceiptData}
                                        sharedTotalLineItems={this.state.totalLineItems}
                                        onOpenCreateManualChargeModal={this.onOpenCreateManualChargeModal}
                                        onSaveReceipt={this.saveReceipt}
                                        sharedGifRecalculate={this.state.sharedGifRecalculate}
                                        onReceiptDataSet={this.onReceiptDataSet}
                                    />
                                )}
                            </div>
                            {/* create manual charge modal for orders calculate charges section  */}
                            {this.state.sharedIsOpenManualChargeModal &&
                                <CreateManualChargeModel
                                    sharedIsOpenManualChargeModal={this.state.sharedIsOpenManualChargeModal}
                                    sharedManualChargeOptions={this.state.sharedReceiptData.ChargeTypeCategories}
                                    onCloseAddManualChargeModel={this.onCloseCreateManualChargeModal}
                                    onAddShipAndCloseGridData={this.onAddOrderChargesGrid}
                                    isAnotherModelOpen={false}
                                    windowId={this.state.windowId}
                                />
                            }

                            {this.state.isOpenAttachDoc &&
                                <AttachDocuments
                                    isOpenAttachDoc={this.state.isOpenAttachDoc}
                                    sharedAttachObj={this.state.sharedAttachObj}
                                    windowId={this.state.windowId}
                                    onClose={this.closeAttachDocuments}
                                />
                            }

                            {this.state.sharedIsOpenConfirmReceiptModal &&
                                <ConfirmReceiptModal
                                    shareReceipt={this.state.sharedReceiptData.Receipt}
                                    sharedSelectedCustomer={this.state.selectedCustomer}
                                    sharedIsOpenConfirmReceiptModal={this.state.sharedIsOpenConfirmReceiptModal}
                                    onClose={this.closeConfirmReceiptModal}
                                    windowId={this.state.windowId} />
                            }

                            {/* modal use in ManageReceiptCustomReceiptInfo component */}
                            {this.state.sharedIsOpenAddNewCustomFieldModal &&
                                <AddNewCustomFieldModal
                                    windowId={this.state.windowId}
                                    sharedIsOpenAddNewCustomFieldModal={this.state.sharedIsOpenAddNewCustomFieldModal}
                                    onReceiptDataSet={this.onReceiptDataSet}
                                    sharedReceiptData={this.state.sharedReceiptData}
                                    onReceiptOptionSet={this.onReceiptOptionSet}
                                    onCloseCustomFieldModal={this.onCloseCustomFieldModal}
                                />
                            }

                            {this.state.sharedIsOpenManageLineItemsModal &&
                                <ManageLineItemsModal
                                    selector={this.state.selector}
                                    windowId={this.state.windowId}
                                    sharedIsOpenManageLineItemsModal={this.state.sharedIsOpenManageLineItemsModal}
                                    onCloseModal={(e) => this.onOpenLineItemModal(false, '', {})}
                                    selectedCustomer={this.state.selectedCustomer}
                                    selectedFacility={this.state.selectedFacility}
                                    sharedReceiptData={this.state.sharedReceiptData}
                                    onReceiptDataSet={this.onReceiptDataSet}
                                    sharedLineItemFlow={this.state.sharedLineItemFlow}
                                    sharedSelectedLineItem={this.state.sharedSelectedLineItem}
                                    onSaveReceipt={this.saveReceipt}
                                    refreshGrid={this.refreshLineItemGrid}
                                    onResetLineItemGrid={this.resetLineItemGrid}
                                    lineItemsGridData={this.state.lineItemsGridData}
                                    totalLineItems={this.state.totalLineItems}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}
