
import React from 'react';
import { ProtoMassAddGrid } from './proto-mass-add-grid.component.jsx';
import { ProtoMassAddSearch } from './proto-mass-add-search.component.jsx';

import style from './proto-mass-add.scss';

import * as GlobalService from '../../../../global_services';
import * as metaDataService from './meta-data.service.js';

export class ProtoMassAddHolder extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            Selector: "ProtoMassAdd",
            windowId: 'proto-mass-add',
            customLabels: GlobalService.getCustomLabels(),
            sharedInventoryFilterObj: null,
            loadConfig: false,
            sharedFilterAreaToggle: false
        }
    }

    async componentDidMount() {

        kendo.ui.progress($('#proto-mass-add'), true);
        $('#proto-mass-add-holder-react').closest('.k-window-content').css( "padding", "0" );

        await metaDataService.getUserClientMetaData();

        // var filterData = metaDataService.getMassAddFilterMetaData();

        this.setState({
            // sharedInventoryFilterObj: filterData,
            loadConfig:true
        });

    }

    componentWillUnmount(){
        $('#proto-mass-add-holder-react').closest('.k-window-content').css( "padding", "26" );
    }

    onInventoryFilterObjChange = (param_inventoryFilterObj) => {
        this.setState({
            sharedInventoryFilterObj: Object.assign({}, param_inventoryFilterObj)
        }, () => {
            // metaDataService.setMassAddFilterMetaData(param_inventoryFilterObj);
            this.gridComponent.loadGridData();
        });
    }

    onRefreshGrid = () => {
        this.gridComponent.loadGridData();
    }

    clickForFilterToggle = () => {
        this.setState({
            filterAreaToggle: !this.state.filterAreaToggle
        });
        this.resizeWindow();
    }

    resizeWindow =() =>{
        $(window).trigger('resize');
    }

    render() {
        return (
            <div id="proto-mass-add-holder-react" className={`${ this.state.filterAreaToggle ? " collapsedFilter " : "" } is-proto-mass`} >
                {this.state.loadConfig && (
                    <ProtoMassAddSearch
                        onInventoryFilterObjChange={this.onInventoryFilterObjChange}
                        sharedInventoryFilterObj={this.state.sharedInventoryFilterObj}
                        onRefreshGrid={this.onRefreshGrid}
                        windowId={this.state.windowId}
                        onClickForFilterToggle={this.clickForFilterToggle}
                    />
                )}
                {this.state.loadConfig && (
                    <ProtoMassAddGrid
                        sharedEditAllocationData={this.props.sharedEditAllocationData}
                        ref={instance => { this.gridComponent = instance; }}
                        windowId={this.state.windowId}
                        sharedInventoryFilterObj={this.state.sharedInventoryFilterObj}
                        onResetGridAndSelection={this.props.onResetGridAndSelection}
                        onGridItemChange={this.props.onGridItemChange}
                    />
                )}
            </div >
        )

    }
}