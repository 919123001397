var axios = require("axios");

export const getCustomerDropDown = function () {
    return axios.get("/WebUI/Customers/WebHooksConfig/GetCustomerDropDown").then(function (response) {
        return response;
    });
};
export const getEventNotificationGridData = function (cstEditUri) {
    let url = "/WebUI/Customers/WebHooksConfig/GridRead";
    // let url = '/assets/json/event-norification.json';
    if (cstEditUri) {
        url = url + "?editUri=" + cstEditUri;
    }
    return axios.get(url).then(function (response) {
        return response;
    });
};

export const saveEventNotificationGridData = function (cstEditUri, data) {
    let url = "/WebUI/Customers/WebHooksConfig/GridSave";
    if (cstEditUri) {
        url = url + "?editUri=" + cstEditUri;
    }
    return axios.put(url, data).then(function (response) {
        return response;
    });
};
