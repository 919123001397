
var axios = require('axios');

export const recalcPackages = function (orderIds) {
    return axios.put('/WebUI/Shipping/FindOrders/PackagesGenerator', orderIds)
        .then(function (response) {
            return response.data;
        });
}
export const parcelFinalizer = function (ids) {
    return axios.put('/WebUI/Shipping/FindOrders/ParcelFinalizer', ids)
        .then(function (response) {
            return response;
        });
}
export const parcelAutoRateShopper = function (ids) {
    return axios.post('/WebUI/Shipping/FindOrders/ParcelAutoRateShopper', ids)
        .then(function (response) {
            return response;
        });
}
export const parcelCanceler = function (ids) {
    return axios.put('/WebUI/Shipping/FindOrders/ParcelCancelerStarter', ids)
        .then(function (response) {
            return response;
        });
}
export const parcelReseter = function (ids) {
    return axios.post('/WebUI/Shipping/FindOrders/ParcelReseter', ids)
        .then(function (response) {
            return response;
        });
}
export const parcelVoidCanceler = function (ids) {
    return axios.post('/WebUI/Shipping/FindOrders/ParcelVoidCanceler', ids)
        .then(function (response) {
            return response;
        });
}
export const parcelVoidDbClear = function (ids) {
    return axios.post('/WebUI/Shipping/FindOrders/ParcelVoidDbClear', ids)
        .then(function (response) {
            return response;
        });
}
export const reprintOrReturnZpl = function (uri, ids) {
    return axios.post(uri, { OrderIds: ids })
        .then(function (response) {
            return response;
        });
}
export const reprintOrReturnPdf = function (uri) {
    return axios.get(uri, { responseType: 'blob' })
        .then(function (response) {
            return response;
        });
}

export const getZplFormattedLabels = function (orderIds, includeReturnLabel, partner) {
    let data = {
        orderIds: orderIds,
        includeReturnLabels: includeReturnLabel,
        partner: partner
    }
    return axios.put('/WebUI/Shipping/SmartParcel/ZplFormattedShippingLabelsStarter', data)
        .then(function (response) {
            return response;
        });
}

export const getPdfFormattedLabels = function (orderIds, customerId, labelFormat, runnerKey, includeReturnLabel, partner) {
    let data = {
        orderIds: orderIds,
        customerId: customerId,
        labelFormat: labelFormat,
        templatedRunnerKey: runnerKey,
        includeReturnLabels: includeReturnLabel,
        partner: partner,
    }
    return axios.put('/WebUI/Shipping/SmartParcel/PdfFormattedShippingLabelsStarter', data)
        .then(function (response) {
            return response;
        });
}


export const orderInfo = function (orderId) {
    return axios.all([
        axios.get('/WebUI/Shipping/SmartParcel/OrderInfo?orderId=' + orderId),
        axios.get('/WebUI/Shipping/SmartPack/OrderPackInfo?orderId=' + orderId)
    ]).then(axios.spread(function (orderInfoResponse, orderPackInfoResponse) {
        return {
            orderInfoResponse: orderInfoResponse.data,
            orderPackInfoResponse: orderPackInfoResponse.data
        };
    }));
}

export const getOrderInfo = function (orderId) {
    return axios.get('/WebUI/Shipping/SmartParcel/OrderInfo?orderId=' + orderId)
        .then(function (response) {
            return response.data;
        });
}

export const getCarriersAndCapacityTypes = function (customerId, facilityId) {
    return axios.get('/WebUI/Shipping/FindOrders/CarriersAndCapacityTypes?customerId=' + customerId + '&facilityId=' + facilityId)
        .then(function (response) {
            return response.data;
        });
}


export const deletePackage = function (packageUri) {
    return axios.delete('/WebUI/Shipping/SmartPack/Package?packageUri=' + packageUri)
        .then(function (response) {
            return response;
        });
}
export const deletePackageContent = function (packageContentUri) {
    return axios.delete('/WebUI/Shipping/SmartPack/PackageContent?packageContentUriList=' + packageContentUri)
        .then(function (response) {
            return response;
        });
}

export const createShippingLabelsHighVolumeBatch = function (orderIds, batchName, labelFormat) {
    let data = {
        orderIds: orderIds,
        batchName: batchName,
        labelFormat: labelFormat
    }
    return axios.put('/WebUI/Shipping/SmartParcel/ShippingLabelsHighVolumeBatch', data)
        .then(function (response) {
            return response;
        });
}

export const initialGetShippingInfoData = function (orderIds, smallParcelSelectObj) {
    let shippingInfoDataParam = {
        orderIds: orderIds,
        customerId: smallParcelSelectObj.CustomerId
    }

    return axios.all([
        axios.put('/WebUI/Shipping/SmartParcel/ShippingInfoData', shippingInfoDataParam),
        axios.get('/WebUI/Settings/SmartParcel/CarrierAccountData?customerUri=/customers/' + smallParcelSelectObj.CustomerId),
        axios.get('/WebUI/Shipping/FindOrders/CarriersAndCapacityTypes?customerId=' + smallParcelSelectObj.CustomerId + '&facilityId=' + smallParcelSelectObj.FacilityId),
    ]).then(axios.spread(function (shippingInfoDataResponse, carrierAccountsResponse, carriersAndCapacityTypesResponse) {
        return {
            shippingInfoDataResponse: shippingInfoDataResponse.data,
            carrierAccountsResponse: carrierAccountsResponse.data,
            carriersAndCapacityTypesResponse: carriersAndCapacityTypesResponse.data
        };
    }));
}


export const shippingInfoUpdater = function (orderIds, shippingInfoObj) {
    let data = {
        orderIds: orderIds,
        shippingInfo: shippingInfoObj
    }
    return axios.put('/WebUI/Shipping/SmartParcel/ShippingInfoUpdater', data)
        .then(function (response) {
            return response;
        });
}

export const orderScanResolver = function (facilityId, scannedValue) {
    return axios.get('/WebUI/Shipping/SmartPack/OrderScanResolver?facilityId=' + facilityId + '&scannedValue=' + encodeURIComponent(scannedValue))
        .then(function (response) {
            return response.data;
        });
}

export const getPackageFromMT = function (packageUri) {
    return axios.get('/WebUI/Shipping/SmartPack/Package?packageUri=' + packageUri)
        .then(function (response) {
            return response;
        });
}

export const addItemToPackage = function (packageContent) {
    return axios.post('/WebUI/Shipping/SmartPack/Package', packageContent)
        .then(function (response) {
            return response;
        });
}

export const addItemToPackageContent = function (packageContent) {
    return axios.post('/WebUI/Shipping/SmartPack/PackageContent', packageContent)
        .then(function (response) {
            return response;
        });
}

export const updatePackage = function (objPackage) {
    return axios.put('/WebUI/Shipping/SmartPack/Package', objPackage)
        .then(function (response) {
            return response;
        });
}

export const orderPackEvent = function (orderId, eventType) {
    return axios.post('/WebUI/Shipping/SmartParcel/OrderPackEvent?orderId='+orderId+'&eventType='+eventType+'&source=smart-ui')
        .then(function (response) {
            return response;
    });
}

export const updateItemToPackageContent = function (packageContent) {
    return axios.put('/WebUI/Shipping/SmartPack/PackageContent', packageContent)
        .then(function (response) {
            return response;
        });
}

export const lineScanResolver = function (customerId, scanValue) {
    return axios.get('/WebUI/Shipping/SmartPack/LineScanResolver?customerId=' + customerId + '&scannedValue=' + scanValue)
        .then(function (response) {
            return response;
        });
}

export const completePackage = function (orderUri) {
    var dataObj = {
        "orderUri": orderUri
    }
    return axios.put('/WebUI/Shipping/SmartPack/Completer', dataObj)
        .then(function (response) {
            return response;
        });
}
export const autoPrintForIndividualPackage = function (payload) {
    return axios.put('/WebUI/Shipping/SmartPack/ZplFormattedUccLabel', payload)
        .then(function (response) {
            return response;
        });
}
export const autoPrintForAllPackage = function (payload) {
    return axios.put('/WebUI/Shipping/SmartPack/ZplFormattedUccLabels', payload)
        .then(function (response) {
            return response;
        });
}

export const deleteAllPackage = function (orderId) {
    return axios.delete('/WebUI/Shipping/SmartPack/Packages?orderId=' + orderId)
        .then(function (response) {
            return response;
        });
}


export const getOrderPackInfo = function (orderId) {
    return axios.get('/WebUI/Shipping/SmartPack/OrderPackInfo?orderId=' + orderId)
        .then(function (response) {
            return response.data;
        });
}

export const getAllPackage = function () {
    return axios.get('/WebUI/Shipping/PackageDefs/PackageDefsData')
        .then((response) => {
            return response;
        });
}

export const initialAvailableRates = function (param_customerId, ids) {

    let editRoutingInfoDataParam = {
        orderIds: ids,
        customerId: param_customerId
    }

    return axios.put('/WebUI/Shipping/FindOrders/MassEditRoutingData', editRoutingInfoDataParam)
        .then(function (response) {
            return response;
        });
}

export const updateRateCarrierAndServices = function (param_rouingdata) {
    return axios.put('/WebUI/Shipping/FindOrders/MassEditRoutingDataUpdater', param_rouingdata)
        .then(function (response) {
            return response;
        });
}

export const getOrderOptionsData = function (orderId) {
    let params = {
        "orderId": orderId,
        "getProposedBilling": true,
        "getParcelOptions": true
    };
    return axios.post('/WebUI/Shipping/Orders/OrderOptions', params)
        .then(function (response) {
            return response.data;
        });
};
export const updateAddressDetail = function (oldOrderData, updatedOrderData) {
    let params = {
        "oldOrderData": oldOrderData,
        "updatedOrderData": updatedOrderData,
        "updateParcelOptions": true
    };
    return axios.put('/WebUI/Shipping/Orders/Order', params)
        .then(function (response) {
            return response;
        });
};
export const removeFromBatch = function (batchData) {
    return axios.post('/WebUI/Shipping/FindOrders/RemoveFromBatch', batchData).then(function success(response) {
        return response;
    });
}

export const getReferenceFields = function (pkgUri) {
    return axios.get(`/WebUI/Shipping/SmartParcel/LabelReferences?packageuri=${pkgUri}`)
        .then(function (response) {
            return response.data;
        });
}

export const setReferenceFields = function (pkgUri, params) {
    return axios.put(`/WebUI/Shipping/SmartParcel/LabelReferences?packageuri=${pkgUri}`, params)
        .then(function (response) {
            return response;
        });
}

export const setParcelContainerFinalizer = function(params) {
    return axios.post(`/WebUI/Shipping/FindOrders/ParcelContainerFinalizer`, params)
        .then(function (response) {
            return response;
        });
}

export const manifestStarter = function (orderIds, customerId) {
    let data = {
        orderIds: orderIds,
        customerId: customerId,
    }
    return axios.put('/WebUI/Shipping/SmartParcel/ManifestStarter', data)
        .then(function (response) {
            return response;
        });
}

export function getCustomersSettings(customerIds) {
    return axios.put("/WebUI/Settings/SmartParcel/CustomersSettings", customerIds)
        .then((response) => {
            return response;
        });
}

export const packNonSkuInserts = function (orderId, insertId, insertedQty) {
    return axios.put('/WebUI/Mobile/Pack/PackageInsertQty?orderId='+orderId+'&insertId='+insertId+'&qty='+insertedQty+'&insertedQty='+insertedQty)
        .then(function (response) {
            return response;
    });
}

export const deletePackNonSkuInsertsAsync = async function (orderId, insertId, insertedQty) {
    return await axios.put('/WebUI/Mobile/Pack/PackageInsertQty?orderId='+orderId+'&insertId='+insertId+'&qty='+insertedQty+'&insertedQty='+insertedQty);
}

export function parcelAutoRateShopperStarter(dataObj) {
    return axios.put("/WebUI/Shipping/SmartParcel/ParcelAutoRateShopperStarter", dataObj)
        .then((response) => {
            return response;
        });
}

export const parcelAutoRateShopperStatus = function (passuri) {
    return axios.get('/WebUI/'+passuri).then(function (response) {
        return response;
    });
}