import React, { Component } from "react";

// Component
import WmsButton from "../components/WmsButton/WmsButton.jsx";
import WmsDropdown from "../components/WmsDropdown/WmsDropdown.jsx";
import WmsCollapse from "../components/WmsCollapse/WmsCollapse.jsx";
import WmsContainer from "../components/WmsContainer/WmsContainer.jsx";
import WmsTextArea from "../components/WmsTextArea/WmsTextArea.jsx";
import WmsInput from "../components/WmsInput/WmsInput.jsx";
import axios from "axios";

// Services
import * as GlobalService from "./../global_services";


import style from "./api-simulator.scss";

export class ApiSimulator extends Component {
    constructor(props) {
        super(props);

        this.translate = window.translate
        this.$rootScope = window;

        this.state = {
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            url:'',
            guid:'',
            selectedType:'JSON',
            selectedMethod:'GET',
            requestBody:'',
            responseData:'',
            responseTime:'0',
            responseCode:'0'
        }
    }

    componentDidMount() {
    }

    handleChangeEvent = (e) =>{
        this.setState({ [e.target.name]: e.target.value });
    }
    isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    submitUrl = () => {

        if (!this.isJson(this.state.requestBody) && this.state.requestBody != '') {
            GlobalService.notification('error', this.translate('Settings_Label_Import_PreLabel_service_jsonFormatMessagef_Save_Success'));
            return false;
        }
        var method = this.state.selectedMethod;
        console.log("method",method);
        axios({method:this.state.selectedMethod,url:this.state.url,data: (this.state.requestBody != '') ? JSON.parse(this.state.requestBody) : {} })
            .then( (response) => {
                if (typeof response != undefined) {

                    var time = response.config.responseTimestamp - response.config.requestTimestamp;
                    console.log('Time taken ' + (time) + ' ms.');
                    this.setState({
                        responseData:JSON.stringify(response, undefined, 2),
                        responseTime: time + ' ms.',
                        responseCode: response.status
                    });
                }
            });
    }
    test400 =  () => {
        axios({
            method:'GET',
            url:"/WebUI/Shared/GlobalServices/TestErrorHandling?statusCode=2"
        }).then(function (response) {
            return response
        });
    }

    test500a = () => {
        axios({
            method:'GET',
            url: "/WebUI/Shared/GlobalServices/TestErrorHandling?statusCode=3"
        }).then( (response) => {
            return response;
        });
    }
    /* istanbul ignore next */
    test500b =  () => {

        axios({
            method:'GET',
            url: "/WebUI/Shared/GlobalServices/TestErrorHandling?statusCode=4"
        }).then( (response) => {
            return response;
        });
    }
    testCallOrdersMethod = () => {
        axios({
            method:'POST',
            url: '/WebUI/Shipping/UpdateSession/GridExporter',
            data: {
                "sorts": "",
                "filters": '[{"member":"Customer","operator":"Contains","value":"Cus"}]',
                "columns": [{
                    "HeaderName": "Customer",
                    "ModelField": "CustomerIdentifier.Name"
                }]
            }
        }).then( (response) => {
            // this callback will be called asynchronously
            // when the response is available
        })

    }
    testCallOrdersMethod2 = () => {
        axios({
            method:'GET',
            url: '/WebUI/Shipping/UpdateSession/GridExporter?guid=' +this.state.guid,
            data: {
                "sorts": "",
                "filters": '[{"member":"Customer","operator":"Contains","value":"Cus"}]',
                "columns": [{
                    "HeaderName": "Customer",
                    "ModelField": "CustomerIdentifier.Name"
                }]
            }
        }).then( (response) => {
            // this callback will be called asynchronously
            // when the response is available
        })
    }

    render() {
        return (
            <div className="api-simulator-wrapper">
                <p> API Simulator</p>
                <div className="simulator-section">
                    <WmsCollapse id={'apiSimulatorRequestSection'} headerLabel={'Request section'} showCollapseIcon={true} showCollapseText={false} >
                        <WmsContainer>
                        <WmsInput
                            id='url'
                            inputName='url'
                            wmsSelector={'url'}
                            onChangeFunc={this.handleChangeEvent}
                            label={'Url'}
                            value={this.state.url} />
                            <div className="options-wrapper">
                                <WmsDropdown
                                    id="selectedMethod"
                                    name="selectedMethod"
                                    label={this.translate('Label_service_method')}
                                    wmsSelector={"selectedMethod"}
                                    onChangeFunc={this.handleChangeEvent}
                                    value={this.state.selectedMethod}
                                    options={[
                                        {value:'GET',name:this.translate('Label_service_get')},
                                        {value:'POST',name:this.translate('Label_service_post')},
                                        {value:'PUT',name:this.translate('Label_service_put')},
                                        {value:'DELETE',name:this.translate('Label_service_delete')}
                                    ]}
                                    valueField='value'
                                    textField='name'/>

                                <WmsDropdown
                                    id="selectedType"
                                    name="selectedType"
                                    label={this.translate('Label_service_type')}
                                    wmsSelector={"selectedType"}
                                    onChangeFunc={this.handleChangeEvent}
                                    value={this.state.selectedType}
                                    options={[
                                        {value:'JSON',name:this.translate('Label_service_JSON_type')}
                                    ]}
                                    valueField='value'
                                    textField='name'
                                    parentClassName='form_group'/>
                            </div>
                            <div className="wms-request-params">
                                <div className={"wms-text-area-component"}>
                                    <label>Request Params</label>
                                    <textarea
                                        id='requestBody'
                                        name="requestBody"
                                        value={this.state.requestBody}
                                        data-wms-selector={'requestBody'}
                                        placeholder='Enter data in JSON format like- { "key":value, "Key2":value2}'
                                        onChange={this.handleChangeEvent}
                                        rows={10}
                                        cols={10}>
                                    </textarea>
                                </div>
                            </div>
                            <WmsButton
                                label={this.translate("Label_service_submit")}
                                wmsSelector={"apiSubmit"}
                                onClickFunc={this.submitUrl}
                                icon=""
                                disabled={!this.state.url} />
                    </WmsContainer>
                    </WmsCollapse>
                    <WmsCollapse id={'apiSimulatorResponseSection'} headerLabel={'Response section'} showCollapseIcon={true}  showCollapseText={false}>
                        <WmsContainer className="response-section">
                            <div className="response-info-wrapper">
                                <label className="wms-section-title">
                                {this.translate('Label_service_response')} -
                                    <span>{this.state.responseTime}</span>
                                </label>
                                <label className="wms-section-title">
                                {this.translate('Label_service_responseCode')} -
                                    <span>{this.state.responseCode}</span>
                                </label>
                            </div>

                            <div className="response-object">
                                <div className={"wms-text-area-component " + this.props.parentClassName}>
                                    <textarea
                                        id='responseData'
                                        name="responseData"
                                        value={this.state.responseData}
                                        data-wms-selector={'responseData'}
                                        onChange={(e) => {}}
                                        rows={10}
                                        cols={10}>
                                    </textarea>
                                </div>
                            </div>
                        </WmsContainer>
                    </WmsCollapse>
                </div>
                <div className="wms-click-btn-block">
                    <div className="display-inline">
                        <WmsButton
                            label={'400'}
                            wmsSelector={"400"}
                            onClickFunc={this.test400}/>
                        <WmsButton
                            label={'500'}
                            wmsSelector={"500"}
                            onClickFunc={this.test500a}/>
                        <WmsButton
                            label={'500'}
                            wmsSelector={"500"}
                            onClickFunc={this.test500b}/>

                        <WmsInput
                            id='guid'
                            inputName='guid'
                            wmsSelector={'guid'}
                            onChangeFunc={this.handleChangeEvent}
                            value={this.state.guid}
                            label={''} />

                        <a href={"http://localhost/WebUI/Receiving/FindReceivings/GridExporterData?guid="+this.state.guid}>Download me</a>
                    </div>
                    <div className="display-inline">
                        <WmsButton
                            wmsSelector={"500"}
                            onClickFunc={this.testCallOrdersMethod}
                            label="Test Call Orders"/>
                        <WmsButton
                            wmsSelector={"500"}
                            onClickFunc={this.testCallOrdersMethod2}
                            label="Test Call 2 Orders"/>
                    </div>
                </div>
            </div>
        );
    }
}