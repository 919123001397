import React from 'react';
import PropTypes from 'prop-types';
import WmsButton from '../WmsButton/WmsButton.jsx';
import style from './WmsComboboxWithTag.scss';

class WmsComboboxWithTag extends React.PureComponent {

    constructor(props) {
        super(props);
        this.windowId = this.props.windowId == "" ? "" : "." + this.props.windowId;
        this.inputTagId = this.windowId + " #" + this.props.id;
        this.comboBoxTag = ''
        this.state = {
            selectItemObj:[],
            staticOptions:[]
        }
    }
    componentDidMount() {

        $(this.inputTagId).kendoComboBox({
            dataTextField: this.props.textField,
            dataValueField: this.props.valueField,
            filter: "contains",
            noDataTemplate:false,
            dataSource: [],
            change: (e) => {  
                // console.log(" change event fire :",e);
                var txtContainsId = this.props.id + "_listbox";
                let inputVal = $("input[aria-owns*='"+txtContainsId+"']").val();  
                console.log(" inputVal",inputVal);
                console.log(" this.comboBoxTag.value()",this.comboBoxTag.value());
                if(this.comboBoxTag.value() && inputVal == this.comboBoxTag.value()) {

                    let selectTagVal =  this.comboBoxTag.value();                    
                    this.setSelectValue(selectTagVal);                   
                   
                    //remove selected value from options 
                    let staticOptions = Object.assign([], this.state.staticOptions);
                    _.remove(staticOptions, obj => ( obj[this.props.valueField] == selectTagVal ) );

                    this.setState({ staticOptions:staticOptions });

                    setTimeout(() => {
                        this.comboBoxTag.setDataSource(staticOptions);
                    }, 100);                    
                }
             }
        });
       

        this.comboBoxTag = $(this.inputTagId).data("kendoComboBox");   

        var txtContainsId = this.props.id + "_listbox";
        let that =this;
        $("input[aria-owns*='"+txtContainsId+"']").on("keydown", function (el, e) {

            let inputVal = $("input[aria-owns*='"+txtContainsId+"']").val();     
            console.log("inputVal ",inputVal )
            if(inputVal) {

                if(e.which == 32 || e.which == 9 ) {
                    e.preventDefault();
                    e.stopPropagation();                
                    console.log("setSelectValue 2");                    
                    that.setSelectValue(inputVal);
                }
            }
        }.bind(null, this.comboBoxTag));

    }

    setSelectValue = (param_val) => {
        console.log("Need to call only one time :",param_val);
        let tmpTagsArr = _.cloneDeep(this.props.valueArray);
        let inputTagVal= param_val ;                        
        let position = tmpTagsArr.indexOf(inputTagVal);                       
        
        if(position == -1) {

            tmpTagsArr.push(inputTagVal);
            this.props.onChangeFunc(tmpTagsArr);
            setTimeout(() => {
                this.comboBoxTag.value('');
            }, 100);

        } 
    }

    removeSelectedItem = (tag_name) => (e) => {

        let tmpValArr = _.cloneDeep(this.props.valueArray);
        let staticOptions = _.cloneDeep(this.state.staticOptions);
        let position = tmpValArr.indexOf(tag_name);
        
        // add remove value in combobox options
        staticOptions.push({'name':tag_name });
        this.setState({ staticOptions:staticOptions });
        tmpValArr.splice(position, 1);

        this.comboBoxTag.setDataSource(staticOptions);
        this.props.onChangeFunc(tmpValArr);
    }

    render() {
        return (
            <div className={"wms-input-tag-component " + this.props.extraWrapperClass}>
                {this.props.label != '' &&
                    <label className="wms-form-label" data-wms-identifier="Shipping_Label_Contacts" htmlFor={this.props.id}>{this.props.label}</label>
                }
                <input id={this.props.id}
                    name={this.props.name}
                    data-wms-selector={this.props.wmsSelector}
                />
                <div className="selected-values">
                    {this.props.valueArray.map((item, index) => (
                        <WmsButton
                        key={index}
                        label={item}
                        onClickFunc={this.removeSelectedItem(item)}
                        wmsSelector={index+'_selectedSku_'+this.props.id}
                        icon='fa-times-circle-o'
                        buttonType='yellow' />
                    ))}
                </div>
            </div>
        );
    }

}
WmsComboboxWithTag.propTypes = {
    id: PropTypes.string.isRequired, // this should write the id to the input,
    name: PropTypes.string, // this should write the id to the input,
    windowId: PropTypes.string, // this should be used to get window id
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    extraWrapperClass: PropTypes.string,
    valueArray: PropTypes.array, // this should control the value ,it`s string or number
    label: PropTypes.string.isRequired,// dropdown title
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up

}
WmsComboboxWithTag.defaultProps = {
    extraWrapperClass: '',
    label: '',
    windowId: '',
    duplicateTagTooltipText:'',
    textField:'name',
    valueField:'name',
}
export default WmsComboboxWithTag;