import React, { useState, useEffect } from "react";
import "./laborAnalytics.scss";
import * as GlobalService from "../react/global_services";
import * as laborAnalyticsDashboardServices from "./labor-analytics-dashboard.service";
import _ from "lodash";
declare var window: any;
const { $ } = window;

interface Props {}

const LaborAnalyticsDashboard: React.FC<Props> = (props: Props): any => {
    const [IframeUrl, setIframeUrl] = useState("");
    const iframeId = "LaborAnalyticsDashboardIframe";
    // const wmsSelector = "LaborAnalyticsDashboard";

    useEffect(() => {
        let postParams = _.cloneDeep(GlobalService.userKeysUrlStringForDashboardPost);
        postParams["dashboard_name"] = "labor_analytics";
        postParams["parameter_list"] = {
            "param1": "value1",
            "param2": "value2",
        };

        laborAnalyticsDashboardServices.getLaborAnalyticsDashboardURL(postParams).then((response: any) => {
            if (response.EmbedUrl) {
                setIframeUrl(response.EmbedUrl);
            }
        });
    }, []);

    const iframeLoad = () => {
        $("#" + iframeId + "Loader").hide();
    };

    return (
        <div className="iframe-controller">
            <div className="k-loading-mask">
                <div id={iframeId + "Loader"} className="k-loading-image iframe-mask">
                    <div className="k-loading-color"></div>
                </div>
                {IframeUrl !== "" ? (
                    <iframe
                        title="LaborAnalytics - Dashboard"
                        id={iframeId}
                        onLoad={iframeLoad}
                        src={IframeUrl}
                        data-wms-id="smartview"
                        name="smartviewiframe"
                        className="smartviewiframe"
                    ></iframe>
                ) : null}
            </div>
        </div>
    );
};
LaborAnalyticsDashboard.propTypes = {};

LaborAnalyticsDashboard.defaultProps = {};

export default React.memo(LaborAnalyticsDashboard);
