import React, { useState, useEffect } from "react";

import WmsModel from "../react/components/WmsModel/WmsModel";
import WmsCollapse from "../react/components/WmsCollapse/WmsCollapse";
import WmsDropdown from "../react/components/WmsDropdown/WmsDropdown";
import WmsInput from "../react/components/WmsInput/WmsInput";
import WmsCheckBox from "../react/components/WmsCheckBox/WmsCheckBox";
import WmsDateTimePicker from "../react/components/WmsDateTimePicker/WmsDateTimePicker";
import WmsButton from "../react/components/WmsButton/WmsButton";
import * as AdjustCustomerChargesServices from "./adjust-customer-charges.services";
import * as GlobalService from "../react/global_services";
import "./adjustCustomerCharges.scss";
import _ from "lodash";
import WmsGrid from "../react/components/WmsGrid/WmsGrid.jsx";
import { Big } from "../react/global_variable_service";
import { ObjectWms } from "../../assets/ts/ObjectWms";
import { CreateManualChargeModel } from "../react/orders/findOrders/model/create-manual-charge-model.component.jsx";
import { StringWms } from "../../assets/ts/StringWms";
import * as GridConfig from "./adjust-customer-charges-grid-configuration";
interface Props {
    onCloseCreateNewCharge?: any;
    sharedIsOpenNewChargeModal?: boolean;
    wmsSelector?: string;
    reloadGrid?: any;
    windowId: string;
}

declare var window: any;
var $ = window.$;
let defaultChargeObj = {
    ReferenceNum: "",
    CreateInvoice: false,
    InvoiceCreationInfo: {
        SetInvoiceDate: true,
        UtcOffset: (new Date().getTimezoneOffset() / 60) * -1,
    },
    Notes: "",
    PONumber: "",
    CustomerIdentifier: { Id: 0 },
    FacilityIdentifier: { Id: 0 },
    Billing: [],
    ProcessDate: new Date(),
};
const CreateNewChargeModal: React.FC<Props> = (props: Props): any => {
    const CustomLabels = GlobalService.getCustomLabels();
    const CalculateCharge_GrideHeaders = StringWms.convertSringToOption(
        window.translate("Orders_ManageOrders_Grid_Headers_Applied_Charges")
    );
    const chargesGridColumns = GridConfig.ChargesColumns(CalculateCharge_GrideHeaders, window.translate);
    const chargesGridSchema = GridConfig.getCalculateChargesGridSchema().schema;
    const [isOpenManualChargeModal, setIsOpenManualChargeModal] = useState(false);
    const [isOpenConfirmModel, setIsOpenConfirmModel] = useState(false);
    const [isManualCharge, setIsManualCharge] = useState(false);
    const optionsCustomer = GlobalService.getCustomerList(true);
    const [optionsFacility, setOptionsFacility] = useState(GlobalService.getFacilityList(true));

    const [chargesType, setChargesType] = useState([]);
    const [chargeObject, setChargeObject] = useState(_.cloneDeep(defaultChargeObj));
    const [totalSum, setTotalSum] = useState("");
    const [createChargeLoading, setCreateChargeLoading] = useState(false);

    useEffect(() => {
        let tmpTotalSum = 0;
        for (let i = 0; i < chargeObject.Billing.length; i++) {
            const billing = chargeObject.Billing[i];
            tmpTotalSum = tmpTotalSum + parseFloat(billing.TotalCharges);
        }

        let formatTotalSum = tmpTotalSum
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // eslint-disable-line security/detect-unsafe-regex
        setTotalSum(formatTotalSum);
    }, [chargeObject]);

    const handleChargeChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "CustomerIdentifier") {
            handleCustomerChange(parseInt(e.target.value));
            return false;
        } else if (name === "FacilityIdentifier") {
            getPredefinedCharge(chargeObject.CustomerIdentifier.Id, value, loadPredefinedCharges);
            value = { Id: value };
        } else if (name === "CreateInvoice") {
            value = e.target.checked;
        }
        setChargeObject((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleCustomerChange = (CustomerID: any) => {
        var selectedCustomer = optionsCustomer.find((cus: any) => cus.id === CustomerID);
        if (selectedCustomer) {
            var customerFacilityIds = selectedCustomer.facilities;
            let allFacilities = GlobalService.getFacilityList(true);
            allFacilities = allFacilities.filter((f: any) => customerFacilityIds.includes(f.id) || f.id === 0);
            setOptionsFacility(allFacilities);
            setChargeObject((prevState: any) => ({
                ...prevState,
                CustomerIdentifier: { Id: CustomerID },
                FacilityIdentifier: { Id: 0 },
            }));
        } else {
            setOptionsFacility([]);
            setChargeObject((prevState: any) => ({
                ...prevState,
                Billing: [],
            }));
        }
    };
    const getPredefinedCharge = (CustomerID, facilityId, callback) => {
        AdjustCustomerChargesServices.getPredefinedChange(CustomerID, facilityId)
            .then((response) => {
                if (response.data) {
                    response.data = response.data.map((item, index) => {
                        item.rowId = index;
                        return item;
                    });
                    let charges = response.data;
                    if (callback) {
                        charges = callback(charges);
                    }
                    setChargeObject((prevState: any) => ({
                        ...prevState,
                        Billing: charges,
                    }));
                }
            })
            .finally(() => {
                // setIsManualChargeLoading(false);
            });
    };
    const loadPredefinedCharges = (billing) => {
        return ObjectWms.removeObjFromListOnParameterValue(billing ?? [], "SystemGenerated", true);
    };
    const isAllValid = () => {
        return (
            chargeObject.CustomerIdentifier.Id.toString() === "0" ||
            chargeObject.FacilityIdentifier.Id.toString() === "0" ||
            !chargeObject.ReferenceNum ||
            chargeObject.ProcessDate.toString() === "" ||
            !chargeObject.Billing.length
        );
    };

    const createCharge = () => {
        setCreateChargeLoading(true);
        let date = new Date(chargeObject.ProcessDate).toString();
        chargeObject.Billing.map((el) => {
            el.chargeDate = kendo.toString(kendo.parseDate(date), "MM/dd/yyyy");
            return el;
        });
        AdjustCustomerChargesServices.createCharges(chargeObject)
            .then((response) => {
                GlobalService.notification("success", window.translate("Label_Create_Charge_Success"));
                props.reloadGrid();
                props.onCloseCreateNewCharge();
            })
            .finally(() => {
                setCreateChargeLoading(false);
            });
    };
    const openCreateManualChargeModel = () => {
        AdjustCustomerChargesServices.getCharges().then((response) => {
            response.data.unshift({
                "id": null,
                "Description": window.translate("Label_Select_Charge_Type"),
            });
            setChargesType(response.data);
            setIsOpenManualChargeModal(true);
        });
    };
    const onCloseCreateManualChargeModal = () => {
        setIsOpenManualChargeModal(false);
    };
    const addOrderChargesGrid = (data) => {
        var ManualCharge = {
            "Action": "a",
            "ChargeOrigin": 2,
            "ChargeLabel": data.ChargeLabel,
            "ChargeTypeId": data.ChargeTypeId,
            "ChargeTypeDescription": data.ChargeType,
            "UnitDescription": data.Description,
            "ChargePerUnit": data.ChargePerUnit,
            "NumberOfUnits": data.NumberOfUnits,
            "TotalCharges": data.TotalCharges,
            "GeneralLedgerAccount": data.GeneralLedgerAccount,
            "ExternalAccountsReceivable": data.ExternalAccountsReceivable,
            "ExternalItemIdentifier": data.ExternalItemIdentifier,
            "ExternalItemDescription": data.ExternalItemDescription,
            "SKU": "",
            "Notes": "",
            "UnsavedManualCharge": true,
            "SystemGenerated": false,
        };
        setChargeObject((prevState: any) => ({
            ...prevState,
            Billing: [...prevState.Billing, ManualCharge],
        }));
    };
    const openConfirmationModal = () => {
        setIsOpenConfirmModel(true);
    };
    const clickExit = () => {
        setIsOpenConfirmModel(false);
    };
    const deleteManualCharges = () => {
        var grid = $("#" + props.wmsSelector + "chargeGrid").data("kendoGrid");
        grid.select().each(function () {
            grid.removeRow($(this));
        });
        var gridData = grid.dataSource.data().length > 0 ? grid.dataSource.data().toJSON() : [];

        setChargeObject((previousState) => ({
            ...previousState,
            Billing: gridData,
        }));
        setIsManualCharge(false);
        grid.clearSelection();
        setIsOpenConfirmModel(false);
        setIsOpenManualChargeModal(false);
    };
    const chargeSelectRow = (param_kendogrid) => {
        var selectedRow = param_kendogrid.getNumberOfSelections();
        var manualChargeType =
            selectedRow > 0 ? param_kendogrid.identicalValuesInSelectedSet("ChargeOrigin", 2) : false;

        setIsManualCharge(manualChargeType);
    };
    const onDataBound = (e, kendoGrid) => {
        if (kendoGrid) {
            var totalCharges = kendoGrid.sumByLocalMemoryGridContents(["TotalCharges"])[0];
            let tmpTotalSum = Number(Math.round(parseFloat(totalCharges + "e2")) + "e-2");
            let formatTotalSum = tmpTotalSum
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // eslint-disable-line security/detect-unsafe-regex
            setTotalSum(formatTotalSum);
        }
    };
    const onEdit = (e) => {
        var grid = $("#" + props.wmsSelector + "chargeGrid").data("kendoGrid");
        var columnIndex = grid.cellIndex(e.container);
        var fieldName = grid.thead.find("th").eq(columnIndex).data("field");

        if (fieldName === "NumberOfUnits" && e.model.ChargeOrigin === 1) {
            grid.closeCell();
        }
        if (fieldName === "ChargePerUnit" && e.model.ChargeOrigin === 1) {
            grid.closeCell();
        }
        if (fieldName === "ChargePerUnit" && e.model.ChargeOrigin === 3) {
            grid.closeCell();
        }
    };
    const EventSaveGridAppliedCharges = (e) => {
        var grid = $(" #" + props.wmsSelector + "chargeGrid").data("kendoGrid");

        var numberOfUnits = StringWms.isEmpty(e.model.NumberOfUnits) ? 0 : e.model.NumberOfUnits;
        if (e.values.hasOwnProperty("NumberOfUnits")) {
            numberOfUnits = StringWms.isEmpty(e.values.NumberOfUnits) ? 0 : e.values.NumberOfUnits;
        }

        var chargePerUnit = StringWms.isEmpty(e.model.ChargePerUnit) ? 0 : e.model.ChargePerUnit;
        if (e.values.hasOwnProperty("ChargePerUnit")) {
            chargePerUnit = StringWms.isEmpty(e.values.ChargePerUnit) ? 0 : e.values.ChargePerUnit;
        }

        var total = parseFloat(new Big(numberOfUnits).times(chargePerUnit).round(4));

        e.model.set("TotalCharges", total);
        e.model.TotalCharges = total;
        grid.saveChanges();

        // Setting a timeout so that NumberOfUnits gets updated by gridSaveChanges
        setTimeout(() => {
            let billingChargesData = grid._data;
            billingChargesData = ObjectWms.removeObjFromListOnParameterValue(billingChargesData, "test", null);

            setChargeObject((prevState: any) => ({
                ...prevState,
                Billing: billingChargesData,
            }));
        }, 100);
    };
    const appliedChargesGridChange = (e) => {
        //   Still nothing to do here.
    };
    return (
        <>
            <WmsModel
                id="CreateNewChargeModal"
                title={window.translate("Create_New_Charge")}
                width={800}
                height={800}
                onCloseFunc={props.onCloseCreateNewCharge}
                isOpen={props.sharedIsOpenNewChargeModal}
                customClass="wms-model-new-ui-wrapper"
            >
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={"manualChargeDetail"}
                        headerLabel={window.translate("Manual_Charge_Details")}
                        showCollapseText={false}
                    >
                        <div className="charge-detail-wrapper">
                            <div className="display-inline">
                                <WmsDropdown
                                    id="CustomerIdentifier"
                                    name="CustomerIdentifier"
                                    label={CustomLabels.Label_Customer}
                                    wmsSelector={props.wmsSelector + "SelectCustomer"}
                                    value={chargeObject.CustomerIdentifier.Id}
                                    onChangeFunc={handleChargeChange}
                                    options={optionsCustomer}
                                    valueField={"id"}
                                    required={true}
                                    textField={"name"}
                                />
                                <WmsDropdown
                                    id="FacilityIdentifier"
                                    name="FacilityIdentifier"
                                    label={CustomLabels.Label_Warehouse}
                                    wmsSelector={props.wmsSelector + "FacilityIdentifier"}
                                    value={chargeObject.FacilityIdentifier.Id}
                                    onChangeFunc={handleChargeChange}
                                    options={optionsFacility}
                                    required={true}
                                    valueField={"id"}
                                    textField={"name"}
                                />
                            </div>
                            <div className="display-inline">
                                <WmsInput
                                    id="ReferenceNum"
                                    inputName="ReferenceNum"
                                    wmsSelector={props.wmsSelector + "ReferenceNum"}
                                    onChangeFunc={handleChargeChange}
                                    label={window.translate("Orders_Lbl_Reference_Num")}
                                    required={true}
                                    value={chargeObject.ReferenceNum}
                                />
                                <WmsInput
                                    id="PONumber"
                                    inputName="PONumber"
                                    wmsSelector={props.wmsSelector + "PONumber"}
                                    onChangeFunc={handleChargeChange}
                                    label={window.translate("PO_Number")}
                                    value={chargeObject.PONumber}
                                />
                                <WmsDateTimePicker
                                    id={"ProcessDate"}
                                    name="ProcessDate"
                                    label={window.translate("Charge_Date")}
                                    wmsSelector={props.wmsSelector + "ProcessDate"}
                                    onChangeFunc={handleChargeChange}
                                    value={chargeObject.ProcessDate}
                                    format="MMMM dd, yyyy"
                                    onlyDate={true}
                                />
                            </div>
                            <div className="display-inline">
                                <WmsInput
                                    id="Notes"
                                    inputName="Notes"
                                    wmsSelector={props.wmsSelector + "Notes"}
                                    onChangeFunc={handleChargeChange}
                                    label={window.translate("Label_SectionTitle_Notes")}
                                    value={chargeObject.Notes}
                                    extraWrapperClass="charge-notes"
                                />
                                <WmsCheckBox
                                    id="CreateInvoice"
                                    name="CreateInvoice"
                                    onChangeFunc={handleChargeChange}
                                    label={window.translate("Label_Create_Charge_Invoice")}
                                    checked={chargeObject.CreateInvoice}
                                    wmsSelector={props.wmsSelector + "CreateInvoice"}
                                />
                            </div>
                            <hr />

                            <div className="display-inline charge-grid-wrapper">
                                <WmsGrid
                                    id={props.wmsSelector + "chargeGrid"}
                                    wmsSelector={props.wmsSelector + "chargeGrid"}
                                    gridKeySelection={"ChargeLabel"}
                                    gridName={props.wmsSelector + "chargeGrid"}
                                    isAlignIndexesByProperty={true}
                                    virtual={false}
                                    columns={chargesGridColumns}
                                    schema={chargesGridSchema}
                                    showGridSummary={false}
                                    onSelection={chargeSelectRow}
                                    clickEventOfContextMenu={() => null}
                                    shawCheckbox={false}
                                    showGridResult={false}
                                    staticGridData={chargeObject.Billing}
                                    showSortColumns={false}
                                    onDataBound={onDataBound}
                                    gridEditable={{
                                        confirmation: false,
                                    }}
                                    onEdit={onEdit}
                                    onGridSave={EventSaveGridAppliedCharges}
                                    onChange={appliedChargesGridChange}
                                    menu={[
                                        {
                                            "name": window.translate("Label_Create_Manual_Charge"),
                                            "value": [],
                                            "disabled": false,
                                            "icon": "fa fa-keyboard-o",
                                            "color": "green",
                                            "clickFunc": openCreateManualChargeModel,
                                            "wmsSelector": props.wmsSelector + "CreateManualCharge",
                                            "isContextMenu": false,
                                        },
                                        {
                                            "name": window.translate("Label_Delete"),
                                            "value": [],
                                            "disabledText": "You may not add blahssss",
                                            "disabled": !isManualCharge,
                                            "icon": "fa-trash",
                                            "color": "red",
                                            "clickFunc": openConfirmationModal,
                                            "wmsSelector": props.wmsSelector + "DeleteManualCharge",
                                            "isContextMenu": false,
                                        },
                                    ]}
                                />
                                <div className="charge-totalWrapper">
                                    <div className="total-text">TOTAL</div>
                                    <div className="total-input">
                                        <WmsInput
                                            id="totalInput"
                                            inputName="totalInput"
                                            wmsSelector={props.wmsSelector + "totalInput"}
                                            onChangeFunc={() => {}}
                                            label={""}
                                            value={totalSum}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={window.translate("Label_Cancel")}
                        wmsSelector={window.wmsSelector + "DiscardExit"}
                        onClickFunc={props.onCloseCreateNewCharge}
                        buttonType="orange"
                        icon={"fa-ban"}
                    />
                    <WmsButton
                        label={window.translate("Create_Charge")}
                        wmsSelector={props.wmsSelector + "SaveExit"}
                        onClickFunc={createCharge}
                        loading={createChargeLoading}
                        disabled={isAllValid()}
                        icon={"fa-floppy-o"}
                    />
                </div>
            </WmsModel>
            {isOpenManualChargeModal && (
                <CreateManualChargeModel
                    sharedIsOpenManualChargeModal={isOpenManualChargeModal}
                    sharedManualChargeOptions={chargesType}
                    onCloseAddManualChargeModel={onCloseCreateManualChargeModal}
                    onAddShipAndCloseGridData={addOrderChargesGrid}
                    isAnotherModelOpen={false}
                    windowId={props.windowId}
                />
            )}
            {isOpenConfirmModel && (
                <WmsModel
                    id={props.windowId + "confirmDeleteManualCharge"}
                    title={window.translate("Label_Confirm_Delete_Manual_Charge")}
                    width={500}
                    height={175}
                    class="confirmDeleteManualCharge"
                    onCloseFunc={clickExit}
                    customClass="wms-model-new-ui-wrapper"
                    isOpen={isOpenConfirmModel}
                >
                    <div className="model-content-wrapper">{window.translate("Label_Confirm_Selected_Charges")}</div>
                    <div className="footer-btn-holder">
                        <WmsButton
                            label={window.translate("Label_Cancel")}
                            wmsSelector={props.wmsSelector + "Cancel"}
                            onClickFunc={clickExit}
                            disabled={false}
                            loading={false}
                            buttonType="yellow"
                            icon={"fa-ban"}
                        />
                        <WmsButton
                            label={window.translate("Label_Confirm_Delete")}
                            wmsSelector={props.wmsSelector + "ConfirmDelete"}
                            onClickFunc={deleteManualCharges}
                            disabled={false}
                            loading={false}
                            buttonType="red"
                            icon={"fa-trash"}
                        />
                    </div>
                </WmsModel>
            )}
        </>
    );
};
CreateNewChargeModal.propTypes = {};

CreateNewChargeModal.defaultProps = {};

export default React.memo(CreateNewChargeModal);
