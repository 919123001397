import React from 'react';

//component
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsInput from './../../../components/WmsInput/WmsInput.jsx';
import WmsDropdown from './../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsDateTimePicker from './../../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import WmsModel from './../../../components/WmsModel/WmsModel.jsx';
import WmsGrid from './../../../components/WmsGrid/WmsGrid.jsx';
import { StringWms } from '../../../../../assets/ts/StringWms.ts';
import { KendoGridWms } from '../../../../../assets/ts/KendoGridWms.ts';
import WmsContainer from '../../../components/WmsContainer/WmsContainer.jsx';

//services
import * as GlobalService from './../../../global_services';
import * as manageOrderServices from './../manage-orders.services.jsx';


import * as GridConfig from './../grid_configuration.jsx';
import './manage-allocation-modal.scss';
import { kendo } from "./../../../global_variable_service.jsx";

export class ManageAllocationModal extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        let gridEditAllocationHeader = this.translate('Orders_ManageOrders_EditAllocations_Grid').split(',');
        let gridMassAddHeader = this.translate('Grid_Mass_Add_Column_Specs').split(',');
        this.state = {
            optionsSearchContains: StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchFilters')),
            optionsSearchByCost: StringWms.convertSringToOption(this.translate('Orders_ManageOrders_SearchByCost')),
            customLabels: GlobalService.getCustomLabels(),

            //columns ,schema and transport data for edit allocation grid
            editAllocationColumns: GridConfig.getEditAllocationColumns(gridEditAllocationHeader, GlobalService.getCustomLabels(), $).columns,
            editAllocationSchema: GridConfig.getEditAllocationSchema("#" + this.props.sharedEditAllocationData.modalType + this.props.windowId, $).schema,
            editAllocationTransport: GridConfig.getEditAllocationTransport(
                this.props.sharedEditAllocationData.CustomerId,
                this.props.sharedEditAllocationData.FacilityId,
                this.props.sharedEditAllocationData.ItemId,
                this.props.sharedEditAllocationData.QualifierId,
                this.props.sharedEditAllocationData.OrderItemId).transport,

            //columns ,schema and transport data for mass add grid
            massAddcolumns: GridConfig.getMassAddColumns(gridMassAddHeader, GlobalService.getCustomLabels()).columns,
            massAddSchema: GridConfig.getMassAddSchema(this.translate('Label_Enter_Valid_Number')).schema,
            massAddTransport: GridConfig.getMassAddTransport(
                this.props.sharedEditAllocationData.CustomerId,
                this.props.sharedEditAllocationData.FacilityId).transport,

            makeOrderedQuantityMatchAllocatedQuantity: false,
            valueGreater: false,
            gifEditAllocation: false,
            savingGifAddNew: false,
            savingGifAddExit: false,
            selector: "OrderAllocation",
            KendoGrid: new KendoGridWms(this.props.sharedEditAllocationData.modalType + this.props.windowId, this.props.windowId, "Key"),
            totalInputedQuantity: 0, // this holds the total amount the user has inputted in their column
            disableSave: true,
            styleSearchScrollBox1: {},
            filter: {},
            filterObject: []
        };
    }
    componentDidMount(){
        this.setIntialEditAllocation();
    }

    onEditAllocationGridBinding = () => {

        if (this.props.sharedEditAllocationData.modalType == "GridEditAllocations") {

            var grid = $("#" + this.props.sharedEditAllocationData.modalType + this.props.windowId).data('kendoGrid');
            var gridData = grid.dataSource.view();
            var myCollection = this.state.KendoGrid.getDictionary().items;
            for (var i = 0; i < gridData.length; i++) {
                var key = gridData[i].Key;
                if (myCollection.hasOwnProperty(key)) {
                    gridData[i].PrimaryQuantity = myCollection[key].Qty;
                }
            }

        }
    }

    setIntialEditAllocation = () => {

        kendo.ui.progress($('#manageAllocation' + this.props.windowId), true);
        if (this.props.sharedEditAllocationData.modalType == 'GridEditAllocations') {

            manageOrderServices.initialEditAllocations({
                "orderId": this.props.sharedEditAllocationData.OrderId,
                "EditWithAllocationDetailsUri": this.props.sharedEditAllocationData.EditWithAllocationsDetailsUri
            }).then((response) => {
                // we keep track of everything using a Key, so we need to add this key to our data return to use in our dictionary
                for (var x = 0; x < response.length; x++) {
                    response[x].Key = response[x].ReceiveItemId;
                }
                this.state.KendoGrid.addMassToDictionary(response);
                this.setDefaultSearchFilter();
                let lineItemHeight = $('#LineItemInfoSection').height();
                let badBrowser = this.isIEorEDGE();
                let topUIHeight = 130;
                if (badBrowser) {
                    $("#IEdead").height(8);
                }
                this.setState({
                    styleSearchScrollBox1: { 'height': ((window.innerWidth - 245) - topUIHeight - lineItemHeight) },
                    totalInputedQuantity: this.props.sharedEditAllocationData.OrderQty
                });

            }).finally(() => {
                kendo.ui.progress($('#manageAllocation'+ this.props.windowId), false);
            });
        } else {
            // $scope.ValidNumberText = $filter('translate')('Label_Enter_Valid_Number')

            // KendoGrid = new KendoGridWms("GridMassAdd", ShippingService.MassAdd.WindowId, "Key");
            // $scope.WindowId = ShippingService.MassAdd.WindowId;

            // $("#WindowMassAdd").addClass($scope.WindowId);



            manageOrderServices.initialMassAdd().then((response) => {
                // $scope.$broadcast('AvailableData', {
                //     "Class": "ShippingService",
                //     "Object": "MassAdd"
                // });
                // Not in use
                // $scope.Text.Label_Line_Item_Save_Success = ShippingService.MassAdd.Initial.Label_Line_Item_Save_Success;

                // if (ShippingService.MassAdd.Session.SearchSession != null) {
                //     $scope.SearchMethod.IndexOptionsSearchMethod = ShippingService.MassAdd.Session.SearchSession.IndexOptionsSearchMethod;
                // } else {
                //     $scope.SearchMethod.IndexOptionsSearchMethod = 0;
                // }
                this.setDefaultSearchFilter();
            }).finally(() => {
                kendo.ui.progress($('#manageAllocation'+ this.props.windowId), false);
            });
        }
    }

    gridSearch = () => {
        let filterObj = JSON.parse(JSON.stringify(this.state.filter));
        filterObj.method = 'POST';
        this.setState({ filterObject: [filterObj] });
    }

    clearFilters = () => {
        this.setDefaultSearchFilter();
        this.setState({ filterObject: [{ method: 'POST' }] });
    }

    setDefaultSearchFilter = () => {
        let filterObj = {
            IndexOptionsSku: 0,
            Sku: '',
            IndexOptionsDescription: 0,
            Description: '',
            IndexOptionsQualifier: 0,
            Qualifier: '',
            IndexOptionsAvailableQty: 0,
            CustomField: '',
            IndexOptionsLotNumber: 0,
            LotNumber: '',
            IndexOptionsSerialNumber: 0,
            SerialNumber: '',
            IndexOptionsSupplier: 0,
            Supplier: '',
            IndexOptionsCost: 0,
            Cost: '',
            ExpDateBegin: null,
            ExpDateEnd: null,
            IndexOptionsReceiptRefId: 0,
            ReceiptRefId: '',
            IndexOptionsPoNumber: 0,
            PoNumber: '',
            IndexOptionsLocation: 0,
            Location: '',
            IndexOptionsPallet: 0,
            Pallet: '',
            IndexOptionsTrailerContainerNumber: 0,
            TrailerContainerNumber: '',
            ReceiptFirstTransaction: null,
            ReceiptLastTransaction: null,
            ReceiptBeginDate: null,
            ReceiptEndDate: null
        };
        this.setState({ filter: filterObj });
    }

    onGridChange = (event) => {
        let inputName = this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' ? 'PrimaryQuantity' : 'PrimaryUnitsToAdd';
        if (event.field === inputName) {
            let valueGreater = false;
            if (parseFloat($("input[name='" + inputName + "']").val()) > event.items[0].Available) {
                valueGreater = true;
            }

            this.setState({ valueGreater: valueGreater });
        }
    }

    onGridSave = (event) => {
        var validator = event.container.data('kendoValidator');
        validator.options.messages.custom = this.translate('Label_Greater_Allocation_Warning');
        let inputName = this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' ? 'PrimaryQuantity' : 'PrimaryUnitsToAdd';
        validator.options.rules.custom = function (input) {
            if (input.is('[name="' + inputName + '"]')) {
                if (parseFloat(input.val()) > parseFloat(event.model.Available)) {
                    return false;
                }
                return true;
            }
            return true;
        };
        if (this.props.sharedEditAllocationData.modalType == 'GridEditAllocations') {
            if (validator.validate()) {
                let finalModel = event.model;
                finalModel.PrimaryQuantity = event.values.PrimaryQuantity;
                // We add to our dictionary with the parameter of override, so if our dictionary finds this key it will override it with the new amount
                this.state.KendoGrid.addSelections(event.model.Key, {
                    "OrderId": this.props.sharedEditAllocationData.orderID,
                    "OrderItemId": this.props.sharedEditAllocationData.orderItemId,
                    "ReceiveItemId": parseInt(event.model.Key),
                    "Qty": event.model.PrimaryQuantity
                }, "override");
                this.setState({
                    totalInputedQuantity: this.state.KendoGrid.sumDictionaryParameter("Qty"),
                    disableSave: false
                });
            }
        } else {
            let finalModel = event.model;
            finalModel.PrimaryUnitsToAdd = event.values.PrimaryUnitsToAdd;
            this.state.KendoGrid.addSelections(event.model.Key, finalModel, "override");
            this.state.KendoGrid.setQuantityCollection(event.model.Key, event.values.PrimaryUnitsToAdd);

            var temp = event.model;
            temp.PrimaryQuantity = event.values.PrimaryUnitsToAdd;
            this.state.KendoGrid.setQuantityArray(temp);
        }
    }

    filterHandelChange = (event) => {
        let filterObj = this.state.filter;
        filterObj[event.target.name] = event.target.value;
        this.setState({ filter: filterObj });
    }

    eventClickSaveAllocation = () => {

        kendo.ui.progress($('#manageAllocation'+ this.props.windowId), true);
        var list = this.state.KendoGrid.getDictionary().items;
        var keys = this.state.KendoGrid.getKeys();

        var newlist = [];
        var sumOfNewQuantity = 0;
        for (var x = 0; x < keys.length; x++) {
            if (list[keys[x]].hasOwnProperty("Key")) {
                delete list[keys[x]].Key;
            }
            if(list[keys[x]].Qty === null){
                list[keys[x]].Qty = 0;
            }
            sumOfNewQuantity = sumOfNewQuantity + list[keys[x]].Qty;
            newlist.push(list[keys[x]]);
        }
        if (sumOfNewQuantity > this.props.sharedEditAllocationData.totalOrdered) {
            this.setState({ makeOrderedQuantityMatchAllocatedQuantity: true });
        }
        if (this.props.sharedEditAllocationData.totalOrdered <= this.state.totalInputedQuantity) {
            this.editOrderLineItemAllocations(newlist);
        }
    }

    editOrderLineItemAllocations(newlist) {
        this.setState({ gifEditAllocation: true });
        manageOrderServices.editOrderLineItemAllocations({
            orderLineItemEditUri: this.props.sharedEditAllocationData.EditWithAllocationsDetailsUri,
            allocations: newlist,
            makeOrderedQuantityMatchAllocatedQuantity: this.state.makeOrderedQuantityMatchAllocatedQuantity
        }).then((response) => {
            if (response && (response.status == 200 || response.status == 204)) {
                GlobalService.notification('success', this.translate('Label_Success_Items_Updated'));
                this.setState({ gifEditAllocation: false });
                this.eventClickDiscardButton();
                // method call to set discard button for react old order version
                if(this.props.onGridItemChange){
                    this.props.onGridItemChange(true);
                }
                this.props.onResetGridAndSelection();
                this.props.onGridItemChange();
            }
        }).finally(() => {
            this.setState({ gifEditAllocation: false });
            kendo.ui.progress($('#manageAllocation'+ this.props.windowId), false);
        });
    }

    isIEorEDGE = () => {
        if (navigator.appName == 'Microsoft Internet Explorer') {
            return true; // IE
        } else if (navigator.appName == "Netscape") {
            return navigator.appVersion.indexOf('Edge') > -1; // EDGE
        }
        return false;
    }
    onLoadGrid = () => {
        this.setState({
            totalInputedQuantity: this.state.KendoGrid.sumDictionaryParameter("Qty")
        });
    }

    eventClickDiscardButton = () => {
        $("#manageAllocation"+ this.props.windowId).data('kendoWindow').close();
        this.props.onCloseEditAllocaion();
    }

    addLineItem = (param_baseCall) => {

        this.setState({ ['savingGif' + param_baseCall]: true });
        var passing = this.state.KendoGrid.getValues();
        kendo.ui.progress($('#manageAllocation'+ this.props.windowId), true);

        manageOrderServices.massOrderLineItemsAdd({
            orderId: this.props.sharedEditAllocationData.OrderId,
            itemsUri: this.props.sharedEditAllocationData.OrderItemsUri,
            details: passing
        }).then((response) => {

            this.setState({ ['savingGif' + param_baseCall]: false });
            if (param_baseCall == 'AddExit') {
                this.eventClickDiscardButton();
            }

            if (response && (response.status == 200 || response.status == 204)) {
                kendo.ui.progress($('#manageAllocation' + this.props.windowId), false);

                GlobalService.notification('success', this.translate('Label_Line_Item_Save_Success'));
                if (param_baseCall == 'AddNew') {
                this.state.KendoGrid.clearSelections(() => {
                    if (typeof $('#GridMassAdd' + this.props.windowId).data('kendoGrid') != 'undefined') {
                        $('#GridMassAdd' + this.props.windowId).data('kendoGrid').dataSource.read();
                    }
                    $("#GridMassAdd" + this.props.windowId).data('kendoGrid').refresh();
                });
                }

                // method call to set discard button for react old order version
                if(this.props.onGridItemChange){
                    this.props.onGridItemChange(true);
                }
                this.props.onResetGridAndSelection();
            }

        }).finally(() => {
            this.setState({ ['savingGif' + param_baseCall]: false });
            kendo.ui.progress($('#manageAllocation'+ this.props.windowId), false);
        });
    }
    render() {
        return (
            <WmsModel
                id={'manageAllocation' + this.props.windowId}
                class={'manageAllocation'}
                title={this.props.sharedEditAllocationData.modalTitle}
                width={(window.innerWidth - 470)+155}
                height={window.innerHeight - 100}
                onCloseFunc={this.props.onCloseEditAllocaion}
                isOpen={this.props.sharedIsOpenManageAllocation}
                customClass='wms-model-new-ui-wrapper'>
                <div className="model-content-wrapper">
                    <div className="grid-filter-wrapper">
                <div className="side-content">
                    <div>
                    <WmsButton
                        label={this.translate("Label_Search")}
                        wmsSelector={this.state.selector + "SearchButton"}
                        onClickFunc={this.gridSearch}
                        icon="fa-search"
                    />
                    <WmsButton
                        label={(this.props.sharedEditAllocationData.modalType == 'GridEditAllocations') ? this.translate("Label_Clear_Filters") : this.translate('Label_Clear_Entries')}
                        wmsSelector={this.state.selector + "ClearFiltersButton"}
                        onClickFunc={this.clearFilters}
                        icon="fa-times-circle"
                        buttonType={'yellow'}
                        showToolTip={true}
                        tooltipText={'Clear search entries and reset grid results.'}
                    />
                    </div>
                    <div id="LineItemInfoSection" >
                        {this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' && (
                            <WmsContainer title={this.translate('Label_Line_Item_Information')}>
                                <div className="without-bg" data-wms-selector={this.state.selector + 'LineItemInformation'}>
                                    <ul>
                                        <li>
                                            <h3 className="pull-left">{this.state.customLabels.Label_Sku}:</h3>
                                            <p className="pull-left">{this.props.sharedEditAllocationData.Sku}</p>
                                        </li>
                                        <li>
                                            <h3 className="pull-left">{this.translate('Label_Description')}:</h3>
                                            <p className="pull-left">{this.props.sharedEditAllocationData.ItemDescription}</p>
                                        </li>
                                        <li>
                                            <h3 className="pull-left">{this.translate('Label_Qualifier')}:</h3>
                                            <p className="pull-left">{
                                                StringWms.isEmpty(this.props.sharedEditAllocationData.QualifierId) ?
                                                    "-" :
                                                    this.props.sharedEditAllocationData.QualifierId
                                            }</p>
                                        </li>
                                        <li>
                                            <h3 className="pull-left">{this.translate('Label_Total_Ordered')}:</h3>
                                            <p className="pull-left">{this.props.sharedEditAllocationData.totalOrdered}</p>
                                        </li>
                                        <li>
                                            <h3 className="pull-left">{this.translate('Label_Selected_Quantity')}:</h3>
                                            <p className="pull-left">{this.state.totalInputedQuantity}</p>
                                        </li>
                                        {/* <li>
                                    <h3 className="pull-left">{this.translate('Label_New_Order_Total') }:</h3>
                                    <p className="pull-right">{ 'totalInputedQuantity' }</p>
                                </li> */}
                                    </ul>
                                </div>
                            </WmsContainer >
                        )}
                    </div>

                    <div className={this.props.sharedEditAllocationData.modalType == 'GridMassAdd' ? 'mass-add-Modal wms-area-content-scroll-y' : 'wms-area-content-scroll-y'}>
                        {this.props.sharedEditAllocationData.modalType == 'GridMassAdd' && (
                            <WmsContainer title={this.translate('Label_Item_Info')}>
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsSku"
                                        name="IndexOptionsSku"
                                        label={this.state.customLabels.Label_Sku}
                                        wmsSelector={this.state.selector + "IndexOptionsSku"}
                                        value={this.state.filter.IndexOptionsSku}
                                        onChangeFunc={this.filterHandelChange}
                                        options={this.state.optionsSearchContains}
                                        valueField='value'
                                        textField='description'
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id='Sku'
                                        inputName='Sku'
                                        wmsSelector={this.state.selector + 'InputSku'}
                                        value={this.state.filter.Sku}
                                        onChangeFunc={this.filterHandelChange}
                                        label=''
                                        parentClassName='input_box' />

                                </div>
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsDescription"
                                        name="IndexOptionsDescription"
                                        label={this.translate('Label_Description')}
                                        wmsSelector={this.state.selector + "IndexOptionsDescription"}
                                        value={this.state.filter.IndexOptionsDescription}
                                        onChangeFunc={this.filterHandelChange}
                                        options={this.state.optionsSearchContains}
                                        valueField='value'
                                        textField='description'
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id='Description'
                                        inputName='Description'
                                        wmsSelector={this.state.selector + 'InputDescription'}
                                        value={this.state.filter.Description}
                                        onChangeFunc={this.filterHandelChange}
                                        label=''
                                        parentClassName='input_box' />

                                </div>
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsQualifier"
                                        name="IndexOptionsQualifier"
                                        label={this.translate('Label_Qualifier')}
                                        wmsSelector={this.state.selector + "IndexOptionsQualifier"}
                                        value={this.state.filter.IndexOptionsQualifier}
                                        onChangeFunc={this.filterHandelChange}
                                        options={this.state.optionsSearchContains}
                                        valueField='value'
                                        textField='description'
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id='Qualifier'
                                        inputName='Qualifier'
                                        wmsSelector={this.state.selector + 'InputQualifier'}
                                        value={this.state.filter.Qualifier}
                                        onChangeFunc={this.filterHandelChange}
                                        label=''
                                        parentClassName='input_box' />

                                </div>
                                <WmsInput
                                    id='CustomField'
                                    inputName='CustomField'
                                    wmsSelector={this.state.selector + 'CustomField'}
                                    value={this.state.filter.CustomField}
                                    onChangeFunc={this.filterHandelChange}
                                    label={this.translate('Label_Custom_Field_Value')} />
                            </WmsContainer >
                        )}
                        <WmsContainer title={this.translate('Label_Search_Track_By')}>
                            <div className="form-group wms-input-group-wrapper">
                                <WmsDropdown
                                    id="IndexOptionsLotNumber"
                                    name="IndexOptionsLotNumber"
                                    label={this.state.customLabels.Label_Lot_Number}
                                    wmsSelector={this.state.selector + "IndexOptionsLotNumber"}
                                    value={this.state.filter.IndexOptionsLotNumber}
                                    onChangeFunc={this.filterHandelChange}
                                    options={this.state.optionsSearchContains}
                                    valueField='value'
                                    textField='description'
                                    parentClassName="select_box"
                                />
                                <WmsInput
                                    id='LotNumber'
                                    inputName='LotNumber'
                                    wmsSelector={this.state.selector + 'InputLotNumber'}
                                    value={this.state.filter.LotNumber}
                                    onChangeFunc={this.filterHandelChange}
                                    label=''
                                    parentClassName='input_box' />

                            </div>
                            <div className="form-group wms-input-group-wrapper">
                                <WmsDropdown
                                    id="IndexOptionsSerialNumber"
                                    name="IndexOptionsSerialNumber"
                                    label={this.state.customLabels.Label_Serial_Number}
                                    wmsSelector={this.state.selector + "IndexOptionsSerialNumber"}
                                    value={this.state.filter.IndexOptionsSerialNumber}
                                    onChangeFunc={this.filterHandelChange}
                                    options={this.state.optionsSearchContains}
                                    valueField='value'
                                    textField='description'
                                    parentClassName="select_box"
                                />
                                <WmsInput
                                    id='SerialNumber'
                                    inputName='SerialNumber'
                                    wmsSelector={this.state.selector + 'InputSerialNumber'}
                                    value={this.state.filter.SerialNumber}
                                    onChangeFunc={this.filterHandelChange}
                                    label=''
                                    parentClassName='input_box' />
                            </div>
                            {this.props.sharedEditAllocationData.modalType == 'GridMassAdd' && (
                                <div className="form-group wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsSupplier"
                                        name="IndexOptionsSupplier"
                                        label={this.translate('Label_Supplier_Company_Name')}
                                        wmsSelector={this.state.selector + "IndexOptionsSupplier"}
                                        value={this.state.filter.IndexOptionsSupplier}
                                        onChangeFunc={this.filterHandelChange}
                                        options={this.state.optionsSearchContains}
                                        valueField='value'
                                        textField='description'
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        id='Supplier'
                                        inputName='Supplier'
                                        wmsSelector={this.state.selector + 'InputSupplier'}
                                        value={this.state.filter.Supplier}
                                        onChangeFunc={this.filterHandelChange}
                                        label=''
                                        parentClassName='input_box' />
                                </div>
                            )}
                            <div className="form-group wms-input-group-wrapper">
                                <WmsDropdown
                                    id="IndexOptionsCost"
                                    name="IndexOptionsCost"
                                    label={this.translate('Label_Cost')}
                                    wmsSelector={this.state.selector + "IndexOptionsCost"}
                                    value={this.state.filter.IndexOptionsCost}
                                    onChangeFunc={this.filterHandelChange}
                                    options={this.state.optionsSearchByCost}
                                    valueField='value'
                                    textField='description'
                                    parentClassName="select_box"
                                />
                                <WmsInput
                                    id='Cost'
                                    inputName='Cost'
                                    wmsSelector={this.state.selector + 'InputCost'}
                                    value={this.state.filter.Cost}
                                    onChangeFunc={this.filterHandelChange}
                                    label=''
                                    parentClassName='input_box' />
                            </div>
                        </WmsContainer >
                        <WmsContainer title={this.translate('Label_Expiration_Date')}>
                            <WmsDateTimePicker
                                id={'ExpDateBegin' + this.props.windowId}
                                name='ExpDateBegin'
                                label={this.translate('Label_Begin')}
                                onChangeFunc={this.filterHandelChange}
                                value={this.state.filter.ExpDateBegin}
                                wmsSelector={this.state.selector + 'ExpDateBegin'}
                            />
                            <WmsDateTimePicker
                                id={'ExpDateEnd' + this.props.windowId}
                                name='ExpDateEnd'
                                label={this.translate('Label_End')}
                                onChangeFunc={this.filterHandelChange}
                                value={this.state.filter.ExpDateEnd}
                                wmsSelector={this.state.selector + 'ExpDateEnd'}
                            />
                        </WmsContainer >
                        <WmsContainer title={this.translate('Label_Custom_Receipt_Info')}>
                            <div className="form-group wms-input-group-wrapper">
                                <WmsDropdown
                                    id="IndexOptionsReceiptRefId"
                                    name="IndexOptionsReceiptRefId"
                                    label={this.translate('Label_Custom_Receipt_Reference_Number')}
                                    wmsSelector={this.state.selector + "IndexOptionsReceiptRefId"}
                                    value={this.state.filter.IndexOptionsReceiptRefId}
                                    onChangeFunc={this.filterHandelChange}
                                    options={this.state.optionsSearchContains}
                                    valueField='value'
                                    textField='description'
                                    parentClassName="select_box"
                                />
                                <WmsInput
                                    id='ReceiptRefId'
                                    inputName='ReceiptRefId'
                                    wmsSelector={this.state.selector + 'InputReceiptRefId'}
                                    value={this.state.filter.ReceiptRefId}
                                    onChangeFunc={this.filterHandelChange}
                                    label=''
                                    parentClassName='input_box' />
                            </div>
                            <div className="form-group wms-input-group-wrapper">
                                <WmsDropdown
                                    id="IndexOptionsPoNumber"
                                    name="IndexOptionsPoNumber"
                                    label={this.translate('Label_PO_Number')}
                                    wmsSelector={this.state.selector + "IndexOptionsPoNumber"}
                                    value={this.state.filter.IndexOptionsPoNumber}
                                    onChangeFunc={this.filterHandelChange}
                                    options={this.state.optionsSearchContains}
                                    valueField='value'
                                    textField='description'
                                    parentClassName="select_box"
                                />
                                <WmsInput
                                    id='PoNumber'
                                    inputName='PoNumber'
                                    wmsSelector={this.state.selector + 'InputPoNumber'}
                                    value={this.state.filter.PoNumber}
                                    onChangeFunc={this.filterHandelChange}
                                    label=''
                                    parentClassName='input_box' />
                            </div>
                            <div className="form-group wms-input-group-wrapper">
                                <WmsDropdown
                                    id="IndexOptionsLocation"
                                    name="IndexOptionsLocation"
                                    label={this.translate('Label_Location')}
                                    wmsSelector={this.state.selector + "IndexOptionsLocation"}
                                    value={this.state.filter.IndexOptionsLocation}
                                    onChangeFunc={this.filterHandelChange}
                                    options={this.state.optionsSearchContains}
                                    valueField='value'
                                    textField='description'
                                    parentClassName="select_box"
                                />
                                <WmsInput
                                    id='Location'
                                    inputName='Location'
                                    wmsSelector={this.state.selector + 'InputLocation'}
                                    value={this.state.filter.Location}
                                    onChangeFunc={this.filterHandelChange}
                                    label=''
                                    parentClassName='input_box' />
                            </div>
                            <div className="form-group wms-input-group-wrapper">
                                <WmsDropdown
                                    id="IndexOptionsPallet"
                                    name="IndexOptionsPallet"
                                    label={this.translate('Label_Pallet')}
                                    wmsSelector={this.state.selector + "IndexOptionsPallet"}
                                    value={this.state.filter.IndexOptionsPallet}
                                    onChangeFunc={this.filterHandelChange}
                                    options={this.state.optionsSearchContains}
                                    valueField='value'
                                    textField='description'
                                    parentClassName="select_box"
                                />
                                <WmsInput
                                    id='Pallet'
                                    inputName='Pallet'
                                    wmsSelector={this.state.selector + 'InputPallet'}
                                    value={this.state.filter.Pallet}
                                    onChangeFunc={this.filterHandelChange}
                                    label=''
                                    parentClassName='input_box' />
                            </div>
                            <div className="form-group wms-input-group-wrapper">
                                <WmsDropdown
                                    id="IndexOptionsTrailerContainerNumber"
                                    name="IndexOptionsTrailerContainerNumber"
                                    label={this.translate('Label_Trailer_Container_Number')}
                                    wmsSelector={this.state.selector + "IndexOptionsTrailerContainerNumber"}
                                    value={this.state.filter.IndexOptionsTrailerContainerNumber}
                                    onChangeFunc={this.filterHandelChange}
                                    options={this.state.optionsSearchContains}
                                    valueField='value'
                                    textField='description'
                                    parentClassName="select_box"
                                />
                                <WmsInput
                                    id='TrailerContainerNumber'
                                    inputName='TrailerContainerNumber'
                                    wmsSelector={this.state.selector + 'InputTrailerContainerNumber'}
                                    value={this.state.filter.TrailerContainerNumber}
                                    onChangeFunc={this.filterHandelChange}
                                    label=''
                                    parentClassName='input_box' />
                            </div>
                        </WmsContainer >
                        <WmsContainer title={this.translate('Label_Custom_Receipt_Date_Range')}>
                            <WmsDateTimePicker
                                id={'ReceiptBeginDate'+ this.props.windowId}
                                name='ReceiptBeginDate'
                                label={this.translate('Label_Begin')}
                                onChangeFunc={this.filterHandelChange}
                                value={this.state.filter.ReceiptBeginDate}
                                wmsSelector={this.state.selector + 'ReceiptBeginDate'}
                            />
                            <WmsDateTimePicker
                                id={'ReceiptEndDate'+ this.props.windowId}
                                name='ReceiptEndDate'
                                label={this.translate('Label_End')}
                                onChangeFunc={this.filterHandelChange}
                                value={this.state.filter.ReceiptEndDate}
                                wmsSelector={this.state.selector + 'ReceiptEndDate'}
                            />
                        </WmsContainer >
                        <WmsContainer title={this.translate('Label_Custom_Receipt_Transaction_Range')}>
                            <WmsInput
                                id='ReceiptFirstTransaction'
                                inputName='ReceiptFirstTransaction'
                                wmsSelector={this.state.selector + 'ReceiptFirstTransaction'}
                                value={this.state.filter.ReceiptFirstTransaction}
                                onChangeFunc={this.filterHandelChange}
                                label={this.translate('First_Orders_Lbl_Transaction_Id')} />
                            <WmsInput
                                id='ReceiptLastTransaction'
                                inputName='ReceiptLastTransaction'
                                wmsSelector={this.state.selector + 'ReceiptLastTransaction'}
                                value={this.state.filter.ReceiptLastTransaction}
                                onChangeFunc={this.filterHandelChange}
                                label={this.translate('Label_Last_Transaction')} />

                        </WmsContainer >
                    </div>
                    </div>
                    <div className='grid-section'>
                        <div className="wms-warning-wrapper">
                            <div className="wms-sprite fa fa-exclamation-triangle"></div>
                            <span>
                                {this.translate('Label_warning_for_total_weight_and_volume_add_edit')}
                            </span>
                        </div>
                        {this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' &&
                            ((this.state.totalInputedQuantity > this.props.sharedEditAllocationData.totalOrdered) || this.state.valueGreater) &&
                            <div className="alert-not">
                                {this.translate('Label_You_cannot_allocate_more_units_than_specified_in_the_line_item')}
                            </div>
                        }
                        {this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' &&
                            (this.state.totalInputedQuantity < this.props.sharedEditAllocationData.totalOrdered) &&
                            <div className="alert-not">
                                {this.translate('Label_You_Cannot_Remove_Quantity')}
                            </div>
                        }
                        {(this.props.sharedEditAllocationData.modalType == 'GridMassAdd' && this.state.valueGreater) &&
                            <span className="mass-add alert-not">{this.translate('Label_You_May_Not_Add')}</span>
                        }
                        <WmsGrid
                            wmsSelector={this.state.selector + "Grid"}
                            gridKeySelection={'Key'}
                            id={this.props.sharedEditAllocationData.modalType+this.props.windowId}
                            gridName={this.props.sharedEditAllocationData.modalType}
                            virtual={true}
                            onGridBinding={this.onEditAllocationGridBinding}
                            shawCheckbox={false}
                            showGridSummary={this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' ? true : false}
                            columns={this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' ?
                                this.state.editAllocationColumns :
                                this.state.massAddcolumns}
                            schema={this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' ?
                                this.state.editAllocationSchema :
                                this.state.massAddSchema}
                            transport={this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' ?
                                this.state.editAllocationTransport :
                                this.state.massAddTransport}
                            virtualGridpageSize={this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' ? 50 : 80}
                            showGridResult={this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' ? true : false}
                            showSortColumns={this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' ? true : false}
                            showColumnFilter={false}
                            onSelection={() => function () { }}
                            // windowId={this.props.windowId}
                            getGridSelectionUrl={''}
                            filterObject={this.state.filterObject}
                            searchUrl={'/WebUI/Shipping/UpdateSession/FilterSearchInfoSend'}
                            gridEditable={true}
                            onGridChange={this.onGridChange}
                            onGridSave={this.onGridSave}
                            onLoadGrid={this.onLoadGrid}
                            resetButtonLabel={this.translate('Label_Options')}
                            menu={[]} />
                    </div>
                </div>
                </div>
                <div className="footer-btn-holder">
                <WmsButton
                    label={this.translate("Label_Cancel")}
                    wmsSelector={this.state.selector + "DiscardExit"}
                    onClickFunc={this.eventClickDiscardButton}
                    buttonType={'orange'}
                    icon={(this.props.sharedEditAllocationData.modalType == 'GridEditAllocations') ? "fa-ban" : "fa-times-circle"}
                />
                {this.props.sharedEditAllocationData.modalType == 'GridEditAllocations' &&
                    <WmsButton
                        label={this.translate("Label_Save")}
                        wmsSelector={this.state.selector + "Save"}
                        onClickFunc={this.eventClickSaveAllocation}
                        disabled={
                            this.state.valueGreater ||
                            this.state.totalInputedQuantity != this.props.sharedEditAllocationData.totalOrdered ||
                            this.state.gifEditAllocation  ||
                            this.state.disableSave
                        }
                        icon="fa-floppy-o"
                        showToolTip={
                            this.state.valueGreater ||
                            this.state.totalInputedQuantity != this.props.sharedEditAllocationData.totalOrdered
                        }
                        tooltipText={(
                            this.state.valueGreater ||
                            this.state.totalInputedQuantity != this.props.sharedEditAllocationData.totalOrdered
                        ) ? this.translate('Label_Enable_Edit_Allocations_Save') : ''}
                        loading={this.state.gifEditAllocation}
                    />
                }
                {this.props.sharedEditAllocationData.modalType == 'GridMassAdd' && (
                    <div>
                        <WmsButton
                            label={this.translate("Label_Add_and_New")}
                            wmsSelector={this.state.selector + "AddAndNewButton"}
                            onClickFunc={() => this.addLineItem('AddNew')}
                            disabled={this.state.valueGreater || this.state.savingGifAddNew}
                            icon="fa-plus"
                            loading={this.state.savingGifAddNew}
                        />

                        <WmsButton
                            label={this.translate("Label_Add_and_Exit")}
                            wmsSelector={this.state.selector + "AddExitButton"}
                            onClickFunc={() => this.addLineItem('AddExit')}
                            disabled={this.state.valueGreater}
                            icon="fa-plus"
                            loading={this.state.savingGifAddExit}
                        />
                    </div>
                )}
                </div>
            </WmsModel >

        );
    }
}