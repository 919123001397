import React from "react";
import _ from "lodash";
import { EventNotificationGrid } from "./event-notification-grid.component.jsx";
import { EventNotificationSearch } from "./event-notification-search.component.jsx";
import "./eventNotification.scss";
import * as EventNotificationMetaServices from "./event-notification-meta-data.service.js";
import * as eventNotificationsService from "./event-notifications.services.js";

export class EventNotificationHolderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.$rootScope = window;
        this.windowId = this.$rootScope.ChildBulletin.Data.Window;
        this.selector = "EventNotification";
        this.translate = window.translate;

        this.defaultFilterValue = {
            customerEditUri: "",
        };

        this.state = {
            sharedCustomerOptions: [],
            sharedEventNotificationFilter: this.defaultFilterValue,
            sharedSearchOn: false,
            isMetaCallComplete: false,
        };
    }

    async componentDidMount() {
        kendo.ui.progress($("#" + this.windowId), true);

        var sharedEventNotificationFilter = Object.assign({}, this.defaultFilterValue);
        var sharedSearchOn = false;
        await EventNotificationMetaServices.getWebhookNotificationClientMetaData();
        var filterData = EventNotificationMetaServices.getWebhookNotificationFilterMetaData();
        if (filterData !== "" && !_.isEqual(this.defaultFilterValue, filterData)) {
            sharedEventNotificationFilter = filterData;
            sharedSearchOn = true;
        }

        eventNotificationsService
            .getCustomerDropDown()
            .then((response) => {
                var tmpCustOpt = response.data;
                tmpCustOpt = tmpCustOpt.sort((i, j) => (i.Name.toUpperCase() > j.Name.toUpperCase() ? 1 : -1));
                tmpCustOpt.unshift({
                    EditUri: "",
                    Name: "Any Customer",
                });

                this.setState({
                    sharedCustomerOptions: tmpCustOpt,
                    sharedSearchOn: sharedSearchOn,
                    sharedEventNotificationFilter: sharedEventNotificationFilter,
                });
            })
            .finally(() => {
                kendo.ui.progress($("#" + this.windowId), false);
                this.setState({
                    isMetaCallComplete: true,
                });
            });
    }

    onClearSearch = () => {
        this.setState(
            {
                sharedEventNotificationFilter: Object.assign({}, this.defaultFilterValue),
                sharedSearchOn: false,
            },
            () => {
                EventNotificationMetaServices.clearWebhookNotificationFilterMetaData();
                this.gridComponent.loadGrid();
            }
        );
    };

    onFilterDataSet = (param_filterObj) => {
        this.setState(
            {
                sharedEventNotificationFilter: param_filterObj,
                sharedSearchOn: !_.isEqual(this.defaultFilterValue, param_filterObj),
            },
            () => {
                EventNotificationMetaServices.setWebhookNotificationFilterMetaData(param_filterObj);
                this.gridComponent.loadGrid();
            }
        );
    };

    render() {
        return (
            <div id="wms-event-notification-wrapper" className="new-layout-grid-filter-wrapper">
                {this.state.isMetaCallComplete && (
                    <EventNotificationSearch
                        windowId={this.windowId}
                        selector={this.selector}
                        sharedSearchOn={this.state.sharedSearchOn}
                        sharedCustomerOptions={this.state.sharedCustomerOptions}
                        onFilterDataSet={this.onFilterDataSet}
                        sharedEventNotificationFilter={this.state.sharedEventNotificationFilter}
                        onClearSearch={this.onClearSearch}
                        onCloseFunc={this.onClearSearch}
                    />
                )}

                {this.state.isMetaCallComplete && (
                    <EventNotificationGrid
                        ref={(instance) => {
                            this.gridComponent = instance;
                        }}
                        windowId={this.windowId}
                        selector={this.selector}
                        sharedMultiSkuEnableRecurring={false}
                        sharedCustomerOptions={this.state.sharedCustomerOptions}
                        sharedEventNotificationFilter={this.state.sharedEventNotificationFilter}
                    />
                )}
            </div>
        );
    }
}
