import React from 'react';
import PropTypes from 'prop-types';
import WmsContextMenuItem from '../WmsContextMenuItem/WmsContextMenuItem.jsx';
import style from './WmsContextMenu.scss';

var that, KendoGrid, textarea, container;
const MyLi = (props) => {
    return (
        <div>

        </div>
    )
}
class WmsContextMenu extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            uniqueId: "random" + Math.floor((Math.random() * 1000) + 1) + "" + Date.now(),
            isOpen: false,
            clipBoardText: '',
            clipBoardTextRow: ''

        }
        this.eventClickContextMenu = this.eventClickContextMenu.bind(this);
        this.createConatinerElement();
    };
    createConatinerElement() {
        if (!textarea) {
            container = document.createElement('div');
            container.id = 'simulate-clipboard-container';
            container.setAttribute('style', ['opacity: 0;height: 0;width: 0;'].join(''));
            document.body.appendChild(container);
            textarea = document.createElement('textarea');
            textarea.id = 'simulate-clipboard';
            textarea = textarea;
            container.appendChild(textarea);
        }
    }
    componentDidMount() {
        $("#" + this.state.uniqueId).hide();
        setTimeout(() => {
            $("#" + this.state.uniqueId).show();
            this.loadKendoMenu();
        },10);
    }

    loadKendoMenu() {
        var that = this;
        $("#" + this.state.uniqueId).kendoContextMenu({
            target: this.props.attachTo,
            animation: {
                open: { effects: "slideIn:right" },
                duration: 500
            },
            orientation: "vertical",
            open: function (e) {
                if($(e.item).hasClass('mydisabled')){
                    e.preventDefault();
                }
                let itemRole = $(e.item).attr('role');
                if (itemRole == "menubar") {
                    that.setState({
                        isOpen: true
                    });
                }
                that.props.onBeforeOpen(e);
            },
            close: function(e) {
                let itemRole = $(e.item).attr('role');
                if (itemRole == "menubar") {
                    that.setState({
                        isOpen: false
                    });
                }
            },
            filter: this.props.contextMenuFilter

        });

        $(that.props.attachTo).on("contextmenu", "tr>td", function (e) {
            that.setState({ clipBoardText: e.target.innerText == '' ? ' ' : e.target.innerText });
            setTimeout(() => {

                var grid = $(that.props.attachTo).data("kendoGrid");
                if(!grid){
                    return;
                }
                var selectedRow = grid.dataItem(grid.select());
                if (selectedRow) {
                    var filterdata = [];
                    var visibleColumns = grid.columns.filter(item => !item.hidden).map(item => item.field);
                    (selectedRow).forEach((value, key) => {
                        if (visibleColumns.includes(key)){
                            if(value !== null && value !== '' && typeof value !== 'object') {
                                filterdata.push(value);
                            } else if(value instanceof Date) {
                                filterdata.push(kendo.toString(kendo.parseDate(value), 'MMM dd yyyy, hh:mm:ss tt'));
                            }
                        }
                    });
                    that.setState({ clipBoardTextRow: filterdata.toString() });
                } else {
                    that.setState({ clipBoardTextRow: "Something wrong please try again" });
                }

            }, 0);

        });
    }

    onChangeFunc() { }

    eventClickContextMenu(obj,e) {
        var that = this;
        if (obj.disabled) {
            return;
        }

        if (obj.wmsid == 'copyField' || obj.wmsid == 'copyRow') {
            var copyText = obj.wmsid == 'copyField' ? that.state.clipBoardText : that.state.clipBoardTextRow;
            var copyTextarea = document.querySelector('#simulate-clipboard');
            var node = document.createTextNode(copyText);
            copyTextarea.appendChild(document.createTextNode(copyText));
            copyTextarea.select();
            var msg = document.execCommand('copy');
            $('#simulate-clipboard').text('');
        } else {
            this.props.eventClickContextMenu(obj,e);
        }
        var contextMenu = $("#" + this.state.uniqueId).data("kendoContextMenu");
        // close the ContextMenu
        contextMenu.close(100, 100);
    }

    // close context menu on hover of button dropdown
    closeWmsContextMenu = () => {
        var contextMenu = $("#" + this.state.uniqueId).data("kendoContextMenu");
        // close the ContextMenu
        contextMenu.close(100, 100);
    }

    eventMouseEnter = (obj) => {
        this.props.eventMouseEnter(obj.disabledText);
    }

    eventMouseLeave = (obj) => {
        this.props.eventMouseLeave();
    }

    render() {
        return (
            <ul id={this.state.uniqueId} className="context-menu-wrapper"  >
                {this.props.menu.map((obj, index) =>
                    (typeof obj.isContextMenu == "undefined" || obj.isContextMenu == true) ?
                        obj.value.length <= 1 ?
                            <li
                                key={index}
                                data-wms-selector={obj.wmsSelector + '_C'}
                                className={obj.disabled == true ? "mydisabled k-item k-state-default" : 'k-item k-state-default'}
                                onMouseLeave={obj.disabled == true ? () => this.eventMouseLeave(obj) : () => function () { }}
                                onMouseEnter={obj.disabled == true ? () => this.eventMouseEnter(obj) : () => function () { }}
                                onClick={(e) => this.eventClickContextMenu(obj,e)}
                                data-run-report={obj.runReport}
                                data-rdl-name={obj.rdlName}>
                                {obj.name}

                            </li> :
                            <li key={index} data-wms-selector={obj.wmsSelector + '_C'}>
                                {obj.name}
                                <WmsContextMenuItem items={obj.value} count={index + 1} onClickContextMenu={this.eventClickContextMenu} eventMouseEnter={this.eventMouseEnter}  eventMouseLeave={this.eventMouseLeave}/>

                                {/* <ul>
                                    {obj.value.map((obj2, index) =>
                                        (typeof obj2.isContextMenu == "undefined" || obj2.isContextMenu == true) ?
                                            <li
                                                key={index}
                                                data-wms-selector={obj2.wmsSelector + '_C'}
                                                className={obj2.disabled == true ? "mydisabled k-item k-state-default" : 'k-item k-state-default'}
                                                onMouseLeave={obj2.disabled == true ? () => this.eventMouseLeave(obj2) : () => function () { }}
                                                onMouseEnter={obj2.disabled == true ? () => this.eventMouseEnter(obj2) : () => function () { }}
                                                onClick={() => this.eventClickContextMenu(obj2)}>
                                                {obj2.name}
                                            </li> : ""
                                    )}
                                </ul> */}
                            </li> : ""
                )}
            </ul>
        );
    }
};
WmsContextMenu.propTypes = {
    attachTo: PropTypes.string.isRequired,
    eventClickContextMenu: PropTypes.func.isRequired,
    menu: PropTypes.array.isRequired,
    contextMenuFilter: PropTypes.string,
    eventMouseEnter: PropTypes.func.isRequired,
    eventMouseLeave: PropTypes.func.isRequired,
    onBeforeOpen: PropTypes.func
}

WmsContextMenu.defaultProps = {
    contextMenuFilter: 'tbody tr:not(.k-grid-edit-row)'
}

export default WmsContextMenu;