export const getGridDemoColumns = (SmartParcel_GridHeaders, CustomLabels) => {

    return ({

        columns: [{
            field: "RowNumber",
            title: "#",
            width: 60,
            wmsid: 1,
            locked: true,
            lockable: false,
            resizable: false,
            sortable: false,
            reorderable: false,
            filterable: false,
            attributes: { style: "text-align:center;" },
            headerAttributes: { style: "text-align:center;" }
        }, {
            field: "Carrier",
            title: SmartParcel_GridHeaders[3].description,
            width: 120,
            wmsid: 4,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'SmallParcelGridCarrier'
            },
            template: function (dataItem) {
                return dataItem.Carrier == "PitneyBowesStd" ? "Pitney Bowes Standard" : ( dataItem.Carrier != null ? dataItem.Carrier : '');
            },

        }, {
            field: "Customer",
            title: CustomLabels.Label_Customer,
            width: 120,
            wmsid: 7,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'SmallParcelGridGridCustomer'
            }
        }, {
            field: "TotPackages",
            title: SmartParcel_GridHeaders[8].description,
            width: 120,
            wmsid: 9,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'SmallParcelGridTotPackages'
            },

        }, {
            field: "ShipService",
            title: SmartParcel_GridHeaders[15].description,
            width: 120,
            wmsid: 16,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'SmallParcelGridShipService'
            }
        }, {
            field: "SkuAndQty",
            title: CustomLabels.Label_Sku + SmartParcel_GridHeaders[19].description,
            width: 120,
            wmsid: 20,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'SmallParcelGridSkuAndQty'
            },

            template: "<span style='white-space: nowrap;text-overflow: ellipsis;width:95%;display: block;overflow: hidden'>#if (typeof SkuAndQty != 'undefined') {# #= SkuAndQty# #}#</span>"
        }, {
            field: "TrackingNumber",
            title: SmartParcel_GridHeaders[22].description,
            width: 120,
            wmsid: 23,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'SmallParcelGridTrackingNumber'
            },
            template: function(dataItem) {
                const {TrackingNumber, TrackingUrl} = dataItem;

                if (TrackingNumber != null) {
                    if (TrackingUrl != '') {
                        return `<a href='${TrackingUrl}' target='_blank' class='tracking-url'>${TrackingNumber}</a>`;
                    }

                    return TrackingNumber;
                }
                return '';
            }

        }, {
            field: "Warehouse",
            title: CustomLabels.Label_Warehouse,
            width: 120,
            wmsid: 26,
            lockable: false,
            headerAttributes: {
                "data-wms-selector": 'SmallParcelGridWarehouse'
            },

        }]
    })
}

export const getAllColumnList = (SmartParcel_GridHeaders, CustomLabels) => {


    var translate = window.translate;
    return ([{
        field: "RowNumber",
        title: "#",
        width: 60,
        wmsid: 1,
        locked: true,
        lockable: false,
        sortable: false,
        resizable: false,
        reorderable: false,
        filterable: false,
        isShawRowNumberColumnInList: true,
        attributes: { style: "text-align:center;" },
        headerAttributes: { style: "text-align:center;" }
    }, {
        field: "BatchOrderId",
        title: SmartParcel_GridHeaders[1].description,
        width: 120,
        wmsid: 2,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridGridBatchOrderId'
        },

    }, {
        field: "BatchName",
        title: SmartParcel_GridHeaders[2].description,
        width: 120,
        wmsid: 3,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridGridBatchName'
        },

    }, {
        field: "Carrier",
        title: SmartParcel_GridHeaders[3].description,
        width: 120,
        wmsid: 4,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridCarrier'
        },
        template: function (dataItem) {
            return dataItem.Carrier == "PitneyBowesStd" ? "Pitney Bowes Standard" : ( dataItem.Carrier != null ? dataItem.Carrier : '');
            // return dataItem.Carrier == "PitneyBowesStd" ? "Pitney Bowes Standard" : dataItem.Carrier;
        },

    }, {
        field: "CreationDate",
        title: SmartParcel_GridHeaders[4].description,
        width: 120,
        wmsid: 5,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridGridCreationDate'
        },
        template: "#= (typeof CreationDate != 'undefined' && CreationDate != null) ?  kendo.toString(kendo.parseDate(CreationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"

    }, {
        field: "TransactionSource",
        title: SmartParcel_GridHeaders[5].description,
        width: 120,
        wmsid: 6,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridTransactionSource'
        },

    }, {
        field: "Customer",
        title: CustomLabels.Label_Customer,
        width: 120,
        wmsid: 7,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridGridCustomer'
        }
    }, {
        field: "HvpBatchId",
        title: SmartParcel_GridHeaders[49].description,
        width: 120,
        wmsid: 7,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridGridCustomer'
        }
    }, {
        field: "HvpBatchName",
        title: SmartParcel_GridHeaders[50].description,
        width: 120,
        wmsid: 7,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridGridCustomer'
        }
    }, {
        field: "TotalLines",
        title: SmartParcel_GridHeaders[7].description,
        width: 120,
        wmsid: 8,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridTotalLines'
        },
    }, {
        field: "TotPackages",
        title: SmartParcel_GridHeaders[8].description,
        width: 120,
        wmsid: 9,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridTotPackages'
        },

    }, {
        field: "PackDoneDate",
        title: SmartParcel_GridHeaders[9].description,
        width: 120,
        wmsid: 10,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridPackDoneDate'
        },

        template: "#= (typeof PackDoneDate != 'undefined' && PackDoneDate != null) ? kendo.toString(kendo.parseDate(PackDoneDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
    }, {
        field: "PickDoneDate",
        title: SmartParcel_GridHeaders[10].description,
        width: 120,
        wmsid: 11,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridPickDoneDate'
        },

        template: "#= (typeof PickDoneDate != 'undefined' && PickDoneDate != null) ? kendo.toString(kendo.parseDate(PickDoneDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
    }, {
        field: "PickTicketPrintDate",
        title: SmartParcel_GridHeaders[11].description,
        width: 120,
        wmsid: 12,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'OrderGriPickTicketPrintDate'
        },

        template: "#= (typeof PickTicketPrintDate != 'undefined' && PickTicketPrintDate != null) ? kendo.toString(kendo.parseDate(PickTicketPrintDate), 'MMM dd yyyy, hh:mm:ss tt'): '' #"
    }, {
        field: "PoNum",
        title: SmartParcel_GridHeaders[12].description,
        width: 120,
        wmsid: 13,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridPoNum'
        },

    }, {
        field: "ReferenceNum",
        title: SmartParcel_GridHeaders[13].description,
        width: 120,
        wmsid: 14,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridGridReferenceNum'
        }
    }, {
        field: "RetailerId",
        title: SmartParcel_GridHeaders[14].description,
        width: 120,
        wmsid: 15,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridRetailerId'
        },

    }, {
        field: "ShipService",
        title: SmartParcel_GridHeaders[15].description,
        width: 120,
        wmsid: 16,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipService'
        }
    }, {
        field: "ShipToZip",
        title: SmartParcel_GridHeaders[16].description,
        width: 120,
        wmsid: 17,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipToZip'
        },

    }, {
        field: "ShipToState",
        title: SmartParcel_GridHeaders[17].description,
        width: 120,
        wmsid: 18,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipToState'
        },

    }, {
        field: "ShipToCountry",
        title: SmartParcel_GridHeaders[18].description,
        width: 120,
        wmsid: 19,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipToCountry'
        },

    }, {
        field: "SkuAndQty",
        title: CustomLabels.Label_Sku + SmartParcel_GridHeaders[19].description,
        width: 120,
        wmsid: 20,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridSkuAndQty'
        },

        template: "<span style='white-space: nowrap;text-overflow: ellipsis;width:95%;display: block;overflow: hidden'>#if (typeof SkuAndQty != 'undefined') {# #= SkuAndQty# #}#</span>"
    }, {
        field: "SmallParcelShipDate",
        title: SmartParcel_GridHeaders[20].description,
        width: 120,
        wmsid: 21,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridSmallParcelShipDate'
        },
        template: "#= (typeof SmallParcelShipDate != 'undefined' && SmallParcelShipDate != null) ? kendo.toString(kendo.parseDate(SmallParcelShipDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "TotalItemQty",
        title: SmartParcel_GridHeaders[21].description,
        width: 120,
        wmsid: 22,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridTotalItemQty'
        }
    }, {
        field: "TrackingNumber",
        title: SmartParcel_GridHeaders[22].description,
        width: 120,
        wmsid: 23,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridTrackingNumber'
        },
        template: function(dataItem) {
            const {TrackingNumber, TrackingUrl} = dataItem;

            if (TrackingNumber != null) {
                if (TrackingUrl != '') {
                    return `<a href='${TrackingUrl}' target='_blank' class='tracking-url'>${TrackingNumber}</a>`;
                }

                return TrackingNumber;
            }
            return '';
        }
    }, {
        field: "TotVolumeImperial",
        title: SmartParcel_GridHeaders[23].description,
        width: 120,
        wmsid: 24,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridTotVolumeImperial'
        },

    }, {
        field: "TotVolumeMetric",
        title: SmartParcel_GridHeaders[24].description,
        width: 120,
        wmsid: 25,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridTotVolumeMetric'
        },

    }, {
        field: "Warehouse",
        title: CustomLabels.Label_Warehouse,
        width: 120,
        wmsid: 26,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridWarehouse'
        },

    }, {
        field: "TotWeightImperial",
        title: SmartParcel_GridHeaders[26].description,
        width: 120,
        wmsid: 27,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridTotWeightImperial'
        },

    }, {
        field: "TotWeightMetric",
        title: SmartParcel_GridHeaders[27].description,
        width: 120,
        wmsid: 28,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridTotWeightMetric'
        },

    }, {
        field: "OrderId",
        title: SmartParcel_GridHeaders[28].description,
        width: 120,
        wmsid: 29,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridOrderId'
        },
    }, {
        field: "ParcelLabelType",
        title: SmartParcel_GridHeaders[29].description,
        width: 120,
        wmsid: 30,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridParcelLabelType'
        },
        template: "# if(typeof ParcelLabelType != 'undefined' && ParcelLabelType === 0){# " + translate('Label_parcel_type_none') + " #}else if(typeof ParcelLabelType != 'undefined' && ParcelLabelType === 1){# " + translate('Label_parcel_type_pdf') + " #}else{# " + translate('Label_parcel_type_zpl') + " #} # "
    }, {
        field: "ShipToName",
        title: SmartParcel_GridHeaders[30].description,
        width: 120,
        wmsid: 31,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipToName'
        },
    }, {
        field: "ShipToCompany",
        title: SmartParcel_GridHeaders[31].description,
        width: 120,
        wmsid: 32,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipToCompany'
        },
    }, {
        field: "ShipToAddress",
        title: SmartParcel_GridHeaders[32].description,
        width: 120,
        wmsid: 33,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipToAddress'
        },
    }, {
        field: "ShipToAddress2",
        title: SmartParcel_GridHeaders[33].description,
        width: 120,
        wmsid: 34,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipToAddress2'
        },
    }, {
        field: "ShipToCity",
        title: SmartParcel_GridHeaders[34].description,
        width: 120,
        wmsid: 35,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipToCity'
        },
    }, {
        field: "EndOfDayDate",
        title: SmartParcel_GridHeaders[35].description,
        width: 120,
        wmsid: 36,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridEndOfDayDate'
        },
        template: "#= (typeof EndOfDayDate != 'undefined' && EndOfDayDate != null) ? kendo.toString(kendo.parseDate(EndOfDayDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "AsnSentDate",
        title: SmartParcel_GridHeaders[36].description,
        width: 120,
        wmsid: 37,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridAsnSentDate'
        },
        template: "#= (typeof AsnSentDate != 'undefined' && AsnSentDate != null) ? kendo.toString(kendo.parseDate(AsnSentDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "BillingCode",
        title: SmartParcel_GridHeaders[37].description,
        width: 120,
        wmsid: 38,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridBillingCode'
        }
    }, {
        field: "CreatedByIdentifier",
        title: SmartParcel_GridHeaders[38].description,
        width: 120,
        wmsid: 39,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridCreatedByIdentifier'
        },
        template: "#= (typeof CreatedByIdentifier != 'undefined' && CreatedByIdentifier && CreatedByIdentifier.Name) ? CreatedByIdentifier.Name : '' #"
    }, {
        field: "EarliestShipDate",
        title: SmartParcel_GridHeaders[39].description,
        width: 120,
        wmsid: 40,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridEarliestShipDate'
        },
        template: "#= (typeof EarliestShipDate != 'undefined' && EarliestShipDate != null) ? kendo.toString(kendo.parseDate(EarliestShipDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }, {
        field: "LoadNumber",
        title: SmartParcel_GridHeaders[40].description,
        width: 120,
        wmsid: 41,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridLoadNumber'
        }
    }, {
        field: "PickerIdentifier",
        title: SmartParcel_GridHeaders[41].description,
        width: 120,
        wmsid: 42,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridPickerIdentifier'
        },
        template: "#= (typeof PickerIdentifier != 'undefined' && PickerIdentifier && PickerIdentifier.Name) ? PickerIdentifier.Name : '' #"
    }, {
        field: "PickJobId",
        title: SmartParcel_GridHeaders[42].description,
        width: 120,
        wmsid: 43,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridPickJobId'
        }
    }, {
        field: "ShipToEmail",
        title: SmartParcel_GridHeaders[43].description,
        width: 120,
        wmsid: 44,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipToEmail'
        }
    }, {
        field: "ShipToPhone",
        title: SmartParcel_GridHeaders[44].description,
        width: 120,
        wmsid: 45,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipToPhone'
        }
    }, {
        field: "Status",
        title: SmartParcel_GridHeaders[45].description,
        width: 120,
        wmsid: 46,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridStatus'
        }
    }, {
        field: "Notes",
        title: SmartParcel_GridHeaders[46].description,
        width: 120,
        wmsid: 47,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridNotes'
        }
    }, {
        field: "ShipToIsResidential",
        title: SmartParcel_GridHeaders[47].description,
        width: 120,
        wmsid: 48,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridShipToIsResidential'
        },
        template: "#= (ShipToIsResidential == true) ? 'Yes' : 'No' #"
    }, {
        field: "ProcessDate",
        title: SmartParcel_GridHeaders[48].description,
        width: 120,
        wmsid: 49,
        lockable: false,
        headerAttributes: {
            "data-wms-selector": 'SmallParcelGridProcessDate'
        },
        template: "#= (typeof ProcessDate != 'undefined' && ProcessDate != null) ? kendo.toString(kendo.parseDate(ProcessDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
    }]
    )
}

export const getGridDemoSchema = () => {
    return ({
        model: {
            id: "OrderId",
            fields: {
                OrderId: { type: "number" },
                ReferenceNum: { type: "string" },
                BatchOrderId: { type: "number" },
                BatchName: { type: "string" },
                Carrier: { type: "string" },
                CreationDate: { type: "date" },
                TransactionSource: { type: "string" },
                Customer: { type: "string" },
                CustomerId: { type: "number" },
                CustomerUri: { type: "string" },
                Warehouse: { type: "string" },
                FacilityId: { type: "number" },
                TotalLines: { type: "number" },
                PickDoneDate: { type: "date" },
                PackDoneDate: { type: "date" },
                PickTicketPrintDate: { type: "date" },
                PoNum: { type: "string" },
                RetailerId: { type: "string" },
                ShipService: { type: "string" },
                ShipToZip: { type: "string" },
                ShipToState: { type: "string" },
                ShipToCountry: { type: "string" },
                SkuAndQty: { type: "string" },
                SmallParcelShipDate: { type: "date" },
                TrackingNumber: { type: "string" },
                ShipToName: { type: "string" },
                ShipToCompany: { type: "string" },
                ShipToAddress: { type: "string" },
                ShipToAddress2: { type: "string" },
                ShipToCity: { type: "string" },
                EndOfDayDate: { type: "date" },
                AsnSentDate: { type: "date" },
                BillingCode: { type: "string" },
                ShippingNotes: { type: "string" },
                CreatedByIdentifier: { type: "object" },
                EarliestShipDate: { type: "date" },
                LoadNumber: { type: "string" },
                PickerIdentifier: { type: "object" },
                PickJobId: { type: "string" },
                ShipToEmail: { type: "string" },
                ShipToPhone: { type: "string" },
                Status: { type: "string" },
                Notes: { type: "string" },
                ShipToIsResidential: { type: "boolean" },
                ProcessDate: { type: "date" }
                // TotVolumeImperial: { type: "string" },
                // TotVolumeMetric: { type: "string" },
                // TotWeightImperial: { type: "string" },
                // TotWeightMetric: { type: "string" }
            }
        }
    })
}