import React from 'react';
import { manageOrdersHolderVirtual } from '../orders/manage-orders-holder.virtual.component.jsx';
import { ManageOrdersFormProto } from '../orders/manageOrders/manage-orders-form-proto.component.jsx';
import { importOrdersHolder } from '../importOrders/import-orders-holder.component.jsx';
import { OrderPreferences } from "../orderPreferences/order-preferences-holder.component.jsx";
import { reportIframe } from '../reportIframe/report-iframe.component.jsx';
import { FindReceiptHolder as FindReceiptHolderProto } from "../receipt/findReceipt_proto/findReceipt-holder.component.jsx";
import { ManageReceiptHolder } from "../receipt/manageReceipt/manageReceipt-holder.component.jsx";
import { ImportAsnHolder } from "../importASN/import-asn-holder.component.jsx";
import { AsnImportPreferences } from "../asnImportPreferences/asn-import-preferences-holder.component.jsx";
import { InventoryHolder } from '../inventory/inventory-holder.component.jsx';
import { cyclecountHolder } from '../cyclecount/cyclecount-holder.component.jsx';
import  AuditInventoryHolder  from '../auditInventory/audit-inventory-holder.component.tsx';
import { transactionsIframeList } from '../reportIframe/transactions/transactions-iframe-list.component.jsx';
import { itemsIframeList } from '../reportIframe/items/items-iframe-list.component.jsx';
import { locationsIframeList } from '../reportIframe/locations/locations-iframe-list.component.jsx';
import { managementIframeList } from '../reportIframe/management/management-iframe-list.component.jsx';
import { AttachFileToAssembly } from '../attachFileToAssembly/attach-file-to-assembly-holder.component.jsx';
import { OutboundProductivity } from '../outboundProductivity/outbound-productivity.component.jsx';
import { userBetaHolder } from '../users-beta/users-beta-holder.component.jsx';
import { rolesHolder } from '../roles/roles-holder.component.jsx';
import { customersHolder } from '../customers/customers-holder.component.jsx';
import { BarcodeHolder } from '../barcode/barcode-holder.component.jsx';
import { SmartParcelSettingsHolder } from '../customers/smart-parcel-settings/smart-parcel-settings-holder.jsx';
import { ItemsHolder } from '../items/items-holder.component.jsx';
import { warehouseHolder } from '../warehouse/warehouse-holder.component.jsx';
import { locationsHolder } from '../locations/locations-holder.component.jsx';
import { ThreeplSettingsHolder } from '../threeplSettings/threepl-settings-holder.component.jsx';
import { settingsHolder } from '../settings/settings-holder.component.jsx';
import { MobilePrintingListHolder } from "../iframePages/mobile-printing/mobile-printing-list-holder.component.jsx";
import { ManageCarriersHolder } from '../manageCarriers/manage-carriers-holder.component.jsx';
import { ApiSimulator } from '../api-simulator/api-simulator.component.jsx';
import { themesComponent } from '../themes/themes-component.jsx';
import { SmallParcelHolder } from '../smallParcel/smallParcel-holder.component.jsx';
import { SmallParcelHVPHolder } from '../smallParcelHVP/small-parcel-hvp-holder.component.tsx';
import { PackagingTypesHolder } from "../orders/packagingMaterials/packaging-types-holder.component.jsx";
import { SmallParcelSettingsHolder } from "../smallParcelSettings/small-parcel-settings-holder.component.jsx";
import * as GlobalService from './../global_services';
import { EventNotificationHolderComponent } from '../../event-notification/event-notification-holder.component.jsx';
import AdjustCustomerChargesHolder from '../../adjustCustomerCharges/adjust-customer-charges-holder.component.tsx';
import ConfigurePackingSlipsHolder from '../customers/configure-packing-slips/configure-packing-slips-holder.component.tsx'
import MobileLabelTemplatesHolder from "../../mobile-label-templates/mobile-label-templates-holder.component.tsx";
import AdjustInventoryHolder from '../../adjust-inventory/adjust-inventory-holder.component';
import LaborAnalyticsDashboard from '../../labor-analytics/labor-analytics.component.tsx';
import ManageAssembliyHolder from '../../manage-assembly/manage-assembly-holder.component';

export const getMenuList = (customerLabel) => {
    return ([{
        'class': 'dropdown',
        'icon': 'fa fa-sitemap',
        'id': 'wms_Orders',
        'title': 'Orders_Page_Title',
        'dataWmsSelector': 'order_MainMenu',
        'rights': ['orders.edit.view', 'orders.manage.view', 'orders.import.manage', 'orders.import.prefs'],
        'subMenu': [{
            'title': 'Window_Title_Label_FindOrders',
            'class': '',
            'isModal': false,
            'rights': ['orders.manage.view'],
            'dataWmsSelector': 'findOrder_SubMenu',
            'clickParamObj': {
                // url: '<find-orders-component></find-orders-component>',
                url: "<react-find-orders-holder></react-find-orders-holder>",
                reactComponent: manageOrdersHolderVirtual,
                singleton: true,
                title: 'Window_Title_Label_FindOrders',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_FindOrders'
                }],
                icon: 'submenu-icon fa fa-search',
                urlParam: 'findorders'
            }

        }, {
            'title': 'Window_Title_Label_CreateOrder',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['orders.edit.view'],
            'dataWmsSelector': 'createOrder_Proto_SubMenu',
            'clickParamObj': {
                // url: '<manage-orders-component></manage-orders-component>',
                url: '<manage-orders-form-proto></manage-orders-form-proto>',
                reactComponent: ManageOrdersFormProto,
                singleton: false,
                title: 'Label_Manage_Order',
                active: true,
                useLineItem: true,
                lineItem: [{
                    name: '',
                    value: 'Label_Manage_Order'
                }],
                icon: 'submenu-icon fa fa-plus',
                urlParam: 'manageorder-v3'
            }

        }, {
            'title': 'Label_Import_Order',
            'class': '',
            'isModal': false,
            'rights': ['orders.import.manage'],
            'dataWmsSelector': 'importOrder_SubMenu',
            'clickParamObj': {
                url: "<react-import-order-holder></react-import-order-holder>",
                reactComponent: importOrdersHolder,
                singleton: true,
                title: 'Label_Import_Order',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Import_Order'
                }],
                icon: 'submenu-icon fa fa-download',
                urlParam: 'importorders'
            }

        }, {
            'title': 'Slideout_QuickLinks_OrderImportPreferences',
            'class': '',
            'isModal': false,
            'rights': ['orders.import.prefs'],
            'dataWmsSelector': 'importOrderPreferences_SubMenu',
            'clickParamObj': {
                url: "<react-order-preferences-holder></react-order-preferences-holder>",
                reactComponent: OrderPreferences,
                singleton: true,
                title: 'Slideout_QuickLinks_OrderImportPreferences',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: ' Slideout_QuickLinks_OrderImportPreferences'
                }],
                icon: 'submenu-icon fa fa-cogs',
                urlParam: 'import-order-preferences'
            }
        }, {
            'title': 'Label_Shipping_Setups',
            'class': '',
            'isModal': false,
            'rights': ['isWarehouseUser'],
            'dataWmsSelector': 'ShippingSetups_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ShowOrderSetupOptions.aspx',
                isIframe: true,
                title: 'Label_Shipping_Setups',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Shipping_Setups'
                }],
                icon: 'submenu-icon fa fa-sliders',
                urlParam: 'shippingsetups'
            }

        }, {
            'title': 'Label_Manage_Packing_Materials',
            'class': '',
            'id': 'wms_Orders_Packaging_Types',
            'isModal': false,
            'rights': ['isWarehouseUser'],
            'dataWmsSelector': 'packagingTypes_SubMenu',
            'clickParamObj': {
                url: "<react-packaging-types-holder></react-packaging-types-holder>",
                reactComponent: PackagingTypesHolder,
                singleton: true,
                title: 'Label_Manage_Packing_Materials',
                active: true,
                icon: 'submenu-icon fas fa-box-open',
                urlParam: 'packaging-types'
            }
        }, {
            'title': 'Label_Packaging_Options',
            'class': '',
            'isModal': false,
            'rights': ['isWarehouseUser'],
            'dataWmsSelector': 'PackagingOptions_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ShowOrderPkgOptions.aspx',
                isIframe: true,
                title: 'Label_Packaging_Options',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Packaging_Options'
                }],
                icon: 'submenu-icon fa fa-filter',
                urlParam: 'packagingoptions'
            }

        }, {
            'title': 'Label_Smart_Pack',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['Shipping.WarehousePackEnabled', 'UxPrototypingDirectivesShowClassicSP', 'isWarehouseUser'],
            'dataWmsSelector': 'SmartPack_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/WarehousePack.aspx',
                isIframe: true,
                title: 'Label_Smart_Pack',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Smart_Pack'
                }],
                icon: 'submenu-icon fa fa-ship',
                urlParam: 'smartpack'
            }

        }, {
            'title': 'Label_Smart_Pack_Settings',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['Shipping.WarehousePackEnabled', 'UxPrototypingDirectivesShowClassicSP', 'isWarehouseUser'],
            'dataWmsSelector': 'SmartPackSettings_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ScanPackSettings.aspx',
                isIframe: true,
                title: 'Label_Smart_Pack_Settings',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Smart_Pack_Settings'
                }],
                icon: 'submenu-icon fa fa-cog',
                urlParam: 'smart-pack-settings'
            }

        }, {
            'title': 'Label_Smart_Adust_Order_Charges',
            'class': '',
            'isModal': false,
            'rights': ['admin.adjustcharges', 'Integrations.isNotActiveBillingManagerApp'],
            'rightsOperator': 'and',
            'dataWmsSelector': 'SmartAdustOrderCharges_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/OrdCostAdj.aspx',
                isIframe: true,
                title: 'Label_Smart_Adust_Order_Charges',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Smart_Adust_Order_Charges'
                }],
                icon: 'submenu-icon fa fa-dashcube',
                urlParam: 'smart-adjust-order-charges'
            }

        }, {
            'title': 'Label_Smart_Reprint_UPS_Labels',
            'class': '',
            'isModal': false,
            'rights': ['showUPSReprintMenu'],
            'dataWmsSelector': 'SmartReprintUPSLabels_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ShowOrdersWithLabels.aspx',
                isIframe: true,
                title: 'Label_Smart_Reprint_UPS_Labels',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Smart_Reprint_UPS_Labels'
                }],
                icon: 'submenu-icon fa fa-print',
                urlParam: 'smart-reprint-ups-labels'
            }

        }]
    }, // For receipts
    {
        'class': 'dropdown ',
        'icon': 'fa fa-file-text',
        'id': 'wms_Receipts',
        'title': 'Label_Receipts',
        'dataWmsSelector': 'receipt_MainMenu',
        'rights': ['receipts.manage.view', 'receipts.edit.view', 'receipts.import.manage', 'receipts.import.prefs'],
        'subMenu': [{
            'title': 'Receiving_Lbl_FindReceipt',
            'class': '',
            'isModal': false,
            'dataWmsSelector': 'findReceipt_SubMenu_proto',
            'rights': ['receipts.manage.view'],
            'parentClass': 'showReceiptTooltip',
            'clickParamObj': {
                url: '<react-find-receipt-holder-proto></react-find-receipt-holder-proto>',
                reactComponent: FindReceiptHolderProto,
                singleton: true,
                title: 'Receiving_Lbl_FindReceipt',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Receiving_Lbl_FindReceipt'
                }],
                icon: 'submenu-icon fa fa-search',
                urlParam: 'findreceiptv2'
            }

        }, {
            'title': 'Label_Create_Receipts',
            'class': '',
            'isModal': false,
            'rights': ['receipts.edit.view'],
            'dataWmsSelector': 'createReceipt_SubMenu',
            'clickParamObj': {
                url: '<react-manage-receipt-holder></react-manage-receipt-holder>',
                reactComponent: ManageReceiptHolder,
                singleton: false,
                title: 'Label_Create_Receipts',
                active: true,
                useLineItem: true,
                lineItem: [{
                    name: '',
                    value: 'Label_Create_Receipts'
                }],
                icon: 'submenu-icon fa fa-plus',
                flow: 'create',
                urlParam: 'managereceipt'
            }

        }, {
            'title': 'Label_Import_ASN',
            'class': 'orange',
            'isModal': false,
            'dataWmsSelector': 'importASN_All_SubMenu',
            'rights': ['receipts.import.manage'],
            'clickParamObj': {
                url: '<react-import-asn-holder></react-import-asn-holder>',
                reactComponent: ImportAsnHolder,
                singleton: true,
                title: 'Label_Import_ASN',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Import_ASN'
                }],
                icon: 'submenu-icon fa fa-download',
                urlParam: 'import-receipts'
            }
        }, {
            'title': 'Label_Receipt_Import_ASN_Preferences',
            'class': '',
            'isModal': false,
            'rights': ['receipts.import.prefs'],
            'dataWmsSelector': 'receipt_importASNPreferences_MainMenu',
            'clickParamObj': {
                url: "<react-asn-import-preference-holder></react-asn-import-preference-holder>",
                reactComponent: AsnImportPreferences,
                singleton: true,
                title: 'Label_Receipt_Import_ASN_Preferences',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: ' Label_Receipt_Import_ASN_Preferences'
                }],
                icon: 'submenu-icon fa fa-cogs',
                urlParam: 'import-asn-preferences'

            }
        }, {
            'title': 'Label_Import_Adjust_Receipt_Charge',
            'class': '',
            'isModal': false,
            'rights': ['admin.adjustcharges', 'Integrations.isNotActiveBillingManagerApp'],
            'rightsOperator': 'and',
            'dataWmsSelector': 'ImportAdjustReceiptCharge_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/RcvCostAdj.aspx',
                isIframe: true,
                title: 'Label_Import_Adjust_Receipt_Charge',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Import_Adjust_Receipt_Charge'
                }],
                icon: 'submenu-icon fa fa-dashcube',
                urlParam: 'import-adjust-receipt-charge'
            }

        }]
    }, // For Inventory
    {
        'class': 'dropdown ',
        'id': 'wms_Inventiry',
        'icon': 'fa fa-hourglass-start',
        'title': 'Window_Title_Label_Inventory',
        'dataWmsSelector': 'inventory_MainMenu',
        // 'rights': 'forcehide',
        'rights': ['inventory.manage', 'inventory.adjust', 'inventory.audit', 'inventory.edit', 'inventory.edittrack'],
        'subMenu': [{
            'title': 'Window_Title_Label_ManageInvetory',
            'dataWmsSelector': 'manageInventory_SubMenu_beta',
            'class': '',
            'isModal': false,
            'rights': ['inventory.manage'],
            'clickParamObj': {
                url: "<react-inventory-holder-beta></react-inventory-holder-beta>",
                reactComponent: InventoryHolder,
                singleton: true,
                title: 'Window_Title_Label_ManageInvetory',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_ManageInvetory'
                }],
                icon: 'submenu-icon fa fa-cogs',
                urlParam: 'manageinventoryv2'
            }

        }, {
            'title': 'Window_Title_Label_Audit_Inventory_Original',
            'dataWmsSelector': 'cyclecount_SubMenu',
            'class': '',
            'isModal': false,
            'rights': ['inventory.audit'],
            'rightsOperator': 'and',
            'clickParamObj': {
                url: '<cyclecount-holder></cyclecount-holder>',
                reactComponent: cyclecountHolder,
                singleton: true,
                title: 'Window_Title_Label_Audit_Inventory_Original',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Audit_Inventory_Original'
                }],
                icon: 'submenu-icon fa fa-handshake-o',
                urlParam: 'auditinventory-original'
            }

        }, {
            'title': 'Window_Title_Label_cyclecount',
            'dataWmsSelector': 'audit_inventory_SubMenu',
            'class': '',
            'isModal': false,
            'rights': ['inventory.audit'],
            'rightsOperator': 'and',
            'clickParamObj': {
                url: '<audit-inventory-holder></audit-inventory-holder>',
                reactComponent: AuditInventoryHolder,
                singleton: true,
                title: 'Window_Title_Label_cyclecount',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_cyclecount'
                }],
                icon: 'submenu-icon fa fa-handshake-o',
                urlParam: 'auditinventory'
            }

        }, {
            'title': 'Window_Title_Label_Adujust_Inventory',
            'class': '',
            'isModal': false,
            'rights': ['inventory.adjust'],
            'rightsOperator': 'and',
            'dataWmsSelector': 'LabelAdujustInventory_SubMenu',
            'isNewMenu': false,
            'clickParamObj': {
                url: '<report-adjust-inventory-handler></report-adjust-inventory-handler>',
                reactComponent: AdjustInventoryHolder,
                singleton: true,
                title: 'Window_Title_Label_Adujust_Inventory',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Adujust_Inventory'
                }],
                icon: 'submenu-icon fa-solid fa-box-circle-check',
                urlParam: 'adjustinventory'
            }

        }, {
            'title': 'Window_Title_Label_Move_Items',
            'class': '',
            'isModal': false,
            'rights': ['inventory.edit'],
            'dataWmsSelector': 'LabelMoveItems_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/MoveInventory.aspx',
                isIframe: true,
                title: 'Window_Title_Label_Move_Items',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Move_Items'
                }],
                icon: 'submenu-icon fa fa-arrows',
                urlParam: 'moveitems'
            }

        }, {
            'title': 'Window_Title_Label_Movable_Unit',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['inventory.edit', 'Receiving.PalletsEnabled'],
            'dataWmsSelector': 'MovableUnit_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/MoveMovableUnits.aspx',
                isIframe: true,
                title: 'Window_Title_Label_Movable_Unit',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Movable_Unit'
                }],
                icon: 'submenu-icon fa fa-puzzle-piece',
                urlParam: 'move-movable-units'
            }

        }, {
            'title': 'Window_Title_Label_Reconfigure',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['inventory.edit', 'Receiving.PalletsEnabled'],
            'dataWmsSelector': 'LabelReconfigure_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ReconfigureMovableUnits.aspx',
                isIframe: true,
                title: 'Window_Title_Label_Reconfigure',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Reconfigure'
                }],
                icon: 'submenu-icon fa fa-cog',
                urlParam: 'reconfigure-movable-unit'
            }

        }, {
            'title': 'Nav_Title_Edit_Track_Bys',
            'class': '',
            'isModal': false,
            'rights': ['inventory.edittrack'],
            'dataWmsSelector': 'EditTrackBys_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/TrackBysEdit.aspx',
                isIframe: true,
                title: 'Nav_Title_Edit_Track_Bys',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Nav_Title_Edit_Track_Bys'
                }],
                icon: 'submenu-icon fa fa-pencil-square-o',
                urlParam: 'edit-track-bys'
            }

        }]
    }, // For Return
    {
        'class': 'dropdown ',
        'id': 'wms_Return',
        'icon': 'fa fa-reply',
        'title': 'Window_Title_Label_Returns',
        'rights': ['inventory.returns'],
        'dataWmsSelector': 'Return_MainMenu',
        'directOpen': true,
        'clickParamObj': {
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            id: '/WebUI/V1/V1Link/TransReturns.aspx',
            isIframe: true,
            title: 'Window_Title_Label_Returns',
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: 'Window_Title_Label_Returns'
            }],
            icon: 'submenu-icon fa fa-reply',
            urlParam: 'returns'
        }
    }, // For Purchase Order
    {
        'class': 'dropdown ',
        'id': 'wms_Purchase_Orders',
        'icon': 'fa fa-first-order',
        'title': 'Window_Title_Label_Purchase_Orders',
        'dataWmsSelector': 'Purchase_Orders_MainMenu',
        // 'rights': 'forcehide',
        'rightsOperator': 'and',
        'rights': ['customer.purchaseorders', 'Receiving.AllowPurchaseOrders'],
        'subMenu': [{
            'title': 'Window_Title_Label_Create_Purchase_Orders',
            'class': '',
            'isModal': false,
            'rights': ['default'],
            'dataWmsSelector': 'CreatePurchaseOrders_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/CreatePurchaseOrder?value=s=n',
                isIframe: true,
                title: 'Window_Title_Label_Create_Purchase_Orders',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Create_Purchase_Orders'
                }],
                icon: 'submenu-icon fa fa-bandcamp',
                urlParam: 'create-purchase-orders'
            }
        }, {
            'title': 'Window_Title_Label_Manage_Purchase_Orders',
            'class': '',
            'isModal': false,
            'rights': ['default'],
            'dataWmsSelector': 'CreatePurchaseOrders_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/ManagePurchaseOrder?value=s=s',
                isIframe: true,
                title: 'Window_Title_Label_Manage_Purchase_Orders',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Manage_Purchase_Orders'
                }],
                icon: 'submenu-icon fa fa-diamond',
                urlParam: 'manage-purchase-orders'
            }

        }, {
            'title': 'Window_Title_Label_Import_Purchase_Orders',
            'class': '',
            'isModal': false,
            'rights': ['default'],
            'dataWmsSelector': 'LabelImportPurchaseOrders_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ImportPurchaseOrder.aspx',
                isIframe: true,
                title: 'Window_Title_Label_Import_Purchase_Orders',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Import_Purchase_Orders'
                }],
                icon: 'submenu-icon fa fa-life-ring',
                urlParam: 'import-purchase-order'
            }

        }, {
            'title': 'Window_Title_Label_Purchase_Order_Reports',
            'class': '',
            'isModal': false,
            'rights': ['default'],
            'dataWmsSelector': 'LabelPurchaseOrderReports_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/PurchaseOrderReports.aspx',
                isIframe: true,
                title: 'Window_Title_Label_Purchase_Order_Reports',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Purchase_Order_Reports'
                }],
                icon: 'submenu-icon fa fa-flask',
                urlParam: 'purchase-order-reports'
            }

        }]
    }, // For Report
    {
        'class': 'dropdown ',
        'id': 'wms_Report',
        'icon': 'fa fa-id-card',
        'rights': ['admin.reports.l1', 'admin.reports.l2', 'admin.reports.l3', 'admin.reports.l4', 'admin.reports.l5', 'admin.reports.custom', 'datainsights.interactivereporting.inventory'],
        'title': 'Window_title_Label_Report',
        'dataWmsSelector': 'Report_MainMenu',
        'subMenu': [{
            'title': 'Label_Stock_Status_Report',
            'class': '',
            'isModal': false,
            'rights': ['admin.reports.l1'],
            'dataWmsSelector': 'StockStatusReport_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/StockStatusReport.aspx',
                isIframe: true,
                title: 'Label_Stock_Status_Report',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Stock_Status_Report'
                }],
                icon: 'submenu-icon  fa fa-stop-circle',
                urlParam: 'stockstatus'
            }
        }, {
            'title': 'Label_Transactions',
            'class': '',
            'isModal': false,
            'rightsOperator': 'or',
            'rights': ['admin.reports.l1', 'admin.reports.l2', 'admin.reports.l3', 'admin.reports.l4'],
            'dataWmsSelector': 'Transactions_SubMenu',
            'clickParamObj': {
                url: '<react-transactions-iframe-list></react-transactions-iframe-list>',
                reactComponent: transactionsIframeList,
                singleton: true,
                title: 'Label_Transactions',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Transactions'
                }],
                icon: 'submenu-icon  fa fa-exchange',
                urlParam: 'transactions'
            }

        }, {
            'title': 'Label_Items',
            'class': '',
            'isModal': false,
            'rightsOperator': 'or',
            'rights': ['admin.reports.l1', 'admin.reports.l2', 'admin.reports.l4'],
            'dataWmsSelector': 'Items_SubMenu',
            'clickParamObj': {
                url: '<react-items-iframe-list></react-items-iframe-list>',
                reactComponent: itemsIframeList,
                singleton: true,
                title: 'Label_Items',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Items'
                }],
                icon: 'submenu-icon  fa fa-sitemap',
                urlParam: 'items-iframe-list'
            }

        }, {
            'title': 'Label_Locations',
            'class': '',
            'isModal': false,
            'rightsOperator': 'or',
            'rights': ['admin.reports.l1', 'admin.reports.l4'],
            'dataWmsSelector': 'LocationsIframe_SubMenu',
            'clickParamObj': {
                url: '<react-locations-iframe-list></react-locations-iframe-list>',
                reactComponent: locationsIframeList,
                singleton: true,
                title: 'Label_Locations',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Locations'
                }],
                icon: 'submenu-icon  fa  fa-compass',
                urlParam: 'locations-iframe-list'
            }

        }, {
            'title': 'Label_ManageMent',
            'class': '',
            'isModal': false,
            'rightsOperator': 'or',
            'rights': ['admin.reports.l3', 'admin.reports.l5'],
            'dataWmsSelector': 'ManageMent_SubMenu',
            'clickParamObj': {
                url: '<react-management-iframe-list></react-management-iframe-list>',
                reactComponent: managementIframeList,
                singleton: true,
                title: 'Label_ManageMent',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_ManageMent'
                }],
                icon: 'submenu-icon  fa fa-area-chart',
                urlParam: 'management-iframe-list'
            }

        }, {
            'title': 'Label_Customize',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['admin.reports.custom'],
            'dataWmsSelector': 'Customize_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ManageReports.aspx',
                isIframe: true,
                title: 'Label_Customize',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Customize'
                }],
                icon: 'submenu-icon fa fa-bullseye',
                urlParam: 'customize-iframe-list'
            }
        }, {
            'title': 'Label_Interactive_Reporting',
            'class': "menu-seperator",
            'rightsOperator': 'and',
            'rights': [globalsMsg.stock_status_url ? 'default' : 'hide', 'UxPrototypingDirectivesStockStatus', 'datainsights.interactivereporting.inventory'],
            'clickParamObj': []
        }, {
            'title': 'Window_Title_Label_Inventory_Reporting',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': [globalsMsg.stock_status_url ? 'default' : 'hide', 'UxPrototypingDirectivesStockStatus', 'datainsights.interactivereporting.inventory'],
            'dataWmsSelector': 'Stock_Status_Prototype',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: globalsMsg.stock_status_url + '?type=stock-status-report&' + GlobalService.userKeysUrlStringForDashboard,
                isIframe: true,
                title: 'Window_Title_Label_Inventory_Reporting',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Find_Orders_Feedback'
                }],
                icon: 'submenu-icon fa fa-stop-circle',
                urlParam: 'inventory-reporting'
            }
        }
        ]
    }, // For users
    {
        'class': 'dropdown ',
        'id': 'wms_Users',
        'icon': 'fa fa-users',
        'title': 'Label_Users',
        'dataWmsSelector': 'user_MainMenu',
        'rights': ['user.manage.view', 'user.edit.view', 'role.manage'],
        'subMenu': [{
            'title': 'Label_Manage_Users',
            'class': '',
            'isModal': false,
            'rights': ['user.manage.view'],
            'dataWmsSelector': 'manageUserBeta_SubMenu',
            'clickParamObj': {
                url: '<react-user-beta-holder></react-user-beta-holder>',
                reactComponent: userBetaHolder,
                singleton: true,
                title: 'Label_Manage_Users',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Manage_Users'
                }],
                icon: 'submenu-icon fa fa-users',
                urlParam: 'manageusersbeta'
            }

        }, {
            'title': 'Label_Manage_Roles',
            'class': '',
            'isModal': false,
            'rights': ['role.manage'],
            'dataWmsSelector': 'manageRole_SubMenu',
            'clickParamObj': {
                url: '<react-role-holder></react-role-holder>',
                reactComponent: rolesHolder,
                singleton: true,
                title: 'Label_Manage_Roles',
                active: true,
                useLineItem: true,
                lineItem: [{
                    name: '',
                    value: 'Label_Manage_Roles'
                }],
                icon: 'submenu-icon fa fa-wrench',
                urlParam: 'manageroles'
            }

        }]
    }, // For Customers
    {
        'class': 'dropdown ',
        'id': 'wms_Customers',
        'icon': 'fa fa-handshake-o',
        'title': customerLabel.Label_Customer + 's',
        'dataWmsSelector': 'customer_MainMenu',
        'rights': ['customer.items.manage', 'customer.barcodedefs', 'customer.manage.edit', 'customer.billing', 'customer.notifications', 'customer.customfields', 'customer.items.import'],
        'subMenu': [{
            'title': 'Manage ' + customerLabel.Label_Customer + 's',
            'class': '',
            'isModal': false,
            'rights': ['customer.manage.edit'],
            'dataWmsSelector': 'manageCustomer_SubMenu',
            'clickParamObj': {
                url: "<react-customers-holder></react-customers-holder>",
                reactComponent: customersHolder,
                singleton: true,
                title: customerLabel.Label_Customer,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_ManageCustomers'
                }],
                icon: 'submenu-icon fa fa-users',
                urlParam: 'managecustomers'
            }

        }, {
            'title': 'Label_Configure_Packing_Slips',
            'class': '',
            'isModal': false,
            'rights': ['customer.packingslip'],
            'dataWmsSelector': 'packingSLips_SubMenu',
            'clickParamObj': {
                url: "<react-customers-configure-packing-slips-holder></react-customers-configure-packing-slips-holder>",
                reactComponent: ConfigurePackingSlipsHolder,
                singleton: true,
                title: 'Label_Configure_Packing_Slips',
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Configure_Packing_Slips'
                }],
                icon: 'submenu-icon fa fa-bars',
                urlParam: 'packingslip'
            }

        }, {
            'title': 'Label_Event_Notifications',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.connections', 'Integrations.WebhooksEnabled'],
            'isNewMenu': false,
            'dataWmsSelector': 'manageCustomer_EventNotificataion',
            'clickParamObj': {
                url: "<react-customers-event-notification-handler></react-customers-event-notification-handler>",
                reactComponent: EventNotificationHolderComponent,
                singleton: true,
                title: 'Label_Event_Notifications',
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Event_Notifications'
                }],
                icon: 'submenu-icon fa fa-exclamation-triangle',
                urlParam: 'eventnotifications'
            }

        }, {
            'title': 'Window_Title_Label_ManageBarcodes',
            'class': '',
            'isModal': false,
            'dataWmsSelector': 'manageBarcode_SubMenu',
            'rights': ['customer.barcodedefs'],
            'clickParamObj': {
                url: '<react-barcode-holder></react-barcode-holder>',
                reactComponent: BarcodeHolder,
                singleton: true,
                title: 'Window_Title_Label_Barcode_Definitions',
                active: true,
                useLineItem: true,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Barcode_Definitions'
                }],
                icon: 'submenu-icon fa fa-barcode',
                flow: 'create',
                urlParam: 'managebarcodes'
            }

        }, {
            'title': 'Label_Customer_Notifies',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.notifications', 'isWarehouseUser'],
            'dataWmsSelector': 'CustomerNotifies_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/EditNotify.aspx',
                isIframe: true,
                title: 'Label_Customer_Notifies',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Customer_Notifies'
                }],
                icon: 'submenu-icon fa fa-sticky-note-o',
                urlParam: 'customernotifies'
            }

        }, {
            'title': 'Label_Billing_Wizard',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.billing', 'isWarehouseUser'],
            'dataWmsSelector': 'BillingWizard_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/BillingWizardMenu.aspx',
                isIframe: true,
                title: 'Label_Billing_Wizard',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Billing_Wizard'
                }],
                icon: 'submenu-icon fa fa-dashcube',
                urlParam: 'billingwizard'
            }

        }, {
            'title': 'Label_Manage_Suppliers',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.items.manage', 'isWarehouseUser'],
            'dataWmsSelector': 'ManageSuppliers_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ManageSupplier.aspx',
                isIframe: true,
                title: 'Label_Manage_Suppliers',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Manage_Suppliers'
                }],
                icon: 'submenu-icon fa fa-cogs',
                urlParam: 'managesuppliers'
            }

        }, {
            'title': 'Label_Ignore_Skus',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.connections', 'isWarehouseUser'],
            'dataWmsSelector': 'IgnoreSkus_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/IgnoreSkuList.aspx',
                isIframe: true,
                title: 'Label_Ignore_Skus',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Ignore_Skus'
                }],
                icon: 'submenu-icon fa fa-sitemap',
                urlParam: 'ignoreskus'
            }

        }, {
            'title': 'Label_custom_fields',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.items.manage', 'OtherUserFeatures.SavedElementsEnabled', 'isWarehouseUser'],
            'dataWmsSelector': 'customfields_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/SavedElementConfig.aspx',
                isIframe: true,
                title: 'Label_custom_fields',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_custom_fields'
                }],
                icon: 'submenu-icon fa fa-columns',
                urlParam: 'customfields'
            }

        }, {
            'title': 'Label_Manage_Connections',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.connections', 'UserInterface.ConnectionsEnabled', 'isWarehouseUser'],
            'dataWmsSelector': 'ManageConnections_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/OrderTransportConfig.aspx',
                isIframe: true,
                title: 'Label_Manage_Connections',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Manage_Connections'
                }],
                icon: 'submenu-icon fa fa-cogs',
                urlParam: 'manageconnections'
            }
        }, {
            'title': 'Label_FTP_Sites',
            'class': '',
            'isModal': false,
            'rights': ['hide'],
            'dataWmsSelector': 'FTPSites_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/EditFtp.aspx',
                isIframe: true,
                title: 'Label_FTP_Sites',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_FTP_Sites'
                }],
                icon: 'submenu-icon fa fa-edge',
                urlParam: 'ftpsites'
            }

        }, {
            'title': 'Label_Classic_BOL_Messages',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.manage.edit', 'isWarehouseUser'],
            'dataWmsSelector': 'ClassicBOLMessages_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/CustomerBOLMessages.aspx',
                isIframe: true,
                title: 'Label_Classic_BOL_Messages',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Classic_BOL_Messages'
                }],
                icon: 'submenu-icon fa fa-commenting-o',
                urlParam: 'BOLMessages'
            }
        }, {
            'title': 'Label_Classic_BOL_Templates',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.manage.edit', 'isWarehouseUser'],
            'dataWmsSelector': 'ClassicBOLTemplates_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/CustomerBOLTemplates.aspx',
                isIframe: true,
                title: 'Label_Classic_BOL_Templates',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Classic_BOL_Templates'
                }],
                icon: 'submenu-icon fa fa-file-text-o',
                urlParam: 'BOLTemplates'
            }
        }, {
            'title': 'Label_Web_Services',
            'class': '',
            'isModal': false,
            'rights': ['hide'],
            'dataWmsSelector': 'WebServices_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/EditWebService.aspx',
                isIframe: true,
                title: 'Label_Web_Services',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Web_Services'
                }],
                icon: 'submenu-icon fa fa-cogs',
                urlParam: 'webservices'
            }
        }, {
            'title': 'Label_Import_Contacts',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.manage.edit', 'isWarehouseUser'],
            'dataWmsSelector': 'ImportContacts_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ImportContact.aspx',
                isIframe: true,
                title: 'Label_Import_Contacts',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Import_Contacts'
                }],
                icon: 'submenu-icon fa-solid fa-compress',
                urlParam: 'importcontacts'
            }
        }, {
            'title': 'Label_Error_Log',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.manage.edit', 'isWarehouseUser'],
            'dataWmsSelector': 'ErrorLog_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ErrorLog.aspx',
                isIframe: true,
                title: 'Label_Error_Log',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Error_Log'
                }],
                icon: 'submenu-icon fa fa-exclamation-circle',
                urlParam: 'errorlog'
            }
        }, {
            'title': 'Charge_Adjustments',
            'class': 'blue',
            'isModal': false,
            'rights': ['admin.adjustcharges', 'Integrations.isNotActiveBillingManagerApp'],
            'rightsOperator': 'and',
            'rightsOperator': 'and',
            'dataWmsSelector': 'Charge_Adjustments',
            'isNewMenu': false,
            'clickParamObj': {
                url: '<charges-adjustments></charges-adjustments>',
                reactComponent: AdjustCustomerChargesHolder,
                singleton: false,
                title: 'Charge_Adjustments',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Charge_Adjustments'
                }],
                icon: 'submenu-icon fa-solid fa-money-check-pen',
                urlParam: 'adjustcharges'
            }
        },  {
            'title': 'Window_Title_Label_SmartScan_Label_Templates',
            'class': 'blue',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['OtherUserFeatures.MobileEnabled', 'isWarehouseUser'],
            'dataWmsSelector': 'MobileLabelTemplates_SubMenu',
            'clickParamObj': {
                url: '<mobile-label-template></mobile-label-template>',
                reactComponent: MobileLabelTemplatesHolder,
                singleton: true,
                title: 'Window_Title_Label_SmartScan_Label_Templates',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_SmartScan_Label_Templates'
                }],
                icon: 'submenu-icon fa fa-mobile',
                urlParam: 'smartscanlabel'
            }

        }]
    }, //For items
    {
        'class': 'dropdown ',
        'id': 'wms_Items',
        'icon': 'fa-solid fa-grid',
        'title': customerLabel.Label_Item + 's',
        'dataWmsSelector': 'item_MainMenu',
        'rights': ['customer.items'],
        'subMenu': [{
            'title': 'Manage ' + customerLabel.Label_Item + 's',
            'class': '',
            'isModal': false,
            'rights': ['customer.items.manage'],
            'dataWmsSelector': 'manageItem_SubMenu',
            'clickParamObj': {
                url: "<react-items-holder></react-items-holder>",
                reactComponent: ItemsHolder,
                singleton: true,
                title: 'Manage ' + customerLabel.Label_Item + 's',
                active: true,
                useLineItem: true,
                lineItem: [{
                    name: '',
                    value: 'Manage ' + customerLabel.Label_Item + 's'
                }],
                icon: 'submenu-icon fa fa-puzzle-piece',
                urlParam: 'manageitems'
                // callerID:'navController'
            }

        }, {
            'title': 'Import ' + customerLabel.Label_Item + 's',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.items.import', 'isWarehouseUser'],
            'dataWmsSelector': 'ImportItems_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ImportItem.aspx',
                isIframe: true,
                title: 'Import ' + customerLabel.Label_Item + 's',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Import ' + customerLabel.Label_Item + 's'
                }],
                icon: 'submenu-icon fa fa-cogs',
                urlParam: 'importitems'
            }

        }, {
            'title': 'Label_Item_Alias',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.items.alias', 'Items.SkuAliasEnabled'],
            'dataWmsSelector': 'ItemAlias_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ItemAlias.aspx',
                isIframe: true,
                title: 'Label_Item_Alias',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Item_Alias'
                }],
                icon: 'submenu-icon fa fa-cog',
                urlParam: 'itemalias'
            }

        }, {
            'title': 'Label_Manage_Commodities',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.items.classify', 'isWarehouseUser'],
            'dataWmsSelector': 'ManageCommodities_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/NMFCList.aspx',
                isIframe: true,
                title: 'Label_Manage_Commodities',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Manage_Commodities'
                }],
                icon: 'submenu-icon fa-solid fa-list-check',
                urlParam: 'manage-commodities'
            }

        }, {
            'title': 'Label_Manage_Classification',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.items.classify', 'Items.ItemClassificationEnabled'],
            'dataWmsSelector': 'ManageClassification_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ItemClassificationCRUD.aspx',
                isIframe: true,
                title: 'Label_Manage_Classification',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Manage_Classification'
                }],
                icon: 'submenu-icon fa fa-deviantart',
                urlParam: 'manage-classifications'
            }

        }, {
            'title': 'Label_Manage_Pricing',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['customer.items.pricing', 'Items.PricingEnabled'],
            'dataWmsSelector': 'ManagePricing_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ManagePricing.aspx',
                isIframe: true,
                title: 'Label_Manage_Pricing',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Manage_Pricing'
                }],
                icon: 'submenu-icon fa-regular fa-money-bill',
                urlParam: 'managepricing'
            }

        }, {
            'title': 'Label_Manage_Item_Images',
            'class': '',
            'isModal': false,
            'rights': ['Items.ItemImagesEnabled', 'isWarehouseUser'],
            'rightsOperator': 'and',
            'dataWmsSelector': 'ManageItemImages_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ItemImages.aspx',
                isIframe: true,
                title: 'Label_Manage_Item_Images',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Manage_Item_Images'
                }],
                icon: 'submenu-icon fa  fa-file-image-o',
                urlParam: 'manage-item-images'
            }

        }, {
             'title': 'Label_Manage_Storage_Rates',
        'class': '',
        'isModal': false,
        'rightsOperator': 'and',
        'rights': ['customer.items.rates', 'isWarehouseUser'],
        'dataWmsSelector': 'ManageStorageRates_SubMenu',
        'clickParamObj': {
            url: '<report-iframe-component></report-iframe-component>',
            reactComponent: reportIframe,
            singleton: false,
            id: '/WebUI/V1/V1Link/UpdateStorageRates.aspx',
            isIframe: true,
            title: 'Label_Manage_Storage_Rates',
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: 'Label_Manage_Storage_Rates'
            }],
            icon: 'submenu-icon fa  fa-line-chart',
            urlParam: 'manage-storage-rates'
        }
            },{
            'title': 'Window_Title_Label_Manage_Assembly',
            'class': 'blue',
            'isModal': false,
            'isNewMenu': false,
            'rightsOperator': 'and',
            'rights': ['customer.items.assemblies', 'isWarehouseUser'],
            'dataWmsSelector': 'manageAssembly_SubMenu',
            'clickParamObj': {
                url: "<react-manage-assembly-holder></react-manage-assembly-holder>",
                reactComponent: ManageAssembliyHolder,
                singleton: true,
                title: 'Window_Title_Label_Manage_Assembly',
                active: true,
                useLineItem: true,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Manage_Assembly'
                }],
                icon: 'submenu-icon fa-solid fa-puzzle',
                urlParam: 'manageAssembly'
                // callerID:'navController'
            }

        }]
    }, // For Warehouse
    {
        'class': 'dropdown ',
        'id': 'wms_Warehouses',
        'icon': 'fa fa-home',
        'title': customerLabel.Label_Warehouse,
        'dataWmsSelector': 'Warehouse_MainMenu',
        'rights': ['warehouse.manage', 'warehouse.locations'],
        'subMenu': [{
            'title': 'Manage ' + customerLabel.Label_Warehouse,
            'class': '',
            'isModal': false,
            'rights': ['warehouse.manage'],
            'dataWmsSelector': 'Warehouse_SubMenu_ManageWarehouse',
            'clickParamObj': {
                url: "<react-warehouse-holder></react-warehouse-holder>",
                reactComponent: warehouseHolder,
                singleton: true,
                title: 'Manage ' + customerLabel.Label_Warehouse,
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_ManageWarehouses'
                }],
                icon: 'submenu-icon fa fa-university',
                urlParam: 'managewarehouse'
            }

        },
        {
            'title': 'Window_Title_Label_ManageLocations',
            'class': '',
            'isModal': false,
            'dataWmsSelector': 'Warehouse_SubMenu_ManageLocation',
            'rights': ['warehouse.locations'],
            'clickParamObj': {
                url: "<react-locations-holder></react-locations-holder>",
                reactComponent: locationsHolder,
                singleton: true,
                title: 'Window_Title_Label_ManageLocations',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_ManageLocations'
                }],
                icon: 'submenu-icon fa fa-map-marker',
                urlParam: 'managelocations'
            }
        },
        {
            'title': 'Nav_Label_Import_Locations',
            'class': '',
            'isModal': false,
            'dataWmsSelector': 'Warehouse_SubMenu_ImportLocation',
            'rights': ['warehouse.locations'],
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ImportLocation.aspx',
                isIframe: true,
                title: 'Nav_Label_Import_Locations',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Nav_Label_Import_Locations'
                }],
                icon: 'submenu-icon fa fa-cogs',
                urlParam: 'importlocations'
            }
        }, {
            'title': 'Nav_Label_Mass_Update_Locations',
            'class': '',
            'isModal': false,
            'rights': ['warehouse.locations'],
            'dataWmsSelector': 'MassUpdateLocations_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/LocationBatch.aspx',
                isIframe: true,
                title: 'Nav_Label_Mass_Update_Locations',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Nav_Label_Mass_Update_Locations'
                }],
                icon: 'submenu-icon fas fa-map-marker-alt',
                urlParam: 'mass-update-locations'
            }

        }]
    }, // For Admin

    {
        'class': 'dropdown ',
        'id': 'wms_Admin',
        'icon': 'fa fa-user',
        'title': 'Window_Title_Label_Administration',
        'dataWmsSelector': 'Admin_MainMenu',
        'rights': [
            'warehouse.locations',
            'admin.storagecharges',
            'admin.managemu',
            'admin.invoices',
            'admin.custlabels',
            'admin.3plinfo',
            'admin.carriers',
            'customer.packingslip',
            'mobile.classic',
            'admin.attachfiles',
            'orders.manage.smartparcel'
        ],
        'subMenu': [{
            'title': 'Label_Settings',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['admin.3plinfo', 'isWarehouseUser'],
            'dataWmsSelector': 'ThreeplSettings_SubMenu',
            'clickParamObj': {
                url: '<threepl-settings-holder></threepl-settings-holder>',
                reactComponent: ThreeplSettingsHolder,
                singleton: false,
                id: '/WebUI/V1/V1Link/GenRecurringStorage.aspx',
                isIframe: true,
                title: 'Label_Settings',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Settings'
                }],
                icon: 'submenu-icon fa fa-hourglass-start',
                urlParam: 'threepl-settings'
            }

        }, {
            'title': 'Label_Location_Billing_Type',
            'class': '',
            'isModal': false,
            'rights': ['warehouse.locations'],
            'dataWmsSelector': 'LocationBillingType_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/LocationBillingTypeList.aspx',
                isIframe: true,
                title: 'Label_Location_Billing_Type',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Location_Billing_Type'
                }],
                icon: 'submenu-icon fa fa-dashcube',
                urlParam: 'location-billing-type'
            }

        }, {
            'title': 'Label_Generate_Recurring_Storage',
            'class': '',
            'isModal': false,
            'rights': ['admin.storagecharges'],
            'dataWmsSelector': 'GenerateRecurringStorage_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/GenRecurringStorage.aspx',
                isIframe: true,
                title: 'Label_Generate_Recurring_Storage',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Generate_Recurring_Storage'
                }],
                icon: 'submenu-icon fa fa-server',
                urlParam: 'recurring-storage-charges'
            }

        }, {
            'title': 'Label_Movable_Units',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['admin.managemu', 'Receiving.PalletsEnabled'],
            'dataWmsSelector': 'MovableUnits_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/MovableUnitTypeList.aspx',
                isIframe: true,
                title: 'Label_Movable_Units',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Movable_Units'
                }],
                icon: 'submenu-icon fa fa-university',
                urlParam: 'movableunits'
            }

        }, {
            'title': 'Label_Manage_Invoices',
            'class': '',
            'isModal': false,
            'rights': ['admin.invoices', 'Integrations.isNotActiveBillingManagerApp'],
            'rightsOperator': 'and',
            'dataWmsSelector': 'ManageInvoices_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ManageInvoices.aspx',
                isIframe: true,
                title: 'Label_Manage_Invoices',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Manage_Invoices'
                }],
                icon: 'submenu-icon fa fa-square-o',
                urlParam: 'manageinvoices'
            }

        }, {
            'title': 'Window_Title_Label_Settings',
            'class': '',
            'isModal': false,
            'rights': ['admin.custlabels'],
            'dataWmsSelector': 'LabelSettings_SubMenu',
            'clickParamObj': {
                url: '<settings-holder></settings-holder>',
                reactComponent: settingsHolder,
                singleton: true,
                title: 'Window_Title_Label_Settings',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Settings'
                }],
                icon: 'submenu-icon fa fa-wrench',
                urlParam: 'customlabels'
            }

        }, {
            'title': 'Window_Title_Label_Company_Info',
            'class': '',
            'isModal': false,
            'rights': ['admin.3plinfo'],
            'dataWmsSelector': 'LabelCompanyInfo_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/UpdateThreePL.aspx',
                isIframe: true,
                title: 'Window_Title_Label_Company_Info',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Company_Info'
                }],
                icon: 'submenu-icon fa fa-building',
                urlParam: 'manage-company-info'
            }

        }, {
            'title': 'Window_Title_Label_Manage_Carriers',
            'class': '',
            'isModal': false,
            'rights': ['admin.carriers'],
            'dataWmsSelector': 'LabelManageCarriers_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/CarrierList.aspx',
                isIframe: true,
                title: 'Window_Title_Label_Manage_Carriers',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Manage_Carriers'
                }],
                icon: 'submenu-icon fa fa-area-chart',
                urlParam: 'managecarriers'
            }

        }, {
            'title': 'Window_Title_Label_Freeze_Period_Transaction',
            'class': '',
            'isModal': false,
            'rights': ['admin.invoices'],
            'dataWmsSelector': 'FreezePeriodTransaction_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/FreezePeriod.aspx',
                isIframe: true,
                title: 'Window_Title_Label_Freeze_Period_Transaction',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Freeze_Period_Transaction'
                }],
                icon: 'submenu-icon fa fa-exchange',
                urlParam: 'freeze-transactions'
            }

        },
        {
            'title': 'Window_Title_Label_Manage_Tpl',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['admin.3plinfo', 'Shipping.SmartPackScaleEnabled'],
            'dataWmsSelector': 'LabelManageTpl_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/TplScale.aspx',
                isIframe: true,
                title: 'Window_Title_Label_Manage_Tpl',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Manage_Tpl'
                }],
                icon: 'submenu-icon fa fa-balance-scale',
                urlParam: 'manage-tpl-scales'
            }

        }, {
            'title': 'Window_Title_Label_Manage_Manage_Zone_Billing',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['admin.3plinfo', 'Shipping.ZoneBillingEnabled'],
            'dataWmsSelector': 'ManageManageZoneBilling_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/ZoneBilling.aspx',
                isIframe: true,
                title: 'Window_Title_Label_Manage_Manage_Zone_Billing',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Manage_Manage_Zone_Billing'
                }],
                icon: 'submenu-icon fa fa-file',
                urlParam: 'manage-zone-billing'
            }

        }, {
            'title': 'Nav_Label_Invoice_Setup',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['admin.invoices', 'Integrations.QbEnabled'],
            'dataWmsSelector': 'InvoiceSetup_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/InvoiceGenerationSetup.aspx',
                isIframe: true,
                title: 'Nav_Label_Invoice_Setup',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Nav_Label_Invoice_Setup'
                }],
                icon: 'submenu-icon fa fa-cog',
                urlParam: 'invoicesetup'
            }

        },  {
            'title': 'Window_Title_Label_Manage_Smart_Parcel_Carriers',
            'class': '',
            'isModal': false,
            'rightsOperator': 'and',
            'rights': ['admin.3plinfo', 'Shipping.SmallParcelEnabled', 'isSysAdminUser'],
            'dataWmsSelector': 'ManageSmartParcelCarriers_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: '/WebUI/V1/V1Link/SmallParcelSetup.aspx',
                isIframe: true,
                title: 'Window_Title_Label_Manage_Smart_Parcel_Carriers',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Title_Label_Manage_Smart_Parcel_Carriers'
                }],
                icon: 'submenu-icon fa fa-bar-chart',
                urlParam: 'manage-smartparcel-carriers'
            }

        }]
    }, // For Misc OR Prior Version
    {
        'class': 'dropdown',
        'id': 'wms_Misc',
        'icon': 'fas fa-trash-restore-alt',
        'title': 'Label_Misc',
        'rights': ['hide'], //'hide', 'isWarehouseUser'
        'rightsOperator': 'and',
        'dataWmsSelector': 'Misc_MainMenu',
        'subMenu': [

        ]
    },// For All
    {
        'class': 'dropdown',
        'id': 'wms_All',
        'icon': 'fa fa-globe',
        'title': 'Label_All',
        'rights': ['hide'],
        'dataWmsSelector': 'All_MainMenu',
        'subMenu': [
            {
                'title': 'Window_Title_Label_Apisimulator',
                'class': 'orange',
                'isModal': false,
                'rights': ['default'],
                'dataWmsSelector': 'api_simulator',
                'isNewMenu': true,
                'clickParamObj': {
                    url: '<style-guide-component></style-guide-component>',
                    reactComponent: ApiSimulator,
                    singleton: true,
                    title: 'Window_Title_Label_Apisimulator',
                    active: true,
                    useLineItem: true,
                    lineItem: [{
                        name: '',
                        value: 'Window_Title_Label_Apisimulator'
                    }],
                    icon: 'submenu-icon fa fa-info'
                }

            }
        ]
    }, // For Classic view
    {
        'class': 'dropdown',
        'id': 'wms_ClassicView',
        'icon': 'fa fa-eye',
        'rights': ['admin.classic', 'UserInterface.AllowClassicAccess', 'hide'],
        'rightsOperator': 'and',
        'title': 'Window_Title_Label_ClassicView',
        'dataWmsSelector': 'wms_ClassicView',
        'isSubLink': true,
        'subMenu': [{
            'title': 'NavBarV1_Btn_Shipping',
            'class': 'orange',
            'icon': 'submenu-icon fa fa-truck',
            'redirectUrl': '/WebUI/V1/Shipping',
            'rights': ['default'],
            'dataWmsSelector': 'BtnShipping_SubMenu',
            'url': true
        }, {
            'title': 'NavBarV1_Btn_Receiving',
            'class': 'orange',
            'icon': 'submenu-icon fa fa-exchange',
            'redirectUrl': '/WebUI/V1/Receiving',
            'rights': ['default'],
            'dataWmsSelector': 'BtnReceiving_SubMenu',
            'url': true
        }, {
            'title': 'NavBarV1_Btn_POs',
            'class': 'orange',
            'icon': 'submenu-icon fa fa-credit-card-alt',
            'redirectUrl': '/WebUI/V1/POs',
            'dataWmsSelector': 'BtnPOs_SubMenu',
            'rights': ['default'],
            'url': true
        }, {
            'title': 'NavBarV1_Btn_Returns',
            'class': 'orange',
            'icon': 'submenu-icon fa fa-exchange',
            'redirectUrl': '/WebUI/V1/Returns',
            'dataWmsSelector': 'BtnReturns_SubMenu',
            'rights': ['default'],
            'url': true
        }, {
            'title': 'NavBarV1_Btn_Assemblies',
            'class': 'orange',
            'icon': 'submenu-icon fa fa-building',
            'redirectUrl': '/WebUI/V1/Assemblies',
            'rights': ['default'],
            'dataWmsSelector': 'BtnAssemblies_SubMenu',
            'url': true
        }, {
            'title': 'NavBarV1_Btn_Adjustments',
            'class': 'orange',
            'icon': 'submenu-icon fa fa-adjust',
            'redirectUrl': '/WebUI/V1/Adjustments',
            'dataWmsSelector': 'BtnAdjustments_SubMenu',
            'rights': ['default'],
            'url': true
        }, {
            'title': 'NavBarV1_Btn_Reports',
            'class': 'orange',
            'icon': 'submenu-icon fa fa-bar-chart',
            'redirectUrl': '/WebUI/V1/Reports',
            'dataWmsSelector': 'BtnReports_SubMenu',
            'rights': ['default'],
            'url': true
        }, {
            'title': 'NavBarV1_Btn_Documents',
            'class': 'orange',
            'icon': 'submenu-icon fa fa-file',
            'redirectUrl': '/WebUI/V1/Documents',
            'dataWmsSelector': 'BtnDocuments_SubMenu',
            'rights': ['default'],
            'url': true
        }, {
            'title': 'Slideout_QuickLinks_Items',
            'class': 'orange',
            'icon': 'submenu-icon fa fa-puzzle-piece',
            'redirectUrl': '/WebUI/V1/Items',
            'dataWmsSelector': 'SlideoutQuickLinksItems_SubMenu',
            'rights': ['default'],
            'url': true
        }, {
            // 'title': 'Window_Title_Label_Customers',
            'title': customerLabel.Label_Customer,
            'class': 'orange',
            'icon': 'submenu-icon fa fa-users',
            'redirectUrl': '/WebUI/V1/Customer',
            'dataWmsSelector': 'ClassicCustomer_SubMenu',
            'rights': ['default'],
            'url': true
        }, {
            'title': 'NavBarV1_Btn_Admin',
            'class': 'orange',
            'icon': 'submenu-icon fa fa-user',
            'redirectUrl': '/WebUI/V1/Admin',
            'dataWmsSelector': 'BtnAdmin_SubMenu',
            'rights': ['default'],
            'url': true
        }]
    },
    {
        'class': 'orange',
        'id': 'wms_UpdateConfig',
        'icon': 'fa fa-refresh',
        'rights': ['hide'],
        'title': 'Window_Title_Label_UpdateConfig',
        'isMethodFor': true,
        'methodFor': 'sync',
        'dataWmsSelector': 'wms_UpdateConfig',
        'toolTip': 'Tooltip_Message_UpdateConfig'
    }, {
        'class': 'dropdown support-link',
        'icon': 'fa fa-sitemap',
        'id': 'wms_Prototype',
        'title': 'Window_Title_Label_Prototype',
        'dataWmsSelector': 'protoType_MainMenu',
        // 'rightsOperator': 'and',
        // 'rights': ['UxPrototypingDirectivesOn'],
        // 'rights': ['isWarehouseUser'],
        'rights': ['hide'],
        'subMenu': [{
            'title': 'Label_Branding_Text',
            'class': '',
            'isModal': false,
            'rights': ['hide'],
            'dataWmsSelector': 'Settings_SubMenu_Branding',
            'clickParamObj': {
                url: "<react-themes-component></react-themes-component>",
                reactComponent: themesComponent,
                singleton: true,
                title: 'Label_Branding_Text',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Branding_Text'
                }],
                icon: 'submenu-icon fab fa-delicious',
                urlParam: 'branding'
            }
        },
        {
            'title': 'Window_Find_Orders_Feedback',
            'class': '',
            'isModal': false,
            'rights': ['hide'],
            'dataWmsSelector': 'FindOrdersFeedback_SubMenu',
            'clickParamObj': {
                url: '<report-iframe-component></report-iframe-component>',
                reactComponent: reportIframe,
                singleton: false,
                id: 'https://www.surveygizmo.com/s3/4875243/4Mar2019-Prototype-survey',
                isIframe: true,
                title: 'Window_Find_Orders_Feedback',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Window_Find_Orders_Feedback'
                }],
                icon: 'submenu-icon fa fa-comments-o',
                urlParam: 'branding-feedback'
            }
        }]
    }, {
        'class': 'dropdown support-link',
        'icon': 'fa fa-tachometer',
        'id': 'wms_dashboard',
        'title': 'Label_Dashboards',
        'dataWmsSelector': 'SupportLink_MainMenu',
        'rights': ['UxPrototypingDirectivesOutBound', 'UxPrototypingDirectivesLabor'],
        'rightsOperator': 'or',
        'subMenu': [{
            'title': 'Label_Dashboard_Order_Productivity',
            'class': 'green',
            'rights': ['UxPrototypingDirectivesOutBound'],
            'dataWmsSelector': 'SupportLink_OrderProductivity_SubMenu',
            'isNewMenu': false,
            'icon': 'submenu-icon fa fa-chart-line',
            'isModal': false,
            'clickParamObj': {
                url: "<react-outbound-productivity></react-outbound-productivity>",
                reactComponent: OutboundProductivity,
                singleton: true,
                title: 'Label_Dashboard_Order_Productivity',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Dashboard_Order_Productivity'
                }],
                icon: 'submenu-icon fa fa-chart-line',
                urlParam: 'warehouseproductivity'
            }
        },
        {
            'title': 'Label_Labor_Analytics_Dashboard',
            'class': 'green',
            'rights': ['UxPrototypingDirectivesLabor', 'isWarehouseUser'],
            'rightsOperator': 'and',
            'dataWmsSelector': 'SupportLink_LaborAnalytics_SubMenu',
            'isNewMenu': false,
            'isFreeTrial': false,
            'icon': 'submenu-icon fa fa-user-helmet-safety',
            'isModal': false,
            'clickParamObj': {
                url: "<react-labor-analytics-dashboard></react-labor-analytics-dashboard>",
                reactComponent: LaborAnalyticsDashboard,
                singleton: true,
                title: 'Label_Labor_Analytics_Dashboard',
                active: true,
                useLineItem: false,
                lineItem: [{
                    name: '',
                    value: 'Label_Labor_Analytics_Dashboard'
                }],
                icon: 'submenu-icon fa-solid fa-chart-mixed',
                urlParam: 'LaborAnalytics'
            }
        }]
    }]
    )
}

export const getMenuListForSmallParcel = (customerLabel) => {
    return ([
        {
            'class': 'dropdown ',
            'id': 'SmallParcel_Parcel_Ship',
            'icon': 'fas fa-box-open',
            'title': 'Window_Title_Label_Small_Parcel',
            'rights': ['smallParcelMenuVisible'],
            'dataWmsSelector': 'Parcel_Ship_MainMenu',
            'directOpen': true,
            'clickParamObj': {
                url: '<SmallParcelHolder></SmallParcelHolder>',
                reactComponent: SmallParcelHolder,
                singleton: true,
                title: 'Window_Title_Label_Small_Parcel',
                active: true,
                useLineItem: false,
                icon: 'fas fa-box-open',
                urlParam: 'small-parcel'
            }
        },
        {
            'class': 'dropdown ',
            'title': 'Label_Small_Parcel_HVP', // Label_Small_Parcel_Settings
            'id': 'wms_Small_Parcel_HVP',
            'icon': 'fa fa-truck',
            'rights': ['default'],
            'dataWmsSelector': 'smallParcelHVP_SubMenu',
            'directOpen': true,
            'clickParamObj': {
                url: "<react-small-parcel-hvp-holder></react-small-parcel-hvp-holder>", // <react-small-parcel-settings-holder>
                reactComponent: SmallParcelHVPHolder,
                singleton: true,
                title: 'Label_Small_Parcel_HVP',
                active: true,
                icon: 'fa fa-truck',
                urlParam: 'small-parcel-hvp'
            }
        },
        {
            'class': 'dropdown ',
            'title': 'Label_Small_Parcel_Settings',
            'id': 'wms_Small_Parcel_Settings',
            'icon': 'fa fa-sliders',
            'rights': ['default'],
            'dataWmsSelector': 'smallParcelSettings_SubMenu',
            'directOpen': true,
            'clickParamObj': {
                url: "<react-small-parcel-settings-holder></react-small-parcel-settings-holder>",
                reactComponent: SmallParcelSettingsHolder,
                singleton: true,
                title: 'Label_Small_Parcel_Settings',
                active: true,
                icon: 'fa fa-sliders',
                urlParam: 'small-parcel-settings'
            }
        }
    ])
}
