import React from 'react';
import * as GlobalService from './../../../../global_services';

export const getGridDemoColumns = (ManageInventory_GridHeaders,selectedFacilityObj) => {
    
    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    return ({

        columns: [{
            field: "PrimaryUnitsToAdd",
            title: "Primary Qty to Add",
            attributes: {
                "class": "editable-cell"
            },
            width: 140,
            wmsid: 49,
            locked: true,
            lockable: false,
            resizable:false,
            sortable: false,
            reorderable: false,
            filterable: false,
            headerAttributes: { style: "text-align:center;" }
        }, {
            field: "Available",
            lockable: false,
            title: ManageInventory_GridHeaders[6].description,
            width: 120,
            // template: function (dataItem) {
            //     return dataItem.Available + ((dataItem.PrimaryUnitType) ? +" "+ dataItem.PrimaryUnitType : '');
            // },
            sortable: false,
            filterable: false,
            wmsid: 9
        }, {
            field: "SKU",
            lockable: false,
            title: customLabels.Label_Sku,
            width: 120,
            wmsid: 3
        }, {
            field: "ReceiverId",
            lockable: false,
            title: ManageInventory_GridHeaders[15].description,
            width: 120,
            wmsid: 2
        },
        {
            field: "Key",
            lockable: false,
            title: ManageInventory_GridHeaders[0].description,
            width: 120,
            wmsid: 1
        },
        {
            field: "ReceivedDate",
            lockable: false,
            title: ManageInventory_GridHeaders[17].description,
            width: 120,
            filterable: true,
            template: "#= ReceivedDate != null ? kendo.toString(kendo.parseDate(ReceivedDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 20
        },
        {
            field: "Qualifier",
            lockable: false,
            title: ManageInventory_GridHeaders[2].description,
            width: 120,
            wmsid: 4
        },
        {
            field: "ExpirationDate",
            lockable: false,
            title: ManageInventory_GridHeaders[20].description,
            width: 120,
            template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 23
        },
        {
            field: "LotNumber",
            lockable: false,
            title: customLabels.Label_Lot_Number,
            width: 120,
            wmsid: 21
        },
        {
            field: "SerialNumber",
            lockable: false,
            title: customLabels.Label_Serial_Number,
            width: 120,
            wmsid: 22
        },
        {
            field: "Cost",
            lockable: false,
            title: ManageInventory_GridHeaders[22].description,
            width: 120,
            wmsid: 24
        },
        {
            field: "LocationName",
            lockable: false,
            title: ManageInventory_GridHeaders[24].description,
            width: 120,
            sortable: true,
            filterable: false,
            wmsid: 27
        },
        {
            field: "PrimaryUnitType",
            lockable: false,
            title: ManageInventory_GridHeaders[11].description,
            width: 120,
            wmsid: 14
        }]
    });
}


export const getAllColumnList = (ManageInventory_GridHeaders,selectedFacilityObj) => {
    
    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    return ({

        columns: [{
            field: "PrimaryUnitsToAdd",
            title: "Primary Qty to Add",
            attributes: {
                "class": "editable-cell"
            },
            width: 140,
            wmsid: 49,
            locked: true,
            lockable: false,
            resizable:false,
            sortable: false,
            reorderable: false,
            filterable: false,
            headerAttributes: { style: "text-align:center;" }
        },{
            field: "Key",
            lockable: false,
            title: ManageInventory_GridHeaders[0].description,
            width: 120,
            wmsid: 1
        }, {
            field: "SKU",
            lockable: false,
            title: customLabels.Label_Sku,
            width: 120,
            wmsid: 3
        }, {
            field: "Qualifier",
            lockable: false,
            title: ManageInventory_GridHeaders[2].description,
            width: 120,
            wmsid: 4
        }, {
            field: "Upc",
            lockable: false,
            title: ManageInventory_GridHeaders[3].description,
            width: 120,
            wmsid: 5
        },{
            field: "Description",
            lockable: false,
            title: ManageInventory_GridHeaders[4].description,
            width: 120,
            wmsid: 6
        },{
            field: "Received",
            lockable: false,
            title: ManageInventory_GridHeaders[5].description,
            width: 120,
            wmsid: 8
        },{
            field: "Available",
            lockable: false,
            title: ManageInventory_GridHeaders[6].description,
            width: 120,
            // template: function (dataItem) {
            //     return dataItem.Available + ((dataItem.PrimaryUnitType) ? +" "+ dataItem.PrimaryUnitType : '');
            // },
            sortable: false,
            filterable: false,
            wmsid: 9
        },{
            field: "OnHand",
            lockable: false,
            title: ManageInventory_GridHeaders[7].description,
            width: 120,
            wmsid: 10
        },{
            field: "OnHold",
            lockable: false,
            title: "OnHold",
            width: 120,
            wmsid: 11,
            // template: "# if(OnHold === true){# " + translate('Label_On_Hold') + " #}else if(OnHold === false){# " + translate('Label_Available') + " #}else{# " + translate('Label_Available') + " #} # "
        },{
            field: "SecondaryReceived",
            lockable: false,
            title: ManageInventory_GridHeaders[9].description,
            width: 120,
            sortable: false,
            filterable: false,
            wmsid: 12
        },{
            field: "SecondaryAvailable",
            lockable: false,
            title: ManageInventory_GridHeaders[10].description,
            width: 120,
            template: function (dataItem) {
                return (dataItem.SecondaryAvailable ? dataItem.SecondaryAvailable : 0) + " " + (dataItem.SecondaryUom ? dataItem.SecondaryUom : "");
            },
            sortable: false,
            filterable: false,
            wmsid: 13
        },{
            field: "PrimaryUnitType",
            lockable: false,
            title: ManageInventory_GridHeaders[11].description,
            width: 120,
            wmsid: 14
        },{
            field: "SecondaryUnitType",
            lockable: false,
            title: ManageInventory_GridHeaders[12].description,
            width: 120,
            wmsid: 15
        },{
            field: "Description2",
            lockable: false,
            title: ManageInventory_GridHeaders[13].description,
            width: 120,
            wmsid: 16
        },{
            field: "PalletLabel",
            lockable: false,
            title: ManageInventory_GridHeaders[14].description,
            width: 120,
            wmsid: 17
        },{
            field: "ReceiverId",
            lockable: false,
            title: ManageInventory_GridHeaders[15].description,
            width: 120,
            wmsid: 18
        },{
            field: "ReceiverReferenceNum",
            lockable: false,
            title: ManageInventory_GridHeaders[16].description,
            width: 120,
            wmsid: 19
        },{
            field: "ReceivedDate",
            lockable: false,
            title: ManageInventory_GridHeaders[17].description,
            width: 120,
            filterable: true,
            template: "#= ReceivedDate != null ? kendo.toString(kendo.parseDate(ReceivedDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 20
        }, {
            field: "LotNumber",
            lockable: false,
            title: customLabels.Label_Lot_Number,
            width: 120,
            wmsid: 21
        }, {
            field: "SerialNumber",
            lockable: false,
            title: customLabels.Label_Serial_Number,
            width: 120,
            wmsid: 22
        },{
            field: "ExpirationDate",
            lockable: false,
            title: ManageInventory_GridHeaders[20].description,
            width: 120,
            template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 23
        },{
            field: "Cost",
            lockable: false,
            title: ManageInventory_GridHeaders[21].description,
            width: 120,
            wmsid: 24
        },{
            field: "PurchaseOrderNumber",
            lockable: false,
            title: ManageInventory_GridHeaders[22].description,
            width: 120,
            wmsid: 25
        },{
            field: "TrailerNumber",
            lockable: false,
            title: ManageInventory_GridHeaders[23].description,
            width: 120,
            wmsid: 26
        },{
            field: "LocationName",
            lockable: false,
            title: ManageInventory_GridHeaders[24].description,
            width: 120,
            sortable: true,
            filterable: false,
            wmsid: 27
        },{
            field: "SupplierName",
            lockable: false,
            title: ManageInventory_GridHeaders[25].description,
            width: 120,
            wmsid: 28
        },{
            field: "CustomFields",
            lockable: false,
            title: ManageInventory_GridHeaders[26].description,
            width: 120,
            wmsid: 29
        },
        {
            field: "WeightImperial",
            lockable: false,
            title: ManageInventory_GridHeaders[28].description,
            width: 120,
            wmsid: 30
        },{
            field: "WeightMetric",
            lockable: false,
            title: ManageInventory_GridHeaders[29].description,
            width: 120,
            wmsid: 31
        },{
            field: "IsOnHold",
            lockable: false,
            title: ManageInventory_GridHeaders[8].description,
            width: 120,
            wmsid: 11,
            template: "# if(IsOnHold === true){# " + translate('Label_On_Hold') + " #}else if(IsOnHold === false){# " + translate('Label_Available') + " #}else{# " + translate('Label_Available') + " #} # "
        }]
    });
}

export const getGridDemoSchema = (GlobalService) => {
    return ({
        schema: {
            model: {
                id: "Key",
                fields: {
                    Key: { type: "number" , editable: false },
                    Varies: { type: "boolean", editable: false },
                    SKU: { type: "string" , editable: false },
                    Qualifier: { type: "string" , editable: false },
                    Upc: { type: "string" , editable: false },
                    Description: { type: "string" , editable: false },
                    Description2: { type: "string" , editable: false },
                    Received: { type: "number" , editable: false },
                    Available: { type: "number" , editable: false },
                    PrimaryUnitsToAdd: { type: "string",
                        validation: {
                            productnamevalidation: function (input,extra) {
                                if (input.is("[name='PrimaryUnitsToAdd']") && input.val() != "") {
                                    var grid = $("#GridManageInventory").data("kendoGrid");
                                    var dataItem = grid.dataItem($(input).closest('tr'));
                                    var isIntegral = GlobalService.getIsIntegeralFlagByType(dataItem.PrimaryUnitType);
                                    if(isIntegral){
                                        input.attr("data-productnamevalidation-msg", "Only Number allowed");
                                        return /^[0-9]*$/.test(input.val());
                                    }else{
                                        input.attr("data-productnamevalidation-msg", "Number and Decimal allowed");
                                        return /^(\d*\.)?\d+$/.test(input.val());
                                    }
                                }

                                return true;
                            }
                        }
                    },
                    OnHand: { type: "number" , editable: false },
                    OnHold: { type: "number" , editable: false },
                    SecondaryReceived: { type: "number" , editable: false },
                    SecondaryAvailable: { type: "string" , editable: false },
                    PrimaryUnitType: { type: "string" , editable: false },
                    SecondaryUnitType: { type: "string" , editable: false },
                    InventoryUnitsPerSecondaryUnit: { type: "string" , editable: false },
                    PalletLabel: { type: "string" , editable: false },
                    ReceiverId: { type: "number" , editable: false },
                    ReceiverReferenceNum: { type: "string" , editable: false },
                    ReceivedDate: { type: "date" , editable: false },
                    LotNumber: { type: "string" , editable: false },
                    SerialNumber: { type: "string" , editable: false },
                    ExpirationDate: { type: "date" , editable: false },
                    Cost: { type: "number" , editable: false },
                    PurchaseOrderNumber: { type: "string" , editable: false },
                    TrailerNumber: { type: "string" , editable: false },
                    LocationName: { type: "string" , editable: false },
                    IsOnHold: { type: "boolean" , editable: false },
                    SupplierName: { type: "string" , editable: false },
                    CustomFields: { type: "string" , editable: false },
                    WeightImperial: { type: "number", editable: false},
                    WeightMetric: { type: "number", editable: false}
                }
            }
        }
    })
}
// Add special configurations for combine row

export const getCombineGridColumns = (ManageInventory_GridHeaders,selectedFacilityObj) => {
    
    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    return ({

        columns: [{
            field: "PrimaryUnitsToAdd",
            title: "Primary Qty to Add",
            attributes: {
                "class": "editable-cell"
            },
            width: 140,
            wmsid: 49,
            locked: true,
            lockable: false,
            resizable:false,
            sortable: false,
            reorderable: false,
            filterable: false,
            headerAttributes: { style: "text-align:center;" }
        }, {
            field: "Available",
            lockable: false,
            title: ManageInventory_GridHeaders[6].description,
            width: 120,
            // template: function (dataItem) {
            //     return dataItem.Available + ((dataItem.PrimaryUnitType) ? +" "+ dataItem.PrimaryUnitType : '');
            // },
            sortable: false,
            filterable: false,
            wmsid: 9
        }, {
            field: "SKU",
            lockable: false,
            title: customLabels.Label_Sku,
            width: 120,
            wmsid: 3
        }, {
            field: "ReceiverId",
            lockable: false,
            title: ManageInventory_GridHeaders[15].description,
            width: 120,
            wmsid: 2
        }, {
            field: "Key",
            lockable: false,
            title: ManageInventory_GridHeaders[0].description,
            width: 120,
            wmsid: 1
        },
        {
            field: "ReceivedDate",
            lockable: false,
            title: ManageInventory_GridHeaders[17].description,
            width: 120,
            filterable: true,
            template: "#= ReceivedDate != null ? kendo.toString(kendo.parseDate(ReceivedDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 20
        },
        {
            field: "Qualifier",
            lockable: false,
            title: ManageInventory_GridHeaders[2].description,
            width: 120,
            wmsid: 4
        },
        {
            field: "ExpirationDate",
            lockable: false,
            title: ManageInventory_GridHeaders[20].description,
            width: 120,
            // template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 23
        },
        {
            field: "LotNumber",
            lockable: false,
            title: customLabels.Label_Lot_Number,
            width: 120,
            wmsid: 21
        },
        {
            field: "SerialNumber",
            lockable: false,
            title: customLabels.Label_Serial_Number,
            width: 120,
            wmsid: 22
        },
        {
            field: "Cost",
            lockable: false,
            title: ManageInventory_GridHeaders[22].description,
            width: 120,
            wmsid: 24
        },
        {
            field: "LocationName",
            lockable: false,
            title: ManageInventory_GridHeaders[24].description,
            width: 120,
            sortable: true,
            filterable: false,
            wmsid: 27
        },
        {
            field: "PrimaryUnitType",
            lockable: false,
            title: ManageInventory_GridHeaders[11].description,
            width: 120,
            wmsid: 14
        }]
    });
}

export const getCombineAllColumnList = (ManageInventory_GridHeaders,selectedFacilityObj) => {
    
    var translate = window.translate;
    var customLabels = GlobalService.getCustomLabels();
    return ({

        columns:[{
            field: "PrimaryUnitsToAdd",
            attributes: {
                "class": "editable-cell"
            },
            title: "Primary Qty to Add",
            width: 140,
            wmsid: 49,
            locked: true,
            lockable: false,
            resizable:false,
            sortable: false,
            reorderable: false,
            filterable: false,
            headerAttributes: { style: "text-align:center;" }
        },{
            field: "Key",
            lockable: false,
            title: ManageInventory_GridHeaders[0].description,
            width: 120,
            wmsid: 1
        }, {
            field: "SKU",
            lockable: false,
            title: customLabels.Label_Sku,
            width: 120,
            wmsid: 3
        }, {
            field: "Qualifier",
            lockable: false,
            title: ManageInventory_GridHeaders[2].description,
            width: 120,
            wmsid: 4
        }, {
            field: "Upc",
            lockable: false,
            title: ManageInventory_GridHeaders[3].description,
            width: 120,
            wmsid: 5
        },{
            field: "Description",
            lockable: false,
            title: ManageInventory_GridHeaders[4].description,
            width: 120,
            wmsid: 6
        },{
            field: "Received",
            lockable: false,
            title: ManageInventory_GridHeaders[5].description,
            width: 120,
            wmsid: 8
        },{
            field: "Available",
            lockable: false,
            title: ManageInventory_GridHeaders[6].description,
            width: 120,
            // template: function (dataItem) {
            //     return dataItem.Available + ((dataItem.PrimaryUnitType) ? +" "+ dataItem.PrimaryUnitType : '');
            // },
            sortable: false,
            filterable: false,
            wmsid: 9
        },{
            field: "OnHand",
            lockable: false,
            title: ManageInventory_GridHeaders[7].description,
            width: 120,
            wmsid: 10
        },{
            field: "OnHold",
            lockable: false,
            title: "OnHold",
            width: 120,
            wmsid: 11,
            // template: "# if(OnHold === true){# " + translate('Label_On_Hold') + " #}else if(OnHold === false){# " + translate('Label_Available') + " #}else{# " + translate('Label_Available') + " #} # "
        },{
            field: "SecondaryReceived",
            lockable: false,
            title: ManageInventory_GridHeaders[9].description,
            width: 120,
            sortable: false,
            filterable: false,
            wmsid: 12
        },{
            field: "SecondaryAvailable",
            lockable: false,
            title: ManageInventory_GridHeaders[10].description,
            width: 120,
            template: function (dataItem) {
                return (dataItem.SecondaryAvailable ? dataItem.SecondaryAvailable : 0) + " " + (dataItem.SecondaryUom ? dataItem.SecondaryUom : "");
            },
            sortable: false,
            filterable: false,
            wmsid: 13
        },{
            field: "PrimaryUnitType",
            lockable: false,
            title: ManageInventory_GridHeaders[11].description,
            width: 120,
            wmsid: 14
        },{
            field: "SecondaryUnitType",
            lockable: false,
            title: ManageInventory_GridHeaders[12].description,
            width: 120,
            wmsid: 15
        },{
            field: "Description2",
            lockable: false,
            title: ManageInventory_GridHeaders[13].description,
            width: 120,
            wmsid: 16
        },{
            field: "PalletLabel",
            lockable: false,
            title: ManageInventory_GridHeaders[14].description,
            width: 120,
            wmsid: 17
        },{
            field: "ReceiverId",
            lockable: false,
            title: ManageInventory_GridHeaders[15].description,
            width: 120,
            wmsid: 18
        },{
            field: "ReceiverReferenceNum",
            lockable: false,
            title: ManageInventory_GridHeaders[16].description,
            width: 120,
            wmsid: 19
        },{
            field: "ReceivedDate",
            lockable: false,
            title: ManageInventory_GridHeaders[17].description,
            width: 120,
            filterable: true,
            // template: "#= ReceivedDate != null ? kendo.toString(kendo.parseDate(ReceivedDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 20
        }, {
            field: "LotNumber",
            lockable: false,
            title: customLabels.Label_Lot_Number,
            width: 120,
            wmsid: 21
        }, {
            field: "SerialNumber",
            lockable: false,
            title: customLabels.Label_Serial_Number,
            width: 120,
            wmsid: 22
        },{
            field: "ExpirationDate",
            lockable: false,
            title: ManageInventory_GridHeaders[20].description,
            width: 120,
            // template: "#= ExpirationDate != null ? kendo.toString(kendo.parseDate(ExpirationDate), 'MMM dd yyyy, hh:mm:ss tt') : '' #",
            wmsid: 23
        },{
            field: "Cost",
            lockable: false,
            title: ManageInventory_GridHeaders[22].description,
            width: 120,
            wmsid: 24
        },{
            field: "PurchaseOrderNumber",
            lockable: false,
            title: ManageInventory_GridHeaders[22].description,
            width: 120,
            wmsid: 25
        },{
            field: "TrailerNumber",
            lockable: false,
            title: ManageInventory_GridHeaders[23].description,
            width: 120,
            wmsid: 26
        },{
            field: "LocationName",
            lockable: false,
            title: ManageInventory_GridHeaders[24].description,
            width: 120,
            sortable: true,
            filterable: false,
            wmsid: 27
        },{
            field: "SupplierName",
            lockable: false,
            title: ManageInventory_GridHeaders[25].description,
            width: 120,
            wmsid: 28
        },{
            field: "CustomFields",
            lockable: false,
            title: ManageInventory_GridHeaders[26].description,
            width: 120,
            wmsid: 29
        },{
            field: "WeightImperial",
            lockable: false,
            title: ManageInventory_GridHeaders[28].description,
            width: 120,
            wmsid: 30
        },{
            field: "WeightMetric",
            lockable: false,
            title: ManageInventory_GridHeaders[29].description,
            width: 120,
            wmsid: 31
        },{
            field: "IsOnHold",
            lockable: false,
            title: ManageInventory_GridHeaders[8].description,
            width: 120,
            wmsid: 11,
            template: "# if(Varies){# "+ translate('Varies') + " #} else if(IsOnHold === true){# " + translate('Label_On_Hold') + " #}else if(IsOnHold === false){# " + translate('Label_Available') + " #}else{# " + translate('Label_Available') + " #} # "
            // template: "# if(IsOnHold === true){# " + translate('Label_On_Hold') + " #}else if(IsOnHold === false){# " + translate('Label_Available') + " #}else{# " + translate('Label_Available') + " #} # "
        }]
    });
}

export const getGridCombineSchema = () => {
    return ({
        schema: {
            model: {
                id: "Key",
                fields: {
                    Key: { type: "string" , editable: false },
                    Varies: { type: "boolean" , editable: false},
                    SKU: { type: "string" , editable: false },
                    Qualifier: { type: "string" , editable: false },
                    Upc: { type: "string" , editable: false },
                    Description: { type: "string" , editable: false },
                    Description2: { type: "string" , editable: false },
                    Received: { type: "number" , editable: false },
                    Available: { type: "string" , editable: false },
                    PrimaryUnitsToAdd: { type: "string",
                        validation: {
                            productnamevalidation: function (input) {
                                if (input.is("[name='PrimaryUnitsToAdd']") && input.val() != "") {
                                    var grid = $("#GridManageInventory").data("kendoGrid");
                                    var dataItem = grid.dataItem($(input).closest('tr'));
                                    var isIntegral = GlobalService.getIsIntegeralFlagByType(dataItem.PrimaryUnitType);
                                    if(isIntegral){
                                        input.attr("data-productnamevalidation-msg", "Only Number allowed");
                                        return /^[0-9]*$/.test(input.val());
                                    }else{
                                        input.attr("data-productnamevalidation-msg", "Number and Decimal allowed");
                                        return /^(\d*\.)?\d+$/.test(input.val());
                                    }
                                }
                                return true;
                            }
                        }
                    },
                    OnHand: { type: "number" , editable: false },
                    OnHold: { type: "number" , editable: false },
                    SecondaryReceived: { type: "number" , editable: false },
                    SecondaryAvailable: { type: "string" , editable: false },
                    PrimaryUnitType: { type: "string" , editable: false },
                    SecondaryUnitType: { type: "string" , editable: false },
                    InventoryUnitsPerSecondaryUnit: { type: "string" , editable: false },
                    PalletLabel: { type: "string" , editable: false },
                    ReceiverId: { type: "string" , editable: false },
                    ReceiverReferenceNum: { type: "string" , editable: false },
                    ReceivedDate: { type: "string" , editable: false },
                    LotNumber: { type: "string" , editable: false },
                    SerialNumber: { type: "string" , editable: false },
                    ExpirationDate: { type: "string" , editable: false },
                    Cost: { type: "number" , editable: false },
                    PurchaseOrderNumber: { type: "string" , editable: false },
                    TrailerNumber: { type: "string" , editable: false },
                    LocationName: { type: "string" , editable: false },
                    SupplierName: { type: "string" , editable: false },
                    IsOnHold: { type: "boolean" , editable: false },
                    CustomFields: { type: "string" , editable: false },
                    WeightImperial: { type: "number" , editable: false },
                    WeightMetric: { type: "number" , editable: false }
                }
            }
        }
    })
}