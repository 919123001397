import axios from "axios";

import * as GlobalService from "../global_services";

export const GetReceivingImportPreferences = (customerUri) => {

    return axios.get('/WebUI/Settings/ImportReceiptAsnPreferences/Preferences?customerUri=' + customerUri)
        .then(function (response) {
            var tempData = response.data;

            let MyReturn = {};
            MyReturn.Defaults = [];

            //Determining if the items in the array belong in customs or defaults
            tempData.Transforms.forEach((currentElem) => {
                MyReturn.Defaults.push(currentElem);
            });

            //Adding the work flow to the return object
            MyReturn.Workflow = tempData.Workflow;

            return MyReturn;
        });
}

export const SaveReceivngImportPreferences = (custUri, workflow, parserIds) => {
    let data = {
        customerUri: custUri,
        workflowViewModel: workflow,
        transformIds: parserIds
    }
    return axios.put('/WebUI/Settings/ImportReceiptAsnPreferences/SavePreferences', data)
        .then(function (response) {
            return response;
        });
}