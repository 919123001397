import React from 'react';
var axios = require('axios');
var UtcOffset = new Date().getTimezoneOffset() / 60 * -1;

import * as GlobalService from './../../global_services';

export const initialFindOrders = function () {
    return axios.all([
        axios.get('/WebUI/Shipping/FindOrders/SearchInfoGet')
    ]).then(axios.spread(function (FindOrderSearchInfo) {
        return { FindOrderSearchInfo: FindOrderSearchInfo.data.Data };
    }));
}


// carrier and routing model services
export const initialiseBulkEditModel = function (param_orderIds, param_customerId) {
    return axios.post('/WebUI/Shipping/FindOrders/GetMassEditRoutingData',
        {"ids": param_orderIds,
            "customerId": param_customerId
        }).then(function (response) {
        return response;
    });
}


// carrier and routing model services
export const initialiseBulkEditModelProto = function (param_orderIds, param_customerId, param_facilityId) {
    let editRoutingInfoDataParam = {
        orderIds: param_orderIds,
        customerId: param_customerId
    }

    return axios.all([
        axios.put('/WebUI/Shipping/FindOrders/MassEditRoutingData',editRoutingInfoDataParam),
        axios.get('/WebUI/Shipping/FindOrders/CarriersAndCapacityTypes?customerId='+param_customerId+'&facilityId='+param_facilityId),
    ]).then(axios.spread(function (editRoutingInfoDataResponse,carriersAndCapacityTypesResponse) {
        return {
            editRoutingInfoDataResponse: editRoutingInfoDataResponse.data,
            carriersAndCapacityTypesResponse: carriersAndCapacityTypesResponse.data
        };
    }));
}

export const bulkUpdateCarrierRouting = function (param_rouingdata) {
    return axios.post('/WebUI/Shipping/FindOrders/MassCarrierAndRoutingSave', param_rouingdata)
}

export const bulkUpdateCarrierRoutingProto = function (param_rouingdata) {
    return axios.put('/WebUI/Shipping/FindOrders/MassEditRoutingDataUpdater', param_rouingdata)
        .then(function (response) {
            return response;
        });
}

// create pick job model services
export const initialiseCreatePickJobModel = function (orderIds) {
    return axios.all([

        axios.get('/WebUI/Shipping/FindOrders/PickJobCandidatUsers'),

    ]).then(axios.spread(function (pickJobCandidatUsersResponse) {

        return {
            pickJobCandidatUsersResponse: pickJobCandidatUsersResponse.data
        };

    }));
}

export const createPickJob = function (orderIds) {
    return axios.post('/WebUI/Shipping/FindOrders/PickJobs', orderIds)
        .then(function (response) {
            return response.data;
        });
}

export const removeFromPickJob = function (orderIds) {
    return axios.put('/WebUI/Shipping/FindOrders/PickJobOrderRemover', orderIds)
        .then(function (response) {
            return response.data;
        });
}

export const deletePickJob = function (pickJobId) {
    return axios.delete('/WebUI/Shipping/FindOrders/PickJob?pickJobId=' + pickJobId)
        .then(function (response) {
            return response.data;
        });
}

export const recalcPackages = function(orderIds) {
    return axios.put('/WebUI/FindOrders/PackagesGenerator', orderIds)
        .then(function(response) {
            return response.data;
        });
}

export const ordersWithoutPackages = function(orderIds) {
    return axios.put('/WebUI/Shipping/FindOrders/OrdersWithoutPackages', {orderIds: orderIds })
        .then(function(response) {
            return response.data;
        });
}

export const consolidatePackages = function(orderIds,packageDefId) {
    return axios.put('/WebUI/Shipping/FindOrders/PackagesConsolidator?packageDefId='+packageDefId, orderIds)
        .then(function(response) {
            return response.data;
        });
}

// Used for CustomDimension
export const customDimensionConsolidatePackages = function(customDimPkg) {
    return axios.put('/WebUI/Shipping/FindOrders/PackagesConsolidatorByCustomDims', customDimPkg)
        .then(function(response) {
            return response.data;
        });
}


// Mass and close model services
export const initialiseMassShipAndCloseModel = function (param_customerId, param_orderIds, param_facilityId) {
    return axios.all([

        axios.post('/WebUI/Shipping/FindOrders/GetMassShipOrderCharges', { orderId: param_orderIds[0], customerId: param_customerId, facilityId: param_facilityId }),
        axios.get('/WebUI/Shipping/Orders/GetChargeCategories'),
        axios.post('/WebUI/Shipping/FindOrders/GetMassShipAndCloseData', param_orderIds),

    ]).then(axios.spread(function (getMassShipOrderChargesResponse, getChargeCategoriesResponse, getMassShipAndCloseDataResponse) {

        return {
            massShipChargesGridData: getMassShipOrderChargesResponse,
            chargesCategoryResponse: getChargeCategoriesResponse,
            massShipAndCloseResponse: getMassShipAndCloseDataResponse
        };

    }));
}
//END Mass and close model services

export const reopenOrders = function (ids) {
    return axios.post('/WebUI/Shipping/FindOrders/ReopenOrders', ids)
        .then(function (response) {
            return response;
        });
}

// Send to tmw service
export const tmwExporter = function (orderIds) {
    return axios.post('/WebUI/Shipping/FindOrders/TmwExporter', {
        'orderIds': orderIds
    }).then(function (response) {
        return response;
    });
}

export const unallocateOrders = function (ids) {
    return axios.post('/WebUI/Shipping/FindOrders/UnallocateOrders', ids)
        .then(function (response) {
            return response;
        });
}

export const splitOrders = function (ids) {
    return axios.post('/WebUI/Shipping/FindOrders/SplitOrders', ids)
        .then(function (response) {
            return response;
        });
}
export const shorterShipOrders = function (ids) {
    return axios.post('/WebUI/Shipping/ShortOrders/Shorter', {"orderIds" : ids})
        .then(function (response) {
            return response;
        });
}

// Pick Tickets Services
export const GetPickTicketReports = function (data) {
    return axios.post('/WebUI/Shipping/FindOrders/GetPickTicketReports', data)
        .then(function (response) {
            if (response.status == 200) {
                // sort report in alphabatic order and
                // move default template in last position
                return sortReports(response);
                // return response;
            }
            return null;
        });
}
const sortReports = function (param_response) {
    $.map(param_response.data, (item,index) => {

        if(item.Reports){
            var tmpOpt = [];
            tmpOpt = item.Reports.sort(function(first, second) {
                // set default value at last in sorting
                if(first.Name == 'Default' || second.Name == 'Default'){ return -1; }
                var firstReport = first.Name.toUpperCase(); // ignore upper and lowercase
                var secondReport = second.Name.toUpperCase(); // ignore upper and lowercase

                if (firstReport < secondReport) { return -1; }

                if (firstReport > secondReport) { return 1; }
                // names must be equal
                return 0;
            });
            param_response.data[index].Reports = tmpOpt;
        }

    });

    return param_response;
}

export const GeneratePickTicket = function (url, data) {
    return axios.post(url, data)
        .then(function (response) {
            return response;
        });
}

// Batch models services

export const createBatch = function (batchData) {
    return axios.post('/WebUI/Shipping/FindOrders/CreateBatch', batchData).then(function success(response) {
        return response;
    });
}
export const addToExistingBatch = function (batchData) {
    return axios.post('/WebUI/Shipping/FindOrders/AddToExistingBatch', batchData).then(function success(response) {
        return response;
    });
}
export const removeFromBatch = function (batchData) {
    return axios.post('/WebUI/Shipping/FindOrders/RemoveFromBatch', batchData).then(function success(response) {
        return response;
    });
}
export const autoBatch = function () {
    return axios.put('/WebUI/Shipping/FindOrders/AutoBatchOrders').then(function success(response) {
        return response;
    });
}

export const dynamicServiceForDynamicMenu = function (urlName, uri, ids, outputType, flowType) {
    var fileType = outputType ? outputType : 'application/pdf';
    var myReturn = {};
    myReturn.FileType = fileType;

    var _url = "";
    var data = {
        requestBody: {
            RunnerUriTemplate: uri,
            TransactionIds: ids,
            IgnoreDuplicates: true,
            UtcOffset: UtcOffset,
            outputType: fileType
        }
    }

    if (urlName === "RunBOLReport") {
        _url = '/WebUI/Shipping/' + flowType + '/RunBOLReport';
        data.requestBody.BolNumAsTransNum = false;
    } else if (urlName === "RunMasterBOLReport") {
        _url = '/WebUI/Shipping/' + flowType + '/RunMasterBOLReport';
        data.requestBody.BolNumAsTransNum = false;
    } else if (urlName === "RunReportViewTransaction") {
        _url = '/WebUI/Shipping/' + flowType + '/RunViewTransactionReport';
        data.requestBody.IgnoreDuplicates = false;
        data.requestBody.ShowCharges = GlobalService.GetRights().hasOwnProperty("admin.viewbilling") ? true : false
    }

    return axios.post(_url, data)
        .then(function (response) {
            myReturn.Data = response.data;
            return myReturn;
        });
}
/* Packing Slip model services */
export const generateShippingLabel = function (param_payload) {
    return axios.post('/WebUI/Shipping/FindOrders/RunShippingLabelsReport',{'requestBody':param_payload}).then(function (response) {
        return response.data;
    });
}
/* Packing Slip model services */
export const initialPackingSlips = function (params_customerid, param_orderIds) {
    return axios.post('/WebUI/Shipping/FindOrders/GetPackingSlipReports', {
        customerId: params_customerid,
        orderIds: param_orderIds
    }).then(function (response) {
        return response.data;
    });
}

export const PackingSlipReportStarter = function (param_body) {

    return axios.post('/WebUI/Shipping/FindOrders/PackingSlipReportStarter', param_body
    ).then(function (response) {
        return response;
    });
}

// send asn model services
export const intialSendAsn = function (param_customerUri) {
    return axios.get('/WebUI/Settings/SendAsnPreferences/Preferences?customerUri=' + param_customerUri).then(function (response) {
        return response.data;
    });
}

export const checkAsnPreChecker = function (orderids) {
    return axios.put('/WebUI/Shipping/FindOrders/AsnPreChecker', orderids)
    .then(function (response) {
        return response;
    });
}

export const getPackageDefs = function () {
    return axios.get('/WebUI/Shipping/FindOrders/GetPackageDefs').then(function (response) {
        return response.data;
    });
}

export const downloadSendAsn = function (param_downloadData) {
    return axios.get('/WebUI/Shipping/FindOrders/DownloadAsn', { params:param_downloadData})
    .then(function (response) {
        return response;
    });
}

export const sendAsn = function (param_asndata) {
    return axios.post('/WebUI/Shipping/FindOrders/QueueAsnToSend', param_asndata)
    .then(function (response) {
        return response;
    });
}

//Smart parcel services

export const parcelPreShip = function (orderids) {
    return axios.put('/WebUI/V1/PreSmartParcelShip', orderids)
    .then(function (response) {
        return response;
    });
}

export const parcelCanceler = function (ids) {
    return axios.put('/WebUI/Shipping/FindOrders/ParcelCancelerStarter', ids)
        .then(function (response) {
            return response;
        });
}

export const parcelFinalizer = function (ids) {
    return axios.put('/WebUI/Shipping/FindOrders/ParcelFinalizer', ids)
    .then(function (response) {
        return response;
    });
}

export const parcelVoidCanceler = function (ids) {
    return axios.post('/WebUI/Shipping/FindOrders/ParcelVoidCanceler',ids)
    .then(function (response) {
        return response;
    });
}

export const parcelReseter = function (ids) {
    return axios.post('/WebUI/Shipping/FindOrders/ParcelReseter',ids)
    .then(function (response) {
        return response;
    });
}

export const parcelAutoRateShopper = function (ids) {
    return axios.post('/WebUI/Shipping/FindOrders/ParcelAutoRateShopper',ids)
    .then(function (response) {
        return response;
    });
}

//print zpl secives

export const reprintOrReturnZpl = function (uri, ids) {
    return axios.post(uri,{ OrderIds: ids })
    .then(function (response) {
        return response;
    });
}

export const reprintOrReturnPdf = function (uri) {
    return axios.get(uri,{ responseType: 'blob' })
    .then(function (response) {
        return response;
    });
}

export const searchInfoClear = function () {
    return axios.post('/WebUI/Shipping/UpdateSession/SearchInfoClear')
    .then(function (response) {
        return response;
    });
}

export const getBOLTemplates = function (param_customerID) {
    return axios.post('/WebUI/Shipping/Orders/BOLTemplates?customerId=' +param_customerID).then(function (response) {
        return response;
     });
}

export const OrdersTranfer = function (data) {
    return axios.post('/WebUI/Shipping/FindOrders/OrdersTransferStarter', data)
        .then(function (response) {
            return response;
        });
}

export const getSmallParcelBatches  = function () {
    return axios.get('/WebUI/Shipping/FindOrders/GetSmallParcelBatches')
        .then(function (response) {
            return response;
        });
}

export const getSmallParcelBatchPdfContents  = function (pdfUri) {
    return axios.get('/WebUI/Shipping/SmartParcel/ShippingPdfLabelsHighVolumeBatch?batchurl=' + pdfUri, { responseType: 'blob' })
        .then(function (response) {
            return response;
        });
}

export const getSmallParcelBatchZplContents  = function (zplUri) {
    let url = "/WebUI/Shipping/FindOrders/GetSmallParcelBatchZplContents?batchlabelszpluri=" + zplUri;
    return axios.get(url)
        .then(function (response) {
            return response;
        });
}

export const putSmallParcelBatchLabels  = function (param_args) {
    return axios.put('/WebUI/Shipping/FindOrders/PutSmallParcelBatchLabels' + param_args)
        .then(function (response) {
            return response;
        });
}

export const getSmallParcelBatchErrors  = function (batcherrorsuri, batchshipmentsuri) {
    return axios.get('/WebUI/Shipping/FindOrders/GetSmallParcelBatchErrors?batcherrorsuri=' + batcherrorsuri + '&batchshipmentsuri=' + batchshipmentsuri)
        .then(function (response) {
            return response;
        });
}

export const markOrdersComplete  = function (orderIds) {
    return axios.post('/WebUI/Shipping/FindOrders/CompleteOrders',orderIds)
        .then(function (response) {
            return response;
        });
}

export const manifestList = function () {
    return axios.put('/WebUI/Shipping/FindOrders/ManifestList').then(function success(response) {
        return response;
    });
}