export const getGridDemoColumns = (FindCustomer_GridHeaders,CustomLabels) => {

    var translate = window.translate;
    return ({

        columns: [{
            field: "Name",
            title: FindCustomer_GridHeaders[1].description,
            width: 120,
            wmsid: 2
        },
        {
            field: "Status",
            title: FindCustomer_GridHeaders[2].description,
            width: 120,
            wmsid: 3,
            template: "#= Status == true ? '" + translate('Label_Active') + "' : '" + translate('Label_Inactive') + "' #"
        },
        {
            field: "Phone",
            title: FindCustomer_GridHeaders[3].description,
            width: 120,
            wmsid: 4,
            filterable: true
        },
        {
            field: "PrimaryContactName",
            title: FindCustomer_GridHeaders[4].description,
            width: 120,
            wmsid: 5,
            hidden: true,
            sortable: false
        },
        {
            field: "PrimaryWarehouse",
            title: "Primary " + CustomLabels.Label_Warehouse,
            width: 120,
            wmsid: 6,
            hidden: true
        },
        {
            field: "CustomerId",
            title: translate('Label_Customer_Id'),
            width: 120,
            wmsid: 7,
            hidden: true
        }]
    });
};


export const getGridDemoSchema = () => {
    return ({
        schema: {
            data: "Data",
            total: "Total",
            model: {
                id: "CustomerId",
                fields: {
                    CustomerId: {
                        type: "number"
                    },
                    Name: {
                        type: "string"
                    },
                    Status: {
                        type: "boolean"
                    },
                    Phone: {
                        type: "string"
                    },
                    PrimaryContactName: {
                        type: "string"
                    },
                    PrimaryWarehouse: {
                        type: "string"
                    }
                }
            }
        }
    });
};

export const getGridDemoTransport = (warehouseIndex) => {
    return ({
        transport: {
            read: {
                url: "/WebUI/Customers/ManageCustomers/CustomersGridRead",
                type: "POST",
                dataType: 'json'
            },
            destroy: {
                url: "/WebUI/Customers/ManageCustomers/CustomersGridRead",
                type: "POST",
                dataType: "JSON"
            },
            parameterMap: function (options, operation) {
                if (operation !== "read" && options.models) {
                    return {
                        models: JSON.stringify(options.models)
                    };
                }
            },
        },
    });
};